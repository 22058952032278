export const BRAND_CATEGORY_BREAKDOWN = 'brand_category'

export const bookedRevenueBreakdownsList = [
  {
    value: BRAND_CATEGORY_BREAKDOWN,
    label: 'Brand category'
  },
  {
    value: 'brand_subcategory',
    label: 'Brand subcategory'
  },
  {
    value: 'media_category',
    label: 'Media category'
  },
  {
    value: 'media_subcategory',
    label: 'Media subcategory'
  },
  {
    value: 'media_subsubcategory',
    label: 'Media subsubcategory'
  },
  {
    value: 'media_product_location',
    label: 'Media product location'
  },
  {
    value: 'media_product_sublocation',
    label: 'Media product sublocation'
  }
]
