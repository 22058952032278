import * as Yup from 'yup'

import { getAdHeadlines } from '../../helpers'
import { headlineShape } from '../../../../validations'
import { HEADLINES_LIST } from '../../../../fields'

export const getInitialValues = ad => {
  const headlinesData = getAdHeadlines(ad)

  return {
    [HEADLINES_LIST]: headlinesData.map(headline => ({
      text: headline
    }))
  }
}

export const validationSchema = Yup.object({
  [HEADLINES_LIST]: Yup.array().of(headlineShape)
})
