import { createStyles } from '../../styles/helpers'

import { lighterGrey } from '../../styles/const/colors'
import { transitionShort } from '../../styles/const/common'

export default createStyles(theme => ({
  progressBar: {
    backgroundColor: lighterGrey,
    position: 'relative',
    height: '4px',
    width: '100%',
    borderRadius: theme.bigBorderRadius,
    overflow: 'hidden'
  },
  progressLine: {
    backgroundColor: ({ barColor }) => barColor || theme.brandPrimary,
    position: 'absolute',
    top: 0,
    left: 0,
    width: 0,
    height: '100%',
    borderRadius: '2px',
    transition: transitionShort
  }
}))
