import { concat, eraseCookie, setCookie } from '../../helpers/common'

const MODULE_NAME = 'LINE_ITEMS_SUMMARY/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const FILTER = concat(MODULE_NAME, 'FILTER')
export const DATE_RANGE = concat(MODULE_NAME, 'DATE_RANGE')
export const SORT = concat(MODULE_NAME, 'SORT')

export const SET_LINE_ITEMS_SUMMARY_FILTER = concat(MODULE_NAME, 'SET_LINE_ITEMS_SUMMARY_FILTER')
export const SET_LINE_ITEMS_SUMMARY_DATE_RANGE = concat(MODULE_NAME, 'SET_LINE_ITEMS_SUMMARY_DATE_RANGE')
export const SET_LINE_ITEMS_SUMMARY_SORT = concat(MODULE_NAME, 'SET_LINE_ITEMS_SUMMARY_SORT')
export const CLEAR_LINE_ITEMS_SUMMARY_FILTERS = concat(MODULE_NAME, 'CLEAR_LINE_ITEMS_SUMMARY_FILTER')

export const GET_LINE_ITEMS_SUMMARY_REPORTS = concat(MODULE_NAME, 'GET_LINE_ITEMS_SUMMARY_REPORTS')
export const GET_LINE_ITEMS_SUMMARY_REPORTS_SUCCESS = concat(MODULE_NAME, 'GET_LINE_ITEMS_SUMMARY_REPORTS_SUCCESS')
export const GET_LINE_ITEMS_SUMMARY_REPORTS_FAILURE = concat(MODULE_NAME, 'GET_LINE_ITEMS_SUMMARY_REPORTS_FAILURE')

export const CLEAR_LINE_ITEMS_SUMMARY_REPORTS = concat(MODULE_NAME, 'CLEAR_LINE_ITEMS_SUMMARY_REPORTS')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

export function setLineItemsSummaryFilter(filter) {
  setCookie(FILTER, filter, 1)
  return { type: SET_LINE_ITEMS_SUMMARY_FILTER, filter }
}

export function setLineItemsSummaryDateRange(dateRange) {
  setCookie(DATE_RANGE, dateRange, 1)
  return { type: SET_LINE_ITEMS_SUMMARY_DATE_RANGE, dateRange }
}

export function setLineItemsSummarySort(sort) {
  setCookie(SORT, sort, 1)
  return { type: SET_LINE_ITEMS_SUMMARY_SORT, sort }
}

export function clearLineItemsSummaryFilters() {
  eraseCookie(FILTER)
  eraseCookie(DATE_RANGE)
  eraseCookie(SORT)

  return { type: CLEAR_LINE_ITEMS_SUMMARY_FILTERS }
}

export function getLineItemsSummaryReports(params) {
  return { type: GET_LINE_ITEMS_SUMMARY_REPORTS, params }
}

export function getLineItemsSummaryReportsSuccess(reportsData) {
  return { type: GET_LINE_ITEMS_SUMMARY_REPORTS_SUCCESS, reportsData }
}

export function getLineItemsSummaryReportsFailure(error) {
  return { type: GET_LINE_ITEMS_SUMMARY_REPORTS_FAILURE, error }
}

export function clearLineItemsSummaryReports() {
  return { type: CLEAR_LINE_ITEMS_SUMMARY_REPORTS }
}
