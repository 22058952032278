import update from 'immutability-helper'
import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_AUDIENCES,
  GET_AUDIENCES_FAILURE,
  GET_AUDIENCES_SUCCESS,
  DELETE_AUDIENCE,
  DELETE_AUDIENCE_FAILURE,
  DELETE_AUDIENCE_SUCCESS,
  CREATE_AUDIENCE,
  CREATE_AUDIENCE_SUCCESS,
  CREATE_AUDIENCE_FAILURE,
  CLEAR_AUDIENCES,
  CLEAR_CREATE_AUDIENCE,
  CLEAR_DELETE_AUDIENCE,
  CREATE_AUDIENCE_BY_SUGGESTION,
  CREATE_AUDIENCE_BY_SUGGESTION_SUCCESS,
  CREATE_AUDIENCE_BY_SUGGESTION_FAILURE,
  CLEAR_CREATE_AUDIENCE_BY_SUGGESTION
} from '../actions/audiences'
import { deleteItemById } from '../../helpers/modules/reducerHelpers'
import { INITIAL_CREATE_REDUCER, INITIAL_GET_RESULTS_PAGING_REDUCER } from '../../constants/reducer'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  audiences: INITIAL_GET_RESULTS_PAGING_REDUCER,
  createAudience: INITIAL_CREATE_REDUCER,
  createdAudienceBySuggestion: INITIAL_CREATE_REDUCER,
  deletedAudience: {}
}

export default function audiences(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    case GET_AUDIENCES:
      return update(state, {
        audiences: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_AUDIENCES_SUCCESS:
      return update(state, {
        audiences: {
          results: {
            ...(action.options.isPaginationLoad ? { $push: action.audiencesData.data } : { $set: action.audiencesData })
          },
          $merge: {
            isLoading: false,
            wasLoaded: true,
            ...(action.options.isPaginationLoad && { paging: action.audiencesData.paging })
          }
        }
      })
    case GET_AUDIENCES_FAILURE:
      return update(state, {
        audiences: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CREATE_AUDIENCE:
      return update(state, {
        createAudience: {
          $merge: {
            isLoading: true
          }
        }
      })
    case CREATE_AUDIENCE_SUCCESS:
      return update(state, {
        createAudience: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.audienceData
          }
        },
        audiences: {
          results: {
            $unshift: [action.audienceData]
          }
        }
      })
    case CREATE_AUDIENCE_FAILURE:
      return update(state, {
        createAudience: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CREATE_AUDIENCE_BY_SUGGESTION:
      return update(state, {
        createdAudienceBySuggestion: { $merge: { isLoading: true } }
      })
    case CREATE_AUDIENCE_BY_SUGGESTION_SUCCESS:
      return update(state, {
        createdAudienceBySuggestion: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            id: action.audienceData.id
          }
        },
        audiences: {
          results: {
            $unshift: [action.audienceData]
          }
        }
      })
    case CREATE_AUDIENCE_BY_SUGGESTION_FAILURE:
      return update(state, {
        createdAudienceBySuggestion: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case DELETE_AUDIENCE:
      return update(state, {
        deletedAudience: { $set: { isLoading: true } }
      })
    case DELETE_AUDIENCE_SUCCESS:
      return update(state, {
        audiences: {
          results: {
            $apply: items => deleteItemById(items, action.id)
          }
        },
        deletedAudience: { $set: { isLoading: false, id: action.id, wasDeleted: true } }
      })
    case DELETE_AUDIENCE_FAILURE:
      return update(state, {
        deletedAudience: { $set: { isLoading: false, error: action.error } }
      })
    case CLEAR_AUDIENCES:
      return update(state, {
        audiences: { $set: initialState.audiences }
      })
    case CLEAR_CREATE_AUDIENCE:
      return update(state, {
        createAudience: { $set: initialState.createAudience }
      })
    case CLEAR_CREATE_AUDIENCE_BY_SUGGESTION:
      return update(state, {
        createdAudienceBySuggestion: { $set: initialState.createdAudienceBySuggestion }
      })
    case CLEAR_DELETE_AUDIENCE:
      return update(state, {
        deletedAudience: { $set: initialState.deletedAudience }
      })

    default:
      return state
  }
}
