import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import { t } from 'i18next'

import { getNumberOfDays } from '../../../../../helpers/date'
import { formatNumber } from '../../../../../helpers/numbers'

import {
  TARGET_ROAS,
  BUDGET_DAILY,
  CAMPAIGN_BUDGET,
  NAME,
  AMOUNT_MICROS,
  MAXIMIZE_CONVERSION_VALUE
} from '../../../../Multiplatform/CampaignForms/fields'
import {
  defaultGoogleCampaignPayload,
  PERFORMANCE_MAX_TYPE,
  DISPLAY_TYPE,
  CAMPAIGN_TYPE
} from '../../../CampaignForms/CampaignGoogleCreate/CampaignGoogleCreateForm/fields'
import { CAMPAIGN_OPTION, campaignValidation } from './CampaignFormContent/fields'

import {
  START_DATE,
  END_DATE,
  startEndDateRangeInitialValues,
  startDateValidation,
  endDateValidation
} from '../../../../ReusableFormFields/StartEndDateRangeFields/fields'
import { formatDateToBE } from '../../../../../constants/dates'
import { getCampaignInitialValues } from '../../helpers'

export const BUDGET_LIFETIME = 'budget_lifetime'

export const getInitialValues = isCampaignsExist => {
  const initialCampaignValues = getCampaignInitialValues(isCampaignsExist)

  return {
    ...initialCampaignValues,
    [BUDGET_DAILY]: '',
    [TARGET_ROAS]: '',
    ...startEndDateRangeInitialValues
  }
}

export const getValidationSchema = ({ remainingBudget }) => {
  return Yup.object({
    ...campaignValidation,
    [START_DATE]: Yup.string().when(CAMPAIGN_OPTION, {
      is: campaignType => campaignType === PERFORMANCE_MAX_TYPE || campaignType === DISPLAY_TYPE,
      then: () => startDateValidation
    }),
    [END_DATE]: Yup.string().when(CAMPAIGN_OPTION, {
      is: campaignType => campaignType === PERFORMANCE_MAX_TYPE || campaignType === DISPLAY_TYPE,
      then: () => endDateValidation
    }),
    [BUDGET_DAILY]: Yup.number().when(CAMPAIGN_OPTION, {
      is: campaignType => campaignType === PERFORMANCE_MAX_TYPE || campaignType === DISPLAY_TYPE,
      then: () =>
        Yup.number().test({
          name: 'Daily budget validation based on number of days',

          test: (value, context) => {
            const startDate = context.parent[START_DATE]
            const endDate = context.parent[END_DATE]
            const days = getNumberOfDays(startDate, endDate)

            const maxDailyBudget = remainingBudget / days

            if (!value) {
              return context.createError({
                message: 'Daily budget is required',
                path: BUDGET_DAILY
              })
            }

            if (value > maxDailyBudget) {
              const formattedMaxDailyBudget = formatNumber(maxDailyBudget)

              return context.createError({
                message: t('maxDailyBudget', { formattedMaxDailyBudget }),
                path: BUDGET_DAILY
              })
            }

            return true
          }
        })
    })
  })
}

export const createCampaignPayload = ({ values, adAccountId, mediaOrder }) => {
  const isDisplayTypeCampaign = values[CAMPAIGN_OPTION] === DISPLAY_TYPE

  const { name: mediaOrderName } = mediaOrder

  const campaignName = `${mediaOrderName} - Google Ads - ${uuidv4().slice(0, 7)}`

  return {
    account: adAccountId,
    name: campaignName,
    status: 'active',
    date_start: formatDateToBE(values[START_DATE]),
    date_stop: formatDateToBE(values[END_DATE]),
    [CAMPAIGN_BUDGET]: {
      delivery_method: 2,
      period: 2,
      type: 2,
      [NAME]: `Budget ${campaignName}`,
      [AMOUNT_MICROS]: values[BUDGET_DAILY],
      explicitly_shared: false
    },
    // we create all display campaigns as manual_cpm bidding type by passing this field in payload
    ...(isDisplayTypeCampaign
      ? {
          manual_cpm: {}
        }
      : {
          [MAXIMIZE_CONVERSION_VALUE]: {
            [TARGET_ROAS]: !!values[TARGET_ROAS] ? values[TARGET_ROAS] : 0
          }
        }),
    // workaround due to managing all media create form on CAMPAIGN_OPTION
    ...defaultGoogleCampaignPayload({
      [CAMPAIGN_TYPE]: values[CAMPAIGN_OPTION]
    })
  }
}
