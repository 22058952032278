import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { parseISO } from 'date-fns'

import { SelectPeriodsContext } from '../../../../SelectPeriodsContext'

import { inventoryLastItemSelector } from '../../../../../../../../../../modules/selectors/mediaOrdersProducts'

import { getInventoryPeriod } from '../../../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/periodHelpers'
import {
  getEndAdjustedDate,
  getStartAdjustedDate
} from '../../../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/ProductSetupStep/ProductSetupFields/SelectPeriodsFields/BookingPeriodSelector/DatePickerPeriodSelector/helpers'

export const AVAILABLE_QUANTITY = 'availableQuantity'
export function useInventoryPeriods({ selectedPeriods, additionalAvailableDays }) {
  const { inventory, productPeriod, productStartPeriodDate } = useContext(SelectPeriodsContext)
  const inventoryLastItem = useSelector(inventoryLastItemSelector)

  // periodOptions are generated based on product period
  const periodOptions = useMemo(() => {
    if (productStartPeriodDate) {
      return inventory.map(
        ({ id, date_start, date_end, available_inventory, product_booked_quantity = 0, period_price }) => {
          // for case when Product was already purchased in Media order and we amend media order - the inventory
          // should be fulfilled with quantity of purchased periods.
          const availableQuantity = available_inventory + product_booked_quantity
          const hasQuantity = availableQuantity > 0
          const isDisabled = !hasQuantity
          // currently the code is comment out, uncomment if we need to support overdue periods
          // check if the period is under the creative deadline
          // const isCreativeDeadlineOverdue = new Date(date_start) < productStartDate
          // // set newDate time to 00:00:00 to compare only dates
          // const isDateAvailable = new Date(date_start).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)

          return {
            inventory: id,
            ...getInventoryPeriod(parseISO(date_start), productPeriod, isDisabled),
            date_start: date_start,
            date_end: date_end,
            disabled: isDisabled,
            [AVAILABLE_QUANTITY]: availableQuantity,
            // If inventory is > 0 and date is >=today but < creative deadline days, show this:
            // currently the code is comment out, uncomment if we need to support overdue periods
            // overdue: hasQuantity && isDateAvailable && isCreativeDeadlineOverdue,
            price: period_price
          }
        }
      )
    } else {
      return []
    }
  }, [productStartPeriodDate, inventory, productPeriod])

  // find the earliest period start date
  const earliestPeriod = periodOptions.reduce(
    (earliest, item) => {
      return new Date(item.date_start) < new Date(earliest.date_start) ? item : earliest
    },
    { date_start: new Date() }
  )

  const firstPeriod = getStartAdjustedDate(earliestPeriod?.date_start, additionalAvailableDays)
  const lastPeriod = getEndAdjustedDate(
    inventoryLastItem?.date_end || periodOptions[periodOptions.length - 1]?.date_start,
    additionalAvailableDays
  )

  const availablePeriods = useMemo(() => {
    const filteredAvailablePeriods = periodOptions.filter(period => {
      // filter out selected periods:
      const isSelected = selectedPeriods.find(selectedPeriod => {
        return selectedPeriod.inventory === period.inventory
      })

      // overdue are not available to select by user
      return !period.disabled && !period.overdue && !isSelected
    })
    return filteredAvailablePeriods

    // if (isEndDateSelection) {
    //   // when user selected the start date, we need to allow the end dates
    //   // the end dates should be the dates that are continuous to the selected start date
    //   // the continuous period is a period that starts the next day after the previous period ends
    //   const lastContinuousPeriod = findLastContinuousPeriod(filteredAvailablePeriods, selectedStartDate, productPeriod)
    //   const lastContinuousDate = lastContinuousPeriod?.date_end
    //
    //   return filteredAvailablePeriods.filter(period => {
    //     const startDate = new Date(selectedStartDate)
    //     const periodStartDate = new Date(period.date_start)
    //     const isContinuous = period.date_start === lastContinuousDate
    //     const isAfterSelectedStartDate = periodStartDate >= startDate
    //     return isContinuous || isAfterSelectedStartDate
    //   })
    // } else {
    //   return filteredAvailablePeriods
    // }
  }, [periodOptions, selectedPeriods])

  return {
    periodOptions,
    firstPeriod,
    lastPeriod,
    availablePeriods
  }
}
