import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Page from '../index'
import InfoBlock from '../../features/components/InfoBlock'
import MediaOrderSummaryContent from './MediaOrderSummaryContent'
import SkeletonMediaOrderSummaryContent from './MediaOrderSummaryContent/Skeleton'

import { getMediaOrder, clearMediaOrder } from '../../modules/actions/mediaOrders'
import { selectedControllerIdSelector } from '../../modules/selectors/app'
import { mediaOrderErrorSelector, mediaOrderWasLoadedSelector } from '../../modules/selectors/mediaOrders'

import { ROUTE_PARAMS } from '../../constants/routes'

import useContentTabsHeaderStyles from '../../features/components/ContentTabs/TabsHeader/contentTabsHeaderStyles'

const MediaOrderSummary = () => {
  const contentTabsHeaderClasses = useContentTabsHeaderStyles()

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { [ROUTE_PARAMS.mediaOrderId]: mediaOrderId } = useParams()

  const controllerId = useSelector(selectedControllerIdSelector)
  const mediaOrderWasLoaded = useSelector(mediaOrderWasLoadedSelector)
  const mediaOrderError = useSelector(mediaOrderErrorSelector)

  const mediaOrderDidLoading = mediaOrderWasLoaded || mediaOrderError

  useEffect(() => {
    dispatch(getMediaOrder(mediaOrderId))

    return () => {
      dispatch(clearMediaOrder())
    }
  }, [dispatch, mediaOrderId, controllerId])

  return (
    <Page
      helmetTitle="helmetTitle.MediaOrderSummary"
      mainClassName={contentTabsHeaderClasses.pageMain}
      className={contentTabsHeaderClasses.pageContainer}
    >
      {mediaOrderError ? (
        <InfoBlock title={t('Sorry, we can’t find that media order')} centered greyDescription>
          {t('You might not have access to it, or it may have been deleted')}
        </InfoBlock>
      ) : (
        <>{mediaOrderDidLoading ? <MediaOrderSummaryContent /> : <SkeletonMediaOrderSummaryContent />}</>
      )}
    </Page>
  )
}

export default MediaOrderSummary
