import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useStyles from './style'

function ProgressBar({ value, max, className, barColor }) {
  const classes = useStyles({ barColor })

  // if max is sent - calculate progress manually in other case just use value as ready progress
  let currentProgressWidth = max ? (value * 100) / max : value

  return (
    <div className={classnames('progressBar', classes.progressBar, className)}>
      <div className={classes.progressLine} style={{ width: `${currentProgressWidth}%` }} />
    </div>
  )
}

ProgressBar.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  barColor: PropTypes.string
}

export default ProgressBar
