import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import DatePickerOptimized from './DatePickerOptimized'
import ButtonIcon from '../../../../../../../../features/components/Buttons/ButtonIcon'
import FieldOptimized from './FieldOptimized'
import BookedField from './BookedField'
import { ReactComponent as CloseIcon } from '../../../../../../../../assets/icons/close-grey.svg'

import {
  BOOKED_QUANTITY,
  INVENTORY_LIST_DATA,
  INVENTORY_QUANTITY,
  INVENTORY_START_DATE
} from '../../../../../../MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'

import useStyles from './styles'

const InventoryRow = ({ formik, item, index, rowIndexInFocus, onRowClickHandler }) => {
  const { setFieldValue } = formik

  const classes = useStyles()

  const {
    [INVENTORY_START_DATE]: startDate,
    [INVENTORY_QUANTITY]: inventoryQuantity,
    [BOOKED_QUANTITY]: bookedQuantity
  } = item

  const removeRowHandler = useCallback(() => {
    // For optimization purposes, when we remove items from the beginning of the array,
    // to avoid shifting all the elements, we set the value to null
    setFieldValue(`${INVENTORY_LIST_DATA}[${index}]`, null)
  }, [index, setFieldValue])

  const rowClickHandler = useCallback(() => {
    onRowClickHandler(index)
  }, [onRowClickHandler, index])

  return (
    <div className={classes.fieldsRow} onClick={rowClickHandler} key={startDate}>
      <DatePickerOptimized formik={formik} index={index} rowIndexInFocus={rowIndexInFocus} />
      <FieldOptimized formik={formik} index={index} />
      <BookedField inventoryQuantity={Number(inventoryQuantity)} bookedQuantity={Number(bookedQuantity)} />
      <ButtonIcon onClick={removeRowHandler} className={classes.deleteRowButton} Icon={CloseIcon} />
    </div>
  )
}

InventoryRow.propTypes = {
  formik: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  rowIndexInFocus: PropTypes.number,
  onRowClickHandler: PropTypes.func.isRequired
}

export default InventoryRow
