import React from 'react'
import { useSelector } from 'react-redux'

import { AD_TEXT } from '../../../fields'

import { adSelector } from '../../../../../../modules/selectors/ads'

const TextSectionPreview = () => {
  const ad = useSelector(adSelector)

  const { [AD_TEXT]: text } = ad

  return <p>{text}</p>
}

export default TextSectionPreview
