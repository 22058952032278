import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR
// location lists
export const locationListsSelector = createSelector(
  ({ location }) => location.locationLists.results,
  results => results
)

export const locationListsNextSelector = createSelector(
  ({ location }) => location.locationLists.next,
  next => next
)

export const locationListsLoadingSelector = createSelector(
  ({ location }) => location.locationLists.isLoading,
  isLoading => isLoading
)

export const locationListsWasLoadedSelector = createSelector(
  ({ location }) => location.locationLists.wasLoaded,
  wasLoaded => wasLoaded
)

export const locationListsErrorSelector = createSelector(
  ({ location }) => location.locationLists.error,
  error => error
)

// location list
export const locationListSelector = createSelector(
  ({ location }) => location.locationList.data,
  data => data
)

export const locationListLoadingSelector = createSelector(
  ({ location }) => location.locationList.isLoading,
  isLoading => isLoading
)

// create location list
export const createLocationListSelector = createSelector(
  ({ location }) => location.createLocationList,
  createLocationList => createLocationList
)

export const createLocationListLoadingSelector = createSelector(
  ({ location }) => location.createLocationList.isLoading,
  isLoading => isLoading
)

export const createLocationListErrorSelector = createSelector(
  ({ location }) => location.createLocationList.error,
  error => error
)

// update location list
export const updateLocationListSelector = createSelector(
  ({ location }) => location.updateLocationList,
  updateLocationList => updateLocationList
)

export const updateLocationListLoadingSelector = createSelector(
  ({ location }) => location.updateLocationList.isLoading,
  isLoading => isLoading
)

export const updateLocationListErrorSelector = createSelector(
  ({ location }) => location.updateLocationList.error,
  error => error
)

// location addresses validation
export const locationAddressesValidationSelector = createSelector(
  ({ location }) => location.locationAddressesValidation.addresses,
  addresses => addresses
)

export const locationAddressesValidationLoadingSelector = createSelector(
  ({ location }) => location.locationAddressesValidation.isLoading,
  isLoading => isLoading
)

export const locationAddressesValidationErrorSelector = createSelector(
  ({ location }) => location.locationAddressesValidation.error,
  error => error
)

export const locationAddressesIsValidationSuccessSelector = createSelector(
  ({ location }) => location.locationAddressesValidation.isValidationSuccess,
  isValidationSuccess => isValidationSuccess
)
