import {
  PACKAGE_PENDING_APPROVAL,
  STATUS_ACTIVE,
  STATUS_PAUSED
} from '../../../forms/Multiplatform/MediaPackageForms/MediaPackageCreate/MediaPackageCreateForm/fields'

export const mediaPackagesFiltersList = [
  {
    value: STATUS_ACTIVE,
    label: 'Active'
  },
  {
    value: STATUS_PAUSED,
    label: 'Paused'
  },
  {
    value: PACKAGE_PENDING_APPROVAL,
    label: 'Pending Approval'
  }
]
