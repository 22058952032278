export const USER_OPTION = 'user_option'
export const SEND_TO_PLATFORM_USER = 'send_to_platform_user'
export const PLATFORM_USER_RECEIVER = 'amendment_receiver_user' // Send to user
export const SEND_BY_EMAIL = 'send_by_email'
export const EMAIL_RECEIVER = 'amendment_receiver_email' // Send to email

export const receiverOptions = [
  { label: 'User', value: SEND_TO_PLATFORM_USER },
  { label: 'Email', value: SEND_BY_EMAIL }
]

export const initialValues = {
  [USER_OPTION]: SEND_TO_PLATFORM_USER,
  [PLATFORM_USER_RECEIVER]: '',
  [EMAIL_RECEIVER]: ''
}
