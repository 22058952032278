const brandShoppers = [
  'Alpro',
  'Batchelors',
  'Birds Eye',
  'Cadbury',
  'Colgate',
  'Dove',
  'Duchy',
  "Ella's Kitchen",
  'Felix',
  'Garnier',
  'Gillette',
  'Glade',
  'Heinz',
  'Innocent',
  "Kellogg's",
  "L'Oreal",
  'Lindt',
  'Maybelline',
  "McVitie's",
  'Nestle',
  'Nivea',
  'Pampers',
  'Pedigree',
  'Princes',
  'Rimmel London',
  'Schwartz',
  'Simple',
  'Sure',
  'Twinings',
  'Walkers',
  'Whiskas'
]

const categoryShoppers = [
  'Christmas Lights & Decorations',
  'Tools',
  'Building & Hardware',
  'Garden',
  'Outdoor Living',
  'Kitchen',
  'Bathroom & Plumbing',
  'Curtains & Blinds',
  'Paint & Wallpaper',
  'Flooring',
  'Storage & Cleaning',
  'Lighting & Electrical',
  'Indoor Living',
  'Smart Home',
  'Hire Shop'
]

const lifestyleShoppers = [
  'Affluent',
  'Convenience',
  'Healthy',
  'Kids food',
  'Offer Lovers',
  'Premium range buyers',
  'Price Sensitive',
  'Traditional'
]

export const brandShoppersList = brandShoppers.map(brand => ({ value: brand, label: brand }))
export const categoryShoppersList = categoryShoppers.map(category => ({ value: category, label: category }))
export const lifestyleShoppersList = lifestyleShoppers.map(lifestyle => ({ value: lifestyle, label: lifestyle }))

export const destinationsList = [
  { label: 'Bangkok - Don Mueang (DMK)', value: 'DMK' },
  { label: 'Bangkok - Suvarnabhumi (BKK)', value: 'BKK' },
  { label: 'Jakarta (CGK)', value: 'CGK' },
  { label: 'Kuala Lumpur - (KUL)', value: 'KUL' },
  { label: 'Manila (MNL)', value: 'MNL' },
  { label: 'Penang (PEN)', value: 'PEN' },
  { label: 'Singapore - (SIN)', value: 'SIN' }
]
