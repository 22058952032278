import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { getIn } from 'formik'
import PropTypes from 'prop-types'

import MinimisedSectionNew from '../../../../../../components/MinimisedSectionNew'
import MinimisedSectionHeader from '../../../../../../components/MinimisedSectionNew/MinimisedSectionHeader'
import Field from '../../../../../../components/Form/Field'

import { keys } from '../../../../../../helpers/common'
import { isStepFieldsValid } from '../../../../../../features/components/Forms/StepForm/helpers'

import { EMAIL, FIRST_NAME, LAST_NAME } from '../../../fields'

import useStyles from './styles'

const ExternalRecipientSection = ({ formik, itemPath, index, selected, onSelect, onClose, onDelete, allowDelete }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { errors, touched } = formik

  const recipientIndexTitle = t('recipientIndexTitle', { index: index + 1 })
  const sectionError = getIn(errors, itemPath)
  const sectionTouched = getIn(touched, itemPath)

  // determine if section has errors only by checking touched fields
  const isSectionValid = sectionTouched && sectionError ? isStepFieldsValid(sectionError, keys(sectionTouched)) : true

  const onClickHandler = useCallback(() => {
    if (selected) {
      onClose()
    } else {
      onSelect()
    }
  }, [selected, onSelect, onClose])

  return (
    <MinimisedSectionNew
      headerContent={
        <MinimisedSectionHeader
          className={classes.recipientsHeader}
          titleClassName={classes.recipientsHeaderTitle}
          title={recipientIndexTitle}
          onDelete={allowDelete ? onDelete : undefined}
          deleteBtnText={t('Remove recipient')}
        />
      }
      isOpened={selected}
      onHeaderClick={onClickHandler}
      hasError={!isSectionValid}
    >
      <Field formik={formik} name={`${itemPath}.${FIRST_NAME}`} placeholder="First Name" />
      <hr className={classes.horizontalRow} />
      <Field formik={formik} name={`${itemPath}.${LAST_NAME}`} placeholder="Last Name" />
      <hr className={classes.horizontalRow} />
      <Field formik={formik} name={`${itemPath}.${EMAIL}`} placeholder="Email" />
    </MinimisedSectionNew>
  )
}

ExternalRecipientSection.propTypes = {
  formik: PropTypes.object.isRequired,
  itemPath: PropTypes.string.isRequired,
  index: PropTypes.number,
  selected: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  allowDelete: PropTypes.bool,
  onDelete: PropTypes.func.isRequired
}

export default ExternalRecipientSection
