import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'react-jss'
import classnames from 'classnames'

import RadioBlock from '../../../components/Form/RadioBlock'
import MultiSelectBox from '../../../features/components/Form/MultiSelectBox'
import CheckboxBlock from '../../../features/components/Form/CheckboxBlock'
import Spoiler from '../../../components/Spoiler'

import {
  AUDIENCES_OPTION,
  CUSTOM_AUDIENCES,
  CUSTOM_AUDIENCES_EXCLUSION,
  CUSTOM_AUDIENCES_EXCLUSION_CHECKED
} from './fields'
import { OPTION_NO, OPTION_YES } from '../../../constants/forms'

import useStyles from './styles'

const CustomAudiencesFields = ({ formik, formattedAudiences, audiencesIsLoading }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()

  const { values, errors, touched, setFieldValue } = formik

  const customAudiencesError = errors[CUSTOM_AUDIENCES]
  const customAudiencesTouched = touched[CUSTOM_AUDIENCES]
  const customAudiencesExclusionError = errors[CUSTOM_AUDIENCES_EXCLUSION]
  const customAudiencesExclusionTouched = touched[CUSTOM_AUDIENCES_EXCLUSION]

  return (
    <>
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_audiences_option_yes"
        name={AUDIENCES_OPTION}
        value={OPTION_YES}
        selectedValue={values[AUDIENCES_OPTION]}
        label={t('Select target audience(s)')}
        error={customAudiencesError}
        touched={customAudiencesTouched}
      >
        <p className={classnames(classes.descriptionText, classes.radiobuttonDescriptionText)}>
          {t('Select one or more audiences to target.')}
        </p>
        <MultiSelectBox
          className={classes.selectAudienceInput}
          placeholder={t('Add an audience')}
          options={formattedAudiences}
          name={CUSTOM_AUDIENCES}
          value={values[CUSTOM_AUDIENCES]}
          isLoading={audiencesIsLoading}
          setFieldValue={setFieldValue}
        />
      </RadioBlock>
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_audiences_option_no"
        name={AUDIENCES_OPTION}
        value={OPTION_NO}
        selectedValue={values[AUDIENCES_OPTION]}
        label={t('No audience targeting')}
      />
      <Spoiler title={t('More targeting options')} initialOpen={values[CUSTOM_AUDIENCES_EXCLUSION_CHECKED]}>
        <CheckboxBlock
          id="checkbox_custom_audiences_exclusions"
          title={t('Exclude an audience')}
          checked={values[CUSTOM_AUDIENCES_EXCLUSION_CHECKED]}
          onCheck={() => {
            setFieldValue(CUSTOM_AUDIENCES_EXCLUSION_CHECKED, !values[CUSTOM_AUDIENCES_EXCLUSION_CHECKED])
          }}
          error={customAudiencesExclusionError}
          touched={customAudiencesExclusionTouched}
          withBorder
          isLarge
          titleColor={theme.brandPrimary}
        >
          <p className={classes.descriptionText}>{t('Select one or more audiences to exclude.')}</p>
          <MultiSelectBox
            className={classes.selectAudienceInput}
            placeholder={t('Exclude an audience')}
            options={formattedAudiences}
            value={values[CUSTOM_AUDIENCES_EXCLUSION]}
            name={CUSTOM_AUDIENCES_EXCLUSION}
            isLoading={audiencesIsLoading}
            setFieldValue={setFieldValue}
          />
        </CheckboxBlock>
      </Spoiler>
    </>
  )
}

CustomAudiencesFields.propTypes = {
  formik: PropTypes.object,
  formattedAudiences: PropTypes.array,
  audiencesIsLoading: PropTypes.bool
}

export default CustomAudiencesFields
