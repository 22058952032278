import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import Form from '../../../../../components/Form'
import Field from '../../../../../components/Form/Field'
import RadioBlock from '../../../../../components/Form/RadioBlock'
import FieldRow from '../../../../../features/components/Form/FieldsSection/FieldRow'
import FieldsSection from '../../../../../features/components/Form/FieldsSection'

import { formatOptionsList } from '../../../../../features/formatters'

import { getAgencyMembers } from '../../../../../modules/actions/agencyTeam'
import {
  clearCreateInvite,
  clearCreateMember,
  createInvite,
  createMember
} from '../../../../../modules/actions/selfAccountTeam'
import {
  inviteCreateErrorSelector,
  inviteCreateIsLoadingSelector,
  inviteWasCreatedSelector,
  memberCreateErrorSelector,
  memberCreateIsLoadingSelector,
  membersSelector,
  memberWasCreatedSelector
} from '../../../../../modules/selectors/selfAccountTeam'
import {
  getCurrentUserSelector,
  selectedAgencyDataSelector,
  selectedSelfAccountSelector
} from '../../../../../modules/selectors/app'
import { agencyMembersSelector } from '../../../../../modules/selectors/agencyTeam'

import { ADMIN, MANAGER } from '../../../../../constants/permissions'
import { TEAM_MEMBER_CREATE } from '../../../../../constants/forms'
import {
  initialValues,
  USER,
  EMAIL,
  AGENCY,
  ADMIN_CHOICE_OPTION,
  ADMIN_CHOICE_ADD_AGENCY_MEMBER,
  ADMIN_CHOICE_ADD_USER_BY_EMAIL,
  roleOptions,
  ROLE
} from './fields'
import { getValidationSchema } from './validation'

import useCommonStyles from '../../../../../styles/common/stepForms'
import useDrawerFormStyles from '../../../../../styles/common/drawerForms'
import useStyles from './styles'

const NewUserCreateForm = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const drawerFormClasses = useDrawerFormStyles()

  const currentUser = useSelector(getCurrentUserSelector)
  const agencyMembers = useSelector(agencyMembersSelector)
  const membersLists = useSelector(membersSelector)
  const selfAccount = useSelector(selectedSelfAccountSelector)
  const selectedAgency = useSelector(selectedAgencyDataSelector)

  const { agencies } = currentUser

  const isUserAdminOfAgency = selectedAgency?.role === ADMIN

  const handleSubmit = values => {
    const { user, email, role } = values

    isUserAdminOfAgency && values[ADMIN_CHOICE_OPTION] === ADMIN_CHOICE_ADD_AGENCY_MEMBER
      ? dispatch(createMember({ user, account: selfAccount, role: MANAGER }))
      : dispatch(createInvite({ email, account: selfAccount, role }))
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getValidationSchema(isUserAdminOfAgency),
    onSubmit: handleSubmit
  })

  const { values, setFieldValue } = formik

  const allowCreateMember = isUserAdminOfAgency && values[ADMIN_CHOICE_OPTION] === ADMIN_CHOICE_ADD_AGENCY_MEMBER

  // todo refactor it under 2 separate StepForms with Routes selection i.e. memberCreate/inviteCreate
  const errorSelector = allowCreateMember ? memberCreateErrorSelector : inviteCreateErrorSelector
  const loadingSelector = allowCreateMember ? memberCreateIsLoadingSelector : inviteCreateIsLoadingSelector
  const successSubmitSelector = allowCreateMember ? memberWasCreatedSelector : inviteWasCreatedSelector

  const successSubmit = useSelector(successSubmitSelector)

  const selectedAgencyId = values[AGENCY].toString()

  const formattedAgencies = formatOptionsList({
    list: agencies,
    valueName: 'id',
    labelName: 'name'
  })

  const membersIds = membersLists.map(item => item.user)
  const filteredAgencyMembers = agencyMembers.filter(item => !membersIds.includes(item.user))

  const formattedAgencyMembers = formatOptionsList({
    list: filteredAgencyMembers,
    valueName: 'user',
    labelName: 'full_name'
  })

  const clearCreateNewUserHandler = useCallback(() => {
    dispatch(clearCreateMember())
    dispatch(clearCreateInvite())
  }, [dispatch])

  useEffect(() => {
    if (agencies.length && !selectedAgencyId) {
      setFieldValue(AGENCY, agencies[0].id)
    }
  }, [agencies, selectedAgencyId, setFieldValue])

  useEffect(() => {
    if (selectedAgencyId) {
      dispatch(getAgencyMembers({ agency: selectedAgencyId }))
    }
  }, [dispatch, selectedAgencyId])

  return (
    <Form
      formName={TEAM_MEMBER_CREATE}
      title={t('Add new member to the team')}
      formik={formik}
      errorSelector={errorSelector}
      isLoadingSelector={loadingSelector}
      successSubmit={successSubmit}
      clearHandler={clearCreateNewUserHandler}
      submitText={t('Invite')}
    >
      {isUserAdminOfAgency ? (
        <div className={commonClasses.stepBody}>
          <section className={drawerFormClasses.section}>
            <h4 className={drawerFormClasses.sectionTitle}>{t("New member's email")}</h4>
            <RadioBlock
              setFieldValue={setFieldValue}
              id="radio_admin_choice_agency_member"
              name={ADMIN_CHOICE_OPTION}
              value={ADMIN_CHOICE_ADD_AGENCY_MEMBER}
              selectedValue={values[ADMIN_CHOICE_OPTION]}
              label={t('Add an agency member')}
            >
              {agencies.length > 1 && (
                <Field
                  formik={formik}
                  name={AGENCY}
                  placeholder="Select an Agency"
                  options={formattedAgencies}
                  isSearchable={false}
                />
              )}
              <Field
                formik={formik}
                name={USER}
                placeholder="Select User"
                options={formattedAgencyMembers}
                isSearchable={false}
              />
            </RadioBlock>
            <RadioBlock
              setFieldValue={setFieldValue}
              id="radio_admin_choice_add_by_email"
              name={ADMIN_CHOICE_OPTION}
              value={ADMIN_CHOICE_ADD_USER_BY_EMAIL}
              selectedValue={values[ADMIN_CHOICE_OPTION]}
              label={t('Add a client or guest user to just this ad account')}
            >
              <Field formik={formik} name={EMAIL} placeholder="Enter an Email" />
            </RadioBlock>
          </section>
        </div>
      ) : (
        <div className={commonClasses.stepBody}>
          <FieldsSection title={t('User email')}>
            <FieldRow
              title="Email address"
              description="This user will receive an email inviting them to this account."
            >
              <Field
                formik={formik}
                name={EMAIL}
                label="email address"
                autoComplete="off"
                placeholder={t('email address')}
              />
            </FieldRow>
            <FieldRow
              title={t('Role')}
              description={
                <>
                  <div className={classes.roleDescription}>
                    <b>{t('Admin')}</b>
                    {t(': Full access')}
                  </div>
                  <div className={classes.roleDescription}>
                    <b>{t('Manager')}</b>
                    {t(': Full campaign management access')}
                  </div>
                  <div className={classes.roleDescription}>
                    <b>{t('Reporting')}</b>
                    {t(': Reporting only')}
                  </div>
                </>
              }
            >
              <Field placeholder={t('Select Role')} formik={formik} name={ROLE} options={roleOptions} />
            </FieldRow>
          </FieldsSection>
        </div>
      )}
    </Form>
  )
}

export default NewUserCreateForm
