import { concat } from '../../helpers/common'

const MODULE_NAME = 'USERS/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_USERS = concat(MODULE_NAME, 'GET_USERS')
export const GET_USERS_SUCCESS = concat(MODULE_NAME, 'GET_USERS_SUCCESS')
export const GET_USERS_FAILURE = concat(MODULE_NAME, 'GET_USERS_FAILURE')
export const CLEAR_USERS = concat(MODULE_NAME, 'CLEAR_USERS')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

export function getUsers() {
  return { type: GET_USERS }
}

export function getUsersSuccess(usersData) {
  return { type: GET_USERS_SUCCESS, usersData }
}

export function getUsersFailure(error) {
  return { type: GET_USERS_FAILURE, error }
}

export function clearUsers() {
  return { type: CLEAR_USERS }
}
