import { VARIABLES } from '../../../fields'

export const getVariablesSectionInitialValues = editProductData => {
  const initialProductVariablesIds = editProductData[VARIABLES] || []
  const formattedInitialProductVariables = initialProductVariablesIds.map(item => {
    return {
      value: item.id,
      label: item.name
    }
  })

  return {
    [VARIABLES]: formattedInitialProductVariables
  }
}
