import React from 'react'
import { getIn } from 'formik'
import PropTypes from 'prop-types'

import { ReactComponent as TrashIcon } from '../../../../../../../../../../../assets/icons/trash.svg'

import Counter from '../../../../../../../../../../../components/Counter'
import ButtonIcon from '../../../../../../../../../../../features/components/Buttons/ButtonIcon'

import { QUANTITY } from '../../../../../../../../fields'

import useStyles from './styles'

function ProductQuantity({
  formik,
  minQuantity,
  maxQuantity,
  productsQuantity,
  productPath,
  onProductRemove,
  onQuantityChange,
  className
}) {
  const { values, setFieldValue } = formik
  const classes = useStyles()

  const handleQuantityChange = ({ newValue }) => {
    const productValue = getIn(values, productPath)

    // decrease quantity
    setFieldValue(productPath, {
      ...productValue,
      [QUANTITY]: newValue
    })
    // callback
    onQuantityChange && onQuantityChange(newValue)
  }

  // show delete button only when maximum amount of products is one and when it's not required
  const showDeleteOnly = maxQuantity === 1 && minQuantity === 0

  return showDeleteOnly ? (
    <ButtonIcon className={classes.btnDelete} onClick={onProductRemove} Icon={TrashIcon} />
  ) : (
    <Counter
      className={className}
      value={productsQuantity}
      onDelete={onProductRemove}
      onValueDecrease={handleQuantityChange}
      onValueIncrease={handleQuantityChange}
      callbackProps={{ productPath }}
      minCount={minQuantity}
      maxCount={maxQuantity}
    />
  )
}

ProductQuantity.propTypes = {
  className: PropTypes.string,
  formik: PropTypes.object.isRequired,
  minQuantity: PropTypes.number,
  maxQuantity: PropTypes.number,
  productsQuantity: PropTypes.number,
  productPath: PropTypes.string,
  onProductRemove: PropTypes.func,
  onQuantityChange: PropTypes.func
}

export default ProductQuantity
