import { createStyles } from '../../../styles/helpers'

import { red } from '../../../styles/const/colors'

const useStyles = createStyles({
  error: {
    color: red,
    fontSize: 11,
    paddingTop: 5,
    paddingLeft: 5,
    lineHeight: '14px',
    textAlign: 'left'
  }
})

export default useStyles
