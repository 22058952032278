import { createStyles } from '../../../styles/helpers'
import { mediumGrey } from '../../../styles/const/colors'
import { defaultBorderRadius } from '../../../styles/const/common'

export default createStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: '16px'
  },
  block: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    border: `1px solid ${mediumGrey}`,
    borderRadius: defaultBorderRadius
  },
  col: {
    padding: '12px 16px'
  },
  label: {
    extend: 'col',
    borderRight: `1px solid ${mediumGrey}`
  },
  value: {
    extend: 'col'
  }
})
