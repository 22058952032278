import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import StepForm from '../../../../../features/components/Forms/StepForm'
import DetailsStep from './Steps/DetailsStep'
import AudienceStep from './Steps/AudienceStep'
import ConversionGoalStep from './Steps/ConversionGoalStep'
import PlacementsStep from './Steps/PlacementsStep'

import { useProgressItems } from './useProgressItems'
import { transformValuesToBE } from './formatters'
import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'
import {
  getIsCampaignBudgetExist,
  getShowPromotedObjectFields,
  getShowTargetCostCapField
} from '../../lineItemsHelpers'

import { clearCreateLineItem, createLineItem } from '../../../../../modules/actions/lineItems'
import {
  lineItemCreatedDataSelector,
  lineItemCreateErrorSelector,
  lineItemCreateIsLoadingSelector,
  lineItemWasCreatedSelector
} from '../../../../../modules/selectors/lineItems'
import { selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'
import { campaignSelector, campaignWasLoadedSelector } from '../../../../../modules/selectors/campaigns'

import { LINE_ITEM_FACEBOOK_CREATE } from '../../../../../constants/forms'
import { FACEBOOK_PLATFORM } from '../../../../../constants/selectLists/platformList'
import { getInitialValues, validationSchema } from './fields'
import { ROUTE_PARAMS } from '../../../../../constants/routes'

const LineItemFacebookCreateForm = ({ onSuccessSubmit }) => {
  const dispatch = useDispatch()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const lineItemWasCreated = useSelector(lineItemWasCreatedSelector)
  const { id: createdLineItemId } = useSelector(lineItemCreatedDataSelector)
  const campaignWasLoaded = useSelector(campaignWasLoadedSelector)
  const campaign = useSelector(campaignSelector)
  const { objective: campaignObjective, bidding_type: campaignBiddingType } = campaign

  const isCampaignBudgetExist = useMemo(() => getIsCampaignBudgetExist(campaign), [campaign])

  const showPromotedObjectFields = useMemo(() => getShowPromotedObjectFields(campaignObjective), [campaignObjective])
  const showTargetCostCapField = useMemo(
    () =>
      getShowTargetCostCapField({
        campaignObjective,
        campaignBiddingType,
        lineItemBiddingType: ''
      }),
    [campaignObjective, campaignBiddingType]
  )

  const showConversionGoalStep = useMemo(() => {
    return showPromotedObjectFields || showTargetCostCapField
  }, [showPromotedObjectFields, showTargetCostCapField])

  const progressItems = useProgressItems({ showConversionGoalStep })

  const onSubmit = useCallback(
    values => {
      const lineItemData = transformValuesToBE({
        values,
        selectedAdAccountId,
        showPromotedObjectFields,
        showTargetCostCapField,
        includeBudget: !isCampaignBudgetExist,
        campaign
      })

      dispatch(createLineItem(lineItemData, FACEBOOK_PLATFORM))
    },
    [dispatch, selectedAdAccountId, isCampaignBudgetExist, campaign, showTargetCostCapField, showPromotedObjectFields]
  )

  const initialValues = useMemo(() => getInitialValues(campaignObjective), [campaignObjective])

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema({ includeBudget: !isCampaignBudgetExist, showTargetCostCapField }),
    onSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const steps = useMemo(
    () => [
      {
        component: <DetailsStep showBudgetFormRow={!isCampaignBudgetExist} />,
        show: true
      },
      {
        component: <AudienceStep />,
        show: true
      },
      {
        component: <ConversionGoalStep />,
        show: showConversionGoalStep
      },
      {
        component: <PlacementsStep />,
        show: true
      }
    ],
    [isCampaignBudgetExist, showConversionGoalStep]
  )

  const clearSubmitHandler = useCallback(() => {
    dispatch(clearCreateLineItem())
  }, [dispatch])

  const onSuccessSubmitHandler = useCallback(() => {
    onSuccessSubmit &&
      onSuccessSubmit({
        [ROUTE_PARAMS.lineItemId]: createdLineItemId
      })
  }, [onSuccessSubmit, createdLineItemId])

  return (
    <StepForm
      formName={LINE_ITEM_FACEBOOK_CREATE}
      steps={steps}
      formik={purifiedFormik}
      isLoadingSelector={lineItemCreateIsLoadingSelector}
      errorSelector={lineItemCreateErrorSelector}
      successSubmit={lineItemWasCreated}
      onSuccessSubmit={onSuccessSubmitHandler}
      clearSubmitHandler={clearSubmitHandler}
      isInitialDataLoading={!campaignWasLoaded}
      // form progress
      formProgressItems={progressItems}
    />
  )
}

export default LineItemFacebookCreateForm
