import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { formatOptionsList } from '../../../../../../features/formatters'

import { choicesPixelsSelector } from '../../../../../../modules/selectors/choices'
import { PIXEL_CHOICE } from '../../../fields'

export default function usePreselectPixel({ selectedPixel, setFieldValue }) {
  const pixels = useSelector(choicesPixelsSelector)

  const formattedPixels = useMemo(
    () =>
      formatOptionsList({
        list: pixels,
        labelName: 'name',
        valueName: 'id'
      }),
    [pixels]
  )

  useEffect(() => {
    const isExistingPixelValid = formattedPixels.find(item => item.value === selectedPixel)
    if (formattedPixels.length && (!selectedPixel || !isExistingPixelValid)) {
      setFieldValue(PIXEL_CHOICE, formattedPixels[0].value)
    }
  }, [formattedPixels, selectedPixel, setFieldValue])
}
