import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../components/Button'
import MultiInputFieldBox from '../../../../../features/components/Form/MultiInputFieldBox'

import { URL_CONTAINS } from '../fields'

import useStyles from '../../../../../styles/common/stepForms'
import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

const PagesStep = ({ formik, handleStepChange }) => {
  const { t } = useTranslation()

  const classes = useStyles()
  const drawerFormClasses = useDrawerFormStyles()

  const { values, setFieldValue } = formik

  const selectedWords = values[URL_CONTAINS]

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create an audience')}</div>
      <div className={classes.stepTitle}>{t('Which page(s)?')}</div>
      <div className={classes.stepBody}>
        <section className={drawerFormClasses.section}>
          <h4 className={drawerFormClasses.sectionTitle}>{t('Visited page URLs')}</h4>
          <p>
            {t(
              'Website visitors who have visited any page with the following text in the URL will be added to the audience:'
            )}
          </p>
          <MultiInputFieldBox
            setFieldValue={setFieldValue}
            value={selectedWords}
            name={URL_CONTAINS}
            label={t('URL CONTAINS')}
            id={'url_contains_field'}
          />
        </section>
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" className="dark" onClick={() => handleStepChange()}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default PagesStep
