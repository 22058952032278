import { v4 as uuidv4 } from 'uuid'

import { BUSINESS_NAME } from '../../../../ReusableFormFields/LineItemForms/fields'
import { CALL_TO_ACTION, LEARN_MORE } from '../../../../ReusableFormFields/CallToActionFields/fields'
import {
  CLICK_THROUGH_LINK,
  DESCRIPTIONS_LIST,
  HEADLINES_LIST,
  initialGoogleFile,
  LONG_HEADLINES_LIST,
  NAME,
  STATUS,
  VIDEO_ADS_URL
} from '../../fields'
import { PRODUCT_DATA, PRODUCT_ID } from '../../../../ReusableFormFields/ProductFields/fields'
import { initialLink } from '../../../../Facebook/AdForms/fields'

export const MEDIA_WIDTH_PIXELS = 'width_pixels'
export const MEDIA_HEIGHT_PIXELS = 'height_pixels'
export const ASSET_IMAGE_LIST = 'asset_image_list'

const getTextList = (length, initialValue = '') => {
  return Array.from({ length }, () => ({ text: initialValue, id: uuidv4() }))
}

export const initialValues = {
  [NAME]: '',
  [CLICK_THROUGH_LINK]: initialLink,
  [BUSINESS_NAME]: '',
  [HEADLINES_LIST]: getTextList(3, ''),
  [DESCRIPTIONS_LIST]: getTextList(2, ''),
  [LONG_HEADLINES_LIST]: getTextList(2, ''),
  [ASSET_IMAGE_LIST]: [initialGoogleFile],
  [VIDEO_ADS_URL]: getTextList(1, ''),
  [CALL_TO_ACTION]: LEARN_MORE,
  [STATUS]: 'paused',
  [PRODUCT_ID]: '',
  [PRODUCT_DATA]: {}
}
