import { ENDPOINTS, WEB_SOCKET_ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'
import { madeSocketRequest } from '../../helpers/api/webSocket'

// PLOP_APPEND_PATTERN_ANCHOR

export function getMediaOrdersBookingsAsCsvService({ params, onSocketOpen }) {
  return madeSocketRequest(
    'post',
    {
      url: WEB_SOCKET_ENDPOINTS.bookedMediaCSV,
      params
    },
    { onSocketOpen }
  )
}

export function updateBookingMediaFileService(
  id,
  {
    // destructure requirementFileId from data, because this variable is used only for Skeleton representation,
    // and should not be sent to the server
    // todo think about handling Skeleton in a different way
    requirementFileId,
    ...data
  }
) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.bookingMediaUploads}${id}/`
  })
}

export function getBookingMediaFilesService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.bookingRecentlyMediaUploads
  })
}

export function uploadBookingMediaFileService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.bookingMediaUploads
  })
}

export function deleteBookingMediaFileService(id, params) {
  return madeRequest('DELETE', {
    params,
    url: `${ENDPOINTS.bookingMediaUploads}${id}/`
  })
}

export function getOrderBookedMediaService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.bookedMedia
  })
}

export function getBookedMediaReportService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.bookedMediaReport
  })
}

export function getBookedRevenueReportService(params) {
  return madeRequest('POST', {
    data: params,
    url: ENDPOINTS.bookedRevenueReport
  })
}

export function getPeriodBookedMediaReportService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.periodBookedMediaReport
  })
}

export function getInstallationBookedMediaReportService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.installationBookedMediaReport
  })
}

export function getInstallationBookedMediaReportAsCsvService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.installationBookedMediaReportExportCsv
  })
}

export function bulkUpdateBookingMediaFileService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.bookingMediaBulkUploads
  })
}

export function bulkUpdateBookedMediaService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.bookingMediaBulkUpdate
  })
}

export function getBookedMediaService({ id }) {
  return madeRequest('GET', {
    url: `${ENDPOINTS.bookedMedia}${id}/`
  })
}

export function getBrandRevenueReportService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.brandRevenueReport
  })
}
