import React, { useCallback } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import Form from '../../../../../../components/Form'
import BrandPageCreateFormContent from './BrandPageCreateFormContent'

import {
  createCategoryPageErrorSelector,
  createCategoryPageIsLoadingSelector,
  categoryPageWasCreatedSelector
} from '../../../../../../modules/selectors/mediaOrders'
import { selectedControllerIdSelector, selectedSelfAccountDataSelector } from '../../../../../../modules/selectors/app'
import { clearCreateCategoryPage, createCategoryPage } from '../../../../../../modules/actions/mediaOrders'

import { CATEGORY_PAGE_CREATE } from '../../../../../../constants/forms'
import { initialValues } from './fields'
import { transformValuesToBE, validationSchema } from '../../fields'

const BrandPageCreateForm = () => {
  const dispatch = useDispatch()

  const createCategoryPageWasCreated = useSelector(categoryPageWasCreatedSelector)
  const { id: selectedSelfAccountId } = useSelector(selectedSelfAccountDataSelector)
  const selectedSelfAccountControllerId = useSelector(selectedControllerIdSelector)

  const onSubmit = useCallback(
    values => {
      const transformedValues = transformValuesToBE(values)

      dispatch(
        createCategoryPage({
          ...transformedValues,
          account: selectedSelfAccountId,
          controller: selectedSelfAccountControllerId
        })
      )
    },
    [dispatch, selectedSelfAccountId, selectedSelfAccountControllerId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const clearCreateCategoryPageHandler = useCallback(() => {
    dispatch(clearCreateCategoryPage())
  }, [dispatch])

  return (
    <Form
      formName={CATEGORY_PAGE_CREATE}
      formik={formik}
      successSubmit={createCategoryPageWasCreated}
      clearHandler={clearCreateCategoryPageHandler}
      errorSelector={createCategoryPageErrorSelector}
      isLoadingSelector={createCategoryPageIsLoadingSelector}
    >
      <BrandPageCreateFormContent formik={formik} />
    </Form>
  )
}

export default BrandPageCreateForm
