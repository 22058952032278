import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import DateRange from '../../../features/components/DateRange'
import ErrorMessage from '../../../components/Form/ErrorMessage'

import { getFormikFieldError } from '../../../helpers/errors'

const StartEndDateRangeFields = ({ formik, startDateName, endDateName, ...props }) => {
  const { errors, status, setValues, values, touched } = formik

  const startDate = values[startDateName]
  const endDate = values[endDateName]

  const dateRangeData = useMemo(() => {
    // object with startDate and endDate
    return { startDate, endDate }
  }, [startDate, endDate])

  const dateStartError = getFormikFieldError(errors, status, startDateName).error
  const dateEndError = getFormikFieldError(errors, status, endDateName).error

  const showErrorMessage = (touched[startDateName] || touched[endDateName]) && (dateStartError || dateEndError)

  const handleDateRangeChange = useCallback(
    rangeValues => {
      setValues({
        ...values,
        [startDateName]: rangeValues.startDate,
        [endDateName]: rangeValues.endDate
      })
    },
    [setValues, endDateName, startDateName, values]
  )

  return (
    <>
      <DateRange onDateRangeChange={handleDateRangeChange} dateRangeData={dateRangeData} {...props} />
      {showErrorMessage && <ErrorMessage error="Date range required" />}
    </>
  )
}

export default StartEndDateRangeFields

StartEndDateRangeFields.propTypes = {
  formik: PropTypes.object.isRequired,
  startDateName: PropTypes.string.isRequired,
  endDateName: PropTypes.string.isRequired
}
