import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getIn, setIn } from 'formik'

import Checkbox from '../../../../components/Form/Checkbox'

import useStyles from './styles'

const CheckboxGroup = ({
  values,
  setFieldValue,
  setValues,
  title,
  options,
  showSelectAllButton = true,
  isLarge = true,
  className,
  disabled
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [areAllSelected, setAreAllSelected] = useState(false)

  const selectAllHandler = () => {
    let valuesToSet = {}

    options.forEach(({ name }) => {
      valuesToSet = setIn(valuesToSet, name, !areAllSelected)
    })

    setValues({ ...values, ...valuesToSet })
  }

  useEffect(() => {
    let hasNotSelected = false

    options.forEach(({ name }) => {
      const value = getIn(values, name)
      if (!value) {
        hasNotSelected = true
      }
    })

    if (hasNotSelected) {
      setAreAllSelected(false)
    } else {
      setAreAllSelected(true)
    }
  }, [values, options])

  return (
    <div className={classnames(classes.wrapper, className)}>
      <div className={classes.header}>
        {title && <h4 className={classes.title}>{title}</h4>}
        {showSelectAllButton && (
          <div className={classes.selectController} onClick={selectAllHandler}>
            {t(areAllSelected ? 'Deselect all' : 'Select all')}
          </div>
        )}
      </div>
      {options.map(({ name, title, titleColor, disabled: disabledOption }) => (
        <Checkbox
          disabled={disabled || disabledOption}
          key={name}
          id={`checkbox_${name}`}
          title={t(title)}
          isLarge={isLarge}
          className={classes.checkbox}
          titleColor={titleColor}
          checked={getIn(values, name)}
          onCheck={() => {
            setFieldValue(name, !getIn(values, name))
          }}
        />
      ))}
    </div>
  )
}

CheckboxGroup.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  title: PropTypes.string,
  showSelectAllButton: PropTypes.bool,
  isLarge: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool
}

export default CheckboxGroup
