import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AdTikTokPreview from './AdTikTokPreview'
import SkeletonTikTokAdPreview from './AdTikTokPreview/Skeleton'
import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import EditSectionsList from '../../../../features/components/Forms/EditForm/EditSectionsList'
import NameSectionPreview from './Sections/NameSection/NameSectionPreview'
import NameSectionForm from './Sections/NameSection/NameSectionForm'
import TextSectionPreview from './Sections/TextSection/TextSectionPreview'
import TextSectionForm from './Sections/TextSection/TextSectionForm'
import ClickThroughLinkSectionPreview from './Sections/ClickThroughLinkSection/ClickThroughLinkSectionPreview'
import ClickThroughLinkSectionForm from './Sections/ClickThroughLinkSection/ClickThroughLinkSectionForm'
import CTASectionPreview from './Sections/CTASection/CTASectionPreview'
import CTASectionForm from './Sections/CTASection/CTASectionForm'
import VideoSectionPreview from './Sections/VideoSection/VideoSectionPreview'
import VideoSectionForm from './Sections/VideoSection/VideoSectionForm'

import useManageEditFormData from '../../../../hooks/formHooks/useManageEditFormData'
import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'

import { createJsonFromQueryString } from '../../../../helpers/url'

import { getAd, clearAd } from '../../../../modules/actions/ads'
import { getTikTokIdentities } from '../../../../modules/actions/tikTokConstants'
import { selectedAdAccountIdSelector } from '../../../../modules/selectors/app'
import { tikTokIdentitiesWasLoadedSelector } from '../../../../modules/selectors/tikTokConstants'
import { adLoadingSelector, adSelector, adWasLoadedSelector, getAdsSelector } from '../../../../modules/selectors/ads'

import { AD_TIK_TOK_EDIT } from '../../../../constants/forms'
import { NAME } from './fields'
import { VIDEO, AD_TEXT, LANDING_PAGE_URL } from '../fields'
import { CALL_TO_ACTION } from '../../../ReusableFormFields/CallToActionFields/fields'
import { TIKTOK_PLATFORM } from '../../../../constants/selectLists/platformList'

// here defined initial open states for function AdTikTokEdit sections
const sectionsInitialOpenStates = {
  [NAME]: false,
  [AD_TEXT]: false,
  [LANDING_PAGE_URL]: false,
  [CALL_TO_ACTION]: false,
  [VIDEO]: false
}

const AdTikTokEdit = () => {
  const dispatch = useDispatch()

  const ad = useSelector(adSelector)
  const ads = useSelector(getAdsSelector)
  const isAdLoading = useSelector(adLoadingSelector)
  const adWasLoaded = useSelector(adWasLoadedSelector)
  const tikTokIdentitiesWasLoaded = useSelector(tikTokIdentitiesWasLoadedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const isInitialDataLoading = isAdLoading || !adWasLoaded

  // if ad is internal, it appears in query params when we open edit form
  const { internal: internalQueryParam } = createJsonFromQueryString(window.location.search)

  const { isFormOpen, selectedEditItemId } = useManageFormsDrawer({
    formName: AD_TIK_TOK_EDIT
  })

  const selectedAd = useMemo(() => ads.find(ad => ad.id === selectedEditItemId), [ads, selectedEditItemId])

  const { account: adAccountId } = selectedAd || {}

  // On AdApprovals page we have ads with different ad accounts, so we need to get ad account from ad
  const adAccount = adAccountId || selectedAdAccountId

  const isInternalAd = internalQueryParam === 'true'

  const getAdDataHandler = useCallback(
    selectedEditItemId => {
      dispatch(
        getAd({
          platform: TIKTOK_PLATFORM,
          id: selectedEditItemId,
          account: adAccount,
          ...(isInternalAd && {
            internal: true
          })
        })
      )
    },
    [dispatch, isInternalAd, adAccount]
  )

  const clearAdDataHandler = useCallback(() => {
    dispatch(clearAd())
  }, [dispatch])

  useManageEditFormData({
    formName: AD_TIK_TOK_EDIT,
    loadedDataId: ad.id,
    getDataHandler: getAdDataHandler,
    clearDataHandler: clearAdDataHandler
  })

  // here defined all edit sections for AdTikTokEdit
  const editSections = useMemo(
    () => [
      {
        sectionName: NAME,
        title: 'Name',
        PreviewComponent: <NameSectionPreview />,
        FormComponent: <NameSectionForm />
      },
      {
        sectionName: AD_TEXT,
        title: 'Text',
        PreviewComponent: <TextSectionPreview />,
        FormComponent: <TextSectionForm />
      },
      {
        sectionName: LANDING_PAGE_URL,
        title: 'Click Through Link',
        PreviewComponent: <ClickThroughLinkSectionPreview />,
        FormComponent: <ClickThroughLinkSectionForm />
      },
      {
        sectionName: CALL_TO_ACTION,
        title: 'Call To Action',
        PreviewComponent: <CTASectionPreview />,
        FormComponent: <CTASectionForm />
      },
      {
        sectionName: VIDEO,
        title: 'Video',
        PreviewComponent: <VideoSectionPreview />,
        FormComponent: <VideoSectionForm />
      }
    ],
    []
  )

  useEffect(() => {
    if (!tikTokIdentitiesWasLoaded && !!isFormOpen) {
      dispatch(getTikTokIdentities({ account: adAccount }))
    }
  }, [dispatch, adAccount, tikTokIdentitiesWasLoaded, isFormOpen])

  return (
    <FormDrawerWrapper
      title="Update the settings of your ad"
      subTitle="Edit a TikTok ad"
      formName={AD_TIK_TOK_EDIT}
      showOpenButton={false}
    >
      <>
        {isInitialDataLoading ? <SkeletonTikTokAdPreview /> : <AdTikTokPreview adData={ad} isEditMode />}
        <EditSectionsList
          editSections={editSections}
          isEditDataLoading={isInitialDataLoading}
          editItemData={ad}
          sectionsInitialOpenStates={sectionsInitialOpenStates}
        />
      </>
    </FormDrawerWrapper>
  )
}

export default AdTikTokEdit
