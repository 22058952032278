import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import PeriodPrice from './PeriodPrice'
import ActionText from '../../../../../../../../../../../components/ActionText'
import ErrorMessage from '../../../../../../../../../../../components/Form/ErrorMessage'

import useStyles from '../styles'

function SelectedPeriodsList({
  productPeriods,
  productsQuantity,
  currencySymbol,
  handleProductPeriodsSetup,
  productPublicationsError,
  currentDiscount,
  showPeriodPrice = true,
  listItemClassName
}) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      {!!productPeriods.length ? (
        <ul className={classes.publicationsList}>
          {productPeriods.map(period => (
            <li className={classnames(classes.publicationDetails, listItemClassName)} key={period.value}>
              {period.label} {productsQuantity > 1 && `x ${productsQuantity}`}
              {showPeriodPrice && (
                <PeriodPrice
                  className={classes.price}
                  productQuantity={productsQuantity}
                  periodPrice={period.price}
                  currentDiscount={currentDiscount}
                  currencySymbol={currencySymbol}
                />
              )}
            </li>
          ))}
        </ul>
      ) : (
        <>
          {handleProductPeriodsSetup && (
            <ActionText onClick={handleProductPeriodsSetup} isDark>
              {t('+ Select dates')}
            </ActionText>
          )}
          {productPublicationsError && <ErrorMessage error={productPublicationsError} />}
        </>
      )}
    </>
  )
}

SelectedPeriodsList.propTypes = {
  productPeriods: PropTypes.array.isRequired,
  currentDiscount: PropTypes.number,
  currencySymbol: PropTypes.string.isRequired,
  productsQuantity: PropTypes.number,
  handleProductPeriodsSetup: PropTypes.func,
  productPublicationsError: PropTypes.string,
  showPeriodPrice: PropTypes.bool,
  listItemClassName: PropTypes.string
}

export default SelectedPeriodsList
