import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { getRandomizedValues } from '../helpers'
import { getUrlDomain } from '../../../../../../helpers/url'

import { ReactComponent as InfoIcon } from '../../../../../../assets/icons/info-circle.svg'

import { ROUTES } from '../../../../../../constants/routes'

import { HEADLINES_LIST, DESCRIPTIONS_LIST, CLICK_THROUGH_LINK } from '../../../fields'

import useStyles from './styles'

const defaultUrl = ROUTES.platformDomain
const defaultHeadLinesList = [{ text: 'Headline 1' }, { text: 'Headline 3' }, { text: 'Headline 2' }]
const defaultDescriptionsList = ['Description 1', 'Description 2']

const GoogleAdPreviewSearch1 = React.memo(({ values }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const randomizedValues = useMemo(() => {
    return getRandomizedValues(values)
  }, [values])

  const urlDomain = getUrlDomain(values[CLICK_THROUGH_LINK])

  const url = urlDomain || defaultUrl

  let headLinesList = defaultHeadLinesList

  if (randomizedValues[HEADLINES_LIST].length > 3) {
    // represent only 3 headlines - cut other headlines
    headLinesList = randomizedValues[HEADLINES_LIST].slice(0, 3)
  } else if (randomizedValues[HEADLINES_LIST].length > 0) {
    // replace defaultHeadLinesList with current filled values
    headLinesList = randomizedValues[HEADLINES_LIST]
  }
  const descriptionsList =
    (randomizedValues[DESCRIPTIONS_LIST] && randomizedValues[DESCRIPTIONS_LIST]) || defaultDescriptionsList

  return (
    <section className={classes.adPreviewContainer}>
      <div className={classes.adPreview}>
        <header className={classes.header}>
          <div className={classes.title}>
            {t('Ad')} <span className={classes.bullet}>&#8226;</span>
          </div>
          <div className={classes.url}>{url}</div>
          <div className={classes.icon}>
            <InfoIcon />
          </div>
        </header>
        <div>
          <div className={classes.headLineList}>
            {headLinesList.map(
              (headLine, index) => `${index !== 0 ? ' | ' : ''} ${headLine.text || `Headline ${index + 1}`}`
            )}
          </div>
          <div className={classes.descriptionList}>
            {descriptionsList
              .slice(0, 2)
              .map(
                (description, index) => `${index !== 0 ? '. ' : ''} ${description.text || `Description ${index + 1}`}`
              )}
          </div>
        </div>
      </div>
    </section>
  )
})

export default GoogleAdPreviewSearch1
