import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  downloadBtn: {
    width: 230,
    margin: '40px auto 0'
  }
})

export default useStyles
