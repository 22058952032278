import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import usePermissions from '../../../../../hooks/usePermissions'

import { insertIf } from '../../../../../helpers/common'
import { showToasts } from '../../../../../helpers/toasts'
import { isEditFormDisabled, isExistingPostTypeAd } from '../../../../../forms/Facebook/AdForms/AdFacebookEdit/helpers'

import { openForm } from '../../../../../modules/actions/forms'
import { approveInternalAd, clearUpdateAd, setSelectedAdForCopy, updateAd } from '../../../../../modules/actions/ads'

import { campaignSelector } from '../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'
import { adUpdateErrorSelector, adWasUpdatedSelector } from '../../../../../modules/selectors/ads'

import { AD_FACEBOOK_EDIT } from '../../../../../constants/forms'
import { listItemChangeStatus, TOAST_TYPE } from '../../../../../constants/other'
import { FACEBOOK_PLATFORM, FACEBOOK_PROVIDER_PLATFORM } from '../../../../../constants/selectLists/platformList'
import { AD_PERMISSION, INTERNAL_ADS_PERMISSION } from '../../../../../constants/permissions'

export default function useFacebookAdActions({ ad, disapproveInternalAdHandler, handleDeleteRequest }) {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  // extra state to manage only status updating (active/pause) and not e.g. edit form updating for this type of items
  const [isStatusUpdateRunning, setIsStatusUpdateRunning] = useState(false)

  const adUpdateError = useSelector(adUpdateErrorSelector)
  const adUpdateWasUpdated = useSelector(adWasUpdatedSelector)
  const { objective: campaignObjective } = useSelector(campaignSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const permissions = usePermissions()

  const isInternalAd = ad.internal

  const { id: adId, ad_account_id: adAccountId, status } = ad
  const isAdArchived = status && status.toLowerCase() === 'archived'

  const adAccount = adAccountId || selectedAdAccountId

  const copyAdHandler = useCallback(
    itemData => {
      // Copy Ad is saving in redux and is reset on AdAccount change
      // it allows to represent paste options in LineItemsList item actions dropdown or on LineItem summary page
      dispatch(setSelectedAdForCopy(itemData))

      showToasts({
        type: TOAST_TYPE.success,
        message: t('adCopiedSuccess', { itemName: itemData.name })
      })
    },
    [t, dispatch]
  )

  const approveInternalAdHandler = useCallback(() => {
    dispatch(approveInternalAd(adId, FACEBOOK_PROVIDER_PLATFORM))
  }, [dispatch, adId])

  const openEditFormHandler = useCallback(() => {
    dispatch(
      openForm({
        id: adId,
        formName: AD_FACEBOOK_EDIT,
        ...(isInternalAd && { otherParams: 'internal=true' })
      })
    )
  }, [dispatch, adId, isInternalAd])

  const deleteAdHandler = useCallback(() => {
    handleDeleteRequest({
      item: ad,
      account: selectedAdAccountId,
      campaignObjective: campaignObjective
    })
  }, [handleDeleteRequest, selectedAdAccountId, ad, campaignObjective])

  // const deleteInternalAdHandler = useCallback(() => {
  //   dispatch(deleteInternalAd(adId))
  // }, [dispatch, adId])

  const actionsDropdownOptions = useMemo(() => {
    if (isInternalAd) {
      // internal ad actions
      return [
        ...insertIf(permissions.can('manage', INTERNAL_ADS_PERMISSION), {
          text: 'Disapprove',
          onClickHandler: disapproveInternalAdHandler
        }),
        ...insertIf(permissions.can('manage', INTERNAL_ADS_PERMISSION), {
          text: 'Approve',
          onClickHandler: approveInternalAdHandler
        }),
        ...insertIf(permissions.can('update', INTERNAL_ADS_PERMISSION), {
          text: 'Edit',
          onClickHandler: openEditFormHandler
        })
      ]
    } else {
      return [
        // usual ad actions
        ...insertIf(!isEditFormDisabled(ad) && permissions.can('update', AD_PERMISSION), {
          text: 'Edit',
          onClickHandler: openEditFormHandler
        }),
        {
          text: 'View preview on Facebook',
          link: ad.preview,
          isItemLinkExternal: true
        },
        ...insertIf(permissions.can('update', AD_PERMISSION), {
          text: ad.status === 'active' ? 'Pause' : 'Activate',
          onClickHandler: () => {
            setIsStatusUpdateRunning(true)

            dispatch(
              updateAd(
                {
                  status: listItemChangeStatus[ad.status].changeTo,
                  account: adAccount
                },
                adId,
                'PATCH',
                FACEBOOK_PLATFORM
              )
            )
          }
        }),
        ...insertIf(permissions.can('delete', AD_PERMISSION), {
          text: isAdArchived ? 'Delete' : 'Archive / Delete',
          onClickHandler: deleteAdHandler
        }),
        // usual ad copy
        ...insertIf(permissions.can('create', AD_PERMISSION) && !isExistingPostTypeAd(ad), {
          text: 'Copy',
          onClickHandler: () => copyAdHandler(ad)
        })
      ]
    }
  }, [
    dispatch,
    ad,
    adId,
    openEditFormHandler,
    approveInternalAdHandler,
    copyAdHandler,
    isInternalAd,
    isAdArchived,
    deleteAdHandler,
    permissions,
    adAccount,
    disapproveInternalAdHandler
  ])

  useEffect(() => {
    if (isStatusUpdateRunning && (adUpdateWasUpdated || adUpdateError)) {
      setIsStatusUpdateRunning(false)

      dispatch(clearUpdateAd())
    }
  }, [dispatch, adUpdateError, adUpdateWasUpdated, isStatusUpdateRunning])

  return useMemo(
    () => ({
      actionsDropdownOptions,
      approveInternalAdHandler
    }),
    [actionsDropdownOptions, approveInternalAdHandler]
  )
}
