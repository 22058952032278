import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

// Delete tag
export const deleteTagSelector = createSelector(
  ({ tags }) => tags.deleteTag.results,
  results => results
)

export const deleteTagIsLoadingSelector = createSelector(
  ({ tags }) => tags.deleteTag.isLoading,
  isLoading => isLoading
)

export const deleteTagIdSelector = createSelector(
  ({ tags }) => tags.deleteTag.id,
  id => id
)

export const deleteTagErrorSelector = createSelector(
  ({ tags }) => tags.deleteTag.error,
  error => error
)

export const deleteTagWasDeletedSelector = createSelector(
  ({ tags }) => tags.deleteTag.wasDeleted,
  wasDeleted => wasDeleted
)

// Update tag
export const updateTagSelector = createSelector(
  ({ tags }) => tags.updateTag.results,
  results => results
)

export const updateTagIdSelector = createSelector(
  ({ tags }) => tags.updateTag.id,
  id => id
)

export const updateTagIsLoadingSelector = createSelector(
  ({ tags }) => tags.updateTag.isLoading,
  isLoading => isLoading
)

export const updateTagErrorSelector = createSelector(
  ({ tags }) => tags.updateTag.error,
  error => error
)

export const updateTagWasUpdatedSelector = createSelector(
  ({ tags }) => tags.updateTag.wasUpdated,
  wasUpdated => wasUpdated
)

// Create tag
export const createTagSelector = createSelector(
  ({ tags }) => tags.createTag.results,
  results => results
)

export const createTagIsLoadingSelector = createSelector(
  ({ tags }) => tags.createTag.isLoading,
  isLoading => isLoading
)

export const createTagErrorSelector = createSelector(
  ({ tags }) => tags.createTag.error,
  error => error
)

export const createTagWasCreatedSelector = createSelector(
  ({ tags }) => tags.createTag.wasCreated,
  wasCreated => wasCreated
)

// Delete product tag
export const deleteProductTagSelector = createSelector(
  ({ tags }) => tags.deleteProductTag.results,
  results => results
)

export const deleteProductTagIdSelector = createSelector(
  ({ tags }) => tags.deleteProductTag.id,
  id => id
)

export const deleteProductTagIsLoadingSelector = createSelector(
  ({ tags }) => tags.deleteProductTag.isLoading,
  isLoading => isLoading
)

export const deleteProductTagErrorSelector = createSelector(
  ({ tags }) => tags.deleteProductTag.error,
  error => error
)

export const deleteProductTagWasDeletedSelector = createSelector(
  ({ tags }) => tags.deleteProductTag.wasDeleted,
  wasDeleted => wasDeleted
)

// Update product tag
export const updateProductTagSelector = createSelector(
  ({ tags }) => tags.updateProductTag.results,
  results => results
)

export const updateProductTagIdSelector = createSelector(
  ({ tags }) => tags.updateProductTag.id,
  id => id
)

export const updateProductTagIsLoadingSelector = createSelector(
  ({ tags }) => tags.updateProductTag.isLoading,
  isLoading => isLoading
)

export const updateProductTagErrorSelector = createSelector(
  ({ tags }) => tags.updateProductTag.error,
  error => error
)

export const updateProductTagWasUpdatedSelector = createSelector(
  ({ tags }) => tags.updateProductTag.wasUpdated,
  wasUpdated => wasUpdated
)

// Create product tag
export const createProductTagSelector = createSelector(
  ({ tags }) => tags.createProductTag.results,
  results => results
)

export const createProductTagIsLoadingSelector = createSelector(
  ({ tags }) => tags.createProductTag.isLoading,
  isLoading => isLoading
)

export const createProductTagErrorSelector = createSelector(
  ({ tags }) => tags.createProductTag.error,
  error => error
)

export const createProductTagWasCreatedSelector = createSelector(
  ({ tags }) => tags.createProductTag.wasCreated,
  wasCreated => wasCreated
)

// Get product tags
export const productTagsSelector = createSelector(
  ({ tags }) => tags.productsTags.results,
  results => results
)

export const productTagsIsLoadingSelector = createSelector(
  ({ tags }) => tags.productsTags.isLoading,
  isLoading => isLoading
)

export const productTagsErrorSelector = createSelector(
  ({ tags }) => tags.productsTags.error,
  error => error
)

export const productTagsWasLoadedSelector = createSelector(
  ({ tags }) => tags.productsTags.wasLoaded,
  wasLoaded => wasLoaded
)

export const tagsSelector = createSelector(
  ({ tags }) => tags.tags.results,
  results => results
)

export const tagsIsLoadingSelector = createSelector(
  ({ tags }) => tags.tags.isLoading,
  isLoading => isLoading
)

export const tagsWasLoadedSelector = createSelector(
  ({ tags }) => tags.tags.wasLoaded,
  wasLoaded => wasLoaded
)

export const tagsErrorSelector = createSelector(
  ({ tags }) => tags.tags.error,
  error => error
)
