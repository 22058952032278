import { concat, eraseCookie, setCookie } from '../../helpers/common'
// import { setSessionStorage } from '../../helpers/storage'
// import { CAMPAIGN_FACEBOOK_CREATE } from '../../constants/forms'

const MODULE_NAME = 'CAMPAIGNS/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const FILTER = concat(MODULE_NAME, 'FILTER')
export const DATE_RANGE = concat(MODULE_NAME, 'DATE_RANGE')
export const SORT = concat(MODULE_NAME, 'SORT')

export const GET_CAMPAIGN = concat(MODULE_NAME, 'GET_CAMPAIGN')
export const GET_CAMPAIGN_SUCCESS = concat(MODULE_NAME, 'GET_CAMPAIGN_SUCCESS')
export const GET_CAMPAIGN_FAILURE = concat(MODULE_NAME, 'GET_CAMPAIGN_FAILURE')

export const GET_CAMPAIGNS = concat(MODULE_NAME, 'GET_CAMPAIGNS')
export const GET_CAMPAIGNS_SUCCESS = concat(MODULE_NAME, 'GET_CAMPAIGNS_SUCCESS')
export const GET_CAMPAIGNS_PAGINATION_SUCCESS = concat(MODULE_NAME, 'GET_CAMPAIGNS_PAGINATION_SUCCESS')
export const GET_CAMPAIGNS_FAILURE = concat(MODULE_NAME, 'GET_CAMPAIGNS_FAILURE')
export const CLEAR_CAMPAIGNS = concat(MODULE_NAME, 'CLEAR_CAMPAIGNS')

export const GET_SHORT_INFO_CAMPAIGNS = concat(MODULE_NAME, 'GET_SHORT_INFO_CAMPAIGNS')
export const GET_SHORT_INFO_CAMPAIGNS_SUCCESS = concat(MODULE_NAME, 'GET_SHORT_INFO_CAMPAIGNS_SUCCESS')
export const GET_SHORT_INFO_CAMPAIGNS_FAILURE = concat(MODULE_NAME, 'GET_SHORT_INFO_CAMPAIGNS_FAILURE')

export const CREATE_CAMPAIGN = concat(MODULE_NAME, 'CREATE_CAMPAIGN')
export const CREATE_CAMPAIGN_SUCCESS = concat(MODULE_NAME, 'CREATE_CAMPAIGN_SUCCESS')
export const CREATE_CAMPAIGN_FAILURE = concat(MODULE_NAME, 'CREATE_CAMPAIGN_FAILURE')

export const UPDATE_CAMPAIGN = concat(MODULE_NAME, 'UPDATE_CAMPAIGN')
export const UPDATE_CAMPAIGN_SUCCESS = concat(MODULE_NAME, 'UPDATE_CAMPAIGN_SUCCESS')
export const UPDATE_CAMPAIGN_FAILURE = concat(MODULE_NAME, 'UPDATE_CAMPAIGN_FAILURE')

export const DUPLICATE_CAMPAIGN = concat(MODULE_NAME, 'DUPLICATE_CAMPAIGN')
export const DUPLICATE_CAMPAIGN_SUCCESS = concat(MODULE_NAME, 'DUPLICATE_CAMPAIGN_SUCCESS')
export const DUPLICATE_CAMPAIGN_FAILURE = concat(MODULE_NAME, 'DUPLICATE_CAMPAIGN_FAILURE')
export const DUPLICATE_CAMPAIGN_COMPLETE = concat(MODULE_NAME, 'DUPLICATE_CAMPAIGN_COMPLETE')
export const CLEAR_DUPLICATE_CAMPAIGN = concat(MODULE_NAME, 'CLEAR_DUPLICATE_CAMPAIGN')

export const GET_CAMPAIGN_CRITERIONS = concat(MODULE_NAME, 'GET_CAMPAIGN_CRITERIONS')
export const GET_CAMPAIGN_CRITERIONS_SUCCESS = concat(MODULE_NAME, 'GET_CAMPAIGN_CRITERIONS_SUCCESS')
export const GET_CAMPAIGN_CRITERIONS_FAILURE = concat(MODULE_NAME, 'GET_CAMPAIGN_CRITERIONS_FAILURE')
export const CLEAR_CAMPAIGN_CRITERIONS = concat(MODULE_NAME, 'CLEAR_CAMPAIGN_CRITERIONS')

export const GET_CAMPAIGN_AD_SCHEDULE_CRITERIONS = concat(MODULE_NAME, 'GET_CAMPAIGN_AD_SCHEDULE_CRITERIONS')
export const GET_CAMPAIGN_AD_SCHEDULE_CRITERIONS_SUCCESS = concat(
  MODULE_NAME,
  'GET_CAMPAIGN_AD_SCHEDULE_CRITERIONS_SUCCESS'
)
export const GET_CAMPAIGN_AD_SCHEDULE_CRITERIONS_FAILURE = concat(
  MODULE_NAME,
  'GET_CAMPAIGN_AD_SCHEDULE_CRITERIONS_FAILURE'
)
export const CLEAR_CAMPAIGN_AD_SCHEDULE_CRITERIONS = concat(MODULE_NAME, 'CLEAR_CAMPAIGN_AD_SCHEDULE_CRITERIONS')

export const CREATE_CAMPAIGN_CRITERIONS = concat(MODULE_NAME, 'CREATE_CAMPAIGN_CRITERIONS')
export const CREATE_CAMPAIGN_CRITERIONS_SUCCESS = concat(MODULE_NAME, 'CREATE_CAMPAIGN_CRITERIONS_SUCCESS')
export const CREATE_CAMPAIGN_SCHEDULE_CRITERIONS_SUCCESS = concat(
  MODULE_NAME,
  'CREATE_CAMPAIGN_SCHEDULE_CRITERIONS_SUCCESS'
)
export const CREATE_CAMPAIGN_CRITERIONS_FAILURE = concat(MODULE_NAME, 'CREATE_CAMPAIGN_CRITERIONS_FAILURE')
export const CLEAR_CREATE_CAMPAIGN_CRITERIONS = concat(MODULE_NAME, 'CLEAR_CREATE_CAMPAIGN_CRITERIONS')

export const DELETE_CAMPAIGN_CRITERIONS = concat(MODULE_NAME, 'DELETE_CAMPAIGN_CRITERIONS')

export const DELETE_CAMPAIGN = concat(MODULE_NAME, 'DELETE_CAMPAIGN')
export const DELETE_CAMPAIGN_SUCCESS = concat(MODULE_NAME, 'DELETE_CAMPAIGN_SUCCESS')
export const DELETE_CAMPAIGN_FAILURE = concat(MODULE_NAME, 'DELETE_CAMPAIGN_FAILURE')

export const CLEAR_CAMPAIGN = concat(MODULE_NAME, 'CLEAR_CAMPAIGN')
export const CLEAR_CREATE_CAMPAIGN = concat(MODULE_NAME, 'CLEAR_CREATE_CAMPAIGN')
export const CLEAR_UPDATE_CAMPAIGN = concat(MODULE_NAME, 'CLEAR_UPDATE_CAMPAIGN')
export const CLEAR_DELETE_CAMPAIGN = concat(MODULE_NAME, 'CLEAR_DELETE_CAMPAIGN')

// FILTERS SECTION:
export const SET_CAMPAIGNS_FILTER = concat(MODULE_NAME, 'SET_CAMPAIGNS_FILTER')
export const SET_CAMPAIGNS_DATE_RANGE = concat(MODULE_NAME, 'SET_CAMPAIGNS_DATE_RANGE')
export const SET_CAMPAIGNS_SORT = concat(MODULE_NAME, 'SET_CAMPAIGNS_SORT')
export const CLEAR_CAMPAIGNS_FILTERS = concat(MODULE_NAME, 'CLEAR_CAMPAIGNS_FILTERS')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// get campaign
export function getCampaign(params, platform) {
  return { type: GET_CAMPAIGN, params, platform }
}

export function getCampaignSuccess(campaignData) {
  return { type: GET_CAMPAIGN_SUCCESS, campaignData }
}

export function getCampaignFailure(error) {
  return { type: GET_CAMPAIGN_FAILURE, error }
}

export function clearCampaign() {
  return { type: CLEAR_CAMPAIGN }
}

// get campaigns
export function getCampaigns(params) {
  return { type: GET_CAMPAIGNS, params }
}

export function getCampaignsSuccess(campaignsData) {
  return { type: GET_CAMPAIGNS_SUCCESS, campaignsData }
}

// action for when we use pagination on campaigns page to push items instead of re-setting
export function getCampaignsPaginationSuccess(campaignsData) {
  return { type: GET_CAMPAIGNS_PAGINATION_SUCCESS, campaignsData }
}

export function getCampaignsFailure(error) {
  return { type: GET_CAMPAIGNS_FAILURE, error }
}

// get short info campaigns
export function getShortInfoCampaigns(params) {
  return { type: GET_SHORT_INFO_CAMPAIGNS, params }
}

export function getShortInfoCampaignsSuccess(campaignsData) {
  return { type: GET_SHORT_INFO_CAMPAIGNS_SUCCESS, campaignsData }
}

export function getShortInfoCampaignsFailure(error) {
  return { type: GET_SHORT_INFO_CAMPAIGNS_FAILURE, error }
}

export function clearCampaigns() {
  return { type: CLEAR_CAMPAIGNS }
}

// create campaign
export function createCampaign(
  data,
  platform,
  createOptions = {
    pushToCombinedCampaignsList: false
  }
) {
  return { type: CREATE_CAMPAIGN, data, platform, createOptions }
}

export function createCampaignSuccess(campaignData) {
  // reset saved form data, todo disabled till post MVP
  // setSessionStorage(CAMPAIGN_FACEBOOK_CREATE, {})

  return { type: CREATE_CAMPAIGN_SUCCESS, campaignData }
}

export function createCampaignFailure(error) {
  return { type: CREATE_CAMPAIGN_FAILURE, error }
}

export function clearCreateCampaign() {
  return { type: CLEAR_CREATE_CAMPAIGN }
}

// update
export function updateCampaign(
  campaignData,
  id,
  updateOptions = {
    platform: '',
    updateType: '',
    requestMethod: ''
  }
) {
  return { type: UPDATE_CAMPAIGN, campaignData, id, updateOptions }
}

export function updateCampaignSuccess(campaignData) {
  return { type: UPDATE_CAMPAIGN_SUCCESS, campaignData }
}

export function updateCampaignFailure(error) {
  return { type: UPDATE_CAMPAIGN_FAILURE, error }
}

export function clearUpdateCampaign() {
  return { type: CLEAR_UPDATE_CAMPAIGN }
}

// duplicate
export function duplicateCampaign(duplicateData, id, platform) {
  return { type: DUPLICATE_CAMPAIGN, duplicateData, id, platform }
}

// DUPLICATE_CAMPAIGN_SUCCESS is a general action which fires in campaign saga,
// when we get success response from "/campaigns/:id/copy/" endpoint
export function duplicateCampaignSuccess(asyncSessionData) {
  return { type: DUPLICATE_CAMPAIGN_SUCCESS, asyncSessionData }
}

// DUPLICATE_CAMPAIGN_COMPLETE is an action which we fire manually in a component,
// when we get 'COMPLETED' status from '/async_sessions/:id/' endpoint
// we need this action to push new item to the campaigns list
export function duplicateCampaignComplete(duplicatedCampaign) {
  return { type: DUPLICATE_CAMPAIGN_COMPLETE, duplicatedCampaign }
}

export function duplicateCampaignFailure(error) {
  return { type: DUPLICATE_CAMPAIGN_FAILURE, error }
}

export function clearDuplicateCampaign() {
  return { type: CLEAR_DUPLICATE_CAMPAIGN }
}

// get campaign criterions
export function getCampaignCriterions(params) {
  return { type: GET_CAMPAIGN_CRITERIONS, params }
}

export function getCampaignCriterionsSuccess(criterionsData) {
  return { type: GET_CAMPAIGN_CRITERIONS_SUCCESS, criterionsData }
}

export function getCampaignCriterionsFailure(error) {
  return { type: GET_CAMPAIGN_CRITERIONS_FAILURE, error }
}

export function clearCampaignCriterions() {
  return { type: CLEAR_CAMPAIGN_CRITERIONS }
}

// get campaign ad schedule criterions
export function getCampaignAdScheduleCriterions(params) {
  return { type: GET_CAMPAIGN_AD_SCHEDULE_CRITERIONS, params }
}

export function getCampaignAdScheduleCriterionsSuccess(adScheduleCriterionsData) {
  return { type: GET_CAMPAIGN_AD_SCHEDULE_CRITERIONS_SUCCESS, adScheduleCriterionsData }
}

export function getCampaignAdScheduleCriterionsFailure(error) {
  return { type: GET_CAMPAIGN_AD_SCHEDULE_CRITERIONS_FAILURE, error }
}

export function clearCampaignAdScheduleCriterions() {
  return { type: CLEAR_CAMPAIGN_AD_SCHEDULE_CRITERIONS }
}

// create campaign criterions
export function createCampaignCriterions(criterionsData, criterionType, criterionsToDelete) {
  return { type: CREATE_CAMPAIGN_CRITERIONS, criterionsData, criterionType, criterionsToDelete }
}

export function createCampaignCriterionsSuccess(criterionsData) {
  return { type: CREATE_CAMPAIGN_CRITERIONS_SUCCESS, criterionsData }
}

export function createCampaignScheduleCriterionsSuccess(criterionsData) {
  return { type: CREATE_CAMPAIGN_SCHEDULE_CRITERIONS_SUCCESS, criterionsData }
}

export function createCampaignCriterionsFailure(error) {
  return { type: CREATE_CAMPAIGN_CRITERIONS_FAILURE, error }
}

export function clearCreateCampaignCriterions() {
  return { type: CLEAR_CREATE_CAMPAIGN_CRITERIONS }
}

// this action used to delete criterions from state, when we use remove_operations field
// to delete criterions inside create request (createCampaignCriterions)
export function deleteCampaignCriterions(criterionsToDelete) {
  return { type: DELETE_CAMPAIGN_CRITERIONS, criterionsToDelete }
}

// delete campaign
export function deleteCampaign(params) {
  return { type: DELETE_CAMPAIGN, params }
}

export function deleteCampaignSuccess(id) {
  return { type: DELETE_CAMPAIGN_SUCCESS, id }
}

export function deleteCampaignFailure(error) {
  return { type: DELETE_CAMPAIGN_FAILURE, error }
}

export function clearDeleteCampaign() {
  return { type: CLEAR_DELETE_CAMPAIGN }
}

// set filters
export function setCampaignsFilter(filter) {
  setCookie(FILTER, filter, 1)
  return { type: SET_CAMPAIGNS_FILTER, filter }
}

export function setCampaignsDateRange(dateRange) {
  setCookie(DATE_RANGE, dateRange, 1)
  return { type: SET_CAMPAIGNS_DATE_RANGE, dateRange }
}

export function setCampaignsSort(sort) {
  setCookie(SORT, sort, 1)
  return { type: SET_CAMPAIGNS_SORT, sort }
}

export function clearCampaignsFilters() {
  eraseCookie(FILTER)
  eraseCookie(DATE_RANGE)
  eraseCookie(SORT)

  return { type: CLEAR_CAMPAIGNS_FILTERS }
}
