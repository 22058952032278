import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import { useMediaQuery } from 'react-responsive'

import { formatOptionsList } from '../../../../features/formatters'

import ContentSection from '../../../../features/components/ContentSection'
import ContentRow from '../../../../features/components/ContentSection/ContentRow'
import Field from '../../../../components/Form/Field'
import SortableUploadImagesList from '../../../../features/components/Form/SortableUploadImagesList'
import ImagePreviewWithAltText from './ImagePreviewWithAltText'
import MultiSelectBox from '../../../../features/components/Form/MultiSelectBox'
import ExternalRecipientsContent from './ExternalRecipientsContent'
import Checkbox from '../../../../components/Form/Checkbox'
import Spoiler from '../../../../components/Spoiler'
import StoresContent from './StoresContent'

import { usersIsLoadingSelector, usersSelector } from '../../../../modules/selectors/users'
import { clearUsers, getUsers } from '../../../../modules/actions/users'

import {
  ADDRESS,
  BOOKING_NOTIFICATION_RECIPIENTS,
  daysInAdvanceOptions,
  INTERNAL_ID,
  NAME,
  SEND_BOOKING_SUMMARY_NOTIFICATION,
  SUMMARY_DAYS_ADVANCE,
  SUMMARY_INTERVAL,
  summaryIntervalsOptions
} from '../fields'

import { tabletDownSize } from '../../../../styles/const/breakpoints'
import useContentRowStyles from '../../../../features/components/ContentSection/ContentRow/styles'
import useStyles from './styles'

const ProductLocationFormContent = ({ formik, initialOpenSubLocations }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const contentRowClasses = useContentRowStyles()

  const { t } = useTranslation()

  const { values, setFieldValue, setValues } = formik

  const users = useSelector(usersSelector)
  const usersIsLoading = useSelector(usersIsLoadingSelector)

  const isTablet = useMediaQuery({ maxWidth: tabletDownSize })

  const formattedUsers = useMemo(
    () =>
      formatOptionsList({
        list: users,
        valueName: 'id',
        labelName: 'full_name'
      }),
    [users]
  )

  const bookingSummaryNotificationCheckboxHandler = useCallback(() => {
    const sendBookingSummaryNotification = values[SEND_BOOKING_SUMMARY_NOTIFICATION]

    const updatedValues = sendBookingSummaryNotification
      ? {
          ...values,
          [SEND_BOOKING_SUMMARY_NOTIFICATION]: false,
          // reset summary interval and days in advance if user unchecks the checkbox
          [SUMMARY_INTERVAL]: '',
          [SUMMARY_DAYS_ADVANCE]: 0
        }
      : {
          ...values,
          [SEND_BOOKING_SUMMARY_NOTIFICATION]: true
        }
    setValues(updatedValues)
  }, [values, setValues])

  useEffect(() => {
    dispatch(getUsers())

    return () => {
      dispatch(clearUsers())
    }
  }, [dispatch])

  return (
    <ContentSection title="Product Location">
      <ContentRow rightSideClassName={classes.rightSide} title="Name" description="e.g. Mall name or store name">
        <Field formik={formik} id={NAME} name={NAME} placeholder="Name" />
      </ContentRow>
      <ContentRow rightSideClassName={classes.rightSide} title="Internal ID (Optional)">
        <Field formik={formik} id={INTERNAL_ID} name={INTERNAL_ID} placeholder="Internal ID" />
      </ContentRow>
      <ContentRow rightSideClassName={classes.rightSide} title="Address" description="e.g. City name">
        <Field formik={formik} id={ADDRESS} name={ADDRESS} placeholder="Address" />
      </ContentRow>
      <ContentRow
        rightSideClassName={classes.rightSide}
        title="Images (optional)"
        leftColumnChildren={
          <>
            <p className={contentRowClasses.description}>{t('File type: png (transparent)')}</p>
            <p className={contentRowClasses.description}>{t('Dimensions: 600 x 400 px')}</p>
            <p className={contentRowClasses.description}>{t('Max file size: 200kb')}</p>
          </>
        }
      >
        <SortableUploadImagesList formik={formik} CustomImagePreview={ImagePreviewWithAltText} isInDrawer={true} />
      </ContentRow>
      <ContentRow
        rightSideClassName={classes.rightSide}
        title="Booking Notification Recipients"
        description="Platform users to receive an email notification every time there is a booking in this location."
      >
        <MultiSelectBox
          // portaled prop need to be passed to avoid cutting off the select list
          portaled={true}
          name={BOOKING_NOTIFICATION_RECIPIENTS}
          value={values[BOOKING_NOTIFICATION_RECIPIENTS]}
          setFieldValue={setFieldValue}
          formik={formik}
          options={formattedUsers}
          isLoading={usersIsLoading}
          placeholder="Select Recipients"
        />
      </ContentRow>
      <ContentRow
        rightSideClassName={classes.rightSide}
        title="External Booking Notification Recipients"
        description="External recipients of the booking notification."
      >
        <ExternalRecipientsContent formik={formik} />
      </ContentRow>
      <ContentRow
        className={classes.bookingSummaryNotificationRow}
        rightSideClassName={classnames(classes.rightSide, classes.bookingSummaryNotification)}
        title="Booking Summary Notification"
        description="A regular email notification with the media bookings summary for this location sent to the recipients above."
      >
        <Checkbox
          checked={values[SEND_BOOKING_SUMMARY_NOTIFICATION]}
          id={SEND_BOOKING_SUMMARY_NOTIFICATION}
          name={SEND_BOOKING_SUMMARY_NOTIFICATION}
          title="Send booking summary notifications"
          setFieldValue={setFieldValue}
          onCheck={bookingSummaryNotificationCheckboxHandler}
        />
        {values[SEND_BOOKING_SUMMARY_NOTIFICATION] && (
          <>
            <Field
              name={SUMMARY_INTERVAL}
              formik={formik}
              placeholder={'Frequency'}
              options={summaryIntervalsOptions}
              portaled
            />
            <Field
              name={SUMMARY_DAYS_ADVANCE}
              formik={formik}
              placeholder="Days in advance"
              options={daysInAdvanceOptions}
              portaled
              // menuHeightLimit prop is used to avoid cutting off the select list at the bottom of the screen
              menuHeightLimit={isTablet ? 200 : 220}
            />
          </>
        )}
      </ContentRow>
      <Spoiler
        title="Advanced location options"
        spoilerTitleClassName={classes.subLocationsSpoilerTitle}
        initialOpen={initialOpenSubLocations}
      >
        <ContentRow
          title="Sub-location"
          description="Sub-locations, stores or units within this location"
          rightSideClassName={classes.rightSide}
        >
          <StoresContent formik={formik} />
        </ContentRow>
      </Spoiler>
    </ContentSection>
  )
}

export default ProductLocationFormContent
