import Color from 'color'

import { navy } from '../const/colors'
import Inter from '../fonts/Inter/Inter.woff2'

const mainColor = navy

export const shopometryTheme = {
  fontFamily: {
    name: 'Inter',
    value: 'Inter, sans-serif',
    // inter is using the same font for all weights
    normal: Inter,
    medium: Inter,
    bold: Inter,
    format: 'woff2'
  },
  brandPrimary: mainColor,
  brandPrimaryLight: Color(mainColor).lightness(80).rgb().string(),
  brandPrimaryHover: Color(mainColor).darken(0.3).rgb().string(),
  brandPrimaryTextContrastColor: 'white',
  selectActiveBorderColor: mainColor,
  defaultBorderRadius: 3,
  bigBorderRadius: 6
}
