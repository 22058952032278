import { createStyles } from '../../../../../styles/helpers'

import { lighterGrey } from '../../../../../styles/const/colors'

export default createStyles({
  mobileHiddenColumnsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 10px',
    color: 'black',

    '&:nth-child(2n)': {
      backgroundColor: lighterGrey
    },
    '&:last-child': {
      padding: '5px 0'
    }
  },
  mobileRowHeader: {
    minWidth: '50%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  colInsideBody: {
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
})
