import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Drawer from '../../../../components/Drawer'
import EditSectionsList from '../../../../features/components/Forms/EditForm/EditSectionsList'
import NameSectionPreview from '../../../ReusableFormSections/NameSection/NameSectionPreview'
import NameSection from './Sections/NameSection'
import BudgetSectionPreview from './Sections/BudgetDailySection/BudgetDailySectionPreview'
import BudgetSectionForm from './Sections/BudgetDailySection/BudgetDailySectionForm'
import DatesSectionPreview from './Sections/DatesSection/DatesSectionPreview'
import DatesSectionForm from './Sections/DatesSection/DatesSectionForm'
import ScheduleSectionPreview from './Sections/ScheduleSection/ScheduleSectionPreview'
import ScheduleSection from './Sections/ScheduleSection'
import TargetRoasSectionPreview from './Sections/TargetRoasSection/TargetRoasSectionPreview'
import TargetRoasSectionForm from './Sections/TargetRoasSection/TargetRoasSectionForm'
import GeoTargetingSectionPreview from './Sections/GeoTargetingSection/GeoTargetingsSectionPreview'
import GeoTargetingSectionForm from './Sections/GeoTargetingSection/GeoTargetingSectionForm'
import CustomLocationsSectionPreview from './Sections/CustomLocationsSection/CustomLocationsSectionPreview'
import CustomLocationsSectionForm from './Sections/CustomLocationsSection/CustomLocationsSectionForm'
import LanguagesSectionPreview from './Sections/LanguagesSection/LanguagesSectionPreview'
import LanguagesSectionForm from './Sections/LanguagesSection/LanguagesSectionForm'

import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'
import useManageEditFormData from '../../../../hooks/formHooks/useManageEditFormData'

import { getLocationLists } from '../../../../modules/actions/location'
import {
  clearCampaign,
  clearCampaignAdScheduleCriterions,
  clearCampaignCriterions,
  getCampaign,
  getCampaignAdScheduleCriterions,
  getCampaignCriterions
} from '../../../../modules/actions/campaigns'
import { getGoogleLanguages } from '../../../../modules/actions/googleConstants'
import {
  campaignAdScheduleCriterionsWasLoadedSelector,
  campaignCriterionsWasLoadedSelector,
  campaignSelector,
  campaignWasLoadedSelector
} from '../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector, selectedSelfAccountSelector } from '../../../../modules/selectors/app'
import { locationListsWasLoadedSelector } from '../../../../modules/selectors/location'
import { googleLanguagesWasLoadedSelector } from '../../../../modules/selectors/googleConstants'

import { CAMPAIGN_GOOGLE_ADS_EDIT } from '../../../../constants/forms'
import {
  CAMPAIGN_BUDGET,
  CAMPAIGN_DATES,
  CUSTOM_LOCATIONS,
  LOCATIONS,
  NAME,
  TARGET_ROAS
} from '../../../ReusableFormFields/CampaignForms/fields'
import { LANGUAGES } from '../../../ReusableFormFields/LanguagesFields/fields'
import { SCHEDULE } from '../../../ReusableFormSections/ScheduleSection/fields'

// here defined initial open states for edit sections
const sectionsInitialOpenStates = {
  [NAME]: false,
  [CAMPAIGN_BUDGET]: false,
  [CAMPAIGN_DATES]: false,
  [TARGET_ROAS]: false,
  [LOCATIONS]: false,
  [LANGUAGES]: false,
  [SCHEDULE]: false,
  [LOCATIONS]: false,
  [CUSTOM_LOCATIONS]: false
}

const CampaignGoogleEdit = ({ shouldBeCleared }) => {
  const dispatch = useDispatch()

  const campaign = useSelector(campaignSelector)
  const campaignWasLoaded = useSelector(campaignWasLoadedSelector)
  const campaignCriterionsWasLoaded = useSelector(campaignCriterionsWasLoadedSelector)
  const campaignAdScheduleCriterionsWasLoaded = useSelector(campaignAdScheduleCriterionsWasLoadedSelector)
  const languagesWasLoaded = useSelector(googleLanguagesWasLoadedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const locationListsWasLoaded = useSelector(locationListsWasLoadedSelector)
  const selectedSelfAccountId = useSelector(selectedSelfAccountSelector)

  const { id: campaignId } = campaign

  const isInitialDataLoading =
    !campaignWasLoaded || !campaignCriterionsWasLoaded || !campaignAdScheduleCriterionsWasLoaded || !languagesWasLoaded

  const getCampaignDataHandler = useCallback(
    selectedEditItemId => {
      // Load campaign data only if user is on campaigns list page
      // if user is on campaign summary page - we already have campaign data in store
      if (!campaignWasLoaded) {
        dispatch(getCampaign({ account: selectedAdAccountId, id: selectedEditItemId }))
      }
    },
    [dispatch, campaignWasLoaded, selectedAdAccountId]
  )

  const clearCampaignDataHandler = useCallback(() => {
    if (shouldBeCleared) {
      dispatch(clearCampaign())
    }

    dispatch(clearCampaignCriterions())
    dispatch(clearCampaignAdScheduleCriterions())
  }, [dispatch, shouldBeCleared])

  const { isFormOpen, handleFormClose } = useManageFormsDrawer({ formName: CAMPAIGN_GOOGLE_ADS_EDIT })

  useManageEditFormData({
    formName: CAMPAIGN_GOOGLE_ADS_EDIT,
    loadedDataId: campaign.id,
    getDataHandler: getCampaignDataHandler,
    clearDataHandler: clearCampaignDataHandler
  })

  const editSections = useMemo(
    () => [
      {
        sectionName: NAME,
        title: 'Name',
        PreviewComponent: <NameSectionPreview dataSelector={campaignSelector} />,
        FormComponent: <NameSection />
      },
      {
        sectionName: CAMPAIGN_BUDGET,
        title: 'Budget',
        PreviewComponent: <BudgetSectionPreview />,
        FormComponent: <BudgetSectionForm />
      },
      {
        sectionName: CAMPAIGN_DATES,
        title: 'Dates',
        PreviewComponent: <DatesSectionPreview />,
        FormComponent: <DatesSectionForm />
      },
      {
        sectionName: SCHEDULE,
        title: 'Schedule',
        PreviewComponent: <ScheduleSectionPreview />,
        FormComponent: <ScheduleSection />
      },
      {
        sectionName: TARGET_ROAS,
        title: 'Bidding',
        PreviewComponent: <TargetRoasSectionPreview />,
        FormComponent: <TargetRoasSectionForm />
      },
      {
        sectionName: LOCATIONS,
        title: 'Location Targeting',
        PreviewComponent: <GeoTargetingSectionPreview />,
        FormComponent: <GeoTargetingSectionForm />
      },
      {
        sectionName: CUSTOM_LOCATIONS,
        title: 'Custom Locations',
        PreviewComponent: <CustomLocationsSectionPreview />,
        FormComponent: <CustomLocationsSectionForm />
      },
      {
        sectionName: LANGUAGES,
        title: 'Languages',
        PreviewComponent: <LanguagesSectionPreview />,
        FormComponent: <LanguagesSectionForm />
      }
    ],
    []
  )

  useEffect(() => {
    if (isFormOpen && campaignId) {
      // pre-load usual criterions data
      dispatch(
        getCampaignCriterions({
          account: selectedAdAccountId,
          campaign_id: campaignId
        })
      )

      // pre-load schedule criterions for schedule section
      dispatch(
        getCampaignAdScheduleCriterions({
          account: selectedAdAccountId,
          campaign: campaignId
        })
      )
    }
  }, [dispatch, isFormOpen, campaignId, selectedAdAccountId])

  useEffect(() => {
    // google language constants require account field, but can't be dependent on different accounts
    if (isFormOpen && !languagesWasLoaded) {
      dispatch(
        getGoogleLanguages({
          account: selectedAdAccountId
        })
      )
    }
  }, [dispatch, isFormOpen, languagesWasLoaded, selectedAdAccountId])

  useEffect(() => {
    // preload location lists for CustomLocations section
    if (isFormOpen && !locationListsWasLoaded) {
      dispatch(
        getLocationLists({
          account: selectedSelfAccountId
        })
      )
    }
  }, [dispatch, isFormOpen, locationListsWasLoaded, selectedSelfAccountId])

  return (
    <Drawer
      title="Update the settings of your campaign"
      subTitle="Edit Google Ads campaign"
      isOpen={isFormOpen}
      onClose={handleFormClose}
    >
      <EditSectionsList
        editSections={editSections}
        isEditDataLoading={isInitialDataLoading}
        editItemData={campaign}
        sectionsInitialOpenStates={sectionsInitialOpenStates}
      />
    </Drawer>
  )
}

export default CampaignGoogleEdit
