import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

const SkeletonStatisticBlocksGrid = ({ blocks }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {blocks.map(({ label }) => (
        <div className={classes.block} key={label}>
          <h4 className={classes.label}>{label || <Skeleton width={60} />}</h4>
          <h4 className={classes.value}>
            <Skeleton width={60} />
          </h4>
        </div>
      ))}
    </div>
  )
}

SkeletonStatisticBlocksGrid.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  ).isRequired
}

export default SkeletonStatisticBlocksGrid
