import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { clearCreateLineItem, createLineItem } from '../../../../../modules/actions/lineItems'

import useManageUserSettings from '../../../../../features/hooks/useManageUserSettings'

import { lineItemCreatedDataSelector } from '../../../../../modules/selectors/lineItems'

import { transformValuesToBE } from './formatters'

import { DO_NOT_ASK_ENABLE_COMMENTS_AGAIN, ENABLE_COMMENTS_OPTION } from './fields'
import { LINE_ITEM_TIKTOK_CREATE } from '../../../../../constants/forms'
import { TIKTOK_PLATFORM } from '../../../../../constants/selectLists/platformList'
import { ROUTE_PARAMS } from '../../../../../constants/routes'

const useLineItemCreateHandlers = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'useLineItemCreateHandlers')

  const dispatch = useDispatch()

  const {
    selectedAdAccountId,
    campaignId,
    campaignDateStart,
    campaignObjective,
    showBidPriceStep,
    isBidTypeCustomPopulated,
    isCampaignLevelBudgetSet,
    tikTokRegions,
    actionCategories,
    interests,
    onSuccessSubmit,
    showPixelStep,
    pixelIdFromExistingLineItem,
    externalActionFromExistingLineItem
  } = props

  const lineItemCreatedData = useSelector(lineItemCreatedDataSelector)

  const { combineNewUserSettings } = useManageUserSettings(selectedAdAccountId)

  const { id: createdLineItemId } = lineItemCreatedData

  const onSubmit = useCallback(
    values => {
      let updatedSettings

      if (values[DO_NOT_ASK_ENABLE_COMMENTS_AGAIN]) {
        updatedSettings = {
          settings: combineNewUserSettings(
            { [ENABLE_COMMENTS_OPTION]: values[ENABLE_COMMENTS_OPTION] },
            LINE_ITEM_TIKTOK_CREATE
          )
        }
      }

      const transformedValues = transformValuesToBE({
        values,
        adAccountId: selectedAdAccountId,
        campaignId,
        campaignDateStart,
        campaignObjective,
        showBidPriceStep,
        isBidTypeCustomPopulated,
        isCampaignLevelBudgetSet,
        tikTokRegions,
        actionCategories,
        interests,
        showPixelStep,
        pixelIdFromExistingLineItem,
        externalActionFromExistingLineItem
      })

      dispatch(createLineItem(transformedValues, TIKTOK_PLATFORM, updatedSettings))
    },
    [
      dispatch,
      selectedAdAccountId,
      campaignId,
      campaignDateStart,
      campaignObjective,
      showBidPriceStep,
      isBidTypeCustomPopulated,
      isCampaignLevelBudgetSet,
      tikTokRegions,
      actionCategories,
      interests,
      combineNewUserSettings,
      showPixelStep,
      pixelIdFromExistingLineItem,
      externalActionFromExistingLineItem
    ]
  )

  const onSuccessSubmitHandler = useCallback(() => {
    onSuccessSubmit({
      [ROUTE_PARAMS.campaignId]: campaignId,
      [ROUTE_PARAMS.lineItemId]: createdLineItemId
    })
  }, [onSuccessSubmit, campaignId, createdLineItemId])

  const clearCreateAdGroupHandler = useCallback(() => {
    dispatch(clearCreateLineItem())
  }, [dispatch])

  return {
    onSubmit,
    onSuccessSubmitHandler,
    clearCreateAdGroupHandler
  }
}

const propTypes = {
  selectedAdAccountId: PropTypes.string.isRequired,
  campaignId: PropTypes.string.isRequired,
  campaignDateStart: PropTypes.string.isRequired,
  campaignObjective: PropTypes.string.isRequired,
  showBidPriceStep: PropTypes.bool.isRequired,
  isBidTypeCustomPopulated: PropTypes.bool.isRequired,
  isCampaignLevelBudgetSet: PropTypes.bool.isRequired,
  tikTokRegions: PropTypes.array.isRequired,
  actionCategories: PropTypes.array.isRequired,
  interests: PropTypes.array.isRequired,
  onSuccessSubmit: PropTypes.func.isRequired,
  showPixelStep: PropTypes.bool.isRequired,
  pixelIdFromExistingLineItem: PropTypes.string,
  externalActionFromExistingLineItem: PropTypes.string
}

export default useLineItemCreateHandlers
