import { madeRequest } from '../../helpers/api'

import { ENDPOINTS } from '../../constants/api'
import {
  DV_360_PLATFORM,
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  TIKTOK_PLATFORM
} from '../../constants/selectLists/platformList'

// PLOP_APPEND_PATTERN_ANCHOR

export function resendRegisterService(resendData) {
  return madeRequest('POST', {
    data: resendData,
    url: ENDPOINTS.resend_register
  })
}

export function socialAuthService({ authParams, selectedPlatform }) {
  let socialAuthUrl = ''
  switch (selectedPlatform) {
    case GOOGLE_PLATFORM:
      socialAuthUrl = ENDPOINTS.googleAuth
      break
    case DV_360_PLATFORM:
      socialAuthUrl = ENDPOINTS.dv360Auth
      break
    case TIKTOK_PLATFORM:
      socialAuthUrl = ENDPOINTS.tikTokAuth
      break
    case FACEBOOK_PLATFORM:
    default:
      socialAuthUrl = ENDPOINTS.facebookAuth
  }

  return madeRequest('GET', {
    url: socialAuthUrl,
    params: authParams
  })
}
