import { createStyles } from '../../styles/helpers'

import { zIndex10, zIndex8 } from '../../styles/const/common'
import { darkGrey } from '../../styles/const/colors'
import { phonesDown } from '../../styles/const/breakpoints'

const useStyles = createStyles({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: showHeader => (showHeader ? zIndex8 : zIndex10),
    backgroundColor: 'rgba(0, 0, 0, .5)',
    color: darkGrey
  },
  modal: {
    overflow: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    maxWidth: 540,
    maxHeight: '90%', // avoid modal content to be cut off
    padding: '42px 24px 50px',
    background: 'rgb(255, 255, 255)',
    borderRadius: 12,
    outline: 'none',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  },
  [`@media screen and (${phonesDown})`]: {
    modal: {
      width: 'calc(100% - 48px)',
      padding: 24
    }
  }
})

export default useStyles
