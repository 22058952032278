import * as Yup from 'yup'
import { passwordValidation } from '../../../features/validations/other'

export const PASSWORD = 'password'

export const initialValues = {
  [PASSWORD]: ''
}

export const validationSchema = Yup.object({
  [PASSWORD]: passwordValidation
})
