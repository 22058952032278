import { createStyles } from '../../../../../../../../styles/helpers'
import { mediumGrey } from '../../../../../../../../styles/const/colors'

const useStyles = createStyles({
  periodCheckbox: {
    marginTop: ['8px', '!important'],
    marginBottom: 20
  },
  alreadyUploadedDescription: {
    color: mediumGrey
  }
})

export default useStyles
