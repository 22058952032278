import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useStyles from './styles'

const ContentSection = ({ title, children, containerClassname, headerClassname, contentClassname }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <div className={classnames(classes.container, containerClassname)}>
      <div className={classes.headerWrapper}>
        <h2 className={classnames(classes.header, headerClassname)}>{t(title)}</h2>
      </div>
      <div className={classes.contentWrapper}>
        <div className={classnames(classes.content, contentClassname)}>{children}</div>
      </div>
    </div>
  )
}

ContentSection.propTypes = {
  title: PropTypes.string,
  contentClassname: PropTypes.string,
  containerClassname: PropTypes.string
}

export default ContentSection
