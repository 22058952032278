import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './styles'
import { useOpenMediaOrderSummaryInNewTab } from '../hooks'

const BookingCell = ({ booking, mediaOrderId, accountId }) => {
  const classes = useStyles()

  const goToMediaOrderSummaryHandler = useOpenMediaOrderSummaryInNewTab({ mediaOrderId, accountId })

  return booking ? (
    <div className={classes.booking} onClick={goToMediaOrderSummaryHandler}>
      {booking}
    </div>
  ) : (
    '-'
  )
}

BookingCell.propTypes = {
  booking: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mediaOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default BookingCell
