import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

const SkeletonInventoryGraph = ({ className }) => {
  const classes = useStyles()

  return (
    <div className={classnames(classes.graphSection, className)}>
      <h3 className={classes.graphHeader}>
        <Skeleton width={140} />
      </h3>
      <Skeleton height={230} width="100%" />
    </div>
  )
}

SkeletonInventoryGraph.propTypes = {
  className: PropTypes.string
}

export default SkeletonInventoryGraph
