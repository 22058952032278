import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles({
  warningFooter: {
    textAlign: 'center',
    maxWidth: '80%',
    margin: '20px auto 0'
  }
})

export default useStyles
