import React from 'react'
import PropTypes from 'prop-types'

import InfoChip from '../../../../../../features/components/Chips/InfoChip'

import { PACKAGE_PENDING_APPROVAL } from '../../../../../../forms/Multiplatform/MediaPackageForms/MediaPackageCreate/MediaPackageCreateForm/fields'
import { red } from '../../../../../../styles/const/colors'

const AdditionalStatusCell = packageData => {
  if (packageData.status === PACKAGE_PENDING_APPROVAL) {
    return <InfoChip text={'In Review'} color={red} />
  }

  if (packageData.featured) {
    return <InfoChip text={'Featured'} />
  }

  return null
}

AdditionalStatusCell.propTypes = {
  packageData: PropTypes.object
}

export default AdditionalStatusCell
