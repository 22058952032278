import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import {
  GET_MARGINS,
  getMarginsFailure,
  getMarginsSuccess,
  CREATE_MARGINS,
  createMarginsSuccess,
  createMarginsFailure,
  getMargins,
  CREATE_MARGIN_OVERRIDE,
  createMarginOverrideSuccess,
  createMarginOverrideFailure,
  DELETE_MARGIN_OVERRIDE,
  deleteMarginOverrideSuccess,
  deleteMarginOverrideFailure
} from '../actions/margins'
import { selectedSelfAccountSelector } from '../selectors/app'
import {
  getMarginsService,
  createMarginsService,
  createMarginOverrideService,
  deleteMarginOverrideService
} from '../services/margins'

function* marginsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_MARGINS, getMarginsWorker),
    takeEvery(CREATE_MARGINS, createMarginsWorker),
    takeEvery(CREATE_MARGIN_OVERRIDE, createMarginOverrideWorker),
    takeEvery(DELETE_MARGIN_OVERRIDE, deleteMarginOverrideWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getMarginsWorker({ params }) {
  try {
    const response = yield call(getMarginsService, params)
    yield put(getMarginsSuccess(response))
  } catch (e) {
    yield put(getMarginsFailure(e))
  }
}

function* createMarginsWorker({ marginsData }) {
  try {
    const response = yield call(createMarginsService, marginsData)
    yield put(createMarginsSuccess(response))

    // as BE manipulates previous created margins, these needs to be fetched again after new creation
    const selectedSelfAccount = yield select(selectedSelfAccountSelector)
    yield put(getMargins({ account: selectedSelfAccount }))
  } catch (e) {
    yield put(createMarginsFailure(e))
  }
}

function* createMarginOverrideWorker({ marginOverrideData }) {
  try {
    const response = yield call(createMarginOverrideService, marginOverrideData)
    yield put(createMarginOverrideSuccess(response))

    // as BE manipulates previous created margins, these needs to be fetched again after new creation
    const selectedSelfAccount = yield select(selectedSelfAccountSelector)
    yield put(getMargins({ account: selectedSelfAccount }))
  } catch (e) {
    yield put(createMarginOverrideFailure(e))
  }
}

function* deleteMarginOverrideWorker({ id }) {
  try {
    yield call(deleteMarginOverrideService, id)
    yield put(deleteMarginOverrideSuccess(id))
  } catch (e) {
    yield put(deleteMarginOverrideFailure(e))
  }
}

export default marginsWatcher
