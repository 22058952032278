import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { formatDateFullDayMonthAndYear } from '../../../../../../constants/dates'

import useCommonStyles from '../../../../../../styles/common/table'
import useStyles from './styles'

const NameField = ({ name, startedDate, endDate }) => {
  const commonClasses = useCommonStyles()
  const classes = useStyles()

  let convertedDate = useMemo(() => {
    if (startedDate && endDate) {
      return `${formatDateFullDayMonthAndYear(startedDate)} - ${formatDateFullDayMonthAndYear(endDate)}`
    } else if (startedDate) {
      return formatDateFullDayMonthAndYear(startedDate)
    } else {
      return ''
    }
  }, [startedDate, endDate])

  return (
    <div className={classes.wrapper}>
      <div className={classes.mainText}>{name}</div>
      {convertedDate && <div className={commonClasses.infoText}>{convertedDate}</div>}
    </div>
  )
}

NameField.propTypes = {
  name: PropTypes.string,
  startedDate: PropTypes.string
}

export default NameField
