import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import FileFormatIcon from '../../Icons/FileFormatIcon'

import { APPROVED, BUYER_DISAPPROVED, DISAPPROVED, PENDING_BUYER_APPROVAL } from '../helpers'
import { getFileFormatFromName } from '../../../helpers/other'

import useStyles from './styles'

const UploadedFileThumbnail = ({ file }) => {
  const { approval_status: status, thumbnail, name: fileName } = file
  const fileFormat = getFileFormatFromName(fileName)

  const classes = useStyles()

  return (
    <div
      className={classnames(classes.thumbnailContainer, {
        [classes.thumbnailApprovedStatus]: status === APPROVED,
        [classes.thumbnailDisapprovedStatus]: status === DISAPPROVED || status === BUYER_DISAPPROVED,
        [classes.pendingBuyerApproval]: status === PENDING_BUYER_APPROVAL
      })}
    >
      {thumbnail ? (
        <img className={classes.thumbnail} src={thumbnail} alt="thumbnail" />
      ) : (
        <FileFormatIcon
          fileFormat={fileFormat}
          className={classes.fileFormatIcon}
          fileFormatClassName={classes.fileFormat}
        />
      )}
    </div>
  )
}

UploadedFileThumbnail.propTypes = {
  file: PropTypes.shape({
    approval_status: PropTypes.string,
    thumbnail: PropTypes.string,
    name: PropTypes.string
  })
}

export default UploadedFileThumbnail
