import { createStyles } from '../../../../../styles/helpers'

import { darkGrey, mediumGrey } from '../../../../../styles/const/colors'
import { phonesDown } from '../../../../../styles/const/breakpoints'

export default createStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: 24,
    background: 'white',
    padding: '18px 24px 18px 30px',
    border: `1px solid ${mediumGrey}`,
    borderRadius: theme.bigBorderRadius
  },
  description: {
    marginTop: 4,
    color: darkGrey
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 24
  },

  [`@media screen and (${phonesDown})`]: {
    container: {
      flexDirection: 'column',
      gap: 14
    }
  }
}))
