import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'

import DatesScheduleSelector from '../DatesScheduleSelector'
import { useMediaQuery } from 'react-responsive'
import classnames from 'classnames'

import { scheduleSelectorMondayDate } from '../../constants/dates'
import { phonesDownSize } from '../../styles/const/breakpoints'

import useStyles from './styles'

const ScheduleSelector = ({ name, value, setFieldValue }) => {
  const classes = useStyles()

  const [schedule, setSchedule] = useState(value)

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const onChangeHandler = useCallback(
    value => {
      if (schedule.length !== value.length) {
        setSchedule(value)

        // set formik value
        setFieldValue(name, value)
      }
    },
    [setFieldValue, name, schedule]
  )

  return (
    <div className={classnames('field', classes.wrapper)}>
      <DatesScheduleSelector
        selection={schedule}
        numDays={7}
        minTime={0}
        maxTime={24}
        // random monday date, which user doesn't see, but we need it for correct work
        startDate={new Date(scheduleSelectorMondayDate)}
        // disable library strange hover/focus effects for mobile
        hoveredColor={isMobile ? '' : '#a2c6f8'}
        columnGap="2px"
        rowGap="2px"
        renderTimeLabel={time => <div className={classes.timeLabel}>{format(time, 'HH:mm')}</div>}
        renderDateLabel={date => <div className={classes.dateLabel}>{format(date, 'EE')}</div>}
        onChange={onChangeHandler}
      />
    </div>
  )
}

ScheduleSelector.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired
}

export default ScheduleSelector
