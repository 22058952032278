import React, { useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'
import { useSelector } from 'react-redux'
import { areIntervalsOverlapping, format, startOfMonth } from 'date-fns'

import PeriodsDateRange from '../Reusable/PeriodsDateRange'
import ErrorMessage from '../../../../../../../../../components/Form/ErrorMessage'
import SelectedPeriodsList from '../Reusable/SelectedPeriodsList'
import PeriodDateCell from '../../../../../../../../../features/components/MediaOrder/PeriodDateCell'

import { SelectPeriodsContext } from '../../../SelectPeriodsContext'
import { useInventoryPeriods } from './hooks/useInventoryPeriods'
import { useGetDisabledDates } from './hooks/useGetDisabledDates'

import calculateProductPrice from '../../../helpers/calculateProductPrice'
import { formatDateToBE } from '../../../../../../../../../constants/dates'
import {
  getEndAdjustedDate,
  sortByStartDateValue
} from '../../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/ProductSetupStep/ProductSetupFields/SelectPeriodsFields/BookingPeriodSelector/DatePickerPeriodSelector/helpers'
import { selectedControllerDataSelector } from '../../../../../../../../../modules/selectors/app'
import { inventoryLastItemSelector } from '../../../../../../../../../modules/selectors/mediaOrdersProducts'

import { CUSTOM_PRODUCT_PRICE, ORIGINAL_PRODUCT_PRICE, QUANTITY } from '../../../../../fields'
import { PERIOD_DAYS_QUANTITY } from '../../../../../../../../../constants/mediaOrders'
import { PRODUCT_GROUPED_PUBLICATIONS_DATES } from '../../../../../../fields'

import useStyles from './styles'

// This component allows to select booking period for the product using the date range
// Each of selected date range is represented as a group of periods, in meanwhile we still manage the selected periods separately
// The grouped periods are used ONLY to display the selected date range in this component
// The selected periods are used to manage the selected dates in the form, send to BE and etc..
function CustomDatePeriodSelector({
  formik,
  selectedPeriods,
  productItemPath,
  productPublicationsDatesPath,
  checkInventory
}) {
  const classes = useStyles()
  const { values, setFieldValue, errors, touched } = formik

  const [shownMonth, setShownMonth] = useState(startOfMonth(new Date()))
  const [selectedStartDate, setSelectedStartDate] = useState(null)
  const [isEndDateSelection, setIsEndDateSelection] = useState(false)

  const { allowToFetchPreviousPeriods, productPeriod, currencySymbol } = useContext(SelectPeriodsContext)
  const inventoryLastItem = useSelector(inventoryLastItemSelector)
  const selectedController = useSelector(selectedControllerDataSelector)

  const daysInPeriod = PERIOD_DAYS_QUANTITY[productPeriod]
  const beforeAfterPercentage = selectedController?.before_percentage || 0
  const additionalAvailableDays = beforeAfterPercentage ? Math.round(daysInPeriod * beforeAfterPercentage) : 0

  const quantity = getIn(values, `${productItemPath}.${QUANTITY}`)
  const selectedGroupedPeriods = getIn(values, `${productItemPath}.${PRODUCT_GROUPED_PUBLICATIONS_DATES}`)

  const { periodOptions, firstPeriod, lastPeriod, availablePeriods } = useInventoryPeriods({
    selectedPeriods,
    additionalAvailableDays
  })

  console.log('selectedPeriods', selectedPeriods)
  console.log('availablePeriods', availablePeriods)

  const { disabledDates, datesStatuses } = useGetDisabledDates({
    firstPeriod: allowToFetchPreviousPeriods ? shownMonth : firstPeriod,
    lastPeriod,
    selectedPeriods,
    availablePeriods,
    additionalAvailableDays
  })

  console.log('disabledDates', disabledDates)
  console.log('datesStatuses', datesStatuses)

  const handleClose = useCallback(() => {
    setIsEndDateSelection(false)
  }, [])

  const handleStartDateSelect = useCallback(startDate => {
    setSelectedStartDate(startDate)
    setIsEndDateSelection(true)
  }, [])

  const handleDaysPeriodSelection = useCallback(
    ({ endDate, startDate }) => {
      const dateRangeStart = new Date(startDate)
      dateRangeStart.setHours(0, 0, 0, 0)
      const dateRangeEnd = new Date(endDate)
      dateRangeEnd.setHours(23, 59, 59, 999)

      // First, check if any single period fully covers the selected date range
      const coveringPeriod = availablePeriods.find(period => {
        const startPeriodDate = new Date(period.date_start)
        startPeriodDate.setHours(0, 0, 0, 0)

        const endPeriodDate = new Date(period.date_end)
        endPeriodDate.setHours(23, 59, 59, 999)

        // Adjusted period dates with additionalAvailableDays
        const adjustedStartDate = new Date(startPeriodDate)
        adjustedStartDate.setDate(adjustedStartDate.getDate() - additionalAvailableDays)

        const adjustedEndDate = new Date(endPeriodDate)
        adjustedEndDate.setDate(adjustedEndDate.getDate() + additionalAvailableDays)

        // Check if the selected date range is entirely within adjusted period
        return (
          adjustedStartDate.getTime() <= dateRangeStart.getTime() && adjustedEndDate.getTime() >= dateRangeEnd.getTime()
        )
      })

      if (coveringPeriod) {
        // The selected date range is fully covered by one period
        const adjustedPeriod = {
          ...coveringPeriod,
          date_start: formatDateToBE(dateRangeStart),
          date_end: formatDateToBE(dateRangeEnd)
        }

        // Update periods
        const newPeriods = [adjustedPeriod]

        const sortedNewGroupPeriods = [...selectedGroupedPeriods, newPeriods].sort(
          (a, b) => new Date(a[0].value) - new Date(b[0].value)
        )

        setFieldValue(`${productItemPath}.${PRODUCT_GROUPED_PUBLICATIONS_DATES}`, sortedNewGroupPeriods)

        const sortedNewPeriods = [...selectedPeriods, ...newPeriods].sort(sortByStartDateValue)

        const calculatedPrice = calculateProductPrice(sortedNewPeriods, 1, 0)

        setFieldValue(productPublicationsDatesPath, sortedNewPeriods)
        setFieldValue(`${productItemPath}.${ORIGINAL_PRODUCT_PRICE}`, calculatedPrice.totalPrice)
        setFieldValue(`${productItemPath}.${CUSTOM_PRODUCT_PRICE}`, calculatedPrice.totalPrice)
        setFieldValue(`${productItemPath}.${QUANTITY}`, 1)
      } else {
        // Proceed with the previous logic
        // Adjusted periods without overlaps
        const overlappingPeriods = availablePeriods
          .map(period => {
            const startPeriodDate = new Date(period.date_start)
            startPeriodDate.setHours(0, 0, 0, 0)

            const endPeriodDate = new Date(period.date_end)
            endPeriodDate.setHours(23, 59, 59, 999)

            // Adjust the period's available dates
            const periodAvailableStartDate = new Date(startPeriodDate)
            periodAvailableStartDate.setDate(periodAvailableStartDate.getDate() - additionalAvailableDays)

            const periodAvailableEndDate = new Date(endPeriodDate)
            periodAvailableEndDate.setDate(periodAvailableEndDate.getDate() + additionalAvailableDays)

            return {
              period,
              periodAvailableStartDate,
              periodAvailableEndDate,
              startPeriodDate, // Unadjusted start date
              endPeriodDate // Unadjusted end date
            }
          })
          .filter(({ periodAvailableStartDate, periodAvailableEndDate }) => {
            // Check for overlap with selected date range
            return areIntervalsOverlapping(
              { start: periodAvailableStartDate, end: periodAvailableEndDate },
              { start: dateRangeStart, end: dateRangeEnd }
            )
          })
          .sort((a, b) => a.periodAvailableStartDate - b.periodAvailableStartDate)

        const newPeriods = []
        let currentDate = dateRangeStart

        for (let i = 0; i < overlappingPeriods.length; i++) {
          const { period, periodAvailableStartDate, periodAvailableEndDate } = overlappingPeriods[i]
          const nextPeriod = overlappingPeriods[i + 1]

          const adjustedStartDate = new Date(
            Math.max(currentDate.getTime(), periodAvailableStartDate.getTime(), dateRangeStart.getTime())
          )

          let adjustedEndDate = new Date(Math.min(periodAvailableEndDate.getTime(), dateRangeEnd.getTime()))

          if (nextPeriod) {
            const nextPeriodStartDate = new Date(nextPeriod.startPeriodDate)
            nextPeriodStartDate.setHours(0, 0, 0, 0)

            const dayBeforeNextPeriodStart = new Date(nextPeriodStartDate)
            dayBeforeNextPeriodStart.setDate(dayBeforeNextPeriodStart.getDate() - 1)
            dayBeforeNextPeriodStart.setHours(23, 59, 59, 999)

            adjustedEndDate = new Date(Math.min(adjustedEndDate.getTime(), dayBeforeNextPeriodStart.getTime()))
          }

          if (adjustedStartDate <= adjustedEndDate) {
            newPeriods.push({
              ...period,
              date_start: formatDateToBE(adjustedStartDate),
              date_end: formatDateToBE(adjustedEndDate)
            })

            currentDate = new Date(adjustedEndDate)
            currentDate.setDate(currentDate.getDate() + 1)
            currentDate.setHours(0, 0, 0, 0)
          } else {
            currentDate = adjustedStartDate
          }

          if (currentDate > dateRangeEnd) {
            break
          }
        }

        const sortedNewGroupPeriods = [...selectedGroupedPeriods, newPeriods].sort(
          (a, b) => new Date(a[0].start_period) - new Date(b[0].start_period)
        )

        setFieldValue(`${productItemPath}.${PRODUCT_GROUPED_PUBLICATIONS_DATES}`, sortedNewGroupPeriods)

        const sortedNewPeriods = [...selectedPeriods, ...newPeriods].sort(sortByStartDateValue)

        const calculatedPrice = calculateProductPrice(sortedNewPeriods, 1, 0)

        setFieldValue(productPublicationsDatesPath, sortedNewPeriods)
        setFieldValue(`${productItemPath}.${ORIGINAL_PRODUCT_PRICE}`, calculatedPrice.totalPrice)
        setFieldValue(`${productItemPath}.${CUSTOM_PRODUCT_PRICE}`, calculatedPrice.totalPrice)
        setFieldValue(`${productItemPath}.${QUANTITY}`, 1)
      }
    },
    [
      availablePeriods,
      selectedGroupedPeriods,
      setFieldValue,
      productItemPath,
      selectedPeriods,
      productPublicationsDatesPath,
      additionalAvailableDays
    ]
  )

  const minDate = useMemo(() => {
    if (isEndDateSelection) {
      return selectedStartDate
    } else {
      return firstPeriod || new Date()
    }
  }, [firstPeriod, isEndDateSelection, selectedStartDate])
  const maxDate = useMemo(() => {
    const lastItemEndDate = getEndAdjustedDate(inventoryLastItem?.date_end, additionalAvailableDays)
    console.log('lastItemEndDate', lastItemEndDate)

    return lastItemEndDate || undefined
  }, [additionalAvailableDays, inventoryLastItem?.date_end])

  const CustomDayComponent = useCallback(
    dayDate => {
      const formattedDate = format(dayDate, 'dd')
      // if the date is not a period start date, it should be greyed out (although still selectable)
      // once the start date is selected, if it’s not period end date it should be greyed out and also selectable
      const dateStatus = datesStatuses?.[formatDateToBE(dayDate)]
      const isPseudoDisabled = isEndDateSelection
        ? !dateStatus?.isOriginalEndPeriod
        : !dateStatus?.isOriginalStartPeriod
      // for performance reason we pass only formatted dates as well as make the search for the periodData only inside
      // when the data actually changed
      return (
        <PeriodDateCell
          currencySymbol={currencySymbol}
          formattedDate={formattedDate}
          formattedDateBE={formatDateToBE(dayDate)}
          periodOptions={periodOptions}
          datesStatuses={datesStatuses}
          isPseudoDisabled={isPseudoDisabled}
        />
      )
    },
    [currencySymbol, periodOptions, datesStatuses, isEndDateSelection]
  )

  const commonProps = useMemo(
    () => ({
      allowToFetchPreviousPeriods: allowToFetchPreviousPeriods,
      minDate: allowToFetchPreviousPeriods ? undefined : minDate,
      maxDate: maxDate,
      disabledDates: disabledDates,
      additionalAvailableDays,
      // UI:
      periodSelectorClassName: classes.periodSelector,
      CustomDayComponent: CustomDayComponent,
      // callbacks
      onShownDateChange: newMonthDate => setShownMonth(newMonthDate),
      onDateRangeChange: handleDaysPeriodSelection,
      onStartDateSelect: handleStartDateSelect,
      onClose: handleClose
    }),
    [
      classes.periodSelector,
      handleDaysPeriodSelection,
      allowToFetchPreviousPeriods,
      additionalAvailableDays,
      minDate,
      maxDate,
      disabledDates,
      handleStartDateSelect,
      handleClose,
      CustomDayComponent
    ]
  )
  const error = getIn(errors, productPublicationsDatesPath)
  const wasTouched = getIn(touched, productPublicationsDatesPath)

  return (
    <>
      <SelectedPeriodsList
        setFieldValue={setFieldValue}
        selectedQuantity={quantity}
        selectedPeriods={selectedPeriods}
        selectedGroupedPeriods={selectedGroupedPeriods}
        productItemPath={productItemPath}
        productPublicationsDatesPath={productPublicationsDatesPath}
        checkInventory={checkInventory}
      />
      <PeriodsDateRange {...commonProps} />
      {/* selectedPeriods?.length < 1 is workaround for the validation which stuck. Remove all to see error add 1
       the error remains until the 2nd is added. If there are 2 and 1 removed there is no error */}
      {selectedPeriods?.length < 1 && wasTouched && error && <ErrorMessage error={error} />}
    </>
  )
}

CustomDatePeriodSelector.propTypes = {
  formik: PropTypes.object.isRequired,
  productItemPath: PropTypes.string.isRequired,
  productPublicationsDatesPath: PropTypes.string.isRequired,
  selectedPeriods: PropTypes.array.isRequired
}
export default CustomDatePeriodSelector
