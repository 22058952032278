export const DISPLAY_NAME = 'display_name'

// identity icon uploading
export const IDENTITY_ICON_URL = 'profile_image'
export const IDENTITY_ICON_ID = 'avatar_icon_web_uri'
export const IDENTITY_ICON_RATIO = 'identity_icon_ratio'

export const initialIdentityIconValues = {
  [IDENTITY_ICON_URL]: '',
  [IDENTITY_ICON_ID]: ''
}

export const initialValues = {
  [DISPLAY_NAME]: '',
  ...initialIdentityIconValues
}
