import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SPEND_DAILY, SPEND_LIFETIME } from '../../../fields'
import { formatCurrency } from '../../../../../../../helpers/numbers'

import { lineItemSelector } from '../../../../../../../modules/selectors/lineItems'
import { selectedAdAccountDataSelector } from '../../../../../../../modules/selectors/app'

const SpendPreview = ({ spendType }) => {
  const { t } = useTranslation()

  const { currency_symbol } = useSelector(selectedAdAccountDataSelector)
  const lineItem = useSelector(lineItemSelector)

  const {
    min_spend_target_lifetime: minSpendTargetLifetime,
    spend_cap_lifetime: spendCapLifetime,
    min_spend_target_daily: minSpendTargetDaily,
    spend_cap_daily: spendCapDaily
  } = lineItem

  if (spendType === SPEND_LIFETIME) {
    const formattedMinSpendTargetLifetime = formatCurrency(
      minSpendTargetLifetime,
      { min: 2, max: 2 },
      { symbol: currency_symbol }
    )
    const formattedSpendCapLifetime = formatCurrency(spendCapLifetime, { min: 2, max: 2 }, { symbol: currency_symbol })

    return (
      <>
        {minSpendTargetLifetime && (
          <p>
            {t('Lifetime minimum spend')}: {formattedMinSpendTargetLifetime}
          </p>
        )}
        {spendCapLifetime && (
          <p>
            {t('Lifetime maximum spend')}: {formattedSpendCapLifetime}
          </p>
        )}
      </>
    )
  }

  if (spendType === SPEND_DAILY) {
    const formattedMinSpendTargetDaily = formatCurrency(
      minSpendTargetDaily,
      { min: 2, max: 2 },
      { symbol: currency_symbol }
    )
    const formattedSpendCapDaily = formatCurrency(spendCapDaily, { min: 2, max: 2 }, { symbol: currency_symbol })

    return (
      <>
        {minSpendTargetDaily && (
          <span>
            {t('Daily minimum spend')}: {formattedMinSpendTargetDaily}
          </span>
        )}
        {spendCapDaily && (
          <span>
            {t('Daily maximum spend')}: {formattedSpendCapDaily}
          </span>
        )}
      </>
    )
  }

  return null
}

export default SpendPreview
