import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'

import Form from '../../../../components/Form'
import QuotationSendFormContent from './QuotationSendFormContent'

import { transformValuesToBE } from './formatters'

import { clearSendQuotation, sendQuotation } from '../../../../modules/actions/quotations'
import {
  sendQuotationErrorSelector,
  sendQuotationIsLoadingSelector,
  sendQuotationWasSentSelector
} from '../../../../modules/selectors/quotations'

import { validationSchema } from './validation'
import { initialValues } from './fields'
import { QUOTATION_SEND } from '../../../../constants/forms'

function QuotationSendForm({ quotationData }) {
  const dispatch = useDispatch()

  const contractId = quotationData?.id

  const quotationWasSent = useSelector(sendQuotationWasSentSelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values)
      dispatch(sendQuotation(transformedData, contractId))
    },
    [dispatch, contractId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const clearItemSubmit = useCallback(() => dispatch(clearSendQuotation()), [dispatch])

  return (
    <Form
      formik={formik}
      formName={QUOTATION_SEND}
      // processing
      successSubmit={quotationWasSent}
      errorSelector={sendQuotationErrorSelector}
      isLoadingSelector={sendQuotationIsLoadingSelector}
      // after form submit
      clearHandler={clearItemSubmit}
      submitText={'Send Quotation'}
    >
      <QuotationSendFormContent formik={formik} selfAccountId={quotationData?.account} />
    </Form>
  )
}

QuotationSendForm.propTypes = {
  quotationData: PropTypes.object
}

export default QuotationSendForm
