import { createStyles } from '../../../../styles/helpers'

import { darkGrey, grey } from '../../../../styles/const/colors'

const useStyles = createStyles({
  wrapper: {
    marginTop: 24
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16
  },
  title: {
    fontSize: 16,
    fontWeight: 600
  },
  selectController: {
    fontSize: 14,
    color: grey,
    '&:hover': {
      color: darkGrey,
      cursor: 'pointer'
    }
  },
  checkbox: {
    marginTop: [12, '!important']
  }
})

export default useStyles
