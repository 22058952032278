import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'

import { groupCampaignsByProvider } from '../helpers'

import {
  clearCombinedCampaigns,
  getCombinedCampaigns,
  getCombinedCampaignsSuccess
} from '../../../modules/actions/combinedData'
import { combinedCampaignsWasLoadedSelector } from '../../../modules/selectors/combinedData'
import {
  mediaOrderCampaignsDateRangeSelector,
  mediaOrderSelector,
  mediaOrderWasLoadedSelector
} from '../../../modules/selectors/mediaOrders'

import { DATES_FORMAT_BE } from '../../../constants/dates'

export const combinedCampaignsInsights = [
  'impressions',
  'cpm',
  'cpa',
  'ctr',
  'clicks',
  'cpc',
  'reach',
  'engagement',
  'frequency',
  'spend',
  'purchase',
  'roas',
  'conversions'
]

const useLoadCombinedCampaigns = () => {
  const dispatch = useDispatch()

  const mediaOrder = useSelector(mediaOrderSelector)
  const mediaOrderWasLoaded = useSelector(mediaOrderWasLoadedSelector)
  const combinedCampaignsWasLoaded = useSelector(combinedCampaignsWasLoadedSelector)
  const mediaOrderCampaignsDateRange = useSelector(mediaOrderCampaignsDateRangeSelector)

  const { campaigns: mediaOrderCampaigns } = mediaOrder

  const { date_preset: datePreset, startDate, endDate } = mediaOrderCampaignsDateRange

  const hasMediaOrderCampaignsToLoad = !!mediaOrderCampaigns?.length

  const shouldSkipLoading = mediaOrderWasLoaded && !hasMediaOrderCampaignsToLoad

  const loadCampaigns = useCallback(() => {
    const campaignsGroupedByProvider = groupCampaignsByProvider(mediaOrderCampaigns)

    dispatch(
      getCombinedCampaigns({
        insights: combinedCampaignsInsights,
        date_from: startDate && format(startDate, DATES_FORMAT_BE),
        date_to: endDate && format(endDate, DATES_FORMAT_BE),
        date_preset: datePreset,
        providers: campaignsGroupedByProvider
      })
    )
  }, [dispatch, startDate, endDate, datePreset, mediaOrderCampaigns])

  useEffect(() => {
    // load initial campaigns
    if (hasMediaOrderCampaignsToLoad && !combinedCampaignsWasLoaded) {
      loadCampaigns()
    }
  }, [hasMediaOrderCampaignsToLoad, loadCampaigns, combinedCampaignsWasLoaded])

  useEffect(() => {
    // custom useEffect to re-load campaigns when date filter changes
    if (combinedCampaignsWasLoaded && hasMediaOrderCampaignsToLoad) {
      loadCampaigns()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, datePreset])

  // if we don't need to load campaigns, call success action with empty state to mock finished loading
  useEffect(() => {
    if (shouldSkipLoading) {
      dispatch(getCombinedCampaignsSuccess({}))
    }
  }, [dispatch, shouldSkipLoading])

  useEffect(
    () => () => {
      dispatch(clearCombinedCampaigns())
    },
    [dispatch]
  )

  return null
}

export default useLoadCombinedCampaigns
