import { call, put, all, takeEvery, select, take, race } from 'redux-saga/effects'

import { showToasts } from '../../helpers/toasts'
import {
  CREATE_ASSET,
  createAssetSuccess,
  createAssetFailure,
  GET_ASSETS,
  CLEAR_ASSETS,
  getAssetsSuccess,
  getAssetsFailure,
  CREATE_ASSETS_BATCH,
  createAssetsBatchSuccess,
  createAssetsBatchFailure,
  CREATE_ASSET_GROUP,
  createAssetGroupSuccess,
  createAssetGroupFailure,
  getAssetGroupsSuccess,
  GET_ASSET_GROUPS,
  getAssetGroupsFailure,
  GET_ASSET_GROUP,
  getAssetGroupSuccess,
  getAssetGroupFailure,
  UPDATE_ASSET_GROUP,
  updateAssetGroupSuccess,
  updateAssetGroupFailure,
  APPROVE_INTERNAL_ASSET_GROUP,
  approveInternalAssetGroupSuccess,
  approveInternalAssetGroupFailure,
  DISAPPROVE_INTERNAL_ASSET_GROUP,
  disapproveInternalAssetGroupSuccess,
  disapproveInternalAssetGroupFailure,
  DELETE_INTERNAL_ASSET_GROUP,
  deleteInternalAssetGroupSuccess,
  deleteInternalAssetGroupFailure,
  CREATE_ASSET_GROUP_SIGNAL,
  createAssetGroupSignalSuccess,
  createAssetGroupSignalFailure
} from '../actions/assets'
import { fileUploadToPlatformFailure, fileUploadToPlatformSuccess } from '../actions/files'
import { decrementInternalAdsItemsCount } from '../actions/ads'

import {
  createAssetService,
  getAssetsService,
  createAssetsBatchService,
  createAssetGroupService,
  getAssetGroupsService,
  getAssetGroupService,
  updateAssetGroupService,
  approveInternalAssetGroupService,
  disapproveInternalAssetGroupService,
  deleteInternalAssetGroupService,
  createAssetGroupSignalService
} from '../services/assets'

import { selectedPlatformSelector, selectedSelfAccountSelector } from '../selectors/app'
import { TOAST_TYPE } from '../../constants/other'
import { GOOGLE_PLATFORM } from '../../constants/selectLists/platformList'

function* assetsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(CREATE_ASSET, createAssetWorker),
    takeEvery(GET_ASSETS, getAssetsWorker),
    takeEvery(CREATE_ASSETS_BATCH, createAssetsBatchWorker),
    takeEvery(CREATE_ASSET_GROUP, createAssetGroupWorker),
    takeEvery(GET_ASSET_GROUPS, getAssetGroupsWorker),
    takeEvery(GET_ASSET_GROUP, getAssetGroupWorker),
    takeEvery(UPDATE_ASSET_GROUP, updateAssetGroupWorker),
    takeEvery(APPROVE_INTERNAL_ASSET_GROUP, approveInternalAssetGroupWorker),
    takeEvery(DISAPPROVE_INTERNAL_ASSET_GROUP, disapproveInternalAssetGroupWorker),
    takeEvery(DELETE_INTERNAL_ASSET_GROUP, deleteInternalAssetGroupWorker),
    takeEvery(CREATE_ASSET_GROUP_SIGNAL, createAssetGroupSignalWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* createAssetWorker({ assetData, isFileUploading, platform }) {
  try {
    const selectedPlatform = yield select(selectedPlatformSelector)

    const creatAdAssetData = yield call(createAssetService, assetData, platform || selectedPlatform)

    yield put(createAssetSuccess(creatAdAssetData))

    if (isFileUploading) {
      // google ads responds with new image name, so should be used name from payload i.e. assetData.name
      yield put(fileUploadToPlatformSuccess(creatAdAssetData, assetData.name))
    }
  } catch (error) {
    yield put(createAssetFailure(error))
    if (isFileUploading) {
      yield put(fileUploadToPlatformFailure(error, assetData.name))
      showToasts({
        type: TOAST_TYPE.error,
        message:
          'Sorry, there was an error uploading that file, please try again. If you keep experiencing errors please check the file or contact support.'
      })
    }
  }
}

function* getAssetsWorker({ params }) {
  try {
    const { response } = yield race({
      response: call(getAssetsService, params, GOOGLE_PLATFORM),
      // need to cancel previous task, if user change the page, but task still in progress
      cancel: take(CLEAR_ASSETS)
    })

    if (response) {
      yield put(getAssetsSuccess(response))
    }
  } catch (error) {
    yield put(getAssetsFailure(error))
  }
}

function* createAssetsBatchWorker({ assetsData, platform }) {
  try {
    const selectedPlatform = yield select(selectedPlatformSelector)

    const creatAdAssetData = yield call(createAssetsBatchService, assetsData, platform || selectedPlatform)

    yield put(createAssetsBatchSuccess(creatAdAssetData))
  } catch (error) {
    yield put(createAssetsBatchFailure(error))
  }
}

function* createAssetGroupWorker({ assetGroupData, platform }) {
  try {
    const selectedPlatform = yield select(selectedPlatformSelector)

    const selectedSelfAccount = yield select(selectedSelfAccountSelector)

    const preparedAssetGroupData = {
      ...assetGroupData,
      account_id: selectedSelfAccount
    }

    const creatAdAssetGroupData = yield call(
      createAssetGroupService,
      preparedAssetGroupData,
      platform || selectedPlatform
    )

    yield put(createAssetGroupSuccess(creatAdAssetGroupData))
  } catch (error) {
    yield put(createAssetGroupFailure(error))
  }
}

function* getAssetGroupsWorker({ params }) {
  try {
    const selectedPlatform = yield select(selectedPlatformSelector)

    const platform = params.platform || selectedPlatform

    const assetGroupsData = yield call(getAssetGroupsService, params, platform)

    yield put(getAssetGroupsSuccess(assetGroupsData))
  } catch (error) {
    yield put(getAssetGroupsFailure(error))
  }
}

function* getAssetGroupWorker({ params }) {
  try {
    const selectedPlatform = yield select(selectedPlatformSelector)

    const platform = params.platform || selectedPlatform

    const assetGroupData = yield call(getAssetGroupService, params, platform)

    yield put(getAssetGroupSuccess(assetGroupData))
  } catch (error) {
    yield put(getAssetGroupFailure(error))
  }
}

function* updateAssetGroupWorker({ id, data }) {
  try {
    // Only Google platform has AssetGroups
    const updatedAssetGroupData = yield call(updateAssetGroupService, id, data, GOOGLE_PLATFORM)

    yield put(updateAssetGroupSuccess(updatedAssetGroupData))
  } catch (error) {
    if (error.errors?.asset_group_asset_operation) {
      showToasts({
        type: TOAST_TYPE.error,
        message: error.errors?.asset_group_asset_operation
      })
    }
    yield put(updateAssetGroupFailure(error))
  }
}

function* approveInternalAssetGroupWorker({ id, provider }) {
  try {
    const response = yield call(approveInternalAssetGroupService, id)
    yield put(approveInternalAssetGroupSuccess(id, response))

    if (provider) {
      yield put(decrementInternalAdsItemsCount(provider))
    }

    showToasts({
      type: TOAST_TYPE.success,
      message: 'Asset group approved successfully'
    })
  } catch (error) {
    yield put(approveInternalAssetGroupFailure(error))
  }
}

function* disapproveInternalAssetGroupWorker({ id, data, provider }) {
  try {
    yield call(disapproveInternalAssetGroupService, id, data)
    yield put(disapproveInternalAssetGroupSuccess(id))

    if (provider) {
      yield put(decrementInternalAdsItemsCount(provider))
    }
  } catch (error) {
    yield put(disapproveInternalAssetGroupFailure(error))
  }
}

function* deleteInternalAssetGroupWorker({ id }) {
  try {
    yield call(deleteInternalAssetGroupService, id)
    yield put(deleteInternalAssetGroupSuccess(id))
  } catch (error) {
    yield put(deleteInternalAssetGroupFailure(error))
  }
}

function* createAssetGroupSignalWorker({ assetGroupSignalData, platform }) {
  try {
    const selectedPlatform = yield select(selectedPlatformSelector)

    const response = yield call(createAssetGroupSignalService, assetGroupSignalData, platform || selectedPlatform)

    yield put(createAssetGroupSignalSuccess(response))
  } catch (error) {
    yield put(createAssetGroupSignalFailure(error))
  }
}

export default assetsWatcher
