import { PERFORMANCE_MAX_TYPE } from '../CampaignForms/CampaignGoogleCreate/CampaignGoogleCreateForm/fields'
import {
  CAMPAIGN,
  CAMPAIGN_OPTION,
  CAMPAIGN_OPTION_EXISTING_CAMPAIGN
} from './UploadGoogleAdCreativeForm/CampaignForm/CampaignFormContent/fields'

export const getCampaignInitialValues = isCampaignsExist => ({
  // campaign
  [CAMPAIGN_OPTION]: isCampaignsExist ? CAMPAIGN_OPTION_EXISTING_CAMPAIGN : PERFORMANCE_MAX_TYPE,
  [CAMPAIGN]: ''
})
