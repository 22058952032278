import { createStyles } from '../../../../../styles/helpers'

import { textEllipsis } from '../../../../../styles/mixins/text'

export default createStyles({
  descriptionText: {
    fontWeight: 400,
    padding: 0,
    width: '100%',
    ...textEllipsis()
  },
  skeletonDescription: {
    maxWidth: '90%',
    maxHeight: 7,
    height: 7,
    margin: 0
  }
})
