import { concat } from '../../helpers/common'

const MODULE_NAME = 'BATCH_INFO/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_BATCH_INFO = concat(MODULE_NAME, 'GET_BATCH_INFO')
export const GET_BATCH_INFO_SUCCESS = concat(MODULE_NAME, 'GET_BATCH_INFO_SUCCESS')
export const GET_BATCH_INFO_FAILURE = concat(MODULE_NAME, 'GET_BATCH_INFO_FAILURE')
export const CLEAR_BATCH_INFO = concat(MODULE_NAME, 'CLEAR_BATCH_INFO')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

export function getBatchInfo(params) {
  return { type: GET_BATCH_INFO, params }
}

export function getBatchInfoSuccess(batchInfoData) {
  return { type: GET_BATCH_INFO_SUCCESS, batchInfoData }
}

export function getBatchInfoFailure(error) {
  return { type: GET_BATCH_INFO_FAILURE, error }
}

export function clearBatchInfo() {
  return { type: CLEAR_BATCH_INFO }
}
