import * as Yup from 'yup'

import { CONVERSIONS, ENGAGEMENT, REACH, TRAFFIC, VIDEO_VIEWS } from '../../../../../constants/campaigns'
import {
  BUDGET_DAILY,
  BUDGET_LIFETIME,
  BUDGET_OPTION,
  NAME,
  OBJECTIVE
} from '../../../../ReusableFormFields/CampaignForms/fields'
import { optimizeGoals } from '../../fields'
import nameValidation from '../../../../../features/validations/name'

export const TIKTOK_TARGET_ROAS = 'roas_bid'
export const TARGET_ROAS_CHECKBOX = 'roas_checkbox'
export const TIKTOK_SPECIAL_AD_CATEGORIES = 'industry_types'

export const optimizationGoalOptions = [
  {
    value: CONVERSIONS,
    label: 'Conversions'
  },
  {
    value: VIDEO_VIEWS,
    label: 'Video Views'
  },
  {
    value: REACH,
    label: 'Reach'
  },
  {
    value: TRAFFIC,
    label: 'Traffic'
  },
  {
    value: ENGAGEMENT,
    label: 'Community Interaction'
  }
]

export const initialValues = {
  [NAME]: '',
  [OBJECTIVE]: CONVERSIONS,
  // budget
  [BUDGET_OPTION]: BUDGET_LIFETIME,
  [BUDGET_LIFETIME]: '',
  [BUDGET_DAILY]: '',
  // target roas
  [TIKTOK_TARGET_ROAS]: '',
  [TARGET_ROAS_CHECKBOX]: false,
  // special ad categories
  [TIKTOK_SPECIAL_AD_CATEGORIES]: []
}

export const getShowBudgetSection = objective => {
  return objective !== REACH
}
export const validationSchema = Yup.object().shape({
  ...nameValidation,
  // budget
  [BUDGET_LIFETIME]: Yup.string().when(BUDGET_OPTION, {
    is: BUDGET_LIFETIME,
    then: () =>
      Yup.string().when(OBJECTIVE, {
        // additional check if step is showed
        is: campaignObjective => getShowBudgetSection(campaignObjective),
        then: () => Yup.string().required('Budget Required')
      })
  }),
  [BUDGET_DAILY]: Yup.string().when(BUDGET_OPTION, {
    is: BUDGET_DAILY,
    then: () =>
      Yup.string().when(OBJECTIVE, {
        // additional check if step is showed
        is: campaignObjective => getShowBudgetSection(campaignObjective),
        then: () => Yup.string().required('Budget Required')
      })
  }),
  // target roas
  [TIKTOK_TARGET_ROAS]: Yup.string().when(TARGET_ROAS_CHECKBOX, {
    is: true,
    then: () => Yup.string().required('Target ROAS Required')
  })
})

export const transformValuesToBE = (values, selectedAdAccountId) => {
  const showBudgetSection = getShowBudgetSection(values[OBJECTIVE])
  const includeBudgetLifetimeInPayload = values[BUDGET_OPTION] === BUDGET_LIFETIME && showBudgetSection
  const includeBudgetDailyInPayload = values[BUDGET_OPTION] === BUDGET_DAILY && showBudgetSection

  const specialAdCategories = values[TIKTOK_SPECIAL_AD_CATEGORIES]

  return {
    account: selectedAdAccountId,
    name: values[NAME],
    objective: values[OBJECTIVE].toUpperCase(),
    // budget
    ...(includeBudgetLifetimeInPayload && {
      budget_lifetime: values[BUDGET_LIFETIME],
      buying_type: 'BUDGET_MODE_TOTAL',
      budget_optimize_on: true
    }),
    ...(includeBudgetDailyInPayload && {
      budget_daily: values[BUDGET_DAILY],
      buying_type: 'BUDGET_MODE_DAY',
      bid_type: 'BID_TYPE_NO_BID',
      optimize_goal: optimizeGoals[values[OBJECTIVE]],
      budget_optimize_on: true
    }),
    ...(!showBudgetSection && { buying_type: 'BUDGET_MODE_INFINITE' }),

    // target roas
    ...(!!values[TARGET_ROAS_CHECKBOX] && {
      [TIKTOK_TARGET_ROAS]: values[TIKTOK_TARGET_ROAS],
      deep_bid_type: 'VO_MIN_ROAS'
    }),

    // special ad categories
    ...(specialAdCategories.length && {
      [TIKTOK_SPECIAL_AD_CATEGORIES]: specialAdCategories.map(item => item.value.toUpperCase())
    })
  }
}
