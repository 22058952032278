import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import MediaCategoryFields from '../../../MediaProductCreate/MediaProductCreateForm/Steps/DetailsStep/MediaCategoryFields'

import useDrawerFormStyles from '../../../../../../styles/common/drawerForms'

const MediaCategorySection = ({ formik, allowShowSelectChannelField }) => {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormStyles()

  return (
    <section className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>{t('Product Category')}</h3>

      <MediaCategoryFields formik={formik} allowShowSelectChannelField={allowShowSelectChannelField} />
    </section>
  )
}

MediaCategorySection.propTypes = {
  formik: PropTypes.object.isRequired,
  allowShowSelectChannelField: PropTypes.bool
}

export default MediaCategorySection
