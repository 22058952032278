import { createStyles } from '../../../../styles/helpers'

import { greyOutline, green } from '../../../../styles/const/colors'
import { transitionShort } from '../../../../styles/const/common'

const useStyles = createStyles(theme => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
    rowGap: 20,
    fontSize: 12,
    fontWeight: 600,
    position: 'relative',
    color: greyOutline,
    transition: transitionShort,
    '&.active': {
      color: theme.brandPrimary
    },
    '&.active $number': {
      borderColor: theme.brandPrimary
    },
    '&.success': {
      color: green,
      cursor: 'pointer',
      '&::after': {
        background: green
      },
      '&:hover': {
        color: theme.brandPrimary
      }
    },
    '&:not(:last-child)': {
      marginBottom: 40,
      '&::after': {
        content: "''",
        position: 'absolute',
        top: 30,
        left: 12,
        width: 1,
        height: 30,
        background: greyOutline
      }
    }
  },
  checkIcon: {
    height: 25,
    width: 25
  },
  number: {
    maxWidth: 25,
    maxHeight: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'inherit',
    padding: '4px 8px',
    border: `2px solid ${greyOutline}`,
    borderRadius: '50%'
  }
}))

export default useStyles
