import { madeRequest } from '../../helpers/api'
import { ENDPOINTS } from '../../constants/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getMediaProductVariablesService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.mediaProductVariables
  })
}

export function createProductVariableService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.mediaProductVariables
  })
}

export function updateProductVariableService(data, id) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.mediaProductVariables}${id}/`
  })
}

export function deleteMediaProductVariableService(id) {
  return madeRequest('DELETE', {
    url: `${ENDPOINTS.mediaProductVariables}${id}/`
  })
}

export function getMediaProductVariableOptionsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.mediaProductVariableOptions
  })
}

export function getMediaProductVariablesBulkListService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.mediaProductVariableOptionsBulkList
  })
}
