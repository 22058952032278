import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Button from '../../../../components/Button'
import SuccessModal from '../../../../features/components/Modals/SuccessModal'

import { redirectTo } from '../../../../helpers/url'
import { openForm } from '../../../../modules/actions/forms'
import { setMediaOrderUploadCreative } from '../../../../modules/actions/mediaOrders'

import { UPLOAD_FACEBOOK_CREATIVE } from '../../../../constants/forms'
import { PRODUCT_RETARGETING_ROUTE } from '../fields'
import { ROUTE_PARAMS, ROUTES } from '../../../../constants/routes'
import { FACEBOOK_PLATFORM } from '../../../../constants/selectLists/platformList'
import { MODAL_TYPE } from '../../../../constants/other'

const UploadAdCreativeSuccessModal = ({
  isInternalAdCreation,
  setCreatedCreativeData,
  createdCreativeData,
  handleSelectRoute
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { selectedRoute } = createdCreativeData

  const uploadAnotherAdHandler = useCallback(() => {
    const { campaign } = createdCreativeData

    handleSelectRoute(selectedRoute)

    // set only campaign data as line item need to be selected or created
    dispatch(setMediaOrderUploadCreative({ campaign }))
    dispatch(openForm({ formName: UPLOAD_FACEBOOK_CREATIVE }))
  }, [dispatch, handleSelectRoute, createdCreativeData, selectedRoute])

  const modalCloseHandler = useCallback(() => {
    setCreatedCreativeData({})
  }, [setCreatedCreativeData])

  const viewAdHandler = useCallback(() => {
    const { adAccountId, campaign, lineItem } = createdCreativeData

    redirectTo(ROUTES.lineItemsSummary, {
      [ROUTE_PARAMS.platform]: FACEBOOK_PLATFORM,
      [ROUTE_PARAMS.adAccount]: adAccountId,
      [ROUTE_PARAMS.campaignId]: campaign.id,
      [ROUTE_PARAMS.lineItemId]: lineItem.id
    })
  }, [createdCreativeData])

  return (
    <SuccessModal
      successButtonText={t(
        selectedRoute === PRODUCT_RETARGETING_ROUTE ? 'Upload another retargeting ad' : 'Upload another Facebook ad'
      )}
      successButtonAction={uploadAnotherAdHandler}
      modalType={MODAL_TYPE.mediaOrderFacebookCreativeUploaded}
      ButtonLeft={<Button onClick={viewAdHandler}>{t('View Ad')}</Button>}
      onAfterClose={modalCloseHandler}
    >
      <p>
        {t(
          isInternalAdCreation
            ? 'Your ad has been uploaded successfully and is pending review.'
            : 'Your ad has been uploaded successfully.'
        )}
      </p>
    </SuccessModal>
  )
}

UploadAdCreativeSuccessModal.propTypes = {
  isInternalAdCreation: PropTypes.bool.isRequired,
  createdCreativeData: PropTypes.object.isRequired,
  setCreatedCreativeData: PropTypes.func.isRequired,
  handleSelectRoute: PropTypes.func.isRequired
}

export default UploadAdCreativeSuccessModal
