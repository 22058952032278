import React from 'react'
import PropTypes from 'prop-types'

import Chip from '../../../../components/Chip'
import ButtonClose from '../../../../features/components/Buttons/ButtonClose'
import { ReactComponent as LinkIcon } from '../../../../assets/icons/new-tab.svg'

import { CHIP_COLORS } from '../../../../constants/other'

import useStyles from './styles'

const ProductCard = ({
  product,
  isSelected,
  showLinkText = false,
  showInventoryChip = true,
  onProductSelectHandler,
  handleRemoveProduct,
  withMarginBottom = false
}) => {
  const classes = useStyles({
    isSelected,
    withRemoveButton: !!handleRemoveProduct,
    withMarginBottom
  })

  const { name, thumbnail_url: imageSrc, brand, inventory, link } = product

  const isInventoryExists = !!inventory

  const inventoryChipText = isInventoryExists ? 'In Stock' : 'Out Of Stock'
  const inventoryChipColor = isInventoryExists ? CHIP_COLORS.green : CHIP_COLORS.red

  const onProductSelect = () => {
    onProductSelectHandler(product)
  }

  const handleRemove = () => {
    handleRemoveProduct(product)
  }

  return (
    <div className={classes.productCard} onClick={onProductSelect}>
      {link && (
        <a
          href={link}
          className={classes.productLink}
          onClick={e => e.stopPropagation()}
          target="_blank"
          rel="noreferrer"
        >
          <LinkIcon className={classes.productLinkIcon} />
        </a>
      )}
      {handleRemoveProduct && <ButtonClose className={classes.removeButton} onClick={handleRemove} />}
      <div className={classes.productCardImageContainer}>
        <img src={imageSrc} alt="product" className={classes.productCardImage} />
      </div>
      <div className={classes.productCardDetails}>
        <h6 className={classes.productName}>{name}</h6>
        {showLinkText && link && <p className={classes.productLinkText}>{link}</p>}
        <div className={classes.productChips}>
          {brand && <Chip text={brand} />}
          {showInventoryChip && <Chip text={inventoryChipText} color={inventoryChipColor} />}
        </div>
      </div>
    </div>
  )
}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  showLinkText: PropTypes.bool,
  showInventoryChip: PropTypes.bool,
  onProductSelectHandler: PropTypes.func.isRequired,
  handleRemoveProduct: PropTypes.func,
  withMarginBottom: PropTypes.bool
}

export default ProductCard
