import React from 'react'
import PropTypes from 'prop-types'

import GoogleMediaFilesPreview from '../../../../GoogleMediaFilesPreview'

import { getAdSquareLogoAssets } from '../../helpers'

const SquareLogoSectionPreview = ({ ad }) => {
  const squareLogoAssets = getAdSquareLogoAssets(ad)

  return <GoogleMediaFilesPreview assets={squareLogoAssets} assetDataName="asset" />
}

SquareLogoSectionPreview.propTypes = {
  ad: PropTypes.object
}

export default SquareLogoSectionPreview
