import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Checkbox from '../../../../../../../../components/Form/Checkbox'
import ErrorMessage from '../../../../../../../../components/Form/ErrorMessage'

import {
  DV_360_PROVIDER_PLATFORM,
  FACEBOOK_PROVIDER_PLATFORM,
  GOOGLE_PROVIDER_PLATFORM,
  TIKTOK_PROVIDER_PLATFORM
} from '../../../../../../../../constants/selectLists/platformList'

import { PLATFORMS } from '../../../../../MediaProductCreate/MediaProductCreateForm/fields'

const PlatformsCheckboxes = ({ formik }) => {
  const { t } = useTranslation()

  const { values, errors, touched, setFieldValue } = formik

  const platformCheckHandler = useCallback(
    e => {
      const { name: platformName } = e.target

      setFieldValue(`${[PLATFORMS]}.${platformName}`, !values[PLATFORMS][platformName])
    },
    [values, setFieldValue]
  )

  const showPlatformsErrorMessage = errors[PLATFORMS] && touched[PLATFORMS]

  return (
    <>
      <Checkbox
        id="checkbox_platform_facebook"
        title="Facebook"
        isLarge
        name={FACEBOOK_PROVIDER_PLATFORM}
        checked={values[PLATFORMS][FACEBOOK_PROVIDER_PLATFORM]}
        onCheck={platformCheckHandler}
      >
        {t('Facebook and Instagram')}
      </Checkbox>
      <Checkbox
        id="checkbox_platform_google"
        title="Google Ads"
        isLarge
        name={GOOGLE_PROVIDER_PLATFORM}
        checked={values[PLATFORMS][GOOGLE_PROVIDER_PLATFORM]}
        onCheck={platformCheckHandler}
      >
        {t('Performance Max campaigns')}
      </Checkbox>
      <Checkbox
        id="checkbox_platform_dv360"
        title="Display Video 360"
        isLarge
        name={DV_360_PROVIDER_PLATFORM}
        checked={values[PLATFORMS][DV_360_PROVIDER_PLATFORM]}
        onCheck={platformCheckHandler}
      >
        {t('Display campaigns')}
      </Checkbox>
      <Checkbox
        id="checkbox_platform_tiktok"
        title="TikTok"
        isLarge
        name={TIKTOK_PROVIDER_PLATFORM}
        checked={values[PLATFORMS][TIKTOK_PROVIDER_PLATFORM]}
        onCheck={platformCheckHandler}
      >
        {t('TikTok vertical video ads')}
      </Checkbox>
      {showPlatformsErrorMessage && <ErrorMessage error={errors[PLATFORMS]} />}
    </>
  )
}

export default PlatformsCheckboxes
