import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import MediaOrdersTableData from './MediaOrdersTableData'
import TableDataLoader from '../../../components/Table/TableDataLoader'

import {
  mediaOrdersWasLoadedSelector,
  mediaOrdersIsLoadingSelector,
  mediaOrdersErrorSelector
} from '../../../modules/selectors/mediaOrders'

import { tableColumnsSize, mobileColumnsSchema } from './columnSizes'
import { phonesDownSize } from '../../../styles/const/breakpoints'

import useCommonStyles from '../../../styles/common/table'

const MediaOrdersTable = ({ mediaOrders, loadMoreMediaOrdersHandler }) => {
  const { t } = useTranslation()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const commonClasses = useCommonStyles()

  const mediaOrdersColumnsSchema = useMemo(
    () => [
      {
        header: 'ID',
        style: { maxWidth: tableColumnsSize.id }
      },
      {
        header: 'Name'
      },
      {
        header: 'Media',
        style: { maxWidth: tableColumnsSize.media }
      },
      {
        header: 'Requested Files',
        style: { maxWidth: tableColumnsSize.requestedFiles }
      },
      {
        header: 'Cost',
        style: { maxWidth: tableColumnsSize.cost }
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no media orders')}</div>
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <TableDataLoader
      itemsLength={mediaOrders.length}
      errorSelector={mediaOrdersErrorSelector}
      wasLoadedSelector={mediaOrdersWasLoadedSelector}
      isLoadingSelector={mediaOrdersIsLoadingSelector}
      skeletonProps={{ cols: isMobile ? mobileColumnsSchema : mediaOrdersColumnsSchema }}
      noDataContent={noDataContent}
      loadMore={loadMoreMediaOrdersHandler}
    >
      <MediaOrdersTableData mediaOrders={mediaOrders} />
    </TableDataLoader>
  )
}

MediaOrdersTable.propTypes = {
  mediaOrders: PropTypes.array.isRequired,
  loadMoreMediaOrdersHandler: PropTypes.func
}

export default MediaOrdersTable
