import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { renderArrayObjectValueWithComas } from './helpers'

import { lineItemSelector } from '../../../../../../modules/selectors/lineItems'

import {
  CUSTOM_AUDIENCES,
  CUSTOM_AUDIENCES_EXCLUSION
} from '../../../../../ReusableFormFields/CustomAudiencesFields/fields'

const CustomAudiencesSectionPreview = () => {
  const { t } = useTranslation()

  const lineItem = useSelector(lineItemSelector)

  const { [CUSTOM_AUDIENCES]: customAudiences, [CUSTOM_AUDIENCES_EXCLUSION]: customAudiencesExclusion } = lineItem

  if (!customAudiences.length && !customAudiencesExclusion.length) {
    return null
  }

  return (
    <p>
      {!!customAudiences.length && (
        <span>
          {t('Custom audience inclusions: ')}
          {renderArrayObjectValueWithComas(customAudiences, 'name')}
        </span>
      )}
      {!!customAudiencesExclusion.length && (
        <span>
          {t('Custom audience exclusions: ')}
          {renderArrayObjectValueWithComas(customAudiencesExclusion, 'name')}
        </span>
      )}
    </p>
  )
}

export default CustomAudiencesSectionPreview
