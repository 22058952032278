export const tableColumnsSize = {
  id: 60,
  media: 550,
  requestedFiles: 280,
  cost: 120,
  actions: 40
}

export const dropdownColumnSizes = { minWidth: tableColumnsSize.actions, maxWidth: tableColumnsSize.actions }
export const mediaColumnSizesMobile = { minWidth: '50%', maxWidth: '50%' }
export const nameColumnSizesMobile = { minWidth: '100%', maxWidth: '100%' }

export const mobileColumnsSchema = [{ style: nameColumnSizesMobile }]
