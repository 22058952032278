import { getIn } from 'formik'

import store from '../store'
import { keys } from './common'

import { approvePlatformConnection } from '../modules/actions/app'

import {
  DV_360_PLATFORM,
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  TIKTOK_PLATFORM
} from '../constants/selectLists/platformList'

export function createErrorObject(data, status) {
  if (data.detail) {
    return {
      error: data.detail,
      message: data.detail,
      code: status
    }
  } else {
    return {
      errors: data,
      code: status
    }
  }
}

export function handleTokenExpiration(data) {
  const { facebook_token_expired, google_token_expired, dv360_token_expired, tiktok_token_expired } = data

  let expiredPlatformField
  let expiredPlatform

  // handle case when facebook token is expired - SHOP-973
  if (facebook_token_expired) {
    expiredPlatform = FACEBOOK_PLATFORM
    expiredPlatformField = { facebook_is_connected: false }
  } else if (google_token_expired) {
    expiredPlatform = GOOGLE_PLATFORM
    expiredPlatformField = { google_is_connected: false }
  } else if (dv360_token_expired) {
    expiredPlatform = DV_360_PLATFORM
    expiredPlatformField = { dv360_is_connected: false }
  } else if (tiktok_token_expired) {
    expiredPlatform = TIKTOK_PLATFORM
    expiredPlatformField = { tiktok_is_connected: false }
  }

  // redirect user to authorisePlatform page and set userProfile data related to platform connection
  if (expiredPlatformField && expiredPlatform) {
    store.dispatch(approvePlatformConnection(expiredPlatformField))
  }
}

export function getErrorMessage({ errors }) {
  // parsing first level of form type(object with fields) errors messages
  if (errors) {
    const errorFields = keys(errors)
    let errorMessage = ''
    errorFields.forEach(errorField => {
      if (errors[errorField]) {
        // take first error message from array of field errors
        const fieldErrors = errors[errorField]
        const fieldFirstError = Array.isArray(fieldErrors) ? fieldErrors[0] : fieldErrors
        errorMessage += `${errorField} - ${fieldFirstError} \n`
      }
    })
    return errorMessage
  }
}

export function getNonFieldErrorMessage(error) {
  // non field error could be either array or string
  if (error) {
    // return first error message
    return Array.isArray(error) ? error[0] : error
  } else {
    return null
  }
}

export function getFormikFieldError(errors, status, fieldName) {
  // server(BE) errors are saving in status due to native formik errors totally resets on Blur
  const serverError = status && status.serverErrors && status.serverErrors[fieldName]
  // The built-in getIn formik function allows to get and interact with nested fields like objects in array etc.
  const error = getIn(errors, fieldName) || serverError

  return { error, serverError }
}

export class ExtendedError extends Error {
  constructor(message, additionalProperties) {
    super(message) // Call the parent class constructor with the message
    Object.keys(additionalProperties).forEach(key => {
      this[key] = additionalProperties[key] // Assign additional properties to the error instance
    })
  }
}
