import React, { useCallback, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import CheckboxBlock from '../../../../../../features/components/Form/CheckboxBlock'
import EditForm from '../../../../../../features/components/Forms/EditForm'
import Field from '../../../../../../components/Form/Field'

import { MAXIMIZE_CONVERSION_VALUE, TARGET_ROAS } from '../../../../../ReusableFormFields/CampaignForms/fields'
import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'

import {
  campaignUpdateErrorSelector,
  campaignUpdateIsLoadingSelector,
  campaignWasUpdatedSelector
} from '../../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'
import { clearUpdateCampaign, updateCampaign } from '../../../../../../modules/actions/campaigns'

import useStyles from '../../../../../../styles/common/drawerForms'

const TargetRoasSectionForm = ({ editItemData: campaign, ...formProps }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const campaignWasUpdated = useSelector(campaignWasUpdatedSelector)

  const targetRoas = campaign[MAXIMIZE_CONVERSION_VALUE][TARGET_ROAS]

  // if target roas value exist (or better than 0), then pre-open section
  const [checkboxBlockOpen, setCheckboxBlockOpen] = useState(!!targetRoas)

  const handleSubmit = useCallback(
    values => {
      const updateCampaignData = {
        account: selectedAdAccountId,
        [MAXIMIZE_CONVERSION_VALUE]: {
          [TARGET_ROAS]: checkboxBlockOpen ? values[TARGET_ROAS] : 0
        }
      }

      dispatch(updateCampaign(updateCampaignData, campaign.id))
    },
    [dispatch, campaign.id, checkboxBlockOpen, selectedAdAccountId]
  )

  const initialValues = useMemo(
    () => ({
      [TARGET_ROAS]: String(targetRoas) || ''
    }),
    [targetRoas]
  )

  const validationSchema = Yup.object({
    ...(checkboxBlockOpen && {
      [TARGET_ROAS]: Yup.string()
        .required('Target ROAS required')
        .test({
          message: 'Please set the ROAS to a number greater than 0',
          test: value => Number(value) > 0
        })
    })
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const handleClearUpdateCampaign = useCallback(() => {
    dispatch(clearUpdateCampaign())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={campaignWasUpdated}
      clearEditItem={handleClearUpdateCampaign}
      errorSelector={campaignUpdateErrorSelector}
      isLoadingSelector={campaignUpdateIsLoadingSelector}
      {...formProps}
    >
      <CheckboxBlock
        id="checkbox_set_target_roas"
        title="Set target ROAS"
        className={classes.sectionCheckboxBlock}
        checked={checkboxBlockOpen}
        withBorder
        onCheck={() => {
          setCheckboxBlockOpen(!checkboxBlockOpen)
        }}
        isLarge
      >
        <Field
          type="number"
          formik={purifiedFormik}
          name={TARGET_ROAS}
          placeholder="Target Roas"
          autoComplete="off"
          inputMode="numeric"
        />
      </CheckboxBlock>
    </EditForm>
  )
}

export default TargetRoasSectionForm
