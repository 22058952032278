import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider, JssProvider } from 'react-jss'
import { create } from 'jss'
import preset from 'jss-preset-default'

import rtl from './jss-rtl'

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import 'react-toastify/dist/ReactToastify.css'
import 'react-phone-input-2/lib/high-res.css' // styles for phone number input
import '../../../styles/index.css'
import 'keen-slider/keen-slider.min.css'
import 'react-loading-skeleton/dist/skeleton.css'

import { getSelectedLanguageData } from '../../../constants/selectLists/languagesList'

import { selectedControllerColorSelector, selectedLanguageSelector } from '../../../modules/selectors/app'

import { shopometryTheme } from '../../../styles/themes/shopometryTheme'
import { getControllerTheme } from '../../../styles/themes/controllerTheme'
import { getDomainTheme } from '../../../styles/themes/helpers'

export const rtlStylePrefix = 'rtl-'
function StylesProvider({ children }) {
  const selfAccountControllerColor = useSelector(selectedControllerColorSelector)
  const selectedLanguageCode = useSelector(selectedLanguageSelector)

  const selectedLanguageData = getSelectedLanguageData(selectedLanguageCode)

  const textDirection = selectedLanguageData.textDirection
  const isRtl = textDirection === 'rtl'

  // todo figure out why after memoization rtl is not working on textDirection change
  // Configure JSS
  // const jss = useMemo(() => {
  //   create({ plugins: [...preset().plugins, rtl({ enabled: isRtl })] })
  // }, [isRtl])

  const jss = create({ plugins: [...preset().plugins, rtl({ enabled: isRtl })] })

  const theme = useMemo(() => {
    const controllerTheme = !!selfAccountControllerColor ? getControllerTheme(selfAccountControllerColor) : null
    const domainTheme = getDomainTheme()

    // Theme hierarchy is: controller -> domain -> shopometry , also each one is as fallback.
    // So for example when we on unauthorised pages it will be domain -> shopometry
    // If we don't have any specific styles for domain(for example font) then it will be just taken the shopometryfont and domain.color

    return {
      ...shopometryTheme,
      ...domainTheme,
      ...controllerTheme,
      // textDirection is not used in styles but is needed to trigger JSS classnames generation to update for RTL
      textDirection
    }
  }, [selfAccountControllerColor, textDirection])

  return (
    <JssProvider jss={jss} classNamePrefix={isRtl ? rtlStylePrefix : null}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </JssProvider>
  )
}

export default StylesProvider
