import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import useStyles from './styles'

const MaxLengthMessage = ({ length, maxLength = 400 }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return <div className={classes.maxLengthMessage}>{t('characterCountWithMax', { length, maxLength })}</div>
}

MaxLengthMessage.propTypes = {
  length: PropTypes.number,
  maxLength: PropTypes.number
}

export default MaxLengthMessage
