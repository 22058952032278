import React from 'react'
import { useTranslation } from 'react-i18next'

import SetupTOTPTokenForm from '../../../Settings/EditProfile/MFAContent/SetupTOTPTokenModal/SetupTOTPTokenForm'

import useDrawerFormsStyles from '../../../../styles/common/drawerForms'

const MfaTotpSetup = ({ onSuccessSetup }) => {
  const drawerFormClasses = useDrawerFormsStyles()
  const { t } = useTranslation()

  return (
    <>
      <h3 className={drawerFormClasses.sectionTitle}>🔒 {t('Register your authenticator app')}</h3>
      <p>
        {t(
          'Open your authenticator app and scan the QR code (or manually enter the token) in the app. Use Google Authenticator, Microsoft Authenticator or any other authenticator app.'
        )}
      </p>
      <br />

      <SetupTOTPTokenForm onSuccessSubmitHandler={onSuccessSetup} />
    </>
  )
}

export default MfaTotpSetup
