import { createStyles } from '../../../styles/helpers'

import { grey, mediumGrey } from '../../../styles/const/colors'

const useStyles = createStyles({
  list: {
    padding: 0
  },
  loadMoreBtn: {
    color: grey,
    border: 'none',
    display: 'block',
    margin: '24px auto'
  },
  noDataContent: {
    color: mediumGrey,
    fontWeight: 600,
    fontSize: 14
  }
})

export default useStyles
