import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

// Get amendment
export const amendmentSelector = createSelector(
  ({ amendments }) => amendments.getAmendment.data,
  data => data
)

export const getAmendmentIsLoadingSelector = createSelector(
  ({ amendments }) => amendments.getAmendment.isLoading,
  isLoading => isLoading
)

export const getAmendmentErrorSelector = createSelector(
  ({ amendments }) => amendments.getAmendment.error,
  error => error
)

export const getAmendmentWasLoadedSelector = createSelector(
  ({ amendments }) => amendments.getAmendment.wasLoaded,
  wasLoaded => wasLoaded
)

// Get amendments list
export const amendmentsListSelector = createSelector(
  ({ amendments }) => amendments.getAmendmentsList.results,
  results => results
)

export const getAmendmentsListIsLoadingSelector = createSelector(
  ({ amendments }) => amendments.getAmendmentsList.isLoading,
  isLoading => isLoading
)

export const getAmendmentsListErrorSelector = createSelector(
  ({ amendments }) => amendments.getAmendmentsList.error,
  error => error
)

export const getAmendmentsListWasLoadedSelector = createSelector(
  ({ amendments }) => amendments.getAmendmentsList.wasLoaded,
  wasLoaded => wasLoaded
)

// Delete amendment contract signature
export const deleteAmendmentContractSignatureSelector = createSelector(
  ({ amendments }) => amendments.deleteAmendmentContractSignature.results,
  results => results
)

export const deleteAmendmentContractSignatureIsLoadingSelector = createSelector(
  ({ amendments }) => amendments.deleteAmendmentContractSignature.isLoading,
  isLoading => isLoading
)

export const deleteAmendmentContractSignatureErrorSelector = createSelector(
  ({ amendments }) => amendments.deleteAmendmentContractSignature.error,
  error => error
)

export const deleteAmendmentContractSignatureWasLoadedSelector = createSelector(
  ({ amendments }) => amendments.deleteAmendmentContractSignature.wasLoaded,
  wasLoaded => wasLoaded
)

// Sign amendment contract
export const signAmendmentContractSelector = createSelector(
  ({ amendments }) => amendments.signAmendmentContract.results,
  results => results
)

export const signAmendmentContractIsLoadingSelector = createSelector(
  ({ amendments }) => amendments.signAmendmentContract.isLoading,
  isLoading => isLoading
)

export const signAmendmentContractErrorSelector = createSelector(
  ({ amendments }) => amendments.signAmendmentContract.error,
  error => error
)

export const signAmendmentContractWasCreatedSelector = createSelector(
  ({ amendments }) => amendments.signAmendmentContract.wasCreated,
  wasCreated => wasCreated
)

// Create amendment
export const createdAmendmentSelector = createSelector(
  ({ amendments }) => amendments.createAmendment.data,
  data => data
)

export const createAmendmentIsLoadingSelector = createSelector(
  ({ amendments }) => amendments.createAmendment.isLoading,
  isLoading => isLoading
)

export const createAmendmentErrorSelector = createSelector(
  ({ amendments }) => amendments.createAmendment.error,
  error => error
)

export const amendmentWasCreatedSelector = createSelector(
  ({ amendments }) => amendments.createAmendment.wasCreated,
  wasCreated => wasCreated
)

export const activeAmendmentContractSelector = createSelector(
  ({ amendments }) => amendments.activeAmendmentContract,
  contractData => contractData
)

// Send amendment contract
export const sendAmendmentContractSelector = createSelector(
  ({ amendments }) => amendments.sendAmendmentContract.results,
  results => results
)

export const sendAmendmentContractIsLoadingSelector = createSelector(
  ({ amendments }) => amendments.sendAmendmentContract.isLoading,
  isLoading => isLoading
)

export const sendAmendmentContractErrorSelector = createSelector(
  ({ amendments }) => amendments.sendAmendmentContract.error,
  error => error
)

export const sendAmendmentContractWasSendSelector = createSelector(
  ({ amendments }) => amendments.sendAmendmentContract.wasCreated,
  wasCreated => wasCreated
)

// Submit amendment
export const submitAmendmentSelector = createSelector(
  ({ amendments }) => amendments.submitAmendment.data,
  data => data
)

export const submitAmendmentIsLoadingSelector = createSelector(
  ({ amendments }) => amendments.submitAmendment.isLoading,
  isLoading => isLoading
)

export const submitAmendmentErrorSelector = createSelector(
  ({ amendments }) => amendments.submitAmendment.error,
  error => error
)

export const submitAmendmentWasLoadedSelector = createSelector(
  ({ amendments }) => amendments.submitAmendment.wasCreated,
  wasCreated => wasCreated
)
