import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import ActionsDropdown from '../../../../../features/components/ActionsDropdown'
import Table from '../../../../../components/Table'
import ActionsButtonsList from '../../../../../features/components/ActionsButtonsList'

import { openForm } from '../../../../../modules/actions/forms'

import { tableColumnsSize } from '../index'

import { CLIENT_ACCOUNT_EDIT } from '../../../../../constants/forms'

import { phonesDownSize } from '../../../../../styles/const/breakpoints'

import useStyles from './styles'

const ClientAccountsTableData = ({ clientAccounts }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const classes = useStyles()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const openEditFormHandler = useCallback(
    (e, id) => {
      dispatch(openForm({ formName: CLIENT_ACCOUNT_EDIT, id }))
    },
    [dispatch]
  )

  const dropdownOptions = useMemo(() => {
    return [
      {
        text: t('Edit Account'),
        onClickHandler: openEditFormHandler
      }
    ]
  }, [openEditFormHandler, t])

  const clientAccountsColumns = useMemo(
    () => [
      {
        header: 'Name',
        fieldKey: 'name'
      },
      {
        Cell: ({ id }) =>
          isMobile ? (
            <ActionsButtonsList itemId={id} options={dropdownOptions} className={classes.actionsStyles} />
          ) : (
            <ActionsDropdown itemId={id} options={dropdownOptions} />
          ),
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [dropdownOptions, isMobile, classes]
  )

  return (
    <Table
      hideFooterRow
      data={clientAccounts}
      cols={clientAccountsColumns}
      isBigRow={false}
      mobileColumnsWrapperClassName={classes.mobileTableHeader}
    />
  )
}

ClientAccountsTableData.propTypes = {
  clientAccounts: PropTypes.array.isRequired
}

export default ClientAccountsTableData
