import React from 'react'
import PropTypes from 'prop-types'

import TypeWriteAnimation from '../../TypeWriteAnimation'
import ShowSkeleton from './ShowSkeleton'

import { isArabic } from '../../../../helpers/language'

import { PRIMARY_TEXT_LIST } from '../../../../forms/Facebook/AdForms/fields'

import useStyles from './styles'

function AdPrimaryText({ formikValues, showSkeletonPlaceholder, typingAnimation }) {
  const classes = useStyles()

  const adText = formikValues[PRIMARY_TEXT_LIST][0]?.text

  if (!adText && !showSkeletonPlaceholder) {
    return null
  } else if (!adText && showSkeletonPlaceholder) {
    return <ShowSkeleton count={3} className={classes.skeletonPrimaryText} />
  }

  return (
    <div className={classes.adText} dir={isArabic(adText) ? 'rtl' : 'ltr'}>
      {typingAnimation ? <TypeWriteAnimation text={adText} delay={40} /> : adText}
    </div>
  )
}

AdPrimaryText.propTypes = {
  formikValues: PropTypes.object,
  showSkeletonPlaceholder: PropTypes.bool,
  typingAnimation: PropTypes.bool
}

export default AdPrimaryText
