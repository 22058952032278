import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Field from '../../../../../../components/Form/Field'
import NewCampaignFields from './FormFields/NewCampaignFields'
import RadioBlock from '../../../../../../components/Form/RadioBlock'

import useAccessibleCampaignsRoutes from '../../../../../Multiplatform/RoutesMissingWarning/useAccessibleCampaignsRoutes'

import {
  selectedControllerDataSelector,
  selectedSelfAccountDataSelector
} from '../../../../../../modules/selectors/app'

import { CAMPAIGN, CAMPAIGN_OPTION, CAMPAIGN_OPTION_EXISTING_CAMPAIGN } from './fields'
import {
  DISPLAY_TYPE,
  PERFORMANCE_MAX_TYPE
} from '../../../../CampaignForms/CampaignGoogleCreate/CampaignGoogleCreateForm/fields'
import { GOOGLE_PLATFORM } from '../../../../../../constants/selectLists/platformList'
import {
  DISPLAY_ROUTE,
  PERFORMANCE_MAX_ROUTE
} from '../../../../../../pages/Settings/AdvertiserAccounts/ClientAccountForms/ClientAccountWithMarginsCreate/ClientAccountWithMarginsCreateForm/fields'

const CampaignFormContent = ({ formik, existedCampaigns, remainingBudget }) => {
  const { t } = useTranslation()

  const controller = useSelector(selectedControllerDataSelector)
  const selectedSelfAccount = useSelector(selectedSelfAccountDataSelector)

  const allowedRoute = useAccessibleCampaignsRoutes(GOOGLE_PLATFORM)
  const hasAccessibleRoutes = Object.values(allowedRoute).some(route => route)

  const { setFieldValue, values } = formik

  return (
    <>
      {!!existedCampaigns?.length && (
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_existing_campaign"
          name={CAMPAIGN_OPTION}
          label={t('Upload an ad to an existing campaign')}
          value={CAMPAIGN_OPTION_EXISTING_CAMPAIGN}
          selectedValue={values[CAMPAIGN_OPTION]}
          description={t('Upload a new ad to a new or existing ad set in one of your campaigns')}
          hasOrSpacing={hasAccessibleRoutes}
        >
          <Field formik={formik} name={CAMPAIGN} placeholder={t('Campaign')} options={existedCampaigns} />
        </RadioBlock>
      )}
      {allowedRoute[PERFORMANCE_MAX_ROUTE] && (
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_performance_max"
          name={CAMPAIGN_OPTION}
          label={t('driveOnlineSales', { accountName: selectedSelfAccount.name })}
          description={
            <>
              <p>{t('Promote to new and existing customers with a Performance Max campaign')}</p>
              <p>{t('Ad content will be shown on display, search and YouTube channels')}</p>
            </>
          }
          value={PERFORMANCE_MAX_TYPE}
          selectedValue={values[CAMPAIGN_OPTION]}
        >
          <NewCampaignFields formik={formik} remainingBudget={remainingBudget} />
        </RadioBlock>
      )}
      {allowedRoute[DISPLAY_ROUTE] && (
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_display_ad"
          name={CAMPAIGN_OPTION}
          label={t('Audience Targeting (display ads)')}
          description={
            <>
              <p>Target {controller?.name} audiences and optimise towards online and in-store sales.</p>
              <p>{t('Select from a wide range of customer and product category audiences')}</p>
            </>
          }
          value={DISPLAY_TYPE}
          selectedValue={values[CAMPAIGN_OPTION]}
        >
          <NewCampaignFields formik={formik} remainingBudget={remainingBudget} />
        </RadioBlock>
      )}
    </>
  )
}

CampaignFormContent.propTypes = {
  formik: PropTypes.object.isRequired,
  remainingBudget: PropTypes.string,
  existedCampaigns: PropTypes.array
}

export default CampaignFormContent
