import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import InfoBlock from '../../../../../../features/components/InfoBlock'
import ReportingContent from './ReportingContent'
import PageSectionHeader from '../../../../../../features/components/PageSectionHeader'

import { ReactComponent as IconEmptyBox } from '../../../../../../assets/icons/empty-box.svg'

import { clearProductsGraph, getUtilisationReport } from '../../../../../../modules/actions/mediaOrdersProducts'
import { selectedControllerDataSelector } from '../../../../../../modules/selectors/app'

import { getDatesFromGraphDateOption, NEXT_6_MONTH } from '../../../../../../constants/selectLists/graphDatesList'
import { getUtilisationReportErrorSelector } from '../../../../../../modules/selectors/mediaOrdersProducts'

const UtilisationReport = ({ setupData }) => {
  const [selectedDateRange, setSelectedDateRange] = useState(NEXT_6_MONTH)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const utilisationReportError = useSelector(getUtilisationReportErrorSelector)
  const selectedSelfAccountController = useSelector(selectedControllerDataSelector)

  const graphFilters = setupData?.graph?.filters

  const { currency: controllerCurrency, id: controllerId } = selectedSelfAccountController ?? {}

  useEffect(() => {
    // always fetch data according to selected graph date range period
    const selectedPeriodDates = getDatesFromGraphDateOption(selectedDateRange)

    dispatch(
      getUtilisationReport({
        ...graphFilters,
        with_empty_data: true, // allows to receive months with empty data, to render all months in graph
        currency: controllerCurrency,
        controller: controllerId,
        date_start_after: selectedPeriodDates.date_from,
        date_start_before: selectedPeriodDates.date_to
      })
    )
  }, [dispatch, controllerCurrency, controllerId, selectedDateRange, graphFilters])

  useEffect(
    () => () => {
      dispatch(clearProductsGraph())
    },
    [dispatch]
  )

  return (
    <>
      <PageSectionHeader title="Utilisation" />
      {utilisationReportError ? (
        <InfoBlock Icon={IconEmptyBox} title="Sorry, something went wrong">
          {t('Please try again later')}
        </InfoBlock>
      ) : (
        <ReportingContent selectedDateRange={selectedDateRange} setSelectedDateRange={setSelectedDateRange} />
      )}
    </>
  )
}

export default UtilisationReport
