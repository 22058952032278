import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Loader from '../../Loaders/Loader'
import PreviewThumbnail from './PreviewThumbnail'

import useStyles from './styles'

const FilePreview = memo(function WrappedFilePreview({
  isLoading,
  filePreviewType,
  previewUrl,
  fileFormat,
  fileName,
  fileDescription,
  FileIcon,
  iconColor,
  isImagePreview
}) {
  const classes = useStyles({ iconColor, hasIcon: !!FileIcon })

  return (
    <>
      {isLoading ? (
        <Loader className={classes.preview} />
      ) : (
        <PreviewThumbnail
          previewUrl={previewUrl}
          fileFormat={fileFormat}
          isImagePreview={filePreviewType === 'image' || isImagePreview}
          FileIcon={FileIcon}
          iconColor={iconColor}
        />
      )}
      <div className={classes.details}>
        <div className={classes.fileName}>{fileName}</div>
        {fileDescription && <p className={classes.description}>{fileDescription}</p>}
      </div>
    </>
  )
})

FilePreview.propTypes = {
  isLoading: PropTypes.bool,
  filePreviewType: PropTypes.string,
  isImagePreview: PropTypes.bool,
  fileUrl: PropTypes.string,
  fileName: PropTypes.string,
  fileDescription: PropTypes.string,
  FileIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  iconColor: PropTypes.string
}

export default FilePreview
