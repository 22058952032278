// campaign
import * as Yup from 'yup'
import { formatNumber } from '../../../helpers/numbers'
import { CURRENCY_FORMAT } from '../../../constants/currency'

export const CAMPAIGN_OPTION = 'campaign_option'
export const CAMPAIGN_OPTION_AUDIENCE_TARGETING = 'campaign_option_audience_targeting'
export const CAMPAIGN_OPTION_EXISTING_CAMPAIGN = 'campaign_option_existing_campaign'
export const CAMPAIGN_OPTION_NEW_CAMPAIGN = 'campaign_option_new_campaign'
export const CAMPAIGN = 'campaign'

export const ALLOW_CUSTOM_CLICK_THROUGH_LINK = 'custom_click_through_link'

// platforms
export const PLATFORM_ROUTE_OPTION = 'platform_route_option'

export const getCampaignInitialValues = isCampaignsExist => ({
  // campaign
  [CAMPAIGN_OPTION]: isCampaignsExist ? CAMPAIGN_OPTION_EXISTING_CAMPAIGN : CAMPAIGN_OPTION_NEW_CAMPAIGN,
  [CAMPAIGN]: ''
})

export const getBudgetValidation = ({ remainingBudget, currencySymbol }) =>
  Yup.number()
    .max(remainingBudget, `Your remaining budget is ${currencySymbol}${formatNumber(remainingBudget, CURRENCY_FORMAT)}`)
    .required('Budget required')

export const campaignValidation = {
  [CAMPAIGN]: Yup.string().when(CAMPAIGN_OPTION, {
    is: CAMPAIGN_OPTION_EXISTING_CAMPAIGN,
    then: () => Yup.string().required('Please select campaign')
  })
}
