import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ContentTabs from '../../../features/components/ContentTabs'
import TabTitle from '../../../features/components/ContentTabs/TabTitle'
import InternalAdsSection from './InternalAdsSection'
import InfoBlock from '../../../features/components/InfoBlock'

import { internalAdsItemsCountSelector, internalAdsItemsCountWasLoadedSelector } from '../../../modules/selectors/ads'

import { ROUTES } from '../../../constants/routes'

import {
  DV_360_PLATFORM,
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  TIKTOK_PLATFORM
} from '../../../constants/selectLists/platformList'

const ApprovalsTabs = () => {
  const { t } = useTranslation()
  const params = useParams()

  const subPagePath = params.providerTab

  const internalAdsItemsCount = useSelector(internalAdsItemsCountSelector)
  const itemsCountWasLoaded = useSelector(internalAdsItemsCountWasLoadedSelector)

  const facebookAdsItemsCount = internalAdsItemsCount.find(item => item.provider === 'facebook')?.count
  const googleAdsItemsCount = internalAdsItemsCount.find(item => item.provider === 'google')?.count
  const tiktokAdsItemsCount = internalAdsItemsCount.find(item => item.provider === 'tiktok')?.count
  const dv360AdsItemsCount = internalAdsItemsCount.find(item => item.provider === 'dv360')?.count

  const contentTabs = useMemo(() => {
    return [
      {
        title: <TabTitle title="Meta" chipText={facebookAdsItemsCount} />,
        mobileTitle: 'Meta',
        path: ROUTES.adApprovalsFacebook,
        pathParam: FACEBOOK_PLATFORM,
        component: <InternalAdsSection platform={FACEBOOK_PLATFORM} />,
        show: !!facebookAdsItemsCount
      },
      {
        title: <TabTitle title="Google Ads" chipText={googleAdsItemsCount} />,
        mobileTitle: 'Google Ads',
        path: ROUTES.adApprovalsGoogle,
        pathParam: GOOGLE_PLATFORM,
        component: <InternalAdsSection platform={GOOGLE_PLATFORM} />,
        show: !!googleAdsItemsCount
      },
      {
        title: <TabTitle title="TikTok" chipText={tiktokAdsItemsCount} />,
        mobileTitle: 'TikTok',
        path: ROUTES.adApprovalsTiktok,
        pathParam: TIKTOK_PLATFORM,
        component: <InternalAdsSection platform={TIKTOK_PLATFORM} />,
        show: !!tiktokAdsItemsCount
      },
      {
        title: <TabTitle title="DV360" chipText={dv360AdsItemsCount} />,
        mobileTitle: 'DV360',
        path: ROUTES.adApprovalsDv360,
        pathParam: DV_360_PLATFORM,
        component: <div>DV360</div>,
        show: !!dv360AdsItemsCount
      }
    ]
  }, [facebookAdsItemsCount, googleAdsItemsCount, tiktokAdsItemsCount, dv360AdsItemsCount])

  if (
    itemsCountWasLoaded &&
    !facebookAdsItemsCount &&
    !googleAdsItemsCount &&
    !tiktokAdsItemsCount &&
    !dv360AdsItemsCount
  ) {
    return (
      <InfoBlock hideIcon>
        <p>{t('There are no ads to approve at this time')}</p>
      </InfoBlock>
    )
  }

  return <ContentTabs tabs={contentTabs} subPagePath={subPagePath} />
}

export default ApprovalsTabs
