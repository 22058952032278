import React from 'react'
import PropTypes from 'prop-types'

import Field from '../../../../../components/Form/Field'
import FieldRow from '../../../../../features/components/Form/FieldsSection/FieldRow'

import useProductsAndPages from './useProductsAndPages'

import { AD_LINK } from '../../../../Facebook/AdForms/fields'
import { BRAND_CATEGORY_PAGE_ID } from '../../../ProductsAndPagesFields/fields'
import { PRODUCT_ID } from '../../../ProductFields/fields'

const ClickThroughLinkField = ({ formik, itemPath }) => {
  const formattedPageLinksList = useProductsAndPages()
  const { values } = formik

  // when we have the Product or Page selection step it means one of the page or product will be selected
  // then when we have Product or Page selection push user to select link from the list
  const selectLinkFromList = values[BRAND_CATEGORY_PAGE_ID] || values[PRODUCT_ID]

  return (
    <>
      {selectLinkFromList ? (
        <FieldRow title="Click through link" description="Please select a click through link">
          <Field
            formik={formik}
            placeholder="Select click through link"
            name={`${itemPath}.${AD_LINK}`}
            options={formattedPageLinksList}
          />
        </FieldRow>
      ) : (
        <FieldRow title="Click through link" description="Please add a click through link">
          <Field
            formik={formik}
            name={`${itemPath}.${AD_LINK}`}
            placeholder="Select click through link"
            autoComplete="off"
          />
        </FieldRow>
      )}
    </>
  )
}

ClickThroughLinkField.propTypes = {
  formik: PropTypes.object.isRequired,
  itemPath: PropTypes.string.isRequired
}

export default ClickThroughLinkField
