import React, { useCallback, useEffect, useMemo } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import StepForm from '../../../../../features/components/Forms/StepForm'
import FilesRequirementsStep from './Steps/FilesRequirementsStep'
import DetailsStep from './Steps/DetailsStep'
import PricingStep from './Steps/PricingStep'
import InventoryStep from './Steps/InventoryStep'
import ImageStep from './Steps/ImageStep'

import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'
import { useProgressItems } from './useProgressItems'
import { transformValuesToBE } from './formatters'

import { clearCreateProduct, createProduct } from '../../../../../modules/actions/mediaOrdersProducts'
import { clearGetDiscountPresets, getDiscountPresets } from '../../../../../modules/actions/discounts'
import { discountPresetsSelector } from '../../../../../modules/selectors/discounts'
import {
  createProductErrorSelector,
  createProductIsLoadingSelector,
  createProductWasCreatedSelector
} from '../../../../../modules/selectors/mediaOrdersProducts'
import { selectedControllerCurrencySelector } from '../../../../../modules/selectors/app'

import { PRODUCT_CREATE } from '../../../../../constants/forms'
import { DIGITAL } from '../../../../../constants/mediaOrders'
import { getInitialValues, MEDIA_CATEGORY } from './fields'
import { validationSchema } from './validation'

const MediaProductCreateForm = ({ controllerId }) => {
  const dispatch = useDispatch()

  const productWasCreated = useSelector(createProductWasCreatedSelector)
  const discountPresets = useSelector(discountPresetsSelector)
  const controllerCurrency = useSelector(selectedControllerCurrencySelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE({ values, controllerId, discountPresets })

      dispatch(createProduct(transformedData))
    },
    [dispatch, controllerId, discountPresets]
  )

  const formik = useFormik({
    initialValues: getInitialValues(controllerCurrency),
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const { [MEDIA_CATEGORY]: mediaCategory } = purifiedFormik.values

  const showFilesRequirementsStep = mediaCategory !== DIGITAL

  const progressItems = useProgressItems({ showFilesRequirements: showFilesRequirementsStep })

  const steps = useMemo(
    () => [
      {
        component: <DetailsStep />,
        show: true
      },
      {
        component: <PricingStep />,
        show: true
      },
      {
        component: <FilesRequirementsStep />,
        show: showFilesRequirementsStep
      },
      {
        component: <InventoryStep />,
        show: true
      },
      {
        component: <ImageStep />,
        show: true
      }
    ],
    [showFilesRequirementsStep]
  )

  const clearProductCreateSubmit = useCallback(() => {
    dispatch(clearCreateProduct())
  }, [dispatch])

  useEffect(() => {
    if (controllerId) {
      dispatch(getDiscountPresets({ controller: controllerId }))
    }
  }, [dispatch, controllerId])

  useEffect(
    () => () => {
      dispatch(clearGetDiscountPresets())
    },
    [dispatch]
  )

  return (
    <StepForm
      formProgressItems={progressItems}
      formName={PRODUCT_CREATE}
      steps={steps}
      formik={purifiedFormik}
      clearSubmitHandler={clearProductCreateSubmit}
      successSubmit={productWasCreated}
      errorSelector={createProductErrorSelector}
      isLoadingSelector={createProductIsLoadingSelector}
    />
  )
}

MediaProductCreateForm.propTypes = {
  controllerId: PropTypes.number.isRequired
}

export default MediaProductCreateForm
