import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getIn } from 'formik'
import PropTypes from 'prop-types'

import SelectPaginated from '../../../../../../../../../components/SelectPaginated'

import { createJsonFromQueryString } from '../../../../../../../../../helpers/url'
import { formatOptionsList } from '../../../../../../../../../features/formatters'

import { getSelfAccountCategoriesService } from '../../../../../../../../../modules/services/selfAccounts'
import { selectedControllerIdSelector } from '../../../../../../../../../modules/selectors/app'

import { CATEGORY } from '../../../../../fields'

const CategoriesSelectPaginated = ({ formik, itemPath, onCategoryChange, defaultOptionsList, limit }) => {
  const selectedControllerId = useSelector(selectedControllerIdSelector)

  const { values, setFieldValue } = formik

  const categorySelectName = `${itemPath}.${CATEGORY}`
  const selectedLocation = getIn(values, categorySelectName)

  const formatCategoriesOptions = useCallback(
    categories =>
      formatOptionsList({
        list: categories,
        valueName: 'id',
        labelName: 'name'
      }),
    []
  )

  const onChangeHandler = useCallback(
    option => {
      setFieldValue(categorySelectName, option?.value)
      onCategoryChange && onCategoryChange()
    },
    [categorySelectName, setFieldValue, onCategoryChange]
  )

  const loadOptions = useCallback(
    async (search, loadedOptions, { next }) => {
      const response = await getSelfAccountCategoriesService({
        search,
        ordering: 'name',
        controller: selectedControllerId,
        limit,
        ...createJsonFromQueryString(`?${next.split('?')[1]}`)
      })
      const newCategoriesOptions = formatCategoriesOptions(response.results)

      return {
        options: newCategoriesOptions,
        hasMore: !!response.next,
        additional: {
          next: response.next
        }
      }
    },
    [selectedControllerId, formatCategoriesOptions, limit]
  )

  return (
    <SelectPaginated
      placeholder="Select Category"
      value={selectedLocation}
      onChange={onChangeHandler}
      loadOptions={loadOptions}
      defaultOptionsList={defaultOptionsList}
    />
  )
}

CategoriesSelectPaginated.propTypes = {
  formik: PropTypes.object.isRequired,
  itemPath: PropTypes.string.isRequired,
  onCategoryChange: PropTypes.func,
  defaultOptionsList: PropTypes.array
}

export default CategoriesSelectPaginated
