import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../components/Button'

import useStyles from '../../../../../styles/common/stepForms'
import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

const InstagramPageStep = ({ handleStepChange }) => {
  const { t } = useTranslation()

  const classes = useStyles()
  const drawerFormClasses = useDrawerFormStyles()

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create an audience')}</div>
      <div className={classes.stepTitle}>{t('Select an Instagram account')}</div>
      <div className={classes.stepBody}>
        <section className={drawerFormClasses.section}>
          <h4 className={drawerFormClasses.sectionTitle}>{t('Instagram account')}</h4>
        </section>
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" className="dark" onClick={() => handleStepChange()}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default InstagramPageStep
