export const START_DATE = 'start_date'
export const END_DATE = 'end_date'
export const MEDIA_CATEGORY = 'media_category'
export const PRICE_CHANGE = 'price_change'
export const INCREASE = 'increase'
export const LOCATIONS = 'locations'
export const INCREASE_SELECT_OPTION = 'increase_select_option'
export const DECREASE_SELECT_OPTION = 'decrease_select_option'
export const priceChangeOptions = [
  {
    value: INCREASE_SELECT_OPTION,
    label: 'Increase'
  },
  {
    value: DECREASE_SELECT_OPTION,
    label: 'Decrease'
  }
]
