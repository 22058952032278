import React from 'react'
import ShowSkeleton from './ShowSkeleton'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import SkeletonActionButton from './ActionButton/Skeleton'
import SkeletonDescriptionText from './DescriptionText/Skeleton'
import SkeletonHeadlineText from './HeadlineText/Skeleton'
import SkeletonWebsiteText from './AdCardSingleContent/SkeletonWebsiteText'

import SkeletonAdMediaThumbnail from './AdMediaThumbnail/Skeleton'

import useStyles from './styles'
import useAdCardSingleStyles from './AdCardSingleContent/styles'
import useActionButtonStyles from './ActionButton/styles'

function SkeletonAdCard({ className }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const classesAdCardSingle = useAdCardSingleStyles()
  const classesActionButton = useActionButtonStyles()

  return (
    <div className={classnames(classes.adCard, className)}>
      <div className={classes.adCardHeader}>
        <div className={classes.headerTop}>
          <div className={classes.avatar} />
          <div className={classes.info}>
            <div className={classes.name}>
              <ShowSkeleton isInProgress={false} count={1} className={classes.skeletonPageName} showLoadingAnimation />
            </div>
            <div className={classes.description}>{t('Sponsored')}</div>
          </div>
        </div>
        <div className={classes.adText}>
          <ShowSkeleton isInProgress={false} count={3} className={classes.skeletonPrimaryText} showLoadingAnimation />
        </div>
      </div>
      <SkeletonAdMediaThumbnail />
      <div className={classesAdCardSingle.adFooter}>
        <div className={classesAdCardSingle.footerInfo}>
          <div className={classesAdCardSingle.footerWebsite}>
            <SkeletonWebsiteText
              isInProgress={false}
              count={1}
              className={classes.skeletonWebsiteText}
              showLoadingAnimation
            />
          </div>
          <div className={classesAdCardSingle.singleHeadlineText}>
            <SkeletonHeadlineText
              isInProgress={false}
              count={1}
              className={classesAdCardSingle.skeletonSingleHeadline}
              showLoadingAnimation
            />
          </div>
          <div className={classesAdCardSingle.singleDescriptionText}>
            <SkeletonDescriptionText isInProgress={false} showLoadingAnimation />
          </div>
        </div>
        <div className={classnames(classesActionButton.actionButton, classesAdCardSingle.singleContentActionButton)}>
          <SkeletonActionButton isInProgress={false} showLoadingAnimation />
        </div>
      </div>
    </div>
  )
}

SkeletonAdCard.propTypes = {
  className: PropTypes.string
}

export default SkeletonAdCard
