import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useFetchedDateRange from './useFetchedDateRange'

import { isEqual } from '../../../../../../../helpers/common'
import {
  getInitialDateRange,
  getNextDateRange,
  getPrevDateRange
} from '../../../../../../../features/components/Calendar/CalendarTable'

import { getBookedRevenueReport } from '../../../../../../../modules/actions/mediaOrdersBookings'
import { selectedControllerIdSelector } from '../../../../../../../modules/selectors/app'
import { bookedRevenueReportLoadingPeriodsSelector } from '../../../../../../../modules/selectors/mediaOrdersBookings'

import { formatDateToBE } from '../../../../../../../constants/dates'
import { CALENDAR_VIEW_TYPE } from '../../../../../../../constants/selectLists/calendarList'
import { initialPeriod } from '../../CalendarTable/helpers'

export const BookedRevenueDataPeriodsContext = React.createContext()

export function BookedRevenueDataPeriodsProvider({ children, params, mainDataWasLoaded, onDateRangeChange }) {
  const dispatch = useDispatch()

  const [{ startDate, endDate }, setDateRange] = useState(initialPeriod)
  const [selectedViewType, setSelectedViewType] = useState(CALENDAR_VIEW_TYPE.YEAR)
  // save date range to ref, to avoid the resetData change, when the date range changes which provokes calling the
  // resetData in hook
  const dateRangeRef = useRef(getInitialDateRange(CALENDAR_VIEW_TYPE.YEAR))
  const prevParamsRef = useRef(params)

  const { checkAndFetchData, resetFetchedData } = useFetchedDateRange(initialPeriod)

  const selfAccountControllerId = useSelector(selectedControllerIdSelector)
  const loadingPeriods = useSelector(bookedRevenueReportLoadingPeriodsSelector)

  const resetData = useCallback(() => {
    // reset hook to initial state
    resetFetchedData(dateRangeRef.current)
  }, [resetFetchedData])

  const handleNewPeriodDataFetch = useCallback(
    newPeriod => {
      // optimization: check if the data for the new period is already fetched
      const { shouldFetch, datesToFetch } = checkAndFetchData(newPeriod.startDate, newPeriod.endDate)

      if (shouldFetch) {
        dispatch(
          getBookedRevenueReport({
            params: {
              controller_id: selfAccountControllerId,
              ...params,
              date_start_after: formatDateToBE(datesToFetch.startDate),
              date_start_before: formatDateToBE(datesToFetch.endDate)
            },
            period: {
              startDate: formatDateToBE(datesToFetch.startDate),
              endDate: formatDateToBE(datesToFetch.endDate)
            }
          })
        )
      }
    },
    [checkAndFetchData, dispatch, params, selfAccountControllerId]
  )

  const handleDateRangeChange = useCallback(
    dateRange => {
      setDateRange(dateRange)
      dateRangeRef.current = dateRange
      onDateRangeChange(dateRange)
    },
    [onDateRangeChange]
  )

  const handleViewTypeChange = useCallback(
    viewType => {
      setSelectedViewType(viewType)
      const newRange = getInitialDateRange(viewType)
      handleDateRangeChange(newRange)
      // Fetch data for the selected view type - in case the data is not already fetched
      handleNewPeriodDataFetch(newRange)
    },
    [handleNewPeriodDataFetch, handleDateRangeChange]
  )

  useEffect(() => {
    // when filter params change - reset state
    const hasParams = !!params && !!prevParamsRef.current
    // skip initial params set i.e. - change from null to {}
    if (hasParams && !isEqual(prevParamsRef.current, params)) {
      resetData()
    }
    prevParamsRef.current = params
  }, [params, resetData])

  useEffect(() => {
    // fetch additional data only after the main data is loaded
    if (mainDataWasLoaded) {
      // pre-fetch next data period for the current date range and selected view type
      // this will help the user to see the data for the next period without waiting for the data to be fetched
      const prevDateRange = getPrevDateRange(selectedViewType, startDate, endDate)
      const nextDateRange = getNextDateRange(selectedViewType, startDate, endDate)

      // handleNewPeriodDataFetch has the check to fetch the data only if it's not already fetched
      handleNewPeriodDataFetch(prevDateRange)
      handleNewPeriodDataFetch(nextDateRange)
    }
  }, [endDate, handleNewPeriodDataFetch, selectedViewType, startDate, mainDataWasLoaded])

  return (
    <BookedRevenueDataPeriodsContext.Provider
      value={{
        startDate,
        endDate,
        handleDateRangeChange,
        selectedViewType,
        setSelectedViewType,
        loadingPeriods,
        onViewTypeChange: handleViewTypeChange,
        onPeriodChange: handleNewPeriodDataFetch
      }}
    >
      {children}
    </BookedRevenueDataPeriodsContext.Provider>
  )
}
