import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles(theme => ({
  chipsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 8
  }
}))

export default useStyles
