import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import useStyles from './styles'

const ErrorMessage = ({ error, className, children }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classnames(className, 'error', classes.error)}>
      {t(error)}
      {children}
    </div>
  )
}

ErrorMessage.propTypes = {
  error: PropTypes.string,
  className: PropTypes.string
}

export default ErrorMessage
