import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { activeStepSelector } from '../../../../modules/selectors/forms'

import { AD_FORM, LINE_ITEM_ADVANCED_FORM } from './Routes/AdvancedSetupRoute'
import {
  AD_PRODUCT_FORM,
  AUDIENCE_PRODUCT_RETARGETING_FORM,
  LINE_ITEM_PRODUCT_RETARGETING_FORM
} from './Routes/ProductRetargetingRoute'
import { LINE_ITEM_AUDIENCE_TARGETING_FORM, PRODUCT_FORM } from './Routes/AudienceTargetingRoute'
import { AD_ADVANTAGE_SHOPPING_FORM, LINE_ITEM_FORM } from './Routes/AdvantageShoppingRoute'
import { AUDIENCE_TARGETING_ROUTE, PRODUCT_RETARGETING_ROUTE, PROXIMITY_CAMPAIGN_ROUTE } from '../fields'
import { OPTION_NEW_LINE_ITEM } from './Routes/AdvancedSetupRoute/LineItemAdvancedForm/LineItemAdvancedFormContent/fields'
import { lineItemsSelector } from '../../../../modules/selectors/lineItems'
import { LINE_ITEM_PROXIMITY_FORM } from './Routes/ProximityCampaignRoute'

const useFormProgressItems = ({
  isCampaignFormSuccess = false,
  selectedRoute,
  activatedForm,
  currentLineItemOption
}) => {
  const isCampaignFormActive = !activatedForm

  const activeStep = useSelector(activeStepSelector)
  const lineItems = useSelector(lineItemsSelector)
  const hasLineItems = lineItems?.length > 0

  const isAdSetFormActive =
    // product retargeting route
    activatedForm === AUDIENCE_PRODUCT_RETARGETING_FORM ||
    activatedForm === LINE_ITEM_PRODUCT_RETARGETING_FORM ||
    // audience targeting route
    activatedForm === PRODUCT_FORM ||
    activatedForm === LINE_ITEM_AUDIENCE_TARGETING_FORM ||
    // Advanced setup route,
    // Existing campaign route
    activatedForm === LINE_ITEM_ADVANCED_FORM ||
    // Advantage shopping route
    activatedForm === LINE_ITEM_FORM ||
    activatedForm === LINE_ITEM_PROXIMITY_FORM

  const isCreativeAssetsFormActive =
    // Product retargeting route,
    // Audience targeting route
    activatedForm === AD_PRODUCT_FORM ||
    // Advanced setup route,
    // Existing campaign route
    activatedForm === AD_FORM ||
    // Advantage shopping route
    activatedForm === AD_ADVANTAGE_SHOPPING_FORM

  const generalFormProgress = useMemo(
    () => [
      {
        label: 'Campaign',
        isActive: isCampaignFormActive,
        isSuccess: isCampaignFormSuccess
      },
      {
        label: 'Ad set',
        isActive: isAdSetFormActive,
        isSuccess:
          activatedForm === AD_FORM || activatedForm === AD_PRODUCT_FORM || activatedForm === AD_ADVANTAGE_SHOPPING_FORM
      },
      {
        label: 'Creative assets',
        isActive: isCreativeAssetsFormActive,
        isSuccess: false
      }
    ],
    [activatedForm, isAdSetFormActive, isCampaignFormActive, isCampaignFormSuccess, isCreativeAssetsFormActive]
  )

  const productRetargetingFormProgress = useMemo(() => {
    return [
      {
        label: 'Choose campaign type',
        isActive: !activatedForm,
        isSuccess: isCampaignFormSuccess
      },
      {
        label: 'Select product',
        isActive: !activatedForm && isCampaignFormSuccess,
        isSuccess: activatedForm === LINE_ITEM_PRODUCT_RETARGETING_FORM || activatedForm === AD_PRODUCT_FORM
      },
      {
        label: 'Set campaign dates',
        isActive: activatedForm === LINE_ITEM_PRODUCT_RETARGETING_FORM,
        isSuccess: activatedForm === PRODUCT_FORM || activatedForm === AD_PRODUCT_FORM
      },
      {
        label: 'Review ad creative',
        isActive: activatedForm === AD_PRODUCT_FORM,
        isSuccess: false
      }
    ]
  }, [activatedForm, isCampaignFormSuccess])

  const proximityCampaignFormProgress = useMemo(() => {
    const hasLineItemsSelectionStep = hasLineItems && currentLineItemOption === OPTION_NEW_LINE_ITEM
    const stepCounter = hasLineItemsSelectionStep ? 1 : 0

    const newLineItemSteps = [
      {
        label: 'Select location(s)',
        isActive: activeStep === stepCounter && isAdSetFormActive,
        isSuccess: (activeStep === stepCounter + 1 && isAdSetFormActive) || activatedForm === AD_PRODUCT_FORM
      },
      {
        label: 'Set campaign dates',
        isActive: activeStep === stepCounter + 1 && isAdSetFormActive,
        isSuccess: (activeStep === stepCounter + 2 && isAdSetFormActive) || activatedForm === AD_PRODUCT_FORM
      }
    ]

    const existingLineItemSteps = [
      {
        label: 'Ad set',
        isActive: isAdSetFormActive,
        isSuccess: activatedForm === AD_PRODUCT_FORM
      }
    ]

    return [
      {
        label: 'Choose campaign type',
        isActive: !activatedForm,
        isSuccess: isCampaignFormSuccess
      },
      ...(currentLineItemOption === OPTION_NEW_LINE_ITEM ? newLineItemSteps : existingLineItemSteps),
      {
        label: 'Review ad creative',
        isActive: activatedForm === AD_PRODUCT_FORM,
        isSuccess: false
      }
    ]
  }, [isAdSetFormActive, hasLineItems, currentLineItemOption, activatedForm, isCampaignFormSuccess, activeStep])

  const audienceTargetingFormProgress = useMemo(() => {
    // audience has possibility to select line item from existed when adding new ad
    const isAdSetFormActive = activatedForm === LINE_ITEM_AUDIENCE_TARGETING_FORM || activatedForm === PRODUCT_FORM
    const hasLineItemsSelectionStep = hasLineItems && currentLineItemOption === OPTION_NEW_LINE_ITEM
    const stepCounter = hasLineItemsSelectionStep ? 1 : 0

    const newLineItemSteps = [
      {
        label: 'Set campaign dates',
        isActive: activeStep === stepCounter && isAdSetFormActive,
        isSuccess:
          (activeStep === stepCounter + 1 && isAdSetFormActive) ||
          activatedForm === PRODUCT_FORM ||
          activatedForm === AD_PRODUCT_FORM
      },
      {
        label: 'Select audience',
        isActive: activeStep === stepCounter + 1 && isAdSetFormActive,
        isSuccess:
          (activeStep === stepCounter + 2 && isAdSetFormActive) ||
          activatedForm === PRODUCT_FORM ||
          activatedForm === AD_PRODUCT_FORM
      }
    ]

    const existingLineItemSteps = [
      {
        label: 'Ad set',
        isActive: isAdSetFormActive,
        isSuccess: activatedForm === PRODUCT_FORM || activatedForm === AD_PRODUCT_FORM
      }
    ]

    return [
      {
        label: 'Choose campaign type',
        isActive: !activatedForm,
        isSuccess: isCampaignFormSuccess
      },
      ...(currentLineItemOption === OPTION_NEW_LINE_ITEM ? newLineItemSteps : existingLineItemSteps),
      {
        label: 'Select page or product',
        isActive: activatedForm === PRODUCT_FORM,
        isSuccess: activatedForm === AD_PRODUCT_FORM
      },
      {
        label: 'Review ad creative',
        isActive: activatedForm === AD_PRODUCT_FORM,
        isSuccess: false
      }
    ]
  }, [hasLineItems, currentLineItemOption, activatedForm, isCampaignFormSuccess, activeStep])

  return useMemo(() => {
    switch (selectedRoute) {
      case PRODUCT_RETARGETING_ROUTE:
        return productRetargetingFormProgress
      case PROXIMITY_CAMPAIGN_ROUTE:
        return proximityCampaignFormProgress
      case AUDIENCE_TARGETING_ROUTE:
        return audienceTargetingFormProgress
      default:
        return generalFormProgress
    }
  }, [
    productRetargetingFormProgress,
    proximityCampaignFormProgress,
    audienceTargetingFormProgress,
    generalFormProgress,
    selectedRoute
  ])
}

export default useFormProgressItems
