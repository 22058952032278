import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Select from '../../../../../../../components/Select'

import { formatOptionsList } from '../../../../../../../features/formatters'

import {
  clearGetSelfAccountCategories,
  getSelfAccountCategories
} from '../../../../../../../modules/actions/selfAccounts'
import { selectedControllerIdSelector } from '../../../../../../../modules/selectors/app'
import {
  getSelfAccountCategoriesIsLoadingSelector,
  selfAccountCategoriesSelector
} from '../../../../../../../modules/selectors/selfAccounts'

import useStyles from './styles'

const SelfAccountCategoryFilter = ({ Context }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  const selfAccountCategories = useSelector(selfAccountCategoriesSelector)
  const selfAccountCategoriesIsLoading = useSelector(getSelfAccountCategoriesIsLoadingSelector)
  const selfAccountControllerId = useSelector(selectedControllerIdSelector)

  const formattedSelfAccountCategoriesOptions = useMemo(() => {
    return formatOptionsList({
      list: selfAccountCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [selfAccountCategories])

  const { brandCategoryFilter, setBrandCategoryFilter } = useContext(Context)

  const onCategoryFilterChange = useCallback(
    option => {
      setBrandCategoryFilter(option?.value)
    },
    [setBrandCategoryFilter]
  )

  useEffect(() => {
    dispatch(
      getSelfAccountCategories({
        controller: selfAccountControllerId
      })
    )
  }, [dispatch, selfAccountControllerId])

  useEffect(() => {
    return () => {
      dispatch(clearGetSelfAccountCategories())
    }
  }, [dispatch])

  return (
    <Select
      placeholder="Brand Category"
      isSelectedValueBrandPrimary={true}
      className={classes.selfAccountCategorySelect}
      value={brandCategoryFilter}
      onChange={onCategoryFilterChange}
      options={formattedSelfAccountCategoriesOptions}
      prefix={t('Brand Category: ')}
      isLoading={selfAccountCategoriesIsLoading}
      isSmall
    />
  )
}

export default SelfAccountCategoryFilter
