import { getShowLocationFilterInCreateForm } from './helpers'

import {
  ACCESS_ACCOUNTS,
  AVAILABILITY_DATE_END,
  AVAILABILITY_DATE_START,
  categoriesWithAllowedPreselectedLocationFilter,
  CATEGORY,
  DATE_END,
  DATE_START,
  MEDIA_CATEGORIES,
  MEDIA_PRODUCTS,
  NAME,
  SUBTEXT,
  TAGS
} from '../../fields'
import {
  FIXED_DELIVERY_DATES_CHECKBOX,
  MEDIA_ITEMS_OPTION,
  PRODUCTS_FILTER,
  USER_CAN_SELECT_PRODUCTS_FROM_LIST
} from './fields'
import { PRICE_CURRENCY, PRICE_PAIRS, PRICE_VALUE } from '../../../../ReusableFormFields/PricePairFields/fields'

import { REPRESENTATIVE } from '../../../../../constants/mediaOrders'
import { formatDateToBE } from '../../../../../constants/dates'

import { LOCATION } from '../../../MediaProductForms/fields'
import { FILE_NAME, FILE_URL } from '../../../../../constants/files'

const formatProductFilterPayload = values => {
  const payload = {}

  const hasSelectedCategoryWithAllowedLocation = values[MEDIA_CATEGORIES].find(category =>
    categoriesWithAllowedPreselectedLocationFilter.includes(category.value)
  )

  if (hasSelectedCategoryWithAllowedLocation && values[LOCATION]) {
    categoriesWithAllowedPreselectedLocationFilter.forEach(category => {
      if (values[MEDIA_CATEGORIES].find(item => item.value === category)) {
        payload[category] = { [LOCATION]: values[LOCATION] }
      }
    })
  }

  return payload
}

export const transformValuesToBE = ({ values, controllerId, currentBiggestOrderNumber }) => {
  const isFixedPrice = values[FIXED_DELIVERY_DATES_CHECKBOX] && values[MEDIA_ITEMS_OPTION] === MEDIA_PRODUCTS

  const showLocationFilter = getShowLocationFilterInCreateForm(values)

  return {
    controller: controllerId,
    status: 'active',
    order: currentBiggestOrderNumber ? currentBiggestOrderNumber + 1 : 1,
    [NAME]: values[NAME],
    [CATEGORY]: values[CATEGORY],
    [AVAILABILITY_DATE_START]: formatDateToBE(values[AVAILABILITY_DATE_START]),
    [AVAILABILITY_DATE_END]: formatDateToBE(values[AVAILABILITY_DATE_END]),
    ...(values[FIXED_DELIVERY_DATES_CHECKBOX] && {
      [DATE_START]: formatDateToBE(values[DATE_START]),
      [DATE_END]: formatDateToBE(values[DATE_END])
    }),
    [USER_CAN_SELECT_PRODUCTS_FROM_LIST]: values[USER_CAN_SELECT_PRODUCTS_FROM_LIST],
    [SUBTEXT]: values[SUBTEXT],
    image: values[FILE_URL],
    [FILE_NAME]: values[FILE_NAME],
    ...(values[MEDIA_ITEMS_OPTION] === MEDIA_CATEGORIES
      ? { [MEDIA_CATEGORIES]: values[MEDIA_CATEGORIES].map(item => item.value) }
      : { [MEDIA_PRODUCTS]: values[MEDIA_PRODUCTS].map(item => item.value) }),
    prices: values[PRICE_PAIRS].map(pricePair => ({
      price: pricePair[PRICE_VALUE],
      currency: pricePair[PRICE_CURRENCY]
    })),
    fixed_price: isFixedPrice,
    [TAGS]: values[TAGS]?.map(tag => tag?.value),
    [ACCESS_ACCOUNTS]: values[ACCESS_ACCOUNTS]?.map(account => account?.id),
    ...(values[REPRESENTATIVE] && { [REPRESENTATIVE]: values[REPRESENTATIVE] }),
    ...(showLocationFilter &&
      values[LOCATION] && {
        [PRODUCTS_FILTER]: formatProductFilterPayload(values)
      })
  }
}
