import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import TableDataLoader from '../../../../components/Table/TableDataLoader'
import ClientAccountsTableData from './ClientAccountsTableData'

import {
  selfAccountsErrorSelector,
  selfAccountsIsLoadingSelector,
  selfAccountsWasLoadedSelector
} from '../../../../modules/selectors/selfAccounts'

import { mobileOneLineColumnsSchema } from '../../../../constants/other'
import { phonesDownSize } from '../../../../styles/const/breakpoints'

import useCommonStyles from '../../../../styles/common/table'

export const tableColumnsSize = {
  actions: 30
}

const ClientAccountsTable = ({ clientAccounts, loadMore }) => {
  const { t } = useTranslation()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const commonClasses = useCommonStyles()

  const clientAccountsSchema = useMemo(
    () => [
      {
        header: 'Name'
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no accounts')}</div>
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <TableDataLoader
      loadMore={loadMore}
      itemsLength={clientAccounts.length}
      errorSelector={selfAccountsErrorSelector}
      wasLoadedSelector={selfAccountsWasLoadedSelector}
      isLoadingSelector={selfAccountsIsLoadingSelector}
      skeletonProps={{ cols: isMobile ? mobileOneLineColumnsSchema : clientAccountsSchema }}
      noDataContent={noDataContent}
    >
      <ClientAccountsTableData clientAccounts={clientAccounts} />
    </TableDataLoader>
  )
}

ClientAccountsTable.propTypes = {
  loadMore: PropTypes.func,
  clientAccounts: PropTypes.array.isRequired
}

export default ClientAccountsTable
