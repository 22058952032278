import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import ActionsDropdown from '../../../../../features/components/ActionsDropdown'
import Chip from '../../../../../components/Chip'
import Table from '../../../../../components/Table'
import ActionsButtonsList from '../../../../../features/components/ActionsButtonsList'

import { openForm } from '../../../../../modules/actions/forms'
import { deleteLocationList } from '../../../../../modules/actions/location'

import { tableColumnsSize } from '../index'

import { CHIP_COLORS } from '../../../../../constants/other'
import { LOCATION_LIST_EDIT } from '../../../../../constants/forms'

import { phonesDownSize } from '../../../../../styles/const/breakpoints'

import useStyles from './styles'

const LocationsTableData = ({ locations }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const classes = useStyles()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const openEditFormHandler = useCallback(
    (e, id) => {
      dispatch(openForm({ id, formName: LOCATION_LIST_EDIT }))
    },
    [dispatch]
  )

  const deleteLocationListHandler = useCallback(
    (e, id) => {
      dispatch(deleteLocationList(id))
    },
    [dispatch]
  )

  const dropdownOptions = useMemo(() => {
    return [
      {
        text: t('Edit'),
        onClickHandler: openEditFormHandler
      },
      {
        text: t('Delete'),
        onClickHandler: deleteLocationListHandler
      }
    ]
  }, [openEditFormHandler, deleteLocationListHandler, t])

  const locationsColumns = useMemo(
    () => [
      {
        header: 'Name',
        fieldKey: 'name'
      },
      {
        header: 'List Type',
        Cell: ({ location_list_type }) => (
          <Chip
            text={location_list_type === 'Supermarket' ? 'Store' : location_list_type}
            color={CHIP_COLORS.blue}
            className={classes.chipMobileField}
          />
        ),
        style: { maxWidth: tableColumnsSize.listType },
        showOnMobile: false
      },
      {
        header: 'No. of Locations',
        fieldKey: 'size',
        style: { maxWidth: tableColumnsSize.size },
        showOnMobile: false
      },
      {
        Cell: ({ id }) =>
          isMobile ? (
            <ActionsButtonsList itemId={id} options={dropdownOptions} />
          ) : (
            <ActionsDropdown itemId={id} options={dropdownOptions} />
          ),
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [dropdownOptions, isMobile, classes]
  )

  return (
    <Table
      hideFooterRow
      data={locations}
      cols={locationsColumns}
      mobileColumnsWrapperClassName={classes.mobileHeaderStyle}
    />
  )
}

LocationsTableData.propTypes = {
  locations: PropTypes.array.isRequired
}

export default LocationsTableData
