import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { loginMFATypeSelector } from '../../../../modules/selectors/cognito'

import { LOGIN_SMS_MFA, LOGIN_TOTP_MFA } from '../../../../constants/other'

import MfaAuth from './MfaAuth'
import SelectMfa from './SelectMfa'

const LoginMFAContent = ({ onRestartLogin }) => {
  const mfaType = useSelector(loginMFATypeSelector)

  const hasLoginMfaType = mfaType === LOGIN_TOTP_MFA || mfaType === LOGIN_SMS_MFA

  return hasLoginMfaType ? (
    <MfaAuth onRestartLogin={onRestartLogin} />
  ) : (
    // if AWS require to select MFA from available options - select it:
    <SelectMfa onRestartLogin={onRestartLogin} />
  )
}

LoginMFAContent.propTypes = {
  onRestartLogin: PropTypes.func.isRequired
}

export default LoginMFAContent
