import { createStyles } from '../../../../../../styles/helpers'

import { googleBlue } from '../../../../../../styles/const/colors'

const useStyles = createStyles(theme => ({
  metricAdditional: {
    color: googleBlue
  },
  engagementMetricsWrapper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 4
  },
  engagementItem: {
    display: 'flex',
    alignItems: 'center',
    background: theme.brandPrimary,
    columnGap: 2,
    borderRadius: 17,
    padding: '2px 4px',
    color: 'white',
    lineHeight: '12px',
    '& svg': {
      minWidth: 10,
      maxWidth: 10,
      height: 'auto',
      fill: 'white'
    }
  }
}))

export default useStyles
