import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import InventoryFields from '../../../../../../../features/components/InventoryFields'
import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import FieldsSection from '../../../../../../../features/components/Form/FieldsSection'
import Spoiler from '../../../../../../../components/Spoiler'
import MinAndMaxPeriodsFields from '../../../../ReusableFormFIelds/MinAndMaxPeriodsFields'
import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'
import DatePicker from '../../../../../../../components/DatePicker'

import { inChargePeriods } from '../../../../../../../constants/mediaOrders'
import { INVENTORY_AMOUNT, INVENTORY_PERIOD } from '../../../../fields'
import { DEFAULT_INVENTORY_DATE_START, PERIOD_OPTION } from '../../fields'

const stepFields = [INVENTORY_AMOUNT, INVENTORY_PERIOD, DEFAULT_INVENTORY_DATE_START]

const InventoryStep = ({ formik, handleStepChange }) => {
  const { t } = useTranslation()

  const { values, errors, touched, setFieldValue } = formik

  const periodOption = values[PERIOD_OPTION]
  const periodLabel = inChargePeriods.find(period => period.value === periodOption)?.label
  const translatedPeriodLabel = t(periodLabel).toLowerCase()

  const handleStartDatePickerChange = useCallback(
    value => {
      setFieldValue(DEFAULT_INVENTORY_DATE_START, value)
    },
    [setFieldValue]
  )

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  return (
    <Step
      stepTitle="Enter the inventory for this media product"
      stepDescription={t('inventoryQuantityPeriod', { period: translatedPeriodLabel })}
      handleContinue={handleContinue}
    >
      <FieldsSection title="Inventory">
        <InventoryFields formik={formik} />
        <FieldRow title="Inventory start date" description={t('The first date that the inventory is available')}>
          <DatePicker
            placeholder={t('Inventory start date')}
            value={values[DEFAULT_INVENTORY_DATE_START]}
            changeHandler={handleStartDatePickerChange}
            error={touched[DEFAULT_INVENTORY_DATE_START] ? errors[DEFAULT_INVENTORY_DATE_START] : ''}
          />
        </FieldRow>
        <Spoiler title="Advanced settings">
          <MinAndMaxPeriodsFields formik={formik} />
        </Spoiler>
      </FieldsSection>
    </Step>
  )
}

export default InventoryStep
