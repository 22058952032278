import React from 'react'

import ShowSkeleton from '../ShowSkeleton'

function SkeletonWebsiteText({ className, count, isInProgress, showLoadingAnimation }) {
  return (
    <ShowSkeleton
      isInProgress={isInProgress}
      count={count}
      className={className}
      showLoadingAnimation={showLoadingAnimation}
    />
  )
}

export default SkeletonWebsiteText
