import React, { useCallback } from 'react'

import UploadBookedMediaFile from './UploadBookedMediaFile'
import Step from '../../../../../../../features/components/Forms/StepForm/Step'

import { FILE_URL, FILE_SELECTED_URL } from '../../fields'

const fields = [FILE_URL, FILE_SELECTED_URL]
function UploadFileStep({ formik, handleStepChange, mediaData, fileRequirements, showPeriodStep }) {
  const productName = mediaData?.original_product_name
  const fileName = fileRequirements?.file_name || ''
  const supported_file_types = fileRequirements?.supported_file_types

  const handleContinue = useCallback(() => {
    handleStepChange(fields)
  }, [handleStepChange])

  return (
    <Step
      stepTitle="Upload page creative"
      stepDescription={`Upload or select ‘${fileName}’ for ‘${productName}’`}
      handleContinue={showPeriodStep ? handleContinue : null}
    >
      <UploadBookedMediaFile formik={formik} fileRequirements={fileRequirements} fileFormats={supported_file_types} />
    </Step>
  )
}

export default UploadFileStep
