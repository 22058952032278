import { combineDateAndTime, formatDateWithTimeToBE } from '../../../../../../../../../helpers/date'
import { START_DATE, END_DATE } from '../../../../../../../../ReusableFormFields/StartEndDateRangeFields/fields'
import { BUDGET_LIFETIME, BUDGET_PERCENTAGE, BUDGET_PERCENTAGE_OPTION } from './fields'
import { OPTION_YES } from '../../../../../../../../../constants/forms'
import { formatStartDateToBE } from '../../../../formatters'

export const transformValuesToBE = ({ values, adAccountId, pixelId, country, campaign }) => {
  return {
    account: adAccountId,
    bidding_type: 'automatic',
    billing_event: 'impressions',
    budget_lifetime: values[BUDGET_LIFETIME],
    campaign_id: campaign.id,
    time_start: formatStartDateToBE(new Date(values[START_DATE])),
    time_stop: formatDateWithTimeToBE(combineDateAndTime(new Date(values[END_DATE]), '23:59')),
    campaign_objective: campaign.objective,
    geo_targeting: { countries: [country] },
    name: 'Advantage+ Shopping line item',
    promoted_object: {
      custom_event_type: 'purchase',
      pixel_id: pixelId
    },
    ...(values[BUDGET_PERCENTAGE_OPTION] === OPTION_YES && {
      existing_customer_budget_percentage: values[BUDGET_PERCENTAGE]
    }),
    execution_options: ['validate_only']
  }
}
