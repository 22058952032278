import { createStyles } from '../../styles/helpers'

import { bannerGrey, darkGrey, mediumGrey } from '../../styles/const/colors'
import { pageContainerMaxWidth } from '../../styles/common'
import { TABLE_CLASSNAME, TABLE_ROW_CLASSNAME } from '../../constants/pdf'
import { phonesDown } from '../../styles/const/breakpoints'
import { ROW_WITH_EXPAND_BUTTON_SPACING } from './constants'

export const rowLeftPadding = 15
export const rowPadding = `10px 10px 10px ${rowLeftPadding}px`
export const expandRowLeftPadding = 32 // paddingLeft is increased to reserve space for expand button
export const expandableRowPadding = `10px 10px 10px ${expandRowLeftPadding}px !important`
const mobileRowPadding = '19px 15px 19px 21px'

export const rowMinHeight = 52
const bigRowMinHeight = 58

// these styles are used across different components
export default createStyles(theme => ({
  [TABLE_CLASSNAME]: {
    border: {
      width: '1px',
      style: 'solid',
      color: mediumGrey
    },
    borderRadius: theme.bigBorderRadius,
    backgroundColor: 'white',
    display: 'grid',
    overflowX: 'auto'
  },
  rowBorder: {
    '&:not(:last-child)': {
      borderBottom: {
        width: 1,
        style: 'solid',
        color: mediumGrey
      }
    }
  },
  topBorder: {
    borderTop: {
      width: 1,
      style: 'solid',
      color: mediumGrey
    }
  },
  [TABLE_ROW_CLASSNAME]: {
    composes: '$rowBorder',
    display: 'flex',
    alignItems: 'center',
    padding: rowPadding,
    minHeight: props => (props.isBigRow ? 58 : 52),
    fontSize: 14,
    fontWeight: 400,
    // limit row max width to our pages size, 2px here is borders width
    maxWidth: `calc(${pageContainerMaxWidth}px - 2px)`
  },
  [ROW_WITH_EXPAND_BUTTON_SPACING]: {
    paddingLeft: [expandRowLeftPadding, '!important']
  },
  mobileRow: {
    composes: '$rowBorder',
    border: ['none', '!important'],
    padding: `${mobileRowPadding} !important`,
    marginBottom: [0, '!important']
  },
  draggableRow: {
    composes: '$rowBorder'
  },
  headlineRow: {
    display: 'flex',
    alignItems: 'center',
    color: darkGrey,
    fontSize: 12,
    fontWeight: 600,
    padding: ({ isMobile }) => (isMobile ? mobileRowPadding : rowPadding),
    minHeight: [60, '!important']
  },
  headlineCol: {},
  footer: {
    backgroundColor: bannerGrey,
    padding: ({ isMobile }) => (isMobile ? mobileRowPadding : rowPadding),
    minHeight: 60,
    display: 'flex',
    alignItems: 'center'
  },
  // workaround to connect main table with loading more skeletons table, todo think of better solution
  loadingMoreMainTableWrapper: {
    '& > div': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    }
  },
  loadingMoreTable: {
    borderTop: 'none',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0
  },
  loadMoreBtn: {
    display: 'block',
    margin: '20px auto 0'
  },
  mobileColumnsWrapper: {
    display: 'flex',
    alignItems: 'center',
    minHeight: props => (props.isBigRow ? bigRowMinHeight : rowMinHeight),
    paddingRight: 20, // reserve spacing for dropdown
    fontSize: 14,
    fontWeight: 400
  },
  [`@media screen and (${phonesDown})`]: {
    [TABLE_CLASSNAME]: {
      // conditionals could be removed when all tables will be refactored
      display: ({ hasMobileHiddenColumns }) => hasMobileHiddenColumns && 'flex',
      overflowX: ({ hasMobileHiddenColumns }) => hasMobileHiddenColumns && 'hidden',
      flexDirection: ({ hasMobileHiddenColumns }) => hasMobileHiddenColumns && 'column'
    }
  },
  minimisedSectionBody: {
    marginTop: 28
  },
  minimisedSectionButton: {
    marginTop: 3,
    paddingRight: 3,
    '& svg': {
      width: 12
    }
  }
}))
