import React from 'react'
import { useTranslation } from 'react-i18next'

import Field from '../../../../../../components/Form/Field'

import { NAME } from '../fields'

import useStyles from '../../../../../../styles/common/stepForms'

function NameStep({ formik }) {
  const commonClasses = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <div className={commonClasses.stepSubTitle}>{t('Create a TikTok ad group')}</div>
      <div className={commonClasses.stepTitle}>{t('Finally, give the new ad group a name')}</div>
      <div className={commonClasses.stepBody}>
        <Field formik={formik} name={NAME} label="Name" autoComplete="off" />
      </div>
    </>
  )
}

export default NameStep
