import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ButtonIcon from '../../../../../../../../../../../features/components/Buttons/ButtonIcon'

import { ReactComponent as CloseIcon } from '../../../../../../../../../../../assets/icons/close-grey.svg'

import useStyles from './styles'

function PeriodItem({ value, label, isNotValid, deleteHandler, className }) {
  const classes = useStyles()

  return (
    <div
      className={classnames(classes.selectedValueWrapper, className, {
        [classes.isNotValid]: isNotValid
      })}
    >
      <div className={classnames(classes.selectedValueLabel)}>{label}</div>
      <ButtonIcon onClick={() => deleteHandler(value)} className={classes.selectedValueRemove} Icon={CloseIcon} />
    </div>
  )
}

PeriodItem.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  label: PropTypes.string,
  isNotValid: PropTypes.bool,
  className: PropTypes.string,
  deleteHandler: PropTypes.func
}

export default PeriodItem
