import { call, put, all, takeEvery, select, takeLatest } from 'redux-saga/effects'

import { showToasts } from '../../helpers/toasts'

import {
  GET_ADS,
  getAdsSuccess,
  getAdsFailure,
  GET_AD,
  getAd,
  getAdSuccess,
  getAdFailure,
  CREATE_AD,
  createAdSuccess,
  createAdFailure,
  DELETE_AD,
  deleteAdSuccess,
  deleteAdFailure,
  VALIDATE_AD,
  validateAdSuccess,
  validateAdFailure,
  COPY_AD,
  copyAdSuccess,
  copyAdFailure,
  APPROVE_INTERNAL_AD,
  approveInternalAdSuccess,
  approveInternalAdFailure,
  DISAPPROVE_INTERNAL_AD,
  disapproveInternalAdSuccess,
  disapproveInternalAdFailure,
  DELETE_INTERNAL_AD,
  deleteInternalAdSuccess,
  deleteInternalAdFailure,
  createAdsBatchSuccess,
  createAdsBatchFailure,
  CREATE_ADS_BATCH,
  getInternalAdsItemsCountSuccess,
  getInternalAdsItemsCountFailure,
  GET_INTERNAL_ADS_ITEMS_COUNT,
  decrementInternalAdsItemsCount
} from '../actions/ads'
import {
  getAdsService,
  createAdService,
  updateAdService,
  deleteAdService,
  getAdService,
  copyAdService,
  approveInternalAdService,
  disapproveInternalAdService,
  deleteInternalAdService,
  createAdsBatchService,
  getInternalAdsItemsCountService
} from '../services/ads'

import { UPDATE_AD, updateAdSuccess, updateAdFailure } from '../actions/ads'
import { selectedPlatformSelector, selectedSelfAccountSelector } from '../selectors/app'
import { TOAST_TYPE } from '../../constants/other'
import {
  FACEBOOK_PLATFORM,
  PLATFORMS_TO_PROVIDER,
  PROVIDER_TO_PLATFORMS
} from '../../constants/selectLists/platformList'

function* adsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(CREATE_AD, createAd),
    takeEvery(CREATE_ADS_BATCH, createAdsBatchWorker),
    takeEvery(VALIDATE_AD, validateAd),
    takeLatest(GET_ADS, getAdsWorker),
    takeEvery(UPDATE_AD, updateAdWorker),
    takeEvery(DELETE_AD, deleteAdWorker),
    takeEvery(GET_AD, getAdWorker),
    takeEvery(COPY_AD, copyAdWorker),
    takeEvery(APPROVE_INTERNAL_AD, approveInternalAdWorker),
    takeEvery(DISAPPROVE_INTERNAL_AD, disapproveInternalAdWorker),
    takeEvery(DELETE_INTERNAL_AD, deleteInternalAdWorker),
    takeEvery(GET_INTERNAL_ADS_ITEMS_COUNT, getInternalAdsItemsCountWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getAdsWorker({ params }) {
  try {
    const selectedPlatform = yield select(selectedPlatformSelector)

    // on internal ads page we need to pass platform from params
    const platform = params.platform || selectedPlatform

    const ads = yield call(getAdsService, params, platform)

    yield put(getAdsSuccess(ads))
  } catch (error) {
    yield put(getAdsFailure(error))
  }
}

function* getAdWorker({ params, pushToList }) {
  try {
    const selectedPlatform = yield select(selectedPlatformSelector)

    const platform = params.platform || selectedPlatform

    const adData = yield call(getAdService, params, platform)
    yield put(getAdSuccess(adData, pushToList))
  } catch (error) {
    yield put(getAdFailure(error))
  }
}

function* createAd({ adData, platform }) {
  try {
    const selectedPlatform = yield select(selectedPlatformSelector)
    const selectedSelfAccount = yield select(selectedSelfAccountSelector)

    const preparedAdData = {
      ...adData,
      account_id: selectedSelfAccount
    }
    const adPlatform = platform || selectedPlatform

    const response = yield call(createAdService, preparedAdData, adPlatform)
    yield put(
      createAdSuccess({
        ...response,
        // todo remove when provided will be added from BE side
        provider: PLATFORMS_TO_PROVIDER[adPlatform]
      })
    )
  } catch (error) {
    yield put(createAdFailure(error))
  }
}

function* createAdsBatchWorker({ adsData, platform }) {
  try {
    const selectedPlatform = yield select(selectedPlatformSelector)

    const response = yield call(createAdsBatchService, adsData, platform || selectedPlatform)
    yield put(createAdsBatchSuccess(response))
  } catch (error) {
    yield put(createAdsBatchFailure(error))
  }
}

function* validateAd({ adData, platform }) {
  const conversionDomain = adData.conversion_domain
  try {
    const selectedPlatform = yield select(selectedPlatformSelector)

    const response = yield call(createAdService, adData, platform || selectedPlatform, false)
    yield put(validateAdSuccess(response, conversionDomain))
  } catch (error) {
    yield put(validateAdFailure(error, conversionDomain))
  }
}

function* updateAdWorker({ adData, id, requestMethod, ...rest }) {
  try {
    const currentAdPlatform = PROVIDER_TO_PLATFORMS[adData.provider]

    // Order is platform param -> currentAdPlatform -> selectedPlatform
    const platform = rest.platform || currentAdPlatform

    const response = yield call(updateAdService, adData, id, requestMethod, platform)

    // Facebook: When we update ad - we also have to update the ad in ads list,
    // but response structure is different from ads list structure,
    // so we have to manually add `thumbnail` field to be able to update ads list
    // for single image use: response.creative.image_url
    // for single video use: response.creative.object_story_spec.video_data.image_url
    const thumbnail = response.creative?.image_url || response.creative?.object_story_spec?.video_data?.image_url

    const mockedResponse = {
      ...response,
      ...(platform === FACEBOOK_PLATFORM && { thumbnail })
    }

    yield put(updateAdSuccess(mockedResponse))
  } catch (e) {
    yield put(updateAdFailure(e))
  }
}

function* deleteAdWorker({ id, params }) {
  try {
    const platform = yield select(selectedPlatformSelector)

    yield call(deleteAdService, id, params, platform)

    yield put(deleteAdSuccess(id))
  } catch (e) {
    yield put(deleteAdFailure(e))
  }
}

function* copyAdWorker({ data, adId }) {
  try {
    const platform = yield select(selectedPlatformSelector)
    const response = yield call(copyAdService, data, adId, platform)

    yield put(
      copyAdSuccess({
        ...response,
        adset_id: data.adset_id
      })
    )
    // get duplicated ad data and push it to ads list
    yield put(getAd({ account: data.account, id: response.copied_ad_id }, true))
  } catch (e) {
    yield put(copyAdFailure(e))
  }
}

function* approveInternalAdWorker({ id, provider }) {
  try {
    const response = yield call(approveInternalAdService, id)

    const modifiedResponse = {
      ...response,
      // set approval_status to null, because BE doesn't modify this field after approval
      approval_status: null
    }

    yield put(approveInternalAdSuccess(id, modifiedResponse))

    if (provider) {
      yield put(decrementInternalAdsItemsCount(provider))
    }

    showToasts({
      type: TOAST_TYPE.success,
      message: 'Ad approved successfully'
    })
  } catch (error) {
    yield put(approveInternalAdFailure(error))
  }
}

function* disapproveInternalAdWorker({ id, data, provider }) {
  try {
    yield call(disapproveInternalAdService, id, data)

    yield put(disapproveInternalAdSuccess(id))

    if (provider) {
      yield put(decrementInternalAdsItemsCount(provider))
    }
  } catch (error) {
    yield put(disapproveInternalAdFailure(error))
  }
}

function* deleteInternalAdWorker({ id }) {
  try {
    yield call(deleteInternalAdService, id)
    yield put(deleteInternalAdSuccess(id))
  } catch (error) {
    yield put(deleteInternalAdFailure(error))
  }
}

function* getInternalAdsItemsCountWorker({ params }) {
  try {
    const response = yield call(getInternalAdsItemsCountService, params)
    yield put(getInternalAdsItemsCountSuccess(response))
  } catch (error) {
    yield put(getInternalAdsItemsCountFailure(error))
  }
}

export default adsWatcher
