import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles({
  dateFieldsWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    '& .field': {
      marginTop: 0
    },
    '& > div:nth-child(1)': {
      minWidth: 190,
      marginRight: 8
    }
  }
})

export default useStyles
