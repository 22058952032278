import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import usePreselectUserCountry from '../../../features/hooks/usePreselectUserCountry'

import Regions from './Regions'
import Locations from './Locations'
import Spoiler from '../../../components/Spoiler'
import RadioBlock from '../../../components/Form/RadioBlock'
import MultiSelectBox from '../../../features/components/Form/MultiSelectBox'

import { getChoicesCountries } from '../../../modules/actions/choices'

import { choicesCountriesSelector, choicesCountriesWasLoadedSelector } from '../../../modules/selectors/choices'

import { SHOW_OPTION, SHOW_COUNTRIES, SHOW_OPTION_WORLDWIDE, SHOW_OPTION_COUNTRIES } from './fields'

const Geolocations = ({
  formik,
  showCountries = true,
  showRegions = true,
  showRegionsCountryFilter = true,
  showCustomLocations = true,
  showWorldwide = true,
  platform,
  adAccountId,
  countriesFilter
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { values, errors, touched, setFieldValue } = formik

  const countriesError = errors[SHOW_COUNTRIES]
  const countiesTouched = touched[SHOW_COUNTRIES]

  const countries = useSelector(choicesCountriesSelector)
  const countriesWasLoaded = useSelector(choicesCountriesWasLoadedSelector)

  const formattedCountries = useMemo(() => {
    // filtering countries if filter applied
    let countriesList = countriesFilter ? [] : countries
    if (countriesFilter) {
      countriesList = countries.filter(countriesFilter)
    }

    // Sorting countries in alphabetical order
    return (
      countriesList &&
      countriesList
        .sort((a, b) => {
          if (a.name > b.name) return 1
          if (a.name < b.name) return -1

          return 0
        })
        .map(item => {
          // resourceName is used for Google
          return {
            value: item.id,
            label: item.name,
            code: item.iso2,
            resourceName: item.resource_name,
            tiktok_id: item['tiktok_id']
          }
        })
    )
  }, [countries, countriesFilter])

  usePreselectUserCountry({ formik, formattedCountries })

  useEffect(() => {
    // getChoicesCountries for SHOW_OPTION_COUNTRIES
    if (!countriesWasLoaded) {
      dispatch(getChoicesCountries())
    }
  }, [dispatch, countriesWasLoaded])

  return (
    <>
      {showCountries && (
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_show_option_countries"
          name={SHOW_OPTION}
          value={SHOW_OPTION_COUNTRIES}
          selectedValue={values[SHOW_OPTION]}
          label={t('In a specific country or countries')}
          error={countriesError}
          touched={countiesTouched}
        >
          <MultiSelectBox
            placeholder={t('Add a Country')}
            name={SHOW_COUNTRIES}
            options={formattedCountries}
            value={values[SHOW_COUNTRIES]}
            setFieldValue={setFieldValue}
          />
        </RadioBlock>
      )}
      {showRegions && (
        <Regions
          formik={formik}
          showRegionsCountryFilter={showRegionsCountryFilter}
          formattedCountries={formattedCountries}
          adAccountId={adAccountId}
          platform={platform}
        />
      )}
      {showCustomLocations && <Locations formik={formik} />}
      {showWorldwide && (
        <Spoiler title={t('View more geotargeting options')}>
          <RadioBlock
            setFieldValue={setFieldValue}
            id="radio_show_option_worldwide"
            name={SHOW_OPTION}
            value={SHOW_OPTION_WORLDWIDE}
            selectedValue={values[SHOW_OPTION]}
            label={t('Worldwide')}
          />
        </Spoiler>
      )}
    </>
  )
}

Geolocations.propTypes = {
  formik: PropTypes.object,
  showRegions: PropTypes.bool,
  showRegionsCountryFilter: PropTypes.bool,
  showCustomLocations: PropTypes.bool,
  showWorldwide: PropTypes.bool,
  adAccountId: PropTypes.string,
  platform: PropTypes.string,
  countriesFilter: PropTypes.func
}

export default Geolocations
