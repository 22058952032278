import React from 'react'
import PropTypes from 'prop-types'

import BreakdownFilter from '../../../../../../features/components/Filters/BreakdownFilter'
import { BookedRevenueDataProvider, CalendarBookedRevenueContext } from './CalendarBookedRevenueContext'

import useStyles from './styles'

const BookedRevenueFilters = ({ onFiltersChange }) => {
  const classes = useStyles()

  return (
    <BookedRevenueDataProvider onFiltersChange={onFiltersChange}>
      <div className={classes.filtersRow}>
        <div className={classes.rightFiltersRow}>
          <BreakdownFilter Context={CalendarBookedRevenueContext} />
          {/*this is used for portalled filters - don't remove until check id usage */}
          <div id="booked_media_calendar_view_portal" />
          {/*this is used for pdf generation */}
          <div id="pdf_generation_portal" />
        </div>
      </div>
    </BookedRevenueDataProvider>
  )
}

BookedRevenueFilters.propTypes = {
  onFiltersChange: PropTypes.func
}

export default BookedRevenueFilters
