export const ELEMENT_HEIGHT = 46

export const resizeRow = args => {
  const { rowElement } = args

  if (!rowElement) return

  requestAnimationFrame(() => {
    const eventBars = rowElement.querySelectorAll('.event-bar')

    if (!eventBars.length) {
      // reset row height if there are no event bars
      rowElement.querySelector('td').style.height = ''
    } else {
      const sortedBars = [...eventBars].sort((a, b) => a.dataset.timestamp - b.dataset.timestamp)

      const rows = sortedBars.reduce((acc, bar, index) => {
        const visibleBar = bar.querySelector('.js-event-bar-placeholder')
        const barData = {
          element: bar,
          visibleBar,
          rect: visibleBar.getBoundingClientRect()
        }

        // find row index where bar should be placed
        const barRowIndex = findRowIndex(acc, barData, 0)

        if (!acc[barRowIndex]) {
          // create new row if it doesn't exist
          acc[barRowIndex] = []
        }

        // push bar to row
        acc[barRowIndex].push(barData)

        return acc
      }, [])

      const calculatedHeight = ELEMENT_HEIGHT + (rows.length - 1) * ELEMENT_HEIGHT

      rows.forEach((row, index) => {
        // goes through each row and compares the last element in row to current element to calculate current's element position
        row.forEach(barData => {
          barData.element.style.top = `${getElementVerticalPosition(index)}px`
        })
      })

      rowElement.querySelector('td').style.height = `${calculatedHeight}px`
    }
  })
}

const getElementVerticalPosition = elementIndex => {
  return elementIndex * ELEMENT_HEIGHT
}

function findRowIndex(rows, barData, index) {
  const row = rows[index]

  if (!row) {
    return index
  }

  const lastRowElement = row[row.length - 1]

  if (barData.rect.left < lastRowElement.rect.right) {
    return findRowIndex(rows, barData, index + 1)
  } else {
    return index
  }
}
