import { createStyles } from '../../../../../../../../../../styles/helpers'

import { greyOutline } from '../../../../../../../../../../styles/const/colors'

const useStyles = createStyles({
  icon: {
    minWidth: 12,
    minHeight: 12,
    marginRight: 8,
    '& svg': {
      height: 12,
      width: 12
    }
  },
  emptyIcon: {
    extend: 'icon',
    display: 'inline-block',
    background: greyOutline,
    borderRadius: '50%'
  }
})

export default useStyles
