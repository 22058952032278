import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

// Get media orders bookings as csv
export const getMediaOrdersBookingsAsCsvSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.mediaOrdersBookingsAsCSV.data,
  data => data
)

export const getMediaOrdersBookingsAsCsvIsLoadingSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.mediaOrdersBookingsAsCSV.isLoading,
  isLoading => isLoading
)

export const getMediaOrdersBookingsAsCsvErrorSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.mediaOrdersBookingsAsCSV.error,
  error => error
)

export const getMediaOrdersBookingsAsCsvWasLoadedSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.mediaOrdersBookingsAsCSV.wasLoaded,
  wasLoaded => wasLoaded
)

// Get booking media files
export const bookingMediaFilesSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bookingMediaFiles.results,
  results => results
)

export const getBookingMediaFilesIsLoadingSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bookingMediaFiles.isLoading,
  isLoading => isLoading
)

export const getBookingMediaFilesErrorSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bookingMediaFiles.error,
  error => error
)

export const getBookingMediaFilesWasLoadedSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bookingMediaFiles.wasLoaded,
  wasLoaded => wasLoaded
)

export const getBookedMediaFilesNextSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bookingMediaFiles.paging,
  paging => paging.next
)

// Update booking media file
export const updateBookedMediaFileSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.updateBookingMediaFile,
  updateBookingMediaFile => updateBookingMediaFile
)

export const updateBookedMediaFileIsLoadingSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.updateBookingMediaFile.isLoading,
  isLoading => isLoading
)

export const updateBookedMediaFileWasUpdatedSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.updateBookingMediaFile.wasUpdated,
  wasUpdated => wasUpdated
)

export const updateBookedMediaFileErrorSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.updateBookingMediaFile.error,
  error => error
)

// Delete booking media file
export const deleteBookingMediaFileSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.deleteBookingMediaFile,
  results => results
)

export const deleteBookingMediaFileIsLoadingSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.deleteBookingMediaFile.isLoading,
  isLoading => isLoading
)

export const deleteBookingMediaFileErrorSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.deleteBookingMediaFile.error,
  error => error
)

export const deleteBookingMediaFileWasLoadedSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.deleteBookingMediaFile.wasLoaded,
  wasLoaded => wasLoaded
)

// Upload booking media file
export const uploadBookingMediaFileSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.uploadBookingMediaFile,
  uploadBookingMediaFile => uploadBookingMediaFile
)

export const uploadBookingMediaFileIsLoadingSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.uploadBookingMediaFile.isLoading,
  isLoading => isLoading
)

export const uploadBookingMediaFileErrorSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.uploadBookingMediaFile.error,
  error => error
)

export const uploadBookingMediaFileWasCreatedSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.uploadBookingMediaFile.wasCreated,
  wasCreated => wasCreated
)

// Get order booked media
export const bookedMediaSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bookedMedia.results,
  results => results
)

export const bookedMediaIsLoadingSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bookedMedia.isLoading,
  isLoading => isLoading
)

export const bookedMediaErrorSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bookedMedia.error,
  error => error
)

export const bookedMediaWasLoadedSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bookedMedia.wasLoaded,
  wasLoaded => wasLoaded
)

export const bookedMediaNextSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bookedMedia.paging.next,
  next => next
)

// Get booked media report
export const bookedMediaReportSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bookedMediaReport.results,
  results => results
)

export const getBookedMediaReportIsLoadingSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bookedMediaReport.isLoading,
  isLoading => isLoading
)

export const getBookedMediaReportErrorSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bookedMediaReport.error,
  error => error
)

export const getBookedMediaReportWasLoadedSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bookedMediaReport.wasLoaded,
  wasLoaded => wasLoaded
)

export const bookedMediaReportLoadingPeriodsSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bookedMediaReport.loadingPeriods,
  loadingPeriods => loadingPeriods
)

// Get booked revenue report
export const bookedRevenueReportSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.getBookedRevenueReport.results,
  results => results
)

export const getBookedRevenueReportIsLoadingSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.getBookedRevenueReport.isLoading,
  isLoading => isLoading
)

export const getBookedRevenueReportErrorSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.getBookedRevenueReport.error,
  error => error
)

export const getBookedRevenueReportWasLoadedSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.getBookedRevenueReport.wasLoaded,
  wasLoaded => wasLoaded
)

export const bookedRevenueReportLoadingPeriodsSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.getBookedRevenueReport.loadingPeriods,
  loadingPeriods => loadingPeriods
)

// Get installation booked media report
export const installationBookedMediaReportSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.installationBookedMediaReport.results,
  results => results
)

export const getInstallationBookedMediaReportIsLoadingSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.installationBookedMediaReport.isLoading,
  isLoading => isLoading
)

export const getInstallationBookedMediaReportErrorSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.installationBookedMediaReport.error,
  error => error
)

export const installationBookedMediaReportWasLoadedSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.installationBookedMediaReport.wasLoaded,
  wasLoaded => wasLoaded
)

export const getInstallationBookedMediaReportAsCsvSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.installationBookedMediaReportAsCSV.data,
  data => data
)

export const getInstallationBookedMediaReportAsCsvIsLoadingSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.installationBookedMediaReportAsCSV.isLoading,
  isLoading => isLoading
)

export const getInstallationBookedMediaReportAsCsvErrorSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.installationBookedMediaReportAsCSV.error,
  error => error
)

export const getInstallationBookedMediaReportAsCsvWasLoadedSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.installationBookedMediaReportAsCSV.wasLoaded,
  wasLoaded => wasLoaded
)

// Bulk update booking media file
export const bulkUpdateBookingMediaFileSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bulkUpdateBookingMediaFile,
  bulkUpdateBookingMediaFile => bulkUpdateBookingMediaFile
)

export const bulkUpdateBookingMediaFileIsLoadingSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bulkUpdateBookingMediaFile.isLoading,
  isLoading => isLoading
)

export const bulkUpdateBookingMediaFileErrorSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bulkUpdateBookingMediaFile.error,
  error => error
)

export const bulkUpdateBookingMediaFileWasUpdatedSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bulkUpdateBookingMediaFile.wasUpdated,
  wasUpdated => wasUpdated
)

// Bulk update booked media
export const bulkUpdateBookedMediaSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bulkUpdateBookedMedia,
  bulkUpdateBookedMedia => bulkUpdateBookedMedia
)

export const bulkUpdateBookedMediaIsLoadingSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bulkUpdateBookedMedia.isLoading,
  isLoading => isLoading
)

export const bulkUpdateBookedMediaErrorSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bulkUpdateBookedMedia.error,
  error => error
)

export const bulkUpdateBookedMediaWasUpdatedSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.bulkUpdateBookedMedia.wasUpdated,
  wasUpdated => wasUpdated
)

// Get booked media
export const getBookedMediaSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.getBookedMedia.data,
  data => data
)

export const getBookedMediaIsLoadingSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.getBookedMedia.isLoading,
  isLoading => isLoading
)

export const getBookedMediaErrorSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.getBookedMedia.error,
  error => error
)

export const getBookedMediaWasLoadedSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.getBookedMedia.wasLoaded,
  wasLoaded => wasLoaded
)

// Get brand revenue report
export const brandRevenueReportSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.brandRevenueReport.data,
  data => data
)

export const getBrandRevenueReportIsLoadingSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.brandRevenueReport.isLoading,
  isLoading => isLoading
)

export const getBrandRevenueReportErrorSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.brandRevenueReport.error,
  error => error
)

export const brandRevenueReportWasLoadedSelector = createSelector(
  ({ mediaOrdersBookings }) => mediaOrdersBookings.brandRevenueReport.wasLoaded,
  wasLoaded => wasLoaded
)
