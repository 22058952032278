import React, { useCallback, useMemo } from 'react'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ActionButton from '../../AdCard/ActionButton'
import InstagramStoryThumbnail from './InstagramStoryThumbnail'

import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/chevron-arrow-down.svg'

import {
  FILE_HASH,
  FILE_ID,
  FILE_NAME,
  FILE_URL,
  MEDIA_TYPE,
  STORIES_ITEM_SELECTED_INDEX,
  STORIES_ITEMS,
  VIDEO_PICTURE
} from '../../../../../forms/Facebook/AdForms/fields'
import { CALL_TO_ACTION } from '../../../../../forms/ReusableFormFields/CallToActionFields/fields'

import useStyles from './styles'

const AdStoriesItem = ({
  viewAdOnFacebook,
  formikValues,
  elementInProgress,
  index,
  fileUrl,
  handleLeftSideClick,
  handleRightSideClick
}) => {
  const storiesItems = formikValues[STORIES_ITEMS]
  const selectedStoriesItemIndex = formikValues[STORIES_ITEM_SELECTED_INDEX]
  const isActive = selectedStoriesItemIndex === index && elementInProgress === STORIES_ITEMS

  const classes = useStyles({ selectedStoriesItemIndex, fileUrl })

  // ACTION BUTTON
  const actionValue = getIn(formikValues, `${STORIES_ITEMS}[${index}].${CALL_TO_ACTION}`)

  const storiesItem = getIn(formikValues, `${STORIES_ITEMS}[${index}]`)

  const mediaFile = useMemo(
    () => ({
      [MEDIA_TYPE]: getIn(storiesItem, MEDIA_TYPE),
      [FILE_NAME]: getIn(storiesItem, FILE_NAME),
      [FILE_URL]: getIn(storiesItem, FILE_URL),
      [FILE_HASH]: getIn(storiesItem, FILE_HASH),
      [VIDEO_PICTURE]: getIn(storiesItem, VIDEO_PICTURE),
      [FILE_ID]: getIn(storiesItem, FILE_ID)
    }),
    [storiesItem]
  )

  const leftSideClick = useCallback(() => {
    if (handleLeftSideClick && selectedStoriesItemIndex !== 0) {
      handleLeftSideClick()
    }
  }, [handleLeftSideClick, selectedStoriesItemIndex])

  const rightSideClick = useCallback(() => {
    if (handleRightSideClick && selectedStoriesItemIndex !== storiesItems.length - 1) {
      handleRightSideClick()
    }
  }, [handleRightSideClick, selectedStoriesItemIndex, storiesItems])

  return (
    <div className={classes.AdStoriesItem}>
      <div className={classes.adStoriesCardMedia}>
        <InstagramStoryThumbnail
          viewAdOnFacebook={viewAdOnFacebook}
          mediaFile={mediaFile}
          isActive={isActive}
          isInProgress={isActive}
          className={classes.adImageInstagram}
        />
      </div>
      <div className={classes.adStoriesCardButton}>
        <ArrowIcon className={classes.adStoriesCardIcon} />
        <ActionButton isInProgress={isActive} actionValue={actionValue} className={classes.storyActionButton} />
      </div>
      <div className={classnames(classes.adStoriesCardSide, classes.adStoriesCardLeftSide)} onClick={leftSideClick} />
      <div className={classnames(classes.adStoriesCardSide, classes.adStoriesCardRightSide)} onClick={rightSideClick} />
    </div>
  )
}

AdStoriesItem.propTypes = {
  viewAdOnFacebook: PropTypes.string,
  formikValues: PropTypes.object,
  elementInProgress: PropTypes.string,
  index: PropTypes.number,
  fileUrl: PropTypes.string,
  handleLeftSideClick: PropTypes.func,
  handleRightSideClick: PropTypes.func
}

export default AdStoriesItem
