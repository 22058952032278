import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import CampaignForm from './CampaignForm'
import LineItemForm from './LineItemForm'
import FormWrapper from '../../../../features/components/Form/FormWrapper'
import AdForm from './AdForm'

import useFormProgressItems from './useFormProgressItems'
import useRedirectFormDrawer from '../../../../features/components/FormDrawerWrapper/useRedirectFormDrawer'

import { mediaOrderUploadCreativeDataSelector } from '../../../../modules/selectors/mediaOrders'
import { ROUTES } from '../../../../constants/routes'
import { MODAL_TYPE } from '../../../../constants/other'

export const CAMPAIGN_FORM = 'campaign_form'
export const LINE_ITEM_FORM = 'line_item_form'
export const AD_FORM = 'ad_form'
const UploadTikTokAdCreativeContent = ({ adAccountId, setCreatedCreativeData, manageStepFormRoutes }) => {
  const { campaign, lineItem } = useSelector(mediaOrderUploadCreativeDataSelector)

  // const [isExistingCampaignFlow, setIsExistingCampaignFlow] = useState(false)

  const { id: lineItemId } = lineItem
  const { id: campaignId } = campaign

  const { selectedRoute, handleSelectRoute } = manageStepFormRoutes

  const formProgressItems = useFormProgressItems({
    activatedForm: selectedRoute
  })

  const handleSuccessFormSubmit = useCallback(
    selectedRoute => {
      handleSelectRoute(selectedRoute)
    },
    [handleSelectRoute]
  )

  const handleSuccessRedirect = useRedirectFormDrawer(
    `${ROUTES.mediaOrderSummaryOffNetworkDigital}?message=${MODAL_TYPE.mediaOrderTikTokCreativeUploaded}`
  )

  const onSuccessAdFormSubmit = useCallback(() => {
    setCreatedCreativeData({
      adAccountId,
      campaignId,
      lineItemId
    })
    // close form and redirect to modals
    handleSuccessRedirect()
  }, [handleSuccessRedirect, setCreatedCreativeData, adAccountId, campaignId, lineItemId])

  const getActivatedForm = useCallback(() => {
    switch (selectedRoute) {
      case AD_FORM:
        return <AdForm onSuccessSubmit={onSuccessAdFormSubmit} adAccountId={adAccountId} />
      case LINE_ITEM_FORM:
        return <LineItemForm onSuccessSubmit={() => handleSuccessFormSubmit(AD_FORM)} />
      case CAMPAIGN_FORM:
      default:
        return <CampaignForm onSuccessSubmit={() => handleSuccessFormSubmit(LINE_ITEM_FORM)} />
    }
  }, [selectedRoute, adAccountId, handleSuccessFormSubmit, onSuccessAdFormSubmit])

  return <FormWrapper formProgressItems={formProgressItems}>{getActivatedForm()}</FormWrapper>
}

UploadTikTokAdCreativeContent.propTypes = {
  adAccountId: PropTypes.string.isRequired,
  setCreatedCreativeData: PropTypes.func.isRequired,
  manageStepFormRoutes: PropTypes.object.isRequired
}

export default UploadTikTokAdCreativeContent
