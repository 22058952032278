import { createStyles } from '../../../../../../styles/helpers'

import { zIndex1, zIndex9 } from '../../../../../../styles/const/common'
import { adPreviewFooterHeight, cardBorderRadius } from '../styles'

export default createStyles({
  videoContainer: {
    display: 'flex',
    position: 'absolute',
    top: `calc(50% - ${adPreviewFooterHeight / 2}px)`,
    transform: 'translateY(-50%)',
    width: '100%',
    height: 'auto',
    borderTopLeftRadius: cardBorderRadius,
    borderTopRightRadius: cardBorderRadius,
    zIndex: zIndex1
  },
  video: {
    width: '100%',
    height: 'auto'
  },
  playButton: {
    borderRadius: '50%',
    border: 'none',
    width: '70px',
    height: '70px',
    padding: 0,
    color: 'rgba(255,255,255,0.7)',
    background: 'rgba(0,0,0,.38)',
    overflow: 'hidden',
    textAlign: 'center',
    lineHeight: '70px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: zIndex9,
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.85
    }
  }
})
