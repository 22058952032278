import {
  END_DATE,
  INCREASE,
  INCREASE_SELECT_OPTION,
  LOCATIONS,
  MEDIA_CATEGORY,
  PRICE_CHANGE,
  START_DATE
} from '../../fields'

export const initialValues = {
  [START_DATE]: '',
  [END_DATE]: '',
  [MEDIA_CATEGORY]: '',
  [PRICE_CHANGE]: '',
  [INCREASE]: INCREASE_SELECT_OPTION,
  [LOCATIONS]: []
}
