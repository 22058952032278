import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getBudgetType } from '../../../../../../../Multiplatform/LineItemForms/typeHelpers'
import { formatCurrency } from '../../../../../../../../helpers/numbers'

import { lineItemSelector } from '../../../../../../../../modules/selectors/lineItems'
import { selectedAdAccountDataSelector } from '../../../../../../../../modules/selectors/app'

import { BUDGET_DAILY, BUDGET_LIFETIME } from '../../../../../../../Multiplatform/LineItemForms/fields'
import { CAMPAIGN_BUDGET } from '../../../../../../../Multiplatform/CampaignForms/fields'

const BudgetPreview = () => {
  const { t } = useTranslation()

  const lineItem = useSelector(lineItemSelector)
  const { currency_symbol: currencySymbol } = useSelector(selectedAdAccountDataSelector)

  const { budget_lifetime: lineItemBudgetLifetime, budget_daily: lineItemBudgetDaily } = lineItem

  const budgetType = getBudgetType(lineItemBudgetLifetime, lineItemBudgetDaily)

  const formattedBudgetLifetime = formatCurrency(lineItemBudgetLifetime, { min: 2, max: 2 }, { symbol: currencySymbol })

  const formattedBudgetDaily = formatCurrency(lineItemBudgetDaily, { min: 2, max: 2 }, { symbol: currencySymbol })

  if (budgetType === BUDGET_LIFETIME) {
    return (
      <span>
        {formattedBudgetLifetime} ({t('Lifetime')})
      </span>
    )
  }

  if (budgetType === BUDGET_DAILY) {
    return (
      <span>
        {formattedBudgetDaily} ({t('Daily')})
      </span>
    )
  }

  if (budgetType === CAMPAIGN_BUDGET) {
    return <span>{t('Campaign level budget is set')}</span>
  }

  return null
}

export default BudgetPreview
