import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import AdFormContent from './AdFormContent'
import DrawerHeadline from '../../../../../components/Drawer/DrawerHeadline'

import { clearTikTokIdentities, getTikTokIdentities } from '../../../../../modules/actions/tikTokConstants'

const AdForm = ({ onSuccessSubmit, adAccountId }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTikTokIdentities({ account: adAccountId }))

    return () => {
      dispatch(clearTikTokIdentities())
    }
  }, [dispatch, adAccountId])

  return (
    <>
      <DrawerHeadline
        activeStepNumber={4}
        customStepsLength={4}
        description="Images should be transparent PNG files that will be displayed on a light grey background"
        title="Upload a TikTok video creative"
      />
      <AdFormContent adAccountId={adAccountId} onSuccessSubmit={onSuccessSubmit} />
    </>
  )
}

AdForm.propTypes = {
  adAccountId: PropTypes.string.isRequired,
  onSuccessSubmit: PropTypes.func.isRequired
}

export default AdForm
