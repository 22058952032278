import IconAngle from '../../assets/icons/chevron-arrow-down.svg'
import { darkGrey, greyOutline, lightYellow, mediumGrey, textGrey } from '../const/colors'
import { transitionShort } from '../const/common'
import { placeholderColor } from '../mixins/common'
import { tabletDown } from '../const/breakpoints'

export const angleIcon = {
  content: '""',
  backgroundImage: `url("${IconAngle}")`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  width: 16,
  height: 12,
  display: 'inline-block'
}

export const backBtn = theme => ({
  border: 0,
  padding: 0,
  backgroundColor: 'transparent',
  outline: 'none',
  fontSize: 14,
  lineHeight: 1,
  fontWeight: 600,
  color: greyOutline,
  display: 'flex',
  alignItems: 'center',
  fontFamily: theme.fontFamily.value,
  cursor: 'pointer',
  transition: transitionShort,
  '&:hover': {
    color: darkGrey
  },
  '& svg': {
    transform: 'rotate(90deg)',
    marginRight: 6
  }
})

export const inputHeight = 38
export const inputMobileHeight = 40

export const input = (hasSymbol = true, theme) => ({
  fontSize: 14,
  lineHeight: 1,
  padding: '10px 12px',
  height: inputHeight,
  color: textGrey,
  width: '100%',
  appearance: 'none',
  fontWeight: 400,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: greyOutline,
  borderRadius: theme.defaultBorderRadius,
  outline: 'none',
  ...placeholderColor(greyOutline),

  [`@media screen and (${tabletDown})`]: {
    height: inputMobileHeight
  },

  '&:active, &:focus': {
    // hasSymbol helps to remove console warning when targeting by $symbol without having a html tag
    // helpful when we reuse input styles for the cases where we don't need an input symbol
    ...(hasSymbol && {
      '& ~ $symbol': {
        opacity: 1
      }
    })
  },

  '&[type="number"]': {
    appearance: 'textfield',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      margin: 0,
      appearance: 'none'
    }
  },

  '&:-webkit-autofill': {
    // move label to the top for autofilled values which are not setting to input value SHOP-90
    fontSize: 14,

    ...(hasSymbol && {
      '& ~ $symbol': {
        opacity: 1
      }
    })
  },
  '&:-webkit-autofill::first-line': {
    fontSize: '14px !important'
  }
})

export const radioCheckboxStyles = ({ theme, isChecked, hasBorder }) => ({
  backgroundColor: 'white',
  border: hasBorder && {
    style: 'solid',
    width: 1,
    radius: theme.bigBorderRadius
  },
  borderColor: isChecked ? theme.brandPrimary : mediumGrey
})

export const scrollableList = {
  display: 'grid',
  overflowX: 'auto',
  paddingBottom: 16,
  marginBottom: -14
}

export const dropdownMenuHover = {
  backgroundColor: lightYellow
}
