import { OUTCOME_AWARENESS, OUTCOME_ENGAGEMENT, OUTCOME_SALES, OUTCOME_TRAFFIC } from '../../../constants/campaigns'
import {
  AUDIENCE_NETWORK_CLASSIC,
  AUDIENCE_NETWORK_INSTREAM_VIDEO,
  AUDIENCE_NETWORK_REWARDED_VIDEO,
  FACEBOOK_BIZ_DISCO_FEED,
  FACEBOOK_FACEBOOK_REELS,
  FACEBOOK_FACEBOOK_REELS_OVERLAY,
  FACEBOOK_FEED,
  FACEBOOK_INSTANT_ARTICLE,
  FACEBOOK_INSTREAM_VIDEO,
  FACEBOOK_MARKETPLACE,
  FACEBOOK_RIGHT_HAND_COLUMN,
  FACEBOOK_SEARCH,
  FACEBOOK_STORY,
  FACEBOOK_VIDEO_FEEDS,
  INSTAGRAM_EXPLORE,
  INSTAGRAM_EXPLORE_HOME,
  INSTAGRAM_IG_SEARCH,
  INSTAGRAM_PROFILE_FEED,
  INSTAGRAM_REELS,
  INSTAGRAM_REELS_OVERLAY,
  INSTAGRAM_SHOP,
  INSTAGRAM_STORY,
  INSTAGRAM_STREAM,
  MESSENGER_MESSENGER_HOME,
  MESSENGER_SPONSORED_MESSAGES,
  MESSENGER_STORY
} from './fields'

export const placementPositionInitialValues = {
  [FACEBOOK_FEED]: false,
  [INSTAGRAM_STREAM]: false,
  [FACEBOOK_MARKETPLACE]: false,
  [FACEBOOK_VIDEO_FEEDS]: false,
  [FACEBOOK_RIGHT_HAND_COLUMN]: false,
  [INSTAGRAM_EXPLORE]: false,
  [INSTAGRAM_SHOP]: false,
  [MESSENGER_MESSENGER_HOME]: false,
  [INSTAGRAM_STORY]: false,
  [FACEBOOK_STORY]: false,
  [MESSENGER_STORY]: false,
  [INSTAGRAM_REELS]: false,
  [FACEBOOK_INSTREAM_VIDEO]: false,
  [AUDIENCE_NETWORK_INSTREAM_VIDEO]: false,
  [FACEBOOK_SEARCH]: false,
  [MESSENGER_SPONSORED_MESSAGES]: false,
  [FACEBOOK_INSTANT_ARTICLE]: false,
  [AUDIENCE_NETWORK_CLASSIC]: false,
  [AUDIENCE_NETWORK_REWARDED_VIDEO]: false,
  [FACEBOOK_BIZ_DISCO_FEED]: false,
  [FACEBOOK_FACEBOOK_REELS]: false,
  [FACEBOOK_FACEBOOK_REELS_OVERLAY]: false,
  [INSTAGRAM_PROFILE_FEED]: false,
  [INSTAGRAM_IG_SEARCH]: false,
  [INSTAGRAM_EXPLORE_HOME]: false,
  [INSTAGRAM_REELS_OVERLAY]: false
}
export const placementPositionTitles = {
  // facebook
  [FACEBOOK_FEED]: 'Facebook Feed',
  [FACEBOOK_MARKETPLACE]: 'Facebook Marketplace',
  [FACEBOOK_VIDEO_FEEDS]: 'Facebook video feeds',
  [FACEBOOK_RIGHT_HAND_COLUMN]: 'Facebook Right Column',
  [FACEBOOK_SEARCH]: 'Facebook search results',
  [FACEBOOK_STORY]: 'Facebook Stories',
  [FACEBOOK_INSTREAM_VIDEO]: 'Facebook in-stream videos',
  [FACEBOOK_INSTANT_ARTICLE]: 'Facebook Instant Articles',
  [FACEBOOK_BIZ_DISCO_FEED]: 'Facebook Business Explore',
  [FACEBOOK_FACEBOOK_REELS]: 'Facebook Reels',
  [FACEBOOK_FACEBOOK_REELS_OVERLAY]: 'Facebook ads in Reels',
  // instagram
  [INSTAGRAM_STORY]: 'Instagram Stories',
  [INSTAGRAM_STREAM]: 'Instagram Feed',
  [INSTAGRAM_PROFILE_FEED]: 'Instagram profile feed',
  [INSTAGRAM_REELS]: 'Instagram Reels',
  [INSTAGRAM_IG_SEARCH]: 'Instagram search results',
  [INSTAGRAM_EXPLORE]: 'Instagram Explore',
  [INSTAGRAM_EXPLORE_HOME]: 'Instagram Explore home',
  [INSTAGRAM_REELS_OVERLAY]: 'Instagram ads in Reels',
  // messenger
  [MESSENGER_MESSENGER_HOME]: 'Messenger inbox',
  [MESSENGER_SPONSORED_MESSAGES]: 'Messenger sponsored messages',
  [MESSENGER_STORY]: 'Messenger Stories',
  // audience network
  [AUDIENCE_NETWORK_INSTREAM_VIDEO]: 'In-stream videos',
  [AUDIENCE_NETWORK_CLASSIC]: 'Audience Network native, banner and interstitial',
  [AUDIENCE_NETWORK_REWARDED_VIDEO]: 'Audience Network rewarded videos'
}
export const placementSafetyRules = {
  brand_safety_content_filter_levels: ['facebook_strict', 'an_strict'],
  excluded_publisher_categories: [
    'dating',
    'gambling',
    'debated_social_issues',
    'mature_audiences',
    'tragedy_and_conflict'
  ]
}
export const placementsObjectiveTable = {
  [FACEBOOK_FEED]: {
    [OUTCOME_AWARENESS]: true,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: true,
    [OUTCOME_SALES]: true
  },
  [INSTAGRAM_STREAM]: {
    [OUTCOME_AWARENESS]: true,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: true,
    [OUTCOME_SALES]: true
  },
  [INSTAGRAM_PROFILE_FEED]: {
    [OUTCOME_AWARENESS]: true,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: true,
    [OUTCOME_SALES]: true
  },
  [FACEBOOK_MARKETPLACE]: {
    [OUTCOME_AWARENESS]: true,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: true,
    [OUTCOME_SALES]: true
  },
  [FACEBOOK_VIDEO_FEEDS]: {
    [OUTCOME_AWARENESS]: true,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: true,
    [OUTCOME_SALES]: true
  },
  [FACEBOOK_RIGHT_HAND_COLUMN]: {
    [OUTCOME_AWARENESS]: false,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: false,
    [OUTCOME_SALES]: true
  },
  [INSTAGRAM_EXPLORE]: {
    [OUTCOME_AWARENESS]: true,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: true,
    [OUTCOME_SALES]: true
  },
  [INSTAGRAM_EXPLORE_HOME]: {
    [OUTCOME_AWARENESS]: true,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: true,
    [OUTCOME_SALES]: true
  },
  [MESSENGER_MESSENGER_HOME]: {
    [OUTCOME_AWARENESS]: false,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: false,
    [OUTCOME_SALES]: true
  },
  [FACEBOOK_BIZ_DISCO_FEED]: {
    [OUTCOME_AWARENESS]: true,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: false,
    [OUTCOME_SALES]: true
  },
  [INSTAGRAM_STORY]: {
    [OUTCOME_AWARENESS]: true,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: true,
    [OUTCOME_SALES]: true
  },
  [FACEBOOK_STORY]: {
    [OUTCOME_AWARENESS]: true,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: true,
    [OUTCOME_SALES]: true
  },
  [MESSENGER_STORY]: {
    [OUTCOME_AWARENESS]: true,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: false,
    [OUTCOME_SALES]: true
  },
  [INSTAGRAM_REELS]: {
    [OUTCOME_AWARENESS]: true,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: true,
    [OUTCOME_SALES]: true
  },
  [FACEBOOK_FACEBOOK_REELS]: {
    [OUTCOME_AWARENESS]: true,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: true,
    [OUTCOME_SALES]: true
  },
  [FACEBOOK_INSTREAM_VIDEO]: {
    [OUTCOME_AWARENESS]: true,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: true,
    [OUTCOME_SALES]: true
  },
  [INSTAGRAM_REELS_OVERLAY]: {
    [OUTCOME_AWARENESS]: true,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: false,
    [OUTCOME_SALES]: true
  },
  [FACEBOOK_FACEBOOK_REELS_OVERLAY]: {
    [OUTCOME_AWARENESS]: true,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: true,
    [OUTCOME_SALES]: true
  },
  [FACEBOOK_SEARCH]: {
    [OUTCOME_AWARENESS]: true,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: true,
    [OUTCOME_SALES]: true
  },
  [INSTAGRAM_IG_SEARCH]: {
    [OUTCOME_AWARENESS]: true,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: true,
    [OUTCOME_SALES]: true
  },
  [MESSENGER_SPONSORED_MESSAGES]: {
    [OUTCOME_AWARENESS]: false,
    [OUTCOME_TRAFFIC]: false,
    [OUTCOME_ENGAGEMENT]: false,
    [OUTCOME_SALES]: false
  },
  [AUDIENCE_NETWORK_CLASSIC]: {
    [OUTCOME_AWARENESS]: false,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: false,
    [OUTCOME_SALES]: true
  },
  [AUDIENCE_NETWORK_REWARDED_VIDEO]: {
    [OUTCOME_AWARENESS]: false,
    [OUTCOME_TRAFFIC]: true,
    [OUTCOME_ENGAGEMENT]: false,
    [OUTCOME_SALES]: true
  }
}
export const isPlacementActive = (placement, campaignObjective) => {
  return !!placementsObjectiveTable[placement]?.[campaignObjective]
}
export const getFeedsCheckboxOptions = campaignObjective => {
  return [
    {
      name: FACEBOOK_FEED,
      title: placementPositionTitles[FACEBOOK_FEED],
      disabled: !isPlacementActive(FACEBOOK_FEED, campaignObjective)
    },
    {
      name: INSTAGRAM_STREAM,
      title: placementPositionTitles[INSTAGRAM_STREAM],
      disabled: !isPlacementActive(INSTAGRAM_STREAM, campaignObjective)
    },
    {
      name: INSTAGRAM_PROFILE_FEED,
      title: placementPositionTitles[INSTAGRAM_PROFILE_FEED],
      disabled: !isPlacementActive(INSTAGRAM_PROFILE_FEED, campaignObjective)
    },
    {
      name: FACEBOOK_MARKETPLACE,
      title: placementPositionTitles[FACEBOOK_MARKETPLACE],
      disabled: !isPlacementActive(FACEBOOK_MARKETPLACE, campaignObjective)
    },
    {
      name: FACEBOOK_VIDEO_FEEDS,
      title: placementPositionTitles[FACEBOOK_VIDEO_FEEDS],
      disabled: !isPlacementActive(FACEBOOK_VIDEO_FEEDS, campaignObjective)
    },
    {
      name: FACEBOOK_RIGHT_HAND_COLUMN,
      title: placementPositionTitles[FACEBOOK_RIGHT_HAND_COLUMN],
      disabled: !isPlacementActive(FACEBOOK_RIGHT_HAND_COLUMN, campaignObjective)
    },
    {
      name: INSTAGRAM_EXPLORE,
      title: placementPositionTitles[INSTAGRAM_EXPLORE],
      disabled: !isPlacementActive(INSTAGRAM_EXPLORE, campaignObjective)
    },
    {
      name: INSTAGRAM_EXPLORE_HOME,
      title: placementPositionTitles[INSTAGRAM_EXPLORE_HOME],
      disabled: !isPlacementActive(INSTAGRAM_EXPLORE_HOME, campaignObjective)
    },
    {
      name: MESSENGER_MESSENGER_HOME,
      title: placementPositionTitles[MESSENGER_MESSENGER_HOME],
      disabled: !isPlacementActive(MESSENGER_MESSENGER_HOME, campaignObjective)
    },
    {
      name: FACEBOOK_BIZ_DISCO_FEED,
      title: placementPositionTitles[FACEBOOK_BIZ_DISCO_FEED],
      disabled: !isPlacementActive(FACEBOOK_BIZ_DISCO_FEED, campaignObjective)
    }
  ]
}
export const getStoriesAndReelsCheckboxOptions = campaignObjective => {
  return [
    {
      name: INSTAGRAM_STORY,
      title: placementPositionTitles[INSTAGRAM_STORY],
      disabled: !isPlacementActive(INSTAGRAM_STORY, campaignObjective)
    },
    {
      name: FACEBOOK_STORY,
      title: placementPositionTitles[FACEBOOK_STORY],
      disabled: !isPlacementActive(FACEBOOK_STORY, campaignObjective)
    },
    {
      name: MESSENGER_STORY,
      title: placementPositionTitles[MESSENGER_STORY],
      disabled: !isPlacementActive(MESSENGER_STORY, campaignObjective)
    },
    {
      name: INSTAGRAM_REELS,
      title: placementPositionTitles[INSTAGRAM_REELS],
      disabled: !isPlacementActive(INSTAGRAM_REELS, campaignObjective)
    },
    {
      name: FACEBOOK_FACEBOOK_REELS,
      title: placementPositionTitles[FACEBOOK_FACEBOOK_REELS],
      disabled: !isPlacementActive(FACEBOOK_FACEBOOK_REELS, campaignObjective)
    }
  ]
}
export const getInStreamCheckboxOptions = campaignObjective => {
  return [
    {
      name: FACEBOOK_INSTREAM_VIDEO,
      title: placementPositionTitles[FACEBOOK_INSTREAM_VIDEO],
      disabled: !isPlacementActive(FACEBOOK_INSTREAM_VIDEO, campaignObjective)
    },
    {
      name: FACEBOOK_FACEBOOK_REELS_OVERLAY,
      title: placementPositionTitles[FACEBOOK_FACEBOOK_REELS_OVERLAY],
      disabled: !isPlacementActive(FACEBOOK_FACEBOOK_REELS_OVERLAY, campaignObjective)
    }
    // {
    //   // todo SHOP-1744 This one need to be added on BE
    //   name: INSTAGRAM_REELS_OVERLAY,
    //   title: placementPositionTitles[INSTAGRAM_REELS_OVERLAY],
    //   disabled: !isPlacementActive(INSTAGRAM_REELS_OVERLAY, campaignObjective)
    // }
  ]
}
export const getSearchCheckboxOptions = campaignObjective => {
  return [
    {
      name: FACEBOOK_SEARCH,
      title: placementPositionTitles[FACEBOOK_SEARCH],
      disabled: !isPlacementActive(FACEBOOK_SEARCH, campaignObjective)
    },
    {
      name: INSTAGRAM_IG_SEARCH,
      title: placementPositionTitles[INSTAGRAM_IG_SEARCH],
      disabled: !isPlacementActive(INSTAGRAM_IG_SEARCH, campaignObjective)
    }
  ]
}
export const getMessagesCheckboxOptions = campaignObjective => {
  return [
    {
      name: MESSENGER_SPONSORED_MESSAGES,
      title: placementPositionTitles[MESSENGER_SPONSORED_MESSAGES],
      disabled: !isPlacementActive(MESSENGER_SPONSORED_MESSAGES, campaignObjective)
    }
  ]
}
export const getAudienceNetworkCheckboxOptions = campaignObjective => {
  return [
    {
      name: AUDIENCE_NETWORK_CLASSIC,
      title: placementPositionTitles[AUDIENCE_NETWORK_CLASSIC],
      disabled: !isPlacementActive(AUDIENCE_NETWORK_CLASSIC, campaignObjective)
    },
    {
      name: AUDIENCE_NETWORK_REWARDED_VIDEO,
      title: placementPositionTitles[AUDIENCE_NETWORK_REWARDED_VIDEO],
      disabled: !isPlacementActive(AUDIENCE_NETWORK_REWARDED_VIDEO, campaignObjective)
    }
  ]
}
export const getPlacementsList = campaignObjective => {
  return [
    ...getFeedsCheckboxOptions(campaignObjective),
    ...getStoriesAndReelsCheckboxOptions(campaignObjective),
    ...getInStreamCheckboxOptions(campaignObjective),
    ...getSearchCheckboxOptions(campaignObjective),
    ...getMessagesCheckboxOptions(campaignObjective),
    ...getAudienceNetworkCheckboxOptions(campaignObjective)
  ]
}
export const getActivePlacementsInitialValues = campaignObjective => {
  const allPlacementOptions = getPlacementsList(campaignObjective)

  return allPlacementOptions.reduce((acc, item) => {
    acc[item.name] = !item.disabled
    return acc
  }, {})
}
