import * as Yup from 'yup'
import {
  SHOW_COUNTRIES,
  SHOW_REGIONS,
  LOCATIONS_SELECTED_TAB,
  LOCATIONS_TAB,
  TARGET_BY_REGION
} from '../../../../ReusableFormFields/GeolocationsFields/fields'
import { LOCATION_LIST } from '../../../../ReusableFormFields/GeolocationsFields/fields'
import { locationValidation } from '../../../../ReusableFormFields/GeolocationsFields/LocationsRedesign/validation'

// STATUS
export const STATUS = 'status'

export const getInitialValues = userCountryOption => ({
  [LOCATIONS_SELECTED_TAB]: LOCATIONS_TAB,
  [SHOW_COUNTRIES]: userCountryOption?.id ? [userCountryOption] : [],
  [TARGET_BY_REGION]: false,
  [SHOW_REGIONS]: [],
  [LOCATION_LIST]: ''
})

export const validationSchema = Yup.object({
  ...locationValidation
})

const formatLocationsTab = (values, campaignId) => {
  const regions = values[SHOW_REGIONS]

  return {
    operations: [
      // locations
      ...(values[TARGET_BY_REGION]
        ? regions.map(({ resource_name }) => ({
            campaign: campaignId,
            location: {
              geo_target_constant: resource_name
            }
          }))
        : // countries
          values[SHOW_COUNTRIES].map(({ resource_name }) => ({
            location: {
              geo_target_constant: resource_name
            },
            campaign: campaignId
          })))
    ]
  }
}

const formatLocationsListTab = values => {
  return {
    // location list
    [LOCATION_LIST]: values[LOCATION_LIST]
  }
}

export const transformLocationsToBE = (values, campaignId) => {
  return values[LOCATIONS_SELECTED_TAB] === LOCATIONS_TAB
    ? formatLocationsTab(values, campaignId)
    : formatLocationsListTab(values)
}
