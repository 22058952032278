import { createStyles } from '../../../../../../../../../../../styles/helpers'

const useStyles = createStyles({
  productVariableName: {
    fontSize: 12,
    fontWeight: 600
  }
})

export default useStyles
