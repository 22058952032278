import { createStyles } from '../../../../styles/helpers'

import { grey } from '../../../../styles/const/colors'
import { zIndex2 } from '../../../../styles/const/common'

const timelineBasis = props => {
  if (props) {
    const { itemsCount } = props
    switch (itemsCount) {
      case 1:
        return '100%'
      case 2:
        return '50%'
      case 3:
        return '33.3%'
      default:
        return '100%'
    }
  }
}

export default createStyles({
  adStoriesCard: {
    position: 'relative',
    display: 'flex',
    maxWidth: 195,
    minHeight: 345,
    height: 345,
    border: `1px solid ${grey}`,
    margin: '0 auto',
    overflow: 'hidden',
    marginBottom: 30,
    wordWrap: 'break-word',
    boxSizing: 'content-box',
    fontFamily: 'Roboto, sans-serif'
  },
  adStoriesCardHeader: {
    width: '100%',
    height: 35,
    zIndex: zIndex2,
    padding: 6,
    paddingTop: 8,
    background: 'linear-gradient(180deg, rgba(85, 85, 85, 0.28) 0%, rgba(196, 196, 140, 0) 100%)'
  },
  storyTimeline: {
    position: 'absolute',
    width: 'calc(100% - 6px)',
    left: 3,
    top: 3,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  timeline: {
    flexBasis: props => timelineBasis(props),
    maxHeight: 1,
    height: 1,
    margin: '0 3px 5px 0',
    backgroundColor: 'white',

    '&:last-child': {
      marginRight: 0
    }
  },
  headerTop: {
    display: 'flex',
    marginBottom: 8
  },
  avatar: {
    width: 22,
    height: 22,
    borderRadius: '50%',
    backgroundColor: 'white',
    marginRight: 8,
    overflow: 'hidden',

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  name: {
    fontSize: 8,
    fontWeight: 700,
    fontStyle: 'normal',
    letterSpacing: 'normal',
    minWidth: 55,
    color: '#fff'
  },
  description: {
    fontSize: 6,
    color: 'white'
  },
  dotsButton: {
    position: 'absolute',
    top: 6,
    right: 4,
    '& svg': {
      height: 14,
      '& circle': {
        fill: '#fff'
      }
    }
  }
})
