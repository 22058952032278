import { v4 as uuidv4 } from 'uuid'

import { IMAGE_URLS, LINK, NAME, THUMBNAIL_URL } from '../../fields'
import { getInitialTextList } from '../../../../../../forms/ReusableFormFields/MultipleOptionsField/helpers'

export const getInitialValues = page => {
  return {
    [NAME]: page?.[NAME] || '',
    [LINK]: page?.[LINK] || '',
    [THUMBNAIL_URL]: page?.[THUMBNAIL_URL] || '',
    [IMAGE_URLS]: page?.[IMAGE_URLS].map(imageUrl => ({ text: imageUrl, id: uuidv4() })) || getInitialTextList(1, '')
  }
}
