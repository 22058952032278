import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import SkeletonSummaryReport from './Skeleton'
import SummaryReportContent from './SummaryReportContent'

import useStyles from './styles'

function SummaryReport({ pageTitle, getReports, clearReportsAction, reportsSelector, reportsLoadingSelector }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { graph_data: graphData, reports_data: reportsData } = useSelector(reportsSelector)
  const isLoading = useSelector(reportsLoadingSelector)

  useEffect(() => {
    getReports()

    return () => {
      dispatch(clearReportsAction())
    }
  }, [dispatch, getReports, clearReportsAction])

  return (
    <div className={classes.content}>
      {!!graphData.length && <h2 className={classes.title}>{pageTitle}</h2>}
      {isLoading ? (
        <>
          <SkeletonSummaryReport />
          <SkeletonSummaryReport />
        </>
      ) : (
        <SummaryReportContent graphData={graphData} reportsData={reportsData} />
      )}
    </div>
  )
}

export default SummaryReport

SummaryReport.propTypes = {
  pageTitle: PropTypes.string,
  getReports: PropTypes.func,
  clearReportsAction: PropTypes.func,
  reportsSelector: PropTypes.func,
  reportsLoadingSelector: PropTypes.func
}
