import { createStyles } from '../../../../../../../styles/helpers'

import { discountedStyle } from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/MediaOrderPrice/styles'

const useStyles = createStyles({
  totalWithFeesWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  discountedTotalWithFees: {
    ...discountedStyle
  },
  totalWithFees: {
    fontWeight: 600
  }
})

export default useStyles
