import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from 'react-jss'

import { selectedControllerCurrencySymbolSelector } from '../../../../../../../modules/selectors/app'

import getReusableGraphOptions from '../../../../../../../features/components/Graphs/reusableGraphOptions'
import { formatNumberToShort } from '../../../../../../../helpers/numbers'
import { useGraph } from '../../../../../../../features/components/Graphs/hooks'

export const useRevenueGraphOptions = () => {
  useGraph()
  const theme = useTheme()

  const controllerCurrencySymbol = useSelector(selectedControllerCurrencySymbolSelector)

  return useMemo(() => {
    const reusableOptions = getReusableGraphOptions({
      format: 'currency',
      currency: controllerCurrencySymbol,
      theme,
      autoSkipPadding: 10
    })
    return {
      ...reusableOptions,
      scales: {
        ...reusableOptions.scales,
        x: {
          ...reusableOptions.scales.x,
          border: {
            display: false
          }
        },
        y: {
          ...reusableOptions.scales.y,
          border: {
            display: false
          },
          grid: {
            display: false
          },
          ticks: {
            ...reusableOptions.scales.y.ticks,
            callback: function (value) {
              if (value === 0) {
                return 0
              } else {
                return `${controllerCurrencySymbol}${formatNumberToShort(value)}`
              }
            }
          }
        }
      }
    }
  }, [theme, controllerCurrencySymbol])
}
