import { createStyles } from '../../../../../styles/helpers'

export default createStyles({
  selectAll: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '10px',
    textDecoration: 'underline',
    alignItems: 'center',
    paddingTop: '5px',
    paddingBottom: '7px'
  }
})
