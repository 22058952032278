import { all, call, put, takeEvery, select } from 'redux-saga/effects'

import {
  CREATE_AGENCY,
  createAgencyFailure,
  createAgencySuccess,
  GET_AGENCIES,
  GET_AGENCY,
  getAgenciesFailure,
  getAgenciesSuccess,
  getAgencyFailure,
  getAgencySuccess,
  UPDATE_AGENCY,
  updateAgencyFailure,
  updateAgencySuccess
} from '../actions/agencies'
import { createAgencyService, getAgenciesService, getAgencyService, updateAgencyService } from '../services/agencies'
import { updateCurrentUserProfileAgencies } from '../actions/app'
import { userProfileAgenciesSelector } from '../selectors/app'
import { updateItem } from '../../helpers/modules/reducerHelpers'

function* agenciesWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_AGENCY, getAgencyWorker),
    takeEvery(GET_AGENCIES, getAgenciesWorker),
    takeEvery(CREATE_AGENCY, createAgencyWorker),
    takeEvery(UPDATE_AGENCY, updateAgencyWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getAgenciesWorker({ params }) {
  try {
    const response = yield call(getAgenciesService, params)
    yield put(getAgenciesSuccess(response))
  } catch (error) {
    yield put(getAgenciesFailure(error))
  }
}

function* createAgencyWorker({ agencyData }) {
  try {
    const response = yield call(createAgencyService, agencyData)
    yield put(createAgencySuccess(response))

    const userProfileAgencies = yield select(userProfileAgenciesSelector)
    // Also need to update the current user profile agencies
    const updatedUserProfileAgencies = [...userProfileAgencies, response]
    yield put(updateCurrentUserProfileAgencies(updatedUserProfileAgencies))
  } catch (e) {
    yield put(createAgencyFailure(e))
  }
}

function* updateAgencyWorker({ data, id }) {
  try {
    const response = yield call(updateAgencyService, data, id)
    yield put(updateAgencySuccess(response))

    const userProfileAgencies = yield select(userProfileAgenciesSelector)
    // Also need to update the current user profile agencies
    const updatedAgency = {
      // make sure to pass data (not response), as response may contain null values for keys missing in data
      ...data,
      id
    }
    const updatedUserProfileAgencies = updateItem(userProfileAgencies, updatedAgency)
    yield put(updateCurrentUserProfileAgencies(updatedUserProfileAgencies))
  } catch (error) {
    yield put(updateAgencyFailure(error))
  }
}

function* getAgencyWorker({ params }) {
  try {
    const response = yield call(getAgencyService, params)
    yield put(getAgencySuccess(response))
  } catch (error) {
    yield put(getAgencyFailure(error))
  }
}

export default agenciesWatcher
