import React from 'react'
import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'
import useDrawerFormStyles from '../../../styles/common/drawerForms'

function DrawerHeadlineSkeleton({ hasTitle, hasDescription }) {
  const classes = useStyles()
  const drawerFormClasses = useDrawerFormStyles()

  return (
    <div className={classes.drawerHeadlineContainer}>
      {hasTitle && (
        <h2 className={drawerFormClasses.formTitle}>
          <Skeleton width="60%" count={1} />
        </h2>
      )}
      {hasDescription && (
        <p className={drawerFormClasses.formDescription}>
          <Skeleton width="90%" count={2} />
        </p>
      )}
    </div>
  )
}

export default DrawerHeadlineSkeleton
