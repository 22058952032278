import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import MediaSkeleton from './MediaSkeleton'
import MediaThumbnail from '../../../features/components/MediaThumbnail'
import ItemsLoading from '../../../components/Loaders/ItemsLoading'
import FileDetailsPreview from '../../../components/FileDetailsPreview'

import useMediaFilesLibrary from './hooks'

import useStyles from './styles'

const MediaFilesLibrary = ({
  videoPicture,
  values,
  type,
  mediaThumbnailFieldName,
  mediaType,
  mediaFilesSelector,
  mediaFilesIsLoadingSelector,
  mediaFilesErrorSelector,
  mediaFilesWasLoadedSelector,
  mediaFilesNextSelector,
  loadInitialMediaHandler,
  loadMoreHandler,
  clearMediaHandler,
  onMediaSelect,
  onFileRemove,
  fileNameKey,
  fileURLKey,
  uploadedMediaTypeKey,
  initialLoadingSkeletonsNumber = 12,
  className,
  mediaThumbnailClassName,
  loadMoreButtonClassName,
  loadMoreButtonText
}) => {
  const classes = useStyles()

  const fileUrl = getIn(values, fileURLKey)
  const fileName = getIn(values, fileNameKey)
  const uploadedMediaType = getIn(values, uploadedMediaTypeKey)

  const mediaFiles = useSelector(mediaFilesSelector)
  const isNext = useSelector(mediaFilesNextSelector)

  const showPreview = !!fileUrl && mediaType === uploadedMediaType

  const { handleMediaClick, handleRemoveFile } = useMediaFilesLibrary({
    onFileRemove,
    onMediaSelect,
    clearMediaHandler,
    loadInitialMediaHandler,
    type
  })

  const skeletonProps = useMemo(
    () => ({
      className: mediaThumbnailClassName
    }),
    [mediaThumbnailClassName]
  )

  if (showPreview) {
    // For Facebook platform we show videoPicture instead of video
    const previewUrl = videoPicture ? videoPicture : fileUrl
    const filePreviewType = videoPicture ? 'image' : type

    return (
      <FileDetailsPreview
        fileName={fileName}
        previewUrl={previewUrl}
        filePreviewType={filePreviewType}
        removeFile={handleRemoveFile}
        allowDelete={!!fileUrl}
      />
    )
  }

  return (
    <ItemsLoading
      className={classnames(classes.library, className)}
      infoBlockClassName={classes.infoBlockLibrary}
      SkeletonComponent={MediaSkeleton}
      isLoadingSelector={mediaFilesIsLoadingSelector}
      wasLoadedSelector={mediaFilesWasLoadedSelector}
      errorSelector={mediaFilesErrorSelector}
      itemsLength={mediaFiles.length}
      initialLoadingSkeletonsNumber={initialLoadingSkeletonsNumber}
      loadMore={isNext ? loadMoreHandler : null}
      loadMoreButtonClassName={loadMoreButtonClassName}
      skeletonProps={skeletonProps}
      loadMoreButtonText={loadMoreButtonText}
    >
      {mediaFiles.map(media => (
        <MediaThumbnail
          key={media.id}
          media={media}
          thumbnailUrl={media[mediaThumbnailFieldName]}
          mediaType={type}
          onMediaClick={handleMediaClick}
          className={mediaThumbnailClassName}
        />
      ))}
    </ItemsLoading>
  )
}

MediaFilesLibrary.propTypes = {
  videoPicture: PropTypes.string,
  values: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  mediaThumbnailFieldName: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired,
  mediaFilesSelector: PropTypes.func.isRequired,
  mediaFilesIsLoadingSelector: PropTypes.func.isRequired,
  mediaFilesErrorSelector: PropTypes.func.isRequired,
  mediaFilesWasLoadedSelector: PropTypes.func.isRequired,
  mediaFilesNextSelector: PropTypes.func.isRequired,
  loadInitialMediaHandler: PropTypes.func.isRequired,
  loadMoreHandler: PropTypes.func.isRequired,
  clearMediaHandler: PropTypes.func.isRequired,
  onMediaSelect: PropTypes.func.isRequired,
  onFileRemove: PropTypes.func.isRequired,
  fileNameKey: PropTypes.string.isRequired,
  fileURLKey: PropTypes.string.isRequired,
  uploadedMediaTypeKey: PropTypes.string.isRequired
}

export default MediaFilesLibrary
