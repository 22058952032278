import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useState } from 'react'

import UploadGoogleAdCreativeForm from './UploadGoogleAdCreativeForm'
import Button from '../../../components/Button'
import BrandsMissingWarning from '../ReusableFormFields/BrandsMissingWarning'
import FormDrawerWrapper from '../../../features/components/FormDrawerWrapper'
import SkeletonForm from '../../../components/Form/Skeleton'
import SocialAuthChecker from '../../../features/components/SocialAuthChecker'
import SuccessModal from '../../../features/components/Modals/SuccessModal'

import { useGetAuthFailureText } from '../../Multiplatform/UploadAdCreative/hooks'
import useClearAssetsGroupCreate from '../AdForms/GoogleAssetGroup/GoogleAssetGroupCreate/hook'
import useRedirectFormDrawer from '../../../features/components/FormDrawerWrapper/useRedirectFormDrawer'
import useUploadAdCreativeConstants from '../../Multiplatform/MediaOrderForms/UploadAdCreative/useUploadAdCreativeConstants'

import { checkIfCampaignHasLineItem } from '../CampaignForms/helpers'
import { redirectTo } from '../../../helpers/url'

import { clearAds } from '../../../modules/actions/ads'
import { openForm } from '../../../modules/actions/forms'
import { clearMediaOrderUploadCreative } from '../../../modules/actions/mediaOrders'
import { clearAssetGroups } from '../../../modules/actions/assets'
import { selectedGoogleAdAccountIdSelector, selectedSelfAccountDataSelector } from '../../../modules/selectors/app'

import { ROUTE_PARAMS, ROUTES } from '../../../constants/routes'
import { GOOGLE_PLATFORM } from '../../../constants/selectLists/platformList'
import { MODAL_TYPE } from '../../../constants/other'
import { UPLOAD_GOOGLE_AD_CREATIVE } from '../../../constants/forms'

const initialRedirectionParams = {
  [ROUTE_PARAMS.campaignId]: ''
}

const initialCampaignType = ''

export function UploadGoogleAdCreative() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // modal related state data
  const [redirectionParams, setRedirectionParams] = useState(initialRedirectionParams)
  const [campaignType, setCampaignType] = useState(initialCampaignType)
  const { brands } = useSelector(selectedSelfAccountDataSelector)
  const hasBrands = brands?.length > 0
  const googleAdAccountId = useSelector(selectedGoogleAdAccountIdSelector)

  const { isInternalAdCreation, isFormLoading } = useUploadAdCreativeConstants()
  const authFailureText = useGetAuthFailureText(GOOGLE_PLATFORM)

  const isCampaignWithLineItem = checkIfCampaignHasLineItem(campaignType)

  const clearCreateAssetsGroupHandler = useClearAssetsGroupCreate()
  const handleSuccessRedirect = useRedirectFormDrawer(
    `${ROUTES.mediaOrderSummaryOffNetworkDigital}?message=${MODAL_TYPE.mediaOrderGoogleCreativeUploaded}`
  )

  const onViewAdRedirection = useCallback(() => {
    const campaignHasLineItem = !!redirectionParams[ROUTE_PARAMS.lineItemId]
    const URL = campaignHasLineItem ? ROUTES.lineItemsSummary : ROUTES.campaignsSummary

    redirectTo(URL, {
      ...redirectionParams,
      [ROUTE_PARAMS.platform]: GOOGLE_PLATFORM,
      [ROUTE_PARAMS.adAccount]: googleAdAccountId
    })
  }, [redirectionParams, googleAdAccountId])

  const onModalCloseHandler = useCallback(() => {
    setRedirectionParams(initialRedirectionParams)
    setCampaignType(initialCampaignType)
  }, [])

  const handleSuccessUploadCreative = useCallback(
    (redirectionParams, campaignType) => {
      setRedirectionParams(redirectionParams)
      setCampaignType(campaignType)

      dispatch(clearAds())
      handleSuccessRedirect()
    },
    [dispatch, handleSuccessRedirect]
  )

  const openUploadCreativeFormHandler = useCallback(() => {
    dispatch(openForm({ formName: UPLOAD_GOOGLE_AD_CREATIVE }))
  }, [dispatch])

  const onAfterFormCloseHandler = useCallback(() => {
    dispatch(clearMediaOrderUploadCreative())

    clearCreateAssetsGroupHandler()
    dispatch(clearAssetGroups())
  }, [dispatch, clearCreateAssetsGroupHandler])

  return (
    <>
      <FormDrawerWrapper
        formName={UPLOAD_GOOGLE_AD_CREATIVE}
        closeOnSubmit={false}
        showOpenButton={false}
        onAfterClose={onAfterFormCloseHandler}
        isFormLoading={isFormLoading}
        SkeletonFormComponent={<SkeletonForm stepsLength={2} />}
        hasDefaultDrawerContentSpacing={false}
        isWideDrawer
      >
        <SocialAuthChecker platform={GOOGLE_PLATFORM} authFailureText={authFailureText}>
          {hasBrands ? (
            <UploadGoogleAdCreativeForm onSuccessSubmit={handleSuccessUploadCreative} />
          ) : (
            <BrandsMissingWarning hasDrawerPadding />
          )}
        </SocialAuthChecker>
      </FormDrawerWrapper>
      <SuccessModal
        successButtonText="Upload another Google ad"
        successButtonAction={openUploadCreativeFormHandler}
        modalType={MODAL_TYPE.mediaOrderGoogleCreativeUploaded}
        ButtonLeft={<Button onClick={onViewAdRedirection}>{t('View Ad')}</Button>}
        onAfterClose={onModalCloseHandler}
      >
        <p>
          {t(
            isInternalAdCreation
              ? `Your ${
                  isCampaignWithLineItem ? 'ad' : 'asset group'
                } has been uploaded successfully and is pending review.`
              : `Your ${isCampaignWithLineItem ? 'ad' : 'asset group'} has been uploaded successfully.`
          )}
        </p>
      </SuccessModal>
    </>
  )
}
