import { useSelector } from 'react-redux'

import { activeStepSelector } from '../../../../../../modules/selectors/forms'
import { AUDIENCE_FORM, MAIN_FORM } from './index'

export function useFormProgressItems({ activatedForm }) {
  const activeStep = useSelector(activeStepSelector)

  return [
    {
      label: 'Select product',
      isActive: activatedForm === MAIN_FORM && activeStep === 0,
      isSuccess: (activatedForm === MAIN_FORM && activeStep === 1) || activatedForm === AUDIENCE_FORM
    },
    {
      label: 'Review ad creative',
      isActive: activatedForm === MAIN_FORM && activeStep === 1,
      isSuccess: activatedForm === AUDIENCE_FORM
    },
    {
      label: 'Select audience',
      isActive: activatedForm === AUDIENCE_FORM,
      isSuccess: false
    }
  ]
}
