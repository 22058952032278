import { googleAssetFieldType } from '../../../../constants/ads'
import { youtubeVideoIdUrlParams } from '../fields'

export const getYoutubeVideoId = url => {
  // The video ID is located in the URL of the video page, right after the v= URL parameter.
  // And consists of 11 characters

  let videoId = ''

  if (url.includes('youtu.be/')) {
    videoId = url.split('youtu.be/')[1].slice(0, 11)
  } else {
    videoId = url.split(youtubeVideoIdUrlParams)[1].slice(0, 11)
  }

  return videoId
}

export const isTextAssetType = type => {
  const textAssets = [
    googleAssetFieldType.HEADLINE,
    googleAssetFieldType.DESCRIPTION,
    googleAssetFieldType.LONG_HEADLINE,
    googleAssetFieldType.BUSINESS_NAME,
    googleAssetFieldType.YOUTUBE_VIDEO
  ]

  return !!textAssets.find(textAssetType => textAssetType === type)
}
