import * as Yup from 'yup'
import {
  DATE_END,
  DATE_START,
  MEDIA_CATEGORIES,
  MEDIA_PRODUCTS,
  mediaCategoriesValidation,
  mediaProductsValidation,
  reusableValidation
} from '../../fields'
import { endDateValidation, startDateValidation } from '../../../../ReusableFormFields/StartEndDateRangeFields/fields'

export const getValidationSchema = ({ isPackageWithDates, isPackageWithMediaCategories }) =>
  Yup.object({
    ...reusableValidation,
    ...(isPackageWithDates && {
      [DATE_START]: startDateValidation,
      [DATE_END]: endDateValidation
    }),
    ...(isPackageWithMediaCategories
      ? { [MEDIA_CATEGORIES]: mediaCategoriesValidation }
      : { [MEDIA_PRODUCTS]: mediaProductsValidation })
  })
