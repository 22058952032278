import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Field from '../../../../../../../components/Form/Field'

import { getDecimalDiscount } from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/price'
import { getCustomProductPrice } from '../helpers/getProductValue'
import { formatCurrency } from '../../../../../../../helpers/numbers'

import { CUSTOM_PRODUCT_PRICE, MEDIA_PRODUCTS, CUSTOM_PRODUCT_DISCOUNT } from '../../../fields'

import useStyles from './styles'

const CostCell = ({ formik, productValues, productIndex, isEditMode, currencySymbol }) => {
  const classes = useStyles()
  const { setFieldValue } = formik

  const isProductSelected = !!productValues.data
  const productPath = `${MEDIA_PRODUCTS}[${productIndex}]`
  const customPriceProductPath = `${productPath}.${CUSTOM_PRODUCT_PRICE}`

  const customPrice = productValues[CUSTOM_PRODUCT_PRICE]
  const discount = getDecimalDiscount(productValues[CUSTOM_PRODUCT_DISCOUNT])
  // product price is already calculated with correct quantity, so for discount calculation we need to use quantity 1
  const calculatedPrice = getCustomProductPrice(productValues)

  const handleCostChange = useCallback(
    (value, customPriceProductPath) => {
      // cost and discount should be changed immediately and not on blur
      // to avoid saving on blur, and loose save data progress when user press save button
      setFieldValue(customPriceProductPath, value || 0) // set 0 instead of '' to avoid NaN or crashing calc functions
    },
    [setFieldValue]
  )

  if (!isProductSelected) {
    return null
  }

  if (discount) {
    // when there is discount set, forbid to edit the price, and calculate instead
    return (
      <div className={classes.costPrice}>
        {formatCurrency(calculatedPrice, { min: 2, max: 2 }, { symbol: currencySymbol })}
      </div>
    )
  }

  return isEditMode ? (
    <Field
      type="number"
      inputMode="decimal"
      formik={formik}
      name={customPriceProductPath}
      placeholder="Cost"
      autoComplete="off"
      fixedDecimalScale={true}
      decimalScale={2}
      enableReinitialize
      onValueChange={handleCostChange}
      triggerValueSet={customPrice}
      inputClassName={classes.costField}
    />
  ) : (
    <div className={classes.costPrice}>
      {formatCurrency(calculatedPrice, { min: 2, max: 2 }, { symbol: currencySymbol })}
    </div>
  )
}

CostCell.propTypes = {
  formik: PropTypes.object,
  productValues: PropTypes.object,
  productIndex: PropTypes.number,
  currencySymbol: PropTypes.string,
  isEditMode: PropTypes.bool
}

export default CostCell
