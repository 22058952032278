import * as Yup from 'yup'
import {
  SCHEDULE_DATES,
  SCHEDULE_OPTION,
  SCHEDULE_OPTION_BASED_ON_WEATHER,
  SCHEDULE_OPTION_SPECIFIC_TIMES,
  SCHEDULE_MONDAY,
  SCHEDULE_TUESDAY,
  SCHEDULE_WEDNESDAY,
  SCHEDULE_THURSDAY,
  SCHEDULE_FRIDAY,
  SCHEDULE_SATURDAY,
  SCHEDULE_SUNDAY,
  SCHEDULE_OPTION_SPECIFIC_DAYS
} from '../../forms/ReusableFormFields/ScheduleFields/fields'
import { FORECAST_RANGE, LOCATION, TEMPERATURE } from '../../forms/ReusableFormFields/TriggerFields/fields'

export const scheduleTimesValidation = Yup.array().min(1, 'Please select at least one time slot')

export const scheduleValidation = {
  [SCHEDULE_OPTION]: Yup.string().test({
    message: 'Please select at least one day',
    test: (value, { parent }) => {
      const daysCollection = [
        parent[SCHEDULE_MONDAY],
        parent[SCHEDULE_TUESDAY],
        parent[SCHEDULE_WEDNESDAY],
        parent[SCHEDULE_THURSDAY],
        parent[SCHEDULE_FRIDAY],
        parent[SCHEDULE_SATURDAY],
        parent[SCHEDULE_SUNDAY]
      ]

      // check that at least one day is select
      return !(value === SCHEDULE_OPTION_SPECIFIC_DAYS && !daysCollection.includes(true))
    }
  }),
  [SCHEDULE_DATES]: Yup.array().when(SCHEDULE_OPTION, {
    is: SCHEDULE_OPTION_SPECIFIC_TIMES,
    then: () => scheduleTimesValidation
  }),
  [LOCATION]: Yup.string().when(SCHEDULE_OPTION, {
    is: SCHEDULE_OPTION_BASED_ON_WEATHER,
    then: () => Yup.string().required('This field can not be empty')
  }),
  [TEMPERATURE]: Yup.string().when(SCHEDULE_OPTION, {
    is: SCHEDULE_OPTION_BASED_ON_WEATHER,
    then: () => Yup.string().required('This field can not be empty')
  }),
  [FORECAST_RANGE]: Yup.string().when(SCHEDULE_OPTION, {
    is: SCHEDULE_OPTION_BASED_ON_WEATHER,
    then: () => Yup.string().required('This field can not be empty')
  })
}
