import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import ProductsTableData from './ProductsTableData'
import TableDataLoader from '../../../../components/Table/TableDataLoader'

import {
  productsErrorSelector,
  productsIsLoadingSelector,
  productsWasLoadedSelector
} from '../../../../modules/selectors/mediaOrdersProducts'

import { phonesDownSize } from '../../../../styles/const/breakpoints'

import useCommonStyles from '../../../../styles/common/table'

export const tableColumnsSize = {
  dragBtn: 40,
  statusToggle: 70,
  thumbnail: 64,
  category: 150,
  price: 120,
  actions: 30
}

const spaceForMargin = 5

const mobileColumnsSchema = [
  { style: { maxWidth: `calc(${tableColumnsSize.dragBtn}px - ${spaceForMargin}px)`, marginInlineEnd: spaceForMargin } },
  {
    style: { maxWidth: `calc(${tableColumnsSize.thumbnail}px - ${spaceForMargin}px)`, marginInlineEnd: spaceForMargin }
  },
  { style: { maxWidth: '100%' } }
]

const ProductsTable = ({ products, loadMoreHandler }) => {
  const { t } = useTranslation()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const commonClasses = useCommonStyles()
  const productsColumnsSchema = useMemo(
    () => [
      {
        style: { maxWidth: tableColumnsSize.thumbnail }
      },
      {
        style: { maxWidth: tableColumnsSize.statusToggle }
      },
      {
        header: 'Product Name',
        fieldKey: 'name'
      },
      {
        header: 'Category',
        style: { maxWidth: tableColumnsSize.category }
      },
      {
        header: 'Price',
        style: { maxWidth: tableColumnsSize.price }
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no media products')}</div>
        <br />
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <TableDataLoader
      loadMore={loadMoreHandler}
      itemsLength={products.length}
      errorSelector={productsErrorSelector}
      wasLoadedSelector={productsWasLoadedSelector}
      isLoadingSelector={productsIsLoadingSelector}
      skeletonProps={{ cols: isMobile ? mobileColumnsSchema : productsColumnsSchema }}
      noDataContent={noDataContent}
    >
      <ProductsTableData products={products} />
    </TableDataLoader>
  )
}

ProductsTable.propTypes = {
  products: PropTypes.array.isRequired
}

export default ProductsTable
