import { useCallback, useState } from 'react'
import { findNestedObj } from '../../helpers/arrays'

const collectParentIdsFromOption = (options, option) => {
  const { id, parent: parentId } = option

  const defaultIdToInclude = parentId ? parentId : id

  // firstly set parentId as initialValue
  let parentIdsToShow = [defaultIdToInclude]

  // then check if parent has its own parent
  const parentOption = parentId && findNestedObj(options, 'id', parentId)

  const parentOptionParentId = parentOption && parentOption.parent

  if (parentOptionParentId) {
    const parentOptionParentData = findNestedObj(options, 'id', parentOptionParentId)

    const aboveParentIdsToShow = parentOptionParentData && collectParentIdsFromOption(options, parentOptionParentData)

    if (aboveParentIdsToShow && aboveParentIdsToShow.length) {
      parentIdsToShow = [...parentIdsToShow, ...aboveParentIdsToShow]
    }
  }

  return parentIdsToShow
}

// check option name if it includes searchText and recursively check children options
// if option or children has match, include it in matched search result with parents
const executeSearchOnOption = (searchText = '', options, option = {}) => {
  let idsToShow = []

  const { name = '', id, options: childrenOptions, parent } = option

  // check provided option
  if (name.toLowerCase().includes(searchText.toLowerCase())) {
    const parentIdsToShow = parent && collectParentIdsFromOption(options, option)

    if (parentIdsToShow && parentIdsToShow.length) {
      idsToShow = parentIdsToShow
    }

    idsToShow.push(id)
  }

  if (childrenOptions && childrenOptions.length) {
    childrenOptions.forEach(childrenOption => {
      const childrenIdsToShow = executeSearchOnOption(searchText, options, childrenOption)

      if (childrenIdsToShow.length) {
        idsToShow = [...idsToShow, ...childrenIdsToShow]
      }
    })
  }

  return idsToShow
}

function useMultiLevelSelectSearch({ isDataInitiallyFormatted, options, optionsState, setOptionsState }) {
  const [isSearchApplied, setIsSearchApplied] = useState(false)
  const [searchOptions, setSearchOptions] = useState([])

  const handleSearchChange = useCallback(
    searchText => {
      // perform search only when initial data formatting was finished
      if (isDataInitiallyFormatted) {
        if (searchText) {
          // ids of all options with their parents to show after search
          let optionsIdsToShow = []

          options.forEach(option => {
            const idsToShow = executeSearchOnOption(searchText, options, option)

            if (idsToShow.length) {
              optionsIdsToShow = [...optionsIdsToShow, ...idsToShow]
            }
          })

          setSearchOptions([...new Set(optionsIdsToShow)])

          // set isExpanded=true for all parents of matched options
          const newOptionsState = { ...optionsState }

          Object.keys(newOptionsState).forEach(optionId => {
            newOptionsState[optionId].isExpanded = optionsIdsToShow.includes(optionId)
          })

          setOptionsState(newOptionsState)

          setIsSearchApplied(true)
        } else {
          // reset state to initial and set all options as not expanded
          setIsSearchApplied(false)
          setSearchOptions([])

          const newOptionsState = { ...optionsState }

          for (const key in newOptionsState) {
            newOptionsState[key].isExpanded = false
          }

          setOptionsState(newOptionsState)
        }
      }
    },
    [isDataInitiallyFormatted, options, optionsState, setOptionsState]
  )

  return { isSearchApplied, searchOptions, handleSearchChange }
}

export default useMultiLevelSelectSearch
