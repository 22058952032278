import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'

import { DiscountsProvider } from './DiscountsContext'
import OrderCartStep from './Steps/OrderCartStep'
import StepForm from '../../../../../../features/components/Forms/StepForm'

import { useRequiredFields } from './hooks/useRequiredFields'
import useFormProgressItems from './hooks/useFormProgressItems'
import { useMediaOrderStepsGenerate } from './hooks/useStepGenerate'
import { useGetSelectedCurrency } from './hooks/useGetSelectedCurrency'
import { useHandleCreateContractClose } from './hooks/useHandleCreateContractClose'
import { useHandleContractGeneration } from './hooks/useHandleContractGeneration'
import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'

import { determineDiscountsFromProducts } from './DiscountsContext/helpers'

import { mediaOrderDataSelector, selectedMediaPackagesSelector } from '../../../../../../modules/selectors/mediaOrders'

import { getInitialPackages, getInitialValues, SELECTED_PACKAGES } from './fields'
import { getValidationSchema } from './validation'
import { getAllPackageProducts } from './helpers/products'

import { MEDIA_ORDER_CONTRACT_CREATE } from '../../../../../../constants/forms'

import useStyles from './styles'
import { MediaOrderFormContext } from '../../../MediaOrderFormContext'

function ContractCreateForm({ onSuccessSubmit }) {
  const classes = useStyles()

  const savedMediaOrderData = useSelector(mediaOrderDataSelector)
  const selectedMediaPackages = useSelector(selectedMediaPackagesSelector)

  const { contextSelfAccountData } = useContext(MediaOrderFormContext)

  const selectedCurrency = useGetSelectedCurrency()
  const { handleSubmit, successSubmit, clearSubmitHandler, errorSelector, isLoadingSelector } =
    useHandleContractGeneration({ currency: selectedCurrency })
  const { askBrandName, askCampaignName, isBrandRequired } = useRequiredFields(contextSelfAccountData)

  const onSubmit = useCallback(
    values => {
      // get all products categories from each package:
      const allSelectedProducts = values[SELECTED_PACKAGES].reduce((acc, packageData) => {
        const products = getAllPackageProducts(packageData)
        return [...acc, ...products]
      }, [])
      const productsDiscount = determineDiscountsFromProducts(allSelectedProducts)
      handleSubmit(values, productsDiscount)
    },
    [handleSubmit]
  )

  const initialValues = useMemo(() => {
    // either use saved data, or generate initial values for packages and general initial values
    return savedMediaOrderData || getInitialValues(getInitialPackages(selectedMediaPackages, selectedCurrency))
  }, [savedMediaOrderData, selectedMediaPackages, selectedCurrency])

  const validationSchema = useMemo(() => {
    return getValidationSchema({ askBrandName, askCampaignName, isBrandRequired })
  }, [askBrandName, askCampaignName, isBrandRequired])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)
  const { setFieldValue, values } = purifiedFormik

  // each media_category has its own steps
  // each selectedMediaPackage has different combinations of media_categories
  const productsSteps = useMediaOrderStepsGenerate({
    selectedCurrency,
    selectedPackages: values[SELECTED_PACKAGES]
  })

  const steps = useMemo(
    () => [
      ...productsSteps,
      {
        component: <OrderCartStep currency={selectedCurrency} />,
        show: true,
        key: 'OrderCartStep'
      }
    ],
    [productsSteps, selectedCurrency]
  )

  const { progressItems } = useFormProgressItems({ steps })
  // save data when form is unmounted
  useHandleCreateContractClose(values, successSubmit)

  const selectedPackagesValues = useMemo(() => {
    return values[SELECTED_PACKAGES]
  }, [values])

  useEffect(() => {
    // handle new packages adding to formik values
    if (selectedMediaPackages.length !== selectedPackagesValues.length) {
      // find package that wasn't added to values
      const addedPackage = selectedMediaPackages.find(
        ({ id }) => !selectedPackagesValues.find(existedPackage => existedPackage.id === id)
      )

      // transform and push added package to values
      if (addedPackage) {
        const newPackage = getInitialPackages([addedPackage], selectedCurrency)[0]

        setFieldValue(SELECTED_PACKAGES, [...selectedPackagesValues, newPackage])
      }
    }
  }, [selectedPackagesValues, setFieldValue, selectedCurrency, selectedMediaPackages])

  const allSelectedProducts = useMemo(() => {
    // get all products categories from each package:
    return values[SELECTED_PACKAGES].reduce((acc, packageData) => {
      const products = getAllPackageProducts(packageData)
      return [...acc, ...products]
    }, [])
  }, [values])

  return (
    <DiscountsProvider allSelectedProducts={allSelectedProducts}>
      <StepForm
        formName={MEDIA_ORDER_CONTRACT_CREATE}
        submitText={'Generate contract'}
        steps={steps}
        formik={purifiedFormik}
        customStepsForProgress={progressItems.length}
        className={classes.mediaOrderForm}
        formProgressItems={progressItems}
        // processing
        successSubmit={successSubmit}
        errorSelector={errorSelector}
        isLoadingSelector={isLoadingSelector}
        // after form submit
        onSuccessSubmit={onSuccessSubmit}
        clearSubmitHandler={clearSubmitHandler}
      />
    </DiscountsProvider>
  )
}

export default ContractCreateForm
