import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import MediaCategoryEditForm from './MediaCategoryEditForm'

import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'

import { mediaPackageCategoriesSelector } from '../../../../modules/selectors/mediaPackages'

import { MEDIA_CATEGORY_EDIT } from '../../../../constants/forms'

function MediaCategoryEdit() {
  const categories = useSelector(mediaPackageCategoriesSelector)

  const { selectedEditItemId } = useManageFormsDrawer({
    formName: MEDIA_CATEGORY_EDIT
  })

  const editCategoryData = useMemo(
    () => categories.find(item => item.id === Number(selectedEditItemId)),
    [categories, selectedEditItemId]
  )

  return (
    <FormDrawerWrapper
      formName={MEDIA_CATEGORY_EDIT}
      title="Edit a category"
      showOpenButton={false}
      editCategoryData={editCategoryData}
      isFormLoading={!editCategoryData}
    >
      <MediaCategoryEditForm editCategoryData={editCategoryData} />
    </FormDrawerWrapper>
  )
}

export default MediaCategoryEdit
