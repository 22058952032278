import React, { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'

import StepForm from '../../../../../../../../features/components/Forms/StepForm'
import CampaignDateStep from '../../Steps/CampaignDateStep'
import BidPerClickStep from '../../Steps/BidPerClickStep'
import SelectProductsStep from '../../Steps/SelectProductsStep'

import { showToasts } from '../../../../../../../../helpers/toasts'
import { useMediaOrderRemainingBudgetCalculation } from '../../../../../../../../forms/Multiplatform/MediaOrderForms/UploadAdCreative/useMediaOrderRemainingBudgetCalculation'
import { usePurifiedFormik } from '../../../../../../../../hooks/formHooks/usePurifiedFormik'

import {
  combinedCampaignsErrorSelector,
  combinedCampaignsWasLoadedSelector
} from '../../../../../../../../modules/selectors/combinedData'

import { selectedSelfAccountDataSelector } from '../../../../../../../../modules/selectors/app'

import { ONSITE_DIGITAL_FORMS } from '../../../../../../../../constants/forms'

import { initialValues } from '../../fields'
import { getValidationSchema } from '../../validation'

const FirstRoute = ({ FormRouteSelector, eventSourceStep, ...formProps }) => {
  const onSubmit = useCallback(() => {
    showToasts({
      type: 'error',
      message: 'Please connect the onsite ad server to create a campaign'
    })
  }, [])

  const { currency_symbol: currencySymbol } = useSelector(selectedSelfAccountDataSelector)

  const remainingBudget = useMediaOrderRemainingBudgetCalculation()

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema({ remainingBudget, currencySymbol }),
    onSubmit,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const steps = useMemo(
    () => [
      {
        component: FormRouteSelector,
        show: true
      },
      {
        component: <CampaignDateStep />,
        show: true
      },
      {
        component: <BidPerClickStep />,
        show: true
      },
      {
        component: <SelectProductsStep />,
        show: true
      }
    ],
    [FormRouteSelector]
  )

  // todo add when form will be ready clearSubmitHandler
  const clearCreateItemHandler = useCallback(() => {}, [])

  return (
    <StepForm
      {...formProps}
      errorSelector={combinedCampaignsErrorSelector}
      isLoadingSelector={combinedCampaignsWasLoadedSelector}
      steps={steps}
      formik={purifiedFormik}
      formName={ONSITE_DIGITAL_FORMS}
      clearSubmitHandler={clearCreateItemHandler}
    />
  )
}

export default FirstRoute
