import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import CalendarEventForm from '../CalendarEventForm'
import DeleteCalendarEvent from '../DeleteCalendarEvent'
import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'

import useManageFormsDrawer from '../../../../../hooks/formHooks/useManageFormsDrawer'
import useManageEditFormData from '../../../../../hooks/formHooks/useManageEditFormData'

import { getEvent, clearEventData, updateEvent, clearUpdateEvent } from '../../../../../modules/actions/calendarPlanner'
import {
  calendarEventUpdateIsLoading,
  calendarEventUpdateError,
  calendarEventDataCreatedSelector,
  calendarEventUpdatedSelector,
  calendarEventDataIsLoadingSelector
} from '../../../../../modules/selectors/calendarPlanner'

import { PLANNER_EVENT_EDIT } from '../../../../../constants/forms'

import {
  NAME,
  END_DATE,
  START_DATE,
  ROW,
  IMAGES,
  NEW_IMAGE,
  validationSchema,
  getInitialValues
} from '../CalendarEventForm/fields'
import { formatDateWithTimeToBE } from '../../../../../helpers/date'

// row is the same as event_group
function CalendarEditEvent({ eventGroups }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const editEvent = useSelector(calendarEventUpdatedSelector)
  const editEventData = useSelector(calendarEventDataCreatedSelector)
  const editEventDataLoading = useSelector(calendarEventDataIsLoadingSelector)
  const originalEventGroupId = editEventData && editEventData[ROW]

  const getEventDataHandler = useCallback(
    selectedEditItemId => {
      dispatch(getEvent(selectedEditItemId))
    },
    [dispatch]
  )

  const clearEventHandler = useCallback(() => {
    dispatch(clearEventData())
  }, [dispatch])

  const { selectedEditItemId } = useManageFormsDrawer({
    formName: PLANNER_EVENT_EDIT
  })
  useManageEditFormData({
    formName: PLANNER_EVENT_EDIT,
    loadedDataId: editEvent.id,
    getDataHandler: getEventDataHandler,
    clearDataHandler: clearEventHandler
  })

  const clearEditEventSubmit = useCallback(() => dispatch(clearUpdateEvent()), [dispatch])

  const onSubmit = useCallback(
    ({ [IMAGES]: images, ...values }) => {
      // new image files which selected to upload are missing 'id' field
      const newImages = images.filter(image => !image.id)

      // on update we send and array of the images we would like to keep for this event.
      // i.e. to delete image from event - just delete object completely from [IMAGES] array.
      // BE will check ids for array and if existed ids(on GET) will be missing then it treats that images were deleted.
      const existedImages = images.filter(image => !!image.id)

      dispatch(
        updateEvent(
          {
            id: selectedEditItemId,
            ...values,
            [NEW_IMAGE]: newImages,
            [IMAGES]: JSON.stringify(existedImages),
            [START_DATE]: formatDateWithTimeToBE(values[START_DATE]),
            [END_DATE]: formatDateWithTimeToBE(values[END_DATE])
          },
          // old/original event group ID should be passed to track if EventGroup was changed during update
          originalEventGroupId
        )
      )
    },
    [dispatch, originalEventGroupId, selectedEditItemId]
  )

  const formik = useFormik({
    initialValues: getInitialValues(editEventData),
    enableReinitialize: true,
    validationSchema,
    onSubmit
  })

  return (
    <FormDrawerWrapper
      title={t('editEvent', { eventName: editEventData?.[NAME] })}
      subTitle={t('Edit event')}
      formName={PLANNER_EVENT_EDIT}
      showOpenButton={false}
      isFormLoading={editEventDataLoading}
    >
      <>
        <CalendarEventForm
          eventGroups={eventGroups}
          formik={formik}
          formName={PLANNER_EVENT_EDIT}
          clearHandler={clearEditEventSubmit}
          successSubmit={editEvent.wasUpdated}
          errorSelector={calendarEventUpdateError}
          isLoadingSelector={calendarEventUpdateIsLoading}
        />
        <DeleteCalendarEvent eventId={parseInt(selectedEditItemId)} eventGroupId={originalEventGroupId} />
      </>
    </FormDrawerWrapper>
  )
}

CalendarEditEvent.propTypes = {
  eventGroups: PropTypes.array
}

export default CalendarEditEvent
