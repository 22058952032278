import React from 'react'
import { ToastContainer } from 'react-toastify'

import { MobileDown, MobileUp } from '../../../../components/hoc/ResponsiveRendering'

function ToastNotifications() {
  return (
    <>
      <MobileUp>
        <ToastContainer position="top-right" autoClose={5000} closeOnClick pauseOnVisibilityChange pauseOnHover />
      </MobileUp>
      <MobileDown>
        <ToastContainer position="top-center" autoClose={5000} closeOnClick pauseOnVisibilityChange pauseOnHover />
      </MobileDown>
    </>
  )
}

export default ToastNotifications
