import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'formik'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import FieldsSection from '../../../../../../../features/components/Form/FieldsSection'
import Field from '../../../../../../../components/Form/Field'
import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'
import StartEndDateRangeFields from '../../../../../../ReusableFormFields/StartEndDateRangeFields'
import MultiSelectBox from '../../../../../../../features/components/Form/MultiSelectBox'

import { capitalizeFirstLetter } from '../../../../../../../helpers/common'
import { formatOptionsList } from '../../../../../../../features/formatters'

import { clearGetTagsList, getTagsList } from '../../../../../../../modules/actions/tags'
import { tagsIsLoadingSelector, tagsSelector } from '../../../../../../../modules/selectors/tags'
import {
  selectedControllerDataSelector,
  selectedControllerRelatedSelfAccountsListSelector,
  selectedSelfAccountDataSelector
} from '../../../../../../../modules/selectors/app'

import {
  AVAILABILITY_DATE_END,
  AVAILABILITY_DATE_START,
  CATEGORY,
  DATE_END,
  DATE_START,
  NAME,
  SUBTEXT,
  TAGS,
  ACCESS_ACCOUNTS
} from '../../../../fields'
import {
  controllerMembersIsLoadingSelector,
  controllerMembersSelector
} from '../../../../../../../modules/selectors/controller'
import { mediaPackageCategoriesSelector } from '../../../../../../../modules/selectors/mediaPackages'

import { REPRESENTATIVE } from '../../../../../../../constants/mediaOrders'

const stepFields = [NAME, SUBTEXT, CATEGORY, DATE_START, DATE_END, TAGS, ACCESS_ACCOUNTS]

function NameStep({ formik, handleStepChange }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const tags = useSelector(tagsSelector)
  const tagsIsLoading = useSelector(tagsIsLoadingSelector)
  const categories = useSelector(mediaPackageCategoriesSelector)
  const selectedSelfAccountData = useSelector(selectedSelfAccountDataSelector)
  const controllerSelfAccountsList = useSelector(selectedControllerRelatedSelfAccountsListSelector)
  const selectedController = useSelector(selectedControllerDataSelector)
  const controllerMembers = useSelector(controllerMembersSelector)
  const controllerMembersIsLoading = useSelector(controllerMembersIsLoadingSelector)

  const selectedControllerName = selectedController?.name || ''

  const { controller: controllerId } = selectedSelfAccountData
  const { values, setFieldValue, errors } = formik

  const formattedControllerMembers = useMemo(
    () =>
      formatOptionsList({
        list: controllerMembers,
        labelName: 'full_name',
        valueName: 'user'
      }),
    [controllerMembers]
  )

  const tagOptions = useMemo(() => {
    return formatOptionsList({
      list: tags,
      labelName: 'title',
      valueName: 'title'
    })
  }, [tags])

  const formattedCategories = useMemo(
    () =>
      categories.map(category => ({
        label: capitalizeFirstLetter(category.name),
        value: category.id
      })),
    [categories]
  )

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  useEffect(() => {
    dispatch(getTagsList({ controller: controllerId }))

    return () => {
      dispatch(clearGetTagsList())
    }
  }, [dispatch, controllerId])

  return (
    <Step
      stepTitle={t('Enter the package details')}
      stepDescription={t('Please provide the following details for the new media package')}
      handleContinue={handleContinue}
    >
      <FieldsSection title={t('Media package details')}>
        <FieldRow title={t('Name')} description={t('This name will be shown on the booking page')}>
          <Field formik={formik} name={NAME} placeholder="Package name" autoComplete="off" />
        </FieldRow>
        <FieldRow title={t('Description')} description={t('More details about the package')}>
          <Field formik={formik} name={SUBTEXT} isTextarea placeholder="Description" autoComplete="off" />
        </FieldRow>
        <FieldRow title={t('Package category')} description={t('How to categorise the package.')}>
          <Field
            formik={formik}
            options={formattedCategories}
            name={CATEGORY}
            isTextarea
            placeholder="Category"
            autoComplete="off"
            preselectFirstOptionValue
          />
        </FieldRow>
        <FieldRow
          title={t('Availability dates')}
          description={t('This package will only be shown on the booking page between these dates.')}
        >
          <StartEndDateRangeFields
            formik={formik}
            startDateName={AVAILABILITY_DATE_START}
            endDateName={AVAILABILITY_DATE_END}
          />
        </FieldRow>
        <FieldRow
          title={t('Visibility by tags (optional)')}
          description={t(
            'This package will only be shown to accounts with the selected tags. Leave blank to show to all accounts.'
          )}
        >
          <MultiSelectBox
            placeholder={t('Select tags')}
            name={TAGS}
            options={tagOptions}
            value={getIn(values, TAGS)}
            setFieldValue={setFieldValue}
            error={errors[TAGS]}
            isLoading={tagsIsLoading}
          />
        </FieldRow>
        <FieldRow
          title={t('Limit to specific accounts (optional)')}
          description={t(
            'This package will only be shown to the selected accounts. Leave blank to show to all accounts.'
          )}
        >
          <MultiSelectBox
            placeholder={t('Select accounts')}
            name={ACCESS_ACCOUNTS}
            options={controllerSelfAccountsList}
            value={getIn(values, ACCESS_ACCOUNTS)}
            setFieldValue={setFieldValue}
            error={errors[ACCESS_ACCOUNTS]}
          />
        </FieldRow>
        <FieldRow
          title={t('representativeOptional', { controllerName: selectedControllerName })}
          description={t('The salesperson or contact person for this media package.')}
        >
          <Field
            formik={formik}
            name={REPRESENTATIVE}
            placeholder="Select user"
            options={formattedControllerMembers}
            isLoading={controllerMembersIsLoading}
            showClearInputIcon={Boolean(values[REPRESENTATIVE])}
          />
        </FieldRow>
      </FieldsSection>
    </Step>
  )
}

export default NameStep
