import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addYears, format, isPast } from 'date-fns'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import EditForm from '../../../../../../features/components/Forms/EditForm'
import DatePicker from '../../../../../../components/DatePicker'
import FieldLabelWrapper from '../../../../../../features/components/Form/FieldLabelWrapper'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'

import {
  campaignSelector,
  campaignUpdateErrorSelector,
  campaignUpdateIsLoadingSelector,
  campaignWasUpdatedSelector
} from '../../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'
import { clearUpdateCampaign, updateCampaign } from '../../../../../../modules/actions/campaigns'

import { DATES_FORMAT_BE } from '../../../../../../constants/dates'
import { START_DATE, STOP_DATE } from '../../../CampaignGoogleCreate/CampaignGoogleCreateForm/fields'

import useStyles from '../../../../../../styles/common/drawerForms'

const DatesSectionForm = ({ editItemData: campaign, ...formProps }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const campaignWasUpdated = useSelector(campaignWasUpdatedSelector)

  const { [START_DATE]: startDate, [STOP_DATE]: stopDate } = useSelector(campaignSelector)

  const handleSubmit = useCallback(
    values => {
      const updateCampaignData = {
        account: selectedAdAccountId,
        [START_DATE]: format(values[START_DATE], DATES_FORMAT_BE),
        [STOP_DATE]: format(values[STOP_DATE], DATES_FORMAT_BE)
      }

      dispatch(updateCampaign(updateCampaignData, campaign.id))
    },
    [dispatch, campaign.id, selectedAdAccountId]
  )

  const initialValues = useMemo(
    () => ({
      [START_DATE]: (startDate && new Date(startDate)) || '',
      [STOP_DATE]: (stopDate && new Date(stopDate)) || ''
    }),
    [startDate, stopDate]
  )

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const { setFieldValue, values } = purifiedFormik

  const isStartDateInPast = isPast(new Date(values[START_DATE]))

  const handleStartDatePickerChange = useCallback(
    value => {
      setFieldValue(START_DATE, value)
    },
    [setFieldValue]
  )

  const handleStopDatePickerChange = useCallback(
    value => {
      setFieldValue(STOP_DATE, value)
    },
    [setFieldValue]
  )

  const handleClearUpdateCampaign = useCallback(() => {
    dispatch(clearUpdateCampaign())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={campaignWasUpdated}
      clearEditItem={handleClearUpdateCampaign}
      errorSelector={campaignUpdateErrorSelector}
      isLoadingSelector={campaignUpdateIsLoadingSelector}
      {...formProps}
    >
      <FieldLabelWrapper label="Start Date">
        <DatePicker
          title={t('Start Date')}
          value={values[START_DATE]}
          changeHandler={handleStartDatePickerChange}
          disabled={isStartDateInPast}
          maxDate={addYears(new Date(), 1)}
        />
      </FieldLabelWrapper>

      {values[STOP_DATE] ? (
        <FieldLabelWrapper label="End Date">
          <DatePicker
            title={t('End Date')}
            value={values[STOP_DATE]}
            changeHandler={handleStopDatePickerChange}
            minDate={isStartDateInPast ? new Date() : values[START_DATE]}
            maxDate={addYears(new Date(), 1)}
          />
        </FieldLabelWrapper>
      ) : (
        <>
          <div className={classes.sectionSubtitle}>{t('End Date')}</div>
          <p>{t('This campaign has been set up to run indefinitely until paused.')}</p>
        </>
      )}
    </EditForm>
  )
}

export default DatesSectionForm
