import { calc } from '../../../../../../../helpers/numbers'
import { calculateDiscountedPrice } from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/price'

export default function calculateProductPrice(productPeriods, quantity, discount) {
  const unitPrice = productPeriods.reduce((acc, period) => acc + period.price, 0)
  const totalPrice = calc(unitPrice).mul(quantity).toDP(2).toNumber()
  const discountedPrice = calculateDiscountedPrice(unitPrice, quantity, discount)

  return {
    unitPrice,
    totalPrice,
    discountedPrice
  }
}
