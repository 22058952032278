import { getFormattedProducts } from './productsFormatters'
import { calculateTotalOrderPrice } from '../helpers/price'
import { getAllPackageProducts } from '../helpers/products'
import { formatDateShort } from '../../../../../../../constants/dates'

import { REPRESENTATIVE } from '../../../../../../../constants/mediaOrders'
import { BRAND, CAMPAIGN_NAME, MEDIA_PRODUCTS, SELECTED_PACKAGES } from '../fields'
import { getAllContractProducts } from '../../../../../../../features/components/Contract/helpers'

const formatProducts = ({ selectedPackages, productsDiscount, selfAccountRepresentative }) => {
  const allSelectedProducts = selectedPackages.reduce((acc, mediaPackage) => {
    const packageProducts = getAllContractProducts(mediaPackage)

    const mediaPackageRepresentative = mediaPackage[REPRESENTATIVE]?.id

    const productsWithPackageInfo = packageProducts.map(productValues => ({
      ...productValues,
      discount: productsDiscount.find(discount => discount.id === productValues.data.id)?.discountPercentage,
      // add media_package to each product so BE has context in which package the product was selected
      media_package: mediaPackage.id,
      // add representative to each product if any, order should be mediaPackageRepresentative -> selfAccountRepresentative
      [REPRESENTATIVE]: mediaPackageRepresentative || selfAccountRepresentative || ''
    }))

    return acc.concat(productsWithPackageInfo)
  }, [])

  return getFormattedProducts(allSelectedProducts)
}

const getTotalOrderCost = ({ selectedPackages, currency, productsDiscount }) => {
  // formik selectedPackages consists from the detailed mediaPackage selectedPackages and selected products for this package
  return selectedPackages.reduce(
    (totalCost, packageValues) => {
      const { fixed_price: isFixedPrice, prices } = packageValues

      if (isFixedPrice) {
        // when there is a fixed price for package, the total cost of all publications are ignored
        // just return the fixed price of package
        const price = prices.find(item => item.currency === currency.code)?.price

        totalCost.regularPrice += price
        totalCost.discountedPrice += price
      } else {
        // calculate total cost of all publications
        const products = getAllPackageProducts(packageValues)
        const totalPackagePrice = calculateTotalOrderPrice(products, productsDiscount)

        totalCost.regularPrice += totalPackagePrice.regularPrice
        totalCost.discountedPrice += totalPackagePrice.discountedPrice
      }

      return totalCost
    },
    { regularPrice: 0, discountedPrice: 0 }
  )
}

export const transformValuesToBE = ({ values, controllerId, selfAccountData, currency, productsDiscount }) => {
  const { id: selectedSelfAccount, representative: selfAccountRepresentative } = selfAccountData

  const selectedPackages = values[SELECTED_PACKAGES]

  const formattedProducts = formatProducts({
    selectedPackages,
    productsDiscount,
    selfAccountRepresentative
  })

  // total cost for all packages
  const totalCost = getTotalOrderCost({ selectedPackages, currency, productsDiscount })

  return {
    name: 'Media Booking - ' + formatDateShort(new Date()),
    account: selectedSelfAccount,
    controller: controllerId,
    cost: Number(totalCost.regularPrice),
    cost_with_discount: Number(totalCost.discountedPrice),
    status: 'awaiting_creatives',
    currency: currency?.code,
    [MEDIA_PRODUCTS]: formattedProducts,
    // if adding other fields, please check also MediaOrderCreateSimpleForm and MediaOrderAmendForm
    [BRAND]: values[BRAND],
    [CAMPAIGN_NAME]: values[CAMPAIGN_NAME]
  }
}
