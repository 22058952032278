import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { getIn } from 'formik'

import Field from '../../../../../components/Form/Field'
import MultipleOptionsField from '../../../MultipleOptionsField'
import FieldRow from '../../../../../features/components/Form/FieldsSection/FieldRow'
import TextSuggestions from '../../../../../features/components/Form/TextSuggestions'
import FieldLabelWrapper from '../../../../../features/components/Form/FieldLabelWrapper'

import { adCreativeSuggestionsLoadingSelector } from '../../../../../modules/selectors/tools'

import { descriptionMaxLength } from '../../../../Facebook/AdForms/validation'

import { DESCRIPTIONS_LIST } from '../../../../Facebook/AdForms/fields'

const DescriptionField = ({ formik, itemPath, suggestions = [], allowMultiple }) => {
  const { values, setFieldValue } = formik

  const suggestionsLoading = useSelector(adCreativeSuggestionsLoadingSelector)

  const fieldPath = `${itemPath}.${DESCRIPTIONS_LIST}[0].text`
  const selectedDescription = getIn(values, fieldPath)

  const handleDescriptionChange = useCallback(value => setFieldValue(fieldPath, value), [fieldPath, setFieldValue])

  return (
    <FieldRow
      title="Description (optional)"
      description="27 characters or less recommended"
      footer={
        <TextSuggestions
          selectedSuggestion={selectedDescription}
          showLogo={false}
          suggestions={suggestions}
          onSelectSuggestion={handleDescriptionChange}
          typingAnimation={suggestionsLoading}
        />
      }
    >
      {allowMultiple ? (
        <FieldLabelWrapper label="Description(s)">
          <MultipleOptionsField
            fieldPlaceholder="Description"
            formik={formik}
            listFieldName={DESCRIPTIONS_LIST}
            fieldMaxLength={descriptionMaxLength}
            maxListQuantity={5}
          />
        </FieldLabelWrapper>
      ) : (
        <Field
          placeholder="Description"
          formik={formik}
          name={fieldPath}
          maxLength={descriptionMaxLength}
          isTextarea
          enableReinitialize
        />
      )}
    </FieldRow>
  )
}

DescriptionField.propTypes = {
  formik: PropTypes.object.isRequired,
  suggestions: PropTypes.array,
  allowMultiple: PropTypes.bool
}

export default DescriptionField
