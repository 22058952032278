import { createStyles } from '../../../../../styles/helpers'
import { textGrey } from '../../../../../styles/const/colors'

export default createStyles({
  modalContent: {
    marginTop: 0
  },
  modalBody: {
    maxWidth: '100%',
    textAlign: 'left',
    color: textGrey // reset modal overlay color
  }
})
