export const getTransformedData = data => {
  const m = data
  return {
    ...m,
    event_groups: m.event_groups.map(eg => ({
      ...eg,
      events: eg.events.map(event => ({
        ...event,
        start_timestamp: +new Date(event.start_datetime), // use timestamps instead of dates
        end_timestamp: +new Date(event.end_datetime) //  use timestamps instead of dates
      }))
    }))
  }
}
