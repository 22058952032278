import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles({
  percentageField: {
    minWidth: 95,
    maxWidth: 95
  },
  valueField: {
    flexGrow: 1
  }
})

export default useStyles
