import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import MultiSelectBox from '../../../../../features/components/Form/MultiSelectBox'
import Checkbox from '../../../../../components/Form/Checkbox'
import FieldRow from '../../../../../features/components/Form/FieldsSection/FieldRow'

import {
  selectedControllerDataSelector,
  selectedControllerRelatedSelfAccountsListSelector
} from '../../../../../modules/selectors/app'

import { ADD_TO_ALL_CONTROLLER_ACCOUNTS, SELF_ACCOUNTS } from '../../fields'

import useStyles from './styles'

const AccountsAccessFieldRow = ({ formik }) => {
  const classes = useStyles()

  const { values, setFieldValue, errors } = formik

  const { t } = useTranslation()
  const controllerSelfAccountsList = useSelector(selectedControllerRelatedSelfAccountsListSelector)
  const selectedController = useSelector(selectedControllerDataSelector)

  const selectedControllerName = selectedController?.name

  const accessToAllAccountsCheckboxHandler = useCallback(() => {
    setFieldValue(ADD_TO_ALL_CONTROLLER_ACCOUNTS, !values[ADD_TO_ALL_CONTROLLER_ACCOUNTS])
  }, [setFieldValue, values])

  return (
    <FieldRow
      title={t('Account(s) access')}
      description={
        values[ADD_TO_ALL_CONTROLLER_ACCOUNTS] ? '' : t('Please add this manager to at least one client account')
      }
    >
      <Checkbox
        id="access_to_all_controller_accounts_checkbox"
        title={t('accessToAllAccounts', { selectedControllerName })}
        isLarge
        checked={values[ADD_TO_ALL_CONTROLLER_ACCOUNTS]}
        onCheck={accessToAllAccountsCheckboxHandler}
        className={classes.accessToAllControllerAccountsCheckbox}
      />
      {!values[ADD_TO_ALL_CONTROLLER_ACCOUNTS] && (
        <MultiSelectBox
          placeholder={t('Select account')}
          name={SELF_ACCOUNTS}
          options={controllerSelfAccountsList}
          value={values[SELF_ACCOUNTS]}
          setFieldValue={setFieldValue}
          error={errors[SELF_ACCOUNTS]}
          touched
        />
      )}
    </FieldRow>
  )
}

export default AccountsAccessFieldRow
