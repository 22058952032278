import { PHONE_NUMBER } from '../../pages/Settings/EditProfile/UserProfileContent/fields'
import { getIn } from 'formik'

export const getValuesWereChanged = (initialValues, currentValues, updatedField) => {
  return Object.keys(initialValues).some(key => {
    if (updatedField && updatedField?.fieldName === key) {
      return initialValues[key] !== updatedField.value
    }
    if (key === PHONE_NUMBER) {
      if (initialValues[PHONE_NUMBER]) {
        return initialValues[PHONE_NUMBER] !== getIn(currentValues, PHONE_NUMBER)
      } else {
        // check that user doesn't have number and phoneSelect adds countryCode for this field
        const maxLengthForCountryCode = 4
        return !(!initialValues[PHONE_NUMBER] && getIn(currentValues, PHONE_NUMBER)?.length <= maxLengthForCountryCode)
      }
    }

    return initialValues[key] !== getIn(currentValues, key)
  })
}
