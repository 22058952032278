import { madeRequest } from '../../helpers/api'
import { ENDPOINTS } from '../../constants/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getSelfAccountsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.accounts
  })
}

export function createSelfAccountService(selfAccountData) {
  return madeRequest('POST', {
    data: selfAccountData,
    url: ENDPOINTS.accounts
  })
}

export function updateSelfAccountService(id, selfAccountData) {
  return madeRequest('PATCH', {
    data: selfAccountData,
    url: `${ENDPOINTS.accounts}${id}/`
  })
}

export function getSelfAccountCategoriesService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.categories
  })
}

export function getSelfAccountSubCategoriesService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.subCategories
  })
}

export function getSelfAccountService({ id }) {
  return madeRequest('GET', {
    url: `${ENDPOINTS.accounts}${id}/`
  })
}

export function getSelfAccountCategoryService({ id }) {
  return madeRequest('GET', {
    url: `${ENDPOINTS.categories}${id}/`
  })
}
