import { createStyles } from '../../../../../styles/helpers'
import { zIndex8 } from '../../../../../styles/const/common'

export const cardBorderRadius = 10
export const adPreviewFooterHeight = 34

export default createStyles({
  previewContainer: {
    backgroundColor: 'black',
    margin: '15px auto 30px',
    width: 258,
    minHeight: 493,
    height: 493,
    position: 'relative'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
    zIndex: zIndex8,
    color: 'white',
    pointerEvents: 'none'
  },
  menu: {
    display: 'flex',
    listStyle: 'none',
    padding: 0,
    margin: '15px auto',
    fontSize: '12px',

    '& li': {
      position: 'relative',

      '&:not(:first-child)': {
        marginLeft: 10
      }
    }
  },
  icon: {
    color: ['white', '!important'],
    fontSize: 40,
    transform: 'translateY(4px)'
  },
  inactive: {
    opacity: '.5'
  },
  active: {
    fontWeight: 'bold'
  },
  dot: {
    marginLeft: [0, '!important'],
    width: 6,
    height: 6,
    borderRadius: '50%',
    background: '#f4cb37'
  },
  mediaPreview: {
    marginTop: 15
  },
  displayName: {
    fontSize: '10px !important',
    fontWeight: 700,
    margin: '0 0 5px 0 !important'
  },
  adText: {
    fontSize: '12px !important',
    margin: '0 0 5px 0 !important'
  },
  link: {
    color: 'white',
    textDecoration: 'none'
  },
  musicIcon: {
    width: 10,
    marginRight: 5,
    marginTop: 5
  },
  cta: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '190px',
    height: '24px',
    marginBottom: '10px',
    color: '#fff',
    fontSize: '12px',
    background: '#3a3a3a',
    borderRadius: '3px',
    opacity: '.5',
    pointerEvents: 'all'
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: 0,
    marginTop: 'auto'
  },
  left: {
    paddingLeft: 10
  },
  right: {
    marginBottom: 65,
    paddingRight: 5
  },
  logo: {
    border: '1px solid #fff',
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginBottom: 20
  },
  stats: {
    width: 40,
    padding: 0,
    listStyle: 'none',
    marginLeft: 'auto',

    '& li': {
      position: 'relative',
      fontSize: 12,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '&:not(:last-child)': {
        marginBottom: 8
      }
    }
  },
  footer: {
    width: '100%',
    height: adPreviewFooterHeight,
    borderBottomLeftRadius: cardBorderRadius,
    borderBottomRightRadius: cardBorderRadius
  }
})
