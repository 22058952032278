import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import CampaignGoogleCreateForm from './CampaignGoogleCreateForm'
import CampaignGoogleGeolocationsForm from './CampaignGoogleGeolocationsForm'
import CampaignGoogleLanguagesForm from './CampaignGoogleLanguagesForm'
import FormWrapper from '../../../../features/components/Form/FormWrapper'

import { redirectTo } from '../../../../helpers/url'
import useContinuousForm from '../../../../hooks/formHooks/useContinuousForm'

import { clearCreateCampaign } from '../../../../modules/actions/campaigns'

import { CAMPAIGN_GOOGLE_ADS_CREATE } from '../../../../constants/forms'
import { ROUTE_PARAMS, ROUTES } from '../../../../constants/routes'
import { MODAL_TYPE } from '../../../../constants/other'
import { CAMPAIGN_GOOGLE_PERFORMANCE_MAX_TYPE } from '../../../../constants/campaigns'

const MAIN_FORM = 'main_form'
const GEOLOCATIONS_FORM = 'geolocations_form'
const LANGUAGES_FORM = 'languages_form'
const INITIAL_CAMPAIGN_ID = null

function CampaignGoogleCreate() {
  const dispatch = useDispatch()

  const { activatedForm, itemId, setItemId, setActivatedForm, handleResetForm, successSubmit, setSuccessSubmit } =
    useContinuousForm({
      initialForm: MAIN_FORM,
      initialItemId: INITIAL_CAMPAIGN_ID
    })

  const { t } = useTranslation()

  const [createdCampaignData, setCreatedCampaignData] = useState({})
  const { advertising_channel_type: advertisingChanelType } = createdCampaignData

  useEffect(() => {
    if (successSubmit) {
      // need to clear created campaign because without clear form opens on second step
      dispatch(clearCreateCampaign())

      // If advertisingChanelType = 10 - campaign is a "performance max campaign",
      // include this variable to url to open appropriate modal
      const redirectionPath =
        advertisingChanelType === CAMPAIGN_GOOGLE_PERFORMANCE_MAX_TYPE
          ? `${ROUTES.campaignsSummary}?message=${MODAL_TYPE.newCampaignCreated}&advertisingChanelType=10`
          : `${ROUTES.campaignsSummary}?message=${MODAL_TYPE.newCampaignCreated}`

      redirectTo(redirectionPath, {
        [ROUTE_PARAMS.campaignId]: itemId
      })
    }
  }, [dispatch, successSubmit, itemId, advertisingChanelType, handleResetForm])

  const getActivatedForm = () => {
    // google campaign creation consists with multiple forms submitting one by one
    // next form is showing only after success Submitting previous form
    switch (activatedForm) {
      case GEOLOCATIONS_FORM:
        return (
          <CampaignGoogleGeolocationsForm
            campaignId={itemId}
            onSuccessGeolocationsSubmit={() => {
              setActivatedForm(LANGUAGES_FORM)
            }}
          />
        )
      case LANGUAGES_FORM:
        return (
          <CampaignGoogleLanguagesForm campaignId={itemId} onSuccessLanguagesSubmit={() => setSuccessSubmit(true)} />
        )
      case MAIN_FORM:
      default:
        return (
          <CampaignGoogleCreateForm
            onSuccessCampaignCreate={(createdCampaignId, createdCampaignData) => {
              setItemId(createdCampaignId)
              setCreatedCampaignData(createdCampaignData)
              setActivatedForm(GEOLOCATIONS_FORM)
            }}
          />
        )
    }
  }

  const progressItems = [
    {
      label: t('Enter ad set details'),
      isActive: activatedForm === MAIN_FORM,
      isSuccess: activatedForm !== MAIN_FORM
    },
    {
      label: t('Set locations'),
      isActive: activatedForm === GEOLOCATIONS_FORM,
      isSuccess: activatedForm === LANGUAGES_FORM
    },
    {
      label: t('Set languages'),
      isActive: activatedForm === LANGUAGES_FORM,
      isSuccess: false
    }
  ]

  return (
    <FormDrawerWrapper
      openButtonText={t('New Campaign')}
      formName={CAMPAIGN_GOOGLE_ADS_CREATE}
      closeOnSubmit={false}
      onAfterFormClose={handleResetForm}
      isWideDrawer
      hasDefaultDrawerContentSpacing={false}
    >
      <FormWrapper formProgressItems={progressItems}>{getActivatedForm()}</FormWrapper>
    </FormDrawerWrapper>
  )
}

export default CampaignGoogleCreate
