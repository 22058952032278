import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import EditForm from '../../../../../../features/components/Forms/EditForm'
import BudgetContent from './ContentSections/BudgetContent'
import BidPriceContent from './ContentSections/BidPriceContent'

import { useEditSectionFormSubmit } from '../../../../../../hooks/formHooks/useEditSectionFormSubmit'
import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'

import { filterObjectEmptyValues } from '../../../../../../helpers/common'

import { updateLineItem, clearUpdateLineItem } from '../../../../../../modules/actions/lineItems'
import {
  lineItemUpdateErrorSelector,
  lineItemUpdateIsLoadingSelector,
  lineItemWasUpdatedSelector
} from '../../../../../../modules/selectors/lineItems'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { formatValuesToFE } from './formatters'
import { getValidationSchema } from './validation'
import { getBudgetType } from '../../../../../Multiplatform/LineItemForms/typeHelpers'

import { BID_PRICE } from '../../../LineItemTikTokCreate/LineItemTikTokCreateForm/fields'
import { BUDGET_DAILY, BUDGET_LIFETIME } from '../../../../../Multiplatform/LineItemForms/fields'

function BudgetSectionForm({ editItemData: lineItem, ...formProps }) {
  const dispatch = useDispatch()

  const lineItemWasUpdated = useSelector(lineItemWasUpdatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const { budget_lifetime: lineItemBudgetLifetime, budget_daily: lineItemBudgetDaily } = lineItem

  const budgetType = getBudgetType(lineItemBudgetLifetime, lineItemBudgetDaily)

  // show bid price field only if it was previously set
  const showBidPrice = !!lineItem?.bid

  const handleSubmit = useCallback(
    values => {
      // as we use PUT for update TikTok campaign, we should pass full data in payload
      dispatch(
        updateLineItem(
          {
            ...filterObjectEmptyValues(lineItem),
            account: selectedAdAccountId,
            ...(budgetType === BUDGET_LIFETIME && { [BUDGET_LIFETIME]: values[BUDGET_LIFETIME] }),
            ...(budgetType === BUDGET_DAILY && { [BUDGET_DAILY]: values[BUDGET_DAILY] }),
            ...(showBidPrice && { bid: values[BID_PRICE] })
          },
          lineItem.id,
          {
            requestMethod: 'PUT'
          }
        )
      )
    },
    [dispatch, budgetType, lineItem, showBidPrice, selectedAdAccountId]
  )

  const initialValues = formatValuesToFE(lineItem)

  const { onSubmit, manualSuccessSubmit } = useEditSectionFormSubmit({ initialValues, handleSubmit })

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: getValidationSchema(lineItem),
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const handleClearUpdateLineItem = useCallback(() => {
    dispatch(clearUpdateLineItem())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={lineItemWasUpdated || manualSuccessSubmit}
      clearEditItem={handleClearUpdateLineItem}
      errorSelector={lineItemUpdateErrorSelector}
      isLoadingSelector={lineItemUpdateIsLoadingSelector}
      {...formProps}
    >
      <BudgetContent formik={formik} budgetType={budgetType} />
      {showBidPrice && <BidPriceContent formik={formik} />}
    </EditForm>
  )
}

export default BudgetSectionForm
