import * as Yup from 'yup'

import { DISCOUNT_PERCENTAGE, DISCOUNTS, NAME } from './fields'

export const validationSchema = Yup.object({
  [NAME]: Yup.string().required('Name required'),
  [DISCOUNTS]: Yup.array().of(
    Yup.object().shape({
      [DISCOUNT_PERCENTAGE]: Yup.number().required('required')
    })
  )
})
