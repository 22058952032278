import {
  formatCategoriesToBE,
  formatDigitalProvidersToBE,
  formatDiscountsToBE,
  transformFilesToBE
} from '../../../formatters'
import { formatProductImagesToBE } from '../../sections/ImagesSection/formatters'
import { formatLocationTabsToBE } from '../../sections/LocationSection/formatters'

import { PRICE_CURRENCY, PRICE_PAIRS, PRICE_VALUE } from '../../../../../ReusableFormFields/PricePairFields/fields'
import {
  CREATIVE_DEADLINE_DAYS,
  DEFAULT_INVENTORY_DATE_START,
  DESCRIPTION,
  INTERNAL_ID,
  MEDIA_CATEGORY,
  NAME,
  PERIOD_OPTION,
  TAGS
} from '../../../MediaProductCreate/MediaProductCreateForm/fields'
import { DIGITAL } from '../../../../../../constants/mediaOrders'
import { INVENTORY_AMOUNT, INVENTORY_PERIOD, MAX_PERIODS, MIN_PERIODS, VARIABLES } from '../../../fields'
import { formatDateToBE } from '../../../../../../constants/dates'

export const transformValuesToBE = ({ initialValues, values, discountPresets }) => {
  const transformedFiles = transformFilesToBE(values)

  return {
    [NAME]: values[NAME],
    [DESCRIPTION]: values[DESCRIPTION],
    ...formatCategoriesToBE(values),
    [CREATIVE_DEADLINE_DAYS]: values[CREATIVE_DEADLINE_DAYS],
    ...(values[INVENTORY_AMOUNT] && { [INVENTORY_AMOUNT]: values[INVENTORY_AMOUNT] }),
    ...(values[INVENTORY_PERIOD] && { [INVENTORY_PERIOD]: values[INVENTORY_PERIOD] }),
    ...(values[DEFAULT_INVENTORY_DATE_START] && {
      [DEFAULT_INVENTORY_DATE_START]: formatDateToBE(new Date(values[DEFAULT_INVENTORY_DATE_START]))
    }),
    [MIN_PERIODS]: values[MIN_PERIODS],
    [MAX_PERIODS]: values[MAX_PERIODS],
    period: values[PERIOD_OPTION],
    prices: values[PRICE_PAIRS].map(pricePair => ({
      price: pricePair[PRICE_VALUE],
      currency: pricePair[PRICE_CURRENCY]
    })),
    ...formatDiscountsToBE({ editFormInitialValues: initialValues, values, discountPresets }),
    ...(values[MEDIA_CATEGORY] === DIGITAL
      ? formatDigitalProvidersToBE(values)
      : {
          offsite: false,
          providers: []
        }),
    ...(transformedFiles && transformedFiles),
    ...formatProductImagesToBE(initialValues, values),
    ...(values[TAGS].length && { [TAGS]: values[TAGS].map(option => option.value) }),
    ...formatLocationTabsToBE(values),
    [VARIABLES]: values[VARIABLES].map(variable => variable.value),
    [INTERNAL_ID]: values[INTERNAL_ID]
  }
}
