import * as Yup from 'yup'

import {
  ACCOUNT_CATEGORIES,
  BID_PRICE,
  BID_PRICE_OPTION,
  ENGAGED_OPTION,
  HASHTAGS_OPTION,
  NAME,
  PIXEL_EVENT,
  PIXEL_ID,
  TARGET_ACCOUNT_CATEGORIES_OPTION,
  TARGET_VIDEO_CATEGORIES_OPTION,
  VIDEO_CATEGORIES,
  WATCHED_OR_ENGAGED_OPTION
} from './fields'
import { startTimeValidation } from '../../../../ReusableFormFields/StartTimeFields/fields'
import { stopTimeValidation } from '../../../../ReusableFormFields/StopTimeFields/fields'
import { scheduleValidation } from '../../../../../features/validations/schedule'
import { geolocationsValidation } from '../../../../../features/validations/gelolocations'
import { interestsValidation } from '../../../../ReusableFormFields/InterestsFields/fields'
import { OPTION_CUSTOM, OPTION_YES } from '../../../../../constants/forms'
import { AGE_OPTION, CUSTOM_AGE_OPTION, CUSTOM_AGE_RANGES } from '../../../../ReusableFormFields/AgeRangesFields/fields'
import {
  SHOW_OPTION,
  SHOW_OPTION_REGIONS,
  SHOW_REGIONS
} from '../../../../ReusableFormFields/GeolocationsFields/fields'
import { BUDGET_DAILY, BUDGET_LIFETIME, BUDGET_OPTION } from '../../../../Multiplatform/LineItemForms/fields'
import { REACH } from '../../../../../constants/campaigns'
import {
  FREQUENCY_DAYS,
  FREQUENCY_IMPRESSIONS,
  FREQUENCY_OPTION
} from '../../../../ReusableFormFields/FrequencyFields/fields'
import { languagesCreateValidation } from '../../../../../features/validations/languages'
import { HASHTAGS } from '../../../ReusableFormFields/TikTokHashtagsFields/fields'
import { customAudiencesValidation } from '../../../../ReusableFormFields/CustomAudiencesFields/fields'

export const getValidationSchema = ({
  isCampaignLevelBudgetSet,
  campaignObjective = '',
  showPixelStep,
  showBidPriceStep,
  isBidTypeCustomPopulated
}) => {
  const shouldValidateFrequency = campaignObjective.toLowerCase() === REACH

  return Yup.object({
    [NAME]: Yup.string().max(400, 'Name should have maximum 400 characters').required('Name Required'),
    // BUDGET
    ...(!isCampaignLevelBudgetSet && {
      [BUDGET_LIFETIME]: Yup.string().when(BUDGET_OPTION, {
        is: BUDGET_LIFETIME,
        then: () => Yup.string().required('This field can not be empty')
      }),
      [BUDGET_DAILY]: Yup.string().when(BUDGET_OPTION, {
        is: BUDGET_DAILY,
        then: () => Yup.string().required('This field can not be empty')
      })
    }),
    // START TIME
    ...startTimeValidation(),
    // STOP TIME
    ...stopTimeValidation,
    // SCHEDULE
    ...scheduleValidation,
    // GEOLOCATIONS
    ...geolocationsValidation,
    [SHOW_REGIONS]: Yup.array().when(SHOW_OPTION, {
      is: SHOW_OPTION_REGIONS,
      then: () => Yup.array().min(1, 'Please select at least one option')
    }),
    // LANGUAGES
    ...languagesCreateValidation,
    // AGES
    [CUSTOM_AGE_RANGES]: Yup.object().when(AGE_OPTION, {
      is: CUSTOM_AGE_OPTION,
      then: () =>
        Yup.object().test({
          message: 'Select at least one age range',
          test: value => !!Object.keys(value).filter(item => value[item]).length
        })
    }),
    // INTERESTS
    ...interestsValidation,
    // VIDEO CATEGORIES
    [VIDEO_CATEGORIES]: Yup.array().when(TARGET_VIDEO_CATEGORIES_OPTION, {
      is: option => option === WATCHED_OR_ENGAGED_OPTION || option === ENGAGED_OPTION,
      then: () => Yup.array().min(1, 'Please select at least one option')
    }),
    // ACCOUNT CATEGORIES
    [ACCOUNT_CATEGORIES]: Yup.array().when(TARGET_ACCOUNT_CATEGORIES_OPTION, {
      is: OPTION_YES,
      then: () => Yup.array().min(1, 'Please select at least one option')
    }),
    // HASHTAGS
    [HASHTAGS]: Yup.array().when(HASHTAGS_OPTION, {
      is: OPTION_YES,
      then: () => Yup.array().min(1, 'Please select at least one option')
    }),
    // FREQUENCY
    // todo correct with proper min/max days, because current version was copied from Facebook
    ...(shouldValidateFrequency && {
      [FREQUENCY_IMPRESSIONS]: Yup.number().when(FREQUENCY_OPTION, {
        is: OPTION_CUSTOM,
        then: () =>
          Yup.number()
            .min(1, 'Value must be between 1 and 90')
            .max(90, 'Value must be between 1 and 90')
            .required('This field can not be empty')
      }),
      [FREQUENCY_DAYS]: Yup.number().when(FREQUENCY_OPTION, {
        is: OPTION_CUSTOM,
        then: () =>
          Yup.number()
            .min(1, 'Value must be between 1 and 90')
            .max(90, 'Value must be between 1 and 90')
            .required('This field can not be empty')
      })
    }),
    // CUSTOM AUDIENCES
    ...customAudiencesValidation,
    ...(showPixelStep && {
      [PIXEL_ID]: Yup.string().required('Please select pixel'),
      [PIXEL_EVENT]: Yup.string().required('Please select pixel event')
    }),
    ...(showBidPriceStep && {
      [BID_PRICE]: isBidTypeCustomPopulated
        ? Yup.number().required('Please enter a bid price')
        : Yup.number().when(BID_PRICE_OPTION, {
            is: OPTION_YES,
            then: () => Yup.number().required('Please enter a bid price')
          })
    })
  })
}
