import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../components/Form'
import Field from '../../../components/Form/Field'
import UnauthorizedPage from '../UnauthorizedPage'
import InfoBlock from '../../../features/components/InfoBlock'

import { createNewPassword } from '../../../modules/actions/cognito'
import {
  cognitoLoadingSelector,
  createPasswordSelector,
  createPasswordErrorSelector
} from '../../../modules/selectors/cognito'

import { createJsonFromQueryString } from '../../../helpers/url'
import { useAuthButtonProps } from '../useAuthButtonProps'

import history from '../../../history'
import { initialValues, PASSWORD, validationSchema } from '../CreatePassword/fields'

import useAuthStyles from '../../../styles/common/authPages'

// the page is to set a new password after reset request, user land to the page from temporary email link
function SetForgotPassword() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const authClasses = useAuthStyles()
  const queryParams = history.location.search

  const { wasCreated: passwordWasCreated } = useSelector(createPasswordSelector)

  const buttonProps = useAuthButtonProps()

  const onSubmit = useCallback(
    values => {
      // take parameters from link and send to AWS for login
      const queryParameters = createJsonFromQueryString(queryParams, false)
      dispatch(
        createNewPassword({
          username: queryParameters.cl,
          code: queryParameters.co,
          new_password: values[PASSWORD] // the new password
        })
      )
    },
    [dispatch, queryParams]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  return (
    <UnauthorizedPage helmetTitle="helmetTitle.SetForgotPasswordPage" className={authClasses.authPage}>
      <InfoBlock title={t('SetForgotPasswordPage title')} hideIcon />
      <div className={authClasses.formContainer}>
        <Form
          formName="setForgotPassword"
          formik={formik}
          method="post"
          errorSelector={createPasswordErrorSelector}
          isLoadingSelector={cognitoLoadingSelector}
          successSubmit={passwordWasCreated}
          submitText={t('Create Password')}
          buttonProps={buttonProps}
        >
          <Field formik={formik} type="password" id={PASSWORD} name={PASSWORD} placeholder="New Password" />
        </Form>
      </div>
    </UnauthorizedPage>
  )
}

export default SetForgotPassword
