import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import TableDataLoader from '../../../../components/Table/TableDataLoader'
import BrandPagesTableData from './BrandPagesTableData'

import {
  categoryPagesErrorSelector,
  categoryPagesIsLoadingSelector,
  categoryPagesSelector,
  categoryPagesWasLoadedSelector
} from '../../../../modules/selectors/mediaOrders'

import { mobileOneLineColumnsSchema } from '../../../../constants/other'

import { phonesDownSize } from '../../../../styles/const/breakpoints'
import useCommonStyles from '../../../../styles/common/table'

export const tableColumnsSize = {
  pageName: 120,
  link: 80,
  actions: 40
}

const BrandPagesTable = () => {
  const { t } = useTranslation()

  const categoryPages = useSelector(categoryPagesSelector)

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const commonClasses = useCommonStyles()

  const categoryPagesSchema = useMemo(
    () => [
      {
        header: 'Page Name',
        style: { maxWidth: tableColumnsSize.pageName }
      },
      {
        header: 'Link',
        style: { maxWidth: tableColumnsSize.link, marginLeft: 'auto' }
      },
      {
        header: 'Actions',
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no pages')}</div>
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <TableDataLoader
      itemsLength={categoryPages.length}
      errorSelector={categoryPagesErrorSelector}
      wasLoadedSelector={categoryPagesWasLoadedSelector}
      isLoadingSelector={categoryPagesIsLoadingSelector}
      skeletonProps={{ cols: isMobile ? mobileOneLineColumnsSchema : categoryPagesSchema }}
      noDataContent={noDataContent}
    >
      <BrandPagesTableData categoryPages={categoryPages} />
    </TableDataLoader>
  )
}

export default BrandPagesTable
