import { createStyles } from '../../../../styles/helpers'

import { cubicBezier } from '../../../../styles/const/animation'
import { greyOutline } from '../../../../styles/const/colors'
import { tabletDown } from '../../../../styles/const/breakpoints'

const useStyles = createStyles(theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 20,
    padding: '8px 16px 8px 8px',
    background: 'white',
    borderRadius: 4,
    border: `1px solid ${greyOutline}`,
    transition: `border .3s ${cubicBezier}`,
    '&.hoverable': {
      cursor: 'pointer',
      '&:hover': {
        border: `1px solid ${theme.brandPrimary}`
      }
    }
  },
  info: {
    flexGrow: 1,
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  description: {
    fontSize: 12,
    marginBottom: 4
  },
  tags: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: 5
  },
  tag: {
    padding: '4px 8px',
    border: `1px solid ${theme.brandPrimary}`,
    color: theme.brandPrimary,
    fontSize: 12,
    borderRadius: 2
  },
  productCardButton: {
    fontSize: 12,
    fontWeight: 400,
    borderRadius: 22,
    height: 'unset',
    padding: ['8px 12px', '!important']
  },
  soldOut: {
    minWidth: 78
  },
  [`@media screen and (${tabletDown})`]: {
    productCardButton: {
      // reset button styles width 100%
      width: ['unset', '!important'],
      maxWidth: ['unset', '!important']
    }
  }
}))

export default useStyles
