import { createStyles } from '../../../../../../styles/helpers'

import { mediumGrey } from '../../../../../../styles/const/colors'

const bottomPadding = 28
const sidePadding = 58
export default createStyles(theme => ({
  page: {
    padding: `48px ${sidePadding}px ${bottomPadding}px ${sidePadding}px`,
    borderRadius: theme.bigBorderRadius,
    border: `1px solid ${mediumGrey}`,
    background: 'white',
    position: 'relative',
    aspectRatio: '1 / 1.41' // set height according to A4 aspect ratio
  },
  row: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    minHeight: 20,
    gap: 30
  },
  col: {
    flex: 1
  },
  header: {
    marginBottom: '25px',
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerDetails: {
    flexGrow: 1,
    textAlign: 'right'
  },
  rowMarginBottom: {
    marginBottom: 16
  },
  rowMarginTop: {
    marginTop: 16
  },
  cartFooter: {
    marginTop: 16
  },
  subValue: {
    verticalAlign: 'baseline'
  },
  lineBottom: {
    borderBottom: `1px solid ${mediumGrey}`,
    margin: '8px 0'
  },
  cartRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    marginBottom: 8
  },
  arabicText: {
    direction: 'rtl'
  },
  listHeadline: {
    marginBottom: 0
  },
  ol: {
    paddingInlineStart: 15
  },
  signatureContainer: {
    display: 'inline-flex',
    alignItems: 'baseline',
    paddingBottom: 16
  },
  signatureArea: {
    width: 250,
    height: 1,
    border: 'none',
    background: '#000',
    marginLeft: 14
  },
  signatureImage: {
    marginLeft: 14
  },
  pageFooter: {
    width: '100%',
    position: 'absolute',
    bottom: bottomPadding,
    left: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    gap: 16
  },
  companyDetails: {
    textAlign: 'center',
    fontSize: 10
  },
  pagesProgress: {
    position: 'absolute',
    bottom: -4,
    right: '26px',
    textAlign: 'right'
  },
  productPrice: {
    width: '200px',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))
