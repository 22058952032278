import React, { useCallback } from 'react'

import Checkbox from '../../../../../../../../components/Form/Checkbox'
import ErrorMessage from '../../../../../../../../components/Form/ErrorMessage'

import { AGE } from '../../../../../../../Multiplatform/LineItemForms/fields'
import {
  AGE_13_17,
  AGE_18_24,
  AGE_25_34,
  AGE_35_44,
  AGE_45_54,
  AGE_55_100
} from '../../../../../../../ReusableFormFields/AgeRangesFields/fields'

import useDrawerFormStyles from '../../../../../../../../styles/common/drawerForms'

const AgeContent = ({ formik }) => {
  const drawerFormClasses = useDrawerFormStyles()

  const { values, errors, touched, setFieldValue } = formik

  const showAgeError = errors[AGE] && touched[AGE]

  const handlerAgeCheck = useCallback(
    ageName => {
      setFieldValue(`${[AGE]}.${[ageName]}`, !values[AGE][ageName])
    },
    [setFieldValue, values]
  )

  return (
    <div className={drawerFormClasses.subSection}>
      <h4 className={drawerFormClasses.sectionSubtitle}>Age</h4>
      <div className={drawerFormClasses.checkboxesGrid}>
        <Checkbox
          id="checkbox_age_13_17"
          title="13-17"
          isBoldTitle={false}
          checked={values[AGE][AGE_13_17]}
          onCheck={() => handlerAgeCheck(AGE_13_17)}
        />
        <Checkbox
          id="checkbox_age_18_24"
          title="18-24"
          isBoldTitle={false}
          checked={values[AGE][AGE_18_24]}
          onCheck={() => handlerAgeCheck(AGE_18_24)}
        />
        <Checkbox
          id="checkbox_age_25_34"
          title="25-34"
          isBoldTitle={false}
          checked={values[AGE][AGE_25_34]}
          onCheck={() => handlerAgeCheck(AGE_25_34)}
        />
        <Checkbox
          id="checkbox_age_35_44"
          title="35-44"
          isBoldTitle={false}
          checked={values[AGE][AGE_35_44]}
          onCheck={() => handlerAgeCheck(AGE_35_44)}
        />
        <Checkbox
          id="checkbox_age_45_54"
          title="45-54"
          isBoldTitle={false}
          checked={values[AGE][AGE_45_54]}
          onCheck={() => handlerAgeCheck(AGE_45_54)}
        />
        <Checkbox
          id="checkbox_age_55_plus"
          title="55+"
          isBoldTitle={false}
          checked={values[AGE][AGE_55_100]}
          onCheck={() => handlerAgeCheck(AGE_55_100)}
        />
      </div>
      {showAgeError && <ErrorMessage error={errors[AGE]} />}
    </div>
  )
}

export default AgeContent
