import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Form from '../../../../components/Form'
import Button from '../../../../components/Button'
import ProgressButton from '../../../../components/Form/ProgressButton'

import { isEqual } from '../../../../helpers/common'

import useStyles from './styles'

const EditForm = ({
  children,
  onSuccessSubmit,
  onEditClose,
  errorSelector,
  isLoadingSelector,
  submitText = 'Save',
  formik,
  initialValues,
  setUnsavedChanges,
  successSubmit,
  clearEditItem,
  submitBtnDisabled,
  isLoading,
  requestError,
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  // to support multiple requests during editing additional error/loading props are used:
  const formError = useSelector(errorSelector) || requestError
  const isFormLoading = useSelector(isLoadingSelector) || isLoading
  const { resetForm } = formik

  const checkUnsavedChanges = useCallback(() => {
    // initial values updates automatically on successful server update response through redux
    const hasChanges = !isEqual(formik.values, initialValues)
    setUnsavedChanges(hasChanges)
  }, [formik.values, initialValues, setUnsavedChanges])

  const handleSuccessSubmit = useCallback(() => {
    onSuccessSubmit && onSuccessSubmit()
    // when form is successfully submitted > reset formik
    resetForm()
  }, [onSuccessSubmit, resetForm])

  useEffect(() => {
    if (setUnsavedChanges) checkUnsavedChanges()
    // eslint-disable-next-line
  }, [formik.values])

  return (
    <Form
      showSubmit={false}
      errorSelector={errorSelector}
      isLoadingSelector={isLoadingSelector}
      formik={formik}
      {...props}
    >
      <>
        {children}
        <div className={classes.sectionControls}>
          <Button onClick={onEditClose} type="button">
            {t('Cancel changes')}
          </Button>
          <ProgressButton
            className={classes.submitBtn}
            formError={formError}
            isFormLoading={isFormLoading}
            successSubmit={successSubmit}
            clearHandler={clearEditItem}
            onSuccessSubmit={handleSuccessSubmit}
            disabled={submitBtnDisabled}
          >
            {t(submitText)}
          </ProgressButton>
        </div>
      </>
    </Form>
  )
}

EditForm.propTypes = {
  errorSelector: PropTypes.func.isRequired,
  isLoadingSelector: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  setUnsavedChanges: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  successSubmit: PropTypes.bool,
  onSuccessSubmit: PropTypes.func,
  onEditClose: PropTypes.func,
  clearEditItem: PropTypes.func,
  submitBtnDisabled: PropTypes.bool
}

export default EditForm
