import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Button from '../../Button'
import InfoBlock from '../../../features/components/InfoBlock'
import ConditionalWrapper from '../../hoc/ConditionalWrapper'

import useLoadMore from '../../../hooks/useLoadMore'

import { ReactComponent as IconEmptyBox } from '../../../assets/icons/empty-box.svg'

import useStyles from './styles'

function ItemsLoading({
  loadMore,
  itemsLength = 0,
  // be careful setting big numbers due to performance issues with a big amount of skeletons
  initialLoadingSkeletonsNumber = 3,
  filteringLoadingSkeletonsNumber,
  isLoadingSelector,
  wasLoadedSelector,
  errorSelector,
  className,
  infoBlockClassName,
  children,
  additionalDataIsLoading,
  noDataTitle = 'Nothing to see here!',
  noDataText = 'There are no items to show.',
  loadMoreButtonClassName,
  loadMoreButtonText = 'Load more',
  showNoDataMessageFromOutside,
  SkeletonComponent,
  ContentWrapper,
  skeletonProps,
  noDataWithoutIconAndDescription = false,
  contentWrapperText
}) {
  const classes = useStyles()

  const { t } = useTranslation()

  const itemsIsLoading = useSelector(isLoadingSelector)
  const itemsWasLoaded = useSelector(wasLoadedSelector)
  const itemsError = useSelector(errorSelector)

  const { isLoadingMoreItems, loadMoreItemsHandler, showLoadMoreButton } = useLoadMore(loadMore, itemsIsLoading)

  const itemsIsEmpty = itemsLength === 0

  const isInitialLoading = (itemsIsLoading && itemsIsEmpty) || additionalDataIsLoading

  // filtering means that items were loaded, but new request running (some filters were changed)
  const isFilteringRunning = itemsWasLoaded && itemsIsLoading

  // show if first initial loading or we loading more items
  const showBasicLoading = isInitialLoading || isLoadingMoreItems

  // show if initial loading was done and items are not filtering, or we loading more items
  const showMainContent = (!isInitialLoading && !isFilteringRunning && !itemsIsEmpty) || isLoadingMoreItems

  const showFilteringLoading = !isInitialLoading && !isLoadingMoreItems && isFilteringRunning

  // request done, but data is empty or received error
  const showNoDataMessage =
    itemsIsEmpty && (itemsWasLoaded || itemsError) && !itemsIsLoading && !additionalDataIsLoading

  if (showNoDataMessage || showNoDataMessageFromOutside) {
    if (noDataWithoutIconAndDescription) {
      return (
        <div className={classes.noDataContent}>
          <div>{t(noDataText)}</div>
        </div>
      )
    }
    return (
      <InfoBlock className={infoBlockClassName} Icon={IconEmptyBox} title={noDataTitle}>
        {noDataText}
      </InfoBlock>
    )
  }

  return (
    <ConditionalWrapper
      condition={!!ContentWrapper}
      wrapper={children => <ContentWrapper title={contentWrapperText}>{children}</ContentWrapper>}
    >
      <ul className={classnames(classes.list, className)}>
        {showMainContent && children}
        {showBasicLoading &&
          Array(initialLoadingSkeletonsNumber)
            .fill(Math.random())
            .map((item, index) => <SkeletonComponent key={index} {...skeletonProps} />)}

        {showFilteringLoading &&
          Array(filteringLoadingSkeletonsNumber || (itemsLength <= 15 ? itemsLength : 15))
            .fill(Math.random())
            .map((item, index) => <SkeletonComponent key={index} {...skeletonProps} />)}
      </ul>

      {showLoadMoreButton && (
        <Button className={classnames(classes.loadMoreBtn, loadMoreButtonClassName)} onClick={loadMoreItemsHandler}>
          {t(loadMoreButtonText)}
        </Button>
      )}
    </ConditionalWrapper>
  )
}

ItemsLoading.propTypes = {
  loadMore: PropTypes.func,
  itemsLength: PropTypes.number,
  initialLoadingSkeletonsNumber: PropTypes.number,
  filteringLoadingSkeletonsNumber: PropTypes.number,
  noDataTitle: PropTypes.string,
  noDataText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  showNoDataMessageFromOutside: PropTypes.bool,
  isLoadingSelector: PropTypes.func.isRequired,
  wasLoadedSelector: PropTypes.func.isRequired,
  errorSelector: PropTypes.func.isRequired,
  additionalDataIsLoading: PropTypes.bool,
  SkeletonComponent: PropTypes.func.isRequired,
  ContentWrapper: PropTypes.func,
  className: PropTypes.string,
  infoBlockClassName: PropTypes.string,
  skeletonProps: PropTypes.object,
  noDataWithoutIconAndDescription: PropTypes.bool,
  contentWrapperText: PropTypes.string
}

export default ItemsLoading
