import { all, call, put, takeEvery } from 'redux-saga/effects'

import {
  GET_TIK_TOK_VIDEOS,
  getTikTokVideosSuccess,
  getTikTokVideosFailure,
  GET_FACEBOOK_IMAGES,
  getFacebookMediaImagesSuccess,
  getFacebookMediaImagesFailure,
  GET_FACEBOOK_VIDEOS,
  getFacebookMediaVideosSuccess,
  getFacebookMediaVideosFailure,
  GET_FACEBOOK_VIDEO,
  getFacebookMediaVideoSuccess,
  getFacebookMediaVideoFailure,
  GET_FACEBOOK_VIDEO_URLS,
  getFacebookVideoUrlsSuccess,
  getFacebookVideoUrlsFailure,
  GET_FACEBOOK_IMAGE_URLS,
  getFacebookImageUrlsSuccess,
  getFacebookImageUrlsFailure
} from '../actions/mediaFiles'

import {
  getTikTokVideosService,
  getFacebookImageUrlsService,
  getFacebookMediaImagesService,
  getFacebookMediaVideoService,
  getFacebookMediaVideosService,
  getFacebookVideoUrlsService
} from '../services/mediaFiles'

function* mediaFilesWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_TIK_TOK_VIDEOS, getTikTokVideosWorker),
    takeEvery(GET_FACEBOOK_IMAGES, getFacebookMediaImagesWatcher),
    takeEvery(GET_FACEBOOK_VIDEOS, getFacebookMediaVideosWatcher),
    takeEvery(GET_FACEBOOK_VIDEO, getFacebookMediaVideoWatcher),
    takeEvery(GET_FACEBOOK_VIDEO_URLS, getFacebookVideoUrlsWatcher),
    takeEvery(GET_FACEBOOK_IMAGE_URLS, getFacebookImageUrlsWatcher)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getTikTokVideosWorker({ params }) {
  try {
    const response = yield call(getTikTokVideosService, params)
    yield put(getTikTokVideosSuccess(response))
  } catch (error) {
    yield put(getTikTokVideosFailure(error))
  }
}

function* getFacebookMediaImagesWatcher({ params }) {
  try {
    const response = yield call(getFacebookMediaImagesService, params)
    yield put(getFacebookMediaImagesSuccess(response))
  } catch (e) {
    yield put(getFacebookMediaImagesFailure(e))
  }
}

function* getFacebookMediaVideosWatcher({ params }) {
  try {
    const response = yield call(getFacebookMediaVideosService, params)
    yield put(getFacebookMediaVideosSuccess(response))
  } catch (e) {
    yield put(getFacebookMediaVideosFailure(e))
  }
}

function* getFacebookMediaVideoWatcher({ params }) {
  try {
    const response = yield call(getFacebookMediaVideoService, params)
    yield put(getFacebookMediaVideoSuccess(response))
  } catch (e) {
    yield put(getFacebookMediaVideoFailure(e))
  }
}

function* getFacebookVideoUrlsWatcher({ params }) {
  try {
    const requestedVideoIds = params.video_ids
    const response = yield call(getFacebookVideoUrlsService, params)
    yield put(getFacebookVideoUrlsSuccess(response, requestedVideoIds))
  } catch (e) {
    yield put(getFacebookVideoUrlsFailure(e))
  }
}

function* getFacebookImageUrlsWatcher({ params }) {
  try {
    const requestedHashes = params.hashes
    const response = yield call(getFacebookImageUrlsService, params)
    yield put(getFacebookImageUrlsSuccess(response, requestedHashes))
  } catch (e) {
    yield put(getFacebookImageUrlsFailure(e))
  }
}

export default mediaFilesWatcher
