import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { calc } from '../../../../../../../../../helpers/numbers'

import useStyles from './styles'

const BookedField = React.memo(({ inventoryQuantity, bookedQuantity }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const bookedPercentages = calc(bookedQuantity).div(inventoryQuantity).mul(100).toNumber()
  const roundedPercentages = calc(bookedPercentages).toDP(2).toNumber()

  if (!bookedQuantity && !inventoryQuantity) {
    return null
  } else if (bookedQuantity && !inventoryQuantity) {
    return (
      <div className={classes.bookedContainer}>
        <div>{t('Booked')}: </div>
        <div>{bookedQuantity}</div>
      </div>
    )
  } else {
    return (
      <div className={classes.bookedContainer}>
        <div>{t('Booked')}: </div>
        <div>
          {bookedQuantity} {`(${roundedPercentages}%)`}
        </div>
      </div>
    )
  }
})

BookedField.propTypes = {
  inventoryQuantity: PropTypes.number.isRequired,
  bookedQuantity: PropTypes.number.isRequired
}

export default BookedField
