import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getCampaignsReportsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.campaignReports
  })
}

export function getCampaignsGraphsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.campaignGraphs
  })
}
