import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import CustomAudiencesFields from '../../../ReusableFormFields/CustomAudiencesFields'

import { formatOptionsList } from '../../../../features/formatters'

import { getAudiences, clearAudiences } from '../../../../modules/actions/audiences'
import { audiencesLoadingSelector, audiencesSelector } from '../../../../modules/selectors/audiences'

import { FACEBOOK_PLATFORM } from '../../../../constants/selectLists/platformList'
import { selectedAdAccountIdSelector } from '../../../../modules/selectors/app'

const FacebookAudiencesFields = ({ formik, adAccountId }) => {
  const dispatch = useDispatch()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const audiences = useSelector(audiencesSelector)
  const audiencesIsLoading = useSelector(audiencesLoadingSelector)

  const formattedAudiences = useMemo(
    () => formatOptionsList({ list: audiences, valueName: 'id', labelName: 'name' }),
    [audiences]
  )

  useEffect(() => {
    dispatch(
      getAudiences(
        {
          account: adAccountId || selectedAdAccountId,
          limit: 25
        },
        FACEBOOK_PLATFORM
      )
    )
  }, [dispatch, adAccountId, selectedAdAccountId])

  useEffect(
    () => () => {
      dispatch(clearAudiences())
    },
    [dispatch]
  )

  return (
    <CustomAudiencesFields
      formik={formik}
      formattedAudiences={formattedAudiences}
      audiencesIsLoading={audiencesIsLoading}
    />
  )
}

FacebookAudiencesFields.propTypes = {
  formik: PropTypes.object.isRequired,
  adAccountId: PropTypes.string
}

export default FacebookAudiencesFields
