import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles(theme => ({
  price: {
    fontSize: 12,
    fontWeight: 400
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

export default useStyles
