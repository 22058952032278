import * as Yup from 'yup'

import { getBudgetType } from '../../../../../Multiplatform/LineItemForms/typeHelpers'
import { BUDGET_DAILY, BUDGET_LIFETIME } from '../../../../../Multiplatform/LineItemForms/fields'
import { BID_PRICE } from '../../../LineItemTikTokCreate/LineItemTikTokCreateForm/fields'

export const getValidationSchema = lineItem => {
  const { budget_lifetime: lineItemBudgetLifetime, budget_daily: lineItemBudgetDaily } = lineItem
  const budgetType = getBudgetType(lineItemBudgetLifetime, lineItemBudgetDaily)

  const isBudgetLifetime = budgetType === BUDGET_LIFETIME
  const isBudgetDaily = budgetType === BUDGET_DAILY

  // if bid was previously set, then we should validate bid price
  const shouldValidateBidPrice = !!lineItem.bid

  return Yup.object().shape({
    ...(isBudgetLifetime && {
      [BUDGET_LIFETIME]: Yup.number()
        .typeError('Budget must be a number')
        .min(1, 'Budget must be greater than 0')
        .required('Budget is required')
    }),
    ...(isBudgetDaily && {
      [BUDGET_DAILY]: Yup.number()
        .typeError('Budget must be a number')
        .min(1, 'Budget must be greater than 0')
        .required('Budget is required')
    }),
    ...(shouldValidateBidPrice && {
      [BID_PRICE]: Yup.number().required('Please enter a bid price')
    })
  })
}
