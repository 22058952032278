import { DOMAINS } from '../../constants/selectLists/languagesList'
import { aduhuTheme } from './aduhuTheme'
import { cenomispaceTheme } from './cenomispaceTheme'
import { oneviuTheme } from './oneviuTheme'
import { nexusTheme } from './nexusTheme'

export const getDomainTheme = () => {
  const currentAppUrl = window.location.href

  if (currentAppUrl.includes(DOMAINS.aduhu)) {
    return aduhuTheme
  } else if (currentAppUrl.includes(DOMAINS.cenomispace)) {
    return cenomispaceTheme
  } else if (currentAppUrl.includes(DOMAINS.oneviu)) {
    return oneviuTheme
  } else if (currentAppUrl.includes(DOMAINS.nexus)) {
    return nexusTheme
  }
}
