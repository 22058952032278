import React, { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import MultiSelectBox from '../../../../../../../../features/components/Form/MultiSelectBox'

import { COUNTRY, COUNTRY_OR_REGION, CUSTOM_LOCATIONS } from '../../../../fields'
import { LOCATION_LIST } from '../../../../../../../ReusableFormFields/GeolocationsFields/fields'
import { capitalizeFirstLetter, formatStringWithUnderscore } from '../../../../../../../../helpers/common'

import { getChoicesGeolocations } from '../../../../../../../../modules/actions/choices'
import {
  choicesGeolocationsLoadingSelector,
  choicesGeolocationsSelector
} from '../../../../../../../../modules/selectors/choices'

const GeolocationField = ({ formik }) => {
  const dispatch = useDispatch()
  const geolocations = useSelector(choicesGeolocationsSelector)
  const geolocationsLoading = useSelector(choicesGeolocationsLoadingSelector)

  const { values, setFieldValue } = formik

  const formattedGeolocations =
    geolocations &&
    geolocations.map(({ key, name, region, type, region_id, country_code }) => {
      // include region name if it is exist in data
      const itemRegion = region ? `, ${region}` : ''

      // show country code label for country type options
      const countryCode = type === COUNTRY ? ` (${country_code})` : ''

      return {
        value: key,
        label: name + itemRegion + countryCode,
        type,
        region_id,
        description: capitalizeFirstLetter(formatStringWithUnderscore(type))
      }
    })

  const handleGetNewGeolocations = useCallback(
    searchValue => {
      if (searchValue) {
        dispatch(getChoicesGeolocations({ search: searchValue }))
      }
    },
    [dispatch]
  )

  const hasLocationList = !!values[CUSTOM_LOCATIONS]?.length || !!values[LOCATION_LIST]

  return (
    <MultiSelectBox
      placeholder="Add a country or region"
      name={COUNTRY_OR_REGION}
      setFieldValue={setFieldValue}
      options={formattedGeolocations}
      value={values[COUNTRY_OR_REGION]}
      isLoading={geolocationsLoading}
      onSearch={handleGetNewGeolocations}
      // it's possible to select either location list or add country/regions manually
      isDisabled={hasLocationList}
    />
  )
}

export default GeolocationField
