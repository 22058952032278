import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

// Get media order suggested changes

export const mediaOrderSuggestedChangesSelector = createSelector(
  ({ mediaOrderOptimisations }) => mediaOrderOptimisations.mediaOrderSuggestedChanges.data,
  data => data
)

export const mediaOrderSuggestedChangesIsLoadingSelector = createSelector(
  ({ mediaOrderOptimisations }) => mediaOrderOptimisations.mediaOrderSuggestedChanges.isLoading,
  isLoading => isLoading
)

export const mediaOrderSuggestedChangesErrorSelector = createSelector(
  ({ mediaOrderOptimisations }) => mediaOrderOptimisations.mediaOrderSuggestedChanges.error,
  error => error
)

export const mediaOrderSuggestedChangesWasLoadedSelector = createSelector(
  ({ mediaOrderOptimisations }) => mediaOrderOptimisations.mediaOrderSuggestedChanges.wasLoaded,
  wasLoaded => wasLoaded
)

// Apply media order suggested changes
export const applyMediaOrderSuggestedChangesIsLoadingSelector = createSelector(
  ({ mediaOrderOptimisations }) => mediaOrderOptimisations.applyMediaOrderSuggestedChanges.isLoading,
  isLoading => isLoading
)

export const applyMediaOrderSuggestedChangesErrorSelector = createSelector(
  ({ mediaOrderOptimisations }) => mediaOrderOptimisations.applyMediaOrderSuggestedChanges.error,
  error => error
)

export const applyMediaOrderSuggestedChangesWasCreatedSelector = createSelector(
  ({ mediaOrderOptimisations }) => mediaOrderOptimisations.applyMediaOrderSuggestedChanges.wasCreated,
  wasCreated => wasCreated
)

// Get optimisation history
export const optimisationHistorySelector = createSelector(
  ({ mediaOrderOptimisations }) => mediaOrderOptimisations.optimisationHistory.results,
  results => results
)

export const optimisationHistoryIsLoadingSelector = createSelector(
  ({ mediaOrderOptimisations }) => mediaOrderOptimisations.optimisationHistory.isLoading,
  isLoading => isLoading
)

export const optimisationHistoryErrorSelector = createSelector(
  ({ mediaOrderOptimisations }) => mediaOrderOptimisations.optimisationHistory.error,
  error => error
)

export const optimisationHistoryWasLoadedSelector = createSelector(
  ({ mediaOrderOptimisations }) => mediaOrderOptimisations.optimisationHistory.wasLoaded,
  wasLoaded => wasLoaded
)

export const optimisationHistoryNextSelector = createSelector(
  ({ mediaOrderOptimisations }) => mediaOrderOptimisations.optimisationHistory.paging.next,
  next => next
)
