import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { getIn } from 'formik'

import ProductsSelectionList from '../../../../../../../../../features/components/ProductsSelectionList'
import ProductsFilters from '../ProductsFilters'

import { getAllPackageProducts, getAvailableProducts, getProductsPath } from '../../../helpers/products'

import { productsSelector } from '../../../../../../../../../modules/selectors/mediaOrdersProducts'
import { SELECTED_PACKAGES } from '../../../fields'
import { getInitialProductSetup } from '../../../helpers'

// multi-category products component
function ProductSelectionContent({
  formik,
  currency,
  loadMoreProducts,
  packageIndex,
  handleStepSubmit,
  selectedLocationId,
  stopProductSelection,
  categoryName,
  filters
}) {
  const { values, setFieldValue } = formik

  const products = useSelector(productsSelector)

  const selectedProducts = useMemo(() => {
    if (categoryName) {
      // category selected products
      return getIn(values, `${SELECTED_PACKAGES}[${packageIndex}].${getProductsPath(categoryName)}`)
    } else {
      // all selected products
      const selectedPackage = getIn(values, `${SELECTED_PACKAGES}[${packageIndex}]`)
      return getAllPackageProducts(selectedPackage)
    }
  }, [categoryName, packageIndex, values])

  const availableProducts = useMemo(() => {
    return getAvailableProducts({
      products,
      selectedProducts,
      productCategory: categoryName
    })
  }, [products, selectedProducts, categoryName])

  const onProductSelect = useCallback(
    product => {
      const categorySelectedProductsName = `${SELECTED_PACKAGES}[${packageIndex}].${getProductsPath(product.media_category)}`
      const categorySelectedProducts = getIn(values, categorySelectedProductsName)

      setFieldValue(
        categorySelectedProductsName,
        categorySelectedProducts.concat({ isSetupAllowed: true, ...getInitialProductSetup(product) })
      )
      stopProductSelection()
      handleStepSubmit()
    },
    [handleStepSubmit, values, packageIndex, setFieldValue, stopProductSelection]
  )

  return (
    <>
      <ProductsFilters filters={filters} productsCategory={categoryName} />
      <ProductsSelectionList
        currency={currency}
        availableProducts={availableProducts}
        onProductSelect={onProductSelect}
        loadMoreProducts={loadMoreProducts}
        selectedLocationId={selectedLocationId}
      />
    </>
  )
}

ProductSelectionContent.propTypes = {
  formik: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  loadMoreProducts: PropTypes.func,
  packageIndex: PropTypes.number.isRequired,
  selectedLocationId: PropTypes.number,
  handleStepSubmit: PropTypes.func.isRequired,
  stopProductSelection: PropTypes.func.isRequired,
  categoryName: PropTypes.string,
  filters: PropTypes.object.isRequired
}
export default ProductSelectionContent
