import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

import FormWrapper from '../../../../features/components/Form/FormWrapper'
import CampaignForm from './CampaignForm'
import LineItemForm from './LineItemForm'
import AudiencesForm from './AudiencesForm'
import AdForm from './AdForm'
import useRedirectFormDrawer from '../../../../features/components/FormDrawerWrapper/useRedirectFormDrawer'

import useFormProgressItems from './useFormProgressItems'

import { ROUTES } from '../../../../constants/routes'
import { MODAL_TYPE } from '../../../../constants/other'
import { mediaOrderUploadCreativeDataSelector } from '../../../../modules/selectors/mediaOrders'

export const CAMPAIGN_FORM = 'campaign_form'
export const LINE_ITEM_FORM = 'line_item_form'
export const AUDIENCES_FORM = 'audiences_form'
export const AD_FORM = 'ad_form'

const UploadDv360AdCreativeContent = ({ adAccountId, setCreatedCreativeData, manageStepFormRoutes }) => {
  const { campaign, lineItem } = useSelector(mediaOrderUploadCreativeDataSelector)

  const { id: lineItemId } = lineItem
  const { id: campaignId } = campaign

  const { selectedRoute, handleSelectRoute } = manageStepFormRoutes

  const formProgressItems = useFormProgressItems({
    activatedForm: selectedRoute
  })

  const onSuccessCampaignFormSubmit = useCallback(
    selectedRoute => {
      handleSelectRoute(selectedRoute)
    },
    [handleSelectRoute]
  )

  const handleSuccessRedirect = useRedirectFormDrawer(
    `${ROUTES.mediaOrderSummaryOffNetworkDigital}?message=${MODAL_TYPE.mediaOrderDv360CreativeUploaded}`
  )

  const onSuccessAdFormSubmit = useCallback(() => {
    setCreatedCreativeData({
      adAccountId,
      campaignId,
      lineItemId
    })
    // close form and redirect to modals
    handleSuccessRedirect()
  }, [handleSuccessRedirect, setCreatedCreativeData, adAccountId, campaignId, lineItemId])

  const getActivatedForm = useCallback(() => {
    switch (selectedRoute) {
      case AD_FORM:
        return <AdForm onSuccessSubmit={onSuccessAdFormSubmit} />
      case AUDIENCES_FORM:
        return (
          <AudiencesForm
            onSuccessSubmit={() => {
              onSuccessCampaignFormSubmit(AD_FORM)
            }}
          />
        )
      case LINE_ITEM_FORM:
        return (
          <LineItemForm
            onSuccessSubmit={() => {
              onSuccessCampaignFormSubmit(AUDIENCES_FORM)
            }}
          />
        )
      case CAMPAIGN_FORM:
      default:
        return (
          <CampaignForm
            onSuccessSubmit={() => {
              onSuccessCampaignFormSubmit(LINE_ITEM_FORM)
            }}
          />
        )
    }
  }, [selectedRoute, onSuccessCampaignFormSubmit, onSuccessAdFormSubmit])

  return <FormWrapper formProgressItems={formProgressItems}>{getActivatedForm()}</FormWrapper>
}

export default UploadDv360AdCreativeContent
