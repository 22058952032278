import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import AdCardMetricContainer from '../../../../../../../../features/components/AdCardListItem/AdCardMetrics/AdCardMetricContainer'

import { renderMetricValue } from '../../../../../../../../features/components/AdCardListItem/AdCardMetrics/AdCardMetricContainer/renderMetricValue'
import { formatDigitalItemValues } from '../../../../../../../../features/formatters'

import useStyles from '../AdCardActions/styles'

const AdCardMetrics = ({ ad }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const formattedData = formatDigitalItemValues(ad, { symbol: ad['currency_symbo'] })
  const { impressions, cpm, purchase, roas, clicks, ctr, active_view_impressions, cpc, spend } = formattedData

  return (
    <div>
      <AdCardMetricContainer>
        <div>
          {t('Impressions')}
          <span className={classes.metricAdditional}> ({t('CPM')})</span>
        </div>
        <div>
          {renderMetricValue(impressions)}
          <span className={classes.metricAdditional}> ({renderMetricValue(cpm)})</span>
        </div>
      </AdCardMetricContainer>

      <AdCardMetricContainer>
        <div>{t('Viewable Impr.')}</div>
        <div>{renderMetricValue(active_view_impressions)} </div>
      </AdCardMetricContainer>

      <AdCardMetricContainer>
        <div>
          {t('Clicks')} <span className={classes.metricAdditional}>({t('CTR')})</span>
        </div>
        <div>
          {renderMetricValue(clicks)} <span className={classes.metricAdditional}>({renderMetricValue(ctr)})</span>
        </div>
      </AdCardMetricContainer>
      <AdCardMetricContainer>
        <div>{t('CPC')}</div>
        <div>{renderMetricValue(cpc)} </div>
      </AdCardMetricContainer>
      <AdCardMetricContainer>
        <div>
          {t('Purchases')} <span className={classes.metricAdditional}>({t('ROAS')})</span>
        </div>
        <div>
          {renderMetricValue(purchase)} <span className={classes.metricAdditional}>({renderMetricValue(roas)})</span>
        </div>
      </AdCardMetricContainer>
      <AdCardMetricContainer>
        <div>{t('Spend')}</div>
        <div>{renderMetricValue(spend)} </div>
      </AdCardMetricContainer>
    </div>
  )
}

AdCardMetrics.propTypes = {
  ad: PropTypes.object.isRequired
}

export default AdCardMetrics
