import React from 'react'
import ModalComponent from 'react-modal'
import classnames from 'classnames'

import useStyles from './styles'

ModalComponent.setAppElement('#root')

function Modal({ showHeader, className, ...modalProps }) {
  const classes = useStyles(showHeader)

  return (
    <ModalComponent
      className={classnames(classes.modal, className)}
      overlayClassName={classes.overlay}
      {...modalProps}
    />
  )
}

export default Modal
