import { createStyles } from '../../../styles/helpers'

import { mediumGrey } from '../../../styles/const/colors'

export default createStyles(theme => ({
  root: {
    position: 'relative',
    maxWidth: '1340px',
    margin: '0 auto'
  },
  isLoading: {
    pointerEvents: 'none'
  },
  tableContainer: {
    backgroundColor: 'white',
    border: {
      width: '1px',
      style: 'solid',
      color: mediumGrey
    },
    borderRadius: theme.bigBorderRadius,
    display: 'table',
    width: '100%',
    marginBottom: '32px',
    tableLayout: 'fixed',
    borderSpacing: '0px'
  }
}))
