import * as Yup from 'yup'

import { END_DATE, MEDIA_CATEGORY, PRICE_CHANGE, START_DATE } from './fields'

export const validationSchema = Yup.object({
  [START_DATE]: Yup.string().required('Start Date required'),
  [END_DATE]: Yup.string().required('End Date required'),
  [MEDIA_CATEGORY]: Yup.string().required('Media Category required'),
  [PRICE_CHANGE]: Yup.string().required('Price Change required')
})
