import { concat } from '../../helpers/common'

const MODULE_NAME = 'PLANNER/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_CALENDARS = concat(MODULE_NAME, 'GET_CALENDARS')
export const GET_CALENDARS_SUCCESS = concat(MODULE_NAME, 'GET_CALENDARS_SUCCESS')
export const GET_CALENDARS_FAILURE = concat(MODULE_NAME, 'GET_CALENDARS_FAILURE')

export const CREATE_CALENDAR = concat(MODULE_NAME, 'CREATE_CALENDAR')
export const CREATE_CALENDAR_SUCCESS = concat(MODULE_NAME, 'CREATE_CALENDAR_SUCCESS')
export const CREATE_CALENDAR_FAILURE = concat(MODULE_NAME, 'CREATE_CALENDAR_FAILURE')

export const GET_CALENDAR = concat(MODULE_NAME, 'GET_CALENDAR')
export const GET_CALENDAR_SUCCESS = concat(MODULE_NAME, 'GET_CALENDAR_SUCCESS')
export const GET_CALENDAR_FAILURE = concat(MODULE_NAME, 'GET_CALENDAR_FAILURE')

export const UPDATE_CALENDAR = concat(MODULE_NAME, 'UPDATE_CALENDAR')
export const UPDATE_CALENDAR_SUCCESS = concat(MODULE_NAME, 'UPDATE_CALENDAR_SUCCESS')
export const UPDATE_CALENDAR_FAILURE = concat(MODULE_NAME, 'UPDATE_CALENDAR_FAILURE')

export const GET_EVENT = concat(MODULE_NAME, 'GET_EVENT')
export const GET_EVENT_SUCCESS = concat(MODULE_NAME, 'GET_EVENT_SUCCESS')
export const GET_EVENT_FAILURE = concat(MODULE_NAME, 'GET_EVENT_FAILURE')
export const CLEAR_EVENT_DATA = concat(MODULE_NAME, 'CLEAR_EVENT_DATA')

export const CREATE_EVENT = concat(MODULE_NAME, 'CREATE_EVENT')
export const CREATE_EVENT_SUCCESS = concat(MODULE_NAME, 'CREATE_EVENT_SUCCESS')
export const CREATE_EVENT_FAILURE = concat(MODULE_NAME, 'CREATE_EVENT_FAILURE')
export const CLEAR_CREATE_EVENT = concat(MODULE_NAME, 'CLEAR_CREATE_EVENT')

export const UPDATE_EVENT = concat(MODULE_NAME, 'UPDATE_EVENT')
export const UPDATE_EVENT_SUCCESS = concat(MODULE_NAME, 'UPDATE_EVENT_SUCCESS')
export const UPDATE_NEW_EVENTS_GROUP_EVENT_SUCCESS = concat(MODULE_NAME, 'UPDATE_NEW_EVENTS_GROUP_EVENT_SUCCESS')
export const UPDATE_EVENT_FAILURE = concat(MODULE_NAME, 'UPDATE_EVENT_FAILURE')

export const UPDATE_EVENT_PARTIAL = concat(MODULE_NAME, 'UPDATE_EVENT_PARTIAL')
export const UPDATE_EVENT_PARTIAL_SUCCESS = concat(MODULE_NAME, 'UPDATE_EVENT_PARTIAL_SUCCESS')
export const UPDATE_EVENT_PARTIAL_FAILURE = concat(MODULE_NAME, 'UPDATE_EVENT_PARTIAL_FAILURE')
export const CLEAR_UPDATE_EVENT = concat(MODULE_NAME, 'CLEAR_UPDATE_EVENT')

export const DELETE_EVENT = concat(MODULE_NAME, 'DELETE_EVENT')
export const DELETE_EVENT_SUCCESS = concat(MODULE_NAME, 'DELETE_EVENT_SUCCESS')
export const DELETE_EVENT_FAILURE = concat(MODULE_NAME, 'DELETE_EVENT_FAILURE')

export const CREATE_EVENT_GROUP = concat(MODULE_NAME, 'CREATE_EVENT_GROUP')
export const CREATE_EVENT_GROUP_SUCCESS = concat(MODULE_NAME, 'CREATE_EVENT_GROUP_SUCCESS')
export const CREATE_EVENT_GROUP_FAILURE = concat(MODULE_NAME, 'CREATE_EVENT_GROUP_FAILURE')
export const CLEAR_CREATE_EVENT_GROUP = concat(MODULE_NAME, 'CLEAR_CREATE_EVENT_GROUP')

export const UPDATE_EVENT_GROUP = concat(MODULE_NAME, 'UPDATE_EVENT_GROUP')
export const UPDATE_EVENT_GROUP_SUCCESS = concat(MODULE_NAME, 'UPDATE_EVENT_GROUP_SUCCESS')
export const UPDATE_EVENT_GROUP_FAILURE = concat(MODULE_NAME, 'UPDATE_EVENT_GROUP_FAILURE')
export const CLEAR_UPDATE_EVENT_GROUP = concat(MODULE_NAME, 'CLEAR_UPDATE_EVENT_GROUP')

export const UPDATE_EVENT_GROUP_ORDER = concat(MODULE_NAME, 'UPDATE_EVENT_GROUP_ORDER')
export const UPDATE_EVENT_GROUP_ORDER_SUCCESS = concat(MODULE_NAME, 'UPDATE_EVENT_GROUP_ORDER_SUCCESS')
export const UPDATE_EVENT_GROUP_ORDER_FAILURE = concat(MODULE_NAME, 'UPDATE_EVENT_GROUP_ORDER_FAILURE')

export const DELETE_EVENT_GROUP = concat(MODULE_NAME, 'DELETE_EVENT_GROUP')
export const DELETE_EVENT_GROUP_SUCCESS = concat(MODULE_NAME, 'DELETE_EVENT_GROUP_SUCCESS')
export const DELETE_EVENT_GROUP_FAILURE = concat(MODULE_NAME, 'DELETE_EVENT_GROUP_FAILURE')
export const CLEAR_DELETE_EVENT_GROUP = concat(MODULE_NAME, 'CLEAR_DELETE_EVENT_GROUP')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

export function getCalendars(params) {
  return { type: GET_CALENDARS, params }
}
export function getCalendarsSuccess(calendarsList) {
  return { type: GET_CALENDARS_SUCCESS, calendarsList }
}
export function getCalendarsFailure(error) {
  return { type: GET_CALENDARS_FAILURE, error }
}

export function createCalendar(calendarData) {
  return { type: CREATE_CALENDAR, calendarData }
}
export function createCalendarSuccess(calendarData) {
  return { type: CREATE_CALENDAR_SUCCESS, calendarData }
}
export function createCalendarFailure(error) {
  return { type: CREATE_CALENDAR_FAILURE, error }
}

export function getCalendar(calendarId) {
  return { type: GET_CALENDAR, calendarId }
}
export function getCalendarSuccess(calendarData) {
  return { type: GET_CALENDAR_SUCCESS, calendarData }
}
export function getCalendarFailure(error) {
  return { type: GET_CALENDAR_FAILURE, error }
}

export function updateCalendar(calendarData) {
  return { type: UPDATE_CALENDAR, calendarData }
}
export function updateCalendarSuccess(newCalendarData) {
  return { type: UPDATE_CALENDAR_SUCCESS, newCalendarData }
}
export function updateCalendarFailure(error) {
  return { type: UPDATE_CALENDAR_FAILURE, error }
}
/* EVENT START */
export function getEvent(eventId) {
  return { type: GET_EVENT, eventId }
}
export function getEventSuccess(eventData) {
  return { type: GET_EVENT_SUCCESS, eventData }
}
export function getEventFailure(error) {
  return { type: GET_EVENT_FAILURE, error }
}
export function clearEventData() {
  return { type: CLEAR_EVENT_DATA }
}

export function createEvent(eventData) {
  return { type: CREATE_EVENT, eventData }
}
export function createEventSuccess(newEventData) {
  return { type: CREATE_EVENT_SUCCESS, newEventData }
}
export function createEventFailure(error) {
  return { type: CREATE_EVENT_FAILURE, error }
}
export function clearCreateEvent() {
  return { type: CLEAR_CREATE_EVENT }
}

export function updateEvent(eventData, oldEventGroupId) {
  return { type: UPDATE_EVENT, eventData, oldEventGroupId }
}
export function updateEventSuccess(updatedEventData) {
  return { type: UPDATE_EVENT_SUCCESS, updatedEventData }
}
export function updateEventWithNewEventGroupSuccess(updatedEventData, oldEventGroupId) {
  // if Event's events_group was changed to new one the different update flow should be applied
  // to avoid complicated logic in reducer there were created 2 different update success actions
  return { type: UPDATE_NEW_EVENTS_GROUP_EVENT_SUCCESS, updatedEventData, oldEventGroupId }
}
export function updateEventFailure(error) {
  return { type: UPDATE_EVENT_FAILURE, error }
}

export function updatePatchEvent(eventData) {
  return { type: UPDATE_EVENT_PARTIAL, eventData }
}
export function updatePatchEventSuccess(updatedEventData) {
  return { type: UPDATE_EVENT_PARTIAL_SUCCESS, updatedEventData }
}
export function updatePatchEventFailure(error) {
  return { type: UPDATE_EVENT_PARTIAL_FAILURE, error }
}
export function clearUpdateEvent() {
  return { type: CLEAR_UPDATE_EVENT }
}

export function deleteEvent(eventId, eventGroupId) {
  return { type: DELETE_EVENT, eventId, eventGroupId }
}
export function deleteEventSuccess(eventId, eventGroupId) {
  return { type: DELETE_EVENT_SUCCESS, eventId, eventGroupId }
}
export function deleteEventFailure(error) {
  return { type: DELETE_EVENT_FAILURE, error }
}

/* EVENT GROUPS START */
export function createEventGroup(eventGroupData) {
  return { type: CREATE_EVENT_GROUP, eventGroupData }
}
export function createEventGroupSuccess(newEventGroupData) {
  return { type: CREATE_EVENT_GROUP_SUCCESS, newEventGroupData }
}
export function createEventGroupFailure(error) {
  return { type: CREATE_EVENT_GROUP_FAILURE, error }
}
export function clearCreateEventGroup() {
  return { type: CLEAR_CREATE_EVENT_GROUP }
}

export function updateEventGroup(editId, eventGroupData) {
  return { type: UPDATE_EVENT_GROUP, editId, eventGroupData }
}
export function updateEventGroupSuccess(eventGroupData) {
  return { type: UPDATE_EVENT_GROUP_SUCCESS, eventGroupData }
}
export function updateEventGroupFailure(error) {
  return { type: UPDATE_EVENT_GROUP_FAILURE, error }
}
export function clearUpdateEventGroup() {
  return { type: CLEAR_UPDATE_EVENT_GROUP }
}

export function updateEventGroupsOrder(eventGroupOrderData) {
  return { type: UPDATE_EVENT_GROUP_ORDER, eventGroupOrderData }
}
export function updateEventGroupsOrderSuccess(eventGroupData) {
  return { type: UPDATE_EVENT_GROUP_ORDER_SUCCESS, eventGroupData }
}
export function updateEventGroupsOrderFailure(error) {
  return { type: UPDATE_EVENT_GROUP_ORDER_FAILURE, error }
}

export function deleteEventGroup(id) {
  return { type: DELETE_EVENT_GROUP, id }
}
export function deleteEventGroupSuccess(id) {
  return { type: DELETE_EVENT_GROUP_SUCCESS, id }
}
export function deleteEventGroupFailure(error) {
  return { type: DELETE_EVENT_GROUP_FAILURE, error }
}
export function clearDeleteEventGroup() {
  return { type: CLEAR_DELETE_EVENT_GROUP }
}

/* EVENT GROUPS  END */
