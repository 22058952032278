import { AD_FILE } from '../../../../Facebook/AdForms/fields'
import { ASSET_ID, FILE_NAME, FILE_URL, MEDIA_TYPE, STATUS } from '../../../../Google/AdForms/fields'
import { FILE_TYPE } from '../../../../../constants/product'
import {
  UPLOADED_MEDIA_TYPE,
  MEDIA_WIDTH as FILE_WIDTH,
  MEDIA_HEIGHT as FILE_HEIGHT
} from '../../../../ReusableFormFields/AdFileUpload/fields'
import { CLICK_THROUGH_LINK, MEDIA_HEIGHT_PIXELS, MEDIA_ITEMS, MEDIA_WIDTH_PIXELS } from './fields'

export const formatMediaFile = fileMediaValues => {
  return {
    [AD_FILE]: fileMediaValues[AD_FILE],
    [FILE_NAME]: fileMediaValues.file_name,
    [MEDIA_WIDTH_PIXELS]: fileMediaValues[FILE_WIDTH],
    [MEDIA_HEIGHT_PIXELS]: fileMediaValues[FILE_HEIGHT],
    [FILE_TYPE]: fileMediaValues[MEDIA_TYPE],
    [MEDIA_TYPE]: fileMediaValues[UPLOADED_MEDIA_TYPE]
  }
}

export const formatAdLink = linkValue => {
  return [
    {
      type: 'EXIT_EVENT_TYPE_DEFAULT',
      [CLICK_THROUGH_LINK]: linkValue,
      name: 'clickTag',
      reporting_name: 'clickTag'
    }
  ]
}

export const transformValuesToBE = ({
  values,
  campaignId,
  lineItemId,
  account,
  productsList,
  showProductSelection
}) => {
  // we always keep initial media file in the array to represent file uploading element on the UI
  // this initial media should be removed from the values to be sent to the BE
  const uploadedMedias = values[MEDIA_ITEMS].filter(ad => ad[ASSET_ID])

  const link = showProductSelection
    ? productsList.find(product => product.id === values[CLICK_THROUGH_LINK])?.link
    : values[CLICK_THROUGH_LINK]

  return {
    [MEDIA_ITEMS]: uploadedMedias.map(ad => ({
      name: ad[FILE_NAME],
      campaign_id: campaignId,
      line_item_id: lineItemId,
      account: account,
      [STATUS]: values[STATUS],
      dimensions: {
        [MEDIA_WIDTH_PIXELS]: ad[MEDIA_WIDTH_PIXELS],
        [MEDIA_HEIGHT_PIXELS]: ad[MEDIA_HEIGHT_PIXELS]
      },
      hosting_source: 'HOSTING_SOURCE_HOSTED',
      assets: [
        {
          asset: {
            media_id: ad[ASSET_ID],
            url: ad[FILE_URL]
          },
          role: 'ASSET_ROLE_MAIN'
        }
      ],
      exit_events: formatAdLink(link),
      type: 'CREATIVE_TYPE_STANDARD'
    }))
  }
}
