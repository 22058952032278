import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import EditForm from '../../../../../../features/components/Forms/EditForm'
import MultipleOptionsField from '../../../../../ReusableFormFields/MultipleOptionsField'
import { getInitialValuesByFormat } from '../Media/Edit/helpers'
import Field from '../../../../../../components/Form/Field'

import { getCreative } from './helpers'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import useEditAdPreview from '../../../../../../features/hooks/useEditAdPreview'

import { clearUpdateAd, updateAd } from '../../../../../../modules/actions/ads'

import {
  adUpdateErrorSelector,
  adUpdateIsLoadingSelector,
  adWasUpdatedSelector
} from '../../../../../../modules/selectors/ads'
import { lineItemSelector } from '../../../../../../modules/selectors/lineItems'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { IS_CUSTOMIZED_MEDIA, PRIMARY_TEXT_LIST } from '../../../fields'
import { primaryTextListValidation } from './validation'
import { primaryTextMaxLength } from '../../../validation'
import { FACEBOOK_PLATFORM } from '../../../../../../constants/selectLists/platformList'

const PrimaryTextForm = ({ editItemData: originalAd, adValues, adFormat, isInternalAd, ...formProps }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const lineItem = useSelector(lineItemSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const successUpdate = useSelector(adWasUpdatedSelector)

  const handleSubmit = useCallback(
    values => {
      const { id } = originalAd

      const updateAdData = {
        account: selectedAdAccountId,
        adset_id: lineItem.id,
        creative: getCreative(values, originalAd, adFormat),
        ...(isInternalAd && { internal: true })
      }
      dispatch(updateAd(updateAdData, id, 'PATCH', FACEBOOK_PLATFORM))
    },
    [dispatch, lineItem, originalAd, isInternalAd, selectedAdAccountId, adFormat]
  )

  const validationSchema = Yup.object(primaryTextListValidation)

  const initialValues = useMemo(
    () => ({
      ...getInitialValuesByFormat(adValues, adFormat)
    }),
    [adValues, adFormat]
  )

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)
  const { values } = purifiedFormik
  const allowMultiple = values[IS_CUSTOMIZED_MEDIA]
  useEditAdPreview(purifiedFormik.values)

  const handleClearAd = useCallback(() => {
    dispatch(clearUpdateAd())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={successUpdate}
      clearEditItem={handleClearAd}
      errorSelector={adUpdateErrorSelector}
      isLoadingSelector={adUpdateIsLoadingSelector}
      {...formProps}
    >
      {allowMultiple ? (
        <>
          <p>{t('If you add multiple options here, Facebook will determine the best one to use.')}</p>
          <MultipleOptionsField
            formik={purifiedFormik}
            fieldPlaceholder="Primary Text"
            listFieldName={PRIMARY_TEXT_LIST}
            fieldMaxLength={primaryTextMaxLength}
            maxListQuantity={5}
          />
        </>
      ) : (
        <Field
          placeholder={t('Primary text')}
          formik={formik}
          name={`${PRIMARY_TEXT_LIST}[0].text`}
          isTextarea
          maxLength={primaryTextMaxLength}
          enableReinitialize
        />
      )}
    </EditForm>
  )
}

PrimaryTextForm.propTypes = {
  editItemData: PropTypes.object,
  adValues: PropTypes.object,
  adFormat: PropTypes.string,
  isInternalAd: PropTypes.bool
}

export default PrimaryTextForm
