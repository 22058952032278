import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

export const agencyMembersSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyMembers.members.results,
  results => results
)

export const agencyMembersIsLoadingSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyMembers.members.isLoading,
  isLoading => isLoading
)

export const agencyMembersWasLoadedSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyMembers.members.wasLoaded,
  wasLoaded => wasLoaded
)

export const agencyMembersErrorSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyMembers.members.error,
  error => error
)

export const agencyInvitesSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyInvites.invites.results,
  results => results
)

export const agencyInvitesIsLoadingSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyInvites.invites.isLoading,
  isLoading => isLoading
)

export const agencyInvitesWasLoadedSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyInvites.invites.wasLoaded,
  wasLoaded => wasLoaded
)

export const agencyInvitesErrorSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyInvites.invites.error,
  error => error
)

export const agencyInviteCreateIsLoadingSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyInvites.createdInvite.isLoading,
  isLoading => isLoading
)

export const agencyInviteWasCreatedSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyInvites.createdInvite.wasCreated,
  wasCreated => wasCreated
)

export const reinvitedAgencyUserSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyInvites.reinvitedUser,
  reinvitedUser => reinvitedUser
)

export const deletedAgencyInvitationSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyInvites.deletedInvite,
  deletedInvite => deletedInvite
)

export const deleteAgencyInvitationIsLoadingSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyInvites.deletedInvite.isLoading,
  isLoading => isLoading
)

export const agencyInviteCreateErrorSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyInvites.createdInvite.error,
  error => error
)

export const updatedAgencyMemberSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyMembers.updatedMember,
  updatedMember => updatedMember
)

export const agencyMemberWasUpdatedSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyMembers.updatedMember.wasUpdated,
  wasUpdated => wasUpdated
)

export const updateAgencyMemberIsLoadingSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyMembers.updatedMember.isLoading,
  isLoading => isLoading
)

export const updateAgencyMemberErrorSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyMembers.updatedMember.error,
  error => error
)

export const acceptedAgencyInviteIsLoadingSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyInvites.acceptedInvite.isLoading,
  isLoading => isLoading
)

export const acceptedAgencyInviteErrorSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyInvites.acceptedInvite.error,
  error => error
)

export const agencyInviteWasAcceptedSelector = createSelector(
  ({ agencyTeam }) => agencyTeam.agencyInvites.acceptedInvite.wasAccepted,
  wasAccepted => wasAccepted
)
