import { createStyles } from '../../../styles/helpers'
import { mediumGrey } from '../../../styles/const/colors'

export default createStyles({
  sectionTitle: {
    fontWeight: 600,
    marginBottom: 16
  },
  brandPagesSection: {
    marginBottom: 32
  },
  productsSection: {
    paddingTop: 32,
    borderTop: `1px solid ${mediumGrey}`
  }
})
