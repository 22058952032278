import React from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { getAppDomainName } from '../../../../../features/helpers/getAppDomainName'

import FieldLabelWrapper from '../../../../../features/components/Form/FieldLabelWrapper'
import Field from '../../../../../components/Form/Field'

import { USER_FIRST_NAME, USER_LAST_NAME, USER_PASSWORD } from '../../fields'

import useDrawerFormsStyles from '../../../../../styles/common/drawerForms'
import useAuthStyles from '../../../../../styles/common/authPages'

const RegisterFields = ({ formik }) => {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormsStyles()
  const authClasses = useAuthStyles()

  const appName = getAppDomainName()

  return (
    <section className={classnames(drawerFormClasses.section, authClasses.section)}>
      <h3 className={drawerFormClasses.sectionTitle}>{t('Let’s get you registered')}.</h3>
      <p>{t('RegisterPage.invitationText', { appName })}</p>
      <div className={authClasses.formRow}>
        <FieldLabelWrapper label={'First name'} labelFor={USER_FIRST_NAME}>
          <Field
            formik={formik}
            id={USER_FIRST_NAME}
            name={USER_FIRST_NAME}
            placeholder="First name"
            autoComplete="off"
          />
        </FieldLabelWrapper>
        <FieldLabelWrapper label={'Last name'} labelFor={USER_LAST_NAME}>
          <Field formik={formik} id={USER_LAST_NAME} name={USER_LAST_NAME} placeholder="Last name" autoComplete="off" />
        </FieldLabelWrapper>
      </div>
      <FieldLabelWrapper label={'Password'} labelFor={USER_PASSWORD}>
        <Field
          formik={formik}
          type="password"
          id={USER_PASSWORD}
          name={USER_PASSWORD}
          placeholder="New Password"
          autoComplete="new-password"
        />
      </FieldLabelWrapper>
      <p className={authClasses.description}>
        {t(
          'Passwords must contain at least 8 characters, one uppercase, one lowercase, one number and one special character'
        )}
      </p>
    </section>
  )
}

export default RegisterFields
