import update from 'immutability-helper'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_TIK_TOK_VIDEOS,
  GET_TIK_TOK_VIDEOS_SUCCESS,
  GET_TIK_TOK_VIDEOS_FAILURE,
  CLEAR_TIK_TOK_VIDEOS,
  GET_FACEBOOK_IMAGES,
  GET_FACEBOOK_IMAGES_SUCCESS,
  GET_FACEBOOK_IMAGES_FAILURE,
  CLEAR_FACEBOOK_IMAGES,
  GET_FACEBOOK_VIDEOS,
  GET_FACEBOOK_VIDEOS_FAILURE,
  GET_FACEBOOK_VIDEOS_SUCCESS,
  CLEAR_FACEBOOK_VIDEOS,
  GET_FACEBOOK_VIDEO,
  GET_FACEBOOK_VIDEO_SUCCESS,
  GET_FACEBOOK_VIDEO_FAILURE,
  CLEAR_FACEBOOK_VIDEO,
  GET_FACEBOOK_VIDEO_URLS,
  GET_FACEBOOK_VIDEO_URLS_SUCCESS,
  GET_FACEBOOK_VIDEO_URLS_FAILURE,
  CLEAR_FACEBOOK_VIDEO_URLS,
  GET_FACEBOOK_IMAGE_URLS,
  GET_FACEBOOK_IMAGE_URLS_SUCCESS,
  GET_FACEBOOK_IMAGE_URLS_FAILURE,
  CLEAR_FACEBOOK_IMAGE_URLS
} from '../actions/mediaFiles'
import { INITIAL_GET_RESULTS_PAGING_REDUCER, INITIAL_GET_RESULTS_REDUCER } from '../../constants/reducer'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  tikTokVideos: INITIAL_GET_RESULTS_PAGING_REDUCER,
  // IMAGES
  images: {
    ...INITIAL_GET_RESULTS_REDUCER,
    paging: {
      cursors: {
        before: '',
        after: ''
      },
      previous: false,
      next: false
    }
  },

  // VIDEO PREVIEWS
  videoPreviews: {
    ...INITIAL_GET_RESULTS_REDUCER,
    paging: {
      cursors: {
        before: '',
        after: ''
      },
      previous: false,
      next: false
    }
  },

  // VIDEO
  video: {
    isLoading: false,
    data: {}
  },

  // VIDEO URLS
  videoUrls: {
    ...INITIAL_GET_RESULTS_REDUCER,
    requestedIds: []
  },

  // IMAGE URLS
  imageUrls: {
    ...INITIAL_GET_RESULTS_REDUCER,
    requestedHashes: []
  }
}

export default function mediaFiles(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // Get tik tok videos
    case GET_TIK_TOK_VIDEOS:
      return update(state, {
        tikTokVideos: { $merge: { isLoading: true } }
      })
    case GET_TIK_TOK_VIDEOS_SUCCESS:
      return update(state, {
        tikTokVideos: {
          results: {
            $push: action.tikTokVideosData.data
          },
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: action.tikTokVideosData.paging
          }
        }
      })
    case GET_TIK_TOK_VIDEOS_FAILURE:
      return update(state, {
        tikTokVideos: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_TIK_TOK_VIDEOS:
      return update(state, {
        tikTokVideos: { $set: initialState.tikTokVideos }
      })
    // IMAGES
    case GET_FACEBOOK_IMAGES:
      return update(state, {
        images: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_FACEBOOK_IMAGES_SUCCESS:
      return update(state, {
        images: {
          results: {
            $push: action.mediaFilesData.data
          },
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: action.mediaFilesData.paging
          }
        }
      })
    case GET_FACEBOOK_IMAGES_FAILURE:
      return update(state, {
        images: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_FACEBOOK_IMAGES:
      return update(state, {
        images: {
          $set: initialState.images
        }
      })
    // VIDEO PREVIEWS
    case GET_FACEBOOK_VIDEOS:
      return update(state, {
        videoPreviews: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_FACEBOOK_VIDEOS_SUCCESS:
      return update(state, {
        videoPreviews: {
          results: {
            $push: action.mediaFilesData.data
          },
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: action.mediaFilesData.paging
          }
        }
      })
    case GET_FACEBOOK_VIDEOS_FAILURE:
      return update(state, {
        images: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_FACEBOOK_VIDEOS:
      return update(state, {
        videoPreviews: {
          $set: initialState.videoPreviews
        }
      })
    case GET_FACEBOOK_VIDEO:
      return update(state, {
        video: {
          isLoading: { $set: true }
        }
      })
    case GET_FACEBOOK_VIDEO_SUCCESS:
      return update(state, {
        video: {
          isLoading: { $set: false },
          data: { $set: action.mediaFilesData }
        }
      })
    case GET_FACEBOOK_VIDEO_FAILURE:
      return update(state, {
        video: {
          isLoading: { $set: false },
          data: { $set: initialState.video.data }
        }
      })
    case CLEAR_FACEBOOK_VIDEO:
      return update(state, {
        video: {
          isLoading: { $set: false },
          data: { $set: initialState.video.data }
        }
      })
    case GET_FACEBOOK_VIDEO_URLS:
      return update(state, {
        videoUrls: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_FACEBOOK_VIDEO_URLS_SUCCESS:
      return update(state, {
        videoUrls: {
          results: {
            $push: action.videoUrls
          },
          requestedIds: {
            $push: action.requestedVideoIds
          },
          $merge: {
            isLoading: false,
            wasLoaded: true
          }
        }
      })
    case GET_FACEBOOK_VIDEO_URLS_FAILURE:
      return update(state, {
        videoUrls: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_FACEBOOK_VIDEO_URLS:
      return update(state, {
        videoUrls: {
          $set: initialState.videoUrls
        }
      })
    case GET_FACEBOOK_IMAGE_URLS:
      return update(state, {
        imageUrls: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_FACEBOOK_IMAGE_URLS_SUCCESS:
      return update(state, {
        imageUrls: {
          results: {
            $push: action.imageUrls
          },
          requestedHashes: {
            $push: action.requestedImageHashes
          },
          $merge: {
            isLoading: false,
            wasLoaded: true
          }
        }
      })
    case GET_FACEBOOK_IMAGE_URLS_FAILURE:
      return update(state, {
        imageUrls: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_FACEBOOK_IMAGE_URLS:
      return update(state, {
        imageUrls: {
          $set: initialState.imageUrls
        }
      })
    default:
      return state
  }
}
