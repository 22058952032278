import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'

import StepForm from '../../../../../features/components/Forms/StepForm'
import AdCreativeDetailsStep from '../../ReusableSteps/AdCreativeDetailsStep'
import ProductsStep from './Steps/ProductsStep'

import useGoogleAssetGroupCreate from './hooks/useGoogleAssetGroupCreate'
import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'
import { useShowProductOrPageSelectionStep } from '../../../../Facebook/AdForms/AdFacebookCreate/AdFacebookCreateForm/hooks/useShowProductOrPageSelectionStep'

import { campaignLoadingSelector } from '../../../../../modules/selectors/campaigns'
import { assetGroupCreateDataSelector } from '../../../../../modules/selectors/assets'

import { ASSET_GROUP_GOOGLE_ADS_CREATE } from '../../../../../constants/forms'
import { initialValues } from './fields'
import { getValidationSchema } from './validation'

const GoogleAssetGroupMainForm = ({ onSuccessSubmit, isInternalAssetGroupCreation, adAccountId, formProps }) => {
  const isCampaignLoading = useSelector(campaignLoadingSelector)
  const assetGroupCreatedData = useSelector(assetGroupCreateDataSelector)

  const { onSubmit, onClearSubmit, isLoadingSelector, errorSelector, successSubmit } = useGoogleAssetGroupCreate({
    isInternalAssetGroupCreation,
    adAccountId
  })

  const showProductSelectionStep = useShowProductOrPageSelectionStep()

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema({ showProductSelectionStep }),
    onSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const steps = useMemo(
    () => [
      {
        component: <ProductsStep formik={formik} />,
        show: showProductSelectionStep
      },
      {
        component: (
          <AdCreativeDetailsStep
            adAccountId={adAccountId}
            showImagesSection
            isAssetGroup
            showNameField={!showProductSelectionStep}
            showCustomClickThroughLink={!showProductSelectionStep}
          />
        ),
        show: true
      }
    ],
    [adAccountId, formik, showProductSelectionStep]
  )

  const successSubmitHandler = useCallback(() => {
    onSuccessSubmit(assetGroupCreatedData)
  }, [assetGroupCreatedData, onSuccessSubmit])

  return (
    <StepForm
      steps={steps}
      formik={purifiedFormik}
      formName={ASSET_GROUP_GOOGLE_ADS_CREATE}
      isLoadingSelector={isLoadingSelector}
      errorSelector={errorSelector}
      successSubmit={successSubmit}
      isInitialDataLoading={isCampaignLoading}
      clearSubmitHandler={onClearSubmit}
      onSuccessSubmit={successSubmitHandler}
      submitText={'Save and continue'}
      {...formProps}
    />
  )
}

export default GoogleAssetGroupMainForm
