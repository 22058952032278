import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { insertIf } from '../../../../../helpers/common'

import { openForm } from '../../../../../modules/actions/forms'
import { isUserControllerSelector } from '../../../../../modules/selectors/app'

import { SELF_ACCOUNT_PERMISSIONS_EDIT } from '../../../../../constants/forms'

export function useSelfAccountMemberActions({ membersLists }) {
  const dispatch = useDispatch()
  const isUserController = useSelector(isUserControllerSelector)

  const openEditSelfAccountPermissionsFormHandler = useCallback(
    (e, id) => {
      dispatch(openForm({ id, formName: SELF_ACCOUNT_PERMISSIONS_EDIT }))
    },
    [dispatch]
  )

  const selfAccountMemberActions = useMemo(
    () => [
      ...insertIf(isUserController, {
        text: 'Edit permissions',
        onClickHandler: openEditSelfAccountPermissionsFormHandler
      })
    ],
    [isUserController, openEditSelfAccountPermissionsFormHandler]
  )

  const getAdditionalSelfAccountMemberActions = useCallback(
    id => {
      // check if current table item is selfAccount member, and not just invite
      const isMember = membersLists.find(item => item.id === id)?.is_active !== undefined
      return [...(isMember ? selfAccountMemberActions : [])]
    },
    [membersLists, selfAccountMemberActions]
  )

  return useMemo(
    () => ({
      getAdditionalSelfAccountMemberActions
    }),
    [getAdditionalSelfAccountMemberActions]
  )
}
