import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles({
  loadMoreButton: {
    display: 'block',
    margin: '0 auto'
  }
})

export default useStyles
