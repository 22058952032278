import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import Form from '../../../../../components/Form'
import CampaignFormContent from './CampaignFormContent'
import DrawerHeadline from '../../../../../components/Drawer/DrawerHeadline'
import RoutesMissingWarning from '../../../../Multiplatform/RoutesMissingWarning'

import { useMediaOrderRemainingBudgetCalculation } from '../../../../Multiplatform/MediaOrderForms/UploadAdCreative/useMediaOrderRemainingBudgetCalculation'
import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'
import useAccessibleCampaignsRoutes from '../../../../Multiplatform/RoutesMissingWarning/useAccessibleCampaignsRoutes'
import useCampaignFormSuccessSubmit from '../../../../Multiplatform/MediaOrderForms/UploadAdCreative/useCampaignFormSuccessSubmit'

import { showToasts } from '../../../../../helpers/toasts'
import { formatOptionsList } from '../../../../../features/formatters'

import { setMediaOrderUploadCreative } from '../../../../../modules/actions/mediaOrders'
import { clearCampaigns, clearCreateCampaign, createCampaign } from '../../../../../modules/actions/campaigns'

import {
  campaignCreateErrorSelector,
  campaignCreateIsLoadingSelector
} from '../../../../../modules/selectors/campaigns'
import { combinedCampaignsSelector } from '../../../../../modules/selectors/combinedData'
import { mediaOrderSelector } from '../../../../../modules/selectors/mediaOrders'
import { selectedControllerDataSelector, selectedGoogleAdAccountIdSelector } from '../../../../../modules/selectors/app'

import { CAMPAIGN, CAMPAIGN_OPTION, CAMPAIGN_OPTION_EXISTING_CAMPAIGN } from './CampaignFormContent/fields'
import { createCampaignPayload, getInitialValues, getValidationSchema } from './fields'
import { TOAST_TYPE } from '../../../../../constants/other'
import { UPLOAD_CREATIVE_CAMPAIGN_CREATE } from '../../../../../constants/forms'
import { GOOGLE_PLATFORM, GOOGLE_PROVIDER_PLATFORM } from '../../../../../constants/selectLists/platformList'

import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

const CampaignForm = ({ onCampaignRouteSelect, onSuccessSubmit, onSelectExistingCampaign }) => {
  const drawerFormClasses = useDrawerFormStyles()
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [manualSuccessSubmit, setManualSuccessSubmit] = useState(false)

  const controller = useSelector(selectedControllerDataSelector)
  const combinedCampaigns = useSelector(combinedCampaignsSelector)
  const campaignCreateError = useSelector(campaignCreateErrorSelector)
  const mediaOrder = useSelector(mediaOrderSelector)
  const adAccountId = useSelector(selectedGoogleAdAccountIdSelector)

  const remainingBudget = useMediaOrderRemainingBudgetCalculation()

  const { campaigns = [] } = combinedCampaigns

  const googleCampaigns = useMemo(
    () => campaigns.filter(campaign => campaign.provider === GOOGLE_PROVIDER_PLATFORM && campaign.status !== 'deleted'),
    [campaigns]
  )

  const isCampaignsExist = !!googleCampaigns.length

  const allowedRoute = useAccessibleCampaignsRoutes(GOOGLE_PLATFORM)
  const hasAccessibleRoutes = Object.values(allowedRoute).some(route => route)
  const hasRoutes = isCampaignsExist || hasAccessibleRoutes

  const formattedCampaigns = formatOptionsList({
    list: googleCampaigns,
    valueName: 'id',
    labelName: 'name'
  })

  const onSubmit = useCallback(
    values => {
      // if existing campaign is selected, find full data and set to redux state
      if (values[CAMPAIGN_OPTION] === CAMPAIGN_OPTION_EXISTING_CAMPAIGN) {
        const selectedCampaignFullData = formattedCampaigns.find(campaign => values[CAMPAIGN] === campaign.id)

        dispatch(setMediaOrderUploadCreative({ campaign: selectedCampaignFullData }, GOOGLE_PLATFORM))

        onSelectExistingCampaign()
        setManualSuccessSubmit(true)
      } else {
        const campaignData = createCampaignPayload({ values, adAccountId, mediaOrder })

        dispatch(createCampaign({ campaignData }, GOOGLE_PLATFORM, { pushToCombinedCampaignsList: true }))
      }
    },
    [dispatch, formattedCampaigns, adAccountId, mediaOrder, onSelectExistingCampaign]
  )

  const validationSchema = useMemo(() => getValidationSchema({ remainingBudget }), [remainingBudget])

  const initialValues = useMemo(() => getInitialValues(isCampaignsExist), [isCampaignsExist])
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const { values } = formik

  const { [CAMPAIGN_OPTION]: campaignOption } = values

  const successSubmit = useCampaignFormSuccessSubmit({ campaignOption, platform: GOOGLE_PLATFORM, adAccountId })

  const clearHandler = useCallback(() => {
    dispatch(clearCampaigns())
    dispatch(clearCreateCampaign())
  }, [dispatch])

  useEffect(() => {
    if (campaignCreateError?.errors?.amount_micros) {
      showToasts({
        type: TOAST_TYPE.error,
        message: campaignCreateError.errors.amount_micros
      })
    }
  }, [campaignCreateError])

  useEffect(() => {
    onCampaignRouteSelect && onCampaignRouteSelect(campaignOption)
  }, [onCampaignRouteSelect, campaignOption])

  return hasRoutes ? (
    <div className={drawerFormClasses.formContainer}>
      <DrawerHeadline
        activeStepNumber={0}
        customStepsLength={1}
        title="Choose your Google campaign type"
        description={t('learnMoreAboutCampaigns', { controllerName: controller?.name })}
      />
      <Form
        formik={purifiedFormik}
        formName={UPLOAD_CREATIVE_CAMPAIGN_CREATE}
        submitText="Save and continue"
        successSubmit={manualSuccessSubmit || successSubmit}
        onSuccessSubmit={onSuccessSubmit}
        // processing
        errorSelector={campaignCreateErrorSelector}
        isLoadingSelector={campaignCreateIsLoadingSelector}
        clearHandler={clearHandler}
      >
        <CampaignFormContent
          formik={purifiedFormik}
          existedCampaigns={formattedCampaigns}
          remainingBudget={remainingBudget}
        />
      </Form>
    </div>
  ) : (
    <RoutesMissingWarning />
  )
}

export default CampaignForm
