import { add, isBefore } from 'date-fns'
import * as Yup from 'yup'

import { combineDateAndTime, formatDateWithTimeToBE, getDateFromTomorrowByDays } from '../../../helpers/date'

import { OPTION_CUSTOM } from '../../../constants/forms'

import { START_DATE, START_TIME } from '../StartTimeFields/fields'

export const STOP_DATE = 'date_stop'
export const STOP_TIME = 'stop_time'

export const STOP_OPTION = 'stop_option'
export const STOP_OPTION_ONE_WEEK = 'stop_option_one_week'
export const STOP_OPTION_ONE_MONTH = 'stop_option_one_month'
export const STOP_OPTION_INDEFINITELY = 'stop_option_indefinitely'

export const stopTimeInitialValues = {
  [STOP_OPTION]: OPTION_CUSTOM,
  [STOP_DATE]: getDateFromTomorrowByDays(31),
  [STOP_TIME]: '23:59'
}

export const stopTimeValidation = {
  [STOP_TIME]: Yup.string().when(STOP_OPTION, {
    is: OPTION_CUSTOM,
    then: () =>
      Yup.string()
        .required('Stop time is required')
        .test(
          STOP_TIME,
          'The stop time must be at least 6 hours more than selected start time',
          // check if the time is at least 6 hours more from start time
          (value, { parent }) =>
            !isBefore(
              add(combineDateAndTime(parent[STOP_DATE], value), { hours: -6 }),
              combineDateAndTime(parent[START_DATE], parent[START_TIME])
            )
        )
  })
}

export const formatTimeStopToBE = ({ stopOption, stopDate, stopTime, timeStart }) => {
  switch (stopOption) {
    case STOP_OPTION_ONE_WEEK:
      return formatDateWithTimeToBE(add(new Date(timeStart), { days: 7 }))
    case STOP_OPTION_ONE_MONTH:
      return formatDateWithTimeToBE(add(new Date(timeStart), { days: 30 }))
    default:
      return formatDateWithTimeToBE(combineDateAndTime(stopDate, stopTime))
  }
}
