import { createStyles } from '../../../../styles/helpers'

import { phonesDown } from '../../../../styles/const/breakpoints'

const useTotalsStyles = createStyles({
  pdfGenerateBtn: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 160, // We need minWidth for the button to prevent jumping when the loader is shown
    height: 37,
    [`@media screen and (${phonesDown})`]: {
      height: 32
    }
  },
  iconDisabled: {
    opacity: '0.2'
  },
  buttonContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 8
  },
  loadingButtonContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  icon: {
    width: 20
  },
  [`@media screen and (${phonesDown})`]: {
    pdfGenerateBtn: {
      height: 32
    },
    icon: {
      width: 18
    }
  }
})

export default useTotalsStyles
