import * as Yup from 'yup'

import {
  AUDIENCE_NETWORK_CLASSIC,
  AUDIENCE_NETWORK_INSTREAM_VIDEO,
  AUDIENCE_NETWORK_REWARDED_VIDEO,
  BUDGET_DAILY,
  BUDGET_LIFETIME,
  BUDGET_OPTION,
  FACEBOOK_FEED,
  FACEBOOK_INSTANT_ARTICLE,
  FACEBOOK_INSTREAM_VIDEO,
  FACEBOOK_MARKETPLACE,
  FACEBOOK_RIGHT_HAND_COLUMN,
  FACEBOOK_SEARCH,
  FACEBOOK_STORY,
  FACEBOOK_VIDEO_FEEDS,
  INSTAGRAM_EXPLORE,
  INSTAGRAM_REELS,
  INSTAGRAM_SHOP,
  INSTAGRAM_STORY,
  INSTAGRAM_STREAM,
  LANGUAGES,
  MAX_AGE_YEARS,
  MESSENGER_MESSENGER_HOME,
  MESSENGER_SPONSORED_MESSAGES,
  MESSENGER_STORY,
  MIN_AGE_YEARS,
  MIN_SPEND_TARGET_DAILY,
  MIN_SPEND_TARGET_LIFETIME,
  NAME,
  PARENT_CATEGORIES,
  PLACEMENT_OPTION,
  PLACEMENT_OPTION_ALL_INCLUDING_AUDIENCE,
  PLACEMENT_OPTION_CUSTOM,
  ROAS_AVERAGE_FLOOR,
  SPEND_CAP_DAILY,
  SPEND_CAP_LIFETIME,
  TARGET_COST_CAP
} from '../../../../ReusableFormFields/LineItemForms/fields'
import {
  initialGeolocationsValues,
  SHOW_OPTION,
  SHOW_OPTION_REGIONS,
  SHOW_REGIONS
} from '../../../../ReusableFormFields/GeolocationsFields/fields'

import { OPTION_CUSTOM, OPTION_NO, OPTION_YES } from '../../../../../constants/forms'
import {
  AD_RECALL_LIFT,
  AUTOMATIC,
  CONVERSIONS,
  COST_CAP,
  MANUAL,
  MINIMUM_ROAS,
  OUTCOME_SALES
} from '../../../../../constants/campaigns'

import { geolocationsValidation, regionsOverlapValidation } from '../../../../../features/validations/gelolocations'
import { languagesCreateValidation } from '../../../../../features/validations/languages'
import nameValidation from '../../../../../features/validations/name'
import { scheduleValidation } from '../../../../../features/validations/schedule'

import { scheduleInitialValues } from '../../../../ReusableFormFields/ScheduleFields/fields'
import { getTriggerInitialValues } from '../../../../ReusableFormFields/TriggerFields/fields'
import { startTimeInitialValues, startTimeValidation } from '../../../../ReusableFormFields/StartTimeFields/fields'

import { stopTimeInitialValues, stopTimeValidation } from '../../../../ReusableFormFields/StopTimeFields/fields'
import { GENDER_OPTION, GENDER_OPTION_ALL } from '../../../../ReusableFormFields/GenderFields/fields'
import { interestsInitialValues, interestsValidation } from '../../../../ReusableFormFields/InterestsFields/fields'
import {
  customAudiencesInitialValues,
  customAudiencesValidation
} from '../../../../ReusableFormFields/CustomAudiencesFields/fields'
import { promotedObjectInitialValues } from '../../../../ReusableFormFields/PromotedObjectFields/fields'
import {
  FREQUENCY_DAYS,
  FREQUENCY_IMPRESSIONS,
  FREQUENCY_OPTION,
  frequencyInitialValues
} from '../../../../ReusableFormFields/FrequencyFields/fields'
import { placementPositionInitialValues } from '../../../../ReusableFormFields/LineItemForms/facebookLineItemPlacementsHelpers'

// routes
export const ROUTES_OPTION = 'routes_option'
export const AUTOMATIC_ROUTE = 'automatic_route'
export const MANUAL_ROUTE = 'manual_route'

// RECOMMENDED SETUP
export const RECOMMENDED_SETUP_OPTION = 'recommended_setup_option'
export const RECOMMENDED_SETUP_OPTION_AUTOMATIC = 'recommended_setup_option_automatic'
export const RECOMMENDED_SETUP_OPTION_MANUAL = 'recommended_setup_option_manual'

// FLAGS
export const IS_SPEND_LIFETIME = 'is_spend_lifetime'

// SPEND
export const SPEND_OPTION = 'spend_option'
export const SPEND_OPTION_AUTOMATIC = 'spend_option_automatic'

// GEOLOCATIONS
export const SHOW_REGIONS_REGION_TYPE = 'region'
export const SHOW_REGIONS_CITY_TYPE = 'city'

// AGE
export const AGE_OPTION = 'age_option'
export const ANYONE_OVER_18 = 'anyone_over_18'
export const MILLENNIALS = 'millennials'
export const GEN_Z = 'gen_z'
export const MIN_AGE_CHOICE = 'age_min'
export const MAX_AGE_CHOICE = 'age_max'

// CATEGORIES
export const PARENT_OPTION = 'parent_option'

// LANGUAGES
export const LANGUAGES_OPTION = 'languages_option'
export const SELECT_LANGUAGES = 'select_languages'

// OPTIMIZATION TYPE
export const OPTIMIZATION_TYPE = 'optimization_type'

// BIDDING TYPE
export const BIDDING_TYPE = 'bidding_type'

// TARGET COST CAP
export const OBJECTIVE = 'objective'

// ENGAGEMENT_TYPE
export const ENGAGEMENT_TYPE = 'engagement_type'
export const POST_ENGAGEMENT = 'post_engagement'
export const THRUPLAY = 'thruplay'
export const PAGE_LIKES = 'page_likes'
export const LANDING_PAGE_VIEWS = 'landing_page_views'

// DESTINATION_TYPE
export const DESTINATION_TYPE = 'destination_type'
export const ON_POST = 'on_post'
export const ON_VIDEO = 'on_video'
export const ON_PAGE = 'on_page'
export const WEBSITE = 'website'

// STATUS
export const STATUS = 'status'

// task names for recommended step
export const FIRST_TASK = 'task1'
export const SECOND_TASK = 'task2'
export const THIRD_TASK = 'task3'
export const FOURTH_TASK = 'task4'
export const FIFTH_TASK = 'task5'
export const SIXTH_TASK = 'task6'

export const getInitialValues = ({ isLineItemsEmpty, firstLineItemObjective }) => {
  return {
    // RECOMMENDED SETUP
    [RECOMMENDED_SETUP_OPTION]: RECOMMENDED_SETUP_OPTION_AUTOMATIC,

    // NAME
    [NAME]: '',

    // ENGAGEMENT_TYPE
    // if campaign line items are not empty, preselect type of the first line item
    [ENGAGEMENT_TYPE]: isLineItemsEmpty ? POST_ENGAGEMENT : firstLineItemObjective,

    // START TIME
    ...startTimeInitialValues,

    // STOP TIME
    ...stopTimeInitialValues,

    // SCHEDULE
    ...scheduleInitialValues,
    ...getTriggerInitialValues(),

    // BUDGET
    [BUDGET_OPTION]: BUDGET_LIFETIME,
    [BUDGET_LIFETIME]: '',
    [BUDGET_DAILY]: '',

    // SPEND
    [SPEND_OPTION]: SPEND_OPTION_AUTOMATIC,
    [MIN_SPEND_TARGET_LIFETIME]: '',
    [SPEND_CAP_LIFETIME]: '',
    [MIN_SPEND_TARGET_DAILY]: '',
    [SPEND_CAP_DAILY]: '',

    // GEOLOCATIONS
    ...initialGeolocationsValues,

    // GENDER
    [GENDER_OPTION]: GENDER_OPTION_ALL,

    // AGE
    [AGE_OPTION]: ANYONE_OVER_18,
    [MIN_AGE_YEARS]: [],
    [MAX_AGE_YEARS]: [],
    [MIN_AGE_CHOICE]: 18,
    [MAX_AGE_CHOICE]: 65,

    // INTERESTS
    ...interestsInitialValues,

    // CATEGORIES
    [PARENT_OPTION]: OPTION_NO,
    [PARENT_CATEGORIES]: [],

    // LANGUAGES
    [LANGUAGES_OPTION]: SELECT_LANGUAGES,
    [LANGUAGES]: [
      {
        label: 'English (All)',
        value: 1001
      }
    ],

    // OPTIMIZATION TYPE
    [OPTIMIZATION_TYPE]: AD_RECALL_LIFT,

    // FREQUENCY
    ...frequencyInitialValues,

    // AUDIENCES
    ...customAudiencesInitialValues,

    [BIDDING_TYPE]: AUTOMATIC,

    // TARGET COST CAP
    [TARGET_COST_CAP]: '',
    [ROAS_AVERAGE_FLOOR]: '',
    [OBJECTIVE]: '',

    // PROMOTED OBJECT
    ...promotedObjectInitialValues,

    // PLACEMENT
    [PLACEMENT_OPTION]: PLACEMENT_OPTION_ALL_INCLUDING_AUDIENCE,
    ...placementPositionInitialValues,

    // STATUS
    [STATUS]: 'paused'
  }
}

export const validationSchema = (includeValuesInRequest, campaignObjective, campaignBiddingType, isAutomaticRoute) => {
  // BUDGET
  const includeBudget = includeValuesInRequest[BUDGET_OPTION]

  // SPEND
  const includeSpend = includeValuesInRequest[SPEND_OPTION]
  const isSpendLifetime = includeValuesInRequest[IS_SPEND_LIFETIME]

  return Yup.object({
    ...(!isAutomaticRoute && nameValidation),

    // START TIME
    ...startTimeValidation(),

    // STOP TIME
    ...stopTimeValidation,

    ...(!isAutomaticRoute && {
      ...scheduleValidation,

      // BUDGET
      ...(includeBudget && {
        [BUDGET_LIFETIME]: Yup.string().when(BUDGET_OPTION, {
          is: BUDGET_LIFETIME,
          then: () => Yup.string().required('This field can not be empty')
        })
      }),
      ...(includeBudget && {
        [BUDGET_DAILY]: Yup.string().when(BUDGET_OPTION, {
          is: BUDGET_DAILY,
          then: () => Yup.string().required('This field can not be empty')
        })
      }),

      // SPEND
      ...(includeSpend &&
        isSpendLifetime && {
          [SPEND_CAP_LIFETIME]: Yup.string().when(SPEND_OPTION, {
            is: OPTION_CUSTOM,
            then: () =>
              Yup.string().test(SPEND_CAP_LIFETIME, 'Please enter at least one option', (value, { parent }) => {
                // check that at least one group value is entered
                return !!value || !!parent[MIN_SPEND_TARGET_LIFETIME]
              })
          })
        }),
      ...(includeSpend &&
        !isSpendLifetime && {
          [SPEND_CAP_DAILY]: Yup.string().when(SPEND_OPTION, {
            is: OPTION_CUSTOM,
            then: () =>
              Yup.string().test(SPEND_CAP_DAILY, 'Please enter at least one option', (value, { parent }) => {
                // check that at least one group value is entered
                return !!value || !!parent[MIN_SPEND_TARGET_DAILY]
              })
          })
        })
    }),

    // GEOLOCATIONS
    ...geolocationsValidation,
    [SHOW_REGIONS]: Yup.array().when(SHOW_OPTION, {
      is: SHOW_OPTION_REGIONS,
      then: () => regionsOverlapValidation
    }),

    // INTERESTS
    ...interestsValidation,

    // CATEGORIES
    [PARENT_CATEGORIES]: Yup.array().when(PARENT_OPTION, {
      is: OPTION_YES,
      then: () => Yup.array().min(1, 'Please select at least one option')
    }),

    // LANGUAGES
    ...languagesCreateValidation,

    ...(!isAutomaticRoute && {
      // FREQUENCY
      [FREQUENCY_IMPRESSIONS]: Yup.number().when(FREQUENCY_OPTION, {
        is: OPTION_CUSTOM,
        then: () =>
          Yup.number()
            .min(1, 'Value must be between 1 and 90')
            .max(90, 'Value must be between 1 and 90')
            .required('This field can not be empty')
      }),
      [FREQUENCY_DAYS]: Yup.number().when(FREQUENCY_OPTION, {
        is: OPTION_CUSTOM,
        then: () =>
          Yup.number()
            .min(1, 'Value must be between 1 and 90')
            .max(90, 'Value must be between 1 and 90')
            .required('This field can not be empty')
      }),

      // AUDIENCES
      ...customAudiencesValidation
    }),

    // TARGET COST CAP
    ...((campaignBiddingType === MANUAL || campaignBiddingType === COST_CAP) && {
      [TARGET_COST_CAP]: Yup.string().required('This field can not be empty')
    }),
    ...(campaignBiddingType === MINIMUM_ROAS && {
      [ROAS_AVERAGE_FLOOR]: Yup.string().required('This field can not be empty')
    }),

    // validations for case with conversions/outcome_sales campaign objective and empty bidding_type
    ...((campaignObjective === CONVERSIONS || campaignObjective === OUTCOME_SALES) &&
      !campaignBiddingType && {
        [TARGET_COST_CAP]: Yup.string().when(BIDDING_TYPE, {
          is: val => val !== AUTOMATIC && (val === COST_CAP || val === MANUAL),
          then: () => Yup.string().required('This field can not be empty')
        }),
        [ROAS_AVERAGE_FLOOR]: Yup.string().when(BIDDING_TYPE, {
          is: val => val !== AUTOMATIC && val === MINIMUM_ROAS,
          then: () => Yup.string().required('This field can not be empty')
        })
      }),

    ...(!isAutomaticRoute && {
      // PLACEMENT
      [PLACEMENT_OPTION]: Yup.string().test({
        message: 'Please select at least one option',
        test: (value, { parent }) => {
          const placementCustomOptions = [
            parent[FACEBOOK_FEED],
            parent[INSTAGRAM_STREAM],
            parent[FACEBOOK_MARKETPLACE],
            parent[FACEBOOK_VIDEO_FEEDS],
            parent[FACEBOOK_RIGHT_HAND_COLUMN],
            parent[INSTAGRAM_EXPLORE],
            parent[INSTAGRAM_SHOP],
            parent[MESSENGER_MESSENGER_HOME],
            parent[INSTAGRAM_STORY],
            parent[FACEBOOK_STORY],
            parent[MESSENGER_STORY],
            parent[INSTAGRAM_REELS],
            parent[FACEBOOK_INSTREAM_VIDEO],
            parent[AUDIENCE_NETWORK_INSTREAM_VIDEO],
            parent[FACEBOOK_SEARCH],
            parent[MESSENGER_SPONSORED_MESSAGES],
            parent[FACEBOOK_INSTANT_ARTICLE],
            parent[AUDIENCE_NETWORK_CLASSIC],
            parent[AUDIENCE_NETWORK_REWARDED_VIDEO]
          ]

          // check if at least one option is selected
          return !(value === PLACEMENT_OPTION_CUSTOM && !placementCustomOptions.includes(true))
        }
      })
    })
  })
}
