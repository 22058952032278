import { createStyles } from '../../../../../../styles/helpers'
import { textGrey, darkGrey, mediumGrey } from '../../../../../../styles/const/colors'

const useStyles = createStyles({
  costField: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    color: textGrey,
    fontSize: 14,
    fontWeight: 600
  },
  subTotalField: {
    color: textGrey,
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'right'
  },
  rowTitle: {
    fontSize: 14,
    fontWeight: 600
  },
  rowSubTitle: {
    fontSize: 12,
    color: darkGrey
  },
  rowWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: textGrey,
    fontSize: 14,
    marginTop: 4
  },
  bookedMediaCampaignsField: {
    borderBottom: `1px solid ${mediumGrey}`,
    paddingBottom: 16
  },
  leftSide: {
    maxWidth: 250
  }
})

export default useStyles
