import { concat } from '../../helpers/common'

const MODULE_NAME = 'MEDIA_ORDERS_BOOKINGS/'

// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_BOOKED_REVENUE_REPORT = concat(MODULE_NAME, 'GET_BOOKED_REVENUE_REPORT')
export const GET_BOOKED_REVENUE_REPORT_SUCCESS = concat(MODULE_NAME, 'GET_BOOKED_REVENUE_REPORT_SUCCESS')
export const GET_BOOKED_REVENUE_REPORT_FAILURE = concat(MODULE_NAME, 'GET_BOOKED_REVENUE_REPORT_FAILURE')
export const CLEAR_GET_BOOKED_REVENUE_REPORT = concat(MODULE_NAME, 'CLEAR_GET_BOOKED_REVENUE_REPORT')

export const GET_MEDIA_ORDERS_BOOKINGS_AS_CSV = concat(MODULE_NAME, 'GET_MEDIA_ORDERS_BOOKINGS_AS_CSV')
export const GET_MEDIA_ORDERS_BOOKINGS_AS_CSV_SUCCESS = concat(MODULE_NAME, 'GET_MEDIA_ORDERS_BOOKINGS_AS_CSV_SUCCESS')
export const GET_MEDIA_ORDERS_BOOKINGS_AS_CSV_FAILURE = concat(MODULE_NAME, 'GET_MEDIA_ORDERS_BOOKINGS_AS_CSV_FAILURE')
export const CLEAR_GET_MEDIA_ORDERS_BOOKINGS_AS_CSV = concat(MODULE_NAME, 'CLEAR_GET_MEDIA_ORDERS_BOOKINGS_AS_CSV')

export const UPDATE_BOOKING_MEDIA_FILE = concat(MODULE_NAME, 'UPDATE_BOOKING_MEDIA_FILE')
export const UPDATE_BOOKING_MEDIA_FILE_SUCCESS = concat(MODULE_NAME, 'UPDATE_BOOKING_MEDIA_FILE_SUCCESS')
export const UPDATE_BOOKING_MEDIA_FILE_FAILURE = concat(MODULE_NAME, 'UPDATE_BOOKING_MEDIA_FILE_FAILURE')
export const CLEAR_UPDATE_BOOKING_MEDIA_FILE = concat(MODULE_NAME, 'CLEAR_UPDATE_BOOKING_MEDIA_FILE')

export const GET_BOOKING_MEDIA_FILES = concat(MODULE_NAME, 'GET_BOOKING_MEDIA_FILES')
export const GET_BOOKING_MEDIA_FILES_SUCCESS = concat(MODULE_NAME, 'GET_BOOKING_MEDIA_FILES_SUCCESS')
export const GET_BOOKING_MEDIA_FILES_FAILURE = concat(MODULE_NAME, 'GET_BOOKING_MEDIA_FILES_FAILURE')
export const CLEAR_GET_BOOKING_MEDIA_FILES = concat(MODULE_NAME, 'CLEAR_GET_BOOKING_MEDIA_FILES')

export const DELETE_BOOKING_MEDIA_FILE = concat(MODULE_NAME, 'DELETE_BOOKING_MEDIA_FILE')
export const DELETE_BOOKING_MEDIA_FILE_SUCCESS = concat(MODULE_NAME, 'DELETE_BOOKING_MEDIA_FILE_SUCCESS')
export const DELETE_BOOKING_MEDIA_FILE_FAILURE = concat(MODULE_NAME, 'DELETE_BOOKING_MEDIA_FILE_FAILURE')
export const CLEAR_DELETE_BOOKING_MEDIA_FILE = concat(MODULE_NAME, 'CLEAR_DELETE_BOOKING_MEDIA_FILE')

export const UPLOAD_BOOKING_MEDIA_FILE = concat(MODULE_NAME, 'UPLOAD_BOOKING_MEDIA_FILE')
export const UPLOAD_BOOKING_MEDIA_FILE_SUCCESS = concat(MODULE_NAME, 'UPLOAD_BOOKING_MEDIA_FILE_SUCCESS')
export const UPLOAD_BOOKING_MEDIA_FILE_FAILURE = concat(MODULE_NAME, 'UPLOAD_BOOKING_MEDIA_FILE_FAILURE')
export const CLEAR_UPLOAD_BOOKING_MEDIA_FILE = concat(MODULE_NAME, 'CLEAR_UPLOAD_BOOKING_MEDIA_FILE')

export const GET_ORDER_BOOKED_MEDIA = concat(MODULE_NAME, 'GET_ORDER_BOOKED_MEDIA')
export const GET_ORDER_BOOKED_MEDIA_SUCCESS = concat(MODULE_NAME, 'GET_ORDER_BOOKED_MEDIA_SUCCESS')
export const GET_ORDER_BOOKED_MEDIA_FAILURE = concat(MODULE_NAME, 'GET_ORDER_BOOKED_MEDIA_FAILURE')
export const CLEAR_GET_ORDER_BOOKED_MEDIA = concat(MODULE_NAME, 'CLEAR_GET_ORDER_BOOKED_MEDIA')

export const GET_BOOKED_MEDIA_REPORT = concat(MODULE_NAME, 'GET_BOOKED_MEDIA_REPORT')
export const GET_BOOKED_MEDIA_REPORT_SUCCESS = concat(MODULE_NAME, 'GET_BOOKED_MEDIA_REPORT_SUCCESS')
export const GET_BOOKED_MEDIA_REPORT_FAILURE = concat(MODULE_NAME, 'GET_BOOKED_MEDIA_REPORT_FAILURE')
export const CLEAR_GET_BOOKED_MEDIA_REPORT = concat(MODULE_NAME, 'CLEAR_GET_BOOKED_MEDIA_REPORT')

export const GET_INSTALLATION_BOOKED_MEDIA_REPORT = concat(MODULE_NAME, 'GET_INSTALLATION_BOOKED_MEDIA_REPORT')
export const GET_INSTALLATION_BOOKED_MEDIA_REPORT_SUCCESS = concat(
  MODULE_NAME,
  'GET_INSTALLATION_BOOKED_MEDIA_REPORT_SUCCESS'
)
export const GET_INSTALLATION_BOOKED_MEDIA_REPORT_FAILURE = concat(
  MODULE_NAME,
  'GET_INSTALLATION_BOOKED_MEDIA_REPORT_FAILURE'
)
export const CLEAR_GET_INSTALLATION_BOOKED_MEDIA_REPORT = concat(
  MODULE_NAME,
  'CLEAR_GET_INSTALLATION_BOOKED_MEDIA_REPORT'
)

// get installation report as csv
export const GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV = concat(
  MODULE_NAME,
  'GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV'
)

export const GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV_SUCCESS = concat(
  MODULE_NAME,
  'GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV_SUCCESS'
)

export const GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV_FAILURE = concat(
  MODULE_NAME,
  'GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV_FAILURE'
)

export const CLEAR_GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV = concat(
  MODULE_NAME,
  'CLEAR_GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV'
)

export const BULK_UPDATE_BOOKING_MEDIA_FILE = concat(MODULE_NAME, 'BULK_UPDATE_BOOKING_MEDIA_FILE')
export const BULK_UPDATE_BOOKING_MEDIA_FILE_SUCCESS = concat(MODULE_NAME, 'BULK_UPDATE_BOOKING_MEDIA_FILE_SUCCESS')
export const BULK_UPDATE_BOOKING_MEDIA_FILE_FAILURE = concat(MODULE_NAME, 'BULK_UPDATE_BOOKING_MEDIA_FILE_FAILURE')
export const CLEAR_BULK_UPDATE_BOOKING_MEDIA_FILE = concat(MODULE_NAME, 'CLEAR_BULK_UPDATE_BOOKING_MEDIA_FILE')

export const BULK_UPDATE_BOOKED_MEDIA = concat(MODULE_NAME, 'BULK_UPDATE_BOOKED_MEDIA')
export const BULK_UPDATE_BOOKED_MEDIA_SUCCESS = concat(MODULE_NAME, 'BULK_UPDATE_BOOKED_MEDIA_SUCCESS')
export const BULK_UPDATE_BOOKED_MEDIA_FAILURE = concat(MODULE_NAME, 'BULK_UPDATE_BOOKED_MEDIA_FAILURE')
export const CLEAR_BULK_UPDATE_BOOKED_MEDIA = concat(MODULE_NAME, 'CLEAR_BULK_UPDATE_BOOKED_MEDIA')

export const GET_BOOKED_MEDIA = concat(MODULE_NAME, 'GET_BOOKED_MEDIA')
export const GET_BOOKED_MEDIA_SUCCESS = concat(MODULE_NAME, 'GET_BOOKED_MEDIA_SUCCESS')
export const GET_BOOKED_MEDIA_FAILURE = concat(MODULE_NAME, 'GET_BOOKED_MEDIA_FAILURE')
export const CLEAR_GET_BOOKED_MEDIA = concat(MODULE_NAME, 'CLEAR_GET_BOOKED_MEDIA')

export const GET_BRAND_REVENUE_REPORT = concat(MODULE_NAME, 'GET_BRAND_REVENUE_REPORT')
export const GET_BRAND_REVENUE_REPORT_SUCCESS = concat(MODULE_NAME, 'GET_BRAND_REVENUE_REPORT_SUCCESS')
export const GET_BRAND_REVENUE_REPORT_FAILURE = concat(MODULE_NAME, 'GET_BRAND_REVENUE_REPORT_FAILURE')
export const CLEAR_GET_BRAND_REVENUE_REPORT = concat(MODULE_NAME, 'CLEAR_GET_BRAND_REVENUE_REPORT')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// Get media orders bookings as csv
export function getMediaOrdersBookingsAsCsv(params) {
  return { type: GET_MEDIA_ORDERS_BOOKINGS_AS_CSV, params }
}

export function getMediaOrdersBookingsAsCsvSuccess(data) {
  return { type: GET_MEDIA_ORDERS_BOOKINGS_AS_CSV_SUCCESS, data }
}

export function getMediaOrdersBookingsAsCsvFailure(error) {
  return { type: GET_MEDIA_ORDERS_BOOKINGS_AS_CSV_FAILURE, error }
}

export function clearGetMediaOrdersBookingsAsCsv() {
  return { type: CLEAR_GET_MEDIA_ORDERS_BOOKINGS_AS_CSV }
}

// Get booking media files
export function getBookingMediaFiles(params) {
  return { type: GET_BOOKING_MEDIA_FILES, params }
}

export function getBookingMediaFilesSuccess(data) {
  return { type: GET_BOOKING_MEDIA_FILES_SUCCESS, data }
}

export function getBookingMediaFilesFailure(error) {
  return { type: GET_BOOKING_MEDIA_FILES_FAILURE, error }
}

export function clearGetBookingMediaFiles() {
  return { type: CLEAR_GET_BOOKING_MEDIA_FILES }
}

// Update booking media file status
export function updateBookingMediaFile(id, data) {
  return { type: UPDATE_BOOKING_MEDIA_FILE, id, data }
}

export function updateBookingMediaFileSuccess(data) {
  return { type: UPDATE_BOOKING_MEDIA_FILE_SUCCESS, data }
}

export function updateBookingMediaFileFailure(error) {
  return { type: UPDATE_BOOKING_MEDIA_FILE_FAILURE, error }
}

export function clearUpdateBookingMediaFile() {
  return { type: CLEAR_UPDATE_BOOKING_MEDIA_FILE }
}

// Delete booking media file
export function deleteBookingMediaFile(id, params) {
  return { type: DELETE_BOOKING_MEDIA_FILE, id, params }
}

export function deleteBookingMediaFileSuccess(data) {
  return { type: DELETE_BOOKING_MEDIA_FILE_SUCCESS, data }
}

export function deleteBookingMediaFileFailure(error) {
  return { type: DELETE_BOOKING_MEDIA_FILE_FAILURE, error }
}

export function clearDeleteBookingMediaFile() {
  return { type: CLEAR_DELETE_BOOKING_MEDIA_FILE }
}

// Upload booking media file
export function uploadBookingMediaFile(data) {
  return { type: UPLOAD_BOOKING_MEDIA_FILE, data }
}

export function uploadBookingMediaFileSuccess(data) {
  return { type: UPLOAD_BOOKING_MEDIA_FILE_SUCCESS, data }
}

export function uploadBookingMediaFileFailure(error) {
  return { type: UPLOAD_BOOKING_MEDIA_FILE_FAILURE, error }
}

export function clearUploadBookingMediaFile() {
  return { type: CLEAR_UPLOAD_BOOKING_MEDIA_FILE }
}

// Get order booked media
export function getOrderBookedMedia(
  params,
  loadOptions = {
    shouldClearExistingState: false
  }
) {
  return { type: GET_ORDER_BOOKED_MEDIA, params, loadOptions }
}

export function getOrderBookedMediaSuccess(data) {
  return { type: GET_ORDER_BOOKED_MEDIA_SUCCESS, data }
}

export function getOrderBookedMediaFailure(error) {
  return { type: GET_ORDER_BOOKED_MEDIA_FAILURE, error }
}

export function clearGetOrderBookedMedia() {
  return { type: CLEAR_GET_ORDER_BOOKED_MEDIA }
}

// Get booked media report
export function getBookedMediaReport({
  params,
  loadOptions = {
    shouldClearExistingState: false
  },
  period
}) {
  return { type: GET_BOOKED_MEDIA_REPORT, params, loadOptions, period }
}

export function getBookedMediaReportSuccess(data, period) {
  return { type: GET_BOOKED_MEDIA_REPORT_SUCCESS, data, period }
}

export function getBookedMediaReportFailure(error, period) {
  return { type: GET_BOOKED_MEDIA_REPORT_FAILURE, error, period }
}

export function clearGetBookedMediaReport() {
  return { type: CLEAR_GET_BOOKED_MEDIA_REPORT }
}

// Get booked revenue report
export function getBookedRevenueReport({
  params,
  loadOptions = {
    shouldClearExistingState: false
  },
  period
}) {
  return { type: GET_BOOKED_REVENUE_REPORT, params, loadOptions, period }
}

export function getBookedRevenueReportSuccess(data, period) {
  return { type: GET_BOOKED_REVENUE_REPORT_SUCCESS, data, period }
}

export function getBookedRevenueReportFailure(error, period) {
  return { type: GET_BOOKED_REVENUE_REPORT_FAILURE, error, period }
}

export function clearGetBookedRevenueReport() {
  return { type: CLEAR_GET_BOOKED_REVENUE_REPORT }
}

// Get installation booked media report
export function getInstallationBookedMediaReport(params) {
  return { type: GET_INSTALLATION_BOOKED_MEDIA_REPORT, params }
}

export function getInstallationBookedMediaReportSuccess(data) {
  return { type: GET_INSTALLATION_BOOKED_MEDIA_REPORT_SUCCESS, data }
}

export function getInstallationBookedMediaReportFailure(error) {
  return { type: GET_INSTALLATION_BOOKED_MEDIA_REPORT_FAILURE, error }
}

export function clearGetInstallationBookedMediaReport() {
  return { type: CLEAR_GET_INSTALLATION_BOOKED_MEDIA_REPORT }
}

export function getInstallationBookedMediaReportAsCsv(params) {
  return { type: GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV, params }
}

export function getInstallationBookedMediaReportAsCsvSuccess(data) {
  return { type: GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV_SUCCESS, data }
}

export function getInstallationBookedMediaReportAsCsvFailure(error) {
  return { type: GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV_FAILURE, error }
}

export function clearGetInstallationBookedMediaReportAsCsv() {
  return { type: CLEAR_GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV }
}

// Bulk update booking media file
export function bulkUpdateBookingMediaFile(data) {
  return { type: BULK_UPDATE_BOOKING_MEDIA_FILE, data }
}

export function bulkUpdateBookingMediaFileSuccess(data) {
  return { type: BULK_UPDATE_BOOKING_MEDIA_FILE_SUCCESS, data }
}

export function bulkUpdateBookingMediaFileFailure(error) {
  return { type: BULK_UPDATE_BOOKING_MEDIA_FILE_FAILURE, error }
}

export function clearBulkUpdateBookingMediaFile() {
  return { type: CLEAR_BULK_UPDATE_BOOKING_MEDIA_FILE }
}

// Bulk update booked media
export function bulkUpdateBookedMedia(data) {
  return { type: BULK_UPDATE_BOOKED_MEDIA, data }
}

export function bulkUpdateBookedMediaSuccess(data) {
  return { type: BULK_UPDATE_BOOKED_MEDIA_SUCCESS, data }
}

export function bulkUpdateBookedMediaFailure(error) {
  return { type: BULK_UPDATE_BOOKED_MEDIA_FAILURE, error }
}

export function clearBulkUpdateBookedMedia() {
  return { type: CLEAR_BULK_UPDATE_BOOKED_MEDIA }
}

// Get booked media
export function getBookedMedia(params) {
  return { type: GET_BOOKED_MEDIA, params }
}

export function getBookedMediaSuccess(data) {
  return { type: GET_BOOKED_MEDIA_SUCCESS, data }
}

export function getBookedMediaFailure(error) {
  return { type: GET_BOOKED_MEDIA_FAILURE, error }
}

export function clearGetBookedMedia() {
  return { type: CLEAR_GET_BOOKED_MEDIA }
}

// Get brand revenue report
export function getBrandRevenueReport(
  params,
  loadOption = {
    shouldClearExistingState: false
  }
) {
  return { type: GET_BRAND_REVENUE_REPORT, params, loadOption }
}

export function getBrandRevenueReportSuccess(data) {
  return { type: GET_BRAND_REVENUE_REPORT_SUCCESS, data }
}

export function getBrandRevenueReportFailure(error) {
  return { type: GET_BRAND_REVENUE_REPORT_FAILURE, error }
}

export function clearGetBrandAnnualRevenueReport() {
  return { type: CLEAR_GET_BRAND_REVENUE_REPORT }
}
