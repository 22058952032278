import { DESCRIPTIONS_LIST, HEADLINES_LIST } from '../../fields'

export const getRandomizedValues = values => {
  // we use randomizedValues to represent headlines and descriptions randomly in different previews
  const headlines = [...values[HEADLINES_LIST]]
  const descriptions = [...values[DESCRIPTIONS_LIST]]

  return {
    ...values,
    [HEADLINES_LIST]: headlines.sort(() => Math.random() - 0.5),
    [DESCRIPTIONS_LIST]: descriptions.sort(() => Math.random() - 0.5)
  }
}
