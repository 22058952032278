import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import DatesStep from './Steps/DatesStep'
import CustomAudiencesStep from './Steps/CustomAudiencesStep'
import StepForm from '../../../../../../features/components/Forms/StepForm'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import useLineItemCreateShowSteps from '../../../../LineItemForms/LineItemTikTokCreate/LineItemTikTokCreateForm/useLineItemCreateShowSteps'

import { clearCreateLineItem, clearLineItems, createLineItem } from '../../../../../../modules/actions/lineItems'
import { setMediaOrderUploadCreative } from '../../../../../../modules/actions/mediaOrders'
import { mediaOrderUploadCreativeDataSelector } from '../../../../../../modules/selectors/mediaOrders'
import { currentUserCountrySelector } from '../../../../../../modules/selectors/choices'
import {
  lineItemCreatedDataSelector,
  lineItemCreateErrorSelector,
  lineItemCreateIsLoadingSelector,
  lineItemWasCreatedSelector
} from '../../../../../../modules/selectors/lineItems'

import { LINE_ITEM_FORM } from '../../index'
import { TIKTOK_PLATFORM } from '../../../../../../constants/selectLists/platformList'
import { initialValues, getValidationSchema, transformValuesToBE } from './fields'

const LineItemFormContent = ({ adAccountId, onSuccessSubmit }) => {
  const dispatch = useDispatch()

  const [successSubmit, setSuccessSubmit] = useState(false)

  const currentUserCountry = useSelector(currentUserCountrySelector)
  const lineItemWasCreated = useSelector(lineItemWasCreatedSelector)
  const lineItemCreatedData = useSelector(lineItemCreatedDataSelector)
  const { campaign } = useSelector(mediaOrderUploadCreativeDataSelector)

  const {
    // pixel
    showPixelStep: showPixelFields,
    pixelIdFromExistingLineItem,
    externalActionFromExistingLineItem
  } = useLineItemCreateShowSteps({
    isCampaignLevelBudgetSet: true,
    campaignObjective: campaign.objective
  })

  const onSubmit = values => {
    const lineItemData = transformValuesToBE({
      values,
      adAccountId,
      campaign,
      country: currentUserCountry,
      showPixelFields,
      pixelIdFromExistingLineItem,
      externalActionFromExistingLineItem
    })

    dispatch(createLineItem(lineItemData, TIKTOK_PLATFORM))
  }

  const validationSchema = getValidationSchema(showPixelFields)
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const clearCreateLineItemHandler = useCallback(() => {
    dispatch(clearCreateLineItem())
    dispatch(clearLineItems())
  }, [dispatch])

  const steps = useMemo(
    () => [
      {
        component: <DatesStep showPixelFields={showPixelFields} adAccountId={adAccountId} />,
        show: true
      },
      {
        component: <CustomAudiencesStep adAccountId={adAccountId} />,
        show: true
      }
    ],
    [showPixelFields, adAccountId]
  )

  useEffect(() => {
    if (lineItemWasCreated) {
      dispatch(setMediaOrderUploadCreative({ lineItem: lineItemCreatedData }))

      setSuccessSubmit(true)
    }
  }, [dispatch, lineItemWasCreated, lineItemCreatedData])

  return (
    <StepForm
      formName={LINE_ITEM_FORM}
      formik={purifiedFormik}
      steps={steps}
      submitText="Save and continue"
      // processing
      successSubmit={successSubmit}
      errorSelector={lineItemCreateErrorSelector}
      isLoadingSelector={lineItemCreateIsLoadingSelector}
      // after form submit
      onSuccessSubmit={onSuccessSubmit}
      clearSubmitHandler={clearCreateLineItemHandler}
    />
  )
}

LineItemFormContent.propTypes = {
  adAccountId: PropTypes.string.isRequired,
  onSuccessSubmit: PropTypes.func.isRequired
}

export default LineItemFormContent
