import { createStyles } from '../../../../../../../../../../../styles/helpers'
import { black, mediumGrey, red } from '../../../../../../../../../../../styles/const/colors'

const useStyles = createStyles(theme => ({
  selectedValueWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 4px 4px 6px',
    height: 'auto',
    minHeight: 27,
    marginBottom: 4,
    // hide the custom dashed for now, until all other fields will have support of it
    // backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C3C3C3FF' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");`,
    color: black,
    border: `1px solid ${mediumGrey}`,
    borderRadius: theme.defaultBorderRadius,
    backgroundColor: 'white'
  },
  selectedValueLabel: {
    fontSize: 12,
    lineClamp: 1,
    display: 'block',
    wordBreak: 'break-word',
    boxOrient: 'vertical',
    height: 'auto'
  },
  selectedValueRemove: {
    marginLeft: 'auto',
    padding: 0
  },
  isNotValid: {
    border: `1px solid ${red}`,
    color: red
  }
}))

export default useStyles
