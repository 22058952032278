import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import ItemsLoading from '../../../../../../../../components/Loaders/ItemsLoading'
import ErrorMessage from '../../../../../../../../components/Form/ErrorMessage'
import TikTokVideoThumbnailItem from './TikTokVideoThumbnailItem'

import { getTikTokVideoThumbnails } from '../../../../../../../../modules/actions/tikTokConstants'
import {
  tikTokVideoThumbnailsErrorSelector,
  tikTokVideoThumbnailsIsLoadingSelector,
  tikTokVideoThumbnailsSelector,
  tikTokVideoThumbnailsWasLoadedSelector
} from '../../../../../../../../modules/selectors/tikTokConstants'

import { VIDEO_THUMBNAIL_URL } from '../../../fields'
import { FILE_URL } from '../../../../../../../ReusableFormFields/AdFileUpload/fields'

import useStyles from './styles'
import useDrawerFormStyles from '../../../../../../../../styles/common/drawerForms'

const TikTokVideoThumbnailLibrary = ({
  formik,
  allowShowingThumbnailsLibrary = true,
  previousVideoUrl,
  setPreviousVideoUrl
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const drawerFormClasses = useDrawerFormStyles()

  const dispatch = useDispatch()

  const [isThumbnailPreselected, setIsThumbnailPreselected] = useState(false)

  const tikTokVideoThumbnails = useSelector(tikTokVideoThumbnailsSelector)
  const tikTokVideoThumbnailsWasLoaded = useSelector(tikTokVideoThumbnailsWasLoadedSelector)

  const { values, errors, touched, setFieldValue } = formik

  const { [FILE_URL]: videoUrl, [VIDEO_THUMBNAIL_URL]: videoThumbnailUrl } = values

  const showTikTokThumbnailLibrary = allowShowingThumbnailsLibrary && !!videoUrl
  const showTikTokThumbnailError =
    showTikTokThumbnailLibrary && errors[VIDEO_THUMBNAIL_URL] && touched[VIDEO_THUMBNAIL_URL]

  const SkeletonVideoThumbnail = () => <Skeleton className={classes.thumbnailImageSkeleton} />

  const onThumbnailSelectHandler = useCallback(
    thumbnailUrl => {
      setFieldValue(VIDEO_THUMBNAIL_URL, thumbnailUrl)
    },
    [setFieldValue]
  )

  // if new video is selected, and it's different from previous one, load new thumbnails
  useEffect(() => {
    if (showTikTokThumbnailLibrary && videoUrl !== previousVideoUrl) {
      setPreviousVideoUrl(videoUrl)
      setIsThumbnailPreselected(false)

      dispatch(
        getTikTokVideoThumbnails({
          video_url: videoUrl,
          num_of_covers: 3
        })
      )
    }
  }, [
    dispatch,
    tikTokVideoThumbnailsWasLoaded,
    previousVideoUrl,
    setPreviousVideoUrl,
    showTikTokThumbnailLibrary,
    videoUrl
  ])

  useEffect(() => {
    // Preselect the first thumbnail when new thumbnails are loaded
    if (!!tikTokVideoThumbnails.length && !isThumbnailPreselected) {
      onThumbnailSelectHandler(tikTokVideoThumbnails[0])
      setIsThumbnailPreselected(true)
    }
  }, [tikTokVideoThumbnails, isThumbnailPreselected, onThumbnailSelectHandler])

  return (
    <div className={classes.thumbnailLibraryContainer}>
      <h3 className={drawerFormClasses.sectionTitle}>Select a thumbnail image for the video</h3>

      {showTikTokThumbnailLibrary ? (
        <ItemsLoading
          className={classes.thumbnailLibrary}
          itemsLength={tikTokVideoThumbnails.length}
          SkeletonComponent={SkeletonVideoThumbnail}
          isLoadingSelector={tikTokVideoThumbnailsIsLoadingSelector}
          wasLoadedSelector={tikTokVideoThumbnailsWasLoadedSelector}
          errorSelector={tikTokVideoThumbnailsErrorSelector}
          noDataText="Nothing to see here. Please try selecting a different video."
        >
          {tikTokVideoThumbnails.map((thumbnail, index) => (
            <TikTokVideoThumbnailItem
              key={index}
              thumbnail={thumbnail}
              onThumbnailSelectHandler={onThumbnailSelectHandler}
              isSelected={videoThumbnailUrl === thumbnail}
            />
          ))}
        </ItemsLoading>
      ) : (
        <p>{t('Please select a video to view thumbnail options.')}</p>
      )}
      {showTikTokThumbnailError && <ErrorMessage error={errors[VIDEO_THUMBNAIL_URL]} />}
    </div>
  )
}

TikTokVideoThumbnailLibrary.propTypes = {
  formik: PropTypes.object.isRequired,
  allowShowingThumbnailsLibrary: PropTypes.bool,
  previousVideoUrl: PropTypes.string,
  setPreviousVideoUrl: PropTypes.func.isRequired
}

export default TikTokVideoThumbnailLibrary
