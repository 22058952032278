import React from 'react'
import PropTypes from 'prop-types'

import DateRangeFilter from '../../../../../../features/components/Filters/DateRangeFilter'
import { InstallationReportDataProvider, InstallationReportFiltersContext } from './InstallationReportFiltersContext'
import MultipleMediaProductsFilter from '../../../../../../features/components/Filters/MultipleMediaProductsFilter'
import MultipleCategoriesFilters from '../../../../../../features/components/Filters/MultipleCategoriesFilters'
import BookedMediaMultipleFileStatusesFilter from '../../../../../../features/components/Filters/BookedMediaMultipleFileStatusesFilter'
import ExportInstallationReportAsCsv from '../ExportInstallationReportAsCsv'
import BookedMediaMultipleStatusesFilter from '../../../../../../features/components/Filters/BookedMediaMultipleStatusesFilter'
import MultipleSelfAccountCategoriesFilters from '../../../../../../features/components/Filters/MultipleSelfAccountCategoriesFilters'
import BookedMediaLocationsFilter from '../../../../../../features/components/Filters/BookedMediaLocationsFilter'
import RefreshButton from '../../../../../../features/components/Filters/RefreshButton'

import usePermissions from '../../../../../../hooks/usePermissions'

import { formatDateRangeLabel } from '../../../../../../features/components/Filters/DateRangeFilter/helpers'

import { MEDIA_CATEGORIES_FILTERS_PERMISSION } from '../../../../../../constants/permissions'

import useStyles from './styles'

const InstallationReportFilters = ({ onFiltersChange }) => {
  const classes = useStyles()

  const permissions = usePermissions()

  return (
    <InstallationReportDataProvider onFiltersChange={onFiltersChange}>
      <div className={classes.filtersRow}>
        <div className={classes.rightFiltersRow}>
          <RefreshButton refreshDataHandler={onFiltersChange} Context={InstallationReportFiltersContext} />
          <ExportInstallationReportAsCsv />
          <BookedMediaLocationsFilter
            Context={InstallationReportFiltersContext}
            placeholder="Location"
            multipleOptionsLabel="Location"
          />
          <MultipleMediaProductsFilter Context={InstallationReportFiltersContext} />
          {permissions && permissions.can('manage', MEDIA_CATEGORIES_FILTERS_PERMISSION) && (
            <MultipleCategoriesFilters Context={InstallationReportFiltersContext} />
          )}
          <BookedMediaMultipleFileStatusesFilter Context={InstallationReportFiltersContext} />
          <BookedMediaMultipleStatusesFilter Context={InstallationReportFiltersContext} />
          <MultipleSelfAccountCategoriesFilters Context={InstallationReportFiltersContext} />
          <DateRangeFilter Context={InstallationReportFiltersContext} formatDateRangeLabel={formatDateRangeLabel} />
        </div>
      </div>
    </InstallationReportDataProvider>
  )
}

InstallationReportFilters.propTypes = {
  onFiltersChange: PropTypes.func
}

export default InstallationReportFilters
