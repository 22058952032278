import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import QRCode from 'react-qr-code'

import AuthCodeInput from '../../../../../../components/Form/AuthCodeInput'
import Form from '../../../../../../components/Form'

import CopyIcon from '../../../../../../assets/icons/copy.png'

import {
  verifyTOTPToken,
  clearVerifyTOTPToken,
  getTOTPToken,
  clearGetTOTPToken
} from '../../../../../../modules/actions/cognito'
import {
  getTOTPTokenSelector,
  getTOTPTokenWasLoadedSelector,
  verifyTOTPTokenErrorSelector,
  verifyTOTPTokenIsLoadingSelector,
  verifyTOTPTokenWasCreatedSelector
} from '../../../../../../modules/selectors/cognito'

import { showToasts } from '../../../../../../helpers/toasts'

import { TOAST_TYPE } from '../../../../../../constants/other'
import { initialValues, validationSchema, VERIFICATION_CODE } from '../fields'
import { VERIFY_TOTP_TOKEN_FORM } from '../../../../../../constants/forms'

import useStyles from '../styles'
import useModalFormsStyles from '../../../../../../styles/common/modalForms'

const SetupTOTPTokenForm = ({ onSuccessSubmitHandler, showSubmitButton = true }) => {
  const classes = useStyles()
  const modalFormsClasses = useModalFormsStyles()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const totpToken = useSelector(getTOTPTokenSelector)
  const totpTokenWasLoaded = useSelector(getTOTPTokenWasLoadedSelector)
  const verifyTOTPTokenWasVerified = useSelector(verifyTOTPTokenWasCreatedSelector)

  const onSubmit = useCallback(
    values => {
      dispatch(verifyTOTPToken(values[VERIFICATION_CODE]))
    },
    [dispatch]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const { setFieldValue, errors, touched } = formik

  const onVerificationCodeChange = value => {
    setFieldValue(VERIFICATION_CODE, value)
  }

  const onTokenCopy = async () => {
    await navigator.clipboard.writeText(totpToken)

    showToasts({
      type: TOAST_TYPE.success,
      message: t('Token was successfully copied')
    })
  }

  const onSuccessSubmit = () => {
    onSuccessSubmitHandler()
  }

  const clearVerifyTOTPTokenHandler = useCallback(() => {
    dispatch(clearVerifyTOTPToken())
  }, [dispatch])

  useEffect(() => {
    dispatch(getTOTPToken())

    return () => dispatch(clearGetTOTPToken())
  }, [dispatch])

  return (
    <>
      <div className={classes.tokenContainer}>
        {totpTokenWasLoaded ? (
          <div className={classes.qrCodeWrapper}>
            <QRCode value={`otpauth://totp/Shopometry?secret=${totpToken}`} size={165} />
          </div>
        ) : (
          <Skeleton width={165} height={165} />
        )}
        <div className={classes.tokenCopyRow}>
          <p className={classes.tokenText}>{totpTokenWasLoaded ? totpToken : <Skeleton />}</p>
          <img className={classes.tokenCopyIcon} src={CopyIcon} onClick={onTokenCopy} alt="Copy" />
        </div>
      </div>
      <Form
        formik={formik}
        formName={VERIFY_TOTP_TOKEN_FORM}
        submitText={t('Submit')}
        className={modalFormsClasses.form}
        formFooterClassName={modalFormsClasses.formFooter}
        successSubmit={verifyTOTPTokenWasVerified}
        errorSelector={verifyTOTPTokenErrorSelector}
        isLoadingSelector={verifyTOTPTokenIsLoadingSelector}
        clearHandler={clearVerifyTOTPTokenHandler}
        onSuccessSubmit={onSuccessSubmit}
        showSubmit={showSubmitButton}
      >
        <p className={classes.passcodeText}>{t('One time passcode')}</p>
        <AuthCodeInput
          onChange={onVerificationCodeChange}
          error={errors[VERIFICATION_CODE]}
          touched={touched[VERIFICATION_CODE]}
        />
      </Form>
    </>
  )
}

SetupTOTPTokenForm.propTypes = {
  onSuccessSubmitHandler: PropTypes.func.isRequired,
  showSubmitButton: PropTypes.bool
}

export default SetupTOTPTokenForm
