import { createSelector } from 'reselect'

import {
  SUGGESTIONS_AD_CREATIVE_SLUG,
  SUGGESTIONS_DESCRIPTIONS_SLUG,
  SUGGESTIONS_GOOGLE_AD,
  SUGGESTIONS_HEADLINE_SLUG,
  SUGGESTIONS_PRIMARY_TEXTS_SLUG
} from '../../constants/tools'

// PLOP_APPEND_PATTERN_ANCHOR

// Get updates history
export const getChangeHistorySelector = createSelector(
  ({ tools }) => tools.changeHistory.results,
  results => results
)

export const getChangeHistoryIsLoadingSelector = createSelector(
  ({ tools }) => tools.changeHistory.isLoading,
  isLoading => isLoading
)

export const getChangeHistoryErrorSelector = createSelector(
  ({ tools }) => tools.changeHistory.error,
  error => error
)

export const getChangeHistoryNextSelector = createSelector(
  ({ tools }) => tools.changeHistory.paging.next,
  next => next
)
export const getChangeHistoryWasLoadedSelector = createSelector(
  ({ tools }) => tools.changeHistory.wasLoaded,
  wasLoaded => wasLoaded
)

// Get ai aiSuggestion
export const adCreativeSuggestionsSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_AD_CREATIVE_SLUG].results,
  data => data
)

export const adCreativeSuggestionsLoadingSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_AD_CREATIVE_SLUG].isLoading,
  isLoading => isLoading
)

export const adCreativeSuggestionsErrorSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_AD_CREATIVE_SLUG].error,
  error => error
)

export const adCreativeSuggestionsWasLoadedSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_AD_CREATIVE_SLUG].wasLoaded,
  wasLoaded => wasLoaded
)

// Primary text aiSuggestion
export const primaryTextSuggestionsSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_PRIMARY_TEXTS_SLUG].results,
  results => results
)
export const primaryTextSuggestionsIsLoadingSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_PRIMARY_TEXTS_SLUG].isLoading,
  isLoading => isLoading
)
export const primaryTextSuggestionsErrorSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_PRIMARY_TEXTS_SLUG].error,
  error => error
)
export const primaryTextSuggestionsWasLoadedSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_PRIMARY_TEXTS_SLUG].wasLoaded,
  wasLoaded => wasLoaded
)
// Headline aiSuggestion
export const headlineSuggestionsSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_HEADLINE_SLUG].results,
  results => results
)
export const headlineSuggestionsIsLoadingSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_HEADLINE_SLUG].isLoading,
  isLoading => isLoading
)
export const headlineSuggestionsErrorSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_HEADLINE_SLUG].error,
  error => error
)
export const headlineSuggestionsWasLoadedSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_HEADLINE_SLUG].wasLoaded,
  wasLoaded => wasLoaded
)
// Description aiSuggestion
export const descriptionSuggestionsSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_DESCRIPTIONS_SLUG].results,
  results => results
)
export const descriptionSuggestionsIsLoadingSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_DESCRIPTIONS_SLUG].isLoading,
  isLoading => isLoading
)
export const descriptionSuggestionsErrorSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_DESCRIPTIONS_SLUG].error,
  error => error
)
export const descriptionSuggestionsWasLoadedSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_DESCRIPTIONS_SLUG].wasLoaded,
  wasLoaded => wasLoaded
)

// Google ad aiSuggestion
export const googleAdSuggestionsSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_GOOGLE_AD].results,
  results => results
)
export const googleAdSuggestionsIsLoadingSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_GOOGLE_AD].isLoading,
  isLoading => isLoading
)
export const googleAdSuggestionsErrorSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_GOOGLE_AD].error,
  error => error
)
export const googleAdSuggestionsWasLoadedSelector = createSelector(
  ({ tools }) => tools.aiSuggestion[SUGGESTIONS_GOOGLE_AD].wasLoaded,
  wasLoaded => wasLoaded
)
