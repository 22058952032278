import { createStyles } from '../../../../../styles/helpers'
import { black, mediumGrey } from '../../../../../styles/const/colors'
import { tabletDown } from '../../../../../styles/const/breakpoints'

const useStyles = createStyles({
  headerMonth: {
    textAlign: 'center'
  },
  revenueRow: {
    padding: 0,
    minHeight: 'auto'
  },
  revenueCol: {
    height: '100%',
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center',
    '&:not(:last-child)': {
      borderRight: `1px solid ${mediumGrey}`
    }
  },
  nameCol: {
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    fontWeight: 600,
    color: black,
    whiteSpace: 'unset'
  },
  [`@media screen and (${tabletDown})`]: {
    revenueCol: {
      minWidth: 85,
      '&$nameCol': {
        minWidth: 125
      }
    }
  }
})

export default useStyles
