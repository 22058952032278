import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from '../../../components/Button'
import Field from '../../../components/Form/Field'

import useStyles from './styles'

function PasswordField(props) {
  const classes = useStyles()

  const { t } = useTranslation()
  const [showPassword, changePasswordVisibility] = useState(false)

  const inputProps = {
    ...props,
    inputClassName: showPassword ? classes.input : classes.hideInputText,
    type: 'text'
  }

  return (
    <div className={classes.passwordInputWrapper}>
      <Field {...inputProps} />
      <Button className={classes.button} type="button" solid onClick={() => changePasswordVisibility(!showPassword)}>
        {t(showPassword ? 'Hide' : 'Show')}
      </Button>
    </div>
  )
}

export default PasswordField

PasswordField.propTypes = {
  formik: PropTypes.object,
  fieldName: PropTypes.string
}
