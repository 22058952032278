import { getInitialTextList } from '../../../../../../forms/ReusableFormFields/MultipleOptionsField/helpers'

import { IMAGE_URLS, LINK, NAME, THUMBNAIL_URL } from '../../fields'

export const initialValues = {
  [NAME]: '',
  [LINK]: '',
  [THUMBNAIL_URL]: '',
  [IMAGE_URLS]: getInitialTextList(1, '')
}
