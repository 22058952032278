import React from 'react'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import MediaOrderCreateSimpleForm from './MediaOrderCreateSimpleForm'
import { MediaOrderFormProvider } from '../MediaOrderFormContext'

import { MEDIA_ORDER_CREATE_SIMPLE } from '../../../../constants/forms'

function MediaOrderCreateSimple() {
  return (
    <FormDrawerWrapper
      formName={MEDIA_ORDER_CREATE_SIMPLE}
      openButtonText="New Booking"
      closeOnSubmit={false}
      hasDefaultDrawerContentSpacing={false}
      isWideDrawer
    >
      <MediaOrderFormProvider>
        <MediaOrderCreateSimpleForm />
      </MediaOrderFormProvider>
    </FormDrawerWrapper>
  )
}

export default MediaOrderCreateSimple
