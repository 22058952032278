import React, { useCallback, useContext, useMemo } from 'react'
import { Portal } from 'react-portal'

import ActionText from '../../../../../../components/ActionText'
import AdCard from '../../../../../../features/components/FacebookPreviews/AdCard'
import Step from '../../../../../../features/components/Forms/StepForm/Step'
import FieldsSection from '../../../../../../features/components/Form/FieldsSection'
import FacebookPageSelectionFields from '../../../../ReusableFormFIelds/FacebookPageSelectionFields'
import CarouselMediaFields from '../../../../../ReusableFormFields/MediaFields/CarouselMediaFields'
import PrimaryTextField from '../../../../ReusableFormFIelds/FacebookAdCreativeFields/FacebookProductAdFields/PrimaryTextField'
import FieldRow from '../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../components/Form/Field'

import useLineItemDetails from '../hooks/useLineItemDetails'
import { useProductSuggestions } from './ProductAdRoute/ProductAdStep/hooks'

import { CAROUSEL_ITEMS, FB_PAGE_CHOICE, INSTA_ACCOUNT_CHOICE, NAME } from '../../../fields'
import {
  BRAND_CATEGORY_PAGE_DATA,
  PRODUCT_OPTION,
  PRODUCT_OPTION_BRAND_CATEGORY_PAGES,
  PRODUCT_OPTION_PRODUCT_PAGES
} from '../../../../../ReusableFormFields/ProductsAndPagesFields/fields'
import { PRODUCT_DATA } from '../../../../../ReusableFormFields/ProductFields/fields'
import { AdFacebookCreateContext } from '../../../../formsContexts/AdFacebookCreateFormContextProvider'

// To make it possible for component to work with nested objects
// in formik values, use the built-in formik function getIn
const CarouselMediaStep = ({ formik, handleStepChange, adAccountId, isActive, isLastStep, showAISuggestions }) => {
  const { values } = formik

  const { campaignObjective } = useContext(AdFacebookCreateContext)

  const selectedProductOrPageData = useMemo(() => {
    if (values[PRODUCT_OPTION] === PRODUCT_OPTION_BRAND_CATEGORY_PAGES) {
      return values[BRAND_CATEGORY_PAGE_DATA]
    } else if (values[PRODUCT_OPTION] === PRODUCT_OPTION_PRODUCT_PAGES) {
      return values[PRODUCT_DATA]
    } else {
      return {}
    }
  }, [values])

  const { suggestions, suggestionsLoading, regenerateSuggestions } = useProductSuggestions({
    selectedProduct: selectedProductOrPageData,
    isActiveStep: isActive
  })

  const { placementPositions } = useLineItemDetails()

  const handleContinue = useCallback(() => {
    handleStepChange([CAROUSEL_ITEMS, FB_PAGE_CHOICE, INSTA_ACCOUNT_CHOICE], values)
  }, [handleStepChange, values])

  const groupedSuggestions = {
    primary_text: [],
    headline: [],
    description: []
  }

  suggestions?.forEach(suggestion => {
    groupedSuggestions.primary_text.push(suggestion.primary_text)
    groupedSuggestions.headline.push(suggestion.headline)
    groupedSuggestions.description.push(suggestion.description)
  })

  return (
    <Step stepTitle="Ad Creative" handleContinue={!isLastStep ? handleContinue : null}>
      <>
        {isActive && (
          <Portal node={document && document.getElementById('formSideColumnPortal')}>
            <AdCard
              elementInProgress={CAROUSEL_ITEMS}
              formikValues={values}
              // show typing animation until first suggestion is loading,
              // when there are more than 1 suggestion we can treat that first was loaded
              typingAnimation={suggestionsLoading && suggestions?.length < 2}
              showSkeletonPlaceholders={true}
            />
          </Portal>
        )}
        <FieldsSection
          title="Ad Settings"
          hasMargin
          rightColumn={
            showAISuggestions &&
            !suggestionsLoading && (
              <ActionText onClick={regenerateSuggestions} isDark>
                Regenerate content
              </ActionText>
            )
          }
        >
          <FacebookPageSelectionFields
            formik={formik}
            adAccountId={adAccountId}
            placementPositions={placementPositions}
          />
          <FieldRow title="Name" description="An ad name to identify this ad in reporting">
            <Field formik={formik} name={NAME} placeholder="Ad Name" autoComplete="off" enableReinitialize />
          </FieldRow>
          <PrimaryTextField formik={formik} suggestions={groupedSuggestions.primary_text} />
        </FieldsSection>

        <CarouselMediaFields
          formik={formik}
          listItemsPath={CAROUSEL_ITEMS}
          adAccountId={adAccountId}
          suggestions={groupedSuggestions}
          campaignObjective={campaignObjective}
        />
      </>
    </Step>
  )
}

export default CarouselMediaStep
