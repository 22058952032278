import { createStyles } from '../../styles/helpers'

import { zIndex8 } from '../../styles/const/common'

export default createStyles(theme => ({
  triggerElement: {
    display: 'flex',
    cursor: 'pointer'
  },
  tooltipBody: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    border: ({ borderColor, borderWidth }) => `${borderWidth}px solid ${borderColor}`,
    borderRadius: theme.defaultBorderRadius,
    cursor: 'auto',
    zIndex: zIndex8,
    boxShadow: 'none',
    animation: '$showTooltip 300ms ease',
    color: ({ color }) => color
  },
  '@keyframes showTooltip': {
    '0%': {
      display: 'none',
      opacity: 0
    },
    '1%': {
      display: 'block',
      opacity: 0
    },
    '100%': {
      display: 'block',
      opacity: 1
    }
  }
}))
