export const SHOP_NOW = 'SHOP_NOW'

const tikTokCallToActionsList = [
  {
    value: 'APPLY_NOW',
    label: 'Apply Now'
  },
  {
    value: 'BOOK_NOW',
    label: 'Book Now'
  },
  {
    value: 'CONTACT_US',
    label: 'Contact Us'
  },
  {
    value: 'DOWNLOAD_NOW',
    label: 'Download Now'
  },
  {
    value: 'LEARN_MORE',
    label: 'Learn More'
  },
  {
    value: SHOP_NOW,
    label: 'Shop Now'
  },
  {
    value: 'SIGN_UP',
    label: 'Sign Up'
  },
  {
    value: 'PLAY_GAME',
    label: 'Play Game'
  },
  {
    value: 'WATCH_NOW',
    label: 'Watch Now'
  },
  {
    value: 'READ_MORE',
    label: 'Read More'
  },
  {
    value: 'VIEW_NOW',
    label: 'View Now'
  },
  {
    value: 'GET_QUOTE',
    label: 'Get Quote'
  },
  {
    value: 'ORDER_NOW',
    label: 'Order Now'
  },
  {
    value: 'INSTALL_NOW',
    label: 'Install Now'
  },
  {
    value: 'GET_SHOWTIMES',
    label: 'Get Showtimes'
  },
  {
    value: 'LISTEN_NOW',
    label: 'Listen Now'
  },
  {
    value: 'INTERESTED',
    label: 'Interested'
  },
  {
    value: 'SUBSCRIBE',
    label: 'Subscribe'
  },
  {
    value: 'GET_TICKETS_NOW',
    label: 'Get Tickets Now'
  },
  {
    value: 'EXPERIENCE_NOW',
    label: 'Experience Now'
  },
  {
    value: 'PREORDER_NOW',
    label: 'Preorder Now'
  },
  {
    value: 'VISIT_STORE',
    label: 'Visit Store'
  },
  {
    value: 'WATCH_LIVE',
    label: 'Watch Live'
  },
  {
    value: 'JOIN_THIS_HASHTAG',
    label: 'Join This Hashtag'
  },
  {
    value: 'VIEW_VIDEO_WITH_THIS_EFFECT',
    label: 'View Video With This Effect'
  },
  {
    value: 'SHOOT_WITH_THIS_EFFECT',
    label: 'Shoot With This Effect'
  }
]

export default tikTokCallToActionsList
