import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Page from '../index'
import AudienceCard from './AudienceCard'
import Can from '../../features/components/Can'
import AudienceCreate from '../../forms/Multiplatform/AudienceForms/AudienceCreate'
import ItemsLoading from '../../components/Loaders/ItemsLoading'
import DeleteItemModal from '../../features/components/Modals/DeleteModal/DeleteItemModal'
import AudienceSuggestions from './AudienceSuggestions'
import SkeletonCard from '../../features/components/Skeletons/SkeletonCard'

import useDeleteAction from '../../features/hooks/useDeleteAction'

import { clearDeleteAudience, getAudiences, deleteAudience, clearAudiences } from '../../modules/actions/audiences'
import { clearAccessibleFacebookPages, getAccessibleFacebookPages } from '../../modules/actions/socialAccounts'
import { clearChoicesPixels, getChoicesPixels } from '../../modules/actions/choices'
import {
  audienceDeleteErrorSelector,
  audienceDeleteIsLoadingSelector,
  audiencesAfterSelector,
  audiencesErrorSelector,
  audiencesIsNextSelector,
  audiencesLoadingSelector,
  audiencesSelector,
  audiencesWasLoadedSelector,
  deletedAudienceSelector
} from '../../modules/selectors/audiences'
import { selectedAdAccountIdSelector } from '../../modules/selectors/app'

import { ROUTES } from '../../constants/routes'

import useCommonStyles from '../../styles/common/listPage'
import useStyles from './styles'

function AudiencesPage() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const commonClasses = useCommonStyles()
  const classes = useStyles()

  const selectedAdAccount = useSelector(selectedAdAccountIdSelector)

  const audiences = useSelector(audiencesSelector)
  const isNext = useSelector(audiencesIsNextSelector)
  const after = useSelector(audiencesAfterSelector)

  const loadMoreHandler = useCallback(() => {
    dispatch(
      getAudiences({
        account: selectedAdAccount,
        after
      })
    )
  }, [dispatch, selectedAdAccount, after])

  const deleteAudienceHandler = useCallback(
    params => {
      dispatch(deleteAudience(params))
    },
    [dispatch]
  )

  const clearDeleteAudienceHandler = useCallback(() => {
    dispatch(clearDeleteAudience())
  }, [dispatch])

  useEffect(() => {
    // load initial audiences
    dispatch(
      getAudiences({
        account: selectedAdAccount
      })
    )

    return () => {
      dispatch(clearAudiences())
    }
  }, [dispatch, selectedAdAccount])

  useEffect(() => {
    dispatch(getAccessibleFacebookPages())

    return () => {
      dispatch(clearAccessibleFacebookPages())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(
      getChoicesPixels({
        account: selectedAdAccount
      })
    )

    return () => {
      dispatch(clearChoicesPixels())
    }
  }, [dispatch, selectedAdAccount])

  const noDataText = t("There aren't any audiences")

  const deleteModalMessage = useMemo(
    () => (
      <>
        <p>{t('Are you sure you want to delete this audience?')}</p>
        <p>{t('This cannot be undone.')}</p>
      </>
    ),
    [t]
  )

  const { handleDelete, handleDeleteRequest, handleCloseDeleteModal, isDeleteModalOpened, deleteItemIsLoading } =
    useDeleteAction({
      onDelete: deleteAudienceHandler,
      onClearDelete: clearDeleteAudienceHandler,
      deletedItemSelector: deletedAudienceSelector,
      deleteItemErrorSelector: audienceDeleteErrorSelector,
      deleteItemIsLoadingSelector: audienceDeleteIsLoadingSelector,
      deleteModalMessage: deleteModalMessage
    })

  return (
    <Page helmetTitle="helmetTitle.AudiencesPage">
      <div className={commonClasses.container}>
        <div className={commonClasses.heading}>
          <h3 className={commonClasses.title}>{t('Your facebook audiences')}</h3>
          <Can I="create" a={ROUTES.audiences}>
            <AudienceCreate />
          </Can>
        </div>
        <ItemsLoading
          className={commonClasses.itemList}
          infoBlockClassName={classes.audiencesInfoBlock}
          SkeletonComponent={SkeletonCard}
          itemsLength={audiences ? audiences.length : 0}
          isLoadingSelector={audiencesLoadingSelector}
          wasLoadedSelector={audiencesWasLoadedSelector}
          errorSelector={audiencesErrorSelector}
          noDataText={noDataText}
          loadMore={isNext ? loadMoreHandler : null}
        >
          {audiences &&
            audiences.map(item => <AudienceCard deleteAudience={handleDeleteRequest} key={item.id} audience={item} />)}
        </ItemsLoading>
      </div>
      <Can I="create" a={ROUTES.audiences}>
        <AudienceSuggestions />
      </Can>
      <Can I="delete" a={ROUTES.audiences}>
        <DeleteItemModal
          handleDelete={handleDelete}
          handleCloseDeleteModal={handleCloseDeleteModal}
          isDeleteModalOpened={isDeleteModalOpened}
          deleteItemIsLoading={deleteItemIsLoading}
          deleteModalMessage={deleteModalMessage}
        />
      </Can>
    </Page>
  )
}

export default AudiencesPage
