import { createStyles } from '../../../styles/helpers'
import { mediumGrey } from '../../../styles/const/colors'

const useStyles = createStyles({
  codeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 16
  },
  codeInput: {
    width: 40,
    height: 40,
    border: `1px solid ${mediumGrey}`,
    borderRadius: 2,
    textAlign: 'center',
    fontSize: 16
  }
})

export default useStyles
