import { useCallback, useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import NameCell from './NameCell'
import StatusField from './StatusField'
import AdaptiveActions from '../../components/AdaptiveActions'

import { formatStringWithUnderscore, insertIf } from '../../../helpers/common'
import { showToasts } from '../../../helpers/toasts'

import { TOAST_TYPE } from '../../../constants/other'
import { ADMIN, MANAGER, REPORT } from '../../../constants/permissions'

import { phonesDownSize } from '../../../styles/const/breakpoints'
import useStyles from './styles'

export const tableColumnsSize = {
  status: 140,
  role: 74,
  actions: 40
}

export const useMembersAndInvitesColumns = ({
  membersAndInvites,
  onChangeMemberStatus,
  onChangeMemberRole,
  onDeleteInvite,
  onResendInvite,
  getAdditionalActions
}) => {
  const classes = useStyles()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const { t } = useTranslation()

  const resendInvitationHandler = useCallback(
    (e, id) => {
      onResendInvite(id)
      showToasts({
        type: TOAST_TYPE.success,
        message: t('The invitation has been sent via email')
      })
    },
    [onResendInvite, t]
  )

  const updateMemberRoleHandler = useCallback(
    (id, newRole) => {
      onChangeMemberRole({ id, newRole })
    },
    [onChangeMemberRole]
  )

  const removeInvitationHandler = useCallback(
    (e, id) => {
      onDeleteInvite(id)
    },
    [onDeleteInvite]
  )

  const changeMemberStatusHandler = useCallback(
    (e, id) => {
      const isActive = membersAndInvites.find(user => user.id === id)?.is_active
      onChangeMemberStatus({ id, isActive })
    },
    [membersAndInvites, onChangeMemberStatus]
  )

  const getDropdownOptions = useCallback(
    (role, isActive, id) => {
      return [
        ...insertIf(isActive === undefined, {
          text: t('Resend invite'),
          onClickHandler: e => resendInvitationHandler(e, id)
        }),
        ...insertIf(isActive === undefined, {
          text: t('Delete invitation'),
          onClickHandler: e => removeInvitationHandler(e, id)
        }),
        ...insertIf(isActive !== undefined && role !== ADMIN, {
          text: t('Make admin'),
          onClickHandler: () => updateMemberRoleHandler(id, ADMIN)
        }),
        ...insertIf(isActive !== undefined && role !== MANAGER, {
          text: t('Make manager'),
          onClickHandler: () => updateMemberRoleHandler(id, MANAGER)
        }),
        ...insertIf(isActive !== undefined && role !== REPORT, {
          text: t('Make reporting'),
          onClickHandler: () => updateMemberRoleHandler(id, REPORT)
        }),
        ...insertIf(isActive !== undefined, {
          text: isActive ? t('Deactivate user') : t('Activate user'),
          onClickHandler: e => changeMemberStatusHandler(e, id)
        }),
        ...(getAdditionalActions ? getAdditionalActions(id) : [])
      ]
    },
    [
      t,
      getAdditionalActions,
      resendInvitationHandler,
      removeInvitationHandler,
      updateMemberRoleHandler,
      changeMemberStatusHandler
    ]
  )

  return useMemo(
    () => [
      {
        header: 'Name',
        Cell: ({ full_name, email }) => <NameCell email={email} name={full_name} />,
        className: classes.mobileNameHeader
      },
      {
        header: 'Status',
        Cell: ({ is_active }) => <StatusField isActive={is_active} className={classes.statusMobileField} />,
        style: { maxWidth: tableColumnsSize.status },
        showOnMobile: false
      },
      {
        header: 'Role',
        Cell: ({ role = '' }) => formatStringWithUnderscore(role),
        style: { maxWidth: tableColumnsSize.role, textTransform: 'capitalize' },
        showOnMobile: false
      },
      {
        Cell: ({ role, is_active, id }) => (
          <AdaptiveActions itemId={id} options={getDropdownOptions(role, is_active, id)} />
        ),
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [getDropdownOptions, isMobile, classes]
  )
}
