import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import MediaPreview from './MediaPreview'

import { AD_FORMAT } from '../../../../../../../constants/ads'
import { CAROUSEL_ITEMS, SINGLE_MEDIA_ITEM } from '../../../../fields'

import useStyles from './styles.js'

const MediaSectionPreview = ({ adValues, adFormat }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const isCarousel = adFormat === AD_FORMAT.carousel || adFormat === AD_FORMAT.carouselCustom

  return isCarousel ? (
    adValues[CAROUSEL_ITEMS].map((mediaItem, index) => (
      <div key={index} className={classes.mediaCard}>
        <h4 className={classes.mediaTitle}>{t('imageOrVideo', { count: index + 1, ordinal: true })}</h4>
        <MediaPreview adValues={mediaItem} />
      </div>
    ))
  ) : (
    <MediaPreview adValues={adValues[SINGLE_MEDIA_ITEM]} />
  )
}

MediaSectionPreview.propTypes = {
  adValues: PropTypes.object,
  adFormat: PropTypes.string
}

export default MediaSectionPreview
