import { createStyles } from '../../styles/helpers'

import { phonesDown } from '../../styles/const/breakpoints'
export const footerMargin = 32
export const formFooter = {
  marginTop: footerMargin,
  textAlign: 'center',
  [`@media screen and (${phonesDown})`]: {
    marginTop: 40,
    '& [class*="btn"]': {
      minWidth: '100%'
    }
  }
}

const useStyles = createStyles({
  formFooter: {
    extend: formFooter
  }
})

export default useStyles
