import { concat } from '../../helpers/common'
// import { setSessionStorage } from '../../helpers/storage'
// import { LINE_ITEM_FACEBOOK_CREATE } from '../../constants/forms'

const MODULE_NAME = 'LINE_ITEMS/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_LINE_ITEM = concat(MODULE_NAME, 'GET_LINE_ITEM')
export const GET_LINE_ITEM_SUCCESS = concat(MODULE_NAME, 'GET_LINE_ITEM_SUCCESS')
export const GET_LINE_ITEM_FAILURE = concat(MODULE_NAME, 'GET_LINE_ITEM_FAILURE')

export const GET_LINE_ITEMS = concat(MODULE_NAME, 'GET_LINE_ITEMS')
export const GET_LINE_ITEMS_SUCCESS = concat(MODULE_NAME, 'GET_LINE_ITEMS_SUCCESS')
export const GET_LINE_ITEMS_FAILURE = concat(MODULE_NAME, 'GET_LINE_ITEMS_FAILURE')

export const CREATE_LINE_ITEM = concat(MODULE_NAME, 'CREATE_LINE_ITEM')
export const CREATE_LINE_ITEM_SUCCESS = concat(MODULE_NAME, 'CREATE_LINE_ITEM_SUCCESS')
export const CREATE_LINE_ITEM_FAILURE = concat(MODULE_NAME, 'CREATE_LINE_ITEM_FAILURE')

export const DELETE_LINE_ITEM = concat(MODULE_NAME, 'DELETE_LINE_ITEM')
export const DELETE_LINE_ITEM_SUCCESS = concat(MODULE_NAME, 'DELETE_LINE_ITEM_SUCCESS')
export const DELETE_LINE_ITEM_FAILURE = concat(MODULE_NAME, 'DELETE_LINE_ITEM_FAILURE')

export const UPDATE_LINE_ITEM = concat(MODULE_NAME, 'UPDATE_LINE_ITEM')
export const UPDATE_LINE_ITEM_SUCCESS = concat(MODULE_NAME, 'UPDATE_LINE_ITEM_SUCCESS')
export const UPDATE_LINE_ITEM_FAILURE = concat(MODULE_NAME, 'UPDATE_LINE_ITEM_FAILURE')

export const DUPLICATE_LINE_ITEM = concat(MODULE_NAME, 'DUPLICATE_LINE_ITEM')
export const DUPLICATE_LINE_ITEM_SUCCESS = concat(MODULE_NAME, 'DUPLICATE_LINE_ITEM_SUCCESS')
export const DUPLICATE_LINE_ITEM_FAILURE = concat(MODULE_NAME, 'DUPLICATE_LINE_ITEM_FAILURE')
export const DUPLICATE_LINE_ITEM_COMPLETE = concat(MODULE_NAME, 'DUPLICATE_LINE_ITEM_COMPLETE')
export const CLEAR_DUPLICATE_LINE_ITEM = concat(MODULE_NAME, 'CLEAR_DUPLICATE_LINE_ITEM')

export const CLEAR_LINE_ITEM = concat(MODULE_NAME, 'CLEAR_LINE_ITEM')
export const CLEAR_LINE_ITEMS = concat(MODULE_NAME, 'CLEAR_LINE_ITEMS')
export const CLEAR_CREATE_LINE_ITEM = concat(MODULE_NAME, 'CLEAR_CREATE_LINE_ITEM')
export const CLEAR_DELETE_LINE_ITEM = concat(MODULE_NAME, 'CLEAR_DELETE_LINE_ITEM')
export const CLEAR_UPDATE_LINE_ITEM = concat(MODULE_NAME, 'CLEAR_UPDATE_LINE_ITEM')

// actions related to line item creation form
export const GET_LOCATIONS = concat(MODULE_NAME, 'GET_LOCATIONS')
export const GET_LOCATIONS_SUCCESS = concat(MODULE_NAME, 'GET_LOCATIONS_SUCCESS')
export const GET_LOCATIONS_FAILURE = concat(MODULE_NAME, 'GET_LOCATIONS_FAILURE')

export const GET_GOALS = concat(MODULE_NAME, 'GET_GOALS')
export const GET_GOALS_SUCCESS = concat(MODULE_NAME, 'GET_GOALS_SUCCESS')
export const GET_GOALS_FAILURE = concat(MODULE_NAME, 'GET_GOALS_FAILURE')
export const CLEAR_GOALS = concat(MODULE_NAME, 'CLEAR_GOALS')

export const GET_PIXELS_STATS = concat(MODULE_NAME, 'GET_PIXELS_STATS')
export const GET_PIXELS_STATS_SUCCESS = concat(MODULE_NAME, 'GET_PIXELS_STATS_SUCCESS')
export const GET_PIXELS_STATS_FAILURE = concat(MODULE_NAME, 'GET_PIXELS_STATS_FAILURE')

export const GET_LINE_ITEM_CRITERIONS = concat(MODULE_NAME, 'GET_LINE_ITEM_CRITERIONS')
export const GET_LINE_ITEM_CRITERIONS_SUCCESS = concat(MODULE_NAME, 'GET_LINE_ITEM_CRITERIONS_SUCCESS')
export const GET_LINE_ITEM_CRITERIONS_FAILURE = concat(MODULE_NAME, 'GET_LINE_ITEM_CRITERIONS_FAILURE')
export const CLEAR_LINE_ITEM_CRITERIONS = concat(MODULE_NAME, 'CLEAR_LINE_ITEM_CRITERIONS')

export const CREATE_LINE_ITEM_CRITERIONS = concat(MODULE_NAME, 'CREATE_LINE_ITEM_CRITERIONS')
export const CREATE_LINE_ITEM_CRITERIONS_SUCCESS = concat(MODULE_NAME, 'CREATE_LINE_ITEM_CRITERIONS_SUCCESS')
export const CREATE_LINE_ITEM_CRITERIONS_FAILURE = concat(MODULE_NAME, 'CREATE_LINE_ITEM_CRITERIONS_FAILURE')
export const CLEAR_CREATE_LINE_ITEM_CRITERIONS = concat(MODULE_NAME, 'CLEAR_CREATE_LINE_ITEM_CRITERIONS')

export const DELETE_LINE_ITEM_CRITERIONS = concat(MODULE_NAME, 'DELETE_LINE_ITEM_CRITERIONS')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// get lineItem
export function getLineItem(params) {
  return { type: GET_LINE_ITEM, params }
}

export function getLineItemSuccess(lineItemData) {
  return { type: GET_LINE_ITEM_SUCCESS, lineItemData }
}

export function getLineItemFailure(error) {
  return { type: GET_LINE_ITEM_FAILURE, error }
}

export function clearLineItem() {
  return { type: CLEAR_LINE_ITEM }
}

// get lineItems
export function getLineItems(params, platform) {
  return { type: GET_LINE_ITEMS, params, platform }
}

export function getLineItemsSuccess(lineItemsData) {
  return { type: GET_LINE_ITEMS_SUCCESS, lineItemsData }
}

export function getLineItemsFailure(error) {
  return { type: GET_LINE_ITEMS_FAILURE, error }
}

export function clearLineItems() {
  return { type: CLEAR_LINE_ITEMS }
}

// create lineItem
export function createLineItem(lineItemData, platform, updatedSettings) {
  return { type: CREATE_LINE_ITEM, lineItemData, platform, updatedSettings }
}

export function createLineItemSuccess(lineItemData) {
  // reset saved data, todo disabled till post MVP
  // if (platform === 'fb') {
  //   setSessionStorage(LINE_ITEM_FACEBOOK_CREATE, {})
  // }

  return { type: CREATE_LINE_ITEM_SUCCESS, lineItemData }
}

export function createLineItemFailure(error) {
  return { type: CREATE_LINE_ITEM_FAILURE, error }
}

export function clearCreateLineItem() {
  return { type: CLEAR_CREATE_LINE_ITEM }
}

// delete lineItem
export function deleteLineItem(params) {
  return { type: DELETE_LINE_ITEM, params }
}

export function deleteLineItemSuccess(id) {
  return { type: DELETE_LINE_ITEM_SUCCESS, id }
}

export function deleteLineItemFailure(error) {
  return { type: DELETE_LINE_ITEM_FAILURE, error }
}

export function clearDeleteLineItem() {
  return { type: CLEAR_DELETE_LINE_ITEM }
}

// update lineItem
export function updateLineItem(
  lineItemData,
  id,
  updateOptions = {
    platform: '',
    requestMethod: 'PATCH'
  }
) {
  return { type: UPDATE_LINE_ITEM, lineItemData, id, updateOptions }
}

export function updateLineItemSuccess(lineItemData) {
  return { type: UPDATE_LINE_ITEM_SUCCESS, lineItemData }
}

export function updateLineItemFailure(error) {
  return { type: UPDATE_LINE_ITEM_FAILURE, error }
}

export function clearUpdateLineItem() {
  return { type: CLEAR_UPDATE_LINE_ITEM }
}

// duplicate lineItem
export function duplicateLineItem(duplicateData, id, platform) {
  return { type: DUPLICATE_LINE_ITEM, duplicateData, id, platform }
}

// DUPLICATE_LINE_ITEM_SUCCESS is a general action which fires in lineItem saga,
// when we get success response from "/line_items/:id/copy/" endpoint
export function duplicateLineItemSuccess(asyncSessionData) {
  return { type: DUPLICATE_LINE_ITEM_SUCCESS, asyncSessionData }
}

// DUPLICATE_LINE_ITEM_COMPLETE is an action which we fire manually in a component,
// when we get 'COMPLETED' status from '/async_sessions/:id/' endpoint
// We need this action to push new item to the lineItems list
export function duplicateLineItemComplete(duplicatedLineItem) {
  return { type: DUPLICATE_LINE_ITEM_COMPLETE, duplicatedLineItem }
}

export function duplicateLineItemFailure(error) {
  return { type: DUPLICATE_LINE_ITEM_FAILURE, error }
}

export function clearDuplicateLineItem() {
  return { type: CLEAR_DUPLICATE_LINE_ITEM }
}

// get locations
export function getLocations() {
  return { type: GET_LOCATIONS }
}

export function getLocationsSuccess(locationsData) {
  return { type: GET_LOCATIONS_SUCCESS, locationsData }
}

export function getLocationsFailure(error) {
  return { type: GET_LOCATIONS_FAILURE, error }
}

// get goals
export function getGoals(objective) {
  return { type: GET_GOALS, objective }
}

export function getGoalsSuccess(goalsData) {
  return { type: GET_GOALS_SUCCESS, goalsData }
}

export function getGoalsFailure(error) {
  return { type: GET_GOALS_FAILURE, error }
}

export function clearGoals() {
  return { type: CLEAR_GOALS }
}

// get pixelsStats
export function getPixelsStats(id) {
  return { type: GET_PIXELS_STATS, id }
}

export function getPixelsStatsSuccess(pixelsStatsData) {
  return { type: GET_PIXELS_STATS_SUCCESS, pixelsStatsData }
}

export function getPixelsStatsFailure(error) {
  return { type: GET_PIXELS_STATS_FAILURE, error }
}

// Get line item criterions
export function getLineItemCriterions(params) {
  return { type: GET_LINE_ITEM_CRITERIONS, params }
}

export function getLineItemCriterionsSuccess(data) {
  return { type: GET_LINE_ITEM_CRITERIONS_SUCCESS, data }
}

export function getLineItemCriterionsFailure(error) {
  return { type: GET_LINE_ITEM_CRITERIONS_FAILURE, error }
}

export function clearLineItemCriterions() {
  return { type: CLEAR_LINE_ITEM_CRITERIONS }
}

// Create line item criterions
export function createLineItemCriterions(data, criterionsToDelete) {
  return { type: CREATE_LINE_ITEM_CRITERIONS, data, criterionsToDelete }
}

export function createLineItemCriterionsSuccess(data) {
  return { type: CREATE_LINE_ITEM_CRITERIONS_SUCCESS, data }
}

export function createLineItemCriterionsFailure(error) {
  return { type: CREATE_LINE_ITEM_CRITERIONS_FAILURE, error }
}

export function clearCreateLineItemCriterions() {
  return { type: CLEAR_CREATE_LINE_ITEM_CRITERIONS }
}

// this action used to delete criterions from state, when we use remove_operations field
// to delete criterions inside create request (createLineItemCriterions)
export function deleteLineItemCriterions(criterionsToDelete) {
  return { type: DELETE_LINE_ITEM_CRITERIONS, criterionsToDelete }
}
