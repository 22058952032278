import { createStyles } from '../../../../styles/helpers'
import { green, mediumGrey, orange, red } from '../../../../styles/const/colors'

const useStyles = createStyles({
  container: {
    display: 'flex',
    gap: 10,
    alignItems: 'center'
  },
  thumbnail: {
    width: 24,
    height: 24,
    maxWidth: 24,
    maxHeight: 24,
    objectFit: 'contain'
  },
  thumbnailContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    border: `2px solid ${mediumGrey}`,
    width: 34,
    height: 34,
    textAlign: 'center'
  },
  thumbnailApprovedStatus: {
    borderColor: green
  },
  thumbnailDisapprovedStatus: {
    borderColor: red
  },
  pendingBuyerApproval: {
    border: `2px solid ${orange}`
  },
  fileFormatIcon: {
    display: 'flex',
    flexDirection: 'column'
  },
  fileFormat: {
    position: 'static'
  }
})

export default useStyles
