import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { mediaOrderSelector } from '../../../../../../../modules/selectors/mediaOrders'
import { getAllMediaOrderProducts } from './helpers'

export const SelectedMediaProductsContext = React.createContext()

export function SelectedMediaProductsProvider({ children }) {
  const mediaOrder = useSelector(mediaOrderSelector)
  const [selectedProductsData, setSelectedProductsData] = useState(getAllMediaOrderProducts(mediaOrder))
  const [contractSelectedProducts, setContractSelectedProducts] = useState([])
  // newProduct is handled through the ref, because state was creating infinite re-rendering on formik values update
  const newProductRef = useRef()

  return (
    <SelectedMediaProductsContext.Provider
      value={{
        selectedProductsData,
        setSelectedProductsData,
        contractSelectedProducts,
        setContractSelectedProducts,
        newProductRef
      }}
    >
      {children}
    </SelectedMediaProductsContext.Provider>
  )
}
