import { createStyles } from '../../../../../styles/helpers'
import { expandableRowPadding, rowMinHeight } from '../../../styles'

export default createStyles(theme => ({
  expandableRow: {
    border: 'none',
    marginBottom: 0,
    // set padding 0 to expandableRow because all sub-rows already have the same padding
    padding: '0 !important',
    display: 'block'
  },
  expandableRowHeader: {
    cursor: 'unset',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: expandableRowPadding,
    minHeight: rowMinHeight
  },
  minimisedSectionButton: {
    cursor: 'pointer',
    display: 'inline-flex',
    width: '28px',
    padding: '10px 6px',
    top: '50%',
    left: '12px',
    transform: 'translateY(-50%)'
  }
}))
