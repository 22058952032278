import React from 'react'
import useStyles from './styles'

import PropTypes from 'prop-types'

const AccountBrandCell = ({ account, brand, isSmall }) => {
  const classes = useStyles()

  return (
    <div className={classes.accountBrandContainer}>
      {isSmall ? <h5>{account}</h5> : <h4>{account}</h4>}
      {brand && <p className={classes.brand}>{brand}</p>}
    </div>
  )
}

AccountBrandCell.propTypes = {
  account: PropTypes.string.isRequired,
  brand: PropTypes.string,
  isSmall: PropTypes.bool
}

export default AccountBrandCell
