import update from 'immutability-helper'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_MEMBERS_LIST,
  GET_MEMBERS_LIST_SUCCESS,
  GET_MEMBERS_LIST_FAILURE,
  GET_INVITES_LIST,
  GET_INVITES_LIST_SUCCESS,
  GET_INVITES_LIST_FAILURE,
  CREATE_MEMBER,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_FAILURE,
  UPDATE_SELF_ACCOUNT_MEMBER,
  UPDATE_SELF_ACCOUNT_MEMBER_SUCCESS,
  UPDATE_SELF_ACCOUNT_MEMBER_FAILURE,
  CLEAR_MEMBERS_LIST,
  CLEAR_INVITES_LIST,
  CREATE_INVITE,
  CREATE_INVITE_SUCCESS,
  CREATE_INVITE_FAILURE,
  RESEND_INVITE,
  RESEND_INVITE_SUCCESS,
  RESEND_INVITE_FAILURE,
  DELETE_INVITE,
  DELETE_INVITE_SUCCESS,
  DELETE_INVITE_FAILURE,
  UPDATE_MEMBER_STATUS,
  UPDATE_MEMBER_STATUS_SUCCESS,
  UPDATE_MEMBER_STATUS_FAILURE,
  CLEAR_UPDATE_MEMBER,
  ACCEPT_INVITE,
  ACCEPT_INVITE_SUCCESS,
  ACCEPT_INVITE_FAILURE,
  CLEAR_ACCEPT_INVITE,
  CLEAR_CREATE_MEMBER,
  CLEAR_CREATE_INVITE
} from '../actions/selfAccountTeam'
import { deleteItemById, updateItem } from '../../helpers/modules/reducerHelpers'
import { MEMBER_CHANGE_DATA, MEMBER_CHANGE_STATUS } from '../../constants/forms'
import {
  INITIAL_CREATE_REDUCER,
  INITIAL_LOADING_REDUCER,
  INITIAL_DELETE_REDUCER,
  INITIAL_UPDATE_REDUCER,
  INITIAL_GET_RESULTS_REDUCER
} from '../../constants/reducer'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  membersList: {
    members: INITIAL_GET_RESULTS_REDUCER,
    createdMember: INITIAL_CREATE_REDUCER,
    updatedMember: {
      ...INITIAL_UPDATE_REDUCER,
      updatingType: null
    }
  },
  invitesList: {
    invites: INITIAL_GET_RESULTS_REDUCER,
    createdInvite: INITIAL_CREATE_REDUCER,
    deletedInvite: INITIAL_DELETE_REDUCER,
    acceptedInvite: {
      ...INITIAL_LOADING_REDUCER,
      wasAccepted: false
    },
    reinvitedUser: {
      wasSent: false,
      id: null,
      error: null
    }
  }
}

export default function selfAccountTeamMembers(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    case GET_MEMBERS_LIST:
      return update(state, {
        membersList: {
          members: {
            $merge: {
              isLoading: true
            }
          }
        }
      })
    case GET_MEMBERS_LIST_SUCCESS:
      return update(state, {
        membersList: {
          members: {
            $merge: {
              isLoading: false,
              wasLoaded: true,
              results: action.membersListData.results
            }
          }
        }
      })
    case GET_MEMBERS_LIST_FAILURE:
      return update(state, {
        membersList: {
          members: {
            $merge: {
              isLoading: false,
              error: action.error
            }
          }
        }
      })
    case GET_INVITES_LIST:
      return update(state, {
        invitesList: {
          invites: {
            $merge: {
              isLoading: true
            }
          }
        }
      })
    case GET_INVITES_LIST_SUCCESS:
      return update(state, {
        invitesList: {
          invites: {
            $merge: {
              isLoading: false,
              wasLoaded: true,
              results: action.invitesListData.results
            }
          }
        }
      })
    case GET_INVITES_LIST_FAILURE:
      return update(state, {
        invitesList: {
          invites: {
            $merge: {
              isLoading: false,
              error: action.error
            }
          }
        }
      })
    case CREATE_MEMBER:
      return update(state, {
        membersList: {
          createdMember: { $merge: { isLoading: true } }
        }
      })
    case CREATE_MEMBER_SUCCESS:
      return update(state, {
        membersList: {
          createdMember: {
            $merge: {
              wasCreated: true,
              isLoading: false,
              id: action.memberData.id
            }
          },
          members: {
            results: {
              $push: [action.memberData]
            }
          }
        }
      })
    case CREATE_MEMBER_FAILURE:
      return update(state, {
        membersList: {
          createdMember: { $merge: { error: action.error, isLoading: false } }
        }
      })
    case CREATE_INVITE:
      return update(state, {
        invitesList: {
          createdInvite: { $merge: { isLoading: true } }
        }
      })
    case CREATE_INVITE_SUCCESS:
      return update(state, {
        invitesList: {
          createdInvite: {
            $merge: {
              wasCreated: true,
              isLoading: false,
              id: action.inviteData.id
            }
          },
          invites: {
            results: {
              $push: [action.inviteData]
            }
          }
        }
      })
    case CREATE_INVITE_FAILURE:
      return update(state, {
        invitesList: {
          createdInvite: { $merge: { error: action.error, isLoading: false } }
        }
      })
    case ACCEPT_INVITE: {
      return update(state, {
        invitesList: {
          acceptedInvite: {
            $merge: {
              isLoading: true
            }
          }
        }
      })
    }
    case ACCEPT_INVITE_SUCCESS: {
      return update(state, {
        invitesList: {
          acceptedInvite: {
            $merge: {
              isLoading: false,
              wasAccepted: true
            }
          }
        }
      })
    }
    case ACCEPT_INVITE_FAILURE: {
      return update(state, {
        invitesList: {
          acceptedInvite: {
            $merge: {
              isLoading: false,
              error: action.error
            }
          }
        }
      })
    }
    case CLEAR_ACCEPT_INVITE: {
      return update(state, {
        invitesList: {
          acceptedInvite: {
            $set: initialState.invitesList.acceptedInvite
          }
        }
      })
    }
    case UPDATE_SELF_ACCOUNT_MEMBER:
      return update(state, {
        membersList: {
          updatedMember: {
            $set: {
              isLoading: true,
              id: action.id,
              updatingType: MEMBER_CHANGE_DATA
            }
          }
        }
      })
    case UPDATE_SELF_ACCOUNT_MEMBER_SUCCESS:
      return update(state, {
        membersList: {
          updatedMember: {
            $merge: {
              isLoading: false,
              wasUpdated: true
            }
          },
          members: {
            results: {
              $apply: items => updateItem(items, action.updatedMemberData)
            }
          }
        }
      })
    case UPDATE_SELF_ACCOUNT_MEMBER_FAILURE:
      return update(state, {
        membersList: {
          updatedMember: {
            $merge: {
              isLoading: false,
              wasUpdated: false,
              error: action.error
            }
          }
        }
      })
    case UPDATE_MEMBER_STATUS:
      return update(state, {
        membersList: {
          updatedMember: {
            $set: {
              isLoading: true,
              id: action.id,
              updatingType: MEMBER_CHANGE_STATUS
            }
          }
        }
      })
    case UPDATE_MEMBER_STATUS_SUCCESS:
      return update(state, {
        membersList: {
          updatedMember: {
            $merge: {
              isLoading: false,
              wasUpdated: true
            }
          },
          members: {
            results: {
              $apply: items => updateItem(items, action.updatedMemberData)
            }
          }
        }
      })
    case UPDATE_MEMBER_STATUS_FAILURE:
      return update(state, {
        membersList: {
          updatedMember: {
            $merge: {
              isLoading: false,
              wasUpdated: false,
              error: action.error
            }
          }
        }
      })
    case DELETE_INVITE:
      return update(state, {
        invitesList: {
          deletedInvite: { $set: { isLoading: true, id: action.id } }
        }
      })
    case DELETE_INVITE_SUCCESS:
      return update(state, {
        invitesList: {
          invites: {
            results: {
              $apply: items => deleteItemById(items, action.id)
            }
          },
          deletedInvite: { $merge: { isLoading: false } }
        }
      })
    case DELETE_INVITE_FAILURE:
      return update(state, {
        invitesList: {
          deletedInvite: { $merge: { isLoading: false, error: action.error } }
        }
      })
    case RESEND_INVITE:
      return update(state, {
        invitesList: {
          reinvitedUser: { $set: { isLoading: true, id: action.id } }
        }
      })
    case RESEND_INVITE_SUCCESS:
      return update(state, {
        invitesList: {
          reinvitedUser: { $merge: { isLoading: false, wasSent: true } }
        }
      })
    case RESEND_INVITE_FAILURE:
      return update(state, {
        invitesList: {
          reinvitedUser: { $merge: { isLoading: false, wasSent: false, error: action.error } }
        }
      })
    case CLEAR_MEMBERS_LIST:
      return update(state, {
        membersList: { $set: initialState.membersList }
      })
    case CLEAR_INVITES_LIST:
      return update(state, {
        invitesList: { $set: initialState.invitesList }
      })
    case CLEAR_UPDATE_MEMBER:
      return update(state, {
        membersList: {
          updatedMember: {
            $set: initialState.membersList.updatedMember
          }
        }
      })
    case CLEAR_CREATE_MEMBER:
      return update(state, {
        membersList: {
          createdMember: {
            $set: initialState.membersList.createdMember
          }
        }
      })
    case CLEAR_CREATE_INVITE:
      return update(state, {
        invitesList: {
          createdInvite: {
            $set: initialState.invitesList.createdInvite
          }
        }
      })
    default:
      return state
  }
}
