// @ts-nocheck // todo remove when finish migrating to ts this file

import { concat } from '../../../helpers/common'

import { CreateAdData, AdsResponse, AdsError, GetAdsParams } from './types'
import { PlatformType } from '../../../types'

const MODULE_NAME = 'ADS/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_ADS = concat(MODULE_NAME, 'GET_ADS')
export const GET_ADS_SUCCESS = concat(MODULE_NAME, 'GET_ADS_SUCCESS')
export const GET_ADS_FAILURE = concat(MODULE_NAME, 'GET_ADS_FAILURE')
export const CLEAR_GET_ADS = concat(MODULE_NAME, 'CLEAR_GET_ADS')

export const CREATE_AD = concat(MODULE_NAME, 'CREATE_AD')
export const CREATE_AD_SUCCESS = concat(MODULE_NAME, 'CREATE_AD_SUCCESS')
export const CREATE_AD_FAILURE = concat(MODULE_NAME, 'CREATE_AD_FAILURE')
export const CLEAR_CREATE_AD = concat(MODULE_NAME, 'CLEAR_CREATE_AD')

export const CREATE_ADS_BATCH = concat(MODULE_NAME, 'CREATE_ADS_BATCH')
export const CREATE_ADS_BATCH_SUCCESS = concat(MODULE_NAME, 'CREATE_ADS_BATCH_SUCCESS')
export const CREATE_ADS_BATCH_FAILURE = concat(MODULE_NAME, 'CREATE_ADS_BATCH_FAILURE')
export const CLEAR_CREATE_ADS_BATCH = concat(MODULE_NAME, 'CLEAR_CREATE_ADS_BATCH')

export const VALIDATE_AD = concat(MODULE_NAME, 'VALIDATE_AD')
export const VALIDATE_AD_SUCCESS = concat(MODULE_NAME, 'VALIDATE_AD_SUCCESS')
export const VALIDATE_AD_FAILURE = concat(MODULE_NAME, 'VALIDATE_AD_FAILURE')
export const CLEAR_VALIDATE_AD = concat(MODULE_NAME, 'CLEAR_VALIDATE_AD')

export const UPDATE_AD = concat(MODULE_NAME, 'UPDATE_AD')
export const UPDATE_AD_SUCCESS = concat(MODULE_NAME, 'UPDATE_AD_SUCCESS')
export const UPDATE_AD_FAILURE = concat(MODULE_NAME, 'UPDATE_AD_FAILURE')
export const CLEAR_UPDATE_AD = concat(MODULE_NAME, 'CLEAR_UPDATE_AD')

export const DELETE_AD = concat(MODULE_NAME, 'DELETE_AD')
export const DELETE_AD_SUCCESS = concat(MODULE_NAME, 'DELETE_AD_SUCCESS')
export const DELETE_AD_FAILURE = concat(MODULE_NAME, 'DELETE_AD_FAILURE')
export const CLEAR_DELETE_AD = concat(MODULE_NAME, 'CLEAR_DELETE_AD')

export const GET_AD = concat(MODULE_NAME, 'GET_AD')
export const GET_AD_SUCCESS = concat(MODULE_NAME, 'GET_AD_SUCCESS')
export const GET_AD_FAILURE = concat(MODULE_NAME, 'GET_AD_FAILURE')
export const CLEAR_AD = concat(MODULE_NAME, 'CLEAR_AD')

// copy ad
export const SET_SELECTED_AD_FOR_COPY = concat(MODULE_NAME, 'SET_SELECTED_AD_FOR_COPY')
export const CLEAR_SELECTED_AD_FOR_COPY = concat(MODULE_NAME, 'CLEAR_SELECTED_AD_FOR_COPY')

export const COPY_AD = concat(MODULE_NAME, 'COPY_AD')
export const COPY_AD_SUCCESS = concat(MODULE_NAME, 'COPY_AD_SUCCESS')
export const COPY_AD_FAILURE = concat(MODULE_NAME, 'COPY_AD_FAILURE')
export const CLEAR_COPY_AD = concat(MODULE_NAME, 'CLEAR_COPY_AD')
// copy ad end

export const APPROVE_INTERNAL_AD = concat(MODULE_NAME, 'APPROVE_INTERNAL_AD')
export const APPROVE_INTERNAL_AD_SUCCESS = concat(MODULE_NAME, 'APPROVE_INTERNAL_AD_SUCCESS')
export const APPROVE_INTERNAL_AD_FAILURE = concat(MODULE_NAME, 'APPROVE_INTERNAL_AD_FAILURE')
export const CLEAR_APPROVE_INTERNAL_AD = concat(MODULE_NAME, 'CLEAR_APPROVE_INTERNAL_AD')

export const DISAPPROVE_INTERNAL_AD = concat(MODULE_NAME, 'DISAPPROVE_INTERNAL_AD')
export const DISAPPROVE_INTERNAL_AD_SUCCESS = concat(MODULE_NAME, 'DISAPPROVE_INTERNAL_AD_SUCCESS')
export const DISAPPROVE_INTERNAL_AD_FAILURE = concat(MODULE_NAME, 'DISAPPROVE_INTERNAL_AD_FAILURE')
export const CLEAR_DISAPPROVE_INTERNAL_AD = concat(MODULE_NAME, 'CLEAR_DISAPPROVE_INTERNAL_AD')

export const DELETE_INTERNAL_AD = concat(MODULE_NAME, 'DELETE_INTERNAL_AD')
export const DELETE_INTERNAL_AD_SUCCESS = concat(MODULE_NAME, 'DELETE_INTERNAL_AD_SUCCESS')
export const DELETE_INTERNAL_AD_FAILURE = concat(MODULE_NAME, 'DELETE_INTERNAL_AD_FAILURE')
export const CLEAR_DELETE_INTERNAL_AD = concat(MODULE_NAME, 'CLEAR_DELETE_INTERNAL_AD')

// modified data used for AdPreview component to represent unsaved changes from EditForm
export const SET_AD_MODIFIED_DATA = concat(MODULE_NAME, 'SET_AD_MODIFIED_DATA')
export const CLEAR_AD_PREVIEW = concat(MODULE_NAME, 'CLEAR_AD_PREVIEW')
export const SET_AD_CAROUSEL_SELECTED_INDEX = concat(MODULE_NAME, 'SET_AD_CAROUSEL_SELECTED_INDEX')
export const TOGGLE_IS_SOUND_MUTED = concat(MODULE_NAME, 'TOGGLE_IS_SOUND_MUTED')

export const GET_INTERNAL_ADS_ITEMS_COUNT = concat(MODULE_NAME, 'GET_INTERNAL_ADS_ITEMS_COUNT')
export const GET_INTERNAL_ADS_ITEMS_COUNT_SUCCESS = concat(MODULE_NAME, 'GET_INTERNAL_ADS_ITEMS_COUNT_SUCCESS')
export const GET_INTERNAL_ADS_ITEMS_COUNT_FAILURE = concat(MODULE_NAME, 'GET_INTERNAL_ADS_ITEMS_COUNT_FAILURE')
export const DECREMENT_INTERNAL_ADS_ITEMS_COUNT = concat(MODULE_NAME, 'DECREMENT_INTERNAL_ADS_ITEMS_COUNT')
export const CLEAR_INTERNAL_ADS_ITEMS_COUNT = concat(MODULE_NAME, 'CLEAR_INTERNAL_ADS_ITEMS_COUNT')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

export function getAds(params: GetAdsParams): GetAdsParams {
  return { type: GET_ADS, params }
}

export function getAdsSuccess(data: AdsResponse) {
  return { type: GET_ADS_SUCCESS, data }
}

export function getAdsFailure(error: AdsError) {
  return { type: GET_ADS_FAILURE, error }
}

export function clearAds() {
  return { type: CLEAR_GET_ADS }
}

// create ad
export function createAd(adData: AdData, platform: PlatformType): CreateAdAction {
  return { type: CREATE_AD, adData, platform }
}
export function createAdSuccess(adData: CreateAdData): CreateAdSuccessAction {
  // reset saved form data, todo disabled till post MVP
  return { type: CREATE_AD_SUCCESS, adData }
}
export function createAdFailure(error: string): CreateAdFailureAction {
  return { type: CREATE_AD_FAILURE, error }
}
export function clearCreateAd(): ClearCreateAdAction {
  return { type: CLEAR_CREATE_AD }
}

export function createAdsBatch(adsData, platform) {
  return { type: CREATE_ADS_BATCH, adsData, platform }
}

export function createAdsBatchSuccess(adsData) {
  return { type: CREATE_ADS_BATCH_SUCCESS, adsData }
}

export function createAdsBatchFailure(error) {
  return { type: CREATE_ADS_BATCH_FAILURE, error }
}

export function clearCreateAdsBatch() {
  return { type: CLEAR_CREATE_ADS_BATCH }
}

// validate ad
export function validateAd(adData, platform) {
  return { type: VALIDATE_AD, adData, platform }
}

export function validateAdSuccess(adData, conversionDomain) {
  return { type: VALIDATE_AD_SUCCESS, adData, conversionDomain }
}

export function validateAdFailure(error, conversionDomain) {
  return { type: VALIDATE_AD_FAILURE, error, conversionDomain }
}

export function clearValidateAd() {
  return { type: CLEAR_VALIDATE_AD }
}

// update ad
export function updateAd(adData, id, requestMethod = 'PATCH', platform) {
  return { type: UPDATE_AD, adData, id, requestMethod, platform }
}

export function updateAdSuccess(adData) {
  return { type: UPDATE_AD_SUCCESS, adData }
}

export function updateAdFailure(error) {
  return { type: UPDATE_AD_FAILURE, error }
}

export function clearUpdateAd() {
  return { type: CLEAR_UPDATE_AD }
}

export function deleteAd({ id, ...params }) {
  return { type: DELETE_AD, params, id }
}

export function deleteAdSuccess(id) {
  return { type: DELETE_AD_SUCCESS, id }
}

export function deleteAdFailure(error) {
  return { type: DELETE_AD_FAILURE, error }
}

export function clearDeleteAd() {
  return { type: CLEAR_DELETE_AD }
}

// get ad
export function getAd(params, pushToList) {
  return { type: GET_AD, params, pushToList }
}

export function getAdSuccess(adData, pushToList) {
  return { type: GET_AD_SUCCESS, adData, pushToList }
}

export function getAdFailure(error) {
  return { type: GET_AD_FAILURE, error }
}

export function clearAd() {
  return { type: CLEAR_AD }
}

// copy ad
export function setSelectedAdForCopy(adData) {
  return { type: SET_SELECTED_AD_FOR_COPY, adData }
}

export function clearSelectedAdForCopy() {
  return { type: CLEAR_SELECTED_AD_FOR_COPY }
}

export function copyAd(data, adId) {
  return { type: COPY_AD, data, adId }
}

export function copyAdSuccess(copiedAdDetails) {
  return { type: COPY_AD_SUCCESS, copiedAdDetails }
}

export function copyAdFailure(error) {
  return { type: COPY_AD_FAILURE, error }
}

export function clearCopyAd() {
  return { type: CLEAR_COPY_AD }
}

// Approve internal ad
export function approveInternalAd(id, provider) {
  return { type: APPROVE_INTERNAL_AD, id, provider }
}

export function approveInternalAdSuccess(id, data) {
  return { type: APPROVE_INTERNAL_AD_SUCCESS, id, data }
}

export function approveInternalAdFailure(error) {
  return { type: APPROVE_INTERNAL_AD_FAILURE, error }
}

export function clearApproveInternalAd() {
  return { type: CLEAR_APPROVE_INTERNAL_AD }
}

// Disapprove internal ad
export function disapproveInternalAd(id, data, provider) {
  return { type: DISAPPROVE_INTERNAL_AD, id, data, provider }
}

export function disapproveInternalAdSuccess(id) {
  return { type: DISAPPROVE_INTERNAL_AD_SUCCESS, id }
}

export function disapproveInternalAdFailure(error) {
  return { type: DISAPPROVE_INTERNAL_AD_FAILURE, error }
}

export function clearDisapproveInternalAd() {
  return { type: CLEAR_DISAPPROVE_INTERNAL_AD }
}

// Delete internal ad
export function deleteInternalAd(id) {
  return { type: DELETE_INTERNAL_AD, id }
}

export function deleteInternalAdSuccess(id) {
  return { type: DELETE_INTERNAL_AD_SUCCESS, id }
}

export function deleteInternalAdFailure(error) {
  return { type: DELETE_INTERNAL_AD_FAILURE, error }
}

export function clearDeleteInternalAd() {
  return { type: CLEAR_DELETE_INTERNAL_AD }
}

// modified data
export function setAdModifiedData(adModifiedData) {
  return { type: SET_AD_MODIFIED_DATA, adModifiedData }
}

export function clearAdPreview() {
  return { type: CLEAR_AD_PREVIEW }
}

export function setAdCarouselSelectedIndex(adId, selectedIndex) {
  return { type: SET_AD_CAROUSEL_SELECTED_INDEX, adId, selectedIndex }
}

export function toggleIsSoundMuted() {
  return { type: TOGGLE_IS_SOUND_MUTED }
}

export function getInternalAdsItemsCount(params) {
  return { type: GET_INTERNAL_ADS_ITEMS_COUNT, params }
}

export function getInternalAdsItemsCountSuccess(results) {
  return { type: GET_INTERNAL_ADS_ITEMS_COUNT_SUCCESS, results }
}

export function getInternalAdsItemsCountFailure(error) {
  return { type: GET_INTERNAL_ADS_ITEMS_COUNT_FAILURE, error }
}

export function decrementInternalAdsItemsCount(provider) {
  return { type: DECREMENT_INTERNAL_ADS_ITEMS_COUNT, provider }
}

export function clearInternalAdsItemsCount() {
  return { type: CLEAR_INTERNAL_ADS_ITEMS_COUNT }
}
