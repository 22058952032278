import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import TextSuggestions from '../../../../features/components/Form/TextSuggestions'

// loading and manage typing suggestions wrapper, it is used when the suggestions not passed and need to be handled
// over selector and pass into TextSuggestions
const FieldTextSuggestionsWrapper = ({
  value,
  suggestionsSelector,
  suggestionsLoadingSelector,
  onSelectSuggestion
}) => {
  const suggestions = useSelector(suggestionsSelector)
  const suggestionsIsLoading = useSelector(suggestionsLoadingSelector)

  return (
    <TextSuggestions
      suggestions={suggestions}
      onSelectSuggestion={onSelectSuggestion}
      selectedSuggestion={value}
      typingAnimation={suggestionsIsLoading}
    />
  )
}

FieldTextSuggestionsWrapper.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suggestionsSelector: PropTypes.func.isRequired,
  suggestionsLoadingSelector: PropTypes.func.isRequired,
  onSelectSuggestion: PropTypes.func.isRequired
}

export default FieldTextSuggestionsWrapper
