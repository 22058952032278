import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { formatCurrency } from '../../../../../../../helpers/numbers'

import useStyles from './styles'

function MediaOrderPrice({ currencySymbol, className, regularPrice, discountedPrice, isBold }) {
  const classes = useStyles({ isBold })

  const formattedRegularPrice = formatCurrency(regularPrice, { min: 2, max: 2 }, { symbol: currencySymbol })
  const formattedDiscountedPrice = formatCurrency(discountedPrice, { min: 2, max: 2 }, { symbol: currencySymbol })

  return (
    <div className={classnames(classes.prices, className)}>
      {!!discountedPrice && <div className={classes.previousPrice}>{formattedRegularPrice}</div>}
      <div className={classes.price}>{discountedPrice ? formattedDiscountedPrice : formattedRegularPrice}</div>
    </div>
  )
}

MediaOrderPrice.propTypes = {
  regularPrice: PropTypes.number.isRequired,
  discountedPrice: PropTypes.number,
  currencySymbol: PropTypes.string.isRequired,
  // styles
  className: PropTypes.string,
  isBold: PropTypes.bool
}

export default MediaOrderPrice
