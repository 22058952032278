import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../components/Form'
import ControllerInviteCreateFormContent from './ControllerInviteCreateFormContent'

import { transformValuesToBE } from './formatters'
import { getShowBrandCategorySelect, getShowMediaSubCategories } from '../../helpers'

import { clearCreateControllerInvite, createControllerInvite } from '../../../../../modules/actions/controller'
import {
  controllerInviteCreateErrorSelector,
  controllerInviteCreateIsLoadingSelector,
  controllerInviteWasCreatedSelector
} from '../../../../../modules/selectors/controller'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'
import { clearGetSelfAccountCategories, getSelfAccountCategories } from '../../../../../modules/actions/selfAccounts'
import { selfAccountCategoriesWasLoadedSelector } from '../../../../../modules/selectors/selfAccounts'
import { clearGetMediaSubCategories, getMediaSubCategories } from '../../../../../modules/actions/mediaOrdersProducts'
import { mediaSubCategoriesWasLoadedSelector } from '../../../../../modules/selectors/mediaOrdersProducts'

import { initialValues, ROLE } from './fields'
import { CONTROLLER_INVITE_CREATE } from '../../../../../constants/forms'
import { validationSchema } from './validation'

const ControllerInviteCreateForm = ({ controller }) => {
  const dispatch = useDispatch()

  const controllerInviteWasCreated = useSelector(controllerInviteWasCreatedSelector)
  const controllerId = useSelector(selectedControllerIdSelector)
  const selfAccountCategoriesWasLoaded = useSelector(selfAccountCategoriesWasLoadedSelector)
  const mediaSubCategoriesWasLoaded = useSelector(mediaSubCategoriesWasLoadedSelector)

  const handleSubmit = useCallback(
    values => {
      const formattedValues = transformValuesToBE(values)

      dispatch(
        createControllerInvite({
          controller: controller.id,
          ...formattedValues
        })
      )
    },
    [controller.id, dispatch]
  )

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: handleSubmit
  })

  const handleClearCreateControllerInvite = useCallback(() => {
    dispatch(clearCreateControllerInvite())
  }, [dispatch])

  const { values } = formik
  const showBrandCategorySelect = getShowBrandCategorySelect(values[ROLE])
  const showMediaSubCategories = getShowMediaSubCategories(values[ROLE])

  useEffect(() => {
    if (showBrandCategorySelect && !selfAccountCategoriesWasLoaded) {
      dispatch(
        getSelfAccountCategories({
          controller: controllerId
        })
      )
    }
  }, [dispatch, controllerId, showBrandCategorySelect, selfAccountCategoriesWasLoaded])

  useEffect(() => {
    if (showMediaSubCategories) {
      dispatch(
        getMediaSubCategories({
          controller: controllerId
        })
      )
    }
  }, [dispatch, controllerId, showMediaSubCategories])

  useEffect(() => {
    return () => {
      if (selfAccountCategoriesWasLoaded) {
        dispatch(clearGetSelfAccountCategories())
      }
    }
  }, [dispatch, selfAccountCategoriesWasLoaded])

  useEffect(() => {
    return () => {
      if (mediaSubCategoriesWasLoaded) {
        dispatch(clearGetMediaSubCategories())
      }
    }
  }, [dispatch, mediaSubCategoriesWasLoaded])

  return (
    <Form
      formName={CONTROLLER_INVITE_CREATE}
      formik={formik}
      errorSelector={controllerInviteCreateErrorSelector}
      isLoadingSelector={controllerInviteCreateIsLoadingSelector}
      successSubmit={controllerInviteWasCreated}
      clearHandler={handleClearCreateControllerInvite}
    >
      <ControllerInviteCreateFormContent formik={formik} showBrandCategorySelect={showBrandCategorySelect} />
    </Form>
  )
}

export default ControllerInviteCreateForm
