import React, { useContext } from 'react'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { ReactComponent as PencilIcon } from '../../../../../../../../../../assets/icons/pencil.svg'

import ProductQuantity from './ProductQuantity'
import TotalProductPrice from './TotalProductPrice'
import SelectedPeriodsList from './SelectedPeriodsList'
import { DiscountsContext } from '../../../../DiscountsContext'
import ButtonIcon from '../../../../../../../../../../features/components/Buttons/ButtonIcon'
import ProductVariablesList from './ProductVariablesList'

import { useProductQuantityLimits } from './useProductQuantityLimits'

import { setProductPeriodsUpdate } from '../../../../../../../../../../modules/actions/mediaOrders'

import { VARIABLES } from '../../../../../../../../MediaProductForms/fields'
import { PRODUCT_PERIODS_DATES, QUANTITY } from '../../../../../../../fields'

import useStyles from './styles'

function ProductBreakdown({ formik, productValues, productPath, currency, minQuantity, onProductRemove }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { values, setFieldValue, errors } = formik
  const { t } = useTranslation()

  const { productsDiscount } = useContext(DiscountsContext)

  const productData = productValues.data
  const isProductGroup = productData?.group
  const productsQuantity = productValues[QUANTITY]
  const productPublicationsError = getIn(errors, productPath)?.[PRODUCT_PERIODS_DATES]
  const maxProductQuantity = useProductQuantityLimits(productValues[PRODUCT_PERIODS_DATES])

  const handleProductPeriodsSetup = () => {
    // this function is used to represent ProductSetupStep and allow user to select dates for the product
    const productValue = getIn(values, productPath)

    // set flag to determine if product periods are being updated and not itinial setup
    dispatch(setProductPeriodsUpdate(true))

    setFieldValue(productPath, {
      ...productValue,
      // reset quantity to 1 when user is setting up periods to avoid inconsistency when there are not enough
      // inventory for the selected quantity
      [QUANTITY]: 1,
      isSetupAllowed: true
    })
  }

  const currentDiscount = productsDiscount.find(
    discountProduct => discountProduct.id === productData.id
  )?.discountPercentage

  return (
    <div className={classes.product} key={productData.id}>
      <div className={classes.productDetails}>
        <h4 className={classes.name}>{productData.name}</h4>
        <ButtonIcon
          className={classes.editBtn}
          iconClass={classes.editIcon}
          Icon={PencilIcon}
          onClick={handleProductPeriodsSetup}
        />

        {/*don't show button when not possible to add more products or delete */}
        {minQuantity !== maxProductQuantity && (
          <ProductQuantity
            formik={formik}
            productsQuantity={productsQuantity}
            productPath={productPath}
            minQuantity={minQuantity}
            maxQuantity={maxProductQuantity}
            onProductRemove={onProductRemove}
          />
        )}
      </div>
      {isProductGroup && (
        <div className={classes.subProductsList}>
          {productData?.selectedSubProducts?.map(subProduct => subProduct.name).join(' | ')}
        </div>
      )}
      <ProductVariablesList variables={productValues[VARIABLES]} />
      <SelectedPeriodsList
        currencySymbol={currency.symbol}
        productPeriods={productValues[PRODUCT_PERIODS_DATES]}
        currentDiscount={currentDiscount}
        productsQuantity={productsQuantity}
        handleProductPeriodsSetup={handleProductPeriodsSetup}
        productPublicationsError={productPublicationsError}
        showPeriodPrice
      />
      <div className={classes.total}>
        <h4>{t('Total:')}</h4>
        <TotalProductPrice
          className={classes.price}
          // total quantity of all publications for current product
          productQuantity={productsQuantity}
          currentDiscount={currentDiscount}
          publications={productValues[PRODUCT_PERIODS_DATES]}
          currencySymbol={currency.symbol}
        />
      </div>
    </div>
  )
}

ProductBreakdown.propTypes = {
  formik: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  onProductRemove: PropTypes.func.isRequired,
  productValues: PropTypes.object.isRequired,
  productPath: PropTypes.string.isRequired,
  minQuantity: PropTypes.number
}

export default ProductBreakdown
