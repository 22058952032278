import { call, put, all, takeEvery, select } from 'redux-saga/effects'

import {
  GET_MEMBERS_LIST,
  getMembersListSuccess,
  getMembersListFailure,
  GET_INVITES_LIST,
  getInvitesListSuccess,
  getInvitesListFailure,
  CREATE_MEMBER,
  createMemberSuccess,
  createMemberFailure,
  UPDATE_SELF_ACCOUNT_MEMBER,
  updateSelfAccountMemberSuccess,
  updateSelfAccountMemberFailure,
  CREATE_INVITE,
  createInviteSuccess,
  createInviteFailure,
  ACCEPT_INVITE,
  acceptInviteSuccess,
  acceptInviteFailure,
  RESEND_INVITE,
  resendInviteSuccess,
  resendInviteFailure,
  DELETE_INVITE,
  deleteInviteSuccess,
  deleteInviteFailure,
  UPDATE_MEMBER_STATUS,
  updateMemberStatusSuccess,
  updateMemberStatusFailure
} from '../actions/selfAccountTeam'
import {
  createInviteService,
  acceptInviteService,
  createMemberService,
  deleteInviteService,
  getInvitesListService,
  getMembersListService,
  resendInviteService,
  updateMemberService,
  updateMemberStatusService
} from '../services/selfAccountTeam'
import { getUserInvitesSuccess, showMFASetupPage } from '../actions/app'

import { encryptJWE } from '../../helpers/encrypt'
import { deleteItemById } from '../../helpers/modules/reducerHelpers'

import { submitLogin } from '../actions/cognito'
import { userInvitesSelector } from '../selectors/app'

import { EMAIL } from '../../pages/Unauthorized/Login/fields'
import { PASSWORD } from '../../pages/Unauthorized/CreatePassword/fields'

function* selfAccountTeamWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_MEMBERS_LIST, getMembersListWorker),
    takeEvery(GET_INVITES_LIST, getInvitesListWorker),
    takeEvery(CREATE_MEMBER, createMemberWatcher),
    takeEvery(CREATE_INVITE, createInviteWatcher),
    takeEvery(ACCEPT_INVITE, acceptInviteWatcher),
    takeEvery(UPDATE_SELF_ACCOUNT_MEMBER, updateMemberWorker),
    takeEvery(UPDATE_MEMBER_STATUS, updateMemberStatusWorker),
    takeEvery(DELETE_INVITE, deleteInviteWorker),
    takeEvery(RESEND_INVITE, resendInviteWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getMembersListWorker({ params }) {
  try {
    const response = yield call(getMembersListService, params)
    yield put(getMembersListSuccess(response))
  } catch (e) {
    yield put(getMembersListFailure(e))
  }
}

function* getInvitesListWorker({ params }) {
  try {
    const response = yield call(getInvitesListService, params)
    yield put(getInvitesListSuccess(response))
  } catch (e) {
    yield put(getInvitesListFailure(e))
  }
}

function* createMemberWatcher({ memberData }) {
  try {
    const response = yield call(createMemberService, memberData)
    yield put(createMemberSuccess(response))
  } catch (e) {
    yield put(createMemberFailure(e))
  }
}

function* createInviteWatcher({ inviteData }) {
  try {
    const response = yield call(createInviteService, inviteData)
    yield put(createInviteSuccess(response))
  } catch (e) {
    yield put(createInviteFailure(e))
  }
}

function* acceptInviteWatcher({ inviteData, submitLoginOnSuccess, removeInviteOnSuccess }) {
  try {
    const password = inviteData[PASSWORD]
    const encryptedPassword = yield call(encryptJWE, { [PASSWORD]: password })
    // password should be encrypted to token when sends to BE for security

    const response = yield call(acceptInviteService, { ...inviteData, [PASSWORD]: encryptedPassword })
    yield put(acceptInviteSuccess())

    if (submitLoginOnSuccess) {
      // registration flow
      // registration was successful, so we can submit login request
      yield put(
        submitLogin({
          [EMAIL]: response[EMAIL],
          [PASSWORD]: inviteData[PASSWORD]
        })
      )
      // show MFA setup page after registration
      yield put(showMFASetupPage(true))
    }

    if (removeInviteOnSuccess) {
      const invites = yield select(userInvitesSelector)

      const { account_invites: accountInvites } = invites

      const filteredAccountInvites = accountInvites.filter(item => item.token !== inviteData.token)

      yield put(getUserInvitesSuccess({ ...invites, account_invites: filteredAccountInvites }))
    }
  } catch (e) {
    // code is added as key for parsing errors inside the ProgressButton
    yield put(acceptInviteFailure({ ...e, code: 'encryption_error' }))
  }
}

function* updateMemberWorker({ id, data }) {
  try {
    const response = yield call(updateMemberService, id, data)
    yield put(updateSelfAccountMemberSuccess(response))
  } catch (e) {
    yield put(updateSelfAccountMemberFailure(e))
  }
}

function* updateMemberStatusWorker({ id, status }) {
  const isActive = status === 'active'

  try {
    yield call(updateMemberStatusService, id, status)
    yield put(updateMemberStatusSuccess({ id, is_active: !isActive }))
  } catch (e) {
    yield put(updateMemberStatusFailure(e))
  }
}

function* deleteInviteWorker({ id, removeInviteOnSuccess }) {
  try {
    yield call(deleteInviteService, id)
    yield put(deleteInviteSuccess(id))

    if (removeInviteOnSuccess) {
      const invites = yield select(userInvitesSelector)

      const { account_invites: accountInvites } = invites

      yield put(getUserInvitesSuccess({ ...invites, account_invites: deleteItemById(accountInvites, id) }))
    }
  } catch (e) {
    yield put(deleteInviteFailure(e))
  }
}

function* resendInviteWorker({ id }) {
  try {
    yield call(resendInviteService, id)
    yield put(resendInviteSuccess(id))
  } catch (e) {
    yield put(resendInviteFailure(e))
  }
}

export default selfAccountTeamWatcher
