import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Select from '../../../../components/Select'

import { bookedRevenueBreakdownsList } from './constants'

import useStyles from './styles'

const BreakdownFilter = ({ Context }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { breakdownFilter, setBreakdownFilter } = useContext(Context)

  const onCategoryFilterChange = useCallback(
    option => {
      setBreakdownFilter(option?.value)
    },
    [setBreakdownFilter]
  )

  return (
    <Select
      placeholder="Breakdown"
      isSelectedValueBrandPrimary={true}
      className={classes.breakDownSelect}
      value={breakdownFilter}
      onChange={onCategoryFilterChange}
      options={bookedRevenueBreakdownsList}
      prefix={t('Breakdown: ')}
      isSmall
    />
  )
}

export default BreakdownFilter
