import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import usePermissions from '../../../hooks/usePermissions'

import { openForm } from '../../../modules/actions/forms'
import { selectedPlatformSelector, selectedAdAccountIdSelector } from '../../../modules/selectors/app'

import { replaceRouteParams } from '../../../helpers/url'
import { getCampaignEditFormNameByPlatform } from '../../../features/helpers/constantHelpers'
import { insertIf } from '../../../helpers/common'

import { ROUTE_PARAMS, ROUTES } from '../../../constants/routes'
import { FACEBOOK_PLATFORM } from '../../../constants/selectLists/platformList'
import {
  // AUTOMATION_RULE_CREATE,
  CAMPAIGN_FACEBOOK_DUPLICATE
} from '../../../constants/forms'
import { CAMPAIGN_PERMISSION } from '../../../constants/permissions'

const useGetCampaignActionsDropdownOptions = ({ handleDeleteRequest }) => {
  const dispatch = useDispatch()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const selectedPlatform = useSelector(selectedPlatformSelector)

  const permissions = usePermissions()

  return useCallback(
    campaign => {
      const { id, status } = campaign

      const campaignSummaryLink = replaceRouteParams(ROUTES.campaignsSummary, { [ROUTE_PARAMS.campaignId]: id })

      const isItemArchived = status && status.toLowerCase() === 'archived'

      const deleteCampaignHandler = () => {
        handleDeleteRequest({
          item: campaign,
          account: selectedAdAccountId,
          campaignObjective: campaign.objective
        })
      }

      const openEditFormHandler = () => {
        dispatch(
          openForm({
            id,
            formName: getCampaignEditFormNameByPlatform(selectedPlatform)
          })
        )
      }

      const duplicateCampaignHandler = () => {
        dispatch(openForm({ id, formName: CAMPAIGN_FACEBOOK_DUPLICATE }))
      }

      // const createRuleHandler = () => {
      //   dispatch(openForm({ id, formName: AUTOMATION_RULE_CREATE }))
      // }

      return [
        {
          text: 'Go To Campaign',
          link: campaignSummaryLink
        },
        ...insertIf(permissions.can('delete', CAMPAIGN_PERMISSION), {
          text: isItemArchived || selectedPlatform !== FACEBOOK_PLATFORM ? 'Delete' : 'Archive / Delete',
          onClickHandler: deleteCampaignHandler
        }),
        ...insertIf(permissions.can('update', CAMPAIGN_PERMISSION), {
          text: 'Edit',
          onClickHandler: openEditFormHandler
        }),
        ...insertIf(selectedPlatform === FACEBOOK_PLATFORM && permissions.can('create', CAMPAIGN_PERMISSION), {
          text: 'Duplicate',
          onClickHandler: duplicateCampaignHandler
        })
        // commented out for now with AutomationRuleCreate and ManageAutomationRules
        // ...insertIf(permissions.can('manage', CAMPAIGN_PERMISSION),{
        //   text: 'Create rule',
        //   onClickHandler: createRuleHandler
        // })
      ]
    },
    [dispatch, selectedPlatform, handleDeleteRequest, selectedAdAccountId, permissions]
  )
}

export default useGetCampaignActionsDropdownOptions
