import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles({
  inlineField: {
    position: 'relative',
    margin: '0, 5px',
    '&.inlineField': {
      maxWidth: 40,
      margin: '0 10px',
      display: 'inline-block',
      '& input': {
        textAlign: 'center',
        verticalAlign: 'middle',
        padding: 5
      }
    }
  }
})

export default useStyles
