import React, { forwardRef, useMemo } from 'react'
import { useTheme } from 'react-jss'
import PropTypes from 'prop-types'

import { Bar } from 'react-chartjs-2'
// import graphs initial setting
import '../index'
import { useGraph } from '../hooks'

import getReusableGraphOptions from '../reusableGraphOptions'

const BarGraph = forwardRef((props, ref) => {
  const theme = useTheme()
  useGraph()

  const { data, labels, className, graphColor, format } = props

  const graphData = {
    labels,
    datasets: [
      {
        data: data,
        backgroundColor: graphColor || theme.brandPrimary,
        borderColor: graphColor || theme.brandPrimary,
        borderRadius: 4,
        borderSkipped: false,
        maxBarThickness: 15
      }
    ]
  }

  const graphOptions = useMemo(
    () => getReusableGraphOptions({ format, theme, graphColor, autoSkipPadding: 10 }),
    [format, graphColor, theme]
  )

  return (
    <div className={className}>
      <Bar type="bar" data={graphData} options={graphOptions} ref={ref} />
    </div>
  )
})

BarGraph.propTypes = {
  data: PropTypes.array.isRequired,
  labels: PropTypes.array,
  className: PropTypes.string,
  graphColor: PropTypes.string,
  format: PropTypes.string
}

export default BarGraph
