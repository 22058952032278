import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getAccessibleFacebookPagesService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.accessibleFacebookPages
  })
}

export function addAccessibleFacebookPagesService(pageData) {
  return madeRequest('POST', {
    data: pageData,
    url: ENDPOINTS.accessibleFacebookPages
  })
}

export function deleteAccessibleFacebookPagesService(id) {
  return madeRequest('DELETE', {
    url: `${ENDPOINTS.accessibleFacebookPages}${id}/`
  })
}

export async function createFacebookPageAccessRequestService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.facebookPagesRequestAccess
  })
}

export function getFacebookPagesService() {
  return madeRequest('GET', {
    url: ENDPOINTS.facebookPages
  })
}

export function getFacebookPageAccessTokenService(facebookPageId, adAccountId) {
  return madeRequest('GET', {
    url: `${ENDPOINTS.facebookPages}${facebookPageId}/access_token/`,
    params: {
      account: adAccountId
    }
  })
}

export function getInstagramAccountsService(facebookPageId, accessToken) {
  return madeRequest('GET', {
    url: `${ENDPOINTS.facebookPages}${facebookPageId}/instagram_accounts/`,
    params: {
      page_access_token: accessToken
    }
  })
}

export function getBackedInstagramAccountsService(facebookPageId, accessToken) {
  return madeRequest('GET', {
    url: `${ENDPOINTS.facebookPages}${facebookPageId}/backed_instagram_accounts/`,
    params: {
      page_access_token: accessToken
    }
  })
}
