import React from 'react'
import PropTypes from 'prop-types'

import { getAdClickThroughLink } from '../../helpers'

const LinkSectionPreview = ({ ad }) => {
  const link = getAdClickThroughLink(ad)

  if (!link) {
    return null
  }

  return <p>{link}</p>
}

LinkSectionPreview.propTypes = {
  ad: PropTypes.object
}

export default LinkSectionPreview
