import React, { useCallback, useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import Select from '../../../components/Select'
import { ReactComponent as AngleIcon } from '../../../assets/icons/chevron-arrow-down.svg'

import { tabletDownSize } from '../../../styles/const/breakpoints'
import useStyles from './styles'

const rightArrow = <AngleIcon className={'right-arrow'} />

function NavItemsMenu({ itemsList, selectedItemId, onMenuItemClick }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const isTablet = useMediaQuery({ maxWidth: tabletDownSize })

  const filesListOptions = useMemo(() => {
    return itemsList.map(({ id, title }) => ({
      value: id,
      label: t(title)
    }))
  }, [t, itemsList])

  const onSelectChange = useCallback(
    option => {
      onMenuItemClick(option.value)
    },
    [onMenuItemClick]
  )

  // hide nav if itemsList is empty
  if (!itemsList?.length) {
    return null
  }

  if (isTablet) {
    return (
      <Select className={classes.select} value={selectedItemId} onChange={onSelectChange} options={filesListOptions} />
    )
  }

  return (
    <nav className={classes.nav}>
      {itemsList.map(({ title, id }) => {
        const isActive = id === selectedItemId

        return (
          <div
            key={id}
            className={classnames(classes.navItem, { [classes.isActive]: isActive })}
            onClick={() => onMenuItemClick(id)}
          >
            {t(title)}
            {isActive && (
              <div className={classes.navItemRight}>
                <p className={classes.navIcon}>{rightArrow}</p>
              </div>
            )}
          </div>
        )
      })}
    </nav>
  )
}

NavItemsMenu.propTypes = {
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      id: PropTypes.number.isRequired
    })
  ),

  selectedItemId: PropTypes.number,
  onMenuItemClick: PropTypes.func
}

export default NavItemsMenu
