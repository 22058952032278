import React from 'react'

import { getAssetsByRatio } from '../../../../../../../../UploadGoogleAdCreative/UploadGoogleAdCreativeForm/imageAssetsListValidationHelper'

import Icon from '../../../../../../../../../../components/Icon'
import { ReactComponent as CheckedCircle } from '../../../../../../../../../../assets/icons/checked-circle.svg'

import useStyles from './styles'

const UploadedImageIcon = ({ uploadedImages, ratio }) => {
  const classes = useStyles()

  const imagesFilteredByRatio = getAssetsByRatio(uploadedImages, ratio)

  return imagesFilteredByRatio?.length ? (
    <Icon className={classes.icon}>
      <CheckedCircle />
    </Icon>
  ) : (
    <span className={classes.emptyIcon} />
  )
}

export default UploadedImageIcon
