import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  reportBtn: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto'
  },
  reportIcon: {
    marginLeft: [0, '!important'],
    marginRight: 10
  },
  reportSection: {
    marginTop: 50
  }
})

export default useStyles
