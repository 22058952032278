import { concat } from '../../helpers/common'

const MODULE_NAME = 'MEDIA_ORDER_OPTIMISATIONS/'

// get media order suggested changes
export const GET_MEDIA_ORDER_SUGGESTED_CHANGES = concat(MODULE_NAME, 'GET_MEDIA_ORDER_SUGGESTED_CHANGES')
export const GET_MEDIA_ORDER_SUGGESTED_CHANGES_SUCCESS = concat(
  MODULE_NAME,
  'GET_MEDIA_ORDER_SUGGESTED_CHANGES_SUCCESS'
)
export const GET_MEDIA_ORDER_SUGGESTED_CHANGES_FAILURE = concat(
  MODULE_NAME,
  'GET_MEDIA_ORDER_SUGGESTED_CHANGES_FAILURE'
)
export const CLEAR_MEDIA_ORDER_SUGGESTED_CHANGES = concat(MODULE_NAME, 'CLEAR_MEDIA_ORDER_SUGGESTED_CHANGES')

// apply media order suggested changes
export const APPLY_MEDIA_ORDER_SUGGESTED_CHANGES = concat(MODULE_NAME, 'APPLY_MEDIA_ORDER_SUGGESTED_CHANGES')
export const APPLY_MEDIA_ORDER_SUGGESTED_CHANGES_SUCCESS = concat(
  MODULE_NAME,
  'APPLY_MEDIA_ORDER_SUGGESTED_CHANGES_SUCCESS'
)
export const APPLY_MEDIA_ORDER_SUGGESTED_CHANGES_FAILURE = concat(
  MODULE_NAME,
  'APPLY_MEDIA_ORDER_SUGGESTED_CHANGES_FAILURE'
)
export const CLEAR_APPLY_MEDIA_ORDER_SUGGESTED_CHANGES = concat(
  MODULE_NAME,
  'CLEAR_APPLY_MEDIA_ORDER_SUGGESTED_CHANGES'
)

// get media order history
export const GET_OPTIMISATION_HISTORY = concat(MODULE_NAME, 'GET_OPTIMISATION_HISTORY')
export const GET_OPTIMISATION_HISTORY_SUCCESS = concat(MODULE_NAME, 'GET_OPTIMISATION_HISTORY_SUCCESS')
export const GET_OPTIMISATION_HISTORY_FAILURE = concat(MODULE_NAME, 'GET_OPTIMISATION_HISTORY_FAILURE')
export const CLEAR_OPTIMISATION_HISTORY = concat(MODULE_NAME, 'CLEAR_OPTIMISATION_HISTORY')

// Get media order suggested changes
export function getMediaOrderSuggestedChanges(id) {
  return { type: GET_MEDIA_ORDER_SUGGESTED_CHANGES, id }
}

export function getMediaOrderSuggestedChangesSuccess(data) {
  return { type: GET_MEDIA_ORDER_SUGGESTED_CHANGES_SUCCESS, data }
}

export function getMediaOrderSuggestedChangesFailure(error) {
  return { type: GET_MEDIA_ORDER_SUGGESTED_CHANGES_FAILURE, error }
}

export function clearMediaOrderSuggestedChanges() {
  return { type: CLEAR_MEDIA_ORDER_SUGGESTED_CHANGES }
}

// Apply media order suggested changes
export function applyMediaOrderSuggestedChanges(id) {
  return { type: APPLY_MEDIA_ORDER_SUGGESTED_CHANGES, id }
}

export function applyMediaOrderSuggestedChangesSuccess(data) {
  return { type: APPLY_MEDIA_ORDER_SUGGESTED_CHANGES_SUCCESS, data }
}

export function applyMediaOrderSuggestedChangesFailure(error) {
  return { type: APPLY_MEDIA_ORDER_SUGGESTED_CHANGES_FAILURE, error }
}

export function clearApplyMediaOrderSuggestedChanges() {
  return { type: CLEAR_APPLY_MEDIA_ORDER_SUGGESTED_CHANGES }
}

// Get optimisation history
export function getOptimisationHistory(params) {
  return { type: GET_OPTIMISATION_HISTORY, params }
}

export function getOptimisationHistorySuccess(data) {
  return { type: GET_OPTIMISATION_HISTORY_SUCCESS, data }
}

export function getOptimisationHistoryFailure(error) {
  return { type: GET_OPTIMISATION_HISTORY_FAILURE, error }
}

export function clearOptimisationHistory() {
  return { type: CLEAR_OPTIMISATION_HISTORY }
}
