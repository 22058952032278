import React from 'react'
import { useTranslation } from 'react-i18next'

const ScheduleSectionPreview = () => {
  const { t } = useTranslation()

  // schedule is always defined for TikTok
  return <p>{t('Expand to view schedule')}</p>
}

export default ScheduleSectionPreview
