import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import UnauthorizedPage from '../UnauthorizedPage'
import AmendmentSubmitContent from './AmendmentSubmitContent'
import DownloadContract from './DownloadContract'

import useStyles from './styles'

function AmendmentSign() {
  const [submittedContract, setSubmittedContract] = useState(null)
  const classes = useStyles()

  const { t } = useTranslation()

  const handleAmendmentSubmit = useCallback(data => {
    setSubmittedContract(data)
  }, [])

  return (
    // Amendment page should have default page wrapper
    <UnauthorizedPage helmetTitle="helmetTitle.AmendmentSign" defaultWrapper>
      {!!submittedContract ? (
        <div className={classes.infoMessageContainer}>
          <h2 className={classes.infoTitle}>{t('You’ve successfully signed the booking amendment contract.')}</h2>
          <p>{t('This will be emailed to you shortly')}</p>

          <DownloadContract contractId={submittedContract.id} />
        </div>
      ) : (
        <AmendmentSubmitContent onAmendmentSubmit={handleAmendmentSubmit} />
      )}
    </UnauthorizedPage>
  )
}

export default AmendmentSign
