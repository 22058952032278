import { createStyles } from '../../../../../styles/helpers'
import { textGrey } from '../../../../../styles/const/colors'
import { phonesDown } from '../../../../../styles/const/breakpoints'

const useStyles = createStyles({
  requestedFilesField: {
    display: 'flex',
    alignItems: 'center'
  },
  uploadNow: {
    marginLeft: 16,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderBottom: `1px solid ${textGrey}`,
    color: textGrey
  },
  outStandingFilesWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  outStandingFilesText: {
    color: textGrey
  },

  [`@media screen and (${phonesDown})`]: {
    requestedFilesField: {
      justifyContent: 'flex-end',
      width: '100%'
    }
  }
})

export default useStyles
