import { createStyles } from './helpers'

import { borderDarkGrey, lightGrey, textGrey } from './const/colors'

export default createStyles(theme => {
  return {
    '@global': {
      '*': {
        boxSizing: 'border-box'
      },
      'body, ul, p': {
        margin: 0
      },
      'h1, h2, h3, h4, h5, h6': {
        margin: 0,
        fontWeight: 600,
        lineHeight: 'normal'
      },
      h1: {
        fontSize: 24
      },
      h2: {
        fontSize: 20
      },
      h3: {
        fontSize: 16
      },
      h4: {
        fontSize: 14
      },
      h5: {
        fontSize: 12
      },
      html: {
        fontFamily: theme.fontFamily.value,
        lineHeight: 'normal',
        fontSize: 14,
        color: textGrey,

        '@global': {
          '@font-face': [
            {
              fontFamily: `${theme.fontFamily.name}`,
              src: `url("${theme.fontFamily.normal}") format('${theme.fontFamily.format}')`,
              fontWeight: 400,
              fontStyle: 'normal',
              fontDisplay: 'swap'
            },
            {
              fontFamily: `${theme.fontFamily.name}`,
              src: `url("${theme.fontFamily.medium}") format('${theme.fontFamily.format}')`,
              fontWeight: 600,
              fontStyle: 'normal',
              fontDisplay: 'swap'
            },
            {
              fontFamily: `${theme.fontFamily.name}`,
              src: `url("${theme.fontFamily.bold}") format('${theme.fontFamily.format}')`,
              fontWeight: 700,
              fontStyle: 'normal',
              fontDisplay: 'swap'
            }
          ]
        }
      },
      body: {
        minHeight: '100vh',
        backgroundColor: lightGrey,
        paddingTop: 48
      },
      'input, textarea, select, button': {
        fontFamily: theme.fontFamily.value
      },
      'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
        {
          '-webkit-box-shadow': '0 0 0 30px white inset !important',
          '-webkit-text-fill-color': `${borderDarkGrey} !important`
        },
      '.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span':
        {
          color: 'inherit'
        },
      '.rdrDateDisplayItem, .rdrMonthAndYearPickers select, .rdrNextPrevButton, .rdrDayToday .rdrDayNumber span:after, .rdrSelected, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrEndEdge, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrStartEdge, .rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview, .rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview, .rdrDayStartPreview, .rdrDayEndPreview, .rdrInputRangeInput, .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after':
        {
          borderRadius: 4
        },
      '.rdrStartEdge, .rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge':
        {
          borderTopLeftRadius: 4,
          borderBottomLeftRadius: 4
        },
      '.rdrEndEdge, .rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge':
        {
          borderTopRightRadius: 4,
          borderBottomRightRadius: 4
        },
      '.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after':
        {
          backgroundColor: borderDarkGrey
        },
      // avoid cut edges in dateRange components
      '.rdrDayStartPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayNumber:after': {
        borderTopRightRadius: '0 !important',
        borderBottomRightRadius: '0 !important'
      },
      '.rdrDayEndPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayNumber:after': {
        borderTopLeftRadius: '0 !important',
        borderBottomLeftRadius: '0 !important'
      }
    }
  }
})
