import { createStyles } from '../../../../../../styles/helpers'

const useStyles = createStyles({
  filtersRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 12
  },
  rightFiltersRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 12,
    flexWrap: 'wrap',
    marginLeft: 'auto'
  },
  topFiltersRow: {
    marginBottom: 12
  },
  select: {
    minWidth: 120,
    maxWidth: 120
  },
  locationsSelect: {
    minWidth: 170
  },
  productSelect: {
    minWidth: 140,
    maxWidth: 140
  }
})

export default useStyles
