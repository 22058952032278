import * as Yup from 'yup'

import { longHeadlineMaxLength, youtubeVideoIdUrlParams } from '../fields'
import { getHeadlineShape } from '../validations'
import { getUrlDomain } from '../../../../helpers/url'
import { urlValidation } from '../../../../features/validations/other'

export const longHeadlineShape = getHeadlineShape(longHeadlineMaxLength)

export const youtubeLinkShapeRequired = Yup.object({
  // this shape is used when the input field has required text value
  text: urlValidation.test(
    'link',
    'Youtube video link must contain video id',
    link => link?.includes(youtubeVideoIdUrlParams) || link?.includes('youtu.be/')
  )
})

export const youtubeLinkShape = Yup.object({
  // link is optional, but if it is provided it must be valid
  // so in each .test we check if link is provided
  text: Yup.string()
    .test('link', 'Please enter a valid URL, including the https://', link => !link || getUrlDomain(link))
    .test(
      'is-youtube-link',
      'Youtube video link must contain video id',
      link => !link || link?.includes(youtubeVideoIdUrlParams) || link?.includes('youtu.be/')
    )
    .max(1024, 'There is a maximum limit of 1024 characters')
})
