import { concat } from '../../helpers/common'

const MODULE_NAME = 'AUTOMATION_RULES/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_AUTOMATION_RULES = concat(MODULE_NAME, 'GET_AUTOMATION_RULES')
export const GET_AUTOMATION_RULES_SUCCESS = concat(MODULE_NAME, 'GET_AUTOMATION_RULES_SUCCESS')
export const GET_AUTOMATION_RULES_FAILURE = concat(MODULE_NAME, 'GET_AUTOMATION_RULES_FAILURE')
export const CLEAR_AUTOMATION_RULES = concat(MODULE_NAME, 'CLEAR_AUTOMATION_RULES')

export const CREATE_AUTOMATION_RULE = concat(MODULE_NAME, 'CREATE_AUTOMATION_RULE')
export const CREATE_AUTOMATION_RULE_SUCCESS = concat(MODULE_NAME, 'CREATE_AUTOMATION_RULE_SUCCESS')
export const CREATE_AUTOMATION_RULE_FAILURE = concat(MODULE_NAME, 'CREATE_AUTOMATION_RULE_FAILURE')
export const CLEAR_CREATE_AUTOMATION_RULE = concat(MODULE_NAME, 'CLEAR_CREATE_AUTOMATION_RULE')

export const DELETE_AUTOMATION_RULE = concat(MODULE_NAME, 'DELETE_AUTOMATION_RULE')
export const DELETE_AUTOMATION_RULE_SUCCESS = concat(MODULE_NAME, 'DELETE_AUTOMATION_RULE_SUCCESS')
export const DELETE_AUTOMATION_RULE_FAILURE = concat(MODULE_NAME, 'DELETE_AUTOMATION_RULE_FAILURE')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// get automation rules
export function getAutomationRules(params) {
  return { type: GET_AUTOMATION_RULES, params }
}

export function getAutomationRulesSuccess(automationRulesData) {
  return { type: GET_AUTOMATION_RULES_SUCCESS, automationRulesData }
}

export function getAutomationRulesFailure(error) {
  return { type: GET_AUTOMATION_RULES_FAILURE, error }
}

export function clearAutomationRules() {
  return { type: CLEAR_AUTOMATION_RULES }
}

// create automation rule
export function createAutomationRule(automationRuleData) {
  return { type: CREATE_AUTOMATION_RULE, automationRuleData }
}

export function createAutomationRuleSuccess(automationRuleData) {
  return { type: CREATE_AUTOMATION_RULE_SUCCESS, automationRuleData }
}

export function createAutomationRuleFailure(error) {
  return { type: CREATE_AUTOMATION_RULE_FAILURE, error }
}

export function clearCreateAutomationRule() {
  return { type: CLEAR_CREATE_AUTOMATION_RULE }
}

// delete automation rule
export function deleteAutomationRule(id) {
  return { type: DELETE_AUTOMATION_RULE, id }
}

export function deleteAutomationRuleSuccess(id) {
  return { type: DELETE_AUTOMATION_RULE_SUCCESS, id }
}

export function deleteAutomationRuleFailure(error) {
  return { type: DELETE_AUTOMATION_RULE_FAILURE, error }
}
