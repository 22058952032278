import store from '../../store'

import { madeRequest } from '../../helpers/api'
import { madeSocketRequest } from '../../helpers/api/webSocket'

import { getAiSuggestionsChunk } from '../actions/tools'

import { ENDPOINTS, WEB_SOCKET_ENDPOINTS } from '../../constants/api'

// PLOP_APPEND_PATTERN_ANCHOR

export async function getAiSuggestionsService({ slug, params, onSocketOpen }) {
  const handleStreamUpdate = parsedJson => {
    store.dispatch(getAiSuggestionsChunk(slug, parsedJson))
  }

  return madeSocketRequest(
    'post',
    {
      url: WEB_SOCKET_ENDPOINTS.streamAiSuggestions,
      params
    },
    {
      handleStreamUpdate,
      onSocketOpen
    }
  )
}
export function getChangeHistoryService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.changeHistory
  })
}
