import update from 'immutability-helper'

import { INITIAL_GET_REDUCER, INITIAL_CREATE_REDUCER, INITIAL_GET_RESULTS_REDUCER } from '../../constants/reducer'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_TIK_TOK_VIDEO,
  GET_TIK_TOK_VIDEO_SUCCESS,
  GET_TIK_TOK_VIDEO_FAILURE,
  CLEAR_GET_TIK_TOK_VIDEO,
  GET_TIK_TOK_REGIONS,
  GET_TIK_TOK_REGIONS_SUCCESS,
  GET_TIK_TOK_REGIONS_FAILURE,
  CLEAR_TIK_TOK_REGIONS,
  GET_TIK_TOK_PIXELS,
  GET_TIK_TOK_PIXELS_SUCCESS,
  GET_TIK_TOK_PIXELS_FAILURE,
  CLEAR_TIK_TOK_PIXELS,
  GET_TIK_TOK_IDENTITIES,
  GET_TIK_TOK_IDENTITIES_SUCCESS,
  GET_TIK_TOK_IDENTITIES_FAILURE,
  CLEAR_TIK_TOK_IDENTITIES,
  CREATE_TIK_TOK_IDENTITY,
  CREATE_TIK_TOK_IDENTITY_SUCCESS,
  CREATE_TIK_TOK_IDENTITY_FAILURE,
  CLEAR_CREATE_TIK_TOK_IDENTITY,
  GET_TIKTOK_INTERESTS,
  GET_TIKTOK_INTERESTS_FAILURE,
  GET_TIKTOK_INTERESTS_SUCCESS,
  CLEAR_TIKTOK_INTERESTS,
  CLEAR_TIKTOK_ACTION_CATEGORIES,
  GET_TIKTOK_ACTION_CATEGORIES,
  GET_TIKTOK_ACTION_CATEGORIES_FAILURE,
  GET_TIKTOK_ACTION_CATEGORIES_SUCCESS,
  GET_TIKTOK_HASHTAGS,
  GET_TIKTOK_HASHTAGS_SUCCESS,
  GET_TIKTOK_HASHTAGS_FAILURE,
  CLEAR_TIKTOK_HASHTAGS,
  GET_TIKTOK_CUSTOM_AUDIENCES,
  GET_TIKTOK_CUSTOM_AUDIENCES_SUCCESS,
  GET_TIKTOK_CUSTOM_AUDIENCES_FAILURE,
  CLEAR_TIKTOK_CUSTOM_AUDIENCES,
  GET_TIK_TOK_VIDEO_THUMBNAILS,
  GET_TIK_TOK_VIDEO_THUMBNAILS_SUCCESS,
  GET_TIK_TOK_VIDEO_THUMBNAILS_FAILURE,
  CLEAR_TIK_TOK_VIDEO_THUMBNAILS
} from '../actions/tikTokConstants'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  tikTokVideo: INITIAL_GET_REDUCER,
  tikTokRegions: INITIAL_GET_RESULTS_REDUCER,
  tikTokPixels: INITIAL_GET_RESULTS_REDUCER,
  tikTokIdentities: INITIAL_GET_RESULTS_REDUCER,
  createTikTokIdentity: INITIAL_CREATE_REDUCER,
  interests: INITIAL_GET_RESULTS_REDUCER,
  actionCategories: INITIAL_GET_RESULTS_REDUCER,
  hashtags: INITIAL_GET_RESULTS_REDUCER,
  customAudiences: INITIAL_GET_RESULTS_REDUCER,
  tikTokVideoThumbnails: INITIAL_GET_RESULTS_REDUCER
}

export default function tikTokConstants(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    // Get tik tok video
    case GET_TIK_TOK_VIDEO:
      return update(state, {
        tikTokVideo: { $merge: { isLoading: true } }
      })
    case GET_TIK_TOK_VIDEO_SUCCESS:
      return update(state, {
        tikTokVideo: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        }
      })
    case GET_TIK_TOK_VIDEO_FAILURE:
      return update(state, {
        tikTokVideo: {
          $merge: {
            ...initialState.tikTokVideo,
            error: action.error
          }
        }
      })
    case CLEAR_GET_TIK_TOK_VIDEO:
      return update(state, {
        tikTokVideo: { $set: initialState.tikTokVideo }
      })

    // Get tik tok regions
    case GET_TIK_TOK_REGIONS:
      return update(state, {
        tikTokRegions: { $merge: { isLoading: true } }
      })
    case GET_TIK_TOK_REGIONS_SUCCESS:
      return update(state, {
        tikTokRegions: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data
          }
        }
      })
    case GET_TIK_TOK_REGIONS_FAILURE:
      return update(state, {
        tikTokRegions: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_TIK_TOK_REGIONS:
      return update(state, {
        tikTokRegions: { $set: initialState.tikTokRegions }
      })

    // Get tik tok pixels
    case GET_TIK_TOK_PIXELS:
      return update(state, {
        tikTokPixels: { $merge: { isLoading: true } }
      })
    case GET_TIK_TOK_PIXELS_SUCCESS:
      return update(state, {
        tikTokPixels: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data
          }
        }
      })
    case GET_TIK_TOK_PIXELS_FAILURE:
      return update(state, {
        tikTokPixels: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_TIK_TOK_PIXELS:
      return update(state, {
        tikTokPixels: { $set: initialState.tikTokPixels }
      })

    // Get tik tok identities
    case GET_TIK_TOK_IDENTITIES:
      return update(state, {
        tikTokIdentities: { $merge: { isLoading: true } }
      })
    case GET_TIK_TOK_IDENTITIES_SUCCESS:
      return update(state, {
        tikTokIdentities: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data
          }
        }
      })
    case GET_TIK_TOK_IDENTITIES_FAILURE:
      return update(state, {
        tikTokIdentities: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_TIK_TOK_IDENTITIES:
      return update(state, {
        tikTokIdentities: { $set: initialState.tikTokIdentities }
      })

    // Create TikTok identities
    case CREATE_TIK_TOK_IDENTITY:
      return update(state, {
        createTikTokIdentity: { $merge: { isLoading: true } }
      })
    case CREATE_TIK_TOK_IDENTITY_SUCCESS:
      return update(state, {
        createTikTokIdentity: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },
        tikTokIdentities: {
          results: {
            $unshift: [action.data]
          }
        }
      })
    case CREATE_TIK_TOK_IDENTITY_FAILURE:
      return update(state, {
        createTikTokIdentity: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_TIK_TOK_IDENTITY:
      return update(state, {
        createTikTokIdentity: { $set: initialState.createTikTokIdentity }
      })

    // get interests
    case GET_TIKTOK_INTERESTS:
      return update(state, {
        interests: { $merge: { isLoading: true } }
      })
    case GET_TIKTOK_INTERESTS_SUCCESS:
      return update(state, {
        interests: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.interestsData
          }
        }
      })
    case GET_TIKTOK_INTERESTS_FAILURE:
      return update(state, {
        interests: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_TIKTOK_INTERESTS:
      return update(state, {
        interests: {
          $set: initialState.interests
        }
      })

    // get actionCategories
    case GET_TIKTOK_ACTION_CATEGORIES:
      return update(state, {
        actionCategories: { $merge: { isLoading: true } }
      })
    case GET_TIKTOK_ACTION_CATEGORIES_SUCCESS:
      return update(state, {
        actionCategories: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.actionCategoriesData
          }
        }
      })
    case GET_TIKTOK_ACTION_CATEGORIES_FAILURE:
      return update(state, {
        actionCategories: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_TIKTOK_ACTION_CATEGORIES:
      return update(state, {
        actionCategories: {
          $set: initialState.actionCategories
        }
      })

    // get hashtags
    case GET_TIKTOK_HASHTAGS:
      return update(state, {
        hashtags: { $merge: { isLoading: true } }
      })
    case GET_TIKTOK_HASHTAGS_SUCCESS:
      return update(state, {
        hashtags: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.hashtagsData
          }
        }
      })
    case GET_TIKTOK_HASHTAGS_FAILURE:
      return update(state, {
        hashtags: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_TIKTOK_HASHTAGS:
      return update(state, {
        hashtags: {
          $set: initialState.hashtags
        }
      })

    // get custom audiences
    case GET_TIKTOK_CUSTOM_AUDIENCES:
      return update(state, {
        customAudiences: { $merge: { isLoading: true } }
      })
    case GET_TIKTOK_CUSTOM_AUDIENCES_SUCCESS:
      return update(state, {
        customAudiences: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.customAudiencesData.results
          }
        }
      })
    case GET_TIKTOK_CUSTOM_AUDIENCES_FAILURE:
      return update(state, {
        customAudiences: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_TIKTOK_CUSTOM_AUDIENCES:
      return update(state, {
        customAudiences: {
          $set: initialState.customAudiences
        }
      })

    // Get tik tok video thumbnails
    case GET_TIK_TOK_VIDEO_THUMBNAILS:
      return update(state, {
        tikTokVideoThumbnails: { $merge: { isLoading: true } }
      })
    case GET_TIK_TOK_VIDEO_THUMBNAILS_SUCCESS:
      return update(state, {
        tikTokVideoThumbnails: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data
          }
        }
      })
    case GET_TIK_TOK_VIDEO_THUMBNAILS_FAILURE:
      return update(state, {
        tikTokVideoThumbnails: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_TIK_TOK_VIDEO_THUMBNAILS:
      return update(state, {
        tikTokVideoThumbnails: { $set: initialState.tikTokVideoThumbnails }
      })

    default:
      return state
  }
}
