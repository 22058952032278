import { createStyles } from '../../../styles/helpers'

import { lightGrey } from '../../../styles/const/colors'
import { phonesDown } from '../../../styles/const/breakpoints'

export default createStyles({
  tabsContainer: {
    display: 'flex',
    columnGap: 10,
    padding: '4px 5px',

    fontSize: 14,
    fontWeight: 600,
    background: lightGrey,
    border: `2px solid ${lightGrey}`,
    borderRadius: 4
  },
  [`@media screen and (${phonesDown})`]: {
    tabsContainer: {
      fontSize: 12,
      minHeight: 45,
      columnGap: 5
    }
  }
})
