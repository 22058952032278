import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function createDoohlyLineItemService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.doohlyLineItems
  })
}

export function createDoohlyCampaignService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.doohlyCampaigns
  })
}

export function getDoohlyApiKeyService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.doohlyApiKey
  })
}

export function updateDoohlyApiKeyService(id, data) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.doohlyApiKey}${id}/`
  })
}

export function createDoohlyApiKeyService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.doohlyApiKey
  })
}

export function getDoohlyCampaignsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.doohlyCampaigns
  })
}
