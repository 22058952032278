import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

const SkeletonInternalAdActionButtons = ({ className }) => {
  const classes = useStyles()

  return (
    <div className={classnames(classes.btnsContainer, className)}>
      <div style={{ width: '50%' }}>
        <Skeleton height={36} />
      </div>
      <div style={{ width: '50%' }}>
        <Skeleton height={36} />
      </div>
    </div>
  )
}

SkeletonInternalAdActionButtons.propTypes = {
  className: PropTypes.string
}

export default SkeletonInternalAdActionButtons
