import { concat } from '../../helpers/common'

const MODULE_NAME = 'TAGS/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const CREATE_TAG = concat(MODULE_NAME, 'CREATE_TAG')
export const CREATE_TAG_SUCCESS = concat(MODULE_NAME, 'CREATE_TAG_SUCCESS')
export const CREATE_TAG_FAILURE = concat(MODULE_NAME, 'CREATE_TAG_FAILURE')
export const CLEAR_CREATE_TAG = concat(MODULE_NAME, 'CLEAR_CREATE_TAG')

export const UPDATE_TAG = concat(MODULE_NAME, 'UPDATE_TAG')
export const UPDATE_TAG_SUCCESS = concat(MODULE_NAME, 'UPDATE_TAG_SUCCESS')
export const UPDATE_TAG_FAILURE = concat(MODULE_NAME, 'UPDATE_TAG_FAILURE')
export const CLEAR_UPDATE_TAG = concat(MODULE_NAME, 'CLEAR_UPDATE_TAG')

export const DELETE_TAG = concat(MODULE_NAME, 'DELETE_TAG')
export const DELETE_TAG_SUCCESS = concat(MODULE_NAME, 'DELETE_TAG_SUCCESS')
export const DELETE_TAG_FAILURE = concat(MODULE_NAME, 'DELETE_TAG_FAILURE')
export const CLEAR_DELETE_TAG = concat(MODULE_NAME, 'CLEAR_DELETE_TAG')

export const CREATE_PRODUCT_TAG = concat(MODULE_NAME, 'CREATE_PRODUCT_TAG')
export const CREATE_PRODUCT_TAG_SUCCESS = concat(MODULE_NAME, 'CREATE_PRODUCT_TAG_SUCCESS')
export const CREATE_PRODUCT_TAG_FAILURE = concat(MODULE_NAME, 'CREATE_PRODUCT_TAG_FAILURE')
export const CLEAR_CREATE_PRODUCT_TAG = concat(MODULE_NAME, 'CLEAR_CREATE_PRODUCT_TAG')

export const UPDATE_PRODUCT_TAG = concat(MODULE_NAME, 'UPDATE_PRODUCT_TAG')
export const UPDATE_PRODUCT_TAG_SUCCESS = concat(MODULE_NAME, 'UPDATE_PRODUCT_TAG_SUCCESS')
export const UPDATE_PRODUCT_TAG_FAILURE = concat(MODULE_NAME, 'UPDATE_PRODUCT_TAG_FAILURE')
export const CLEAR_UPDATE_PRODUCT_TAG = concat(MODULE_NAME, 'CLEAR_UPDATE_PRODUCT_TAG')

export const DELETE_PRODUCT_TAG = concat(MODULE_NAME, 'DELETE_PRODUCT_TAG')
export const DELETE_PRODUCT_TAG_SUCCESS = concat(MODULE_NAME, 'DELETE_PRODUCT_TAG_SUCCESS')
export const DELETE_PRODUCT_TAG_FAILURE = concat(MODULE_NAME, 'DELETE_PRODUCT_TAG_FAILURE')
export const CLEAR_DELETE_PRODUCT_TAG = concat(MODULE_NAME, 'CLEAR_DELETE_PRODUCT_TAG')

export const GET_PRODUCT_TAGS = concat(MODULE_NAME, 'GET_PRODUCT_TAGS')
export const GET_PRODUCT_TAGS_SUCCESS = concat(MODULE_NAME, 'GET_PRODUCT_TAGS_SUCCESS')
export const GET_PRODUCT_TAGS_FAILURE = concat(MODULE_NAME, 'GET_PRODUCT_TAGS_FAILURE')
export const CLEAR_GET_PRODUCT_TAGS = concat(MODULE_NAME, 'CLEAR_GET_PRODUCT_TAGS')

export const GET_TAGS_LIST = concat(MODULE_NAME, 'GET_TAGS_LIST')
export const GET_TAGS_LIST_SUCCESS = concat(MODULE_NAME, 'GET_TAGS_LIST_SUCCESS')
export const GET_TAGS_LIST_FAILURE = concat(MODULE_NAME, 'GET_TAGS_LIST_FAILURE')
export const CLEAR_GET_TAGS_LIST = concat(MODULE_NAME, 'CLEAR_GET_TAGS_LIST')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// Delete tag
export function deleteTag(id, params) {
  return { type: DELETE_TAG, id, params }
}

export function deleteTagSuccess(data) {
  return { type: DELETE_TAG_SUCCESS, data }
}

export function deleteTagFailure(error) {
  return { type: DELETE_TAG_FAILURE, error }
}

export function clearDeleteTag() {
  return { type: CLEAR_DELETE_TAG }
}

// Update tag
export function updateTag(id, data) {
  return { type: UPDATE_TAG, id, data }
}

export function updateTagSuccess(data) {
  return { type: UPDATE_TAG_SUCCESS, data }
}

export function updateTagFailure(error) {
  return { type: UPDATE_TAG_FAILURE, error }
}

export function clearUpdateTag() {
  return { type: CLEAR_UPDATE_TAG }
}

// Create tag
export function createTag(data) {
  return { type: CREATE_TAG, data }
}

export function createTagSuccess(data) {
  return { type: CREATE_TAG_SUCCESS, data }
}

export function createTagFailure(error) {
  return { type: CREATE_TAG_FAILURE, error }
}

export function clearCreateTag() {
  return { type: CLEAR_CREATE_TAG }
}

// Delete product tag
export function deleteProductTag(id, params) {
  return { type: DELETE_PRODUCT_TAG, id, params }
}

export function deleteProductTagSuccess(data) {
  return { type: DELETE_PRODUCT_TAG_SUCCESS, data }
}

export function deleteProductTagFailure(error) {
  return { type: DELETE_PRODUCT_TAG_FAILURE, error }
}

export function clearDeleteProductTag() {
  return { type: CLEAR_DELETE_PRODUCT_TAG }
}

// Update product tag
export function updateProductTag(id, data) {
  return { type: UPDATE_PRODUCT_TAG, id, data }
}

export function updateProductTagSuccess(data) {
  return { type: UPDATE_PRODUCT_TAG_SUCCESS, data }
}

export function updateProductTagFailure(error) {
  return { type: UPDATE_PRODUCT_TAG_FAILURE, error }
}

export function clearUpdateProductTag() {
  return { type: CLEAR_UPDATE_PRODUCT_TAG }
}

// Create product tag
export function createProductTag(data) {
  return { type: CREATE_PRODUCT_TAG, data }
}

export function createProductTagSuccess(data) {
  return { type: CREATE_PRODUCT_TAG_SUCCESS, data }
}

export function createProductTagFailure(error) {
  return { type: CREATE_PRODUCT_TAG_FAILURE, error }
}

export function clearCreateProductTag() {
  return { type: CLEAR_CREATE_PRODUCT_TAG }
}

// Get product tags
export function getProductTags(params) {
  return { type: GET_PRODUCT_TAGS, params }
}

export function getProductTagsSuccess(data) {
  return { type: GET_PRODUCT_TAGS_SUCCESS, data }
}

export function getProductTagsFailure(error) {
  return { type: GET_PRODUCT_TAGS_FAILURE, error }
}

export function clearGetProductTags() {
  return { type: CLEAR_GET_PRODUCT_TAGS }
}

export function getTagsList(params) {
  return { type: GET_TAGS_LIST, params }
}
export function getTagsListSuccess(tagsListData) {
  return { type: GET_TAGS_LIST_SUCCESS, tagsListData }
}
export function getTagsListFailure(error) {
  return { type: GET_TAGS_LIST_FAILURE, error }
}
export function clearGetTagsList() {
  return { type: CLEAR_GET_TAGS_LIST }
}
