import store from '../store'
import { submitLogout } from '../modules/actions/cognito'
import { submitServerLogout } from '../modules/actions/app'

export function handleLogout() {
  // during logout we need to make both requests: to Cognito and our BE.
  // our BE blacklist the token so it become invalid for use(in other case the API still be accessible with old token)
  store.dispatch(submitLogout())
  store.dispatch(submitServerLogout())
}
