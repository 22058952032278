import { createStyles } from '../../../styles/helpers'

import { greyOutline } from '../../../styles/const/colors'

const useStyles = createStyles({
  invoicingText: {
    fontSize: 14,
    fontWeight: 600,
    color: greyOutline,
    maxWidth: 335,
    textAlign: 'center',
    margin: '0 auto'
  },
  noDataContainer: {
    marginTop: 32
  }
})

export default useStyles
