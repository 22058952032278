import React from 'react'
import { useTranslation } from 'react-i18next'

import CustomizedMedia from './CustomizedMedia'
import Checkbox from '../../../../components/Form/Checkbox'
import FieldRow from '../../../../features/components/Form/FieldsSection/FieldRow'

import { IS_CUSTOMIZED_MEDIA } from '../../AdForms/fields'

const CustomizedMediaFields = ({ formik, adAccountId, showCustomizedPreview }) => {
  const { t } = useTranslation()

  const { values, setFieldValue } = formik

  const showCustomizedMediaFields = !!values[IS_CUSTOMIZED_MEDIA]

  return (
    <>
      <FieldRow
        title="Story/reels creative"
        description="Would you like to upload a customised image or video for story/reels positions?"
      >
        <Checkbox
          id="customized_media_recommended"
          title={t('Upload a customised image or video')}
          checked={values[IS_CUSTOMIZED_MEDIA]}
          onCheck={() => setFieldValue(IS_CUSTOMIZED_MEDIA, !values[IS_CUSTOMIZED_MEDIA])}
        />
      </FieldRow>
      {showCustomizedMediaFields && (
        <CustomizedMedia formik={formik} adAccountId={adAccountId} showPreview={showCustomizedPreview} />
      )}
    </>
  )
}

export default CustomizedMediaFields
