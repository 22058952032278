import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classnames from 'classnames'

import useStyles from './styles'

const MediaSkeleton = ({ className }) => {
  const classes = useStyles()

  return (
    <div className={classnames(classes.skeleton, className)}>
      <Skeleton />
    </div>
  )
}

export default MediaSkeleton
