import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  container: {
    marginTop: 48,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%'
  }
})

export default useStyles
