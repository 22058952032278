import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import AmendmentContractCreateForm from './AmendmentContractCreateForm'
import AddNewProductModal from './AddNewProductModal'
import { SelectedMediaProductsProvider } from './SelectedMediaProductsContext'

function AmendmentContractCreate({ onSuccessSubmit }) {
  const [showSelectProductModal, setShowSelectProductModal] = useState(false)

  const openNewProductModalHandler = useCallback(() => {
    setShowSelectProductModal(true)
  }, [setShowSelectProductModal])

  const closeModalHandler = useCallback(() => {
    setShowSelectProductModal(false)
  }, [setShowSelectProductModal])

  return (
    <SelectedMediaProductsProvider>
      <AddNewProductModal showSelectProductModal={showSelectProductModal} onModalClose={closeModalHandler} />
      <AmendmentContractCreateForm
        onAddNewMediaProduct={openNewProductModalHandler}
        onSuccessSubmit={onSuccessSubmit}
      />
    </SelectedMediaProductsProvider>
  )
}

AmendmentContractCreate.propTypes = {
  onSuccessSubmit: PropTypes.func.isRequired
}

export default AmendmentContractCreate
