import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

export const audiencesSelector = createSelector(
  ({ audiences }) => audiences.audiences.results,
  results => results
)

export const audiencesLoadingSelector = createSelector(
  ({ audiences }) => audiences.audiences.isLoading,
  isLoading => isLoading
)

export const audiencesWasLoadedSelector = createSelector(
  ({ audiences }) => audiences.audiences.wasLoaded,
  wasLoaded => wasLoaded
)

export const audiencesErrorSelector = createSelector(
  ({ audiences }) => audiences.audiences.error,
  error => error
)

export const audiencesIsNextSelector = createSelector(
  ({ audiences }) => audiences.audiences.paging,
  paging => paging.next
)

export const audiencesAfterSelector = createSelector(
  ({ audiences }) => audiences.audiences.paging,
  paging => paging.cursors?.after
)

export const createAudienceDataSelector = createSelector(
  ({ audiences }) => audiences.createAudience.data,
  data => data
)

export const createAudienceIsLoadingSelector = createSelector(
  ({ audiences }) => audiences.createAudience.isLoading,
  isLoading => isLoading
)

export const createAudienceWasCreatedSelector = createSelector(
  ({ audiences }) => audiences.createAudience.wasCreated,
  wasCreated => wasCreated
)

export const createAudienceErrorSelector = createSelector(
  ({ audiences }) => audiences.createAudience.error,
  error => error
)

export const audiencesCreateBySuggestionIsLoadingSelector = createSelector(
  ({ audiences }) => audiences.createdAudienceBySuggestion.isLoading,
  isLoading => isLoading
)

export const createdAudienceBySuggestionSelector = createSelector(
  ({ audiences }) => audiences.createdAudienceBySuggestion,
  createdAudienceBySuggestion => createdAudienceBySuggestion
)

export const audiencesCreateBySuggestionErrorSelector = createSelector(
  ({ audiences }) => audiences.createdAudienceBySuggestion.error,
  error => error
)

export const deletedAudienceSelector = createSelector(
  ({ audiences }) => audiences.deletedAudience,
  deletedAudience => deletedAudience
)

export const audienceDeleteIsLoadingSelector = createSelector(
  ({ audiences }) => audiences.deletedAudience.isLoading,
  isLoading => isLoading
)

export const audienceDeleteErrorSelector = createSelector(
  ({ audiences }) => audiences.deletedAudience.error,
  error => error
)
