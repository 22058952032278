import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import DiscountPresetEditForm from './DiscountPresetEditForm'

import useManageFormsDrawer from '../../../../../hooks/formHooks/useManageFormsDrawer'

import { discountPresetsSelector, discountPresetsWasLoadedSelector } from '../../../../../modules/selectors/discounts'

import { DISCOUNT_PRESET_EDIT } from '../../../../../constants/forms'

const DiscountPresetEdit = () => {
  const discountPresets = useSelector(discountPresetsSelector)
  const discountPresetsWasLoaded = useSelector(discountPresetsWasLoadedSelector)

  const { selectedEditItemId } = useManageFormsDrawer({
    formName: DISCOUNT_PRESET_EDIT
  })

  const editDiscountPresetData = useMemo(
    () => discountPresets.find(item => item.id === Number(selectedEditItemId)),
    [discountPresets, selectedEditItemId]
  )

  const isFormLoading = !editDiscountPresetData || !discountPresetsWasLoaded

  return (
    <FormDrawerWrapper
      formName={DISCOUNT_PRESET_EDIT}
      isFormLoading={isFormLoading}
      title="Edit Discount Preset"
      showOpenButton={false}
    >
      <DiscountPresetEditForm editDiscountPresetData={editDiscountPresetData} />
    </FormDrawerWrapper>
  )
}

export default DiscountPresetEdit
