import { madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function determineUserCountry(appId) {
  // intercom is part of the segment analytics
  // it provides user object in answer to ping request
  return madeRequest('POST', {
    params: { app_id: appId },
    url: 'https://api-iam.intercom.io/messenger/web/ping'
  })
}
