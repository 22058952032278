import * as Yup from 'yup'

import { OPTION_YES } from '../../../../../../../constants/forms'
import {
  AUDIENCE,
  AUDIENCE_OPTION
} from '../../../../../LineItemForms/LineItemGoogleCreate/LineItemGoogleCreateForm/LineItemGoogleAudienceForm/fields'
import { CPM_BID } from '../../../../../LineItemForms/LineItemGoogleCreate/LineItemGoogleCreateForm/LineItemGoogleCreateMainForm/fields'

export const initialValues = {
  [AUDIENCE]: [],
  [AUDIENCE_OPTION]: OPTION_YES,
  [CPM_BID]: '4'
}

export const validationSchema = Yup.object({
  [AUDIENCE]: Yup.array().when(AUDIENCE_OPTION, {
    is: OPTION_YES,
    then: () => Yup.array().min(1, 'Please select at least one option')
  })
})
