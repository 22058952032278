import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import MfaTypeSet from './MfaTypeSet'
import MfaTotpSetup from './MfaTotpSetup'
import ActionText from '../../../components/ActionText'
import SetupPhoneNumberForm from './SetupPhoneNumberForm'
import PhoneVerificationForm from '../../Settings/EditProfile/UserProfileContent/PhoneNumberVerificationModal/PhoneVerificationForm'

import useContinuousForm from '../../../hooks/formHooks/useContinuousForm'

import { updateCurrentUserProfile } from '../../../modules/actions/app'
import { getCurrentUserSelector } from '../../../modules/selectors/app'

import { SMS_MFA } from '../../Settings/EditProfile/MFAContent/fields'
import {
  SET_MFA_TYPE_FORM,
  SETUP_PHONE_NUMBER_FORM,
  VERIFY_PHONE_NUMBER_FORM,
  VERIFY_TOTP_TOKEN_FORM
} from '../../../constants/forms'

import useAuthStyles from '../../../styles/common/authPages'
import useDrawerFormsStyles from '../../../styles/common/drawerForms'

// current component covers 2 different flows based on selected mfaType(one is SMS, 2nd is TOTP)
// the difference between and TOTP is that TOTP has one extra step/form -
const MfaSetupForms = ({ onGoBack, onSuccessSetup, mfaType }) => {
  const dispatch = useDispatch()
  const authClasses = useAuthStyles()
  const drawerFormClasses = useDrawerFormsStyles()
  const { t } = useTranslation()

  // to re-send verification, we need to re-submit values with the same update action
  const [submittedUserPhoneValues, setSubmittedUserPhoneValues] = useState(null)

  const user = useSelector(getCurrentUserSelector)
  const { id: userId } = user

  const { activatedForm, setActivatedForm } = useContinuousForm({
    initialForm: SETUP_PHONE_NUMBER_FORM
  })

  // todo - refactor this to custom endpoint instead of triggering new code verification on every user profile
  //  submit(this require BE changes)
  const handleVerificationResend = () => {
    dispatch(
      updateCurrentUserProfile({
        userId: userId,
        userData: submittedUserPhoneValues
      })
    )
  }

  const handlePhoneApprove = () => {
    if (mfaType === SMS_MFA) {
      setActivatedForm(SET_MFA_TYPE_FORM)
    } else {
      setActivatedForm(VERIFY_TOTP_TOKEN_FORM)
    }
  }

  return (
    <>
      <div className={authClasses.formContainer}>
        <div className={drawerFormClasses.section}>
          {activatedForm === SETUP_PHONE_NUMBER_FORM && (
            <>
              <h3 className={drawerFormClasses.sectionTitle}>
                🔒{' '}
                {t(
                  mfaType === SMS_MFA
                    ? 'Secure your account with SMS verification'
                    : 'Set phone number to proceed with authenticator app'
                )}
              </h3>
              <SetupPhoneNumberForm
                onSuccessSubmit={phoneValues => {
                  setActivatedForm(VERIFY_PHONE_NUMBER_FORM)
                  setSubmittedUserPhoneValues(phoneValues)
                }}
                onPhoneApprove={handlePhoneApprove}
              />
            </>
          )}

          {activatedForm === VERIFY_PHONE_NUMBER_FORM && (
            <>
              <h3 className={drawerFormClasses.sectionTitle}>🔒 {t('Phone number verification')}</h3>
              <p>
                {t(
                  'A message with a verification code has been sent to your phone. Please enter the code to continue.'
                )}
              </p>
              <br />
              <PhoneVerificationForm
                modalData={submittedUserPhoneValues}
                onVerificationResend={handleVerificationResend}
                onSuccessSubmit={handlePhoneApprove}
              />
            </>
          )}
          {activatedForm === VERIFY_TOTP_TOKEN_FORM && (
            <MfaTotpSetup onSuccessSetup={() => setActivatedForm(SET_MFA_TYPE_FORM)} onGoBack={onGoBack} />
          )}
          {activatedForm === SET_MFA_TYPE_FORM && (
            <>
              <h3 className={drawerFormClasses.sectionTitle}>🔒 {t('Finalizing SMS verification set up')}</h3>
              <MfaTypeSet onSuccessSubmit={onSuccessSetup} mfaType={mfaType} />
            </>
          )}
        </div>
      </div>

      <p>
        {t('Having trouble?')}{' '}
        <ActionText onClick={onGoBack} isDarkGrey isUnderlined={true}>
          {t('Go back')}
        </ActionText>
      </p>
    </>
  )
}

export default MfaSetupForms
