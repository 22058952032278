import React from 'react'
import PropTypes from 'prop-types'

import LineItemFormContent from './LineItemFormContent'

const LineItemForm = ({ adAccountId, ...props }) => {
  return <LineItemFormContent adAccountId={adAccountId} {...props} />
}

LineItemForm.propTypes = {
  adAccountId: PropTypes.string,
  isExistingCampaignFlow: PropTypes.bool,
  onSuccessSubmit: PropTypes.func.isRequired
}

export default LineItemForm
