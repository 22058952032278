import React, { useEffect } from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useKeenSlider } from 'keen-slider/react'

import Banner from './Banner'

import { bannersSelector } from '../../../../modules/selectors/app'
import { isAuthorizedSelector } from '../../../../modules/selectors/cognito'

import { getBanners } from '../../../../modules/actions/app'

import useStyles from './styles'

function BannersContainer() {
  const classes = useStyles()
  const dispatch = useDispatch()

  const banners = useSelector(bannersSelector)
  const isAuthorized = useSelector(isAuthorizedSelector)

  // Slider configuration
  const [sliderRef] = useKeenSlider(
    {
      // If there are more than one banner - rotate them in a loop
      loop: banners?.length > 1,
      vertical: true
    },
    [
      slider => {
        let timeout
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            // Changes the currently active slide to the next one when called. If exists.
            slider.next()
          }, 4000)
        }

        // Registers an event hooks mouseover and mouseout when created.
        slider.on('created', () => {
          // Stop sleder on mouseover
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true
            clearNextTimeout()
          })
          // Start slider on mouseout
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })

        // When Drag has started clear timeout that changes slides
        slider.on('dragStarted', clearNextTimeout)
        // When Animation has ended start timeout that changes slides
        slider.on('animationEnded', nextTimeout)
        // the update function was called due to a size change or other trigger
        slider.on('updated', nextTimeout)
      }
    ]
  )

  useEffect(() => {
    // Call getBanners endpoint in 3 cases: 1) App is firstly loaded, 2) login  3) logout
    dispatch(getBanners())
  }, [dispatch, isAuthorized])

  if (!banners?.length) return null

  return (
    <div ref={sliderRef} className={classnames('keen-slider', classes.slider)}>
      {banners.map(banner => (
        <div key={banner.id} className="keen-slider__slide">
          <Banner banner={banner} />
        </div>
      ))}
    </div>
  )
}

export default BannersContainer
