import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import AmendmentContractCreateForm from './AmendmentContractCreateForm'
import SendAmendmentContractForm from './SendAmmendmentContractForm'

import useContinuousForm from '../../../../../hooks/formHooks/useContinuousForm'

import { closeForm } from '../../../../../modules/actions/forms'
import { getMediaOrder, getMediaOrderFiles } from '../../../../../modules/actions/mediaOrders'
import { mediaOrderSelector } from '../../../../../modules/selectors/mediaOrders'

export const CONTRACT_CREATE_FORM = 'contract_create_form'
export const AMENDMENT_SEND_FORM = 'amendment_send_form'
const MediaOrderAmendForm = ({ onFormChange }) => {
  const dispatch = useDispatch()
  const { activatedForm, setActivatedForm } = useContinuousForm({
    initialForm: CONTRACT_CREATE_FORM
  })

  const mediaOrder = useSelector(mediaOrderSelector)
  const mediaOrderId = mediaOrder.id

  const handleFormSwitch = useCallback(
    newActiveFormName => {
      setActivatedForm(newActiveFormName)
      onFormChange(newActiveFormName)
    },
    [setActivatedForm, onFormChange]
  )

  const onSuccessSubmit = useCallback(() => {
    dispatch(closeForm())
  }, [dispatch])

  const amendmentContractCreateFormSuccessSubmitHandler = useCallback(() => {
    if (mediaOrder.without_requirements) {
      // If media order is without requirements - we don't need to sign the contract,
      // we can just update the media order (re-fetch media order for simplification) and load the files to represent the new amendment
      dispatch(getMediaOrder(mediaOrderId))
      dispatch(getMediaOrderFiles({ media_order: mediaOrderId }))
      onSuccessSubmit()
    } else {
      // otherwise - show the send amendment form to sign the contract
      handleFormSwitch(AMENDMENT_SEND_FORM)
    }
  }, [handleFormSwitch, dispatch, mediaOrder, mediaOrderId, onSuccessSubmit])

  useEffect(() => {
    return () => {
      // reset active form to default when forms are unmounted
      onFormChange(null)
    }
  }, [onFormChange])

  const getActivatedForm = useCallback(() => {
    switch (activatedForm) {
      case AMENDMENT_SEND_FORM:
        return (
          <SendAmendmentContractForm
            onStepBack={() => handleFormSwitch(CONTRACT_CREATE_FORM)}
            onSuccessSubmit={onSuccessSubmit}
          />
        )
      case CONTRACT_CREATE_FORM:
      default:
        return <AmendmentContractCreateForm onSuccessSubmit={amendmentContractCreateFormSuccessSubmitHandler} />
    }
  }, [activatedForm, handleFormSwitch, onSuccessSubmit, amendmentContractCreateFormSuccessSubmitHandler])

  return getActivatedForm()
}

MediaOrderAmendForm.propTypes = {
  onFormChange: PropTypes.func.isRequired
}

export default MediaOrderAmendForm
