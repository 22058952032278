import { createStyles } from '../../../styles/helpers'

import { darkChipGreen, darkChipRed, darkChipBlue } from '../../../styles/const/colors'
import { phonesDown, phonesUp } from '../../../styles/const/breakpoints'

const useStyles = createStyles({
  title: {
    fontSize: 22,
    marginBottom: 48,
    textAlign: 'center'
  },
  content: {
    margin: '80px auto 0',
    fontSize: 14,
    maxWidth: 830
  },
  contentRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 132,
    '&:nth-child(odd)': {
      flexDirection: 'row-reverse',
      textAlign: 'right'
    }
  },
  contentText: {
    '& h4': {
      fontSize: 18,
      margin: '0 0 16px'
    },
    '& p': {
      margin: '0 0 22px'
    },
    '& > *:last-child': {
      marginBottom: '0 !important'
    },
    '& .info': {
      color: darkChipBlue,
      fontWeight: 700
    },
    '& .failure': {
      color: darkChipRed,
      fontWeight: 700
    },
    '& .success': {
      color: darkChipGreen,
      fontWeight: 700
    }
  },
  contentGraph: {},

  [`@media screen and (${phonesUp})`]: {
    contentText: {
      maxWidth: 330
    },
    contentRow: {
      '&:nth-child(odd) $contentText': {
        marginLeft: 10
      },
      '&:nth-child(even) $contentText': {
        marginRight: 10
      }
    },
    contentGraph: {
      maxWidth: '450px',
      flexGrow: 1
    }
  },
  [`@media screen and (${phonesDown})`]: {
    content: {
      margin: '60px auto 0',
      '& h3': {
        margin: '0 0 36px',
        textAlign: 'left'
      }
    },
    contentRow: {
      display: 'block',
      '&:not(:last-child)': {
        marginBottom: 44
      },
      '&:nth-child(odd)': {
        textAlign: 'left'
      }
    },
    contentGraph: {
      margin: '20px -8px 0'
    }
  }
})

export default useStyles
