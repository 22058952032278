import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import UploadedImageIcon from './UploadedImageIcon'

import { ASSET_IMAGE_LIST } from '../../../../../../GoogleAssetGroup/GoogleAssetGroupMainForm/fields'
import { landscapeRatio, portraitRatio } from '../../../../../../validations'

import useStyles from './styles'

const UploadedImagesInfoBlock = ({ values, showPortraitImage }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const imageList = values[ASSET_IMAGE_LIST]
  const filledImages = imageList.filter(image => image?.file_url)

  return (
    <>
      <p>{t('Upload or select at least 1 of each of the following:')}</p>
      <p className={classes.uploadedImageText}>
        <UploadedImageIcon uploadedImages={filledImages} ratio={landscapeRatio} />
        {t('landscape image with ratio 1.91:1')}
      </p>

      <p className={classes.uploadedImageText}>
        <UploadedImageIcon uploadedImages={filledImages} ratio={1} />
        {t('Square image')}
      </p>
      {showPortraitImage && (
        <p className={classes.uploadedImageText}>
          <UploadedImageIcon uploadedImages={filledImages} ratio={portraitRatio} />
          {t('Portrait image with ratio 4:5')}
        </p>
      )}
    </>
  )
}

UploadedImagesInfoBlock.propTypes = {
  values: PropTypes.object.isRequired,
  showPortraitImage: PropTypes.bool
}

export default UploadedImagesInfoBlock
