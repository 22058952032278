import { card } from '../common'
import { phonesDown } from '../const/breakpoints'

export function placeholderColor(color) {
  return {
    '&::-webkit-input-placeholder': {
      color,
      opacity: 1
    },
    ':-moz-placeholder': {
      /* Mozilla Firefox 4 to 18 */ color,
      opacity: 1
    },
    '::-moz-placeholder': {
      /* Mozilla Firefox 19+ */ color,
      opacity: 1
    },
    ':-ms-input-placeholder': {
      /* Internet Explorer 10-11 */ color,
      opacity: 1
    },
    '::-ms-input-placeholder': {
      /* Microsoft Edge */ color,
      opacity: 1
    },

    '::placeholder': {
      /* Most modern browsers support this now. */ color,
      opacity: 1
    }
  }
}

export function cardWithBorder(borderColor, theme) {
  return {
    extend: card(theme),
    minHeight: 38,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 11px 8px 15px',
    position: 'relative',
    marginBottom: 8,

    '&:before': {
      content: '""',
      position: 'absolute',
      width: 5,
      height: '100%',
      background: borderColor,
      left: 0,
      top: 0,
      borderBottomLeftRadius: theme.defaultBorderRadius,
      borderTopLeftRadius: theme.defaultBorderRadius
    },
    [`@media screen and (${phonesDown})`]: {
      marginBottom: 16
    }
  }
}

export function filledLabel(isTextArea) {
  const top = isTextArea ? 8 : 3
  return {
    top: top,
    fontSize: 12,
    transform: 'translateY(0)'
  }
}
