import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import ProductQuantity from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/OrderCartStep/PackageBreakdown/ProductBreakdown/ProductQuantity'

import { useProductQuantityLimits } from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/OrderCartStep/PackageBreakdown/ProductBreakdown/useProductQuantityLimits'

import { calc } from '../../../../../../../helpers/numbers'
import {
  CUSTOM_PRODUCT_PRICE,
  MEDIA_PRODUCTS,
  ORIGINAL_PRODUCT_PRICE,
  PRODUCT_PERIODS_DATES,
  QUANTITY
} from '../../../fields'

const QuantityCell = ({ formik, productValues, productIndex, isEditMode }) => {
  const { setFieldValue } = formik
  const isProductSelected = !!productValues.data
  const productPath = `${MEDIA_PRODUCTS}[${productIndex}]`

  const originalProductPrice = productValues[ORIGINAL_PRODUCT_PRICE]
  const maxProductQuantity = useProductQuantityLimits(productValues[PRODUCT_PERIODS_DATES])

  const handleQuantityChange = useCallback(
    newQuantity => {
      const totalPrice = calc(originalProductPrice).mul(newQuantity).toDP(2).toNumber()
      setFieldValue(`${productPath}.${CUSTOM_PRODUCT_PRICE}`, totalPrice)
    },
    [originalProductPrice, productPath, setFieldValue]
  )

  if (!isProductSelected) {
    return null
  }

  return isEditMode ? (
    <ProductQuantity
      formik={formik}
      productsQuantity={productValues[QUANTITY]}
      productPath={productPath}
      minQuantity={1}
      maxQuantity={maxProductQuantity}
      onQuantityChange={handleQuantityChange}
    />
  ) : (
    `x${productValues[QUANTITY]}`
  )
}

QuantityCell.propTypes = {
  formik: PropTypes.object,
  productValues: PropTypes.object,
  productIndex: PropTypes.number,
  isEditMode: PropTypes.bool
}

export default QuantityCell
