import React, { useMemo } from 'react'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import FacebookAdCreativeFields from '../../../../../ReusableFormFIelds/FacebookAdCreativeFields'
import AdaptiveAdCard from '../ProductAdRoute/ProductAdStep/AdaptiveAdCard'

import { useProductSuggestions } from '../ProductAdRoute/ProductAdStep/hooks'
import { useShowProductOrPageSelectionStep } from '../../hooks/useShowProductOrPageSelectionStep'

import { PRODUCT_DATA } from '../../../../../../ReusableFormFields/ProductFields/fields'
import {
  AD_CUSTOM_URL,
  AD_DESCRIPTION,
  AD_HEADLINE,
  FB_PAGE_CHOICE,
  FILE_URL,
  MEDIA_DURATION,
  MEDIA_FORMAT,
  MEDIA_HEIGHT,
  MEDIA_RATIO,
  MEDIA_SIZE,
  MEDIA_WIDTH,
  PRIMARY_TEXT_LIST
} from '../../../../fields'
import {
  BRAND_CATEGORY_PAGE_DATA,
  PRODUCT_OPTION,
  PRODUCT_OPTION_BRAND_CATEGORY_PAGES,
  PRODUCT_OPTION_PRODUCT_PAGES
} from '../../../../../../ReusableFormFields/ProductsAndPagesFields/fields'

const stepFields = [
  PRIMARY_TEXT_LIST,
  FILE_URL,
  MEDIA_SIZE,
  MEDIA_WIDTH,
  MEDIA_HEIGHT,
  MEDIA_FORMAT,
  MEDIA_RATIO,
  MEDIA_DURATION,
  AD_CUSTOM_URL,
  AD_HEADLINE,
  AD_DESCRIPTION,
  FB_PAGE_CHOICE
]
const AdCreativeStep = ({ formik, isActive, isLastStep, adAccountId, placementPositions, handleStepChange }) => {
  const { values } = formik

  const showProductSelectionStep = useShowProductOrPageSelectionStep()

  const selectedProductOrPageData = useMemo(() => {
    if (values[PRODUCT_OPTION] === PRODUCT_OPTION_BRAND_CATEGORY_PAGES) {
      return values[BRAND_CATEGORY_PAGE_DATA]
    } else if (values[PRODUCT_OPTION] === PRODUCT_OPTION_PRODUCT_PAGES) {
      return values[PRODUCT_DATA]
    } else {
      return {}
    }
  }, [values])

  const { regenerateSuggestions, suggestions, suggestionsLoading } = useProductSuggestions({
    selectedProduct: selectedProductOrPageData,
    isActiveStep: isActive
  })

  const handleContinue = () => {
    handleStepChange(stepFields, values)
  }

  const showCustomizedMediaQuestion =
    placementPositions &&
    (placementPositions.includes('facebook_story') ||
      placementPositions.includes('instagram_story') ||
      placementPositions.includes('instagram_reels') ||
      !placementPositions.length)

  return (
    <Step stepTitle="Review the ad creative" handleContinue={isLastStep ? null : handleContinue}>
      {isActive && <AdaptiveAdCard values={values} suggestions={suggestions} suggestionsLoading={suggestionsLoading} />}
      <FacebookAdCreativeFields
        formik={formik}
        regenerateSuggestions={regenerateSuggestions}
        adAccountId={adAccountId}
        placementPositions={placementPositions}
        showCustomizedMediaQuestion={showCustomizedMediaQuestion}
        showCustomizedPreview={isActive}
        showCustomClickThroughLink={!showProductSelectionStep}
        showNameField={true}
      />
    </Step>
  )
}

export default AdCreativeStep
