import { createStyles } from '../../../../styles/helpers'
import { smallPhonesDown, tabletDown, smallDesktopDown } from '../../../../styles/const/breakpoints'

const useStyles = createStyles({
  filtersRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 12
  },
  rightFiltersRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 12,
    flexWrap: 'wrap'
  },
  topFiltersRow: {
    marginBottom: 12
  },
  select: {
    minWidth: 120,
    maxWidth: 120
  },
  locationsSelect: {
    minWidth: 170
  },
  productSelect: {
    minWidth: 150,
    maxWidth: 150
  },
  searchInput: {
    minWidth: 120
  },
  [`@media screen and (${smallDesktopDown})`]: {
    rightFiltersRow: {
      justifyContent: 'flex-end',
      marginLeft: 0
    },
    filtersRow: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
    }
  },
  [`@media screen and (${tabletDown})`]: {
    filtersRow: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr'
    },
    searchInput: {
      width: '100%'
    },
    productSelect: {
      maxWidth: 'none'
    },
    select: {
      maxWidth: 'none'
    },
    dateRange: {
      minWidth: 0,
      maxWidth: 'none',
      width: '100%'
    }
  },
  [`@media screen and (${smallPhonesDown})`]: {
    topFiltersRow: {
      gridTemplateColumns: '1fr'
    }
  }
})

export default useStyles
