export const generateMediaFieldText = (productsNames = []) => {
  // Define the number of names to be shown before addition is displayed
  const visibleNamesCount = 2

  // If no productsNames, return empty string
  if (!productsNames?.length) {
    return {
      fieldName: '',
      moreItemText: ''
    }
  }

  // If the amount of productsNames is less than or equal to visibleNamesCount, return all names
  if (productsNames.length <= visibleNamesCount) {
    return {
      fieldName: productsNames.join(', '),
      moreItemText: ''
    }
  }

  // If the amount of productsNames is greater than visibleNamesCount, return the first two names and the total of hidden names
  const hiddenNamesCount = productsNames.length - visibleNamesCount

  // Return visible names and hidden count
  return {
    fieldName: productsNames.slice(0, visibleNamesCount).join(', '),
    moreItemText: `+ ${hiddenNamesCount} more`
  }
}
