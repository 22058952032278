import React from 'react'
import { useTranslation } from 'react-i18next'
import { add, addYears } from 'date-fns'

import RadioBlock from '../../../components/Form/RadioBlock'
import DatePicker from '../../../components/DatePicker'
import TimePicker from '../../../components/TimePicker'

import { OPTION_CUSTOM } from '../../../constants/forms'

import { START_DATE, START_OPTION, START_OPTION_NOW, START_TIME } from './fields'
import { STOP_DATE } from '../StopTimeFields/fields'

import useStepFormStyles from '../../../styles/common/stepForms'
import useDrawerFormsStyles from '../../../styles/common/drawerForms'

const StartTimeFields = ({ formik, showTimePicker = true }) => {
  const { t } = useTranslation()

  const stepFormClasses = useStepFormStyles()
  const drawerFormsClasses = useDrawerFormsStyles()

  const { values, errors, touched, setFieldValue } = formik

  const timeError = errors[START_TIME]
  const timeTouched = touched[START_TIME]

  const handleDatePickerChange = value => {
    setFieldValue(START_DATE, value)

    // update stop_date to be 30 days in advance from start_date
    setFieldValue(STOP_DATE, add(value, { days: 30 }), false)
  }

  const handleTimePickerChange = value => {
    setFieldValue(START_TIME, value || '00:00')
  }

  return (
    <div className={stepFormClasses.stepBody}>
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_start_now"
        name={START_OPTION}
        value={START_OPTION_NOW}
        selectedValue={values[START_OPTION]}
        label={t('As soon as possible')}
      />
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_start_specific"
        name={START_OPTION}
        value={OPTION_CUSTOM}
        selectedValue={values[START_OPTION]}
        label={t('At a specific date and time')}
        error={timeError}
        touched={timeTouched}
      >
        <DatePicker
          className={drawerFormsClasses.dateTimeInput}
          title={t('Start Date')}
          value={values[START_DATE]}
          // limit maxDate to one year from today
          maxDate={addYears(new Date(), 1)}
          changeHandler={handleDatePickerChange}
        />
        {showTimePicker && (
          <TimePicker
            className={drawerFormsClasses.dateTimeInput}
            value={values[START_TIME]}
            changeHandler={handleTimePickerChange}
          />
        )}
      </RadioBlock>
    </div>
  )
}

export default StartTimeFields
