import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import PageSection from '../../PageSection'
import BarGraph from '../../Graphs/BarGraph'

import { formatNumber } from '../../../../helpers/numbers'

import useStyles from '../styles'

const ClicksGraph = ({ rawGraphData }) => {
  const classes = useStyles()

  const clicksGraphData = useMemo(() => rawGraphData && rawGraphData.data.map(data => data.value), [rawGraphData])
  const clicksGraphLabels = useMemo(() => rawGraphData && rawGraphData.data.map(data => data.segment), [rawGraphData])

  return (
    <PageSection className={classes.sectionContainer}>
      <div className={classes.headingContainer}>
        <div className={classes.title}>Clicks</div>
        <div className={classes.value}>{formatNumber(rawGraphData.total)}</div>
      </div>
      <BarGraph className={classes.graphWrapper} data={clicksGraphData} labels={clicksGraphLabels} format="integer" />
    </PageSection>
  )
}

ClicksGraph.propTypes = {
  rawGraphData: PropTypes.object.isRequired
}

export default ClicksGraph
