import React, { useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { TABLE_COL_CLASSNAME, TABLE_ROW_CLASSNAME } from '../../../constants/pdf'

import useStyles from '../styles'
import useColumnStyles from '../TableRows/TableRowWrapper/Col/styles'

function TableFooter({ cols, footerClassName, Footer, isBigRow, showTopBorder }) {
  const classes = useStyles({ isBigRow })
  const columnClasses = useColumnStyles()

  const columnClassName = columnClasses[TABLE_COL_CLASSNAME]

  const FooterColumns = useCallback(() => {
    return cols
      .filter(({ showOnMobile }) => showOnMobile !== false)
      .map(({ footerClassName, footer, style }) => {
        return (
          <div key={uuidv4()} className={classnames(columnClassName, footerClassName)} style={style}>
            {typeof footer === 'function' ? footer() : footer}
          </div>
        )
      })
  }, [cols, columnClassName])

  return Footer ? (
    <footer className={classnames(classes.footer, footerClassName, { [classes.topBorder]: showTopBorder })}>
      {Footer}
    </footer>
  ) : (
    <div
      className={classnames(classes[TABLE_ROW_CLASSNAME], classes.headlineRow, footerClassName, {
        [classes.topBorder]: showTopBorder
      })}
      key={uuidv4()}
    >
      <FooterColumns />
    </div>
  )
}

TableFooter.propTypes = {
  footerClassName: PropTypes.string,
  Footer: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  cols: PropTypes.array,
  isBigRow: PropTypes.bool,
  showTopBorder: PropTypes.bool
}

export default TableFooter
