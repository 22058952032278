import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import Modal from '../../../../components/Modal'

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-grey.svg'

import useStyles from './styles'

const InfoModal = ({
  Icon,
  ModalMessage,
  isOpen,
  onClose,
  modalTitle,
  children,
  className,
  classNameBody,
  iconClassName,
  contentClassName
}) => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} shouldFocusAfterRender={true} className={className}>
      <span onClick={onClose} className={classes.closeIcon}>
        <CloseIcon />
      </span>
      <div className={classnames(classes.body, classNameBody)}>
        {Icon && <Icon className={iconClassName} />}
        <h2 className={classes.title}>{t(modalTitle)}</h2>
        <div className={classes.modalMessage}>{ModalMessage}</div>
        <div className={classnames(classes.contentContainer, contentClassName)}>{children}</div>
      </div>
    </Modal>
  )
}

InfoModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  ModalMessage: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  modalTitle: PropTypes.string,
  Icon: PropTypes.object,
  iconClassName: PropTypes.string,
  classNameBody: PropTypes.string,
  contentClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

export default InfoModal
