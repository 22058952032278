import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import ButtonChangeText from '../../../../../../features/components/Buttons/ButtonChangeText'

import { ReactComponent as CopyIcon } from '../../../../../../assets/icons/copy-icon.svg'
import { ContractBuilderContext } from '../ContractBuilderContext'

import { formatTableContentToSheet } from './helpers'

import { TABLE_ROW_CLASSNAME } from '../../../../../../constants/pdf'
import { QUOTATION_BUILDER_TABLE_ID } from '../ProductsTable'

import useStyles from './styles'

function TableActions() {
  const classes = useStyles()
  const { t } = useTranslation()

  const { editProductId } = useContext(ContractBuilderContext)

  const handleCopy = useCallback(() => {
    // allow to copy table data to clipboard with the format to paste in excel or sheets
    const table = document.querySelector(`#${QUOTATION_BUILDER_TABLE_ID}`)

    if (table) {
      // Get all rows from the table, including headers
      const rows = Array.from(table.querySelectorAll(`[class^="${TABLE_ROW_CLASSNAME}-"]`))

      const tableString = formatTableContentToSheet(rows)

      // Copy the resulting string to the clipboard
      navigator.clipboard
        .writeText(tableString)
        .then(() => {
          // success
        })
        .catch(err => {
          console.error('Failed to copy: ', err)
        })
    }
  }, [])

  return (
    <div className={classes.tableActions}>
      <ButtonChangeText
        className={classes.copyTableBtn}
        type="button"
        // disable button when editing a product is in progress
        disabled={Boolean(editProductId)}
        onClick={handleCopy}
        isSmall={true}
        textTimeout={2000}
        textToChange={t('Copied!')}
      >
        <CopyIcon />
        {t('Copy table')}
      </ButtonChangeText>
    </div>
  )
}

export default TableActions
