import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import MediaProductLocationCreateForm from './MediaProductLocationCreateForm'

import { clearGetController, getController } from '../../../../modules/actions/controller'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import { controllerWasLoadedSelector } from '../../../../modules/selectors/controller'
import { formsOpenStatesSelector } from '../../../../modules/selectors/forms'

import { MEDIA_PRODUCT_LOCATION_CREATE } from '../../../../constants/forms'

function MediaProductLocationCreate() {
  const dispatch = useDispatch()

  const selectedControllerId = useSelector(selectedControllerIdSelector)
  const controllerWasLoaded = useSelector(controllerWasLoadedSelector)

  const { [MEDIA_PRODUCT_LOCATION_CREATE]: isFormOpen = false } = useSelector(formsOpenStatesSelector)

  const onAfterFormCloseHandler = useCallback(() => {
    dispatch(clearGetController())
  }, [dispatch])

  useEffect(() => {
    if (isFormOpen) {
      dispatch(getController({ id: selectedControllerId }))
    }
  }, [dispatch, isFormOpen, selectedControllerId])

  return (
    <FormDrawerWrapper
      formName={MEDIA_PRODUCT_LOCATION_CREATE}
      title="Product Locations"
      description="Product locations are used to group media products into physical locations like malls or stores"
      openButtonText="Add new location"
      isFormLoading={!controllerWasLoaded}
      onAfterFormClose={onAfterFormCloseHandler}
    >
      <MediaProductLocationCreateForm />
    </FormDrawerWrapper>
  )
}

export default MediaProductLocationCreate
