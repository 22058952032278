import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import {
  getTikTokActionCategories,
  clearTikTokActionCategories,
  getTikTokRegions,
  clearTikTokRegions,
  clearTikTokCustomAudiences,
  getTikTokCustomAudiences,
  getTikTokInterests,
  clearTikTokInterests
} from '../../../../modules/actions/tikTokConstants'
import {
  tikTokActionCategoriesErrorSelector,
  tikTokActionCategoriesWasLoadedSelector,
  tikTokCustomAudiencesErrorSelector,
  tikTokCustomAudiencesWasLoadedSelector,
  tikTokInterestsErrorSelector,
  tikTokInterestsWasLoadedSelector,
  tikTokRegionsErrorSelector,
  tikTokRegionsWasLoadedSelector
} from '../../../../modules/selectors/tikTokConstants'
import { campaignSelector } from '../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../modules/selectors/app'

export function useLoadAdditionalData({ isFormOpen }) {
  const dispatch = useDispatch()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const campaign = useSelector(campaignSelector)
  const tikTokRegionsWasLoaded = useSelector(tikTokRegionsWasLoadedSelector)
  const tikTokRegionsError = useSelector(tikTokRegionsErrorSelector)
  const tikTokAudiencesWasLoaded = useSelector(tikTokCustomAudiencesWasLoadedSelector)
  const tikTokAudiencesError = useSelector(tikTokCustomAudiencesErrorSelector)
  const tikTokActionCategoriesWasLoaded = useSelector(tikTokActionCategoriesWasLoadedSelector)
  const tikTokActionCategoriesError = useSelector(tikTokActionCategoriesErrorSelector)
  const tikTokInterestsWasLoaded = useSelector(tikTokInterestsWasLoadedSelector)
  const tikTokInterestsError = useSelector(tikTokInterestsErrorSelector)

  const { objective: campaignObjective } = campaign

  const tikTokRegionsDidLoading = tikTokRegionsWasLoaded || tikTokRegionsError
  const tikTokAudiencesDidLoading = tikTokAudiencesWasLoaded || tikTokAudiencesError
  const tikTokActionCategoriesDidLoading = tikTokActionCategoriesWasLoaded || tikTokActionCategoriesError
  const tikTokInterestsDidLoading = tikTokInterestsWasLoaded || tikTokInterestsError

  const isAdditionalDataLoading =
    !tikTokRegionsDidLoading ||
    !tikTokAudiencesDidLoading ||
    !tikTokActionCategoriesDidLoading ||
    !tikTokInterestsDidLoading

  useEffect(() => {
    if (isFormOpen && !tikTokRegionsDidLoading && campaignObjective) {
      dispatch(
        getTikTokRegions({
          objective_type: campaignObjective,
          account: selectedAdAccountId,
          placement: ['PLACEMENT_TIKTOK']
        })
      )
    }
  }, [dispatch, isFormOpen, selectedAdAccountId, tikTokRegionsDidLoading, campaignObjective])

  useEffect(() => {
    if (isFormOpen && !tikTokAudiencesDidLoading) {
      dispatch(getTikTokCustomAudiences({ account: selectedAdAccountId, page_size: 999 }))
    }
  }, [dispatch, isFormOpen, selectedAdAccountId, tikTokAudiencesDidLoading])

  useEffect(() => {
    if (isFormOpen && !tikTokActionCategoriesDidLoading) {
      dispatch(getTikTokActionCategories({ account: selectedAdAccountId }))
    }
  }, [dispatch, isFormOpen, selectedAdAccountId, tikTokActionCategoriesDidLoading])

  useEffect(() => {
    if (isFormOpen && !tikTokInterestsDidLoading) {
      dispatch(getTikTokInterests({ account: selectedAdAccountId, language: 'en', placement: ['PLACEMENT_TIKTOK'] }))
    }
  }, [dispatch, isFormOpen, selectedAdAccountId, tikTokInterestsDidLoading])

  useEffect(
    () => () => {
      dispatch(clearTikTokRegions())
      dispatch(clearTikTokCustomAudiences())
      dispatch(clearTikTokActionCategories())
      dispatch(clearTikTokInterests())
    },
    [dispatch]
  )

  return isAdditionalDataLoading
}
