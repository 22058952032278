const locationListTypes = [
  {
    label: 'Store',
    value: 'Supermarket'
  },
  {
    label: 'List of OOH sites',
    value: 'OOH'
  },
  {
    label: 'Other',
    value: 'Other'
  }
]

export default locationListTypes
