import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import LineItemForm from './LineItemForm'

import useContinuousForm from '../../../../../../hooks/formHooks/useContinuousForm'

// import useRedirectFormDrawer from '../../../../../../features/components/FormDrawerWrapper/useRedirectFormDrawer'
//
// import { mediaOrderUploadCreativeDataSelector } from '../../../../../../modules/selectors/mediaOrders'
//
// import { ROUTES } from '../../../../../../constants/routes'
// import { MODAL_TYPE } from '../../../../../../constants/other'

export const LINE_ITEM_FORM = 'line_item_form'
export const AD_ADVANTAGE_SHOPPING_FORM = 'ad_advantage_shopping_form'

// todo route real content to be implemented as separate tickets
const AdvantageShoppingRoute = ({
  isExistingCampaignFlow,
  adAccountId,
  setActivatedInternalForm
  // onSuccessAdFormSubmit
}) => {
  const { activatedForm, setActivatedForm } = useContinuousForm({
    initialForm: LINE_ITEM_FORM
  })

  const handleSetActivatedForm = useCallback(
    formName => {
      setActivatedForm(formName)
      // set internal selected form for progress representation
      setActivatedInternalForm(formName)
    },
    [setActivatedForm, setActivatedInternalForm]
  )

  //
  // const handleSuccessRedirect = useRedirectFormDrawer(
  //   `${ROUTES.mediaOrderSummary}?message=${MODAL_TYPE.mediaOrderFacebookCreativeUploaded}`
  // )
  //
  // const isLineItemPopulated = !!Object.keys(lineItem).length
  //
  // const onSuccessAdFormSubmitHandler = useCallback(() => {
  //   onSuccessAdFormSubmit()
  //
  //   handleSuccessRedirect()
  // }, [handleSuccessRedirect, onSuccessAdFormSubmit])

  switch (activatedForm) {
    case AD_ADVANTAGE_SHOPPING_FORM:
      return <div>Ad form</div>
    case LINE_ITEM_FORM:
    default:
      return (
        <LineItemForm
          isExistingCampaignFlow={isExistingCampaignFlow}
          adAccountId={adAccountId}
          onSuccessSubmit={() => {
            handleSetActivatedForm(AD_ADVANTAGE_SHOPPING_FORM)
          }}
        />
      )
  }
}

AdvantageShoppingRoute.propTypes = {
  isExistingCampaignFlow: PropTypes.bool.isRequired,
  adAccountId: PropTypes.string.isRequired,
  onSuccessAdFormSubmit: PropTypes.func.isRequired
}

export default AdvantageShoppingRoute
