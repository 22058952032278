import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import EditForm from '../../../../../../../features/components/Forms/EditForm'
import MultipleOptionsField from '../../../../../../ReusableFormFields/MultipleOptionsField'

import { getLongHeadlineData } from '../HeadlinesSection/helpers'

import { getInitialValues } from './fields'
import { useEditAssetGroupSubmit } from '../../hooks'

import { selectedAdAccountIdSelector } from '../../../../../../../modules/selectors/app'
import { assetsCreateErrorSelector, assetsCreateLoadingSelector } from '../../../../../../../modules/selectors/assets'

import { googleAssetFieldType } from '../../../../../../../constants/ads'
import { LONG_HEADLINES_LIST, longHeadlineMaxLength } from '../../../../fields'

const LongHeadlinesSectionForm = ({ editItemData, isInternalAssetGroup, ...formProps }) => {
  const { assets, id: assetGroupId, account } = editItemData

  const selectedAdAccount = useSelector(selectedAdAccountIdSelector)
  const adAccount = account || selectedAdAccount

  const assetsCreateError = useSelector(assetsCreateErrorSelector)
  const assetsCreateLoading = useSelector(assetsCreateLoadingSelector)

  const originalLongHeadlineAssets = useMemo(() => getLongHeadlineData(assets), [assets])

  const initialValues = useMemo(() => getInitialValues(originalLongHeadlineAssets), [originalLongHeadlineAssets])

  const purifiedFormik = useEditAssetGroupSubmit({
    originalSectionAssets: originalLongHeadlineAssets,
    fieldType: googleAssetFieldType.LONG_HEADLINE,
    sectionValueKey: LONG_HEADLINES_LIST,
    isInternalAssetGroup,
    assetGroupId,
    initialValues,
    adAccount
  })

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      requestError={assetsCreateError}
      isLoading={assetsCreateLoading}
      {...formProps}
    >
      <MultipleOptionsField
        fieldPlaceholder="Long headline"
        fieldMaxLength={longHeadlineMaxLength}
        listFieldName={LONG_HEADLINES_LIST}
        formik={purifiedFormik}
        minListQuantity={2}
      />
    </EditForm>
  )
}

export default LongHeadlinesSectionForm
