import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import Table from '../../../../../components/Table'
import ActionsDropdown from '../../../../../features/components/ActionsDropdown'
import ActionsButtonsList from '../../../../../features/components/ActionsButtonsList'

import { openForm } from '../../../../../modules/actions/forms'

import { tableColumnsSize } from '../index'
import { CATEGORY_PAGE_EDIT } from '../../../../../constants/forms'

import { phonesDownSize } from '../../../../../styles/const/breakpoints'
import useStyles from './styles'

const BrandPagesTableData = ({ categoryPages }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const classes = useStyles()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const openEditFormHandler = useCallback(
    (e, id) => {
      dispatch(openForm({ id, formName: CATEGORY_PAGE_EDIT }))
    },
    [dispatch]
  )

  const dropdownOptions = useMemo(() => {
    return [
      {
        text: t('Edit'),
        onClickHandler: openEditFormHandler
      }
    ]
  }, [openEditFormHandler, t])

  const locationsColumns = useMemo(
    () => [
      {
        header: 'Page Name',
        fieldKey: 'name'
      },
      {
        header: 'Link',
        style: { maxWidth: tableColumnsSize.link },
        Cell: ({ link }) => (
          <a rel="noreferrer" target="_blank" href={link} className={classes.link}>
            {t('Open')}
          </a>
        )
      },
      {
        Cell: ({ id }) =>
          isMobile ? (
            <ActionsButtonsList itemId={id} options={dropdownOptions} />
          ) : (
            <ActionsDropdown itemId={id} options={dropdownOptions} />
          ),
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [dropdownOptions, isMobile, classes, t]
  )

  return <Table hideFooterRow data={categoryPages} cols={locationsColumns} />
}

BrandPagesTableData.propTypes = {
  categoryPages: PropTypes.array.isRequired
}

export default BrandPagesTableData
