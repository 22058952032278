import { extractTimeStringFromDate } from '../../../../../../helpers/date'

import { START_DATE, START_TIME, STOP_DATE, STOP_TIME } from '../../../../../Multiplatform/LineItemForms/fields'

export const formatValuesToFE = lineItem => {
  const { schedule_start_time: startDate, schedule_end_time: stopDate } = lineItem

  return {
    [START_DATE]: (startDate && new Date(startDate)) || '',
    [START_TIME]: (startDate && extractTimeStringFromDate(new Date(startDate))) || '',
    [STOP_DATE]: (stopDate && new Date(stopDate)) || '',
    [STOP_TIME]: (stopDate && extractTimeStringFromDate(new Date(stopDate))) || ''
  }
}
