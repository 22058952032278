import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import AdVideoElement from './AdVideoElement'
import Icon from '../../../../../components/Icon'

import footer from '../../../../../assets/images/tt-preview-footer.jpg'
import defaultAvatar from '../../../../../assets/images/default-avatar.png'
import { ReactComponent as CommentIcon } from '../../../../../assets/icons/tiktok/comment.svg'
import { ReactComponent as LikeIcon } from '../../../../../assets/icons/tiktok/like.svg'
import { ReactComponent as ShareIcon } from '../../../../../assets/icons/tiktok/share.svg'

import { adModifiedDataSelector } from '../../../../../modules/selectors/ads'
import { tikTokIdentitiesSelector } from '../../../../../modules/selectors/tikTokConstants'

import { VIDEO } from '../../fields'
import tikTokCallToActionsList from '../../../../../constants/selectLists/tikTokCallToActionsList'

import useStyles from './styles'

const defaultData = {}

const AdTikTokPreview = ({ adData = defaultData, className, isEditMode }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const modifiedAdValues = useSelector(adModifiedDataSelector)
  const tikTokIdentities = useSelector(tikTokIdentitiesSelector)

  const ad = isEditMode
    ? {
        ...adData,
        ...modifiedAdValues
      }
    : adData

  // modified data has first priority
  const videoUrl = (isEditMode && modifiedAdValues[VIDEO]?.url) || adData[VIDEO]?.url
  const ctaLabel = tikTokCallToActionsList.find(({ value }) => value === ad.call_to_action)?.label
  // identity data is the most up to date, if there is not identity data, use data from ad
  const identity = tikTokIdentities.find(({ identity_id: id }) => id === ad.identity_id) || {}
  const logoUrl = identity.logo_url || defaultAvatar
  const displayName = identity.display_name || ad.display_name

  return (
    <div className={classnames(className, classes.previewContainer)}>
      <AdVideoElement videoUrl={videoUrl} />
      <div className={classes.content}>
        <ul className={classes.menu}>
          <li className={classes.inactive}>{t('Following')}</li>
          <li className={classes.dot}></li>
          <li className={classes.active}>{t('For You')}</li>
        </ul>
        <div className={classes.info}>
          <div className={classes.left}>
            <p className={classes.displayName}>{displayName}</p>
            <p className={classes.adText}>{ad.ad_text}</p>
            <div className={classes.cta}>
              <a className={classes.link} href={ad.landing_page_url} rel="noreferrer nofollow" target="_blank">
                {ctaLabel}
              </a>
            </div>
          </div>
          <div className={classes.right}>
            <img className={classes.logo} src={logoUrl} alt="page logo" />
            <ul className={classes.stats}>
              <li>
                <Icon className={classes.icon}>
                  <LikeIcon />
                </Icon>{' '}
                <span>71.1k</span>
              </li>
              <li>
                <Icon className={classes.icon}>
                  <CommentIcon />
                </Icon>{' '}
                <span>1281</span>
              </li>
              <li>
                <Icon className={classes.icon}>
                  <ShareIcon />
                </Icon>{' '}
                <span>232</span>
              </li>
            </ul>
          </div>
        </div>
        <img className={classes.footer} src={footer} alt="TikTokMenu" />
      </div>
    </div>
  )
}

AdTikTokPreview.propTypes = {
  adData: PropTypes.object,
  className: PropTypes.string,
  // using AdTikTokPreview for Edit form
  isEditMode: PropTypes.bool
}

export default AdTikTokPreview
