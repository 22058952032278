import React from 'react'
import PropTypes from 'prop-types'

import CreateSignatureForm from './CreateSignatureForm'
import InfoModal from '../../../../../features/components/Modals/InfoModal'

import useStyles from './styles'

function SignContractModal({ showSignContractModal, onModalClose, formProps }) {
  const classes = useStyles()

  return (
    <InfoModal
      isOpen={showSignContractModal}
      onClose={onModalClose}
      classNameBody={classes.modalBody}
      contentClassName={classes.modalContent}
    >
      <CreateSignatureForm onSuccessSubmit={onModalClose} formProps={formProps} />
    </InfoModal>
  )
}

SignContractModal.propTypes = {
  showSignContractModal: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  formProps: PropTypes.object.isRequired
}
export default SignContractModal
