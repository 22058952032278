import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import MediaOrderHistoryList from './MediaOrderHistoryList'
import MediaOrderHistoryListSkeleton from './MediaOrderHistoryListSkeleton'
import Button from '../../../../../components/Button'

import { useLoadPaginatedList } from '../../../../../features/hooks/useLoadPaginatedList'

import {
  getMediaOrderHistoryIsLoadingSelector,
  mediaOrderHistoryNextSelector,
  mediaOrderHistoryWasLoadedSelector,
  mediaOrderSelector
} from '../../../../../modules/selectors/mediaOrders'
import { clearGetMediaOrderHistory, getMediaOrderHistory } from '../../../../../modules/actions/mediaOrders'

import useDrawerFormStyles from '../../../../../styles/common/drawerForms'
import useStyles from './styles'

const MediaOrderHistoryContent = () => {
  const drawerFormClasses = useDrawerFormStyles()
  const classes = useStyles()

  const getMediaOrderHistoryIsLoading = useSelector(getMediaOrderHistoryIsLoadingSelector)
  const mediaOrderHistoryWasLoaded = useSelector(mediaOrderHistoryWasLoadedSelector)
  const mediaOrderHistoryNext = useSelector(mediaOrderHistoryNextSelector)
  const { id: mediaOrderId } = useSelector(mediaOrderSelector)

  const { t } = useTranslation()

  const mediaOrderHistoryParams = useMemo(
    () => ({
      media_order: mediaOrderId
    }),
    [mediaOrderId]
  )

  const loadMoreMediaOrderHistoryHandler = useLoadPaginatedList({
    params: mediaOrderHistoryParams,
    action: getMediaOrderHistory,
    clearAction: clearGetMediaOrderHistory,
    next: mediaOrderHistoryNext
  })

  return (
    <section className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>{t('History')}</h3>

      {getMediaOrderHistoryIsLoading && !mediaOrderHistoryWasLoaded ? (
        <MediaOrderHistoryListSkeleton />
      ) : (
        <MediaOrderHistoryList />
      )}
      {!getMediaOrderHistoryIsLoading && mediaOrderHistoryWasLoaded && mediaOrderHistoryNext && (
        <Button className={classes.loadMoreButton} onClick={loadMoreMediaOrderHistoryHandler}>
          {t('Load more')}
        </Button>
      )}
      {getMediaOrderHistoryIsLoading && mediaOrderHistoryWasLoaded && mediaOrderHistoryNext && (
        // Load more skeleton
        <MediaOrderHistoryListSkeleton />
      )}
    </section>
  )
}

export default MediaOrderHistoryContent
