import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Dropdown from '../../../../../components/Dropdown'
import Chip from '../../../../../components/Chip'

import usePermissions from '../../../../../hooks/usePermissions'

import { clearUpdateMediaOrder, updateMediaOrder } from '../../../../../modules/actions/mediaOrders'
import {
  mediaOrderSelector,
  updateMediaOrderErrorSelector,
  updateMediaOrderWasUpdatedSelector
} from '../../../../../modules/selectors/mediaOrders'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'

import { paymentStatusesOptions } from './fields'
import { FINANCE_ADMIN_PERMISSION } from '../../../../../constants/permissions'

import useStyles from './styles'

const PaymentStatusDropdown = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const mediaOrder = useSelector(mediaOrderSelector)
  const controllerId = useSelector(selectedControllerIdSelector)
  const mediaOrderWasUpdated = useSelector(updateMediaOrderWasUpdatedSelector)
  const updateMediaOrderError = useSelector(updateMediaOrderErrorSelector)

  const mediaOrderId = mediaOrder.id
  const paymentStatus = mediaOrder?.payment_status

  const permissions = usePermissions()
  const canManagePaymentStatus = permissions.can('manage', FINANCE_ADMIN_PERMISSION)

  const selectedPaymentStatus = useMemo(
    () => paymentStatusesOptions.find(item => item.value === paymentStatus),
    [paymentStatus]
  )

  const filteredStatuses = useMemo(
    () => paymentStatusesOptions.filter(item => item.value !== selectedPaymentStatus.value),
    [selectedPaymentStatus]
  )

  const updatePaymentStatusHandler = useCallback(
    status => {
      dispatch(
        updateMediaOrder(mediaOrderId, {
          payment_status: status.value,
          controller: controllerId
        })
      )
    },
    [controllerId, dispatch, mediaOrderId]
  )

  useEffect(() => {
    if (mediaOrderWasUpdated || updateMediaOrderError) {
      dispatch(clearUpdateMediaOrder())
    }
  }, [dispatch, mediaOrderWasUpdated, updateMediaOrderError])

  return (
    <Dropdown
      closeOnInsideClick
      placement="bottom-start"
      triggerElement={
        <Chip
          isHoverable={canManagePaymentStatus}
          text={selectedPaymentStatus.label}
          color={selectedPaymentStatus.color}
          className={classes.statusChip}
        />
      }
      isDisabled={!canManagePaymentStatus}
    >
      <div className={classes.container}>
        {filteredStatuses.map(status => (
          <Chip
            key={status.value}
            text={status.label}
            color={status.color}
            isHoverable={canManagePaymentStatus}
            onClick={() => updatePaymentStatusHandler(status)}
          />
        ))}
      </div>
    </Dropdown>
  )
}

export default PaymentStatusDropdown
