import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import LineGraph from '../../Graphs/LineGraph'

import PageSection from '../../PageSection'

import { getFormattedValue } from '../../../formatters'

import useStyles from '../styles'

const CTRGraph = ({ rawGraphData }) => {
  const classes = useStyles()

  const ctrGraphData = useMemo(
    () => rawGraphData && rawGraphData.data && rawGraphData.data.map(data => data.value),
    [rawGraphData]
  )
  const ctrGraphLabels = useMemo(
    () => rawGraphData && rawGraphData.data && rawGraphData.data.map(data => data.segment),
    [rawGraphData]
  )

  return (
    <PageSection className={classes.sectionContainer}>
      <div className={classes.headingContainer}>
        <div className={classes.title}>CTR</div>
        {/*<div className={classes.value}>{getFormattedValue({ value: rawGraphData.total, type: 'ctr' })}</div>*/}
        {/*// todo SHOP-1580 ROAS total is hardcoded for now, fix it later*/}
        <div className={classes.value}>{getFormattedValue({ value: 0.65, type: 'ctr' })}</div>
      </div>
      <LineGraph className={classes.graphWrapper} data={ctrGraphData} labels={ctrGraphLabels} format="percentage" />
    </PageSection>
  )
}

CTRGraph.propTypes = {
  rawGraphData: PropTypes.object.isRequired
}

export default CTRGraph
