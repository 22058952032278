import { PROVIDER_TO_PLATFORMS } from '../../constants/selectLists/platformList'

export const groupCampaignsByProvider = campaigns => {
  // group combined campaigns by their adAccountId and platform
  const groupedList = []

  campaigns.forEach(campaign => {
    const group = groupedList.find(item => {
      return item.ad_account_id === campaign.ad_account_id && item.platform === PROVIDER_TO_PLATFORMS[campaign.provider]
    })

    if (group) {
      group.campaigns.push(campaign.external_id)
    } else {
      groupedList.push({
        ad_account_id: campaign['ad_account_id'],
        platform: PROVIDER_TO_PLATFORMS[campaign.provider],
        campaigns: [campaign['external_id']]
      })
    }
  })

  return groupedList
}
