import React from 'react'
import { useTranslation } from 'react-i18next'

import MultiSelectBox from '../../../../../features/components/Form/MultiSelectBox'
import ErrorMessage from '../../../../../components/Form/ErrorMessage'

import { BRAND_SHOPPERS, CATEGORY_SHOPPERS, LIFESTYLE_CATEGORIES, SHOPPER_TYPE_OPTION } from '../fields'
import {
  brandShoppersList,
  categoryShoppersList,
  lifestyleShoppersList
} from '../../../../../constants/selectLists/audienceSelections'

import useStyles from '../../../../../styles/common/stepForms'
import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

const getStepTitle = shopperType => {
  switch (shopperType) {
    case BRAND_SHOPPERS:
      return 'Please select the brand(s) you want to target'
    case CATEGORY_SHOPPERS:
      return 'Please select the shopper category or categories you want to target'
    case LIFESTYLE_CATEGORIES:
      return 'Please select the lifestyle category or categories you want to target'
    default:
      return
  }
}

const ShopperSelectionStep = ({ formik }) => {
  const { t } = useTranslation()

  const classes = useStyles()
  const drawerFormClasses = useDrawerFormStyles()

  const { values, setFieldValue, errors, touched } = formik

  const {
    [BRAND_SHOPPERS]: brandShoppersError,
    [CATEGORY_SHOPPERS]: categoryShoppersError,
    [LIFESTYLE_CATEGORIES]: lifestyleCategoriesError
  } = errors

  const {
    [BRAND_SHOPPERS]: brandShoppersTouched,
    [CATEGORY_SHOPPERS]: categoryShoppersTouched,
    [LIFESTYLE_CATEGORIES]: lifestyleCategoriesTouched
  } = touched

  const { [SHOPPER_TYPE_OPTION]: shopperTypeOption } = values

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create an audience')}</div>
      <div className={classes.stepTitle}>{t(getStepTitle(shopperTypeOption))}</div>
      <div className={classes.stepBody}>
        {shopperTypeOption === BRAND_SHOPPERS && (
          <section className={drawerFormClasses.section}>
            <h4 className={drawerFormClasses.sectionTitle}>{t('Brands')}</h4>
            <MultiSelectBox
              placeholder="Add a Brand"
              setFieldValue={setFieldValue}
              value={values[BRAND_SHOPPERS]}
              name={BRAND_SHOPPERS}
              options={brandShoppersList}
            />
            {brandShoppersError && brandShoppersTouched && <ErrorMessage error={brandShoppersError} />}
          </section>
        )}
        {shopperTypeOption === CATEGORY_SHOPPERS && (
          <section className={drawerFormClasses.section}>
            <h4 className={drawerFormClasses.sectionTitle}>{t('Shopper Categories')}</h4>
            <MultiSelectBox
              placeholder="Add a Shopper Category"
              setFieldValue={setFieldValue}
              value={values[CATEGORY_SHOPPERS]}
              name={CATEGORY_SHOPPERS}
              options={categoryShoppersList}
            />
            {categoryShoppersError && categoryShoppersTouched && <ErrorMessage error={categoryShoppersError} />}
          </section>
        )}
        {shopperTypeOption === LIFESTYLE_CATEGORIES && (
          <section className={drawerFormClasses.section}>
            <h4 className={drawerFormClasses.sectionTitle}>{t('Lifestyle Categories')}</h4>
            <MultiSelectBox
              placeholder="Add a Lifestyle Category"
              setFieldValue={setFieldValue}
              value={values[LIFESTYLE_CATEGORIES]}
              name={LIFESTYLE_CATEGORIES}
              options={lifestyleShoppersList}
            />
            {lifestyleCategoriesError && lifestyleCategoriesTouched && (
              <ErrorMessage error={lifestyleCategoriesError} />
            )}
          </section>
        )}
      </div>
    </>
  )
}

export default ShopperSelectionStep
