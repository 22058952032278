import { CHIP_COLORS } from '../../../../constants/other'
import { differenceInDays } from 'date-fns'
import { formatDateFullYear } from '../../../../constants/dates'

export const SORT_CREATED = 'created'

export const getChipColorByStatus = status => {
  switch (status) {
    case 'active':
      return CHIP_COLORS.green
    case 'expired':
      return CHIP_COLORS.red
    default:
      return CHIP_COLORS.grey
  }
}

export const getDaysDiffLabel = date => {
  const daysDiff = differenceInDays(new Date(), new Date(date))
  if (daysDiff === 0) {
    return 'Today'
  } else if (daysDiff <= 7) {
    if (daysDiff === 1) {
      return '1 day ago'
    } else {
      return `${daysDiff} days ago`
    }
  } else {
    return formatDateFullYear(date)
  }
}
