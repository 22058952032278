import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import { usePurifiedFormik } from '../../../../../../../hooks/formHooks/usePurifiedFormik'

import Form from '../../../../../../../components/Form'
import Field from '../../../../../../../components/Form/Field'

import {
  createFacebookPageAccessRequestErrorSelector,
  createFacebookPageAccessRequestIsLoadingSelector,
  createFacebookPageAccessRequestWasCreatedSelector
} from '../../../../../../../modules/selectors/socialAccounts'
import {
  selectedFacebookAdAccountIdSelector,
  selectedSelfAccountDataSelector
} from '../../../../../../../modules/selectors/app'
import {
  clearCreateFacebookPageAccessRequest,
  createFacebookPageAccessRequest
} from '../../../../../../../modules/actions/socialAccounts'

import { REQUEST_FACEBOOK_PAGE_ACCESS } from '../../../../../../../constants/forms'

import { FACEBOOK_PAGE_ID } from './fields'
import { initialValues, validationSchema } from '../../AddFacebookPage/AddFacebookPageForm/fields'

import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

const RequestPageAccessForm = () => {
  const drawerFormClasses = useDrawerFormStyles()

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const pageAccessRequestWasCreated = useSelector(createFacebookPageAccessRequestWasCreatedSelector)

  const { facebook_bm_id: facebookBmId } = useSelector(selectedSelfAccountDataSelector)

  const facebookAdAccountId = useSelector(selectedFacebookAdAccountIdSelector)

  const onSubmit = useCallback(
    values => {
      dispatch(
        createFacebookPageAccessRequest({
          ...values,
          account: facebookAdAccountId,
          facebook_bm_id: facebookBmId
        })
      )
    },
    [dispatch, facebookAdAccountId, facebookBmId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const clearRequestPageAccessHandler = useCallback(() => {
    dispatch(clearCreateFacebookPageAccessRequest())
  }, [dispatch])

  return (
    <Form
      formName={REQUEST_FACEBOOK_PAGE_ACCESS}
      formik={purifiedFormik}
      successSubmit={pageAccessRequestWasCreated}
      errorSelector={createFacebookPageAccessRequestErrorSelector}
      isLoadingSelector={createFacebookPageAccessRequestIsLoadingSelector}
      clearHandler={clearRequestPageAccessHandler}
      submitText="Request Access"
    >
      <section className={drawerFormClasses.section}>
        <h3 className={drawerFormClasses.sectionTitle}>{t('Facebook page ID')}</h3>
        <p>{t('Enter the ID of the Facebook page to request access to')}</p>
        <Field placeholder="Facebook page ID" formik={formik} name={FACEBOOK_PAGE_ID} />
      </section>
    </Form>
  )
}

export default RequestPageAccessForm
