import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../../components/Form'
import DiscountPresetFormContent from '../../DiscountPresetFormContent'

import { transformValuesToBE } from '../../formatters'

import { clearCreateDiscountPreset, createDiscountPreset } from '../../../../../../modules/actions/discounts'
import { selectedSelfAccountDataSelector } from '../../../../../../modules/selectors/app'

import {
  createDiscountPresetErrorSelector,
  createDiscountPresetIsLoadingSelector,
  createDiscountPresetWasCreatedSelector
} from '../../../../../../modules/selectors/discounts'

import { DISCOUNT_PRESET_CREATE } from '../../../../../../constants/forms'
import { validationSchema } from '../../validation'
import { initialValues } from './fields'

function DiscountPresetCreateForm() {
  const dispatch = useDispatch()

  const selectedSelfAccountData = useSelector(selectedSelfAccountDataSelector)
  const controllerId = selectedSelfAccountData.controller
  const discountPresetWasCreated = useSelector(createDiscountPresetWasCreatedSelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values)
      dispatch(
        createDiscountPreset({
          controller: controllerId,
          ...transformedData
        })
      )
    },
    [dispatch, controllerId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const clearCreateItemHandler = useCallback(() => dispatch(clearCreateDiscountPreset()), [dispatch])

  return (
    <Form
      formik={formik}
      formName={DISCOUNT_PRESET_CREATE}
      // processing
      successSubmit={discountPresetWasCreated}
      errorSelector={createDiscountPresetErrorSelector}
      isLoadingSelector={createDiscountPresetIsLoadingSelector}
      // after form submit
      clearHandler={clearCreateItemHandler}
    >
      <DiscountPresetFormContent formik={formik} />
    </Form>
  )
}

export default DiscountPresetCreateForm
