import React from 'react'
import PropTypes from 'prop-types'

import FieldRow from '../../../../features/components/Form/FieldsSection/FieldRow'
import Tabs from '../../../../features/components/Tabs'
import LocationsTabContent from './LocationsTabContent'
import LocationsListTabContent from './LocationsListTabContent'

import { locationsTabs, LOCATIONS_SELECTED_TAB, LOCATIONS_TAB } from '../fields'

import useStyles from './styles'

const LocationsRedesign = ({ formik, TargetByRegionComponent, rowTitle = '', rowDescription = '' }) => {
  const classes = useStyles()

  const { values, setFieldValue } = formik

  return (
    <FieldRow title={rowTitle} description={rowDescription}>
      <Tabs
        options={locationsTabs}
        selectedValue={values[LOCATIONS_SELECTED_TAB]}
        onSelectValue={value => setFieldValue(LOCATIONS_SELECTED_TAB, value)}
        className={classes.locationsTabs}
      />
      {values[LOCATIONS_SELECTED_TAB] === LOCATIONS_TAB ? (
        <LocationsTabContent
          checkboxClassName={classes.checkbox}
          formik={formik}
          TargetByRegionComponent={TargetByRegionComponent}
        />
      ) : (
        <LocationsListTabContent formik={formik} />
      )}
    </FieldRow>
  )
}

LocationsRedesign.propTypes = {
  formik: PropTypes.object.isRequired,
  TargetByRegionComponent: PropTypes.node.isRequired,
  rowTitle: PropTypes.string,
  rowDescription: PropTypes.string
}

export default LocationsRedesign
