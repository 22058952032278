import * as Yup from 'yup'

import { AD_TEXT } from '../../../fields'
import { tikTokAdTextValidation } from '../../../validation'

export const getInitialValues = adData => ({
  [AD_TEXT]: adData[AD_TEXT] || ''
})

export const validationSchema = Yup.object({
  [AD_TEXT]: tikTokAdTextValidation
})
