import { createStyles } from '../../../../../styles/helpers'

import { tabletDown } from '../../../../../styles/const/breakpoints'

export default createStyles({
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 8
  },

  [`@media screen and (${tabletDown})`]: {
    buttonsWrapper: {
      width: '100%'
    }
  }
})
