import React from 'react'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import MediaCategoryCreateForm from './MediaCategoryCreateForm'

import { MEDIA_CATEGORY_CREATE } from '../../../../constants/forms'

function MediaCategoryCreate() {
  return (
    <FormDrawerWrapper formName={MEDIA_CATEGORY_CREATE} title="Create a category" showOpenButton={false}>
      <MediaCategoryCreateForm />
    </FormDrawerWrapper>
  )
}

export default MediaCategoryCreate
