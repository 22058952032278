import { createStyles } from '../../styles/helpers'

import { transitionShort } from '../../styles/const/common'
import { lightGrey, mediumGrey, red } from '../../styles/const/colors'

const useStyles = createStyles(theme => ({
  minimizeSection: {
    border: '1px solid',
    borderRadius: theme.bigBorderRadius,
    borderColor: ({ isFocused }) => (isFocused ? theme.brandPrimary : mediumGrey),

    '&:not(:last-child)': {
      marginBottom: 16
    }
  },
  hasError: {
    borderColor: `${red} !important`
  },
  header: {
    position: 'relative',
    cursor: 'pointer',
    padding: 16,
    background: lightGrey,
    // helps to have borderRadius of the wrapper set correctly without cut off corners
    borderRadius: 'inherit'
  },
  toggleButtonWrapper: {
    display: 'flex',
    padding: 13,
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)'
  },
  toggleButton: {
    transform: ({ isOpened }) => (isOpened ? `rotate(0deg)` : `rotate(-90deg)`),
    border: 0,
    fontSize: 0,
    padding: 0,
    cursor: 'pointer',
    outline: 'none',
    backgroundColor: 'transparent',
    transition: transitionShort,

    '& svg': {
      color: theme.brandPrimary,
      width: 12,
      height: 'auto'
    }
  },
  body: {
    padding: 16,
    borderTop: `1px solid ${mediumGrey}`
  }
}))

export default useStyles
