import React from 'react'

import Field from '../../../../../../../components/Form/Field'
import FieldLabelWrapper from '../../../../../../../features/components/Form/FieldLabelWrapper'

import { MIN_AGE_YEARS, MAX_AGE_YEARS } from '../../../../../../ReusableFormFields/LineItemForms/fields'
import { ageYearsList } from '../../../../../../../constants/selectLists/ageYearsList'

import useAudienceSectionStyles from '../styles'

const AgeContent = ({ formik }) => {
  const audienceClasses = useAudienceSectionStyles()

  const { values } = formik

  const minYearsList = ageYearsList.filter(year => year.value < values[MAX_AGE_YEARS])
  const maxYearsList = ageYearsList.filter(year => year.value > values[MIN_AGE_YEARS])

  if (values[MIN_AGE_YEARS] < 18) {
    // in some cases we can get age that less then minimum supported "18" by our platform
    minYearsList.unshift({
      value: values[MIN_AGE_YEARS],
      label: `${values[MIN_AGE_YEARS]}`
    })
  }

  if (values[MAX_AGE_YEARS] < 18) {
    maxYearsList.unshift({
      value: values[MAX_AGE_YEARS],
      label: `${values[MAX_AGE_YEARS]}`
    })
  }

  return (
    <FieldLabelWrapper label="Age">
      <div className={audienceClasses.sectionAgePickers}>
        <Field
          formik={formik}
          name={MIN_AGE_YEARS}
          placeholder="Minimum Age"
          options={minYearsList}
          isSearchable={false}
        />
        <Field
          formik={formik}
          name={MAX_AGE_YEARS}
          placeholder="Maximum Age"
          options={maxYearsList}
          isSearchable={false}
        />
      </div>
    </FieldLabelWrapper>
  )
}

export default AgeContent
