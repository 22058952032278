import { addDays, differenceInDays, eachMonthOfInterval, endOfMonth, format } from 'date-fns'

import { DATES_FORMAT_MONTH_YEAR } from '../../../../constants/dates'

export const getMonthTitleCols = (startDate, endDate) => {
  const months = eachMonthOfInterval({ start: startDate, end: endDate })

  const result = months.map((monthStart, index) => {
    const monthEnd = endOfMonth(monthStart)
    const start = index === 0 ? startDate : monthStart
    const end = endDate < monthEnd ? endDate : monthEnd

    const monthDuration = differenceInDays(end, start) + 1
    return {
      text: format(start, DATES_FORMAT_MONTH_YEAR),
      colSpan: monthDuration
    }
  })

  return result
}

export const getSubtitleDaysColumns = (startDate, endDate) => {
  const result = []
  let date = startDate

  while (date <= endDate) {
    result.push({
      text: `${date.getDate()}`.padStart(2, '0'),
      // get first letter of the day
      subText: format(date, 'E')[0]
    })
    date = addDays(date, 1)
  }

  return result
}
