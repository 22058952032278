import React from 'react'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import FrequencyFields from '../../../../../../ReusableFormFields/FrequencyFields'

import {
  FREQUENCY_DAYS,
  FREQUENCY_IMPRESSIONS,
  FREQUENCY_OPTION
} from '../../../../../../ReusableFormFields/FrequencyFields/fields'

const stepFields = [FREQUENCY_OPTION, FREQUENCY_IMPRESSIONS, FREQUENCY_DAYS]

const FrequencyStep = ({ formik, handleStepChange }) => {
  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  return (
    <Step
      formTitle="Create a TikTok Ad Group"
      stepTitle="How many times should each person be shown the ads?"
      handleContinue={handleContinue}
    >
      <FrequencyFields formik={formik} showNoFrequencyOption={false} />
    </Step>
  )
}

export default FrequencyStep
