import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'

import PropTypes from 'prop-types'
import DrawerHeadline from '../../../../../../../components/Drawer/DrawerHeadline'
import Form from '../../../../../../../components/Form'
import ProductOrPageSelector from '../../../../../ReusableFormFIelds/ProductOrPageSelector'

import { usePurifiedFormik } from '../../../../../../../hooks/formHooks/usePurifiedFormik'

import { setMediaOrderUploadCreative } from '../../../../../../../modules/actions/mediaOrders'

import { initialValues, validationSchema } from './fields'
import { PRODUCT_DATA } from '../../../../../../ReusableFormFields/ProductFields/fields'
import { PRODUCT_FORM } from '../index'

import {
  BRAND_CATEGORY_PAGE_DATA,
  PRODUCT_OPTION,
  PRODUCT_OPTION_BRAND_CATEGORY_PAGES
} from '../../../../../../ReusableFormFields/ProductsAndPagesFields/fields'

import { AD_LINK, SINGLE_MEDIA_ITEM } from '../../../../../AdForms/fields'

import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

// as we don't create anything on BE on this form, we just imitate form selectors
const mockErrorSelector = () => {}
const mockIsLoadingSelector = () => {}

const ProductForm = ({ onSuccessSubmit }) => {
  const drawerFormClasses = useDrawerFormStyles()

  const [successSubmit, setSuccessSubmit] = useState(false)

  const dispatch = useDispatch()

  const onSubmit = values => {
    // audience form has two radio options to select brand/category or product pages
    // however, we treat them as the same entity later on AdProductForm
    const selectedPageData =
      values[PRODUCT_OPTION] === PRODUCT_OPTION_BRAND_CATEGORY_PAGES
        ? values[BRAND_CATEGORY_PAGE_DATA]
        : values[PRODUCT_DATA]

    // this form doesn't create new item, just saves catalogueProduct and pixelId to media order state
    dispatch(
      setMediaOrderUploadCreative({
        catalogueProduct: selectedPageData
      })
    )

    setSuccessSubmit(true)
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const { values, setValues } = formik

  const onProductOrPageDataSelect = useCallback(
    (productSelectionValues, productData) => {
      const updatedValues = {
        ...values,
        ...productSelectionValues,
        [SINGLE_MEDIA_ITEM]: {
          ...values[SINGLE_MEDIA_ITEM],
          [AD_LINK]: productData.link
        }
      }

      setValues(updatedValues)
    },
    [values, setValues]
  )

  return (
    <div className={drawerFormClasses.formContainer}>
      <DrawerHeadline
        activeStepNumber={3}
        customStepsLength={5}
        title="Select a page or product to click through to"
        description="When a shopper clicks on your ad, they will be taken to your selected brand or product page"
      />
      <Form
        formName={PRODUCT_FORM}
        formik={purifiedFormik}
        submitText="Save and continue"
        successSubmit={successSubmit}
        onSuccessSubmit={onSuccessSubmit}
        errorSelector={mockErrorSelector}
        isLoadingSelector={mockIsLoadingSelector}
      >
        <ProductOrPageSelector formik={formik} onProductOrPageDataSelect={onProductOrPageDataSelect} />
      </Form>
    </div>
  )
}

ProductForm.propTypes = {
  onSuccessSubmit: PropTypes.func.isRequired
}

export default ProductForm
