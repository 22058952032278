import { PLACEMENT_POSITIONS } from '../../../ReusableFormFields/LineItemForms/fields'

import { formatOptionsList } from '../../../../features/formatters'
import { getSelectedPlacementPositions } from '../LineItemFacebookCreate/LineItemFacebookCreateForm/formatters'
import { getPlacementsList } from '../../../ReusableFormFields/LineItemForms/facebookLineItemPlacementsHelpers'

// BUDGET
export const LINE_ITEM_BUDGET = 'line_item_budget'
export const CAMPAIGN_BUDGET = 'campaign_budget'

// SPEND
export const SPEND_LIFETIME = 'spend_lifetime'
export const SPEND_DAILY = 'spend_daily'

// DATES
export const LINE_ITEM_DATES = 'line_item_dates'

// GEO_TARGETING
export const GEO_TARGETING = 'geo_targeting'
export const COUNTRY_OR_REGION = 'country_or_region'
export const COUNTRY = 'country'
export const CITY = 'city'
export const REGION = 'region'
export const ZIP = 'zip'
export const COUNTRY_GROUP = 'country_group'
export const GEO_MARKET = 'geo_market'
export const PLACE = 'place'
export const MEDIUM_GEO_AREA = 'medium_geo_area'
export const LARGE_GEO_AREA = 'large_geo_area'
export const SMALL_GEO_AREA = 'small_geo_area'
export const SUBCITY = 'subcity'
export const NEIGHBORHOOD = 'neighborhood'
export const SUBNEIGHBORHOOD = 'subneighborhood'
export const METRO_AREA = 'metro_area'
export const CUSTOM_LOCATIONS = 'custom_locations'

// AUDIENCE
export const LINE_ITEM_AUDIENCE = 'lime_item_audience'

// LANGUAGES
export const LINE_ITEM_LANGUAGES = 'line_item_languages'

// CUSTOM AUDIENCES
export const LINE_ITEM_CUSTOM_AUDIENCES = 'line_item_custom_audiences'

// PLACEMENT
export const LINE_ITEM_PLACEMENT = 'line_item_placement'

export const formatList = list =>
  formatOptionsList({
    list: list,
    valueName: 'id',
    labelName: 'name'
  })

export const transformValuesToPlacementBE = (values, campaignObjective) => {
  const selectedPlacements = getSelectedPlacementPositions(values)

  const placements = getPlacementsList(campaignObjective)
  const activePlacements = placements.filter(({ disabled }) => disabled === false)

  const isAllActivePlacementsChecked = activePlacements.every(placement => !!values[placement.name])

  return {
    [PLACEMENT_POSITIONS]: isAllActivePlacementsChecked ? [] : selectedPlacements
  }
}
