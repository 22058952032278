import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import ButtonIcon from '../../../Buttons/ButtonIcon'

import { ReactComponent as PencilIcon } from '../../../../../assets/icons/pencil.svg'
import { ReactComponent as MinimizeIcon } from '../../../../../assets/icons/minimize.svg'
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/trash.svg'

import useDrawerFormStyles from '../../../../../styles/common/drawerForms'
import useStyles from './styles'

// this component allows to control visibility by Preview and edit Form/Fields
const EditSection = ({ title, children, isSectionOpen, onDelete, toggleSectionVisibility, preview }) => {
  const { t } = useTranslation()

  const classes = useStyles()
  const drawerFormClasses = useDrawerFormStyles()

  return (
    <section className={drawerFormClasses.section}>
      <h3 className={classnames(drawerFormClasses.sectionTitle, drawerFormClasses.sectionPreviewTitle)}>{t(title)}</h3>
      {onDelete && <ButtonIcon className={drawerFormClasses.deleteIcon} Icon={TrashIcon} onClick={onDelete} />}
      {/* to avoid unnecessary endpoints call, and also for a better performance - SectionForm within the formik and
       other logic is only renders and invokes when it's opened */}
      {isSectionOpen ? (
        <>
          <ButtonIcon className={drawerFormClasses.sectionIcon} Icon={MinimizeIcon} onClick={toggleSectionVisibility} />
          <div className={classes.editSectionContent}>{children}</div>
        </>
      ) : (
        <>
          <ButtonIcon className={drawerFormClasses.sectionIcon} Icon={PencilIcon} onClick={toggleSectionVisibility} />
          <div className={drawerFormClasses.sectionPreview}>{preview}</div>
        </>
      )}
    </section>
  )
}

EditSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  isSectionOpen: PropTypes.bool.isRequired,
  toggleSectionVisibility: PropTypes.func.isRequired,
  preview: PropTypes.node,
  onDelete: PropTypes.func
}

export default EditSection
