import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import InternalLink from '../../../../../components/InternalLink'

import { getUrlDomain } from '../../../../../helpers/url'

import useStyles from './styles'

function Banner({ banner }) {
  const { text, links, color } = banner
  const { t } = useTranslation()

  const classes = useStyles({ color })

  const relativeLinks = useMemo(
    () =>
      links.map(link => {
        const domain = getUrlDomain(link.link)
        const relativePath = link.link.split(domain)[1]
        return {
          link: relativePath,
          text: link.text
        }
      }),
    [links]
  )

  return (
    <div className={classes.banner}>
      <p className={classes.bannerText}>{t(text)}</p>
      {relativeLinks.map(link => (
        <InternalLink key={link.link} className={classes.bannerLink} to={link.link}>
          {t(link.text)}
        </InternalLink>
      ))}
    </div>
  )
}

Banner.propTypes = {
  banner: PropTypes.object
}

export default Banner
