import { useCallback, useMemo, useState } from 'react'
import { useTheme } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { addMonths, endOfMonth, startOfMonth } from 'date-fns'

import { getInventory } from '../../../../../../../../../../../../../modules/actions/mediaOrdersProducts'
import { requestedInventoryDateRangesSelector } from '../../../../../../../../../../../../../modules/selectors/mediaOrdersProducts'

import { formatDateToBE } from '../../../../../../../../../../../../../constants/dates'
import { getIsMatchDateRange } from './helpers'

export default function useDateRange() {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)

  const rangeColors = useMemo(() => [theme.brandPrimary], [theme.brandPrimary])
  const rangeSelectedTextColor = useMemo(
    () => theme.brandPrimaryTextContrastColor,
    [theme.brandPrimaryTextContrastColor]
  )

  return useMemo(
    () => ({
      isOpen,
      setIsOpen,
      rangeColors,
      rangeSelectedTextColor
    }),
    [isOpen, setIsOpen, rangeColors, rangeSelectedTextColor]
  )
}

export const useCheckAndFetchInventoryByDateRange = ({ requestParams }) => {
  const dispatch = useDispatch()
  const requestedInventoryDateRanges = useSelector(requestedInventoryDateRangesSelector)

  const handleFetchInventory = useCallback(
    ({ dateFrom, dateTo }) => {
      dispatch(
        getInventory({
          date_from: dateFrom,
          date_to: dateTo,
          ...requestParams
        })
      )
    },
    [dispatch, requestParams]
  )

  const fetchPreviousMonthHandler = useCallback(
    dateRange => {
      const dateFrom = formatDateToBE(startOfMonth(addMonths(new Date(dateRange), -1)))
      const dateTo = formatDateToBE(endOfMonth(new Date(dateFrom)))

      handleFetchInventory({ dateFrom, dateTo })
    },
    [handleFetchInventory]
  )

  const fetchNextMonthHandler = useCallback(
    dateRange => {
      const dateFrom = formatDateToBE(startOfMonth(addMonths(new Date(dateRange), 1)))
      const dateTo = formatDateToBE(endOfMonth(new Date(dateFrom)))

      handleFetchInventory({ dateFrom, dateTo })
    },
    [handleFetchInventory]
  )

  return useCallback(
    (dateRange, allowToFetchPreviousPeriods) => {
      const shouldFetchNextMonth = !getIsMatchDateRange(requestedInventoryDateRanges, addMonths(new Date(dateRange), 1))
      const shouldFetchPreviousMonth = !getIsMatchDateRange(
        requestedInventoryDateRanges,
        addMonths(new Date(dateRange), -1)
      )

      if (shouldFetchNextMonth) {
        fetchNextMonthHandler(dateRange)
      }

      if (shouldFetchPreviousMonth && allowToFetchPreviousPeriods) {
        fetchPreviousMonthHandler(dateRange)
      }
    },
    [requestedInventoryDateRanges, fetchNextMonthHandler, fetchPreviousMonthHandler]
  )
}
