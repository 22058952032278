import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import StackedBarGraph from '../Graphs/StackedBarGraph'

import { formatCurrency, formatNumber } from '../../../helpers/numbers'
import { getUnsoldQuantity, getUnsoldValue } from './helpers'

import { selectedControllerDataSelector } from '../../../modules/selectors/app'

import useStyles from './styles'

const InventoryGraph = ({
  className,
  DatesSelector,
  sortedInventoryLimitedByDateRange,
  formattedLabels,
  graphColor,
  maxYValue
}) => {
  const classes = useStyles()

  const selectedSelfAccountController = useSelector(selectedControllerDataSelector)

  const controllerCurrencySymbol = selectedSelfAccountController && selectedSelfAccountController['currency_symbol']

  const primaryData = useMemo(
    () => sortedInventoryLimitedByDateRange.map(({ booked_quantity: bookedQuantity }) => bookedQuantity),
    [sortedInventoryLimitedByDateRange]
  )

  const secondaryData = useMemo(
    () =>
      sortedInventoryLimitedByDateRange.map(({ quantity, booked_quantity: bookedQuantity }) =>
        getUnsoldQuantity(quantity, bookedQuantity)
      ),
    [sortedInventoryLimitedByDateRange]
  )

  const percentageData = useMemo(() => {
    return sortedInventoryLimitedByDateRange.map(item => {
      const percentage = (item['booked_quantity'] / item['quantity']) * 100
      return percentage.toFixed(2)
    })
  }, [sortedInventoryLimitedByDateRange])

  const tooltipCallbackOptions = useMemo(
    () => ({
      label: ({ dataIndex, datasetIndex }) => {
        const currentItem = sortedInventoryLimitedByDateRange[dataIndex]

        if (datasetIndex === 0) {
          const bookedValue = formatCurrency(
            currentItem['booked_value'],
            { min: 2, max: 2 },
            { symbol: controllerCurrencySymbol }
          )

          return `Booked value: ${bookedValue}`
        } else {
          const unsoldValue = formatCurrency(
            getUnsoldValue(currentItem['unsold_value'], currentItem['average_discount']),
            { min: 2, max: 2 },
            { symbol: controllerCurrencySymbol }
          )

          return `Unsold value: ${unsoldValue}`
        }
      },
      afterLabel: ({ dataIndex, datasetIndex }) => {
        const currentItem = sortedInventoryLimitedByDateRange[dataIndex]

        if (datasetIndex === 0) {
          const bookedQuantity = formatNumber(currentItem['booked_quantity'])

          return `Booked quantity: ${bookedQuantity}`
        } else {
          const unsoldQuantity = formatNumber(
            getUnsoldQuantity(currentItem['quantity'], currentItem['booked_quantity'])
          )

          return `Unsold quantity: ${unsoldQuantity}`
        }
      }
    }),
    [sortedInventoryLimitedByDateRange, controllerCurrencySymbol]
  )

  return (
    <div className={classnames(classes.graphSection, className)}>
      {DatesSelector && DatesSelector}
      <StackedBarGraph
        labels={formattedLabels}
        primaryData={primaryData}
        secondaryData={secondaryData}
        percentageData={percentageData}
        tooltipCallbackOptions={tooltipCallbackOptions}
        graphColor={graphColor}
        maxYValue={maxYValue}
      />
    </div>
  )
}

InventoryGraph.propTypes = {
  className: PropTypes.string,
  DatesSelector: PropTypes.node,
  sortedInventoryLimitedByDateRange: PropTypes.array,
  formattedLabels: PropTypes.array,
  graphColor: PropTypes.string,
  maxYValue: PropTypes.number
}

export default InventoryGraph
