import React from 'react'

import useStyles from './styles'

const TableWrapper = ({ title, Filters, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.tableWrapper}>
      <div className={classes.filtersContainer}>
        {title && <h3>{title}</h3>}
        {Filters && Filters}
      </div>
      {children}
    </div>
  )
}

export default TableWrapper
