import update from 'immutability-helper'

import { updateItem } from '../../helpers/modules/reducerHelpers'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_COMBINED_CAMPAIGNS,
  GET_COMBINED_CAMPAIGNS_SUCCESS,
  GET_COMBINED_CAMPAIGNS_FAILURE,
  CLEAR_COMBINED_CAMPAIGNS,
  UPDATE_COMBINED_CAMPAIGN,
  GET_COMBINED_LINE_ITEMS,
  GET_COMBINED_LINE_ITEMS_SUCCESS,
  GET_COMBINED_LINE_ITEMS_FAILURE,
  CLEAR_COMBINED_LINE_ITEMS,
  GET_COMBINED_GRAPHS,
  GET_COMBINED_GRAPHS_SUCCESS,
  GET_COMBINED_GRAPHS_FAILURE,
  CLEAR_COMBINED_GRAPHS,
  GET_COMBINED_SEGMENTED_DATA,
  GET_COMBINED_SEGMENTED_DATA_SUCCESS,
  GET_COMBINED_SEGMENTED_DATA_FAILURE,
  CLEAR_COMBINED_SEGMENTED_DATA,
  GET_COMBINED_ADS,
  GET_COMBINED_ADS_SUCCESS,
  GET_COMBINED_ADS_FAILURE,
  CLEAR_COMBINED_ADS
} from '../actions/combinedData'

import { INITIAL_GET_REDUCER, INITIAL_GET_RESULTS_REDUCER } from '../../constants/reducer'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  combinedCampaigns: INITIAL_GET_REDUCER,
  combinedLineItems: INITIAL_GET_RESULTS_REDUCER,
  combinedGraphs: INITIAL_GET_RESULTS_REDUCER,
  combinedSegmentedData: INITIAL_GET_REDUCER,
  combinedAds: INITIAL_GET_RESULTS_REDUCER
}

export default function reports(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    // combined campaigns
    case GET_COMBINED_CAMPAIGNS:
      return update(state, {
        combinedCampaigns: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_COMBINED_CAMPAIGNS_SUCCESS:
      return update(state, {
        combinedCampaigns: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.campaignsData
          }
        }
      })
    case GET_COMBINED_CAMPAIGNS_FAILURE:
      return update(state, {
        combinedCampaigns: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_COMBINED_CAMPAIGNS:
      return update(state, {
        combinedCampaigns: {
          $set: initialState.combinedCampaigns
        }
      })

    // internal update combined campaign
    case UPDATE_COMBINED_CAMPAIGN:
      return update(state, {
        combinedCampaigns: {
          data: {
            campaigns: {
              $apply: items => updateItem(items, action.campaignData)
            }
          }
        }
      })

    // combined line items
    case GET_COMBINED_LINE_ITEMS:
      return update(state, {
        combinedLineItems: {
          $merge: {
            isLoading: true,
            // reset error when request new data, this is needed when using date range filter
            error: null
          }
        }
      })
    case GET_COMBINED_LINE_ITEMS_SUCCESS:
      return update(state, {
        combinedLineItems: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.lineItemsData
          }
        }
      })
    case GET_COMBINED_LINE_ITEMS_FAILURE:
      return update(state, {
        combinedLineItems: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_COMBINED_LINE_ITEMS:
      return update(state, {
        combinedLineItems: {
          $set: initialState.combinedLineItems
        }
      })

    // combined graphs
    case GET_COMBINED_GRAPHS:
      return update(state, {
        combinedGraphs: {
          $merge: {
            isLoading: true,
            // reset error fields when request new data, this is needed when using date range filter
            error: null
          }
        }
      })
    case GET_COMBINED_GRAPHS_SUCCESS:
      return update(state, {
        combinedGraphs: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.graphsData
          }
        }
      })
    case GET_COMBINED_GRAPHS_FAILURE:
      return update(state, {
        combinedGraphs: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_COMBINED_GRAPHS:
      return update(state, {
        combinedGraphs: {
          $set: initialState.combinedGraphs
        }
      })

    // combined segmented data
    case GET_COMBINED_SEGMENTED_DATA:
      return update(state, {
        combinedSegmentedData: {
          $merge: {
            isLoading: true,
            // reset some state fields when request new data, this is needed when using date range filter
            error: null,
            wasLoaded: false
          }
        }
      })
    case GET_COMBINED_SEGMENTED_DATA_SUCCESS:
      return update(state, {
        combinedSegmentedData: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.segmentedData
          }
        }
      })
    case GET_COMBINED_SEGMENTED_DATA_FAILURE:
      return update(state, {
        combinedSegmentedData: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_COMBINED_SEGMENTED_DATA:
      return update(state, {
        combinedSegmentedData: {
          $set: initialState.combinedSegmentedData
        }
      })

    // combined ads
    case GET_COMBINED_ADS:
      return update(state, {
        combinedAds: {
          $merge: {
            isLoading: true,
            // reset some state fields when request new data, this is needed when using date range filter
            error: null,
            wasLoaded: false
          }
        }
      })
    case GET_COMBINED_ADS_SUCCESS:
      return update(state, {
        combinedAds: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.adsData
          }
        }
      })
    case GET_COMBINED_ADS_FAILURE:
      return update(state, {
        combinedAds: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_COMBINED_ADS:
      return update(state, {
        combinedAds: {
          $set: initialState.combinedAds
        }
      })

    default:
      return state
  }
}
