import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import TableDataLoader from '../../../../../components/Table/TableDataLoader'
import ProductVariablesTableData from './ProductVariablesTableData'

import {
  getMediaProductVariablesErrorSelector,
  getMediaProductVariablesIsLoadingSelector,
  mediaProductVariablesSelector,
  mediaProductVariablesWasLoadedSelector
} from '../../../../../modules/selectors/mediaOrdersProductVariables'

import useCommonStyles from '../../../../../styles/common/listPage'

export const tableColumnsSize = {
  actions: 30
}

const ProductVariablesTable = () => {
  const { t } = useTranslation()
  const commonClasses = useCommonStyles()

  const mediaProductVariables = useSelector(mediaProductVariablesSelector)

  const productVariablesColumnsSchema = useMemo(
    () => [
      {
        fieldKey: 'name'
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no product variables')}</div>
        <br />
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <TableDataLoader
      itemsLength={mediaProductVariables.length}
      errorSelector={getMediaProductVariablesErrorSelector}
      wasLoadedSelector={mediaProductVariablesWasLoadedSelector}
      isLoadingSelector={getMediaProductVariablesIsLoadingSelector}
      skeletonProps={{ cols: productVariablesColumnsSchema }}
      noDataContent={noDataContent}
    >
      <ProductVariablesTableData />
    </TableDataLoader>
  )
}

export default ProductVariablesTable
