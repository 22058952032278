import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Loader from '../../../../components/Loaders/Loader'

import useStyles from './styles'

function ButtonIcon({ Icon, isLoading, className, iconClass, hasBorder, ...props }) {
  // wrapper for SVG icons with adding basic reusable styles and options
  const classes = useStyles(hasBorder)

  return (
    <button type="button" className={classnames(classes.btnIcon, className)} {...props}>
      {isLoading ? <Loader /> : <Icon className={classnames(classes.icon, iconClass)} />}
    </button>
  )
}

ButtonIcon.propTypes = {
  className: PropTypes.string,
  iconClass: PropTypes.string,
  type: PropTypes.string,
  Icon: PropTypes.object.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default ButtonIcon
