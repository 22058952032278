import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

import { getSelectedLanguage } from './helpers/other'

import { getLocalizationValue, setLanguageAttributes } from './helpers/language'
import { DEFAULT_LANGUAGE } from './constants/selectLists/languagesList'

const selectedLanguageCode = getSelectedLanguage()

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: selectedLanguageCode,
    fallbackLng: getLocalizationValue(DEFAULT_LANGUAGE),
    backend: {
      /* translation file path */
      loadPath: '/localizations/{{lng}}.json'
    },

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    // If we want to use symbol like ":" in translations we must to set this:
    // https://github.com/i18next/react-i18next/issues/387#issuecomment-361503984
    nsSeparator: false
  })

// set html element attributes and styles according to selected language
setLanguageAttributes(selectedLanguageCode)
export default i18n
