export const formatFileMaxSizeErrorMessage = message => {
  // Formats file size error messages to use KB or MB instead of bytes and rounds to the nearest whole number.
  // Ensures user-friendly display by replacing exact byte values with rounded kilobytes or megabytes.
  const byteMessageMatch = message.match(/(\d+)\sbytes/)
  if (byteMessageMatch) {
    const bytes = parseInt(byteMessageMatch[1], 10)
    // Convert to KB or MB and round to the nearest whole number
    const sizeInKB = bytes / 1024
    const sizeInMB = bytes / (1024 * 1024)

    let size
    if (bytes < 1024 * 1024) {
      size = `${Math.round(sizeInKB)} KB` // For KB
    } else {
      size = `${Math.round(sizeInMB)} MB` // For MB
    }

    return message.replace(/\d+\sbytes/, size)
  }
  return message
}
