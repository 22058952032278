import { createStyles } from '../../../../../../../../styles/helpers'

const useStyles = createStyles({
  manageAdWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 16
  }
})

export default useStyles
