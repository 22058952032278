import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from '../../../../components/Button'
import MultiFieldItem from '../MultiFieldItem'

import useStyles from './styles'

function MultiInputFieldBox({ name, setFieldValue, value, id, label }) {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')

  const hasValue = !!inputValue
  const classes = useStyles({ hasValue })

  const handleEnteredValueRemove = index => {
    value.splice(index, 1)
    setFieldValue(name, value)
  }

  const handleInputChange = event => {
    setInputValue(event.target.value)
  }

  const handleAddValueClick = event => {
    event.preventDefault()
    if (inputValue) {
      setFieldValue(name, [...value, inputValue])
      setInputValue('')
    }
  }

  const enteredValues = value.map((item, index) => (
    <MultiFieldItem value={index} key={index} label={item} deleteHandler={handleEnteredValueRemove} />
  ))

  return (
    <div>
      <div>{enteredValues}</div>
      <div className={classes.multiField}>
        <input
          id={id}
          className={classes.input}
          type="text"
          onInput={handleInputChange}
          value={inputValue}
          autoComplete={'off'}
        />
        <label className={classes.label} htmlFor={id}>
          {label}
        </label>
        <Button className={classes.button} onClick={handleAddValueClick}>
          {t('Add')}
        </Button>
      </div>
    </div>
  )
}

MultiInputFieldBox.propTypes = {
  name: PropTypes.string,
  setFieldValue: PropTypes.func,
  value: PropTypes.array,
  id: PropTypes.string,
  label: PropTypes.string
}

export default MultiInputFieldBox
