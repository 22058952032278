import * as Yup from 'yup'

export const PAGE_ID = 'page_id'

export const initialValues = {
  [PAGE_ID]: ''
}

export const validationSchema = Yup.object({
  [PAGE_ID]: Yup.string().required('Please select Facebook page')
})
