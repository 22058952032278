import { createStyles } from '../../../../../styles/helpers'

import { mediumGrey } from '../../../../../styles/const/colors'
import { transitionShort } from '../../../../../styles/const/common'

const useStyles = createStyles({
  link: {
    textDecoration: 'none',
    color: mediumGrey,
    transition: transitionShort,
    '&:hover': {
      fontWeight: 600
    }
  }
})

export default useStyles
