import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'

import ActionText from '../../../../../../../components/ActionText'
import ManageTotalPrice from './ManageTotalPrice'

import { CUSTOM_PRODUCT_DISCOUNT, initialProductValues, MEDIA_PRODUCTS } from '../../../fields'

import useStyles from './styles'

const FooterSummary = ({ formik, disableProductAdd, allowEdit, editProductId, handleTotalEdit }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { values, setFieldValue } = formik

  const handleAddNewProductRow = useCallback(() => {
    const maxOrder = values[MEDIA_PRODUCTS].reduce((acc, product) => (product.order > acc ? product.order : acc), 0)

    // If all discounts are the same, apply that discount to the new row. Otherwise, do not add any discount.
    const firstProductDiscount = values[MEDIA_PRODUCTS][0]?.[CUSTOM_PRODUCT_DISCOUNT]
    const allDiscountsSame = values[MEDIA_PRODUCTS].every(
      product => product[CUSTOM_PRODUCT_DISCOUNT] === firstProductDiscount
    )

    const newProduct = {
      id: uuidv4(), // create unique id for current row
      ...initialProductValues,
      order: maxOrder + 1,
      [CUSTOM_PRODUCT_DISCOUNT]: allDiscountsSame && values[MEDIA_PRODUCTS].length > 0 ? firstProductDiscount : 0
    }

    setFieldValue(MEDIA_PRODUCTS, values[MEDIA_PRODUCTS].concat(newProduct))
  }, [values, setFieldValue])

  const products = values[MEDIA_PRODUCTS]
  const hasAtLeastOneProduct = useMemo(() => {
    const hasProducts = products.length > 0
    return hasProducts && products.some(product => product.data)
  }, [products])

  return (
    <div className={classes.footerContent}>
      {allowEdit && (
        <ActionText
          onClick={handleAddNewProductRow}
          isDisabled={disableProductAdd}
          className={classes.newProductButton}
          isDark
        >
          {t('+ Add product')}
        </ActionText>
      )}
      {hasAtLeastOneProduct && (
        <ManageTotalPrice
          formik={formik}
          allowEdit={allowEdit}
          editProductId={editProductId}
          handleTotalEdit={handleTotalEdit}
        />
      )}
    </div>
  )
}

FooterSummary.propTypes = {
  formik: PropTypes.object,
  disableProductAdd: PropTypes.bool,
  editProductId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleTotalEdit: PropTypes.func,
  allowEdit: PropTypes.bool
}

export default FooterSummary
