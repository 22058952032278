import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getMembersListService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.membersList
  })
}

export function getInvitesListService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.invitesList
  })
}

export function createMemberService(memberData) {
  return madeRequest('POST', {
    data: memberData,
    url: ENDPOINTS.membersList
  })
}

export function createInviteService(inviteData) {
  return madeRequest('POST', {
    data: inviteData,
    url: ENDPOINTS.invitesList
  })
}

export function acceptInviteService(inviteData) {
  return madeRequest('POST', {
    data: inviteData,
    url: ENDPOINTS.inviteAccept
  })
}

export function updateMemberService(id, data) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.membersList}${id}/`
  })
}

export function updateMemberStatusService(id, status) {
  return madeRequest('POST', {
    url: `${ENDPOINTS.membersList}${id}/${status === 'active' ? 'deactivate/' : 'activate/'}`
  })
}

export function deleteInviteService(id) {
  return madeRequest('DELETE', {
    url: `${ENDPOINTS.invitesList}${id}/`
  })
}

export function resendInviteService(id) {
  return madeRequest('POST', {
    url: `${ENDPOINTS.invitesList}${id}/resend/`
  })
}
