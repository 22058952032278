import * as Yup from 'yup'

import { DESCRIPTIONS_LIST, RESOURCE_NAME } from '../../../../fields'
import { descriptionShape } from '../../../../validations'

export const getInitialValues = assets => {
  return {
    [DESCRIPTIONS_LIST]: assets.map(({ [RESOURCE_NAME]: assetId, asset_data }) => ({
      text: asset_data.text_asset.text,
      id: assetId
    }))
  }
}

export const validationSchema = Yup.object({
  [DESCRIPTIONS_LIST]: Yup.array().of(descriptionShape)
})
