import * as Yup from 'yup'
import { adTextMaxLength } from './fields'
import { CALL_TO_ACTION_OPTION, CALL_TO_ACTION_CUSTOM } from '../../ReusableFormFields/CallToActionFields/fields'
import { MEDIA_MIN_RATIO_9_16, MEDIA_RATIO } from '../../Facebook/AdForms/fields'

export const tikTokAdTextValidation = Yup.string()
  .max(adTextMaxLength, `Ad text should have maximum ${adTextMaxLength} characters`)
  .required('This field should not be empty')

export const tikTokAdCTAValidation = Yup.string().when(CALL_TO_ACTION_OPTION, {
  is: CALL_TO_ACTION_CUSTOM,
  then: () => Yup.string().required('Please select Call to action')
})

// Yup validation schema
export const tikTokVideoValidation = Yup.object({
  [MEDIA_RATIO]: Yup.number()
    .min(MEDIA_MIN_RATIO_9_16, 'Video ratio should be 9:16')
    .max(MEDIA_MIN_RATIO_9_16, 'Video ratio should be 9:16')
})
