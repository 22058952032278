import { createStyles } from '../../../../../../../../../styles/helpers'

export default createStyles({
  publicationsList: {
    paddingLeft: 0
  },
  publicationDetails: {
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '&:not(:last-child)': {
      marginBottom: '8px'
    }
  },
  periodLabel: {
    width: '140px'
  },
  priceField: {
    marginLeft: '50px',
    marginRight: '8px',
    width: '130px'
  },
  price: {
    marginLeft: 'auto'
  }
})
