import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import useStyles from './styles'

const FieldsSection = ({ children, title, rightColumn, hasMargin, isDark }) => {
  const classes = useStyles({ hasMargin, isDark })

  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.leftColumn}>
          <h3 className={classes.title}>{t(title)}</h3>
        </div>
        <div className={classes.rightColumn}>{rightColumn}</div>
      </div>

      <div className={classes.content}>{children}</div>
    </div>
  )
}

FieldsSection.propTypes = {
  title: PropTypes.string.isRequired,
  hasMargin: PropTypes.bool,
  isDark: PropTypes.bool
}

export default FieldsSection
