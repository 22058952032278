import { createStyles } from '../../../../styles/helpers'

export default createStyles({
  sliderPreview: {
    minHeight: 370,
    marginBottom: 32
  },
  slide: {
    minHeight: 360,
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 8px'
  },
  skeletonSlideWrapper: {
    width: '100%',
    '& > span': {
      width: '100%'
    }
  }
})
