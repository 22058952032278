import React, { useCallback, useContext, useMemo } from 'react'
import useStyles from './styles'
import PropTypes from 'prop-types'

import Checkbox from '../../../Form/Checkbox'

const AllRowsSelectionCell = ({ Context, isDisabled }) => {
  const classes = useStyles()

  const { rowIds, rowSelection, setRowSelection } = useContext(Context)

  const isAllSelected = useMemo(() => {
    return rowIds.every(id => rowSelection[id])
  }, [rowIds, rowSelection])

  const handleSelectRow = useCallback(() => {
    if (isAllSelected) {
      // if all rows are selected, deselect all
      setRowSelection({})
    } else {
      // if not all rows are selected, select all
      setRowSelection(rowIds.reduce((acc, id) => ({ ...acc, [id]: true }), {}))
    }
  }, [isAllSelected, rowIds, setRowSelection])

  return (
    <div className={classes.checkBoxContainer}>
      <Checkbox key={'all'} id={'all'} checked={isAllSelected} onCheck={handleSelectRow} disabled={isDisabled} />
    </div>
  )
}

AllRowsSelectionCell.propTypes = {
  Context: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool
}

export default AllRowsSelectionCell
