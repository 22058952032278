// This function is needed, because TikTok saves just IDs of the items, but we need full data for FE
// So we need to get full data for each item by ID and then also populate it with the name and value
export const populateItemsByIdsArray = ({
  idsArray = [],
  itemsList = [],
  itemIdFieldName = 'id',
  itemFallbackNameString
}) => {
  // If we don't have any IDs, return empty array
  if (!idsArray?.length) {
    return []
  }

  // Create a map of item IDs to their data for faster lookup.
  const itemsMap = new Map(itemsList.map(item => [String(item[itemIdFieldName]), item]))

  return idsArray.map(itemId => {
    const itemIdString = String(itemId)
    const itemData = itemsMap.get(itemIdString)

    // if we have item data, use its name/label, otherwise use fallback name
    const itemName = itemData?.name || `${itemFallbackNameString} (id -${itemIdString})`

    return {
      ...(itemData || {}),
      value: itemIdString,
      name: itemName,
      label: itemName
    }
  })
}
