import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import useStyles from './styles'

const CardHeader = ({ itemCategory, itemCategoryClassName, headerClassName, descriptionClassName, name, subtext }) => {
  const classes = useStyles()

  return (
    <div>
      {itemCategory && <p className={classnames(classes.itemCategory, itemCategoryClassName)}>{itemCategory}</p>}
      <h3 className={classnames(classes.header, headerClassName)}>{name}</h3>
      <p className={classnames(classes.description, descriptionClassName)}>{subtext}</p>
    </div>
  )
}

CardHeader.propTypes = {
  itemCategory: PropTypes.string,
  itemCategoryClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
  name: PropTypes.string,
  subtext: PropTypes.string
}

export default CardHeader
