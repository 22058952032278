import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { redirectTo } from '../../../../../helpers/url'

import { ROUTE_PARAMS, ROUTES } from '../../../../../constants/routes'
import { PROVIDER_TO_PLATFORMS } from '../../../../../constants/selectLists/platformList'
import { selectedSelfAccountSelector } from '../../../../../modules/selectors/app'

const useGetCampaignActionsDropdownOptions = () => {
  const selfAccountId = useSelector(selectedSelfAccountSelector)

  return useCallback(
    ({ ad_account_id: adAccountId, provider, id }) => [
      {
        text: 'Go to campaign',
        onClickHandler: () => {
          redirectTo(ROUTES.campaignsSummary, {
            [ROUTE_PARAMS.platform]: PROVIDER_TO_PLATFORMS[provider],
            [ROUTE_PARAMS.selfAccount]: selfAccountId,
            [ROUTE_PARAMS.adAccount]: adAccountId,
            [ROUTE_PARAMS.campaignId]: id
          })
        }
      }
    ],
    [selfAccountId]
  )
}

export default useGetCampaignActionsDropdownOptions
