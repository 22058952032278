import { createStyles } from '../../../../styles/helpers'
import { lightGrey } from '../../../../styles/const/colors'
import { tabletDown } from '../../../../styles/const/breakpoints'

const useStyles = createStyles({
  quotationReviewDrawerContent: {
    backgroundColor: lightGrey
  },
  section: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  description: {
    fontSize: 12,
    marginTop: [8, '!important']
  },
  [`@media screen and (${tabletDown})`]: {
    section: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 20
    }
  }
})

export default useStyles
