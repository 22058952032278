import update from 'immutability-helper'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  CLEAR_BATCH_INFO,
  GET_BATCH_INFO,
  GET_BATCH_INFO_FAILURE,
  GET_BATCH_INFO_SUCCESS
} from '../actions/batchInfo'
import { INITIAL_GET_RESULTS_REDUCER } from '../../constants/reducer'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  batchInfo: INITIAL_GET_RESULTS_REDUCER
}

export default function batchInfo(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    case GET_BATCH_INFO:
      return update(state, {
        batchInfo: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_BATCH_INFO_SUCCESS:
      return update(state, {
        batchInfo: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.batchInfoData
          }
        }
      })
    case GET_BATCH_INFO_FAILURE:
      return update(state, {
        batchInfo: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_BATCH_INFO:
      return update(state, {
        batchInfo: {
          $set: initialState.batchInfo
        }
      })
    default:
      return state
  }
}
