import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import RadioBlock from '../../../../../components/Form/RadioBlock'
import Button from '../../../../../components/Button'
import Field from '../../../../../components/Form/Field'

import {
  AIRLINE,
  BASED_ON_CHOICE_DURATION,
  CUSTOM_DAYS,
  CUSTOM_DURATION,
  DURATION_OPTION,
  FACEBOOK_USERS,
  INSTAGRAM_USERS,
  ORIGIN_OPTION,
  THIRTY_DAYS_DURATION,
  YOUR_WEBSITE
} from '../fields'

import useStyles from '../../../../../styles/common/stepForms'

const stepFields = [CUSTOM_DAYS]

const DurationStep = ({ formik, handleStepChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [title, setTitle] = useState(t('How many days should people be in your audience after visiting your website?'))

  const { values, setFieldValue } = formik

  const originOption = values[ORIGIN_OPTION]

  useEffect(() => {
    switch (originOption) {
      case YOUR_WEBSITE:
        setTitle(t('How many days should people be in your audience after visiting your website?'))
        break
      case FACEBOOK_USERS:
        setTitle(t('How many days should people be in your audience after interacting with your Facebook page?'))
        break
      case INSTAGRAM_USERS:
        setTitle(t('How many days should people be in your audience after interacting with your Instagram account?'))
        break
      case AIRLINE:
        setTitle(t('How recently should the search or booking have been made?'))
        break
      default:
        setTitle(t('How many days should people be in your audience after visiting your website?'))
    }
  }, [originOption, t])

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create an audience')}</div>
      <div className={classes.stepTitle}>{t(title)}</div>
      <div className={classes.stepBody}>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_30_days_duration"
          name={DURATION_OPTION}
          value={THIRTY_DAYS_DURATION}
          selectedValue={values[DURATION_OPTION]}
          label={t('30 days')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_180_days_duration"
          name={DURATION_OPTION}
          value={BASED_ON_CHOICE_DURATION}
          selectedValue={values[DURATION_OPTION]}
          label={originOption === YOUR_WEBSITE ? t('180 days') : t('365 days')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_custom_days_duration"
          name={DURATION_OPTION}
          value={CUSTOM_DURATION}
          selectedValue={values[DURATION_OPTION]}
          label={t('Custom')}
        >
          <Field formik={formik} name={CUSTOM_DAYS} placeholder="Days" autoComplete="off" />
        </RadioBlock>
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" className="dark" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default DurationStep
