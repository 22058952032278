import React from 'react'
import { useTranslation } from 'react-i18next'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import MediaPackageImageUpload from './MediaPackageImageUpload'
import FieldsSection from '../../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'

function ImageStep({ formik }) {
  const { t } = useTranslation()

  return (
    <Step
      stepTitle="Upload an image to represent this package"
      stepDescription="Images should be transparent PNG files that will be displayed on a light grey background"
    >
      <FieldsSection title="Package image">
        <FieldRow
          title="Image file"
          description={
            <>
              <p>{t('File type: png (transparent)')}</p>
              <p>{t('Dimensions: 600x600 px')}</p>
              <p>{t('Max file size: 200kb')}</p>
            </>
          }
        >
          <MediaPackageImageUpload formik={formik} />
        </FieldRow>
      </FieldsSection>
    </Step>
  )
}

export default ImageStep
