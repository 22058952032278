import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ItemsLoading from '../../../../components/Loaders/ItemsLoading'
import SkeletonFacebookAdCardListItem from './FacebookAdCardListItem/Skeleton'
import FacebookAdCardListItem from './FacebookAdCardListItem'
import AdsWrapper from '../../../MediaOrderSummary/MediaOrderSummaryContent/MediaOrderCampaignsReports/HighestPerformingAds/AdsWrapper'

import { useFacebookMediaLoading } from './useFacebookMediaLoading'
import { getAllImageHashesFromAdsList } from './helpers'

import { clearAccessibleFacebookPages, getAccessibleFacebookPages } from '../../../../modules/actions/socialAccounts'
import { clearFacebookImageUrls, clearFacebookVideoUrls } from '../../../../modules/actions/mediaFiles'
import { clearAdPreview } from '../../../../modules/actions/ads'
import { clearChoicesCTA, getChoicesCTA } from '../../../../modules/actions/choices'
import {
  accessibleFacebookPagesErrorSelector,
  accessibleFacebookPagesWasLoadedSelector
} from '../../../../modules/selectors/socialAccounts'

import useStyles from './styles'

const FacebookAdCardList = ({
  // list props
  adAccountId,
  contentWrapperText = 'Ads',
  adsList,
  noDataText,
  skeletonProps,
  // request selectors
  loadingSelector,
  errorSelector,
  wasLoadedSelector,
  // cardListItem props
  currencySymbol,
  showStatus,
  showDetails,
  showMetrics,
  showActionsDropdown,
  showActionsButtons,
  showPurchaseMetric,
  // lineItem props
  lineItems,
  checkPlacements = true,
  isLineItemSummaryPage,
  lineItemsIsLoading,
  // internalAd props
  isAdApprovalsStyle,
  disapproveInternalAdHandler,
  // handlers
  loadMoreHandler,
  handleDeleteRequest,
  initialLoadingSkeletonsNumber = 8
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const facebookPagesWasLoaded = useSelector(accessibleFacebookPagesWasLoadedSelector)
  const facebookPagesError = useSelector(accessibleFacebookPagesErrorSelector)

  const imageHashes = useMemo(() => {
    return getAllImageHashesFromAdsList(adsList)
  }, [adsList])

  const { initialMediaIsLoading } = useFacebookMediaLoading({ adAccountId, imageHashes })

  // check that all needed data for ad previews is loaded and allow pages to be loaded with error
  const facebookPagesDidLoading = facebookPagesWasLoaded || facebookPagesError
  const adPreviewDataIsLoading = !facebookPagesDidLoading || initialMediaIsLoading

  const additionalDataIsLoading = adPreviewDataIsLoading || lineItemsIsLoading

  useEffect(() => {
    dispatch(getAccessibleFacebookPages())
    dispatch(getChoicesCTA())

    return () => {
      dispatch(clearAccessibleFacebookPages())
      dispatch(clearFacebookImageUrls())
      dispatch(clearFacebookVideoUrls())
      dispatch(clearAdPreview())
      dispatch(clearChoicesCTA())
    }
  }, [dispatch])

  return (
    <ItemsLoading
      itemsLength={adsList.length}
      className={classes.cardsList}
      noDataText={noDataText}
      isLoadingSelector={loadingSelector}
      wasLoadedSelector={wasLoadedSelector}
      additionalDataIsLoading={additionalDataIsLoading}
      errorSelector={errorSelector}
      initialLoadingSkeletonsNumber={initialLoadingSkeletonsNumber}
      SkeletonComponent={SkeletonFacebookAdCardListItem}
      ContentWrapper={AdsWrapper}
      contentWrapperText={contentWrapperText}
      skeletonProps={skeletonProps}
      loadMore={loadMoreHandler}
    >
      {adsList.map(ad => {
        const { id } = ad

        return (
          <FacebookAdCardListItem
            key={id}
            ad={ad}
            currencySymbol={currencySymbol}
            showStatus={showStatus}
            showDetails={showDetails}
            showMetrics={showMetrics}
            showActionsDropdown={showActionsDropdown}
            showActionsButtons={showActionsButtons}
            showPurchaseMetric={showPurchaseMetric}
            lineItems={lineItems}
            checkPlacements={checkPlacements}
            isLineItemSummaryPage={isLineItemSummaryPage}
            isAdApprovalsStyle={isAdApprovalsStyle}
            disapproveInternalAdHandler={() => disapproveInternalAdHandler(ad)}
            handleDeleteRequest={handleDeleteRequest}
          />
        )
      })}
    </ItemsLoading>
  )
}

export default FacebookAdCardList
