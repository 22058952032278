import { createStyles } from '../../../../../../styles/helpers'
import { black, darkGrey, lightGrey } from '../../../../../../styles/const/colors'

export default createStyles({
  adPreviewContainer: {
    width: '100%',
    maxWidth: 400,
    margin: '0 auto'
  },
  adPreview: {
    background: '#fff',
    padding: 14,
    fontFamily: 'Arial',
    marginBottom: 35,
    width: '100%'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  footer: {
    display: 'flex'
  },
  logo: {
    height: 70,
    width: 70,
    marginRight: 15,
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  description: {
    margin: 0,
    fontSize: 12
  },
  info: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  businessName: {
    fontSize: 10
  },
  adIcon: {
    display: 'inline-block',
    borderRadius: 2,
    background: '#34A852',
    fontSize: 10,
    color: '#fff',
    margin: 5,
    padding: '2px 4px 2px 3px'
  },
  callToActionButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 58,
    minHeight: 26,
    border: `1px solid ${lightGrey}`,
    fontSize: 10,
    fontWeight: 700,
    cursor: 'pointer',
    padding: '0 5px',
    margin: 3,
    marginLeft: 'auto'
  },
  title: {
    fontSize: 14,
    color: black,
    fontWeight: 'bold'
  },

  icon: {
    marginLeft: 'auto',
    color: darkGrey,
    fontSize: 13,
    cursor: 'pointer'
  }
})
