import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import MultiSelectBox from '../../../../../../features/components/Form/MultiSelectBox'

import { mediaProductVariablesSelector } from '../../../../../../modules/selectors/mediaOrdersProductVariables'
import { VARIABLES } from '../../../fields'

import useDrawerFormStyles from '../../../../../../styles/common/drawerForms'

const VariablesSection = ({ formik }) => {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormStyles()

  const { values, setFieldValue } = formik

  const mediaProductVariables = useSelector(mediaProductVariablesSelector)

  const variablesOptions = useMemo(() => {
    // used regular map instead of formatOptionsList because spreading all fields leads to unexpected behavior in MultiSelectBox
    return mediaProductVariables.map(variable => ({
      label: variable.name,
      value: variable.id
    }))
  }, [mediaProductVariables])

  return (
    <section className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>{t('Product variables (optional)')}</h3>
      <MultiSelectBox
        placeholder={t('Select variables')}
        name={VARIABLES}
        options={variablesOptions}
        value={values[VARIABLES]}
        setFieldValue={setFieldValue}
      />
    </section>
  )
}

export default VariablesSection
