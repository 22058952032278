import React from 'react'

import Field from '../../../components/Form/Field'

import currenciesList from '../../../constants/selectLists/currencies'
import { PRICE_PAIRS, PRICE_CURRENCY, PRICE_VALUE } from './fields'

import useStyles from './styles'

const PricePairFields = ({ formik, index }) => {
  const classes = useStyles()

  const { values } = formik

  const selectedCurrency = values[PRICE_PAIRS][index][PRICE_CURRENCY]

  const currencySymbol =
    selectedCurrency && currenciesList.find(({ value }) => value === selectedCurrency)?.['currency_symbol']

  return (
    <>
      <Field
        className={classes.currencyField}
        formik={formik}
        name={`${PRICE_PAIRS}[${index}].${PRICE_CURRENCY}`}
        options={currenciesList}
        placeholder="Curr"
        autoComplete="off"
      />
      <Field
        className={classes.valueField}
        symbolClassName={classes.symbol}
        formik={formik}
        symbol={currencySymbol}
        name={`${PRICE_PAIRS}[${index}].${PRICE_VALUE}`}
        type="number"
        placeholder="Price"
        autoComplete="off"
      />
    </>
  )
}

export default PricePairFields
