import { useMemo } from 'react'
import PropTypes from 'prop-types'

import FacebookVideoPreview from '../FacebookSingleMediaAdThumbnail/FacebookVideoPreview'
import SkeletonAdMediaThumbnail from '../AdMediaThumbnail/Skeleton'

import { FILE_NAME, FILE_URL, MEDIA_TYPE, VIDEO_PICTURE } from '../../../../../forms/Facebook/AdForms/fields'

import useStyles from './styles'

const FacebookCarouselItemThumbnail = ({ viewAdOnFacebook, mediaFile, isActive = false }) => {
  const classes = useStyles()

  const {
    [MEDIA_TYPE]: mediaType = 'image',
    [FILE_NAME]: fileName = '',
    [FILE_URL]: mediaUrl = '',
    [VIDEO_PICTURE]: videoPicture
  } = mediaFile

  const showSkeleton = useMemo(
    () => (mediaType === 'image' && !mediaUrl) || (mediaType === 'video' && !videoPicture) || !mediaType,
    [mediaType, mediaUrl, videoPicture]
  )

  if (showSkeleton) {
    return (
      <SkeletonAdMediaThumbnail
        isInProgress={isActive}
        fileName={fileName}
        className={classes.carouselItemMedia}
        mediaPreviewHeight="100%"
      />
    )
  }

  return (
    <div className={classes.carouselItemMedia}>
      {mediaType === 'image' ? (
        // Image in carousel item should cover the whole area
        <img className={classes.carouselItemImage} src={mediaUrl} alt="Media preview" />
      ) : (
        <FacebookVideoPreview
          viewAdOnFacebook={viewAdOnFacebook}
          mediaFile={mediaFile}
          pauseFromOutside={!isActive}
          className={classes.carouselVideoPreview}
        />
      )}
    </div>
  )
}

FacebookCarouselItemThumbnail.propTypes = {
  viewAdOnFacebook: PropTypes.string,
  mediaFile: PropTypes.object,
  isActive: PropTypes.bool
}

export default FacebookCarouselItemThumbnail
