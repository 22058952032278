import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import AdFacebookCreateForm from './AdFacebookCreateForm'
import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import { AdFacebookCreateContextProvider } from '../../formsContexts/AdFacebookCreateFormContextProvider'

import useStepFormRoutes from '../../../../hooks/formHooks/useStepFormRoutes'

import { setAdCarouselSelectedIndex } from '../../../../modules/actions/ads'

import { AD_FACEBOOK_CREATE } from '../../../../constants/forms'
import { FORMAT_PRODUCT } from '../fields'

const AdFacebookCreate = ({ campaignObjective = '', lineItemWasLoaded }) => {
  const dispatch = useDispatch()
  const manageStepFormRoutes = useStepFormRoutes({ initialRoute: FORMAT_PRODUCT })

  const { handleFormClose } = manageStepFormRoutes

  const onAfterFormClose = useCallback(() => {
    handleFormClose()
    dispatch(setAdCarouselSelectedIndex('adCreateForm', 0))
  }, [dispatch, handleFormClose])

  return (
    <FormDrawerWrapper
      openButtonText="Create Ad"
      formName={AD_FACEBOOK_CREATE}
      onAfterFormClose={onAfterFormClose}
      hasDefaultDrawerContentSpacing={false}
      isWideDrawer
    >
      <AdFacebookCreateContextProvider campaignObjective={campaignObjective}>
        <AdFacebookCreateForm lineItemWasLoaded={lineItemWasLoaded} />
      </AdFacebookCreateContextProvider>
    </FormDrawerWrapper>
  )
}

AdFacebookCreate.propTypes = {
  isWrappedInDrawer: PropTypes.bool,
  onSuccessSubmit: PropTypes.func,
  // some of the props controlled outside of the form
  // it helps to make it more dynamic, like using on rights summary page with multi-form approach
  campaignObjective: PropTypes.string,
  lineItemWasLoaded: PropTypes.bool.isRequired,
  // ad account can be passed from outside (e.g. media-orders upload creative form)
  adAccountId: PropTypes.string
}

export default AdFacebookCreate
