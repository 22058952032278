import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import ListItemsTable from '../../../../../../features/components/ListItemsTable'
import AdsWrapper from '../AdsWrapper'

import { selectedAdAccountDataSelector } from '../../../../../../modules/selectors/app'

import { TIKTOK_PLATFORM, TIKTOK_PROVIDER_PLATFORM } from '../../../../../../constants/selectLists/platformList'

const TikTokPerformingAds = ({
  dataSelector,
  loadingSelector,
  errorSelector,
  wasLoadedSelector,
  showOnlyHighestPerforming = true,
  loadMoreHandler
}) => {
  const { currency_symbol: selectedAdAccountCurrencySymbol } = useSelector(selectedAdAccountDataSelector)
  const currencySymbol = selectedAdAccountCurrencySymbol

  const combinedAds = useSelector(dataSelector)

  const tiktokAds = useMemo(() => {
    return combinedAds.filter(ad => ad.provider === TIKTOK_PROVIDER_PLATFORM)
  }, [combinedAds])

  const sortedTiktokAds = useMemo(() => {
    return showOnlyHighestPerforming
      ? tiktokAds.sort((a, b) => b.ctr - a.ctr).slice(0, 5)
      : tiktokAds.sort((a, b) => b.ctr - a.ctr)
  }, [tiktokAds, showOnlyHighestPerforming])

  return (
    <section id="tiktok-ad-cards-list">
      <ListItemsTable
        items={sortedTiktokAds}
        isLoadingSelector={loadingSelector}
        errorSelector={errorSelector}
        wasLoadedSelector={wasLoadedSelector}
        platform={TIKTOK_PLATFORM}
        currencySymbol={currencySymbol}
        noDataText={'There are no highest performing TikTok ads'}
        ContentWrapper={AdsWrapper}
        loadMoreDataHandler={loadMoreHandler}
      />
    </section>
  )
}

export default TikTokPerformingAds
