import { createStyles } from '../../../../../styles/helpers'
import { lighterGrey } from '../../../../../styles/const/colors'

const useStyles = createStyles({
  metric: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '4px 8px',
    fontSize: 12,

    '&:nth-child(2n)': {
      backgroundColor: lighterGrey
    }
  }
})

export default useStyles
