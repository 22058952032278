import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { openForm } from '../../../../../../../modules/actions/forms'

import { DISCOUNT_PRESET_EDIT } from '../../../../../../../constants/forms'

export function useDiscountPresetActions(discountPresetData) {
  const dispatch = useDispatch()

  const { id } = discountPresetData

  const openEditFormHandler = useCallback(() => {
    dispatch(openForm({ id, formName: DISCOUNT_PRESET_EDIT }))
  }, [dispatch, id])

  const dropdownOptions = useMemo(
    () => [
      {
        text: 'Edit',
        onClickHandler: openEditFormHandler
      }
    ],
    [openEditFormHandler]
  )

  return useMemo(
    () => ({
      dropdownOptions
    }),
    [dropdownOptions]
  )
}
