import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './styles'

function AdCardMetricContainer({ children }) {
  const classes = useStyles()

  return <div className={classes.metric}>{children}</div>
}

export default AdCardMetricContainer

AdCardMetricContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}
