import { concat } from '../../helpers/common'

const MODULE_NAME = 'MARGINS/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_MARGINS = concat(MODULE_NAME, 'GET_MARGINS')
export const GET_MARGINS_SUCCESS = concat(MODULE_NAME, 'GET_MARGINS_SUCCESS')
export const GET_MARGINS_FAILURE = concat(MODULE_NAME, 'GET_MARGINS_FAILURE')
export const CLEAR_MARGINS = concat(MODULE_NAME, 'CLEAR_MARGINS')

export const CREATE_MARGINS = concat(MODULE_NAME, 'CREATE_MARGINS')
export const CREATE_MARGINS_SUCCESS = concat(MODULE_NAME, 'CREATE_MARGINS_SUCCESS')
export const CREATE_MARGINS_FAILURE = concat(MODULE_NAME, 'CREATE_MARGINS_FAILURE')
export const CLEAR_CREATE_MARGINS = concat(MODULE_NAME, 'CLEAR_CREATE_MARGINS')

export const CREATE_MARGIN_OVERRIDE = concat(MODULE_NAME, 'CREATE_MARGIN_OVERRIDE')
export const CREATE_MARGIN_OVERRIDE_SUCCESS = concat(MODULE_NAME, 'CREATE_MARGIN_OVERRIDE_SUCCESS')
export const CREATE_MARGIN_OVERRIDE_FAILURE = concat(MODULE_NAME, 'CREATE_MARGIN_OVERRIDE_FAILURE')
export const CLEAR_CREATE_MARGIN_OVERRIDE = concat(MODULE_NAME, 'CLEAR_CREATE_MARGIN_OVERRIDE')

export const DELETE_MARGIN_OVERRIDE = concat(MODULE_NAME, 'DELETE_MARGIN_OVERRIDE')
export const DELETE_MARGIN_OVERRIDE_SUCCESS = concat(MODULE_NAME, 'DELETE_MARGIN_OVERRIDE_SUCCESS')
export const DELETE_MARGIN_OVERRIDE_FAILURE = concat(MODULE_NAME, 'DELETE_MARGIN_OVERRIDE_FAILURE')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// get margins
export function getMargins(params) {
  return { type: GET_MARGINS, params }
}

export function getMarginsSuccess(marginsData) {
  return { type: GET_MARGINS_SUCCESS, marginsData }
}

export function getMarginsFailure(error) {
  return { type: GET_MARGINS_FAILURE, error }
}

export function clearMargins() {
  return { type: CLEAR_MARGINS }
}

// create margins
export function createMargins(marginsData) {
  return { type: CREATE_MARGINS, marginsData }
}

export function createMarginsSuccess(marginsData) {
  return { type: CREATE_MARGINS_SUCCESS, marginsData }
}

export function createMarginsFailure(error) {
  return { type: CREATE_MARGINS_FAILURE, error }
}

export function clearCreateMargins() {
  return { type: CLEAR_CREATE_MARGINS }
}

// create margin override
export function createMarginOverride(marginOverrideData) {
  return { type: CREATE_MARGIN_OVERRIDE, marginOverrideData }
}

export function createMarginOverrideSuccess(marginOverrideData) {
  return { type: CREATE_MARGIN_OVERRIDE_SUCCESS, marginOverrideData }
}

export function createMarginOverrideFailure(error) {
  return { type: CREATE_MARGIN_OVERRIDE_FAILURE, error }
}

export function clearCreateMarginOverride() {
  return { type: CLEAR_CREATE_MARGIN_OVERRIDE }
}

// delete margin override
export function deleteMarginOverride(id) {
  return { type: DELETE_MARGIN_OVERRIDE, id }
}

export function deleteMarginOverrideSuccess(id) {
  return { type: DELETE_MARGIN_OVERRIDE_SUCCESS, id }
}

export function deleteMarginOverrideFailure(error) {
  return { type: DELETE_MARGIN_OVERRIDE_FAILURE, error }
}
