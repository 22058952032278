import { useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import SkeletonAdMediaThumbnail from '../../../AdCard/AdMediaThumbnail/Skeleton'
import FacebookVideoPreview from '../../../AdCard/FacebookSingleMediaAdThumbnail/FacebookVideoPreview'

import { FILE_NAME, FILE_URL, MEDIA_TYPE, VIDEO_PICTURE } from '../../../../../../forms/Facebook/AdForms/fields'

import useStyles from './styles'

const InstagramStoryThumbnail = ({ viewAdOnFacebook, mediaFile, isInProgress = false, isActive = false }) => {
  const classes = useStyles()

  const {
    [MEDIA_TYPE]: mediaType = 'image',
    [FILE_NAME]: fileName = '',
    [FILE_URL]: mediaUrl = '',
    [VIDEO_PICTURE]: videoPicture
  } = mediaFile

  const videoRef = useRef(null)

  const showSkeleton = useMemo(
    () => (mediaType === 'image' && !mediaUrl) || (mediaType === 'video' && !videoPicture) || !mediaType,
    [mediaType, mediaUrl, videoPicture]
  )

  useEffect(() => {
    if (!isActive) {
      // Stop video when it's not active. (next/prev carousel item is active)
      // we can only stop video if it's not paused, otherwise it will throw an error
      if (videoRef.current && !videoRef.current.paused) {
        videoRef.current?.pause()
      }
    }
  }, [isActive])

  if (showSkeleton) {
    return (
      <SkeletonAdMediaThumbnail isInProgress={isInProgress} fileName={fileName} className={classes.storyItemMedia} />
    )
  }

  return (
    <div className={classes.storyItemMedia}>
      {mediaType === 'image' ? (
        <img src={mediaUrl} alt="Media preview" className={classes.storyImage} />
      ) : (
        <FacebookVideoPreview
          viewAdOnFacebook={viewAdOnFacebook}
          mediaFile={mediaFile}
          videoRef={videoRef}
          videoPictureClassName={classes.storyVideoPicture}
          pauseFromOutside={!isActive}
        />
      )}
    </div>
  )
}

InstagramStoryThumbnail.propTypes = {
  viewAdOnFacebook: PropTypes.string,
  mediaFile: PropTypes.object,
  isInProgress: PropTypes.bool,
  isActive: PropTypes.bool
}

export default InstagramStoryThumbnail
