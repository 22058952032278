import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { CHIP_COLORS } from '../../constants/other'

import useStyles from './styles'

function Chip(props) {
  const { t } = useTranslation()

  const {
    text = '',
    subTagText,
    color = CHIP_COLORS.grey,
    subTagColor = CHIP_COLORS.grey,
    wrapperClassName,
    className = '',
    isBadge,
    size = 'big',
    fontSize = 12,
    isHoverable,
    ...restProps
  } = props
  const classes = useStyles({ color, subTagColor, isBadge, fontSize, size })

  return (
    <div className={classnames(classes.tagWrapper, wrapperClassName)}>
      <div
        className={classnames(classes.tag, className, { withSubTagSeparator: !!subTagText, hoverable: isHoverable })}
        {...restProps}
      >
        {t(text)}
      </div>
      {subTagText && (
        <div className={classnames(classes.subTag, className)} {...restProps}>
          {subTagText}
        </div>
      )}
    </div>
  )
}

const chipColors = [
  CHIP_COLORS.green,
  CHIP_COLORS.yellow,
  CHIP_COLORS.grey,
  CHIP_COLORS.purple,
  CHIP_COLORS.blue,
  CHIP_COLORS.red,
  CHIP_COLORS.redWithWhiteColor
]

Chip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  subTagText: PropTypes.string,
  color: PropTypes.oneOf(chipColors),
  subTagColor: PropTypes.oneOf(chipColors),
  fontSize: PropTypes.number,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  isBadge: PropTypes.bool,
  size: PropTypes.oneOf(['big', 'small'])
}

export default Chip
