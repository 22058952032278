// AGES
export const AGE_OPTION = 'age_option'
// basic options
export const AGE_13_17 = 'AGE_13_17'
export const AGE_18_24 = 'AGE_18_24'
export const AGE_25_34 = 'AGE_25_34'
export const AGE_35_44 = 'AGE_35_44'
export const AGE_45_54 = 'AGE_45_54'
export const AGE_55_100 = 'AGE_55_100'
// complex options
export const ANYONE_OVER_18 = 'anyone_over_18'
export const AGE_18_34 = 'AGE_18_34'
export const AGE_25_54 = 'AGE_25_54'
// custom age ranges
export const CUSTOM_AGE_OPTION = 'custom_age_option'
export const CUSTOM_AGE_RANGES = 'custom_age_ranges'
export const customAgeRanges = {
  [AGE_13_17]: false,
  [AGE_18_24]: false,
  [AGE_25_34]: false,
  [AGE_35_44]: false,
  [AGE_45_54]: false,
  [AGE_55_100]: false
}

export const ageRangesInitialValues = {
  [AGE_OPTION]: AGE_25_54,
  [CUSTOM_AGE_RANGES]: customAgeRanges
}
