import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import Field from '../../../../../components/Form/Field'
import Form from '../../../../../components/Form'
import InfoModal from '../../../../../features/components/Modals/InfoModal'

import { ReactComponent as WarningIcon } from '../../../../../assets/icons/warning-icon.svg'

import { disapproveInternalAssetGroup, clearDisapproveInternalAssetGroup } from '../../../../../modules/actions/assets'
import {
  disapproveInternalAssetGroupErrorSelector,
  disapproveInternalAssetGroupIsLoadingSelector,
  disapproveInternalAssetGroupWasDisapprovedSelector
} from '../../../../../modules/selectors/assets'

import { REASON } from './fields'
import { DISAPPROVE_INTERNAL_AD } from '../../../../../constants/forms'
import { GOOGLE_PROVIDER_PLATFORM } from '../../../../../constants/selectLists/platformList'

import { initialValues, validationSchema } from './fields'

import useStyles from '../../../../../styles/common/modalForms'

const DisapproveAssetGroupModal = ({ isModalOpened, modalData = {}, onClose }) => {
  const classes = useStyles()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const assetGroupWasDisapproved = useSelector(disapproveInternalAssetGroupWasDisapprovedSelector)

  // id comes from modal data when user opens modal on specific asset group
  const { id: assetGroupId } = modalData

  const onSubmit = useCallback(
    values => {
      dispatch(
        disapproveInternalAssetGroup(
          assetGroupId,
          {
            reason: values[REASON]
          },
          GOOGLE_PROVIDER_PLATFORM
        )
      )
    },
    [dispatch, assetGroupId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const clearDisapproveAssetGroupHandler = useCallback(() => {
    dispatch(clearDisapproveInternalAssetGroup())
  }, [dispatch])

  return (
    <InfoModal
      isOpen={isModalOpened}
      onClose={onClose}
      Icon={WarningIcon}
      contentClassName={classes.content}
      modalTitle="Disapprove asset group"
      ModalMessage={
        <p>
          {t(
            'Please enter a descriptive reason why the asset group is being disapproved. This feedback will be shared with the partner.'
          )}
        </p>
      }
    >
      <Form
        formik={formik}
        formName={DISAPPROVE_INTERNAL_AD}
        submitText={t('Disapprove')}
        className={classes.form}
        formFooterClassName={classes.formFooter}
        successSubmit={assetGroupWasDisapproved}
        errorSelector={disapproveInternalAssetGroupErrorSelector}
        isLoadingSelector={disapproveInternalAssetGroupIsLoadingSelector}
        clearHandler={clearDisapproveAssetGroupHandler}
        // close modal when asset group is disapproved
        onSuccessSubmit={onClose}
      >
        <Field formik={formik} name={REASON} placeholder={t('Reason')} />
      </Form>
    </InfoModal>
  )
}

export default DisapproveAssetGroupModal
