import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import MediaPackageEditForm from './MediaPackageEditForm'

import useManageEditFormData from '../../../../hooks/formHooks/useManageEditFormData'

import { clearGetProductTags, getTagsList } from '../../../../modules/actions/tags'
import { clearGetMediaPackage, getMediaPackage } from '../../../../modules/actions/mediaPackages'

import {
  mediaPackageCategoriesWasLoadedSelector,
  mediaPackageSelector,
  mediaPackageWasLoadedSelector
} from '../../../../modules/selectors/mediaPackages'
import { tagsWasLoadedSelector } from '../../../../modules/selectors/tags'

import { MEDIA_PACKAGE_EDIT } from '../../../../constants/forms'

function MediaPackageEdit({ controllerId }) {
  const dispatch = useDispatch()

  const mediaPackage = useSelector(mediaPackageSelector)
  const mediaPackageWasLoaded = useSelector(mediaPackageWasLoadedSelector)
  const mediaCategoriesWasLoaded = useSelector(mediaPackageCategoriesWasLoadedSelector)
  const tagsWasLoaded = useSelector(tagsWasLoadedSelector)

  const getMediaPackageHandler = useCallback(
    selectedEditItemId => {
      dispatch(getMediaPackage(selectedEditItemId))
      dispatch(getTagsList({ controller: controllerId }))
    },
    [controllerId, dispatch]
  )

  const clearMediaPackageHandler = useCallback(() => {
    dispatch(clearGetMediaPackage())
    dispatch(clearGetProductTags())
  }, [dispatch])

  useManageEditFormData({
    formName: MEDIA_PACKAGE_EDIT,
    loadedDataId: mediaPackage.id,
    getDataHandler: getMediaPackageHandler,
    clearDataHandler: clearMediaPackageHandler
  })

  const isFormLoading = useMemo(
    () => !mediaPackageWasLoaded || !mediaCategoriesWasLoaded || !tagsWasLoaded,
    [mediaCategoriesWasLoaded, mediaPackageWasLoaded, tagsWasLoaded]
  )

  return (
    <FormDrawerWrapper
      formName={MEDIA_PACKAGE_EDIT}
      isFormLoading={isFormLoading}
      title="Edit media package"
      showOpenButton={false}
    >
      <MediaPackageEditForm />
    </FormDrawerWrapper>
  )
}

export default MediaPackageEdit
