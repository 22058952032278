import React from 'react'
import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

function SkeletonSummaryReportGraph() {
  const classes = useStyles()

  return (
    <div className={classes.graphCard} style={{ padding: 15, display: 'block' }}>
      <h5 className={classes.graphTitle} style={{ padding: 0 }}>
        <Skeleton width={'70%'} />
      </h5>
      <h5 className={classes.graphTitle} style={{ padding: 0 }}>
        <Skeleton width={'20%'} />
      </h5>
      <div>
        <Skeleton height={100} />
      </div>
    </div>
  )
}

export default SkeletonSummaryReportGraph
