import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import MultipleOptionsField from '../../../../../../../ReusableFormFields/MultipleOptionsField'
import TextSuggestions from '../../../../../../../../features/components/Form/TextSuggestions'

import { googleAdSuggestionsIsLoadingSelector } from '../../../../../../../../modules/selectors/tools'

import { DESCRIPTIONS_LIST, descriptionsMaxLengthSchema } from '../../../../../fields'

function DescriptionsSection({ formik, suggestions }) {
  const { t } = useTranslation()

  const [selectedField, setSelectedField] = useState('')

  const { setFieldValue } = formik

  const suggestionsLoading = useSelector(googleAdSuggestionsIsLoadingSelector)

  const handleDescriptionTextChange = useCallback(
    selectedChange => {
      setFieldValue(selectedField, selectedChange)
      setSelectedField('')
    },
    [setFieldValue, selectedField]
  )

  const handleSelectedFieldChange = useCallback(selectedField => {
    setSelectedField(selectedField)
  }, [])

  return (
    <FieldRow
      title="Descriptions"
      description={
        <>
          <p>{t('Description 1 must be 60 characters or less')}</p>
          <br />
          <p>{t('All others must be 90 characters or less')}</p>
        </>
      }
      footer={
        <TextSuggestions
          showLogo={false}
          selectedSuggestion={null}
          suggestions={suggestions}
          onSelectSuggestion={handleDescriptionTextChange}
          typingAnimation={suggestionsLoading}
        />
      }
    >
      <MultipleOptionsField
        isTextarea
        ignoreBlur
        fieldPlaceholder="Description"
        maxLengthSchema={descriptionsMaxLengthSchema}
        listFieldName={DESCRIPTIONS_LIST}
        formik={formik}
        minListQuantity={2}
        maxListQuantity={5}
        onFieldSelect={handleSelectedFieldChange}
        selectedFieldName={selectedField}
        preselectField={!!suggestions?.length}
        addAnotherButtonText="+ Add another description"
      />
    </FieldRow>
  )
}

DescriptionsSection.propTypes = {
  suggestions: PropTypes.array
}

export default DescriptionsSection
