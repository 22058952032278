import { concat } from '../../helpers/common'

const MODULE_NAME = 'TOOLS/'

// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_AI_SUGGESTIONS = concat(MODULE_NAME, 'GET_AI_SUGGESTIONS')
export const GET_AI_SUGGESTIONS_CHUNK = concat(MODULE_NAME, 'GET_AI_SUGGESTIONS_CHUNK')
export const GET_AI_SUGGESTIONS_SUCCESS = concat(MODULE_NAME, 'GET_AI_SUGGESTIONS_SUCCESS')
export const GET_AI_SUGGESTIONS_FAILURE = concat(MODULE_NAME, 'GET_AI_SUGGESTIONS_FAILURE')
export const CLEAR_GET_AI_SUGGESTIONS = concat(MODULE_NAME, 'CLEAR_GET_AI_SUGGESTIONS')

export const GET_CHANGE_HISTORY = concat(MODULE_NAME, 'GET_CHANGE_HISTORY')
export const GET_CHANGE_HISTORY_SUCCESS = concat(MODULE_NAME, 'GET_CHANGE_HISTORY_SUCCESS')
export const GET_CHANGE_HISTORY_FAILURE = concat(MODULE_NAME, 'GET_CHANGE_HISTORY_FAILURE')
export const CLEAR_GET_CHANGE_HISTORY = concat(MODULE_NAME, 'CLEAR_GET_CHANGE_HISTORY')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// Get ai suggestions
export function getAiSuggestions(slug, params) {
  return { type: GET_AI_SUGGESTIONS, slug, params }
}

export function getAiSuggestionsChunk(slug, dataChunk) {
  return { type: GET_AI_SUGGESTIONS_CHUNK, slug, dataChunk }
}

export function getAiSuggestionsSuccess(slug, data) {
  return { type: GET_AI_SUGGESTIONS_SUCCESS, slug, data }
}

export function getAiSuggestionsFailure(slug, error) {
  return { type: GET_AI_SUGGESTIONS_FAILURE, slug, error }
}

export function clearGetAiSuggestions() {
  return { type: CLEAR_GET_AI_SUGGESTIONS }
}

export function clearSlugAiSuggestions(slug) {
  return { type: `${CLEAR_GET_AI_SUGGESTIONS}_${slug}`, slug }
}

// Get updates history
export function getChangeHistory(params) {
  return { type: GET_CHANGE_HISTORY, params }
}

export function getChangeHistorySuccess(data) {
  return { type: GET_CHANGE_HISTORY_SUCCESS, data }
}

export function getChangeHistoryFailure(error) {
  return { type: GET_CHANGE_HISTORY_FAILURE, error }
}

export function clearGetChangeHistory() {
  return { type: CLEAR_GET_CHANGE_HISTORY }
}
