import { keys } from '../../../../helpers/common'

export const scrollToContainerTop = containerRef => {
  containerRef?.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

export const isStepFieldsValid = (errors, stepFields) => {
  let isValid = true

  stepFields.forEach(field => {
    keys(errors).forEach(fieldError => {
      if (field === fieldError) {
        isValid = false
      }
    })
  })
  return isValid
}
