import * as Yup from 'yup'

import { LOCATION_LIST } from '../GeolocationsFields/fields'

import store from '../../../store'
// NAME
export const NAME = 'name'

// NAME
export const BUSINESS_NAME = 'business_name'

// BUDGET
export const BUDGET_OPTION = 'budget_option'
export const BUDGET_LIFETIME = 'budget_lifetime'
export const BUDGET_DAILY = 'budget_daily'

// SPEND
export const MIN_SPEND_TARGET_LIFETIME = 'min_spend_target_lifetime'
export const SPEND_CAP_LIFETIME = 'spend_cap_lifetime'
export const MIN_SPEND_TARGET_DAILY = 'min_spend_target_daily'
export const SPEND_CAP_DAILY = 'spend_cap_daily'

// TARGET COST CAP, ROAS AVERAGE FLOOR
export const TARGET_COST_CAP = 'target_cost_cap'
export const ROAS_AVERAGE_FLOOR = 'roas_average_floor'

// AUDIENCE
export const AUDIENCE = 'audience'

// AGE
export const AGE = 'age'
export const MIN_AGE_YEARS = 'min_age_years'
export const MAX_AGE_YEARS = 'max_age_years'

// FAMILY STATUS
export const FAMILY_STATUSES = 'family_statuses'
export const PARENT_CATEGORIES = 'parent_categories'

// LANGUAGES
export const LANGUAGES = 'languages'

// PLACEMENT
export const PLACEMENT_OPTION = 'placement_option'
export const PLACEMENT_OPTION_ALL_INCLUDING_AUDIENCE = 'placement_option_all_including_audience'
export const PLACEMENT_OPTION_ALL_EXCLUDING_AUDIENCE = 'placement_option_all_excluding_audience'
export const PLACEMENT_OPTION_NEWS_FEEDS_AND_STORIES = 'placement_option_news_feeds_and_stories'
export const PLACEMENT_OPTION_NEWS_FEEDS = 'placement_option_news_feeds'
export const PLACEMENT_OPTION_STORIES = 'placement_option_stories'
export const PLACEMENT_OPTION_INSTAGRAM = 'placement_option_instagram'
export const PLACEMENT_OPTION_CUSTOM = 'placement_option_custom'

// placement custom positions
export const PLACEMENT_POSITIONS = 'placement_positions'
// facebook
export const FACEBOOK_FEED = 'facebook_feed'
export const FACEBOOK_MARKETPLACE = 'facebook_marketplace'
export const FACEBOOK_VIDEO_FEEDS = 'facebook_video_feeds'
export const FACEBOOK_RIGHT_HAND_COLUMN = 'facebook_right_hand_column'
export const FACEBOOK_INSTREAM_VIDEO = 'facebook_instream_video'
export const FACEBOOK_SEARCH = 'facebook_search'
export const FACEBOOK_INSTANT_ARTICLE = 'facebook_instant_article'
export const FACEBOOK_STORY = 'facebook_story'
export const FACEBOOK_BIZ_DISCO_FEED = 'facebook_biz_disco_feed'
export const FACEBOOK_FACEBOOK_REELS = 'facebook_facebook_reels'
export const FACEBOOK_FACEBOOK_REELS_OVERLAY = 'facebook_facebook_reels_overlay'
// instagram
export const INSTAGRAM_IG_SEARCH = 'instagram_ig_search'
export const INSTAGRAM_STREAM = 'instagram_stream'
export const INSTAGRAM_SHOP = 'instagram_shop'
export const INSTAGRAM_STORY = 'instagram_story'
export const INSTAGRAM_REELS = 'instagram_reels'
export const INSTAGRAM_PROFILE_FEED = 'instagram_profile_feed'
export const INSTAGRAM_EXPLORE = 'instagram_explore'
export const INSTAGRAM_EXPLORE_HOME = 'instagram_explore_home'
// todo: SHOP-1744 INSTAGRAM_REELS_OVERLAY item doesn't exist on BE, but it will be added. Correct the name when it's ready
export const INSTAGRAM_REELS_OVERLAY = 'instagram_reels_overlay'
// messenger
export const MESSENGER_MESSENGER_HOME = 'messenger_messenger_home'
export const MESSENGER_STORY = 'messenger_story'
export const MESSENGER_SPONSORED_MESSAGES = 'messenger_sponsored_messages'
// audience network
export const AUDIENCE_NETWORK_INSTREAM_VIDEO = 'audience_network_instream_video'
export const AUDIENCE_NETWORK_CLASSIC = 'audience_network_classic'
export const AUDIENCE_NETWORK_REWARDED_VIDEO = 'audience_network_rewarded_video'

export const locationListValidation = Yup.string().test(
  LOCATION_LIST,
  'You are trying to add list with more than 500 locations. To target all selected locations you need to create separate line items, each with less than 500 locations in each.',
  selectedLocationList => {
    if (selectedLocationList) {
      const {
        location: { locationLists }
      } = store.getState()
      const locationListData =
        locationLists.results && locationLists.results.find(({ id }) => id === parseInt(selectedLocationList))

      return locationListData && locationListData.size <= 500
    } else {
      return true
    }
  }
)
