import * as Yup from 'yup'

export const PRICE_PAIRS = 'price_pairs'
export const PRICE_CURRENCY = 'price_currency'
export const PRICE_VALUE = 'price_value'

export const pricePairInitialValue = {
  [PRICE_CURRENCY]: '',
  [PRICE_VALUE]: ''
}

export const pricePairsValidation = Yup.array().of(
  Yup.object().shape({
    [PRICE_CURRENCY]: Yup.string().required('Currency Required'),
    [PRICE_VALUE]: Yup.number().required('Price Required')
  })
)
