import { createStyles } from '../../../../../styles/helpers'
import { darkGrey, textGrey } from '../../../../../styles/const/colors'

const useStyles = createStyles({
  radioButtonsContainer: {
    paddingLeft: 28
  },
  setupAgainButton: {
    border: 'none',
    background: 'none',
    padding: 0,
    fontSize: 14,
    textDecoration: 'underline',
    outline: 'none',
    color: textGrey,
    cursor: 'pointer',
    marginTop: 8,
    marginBottom: 8,
    textAlign: 'left',
    '&:hover': {
      color: darkGrey
    }
  }
})

export default useStyles
