export const IMAGE_TYPE = 'image'
export const VIDEO_TYPE = 'video'
// export const AUDIO_TYPE = 'audio'
export const FILE_TYPE = 'file'
export const IMAGE_OR_VIDEO_TYPE = 'image_or_video'
export const ORIGINAL_PRODUCT_NAME = 'original_product_name'

export const productFileTypes = [
  {
    value: IMAGE_TYPE,
    label: 'Image'
  },
  {
    value: VIDEO_TYPE,
    label: 'Video'
  },
  {
    value: IMAGE_OR_VIDEO_TYPE,
    label: 'Image or video'
  },
  // {
  //   value: AUDIO_TYPE,
  //   label: 'Audio'
  // },
  {
    value: 'short_text',
    label: 'Short text'
  },
  {
    value: 'long_text',
    label: 'Long text'
  },
  {
    value: FILE_TYPE,
    label: 'File'
  }
]

export const productImageTypeFormats = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'eps', 'raw', 'ico', 'heic']
export const productVideoTypeFormats = [
  'mp4',
  'mkv',
  'flv',
  'wmv',
  'mov',
  'avi',
  'vob',
  'mpg',
  'mpeg',
  'm4v',
  '3gp',
  'ogg',
  'webm'
]
export const productImageOrVideoTypeFormats = [...productImageTypeFormats, ...productVideoTypeFormats]
// export const productAudioTypeFormats = ['mp3', 'wav', 'aac', 'flac', 'ogg', 'wma', 'm4a', 'aiff']
export const productFileTypeFormats = [
  'zip',
  'rar',
  '7z',
  'tar',
  'gz',
  'tar',
  'bz2',
  'iso',
  'dmg',
  'bin',
  'csv',
  'dat',
  'db',
  'dbf',
  'log',
  'mdb',
  'sav',
  'sql',
  'tar',
  'xml',
  'indd',
  'ai',
  'psd',
  'svg',
  'txt',
  'rtf',
  'doc',
  'docx',
  'odt',
  'pdf'
]

export const getSupportedFormats = fileTypeValue => {
  // allow any extension for any type
  const allFormats = [...productImageOrVideoTypeFormats, ...productFileTypeFormats]

  // sort alphabetically and format for select
  return allFormats.sort().map(format => ({ value: format, label: '.' + format }))

  // if decide return to allow only specific extensions for each type this could be used:
  // switch (fileTypeValue) {
  //   case VIDEO_TYPE:
  //     return productVideoTypeFormats.map(format => ({ value: format, label: '.' + format }))
  //   // case AUDIO_TYPE:
  //   //   return productAudioTypeFormats.map(format => ({ value: format, label: '.' + format }))
  //   case FILE_TYPE:
  //     return productFileTypeFormats.map(format => ({ value: format, label: '.' + format }))
  //   case IMAGE_OR_VIDEO_TYPE:
  //     return productImageOrVideoTypeFormats.map(format => ({ value: format, label: '.' + format }))
  //   case IMAGE_TYPE:
  //   default:
  //     return productImageTypeFormats.map(format => ({ value: format, label: '.' + format }))
  // }
}
