import { createStyles } from '../../../styles/helpers'

import { red } from '../../../styles/const/colors'

export default createStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:first-child)': {
      marginTop: 16
    }
  },
  iconWrapper: {
    width: '100%',
    maxWidth: 17,
    marginRight: 13,
    display: 'flex',
    justifyContent: 'center'
  },
  errorIcon: {
    width: 10,
    height: 10,
    '& path': {
      fill: red
    }
  },
  loaderIcon: {
    '& .path': {
      stroke: `${theme.brandPrimary} !important`,
      strokeWidth: '5 !important'
    }
  },
  description: {
    fontSize: 14
  }
}))
