import store from '../../store'
import * as Sentry from '@sentry/react'

import { currentUserIdSelector } from '../../modules/selectors/app'

export const captureErrorToSentry = ({ message, status, method, otherProps, data, params, error, toast }) => {
  const userId = currentUserIdSelector(store.getState())

  // skip token expiration errors
  const isTokenExpired = error?.message === 'Your session has expired. Please log in again.'

  if (!isTokenExpired) {
    // throw all api errors
    Sentry.captureException(message, {
      user: {
        id: userId
      },
      extra: {
        method,
        status,
        url: otherProps.url,
        otherProps,
        dataPayload: JSON.stringify(data, null, 2),
        paramsPayload: params,
        error: error,
        response: error.response,
        responseData: JSON.stringify(error.response?.data, null, 2),
        toast
      }
    })
  }
}
