import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { updateFileUploadProgress, clearUploadedFile } from '../../modules/actions/files'
import {
  fileUploadErrorSelector,
  fileUploadProgressSelector,
  uploadedFileSelector
} from '../../modules/selectors/files'

export default function useFileUpload({ fileName, onFileRemove, onFileUploaded }) {
  const dispatch = useDispatch()

  const [selectedFile, setSelectedFile] = useState({})
  const [fileURLBlob, setFileURLBlob] = useState('')
  // when file successfully uploaded set the internal state, that is done to make one time callback on successful
  // FileUploaded to avoid infinity loops based on onFileUploaded func change due to dependencies
  const [fileWasUploaded, setFileWasUploaded] = useState(false)

  const uploadingFileName = fileName || selectedFile.name

  const fileUploadError = useSelector(fileUploadErrorSelector)
  const { [uploadingFileName]: uploadedFileData } = useSelector(uploadedFileSelector)
  const { [uploadingFileName]: fileUploadProgress } = useSelector(fileUploadProgressSelector)

  const fileIsUploaded = useMemo(() => fileUploadProgress === 100, [fileUploadProgress])
  const isFileLoading = useMemo(() => !!fileUploadProgress && !fileIsUploaded, [fileUploadProgress, fileIsUploaded])

  const handleRemoveFile = useCallback(() => {
    setSelectedFile({})
    setFileURLBlob('')
    dispatch(updateFileUploadProgress({ [uploadingFileName]: 0 }))
    dispatch(clearUploadedFile(uploadingFileName))
    // callback for parent components
    onFileRemove && onFileRemove()
  }, [dispatch, onFileRemove, uploadingFileName])

  useEffect(() => {
    if (fileIsUploaded) {
      setFileWasUploaded(true)
    }
  }, [fileIsUploaded])

  useEffect(() => {
    // when file successfully uploaded make a callback to Parent component
    if (fileWasUploaded) {
      onFileUploaded(uploadedFileData)
      setFileWasUploaded(false)
    }
  }, [fileWasUploaded, uploadedFileData, onFileUploaded])

  useEffect(() => {
    if (fileUploadError) {
      // remove file when the uploading failed
      handleRemoveFile()
    }
  }, [handleRemoveFile, fileUploadError])

  return {
    selectedFile,
    setSelectedFile,
    fileURLBlob,
    setFileURLBlob,
    uploadedFileData,
    isFileLoading,
    fileUploadProgress,
    fileIsUploaded,
    handleRemoveFile
  }
}
