import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import AudienceForm from './AudienceForm'
import LineItemProductRetargetingForm from './LineItemForm'
import AdProductForm from '../../ReusableForms/AdProductForm'

import useContinuousForm from '../../../../../../hooks/formHooks/useContinuousForm'

export const AUDIENCE_PRODUCT_RETARGETING_FORM = 'audience_product_retargeting_form'
export const LINE_ITEM_PRODUCT_RETARGETING_FORM = 'line_item_product_retargeting_form'
export const AD_PRODUCT_FORM = 'ad_product_form'

const ProductRetargetingRoute = ({ adAccountId, onSuccessAdFormSubmit, setActivatedInternalForm }) => {
  const { activatedForm, setActivatedForm } = useContinuousForm({
    initialForm: AUDIENCE_PRODUCT_RETARGETING_FORM
  })

  const handleSetActivatedForm = useCallback(
    formName => {
      setActivatedForm(formName)
      // set internal selected form for progress representation
      setActivatedInternalForm(formName)
    },
    [setActivatedForm, setActivatedInternalForm]
  )

  switch (activatedForm) {
    case LINE_ITEM_PRODUCT_RETARGETING_FORM:
      return (
        <LineItemProductRetargetingForm
          adAccountId={adAccountId}
          onSuccessSubmit={() => {
            handleSetActivatedForm(AD_PRODUCT_FORM)
          }}
        />
      )
    case AD_PRODUCT_FORM:
      return (
        <AdProductForm
          activeStepNumber={3}
          customStepsLength={4}
          onSuccessSubmit={onSuccessAdFormSubmit}
          allowMediaUpload={false}
          adAccountId={adAccountId}
        />
      )
    case AUDIENCE_PRODUCT_RETARGETING_FORM:
    default:
      return (
        <AudienceForm
          adAccountId={adAccountId}
          onSuccessSubmit={() => {
            handleSetActivatedForm(LINE_ITEM_PRODUCT_RETARGETING_FORM)
          }}
        />
      )
  }
}

ProductRetargetingRoute.propTypes = {
  adAccountId: PropTypes.string.isRequired,
  onSuccessAdFormSubmit: PropTypes.func.isRequired,
  setActivatedInternalForm: PropTypes.func.isRequired
}

export default ProductRetargetingRoute
