import { call, put, all, takeEvery, select } from 'redux-saga/effects'

import {
  GET_FACEBOOK_PAGES,
  getFacebookPagesSuccess,
  getFacebookPagesFailure,
  GET_ACCESSIBLE_FACEBOOK_PAGES,
  getAccessibleFacebookPagesSuccess,
  getAccessibleFacebookPagesFailure,
  ADD_ACCESSIBLE_FACEBOOK_PAGES,
  addAccessibleFacebookPagesSuccess,
  addAccessibleFacebookPagesFailure,
  DELETE_ACCESSIBLE_FACEBOOK_PAGES,
  deleteAccessibleFacebookPagesSuccess,
  deleteAccessibleFacebookPagesFailure,
  CREATE_FACEBOOK_PAGE_ACCESS_REQUEST,
  createFacebookPageAccessRequestSuccess,
  createFacebookPageAccessRequestFailure,
  GET_INSTAGRAM_ACCOUNTS,
  getInstagramAccountsSuccess,
  getInstagramAccountsFailure,
  GET_FACEBOOK_PAGE_ACCESS_TOKEN,
  getFacebookPageAccessTokenSuccess,
  getFacebookPageAccessTokenFailure,
  getBackedInstagramAccountsSuccess,
  getBackedInstagramAccountsFailure,
  GET_BACKED_INSTAGRAM_ACCOUNTS
} from '../actions/socialAccounts'

import {
  getFacebookPagesService,
  getInstagramAccountsService,
  getFacebookPageAccessTokenService,
  getBackedInstagramAccountsService,
  getAccessibleFacebookPagesService,
  addAccessibleFacebookPagesService,
  deleteAccessibleFacebookPagesService,
  createFacebookPageAccessRequestService
} from '../services/socialAccounts'

import { selectedSelfAccountSelector } from '../selectors/app'

function* socialAccountsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_FACEBOOK_PAGES, getFacebookPagesWorker),
    takeEvery(GET_ACCESSIBLE_FACEBOOK_PAGES, getAccessibleFacebookPagesWorker),
    takeEvery(ADD_ACCESSIBLE_FACEBOOK_PAGES, addAccessibleFacebookPagesWorker),
    takeEvery(DELETE_ACCESSIBLE_FACEBOOK_PAGES, deleteAccessibleFacebookPagesWorker),
    takeEvery(CREATE_FACEBOOK_PAGE_ACCESS_REQUEST, createFacebookPageAccessRequestWorker),
    takeEvery(GET_FACEBOOK_PAGE_ACCESS_TOKEN, getFacebookPageAccessTokenWorker),
    takeEvery(GET_INSTAGRAM_ACCOUNTS, getInstagramAccountsWorker),
    takeEvery(GET_BACKED_INSTAGRAM_ACCOUNTS, getBackedInstagramAccountsWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getFacebookPagesWorker() {
  try {
    const response = yield call(getFacebookPagesService)
    yield put(getFacebookPagesSuccess(response))
  } catch (e) {
    yield put(getFacebookPagesFailure(e))
  }
}

function* getAccessibleFacebookPagesWorker() {
  try {
    const selectedSelfAccountId = yield select(selectedSelfAccountSelector)

    const params = {
      account: selectedSelfAccountId
    }

    const response = yield call(getAccessibleFacebookPagesService, params)

    yield put(getAccessibleFacebookPagesSuccess(response))
  } catch (e) {
    yield put(getAccessibleFacebookPagesFailure(e))
  }
}

function* addAccessibleFacebookPagesWorker({ pageData }) {
  try {
    const response = yield call(addAccessibleFacebookPagesService, pageData)

    yield put(addAccessibleFacebookPagesSuccess(response))
  } catch (e) {
    yield put(addAccessibleFacebookPagesFailure(e))
  }
}

function* deleteAccessibleFacebookPagesWorker({ id }) {
  try {
    yield call(deleteAccessibleFacebookPagesService, id)

    yield put(deleteAccessibleFacebookPagesSuccess(id))
  } catch (e) {
    yield put(deleteAccessibleFacebookPagesFailure(e))
  }
}

function* createFacebookPageAccessRequestWorker({ data }) {
  try {
    const response = yield call(createFacebookPageAccessRequestService, data)
    yield put(createFacebookPageAccessRequestSuccess(response))
  } catch (error) {
    yield put(createFacebookPageAccessRequestFailure(error))
  }
}
function* getFacebookPageAccessTokenWorker({ facebookPageId, adAccountId }) {
  try {
    const response = yield call(getFacebookPageAccessTokenService, facebookPageId, adAccountId)
    yield put(getFacebookPageAccessTokenSuccess(response, facebookPageId))
  } catch (e) {
    yield put(getFacebookPageAccessTokenFailure(e, facebookPageId))
  }
}

function* getInstagramAccountsWorker({ facebookPageId, accessToken }) {
  try {
    const response = yield call(getInstagramAccountsService, facebookPageId, accessToken)
    yield put(getInstagramAccountsSuccess(response, facebookPageId))
  } catch (e) {
    yield put(getInstagramAccountsFailure(e, facebookPageId))
  }
}

function* getBackedInstagramAccountsWorker({ facebookPageId, accessToken }) {
  try {
    const response = yield call(getBackedInstagramAccountsService, facebookPageId, accessToken)
    yield put(getBackedInstagramAccountsSuccess(response, facebookPageId))
  } catch (e) {
    yield put(getBackedInstagramAccountsFailure(e))
  }
}

export default socialAccountsWatcher
