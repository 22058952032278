import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import RadioBlock from '../../../../../components/Form/RadioBlock'
import Button from '../../../../../components/Button'

import { FACEBOOK_USERS, ORIGIN_OPTION, SUPERMARKET, YOUR_WEBSITE } from '../fields'

import { selectedControllerDataSelector } from '../../../../../modules/selectors/app'

import useStyles from '../../../../../styles/common/stepForms'

const OriginStep = ({ formik, handleStepChange }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { values, setFieldValue } = formik

  const selectedSelfAccountController = useSelector(selectedControllerDataSelector)
  const selectedSelfAccountControllerName = selectedSelfAccountController?.name

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create an audience')}</div>
      <div className={classes.stepTitle}>{t('Where will the audience come from?')}</div>
      <div className={classes.stepBody}>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_audience_supermarket"
          name={ORIGIN_OPTION}
          value={SUPERMARKET}
          selectedValue={values[ORIGIN_OPTION]}
          label={selectedSelfAccountControllerName ? selectedSelfAccountControllerName : t('Supermarket')}
        />
        {/* todo Airline is temporary hidden, uncomment it when it's needed*/}
        {/*{allowAirlineRoute && (*/}
        {/*  <RadioBlock*/}
        {/*    setFieldValue={setFieldValue}*/}
        {/*    id="radio_audience_airline"*/}
        {/*    name={ORIGIN_OPTION}*/}
        {/*    value={AIRLINE}*/}
        {/*    selectedValue={values[ORIGIN_OPTION]}*/}
        {/*    label={t('Airline')}*/}
        {/*  />*/}
        {/*)}*/}
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_audience_from_your_website"
          name={ORIGIN_OPTION}
          value={YOUR_WEBSITE}
          selectedValue={values[ORIGIN_OPTION]}
          label={t('Your website')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_audience_from_facebook_users"
          name={ORIGIN_OPTION}
          value={FACEBOOK_USERS}
          selectedValue={values[ORIGIN_OPTION]}
          label={t('Users that have interacted with your Facebook page')}
        />
        {/* todo uncomment this option when instagram is implemented */}
        {/*<RadioBlock*/}
        {/*  setFieldValue={setFieldValue}*/}
        {/*  id="radio_audience_from_instagram_users"*/}
        {/*  name={ORIGIN_OPTION}*/}
        {/*  value={INSTAGRAM_USERS}*/}
        {/*  selectedValue={values[ORIGIN_OPTION]}*/}
        {/*  label={t('Users that have interacted with your Instagram account')}*/}
        {/*/>*/}
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" className="dark" onClick={() => handleStepChange()}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default OriginStep
