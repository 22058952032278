function handleAddressesErrors(addresses, validatedAddresses, setAddressesErrorIndexes) {
  const errorRowsIndexes = []

  // loop over validated addresses and find indexes for items with errors in formik data
  validatedAddresses.forEach(validatedAddress => {
    if (validatedAddress.error) {
      const errorRowIndex = addresses.findIndex(address => {
        return address[1] && address[1].value === validatedAddress['address']
      })
      errorRowIndex >= 0 && errorRowsIndexes.push(errorRowIndex)
    }
  })

  setAddressesErrorIndexes(errorRowsIndexes)
}

export default handleAddressesErrors
