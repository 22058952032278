import { formatDate } from 'date-fns'
import * as React from 'react'

import useStyles, { StyleClasses } from './styles'

interface RenderDateLabelProps {
  date: Date
  renderDateLabel?: (date: Date) => JSX.Element
  dateFormat: string
}

const RenderDateLabel: React.FC<RenderDateLabelProps> = ({ date, renderDateLabel, dateFormat }) => {
  const classes = useStyles() as StyleClasses // Use TypeScript assertion here

  if (renderDateLabel) {
    return renderDateLabel(date)
  } else {
    return <h2 className={classes.subtitle}>{formatDate(date, dateFormat as string)}</h2>
  }
}

export default RenderDateLabel
