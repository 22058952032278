import { createStyles } from '../../../../styles/helpers'
import { lightGrey2 } from '../../../../styles/const/colors'

const useStyles = createStyles({
  fileTypeIcon: {
    position: 'relative',
    color: lightGrey2
  },
  fileFormat: {
    width: '100%',
    position: 'absolute',
    fontSize: 7,
    bottom: 6,
    fontStyle: 'normal',
    display: 'block',
    textAlign: 'center',
    fontWeight: '700'
  }
})

export default useStyles
