import * as Yup from 'yup'

import { nameValidation } from '../../../../../../pages/Settings/AdvertiserAccounts/ClientAccountForms/fields'
import { PRICE_PAIRS, pricePairsValidation } from '../../../../../ReusableFormFields/PricePairFields/fields'
import {
  CREATIVE_DEADLINE_DAYS,
  DEFAULT_INVENTORY_DATE_START,
  PLATFORMS
} from '../../../MediaProductCreate/MediaProductCreateForm/fields'
import { filesRequirementsValidation } from '../../../MediaProductCreate/MediaProductCreateForm/validation'
import { inventoryValidationTest, minMaxPeriodsValidation, platformsValidation } from '../../../validation'
import { INVENTORY_AMOUNT, INVENTORY_PERIOD } from '../../../fields'
import { imagesValidation } from '../../sections/ImagesSection/validation'

export const validationSchema = Yup.object({
  ...nameValidation,
  [PRICE_PAIRS]: pricePairsValidation,
  [CREATIVE_DEADLINE_DAYS]: Yup.number().required('Creative Deadline Days Required'),
  [PLATFORMS]: platformsValidation,
  ...imagesValidation,
  [INVENTORY_AMOUNT]: inventoryValidationTest(INVENTORY_AMOUNT, 'Inventory required'),
  [INVENTORY_PERIOD]: inventoryValidationTest(INVENTORY_PERIOD, 'Number of periods required'),
  [DEFAULT_INVENTORY_DATE_START]: inventoryValidationTest(DEFAULT_INVENTORY_DATE_START, 'Start Date Required'),
  ...filesRequirementsValidation,
  ...minMaxPeriodsValidation
})
