import { createStyles } from '../../../../styles/helpers'

import { transitionShort } from '../../../../styles/const/common'
import { lightGrey } from '../../../../styles/const/colors'
import { phonesDown } from '../../../../styles/const/breakpoints'

const useStyles = createStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    top: 48,
    columnGap: 9,
    marginBottom: 20
  },
  stepItem: {
    height: 6,
    width: '100%',
    borderRadius: theme.bigBorderRadius,
    maxWidth: 70,
    transition: transitionShort,
    background: lightGrey,
    '&.passed': {
      background: theme.brandPrimary
    }
  },
  [`@media screen and (${phonesDown})`]: {
    stepItem: {
      maxWidth: 'initial'
    }
  }
}))

export default useStyles
