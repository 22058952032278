import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Button from '../../../../components/Button'

import { openForm } from '../../../../modules/actions/forms'
import { mediaPackageCategoriesSelector, mediaPackagesSelector } from '../../../../modules/selectors/mediaPackages'

import { MEDIA_PACKAGE_CREATE } from '../../../../constants/forms'

const CreatePackageBtn = ({ className }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const openPackageFormHandler = useCallback(() => dispatch(openForm({ formName: MEDIA_PACKAGE_CREATE })), [dispatch])

  const mediaPackages = useSelector(mediaPackagesSelector)
  const mediaCategories = useSelector(mediaPackageCategoriesSelector)

  const isDisabled = !mediaPackages.length && !mediaCategories.length

  return (
    <Button className={className} onClick={openPackageFormHandler} solid disabled={isDisabled}>
      {t('Create package')}
    </Button>
  )
}

CreatePackageBtn.propTypes = {
  className: PropTypes.string
}

export default CreatePackageBtn
