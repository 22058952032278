import * as Yup from 'yup'
import { t } from 'i18next'

export const FB_BUSINESS_ID = 'fb_business_id'
export const FB_PIXEL_ID = 'fb_pixel_id'
export const MFA_IS_REQUIRED = 'mfa_is_required'

export const ALLOW_MULTI_LANGUAGE = 'display_language_selection'
export const SELECTED_LANGUAGES = 'display_languages'
export const PROFORMA = 'proforma'
export const MEDIA_KIT_FILE = 'media_kit_file'
export const MEDIA_KIT_FILE_NAME = 'media_kit_file_name'

export const getInitialValues = (controller, languagesList) => {
  return {
    [FB_BUSINESS_ID]: controller[FB_BUSINESS_ID] || '',
    [FB_PIXEL_ID]: controller[FB_PIXEL_ID] || '',
    [MFA_IS_REQUIRED]: controller[MFA_IS_REQUIRED] || false,
    [ALLOW_MULTI_LANGUAGE]: controller[ALLOW_MULTI_LANGUAGE] || false,
    [SELECTED_LANGUAGES]:
      controller[SELECTED_LANGUAGES]?.map(languageCode => languagesList.find(({ value }) => languageCode === value)) ||
      [],
    [PROFORMA]: controller[PROFORMA] || false,
    [MEDIA_KIT_FILE]: controller[MEDIA_KIT_FILE] || '',
    [MEDIA_KIT_FILE_NAME]: controller[MEDIA_KIT_FILE_NAME] || ''
  }
}

export const transformValuesToBE = values => ({
  [FB_BUSINESS_ID]: values[FB_BUSINESS_ID],
  [FB_PIXEL_ID]: values[FB_PIXEL_ID],
  [MFA_IS_REQUIRED]: values[MFA_IS_REQUIRED],
  [ALLOW_MULTI_LANGUAGE]: values[ALLOW_MULTI_LANGUAGE],
  [SELECTED_LANGUAGES]: values[ALLOW_MULTI_LANGUAGE] ? values[SELECTED_LANGUAGES].map(({ value }) => value) : [],
  [PROFORMA]: values[PROFORMA],
  [MEDIA_KIT_FILE]: values[MEDIA_KIT_FILE],
  [MEDIA_KIT_FILE_NAME]: values[MEDIA_KIT_FILE_NAME]
})

export const validationSchema = Yup.object().shape({
  [SELECTED_LANGUAGES]: Yup.array().when(ALLOW_MULTI_LANGUAGE, {
    is: true,
    then: () => Yup.array().min(1, t('Please add at least one language'))
  })
})
