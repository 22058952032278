import React from 'react'
import Skeleton from 'react-loading-skeleton'

const LoadMoreItemsSkeleton = () => {
  return (
    <>
      <Skeleton height={38} style={{ marginBottom: 8 }} />
      <Skeleton height={38} style={{ marginBottom: 8 }} />
      <Skeleton height={38} style={{ marginBottom: 8 }} />
      <Skeleton height={38} style={{ marginBottom: 8 }} />
      <Skeleton height={38} style={{ marginBottom: 8 }} />
    </>
  )
}

export default LoadMoreItemsSkeleton
