import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Button from '../../../components/Button'
import Can from '../../../features/components/Can'
import InternalLink from '../../../components/InternalLink'
import Breadcrumbs from '../../../features/components/Breadcrumbs'
import StatisticBlocksGrid from '../../../features/components/StatisticBlocksGrid'
import SkeletonStatisticBlocksGrid from '../../../features/components/StatisticBlocksGrid/Skeleton'

import { ReactComponent as LayersIcon } from '../../../assets/icons/layers.svg'

import { formatCurrency } from '../../../helpers/numbers'

import {
  selectedControllerCurrencySymbolSelector,
  selectedControllerDataSelector
} from '../../../modules/selectors/app'
import {
  quotationsCountSelector,
  quotationsIsLoadingSelector,
  quotationsTotalValueSelector
} from '../../../modules/selectors/quotations'

import { ROUTES } from '../../../constants/routes'

import useCommonStyles from '../../../styles/common/listPage'
import useStyles from './styles'

const ProposalsPageHeader = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const totalCount = useSelector(quotationsCountSelector)
  const totalCost = useSelector(quotationsTotalValueSelector)
  const selectedController = useSelector(selectedControllerDataSelector)
  const quotationIsLoading = useSelector(quotationsIsLoadingSelector)
  const controllerCurrencySymbol = useSelector(selectedControllerCurrencySymbolSelector)

  // show only initial loading, as the totals will not change when new items are loading
  const isLoading = quotationIsLoading && !totalCount

  const breadcrumbs = [
    {
      title: selectedController?.name,
      url: ''
    },
    {
      title: 'Proposals List',
      Icon: LayersIcon,
      url: ''
    }
  ]

  const totalStats = useMemo(
    () => [
      {
        label: 'Proposals',
        value: totalCount
      },
      {
        label: 'Total Value',
        value: formatCurrency(totalCost, { min: 0, max: 0 }, { symbol: controllerCurrencySymbol })
      }
    ],
    [totalCount, totalCost, controllerCurrencySymbol]
  )

  return (
    <>
      <div className={commonClasses.heading}>
        <div>
          <Breadcrumbs routes={breadcrumbs} />
          <h3 className={commonClasses.title}>{t('Proposals')}</h3>
        </div>
        <Can I={'manage'} a={ROUTES.proposalsCreate}>
          <div className={classes.quotationsActions}>
            <InternalLink to={ROUTES.proposalsCreate}>
              <Button solid>{t('New Proposal')}</Button>
            </InternalLink>
          </div>
        </Can>
      </div>
      <div className={classes.proposalsActions}>
        {isLoading ? <SkeletonStatisticBlocksGrid blocks={totalStats} /> : <StatisticBlocksGrid blocks={totalStats} />}
      </div>
    </>
  )
}

export default ProposalsPageHeader
