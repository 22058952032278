import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import MultiSelectBox from '../../../features/components/Form/MultiSelectBox'

import {
  choicesLanguagesLoadingSelector,
  choicesLanguagesSelector,
  choicesLanguagesWasLoadedSelector
} from '../../../modules/selectors/choices'
import { getChoicesLanguages } from '../../../modules/actions/choices'
import { selectedAdAccountIdSelector } from '../../../modules/selectors/app'

const FacebookLanguages = ({ name, value, setFieldValue }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const languages = useSelector(choicesLanguagesSelector)
  const languagesLoading = useSelector(choicesLanguagesLoadingSelector)
  const languagesWasLoaded = useSelector(choicesLanguagesWasLoadedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const formattedLanguages = useMemo(
    () =>
      languages &&
      languages.map(item => {
        return { value: item.key, label: item.name }
      }),
    [languages]
  )

  useEffect(() => {
    // get options for LANGUAGES
    if (!languagesWasLoaded) {
      dispatch(getChoicesLanguages({ account: selectedAdAccountId }))
    }
  }, [dispatch, languagesWasLoaded, selectedAdAccountId])

  return (
    <MultiSelectBox
      placeholder={t('Select language')}
      name={name}
      options={formattedLanguages}
      value={value}
      isLoading={languagesLoading}
      setFieldValue={setFieldValue}
    />
  )
}

export default FacebookLanguages
