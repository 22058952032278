import { createStyles } from '../../styles/helpers'

import { darkGrey, grey, lightGrey, lightGrey2, red } from '../../styles/const/colors'

const useStyles = createStyles({
  tableWrapper: {
    maxHeight: 309,
    overflow: 'auto',
    lineHeight: 0
  },
  table: {
    width: '100%',
    fontSize: 12,
    '& .Spreadsheet__cell, & .Spreadsheet__header': {
      height: 28,
      borderColor: lightGrey,
      color: darkGrey,
      verticalAlign: 'middle',
      '&:nth-child(2)': {
        width: '30%'
      },
      '&:nth-child(3)': {
        width: '70%'
      }
    },
    '& .Spreadsheet__cell': {
      background: 'white'
    },
    '& .Spreadsheet__header': {
      background: lightGrey2,
      minWidth: 0,
      padding: '0 12px',
      position: 'relative',
      overflow: 'visible',
      '&.error': {
        color: red
      }
    }
  },
  errorHighlighter: {
    height: 'calc(100% + 2px)',
    width: 'calc(100% + 2px)',
    position: 'absolute',
    top: -1,
    border: `1px solid ${red}`,
    left: -1
  },
  addNewRowButton: {
    color: grey,
    border: 'none',
    display: 'block',
    margin: '16px auto 0'
  }
})

export default useStyles
