import { createStyles } from '../../../../../../styles/helpers'

import { mediumGrey } from '../../../../../../styles/const/colors'
import { zIndex1 } from '../../../../../../styles/const/common'

export default createStyles(theme => ({
  signatureDrawerContainer: {
    position: 'relative',
    paddingLeft: 61, // width of the signHereIcon + left position
    borderRadius: theme.defaultBorderRadius,
    border: `1px solid ${mediumGrey}`,
    background: 'white'
  },
  signHereIcon: {
    width: 56,
    position: 'absolute',
    left: 5,
    bottom: 37,
    pointerEvents: 'none'
  },
  signElement: {
    width: '100%',
    height: '180px'
  },
  clearButton: {
    position: 'absolute',
    top: 15,
    right: 15,
    zIndex: zIndex1
  },
  horizontalLine: {
    position: 'absolute',
    width: '100%',
    bottom: 40,
    left: 0,
    margin: 0,
    border: 'none',
    height: '1px',
    backgroundColor: 'black',
    pointerEvents: 'none'
  },
  terms: {
    marginTop: 32,
    textAlign: 'center'
  },
  applyBtn: {
    marginTop: 16
  }
}))
