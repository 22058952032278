import { createStyles } from '../../../../styles/helpers'

import { bannerGrey, mediumGrey } from '../../../../styles/const/colors'
import { ID_COL_WIDTH, NAME_COL_WIDTH } from '../constatns'

export default createStyles({
  row: {
    display: 'table-row',
    transition: 'height 0.2s ease',
    outline: 'none',
    '&:last-child td': {
      borderBottom: 'none'
    }
  },
  eventsContainerCol: {
    position: 'relative'
  },
  innerCol: {
    border: `1px solid ${bannerGrey}`,
    padding: '11px',
    minHeight: '40px',
    display: 'table-cell',
    borderLeft: '0px solid transparent',
    borderTop: '0px solid transparent',
    '&:last-child': {
      borderRight: '0px solid transparent'
    }
  },
  headerCol: {
    border: `1px solid ${bannerGrey}`,
    borderLeft: '0px solid transparent',
    borderTop: '0px solid transparent',
    display: 'table-cell',
    position: 'relative',
    backgroundClip: 'border-box',
    transition: 'height 0.2s ease',
    verticalAlign: 'baseline',
    '&:last-child': {
      borderBottom: 'none'
    },
    borderBottom: '0px solid transparent'
  },
  nameContainer: {
    extend: 'innerCol',
    paddingLeft: '20px',
    border: `1px solid ${mediumGrey}`,
    borderLeft: '0px solid transparent',
    borderTop: '0px solid transparent',
    width: `${NAME_COL_WIDTH}px`
  },
  idContainer: {
    extend: 'innerCol',
    paddingLeft: '20px',
    border: `1px solid ${mediumGrey}`,
    borderLeft: '0px solid transparent',
    borderTop: '0px solid transparent',
    width: `${ID_COL_WIDTH}px`
  },
  groupOpened: {
    transform: 'rotate(180deg)'
  },
  name: {
    whiteSpace: 'normal',
    fontSize: '12px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  id: {
    whiteSpace: 'normal',
    fontSize: '12px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  mainGroupName: {
    paddingLeft: '20px'
  },
  actionBtn: {
    position: 'absolute',
    width: 36,
    border: 'none',
    background: 'transparent',
    right: 0,
    margin: 'auto',
    cursor: 'pointer',
    outline: 'none'
  }
})
