import React from 'react'

import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

const MediaOrderHistoryListSkeleton = () => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.historyItemSkeleton}>
        <Skeleton height={17} width={170} style={{ marginBottom: 4 }} />
        <Skeleton height={20} width={370} />
      </div>

      <div className={classes.historyItemSkeleton}>
        <Skeleton height={17} width={170} style={{ marginBottom: 4 }} />
        <Skeleton height={20} width={370} />
      </div>

      <div className={classes.historyItemSkeleton}>
        <Skeleton height={17} width={170} style={{ marginBottom: 4 }} />
        <Skeleton height={20} width={370} />
      </div>

      <div className={classes.historyItemSkeleton}>
        <Skeleton height={17} width={170} style={{ marginBottom: 4 }} />
        <Skeleton height={20} width={370} />
      </div>
    </>
  )
}

export default MediaOrderHistoryListSkeleton
