import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'

import Form from '../../../../../components/Form'
import ProductVariableFormContent from '../../MediaProductVariableCreate/MediaProductVariableCreateForm/ProductVariableFormContent'

import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'
import { transformValuesToBE } from './formatters'

import {
  mediaProductVariableOptionsSelector,
  updateMediaProductVariableErrorSelector,
  updateMediaProductVariableIsLoadingSelector,
  updateMediaProductVariableWasUpdatedSelector
} from '../../../../../modules/selectors/mediaOrdersProductVariables'
import {
  clearUpdateMediaProductVariable,
  updateMediaProductVariable
} from '../../../../../modules/actions/mediaOrdersProductVariables'

import { MEDIA_PRODUCT_VARIABLE_EDIT } from '../../../../../constants/forms'
import { getInitialValues } from './fields'
import { validationSchema } from '../../validation'

function MediaProductVariableEditForm({ editProductVariableData, loadMoreVariableOptionsHandler }) {
  const dispatch = useDispatch()

  const { id: productVariableId } = editProductVariableData

  const productVariableWasUpdated = useSelector(updateMediaProductVariableWasUpdatedSelector)

  const mediaProductVariableOptions = useSelector(mediaProductVariableOptionsSelector(productVariableId))

  const initialValues = useMemo(
    () => getInitialValues(editProductVariableData, mediaProductVariableOptions),
    [editProductVariableData, mediaProductVariableOptions]
  )

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(initialValues, values)
      dispatch(updateMediaProductVariable(transformedData, productVariableId))
    },
    [initialValues, dispatch, productVariableId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const clearHandler = useCallback(() => {
    dispatch(clearUpdateMediaProductVariable())
  }, [dispatch])

  return (
    <Form
      formik={purifiedFormik}
      formName={MEDIA_PRODUCT_VARIABLE_EDIT}
      successSubmit={productVariableWasUpdated}
      errorSelector={updateMediaProductVariableErrorSelector}
      isLoadingSelector={updateMediaProductVariableIsLoadingSelector}
      clearHandler={clearHandler}
    >
      <ProductVariableFormContent
        formik={formik}
        loadMoreVariableOptionsHandler={loadMoreVariableOptionsHandler}
        productVariableId={productVariableId}
      />
    </Form>
  )
}

export default MediaProductVariableEditForm
