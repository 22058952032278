import React, { useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'

import ProductSetup from './ProductSetup'
import ProductSelection from './ProductSelection'
import InfoModal from '../../../../../../../features/components/Modals/InfoModal'

import { SelectedMediaProductsContext } from '../SelectedMediaProductsContext'

import { ADDED_PRODUCT, getInitialProductValues } from './fields'

import { REPRESENTATIVE } from '../../../../../../../constants/mediaOrders'
import { initialValues, validationSchema } from './fields'
import { PRODUCT_PERIODS_DATES, QUANTITY } from '../../../../fields'

import useStyles from './styles'

function AddNewProductModal({ showSelectProductModal, onModalClose }) {
  const [selectedProduct, setSelectedProduct] = useState(null)

  const classes = useStyles()

  const {
    selectedProductsData,
    setSelectedProductsData,
    contractSelectedProducts,
    setContractSelectedProducts,
    newProductRef
  } = useContext(SelectedMediaProductsContext)

  const closeModalHandler = useCallback(() => {
    onModalClose && onModalClose()
  }, [onModalClose])

  const handleProductValuesAdd = useCallback(
    productValues => {
      newProductRef.current = productValues
      setContractSelectedProducts([...contractSelectedProducts, productValues])
    },
    [contractSelectedProducts, newProductRef, setContractSelectedProducts]
  )

  const onSubmit = useCallback(
    values => {
      // todo refactor product formatting
      const addedProduct = values[ADDED_PRODUCT]

      const selectedPeriods = addedProduct[PRODUCT_PERIODS_DATES]
      const periodDates = selectedPeriods.map(({ value }) => value)
      const periodsPrice = selectedPeriods.map(({ price, value }) => ({ date_start: value, price }))

      const newProductValues = {
        id: addedProduct.id,
        [PRODUCT_PERIODS_DATES]: periodDates,
        periods_price: periodsPrice,
        [QUANTITY]: addedProduct[QUANTITY],
        [REPRESENTATIVE]: addedProduct[REPRESENTATIVE]
      }

      handleProductValuesAdd(newProductValues)
      setSelectedProduct(null)
      setSelectedProductsData([...selectedProductsData, selectedProduct])
      onModalClose()
    },
    [handleProductValuesAdd, onModalClose, selectedProduct, selectedProductsData, setSelectedProductsData]
  )

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema
  })
  const { setFieldValue } = formik

  const onProductSelect = useCallback(
    product => {
      setSelectedProduct(product)
      const productValues = getInitialProductValues(product)

      setFieldValue(ADDED_PRODUCT, productValues)
    },
    [setFieldValue]
  )

  return (
    <InfoModal
      isOpen={showSelectProductModal}
      onClose={closeModalHandler}
      className={classes.modal}
      classNameBody={classes.modalBody}
      contentClassName={classes.modalContent}
    >
      <div className={classes.container}>
        {selectedProduct ? (
          <ProductSetup formik={formik} selectedProduct={selectedProduct} onBack={() => setSelectedProduct(null)} />
        ) : (
          <ProductSelection onProductSelect={onProductSelect} />
        )}
      </div>
    </InfoModal>
  )
}

AddNewProductModal.propTypes = {
  showSelectProductModal: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired
}
export default AddNewProductModal
