import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Field from '../../../../components/Form/Field'

import useStyles from '../styles'

function MarginField({ formik, name, label }) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Field
      type="number"
      formik={formik}
      name={name}
      className={classes.numberField}
      placeholder={t(label || 'Margin')}
      autoComplete="off"
      symbol="%"
      symbolPosition="end"
      decimalScale={2}
    />
  )
}

MarginField.propTypes = {
  formik: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string
}

export default MarginField
