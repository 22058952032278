import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import ConditionalWrapper from '../../hoc/ConditionalWrapper'

import useStyles from './styles'

export const loaderClassName = 'loader'
function Loader({ show = true, withOverlay, className }) {
  const classes = useStyles({ loaderWidth: 100 })

  return (
    show && (
      <ConditionalWrapper
        condition={!!withOverlay}
        wrapper={children => <div className={classes.overlay}>{children}</div>}
      >
        <div className={classnames(loaderClassName, classes.loader, className)}>
          <svg className="circular" viewBox="25 25 50 50">
            <circle r="20" cx="50" cy="50" fill="none" className="path" strokeWidth="2" strokeMiterlimit="10" />
          </svg>
        </div>
      </ConditionalWrapper>
    )
  )
}

export default Loader

Loader.propTypes = {
  show: PropTypes.bool,
  withOverlay: PropTypes.bool,
  className: PropTypes.string
}
