import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  brandCategorySelect: {
    minWidth: 160,
    maxWidth: 160
  },
  brandSubCategorySelect: {
    minWidth: 190,
    maxWidth: 190
  }
})

export default useStyles
