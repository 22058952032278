import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles({
  warningContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 24,
    padding: '16px 24px',
    textAlign: 'center'
  },
  warningTitle: {
    marginBottom: 10
  }
})

export default useStyles
