import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import useStyles from './styles'

const CardContent = ({ image, customImagesSection, contentClassName, altText }) => {
  const classes = useStyles()

  return (
    <div className={classnames(classes.content, contentClassName)}>
      {image && <img src={image} className={classes.image} alt={altText} />}
      {customImagesSection && customImagesSection}
    </div>
  )
}

CardContent.propTypes = {
  image: PropTypes.string,
  customImagesSection: PropTypes.node,
  contentClassName: PropTypes.string,
  altText: PropTypes.string
}

export default CardContent
