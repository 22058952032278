import update from 'immutability-helper'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  SET_LINE_ITEM_TRIGGERS_FILTER,
  CLEAR_LINE_ITEM_TRIGGERS_FILTER,
  GET_TRIGGERS,
  GET_TRIGGERS_SUCCESS,
  GET_TRIGGERS_FAILURE,
  CLEAR_TRIGGERS,
  CREATE_TRIGGER,
  CREATE_TRIGGER_SUCCESS,
  CREATE_TRIGGER_FAILURE,
  CLEAR_CREATE_TRIGGER,
  UPDATE_TRIGGER,
  UPDATE_TRIGGER_SUCCESS,
  UPDATE_TRIGGER_FAILURE,
  CLEAR_UPDATE_TRIGGER,
  DELETE_TRIGGER,
  DELETE_TRIGGER_SUCCESS,
  DELETE_TRIGGER_FAILURE,
  CLEAR_DELETE_TRIGGER
} from '../actions/triggers'
import {
  INITIAL_GET_RESULTS_PAGING_REDUCER,
  INITIAL_CREATE_REDUCER,
  INITIAL_UPDATE_REDUCER,
  INITIAL_DELETE_REDUCER
} from '../../constants/reducer'
import { deleteItemById, updateItem } from '../../helpers/modules/reducerHelpers'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  lineItemFilter: {},
  triggers: INITIAL_GET_RESULTS_PAGING_REDUCER,
  createTrigger: INITIAL_CREATE_REDUCER,
  updateTrigger: INITIAL_UPDATE_REDUCER,
  deleteTrigger: INITIAL_DELETE_REDUCER
}

export default function triggers(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    case SET_LINE_ITEM_TRIGGERS_FILTER:
      return update(state, {
        lineItemFilter: {
          $set: {
            id: action.lineItemId,
            name: action.lineItemName
          }
        }
      })
    case CLEAR_LINE_ITEM_TRIGGERS_FILTER:
      return update(state, {
        lineItemFilter: {
          $set: initialState.lineItemFilter
        }
      })
    case GET_TRIGGERS:
      return update(state, {
        triggers: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_TRIGGERS_SUCCESS:
      return update(state, {
        triggers: {
          results: {
            // not replace, but add items to existing data
            $push: action.triggersData.results
          },
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: {
              next: action.triggersData.next
            }
          }
        }
      })
    case GET_TRIGGERS_FAILURE:
      return update(state, {
        triggers: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_TRIGGERS:
      return update(state, {
        triggers: {
          $set: initialState.triggers
        }
      })
    case CREATE_TRIGGER:
      return update(state, {
        createTrigger: {
          $merge: {
            isLoading: true
          }
        }
      })
    case CREATE_TRIGGER_SUCCESS:
      return update(state, {
        createTrigger: {
          $merge: {
            isLoading: false,
            wasCreated: true
          }
        },
        triggers: {
          results: {
            $push: [action.triggerData]
          }
        }
      })
    case CREATE_TRIGGER_FAILURE:
      return update(state, {
        createTrigger: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_TRIGGER:
      return update(state, {
        createTrigger: {
          $set: initialState.createTrigger
        }
      })
    case UPDATE_TRIGGER:
      return update(state, {
        updateTrigger: {
          $merge: {
            isLoading: true,
            [action.id]: true
          }
        }
      })
    case UPDATE_TRIGGER_SUCCESS:
      return update(state, {
        updateTrigger: {
          $merge: {
            isLoading: false,
            wasUpdated: true
          }
        },
        triggers: {
          results: {
            $apply: items => updateItem(items, action.triggerData)
          }
        }
      })
    case UPDATE_TRIGGER_FAILURE:
      return update(state, {
        updateTrigger: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_TRIGGER:
      return update(state, {
        updateTrigger: {
          $set: initialState.updateTrigger
        }
      })
    case DELETE_TRIGGER:
      return update(state, {
        deleteTrigger: {
          $merge: {
            isLoading: true
          }
        }
      })
    case DELETE_TRIGGER_SUCCESS:
      return update(state, {
        triggers: {
          results: {
            $apply: items => deleteItemById(items, action.id)
          }
        },
        deleteTrigger: {
          $merge: {
            isLoading: false,
            id: action.id,
            wasDeleted: true
          }
        }
      })
    case DELETE_TRIGGER_FAILURE:
      return update(state, {
        deleteTrigger: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DELETE_TRIGGER:
      return update(state, {
        deleteTrigger: {
          $set: initialState.deleteTrigger
        }
      })
    default:
      return state
  }
}
