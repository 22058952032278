import React from 'react'
import { useTranslation } from 'react-i18next'

import BudgetSectionForm from './BudgetSectionForm'

import { getBudgetSectionType } from '../../../../../ReusableFormFields/CampaignForms/typeHelpers'

import { NO_BUDGET } from '../../../../../ReusableFormFields/CampaignForms/fields'

import useStyles from '../../../../../../styles/common/drawerForms'

const BudgetSection = ({ editItemData: campaign, ...formProps }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const { budget_lifetime: initialBudgetLifetime, budget_daily: initialBudgetDaily } = campaign
  const budgetType = getBudgetSectionType(initialBudgetLifetime, initialBudgetDaily)

  return budgetType === NO_BUDGET ? (
    <div className={classes.sectionPreview}>
      <p>{t('Budgets for this campaign are set at an ad set level. Please edit your ad sets to manage the budget.')}</p>
    </div>
  ) : (
    <BudgetSectionForm campaign={campaign} budgetType={budgetType} {...formProps} />
  )
}

export default BudgetSection
