import React, { useCallback } from 'react'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import FieldsSection from '../../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../../components/Form/Field'
import AdvancedSettingsFields from './AdvancedSettingsFields'
import LocationSectionContent from '../../../../ReusableFormFIelds/LocationSectionContent'
import MediaCategoryFields from './MediaCategoryFields'

import { DIGITAL, inChargePeriods } from '../../../../../../../constants/mediaOrders'
import { CREATIVE_DEADLINE_DAYS, DESCRIPTION, MEDIA_CATEGORY, NAME, PERIOD_OPTION, PLATFORMS, TAGS } from '../../fields'

const stepFields = [NAME, DESCRIPTION, MEDIA_CATEGORY, PERIOD_OPTION, CREATIVE_DEADLINE_DAYS, TAGS, PLATFORMS]

const DetailsStep = ({ formik, handleStepChange }) => {
  const { values } = formik

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  const { [MEDIA_CATEGORY]: mediaCategory } = values

  const showCreativeDeadlineQuestion = mediaCategory !== DIGITAL

  return (
    <Step
      stepTitle="Enter the product details"
      stepDescription="Please provide the following details for the new media product"
      handleContinue={handleContinue}
    >
      <FieldsSection title="Media product details">
        <FieldRow title="Name" description="Media product name.">
          <Field formik={formik} name={NAME} placeholder="Product name" autoComplete="off" />
        </FieldRow>
        <FieldRow title="Description" description="More details about the product.">
          <Field formik={formik} name={DESCRIPTION} placeholder="Product description" autoComplete="off" isTextarea />
        </FieldRow>
        <FieldRow title="Media Category" description="The category of media.">
          <MediaCategoryFields formik={formik} />
        </FieldRow>
        <FieldRow title="Publication / delivery period" description="The time period that this product is sold in.">
          <Field
            formik={formik}
            name={PERIOD_OPTION}
            options={inChargePeriods}
            placeholder="Delivery period"
            autoComplete="off"
          />
        </FieldRow>
        <FieldRow title="Location (optional)" description="Group media products by location (e.g. by mall, store)">
          <LocationSectionContent formik={formik} />
        </FieldRow>
        {showCreativeDeadlineQuestion && (
          <FieldRow
            title="Creative deadline days"
            description="The number of days before go-live for the creative submission deadline."
          >
            <Field
              formik={formik}
              name={CREATIVE_DEADLINE_DAYS}
              type="number"
              label="Creative deadline days"
              autoComplete="off"
              placeholder="Creative deadline days"
              decimalScale={0}
              allowNegative={false}
            />
          </FieldRow>
        )}
        <AdvancedSettingsFields formik={formik} />
      </FieldsSection>
    </Step>
  )
}

export default DetailsStep
