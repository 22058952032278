import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Chip from '../../../components/Chip'
import Can from '../../../features/components/Can'
import { MobileDown, MobileUp } from '../../../components/hoc/ResponsiveRendering'
import ActionsDropdown from '../../../features/components/ActionsDropdown'
import ListCardItem from '../../../features/components/ListCardItem'
import MinimisedSection from '../../../components/MinimisedSection'
import Button from '../../../components/Button'

import usePermissions from '../../../hooks/usePermissions'

import { insertIf } from '../../../helpers/common'
import { formatNumber } from '../../../helpers/numbers'

import { ROUTES } from '../../../constants/routes'
import { CHIP_COLORS } from '../../../constants/other'

import useCommonStyles from '../../../styles/common/card'
import useStyles from './styles'

function AudienceCard({ audience, deleteAudience }) {
  const { t } = useTranslation()

  const {
    approximate_count_lower_bound: lower,
    approximate_count_upper_bound: upper,
    name,
    subtype,
    account
  } = audience

  const commonClasses = useCommonStyles({})
  const classes = useStyles(lower)

  const permissions = usePermissions()

  //if there are any _ then replace them with spaces
  const replaceWithSpaces = str => {
    return str.replace(/_/i, ' ')
  }
  const formattedSubtype = subtype && replaceWithSpaces(subtype)

  //add thousand comma separator
  const formattedLowerBound = formatNumber(lower, { min: 0, max: 0 })
  const formattedUpperBound = formatNumber(upper, { min: 0, max: 0 })

  const showSize = Number(lower) >= 0 || Number(upper) >= 0

  // if approximate_count_lower_bound = 1000 and approximate_count_upper_bound = 1000 then display as Size: Below 1,000
  const size =
    Number(lower) === 1000 && Number(upper) === 1000
      ? `Size: Below ${formattedLowerBound}`
      : `Size: ${formattedLowerBound} - ${formattedUpperBound}`

  const deleteAudienceHandler = useCallback(() => {
    deleteAudience({ item: audience, account })
  }, [deleteAudience, account, audience])

  const dropdownOptions = [
    ...insertIf(permissions.can('delete', ROUTES.audiences), {
      text: 'Delete',
      onClickHandler: deleteAudienceHandler
    })
  ]

  return (
    <>
      <MobileUp>
        <ListCardItem className={classes.audienceCard}>
          <div className={commonClasses.cardInfo}>
            <div className={commonClasses.cardName}>{name}</div>
            {showSize && <Chip text={size} />}
            {subtype && <Chip text={formattedSubtype} color={CHIP_COLORS.yellow} />}
          </div>
          <ActionsDropdown options={dropdownOptions} />
        </ListCardItem>
      </MobileUp>

      <MobileDown>
        <MinimisedSection
          headerContent={
            <>
              <div className={commonClasses.cardName}>{name}</div>
              {subtype && (
                <Chip text={formattedSubtype} className={commonClasses.cardChip} color={CHIP_COLORS.yellow} />
              )}
              {showSize && <Chip text={size} />}
            </>
          }
        >
          <Can I="delete" a={ROUTES.audiences}>
            <div className={commonClasses.cardButtons}>
              <Button danger onClick={deleteAudienceHandler}>
                {t('Delete')}
              </Button>
            </div>
          </Can>
        </MinimisedSection>
      </MobileDown>
    </>
  )
}

export default AudienceCard
