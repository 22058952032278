import React, { useMemo } from 'react'

import Step from '../../../../../../../../features/components/Forms/StepForm/Step'
import FacebookAdCreativeFields from '../../../../../../ReusableFormFIelds/FacebookAdCreativeFields'
import AdaptiveAdCard from './AdaptiveAdCard'

import { useProductSuggestions } from './hooks'

import { PRODUCT_ID, PRODUCT_DATA } from '../../../../../../../ReusableFormFields/ProductFields/fields'
import {
  BRAND_CATEGORY_PAGE_ID,
  BRAND_CATEGORY_PAGE_DATA
} from '../../../../../../../ReusableFormFields/ProductsAndPagesFields/fields'

const ProductAdStep = ({ formik, isActive, adAccountId, placementPositions }) => {
  const { values } = formik

  const selectedProduct = useMemo(() => {
    if (values[PRODUCT_ID]) {
      return values[PRODUCT_DATA]
    } else if (values[BRAND_CATEGORY_PAGE_ID]) {
      return values[BRAND_CATEGORY_PAGE_DATA]
    } else {
      // need to be object because of used destructring in useAiSuggestionsData
      return {}
    }
  }, [values])

  const { regenerateSuggestions, suggestions, suggestionsLoading } = useProductSuggestions({
    selectedProduct,
    isActiveStep: isActive
  })

  return (
    <Step stepTitle="Review the ad creative">
      {isActive && <AdaptiveAdCard values={values} suggestions={suggestions} suggestionsLoading={suggestionsLoading} />}

      <FacebookAdCreativeFields
        formik={formik}
        regenerateSuggestions={regenerateSuggestions}
        selectedProduct={selectedProduct}
        adAccountId={adAccountId}
        placementPositions={placementPositions}
      />
    </Step>
  )
}

export default ProductAdStep
