import {
  AUDIENCES_OPTION,
  CUSTOM_AUDIENCES_EXCLUSION,
  CUSTOM_AUDIENCES_EXCLUSION_CHECKED
} from '../../../../ReusableFormFields/CustomAudiencesFields/fields'
import { OPTION_YES } from '../../../../../constants/forms'
import { CUSTOM_AUDIENCES } from '../../../../Tiktok/LineItemForms/LineItemTikTokEdit/fields'

export const formatAudiencesToBE = values => {
  const audiencesChecked = values[AUDIENCES_OPTION] === OPTION_YES
  const audiencesExclusionChecked = values[CUSTOM_AUDIENCES_EXCLUSION_CHECKED]

  const includedAudiences = values[CUSTOM_AUDIENCES].map(item => ({
    settings: [
      {
        firstAndThirdPartyAudienceId: item.value
      }
    ]
  }))

  const excludedAudience = {
    settings: values[CUSTOM_AUDIENCES_EXCLUSION].map(item => ({
      firstAndThirdPartyAudienceId: item.value
    }))
  }

  const targetingOptions = [
    {
      ...(audiencesChecked && { included_first_and_third_party_audience_groups: includedAudiences }),
      ...(audiencesExclusionChecked && { excluded_first_and_third_party_audience_group: excludedAudience })
    }
  ]

  return targetingOptions
}
