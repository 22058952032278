import * as Yup from 'yup'

export const NAME = 'name'
export const CATEGORY = 'category'
export const SUBCATEGORY = 'subcategory'

export const getInitialValues = (account = {}) => ({
  [NAME]: account[NAME] || ''
})

export const nameValidation = {
  [NAME]: Yup.string().required('Please enter name').max(255, 'Name should have maximum 255 characters')
}

export const validationSchema = Yup.object({
  ...nameValidation
})
