import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'

import Skeleton from 'react-loading-skeleton'
import SuccessModal from '../../../features/components/Modals/SuccessModal'

import { useCalculateQuotationValidDate } from '../hooks'

import { createJsonFromQueryString } from '../../../helpers/url'

import { quotationsSelector } from '../../../modules/selectors/quotations'

import { MODAL_TYPE } from '../../../constants/other'

const SuccessQuotationSentModal = () => {
  const { search } = useLocation()
  const { t } = useTranslation()
  const { itemId: quotationId } = createJsonFromQueryString(search)

  const quotations = useSelector(quotationsSelector)

  const createdQuotation = quotations.find(quotation => String(quotation.id) === String(quotationId))
  const validUntilDate = useCalculateQuotationValidDate(createdQuotation?.quotation_create_date)

  return (
    <SuccessModal title={t('Booking quotation has been sent successfully')} modalType={MODAL_TYPE.quotationWasSent}>
      {t('quotationValidUntilDate', { validUntilDate })}
      {!validUntilDate && <Skeleton width={120} />}
    </SuccessModal>
  )
}

export default SuccessQuotationSentModal
