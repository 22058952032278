import { addWeeks, endOfMonth, endOfWeek, format, startOfDay, startOfMonth, startOfWeek, subWeeks } from 'date-fns'

import { calc } from '../../../../helpers/numbers'

import { EVENTS_AREA_PADDING, ONE_DAY } from '../../../../constants/timeline'
import { DATES_FORMAT_MONTH_YEAR } from '../../../../constants/dates'

export const getQuarterTitleCols = (startDate, endDate) => {
  return getQuartersData(startDate, endDate).map(data => ({
    text: data.text,
    colSpan: data.days.length
  }))
}

export const getQuarterSubtitleCols = (startDate, endDate) => {
  return getQuartersData(startDate, endDate)
    .map(data => data.days.map(week => `${week.getDate()}`.padStart(2, '0')))
    .reduce((acc, d) => [...acc, ...d])
    .map(d => ({ text: d }))
}

export const calculateWeeksInPx = args => {
  const { tableInnerWidth, startDate, endDate } = args

  const dates = getQuartersData(startDate, endDate).reduce((acc, d) => {
    acc.push(...d.days)
    return acc
  }, [])
  const intervalsCount = dates.length
  const pxPerInterval = tableInnerWidth / intervalsCount

  const timeToPx = dates.map((date, index) => {
    const start = startOfDay(date).getTime()
    let end

    if (index === dates.length - 1) {
      const weekEnd = endOfWeek(start, { weekStartsOn: 1 })
      const monthEnd = endOfMonth(start)
      end = weekEnd < monthEnd ? weekEnd.getTime() : monthEnd.getTime()
    } else {
      end = startOfDay(dates[index + 1]).getTime() - 1
    }

    const daysCount = calc(end).minus(start).div(ONE_DAY).toNumber()

    let currentPxPerInterval = pxPerInterval

    const isFirst = index === 0
    const isLast = index === dates.length - 1
    if (isFirst || isLast) {
      // first and last intervals have padding in columns when the event ends or starts on the edge
      currentPxPerInterval = pxPerInterval - EVENTS_AREA_PADDING
    }

    return {
      oneDayInPx: currentPxPerInterval / daysCount,
      daysCount: daysCount,
      start,
      end
    }
  })

  return timeToPx
}

const getQuartersData = (startDate, endDate) => {
  const result = [
    {
      text: `${format(startDate, DATES_FORMAT_MONTH_YEAR)}`,
      days: [startDate]
    }
  ]

  const getLastElement = a => a[a.length - 1]

  let index = 0
  let nextDate = calculateCalendarNextWeekDate(getLastElement(result[index].days))

  while (nextDate <= endDate) {
    const currentDate = getLastElement(result[index].days)

    if (currentDate.getMonth() !== nextDate.getMonth()) {
      index++
      result[index] = {
        text: `${format(nextDate, DATES_FORMAT_MONTH_YEAR)}`,
        days: [nextDate]
      }
    } else {
      result[index].days.push(nextDate)
    }

    nextDate = calculateCalendarNextWeekDate(nextDate)
  }

  return result
}

export const calculateCalendarNextWeekDate = (date, type, isDateRangeCalculation) => {
  let nextDate = addWeeks(date, 1)

  if (date.getMonth() !== nextDate.getMonth()) {
    const nextWeekStart = startOfWeek(nextDate, { weekStartsOn: 1 })
    const nextMonthStart = startOfMonth(nextDate)

    nextDate = nextWeekStart < nextMonthStart ? nextWeekStart : nextMonthStart
  } else {
    nextDate = startOfWeek(nextDate, { weekStartsOn: 1 })
  }

  if (isDateRangeCalculation && type === 'end') {
    //  get last possible time for date range calculation
    const weekEnd = endOfWeek(nextDate, { weekStartsOn: 1 })
    const monthEnd = endOfMonth(nextDate)

    nextDate = weekEnd < monthEnd ? weekEnd : monthEnd
  }

  return nextDate
}

export const calculateCalendarPrevWeekDate = (oldDate, type, isDateRangeCalculation) => {
  let newDate = subWeeks(oldDate, 1)

  if (oldDate.getMonth() !== newDate.getMonth()) {
    // has month changed
    const weekStart = startOfWeek(oldDate, { weekStartsOn: 1 })

    // 1. if current day is the first day of the month and is also the first day of the week(for example 01.04.2024
    if (weekStart.getDate() === 1 && oldDate.getDate() === 1) {
      return newDate
    }
    // 2. if current day is the first day of the month and is not the first day of the week
    else if (weekStart.getMonth() === oldDate.getMonth()) {
      return startOfMonth(oldDate)
      // 3. if current day is not the first day of the month
    } else {
      return startOfWeek(endOfMonth(newDate), { weekStartsOn: 1 })
    }
  } else {
    // month has not changed
    const prevWeekStart = startOfWeek(newDate, { weekStartsOn: 1 })

    newDate = prevWeekStart.getMonth() !== oldDate.getMonth() ? startOfMonth(oldDate) : prevWeekStart
  }

  if (isDateRangeCalculation && type === 'end') {
    // get last possible time for date range calculation
    const weekEnd = endOfWeek(newDate, { weekStartsOn: 1 })
    const monthEnd = endOfMonth(newDate)
    newDate = weekEnd < monthEnd ? weekEnd : monthEnd
  }

  return newDate
}
