export const FREQUENCY_OPTION = 'frequency_option'
export const FREQUENCY_OPTION_DEFAULT = 'frequency_option_default'
export const FREQUENCY_OPTION_AUTOMATIC = 'frequency_option_automatic'
export const FREQUENCY_IMPRESSIONS = 'frequency_impressions'
export const FREQUENCY_DAYS = 'frequency_days'

export const frequencyInitialValues = {
  [FREQUENCY_OPTION]: FREQUENCY_OPTION_DEFAULT,
  [FREQUENCY_IMPRESSIONS]: '2',
  [FREQUENCY_DAYS]: '7'
}
