import { createStyles } from '../../../../../styles/helpers'
import { darkGrey, textGrey } from '../../../../../styles/const/colors'
import { textEllipsis } from '../../../../../styles/mixins/text'

const useStyles = createStyles({
  statusActive: {
    color: textGrey
  },
  status: {
    color: darkGrey
  },
  mobileHeaderStyle: {
    minHeight: 24
  },
  effectiveDatesHeader: {
    paddingLeft: 10
  },
  effectiveDatesCol: {
    paddingRight: 10
  },

  effectiveDatesRender: {
    width: '100%',
    ...textEllipsis(),
    textAlign: 'right'
  },

  statusMobileField: {
    marginRight: 0
  }
})

export default useStyles
