import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import { combinedCampaignsSelector } from '../../../modules/selectors/combinedData'

import { FACEBOOK_PROVIDER_PLATFORM } from '../../../constants/selectLists/platformList'
import {
  CAMPAIGN_OPTION_ADVANTAGE_SHOPPING,
  CAMPAIGN_OPTION_AUDIENCE_TARGETING,
  CAMPAIGN_OPTION_EXISTING_CAMPAIGN,
  CAMPAIGN_OPTION_PRODUCT_RETARGETING
} from './UploadFacebookAdCreativeContent/CampaignForm/fields'
import {
  AUDIENCE_RETARGETING_ROUTE,
  PRODUCT_RETARGETING_ROUTE,
  SHOPPING_CAMPAIGNS_ROUTE
} from '../../../pages/Settings/AdvertiserAccounts/ClientAccountForms/ClientAccountWithMarginsCreate/ClientAccountWithMarginsCreateForm/fields'

export const useInitialCampaignOption = allowedRoutes => {
  const combinedCampaigns = useSelector(combinedCampaignsSelector)
  const { campaigns = [] } = combinedCampaigns
  const facebookCampaigns = useMemo(
    () =>
      campaigns.filter(campaign => campaign.provider === FACEBOOK_PROVIDER_PLATFORM && campaign.status !== 'deleted'),
    [campaigns]
  )

  // Determine the first allowed route in this order
  let defaultOption = ''
  if (allowedRoutes[SHOPPING_CAMPAIGNS_ROUTE]) {
    defaultOption = CAMPAIGN_OPTION_ADVANTAGE_SHOPPING
  } else if (allowedRoutes[PRODUCT_RETARGETING_ROUTE]) {
    defaultOption = CAMPAIGN_OPTION_PRODUCT_RETARGETING
  } else if (allowedRoutes[AUDIENCE_RETARGETING_ROUTE]) {
    defaultOption = CAMPAIGN_OPTION_AUDIENCE_TARGETING
  }

  const isCampaignsExist = !!facebookCampaigns?.length

  return isCampaignsExist ? CAMPAIGN_OPTION_EXISTING_CAMPAIGN : defaultOption
}
