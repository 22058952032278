import { createStyles } from '../../../../styles/helpers'

import { authPageContainerWidth } from '../../../../styles/common/authPages'
import { tabletDown } from '../../../../styles/const/breakpoints'

const useStyles = createStyles(theme => ({
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh'
  },
  logo: {
    marginTop: 120,
    marginBottom: 100
  },
  brandContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: 'linear-gradient(to bottom right, #592b87, #20113e)',
    flexGrow: 1
  },
  mainContentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '42.85%',
    margin: '0 auto',
    minWidth: authPageContainerWidth,
    backgroundColor: '#f5f5f7',
    color: '#20113e'
  },
  mainContent: {},
  [`@media screen and (${tabletDown})`]: {
    mainContent: {
      background: 'white',
      padding: '0 20px 20px 20px'
    }
  },
  '@global': {
    body: {
      // reset Shopometry theme header padding as Centomi theme does not have a header
      padding: '0 !important'
    }
  }
}))

export default useStyles
