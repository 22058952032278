import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import MultiSelectBox from '../../../../features/components/Form/MultiSelectBox'

import { formatOptionsList } from '../../../../features/formatters'

import { getTikTokHashtags, clearTikTokHashtags } from '../../../../modules/actions/tikTokConstants'
import { selectedAdAccountIdSelector } from '../../../../modules/selectors/app'
import { tikTokHashtagsIsLoadingSelector, tikTokHashtagsSelector } from '../../../../modules/selectors/tikTokConstants'

import { HASHTAGS } from './fields'

const TikTokHashtagsFields = ({ formik, adAccountId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { values, setFieldValue } = formik

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const hashtags = useSelector(tikTokHashtagsSelector)
  const hashtagsIsLoading = useSelector(tikTokHashtagsIsLoadingSelector)

  const providedAdAccountId = adAccountId || selectedAdAccountId

  const formattedHashtags = useMemo(
    () =>
      formatOptionsList({
        list: hashtags,
        valueName: 'id',
        labelName: 'name'
      }),
    [hashtags]
  )

  const getHashtagsHandler = useCallback(
    searchValue => {
      if (searchValue) {
        dispatch(getTikTokHashtags({ account: providedAdAccountId, keywords: [searchValue] }))
      }
    },
    [dispatch, providedAdAccountId]
  )

  useEffect(
    () => () => {
      dispatch(clearTikTokHashtags())
    },
    [dispatch]
  )

  return (
    <MultiSelectBox
      placeholder={t('Add a hashtag')}
      name={HASHTAGS}
      options={formattedHashtags}
      value={values[HASHTAGS]}
      isLoading={hashtagsIsLoading}
      setFieldValue={setFieldValue}
      onSearch={getHashtagsHandler}
    />
  )
}

TikTokHashtagsFields.propTypes = {
  formik: PropTypes.object.isRequired
}

export default TikTokHashtagsFields
