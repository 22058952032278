import { createStyles } from '../../../../../../../styles/helpers'

export default createStyles({
  datesCol: {
    width: '100%'
  },
  selectedDatesList: {
    listStyle: 'none',
    fontSize: '12px',
    padding: 0
  }
})
