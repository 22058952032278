import { createStyles } from '../../../../styles/helpers'

import { greyOutline } from '../../../../styles/const/colors'
import { inputHeight } from '../../../../styles/common/components'

const useStyles = createStyles(theme => ({
  fieldsContainer: {
    marginTop: 2,
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8
  },
  fieldsRow: {
    display: 'flex',
    rowGap: 8,
    columnGap: 8,
    '& .field': {
      marginTop: 0
    }
  },
  deleteRowButton: {
    // Negative margin is workaround to give second field in row more space
    marginRight: -10,
    marginLeft: -2,
    // Max height fixes wrong positions when field has error
    maxHeight: inputHeight,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& path': {
      fill: greyOutline
    }
  },
  loadMoreButton: {
    margin: '0 auto',
    color: theme.brandPrimary,
    marginTop: 10
  },
  addNewRowButton: {
    color: theme.brandPrimary,
    marginTop: 10
  }
}))

export default useStyles
