import React from 'react'

import Dv360MediaUpload from './Dv360MediaUpload'

import { MEDIA_ITEMS } from '../fields'

const MultipleDv360MediaUpload = ({ formik, FileItemPath, fileValidationSchema }) => {
  const itemPath = `${MEDIA_ITEMS}[${0}]`

  return (
    // todo implement multiple files upload
    <Dv360MediaUpload formik={formik} itemPath={itemPath} index={0} fileValidationSchema={fileValidationSchema} />
  )
}

export default MultipleDv360MediaUpload
