import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Button from '../../../components/Button'
import Can from '../../../features/components/Can'
import DrawerHeadline from '../../../components/Drawer/DrawerHeadline'

import useRedirectFormDrawer from '../../../features/components/FormDrawerWrapper/useRedirectFormDrawer'

import { selectedSelfAccountSelector } from '../../../modules/selectors/app'

import { ROUTE_PARAMS, ROUTES } from '../../../constants/routes'

import useStyles from './styles'

const RoutesMissingWarning = ({ hasDrawerPadding }) => {
  const { t } = useTranslation()
  const classes = useStyles({ hasDrawerPadding })

  const selfAccountId = useSelector(selectedSelfAccountSelector)

  const handleRedirect = useRedirectFormDrawer(ROUTES.advertiserAccounts)
  const redirectToPages = useCallback(() => {
    handleRedirect({ [ROUTE_PARAMS.selfAccount]: selfAccountId })
  }, [handleRedirect, selfAccountId])

  return (
    <div className={classes.formContainer}>
      <DrawerHeadline title={t('Sorry, there are no valid campaign types available')} />
      <div className={classes.warningFooter}>
        <Can I="create" a={ROUTES.advertiserAccounts}>
          <Button className="btnBlock" onClick={redirectToPages}>
            {t('Add campaign types')}
          </Button>
        </Can>
      </div>
    </div>
  )
}

export default RoutesMissingWarning
