import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import PageSection from '../../PageSection'
import BigBarGraph from '../../Graphs/BigBarGraph'

import { getReusableTooltipCallbackOptions } from '../helpers'

import { brown } from '../../../../styles/const/colors'
import useStyles from '../styles'

const AgeGraphSection = ({ rawGraphData, showAdvancedMetrics, currencySymbol }) => {
  const classes = useStyles()

  const ageGraphData = useMemo(() => rawGraphData && rawGraphData.map(data => data.impressions), [rawGraphData])
  const ageGraphLabels = useMemo(() => rawGraphData && rawGraphData.map(data => data.breakdown), [rawGraphData])

  const tooltipCallbackOptions = useMemo(
    () => getReusableTooltipCallbackOptions({ rawGraphData, showAdvancedMetrics, currencySymbol }),
    [currencySymbol, rawGraphData, showAdvancedMetrics]
  )

  return (
    <PageSection className={classes.sectionContainer}>
      <div className={classes.headingContainer}>
        <div className={classes.title}>Age</div>
      </div>
      <BigBarGraph
        className={classes.graphWrapper}
        data={ageGraphData}
        labels={ageGraphLabels}
        graphColor={brown}
        format="integer"
        tooltipCallbackOptions={tooltipCallbackOptions}
      />
    </PageSection>
  )
}

AgeGraphSection.propTypes = {
  rawGraphData: PropTypes.array.isRequired,
  showAdvancedMetrics: PropTypes.bool,
  currencySymbol: PropTypes.string
}

export default AgeGraphSection
