export const getUploadByName = uploadByUser => {
  if (uploadByUser?.first_name && uploadByUser?.last_name) {
    return `${uploadByUser.first_name} ${uploadByUser?.last_name}`
  } else if (uploadByUser?.first_name) {
    return uploadByUser.first_name
  } else if (uploadByUser?.last_name) {
    return uploadByUser.last_name
  } else {
    return ''
  }
}

export const findFileRequirementName = (id, fileRequirements) => {
  const currentFileRequirement = fileRequirements.find(file => file?.id === id)

  return currentFileRequirement?.file_name || ''
}
