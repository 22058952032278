import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import TableDataLoader from '../../../../../../components/Table/TableDataLoader'
import Table from '../../../../../../components/Table'

import { useRefreshInstallationReportRowStatusOnFileStatusUpdate } from './hooks'

import { useInstallationColumns } from '../columns'

import {
  bulkUpdateBookedMediaSelector,
  bulkUpdateBookingMediaFileSelector,
  getInstallationBookedMediaReportErrorSelector,
  getInstallationBookedMediaReportIsLoadingSelector,
  installationBookedMediaReportSelector,
  installationBookedMediaReportWasLoadedSelector
} from '../../../../../../modules/selectors/mediaOrdersBookings'
import { usersWasLoadedSelector } from '../../../../../../modules/selectors/users'

import useStyles from './styles'
import useCommonStyles from '../../../../../../styles/common/table'

const InstallationReportTable = () => {
  const { booked_media: bookedMediaId } = useSelector(bulkUpdateBookingMediaFileSelector)
  const { isLoading: bulkUpdateBookedMediaIsLoading, ids: bulkUpdateBookedMediaIds } =
    useSelector(bulkUpdateBookedMediaSelector)
  const usersWasLoaded = useSelector(usersWasLoadedSelector)

  const installationBookedMediaReport = useSelector(installationBookedMediaReportSelector)

  const isInstallationReportRowRefreshing = useRefreshInstallationReportRowStatusOnFileStatusUpdate()

  const maxUploadedFilesAmount = useMemo(() => {
    return installationBookedMediaReport.reduce((max, item) => {
      if (item.uploaded_files.length > max) {
        return item.uploaded_files.length
      }
      return max
    }, 0)
  }, [installationBookedMediaReport])

  const formattedInstallationBookedMediaReport = useMemo(() => {
    return installationBookedMediaReport.map(item => {
      if (item.id) {
        return item
      } else {
        return {
          ...item,
          // Add id to group rows to be able to represent update/loading state
          id: item.sequential_ids?.[0],
          // copy booking id from first sequential row to parent product group row to be able to show it
          booking: item.sequential_list?.[0]?.booking
        }
      }
    })
  }, [installationBookedMediaReport])

  const commonClasses = useCommonStyles()
  const { t } = useTranslation()

  const itemUpdatingId = useMemo(() => {
    if (isInstallationReportRowRefreshing) {
      const parentRowWithSequentialIds = formattedInstallationBookedMediaReport.find(item =>
        // find parent fow of updating sub row
        item.sequential_ids?.includes(bookedMediaId)
      )
      if (parentRowWithSequentialIds) {
        // if we update sub row - show loading on parent row
        return parentRowWithSequentialIds.sequential_ids[0]
      }
      return bookedMediaId
    } else if (bulkUpdateBookedMediaIsLoading) {
      return bulkUpdateBookedMediaIds[0]
    } else {
      return undefined
    }
  }, [
    isInstallationReportRowRefreshing,
    bookedMediaId,
    bulkUpdateBookedMediaIsLoading,
    bulkUpdateBookedMediaIds,
    formattedInstallationBookedMediaReport
  ])

  const classes = useStyles()

  const installationColumns = useInstallationColumns(maxUploadedFilesAmount)

  const rowTemplate = useMemo(() => {
    return {
      cols: installationColumns,
      params: {
        isExpandable: data => !!data?.sequential_list,
        renderExpandedContent: data => {
          return (
            <Table
              data={data?.sequential_list}
              cols={installationColumns}
              hideFooterRow
              hideHeadlineRow
              className={classes.subTable}
              rowTemplate={{
                rowClassName: classes.subTableRow
              }}
            />
          )
        }
      }
    }
  }, [classes.subTable, classes.subTableRow, installationColumns])

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContentInTheMiddleOfTheTable}>
        <div>{t('There are no bookings to show')}</div>
        <br />
      </div>
    ),
    [commonClasses.noDataContentInTheMiddleOfTheTable, t]
  )
  return (
    <TableDataLoader
      itemsLength={formattedInstallationBookedMediaReport?.length}
      errorSelector={getInstallationBookedMediaReportErrorSelector}
      wasLoadedSelector={installationBookedMediaReportWasLoadedSelector}
      isLoadingSelector={getInstallationBookedMediaReportIsLoadingSelector}
      skeletonProps={{ cols: installationColumns }}
      additionalDataIsLoading={!usersWasLoaded}
      noDataContent={noDataContent}
    >
      <Table
        data={formattedInstallationBookedMediaReport}
        rowTemplate={rowTemplate}
        hideFooterRow
        itemUpdatingId={itemUpdatingId}
      />
    </TableDataLoader>
  )
}

export default InstallationReportTable
