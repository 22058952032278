import React from 'react'
import { useTranslation } from 'react-i18next'

import Field from '../../../../../../../../components/Form/Field'

import {
  MAX_AGE_CHOICE,
  MIN_AGE_CHOICE
} from '../../../../../LineItemFacebookCreateOld/LineItemFacebookCreateFormOld/fields'
import { ageYearsList } from '../../../../../../../../constants/selectLists/ageYearsList'

import useStyles from './styles'

const AgesFields = ({ formik }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { values } = formik

  const minYearsList = ageYearsList.filter(year => Number(year.value) < values[MAX_AGE_CHOICE])
  const maxYearsList = ageYearsList.filter(year => Number(year.value) > values[MIN_AGE_CHOICE])

  return (
    <div className={classes.agesContainer}>
      <Field
        className={classes.yearField}
        placeholder="Min"
        formik={formik}
        name={MIN_AGE_CHOICE}
        options={minYearsList}
      />
      <span className={classes.to}>{t('to')}</span>
      <Field
        className={classes.yearField}
        placeholder="Max"
        formik={formik}
        name={MAX_AGE_CHOICE}
        options={maxYearsList}
      />
    </div>
  )
}

export default AgesFields
