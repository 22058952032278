import { ENDPOINTS } from '../../constants/api'
import { getFormData, madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getCalendarListService(params) {
  return madeRequest('GET', {
    url: ENDPOINTS.calendarsList,
    params
  })
}

export function createCalendarService(data) {
  return madeRequest('POST', {
    url: ENDPOINTS.calendarsList,
    data
  })
}

export function getCalendarService(calendarId) {
  return madeRequest('GET', {
    url: `${ENDPOINTS.calendarsList}${calendarId}/`
  })
}

export function updateCalendarService(updatedCalendarData) {
  return madeRequest('PATCH', {
    url: `${ENDPOINTS.calendarsList}${updatedCalendarData.id}/`,
    data: updatedCalendarData
  })
}

export function getEventService(eventId) {
  return madeRequest('GET', {
    url: `${ENDPOINTS.calendarEvents}${eventId}/`
  })
}

export function createEventService(eventData) {
  return madeRequest('POST', {
    url: ENDPOINTS.calendarEvents,
    // BE support multiple images upload within the event creation, for this need to pass images array to image field
    // i.e. {new_image: [file,file]} which fill be transformed to: "new_image: file, new_image: file" in FormData
    data: getFormData(eventData)
  })
}

export function updateEventService(eventData) {
  return madeRequest('PUT', {
    url: `${ENDPOINTS.calendarEvents}${eventData.id}/`,
    data: getFormData(eventData)
  })
}

export function updatePatchEventService(eventData) {
  return madeRequest('PATCH', {
    url: `${ENDPOINTS.calendarEvents}${eventData.id}/`,
    data: eventData
  })
}

export function deleteEventService(eventId) {
  return madeRequest('DELETE', {
    url: `${ENDPOINTS.calendarEvents}${eventId}/`
  })
}

export function createEventGroupService(eventGroupData) {
  return madeRequest('POST', {
    url: ENDPOINTS.calendarEventGroups,
    data: eventGroupData
  })
}

export function updateEventGroupService(editId, eventGroupData) {
  return madeRequest('PATCH', {
    url: `${ENDPOINTS.calendarEventGroups}${editId}/`,
    data: eventGroupData
  })
}

export function updateEventGroupOrderService(eventGroupData) {
  return madeRequest('POST', {
    url: ENDPOINTS.updateEventGroupsOrder,
    data: eventGroupData
  })
}

export function deleteEventGroupService(id) {
  return madeRequest('DELETE', {
    url: `${ENDPOINTS.calendarEventGroups}${id}/`
  })
}
