import React from 'react'
import { useSelector } from 'react-redux'

import Field from '../../../components/Form/Field'

import { BUDGET_DAILY, BUDGET_LIFETIME, BUDGET_OPTION } from '../CampaignForms/fields'
import { selectedAdAccountDataSelector } from '../../../modules/selectors/app'

const BudgetField = ({ formik }) => {
  const { currency_symbol } = useSelector(selectedAdAccountDataSelector)

  const { values } = formik

  const budgetType = values[BUDGET_OPTION]

  return budgetType === BUDGET_LIFETIME ? (
    <Field
      key={BUDGET_LIFETIME}
      type="number"
      formik={formik}
      name={BUDGET_LIFETIME}
      placeholder="Lifetime Budget"
      symbol={currency_symbol}
      autoComplete="off"
    />
  ) : (
    <Field
      key={BUDGET_DAILY}
      type="number"
      formik={formik}
      name={BUDGET_DAILY}
      placeholder="Daily Budget"
      symbol={currency_symbol}
      autoComplete="off"
    />
  )
}

export default BudgetField
