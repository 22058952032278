import React from 'react'
import { useTranslation } from 'react-i18next'

import ContentSection from '../../../features/components/ContentSection'

import DiscountPresets from './DiscountPresets'
import PriceChangePeriods from './PriceChangePeriods'

import useSettingsClasses from '../styles'

const Discounts = () => {
  const { t } = useTranslation()
  const settingsClasses = useSettingsClasses()

  return (
    <>
      <h1 className={settingsClasses.title}>{t('Price Modifiers')}</h1>

      <ContentSection title="Price Modifier Settings">
        <DiscountPresets />
        <PriceChangePeriods />
      </ContentSection>
    </>
  )
}

export default Discounts
