import { madeRequest } from '../../helpers/api'

import { ENDPOINTS } from '../../constants/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getAgencyService({ id }) {
  return madeRequest('GET', {
    url: `${ENDPOINTS.agencies}${id}/`
  })
}

export function updateAgencyService(data, id) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.agencies}${id}/`
  })
}

export function getAgenciesService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.agencies
  })
}

export function createAgencyService(agencyData) {
  return madeRequest('POST', {
    data: agencyData,
    url: ENDPOINTS.agencies
  })
}
