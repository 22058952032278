import {
  FILE_UPLOAD_OPTION,
  FILE_NAME,
  FILE_URL,
  FILE_UPLOAD,
  FILE_SELECT_EXISTING,
  FILE_SELECTED_NAME,
  FILE_SELECTED_URL,
  SELECTED_PERIODS
} from './fields'

export const transformValuesToBE = ({ values }) => {
  let commonFileData = {
    approval_status: 'pending_approval'
  }

  if (values[FILE_UPLOAD_OPTION] === FILE_UPLOAD) {
    commonFileData = {
      ...commonFileData,
      [FILE_NAME]: values[FILE_NAME],
      [FILE_URL]: values[FILE_URL]
    }
  } else if (values[FILE_UPLOAD_OPTION] === FILE_SELECT_EXISTING) {
    commonFileData = {
      ...commonFileData,
      [FILE_NAME]: values[FILE_SELECTED_NAME],
      [FILE_URL]: values[FILE_SELECTED_URL]
    }
  }

  // user can set which booked_media periods and media files the uploaded file should apply to.
  // by default only one period is selected (the one user clicked on), but user can select multiple periods
  const populatedFileToSelectedPeriods = []
  const selectedFilesId = Object.keys(values[SELECTED_PERIODS])
  selectedFilesId.forEach(function (fileId) {
    const selectedBookedMedias = Object.keys(values[SELECTED_PERIODS][fileId])

    selectedBookedMedias.forEach(function (bookedMediaId) {
      const isPeriodSelected = values[SELECTED_PERIODS][fileId][bookedMediaId]
      if (isPeriodSelected) {
        populatedFileToSelectedPeriods.push({
          ...commonFileData,
          media_product_file: fileId,
          booked_media: bookedMediaId
        })
      }
    })
  })

  return populatedFileToSelectedPeriods
}
