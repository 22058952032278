import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import { addDays, isPast, isToday } from 'date-fns'

import { formatDateToBE } from '../../../../../../constants/dates'
import { getBudgetValidation } from '../../../../../../forms/Multiplatform/UploadAdCreative/fields'
import {
  OBJECTIVE_PERCENTAGE,
  PERFORMANCE_GOAL_TYPE_CTR,
  timeUnitsValues,
  CAMPAIGN,
  CAMPAIGN_OPTION,
  CAMPAIGN_OPTION_EXISTING_CAMPAIGN,
  CAMPAIGN_OPTION_NEW_CAMPAIGN,
  BID_AMOUNT,
  BUDGET_LIFETIME
} from './fields'
import {
  END_DATE,
  endDateValidation,
  START_DATE,
  startDateValidation
} from '../../../../../../forms/ReusableFormFields/StartEndDateRangeFields/fields'

export const getValidationSchema = ({ remainingBudget, currencySymbol }) => {
  return Yup.object().shape({
    [CAMPAIGN]: Yup.string().when(CAMPAIGN_OPTION, {
      is: CAMPAIGN_OPTION_EXISTING_CAMPAIGN,
      then: () => Yup.string().required('Please select campaign')
    }),
    [BUDGET_LIFETIME]: Yup.number().when(CAMPAIGN_OPTION, {
      is: CAMPAIGN_OPTION_NEW_CAMPAIGN,
      then: () => getBudgetValidation({ remainingBudget, currencySymbol })
    }),
    [START_DATE]: startDateValidation,
    [END_DATE]: endDateValidation,
    [BID_AMOUNT]: Yup.number().required('Please enter your bid')
  })
}

export const transformValuesToBE = ({ values, mediaOrder, account }) => {
  const { name: mediaOrderName, start_date: mediaOrderStartDate, end_date: mediaOrderEndDate } = mediaOrder

  // If mediaOrder start date is in the past or today, then set campaign start date to tomorrow
  const startDate =
    isPast(new Date(mediaOrderStartDate)) || isToday(new Date(mediaOrderStartDate))
      ? formatDateToBE(addDays(new Date(), 1))
      : formatDateToBE(new Date(mediaOrderStartDate))

  const endDate = formatDateToBE(new Date(mediaOrderEndDate))

  return {
    account,
    name: `${mediaOrderName} - DV360 - ${uuidv4().slice(0, 7)}`,
    budget_lifetime: values[BUDGET_LIFETIME],
    date_start: startDate,
    date_stop: endDate,

    // Hardcoded values
    status: 'paused',
    frequency_cap: {
      time_unit: timeUnitsValues.TIME_UNIT_WEEKS,
      time_unit_count: 1,
      max_impressions: 14
    },
    objective: PERFORMANCE_GOAL_TYPE_CTR,
    [OBJECTIVE_PERCENTAGE]: '0.5',
    bid_strategy: { fixed_bid: {} },
    pacing: {
      pacing_period: 'PACING_PERIOD_FLIGHT',
      pacing_type: 'PACING_TYPE_AHEAD'
    }
  }
}
