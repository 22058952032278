import { createStyles } from '../../../../../../../../styles/helpers'

const useStyles = createStyles({
  addPackage: {
    marginTop: 16,
    marginBottom: 32
  }
})

export default useStyles
