import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../../components/Form'
import Field from '../../../../../../components/Form/Field'

import { clearUpdateEventGroup, updateEventGroup } from '../../../../../../modules/actions/calendarPlanner'
import {
  updateEventGroupLoadingSelector,
  updateEventGroupErrorSelector,
  updatedEventGroupSelector
} from '../../../../../../modules/selectors/calendarPlanner'

import { PLANNER_EVENT_GROUP_EDIT } from '../../../../../../constants/forms'
import { eventGroupTypesList } from '../../../../../../constants/selectLists/calendarList'

import { NAME, TYPE, getInitialValues, validationSchema } from '../../fields'

import useStyles from '../../CalendarCreateRow/CalendarCreateRowForm/styles'

// row is the same as event_group
function CalendarEditRowForm({ editItemData, selectedEditItemId }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const updatedEventGroup = useSelector(updatedEventGroupSelector)

  const clearEditRowSubmit = useCallback(() => dispatch(clearUpdateEventGroup()), [dispatch])

  const onSubmit = useCallback(
    values => dispatch(updateEventGroup(selectedEditItemId, values)),
    [dispatch, selectedEditItemId]
  )

  const formik = useFormik({
    initialValues: getInitialValues(editItemData),
    enableReinitialize: true,
    validationSchema,
    onSubmit
  })

  return (
    <Form
      formName={PLANNER_EVENT_GROUP_EDIT}
      formik={formik}
      className={classes.drawerForm}
      successSubmit={updatedEventGroup.wasUpdated}
      clearHandler={clearEditRowSubmit}
      errorSelector={updateEventGroupErrorSelector}
      isLoadingSelector={updateEventGroupLoadingSelector}
    >
      <Field placeholder="Event Name" formik={formik} name={NAME} enableReinitialize />
      <Field placeholder="Row Type" formik={formik} name={TYPE} options={eventGroupTypesList} enableReinitialize />
    </Form>
  )
}

CalendarEditRowForm.propTypes = {
  editItemData: PropTypes.object,
  selectedEditItemId: PropTypes.number
}
export default CalendarEditRowForm
