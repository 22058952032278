import * as Yup from 'yup'

import { INVENTORY_AMOUNT, INVENTORY_PERIOD, MAX_PERIODS, MIN_PERIODS } from './fields'
import {
  CHANNEL_OPTION,
  CHANNEL_OPTION_OFFSITE,
  DEFAULT_INVENTORY_DATE_START,
  MEDIA_CATEGORY
} from './MediaProductCreate/MediaProductCreateForm/fields'
import { DIGITAL } from '../../../constants/mediaOrders'

export const minMaxPeriodsValidation = {
  [MIN_PERIODS]: Yup.number().test(
    'is-less-than-max',
    'Minimum periods must be less than maximum periods',
    function (value) {
      const { [MAX_PERIODS]: maxPeriods } = this.parent
      return !maxPeriods || !value || value < maxPeriods
    }
  ),
  [MAX_PERIODS]: Yup.number().test(
    'is-greater-than-min',
    'Maximum periods must be greater than minimum periods',
    function (value) {
      const { [MIN_PERIODS]: minPeriods } = this.parent
      return !minPeriods || !value || value > minPeriods
    }
  )
}

export const inventoryValidationTest = (fieldName, errorMessage) => {
  const inventoryFieldConditions = {
    [INVENTORY_AMOUNT]: [INVENTORY_PERIOD, DEFAULT_INVENTORY_DATE_START],
    [INVENTORY_PERIOD]: [INVENTORY_AMOUNT, DEFAULT_INVENTORY_DATE_START],
    [DEFAULT_INVENTORY_DATE_START]: [INVENTORY_AMOUNT, INVENTORY_PERIOD]
  }

  return Yup.mixed().test({
    name: 'inventory-fields-conditional-required',
    message: errorMessage,
    test: function (value) {
      const relatedFields = inventoryFieldConditions[fieldName]
      const anyFilled = relatedFields.some(field => !!this.parent[field])
      // If any related fields are filled, check if the current field is empty. If so, trigger a validation error.
      if (anyFilled && !value) {
        return this.createError({ path: this.path, message: errorMessage })
      } else {
        return true // If no related fields are filled or the current field is filled, validation passes.
      }
    }
  })
}
export const platformsValidation = Yup.array().when(MEDIA_CATEGORY, {
  is: DIGITAL,
  then: () =>
    Yup.array().when(CHANNEL_OPTION, {
      is: CHANNEL_OPTION_OFFSITE,
      then: () => Yup.array().min(1, 'Select at least one platform')
    })
})
