import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { getIn } from 'formik'

import InputField from '../InputField'

import useStyles from './styles'

function InlineField({ formik, name, id, className, autoComplete, formatType, ...inputProps }) {
  const { values, errors, status, setStatus, touched, setFieldValue, setFieldError, setFieldTouched } = formik
  const value = getIn(values, name) || ''
  // server errors are saving in status due to native formik errors totally resets on Blur
  const serverError = status && status.serverErrors && status.serverErrors[name]
  const error = errors[name] || serverError
  const isTouched = touched[name]
  const showError = !!(isTouched && error)

  const classes = useStyles()

  const resetError = () => {
    if (error) {
      // hide existed error on value change
      errors[name] && setFieldError(name, '')
      // reset server error which saved in status
      serverError &&
        setStatus({
          serverErrors: {
            ...status.serverErrors,
            [name]: ''
          }
        })
    }
  }

  return (
    // to avoid unnecessary re-renders formik sets only onBlur and field value is state manageable
    <div className={classnames('inlineField', className, classes.inlineField)}>
      <InputField
        id={id}
        name={name}
        formikValue={value}
        inputProps={inputProps}
        showError={showError}
        resetError={resetError}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        autoComplete={autoComplete ? autoComplete : 'on'}
      />
    </div>
  )
}

export default InlineField

InlineField.propTypes = {
  id: PropTypes.string,
  autoComplete: PropTypes.string,
  formatType: PropTypes.object,
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired
}
