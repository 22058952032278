import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addDays } from 'date-fns'
import PropTypes from 'prop-types'

import {
  isControllerBuyerApprovalSelector,
  selectedControllerDataSelector
} from '../../../../../../../modules/selectors/app'

import { formatDateToBE } from '../../../../../../../constants/dates'
import {
  filesApprovalStatusOptions,
  STATUS_FILTER_AWAITING_FILES,
  STATUS_FILTER_FILES_OVERDUE,
  STATUS_FILTER_FILES_PENDING_REVIEW
} from '../../../../../../../features/components/Filters/BookedMediaStatusFilter/options'
import { bookedMediaStatuses } from '../../../../../../../constants/bookedMedia'
import { PENDING_BUYER_APPROVAL } from '../../../../../../../features/components/UploadedFilesField/helpers'
import { MEDIA_SUB_CATEGORIES } from '../../../../../../Settings/ManageControllerTeam/ControllerInviteCreate/ControllerInviteCreateForm/fields'
import { ASSET_MANAGER, BUYER, INSTALLER } from '../../../../../../../constants/permissions'

export const InstallationReportFiltersContext = React.createContext()

const nowDate = new Date()

const installerRoleDefaultBookedMediaStatuses = [
  bookedMediaStatuses.awaiting_set_up,
  bookedMediaStatuses.creative_updated,
  bookedMediaStatuses.scheduled,
  bookedMediaStatuses.remove_creative,
  bookedMediaStatuses.pause_request,
  bookedMediaStatuses.paused
]

export function InstallationReportDataProvider({ children, onFiltersChange }) {
  const dispatch = useDispatch()

  const isControllerBuyerApproval = useSelector(isControllerBuyerApprovalSelector)
  const {
    category: selectedControllerCategory,
    role: selectedControllerRole,
    [MEDIA_SUB_CATEGORIES]: selectedControllerMediaSubCategories
  } = useSelector(selectedControllerDataSelector)

  const [dateRangeFilter, setDateRangeFilter] = useState({
    startDate: nowDate,
    endDate: addDays(nowDate, 30)
  })
  const [selectedLocations, setSelectedLocations] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])

  const selectedControllerMediaSubCategoriesIds = useMemo(
    () => selectedControllerMediaSubCategories?.map(item => item.id) || [],
    [selectedControllerMediaSubCategories]
  )

  const [selectedSubCategories, setSelectedSubCategories] = useState(
    selectedControllerRole === ASSET_MANAGER || selectedControllerRole === INSTALLER
      ? selectedControllerMediaSubCategoriesIds
      : []
  )
  const [selectedSubSubCategories, setSelectedSubSubCategories] = useState([])
  const [brandCategory, setBrandCategory] = useState(
    selectedControllerCategory ? [Number(selectedControllerCategory?.id)] : []
  )
  const [brandSubCategory, setBrandSubCategory] = useState([])

  const [selectedStatuses, setSelectedStatuses] = useState(
    selectedControllerRole === INSTALLER ? installerRoleDefaultBookedMediaStatuses : []
  )
  // if controller is buyer approval then file statuses filter has preselected value PENDING_BUYER_APPROVAL
  const [selectedFileStatuses, setSelectedFileStatuses] = useState(
    isControllerBuyerApproval && selectedControllerRole === BUYER ? [PENDING_BUYER_APPROVAL] : []
  )

  const filterRequestParams = useMemo(() => {
    const filesApprovalStatusOptionsValues = filesApprovalStatusOptions.map(option => option.value)
    const selectedFilesApprovalStatuses = selectedFileStatuses.filter(status =>
      filesApprovalStatusOptionsValues.includes(status)
    )

    return {
      date_start_after: formatDateToBE(dateRangeFilter.startDate),
      date_start_before: formatDateToBE(dateRangeFilter.endDate),
      ...(selectedLocations.length && { location: selectedLocations.join(',') }),
      ...(selectedProducts.length && { media_product: selectedProducts.join(',') }),
      ...(selectedCategories.length && { media_category: selectedCategories.join(',') }),
      ...(selectedSubCategories.length && { media_sub_category: selectedSubCategories.join(',') }),
      ...(selectedSubSubCategories.length && { media_sub_sub_category: selectedSubSubCategories.join(',') }),
      // file statuses
      ...(selectedFileStatuses.includes(STATUS_FILTER_AWAITING_FILES) && { file_pending: true }),
      ...(selectedFileStatuses.includes(STATUS_FILTER_FILES_PENDING_REVIEW) && { files_pending_review: true }),
      ...(selectedFileStatuses.includes(STATUS_FILTER_FILES_OVERDUE) && { files_overdue: true }),
      // files approval statuses
      ...(selectedFilesApprovalStatuses.length && { files_approval_status: selectedFilesApprovalStatuses.join(',') }),
      // statuses
      ...(selectedStatuses.length && { status: selectedStatuses.join(',') }),
      // brand category
      ...(brandCategory.length && { brand_category: brandCategory.join(',') }),
      // brand sub category
      ...(brandSubCategory.length && { brand_subcategory: brandSubCategory.join(',') })
    }
  }, [
    dateRangeFilter,
    selectedLocations,
    selectedProducts,
    selectedCategories,
    selectedFileStatuses,
    selectedStatuses,
    selectedSubCategories,
    selectedSubSubCategories,
    brandCategory,
    brandSubCategory
  ])

  const installationReportFilterProps = useMemo(
    () => ({
      filterRequestParams,
      dateRangeFilter,
      setDateRangeFilter,
      selectedLocations,
      setSelectedLocations,
      selectedProducts,
      setSelectedProducts,
      selectedCategories,
      setSelectedCategories,
      selectedSubCategories,
      setSelectedSubCategories,
      selectedSubSubCategories,
      setSelectedSubSubCategories,
      selectedFileStatuses,
      setSelectedFileStatuses,
      selectedStatuses,
      setSelectedStatuses,
      brandCategory,
      setBrandCategory,
      brandSubCategory,
      setBrandSubCategory
    }),
    [
      dateRangeFilter,
      filterRequestParams,
      selectedLocations,
      setSelectedLocations,
      selectedProducts,
      setSelectedProducts,
      selectedCategories,
      selectedSubCategories,
      setSelectedSubCategories,
      selectedSubSubCategories,
      setSelectedSubSubCategories,
      setSelectedCategories,
      selectedFileStatuses,
      setSelectedFileStatuses,
      selectedStatuses,
      setSelectedStatuses,
      brandCategory,
      setBrandCategory,
      brandSubCategory,
      setBrandSubCategory
    ]
  )
  // make a type check for the props
  PropTypes.checkPropTypes(propTypes, installationReportFilterProps, 'prop', 'useRequestHandler')

  useEffect(() => {
    onFiltersChange(filterRequestParams)
  }, [dispatch, filterRequestParams, onFiltersChange])

  return (
    <InstallationReportFiltersContext.Provider value={installationReportFilterProps}>
      {children}
    </InstallationReportFiltersContext.Provider>
  )
}

export const filterRequestParamsTypes = PropTypes.shape({
  date_start: PropTypes.string,
  date_end: PropTypes.string
}).isRequired

const propTypes = {
  filterRequestParams: filterRequestParamsTypes,
  dateRangeFilter: PropTypes.object.isRequired,
  setDateRangeFilter: PropTypes.func.isRequired
}
