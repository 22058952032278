import React from 'react'
import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

function NavItemsMenuSkeleton() {
  const classes = useStyles()

  return (
    <nav className={classes.nav}>
      <div className={classes.navItem}>
        <Skeleton width="150px" />
        <div className={classes.navItemRight}>
          <p className={classes.navIcon}>
            <Skeleton width="12px" />
          </p>
        </div>
      </div>
      <div className={classes.navItem}>
        <Skeleton width="150px" />
      </div>
    </nav>
  )
}

export default NavItemsMenuSkeleton
