import { getLocalStorage } from './storage'

import { getParameterFromPath, updateRouteParams } from './url'

import store from '../store'

import { ROUTE_PARAMS } from '../constants/routes'
import { SELECTED_PLATFORM } from '../modules/reducers/app'
import platformList, { FACEBOOK_PLATFORM } from '../constants/selectLists/platformList'

import { setSelectedPlatform } from '../modules/actions/app'
import { clearSelectedAdForCopy } from '../modules/actions/ads'

export function handlePlatformChange(newPlatform, onPlatformChange) {
  const { app } = store.getState()
  const currentPlatform = app.selectedPlatform

  if (newPlatform !== currentPlatform) {
    // trigger platform change only when values different
    onPlatformChange && onPlatformChange()
    store.dispatch(setSelectedPlatform(newPlatform))
    // selected ad for copy should be reset on Platform/AdAccount change
    store.dispatch(clearSelectedAdForCopy())
  }
}

export function getInitialPlatform() {
  const platformFromPath = getParameterFromPath(ROUTE_PARAMS.platform)
  const isPlatformValid = !!platformList.find(({ value }) => value === platformFromPath)
  const initialSelectedPlatform = getLocalStorage(SELECTED_PLATFORM) || FACEBOOK_PLATFORM

  if (!platformFromPath) {
    // not platform related path
    return initialSelectedPlatform
  }
  if (isPlatformValid) {
    // path with correct platform
    return platformFromPath
  } else {
    // path with not correct platform parameter - redirect to correct
    updateRouteParams({
      [ROUTE_PARAMS.platform]: initialSelectedPlatform
    })
    return initialSelectedPlatform
  }
}
