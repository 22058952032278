import { t } from 'i18next'

import { getAppDomainName } from '../../../../features/helpers/getAppDomainName'

import { AUDIENCE_SUGGESTION_TYPE } from '../../../../constants/forms'

export const OPTION_CHOICE = 'option_choice'
export const OPTION_TYPE = 'option_type'

export const initialValues = {
  [OPTION_CHOICE]: '',
  [OPTION_TYPE]: ''
}

const formatAudienceNameToBE = suggestion_type => {
  switch (suggestion_type) {
    case AUDIENCE_SUGGESTION_TYPE.WEBSITE_TOP_25:
      return 'Website Top 25% (180 days)'
    case AUDIENCE_SUGGESTION_TYPE.FACEBOOK_PAGE_ENGAGERS:
      return 'Facebook Page Engagers (365 days)'
    case AUDIENCE_SUGGESTION_TYPE.WEBSITE_ALL_VISITORS:
    default:
      return 'All website visitors (180 days)'
  }
}

export const transformValuesToBE = ({ values, account, suggestionType }) => {
  const appName = getAppDomainName()

  const { option_choice } = values

  return {
    name: formatAudienceNameToBE(suggestionType),
    description:
      suggestionType === AUDIENCE_SUGGESTION_TYPE.FACEBOOK_PAGE_ENGAGERS
        ? 'Anyone that has engaged with Facebook page'
        : t('CreatedWithPlatformText', { appName }),
    account,
    rule: {
      inclusions: {
        operator: 'or',
        rules: [
          {
            event_sources: [
              {
                type: suggestionType === AUDIENCE_SUGGESTION_TYPE.FACEBOOK_PAGE_ENGAGERS ? 'page' : 'pixel',
                id: option_choice
              }
            ],
            retention_seconds:
              suggestionType === AUDIENCE_SUGGESTION_TYPE.FACEBOOK_PAGE_ENGAGERS ? '31536000' : '15552000',
            filter: {
              operator: 'and',
              filters: [
                suggestionType === AUDIENCE_SUGGESTION_TYPE.FACEBOOK_PAGE_ENGAGERS
                  ? {
                      field: 'event',
                      value: 'page_engaged',
                      operator: '='
                    }
                  : {
                      field: 'url',
                      value: '',
                      operator: 'i_contains'
                    }
              ]
            },
            ...(suggestionType === 'websiteTop25' && {
              aggregation: {
                type: 'time_spent',
                operator: 'in_range',
                value: {
                  from: 75,
                  to: 100
                },
                method: 'percentile'
              }
            })
          }
        ]
      }
    }
  }
}
