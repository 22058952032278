import { createStyles } from '../../../../styles/helpers'

import { transitionShort } from '../../../../styles/const/common'

export default createStyles(theme => ({
  closeBtn: {
    fontSize: 0,
    outline: 'none',
    backgroundColor: 'white',
    cursor: 'pointer',
    border: {
      width: 0
    },
    '&:hover path': {
      fill: theme.brandPrimary
    },
    '& svg': {
      width: 10,
      height: 10
    },
    '& path': {
      transition: transitionShort
    }
  },
  disabled: {
    pointerEvents: 'none'
  }
}))
