import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import EditForm from '../../../../../../../features/components/Forms/EditForm'
import MultipleOptionsField from '../../../../../../ReusableFormFields/MultipleOptionsField'
import { getVideoUrlsData } from '../HeadlinesSection/helpers'

import { useEditAssetGroupSubmit } from '../../hooks'

import { selectedAdAccountIdSelector } from '../../../../../../../modules/selectors/app'
import { assetsCreateErrorSelector, assetsCreateLoadingSelector } from '../../../../../../../modules/selectors/assets'

import { VIDEO_ADS_URL } from '../../../../fields'
import { getInitialValues, validationSchema } from './fields'
import { googleAssetFieldType } from '../../../../../../../constants/ads'

const VideoUrlsSectionForm = ({ editItemData, isInternalAssetGroup, ...formProps }) => {
  const { assets, id: assetGroupId, account } = editItemData

  const selectedAdAccount = useSelector(selectedAdAccountIdSelector)
  const adAccount = account || selectedAdAccount

  const assetsCreateError = useSelector(assetsCreateErrorSelector)
  const assetsCreateLoading = useSelector(assetsCreateLoadingSelector)

  const originalVideoUrlAssets = useMemo(() => getVideoUrlsData(assets), [assets])

  const initialValues = useMemo(() => getInitialValues(originalVideoUrlAssets), [originalVideoUrlAssets])

  const purifiedFormik = useEditAssetGroupSubmit({
    originalSectionAssets: originalVideoUrlAssets,
    fieldType: googleAssetFieldType.YOUTUBE_VIDEO,
    sectionValueKey: VIDEO_ADS_URL,
    isInternalAssetGroup,
    assetGroupId,
    initialValues,
    validationSchema,
    adAccount
  })

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      requestError={assetsCreateError}
      isLoading={assetsCreateLoading}
      {...formProps}
    >
      <MultipleOptionsField
        fieldPlaceholder="YouTube Video link"
        listFieldName={VIDEO_ADS_URL}
        formik={purifiedFormik}
        minListQuantity={0}
        maxListQuantity={5}
        initialValue={'https://www.youtube.com'}
      />
    </EditForm>
  )
}

export default VideoUrlsSectionForm
