import React from 'react'
import { useTranslation } from 'react-i18next'

import { getAdBusinessName, getAdCallToActionText } from '../../helpers'

function NameSectionPreview({ ad }) {
  const { t } = useTranslation()
  const businessName = getAdBusinessName(ad)
  const callToActionText = getAdCallToActionText(ad)

  return (
    <>
      {businessName && (
        <p>
          <span>
            <strong>{t('Business Name')}</strong>
          </span>
          <span>{businessName}</span>
        </p>
      )}
      {callToActionText && (
        <p>
          <span>
            <strong>{t('Call To Action')}</strong>
          </span>
          <span>{callToActionText}</span>
        </p>
      )}
    </>
  )
}

export default NameSectionPreview
