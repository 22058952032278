import { createStyles } from '../../../../../../../../styles/helpers'

const useStyles = createStyles(theme => ({
  thumbnailLibraryContainer: {
    marginTop: 24
  },
  thumbnailLibrary: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: 11,
    rowGap: 8
  },
  thumbnailImageSkeleton: {
    width: '100%',
    borderRadius: 4,
    height: 200
  },
  thumbnailImage: {
    width: '100%',
    cursor: 'pointer',
    borderRadius: 4,
    backgroundColor: 'transparent',
    outline: {
      width: 4,
      style: 'solid',
      color: 'transparent'
    },
    outlineOffset: -4
  },
  thumbnailImageSelected: {
    outlineColor: theme.brandPrimary
  }
}))

export default useStyles
