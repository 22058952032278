import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectedControllerDataSelector } from '../../modules/selectors/app'

// The start day of the week currently determining by controller currency
export default function useStartWeekDay() {
  const selectedController = useSelector(selectedControllerDataSelector)
  const controllerCurrency = selectedController?.currency

  return useMemo(() => {
    // If SAR currency then start on Sunday, otherwise Monday
    return controllerCurrency === 'SAR' ? 0 : 1
  }, [controllerCurrency])
}
