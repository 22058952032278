import PropTypes from 'prop-types'

import useSelectedAdAccountValidation from '../../../features/hooks/useSelectedAdAccountValidation'

function AuthorizedRoute({ children, path }) {
  // on every path change check selectedAdAccount
  useSelectedAdAccountValidation(path)

  return children
}

AuthorizedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired
}

export default AuthorizedRoute
