import { DOMAINS } from '../../constants/selectLists/languagesList'

export const getAppDomainName = () => {
  const currentAppUrl = window.location.href

  if (currentAppUrl.includes(DOMAINS.aduhu)) {
    return 'Aduhu'
  } else if (currentAppUrl.includes(DOMAINS.cenomispace)) {
    return 'Cenomi Space'
  } else if (currentAppUrl.includes(DOMAINS.oneviu)) {
    return 'OneViu Media'
  } else if (currentAppUrl.includes(DOMAINS.nexus)) {
    return 'Nexus'
  } else {
    return 'Shopometry'
  }
}
