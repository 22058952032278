import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { useInvitesColumns } from '../../../../../features/hooks/useMembersAndInvitesColumns/useInvitesColumns'

import {
  controllerInvitesSelector,
  deletedControllerInvitationSelector,
  deletedControllerInviteIsLoadingSelector
} from '../../../../../modules/selectors/controller'

import Table from '../../../../../components/Table'

import useStyles from '../../../../../features/hooks/useMembersAndInvitesColumns/styles'

const ControllerInvitesTableData = ({ onResendInvite, onDeleteInvite }) => {
  const classes = useStyles()

  const controllerInvites = useSelector(controllerInvitesSelector)
  const { id: inviteId } = useSelector(deletedControllerInvitationSelector)
  const deletedControllerInviteIsLoading = useSelector(deletedControllerInviteIsLoadingSelector)

  const itemUpdatingId = useMemo(() => {
    if (deletedControllerInviteIsLoading) {
      return inviteId
    }

    return undefined
  }, [inviteId, deletedControllerInviteIsLoading])

  const membersAndInvitesColumns = useInvitesColumns({
    invites: controllerInvites,
    onDeleteInvite,
    onResendInvite
  })

  return (
    <Table
      mobileColumnsWrapperClassName={classes.mobileHeaderStyle}
      hideFooterRow
      data={controllerInvites}
      cols={membersAndInvitesColumns}
      itemUpdatingId={itemUpdatingId}
    />
  )
}

ControllerInvitesTableData.propTypes = {
  onResendInvite: PropTypes.func.isRequired,
  onDeleteInvite: PropTypes.func.isRequired
}

export default ControllerInvitesTableData
