import React from 'react'
import Skeleton from 'react-loading-skeleton'
import useStyles from './styles'

function PdfPageSkeleton() {
  const classes = useStyles()

  return (
    <div className={classes.page}>
      <header>
        <div className={classes.header}>
          <Skeleton height={40} width={150} />
          <div className={classes.headerDetails}>
            <Skeleton width="50%" count={1} />
          </div>
        </div>
      </header>
      <section className={classes.bookingDetails}>
        <div className={classes.row}>
          <div className={classes.col}>
            <Skeleton width="50%" count={5} />
          </div>
        </div>
      </section>
      <br />
      <br />
      <Skeleton count={1} />
      <br />
      <br />
      <section className={classes.orderTable}>
        <div className={`${classes.product} ${classes.lineBottom}`}>
          <div className={classes.cartRow}>
            <p>
              <Skeleton width={200} count={1} />
            </p>
            <p className={classes.productPrice}>
              <Skeleton width={40} count={1} />
              <sub className="sub-value">
                .<Skeleton width={20} count={1} />
              </sub>
            </p>
          </div>
          <div className={classes.cartRow}>
            <p>
              <Skeleton width={200} count={1} />
            </p>
            <p className={classes.productPrice}>
              <Skeleton width={40} count={1} />
              <sub className="sub-value">
                .<Skeleton width={20} count={1} />
              </sub>
            </p>
          </div>
        </div>
        <div className={`${classes.product} ${classes.lineBottom}`}>
          <div className={classes.cartRow}>
            <p>
              <Skeleton width={200} count={1} />
            </p>
            <p className={classes.productPrice}>
              <Skeleton width={40} count={1} />
              <sub className="sub-value">
                .<Skeleton width={20} count={1} />
              </sub>
            </p>
          </div>
          <div className={classes.cartRow}>
            <p>
              <Skeleton width={200} count={1} />
            </p>
            <p className={classes.productPrice}>
              <Skeleton width={40} count={1} />
              <sub className="sub-value">
                .<Skeleton width={20} count={1} />
              </sub>
            </p>
          </div>
        </div>
        <div className={classes.cartFooter}>
          <div className={classes.cartRow}>
            <p>
              <Skeleton width={200} count={1} />
            </p>
            <p className={classes.productPrice}>
              <Skeleton width={40} count={1} />
              <sub className="sub-value">
                .<Skeleton width={20} count={1} />
              </sub>
            </p>
          </div>
          <div className={classes.cartRow}>
            <p>
              <Skeleton width={200} count={1} />
            </p>
            <p className={classes.productPrice}>
              <Skeleton width={40} count={1} />
              <sub className="sub-value">
                .<Skeleton width={20} count={1} />
              </sub>
            </p>
          </div>
        </div>
      </section>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <footer className={classes.pageFooter}>
        <div className={`${classes.companyDetails} ${classes.col}`}>
          <Skeleton width={400} count={3} />
        </div>
        <div className={`${classes.pagesProgress} ${classes.col}`}>
          <Skeleton width={60} count={1} />
        </div>
      </footer>
    </div>
  )
}

export default PdfPageSkeleton
