import { createStyles } from '../../../styles/helpers'

import { lighterGrey } from '../../../styles/const/colors'
import { angleIcon } from '../../../styles/common/components'
import { phonesDown, tabletDown } from '../../../styles/const/breakpoints'

const useStyles = createStyles(theme => ({
  // part of range styles are in the index.js
  // that is because of issue of reusing same rule for different selectors in react-jss
  dateRange: {
    minWidth: 220,
    maxWidth: 220
  },
  dateRangeDropdown: {
    display: 'flex',
    flexDirection: 'column'
  },
  dateRanges: {
    display: 'flex',
    backgroundColor: 'white'
  },
  staticRangeList: {
    fontSize: 12,
    padding: '16px 0',
    listStyle: 'none'
  },
  staticRangeItem: {
    padding: '5px 15px',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: lighterGrey
    }
  },
  rangeItemActive: {
    backgroundColor: theme.brandPrimary,
    color: theme.brandPrimaryTextContrastColor,
    fontWeight: 600
  },
  range: {
    position: 'relative',

    '& .rdrNextPrevButton': {
      width: 'auto',
      height: 'auto',
      background: 'none',

      '&:before': {
        extend: angleIcon
      },
      '& i': {
        display: 'none'
      }
    },

    '& .rdrPprevButton': {
      transform: 'rotate(90deg)'
    },
    '& .rdrNextButton': {
      transform: 'rotate(-90deg)'
    },

    '& .rdrDayNumber, & .rdrDayToday .rdrDayNumber span': {
      fontWeight: 400
    },

    '& .rdrMonthAndYearPickers': {
      display: 'none'
    },

    '& .rdrMonthName': {
      padding: '15px 0',
      fontSize: 16,
      color: theme.brandPrimary,
      position: 'absolute',
      top: 12
    },

    '& .rdrMonth:first-child .rdrMonthName': {
      left: '25%',
      transform: 'translateX(-50%)'
    },
    '& .rdrMonth:nth-child(2) .rdrMonthName': {
      right: '25%',
      transform: 'translateX(50%)'
    },
    '& .rdrDayToday .rdrDayNumber span:after': {
      background: theme.brandPrimary
    }
  },
  saveBtn: {
    marginLeft: 'auto',
    marginBottom: 16,
    marginRight: 16
  },
  dateRangeMobile: {
    minWidth: 220,
    maxWidth: 220
  },

  [`@media screen and (${tabletDown})`]: {
    dateRange: {
      display: 'none'
    },
    saveBtn: {
      width: '90%',
      margin: '0 auto 16px'
    }
  },

  [`@media screen and (${phonesDown})`]: {
    range: {
      '& .rdrMonth:first-child .rdrMonthName': {
        left: '50%'
      }
    },
    dateRangeMobile: {
      maxWidth: '100%',
      width: '100%'
    }
  }
}))

export default useStyles
