import { createStyles } from '../../../styles/helpers'

import { darkChipGreen, red, lightGrey2, darkGrey } from '../../../styles/const/colors'

export default createStyles(theme => ({
  graphSection: {
    padding: '18px 24px',
    background: 'white',
    marginBottom: 16,
    border: `1px solid ${lightGrey2}`,
    borderRadius: theme.defaultBorderRadius
  },
  graphHeader: {
    fontSize: 16,
    marginTop: 0,
    marginBottom: 16
  },
  graphText: {
    marginTop: 8,
    marginBottom: 0,
    fontSize: 12,
    fontWeight: '400',
    color: darkGrey
  },
  graphGreenText: {
    color: darkChipGreen
  },
  graphRedText: {
    color: red
  }
}))
