import React from 'react'
import { useSelector } from 'react-redux'

import { capitalizeFirstLetter } from '../../../../../../helpers/common'

import { campaignSelector } from '../../../../../../modules/selectors/campaigns'

const SpecialAdCategoriesSectionPreview = () => {
  const campaign = useSelector(campaignSelector)

  const { special_ad_categories: initialSpecialAdCategories } = campaign

  return (
    <>
      {initialSpecialAdCategories && initialSpecialAdCategories.length ? (
        <p>
          {initialSpecialAdCategories.map((item, index) =>
            index > 0 ? `, ${capitalizeFirstLetter(item)}` : capitalizeFirstLetter(item)
          )}
        </p>
      ) : null}
    </>
  )
}

export default SpecialAdCategoriesSectionPreview
