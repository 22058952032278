import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import MultiSelectBox from '../../../../../../../../features/components/Form/MultiSelectBox'

import { formatOptionsList } from '../../../../../../../../features/formatters'

import { productTagsIsLoadingSelector, productTagsSelector } from '../../../../../../../../modules/selectors/tags'

import { TAGS } from '../../../fields'

const CreatableTagsMultiSelect = ({ formik, itemPath = '' }) => {
  const { t } = useTranslation()

  const tags = useSelector(productTagsSelector)
  const tagsIsLoading = useSelector(productTagsIsLoadingSelector)

  const { values, setFieldValue, errors } = formik

  const name = itemPath ? `${itemPath}.${TAGS}` : TAGS
  const itemValues = itemPath ? getIn(values, itemPath) : values
  const itemErrors = itemPath ? getIn(errors, itemPath) : errors

  const value = getIn(itemValues, TAGS)
  const error = getIn(itemErrors, TAGS)

  const tagsOptions = useMemo(() => {
    return formatOptionsList({
      list: tags,
      labelName: 'title',
      valueName: 'title'
    })
  }, [tags])

  const handleCreateTag = useCallback(
    newTag => {
      setFieldValue(TAGS, [...values[TAGS], { value: newTag, label: newTag }])
    },
    [values, setFieldValue]
  )

  return (
    <MultiSelectBox
      placeholder={t('Select or create tags')}
      name={name}
      value={value}
      error={error}
      options={tagsOptions}
      isLoading={tagsIsLoading}
      onCreateOption={handleCreateTag}
      touched
      setFieldValue={setFieldValue}
    />
  )
}

CreatableTagsMultiSelect.propTypes = {
  formik: PropTypes.object.isRequired,
  itemPath: PropTypes.string
}

export default CreatableTagsMultiSelect
