import { useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { transformValuesToBE } from '../formatters'

import {
  clearCreateContract,
  clearUpdateContract,
  createContract,
  updateContract
} from '../../../../../../../modules/actions/contracts'
import { selectedSelfAccountSelector } from '../../../../../../../modules/selectors/app'
import { currentUserIdSelector, selectedControllerIdSelector } from '../../../../../../../modules/selectors/app'
import {
  activeContractSelector,
  contractWasCreatedSelector,
  createContractsIsLoadingSelector,
  createContractsErrorSelector,
  updateContractIsLoadingSelector,
  updateContractErrorSelector,
  contractWasUpdatedSelector
} from '../../../../../../../modules/selectors/contracts'
import { MediaOrderFormContext } from '../../../../MediaOrderFormContext'

export function useHandleContractGeneration({ currency }) {
  const dispatch = useDispatch()

  const currentUserId = useSelector(currentUserIdSelector)
  const activeContract = useSelector(activeContractSelector)
  const controllerId = useSelector(selectedControllerIdSelector)
  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)
  const createContractsWasLoaded = useSelector(contractWasCreatedSelector)
  const updateContractWasLoaded = useSelector(contractWasUpdatedSelector)

  const { setCreatedContractPayload, contextSelfAccountData } = useContext(MediaOrderFormContext)

  const contractId = activeContract?.id

  const generateContract = useCallback(
    (formikValues, productsDiscount) => {
      // generate contract if media order values were changed from previous generated contract
      const contractTransformedData = transformValuesToBE({
        values: formikValues,
        controllerId,
        selfAccountData: contextSelfAccountData,
        currency,
        productsDiscount
      })
      const contractWasCreated = Boolean(contractId)
      const contractData = {
        detail: contractTransformedData,
        account: selectedSelfAccount,
        controller: controllerId,
        created_by: currentUserId
      }

      // save contract payload as it can be reused later for media order creation without contract
      setCreatedContractPayload(contractData)

      // when contract was created and values changes we need to update it instead of creation new one
      if (contractWasCreated) {
        // update contract
        dispatch(updateContract(contractId, contractData))
      } else {
        // create new contract
        dispatch(createContract(contractData))
      }
    },
    [
      controllerId,
      contextSelfAccountData,
      currency,
      contractId,
      selectedSelfAccount,
      currentUserId,
      setCreatedContractPayload,
      dispatch
    ]
  )

  const clearContractGeneration = useCallback(() => {
    dispatch(clearCreateContract())
    dispatch(clearUpdateContract())
  }, [dispatch])

  return {
    handleSubmit: generateContract,
    successSubmit: createContractsWasLoaded || updateContractWasLoaded,
    clearSubmitHandler: clearContractGeneration,
    errorSelector: contractId ? updateContractErrorSelector : createContractsErrorSelector,
    isLoadingSelector: contractId ? updateContractIsLoadingSelector : createContractsIsLoadingSelector
  }
}
