import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import ContentRow from '../../../features/components/ContentSection/ContentRow'
import ContentSection from '../../../features/components/ContentSection'
import UserProfileContent from './UserProfileContent'
import MFAContent from './MFAContent'

import { getCurrentUserSelector } from '../../../modules/selectors/app'

import useSettingsClasses from '../styles'

function EditProfile() {
  const settingsClasses = useSettingsClasses()

  const { t } = useTranslation()

  const user = useSelector(getCurrentUserSelector)

  const { email, first_name: firstName } = user

  return (
    <>
      <h1 className={settingsClasses.title}>{t('User Profile')}</h1>
      <ContentSection title="User settings">
        <ContentRow
          title={t('userNameProfile', { userName: firstName })}
          description={t('registrationEmail', { email })}
        >
          <UserProfileContent />
        </ContentRow>
        <ContentRow title={t('Two-factor authentication')}>
          <MFAContent />
        </ContentRow>
      </ContentSection>
    </>
  )
}

export default EditProfile
