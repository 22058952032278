import React, { useMemo } from 'react'
import { Portal } from 'react-portal'
import { useMediaQuery } from 'react-responsive'

import AdCard from '../../../../../../../../../features/components/FacebookPreviews/AdCard'

import { tabletDownSize } from '../../../../../../../../../styles/const/breakpoints'

const AdaptiveAdCard = ({ values, suggestionsLoading, suggestions }) => {
  const isTablet = useMediaQuery({ maxWidth: tabletDownSize })

  const adCardProps = useMemo(() => {
    return {
      // initial values consists from product data and suggestions data
      // formik values are already edited suggestion data which become custom creative data
      formikValues: values,
      // show typing animation until first suggestion is loading,
      // when there are more than 1 suggestion we can treat that first was loaded
      typingAnimation: suggestionsLoading && suggestions?.length < 2,
      showSkeletonPlaceholders: true
    }
  }, [values, suggestionsLoading, suggestions])

  return isTablet ? (
    <AdCard {...adCardProps} />
  ) : (
    // on desktop we render AdCard in Portal in sideColumn
    <Portal node={document && document.getElementById('formSideColumnPortal')}>
      <AdCard {...adCardProps} />
    </Portal>
  )
}

export default AdaptiveAdCard
