import { useSelector } from 'react-redux'

import { activeStepSelector } from '../../../../../modules/selectors/forms'

export const useProgressItems = () => {
  const activeStep = useSelector(activeStepSelector)

  return [
    {
      show: true,
      label: 'Select media products'
    },
    {
      show: true,
      label: 'Review and book'
    }
  ].map((item, index) => {
    return {
      ...item,
      isActive: activeStep === index,
      isSuccess: activeStep > index
    }
  })
}
