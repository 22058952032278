import { createStyles } from '../../../styles/helpers'
import { textEllipsis } from '../../../styles/mixins/text'
import { leftSpaceSize } from '../../Select/styles'

const useStyles = createStyles({
  valueWrapper: {
    position: 'absolute',
    left: 0,
    paddingLeft: leftSpaceSize,
    width: '100%',
    overflow: 'hidden',

    // woraround to fis placeholedr spacing for multiple items
    '& + [class*="placeholder"]': {
      marginLeft: 1
    }
  },
  label: {
    color: 'white',
    ...textEllipsis(),
    fontWeight: 600
  }
})

export default useStyles
