import React from 'react'
import PropTypes from 'prop-types'

import Field from '../../../../../../../../components/Form/Field'

import { BID_PRICE } from '../../../../../LineItemTikTokCreate/LineItemTikTokCreateForm/fields'

import useDrawerFormStyles from '../../../../../../../../styles/common/drawerForms'

const BidPriceContent = ({ formik }) => {
  const drawerFormClasses = useDrawerFormStyles()

  return (
    <div className={drawerFormClasses.sectionItem}>
      <h4 className={drawerFormClasses.sectionSubtitle}>Bid Price</h4>
      <Field formik={formik} name={BID_PRICE} label="Bid Price" autoComplete="off" type="number" />
    </div>
  )
}

BidPriceContent.propTypes = {
  formik: PropTypes.object.isRequired
}

export default BidPriceContent
