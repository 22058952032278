import { createStyles } from '../../../styles/helpers'
import { black, dv360blue, googleAdsYellow, greyOutline } from '../../../styles/const/colors'
import { transitionShort } from '../../../styles/const/common'

const useStyles = createStyles({
  btn: {
    marginTop: 12,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    height: 'auto'
  },
  googleButton: {
    extend: 'btn',
    transition: transitionShort,
    background: ['white', '!important'],
    padding: '15px 20px',
    border: `1px solid ${greyOutline}`,
    borderRadius: 50,
    color: [black, '!important'],
    maxWidth: 208,
    '&:hover': {
      '-webkit-box-shadow': `inset 0px 0px 0px 1px ${googleAdsYellow}`,
      '-moz-box-shadow': `inset 0px 0px 0px 1px ${googleAdsYellow}`,
      'box-shadow': `inset 0px 0px 0px 1px ${googleAdsYellow}`,
      border: `1px solid  ${googleAdsYellow} !important`
    }
  },
  googleButtonIcon: {
    marginLeft: [0, '!important'],
    fontSize: 30,
    marginRight: 10,

    '& svg': {
      width: '30px',
      height: '30px'
    }
  },
  googleButtonText: {
    fontSize: 16,
    textAlign: 'left'
  },
  dv360Button: {
    extend: 'googleButton',
    maxWidth: 230,
    '&:hover': {
      '-webkit-box-shadow': `inset 0px 0px 0px 1px ${dv360blue}`,
      '-moz-box-shadow': `inset 0px 0px 0px 1px ${dv360blue}`,
      'box-shadow': `inset 0px 0px 0px 1px ${dv360blue}`,
      border: `1px solid  ${dv360blue} !important`
    }
  },

  facebookBtn: {
    extend: 'btn',
    color: 'white',
    textAlign: 'center',
    borderColor: '#3975EA !important',
    backgroundColor: '#3975EA !important',

    '&:hover': {
      color: 'white !important',
      borderColor: '#2f62c5 !important',
      backgroundColor: '#2f62c5 !important'
    }
  },
  tikTokButton: {
    extend: 'btn',
    textAlign: 'center',
    position: 'relative',
    color: 'white',
    background: `${black} !important`,
    borderColor: `${black} !important`,
    minWidth: 300,
    '&:hover': {
      color: `${greyOutline} !important`
    }
  },
  tikTokIcon: {
    height: 20,
    width: 20,
    fill: 'white',
    position: 'absolute',
    left: 12
  }
})

export default useStyles
