import { createStyles } from '../../../../../../styles/helpers'

import { black, darkGrey, googleAdsTextBlue, googleAdsTextGrey } from '../../../../../../styles/const/colors'
import { card } from '../../../../../../styles/common'

export default createStyles(theme => ({
  adPreviewContainer: {
    width: '100%',
    maxWidth: 400,
    margin: '0 auto 32px'
  },
  adPreview: {
    extend: card(theme),
    borderRadius: 13,
    padding: 14,
    fontFamily: 'Arial',
    width: '100%'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: 14,
    color: black,
    fontWeight: 'bold'
  },
  bullet: {
    fontWeight: 400,
    fontSize: 10,
    paddingLeft: 5,
    paddingRight: 8
  },
  url: {
    fontSize: 12,
    color: black
  },
  icon: {
    marginLeft: 'auto',
    color: darkGrey,
    fontSize: 13
  },
  headLineList: {
    color: googleAdsTextBlue,
    marginTop: 20,
    minHeight: '34px',
    marginBottom: 10,
    fontSize: 16
  },
  headline: {
    '&:not(:last-child)': {
      '&:after': {
        content: "' | '",
        padding: '0 5px'
      }
    }
  },
  descriptionList: {
    color: googleAdsTextGrey,
    fontSize: 12
  }
}))
