import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '../../../../components/Button'

import useStyles from './styles'

function ButtonChangeText({ className, textToChange, textTimeout = 3000, children, onClick, ...props }) {
  const [showChangedText, setShowChangedText] = useState(false)
  const classes = useStyles()

  const handleClick = useCallback(
    e => {
      setShowChangedText(true)
      onClick(e)

      setTimeout(() => {
        setShowChangedText(false)
      }, textTimeout)
    },
    [onClick, textTimeout]
  )

  return (
    <Button className={classnames(classes.btnIcon, className)} onClick={handleClick} isSmall={true} {...props}>
      {showChangedText ? textToChange : children}
    </Button>
  )
}

ButtonChangeText.propTypes = {
  className: PropTypes.string,
  textToChange: PropTypes.string.isRequired,
  textTimeout: PropTypes.number,
  onClick: PropTypes.func.isRequired
}

export default ButtonChangeText
