import React from 'react'
import Skeleton from 'react-loading-skeleton'

import SkeletonSummaryReportGraph from './SummaryReportGraph/Skeleton'

import useStyles from './styles'

function SkeletonSummaryReport() {
  const classes = useStyles()

  return (
    <div className={classes.contentRow}>
      <div className={classes.contentText} style={{ flexGrow: 1 }}>
        <h4>
          <Skeleton width={'70%'} />
        </h4>
        <p>
          <Skeleton style={{ fontSize: 12, display: 'inline-block' }} count={3} />
        </p>
      </div>
      <div className={classes.contentGraph}>
        <SkeletonSummaryReportGraph />
      </div>
    </div>
  )
}

export default SkeletonSummaryReport
