import React from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import useStyles from './styles'

function AdditionalMediaOrderInfoSkeleton({ hasTopMargin }) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classnames(classes.additionalFieldsContainer, { [classes.topMargin]: hasTopMargin })}>
      <div className={classes.fieldWrapper}>
        <h4>{t('Account')}:</h4>
        <Skeleton width={100} />
      </div>
    </div>
  )
}

AdditionalMediaOrderInfoSkeleton.propTypes = {
  hasTopMargin: PropTypes.bool
}

export default AdditionalMediaOrderInfoSkeleton
