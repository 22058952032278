import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../../components/Form'

import handleAddressesErrors from '../../helpers'
import LocationListFormFields from '../../LocationListFormFields'

import {
  COUNTRY,
  VALIDATION_ERROR_CODE,
  getInitialValues,
  validationSchema,
  transformAddressesValidationFieldsToBE,
  transformLocationListFieldsToBE
} from '../../fields'

import {
  locationAddressesValidationSelector,
  locationAddressesValidationLoadingSelector,
  locationAddressesValidationErrorSelector,
  locationAddressesIsValidationSuccessSelector,
  createLocationListSelector,
  createLocationListErrorSelector,
  createLocationListLoadingSelector
} from '../../../../../../modules/selectors/location'
import { selectedSelfAccountSelector } from '../../../../../../modules/selectors/app'
import { currentUserCountrySelector } from '../../../../../../modules/selectors/choices'
import {
  clearCreateLocationList,
  clearValidateLocationAddresses,
  createLocationList,
  validateLocationAddresses
} from '../../../../../../modules/actions/location'

import { LOCATION_LIST_CREATE } from '../../../../../../constants/forms'

import useDrawerFormsStyles from '../../../../../../styles/common/drawerForms'

const LocationListCreateForm = () => {
  const drawerFormClasses = useDrawerFormsStyles()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [addressesErrorIndexes, setAddressesErrorIndexes] = useState([])

  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)
  const currentUserCountry = useSelector(currentUserCountrySelector)
  const locationAddressesValidation = useSelector(locationAddressesValidationSelector)
  const locationAddressesValidationLoading = useSelector(locationAddressesValidationLoadingSelector)
  const locationAddressesValidationError = useSelector(locationAddressesValidationErrorSelector)
  const locationAddressesIsValidationSuccess = useSelector(locationAddressesIsValidationSuccessSelector)
  const createdLocationList = useSelector(createLocationListSelector)

  const isLocationAddressesValidationError =
    locationAddressesValidationError && locationAddressesValidationError.code === VALIDATION_ERROR_CODE

  const clearLocationListSubmit = useCallback(() => dispatch(clearCreateLocationList()), [dispatch])

  const onSubmit = useCallback(
    values => {
      // reset addressesErrorIndexes and clear redux state before doing new validation request
      // if there was already an error before
      if (isLocationAddressesValidationError) {
        dispatch(clearValidateLocationAddresses())
        setAddressesErrorIndexes([])
      }

      dispatch(validateLocationAddresses(transformAddressesValidationFieldsToBE(values)))
    },
    [dispatch, isLocationAddressesValidationError]
  )

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit
  })

  const { values, setFieldValue } = formik

  const { addresses, country } = values

  // preselect user country
  useEffect(() => {
    if (!country && currentUserCountry) {
      setFieldValue(COUNTRY, currentUserCountry.id)
    }
  }, [country, setFieldValue, currentUserCountry])

  useEffect(() => {
    if (!locationAddressesIsValidationSuccess && isLocationAddressesValidationError && !addressesErrorIndexes.length) {
      handleAddressesErrors(addresses, locationAddressesValidation, setAddressesErrorIndexes)
    }
  }, [
    locationAddressesIsValidationSuccess,
    isLocationAddressesValidationError,
    locationAddressesValidation,
    addresses,
    addressesErrorIndexes
  ])

  useEffect(() => {
    if (locationAddressesIsValidationSuccess) {
      dispatch(
        createLocationList(transformLocationListFieldsToBE(values, locationAddressesValidation, selectedSelfAccount))
      )
    }
  }, [dispatch, locationAddressesIsValidationSuccess, values, locationAddressesValidation, selectedSelfAccount])

  return (
    <>
      <Form
        formName={LOCATION_LIST_CREATE}
        formik={formik}
        successSubmit={createdLocationList.wasCreated}
        clearHandler={clearLocationListSubmit}
        errorSelector={
          locationAddressesIsValidationSuccess
            ? createLocationListErrorSelector
            : locationAddressesValidationErrorSelector
        }
        isLoadingSelector={
          locationAddressesIsValidationSuccess
            ? createLocationListLoadingSelector
            : locationAddressesValidationLoadingSelector
        }
      >
        <LocationListFormFields formik={formik} addressesErrorIndexes={addressesErrorIndexes} />
      </Form>
      {addresses.length > 10 && locationAddressesValidationLoading ? (
        <div className={drawerFormClasses.afterFormText}>
          {t(
            'It can take a few minutes to match the addresses. Please leave this page open while this process is running'
          )}{' '}
        </div>
      ) : null}
    </>
  )
}

export default LocationListCreateForm
