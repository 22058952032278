import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import ItemsLoading from '../../../components/Loaders/ItemsLoading'
import ProductCard from './ProductCard'
import SkeletonProductCard from './ProductCard/Skeleton'
import ErrorMessage from '../../../components/Form/ErrorMessage'
import SearchInput from '../../../components/SearchInput'

import { PRODUCT_ID } from './fields'

import useStyles from './styles'

const ProductFields = ({
  formik,
  searchInputPlaceholder = 'Search',
  productFieldName = PRODUCT_ID,
  loadInitialProductsHandler,
  loadMoreProductsHandler,
  dataSelector,
  isLoadingSelector,
  wasLoadedSelector,
  errorSelector,
  onProductSelect,
  showSearchField = true,
  showLinkText,
  showInventoryChip,
  selectedProductsIDs
}) => {
  const classes = useStyles()

  const catalogueProducts = useSelector(dataSelector)

  const { values, errors, touched } = formik

  const showProductError = touched[productFieldName] && errors[productFieldName]

  const { [productFieldName]: selectedProductId } = values

  const getFilteredCatalogueProducts = useMemo(() => {
    if (selectedProductsIDs?.length) {
      return catalogueProducts?.filter(product => !selectedProductsIDs?.includes(product?.id))
    }

    return catalogueProducts
  }, [selectedProductsIDs, catalogueProducts])

  return (
    <>
      {showSearchField && (
        <SearchInput
          className={classes.searchInput}
          placeholder={searchInputPlaceholder}
          onSearchChange={loadInitialProductsHandler}
        />
      )}
      <div className={classes.productCardsListContainer}>
        <ItemsLoading
          noDataWithoutIconAndDescription
          className={classes.productCardsList}
          infoBlockClassName={classes.noProductsDataInfoBlock}
          SkeletonComponent={SkeletonProductCard}
          itemsLength={catalogueProducts.length}
          errorSelector={errorSelector}
          wasLoadedSelector={wasLoadedSelector}
          isLoadingSelector={isLoadingSelector}
          loadMore={loadMoreProductsHandler ? loadMoreProductsHandler : null}
        >
          {getFilteredCatalogueProducts.map(product => (
            <ProductCard
              key={product.id}
              product={product}
              isSelected={selectedProductId === product.id}
              showLinkText={showLinkText}
              showInventoryChip={showInventoryChip}
              onProductSelectHandler={onProductSelect}
            />
          ))}
        </ItemsLoading>
      </div>
      {showProductError && <ErrorMessage error={errors[productFieldName]} />}
    </>
  )
}

ProductFields.propTypes = {
  formik: PropTypes.object.isRequired,
  searchInputPlaceholder: PropTypes.string,
  productFieldName: PropTypes.string,
  loadInitialProductsHandler: PropTypes.func,
  loadMoreProductsHandler: PropTypes.func,
  dataSelector: PropTypes.func.isRequired,
  isLoadingSelector: PropTypes.func.isRequired,
  wasLoadedSelector: PropTypes.func.isRequired,
  errorSelector: PropTypes.func.isRequired,
  onProductSelect: PropTypes.func.isRequired,
  showSearchField: PropTypes.bool,
  showLinkText: PropTypes.bool,
  showInventoryChip: PropTypes.bool,
  selectedProductsIDs: PropTypes.array
}

export default ProductFields
