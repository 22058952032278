import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { campaignSelector } from '../../../../../../modules/selectors/campaigns'
import { selectedAdAccountDataSelector } from '../../../../../../modules/selectors/app'

import { getBudgetSectionType } from '../../../../../ReusableFormFields/CampaignForms/typeHelpers'
import { formatCurrency } from '../../../../../../helpers/numbers'
import { BUDGET_LIFETIME, BUDGET_DAILY, NO_BUDGET } from '../../../../../ReusableFormFields/CampaignForms/fields'

const BudgetSectionPreview = () => {
  const { t } = useTranslation()

  const campaign = useSelector(campaignSelector)
  const { currency_symbol: currencySymbol } = useSelector(selectedAdAccountDataSelector)

  const { budget_lifetime: initialBudgetLifetime, budget_daily: initialBudgetDaily } = campaign

  const budgetType = getBudgetSectionType(initialBudgetLifetime, initialBudgetDaily)

  const formattedLifetimeBudget = formatCurrency(initialBudgetLifetime, { min: 2, max: 2 }, { symbol: currencySymbol })

  const formattedDailyBudget = formatCurrency(initialBudgetDaily, { min: 2, max: 2 }, { symbol: currencySymbol })

  if (budgetType === BUDGET_LIFETIME) {
    return (
      <p>
        {formattedLifetimeBudget} ({t('Lifetime')})
      </p>
    )
  }

  if (budgetType === BUDGET_DAILY) {
    return (
      <p>
        {formattedDailyBudget} ({t('Daily')})
      </p>
    )
  }

  if (budgetType === NO_BUDGET) {
    return <p>{t('Using ad group budgets')}</p>
  }

  return null
}

export default BudgetSectionPreview
