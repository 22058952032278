import React from 'react'
import { useSelector } from 'react-redux'

import { getAgeRangePreviewText, getGenderPreviewText } from './helpers'

import { lineItemSelector } from '../../../../../../modules/selectors/lineItems'
import {
  tikTokRegionsSelector,
  tikTokRegionsWasLoadedSelector
} from '../../../../../../modules/selectors/tikTokConstants'

const DemographicsSectionPreview = () => {
  const lineItem = useSelector(lineItemSelector)
  const tikTokRegions = useSelector(tikTokRegionsSelector)
  const tikTokRegionsWasLoaded = useSelector(tikTokRegionsWasLoadedSelector)

  const { age, gender, location: lineItemLocations } = lineItem

  const formattedAge = getAgeRangePreviewText(age)
  const formattedGender = getGenderPreviewText(gender)

  const populatedLineItemLocations = lineItemLocations.map(lineItemLocationId => {
    const tikTokRegion = tikTokRegions.find(region => String(region.id) === String(lineItemLocationId)) || {}

    return {
      id: lineItemLocationId,
      ...tikTokRegion
    }
  })

  return (
    <p>
      <span>Age: {formattedAge}</span>
      <span>Gender: {formattedGender}</span>
      {tikTokRegionsWasLoaded && (
        <span>Locations: {populatedLineItemLocations.map(location => location.name).join(', ')}</span>
      )}
    </p>
  )
}

export default DemographicsSectionPreview
