import React from 'react'

import Field from '../../../../../../components/Form/Field'

import { DISCOUNT_PERCENTAGE, DISCOUNTS, PERIODS, QUANTITY } from '../../fields'

import useStyles from '../../../../../ReusableFormFields/DiscountPairFields/styles'

const DiscountFields = ({ formik, index }) => {
  const classes = useStyles()

  return (
    <>
      <Field
        type="number"
        formik={formik}
        name={`${DISCOUNTS}[${index}].${DISCOUNT_PERCENTAGE}`}
        className={classes.percentageField}
        placeholder="Discount"
        autoComplete="off"
        symbol="%"
        symbolPosition="end"
        decimalScale={2}
      />
      <Field
        type="number"
        formik={formik}
        className={classes.valueField}
        name={`${DISCOUNTS}[${index}].${QUANTITY}`}
        placeholder="Quantity"
        autoComplete="off"
        decimalScale={0}
      />
      <Field
        type="number"
        formik={formik}
        className={classes.valueField}
        name={`${DISCOUNTS}[${index}].${PERIODS}`}
        placeholder="Periods"
        autoComplete="off"
        decimalScale={0}
      />
    </>
  )
}

export default DiscountFields
