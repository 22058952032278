import Color from 'color'

export const white = '#FFFFFF'
export const borderDarkGrey = '#555555'

export const yellow = '#F9CF48'
export const lightYellow = '#FBF1BE'

export const lightChipGreen = '#F3FFE7'
export const darkChipGreen = '#34A852'
export const lilac = '#9D5DC5'
export const darkChipOrange = '#E6934F'
export const lightChipRed = '#FFE2E4'
export const darkChipRed = '#E7122B'
export const lightChipBLue = '#EBF3FF'

export const brown = '#DB8E46'
export const darkChipBlue = '#3C8BD9'

export const oceanBlue = '#48C4F9'

export const facebookBlue = '#1778F2'
export const instagramOrange = '#FF7944'
export const dv360blue = '#40b5e8'
export const googleBlue = '#3C8BD9'
export const googleAdsTextBlue = '#1D5BCE'
export const googleAdsTextGrey = '#606060'
export const googleAdsYellow = '#ffc008'

export const grey = '#929292'
export const lightGrey2 = '#E5E5E5'
export const lighterGrey = '#F6F6F6'

export const black = '#1f1d1d'

export const transparent = 'rgba(0,0,0,0)'

// re-design colors will go here
export const navy = '#26245B'
export const textGrey = '#3D3D3D'

export const greyOutline = '#BBBBBB'
export const darkGrey = '#777777'
export const mediumGrey = '#DDDFE0'
export const lightGrey = '#F9F9F9'
export const bannerGrey = '#F0F0F0'

export const lightGreen = '#D8F4E3'
export const green = '#75D99D'
export const darkGreen = '#36BF54'

export const red = '#FF4B55'
export const lightRed = '#FEE9EA'
export const coralPink = '#EF848A'
export const redHover = Color(red).darken(0.2).rgb().string()

export const darkYellow = '#837113'

export const darkPurple = '#4C29AE'
export const lightPurple = '#DAD1F2'

export const blue = '#4597E2'

export const darkBlue = '#22819E'
export const lightBlue = '#E1F8FF'
export const orange = 'orange'
