import update from 'immutability-helper'
import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  RESEND_REGISTER,
  RESEND_REGISTER_SUCCESS,
  RESEND_REGISTER_FAILURE,
  SOCIAL_AUTH,
  SOCIAL_AUTH_SUCCESS,
  SOCIAL_AUTH_FAILURE
} from '../actions/auth'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  isLoading: false,
  resendRegister: {
    isLoading: false,
    error: {}
  },
  socialAuth: {
    isLoading: false,
    authParams: {},
    error: {}
  }
}

export default function accounts(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    case RESEND_REGISTER:
      return update(state, {
        resendRegister: { $set: { isLoading: true } }
      })
    case RESEND_REGISTER_SUCCESS:
      return update(state, {
        resendRegister: { $set: initialState.resendRegister }
      })
    case RESEND_REGISTER_FAILURE:
      return update(state, {
        resendRegister: { $set: { error: action.error } }
      })
    case SOCIAL_AUTH:
      return update(state, {
        socialAuth: {
          $set: {
            isLoading: true,
            authParams: action.params.authParams
          }
        }
      })
    case SOCIAL_AUTH_SUCCESS:
      return update(state, {
        socialAuth: {
          $merge: {
            isLoading: false
          }
        }
      })

    case SOCIAL_AUTH_FAILURE:
      return update(state, {
        socialAuth: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    default:
      return state
  }
}
