import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import Table from '../../../../../components/Table'

import { useMembersColumns } from '../../../../../features/hooks/useMembersAndInvitesColumns/useMembersColumns'

import {
  controllerMembersSelector,
  updatedControllerMemberIsLoadingSelector,
  updatedControllerMemberSelector
} from '../../../../../modules/selectors/controller'
import { openForm } from '../../../../../modules/actions/forms'

import { CONTROLLER_MEMBER_EDIT, CONTROLLER_PERMISSIONS_EDIT } from '../../../../../constants/forms'

import useStyles from '../../../../../features/hooks/useMembersAndInvitesColumns/styles'

const ControllerMembersTableData = ({ onChangeMemberStatus, onChangeMemberRole }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const controllerMembers = useSelector(controllerMembersSelector)
  const { id: memberId } = useSelector(updatedControllerMemberSelector)
  const updatedControllerMemberIsLoading = useSelector(updatedControllerMemberIsLoadingSelector)

  const itemUpdatingId = useMemo(() => {
    if (updatedControllerMemberIsLoading) {
      return memberId
    }
    return undefined
  }, [memberId, updatedControllerMemberIsLoading])

  const openEditControllerPermissionsFormHandler = useCallback(
    (e, id) => {
      dispatch(openForm({ id, formName: CONTROLLER_PERMISSIONS_EDIT }))
    },
    [dispatch]
  )

  const openEditControllerMemberFormHandler = useCallback(
    (e, id) => {
      dispatch(openForm({ id, formName: CONTROLLER_MEMBER_EDIT }))
    },
    [dispatch]
  )

  const getAdditionalControllerMemberActions = useCallback(() => {
    return [
      {
        text: 'Edit permissions',
        onClickHandler: openEditControllerPermissionsFormHandler
      },
      {
        text: 'Edit Controller Member',
        onClickHandler: openEditControllerMemberFormHandler
      }
    ]
  }, [openEditControllerPermissionsFormHandler, openEditControllerMemberFormHandler])

  const membersColumns = useMembersColumns({
    members: controllerMembers,
    onChangeMemberStatus,
    onChangeMemberRole,
    getAdditionalActions: getAdditionalControllerMemberActions
  })

  return (
    <Table
      mobileColumnsWrapperClassName={classes.mobileHeaderStyle}
      hideFooterRow
      data={controllerMembers}
      cols={membersColumns}
      itemUpdatingId={itemUpdatingId}
    />
  )
}

ControllerMembersTableData.propTypes = {
  onChangeMemberStatus: PropTypes.func.isRequired,
  onChangeMemberRole: PropTypes.func.isRequired
}

export default ControllerMembersTableData
