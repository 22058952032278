export const DISCOUNT = 'discount'
export const PRODUCT_PERIODS_DATES = 'dates'
// grouped publications dates are to use only for UI representation for example:
// regular will be 01, 02, 03, 04 and 08,09 and grouped will be 01-04 and 08-09
// this is expected to be used only on BookingPeriodSelector component to group dates by selected ranges
export const PRODUCT_GROUPED_PUBLICATIONS_DATES = 'product_grouped_publications_dates'
// product quantity
export const QUANTITY = 'quantity'
export const SINGLE_VARIABLE_OPTION = 'single_variable_option'
export const MULTIPLE_VARIABLE_OPTIONS = 'multiple_variable_options'
