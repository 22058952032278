import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../components/Button'

import useStyles from './styles'

const CancelButton = ({ onClick }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <Button className={classes.cancelButton} onClick={onClick} isSmall={true}>
      {t('Cancel')}
    </Button>
  )
}

export default CancelButton
