import { all, takeEvery, call, put, takeLatest } from 'redux-saga/effects'
import { t } from 'i18next'

import {
  clearResendQuotation,
  CREATE_QUOTATION,
  createQuotationFailure,
  createQuotationSuccess,
  GET_QUOTATIONS,
  getQuotationsFailure,
  getQuotationsSuccess,
  RESEND_QUOTATION,
  resendQuotationFailure,
  resendQuotationSuccess,
  SEND_QUOTATION,
  sendQuotationFailure,
  sendQuotationSuccess,
  UPDATE_QUOTATION,
  updateQuotationFailure,
  updateQuotationSuccess,
  UPDATE_QUOTATIONS_STATUS_BULK,
  updateQuotationsStatusBulkFailure,
  updateQuotationsStatusBulkSuccess
} from '../actions/quotations'

import {
  createQuotationService,
  getQuotationsService,
  resendQuotationService,
  sendQuotationService,
  updateQuotationService,
  updateQuotationsStatusBulkService
} from '../services/quotations'
import { showToasts } from '../../helpers/toasts'
import { TOAST_TYPE } from '../../constants/other'

function* quotationsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(CREATE_QUOTATION, createQuotationWorker),
    takeLatest(GET_QUOTATIONS, getQuotationsWorker),
    takeEvery(SEND_QUOTATION, sendQuotationWorker),
    takeEvery(RESEND_QUOTATION, resendQuotationWorker),
    takeEvery(UPDATE_QUOTATION, updateQuotationWorker),
    takeEvery(UPDATE_QUOTATIONS_STATUS_BULK, updateQuotationsStatusBulkWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* createQuotationWorker({ id, data }) {
  try {
    const response = yield call(createQuotationService, id, data)
    yield put(createQuotationSuccess(response))
  } catch (error) {
    yield put(createQuotationFailure(error))
  }
}

function* getQuotationsWorker({ params }) {
  try {
    const response = yield call(getQuotationsService, params)
    yield put(getQuotationsSuccess(response))
  } catch (error) {
    yield put(getQuotationsFailure(error))
  }
}

function* sendQuotationWorker({ data, id }) {
  try {
    const response = yield call(sendQuotationService, data, id)
    yield put(sendQuotationSuccess(response))
  } catch (error) {
    yield put(sendQuotationFailure(error))
  }
}

function* resendQuotationWorker({ id }) {
  try {
    const response = yield call(resendQuotationService, id)
    yield put(resendQuotationSuccess(response))

    showToasts({
      type: TOAST_TYPE.success,
      message: t('The quotation has been resent')
    })
    yield put(clearResendQuotation())
  } catch (error) {
    yield put(resendQuotationFailure(error))
  }
}

function* updateQuotationWorker({ id, data }) {
  try {
    const response = yield call(updateQuotationService, id, data)
    yield put(updateQuotationSuccess(response))
  } catch (error) {
    yield put(updateQuotationFailure(error))
  }
}

function* updateQuotationsStatusBulkWorker({ data }) {
  try {
    yield call(updateQuotationsStatusBulkService, data)
    yield put(updateQuotationsStatusBulkSuccess(data))
  } catch (error) {
    yield put(updateQuotationsStatusBulkFailure(error))
  }
}

export default quotationsWatcher
