import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import html2canvas from 'html2canvas'
import { t } from 'i18next'

import { insertIf } from '../../../../helpers/common'
import { getAppDomainName } from '../../../helpers/getAppDomainName'

import { mediumGrey, darkGrey } from '../../../../styles/const/colors'

pdfMake.vfs = pdfFonts.pdfMake.vfs

const A3_WIDTH = 842
// const A4_HEIGHT = 842
const PAGE_MARGIN_Y = 20
const PAGE_MARGIN_X = 20

const availablePageWidth = A3_WIDTH - PAGE_MARGIN_X * 2

export const getSectionsScreenshots = async sections => {
  const content = []

  for (let i = 0; i < sections.length; i++) {
    const section = sections[i]
    if (!section) continue

    // Generate canvas for each section and push to content
    const canvas = await html2canvas(section, {
      allowTaint: true,
      backgroundColor: null,
      useCORS: true,
      proxy: 'https://scontent-cdg4-2.xx.fbcdn.net/'
    })

    const dataUrl = canvas.toDataURL()

    content.push({
      image: dataUrl,
      width: availablePageWidth,
      marginTop: 8
    })
  }

  return content
}

const generatePdfReport = async ({ dateRangeLabel, title, contentSections, fileName }) => {
  const appName = getAppDomainName()

  // replace with svg
  // const logoBase64 = await getBase64FromUrl(logo)

  const content = [
    {
      text: title,
      alignment: 'center',
      color: darkGrey,
      marginBottom: 10,
      fontSize: 15
    },
    ...contentSections
  ]

  let docDefinition = {
    pageSize: 'A3',
    pageMargins: [PAGE_MARGIN_Y, PAGE_MARGIN_X],
    content,
    // todo replace with svg
    // images: {
    //   logo: logoBase64
    // },
    footer: function (currentPage) {
      return [
        {
          columns: [
            ...insertIf(dateRangeLabel, {
              // text is not translated due to issue to represent Arabic symbols in PDF
              text: `Reporting date range: ${dateRangeLabel}`,
              alignment: 'left',
              margin: [PAGE_MARGIN_X, 0, 0, 0],
              color: mediumGrey,
              fontSize: 8
            }),
            {
              text: t('pdfSignOff', { appName }) + currentPage.toString(),
              alignment: 'right',
              margin: [0, 0, PAGE_MARGIN_X, 0],
              color: mediumGrey,
              fontSize: 8
            }
          ]
        }
        // todo replace with svg
        // {
        //   image: 'logo',
        //   width: 10,
        //   height: 10,
        //   absolutePosition: { x: 705, y: 0 }
        // }
      ]
    }
  }

  pdfMake.createPdf(docDefinition).download(fileName)
}

export default generatePdfReport
