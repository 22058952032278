import { createStyles } from '../../../../../../styles/helpers'

import { card } from '../../../../../../styles/common'
import { darkGrey, googleBlue, lightGrey } from '../../../../../../styles/const/colors'
import { zIndex10 } from '../../../../../../styles/const/common'

export default createStyles(theme => ({
  container: {
    position: 'relative',
    extend: card(theme),
    borderRadius: 0,
    padding: 10,
    width: 300
  },
  adPreview: {
    fontFamily: 'Arial',
    width: '100%',
    borderRadius: 5,
    overflow: 'hidden'
  },
  icon: {
    background: '#fff',
    color: googleBlue,
    position: 'absolute',
    top: 2,
    right: 2,
    zIndex: zIndex10,
    display: 'flex',
    padding: 3
  },
  top: {
    backdropFilter: 'blur(20px)',
    '& img': {
      display: 'block',
      maxWidth: '100%'
    }
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10
  },
  image: {
    width: '15%'
  },
  headline: {
    fontSize: 24
  },
  description: {
    margin: 0,
    marginBottom: 20
  },
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',

    '& button': {
      width: '42% !important',
      paddingTop: '10 !important',
      paddingBottom: '10 !important',
      fontWeight: 400
    }
  },
  closeButton: {
    border: `1px solid ${lightGrey} !important`,
    paddingTop: '10 !important',
    paddingBottom: '10 !important'
  },
  openButton: {
    color: 'white !important',
    background: darkGrey,
    border: `1px solid ${darkGrey} !important`,
    paddingTop: '10 !important',
    paddingBottom: '10 !important'
  }
}))
