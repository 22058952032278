import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import ScheduleSectionForm from '../../../../../ReusableFormSections/ScheduleSection/ScheduleSectionForm'

import { clearCreateCampaignCriterions, createCampaignCriterions } from '../../../../../../modules/actions/campaigns'
import {
  campaignAdScheduleCriterionsSelector,
  createCampaignCriterionsErrorSelector,
  createCampaignCriterionsIsLoadingSelector,
  createCampaignCriterionsWasCreatedSelector
} from '../../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { criterionTypes } from '../../../../../../constants/campaigns'
import { formatScheduleDatesToBE, formatScheduleDatesToFE } from '../../../../../../features/formatters'
import { SCHEDULE } from '../../../../../ReusableFormSections/ScheduleSection/fields'

import useDrawerFormStyles from '../../../../../../styles/common/drawerForms'

const ScheduleSection = ({ editItemData: campaign, ...formProps }) => {
  const drawerFormClasses = useDrawerFormStyles()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const schedule = useSelector(campaignAdScheduleCriterionsSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const criterionsWasCreated = useSelector(createCampaignCriterionsWasCreatedSelector)

  const { id: campaignId } = campaign

  const scheduleExist = Object.keys(schedule).length

  const formattedSchedule = formatScheduleDatesToFE(schedule)

  const handleSubmit = useCallback(
    values => {
      const formattedCriterionsData = {
        account: selectedAdAccountId,
        campaign: campaignId,
        ad_schedule: formatScheduleDatesToBE(values[SCHEDULE])
      }

      dispatch(createCampaignCriterions(formattedCriterionsData, criterionTypes.schedule))
    },
    [dispatch, selectedAdAccountId, campaignId]
  )

  const handleClearCreateCriterions = useCallback(() => {
    dispatch(clearCreateCampaignCriterions())
  }, [dispatch])

  if (scheduleExist) {
    return (
      <ScheduleSectionForm
        schedule={formattedSchedule}
        handleSubmit={handleSubmit}
        clearEditItem={handleClearCreateCriterions}
        successSubmit={criterionsWasCreated}
        errorSelector={createCampaignCriterionsErrorSelector}
        isLoadingSelector={createCampaignCriterionsIsLoadingSelector}
        {...formProps}
      />
    )
  } else {
    return (
      <div className={drawerFormClasses.sectionPreview}>
        <p>{t('Ads set to run on all days at all times')}</p>
      </div>
    )
  }
}

export default ScheduleSection
