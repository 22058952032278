import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function deleteTagService(id) {
  return madeRequest('DELETE', {
    url: `${ENDPOINTS.tags}${id}/`
  })
}

export function updateTagService(id, data) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.tags}${id}/`
  })
}

export function createTagService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.tags
  })
}

export function deleteProductTagService(id) {
  return madeRequest('DELETE', {
    url: `${ENDPOINTS.mediaProductTags}${id}/`
  })
}

export function updateProductTagService(id, data) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.mediaProductTags}${id}/`
  })
}

export function createProductTagService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.mediaProductTags
  })
}

export function getProductTagsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.mediaProductTags
  })
}

export function getTagsListService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.tags
  })
}
