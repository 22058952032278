import { createStyles } from '../../../../../../styles/helpers'

const useStyles = createStyles(theme => ({
  storyItemMedia: {
    height: '100%',
    minHeight: '100%',
    maxHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',

    '& svg path': {
      fill: 'white'
    },
    '& video': {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  storyImage: {
    width: 'auto',
    maxWidth: ['unset', '!important'],
    height: '100%'
  },
  storyVideoPicture: {
    width: ['100%', '!important']
  }
}))

export default useStyles
