// booking media statuses

export const AWAITING_CREATIVES = 'awaiting_creatives'
export const PENDING_APPROVAL = 'pending_approval'
export const READY = 'ready'
export const LIVE = 'live'
export const WITH_ISSUES = 'with_issues'
export const COMPLETED = 'completed'
export const REMOVED = 'removed'

// media product types
export const PRINT = 'print'
export const DIGITAL = 'digital'
export const EDM = 'EDM'
export const OOH = 'OOH'
export const IN_STORE = 'in_store'
export const PHYSICAL_SPACES = 'physical_spaces'

export const PERIOD_DAY = 'day'
export const PERIOD_TWO_DAY = 'two_day'
export const PERIOD_THREE_DAY = 'three_day'
export const PERIOD_FOUR_DAY = 'four_day'
export const PERIOD_FIVE_DAY = 'five_day'
export const PERIOD_SIX_DAY = 'six_day'
export const PERIOD_TEN_DAY = 'ten_day'

// media product period
export const PERIOD_THREE_MONTH = 'three_month'
export const PERIOD_TWO_MONTH = 'two_month'
export const PERIOD_ONE_MONTH = 'month'
export const PERIOD_TWO_WEEKS = 'two_week'
export const PERIOD_ONE_WEEK = 'week'

export const REPRESENTATIVE = 'representative'

export const PRODUCT_OOH_TYPE = 'product_ooh_type'
export const OOH_STATIC_OPTION = 'ooh_static_option'
export const OOH_DIGITAL_OPTION = 'ooh_digital_option'

export const mediaCategoriesLabels = {
  [PRINT]: 'Print',
  [DIGITAL]: 'Digital',
  [EDM]: 'EDM',
  [OOH]: 'OOH',
  [OOH_STATIC_OPTION]: 'OOH (Static)',
  [OOH_DIGITAL_OPTION]: 'OOH (Digital)',
  [IN_STORE]: 'In-store',
  [PHYSICAL_SPACES]: 'Physical Spaces'
}

export const mediaCategories = [
  {
    value: PRINT,
    label: mediaCategoriesLabels[PRINT]
  },
  {
    value: DIGITAL,
    label: mediaCategoriesLabels[DIGITAL]
  },
  {
    value: EDM,
    label: mediaCategoriesLabels[EDM]
  },
  {
    value: OOH,
    label: mediaCategoriesLabels[OOH]
  },
  {
    value: IN_STORE,
    label: mediaCategoriesLabels[IN_STORE]
  },
  {
    value: PHYSICAL_SPACES,
    label: mediaCategoriesLabels[PHYSICAL_SPACES]
  }
]

const days = [
  {
    value: PERIOD_DAY,
    label: '1 day'
  },
  {
    value: PERIOD_TWO_DAY,
    label: '2 days'
  },
  {
    value: PERIOD_THREE_DAY,
    label: '3 days'
  },
  {
    value: PERIOD_FOUR_DAY,
    label: '4 days'
  },
  {
    value: PERIOD_FIVE_DAY,
    label: '5 days'
  },
  {
    value: PERIOD_SIX_DAY,
    label: '6 days'
  },
  {
    value: PERIOD_TEN_DAY,
    label: '10 days'
  }
]

export const inChargePeriods = [
  {
    value: PERIOD_THREE_MONTH,
    label: 'Three months'
  },
  {
    value: PERIOD_TWO_MONTH,
    label: 'Two months'
  },
  {
    value: PERIOD_ONE_MONTH,
    label: 'One month'
  },
  {
    value: PERIOD_TWO_WEEKS,
    label: 'Two weeks'
  },
  {
    value: PERIOD_ONE_WEEK,
    label: 'One week'
  },
  ...days.reverse()
]

export const periodsPluralTitle = {
  [PERIOD_THREE_MONTH]: '3 month periods',
  [PERIOD_TWO_MONTH]: '2 month periods',
  [PERIOD_ONE_MONTH]: 'months',
  [PERIOD_TWO_WEEKS]: '2 week periods',
  [PERIOD_ONE_WEEK]: 'weeks',
  [PERIOD_DAY]: 'days',
  [PERIOD_TWO_DAY]: '2 day periods',
  [PERIOD_THREE_DAY]: '3 day periods',
  [PERIOD_FOUR_DAY]: '4 day periods',
  [PERIOD_FIVE_DAY]: '5 day periods',
  [PERIOD_SIX_DAY]: '6 day periods',
  [PERIOD_TEN_DAY]: '10 day periods'
}
export const periodDayValues = {
  [PERIOD_DAY]: 1,
  [PERIOD_TWO_DAY]: 2,
  [PERIOD_THREE_DAY]: 3,
  [PERIOD_FOUR_DAY]: 4,
  [PERIOD_FIVE_DAY]: 5,
  [PERIOD_SIX_DAY]: 6,
  [PERIOD_TEN_DAY]: 10
}

export const validMediaOrdersStatuses = [
  'awaiting_creatives',
  'pending_approval',
  'ready',
  'live',
  'completed',
  'with_issues',
  'cancelled'
]

export const PERIOD_DAYS_QUANTITY = {
  [PERIOD_DAY]: 1,
  [PERIOD_TWO_DAY]: 2,
  [PERIOD_THREE_DAY]: 3,
  [PERIOD_FOUR_DAY]: 4,
  [PERIOD_FIVE_DAY]: 5,
  [PERIOD_SIX_DAY]: 6,
  [PERIOD_TEN_DAY]: 10,
  [PERIOD_ONE_WEEK]: 7,
  [PERIOD_TWO_WEEKS]: 14,
  [PERIOD_ONE_MONTH]: 30,
  [PERIOD_TWO_MONTH]: 60,
  [PERIOD_THREE_MONTH]: 90
}
