import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

export const formsOpenStatesSelector = createSelector(
  ({ forms }) => forms.formsOpenStates,
  formsOpenStates => formsOpenStates
)

export const formDrawerMinimizeStatesSelector = createSelector(
  ({ forms }) => forms.formDrawerMinimize,
  formDrawerMinimize => formDrawerMinimize
)

export const activeStepSelector = createSelector(
  ({ forms }) => forms.formActiveStep,
  formActiveStep => formActiveStep
)

export const formPrevActiveStepSelector = createSelector(
  ({ forms }) => forms.formPrevActiveStep,
  prevFormActiveStep => prevFormActiveStep
)

export const formItemIdSelector = createSelector(
  ({ forms }) => forms.formItemId,
  formItemId => formItemId
)
export const formSuccessSubmittedSelector = formName =>
  createSelector(
    ({ forms }) => forms.formSuccessfullySubmitted[formName],
    formSuccessfullySubmitted => formSuccessfullySubmitted
  )
export const formIsSubmittingSelector = formName =>
  createSelector(
    ({ forms }) => forms.formIsSubmitting[formName],
    formIsSubmitting => formIsSubmitting
  )
