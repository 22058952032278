import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'
import { replaceRouteParams } from '../../helpers/url'

// PLOP_APPEND_PATTERN_ANCHOR

export function getMediaOrderSuggestedChangesService(id) {
  return madeRequest('GET', {
    url: replaceRouteParams(ENDPOINTS.mediaOrderSuggestedChanges, { id })
  })
}

export function applyMediaOrderSuggestedChangesService(id) {
  return madeRequest('POST', {
    url: replaceRouteParams(ENDPOINTS.mediaOrderApplySuggestedChanges, { id })
  })
}

export function getOptimisationHistoryService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.optimizationHistory
  })
}
