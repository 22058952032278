import React from 'react'

import Field from '../../../../../../components/Form/Field'

import useStyles from './styles'
import { INCREASE, PRICE_CHANGE, priceChangeOptions } from '../../fields'

const PriceChangePeriodsFields = ({ formik }) => {
  const classes = useStyles()

  return (
    <div className={classes.priceChangeContainer}>
      <Field
        className={classes.increase}
        formik={formik}
        name={INCREASE}
        options={priceChangeOptions}
        placeholder="Increase"
      />
      <Field
        placeholder="0"
        className={classes.priceChange}
        name={PRICE_CHANGE}
        formik={formik}
        symbol="%"
        symbolPosition="end"
      />
    </div>
  )
}

export default PriceChangePeriodsFields
