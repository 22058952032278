import React from 'react'
import PropTypes from 'prop-types'

import { getLongHeadlineData } from '../HeadlinesSection/helpers'

const LongHeadlinesSectionPreview = ({ data }) => {
  const { assets } = data
  const longHeadlineData = getLongHeadlineData(assets)

  if (!longHeadlineData || !longHeadlineData.length) {
    return null
  }

  return (
    <p>
      {longHeadlineData.map(({ asset_data }, index) => (
        <span key={index}>{asset_data.text_asset.text}</span>
      ))}
    </p>
  )
}

LongHeadlinesSectionPreview.propTypes = {
  data: PropTypes.object
}

export default LongHeadlinesSectionPreview
