import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import RadioBlock from '../../../components/Form/RadioBlock'
import ScheduleSelector from '../../../components/ScheduleSelector'
import Checkbox from '../../../components/Form/Checkbox'
import TriggerFields from '../TriggerFields'

import { campaignSelector } from '../../../modules/selectors/campaigns'

import { CHIP_COLORS } from '../../../constants/other'
import { CONVERSIONS } from '../../../constants/campaigns'
import {
  SCHEDULE_FRIDAY,
  SCHEDULE_MONDAY,
  SCHEDULE_OPTION,
  SCHEDULE_OPTION_ALL_THE_TIME,
  SCHEDULE_OPTION_BASED_ON_WEATHER,
  SCHEDULE_OPTION_SPECIFIC_DAYS,
  SCHEDULE_OPTION_SPECIFIC_TIMES,
  SCHEDULE_SATURDAY,
  SCHEDULE_SUNDAY,
  SCHEDULE_THURSDAY,
  SCHEDULE_TUESDAY,
  SCHEDULE_WEDNESDAY,
  SCHEDULE_DATES
} from './fields'

const ScheduleFields = ({ formik, hideScheduleFields, showBasedOnTheWeather = true }) => {
  const { t } = useTranslation()
  const { values, setFieldValue, errors, touched } = formik

  const { objective: campaignObjective } = useSelector(campaignSelector)

  const isConversions = campaignObjective === CONVERSIONS

  const onCheckboxCheck = name => {
    setFieldValue(name, !values[name])
  }

  return (
    <>
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_schedule_option_all_the_time"
        name={SCHEDULE_OPTION}
        value={SCHEDULE_OPTION_ALL_THE_TIME}
        selectedValue={values[SCHEDULE_OPTION]}
        label={t('All the time')}
        badgeText={isConversions ? 'Recommended' : ''}
        badgeColor={CHIP_COLORS.green}
      />
      {!hideScheduleFields && (
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_schedule_option_specific_days"
          name={SCHEDULE_OPTION}
          value={SCHEDULE_OPTION_SPECIFIC_DAYS}
          selectedValue={values[SCHEDULE_OPTION]}
          label={t('Only on specific days')}
          error={errors[SCHEDULE_OPTION]}
          touched={touched[SCHEDULE_OPTION]}
        >
          <Checkbox
            id="checkbox_schedule_monday"
            title="Monday"
            isLarge
            checked={values[SCHEDULE_MONDAY]}
            onCheck={() => onCheckboxCheck(SCHEDULE_MONDAY)}
          />
          <Checkbox
            id="checkbox_schedule_tuesday"
            title="Tuesday"
            isLarge
            checked={values[SCHEDULE_TUESDAY]}
            onCheck={() => onCheckboxCheck(SCHEDULE_TUESDAY)}
          />
          <Checkbox
            id="checkbox_schedule_wednesday"
            title="Wednesday"
            isLarge
            checked={values[SCHEDULE_WEDNESDAY]}
            onCheck={() => onCheckboxCheck(SCHEDULE_WEDNESDAY)}
          />
          <Checkbox
            id="checkbox_schedule_thursday"
            title="Thursday"
            isLarge
            checked={values[SCHEDULE_THURSDAY]}
            onCheck={() => onCheckboxCheck(SCHEDULE_THURSDAY)}
          />
          <Checkbox
            id="checkbox_schedule_friday"
            title="Friday"
            isLarge
            checked={values[SCHEDULE_FRIDAY]}
            onCheck={() => onCheckboxCheck(SCHEDULE_FRIDAY)}
          />
          <Checkbox
            id="checkbox_schedule_saturday"
            title="Saturday"
            isLarge
            checked={values[SCHEDULE_SATURDAY]}
            onCheck={() => onCheckboxCheck(SCHEDULE_SATURDAY)}
          />
          <Checkbox
            id="checkbox_schedule_sunday"
            title="Sunday"
            isLarge
            checked={values[SCHEDULE_SUNDAY]}
            onCheck={() => onCheckboxCheck(SCHEDULE_SUNDAY)}
          />
        </RadioBlock>
      )}
      {!hideScheduleFields && (
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_schedule_option_specific_times"
          name={SCHEDULE_OPTION}
          value={SCHEDULE_OPTION_SPECIFIC_TIMES}
          selectedValue={values[SCHEDULE_OPTION]}
          label={t('Only at specific times')}
          error={errors[SCHEDULE_DATES]}
          touched={touched[SCHEDULE_DATES]}
        >
          <ScheduleSelector name={SCHEDULE_DATES} value={values[SCHEDULE_DATES]} setFieldValue={setFieldValue} />
        </RadioBlock>
      )}
      {showBasedOnTheWeather && (
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_schedule_option_based_on_weather"
          name={SCHEDULE_OPTION}
          value={SCHEDULE_OPTION_BASED_ON_WEATHER}
          selectedValue={values[SCHEDULE_OPTION]}
          label={t('Based on the weather')}
        >
          <TriggerFields formik={formik} />
        </RadioBlock>
      )}
    </>
  )
}

ScheduleFields.propTypes = {
  hideScheduleFields: PropTypes.bool,
  showBasedOnTheWeather: PropTypes.bool
}

export default ScheduleFields
