import React from 'react'
import { useTranslation } from 'react-i18next'

import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'
import MediaPackageImageUpload from '../../../../MediaPackageCreate/MediaPackageCreateForm/Steps/ImageStep/MediaPackageImageUpload'

import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

const ImageSection = ({ formik }) => {
  const drawerFormClasses = useDrawerFormStyles()

  const { t } = useTranslation()

  return (
    <div className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>{t('Image')}</h3>
      <FieldRow
        title="Image file"
        description={
          <>
            <p>{t('File type: png (transparent)')}</p>
            <p>{t('Dimensions: 600x600 px')}</p>
            <p>{t('Max file size: 200kb')}</p>
          </>
        }
      >
        <MediaPackageImageUpload formik={formik} />
      </FieldRow>
    </div>
  )
}

export default ImageSection
