import { call, put, all, takeEvery } from 'redux-saga/effects'

import { GET_BATCH_INFO, getBatchInfoFailure, getBatchInfoSuccess } from '../actions/batchInfo'
import { getBatchInfoService } from '../services/batchInfo'

function* batchInfoWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_BATCH_INFO, getBatchInfoWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getBatchInfoWorker({ params }) {
  try {
    const response = yield call(getBatchInfoService, params)
    yield put(getBatchInfoSuccess(response))
  } catch (e) {
    yield put(getBatchInfoFailure(e))
  }
}

export default batchInfoWatcher
