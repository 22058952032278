import React from 'react'
import { components } from 'react-select'

import { ReactComponent as DownIcon } from '../../../assets/icons/chevron-arrow-down.svg'

export default function SelectDropdownIndicator(props) {
  const { isIconSelect } = props.selectProps

  return (
    <components.DropdownIndicator {...props} className={isIconSelect && 'iconDropdown'}>
      {props.selectProps.CustomDownIcon || <DownIcon />}
    </components.DropdownIndicator>
  )
}
