import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import Table from '../../../../../components/Table'
import AdaptiveActions from '../../../../../features/components/AdaptiveActions'

import { agenciesSelector } from '../../../../../modules/selectors/agencies'
import { openForm } from '../../../../../modules/actions/forms'

import { tableColumnsSize } from '../../../Bookings/BookingsTable'
import { AGENCY_EDIT } from '../../../../../constants/forms'

import { phonesDownSize } from '../../../../../styles/const/breakpoints'

const AgencyAccountsTableData = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })
  const dispatch = useDispatch()

  const agencies = useSelector(agenciesSelector)

  const openEditFormHandler = useCallback(
    (e, id) => {
      dispatch(openForm({ formName: AGENCY_EDIT, id }))
    },
    [dispatch]
  )

  const dropdownOptions = useMemo(() => {
    return [
      {
        text: t('Edit Account'),
        onClickHandler: openEditFormHandler
      }
    ]
  }, [openEditFormHandler, t])

  const accountsColumns = useMemo(
    () => [
      {
        header: 'Name',
        fieldKey: 'name'
      },
      {
        Cell: ({ id }) => <AdaptiveActions itemId={id} options={dropdownOptions} />,
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [isMobile, dropdownOptions]
  )

  return <Table hideFooterRow data={agencies} cols={accountsColumns} />
}

export default AgencyAccountsTableData
