import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Modal from '../../../components/Modal'
import ProgressButton from '../../../components/Form/ProgressButton'
import { ReactComponent as CalendarColorful } from '../../../assets/calendarTimeline/icons/calendar-colorful.svg'

import { selectedSelfAccountSelector } from '../../../modules/selectors/app'
import { createCalendar } from '../../../modules/actions/calendarPlanner'

import useStyles from './styles'
import {
  calendarCreatedSelector,
  calendarCreateErrorSelector,
  calendarCreateIsLoadingSelector
} from '../../../modules/selectors/calendarPlanner'

const CreateCalendarPlanner = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)
  const calendarCreatedData = useSelector(calendarCreatedSelector)
  const calendarCreateError = useSelector(calendarCreateErrorSelector)
  const calendarCreateIsLoading = useSelector(calendarCreateIsLoadingSelector)

  const handleCreateCalendarPlanner = useCallback(() => {
    const calendarData = {
      account: selectedSelfAccount,
      name: 'Planner'
    }
    dispatch(createCalendar(calendarData))
  }, [dispatch, selectedSelfAccount])

  return (
    <Modal isOpen shouldFocusAfterRender={true} showHeader>
      <CalendarColorful />
      <h2>Create a planner</h2>
      <p>
        {t(
          'The planner is an easy and visual way to lay down your monthly, quarterly or annual media plans and' +
            ' include key events and promo periods.'
        )}
      </p>
      <ProgressButton
        formError={calendarCreateError}
        isFormLoading={calendarCreateIsLoading}
        successSubmit={calendarCreatedData}
        onClick={handleCreateCalendarPlanner}
        className={classes.createPlannerBtn}
      >
        {t('Create Blank Planner')}
      </ProgressButton>
    </Modal>
  )
}

export default CreateCalendarPlanner
