import React from 'react'
import { components } from 'react-select'

import { useStyles } from '../styles'

const SelectMenu = props => {
  const { isIconSelect, menuWidth } = props.selectProps

  const classes = useStyles({ isIconSelect, menuWidth })

  return (
    <div className={classes.menuContainer}>
      <components.Menu {...props} className={classes.menu} />
    </div>
  )
}

export default SelectMenu
