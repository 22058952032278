import React from 'react'
import { useTranslation } from 'react-i18next'

import RadioBlock from '../../../components/Form/RadioBlock'
import InlineField from '../../../components/Form/InlineField'
import Spoiler from '../../../components/Spoiler'

import { OPTION_CUSTOM } from '../../../constants/forms'
import {
  FREQUENCY_DAYS,
  FREQUENCY_IMPRESSIONS,
  FREQUENCY_OPTION,
  FREQUENCY_OPTION_AUTOMATIC,
  FREQUENCY_OPTION_DEFAULT
} from './fields'

const FrequencyFields = ({ formik, showNoFrequencyOption = true, noFrequencyOptionLabel = 'Leave it to platform' }) => {
  const { t } = useTranslation()

  const { values, setFieldValue, errors, touched } = formik

  const impressionError = errors[FREQUENCY_IMPRESSIONS]
  const daysError = errors[FREQUENCY_DAYS]

  const isImpressionsTouched = touched[FREQUENCY_IMPRESSIONS]
  const isDaysTouched = touched[FREQUENCY_DAYS]

  const isTouched = isImpressionsTouched || isDaysTouched
  const error = impressionError || daysError

  return (
    <>
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_frequency_option_frequency_default"
        name={FREQUENCY_OPTION}
        value={FREQUENCY_OPTION_DEFAULT}
        selectedValue={values[FREQUENCY_OPTION]}
        label={t('Show the ads no more than 2 times every 7 days')}
      />
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_frequency_option_frequency_custom"
        name={FREQUENCY_OPTION}
        value={OPTION_CUSTOM}
        selectedValue={values[FREQUENCY_OPTION]}
        error={error}
        touched={isTouched}
        label={t('Custom')}
      >
        {t('Show the ads no more than')}
        <InlineField formik={formik} error={impressionError} name={FREQUENCY_IMPRESSIONS} type="number" />
        {t('times every')}
        <InlineField formik={formik} error={daysError} name={FREQUENCY_DAYS} type="number" />
        {t('days')}
      </RadioBlock>
      {showNoFrequencyOption && (
        <Spoiler title={t('More frequency cap options')}>
          <RadioBlock
            setFieldValue={setFieldValue}
            id="radio_frequency_option_automatic"
            name={FREQUENCY_OPTION}
            value={FREQUENCY_OPTION_AUTOMATIC}
            selectedValue={values[FREQUENCY_OPTION]}
            label={t(noFrequencyOptionLabel)}
          />
        </Spoiler>
      )}
    </>
  )
}

export default FrequencyFields
