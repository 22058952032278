import React from 'react'
import PropTypes from 'prop-types'

import SkeletonWebsiteText from './SkeletonWebsiteText'

import { getUrlDomain } from '../../../../../helpers/url'

import { AD_LINK } from '../../../../../forms/Facebook/AdForms/fields'

import useStyles from '../styles'

function WebsiteText({ formikValues, itemPath = '' }) {
  const classes = useStyles()

  const mediaItem = formikValues[itemPath]
  const adLink = mediaItem[AD_LINK]

  // get the domain of the entered URL
  const urlDomain = getUrlDomain(adLink)

  if (urlDomain) {
    return urlDomain
  } else {
    return <SkeletonWebsiteText count={1} className={classes.skeletonWebsiteText} />
  }
}

WebsiteText.propTypes = {
  formikValues: PropTypes.object.isRequired,
  itemPath: PropTypes.string
}

export default WebsiteText
