import React, { useCallback, useContext } from 'react'

import HighlightedValueSelect from '../../../../components/HighlightedValueSelect'

import { statusFilterOptions } from './options'

import useStyles from '../../../../pages/Settings/BookedMediaAndFiles/BookedMediaAndFilesFilters/styles'

const BookedMediaStatusFilter = ({ Context }) => {
  const classes = useStyles()

  const { statusFilterOption, setStatusFilterOption } = useContext(Context)

  const onStatusFilterChange = useCallback(
    option => {
      setStatusFilterOption(option?.value)
    },
    [setStatusFilterOption]
  )

  return (
    <HighlightedValueSelect
      placeholder="Status"
      value={statusFilterOption}
      onChange={onStatusFilterChange}
      className={classes.select}
      options={statusFilterOptions}
      isSmall
      portaled
    />
  )
}

export default BookedMediaStatusFilter
