import * as Yup from 'yup'
import {
  LOCATION_LIST,
  SHOW_COUNTRIES,
  SHOW_REGIONS,
  LOCATIONS_LIST_TAB,
  LOCATIONS_SELECTED_TAB,
  LOCATIONS_TAB,
  TARGET_BY_REGION
} from '../fields'
import { regionsOverlapValidation } from '../../../../features/validations/gelolocations'

export const locationValidation = {
  [SHOW_COUNTRIES]: Yup.array().when([LOCATIONS_SELECTED_TAB, TARGET_BY_REGION], {
    is: (selectedTab, targetByRegion) => {
      const isLocationsTab = selectedTab === LOCATIONS_TAB
      return isLocationsTab && !targetByRegion
    },
    then: () => Yup.array().min(1, 'Country Required')
  }),
  [SHOW_REGIONS]: Yup.array().when([LOCATIONS_SELECTED_TAB, TARGET_BY_REGION], {
    is: (selectedTab, targetByRegion) => {
      const isLocationsTab = selectedTab === LOCATIONS_TAB
      return isLocationsTab && targetByRegion
    },
    then: () => regionsOverlapValidation
  }),
  [LOCATION_LIST]: Yup.string().when(LOCATIONS_SELECTED_TAB, {
    is: LOCATIONS_LIST_TAB,
    then: () => Yup.string().required('Location list Required')
  })
}
