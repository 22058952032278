import { createStyles } from '../../../../styles/helpers'

import { darkGrey, grey, greyOutline } from '../../../../styles/const/colors'
import { transitionShort } from '../../../../styles/const/common'
import { disabled } from '../../../../styles/common'
import { loaderClassName } from '../../../../components/Loaders/Loader'

export default createStyles(theme => ({
  btnIcon: {
    border: hasBorder => (hasBorder ? `1px solid ${darkGrey}` : 'none'),
    borderRadius: theme.defaultBorderRadius,
    fontSize: 16,
    padding: 5,
    lineHeight: 1,
    cursor: 'pointer',
    backgroundColor: 'transparent',

    ['& .' + loaderClassName]: {
      width: '100%'
    },

    '&:hover': {
      borderColor: theme.brandPrimary,

      '& $icon path': {
        fill: grey
      }
    },

    '&:focus': {
      outline: 'none'
    },

    '&:disabled': {
      extend: disabled
    }
  },

  icon: {
    display: 'flex',

    '& path': {
      transition: transitionShort,
      fill: greyOutline
    }
  }
}))
