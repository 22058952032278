import {
  DV_360_PLATFORM,
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  TIKTOK_PLATFORM
} from '../../constants/selectLists/platformList'

export const getAdStructureLabel = platform => {
  switch (platform) {
    case FACEBOOK_PLATFORM:
      return 'Ad set'
    case DV_360_PLATFORM:
      return 'Line item'
    case GOOGLE_PLATFORM:
    case TIKTOK_PLATFORM:
      return 'Ad Group'
    default:
      return ''
  }
}
