import React from 'react'

import Skeleton from 'react-loading-skeleton'

const DropdownSkeleton = () => {
  return (
    <div>
      <Skeleton height={14} width={'50%'} style={{ marginBottom: 6 }} />
      <Skeleton height={12} width={'20%'} style={{ marginBottom: 6 }} />
      <Skeleton height={14} width={'70%'} style={{ marginBottom: 6 }} />
      <Skeleton height={16} width={'50%'} style={{ marginBottom: 14 }} />
      <Skeleton height={38} style={{ marginBottom: 8 }} />
      <Skeleton height={38} />
    </div>
  )
}

export default DropdownSkeleton
