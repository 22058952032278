import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ItemsLoading from '../../../../components/Loaders/ItemsLoading'
import GoogleAdCardSkeleton from '../../../MediaOrderSummary/MediaOrderSummaryContent/MediaOrderCampaignsReports/HighestPerformingAds/GooglePerformingAds/GoogleAdCard/Skeleton'
import AdsWrapper from '../../../MediaOrderSummary/MediaOrderSummaryContent/MediaOrderCampaignsReports/HighestPerformingAds/AdsWrapper'
import GoogleAssetGroupPreviewCard from './GoogleAssetGroupPreviewCard'

import useGetAssetGroupActionsDropdownOptions from './useGetAssetGroupActionsDropdownOptions'

import {
  clearApproveInternalAssetGroup,
  clearDeleteInternalAssetGroup,
  clearUpdateAssetGroup,
  updateAssetGroup
} from '../../../../modules/actions/assets'
import {
  approveInternalAssetGroupErrorSelector,
  approveInternalAssetGroupIsLoadingSelector,
  approveInternalAssetGroupSelector,
  approveInternalAssetGroupWasApprovedSelector,
  assetGroupsErrorSelector,
  assetGroupsIsLoadingSelector,
  assetGroupsSelector,
  assetGroupsWasLoadedSelector,
  deleteInternalAssetGroupErrorSelector,
  deleteInternalAssetGroupIsLoadingSelector,
  deleteInternalAssetGroupSelector,
  deleteInternalAssetGroupWasDeletedSelector,
  updateAssetGroupErrorSelector,
  updateAssetGroupIsLoadingSelector,
  updateAssetGroupSelector,
  updateAssetGroupWaUpdatedSelector
} from '../../../../modules/selectors/assets'
import { campaignWasLoadedSelector } from '../../../../modules/selectors/campaigns'

import { listItemChangeStatus } from '../../../../constants/other'

import useStyles from './styles'
import { selectedAdAccountIdSelector } from '../../../../modules/selectors/app'

function AssetGroupsContent({ showOnlyHighestPerforming, loadMoreHandler }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles(260)

  const [isStatusUpdateRunning, setIsStatusUpdateRunning] = useState(false)

  const assetGroups = useSelector(assetGroupsSelector)
  const campaignWasLoaded = useSelector(campaignWasLoadedSelector)
  const updateAssetGroupData = useSelector(updateAssetGroupSelector)
  const updateAssetGroupIsLoading = useSelector(updateAssetGroupIsLoadingSelector)
  const updateAssetGroupWasUpdated = useSelector(updateAssetGroupWaUpdatedSelector)
  const updateAssetGroupError = useSelector(updateAssetGroupErrorSelector)
  const approveInternalAssetGroupIsLoading = useSelector(approveInternalAssetGroupIsLoadingSelector)
  const approveInternalAssetGroupData = useSelector(approveInternalAssetGroupSelector)
  const approveInternalAssetGroupWasApproved = useSelector(approveInternalAssetGroupWasApprovedSelector)
  const approveInternalAssetGroupError = useSelector(approveInternalAssetGroupErrorSelector)
  const deleteInternalAssetGroupIsLoading = useSelector(deleteInternalAssetGroupIsLoadingSelector)
  const deleteInternalAssetGroupData = useSelector(deleteInternalAssetGroupSelector)
  const deleteInternalAssetGroupWasDeleted = useSelector(deleteInternalAssetGroupWasDeletedSelector)
  const deleteInternalAssetGroupError = useSelector(deleteInternalAssetGroupErrorSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const itemUpdatingId = useMemo(() => {
    // avoid representation of skeleton row during status update (happens from toggle button)
    if (updateAssetGroupIsLoading && !isStatusUpdateRunning) {
      return updateAssetGroupData.id
    } else if (approveInternalAssetGroupIsLoading) {
      return approveInternalAssetGroupData.id
    } else if (deleteInternalAssetGroupIsLoading) {
      return deleteInternalAssetGroupData.id
    }

    return null
  }, [
    isStatusUpdateRunning,
    updateAssetGroupData.id,
    updateAssetGroupIsLoading,
    approveInternalAssetGroupIsLoading,
    approveInternalAssetGroupData.id,
    deleteInternalAssetGroupIsLoading,
    deleteInternalAssetGroupData.id
  ])

  const noDataMessageText = useMemo(
    () => (
      <>
        {t('There are no asset groups to show.')}
        <br />
        {t('Click ‘Create Ad’ to set one up.')}
      </>
    ),
    [t]
  )

  const getGetAssetGroupActionsDropdownOptions = useGetAssetGroupActionsDropdownOptions()

  const updateAssetGroupStatusHandler = useCallback(
    assetGroup => {
      setIsStatusUpdateRunning(true)

      dispatch(
        updateAssetGroup(
          {
            account: selectedAdAccountId,
            status: listItemChangeStatus[assetGroup.status]?.changeTo
          },
          assetGroup.id
        )
      )
    },
    [dispatch, selectedAdAccountId]
  )

  useEffect(() => {
    if (isStatusUpdateRunning && (updateAssetGroupWasUpdated || updateAssetGroupError)) {
      dispatch(clearUpdateAssetGroup())

      setIsStatusUpdateRunning(false)
    }
  }, [dispatch, updateAssetGroupError, updateAssetGroupWasUpdated, isStatusUpdateRunning])

  useEffect(() => {
    if (approveInternalAssetGroupWasApproved || approveInternalAssetGroupError) {
      dispatch(clearApproveInternalAssetGroup())
    }
  }, [dispatch, approveInternalAssetGroupError, approveInternalAssetGroupWasApproved])

  useEffect(() => {
    if (deleteInternalAssetGroupWasDeleted || deleteInternalAssetGroupError) {
      dispatch(clearDeleteInternalAssetGroup())
    }
  }, [dispatch, deleteInternalAssetGroupError, deleteInternalAssetGroupWasDeleted])

  return (
    <section id="google-ad-cards-list">
      <ItemsLoading
        additionalDataIsLoading={!campaignWasLoaded}
        noDataWithoutIconAndDescription
        itemsLength={assetGroups.length}
        className={classes.cardsList}
        // noDataText needs to be empty string for not using default noDataText
        noDataText={showOnlyHighestPerforming ? '' : noDataMessageText}
        contentWrapperText={t(showOnlyHighestPerforming ? 'Highest performing ads' : 'Ads')}
        isLoadingSelector={assetGroupsIsLoadingSelector}
        wasLoadedSelector={assetGroupsWasLoadedSelector}
        errorSelector={assetGroupsErrorSelector}
        initialLoadingSkeletonsNumber={5}
        SkeletonComponent={GoogleAdCardSkeleton}
        ContentWrapper={AdsWrapper}
        loadMore={loadMoreHandler}
      >
        {assetGroups.map(ad => {
          return ad.id === itemUpdatingId ? (
            <GoogleAdCardSkeleton />
          ) : (
            <GoogleAssetGroupPreviewCard
              assetGroup={ad}
              updateAdStatusHandler={updateAssetGroupStatusHandler}
              getAdActionsDropdownOptions={getGetAssetGroupActionsDropdownOptions}
            />
          )
        })}
      </ItemsLoading>
    </section>
  )
}

export default AssetGroupsContent
