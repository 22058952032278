import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIn, setIn } from 'formik'

import { isStepFieldsValid, scrollToContainerTop } from '../helpers'
import { keys } from '../../../../../helpers/common'

import { setFormStepNext, setFormStepPrevious, setStep } from '../../../../../modules/actions/forms'

import { activeStepSelector } from '../../../../../modules/selectors/forms'

import { handleScrollToFieldWithError } from '../../../../../components/Form/helpers'

export default function useStepForm({ initialStep, stepsContainerEl, stepFormik, formName, onBackButtonClick }) {
  const dispatch = useDispatch()

  const activeStep = useSelector(activeStepSelector)

  const changeStep = useCallback(newStep => dispatch(setStep(newStep)), [dispatch])

  const nextStep = useCallback(() => dispatch(setFormStepNext()), [dispatch])

  const validateStepFieldsAndProceed = useCallback(
    (errors, stepFields) => {
      if (isStepFieldsValid(errors, stepFields)) {
        // if current step has no errors - proceed to the next step
        nextStep()
      } else {
        // if current step has errors - scroll the step to the top field with error
        handleScrollToFieldWithError(formName, activeStep)
      }
    },
    [formName, activeStep, nextStep]
  )

  const backStep = useCallback(() => {
    onBackButtonClick && onBackButtonClick()
    dispatch(setFormStepPrevious())
  }, [dispatch, onBackButtonClick])

  const stepFormikTouched = stepFormik.touched

  const getNewTouchedValues = useCallback(
    (field, fieldValue) => {
      let touched
      let hasUntouchedFields = false
      if (Array.isArray(fieldValue)) {
        // check each array field and recursively go through the getNewTouchedValues to check touched fields for
        // objects or simple fields
        touched = getIn(stepFormikTouched, field) || []

        if (!!fieldValue.length) {
          fieldValue.forEach((subField, index) => {
            // recursively go through getNewTouchedValues to touch field and it subfields(in case subField is object)
            const newTouchedValue = getNewTouchedValues(`${field}.${index}`, subField)

            if (!!newTouchedValue) {
              hasUntouchedFields = true
              // touch array index element
              touched[index] = newTouchedValue
            }
          })
        } else {
          // when there is no value set to the array set it first element to true
          const wasTouched = getIn(stepFormikTouched, field)
          if (!wasTouched) {
            hasUntouchedFields = true
            touched[0] = true
          }
        }
      } else if (typeof fieldValue === 'object') {
        // check object first level of subfields
        touched = getIn(stepFormikTouched, field) || {}

        keys(fieldValue).forEach(subField => {
          const touchedField = getIn(stepFormikTouched, field)
          const wasTouched = touchedField && touchedField[subField]
          if (!wasTouched) {
            hasUntouchedFields = true
            touched[subField] = true
          }
        })
      } else {
        // simple text/number/bool field
        const wasTouched = getIn(stepFormikTouched, field)
        if (!wasTouched) {
          hasUntouchedFields = true
          touched = true
        }
      }

      return hasUntouchedFields ? touched : false
    },
    [stepFormikTouched]
  )

  const checkFlatFields = useCallback(
    stepFields => {
      // make sure that all step field are touched/validated
      let hasUntouchedFields = false
      stepFields.forEach(field => {
        if (!stepFormik.touched[field]) {
          hasUntouchedFields = true
        }
      })

      if (hasUntouchedFields) {
        // if field wasn't touched it will not be validated
        // in this case field triggers manually to be touched and run validation
        const updateTouched = {}
        stepFields.forEach(field => (updateTouched[field] = true))

        stepFormik.setTouched(updateTouched, true).then(errors => {
          validateStepFieldsAndProceed(errors, stepFields)
        })
      } else {
        validateStepFieldsAndProceed(stepFormik.errors, stepFields)
      }
    },
    [stepFormik, validateStepFieldsAndProceed]
  )

  const checkIncludedFields = useCallback(
    (stepFields, values) => {
      // make sure that all step field are touched/validated
      let hasUntouchedFields = false
      let updateTouched = {}

      stepFields.forEach(field => {
        const newTouchedValue = getNewTouchedValues(field, getIn(values, field))
        if (!!newTouchedValue) {
          hasUntouchedFields = true
          updateTouched = setIn(updateTouched, field, newTouchedValue)
        }
      })

      if (hasUntouchedFields) {
        // if field wasn't touched it will not be validated
        // in this case field triggers manually to be touched and run validation
        stepFormik.setTouched(updateTouched, true).then(errors => {
          validateStepFieldsAndProceed(errors, stepFields)
        })
      } else {
        validateStepFieldsAndProceed(stepFormik.errors, stepFields)
      }
    },
    [stepFormik, getNewTouchedValues, validateStepFieldsAndProceed]
  )

  const handleStepChange = useCallback(
    (stepFields, values) => {
      if (values) {
        // check nested fields i.e. arrays and objects
        // the function is simple and check only first level
        checkIncludedFields(stepFields, values)
      } else if (stepFields && stepFields.length) {
        checkFlatFields(stepFields)
      } else {
        nextStep()
      }
    },
    [nextStep, checkFlatFields, checkIncludedFields]
  )

  const resetSteps = useCallback(() => {
    changeStep(initialStep || 0)
  }, [changeStep, initialStep])

  useEffect(() => {
    // scroll to top on every step change
    // look for the scroll container through the data-content attribute
    const carouselContainer = stepsContainerEl?.current?.closest('div[data-content]')
    scrollToContainerTop(carouselContainer)
  }, [activeStep, stepsContainerEl])

  useEffect(() => {
    if (initialStep) {
      // set initialStep on StepForm render
      dispatch(setStep(initialStep))
    }

    return () => {
      // reset step to 0 only on unMount - to avoid reseting StepForm on Route selection!
      dispatch(setStep(0))
    }
  }, [initialStep, dispatch])

  return {
    changeStep,
    backStep,
    resetSteps,
    handleStepChange
  }
}
