import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Table from '../../../../../components/Table'
import ProgressButton from '../../../../../components/Form/ProgressButton'
import BudgetCell from './BudgetCell'
import ChangeCell from './ChangeCell'
import PlatformIcon from '../../../../../components/Table/ReusableCells/PlatformIcon'

import {
  applyMediaOrderSuggestedChangesErrorSelector,
  applyMediaOrderSuggestedChangesIsLoadingSelector,
  applyMediaOrderSuggestedChangesWasCreatedSelector,
  mediaOrderSuggestedChangesSelector
} from '../../../../../modules/selectors/mediaOrderOptimisations'

import {
  applyMediaOrderSuggestedChanges,
  clearApplyMediaOrderSuggestedChanges,
  clearMediaOrderSuggestedChanges,
  clearOptimisationHistory
} from '../../../../../modules/actions/mediaOrderOptimisations'
import { mediaOrderSelector } from '../../../../../modules/selectors/mediaOrders'

import useStyles from './styles'

const SuggestedChangesTable = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const mediaOrder = useSelector(mediaOrderSelector)

  const { id: mediaOrderId } = mediaOrder

  const mediaOrderSuggestedChanges = useSelector(mediaOrderSuggestedChangesSelector)

  const applyChangesIsLoading = useSelector(applyMediaOrderSuggestedChangesIsLoadingSelector)
  const applyChangesError = useSelector(applyMediaOrderSuggestedChangesErrorSelector)
  const changesWasApplied = useSelector(applyMediaOrderSuggestedChangesWasCreatedSelector)

  const { detail: noSuggestedChangesMessage, roas = [], lead = [], cpc = [], cpe = [] } = mediaOrderSuggestedChanges
  const gatheredCampaigns = useMemo(() => [...roas, ...lead, ...cpc, ...cpe], [cpc, cpe, lead, roas])

  const suggestedChangesColumns = [
    {
      Cell: ({ provider }) => {
        return <PlatformIcon provider={provider} />
      },
      style: { maxWidth: 60 }
    },
    {
      header: 'Campaign Name',
      Cell: ({ name: campaignName }) => <p className={classes.campaignName}>{campaignName}</p>,
      style: { maxWidth: 230 }
    },
    {
      header: () => (
        <div className={classes.budgetHeader}>
          <p>{t('Old Budget')}</p>
          <p className={classes.newBudget}>{t('New Budget')}</p>
        </div>
      ),
      Cell: data => <BudgetCell data={data} />,
      style: { maxWidth: 100 }
    },
    {
      header: 'Change',
      Cell: data => <ChangeCell data={data} />,
      style: { maxWidth: 70 }
    }
  ]

  const applyMediaOrderChangesHandler = useCallback(() => {
    dispatch(applyMediaOrderSuggestedChanges(mediaOrderId))
  }, [dispatch, mediaOrderId])

  const applyMediaOrderClearHandler = useCallback(() => {
    dispatch(clearApplyMediaOrderSuggestedChanges())
  }, [dispatch])

  const onSuccessChangesWasApplied = useCallback(() => {
    dispatch(clearMediaOrderSuggestedChanges())
    dispatch(clearOptimisationHistory())
  }, [dispatch])

  if (noSuggestedChangesMessage) {
    return <p className={classes.suggestedChangesTable}>{noSuggestedChangesMessage}</p>
  }

  return (
    <Table
      data={gatheredCampaigns}
      cols={suggestedChangesColumns}
      className={classes.suggestedChangesTable}
      Footer={
        <ProgressButton
          isFormLoading={applyChangesIsLoading}
          successSubmit={changesWasApplied}
          formError={applyChangesError}
          wrapperClassName={classes.applyChangesBtn}
          onClick={applyMediaOrderChangesHandler}
          clearHandler={applyMediaOrderClearHandler}
          onSuccessSubmit={onSuccessChangesWasApplied}
        >
          {t('Apply changes')}
        </ProgressButton>
      }
    />
  )
}

export default SuggestedChangesTable
