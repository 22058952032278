import React from 'react'
import { useTranslation } from 'react-i18next'

import Step from '../../../../../../../../../../../features/components/Forms/StepForm/Step'
import StartEndDateRangeFields from '../../../../../../../../../../ReusableFormFields/StartEndDateRangeFields'

import { END_DATE, START_DATE } from '../../../../../../../../../../ReusableFormFields/StartEndDateRangeFields/fields'

import useDrawerFormStyles from '../../../../../../../../../../../styles/common/drawerForms'

const DatesStep = ({ formik }) => {
  const drawerFormClasses = useDrawerFormStyles()

  const { t } = useTranslation()

  return (
    <Step formTitle="Launch an off-network campaign" stepTitle="Between which dates should the ad set run?">
      <section className={drawerFormClasses.section}>
        <h4 className={drawerFormClasses.sectionTitle}>{t('Dates')}</h4>
        <StartEndDateRangeFields
          formik={formik}
          startDateName={START_DATE}
          endDateName={END_DATE}
          minDate={new Date()}
        />
      </section>
    </Step>
  )
}

export default DatesStep
