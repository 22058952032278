import {
  FB_PAGE_CHOICE,
  FORMAT_OPTION,
  FORMAT_SINGLE,
  initialStoriesItem,
  IS_CUSTOMIZED_MEDIA,
  NAME,
  PIXEL_CHOICE,
  PRIMARY_TEXT_LIST,
  SINGLE_MEDIA_ITEM,
  STORIES_ITEM_SELECTED_INDEX,
  STORIES_ITEMS
} from '../../../../fields'

import { initialProductOrPagesValues } from '../../../../../../ReusableFormFields/ProductsAndPagesFields/fields'
import { singleMediaItemInitialValues } from '../fields'

export const getInitialValues = ({ facebookPages }) => ({
  [NAME]: '',
  [FORMAT_OPTION]: FORMAT_SINGLE,
  [PRIMARY_TEXT_LIST]: [{ text: '' }],
  [SINGLE_MEDIA_ITEM]: singleMediaItemInitialValues,
  [FB_PAGE_CHOICE]: facebookPages[0]?.value, // preselect first facebook page
  [IS_CUSTOMIZED_MEDIA]: false,
  [PIXEL_CHOICE]: '',
  // CATALOGUE PRODUCT route fields (internal feature)
  ...initialProductOrPagesValues,
  // STORIES, We need stories fields in single route because we have possibility to customise ad with story (ad story image/video)
  [STORIES_ITEMS]: [{ ...initialStoriesItem }],
  [STORIES_ITEM_SELECTED_INDEX]: 0
})
