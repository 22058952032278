import * as Yup from 'yup'

export const VERIFICATION_CODE = 'verification_code'

export const initialValues = {
  [VERIFICATION_CODE]: ''
}

export const validationSchema = Yup.object({
  [VERIFICATION_CODE]: Yup.string()
    .required('Please enter verification code from authenticator app')
    .min(6, 'Please enter verification code authenticator app')
})
