import React from 'react'
import PropTypes from 'prop-types'

import { getAdDescriptions } from '../../helpers'

const DescriptionsSectionPreview = ({ ad }) => {
  const descriptionsData = getAdDescriptions(ad)

  if (!descriptionsData || !descriptionsData.length) {
    return null
  }

  return (
    <p>
      {descriptionsData.map((description, index) => (
        <span key={index}>{description}</span>
      ))}
    </p>
  )
}

DescriptionsSectionPreview.propTypes = {
  ad: PropTypes.object
}

export default DescriptionsSectionPreview
