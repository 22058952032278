import * as Yup from 'yup'

import { HEADLINES_LIST, RESOURCE_NAME } from '../../../../fields'

import { headlineShape } from '../../../../validations'

export const headlineMaxLength = 30

// FILE FIELDS:
export const FILE_NAME = 'file_name'
export const FILE_URL = 'file_url'
export const MEDIA_TYPE = 'media_type'

export const getInitialValues = assets => {
  return {
    [HEADLINES_LIST]: assets.map(({ [RESOURCE_NAME]: assetId, asset_data }) => ({
      text: asset_data.text_asset.text,
      id: assetId
    }))
  }
}

export const validationSchema = Yup.object({
  [HEADLINES_LIST]: Yup.array().of(headlineShape)
})
