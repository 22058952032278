import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import EditForm from '../../../../../../features/components/Forms/EditForm'
import Field from '../../../../../../components/Form/Field'
import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'

import {
  campaignUpdateErrorSelector,
  campaignUpdateIsLoadingSelector,
  campaignWasUpdatedSelector
} from '../../../../../../modules/selectors/campaigns'
import { clearUpdateCampaign, updateCampaign } from '../../../../../../modules/actions/campaigns'

import { AMOUNT_MICROS, BUDGET_DAILY, CAMPAIGN_BUDGET } from '../../../../../ReusableFormFields/CampaignForms/fields'
import { budgetDailyValidation } from '../../../CampaignGoogleCreate/CampaignGoogleCreateForm/fields'
import { selectedAdAccountIdSelector, selectedAdAccountsSelector } from '../../../../../../modules/selectors/app'
import { GOOGLE_PLATFORM } from '../../../../../../constants/selectLists/platformList'

const validationSchema = Yup.object({ ...budgetDailyValidation })

const BudgetDailySectionForm = ({ editItemData: campaign, ...formProps }) => {
  const dispatch = useDispatch()

  const { [GOOGLE_PLATFORM]: adAccountData } = useSelector(selectedAdAccountsSelector)
  const { currency_symbol } = adAccountData
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const campaignWasUpdated = useSelector(campaignWasUpdatedSelector)

  const budgetDaily = campaign[CAMPAIGN_BUDGET][AMOUNT_MICROS]

  const handleSubmit = useCallback(
    values => {
      const updateCampaignData = {
        account: selectedAdAccountId,
        [CAMPAIGN_BUDGET]: {
          [AMOUNT_MICROS]: values[BUDGET_DAILY]
        }
      }

      dispatch(updateCampaign(updateCampaignData, campaign.id))
    },
    [dispatch, campaign.id, selectedAdAccountId]
  )

  const initialValues = useMemo(
    () => ({
      [BUDGET_DAILY]: budgetDaily || ''
    }),
    [budgetDaily]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const handleClearUpdateCampaign = useCallback(() => {
    dispatch(clearUpdateCampaign())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={campaignWasUpdated}
      clearEditItem={handleClearUpdateCampaign}
      errorSelector={campaignUpdateErrorSelector}
      isLoadingSelector={campaignUpdateIsLoadingSelector}
      {...formProps}
    >
      <Field
        type="number"
        formik={purifiedFormik}
        name={BUDGET_DAILY}
        placeholder="Daily Budget"
        symbol={currency_symbol}
        autoComplete="off"
      />
    </EditForm>
  )
}

export default BudgetDailySectionForm
