import { add, isToday } from 'date-fns'
import { formatDateWithTimeToBE } from '../../../../../helpers/date'

export const formatStartDateToBE = startDate => {
  if (isToday(startDate)) {
    // adding one minute to allow BE process request (start time should be in the future)
    return formatDateWithTimeToBE(add(new Date(), { minutes: 1 }))
  } else {
    return formatDateWithTimeToBE(startDate)
  }
}
