import React, { forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'react-jss'
import { Doughnut } from 'react-chartjs-2'
// import graphs initial setting
import '../index'
import { useGraph } from '../hooks'

import getReusableGraphOptions from '../reusableGraphOptions'
import { formatNumber } from '../../../../helpers/numbers'

import { googleBlue, instagramOrange, red, yellow } from '../../../../styles/const/colors'

const doughnutColors = [googleBlue, yellow, instagramOrange, red]

const DoughnutGraph = forwardRef((props, ref) => {
  const { data, labels, className, graphColor, format, tooltipLabel } = props

  const { t } = useTranslation()
  const theme = useTheme()
  useGraph()

  const graphData = {
    labels,
    datasets: [
      {
        data: data,
        backgroundColor: doughnutColors,
        borderColor: 'white',
        borderWidth: 1,
        borderRadius: 4,
        borderAlign: 'inner',
        hoverBorderColor: 'white'
      }
    ]
  }

  const graphOptions = useMemo(
    () => ({
      ...getReusableGraphOptions({ format, graphColor, theme }),
      scales: {
        x: {
          display: false
        },
        y: {
          display: false
        }
      },
      plugins: {
        legend: {
          labels: {
            generateLabels: () =>
              labels.map((label, index) => ({
                text: label,
                fillStyle: doughnutColors[index],
                fontColor: doughnutColors[index],
                borderRadius: 2,
                datasetIndex: index,
                strokeStyle: 'white'
              })),
            boxWidth: 25,
            boxHeight: 8,
            font: {
              size: 15
            },
            borderRadius: theme.defaultBorderRadius
          }
        },
        tooltip: {
          ...getReusableGraphOptions({ format, theme }).plugins.tooltip,
          callbacks: {
            title: () => '', // remove title as it duplicates with label
            label: tooltipItem => tooltipItem.label,
            labelTextColor: tooltipItem => doughnutColors[tooltipItem.dataIndex],
            footer: tooltipItem => `${formatNumber(tooltipItem[0].raw)} ${t(tooltipLabel)}`
          },
          bodyFont: {
            weight: 700,
            size: 13
          }
        }
      }
    }),
    [format, graphColor, labels, theme, t, tooltipLabel]
  )

  return (
    <div className={className}>
      <Doughnut type="doughnut" data={graphData} options={graphOptions} ref={ref} />
    </div>
  )
})

DoughnutGraph.propTypes = {
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  className: PropTypes.string,
  graphColor: PropTypes.string,
  format: PropTypes.string,
  tooltipLabel: PropTypes.string
}

export default DoughnutGraph
