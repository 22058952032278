export const CALENDAR_VIEW_TYPE = {
  YEAR: 'YEAR',
  HALF_YEAR: 'HALF_YEAR',
  QUARTER: 'QUARTER',
  MONTH: 'MONTH'
}

export const calendarViewOptionsList = [
  { value: CALENDAR_VIEW_TYPE.YEAR, label: 'Year' },
  { value: CALENDAR_VIEW_TYPE.HALF_YEAR, label: 'Half Year' },
  { value: CALENDAR_VIEW_TYPE.QUARTER, label: 'Quarter' },
  { value: CALENDAR_VIEW_TYPE.MONTH, label: 'Month' }
]

export const EVENT_GROUP_TYPE = {
  EVENT: 'event',
  MEDIA: 'media',
  PROMO: 'promo',
  INFO: 'info'
}

export const eventGroupTypesList = [
  { value: EVENT_GROUP_TYPE.EVENT, label: 'Events' },
  { value: EVENT_GROUP_TYPE.MEDIA, label: 'Media' },
  { value: EVENT_GROUP_TYPE.PROMO, label: 'Promo periods' },
  { value: EVENT_GROUP_TYPE.INFO, label: 'Other' }
]
