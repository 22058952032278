import { call, put, all, takeEvery } from 'redux-saga/effects'

import {
  GET_TRIGGERS,
  getTriggersSuccess,
  getTriggersFailure,
  CREATE_TRIGGER,
  createTriggerSuccess,
  createTriggerFailure,
  UPDATE_TRIGGER,
  updateTriggerSuccess,
  updateTriggerFailure,
  DELETE_TRIGGER,
  deleteTriggerSuccess,
  deleteTriggerFailure
} from '../actions/triggers'
import {
  getTriggersService,
  createTriggerService,
  updateTriggerService,
  deleteTriggerService
} from '../services/triggers'

function* triggersWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_TRIGGERS, getTriggersWorker),
    takeEvery(CREATE_TRIGGER, createTriggerWorker),
    takeEvery(UPDATE_TRIGGER, updateTriggerWorker),
    takeEvery(DELETE_TRIGGER, deleteTriggerWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getTriggersWorker({ params }) {
  try {
    const response = yield call(getTriggersService, params)
    yield put(getTriggersSuccess(response))
  } catch (e) {
    yield put(getTriggersFailure(e))
  }
}

function* createTriggerWorker({ triggerData }) {
  try {
    const response = yield call(createTriggerService, triggerData)
    yield put(createTriggerSuccess(response))
  } catch (e) {
    yield put(createTriggerFailure(e))
  }
}

function* updateTriggerWorker({ id, triggerData }) {
  try {
    const response = yield call(updateTriggerService, id, triggerData)
    yield put(updateTriggerSuccess(response))
  } catch (e) {
    yield put(updateTriggerFailure(e))
  }
}

function* deleteTriggerWorker({ id }) {
  try {
    yield call(deleteTriggerService, id)
    yield put(deleteTriggerSuccess(id))
  } catch (e) {
    yield put(deleteTriggerFailure(e))
  }
}

export default triggersWatcher
