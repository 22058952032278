import { useSelector } from 'react-redux'
import { activeStepSelector } from '../../../../../modules/selectors/forms'

export function useProgressItems() {
  const activeStep = useSelector(activeStepSelector)

  const progressItems = [
    {
      label: 'Enter package details',
      isActive: activeStep === 0,
      isSuccess: activeStep > 0
    },
    {
      label: 'Select products or categories',
      isActive: activeStep === 1,
      isSuccess: activeStep > 1
    },
    {
      label: 'Set the pricing',
      isActive: activeStep === 2,
      isSuccess: activeStep > 2
    },
    {
      label: 'Upload image',
      isActive: activeStep === 3,
      isSuccess: activeStep > 3
    }
  ]

  return progressItems
}
