import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { addMonths, startOfMonth } from 'date-fns'

import BreakdownTable from './BreakdownTable'
import GraphTableSection from '../UI/GraphTableSection'
import PeriodBookedMediaReportGraph from '../UI/graphs/PeriodBookedMediaReportGraph'
import SkeletonBookedRevenueByMonthGraph from '../UI/graphs/Skeleton'
import { BrandCategoryRevenueContext } from './BreakdownTable/BrandCategoryRevenueContext'

import {
  controllerLandingPageSetupWasLoadedSelector,
  selectedControllerIdSelector
} from '../../../../../../modules/selectors/app'

import { getPeriodBookedMediaReportService } from '../../../../../../modules/services/mediaOrdersBookings'

import { formatDateToBE } from '../../../../../../constants/dates'
import { formatSetupFilters } from '../helpers'

const BrandCategoryBreakdown = ({ setupData }) => {
  const [monthRevenueReportData, setMonthRevenueReportData] = useState(null)
  const selfAccountControllerId = useSelector(selectedControllerIdSelector)
  const controllerLandingPageSetupWasLoaded = useSelector(controllerLandingPageSetupWasLoadedSelector)

  const { brandCategoryFilter } = useContext(BrandCategoryRevenueContext)

  useEffect(() => {
    // start of current month:
    const startDate = startOfMonth(new Date())
    // end in 6months
    const endDate = addMonths(startOfMonth(new Date()), 6)

    const setupGraphFilter = setupData?.graph?.filters
    const formattedGraphFilters = formatSetupFilters(setupGraphFilter)

    if (controllerLandingPageSetupWasLoaded && brandCategoryFilter) {
      getPeriodBookedMediaReportService({
        ...formattedGraphFilters,
        brand_category: brandCategoryFilter,
        controller: selfAccountControllerId,
        with_empty_data: true,
        breakdown: 'month',
        date_start_after: formatDateToBE(startDate),
        date_start_before: formatDateToBE(endDate)
      }).then(response => {
        setMonthRevenueReportData(response)
      })
    }
  }, [selfAccountControllerId, brandCategoryFilter, controllerLandingPageSetupWasLoaded, setupData])

  return (
    <GraphTableSection title="Brand Category Breakdown">
      {monthRevenueReportData?.length > 0 ? (
        <PeriodBookedMediaReportGraph
          monthRevenueReportData={monthRevenueReportData}
          selectedSelfAccountCategory={brandCategoryFilter}
        />
      ) : (
        <SkeletonBookedRevenueByMonthGraph />
      )}
      <BreakdownTable />
    </GraphTableSection>
  )
}

export default BrandCategoryBreakdown
