import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import MultiSelectBox from '../../../../../../../../features/components/Form/MultiSelectBox'

import { formatNumber } from '../../../../../../../../helpers/numbers'

import {
  choicesInterestsLoadingSelector,
  choicesInterestsSelector
} from '../../../../../../../../modules/selectors/choices'
import { selectedAdAccountIdSelector } from '../../../../../../../../modules/selectors/app'
import { clearChoicesInterests, getChoicesInterests } from '../../../../../../../../modules/actions/choices'

import { INTERESTS } from '../../../../../../../ReusableFormFields/InterestsFields/fields'

const InterestsField = ({ formik }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const interests = useSelector(choicesInterestsSelector)
  const interestsLoading = useSelector(choicesInterestsLoadingSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const { values, setFieldValue } = formik

  const formattedInterests =
    interests &&
    interests.map(item => {
      // adds a label to the results showing the audience size
      const description = `${formatNumber(item.audience_size_lower_bound, { min: 0, max: 0 })} - ${formatNumber(
        item.audience_size_upper_bound,
        { min: 0, max: 0 }
      )} ${t('people')}`

      return { value: item.id, label: item.name, description: description }
    })

  const onSearchInterests = useCallback(
    value => {
      if (value) {
        dispatch(
          getChoicesInterests({
            account: selectedAdAccountId,
            search: value
          })
        )
      } else {
        dispatch(clearChoicesInterests())
      }
    },
    [dispatch, selectedAdAccountId]
  )

  return (
    <MultiSelectBox
      placeholder={t('Search for an interest')}
      showIndicator={false}
      name={INTERESTS}
      options={formattedInterests}
      value={values[INTERESTS]}
      isLoading={interestsLoading}
      setFieldValue={setFieldValue}
      onSearch={onSearchInterests}
    />
  )
}

export default InterestsField
