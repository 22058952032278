import React, { useState } from 'react'

export const ContractBuilderContext = React.createContext()

export const defaultTableSort = {
  parameter: null,
  direction: null
}
export function ContractBuilderProvider({ children }) {
  const [editProductId, setEditProductId] = React.useState(null)
  const [tableSort, setTableSort] = useState(defaultTableSort)

  return (
    <ContractBuilderContext.Provider
      value={{
        editProductId,
        setEditProductId,
        tableSort,
        setTableSort
      }}
    >
      {children}
    </ContractBuilderContext.Provider>
  )
}
