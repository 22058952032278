import { createStyles } from '../../../../styles/helpers'
import { lightGrey } from '../../../../styles/const/colors'

const useStyles = createStyles({
  amendmentContractSend: {
    backgroundColor: [lightGrey, '!important']
  }
})

export default useStyles
