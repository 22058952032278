import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import React, { useMemo } from 'react'

import TableDataLoader from '../../../../../components/Table/TableDataLoader'
import PriceChangePeriodsTableData from './PriceChangePeriodsTableData'

import useCommonStyles from '../../../../../styles/common/listPage'

import {
  priceChangePeriodsErrorSelector,
  priceChangePeriodsIsLoadingSelector,
  priceChangePeriodsSelector,
  priceChangePeriodsWasLoadedSelector
} from '../../../../../modules/selectors/discounts'

export const tableColumnsSize = {
  mediaCategory: 150,
  locations: 130,
  priceChange: 130,
  actions: 30
}

const PriceChangePeriodsTable = () => {
  const { t } = useTranslation()
  const commonClasses = useCommonStyles()

  const priceChangePeriods = useSelector(priceChangePeriodsSelector)

  const priceChangePeriodsColumnsSchema = useMemo(
    () => [
      {
        header: 'Period'
      },
      {
        header: 'Media Category',
        style: { maxWidth: tableColumnsSize.mediaCategory }
      },
      {
        header: 'Locations',
        style: { maxWidth: tableColumnsSize.locations }
      },
      {
        header: 'Price Change',
        style: { maxWidth: tableColumnsSize.priceChange }
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no price change periods')}</div>
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <>
      <TableDataLoader
        itemsLength={priceChangePeriods.length}
        errorSelector={priceChangePeriodsErrorSelector}
        wasLoadedSelector={priceChangePeriodsWasLoadedSelector}
        isLoadingSelector={priceChangePeriodsIsLoadingSelector}
        skeletonProps={{ cols: priceChangePeriodsColumnsSchema }}
        noDataContent={noDataContent}
      >
        <PriceChangePeriodsTableData />
      </TableDataLoader>
    </>
  )
}

export default PriceChangePeriodsTable
