import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

import {
  CAMPAIGN_OPTION,
  CAMPAIGN_OPTION_NEW_CAMPAIGN,
  campaignValidation,
  getBudgetValidation,
  getCampaignInitialValues
} from '../../../../Multiplatform/UploadAdCreative/fields'
import { BUDGET_LIFETIME } from '../../../../Multiplatform/CampaignForms/fields'
import { CONVERSIONS } from '../../../../../constants/campaigns'

export const getInitialValues = ({ isCampaignsExist }) => {
  const campaignsInitialValues = getCampaignInitialValues(isCampaignsExist)

  return {
    ...campaignsInitialValues,
    [BUDGET_LIFETIME]: ''
  }
}

const conversionGoal = {
  value: CONVERSIONS,
  label: 'Conversions'
}
export const getValidationSchema = ({ remainingBudget, currencySymbol }) => {
  return Yup.object({
    ...campaignValidation,
    [BUDGET_LIFETIME]: Yup.number().when(CAMPAIGN_OPTION, {
      is: CAMPAIGN_OPTION_NEW_CAMPAIGN,
      then: () => getBudgetValidation({ remainingBudget, currencySymbol })
    })
  })
}

export const transformValuesToBE = (values, adAccountId, mediaOrder) => {
  const { name: mediaOrderName } = mediaOrder

  return {
    account: adAccountId,
    name: `${mediaOrderName} (${conversionGoal.label}) - TikTok - ${uuidv4().slice(0, 7)}`,
    objective: conversionGoal.value.toUpperCase(),
    budget_lifetime: values[BUDGET_LIFETIME],
    buying_type: 'BUDGET_MODE_TOTAL',
    budget_optimize_on: true
  }
}
