import React, { useMemo } from 'react'

import { determineDiscountsFromProducts } from './helpers'

export const DiscountsContext = React.createContext()

export function DiscountsProvider({ children, allSelectedProducts }) {
  const productsDiscount = useMemo(() => determineDiscountsFromProducts(allSelectedProducts), [allSelectedProducts])

  return <DiscountsContext.Provider value={{ productsDiscount }}>{children}</DiscountsContext.Provider>
}
