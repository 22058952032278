import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import useStyles from './styles'

const CalendarTableHeader = ({ titleCols, subtitleCols }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <thead>
      <tr className={classes.row}>
        <td className={classes.namePlaceholder} rowSpan={2}>
          <h5>{t('Product Name')}</h5>
        </td>
        <td className={classes.idPlaceholder} rowSpan={2}>
          <h5>{t('Asset ID')}</h5>
        </td>
        {titleCols.map((d, index) => {
          return (
            <td className={classes.titleCol} key={index} colSpan={d.colSpan}>
              <h5>{d.text}</h5>
              <div>{d.subText}</div>
            </td>
          )
        })}
      </tr>
      <tr className={classes.row}>
        {subtitleCols.map((col, index) => {
          return (
            <td className={classnames('js-so-header-subtitle', classes.subtitleCol)} key={index}>
              <h5>{col.text}</h5>
              <div>{col.subText}</div>
            </td>
          )
        })}
      </tr>
    </thead>
  )
}

CalendarTableHeader.propTypes = {
  titleCols: PropTypes.array.isRequired,
  subtitleCols: PropTypes.array.isRequired
}

export default CalendarTableHeader
