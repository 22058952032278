import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import BackButton from './BackButton'
import Button from '../../../../components/Button'
import StepTitle from './StepTitle'

import useStyles from '../../../../styles/common/stepForms'

const Step = forwardRef(
  (
    {
      children,
      handleContinue,
      onBackButtonClick,
      backButtonClassName,
      stepTitle,
      stepDescription,
      btnText = 'Continue',
      activeStep,
      showButton,
      headerFilter
    },
    ref
  ) => {
    const classes = useStyles()

    const { t } = useTranslation()

    return (
      <>
        <StepTitle stepTitle={stepTitle} headerFilter={headerFilter} />
        {stepDescription && <p className={classes.stepDescription}>{t(stepDescription)}</p>}
        <div ref={ref} className={classes.stepBody}>
          {children}
        </div>
        {handleContinue && (
          <div className={classes.stepFooter}>
            <Button type="button" className="dark" onClick={handleContinue}>
              {t(btnText)}
            </Button>
          </div>
        )}
        {onBackButtonClick && (
          <BackButton
            className={backButtonClassName}
            onStepBack={onBackButtonClick}
            activeStep={activeStep}
            showButton={showButton}
          />
        )}
      </>
    )
  }
)

Step.propTypes = {
  stepTitle: PropTypes.string,
  stepDescription: PropTypes.string,
  handleContinue: PropTypes.func,
  onBackButtonClick: PropTypes.func,
  backButtonClassName: PropTypes.string,
  activeStep: PropTypes.bool,
  btnText: PropTypes.string,
  headerFilter: PropTypes.node
}

export default Step
