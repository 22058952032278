import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import GoogleAssetGroupMainForm from '../../../../AdForms/GoogleAssetGroup/GoogleAssetGroupMainForm'
import GoogleAssetGroupAudienceForm from '../../../../AdForms/GoogleAssetGroup/GoogleAssetGroupAudienceForm'

import useIsInternalAdCreation from '../../../../../../features/hooks/useIsInternalAdCreation'

import { activeStepSelector } from '../../../../../../modules/selectors/forms'

export const MAIN_FORM = 'main_form'
export const AUDIENCE_FORM = 'audience_form'

const GoogleAssetGroupForm = ({ assetGroupRouter, onSuccessSubmit, adAccountId }) => {
  const [assetGroupCreatedData, setAssetGroupCreatedData] = useState({})
  const activeStep = useSelector(activeStepSelector)

  const isInternalAssetGroupCreation = useIsInternalAdCreation()

  const { activatedForm, setActivatedForm } = assetGroupRouter

  const onSuccessMainFormSubmit = useCallback(
    assetGroupData => {
      setAssetGroupCreatedData(assetGroupData)
      setActivatedForm(AUDIENCE_FORM)
    },
    [setActivatedForm]
  )

  switch (activatedForm) {
    case AUDIENCE_FORM:
      return (
        <GoogleAssetGroupAudienceForm
          assetGroupCreatedData={assetGroupCreatedData}
          isInternalAssetGroupCreation={isInternalAssetGroupCreation}
          onSuccessSubmit={onSuccessSubmit}
          adAccountId={adAccountId}
        />
      )
    case MAIN_FORM:
    default:
      return (
        <GoogleAssetGroupMainForm
          isInternalAssetGroupCreation={isInternalAssetGroupCreation}
          onSuccessSubmit={onSuccessMainFormSubmit}
          adAccountId={adAccountId}
          formProps={{
            customStepsForProgress: 4,
            // We show activeStep + 1 because this is second form, and first form has only one step
            customActiveStep: activeStep + 1
          }}
        />
      )
  }
}

export default GoogleAssetGroupForm
