import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import CenomiSubLogo from './sublogoimage.jpg'
import CenomiLogo from '../../../../assets/domains/cenomispace/logo_white.png'
import { TabletDown, TabletUp } from '../../../../components/hoc/ResponsiveRendering'

import useStyles from './styles'

function CenomiDomainPage({ mainClassName, className, children }) {
  const classes = useStyles()

  return (
    <main className={classnames(classes.main, mainClassName)}>
      <TabletUp>
        <div className={classes.brandContent}>
          <img src={CenomiLogo} alt="CenomiLogo" className={classes.logo} />
          <img src={CenomiSubLogo} alt="CenomiSubLogo" />
        </div>
        <div className={classes.mainContentContainer}>
          <div className={classnames(classes.mainContent, className)}>{children}</div>
        </div>
      </TabletUp>

      <TabletDown>
        <div className={classes.brandContent}>
          <img src={CenomiLogo} alt="CenomiLogo" className={classes.logo} />
          <div className={classnames(classes.mainContent, className)}>{children}</div>
        </div>
      </TabletDown>
    </main>
  )
}

CenomiDomainPage.propTypes = {
  mainClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
}

export default CenomiDomainPage
