import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { signAmendmentContract, clearSignAmendmentContract } from '../../../../../../../../modules/actions/amendments'
import {
  signAmendmentContractErrorSelector,
  signAmendmentContractIsLoadingSelector,
  signAmendmentContractWasCreatedSelector
} from '../../../../../../../../modules/selectors/amendments'

const propTypes = {
  contractId: PropTypes.number.isRequired,
  signType: PropTypes.string.isRequired,
  tokenParams: PropTypes.object
}

export function useAmendmentContractSign(props) {
  const dispatch = useDispatch()
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'useContractSign')
  const { contractId, tokenParams, signType } = props

  const handleSubmit = useCallback(
    data => {
      dispatch(signAmendmentContract({ id: contractId, data, params: tokenParams, signType }))
    },
    [contractId, dispatch, tokenParams, signType]
  )

  const clearHandler = useCallback(() => {
    dispatch(clearSignAmendmentContract())
  }, [dispatch])

  return useMemo(
    () => ({
      clearHandler,
      handleSubmit,
      contractWasSignedSelector: signAmendmentContractWasCreatedSelector,
      errorSelector: signAmendmentContractErrorSelector,
      isLoadingSelector: signAmendmentContractIsLoadingSelector
    }),
    [clearHandler, handleSubmit]
  )
}
