import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useStyles from './styles'

const MediaThumbnail = React.memo(({ className, onMediaClick, imageUrl }) => {
  const classes = useStyles(onMediaClick)

  const handleMediaClick = useCallback(() => {
    onMediaClick && onMediaClick(imageUrl)
  }, [imageUrl, onMediaClick])

  return (
    <img
      className={classnames(classes.media, className)}
      src={imageUrl}
      alt="product thumbnail"
      onClick={handleMediaClick}
    />
  )
})

MediaThumbnail.propTypes = {
  media: PropTypes.object,
  mediaType: PropTypes.string,
  className: PropTypes.string,
  onMediaClick: PropTypes.func,
  onRemoveClick: PropTypes.func
}

export default MediaThumbnail
