import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  platformIconWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  platformIcon: {
    maxWidth: '100%',
    width: 31,
    height: 31
  },
  tikTokIcon: {
    width: 27,
    height: 27
  }
})

export default useStyles
