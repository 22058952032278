import { createStyles } from '../../../../../styles/helpers'

import { backBtn } from '../../../../../styles/common/components'
import { phonesDown } from '../../../../../styles/const/breakpoints'

export const backBtnMarginTop = '6px'
const useStyles = createStyles(theme => ({
  backBtn: {
    extend: backBtn(theme),
    display: 'block',
    textAlign: 'center',
    margin: `${backBtnMarginTop} auto 0`,
    padding: '10px 12px',
    '& svg': {
      color: theme.brandPrimary
    }
  },
  [`@media screen and (${phonesDown})`]: {
    backBtn: {
      margin: '14px auto 0'
    }
  }
}))

export default useStyles
