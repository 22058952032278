import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import ProgressButton from '../../../../../components/Form/ProgressButton'

import { downloadCSVFile } from '../../../../../helpers/other'

import { getStatementsAsCsv, clearGetStatementsAsCsv } from '../../../../../modules/actions/statements'

import {
  csvStatementSelector,
  csvStatementWasLoadedSelector,
  csvStatementErrorSelector,
  csvStatementIsLoadingSelector
} from '../../../../../modules/selectors/statements'

import useStyles from './styles'

const InvoicingDownloadCSV = ({ paramsData }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const isLoading = useSelector(csvStatementIsLoadingSelector)
  const error = useSelector(csvStatementErrorSelector)
  const wasLoaded = useSelector(csvStatementWasLoadedSelector)
  const csvData = useSelector(csvStatementSelector)

  const clearHandler = () => {
    dispatch(clearGetStatementsAsCsv())
  }

  const handleOnClick = () => {
    dispatch(getStatementsAsCsv(paramsData))
  }

  const handleDownloadCSVFile = useCallback(() => {
    downloadCSVFile({ data: csvData, fileName: 'invoices' })
  }, [csvData])

  return (
    <ProgressButton
      onClick={handleOnClick}
      isFormLoading={isLoading}
      formError={error}
      successSubmit={wasLoaded}
      clearHandler={clearHandler}
      onSuccessSubmit={handleDownloadCSVFile}
      wrapperClassName={classes.exportButtonWrapper}
    >
      {t('Export as CSV')}
    </ProgressButton>
  )
}

InvoicingDownloadCSV.propTypes = {
  paramsData: PropTypes.shape({
    controller: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    status: PropTypes.string,
    date_start_after: PropTypes.string,
    date_start_before: PropTypes.string,
    account: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired
}

export default InvoicingDownloadCSV
