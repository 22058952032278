import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import UnauthorizedPage from '../UnauthorizedPage'
import QuotationSignContent from './QuotationSignContent'
import DownloadContract from './DownloadContract'

import useStyles from './styles'

function QuotationSign() {
  const [createdOrderContract, setCreatedOrderContract] = useState(null)
  const classes = useStyles()

  const { t } = useTranslation()

  const handleMediaOrderCreate = useCallback(data => {
    setCreatedOrderContract(data)
  }, [])

  return (
    // quotation page should have default page wrapper
    <UnauthorizedPage helmetTitle="helmetTitle.QuotationSign" defaultWrapper>
      {!!createdOrderContract ? (
        <div className={classes.infoMessageContainer}>
          <h2 className={classes.infoTitle}>{t('You’ve successfully signed the booking contract')}</h2>
          <p>{t('This will be emailed to you shortly')}</p>

          <DownloadContract contractId={createdOrderContract.id} />
        </div>
      ) : (
        <QuotationSignContent onMediaOrderCreate={handleMediaOrderCreate} />
      )}
    </UnauthorizedPage>
  )
}

export default QuotationSign
