import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  cardsList: {
    display: 'grid',
    width: '100%',
    padding: '20px 16px 20px 18px',
    gridGap: 16,
    gridTemplateColumns: (columnWidth = 220) => `repeat(auto-fill, [col-start] minmax(${columnWidth}px, 1fr) [col-end])`
  }
})

export default useStyles
