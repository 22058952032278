import React from 'react'
import { useTranslation } from 'react-i18next'

import MultiSelectBox from '../../../../../../../features/components/Form/MultiSelectBox'
import MediaProductsPaginatedMultiSelect from '../../../../components/MediaProductsPaginatedMultiSelect'

import { mediaCategories } from '../../../../../../../constants/mediaOrders'

import { MEDIA_CATEGORIES } from '../../../../fields'

import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

const MediaItemsSection = ({ formik, isPackageWithMediaCategories }) => {
  const drawerFormClasses = useDrawerFormStyles()

  const { setFieldValue, values, errors, touched } = formik

  const { t } = useTranslation()

  return (
    <div className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>
        {t(isPackageWithMediaCategories ? 'Media Categories' : 'Media Products')}
      </h3>
      {isPackageWithMediaCategories ? (
        <MultiSelectBox
          placeholder="Media categories"
          setFieldValue={setFieldValue}
          value={values[MEDIA_CATEGORIES]}
          name={MEDIA_CATEGORIES}
          options={mediaCategories}
          error={errors[MEDIA_CATEGORIES]}
          touched={touched[MEDIA_CATEGORIES]}
        />
      ) : (
        <MediaProductsPaginatedMultiSelect formik={formik} />
      )}
    </div>
  )
}

export default MediaItemsSection
