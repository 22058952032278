import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

export const campaignsSelector = createSelector(
  ({ campaigns }) => campaigns.campaigns.results,
  // get only valid campaigns (filter totals item)
  results => results.filter(item => item.id)
)

export const campaignsIsLoadingSelector = createSelector(
  ({ campaigns }) => campaigns.campaigns.isLoading,
  isLoading => isLoading
)

export const campaignsErrorSelector = createSelector(
  ({ campaigns }) => campaigns.campaigns.error,
  error => error
)

export const campaignsWasLoadedSelector = createSelector(
  ({ campaigns }) => campaigns.campaigns.wasLoaded,
  wasLoaded => wasLoaded
)

export const campaignsNextSelector = createSelector(
  ({ campaigns }) => campaigns.campaigns.paging.next,
  next => next
)

export const campaignsAfterSelector = createSelector(
  ({ campaigns }) => campaigns.campaigns.paging.cursors.after,
  after => after
)

export const campaignsTotalsSelector = createSelector(
  ({ campaigns }) => campaigns.campaigns.results,
  // at (-1) = get last totals item (pagination features)
  results => results.filter(item => !item.name && !item.id).at(-1)
)

export const shortInfoCampaignsSelector = createSelector(
  ({ campaigns }) => campaigns.shortInfoCampaigns.results,
  results => results
)

export const shortInfoCampaignsIsLoadingSelector = createSelector(
  ({ campaigns }) => campaigns.shortInfoCampaigns.isLoading,
  isLoading => isLoading
)

export const shortInfoCampaignsErrorSelector = createSelector(
  ({ campaigns }) => campaigns.shortInfoCampaigns.error,
  error => error
)

export const campaignsFilterSelector = createSelector(
  ({ campaigns }) => campaigns.filter,
  filter => filter
)

export const campaignsDateRangeSelector = createSelector(
  ({ campaigns }) => campaigns.dateRange,
  dateRange => dateRange
)

export const campaignsSortSelector = createSelector(
  ({ campaigns }) => campaigns.sort,
  sort => sort
)

export const campaignSelector = createSelector(
  ({ campaigns }) => campaigns.campaign.data,
  data => data
)

export const campaignWasLoadedSelector = createSelector(
  ({ campaigns }) => campaigns.campaign.wasLoaded,
  wasLoaded => wasLoaded
)

export const campaignLoadingSelector = createSelector(
  ({ campaigns }) => campaigns.campaign.isLoading,
  isLoading => isLoading
)

export const campaignErrorSelector = createSelector(
  ({ campaigns }) => campaigns.campaign.error,
  error => error
)

export const campaignCreateSelector = createSelector(
  ({ campaigns }) => campaigns.createdCampaign.data,
  data => data
)

export const campaignCreateIsLoadingSelector = createSelector(
  ({ campaigns }) => campaigns.createdCampaign.isLoading,
  isLoading => isLoading
)

export const campaignCreateErrorSelector = createSelector(
  ({ campaigns }) => campaigns.createdCampaign.error,
  error => error
)

export const campaignCreateWasCreatedSelector = createSelector(
  ({ campaigns }) => campaigns.createdCampaign.wasCreated,
  wasCreated => wasCreated
)

export const createdCampaignIdSelector = createSelector(
  ({ campaigns }) => campaigns.createdCampaign.id,
  createdCampaignId => createdCampaignId
)

export const createdCampaignDataSelector = createSelector(
  ({ campaigns }) => campaigns.createdCampaign.data,
  campaignData => campaignData
)

export const campaignCriterionsSelector = createSelector(
  ({ campaigns }) => campaigns.campaignCriterions.results,
  results => results
)

export const campaignCriterionsWasLoadedSelector = createSelector(
  ({ campaigns }) => campaigns.campaignCriterions.wasLoaded,
  wasLoaded => wasLoaded
)

export const campaignAdScheduleCriterionsSelector = createSelector(
  ({ campaigns }) => campaigns.campaignAdScheduleCriterions.data,
  data => data
)

export const campaignAdScheduleCriterionsWasLoadedSelector = createSelector(
  ({ campaigns }) => campaigns.campaignAdScheduleCriterions.wasLoaded,
  wasLoaded => wasLoaded
)

export const createCampaignCriterionsIsLoadingSelector = createSelector(
  ({ campaigns }) => campaigns.createCampaignCriterions.isLoading,
  isLoading => isLoading
)

export const createCampaignCriterionsErrorSelector = createSelector(
  ({ campaigns }) => campaigns.createCampaignCriterions.error,
  error => error
)

export const createCampaignCriterionsWasCreatedSelector = createSelector(
  ({ campaigns }) => campaigns.createCampaignCriterions.wasCreated,
  wasCreated => wasCreated
)

export const campaignDeleteIsLoadingSelector = createSelector(
  ({ campaigns }) => campaigns.deletedCampaign.isLoading,
  isLoading => isLoading
)

export const campaignDeleteErrorSelector = createSelector(
  ({ campaigns }) => campaigns.deletedCampaign.error,
  error => error
)

export const campaignDeleteItemSelector = createSelector(
  ({ campaigns }) => campaigns.deletedCampaign,
  deletedCampaign => deletedCampaign
)

export const campaignUpdateSelector = createSelector(
  ({ campaigns }) => campaigns.updatedCampaign,
  updatedCampaign => updatedCampaign
)
export const campaignWasUpdatedSelector = createSelector(
  ({ campaigns }) => campaigns.updatedCampaign.wasUpdated,
  wasUpdated => wasUpdated
)

export const campaignUpdateIsLoadingSelector = createSelector(
  ({ campaigns }) => campaigns.updatedCampaign.isLoading,
  isLoading => isLoading
)

export const campaignUpdateErrorSelector = createSelector(
  ({ campaigns }) => campaigns.updatedCampaign.error,
  error => error
)

// duplicate campaign
export const duplicateCampaignIsLoadingSelector = createSelector(
  ({ campaigns }) => campaigns.duplicateCampaign.isLoading,
  isLoading => isLoading
)

export const duplicateCampaignErrorSelector = createSelector(
  ({ campaigns }) => campaigns.duplicateCampaign.error,
  error => error
)

export const duplicateCampaignProcessWasStartedSelector = createSelector(
  ({ campaigns }) => campaigns.duplicateCampaign.duplicateProcessWasStarted,
  duplicateProcessWasStarted => duplicateProcessWasStarted
)

export const duplicateCampaignAsyncSessionIdSelector = createSelector(
  ({ campaigns }) => campaigns.duplicateCampaign.asyncSessionData,
  asyncSessionData => asyncSessionData.id
)
