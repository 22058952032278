import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '../Button'
import ErrorMessage from '../Form/ErrorMessage'
import SpreadSheetTable from './SpreadSheetTable'

import useStyles from './styles'
import { useDebounce } from '../../hooks/useDebounce'

// by default we have initial data as 10 empty rows with 2 columns, 1 row = 1 array
// for some reasons, not putting undefined inside arrays break copy/paste logic
export const spreadSheetInitialData = [
  [undefined, undefined],
  [undefined, undefined],
  [undefined, undefined],
  [undefined, undefined],
  [undefined, undefined],
  [undefined, undefined],
  [undefined, undefined],
  [undefined, undefined],
  [undefined, undefined],
  [undefined, undefined]
]

const SpreadSheet = ({
  name,
  formikData,
  setFieldValue,
  maxRows,
  error,
  errorRowsIndexes,
  className,
  columnLabels
}) => {
  // pass errorRowsIndexes to styles to highlight rows with errors
  const [internalData, setInternalData] = useState(formikData || spreadSheetInitialData)
  const classes = useStyles()
  const { t } = useTranslation()
  const tableRef = useRef(null)

  const isMaxDataLength = formikData.length >= maxRows

  const addEmptyRow = e => {
    e.preventDefault()

    if (!isMaxDataLength) {
      // set formik value
      setFieldValue(name, [...formikData, ['', '']])

      // scroll to bottom of table when adding new row
      setTimeout(() => {
        // 28 - row height multiplied by maximum rows
        tableRef && tableRef.current.scrollTo({ top: 28 * maxRows, behavior: 'smooth' })

        // 1 is needed for correct working of scrolling to the actual bottom
      }, 1)
    }
  }

  const onChange = useCallback(data => {
    // data is setting to the internal state during typing to avoid performance issues
    // after debounceData change then it sets to Formik
    setInternalData(data)
  }, [])

  // To improve performance, we update formik only when the user has finished typing
  const debounceData = useDebounce(internalData, 500)
  useEffect(() => {
    setFieldValue(name, debounceData)
    // eslint-disable-next-line
  }, [debounceData, setFieldValue, name])

  const RowIndicator = useCallback(
    ({ row }) => {
      // custom span used for highlighting rows with errors
      const isErrorIndicator = errorRowsIndexes.includes(row)

      return (
        <th
          className={classnames('Spreadsheet__header', {
            error: isErrorIndicator
          })}
        >
          <span
            className={classnames({
              [classes.errorHighlighter]: isErrorIndicator
            })}
          />
          {row + 1}
        </th>
      )
    },
    [classes, errorRowsIndexes]
  )

  return (
    <>
      <div className={classnames(classes.tableWrapper, className)} ref={tableRef}>
        <SpreadSheetTable
          data={formikData}
          onChange={onChange}
          RowIndicator={RowIndicator}
          className={classes.table}
          columnLabels={columnLabels}
        />
      </div>
      {error && <ErrorMessage error={error} />}
      {!isMaxDataLength && (
        <Button className={classes.addNewRowButton} onClick={addEmptyRow}>
          {t('Add new row')}
        </Button>
      )}
    </>
  )
}

SpreadSheet.propTypes = {
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  maxRows: PropTypes.number.isRequired,
  formikData: PropTypes.array,
  error: PropTypes.string,
  errorRowsIndexes: PropTypes.array,
  className: PropTypes.string
}

export default SpreadSheet
