import { CAN_SELECT_MULTIPLE, NAME, OPTIONS, REQUIRED } from '../../fields'

export const getInitialValues = (editProductVariableData, mediaProductVariableOptions = []) => {
  const formattedInitialOptions = mediaProductVariableOptions.map(option => ({
    text: option.value,
    id: option.id
  }))

  return {
    [NAME]: editProductVariableData[NAME] || '',
    [CAN_SELECT_MULTIPLE]: editProductVariableData[CAN_SELECT_MULTIPLE] || false,
    [REQUIRED]: editProductVariableData[REQUIRED] || false,
    [OPTIONS]: formattedInitialOptions
  }
}
