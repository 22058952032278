import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import Button from '../../../components/Button'
import ActionText from '../../../components/ActionText'

import useCheckMFASetupRequired from '../../../features/hooks/useCheckMFASetupRequired'

import { showMFASetupPage } from '../../../modules/actions/app'

import { SMS_MFA, TOTP_MFA } from '../../Settings/EditProfile/MFAContent/fields'

import useAuthStyles from '../../../styles/common/authPages'
import useDrawerFormsStyles from '../../../styles/common/drawerForms'

const MfaTypeSelection = ({ handleSelectMFAType }) => {
  const dispatch = useDispatch()
  const authClasses = useAuthStyles()
  const drawerFormClasses = useDrawerFormsStyles()
  const { t } = useTranslation()

  const isMFASetupRequired = useCheckMFASetupRequired()

  const handleSkipMFASetup = useCallback(() => {
    dispatch(showMFASetupPage(false))
  }, [dispatch])

  return (
    <>
      <div className={authClasses.formContainer}>
        <div className={drawerFormClasses.section}>
          <h3 className={drawerFormClasses.sectionTitle}>🔒 {t('Secure your account')}</h3>
          <p>
            {isMFASetupRequired
              ? t('Your organisation requires you to set up secure verification on your account.')
              : t('You can set up secure verification on your account.')}
          </p>

          <p>
            <strong>{t('Option 1: Use authenticator app')}</strong>
            <br />
            {t(
              'Authentication codes generated in your authenticator app (works with Google Authenticator, Microsoft' +
                ' Authenticator or any other authenticator app.)'
            )}
          </p>
          <Button onClick={() => handleSelectMFAType(TOTP_MFA)} solid>
            {t('Use authenticator app')}
          </Button>

          <p>
            <strong>{t('Option 2: Use SMS verification')}</strong>
            <br />
            {t('Receive authentication codes via SMS to your mobile device.')}
          </p>
          <Button onClick={() => handleSelectMFAType(SMS_MFA)} solid>
            {t('Use SMS verification')}
          </Button>
        </div>
      </div>

      {!isMFASetupRequired && (
        <ActionText onClick={handleSkipMFASetup} isUnderlined={true} isDarkGrey={true}>
          {t('Skip this step for now')}
        </ActionText>
      )}
    </>
  )
}

export default MfaTypeSelection
