import React from 'react'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import TagsCreateForm from './TagsCreateForm'

import { CREATE_SELF_ACCOUNT_TAG } from '../../../../constants/forms'

function TagsCreate() {
  return (
    <FormDrawerWrapper
      formName={CREATE_SELF_ACCOUNT_TAG}
      title="Create tag"
      openButtonText="Create tag"
      closeOnSubmit={true}
    >
      <TagsCreateForm />
    </FormDrawerWrapper>
  )
}

export default TagsCreate
