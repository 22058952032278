import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import useStyles from './styles'

const FormStepProgress = ({ stepsLength, activeStepNumber }) => {
  const classes = useStyles()

  if (!stepsLength) {
    return null
  }

  return (
    <div className={classes.container}>
      {Array(stepsLength)
        .fill(Math.random())
        .map((item, index) => (
          <div
            key={index}
            className={classnames(classes.stepItem, {
              passed: activeStepNumber >= index
            })}
          />
        ))}
    </div>
  )
}

FormStepProgress.propTypes = {
  stepsLength: PropTypes.number,
  activeStepNumber: PropTypes.number
}

export default FormStepProgress
