import { createStyles } from '../../../../../../../../../../styles/helpers'

const useStyles = createStyles(theme => ({
  skeletonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 300,
    padding: 20
  },
  imagesWrapper: {
    marginBottom: 30
  },
  imagesContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 300,
    padding: 20,
    textAlign: 'center',

    '& img': {
      maxWidth: '100%',
      height: 'auto',
      cursor: 'pointer'
    }
  },
  imagesButtons: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 10px'
  },
  imageButton: {
    height: 30,
    padding: '5px 10px'
  },
  chipButton: {
    height: 22,
    borderRadius: 2,
    cursor: 'pointer',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    color: theme.brandPrimary,
    border: `1px solid ${theme.brandPrimary}`,
    '&.active': {
      border: 'none',
      color: '#fff',
      backgroundColor: theme.brandPrimary
    }
  }
}))

export default useStyles
