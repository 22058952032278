import React, { useCallback, useEffect, useState, cloneElement, useRef } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { NumericFormat } from 'react-number-format'
import FieldPortalContent from '../Field/FieldPortalContent'

import { formatNumericInputValue } from './helpers'

import { isArabic } from '../../../helpers/language'
import { clearLSEPCharacters } from '../clearLSEPCharacters'

import useStyles from './styles'

function InputField({
  id,
  name,
  type,
  showError,
  resetError,
  decimalScale = 2,
  formikValue,
  autoComplete,
  setFieldValue,
  setFieldTouched,
  symbol,
  symbolPosition,
  symbolClassName,
  triggerValueSet,
  enableReinitialize,
  formatType,
  onValueChange,
  onBlur,
  maxLength,
  portalElement,
  textSuggestionsProps,
  requestButton,
  inputClassName,
  fixedDecimalScale,
  focusFromOutside,
  highlightValueFromOutside,
  ...restFieldProps
}) {
  const { t } = useTranslation()
  // format initial formikValue if it is not an empty string
  const [value, setValue] = useState(formikValue)
  const [textDirection, setTextDirection] = useState(isArabic(value) ? 'rtl' : 'ltr')
  const [valueReinitialized, setValueReinitialized] = useState(false)

  const inputRef = useRef()

  useEffect(() => {
    if (focusFromOutside) {
      inputRef.current?.focus()
    }
    if (highlightValueFromOutside) {
      inputRef.current?.select()
    }
  }, [focusFromOutside, highlightValueFromOutside])

  const hasValue = !!value
  const classes = useStyles({
    showError,
    hasValue,
    hasSymbol: !!symbol,
    symbolLength: symbol && symbol.length,
    symbolPosition
  })

  useEffect(() => {
    // hook which helps to set value from outside, as enableReinitialize is only working one time until it will not
    // be reset, so we need to set value from outside in case when it is changed
    // todo check if possible to replace enableReinitialize with it later
    if (triggerValueSet) {
      setValue(formikValue)
    }
  }, [triggerValueSet, formikValue])

  useEffect(() => {
    // we need to enable init value reinitialization for cases like edit form, when value is not available right away
    if (enableReinitialize && !valueReinitialized && formikValue !== value) {
      setValue(formikValue)
      setValueReinitialized(true)
    }
    // eslint-disable-next-line
  }, [formikValue, enableReinitialize, valueReinitialized])

  const handleValueChange = useCallback(
    ({ value, target }) => {
      // first try to set custom value, if not accessible just use value from target
      // support saving 0 as number instead of taking the target value(string)
      const newValue = value === 0 ? 0 : value || target.value
      setValue(newValue)
      resetError()
      setTextDirection(isArabic(newValue) ? 'rtl' : 'ltr')

      // allow to handle some actions from outside
      onValueChange && onValueChange(newValue, name)

      // handle case when field is not focused but the value was changed(e.g. autocomplete)
      const isFocused = document.activeElement === target
      !isFocused && setFieldValue(name, newValue)
    },
    [name, onValueChange, resetError, setFieldValue]
  )

  const handleNumberInputChange = useCallback(
    (values, e) => {
      // get unformatted value from react-number-format instead of using formatted e.target.value
      // the values.value was not used because it was passing formatted value for cases when fixedDecimalScale is true
      // so we are using the floatValue to get number field value
      const unformattedValue = values.floatValue || 0 // always set 0 if value is empty

      // mockup data structure for handleValueChange and set unformatted value
      const target = e.event?.target
        ? e.event.target
        : // event may be undefined in some cases, when user types in something invalid,
          // and then onBlur NumericFormat component is removing value from the input
          {
            value: 0
          }

      handleValueChange({ value: unformattedValue, target })
    },
    [handleValueChange]
  )

  const handleFieldChange = useCallback(() => {
    // reset reinitialized state for possible value resetting
    setValueReinitialized(false)

    // We need to clear the value in some cases:
    // (e.g. user copy text with special character LSEP from text editor, and paste it to input)
    // when type is number ignore clearing to avoid app crash
    const cleanValue = type !== 'number' ? clearLSEPCharacters(value) : value

    setFieldValue(name, cleanValue)
    setFieldTouched(name, true, false)
  }, [name, setFieldTouched, setFieldValue, type, value])

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      // set field value on enter click, otherwise value change is ignored and form is sent
      handleFieldChange()
    }
  }

  const handleOnBlur = useCallback(
    event => {
      onBlur && onBlur(event)

      handleFieldChange()
    },
    [handleFieldChange, onBlur]
  )

  const onSelectTextSuggestion = useCallback(
    text => {
      // mock structure needed in handleFieldChange, and also trigger blur action
      const eventMock = { target: { value: text } }

      handleValueChange(eventMock)
      handleOnBlur(eventMock)
    },
    [handleOnBlur, handleValueChange]
  )

  const inputProps = {
    ...restFieldProps,
    id,
    // name attribute is required
    name,
    onKeyDown: handleKeyDown,
    onBlur: handleOnBlur,
    className: classnames(classes.input, inputClassName),
    autoComplete: autoComplete ? autoComplete : 'on',
    placeholder: t(restFieldProps.placeholder)
  }

  const renderRequestButtonWithProps = () => {
    return cloneElement(requestButton, {
      value
    })
  }

  return (
    <>
      {type === 'number' ? (
        <NumericFormat
          {...inputProps}
          value={formatNumericInputValue(value, decimalScale)}
          inputMode={restFieldProps.inputMode || 'numeric'}
          step="0.01"
          thousandSeparator={true}
          onValueChange={handleNumberInputChange}
          valueIsNumericString
          // decimals support:
          decimalScale={decimalScale}
          fixedDecimalScale={fixedDecimalScale}
          allowedDecimalSeparators={['.', ',']}
          // other
          getInputRef={inputRef}
        />
      ) : (
        <input
          ref={inputRef}
          {...inputProps}
          value={value}
          type={type || 'text'}
          // allow to pass right to left direction for arabic text
          dir={textDirection}
          lang={textDirection === 'rtl' ? 'ar' : null}
          maxLength={maxLength}
          onInput={handleValueChange}
        />
      )}
      {symbol && (
        <span
          className={classnames(classes.symbol, symbolClassName, {
            // symbol position are moved to separate classes to avoid JSS update issues in RTL version of the site
            [classes.symbolEnd]: symbolPosition === 'end',
            [classes.symbolStart]: symbolPosition !== 'end'
          })}
        >
          {symbol}
        </span>
      )}
      {portalElement && (
        <FieldPortalContent
          value={value}
          maxLength={maxLength}
          portalElement={portalElement}
          textSuggestionsProps={textSuggestionsProps}
          onSelectTextSuggestion={onSelectTextSuggestion}
        />
      )}
      {!!requestButton && renderRequestButtonWithProps()}
    </>
  )
}

InputField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  showError: PropTypes.bool,
  resetError: PropTypes.func,
  decimalScale: PropTypes.number,
  formikValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoComplete: PropTypes.string,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  symbol: PropTypes.string,
  symbolPosition: PropTypes.string,
  symbolClassName: PropTypes.string,
  enableReinitialize: PropTypes.bool,
  formatType: PropTypes.string,
  onValueChange: PropTypes.func,
  onBlur: PropTypes.func,
  maxLength: PropTypes.number,
  portalElement: PropTypes.object,
  textSuggestionsProps: PropTypes.object,
  requestButton: PropTypes.node,
  inputClassName: PropTypes.string
}

export default InputField
