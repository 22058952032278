import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { clearCreateAssetGroup, clearCreateAssetsBatch } from '../../../../../modules/actions/assets'

const useClearAssetsGroupCreate = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(clearCreateAssetsBatch())
    dispatch(clearCreateAssetGroup())
  }, [dispatch])
}

export default useClearAssetsGroupCreate
