import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import SkeletonActionButton from './Skeleton'

import { formatOptionsList } from '../../../../formatters'
import { choicesCTASelector } from '../../../../../modules/selectors/choices'

import useStyles from './styles'

function ActionButton({ actionValue, isInProgress, className }) {
  const classes = useStyles()

  const { t } = useTranslation()

  const actionsList = useSelector(choicesCTASelector)

  const formattedActionsList = formatOptionsList({
    list: actionsList,
    labelName: 'label',
    valueName: 'name'
  })

  const selectedAction = formattedActionsList?.find(action => action.value === actionValue)

  const formattedActionValue = actionValue?.replace('_', ' ').toUpperCase()

  const selectedActionLabel = selectedAction?.label || formattedActionValue || ''

  return (
    <div className={classnames(classes.actionButton, className)}>
      {selectedActionLabel ? (
        <span className={classes.actionLabel}>{t(selectedActionLabel)}</span>
      ) : (
        <SkeletonActionButton isInProgress={isInProgress} />
      )}
    </div>
  )
}

ActionButton.propTypes = {
  actionValue: PropTypes.string,
  isInProgress: PropTypes.bool,
  className: PropTypes.string
}

export default ActionButton
