import React, { useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Button from '../../../../components/Button'
import Can from '../../../../features/components/Can'
import AccountFilter from '../../../../features/components/Filters/AccountFilter'
import DeleteItemModal from '../../../../features/components/Modals/DeleteModal/DeleteItemModal'

import { ReactComponent as DeleteIcon } from '../../../../assets/icons/trash-new.svg'

import { QuotationTableContext } from '../QuotationTableContext'
import useDeleteAction from '../../../../features/hooks/useDeleteAction'

import { clearUpdateQuotationsStatusBulk, updateQuotationsStatusBulk } from '../../../../modules/actions/quotations'
import { isClientViewSelector, isUserControllerSelector } from '../../../../modules/selectors/app'
import {
  quotationsStatusBulkWasDeletedSelector,
  updateQuotationsStatusBulkErrorSelector,
  updateQuotationsStatusBulkIsLoadingSelector,
  updateQuotationsStatusBulkSelector
} from '../../../../modules/selectors/quotations'

import { QUOTATION_PERMISSION } from '../../../../constants/permissions'

import useStyles from './styles'

const ProposalsTableActions = ({ accountFilterOption, setAccountFilterOption }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { rowSelection } = useContext(QuotationTableContext)

  const isClientView = useSelector(isClientViewSelector)
  const isUserController = useSelector(isUserControllerSelector)
  const quotationsWasDeleted = useSelector(quotationsStatusBulkWasDeletedSelector)

  const deleteContractHandler = useCallback(
    ({ data }) => {
      // contract delete is just status replacement to deleted
      dispatch(updateQuotationsStatusBulk(data))
    },
    [dispatch]
  )

  const clearDeleteContractsHandler = useCallback(() => {
    dispatch(clearUpdateQuotationsStatusBulk())
  }, [dispatch])

  const {
    handleDelete,
    handleDeleteRequest,
    handleCloseDeleteModal,
    isDeleteModalOpened,
    deleteItemIsLoading,
    deleteItemError
  } = useDeleteAction({
    onDelete: deleteContractHandler,
    onClearDelete: clearDeleteContractsHandler,
    deletedItemSelector: updateQuotationsStatusBulkSelector,
    wasSuccessfullyDeleted: quotationsWasDeleted,
    deleteItemErrorSelector: updateQuotationsStatusBulkErrorSelector, // ignore close on error
    deleteItemIsLoadingSelector: updateQuotationsStatusBulkIsLoadingSelector,
    closeOnError: false
  })

  const onDeleteContracts = useCallback(() => {
    // list of ids should be number
    const deleteIds = Object.keys(rowSelection).map(Number)
    handleDeleteRequest({
      ids: deleteIds,
      status: 'deleted'
    })
  }, [handleDeleteRequest, rowSelection])

  const hasSelectedProposals = Object.keys(rowSelection).length > 0

  return (
    <div className={classes.proposalsActions}>
      <Can I={'delete'} a={QUOTATION_PERMISSION}>
        <Button
          onClick={onDeleteContracts}
          disabled={!hasSelectedProposals}
          className={classes.deleteBtn}
          isSmall={true}
        >
          <DeleteIcon />
          {t('Delete')}
        </Button>
        <DeleteItemModal
          handleDelete={handleDelete}
          handleCloseDeleteModal={handleCloseDeleteModal}
          isDeleteModalOpened={isDeleteModalOpened}
          deleteItemIsLoading={deleteItemIsLoading}
          deleteItemError={deleteItemError}
          showCancelButton={true}
          modalTitle={t('Delete proposals?')}
          deleteModalMessage={t('This can’t be undone')}
        />
      </Can>
      {isUserController && !isClientView && (
        <AccountFilter accountFilterOption={accountFilterOption} setAccountFilterOption={setAccountFilterOption} />
      )}
    </div>
  )
}

ProposalsTableActions.propTypes = {
  accountFilterOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setAccountFilterOption: PropTypes.func.isRequired
}
export default ProposalsTableActions
