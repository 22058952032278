import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning.svg'
import { ReactComponent as SuccessIcon } from '../../../../assets/icons/checked-circle.svg'

import useStyles from './styles'

const MessageBlock = ({ state = 'warning', title, className, color, borderColor, children }) => {
  const classes = useStyles({ color, borderColor })

  const isWarningState = state === 'warning'
  const isSuccessState = state === 'success'

  return (
    <div className={classnames(classes.container, className)}>
        <div className={classes.headerWrapper}>
        {isWarningState && <WarningIcon className={classes.icon} />}
        {isSuccessState && <SuccessIcon className={classes.icon} />}
        {title && <div className={classes.header}>{title}</div>}
      </div>
      {children && <div className={classes.warningText}>{children}</div>}
    </div>
  )
}

MessageBlock.propTypes = {
  state: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  borderColor: PropTypes.string
}

export default MessageBlock
