import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import HighlightedValueSelect from '../../../../../../../../../components/HighlightedValueSelect'
import FiltersSearch from '../../../../../../../../../features/components/Filters/FiltersSearch'

import { formatOptionsList } from '../../../../../../../../../features/formatters'

import {
  clearGetMediaProductAttachedLocations,
  getMediaProductAttachedLocations
} from '../../../../../../../../../modules/actions/mediaOrdersProductLocations'
import { selectedControllerIdSelector } from '../../../../../../../../../modules/selectors/app'
import {
  mediaProductAttachedLocationsIsLoadingSelector,
  mediaProductAttachedLocationsSelector
} from '../../../../../../../../../modules/selectors/mediaOrdersProductLocations'
import { productTagsIsLoadingSelector, productTagsSelector } from '../../../../../../../../../modules/selectors/tags'

import { MEDIA_PRODUCTS_FILTERS_SEARCH } from '../../../../../../../../../features/components/mediaProductsComponents/MediaProductsFilters'

import useStyles from './styles'

function ProductsFilters({ filters, productsCategory }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const tags = useSelector(productTagsSelector)
  const controllerId = useSelector(selectedControllerIdSelector)
  const tagsIsLoading = useSelector(productTagsIsLoadingSelector)
  const locations = useSelector(mediaProductAttachedLocationsSelector)
  const locationsIsLoading = useSelector(mediaProductAttachedLocationsIsLoadingSelector)

  const setSelectedTag = filters.setSelectedTag
  const setSelectedLocations = filters.setSelectedLocations

  const locationsOptions = useMemo(() => {
    return formatOptionsList({
      list: locations,
      labelName: 'name',
      valueName: 'id'
    })
  }, [locations])

  const tagsOptions = useMemo(() => {
    const productsTags = [...tags]

    // sort tags by title
    productsTags.sort((a, b) => {
      if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1
      }
      if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1
      }
      return 0
    })

    return formatOptionsList({
      list: productsTags,
      valueName: 'title',
      labelName: 'title'
    })
  }, [tags])

  const handleChangeFilter = useCallback(
    option => {
      setSelectedTag(option?.value)
    },
    [setSelectedTag]
  )

  const handleChangeLocation = useCallback(
    option => {
      // locations managed as an array
      setSelectedLocations([option?.value])
    },
    [setSelectedLocations]
  )

  useEffect(() => {
    if (controllerId) {
      dispatch(
        getMediaProductAttachedLocations({
          controller: controllerId,
          ordering: 'name',
          ...(productsCategory && { media_category: productsCategory })
        })
      )
    }
  }, [dispatch, controllerId, productsCategory])

  useEffect(() => {
    return () => {
      dispatch(clearGetMediaProductAttachedLocations())
    }
  }, [dispatch])

  return (
    <div className={classes.filtersContainer}>
      <FiltersSearch searchId={MEDIA_PRODUCTS_FILTERS_SEARCH} />

      <div className={classes.rightSideFilters}>
        {!!tagsOptions.length && (
          <HighlightedValueSelect
            value={filters.selectedTag}
            onChange={handleChangeFilter}
            className={classes.select}
            options={tagsOptions}
            placeholder="Filter"
            isLoading={tagsIsLoading}
            isSmall
          />
        )}
        {!!locationsOptions.length && (
          <HighlightedValueSelect
            value={filters.selectedLocations[0]}
            onChange={handleChangeLocation}
            className={classes.select}
            options={locationsOptions}
            placeholder="Location"
            isLoading={locationsIsLoading}
            isSmall
          />
        )}
      </div>
    </div>
  )
}

ProductsFilters.propTypes = {
  filters: PropTypes.shape({
    selectedTag: PropTypes.string,
    selectedLocations: PropTypes.array,
    setSelectedTag: PropTypes.func,
    setSelectedLocations: PropTypes.func
  })
}
export default ProductsFilters
