import { createStyles } from '../../../../../../styles/helpers'

export const adPreviewScale = 0.5
export const adApprovalsPreviewScale = 0.9

const useStyles = createStyles({
  adPreviewWrapper: {
    height: ({ previewHeight }) => previewHeight || 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    marginBottom: 10,
    minHeight: 195
  },
  adPreview: {
    // this is needed to make sure that the story preview is not cut off
    flex: 1,
    transform: ({ isAdApprovalsStyle }) =>
      isAdApprovalsStyle ? `scale(${adApprovalsPreviewScale})` : `scale(${adPreviewScale})`
  }
})

export default useStyles
