import React, { useMemo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Drawer from '../../../../components/Drawer'
import EditSectionsList from '../../../../features/components/Forms/EditForm/EditSectionsList'
import NameSectionPreview from '../../../ReusableFormSections/NameSection/NameSectionPreview'
import NameSection from './Sections/NameSection'
import BudgetSectionPreview from './Sections/BudgetSection/BudgetSectionPreview'
import BudgetSectionForm from './Sections/BudgetSection/BudgetSectionForm'
import DatesSectionPreview from './Sections/DatesSection/DatesSectionPreview'
import DatesSectionForm from './Sections/DatesSection/DatesSectionForm'
import ScheduleSectionPreview from './Sections/ScheduleSection/ScheduleSectionPreview'
import ScheduleSection from './Sections/ScheduleSection'
import GeoTargetingSectionPreview from './Sections/GeoTargetingSection/GeoTargetingSectionPreview'
import GeoTargetingSectionForm from './Sections/GeoTargetingSection/GeoTargetingSectionForm'
import AudienceSectionPreview from './Sections/AudienceSection/AudienceSectionPreview'
import AudienceSectionForm from './Sections/AudienceSection/AudienceSectionForm'
import LanguagesSectionPreview from './Sections/LanguagesSection/LanguagesSectionPreview'
import LanguagesSectionForm from './Sections/LanguagesSection/LanguagesSectionForm'
import CustomAudiencesSectionPreview from './Sections/CustomAudiencesSection/CustomAudiencesSectionPreview'
import CustomAudiencesSectionForm from './Sections/CustomAudiencesSection/CustomAudiencesSectionForm'
import PlacementSectionPreview from './Sections/PlacementSection/PlacementSectionPreview'
import PlacementSectionForm from './Sections/PlacementSection/PlacementSectionForm'

import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'
import useManageEditFormData from '../../../../hooks/formHooks/useManageEditFormData'

import { insertIf } from '../../../../helpers/common'

import { getCampaign } from '../../../../modules/actions/campaigns'
import { getChoicesCountries, getChoicesLanguages } from '../../../../modules/actions/choices'
import { clearLineItem, getLineItem } from '../../../../modules/actions/lineItems'
import { clearLocationLists, getLocationLists } from '../../../../modules/actions/location'
import { lineItemSelector, lineItemWasLoadedSelector } from '../../../../modules/selectors/lineItems'
import { campaignSelector, campaignWasLoadedSelector } from '../../../../modules/selectors/campaigns'
import {
  choicesCountriesWasLoadedSelector,
  choicesLanguagesWasLoadedSelector
} from '../../../../modules/selectors/choices'
import { selectedAdAccountIdSelector, selectedSelfAccountSelector } from '../../../../modules/selectors/app'
import { locationListsWasLoadedSelector } from '../../../../modules/selectors/location'

import { LINE_ITEM_FACEBOOK_EDIT } from '../../../../constants/forms'
import { NAME } from '../../../ReusableFormFields/LineItemForms/fields'
import {
  LINE_ITEM_BUDGET,
  LINE_ITEM_AUDIENCE,
  LINE_ITEM_DATES,
  GEO_TARGETING,
  LINE_ITEM_LANGUAGES,
  LINE_ITEM_CUSTOM_AUDIENCES,
  LINE_ITEM_PLACEMENT
} from './fields'
import { SCHEDULE } from '../../../ReusableFormSections/ScheduleSection/fields'

// here defined initial open states for LineItemFacebookEdit sections
const sectionsInitialOpenStates = {
  [NAME]: false,
  [LINE_ITEM_BUDGET]: false,
  [GEO_TARGETING]: false,
  [LINE_ITEM_AUDIENCE]: false,
  [LINE_ITEM_DATES]: false,
  [LINE_ITEM_LANGUAGES]: false,
  [LINE_ITEM_CUSTOM_AUDIENCES]: false,
  [LINE_ITEM_PLACEMENT]: false,
  [SCHEDULE]: false
}

const LineItemFacebookEdit = ({ shouldBeCleared }) => {
  const dispatch = useDispatch()

  const campaign = useSelector(campaignSelector)
  const campaignWasLoaded = useSelector(campaignWasLoadedSelector)
  const lineItem = useSelector(lineItemSelector)
  const lineItemWasLoaded = useSelector(lineItemWasLoadedSelector)
  const choicesLanguagesWasLoaded = useSelector(choicesLanguagesWasLoadedSelector)
  const choicesCountriesWasLoaded = useSelector(choicesCountriesWasLoadedSelector)
  const locationListsWasLoaded = useSelector(locationListsWasLoadedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const selectedSelfAccountId = useSelector(selectedSelfAccountSelector)

  const { campaign_id: campaignId, budget_daily: lineItemBudgetDaily } = lineItem

  const {
    objective: campaignObjective,
    budget_lifetime: campaignBudgetLifetime,
    budget_daily: campaignBudgetDaily
  } = campaign

  const isInitialDataLoading =
    !campaignWasLoaded ||
    !lineItemWasLoaded ||
    !locationListsWasLoaded ||
    !choicesLanguagesWasLoaded ||
    !choicesCountriesWasLoaded

  const getLineItemDataHandler = useCallback(
    selectedEditItemId => {
      // Load lineItem data only if user is on lineItems list page
      // if user is on lineItem summary page - we already have lineItem data in store
      if (!lineItem.id && campaignObjective) {
        dispatch(
          getLineItem({ id: selectedEditItemId, campaign_objective: campaignObjective, account: selectedAdAccountId })
        )
      }
    },
    [dispatch, selectedAdAccountId, campaignObjective, lineItem.id]
  )

  const clearLineItemHandler = useCallback(() => {
    if (shouldBeCleared) {
      dispatch(clearLineItem())
    }
  }, [dispatch, shouldBeCleared])

  const { isFormOpen, handleFormClose } = useManageFormsDrawer({ formName: LINE_ITEM_FACEBOOK_EDIT })

  useManageEditFormData({
    formName: LINE_ITEM_FACEBOOK_EDIT,
    loadedDataId: lineItem.id,
    getDataHandler: getLineItemDataHandler,
    clearDataHandler: clearLineItemHandler
  })

  // show if campaign doesn't have budget and line item has daily budget
  const showScheduleSection = !campaignBudgetLifetime && !campaignBudgetDaily && !lineItemBudgetDaily

  const lineItemEditSections = useMemo(
    () => [
      {
        sectionName: NAME,
        title: 'Name',
        PreviewComponent: <NameSectionPreview dataSelector={lineItemSelector} />,
        FormComponent: <NameSection />
      },
      {
        sectionName: LINE_ITEM_BUDGET,
        title: 'Budget',
        PreviewComponent: <BudgetSectionPreview />,
        FormComponent: <BudgetSectionForm />
      },
      {
        sectionName: LINE_ITEM_DATES,
        title: 'Dates',
        PreviewComponent: <DatesSectionPreview />,
        FormComponent: <DatesSectionForm />
      },
      // hide section when line item has budget
      ...insertIf(showScheduleSection, {
        sectionName: SCHEDULE,
        title: 'Schedule',
        PreviewComponent: <ScheduleSectionPreview />,
        FormComponent: <ScheduleSection />
      }),
      {
        sectionName: GEO_TARGETING,
        title: 'Location Targeting',
        PreviewComponent: <GeoTargetingSectionPreview />,
        FormComponent: <GeoTargetingSectionForm />
      },
      {
        sectionName: LINE_ITEM_AUDIENCE,
        title: 'Audience',
        PreviewComponent: <AudienceSectionPreview />,
        FormComponent: <AudienceSectionForm />
      },
      {
        sectionName: LINE_ITEM_LANGUAGES,
        title: 'Languages',
        PreviewComponent: <LanguagesSectionPreview />,
        FormComponent: <LanguagesSectionForm />
      },
      {
        sectionName: LINE_ITEM_CUSTOM_AUDIENCES,
        title: 'Custom Audiences',
        PreviewComponent: <CustomAudiencesSectionPreview />,
        FormComponent: <CustomAudiencesSectionForm />
      },
      {
        sectionName: LINE_ITEM_PLACEMENT,
        title: 'Placements',
        PreviewComponent: <PlacementSectionPreview />,
        FormComponent: <PlacementSectionForm />
      }
    ],
    [showScheduleSection]
  )

  // if user is on lineItem summary page - we don't have campaign data in store, so we need upload it
  useEffect(() => {
    if (!campaign.id && isFormOpen && campaignId) {
      dispatch(getCampaign({ id: campaignId }))
    }
  }, [dispatch, campaignId, campaign.id, isFormOpen])

  useEffect(() => {
    // get options for GeoTargetingSection Preview and Form
    if (isFormOpen && !choicesCountriesWasLoaded) {
      dispatch(getChoicesCountries())
    }
  }, [dispatch, choicesCountriesWasLoaded, isFormOpen])

  useEffect(() => {
    // get options for LanguagesSection Preview and Form
    if (isFormOpen && !choicesLanguagesWasLoaded) {
      dispatch(getChoicesLanguages({ account: selectedAdAccountId }))
    }
  }, [dispatch, choicesLanguagesWasLoaded, selectedAdAccountId, isFormOpen])

  useEffect(() => {
    // preload location lists for geo targeting section
    if (isFormOpen && !locationListsWasLoaded) {
      dispatch(
        getLocationLists({
          account: selectedSelfAccountId
        })
      )
    }
  }, [dispatch, isFormOpen, locationListsWasLoaded, selectedSelfAccountId])

  useEffect(() => {
    return () => {
      dispatch(clearLocationLists())
    }
  }, [dispatch])

  return (
    <Drawer
      title="Update the settings of your ad set"
      subTitle="Edit a Facebook ad set"
      isOpen={isFormOpen}
      onClose={handleFormClose}
    >
      <EditSectionsList
        editSections={lineItemEditSections}
        isEditDataLoading={isInitialDataLoading}
        editItemData={lineItem}
        sectionsInitialOpenStates={sectionsInitialOpenStates}
      />
    </Drawer>
  )
}

export default LineItemFacebookEdit
