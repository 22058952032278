import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import PageSection from '../../PageSection'
import DoughnutGraph from '../../Graphs/DoughnutGraph'

import { capitalizeFirstLetter } from '../../../../helpers/common'
import { getReusableTooltipCallbackOptions } from '../helpers'

import useStyles from '../styles'

const PlatformGraphSection = ({ rawGraphData, showAdvancedMetrics, currencySymbol }) => {
  const classes = useStyles()

  const platformGraphData = useMemo(() => rawGraphData && rawGraphData.map(data => data.impressions), [rawGraphData])
  const platformGraphLabels = useMemo(
    () => rawGraphData && rawGraphData.map(data => `${capitalizeFirstLetter(data.breakdown)} ${data.percent}%`),
    [rawGraphData]
  )

  const tooltipCallbackOptions = useMemo(
    () =>
      getReusableTooltipCallbackOptions({
        rawGraphData,
        showAdvancedMetrics,
        currencySymbol,
        showImpressionsAsLabel: false
      }),
    [currencySymbol, rawGraphData, showAdvancedMetrics]
  )

  return (
    <PageSection className={classes.sectionContainer}>
      <div className={classes.headingContainer}>
        <div className={classes.title}>Platform</div>
      </div>
      <DoughnutGraph
        className={classes.graphWrapper}
        data={platformGraphData}
        labels={platformGraphLabels}
        tooltipCallbackOptions={tooltipCallbackOptions}
        format="integer"
      />
    </PageSection>
  )
}

PlatformGraphSection.propTypes = {
  rawGraphData: PropTypes.array.isRequired,
  showAdvancedMetrics: PropTypes.bool,
  currencySymbol: PropTypes.string
}

export default PlatformGraphSection
