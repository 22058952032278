import React from 'react'

import ClientAccountCreateForm from './ClientAccountCreateForm'
import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'

import { CLIENT_ACCOUNT_CREATE } from '../../../../../constants/forms'

const ClientAccountCreate = () => {
  return (
    <FormDrawerWrapper
      formName={CLIENT_ACCOUNT_CREATE}
      title="Create a new client account"
      openButtonText="New Account"
    >
      <ClientAccountCreateForm />
    </FormDrawerWrapper>
  )
}

export default ClientAccountCreate
