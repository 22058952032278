import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { format } from 'date-fns'

import Can from '../../../features/components/Can'
import AdFacebookEdit from '../../../forms/Facebook/AdForms/AdFacebookEdit'
import AdGoogleEdit from '../../../forms/Google/AdForms/GoogleDisplayAd/AdGoogleEdit'
import AdTikTokEdit from '../../../forms/Tiktok/AdForms/AdTikTokEdit'
import SummaryPagesAdsLists from '../../../features/components/SummaryPagesAdsLists/SummaryPagesAdsLists'

import { getAds } from '../../../modules/actions/ads'
import { adsAfterSelector, adsLoadingSelector, getAdsSelector } from '../../../modules/selectors/ads'
import { selectedPlatformSelector, selectedAdAccountIdSelector } from '../../../modules/selectors/app'

import { getListItemInsights } from '../../../features/helpers/componentsHelpers'

import { formatSortParams } from '../../../features/formatters'

import { CAMPAIGN_FILTERS_DEFAULT } from '../../../constants/selectLists/listItemFiltersList'
import { DATES_FORMAT_BE } from '../../../constants/dates'
import { FACEBOOK_PLATFORM, GOOGLE_PLATFORM, TIKTOK_PLATFORM } from '../../../constants/selectLists/platformList'
import { LINE_ITEM_PERMISSION } from '../../../constants/permissions'

// This component currently is used on CampaignSummary and LineItemSummary pages
function SummaryPagesAdsContent({ searchTerm, filter, dateRange, sort, isLineItemSummaryPage }) {
  const dispatch = useDispatch()

  const { campaign_id: campaignId, line_item_id: lineItemId } = useParams()

  const selectedPlatform = useSelector(selectedPlatformSelector)
  const adsList = useSelector(getAdsSelector)
  const adsAfter = useSelector(adsAfterSelector)
  const adsLoading = useSelector(adsLoadingSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const { date_preset, startDate, endDate } = dateRange

  const isAdsEmpty = !adsList.length

  // on campaigns summary we don't show this section if it is empty
  // but, for example, on line items summary page we don't have this rule
  const showAdsSection = isLineItemSummaryPage || adsLoading || !isAdsEmpty

  const includePaginationParams = selectedPlatform === FACEBOOK_PLATFORM

  const getAdsParams = useMemo(() => {
    return {
      campaign_id: campaignId,
      ad_group_id: lineItemId,
      account: selectedAdAccountId,
      date_from: startDate && format(startDate, DATES_FORMAT_BE),
      date_to: endDate && format(endDate, DATES_FORMAT_BE),
      date_preset,
      insights: getListItemInsights(selectedPlatform),
      status: filter === CAMPAIGN_FILTERS_DEFAULT ? undefined : filter,
      ...(searchTerm && { search: searchTerm }),
      ...(sort && formatSortParams(sort)),
      ...(includePaginationParams && {
        limit: 8
      }),
      detail: true
    }
  }, [
    selectedPlatform,
    startDate,
    endDate,
    date_preset,
    selectedAdAccountId,
    campaignId,
    lineItemId,
    sort,
    searchTerm,
    filter,
    includePaginationParams
  ])

  const loadAdsHandler = useCallback(() => {
    dispatch(getAds(getAdsParams))
  }, [dispatch, getAdsParams])

  const loadMoreAdsHandler = useCallback(() => {
    dispatch(getAds({ ...getAdsParams, after: adsAfter }))
  }, [dispatch, getAdsParams, adsAfter])

  useEffect(() => {
    loadAdsHandler && loadAdsHandler()
  }, [loadAdsHandler])

  return showAdsSection ? (
    <>
      <SummaryPagesAdsLists
        selectedPlatform={selectedPlatform}
        adAccountId={selectedAdAccountId}
        loadMoreHandler={adsAfter ? loadMoreAdsHandler : null}
      />

      {selectedPlatform === FACEBOOK_PLATFORM && (
        <Can I="edit" a={LINE_ITEM_PERMISSION}>
          <AdFacebookEdit filter={filter} />
        </Can>
      )}
      {selectedPlatform === GOOGLE_PLATFORM && (
        <Can I="edit" a={LINE_ITEM_PERMISSION}>
          <AdGoogleEdit />
        </Can>
      )}
      {selectedPlatform === TIKTOK_PLATFORM && (
        <Can I="edit" a={LINE_ITEM_PERMISSION}>
          <AdTikTokEdit />
        </Can>
      )}
    </>
  ) : null
}

export default SummaryPagesAdsContent
