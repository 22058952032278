import { ADMIN, ASSET_MANAGER, BUYER, INSTALLER } from '../../../constants/permissions'

export const getAllowSelfAccounts = controllerMemberRole => {
  return controllerMemberRole !== ADMIN
}

export const getShowBrandCategorySelect = controllerMemberRole => {
  return controllerMemberRole === BUYER
}

export const getShowMediaSubCategories = controllerMemberRole => {
  return controllerMemberRole === ASSET_MANAGER || controllerMemberRole === INSTALLER
}
