import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { getIn } from 'formik'
import PropTypes from 'prop-types'

import AdFileUpload from '../AdFileUpload'
import FacebookExistedMedia from './FacebookExistedMedia'
import ErrorMessage from '../../../components/Form/ErrorMessage'
import FieldRow from '../../../features/components/Form/FieldsSection/FieldRow'
import FieldSectionSpacer from '../../../features/components/Form/FieldsSection/FieldSectionSpacer'

import { concat } from '../../../helpers/common'

import { fileUpload } from '../../../modules/actions/files'

import { MEDIA_FILE_UPLOAD, UPLOADED_MEDIA_TYPE } from '../AdFileUpload/fields'
import {
  FILE_HASH,
  FILE_ID,
  FILE_NAME,
  FILE_URL,
  FILE_URL_BLOB,
  initialFileValues,
  initialMediaValues,
  MEDIA_FILE_IMAGE_USE_LIBRARY,
  MEDIA_FILE_VIDEO_USE_LIBRARY,
  MEDIA_TYPE,
  VIDEO_PICTURE
} from '../../Facebook/AdForms/fields'

import useStyles from './styles'

const FacebookMediaUpload = ({
  formik,
  itemPath,
  title = 'Image',
  description = 'Upload an image or video, or select from one of the existing files',
  mediaValidationSchema,
  mediaUrlError,
  recommendedSize,
  adAccountId,
  initialExistedLoadSize
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { values, setFieldValue } = formik

  const fileURLKey = concat(itemPath, '.', FILE_URL)
  const uploadedMediaTypeKey = concat(itemPath, '.', UPLOADED_MEDIA_TYPE)

  const mediaUrl = getIn(values, fileURLKey)
  const uploadedMediaType = getIn(values, uploadedMediaTypeKey)

  const showPreview = !!mediaUrl

  const setMediaFiles = useCallback(
    // set Media files to Formik according to media quantity
    newFileValues => {
      const updatedItem = {
        ...getIn(values, itemPath),
        ...newFileValues
      }
      setFieldValue(itemPath, updatedItem)
    },
    [itemPath, values, setFieldValue]
  )

  const handleMediaRemove = useCallback(() => {
    // set initial media file values for deleted file
    const mediaItem = getIn(values, itemPath)
    const initialItemMediaValues = {
      ...mediaItem,
      ...initialFileValues,
      ...initialMediaValues
    }
    setFieldValue(itemPath, initialItemMediaValues)
  }, [itemPath, setFieldValue, values])

  const handleMediaStartUpload = useCallback(
    // file is loading to Server, on success file uploading the handleMediaUploaded is called and set rest fields
    (file, newFileValues, fileUrlBlob) => {
      // remove file when select file from another type
      handleMediaRemove()

      const newMediaValues = {
        ...initialFileValues,
        ...initialMediaValues,
        ...newFileValues,
        ...(fileUrlBlob && { [FILE_URL_BLOB]: fileUrlBlob })
      }
      setMediaFiles(newMediaValues)

      // upload file to server
      dispatch(fileUpload(file, { isPlatformRelated: true, ...(adAccountId && { adAccountId }) }))
    },
    [dispatch, handleMediaRemove, setMediaFiles, adAccountId]
  )

  const handleMediaUploaded = useCallback(
    uploadedFile => {
      const newFileValues = {
        [FILE_URL]: uploadedFile.url,
        [FILE_HASH]: uploadedFile.hash,
        [FILE_ID]: uploadedFile.id,
        // if type of media is video, we have additional property VIDEO_PICTURE
        ...(uploadedFile.picture && { [VIDEO_PICTURE]: uploadedFile.picture })
      }

      setMediaFiles(newFileValues)
    },
    [setMediaFiles]
  )

  // hide file upload once other media type was uploaded:
  const showFileUpload = !showPreview || (showPreview && uploadedMediaType === MEDIA_FILE_UPLOAD)
  // hide existed file once other media type was uploaded then MEDIA_FILE_IMAGE_USE_LIBRARY,
  // MEDIA_FILE_VIDEO_USE_LIBRARY
  const existedFileWasUploaded =
    showPreview &&
    (uploadedMediaType === MEDIA_FILE_IMAGE_USE_LIBRARY || uploadedMediaType === MEDIA_FILE_VIDEO_USE_LIBRARY)
  const showExistedFiles = !showPreview || existedFileWasUploaded

  return (
    <FieldRow
      title={title}
      description={description}
      footer={mediaUrlError && <ErrorMessage error={mediaUrlError} />}
      footerClassName={classes.errorContainer}
    >
      {showFileUpload && (
        <AdFileUpload
          values={formik.values}
          fileValidationSchema={mediaValidationSchema}
          onFileStartUpload={handleMediaStartUpload}
          onFileUploaded={handleMediaUploaded}
          onFileRemove={handleMediaRemove}
          mediaType={MEDIA_FILE_UPLOAD}
          fileURLKey={fileURLKey}
          fileNameKey={concat(itemPath, '.', FILE_NAME)}
          fileUrlBlobKey={concat(itemPath, '.', FILE_URL_BLOB)}
          fileTypeKey={concat(itemPath, '.', MEDIA_TYPE)}
          uploadedMediaTypeKey={uploadedMediaTypeKey}
          recommendedSize={recommendedSize}
          isDraggable
        />
      )}
      {showFileUpload && showExistedFiles && <FieldSectionSpacer hasMargin />}
      {showExistedFiles && (
        <FacebookExistedMedia
          // hide tabs when existed file was selected
          showTabsSelector={!existedFileWasUploaded}
          formik={formik}
          itemPath={itemPath}
          setMediaFiles={setMediaFiles}
          adAccountId={adAccountId}
          initialLoadSize={initialExistedLoadSize}
        />
      )}
    </FieldRow>
  )
}

FacebookMediaUpload.propTypes = {
  formik: PropTypes.object,
  itemPath: PropTypes.string,
  // item: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  initialExistedLoadSize: PropTypes.number,
  mediaValidationSchema: PropTypes.object,
  mediaUrlError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  recommendedSize: PropTypes.string,
  adAccountId: PropTypes.string
}

export default FacebookMediaUpload
