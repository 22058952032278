import React from 'react'
import { useTranslation } from 'react-i18next'

import Field from '../../../../../../../components/Form/Field'
import FieldLabelWrapper from '../../../../../../../features/components/Form/FieldLabelWrapper'

import {
  GENDER_OPTION,
  GENDER_OPTION_ALL,
  GENDER_OPTION_FEMALES,
  GENDER_OPTION_MALES
} from '../../../../../../ReusableFormFields/GenderFields/fields'

const AudienceSection = ({ formik }) => {
  const { t } = useTranslation()

  const formattedGender = [
    { value: GENDER_OPTION_MALES, label: t('Only males') },
    { value: GENDER_OPTION_FEMALES, label: t('Only females') },
    { value: GENDER_OPTION_ALL, label: t('All') }
  ]

  return (
    <FieldLabelWrapper label="Gender">
      <Field formik={formik} name={GENDER_OPTION} placeholder="Gender" options={formattedGender} isSearchable={false} />
    </FieldLabelWrapper>
  )
}

export default AudienceSection
