import { v4 as uuidv4 } from 'uuid'

import { cloneElementForPDF } from '../../../../helpers/pdf'

import {
  PAGE_WRAPPER_TITLE_CLASSNAME,
  SECTION_HEADER_CONTAINER_CLASSNAME,
  TABLE_CLASSNAME
} from '../../../../constants/pdf'
import { getClassnamePrefix } from '../../../../pages/MediaOrderSummary/MediaOrderSummaryContent/CampaignsSection/CampaignsSectionActions/generatePdf'

const splitListByPages = ({ splittedByPage, sectionId, header, listSelector, isRtl }) => {
  const splittedListItems = []

  const listSelectorWithPrefix = listSelector || getClassnamePrefix(TABLE_CLASSNAME, isRtl)

  splittedByPage.forEach((page, index) => {
    const newList = cloneElementForPDF(`${sectionId} ${listSelectorWithPrefix}`, `${sectionId.slice(1)}-${uuidv4()}`)
    newList.innerHTML = ''

    for (const row of page) {
      newList.appendChild(row)
    }

    if (index === 0) {
      // first page has header with section title
      const wrapper = document.createElement('div')
      wrapper.className = 'temporary-pdf-wrapper'
      // move element out of the visible screen
      wrapper.style.position = 'absolute'
      wrapper.style.width = '1200px'
      wrapper.style.left = '-10000px'

      header && wrapper.appendChild(header)
      wrapper.appendChild(newList)
      document.body.appendChild(wrapper)

      splittedListItems.push(wrapper)
    } else {
      splittedListItems.push(newList)
    }
  })

  return splittedListItems
}

// the idea of this function is to get section which has one or multiple <List /> component rendered inside
// if List components has more list items then chunkSize split it to according amount items
// to fit the list on one PDF page and void cutting list items in unexpected places
export const getSectionSplitByPage = ({
  sectionId,
  listItemSelector,
  chunkSize,
  processListItems = null,
  listSelector,
  childSectionsSelector,
  isRtl
}) => {
  const section = document.querySelector(sectionId)
  const childSectionSelectorPrefixed = childSectionsSelector || getClassnamePrefix(TABLE_CLASSNAME, isRtl)

  if (!section) return []

  const newUl = section.cloneNode(true)

  if (processListItems) {
    processListItems(newUl)
  }

  // one section could have multiple child sections
  const childSections = newUl.querySelectorAll(`${sectionId} ${childSectionSelectorPrefixed}`)

  const childSectionsSplittedByPages = []
  childSections.forEach((list, index) => {
    // split each list into pages
    const splittedByPage = []

    const sectionListItems = Array.from(list.querySelectorAll(listItemSelector))
    for (let i = 0; i < sectionListItems.length; i += chunkSize) {
      splittedByPage.push(sectionListItems.slice(i, i + chunkSize))
    }

    const sectionHeaderClassname = getClassnamePrefix(SECTION_HEADER_CONTAINER_CLASSNAME, isRtl)
    const pageWrapperClassname = getClassnamePrefix(PAGE_WRAPPER_TITLE_CLASSNAME, isRtl)
    const headerNode =
      document.querySelector(`${sectionId} ${sectionHeaderClassname}`) ||
      document.querySelector(`${sectionId} ${pageWrapperClassname}`)
    // show header only for first list
    const showHeader = index === 0
    const header = showHeader && headerNode?.cloneNode(true)

    childSectionsSplittedByPages.push(splitListByPages({ splittedByPage, sectionId, header, listSelector, isRtl }))
  })

  // as each list could have multiple pages, we need to flatten array so as a result we return list of elements to screenshot
  return childSectionsSplittedByPages.flat()
}
