import React, { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Select from '../../../../../../../../components/Select'
import { MediaOrderFormContext } from '../../../../../MediaOrderFormContext'

import { selectedControllerRelatedSelfAccountsListSelector } from '../../../../../../../../modules/selectors/app'

import { BRAND, initialBrand, initialMediaProducts, MEDIA_PRODUCTS } from '../../../fields'
import useStyles from './styles'

function AccountSelectionSection({ setFieldValue }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const controllerSelfAccountsList = useSelector(selectedControllerRelatedSelfAccountsListSelector)

  const { contextSelfAccountData, handleContextSelfAccountChange } = useContext(MediaOrderFormContext)
  const handleSelfAccountChange = useCallback(
    newSelfAccount => {
      handleContextSelfAccountChange(newSelfAccount)

      // reset media products as these related on selfAccount currency
      setFieldValue(MEDIA_PRODUCTS, initialMediaProducts)
      // reset selected brand which relates on selfAccount
      setFieldValue(BRAND, initialBrand)
    },
    [handleContextSelfAccountChange, setFieldValue]
  )

  return (
    <section className={classes.accountSelectSection}>
      <h3>{t('Account')}</h3>
      <Select
        placeholder="Account"
        className={classes.accountSelect}
        options={controllerSelfAccountsList}
        value={contextSelfAccountData}
        onChange={handleSelfAccountChange}
        expandMenu
        formatValue={false} // save full data object
        isSearchable={true}
      />
    </section>
  )
}

export default AccountSelectionSection
