import { call, put, all, takeEvery } from 'redux-saga/effects'

import {
  createAutomationRuleService,
  deleteAutomationRuleService,
  getAutomationRulesService
} from '../services/automationRules'

import {
  CREATE_AUTOMATION_RULE,
  createAutomationRuleFailure,
  createAutomationRuleSuccess,
  DELETE_AUTOMATION_RULE,
  deleteAutomationRuleFailure,
  deleteAutomationRuleSuccess,
  GET_AUTOMATION_RULES,
  getAutomationRulesFailure,
  getAutomationRulesSuccess
} from '../actions/automationRules'

function* automationRulesWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_AUTOMATION_RULES, getAutomationRulesWorker),
    takeEvery(CREATE_AUTOMATION_RULE, createAutomationRuleWorker),
    takeEvery(DELETE_AUTOMATION_RULE, deleteAutomationRuleWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getAutomationRulesWorker({ params }) {
  try {
    const response = yield call(getAutomationRulesService, params)
    yield put(getAutomationRulesSuccess(response))
  } catch (e) {
    yield put(getAutomationRulesFailure(e))
  }
}

function* createAutomationRuleWorker({ automationRuleData }) {
  try {
    const response = yield call(createAutomationRuleService, automationRuleData)
    yield put(createAutomationRuleSuccess(response))
  } catch (error) {
    yield put(createAutomationRuleFailure(error))
  }
}

function* deleteAutomationRuleWorker({ id }) {
  try {
    yield call(deleteAutomationRuleService, id)
    yield put(deleteAutomationRuleSuccess(id))
  } catch (error) {
    yield put(deleteAutomationRuleFailure(error))
  }
}

export default automationRulesWatcher
