import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { redirectTo } from '../../../helpers/url'
import { closeForm } from '../../../modules/actions/forms'

export default function useRedirectFormDrawer(route) {
  const dispatch = useDispatch()

  const handleDrawerRedirect = useCallback(
    (redirectionParams, customRoute) => {
      // first the form need to be closed to clean up URL from the form id
      dispatch(closeForm())
      // then it possible to redirect
      redirectTo(customRoute || route, redirectionParams)
    },
    [dispatch, route]
  )

  return useMemo(() => handleDrawerRedirect, [handleDrawerRedirect])
}
