import React from 'react'

import FormDrawerWrapper from '../../../features/components/FormDrawerWrapper'
import IdentityTikTokCreateForm from './IdentityTikTokCreateForm'

import { IDENTITY_TIK_TOK_CREATE } from '../../../constants/forms'

const IdentityTikTokCreate = () => {
  return (
    <FormDrawerWrapper
      formName={IDENTITY_TIK_TOK_CREATE}
      title="Add a TikTok Identity"
      openButtonText="Add a TikTok Identity"
    >
      <IdentityTikTokCreateForm />
    </FormDrawerWrapper>
  )
}

export default IdentityTikTokCreate
