import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useMediaQuery } from 'react-responsive'

import { splitFormattedNumber } from '../../../helpers/numbers'

import { phonesDownSize } from '../../../styles/const/breakpoints'

import useStyles from './styles'

function TableValueMetric({ value, subValue, className, valueClassName, showEmptySubValue, wrapMobileSubValue }) {
  const classes = useStyles({ wrapMobileSubValue })

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  let intValue = null
  let remainderValue = null

  if (value) {
    // split for decimal and remainder
    const splitValue = splitFormattedNumber(value)
    intValue = splitValue[0]
    remainderValue = splitValue[1]
  }

  return (
    <>
      {isMobile ? (
        <span className={classes.mobileContainer}>
          {intValue || '-'}
          {showEmptySubValue ? (
            <span className={classes.bracketsText}>({subValue || '-'})</span>
          ) : (
            !!subValue && <span className={classes.bracketsText}>({subValue || '-'})</span>
          )}
        </span>
      ) : (
        <div className={classnames(className, classes.metricsItem)}>
          <div className={classnames(classes.metricsValue, valueClassName)}>
            {/*<sub> to get tiny text*/}
            {intValue ? intValue : '-'}
            {remainderValue && (
              <>
                .<sub>{remainderValue}</sub>
              </>
            )}{' '}
          </div>
          {showEmptySubValue ? (
            // still show ('-') if subValue is empty
            <p className={classes.subValue}>({subValue ? subValue : '-'})</p>
          ) : (
            !!subValue && <p className={classes.subValue}>({subValue})</p>
          )}
        </div>
      )}
    </>
  )
}

export default TableValueMetric

TableValueMetric.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  valueClassName: PropTypes.string,
  showEmptySubValue: PropTypes.bool
}
