export const sliderPreviewParams = [
  {
    loop: true,
    rtl: false
  },
  [
    slider => {
      let timeout
      let mouseOver = false
      function clearNextTimeout() {
        clearTimeout(timeout)
      }
      function nextTimeout() {
        clearTimeout(timeout)
        if (mouseOver) return
        timeout = setTimeout(() => {
          // Changes the currently active slide to the next one when called. If exists.
          slider.next()
        }, 2000)
      }

      // Registers an event hooks mouseover and mouseout when created.
      slider.on('created', () => {
        // Stop sleder on mouseover
        slider.container.addEventListener('mouseover', () => {
          mouseOver = true
          clearNextTimeout()
        })
        // Start slider on mouseout
        slider.container.addEventListener('mouseout', () => {
          mouseOver = false
          nextTimeout()
        })
        nextTimeout()
      })

      // When Drag has started clear timeout that changes slides
      slider.on('dragStarted', clearNextTimeout)
      // When Animation has ended start timeout that changes slides
      slider.on('animationEnded', nextTimeout)
      // the update function was called due to a size change or other trigger
      slider.on('updated', nextTimeout)
    }
  ]
]
