import React from 'react'
import { useTranslation } from 'react-i18next'

import Step from '../../../../../../../../features/components/Forms/StepForm/Step'
import FieldsSection from '../../../../../../../../features/components/Form/FieldsSection'
import ImagesSection from './ImagesSection'

const UploadFilesStep = ({ formik }) => {
  const { t } = useTranslation()

  return (
    <Step stepTitle={t('Upload files')}>
      <FieldsSection title="Ad creative upload">
        <ImagesSection formik={formik} />
      </FieldsSection>
    </Step>
  )
}

export default UploadFilesStep
