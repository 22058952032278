import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { clearSlugAiSuggestions, getAiSuggestions } from '../../../../../../modules/actions/tools'
import { SUGGESTIONS_AD_CREATIVE_SLUG } from '../../../../../../constants/tools'

export function useAiSuggestionsData({
  selectedProduct,
  allowLoadSuggestions = true,
  suggestionsSlug = SUGGESTIONS_AD_CREATIVE_SLUG
}) {
  const dispatch = useDispatch()

  const { description = '', name = '' } = selectedProduct

  const getSuggestions = useCallback(() => {
    dispatch(
      getAiSuggestions(suggestionsSlug, {
        slug: suggestionsSlug,
        text: description || name
      })
    )
  }, [dispatch, description, name, suggestionsSlug])

  const regenerateSuggestions = useCallback(() => {
    dispatch(clearSlugAiSuggestions(suggestionsSlug))
    getSuggestions()
  }, [dispatch, getSuggestions, suggestionsSlug])

  useEffect(() => {
    // initial loading
    if (allowLoadSuggestions) {
      getSuggestions()
    }
  }, [dispatch, allowLoadSuggestions, getSuggestions])

  useEffect(() => {
    return () => {
      dispatch(clearSlugAiSuggestions(suggestionsSlug))
    }
  }, [dispatch, suggestionsSlug])

  return useMemo(
    () => ({
      regenerateSuggestions
    }),
    [regenerateSuggestions]
  )
}
