import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import InvoiceInformationSection from './InvoiceInformationSection'

import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'

import { getCurrentStatement } from '../../../../modules/actions/statements'
import {
  currentStatementSelector,
  currentStatementWasLoadedSelector,
  currentStatementIsLoadingSelector
} from '../../../../modules/selectors/statements'

import { INVOICE_INFORMATION_FORM } from '../../../../constants/forms'

function InvoiceInformation({ paramsData = {} }) {
  const dispatch = useDispatch()
  const currentStatement = useSelector(currentStatementSelector)
  const currentStatementIsLoading = useSelector(currentStatementIsLoadingSelector)
  const currentStatementWasLoaded = useSelector(currentStatementWasLoadedSelector)

  const { selectedEditItemId } = useManageFormsDrawer({
    formName: INVOICE_INFORMATION_FORM
  })

  const isFormLoading = !currentStatementWasLoaded || currentStatementIsLoading

  useEffect(() => {
    if (selectedEditItemId) {
      dispatch(getCurrentStatement(selectedEditItemId, paramsData))
    }
  }, [dispatch, selectedEditItemId, paramsData])

  return (
    <FormDrawerWrapper formName={INVOICE_INFORMATION_FORM} isFormLoading={isFormLoading} showOpenButton={false}>
      <InvoiceInformationSection currentStatement={currentStatement} />
    </FormDrawerWrapper>
  )
}

InvoiceInformation.propTypes = {
  paramsData: PropTypes.shape({
    controller: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    status: PropTypes.string,
    date_start_after: PropTypes.string,
    date_start_before: PropTypes.string,
    account: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired
}

export default InvoiceInformation
