import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import Chip from '../../../../../components/Chip'

import { CHIP_COLORS } from '../../../../../constants/other'

import { phonesDownSize } from '../../../../../styles/const/breakpoints'

import useStyles from './styles'

const RequestedFilesField = ({ outstandingRequiredFiles = 0, id, onClick }) => {
  const { t } = useTranslation()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const classes = useStyles()

  const handleOnClick = () => {
    onClick(id)
  }

  return (
    <div className={classes.requestedFilesField}>
      <div className={classes.outStandingFilesWrapper}>
        <Chip
          size="small"
          text={String(outstandingRequiredFiles)}
          className={classes.outstandingFiles}
          color={CHIP_COLORS.redWithWhiteColor}
        />
        <p className={classes.outStandingFilesText}>
          {t(outstandingRequiredFiles === 1 ? 'outstanding file' : 'outstanding files')}
        </p>
      </div>
      {!isMobile && (
        <div className={classes.uploadNow} onClick={handleOnClick}>
          {t('upload now')}
        </div>
      )}
    </div>
  )
}

RequestedFilesField.propTypes = {
  outstandingRequiredFiles: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
}

export default RequestedFilesField
