import React, { useCallback, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import useStyles from './styles'

function List({
  cols,
  listData,
  rowsClassName,
  hideHeadlineColumns,
  SubHeadlineRow,
  headLineRowClassName,
  itemUpdatingId,
  itemDeletingId,
  Skeleton
}) {
  const classes = useStyles(cols.length)

  const hasFooterColumns = useMemo(() => cols.find(col => col.hasOwnProperty('Footer')), [cols])

  const HeadLineColumns = useCallback(() => {
    return cols.map(({ headClassName, Header, style }) => {
      return (
        <div key={uuidv4()} className={classnames(classes.col, headClassName)} style={style}>
          {Header}
        </div>
      )
    })
  }, [cols, classes])

  const Col = useCallback(
    ({ colData, rowData, rowIndex }) => (
      <div className={classnames(classes.col, colData.className)} style={colData.style}>
        {/* return either simple field value or column component */}
        {colData.field ? rowData[colData.field] || '-' : colData.Component(rowData, rowIndex)}
      </div>
    ),
    [classes]
  )

  const DataRows = useCallback(() => {
    return listData.map((rowData, rowIndex) => {
      const isRowUpdating =
        (itemUpdatingId && String(itemUpdatingId) === String(rowData.id)) ||
        (itemDeletingId && String(itemDeletingId) === String(rowData.id))

      return isRowUpdating ? (
        Skeleton
      ) : (
        <li className={classnames(classes.row, rowsClassName, { [classes.firstRow]: rowIndex === 0 })} key={uuidv4()}>
          {cols.map(col => (
            <Col colData={col} rowData={rowData} key={uuidv4()} rowIndex={rowIndex} />
          ))}
        </li>
      )
    })
  }, [Col, classes, listData, cols, itemUpdatingId, itemDeletingId, Skeleton, rowsClassName])

  const FooterColumns = useCallback(() => {
    return cols.map(({ Footer, style }) => {
      return (
        <div key={uuidv4()} className={classnames(classes.col)} style={style}>
          {Footer}
        </div>
      )
    })
  }, [cols, classes])

  return (
    <>
      {/* first row is headline with columns */}
      {!hideHeadlineColumns && (
        <li className={classnames(classes.headlineRow, headLineRowClassName)} key={uuidv4()}>
          <HeadLineColumns />
        </li>
      )}
      {/*In some cases we need to additional row below headline row. E.g. this is used in LineItemsBreakdown component*/}
      {SubHeadlineRow && SubHeadlineRow}
      <DataRows />
      {hasFooterColumns && (
        <li className={classnames(classes.row)} key={uuidv4()}>
          <FooterColumns />
        </li>
      )}
    </>
  )
}

const colsPropTypeShape = {
  Header: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  Footer: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  field: PropTypes.string,
  headClassName: PropTypes.string,
  className: PropTypes.string
}

List.propTypes = {
  listData: PropTypes.array.isRequired,
  cols: PropTypes.arrayOf(PropTypes.shape(colsPropTypeShape)).isRequired,
  hideHeadlineColumns: PropTypes.bool,
  headLineRowClassName: PropTypes.string,
  SubHeadlineRow: PropTypes.node,
  rowsClassName: PropTypes.string,
  itemUpdatingId: PropTypes.string,
  itemDeletingId: PropTypes.string,
  Skeleton: PropTypes.element
}

export default List
