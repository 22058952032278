import useSettingsClasses from '../styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import ContentSection from '../../../features/components/ContentSection'
import ContentRow from '../../../features/components/ContentSection/ContentRow'
import AgencyAccountsTable from './AgencyAccountsTable'
import AgencyCreate from '../../../forms/Multiplatform/AgencyForms/AgencyCreate'
import AgencyEdit from '../../../forms/Multiplatform/AgencyForms/AgencyEdit'

import { useLoadPaginatedList } from '../../../features/hooks/useLoadPaginatedList'

import { clearGetAgencies, getAgencies } from '../../../modules/actions/agencies'
import { agenciesNextSelector } from '../../../modules/selectors/agencies'
import { selectedControllerIdSelector } from '../../../modules/selectors/app'

const AgencyAccounts = () => {
  const settingsClasses = useSettingsClasses()

  const { t } = useTranslation()

  const selectedControllerId = useSelector(selectedControllerIdSelector)
  const next = useSelector(agenciesNextSelector)

  const params = useMemo(() => ({ controller: selectedControllerId, ordering: 'name' }), [selectedControllerId])

  const loadMoreHandler = useLoadPaginatedList({
    params,
    action: getAgencies,
    clearAction: clearGetAgencies,
    next
  })

  return (
    <>
      <h1 className={settingsClasses.title}>{t('Manage your agency accounts')}</h1>
      <ContentSection title={t('Agency Accounts')}>
        <ContentRow
          title={t('Accounts list')}
          description={t('Agency accounts can manage multiple client accounts')}
          leftColumnChildren={<AgencyCreate />}
        >
          <AgencyAccountsTable loadMoreHandler={loadMoreHandler} />
        </ContentRow>
      </ContentSection>
      <AgencyEdit />
    </>
  )
}

export default AgencyAccounts
