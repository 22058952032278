import * as Yup from 'yup'

import { DESCRIPTIONS_LIST, HEADLINES_LIST, LONG_HEADLINES_LIST } from '../../AdForms/fields'
import { LONG_HEADLINE } from '../../AdForms/GoogleDisplayAd/DisplayAdGoogleCreateForm/fields'

export const TEXT_ASSETS = 'text_assets'

export const getDuplicatedTextAssetsValidation = (isAssetGroup = false) => {
  return {
    [TEXT_ASSETS]: Yup.array().test({
      name: 'Duplicated text assets validation',
      test: (value, context) => {
        const headlinesList = context.parent[HEADLINES_LIST]
        const descriptionList = context.parent[DESCRIPTIONS_LIST]

        const longHeadlineList = isAssetGroup
          ? // on asset group form we have long headlines list
            context.parent[LONG_HEADLINES_LIST]
          : // on ad form we have only 1 long headline
            [{ text: context.parent[LONG_HEADLINE], id: 1 }]

        const textAssets = [...headlinesList, ...descriptionList, ...longHeadlineList]

        const hasDuplication = textAssets.some((textAsset, index) => {
          if (!textAsset?.text) {
            // skip empty values
            return false
          }

          return textAssets.some((textAssetItem, textAssetIndex) => {
            return textAsset?.text === textAssetItem?.text && index !== textAssetIndex
          })
        })

        if (hasDuplication) {
          return context.createError({
            message: 'Please do not have duplicate text amongst your headlines, descriptions and long headlines',
            path: TEXT_ASSETS
          })
        }

        return true
      }
    })
  }
}
