import React, { useCallback, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ProgressButton from '../../../../../components/Form/ProgressButton'
import { BookedMediaFiltersContext } from '../BookedMediaFiltersContext'

import { downloadFileByBrowser } from '../../../../../helpers/other'

import {
  clearGetMediaOrdersBookingsAsCsv,
  getMediaOrdersBookingsAsCsv
} from '../../../../../modules/actions/mediaOrdersBookings'
import {
  getMediaOrdersBookingsAsCsvIsLoadingSelector,
  getMediaOrdersBookingsAsCsvSelector,
  getMediaOrdersBookingsAsCsvWasLoadedSelector,
  getMediaOrdersBookingsAsCsvErrorSelector
} from '../../../../../modules/selectors/mediaOrdersBookings'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'

import useStyles from './styles'

const BookedMediaDownloadCSV = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const selfAccountControllerId = useSelector(selectedControllerIdSelector)
  const isLoading = useSelector(getMediaOrdersBookingsAsCsvIsLoadingSelector)
  const error = useSelector(getMediaOrdersBookingsAsCsvErrorSelector)
  const wasLoaded = useSelector(getMediaOrdersBookingsAsCsvWasLoadedSelector)
  const csvData = useSelector(getMediaOrdersBookingsAsCsvSelector)

  const csvUrl = csvData?.download_url

  const { filterRequestParams } = useContext(BookedMediaFiltersContext)

  const clearHandler = useCallback(() => {
    dispatch(clearGetMediaOrdersBookingsAsCsv())
  }, [dispatch])

  const handleOnClick = () => {
    const { date_start, date_end, ...filterParams } = filterRequestParams
    dispatch(
      getMediaOrdersBookingsAsCsv({
        controller: selfAccountControllerId,
        ordering: 'date_start',
        date_start_after: date_start,
        date_start_before: date_end,
        ...filterParams
      })
    )
  }

  const handleDownloadCSVFile = useCallback(() => {
    downloadFileByBrowser({
      url: csvUrl,
      fileName: 'booked_media',
      fileFormat: 'csv'
    })
  }, [csvUrl])

  useEffect(() => {
    // clear data on page close, to cancel download task in case it started
    return clearHandler
  }, [clearHandler])

  return (
    <ProgressButton
      onClick={handleOnClick}
      isFormLoading={isLoading}
      formError={error}
      successSubmit={wasLoaded}
      clearHandler={clearHandler}
      onSuccessSubmit={handleDownloadCSVFile}
      wrapperClassName={classes.exportButtonWrapper}
    >
      {t('Export as CSV')}
    </ProgressButton>
  )
}

export default BookedMediaDownloadCSV
