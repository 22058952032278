import React from 'react'
import { components } from 'react-select'
import PropTypes from 'prop-types'

import Checkbox from '../../Form/Checkbox'

import useStyles from '../../Select/SelectOption/styles'

const SelectOptionCheckbox = props => {
  const classes = useStyles()
  const { data, selectProps } = props
  const { value, label, isDisabled } = data

  const selectedOptions = selectProps.value || []

  const isSelected = selectedOptions.some(option => option.value === value)

  const onClickMultiOption = e => {
    props.selectOption({ ...data })
    // these 2 methods are needed to not close menu when select option
    e.stopPropagation()
    e.preventDefault()
  }

  return (
    <components.Option {...props}>
      <div className={classes.option} onClick={onClickMultiOption}>
        <Checkbox
          key={value}
          id={value}
          title={label}
          checked={isSelected}
          className={classes.label}
          disabled={isDisabled}
        />
      </div>
    </components.Option>
  )
}

SelectOptionCheckbox.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string
  }),
  selectProps: PropTypes.shape({
    value: PropTypes.array
  })
}

export default SelectOptionCheckbox
