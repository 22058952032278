import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import Table from '../../../../../../../../components/Table'

import { brandRevenueReportSelector } from '../../../../../../../../modules/selectors/mediaOrdersBookings'
import { selectedControllerCurrencySymbolSelector } from '../../../../../../../../modules/selectors/app'

import { generateTableColumns } from './helpers'

import useRevenueTablesStyles from '../../../revenueTablesStyles'
import useStyles from './styles'

const BrandCategoryRevenueTable = () => {
  const revenueTablesClasses = useRevenueTablesStyles()
  const classes = useStyles()

  const brandRevenueReport = useSelector(brandRevenueReportSelector)
  const controllerCurrencySymbol = useSelector(selectedControllerCurrencySymbolSelector)

  const formattedBrandRevenueReport = useMemo(() => {
    return brandRevenueReport.map(categoryItem => {
      const columnsObject = {}

      categoryItem.revenue_breakdown.forEach(columnData => {
        columnsObject[columnData.period] = columnData
      })

      return {
        ...categoryItem,
        name: categoryItem.brand_name || 'Uncategorized',
        columns: columnsObject,
        brand_name: categoryItem.brand_name
      }
    })
  }, [brandRevenueReport])

  const cols = useMemo(
    () => [
      {
        fieldKey: 'name',
        headClassName: revenueTablesClasses.revenueCol,
        className: classnames(revenueTablesClasses.revenueCol, revenueTablesClasses.nameCol)
      },
      ...generateTableColumns({
        brandRevenueReport,
        classes,
        revenueTablesClasses,
        controllerCurrencySymbol
      })
    ],
    [classes, revenueTablesClasses, brandRevenueReport, controllerCurrencySymbol]
  )

  const rowTemplate = useMemo(
    () => ({
      rowClassName: revenueTablesClasses.revenueRow,
      headlineRowClassName: revenueTablesClasses.revenueRow
    }),
    [revenueTablesClasses]
  )

  return <Table cols={cols} rowTemplate={rowTemplate} data={formattedBrandRevenueReport} hideFooterRow />
}

export default BrandCategoryRevenueTable
