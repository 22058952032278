import * as Yup from 'yup'
import {
  AUDIENCES_OPTION,
  customAudiencesInitialValues,
  customAudiencesValidation
} from '../../../../ReusableFormFields/CustomAudiencesFields/fields'

import { OPTION_YES } from '../../../../../constants/forms'

export const targetingTypes = {
  TARGETING_TYPE_LANGUAGE: 'TARGETING_TYPE_LANGUAGE',
  TARGETING_TYPE_AGE_RANGE: 'TARGETING_TYPE_AGE_RANGE',
  TARGETING_TYPE_GENDER: 'TARGETING_TYPE_GENDER',
  TARGETING_TYPE_GEO_REGION: 'TARGETING_TYPE_GEO_REGION',
  TARGETING_TYPE_AUDIENCE_GROUP: 'TARGETING_TYPE_AUDIENCE_GROUP'
}

export const initialValues = {
  ...customAudiencesInitialValues,
  [AUDIENCES_OPTION]: OPTION_YES
}

export const validationSchema = Yup.object({
  ...customAudiencesValidation
})
