import * as Yup from 'yup'

export const NAME = 'name'

export const initialValues = {
  [NAME]: ''
}

export const validationSchema = Yup.object({
  [NAME]: Yup.string()
    .required('Please enter brand or company name')
    .max(255, 'Brand or company name should have maximum 255 characters')
})
