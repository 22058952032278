import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import StackedBarGraph from '../../../../../../../../../features/components/Graphs/StackedBarGraph'

import { formatCurrency, formatNumber } from '../../../../../../../../../helpers/numbers'
import { getUnsoldQuantity } from './helpers'

import { selectedControllerDataSelector } from '../../../../../../../../../modules/selectors/app'

const UtilisationGraph = ({ sortedInventoryLimitedByDateRange, formattedLabels, graphColor }) => {
  const selectedSelfAccountController = useSelector(selectedControllerDataSelector)

  const controllerCurrencySymbol = selectedSelfAccountController && selectedSelfAccountController['currency_symbol']

  const soldData = useMemo(
    () => sortedInventoryLimitedByDateRange.map(({ booked_quantity: bookedQuantity }) => bookedQuantity),
    [sortedInventoryLimitedByDateRange]
  )

  const unsoldData = useMemo(
    () =>
      sortedInventoryLimitedByDateRange.map(
        ({ total_quantity, booked_quantity: bookedQuantity }) =>
          // add a fallback - 1 - it allows to represent empty background bar when no items
          getUnsoldQuantity(total_quantity, bookedQuantity) || 1
      ),
    [sortedInventoryLimitedByDateRange]
  )

  const percentageData = useMemo(() => {
    return sortedInventoryLimitedByDateRange.map(item => item['booked_percentage'].toFixed(2))
  }, [sortedInventoryLimitedByDateRange])

  const tooltipCallbackOptions = useMemo(
    () => ({
      label: ({ dataIndex, datasetIndex }) => {
        const currentItem = sortedInventoryLimitedByDateRange[dataIndex]

        if (datasetIndex === 0) {
          const bookedValue = formatCurrency(
            currentItem['booked_value'],
            { min: 2, max: 2 },
            { symbol: controllerCurrencySymbol }
          )

          return `Booked value: ${bookedValue}`
        } else {
          const unsoldValue = formatCurrency(
            currentItem['unbooked_value'],
            { min: 2, max: 2 },
            { symbol: controllerCurrencySymbol }
          )

          return `Unsold value: ${unsoldValue}`
        }
      },
      afterLabel: ({ dataIndex, datasetIndex }) => {
        const currentItem = sortedInventoryLimitedByDateRange[dataIndex]

        if (datasetIndex === 0) {
          const bookedQuantity = formatNumber(currentItem['booked_quantity'])

          return `Booked quantity: ${bookedQuantity}`
        } else {
          const unsoldQuantity = formatNumber(
            getUnsoldQuantity(currentItem['total_quantity'], currentItem['booked_quantity'])
          )

          return `Unsold quantity: ${unsoldQuantity}`
        }
      }
    }),
    [sortedInventoryLimitedByDateRange, controllerCurrencySymbol]
  )

  return (
    <StackedBarGraph
      labels={formattedLabels}
      primaryData={soldData}
      secondaryData={unsoldData}
      percentageData={percentageData}
      tooltipCallbackOptions={tooltipCallbackOptions}
      graphColor={graphColor}
      maxYValue={100}
    />
  )
}

UtilisationGraph.propTypes = {
  DatesSelector: PropTypes.node,
  sortedInventoryLimitedByDateRange: PropTypes.array,
  formattedLabels: PropTypes.array,
  graphColor: PropTypes.string
}

export default UtilisationGraph
