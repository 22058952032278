import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

// Get agencies
export const agenciesSelector = createSelector(
  ({ agencies }) => agencies.agencies.results,
  results => results
)

export const getAgenciesIsLoadingSelector = createSelector(
  ({ agencies }) => agencies.agencies.isLoading,
  isLoading => isLoading
)

export const getAgenciesErrorSelector = createSelector(
  ({ agencies }) => agencies.agencies.error,
  error => error
)

export const agenciesWasLoadedSelector = createSelector(
  ({ agencies }) => agencies.agencies.wasLoaded,
  wasLoaded => wasLoaded
)

export const agenciesNextSelector = createSelector(
  ({ agencies }) => agencies.agencies.paging.next,
  next => next
)

export const createAgencyDataSelector = createSelector(
  ({ agencies }) => agencies.createAgency.data,
  data => data
)

export const createAgencyIsLoadingSelector = createSelector(
  ({ agencies }) => agencies.createAgency.isLoading,
  isLoading => isLoading
)

export const createAgencyErrorSelector = createSelector(
  ({ agencies }) => agencies.createAgency.error,
  error => error
)

export const createAgencyWasCreatedSelector = createSelector(
  ({ agencies }) => agencies.createAgency.wasCreated,
  wasCreated => wasCreated
)

// Update agency
export const updateAgencySelector = createSelector(
  ({ agencies }) => agencies.updateAgency.data,
  data => data
)

export const updateAgencyIsLoadingSelector = createSelector(
  ({ agencies }) => agencies.updateAgency.isLoading,
  isLoading => isLoading
)

export const updateAgencyErrorSelector = createSelector(
  ({ agencies }) => agencies.updateAgency.error,
  error => error
)

export const updateAgencyWasUpdatedSelector = createSelector(
  ({ agencies }) => agencies.updateAgency.wasUpdated,
  wasUpdated => wasUpdated
)

// Get agency
export const agencySelector = createSelector(
  ({ agencies }) => agencies.agency.data,
  data => data
)

export const getAgencyIsLoadingSelector = createSelector(
  ({ agencies }) => agencies.agency.isLoading,
  isLoading => isLoading
)

export const getAgencyErrorSelector = createSelector(
  ({ agencies }) => agencies.agency.error,
  error => error
)

export const agencyWasLoadedSelector = createSelector(
  ({ agencies }) => agencies.agency.wasLoaded,
  wasLoaded => wasLoaded
)
