import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../components/Form'
import InventoryList from './InventoryList'

import { useUpdateInventoryListDataWithNewPortionOfInventory } from './hooks'

import { clearUpdateBatchInventory, updateBatchInventory } from '../../../../../modules/actions/mediaOrdersProducts'
import {
  updateBatchInventoryErrorSelector,
  updateBatchInventoryIsLoadingSelector,
  updateBatchInventoryWasUpdatedSelector,
  inventorySelector,
  inventoryNextSelector
} from '../../../../../modules/selectors/mediaOrdersProducts'

import { INVENTORY_BATCH_UPDATE } from '../../../../../constants/forms'
import { formatInventoryPairsToBE, getInitialValues } from './fields'
import { validationSchema } from './validation'

function InventoryBatchUpdateForm({ selectedMediaProduct = {}, loadMoreHandler }) {
  const dispatch = useDispatch()

  const { id: productId } = selectedMediaProduct

  const batchInventoryWasUpdated = useSelector(updateBatchInventoryWasUpdatedSelector)
  const inventory = useSelector(inventorySelector)
  const next = useSelector(inventoryNextSelector)

  const [isMoreInventoryItemsLoading, setIsMoreInventoryItemsLoading] = useState(false)

  const initialValues = useMemo(() => getInitialValues(inventory), [inventory])

  const onSubmit = useCallback(
    values => {
      const transformedData = formatInventoryPairsToBE({
        originalInventory: inventory,
        values,
        productId
      })

      dispatch(updateBatchInventory(transformedData, productId))
    },
    [dispatch, inventory, productId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  })

  const loadMoreItemsHandler = useCallback(() => {
    loadMoreHandler && loadMoreHandler()
    setIsMoreInventoryItemsLoading(true)
  }, [loadMoreHandler])

  const clearUpdateBatchInventoryHandler = useCallback(() => dispatch(clearUpdateBatchInventory()), [dispatch])

  useUpdateInventoryListDataWithNewPortionOfInventory({
    formik,
    isMoreInventoryItemsLoading,
    setIsMoreInventoryItemsLoading
  })

  return (
    <Form
      // showSubmit={false}
      submitText="Save changes"
      formik={formik}
      formName={INVENTORY_BATCH_UPDATE}
      // processing
      successSubmit={batchInventoryWasUpdated}
      errorSelector={updateBatchInventoryErrorSelector}
      isLoadingSelector={updateBatchInventoryIsLoadingSelector}
      // after form submit
      onSuccessSubmit={clearUpdateBatchInventoryHandler}
      onErrorSubmit={clearUpdateBatchInventoryHandler}
    >
      <InventoryList
        formik={formik}
        period={selectedMediaProduct?.period}
        loadMoreHandler={next ? loadMoreItemsHandler : undefined}
        isMoreInventoryItemsLoading={isMoreInventoryItemsLoading}
      />
    </Form>
  )
}

export default InventoryBatchUpdateForm
