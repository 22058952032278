import React, { useCallback } from 'react'
import { useTheme } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Toggle from '../../../../../components/Toggle'
import ShoptimiseOptimisation from '../../../../../forms/Multiplatform/ShoptimiseOptimisation'

import { updateMediaOrder } from '../../../../../modules/actions/mediaOrders'
import { mediaOrderSelector } from '../../../../../modules/selectors/mediaOrders'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'

import useStyles from './styles'

const CampaignsOptimisationPanel = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  const dispatch = useDispatch()

  const {
    id: mediaOrderId,
    optimization_enabled: isShoptimiseOptimizationEnabled,
    campaigns: mediaOrderCampaigns
  } = useSelector(mediaOrderSelector)
  const controllerId = useSelector(selectedControllerIdSelector)

  const showCheckForOptimisationsButton = mediaOrderCampaigns?.length > 1 && isShoptimiseOptimizationEnabled

  const shoptimiseOptimizationHandler = useCallback(
    isShoptimiseOptimizationEnabled => {
      dispatch(
        updateMediaOrder(mediaOrderId, {
          optimization_enabled: isShoptimiseOptimizationEnabled,
          controller: controllerId
        })
      )
    },
    [controllerId, dispatch, mediaOrderId]
  )

  return (
    <div className={classes.container}>
      <div>
        <h4 className={classes.header}>{t('Automatically optimise off-network campaigns')}</h4>
        <p className={classes.description}>
          {t('The system will automatically shift budget from the lowest to the highest performing campaigns')}
        </p>
      </div>
      <div className={classes.rightSide}>
        {showCheckForOptimisationsButton && <ShoptimiseOptimisation />}
        <Toggle
          isInitiallyEnabled={isShoptimiseOptimizationEnabled}
          toggleColor={theme.brandPrimary}
          onToggle={shoptimiseOptimizationHandler}
        />
      </div>
    </div>
  )
}

export default CampaignsOptimisationPanel
