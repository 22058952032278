import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useStyles from './styles'

const FieldRow = ({ children, title, description, footer, footerClassName, leftSideClassName, rightSideClassName }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const renderDescription = useCallback(() => {
    if (typeof description === 'string') {
      return <p className={classes.description}>{t(description)}</p>
    } else {
      return <div className={classes.descriptionBlock}>{description}</div>
    }
  }, [description, classes, t])

  return (
    <div className={classes.fieldRow}>
      <div className={classes.main}>
        <div className={classnames(classes.leftSide, leftSideClassName)}>
          {title && <h4 className={classes.title}>{t(title)}</h4>}
          {description && renderDescription()}
        </div>
        <div className={classnames(classes.rightSide, rightSideClassName)}>{children}</div>
      </div>
      {footer && <div className={classnames(classes.footer, footerClassName)}>{footer}</div>}
    </div>
  )
}

FieldRow.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  footerClassName: PropTypes.string,
  leftSideClassName: PropTypes.string,
  rightSideClassName: PropTypes.string
}

export default FieldRow
