import {
  formatImagesToBE,
  formatLocationTabsToBE,
  formatTagsToBE,
  formatVariablesToBE
} from '../../MediaProductCreate/MediaProductCreateForm/formatters'
import { formatCategoriesToBE } from '../../formatters'

import { DESCRIPTION, INTERNAL_ID, NAME, PERIOD_OPTION } from '../../MediaProductCreate/MediaProductCreateForm/fields'
import { SUB_PRODUCTS, VARIABLES } from '../../fields'
import { CURRENCY } from './fields'

export const transformValuesToBE = values => {
  return {
    [NAME]: values[NAME],
    ...(values[DESCRIPTION] && { [DESCRIPTION]: values[DESCRIPTION] }),
    ...formatCategoriesToBE(values),
    period: values[PERIOD_OPTION],
    ...formatImagesToBE(values),
    ...formatLocationTabsToBE(values),
    ...formatTagsToBE(values),
    ...(values[VARIABLES].length && formatVariablesToBE(values)),
    ...(values[INTERNAL_ID] && { [INTERNAL_ID]: values[INTERNAL_ID] }),
    [SUB_PRODUCTS]: values[SUB_PRODUCTS].map(product => product.value),
    // for product group to be determined under some currency we need to have at least one price
    // so we hardcode price to 1, but provide the currency
    prices: [
      {
        [CURRENCY]: values[CURRENCY],
        price: 1
      }
    ]
  }
}
