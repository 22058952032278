import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import FieldsSection from '../../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'
import CustomAudienceFields from './CustomAudienceFields'
import AgesFields from './AgesFields'
import RadioButton from '../../../../../../../components/Form/RadioButton'
import InterestsField from './InterestsField'

import { selectedControllerDataSelector } from '../../../../../../../modules/selectors/app'

import {
  GENDER_OPTION,
  GENDER_OPTION_ALL,
  GENDER_OPTION_FEMALES,
  GENDER_OPTION_MALES
} from '../../../../../../ReusableFormFields/GenderFields/fields'
import { CUSTOM_AUDIENCES_EXCLUSION } from '../../../../../../ReusableFormFields/CustomAudiencesFields/fields'

const stepFields = [CUSTOM_AUDIENCES_EXCLUSION]

const AudienceStep = ({ formik, handleStepChange }) => {
  const { t } = useTranslation()

  const { values, setFieldValue } = formik

  const selectedSelfAccountController = useSelector(selectedControllerDataSelector)

  const controllerName = selectedSelfAccountController?.name || ''

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  return (
    <Step
      stepTitle="Select your target audience"
      stepDescription={t('targetBasedOnAudiences', { controllerName: controllerName })}
      handleContinue={handleContinue}
    >
      <FieldsSection title="Audience">
        <FieldRow
          title={t('audiencesOptional', { controllerName: controllerName })}
          description={t('targetAudiencesDescription', { controllerName: controllerName })}
        >
          <CustomAudienceFields formik={formik} />
        </FieldRow>
        <FieldRow title="Age" description="Select a target age range">
          <AgesFields formik={formik} />
        </FieldRow>
        <FieldRow title="Gender" description="Select a target gender">
          <RadioButton
            id={'radio_gender_any'}
            name={GENDER_OPTION}
            value={GENDER_OPTION_ALL}
            selectedValue={values[GENDER_OPTION]}
            label={'Any'}
            setFieldValue={setFieldValue}
          />
          <RadioButton
            id="radio_gender_male"
            name={GENDER_OPTION}
            value={GENDER_OPTION_MALES}
            selectedValue={values[GENDER_OPTION]}
            label="Males only"
            setFieldValue={setFieldValue}
          />
          <RadioButton
            id="radio_gender_female"
            name={GENDER_OPTION}
            value={GENDER_OPTION_FEMALES}
            selectedValue={values[GENDER_OPTION]}
            label="Females only"
            setFieldValue={setFieldValue}
          />
        </FieldRow>
        <FieldRow
          title="Interests (optional)"
          description="Search for an interest to limit your audience to these specific interests"
        >
          <InterestsField formik={formik} />
        </FieldRow>
      </FieldsSection>
    </Step>
  )
}

export default AudienceStep
