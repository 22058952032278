import { FEE, FEE_OPTION, getMarginWithFee } from '../../../../../../forms/ReusableFormFields/MarginFields/fields'
import {
  SPECIFIC_CAMPAIGN,
  SPECIFIC_CAMPAIGN_MARGIN,
  SPECIFIC_CAMPAIGN_PLATFORM,
  SPECIFIC_MARGIN_OPTION
} from '../../fields'

import { OPTION_YES } from '../../../../../../constants/forms'

export const transformValuesToBE = (values, selfAccount) => {
  const commonValues = {
    account: selfAccount,
    provider: values[SPECIFIC_CAMPAIGN_PLATFORM],
    campaign_id: values[SPECIFIC_CAMPAIGN]
  }

  // if FEE should be added then calculation through getMarginWithFee should be done
  const includeFee = values[FEE_OPTION] === OPTION_YES
  // convert margins to decimal when dealing with BE i.e. (70% becomes 0.7)
  const fee = parseFloat(values[FEE]) * 0.01

  if (values[SPECIFIC_MARGIN_OPTION] === OPTION_YES) {
    const margin = values[SPECIFIC_CAMPAIGN_MARGIN] * 0.01
    return {
      ...commonValues,
      margin: includeFee ? getMarginWithFee(values[SPECIFIC_CAMPAIGN_MARGIN] * 0.01, fee) : margin
    }
  } else {
    const margin = 0
    return {
      ...commonValues,
      margin: includeFee ? getMarginWithFee(margin, fee) : margin
    }
  }
}
