import * as Yup from 'yup'

import { productOrPagesValidation } from '../../../../../../ReusableFormFields/ProductsAndPagesFields/fields'
import { DESCRIPTIONS_LIST, FILE_URL, HEADLINES_LIST, PRIMARY_TEXT_LIST, SINGLE_MEDIA_ITEM } from '../../../../fields'
import { descriptionValidation, headlineValidation, primaryTextValidation } from '../../validation'

export const validationSchema = Yup.object({
  [PRIMARY_TEXT_LIST]: primaryTextValidation,
  [SINGLE_MEDIA_ITEM]: Yup.object().shape({
    [FILE_URL]: Yup.string().required('Please select Image or Video file'),
    [HEADLINES_LIST]: Yup.array().of(Yup.object({ text: headlineValidation })),
    [DESCRIPTIONS_LIST]: Yup.array().of(Yup.object({ text: descriptionValidation }))
  }),
  // CATALOGUE PRODUCT
  ...productOrPagesValidation
})
