import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ContentRow from '../../../../features/components/ContentSection/ContentRow'
import DiscountPresetCreate from '../../../../forms/Multiplatform/DiscountsForms/DiscountPresetForms/DiscountPresetCreate'
import DiscountPresetsTable from './DiscountPresetsTable'
import DiscountPresetEdit from '../../../../forms/Multiplatform/DiscountsForms/DiscountPresetForms/DiscountPresetEdit'

import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import { clearGetDiscountPresets, getDiscountPresets } from '../../../../modules/actions/discounts'

const DiscountPresets = () => {
  const dispatch = useDispatch()

  const controllerId = useSelector(selectedControllerIdSelector)

  useEffect(() => {
    if (controllerId) {
      dispatch(getDiscountPresets({ controller: controllerId }))
    }
  }, [dispatch, controllerId])

  useEffect(
    () => () => {
      dispatch(clearGetDiscountPresets())
    },
    [dispatch]
  )

  return (
    <>
      <ContentRow
        leftColumnChildren={<DiscountPresetCreate />}
        title="Discount presets"
        description="These discounts apply across products based on quantity and number of periods booked in one order."
      >
        <DiscountPresetsTable />
      </ContentRow>
      <DiscountPresetEdit />
    </>
  )
}

export default DiscountPresets
