import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getFacebookImageUrls } from '../../../../modules/actions/mediaFiles'
import {
  facebookImageUrlsWasLoadedSelector,
  facebookRequestedImageHashesSelector
} from '../../../../modules/selectors/mediaFiles'

import { selectedFacebookAdAccountIdSelector } from '../../../../modules/selectors/app'
import {
  getMissingImageHashes,
  isAllImageUrlsWasRequested
} from '../../../../forms/Facebook/AdForms/AdFacebookEdit/helpers/mediaFileHelpers'

export function useFacebookMediaLoading({ shouldLoadMedia = true, adAccountId, imageHashes }) {
  const dispatch = useDispatch()

  const requestedImageHashes = useSelector(facebookRequestedImageHashesSelector)

  const imageUrlsWasLoaded = useSelector(facebookImageUrlsWasLoadedSelector)
  const selectedAdAccountId = useSelector(selectedFacebookAdAccountIdSelector)

  const providedAdAccountId = adAccountId || selectedAdAccountId

  const initialMediaIsLoading = useMemo(
    () => !!imageHashes.length && !imageUrlsWasLoaded,
    [imageHashes, imageUrlsWasLoaded]
  )

  useEffect(() => {
    if (shouldLoadMedia && !imageUrlsWasLoaded && imageHashes.length) {
      dispatch(getFacebookImageUrls({ account: providedAdAccountId, hashes: imageHashes }))
    }
  }, [dispatch, shouldLoadMedia, imageHashes, providedAdAccountId, imageUrlsWasLoaded])

  // these useEffects were created for ad create/edit form, when new images/videos can be added
  // load more images, if missing hashes appeared - this helps to avoid all images or videos re-fetching
  useEffect(() => {
    if (
      imageUrlsWasLoaded &&
      !isAllImageUrlsWasRequested({ hashes: imageHashes, requestedHashes: requestedImageHashes })
    ) {
      dispatch(
        getFacebookImageUrls({
          account: providedAdAccountId,
          hashes: getMissingImageHashes({ hashes: imageHashes, requestedHashes: requestedImageHashes })
        })
      )
    }
  }, [dispatch, imageHashes, requestedImageHashes, imageUrlsWasLoaded, providedAdAccountId])

  return { initialMediaIsLoading }
}
