import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import TableRows from './TableRows'
import TableHeader from './TableHeader'
import TableFooter from './TableFooter'
import ConditionalWrapper from '../hoc/ConditionalWrapper'
import DragAndDropWrapper from '../../features/components/DragAndDropWrapper'

import { TABLE_CLASSNAME } from '../../constants/pdf'
import { SKIP_CELL_FOR_COPY } from './constants'

import { phonesDownSize } from '../../styles/const/breakpoints'

import useStyles from './styles'

const Table = React.memo(
  ({
    cols,
    rowTemplate,
    data,
    tableSort,
    hideHeadlineRow,
    hideFooterRow,
    SubHeadlineRow,
    itemUpdatingId,
    footerClassName,
    Footer,
    isBigRow,
    onRowDrag,
    customColumns,
    tableId,
    className,
    mobileColumnsWrapperClassName,
    mobileMinimisedSectionBtnWrapperClassName
  }) => {
    const colsTemplate = cols || rowTemplate.cols
    const isMobile = useMediaQuery({ maxWidth: phonesDownSize })
    const hasMobileHiddenColumns = useMemo(() => {
      return isMobile && colsTemplate.some(({ showOnMobile }) => showOnMobile === false)
    }, [colsTemplate, isMobile])

    const isSupportExpandableRow = rowTemplate?.params?.isExpandable
    const hasExpandableRow = useMemo(() => {
      // check through all data rows if there is at least one expandable row when the row params support it
      if (isSupportExpandableRow) {
        return data.some(rowData => isSupportExpandableRow(rowData))
      } else {
        return false
      }
    }, [data, isSupportExpandableRow])
    const classes = useStyles({ isBigRow, isMobile, hasMobileHiddenColumns })

    const showHeadline = !hideHeadlineRow && !isMobile

    return (
      <div className={classnames(classes[TABLE_CLASSNAME], className)} id={tableId}>
        {/* first row is headline with columns */}
        {showHeadline && (
          <TableHeader
            cols={cols}
            rowTemplate={rowTemplate}
            tableSort={tableSort}
            isBigRow={isBigRow}
            hasMobileHiddenColumns={hasMobileHiddenColumns}
            hasExpandableRow={hasExpandableRow}
          />
        )}
        {/*In some cases we need to additional row below headline row. E.g. this is used in LineItemsBreakdown component*/}
        {SubHeadlineRow && SubHeadlineRow}
        <ConditionalWrapper
          condition={!!onRowDrag}
          wrapper={children => (
            <DragAndDropWrapper onDragEnd={onRowDrag} droppableId={tableId}>
              {children}
            </DragAndDropWrapper>
          )}
        >
          <TableRows
            colsTemplate={colsTemplate}
            rowTemplate={rowTemplate}
            data={data}
            itemUpdatingId={itemUpdatingId}
            isBigRow={isBigRow}
            onRowDrag={onRowDrag}
            customColumns={customColumns}
            hasMobileHiddenColumns={hasMobileHiddenColumns}
            mobileColumnsWrapperClassName={mobileColumnsWrapperClassName}
            mobileMinimisedSectionBtnWrapperClassName={mobileMinimisedSectionBtnWrapperClassName}
            hasExpandRowSpacing={hasExpandableRow}
          />
        </ConditionalWrapper>
        {!hideFooterRow && (
          <TableFooter
            cols={colsTemplate}
            isBigRow={isBigRow}
            Footer={Footer}
            // when we have drag and drop functionality we need to show top border for footer
            // that is because last row will not have border
            showTopBorder={Boolean(onRowDrag)}
            footerClassName={footerClassName}
          />
        )}
      </div>
    )
  }
)

const colsPropTypeShape = {
  showOnMobile: PropTypes.bool,
  attributes: PropTypes.shape({
    // allow to add HTML elements attributes to cell
    [SKIP_CELL_FOR_COPY]: PropTypes.bool // allow to skip cell for copy
  }),
  // header
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]),
  headClassName: PropTypes.string,
  // for sorting both are needed:
  sortParameter: PropTypes.string,
  onSortingChange: PropTypes.func,
  sortDescFirst: PropTypes.bool, //sort for column in descending order first (default is ascending)
  // main cell
  Cell: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  fieldKey: PropTypes.string,
  className: PropTypes.string,
  // footer
  footerClassName: PropTypes.string
}
const colsOrRowTemplateRequired = (props, propName, componentName) => {
  if (!props.cols && !props.rowTemplate) {
    return new Error(`One of 'cols' or 'rowTemplate' is required in '${componentName}'.`)
  }
  return null
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  cols: PropTypes.arrayOf(PropTypes.shape(colsPropTypeShape)),
  tableSort: PropTypes.object,
  rowTemplate: PropTypes.shape({
    cols: PropTypes.arrayOf(PropTypes.shape(colsPropTypeShape)),
    params: PropTypes.shape({
      isExpandable: PropTypes.func,
      expandedContent: PropTypes.func
    }),
    markerColor: PropTypes.string,
    determineRowMarker: PropTypes.func,
    rowClassName: PropTypes.string,
    headlineRowClassName: PropTypes.string,
    getRowClassName: PropTypes.func,
    getMobileRowClassName: PropTypes.func,
    columnClassName: PropTypes.string
  }),
  hideHeadlineRow: PropTypes.bool,
  hideFooterRow: PropTypes.bool,
  SubHeadlineRow: PropTypes.node,
  // is an object of available custom columns layouts
  // allows to render row with custom columns if rowData has key of customColumns
  customColumns: PropTypes.object,
  tableId: PropTypes.string,
  // updating, deleting and etc..
  onRowDrag: PropTypes.func, // allows to change rows order - adds drag and drop functionality
  itemUpdatingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isBigRow: PropTypes.bool,
  footerClassName: PropTypes.string,
  Footer: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  mobileColumnsWrapperClassName: PropTypes.string,
  mobileMinimisedSectionBtnWrapperClassName: PropTypes.string,
  colsOrRowTemplateRequired
}

export default Table
