import React from 'react'
import PropTypes from 'prop-types'

import AdStoriesItem from '../AdStoriesItem'

import { STORIES_ITEMS } from '../../../../../forms/Facebook/AdForms/fields'

import useStyles from './styles'

const AdStoriesCarousel = ({
  viewAdOnFacebook,
  formikValues,
  elementInProgress,
  fileUrl,
  handleLeftSideClick,
  handleRightSideClick
}) => {
  const classes = useStyles()

  const storiesItems = formikValues[STORIES_ITEMS]

  return (
    <div className={classes.adStoriesCarousel}>
      {storiesItems.map((item, index) => (
        <AdStoriesItem
          viewAdOnFacebook={viewAdOnFacebook}
          key={index}
          formikValues={formikValues}
          elementInProgress={elementInProgress}
          fileUrl={fileUrl}
          index={index}
          handleLeftSideClick={handleLeftSideClick}
          handleRightSideClick={handleRightSideClick}
        />
      ))}
    </div>
  )
}

AdStoriesCarousel.propTypes = {
  viewAdOnFacebook: PropTypes.string,
  formikValues: PropTypes.object,
  elementInProgress: PropTypes.string,
  fileUrl: PropTypes.string,
  handleLeftSideClick: PropTypes.func,
  handleRightSideClick: PropTypes.func
}

export default AdStoriesCarousel
