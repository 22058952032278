import { useMemo } from 'react'

import { formatOptionsList } from '../../../../../../features/formatters'

export default function useFormattedFacebookPages(facebookPages) {
  return useMemo(
    () =>
      formatOptionsList({
        list: facebookPages.filter(({ tasks }) => tasks.includes('advertise')),
        valueName: 'id',
        labelName: 'name'
      }),
    [facebookPages]
  )
}
