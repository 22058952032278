import { createStyles } from '../../../../styles/helpers'
import { lighterGrey, mediumGrey } from '../../../../styles/const/colors'

const useStyles = createStyles({
  filesSection: {
    borderRadius: 8,
    padding: 24
  },
  filesSectionToggle: {
    top: ['50%', '!important'],
    transform: 'translateY(-50%)'
  },
  product: {
    '&:not(:first-of-type)': {
      marginTop: 32,
      borderTop: `1px solid ${mediumGrey}`,
      paddingTop: 32
    }
  },
  productHeadline: {
    marginTop: 25
  },
  productBookedMedia: {
    marginTop: 15,
    paddingLeft: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 12
  },
  mediaTitle: {
    fontSize: 14
  },
  filesCardsGrid: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    gap: 8,
    marginTop: 15
  },
  fileDetails: {
    padding: 8,
    backgroundColor: lighterGrey
  },
  statusChip: {
    marginTop: 8
  }
})

export default useStyles
