import {
  DECREASE_SELECT_OPTION,
  END_DATE,
  INCREASE,
  INCREASE_SELECT_OPTION,
  LOCATIONS,
  MEDIA_CATEGORY,
  PRICE_CHANGE,
  START_DATE
} from '../../fields'
import { calc } from '../../../../../../helpers/numbers'

export const getInitialValues = editPriceChangePeriodData => {
  const formattedLocations = editPriceChangePeriodData[LOCATIONS]?.map(location => ({
    value: location.id,
    label: location.name
  }))

  return {
    [START_DATE]: new Date(editPriceChangePeriodData[START_DATE] || ''),
    [END_DATE]: new Date(editPriceChangePeriodData[END_DATE] || ''),
    [MEDIA_CATEGORY]: editPriceChangePeriodData[MEDIA_CATEGORY] || '',
    // price_change is managed in 0-1 range on BE, but we need to display it in 0-100 range
    // convert to string as our input fields manipulate with strings
    [PRICE_CHANGE]: calc(editPriceChangePeriodData[PRICE_CHANGE]).mul(100).toString() || '',
    // BE has boolean, FE manipulates with select options
    [INCREASE]: editPriceChangePeriodData[INCREASE] ? INCREASE_SELECT_OPTION : DECREASE_SELECT_OPTION,
    [LOCATIONS]: formattedLocations || []
  }
}
