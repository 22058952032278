import { createStyles } from '../../styles/helpers'

import { darkGrey, mediumGrey } from '../../styles/const/colors'

export const counterBtn = {
  fontSize: '12px',
  color: darkGrey,

  '& svg': {
    width: '12px',
    height: '12px',
    color: darkGrey,

    '& path': {
      fill: darkGrey
    }
  },
  '&:hover svg': {
    color: 'black',

    '& path': {
      fill: ['black', '!important']
    }
  }
}

export const counterBorder = {
  border: `1px solid ${mediumGrey}`,
  borderRadius: '50px',
  color: darkGrey
}
export default createStyles({
  counter: {
    extend: counterBorder,
    display: 'inline-flex',
    width: '70px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  counterValue: {
    padding: '0 3px'
  },
  btnIncrease: {
    extend: counterBtn,
    // leave space near the counter number not clickable
    padding: '6px 6px 6px 0',
    opacity: ({ hideIncreaseButton }) => hideIncreaseButton && 0,
    pointerEvents: ({ hideIncreaseButton }) => hideIncreaseButton && 'none'
  },
  btnDecrease: {
    extend: counterBtn,
    // leave space near the counter number not clickable
    padding: '6px 0 6px 6px',
    opacity: ({ hideDecreaseButton }) => hideDecreaseButton && 0,
    pointerEvents: ({ hideDecreaseButton }) => hideDecreaseButton && 'none'
  }
})
