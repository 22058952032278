import { EncryptJWT, base64url } from 'jose'

export async function encryptJWE(passwordField = {}) {
  // passwordField is an object, that is required by EncryptJWT
  try {
    const secret = base64url.decode(process.env.REACT_APP_JWT_SECRET)

    // returns a JWE Compact Serialization
    return await new EncryptJWT(passwordField).setProtectedHeader({ alg: 'dir', enc: 'A128CBC-HS256' }).encrypt(secret)
  } catch (error) {
    console.error('Error encrypting password:', error)
    throw error // re-throw the error if you want it to propagate
  }
}

// async function decryptJWE(encryptedJWE) {
//   // Assuming secretKey is the same Uint8Array(32) used for encryption
//   const secretKey = base64url.decode(process.env.REACT_APP_JWT_SECRET)
//   const { plaintext } = await compactDecrypt(encryptedJWE, secretKey);
//
//   // decrypted data
//   return new TextDecoder().decode(plaintext); // Convert Uint8Array to a string
// }
