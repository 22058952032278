import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { addMonths, lastDayOfMonth, startOfMonth } from 'date-fns'

import InvoicesTable from './InvoicesTable'
import InvoicingFilters from './InvoicesFilters'
import InvoiceInformation from '../../../forms/Multiplatform/InvoiceForms/InvoiceInformation'

import { getStatementsSelector } from '../../../modules/selectors/statements'
import { userSelfAccountTypeSelector, selectedSelfAccountSelector } from '../../../modules/selectors/app'
import { getStatements, clearGetStatements } from '../../../modules/actions/statements'

import { formatDateToBE } from '../../../constants/dates'

import { CONTROLLER_TYPE } from '../../../constants/permissions'

import useSettingsClasses from '../styles'

const Invoicing = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const selectedSelfAccountId = useSelector(selectedSelfAccountSelector)

  const [dateRangeFilter, setDateRangeFilter] = useState({
    startDate: startOfMonth(addMonths(new Date(), -6)),
    endDate: lastDayOfMonth(addMonths(new Date(), -1))
  })

  const [statusFilterOption, setStatusFilterOption] = useState(undefined)
  const [accountFilterOption, setAccountFilterOption] = useState(selectedSelfAccountId)

  const settingsClasses = useSettingsClasses()

  const invoices = useSelector(getStatementsSelector)
  const userAccountType = useSelector(userSelfAccountTypeSelector)
  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)

  const isNotController = userAccountType !== CONTROLLER_TYPE

  const paramsData = useMemo(
    () => ({
      date_start_after: formatDateToBE(dateRangeFilter.startDate),
      date_start_before: formatDateToBE(dateRangeFilter.endDate),
      ...(statusFilterOption && { status: statusFilterOption }),
      ...(accountFilterOption && { account: accountFilterOption }),
      ...(isNotController && { account: selectedSelfAccount })
    }),
    [statusFilterOption, accountFilterOption, dateRangeFilter, selectedSelfAccount, isNotController]
  )

  useEffect(() => {
    dispatch(getStatements(paramsData))
    return () => {
      dispatch(clearGetStatements())
    }
  }, [dispatch, paramsData])

  return (
    <>
      <h1 className={settingsClasses.title}>{t('Billing')}</h1>
      <InvoicingFilters
        dateRangeFilter={dateRangeFilter}
        setAccountFilterOption={setAccountFilterOption}
        setStatusFilterOption={setStatusFilterOption}
        setDateRangeFilter={setDateRangeFilter}
        accountFilterOption={accountFilterOption}
        statusFilterOption={statusFilterOption}
        paramsData={paramsData}
        hideAccountFilter={isNotController}
      />
      <InvoicesTable invoices={invoices} />

      <InvoiceInformation paramsData={paramsData} />
    </>
  )
}

export default Invoicing
