import { REPRESENTATIVE } from '../../../../../../../constants/mediaOrders'
import { CAN_SELECT_MULTIPLE } from '../../../../../MediaProductVariableForms/fields'
import { VARIABLES } from '../../../../../MediaProductForms/fields'
import { MULTIPLE_VARIABLE_OPTIONS, PRODUCT_PERIODS_DATES, QUANTITY, SINGLE_VARIABLE_OPTION } from '../../../../fields'

export const formatVariablesToBE = variables => {
  // BE expect structure like this:
  // [
  //   {
  //     "name": "Color",
  //     "value": ["red", "green", "blue""]
  //   }
  // ]
  return (
    variables
      // filter out only  variables with selected values
      .filter(item => !!item[SINGLE_VARIABLE_OPTION] || !!item[MULTIPLE_VARIABLE_OPTIONS]?.length)
      .map(variable => {
        if (variable[CAN_SELECT_MULTIPLE]) {
          return {
            name: variable.name,
            value: variable[MULTIPLE_VARIABLE_OPTIONS].map(option => option.value)
          }
        } else {
          return {
            name: variable.name,
            value: [variable[SINGLE_VARIABLE_OPTION]]
          }
        }
      })
  )
}

export const getFormattedProducts = selectedProducts => {
  return selectedProducts.map(productValues => {
    const productData = productValues.data
    const selectedPeriods = productValues[PRODUCT_PERIODS_DATES]

    const periodsDatesPrice = selectedPeriods.map(({ inventory, price, value }) => ({
      inventory,
      date_start: value,
      price
    }))

    return {
      media_product: productData.id,
      media_package: productValues.media_package,
      discount: productValues.discount || 0,
      original_product_name: productData.name,
      periods_price: periodsDatesPrice,
      [QUANTITY]: productValues[QUANTITY],
      [REPRESENTATIVE]: productValues[REPRESENTATIVE],
      [VARIABLES]: formatVariablesToBE(productValues[VARIABLES])
    }
  })
}
