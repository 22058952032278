import { createStyles } from '../../../../../../../styles/helpers'

import { textGrey, borderDarkGrey } from '../../../../../../../styles/const/colors'
import { textEllipsis } from '../../../../../../../styles/mixins/text'

export default createStyles(theme => ({
  nameField: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  thumbnail: {
    marginRight: 24,
    minHeight: 40,
    minWidth: 40
  },
  nameFieldTexts: {
    display: 'flex',
    flexDirection: 'column',
    color: textGrey,
    fontWeight: 400,
    fontSize: 16,
    minWidth: 0
  },
  nameFieldTitle: {
    ...textEllipsis(),
    cursor: 'pointer'
  },
  previewContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexGrow: 1
  },
  filePreview: {
    width: 40,
    height: 40,
    objectFit: 'contain',
    cursor: 'pointer'
  },
  formats: {
    display: 'flex',
    marginTop: 5,
    alignItems: 'center',
    fontSize: 12,
    color: borderDarkGrey,
    ...textEllipsis()
  },
  formatField: {
    borderColor: borderDarkGrey,
    border: '1px solid',
    display: 'flex',
    borderRadius: theme.defaultBorderRadius,
    alignItems: 'center',
    padding: '1px 4px 3px',
    fontWeight: 400,
    width: 'max-content',
    marginRight: 5,
    ...textEllipsis(),
    minWidth: 0
  },
  moreHiddenNumberOfFormats: {
    ...textEllipsis(),
    minWidth: 0
  },
  icon: {
    minWidth: 40,
    minHeight: 40,
    cursor: 'pointer',
    // is needed for better position file upload icon in the table
    top: '-4px !important'
  },
  fileFormat: {
    // is needed to move file format out of the file icon
    bottom: '-2px !important'
  }
}))
