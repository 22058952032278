import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import DisplayAdGoogleCreateForm from '../DisplayAdGoogleCreateForm'
import BrandsMissingWarning from '../../../ReusableFormFields/BrandsMissingWarning'
import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'

import { useFormProgressItems } from './useFormProgressItems'

import { AD_GOOGLE_CREATE } from '../../../../../constants/forms'
import { selectedSelfAccountDataSelector } from '../../../../../modules/selectors/app'

// If Google Ads campaign type = display (identified by AdvertisingChannelType = 3 ) then use regular ad create:
const AdGoogleCreate = ({ lineItemId, campaignId }) => {
  const { t } = useTranslation()

  const { brands } = useSelector(selectedSelfAccountDataSelector)
  const hasBrands = brands?.length > 0

  const formProgressItems = useFormProgressItems()

  return (
    <FormDrawerWrapper
      openButtonText={t('Create Ad')}
      formName={AD_GOOGLE_CREATE}
      hasDefaultDrawerContentSpacing={false}
      isWideDrawer
    >
      {hasBrands ? (
        <DisplayAdGoogleCreateForm lineItemId={lineItemId} campaignId={campaignId} formProps={{ formProgressItems }} />
      ) : (
        <BrandsMissingWarning hasDrawerPadding />
      )}
    </FormDrawerWrapper>
  )
}

export default AdGoogleCreate
