import { createStyles } from '../../../../../../styles/helpers'

import { black } from '../../../../../../styles/const/colors'

const useStyles = createStyles({
  details: {
    marginTop: [0, '!important'],
    color: [black, '!important']
  },
  historyItem: {
    marginBottom: 16
  }
})

export default useStyles
