import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

export const fileUploadProgressSelector = createSelector(
  ({ files }) => files.fileUploadProgress,
  fileUploadProgress => fileUploadProgress
)

export const uploadedFileSelector = createSelector(
  ({ files }) => files.uploadedFile,
  uploadedFile => uploadedFile
)

export const fileUploadErrorSelector = createSelector(
  ({ files }) => files.fileUploadError,
  fileUploadError => fileUploadError
)

export const fileAccessibleURLSelector = createSelector(
  ({ files }) => files.accessibleURL,
  accessibleURL => accessibleURL
)
