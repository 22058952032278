import { v4 as uuidv4 } from 'uuid'

import {
  AVAILABILITY_DATE_END,
  AVAILABILITY_DATE_START,
  CATEGORY,
  DATE_END,
  DATE_START,
  MEDIA_CATEGORIES,
  MEDIA_PRODUCTS,
  NAME,
  SUBTEXT,
  TAGS,
  ACCESS_ACCOUNTS,
  categoriesWithAllowedPreselectedLocationFilter
} from '../../fields'
import { mediaCategories } from '../../../../../constants/mediaOrders'
import {
  PRICE_CURRENCY,
  PRICE_PAIRS,
  PRICE_VALUE,
  pricePairInitialValue
} from '../../../../ReusableFormFields/PricePairFields/fields'
import { PRODUCTS_FILTER } from '../../MediaPackageCreate/MediaPackageCreateForm/fields'

import { LOCATION } from '../../../MediaProductForms/fields'
import { FILE_NAME, FILE_URL } from '../../../../../constants/files'

const getAccessAccountsValue = (id, accountsOptions) => {
  // check that controllerMembers were loaded
  if (accountsOptions?.length) {
    return accountsOptions.find(account => account.id === id) || { value: id, id }
  }

  return { id, value: id }
}

const getProductsFilterLocation = editPackageData => {
  for (const category of categoriesWithAllowedPreselectedLocationFilter) {
    const location = editPackageData?.[PRODUCTS_FILTER]?.[category]?.[LOCATION]
    if (location) {
      return location
    }
  }
  return ''
}

export const getInitialValues = ({ editPackageData = {}, isPackageWithMediaCategories, accountsOptions, tags }) => {
  const {
    [AVAILABILITY_DATE_START]: availabilityDateStart,
    [AVAILABILITY_DATE_END]: availabilityDateEnd,
    [DATE_START]: dateStart,
    [DATE_END]: dateEnd
  } = editPackageData

  const formattedInitialMediaCategories = editPackageData[MEDIA_CATEGORIES]
    ? mediaCategories.filter(category => editPackageData[MEDIA_CATEGORIES].includes(category.value))
    : []

  const formattedInitialMediaProducts = editPackageData[MEDIA_PRODUCTS]
    ? editPackageData[MEDIA_PRODUCTS].map(product => ({
        value: product.id,
        label: product.name
      }))
    : []

  return {
    [NAME]: editPackageData[NAME] || '',
    [CATEGORY]: editPackageData[CATEGORY].id || '',
    [AVAILABILITY_DATE_START]: (availabilityDateStart && new Date(availabilityDateStart)) || '',
    [AVAILABILITY_DATE_END]: (availabilityDateEnd && new Date(availabilityDateEnd)) || '',
    [DATE_START]: (dateStart && new Date(dateStart)) || '',
    [DATE_END]: (dateEnd && new Date(dateEnd)) || '',
    [SUBTEXT]: editPackageData[SUBTEXT] || '',
    [FILE_URL]: editPackageData.image || '',
    // editPackageData.image is needed for old packages without file_name
    [FILE_NAME]: editPackageData[FILE_NAME] || editPackageData.image || '',
    // either media_categories or media_products are selected, so we allow to edit only one of them
    ...(isPackageWithMediaCategories
      ? { [MEDIA_CATEGORIES]: formattedInitialMediaCategories }
      : { [MEDIA_PRODUCTS]: formattedInitialMediaProducts }),
    [PRICE_PAIRS]: editPackageData.prices?.length
      ? editPackageData.prices.map(item => ({
          [PRICE_VALUE]: item.price,
          [PRICE_CURRENCY]: item.currency,
          id: uuidv4()
        }))
      : [{ ...pricePairInitialValue, id: uuidv4() }],
    [TAGS]: editPackageData[TAGS]?.map(tagText => ({
      ...tags?.find(tag => tag?.title === tagText),
      value: tagText,
      label: tagText
    })),
    [ACCESS_ACCOUNTS]: editPackageData[ACCESS_ACCOUNTS]?.map(id => getAccessAccountsValue(id, accountsOptions)),
    [LOCATION]: getProductsFilterLocation(editPackageData)
  }
}
