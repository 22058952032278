import { concat, eraseCookie, setCookie } from '../../helpers/common'

const MODULE_NAME = 'CAMPAIGNS_SUMMARY/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const FILTER = concat(MODULE_NAME, 'FILTER')
export const DATE_RANGE = concat(MODULE_NAME, 'DATE_RANGE')
export const SORT = concat(MODULE_NAME, 'SORT')

export const GET_CAMPAIGNS_SUMMARY_REPORTS = concat(MODULE_NAME, 'GET_CAMPAIGNS_SUMMARY_REPORTS')
export const GET_CAMPAIGNS_SUMMARY_REPORTS_SUCCESS = concat(MODULE_NAME, 'GET_CAMPAIGNS_SUMMARY_REPORTS_SUCCESS')
export const GET_CAMPAIGNS_SUMMARY_REPORTS_FAILURE = concat(MODULE_NAME, 'GET_CAMPAIGNS_SUMMARY_REPORTS_FAILURE')

// REPORTS
export const GET_CAMPAIGNS_REPORTS = concat(MODULE_NAME, 'GET_CAMPAIGNS_REPORTS')
export const GET_CAMPAIGNS_REPORTS_SUCCESS = concat(MODULE_NAME, 'GET_CAMPAIGNS_REPORTS_SUCCESS')
export const GET_CAMPAIGNS_REPORTS_FAILURE = concat(MODULE_NAME, 'GET_CAMPAIGNS_REPORTS_FAILURE')

export const CLEAR_REPORTS = concat(MODULE_NAME, 'CLEAR_REPORTS')

// GRAPHS
export const GET_CAMPAIGN_GRAPHS = concat(MODULE_NAME, 'GET_CAMPAIGN_GRAPHS')
export const GET_CAMPAIGN_GRAPHS_SUCCESS = concat(MODULE_NAME, 'GET_CAMPAIGN_GRAPHS_SUCCESS')
export const GET_CAMPAIGN_GRAPHS_FAILURE = concat(MODULE_NAME, 'GET_CAMPAIGN_GRAPHS_FAILURE')

// FILTERS SECTION:
export const SET_CAMPAIGNS_SUMMARY_FILTER = concat(MODULE_NAME, 'SET_CAMPAIGNS_SUMMARY_FILTER')
export const SET_CAMPAIGNS_SUMMARY_DATE_RANGE = concat(MODULE_NAME, 'SET_CAMPAIGNS_SUMMARY_DATE_RANGE')
export const SET_CAMPAIGNS_SUMMARY_SORT = concat(MODULE_NAME, 'SET_CAMPAIGNS_SUMMARY_SORT')
export const CLEAR_CAMPAIGNS_SUMMARY_FILTERS = concat(MODULE_NAME, 'CLEAR_CAMPAIGNS_SUMMARY_FILTERS')

// OTHER
export const LINE_ITEMS_SELECTION_CHANGE = concat(MODULE_NAME, 'LINE_ITEMS_SELECTION_CHANGE')
export const CLEAR_LINE_ITEMS_SELECTION_CHANGE = concat(MODULE_NAME, 'CLEAR_LINE_ITEMS_SELECTION_CHANGE')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

export function getCampaignsSummaryReports(params) {
  return { type: GET_CAMPAIGNS_SUMMARY_REPORTS, params }
}

export function getCampaignsSummaryReportsSuccess(reportsData) {
  return { type: GET_CAMPAIGNS_SUMMARY_REPORTS_SUCCESS, reportsData }
}

export function getCampaignsSummaryReportsFailure(error) {
  return { type: GET_CAMPAIGNS_SUMMARY_REPORTS_FAILURE, error }
}

export function getCampaignsReports(params) {
  return { type: GET_CAMPAIGNS_REPORTS, params }
}

export function getCampaignsReportsSuccess(reportsData) {
  return { type: GET_CAMPAIGNS_REPORTS_SUCCESS, reportsData }
}

export function getCampaignsReportsFailure(error) {
  return { type: GET_CAMPAIGNS_REPORTS_FAILURE, error }
}

export function getCampaignGraphs(params) {
  return { type: GET_CAMPAIGN_GRAPHS, params }
}

export function getCampaignGraphsSuccess(graphsData) {
  return { type: GET_CAMPAIGN_GRAPHS_SUCCESS, graphsData }
}

export function getCampaignGraphsFailure(error) {
  return { type: GET_CAMPAIGN_GRAPHS_FAILURE, error }
}

export function clearReports() {
  return { type: CLEAR_REPORTS }
}

export function setCampaignsSummaryFilter(filter) {
  setCookie(FILTER, filter, 1)
  return { type: SET_CAMPAIGNS_SUMMARY_FILTER, filter }
}

export function setCampaignsSummaryDateRange(dateRange) {
  setCookie(DATE_RANGE, dateRange, 1)
  return { type: SET_CAMPAIGNS_SUMMARY_DATE_RANGE, dateRange }
}

export function setCampaignsSummarySort(sort) {
  setCookie(SORT, sort, 1)
  return { type: SET_CAMPAIGNS_SUMMARY_SORT, sort }
}

export function clearCampaignsSummaryFilters() {
  eraseCookie(FILTER)
  eraseCookie(DATE_RANGE)
  eraseCookie(SORT)

  return { type: CLEAR_CAMPAIGNS_SUMMARY_FILTERS }
}

export function setCampaignsSummarySelectedLineItems(lineItemId) {
  return { type: LINE_ITEMS_SELECTION_CHANGE, lineItemId }
}

export function clearCampaignsSummarySelectedLineItems() {
  return { type: CLEAR_LINE_ITEMS_SELECTION_CHANGE }
}
