import { useMemo, useState } from 'react'
import { useTheme } from 'react-jss'
import { format } from 'date-fns'

import { DATES_FORMAT_MONTH_DAY, DATES_FORMAT_SHORT } from '../../../constants/dates'

export default function useDateRange(datePeriod, isMobile) {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)

  const rangeColors = useMemo(() => [theme.brandPrimary], [theme.brandPrimary])
  const rangeSelectedTextColor = useMemo(
    () => theme.brandPrimaryTextContrastColor,
    [theme.brandPrimaryTextContrastColor]
  )

  const { startDate, endDate } = datePeriod

  const dateRangeLabel = useMemo(() => {
    if (!startDate || !endDate) {
      return ''
    }

    if (isMobile) {
      return `${format(startDate, DATES_FORMAT_MONTH_DAY)} - ${format(endDate, DATES_FORMAT_MONTH_DAY)}`
    }

    return `${format(startDate, DATES_FORMAT_SHORT)} - ${format(endDate, DATES_FORMAT_SHORT)}`
  }, [startDate, endDate, isMobile])

  return useMemo(
    () => ({
      isOpen,
      setIsOpen,
      rangeColors,
      rangeSelectedTextColor,
      dateRangeLabel
    }),
    [isOpen, setIsOpen, rangeColors, rangeSelectedTextColor, dateRangeLabel]
  )
}
