import React from 'react'
import classnames from 'classnames'

import { ReactComponent as ArrowLeft } from '../../../../../../assets/icons/chevron-arrow-left.svg'
import { ReactComponent as ArrowRight } from '../../../../../../assets/icons/chevron-arrow-right.svg'

function Arrow({ left, onClick, classes, disabled }) {
  if (left) {
    return (
      <ArrowLeft className={classnames(classes.arrowLeft, { [classes.iconDisabled]: disabled })} onClick={onClick} />
    )
  }

  return (
    <ArrowRight className={classnames(classes.arrowRight, { [classes.iconDisabled]: disabled })} onClick={onClick} />
  )
}

export default Arrow
