import React from 'react'
import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

function ProductCardSkeleton() {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Skeleton height={70} width={70} />
      <div className={classes.info}>
        <Skeleton height={20} width={200} />
        <Skeleton height={14} width={120} />
      </div>
      <Skeleton height={25} width={70} style={{ borderRadius: '25px' }} />
    </div>
  )
}

export default ProductCardSkeleton
