import React from 'react'

import CreatedContract from './CreatedContract'
import DrawerHeadline from '../../../../../../components/Drawer/DrawerHeadline'
import BackButton from '../../../../../../features/components/Forms/StepForm/BackButton'

function SendAmmendmentContractForm({ onStepBack, onSuccessSubmit }) {
  return (
    <>
      <DrawerHeadline
        title="Review, sign and send"
        description="Please review, sign and send the booking amendment contract."
      />
      <CreatedContract onSuccessSubmit={onSuccessSubmit} />
      <BackButton onStepBack={onStepBack} showButton />
    </>
  )
}

export default SendAmmendmentContractForm
