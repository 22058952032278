import { createStyles } from '../../../../../../../../styles/helpers'

const useStyles = createStyles({
  media: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    objectFit: 'contain',
    borderRadius: 2,
    position: 'relative',
    cursor: onMediaClick => !!onMediaClick && 'pointer'
  }
})

export default useStyles
