import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'

import StepForm from '../../../../../../features/components/Forms/StepForm'
import AccountDetailsStep from './AccountDetailsStep'
// import MarginsStep from './MarginsStep'
// import DefineAdAccountsStep from './DefineAdAccountsStep'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import { useProgressItems } from './useProgressItem'
import { transformValuesToBE } from './formatters'

import { clearCreateSelfAccount, createSelfAccount } from '../../../../../../modules/actions/selfAccounts'
import { clearAllPlatformsSingleAdAccount } from '../../../../../../modules/actions/adAccounts'
import { clearControllerMembers, getControllerMembers } from '../../../../../../modules/actions/controller'
import {
  createSelfAccountErrorSelector,
  createSelfAccountIsLoadingSelector,
  selfAccountWasCreatedSelector
} from '../../../../../../modules/selectors/selfAccounts'
import { isUserControllerSelector, selectedControllerDataSelector } from '../../../../../../modules/selectors/app'

import { getInitialValues, validationSchema } from './fields'
import { CLIENT_ACCOUNT_WITH_MARGINS_CREATE } from '../../../../../../constants/forms'

const ClientAccountWithMarginsCreateForm = ({ controllerId }) => {
  const dispatch = useDispatch()

  const selfAccountWasCreated = useSelector(selfAccountWasCreatedSelector)
  const isUserController = useSelector(isUserControllerSelector)

  const selectedControllerData = useSelector(selectedControllerDataSelector)
  const { currency: controllerCurrency } = selectedControllerData

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE({
        values,
        controllerId,
        isController: isUserController
      })

      dispatch(createSelfAccount(transformedData))
    },
    [dispatch, controllerId, isUserController]
  )

  const formik = useFormik({
    initialValues: getInitialValues(controllerCurrency),
    validationSchema,
    onSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const formProgressItems = useProgressItems()

  const steps = useMemo(
    () => [
      {
        component: <AccountDetailsStep />,
        show: true
      }
      //
      // {
      //   component: <DefineAdAccountsStep />,
      //   show: isUserController
      // },
      // {
      //   component: <MarginsStep />,
      //   show: isUserController
      // }
    ],
    []
  )

  const clearCreateSelfAccountSubmit = useCallback(() => {
    dispatch(clearCreateSelfAccount())
  }, [dispatch])

  useEffect(() => {
    // clear data on form close for cases when the error was or success cases
    return () => dispatch(clearAllPlatformsSingleAdAccount())
  }, [dispatch])

  useEffect(() => {
    dispatch(getControllerMembers({ controller: controllerId }))

    return () => {
      dispatch(clearControllerMembers())
    }
  }, [dispatch, controllerId])

  return (
    <StepForm
      steps={steps}
      formProgressItems={formProgressItems}
      formik={purifiedFormik}
      formName={CLIENT_ACCOUNT_WITH_MARGINS_CREATE}
      clearSubmitHandler={clearCreateSelfAccountSubmit}
      successSubmit={selfAccountWasCreated}
      errorSelector={createSelfAccountErrorSelector}
      isLoadingSelector={createSelfAccountIsLoadingSelector}
    />
  )
}

ClientAccountWithMarginsCreateForm.propTypes = {
  controllerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default ClientAccountWithMarginsCreateForm
