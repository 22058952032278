import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import VideoUploadField from './VideoUploadField'
import Field from '../../../../../../../components/Form/Field'
import FieldsSection from '../../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'

import { getCatalogueProducts } from '../../../../../../../modules/actions/mediaOrders'
import { catalogueProductsSelector } from '../../../../../../../modules/selectors/mediaOrders'
import { selectedControllerIdSelector, selectedSelfAccountSelector } from '../../../../../../../modules/selectors/app'

import { AD_TEXT, adTextMaxLength, LANDING_PAGE_URL } from '../../../../../AdForms/fields'
import { CALL_TO_ACTION } from '../../../../../../ReusableFormFields/CallToActionFields/fields'
import tikTokCallToActionsList from '../../../../../../../constants/selectLists/tikTokCallToActionsList'
import { useShowProductOrPageSelectionStep } from '../../../../../../Facebook/AdForms/AdFacebookCreate/AdFacebookCreateForm/hooks/useShowProductOrPageSelectionStep'

const AdFormFields = ({ formik, adAccountId }) => {
  const dispatch = useDispatch()

  const catalogueProducts = useSelector(catalogueProductsSelector)
  const selectedSelfAccountId = useSelector(selectedSelfAccountSelector)
  const selectedSelfAccountControllerId = useSelector(selectedControllerIdSelector)

  const showProductSelection = useShowProductOrPageSelectionStep()

  useEffect(() => {
    dispatch(
      getCatalogueProducts({
        account: selectedSelfAccountId,
        controller: selectedSelfAccountControllerId
      })
    )
  }, [dispatch, selectedSelfAccountId, selectedSelfAccountControllerId])

  const formattedPageLinksList = catalogueProducts.map(({ link, name, id }) => ({
    value: id,
    label: name,
    description: link
  }))

  return (
    <FieldsSection title="Creative assets">
      <VideoUploadField formik={formik} adAccountId={adAccountId} />
      <FieldRow title="Ad text" description="100 characters or less with no emojis or hashtags.">
        <Field
          formik={formik}
          name={AD_TEXT}
          placeholder="Ad description"
          autoComplete="off"
          maxLength={adTextMaxLength}
        />
      </FieldRow>
      <FieldRow title="Call to action" description="Select the button text.">
        <Field
          placeholder="Select Call To Action"
          formik={formik}
          name={CALL_TO_ACTION}
          options={tikTokCallToActionsList}
        />
      </FieldRow>
      <FieldRow title="Page link" description="Which category or product page the ad will click through to.">
        <Field
          formik={formik}
          name={LANDING_PAGE_URL}
          // if custom_click_though_link field on selfAccount is true - we don't show select, but just "text" input
          placeholder={showProductSelection ? 'Select product' : 'Landing page URL'}
          options={showProductSelection ? formattedPageLinksList : null}
        />
      </FieldRow>
    </FieldsSection>
  )
}

AdFormFields.propTypes = {
  adAccountId: PropTypes.string.isRequired
}

export default AdFormFields
