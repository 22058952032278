import { ADD_TO_ALL_CONTROLLER_ACCOUNTS, BRAND_CATEGORY, SELF_ACCOUNTS } from '../../fields'

export const getInitialValues = (memberData = {}, controllerSelfAccountsList) => {
  const formattedSelfAccounts = memberData[SELF_ACCOUNTS]?.map(accountId => {
    const account = controllerSelfAccountsList.find(account => account.id === accountId)
    return account ? { label: account.name, value: account.id } : null
  })

  return {
    [BRAND_CATEGORY]: memberData[BRAND_CATEGORY] || '',
    [SELF_ACCOUNTS]: formattedSelfAccounts,
    [ADD_TO_ALL_CONTROLLER_ACCOUNTS]: memberData[ADD_TO_ALL_CONTROLLER_ACCOUNTS] || false
  }
}
