import React, { useCallback } from 'react'

import AppLogo from '../../../features/components/AppLogo'

import { DOMAINS } from '../../../constants/selectLists/languagesList'

import { ReactComponent as NexusLogo } from '../../../assets/domains/nexus/nexus_logo.svg'

import useStyles from './styles'

function AppLoader({ isFixed }) {
  const classes = useStyles(isFixed)

  const currentAppUrl = window.location.href

  const LoaderLogo = useCallback(() => {
    if (currentAppUrl.includes(DOMAINS.nexus)) {
      // Add loader logo may be different from AppLogo, in this case we re-write it here
      return <NexusLogo />
    } else {
      return <AppLogo />
    }
  }, [currentAppUrl])

  return (
    <div className={classes.screensaver}>
      <LoaderLogo />
    </div>
  )
}

export default AppLoader
