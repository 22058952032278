import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import MediaPackageCard from './MediaPackageCard'
import HighlightedValueSelect from '../../../../../components/HighlightedValueSelect'

import { mediaPackagesSelector } from '../../../../../modules/selectors/mediaPackages'
import { selectedSelfAccountDataSelector } from '../../../../../modules/selectors/app'

import { getAvailableCategories, getSelectedCategoryPackages, getValidCurrencyPackages } from './helpers'

import useStyles from './styles'

const MediaPackagesSection = () => {
  const classes = useStyles()

  const { t } = useTranslation()

  const [selectedCategory, setSelectedCategory] = useState('')

  const mediaPackages = useSelector(mediaPackagesSelector)
  const { currency: selectedCurrencyCode } = useSelector(selectedSelfAccountDataSelector)

  const formattedMediaPackages = useMemo(() => {
    const validCurrencyPackages = getValidCurrencyPackages({ mediaPackages, selectedCurrencyCode })
    const selectedCategoryPackages = getSelectedCategoryPackages({
      mediaPackages: validCurrencyPackages,
      selectedCategory
    })

    return selectedCategoryPackages.sort((a, b) => a.order - b.order)
  }, [selectedCategory, mediaPackages, selectedCurrencyCode])

  const availableCategories = useMemo(
    () => getAvailableCategories({ mediaPackages, selectedCurrencyCode }),
    [mediaPackages, selectedCurrencyCode]
  )

  const hasMediaPackages = !!formattedMediaPackages.length

  const onCategorySelectHandler = useCallback(
    item => {
      setSelectedCategory(item?.value)
    },
    [setSelectedCategory]
  )

  return (
    <section className={classes.section}>
      <div className={classes.content}>
        <div className={classes.headerSection}>
          <h2 className={classes.header}>{t('Our media packages')}</h2>
          <p className={classes.headerDescription}>
            {t(
              'Here you can book a media package, select your media products, reserve inventory and upload your creative assets.'
            )}
          </p>
        </div>
        <section className={classes.filters}>
          {availableCategories.length > 1 && (
            <HighlightedValueSelect
              className={classes.categorySelect}
              placeholder="Media category"
              options={availableCategories}
              value={selectedCategory}
              onChange={onCategorySelectHandler}
              isSearchable={false}
            />
          )}
        </section>
        {hasMediaPackages ? (
          <div className={classes.cardsContainer}>
            {formattedMediaPackages.map(mediaPackage => (
              <MediaPackageCard key={mediaPackage.id} mediaPackage={mediaPackage} />
            ))}
          </div>
        ) : (
          <p>{t('There are no media packages')}</p>
        )}
      </div>
    </section>
  )
}

export default MediaPackagesSection
