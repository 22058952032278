import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

export const calendarsListSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarsList.results,
  results => results
)

export const calendarsListIsLoadingSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarsList.isLoading,
  isLoading => isLoading
)

export const calendarsListWasLoadedSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarsList.wasLoaded,
  wasLoaded => wasLoaded
)

export const calendarsListErrorSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarsList.error,
  error => error
)

export const calendarCreatedSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarCreate,
  calendarCreate => calendarCreate.data
)

export const calendarCreateIsLoadingSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarCreate,
  calendarCreate => calendarCreate.isLoading
)

export const calendarCreateErrorSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarCreate,
  calendarCreate => calendarCreate.error
)

export const calendarDataSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarData,
  calendarData => calendarData.data
)

export const calendarDataIsLoadingSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarData,
  calendarData => calendarData.isLoading
)

export const calendarDataWasLoadedSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarData.wasLoaded,
  wasLoaded => wasLoaded
)

/* EVENT selectors START */
export const calendarEventDataIsLoadingSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarEventData,
  calendarEvent => calendarEvent.isLoading
)

export const calendarEventDataErrorSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarEventData,
  calendarEvent => calendarEvent.error
)

export const calendarEventDataCreatedSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarEventData,
  calendarEvent => calendarEvent.data
)

export const calendarEventIsLoadingSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarEvent,
  calendarEvent => calendarEvent.isLoading
)

export const calendarEventErrorSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarEvent,
  calendarEvent => calendarEvent.error
)

export const calendarEventWasCreatedSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarEvent.wasCreated,
  calendarEvent => calendarEvent
)

export const calendarEventUpdateIsLoading = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarEventUpdate,
  calendarEventUpdate => calendarEventUpdate.isLoading
)

export const calendarEventUpdateError = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarEventUpdate,
  calendarEventUpdate => calendarEventUpdate.error
)

export const calendarEventUpdatedSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarEventUpdate,
  calendarEventUpdate => calendarEventUpdate
)

export const calendarEventDeleteIsLoadingSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.deletedEvent,
  deletedEvent => deletedEvent.isLoading
)

export const calendarEventDeleteErrorSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.deletedEvent,
  deletedEvent => deletedEvent.error
)

export const calendarEventDeletedIdSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.deletedEvent,
  deletedEvent => deletedEvent.id
)

/* EVENT selectors END */

/* EVENT_GROUP selectors START */

export const calendarRowWasCreatedSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarRow.wasCreated,
  wasCreated => wasCreated
)

export const calendarRowIsLoadingSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarRow,
  calendarRow => calendarRow.isLoading
)

export const calendarRowErrorSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.calendarRow,
  calendarRow => calendarRow.error
)

export const updateEventGroupLoadingSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.updatedEventGroup,
  updatedEventGroup => updatedEventGroup.isLoading
)

export const updateEventGroupErrorSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.updatedEventGroup,
  updatedEventGroup => updatedEventGroup.error
)

export const updatedEventGroupSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.updatedEventGroup,
  updatedEventGroup => updatedEventGroup
)

export const updateEventGroupsOrderLoadingSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.updateEventGroupOrder,
  updateEventGroupOrder => updateEventGroupOrder.isLoading
)

export const deletedEventGroupIsLoadingSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.deletedEventGroup,
  deletedEventGroup => deletedEventGroup.isLoading
)

export const deletedEventGroupErrorSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.deletedEventGroup,
  deletedEventGroup => deletedEventGroup.error
)

export const deletedEventGroupSelector = createSelector(
  ({ calendarPlanner }) => calendarPlanner.deletedEventGroup,
  deletedEventGroup => deletedEventGroup
)

/* EVENT_GROUP selectors END */
