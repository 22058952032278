import { formatCurrency, formatNumber } from '../../../helpers/numbers'
import { insertIf } from '../../../helpers/common'

export const getReusableTooltipCallbackOptions = ({
  rawGraphData,
  showAdvancedMetrics,
  currencySymbol,
  showImpressionsAsLabel = true
}) => {
  return {
    ...(showImpressionsAsLabel && {
      label: function (tooltipItem) {
        return `${formatNumber(tooltipItem.raw)} impressions`
      }
    }),
    footer: tooltipItem => {
      const { dataIndex } = tooltipItem && tooltipItem[0]

      return (
        rawGraphData && [
          ...insertIf(!showImpressionsAsLabel, `${formatNumber(tooltipItem[0].raw)} impressions`),
          `${formatNumber(rawGraphData[dataIndex].clicks)} clicks`,
          `${formatNumber(rawGraphData[dataIndex].ctr, { min: 2, max: 2 })}% click through rate`,
          ...insertIf(
            showAdvancedMetrics,
            `${formatCurrency(
              rawGraphData[dataIndex].cpa,
              { min: 2, max: 2 },
              { symbol: currencySymbol }
            )} cost per action`
          )
        ]
      )
    }
  }
}
