import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { lineItemSelector } from '../../../../../../../modules/selectors/lineItems'

import { formatOptionsList } from '../../../../../../../features/formatters'

const CategoriesPreview = () => {
  const { t } = useTranslation()

  const { family_statuses } = useSelector(lineItemSelector)

  const isFamilyStatusesExists = family_statuses && family_statuses.length

  if (isFamilyStatusesExists) {
    return (
      <span>
        {t('Parental Status: ') +
          formatOptionsList({
            list: family_statuses,
            valueName: 'id',
            labelName: 'name'
          })
            .map(status => status.label)
            .join(', ')}
      </span>
    )
  }

  return null
}

export default CategoriesPreview
