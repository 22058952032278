import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'

import StepForm from '../../../../../../../../features/components/Forms/StepForm'
import CustomAudiencesStep from './Steps/CustomAudiencesStep'
import DatesStep from './Steps/DatesStep'

import { usePurifiedFormik } from '../../../../../../../../hooks/formHooks/usePurifiedFormik'
import { transformValuesToBE } from './formatters'
import {
  useAllowExistingLineItems,
  useManageExistingLineItemFormStep,
  useManageExistingLineItemFormSubmit
} from '../../../hooks'

import { createLineItem, clearCreateLineItem } from '../../../../../../../../modules/actions/lineItems'
import { setMediaOrderUploadCreative } from '../../../../../../../../modules/actions/mediaOrders'
import {
  lineItemCreatedDataSelector,
  lineItemCreateErrorSelector,
  lineItemCreateIsLoadingSelector,
  lineItemsSelector,
  lineItemWasCreatedSelector
} from '../../../../../../../../modules/selectors/lineItems'
import { mediaOrderUploadCreativeDataSelector } from '../../../../../../../../modules/selectors/mediaOrders'
import { choicesPixelsSelector, currentUserCountrySelector } from '../../../../../../../../modules/selectors/choices'
import { activeStepSelector } from '../../../../../../../../modules/selectors/forms'

import { getInitialValues, validationSchema } from './fields'
import { FACEBOOK_PLATFORM } from '../../../../../../../../constants/selectLists/platformList'
import { LINE_ITEM_AUDIENCE_TARGETING_FORM } from '../../index'

const LineItemFormContent = ({ adAccountId, onSuccessSubmit, onLineItemOptionChange }) => {
  const dispatch = useDispatch()

  const [successSubmit, setSuccessSubmit] = useState(false)

  const lineItems = useSelector(lineItemsSelector)
  const activeStep = useSelector(activeStepSelector)
  const lineItemFacebookCreatedData = useSelector(lineItemCreatedDataSelector)
  const lineItemWasCreated = useSelector(lineItemWasCreatedSelector)
  const currentUserCountry = useSelector(currentUserCountrySelector)
  const pixels = useSelector(choicesPixelsSelector)
  const { campaign } = useSelector(mediaOrderUploadCreativeDataSelector)

  const firstPixelId = pixels[0]?.id

  const { isLineItemsExist } = useAllowExistingLineItems(lineItems)

  const createLineItemHandler = useCallback(
    values => {
      dispatch(
        createLineItem(
          transformValuesToBE({
            values,
            adAccountId,
            campaign,
            pixelId: firstPixelId,
            country: currentUserCountry?.['iso2']
          }),
          FACEBOOK_PLATFORM
        )
      )
    },
    [dispatch, adAccountId, campaign, firstPixelId, currentUserCountry]
  )

  const onSubmit = useManageExistingLineItemFormSubmit({ onSubmit: createLineItemHandler, setSuccessSubmit })

  const newLineItemSteps = useMemo(
    () => [
      {
        component: <DatesStep />,
        show: true
      },
      {
        component: <CustomAudiencesStep adAccountId={adAccountId} />,
        show: true
      }
    ],
    [adAccountId]
  )

  const formik = useFormik({
    initialValues: getInitialValues(isLineItemsExist),
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)
  const { values } = purifiedFormik

  const steps = useManageExistingLineItemFormStep({
    values,
    newLineItemSteps,
    onLineItemOptionChange
  })

  const clearCreateLineItemHandler = useCallback(() => {
    dispatch(clearCreateLineItem())
  }, [dispatch])

  useEffect(() => {
    if (lineItemWasCreated) {
      dispatch(
        setMediaOrderUploadCreative({
          lineItem: lineItemFacebookCreatedData,
          pixelId: firstPixelId
        })
      )

      setSuccessSubmit(true)
    }
  }, [dispatch, lineItemWasCreated, lineItemFacebookCreatedData, firstPixelId])

  return (
    <StepForm
      steps={steps}
      customActiveStep={activeStep + 1}
      customStepsForProgress={5}
      formik={purifiedFormik}
      formName={LINE_ITEM_AUDIENCE_TARGETING_FORM}
      submitText="Save and continue"
      onSuccessSubmit={onSuccessSubmit}
      clearSubmitHandler={clearCreateLineItemHandler}
      successSubmit={successSubmit}
      errorSelector={lineItemCreateErrorSelector}
      isLoadingSelector={lineItemCreateIsLoadingSelector}
    />
  )
}

LineItemFormContent.propTypes = {
  adAccountId: PropTypes.string.isRequired,
  onSuccessSubmit: PropTypes.func.isRequired,
  onLineItemOptionChange: PropTypes.func.isRequired
}

export default LineItemFormContent
