import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import MediaOrderPrice from '../../../../../MediaOrderPrice'

import { calculateDiscountedPrice } from '../../../../../helpers/price'

function TotalProductPrice({ className, productQuantity, currentDiscount, publications, currencySymbol }) {
  // add each publication price to get total product price for quantity 1
  const productPrice = publications.reduce((acc, publication) => {
    return acc + publication.price
  }, 0)

  const discountedPrice = useMemo(() => {
    if (currentDiscount) {
      return calculateDiscountedPrice(productPrice, productQuantity, currentDiscount)
    } else {
      return null
    }
  }, [productPrice, productQuantity, currentDiscount])

  return (
    <MediaOrderPrice
      regularPrice={productPrice * productQuantity}
      discountedPrice={discountedPrice}
      currencySymbol={currencySymbol}
      className={className}
      isBold
    />
  )
}

TotalProductPrice.propTypes = {
  productQuantity: PropTypes.number.isRequired,
  // publications to which price is calculated
  publications: PropTypes.array.isRequired,
  currentDiscount: PropTypes.number,
  currencySymbol: PropTypes.string.isRequired,
  // styles
  className: PropTypes.string
}

export default TotalProductPrice
