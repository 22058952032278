import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '../../../components/Form/Field'
import RadioBlock from '../../../components/Form/RadioBlock'

import {
  CALL_TO_ACTION_OPTION,
  CALL_TO_ACTION_LEARN_MORE,
  CALL_TO_ACTION_CUSTOM,
  CALL_TO_ACTION,
  LEARN_MORE
} from './fields'

const CallToActionFields = ({ formik, actionsList, preselectDefaultValue }) => {
  const { t } = useTranslation()

  const { values, setFieldValue } = formik

  const learnMoreActionHandler = useCallback(() => {
    setFieldValue(CALL_TO_ACTION_OPTION, CALL_TO_ACTION_LEARN_MORE)
    setFieldValue(CALL_TO_ACTION, LEARN_MORE)
  }, [setFieldValue])

  const [wasDefaultValuePreselected, setWasDefaultValuePreselected] = useState(false)

  useEffect(() => {
    if (preselectDefaultValue && !wasDefaultValuePreselected) {
      learnMoreActionHandler()
      setWasDefaultValuePreselected(true)
    }
  }, [wasDefaultValuePreselected, preselectDefaultValue, learnMoreActionHandler])

  return (
    <>
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_call_to_action_option_learn_more"
        name={CALL_TO_ACTION_OPTION}
        value={CALL_TO_ACTION_LEARN_MORE}
        selectedValue={values[CALL_TO_ACTION_OPTION]}
        label={t('Learn more')}
        onChange={learnMoreActionHandler}
      />
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_call_to_action_custom"
        name={CALL_TO_ACTION_OPTION}
        value={CALL_TO_ACTION_CUSTOM}
        selectedValue={values[CALL_TO_ACTION_OPTION]}
        label={t('Select Call To Action')}
      >
        <Field label="Call To Action" formik={formik} name={CALL_TO_ACTION} options={actionsList} />
      </RadioBlock>
    </>
  )
}

export default CallToActionFields
