import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import EditProfileFormContent from './EditProfileFormContent'
import PhoneNumberVerificationModal from './PhoneNumberVerificationModal'

import { formatPhoneNumber } from './UserPhoneField/fields'
import { getInitialValues, getValidationSchema, PHONE_NUMBER } from './fields'

import { getChoicesCountries } from '../../../../modules/actions/choices'
import { clearUpdateCurrentUserProfile, updateCurrentUserProfile } from '../../../../modules/actions/app'
import { getCurrentUserSelector } from '../../../../modules/selectors/app'
import { choicesCountriesWasLoadedSelector } from '../../../../modules/selectors/choices'

const UserProfileContent = () => {
  const dispatch = useDispatch()

  const [isVerificationModalOpened, setIsVerificationModalOpened] = useState(false)
  // to re-send verification, we need to re-submit values with the same update action
  const [submittedNewUserValues, setSubmittedNewUserValues] = useState(null)

  const user = useSelector(getCurrentUserSelector)
  const countriesWasLoaded = useSelector(choicesCountriesWasLoadedSelector)

  const { id, phone_number: phoneNumber } = user

  const onSubmit = values => {
    const formattedNewPhoneNumber = formatPhoneNumber(values[PHONE_NUMBER])

    const userDataNewValues = {
      ...values,
      phone_number: formattedNewPhoneNumber
    }

    dispatch(
      updateCurrentUserProfile({
        userId: id,
        userData: userDataNewValues
      })
    )

    // if user changes phone, Amazon always verifies it, so we need to show verification modal
    if (formattedNewPhoneNumber !== phoneNumber) {
      setSubmittedNewUserValues(userDataNewValues)
    }
  }

  const formik = useFormik({
    initialValues: getInitialValues(user),
    validationSchema: getValidationSchema(user.phone_number),
    enableReinitialize: true,
    onSubmit
  })

  const onSuccessEditFormSubmit = () => {
    // if we have populated new user values, it means the form was submitted with phone change
    // in this case, we should open verification modal
    if (submittedNewUserValues) {
      setIsVerificationModalOpened(true)
    }
  }

  const onVerificationModalCloseHandler = ({ shouldPopulateInitialPhone = false } = {}) => {
    // if user closes modal without verification, populate previously entered phone to formik
    // as formik resets it when we successfully submit the user edit form
    if (shouldPopulateInitialPhone) {
      formik.setFieldValue(PHONE_NUMBER, submittedNewUserValues[PHONE_NUMBER].split('+')[1])
    }

    setIsVerificationModalOpened(false)
    setSubmittedNewUserValues(null)
  }

  const onVerificationResend = () => {
    onSubmit(submittedNewUserValues)
  }

  const clearHandler = () => {
    dispatch(clearUpdateCurrentUserProfile())
  }

  useEffect(() => {
    if (!countriesWasLoaded) {
      dispatch(getChoicesCountries())
    }
  }, [dispatch, countriesWasLoaded])

  return (
    <>
      <EditProfileFormContent formik={formik} onSuccessSubmit={onSuccessEditFormSubmit} clearHandler={clearHandler} />

      <PhoneNumberVerificationModal
        onClose={onVerificationModalCloseHandler}
        modalData={submittedNewUserValues}
        isModalOpened={isVerificationModalOpened}
        onVerificationResend={onVerificationResend}
      />
    </>
  )
}

export default UserProfileContent
