import { useCallback, useEffect, useMemo } from 'react'

export default function useMediaFilesLibrary({
  onFileRemove,
  onMediaSelect,
  clearMediaHandler,
  loadInitialMediaHandler,
  type
}) {
  const handleRemoveFile = useCallback(() => {
    onFileRemove() && onFileRemove()
  }, [onFileRemove])

  const handleMediaClick = useCallback(
    media => {
      onMediaSelect && onMediaSelect(media, type)
    },
    [onMediaSelect, type]
  )

  useEffect(() => {
    // clear media before initial load to avoid duplication (in case user already downloaded them)
    clearMediaHandler && clearMediaHandler()

    loadInitialMediaHandler && loadInitialMediaHandler()
  }, [clearMediaHandler, loadInitialMediaHandler])

  useEffect(
    () => () => {
      clearMediaHandler && clearMediaHandler()
    },
    [clearMediaHandler]
  )

  return useMemo(
    () => ({
      handleRemoveFile,
      handleMediaClick
    }),
    [handleRemoveFile, handleMediaClick]
  )
}
