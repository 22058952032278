import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setFormSuccessSubmit } from '../../modules/actions/forms'

export default function useSuccessFormSubmit({ onSuccessSubmit, formName, resetForm }) {
  const dispatch = useDispatch()

  return useCallback(() => {
    onSuccessSubmit && onSuccessSubmit()
    dispatch(setFormSuccessSubmit(formName))
    // when form is successfully submitted > reset formik
    resetForm()
  }, [dispatch, formName, onSuccessSubmit, resetForm])
}
