import update from 'immutability-helper'

import {
  INITIAL_CREATE_REDUCER,
  INITIAL_DELETE_REDUCER,
  INITIAL_GET_RESULTS_PAGING_REDUCER
} from '../../constants/reducer'
import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  CLEAR_AUTOMATION_RULES,
  CLEAR_CREATE_AUTOMATION_RULE,
  CREATE_AUTOMATION_RULE,
  CREATE_AUTOMATION_RULE_FAILURE,
  CREATE_AUTOMATION_RULE_SUCCESS,
  DELETE_AUTOMATION_RULE,
  DELETE_AUTOMATION_RULE_FAILURE,
  DELETE_AUTOMATION_RULE_SUCCESS,
  GET_AUTOMATION_RULES,
  GET_AUTOMATION_RULES_FAILURE,
  GET_AUTOMATION_RULES_SUCCESS
} from '../actions/automationRules'

import { deleteItemById } from '../../helpers/modules/reducerHelpers'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  automationRules: INITIAL_GET_RESULTS_PAGING_REDUCER,
  createAutomationRule: INITIAL_CREATE_REDUCER,
  deleteAutomationRule: INITIAL_DELETE_REDUCER
}

export default function automationRules(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    // get automation rules
    case GET_AUTOMATION_RULES:
      return update(state, {
        automationRules: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_AUTOMATION_RULES_SUCCESS:
      return update(state, {
        automationRules: {
          results: {
            // not replace, but add items to existing data
            $push: action.automationRulesData.results
          },
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: {
              next: action.automationRulesData.next
            }
          }
        }
      })
    case GET_AUTOMATION_RULES_FAILURE:
      return update(state, {
        automationRules: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_AUTOMATION_RULES:
      return update(state, {
        automationRules: {
          $set: initialState.automationRules
        }
      })

    // create automation rule
    case CREATE_AUTOMATION_RULE:
      return update(state, {
        createAutomationRule: {
          $merge: {
            isLoading: true
          }
        }
      })
    case CREATE_AUTOMATION_RULE_SUCCESS:
      return update(state, {
        createAutomationRule: {
          $merge: {
            isLoading: false,
            wasCreated: true
          }
        },
        automationRules: {
          results: {
            $unshift: [action.automationRuleData]
          }
        }
      })
    case CREATE_AUTOMATION_RULE_FAILURE:
      return update(state, {
        createAutomationRule: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_AUTOMATION_RULE: {
      return update(state, {
        createAutomationRule: { $set: initialState.createAutomationRule }
      })
    }

    // delete automation rule
    case DELETE_AUTOMATION_RULE:
      return update(state, {
        deleteAutomationRule: {
          $merge: {
            isLoading: true,
            id: action.id
          }
        }
      })
    case DELETE_AUTOMATION_RULE_SUCCESS:
      return update(state, {
        deleteAutomationRule: {
          $merge: {
            isLoading: false,
            wasDeleted: true
          }
        },
        automationRules: {
          results: {
            $set: deleteItemById(state.automationRules.results, action.id)
          }
        }
      })
    case DELETE_AUTOMATION_RULE_FAILURE:
      return update(state, {
        deleteAutomationRule: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    default:
      return state
  }
}
