import * as Yup from 'yup'

import {
  BUDGET_DAILY,
  BUDGET_LIFETIME,
  BUDGET_OPTION,
  LANGUAGES,
  MAX_AGE_YEARS,
  MIN_AGE_YEARS,
  NAME,
  TARGET_COST_CAP
} from '../../../../ReusableFormFields/LineItemForms/fields'
import nameValidation from '../../../../../features/validations/name'
import { LOCATION_LIST, SHOW_COUNTRIES, SHOW_REGIONS } from '../../../../ReusableFormFields/GeolocationsFields/fields'
import { regionsOverlapValidation } from '../../../../../features/validations/gelolocations'
import {
  CUSTOM_AUDIENCES,
  CUSTOM_AUDIENCES_EXCLUSION,
  CUSTOM_AUDIENCES_EXCLUSION_CHECKED
} from '../../../../ReusableFormFields/CustomAudiencesFields/fields'
import { MAX_AGE_CHOICE, MIN_AGE_CHOICE } from '../../LineItemFacebookCreateOld/LineItemFacebookCreateFormOld/fields'
import { GENDER_OPTION, GENDER_OPTION_ALL } from '../../../../ReusableFormFields/GenderFields/fields'
import { INTERESTS } from '../../../../ReusableFormFields/InterestsFields/fields'
import { promotedObjectInitialValues } from '../../../../ReusableFormFields/PromotedObjectFields/fields'
import { getActivePlacementsInitialValues } from '../../../../ReusableFormFields/LineItemForms/facebookLineItemPlacementsHelpers'

// dates
export const TIME_START = 'time_start'
export const TIME_STOP = 'time_stop'

// locations
export const LOCATIONS_SELECTED_TAB = 'locationsSelectedTab'
export const LOCATIONS_TAB = 'locationsTab'
export const LOCATIONS_LIST_TAB = 'locationsListTab'
export const TARGET_BY_REGION = 'targetByRegion'

export const locationsTabs = [
  {
    value: LOCATIONS_TAB,
    label: 'Locations'
  },
  {
    value: LOCATIONS_LIST_TAB,
    label: 'Location list'
  }
]

export const getInitialValues = campaignObjective => {
  const initialActivePlacementsValues = getActivePlacementsInitialValues(campaignObjective)

  return {
    [NAME]: '',
    [TIME_START]: '',
    [TIME_STOP]: '',
    [BUDGET_OPTION]: BUDGET_LIFETIME,
    [BUDGET_LIFETIME]: '',
    [BUDGET_DAILY]: '',
    [LOCATIONS_SELECTED_TAB]: LOCATIONS_TAB,
    [SHOW_COUNTRIES]: [],
    [TARGET_BY_REGION]: false,
    [SHOW_REGIONS]: [],
    [LOCATION_LIST]: '',
    [LANGUAGES]: [
      {
        label: 'English (All)',
        value: 1001
      }
    ],
    // audience
    [CUSTOM_AUDIENCES]: [],
    [CUSTOM_AUDIENCES_EXCLUSION_CHECKED]: false,
    [CUSTOM_AUDIENCES_EXCLUSION]: [],
    // age
    [MIN_AGE_YEARS]: [],
    [MAX_AGE_YEARS]: [],
    [MIN_AGE_CHOICE]: 18,
    [MAX_AGE_CHOICE]: 65,
    // gender
    [GENDER_OPTION]: GENDER_OPTION_ALL,
    [INTERESTS]: [],
    // promoted object
    ...promotedObjectInitialValues,
    // placement
    ...initialActivePlacementsValues
  }
}

export const validationSchema = ({ includeBudget, showTargetCostCapField }) => {
  return Yup.object().shape({
    ...nameValidation,
    [TIME_START]: Yup.date().required('Start date Required'),
    [TIME_STOP]: Yup.date().required('End date Required'),

    ...(includeBudget && {
      [BUDGET_LIFETIME]: Yup.string().when(BUDGET_OPTION, {
        is: BUDGET_LIFETIME,
        then: () => Yup.string().required('This field can not be empty')
      })
    }),
    ...(includeBudget && {
      [BUDGET_DAILY]: Yup.string().when(BUDGET_OPTION, {
        is: BUDGET_DAILY,
        then: () => Yup.string().required('This field can not be empty')
      })
    }),

    // geolocations
    [SHOW_COUNTRIES]: Yup.array().when([LOCATIONS_SELECTED_TAB, TARGET_BY_REGION], {
      is: (selectedTab, targetByRegion) => {
        const isLocationsTab = selectedTab === LOCATIONS_TAB
        return isLocationsTab && !targetByRegion
      },
      then: () => Yup.array().min(1, 'Country Required')
    }),
    [SHOW_REGIONS]: Yup.array().when([LOCATIONS_SELECTED_TAB, TARGET_BY_REGION], {
      is: (selectedTab, targetByRegion) => {
        const isLocationsTab = selectedTab === LOCATIONS_TAB
        return isLocationsTab && targetByRegion
      },
      then: () => regionsOverlapValidation
    }),
    [LOCATION_LIST]: Yup.string().when(LOCATIONS_SELECTED_TAB, {
      is: LOCATIONS_LIST_TAB,
      then: () => Yup.string().required('Location list Required')
    }),
    // audiences
    [CUSTOM_AUDIENCES_EXCLUSION]: Yup.array().when(CUSTOM_AUDIENCES_EXCLUSION_CHECKED, {
      is: true,
      then: () => Yup.array().min(1, 'Excluded audiences Required')
    }),
    ...(showTargetCostCapField && {
      // cost cap
      [TARGET_COST_CAP]: Yup.string().required('Goal Required')
    })
  })
}
