import React, { useCallback, useMemo, useState } from 'react'
import { getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import BrandsSection from './BrandsSection'
import ActionText from '../../../../../../components/ActionText'

import {
  initialBrandValue,
  BRANDS
} from '../../ClientAccountWithMarginsCreate/ClientAccountWithMarginsCreateForm/fields'

const BrandsContent = ({ formik, isEditForm }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(0)
  const { values, setFieldValue } = formik

  const brands = useMemo(() => getIn(values, BRANDS), [values])

  const addNewFileButtonText = values[BRANDS].length ? '+ Add another brand name' : '+ Add a brand name'

  const handleAddAnotherItem = useCallback(() => {
    const currentRequirements = values[BRANDS]

    // open new item in carousel
    setSelected(currentRequirements.length)
    setFieldValue(BRANDS, [...currentRequirements, { ...initialBrandValue, id: uuidv4() }])
  }, [setFieldValue, values])

  const handleDeleteItem = useCallback(
    index => {
      // delete item from carousel items array
      const newCarouselItems = [...brands]
      newCarouselItems.splice(index, 1)

      // open previous item in carousel
      setSelected(newCarouselItems.length - 1)
      setFieldValue(BRANDS, newCarouselItems)
    },
    [brands, setFieldValue]
  )

  return (
    <>
      {brands.map((item, index) => {
        return (
          <BrandsSection
            key={item.id}
            formik={formik}
            itemPath={`${BRANDS}[${index}]`}
            selected={selected === index}
            allowDelete={brands.length > 1}
            onSelect={() => setSelected(index)}
            onClose={() => setSelected(null)}
            onDelete={() => handleDeleteItem(index)}
            isEditForm={isEditForm}
          />
        )
      })}
      <ActionText onClick={handleAddAnotherItem} isDark>
        {t(addNewFileButtonText)}
      </ActionText>
    </>
  )
}

BrandsContent.propTypes = {
  formik: PropTypes.object.isRequired,
  isEditForm: PropTypes.bool
}

export default BrandsContent
