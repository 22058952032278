import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { formatCurrency } from '../../../../../../../helpers/numbers'
import { findPlatformName } from './helpers'

import { selectedControllerDataSelector } from '../../../../../../../modules/selectors/app'

import useStyles from '../styles'

const BookedMediaCampaignsField = ({ bookedMediaCampaigns = [], productName = '' }) => {
  const selectedSelfAccountController = useSelector(selectedControllerDataSelector)

  const classes = useStyles()

  if (!bookedMediaCampaigns?.length) {
    return null
  }

  return (
    <div className={classes.bookedMediaCampaignsField}>
      <div className={classes.rowTitle}>{productName}</div>
      {bookedMediaCampaigns.map(({ platform, name, spend }, index) => (
        <div key={index} className={classes.rowWrapper}>
          <div className={classes.rowSubTitle}>
            {findPlatformName(platform)} - {name}
          </div>
          <div className={classes.costField}>
            {formatCurrency(spend, { min: 2, max: 2 }, { symbol: selectedSelfAccountController?.currency_symbol })}
          </div>
        </div>
      ))}
    </div>
  )
}

BookedMediaCampaignsField.propTypes = {
  bookedMediaCampaigns: PropTypes.array.isRequired,
  productName: PropTypes.string.isRequired
}

export default BookedMediaCampaignsField
