import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ContentRow from '../../../features/components/ContentSection/ContentRow'
import ContentSection from '../../../features/components/ContentSection'
import ControllerInviteCreate from './ControllerInviteCreate'
import DeactivateUserModal from '../ManageSelfAccountTeam/DeactivateUserModal'
import ControllerPermissionsEdit from './ControllerPermissionsEdit'
import ControllerMemberEdit from './ControllerMemberEdit'
import ControllerMembersTable from './ControllerMembersTable'
import ControllerInvitesTable from './ControllerInvitesTable'

import useModalManage from '../../../hooks/useModalManage'
import { useLoadPaginatedList } from '../../../features/hooks/useLoadPaginatedList'

import {
  clearControllerInvites,
  clearControllerMembers,
  clearUpdateControllerMember,
  deleteControllerInvite,
  getControllerInvites,
  getControllerMembers,
  resendControllerInvite,
  updateControllerMember,
  updateControllerMemberStatus
} from '../../../modules/actions/controller'
import {
  controllerInvitesNextSelector,
  controllerMembersNextSelector,
  updatedControllerMemberSelector
} from '../../../modules/selectors/controller'
import { selectedControllerDataSelector } from '../../../modules/selectors/app'

import { MEMBER_CHANGE_DATA, MEMBER_CHANGE_STATUS } from '../../../constants/forms'

import useSettingsClasses from '../styles'
import useStyles from './styles'

function ManageControllerTeam() {
  const classes = useStyles()
  const settingsClasses = useSettingsClasses()

  const [memberId, setMemberId] = useState(null)

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const selectedController = useSelector(selectedControllerDataSelector)
  const controllerMembersNext = useSelector(controllerMembersNextSelector)
  const controllerInvitesNext = useSelector(controllerInvitesNextSelector)

  const member = useSelector(updatedControllerMemberSelector)
  const { wasUpdated: memberWasUpdated, updatingType } = member

  const memberStatusWasUpdated = memberWasUpdated && updatingType === MEMBER_CHANGE_STATUS
  const memberDataWasUpdated = memberWasUpdated && updatingType === MEMBER_CHANGE_DATA

  const { isModalOpened, closeModalHandler, openModalHandler } = useModalManage({
    triggerClose: memberStatusWasUpdated
  })

  const changeMemberStatusHandler = useCallback(
    ({ id, isActive }) => {
      if (isActive) {
        setMemberId(id)
        openModalHandler()
      } else {
        dispatch(updateControllerMemberStatus(id, 'inactive'))
      }
    },
    [dispatch, openModalHandler]
  )

  const changeMemberRoleHandler = useCallback(
    ({ id, newRole }) => {
      dispatch(updateControllerMember(id, { role: newRole }))
    },
    [dispatch]
  )

  const deactivateHandler = useCallback(() => {
    dispatch(updateControllerMemberStatus(memberId, 'active'))
  }, [dispatch, memberId])

  const clearUpdateMemberHandler = useCallback(() => {
    dispatch(clearUpdateControllerMember())
  }, [dispatch])

  const resendInviteHandler = useCallback(
    id => {
      dispatch(resendControllerInvite(id))
    },
    [dispatch]
  )

  const deleteInviteHandler = useCallback(
    id => {
      dispatch(deleteControllerInvite(id))
    },
    [dispatch]
  )

  const params = useMemo(
    () => ({
      controller: selectedController.id
    }),
    [selectedController]
  )

  const loadMoreControllerMembers = useLoadPaginatedList({
    params,
    action: getControllerMembers,
    clearAction: clearControllerMembers,
    next: controllerMembersNext
  })

  const loadMoreControllerInvites = useLoadPaginatedList({
    params,
    action: getControllerInvites,
    clearAction: clearControllerInvites,
    next: controllerInvitesNext
  })

  useEffect(() => {
    if (memberStatusWasUpdated || memberDataWasUpdated) {
      clearUpdateMemberHandler()
    }
  }, [memberStatusWasUpdated, memberDataWasUpdated, clearUpdateMemberHandler])

  return (
    <>
      <h1 className={settingsClasses.title}>{t('manageTeam', { selectedControllerName: selectedController?.name })}</h1>
      <ContentSection title={t('Team members')}>
        <ContentRow
          title={t('Users and invites')}
          description={t(
            'Users will also need access to the ad platforms (e.g. Meta) directly to be able to access campaigns and reporting.'
          )}
          leftColumnChildren={<ControllerInviteCreate controller={selectedController} />}
        >
          <h3 className={classes.tableTitle}>{t('Users')}</h3>
          <ControllerMembersTable
            loadMore={loadMoreControllerMembers}
            onChangeMemberStatus={changeMemberStatusHandler}
            onChangeMemberRole={changeMemberRoleHandler}
          />
          <h3 className={classes.invitesTableTitle}>{t('Invites')}</h3>
          <ControllerInvitesTable
            onDeleteInvite={deleteInviteHandler}
            onResendInvite={resendInviteHandler}
            loadMore={loadMoreControllerInvites}
          />
        </ContentRow>
      </ContentSection>
      <ControllerPermissionsEdit />
      <ControllerMemberEdit />

      <DeactivateUserModal
        member={member}
        deactivateHandler={deactivateHandler}
        isModalOpened={isModalOpened}
        closeModalHandler={closeModalHandler}
        memberStatusWasUpdated={memberStatusWasUpdated}
        clearHandler={clearUpdateMemberHandler}
        accountName={`${selectedController?.name} controller.`}
      />
    </>
  )
}

export default ManageControllerTeam
