import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import UsersAudiencesSelect from './UsersAudiencesSelect'
import RadioBlock from '../../../../../../../components/Form/RadioBlock'
import AudiencesInfoSection from '../../../../../../ReusableFormFields/Audiences/AudiencesFields/AudiencesInfoSection'

import { getGoogleUsersList, clearGetGoogleUsersList } from '../../../../../../../modules/actions/googleConstants'
import { selectedAdAccountIdSelector } from '../../../../../../../modules/selectors/app'

import { AUDIENCE_OPTION } from '../fields'
import { OPTION_NO, OPTION_YES } from '../../../../../../../constants/forms'

const LineItemGoogleAudienceFields = ({ formik, adAccountId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const { values, setFieldValue } = formik

  useEffect(() => {
    dispatch(
      getGoogleUsersList({
        account: adAccountId || selectedAdAccountId,
        page_size: 50,
        eligible_for_display: true,
        membership_status: 2
      })
    )

    return () => {
      dispatch(clearGetGoogleUsersList())
    }
  }, [dispatch, adAccountId, selectedAdAccountId])

  return (
    <>
      <AudiencesInfoSection />
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_audience_option_yes"
        name={AUDIENCE_OPTION}
        value={OPTION_YES}
        selectedValue={values[AUDIENCE_OPTION]}
        label={t('Yes')}
      >
        <UsersAudiencesSelect formik={formik} />
      </RadioBlock>
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_audience_option_no"
        name={AUDIENCE_OPTION}
        value={OPTION_NO}
        selectedValue={values[AUDIENCE_OPTION]}
        label={t('No')}
      />
    </>
  )
}

export default LineItemGoogleAudienceFields
