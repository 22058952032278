import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import BudgetStep from './Steps/BudgetStep'
import StartTimeStep from './Steps/StartDateStep'
import EndDateStep from './Steps/EndDateStep'
import ScheduleStep from './Steps/ScheduleStep'
import GeolocationsStep from './Steps/GeolocationsStep'
import LanguagesStep from './Steps/LanguagesStep'
import CustomAudiencesStep from './Steps/CustomAudiencesStep'
import GenderStep from './Steps/GenderStep'
import AgesStep from './Steps/AgesStep'
import InterestsStep from './Steps/InterestsStep'
import VideoCategoriesStep from './Steps/VideoCategoriesStep'
import AccountCategoriesStep from './Steps/AccountCategoriesStep'
import HashtagsStep from './Steps/HashtagsStep'
import FrequencyStep from './Steps/FrequencyStep'
import EnableCommentsStep from './Steps/EnableCommentsStep'
import PixelStep from './Steps/PixelStep'
import BidPriceStep from './Steps/BidPriceStep'
import NameStep from './Steps/NameStep'

const useLineItemCreateSteps = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'useLineItemCreateSteps')

  const {
    campaignBuyingType,
    showBudgetStep,
    showFrequencyStep,
    showEnableCommentsStep,
    showPixelStep,
    campaignObjective,
    isBidTypeCustomPopulated,
    showBidPriceStep,
    isBidTypeStepInitiallyShown,
    isPixelStepInitiallyShown
  } = props

  return useMemo(
    () => [
      {
        component: <BudgetStep campaignBuyingType={campaignBuyingType} />,
        show: showBudgetStep
      },
      {
        component: <StartTimeStep />,
        show: true
      },
      {
        component: <EndDateStep />,
        show: true
      },
      {
        component: <ScheduleStep />,
        show: true
      },
      {
        component: <GeolocationsStep />,
        show: true
      },
      {
        component: <LanguagesStep />,
        show: true
      },
      {
        component: <CustomAudiencesStep />,
        show: true
      },
      {
        component: <GenderStep />,
        show: true
      },
      {
        component: <AgesStep />,
        show: true
      },
      {
        component: <InterestsStep />,
        show: true
      },
      {
        component: <VideoCategoriesStep />,
        show: true
      },
      {
        component: <AccountCategoriesStep />,
        show: true
      },
      {
        component: <HashtagsStep />,
        show: true
      },
      {
        component: <FrequencyStep />,
        show: showFrequencyStep
      },
      {
        component: <EnableCommentsStep />,
        show: showEnableCommentsStep
      },
      {
        component: <PixelStep />,
        show: showPixelStep || isPixelStepInitiallyShown
      },
      {
        component: (
          <BidPriceStep campaignObjective={campaignObjective} isBidTypeCustomPopulated={isBidTypeCustomPopulated} />
        ),
        show: showBidPriceStep || isBidTypeStepInitiallyShown
      },
      {
        component: <NameStep />,
        show: true
      }
    ],
    [
      campaignBuyingType,
      showBudgetStep,
      showFrequencyStep,
      showEnableCommentsStep,
      showPixelStep,
      campaignObjective,
      isBidTypeCustomPopulated,
      showBidPriceStep,
      isBidTypeStepInitiallyShown,
      isPixelStepInitiallyShown
    ]
  )
}

const propTypes = {
  campaignBuyingType: PropTypes.string.isRequired,
  showBudgetStep: PropTypes.bool.isRequired,
  showFrequencyStep: PropTypes.bool.isRequired,
  showEnableCommentsStep: PropTypes.bool.isRequired,
  showPixelStep: PropTypes.bool.isRequired,
  campaignObjective: PropTypes.string.isRequired,
  isBidTypeCustomPopulated: PropTypes.bool.isRequired,
  showBidPriceStep: PropTypes.bool.isRequired,
  isBidTypeStepInitiallyShown: PropTypes.bool.isRequired,
  isPixelStepInitiallyShown: PropTypes.bool.isRequired
}

export default useLineItemCreateSteps
