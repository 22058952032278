import { concat } from '../../helpers/common'

const MODULE_NAME = 'QUOTATIONS/'

// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const CREATE_QUOTATION = concat(MODULE_NAME, 'CREATE_QUOTATION')
export const CREATE_QUOTATION_SUCCESS = concat(MODULE_NAME, 'CREATE_QUOTATION_SUCCESS')
export const CREATE_QUOTATION_FAILURE = concat(MODULE_NAME, 'CREATE_QUOTATION_FAILURE')
export const CLEAR_CREATE_QUOTATION = concat(MODULE_NAME, 'CLEAR_CREATE_QUOTATION')

export const GET_QUOTATIONS = concat(MODULE_NAME, 'GET_QUOTATIONS')
export const GET_QUOTATIONS_SUCCESS = concat(MODULE_NAME, 'GET_QUOTATIONS_SUCCESS')
export const GET_QUOTATIONS_FAILURE = concat(MODULE_NAME, 'GET_QUOTATIONS_FAILURE')
export const CLEAR_GET_QUOTATIONS = concat(MODULE_NAME, 'CLEAR_GET_QUOTATIONS')

export const SEND_QUOTATION = concat(MODULE_NAME, 'SEND_QUOTATION')
export const SEND_QUOTATION_SUCCESS = concat(MODULE_NAME, 'SEND_QUOTATION_SUCCESS')
export const SEND_QUOTATION_FAILURE = concat(MODULE_NAME, 'SEND_QUOTATION_FAILURE')
export const CLEAR_SEND_QUOTATION = concat(MODULE_NAME, 'CLEAR_SEND_QUOTATION')

export const RESEND_QUOTATION = concat(MODULE_NAME, 'RESEND_QUOTATION')
export const RESEND_QUOTATION_SUCCESS = concat(MODULE_NAME, 'RESEND_QUOTATION_SUCCESS')
export const RESEND_QUOTATION_FAILURE = concat(MODULE_NAME, 'RESEND_QUOTATION_FAILURE')
export const CLEAR_RESEND_QUOTATION = concat(MODULE_NAME, 'CLEAR_RESEND_QUOTATION')

export const UPDATE_QUOTATION = concat(MODULE_NAME, 'UPDATE_QUOTATION')
export const UPDATE_QUOTATION_SUCCESS = concat(MODULE_NAME, 'UPDATE_QUOTATION_SUCCESS')
export const UPDATE_QUOTATION_FAILURE = concat(MODULE_NAME, 'UPDATE_QUOTATION_FAILURE')
export const CLEAR_UPDATE_QUOTATION = concat(MODULE_NAME, 'CLEAR_UPDATE_QUOTATION')

export const UPDATE_QUOTATIONS_STATUS_BULK = concat(MODULE_NAME, 'UPDATE_QUOTATIONS_STATUS_BULK')
export const UPDATE_QUOTATIONS_STATUS_BULK_SUCCESS = concat(MODULE_NAME, 'UPDATE_QUOTATIONS_STATUS_BULK_SUCCESS')
export const UPDATE_QUOTATIONS_STATUS_BULK_FAILURE = concat(MODULE_NAME, 'UPDATE_QUOTATIONS_STATUS_BULK_FAILURE')
export const CLEAR_UPDATE_QUOTATIONS_STATUS_BULK = concat(MODULE_NAME, 'CLEAR_UPDATE_QUOTATIONS_STATUS_BULK')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR
// Create quotation
export function createQuotation(id, data) {
  return { type: CREATE_QUOTATION, id, data }
}

export function createQuotationSuccess(data) {
  return { type: CREATE_QUOTATION_SUCCESS, data }
}

export function createQuotationFailure(error) {
  return { type: CREATE_QUOTATION_FAILURE, error }
}

export function clearCreateQuotation() {
  return { type: CLEAR_CREATE_QUOTATION }
}

// Get quotations
export function getQuotations(
  params,
  loadOptions = {
    shouldClearExistingState: false
  }
) {
  return { type: GET_QUOTATIONS, params, loadOptions }
}

export function getQuotationsSuccess(data) {
  return { type: GET_QUOTATIONS_SUCCESS, data }
}

export function getQuotationsFailure(error) {
  return { type: GET_QUOTATIONS_FAILURE, error }
}

export function clearGetQuotations() {
  return { type: CLEAR_GET_QUOTATIONS }
}

// Send quotation
export function sendQuotation(data, id) {
  return { type: SEND_QUOTATION, data, id }
}

export function sendQuotationSuccess(data) {
  return { type: SEND_QUOTATION_SUCCESS, data }
}

export function sendQuotationFailure(error) {
  return { type: SEND_QUOTATION_FAILURE, error }
}

export function clearSendQuotation() {
  return { type: CLEAR_SEND_QUOTATION }
}

// Resend quotation
export function resendQuotation(id) {
  return { type: RESEND_QUOTATION, id }
}

export function resendQuotationSuccess(data) {
  return { type: RESEND_QUOTATION_SUCCESS, data }
}

export function resendQuotationFailure(error) {
  return { type: RESEND_QUOTATION_FAILURE, error }
}

export function clearResendQuotation() {
  return { type: CLEAR_RESEND_QUOTATION }
}

// Update quotation
export function updateQuotation(id, data) {
  return { type: UPDATE_QUOTATION, id, data }
}

export function updateQuotationSuccess(data) {
  return { type: UPDATE_QUOTATION_SUCCESS, data }
}

export function updateQuotationFailure(error) {
  return { type: UPDATE_QUOTATION_FAILURE, error }
}

export function clearUpdateQuotation() {
  return { type: CLEAR_UPDATE_QUOTATION }
}

// Update quotations status bulk
export function updateQuotationsStatusBulk(data) {
  return { type: UPDATE_QUOTATIONS_STATUS_BULK, data }
}

export function updateQuotationsStatusBulkSuccess(data) {
  return { type: UPDATE_QUOTATIONS_STATUS_BULK_SUCCESS, data }
}

export function updateQuotationsStatusBulkFailure(error) {
  return { type: UPDATE_QUOTATIONS_STATUS_BULK_FAILURE, error }
}

export function clearUpdateQuotationsStatusBulk() {
  return { type: CLEAR_UPDATE_QUOTATIONS_STATUS_BULK }
}
