import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getIn } from 'formik'

import MultiSelectBox from '../../../../../../../features/components/Form/MultiSelectBox'

import { selectedControllerRelatedSelfAccountsListSelector } from '../../../../../../../modules/selectors/app'

import { ACCESS_ACCOUNTS } from '../../../../fields'

import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

const AccountsSection = ({ formik }) => {
  const { t } = useTranslation()

  const { values, setFieldValue, errors } = formik

  const drawerFormClasses = useDrawerFormStyles()

  const controllerSelfAccountsList = useSelector(selectedControllerRelatedSelfAccountsListSelector)

  return (
    <div className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>{t('Select accounts (optional)')}</h3>
      <MultiSelectBox
        placeholder={t('Select accounts')}
        name={ACCESS_ACCOUNTS}
        options={controllerSelfAccountsList}
        value={getIn(values, ACCESS_ACCOUNTS)}
        setFieldValue={setFieldValue}
        error={errors[ACCESS_ACCOUNTS]}
      />
    </div>
  )
}

export default AccountsSection
