import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createJsonFromQueryString } from '../../../../helpers/url'

import { getCategoryPages, clearCategoryPages } from '../../../../modules/actions/mediaOrders'
import { selectedControllerIdSelector, selectedSelfAccountSelector } from '../../../../modules/selectors/app'
import { categoryPagesNextSelector } from '../../../../modules/selectors/mediaOrders'

const useLoadCategoryPages = () => {
  const dispatch = useDispatch()

  const selectedSelfAccountId = useSelector(selectedSelfAccountSelector)
  const selectedSelfAccountControllerId = useSelector(selectedControllerIdSelector)
  const categoryPagesNext = useSelector(categoryPagesNextSelector)

  const loadMoreCategoryPagesHandler = useCallback(() => {
    dispatch(getCategoryPages(createJsonFromQueryString(`?${categoryPagesNext.split('?')[1]}`)))
  }, [dispatch, categoryPagesNext])

  useEffect(() => {
    dispatch(
      getCategoryPages({
        account: selectedSelfAccountId,
        controller: selectedSelfAccountControllerId,
        limit: 10
      })
    )
  }, [dispatch, selectedSelfAccountControllerId, selectedSelfAccountId])

  useEffect(
    () => () => {
      dispatch(clearCategoryPages())
    },
    [dispatch]
  )

  return useMemo(
    () => ({
      loadMoreCategoryPagesHandler
    }),
    [loadMoreCategoryPagesHandler]
  )
}

export default useLoadCategoryPages
