import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

// facebook pages
export const facebookPagesSelector = createSelector(
  ({ socialAccounts }) => socialAccounts.facebookPages.results,
  results => results
)

export const facebookPagesLoadingSelector = createSelector(
  ({ socialAccounts }) => socialAccounts.facebookPages.isLoading,
  isLoading => isLoading
)

export const facebookPagesWasLoadedSelector = createSelector(
  ({ socialAccounts }) => socialAccounts.facebookPages.wasLoaded,
  wasLoaded => wasLoaded
)

export const facebookPagesErrorSelector = createSelector(
  ({ socialAccounts }) => socialAccounts.facebookPages.error,
  error => error
)

// facebook page access tokens
export const facebookPageAccessTokensSelector = createSelector(
  ({ socialAccounts }) => socialAccounts.facebookPageAccessTokens,
  facebookPageAccessTokens => facebookPageAccessTokens
)
// instagram accounts
export const instagramAccountsSelector = createSelector(
  ({ socialAccounts }) => socialAccounts.instagramAccounts,
  instagramAccounts => instagramAccounts
)

// backed instagram accounts
export const backedInstagramAccountsSelector = createSelector(
  ({ socialAccounts }) => socialAccounts.backedInstagramAccounts,
  backedInstagramAccounts => backedInstagramAccounts
)

// accessible facebook pages which shared to user/self account
export const accessibleFacebookPagesSelector = createSelector(
  ({ socialAccounts }) => socialAccounts?.accessibleFacebookPages?.results,
  // use external_id as id, because BE uses id for its business logic, and we manipulate with external_id,
  // and rename id to internal_id (we need it to delete accessible facebook page)
  results => results?.map(page => ({ ...page, id: page.external_id, internal_id: page.id }))
)

export const accessibleFacebookPagesLoadingSelector = createSelector(
  ({ socialAccounts }) => socialAccounts.accessibleFacebookPages.isLoading,
  isLoading => isLoading
)

export const accessibleFacebookPagesErrorSelector = createSelector(
  ({ socialAccounts }) => socialAccounts.accessibleFacebookPages.error,
  error => error
)

export const accessibleFacebookPagesWasLoadedSelector = createSelector(
  ({ socialAccounts }) => socialAccounts.accessibleFacebookPages.wasLoaded,
  wasLoaded => wasLoaded
)

// add accessible facebook page
export const addAccessibleFacebookPagesIsLoadingSelector = createSelector(
  ({ socialAccounts }) => socialAccounts.addAccessibleFacebookPage.isLoading,
  isLoading => isLoading
)

export const addAccessibleFacebookPagesErrorSelector = createSelector(
  ({ socialAccounts }) => socialAccounts.addAccessibleFacebookPage.error,
  error => error
)

export const addAccessibleFacebookPagesWasCreatedSelector = createSelector(
  ({ socialAccounts }) => socialAccounts.addAccessibleFacebookPage.wasCreated,
  wasCreated => wasCreated
)

// delete accessible facebook page
export const deleteAccessibleFacebookPagesIdSelector = createSelector(
  ({ socialAccounts }) => socialAccounts.deleteAccessibleFacebookPage.id,
  isLoading => isLoading
)
export const deleteAccessibleFacebookPagesIsLoadingSelector = createSelector(
  ({ socialAccounts }) => socialAccounts.deleteAccessibleFacebookPage.isLoading,
  isLoading => isLoading
)

// Create facebook page access request
export const createFacebookPageAccessRequestSelector = createSelector(
  ({ socialAccounts }) => socialAccounts.createFacebookPageAccessRequest.results,
  results => results
)

export const createFacebookPageAccessRequestIsLoadingSelector = createSelector(
  ({ socialAccounts }) => socialAccounts.createFacebookPageAccessRequest.isLoading,
  isLoading => isLoading
)

export const createFacebookPageAccessRequestErrorSelector = createSelector(
  ({ socialAccounts }) => socialAccounts.createFacebookPageAccessRequest.error,
  error => error
)

export const createFacebookPageAccessRequestWasCreatedSelector = createSelector(
  ({ socialAccounts }) => socialAccounts.createFacebookPageAccessRequest.wasCreated,
  wasCreated => wasCreated
)
