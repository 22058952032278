import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import TextareaAutosize from 'react-textarea-autosize'

import Chip from '../../../../../components/Chip'

function MultipleOptionsPreview({ optionsList, optionsTitle }) {
  const { t } = useTranslation()

  if (!optionsList || !optionsList.length || !optionsList[0].text) {
    return null
  }

  if (optionsList.length === 1) {
    return (
      <p>
        {optionsTitle && <b>{t(optionsTitle)}</b>}
        <TextareaAutosize
          style={{ width: '100%', border: 'none', outline: 'none', resize: 'none', background: 'none' }}
          value={optionsList[0].text}
          disabled
        />
      </p>
    )
  }

  if (optionsList.length === 2) {
    return (
      <div>
        <p>
          {optionsTitle && <b>{t(optionsTitle)}</b>}
          <TextareaAutosize
            style={{ width: '100%', border: 'none', outline: 'none', resize: 'none', background: 'none' }}
            value={optionsList[0].text}
            disabled
          />
        </p>
        <Chip text={` + 1 ${t('another variation')}`} fontSize={16} />
      </div>
    )
  }

  return (
    <div>
      <p>
        {optionsTitle && <b>{t(optionsTitle)}</b>}
        <TextareaAutosize
          style={{ width: '100%', border: 'none', outline: 'none', resize: 'none', background: 'none' }}
          value={optionsList[0].text}
          disabled
        />
      </p>
      <Chip text={` + ${optionsList.length - 1} ${t('other variations')}`} fontSize={16} />
    </div>
  )
}

MultipleOptionsPreview.propTypes = {
  optionsList: PropTypes.array,
  optionsTitle: PropTypes.string
}

export default MultipleOptionsPreview
