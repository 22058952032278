import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: 10,
    width: 'fit-content'
  }
})

export default useStyles
