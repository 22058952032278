import { IMAGE, IMAGES } from '../../../MediaProductCreate/MediaProductCreateForm/fields'
import { ORDER } from '../../../../../../features/components/Form/SortableUploadImagesList/fields'
import { FILE_NAME, FILE_URL } from '../../../../../../constants/files'

export const formatProductImagesToBE = (initialValues, values) => {
  const initialImagesIds = initialValues[IMAGES]?.map(imageItem => imageItem.id) || []
  const formattedImages = values[IMAGES].filter(imageItem => !!imageItem[FILE_URL]).map(imageItem => ({
    [IMAGE]: imageItem[FILE_URL],
    [ORDER]: imageItem[ORDER],
    [FILE_NAME]: imageItem[FILE_NAME],
    // we need to send id only for images that were initially present in the form
    ...(!!imageItem.id && initialImagesIds.includes(imageItem.id) && { id: imageItem.id })
  }))

  return { [IMAGES]: formattedImages }
}
