import * as Yup from 'yup'

import {
  AD_LINK,
  DESCRIPTIONS_LIST,
  FB_PAGE_CHOICE,
  FILE_URL,
  FORMAT_OPTION,
  FORMAT_PRODUCT,
  HEADLINES_LIST,
  initialFileValues,
  initialLink,
  initialMediaValues,
  MEDIA_FILE_OPTION,
  MEDIA_TYPE,
  NAME,
  PIXEL_CHOICE,
  PRIMARY_TEXT_LIST,
  SINGLE_MEDIA_ITEM,
  STATUS
} from '../../../../AdForms/fields'
import { CALL_TO_ACTION, SHOP_NOW } from '../../../../../ReusableFormFields/CallToActionFields/fields'
import {
  descriptionValidation,
  headlineValidation,
  primaryTextValidation
} from '../../../../AdForms/AdFacebookCreate/AdFacebookCreateForm/validation'
import { MEDIA_FILE_IMAGE_USE_LIBRARY } from '../../../../../ReusableFormFields/AdFileUpload/fields'
import { urlValidation } from '../../../../../../features/validations/other'

export const getInitialValues = ({ ad = {}, suggestions = {}, pixelId, facebookPages, callToAction }) => ({
  [FORMAT_OPTION]: FORMAT_PRODUCT,
  [STATUS]: 'active',
  [NAME]: ad?.name || '',
  // primary text
  [PRIMARY_TEXT_LIST]: [{ text: suggestions.primary_text || '' }],
  [SINGLE_MEDIA_ITEM]: {
    // file
    ...initialFileValues,
    ...initialMediaValues,
    [MEDIA_TYPE]: 'image',
    [MEDIA_FILE_OPTION]: MEDIA_FILE_IMAGE_USE_LIBRARY,
    [FILE_URL]: ad?.image_urls?.[0] || '',
    [HEADLINES_LIST]: [{ text: suggestions.description || '' }],
    [DESCRIPTIONS_LIST]: [{ text: suggestions.headline || '' }],
    [CALL_TO_ACTION]: callToAction || SHOP_NOW,
    [AD_LINK]: ad?.link || initialLink
  },
  [FB_PAGE_CHOICE]: facebookPages[0]?.value, // preselect first facebook page
  [PIXEL_CHOICE]: pixelId
})

export const validationSchema = Yup.object({
  [NAME]: Yup.string().required('Name Required'),
  [PRIMARY_TEXT_LIST]: primaryTextValidation,
  [SINGLE_MEDIA_ITEM]: Yup.object({}).shape({
    [FILE_URL]: Yup.string().required('File Required'),
    [HEADLINES_LIST]: Yup.array().of(Yup.object({ text: headlineValidation })),
    [DESCRIPTIONS_LIST]: Yup.array().of(Yup.object({ text: descriptionValidation })),
    [AD_LINK]: urlValidation
  })
})
