import { concat } from '../../helpers/common'

const MODULE_NAME = 'DISCOUNT_PRESETS/'

// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_DISCOUNT_PRESETS = concat(MODULE_NAME, 'GET_DISCOUNT_PRESETS')
export const GET_DISCOUNT_PRESETS_SUCCESS = concat(MODULE_NAME, 'GET_DISCOUNT_PRESETS_SUCCESS')
export const GET_DISCOUNT_PRESETS_FAILURE = concat(MODULE_NAME, 'GET_DISCOUNT_PRESETS_FAILURE')
export const CLEAR_GET_DISCOUNT_PRESETS = concat(MODULE_NAME, 'CLEAR_GET_DISCOUNT_PRESETS')

export const CREATE_DISCOUNT_PRESET = concat(MODULE_NAME, 'CREATE_DISCOUNT_PRESET')
export const CREATE_DISCOUNT_PRESET_SUCCESS = concat(MODULE_NAME, 'CREATE_DISCOUNT_PRESET_SUCCESS')
export const CREATE_DISCOUNT_PRESET_FAILURE = concat(MODULE_NAME, 'CREATE_DISCOUNT_PRESET_FAILURE')
export const CLEAR_CREATE_DISCOUNT_PRESET = concat(MODULE_NAME, 'CLEAR_CREATE_DISCOUNT_PRESET')

export const UPDATE_DISCOUNT_PRESET = concat(MODULE_NAME, 'UPDATE_DISCOUNT_PRESET')
export const UPDATE_DISCOUNT_PRESET_SUCCESS = concat(MODULE_NAME, 'UPDATE_DISCOUNT_PRESET_SUCCESS')
export const UPDATE_DISCOUNT_PRESET_FAILURE = concat(MODULE_NAME, 'UPDATE_DISCOUNT_PRESET_FAILURE')
export const CLEAR_UPDATE_DISCOUNT_PRESET = concat(MODULE_NAME, 'CLEAR_UPDATE_DISCOUNT_PRESET')

export const GET_PRICE_CHANGE_PERIODS = concat(MODULE_NAME, 'GET_PRICE_CHANGE_PERIODS')
export const GET_PRICE_CHANGE_PERIODS_SUCCESS = concat(MODULE_NAME, 'GET_PRICE_CHANGE_PERIODS_SUCCESS')
export const GET_PRICE_CHANGE_PERIODS_FAILURE = concat(MODULE_NAME, 'GET_PRICE_CHANGE_PERIODS_FAILURE')
export const CLEAR_GET_PRICE_CHANGE_PERIODS = concat(MODULE_NAME, 'CLEAR_GET_PRICE_CHANGE_PERIODS')

export const CREATE_PRICE_CHANGE_PERIOD = concat(MODULE_NAME, 'CREATE_PRICE_CHANGE_PERIOD')
export const CREATE_PRICE_CHANGE_PERIOD_SUCCESS = concat(MODULE_NAME, 'CREATE_PRICE_CHANGE_PERIOD_SUCCESS')
export const CREATE_PRICE_CHANGE_PERIOD_FAILURE = concat(MODULE_NAME, 'CREATE_PRICE_CHANGE_PERIOD_FAILURE')
export const CLEAR_CREATE_PRICE_CHANGE_PERIOD = concat(MODULE_NAME, 'CLEAR_CREATE_PRICE_CHANGE_PERIOD')

export const UPDATE_PRICE_CHANGE_PERIOD = concat(MODULE_NAME, 'UPDATE_PRICE_CHANGE_PERIOD')
export const UPDATE_PRICE_CHANGE_PERIOD_SUCCESS = concat(MODULE_NAME, 'UPDATE_PRICE_CHANGE_PERIOD_SUCCESS')
export const UPDATE_PRICE_CHANGE_PERIOD_FAILURE = concat(MODULE_NAME, 'UPDATE_PRICE_CHANGE_PERIOD_FAILURE')
export const CLEAR_UPDATE_PRICE_CHANGE_PERIOD = concat(MODULE_NAME, 'CLEAR_UPDATE_PRICE_CHANGE_PERIOD')

export const DELETE_PRICE_CHANGE_PERIOD = concat(MODULE_NAME, 'DELETE_PRICE_CHANGE_PERIOD')
export const DELETE_PRICE_CHANGE_PERIOD_SUCCESS = concat(MODULE_NAME, 'DELETE_PRICE_CHANGE_PERIOD_SUCCESS')
export const DELETE_PRICE_CHANGE_PERIOD_FAILURE = concat(MODULE_NAME, 'DELETE_PRICE_CHANGE_PERIOD_FAILURE')
export const CLEAR_DELETE_PRICE_CHANGE_PERIOD = concat(MODULE_NAME, 'CLEAR_DELETE_PRICE_CHANGE_PERIOD')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// Get discount presets
export function getDiscountPresets(params) {
  return { type: GET_DISCOUNT_PRESETS, params }
}

export function getDiscountPresetsSuccess(data) {
  return { type: GET_DISCOUNT_PRESETS_SUCCESS, data }
}

export function getDiscountPresetsFailure(error) {
  return { type: GET_DISCOUNT_PRESETS_FAILURE, error }
}

export function clearGetDiscountPresets() {
  return { type: CLEAR_GET_DISCOUNT_PRESETS }
}

// Create discount preset
export function createDiscountPreset(data) {
  return { type: CREATE_DISCOUNT_PRESET, data }
}

export function createDiscountPresetSuccess(data) {
  return { type: CREATE_DISCOUNT_PRESET_SUCCESS, data }
}

export function createDiscountPresetFailure(error) {
  return { type: CREATE_DISCOUNT_PRESET_FAILURE, error }
}

export function clearCreateDiscountPreset() {
  return { type: CLEAR_CREATE_DISCOUNT_PRESET }
}

// Update discount preset
export function updateDiscountPreset(data, id) {
  return { type: UPDATE_DISCOUNT_PRESET, data, id }
}

export function updateDiscountPresetSuccess(data) {
  return { type: UPDATE_DISCOUNT_PRESET_SUCCESS, data }
}

export function updateDiscountPresetFailure(error) {
  return { type: UPDATE_DISCOUNT_PRESET_FAILURE, error }
}

export function clearUpdateDiscountPreset() {
  return { type: CLEAR_UPDATE_DISCOUNT_PRESET }
}

// Get price change periods
export function getPriceChangePeriods(params) {
  return { type: GET_PRICE_CHANGE_PERIODS, params }
}

export function getPriceChangePeriodsSuccess(data) {
  return { type: GET_PRICE_CHANGE_PERIODS_SUCCESS, data }
}

export function getPriceChangePeriodsFailure(error) {
  return { type: GET_PRICE_CHANGE_PERIODS_FAILURE, error }
}

export function clearGetPriceChangePeriods() {
  return { type: CLEAR_GET_PRICE_CHANGE_PERIODS }
}

// Create price change period
export function createPriceChangePeriod(data) {
  return { type: CREATE_PRICE_CHANGE_PERIOD, data }
}

export function createPriceChangePeriodSuccess(data) {
  return { type: CREATE_PRICE_CHANGE_PERIOD_SUCCESS, data }
}

export function createPriceChangePeriodFailure(error) {
  return { type: CREATE_PRICE_CHANGE_PERIOD_FAILURE, error }
}

export function clearCreatePriceChangePeriod() {
  return { type: CLEAR_CREATE_PRICE_CHANGE_PERIOD }
}

// Update price change period
export function updatePriceChangePeriod(data, id) {
  return { type: UPDATE_PRICE_CHANGE_PERIOD, data, id }
}

export function updatePriceChangePeriodSuccess(data) {
  return { type: UPDATE_PRICE_CHANGE_PERIOD_SUCCESS, data }
}

export function updatePriceChangePeriodFailure(error) {
  return { type: UPDATE_PRICE_CHANGE_PERIOD_FAILURE, error }
}

export function clearUpdatePriceChangePeriod() {
  return { type: CLEAR_UPDATE_PRICE_CHANGE_PERIOD }
}

// Delete price change period
export function deletePriceChangePeriod(id) {
  return { type: DELETE_PRICE_CHANGE_PERIOD, id }
}

export function deletePriceChangePeriodSuccess(id) {
  return { type: DELETE_PRICE_CHANGE_PERIOD_SUCCESS, id }
}

export function deletePriceChangePeriodFailure(error) {
  return { type: DELETE_PRICE_CHANGE_PERIOD_FAILURE, error }
}

export function clearDeletePriceChangePeriod() {
  return { type: CLEAR_DELETE_PRICE_CHANGE_PERIOD }
}
