import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format, toDate } from 'date-fns'

import DateRangePanel from '../../../pages/Settings/MediaProducts/ProductInventory/DateRangePanel'

import { clearGraphDatesPeriod, setGraphDatesPeriod } from '../../../modules/actions/mediaOrdersProducts'
import { graphDatesPeriodSelector } from '../../../modules/selectors/mediaOrdersProducts'
import { graphDatesOptionsListShort } from '../../../constants/selectLists/graphDatesList'

const initialData = []
export const useInventoryDatesSelector = ({
  rawData = initialData,
  dateLabelValueName = 'date',
  onDateRangeChange
}) => {
  const dispatch = useDispatch()

  const graphDatesPeriod = useSelector(graphDatesPeriodSelector)

  // sort data by date value (min > max)
  const sortedInventoryLimitedByDateRange = useMemo(
    () => [...rawData].sort((a, b) => new Date(a[dateLabelValueName]) - new Date(b[dateLabelValueName])),
    [dateLabelValueName, rawData]
  )

  const formattedLabels = useMemo(
    () =>
      sortedInventoryLimitedByDateRange.map(({ [dateLabelValueName]: dateStart }) => {
        const parsedDate = toDate(dateStart)
        return format(parsedDate, 'MMM ‘yy')
      }),
    [sortedInventoryLimitedByDateRange, dateLabelValueName]
  )

  const handleGraphDateChange = useCallback(
    value => {
      dispatch(setGraphDatesPeriod(value))
      onDateRangeChange && onDateRangeChange(value)
    },
    [dispatch, onDateRangeChange]
  )

  useEffect(() => {
    return () => {
      dispatch(clearGraphDatesPeriod())
    }
  }, [dispatch])

  return useMemo(() => {
    return {
      DatesSelector: (
        <DateRangePanel
          dateRangeList={graphDatesOptionsListShort}
          selectedDateRange={graphDatesPeriod}
          onSelectDateRange={handleGraphDateChange}
        />
      ),
      formattedLabels,
      sortedInventoryLimitedByDateRange
    }
  }, [formattedLabels, sortedInventoryLimitedByDateRange, handleGraphDateChange, graphDatesPeriod])
}
