import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

// Create quotation
export const createdQuotationSelector = createSelector(
  ({ quotations }) => quotations.createQuotation.data,
  data => data
)

export const createQuotationIsLoadingSelector = createSelector(
  ({ quotations }) => quotations.createQuotation.isLoading,
  isLoading => isLoading
)

export const createQuotationErrorSelector = createSelector(
  ({ quotations }) => quotations.createQuotation.error,
  error => error
)

export const quotationWasCreatedSelector = createSelector(
  ({ quotations }) => quotations.createQuotation.wasCreated,
  wasCreated => wasCreated
)

// Get quotations
export const quotationsSelector = createSelector(
  ({ quotations }) => quotations.quotations.results,
  results => results
)

export const quotationsTotalValueSelector = createSelector(
  ({ quotations }) => quotations.quotations.total_value,
  total_value => total_value
)

export const quotationsCountSelector = createSelector(
  ({ quotations }) => quotations.quotations.count,
  count => count
)

export const quotationsIsLoadingSelector = createSelector(
  ({ quotations }) => quotations.quotations.isLoading,
  isLoading => isLoading
)

export const quotationsErrorSelector = createSelector(
  ({ quotations }) => quotations.quotations.error,
  error => error
)

export const quotationsWasLoadedSelector = createSelector(
  ({ quotations }) => quotations.quotations.wasLoaded,
  wasLoaded => wasLoaded
)

export const quotationsNextSelector = createSelector(
  ({ quotations }) => quotations.quotations.paging.next,
  results => results
)

// Send quotations
export const sendQuotationSelector = createSelector(
  ({ quotations }) => quotations.sendQuotation.data,
  data => data
)

export const sendQuotationIsLoadingSelector = createSelector(
  ({ quotations }) => quotations.sendQuotation.isLoading,
  isLoading => isLoading
)

export const sendQuotationErrorSelector = createSelector(
  ({ quotations }) => quotations.sendQuotation.error,
  error => error
)

export const sendQuotationWasSentSelector = createSelector(
  ({ quotations }) => quotations.sendQuotation.wasCreated,
  wasCreated => wasCreated
)

// Resend quotation
export const resendQuotationSelector = createSelector(
  ({ quotations }) => quotations.resendQuotation.data,
  data => data
)

export const resendQuotationIsLoadingSelector = createSelector(
  ({ quotations }) => quotations.resendQuotation.isLoading,
  isLoading => isLoading
)

export const resendQuotationErrorSelector = createSelector(
  ({ quotations }) => quotations.resendQuotation.error,
  error => error
)

export const resendQuotationWasResentSelector = createSelector(
  ({ quotations }) => quotations.resendQuotation.wasUpdated,
  wasUpdated => wasUpdated
)

// Update quotation
export const updateQuotationSelector = createSelector(
  ({ quotations }) => quotations.updateQuotation.data,
  data => data
)

export const updateQuotationIsLoadingSelector = createSelector(
  ({ quotations }) => quotations.updateQuotation.isLoading,
  isLoading => isLoading
)

export const updateQuotationErrorSelector = createSelector(
  ({ quotations }) => quotations.updateQuotation.error,
  error => error
)

export const updateQuotationWasUpdatedSelector = createSelector(
  ({ quotations }) => quotations.updateQuotation.wasUpdated,
  wasUpdated => wasUpdated
)

// Update quotations status bulk
export const updateQuotationsStatusBulkSelector = createSelector(
  ({ quotations }) => quotations.updateQuotationsStatusBulk.data,
  data => data
)

export const updateQuotationsStatusBulkIsLoadingSelector = createSelector(
  ({ quotations }) => quotations.updateQuotationsStatusBulk.isLoading,
  isLoading => isLoading
)

export const updateQuotationsStatusBulkErrorSelector = createSelector(
  ({ quotations }) => quotations.updateQuotationsStatusBulk.error,
  error => error
)

export const quotationsStatusBulkWasUpdatedSelector = createSelector(
  ({ quotations }) => quotations.updateQuotationsStatusBulk.wasLoaded,
  wasUpdated => wasUpdated
)

export const quotationsStatusBulkWasDeletedSelector = createSelector(
  ({ quotations }) => quotations.updateQuotationsStatusBulk,
  // the update status is also using for deleting
  // to determine delete, separate selector is used
  ({ wasUpdated, data }) => wasUpdated && data?.status === 'deleted'
)
