import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import EditForm from '../../../../../../features/components/Forms/EditForm'
import AgeContent from './AgeContent/AgeContent'
import GenderContent from './GenderContent/GenderContent'
import InterestsContent from './InterestsContent/InterestsContent'
import CategoriesContent from './CategoriesContent/CategoriesContent'

import { getGenderType } from '../../typeHelpers'
import { formatList } from '../../fields'
import {
  formatAgeToBE,
  formatCategoriesToBE,
  formatGenderToBE,
  formatInterestsToBE
} from '../../../LineItemFacebookCreateOld/LineItemFacebookCreateFormOld/formatters'
import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'

import {
  lineItemUpdateErrorSelector,
  lineItemUpdateIsLoadingSelector,
  lineItemWasUpdatedSelector
} from '../../../../../../modules/selectors/lineItems'
import { campaignSelector } from '../../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'
import { clearUpdateLineItem, updateLineItem } from '../../../../../../modules/actions/lineItems'

import { OPTION_CUSTOM } from '../../../../../../constants/forms'
import {
  AGE,
  FAMILY_STATUSES,
  MAX_AGE_YEARS,
  MIN_AGE_YEARS,
  PARENT_CATEGORIES
} from '../../../../../ReusableFormFields/LineItemForms/fields'
import { GENDER, GENDER_OPTION } from '../../../../../ReusableFormFields/GenderFields/fields'
import { INTERESTS } from '../../../../../ReusableFormFields/InterestsFields/fields'

const AudienceSectionForm = ({ editItemData: lineItem, ...formProps }) => {
  const dispatch = useDispatch()

  const lineItemWasUpdated = useSelector(lineItemWasUpdatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const { objective: campaignObjective } = useSelector(campaignSelector)

  const handleSubmit = useCallback(
    values => {
      const updateLineItemData = {
        account: selectedAdAccountId,
        campaign_objective: campaignObjective,
        [AGE]: formatAgeToBE(OPTION_CUSTOM, values[MIN_AGE_YEARS], values[MAX_AGE_YEARS]),
        [GENDER]: formatGenderToBE(values[GENDER_OPTION]),
        [INTERESTS]: formatInterestsToBE(values[INTERESTS]),
        [FAMILY_STATUSES]: formatCategoriesToBE(values[PARENT_CATEGORIES])
      }

      dispatch(updateLineItem(updateLineItemData, lineItem.id))
    },
    [dispatch, lineItem.id, campaignObjective, selectedAdAccountId]
  )

  const initialValues = useMemo(
    () => ({
      // AGE
      [MIN_AGE_YEARS]: (lineItem[AGE] && lineItem[AGE].age_min) || '',
      [MAX_AGE_YEARS]: (lineItem[AGE] && lineItem[AGE].age_max) || '',

      // GENDER
      [GENDER_OPTION]: lineItem[GENDER] && getGenderType(lineItem[GENDER]),

      // INTERESTS
      [INTERESTS]: formatList(lineItem[INTERESTS]),

      // FAMILY STATUS
      [PARENT_CATEGORIES]: formatList(lineItem[FAMILY_STATUSES])
    }),
    [lineItem]
  )

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const { values, setFieldValue } = purifiedFormik

  const handleClearUpdateLineItem = useCallback(() => {
    dispatch(clearUpdateLineItem())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={lineItemWasUpdated}
      clearEditItem={handleClearUpdateLineItem}
      errorSelector={lineItemUpdateErrorSelector}
      isLoadingSelector={lineItemUpdateIsLoadingSelector}
      {...formProps}
    >
      <AgeContent formik={purifiedFormik} />
      <GenderContent formik={purifiedFormik} />
      <InterestsContent values={values} setFieldValue={setFieldValue} />
      <CategoriesContent values={values} setFieldValue={setFieldValue} />
    </EditForm>
  )
}

export default AudienceSectionForm
