import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  skeleton: {
    // Calculate the with of Skeleton grid item
    // ( 30% - 8px of margin )
    width: 'calc(100% / 3 - 8px)',
    marginRight: 8,
    marginBottom: 8,

    '& span': {
      '& span': {
        paddingTop: 'calc(100% - 16px)',
        lineHeight: 1.1
      }
    }
  }
})

export default useStyles
