import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

export const getGoogleUsersListSelector = createSelector(
  ({ googleConstants }) => googleConstants.getGoogleUsersList.results,
  results => results
)

export const getGoogleUsersListLoadingSelector = createSelector(
  ({ googleConstants }) => googleConstants.getGoogleUsersList.isLoading,
  isLoading => isLoading
)

export const getGoogleUsersListErrorSelector = createSelector(
  ({ googleConstants }) => googleConstants.getGoogleUsersList.error,
  error => error
)

export const getGoogleUsersListWasLoadedSelector = createSelector(
  ({ googleConstants }) => googleConstants.getGoogleUsersList.wasLoaded,
  wasLoaded => wasLoaded
)

export const googleGeolocationsSelector = createSelector(
  ({ googleConstants }) => googleConstants.googleGeolocations.results,
  results => results
)

export const googleGeolocationsWasLoadedSelector = createSelector(
  ({ googleConstants }) => googleConstants.googleGeolocations.wasLoaded,
  wasLoaded => wasLoaded
)

export const googleGeolocationsIsLoadingSelector = createSelector(
  ({ googleConstants }) => googleConstants.googleGeolocations.isLoading,
  isLoading => isLoading
)

export const googleLanguagesSelector = createSelector(
  ({ googleConstants }) => googleConstants.googleLanguages.results,
  results => [...results].sort((a, b) => a.name.localeCompare(b.name))
)

export const googleLanguagesIsLoadingSelector = createSelector(
  ({ googleConstants }) => googleConstants.googleLanguages.isLoading,
  isLoading => isLoading
)

export const googleLanguagesWasLoadedSelector = createSelector(
  ({ googleConstants }) => googleConstants.googleLanguages.wasLoaded,
  wasLoaded => wasLoaded
)
