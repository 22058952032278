import {
  formatAudiencesToBE,
  formatShowTargetToBE
} from '../../../../../../LineItemForms/LineItemFacebookCreateOld/LineItemFacebookCreateFormOld/formatters'

import {
  AUDIENCES_OPTION,
  CUSTOM_AUDIENCES,
  CUSTOM_AUDIENCES_EXCLUSION,
  CUSTOM_AUDIENCES_EXCLUSION_CHECKED
} from '../../../../../../../ReusableFormFields/CustomAudiencesFields/fields'
import {
  OUTCOME_AWARENESS,
  OUTCOME_ENGAGEMENT,
  OUTCOME_SALES,
  OUTCOME_TRAFFIC,
  REACH
} from '../../../../../../../../constants/campaigns'
import { BUDGET_LIFETIME } from './fields'
import { CUSTOM_EVENT_TYPE, PROMOTED_PIXEL } from '../../../../../../../ReusableFormFields/PromotedObjectFields/fields'
import {
  LOCATION_LIST,
  SHOW_COUNTRIES,
  SHOW_OPTION,
  SHOW_OPTION_LOCATION_LIST,
  SHOW_REGIONS
} from '../../../../../../../ReusableFormFields/GeolocationsFields/fields'
import { GEO_TARGETING } from '../../../../../../LineItemForms/LineItemFacebookEdit/fields'
import { OPTION_YES } from '../../../../../../../../constants/forms'
import { combineDateAndTime, formatDateWithTimeToBE } from '../../../../../../../../helpers/date'
import { formatStartDateToBE } from '../../../formatters'
import { END_DATE, START_DATE } from '../../../../../../../ReusableFormFields/StartEndDateRangeFields/fields'

// use selected audiences for line item name, separate with comas
const createNameFromCustomAudiences = customAudiences => {
  const audiencesNames = customAudiences.map(audience => audience.label)

  if (audiencesNames.length) {
    let finalName = ''

    for (let i = 0; i < audiencesNames.length; i++) {
      if (i > 0) {
        const newFinalName = finalName + `, ${audiencesNames[i]}`

        // limit final name to 400 characters, break loop if exeeds
        if (newFinalName.length > 400) break

        finalName = newFinalName
      } else {
        finalName += audiencesNames[i]
      }
    }

    return finalName
  } else {
    return 'Broad targeting'
  }
}

export const transformValuesToBE = ({ values, adAccountId, campaign }) => {
  const hasCustomAudiences = values[AUDIENCES_OPTION] === OPTION_YES

  const reusablePayload = {
    account: adAccountId,
    name: createNameFromCustomAudiences(hasCustomAudiences ? values[CUSTOM_AUDIENCES] : []),
    status: 'active',
    campaign_id: campaign.id,
    campaign_objective: campaign.objective,
    time_start: formatStartDateToBE(new Date(values[START_DATE])),
    time_stop: formatDateWithTimeToBE(combineDateAndTime(new Date(values[END_DATE]), '23:59')),
    ...(!campaign['budget_lifetime'] && { [BUDGET_LIFETIME]: values[BUDGET_LIFETIME] }),
    bidding_type: 'automatic',
    languages: [1001],
    ...(values[SHOW_OPTION] === SHOW_OPTION_LOCATION_LIST && { [LOCATION_LIST]: values[LOCATION_LIST] }),
    // GEO_TARGETING could be sent within LOCATION_LIST
    [GEO_TARGETING]: formatShowTargetToBE(values[SHOW_OPTION], values[SHOW_COUNTRIES], values[SHOW_REGIONS]),
    ...(values[AUDIENCES_OPTION] === OPTION_YES && { custom_audiences: formatAudiencesToBE(values[CUSTOM_AUDIENCES]) }),
    ...(values[CUSTOM_AUDIENCES_EXCLUSION_CHECKED] && {
      [CUSTOM_AUDIENCES_EXCLUSION]: formatAudiencesToBE(values[CUSTOM_AUDIENCES_EXCLUSION])
    }),
    billing_event: 'impressions',
    pacing: ['standard'],
    brand_safety_content_filter_levels: ['facebook_strict', 'an_strict'],
    excluded_publisher_categories: [
      'dating',
      'gambling',
      'debated_social_issues',
      'mature_audiences',
      'tragedy_and_conflict'
    ]
  }
  if (campaign.objective === REACH) {
    return {
      ...reusablePayload,
      frequency: { impressions: 2, event: 'impressions', days: 7 },
      objective: 'reach'
    }
  } else if (campaign.objective === OUTCOME_AWARENESS) {
    return {
      ...reusablePayload,
      objective: 'ad_recall_lift'
    }
  } else if (campaign.objective === OUTCOME_SALES) {
    return {
      ...reusablePayload,
      promoted_object: {
        ...(values[PROMOTED_PIXEL] && { pixel_id: values[PROMOTED_PIXEL] }),
        custom_event_type: values[CUSTOM_EVENT_TYPE]
      }
    }
  } else if (campaign.objective === OUTCOME_ENGAGEMENT) {
    return {
      ...reusablePayload,
      objective: 'post_engagement',
      destination_type: 'on_post'
    }
  } else if (campaign.objective === OUTCOME_TRAFFIC) {
    return {
      ...reusablePayload,
      objective: 'landing_page_views',
      destination_type: 'website'
    }
  } else {
    return reusablePayload
  }
}
