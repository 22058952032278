import { createStyles } from '../../../../../../../styles/helpers'

const useStyles = createStyles({
  filtersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16
  },
  tableWrapper: {
    flexGrow: 1
  }
})

export default useStyles
