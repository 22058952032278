import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import ScheduleSectionForm from '../../../../../ReusableFormSections/ScheduleSection/ScheduleSectionForm'

import { clearUpdateLineItem, updateLineItem } from '../../../../../../modules/actions/lineItems'
import { campaignSelector } from '../../../../../../modules/selectors/campaigns'
import {
  lineItemUpdateErrorSelector,
  lineItemUpdateIsLoadingSelector,
  lineItemWasUpdatedSelector
} from '../../../../../../modules/selectors/lineItems'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { formatScheduleDatesToBE, formatScheduleDatesToFE } from '../../../../../../features/formatters'
import { SCHEDULE } from '../../../../../ReusableFormSections/ScheduleSection/fields'

import useStyles from '../../../../../../styles/common/drawerForms'

const ScheduleSection = ({ editItemData: lineItem, ...formProps }) => {
  const classes = useStyles()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const lineItemWasUpdated = useSelector(lineItemWasUpdatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const { objective: campaignObjective } = useSelector(campaignSelector)

  const { schedule } = lineItem

  const scheduleExist = Object.keys(schedule).length

  const formattedSchedule = formatScheduleDatesToFE(schedule)

  const handleSubmit = useCallback(
    values => {
      const updateLineItemData = {
        account: selectedAdAccountId,
        campaign_objective: campaignObjective,
        schedule: formatScheduleDatesToBE(values[SCHEDULE])
      }

      dispatch(updateLineItem(updateLineItemData, lineItem.id))
    },
    [dispatch, lineItem.id, campaignObjective, selectedAdAccountId]
  )

  const handleClearUpdateLineItem = useCallback(() => {
    dispatch(clearUpdateLineItem())
  }, [dispatch])

  if (scheduleExist) {
    return (
      <ScheduleSectionForm
        schedule={formattedSchedule}
        handleSubmit={handleSubmit}
        clearEditItem={handleClearUpdateLineItem}
        successSubmit={lineItemWasUpdated}
        isLoadingSelector={lineItemUpdateIsLoadingSelector}
        errorSelector={lineItemUpdateErrorSelector}
        {...formProps}
      />
    )
  } else {
    return (
      <div className={classes.sectionPreview}>
        <p>{t('Ads set to run on all days at all times')}</p>
      </div>
    )
  }
}

export default ScheduleSection
