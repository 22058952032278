import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import FieldsSection from '../../../../../../features/components/Form/FieldsSection'
import Field from '../../../../../../components/Form/Field'
import FieldRow from '../../../../../../features/components/Form/FieldsSection/FieldRow'
import AccountsAccessFieldRow from '../../../reusableFormFields/AccountsAccessFieldRow'

import { formatOptionsList } from '../../../../../../features/formatters'
import { getAllowSelfAccounts } from '../../../helpers'

import { selfAccountCategoriesSelector } from '../../../../../../modules/selectors/selfAccounts'

import { BUYER } from '../../../../../../constants/permissions'
import { BRAND_CATEGORY } from '../../../fields'

const ControllerMemberEditFormContent = ({ formik, memberRole }) => {
  const { t } = useTranslation()

  const { values, setFieldValue } = formik

  const selfAccountCategories = useSelector(selfAccountCategoriesSelector)

  const allowSelfAccounts = getAllowSelfAccounts(memberRole)

  const formattedSelfAccountCategoriesOptions = useMemo(() => {
    return formatOptionsList({
      list: selfAccountCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [selfAccountCategories])

  const isBrandCategoryRequired = useMemo(() => {
    return memberRole === BUYER
  }, [memberRole])

  const clearBrandCategoryHandler = useCallback(() => {
    setFieldValue(BRAND_CATEGORY, '')
  }, [setFieldValue])

  return (
    <FieldsSection title="Controller member">
      <FieldRow title={t('Brand Category')}>
        <Field
          placeholder={t('Brand Category')}
          formik={formik}
          name={BRAND_CATEGORY}
          options={formattedSelfAccountCategoriesOptions}
          showClearInputIcon={values[BRAND_CATEGORY] && !isBrandCategoryRequired}
          onClearInput={clearBrandCategoryHandler}
        />
      </FieldRow>
      {allowSelfAccounts && <AccountsAccessFieldRow formik={formik} />}
    </FieldsSection>
  )
}

ControllerMemberEditFormContent.propTypes = {
  formik: PropTypes.object.isRequired,
  memberRole: PropTypes.string.isRequired
}

export default ControllerMemberEditFormContent
