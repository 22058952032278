import React, { useCallback, useEffect, useMemo, useState } from 'react'
import history from '../../../../history'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import DrawerHeadline from '../../../../components/Drawer/DrawerHeadline'
import DrawerHeadlineSkeleton from '../../../../components/Drawer/DrawerHeadline/Skeleton'
import AmendmentSubmit from '../../../../forms/Multiplatform/MediaOrderForms/AmendmentSubmit'
import PdfPageSkeleton from '../../../../features/components/Contract/ResponsiveContractPdfViewer/ContractPdfViewer/Skeleton/PdfPageSkeleton'

import useAmendmentData from '../../../MediaOrderSummary/MediaOrderSummaryContent/AmendmentsSection/AmendmentReview/useAmendmentData'

import { createJsonFromQueryString } from '../../../../helpers/url'

import { getAmendmentErrorSelector } from '../../../../modules/selectors/amendments'

import useStyles from './styles'
import useAmendmentSignStyles from '../styles'

function AmendmentSubmitContent({ onAmendmentSubmit }) {
  const classes = useStyles()
  const amendmentSignClasses = useAmendmentSignStyles()
  const { t } = useTranslation()

  // if token is expired we get an access error, also handle regular error:
  const [loadContractError, setLoadContractError] = useState()

  const getContractError = useSelector(getAmendmentErrorSelector)

  // token param is used to provide auth access to not registered users
  // itemId is contract/amendment contract id
  const { token, itemId } = createJsonFromQueryString(history.location.search, false)

  const tokenParams = useMemo(() => {
    return { token }
  }, [token])

  const { amendmentContract, isDataLoading, handleClearSelectedContract } = useAmendmentData(itemId, tokenParams)

  const handleSuccessCreate = useCallback(() => {
    handleClearSelectedContract()
    onAmendmentSubmit(amendmentContract)
  }, [amendmentContract, onAmendmentSubmit, handleClearSelectedContract])

  useEffect(() => {
    // set error to local state as 401 error logout and is cleaning up the redux store
    if (getContractError) {
      setLoadContractError(getContractError)
    }
  }, [getContractError, setLoadContractError])

  if (loadContractError) {
    return (
      <div className={amendmentSignClasses.infoMessageContainer}>
        <h2>{t('Sorry, that link has expired')}</h2>
        <p>{t('Please ask your account manager to resend the amendment')}</p>
      </div>
    )
  }

  return (
    <>
      <div className={classes.pdfContainer}>
        {isDataLoading ? (
          <>
            <DrawerHeadlineSkeleton hasTitle hasDescription />
            <PdfPageSkeleton />
          </>
        ) : (
          <>
            {/* Current page headline design is the same as Drawer headline design, so the Drawer component is used*/}
            <DrawerHeadline
              title={t('Review, sign')}
              description={t('Please review, sign and submit the booking amendment contract.')}
            />
            <AmendmentSubmit
              contract={amendmentContract}
              tokenParams={tokenParams}
              onSuccessSubmit={handleSuccessCreate}
            />
          </>
        )}
      </div>
    </>
  )
}

export default AmendmentSubmitContent
