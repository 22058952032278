import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { getCurrentUserSelector } from '../../modules/selectors/app'

export default function useManageUserSettings(selectedAdAccountId) {
  const { settings: userSettings } = useSelector(getCurrentUserSelector)

  const combineNewUserSettings = useCallback(
    (newSettings, settingId) => {
      const settingField = userSettings?.[settingId]

      if (selectedAdAccountId) {
        return {
          ...userSettings,
          [settingId]: {
            ...settingField,
            [selectedAdAccountId]: {
              ...settingField?.[selectedAdAccountId],
              ...newSettings
            }
          }
        }
      } else {
        // not selectedAdAccountId related settings
        return {
          ...userSettings,
          [settingId]: {
            ...settingField,
            ...newSettings
          }
        }
      }
    },
    [userSettings, selectedAdAccountId]
  )

  const getSavedSettings = useCallback(
    SETTING_ID => {
      const settingField = userSettings?.[SETTING_ID]
      if (selectedAdAccountId) {
        return settingField?.[selectedAdAccountId] || {}
      } else {
        // not selectedAdAccountId related settings
        return settingField || {}
      }
    },
    [userSettings, selectedAdAccountId]
  )

  return {
    combineNewUserSettings,
    getSavedSettings
  }
}
