import * as Yup from 'yup'

import { getAdClickThroughLink } from '../../helpers'

import { CLICK_THROUGH_LINK } from '../../../../fields'
import { urlValidation } from '../../../../../../../features/validations/other'

export const getInitialValues = ad => {
  const link = getAdClickThroughLink(ad)

  return {
    [CLICK_THROUGH_LINK]: link || ''
  }
}

export const validationSchema = Yup.object({
  [CLICK_THROUGH_LINK]: urlValidation
})
