import Color from 'color'
import { createUseStyles } from 'react-jss'

// this helper covers styles with theme option which will trigger to create new classes when theme is changed
// this is needed to trigger hooks for processing styles flipping for different text direction language
export const createStyles = (styles, options) =>
  createUseStyles(theme => {
    if (typeof styles === 'function') {
      // if styles is a function, it means it's a theme dependent styles
      return styles(theme)
    } else {
      return styles
    }
  }, options)

// default threshold is set to 60, because we want to consider #26B9FFFF as dark color
const defaultThreshold = 60
export const isColorDark = (hex, threshold = defaultThreshold) => {
  let lightness = Color(hex).lightness()

  return lightness <= threshold
}

// get random color
export const randomizeColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }

  return color
}
