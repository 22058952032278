import { combineDateAndTime, formatDateWithTimeToBE } from '../../../../../../../../helpers/date'
import { formatAudiencesToBE } from '../../../../../../LineItemForms/LineItemFacebookCreateOld/LineItemFacebookCreateFormOld/formatters'

import { END_DATE, START_DATE } from '../../../../../../../ReusableFormFields/StartEndDateRangeFields/fields'
import {
  AUDIENCES_OPTION,
  CUSTOM_AUDIENCES,
  CUSTOM_AUDIENCES_EXCLUSION,
  CUSTOM_AUDIENCES_EXCLUSION_CHECKED
} from '../../../../../../../ReusableFormFields/CustomAudiencesFields/fields'
import { OPTION_YES } from '../../../../../../../../constants/forms'

import { formatStartDateToBE } from '../../../formatters'

const createNameFromAudiences = customAudiences => {
  const audiencesNames = customAudiences.slice(0, 3).map(audience => audience.label)

  if (audiencesNames.length) {
    return audiencesNames.join(', ')
  } else {
    return 'Broad targeting'
  }
}

export const transformValuesToBE = ({ values, adAccountId, campaign, country, pixelId }) => {
  return {
    name: createNameFromAudiences(values[CUSTOM_AUDIENCES]),
    status: 'active',
    account: adAccountId,
    campaign_id: campaign.id,
    campaign_objective: campaign.objective,
    time_start: formatStartDateToBE(new Date(values[START_DATE])),
    time_stop: formatDateWithTimeToBE(combineDateAndTime(new Date(values[END_DATE]), '23:59')),
    pacing: ['standard'],
    geo_targeting: { countries: [country] },
    billing_event: 'impressions',
    gender: { male: true, female: true },
    age: { age_min: 18, age_max: 65 },
    ...(values[AUDIENCES_OPTION] === OPTION_YES && { custom_audiences: formatAudiencesToBE(values[CUSTOM_AUDIENCES]) }),
    ...(values[CUSTOM_AUDIENCES_EXCLUSION_CHECKED] && {
      custom_audiences_exclusions: formatAudiencesToBE(values[CUSTOM_AUDIENCES_EXCLUSION])
    }),
    promoted_object: { pixel_id: pixelId, custom_event_type: 'purchase' },
    brand_safety_content_filter_levels: ['facebook_strict', 'an_strict'],
    excluded_publisher_categories: [
      'dating',
      'gambling',
      'debated_social_issues',
      'mature_audiences',
      'tragedy_and_conflict'
    ]
  }
}
