import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import StatusBlock from '../../../../../../features/components/StatusBlock'

import { clearAsyncSession } from '../../../../../../modules/actions/app'
import { setFormStepPrevious } from '../../../../../../modules/actions/forms'
import { clearDuplicateCampaign, duplicateCampaignComplete } from '../../../../../../modules/actions/campaigns'
import { asyncSessionDataSelector } from '../../../../../../modules/selectors/app'

import { ERROR, PROCESS, SUCCESS } from '../../../../../../constants/other'

import useStyles from '../../../../../../styles/common/stepForms'
import useDrawerFormsStyles from '../../../../../../styles/common/drawerForms'

const CampaignDuplicationProgressStep = ({ onSuccessSubmit }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const classes = useStyles()
  const drawerFormsClasses = useDrawerFormsStyles()

  const asyncSessionData = useSelector(asyncSessionDataSelector)

  const duplicatedCampaign = asyncSessionData.item

  const status = useMemo(() => {
    switch (asyncSessionData.status) {
      case 'COMPLETED':
        return SUCCESS
      case 'FAILED':
        return ERROR
      default:
        return PROCESS
    }
  }, [asyncSessionData.status])

  useEffect(() => {
    let timer

    // close the form after async session COMPLETED
    if (asyncSessionData.status === 'COMPLETED') {
      // Update campaigns list
      dispatch(duplicateCampaignComplete(duplicatedCampaign))

      // Wait for success loader animation before closing the form
      timer = setTimeout(() => {
        onSuccessSubmit()
      }, 700)

      // If asyncSession FAILED - jump back to the submit step and clear submitted data
    } else if (asyncSessionData.status === 'FAILED') {
      // Wait for error loader animation
      timer = setTimeout(() => {
        dispatch(clearDuplicateCampaign())
        dispatch(clearAsyncSession())
        dispatch(setFormStepPrevious())
      }, 700)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [dispatch, onSuccessSubmit, asyncSessionData.status, duplicatedCampaign])

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Duplicate a campaign')}</div>
      <div className={classes.stepTitle}>{t('Please do not close this window while we duplicate your campaign')}</div>
      <div className={classes.stepBody}>
        <section className={drawerFormsClasses.section}>
          <StatusBlock description={t('Duplicating campaign')} status={status} />
        </section>
      </div>
    </>
  )
}

export default CampaignDuplicationProgressStep
