import React from 'react'
import PropTypes from 'prop-types'

import UploadedImagesInfoBlock from './UploadedImagesInfoBlock'
import FieldRow from '../../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import MultipleOnsiteMediaUpload from './MultipleOnsiteMediaUpload'
import { IMAGE_LIST } from '../../../fields'

const ImagesSection = ({ formik }) => {
  const { values } = formik

  return (
    <FieldRow title="Images" description={<UploadedImagesInfoBlock values={values} />}>
      <MultipleOnsiteMediaUpload formik={formik} FileItemPath={IMAGE_LIST} />
    </FieldRow>
  )
}

ImagesSection.propTypes = {
  formik: PropTypes.object.isRequired
}

export default ImagesSection
