import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import Field from '../../../../../../components/Form/Field'
import FieldRow from '../../../../../../features/components/Form/FieldsSection/FieldRow'
import FieldsSection from '../../../../../../features/components/Form/FieldsSection'
import CheckboxBlock from '../../../../../../features/components/Form/CheckboxBlock'
import Form from '../../../../../../components/Form'
import DrawerHeadline from '../../../../../../components/Drawer/DrawerHeadline'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'

import { clearCreateLineItem, createLineItem } from '../../../../../../modules/actions/lineItems'
import {
  lineItemCreateErrorSelector,
  lineItemCreateIsLoadingSelector,
  lineItemWasCreatedSelector,
  lineItemCreatedDataSelector
} from '../../../../../../modules/selectors/lineItems'
import { selectedAdAccountIdSelector, selectedAdAccountDataSelector } from '../../../../../../modules/selectors/app'

import { validationSchema } from './validation'
import { CPM_BID, initialValues, NAME, SET_CPM_BID } from './fields'
import { transformValuesToBE } from './formatters'

import { GOOGLE_PLATFORM } from '../../../../../../constants/selectLists/platformList'
import { LINE_ITEM_GOOGLE_ADS_CREATE, OPTION_NO, OPTION_YES } from '../../../../../../constants/forms'

import useStyles from './styles'
import useDrawerFormStyles from '../../../../../../styles/common/drawerForms'

function LineItemGoogleCreateMainForm({ onSuccessSubmit, campaignId }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const classes = useStyles()
  const drawerFormClasses = useDrawerFormStyles()

  const lineItemWasCreated = useSelector(lineItemWasCreatedSelector)
  const createdLineItemData = useSelector(lineItemCreatedDataSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const { currency_symbol } = useSelector(selectedAdAccountDataSelector)

  const clearCreateItemHandler = useCallback(() => {
    dispatch(clearCreateLineItem())
  }, [dispatch])

  const handleSuccessCreate = useCallback(() => {
    onSuccessSubmit(createdLineItemData)
  }, [createdLineItemData, onSuccessSubmit])

  const onSubmit = useCallback(
    values => {
      const formattedData = transformValuesToBE(values)

      dispatch(
        createLineItem(
          {
            ...formattedData,
            account: selectedAdAccountId,
            campaign_id: campaignId,
            status: 'active'
          },
          GOOGLE_PLATFORM
        )
      )
    },
    [dispatch, selectedAdAccountId, campaignId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const { values, setFieldValue } = purifiedFormik

  const handleCheck = () => {
    if (values[SET_CPM_BID] === OPTION_YES) {
      setFieldValue(SET_CPM_BID, OPTION_NO)
    } else {
      setFieldValue(SET_CPM_BID, OPTION_YES)
    }
  }

  return (
    <div className={drawerFormClasses.formContainer}>
      <DrawerHeadline
        title="Enter the ad group details"
        description="Please provide the following details for the new Google Ads ad group"
      />
      <Form
        formik={purifiedFormik}
        errorSelector={lineItemCreateErrorSelector}
        isLoadingSelector={lineItemCreateIsLoadingSelector}
        formName={LINE_ITEM_GOOGLE_ADS_CREATE}
        successSubmit={lineItemWasCreated}
        onSuccessSubmit={handleSuccessCreate}
        clearHandler={clearCreateItemHandler}
      >
        <FieldsSection title="Ad group details">
          <FieldRow title="Name" description="Ad group name">
            <Field formik={formik} name={NAME} placeholder={t('Ad group name')} autoComplete="off" />
          </FieldRow>
          <FieldRow
            title="Viewable CPM bid cap"
            description="Limit the cost per thousand impressions for the ads delivering in this ad group."
          >
            <CheckboxBlock
              checked={values[SET_CPM_BID] === OPTION_YES}
              title={t('Set a viewable CPM bid cap')}
              onCheck={handleCheck}
              id="fee-checkbox-block"
            >
              <Field
                placeholder={t('CPM bid')}
                name={CPM_BID}
                formik={formik}
                type="number"
                className={classes.cpmBidField}
                symbol={currency_symbol}
              />
            </CheckboxBlock>
          </FieldRow>
        </FieldsSection>
      </Form>
    </div>
  )
}

export default LineItemGoogleCreateMainForm
