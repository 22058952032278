import React from 'react'
import PropTypes from 'prop-types'

import ActionsDropdown from '../../../../../../features/components/ActionsDropdown'
import ActionsButtonsList from '../../../../../../features/components/ActionsButtonsList'

import { usePackageActions } from '../../hooks'
import { useMediaQuery } from 'react-responsive'
import { phonesDownSize } from '../../../../../../styles/const/breakpoints'

const MediaPackageActions = ({ itemData }) => {
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const { dropdownOptions } = usePackageActions(itemData)

  return isMobile ? (
    <ActionsButtonsList options={dropdownOptions.length ? dropdownOptions : null} />
  ) : (
    <ActionsDropdown options={dropdownOptions.length ? dropdownOptions : null} />
  )
}

MediaPackageActions.propTypes = {
  itemData: PropTypes.object.isRequired
}

export default MediaPackageActions
