import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import PhoneInput from '../../../../../components/Form/PhoneInput'

import { formatOptionsList } from '../../../../../features/formatters'

import { choicesCountriesSelector } from '../../../../../modules/selectors/choices'

import { COUNTRY, PHONE_NUMBER } from '../fields'

const UserPhoneField = ({ formik, onValueChange }) => {
  const countries = useSelector(choicesCountriesSelector)

  const { values } = formik

  const formattedCountries = formatOptionsList({
    list: countries,
    valueName: 'id',
    labelName: 'name'
  })

  const userCountry = values[COUNTRY]
  const userCountryIso2 = formattedCountries.find(item => item.id === userCountry)?.iso2?.toLowerCase()

  return (
    <PhoneInput
      onValueChange={onValueChange}
      name={PHONE_NUMBER}
      formik={formik}
      initialValue={formik.values[PHONE_NUMBER]}
      country={userCountryIso2}
    />
  )
}

UserPhoneField.propTypes = {
  formik: PropTypes.object.isRequired,
  onValueChange: PropTypes.func
}

export default UserPhoneField
