import React from 'react'
import PropTypes from 'prop-types'

import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

const SkeletonAdCardDetails = ({ showSubTitle = true }) => {
  const classes = useStyles()

  return (
    <div className={classes.details}>
      <h3 className={classes.title}>
        <Skeleton style={{ width: '70%' }} />
      </h3>
      {showSubTitle && (
        <h4 className={classes.subTitle}>
          <Skeleton style={{ width: '45%' }} />
        </h4>
      )}
    </div>
  )
}

SkeletonAdCardDetails.propTypes = {
  showSubTitle: PropTypes.bool
}

export default SkeletonAdCardDetails
