import React, { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'

import StepForm from '../../../../../../../../features/components/Forms/StepForm'
import CampaignDateStep from '../../Steps/CampaignDateStep'
import CustomAudiencesStep from '../../Steps/CustomAudiencesStep'
import UploadFilesStep from '../../Steps/UploadFilesStep'

import { usePurifiedFormik } from '../../../../../../../../hooks/formHooks/usePurifiedFormik'
import { useMediaOrderRemainingBudgetCalculation } from '../../../../../../../../forms/Multiplatform/MediaOrderForms/UploadAdCreative/useMediaOrderRemainingBudgetCalculation'

import {
  combinedCampaignsErrorSelector,
  combinedCampaignsWasLoadedSelector
} from '../../../../../../../../modules/selectors/combinedData'
import { selectedSelfAccountDataSelector } from '../../../../../../../../modules/selectors/app'

import { ONSITE_DIGITAL_FORMS, OPTION_YES } from '../../../../../../../../constants/forms'
import {
  AUDIENCES_OPTION,
  customAudiencesInitialValues
} from '../../../../../../../../forms/ReusableFormFields/CustomAudiencesFields/fields'
import { initialGoogleFile } from '../../../../../../../../forms/Google/AdForms/fields'
import { IMAGE_LIST } from '../../fields'
import { getValidationSchema } from './validation'
import { BUDGET_LIFETIME } from '../../fields'
import { END_DATE, START_DATE } from '../../../../../../../../forms/ReusableFormFields/StartEndDateRangeFields/fields'

const AudienceTargetingRoute = ({ FormRouteSelector, ...formProps }) => {
  const { currency_symbol: currencySymbol } = useSelector(selectedSelfAccountDataSelector)

  const remainingBudget = useMediaOrderRemainingBudgetCalculation()

  const onSubmit = useCallback(() => {
    // todo implement submit when needed
    console.log('submitting form...')
  }, [])

  const formik = useFormik({
    initialValues: {
      [START_DATE]: '',
      [END_DATE]: '',
      [BUDGET_LIFETIME]: '',
      ...customAudiencesInitialValues,
      [IMAGE_LIST]: [initialGoogleFile],
      [AUDIENCES_OPTION]: OPTION_YES
    },
    validationSchema: getValidationSchema({ remainingBudget, currencySymbol }),
    onSubmit,
    enableReinitialize: true
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const steps = useMemo(() => {
    return [
      {
        component: FormRouteSelector,
        show: true
      },
      {
        component: <CampaignDateStep />,
        show: true
      },
      {
        component: <CustomAudiencesStep />,
        show: true
      },
      {
        component: <UploadFilesStep />,
        show: true
      }
    ]
  }, [FormRouteSelector])

  // todo add when form will be ready clearSubmitHandler
  const clearCreateItemHandler = useCallback(() => {}, [])

  return (
    <StepForm
      {...formProps}
      errorSelector={combinedCampaignsErrorSelector}
      isLoadingSelector={combinedCampaignsWasLoadedSelector}
      steps={steps}
      formik={purifiedFormik}
      formName={ONSITE_DIGITAL_FORMS}
      clearSubmitHandler={clearCreateItemHandler}
    />
  )
}

export default AudienceTargetingRoute
