import { createStyles } from '../../../../../../../../../../../../styles/helpers'
import { phonesUp } from '../../../../../../../../../../../../styles/const/breakpoints'

export default createStyles({
  dateRange: {
    '& .rdrDay': {
      height: '46px',

      '& .rdrStartEdge + .rdrDayNumber .price': {
        // when date is selected and active reset price to white color
        color: 'white !important'
      }
    },
    '& .rdrDayToday .rdrDayNumber span:after': {
      // move up the today's date marker
      bottom: 0
    },
    [`@media screen and (${phonesUp})`]: {
      '& .rdrMonth': {
        width: 475 // this helps to make week cell wider and fit 9 characters i.e A$900,000
      }
    }
  }
})
