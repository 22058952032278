import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import PriceChangePeriodEditForm from './PriceChangePeriodEditForm'

import useManageFormsDrawer from '../../../../../hooks/formHooks/useManageFormsDrawer'

import {
  priceChangePeriodsSelector,
  priceChangePeriodsWasLoadedSelector
} from '../../../../../modules/selectors/discounts'

import { PRICE_CHANGE_PERIOD_EDIT } from '../../../../../constants/forms'

const PriceChangePeriodEdit = () => {
  const priceChangePeriods = useSelector(priceChangePeriodsSelector)
  const priceChangePeriodsWasLoaded = useSelector(priceChangePeriodsWasLoadedSelector)

  const { selectedEditItemId } = useManageFormsDrawer({
    formName: PRICE_CHANGE_PERIOD_EDIT
  })

  const editPriceChangePeriodData = useMemo(
    () => priceChangePeriods.find(item => item.id === Number(selectedEditItemId)),
    [priceChangePeriods, selectedEditItemId]
  )

  const isFormLoading = !editPriceChangePeriodData || !priceChangePeriodsWasLoaded

  return (
    <FormDrawerWrapper
      formName={PRICE_CHANGE_PERIOD_EDIT}
      isFormLoading={isFormLoading}
      title="Edit Discount Preset"
      showOpenButton={false}
    >
      <PriceChangePeriodEditForm editPriceChangePeriodData={editPriceChangePeriodData} />
    </FormDrawerWrapper>
  )
}

export default PriceChangePeriodEdit
