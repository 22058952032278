import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  pdfContainer: {
    margin: '0 auto',
    maxWidth: 800
  }
})

export default useStyles
