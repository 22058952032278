import { createStyles } from '../../../styles/helpers'

import { phonesDown } from '../../../styles/const/breakpoints'
import { SECTION_HEADER_CONTAINER_CLASSNAME } from '../../../constants/pdf'

const useStyles = createStyles({
  [SECTION_HEADER_CONTAINER_CLASSNAME]: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 48,
    marginBottom: 16
  },
  filters: {
    flexGrow: 1
  },
  panels: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  panel: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: '8px'
  },
  leftPanel: {
    extend: 'panel'
  },
  rightPanel: {
    extend: 'panel',
    justifyContent: 'flex-end',
    flex: 1
  },
  title: {
    fontSize: 18,
    marginRight: 20
  },

  [`@media screen and (${phonesDown})`]: {
    [SECTION_HEADER_CONTAINER_CLASSNAME]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 12,
      paddingBottom: 4,
      marginTop: 30
    },
    panels: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'stretch',
      marginTop: 12,
      rowGap: 10
    },
    leftPanel: {
      display: 'flex'
    },
    rightPanel: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      '& > button, & > div': {
        marginBottom: 8
      }
    }
  }
})

export default useStyles
