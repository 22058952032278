import React, { useMemo } from 'react'
import { getIn } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Portal } from 'react-portal'
import { useTranslation } from 'react-i18next'

import ProductSetupFields from './ProductSetupFields'
import Step from '../../../../../../../../features/components/Forms/StepForm/Step'
import SubProductsList from './SubProductsList'
import ProductVariables from './ProductSetupFields/ProductVariables'
import FieldsSection from '../../../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import ProductDetails from './ProductSetupFields/ProductDetails'

import useProductGroupSubProducts from './useProductGroupSubProducts'
import { useProductDelete } from '../../hooks/useProductDelete'
import useStepValidation from '../../../../../../../../features/components/Forms/StepForm/hooks/useStepValidation'

import { scrollToContainerTop } from '../../../../../../../../features/components/Forms/StepForm/helpers'

import { setProductPeriodsUpdate } from '../../../../../../../../modules/actions/mediaOrders'
import { productPeriodsUpdateSelector } from '../../../../../../../../modules/selectors/mediaOrders'

import { productSetupValidation } from '../../validation'
import {
  ALLOWED_CATEGORY_PRODUCTS_SELECTION,
  SELECTED_PACKAGES,
  FIXED_PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE
} from '../../fields'
import { PRODUCT_PERIODS_DATES } from '../../../../../fields'
import { PRINT } from '../../../../../../../../constants/mediaOrders'

function ProductSetupStep({
  formik,
  packageIndex,
  productValues,
  productItemPath,
  mediaProductsPath,
  stepsContainerEl,
  isActive,
  currency,
  productsPackageFlow
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { values, setFieldValue } = formik
  const { data: productData = {} } = productValues
  const { media_category, group: isProductGroup } = productData

  const variablesIds = useMemo(() => productValues.variables?.map(variable => variable.id), [productValues])

  const isProductPeriodsUpdate = useSelector(productPeriodsUpdateSelector)

  const selectedProductCategory = getIn(values, `${productItemPath}.media_category`)

  const productPeriodsDatesPath = `${productItemPath}.${PRODUCT_PERIODS_DATES}`
  const selectedPeriods = useMemo(() => {
    return getIn(values, productPeriodsDatesPath)
  }, [values, productPeriodsDatesPath])
  const selectedSubProducts = useProductGroupSubProducts({ selectedPeriods, isProductGroup })

  const handleStepChange = () => {
    setFieldValue(productItemPath, {
      ...productValues,
      data: {
        ...productValues?.data,
        selectedSubProducts // store selected sub products in the formik values to be able to show them in the order
        // cart
      },
      isSetupAllowed: false
    })
    // as current step is removed from DOM after product setup is over it doesn't use standard handleStepChange
    // so the Step forms stays on the same scroll position when new Step is presented, for this case we need to
    // scroll to top manually:
    const carouselContainer = stepsContainerEl?.current?.closest('div[data-content]')
    scrollToContainerTop(carouselContainer)

    // reset product periods update flag
    dispatch(setProductPeriodsUpdate(false))
  }

  const {
    errors: stepErrors,
    isStepTouched,
    handleContinue
  } = useStepValidation({
    stepValidation: productSetupValidation,
    stepValues: productValues,
    isActive,
    handleStepChange
  })

  const selectedProducts = getIn(values, mediaProductsPath)
  const handleProductDelete = useProductDelete({ mediaProductsPath, selectedProducts, setFieldValue })

  const handleStepBack = () => {
    // if user goes back from product setup step we need to allow product selection again
    // as it was disabled/step removed after product selection
    const allowedProductSelectionPath = `${SELECTED_PACKAGES}[${packageIndex}].${ALLOWED_CATEGORY_PRODUCTS_SELECTION}[${media_category}]`
    setFieldValue(allowedProductSelectionPath, true)
    // also need to remove selected product from formik values
    handleProductDelete(productData.id)
  }

  // don't show back button when user updates product periods(lands from the order summary)
  // remove back button for FIXED_PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE flow, as it doesn't have product
  // selection
  const hideBackButton =
    isProductPeriodsUpdate || productsPackageFlow === FIXED_PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE

  return (
    <Step
      handleContinue={handleContinue}
      btnText="Add to cart"
      onBackButtonClick={hideBackButton ? undefined : handleStepBack}
      showButton={isActive}
    >
      <ProductDetails productData={productValues?.data} currency={currency} />
      <FieldsSection title="Booking dates">
        <FieldRow
          title={selectedProductCategory === PRINT ? 'Publications' : 'Booking Periods'}
          description="Select one or more delivery date periods."
        >
          <ProductSetupFields
            formik={formik}
            productItemPath={productItemPath}
            isActive={isActive}
            currency={currency}
            showError={isStepTouched}
            productErrors={stepErrors}
          />
        </FieldRow>
      </FieldsSection>
      {!!variablesIds?.length && (
        <ProductVariables
          formik={formik}
          variablesIds={variablesIds}
          productItemPath={productItemPath}
          stepErrors={stepErrors}
          showError={isStepTouched}
        />
      )}
      {isProductGroup && (
        <Portal node={document && document.getElementById('formSideColumnPortal')}>
          <h4>{t('Media Assets')}</h4>
          <SubProductsList availableProducts={selectedSubProducts} />
        </Portal>
      )}
    </Step>
  )
}

export default ProductSetupStep
