import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from '../../../../components/Button'
import GenerateMediaOrderButton from './GenerateMediaOrderButton'
import SignContractModal from './SignContractModal'
import ResponsiveContractPdfViewer from '../../../../features/components/Contract/ResponsiveContractPdfViewer'

import { useContractSign } from './hooks/useContractSign'

import { deleteContractSignatureIsLoadingSelector } from '../../../../modules/selectors/contracts'
import { deleteContractSignature } from '../../../../modules/actions/contracts'

import { CONTRACT_SIGNED_STATUS } from '../../../../constants/contacts'

import useStyles from './styles'

function ContractMediaOrderGenerate({ contract = {}, tokenParams, onSuccessSubmit }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const [showSignContractModal, setShowSignContractModal] = useState(false)

  const signedContractButtonsRef = useRef(null)

  const deleteSignatureIsLoading = useSelector(deleteContractSignatureIsLoadingSelector)
  const { id: contractId } = contract
  const pdfFile = contract?.file
  const isContractSigned = contract?.status === CONTRACT_SIGNED_STATUS

  const signFormProps = useContractSign({ contractId, tokenParams })

  const openSignContractModal = useCallback(() => {
    if (!isContractSigned) {
      // show sign contract modal only if last page was clicked and contract hadn't been signed yet
      setShowSignContractModal(true)
    }
  }, [isContractSigned])

  const handleSignatureClear = useCallback(() => {
    dispatch(deleteContractSignature(contractId, tokenParams))
  }, [dispatch, contractId, tokenParams])

  // Don't show the submit button until the contract is signed. it is not possible to submit until
  // or during the signature deletion process
  const showSubmitButton = useMemo(
    () => isContractSigned && !deleteSignatureIsLoading,
    [isContractSigned, deleteSignatureIsLoading]
  )

  useEffect(() => {
    if (
      isContractSigned &&
      signedContractButtonsRef &&
      signedContractButtonsRef.current &&
      // check if scrollIntoView is supported by the user's browser before using it
      signedContractButtonsRef.current.scrollIntoView instanceof Function
    ) {
      // after contract is signed scroll down the page so that the Confirm and Book button is shown
      // otherwise it’s not clear that it needs to be pressed.
      signedContractButtonsRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isContractSigned])

  const SignedContractActions = () => {
    return showSubmitButton ? (
      <>
        <h4 className={classes.approveTitle}>{t('Ready to confirm your booking and reserve the inventory?')}</h4>
        <div className={classes.buttons} ref={signedContractButtonsRef}>
          <Button type="button" onClick={handleSignatureClear}>
            {t('Clear signature')}
          </Button>
          <GenerateMediaOrderButton
            contractId={contractId}
            tokenParams={tokenParams}
            onSuccessSubmit={onSuccessSubmit}
          />
        </div>
      </>
    ) : (
      <div className={classes.buttons} ref={signedContractButtonsRef}>
        <Button type="button" onClick={handleSignatureClear}>
          {t('Clear signature')}
        </Button>
      </div>
    )
  }

  return (
    <>
      <ResponsiveContractPdfViewer
        pdfFile={pdfFile}
        onLastPageClick={!isContractSigned ? openSignContractModal : null}
        showPagesCount={true}
      />
      {isContractSigned && <SignedContractActions />}
      {/*don't render conditionally with checking isContractSigned,
      because the clear handler will not be called on success sign*/}
      <SignContractModal
        showSignContractModal={showSignContractModal}
        onModalClose={() => setShowSignContractModal(false)}
        formProps={signFormProps}
      />
    </>
  )
}

ContractMediaOrderGenerate.propTypes = {
  contract: PropTypes.object,
  tokenParams: PropTypes.object,
  onSuccessSubmit: PropTypes.func
}

export default ContractMediaOrderGenerate
