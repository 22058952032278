import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'

import Form from '../../../../components/Form'
import RegisterFields from './RegisterFields'

import { useAuthButtonProps } from '../../useAuthButtonProps'

import {
  acceptedControllerInviteErrorSelector,
  acceptedControllerInviteIsLoadingSelector,
  controllerInviteWasAcceptedSelector
} from '../../../../modules/selectors/controller'
import { acceptControllerInvite, clearAcceptControllerInvite } from '../../../../modules/actions/controller'

import { initialValues, transformValuesToBE, validationSchema } from '../fields'

const AcceptControllerInviteForm = ({ token, userCountry }) => {
  const dispatch = useDispatch()

  const inviteWasAccepted = useSelector(controllerInviteWasAcceptedSelector)

  const buttonProps = useAuthButtonProps()

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values, userCountry, token)
      dispatch(acceptControllerInvite(transformedData, true))
    },
    [dispatch, token, userCountry]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  useEffect(() => {
    return () => {
      dispatch(clearAcceptControllerInvite())
    }
  }, [dispatch])

  return (
    <Form
      formName="acceptControllerInvite"
      formik={formik}
      method="post"
      successSubmit={inviteWasAccepted}
      isLoadingSelector={acceptedControllerInviteIsLoadingSelector}
      errorSelector={acceptedControllerInviteErrorSelector}
      submitText="Sign Up"
      buttonProps={buttonProps}
    >
      <RegisterFields formik={formik} />
    </Form>
  )
}

AcceptControllerInviteForm.propTypes = {
  token: PropTypes.string.isRequired,
  userCountry: PropTypes.string
}

export default AcceptControllerInviteForm
