import { formatStartDateToBE } from '../../../formatters'
import { combineDateAndTime, formatDateWithTimeToBE } from '../../../../../../../../helpers/date'
import { formatShowTargetToBE } from '../../../../../../LineItemForms/LineItemFacebookCreateOld/LineItemFacebookCreateFormOld/formatters'

import { END_DATE, START_DATE } from '../../../../../../../ReusableFormFields/StartEndDateRangeFields/fields'
import {
  LOCATION_LIST,
  SHOW_COUNTRIES,
  SHOW_OPTION,
  SHOW_OPTION_LOCATION_LIST,
  SHOW_OPTION_REGIONS,
  SHOW_REGIONS
} from '../../../../../../../ReusableFormFields/GeolocationsFields/fields'
import { GEO_TARGETING } from '../../../../../../LineItemForms/LineItemFacebookEdit/fields'

const createNameFromLocations = ({ showOption, regions, locationId, locationLists }) => {
  const regionsNames = regions.slice(0, 3).map(region => region.label)

  const selectedLocationList = locationLists.find(locationList => locationList.id === locationId)

  if (showOption === SHOW_OPTION_REGIONS) {
    return regionsNames.join(', ')
  } else if (showOption === SHOW_OPTION_LOCATION_LIST) {
    return selectedLocationList?.name
  } else {
    return 'Worldwide'
  }
}

export const transformValuesToBE = ({ values, adAccountId, campaign, locationLists }) => {
  const name = createNameFromLocations({
    showOption: values[SHOW_OPTION],
    regions: values[SHOW_REGIONS],
    locationId: values[LOCATION_LIST],
    locationLists
  })

  const timeStart = formatStartDateToBE(new Date(values[START_DATE]))
  const timeStop = formatDateWithTimeToBE(combineDateAndTime(new Date(values[END_DATE]), '23:59'))
  return {
    name: name,
    status: 'active',
    account: adAccountId,
    campaign_id: campaign.id,
    campaign_objective: campaign.objective,
    time_start: timeStart,
    time_stop: timeStop,
    ...(values[SHOW_OPTION] === SHOW_OPTION_LOCATION_LIST && { [LOCATION_LIST]: values[LOCATION_LIST] }),
    [GEO_TARGETING]: formatShowTargetToBE(values[SHOW_OPTION], values[SHOW_COUNTRIES], values[SHOW_REGIONS]),
    objective: 'clicks',
    pacing: ['standard'],
    billing_event: 'impressions',
    gender: { male: true, female: true },
    age: { age_min: 18, age_max: 65 },
    brand_safety_content_filter_levels: ['facebook_strict', 'an_strict'],
    excluded_publisher_categories: [
      'dating',
      'gambling',
      'debated_social_issues',
      'mature_audiences',
      'tragedy_and_conflict'
    ]
  }
}
