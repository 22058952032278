import * as Yup from 'yup'

import { urlValidation } from '../../../../../../features/validations/other'

import { LANDING_PAGE_URL } from '../../../fields'

export const getInitialValues = adData => ({
  [LANDING_PAGE_URL]: adData[LANDING_PAGE_URL] || ''
})

export const validationSchema = Yup.object({
  [LANDING_PAGE_URL]: urlValidation
})
