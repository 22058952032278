import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'

import FileDetailsPreview from '../../../../../../components/FileDetailsPreview'
import GoogleMediaUpload from '../GoogleMediaUpload'
import ErrorMessage from '../../../../../../components/Form/ErrorMessage'

import { FILE_TYPE, FILE_NAME, FILE_URL, initialGoogleFile } from '../../../fields'
import { imageRatioValidationSchema } from './imageRatioValiadtion'
import { ASSET_IMAGE_LIST } from '../fields'

function MultipleGoogleMediaUpload({ formik, FileItemPath, maxCount = 20, adAccountId }) {
  const { values, setValues, errors, touched } = formik

  const imagesList = getIn(values, FileItemPath)

  const imageListErrors = errors[ASSET_IMAGE_LIST]
  const imageListTouched = touched[ASSET_IMAGE_LIST]
  const error = imageListTouched && imageListErrors

  const handleDeleteItem = useCallback(
    index => {
      // delete item from carousel items array
      let newImagesList = [...imagesList]

      newImagesList.splice(index, 1)

      if (newImagesList.length === 0) {
        // if all files were removed add empty file to represent UploadAd component
        newImagesList.push(initialGoogleFile)
      }

      const updatedValues = { [FileItemPath]: newImagesList }

      setValues({ ...values, ...updatedValues })
    },
    [FileItemPath, imagesList, setValues, values]
  )

  // Calculate the index to manage based on the last element in the imagesList array.
  // If the last element has a non-empty value for the FILE_URL property, set indexToManage to the length of imagesList.
  // Otherwise, set it to the index of the last element in the array (currentIndex).
  // (We have object with empty values in initialValues)
  const currentIndex = imagesList.length - 1
  const lastElement = imagesList[currentIndex]
  const indexToManage = lastElement?.[FILE_URL] ? imagesList.length : currentIndex

  return (
    <>
      {imagesList.map((item, index) => {
        const itemPath = `${FileItemPath}[${index}]`
        const fileUrl = getIn(values, `${itemPath}.${FILE_URL}`)
        const fileName = getIn(values, `${itemPath}.${FILE_NAME}`)
        const filePreviewType = getIn(values, `${itemPath}.${FILE_TYPE}`)

        if (!fileUrl) {
          return null
        }

        return (
          <FileDetailsPreview
            key={index}
            previewUrl={fileUrl}
            fileName={fileName}
            filePreviewType={filePreviewType}
            isFileLoading={false}
            allowDelete={true}
            removeFile={() => handleDeleteItem(index)}
          />
        )
      })}

      {imagesList.length < maxCount && (
        <GoogleMediaUpload
          formik={formik}
          itemPath={`${FileItemPath}[${indexToManage}]`}
          fileValidationSchema={imageRatioValidationSchema}
          adAccountId={adAccountId}
          isDraggable={true}
        />
      )}

      {error && <ErrorMessage error={error} />}
    </>
  )
}

MultipleGoogleMediaUpload.propTypes = {
  formik: PropTypes.object.isRequired,
  FileItemPath: PropTypes.string.isRequired,
  maxCount: PropTypes.number,
  adAccountId: PropTypes.string
}

export default MultipleGoogleMediaUpload
