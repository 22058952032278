import React from 'react'
import Skeleton from 'react-loading-skeleton'

import TabsHeader from '../../../features/components/ContentTabs/TabsHeader'
import SkeletonContentTabs from '../../../features/components/ContentTabs/Skeleton'

const SkeletonMediaOrderSummaryContent = () => {
  return (
    <>
      <TabsHeader>
        <h1>
          <Skeleton style={{ maxWidth: 360 }} />
        </h1>
      </TabsHeader>
      <SkeletonContentTabs />
    </>
  )
}

export default SkeletonMediaOrderSummaryContent
