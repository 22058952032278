import * as Yup from 'yup'

import { CLICK_THROUGH_LINK } from '../../../../fields'
import { urlValidation } from '../../../../../../../features/validations/other'

export const getInitialValues = assetGroup => {
  return {
    [CLICK_THROUGH_LINK]: assetGroup[CLICK_THROUGH_LINK][0] || ''
  }
}

export const validationSchema = Yup.object({
  [CLICK_THROUGH_LINK]: urlValidation
})
