import { capitalizeFirstLetter } from '../../helpers/common'

const types = [
  'add_payment_info',
  'add_to_cart',
  'add_to_wishlist',
  'complete_registration',
  'content_view',
  'initiated_checkout',
  'lead',
  'purchase',
  'search',
  'contact',
  'customize_product',
  'donate',
  'find_location',
  'schedule',
  'start_trial',
  'submit_application',
  'subscribe',
  'listing_interaction',
  'facebook_selected',
  'other'
]

export const formatCustomEventLabel = type => {
  return type
    .split('_')
    .map(word => capitalizeFirstLetter(word))
    .join(' ')
}

const customEventTypes = types.map(type => ({
  value: type,
  label: formatCustomEventLabel(type)
}))

export default customEventTypes
