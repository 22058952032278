import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import PageSection from '../../PageSection'
import BigBarGraph from '../../Graphs/BigBarGraph'

import { capitalizeFirstLetter } from '../../../../helpers/common'
import { getReusableTooltipCallbackOptions } from '../helpers'

import { lilac } from '../../../../styles/const/colors'
import useStyles from '../styles'

const GenderGraphSection = ({ rawGraphData, showAdvancedMetrics, currencySymbol }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const genderGraphData = useMemo(() => rawGraphData && rawGraphData.map(data => data.impressions), [rawGraphData])
  const genderGraphLabels = useMemo(
    () => rawGraphData && rawGraphData.map(data => capitalizeFirstLetter(data.breakdown)),
    [rawGraphData]
  )

  const tooltipCallbackOptions = useMemo(
    () => getReusableTooltipCallbackOptions({ rawGraphData, showAdvancedMetrics, currencySymbol }),
    [rawGraphData, showAdvancedMetrics, currencySymbol]
  )

  return (
    <PageSection className={classes.sectionContainer}>
      <div className={classes.headingContainer}>
        <div className={classes.title}>{t('Gender')}</div>
      </div>
      <BigBarGraph
        className={classes.graphWrapper}
        data={genderGraphData}
        labels={genderGraphLabels}
        graphColor={lilac}
        format="integer"
        tooltipCallbackOptions={tooltipCallbackOptions}
      />
    </PageSection>
  )
}

GenderGraphSection.propTypes = {
  rawGraphData: PropTypes.array.isRequired,
  showAdvancedMetrics: PropTypes.bool,
  currencySymbol: PropTypes.string
}

export default GenderGraphSection
