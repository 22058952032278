import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clearLineItems, getLineItems } from '../../../../../modules/actions/lineItems'
import { lineItemsErrorSelector, lineItemsWasLoadedSelector } from '../../../../../modules/selectors/lineItems'
import { mediaOrderUploadCreativeDataSelector } from '../../../../../modules/selectors/mediaOrders'

import { LIFETIME_DATE_PRESET } from '../../../../../constants/selectLists/listItemFiltersList'
import { FACEBOOK_PLATFORM } from '../../../../../constants/selectLists/platformList'

export default function useLoadLineItems({ adAccountId, isExistingCampaignFlow }) {
  const dispatch = useDispatch()

  const lineItemsWasLoaded = useSelector(lineItemsWasLoadedSelector)
  const lineItemsError = useSelector(lineItemsErrorSelector)
  const { campaign } = useSelector(mediaOrderUploadCreativeDataSelector)

  const { id: campaignId, objective: campaignObjective } = campaign

  const lineItemsDidLoading = lineItemsWasLoaded || lineItemsError

  const isLineItemsLoading = isExistingCampaignFlow && !lineItemsDidLoading

  useEffect(() => {
    if (isExistingCampaignFlow) {
      dispatch(
        getLineItems(
          {
            account: adAccountId,
            campaign_id: campaignId,
            ...(campaignObjective && { campaign_objective: campaignObjective }),
            date_preset: LIFETIME_DATE_PRESET
          },
          FACEBOOK_PLATFORM
        )
      )
    }
  }, [dispatch, isExistingCampaignFlow, adAccountId, campaignId, campaignObjective])

  useEffect(
    () => () => {
      dispatch(clearLineItems())
    },
    [dispatch]
  )

  return useMemo(
    () => ({
      isLineItemsLoading
    }),
    [isLineItemsLoading]
  )
}
