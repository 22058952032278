import { createStyles } from '../../../styles/helpers'

import { facebookBlue, greyOutline, instagramOrange, mediumGrey, textGrey } from '../../../styles/const/colors'
import { transitionShort } from '../../../styles/const/common'
import { bigRadioLeftPadding } from '../Radio/style'

const getTitleColor = color => {
  switch (color) {
    case 'blue':
      return facebookBlue
    case 'orange':
      return instagramOrange
    default:
      return color || textGrey
  }
}

const useStyles = createStyles(theme => ({
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    minHeight: ({ isLarge }) => (isLarge ? 25 : 20)
  },
  checkboxInput: {
    width: 0,
    height: 0,
    opacity: 0,
    position: 'absolute',
    '&:checked + label $checkmark': {
      backgroundColor: theme.brandPrimary
    },
    '&:not(:checked) + label $checkmark': {
      height: ({ isPartiallyChecked }) => isPartiallyChecked && 2,
      backgroundColor: ({ isPartiallyChecked }) => isPartiallyChecked && theme.brandPrimary
    },
    '& + label $checkboxContainer': {
      transition: transitionShort
    },
    '&:hover:not([disabled]) + label $checkboxContainer': {
      borderColor: theme.brandPrimaryHover
    }
  },
  checkboxLabel: {
    display: 'block',
    minWidth: ({ isLarge }) => (isLarge ? 20 : 16),
    minHeight: ({ isLarge }) => (isLarge ? 20 : 16),
    cursor: 'pointer'
  },
  checkboxContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    border: `1px solid ${greyOutline}`,
    backgroundColor: 'white',
    top: ({ centerVertically }) => (centerVertically ? '50%' : 0),
    transform: ({ centerVertically }) => centerVertically && 'translateY(-50%)',
    width: ({ isLarge }) => (isLarge ? 25 : 20),
    height: ({ isLarge }) => (isLarge ? 25 : 20),
    cursor: 'pointer'
  },
  checkmark: {
    width: ({ isLarge }) => (isLarge ? 15 : 12),
    height: ({ isLarge }) => (isLarge ? 15 : 12),
    backgroundColor: 'transparent',
    transition: transitionShort
  },
  checkboxTitle: {
    paddingLeft: ({ isLarge }) => (isLarge ? bigRadioLeftPadding : 28),
    paddingTop: ({ isLarge }) => (isLarge ? 5 : 2),
    paddingBottom: ({ isLarge }) => (isLarge ? 4 : 2),
    fontSize: ({ isLarge }) => (isLarge ? 14 : 'inherit'),
    lineHeight: 1,
    fontWeight: ({ isLarge }) => (isLarge ? 600 : 400),
    color: ({ titleColor, disabled }) => (disabled ? mediumGrey : getTitleColor(titleColor))
  },
  checkboxBody: {
    paddingLeft: ({ isLarge }) => (isLarge ? bigRadioLeftPadding : 24),
    paddingTop: 4,
    fontSize: 14,
    '& p': {
      marginTop: 12
    },
    '& > *:first-child': {
      marginTop: 0
    }
  }
}))

export default useStyles
