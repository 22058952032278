import React from 'react'
import PropTypes from 'prop-types'
import AuthCode from 'react-auth-code-input'

import ErrorMessage from '../ErrorMessage'

import useStyles from './styles'

const AuthCodeInput = ({ onChange, touched, error }) => {
  const classes = useStyles()

  const showError = touched && error

  return (
    <>
      <AuthCode
        containerClassName={classes.codeContainer}
        inputClassName={classes.codeInput}
        onChange={onChange}
        allowedCharacters="numeric"
      />
      {showError && <ErrorMessage error={error} />}
    </>
  )
}

AuthCodeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.string
}

export default AuthCodeInput
