import { createStyles } from '../../../../styles/helpers'
import { lightGrey } from '../../../../styles/const/colors'

const useStyles = createStyles({
  shoppingCart: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative'
  },
  shoppingCartIcon: {
    fontSize: '26px'
  },
  quantity: {
    top: '50%',
    left: '50%',
    color: 'white',
    position: 'absolute',
    fontSize: '8px',
    fontWeight: 700,
    transform: 'translate(-50%, -9px)',
    pointerEvents: 'none'
  },
  packageMissing: {
    margin: '50px auto',
    width: '80%'
  },
  mediaOrderContent: {
    backgroundColor: [lightGrey, '!important']
  }
})

export default useStyles
