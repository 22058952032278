import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { TABLE_COL_CLASSNAME } from '../../../../constants/pdf'
import { SKIP_CELL_FOR_COPY } from '../../constants'

import useColumnStyles from '../../TableRows/TableRowWrapper/Col/styles'
import useStyles from './styles'

const HeaderCell = React.memo(({ className, headClassName, header, attributes, style, onClick, children }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const columnClasses = useColumnStyles()
  const columnClassName = columnClasses[TABLE_COL_CLASSNAME]

  return (
    <div
      className={classnames(columnClassName, headClassName, className, {
        [classes.isClickable]: Boolean(onClick)
      })}
      onClick={onClick}
      style={style}
      {...attributes}
    >
      {typeof header === 'function' ? header() : t(header)}
      {children}
    </div>
  )
})

HeaderCell.propTypes = {
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  headClassName: PropTypes.string,
  onClick: PropTypes.func,
  attributes: PropTypes.shape({
    // allow to add HTML elements attributes to cell
    [SKIP_CELL_FOR_COPY]: PropTypes.bool // allow to skip cell for copy
  }),
  style: PropTypes.object
}

export default HeaderCell
