import { useMemo } from 'react'
import { AD_FORM, AUDIENCES_FORM, CAMPAIGN_FORM, LINE_ITEM_FORM } from './index'

const useFormProgressItems = ({ activatedForm }) => {
  return useMemo(
    () => [
      {
        label: 'Choose campaign type',
        isActive: activatedForm === CAMPAIGN_FORM,
        isSuccess: activatedForm === LINE_ITEM_FORM || activatedForm === AUDIENCES_FORM || activatedForm === AD_FORM
      },
      {
        label: 'Set campaign dates',
        isActive: activatedForm === LINE_ITEM_FORM,
        isSuccess: activatedForm === AUDIENCES_FORM || activatedForm === AD_FORM
      },
      {
        label: 'Select audience',
        isActive: activatedForm === AUDIENCES_FORM,
        isSuccess: activatedForm === AD_FORM
      },
      {
        label: 'Upload image',
        isActive: activatedForm === AD_FORM,
        isSuccess: false
      }
    ],
    [activatedForm]
  )
}

export default useFormProgressItems
