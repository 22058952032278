import { createStyles } from '../../../../../styles/helpers'

import { greyOutline, navy } from '../../../../../styles/const/colors'
import { zIndex2, zIndex3 } from '../../../../../styles/const/common'
import { cubicBezier } from '../../../../../styles/const/animation'

const squareSlideSize = 70
const edgeSize = 25

const useStyles = createStyles(theme => ({
  edge: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    cursor: 'pointer',
    height: '100%',
    zIndex: zIndex3,
    width: 25,
    '&:hover': {
      '& $arrowLeft, $arrowRight': {
        opacity: 1
      }
    }
  },
  leftEdge: {
    left: 0,
    justifyContent: 'flex-start'
  },
  rightEdge: {
    left: `calc(${squareSlideSize}px - ${edgeSize}px)`,
    justifyContent: 'flex-end'
  },
  arrowLeft: {
    transition: `opacity .3s ${cubicBezier}`,
    opacity: 0,
    width: 14,
    height: 14,
    cursor: 'pointer',
    color: navy,
    zIndex: zIndex2
  },
  arrowRight: {
    opacity: 0,
    width: 14,
    height: 14,
    cursor: 'pointer',
    color: navy,
    zIndex: zIndex2
  },
  iconDisabled: {
    color: greyOutline
  },
  imagesContainer: {
    width: squareSlideSize
  },
  slider: {
    position: 'relative'
  },
  thumbnail: {
    minWidth: squareSlideSize,
    width: squareSlideSize,
    height: squareSlideSize,
    objectFit: 'cover',
    borderRadius: 2
  },
  dots: {
    display: 'flex',
    gap: 3,
    justifyContent: 'center',
    marginTop: 4
  },
  dot: {
    '-webkit-transition': 'background-color .5s, width .5s',
    '-o-transition': 'background-color .5s, width .5s',
    transition: 'background-color .5s, width .5s',

    display: 'inline-block',
    border: 'none',
    width: 5,
    height: 5,
    background: '#d9d9d9',
    borderRadius: '50%',
    cursor: 'pointer',
    '&.active': {
      background: theme.brandPrimary,
      borderRadius: 9,
      width: 10
    },
    ':focus': {
      outline: 'none'
    }
  }
}))

export default useStyles
