import { createStyles } from '../../../../../../../styles/helpers'

const useStyles = createStyles({
  filters: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  graphsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
    gap: 16
  }
})

export default useStyles
