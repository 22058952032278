import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'

import Form from '../../../../../../components/Form'
import MemberPermissionsFields from './MemberPermissionsFields'

import {
  clearUpdateMember,
  updateSelfAccountMember,
  updateSelfAccountMemberSuccess
} from '../../../../../../modules/actions/selfAccountTeam'
import {
  updateMemberWasUpdatedSelector,
  updateMemberIsLoadingSelector,
  updateMemberErrorSelector
} from '../../../../../../modules/selectors/selfAccountTeam'

import { transformValuesToBE } from './formatters'
import { isEqual } from '../../../../../../helpers/common'

import { SELF_ACCOUNT_PERMISSIONS_EDIT } from '../../../../../../constants/forms'
import { getInitialValues } from './fields'

const MemberPermissionsEditForm = ({ memberData = {} }) => {
  const dispatch = useDispatch()

  const { id: memberId } = memberData

  const memberWasUpdated = useSelector(updateMemberWasUpdatedSelector)

  const initialValues = useMemo(() => getInitialValues(memberData), [memberData])

  const clearUpdateCategoryPageHandler = useCallback(() => {
    dispatch(clearUpdateMember())
  }, [dispatch])

  const onSubmit = useCallback(
    values => {
      const hasChanges = !isEqual(values, initialValues)
      const formattedData = transformValuesToBE(values)

      if (hasChanges) {
        dispatch(updateSelfAccountMember(memberId, formattedData))
      } else {
        dispatch(updateSelfAccountMemberSuccess(formattedData))
      }
    },
    [dispatch, memberId, initialValues]
  )

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true
  })

  return (
    <Form
      formName={SELF_ACCOUNT_PERMISSIONS_EDIT}
      formik={formik}
      successSubmit={memberWasUpdated}
      errorSelector={updateMemberErrorSelector}
      isLoadingSelector={updateMemberIsLoadingSelector}
      clearHandler={clearUpdateCategoryPageHandler}
    >
      <MemberPermissionsFields formik={formik} />
    </Form>
  )
}

export default MemberPermissionsEditForm
