import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Field from '../../../components/Form/Field'

import { formatOptionsList } from '../../../features/formatters'

import { getLocations } from '../../../modules/actions/lineItems'
import { locationsSelector, locationsIsLoadingSelector } from '../../../modules/selectors/lineItems'

import { sevenDaysList } from '../../../constants/selectLists/sevenDaysList'
import { LOCATION, TEMPERATURE, FORECAST_RANGE } from './fields'

import useDrawerFormStyles from '../../../styles/common/drawerForms'

const TriggerFields = ({ formik }) => {
  const classes = useDrawerFormStyles()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const locations = useSelector(locationsSelector)
  const locationsIsLoading = useSelector(locationsIsLoadingSelector)

  const formattedLocations = useMemo(() => {
    return formatOptionsList({
      list: locations,
      valueName: 'id',
      labelName: 'city'
    })
  }, [locations])

  useEffect(() => {
    if (!locations.length) {
      dispatch(getLocations())
    }
  }, [dispatch, locations])

  return (
    <>
      <p>{t('Trigger this ad set to turn on or off automatically depending on the weather forecast')}</p>
      <p>{t('Please select where the weather should be tracked')}</p>
      <Field
        placeholder={t('Location')}
        formik={formik}
        name={LOCATION}
        options={formattedLocations}
        isLoading={locationsIsLoading}
      />
      <div className={classes.subSection}>
        <h4 className={classes.sectionTitle}>{t('Reaches a temperature')}</h4>
        <div className={classes.sectionSelectBlock}>
          <p>{t('When the temperature will reach at least')}:</p>
          <Field placeholder={t('Celsius')} formik={formik} name={TEMPERATURE} enableReinitialize inputMode="numeric" />
        </div>
        <div className={classes.sectionSelectBlock}>
          <p>{t('Within the following')}:</p>
          <Field
            placeholder={t('Days')}
            formik={formik}
            name={FORECAST_RANGE}
            options={sevenDaysList}
            isSearchable={false}
          />
        </div>
      </div>
    </>
  )
}

export default TriggerFields
