import { all, call, put, takeEvery } from 'redux-saga/effects'

import { getLineItemsSummaryReportsService } from '../services/lineItemsSummary'
import {
  GET_LINE_ITEMS_SUMMARY_REPORTS,
  getLineItemsSummaryReportsSuccess,
  getLineItemsSummaryReportsFailure
} from '../actions/lineItemsSummary'

function* lineItemsSummaryWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_LINE_ITEMS_SUMMARY_REPORTS, getLineItemsReportsWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getLineItemsReportsWorker({ params }) {
  try {
    const response = yield call(getLineItemsSummaryReportsService, params)
    yield put(getLineItemsSummaryReportsSuccess(response))
  } catch (e) {
    yield put(getLineItemsSummaryReportsFailure(e))
  }
}

export default lineItemsSummaryWatcher
