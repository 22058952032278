import { useSelector } from 'react-redux'

import { userSelfAccountTypeSelector } from '../../modules/selectors/app'
import { CONTROLLER_TYPE } from '../../constants/permissions'

export default function useIsInternalAdCreation() {
  const userAccountType = useSelector(userSelfAccountTypeSelector)

  // return true (create internal ad) if user in selected self account is not a controller
  return userAccountType !== CONTROLLER_TYPE
}
