import React from 'react'
import { components } from 'react-select'

const SelectMultiplePlaceholder = props => {
  const { isFocused } = props

  const children = !isFocused ? props.children : ''
  const hasValues = props.selectProps.value?.length > 0

  return hasValues ? null : <components.Placeholder {...props} children={children} />
}

export default SelectMultiplePlaceholder
