import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import InvoicesTableData from './InvoicesTableData'
import TableDataLoader from '../../../../components/Table/TableDataLoader'

import useCommonStyles from '../../../../styles/common/table'
import {
  getStatementsErrorSelector,
  getStatementsIsLoadingSelector,
  getStatementsWasLoadedSelector
} from '../../../../modules/selectors/statements'

import { phonesDownSize } from '../../../../styles/const/breakpoints'

import useStyles from '../styles'

export const tableColumnsSize = {
  id: 68,
  account: 200,
  status: 100,
  total: 120,
  actions: 40
}

const spaceForMargin = 5

const mobileColumnsSchema = [
  { style: { maxWidth: tableColumnsSize.id, marginInlineEnd: spaceForMargin } },
  { style: { maxWidth: '100%' } }
]

const InvoicesTable = ({ invoices = [] }) => {
  const { t } = useTranslation()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const invoicesColumnsSchema = useMemo(
    () => [
      {
        header: 'ID',
        style: { maxWidth: tableColumnsSize.id }
      },
      {
        header: 'Name'
      },
      {
        header: 'Account',
        style: { maxWidth: tableColumnsSize.account }
      },
      {
        header: 'Status',
        style: { maxWidth: tableColumnsSize.status }
      },
      {
        header: 'Total (excl. GST)',
        style: { maxWidth: tableColumnsSize.total }
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={classnames(commonClasses.noDataContentInTheMiddleOfTheTable, classes.noDataContainer)}>
        <div>{t('There are no billing data to show')}</div>
      </div>
    ),
    [commonClasses.noDataContentInTheMiddleOfTheTable, classes.noDataContainer, t]
  )

  return (
    <TableDataLoader
      itemsLength={invoices.length}
      errorSelector={getStatementsErrorSelector}
      wasLoadedSelector={getStatementsWasLoadedSelector}
      isLoadingSelector={getStatementsIsLoadingSelector}
      skeletonProps={{ cols: isMobile ? mobileColumnsSchema : invoicesColumnsSchema }}
      noDataContent={noDataContent}
    >
      <InvoicesTableData invoices={invoices} />
    </TableDataLoader>
  )
}

InvoicesTable.propTypes = {
  invoices: PropTypes.array.isRequired
}

export default InvoicesTable
