import { createStyles } from '../../../styles/helpers'

import { selectValueSize } from '../styles'
import { textEllipsis } from '../../../styles/mixins/text'
import { textGrey } from '../../../styles/const/colors'

const useStyles = createStyles(theme => ({
  value: {
    display: 'flex',
    alignItems: 'center'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 5,
    width: ({ bigIcon }) => (bigIcon ? 32 : 16),
    height: ({ bigIcon }) => (bigIcon ? 32 : 16),

    '& svg, & img': {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  info: {
    width: '100%',
    overflow: 'hidden'
  },
  label: {
    ...textEllipsis(),
    color: ({ isLabelBrandPrimary }) => (isLabelBrandPrimary ? theme.brandPrimary : textGrey),
    fontWeight: ({ isLabelBrandPrimary }) => (isLabelBrandPrimary ? 600 : 400),
    fontSize: selectValueSize,
    lineHeight: 'normal'
  }
}))

export default useStyles
