import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import AnimateHeight from 'react-animate-height'
import { useTranslation } from 'react-i18next'

import useStyles from './style'

import { ReactComponent as ArrowIcon } from '../../assets/icons/chevron-arrow-down.svg'

function Spoiler({ title, initialOpen, children, className, spoilerTitleClassName }) {
  const { t } = useTranslation()

  const [height, setHeight] = useState(initialOpen ? 'auto' : 0)
  const classes = useStyles(!!height)

  const onToggleSpoiler = () => {
    setHeight(height === 0 ? 'auto' : 0)
  }

  return (
    <div className={classnames(classes.spoiler, 'spoiler', className)}>
      <div
        className={classnames('spoilerTitle', classes.spoilerTitle, spoilerTitleClassName)}
        onClick={onToggleSpoiler}
      >
        <ArrowIcon className={classes.arrowIcon} />
        {t(title)}
      </div>
      <AnimateHeight duration={300} height={height}>
        {children}
      </AnimateHeight>
    </div>
  )
}

Spoiler.propTypes = {
  title: PropTypes.string,
  initialOpen: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  spoilerTitleClassName: PropTypes.string
}

export default Spoiler
