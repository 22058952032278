import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Field from '../../../../../../../../components/Form/Field'
import MessageBlock from '../../../../../../../../features/components/Form/MessageBlock'
import FieldsSection from '../../../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'

import { formatPixelItems } from './helpers'

import { getTikTokPixels, clearTikTokPixels } from '../../../../../../../../modules/actions/tikTokConstants'
import {
  tikTokPixelsIsLoadingSelector,
  tikTokPixelsSelector,
  tikTokPixelsWasLoadedSelector
} from '../../../../../../../../modules/selectors/tikTokConstants'
import { selectedAdAccountIdSelector } from '../../../../../../../../modules/selectors/app'

import { PIXEL_EVENT, PIXEL_ID } from '../../../fields'

const TikTokPixelFields = ({ formik, adAccountId: providedAdAccountId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { values, setFieldValue, setFieldTouched } = formik

  const { [PIXEL_ID]: selectedPixelId, [PIXEL_EVENT]: selectedPixelEventId } = values

  const tikTokPixels = useSelector(tikTokPixelsSelector)
  const tikTokPixelsIsLoading = useSelector(tikTokPixelsIsLoadingSelector)
  const tikTokPixelsWasLoaded = useSelector(tikTokPixelsWasLoadedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const adAccountId = providedAdAccountId || selectedAdAccountId

  const selectedPixelEvents = tikTokPixels.find(pixel => pixel.id === selectedPixelId)?.events

  const isEmptyEvents = tikTokPixelsWasLoaded && (!selectedPixelEvents || !selectedPixelEvents.length)

  const formattedTikTokPixels = useMemo(() => formatPixelItems(tikTokPixels), [tikTokPixels])

  const formattedTikTokPixelEvents = useMemo(() => formatPixelItems(selectedPixelEvents), [selectedPixelEvents])

  // preselect pixel
  useEffect(() => {
    if (!selectedPixelId && tikTokPixels.length) {
      // try to find active pixel, if it doesn't exist, preselect any first one
      const activePixel = tikTokPixels.find(pixel => pixel.status.toLowerCase() === 'active')

      if (activePixel) {
        setFieldValue(PIXEL_ID, activePixel.id)
      } else {
        setFieldValue(PIXEL_ID, tikTokPixels[0].id)
      }
    }
  }, [tikTokPixels, tikTokPixels.length, selectedPixelId, setFieldValue])

  // clear event when changing pixel
  useEffect(() => {
    setFieldValue(PIXEL_EVENT, '')
    setFieldTouched(PIXEL_EVENT, false)
  }, [selectedPixelId, setFieldValue, setFieldTouched])

  // preselect pixel event
  useEffect(() => {
    if (!selectedPixelEventId && selectedPixelEvents && selectedPixelEvents.length) {
      // try to find active pixel, if it doesn't exist, preselect any first one
      const activeEvent = selectedPixelEvents.find(event => event.status.toLowerCase() === 'active')

      if (activeEvent) {
        setFieldValue(PIXEL_EVENT, activeEvent.id)
      } else {
        setFieldValue(PIXEL_EVENT, selectedPixelEvents[0].id)
      }
    }
  }, [selectedPixelEventId, selectedPixelEvents, setFieldValue])

  useEffect(() => {
    dispatch(getTikTokPixels({ account: adAccountId, order_by: 'LATEST_CREATE' }))

    return () => {
      dispatch(clearTikTokPixels())
    }
  }, [dispatch, adAccountId])

  return (
    <FieldsSection title="Event Source">
      <FieldRow title="Pixel">
        <Field
          formik={formik}
          options={formattedTikTokPixels}
          name={PIXEL_ID}
          placeholder="Pixel"
          autoComplete="off"
          isLoading={tikTokPixelsIsLoading}
        />
      </FieldRow>
      {isEmptyEvents && (
        <MessageBlock title={t('No events found')} color="warning">
          {t(
            'This pixel has not had any events fire in the past 28 days. Events must be tracked (e.g. purchase) for this campaign to be able to optimise effectively. '
          )}
          <a
            href={`https://ads.tiktok.com/i18n/events_manager/?aadvid=${adAccountId}`}
            target="_blank"
            rel="noreferrer"
          >
            {t('Click here ')}
          </a>
          {t('to launch TikTok’s event manager.')}
        </MessageBlock>
      )}
      <FieldRow title="Event">
        <Field
          formik={formik}
          options={formattedTikTokPixelEvents}
          name={PIXEL_EVENT}
          placeholder="Event"
          autoComplete="off"
          isLoading={tikTokPixelsIsLoading}
        />
      </FieldRow>
    </FieldsSection>
  )
}

export default TikTokPixelFields
