import React from 'react'

import SimpleHeaderTemplate from '../SimpleHeaderTemplate'

import { ROUTES } from '../../../../constants/routes'

function UnauthorizedHeader({ logoUrl = ROUTES.platformDomain }) {
  return <SimpleHeaderTemplate logoUrl={logoUrl} />
}

export default UnauthorizedHeader
