import * as Yup from 'yup'
import { getInitialPhoneValues, validatePhoneNumber } from './UserPhoneField/fields'

export const FIRST_NAME = 'first_name'
export const LAST_NAME = 'last_name'
export const PHONE_NUMBER = 'phone_number'
export const COUNTRY = 'country'

export const getInitialValues = user => {
  const { first_name, last_name } = user

  return {
    [FIRST_NAME]: first_name || '',
    [LAST_NAME]: last_name || '',
    ...getInitialPhoneValues(user)
  }
}

export const getValidationSchema = initialPhoneNumber =>
  Yup.object({
    [FIRST_NAME]: Yup.string().required('Please Enter your first name'),
    [LAST_NAME]: Yup.string().required('Please Enter your last name'),
    [PHONE_NUMBER]: Yup.string().test({
      message: 'Please enter a valid phone number',
      // allow empty value or min 6 chars
      test: value => {
        // Allow initially empty and still empty
        if (!initialPhoneNumber && !value) {
          return true
        } else {
          // Initially not empty, or has a value now, so check length
          return validatePhoneNumber(value)
        }
      }
    })
  })
