import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../../../features/components/FormDrawerWrapper'
import RequestPageAccessForm from './RequestPageAccessForm'

import { selectedSelfAccountDataSelector } from '../../../../../../modules/selectors/app'

import { REQUEST_FACEBOOK_PAGE_ACCESS } from '../../../../../../constants/forms'

import useCommonStyles from '../../../../../../styles/common/listPage'

const RequestPageAccess = () => {
  const { t } = useTranslation()
  const commonClasses = useCommonStyles()

  const { facebook_bm_id: facebookBmId } = useSelector(selectedSelfAccountDataSelector)

  const noFacebookManagerIdMessage = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        {t('Sorry, you should have Facebook business manager id associated with this account to use this form.')}
      </div>
    ),
    [t, commonClasses.noDataContent]
  )

  return (
    <FormDrawerWrapper
      formName={REQUEST_FACEBOOK_PAGE_ACCESS}
      openButtonText="Request Page Access"
      title="Request Facebook page access"
    >
      {facebookBmId ? <RequestPageAccessForm /> : noFacebookManagerIdMessage}
    </FormDrawerWrapper>
  )
}

export default RequestPageAccess
