import React, { useCallback, useMemo } from 'react'
import * as Yup from 'yup'

import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import EditForm from '../../../../../../../features/components/Forms/EditForm'
import GeolocationField from './GeolocationField'
import ErrorMessage from '../../../../../../../components/Form/ErrorMessage'
import CustomLocationsContent from '../../../../../../ReusableFormSections/CustomLocationsSection/CustomLocationsContent'

import { usePurifiedFormik } from '../../../../../../../hooks/formHooks/usePurifiedFormik'

import { formatGeoTargetingToBE, formatGeoTargetingToFE } from '../../../formatters'

import geolocationsValidation from './validation'
import { COUNTRY_OR_REGION, CUSTOM_LOCATIONS, GEO_TARGETING } from '../../../fields'
import { LOCATION_LIST } from '../../../../../../ReusableFormFields/GeolocationsFields/fields'

import { clearUpdateLineItem, updateLineItem } from '../../../../../../../modules/actions/lineItems'
import {
  lineItemUpdateErrorSelector,
  lineItemUpdateIsLoadingSelector,
  lineItemWasUpdatedSelector
} from '../../../../../../../modules/selectors/lineItems'
import { campaignSelector } from '../../../../../../../modules/selectors/campaigns'
import { choicesCountriesSelector } from '../../../../../../../modules/selectors/choices'
import { selectedAdAccountIdSelector } from '../../../../../../../modules/selectors/app'

import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

const GeoTargetingSectionForm = ({ editItemData: lineItem, ...formProps }) => {
  const drawerFormClasses = useDrawerFormStyles()

  const dispatch = useDispatch()

  const lineItemWasUpdated = useSelector(lineItemWasUpdatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const choicesCountries = useSelector(choicesCountriesSelector)
  const { objective: campaignObjective } = useSelector(campaignSelector)

  const { geo_targeting: geoTargeting } = lineItem

  const handleSubmit = useCallback(
    values => {
      const {
        [COUNTRY_OR_REGION]: countryOrRegion,
        [CUSTOM_LOCATIONS]: customLocations,
        [LOCATION_LIST]: customLocationList
      } = values

      const updateLineItemData = {
        account: selectedAdAccountId,
        campaign_objective: campaignObjective,
        [GEO_TARGETING]: formatGeoTargetingToBE(countryOrRegion),
        // customLocations which are populated by BE
        // customLocationList that is ID of new selected list. BE will populate custom locations from selected list
        // add new - LOCATION_LIST: id
        // keep - don't send LOCATION_LIST
        ...(customLocationList && { [LOCATION_LIST]: customLocationList }),
        // delete - LOCATION_LIST: null
        ...(!customLocations.length && !customLocationList && { [LOCATION_LIST]: null })
      }

      dispatch(updateLineItem(updateLineItemData, lineItem.id))
    },
    [dispatch, lineItem.id, campaignObjective, selectedAdAccountId]
  )

  const validationSchema = Yup.object(geolocationsValidation)

  const initialValues = useMemo(
    () => ({
      [COUNTRY_OR_REGION]: formatGeoTargetingToFE(geoTargeting, choicesCountries) || [],
      [CUSTOM_LOCATIONS]: (geoTargeting && geoTargeting[CUSTOM_LOCATIONS]) || [],
      [LOCATION_LIST]: ''
    }),
    [choicesCountries, geoTargeting]
  )

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const { values, errors } = formik

  const { [GEO_TARGETING]: geoTargetingError, [COUNTRY_OR_REGION]: countryError } = errors

  const handleClearUpdateLineItem = useCallback(() => {
    dispatch(clearUpdateLineItem())
  }, [dispatch])

  const hasCountryOrRegion = !!values[COUNTRY_OR_REGION]?.length

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={lineItemWasUpdated}
      clearEditItem={handleClearUpdateLineItem}
      errorSelector={lineItemUpdateErrorSelector}
      isLoadingSelector={lineItemUpdateIsLoadingSelector}
      {...formProps}
    >
      <GeolocationField formik={formik} />
      {countryError && <ErrorMessage error={countryError} />}
      {geoTargetingError && <ErrorMessage error={geoTargetingError} />}
      <div className={drawerFormClasses.subSection}>
        {/*// it's possible to select either location list or add country/regions manually*/}
        <CustomLocationsContent formik={purifiedFormik} isDisabled={hasCountryOrRegion} />
      </div>
    </EditForm>
  )
}

export default GeoTargetingSectionForm
