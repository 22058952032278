import React, { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import EditForm from '../../../../../../../features/components/Forms/EditForm'
import MultipleGoogleMediaUpload from '../../../../ReusableFields/MultipleGoogleMediaUpload'

import { usePurifiedFormik } from '../../../../../../../hooks/formHooks/usePurifiedFormik'
import { useEditSectionFormSubmit } from '../../../../../../../hooks/formHooks/useEditSectionFormSubmit'

import { updateAd } from '../../../../../../../modules/actions/ads'
import { selectedAdAccountIdSelector } from '../../../../../../../modules/selectors/app'

import { getInitialValues, validationSchema } from './fields'
import { getImageAssets, SQUARE_LOGO, squareLogoHeight, squareLogoWidth } from '../../../../fields'
import { getSquareLogoValidationSchema } from '../../../../validations'
import { GOOGLE_PLATFORM } from '../../../../../../../constants/selectLists/platformList'

const SquareLogoSectionForm = ({ editItemData: ad, successSubmit, isInternalAd, ...formProps }) => {
  const dispatch = useDispatch()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const { id, ad_account_id: adAccountId } = ad
  const adAccount = adAccountId || selectedAdAccountId

  const initialValues = useMemo(() => getInitialValues(ad), [ad])
  const squareLogoValidationSchema = useMemo(() => getSquareLogoValidationSchema(), [])

  const handleSubmit = useCallback(
    values => {
      dispatch(
        updateAd(
          {
            // On AdApprovals page we have ads from different ad accounts,
            // so we use ad_account_id from ad item
            account: adAccount,
            responsive_display_ad: {
              square_logo_images: getImageAssets(values[SQUARE_LOGO])
            },
            ...(isInternalAd && { internal: true })
          },
          id,
          'PATCH',
          GOOGLE_PLATFORM
        )
      )
    },
    [dispatch, id, isInternalAd, adAccount]
  )

  const { onSubmit, manualSuccessSubmit } = useEditSectionFormSubmit({ initialValues, handleSubmit })

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const { values } = purifiedFormik

  const isEmptyList = !values[SQUARE_LOGO].length

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={successSubmit || manualSuccessSubmit}
      {...formProps}
    >
      <MultipleGoogleMediaUpload
        formik={purifiedFormik}
        FileItemPath={SQUARE_LOGO}
        validationSchema={squareLogoValidationSchema}
        addAnotherButtonText={isEmptyList ? 'Add square image' : 'Add another square image'}
        width={squareLogoWidth}
        height={squareLogoHeight}
        adAccountId={adAccountId}
      />
    </EditForm>
  )
}

export default SquareLogoSectionForm
