import { useCallback, useState, useEffect, useMemo } from 'react'

const useTouchManage = () => {
  const [isTouched, setIsTouched] = useState(false)

  const touchStartEvent = useCallback(() => {
    setIsTouched(true)
  }, [])

  useEffect(() => {
    // we need this to simulate changing css on click events for mobile devices (tap in our case)
    let timeOut

    if (isTouched) {
      timeOut = setTimeout(() => {
        setIsTouched(false)
      }, 100)
    }
    return () => {
      clearTimeout(timeOut)
    }
  }, [isTouched])

  return useMemo(
    () => ({
      isTouched,
      touchStartEvent
    }),
    [isTouched, touchStartEvent]
  )
}

export default useTouchManage
