import { createStyles } from '../../../../styles/helpers'

import { phonesDown } from '../../../../styles/const/breakpoints'
import { lighterGrey } from '../../../../styles/const/colors'
import { drawerContentPadding } from '../../../../styles/common/drawerForms'

// such a big padding needed when we have select at then end of the step
// whose dropdown can be cut by bottom of the form
// export const stepFormPaddingBottom = 200

const useStyles = createStyles({
  formWrapper: {
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  formContainer: {
    width: '100%',
    margin: '0 auto'
  },
  formContainerWidthLimit: {
    maxWidth: 580
  },
  formProgressPadding: {
    padding: drawerContentPadding
  },
  sideColumn: {
    minHeight: '100vh',
    width: 330,
    background: lighterGrey,
    padding: '70px 20px'
  },
  progressItemsContainer: {
    marginBottom: 50
  },
  [`@media screen and (${phonesDown})`]: {
    formContainer: {
      maxWidth: '100%',
      padding: '0 !important'
    },
    sideColumn: {
      display: 'none'
    }
  }
})

export default useStyles
