import update from 'immutability-helper'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_AI_SUGGESTIONS,
  GET_AI_SUGGESTIONS_SUCCESS,
  GET_AI_SUGGESTIONS_FAILURE,
  CLEAR_GET_AI_SUGGESTIONS,
  GET_CHANGE_HISTORY,
  GET_CHANGE_HISTORY_SUCCESS,
  GET_CHANGE_HISTORY_FAILURE,
  CLEAR_GET_CHANGE_HISTORY,
  GET_AI_SUGGESTIONS_CHUNK
} from '../actions/tools'
import { INITIAL_GET_RESULTS_PAGING_REDUCER, INITIAL_GET_RESULTS_REDUCER } from '../../constants/reducer'
import {
  SUGGESTIONS_AD_CREATIVE_SLUG,
  SUGGESTIONS_DESCRIPTIONS_SLUG,
  SUGGESTIONS_GOOGLE_AD,
  SUGGESTIONS_HEADLINE_SLUG,
  SUGGESTIONS_PRIMARY_TEXTS_SLUG
} from '../../constants/tools'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  aiSuggestion: {
    [SUGGESTIONS_AD_CREATIVE_SLUG]: INITIAL_GET_RESULTS_REDUCER,
    [SUGGESTIONS_GOOGLE_AD]: INITIAL_GET_RESULTS_REDUCER,
    [SUGGESTIONS_PRIMARY_TEXTS_SLUG]: INITIAL_GET_RESULTS_REDUCER,
    [SUGGESTIONS_HEADLINE_SLUG]: INITIAL_GET_RESULTS_REDUCER,
    [SUGGESTIONS_DESCRIPTIONS_SLUG]: INITIAL_GET_RESULTS_REDUCER
  },
  changeHistory: INITIAL_GET_RESULTS_PAGING_REDUCER
}

export default function tools(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // Get ai suggestions
    case GET_AI_SUGGESTIONS:
      return update(state, {
        aiSuggestion: {
          [action.slug]: { $merge: { isLoading: true } }
        }
      })
    case GET_AI_SUGGESTIONS_CHUNK:
      return update(state, {
        aiSuggestion: {
          [action.slug]: {
            $merge: {
              results: action.dataChunk.data
            }
          }
        }
      })
    case GET_AI_SUGGESTIONS_SUCCESS:
      return update(state, {
        aiSuggestion: {
          [action.slug]: {
            $merge: {
              isLoading: false,
              wasLoaded: true,
              // currently all templates responds with data object
              results: action.data.data
            }
          }
        }
      })

    case GET_AI_SUGGESTIONS_FAILURE:
      return update(state, {
        aiSuggestion: {
          [action.slug]: {
            $merge: {
              isLoading: false,
              error: action.error
            }
          }
        }
      })

    case `${CLEAR_GET_AI_SUGGESTIONS}_${action.slug}`:
      return update(state, {
        aiSuggestion: {
          [action.slug]: { $set: initialState.aiSuggestion[action.slug] }
        }
      })
    case CLEAR_GET_AI_SUGGESTIONS:
      return update(state, {
        aiSuggestion: { $set: initialState.aiSuggestion }
      })
    // Get updates history
    case GET_CHANGE_HISTORY:
      return update(state, {
        changeHistory: { $merge: { isLoading: true } }
      })
    case GET_CHANGE_HISTORY_SUCCESS:
      return update(state, {
        changeHistory: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data.data,
            paging: {
              next: action.data.next
            }
          }
        }
      })
    case GET_CHANGE_HISTORY_FAILURE:
      return update(state, {
        changeHistory: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_CHANGE_HISTORY:
      return update(state, {
        changeHistory: { $set: initialState.changeHistory }
      })

    default:
      return state
  }
}
