import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import CheckboxBlock from '../../../../../features/components/Form/CheckboxBlock'
import MultiSelectBox from '../../../../../features/components/Form/MultiSelectBox'
import { TabletDown, TabletUp } from '../../../../../components/hoc/ResponsiveRendering'
import FieldLabelWrapper from '../../../../../features/components/Form/FieldLabelWrapper'
import FormField from '../../../EditProfile/UserProfileContent/EditProfileFormContent/FormField'
import Field from '../../../../../components/Form/Field'
import Toggle from '../../../../../components/Toggle'
import MediaFileKitUpload from './MediaFileKitUpload'
import Checkbox from '../../../../../components/Form/Checkbox'

import {
  ALLOW_MULTI_LANGUAGE,
  FB_BUSINESS_ID,
  FB_PIXEL_ID,
  MFA_IS_REQUIRED,
  PROFORMA,
  SELECTED_LANGUAGES
} from '../fields'

import useStyles from '../../../EditProfile/styles'

const ManageControllerFormContent = ({ formik, languagesList }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { setFieldValue, values, errors, touched } = formik

  const mfaIsRequired = values[MFA_IS_REQUIRED]
  const mfaIsRequiredToggleHandler = useCallback(() => {
    setFieldValue(MFA_IS_REQUIRED, !mfaIsRequired)
  }, [setFieldValue, mfaIsRequired])

  const allowMultiLanguage = !!values[ALLOW_MULTI_LANGUAGE]
  const handleLanguagesSelectionCheck = useCallback(() => {
    setFieldValue(ALLOW_MULTI_LANGUAGE, !allowMultiLanguage)
  }, [setFieldValue, allowMultiLanguage])

  const handleProformaCheck = useCallback(() => {
    setFieldValue(PROFORMA, !values[PROFORMA])
  }, [setFieldValue, values])

  return (
    <>
      <TabletUp>
        <FormField label="Meta Business Manager ID">
          <Field name={FB_BUSINESS_ID} formik={formik} placeholder="Meta Business Manager ID" />
        </FormField>
        <FormField label="Meta Dataset ID">
          <Field name={FB_PIXEL_ID} formik={formik} placeholder="Meta Dataset ID" />
        </FormField>
        <FormField label="Require 2 factor authentication">
          <Toggle isInitiallyEnabled={mfaIsRequired} onToggle={mfaIsRequiredToggleHandler} />
        </FormField>
        <FormField label="App languages">
          <CheckboxBlock
            id="allow_multi_language"
            checked={allowMultiLanguage}
            onCheck={handleLanguagesSelectionCheck}
            title={t('Allow language selection')}
          >
            <MultiSelectBox
              placeholder={t('Add possible languages to select')}
              name={SELECTED_LANGUAGES}
              options={languagesList}
              value={values[SELECTED_LANGUAGES]}
              setFieldValue={setFieldValue}
              touched={touched[SELECTED_LANGUAGES]}
              error={errors[SELECTED_LANGUAGES]}
            />
          </CheckboxBlock>
        </FormField>
        <FormField label="Proforma Invoices">
          <Checkbox
            id={PROFORMA}
            name={PROFORMA}
            title={t('Proforma invoices')}
            checked={values[PROFORMA]}
            onCheck={handleProformaCheck}
          />
        </FormField>
        <FormField label="Rate card file" isLastFormField>
          <p>{t('Upload a rate card that will be available to download on the landing page')}</p>
          <MediaFileKitUpload formik={formik} />
        </FormField>
      </TabletUp>

      <TabletDown>
        <div className={classes.formRow}>
          <FieldLabelWrapper label={'Meta Business Manager ID'}>
            <Field name={FB_BUSINESS_ID} formik={formik} placeholder="Meta Business Manager ID" />
          </FieldLabelWrapper>
          <FieldLabelWrapper label={'Meta Dataset ID'}>
            <Field name={FB_PIXEL_ID} formik={formik} placeholder="Meta Dataset ID" />
          </FieldLabelWrapper>
        </div>

        <FieldLabelWrapper label="Require 2 factor authentication">
          <Toggle isInitiallyEnabled={mfaIsRequired} onToggle={mfaIsRequiredToggleHandler} />
        </FieldLabelWrapper>
      </TabletDown>
    </>
  )
}

ManageControllerFormContent.propTypes = {
  languagesList: PropTypes.array.isRequired
}
export default ManageControllerFormContent
