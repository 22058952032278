import { call, race, take, put } from 'redux-saga/effects'

import { takeEvery, takeLatest } from 'typed-redux-saga'

export function* handleWebSocketRequest({ service, serviceProps, cancelActionType, successAction, failureAction }) {
  let socket = null
  const onSocketOpen = openedSocket => {
    socket = openedSocket
  }

  try {
    const { response, cancel } = yield race({
      response: call(service, { ...serviceProps, onSocketOpen }),
      // avoid loading, if items were cleared
      cancel: take(cancelActionType)
    })

    if (cancel) {
      // close the websocket connection if it was cancelled
      socket.close()
    } else if (response) {
      yield put(successAction(response))
    }
  } catch (error) {
    console.error('error', error)
    yield put(failureAction(error))
  }
}

export function takeLatestIgnorePrevious({ ACTION, worker }) {
  // Once filters shouldClearExistingState is true, the previous worker task should be
  // cancelled to avoid fetched data duplications(i.e. it may load the same data again).
  return [
    // This way, worker will be started for each ACTION, allowing multiple tasks to run in parallel when
    // shouldClearExistingState is false.
    takeEvery(action => action.type === ACTION && !action.loadOptions.shouldClearExistingState, worker),
    // And only the latest worker task will be running when shouldClearExistingState is true, cancelling any previous tasks.
    takeLatest(action => action.type === ACTION && action.loadOptions.shouldClearExistingState, worker)
  ]
}
