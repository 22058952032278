import React from 'react'
import PropTypes from 'prop-types'

import { getSquareImageData } from '../HeadlinesSection/helpers'

import FileDetailsPreview from '../../../../../../../components/FileDetailsPreview'

const SquareImageSectionPreview = ({ data }) => {
  const { assets } = data
  const squareImageData = getSquareImageData(assets)

  if (!squareImageData || !squareImageData.length) {
    return null
  }

  return (
    <div>
      {squareImageData.map(({ asset_data: assetData }) => {
        const fileUrl = assetData?.image_asset?.full_size?.url
        const fileName = assetData?.name

        return (
          <FileDetailsPreview
            key={fileUrl}
            fileName={fileName}
            previewUrl={fileUrl}
            filePreviewType={'image'}
            isFileLoading={false}
            allowDelete={false}
          />
        )
      })}
    </div>
  )
}

SquareImageSectionPreview.propTypes = {
  data: PropTypes.object
}

export default SquareImageSectionPreview
