import { useState, useCallback, useEffect } from 'react'

function useElementHeight(data = null, liveMeasure = true) {
  const [height, setHeight] = useState(0)
  const [node, setNode] = useState(null)

  const ref = useCallback(node => {
    setNode(node)
  }, [])

  useEffect(() => {
    if (node) {
      const measure = () => window.requestAnimationFrame(() => setHeight(node.getBoundingClientRect().height))
      measure()

      if (liveMeasure) {
        window.addEventListener('resize', measure)

        return () => {
          window.removeEventListener('resize', measure)
        }
      }
    }
  }, [node, data, liveMeasure])

  return { ref, height }
}

export default useElementHeight
