import { SA, GB } from 'country-flag-icons/react/3x2'

export const DOMAINS = {
  aduhu: 'aduhu',
  cenomispace: 'cenomispace',
  shopometry: 'shopometry',
  oneviu: 'oneviu',
  nexus: 'nexus'
}

export const ENGLISH_CODE = 'en'
export const ARABIC_CODE = 'ar'

// Shopometry project is used for different apps, each app have it's own localization file
// different apps localization files:
export const SHOPOMETRY_LOCALIZATION = {
  [ENGLISH_CODE]: 'en',
  [ARABIC_CODE]: 'ar'
}
export const ADUHU_LOCALIZATION = {
  [ENGLISH_CODE]: 'enAduhu',
  [ARABIC_CODE]: 'ar'
}

export const CENOMI_LOCALIZATION = {
  [ENGLISH_CODE]: 'en',
  [ARABIC_CODE]: 'ar'
}

export const ONEVIU_LOCALIZATION = {
  [ENGLISH_CODE]: 'en',
  [ARABIC_CODE]: 'ar'
}

export const NEXUS_LOCALIZATION = {
  [ENGLISH_CODE]: 'en',
  [ARABIC_CODE]: 'ar'
}

export const DEFAULT_LANGUAGE = ENGLISH_CODE

// BE store languages with their own values
export const BE_LANGUAGE_VALUES = {
  [ENGLISH_CODE]: 'en-us',
  [ARABIC_CODE]: 'ar'
}

export const languagesList = [
  {
    value: ENGLISH_CODE,
    label: 'English',
    icon: GB,
    textDirection: 'ltr'
  },
  {
    value: ARABIC_CODE,
    label: 'العربية',
    icon: SA,
    textDirection: 'rtl'
  }
]

export const getSelectedLanguageData = code => languagesList.find(language => language.value === code)

export const tikTokLanguagesList = [
  { label: 'Arabic', value: 'ar' },
  { label: 'Assamese', value: 'as' },
  { label: 'Bengali', value: 'bn' },
  { label: 'Bhojpuri', value: 'bh' },
  { label: 'Chinese (Traditional)', value: 'zh-Hant' },
  { label: 'Czech', value: 'cs' },
  { label: 'Dutch', value: 'nl' },
  { label: 'English', value: 'en' },
  { label: 'Finnish', value: 'fi' },
  { label: 'French', value: 'fr' },
  { label: 'German', value: 'de' },
  { label: 'Gujarati', value: 'gu' },
  { label: 'Haryanvi', value: 'bgc' },
  { label: 'Hebrew', value: 'he' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Indonesian', value: 'id' },
  { label: 'Italian', value: 'it' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Kannada', value: 'kn' },
  { label: 'Korean', value: 'ko' },
  { label: 'Malay', value: 'ms' },
  { label: 'Malayalam', value: 'ml' },
  { label: 'Marathi', value: 'mr' },
  { label: 'Oriya', value: 'or' },
  { label: 'Polish', value: 'pl' },
  { label: 'Portuguese', value: 'pt' },
  { label: 'Punjabi', value: 'pa' },
  { label: 'Rajasthani', value: 'raj' },
  { label: 'Romanian', value: 'ro' },
  { label: 'Russian', value: 'ru' },
  { label: 'Spanish', value: 'es' },
  { label: 'Swedish', value: 'sv' },
  { label: 'Tamil', value: 'ta' },
  { label: 'Telugu', value: 'te' },
  { label: 'Thai', value: 'th' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Ukrainian', value: 'uk' },
  { label: 'Vietnamese', value: 'vi' }
]
