import { CAN_SELECT_MULTIPLE, NAME, OPTIONS, REQUIRED } from '../../fields'

export const transformValuesToBE = values => {
  return {
    [NAME]: values[NAME],
    [CAN_SELECT_MULTIPLE]: Boolean(values[CAN_SELECT_MULTIPLE]),
    [REQUIRED]: Boolean(values[REQUIRED]),
    [OPTIONS]: values[OPTIONS].map(option => option.text)
  }
}
