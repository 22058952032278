import {
  getImageHashes,
  getVideoImageHashes
} from '../../../../forms/Facebook/AdForms/AdFacebookEdit/helpers/mediaFileHelpers'

export const getAllImageHashesFromAdsList = adsList => {
  const uniqueValidHashes = adsList.reduce((acc, ad) => {
    const imageHashes = getImageHashes(ad)
    const videoImageHashes = getVideoImageHashes(ad)

    imageHashes.forEach(hash => {
      if (hash && !acc.has(hash)) {
        acc.add(hash)
      }
    })

    videoImageHashes.forEach(hash => {
      if (hash && !acc.has(hash)) {
        acc.add(hash)
      }
    })

    return acc
  }, new Set())

  return Array.from(uniqueValidHashes)
}
