import React, { useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import MultipleCheckboxesSelect from '../../../../components/MultipleCheckboxesSelect'

import { filesApprovalStatusOptions, STATUS_FILTER_FILES_OVERDUE } from '../BookedMediaStatusFilter/options'
import { fileStatusFilterOptions } from './options'

import useStyles from './styles'

const BookedMediaMultipleFileStatusesFilter = ({ Context }) => {
  const classes = useStyles()

  const { selectedFileStatuses, setSelectedFileStatuses } = useContext(Context)

  const [selectedOptions, setSelectedOptions] = useState([])

  const selectStatusHandler = useCallback(
    data => {
      setSelectedFileStatuses(data)
    },
    [setSelectedFileStatuses]
  )

  const formattedOptions = useMemo(() => {
    const isFilesOverdueIncluded = selectedOptions.includes(STATUS_FILTER_FILES_OVERDUE)
    if (isFilesOverdueIncluded) {
      return fileStatusFilterOptions.map(item => {
        const isFileApprovalStatus = filesApprovalStatusOptions.some(option => option.value === item.value)
        if (isFileApprovalStatus) {
          // if Files Overdue is selected then disable all file approval statuses
          return { ...item, isDisabled: true }
        }
        return item
      })
    } else {
      return fileStatusFilterOptions
    }
  }, [selectedOptions])

  const modifySelectedFileStatusesHandler = useCallback(options => {
    setSelectedOptions(options)
    const isFilesOverdueIncluded = options.includes(STATUS_FILTER_FILES_OVERDUE)
    const someOfFilesApprovalStatusesIncluded = filesApprovalStatusOptions.some(option =>
      options.includes(option.value)
    )
    if (isFilesOverdueIncluded && someOfFilesApprovalStatusesIncluded) {
      // if Files Overdue is selected then remove all file approval statuses
      const filesApprovalStatusOptionsValues = filesApprovalStatusOptions.map(option => option.value)
      return [...options].filter(status => !filesApprovalStatusOptionsValues.includes(status))
    } else {
      return options
    }
  }, [])

  return (
    <MultipleCheckboxesSelect
      className={classes.statusFilter}
      options={formattedOptions}
      value={selectedFileStatuses}
      onChange={selectStatusHandler}
      placeholder="File Status"
      multipleOptionsLabel="File Status"
      isSearchable={false}
      modifySelectedOptions={modifySelectedFileStatusesHandler}
    />
  )
}

BookedMediaMultipleFileStatusesFilter.propTypes = {
  Context: PropTypes.object.isRequired
}

export default BookedMediaMultipleFileStatusesFilter
