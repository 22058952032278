import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import ProgressButton from '../../../../../components/Form/ProgressButton'

import { deleteEvent } from '../../../../../modules/actions/calendarPlanner'
import {
  calendarEventDeleteErrorSelector,
  calendarEventDeleteIsLoadingSelector,
  calendarEventDeletedIdSelector
} from '../../../../../modules/selectors/calendarPlanner'

import useStyles from './styles'

// row is the same as event_group
function DeleteCalendarEvent({ eventId, eventGroupId }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const error = useSelector(calendarEventDeleteErrorSelector)
  const successDelete = useSelector(calendarEventDeletedIdSelector)
  const isLoading = useSelector(calendarEventDeleteIsLoadingSelector)

  const handleDeleteEvent = useCallback(() => {
    dispatch(deleteEvent(parseInt(eventId), eventGroupId))
  }, [dispatch, eventId, eventGroupId])

  return (
    <ProgressButton
      transparent
      onClick={handleDeleteEvent}
      formError={error}
      isFormLoading={isLoading}
      successSubmit={successDelete}
      clearHandler={() => {}}
      solid={false}
      className={classes.deleteBtn}
    >
      {t('Delete event')}
    </ProgressButton>
  )
}

DeleteCalendarEvent.propTypes = {
  eventId: PropTypes.number,
  eventGroupId: PropTypes.number
}

export default DeleteCalendarEvent
