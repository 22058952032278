import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { clearSignContract, signContract } from '../../../../../modules/actions/contracts'
import {
  signContractErrorSelector,
  signContractIsLoadingSelector,
  signContractWasCreatedSelector
} from '../../../../../modules/selectors/contracts'

const propTypes = {
  contractId: PropTypes.number.isRequired,
  tokenParams: PropTypes.object
}

export function useContractSign(props) {
  const dispatch = useDispatch()
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'useContractSign')
  const { contractId, tokenParams } = props

  const handleSubmit = useCallback(
    data => {
      dispatch(signContract(contractId, data, tokenParams))
    },
    [contractId, dispatch, tokenParams]
  )

  const clearHandler = useCallback(() => {
    dispatch(clearSignContract())
  }, [dispatch])

  return useMemo(
    () => ({
      clearHandler,
      handleSubmit,
      contractWasSignedSelector: signContractWasCreatedSelector,
      errorSelector: signContractErrorSelector,
      isLoadingSelector: signContractIsLoadingSelector
    }),
    [clearHandler, handleSubmit]
  )
}
