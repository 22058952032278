import { createStyles } from '../../../../styles/helpers'
import { tabletDown } from '../../../../styles/const/breakpoints'
import { container } from '../../../../styles/common'

const useStyles = createStyles({
  pageMain: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0
  },
  pageContainer: {
    maxWidth: 'none'
  },
  containerWrapper: {
    padding: '0 48px'
  },
  headerContainerWrapper: {
    background: 'white'
  },
  headerContainer: {
    ...container,
    paddingTop: 40,
    paddingBottom: 32
  },
  header: {
    fontSize: 24
  },

  [`@media screen and (${tabletDown})`]: {
    containerWrapper: {
      padding: '0px 24px'
    }
  }
})

export default useStyles
