import * as Yup from 'yup'
import { DISPLAY_NAME, IDENTITY_ICON_RATIO, IDENTITY_ICON_URL } from './fields'

export const validationSchema = Yup.object({
  [DISPLAY_NAME]: Yup.string().required('Identity Name required'),
  [IDENTITY_ICON_URL]: Yup.string().required('Please select Icon file')
})

// identity icon should be square
export const identityIconValidationSchema = Yup.object().shape({
  [IDENTITY_ICON_RATIO]: Yup.number().test(IDENTITY_ICON_RATIO, `Icon ratio should be 1:1`, ratio => {
    // rounded for 2 decimal places
    return Math.round(ratio * 100) / 100 === 1
  })
})
