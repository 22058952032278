import { call, put, all, takeEvery, select } from 'redux-saga/effects'

import {
  GET_AD_ACCOUNTS,
  getAdAccountsSuccess,
  getAdAccountsFailure,
  GET_AD_ACCOUNT,
  getAdAccountSuccess,
  getAdAccountFailure
} from '../actions/adAccounts'

import { getAdAccountsService, getAdAccountService } from '../services/adAccounts'
import { selectedPlatformSelector } from '../selectors/app'

function* adAccountsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_AD_ACCOUNTS, getAdAccountsWorker),
    takeEvery(GET_AD_ACCOUNT, getAdAccountWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getAdAccountsWorker() {
  try {
    const platform = yield select(selectedPlatformSelector)

    const response = yield call(getAdAccountsService, platform)
    yield put(getAdAccountsSuccess(response))
  } catch (e) {
    yield put(getAdAccountsFailure(e))
  }
}

function* getAdAccountWorker({ id, platform }) {
  try {
    // add logic for selecting current platform (not passed) if needed in the future
    const response = yield call(getAdAccountService, id, platform)

    // some ad account data doesn't have platform field, so we add it manually
    const enhancedResponse = {
      ...response,
      platform
    }

    yield put(getAdAccountSuccess(enhancedResponse, platform))
  } catch (e) {
    yield put(getAdAccountFailure(e, platform))
  }
}

export default adAccountsWatcher
