import { v4 as uuidv4 } from 'uuid'
import { DISCOUNT_PERCENTAGE, DISCOUNTS, NAME, PERIODS, QUANTITY } from '../../fields'

export const newDiscountRowInitialValues = {
  [QUANTITY]: '',
  [DISCOUNT_PERCENTAGE]: '',
  [PERIODS]: ''
}

export const initialValues = {
  [NAME]: '',
  [DISCOUNTS]: [
    {
      ...newDiscountRowInitialValues,
      id: uuidv4()
    }
  ]
}
