import { madeRequest } from '../../helpers/api'

import { ENDPOINTS } from '../../constants/api'
import { replaceRouteParams } from '../../helpers/url'

// PLOP_APPEND_PATTERN_ANCHOR

export function createQuotationService(id, data) {
  // to create a quotation on BE we actually update the contract and set quotation field to true
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.contracts}${id}/`
  })
}

export function getQuotationsService(params) {
  return madeRequest('GET', {
    params: {
      ...params,
      // Quotations are just a contract with quotation field set to true,
      // so we use the same endpoint, and pass quotation: true
      quotation: true
    },
    url: ENDPOINTS.contracts
  })
}

export function sendQuotationService(data, id) {
  return madeRequest('POST', {
    data,
    url: replaceRouteParams(ENDPOINTS.sendQuotation, { id })
  })
}

export function resendQuotationService(id) {
  return madeRequest('POST', {
    url: replaceRouteParams(ENDPOINTS.resendQuotation, { id })
  })
}

export function updateQuotationService(id, data) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.contracts}${id}/`
  })
}

export function updateQuotationsStatusBulkService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.contractsStatusBulk
  })
}
