import * as Yup from 'yup'

import { VIDEO_ADS_URL, youtubeVideoIdUrlParams } from '../../../../fields'

import { youtubeLinkShapeRequired } from '../../../validations'

export const getInitialValues = assets => {
  return {
    [VIDEO_ADS_URL]: assets.map(({ asset_data }) => ({
      videoId: asset_data.youtube_video_asset.youtube_video_id,
      id: asset_data.youtube_video_asset.youtube_video_id,
      text: 'https://www.youtube.com/' + youtubeVideoIdUrlParams + asset_data?.youtube_video_asset?.youtube_video_id
    }))
  }
}

export const validationSchema = Yup.object({
  [VIDEO_ADS_URL]: Yup.array().of(youtubeLinkShapeRequired)
})
