import * as Yup from 'yup'

import { NAME, CPM_BID, SET_CPM_BID } from './fields'
import { OPTION_YES } from '../../../../../../constants/forms'

export const cpmBidValidation = Yup.number()
  .required('Please set maximum CPM bid')
  .min(0.01, 'CPM bid should be at least 0.01')

export const validationSchema = Yup.object({
  [NAME]: Yup.string().required('Name required'),
  [CPM_BID]: Yup.number().when(SET_CPM_BID, {
    is: OPTION_YES,
    then: () => cpmBidValidation
  })
})
