import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import OriginStep from './Steps/OriginStep'
import ShopperTypeStep from './Steps/ShopperTypeStep'
import ShopperSelectionStep from './Steps/ShopperSelectionStep'
import EventSourceStep from './Steps/EventSourceStep'
import FacebookPageStep from './Steps/FacebookPageStep'
import InstagramPageStep from './Steps/InstagramPageStep'
import TargetStep from './Steps/TargetStep'
import PagesStep from './Steps/PagesStep'
import DurationStep from './Steps/DurationStep'
import NameStep from './Steps/NameStep'
import StepForm from '../../../../features/components/Forms/StepForm'
import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import TravelerTypeStep from './Steps/TravelerTypeStep'
import FlightRoutesStep from './Steps/FlightRoutesStep'
import AirlineDurationStep from './Steps/AirlineDurationStep'
import PassengerStatusStep from './Steps/PassengerStatusStep'

import { usePurifiedFormik } from '../../../../hooks/formHooks/usePurifiedFormik'

import { insertIf } from '../../../../helpers/common'

import { clearCreateAudience, createAudience } from '../../../../modules/actions/audiences'
import {
  createAudienceIsLoadingSelector,
  createAudienceWasCreatedSelector,
  createAudienceErrorSelector
} from '../../../../modules/selectors/audiences'
import { choicesPixelsSelector } from '../../../../modules/selectors/choices'
import { selectedAdAccountIdSelector } from '../../../../modules/selectors/app'

import {
  ORIGIN_OPTION,
  YOUR_WEBSITE,
  FACEBOOK_USERS,
  INSTAGRAM_USERS,
  initialValues,
  TARGET_OPTION,
  SPECIFIC_VISITORS,
  transformValuesToBE,
  SUPERMARKET,
  AIRLINE
} from './fields'
import { AUDIENCE_CREATE } from '../../../../constants/forms'
import { validationSchema } from './validation'

const AudienceCreate = () => {
  const dispatch = useDispatch()

  const [supermarketRouteWasPreselected, setSupermarketRouteWasPreselected] = useState(false)

  const account = useSelector(selectedAdAccountIdSelector)
  const audienceWasCreated = useSelector(createAudienceWasCreatedSelector)
  const pixels = useSelector(choicesPixelsSelector)

  // user for supermarket route payload values formatter
  const firstPixelId = pixels.length && pixels[0].id

  const onSubmit = values => {
    dispatch(createAudience(transformValuesToBE(values, account, firstPixelId)))
  }

  const onAfterFormClose = useCallback(() => {
    // reset inner state
    supermarketRouteWasPreselected && setSupermarketRouteWasPreselected(false)
  }, [supermarketRouteWasPreselected])

  const clearCreateAudienceSubmit = useCallback(() => {
    dispatch(clearCreateAudience())

    // reset inner state
    supermarketRouteWasPreselected && setSupermarketRouteWasPreselected(false)
  }, [dispatch, supermarketRouteWasPreselected])

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)
  const { values, setFieldValue } = purifiedFormik

  const originOption = values[ORIGIN_OPTION]
  const targetOption = values[TARGET_OPTION]

  const supermarketRoute = useMemo(
    () => [
      {
        component: <ShopperTypeStep />,
        show: true
      },
      {
        component: <ShopperSelectionStep />,
        show: true
      }
    ],
    []
  )

  const searchOrBookRoute = useMemo(
    () => [
      {
        component: <FlightRoutesStep />,
        show: true
      },
      {
        component: <AirlineDurationStep />,
        show: true
      },
      {
        component: <PassengerStatusStep />,
        show: true
      }
    ],
    []
  )

  const airlineRoute = useMemo(
    () => [
      {
        component: <TravelerTypeStep />,
        show: true
      },
      // todo: implement switching between traveler types routes,
      //  currently only search_or_book is available
      ...searchOrBookRoute
    ],
    [searchOrBookRoute]
  )

  const yourWebsiteRoute = useMemo(
    () => [
      {
        component: <EventSourceStep />,
        show: true
      },
      {
        component: <TargetStep />,
        show: true
      },
      {
        component: <PagesStep />,
        show: targetOption === SPECIFIC_VISITORS
      }
    ],
    [targetOption]
  )

  const yourFacebookRoute = useMemo(
    () => [
      {
        component: <FacebookPageStep />,
        show: true
      }
    ],
    []
  )

  const yourInstagramRoute = useMemo(
    () => [
      {
        component: <InstagramPageStep />,
        show: true
      }
    ],
    []
  )

  const [selectedRoute, setSelectedRoute] = useState(yourWebsiteRoute)

  const isSupermarketRoute = originOption === SUPERMARKET
  const isAirlineRoute = originOption === AIRLINE

  const steps = useMemo(
    () => [
      {
        component: <OriginStep />,
        show: true
      },
      ...selectedRoute,
      ...insertIf(
        !isSupermarketRoute && !isAirlineRoute,
        {
          component: <DurationStep />,
          show: true
        },
        {
          component: <NameStep />,
          show: true
        }
      )
    ],
    [selectedRoute, isSupermarketRoute, isAirlineRoute]
  )

  useEffect(() => {
    // preselect supermarket route if it's allowed
    if (!supermarketRouteWasPreselected) {
      setFieldValue(ORIGIN_OPTION, SUPERMARKET)
      setSupermarketRouteWasPreselected(true)
    }
  }, [setFieldValue, supermarketRouteWasPreselected])

  useEffect(() => {
    switch (originOption) {
      case SUPERMARKET:
        setSelectedRoute(supermarketRoute)
        break
      case AIRLINE:
        setSelectedRoute(airlineRoute)
        break
      case YOUR_WEBSITE:
        setSelectedRoute(yourWebsiteRoute)
        break
      case FACEBOOK_USERS:
        setSelectedRoute(yourFacebookRoute)
        break
      case INSTAGRAM_USERS:
        setSelectedRoute(yourInstagramRoute)
        break
      default:
        setSelectedRoute(yourWebsiteRoute)
    }
  }, [originOption, yourWebsiteRoute, yourFacebookRoute, yourInstagramRoute, supermarketRoute, airlineRoute])

  return (
    <FormDrawerWrapper openButtonText={'New Audience'} formName={AUDIENCE_CREATE} onAfterFormClose={onAfterFormClose}>
      <StepForm
        formik={purifiedFormik}
        steps={steps}
        formName={AUDIENCE_CREATE}
        successSubmit={audienceWasCreated}
        clearSubmitHandler={clearCreateAudienceSubmit}
        errorSelector={createAudienceErrorSelector}
        isLoadingSelector={createAudienceIsLoadingSelector}
      />
    </FormDrawerWrapper>
  )
}

export default AudienceCreate
