import { createStyles } from '../../../../../../../../styles/helpers'

export default createStyles({
  submitBtn: {
    display: 'block',
    margin: '0 auto'
  },
  sameProductWarning: {
    textAlign: 'center',
    marginBottom: 20
  }
})
