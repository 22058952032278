import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { endOfDay, startOfDay } from 'date-fns'

import TableActions from './TableActions'
import ProductsTable from './ProductsTable'
import ProductsCalendarPreview from '../../../../../pages/Proposals/ProductsCalendarPreview'

import { ProductsCalendarProvider } from '../../../../../pages/Proposals/ProductsCalendarPreview/ProductsCalendarContext'
import { MediaOrderFormContext } from '../../MediaOrderFormContext'

import { MEDIA_PRODUCTS, PRODUCT_GROUPED_PUBLICATIONS_DATES } from '../fields'

function ProductsManage({ formik, isAdditionalInfoEdit, allowEdit, allowAutoSave, checkInventory, isAmendment }) {
  const { currency } = useContext(MediaOrderFormContext)

  const { values } = formik

  const calendar = {
    earliestDate: new Date(),
    latestDate: new Date(),
    products: []
  }
  const selectedProducts = values[MEDIA_PRODUCTS]
  const hasProductWithData = selectedProducts.some(product => product[PRODUCT_GROUPED_PUBLICATIONS_DATES].length > 0)

  // show only products row with data(selected product)
  const formattedProducts = selectedProducts
    .filter(product => Boolean(product.data))
    .map(product => {
      return {
        group: {
          id: product.id,
          name: product.data?.name,
          asset_id: product.data?.internal_id
        },
        events: product[PRODUCT_GROUPED_PUBLICATIONS_DATES].map(periods => {
          const startDate = new Date(periods[0].date_start)
          const endDate = new Date(periods[periods.length - 1].date_end)

          // check if startDate is earlier then the calendar.start date
          if (startDate < calendar.earliestDate) {
            calendar.earliestDate = startDate
          }
          // check if endDate is later then the calendar.end date
          if (endDate > calendar.latestDate) {
            calendar.latestDate = endDate
          }

          return {
            ...periods,
            currency,
            start_timestamp: +startOfDay(startDate), // use timestamps instead of dates
            // take the full end date (end of the day) to show the event on the full day
            end_timestamp: +endOfDay(endDate) //  use timestamps instead of dates
          }
        })
      }
    })

  return (
    <>
      <ProductsCalendarProvider calendar={calendar}>
        {hasProductWithData && (
          <ProductsCalendarPreview
            calendar={{
              ...calendar,
              products: formattedProducts
            }}
          />
        )}
      </ProductsCalendarProvider>
      <TableActions />
      <ProductsTable
        formik={formik}
        isAdditionalInfoEdit={isAdditionalInfoEdit}
        allowEdit={allowEdit}
        allowAutoSave={allowAutoSave}
        checkInventory={checkInventory}
        isAmendment={isAmendment}
      />
    </>
  )
}

ProductsManage.propTypes = {
  formik: PropTypes.object.isRequired,
  isAdditionalInfoEdit: PropTypes.bool,
  allowEdit: PropTypes.bool,
  allowAutoSave: PropTypes.bool,
  isAmendment: PropTypes.bool,
  checkInventory: PropTypes.bool
}

export default ProductsManage
