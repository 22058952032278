import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import TableDataLoader from '../../../../../components/Table/TableDataLoader'
import MediaTagsTableData from './MediaTagsTableData'

import {
  productTagsErrorSelector,
  productTagsIsLoadingSelector,
  productTagsWasLoadedSelector
} from '../../../../../modules/selectors/tags'

import { phonesDownSize } from '../../../../../styles/const/breakpoints'

import useCommonStyles from '../../../../../styles/common/listPage'

export const tableColumnsSize = {
  actions: 30
}

const spaceForMargin = 5

const mobileColumnsSchema = [
  { style: { maxWidth: `calc(${tableColumnsSize.dragBtn}px - ${spaceForMargin}px)`, marginInlineEnd: spaceForMargin } },
  { style: { maxWidth: '100%' } }
]

const MediaTagsTable = ({ tags, handleDragEnd }) => {
  const commonClasses = useCommonStyles()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const { t } = useTranslation()

  const tagColumnsSchema = useMemo(
    () => [
      {
        fieldKey: 'title'
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no tags')}</div>
        <br />
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <TableDataLoader
      itemsLength={tags.length}
      errorSelector={productTagsErrorSelector}
      wasLoadedSelector={productTagsWasLoadedSelector}
      isLoadingSelector={productTagsIsLoadingSelector}
      skeletonProps={{ cols: isMobile ? mobileColumnsSchema : tagColumnsSchema }}
      noDataContent={noDataContent}
    >
      <MediaTagsTableData tags={tags} onOrderChange={handleDragEnd} />
    </TableDataLoader>
  )
}

MediaTagsTable.propTypes = {
  tags: PropTypes.array.isRequired,
  handleDragEnd: PropTypes.func.isRequired
}

export default MediaTagsTable
