import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  quotationsProductsTable: {
    // workaround to have spacing between end of the screen and DatesSelector when it in the end of the screen
    paddingBottom: '240px'
  }
})

export default useStyles
