import { createStyles } from '../../../styles/helpers'

import { grey } from '../../../styles/const/colors'

const useStyles = createStyles({
  maxLengthMessage: {
    color: grey,
    fontSize: 10,
    paddingTop: 4,
    paddingLeft: 8,
    lineHeight: '14px',
    textAlign: 'left',
    marginBottom: 5
  }
})

export default useStyles
