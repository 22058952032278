import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import ActionsDropdown from '../../../../../../features/components/ActionsDropdown'
import NameField from './NameField'
import Table from '../../../../../../components/Table'
import DueDateField from './DueDateField'
import ModalForDisapprovalReason from './ModalForDisapprovalReason'
import ButtonColumn from './ButtonColumn'
import FileStatusCell from './FileStatusCell'

import useGetAccessibleUrl from '../../../../../../features/hooks/useGetAccessibleUrl'
import { useGetUpdatingId, useHandleDeleteUploadFile, useOpenFileForm, useGetDropdownOptions } from './hooks'
import { useChangeFileStatusOnDisapproved } from '../../../../../../features/hooks/bookedMediaUploadedFilesHooks'

import { updateBookedMediaFileWasUpdatedSelector } from '../../../../../../modules/selectors/mediaOrdersBookings'
import { clearUpdateBookingMediaFile } from '../../../../../../modules/actions/mediaOrdersBookings'

import { formatDateFullYear } from '../../../../../../constants/dates'

import { tableColumnsSize } from '../index'

const RequestedFilesTableData = ({ files }) => {
  const dispatch = useDispatch()

  const [showDisapprovalReasonModal, setShowDisapprovalReasonModal] = useState(false)
  const [selectedRequirementId, setSelectedRequirementId] = useState('')
  const [selectedUploadedFile, setSelectedUploadedFile] = useState({})

  const { handleFileDownloading } = useGetAccessibleUrl()

  const bookedMediaFileWasUpdated = useSelector(updateBookedMediaFileWasUpdatedSelector)

  const handleFileDownload = useCallback(
    uploadedFile => () => {
      handleFileDownloading(uploadedFile?.file_url)
    },
    [handleFileDownloading]
  )

  const handleShowModal = (requirementId, uploadedFile) => () => {
    setShowDisapprovalReasonModal(true)
    setSelectedRequirementId(requirementId)
    setSelectedUploadedFile(uploadedFile)
  }

  const handleCloseModal = () => {
    setShowDisapprovalReasonModal(false)
    setSelectedRequirementId('')
    setSelectedUploadedFile({})
  }

  const getItemUpdatingId = useGetUpdatingId()

  const openUploadFileForm = useOpenFileForm()

  const handleDelete = useHandleDeleteUploadFile()

  const handleChangeStatusOnDisapproval = useChangeFileStatusOnDisapproved()

  const getDropdownOptions = useGetDropdownOptions({
    handleDelete,
    openUploadFileForm,
    handleFileDownload,
    handleShowModal
  })

  const requestedFilesColumns = useMemo(
    () => [
      {
        header: 'Requested file',
        Cell: ({ required: requirements, uploaded: uploadedFile, requirementFileId: id, mediaId }) => {
          return (
            <NameField
              formats={requirements?.supported_file_types}
              uploadedFile={uploadedFile}
              requirementsFileName={requirements?.file_name}
              id={id}
              mediaId={mediaId}
              handleFileDownloading={handleFileDownloading}
            />
          )
        }
      },
      {
        header: 'Period Start',
        Cell: ({ dateStart }) => formatDateFullYear(dateStart),
        style: { maxWidth: tableColumnsSize.mediaPeriod }
      },
      {
        header: 'Status',
        Cell: ({ uploaded: uploadedFile }) => {
          return <FileStatusCell uploadedFile={uploadedFile} />
        },
        style: { width: tableColumnsSize.status, minWidth: tableColumnsSize.status }
      },
      {
        header: 'Due date',
        Cell: ({ deadline, uploaded: uploadedFile }) => (!uploadedFile ? <DueDateField deadline={deadline} /> : ''),
        style: { maxWidth: tableColumnsSize.dueDate }
      },
      {
        Cell: ({ requirementFileId, mediaId, uploaded: uploadedFile, deadline }) => (
          <ButtonColumn
            requirementFileId={requirementFileId}
            uploadedFile={uploadedFile}
            mediaId={mediaId}
            deadline={deadline}
            handleDelete={handleDelete}
            openUploadFileForm={openUploadFileForm}
          />
        ),
        style: { maxWidth: tableColumnsSize.uploadButton }
      },
      {
        Cell: ({ mediaId, requirementFileId, uploaded: uploadedFile }) => {
          return (
            <ActionsDropdown
              itemId={requirementFileId}
              options={getDropdownOptions({ requirementFileId, mediaId, uploadedFile })}
            />
          )
        },
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    [openUploadFileForm, handleFileDownloading, getDropdownOptions, handleDelete]
  )

  useEffect(() => {
    if (bookedMediaFileWasUpdated) {
      dispatch(clearUpdateBookingMediaFile())
    }
  }, [dispatch, bookedMediaFileWasUpdated])

  return (
    <>
      <Table hideFooterRow data={files} cols={requestedFilesColumns} itemUpdatingId={getItemUpdatingId} />

      <ModalForDisapprovalReason
        onSubmit={handleChangeStatusOnDisapproval}
        requirementFileId={selectedRequirementId}
        uploadedFile={selectedUploadedFile}
        onClose={handleCloseModal}
        isOpen={showDisapprovalReasonModal}
      />
    </>
  )
}

RequestedFilesTableData.propTypes = {
  files: PropTypes.array.isRequired
}

export default RequestedFilesTableData
