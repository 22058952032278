import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import SingleMediaForm from './SingleMediaForm'
import CarouselMediaForm from './CarouselMediaForm'
import EditForm from '../../../../../../../features/components/Forms/EditForm'

import useEditAdPreview from '../../../../../../../features/hooks/useEditAdPreview'
import { usePurifiedFormik } from '../../../../../../../hooks/formHooks/usePurifiedFormik'

import { getCreative, getInitialValuesByFormat } from './helpers'
import { getAdFormat } from '../../../helpers'

import { clearUpdateAd, updateAd } from '../../../../../../../modules/actions/ads'
import {
  adUpdateErrorSelector,
  adUpdateIsLoadingSelector,
  adWasUpdatedSelector
} from '../../../../../../../modules/selectors/ads'
import { selectedAdAccountIdSelector } from '../../../../../../../modules/selectors/app'

import { AD_FORMAT } from '../../../../../../../constants/ads'
import { FACEBOOK_PLATFORM } from '../../../../../../../constants/selectLists/platformList'
import { initialFileValues, initialMediaValues } from '../../../../fields'
import { getValidationSchema } from './validation'

const MediaForm = ({ editItemData: originalAd, adValues, adFormat, isInternalAd, ...formProps }) => {
  const dispatch = useDispatch()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const successUpdate = useSelector(adWasUpdatedSelector)

  const handleSubmit = useCallback(
    modifiedAdValues => {
      const modifiedAdFormat = getAdFormat(modifiedAdValues)

      const { id, creative } = originalAd

      const updateAdData = {
        ...originalAd,
        account: selectedAdAccountId,
        creative: {
          account_id: selectedAdAccountId,
          ...creative,
          ...getCreative({
            values: modifiedAdValues,
            originalAd,
            adFormat: modifiedAdFormat,
            adAccountId: selectedAdAccountId
          })
        },
        ...(isInternalAd && { internal: true })
      }

      // Some tracking_specs leads to an error, so we completely remove it from payload when do PUT request
      delete updateAdData.tracking_specs

      // for media edits PUT method is used
      dispatch(updateAd(updateAdData, id, 'PUT', FACEBOOK_PLATFORM))
    },
    [dispatch, isInternalAd, originalAd, selectedAdAccountId]
  )

  const validationSchema = getValidationSchema()

  const initialValues = useMemo(
    () => ({
      // file
      ...initialFileValues,
      ...initialMediaValues,
      ...getInitialValuesByFormat(adValues, adFormat)
    }),
    [adFormat, adValues]
  )

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)
  const { values: modifiedAdValues } = purifiedFormik

  useEditAdPreview(modifiedAdValues)

  const handleClearUpdateAd = useCallback(() => {
    dispatch(clearUpdateAd())
  }, [dispatch])

  const modifiedAdFormat = getAdFormat(modifiedAdValues)

  const getFormByAdFormat = useCallback(() => {
    switch (modifiedAdFormat) {
      case AD_FORMAT.carousel:
      case AD_FORMAT.carouselCustom:
        return <CarouselMediaForm formik={purifiedFormik} />
      case AD_FORMAT.single:
      case AD_FORMAT.singleCustom:
      default:
        return <SingleMediaForm formik={purifiedFormik} adFormat={adFormat} />
    }
  }, [adFormat, modifiedAdFormat, purifiedFormik])

  return (
    <EditForm
      formik={purifiedFormik}
      method="post"
      initialValues={initialValues}
      successSubmit={successUpdate}
      clearEditItem={handleClearUpdateAd}
      errorSelector={adUpdateErrorSelector}
      isLoadingSelector={adUpdateIsLoadingSelector}
      submitText="Save"
      {...formProps}
    >
      {getFormByAdFormat()}
    </EditForm>
  )
}

export default MediaForm
