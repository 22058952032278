import { createStyles } from '../../../../styles/helpers'

import { transitionShort } from '../../../../styles/const/common'
import { bannerGrey, mediumGrey } from '../../../../styles/const/colors'

const useStyles = createStyles(theme => ({
  arrowIcon: {
    marginLeft: 10
  },
  dropdownBody: {
    border: 'none !important',
    background: 'transparent !important',
    paddingTop: 2
  },
  dropdownContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    background: 'white',
    border: `1px solid ${mediumGrey}`,
    borderRadius: theme.defaultBorderRadius
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: 20,
    padding: '18px 16px 18px 24px',
    fontWeight: 600,
    cursor: 'pointer',
    position: 'relative',
    transition: transitionShort,
    '&:not(:last-child)': {
      borderBottom: `1px solid ${mediumGrey}`
    },
    '&:hover': {
      background: bannerGrey,
      '& > $dropdownItemArrowIcon': {
        opacity: 1
      }
    }
  },
  dropdownItemMainPart: {
    display: 'flex',
    alignItems: 'center'
  },
  dropdownItemArrowIcon: {
    transform: 'rotate(-90deg)',
    opacity: 0,
    transition: transitionShort,
    '& path': {
      fill: theme.brandPrimary
    }
  },
  platformIcon: {
    width: 20,
    maxHeight: 20,
    marginRight: 10,
    transform: 'translateY(-1px)',
    color: theme.brandPrimary
  }
}))

export default useStyles
