import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import StepForm from '../../../../../../../../features/components/Forms/StepForm'
import DatesStep from './Steps/DatesStep'
import GeolocationsStep from './Steps/GeolocationsStep'

import { usePurifiedFormik } from '../../../../../../../../hooks/formHooks/usePurifiedFormik'
import { transformValuesToBE } from './formatters'
import {
  useAllowExistingLineItems,
  useManageExistingLineItemFormStep,
  useManageExistingLineItemFormSubmit
} from '../../../hooks'

import {
  lineItemCreatedDataSelector,
  lineItemCreateErrorSelector,
  lineItemCreateIsLoadingSelector,
  lineItemsSelector,
  lineItemWasCreatedSelector
} from '../../../../../../../../modules/selectors/lineItems'
import { activeStepSelector } from '../../../../../../../../modules/selectors/forms'
import { mediaOrderUploadCreativeDataSelector } from '../../../../../../../../modules/selectors/mediaOrders'
import { setMediaOrderUploadCreative } from '../../../../../../../../modules/actions/mediaOrders'
import { clearCreateLineItem, createLineItem } from '../../../../../../../../modules/actions/lineItems'
import { locationListsSelector } from '../../../../../../../../modules/selectors/location'

import { FACEBOOK_PLATFORM } from '../../../../../../../../constants/selectLists/platformList'
import { LINE_ITEM_PROXIMITY_FORM } from '../../index'
import { getInitialValues, validationSchema } from './fields'

const LineItemFormContent = ({ adAccountId, onSuccessSubmit, onLineItemOptionChange }) => {
  const dispatch = useDispatch()

  const [successSubmit, setSuccessSubmit] = useState(false)

  const lineItems = useSelector(lineItemsSelector)
  const activeStep = useSelector(activeStepSelector)
  const lineItemFacebookCreatedData = useSelector(lineItemCreatedDataSelector)
  const lineItemWasCreated = useSelector(lineItemWasCreatedSelector)
  const { campaign } = useSelector(mediaOrderUploadCreativeDataSelector)
  const locationLists = useSelector(locationListsSelector)

  const { isLineItemsExist } = useAllowExistingLineItems(lineItems)

  const createLineItemHandler = useCallback(
    values => {
      const transformedData = transformValuesToBE({
        values,
        adAccountId,
        campaign,
        locationLists
      })

      dispatch(createLineItem(transformedData, FACEBOOK_PLATFORM))
    },
    [dispatch, adAccountId, campaign, locationLists]
  )

  const onSubmit = useManageExistingLineItemFormSubmit({ onSubmit: createLineItemHandler, setSuccessSubmit })

  const formik = useFormik({
    initialValues: getInitialValues(isLineItemsExist),
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)
  const { values } = formik

  const newLineItemSteps = useMemo(() => {
    return [
      {
        component: <GeolocationsStep />,
        show: true
      },
      {
        component: <DatesStep />,
        show: true
      }
    ]
  }, [])

  const steps = useManageExistingLineItemFormStep({
    values,
    newLineItemSteps,
    onLineItemOptionChange
  })

  const clearCreateLineItemHandler = useCallback(() => {
    dispatch(clearCreateLineItem())
  }, [dispatch])

  useEffect(() => {
    if (lineItemWasCreated) {
      dispatch(
        setMediaOrderUploadCreative({
          lineItem: lineItemFacebookCreatedData
        })
      )

      setSuccessSubmit(true)
    }
  }, [dispatch, lineItemWasCreated, lineItemFacebookCreatedData])

  return (
    <StepForm
      steps={steps}
      customActiveStep={activeStep + 1}
      customStepsForProgress={5}
      formik={purifiedFormik}
      formName={LINE_ITEM_PROXIMITY_FORM}
      submitText="Save and continue"
      onSuccessSubmit={onSuccessSubmit}
      clearSubmitHandler={clearCreateLineItemHandler}
      successSubmit={successSubmit}
      errorSelector={lineItemCreateErrorSelector}
      isLoadingSelector={lineItemCreateIsLoadingSelector}
    />
  )
}

export default LineItemFormContent
