import { useCallback, useEffect, useMemo, useState } from 'react'

export default function useLoadMore(loadMore, itemsIsLoading) {
  const [isLoadingMoreItems, setIsLoadingMoreItems] = useState(false)

  const loadMoreItemsHandler = useCallback(() => {
    loadMore()
    setIsLoadingMoreItems(true)
  }, [loadMore])

  const showLoadMoreButton = loadMore && !itemsIsLoading

  useEffect(() => {
    // reset Load more loading state to false after additional items loads
    if (!itemsIsLoading && isLoadingMoreItems) {
      setIsLoadingMoreItems(false)
    }
  }, [isLoadingMoreItems, itemsIsLoading])

  return useMemo(
    () => ({
      showLoadMoreButton,
      loadMoreItemsHandler,
      isLoadingMoreItems: isLoadingMoreItems && itemsIsLoading
    }),
    [showLoadMoreButton, loadMoreItemsHandler, isLoadingMoreItems, itemsIsLoading]
  )
}
