import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { ReactComponent as DownloadIcon } from '../../../../../../assets/icons/download.svg'

import ButtonTrash from '../../../../../../features/components/Buttons/ButtonTrash'
import ButtonIcon from '../../../../../../features/components/Buttons/ButtonIcon'
import FileFormatIcon from '../../../../../../features/components/Icons/FileFormatIcon'

import useGetAccessibleUrl from '../../../../../../features/hooks/useGetAccessibleUrl'

import { deleteBookingMediaFile } from '../../../../../../modules/actions/mediaOrdersBookings'
import { deleteBookingMediaFileSelector } from '../../../../../../modules/selectors/mediaOrdersBookings'

import useStyles from './styles'

const UploadedFile = ({ uploadedFile, isDeadlineInFuture }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const itemDelete = useSelector(deleteBookingMediaFileSelector)
  const isDeleting = itemDelete.isLoading && itemDelete.id === uploadedFile.id

  const allowDeleteFile = uploadedFile.approval_status !== 'approved' && isDeadlineInFuture

  const handleDelete = useCallback(() => {
    dispatch(
      deleteBookingMediaFile(uploadedFile.id, {
        booked_media: uploadedFile.booked_media
      })
    )
  }, [dispatch, uploadedFile.id, uploadedFile.booked_media])

  const { isAccessibleUrlLoading, handleFileDownloading } = useGetAccessibleUrl()

  const handleFileDownload = useCallback(() => {
    handleFileDownloading(uploadedFile.file_url)
  }, [uploadedFile.file_url, handleFileDownloading])

  return (
    <div className={classes.uploadedFile}>
      <div className={classes.previewContainer}>
        {uploadedFile.thumbnail ? (
          <img className={classes.filePreview} src={uploadedFile.thumbnail} alt="preview" />
        ) : (
          <FileFormatIcon fileFormat={uploadedFile.file_format} />
        )}
      </div>
      <div className={classes.uploadedFileControls}>
        <ButtonIcon
          className={classes.downloadIcon}
          onClick={handleFileDownload}
          Icon={DownloadIcon}
          isLoading={isAccessibleUrlLoading}
          hasBorder
        />
        {allowDeleteFile && !isDeleting && <ButtonTrash className={classes.deleteBtn} onClick={handleDelete} />}
      </div>
    </div>
  )
}

UploadedFile.propTypes = {
  uploadedFile: PropTypes.object,
  isDeadlineInFuture: PropTypes.bool
}

export default UploadedFile
