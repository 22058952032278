import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Table from '../../../../../../components/Table'
import ActionsButtonsList from '../../../../../../features/components/ActionsButtonsList'
import ActionsDropdown from '../../../../../../features/components/ActionsDropdown'
import { ReactComponent as PdfFileIcon } from '../../../../../../assets/icons/pdf-file.svg'

import { formatDateDetailed } from '../../../../../../constants/dates'

import { openForm } from '../../../../../../modules/actions/forms'
import { amendmentsListSelector } from '../../../../../../modules/selectors/amendments'
import {
  selectedControllerDataSelector,
  selectedSelfAccountDataSelector
} from '../../../../../../modules/selectors/app'

import { dropdownColumnSizes } from '../../../../../MediaOrders/MediaOrdersTable/columnSizes'

import { columnsSize } from '../index'
import { AMENDMENT_REVIEW } from '../../../../../../constants/forms'

import { phonesDownSize } from '../../../../../../styles/const/breakpoints'

const AmendmentsTableData = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const amendments = useSelector(amendmentsListSelector)
  const controllerData = useSelector(selectedControllerDataSelector)
  const selfAccountData = useSelector(selectedSelfAccountDataSelector)

  const controllerName = controllerData?.name
  const selfAccountName = selfAccountData?.name

  const viewAmendmentHandler = useCallback(
    (e, id) => {
      dispatch(openForm({ id, formName: AMENDMENT_REVIEW }))
    },
    [dispatch]
  )

  const dropdownOptions = useCallback(
    id => {
      return [
        {
          text: t('View'),
          onClickHandler: viewAmendmentHandler
        }
      ]
    },
    [t, viewAmendmentHandler]
  )

  const amendmentsColumns = useMemo(
    () => [
      {
        Cell: ({ id }) => <PdfFileIcon onClick={e => viewAmendmentHandler(e, id)} />,
        style: { maxWidth: columnsSize.icon, cursor: 'pointer' }
      },
      {
        header: 'Name',
        Cell: ({ name, id }) => {
          return (
            <div style={{ cursor: 'pointer' }} onClick={e => viewAmendmentHandler(e, id)}>
              {name}
            </div>
          )
        }
      },
      {
        header: t('controllerSignatory', { controllerName }),
        Cell: ({ controller_signature, controller_signature_name }) => {
          return !!controller_signature ? controller_signature_name : '-'
        },
        showOnMobile: false
      },
      {
        header: t('selfAccountSignatory', { selfAccountName }),
        Cell: ({ user_signature, user_signature_name }) => {
          return !!user_signature ? user_signature_name : '-'
        },
        showOnMobile: false
      },
      {
        header: 'Created',
        Cell: ({ created }) => (created ? formatDateDetailed(new Date(created)) : '-'),
        showOnMobile: false
      },
      {
        header: 'Signed',
        Cell: ({ user_signature_date }) =>
          user_signature_date ? formatDateDetailed(new Date(user_signature_date)) : '-',
        showOnMobile: false
      },
      {
        Cell: ({ id }) =>
          isMobile ? (
            <ActionsButtonsList itemId={id} options={dropdownOptions(id)} />
          ) : (
            <ActionsDropdown itemId={id} options={dropdownOptions(id)} closeOnInsideClick />
          ),
        style: isMobile ? { padding: 0 } : { ...dropdownColumnSizes },
        showOnMobile: false
      }
    ],
    [t, dropdownOptions, isMobile, viewAmendmentHandler, controllerName, selfAccountName]
  )

  return <Table data={amendments} cols={amendmentsColumns} hideFooterRow />
}

export default AmendmentsTableData
