import * as Yup from 'yup'

import {
  formatTimeStartToBE,
  START_DATE,
  START_OPTION,
  START_TIME,
  startTimeInitialValues,
  startTimeValidation
} from '../../../../ReusableFormFields/StartTimeFields/fields'
import {
  formatTimeStopToBE,
  STOP_DATE,
  STOP_OPTION,
  STOP_OPTION_INDEFINITELY,
  STOP_TIME,
  stopTimeInitialValues,
  stopTimeValidation
} from '../../../../ReusableFormFields/StopTimeFields/fields'
import { STATUS, STATUS_ACTIVE } from '../../../../ReusableFormFields/CampaignForms/fields'

export const DEEP_COPY_OPTION = 'deep_copy_option'
export const DEEP_COPY_YES = 'deep_copy_yes'
export const DEEP_COPY_NO = 'deep_copy_no'

export const initialValues = {
  // deep copy (ad sets, ads)
  [DEEP_COPY_OPTION]: DEEP_COPY_YES,
  // ad set start time
  ...startTimeInitialValues,
  // ad set stop time
  ...stopTimeInitialValues,
  // status
  [STATUS]: STATUS_ACTIVE
}

export const validationSchema = Yup.object({
  // ad set start time
  ...startTimeValidation(),
  // as set stop time
  ...stopTimeValidation
})

export const transformValuesToBE = (values, selectedAdAccountId) => {
  const {
    [START_OPTION]: startOption,
    [START_DATE]: startDate,
    [START_TIME]: startTime,
    [STOP_OPTION]: stopOption,
    [STOP_DATE]: stopDate,
    [STOP_TIME]: stopTime,
    [DEEP_COPY_OPTION]: deepCopyOption,
    [STATUS]: status
  } = values

  const isDeepCopy = deepCopyOption === DEEP_COPY_YES

  const formattedTimeStart = formatTimeStartToBE({ startOption, startDate, startTime })

  return {
    account: selectedAdAccountId,
    status_option: status.toUpperCase(),
    rename_options: {
      rename_strategy: 'ONLY_TOP_LEVEL_RENAME'
    },
    deep_copy: isDeepCopy,
    ...(isDeepCopy && { start_time: formattedTimeStart }),
    ...(isDeepCopy &&
      values[STOP_OPTION] !== STOP_OPTION_INDEFINITELY && {
        end_time: formatTimeStopToBE({ stopOption, stopDate, stopTime, timeStart: formattedTimeStart })
      })
  }
}
