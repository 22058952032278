import { createStyles } from '../../../styles/helpers'

export default createStyles({
  typingText: {
    borderRight: '2px solid #000',
    overflow: 'hidden',
    fontSize: '1em',
    animation: '$typing 4s steps(40, end) infinite, $blink-caret .8s step-end infinite'
  },

  '@keyframes typing': {
    '0%': {
      width: 0
    },
    '100%': {
      width: '100%'
    }
  },

  '@keyframes blink-caret': {
    '0%, 100%': {
      borderColor: 'transparent'
    },
    '50%': {
      borderColor: '#000;'
    }
  }
})
