import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import MediaProductsFilters from '../../../../../../../../features/components/mediaProductsComponents/MediaProductsFilters'
import ProductsSelectionList from '../../../../../../../../features/components/ProductsSelectionList'

import { useLoadFilteredAndPaginatedProducts } from '../../../../../../../../features/hooks/useLoadFilteredAndPaginatedProducts'

import { productsSelector } from '../../../../../../../../modules/selectors/mediaOrdersProducts'
import { mediaOrderSelector } from '../../../../../../../../modules/selectors/mediaOrders'

import useStyles from '../styles'

const productParams = {
  in_stock: true
}

function ProductSelection({ onProductSelect }) {
  const { t } = useTranslation()

  const products = useSelector(productsSelector)
  const mediaOrder = useSelector(mediaOrderSelector)
  const { currency } = mediaOrder

  const classes = useStyles()

  const additionalRequestParams = useMemo(
    () => ({
      ...productParams,
      currency_code: currency.code
    }),
    [currency.code]
  )

  const { loadMoreProducts, filters } = useLoadFilteredAndPaginatedProducts({
    additionalRequestParams,
    allowFetch: true
  })
  const selectedLocationId = filters.selectedLocations[0]

  return (
    <>
      <h2 className={classes.title}>{t('Please select a media product')}</h2>
      <p className={classes.description}>
        {t('Please select one of the following media products to add it to your media booking')}
      </p>
      <MediaProductsFilters filters={filters} showSearchField={true} />
      <ProductsSelectionList
        noProductsMessageClassName={classes.noProductsMessage}
        className={classes.productsGrid}
        currency={currency}
        availableProducts={products}
        onProductSelect={onProductSelect}
        loadMoreProducts={loadMoreProducts}
        selectedLocationId={selectedLocationId}
      />
    </>
  )
}

ProductSelection.propTypes = {
  onProductSelect: PropTypes.func.isRequired
}
export default ProductSelection
