import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Icon from '../../../Icon'
import FileFormatIcon from '../../../../features/components/Icons/FileFormatIcon'

import useHandleImageLoading from '../../../../hooks/useHandleImageLoading'

import useCommonStyles from '../styles'

const PreviewThumbnail = memo(function WrappedPreviewThumbnail({
  isImagePreview,
  previewUrl,
  FileIcon,
  iconColor,
  fileFormat
}) {
  const { t } = useTranslation()
  const classes = useCommonStyles({ iconColor, hasIcon: !!FileIcon })

  const { isMediaFailed, handleMediaFailed } = useHandleImageLoading(previewUrl)

  if ((!previewUrl && !FileIcon) || isMediaFailed) {
    // representing the placeholder svg file icon
    // instead of broken image when previewUrl is not provided or failed to load
    return <FileFormatIcon className={classes.icon} fileFormat={fileFormat} />
  }

  return FileIcon ? (
    <Icon className={classes.icon}>
      <FileIcon />
    </Icon>
  ) : isImagePreview ? (
    <img className={classes.preview} src={previewUrl} alt={t('filePreview')} onError={handleMediaFailed} />
  ) : (
    <video className={classes.preview} src={previewUrl} onError={handleMediaFailed} />
  )
})

PreviewThumbnail.propTypes = {
  isImagePreview: PropTypes.bool,
  previewUrl: PropTypes.string,
  fileFormat: PropTypes.string,
  // icon from library or react component from imported file
  FileIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  iconColor: PropTypes.string
}

export default PreviewThumbnail
