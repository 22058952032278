import { createStyles } from '../../../../../../styles/helpers'

export default createStyles(theme => ({
  googleMediaUpload: {
    marginTop: 16
  },

  // Redesigned GoogleMediaUpload styles
  imagesLibrary: {
    justifyContent: 'space-between'
  },
  mediaThumbnail: {
    marginRight: 'unset'
  },
  loadMoreButton: {
    height: 'unset',
    padding: 5,
    color: [theme.brandPrimary, '!important'],
    backgroundColor: ['transparent', '!important'],
    fontWeight: '400',
    margin: ['0 auto', '!important'],
    fontSize: 12
  }
}))
