import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import RadioBlock from '../../../../../components/Form/RadioBlock'
import ProgressButton from '../../../../../components/Form/ProgressButton'

import { selectedPlatformSelector, selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'
import {
  duplicateLineItemAsyncSessionIdSelector,
  duplicateLineItemErrorSelector,
  duplicateLineItemIsLoadingSelector,
  duplicateLineItemProcessWasStartedSelector
} from '../../../../../modules/selectors/lineItems'
import { duplicateLineItem } from '../../../../../modules/actions/lineItems'
import { getAsyncSession } from '../../../../../modules/actions/app'
import { campaignSelector } from '../../../../../modules/selectors/campaigns'

import { STATUS, STATUS_ACTIVE, STATUS_PAUSED, transformValuesToBE } from '../fields'

import useStyles from '../../../../../styles/common/stepForms'

function StatusStep({ formik, handleStepChange, selectedEditItemId }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const classes = useStyles()

  const { values, setFieldValue } = formik

  const selectedPlatform = useSelector(selectedPlatformSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const { id: currentCampaignId } = useSelector(campaignSelector)

  const duplicateLineItemError = useSelector(duplicateLineItemErrorSelector)
  const duplicateLineItemIsLoading = useSelector(duplicateLineItemIsLoadingSelector)
  const duplicateLineItemProcessWasStarted = useSelector(duplicateLineItemProcessWasStartedSelector)
  const duplicateLineItemAsyncSessionId = useSelector(duplicateLineItemAsyncSessionIdSelector)

  const duplicateLineItemHandler = useCallback(() => {
    const formattedValues = transformValuesToBE({
      values,
      currentCampaignId,
      adAccountId: selectedAdAccountId
    })

    dispatch(duplicateLineItem(formattedValues, selectedEditItemId, selectedPlatform))
  }, [dispatch, values, selectedEditItemId, selectedAdAccountId, selectedPlatform, currentCampaignId])

  const onDuplicateLineItemSuccess = useCallback(() => {
    handleStepChange()
    dispatch(getAsyncSession(duplicateLineItemAsyncSessionId, selectedPlatform, { account: selectedAdAccountId }))
  }, [dispatch, handleStepChange, duplicateLineItemAsyncSessionId, selectedPlatform, selectedAdAccountId])

  return (
    <>
      <div className={classes.stepTitle}>{t('Do you want the duplicated ad set to go live immediately?')}</div>
      <div className={classes.stepBody}>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_status_paused"
          name={STATUS}
          label={t('No, set it as paused')}
          value={STATUS_PAUSED}
          selectedValue={values[STATUS]}
        >
          <p>{t('Your ad set will not go live until you activate it later.')}</p>
        </RadioBlock>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_status_active"
          name={STATUS}
          label={t('Yes, set it as active')}
          value={STATUS_ACTIVE}
          selectedValue={values[STATUS]}
        >
          <p>{t('Your ad set will go live immediately upon ad approval.')}</p>
        </RadioBlock>
      </div>
      <div className={classes.stepFooter}>
        <ProgressButton
          type="button"
          onClick={duplicateLineItemHandler}
          solid
          isFormLoading={duplicateLineItemIsLoading}
          formError={duplicateLineItemError}
          successSubmit={duplicateLineItemProcessWasStarted}
          onSuccessSubmit={onDuplicateLineItemSuccess}
        >
          {t('Duplicate')}
        </ProgressButton>
      </div>
    </>
  )
}

export default StatusStep
