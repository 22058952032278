import { createStyles } from '../../../../../../styles/helpers'

const useAudienceSectionStyles = createStyles({
  sectionAgePickers: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > .field': {
      marginTop: 8
    },
    '& > div': {
      flex: 1
    },
    '& > div:nth-child(1)': {
      marginRight: 8
    }
  }
})

export default useAudienceSectionStyles
