import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import SkeletonTableRow from '../../TableRows/TableRowWrapper/Skeleton'

// this is items loading component for Table items
const ItemsLoading = React.memo(
  ({
    isLoadingMoreItems,
    isFilteringRunning,
    itemsLength = 0,
    isInitialLoading,
    // be careful setting big numbers due to performance issues with a big amount of skeletons
    initialLoadingSkeletonsNumber = 3,
    filteringLoadingSkeletonsNumber,
    skeletonProps,
    className
  }) => {
    // show if first initial loading or when loading more items
    const showBasicLoading = useMemo(
      () => isInitialLoading || isLoadingMoreItems,
      [isInitialLoading, isLoadingMoreItems]
    )

    // filtering means that items were loaded, but new request running (some filters were changed)
    const showFilteringLoading = useMemo(
      () => !isInitialLoading && !isLoadingMoreItems && isFilteringRunning,
      [isFilteringRunning, isInitialLoading, isLoadingMoreItems]
    )

    if (!showBasicLoading && !showFilteringLoading) {
      return null
    }

    return (
      <div className={className}>
        {showBasicLoading &&
          Array(initialLoadingSkeletonsNumber)
            .fill(Math.random())
            .map((item, index) => <SkeletonTableRow key={index} {...skeletonProps} />)}

        {showFilteringLoading &&
          Array(filteringLoadingSkeletonsNumber || (itemsLength <= 15 ? itemsLength : 15))
            .fill(Math.random())
            .map((item, index) => <SkeletonTableRow key={index} {...skeletonProps} />)}
      </div>
    )
  }
)

ItemsLoading.propTypes = {
  className: PropTypes.string,
  isLoadingMoreItems: PropTypes.bool,
  isInitialLoading: PropTypes.bool,
  itemsLength: PropTypes.number,
  initialLoadingSkeletonsNumber: PropTypes.number,
  filteringLoadingSkeletonsNumber: PropTypes.number,
  skeletonProps: PropTypes.object,
  isFilteringRunning: PropTypes.bool
}

export default ItemsLoading
