import {
  formatTimeStartToBE,
  START_DATE,
  START_OPTION,
  START_TIME
} from '../../../../ReusableFormFields/StartTimeFields/fields'
import {
  formatTimeStopToBE,
  STOP_DATE,
  STOP_OPTION,
  STOP_OPTION_INDEFINITELY,
  STOP_TIME
} from '../../../../ReusableFormFields/StopTimeFields/fields'
import {
  ACCOUNT_CATEGORIES,
  ENABLE_COMMENTS_OPTION,
  HASHTAGS_OPTION,
  NAME,
  PLACEMENT_TIKTOK,
  TARGET_ACCOUNT_CATEGORIES_OPTION,
  TARGET_VIDEO_CATEGORIES_OPTION,
  VIDEO_CATEGORIES,
  WATCHED_OR_ENGAGED_OPTION,
  PIXEL_ID,
  PIXEL_EVENT,
  BID_PRICE,
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_UNLIMITED,
  BID_TYPE_CUSTOM,
  BID_TYPE_NO_BID,
  BID_PRICE_OPTION
} from './fields'
import {
  SCHEDULE_DATES,
  SCHEDULE_FRIDAY,
  SCHEDULE_MONDAY,
  SCHEDULE_OPTION,
  SCHEDULE_OPTION_ALL_THE_TIME,
  SCHEDULE_OPTION_SPECIFIC_DAYS,
  SCHEDULE_OPTION_SPECIFIC_TIMES,
  SCHEDULE_SATURDAY,
  SCHEDULE_SUNDAY,
  SCHEDULE_THURSDAY,
  SCHEDULE_TUESDAY,
  SCHEDULE_WEDNESDAY
} from '../../../../ReusableFormFields/ScheduleFields/fields'
import {
  GENDER_OPTION,
  GENDER_OPTION_ALL,
  GENDER_OPTION_FEMALES,
  GENDER_OPTION_MALES
} from '../../../../ReusableFormFields/GenderFields/fields'
import { INTERESTS, INTERESTS_OPTION } from '../../../../ReusableFormFields/InterestsFields/fields'
import { OPTION_YES, OPTION_NO, OPTION_CUSTOM } from '../../../../../constants/forms'

import { insertIf } from '../../../../../helpers/common'

import {
  AGE_18_24,
  AGE_18_34,
  AGE_25_34,
  AGE_25_54,
  AGE_35_44,
  AGE_45_54,
  AGE_55_100,
  AGE_OPTION,
  ANYONE_OVER_18,
  CUSTOM_AGE_OPTION,
  CUSTOM_AGE_RANGES
} from '../../../../ReusableFormFields/AgeRangesFields/fields'
import { formatDateToBE } from '../../../../../constants/dates'
import {
  SHOW_COUNTRIES,
  SHOW_OPTION,
  SHOW_OPTION_COUNTRIES,
  SHOW_OPTION_REGIONS,
  SHOW_REGIONS
} from '../../../../ReusableFormFields/GeolocationsFields/fields'
import { BUDGET_DAILY, BUDGET_LIFETIME, BUDGET_OPTION } from '../../../../Multiplatform/LineItemForms/fields'
import { CONVERSIONS, REACH, TRAFFIC, VIDEO_VIEWS } from '../../../../../constants/campaigns'
import {
  FREQUENCY_DAYS,
  FREQUENCY_IMPRESSIONS,
  FREQUENCY_OPTION,
  FREQUENCY_OPTION_DEFAULT
} from '../../../../ReusableFormFields/FrequencyFields/fields'
import { LANGUAGES_OPTION, SELECT_LANGUAGES, LANGUAGES } from '../../../../ReusableFormFields/LanguagesFields/fields'
import { CREATOR_RELATED, HASHTAG_RELATED, VIDEO_RELATED } from '../../../../../constants/lineItems'
import { HASHTAGS } from '../../../ReusableFormFields/TikTokHashtagsFields/fields'
import {
  AUDIENCES_OPTION,
  CUSTOM_AUDIENCES,
  CUSTOM_AUDIENCES_EXCLUSION,
  CUSTOM_AUDIENCES_EXCLUSION_CHECKED
} from '../../../../ReusableFormFields/CustomAudiencesFields/fields'
import { getMultiLevelSelectPayloadValues } from '../../../../../components/SelectMultiLevel/formatters'
import { formatScheduleDatesToBE } from '../../../../../features/formatters'
import { optimizeGoals } from '../../../CampaignForms/fields'

const formatScheduleToBE = ({
  scheduleOption,
  scheduleMonday,
  scheduleTuesday,
  scheduleWednesday,
  scheduleThursday,
  scheduleFriday,
  scheduleSaturday,
  scheduleSunday,
  scheduleDates
}) => {
  const fullDayTemplate = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]

  switch (scheduleOption) {
    case SCHEDULE_OPTION_ALL_THE_TIME:
      return {
        0: fullDayTemplate,
        1: fullDayTemplate,
        2: fullDayTemplate,
        3: fullDayTemplate,
        4: fullDayTemplate,
        5: fullDayTemplate,
        6: fullDayTemplate
      }
    case SCHEDULE_OPTION_SPECIFIC_DAYS:
      return {
        ...(scheduleSunday && { 0: fullDayTemplate }),
        ...(scheduleMonday && { 1: fullDayTemplate }),
        ...(scheduleTuesday && { 2: fullDayTemplate }),
        ...(scheduleWednesday && { 3: fullDayTemplate }),
        ...(scheduleThursday && { 4: fullDayTemplate }),
        ...(scheduleFriday && { 5: fullDayTemplate }),
        ...(scheduleSaturday && { 6: fullDayTemplate })
      }
    case SCHEDULE_OPTION_SPECIFIC_TIMES:
      return formatScheduleDatesToBE(scheduleDates)
    default:
      return {}
  }
}

const formatGenderToBE = gender_option => {
  switch (gender_option) {
    case GENDER_OPTION_MALES:
      return GENDER_MALE
    case GENDER_OPTION_FEMALES:
      return GENDER_FEMALE
    case GENDER_OPTION_ALL:
    default:
      return GENDER_UNLIMITED
  }
}

const formatAgesToBE = (ageOption, customAgeRanges) => {
  switch (ageOption) {
    case AGE_18_34:
      return [AGE_18_24, AGE_25_34]
    case AGE_25_54:
      return [AGE_25_34, AGE_35_44, AGE_45_54]
    case CUSTOM_AGE_OPTION:
      return Object.keys(customAgeRanges).filter(item => customAgeRanges[item])
    case ANYONE_OVER_18:
    default:
      return [AGE_18_24, AGE_25_34, AGE_35_44, AGE_45_54, AGE_55_100]
  }
}

const formatLocationsToBE = (showOption, showCountries, showRegions, tikTokRegions) => {
  switch (showOption) {
    case SHOW_OPTION_REGIONS:
      return getMultiLevelSelectPayloadValues(showRegions, tikTokRegions, 'parent_id')
    case SHOW_OPTION_COUNTRIES:
    default:
      return showCountries.map(({ tiktok_id }) => tiktok_id)
  }
}

const formatVideoCategoriesToBE = (targetVideoCategoriesOption, videoCategories, actionCategories) => {
  const userActions =
    targetVideoCategoriesOption === WATCHED_OR_ENGAGED_OPTION
      ? ['WATCHED_TO_END', 'LIKED', 'COMMENTED', 'SHARED']
      : ['LIKED', 'COMMENTED', 'SHARED']

  return {
    action_categories: getMultiLevelSelectPayloadValues(videoCategories, actionCategories),
    action_scene: VIDEO_RELATED,
    action_period: 15, // Select a time period to include actions from. Supported values: 0, 7, 15
    user_actions: userActions
  }
}

const formatAccountCategoriesToBE = (values, actionCategories) => {
  return {
    action_categories: getMultiLevelSelectPayloadValues(values, actionCategories),
    action_scene: CREATOR_RELATED,
    action_period: 0,
    user_actions: ['FOLLOWING', 'VIEW_HOMEPAGE']
  }
}

const formatHashtagsToBE = hashtags => {
  return {
    action_categories: hashtags.map(item => item.id),
    action_scene: HASHTAG_RELATED,
    action_period: 0,
    user_actions: ['VIEW_HASHTAG']
  }
}

export const formatAudiencesToBE = audiences => {
  return audiences.map(audience => audience.value)
}

export const formatFrequencyToBE = ({ frequencyOption, frequencyImpression, frequencyDays }) => {
  switch (frequencyOption) {
    case OPTION_CUSTOM:
      return {
        frequency: Number(frequencyImpression),
        frequency_schedule: Number(frequencyDays)
      }
    case FREQUENCY_OPTION_DEFAULT:
    default:
      return {
        frequency: 2,
        frequency_schedule: 7
      }
  }
}

export const getBillingEvent = campaignObjective => {
  switch (campaignObjective) {
    case TRAFFIC:
      return 'CPC'
    case REACH:
      return 'CPM'
    case VIDEO_VIEWS:
      return 'CPV'
    default:
      return 'OCPM'
  }
}

export const transformValuesToBE = ({
  values,
  adAccountId,
  campaignId,
  campaignDateStart,
  campaignObjective,
  showBidPriceStep,
  isBidTypeCustomPopulated,
  isCampaignLevelBudgetSet,
  tikTokRegions,
  actionCategories,
  interests,
  showPixelStep,
  pixelIdFromExistingLineItem,
  externalActionFromExistingLineItem
}) => {
  const {
    // START TIME
    [START_OPTION]: startOption,
    [START_DATE]: startDate,
    [START_TIME]: startTime,
    // STOP TIME
    [STOP_OPTION]: stopOption,
    [STOP_DATE]: stopDate,
    [STOP_TIME]: stopTime
  } = values

  const formattedTimeStart = formatTimeStartToBE({ startOption, startDate, startTime })

  const formattedSchedule = formatScheduleToBE({
    scheduleOption: values[SCHEDULE_OPTION],
    scheduleMonday: values[SCHEDULE_MONDAY],
    scheduleTuesday: values[SCHEDULE_TUESDAY],
    scheduleWednesday: values[SCHEDULE_WEDNESDAY],
    scheduleThursday: values[SCHEDULE_THURSDAY],
    scheduleFriday: values[SCHEDULE_FRIDAY],
    scheduleSaturday: values[SCHEDULE_SATURDAY],
    scheduleSunday: values[SCHEDULE_SUNDAY],
    scheduleDates: values[SCHEDULE_DATES]
  })

  const formattedVideoCategories = formatVideoCategoriesToBE(
    values[TARGET_VIDEO_CATEGORIES_OPTION],
    values[VIDEO_CATEGORIES],
    actionCategories
  )

  const formattedAccountCategories = formatAccountCategoriesToBE(values[ACCOUNT_CATEGORIES], actionCategories)

  const formattedHashtags = formatHashtagsToBE(values[HASHTAGS])

  const formattedInterests = getMultiLevelSelectPayloadValues(values[INTERESTS], interests)

  const shouldIncludeFrequency = campaignObjective.toLowerCase() === REACH
  const shouldIncludePixel = campaignObjective.toLowerCase() === CONVERSIONS
  const shouldIncludeBidPrice =
    showBidPriceStep && (isBidTypeCustomPopulated || values[BID_PRICE_OPTION] === OPTION_YES)

  return {
    account: adAccountId,
    campaign_id: campaignId,
    campaign_start_date: formatDateToBE(campaignDateStart),
    objective: optimizeGoals[campaignObjective.toLowerCase()],
    name: values[NAME],
    // budget
    ...(isCampaignLevelBudgetSet
      ? {
          buying_type: 'BUDGET_MODE_INFINITE',
          schedule_type: 'SCHEDULE_START_END'
        }
      : {
          ...(values[BUDGET_OPTION] === BUDGET_LIFETIME && {
            budget_lifetime: values[BUDGET_LIFETIME],
            buying_type: 'BUDGET_MODE_TOTAL',
            schedule_type: 'SCHEDULE_START_END'
          }),
          ...(values[BUDGET_OPTION] === BUDGET_DAILY && {
            budget_daily: values[BUDGET_DAILY],
            buying_type: 'BUDGET_MODE_DAY',
            schedule_type: 'SCHEDULE_FROM_NOW'
          })
        }),
    // schedule
    schedule_start_time: formattedTimeStart,
    ...(values[STOP_OPTION] !== STOP_OPTION_INDEFINITELY && {
      schedule_end_time: formatTimeStopToBE({ stopOption, stopDate, stopTime, timeStart: formattedTimeStart })
    }),
    // schedule
    dayparting: formattedSchedule,
    location: formatLocationsToBE(values[SHOW_OPTION], values[SHOW_COUNTRIES], values[SHOW_REGIONS], tikTokRegions),
    ...(values[LANGUAGES_OPTION] === SELECT_LANGUAGES && { languages: values[LANGUAGES].map(({ value }) => value) }),
    gender: formatGenderToBE(values[GENDER_OPTION]),
    age: formatAgesToBE(values[AGE_OPTION], values[CUSTOM_AGE_RANGES]),
    ...(values[INTERESTS_OPTION] === OPTION_YES && {
      interest_category: formattedInterests
    }),
    // A list of action category objects.
    action: [
      ...insertIf(values[TARGET_VIDEO_CATEGORIES_OPTION] !== OPTION_NO, formattedVideoCategories),
      ...insertIf(values[TARGET_ACCOUNT_CATEGORIES_OPTION] === OPTION_YES, formattedAccountCategories),
      ...insertIf(values[HASHTAGS_OPTION] === OPTION_YES, formattedHashtags)
    ],
    comment_disabled: values[ENABLE_COMMENTS_OPTION] === OPTION_NO,
    ...(values[AUDIENCES_OPTION] === OPTION_YES && {
      audience: formatAudiencesToBE(values[CUSTOM_AUDIENCES])
    }),
    ...(values[CUSTOM_AUDIENCES_EXCLUSION_CHECKED] && {
      excluded_audience: formatAudiencesToBE(values[CUSTOM_AUDIENCES_EXCLUSION])
    }),
    billing_event: getBillingEvent(campaignObjective.toLowerCase()),
    ...(campaignObjective.toLowerCase() === VIDEO_VIEWS && { bid_display_mode: 'CPV' }),
    // pixel
    ...(shouldIncludePixel && {
      // if pixel step was not shown, but campaign is conversions (type that requires pixel),
      // then we use the pixel settings from the existing line item
      pixel_id: showPixelStep ? values[PIXEL_ID] : pixelIdFromExistingLineItem,
      external_action: showPixelStep ? values[PIXEL_EVENT] : externalActionFromExistingLineItem
    }),
    ...(shouldIncludeBidPrice && {
      bid: values[BID_PRICE]
    }),
    bid_type: shouldIncludeBidPrice ? BID_TYPE_CUSTOM : BID_TYPE_NO_BID,
    ...(shouldIncludeFrequency &&
      formatFrequencyToBE({
        frequencyOption: values[FREQUENCY_OPTION],
        frequencyImpression: values[FREQUENCY_IMPRESSIONS],
        frequencyDays: values[FREQUENCY_DAYS]
      })),
    ...(campaignObjective.toLowerCase() === VIDEO_VIEWS && {
      cpv_video_duration: 'SIX_SECONDS'
    }),
    // required hardcoded fields
    placement: [PLACEMENT_TIKTOK],
    external_type: 'WEBSITE',
    pacing: 'PACING_MODE_SMOOTH',
    creative_material_mode: 'CUSTOM',
    placement_type: 'PLACEMENT_TYPE_NORMAL',
    conversion_bid: 1
  }
}
