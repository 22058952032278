import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { getRandomizedValues } from '../helpers'

import ImageAssetPreview from '../ImageAssetPreview'

import { BUSINESS_NAME, DESCRIPTIONS_LIST, HEADLINES_LIST } from '../../../fields'

import useStyles from './styles'

const containerWidth = 260

const GoogleAdPreviewDiscover1 = React.memo(({ values, fileUrl, width, height }) => {
  const { t } = useTranslation()
  const classes = useStyles(containerWidth)

  const randomizedValues = useMemo(() => {
    return getRandomizedValues(values)
  }, [values])

  const description = randomizedValues[DESCRIPTIONS_LIST][0].text
  const headline = randomizedValues[HEADLINES_LIST][0].text

  const businessName = randomizedValues[BUSINESS_NAME]

  return (
    <div className={classes.adPreview}>
      <ImageAssetPreview containerWidth={containerWidth} width={width} height={height} fileUrl={fileUrl} />
      <footer className={classes.footer}>
        <div className={classes.footerTop}>
          {headline} | {description}
        </div>
        <div className={classes.footerBottom}>
          <strong>{t('Ad')} ·</strong> {businessName}
        </div>
      </footer>
    </div>
  )
})

GoogleAdPreviewDiscover1.propTypes = {
  values: PropTypes.object,
  fileUrl: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
}

export default GoogleAdPreviewDiscover1
