import { createStyles } from '../../../styles/helpers'

export default createStyles({
  selectedOptionsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: 8,
    columnGap: 8,
    paddingBottom: 16
  },
  selectedItem: {
    margin: '0 !important'
  }
})
