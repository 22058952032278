import { createStyles } from '../../../styles/helpers'

import { darkGrey } from '../../../styles/const/colors'

const useStyles = createStyles({
  selectAudienceInput: {
    maxWidth: 270
  },
  descriptionText: {
    fontSize: 12,
    fontWeight: 400,
    color: darkGrey,
    marginBottom: 12
  },
  radiobuttonDescriptionText: {
    marginTop: 8
  }
})

export default useStyles
