import React from 'react'

import ShowSkeleton from '../ShowSkeleton'

import useStyles from './styles'

function SkeletonActionButton({ isInProgress, showLoadingAnimation }) {
  const classes = useStyles()

  return (
    <ShowSkeleton
      isInProgress={isInProgress}
      className={classes.skeletonButtonLine}
      showLoadingAnimation={showLoadingAnimation}
    />
  )
}

export default SkeletonActionButton
