import React from 'react'
import PropTypes from 'prop-types'

import FileDetailsPreview from '../../../../../../../components/FileDetailsPreview'

import { getLandscapeLogoData } from '../HeadlinesSection/helpers'

const LandscapeLogoSectionPreview = ({ data }) => {
  const { assets } = data
  const landscapeLogoData = getLandscapeLogoData(assets)

  if (!landscapeLogoData || !landscapeLogoData.length) {
    return null
  }

  return (
    <div>
      {landscapeLogoData.map(({ asset_data: assetData }) => {
        const fileUrl = assetData?.image_asset?.full_size?.url
        const fileName = assetData?.name

        return (
          <FileDetailsPreview
            key={fileUrl}
            fileName={fileName}
            previewUrl={fileUrl}
            filePreviewType={'image'}
            isFileLoading={false}
            allowDelete={false}
          />
        )
      })}
    </div>
  )
}

LandscapeLogoSectionPreview.propTypes = {
  data: PropTypes.object
}

export default LandscapeLogoSectionPreview
