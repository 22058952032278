import update from 'immutability-helper'
import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_LINE_ITEM,
  GET_LINE_ITEM_SUCCESS,
  GET_LINE_ITEM_FAILURE,
  GET_LINE_ITEMS,
  GET_LINE_ITEMS_SUCCESS,
  GET_LINE_ITEMS_FAILURE,
  CREATE_LINE_ITEM,
  CREATE_LINE_ITEM_SUCCESS,
  CREATE_LINE_ITEM_FAILURE,
  DELETE_LINE_ITEM,
  DELETE_LINE_ITEM_SUCCESS,
  DELETE_LINE_ITEM_FAILURE,
  UPDATE_LINE_ITEM,
  UPDATE_LINE_ITEM_SUCCESS,
  UPDATE_LINE_ITEM_FAILURE,
  CLEAR_LINE_ITEM,
  CLEAR_LINE_ITEMS,
  CLEAR_CREATE_LINE_ITEM,
  CLEAR_DELETE_LINE_ITEM,
  CLEAR_UPDATE_LINE_ITEM,
  GET_LOCATIONS,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAILURE,
  GET_GOALS,
  GET_GOALS_SUCCESS,
  GET_GOALS_FAILURE,
  CLEAR_GOALS,
  GET_PIXELS_STATS,
  GET_PIXELS_STATS_SUCCESS,
  GET_PIXELS_STATS_FAILURE,
  DUPLICATE_LINE_ITEM,
  DUPLICATE_LINE_ITEM_SUCCESS,
  DUPLICATE_LINE_ITEM_COMPLETE,
  DUPLICATE_LINE_ITEM_FAILURE,
  CLEAR_DUPLICATE_LINE_ITEM,
  GET_LINE_ITEM_CRITERIONS,
  GET_LINE_ITEM_CRITERIONS_SUCCESS,
  GET_LINE_ITEM_CRITERIONS_FAILURE,
  CLEAR_LINE_ITEM_CRITERIONS,
  CREATE_LINE_ITEM_CRITERIONS,
  CREATE_LINE_ITEM_CRITERIONS_SUCCESS,
  CREATE_LINE_ITEM_CRITERIONS_FAILURE,
  CLEAR_CREATE_LINE_ITEM_CRITERIONS,
  DELETE_LINE_ITEM_CRITERIONS
} from '../actions/lineItems'
import { deleteItemById, deleteItemsByPropertyName, updateItem } from '../../helpers/modules/reducerHelpers'
import {
  INITIAL_CREATE_REDUCER,
  INITIAL_DELETE_REDUCER,
  INITIAL_DUPLICATE_REDUCER,
  INITIAL_GET_RESULTS_REDUCER,
  INITIAL_UPDATE_REDUCER
} from '../../constants/reducer'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  isLoading: false,
  wasLoaded: false,
  error: '',
  lineItems: [],
  createdLineItem: INITIAL_CREATE_REDUCER,
  deletedLineItem: INITIAL_DELETE_REDUCER,
  updatedLineItem: INITIAL_UPDATE_REDUCER,
  duplicateLineItem: INITIAL_DUPLICATE_REDUCER,
  lineItem: {
    data: {},
    isLoading: false,
    error: null,
    wasLoaded: false
  },
  locations: INITIAL_GET_RESULTS_REDUCER,
  goals: INITIAL_GET_RESULTS_REDUCER,
  pixelsStats: {},
  lineItemCriterions: INITIAL_GET_RESULTS_REDUCER,
  createLineItemCriterions: INITIAL_CREATE_REDUCER
}

export default function lineItems(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    // get lineItem
    case GET_LINE_ITEM:
      return update(state, {
        lineItem: { $merge: { isLoading: true } }
      })
    case GET_LINE_ITEM_SUCCESS:
      return update(state, {
        lineItem: {
          $merge: {
            data: action.lineItemData,
            isLoading: false,
            wasLoaded: true
          }
        }
      })
    case GET_LINE_ITEM_FAILURE:
      return update(state, {
        lineItem: { $merge: { isLoading: false, error: action.error, wasLoaded: true } }
      })
    case CLEAR_LINE_ITEM:
      return update(state, {
        lineItem: { $set: initialState.lineItem }
      })

    // get lineItems
    case GET_LINE_ITEMS:
      return update(state, {
        isLoading: { $set: true },
        error: { $set: '' }
      })
    case GET_LINE_ITEMS_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        wasLoaded: { $set: true },
        lineItems: { $set: action.lineItemsData }
      })
    case GET_LINE_ITEMS_FAILURE:
      return update(state, {
        isLoading: { $set: false },
        wasLoaded: { $set: true },
        error: { $set: action.error }
      })
    case CLEAR_LINE_ITEMS:
      return update(state, {
        lineItems: { $set: initialState.lineItems },
        isLoading: { $set: initialState.isLoading },
        wasLoaded: { $set: initialState.wasLoaded },
        createdLineItem: { $set: initialState.createdLineItem },
        updatedLineItem: { $set: initialState.updatedLineItem }
      })

    // create lineItem
    case CREATE_LINE_ITEM:
      return update(state, {
        createdLineItem: {
          $merge: {
            isLoading: true
          }
        }
      })
    case CREATE_LINE_ITEM_SUCCESS:
      return update(state, {
        createdLineItem: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.lineItemData
          }
        },
        lineItems: { $unshift: [action.lineItemData] }
      })
    case CREATE_LINE_ITEM_FAILURE:
      return update(state, {
        createdLineItem: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_LINE_ITEM:
      return update(state, {
        createdLineItem: { $set: initialState.createdLineItem }
      })

    // delete lineItem
    case DELETE_LINE_ITEM:
      return update(state, {
        deletedLineItem: { $set: { isLoading: true, [action.params.id]: true, id: action.params.id } }
      })
    case DELETE_LINE_ITEM_SUCCESS:
      return update(state, {
        lineItems: {
          $apply: items => deleteItemById(items, action.id)
        },
        deletedLineItem: { $set: { isLoading: false, id: action.id, wasDeleted: true } }
      })
    case DELETE_LINE_ITEM_FAILURE:
      return update(state, {
        deletedLineItem: { $set: { isLoading: false, error: action.error } }
      })
    case CLEAR_DELETE_LINE_ITEM:
      return update(state, {
        deletedLineItem: { $set: initialState.deletedLineItem }
      })

    // update lineItem
    case UPDATE_LINE_ITEM:
      return update(state, {
        updatedLineItem: { $merge: { isLoading: true, [action.id]: true, id: action.id } }
      })
    case UPDATE_LINE_ITEM_SUCCESS:
      const isArchived = action.lineItemData.status === 'archived'
      return update(state, {
        lineItems: {
          // if we archive lineItem we should remove this campaign from active/paused lineItems list,
          // we use for this purpose deleteItemById() method
          $apply: isArchived
            ? items => deleteItemById(items, action.lineItemData.id)
            : items => updateItem(items, action.lineItemData)
        },
        updatedLineItem: { $merge: { isLoading: false, id: action.lineItemData.id, wasUpdated: true } },
        lineItem: {
          $merge: {
            data: action.lineItemData
          }
        }
      })
    case UPDATE_LINE_ITEM_FAILURE:
      return update(state, {
        updatedLineItem: { $merge: { isLoading: false, error: action.error } }
      })
    case CLEAR_UPDATE_LINE_ITEM:
      return update(state, {
        updatedLineItem: { $set: initialState.updatedLineItem }
      })

    // duplicate lineItem
    case DUPLICATE_LINE_ITEM:
      return update(state, {
        duplicateLineItem: {
          $merge: {
            isLoading: true
          }
        }
      })
    case DUPLICATE_LINE_ITEM_SUCCESS:
      return update(state, {
        duplicateLineItem: {
          $merge: {
            isLoading: false,
            duplicateProcessWasStarted: true,
            asyncSessionData: action.asyncSessionData
          }
        }
      })
    case DUPLICATE_LINE_ITEM_COMPLETE:
      return update(state, {
        lineItems: {
          $unshift: [action.duplicatedLineItem]
        }
      })
    case DUPLICATE_LINE_ITEM_FAILURE:
      return update(state, {
        duplicateLineItem: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DUPLICATE_LINE_ITEM:
      return update(state, {
        duplicateLineItem: { $set: initialState.duplicateLineItem }
      })

    // get locations
    case GET_LOCATIONS:
      return update(state, {
        locations: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_LOCATIONS_SUCCESS:
      return update(state, {
        locations: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.locationsData.results
          }
        }
      })
    case GET_LOCATIONS_FAILURE:
      return update(state, {
        locations: {
          $merge: {
            error: action.error,
            isLoading: false
          }
        }
      })

    // get goals
    case GET_GOALS:
      return update(state, {
        goals: { $merge: { isLoading: true } }
      })
    case GET_GOALS_SUCCESS:
      return update(state, {
        goals: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.goalsData.results
          }
        }
      })
    case GET_GOALS_FAILURE:
      return update(state, {
        goals: {
          $merge: {
            error: action.error,
            isLoading: false
          }
        }
      })
    case CLEAR_GOALS:
      return update(state, {
        goals: { $set: initialState.goals }
      })

    // get pixels
    case GET_PIXELS_STATS:
      return update(state, {
        pixelsStats: { $set: { isLoading: true } }
      })
    case GET_PIXELS_STATS_SUCCESS:
      return update(state, {
        pixelsStats: { $set: action.pixelsStatsData }
      })
    case GET_PIXELS_STATS_FAILURE:
      return update(state, {
        pixelsStats: { $set: { error: action.error } }
      })

    // Get line item criterions
    case GET_LINE_ITEM_CRITERIONS:
      return update(state, {
        lineItemCriterions: { $merge: { isLoading: true } }
      })
    case GET_LINE_ITEM_CRITERIONS_SUCCESS:
      return update(state, {
        lineItemCriterions: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data
          }
        }
      })
    case GET_LINE_ITEM_CRITERIONS_FAILURE:
      return update(state, {
        lineItemCriterions: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_LINE_ITEM_CRITERIONS:
      return update(state, {
        lineItemCriterions: { $set: initialState.lineItemCriterions }
      })

    // Create line item criterions
    case CREATE_LINE_ITEM_CRITERIONS:
      return update(state, {
        createLineItemCriterions: { $merge: { isLoading: true } }
      })
    case CREATE_LINE_ITEM_CRITERIONS_SUCCESS:
      return update(state, {
        createLineItemCriterions: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },
        lineItemCriterions: {
          results: {
            $push: [...action.data.operations]
          }
        }
      })
    case CREATE_LINE_ITEM_CRITERIONS_FAILURE:
      return update(state, {
        createLineItemCriterions: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_LINE_ITEM_CRITERIONS:
      return update(state, {
        createLineItemCriterions: { $set: initialState.createLineItemCriterions }
      })

    // delete line item criterions internal action
    case DELETE_LINE_ITEM_CRITERIONS:
      return update(state, {
        lineItemCriterions: {
          results: {
            $apply: items => deleteItemsByPropertyName(items, action.criterionsToDelete, 'resource_name')
          }
        }
      })

    default:
      return state
  }
}
