import {
  APPROVED,
  BUYER_DISAPPROVED,
  DISAPPROVED,
  PENDING_APPROVAL,
  PENDING_BUYER_APPROVAL
} from '../../UploadedFilesField/helpers'

export const STATUS_FILTER_ALL = 'all'
export const STATUS_FILTER_AWAITING_FILES = 'awaiting_files'
export const STATUS_FILTER_FILES_PENDING_REVIEW = 'status_filter_files_pending_review'
export const STATUS_FILTER_FILES_OVERDUE = 'status_filter_files_overdue'

export const filesApprovalStatusOptions = [
  {
    label: 'Pending Approval',
    value: PENDING_APPROVAL
  },
  {
    label: 'Approved',
    value: APPROVED
  },
  {
    label: 'Disapproved',
    value: DISAPPROVED
  },
  {
    label: 'Pending Buyer Approval',
    value: PENDING_BUYER_APPROVAL
  },
  {
    label: 'Buyer Disapproved',
    value: BUYER_DISAPPROVED
  }
]

export const statusFilterOptions = [
  {
    label: 'Awaiting Files',
    value: STATUS_FILTER_AWAITING_FILES
  },
  {
    label: 'Files Pending Review',
    value: STATUS_FILTER_FILES_PENDING_REVIEW
  },
  {
    label: 'Files Overdue',
    value: STATUS_FILTER_FILES_OVERDUE
  }
]
