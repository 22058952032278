import React from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { formatNumber } from '../../../../../../helpers/numbers'

import {
  getAdAccountFieldNameByPlatform,
  PROVIDER_TO_PLATFORMS
} from '../../../../../../constants/selectLists/platformList'

import { selectedSelfAccountDataSelector } from '../../../../../../modules/selectors/app'

import useStyles from './styles'

const BudgetCell = ({ data }) => {
  const classes = useStyles()

  const { provider, budget, new_budget: newBudget } = data
  const platform = PROVIDER_TO_PLATFORMS[provider]

  const adAccountFieldName = getAdAccountFieldNameByPlatform(platform)
  const { [adAccountFieldName]: adAccountData } = useSelector(selectedSelfAccountDataSelector)
  const { currency_symbol: currencySymbol } = adAccountData

  const formattedBudget = budget && formatNumber(budget)
  const formattedNewBudget = newBudget && formatNumber(newBudget)

  const isIncrease = Number(newBudget) > Number(budget)

  return (
    <div className={classes.wrapper}>
      <p className={classes.oldBudget}>
        {currencySymbol}
        {formattedBudget}
      </p>
      <p className={classnames(classes.newBudget, { increase: isIncrease })}>
        {isIncrease ? '↑' : '↓'}
        {currencySymbol}
        {formattedNewBudget}
      </p>
    </div>
  )
}

BudgetCell.propTypes = {
  data: PropTypes.shape({
    provider: PropTypes.string.isRequired,
    budget: PropTypes.number.isRequired,
    new_budget: PropTypes.number.isRequired
  }).isRequired
}

export default BudgetCell
