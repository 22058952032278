import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../../../../components/Button'
import Field from '../../../../../../../../../../components/Form/Field'

import { BUDGET_LIFETIME } from '../../fields'

import useStepFormStyles from '../../../../../../../../../../styles/common/stepForms'
import useDrawerFormStyles from '../../../../../../../../../../styles/common/drawerForms'

const stepFields = [BUDGET_LIFETIME]

const BudgetStep = ({ formik, handleStepChange, currencySymbol }) => {
  const stepFormClasses = useStepFormStyles()
  const drawerFormClasses = useDrawerFormStyles()

  const { t } = useTranslation()

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  return (
    <>
      <div className={stepFormClasses.stepTitle}>{t('What budget would you like to set for new ad set?')}</div>
      <div className={stepFormClasses.stepBody}>
        <section className={drawerFormClasses.section}>
          <h4 className={drawerFormClasses.sectionTitle}>{t('Lifetime Budget')}</h4>
          <p>{t('A total budget for the entire run-time of the ad set.')}</p>
          <p>{t('The budget will be spent evenly over its lifetime.')}</p>
          <Field
            type="number"
            formik={formik}
            name={BUDGET_LIFETIME}
            placeholder={t('Lifetime Budget')}
            symbol={currencySymbol}
            autoComplete="off"
            inputMode="numeric"
          />
        </section>
      </div>
      <div className={stepFormClasses.stepFooter}>
        <Button type="button" className="dark" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default BudgetStep
