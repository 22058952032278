import { call, put, all, takeEvery } from 'redux-saga/effects'

import {
  getCalendarListService,
  createCalendarService,
  getCalendarService,
  updateCalendarService,
  getEventService,
  createEventService,
  updateEventService,
  updatePatchEventService,
  deleteEventService,
  createEventGroupService,
  deleteEventGroupService,
  updateEventGroupService,
  updateEventGroupOrderService
} from '../services/calendarPlanner'

import {
  GET_CALENDARS,
  getCalendarsSuccess,
  getCalendarsFailure,
  CREATE_CALENDAR,
  createCalendarSuccess,
  createCalendarFailure,
  GET_CALENDAR,
  getCalendarSuccess,
  getCalendarFailure,
  UPDATE_CALENDAR,
  updateCalendarSuccess,
  updateCalendarFailure,
  GET_EVENT,
  getEventSuccess,
  getEventFailure,
  CREATE_EVENT,
  createEventSuccess,
  createEventFailure,
  UPDATE_EVENT,
  updateEventSuccess,
  updateEventWithNewEventGroupSuccess,
  updateEventFailure,
  UPDATE_EVENT_PARTIAL,
  updatePatchEventSuccess,
  updatePatchEventFailure,
  DELETE_EVENT,
  deleteEventSuccess,
  deleteEventFailure,
  CREATE_EVENT_GROUP,
  createEventGroupSuccess,
  createEventGroupFailure,
  DELETE_EVENT_GROUP,
  deleteEventGroupSuccess,
  deleteEventGroupFailure,
  UPDATE_EVENT_GROUP,
  updateEventGroupSuccess,
  updateEventGroupFailure,
  UPDATE_EVENT_GROUP_ORDER,
  updateEventGroupsOrderSuccess,
  updateEventGroupsOrderFailure
} from '../actions/calendarPlanner'
import { closeForm } from '../actions/forms'
import { ROW } from '../../forms/Multiplatform/CalendarPlannerForms/Event/CalendarEventForm/fields'

function* calendarPlannerWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_CALENDARS, getCalendarsListWorker),
    takeEvery(CREATE_CALENDAR, createCalendarWorker),
    takeEvery(GET_CALENDAR, getCalendarWorker),
    takeEvery(UPDATE_CALENDAR, updateCalendarWorker),
    takeEvery(CREATE_EVENT, createEventWorker),
    takeEvery(GET_EVENT, getEventWorker),
    takeEvery(UPDATE_EVENT, updateEventWorker),
    takeEvery(UPDATE_EVENT_PARTIAL, updateEventPartialWorker),
    takeEvery(DELETE_EVENT, deleteEventWorker),
    takeEvery(CREATE_EVENT_GROUP, createEventGroupWorker),
    takeEvery(DELETE_EVENT_GROUP, deleteEventGroupWorker),
    takeEvery(UPDATE_EVENT_GROUP, updateEventGroupWorker),
    takeEvery(UPDATE_EVENT_GROUP_ORDER, updateEventGroupOrderWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getCalendarsListWorker({ params }) {
  try {
    const calendarList = yield call(getCalendarListService, params)
    yield put(getCalendarsSuccess(calendarList))
  } catch (e) {
    yield put(getCalendarsFailure(e))
  }
}

function* createCalendarWorker({ calendarData }) {
  try {
    const newCalendarData = yield call(createCalendarService, calendarData)
    yield put(createCalendarSuccess(newCalendarData))
  } catch (e) {
    yield put(createCalendarFailure(e))
  }
}

function* getCalendarWorker({ calendarId }) {
  try {
    const calendarData = yield call(getCalendarService, calendarId)
    yield put(getCalendarSuccess(calendarData))
  } catch (e) {
    yield put(getCalendarFailure(e))
  }
}

function* updateCalendarWorker({ calendarData }) {
  try {
    const updatedData = yield call(updateCalendarService, calendarData)
    yield put(updateCalendarSuccess(updatedData))
  } catch (e) {
    yield put(updateCalendarFailure(e))
  }
}

function* getEventWorker({ eventId }) {
  try {
    const eventData = yield call(getEventService, eventId)
    yield put(getEventSuccess(eventData))
  } catch (e) {
    yield put(getEventFailure(e))
  }
}

function* createEventWorker({ eventData }) {
  try {
    const newEventData = yield call(createEventService, eventData)
    yield put(createEventSuccess(newEventData))
  } catch (e) {
    yield put(createEventFailure(e))
  }
}

function* updateEventWorker({ eventData, oldEventGroupId }) {
  try {
    const newEventData = yield call(updateEventService, eventData)

    if (newEventData[ROW] !== oldEventGroupId) {
      // event`s event_group was changed during update
      yield put(updateEventWithNewEventGroupSuccess(newEventData, oldEventGroupId))
    } else {
      yield put(updateEventSuccess(newEventData))
    }
  } catch (e) {
    yield put(updateEventFailure(e))
  }
}

function* updateEventPartialWorker({ eventData }) {
  try {
    const newEventData = yield call(updatePatchEventService, eventData)
    yield put(updatePatchEventSuccess(newEventData))
  } catch (e) {
    yield put(updatePatchEventFailure(e))
  }
}

function* deleteEventWorker({ eventId, eventGroupId }) {
  try {
    yield call(deleteEventService, eventId)
    yield put(deleteEventSuccess(eventId, eventGroupId))
    // as delete btn placed in Edit form - close form on success delete
    yield put(closeForm())
  } catch (e) {
    yield put(deleteEventFailure(e))
  }
}

function* createEventGroupWorker({ eventGroupData }) {
  try {
    const newEventGroupData = yield call(createEventGroupService, eventGroupData)
    yield put(createEventGroupSuccess(newEventGroupData))
  } catch (e) {
    yield put(createEventGroupFailure(e))
  }
}

function* updateEventGroupWorker({ editId, eventGroupData }) {
  try {
    const updatedEventGroupData = yield call(updateEventGroupService, editId, eventGroupData)
    yield put(updateEventGroupSuccess(updatedEventGroupData))
  } catch (e) {
    yield put(updateEventGroupFailure(e))
  }
}

function* updateEventGroupOrderWorker({ eventGroupOrderData }) {
  try {
    const updatedEventGroupData = yield call(updateEventGroupOrderService, eventGroupOrderData)
    yield put(updateEventGroupsOrderSuccess(updatedEventGroupData))
  } catch (e) {
    yield put(updateEventGroupsOrderFailure(e))
  }
}

function* deleteEventGroupWorker({ id }) {
  try {
    const deletedEventGroup = yield call(deleteEventGroupService, id)
    // BE doesn't send respond data on success, so send id back
    yield put(deleteEventGroupSuccess(deletedEventGroup || id))
  } catch (e) {
    yield put(deleteEventGroupFailure(e))
  }
}

export default calendarPlannerWatcher
