import React, { useEffect, useMemo, useRef, useState } from 'react'
import { addDays, addMonths, endOfDay, startOfDay } from 'date-fns'

import CalendarRows from './CalendarRows'
import CalendarTableHeader from './CalendarTableHeader'

import { useWindowSize } from '../../../hooks/useWindowSize'

import { getSubtitleCols, getTitleCols } from '../../../features/components/Calendar/CalendarTable'
import { getRandomEventGroups } from '../../../features/components/Calendar/CalendarTable/skeleton'

import { EVENTS_AREA_PADDING, SIDEBAR_WIDTH } from '../../../constants/timeline'
import { CALENDAR_VIEW_TYPE } from '../../../constants/selectLists/calendarList'

import useStyles from './styles'

const CalendarTableSkeleton = () => {
  const classes = useStyles()

  const tableRef = useRef(null)
  const [width] = useWindowSize()
  const [tableWidth, setTableWidth] = useState(1000)

  const startDate = startOfDay(addDays(new Date(), -2))
  const endDate = endOfDay(addMonths(startDate, 1))
  const selectedViewType = CALENDAR_VIEW_TYPE.QUARTER
  const tableElementWidth = tableRef.current && tableRef.current.offsetWidth

  useEffect(() => {
    // change table width on window width change
    if (!tableRef.current) return
    setTableWidth(tableElementWidth)
  }, [width, tableElementWidth])

  const [titleCols, subtitleCols] = useMemo(() => {
    return [getTitleCols(selectedViewType, startDate, endDate), getSubtitleCols(selectedViewType, startDate, endDate)]

    // there is no need to add selectedViewType as changing selectedViewType will update dates so dates and selectedViewType will always be in sync here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  const tableInnerRowWidth = tableWidth * (1 - SIDEBAR_WIDTH) - 2 * EVENTS_AREA_PADDING

  return (
    <div id="so-calendar" className={classes.root}>
      <table className={classes.tableContainer} ref={tableRef}>
        <CalendarTableHeader titleCols={titleCols} subtitleCols={subtitleCols} />
        <CalendarRows
          products={getRandomEventGroups(5, startDate, endDate)}
          selectedViewType={selectedViewType}
          colsCount={subtitleCols.length}
          tableInnerRowWidth={tableInnerRowWidth}
          startDate={startDate}
          endDate={endDate}
          isSkeleton={true}
        />
      </table>
    </div>
  )
}

export default CalendarTableSkeleton
