import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import MediaFilesLibrary from '../../../../../../ReusableFormFields/MediaFilesLibrary'

import { getAssets, clearAssets } from '../../../../../../../modules/actions/assets'
import {
  assetsErrorSelector,
  assetsIsLoadingSelector,
  assetsNextPageTokenSelector,
  assetsWasLoadedSelector,
  imageAssetsSelector
} from '../../../../../../../modules/selectors/assets'

const GoogleImageFilesLibrary = ({
  adAccountId,
  width,
  height,
  initialLoadPageSize = 12,
  className,
  mediaThumbnailClassName,
  loadMoreButtonClassName,
  loadMoreButtonText,
  ...props
}) => {
  const dispatch = useDispatch()

  const nextPageToken = useSelector(assetsNextPageTokenSelector)

  const loadInitialMediaHandler = useCallback(() => {
    dispatch(
      getAssets({
        account: adAccountId,
        asset_type: 4,
        page_size: initialLoadPageSize,
        height,
        width
      })
    )
  }, [dispatch, height, adAccountId, width, initialLoadPageSize])

  const loadMoreHandler = useCallback(() => {
    dispatch(
      getAssets({
        account: adAccountId,
        asset_type: 4,
        page_size: 12,
        page_token: nextPageToken,
        height,
        width
      })
    )
  }, [dispatch, adAccountId, nextPageToken, height, width])

  const clearAssetsHandler = useCallback(() => {
    dispatch(clearAssets())
  }, [dispatch])

  return (
    <MediaFilesLibrary
      type="image"
      mediaThumbnailFieldName="url"
      mediaFilesSelector={imageAssetsSelector}
      mediaFilesIsLoadingSelector={assetsIsLoadingSelector}
      mediaFilesErrorSelector={assetsErrorSelector}
      mediaFilesWasLoadedSelector={assetsWasLoadedSelector}
      mediaFilesNextSelector={assetsNextPageTokenSelector}
      loadInitialMediaHandler={loadInitialMediaHandler}
      loadMoreHandler={loadMoreHandler}
      clearMediaHandler={clearAssetsHandler}
      initialLoadingSkeletonsNumber={initialLoadPageSize}
      className={className}
      mediaThumbnailClassName={mediaThumbnailClassName}
      loadMoreButtonClassName={loadMoreButtonClassName}
      loadMoreButtonText={loadMoreButtonText}
      {...props}
    />
  )
}

GoogleImageFilesLibrary.propTypes = {
  adAccountId: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  initialLoadPageSize: PropTypes.number,
  className: PropTypes.string,
  mediaThumbnailClassName: PropTypes.string,
  loadMoreButtonClassName: PropTypes.string,
  loadMoreButtonText: PropTypes.string
}

export default GoogleImageFilesLibrary
