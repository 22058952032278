import { createStyles } from '../../../../styles/helpers'

import { tabletDown } from '../../../../styles/const/breakpoints'
import { textGrey } from '../../../../styles/const/colors'

const useStyles = createStyles(theme => ({
  modal: {
    maxWidth: '90%',
    maxHeight: '100vh'
  },
  modalBody: {
    maxWidth: 'unset'
  },
  modalTitle: {
    textAlign: 'left',
    marginBottom: 20,
    color: textGrey
  },
  modalContent: {
    minWidth: 'unset',
    width: '100%',
    display: 'flex'
  },
  contentContainer: {
    maxHeight: '75vh'
  },
  sideMenu: {
    width: 310,
    position: 'relative',
    top: -50
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain'
  },
  [`@media screen and (${tabletDown})`]: {
    modalContent: {
      flexDirection: 'column',
      columnGap: 20
    }
  }
}))

export default useStyles
