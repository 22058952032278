import React, { useCallback, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import EditForm from '../../../../../../features/components/Forms/EditForm'
import MultiSelectBox from '../../../../../../features/components/Form/MultiSelectBox'
import ErrorMessage from '../../../../../../components/Form/ErrorMessage'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import {
  getDeletedCriterionsArray,
  removeExistingCriterions
} from '../../../../../../features/helpers/googleCriterionsHelpers'

import { createCampaignCriterions, clearCreateCampaignCriterions } from '../../../../../../modules/actions/campaigns'
import {
  googleLanguagesIsLoadingSelector,
  googleLanguagesSelector
} from '../../../../../../modules/selectors/googleConstants'
import {
  campaignCriterionsSelector,
  createCampaignCriterionsErrorSelector,
  createCampaignCriterionsIsLoadingSelector,
  createCampaignCriterionsWasCreatedSelector
} from '../../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { languagesEditValidation } from '../../../../../../features/validations/languages'
import { LANGUAGES } from '../../../../../ReusableFormFields/LineItemForms/fields'
import { CAMPAIGN_GOOGLE_LANGUAGE_CRITERION_TYPE } from '../../../../../../constants/campaigns'

const validationSchema = Yup.object({ ...languagesEditValidation })

const LanguagesSectionForm = ({ editItemData: campaign, ...formProps }) => {
  const dispatch = useDispatch()

  const [manualSuccessSubmit, setManualSuccessSubmit] = useState(false)

  const languages = useSelector(googleLanguagesSelector)
  const languagesIsLoading = useSelector(googleLanguagesIsLoadingSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const criterions = useSelector(campaignCriterionsSelector)
  const criterionsWasCreated = useSelector(createCampaignCriterionsWasCreatedSelector)

  const { id: campaignId } = campaign

  const formattedInitialLanguages = useMemo(
    () =>
      criterions
        .filter(criterion => criterion.type_ === CAMPAIGN_GOOGLE_LANGUAGE_CRITERION_TYPE)
        .map(languageCriterion => ({
          label: languages.find(language => language.id === languageCriterion['criterion_id']).name,
          value: languageCriterion['criterion_id'],
          criterionResourceName: languageCriterion.resource_name
        })),
    [criterions, languages]
  )

  const formattedLanguageConstants = useMemo(
    () =>
      languages.map(language => {
        const { id, name, resource_name } = language

        return { value: id, label: name, resourceName: resource_name }
      }),
    [languages]
  )

  const handleSubmit = useCallback(
    values => {
      const criterionsToDelete = getDeletedCriterionsArray(formattedInitialLanguages, values[LANGUAGES])
      const criterionsToCreate = removeExistingCriterions(formattedInitialLanguages, values[LANGUAGES])

      // if nothing was changed > imitate success state to user when he save the form
      if (!criterionsToDelete.length && !criterionsToCreate.length) {
        setManualSuccessSubmit(true)
      } else {
        const formattedCriterionsData = {
          account: selectedAdAccountId,
          operations: criterionsToCreate.map(language => ({
            campaign: campaignId,
            language: {
              language_constant: language.resourceName
            }
          })),
          remove_operations: criterionsToDelete
        }

        dispatch(createCampaignCriterions(formattedCriterionsData, null, criterionsToDelete))
      }
    },
    [dispatch, selectedAdAccountId, formattedInitialLanguages, campaignId]
  )

  const initialValues = useMemo(
    () => ({
      [LANGUAGES]: formattedInitialLanguages || []
    }),
    [formattedInitialLanguages]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const { setFieldValue, values, errors } = purifiedFormik

  const languagesError = errors[LANGUAGES]

  const handleClearCreateCriterions = useCallback(() => {
    dispatch(clearCreateCampaignCriterions())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      clearEditItem={handleClearCreateCriterions}
      successSubmit={criterionsWasCreated || manualSuccessSubmit}
      errorSelector={createCampaignCriterionsErrorSelector}
      isLoadingSelector={createCampaignCriterionsIsLoadingSelector}
      {...formProps}
    >
      <MultiSelectBox
        placeholder="Add a language"
        options={formattedLanguageConstants}
        value={values[LANGUAGES]}
        isLoading={languagesIsLoading}
        name={LANGUAGES}
        setFieldValue={setFieldValue}
      />
      {languagesError && <ErrorMessage error={languagesError} />}
    </EditForm>
  )
}

export default LanguagesSectionForm
