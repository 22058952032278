import React, { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import StepForm from '../../../../../../../features/components/Forms/StepForm'
import CarouselMediaStep from '../../Steps/CarouselMediaStep'
import ProductOrPageStep from '../../Steps/ProductOrPageStep'

import { usePurifiedFormik } from '../../../../../../../hooks/formHooks/usePurifiedFormik'
import useLineItemDetails from '../../hooks/useLineItemDetails'
import usePreselectPixel from '../../hooks/usePreselectPixel'
import getConversionDomain from '../../helpers/getConversionDomain'
import getConversionDomainValidation from '../../helpers/getConversionDomainValidation'
import useCampaignDetails from '../../hooks/useCampaignDetails'
import useFormattedFacebookPages from '../../hooks/useFormattedFacebookPages'
import useIsInternalAdCreation from '../../../../../../../features/hooks/useIsInternalAdCreation'
import { useShowProductOrPageSelectionStep } from '../../hooks/useShowProductOrPageSelectionStep'

import { createAd } from '../../../../../../../modules/actions/ads'
import { validatedDomainsSelector } from '../../../../../../../modules/selectors/ads'
import {
  selectedAdAccountIdSelector,
  selectedSelfAccountDataSelector
} from '../../../../../../../modules/selectors/app'
import { accessibleFacebookPagesSelector } from '../../../../../../../modules/selectors/socialAccounts'

import { transformValuesToBE } from '../../fields'
import { AD_LINK, CAROUSEL_ITEMS, initialLink, PIXEL_CHOICE } from '../../../../fields'
import { FACEBOOK_PLATFORM } from '../../../../../../../constants/selectLists/platformList'
import { getValidationSchema } from './validation'
import { getInitialValues } from './initialValues'

const MultipleMediaFileRoute = ({ FormRouteSelector, eventSourceStep, adAccountId, warningSteps, ...formProps }) => {
  const dispatch = useDispatch()

  const facebookPages = useSelector(accessibleFacebookPagesSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const validatedDomains = useSelector(validatedDomainsSelector)
  const { controller: selfAccountController } = useSelector(selectedSelfAccountDataSelector)

  const showProductSelectionStep = useShowProductOrPageSelectionStep()

  const { lineItemId, showAsStory } = useLineItemDetails()
  const { campaignId } = useCampaignDetails()

  const providedAdAccountId = adAccountId || selectedAdAccountId

  const isInternalAdCreation = useIsInternalAdCreation()
  const formattedFacebookPages = useFormattedFacebookPages(facebookPages)

  const onSubmit = useCallback(
    values => {
      dispatch(
        createAd(
          transformValuesToBE({
            values,
            adAccountId: providedAdAccountId,
            campaignId,
            adSetId: lineItemId,
            validatedDomains,
            isInternalAdCreation,
            selfAccountController
          }),
          FACEBOOK_PLATFORM
        )
      )
    },
    [
      dispatch,
      providedAdAccountId,
      campaignId,
      lineItemId,
      validatedDomains,
      isInternalAdCreation,
      selfAccountController
    ]
  )

  const initialValues = useMemo(
    () => getInitialValues({ facebookPages: formattedFacebookPages }),
    [formattedFacebookPages]
  )

  const validationSchema = useMemo(() => getValidationSchema({ showProductSelectionStep }), [showProductSelectionStep])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)
  const { values, setValues, setFieldValue } = purifiedFormik

  const selectedPixel = values[PIXEL_CHOICE]

  const conversionDomain = getConversionDomain(values)

  const { isDomainValidating } = getConversionDomainValidation(conversionDomain, validatedDomains)

  usePreselectPixel({ selectedPixel, setFieldValue })

  const onProductOrPageDataSelect = useCallback(
    (productSelectionValues, productData) => {
      const updatedCarouselItems = values[CAROUSEL_ITEMS]?.map((item, index) => {
        const itemLink = item[AD_LINK]
        if (!itemLink || itemLink === initialLink) {
          // preselect selectedProductLink to each carousel item which doesn't have selected link yet
          return {
            ...item,
            [AD_LINK]: productData.link
          }
        }
        return item
      })

      const updatedValues = {
        ...values,
        ...productSelectionValues,
        [CAROUSEL_ITEMS]: updatedCarouselItems
      }

      setValues(updatedValues)
    },
    [values, setValues]
  )

  const steps = useMemo(
    () => [
      ...warningSteps,
      {
        component: FormRouteSelector,
        show: !showAsStory
      },
      {
        component: <ProductOrPageStep onProductOrPageDataSelect={onProductOrPageDataSelect} />,
        show: showProductSelectionStep
      },
      {
        component: <CarouselMediaStep adAccountId={providedAdAccountId} showAISuggestions={showProductSelectionStep} />,
        show: true
      },
      eventSourceStep
    ],
    [
      FormRouteSelector,
      showAsStory,
      eventSourceStep,
      providedAdAccountId,
      warningSteps,
      showProductSelectionStep,
      onProductOrPageDataSelect
    ]
  )

  return <StepForm steps={steps} formik={purifiedFormik} {...formProps} isSubmitDataLoading={isDomainValidating} />
}

export default MultipleMediaFileRoute
