import React from 'react'
import { getIn } from 'formik'

import ProductSetupStep from '../Steps/ProductSetupStep'

import { getAllPackageProducts, getProductsPath } from './products'
import { SELECTED_PACKAGES } from '../fields'

export const getProductSetupStep = ({ selectedCurrency, selectedMediaPackage, packageIndex, productsPackageFlow }) => {
  // get all products from all categories
  const packageProducts = getAllPackageProducts(selectedMediaPackage)
  const productReadyToSetup = packageProducts.find(product => product.isSetupAllowed)
  const productData = productReadyToSetup?.data

  if (productReadyToSetup?.isSetupAllowed) {
    // render product setup one by one i.e. avoid render/representing multiple Setup products steps at once
    const productCategory = productData?.media_category
    const productsInCategory = getIn(selectedMediaPackage, getProductsPath(productCategory))
    const productIndex = productsInCategory.findIndex(product => product.data.id === productData.id)
    const productPath = `${SELECTED_PACKAGES}[${packageIndex}].${getProductsPath(productCategory)}.[${productIndex}]`
    const mediaProductsPath = `${SELECTED_PACKAGES}[${packageIndex}].${getProductsPath(productData.media_category)}`

    return [
      {
        component: (
          <ProductSetupStep
            productValues={productReadyToSetup}
            productItemPath={productPath}
            mediaProductsPath={mediaProductsPath}
            packageIndex={packageIndex}
            currency={selectedCurrency}
            productsPackageFlow={productsPackageFlow}
          />
        ),
        show: true
      }
    ]
  } else {
    return []
  }
}
