import { createStyles } from '../../../styles/helpers'

import { transitionShort } from '../../../styles/const/common'
import { darkGrey } from '../../../styles/const/colors'
import { dropdownMenuHover } from '../../../styles/common/components'

export default createStyles({
  actionsList: {
    margin: '0 auto',
    padding: '32px 0 4px',
    listStyle: 'none',
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    width: '100%'
  },
  actionsItem: {
    display: 'block',
    transition: transitionShort,
    '&:hover': {
      extend: dropdownMenuHover
    }
  },
  actionsLink: {
    textDecoration: 'none',
    color: darkGrey
  }
})
