import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import { addDays, isPast, isToday } from 'date-fns'

import {
  CAMPAIGN_OPTION,
  CAMPAIGN_OPTION_NEW_CAMPAIGN,
  campaignValidation,
  getBudgetValidation,
  getCampaignInitialValues
} from '../../../../Multiplatform/UploadAdCreative/fields'
import { formatDateToBE } from '../../../../../constants/dates'

import { BUDGET_LIFETIME } from '../../../../Multiplatform/CampaignForms/fields'
export const OBJECTIVE_PERCENTAGE = 'objective_percentage'
export const PERFORMANCE_GOAL_TYPE_CTR = 'PERFORMANCE_GOAL_TYPE_CTR'

export const timeUnitsValues = {
  TIME_UNIT_LIFETIME: 'TIME_UNIT_LIFETIME',
  TIME_UNIT_MONTHS: 'TIME_UNIT_MONTHS',
  TIME_UNIT_WEEKS: 'TIME_UNIT_WEEKS'
}

export const getInitialValues = ({ isCampaignsExist }) => {
  const campaignsInitialValues = getCampaignInitialValues(isCampaignsExist)

  return {
    ...campaignsInitialValues,

    [BUDGET_LIFETIME]: ''
  }
}

export const getValidationSchema = ({ remainingBudget, currencySymbol }) => {
  return Yup.object().shape({
    ...campaignValidation,

    [BUDGET_LIFETIME]: Yup.number().when(CAMPAIGN_OPTION, {
      is: CAMPAIGN_OPTION_NEW_CAMPAIGN,
      then: () => getBudgetValidation({ remainingBudget, currencySymbol })
    })
  })
}

export const transformValuesToBE = ({ values, mediaOrder, account }) => {
  const { name: mediaOrderName, start_date: mediaOrderStartDate, end_date: mediaOrderEndDate } = mediaOrder

  // If mediaOrder start date is in the past or today, then set campaign start date to tomorrow
  const startDate =
    isPast(new Date(mediaOrderStartDate)) || isToday(new Date(mediaOrderStartDate))
      ? formatDateToBE(addDays(new Date(), 1))
      : formatDateToBE(new Date(mediaOrderStartDate))

  const endDate = formatDateToBE(new Date(mediaOrderEndDate))

  return {
    account,
    name: `${mediaOrderName} - DV360 - ${uuidv4().slice(0, 7)}`,
    budget_lifetime: values[BUDGET_LIFETIME],
    date_start: startDate,
    date_stop: endDate,

    // Hardcoded values
    status: 'paused',
    frequency_cap: {
      time_unit: timeUnitsValues.TIME_UNIT_WEEKS,
      time_unit_count: 1,
      max_impressions: 14
    },
    objective: PERFORMANCE_GOAL_TYPE_CTR,
    [OBJECTIVE_PERCENTAGE]: '0.5',
    bid_strategy: { fixed_bid: {} },
    pacing: {
      pacing_period: 'PACING_PERIOD_FLIGHT',
      pacing_type: 'PACING_TYPE_AHEAD'
    }
  }
}
