import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import MultiSelectBox from '../../../features/components/Form/MultiSelectBox'

import { getGoogleLanguages } from '../../../modules/actions/googleConstants'
import {
  googleLanguagesIsLoadingSelector,
  googleLanguagesSelector,
  googleLanguagesWasLoadedSelector
} from '../../../modules/selectors/googleConstants'

import { selectedAdAccountIdSelector } from '../../../modules/selectors/app'

const GoogleLanguages = ({ name, value, setFieldValue, adAccountId, placeholder = 'Add a language' }) => {
  const dispatch = useDispatch()

  const languages = useSelector(googleLanguagesSelector)
  const languagesIsLoading = useSelector(googleLanguagesIsLoadingSelector)
  const languagesWasLoaded = useSelector(googleLanguagesWasLoadedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const formattedLanguages = useMemo(
    () =>
      languages.map(item => {
        const { id, name, resource_name } = item

        return { value: id, label: name, resource_name }
      }),
    [languages]
  )

  useEffect(() => {
    if (!languagesWasLoaded) {
      dispatch(
        getGoogleLanguages({
          account: adAccountId || selectedAdAccountId
        })
      )
    }
  }, [dispatch, adAccountId, selectedAdAccountId, languagesWasLoaded])

  return (
    <MultiSelectBox
      placeholder={placeholder}
      name={name}
      options={formattedLanguages}
      value={value}
      isLoading={languagesIsLoading}
      setFieldValue={setFieldValue}
    />
  )
}

export default GoogleLanguages
