import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles({
  fileDetailsPreview: {
    marginTop: [0, '!important'],
    marginBottom: [8, '!important']
  },
  dropFileUploader: {
    marginTop: [0, '!important']
  }
})

export default useStyles
