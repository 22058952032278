import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import FormWrapper from '../../Form/FormWrapper'
import SkeletonForm from '../../../../components/Form/Skeleton'
import StepFormContent from './StepFormContent'

import { activeStepSelector } from '../../../../modules/selectors/forms'

import useStyles from './style'

const StepForm = React.memo(
  ({
    steps,
    // todo revisit ideas with following props and think of overall refactoring
    customStepsForProgress,
    customActiveStep,
    formProgressItems,
    hasWidthLimit,
    isInitialDataLoading,
    ...props
  }) => {
    const activeStep = useSelector(activeStepSelector)

    const classes = useStyles({ activeStep, isFormProgressExist: !!formProgressItems })

    const visibleSteps = steps.filter(step => step.show)

    return (
      <FormWrapper
        stepsForProgress={customStepsForProgress || visibleSteps.length}
        activeStep={customActiveStep || activeStep}
        formProgressItems={formProgressItems}
        hasWidthLimit={hasWidthLimit}
      >
        {isInitialDataLoading ? (
          <SkeletonForm className={classes.form} />
        ) : (
          <StepFormContent steps={steps} formProgressItems={formProgressItems} {...props} />
        )}
      </FormWrapper>
    )
  }
)

StepForm.propTypes = {
  steps: PropTypes.array,
  customStepsForProgress: PropTypes.number,
  customActiveStep: PropTypes.number,
  formProgressItems: PropTypes.array,
  isInitialDataLoading: PropTypes.bool,
  onBackButtonClick: PropTypes.func
}

export default StepForm
