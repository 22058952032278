import * as Yup from 'yup'

import {
  initialProductOrPagesValues,
  productOrPagesValidation
} from '../../../../../../ReusableFormFields/ProductsAndPagesFields/fields'

// audience form has two radio options to select brand/category or product pages
// however, we treat them as the same entity later (audience creation, usage on AdProductForm)
export const initialValues = {
  ...initialProductOrPagesValues
}

export const validationSchema = Yup.object({
  ...productOrPagesValidation
})
