import { MEDIA_CATEGORY } from '../MediaProductCreate/MediaProductCreateForm/fields'
import { MEDIA_SUB_CATEGORY, MEDIA_SUB_SUB_CATEGORY } from '../fields'

export const getInitialCategories = editProductData => {
  return {
    [MEDIA_CATEGORY]: editProductData[MEDIA_CATEGORY] || '',
    [MEDIA_SUB_CATEGORY]: editProductData[MEDIA_SUB_CATEGORY] || '',
    [MEDIA_SUB_SUB_CATEGORY]: editProductData[MEDIA_SUB_SUB_CATEGORY] || ''
  }
}
