import { createStyles } from '../../../styles/helpers'

import { transitionShort } from '../../../styles/const/common'
import { greyOutline, red, textGrey } from '../../../styles/const/colors'
import { input } from '../../../styles/common/components'

const symbolWidth = 12 //px
const symbolPadding = 8 //px
export const inputPaddingLeft = 14 //px

const getPaddingLeft = ({ hasSymbol, symbolLength, symbolPosition }) => {
  return hasSymbol && symbolPosition !== 'end' ? symbolLength * symbolWidth + inputPaddingLeft : inputPaddingLeft
}
const getPaddingRight = ({ hasSymbol, symbolLength, symbolPosition }) => {
  return hasSymbol && symbolPosition === 'end' ? symbolLength * symbolWidth + symbolPadding : 14
}

export default createStyles(theme => ({
  symbol: {
    opacity: ({ hasValue }) => (hasValue ? 1 : 0),
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    transition: transitionShort,
    fontSize: '14px',
    color: textGrey,
    pointerEvents: 'none'
  },
  symbolEnd: {
    right: `${symbolPadding}px`
  },
  symbolStart: {
    left: `${symbolPadding}px`
  },
  input: {
    extend: input(true, theme),
    padding: props => `0 ${getPaddingRight(props)}px 0 ${getPaddingLeft(props)}px`,
    borderColor: props => (props.showError ? [red, '!important'] : greyOutline)
  }
}))
