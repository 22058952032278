import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import classnames from 'classnames'

import {
  getInventoryGroupIsLoadingSelector,
  inventoryGroupProductsSelector
} from '../../../../../../../../../modules/selectors/mediaOrdersProducts'

import useStyles from './styles'

function SubProductsList({ availableProducts }) {
  const classes = useStyles()

  const inventoryGroupIsLoading = useSelector(getInventoryGroupIsLoadingSelector)
  const inventoryGroupProducts = useSelector(inventoryGroupProductsSelector)
  // Convert availableProducts to a Set of IDs for O(1) lookup
  const availableProductIds = useMemo(() => new Set(availableProducts.map(p => p.id)), [availableProducts])

  return (
    <>
      {inventoryGroupIsLoading ? (
        <Skeleton count={3} width="80%" className={classes.product} />
      ) : (
        inventoryGroupProducts.map(product => {
          const isNotAvailable = !availableProductIds.has(product.id)
          return (
            <div
              key={product.id}
              className={classnames(classes.product, {
                [classes.isNotAvailable]: isNotAvailable
              })}
            >
              {product.name}
            </div>
          )
        })
      )}
    </>
  )
}

export default SubProductsList
