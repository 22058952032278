import React from 'react'
import classnames from 'classnames'

import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

const SkeletonGoogleAssetsSliderPreview = () => {
  const classes = useStyles()

  return (
    <div className={classnames(classes.sliderPreview)}>
      <div className={classnames(classes.slide)}>
        <div className={classes.skeletonSlideWrapper}>
          <Skeleton height={120} />
        </div>
      </div>
    </div>
  )
}

export default SkeletonGoogleAssetsSliderPreview
