import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles({
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logoMiddle: {
    marginLeft: 40,
    marginRight: 40
  },
  logo: {
    '& svg': {
      width: 80,
      height: 'auto'
    }
  }
})

export default useStyles
