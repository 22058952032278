import React from 'react'

import { formatNumber } from '../../../../../../../../helpers/numbers'

import useStyles from './styles'

const FileRequirementsInfoBlock = ({ fileRequirements }) => {
  const classes = useStyles()

  const {
    description,
    height,
    max_file_size,
    max_height,
    max_width,
    min_file_size,
    min_height,
    min_width,
    ratio_from,
    ratio_to,
    resolution_from,
    resolution_to,
    supported_file_types,
    width
  } = fileRequirements

  return (
    <div className={classes.container}>
      {description && <p>{description}</p>}
      {supported_file_types && <p>File type: {supported_file_types.join(', ')}</p>}
      {max_file_size && <p className={classes.requirementNum}>Max file size: {formatNumber(max_file_size)}Mb</p>}
      {min_file_size && <p className={classes.requirementNum}>Min file size: {formatNumber(min_file_size)}Mb</p>}
      {width && <p className={classes.requirementNum}>Width: {formatNumber(width)}px</p>}
      {height && <p className={classes.requirementNum}>Height: {formatNumber(height)}px</p>}
      {max_width && <p className={classes.requirementNum}>Max width: {formatNumber(max_width)}px</p>}
      {min_width && <p className={classes.requirementNum}>Min width: {formatNumber(min_width)}px</p>}
      {max_height && <p className={classes.requirementNum}>Max height: {formatNumber(max_height)}px</p>}
      {min_height && <p className={classes.requirementNum}>Min height: {formatNumber(min_height)}px</p>}
      {ratio_from && (
        <p className={classes.requirementNum}>Ratio from: {formatNumber(ratio_from, { min: 0, max: 2 })}</p>
      )}
      {ratio_to && <p className={classes.requirementNum}>Ratio to: {formatNumber(ratio_to, { min: 0, max: 2 })}</p>}
      {resolution_from && <p className={classes.requirementNum}>Resolution from: {formatNumber(resolution_from)}dpi</p>}
      {resolution_to && <p className={classes.requirementNum}>Resolution to: {formatNumber(resolution_to)}dpi</p>}
    </div>
  )
}

export default FileRequirementsInfoBlock
