import React from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import useStyles from './styles'
import useDrawerFormStyles from '../../../../styles/common/drawerForms'

const ContractButtonSection = ({ title, description, children }) => {
  const { t } = useTranslation()

  const drawerFormClasses = useDrawerFormStyles()
  const classes = useStyles()

  return (
    <section className={classnames(drawerFormClasses.section, classes.section)}>
      <div>
        <h4>{t(title)}</h4>
        <p className={classes.description}>{t(description)}</p>
      </div>
      {children}
    </section>
  )
}

ContractButtonSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  Button: PropTypes.node
}

export default ContractButtonSection
