import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useStyles from './styles'

const FieldSectionSpacer = ({ hasMargin, className }) => {
  const classes = useStyles(hasMargin)

  return <hr className={classnames(classes.spacer, className)} />
}

FieldSectionSpacer.propTypes = {
  hasMargin: PropTypes.bool,
  className: PropTypes.string
}

export default FieldSectionSpacer
