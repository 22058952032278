import { v4 as uuidv4 } from 'uuid'

import { formatChildAttachments, formatSingleItemFeedToBE } from '../../../../helpers'

import { AD_FORMAT } from '../../../../../../../constants/ads'

import {
  AD_DESCRIPTION,
  AD_HEADLINE,
  AD_LINK,
  AD_PREVIEW_CAROUSEL_INDEX_KEY,
  CAROUSEL_ITEMS,
  IS_CUSTOMIZED_MEDIA,
  DESCRIPTIONS_LIST,
  FILE_HASH,
  FILE_ID,
  FILE_NAME,
  FILE_URL,
  FORMAT_CAROUSEL,
  FORMAT_OPTION,
  FORMAT_SINGLE,
  HEADLINES_LIST,
  initialCarouselItem,
  IS_ITEM_FORM_OPENED,
  MEDIA_FILE_OPTION,
  MEDIA_FILE_PREVIEW,
  MEDIA_TYPE,
  PRIMARY_TEXT_LIST,
  VIDEO_PICTURE
} from '../../../../fields'
import {
  MEDIA_FILE_IMAGE_USE_LIBRARY,
  UPLOADED_MEDIA_TYPE
} from '../../../../../../ReusableFormFields/AdFileUpload/fields'
import { CALL_TO_ACTION } from '../../../../../../ReusableFormFields/CallToActionFields/fields'
import { formatSingleItemWithStoryToBE } from '../../../../AdFacebookCreate/AdFacebookCreateForm/formatters'

export function getCreative({ values, originalAd, adFormat, adAccountId }) {
  const facebookPageId = originalAd.creative.object_story_spec?.page_id

  const multiShareEndCard = originalAd?.creative?.object_story_spec?.multi_share_end_card
  const multiShareOptimized = originalAd?.creative?.object_story_spec?.multi_share_optimized

  let singleValues = {}
  if (adFormat === AD_FORMAT.single || adFormat === AD_FORMAT.singleCustom) {
    // format data only when adFormat is single

    let adHeadline = values.singleMediaItem[HEADLINES_LIST]?.[0].text
    let adDescription = values.singleMediaItem[DESCRIPTIONS_LIST]?.[0].text

    singleValues = {
      ...values,
      ...values.singleMediaItem,
      [AD_HEADLINE]: adHeadline,
      [AD_DESCRIPTION]: adDescription
    }
  }

  switch (adFormat) {
    case AD_FORMAT.carousel:
      const carouselItems = values[CAROUSEL_ITEMS].map(item => {
        // For edit form if OPTION === BLANK send empty string
        let adHeadline = item[HEADLINES_LIST]?.[0].text
        let adDescription = item[DESCRIPTIONS_LIST]?.[0].text

        return {
          ...item,
          [DESCRIPTIONS_LIST]: [{ text: adDescription }],
          [HEADLINES_LIST]: [{ text: adHeadline }]
        }
      })

      return {
        object_story_spec: {
          link_data: {
            // FB requires this field for a carousel ad, so the link is taken from first Carousel item
            child_attachments: formatChildAttachments(carouselItems),
            link: values[CAROUSEL_ITEMS][0][AD_LINK],
            ...(originalAd.creative.object_story_spec?.caption && {
              caption: originalAd.creative.object_story_spec?.caption
            }),
            message: values[PRIMARY_TEXT_LIST]?.[0].text,

            // multi_share_end_card and multi_share_optimized are set if ad is created as carousel,
            // if ad was created as single, and we switch it to carousel - set both as false
            multi_share_end_card: multiShareEndCard || false,
            multi_share_optimized: multiShareOptimized || false
          },
          page_id: facebookPageId
        }
      }
    case AD_FORMAT.single:
      return {
        object_story_spec: {
          ...formatSingleItemFeedToBE(singleValues),
          page_id: facebookPageId
        }
      }
    case AD_FORMAT.singleCustom:
    default:
      return formatSingleItemWithStoryToBE({ values: singleValues, adAccountId })
  }
}

export function getInitialValuesByFormat(adValues, adFormat) {
  switch (adFormat) {
    case AD_FORMAT.carousel:
      return getCarouselInitialValues(adValues)
    case AD_FORMAT.single:
    default:
      return getSingleInitialValues(adValues)
  }
}

export function getCarouselInitialValues(adValues) {
  return {
    [FORMAT_OPTION]: AD_FORMAT.carousel,
    [CAROUSEL_ITEMS]: adValues[CAROUSEL_ITEMS],
    [IS_ITEM_FORM_OPENED]: true,

    // primary text
    [PRIMARY_TEXT_LIST]: adValues[PRIMARY_TEXT_LIST] ? adValues[PRIMARY_TEXT_LIST] : [{ text: '' }],
    [AD_PREVIEW_CAROUSEL_INDEX_KEY]: adValues[AD_PREVIEW_CAROUSEL_INDEX_KEY]
  }
}

export function getSingleInitialValues(adValues) {
  const singleMediaItem = {
    // ad text
    [PRIMARY_TEXT_LIST]: adValues[PRIMARY_TEXT_LIST]?.map(item => ({ ...item, id: uuidv4() })),
    // headline
    [HEADLINES_LIST]: adValues[HEADLINES_LIST]?.map(item => ({ ...item, id: uuidv4() })),
    // // description
    [DESCRIPTIONS_LIST]: adValues[DESCRIPTIONS_LIST]?.map(item => ({ ...item, id: uuidv4() })),
    // open existed library image or video
    // !!! We need both fields, otherwise edit form may be broken
    // todo SHOP-1851 Refactor to avoid one field bellow
    [MEDIA_FILE_OPTION]: MEDIA_FILE_IMAGE_USE_LIBRARY,
    [UPLOADED_MEDIA_TYPE]: MEDIA_FILE_IMAGE_USE_LIBRARY,
    [MEDIA_FILE_PREVIEW]: '',
    ...adValues
  }

  return {
    ...singleMediaItem,
    [FORMAT_OPTION]: AD_FORMAT.single,
    // isCustom
    [IS_CUSTOMIZED_MEDIA]: adValues[IS_CUSTOMIZED_MEDIA]
  }
}

export function transformCarouselToSingle(carouselItems) {
  const carouselItemValues = carouselItems[0]

  // change format option to single
  return {
    ...carouselItemValues,
    singleMediaItem: {
      ...carouselItemValues
    },
    [FORMAT_OPTION]: FORMAT_SINGLE
  }
}

export function transformSingleToCarousel(modifiedAdValues) {
  const carouselItem = {
    [AD_DESCRIPTION]: modifiedAdValues.singleMediaItem[AD_DESCRIPTION],
    [DESCRIPTIONS_LIST]: modifiedAdValues.singleMediaItem[DESCRIPTIONS_LIST],
    [AD_HEADLINE]: modifiedAdValues.singleMediaItem[AD_HEADLINE],
    [HEADLINES_LIST]: modifiedAdValues.singleMediaItem[HEADLINES_LIST],
    [CALL_TO_ACTION]: modifiedAdValues.singleMediaItem[CALL_TO_ACTION],
    [AD_LINK]: modifiedAdValues.singleMediaItem[AD_LINK],
    [MEDIA_TYPE]: modifiedAdValues.singleMediaItem[MEDIA_TYPE],
    [MEDIA_FILE_OPTION]: modifiedAdValues.singleMediaItem[MEDIA_FILE_OPTION],
    [FILE_HASH]: modifiedAdValues.singleMediaItem[FILE_HASH],
    [FILE_ID]: modifiedAdValues.singleMediaItem[FILE_ID],
    [VIDEO_PICTURE]: modifiedAdValues.singleMediaItem[VIDEO_PICTURE],
    [FILE_URL]: modifiedAdValues.singleMediaItem[FILE_URL],
    [FILE_NAME]: modifiedAdValues.singleMediaItem[FILE_NAME]
  }

  // add new initial item to carousel items array
  // AD_LINK, CALL_TO_ACTION from existed item must be added to new item
  const newCarouselItem = {
    ...initialCarouselItem,
    [AD_LINK]: modifiedAdValues.singleMediaItem[AD_LINK],
    [CALL_TO_ACTION]: modifiedAdValues.singleMediaItem[CALL_TO_ACTION]
  }

  // change format option to carousel, combine existed media item with newly created
  return {
    [FORMAT_OPTION]: FORMAT_CAROUSEL,
    [CAROUSEL_ITEMS]: [carouselItem, newCarouselItem],
    [AD_PREVIEW_CAROUSEL_INDEX_KEY]: modifiedAdValues[AD_PREVIEW_CAROUSEL_INDEX_KEY],
    // pre Open edit form
    [IS_ITEM_FORM_OPENED]: true,
    // is custom
    [IS_CUSTOMIZED_MEDIA]: modifiedAdValues.singleMediaItem[IS_CUSTOMIZED_MEDIA]
  }
}
