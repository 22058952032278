import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import Page from '../index'
import CampaignSummaryContent from './CampaignSummaryContent'
import InfoBlock from '../../features/components/InfoBlock'
import SocialAuthChecker from '../../features/components/SocialAuthChecker'

import { clearCampaign, getCampaign } from '../../modules/actions/campaigns'
import { clearAds } from '../../modules/actions/ads'
import { clearLineItems } from '../../modules/actions/lineItems'

import {
  selectedPlatformSelector,
  selectedAdAccountIdSelector,
  userSelfAccountTypeSelector
} from '../../modules/selectors/app'
import { campaignErrorSelector, campaignWasLoadedSelector } from '../../modules/selectors/campaigns'

import { PLATFORM_LABELS } from '../../constants/selectLists/platformList'
import { CONTROLLER_TYPE } from '../../constants/permissions'

function CampaignsSummaryPage() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { campaign_id: campaignId } = useParams()

  const campaignError = useSelector(campaignErrorSelector)
  const selectedPlatform = useSelector(selectedPlatformSelector)
  const campaignWasLoaded = useSelector(campaignWasLoadedSelector)
  const userAccountType = useSelector(userSelfAccountTypeSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const loadCampaign = useCallback(() => {
    if (!campaignWasLoaded) {
      dispatch(getCampaign({ id: campaignId, account: selectedAdAccountId }))
    }
  }, [dispatch, campaignId, selectedAdAccountId, campaignWasLoaded])

  const authFailureText = useMemo(() => {
    if (userAccountType === CONTROLLER_TYPE) {
      return `Please authenticate with ${PLATFORM_LABELS[selectedPlatform]} to retrieve the campaign details.`
    } else {
      return 'Please contact your account manager to access this campaign details'
    }
  }, [selectedPlatform, userAccountType])

  useEffect(() => {
    return () => {
      dispatch(clearLineItems())
      dispatch(clearCampaign())
      dispatch(clearAds())
    }
  }, [dispatch])

  return (
    <Page helmetTitle="helmetTitle.CampaignsPage">
      {campaignError ? (
        <InfoBlock title={t('Sorry, we can’t find that campaign')} centered greyDescription>
          <div>{t('You might not have access to it, or it may have been deleted')}</div>
        </InfoBlock>
      ) : (
        <SocialAuthChecker
          platform={selectedPlatform}
          dataFetch={loadCampaign}
          adAccountId={selectedAdAccountId}
          authFailureText={authFailureText}
        >
          <CampaignSummaryContent />
        </SocialAuthChecker>
      )}
    </Page>
  )
}

export default CampaignsSummaryPage
