import { createStyles } from '../../../../../../styles/helpers'
import { lightGrey } from '../../../../../../styles/const/colors'

import { textEllipsisMultiline } from '../../../../../../styles/mixins/text'

export const carouselAdThumbnailHeight = 150

export const slide = data => {
  if (!data) return 0
  const { selectedCarouselItemIndex, lastItemIndex } = data

  if (!selectedCarouselItemIndex) {
    return 0
  } else if (selectedCarouselItemIndex === lastItemIndex) {
    return -160 * selectedCarouselItemIndex + 72
  } else {
    return -160 * selectedCarouselItemIndex + 38
  }
}

export default createStyles({
  carouselItem: {
    width: 150,
    minWidth: 150,
    height: 185,
    borderRadius: 5,
    border: `1px solid ${lightGrey}`,
    marginRight: 10,
    overflow: 'hidden',
    transition: 'all .5s',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',

    '&:hover': {
      boxShadow: '0 1px 5px 2px rgba(0,0,0,.05)'
    }
  },
  thumbnail: {
    height: 150,
    maxHeight: carouselAdThumbnailHeight
  },
  footer: {
    height: 35,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 5px 3px'
  },
  info: {
    width: 85,
    maxWidth: '60%',
    height: '100%',
    position: 'relative'
  },

  // headline
  carouseHeadlineText: {
    fontSize: 8,
    ...textEllipsisMultiline(2),
    position: 'absolute',
    // paddingRight to not touch the action button
    paddingRight: 1
  },
  skeletonCarouselHeadline: {
    maxHeight: 7,
    height: 7,
    // marginRight to not touch the action button
    marginRight: 5
  },

  // description
  carouseDescriptionText: {
    fontSize: 7,
    position: 'absolute',
    bottom: 0
  },

  skeletonCarouselDescription: {
    width: 50
  },

  // media thumbnail
  carouselItemThumbnail: {
    minHeight: '100%',
    minWidth: '100%'
  },

  // action button
  carouseActionButton: {
    padding: '0 4px'
  }
})
