import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import Page from '../index'
import Can from '../../features/components/Can'
import ListItemsFilters from '../../features/components/ListItemsFilters'
import SocialAuthChecker from '../../features/components/SocialAuthChecker'
import CampaignFacebookCreate from '../../forms/Facebook/CampaignForms/CampaignFacebookCreate'

import CampaignGoogleCreate from '../../forms/Google/CampaignForms/CampaignGoogleCreate'
import CampaignTikTokCreate from '../../forms/Tiktok/CampaignForms/CampaignTikTokCreate'
import CampaignsContent from './CampaignsContent'

import useSearch from '../../hooks/useSearch'

import { formatSortParams } from '../../features/formatters'
import { getListItemInsights } from '../../features/helpers/componentsHelpers'

import { getCampaigns, clearCampaigns } from '../../modules/actions/campaigns'
import { setCampaignsFilter, setCampaignsDateRange, setCampaignsSort } from '../../modules/actions/campaigns'
import {
  campaignsFilterSelector,
  campaignsDateRangeSelector,
  campaignsSortSelector,
  campaignsAfterSelector
} from '../../modules/selectors/campaigns'
import {
  selectedPlatformSelector,
  selectedAdAccountIdSelector,
  userSelfAccountTypeSelector
} from '../../modules/selectors/app'

import {
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  PLATFORM_LABELS,
  TIKTOK_PLATFORM
} from '../../constants/selectLists/platformList'
import { CAMPAIGN_FILTERS_DEFAULT } from '../../constants/selectLists/listItemFiltersList'
import { DATES_FORMAT_BE } from '../../constants/dates'
import { CAMPAIGN_PERMISSION, CONTROLLER_TYPE } from '../../constants/permissions'

const searchId = 'campaignsPage'

function CampaignsPage() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const sort = useSelector(campaignsSortSelector)
  const filter = useSelector(campaignsFilterSelector)
  const dateRange = useSelector(campaignsDateRangeSelector)

  const { date_preset, startDate, endDate } = dateRange

  const searchTerm = useSearch(searchId)
  const campaignsAfter = useSelector(campaignsAfterSelector)
  const selectedPlatform = useSelector(selectedPlatformSelector)
  const userAccountType = useSelector(userSelfAccountTypeSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const campaignCreateForm = useMemo(() => {
    switch (selectedPlatform) {
      case FACEBOOK_PLATFORM:
        return (
          <Can I="create" a={CAMPAIGN_PERMISSION}>
            <CampaignFacebookCreate />
          </Can>
        )
      case GOOGLE_PLATFORM:
        return (
          <Can I="create" a={CAMPAIGN_PERMISSION}>
            <CampaignGoogleCreate />
          </Can>
        )
      case TIKTOK_PLATFORM:
        return (
          <Can I="create" a={CAMPAIGN_PERMISSION}>
            <CampaignTikTokCreate />
          </Can>
        )
      default:
        return null
    }
  }, [selectedPlatform])

  const loadCampaigns = useCallback(
    campaignsAfter => {
      dispatch(
        getCampaigns({
          date_from: startDate && format(startDate, DATES_FORMAT_BE),
          date_to: endDate && format(endDate, DATES_FORMAT_BE),
          date_preset,
          insights: getListItemInsights(selectedPlatform),
          account: selectedAdAccountId,
          status: filter === CAMPAIGN_FILTERS_DEFAULT ? undefined : filter,
          search: searchTerm,
          limit: 10,
          ...formatSortParams(sort),
          ...(campaignsAfter && { after: campaignsAfter })
        })
      )
    },
    [dispatch, selectedPlatform, startDate, endDate, date_preset, selectedAdAccountId, filter, searchTerm, sort]
  )

  const authFailureText = useMemo(() => {
    if (userAccountType === CONTROLLER_TYPE) {
      return `Please authenticate with ${PLATFORM_LABELS[selectedPlatform]} to retrieve the list of campaigns.`
    } else {
      return 'Please contact your account manager to access these campaigns'
    }
  }, [selectedPlatform, userAccountType])

  const loadMoreCampaignsHandler = useCallback(() => {
    loadCampaigns(campaignsAfter)
  }, [loadCampaigns, campaignsAfter])

  useEffect(() => {
    return () => {
      dispatch(clearCampaigns())
    }
  }, [dispatch])

  return (
    <Page helmetTitle="helmetTitle.CampaignsPage">
      <SocialAuthChecker
        platform={selectedPlatform}
        dataFetch={loadCampaigns}
        adAccountId={selectedAdAccountId}
        authFailureText={authFailureText}
      >
        <ListItemsFilters
          pageTitle={t('Digital Campaigns')}
          createFormComponent={campaignCreateForm}
          setFilter={setCampaignsFilter}
          setSort={setCampaignsSort}
          setDateRange={setCampaignsDateRange}
          searchId={searchId}
          dateRangeSelector={campaignsDateRangeSelector}
          filterSelector={campaignsFilterSelector}
          sortSelector={campaignsSortSelector}
        />
        <CampaignsContent searchId={searchId} loadMoreCampaignsHandler={loadMoreCampaignsHandler} />
      </SocialAuthChecker>
    </Page>
  )
}

export default CampaignsPage
