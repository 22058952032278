import { createStyles } from '../../../../../../../../../styles/helpers'
import { phonesUp } from '../../../../../../../../../styles/const/breakpoints'

export default createStyles({
  actionText: {
    fontSize: 12
  },
  periodSelector: {
    '& .rdrDay': {
      height: '46px'
    },
    '& .rdrDayToday .rdrDayNumber span:after': {
      // move up the today's date marker
      bottom: 0
    },
    '& .rdrEndEdge, .rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek':
      {
        display: 'none'
      },
    [`@media screen and (${phonesUp})`]: {
      '& .rdrMonth': {
        width: 475 // this helps to make week cell wider and fit 9 characters i.e A$900,000
      }
    }
  }
})
