import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import MediaCategoryEdit from '../../../../forms/Multiplatform/MediaCategoryForms/MediaCategoryEdit'
import ContentRow from '../../../../features/components/ContentSection/ContentRow'
import MediaCategoryCreate from '../../../../forms/Multiplatform/MediaCategoryForms/MediaCategoryCreate'
import MediaPackageCategoriesTable from './MediaPackageCategoriesTable'
import CreateCategoryBtn from '../CreateCategoryBtn'

import { reorderElement } from '../../../../helpers/arrays'

import { updateMediaPackageCategoriesOrder } from '../../../../modules/actions/mediaPackages'
import { mediaPackageCategoriesSelector } from '../../../../modules/selectors/mediaPackages'

const MediaPackageCategories = () => {
  const dispatch = useDispatch()

  const mediaCategories = useSelector(mediaPackageCategoriesSelector)

  const orderedMediaCategories = useMemo(() => mediaCategories.sort((a, b) => a.order - b.order), [mediaCategories])

  const handleDragEnd = useCallback(
    ({ destination, source }) => {
      if (destination && destination.index !== source.index) {
        const reorderedCategories = reorderElement(orderedMediaCategories, source.index, destination.index)

        const reorderedCategoriesPayload = reorderedCategories.map((item, index) => ({
          ...item,
          order: index
        }))

        dispatch(updateMediaPackageCategoriesOrder(reorderedCategoriesPayload))
      }
    },
    [dispatch, orderedMediaCategories]
  )

  return (
    <ContentRow
      //todo SHOP-1476 Think how create category button should be displayed on mobile
      leftColumnChildren={<CreateCategoryBtn />}
      title="Media Package Categories"
      description="The category will be displayed on the homepage and categorise different types of packages - for example by ‘Channel’, ‘Event’ etc."
    >
      <MediaCategoryCreate />
      <MediaPackageCategoriesTable categories={orderedMediaCategories} handleDragEnd={handleDragEnd} />

      <MediaCategoryEdit />
    </ContentRow>
  )
}

export default MediaPackageCategories
