import { FILE_HASH, VIDEO_PICTURE } from '../../../../../../forms/Facebook/AdForms/fields'

export const getFacebookVideoPicture = (mediaFile, imageUrls = []) => {
  // - When we get the ad from facebook, video_picture may be not available, so firstly try to get it using image_hash
  const { [FILE_HASH]: videoImageHash } = mediaFile

  const videoImageUrlData = imageUrls.find(imageUrl => imageUrl.hash === videoImageHash)
  const videoImageUrl = videoImageUrlData?.url

  return videoImageUrl || mediaFile[VIDEO_PICTURE]
}
