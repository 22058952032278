import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import CheckboxBlock from '../../../../../features/components/Form/CheckboxBlock'
import RadioButton from '../../../../../components/Form/RadioButton'
import ErrorMessage from '../../../../../components/Form/ErrorMessage'

import { getCurrentUserSelector } from '../../../../../modules/selectors/app'

import { MFA_TYPE, SMS_MFA, TOTP_MFA, VERIFICATION_REQUIRED_CHECK } from '../fields'

import useStyles from './styles'

const MFAContentFormFields = ({ formik, formHasChanges, onRegenerateTOTPTokenHandler }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const user = useSelector(getCurrentUserSelector)

  const { phone_number: phoneNumber, is_mfa_enabled: userCurrentMFAType } = user

  const showPhoneNumberMessage = !phoneNumber

  const { values, touched, errors, setFieldValue, setFieldTouched } = formik

  const showUserMFARequiredError =
    formHasChanges && touched[VERIFICATION_REQUIRED_CHECK] && errors[VERIFICATION_REQUIRED_CHECK]

  const handleVerificationRequiredCheck = () => {
    setFieldValue(VERIFICATION_REQUIRED_CHECK, !values[VERIFICATION_REQUIRED_CHECK], true)

    if (!touched[VERIFICATION_REQUIRED_CHECK]) {
      setFieldTouched(VERIFICATION_REQUIRED_CHECK, true, false)
    }
  }

  if (showPhoneNumberMessage) {
    return <p>{t('Please add a valid phone number to enable MFA.')}</p>
  }

  return (
    <>
      <CheckboxBlock
        checked={values[VERIFICATION_REQUIRED_CHECK]}
        title={t('Account verification required')}
        onCheck={handleVerificationRequiredCheck}
        id="verification-required-checkbox"
      >
        <div className={classes.radioButtonsContainer}>
          <RadioButton
            id="mfa_authenticator_app"
            name={MFA_TYPE}
            value={TOTP_MFA}
            setFieldValue={setFieldValue}
            selectedValue={values[MFA_TYPE]}
            label={<p>{t('Authenticator app')}</p>}
          />
          <RadioButton
            id="mfa_sms"
            name={MFA_TYPE}
            value={SMS_MFA}
            setFieldValue={setFieldValue}
            selectedValue={values[MFA_TYPE]}
            label={<p>{t('SMS verification')}</p>}
          />
        </div>
      </CheckboxBlock>
      {showUserMFARequiredError && <ErrorMessage error={errors[VERIFICATION_REQUIRED_CHECK]} />}
      {userCurrentMFAType === TOTP_MFA && (
        <button onClick={onRegenerateTOTPTokenHandler} type="button" className={classes.setupAgainButton}>
          {t('Regenerate authenticator app token')}
        </button>
      )}
    </>
  )
}

MFAContentFormFields.propTypes = {
  formik: PropTypes.object.isRequired,
  formHasChanges: PropTypes.bool.isRequired,
  onRegenerateTOTPTokenHandler: PropTypes.func.isRequired
}

export default MFAContentFormFields
