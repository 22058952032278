import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { campaignAdScheduleCriterionsSelector } from '../../../../../../modules/selectors/campaigns'

const ScheduleSectionPreview = () => {
  const { t } = useTranslation()

  const schedule = useSelector(campaignAdScheduleCriterionsSelector)

  const scheduleExist = Object.keys(schedule).length

  if (scheduleExist) {
    return <p>{t('Expand to view schedule')}</p>
  } else {
    return <p>{t('Ads set to run on all days at all times')}</p>
  }
}

export default ScheduleSectionPreview
