import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { insertIf } from '../../../../../helpers/common'

import {
  deleteAgencyInvite,
  resendAgencyInvite,
  updateAgencyMember,
  updateAgencyMemberStatus
} from '../../../../../modules/actions/agencyTeam'
import { openForm } from '../../../../../modules/actions/forms'
import { isUserControllerSelector } from '../../../../../modules/selectors/app'

import { AGENCY_PERMISSIONS_EDIT } from '../../../../../constants/forms'

export function useAgencyMemberActions({ agencyMembersAndInvites, setMemberId, openModalHandler }) {
  const dispatch = useDispatch()
  const isUserController = useSelector(isUserControllerSelector)

  const changeMemberStatusHandler = useCallback(
    ({ id, isActive }) => {
      if (isActive) {
        setMemberId(id)
        openModalHandler()
      } else {
        dispatch(updateAgencyMemberStatus(id, 'inactive'))
      }
    },
    [dispatch, setMemberId, openModalHandler]
  )

  const changeMemberRoleHandler = useCallback(
    ({ id, newRole }) => {
      dispatch(updateAgencyMember(id, { role: newRole }))
    },
    [dispatch]
  )

  const resendInviteHandler = useCallback(
    id => {
      dispatch(resendAgencyInvite(id))
    },
    [dispatch]
  )

  const deleteInviteHandler = useCallback(
    id => {
      dispatch(deleteAgencyInvite(id))
    },
    [dispatch]
  )

  const openEditAgencyPermissionsFormHandler = useCallback(
    (e, id) => {
      dispatch(openForm({ id, formName: AGENCY_PERMISSIONS_EDIT }))
    },
    [dispatch]
  )

  const agencyMemberActions = useMemo(
    () => [
      ...insertIf(isUserController, {
        text: 'Edit permissions',
        onClickHandler: openEditAgencyPermissionsFormHandler
      })
    ],
    [isUserController, openEditAgencyPermissionsFormHandler]
  )

  const getAdditionalAgencyMemberActions = useCallback(
    id => {
      // check if current table item is agency member, and not just invite
      const isMember = agencyMembersAndInvites.find(item => item.id === id)?.is_active !== undefined
      return [...(isMember ? agencyMemberActions : [])]
    },
    [agencyMembersAndInvites, agencyMemberActions]
  )

  return useMemo(
    () => ({
      changeMemberStatusHandler,
      changeMemberRoleHandler,
      resendInviteHandler,
      deleteInviteHandler,
      getAdditionalAgencyMemberActions
    }),
    [
      changeMemberStatusHandler,
      changeMemberRoleHandler,
      resendInviteHandler,
      deleteInviteHandler,
      getAdditionalAgencyMemberActions
    ]
  )
}
