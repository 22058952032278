import { createStyles } from '../../../../styles/helpers'
import { tabletDown } from '../../../../styles/const/breakpoints'

const useStyles = createStyles(theme => ({
  container: {
    marginTop: 48,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    columnGap: 44
  },
  uploadFile: {
    paddingLeft: 16,
    marginBottom: 20
  },
  // these empty classes are needed, because we use it in tabletDown media screen
  menuWrapper: {},
  downloadPdfButton: {},
  [`@media screen and (${tabletDown})`]: {
    menuWrapper: {
      width: '100%'
    },
    downloadPdfButton: {
      height: 40
    },
    uploadFile: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: 40,
      border: `1px solid ${theme.brandPrimary}`,
      borderRadius: 3,
      margin: '16px 0'
    }
  }
}))

export default useStyles
