import * as Yup from 'yup'

import { getAllowSelfAccounts, getShowBrandCategorySelect, getShowMediaSubCategories } from '../../helpers'

import { EMAIL, MEDIA_SUB_CATEGORIES, ROLE } from './fields'
import { ADD_TO_ALL_CONTROLLER_ACCOUNTS, BRAND_CATEGORY, SELF_ACCOUNTS } from '../../fields'

export const validationSchema = Yup.object({
  [EMAIL]: Yup.string().required('This field should not be empty').email('Please enter a valid email address'),
  [ROLE]: Yup.string().required('This field should not be empty'),
  [SELF_ACCOUNTS]: Yup.array().when([ROLE, ADD_TO_ALL_CONTROLLER_ACCOUNTS], {
    is: (role, addToAllControllerAccounts) => getAllowSelfAccounts(role) && !addToAllControllerAccounts,
    then: () =>
      Yup.array().test({
        message: 'You must add this user to at least one client account',
        test: accounts => !!accounts[0]?.id
      }),
    otherwise: () => Yup.array().notRequired()
  }),
  // brand category is required if role is buyer
  [BRAND_CATEGORY]: Yup.string().when(ROLE, {
    is: role => getShowBrandCategorySelect(role),
    then: () => Yup.string().required('Brand category is required')
  }),
  [MEDIA_SUB_CATEGORIES]: Yup.array().when(ROLE, {
    is: role => getShowMediaSubCategories(role),
    then: () =>
      Yup.array().test({
        message: 'Please select at least one media sub category',
        test: mediaSubCategories => !!mediaSubCategories[0]?.id
      }),
    otherwise: () => Yup.array().notRequired()
  })
})
