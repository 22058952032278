import store from '../../../store'
import { updateFileUploadProgress } from '../../../modules/actions/files'

export function updateItem(items, updatedItem, itemIdToUpdate) {
  return items.map(item => {
    if (String(item.id) === String(itemIdToUpdate || updatedItem.id)) {
      return {
        ...item,
        ...updatedItem
      }
    }
    return item
  })
}

export function deleteItemById(items, itemId) {
  return items.filter(item => String(item.id) !== String(itemId))
}

export function deleteItemsByPropertyName(items, itemsToDelete, propertyName) {
  return items.filter(item => !itemsToDelete.includes(item[propertyName]))
}

export function simulateFileUploadProgress(fileName, intervalUpdate = 900) {
  const { files } = store.getState()

  const { [fileName]: currentFileUploadProgress } = files.fileUploadProgress
  let fileUploadProgress = currentFileUploadProgress

  return setInterval(() => {
    // as it's not possible to predict or calculate current porcessing progress
    // simulate progress with updating it every intervalUpdate until it reach 99
    if (fileUploadProgress < 99) {
      fileUploadProgress += 1

      store.dispatch(updateFileUploadProgress({ [fileName]: fileUploadProgress }))
    }
  }, intervalUpdate)
}

export function insertInSortedArray(array, item, compareFn) {
  // The insertInSortedArray function uses a binary search to find the correct index
  // to insert the new agency so that the list remains sorted by name.
  // This is more efficient than re-sorting the entire list every time a new item is added.
  let low = 0
  let high = array.length

  while (low < high) {
    const mid = Math.floor((low + high) / 2)
    if (compareFn(item, array[mid]) >= 0) {
      low = mid + 1
    } else {
      high = mid
    }
  }

  array.splice(low, 0, item) // Inserts the item at the correct index
  return array
}
