import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { formatCurrency } from '../../../../../../../helpers/numbers'
import { COST_CAP, MANUAL, MINIMUM_ROAS } from '../../../../../../../constants/campaigns'

import { lineItemSelector } from '../../../../../../../modules/selectors/lineItems'
import { selectedAdAccountDataSelector } from '../../../../../../../modules/selectors/app'

const TargetCostCapPreview = ({ campaignBiddingType }) => {
  const { t } = useTranslation()

  const { currency_symbol } = useSelector(selectedAdAccountDataSelector)
  const lineItem = useSelector(lineItemSelector)

  const { target_cost_cap: targetCostCap } = lineItem

  const formattedTargetCostCap = formatCurrency(targetCostCap, { min: 2, max: 2 }, { symbol: currency_symbol })

  if (campaignBiddingType === MANUAL) {
    return (
      <span>
        {t('Conversion cost cap')} {formattedTargetCostCap}
      </span>
    )
  }

  if (campaignBiddingType === COST_CAP) {
    return (
      <span>
        {t('Conversion average cost goal')} {formattedTargetCostCap}
      </span>
    )
  }

  if (campaignBiddingType === MINIMUM_ROAS) {
    return (
      <span>
        {t('Minimum ROAS')} {targetCostCap}
      </span>
    )
  }

  return null
}

export default TargetCostCapPreview
