import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setMediaOrderUploadCreative, updateMediaOrder } from '../../../../modules/actions/mediaOrders'
import { createdCampaignDataSelector } from '../../../../modules/selectors/campaigns'
import { mediaOrderSelector } from '../../../../modules/selectors/mediaOrders'

import { PLATFORMS_TO_PROVIDER } from '../../../../constants/selectLists/platformList'
import { CAMPAIGN_OPTION_EXISTING_CAMPAIGN } from '../../../Google/UploadGoogleAdCreative/UploadGoogleAdCreativeForm/CampaignForm/CampaignFormContent/fields'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'

export default function useCampaignFormSuccessSubmit({ campaignOption, platform, adAccountId }) {
  const [successSubmit, setSuccessSubmit] = useState(false)

  const dispatch = useDispatch()

  const mediaOrder = useSelector(mediaOrderSelector)
  const controllerId = useSelector(selectedControllerIdSelector)
  const campaignCreateData = useSelector(createdCampaignDataSelector)

  const { id: mediaOrderId, campaigns: mediaOrderCampaigns } = mediaOrder

  const updateMediaOrderHandler = useCallback(
    createdCampaignId => {
      dispatch(
        updateMediaOrder(mediaOrderId, {
          campaigns: [
            {
              ad_account_id: adAccountId,
              external_id: createdCampaignId,
              provider: PLATFORMS_TO_PROVIDER[platform]
            },
            ...(mediaOrderCampaigns ? mediaOrderCampaigns : [])
          ],
          controller: controllerId
        })
      )
    },
    [dispatch, mediaOrderId, adAccountId, platform, mediaOrderCampaigns, controllerId]
  )

  // handle success submit of other campaign create cases - update media order, combined campaigns list
  useEffect(() => {
    if (campaignOption !== CAMPAIGN_OPTION_EXISTING_CAMPAIGN && campaignCreateData.id && !successSubmit) {
      // manually update media order with created campaign (we don't track status of this request)
      updateMediaOrderHandler(campaignCreateData.id)

      dispatch(setMediaOrderUploadCreative({ campaign: campaignCreateData }))

      setSuccessSubmit(true)
    }
  }, [campaignCreateData, campaignOption, dispatch, successSubmit, updateMediaOrderHandler])

  return useMemo(() => successSubmit, [successSubmit])
}
