import { useMemo } from 'react'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../features/components/Can'

const usePermissions = () => {
  const permissions = useAbility(AbilityContext)

  return useMemo(() => permissions, [permissions])
}

export default usePermissions
