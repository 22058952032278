import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import NameSectionForm from '../../../../../ReusableFormSections/NameSection/NameSectionForm'

import { filterObjectEmptyValues } from '../../../../../../helpers/common'

import { updateCampaign, clearUpdateCampaign } from '../../../../../../modules/actions/campaigns'
import {
  campaignUpdateIsLoadingSelector,
  campaignUpdateErrorSelector,
  campaignWasUpdatedSelector
} from '../../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { TIKTOK_PLATFORM } from '../../../../../../constants/selectLists/platformList'

function NameSection({ editItemData: campaign, ...formProps }) {
  const dispatch = useDispatch()

  const campaignWasUpdated = useSelector(campaignWasUpdatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const handleSubmit = useCallback(
    values => {
      // as we use PUT for update tiktok campaign, we should pass full data in payload
      dispatch(
        updateCampaign(
          {
            ...filterObjectEmptyValues(campaign),
            account: selectedAdAccountId,
            ...values
          },
          campaign.id,
          {
            platform: TIKTOK_PLATFORM,
            requestMethod: 'PUT'
          }
        )
      )
    },
    [dispatch, campaign, selectedAdAccountId]
  )

  const handleClearUpdateCampaign = useCallback(() => {
    dispatch(clearUpdateCampaign())
  }, [dispatch])

  return (
    <NameSectionForm
      placeholder="Campaign Name"
      editItem={campaign}
      handleSubmit={handleSubmit}
      clearEditItem={handleClearUpdateCampaign}
      successSubmit={campaignWasUpdated}
      isLoadingSelector={campaignUpdateIsLoadingSelector}
      errorSelector={campaignUpdateErrorSelector}
      {...formProps}
    />
  )
}

export default NameSection
