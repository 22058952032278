// This file contains payload and initial state formatters for use cases of multi select
// usual multi level select data item should have such fields as "id" - own id, "parent" - parent id

// Converts the ids of optionsFlatData to match the type of ids in the values array.
export const convertFlatDataIdsToMatchValuesType = (optionsFlatData, values, parentIdFieldName = 'parent') => {
  // Get the type of the first value in the values array
  const valueType = typeof values[0]

  // Map through the optionsFlatData and convert the ids to the same type as values
  return optionsFlatData.map(option => ({
    ...option,
    id: valueType === 'number' ? Number(option.id) : String(option.id),
    [parentIdFieldName]: option[parentIdFieldName]
      ? valueType === 'number'
        ? Number(option[parentIdFieldName])
        : String(option[parentIdFieldName])
      : null
  }))
}

// When we format values to payload, we don't have to include children ids, if parent was selected
// use for create or edit form submit
export const getMultiLevelSelectPayloadValues = (values = [], options = [], parentIdFieldName = 'parent') => {
  const result = []

  const formattedOptions = convertFlatDataIdsToMatchValuesType(options, values, parentIdFieldName)

  for (const option of formattedOptions) {
    const { id: optionId } = option
    if (values.includes(optionId) && !result.includes(optionId)) {
      const optionParentId = option[parentIdFieldName]

      if (!optionParentId || !values.includes(optionParentId)) {
        result.push(optionId)
      }
    }
  }

  return result
}

// When we create initial state, if item is selected and has children, include all children as selected items
// (this behaviour helps to properly show selected items for SelectMultiLevel UI)
// use for edit form initial values preparation
export const gatherMultiLevelSelectInitialValues = (values = [], options = [], parentIdFieldName = 'parent') => {
  const selectedOptionIds = new Set()

  const formattedOptions = convertFlatDataIdsToMatchValuesType(options, values, parentIdFieldName)

  const findChildren = parentId => {
    const children = formattedOptions.filter(option => option[parentIdFieldName] === parentId)
    children.forEach(child => {
      selectedOptionIds.add(child.id)
      findChildren(child.id)
    })
  }

  values.forEach(value => {
    const optionId = formattedOptions.find(option => option.id === value)?.id
    optionId && selectedOptionIds.add(optionId)
    findChildren(value)
  })

  return [...selectedOptionIds]
}
