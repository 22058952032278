import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import Can from '../../../../../../features/components/Can'
import AdCardListItem from '../../../../../../features/components/AdCardListItem'
import ActionsDropdown from '../../../../../../features/components/ActionsDropdown'
import AdCardDetails from '../../../../../../features/components/AdCardListItem/AdCardDetails'
import AdTikTokPreview from '../../../../../../forms/Tiktok/AdForms/AdTikTokEdit/AdTikTokPreview'
import InternalAdActionButtons from '../../../../InternalAdActionButtons'

import usePermissions from '../../../../../../hooks/usePermissions'

import { insertIf } from '../../../../../../helpers/common'

import { openForm } from '../../../../../../modules/actions/forms'
import { approveInternalAd } from '../../../../../../modules/actions/ads'
import {
  approveInternalAdErrorSelector,
  approveInternalAdIsLoadingSelector,
  approveInternalAdSelector,
  approveInternalAdWasApprovedSelector
} from '../../../../../../modules/selectors/ads'
import { currentUserSelfAccountsListSelector } from '../../../../../../modules/selectors/app'

import { AD_TIK_TOK_EDIT } from '../../../../../../constants/forms'
import { INTERNAL_ADS_PERMISSION } from '../../../../../../constants/permissions'

import useStyles from './styles'

// this component currently support only ad approvals page style
const TikTokAdPreviewCard = ({ ad, disapproveInternalAdHandler }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { id, name } = ad

  const selfAccounts = useSelector(currentUserSelfAccountsListSelector)
  const adSelfAccountName = selfAccounts.find(({ id }) => Number(id) === Number(ad.account_id))?.name || ''

  const permissions = usePermissions()
  const hasUpdateInternalAdsPermission = permissions.can('update', INTERNAL_ADS_PERMISSION)
  const hasManageInternalAdsPermission = permissions.can('manage', INTERNAL_ADS_PERMISSION)

  const approveInternalAdHandler = useCallback(() => {
    dispatch(approveInternalAd(id))
  }, [dispatch, id])

  const editInternalAdHandler = useCallback(() => {
    dispatch(openForm({ id, formName: AD_TIK_TOK_EDIT, otherParams: 'internal=true' }))
  }, [dispatch, id])

  const actionsDropdownOptions = useMemo(() => {
    return [
      ...insertIf(hasManageInternalAdsPermission, {
        text: 'Approve',
        onClickHandler: approveInternalAdHandler
      }),
      ...insertIf(hasManageInternalAdsPermission, {
        text: 'Disapprove',
        onClickHandler: disapproveInternalAdHandler
      }),
      ...insertIf(hasUpdateInternalAdsPermission, {
        text: 'Edit',
        onClickHandler: editInternalAdHandler
      })
    ]
  }, [
    editInternalAdHandler,
    hasManageInternalAdsPermission,
    hasUpdateInternalAdsPermission,
    approveInternalAdHandler,
    disapproveInternalAdHandler
  ])

  return (
    <AdCardListItem>
      <div className={classes.cardHeader}>
        {!!actionsDropdownOptions.length && (
          <ActionsDropdown
            itemId={ad.id}
            options={actionsDropdownOptions}
            actionsListWrapperClassName={classes.actionsListWrapper}
            className={classes.actionsDropdownMenu}
            closeOnInsideClick
          />
        )}
      </div>
      <div className={classes.adPreviewContainer}>
        <AdTikTokPreview adData={ad} className={classes.adPreview} />
      </div>
      <div className={classes.previewContainer} />
      <AdCardDetails title={name} subTitle={adSelfAccountName} />
      <Can I="manage" a={INTERNAL_ADS_PERMISSION}>
        <InternalAdActionButtons
          adId={id}
          approveInternalAdSelector={approveInternalAdSelector}
          approveInternalAdIsLoadingSelector={approveInternalAdIsLoadingSelector}
          approveInternalAdErrorSelector={approveInternalAdErrorSelector}
          approveInternalAdWasApprovedSelector={approveInternalAdWasApprovedSelector}
          approveInternalAdHandler={approveInternalAdHandler}
          disapproveInternalAdHandler={disapproveInternalAdHandler}
        />
      </Can>
    </AdCardListItem>
  )
}

TikTokAdPreviewCard.propTypes = {
  ad: PropTypes.object.isRequired,
  disapproveInternalAdHandler: PropTypes.func
}

export default TikTokAdPreviewCard
