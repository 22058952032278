import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from '../../../../components/Button'

import { selectedMediaPackagesSelector } from '../../../../modules/selectors/mediaOrders'

import useStyles from './styles'

const MediaPackageBookNowButton = ({ mediaPackage, className }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const selectedMediaPackages = useSelector(selectedMediaPackagesSelector)

  const isMediaPackageSelected = useMemo(() => {
    return selectedMediaPackages.some(({ id }) => id === mediaPackage.id)
  }, [selectedMediaPackages, mediaPackage])

  const isInStock = mediaPackage?.inventory
  const isDisabled = !isInStock || isMediaPackageSelected

  const buttonText = useMemo(() => {
    if (!isInStock) {
      // mark all packages with no inventory as sold out
      return t('Sold Out')
    } else if (isMediaPackageSelected) {
      return t('Added')
    } else {
      return t('Book')
    }
  }, [t, isInStock, isMediaPackageSelected])

  return (
    <Button solid className={classnames(classes.button, className)} disabled={isDisabled}>
      {buttonText}
    </Button>
  )
}

MediaPackageBookNowButton.propTypes = {
  mediaPackage: PropTypes.object.isRequired,
  className: PropTypes.string
}

export default MediaPackageBookNowButton
