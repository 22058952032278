import { useSelector } from 'react-redux'

import { selectedSelfAccountDataSelector } from '../../../../../../modules/selectors/app'

import { ALLOW_CUSTOM_CLICK_THROUGH_LINK } from '../../../../../Multiplatform/UploadAdCreative/fields'

export function useShowProductOrPageSelectionStep() {
  const { [ALLOW_CUSTOM_CLICK_THROUGH_LINK]: customClickThroughLink } = useSelector(selectedSelfAccountDataSelector)
  const showProductSelectionStep = !customClickThroughLink

  return showProductSelectionStep
}
