import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import WarningMessage from '../../../../../../../features/components/Form/WarningMessage'
import Form from '../../../../../../../components/Form'
import StartEndDateRangeFields from '../../../../../../ReusableFormFields/StartEndDateRangeFields'
import SkeletonForm from '../../../../../../../components/Form/Skeleton'
import FieldsSection from '../../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'
import DrawerHeadline from '../../../../../../../components/Drawer/DrawerHeadline'

import { usePurifiedFormik } from '../../../../../../../hooks/formHooks/usePurifiedFormik'

import { clearChoicesCountries, getChoicesCountries } from '../../../../../../../modules/actions/choices'
import { clearCreateLineItem, clearLineItems, createLineItem } from '../../../../../../../modules/actions/lineItems'
import { setMediaOrderUploadCreative } from '../../../../../../../modules/actions/mediaOrders'
import { mediaOrderUploadCreativeDataSelector } from '../../../../../../../modules/selectors/mediaOrders'
import {
  choicesCountriesErrorSelector,
  choicesCountriesWasLoadedSelector,
  currentUserCountrySelector
} from '../../../../../../../modules/selectors/choices'
import {
  lineItemCreatedDataSelector,
  lineItemCreateErrorSelector,
  lineItemCreateIsLoadingSelector,
  lineItemWasCreatedSelector
} from '../../../../../../../modules/selectors/lineItems'

import { initialValues, validationSchema, transformValuesToBE } from './fields'
import { END_DATE, START_DATE } from '../../../../../../ReusableFormFields/StartEndDateRangeFields/fields'
import { FACEBOOK_PLATFORM } from '../../../../../../../constants/selectLists/platformList'
import { LINE_ITEM_PRODUCT_RETARGETING_FORM } from '../index'

import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

const LineItemForm = ({ adAccountId, onSuccessSubmit }) => {
  const drawerFormClasses = useDrawerFormStyles()

  const dispatch = useDispatch()

  const [successSubmit, setSuccessSubmit] = useState(false)

  const currentUserCountry = useSelector(currentUserCountrySelector)
  const choicesCountriesWasLoaded = useSelector(choicesCountriesWasLoadedSelector)
  const choicesCountriesError = useSelector(choicesCountriesErrorSelector)
  const lineItemWasCreated = useSelector(lineItemWasCreatedSelector)
  const lineItemCreatedData = useSelector(lineItemCreatedDataSelector)
  const { campaign, audience, pixelId, catalogueProduct } = useSelector(mediaOrderUploadCreativeDataSelector)

  const onSubmit = values => {
    const lineItemData = transformValuesToBE({
      values,
      adAccountId,
      campaign,
      product: catalogueProduct,
      audience,
      pixelId,
      country: currentUserCountry?.['iso2']
    })

    dispatch(createLineItem(lineItemData, FACEBOOK_PLATFORM))
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const clearCreateLineItemHandler = useCallback(() => {
    dispatch(clearCreateLineItem())
    dispatch(clearLineItems())
  }, [dispatch])

  useEffect(() => {
    if (lineItemWasCreated) {
      dispatch(setMediaOrderUploadCreative({ lineItem: lineItemCreatedData }))

      setSuccessSubmit(true)
    }
  }, [dispatch, lineItemWasCreated, lineItemCreatedData])

  useEffect(() => {
    if (!choicesCountriesWasLoaded) {
      dispatch(getChoicesCountries())
    }
  }, [dispatch, choicesCountriesWasLoaded])

  // if choices countries were loaded with error, clear them, so user can re-start the flow and re-load them
  useEffect(
    () => () => {
      if (choicesCountriesError) {
        dispatch(clearChoicesCountries())
      }
    },
    [dispatch, choicesCountriesError]
  )

  if (!choicesCountriesWasLoaded && !choicesCountriesError) {
    return <SkeletonForm stepsLength={4} />
  }

  if (choicesCountriesError) {
    return (
      <WarningMessage
        title="Something went wrong"
        subTitle="Launch an off-network campaign"
        description="Sorry, something went wrong with identifying user country. Please try again later or contact your account manager."
      />
    )
  }

  return (
    <div className={drawerFormClasses.formContainer}>
      <DrawerHeadline
        activeStepNumber={2}
        customStepsLength={4}
        description="Please enter the start and end dates for the campaign"
        title="Set the campaign dates"
      />
      <Form
        formName={LINE_ITEM_PRODUCT_RETARGETING_FORM}
        formik={purifiedFormik}
        submitText="Save and continue"
        successSubmit={successSubmit}
        onSuccessSubmit={onSuccessSubmit}
        errorSelector={lineItemCreateErrorSelector}
        isLoadingSelector={lineItemCreateIsLoadingSelector}
        clearHandler={clearCreateLineItemHandler}
      >
        <FieldsSection title="Campaign period">
          <FieldRow title="Campaigns dates" description="Start and end date">
            <StartEndDateRangeFields
              formik={purifiedFormik}
              startDateName={START_DATE}
              endDateName={END_DATE}
              minDate={new Date()}
            />
          </FieldRow>
        </FieldsSection>
      </Form>
    </div>
  )
}

LineItemForm.propTypes = {
  adAccountId: PropTypes.string.isRequired,
  onSuccessSubmit: PropTypes.func.isRequired
}

export default LineItemForm
