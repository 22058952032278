import { useMemo } from 'react'

export function usePurifiedFormik(formik) {
  // todo check if some other fields could be purified from formik object
  // this hook allows to purify formik from not used field of Formik object
  // one of the example is - isValidating field which is not used in app but triggering rerendering for all
  // formik dependent components twice after each validation process
  const {
    dirty,
    errors,
    getFieldHelpers,
    getFieldMeta,
    getFieldProps,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    initialErrors,
    initialStatus,
    initialTouched,
    initialValues,
    isSubmitting,
    isValid,
    // isValidating,
    registerField,
    resetForm,
    setErrors,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    setFormikState,
    setStatus,
    setSubmitting,
    setTouched,
    setValues,
    status,
    submitCount,
    submitForm,
    touched,
    unregisterField,
    validateField,
    validateForm,
    validateOnBlur,
    validateOnChange,
    validateOnMount,
    values
  } = formik

  // purified formik object, it also is memoized to avoid object being updated when fields were not changed
  return useMemo(() => {
    return {
      dirty,
      errors,
      getFieldHelpers,
      getFieldMeta,
      getFieldProps,
      handleBlur,
      handleChange,
      handleReset,
      handleSubmit,
      initialErrors,
      initialStatus,
      initialTouched,
      initialValues,
      isSubmitting,
      isValid,
      registerField,
      resetForm,
      setErrors,
      setFieldError,
      setFieldTouched,
      setFieldValue,
      setFormikState,
      setStatus,
      setSubmitting,
      setTouched,
      setValues,
      status,
      submitCount,
      submitForm,
      touched,
      unregisterField,
      validateField,
      validateForm,
      validateOnBlur,
      validateOnChange,
      validateOnMount,
      values
    }
  }, [
    dirty,
    errors,
    getFieldHelpers,
    getFieldMeta,
    getFieldProps,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    initialErrors,
    initialStatus,
    initialTouched,
    initialValues,
    isSubmitting,
    isValid,
    registerField,
    resetForm,
    setErrors,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    setFormikState,
    setStatus,
    setSubmitting,
    setTouched,
    setValues,
    status,
    submitCount,
    submitForm,
    touched,
    unregisterField,
    validateField,
    validateForm,
    validateOnBlur,
    validateOnChange,
    validateOnMount,
    values
  ])
}
