import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import FieldLabelWrapper from '../../../../../features/components/Form/FieldLabelWrapper'
import Field from '../../../../../components/Form/Field'

import { userProfileAgenciesSelector } from '../../../../../modules/selectors/app'

import { NAME } from '../../SelfAccountCreate/SelfAccountCreateForm/fields'

import useDrawerFormsStyles from '../../../../../styles/common/drawerForms'
import useAuthStyles from '../../../../../styles/common/authPages'

const AgencyAdminSelfAccountCreateContent = ({ formik }) => {
  const drawerFormClasses = useDrawerFormsStyles()
  const authClasses = useAuthStyles()

  const userProfileAgencies = useSelector(userProfileAgenciesSelector)
  const firstAgency = userProfileAgencies[0]
  const agencyName = firstAgency.name

  const { t } = useTranslation()

  return (
    <div className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>💼 {t('Agency Set Up: First Client Account')}</h3>
      <p>{t('agencyAdminFirstTimeLoginMessage', { agencyName })}</p>
      <p>{t('You can add more client accounts later.')}</p>
      <div className={authClasses.fieldsContainer}>
        <FieldLabelWrapper label={'Client Account Name'} labelFor={NAME}>
          <Field formik={formik} id={NAME} name={NAME} placeholder="Client Account Name" />
        </FieldLabelWrapper>
      </div>
    </div>
  )
}

export default AgencyAdminSelfAccountCreateContent
