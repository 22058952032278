import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { isFuture } from 'date-fns'

import EditForm from '../../../../../../features/components/Forms/EditForm'
import StartEndTimeFields from '../../../../../ReusableFormFields/StartEndTimeFields'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'

import { formatDatesToBE } from '../../formatters'
import { clearTimeZone, combineDateAndTime, extractTimeStringFromDate } from '../../../../../../helpers/date'

import { clearUpdateLineItem, updateLineItem } from '../../../../../../modules/actions/lineItems'
import {
  lineItemUpdateErrorSelector,
  lineItemUpdateIsLoadingSelector,
  lineItemWasUpdatedSelector
} from '../../../../../../modules/selectors/lineItems'
import { campaignSelector } from '../../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { START_DATE, START_TIME } from '../../../../../ReusableFormFields/StartTimeFields/fields'
import { STOP_DATE, STOP_TIME } from '../../../../../ReusableFormFields/StopTimeFields/fields'

const DatesSectionForm = ({ editItemData: lineItem, ...formProps }) => {
  const dispatch = useDispatch()

  const lineItemWasUpdated = useSelector(lineItemWasUpdatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const { objective: campaignObjective } = useSelector(campaignSelector)

  const { time_start: timeStart, time_stop: timeStop } = lineItem

  const handleSubmit = useCallback(
    values => {
      const updateLineItemData = {
        account: selectedAdAccountId,
        campaign_objective: campaignObjective,
        ...formatDatesToBE(values[START_DATE], values[START_TIME], values[STOP_DATE], values[STOP_TIME])
      }

      dispatch(updateLineItem(updateLineItemData, lineItem.id))
    },
    [dispatch, lineItem.id, campaignObjective, selectedAdAccountId]
  )

  const initialValues = useMemo(
    () => ({
      [START_DATE]: clearTimeZone(timeStart) || '',
      [START_TIME]: (timeStart && extractTimeStringFromDate(clearTimeZone(timeStart))) || '',
      [STOP_DATE]: clearTimeZone(timeStop) || '',
      [STOP_TIME]: (timeStop && extractTimeStringFromDate(clearTimeZone(timeStop))) || ''
    }),
    [timeStart, timeStop]
  )

  const validationSchema = useMemo(() => {
    return Yup.object({
      [STOP_DATE]: Yup.string()
        .required('Stop time is required')
        .test(
          STOP_DATE,
          'End date and time must be in future',
          // check if the data and time are in future
          (value, { parent }) => isFuture(combineDateAndTime(value, parent[STOP_TIME]))
        )
    })
  }, [])

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const handleClearUpdateLineItem = useCallback(() => {
    dispatch(clearUpdateLineItem())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={lineItemWasUpdated}
      clearEditItem={handleClearUpdateLineItem}
      errorSelector={lineItemUpdateErrorSelector}
      isLoadingSelector={lineItemUpdateIsLoadingSelector}
      {...formProps}
    >
      <StartEndTimeFields formik={purifiedFormik} />
    </EditForm>
  )
}

export default DatesSectionForm
