import { v4 as uuidv4 } from 'uuid'

import { ASSET_ID, FILE_NAME, FILE_URL, MEDIA_TYPE, STATUS } from '../../../../Google/AdForms/fields'

import { FILE_TYPE } from '../../../../../constants/product'
import { initialLink } from '../../../../Facebook/AdForms/fields'

export const CLICK_THROUGH_LINK = 'url'
export const MEDIA_WIDTH_PIXELS = 'width_pixels'
export const MEDIA_HEIGHT_PIXELS = 'height_pixels'

export const MEDIA_ITEMS = 'creatives'

export const initialDv360File = {
  [FILE_NAME]: '',
  [FILE_URL]: '',
  [FILE_TYPE]: '',
  [MEDIA_TYPE]: '',
  [ASSET_ID]: null,
  [MEDIA_WIDTH_PIXELS]: null,
  [MEDIA_HEIGHT_PIXELS]: null
}

export const initialDv360Media = {
  ...initialDv360File,
  // this is a temporary id for the media file uploading to avoid preview disappearing on other media files removal
  id: uuidv4()
}

export const getInitialValues = ({ showProductSelection }) => {
  return {
    [MEDIA_ITEMS]: [initialDv360Media],
    [STATUS]: 'paused',
    [CLICK_THROUGH_LINK]: showProductSelection ? '' : initialLink
  }
}
