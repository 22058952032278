import { createStyles } from '../../../../styles/helpers'
import { tabletDown } from '../../../../styles/const/breakpoints'

const useStyles = createStyles({
  approveTitle: {
    marginTop: 40,
    textAlign: 'center'
  },
  buttons: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,

    [`@media screen and (${tabletDown})`]: {
      '& button': {
        // reset width 100% on tabletDown
        width: ['unset', '!important']
      }
    }
  }
})

export default useStyles
