import { createStyles } from '../../../../styles/helpers'

import { darkGrey, mediumGrey, textGrey } from '../../../../styles/const/colors'
import { transitionShort } from '../../../../styles/const/common'

const findColor = (isActive, isDisabled) => {
  if (isDisabled) {
    return mediumGrey
  } else if (isActive) {
    return textGrey
  }

  return darkGrey
}

export default createStyles({
  tabItem: {
    flex: 1,
    backgroundColor: ({ isActive }) => (isActive ? 'white' : 'transparent'),
    color: ({ isActive, isDisabled }) => findColor(isActive, isDisabled),
    padding: 7,
    transition: transitionShort,
    border: ({ isActive }) => (isActive ? `1px solid ${mediumGrey}` : '1px solid transparent'),
    boxShadow: ({ isActive }) => (isActive ? '2px 2px 3px 0px rgba(0, 0, 0, 0.02)' : 'unset'),
    borderRadius: 4,
    cursor: 'pointer',
    textAlign: 'center',

    '&:hover': {
      borderColor: ({ isDisabled }) => (isDisabled ? 'transparent' : mediumGrey)
    }
  }
})
