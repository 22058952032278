import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles({
  cardsList: {
    display: 'grid',
    width: '100%',
    gridGap: 16,
    gridTemplateColumns: 'repeat(auto-fill, [col-start] minmax(260px, 1fr) [col-end])'
  }
})

export default useStyles
