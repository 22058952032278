import { createStyles } from '../../../../../styles/helpers'
import { textEllipsis } from '../../../../../styles/mixins/text'

const useStyles = createStyles({
  nameMobileFieldWrapper: {
    width: '100%',
    paddingRight: 5
  },
  nameField: {
    width: '100%',
    ...textEllipsis()
  }
})

export default useStyles
