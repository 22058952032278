import * as Yup from 'yup'

import { EMAIL, ROLE, SELF_ACCOUNTS } from './fields'
import { MANAGER, REPORT } from '../../../../../constants/permissions'

export const validationSchema = Yup.object({
  [EMAIL]: Yup.string().required('This field should not be empty').email('Please enter a valid email address'),
  [ROLE]: Yup.string().required('This field should not be empty'),
  [SELF_ACCOUNTS]: Yup.array().when(ROLE, {
    is: role => role === MANAGER || role === REPORT,
    then: () =>
      Yup.array().test({
        message: 'You must add this user to at least one client account',
        test: accounts => !!accounts[0]?.id
      }),
    otherwise: () => Yup.array().notRequired()
  })
})
