import { createStyles } from '../../../../styles/helpers'

import { card } from '../../../../styles/common'
import { phonesDown, smallPhonesDown } from '../../../../styles/const/breakpoints'

export default createStyles(theme => ({
  graphCard: {
    extend: card(theme),
    paddingTop: 4
  },
  graphTitle: {
    textAlign: 'left',
    fontSize: 18,
    fontWeight: 600,
    paddingTop: 8,
    paddingLeft: 15,
    margin: 0
  },
  graphWrapper: {
    padding: '8px 20px 10px 10px',
    height: 225,
    [`@media screen and (${phonesDown})`]: {
      height: 270
    },
    [`@media screen and (${smallPhonesDown})`]: {
      height: 190
    }
  }
}))
