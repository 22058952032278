import React from 'react'

import SectionWrapper from '../SectionWrapper'

import useStyles from './styles'

const AdsWrapper = ({ children, title }) => {
  const classes = useStyles()

  return (
    <SectionWrapper title={title} className={classes.adContainer}>
      {children}
    </SectionWrapper>
  )
}

export default AdsWrapper
