import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import classnames from 'classnames'

import Table from '../../../../../components/Table'
import Chip from '../../../../../components/Chip'

import { changeDateFormatFromNumberToCommon } from '../../../../../helpers/date'

import { tableColumnsSize } from '../index'
import { CHIP_COLORS } from '../../../../../constants/other'
import { phonesDownSize } from '../../../../../styles/const/breakpoints'

import useStyles from './style'

const MarginsTableData = ({ margins }) => {
  const classes = useStyles()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const marginsListColumns = useMemo(
    () => [
      {
        header: 'Name',
        Cell: ({ labels, active_label }) => (
          <p className={active_label === 'Active' ? classes.statusActive : classes.status}>{labels?.join(' ')}</p>
        ),
        style: { maxWidth: tableColumnsSize.name }
      },
      {
        header: 'Status',
        Cell: ({ active_label }) => (
          <Chip
            text={active_label}
            color={active_label === 'Active' ? CHIP_COLORS.green : CHIP_COLORS.grey}
            className={classes.statusMobileField}
          />
        ),
        style: { maxWidth: tableColumnsSize.status },
        showOnMobile: false
      },
      {
        header: 'Effective dates',
        Cell: ({ date_label: dateLabel }) => {
          const splitDateLabel = dateLabel?.split(' - ')

          if (!dateLabel) {
            return '-'
          }

          return (
            <p className={classnames({ [classes.effectiveDatesRender]: isMobile })}>
              {changeDateFormatFromNumberToCommon(splitDateLabel[0])}
              {splitDateLabel[0] && splitDateLabel[1] ? ' - ' : null}
              {changeDateFormatFromNumberToCommon(splitDateLabel[1])}
            </p>
          )
        },
        style: { maxWidth: tableColumnsSize.effectiveDates },
        showOnMobile: false,
        className: classes.effectiveDatesCol,
        headerClassName: classes.effectiveDatesHeader
      }
    ],
    [classes, isMobile]
  )

  return (
    <Table
      hideFooterRow
      data={margins}
      cols={marginsListColumns}
      mobileColumnsWrapperClassName={classes.mobileHeaderStyle}
    />
  )
}

MarginsTableData.propTypes = {
  margins: PropTypes.array.isRequired
}

export default MarginsTableData
