import { useCallback, useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

const useDeleteAction = ({
  deletedItemSelector,
  deleteItemIsLoadingSelector,
  deleteItemErrorSelector,
  wasSuccessfullyDeleted, // allow to pass custom success trigger flag
  closeOnError = true,
  onDelete,
  onClearDelete,
  onDeleteEnd
}) => {
  const [dataWaitingForDelete, setDataWaitingForDelete] = useState(null)
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false)

  const deleteItemError = useSelector(deleteItemErrorSelector)
  const { id: deletedItemId, wasDeleted } = useSelector(deletedItemSelector)
  const deleteItemIsLoading = useSelector(deleteItemIsLoadingSelector)

  const clearSubmitHandler = useCallback(() => {
    onClearDelete()
  }, [onClearDelete])

  useEffect(() => {
    const isCloseOnErrors = closeOnError && deleteItemError
    if (wasDeleted || wasSuccessfullyDeleted || isCloseOnErrors) {
      setIsDeleteModalOpened(false)
      if (onDeleteEnd) onDeleteEnd(deletedItemId)
      clearSubmitHandler()
    }
    // eslint-disable-next-line
  }, [wasDeleted, wasSuccessfullyDeleted, deleteItemError, closeOnError])

  const handleDelete = useCallback(
    (deleteProps = {}) => {
      // In case we delete item immediately, we use arguments deleteProps
      // In case we delete item via deleteModal, use saved localState dataWaitingForDelete
      const item = deleteProps.item || dataWaitingForDelete.item
      const account = deleteProps.account || dataWaitingForDelete.account

      onDelete({
        account,
        id: item?.id,
        data: dataWaitingForDelete
      })
    },
    // eslint-disable-next-line
    [dataWaitingForDelete]
  )

  const handleDeleteRequest = useCallback(
    deleteItemData => {
      const { item } = deleteItemData

      if (item?.status === 'archived') {
        // on archived items do not show the modal, just delete
        handleDelete(deleteItemData)
      } else {
        // set delete data that should be used in deleteModal
        setDataWaitingForDelete(deleteItemData)
        setIsDeleteModalOpened(true)
      }
    },
    [handleDelete]
  )

  const handleCloseDeleteModal = useCallback(() => {
    // forbid to close modal if delete is in progress
    if (!deleteItemIsLoading) {
      setIsDeleteModalOpened(false)
    }
  }, [deleteItemIsLoading])

  return useMemo(
    () => ({
      handleDelete,
      handleDeleteRequest,
      handleCloseDeleteModal,
      isDeleteModalOpened,
      deleteItemIsLoading,
      deleteItemError,
      dataWaitingForDelete
    }),
    [
      handleDelete,
      handleDeleteRequest,
      deleteItemIsLoading,
      deleteItemError,
      handleCloseDeleteModal,
      isDeleteModalOpened,
      dataWaitingForDelete
    ]
  )
}

export default useDeleteAction
