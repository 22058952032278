import { RESOURCE_NAME } from '../../fields'

const formatDeleteAssets = ({ resourceName, id, isInternalAssetGroup }) => ({
  ...(resourceName && { resource_name: resourceName }),
  // when doing update on internal asset group, we have to send internal_id field
  ...(isInternalAssetGroup && id && { internal_id: id }),
  action: 'delete'
})

export const formatUpdatedAssets = (originalAssets, updatesAssets, isInternalAssetGroup) => {
  // check if some assets were deleted during update
  const deleted = originalAssets.filter(
    originalAsset =>
      !updatesAssets.find(updatesAsset => {
        const updatesAssetId = updatesAsset.id || updatesAsset[RESOURCE_NAME]
        return updatesAssetId === originalAsset[RESOURCE_NAME]
      })
  )
  const deleteAssets = deleted.map(asset =>
    formatDeleteAssets({ resourceName: asset.resource_name, id: asset.id, isInternalAssetGroup })
  )
  const createAssets = []

  updatesAssets.forEach(updatedAsset => {
    // check if this updatedAsset was stored in google ads or it's new
    const updatedAssetId = updatedAsset.id || updatedAsset[RESOURCE_NAME]
    const existedAsset = originalAssets.find(asset => asset[RESOURCE_NAME] === updatedAssetId)

    if (existedAsset) {
      // updatedAsset existed in originalAssets - delete/create
      if (existedAsset.asset_data.text_asset) {
        if (existedAsset.asset_data.text_asset.text !== updatedAsset.text) {
          // if asset data was edited i.e. changed text, then a new one asset should be created
          // and added to the asset_group instead. That is because GA it self doesn't have ability to edit asset data
          createAssets.push(updatedAsset)
          // the previous asset should be removed
          deleteAssets.push(
            formatDeleteAssets({ resourceName: existedAsset.resource_name, id: existedAsset.id, isInternalAssetGroup })
          )
        }
      } else if (existedAsset.asset_data.image_asset) {
        // image asset can't be edited, it removes directly
      }
    } else {
      // new updatedAsset - create
      createAssets.push(updatedAsset)
    }
  })

  return {
    deleteAssets,
    createAssets
  }
}

export const formatAssetsToAssetGroupUpdate = (assets = [], assetType, assetGroupId) => {
  return assets.map(asset => ({
    asset_group: assetGroupId,
    asset: asset.resource_name,
    action: 'create',
    field_type: assetType,
    // hardcode status for 2 - ENABLED(AssetLinkStatus)
    status: 2
  }))
}

export const formatImageAssetsToAssetGroupUpdate = (assets, assetType, assetGroupId) => {
  return assets.map(asset => ({
    asset_group: assetGroupId,
    asset: asset.asset,
    action: 'create',
    field_type: assetType,
    // hardcode status for 2 - ENABLED(AssetLinkStatus)
    status: 2
  }))
}
