import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import Checkbox from '../../../../../../components/Form/Checkbox'
import EditForm from '../../../../../../features/components/Forms/EditForm'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'

import { clearUpdateCampaign, updateCampaign } from '../../../../../../modules/actions/campaigns'
import {
  campaignUpdateErrorSelector,
  campaignUpdateIsLoadingSelector,
  campaignWasUpdatedSelector
} from '../../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import {
  SPECIAL_AD_CATEGORIES,
  CATEGORY_CREDIT,
  CATEGORY_EMPLOYMENT,
  CATEGORY_HOUSING
} from '../../../../../ReusableFormFields/CampaignForms/fields'

const SpecialAdCategoriesSectionForm = ({ editItemData: campaign, ...formProps }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const successUpdate = useSelector(campaignWasUpdatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const { id: campaignId } = campaign

  const initialValues = useMemo(() => {
    const { special_ad_categories = [] } = campaign

    return {
      // SPECIAL AD CATEGORIES
      [SPECIAL_AD_CATEGORIES]: special_ad_categories || [],
      [CATEGORY_CREDIT]: special_ad_categories.includes(CATEGORY_CREDIT) || false,
      [CATEGORY_EMPLOYMENT]: special_ad_categories.includes(CATEGORY_EMPLOYMENT) || false,
      [CATEGORY_HOUSING]: special_ad_categories.includes(CATEGORY_HOUSING) || false
    }
  }, [campaign])

  const handleSubmit = useCallback(
    values => {
      const specialCategories = [CATEGORY_CREDIT, CATEGORY_EMPLOYMENT, CATEGORY_HOUSING]

      const updateCampaignData = {
        account: selectedAdAccountId,
        // combine separated values in one special_ad_categories array field
        [SPECIAL_AD_CATEGORIES]: specialCategories.filter(category => values[category])
      }
      dispatch(updateCampaign(updateCampaignData, campaignId))
    },
    [dispatch, selectedAdAccountId, campaignId]
  )

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)
  const { values, setFieldValue } = purifiedFormik

  const handleClearUpdateCampaign = useCallback(() => {
    dispatch(clearUpdateCampaign())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      successSubmit={successUpdate}
      clearEditItem={handleClearUpdateCampaign}
      initialValues={initialValues}
      errorSelector={campaignUpdateErrorSelector}
      isLoadingSelector={campaignUpdateIsLoadingSelector}
      {...formProps}
    >
      <p>{t('Please select which categories of ads you will be promoting (select all that apply)')}:</p>
      <Checkbox
        id="checkbox_credit"
        title={t('Credit')}
        isLarge
        checked={values[CATEGORY_CREDIT]}
        onCheck={() => {
          setFieldValue(CATEGORY_CREDIT, !values[CATEGORY_CREDIT])
        }}
      >
        <p>{t('For example: credit cards, loans and long-term financing.')}</p>
      </Checkbox>
      <Checkbox
        id="checkbox_employment"
        title={t('Employment')}
        isLarge
        checked={values[CATEGORY_EMPLOYMENT]}
        onCheck={() => {
          setFieldValue(CATEGORY_EMPLOYMENT, !values[CATEGORY_EMPLOYMENT])
        }}
      >
        <p>{t('For example: jobs, internships or professional certifications.')}</p>
      </Checkbox>
      <Checkbox
        id="checkbox_housing"
        title={t('Housing')}
        isLarge
        checked={values[CATEGORY_HOUSING]}
        onCheck={() => {
          setFieldValue(CATEGORY_HOUSING, !values[CATEGORY_HOUSING])
        }}
      >
        <p>{t('For example: property listings, insurance, loans and repairs.')}</p>
      </Checkbox>
    </EditForm>
  )
}

export default SpecialAdCategoriesSectionForm
