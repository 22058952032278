import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../components/Button'
import RadioBlock from '../../../../../components/Form/RadioBlock'

import {
  TRAVELER_TYPE_OPTION,
  SEARCHED_OR_BOOKED,
  SEARCHED,
  CONFIRMED,
  PRE_DEPARTURE,
  AT_AIRPORT,
  IN_DESTINATION,
  ALL_TRAVELERS
} from '../fields'

import useStyles from '../../../../../styles/common/stepForms'

const TravelerTypeStep = ({ formik, handleStepChange }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { values, setFieldValue } = formik

  const { [TRAVELER_TYPE_OPTION]: travelerTypeOption } = values

  const handleContinue = () => {
    // temporary limits
    if (travelerTypeOption === SEARCHED_OR_BOOKED) {
      handleStepChange()
    }
  }

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create an audience')}</div>
      <div className={classes.stepTitle}>{t('Which type of traveller do you want to target?')}</div>
      <div className={classes.stepBody}>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_searched_or_booked"
          name={TRAVELER_TYPE_OPTION}
          value={SEARCHED_OR_BOOKED}
          selectedValue={values[TRAVELER_TYPE_OPTION]}
          label={t('Searched or booked a flight')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_searched"
          name={TRAVELER_TYPE_OPTION}
          value={SEARCHED}
          selectedValue={values[TRAVELER_TYPE_OPTION]}
          label={t('Searched but not booked yet')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_confirmed"
          name={TRAVELER_TYPE_OPTION}
          value={CONFIRMED}
          selectedValue={values[TRAVELER_TYPE_OPTION]}
          label={t('Confirmed flight bookings only')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_pre_departure"
          name={TRAVELER_TYPE_OPTION}
          value={PRE_DEPARTURE}
          selectedValue={values[TRAVELER_TYPE_OPTION]}
          label={t('Pre-departure')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_at_airport"
          name={TRAVELER_TYPE_OPTION}
          value={AT_AIRPORT}
          selectedValue={values[TRAVELER_TYPE_OPTION]}
          label={t('At airport')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_in_destination"
          name={TRAVELER_TYPE_OPTION}
          value={IN_DESTINATION}
          selectedValue={values[TRAVELER_TYPE_OPTION]}
          label={t('In destination')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_all_travelers"
          name={TRAVELER_TYPE_OPTION}
          value={ALL_TRAVELERS}
          selectedValue={values[TRAVELER_TYPE_OPTION]}
          label={t('Target all travellers, regardless of route')}
        />
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" className="dark" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default TravelerTypeStep
