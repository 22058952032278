import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import usePermissions from '../../../../hooks/usePermissions'

import { insertIf } from '../../../../helpers/common'

import { openForm } from '../../../../modules/actions/forms'
import { approveInternalAssetGroup, deleteInternalAssetGroup } from '../../../../modules/actions/assets'

import { ASSET_GROUP_GOOGLE_ADS_EDIT } from '../../../../constants/forms'
import { GOOGLE_PROVIDER_PLATFORM } from '../../../../constants/selectLists/platformList'
import { AD_PERMISSION, INTERNAL_ADS_PERMISSION } from '../../../../constants/permissions'

const useGetAssetGroupActionsDropdownOptions = () => {
  const dispatch = useDispatch()

  const permissions = usePermissions()

  return useCallback(
    assetGroup => {
      const { id, internal: isInternal } = assetGroup

      const approveInternalAssetGroupHandler = () => {
        dispatch(approveInternalAssetGroup(id, GOOGLE_PROVIDER_PLATFORM))
      }

      const deleteInternalAssetGroupHandler = () => {
        dispatch(deleteInternalAssetGroup(id))
      }

      const openEditFormHandler = () => {
        dispatch(
          openForm({
            id,
            formName: ASSET_GROUP_GOOGLE_ADS_EDIT,
            ...(isInternal && { otherParams: 'internal=true' })
          })
        )
      }

      return [
        ...insertIf(
          isInternal && permissions.can('manage', INTERNAL_ADS_PERMISSION),
          {
            text: 'Approve',
            onClickHandler: approveInternalAssetGroupHandler
          },
          {
            text: 'Delete',
            onClickHandler: deleteInternalAssetGroupHandler
          }
        ),
        ...insertIf(permissions.can('update', AD_PERMISSION), {
          text: 'Edit',
          onClickHandler: openEditFormHandler
        })
      ]
    },
    [dispatch, permissions]
  )
}

export default useGetAssetGroupActionsDropdownOptions
