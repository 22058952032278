import React from 'react'

import SkeletonGoogleAssetsSliderPreview from '../../../../../../forms/Google/AdForms/GoogleAssetsSliderPreview/Skeleton'
import SkeletonInternalAdActionButtons from '../../../../InternalAdActionButtons/Skeleton'

import useStyles from '../../../../../../styles/common/adPreviewCard'

const SkeletonGoogleAdPreviewCard = () => {
  const classes = useStyles()

  return (
    <div className={classes.adPreviewCard}>
      <div className={classes.cardSliderPreviewWrapper}>
        <SkeletonGoogleAssetsSliderPreview />
      </div>
      <SkeletonInternalAdActionButtons />
    </div>
  )
}

export default SkeletonGoogleAdPreviewCard
