import React from 'react'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import UploadMediaOrderFileForm from './UploadMediaOrderFileForm'

import { UPLOAD_MEDIA_ORDER_FILE } from '../../../../constants/forms'

function UploadMediaOrderFile({ mediaOrderId, openBtnClassName }) {
  return (
    <FormDrawerWrapper
      openBtnClassName={openBtnClassName}
      formName={UPLOAD_MEDIA_ORDER_FILE}
      title="Upload a new file to this media booking"
      description="Please also select the type of file being uploaded"
      showOpenButton={false}
    >
      <UploadMediaOrderFileForm mediaOrderId={mediaOrderId} />
    </FormDrawerWrapper>
  )
}

export default UploadMediaOrderFile
