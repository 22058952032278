import {
  AD_LINK,
  DESCRIPTIONS_LIST,
  FILE_HASH,
  FILE_ID,
  FILE_URL,
  HEADLINES_LIST,
  MEDIA_TYPE,
  PRIMARY_TEXT_LIST,
  SINGLE_MEDIA_ITEM,
  VIDEO_PICTURE
} from './fields'
import { CALL_TO_ACTION } from '../../ReusableFormFields/CallToActionFields/fields'

export const formatChildAttachments = items => {
  return items.map(item => {
    const {
      [MEDIA_TYPE]: mediaType,
      [HEADLINES_LIST]: headlinesList,
      [DESCRIPTIONS_LIST]: descriptionsList,
      [FILE_ID]: feedVideoId,
      [AD_LINK]: adLink,
      [FILE_HASH]: fileHash,
      [CALL_TO_ACTION]: callToAction,
      [VIDEO_PICTURE]: videoPicture
    } = item

    // We support list of headlines and descriptions for custom ad, up to 5 items
    // For NOT custom ad we use 0 element from description and headline list
    const adHeadline = headlinesList?.[0].text
    const adDescription = descriptionsList?.[0].text

    return {
      ...(mediaType === 'video' && { video_id: feedVideoId }),
      // todo remove this conditional and place fields separately
      ...(videoPicture ? { image_url: videoPicture } : { image_hash: fileHash }),
      ...(!!adHeadline && { name: adHeadline }),
      ...(!!adDescription && { description: adDescription }),
      link: adLink,
      call_to_action: {
        type: callToAction.toLowerCase(),
        value: {
          link: adLink
        }
      }
    }
  })
}

export const formatSingleItemFeedToBE = values => {
  const mediaItem = values[SINGLE_MEDIA_ITEM]

  const {
    [MEDIA_TYPE]: mediaType,
    [VIDEO_PICTURE]: feedVideoPicture,
    [FILE_HASH]: fileHash,
    [FILE_URL]: fileUrl,
    [FILE_ID]: feedVideoId,
    [AD_LINK]: adLink,
    [CALL_TO_ACTION]: callToAction
  } = mediaItem

  const adText = values[PRIMARY_TEXT_LIST]?.[0].text
  const adHeadline = mediaItem[HEADLINES_LIST]?.[0].text
  const adDescription = mediaItem[DESCRIPTIONS_LIST]?.[0].text

  const callToActionPayload = {
    type: callToAction?.toLowerCase(),
    value: {
      link: adLink
    }
  }

  return mediaType === 'image'
    ? // MAIN FEED IS AN IMAGE
      {
        link_data: {
          ...(!!adText && { message: adText }),
          ...(!!adHeadline && { name: adHeadline }),
          ...(!!adDescription && { description: adDescription }),
          ...(fileHash && { image_hash: fileHash }),
          ...(!fileHash && { picture: fileUrl }),
          link: adLink,
          ...(adLink && { call_to_action: callToActionPayload })
        }
      }
    : {
        // MAIN FEED IS A VIDEO
        video_data: {
          ...(!!adText && { message: adText }),
          ...(!!adHeadline && { title: adHeadline }),
          ...(!!adDescription && { link_description: adDescription }),
          image_url: feedVideoPicture,
          video_id: feedVideoId,
          link: adLink,
          ...(adLink && { call_to_action: callToActionPayload })
        }
      }
}

export const isStory = placementPositions => {
  // if lineItem placement_positions consist only of storyPlacements items, then use story preview
  // if placement_positions is blank or has at least one different placement
  // then preview type is not story, but single or carousel instead
  const storyPlacements = ['facebook_story', 'instagram_reels', 'instagram_story', 'messenger_story']

  return (
    placementPositions && placementPositions.length && placementPositions.every(item => storyPlacements.includes(item))
  )
}
