import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAccessibleUrl } from '../../modules/actions/files'
import { fileAccessibleURLSelector } from '../../modules/selectors/files'

export default function useGetAccessibleUrl(autoDownload = true) {
  const dispatch = useDispatch()

  const [fileUrl, setFileUrl] = useState('')

  const { [fileUrl]: accessibleUrl } = useSelector(fileAccessibleURLSelector)
  const isAccessibleUrlLoading = !!accessibleUrl && accessibleUrl.isLoading

  const handleFileDownloading = useCallback(
    (fileUrl, params) => {
      // all urls are private, to make them accessible for file downloading, need to get getAccessibleUrl
      if (!isAccessibleUrlLoading) {
        setFileUrl(fileUrl)
        dispatch(getAccessibleUrl(fileUrl, params))
      }
    },
    [dispatch, isAccessibleUrlLoading]
  )

  useEffect(() => {
    // when accessibleUrl created, start file downloading/open file in new tab
    if (autoDownload && fileUrl && accessibleUrl && accessibleUrl.data) {
      window.open(accessibleUrl.data)
      setFileUrl('')
    }
  }, [autoDownload, fileUrl, accessibleUrl])

  return {
    isAccessibleUrlLoading,
    handleFileDownloading,
    fileUrl
  }
}
