import { addMonths, format, startOfMonth } from 'date-fns'

import RevenueTooltip from './RevenueTooltip'

import { formatDateToBE } from '../../../../../../../../constants/dates'
import { tableColumnsSize } from '../index'

export const generateTableColumns = classes => {
  const generatedColumns = generateMonths(startOfMonth(new Date()))

  return generatedColumns.map(date => {
    return {
      header: () => (
        <div className={classes.headerMonth}>
          <div>{format(date, 'MMM')}</div>
          <div>{format(date, 'yyyy')}</div>
        </div>
      ),
      Cell: data => {
        const startDate = formatDateToBE(date)
        const columnData = data.columns[startDate]

        if (columnData) {
          return (
            <RevenueTooltip
              startDate={date}
              totalRevenue={columnData.total_revenue}
              target={columnData.target}
              previousYear={columnData.previous_year}
            />
          )
        } else {
          return null
        }
      },
      headClassName: classes.revenueCol,
      className: classes.revenueCol,
      style: { maxWidth: tableColumnsSize.month }
    }
  })
}

const generateMonths = startDate => {
  // generate list of months from startDate, with 6 months in the future
  // each month should start from the first day of the month
  const months = [startDate]
  for (let i = 1; i < 6; i++) {
    const startMonthDate = addMonths(startOfMonth(startDate), i)
    months.push(startMonthDate)
  }

  return months
}
