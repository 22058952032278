import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo, useState } from 'react'

import useCampaignDetails from './useCampaignDetails'

import { transformValuesToAssetsCreation, transformValuesToBE } from '../formatters'

import {
  clearCreateAssetGroup,
  clearCreateAssetsBatch,
  createAssetGroup,
  createAssetsBatch
} from '../../../../../../modules/actions/assets'
import useDoubleRequestSubmit from '../../../../../../hooks/formHooks/useDoubleRequestSubmit'
import {
  assetGroupCreateErrorSelector,
  assetGroupWasCreatedSelector,
  assetsCreatedDataSelector,
  assetsCreateErrorSelector,
  assetsWasCreatedSelector
} from '../../../../../../modules/selectors/assets'
import { selectedAdAccountIdSelector, selectedSelfAccountDataSelector } from '../../../../../../modules/selectors/app'

import { STATUS } from '../../../fields'
import { GOOGLE_PLATFORM } from '../../../../../../constants/selectLists/platformList'

// GoogleAssetGroupMainForm form submission consists of 2 different requests:
// createAssetsBatch and then createAssetGroup, once first successfully submitted the 2nd is triggerred
export default function useGoogleAssetGroupCreate({ isInternalAssetGroupCreation, adAccountId: providedAdAccountId }) {
  const dispatch = useDispatch()

  const [submittedAssetsList, setSubmittedAssetsList] = useState([])

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const selectedSelfAccountData = useSelector(selectedSelfAccountDataSelector)
  const { controller: selfAccountController, id: selectedSelfAccountId } = selectedSelfAccountData

  const adAccountId = providedAdAccountId || selectedAdAccountId

  const { campaignId } = useCampaignDetails()

  const createAssetsHandler = useCallback(
    values => {
      const assetsData = transformValuesToAssetsCreation({ values, adAccountId, selectedSelfAccountData })
      // assetsData which is transformed to BE need to be saved to state as it is also used for 2nd request createAssetGroup
      setSubmittedAssetsList(assetsData)

      dispatch(
        createAssetsBatch(
          {
            account: adAccountId,
            operations: assetsData
          },
          GOOGLE_PLATFORM
        )
      )
    },
    [dispatch, adAccountId, selectedSelfAccountData]
  )

  const createAssetGroupHandler = useCallback(
    (values, createdAssetsData) => {
      const transformedValues = transformValuesToBE({
        values,
        assetsData: createdAssetsData,
        submittedAssetsList
      })

      dispatch(
        createAssetGroup(
          {
            ...transformedValues,
            account: adAccountId,
            campaign: campaignId,
            status: values[STATUS],
            ...(isInternalAssetGroupCreation && {
              quarantine: true,
              controller_id: selfAccountController,
              account_id: selectedSelfAccountId
            })
          },
          GOOGLE_PLATFORM
        )
      )
    },
    [
      campaignId,
      dispatch,
      isInternalAssetGroupCreation,
      selfAccountController,
      selectedSelfAccountId,
      adAccountId,
      submittedAssetsList
    ]
  )

  const clearSubmitHandler = useCallback(() => {
    dispatch(clearCreateAssetsBatch())
    dispatch(clearCreateAssetGroup())
  }, [dispatch])

  const createAssetRequest = useMemo(
    () => ({
      requestHandler: createAssetsHandler,
      errorSelector: assetsCreateErrorSelector,
      successSelector: assetsWasCreatedSelector,
      dataSelector: assetsCreatedDataSelector
    }),
    [createAssetsHandler]
  )

  const createAssetGroupRequest = useMemo(
    () => ({
      requestHandler: createAssetGroupHandler,
      errorSelector: assetGroupCreateErrorSelector,
      successSelector: assetGroupWasCreatedSelector
    }),
    [createAssetGroupHandler]
  )

  const requests = useMemo(
    () => [createAssetRequest, createAssetGroupRequest],
    [createAssetRequest, createAssetGroupRequest]
  )

  return useDoubleRequestSubmit({ requests, clearSubmitHandler })
}
