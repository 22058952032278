import { madeRequest } from '../../helpers/api'
import { replaceRouteParams } from '../../helpers/url'

import { ENDPOINT_PLATFORM, ENDPOINTS } from '../../constants/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getCampaignService({ id, ...params }, platform) {
  return madeRequest('GET', {
    params,
    url: replaceRouteParams(`${ENDPOINTS.campaigns}${id}/`, { platform: ENDPOINT_PLATFORM[platform] })
  })
}

export function getCampaignsService(params, platform) {
  return madeRequest('GET', {
    params,
    url: replaceRouteParams(ENDPOINTS.campaigns, { platform: ENDPOINT_PLATFORM[platform] })
  })
}

export function getShortInfoCampaignsService({ platform, ...params }) {
  return madeRequest('GET', {
    params,
    url: replaceRouteParams(ENDPOINTS.shortInfoCampaigns, {
      platform: ENDPOINT_PLATFORM[platform]
    })
  })
}

export function createCampaignService(campaignData, platform) {
  return madeRequest('POST', {
    data: campaignData,
    url: replaceRouteParams(ENDPOINTS.campaigns, { platform: ENDPOINT_PLATFORM[platform] })
  })
}

export function updateCampaignService(campaignData, id, platform, requestMethod) {
  return madeRequest(requestMethod || 'PATCH', {
    data: campaignData,
    url: replaceRouteParams(`${ENDPOINTS.campaigns}${id}/`, { platform: ENDPOINT_PLATFORM[platform] })
  })
}

export function duplicateCampaignService(duplicateData, id, platform) {
  return madeRequest('POST', {
    data: duplicateData,
    url: replaceRouteParams(ENDPOINTS.duplicateCampaign, {
      id,
      platform: ENDPOINT_PLATFORM[platform]
    })
  })
}

export function deleteCampaignService({ id, ...params }, platform) {
  return madeRequest('DELETE', {
    params,
    url: replaceRouteParams(`${ENDPOINTS.campaigns}${id}/`, { platform: ENDPOINT_PLATFORM[platform] })
  })
}

export function getCampaignCriterionsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.campaignCriterions
  })
}

export function getCampaignAdScheduleCriterionsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.campaignAdScheduleCriterions
  })
}

export function createCampaignCriterionsService(criterionsData) {
  return madeRequest('POST', {
    data: criterionsData,
    url: ENDPOINTS.campaignCriterionsBatch
  })
}

export function createCampaignCriterionProximityService(criterionData) {
  return madeRequest('POST', {
    data: criterionData,
    url: ENDPOINTS.campaignCriterionsCreateProximity
  })
}

export function createCampaignCriterionScheduleService(scheduleData) {
  return madeRequest('POST', {
    data: scheduleData,
    url: ENDPOINTS.campaignCriterionsCreateSchedule
  })
}
