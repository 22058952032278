import { ENDPOINTS, WEB_SOCKET_ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'
import { replaceRouteParams } from '../../helpers/url'
import { madeSocketRequest } from '../../helpers/api/webSocket'

// PLOP_APPEND_PATTERN_ANCHOR

export function getMediaOrderAsCsvService({ params, onSocketOpen }) {
  return madeSocketRequest(
    'post',
    {
      url: WEB_SOCKET_ENDPOINTS.mediaOrderExportCSV,
      params
    },
    { onSocketOpen }
  )
}

export function generateMediaOrderService(id, params) {
  return madeRequest('POST', {
    params,
    url: replaceRouteParams(ENDPOINTS.generateMediaOrder, { id })
  })
}

export function getMediaOrdersService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.mediaOrders
  })
}

export function getMediaOrderService(id) {
  return madeRequest('GET', {
    url: replaceRouteParams(`${ENDPOINTS.mediaOrders}:id/`, { id })
  })
}

export function getMediaOrderFilesService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.mediaOrderFiles
  })
}

export function addMediaOrderFileService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.mediaOrderFiles
  })
}

export function deleteMediaOrderFileService(params) {
  return madeRequest('DELETE', {
    url: replaceRouteParams(`${ENDPOINTS.mediaOrderFiles}:id/`, { id: params.id })
  })
}

export function createMediaOrderService(mediaOrderData) {
  return madeRequest('POST', {
    data: mediaOrderData,
    url: ENDPOINTS.mediaOrders
  })
}
export function updateMediaOrderService(id, data) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.mediaOrders}${id}/`
  })
}

export function cancelMediaOrderService(id, data) {
  return madeRequest('POST', {
    data,
    url: `${ENDPOINTS.mediaOrders}${id}/cancel/`
  })
}

export function getCatalogueProductsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.catalogueProducts
  })
}

export function getCategoryPagesService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.categoryPages
  })
}

export function createCategoryPageService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.categoryPages
  })
}

export function updateCategoryPageService(id, data) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.categoryPages}${id}/`
  })
}

export function getContractEntitiesService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.contractingEntities
  })
}

export function getMediaOrderHistoryService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.contractLogs
  })
}
