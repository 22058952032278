import {
  CALL_TO_ACTION,
  CALL_TO_ACTION_LEARN_MORE,
  CALL_TO_ACTION_OPTION,
  LEARN_MORE
} from '../../ReusableFormFields/CallToActionFields/fields'

export const adTextMaxLength = 100

// landing page url
export const LANDING_PAGE_URL = 'landing_page_url'
// ad text
export const AD_TEXT = 'ad_text'
// video
export const VIDEO = 'video_id'

export const formatCTA = values => {
  return values[CALL_TO_ACTION_OPTION] === CALL_TO_ACTION_LEARN_MORE ? LEARN_MORE : values[CALL_TO_ACTION]
}
