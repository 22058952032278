import React from 'react'

import Button from '../../../../../../components/Button'
import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

function TableActionsSkeleton() {
  const classes = useStyles()

  return (
    <div className={classes.tableActions}>
      <Button className={classes.copyTableBtn} type={'button'} isSmall={true}>
        <Skeleton width={100} />
      </Button>
    </div>
  )
}

export default TableActionsSkeleton
