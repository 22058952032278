import { calc } from '../../../../helpers/numbers'

import { DISCOUNT_PERCENTAGE, DISCOUNTS, NAME, PERIODS, QUANTITY } from './fields'

export const transformValuesToBE = values => {
  const transformedDiscounts = values[DISCOUNTS].map(discountPair => ({
    [QUANTITY]: discountPair[QUANTITY],
    [DISCOUNT_PERCENTAGE]: calc(discountPair[DISCOUNT_PERCENTAGE]).div(100).toNumber(),
    [PERIODS]: discountPair[PERIODS]
  }))

  return {
    [NAME]: values[NAME],
    ...(transformedDiscounts.length && { [DISCOUNTS]: transformedDiscounts })
  }
}
