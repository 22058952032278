import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { BID_TYPE_CUSTOM, BID_TYPE_NO_BID } from './fields'
import { CONVERSIONS, REACH, TRAFFIC, VIDEO_VIEWS } from '../../../../../constants/campaigns'

import { lineItemsSelector } from '../../../../../modules/selectors/lineItems'

const useLineItemCreateShowSteps = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'useLineItemCreateShowSteps')

  const { isCampaignLevelBudgetSet, campaignObjective } = props

  // following steps are conditionally shown based on number of existing line items
  // after first line item creation steps can disappear, which force number of shown steps to change
  // to prevent this behaviour during success progress button animation, we need this extra state
  const [isBidTypeStepInitiallyShown, setIsBidTypeStepInitiallyShown] = useState(false)
  const [isPixelStepInitiallyShown, setIsPixelStepInitiallyShown] = useState(false)

  const lineItems = useSelector(lineItemsSelector)

  // when campaign budget_optimize_on is true, and at least one line time exists
  // we should use bid type from any line item, because they all should have the same
  const shouldPopulateExistingValues = isCampaignLevelBudgetSet && !!lineItems.length

  // bid type step
  const bidTypeFromExistingLineItem = shouldPopulateExistingValues ? lineItems[0].bid_type : null

  const isBidTypeNoBidPopulated = shouldPopulateExistingValues && bidTypeFromExistingLineItem === BID_TYPE_NO_BID
  const isBidTypeCustomPopulated = shouldPopulateExistingValues && bidTypeFromExistingLineItem === BID_TYPE_CUSTOM

  const showBidPriceStep = useMemo(
    () =>
      (campaignObjective?.toLowerCase() === TRAFFIC ||
        campaignObjective?.toLowerCase() === REACH ||
        campaignObjective?.toLowerCase() === VIDEO_VIEWS) &&
      // if BID_TYPE_NO_BID is populated from existing line item, hide bid price step
      !isBidTypeNoBidPopulated,
    [campaignObjective, isBidTypeNoBidPopulated]
  )

  // pixel step
  const pixelIdFromExistingLineItem = shouldPopulateExistingValues ? lineItems[0].pixel_id : null
  const externalActionFromExistingLineItem = shouldPopulateExistingValues ? lineItems[0].external_action : null

  const showPixelStep = useMemo(
    () => campaignObjective?.toLowerCase() === CONVERSIONS && !shouldPopulateExistingValues,
    [campaignObjective, shouldPopulateExistingValues]
  )

  return {
    // bid price
    showBidPriceStep,
    isBidTypeNoBidPopulated,
    isBidTypeCustomPopulated,
    isBidTypeStepInitiallyShown,
    setIsBidTypeStepInitiallyShown,
    // pixel
    showPixelStep,
    pixelIdFromExistingLineItem,
    externalActionFromExistingLineItem,
    isPixelStepInitiallyShown,
    setIsPixelStepInitiallyShown
  }
}

const propTypes = {
  isCampaignLevelBudgetSet: PropTypes.bool.isRequired,
  campaignObjective: PropTypes.string.isRequired
}

export default useLineItemCreateShowSteps
