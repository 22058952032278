import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getIn } from 'formik'
import { Portal } from 'react-portal'

import AdStoriesCard from '../../../../../../../features/components/FacebookPreviews/AdStoriesCard'
import StoriesItemForm from '../StoriesItemForm'
import Button from '../../../../../../../components/Button'
import Step from '../../../../../../../features/components/Forms/StepForm/Step'

import {
  initialStoriesItem,
  FILE_URL,
  STORIES_ITEM_SELECTED_INDEX,
  STORIES_ITEMS,
  IS_ITEM_FORM_OPENED,
  AD_LINK
} from '../../../../fields'

import useStyles from './styles'

const StoriesStep = ({ formik, handleStepChange, adAccountId, isActive, isLastStep }) => {
  const [showError, setShowError] = useState(false)
  const { t } = useTranslation()

  const classes = useStyles()

  const { values, setFieldValue, setValues } = formik

  const storiesItems = values[STORIES_ITEMS]
  const selectedItemIndex = values[STORIES_ITEM_SELECTED_INDEX]
  const fileUrl = getIn(values, `${STORIES_ITEMS}[${selectedItemIndex}].${FILE_URL}`)
  // Constant that represent path of item in formik values
  const activeStoryPath = `${STORIES_ITEMS}[${selectedItemIndex}]`

  const handleAddAnotherStory = useCallback(() => {
    // add new initial item to storiesItems array
    // AD_LINK from previous item must be added to new item
    const newStoryItem = {
      ...initialStoriesItem,
      [AD_LINK]: getIn(values, `${activeStoryPath}.${AD_LINK}`)
    }
    setValues({
      ...values,
      [STORIES_ITEMS]: [...storiesItems, newStoryItem],
      [STORIES_ITEM_SELECTED_INDEX]: storiesItems.length
    })
    setShowError(false)
  }, [storiesItems, activeStoryPath, values, setValues])

  const handleDeleteItem = useCallback(
    index => {
      // delete item from carousel items array
      storiesItems.splice(index, 1)
      setFieldValue(STORIES_ITEMS, storiesItems)

      // select previous item if deleted item wasn't first
      if (index !== 0) {
        setFieldValue(STORIES_ITEM_SELECTED_INDEX, selectedItemIndex - 1)
      }
    },
    [storiesItems, setFieldValue, selectedItemIndex]
  )

  const handleItemClick = useCallback(
    ({ index, isFormOpened }) => {
      setFieldValue(STORIES_ITEM_SELECTED_INDEX, index)
      setFieldValue(IS_ITEM_FORM_OPENED, isFormOpened)
    },
    [setFieldValue]
  )

  const handleLeftSideClick = useCallback(() => {
    setFieldValue(STORIES_ITEM_SELECTED_INDEX, selectedItemIndex - 1)
  }, [setFieldValue, selectedItemIndex])

  const handleRightSideClick = useCallback(() => {
    setFieldValue(STORIES_ITEM_SELECTED_INDEX, selectedItemIndex + 1)
  }, [setFieldValue, selectedItemIndex])

  const handleContinue = useCallback(() => {
    handleStepChange([STORIES_ITEMS])
    setShowError(true)
  }, [handleStepChange])

  return (
    <Step stepTitle="Select images or videos" handleContinue={!isLastStep ? handleContinue : null}>
      <>
        {isActive && (
          <Portal node={document && document.getElementById('formSideColumnPortal')}>
            <AdStoriesCard
              formikValues={values}
              elementInProgress={STORIES_ITEMS}
              fileUrl={fileUrl}
              handleLeftSideClick={handleLeftSideClick}
              handleRightSideClick={handleRightSideClick}
            />
          </Portal>
        )}
        {storiesItems.map((item, index) => (
          <StoriesItemForm
            key={index}
            formik={formik}
            item={item}
            index={index}
            showError={showError}
            onItemDelete={handleDeleteItem}
            onItemClick={handleItemClick}
            adAccountId={adAccountId}
          />
        ))}
        {storiesItems.length < 3 && (
          <Button solid type={'button'} className={'btnBlock'} onClick={handleAddAnotherStory}>
            {t('Add Another Image or Video')}
          </Button>
        )}
        {storiesItems.length < 3 && (
          <div className={classes.additionalInfo}>
            {t('Adding more images or videos will create a series of up to 3 stories.')}
          </div>
        )}
      </>
    </Step>
  )
}

export default StoriesStep
