import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { formatDateShort } from '../../../../../../constants/dates'
import { clearTimeZone } from '../../../../../../helpers/date'

import { lineItemSelector } from '../../../../../../modules/selectors/lineItems'

const DatesSectionPreview = () => {
  const { t } = useTranslation()

  const { time_start: timeStart, time_stop: timeStop } = useSelector(lineItemSelector)

  const clearedTimeStart = clearTimeZone(timeStart)
  const clearedTimeStop = clearTimeZone(timeStop)

  const startDate = clearedTimeStart && formatDateShort(clearedTimeStart)
  const endDate = clearedTimeStop ? formatDateShort(clearedTimeStop) : t('Ongoing')

  return (
    <p>
      {startDate}
      {startDate && endDate ? ' - ' : null}
      {endDate}
    </p>
  )
}

export default DatesSectionPreview
