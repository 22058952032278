import * as Yup from 'yup'

import nameValidation from '../../../../features/validations/name'

export const NAME = 'name'

export const initialValues = {
  [NAME]: ''
}

export const validationSchema = Yup.object(nameValidation)
