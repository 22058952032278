import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTheme } from 'react-jss'

import useTouchManage from '../../hooks/useTouchManage'

import { getActionTextColor } from './helpers'

import useStyles from './styles'

function ActionText({
  children,
  className,
  onClick,
  isDark,
  isDarkGrey,
  isBold,
  isDisabled,
  isUnderlined,
  customColorSchema
}) {
  const theme = useTheme()

  const colorsSchema = useMemo(() => {
    if (customColorSchema) {
      // if customColorSchema is provided, we use it
      return customColorSchema
    } else {
      return getActionTextColor(theme, { isDark, isDarkGrey, isDisabled })
    }
  }, [theme, isDark, isDarkGrey, isDisabled, customColorSchema])
  const classes = useStyles({ isBold, isDisabled, isUnderlined, colorsSchema })

  const { t } = useTranslation()

  const { isTouched, touchStartEvent } = useTouchManage()

  return (
    <span
      onClick={isDisabled ? null : onClick}
      onTouchStart={touchStartEvent}
      className={classnames(classes.actionText, className, { isTouched })}
    >
      {t(children)}
    </span>
  )
}

ActionText.propTypes = {
  // PropTypes.array is needed if we pass t function
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  isDark: PropTypes.bool,
  isBold: PropTypes.bool,
  customColorSchema: PropTypes.shape({
    color: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    touchedColor: PropTypes.string.isRequired
  }),
  isDisabled: PropTypes.bool
}

export default ActionText
