import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getTriggersService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.triggers
  })
}

export function createTriggerService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.triggers
  })
}

export function updateTriggerService(id, data) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.triggers}${id}/`
  })
}

export function deleteTriggerService(id) {
  return madeRequest('DELETE', {
    url: `${ENDPOINTS.triggers}${id}/`
  })
}
