import Color from 'color'

import NeoSansArabicforCenomiRegular from '../fonts/NeoSansArabic/NeoSansArabicforCenomi-Rg.ttf'
import NeoSansArabicforCenomiMedium from '../fonts/NeoSansArabic/NeoSansArabicforCenomi-Md.ttf'

import { CHIP_COLORS } from '../../constants/other'

const mainColor = '#20113e'

export const cenomispaceTheme = {
  fontFamily: {
    name: 'NeoSansArabicforCenomi',
    value: 'NeoSansArabicforCenomi, sans-serif',
    normal: NeoSansArabicforCenomiRegular,
    medium: NeoSansArabicforCenomiMedium,
    bold: NeoSansArabicforCenomiMedium,
    format: 'truetype'
  },
  chips: {
    borderRadius: '50px',
    textColor: 'white',
    backgroundColors: {
      [CHIP_COLORS.green]: '#00b303',
      [CHIP_COLORS.yellow]: '#FACC15FF',
      [CHIP_COLORS.grey]: '#626670FF',
      [CHIP_COLORS.purple]: '#592B87FF',
      [CHIP_COLORS.blue]: '#3494EDFF',
      [CHIP_COLORS.red]: '#C72000FF',
      [CHIP_COLORS.redWithWhiteColor]: '#C72000FF'
    }
  },
  brandPrimary: mainColor,
  brandPrimaryLight: Color(mainColor).lightness(80).rgb().string(),
  brandPrimaryHover: Color(mainColor).darken(0.3).rgb().string(),
  brandPrimaryTextContrastColor: 'white',
  selectActiveBorderColor: mainColor,
  defaultBorderRadius: 2,
  bigBorderRadius: 4
}
