import { concat } from '../../helpers/common'

const MODULE_NAME = 'MEDIA_ORDER_PRODUCT_LOCATION/'

// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_MEDIA_PRODUCT_VARIABLES = concat(MODULE_NAME, 'GET_MEDIA_PRODUCT_VARIABLES')
export const GET_MEDIA_PRODUCT_VARIABLES_SUCCESS = concat(MODULE_NAME, 'GET_MEDIA_PRODUCT_VARIABLES_SUCCESS')
export const GET_MEDIA_PRODUCT_VARIABLES_FAILURE = concat(MODULE_NAME, 'GET_MEDIA_PRODUCT_VARIABLES_FAILURE')
export const CLEAR_GET_MEDIA_PRODUCT_VARIABLES = concat(MODULE_NAME, 'CLEAR_GET_MEDIA_PRODUCT_VARIABLES')

export const CREATE_MEDIA_PRODUCT_VARIABLE = concat(MODULE_NAME, 'CREATE_MEDIA_PRODUCT_VARIABLE')
export const CREATE_MEDIA_PRODUCT_VARIABLE_SUCCESS = concat(MODULE_NAME, 'CREATE_MEDIA_PRODUCT_VARIABLE_SUCCESS')
export const CREATE_MEDIA_PRODUCT_VARIABLE_FAILURE = concat(MODULE_NAME, 'CREATE_MEDIA_PRODUCT_VARIABLE_FAILURE')
export const CLEAR_CREATE_MEDIA_PRODUCT_VARIABLE = concat(MODULE_NAME, 'CLEAR_CREATE_MEDIA_PRODUCT_VARIABLE')

export const UPDATE_MEDIA_PRODUCT_VARIABLE = concat(MODULE_NAME, 'UPDATE_MEDIA_PRODUCT_VARIABLE')
export const UPDATE_MEDIA_PRODUCT_VARIABLE_SUCCESS = concat(MODULE_NAME, 'UPDATE_MEDIA_PRODUCT_VARIABLE_SUCCESS')
export const UPDATE_MEDIA_PRODUCT_VARIABLE_FAILURE = concat(MODULE_NAME, 'UPDATE_MEDIA_PRODUCT_VARIABLE_FAILURE')
export const CLEAR_UPDATE_MEDIA_PRODUCT_VARIABLE = concat(MODULE_NAME, 'CLEAR_UPDATE_MEDIA_PRODUCT_VARIABLE')

export const DELETE_MEDIA_PRODUCT_VARIABLE = concat(MODULE_NAME, 'DELETE_MEDIA_PRODUCT_VARIABLE')
export const DELETE_MEDIA_PRODUCT_VARIABLE_SUCCESS = concat(MODULE_NAME, 'DELETE_MEDIA_PRODUCT_VARIABLE_SUCCESS')
export const DELETE_MEDIA_PRODUCT_VARIABLE_FAILURE = concat(MODULE_NAME, 'DELETE_MEDIA_PRODUCT_VARIABLE_FAILURE')
export const CLEAR_DELETE_MEDIA_PRODUCT_VARIABLE = concat(MODULE_NAME, 'CLEAR_DELETE_MEDIA_PRODUCT_VARIABLE')

export const GET_MEDIA_PRODUCT_VARIABLE_OPTIONS = concat(MODULE_NAME, 'GET_MEDIA_PRODUCT_VARIABLE_OPTIONS')
export const GET_MEDIA_PRODUCT_VARIABLE_OPTIONS_SUCCESS = concat(
  MODULE_NAME,
  'GET_MEDIA_PRODUCT_VARIABLE_OPTIONS_SUCCESS'
)
export const GET_MEDIA_PRODUCT_VARIABLE_OPTIONS_FAILURE = concat(
  MODULE_NAME,
  'GET_MEDIA_PRODUCT_VARIABLE_OPTIONS_FAILURE'
)
export const CLEAR_GET_MEDIA_PRODUCT_VARIABLE_OPTIONS = concat(MODULE_NAME, 'CLEAR_GET_MEDIA_PRODUCT_VARIABLE_OPTIONS')

export const GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST = concat(MODULE_NAME, 'GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST')
export const GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST_SUCCESS = concat(
  MODULE_NAME,
  'GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST_SUCCESS'
)
export const GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST_FAILURE = concat(
  MODULE_NAME,
  'GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST_FAILURE'
)
export const CLEAR_GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST = concat(
  MODULE_NAME,
  'CLEAR_GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST'
)

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// Get media product variables
export function getMediaProductVariables(params) {
  return { type: GET_MEDIA_PRODUCT_VARIABLES, params }
}

export function getMediaProductVariablesSuccess(data) {
  return { type: GET_MEDIA_PRODUCT_VARIABLES_SUCCESS, data }
}

export function getMediaProductVariablesFailure(error) {
  return { type: GET_MEDIA_PRODUCT_VARIABLES_FAILURE, error }
}

export function clearGetMediaProductVariables() {
  return { type: CLEAR_GET_MEDIA_PRODUCT_VARIABLES }
}

// Create product variable
export function createMediaProductVariable(data) {
  return { type: CREATE_MEDIA_PRODUCT_VARIABLE, data }
}

export function createMediaProductVariableSuccess(data) {
  return { type: CREATE_MEDIA_PRODUCT_VARIABLE_SUCCESS, data }
}

export function createMediaProductVariableFailure(error) {
  return { type: CREATE_MEDIA_PRODUCT_VARIABLE_FAILURE, error }
}

export function clearCreateMediaProductVariable() {
  return { type: CLEAR_CREATE_MEDIA_PRODUCT_VARIABLE }
}

// Update product variable
export function updateMediaProductVariable(data, id) {
  return { type: UPDATE_MEDIA_PRODUCT_VARIABLE, data, id }
}

export function updateMediaProductVariableSuccess(data) {
  return { type: UPDATE_MEDIA_PRODUCT_VARIABLE_SUCCESS, data }
}

export function updateMediaProductVariableFailure(error) {
  return { type: UPDATE_MEDIA_PRODUCT_VARIABLE_FAILURE, error }
}

export function clearUpdateMediaProductVariable() {
  return { type: CLEAR_UPDATE_MEDIA_PRODUCT_VARIABLE }
}

// Delete media product variable
export function deleteMediaProductVariable(id) {
  return { type: DELETE_MEDIA_PRODUCT_VARIABLE, id }
}

export function deleteMediaProductVariableSuccess(data) {
  return { type: DELETE_MEDIA_PRODUCT_VARIABLE_SUCCESS, data }
}

export function deleteMediaProductVariableFailure(error) {
  return { type: DELETE_MEDIA_PRODUCT_VARIABLE_FAILURE, error }
}

export function clearDeleteMediaProductVariable() {
  return { type: CLEAR_DELETE_MEDIA_PRODUCT_VARIABLE }
}

// Get media product variable options
export function getMediaProductVariableOptions(
  params,
  loadOptions = {
    shouldClearExistingState: false
  }
) {
  return { type: GET_MEDIA_PRODUCT_VARIABLE_OPTIONS, params, loadOptions }
}

export function getMediaProductVariableOptionsSuccess(mediaProductVariableId, data) {
  return { type: GET_MEDIA_PRODUCT_VARIABLE_OPTIONS_SUCCESS, mediaProductVariableId, data }
}

export function getMediaProductVariableOptionsFailure(mediaProductVariableId, error) {
  return { type: GET_MEDIA_PRODUCT_VARIABLE_OPTIONS_FAILURE, mediaProductVariableId, error }
}

export function clearGetMediaProductVariableOptions() {
  return { type: CLEAR_GET_MEDIA_PRODUCT_VARIABLE_OPTIONS }
}

// Get media product variables bulk list
export function getMediaProductVariablesBulkList(params) {
  return { type: GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST, params }
}

export function getMediaProductVariablesBulkListSuccess(data) {
  return { type: GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST_SUCCESS, data }
}

export function getMediaProductVariablesBulkListFailure(error) {
  return { type: GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST_FAILURE, error }
}

export function clearGetMediaProductVariablesBulkList() {
  return { type: CLEAR_GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST }
}
