import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DeactivateUserModal from '../ManageSelfAccountTeam/DeactivateUserModal'
import ManageAgencyContent from './ManageAgencyContent'

import useModalManage from '../../../hooks/useModalManage'

import {
  clearAgencyInvites,
  clearAgencyMembers,
  clearUpdateAgencyMember,
  updateAgencyMemberStatus
} from '../../../modules/actions/agencyTeam'
import { updatedAgencyMemberSelector } from '../../../modules/selectors/agencyTeam'
import { clearGetAgency } from '../../../modules/actions/agencies'
import { agencySelector } from '../../../modules/selectors/agencies'

import { MEMBER_CHANGE_DATA, MEMBER_CHANGE_STATUS } from '../../../constants/forms'

function ManageAgency() {
  const [memberId, setMemberId] = useState(null)

  const dispatch = useDispatch()

  const agency = useSelector(agencySelector)
  const member = useSelector(updatedAgencyMemberSelector)

  const { name: agencyName } = agency
  const { wasUpdated: memberWasUpdated, updatingType } = member

  const memberStatusWasUpdated = memberWasUpdated && updatingType === MEMBER_CHANGE_STATUS
  const memberDataWasUpdated = memberWasUpdated && updatingType === MEMBER_CHANGE_DATA

  const { isModalOpened, closeModalHandler, openModalHandler } = useModalManage({
    triggerClose: memberStatusWasUpdated
  })

  const deactivateHandler = useCallback(() => {
    dispatch(updateAgencyMemberStatus(memberId, 'active'))
  }, [dispatch, memberId])

  const clearUpdateMemberHandler = useCallback(() => {
    dispatch(clearUpdateAgencyMember())
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(clearAgencyMembers())
      dispatch(clearAgencyInvites())
      dispatch(clearGetAgency())
    }
  }, [dispatch])

  useEffect(() => {
    if (memberStatusWasUpdated || memberDataWasUpdated) {
      clearUpdateMemberHandler()
    }
  }, [memberStatusWasUpdated, memberDataWasUpdated, clearUpdateMemberHandler])

  return (
    <>
      <ManageAgencyContent setMemberId={setMemberId} openModalHandler={openModalHandler} />
      <DeactivateUserModal
        member={member}
        deactivateHandler={deactivateHandler}
        clearHandler={clearUpdateMemberHandler}
        isModalOpened={isModalOpened}
        closeModalHandler={closeModalHandler}
        memberStatusWasUpdated={memberStatusWasUpdated}
        accountName={`${agencyName} agency`}
      />
    </>
  )
}

export default ManageAgency
