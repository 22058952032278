import React, { forwardRef, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import AdCard from '../../../../features/components/FacebookPreviews/AdCard'
import SkeletonAdCard from '../../../../features/components/FacebookPreviews/AdCard/Skeleton'
import AdStoriesCard from '../../../../features/components/FacebookPreviews/AdStoriesCard'

import { isCarousel } from './helpers'
import { isStory } from '../helpers'

import { adModifiedDataSelector } from '../../../../modules/selectors/ads'

import { CAROUSEL_ITEMS, MEDIA_FILE_OPTION, STORIES_ITEM_SELECTED_INDEX, STORIES_ITEMS } from '../fields'

const AdPreview = forwardRef((props, ref) => {
  const {
    originalValues,
    ad,
    placementPositions,
    isLoading,
    hideControlsPanel,
    className,
    isEditForm,
    showSkeletonPlaceholders
  } = props

  const [storiesSelectedItem, setStoriesSelectedItem] = useState(0)

  const modifiedValues = useSelector(adModifiedDataSelector)

  const handleLeftSideClick = useCallback(() => {
    setStoriesSelectedItem(storiesSelectedItem - 1)
  }, [storiesSelectedItem])

  const handleRightSideClick = useCallback(() => {
    setStoriesSelectedItem(storiesSelectedItem + 1)
  }, [storiesSelectedItem])

  const formikValues = {
    ...originalValues,
    ...(isEditForm && { ...modifiedValues }),
    [STORIES_ITEM_SELECTED_INDEX]: storiesSelectedItem
  }

  if (isLoading) {
    return <SkeletonAdCard />
  }

  return (
    <div className={className} ref={ref}>
      {isStory(placementPositions) ? (
        <AdStoriesCard
          viewAdOnFacebook={ad?.preview}
          formikValues={formikValues}
          elementInProgress={STORIES_ITEMS}
          handleLeftSideClick={handleLeftSideClick}
          handleRightSideClick={handleRightSideClick}
          hideControlsPanel={hideControlsPanel}
        />
      ) : (
        <AdCard
          viewAdOnFacebook={ad?.preview}
          formikValues={formikValues}
          elementInProgress={isCarousel(ad) ? CAROUSEL_ITEMS : MEDIA_FILE_OPTION}
          hideControlsPanel={hideControlsPanel}
          showSkeletonPlaceholders={showSkeletonPlaceholders}
        />
      )}
    </div>
  )
})

AdPreview.propTypes = {
  originalValues: PropTypes.object,
  ad: PropTypes.object,
  placementPositions: PropTypes.array,
  isLoading: PropTypes.bool,
  hideControlsPanel: PropTypes.bool,
  className: PropTypes.string,
  isEditForm: PropTypes.bool
}

export default AdPreview
