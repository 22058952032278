import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as TeamIcon } from '../../../../../assets/icons/team.svg'
import { ReactComponent as CheckedIcon } from '../../../../../assets/icons/checked-circle.svg'

import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

const AudiencesInfoSection = () => {
  const drawerFormClasses = useDrawerFormStyles()

  const { t } = useTranslation()

  return (
    <section className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>{t('1st party audience targeting')}</h3>
      <div className={drawerFormClasses.sectionInfoIconWrapper}>
        <TeamIcon />
      </div>
      <p>
        {t(
          'You can target your ads to their 1st party data audiences. The audience targeting will be limited to the audiences you select.'
        )}
      </p>
      <div className={drawerFormClasses.sectionInfoStatusRow}>
        <CheckedIcon className={drawerFormClasses.sectionInfoStatusIcon} />
        <p>{t('Valuable audiences direct from the data owner')}</p>
      </div>
      <div className={drawerFormClasses.sectionInfoStatusRow}>
        <CheckedIcon className={drawerFormClasses.sectionInfoStatusIcon} />
        <p>{t('Target one audience which can contain multiple audience segments')}</p>
      </div>
      <div className={drawerFormClasses.sectionInfoStatusRow}>
        <CheckedIcon className={drawerFormClasses.sectionInfoStatusIcon} />
        <p>{t('Ability to exclude your own audiences (e.g. recent purchasers)')}</p>
      </div>
    </section>
  )
}

export default AudiencesInfoSection
