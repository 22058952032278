import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import Table from '../../../../../../components/Table'
import ActionsDropdown from '../../../../../../features/components/ActionsDropdown'
import ActionsButtonsList from '../../../../../../features/components/ActionsButtonsList'

import { accessibleFacebookPagesSelector } from '../../../../../../modules/selectors/socialAccounts'
import { deleteAccessibleFacebookPages } from '../../../../../../modules/actions/socialAccounts'

import { facebookPagesTableColumnsSize } from '../index'

import { phonesDownSize } from '../../../../../../styles/const/breakpoints'

import useStyles from '../../../styles'

const FacebookPagesTableData = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const facebookPages = useSelector(accessibleFacebookPagesSelector)

  const handleFacebookPageDelete = useCallback(id => dispatch(deleteAccessibleFacebookPages(id)), [dispatch])

  const getDropdownOptions = useCallback(
    id => [
      {
        text: 'Delete',
        onClickHandler: () => handleFacebookPageDelete(id)
      }
    ],
    [handleFacebookPageDelete]
  )

  const facebookPagesColumns = useMemo(
    () => [
      {
        Cell: ({ thumbnail }) => (
          <img
            className={classes.listImage}
            src={thumbnail}
            alt="icon"
            onError={i => (i.target.style.display = 'none')}
          />
        ),
        style: { padding: '0 5px 0 0', maxWidth: facebookPagesTableColumnsSize.dropdown }
      },
      {
        header: 'Name',
        fieldKey: 'name'
      },
      {
        header: 'Page ID',
        fieldKey: 'external_id',
        showOnMobile: false
      },
      {
        Cell: ({ internal_id: internalId }) =>
          isMobile ? (
            <ActionsButtonsList options={getDropdownOptions(internalId)} />
          ) : (
            <ActionsDropdown options={getDropdownOptions(internalId)} />
          ),
        style: isMobile ? { padding: 0 } : { maxWidth: facebookPagesTableColumnsSize.dropdown },
        showOnMobile: false
      }
    ],
    [classes.listImage, getDropdownOptions, isMobile]
  )

  return (
    <Table
      hideFooterRow
      data={facebookPages}
      cols={facebookPagesColumns}
      mobileColumnsWrapperClassName={classes.mobileHeaderStyle}
    />
  )
}

export default FacebookPagesTableData
