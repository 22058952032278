import { createStyles } from '../../../../styles/helpers'

import { bannerGrey, lightGrey, mediumGrey } from '../../../../styles/const/colors'

export default createStyles(theme => ({
  container: {
    border: `1px solid ${mediumGrey}`,
    borderRadius: theme.bigBorderRadius,
    background: ({ isDark }) => (isDark ? lightGrey : 'white'),

    '&:not(:last-child)': {
      marginBottom: hasMargin => hasMargin && 16
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTopLeftRadius: theme.bigBorderRadius,
    borderTopRightRadius: theme.bigBorderRadius,
    padding: '16px 20px',
    borderBottom: `1px solid ${mediumGrey}`,
    background: ({ isDark }) => (isDark ? bannerGrey : lightGrey)
  },
  title: {
    fontSize: 16,
    fontWeight: 600
  },
  content: {
    padding: '18px 20px 20px'
  }
}))
