import {
  FB_PAGE_CHOICE,
  FORMAT_OPTION,
  FORMAT_PRODUCT,
  IS_CUSTOMIZED_MEDIA,
  MEDIA_TYPE,
  NAME,
  PIXEL_CHOICE,
  PRIMARY_TEXT_LIST,
  SINGLE_MEDIA_ITEM
} from '../../../../fields'
import {
  initialProductOrPagesValues,
  PRODUCT_OPTION,
  PRODUCT_OPTION_PRODUCT_PAGES
} from '../../../../../../ReusableFormFields/ProductsAndPagesFields/fields'
import { singleMediaItemInitialValues } from '../fields'
import { CALL_TO_ACTION, SHOP_NOW } from '../../../../../../ReusableFormFields/CallToActionFields/fields'

export const getInitialValues = ({ facebookPages }) => ({
  [NAME]: '',
  [FORMAT_OPTION]: FORMAT_PRODUCT,
  [PRIMARY_TEXT_LIST]: [{ text: '' }],
  [SINGLE_MEDIA_ITEM]: {
    ...singleMediaItemInitialValues,
    [MEDIA_TYPE]: 'image',
    [CALL_TO_ACTION]: SHOP_NOW
  },
  [FB_PAGE_CHOICE]: facebookPages[0]?.value, // preselect first facebook page
  [IS_CUSTOMIZED_MEDIA]: false,
  [PIXEL_CHOICE]: '',
  // CATALOGUE PRODUCT route fields (internal feature)
  ...initialProductOrPagesValues,
  [PRODUCT_OPTION]: PRODUCT_OPTION_PRODUCT_PAGES
})
