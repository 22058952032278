import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'

import ActionText from '../../../components/ActionText'
import OptionItemRow from './OptionItemRow'
import LoadMoreItemsSkeleton from './LoadMoreItemsSkeleton'

import useStyles from './styles'

function MultipleOptionsField({
  listFieldName,
  fieldPlaceholder,
  initialValue,
  fieldMaxLength,
  maxLengthSchema,
  isTextarea,
  formik,
  itemPath,
  minListQuantity = 1,
  maxListQuantity,
  selectedFieldName,
  preselectField,
  onFieldSelect,
  ignoreBlur,
  addAnotherButtonText = 'Add another',
  showLoadMoreButton,
  onLoadMoreButtonClick,
  isMoreItemsLoading
}) {
  const { t } = useTranslation()
  const classes = useStyles()

  const { values, setFieldValue } = formik

  const fieldsList = values[listFieldName]

  const getName = useCallback(({ itemPath, listFieldName, index }) => {
    return !!itemPath ? `${itemPath}.${listFieldName}[${index}].text` : `${listFieldName}[${index}].text`
  }, [])

  const selectFirstEmptyField = useCallback(
    fieldsList => {
      if (preselectField && onFieldSelect) {
        // find field to set when there is field selection function and preselection is allowed
        const fieldIndexWithoutValue = fieldsList.findIndex(field => !field.text)

        if (fieldIndexWithoutValue >= 0) {
          // if there is field with empty value set it
          const preselectFieldName = getName({ itemPath, listFieldName, index: fieldIndexWithoutValue })
          selectedFieldName !== preselectFieldName && onFieldSelect(preselectFieldName)
        }
      }
    },
    [preselectField, getName, itemPath, onFieldSelect, listFieldName, selectedFieldName]
  )

  const addAnotherFieldItemHandler = useCallback(
    (initialValue = '') => {
      setFieldValue(listFieldName, [...fieldsList, { text: initialValue, id: uuidv4() }])
      const fieldName = getName({ itemPath, listFieldName, index: fieldsList.length })
      onFieldSelect && onFieldSelect(fieldName)
    },
    [getName, itemPath, onFieldSelect, setFieldValue, fieldsList, listFieldName]
  )

  const showAddAnotherButton = useMemo(() => {
    if (!maxListQuantity && maxListQuantity !== 0) {
      // if maxListQuantity is not set, allow adding fields
      return true
    } else {
      return fieldsList?.length < maxListQuantity
    }
  }, [maxListQuantity, fieldsList])

  useEffect(() => {
    // preselect first empty field if there is no selected field
    if (!selectedFieldName) {
      selectFirstEmptyField(fieldsList)
    }
  }, [selectedFieldName, selectFirstEmptyField, fieldsList])

  return (
    <>
      {fieldsList?.map((item, index) => {
        const fieldName = getName({ itemPath, listFieldName, index })
        const allowDelete = fieldsList?.length > minListQuantity

        return (
          <OptionItemRow
            formik={formik}
            fieldsList={fieldsList}
            listFieldName={listFieldName}
            selectedFieldName={selectedFieldName}
            fieldMaxLength={fieldMaxLength}
            maxLengthSchema={maxLengthSchema}
            fieldName={fieldName}
            index={index}
            item={item}
            fieldPlaceholder={fieldPlaceholder}
            minListQuantity={minListQuantity}
            allowDelete={allowDelete}
            ignoreBlur={ignoreBlur}
            isTextarea={isTextarea}
            selectFirstEmptyField={selectFirstEmptyField}
            onFieldSelect={onFieldSelect}
          />
        )
      })}

      {showLoadMoreButton &&
        (isMoreItemsLoading ? (
          <LoadMoreItemsSkeleton />
        ) : (
          <ActionText className={classes.loadMoreButton} onClick={onLoadMoreButtonClick} isDark={true}>
            {t('Load more')}
          </ActionText>
        ))}

      <div className={classes.addAnotherWrapper}>
        {showAddAnotherButton && (
          <ActionText
            className={classes.addAnother}
            onClick={() => addAnotherFieldItemHandler(initialValue)}
            isDark={true}
          >
            {t(addAnotherButtonText)}
          </ActionText>
        )}
      </div>
    </>
  )
}

MultipleOptionsField.propTypes = {
  isTextarea: PropTypes.bool,
  ignoreBlur: PropTypes.bool, // control field onBlur action fire
  listFieldName: PropTypes.string,
  fieldPlaceholder: PropTypes.string,
  initialValue: PropTypes.string,
  fieldMaxLength: PropTypes.number,
  maxLengthSchema: PropTypes.shape({
    default: PropTypes.number.isRequired
  }),
  formik: PropTypes.object,
  itemPath: PropTypes.string,
  // control how many fields could be created/deleted
  minListQuantity: PropTypes.number,
  maxListQuantity: PropTypes.number,
  selectedFieldName: PropTypes.string,
  onFieldSelect: PropTypes.func,
  addAnotherButtonText: PropTypes.string
}

export default MultipleOptionsField
