import { t } from 'i18next'
import { call, put, all, takeEvery, select } from 'redux-saga/effects'

import {
  SUBMIT_LOGIN,
  submitLoginSuccess,
  submitLoginFailure,
  SUBMIT_MFA_CODE,
  submitMFACodeSuccess,
  submitMFACodeFailure,
  CHECK_AUTH_STATUS,
  checkAuthStatusSuccess,
  checkAuthStatusFailure,
  SUBMIT_LOGOUT,
  submitLogoutSuccess,
  submitLogoutFailure,
  RESET_PASSWORD,
  resetPasswordSuccess,
  resetPasswordFailure,
  CREATE_PASSWORD,
  createPasswordSuccess,
  createPasswordFailure,
  CREATE_NEW_PASSWORD,
  createNewPasswordSuccess,
  createNewPasswordFailure,
  GET_TOTP_TOKEN,
  getTOTPTokenSuccess,
  getTOTPTokenFailure,
  VERIFY_TOTP_TOKEN,
  verifyTOTPTokenSuccess,
  verifyTOTPTokenFailure,
  submitLoginMFARequiredSuccess,
  SUBMIT_MFA_TYPE,
  clearSubmitMfaType,
  submitMfaTypeSuccess,
  submitMfaTypeFailure
} from '../actions/cognito'
import {
  signInService,
  getCurrentUser,
  signOutService,
  resetPasswordService,
  createPasswordService,
  createNewPasswordService,
  submitMFACodeService,
  getTOTPTokenService,
  verifyTOTPTokenService,
  submitMfaTypeService
} from '../services/cognito'
import { getCurrentUserProfile, setSelectedLanguage } from '../actions/app'

import { setLocalStorage } from '../../helpers/storage'
import { redirectTo } from '../../helpers/url'
import { showToasts } from '../../helpers/toasts'
import { handleChangeLanguage } from '../../helpers/language'

import { selectedLanguageSelector } from '../selectors/app'

import { ROUTES } from '../../constants/routes'
import { TOAST_TYPE } from '../../constants/other'
import { DEFAULT_LANGUAGE } from '../../constants/selectLists/languagesList'

function* cognitoWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(SUBMIT_LOGIN, submitLoginWorker),
    takeEvery(SUBMIT_MFA_TYPE, submitMfaTypeWorker),
    takeEvery(SUBMIT_MFA_CODE, submitMFACodeWorker),
    takeEvery(CHECK_AUTH_STATUS, checkAuthWorker),
    takeEvery(SUBMIT_LOGOUT, submitLogoutWorker),
    takeEvery(CREATE_PASSWORD, createPasswordWorker),
    takeEvery(CREATE_NEW_PASSWORD, createNewPasswordWorker),
    takeEvery(RESET_PASSWORD, resetPasswordWorker),
    takeEvery(GET_TOTP_TOKEN, getTOTPTokenWorker),
    takeEvery(VERIFY_TOTP_TOKEN, verifyTOTPTokenWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* submitLoginWorker({ userCredentials }) {
  try {
    const response = yield call(signInService, userCredentials)

    if (response.isSignedIn) {
      yield put(submitLoginSuccess(response))
      yield put(getCurrentUserProfile())
    } else {
      if (response.nextStep) {
        yield put(submitLoginMFARequiredSuccess(response.nextStep.signInStep, response.nextStep))
      }
    }
  } catch (e) {
    // code is added as key for parsing errors inside the ProgressButton
    yield put(submitLoginFailure({ ...e, code: 'cognito_error' }))
  }
}

function* submitMfaTypeWorker({ mfaType }) {
  try {
    const response = yield call(submitMfaTypeService, mfaType)

    yield put(submitMfaTypeSuccess())
    // clear data here as on level form it is not calling as MFA TYPE Form replaced with
    // other and it doesn't call onSuccess callback
    yield put(clearSubmitMfaType())

    if (response.isSignedIn) {
      yield put(submitLoginSuccess(response))
      yield put(getCurrentUserProfile())
    } else {
      if (response.nextStep) {
        yield put(submitLoginMFARequiredSuccess(response.nextStep.signInStep, response.nextStep))
      }
    }
  } catch (e) {
    // code is added as key for parsing errors inside the ProgressButton
    yield put(submitMfaTypeFailure({ ...e, code: 'cognito_error' }))
  }
}

function* submitMFACodeWorker({ code }) {
  try {
    const response = yield call(submitMFACodeService, code)

    yield put(submitMFACodeSuccess(response))
    yield put(getCurrentUserProfile())
  } catch (e) {
    // code is added as key for parsing errors inside the ProgressButton
    yield put(submitMFACodeFailure({ ...e, code: 'cognito_error' }))
  }
}

function* checkAuthWorker() {
  try {
    const response = yield call(getCurrentUser)
    if (response?.userId) {
      yield put(checkAuthStatusSuccess(response))
      yield put(getCurrentUserProfile())
    }
  } catch (e) {
    // save the destination URL which could be used for redirecting after Login
    // save full url with params to avoid missing opened forms or modals
    // this localStorage property used in Routes and is cleaned up after redirection
    setLocalStorage('landingUrl', window.location.pathname + window.location.search)
    // code is added as key for parsing errors inside the ProgressButton
    yield put(checkAuthStatusFailure({ ...e, code: 'cognito_error' }))
  }
}

function* submitLogoutWorker() {
  try {
    const response = yield call(signOutService)

    const selectedLanguage = yield select(selectedLanguageSelector)
    if (selectedLanguage !== DEFAULT_LANGUAGE) {
      // set default(english) language for not authorized pages
      handleChangeLanguage(DEFAULT_LANGUAGE)
      yield put(setSelectedLanguage(DEFAULT_LANGUAGE))
    }

    yield put(submitLogoutSuccess(response))
  } catch (e) {
    // code is added as key for parsing errors inside the ProgressButton
    yield put(submitLogoutFailure({ ...e, code: 'cognito_error' }))
  }
}

function* createPasswordWorker({ data }) {
  try {
    // in current App version we don't have a way to create a user so the code below is not tested after aws-apmlify
    // was updated and all code was refactored due to new version
    const response = yield call(createPasswordService, data)
    yield put(createPasswordSuccess(response))

    if (response.signInUserSession) {
      yield put(submitLoginSuccess(response))
      yield put(getCurrentUserProfile())
    }
  } catch (e) {
    // code is added as key for parsing errors inside the ProgressButton
    yield put(createPasswordFailure({ ...e, code: 'cognito_error' }))
  }
}

function* createNewPasswordWorker({ data }) {
  try {
    yield call(createNewPasswordService, data)
    yield put(createNewPasswordSuccess({ wasCreated: true }))

    showToasts({
      type: TOAST_TYPE.success,
      message: 'Password was created, please login.'
    })
    redirectTo(ROUTES.login)
  } catch (e) {
    // code is added as key for parsing errors inside the ProgressButton
    yield put(createNewPasswordFailure({ ...e, code: 'cognito_error' }))
  }
}

function* resetPasswordWorker({ email, showSuccessToast }) {
  try {
    const response = yield call(resetPasswordService, email)

    yield put(resetPasswordSuccess(response))

    if (showSuccessToast) {
      showToasts({
        type: TOAST_TYPE.success,
        message: t('The reset password email has been resent. Please check your junk folder.')
      })
    }
  } catch (e) {
    // code is added as key for parsing errors inside the ProgressButton
    yield put(resetPasswordFailure({ ...e, code: 'cognito_error' }))
  }
}

function* getTOTPTokenWorker() {
  try {
    const response = yield call(getTOTPTokenService)
    yield put(getTOTPTokenSuccess(response))
  } catch (e) {
    yield put(getTOTPTokenFailure({ ...e, code: 'cognito_error' }))
  }
}

function* verifyTOTPTokenWorker({ code }) {
  try {
    yield call(verifyTOTPTokenService, code)
    yield put(verifyTOTPTokenSuccess())
  } catch (e) {
    yield put(verifyTOTPTokenFailure({ ...e, code: 'cognito_error' }))
  }
}

export default cognitoWatcher
