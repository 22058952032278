import { call, put, all, takeEvery } from 'redux-saga/effects'

import {
  GET_LOCATION_LISTS,
  getLocationListsSuccess,
  getLocationListsFailure,
  GET_LOCATION_LIST,
  getLocationListSuccess,
  getLocationListFailure,
  CREATE_LOCATION_LIST,
  createLocationListSuccess,
  createLocationListFailure,
  UPDATE_LOCATION_LIST,
  updateLocationListSuccess,
  updateLocationListFailure,
  DELETE_LOCATION_LIST,
  deleteLocationListSuccess,
  deleteLocationListFailure,
  VALIDATE_LOCATION_ADDRESSES,
  validateLocationAddressesSuccess,
  validateLocationAddressesFailure,
  clearValidateLocationAddresses
} from '../actions/location'
import {
  getLocationListsService,
  getLocationListService,
  createLocationListService,
  updateLocationListService,
  deleteLocationListService,
  validateLocationAddressesService
} from '../services/location'
import { VALIDATION_ERROR_CODE } from '../../pages/Settings/ManageLocationLists/LocationListForms/fields'

function* locationWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_LOCATION_LISTS, getLocationListsWorker),
    takeEvery(GET_LOCATION_LIST, getLocationListWorker),
    takeEvery(CREATE_LOCATION_LIST, createLocationListWorker),
    takeEvery(UPDATE_LOCATION_LIST, updateLocationListWorker),
    takeEvery(DELETE_LOCATION_LIST, deleteLocationListWorker),
    takeEvery(VALIDATE_LOCATION_ADDRESSES, validateLocationAddressesWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getLocationListsWorker({ params }) {
  try {
    const response = yield call(getLocationListsService, params)
    yield put(getLocationListsSuccess(response))
  } catch (e) {
    yield put(getLocationListsFailure(e))
  }
}

function* getLocationListWorker({ id }) {
  try {
    const response = yield call(getLocationListService, id)
    yield put(getLocationListSuccess(response))
  } catch (e) {
    yield put(getLocationListFailure(e))
  }
}

function* createLocationListWorker({ locationListData }) {
  try {
    const response = yield call(createLocationListService, locationListData)
    // we need to clear validate addresses before createLocationListSuccess to prevent bug with double request
    yield put(clearValidateLocationAddresses())
    yield put(createLocationListSuccess(response))
  } catch (e) {
    yield put(createLocationListFailure(e))

    // clear addresses validation redux state to prevent running create request when editing fields after error
    yield put(clearValidateLocationAddresses())
  }
}

function* updateLocationListWorker({ locationListData, id }) {
  try {
    const response = yield call(updateLocationListService, locationListData, id)
    // we need to clear validate addresses before updateLocationListSuccess to prevent bug with double request
    yield put(clearValidateLocationAddresses())
    yield put(updateLocationListSuccess(response))
  } catch (e) {
    yield put(updateLocationListFailure(e))

    // clear addresses validation redux state to prevent running update request when editing fields after error
    yield put(clearValidateLocationAddresses())
  }
}

function* deleteLocationListWorker({ id }) {
  try {
    yield call(deleteLocationListService, id)
    yield put(deleteLocationListSuccess(id))
  } catch (e) {
    yield put(deleteLocationListFailure(e))
  }
}

function* validateLocationAddressesWorker({ locationAddressesData }) {
  try {
    const response = yield call(validateLocationAddressesService, locationAddressesData)

    const isValidationErrorsExist = response.find(address => address.error)
    if (isValidationErrorsExist) {
      // simulate BE error if validated addresses have address with error
      yield put(validateLocationAddressesFailure({ code: VALIDATION_ERROR_CODE }, response))
    } else {
      yield put(validateLocationAddressesSuccess(response))
    }
  } catch (e) {
    yield put(validateLocationAddressesFailure(e))
  }
}

export default locationWatcher
