import React from 'react'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import ClientAccountWithMarginsCreateForm from './ClientAccountWithMarginsCreateForm'

import { CLIENT_ACCOUNT_WITH_MARGINS_CREATE } from '../../../../../constants/forms'

const ClientAccountWithMarginsCreate = ({ controllerId }) => {
  return (
    <FormDrawerWrapper
      openButtonText="New Account"
      formName={CLIENT_ACCOUNT_WITH_MARGINS_CREATE}
      hasDefaultDrawerContentSpacing={false}
      isWideDrawer
    >
      <ClientAccountWithMarginsCreateForm controllerId={controllerId} />
    </FormDrawerWrapper>
  )
}

export default ClientAccountWithMarginsCreate
