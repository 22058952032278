import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import BrandPageCreateFormContent from '../../BrandPageCreate/BrandPageCreateForm/BrandPageCreateFormContent'
import Form from '../../../../../../components/Form'

import { isEqual } from '../../../../../../helpers/common'

import {
  updateCategoryPageErrorSelector,
  updateCategoryPageIsLoadingSelector,
  updateCategoryPageWasUpdatedSelector
} from '../../../../../../modules/selectors/mediaOrders'
import {
  clearUpdateCategoryPage,
  updateCategoryPage,
  updateCategoryPageSuccess
} from '../../../../../../modules/actions/mediaOrders'
import { selectedSelfAccountSelector } from '../../../../../../modules/selectors/app'

import { CATEGORY_PAGE_EDIT } from '../../../../../../constants/forms'

import { getInitialValues } from './fields'
import { transformValuesToBE, validationSchema } from '../../fields'

const BrandPageEditForm = ({ brandPage }) => {
  const dispatch = useDispatch()

  const categoryPageWasUpdated = useSelector(updateCategoryPageWasUpdatedSelector)
  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)

  const initialValues = useMemo(() => getInitialValues(brandPage), [brandPage])

  const clearUpdateCategoryPageHandler = useCallback(() => {
    dispatch(clearUpdateCategoryPage())
  }, [dispatch])

  const onSubmit = useCallback(
    values => {
      const hasChanges = !isEqual(values, initialValues)

      const transformedValues = transformValuesToBE(values)

      const data = {
        ...transformedValues,
        account: selectedSelfAccount.id
      }

      if (hasChanges) {
        dispatch(updateCategoryPage(brandPage?.id, data))
      } else {
        dispatch(updateCategoryPageSuccess(data))
      }
    },
    [dispatch, selectedSelfAccount.id, brandPage?.id, initialValues]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  })

  return (
    <Form
      formName={CATEGORY_PAGE_EDIT}
      formik={formik}
      successSubmit={categoryPageWasUpdated}
      errorSelector={updateCategoryPageErrorSelector}
      isLoadingSelector={updateCategoryPageIsLoadingSelector}
      clearHandler={clearUpdateCategoryPageHandler}
    >
      <BrandPageCreateFormContent formik={formik} />
    </Form>
  )
}

export default BrandPageEditForm
