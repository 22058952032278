import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import MultiSelectBox from '../../../../../../../../features/components/Form/MultiSelectBox'
import ErrorMessage from '../../../../../../../../components/Form/ErrorMessage'

import { getFormikFieldError } from '../../../../../../../../helpers/errors'
import { formatNumber } from '../../../../../../../../helpers/numbers'

import {
  getGoogleUsersListLoadingSelector,
  getGoogleUsersListSelector
} from '../../../../../../../../modules/selectors/googleConstants'

import { AUDIENCE } from '../../fields'

const UsersAudiencesSelect = ({ formik }) => {
  const { t } = useTranslation()

  const usersAudiencesList = useSelector(getGoogleUsersListSelector)
  const usersAudiencesIsLoading = useSelector(getGoogleUsersListLoadingSelector)

  const { values, setFieldValue, errors, status, touched } = formik
  const { error, serverError } = getFormikFieldError(errors, status, AUDIENCE)
  const audiencesTouched = touched[AUDIENCE]

  const formattedUsersAudiences = useMemo(
    () =>
      usersAudiencesList.map(({ name, resource_name: resourceName, size_for_display: sizeForDisplay }) => {
        return {
          value: resourceName,
          label: name,
          tag: formatNumber(sizeForDisplay)
        }
      }),
    [usersAudiencesList]
  )

  return (
    <>
      <MultiSelectBox
        placeholder={t('Select an audience')}
        options={formattedUsersAudiences}
        name={AUDIENCE}
        value={values[AUDIENCE]}
        isLoading={usersAudiencesIsLoading}
        setFieldValue={setFieldValue}
        isSearchable={false}
      />
      {audiencesTouched && (error || serverError) && <ErrorMessage error={error || serverError} />}
    </>
  )
}

export default UsersAudiencesSelect
