import * as Yup from 'yup'

export const FACEBOOK_PAGE_ID = 'page_id'

export const initialValues = {
  [FACEBOOK_PAGE_ID]: ''
}

export const validationSchema = Yup.object({
  [FACEBOOK_PAGE_ID]: Yup.number().required('This field is required')
})
