import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Field from '../../../../components/Form/Field'
import FieldRow from '../../../../features/components/Form/FieldsSection/FieldRow'

import { choicesCTAByGoalSelector } from '../../../../modules/selectors/choices'

import { formatOptionsList } from '../../../../features/formatters'

const CallToActionFields = ({ formik, fieldName, campaignObjective }) => {
  const actionsList = useSelector(choicesCTAByGoalSelector(campaignObjective))

  const formattedActionsList = useMemo(
    () =>
      formatOptionsList({
        list: actionsList,
        labelName: 'label',
        valueName: 'name'
      }),
    [actionsList]
  )

  return (
    <FieldRow title="Call to action" description="Select the button text">
      <Field
        placeholder="Call To Action"
        label="Call To Action"
        formik={formik}
        name={fieldName}
        options={formattedActionsList}
      />
    </FieldRow>
  )
}

CallToActionFields.propTypes = {
  formik: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  campaignObjective: PropTypes.string.isRequired
}

export default CallToActionFields
