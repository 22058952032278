import React from 'react'
import { useTranslation } from 'react-i18next'

import DatePicker from '../../../../../components/DatePicker'
import ErrorMessage from '../../../../../components/Form/ErrorMessage'

import { START_DATE, END_DATE } from '../CalendarEventForm/fields'

import { getFormikFieldError } from '../../../../../helpers/errors'

function CalendarCreateEventDates({ formik }) {
  const { t } = useTranslation()
  const { errors, status, setFieldValue, values, touched } = formik

  const dateStartError = getFormikFieldError(errors, status, START_DATE).error
  const dateEndError = getFormikFieldError(errors, status, END_DATE).error

  const handleStartDatePickerChange = value => {
    setFieldValue(START_DATE, value)
  }
  const handleEndDatePickerChange = value => {
    setFieldValue(END_DATE, value)
  }

  return (
    <>
      <div className="field">
        <DatePicker
          title={t('Start Date')}
          value={values[START_DATE]}
          changeHandler={handleStartDatePickerChange}
          isPastAllowed
        />
        {touched[START_DATE] && dateStartError && <ErrorMessage error={dateStartError} />}
      </div>
      <div className="field">
        <DatePicker
          title={t('End Date')}
          value={values[END_DATE]}
          minDate={values[START_DATE]}
          changeHandler={handleEndDatePickerChange}
        />
        {touched[END_DATE] && dateEndError && <ErrorMessage error={dateEndError} />}
      </div>
    </>
  )
}

export default CalendarCreateEventDates
