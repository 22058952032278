import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import CheckboxBlock from '../../../../../../../features/components/Form/CheckboxBlock'
import StartEndDateRangeFields from '../../../../../../ReusableFormFields/StartEndDateRangeFields'
import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'
import FieldsSection from '../../../../../../../features/components/Form/FieldsSection'
import Tabs from '../../../../../../../features/components/Tabs'
import PackageContentDropdown from './PackageContentDropdown'
import LocationsSelectPaginated from '../../../../../../../features/components/locationsFields/LocationsSelectPaginated'

import { getShowMediaDeliveryDatesQuestion } from './helpers'
import { getShowLocationFilterInCreateForm } from '../../helpers'

import { mediaProductLocationsWasLoadedSelector } from '../../../../../../../modules/selectors/mediaOrdersProductLocations'
import { clearGetMediaProductLocations } from '../../../../../../../modules/actions/mediaOrdersProductLocations'

import { DATE_END, DATE_START, MEDIA_CATEGORIES, MEDIA_PRODUCTS } from '../../../../fields'
import { FIXED_DELIVERY_DATES_CHECKBOX, MEDIA_ITEMS_OPTION } from '../../fields'

import useStyles from './styles'

const stepFields = [MEDIA_CATEGORIES, MEDIA_PRODUCTS, DATE_START, DATE_END]

const packageContentOptions = [
  { label: 'Categories', value: MEDIA_CATEGORIES },
  { label: 'Products', value: MEDIA_PRODUCTS }
]

function MediaItemsStep({ formik, handleStepChange }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const mediaProductLocationsWasLoaded = useSelector(mediaProductLocationsWasLoadedSelector)

  const { setFieldValue, values } = formik

  const showMediaDeliveryDatesQuestion = getShowMediaDeliveryDatesQuestion(values)

  const fixedDeliveryDatesCheckbox = values[FIXED_DELIVERY_DATES_CHECKBOX]

  const showLocationFilter = useMemo(() => getShowLocationFilterInCreateForm(values), [values])

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  useEffect(() => {
    // When we hide the media delivery dates question, we need to uncheck the fixed delivery dates checkbox
    if (!showMediaDeliveryDatesQuestion && fixedDeliveryDatesCheckbox) {
      setFieldValue(FIXED_DELIVERY_DATES_CHECKBOX, false)
    }
  }, [setFieldValue, showMediaDeliveryDatesQuestion, fixedDeliveryDatesCheckbox])

  useEffect(() => {
    return () => {
      if (mediaProductLocationsWasLoaded) {
        dispatch(clearGetMediaProductLocations())
      }
    }
  }, [mediaProductLocationsWasLoaded, dispatch])

  return (
    <Step
      stepTitle="Choose the media categories or products"
      stepDescription="Please select which categories or products this package will contain"
      handleContinue={handleContinue}
    >
      <FieldsSection title="Package contents">
        <FieldRow
          title="Categories or products"
          description="Select the categories or media products that this package will contain."
        >
          <div>
            <Tabs
              options={packageContentOptions}
              selectedValue={values[MEDIA_ITEMS_OPTION]}
              onSelectValue={value => setFieldValue(MEDIA_ITEMS_OPTION, value)}
              className={classes.packageContentTabs}
            />
            <PackageContentDropdown formik={formik} />
          </div>
        </FieldRow>
        {showLocationFilter && (
          <FieldRow
            title="Location filter"
            description="Automatically filter the products shown in this package by a location."
          >
            {showLocationFilter && <LocationsSelectPaginated formik={formik} isClearable />}
          </FieldRow>
        )}
        {showMediaDeliveryDatesQuestion && (
          <FieldRow
            title="Media delivery dates"
            description="If this box is unchecked, the advertiser can select the publication / delivery dates. If checked, you can specify fixed delivery dates."
          >
            <CheckboxBlock
              id="fixed_dates_checkbox"
              checked={!!values[FIXED_DELIVERY_DATES_CHECKBOX]}
              onCheck={() => setFieldValue(FIXED_DELIVERY_DATES_CHECKBOX, !values[FIXED_DELIVERY_DATES_CHECKBOX])}
              title="Fixed delivery dates"
            >
              <StartEndDateRangeFields formik={formik} startDateName={DATE_START} endDateName={DATE_END} />
            </CheckboxBlock>
          </FieldRow>
        )}
      </FieldsSection>
    </Step>
  )
}

export default MediaItemsStep
