import React, { Fragment, useCallback, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'

import TableWrapper from './UI/TableWrapper'
import UtilisationReport from './UtilisationReport'
import BookedRevenueBreakdown from './BookedRevenueBreakdown'
import BrandCategoryBreakdown from './BrandCategoryBreakdown'
import GraphTableSection from './UI/GraphTableSection'
import TableDataLoader from '../../../../../components/Table/TableDataLoader'
import SkeletonBookedRevenueByMonthGraph from './UI/graphs/Skeleton'
import { BrandCategoryRevenueDataProvider } from './BrandCategoryBreakdown/BreakdownTable/BrandCategoryRevenueContext'

import {
  selectedControllerIdSelector,
  selectedControllerLandingPageSetupSelector,
  getControllerLandingPageSetupIsLoadingSelector,
  selectedSelfAccountSelector
} from '../../../../../modules/selectors/app'
import {
  getBookedRevenueReportErrorSelector,
  getBookedRevenueReportWasLoadedSelector
} from '../../../../../modules/selectors/mediaOrdersBookings'
import { clearGetControllerLandingPageSetup, getControllerLandingPageSetup } from '../../../../../modules/actions/app'

const Dashboard = () => {
  const dispatch = useDispatch()

  const selfAccountId = useSelector(selectedSelfAccountSelector)
  const controllerId = useSelector(selectedControllerIdSelector)
  const controllerPageSetup = useSelector(selectedControllerLandingPageSetupSelector)
  const controllerLandingPageSetupIsLoading = useSelector(getControllerLandingPageSetupIsLoadingSelector)

  const activeSections = controllerPageSetup?.data?.controller_landing_page

  useEffect(() => {
    dispatch(getControllerLandingPageSetup({ controller: controllerId }))
  }, [dispatch, controllerId, selfAccountId])

  useEffect(
    () => () => {
      dispatch(clearGetControllerLandingPageSetup())
    },
    [dispatch]
  )

  const getGraphComponent = useCallback(section => {
    const sectionId = section.id

    switch (sectionId) {
      case 'booked_revenue_breakdown':
        return (
          <Fragment key={sectionId}>
            <BookedRevenueBreakdown setupData={section.data} />
          </Fragment>
        )
      case 'brand_category_breakdown':
        return (
          <Fragment key={sectionId}>
            <BrandCategoryRevenueDataProvider setupData={section.data}>
              <BrandCategoryBreakdown setupData={section.data} />
            </BrandCategoryRevenueDataProvider>
          </Fragment>
        )
      case 'utilisation':
        return (
          <Fragment key={sectionId}>
            <UtilisationReport setupData={section.data} />
          </Fragment>
        )
      default:
        return null
    }
  }, [])

  if (controllerLandingPageSetupIsLoading) {
    return (
      <GraphTableSection title={'skeletonPlaceholder'} titleIsLoading={true}>
        <SkeletonBookedRevenueByMonthGraph />
        <TableWrapper title={<Skeleton width={200} />}>
          <TableDataLoader
            errorSelector={getBookedRevenueReportErrorSelector}
            wasLoadedSelector={getBookedRevenueReportWasLoadedSelector}
            isLoadingSelector={getControllerLandingPageSetupIsLoadingSelector}
          />
        </TableWrapper>
      </GraphTableSection>
    )
  }

  return activeSections?.map(section => {
    return getGraphComponent(section)
  })
}

export default Dashboard
