import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

export const lineItemSelector = createSelector(
  ({ lineItems }) => lineItems.lineItem.data,
  data => data
)

export const lineItemLoadingSelector = createSelector(
  ({ lineItems }) => lineItems.lineItem.isLoading,
  isLoading => isLoading
)

export const lineItemErrorSelector = createSelector(
  ({ lineItems }) => lineItems.lineItem.error,
  error => error
)

export const lineItemWasLoadedSelector = createSelector(
  ({ lineItems }) => lineItems.lineItem.wasLoaded,
  wasLoaded => wasLoaded
)

export const lineItemsSelector = createSelector(
  ({ lineItems }) => lineItems.lineItems,
  // filter totals item and return real line items
  lineItems => lineItems.filter(item => !!item.id)
)

export const lineItemsTotalsSelector = createSelector(
  ({ lineItems }) => lineItems.lineItems,
  lineItems => lineItems.filter(item => !item.name && !item.id)[0]
)

export const lineItemsLoadingSelector = createSelector(
  ({ lineItems }) => lineItems.isLoading,
  isLoading => isLoading
)

export const lineItemsWasLoadedSelector = createSelector(
  ({ lineItems }) => lineItems.wasLoaded,
  wasLoaded => wasLoaded
)

export const lineItemsErrorSelector = createSelector(
  ({ lineItems }) => lineItems.error,
  error => error
)

export const lineItemCreatedDataSelector = createSelector(
  ({ lineItems }) => lineItems.createdLineItem.data,
  data => data
)

export const lineItemCreateIsLoadingSelector = createSelector(
  ({ lineItems }) => lineItems.createdLineItem.isLoading,
  isLoading => isLoading
)

export const lineItemCreateErrorSelector = createSelector(
  ({ lineItems }) => lineItems.createdLineItem.error,
  error => error
)

export const lineItemWasCreatedSelector = createSelector(
  ({ lineItems }) => lineItems.createdLineItem.wasCreated,
  wasCreated => wasCreated
)

export const lineItemDeleteSelector = createSelector(
  ({ lineItems }) => lineItems.deletedLineItem,
  deletedLineItem => deletedLineItem
)

export const lineItemDeleteIsLoadingSelector = createSelector(
  ({ lineItems }) => lineItems.deletedLineItem.isLoading,
  isLoading => isLoading
)

export const lineItemDeleteErrorSelector = createSelector(
  ({ lineItems }) => lineItems.deletedLineItem.error,
  error => error
)

export const lineItemUpdateSelector = createSelector(
  ({ lineItems }) => lineItems.updatedLineItem,
  updatedLineItem => updatedLineItem
)

export const lineItemUpdateIsLoadingSelector = createSelector(
  ({ lineItems }) => lineItems.updatedLineItem.isLoading,
  isLoading => isLoading
)

export const lineItemUpdateErrorSelector = createSelector(
  ({ lineItems }) => lineItems.updatedLineItem.error,
  error => error
)

export const lineItemWasUpdatedSelector = createSelector(
  ({ lineItems }) => lineItems.updatedLineItem.wasUpdated,
  wasUpdated => wasUpdated
)

export const locationsSelector = createSelector(
  ({ lineItems }) => lineItems.locations.results,
  results => results
)

export const locationsIsLoadingSelector = createSelector(
  ({ lineItems }) => lineItems.locations.isLoading,
  isLoading => isLoading
)

export const locationsWasLoadedSelector = createSelector(
  ({ lineItems }) => lineItems.locations.wasLoaded,
  wasLoaded => wasLoaded
)

export const goalsSelector = createSelector(
  ({ lineItems }) => lineItems.goals.results,
  results => results
)

export const goalsLoadingSelector = createSelector(
  ({ lineItems }) => lineItems.goals.isLoading,
  isLoading => isLoading
)

export const goalsWasLoadedSelector = createSelector(
  ({ lineItems }) => lineItems.goals.wasLoaded,
  wasLoaded => wasLoaded
)

export const goalsErrorSelector = createSelector(
  ({ lineItems }) => lineItems.goals.error,
  error => error
)
// duplicate lineItem
export const duplicateLineItemIsLoadingSelector = createSelector(
  ({ lineItems }) => lineItems.duplicateLineItem.isLoading,
  isLoading => isLoading
)

export const duplicateLineItemErrorSelector = createSelector(
  ({ lineItems }) => lineItems.duplicateLineItem.error,
  error => error
)

export const duplicateLineItemProcessWasStartedSelector = createSelector(
  ({ lineItems }) => lineItems.duplicateLineItem.duplicateProcessWasStarted,
  duplicateProcessWasStarted => duplicateProcessWasStarted
)

export const duplicateLineItemAsyncSessionIdSelector = createSelector(
  ({ lineItems }) => lineItems.duplicateLineItem.asyncSessionData.id,
  id => id
)

// get line item criterions
export const lineItemCriterionsSelector = createSelector(
  ({ lineItems }) => lineItems.lineItemCriterions.results,
  results => results
)

export const lineItemCriterionsIsLoadingSelector = createSelector(
  ({ lineItems }) => lineItems.lineItemCriterions.isLoading,
  isLoading => isLoading
)

export const lineItemCriterionsErrorSelector = createSelector(
  ({ lineItems }) => lineItems.lineItemCriterions.error,
  error => error
)

export const lineItemCriterionsWasLoadedSelector = createSelector(
  ({ lineItems }) => lineItems.lineItemCriterions.wasLoaded,
  wasLoaded => wasLoaded
)

// create line item criterions
export const createLineItemCriterionsDataSelector = createSelector(
  ({ lineItems }) => lineItems.createLineItemCriterions.data,
  data => data
)

export const createLineItemCriterionsIsLoadingSelector = createSelector(
  ({ lineItems }) => lineItems.createLineItemCriterions.isLoading,
  isLoading => isLoading
)

export const createLineItemCriterionsErrorSelector = createSelector(
  ({ lineItems }) => lineItems.createLineItemCriterions.error,
  error => error
)

export const createLineItemCriterionsWasCreatedSelector = createSelector(
  ({ lineItems }) => lineItems.createLineItemCriterions.wasCreated,
  wasCreated => wasCreated
)
