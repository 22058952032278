import React, { useCallback, useEffect, useState } from 'react'
import Page from '../index'
import { useDispatch } from 'react-redux'

import MfaSetupForms from './MfaSetupForms'
import MfaTypeSelection from './MfaTypeSelection'

import { getChoicesCountries } from '../../modules/actions/choices'
import { showMFASetupPage } from '../../modules/actions/app'

import useAuthStyles from '../../styles/common/authPages'

const MFASetupPage = () => {
  const dispatch = useDispatch()
  const authClasses = useAuthStyles()

  const [activeMFASetupType, setActiveMFASetupType] = useState(null)

  const handleSelectMFAType = useCallback(mfaType => {
    setActiveMFASetupType(mfaType)
  }, [])

  const handleSuccessMFASetup = useCallback(() => {
    dispatch(showMFASetupPage(false))
  }, [dispatch])

  useEffect(() => {
    dispatch(getChoicesCountries())
  }, [dispatch])

  return (
    <Page helmetTitle="helmetTitle.MFASetupPage" className={authClasses.authPage}>
      {activeMFASetupType ? (
        <MfaSetupForms
          onGoBack={() => setActiveMFASetupType(null)}
          onSuccessSetup={handleSuccessMFASetup}
          mfaType={activeMFASetupType}
        />
      ) : (
        <MfaTypeSelection handleSelectMFAType={handleSelectMFAType} />
      )}
    </Page>
  )
}

export default MFASetupPage
