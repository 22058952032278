import { createStyles } from '../../../../styles/helpers'
import { darkGrey } from '../../../../styles/const/colors'
import { phonesDown } from '../../../../styles/const/breakpoints'

const useStyles = createStyles({
  emptyDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: 24,
    paddingTop: 28
  },
  emptyDataText: {
    color: darkGrey,
    fontWeight: 600
  },
  filters: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    columnGap: 24,
    rowGap: 12
  },
  tableContainer: {
    marginTop: 16
  },

  [`@media screen and (${phonesDown})`]: {
    filters: {
      '& .dropdown': {
        width: '100%'
      }
    }
  }
})

export default useStyles
