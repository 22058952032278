import { createStyles } from '../../../../styles/helpers'

import { darkGrey, greyOutline, textGrey, blue } from '../../../../styles/const/colors'

const useStyles = createStyles({
  doubleLineHeader: {
    textAlign: 'right'
  },
  mobileHeaderWithOptionalDescription: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  bracketsText: {
    color: blue,
    marginLeft: 3
  },
  metricHeaderColumn: {
    justifyContent: 'flex-end'
  },
  errorMessage: {
    color: greyOutline,
    fontWeight: 600
  },
  totalsTitle: {
    color: textGrey
  },
  totalsValue: {
    color: textGrey,
    fontSize: 16,
    '& sub': {
      fontSize: 14
    }
  },
  noDataMessage: {
    display: 'block',
    textAlign: 'center',
    color: darkGrey,
    fontWeight: 600,
    paddingTop: 40
  },
  mobileGoalClassName: {
    marginRight: 0
  },
  mobileMinimisedSectionBtnWrapper: {
    top: -14
  },
  mobileMinimisedSectionHeader: {
    minHeight: 24
  }
})

export default useStyles
