import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'formik'
import PropTypes from 'prop-types'

import FieldRow from '../../../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../../../../../components/Form/Field'
import MultiSelectBox from '../../../../../../../../../../features/components/Form/MultiSelectBox'
import ErrorMessage from '../../../../../../../../../../components/Form/ErrorMessage'

import { formatOptionsList } from '../../../../../../../../../../features/formatters'

import {
  getMediaProductVariableOptionsIsLoadingSelector,
  getMediaProductVariablesBulkListIsLoadingSelector,
  mediaProductVariableOptionsSelector,
  mediaProductVariablesSelector
} from '../../../../../../../../../../modules/selectors/mediaOrdersProductVariables'
import { getMediaProductVariableOptions } from '../../../../../../../../../../modules/actions/mediaOrdersProductVariables'

import { CAN_SELECT_MULTIPLE, REQUIRED } from '../../../../../../../../MediaProductVariableForms/fields'
import { MULTIPLE_VARIABLE_OPTIONS, SINGLE_VARIABLE_OPTION } from '../../../../../../../fields'

import useStyles from './styles'

const VariableOptionsSelector = ({ formik, productItemPath, index, selectedVariableId, stepErrors, showError }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [prevSearchValue, setPrevSearchValue] = useState('')

  const mediaProductVariables = useSelector(mediaProductVariablesSelector)

  const mediaProductVariableOptions = useSelector(mediaProductVariableOptionsSelector(selectedVariableId))
  const mediaProductVariableOptionsIsLoading = useSelector(
    getMediaProductVariableOptionsIsLoadingSelector(selectedVariableId)
  )
  const getMediaProductVariablesBulkListIsLoading = useSelector(getMediaProductVariablesBulkListIsLoadingSelector)

  const variableOptionsIsLoading = useMemo(
    () => getMediaProductVariablesBulkListIsLoading || mediaProductVariableOptionsIsLoading,
    [getMediaProductVariablesBulkListIsLoading, mediaProductVariableOptionsIsLoading]
  )

  const selectedVariable = useMemo(
    () => mediaProductVariables.find(variable => variable.id === selectedVariableId) || {},
    [mediaProductVariables, selectedVariableId]
  )
  const isSelectedVariableRequired = selectedVariable?.required
  const canSelectMultipleOptions = selectedVariable?.[CAN_SELECT_MULTIPLE]
  const selectedVariableName = selectedVariable?.name

  const formattedVariableOptions = useMemo(() => {
    return formatOptionsList({
      list: mediaProductVariableOptions,
      labelName: 'value',
      valueName: 'id'
    })
  }, [mediaProductVariableOptions])

  const { setFieldValue, values } = formik

  const singleVariableError = getIn(stepErrors, `variables[${index}].${SINGLE_VARIABLE_OPTION}`)
  const singleVariableOption = getIn(values, `${productItemPath}.variables[${index}].${SINGLE_VARIABLE_OPTION}`)
  const variableIsRequired = getIn(values, `${productItemPath}.variables[${index}].${REQUIRED}`)

  const clearSingleVariableOptionHandler = useCallback(() => {
    setFieldValue(`${productItemPath}.variables[${index}].${SINGLE_VARIABLE_OPTION}`, '')
  }, [setFieldValue, productItemPath, index])

  const loadVariableOptionsHandler = useCallback(
    search => {
      // we use getMediaProductVariablesBulkList request to get initial options for all variables
      // therefore we don't need to fetch options here until the user starts searching
      if (search || prevSearchValue) {
        dispatch(
          getMediaProductVariableOptions(
            {
              media_product_variable: selectedVariableId,
              ...(search && { search }),
              // TODO: add pagination when select component with pagination will be implemented
              limit: 50
            },
            {
              shouldClearExistingState: true
            }
          )
        )
        setPrevSearchValue(search)
      }
    },
    [dispatch, selectedVariableId, prevSearchValue]
  )

  return (
    <FieldRow
      description={
        <div className={classes.description}>
          {selectedVariableName}
          {isSelectedVariableRequired && <span>*</span>}
        </div>
      }
    >
      {canSelectMultipleOptions ? (
        <MultiSelectBox
          setFieldValue={setFieldValue}
          name={`${productItemPath}.variables[${index}].${MULTIPLE_VARIABLE_OPTIONS}`}
          value={getIn(values, `${productItemPath}.variables[${index}].${MULTIPLE_VARIABLE_OPTIONS}`)}
          error={getIn(stepErrors, `variables[${index}].${MULTIPLE_VARIABLE_OPTIONS}`)}
          options={formattedVariableOptions}
          placeholder="Select options"
          touched={showError}
          onSearch={loadVariableOptionsHandler}
          isLoading={variableOptionsIsLoading}
        />
      ) : (
        <>
          <Field
            name={`${productItemPath}.variables[${index}].${SINGLE_VARIABLE_OPTION}`}
            options={formattedVariableOptions}
            formik={formik}
            placeholder="Select option"
            // show clear icon only if the option is selected and the variable is not required
            showClearInputIcon={!!singleVariableOption && !variableIsRequired}
            onClearInput={clearSingleVariableOptionHandler}
            isLoading={variableOptionsIsLoading}
          />
          {/*Error of this field is not managed via formik, so me need to represent the error manually*/}
          {showError && singleVariableError && <ErrorMessage error={singleVariableError} />}
        </>
      )}
    </FieldRow>
  )
}

VariableOptionsSelector.propTypes = {
  formik: PropTypes.object.isRequired,
  productItemPath: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  selectedVariableId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stepErrors: PropTypes.object.isRequired,
  showError: PropTypes.bool.isRequired
}

export default VariableOptionsSelector
