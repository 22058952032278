import React from 'react'

import PolicyLinks from './PolicyLinks'
import UnauthorizedPage from '../UnauthorizedPage'
import AcceptInviteForm from './AcceptInviteForms/AcceptInviteForm'
import AcceptAgencyInviteForm from './AcceptInviteForms/AcceptAgencyInviteForm'
import AcceptControllerInviteForm from './AcceptInviteForms/AcceptControllerInviteForm'

import { createJsonFromQueryString } from '../../../helpers/url'
import useDetermineUserCountry from '../../../features/hooks/useDetermineUserCountry'

import history from '../../../history'
import { AGENCY_INVITE, CONTROLLER_INVITE } from '../../../constants/other'

import useAuthStyles from '../../../styles/common/authPages'

// this page is basically combination of SignUp and CreatePassword
// it allows for user who received invite and doesn't have an account to create it within the password right away
function AcceptInvite() {
  const authClasses = useAuthStyles()

  // t param is used to determine which type of invite we have, e.g. i - user event, ai - agency invite
  const { token, t: inviteTypeQueryParam } = createJsonFromQueryString(history.location.search, false)

  const userCountry = useDetermineUserCountry()

  const renderAcceptInviteForm = () => {
    if (inviteTypeQueryParam === AGENCY_INVITE) {
      return <AcceptAgencyInviteForm token={token} userCountry={userCountry} />
    } else if (inviteTypeQueryParam === CONTROLLER_INVITE) {
      return <AcceptControllerInviteForm token={token} userCountry={userCountry} />
    } else {
      return <AcceptInviteForm token={token} userCountry={userCountry} />
    }
  }

  return (
    <UnauthorizedPage helmetTitle="helmetTitle.AcceptInvitePage" className={authClasses.authPage}>
      <div className={authClasses.formContainer}>
        <>
          <div className={authClasses.formContainer}>{renderAcceptInviteForm()}</div>
          <PolicyLinks />
        </>
      </div>
    </UnauthorizedPage>
  )
}

export default AcceptInvite
