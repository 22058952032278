import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import MediaFilesLibrary from '../../../MediaFilesLibrary'

import { getFacebookMediaImages, clearFacebookMediaImages } from '../../../../../modules/actions/mediaFiles'
import {
  facebookImagesAfterSelector,
  facebookImagesErrorSelector,
  facebookImagesIsLoadingSelector,
  facebookImagesIsNextSelector,
  facebookImagesWasLoadedSelector,
  facebookMediaImagesSelector
} from '../../../../../modules/selectors/mediaFiles'
import { selectedFacebookAdAccountIdSelector } from '../../../../../modules/selectors/app'

const FacebookImageFilesLibrary = ({ adAccountId, initialLoadSize = 12, ...props }) => {
  const dispatch = useDispatch()

  const selectedAdAccountId = useSelector(selectedFacebookAdAccountIdSelector)
  const afterImages = useSelector(facebookImagesAfterSelector)

  // when ad account is manually passed from outside, use it instead of selected
  const providedAdAccountId = adAccountId || selectedAdAccountId

  const loadInitialMediaHandler = useCallback(() => {
    dispatch(
      getFacebookMediaImages({
        account: providedAdAccountId,
        limit: initialLoadSize
      })
    )
  }, [dispatch, initialLoadSize, providedAdAccountId])

  const loadMoreHandler = useCallback(() => {
    dispatch(
      getFacebookMediaImages({
        account: providedAdAccountId,
        limit: initialLoadSize,
        after: afterImages
      })
    )
  }, [dispatch, initialLoadSize, providedAdAccountId, afterImages])

  const clearVideosHandler = useCallback(() => {
    dispatch(clearFacebookMediaImages())
  }, [dispatch])

  return (
    <MediaFilesLibrary
      type="image"
      mediaThumbnailFieldName="url_128"
      mediaFilesSelector={facebookMediaImagesSelector}
      mediaFilesIsLoadingSelector={facebookImagesIsLoadingSelector}
      mediaFilesErrorSelector={facebookImagesErrorSelector}
      mediaFilesWasLoadedSelector={facebookImagesWasLoadedSelector}
      mediaFilesNextSelector={facebookImagesIsNextSelector}
      loadInitialMediaHandler={loadInitialMediaHandler}
      loadMoreHandler={loadMoreHandler}
      clearMediaHandler={clearVideosHandler}
      initialLoadingSkeletonsNumber={initialLoadSize}
      {...props}
    />
  )
}

export default FacebookImageFilesLibrary
