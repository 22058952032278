import React, { useCallback } from 'react'
import { getIn } from 'formik'
import PropTypes from 'prop-types'

import SingleFileUpload from '../../../../../../forms/ReusableFormFields/SingleFileUpload'

import { MEDIA_KIT_FILE, MEDIA_KIT_FILE_NAME } from '../../fields'

const MediaFileKitUpload = ({ formik }) => {
  const { values, setValues } = formik

  const mediaKitFileUrl = getIn(values, MEDIA_KIT_FILE)
  const mediaKitFileName = getIn(values, MEDIA_KIT_FILE_NAME)

  const fileUploadHandler = useCallback(
    uploadedFile => {
      setValues({
        ...values,
        [MEDIA_KIT_FILE]: uploadedFile.url,
        [MEDIA_KIT_FILE_NAME]: uploadedFile.name
      })
    },
    [setValues, values]
  )

  const fileRemoveHandler = useCallback(() => {
    setValues({
      ...values,
      [MEDIA_KIT_FILE]: '',
      [MEDIA_KIT_FILE_NAME]: ''
    })
  }, [setValues, values])

  return (
    <SingleFileUpload
      fileURL={mediaKitFileUrl}
      fileName={mediaKitFileName}
      onFileUploaded={fileUploadHandler}
      onFileRemove={fileRemoveHandler}
      // accepted file types are image, pdf, xls, xlsx
      accept="image/png,image/jpeg, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      onPreviewClick={() => window.open(mediaKitFileUrl)}
    />
  )
}

MediaFileKitUpload.propTypes = {
  formik: PropTypes.object.isRequired
}

export default MediaFileKitUpload
