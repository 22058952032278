import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import TypeWriteAnimation from '../../../TypeWriteAnimation'
import SkeletonHeadlineText from './Skeleton'

import useStyles from './styles'

function HeadlineText({
  headlineText,
  showSkeletonPlaceholder,
  isInProgress,
  className,
  skeletonClassName,
  isCarousel,
  typingAnimation
}) {
  const classes = useStyles()

  if (!headlineText && !showSkeletonPlaceholder) {
    return null
  }

  if (!headlineText && showSkeletonPlaceholder) {
    return <SkeletonHeadlineText isInProgress={isInProgress} count={isCarousel ? 2 : 1} className={skeletonClassName} />
  }

  return (
    <div className={classnames(classes.headlineText, className)}>
      {typingAnimation ? <TypeWriteAnimation text={headlineText} delay={40} /> : headlineText}
    </div>
  )
}

HeadlineText.propTypes = {
  headlineText: PropTypes.string,
  isInProgress: PropTypes.bool,
  className: PropTypes.string,
  skeletonClassName: PropTypes.string,
  isCarousel: PropTypes.bool,
  typingAnimation: PropTypes.bool
}

export default HeadlineText
