import React from 'react'
import PdfPageSkeleton from '../../../../../../../features/components/Contract/ResponsiveContractPdfViewer/ContractPdfViewer/Skeleton/PdfPageSkeleton'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import ManageAmendmentContract from './ManageAmendmentContract'
import AmendmentContractSendForm from '../../../../AmendmentContractSendForm'

import {
  activeAmendmentContractSelector,
  createAmendmentErrorSelector,
  deleteAmendmentContractSignatureIsLoadingSelector
} from '../../../../../../../modules/selectors/amendments'

const CreatedContract = ({ onSuccessSubmit }) => {
  const { t } = useTranslation()

  const activeContract = useSelector(activeAmendmentContractSelector)
  const deleteContractSignatureIsLoading = useSelector(deleteAmendmentContractSignatureIsLoadingSelector)
  const createContractsError = useSelector(createAmendmentErrorSelector)

  const contractId = activeContract?.id
  const isContractSigned = !!activeContract?.controller_signature
  const isContractInvalid = activeContract?.status === 'invalid'

  if (deleteContractSignatureIsLoading) {
    return <PdfPageSkeleton />
  }

  if (isContractInvalid) {
    return (
      <p>
        {t(
          'Sorry, there was an error generating this contract. We are looking into this issue and will contact you with an update as soon as possible.'
        )}
      </p>
    )
  }

  if (createContractsError) {
    return null
  }

  return (
    <>
      <ManageAmendmentContract signType="controller" contract={activeContract} isContractSigned={isContractSigned} />
      {isContractSigned && (
        <AmendmentContractSendForm contractId={contractId} signType="controller" onSuccessSubmit={onSuccessSubmit} />
      )}
    </>
  )
}

export default CreatedContract
