import { createStyles } from '../../../../../../../styles/helpers'
import { smallPhonesDown } from '../../../../../../../styles/const/breakpoints'

const useStyles = createStyles({
  sectionContainer: {
    padding: '24px 24px 10px 24px',
    borderRadius: 6,
    marginTop: 0,
    width: 440
  },
  graphTitle: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 40
  },
  graphWrapper: {
    height: 195
  },
  [`@media screen and (${smallPhonesDown})`]: {
    sectionContainer: {
      padding: 10,
      width: 300
    }
  }
})

export default useStyles
