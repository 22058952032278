import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles({
  container: {
    minWidth: 120,
    padding: '10px 14px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 4
  },
  statusChip: {
    display: 'inline-block'
  }
})

export default useStyles
