import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { PLACEMENT_POSITIONS } from '../../../../../ReusableFormFields/LineItemForms/fields'

import { lineItemSelector } from '../../../../../../modules/selectors/lineItems'
import { campaignSelector } from '../../../../../../modules/selectors/campaigns'
import {
  getPlacementsList,
  placementPositionTitles
} from '../../../../../ReusableFormFields/LineItemForms/facebookLineItemPlacementsHelpers'

const PlacementSectionPreview = () => {
  const { t } = useTranslation()

  const lineItem = useSelector(lineItemSelector)
  const { objective: campaignObjective } = useSelector(campaignSelector)

  const placements = getPlacementsList(campaignObjective)
  const activePlacements = placements.filter(({ disabled }) => disabled === false)

  const { [PLACEMENT_POSITIONS]: selectedPlacementPositions } = lineItem

  const placementPositions = selectedPlacementPositions?.length
    ? selectedPlacementPositions
    : activePlacements.map(({ name }) => name)

  return (
    <p>
      {t('Positions: ')}
      {placementPositions
        .map(position => {
          return t(placementPositionTitles[position])
        })
        .join(', ')}
    </p>
  )
}

export default PlacementSectionPreview
