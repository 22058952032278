import { useEffect, useRef } from 'react'

// cleanupCallback will be executed when the component using this hook is unmounted.
const useComponentWillUnmount = (cleanupCallback = () => {}) => {
  // useRef is used to store the callback function, ensuring the same reference is used
  const callbackRef = useRef(cleanupCallback)

  // Update the callbackRef with the latest cleanupCallback function.
  callbackRef.current = cleanupCallback

  useEffect(() => {
    // When the component unmounts, the cleanupCallback function stored in
    // callbackRef.current will be executed.
    return () => callbackRef.current()
  }, [])
}

export default useComponentWillUnmount
