import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

import HighlightedValueSelect from '../../../../../components/HighlightedValueSelect'
import AgencyContentSection from '../AgencyContentSection'
import AgencyMembersAndInvitesTable from '../../AgencyMembersAndInvitesTable'

import { formatOptionsList } from '../../../../../features/formatters'

import { agenciesSelector, agenciesWasLoadedSelector } from '../../../../../modules/selectors/agencies'
import { getAgencyInvites, getAgencyMembers } from '../../../../../modules/actions/agencyTeam'
import { clearGetAgencies, getAgencies } from '../../../../../modules/actions/agencies'
import { selectedSelfAccountDataSelector } from '../../../../../modules/selectors/app'

import useSettingsClasses from '../../../styles'
import useStyles from './styles'

const ControllerUserContent = ({ setMemberId, openModalHandler }) => {
  const dispatch = useDispatch()

  const settingsClasses = useSettingsClasses()
  const classes = useStyles()
  const { t } = useTranslation()

  const agencies = useSelector(agenciesSelector)
  const agenciesWasLoaded = useSelector(agenciesWasLoadedSelector)
  const { agency: selectedSelfAccountAgency } = useSelector(selectedSelfAccountDataSelector)

  const firstAgency = agencies?.[0]?.id

  const [selectedAgencyId, setSelectedAgencyId] = useState(selectedSelfAccountAgency)
  const [firstAgencyWasPreselected, setFirstAgencyWasPreselected] = useState(false)

  const selectedAgency = agencies.find(agency => agency.id === selectedAgencyId)
  const { name: agencyName } = selectedAgency || {}

  const formattedAgencies = useMemo(() => {
    return formatOptionsList({
      list: agencies,
      valueName: 'id',
      labelName: 'name'
    })
  }, [agencies])

  const pageTitle = useMemo(() => {
    if (selectedAgencyId && agencyName) {
      return t('manageAgencyTeam', { agencyName })
    } else if (!agenciesWasLoaded) {
      return <Skeleton width={200} />
    } else {
      return t('Select an agency')
    }
  }, [t, selectedAgencyId, agencyName, agenciesWasLoaded])

  useEffect(() => {
    dispatch(getAgencies())
  }, [dispatch])

  useEffect(() => {
    // we don't need to use loadMore handlers for members and invites, just load all
    if (selectedAgencyId) {
      dispatch(getAgencyMembers({ agency: selectedAgencyId, limit: 999 }))
      dispatch(getAgencyInvites({ agency: selectedAgencyId, limit: 999 }))
    }
  }, [dispatch, selectedAgencyId])

  useEffect(() => {
    // if the selected self account has an agency, then preselect that. Otherwise, just select the first one
    if (!selectedAgencyId && firstAgency && !firstAgencyWasPreselected) {
      setSelectedAgencyId(firstAgency)
      setFirstAgencyWasPreselected(true)
    }
  }, [selectedAgencyId, firstAgency, firstAgencyWasPreselected])

  useEffect(() => {
    return () => {
      dispatch(clearGetAgencies())
    }
  }, [dispatch])

  return (
    <>
      <h1 className={settingsClasses.title}>{pageTitle}</h1>
      <AgencyContentSection agency={selectedAgencyId} setMemberId={setMemberId} openModalHandler={openModalHandler}>
        <HighlightedValueSelect
          className={classes.agencySelect}
          placeholder="Select agency"
          options={formattedAgencies}
          value={selectedAgencyId}
          onChange={agency => setSelectedAgencyId(agency?.value)}
          isSearchable={false}
          portaled
        />
        {selectedAgencyId && (
          <AgencyMembersAndInvitesTable setMemberId={setMemberId} openModalHandler={openModalHandler} />
        )}
      </AgencyContentSection>
    </>
  )
}

ControllerUserContent.propTypes = {
  setMemberId: PropTypes.func.isRequired,
  openModalHandler: PropTypes.func.isRequired
}

export default ControllerUserContent
