import { createStyles } from '../../styles/helpers'

import { zIndex1, zIndex10, zIndex9 } from '../../styles/const/common'
import { phonesDown, tabletDown } from '../../styles/const/breakpoints'
import { hideVisibleScrollbar } from '../../styles/common'
import { drawerContentMobilePadding, drawerContentPadding } from '../../styles/common/drawerForms'

const mobileHeaderHeight = 48

const useStyles = createStyles({
  closeBtn: {
    zIndex: zIndex1,
    position: 'absolute',
    top: 14,
    right: 14,
    width: 30,
    height: 30,
    backgroundColor: 'transparent',
    border: {
      radius: '50%'
    }
  },
  minimize: {
    zIndex: zIndex1,
    position: 'absolute',
    top: 50,
    right: 14,
    width: 20,
    height: 20,
    backgroundColor: 'transparent',
    border: {
      radius: '50%'
    }
  },
  content: {
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: zIndex10,
    width: ({ isMinimized }) => (isMinimized ? 0 : '100%'),
    maxWidth: ({ isWideDrawer }) => (isWideDrawer ? 900 : 580),
    height: '100%',
    overflowY: 'auto',
    background: 'white',
    transform: ({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)'),
    transition: 'width .3s ease-out, transform .3s ease-out',

    // hide visible scrollbar
    extend: hideVisibleScrollbar
  },
  body: {
    position: 'relative',
    margin: '0 auto',
    minHeight: '100%',
    width: '100%',
    maxWidth: ({ isWideDrawer }) => (isWideDrawer ? 'none' : 580),
    padding: ({ hasDefaultDrawerContentSpacing }) => (hasDefaultDrawerContentSpacing ? drawerContentPadding : 0)
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: zIndex10,
    width: '100vw',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, .5)',
    transform: ({ isOpen, isMinimized }) => (isOpen && !isMinimized ? 'translateX(0)' : 'translateX(100%)'),
    transition: 'opacity .3s ease-out',
    opacity: ({ isOpen, isMinimized }) => (isOpen && !isMinimized ? 1 : 0)
  },

  [`@media screen and (${tabletDown})`]: {
    content: {
      width: '70%',
      maxWidth: () => 580
    }
  },
  [`@media screen and (${phonesDown})`]: {
    content: {
      width: '100%',
      maxWidth: () => '100%',
      // padding top is needed to prevent content from being hidden under the mobile header
      paddingTop: mobileHeaderHeight,
      zIndex: zIndex9
    },
    closeBtn: {
      // make sure close button is visible on mobile under the header
      top: 14 + mobileHeaderHeight,
      right: 14
    },
    backdrop: {
      zIndex: zIndex9
    },
    body: {
      padding: () => drawerContentMobilePadding,
      height: 'auto'
    }
  }
})

export default useStyles
