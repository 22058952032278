import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import CalendarEditRowForm from './CalendarEditRowForm'
import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'

import useManageFormsDrawer from '../../../../../hooks/formHooks/useManageFormsDrawer'

import { PLANNER_EVENT_GROUP_EDIT } from '../../../../../constants/forms'

// row is the same as event_group
function CalendarEditRow({ eventGroups = [] }) {
  const { t } = useTranslation()

  const { selectedEditItemId } = useManageFormsDrawer({
    formName: PLANNER_EVENT_GROUP_EDIT
  })

  const editItemData = eventGroups.find(eventGroup => eventGroup.id === parseInt(selectedEditItemId)) || {}

  return (
    <FormDrawerWrapper
      title={t('editRow', { itemName: editItemData.name })}
      subTitle={t('Edit Row')}
      formName={PLANNER_EVENT_GROUP_EDIT}
      showOpenButton={false}
    >
      <CalendarEditRowForm editItemData={editItemData} selectedEditItemId={selectedEditItemId} />
    </FormDrawerWrapper>
  )
}

CalendarEditRow.propTypes = {
  eventGroups: PropTypes.array
}
export default CalendarEditRow
