import { concat } from '../../helpers/common'

const MODULE_NAME = 'TRIGGERS/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const SET_LINE_ITEM_TRIGGERS_FILTER = concat(MODULE_NAME, 'SET_LINE_ITEM_TRIGGERS_FILTER')
export const CLEAR_LINE_ITEM_TRIGGERS_FILTER = concat(MODULE_NAME, 'CLEAR_LINE_ITEM_TRIGGERS_FILTER')

export const GET_TRIGGERS = concat(MODULE_NAME, 'GET_TRIGGERS')
export const GET_TRIGGERS_SUCCESS = concat(MODULE_NAME, 'GET_TRIGGERS_SUCCESS')
export const GET_TRIGGERS_FAILURE = concat(MODULE_NAME, 'GET_TRIGGERS_FAILURE')
export const CLEAR_TRIGGERS = concat(MODULE_NAME, 'CLEAR_TRIGGERS')

export const CREATE_TRIGGER = concat(MODULE_NAME, 'CREATE_TRIGGER')
export const CREATE_TRIGGER_SUCCESS = concat(MODULE_NAME, 'CREATE_TRIGGER_SUCCESS')
export const CREATE_TRIGGER_FAILURE = concat(MODULE_NAME, 'CREATE_TRIGGER_FAILURE')
export const CLEAR_CREATE_TRIGGER = concat(MODULE_NAME, 'CLEAR_CREATE_TRIGGER')

export const UPDATE_TRIGGER = concat(MODULE_NAME, 'UPDATE_TRIGGER')
export const UPDATE_TRIGGER_SUCCESS = concat(MODULE_NAME, 'UPDATE_TRIGGER_SUCCESS')
export const UPDATE_TRIGGER_FAILURE = concat(MODULE_NAME, 'UPDATE_TRIGGER_FAILURE')
export const CLEAR_UPDATE_TRIGGER = concat(MODULE_NAME, 'CLEAR_UPDATE_TRIGGER')

export const DELETE_TRIGGER = concat(MODULE_NAME, 'DELETE_TRIGGER')
export const DELETE_TRIGGER_SUCCESS = concat(MODULE_NAME, 'DELETE_TRIGGER_SUCCESS')
export const DELETE_TRIGGER_FAILURE = concat(MODULE_NAME, 'DELETE_TRIGGER_FAILURE')
export const CLEAR_DELETE_TRIGGER = concat(MODULE_NAME, 'CLEAR_DELETE_TRIGGER')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

export function setLineItemTriggersFilter(lineItemId, lineItemName) {
  return { type: SET_LINE_ITEM_TRIGGERS_FILTER, lineItemId, lineItemName }
}

export function clearLineItemTriggersFilter() {
  return { type: CLEAR_LINE_ITEM_TRIGGERS_FILTER }
}

export function getTriggers(params) {
  return { type: GET_TRIGGERS, params }
}

export function getTriggersSuccess(triggersData) {
  return { type: GET_TRIGGERS_SUCCESS, triggersData }
}

export function getTriggersFailure(error) {
  return { type: GET_TRIGGERS_FAILURE, error }
}

export function clearTriggers() {
  return { type: CLEAR_TRIGGERS }
}

export function createTrigger(triggerData) {
  return { type: CREATE_TRIGGER, triggerData }
}

export function createTriggerSuccess(triggerData) {
  return { type: CREATE_TRIGGER_SUCCESS, triggerData }
}

export function createTriggerFailure(error) {
  return { type: CREATE_TRIGGER_FAILURE, error }
}

export function clearCreateTrigger() {
  return { type: CLEAR_CREATE_TRIGGER }
}

export function updateTrigger(id, triggerData) {
  return { type: UPDATE_TRIGGER, id, triggerData }
}

export function updateTriggerSuccess(triggerData) {
  return { type: UPDATE_TRIGGER_SUCCESS, triggerData }
}

export function updateTriggerFailure(error) {
  return { type: UPDATE_TRIGGER_FAILURE, error }
}

export function clearUpdateTrigger() {
  return { type: CLEAR_UPDATE_TRIGGER }
}

export function deleteTrigger(id) {
  return { type: DELETE_TRIGGER, id }
}

export function deleteTriggerSuccess(id) {
  return { type: DELETE_TRIGGER_SUCCESS, id }
}

export function deleteTriggerFailure(error) {
  return { type: DELETE_TRIGGER_FAILURE, error }
}

export function clearDeleteTrigger() {
  return { type: CLEAR_DELETE_TRIGGER }
}
