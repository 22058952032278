import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  filtersRow: {
    display: 'flex',
    gap: 12,
    maxWidth: '85%',
    marginLeft: 'auto',
    marginBottom: 14
  },
  rightFiltersRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 12,
    flexWrap: 'wrap',
    marginLeft: 'auto'
  }
})

export default useStyles
