import { useTranslation } from 'react-i18next'
import React from 'react'

import PageSection from '../../../../../features/components/PageSection'

import useStyles from './styles'

const SectionWrapper = ({ children, title, className }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <PageSection className={className}>
      <h2 className={classes.pageWrapperTitle}>{t(title)}</h2>
      {children}
    </PageSection>
  )
}

export default SectionWrapper
