import { ReactComponent as ShopLogo } from '../../../assets/logos/shopometry-new.svg'
import { ReactComponent as AduhuLogo } from '../../../assets/logos/aduhu_logo.svg'
import { ReactComponent as CenomiLogo } from '../../../assets/domains/cenomispace/cenomi_logo.svg'
import { ReactComponent as OneviuLogo } from '../../../assets/domains/oneviu/oneviu_logo.svg'
import { ReactComponent as NexusLogo } from '../../../assets/domains/nexus/nexus_logo_top_menu.svg'

import { DOMAINS } from '../../../constants/selectLists/languagesList'

function AppLogo() {
  const currentAppUrl = window.location.href

  if (currentAppUrl.includes(DOMAINS.aduhu)) {
    return <AduhuLogo />
  } else if (currentAppUrl.includes(DOMAINS.cenomispace)) {
    return <CenomiLogo />
  } else if (currentAppUrl.includes(DOMAINS.oneviu)) {
    return <OneviuLogo />
  } else if (currentAppUrl.includes(DOMAINS.nexus)) {
    return <NexusLogo />
  } else {
    return <ShopLogo />
  }
}
export default AppLogo
