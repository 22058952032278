import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import RadioBlock from '../../../../../components/Form/RadioBlock'
import Field from '../../../../../components/Form/Field'
import Button from '../../../../../components/Button'

import { formatOptionsList } from '../../../../../features/formatters'

import { selectedPlatformSelector, selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'

import { getShortInfoCampaigns } from '../../../../../modules/actions/campaigns'
import {
  shortInfoCampaignsIsLoadingSelector,
  shortInfoCampaignsSelector
} from '../../../../../modules/selectors/campaigns'

import { CAMPAIGN_DIFFERENT, CAMPAIGN_OPTION, CAMPAIGN_SAME, SELECTED_CAMPAIGN } from '../fields'

import useStyles from '../../../../../styles/common/stepForms'

const stepFields = [SELECTED_CAMPAIGN]

function CampaignStep({ formik, handleStepChange }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const classes = useStyles()

  const { values, setFieldValue } = formik

  const selectedPlatform = useSelector(selectedPlatformSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const campaigns = useSelector(shortInfoCampaignsSelector)
  const isCampaignsLoading = useSelector(shortInfoCampaignsIsLoadingSelector)

  // Method localeCompare used for sorting without a case, accent, etc.
  const sortedCampaigns = useMemo(() => {
    return campaigns.sort((a, b) => a.name && b.name && a.name.localeCompare(b.name, { sensitivity: 'case' }))
  }, [campaigns])

  const formattedCampaigns = useMemo(() => {
    return formatOptionsList({
      list: sortedCampaigns,
      valueName: 'id',
      labelName: 'name'
    })
  }, [sortedCampaigns])

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  useEffect(() => {
    dispatch(
      getShortInfoCampaigns({
        account: selectedAdAccountId,
        platform: selectedPlatform
      })
    )
  }, [dispatch, selectedAdAccountId, selectedPlatform])

  return (
    <>
      <div className={classes.stepTitle}>{t('Do you want to keep the duplicate line item in the same campaign?')}</div>
      <div className={classes.stepBody}>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_same_campaign"
          name={CAMPAIGN_OPTION}
          label={t('Yes')}
          value={CAMPAIGN_SAME}
          selectedValue={values[CAMPAIGN_OPTION]}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_different_campaign"
          name={CAMPAIGN_OPTION}
          label={t('No, copy it to a different campaign')}
          value={CAMPAIGN_DIFFERENT}
          selectedValue={values[CAMPAIGN_OPTION]}
        >
          <Field
            placeholder="Select a Campaign"
            formik={formik}
            name={SELECTED_CAMPAIGN}
            options={formattedCampaigns}
            isLoading={isCampaignsLoading}
          />
        </RadioBlock>
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" className="dark" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default CampaignStep
