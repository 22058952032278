// both platforms constants
// NAME
export const NAME = 'name'

// OBJECTIVE
export const OBJECTIVE = 'objective'

// BUDGET
export const CAMPAIGN_BUDGET = 'campaign_budget'
export const BUDGET_OPTION = 'budget_option'
export const BUDGET_LIFETIME = 'budget_lifetime'
export const BUDGET_DAILY = 'budget_daily'
export const NO_BUDGET = 'no_budget'

// SPECIAL AD CATEGORIES
export const SPECIAL_AD_CATEGORIES = 'special_ad_categories'
export const SPECIAL_AD_CATEGORY_COUNTRY = 'special_ad_category_country'
export const CATEGORY_CREDIT = 'credit'
export const CATEGORY_EMPLOYMENT = 'employment'
export const CATEGORY_HOUSING = 'housing'
export const DO_NOT_ASK_AD_CATEGORIES_AGAIN = 'do_not_ask_ad_categories_again'

export const specialAdCategories = [CATEGORY_CREDIT, CATEGORY_EMPLOYMENT, CATEGORY_HOUSING]

export const specialAdCategoriesOptions = [
  {
    value: CATEGORY_CREDIT,
    label: 'Credit'
  },
  {
    value: CATEGORY_EMPLOYMENT,
    label: 'Employment'
  },
  {
    value: CATEGORY_HOUSING,
    label: 'Housing'
  }
]

export const hasCategorySelected = values => {
  return values[CATEGORY_CREDIT] || values[CATEGORY_EMPLOYMENT] || values[CATEGORY_HOUSING]
}

export const getUpdatedUserSettings = values => ({
  ...(values[DO_NOT_ASK_AD_CATEGORIES_AGAIN] && { [SPECIAL_AD_CATEGORIES]: values[SPECIAL_AD_CATEGORIES] })
})

// google ads campaign constants
// BUDGET
export const AMOUNT_MICROS = 'amount_micros'

// DATES
export const CAMPAIGN_DATES = 'campaign_dates'

// TARGET ROAS
export const TARGET_ROAS = 'target_roas'
export const MAXIMIZE_CONVERSION_VALUE = 'maximize_conversion_value'

// GEO TARGETING
export const LOCATIONS = 'locations'
export const CUSTOM_LOCATIONS = 'custom_locations'

// STATUS
export const STATUS = 'status'
export const STATUS_PAUSED = 'paused'
export const STATUS_ACTIVE = 'active'
