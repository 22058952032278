import { calc } from '../../../../helpers/numbers'

import { CUSTOM_PRODUCT_DISCOUNT, CUSTOM_PRODUCT_PRICE, ORIGINAL_PRODUCT_PRICE, QUANTITY } from './fields'
import {
  calculateDiscountedPrice,
  getDecimalDiscount
} from '../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/price'

export const getOriginalTotalPrice = productsValues => {
  return productsValues.reduce((acc, product) => {
    const originalPrice = product[ORIGINAL_PRODUCT_PRICE]
    const totalProductPrice = calc(originalPrice).mul(product[QUANTITY]).toDP(2).toNumber()

    return calc(acc).add(totalProductPrice).toDP(2).toNumber()
  }, 0)
}

export const getDiscountedTotalPrice = productsValues => {
  return productsValues.reduce((acc, product) => {
    const customPrice = product[CUSTOM_PRODUCT_PRICE]
    const discount = getDecimalDiscount(product[CUSTOM_PRODUCT_DISCOUNT])

    // the quantity is already multiplied by the original price so we pass 1
    const calculatedPrice = calculateDiscountedPrice(customPrice, 1, discount)

    return calc(acc).add(calculatedPrice).toDP(2).toNumber()
  }, 0)
}
