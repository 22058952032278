import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import Field from '../../../../../components/Form/Field'
import Form from '../../../../../components/Form'

import { updateTag, clearUpdateTag } from '../../../../../modules/actions/tags'
import {
  updateTagErrorSelector,
  updateTagIsLoadingSelector,
  updateTagWasUpdatedSelector
} from '../../../../../modules/selectors/tags'

import { EDIT_SELF_ACCOUNT_TAG } from '../../../../../constants/forms'

import { validationSchema } from './validation'
import { TAG, getInitialValues } from './fields'

function TagsEditForm({ editTagData }) {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const itemWasUpdated = useSelector(updateTagWasUpdatedSelector)

  const onSubmit = useCallback(
    values => {
      dispatch(updateTag(editTagData?.id, { title: values[TAG] }))
    },
    [dispatch, editTagData?.id]
  )

  const handleClearHandler = useCallback(() => {
    dispatch(clearUpdateTag())
  }, [dispatch])

  const initialValues = useMemo(() => {
    return getInitialValues({ editTagData })
  }, [editTagData])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  return (
    <Form
      formik={formik}
      formName={EDIT_SELF_ACCOUNT_TAG}
      successSubmit={itemWasUpdated}
      errorSelector={updateTagErrorSelector}
      isLoadingSelector={updateTagIsLoadingSelector}
      clearHandler={handleClearHandler}
    >
      <Field formik={formik} id={TAG} name={TAG} placeholder={t('Enter tag')} />
    </Form>
  )
}

export default TagsEditForm
