import { createStyles } from '../../../../styles/helpers'

import { drawerFormDescription } from '../../../../styles/common/drawerForms'

const useStyles = createStyles({
  container: {
    marginTop: 60
  },
  formDescription: {
    ...drawerFormDescription
  }
})

export default useStyles
