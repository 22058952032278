import store from '../../store'

import { contextSelfAccountSelector, selectedSelfAccountSelector } from '../../modules/selectors/app'
import { isAuthorizedSelector } from '../../modules/selectors/cognito'
import api from '../../modules/services/api'

import { handleRequestError } from './errorHandlers'

export function isInternalRequest(url) {
  // Determines if a given URL is for an internal request or an external service.
  const hasProtocolScheme = url.startsWith('http://') || url.startsWith('https://')

  // Absolute URLs to external services start with a protocol scheme (like `http://` or `https://`).
  // Relative URLs are used for internal requests and do not start with a protocol scheme.
  return !hasProtocolScheme
}

export async function madeRequest(
  method,
  { data, params = {}, skipTikTokIsSandbox, showErrorToast = true, ...otherProps }
) {
  // todo remove is_sandbox for test ad account when it's not needed
  // also clean all places where skipTikTokIsSandbox is uses
  const hasTiktokSandboxAdAccount =
    !skipTikTokIsSandbox &&
    (params.account === '7109089981687218177' ||
      (data && data.account === '7109089981687218177') ||
      !!data?.providers?.find(({ ad_account_id }) => ad_account_id === '7109089981687218177'))

  if (hasTiktokSandboxAdAccount) {
    params.is_sandbox = true
  }
  const storeState = store.getState()
  const isAuthorized = isAuthorizedSelector(storeState)

  // add parameter context_partnership_id on FE to all requests to our BE
  if (isAuthorized && isInternalRequest(otherProps.url)) {
    // don't add it to any third party services requests
    const selectedSelfAccount = selectedSelfAccountSelector(storeState)
    // there are cases where we use contextSelfAccountData instead of the Redux store selectedSelfAccount
    // the contextSelfAccountData is higher priority then the Redux store selectedSelfAccount
    const contextSelfAccount = contextSelfAccountSelector(storeState)

    params.context_self_account_id = contextSelfAccount || selectedSelfAccount
  }

  try {
    const response = await api({
      method,
      data,
      params,
      ...otherProps,
      // 90sec as BE requests timeout to 3rd party
      timeout: 90000,
      timeoutErrorMessage: 'There has been a timeout, please try again'
    })

    return response.data
  } catch (error) {
    handleRequestError(error, { data, method, otherProps, params, showErrorToast })
  }
}

export const getFormData = object =>
  Object.keys(object).reduce((formData, key) => {
    const fieldValue = object[key]
    if (Array.isArray(fieldValue)) {
      // when there is an array in the data object it should be passed as a new separate field in FormData
      // FormData accepting multiple fields with the same names
      // i.e. {image: [file,file]} fill be transformed to: "image: file,  image: file" in FormData
      fieldValue.forEach(value => {
        formData.append(key, value)
      })
    } else {
      formData.append(key, fieldValue)
    }
    return formData
  }, new FormData())
