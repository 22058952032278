import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import MediaCategoryForm from '../../MediaCategoryForm'

import {
  clearCreateMediaPackageCategory,
  createMediaPackageCategory
} from '../../../../../modules/actions/mediaPackages'
import {
  createMediaPackageCategoryErrorSelector,
  createMediaPackageCategoryIsLoadingSelector,
  createMediaPackageCategoryWasCreatedSelector,
  mediaPackageCategoriesSelector
} from '../../../../../modules/selectors/mediaPackages'
import { selectedSelfAccountDataSelector } from '../../../../../modules/selectors/app'

import { MEDIA_CATEGORY_CREATE } from '../../../../../constants/forms'

function MediaCategoryCreateForm() {
  const dispatch = useDispatch()

  const categories = useSelector(mediaPackageCategoriesSelector)
  const categoryWasCreated = useSelector(createMediaPackageCategoryWasCreatedSelector)
  const selectedSelfAccountData = useSelector(selectedSelfAccountDataSelector)

  const controllerId = selectedSelfAccountData.controller

  const onSubmit = useCallback(
    values => {
      const currentBiggestCategoryOrderNumber = categories.length ? Math.max(...categories.map(item => item.order)) : 0

      dispatch(
        createMediaPackageCategory({
          ...values,
          controller: controllerId,
          order: currentBiggestCategoryOrderNumber ? currentBiggestCategoryOrderNumber + 1 : 1
        })
      )
    },
    [dispatch, controllerId, categories]
  )

  const clearCreateCategoryHandler = useCallback(() => dispatch(clearCreateMediaPackageCategory()), [dispatch])
  return (
    <MediaCategoryForm
      onSubmit={onSubmit}
      formName={MEDIA_CATEGORY_CREATE}
      successSubmit={categoryWasCreated}
      clearHandler={clearCreateCategoryHandler}
      errorSelector={createMediaPackageCategoryErrorSelector}
      isLoadingSelector={createMediaPackageCategoryIsLoadingSelector}
      isCreateForm
    />
  )
}

export default MediaCategoryCreateForm
