import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import DrawerHeadline from '../../../../../components/Drawer/DrawerHeadline'
import Form from '../../../../../components/Form'
import CustomAudiencesFields from '../../../../ReusableFormFields/CustomAudiencesFields'

import { formatAudiencesToBE } from './formatters'
import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'

import {
  createDv360BulkTargetingErrorSelector,
  createDv360BulkTargetingIsLoadingSelector,
  dv360BulkTargetingWasCreatedSelector
} from '../../../../../modules/selectors/dv360Constants'
import { mediaOrderUploadCreativeDataSelector } from '../../../../../modules/selectors/mediaOrders'
import { selectedControllerDataSelector, selectedDv360AdAccountIdSelector } from '../../../../../modules/selectors/app'
import { clearCreateDv360BulkTargeting, createDv360BulkTargeting } from '../../../../../modules/actions/dv360Constants'
import { clearAudiences, getAudiences } from '../../../../../modules/actions/audiences'
import { audiencesLoadingSelector, audiencesSelector } from '../../../../../modules/selectors/audiences'

import {
  AUDIENCES_OPTION,
  CUSTOM_AUDIENCES_EXCLUSION_CHECKED
} from '../../../../ReusableFormFields/CustomAudiencesFields/fields'
import { OPTION_YES } from '../../../../../constants/forms'

import { initialValues, targetingTypes, validationSchema } from './fields'

import { DV_360_PLATFORM } from '../../../../../constants/selectLists/platformList'

import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

const AudiencesForm = ({ onSuccessSubmit }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const drawerFormClasses = useDrawerFormStyles()

  const selectedController = useSelector(selectedControllerDataSelector)

  const selectedControllerName = selectedController?.name || ''

  const [successSubmit, setSuccessSubmit] = useState(false)

  const audiences = useSelector(audiencesSelector)
  const audiencesIsLoading = useSelector(audiencesLoadingSelector)

  const formattedAudiences = useMemo(
    () =>
      audiences.map(({ id, name }) => ({
        value: id,
        label: name
      })),
    [audiences]
  )

  const dv360AdAccountId = useSelector(selectedDv360AdAccountIdSelector)

  const { lineItem: lineItemCreatedData } = useSelector(mediaOrderUploadCreativeDataSelector)
  const dv360BulkTargetingWasCreated = useSelector(dv360BulkTargetingWasCreatedSelector)

  const onSubmit = useCallback(
    values => {
      const { id: lineItemId } = lineItemCreatedData

      // If users don't select any audiences, we don't need to send request to BE to update targeting options
      // in this case we just set successSubmit to true, and switch to next form
      const shouldSendRequest = values[AUDIENCES_OPTION] === OPTION_YES || values[CUSTOM_AUDIENCES_EXCLUSION_CHECKED]

      if (shouldSendRequest) {
        const requests = [
          {
            targeting_type: targetingTypes.TARGETING_TYPE_AUDIENCE_GROUP,
            assigned_targeting_options: formatAudiencesToBE(values)
          }
        ]

        const updateData = {
          account: dv360AdAccountId,
          line_item_id: lineItemId,
          create_requests: requests
        }

        dispatch(createDv360BulkTargeting(updateData))
      } else {
        setSuccessSubmit(true)
      }
    },
    [dispatch, lineItemCreatedData, dv360AdAccountId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const clearHandler = useCallback(() => {
    dispatch(clearCreateDv360BulkTargeting())
    dispatch(clearAudiences())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      getAudiences(
        {
          account: dv360AdAccountId,
          filter_by_account_id: true,
          limit: 25
        },
        DV_360_PLATFORM
      )
    )
  }, [dispatch, dv360AdAccountId])

  useEffect(
    () => () => {
      dispatch(clearAudiences())
    },
    [dispatch]
  )

  return (
    <div className={drawerFormClasses.formContainer}>
      <DrawerHeadline
        activeStepNumber={2}
        customStepsLength={4}
        title="Select an audiences"
        description={t('targetShopperAudiencesDV360', { selectedControllerName })}
      />
      <Form
        formName="campaignFormContent"
        formik={purifiedFormik}
        submitText="Save and continue"
        successSubmit={dv360BulkTargetingWasCreated || successSubmit}
        onSuccessSubmit={onSuccessSubmit}
        errorSelector={createDv360BulkTargetingErrorSelector}
        isLoadingSelector={createDv360BulkTargetingIsLoadingSelector}
        clearHandler={clearHandler}
      >
        <CustomAudiencesFields
          formik={formik}
          audiencesIsLoading={audiencesIsLoading}
          formattedAudiences={formattedAudiences}
        />
      </Form>
    </div>
  )
}

export default AudiencesForm
