import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Drawer from '../../../../components/Drawer'
import EditSectionsList from '../../../../features/components/Forms/EditForm/EditSectionsList'
import NameSectionPreview from '../../../ReusableFormSections/NameSection/NameSectionPreview'
import NameSectionForm from './Sections/NameSection'
import AudienceSectionPreview from './Sections/AudienceSection/AudienceSectionPreview'
import AudienceSectionForm from './Sections/AudienceSection/AudienceSectionForm'

import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'
import useManageEditFormData from '../../../../hooks/formHooks/useManageEditFormData'

import {
  getLineItem,
  clearLineItem,
  getLineItemCriterions,
  clearLineItemCriterions
} from '../../../../modules/actions/lineItems'
import {
  lineItemSelector,
  lineItemLoadingSelector,
  lineItemWasLoadedSelector,
  lineItemCriterionsWasLoadedSelector
} from '../../../../modules/selectors/lineItems'
import { selectedAdAccountIdSelector } from '../../../../modules/selectors/app'

import { LINE_ITEM_GOOGLE_ADS_EDIT } from '../../../../constants/forms'
import { NAME } from './fields'
import { GOOGLE_PLATFORM } from '../../../../constants/selectLists/platformList'
import { AUDIENCE } from '../LineItemGoogleCreate/LineItemGoogleCreateForm/LineItemGoogleAudienceForm/fields'

// here defined initial open states for function LineItemGoogleEdit sections
const sectionsInitialOpenStates = {
  [NAME]: false,
  [AUDIENCE]: false
}

function LineItemGoogleEdit({ shouldBeCleared }) {
  const dispatch = useDispatch()

  const item = useSelector(lineItemSelector)
  const isItemLoading = useSelector(lineItemLoadingSelector)
  const itemWasLoaded = useSelector(lineItemWasLoadedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const lineItemCriterionsWasLoaded = useSelector(lineItemCriterionsWasLoadedSelector)

  // to avoid loading representation
  const isInitialDataLoading = isItemLoading || !itemWasLoaded || !lineItemCriterionsWasLoaded

  const getItemDataHandler = useCallback(
    selectedEditItemId => {
      dispatch(getLineItem({ id: selectedEditItemId, account: selectedAdAccountId }, GOOGLE_PLATFORM))
    },
    [dispatch, selectedAdAccountId]
  )

  const clearItemDataHandler = useCallback(() => {
    if (shouldBeCleared) {
      dispatch(clearLineItem())
    }

    dispatch(clearLineItemCriterions())
  }, [dispatch, shouldBeCleared])

  const {
    isFormOpen,
    handleFormClose,
    selectedEditItemId: adGroupId
  } = useManageFormsDrawer({
    formName: LINE_ITEM_GOOGLE_ADS_EDIT
  })

  useManageEditFormData({
    formName: LINE_ITEM_GOOGLE_ADS_EDIT,
    loadedDataId: item.id,
    getDataHandler: getItemDataHandler,
    clearDataHandler: clearItemDataHandler
  })

  // here defined all edit sections for LineItemGoogleEdit
  const editSections = useMemo(
    () => [
      {
        sectionName: NAME,
        title: 'Name',
        PreviewComponent: <NameSectionPreview dataSelector={lineItemSelector} />,
        FormComponent: <NameSectionForm />
      },
      {
        sectionName: AUDIENCE,
        title: 'Audience',
        PreviewComponent: <AudienceSectionPreview />,
        FormComponent: <AudienceSectionForm />
      }
    ],
    []
  )

  useEffect(() => {
    if (isFormOpen && adGroupId) {
      dispatch(getLineItemCriterions({ ad_group: adGroupId, account: selectedAdAccountId }))
    }
  }, [dispatch, adGroupId, isFormOpen, selectedAdAccountId])

  return (
    <Drawer title="Update the settings of your ad group" isOpen={isFormOpen} onClose={handleFormClose}>
      <EditSectionsList
        editSections={editSections}
        isEditDataLoading={isInitialDataLoading}
        editItemData={item}
        sectionsInitialOpenStates={sectionsInitialOpenStates}
      />
    </Drawer>
  )
}

export default LineItemGoogleEdit
