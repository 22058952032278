import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useLoadFilteredAndPaginatedProducts } from '../../../../../../../../features/hooks/useLoadFilteredAndPaginatedProducts'

import {
  mediaProductAttachedLocationsSelector,
  mediaProductAttachedLocationsWasLoadedSelector
} from '../../../../../../../../modules/selectors/mediaOrdersProductLocations'
import { PRODUCTS_FILTER } from '../../../../../../MediaPackageForms/MediaPackageCreate/MediaPackageCreateForm/fields'
import { PRODUCT_STATUS_ACTIVE } from '../../../../../../MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'
import { LOCATION } from '../../../../../../MediaProductForms/fields'

const productParams = {
  in_stock: true
}

export function useLoadProductsData({
  preDefinedProductsFilter,
  isStepActive,
  selectedMediaPackage,
  categoryName,
  currencyCode
}) {
  const locations = useSelector(mediaProductAttachedLocationsSelector)
  const locationsWasLoaded = useSelector(mediaProductAttachedLocationsWasLoadedSelector)

  const additionalRequestParams = useMemo(() => {
    return {
      // get only specific products:
      ...(preDefinedProductsFilter && { id: preDefinedProductsFilter }),
      ...productParams,
      currency_code: currencyCode,
      status: PRODUCT_STATUS_ACTIVE // filter by active only(but currently for amendments we will fetch all)
    }
  }, [preDefinedProductsFilter, currencyCode])

  const initialLocations = useMemo(() => {
    // useLoadFilteredAndPaginatedProducts hook manage location filter as array
    const locationId = selectedMediaPackage?.[PRODUCTS_FILTER]?.[categoryName]?.[LOCATION]
    return locationId ? [locationId] : null
  }, [selectedMediaPackage, categoryName])

  const availableLocations = useMemo(() => {
    return locationsWasLoaded ? locations : null
  }, [locationsWasLoaded, locations])

  const loadProps = useMemo(
    () => ({
      initialLocations,
      availableLocations,
      initialMediaCategory: categoryName, // fetch product by category as preDefinedProductsFilter are all products
      additionalRequestParams,
      allowFetch: isStepActive // there could be multiple ProductSelectStep components rendered for different
      // categories, so make sure the data fetched to the active only
    }),
    [categoryName, initialLocations, availableLocations, additionalRequestParams, isStepActive]
  )
  const { loadMoreProducts, filters } = useLoadFilteredAndPaginatedProducts(loadProps)

  return useMemo(
    () => ({
      loadMoreProducts,
      filters
    }),
    [loadMoreProducts, filters]
  )
}
