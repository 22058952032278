export const removeExistingCriterions = (initialData, finalData, comparisonName = 'value') => {
  // filter items which exist both in final and initial data
  return finalData.filter(
    finalDataCriterion =>
      !initialData.find(
        initialDataCriterion => initialDataCriterion[comparisonName] === finalDataCriterion[comparisonName]
      )
  )
}

export const getDeletedCriterionsArray = (initialData, finalData, comparisonName = 'value') => {
  // check if some criterions were deleted and return list of resourseName properties
  return initialData
    .filter(
      initialDataCriterion =>
        !finalData.find(
          finalDataCriterion => finalDataCriterion[comparisonName] === initialDataCriterion[comparisonName]
        )
    )
    .map(deletedCriterion => deletedCriterion.criterionResourceName)
}
