import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { formatOptionsList } from '../../../../../../features/formatters'

import { getChoicesCountries } from '../../../../../../modules/actions/choices'
import { choicesCountriesSelector } from '../../../../../../modules/selectors/choices'

import FieldsSection from '../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../components/Form/Field'
import RadioButton from '../../../../../../components/Form/RadioButton'
import MultiSelectBox from '../../../../../../features/components/Form/MultiSelectBox'
import BudgetTabs from '../../../../../ReusableFormFields/BudgetTabs'

import {
  NAME,
  OBJECTIVE,
  SPECIAL_AD_CATEGORIES,
  SPECIAL_AD_CATEGORY_COUNTRY,
  specialAdCategoriesOptions
} from '../../../../../ReusableFormFields/CampaignForms/fields'
import { optimizationGoalOptions } from '../fields'
import {
  AUTOMATIC,
  BIDDING_TYPE,
  COST_CAP,
  MANUAL,
  OUTCOME_AWARENESS,
  OUTCOME_SALES
} from '../../../../../../constants/campaigns'

const CampaignFacebookCreateFormContent = ({ formik }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const countries = useSelector(choicesCountriesSelector)

  const { values, errors, touched, setFieldValue } = formik

  const selectedBiddingType = values[BIDDING_TYPE]

  const selectedObjective = values[OBJECTIVE]

  const showBudget = selectedObjective !== OUTCOME_AWARENESS

  const showCostCap = selectedObjective === OUTCOME_SALES

  const formattedCountries = useMemo(
    () =>
      formatOptionsList({
        list: countries,
        labelName: 'name',
        valueName: 'id'
      }),
    [countries]
  )

  useEffect(() => {
    dispatch(getChoicesCountries())
  }, [dispatch])

  useEffect(() => {
    if (!values[SPECIAL_AD_CATEGORIES].length && values[SPECIAL_AD_CATEGORY_COUNTRY].length) {
      // Reset countries if special ad categories are removed
      setFieldValue(SPECIAL_AD_CATEGORY_COUNTRY, [])
    }
  }, [values, setFieldValue])

  return (
    <FieldsSection title="Campaign details">
      <FieldRow title="Name" description="Campaign name">
        <Field formik={formik} name={NAME} placeholder="Campaign Name" />
      </FieldRow>
      <FieldRow title="Optimisation goal" description="The goal of the campaign">
        <Field formik={formik} name={OBJECTIVE} placeholder="Optimisation goal" options={optimizationGoalOptions} />
      </FieldRow>

      {showBudget && (
        <FieldRow
          title="Budget"
          description="Set a lifetime budget (runs for the whole lifetime of the campaign) or a daily budget "
        >
          <BudgetTabs formik={formik} />
        </FieldRow>
      )}

      {showCostCap && (
        <FieldRow title="Cost cap approach" description="Select how the optimisation should work">
          <RadioButton
            id="bid_type_cost_cap"
            name={BIDDING_TYPE}
            value={COST_CAP}
            setFieldValue={setFieldValue}
            selectedValue={selectedBiddingType}
            checked={selectedBiddingType === COST_CAP}
            label={
              <p>
                {t('keepThe')} <strong>{t('average')}</strong> {t('costPerConversion')}
              </p>
            }
          />
          <RadioButton
            id="bid_type_manual"
            name={BIDDING_TYPE}
            value={MANUAL}
            setFieldValue={setFieldValue}
            selectedValue={selectedBiddingType}
            label={
              <p>
                {t('keepCostOf')} <strong>{t('every')}</strong> {t('conversionLimit')}
              </p>
            }
          />
          <RadioButton
            id="bid_type_automatic"
            name={BIDDING_TYPE}
            value={AUTOMATIC}
            setFieldValue={setFieldValue}
            selectedValue={selectedBiddingType}
            label={<p>{t('Spend all my budget with the lowest possible cost per conversion')}</p>}
          />
        </FieldRow>
      )}

      <FieldRow
        title="Special ad categories"
        description="If this campaign will advertise any special ad categories, select them here"
      >
        <MultiSelectBox
          placeholder="Special ad categories"
          setFieldValue={setFieldValue}
          name={SPECIAL_AD_CATEGORIES}
          value={values[SPECIAL_AD_CATEGORIES]}
          options={specialAdCategoriesOptions}
        />
      </FieldRow>

      {/*Show countries only if special ad categories are selected*/}
      {!!values[SPECIAL_AD_CATEGORIES].length && (
        <FieldRow title="Countries">
          <MultiSelectBox
            placeholder="Countries"
            setFieldValue={setFieldValue}
            name={SPECIAL_AD_CATEGORY_COUNTRY}
            value={values[SPECIAL_AD_CATEGORY_COUNTRY]}
            options={formattedCountries}
            error={errors[SPECIAL_AD_CATEGORY_COUNTRY]}
            touched={touched[SPECIAL_AD_CATEGORY_COUNTRY]}
          />
        </FieldRow>
      )}
    </FieldsSection>
  )
}

export default CampaignFacebookCreateFormContent
