import { createStyles } from '../../../../../styles/helpers'

import { transitionShort } from '../../../../../styles/const/common'
import { darkGrey, textGrey } from '../../../../../styles/const/colors'
import { textEllipsis, textEllipsisMultiline } from '../../../../../styles/mixins/text'
import { phonesDown } from '../../../../../styles/const/breakpoints'

const useStyles = createStyles({
  nameContainer: {
    width: '100%'
  },
  nameLink: {
    display: 'block',
    textDecoration: 'none',
    transition: transitionShort,
    cursor: 'pointer',
    '&.linkTouched, &:hover, &:active': {
      color: darkGrey
    }
  },
  nameText: {
    color: textGrey,
    fontWeight: 600,
    ...textEllipsis()
  },
  date: {
    color: darkGrey,
    fontSize: 12,
    marginTop: 2
  },

  [`@media screen and (${phonesDown})`]: {
    date: {
      marginTop: 5,
      color: textGrey
    },
    nameText: {
      whiteSpace: 'initial',
      ...textEllipsisMultiline(2)
    }
  }
})

export default useStyles
