import React, { useCallback, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import PropTypes from 'prop-types'

import ContractPdfViewer from './ContractPdfViewer'

function ResponsiveContractPdfViewer({ pdfFile, onLastPageClick, showPagesCount, onLoadSuccess }) {
  const [pdfWidth, setPdfWidth] = useState(0)

  const onResize = useCallback(({ width }) => {
    if (!!width) {
      setPdfWidth(width)
    }
  }, [])

  const { ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 100,
    onResize
  })

  return (
    <>
      {/*placeholder div to get the width of the parent container and pass to PDFViewer*/}
      <div style={{ width: '100%' }} ref={ref} />
      <ContractPdfViewer
        pdfFile={pdfFile}
        pdfWidth={pdfWidth}
        onLastPageClick={onLastPageClick}
        showPages={showPagesCount}
        onLoadSuccess={onLoadSuccess}
      />
    </>
  )
}

ResponsiveContractPdfViewer.propTypes = {
  pdfFile: PropTypes.string,
  onLastPageClick: PropTypes.func,
  showPagesCount: PropTypes.bool,
  onLoadSuccess: PropTypes.func
}

export default ResponsiveContractPdfViewer
