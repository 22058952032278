import update from 'immutability-helper'
import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_CHOICES_GEOLOCATIONS,
  GET_CHOICES_GEOLOCATIONS_SUCCESS,
  GET_CHOICES_GEOLOCATIONS_FAILURE,
  GET_CHOICES_COUNTRIES,
  GET_CHOICES_COUNTRIES_SUCCESS,
  GET_CHOICES_COUNTRIES_FAILURE,
  CLEAR_CHOICES_COUNTRIES,
  GET_CHOICES_INTERESTS,
  GET_CHOICES_INTERESTS_FAILURE,
  GET_CHOICES_INTERESTS_SUCCESS,
  GET_CHOICES_CATEGORIES,
  GET_CHOICES_CATEGORIES_SUCCESS,
  GET_CHOICES_CATEGORIES_FAILURE,
  GET_CHOICES_LANGUAGES,
  GET_CHOICES_LANGUAGES_SUCCESS,
  GET_CHOICES_LANGUAGES_FAILURE,
  GET_CHOICES_PIXELS,
  GET_CHOICES_PIXELS_SUCCESS,
  GET_CHOICES_PIXELS_FAILURE,
  CLEAR_CHOICES_PIXELS,
  GET_CHOICES_CTA,
  GET_CHOICES_CTA_SUCCESS,
  GET_CHOICES_CTA_FAILURE,
  CLEAR_CHOICES_CTA,
  CLEAR_CHOICES_INTERESTS,
  GET_CHOICES_CTA_BY_GOAL,
  GET_CHOICES_CTA_BY_GOAL_SUCCESS,
  GET_CHOICES_CTA_BY_GOAL_FAILURE,
  CLEAR_CHOICES_CTA_BY_GOAL
} from '../actions/choices'
import { INITIAL_GET_RESULTS_REDUCER } from '../../constants/reducer'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  geolocations: {
    items: [],
    isLoading: false
  },
  countries: INITIAL_GET_RESULTS_REDUCER,
  interests: {
    items: [],
    isLoading: false
  },
  categories: {
    items: [],
    isLoading: false
  },
  languages: INITIAL_GET_RESULTS_REDUCER,
  pixels: INITIAL_GET_RESULTS_REDUCER,
  cta: INITIAL_GET_RESULTS_REDUCER,
  ctaByGoal: {}
}

export default function choices(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    case GET_CHOICES_GEOLOCATIONS:
      return update(state, {
        geolocations: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_CHOICES_GEOLOCATIONS_SUCCESS:
      return update(state, {
        geolocations: {
          $set: {
            isLoading: false,
            items: action.choicesData
          }
        }
      })
    case GET_CHOICES_GEOLOCATIONS_FAILURE:
      return update(state, {
        geolocations: {
          $set: initialState.geolocations
        }
      })
    case GET_CHOICES_COUNTRIES:
      return update(state, {
        countries: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_CHOICES_COUNTRIES_SUCCESS:
      return update(state, {
        countries: {
          $merge: {
            isLoading: false,
            results: action.choicesData,
            wasLoaded: true
          }
        }
      })
    case GET_CHOICES_COUNTRIES_FAILURE:
      return update(state, {
        countries: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CHOICES_COUNTRIES:
      return update(state, {
        countries: { $set: initialState.countries }
      })

    case GET_CHOICES_INTERESTS:
      return update(state, {
        interests: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_CHOICES_INTERESTS_SUCCESS:
      return update(state, {
        interests: {
          $set: {
            isLoading: false,
            items: action.choicesData
          }
        }
      })
    case GET_CHOICES_INTERESTS_FAILURE:
      return update(state, {
        interests: {
          $set: initialState.interests
        }
      })
    case CLEAR_CHOICES_INTERESTS:
      return update(state, {
        interests: { $set: initialState.interests }
      })

    case GET_CHOICES_CATEGORIES:
      return update(state, {
        categories: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_CHOICES_CATEGORIES_SUCCESS:
      return update(state, {
        categories: {
          $set: {
            isLoading: false,
            items: action.choicesData
          }
        }
      })
    case GET_CHOICES_CATEGORIES_FAILURE:
      return update(state, {
        categories: {
          $set: initialState.categories
        }
      })

    case GET_CHOICES_LANGUAGES:
      return update(state, {
        languages: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_CHOICES_LANGUAGES_SUCCESS:
      return update(state, {
        languages: {
          $merge: {
            isLoading: false,
            results: action.choicesData,
            wasLoaded: true
          }
        }
      })
    case GET_CHOICES_LANGUAGES_FAILURE:
      return update(state, {
        languages: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case GET_CHOICES_PIXELS:
      return update(state, {
        pixels: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_CHOICES_PIXELS_SUCCESS:
      return update(state, {
        pixels: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.choicesData
          }
        }
      })
    case GET_CHOICES_PIXELS_FAILURE:
      return update(state, {
        pixels: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CHOICES_PIXELS:
      return update(state, {
        pixels: {
          $set: initialState.pixels
        }
      })

    // call to actions
    case GET_CHOICES_CTA:
      return update(state, {
        cta: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_CHOICES_CTA_SUCCESS:
      return update(state, {
        cta: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.choicesData.results
          }
        }
      })
    case GET_CHOICES_CTA_FAILURE:
      return update(state, {
        cta: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CHOICES_CTA:
      return update(state, {
        cta: {
          $set: initialState.cta
        }
      })

    // call to actions by goal
    case GET_CHOICES_CTA_BY_GOAL:
      return update(state, {
        ctaByGoal: {
          $merge: {
            [action.params.goals]: {
              isLoading: true
            }
          }
        }
      })

    case GET_CHOICES_CTA_BY_GOAL_SUCCESS:
      return update(state, {
        ctaByGoal: {
          $merge: {
            [action.goal]: {
              isLoading: false,
              wasLoaded: true,
              results: action.choicesData.results
            }
          }
        }
      })
    case GET_CHOICES_CTA_BY_GOAL_FAILURE:
      return update(state, {
        ctaByGoal: {
          $merge: {
            [action.goal]: {
              isLoading: false,
              error: action.error
            }
          }
        }
      })

    case CLEAR_CHOICES_CTA_BY_GOAL:
      return update(state, {
        ctaByGoal: {
          $merge: {
            [action.goal]: undefined
          }
        }
      })

    default:
      return state
  }
}
