import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Can from '../../../../../../features/components/Can'
import GoogleAssetsSliderPreview from '../../../../../../forms/Google/AdForms/GoogleAssetsSliderPreview'
import InternalAdActionButtons from '../../../../InternalAdActionButtons'
import ActionsDropdown from '../../../../../../features/components/ActionsDropdown'
import AdCardDetails from '../../../../../../features/components/AdCardListItem/AdCardDetails'

import usePermissions from '../../../../../../hooks/usePermissions'

import { insertIf } from '../../../../../../helpers/common'
import { getAssetGroupTransformedValues } from '../../../../../../forms/Google/AdForms/GoogleAssetGroup/AssetGroupGoogleEdit/helpers'

import { openForm } from '../../../../../../modules/actions/forms'
import { approveInternalAssetGroup } from '../../../../../../modules/actions/assets'
import {
  approveInternalAssetGroupErrorSelector,
  approveInternalAssetGroupIsLoadingSelector,
  approveInternalAssetGroupSelector,
  approveInternalAssetGroupWasApprovedSelector
} from '../../../../../../modules/selectors/assets'
import { currentUserSelfAccountsListSelector } from '../../../../../../modules/selectors/app'

import { ASSET_GROUP_GOOGLE_ADS_EDIT } from '../../../../../../constants/forms'
import { GOOGLE_PROVIDER_PLATFORM } from '../../../../../../constants/selectLists/platformList'
import { INTERNAL_ADS_PERMISSION } from '../../../../../../constants/permissions'

import useStyles from '../../../../../../styles/common/adPreviewCard'

// this component currently support only ad approvals page style
const GoogleAssetGroupPreviewCard = ({ assetGroup, disapproveInternalAssetGroupHandler }) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const { id: assetGroupId, account_id: selfAccountId, name: assetGroupName } = assetGroup

  const permissions = usePermissions()

  const selfAccounts = useSelector(currentUserSelfAccountsListSelector)
  const adSelfAccountName = selfAccounts.find(({ id }) => Number(id) === Number(selfAccountId))?.name || ''

  const hasUpdateInternalAdsPermission = permissions.can('update', INTERNAL_ADS_PERMISSION)
  const hasManageInternalAdsPermission = permissions.can('manage', INTERNAL_ADS_PERMISSION)
  const transformedAssetGroupValues = useMemo(() => getAssetGroupTransformedValues(assetGroup), [assetGroup])

  const approveInternalAssetGroupHandler = useCallback(() => {
    dispatch(approveInternalAssetGroup(assetGroupId, GOOGLE_PROVIDER_PLATFORM))
  }, [dispatch, assetGroupId])

  const editInternalAssetGroupHandler = useCallback(() => {
    dispatch(
      openForm({
        id: assetGroupId,
        formName: ASSET_GROUP_GOOGLE_ADS_EDIT,
        otherParams: 'internal=true'
      })
    )
  }, [dispatch, assetGroupId])

  const actionsDropdownOptions = useMemo(() => {
    return [
      ...insertIf(hasManageInternalAdsPermission, {
        text: 'Approve',
        onClickHandler: approveInternalAssetGroupHandler
      }),
      ...insertIf(hasManageInternalAdsPermission, {
        text: 'Disapprove',
        onClickHandler: disapproveInternalAssetGroupHandler
      }),
      ...insertIf(hasUpdateInternalAdsPermission, {
        text: 'Edit',
        onClickHandler: editInternalAssetGroupHandler
      })
    ]
  }, [
    hasManageInternalAdsPermission,
    hasUpdateInternalAdsPermission,
    disapproveInternalAssetGroupHandler,
    approveInternalAssetGroupHandler,
    editInternalAssetGroupHandler
  ])

  return (
    <div className={classes.adPreviewCard}>
      {!!actionsDropdownOptions.length && (
        <ActionsDropdown
          itemId={assetGroup.id}
          options={actionsDropdownOptions}
          className={classes.actionsDropdownMenu}
          closeOnInsideClick
        />
      )}
      <div className={classes.cardSliderPreviewWrapper}>
        <GoogleAssetsSliderPreview values={transformedAssetGroupValues} />
      </div>
      <AdCardDetails title={assetGroupName} subTitle={adSelfAccountName} />
      <Can I="manage" a={INTERNAL_ADS_PERMISSION}>
        <InternalAdActionButtons
          className={classes.actionBtnsContainer}
          adId={assetGroupId}
          approveInternalAdSelector={approveInternalAssetGroupSelector}
          approveInternalAdIsLoadingSelector={approveInternalAssetGroupIsLoadingSelector}
          approveInternalAdErrorSelector={approveInternalAssetGroupErrorSelector}
          approveInternalAdWasApprovedSelector={approveInternalAssetGroupWasApprovedSelector}
          approveInternalAdHandler={approveInternalAssetGroupHandler}
          disapproveInternalAdHandler={disapproveInternalAssetGroupHandler}
        />
      </Can>
    </div>
  )
}

export default GoogleAssetGroupPreviewCard
