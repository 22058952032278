import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'

import Form from '../../../../components/Form'
import AmendmentContractSendFormContent from './AmendmentContractSendFormContent'

import { transformValuesToBE } from './formatters'

import { sendAmendmentContract, clearSendAmendmentContract } from '../../../../modules/actions/amendments'
import {
  sendAmendmentContractIsLoadingSelector,
  sendAmendmentContractErrorSelector,
  sendAmendmentContractWasSendSelector
} from '../../../../modules/selectors/amendments'

import { validationSchema } from './validation'
import { initialValues } from './fields'
import { AMENDMENT_CONTRACT_SEND } from '../../../../constants/forms'

function AmendmentContractSendForm({ className, onSuccessSubmit, contractId, signType }) {
  const dispatch = useDispatch()

  const amendmentWasSend = useSelector(sendAmendmentContractWasSendSelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values)
      dispatch(sendAmendmentContract(contractId, transformedData))
    },
    [dispatch, contractId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const clearItemSubmit = useCallback(() => dispatch(clearSendAmendmentContract()), [dispatch])

  return (
    <Form
      formik={formik}
      formName={AMENDMENT_CONTRACT_SEND}
      className={className}
      // processing
      successSubmit={amendmentWasSend}
      errorSelector={sendAmendmentContractErrorSelector}
      isLoadingSelector={sendAmendmentContractIsLoadingSelector}
      showSubmit={false}
    >
      <AmendmentContractSendFormContent
        formik={formik}
        contractId={contractId}
        signType={signType}
        onSuccessSubmit={onSuccessSubmit}
        clearItemSubmit={clearItemSubmit}
      />
    </Form>
  )
}

AmendmentContractSendForm.propTypes = {
  className: PropTypes.string,
  onSuccessSubmit: PropTypes.func,
  contractId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  signType: PropTypes.string
}

export default AmendmentContractSendForm
