import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { ReactComponent as MetaIcon } from '../../../../assets/logos/meta-icon.svg'
import { ReactComponent as GoogleIcon } from '../../../../assets/logos/google-icon.svg'
import { ReactComponent as TikTokIcon } from '../../../../assets/logos/tt-icon.svg'
import { ReactComponent as DV360Icon } from '../../../../assets/logos/dv360/dv360-display-ads-icon.svg'

import {
  DV_360_PLATFORM,
  FACEBOOK_PROVIDER_PLATFORM,
  GOOGLE_PROVIDER_PLATFORM,
  PLATFORMS_TO_PROVIDER,
  TIKTOK_PLATFORM
} from '../../../../constants/selectLists/platformList'

import useStyles from './styles'

const PlatformIcon = ({ provider: itemProvider, platform, className }) => {
  const classes = useStyles()
  const provider = itemProvider || PLATFORMS_TO_PROVIDER[platform]

  return (
    <div className={classnames(classes.platformIconWrapper, className)}>
      {provider === FACEBOOK_PROVIDER_PLATFORM && <MetaIcon className={classes.platformIcon} />}
      {provider === GOOGLE_PROVIDER_PLATFORM && <GoogleIcon className={classes.platformIcon} />}
      {provider === TIKTOK_PLATFORM && <TikTokIcon className={classnames(classes.platformIcon, classes.tikTokIcon)} />}
      {provider === DV_360_PLATFORM && <DV360Icon className={classes.platformIcon} />}
    </div>
  )
}

PlatformIcon.propTypes = {
  provider: PropTypes.string,
  platform: PropTypes.string
}

export default PlatformIcon
