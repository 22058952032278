import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import AdStoriesCarousel from './AdStoriesCarousel'

import Avatar from '../AdCard/Avatar'
import PageName from '../AdCard/PageName'
import ButtonIcon from '../../Buttons/ButtonIcon'
import AdCardControlsPanel from '../AdCard/ControlsPanel'
import { ReactComponent as DotsIcon } from '../../../../assets/icons/dots-menu.svg'

import { MEDIA_TYPE, STORIES_ITEMS } from '../../../../forms/Facebook/AdForms/fields'

import { adPreviewIsSoundMutedSelector } from '../../../../modules/selectors/ads'
import { toggleIsSoundMuted } from '../../../../modules/actions/ads'

import useStyles from './styles'

const AdStoriesCard = ({
  viewAdOnFacebook,
  formikValues,
  elementInProgress,
  fileUrl,
  handleLeftSideClick,
  handleRightSideClick,
  hideControlsPanel
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const storiesItems = formikValues[STORIES_ITEMS]

  const itemsCount = storiesItems.length

  const isSoundMuted = useSelector(adPreviewIsSoundMutedSelector)

  const showControlsPanel = useMemo(
    () => !hideControlsPanel && formikValues[STORIES_ITEMS]?.some(item => item[MEDIA_TYPE] === 'video'),
    [hideControlsPanel, formikValues]
  )

  const toggleIsSoundMutedHandler = useCallback(() => {
    dispatch(toggleIsSoundMuted())
  }, [dispatch])

  const classes = useStyles({ itemsCount })

  return (
    <>
      <div className={classes.adStoriesCard}>
        <div className={classes.adStoriesCardHeader}>
          <div className={classes.storyTimeline}>
            {storiesItems.map((item, index) => {
              return <div key={index} className={classes.timeline} />
            })}
          </div>
          <div className={classes.headerTop}>
            <div className={classes.avatar}>
              <Avatar formikValues={formikValues} />
            </div>
            <div className={classes.info}>
              <div className={classes.name}>
                <PageName formikValues={formikValues} elementInProgress={elementInProgress} />
              </div>
              <div className={classes.description}>{t('Sponsored')}</div>
            </div>
            <ButtonIcon Icon={DotsIcon} className={classes.dotsButton} />
          </div>
        </div>
        <AdStoriesCarousel
          viewAdOnFacebook={viewAdOnFacebook}
          formikValues={formikValues}
          elementInProgress={elementInProgress}
          fileUrl={fileUrl}
          handleLeftSideClick={handleLeftSideClick}
          handleRightSideClick={handleRightSideClick}
        />
      </div>
      {showControlsPanel && (
        <AdCardControlsPanel onMuteSoundToggle={toggleIsSoundMutedHandler} isSoundMuted={isSoundMuted} />
      )}
    </>
  )
}

AdStoriesCard.propTypes = {
  viewAdOnFacebook: PropTypes.string,
  formikValues: PropTypes.object,
  elementInProgress: PropTypes.string,
  fileUrl: PropTypes.string,
  handleLeftSideClick: PropTypes.func,
  handleRightSideClick: PropTypes.func,
  hideControlsPanel: PropTypes.bool
}

export default AdStoriesCard
