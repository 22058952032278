import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import RadioBlock from '../../../../../../components/Form/RadioBlock'
import ErrorMessage from '../../../../../../components/Form/ErrorMessage'
import EditForm from '../../../../../../features/components/Forms/EditForm'
import TikTokVideoUpload from '../../../AdTikTokCreate/AdTikTokCreateForm/Steps/VideoStep/TikTokVideoUpload'
import TikTokVideoFilesLibrary from '../../../AdTikTokCreate/AdTikTokCreateForm/Steps/VideoStep/TikTokVideoFilesLibrary'
import TikTokVideoThumbnailLibrary from '../../../AdTikTokCreate/AdTikTokCreateForm/Steps/VideoStep/TikTokVideoThumbnailLibrary'

import useEditAdPreview from '../../../../../../features/hooks/useEditAdPreview'
import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import { useEditSectionFormSubmit } from '../../../../../../hooks/formHooks/useEditSectionFormSubmit'

import { updateAd, clearUpdateAd } from '../../../../../../modules/actions/ads'
import { clearTikTokVideoThumbnails } from '../../../../../../modules/actions/tikTokConstants'
import {
  adUpdateIsLoadingSelector,
  adUpdateErrorSelector,
  adWasUpdatedSelector
} from '../../../../../../modules/selectors/ads'

import { formatValuesToFE, transformValuesToBE } from './formatters'
import { VIDEO } from '../../../fields'
import {
  VIDEO_FROM_LIBRARY_OPTION,
  VIDEO_OPTION,
  VIDEO_UPLOAD_OPTION
} from '../../../AdTikTokCreate/AdTikTokCreateForm/fields'
import { MEDIA_TYPE } from '../../../../../Google/AdForms/fields'
import {
  FILE_ID,
  FILE_URL,
  MEDIA_FILE_UPLOAD,
  MEDIA_FILE_VIDEO_USE_LIBRARY
} from '../../../../../ReusableFormFields/AdFileUpload/fields'
import { TIKTOK_PLATFORM } from '../../../../../../constants/selectLists/platformList'

import useStyles from './styles'

const VideoSectionForm = ({ editItemData: adData, ...formProps }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const classes = useStyles()
  const [previousVideoUrl, setPreviousVideoUrl] = useState('')

  const { account: adAccountId } = adData

  const itemWasUpdated = useSelector(adWasUpdatedSelector)

  const initialValues = useMemo(() => formatValuesToFE({}), [])

  const handleSubmit = useCallback(
    values => {
      const formattedUpdateItemData = transformValuesToBE(values)

      dispatch(updateAd({ ...adData, ...formattedUpdateItemData }, adData.id, 'PUT', TIKTOK_PLATFORM))
    },
    [dispatch, adData]
  )

  const { onSubmit, manualSuccessSubmit } = useEditSectionFormSubmit({ initialValues, handleSubmit })

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)
  const { setFieldValue, setValues, values, errors, touched } = purifiedFormik
  useEditAdPreview({ [VIDEO]: { url: values[FILE_URL] } })

  const onVideoRemoveHandler = useCallback(() => {
    dispatch(clearTikTokVideoThumbnails())

    setPreviousVideoUrl('')
  }, [dispatch])

  const handleClearUpdateItem = useCallback(() => {
    dispatch(clearUpdateAd())
  }, [dispatch])

  useEffect(() => {
    // after item was updated the initial values are not updating, and warning "Unsaved changes" is showing
    // so we need to reset values to initial values manually after item was updated
    if (itemWasUpdated) {
      dispatch(clearTikTokVideoThumbnails())
      setValues(initialValues)
    }
  }, [dispatch, itemWasUpdated, initialValues, setValues])

  const showSelectVideoError = errors[FILE_ID] && touched[FILE_ID]

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={itemWasUpdated || manualSuccessSubmit}
      clearEditItem={handleClearUpdateItem}
      errorSelector={adUpdateErrorSelector}
      isLoadingSelector={adUpdateIsLoadingSelector}
      {...formProps}
    >
      <p className={classes.sectionDescription}>{t('Upload a new video or choose one from the library.')}</p>
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_tiktok_video_upload"
        name={VIDEO_OPTION}
        value={VIDEO_UPLOAD_OPTION}
        selectedValue={values[VIDEO_OPTION]}
        label="Upload a file from my device"
      >
        <TikTokVideoUpload
          formik={formik}
          onVideoRemoveHandler={onVideoRemoveHandler}
          recommendedSize="1080x1920 px"
          adAccountId={adAccountId}
        />
        <TikTokVideoThumbnailLibrary
          previousVideoUrl={previousVideoUrl}
          setPreviousVideoUrl={setPreviousVideoUrl}
          allowShowingThumbnailsLibrary={values[MEDIA_TYPE] === MEDIA_FILE_UPLOAD}
          formik={formik}
        />
      </RadioBlock>
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_tiktok_video_from_library"
        name={VIDEO_OPTION}
        value={VIDEO_FROM_LIBRARY_OPTION}
        selectedValue={values[VIDEO_OPTION]}
        label="Use an existing video from the library"
      >
        <TikTokVideoFilesLibrary formik={formik} onVideoRemoveHandler={onVideoRemoveHandler} />
        {showSelectVideoError && <ErrorMessage error={errors[FILE_ID]} />}
        <TikTokVideoThumbnailLibrary
          previousVideoUrl={previousVideoUrl}
          setPreviousVideoUrl={setPreviousVideoUrl}
          allowShowingThumbnailsLibrary={values[MEDIA_TYPE] === MEDIA_FILE_VIDEO_USE_LIBRARY}
          formik={formik}
        />
      </RadioBlock>
    </EditForm>
  )
}

export default VideoSectionForm
