import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import ImagePlaceholder from '../../../../../../features/components/ImagePlaceholder'

import useHandleImageLoading from '../../../../../../hooks/useHandleImageLoading'

import { showToasts } from '../../../../../../helpers/toasts'

import { TOAST_TYPE } from '../../../../../../constants/other'

const ImageAssetPreview = ({ containerWidth, fileUrl, width, height, backgroundColor, arrowColor }) => {
  const { isMediaLoaded, isMediaFailed, handleMediaSuccessfullyLoaded, handleMediaFailed } =
    useHandleImageLoading(fileUrl)

  const displayImage = useMemo(() => {
    return isMediaLoaded ? 'block' : 'none'
  }, [isMediaLoaded])

  // In some cases (adBlocker, bad internet connection) getting image by url may fail,
  // In this case we show imagePlaceholder and show toast with info message
  useEffect(() => {
    if (isMediaFailed) {
      showToasts({
        type: TOAST_TYPE.error,
        message: 'Oops, something went wrong, please check your internet connection, turn off ad blocker, and try again'
      })
    }
  }, [isMediaFailed])

  if (!fileUrl) {
    return (
      <ImagePlaceholder
        containerWidth={containerWidth}
        width={width}
        height={height}
        backgroundColor={backgroundColor}
        arrowColor={arrowColor}
      />
    )
  }

  return (
    <>
      {isMediaFailed ? (
        <ImagePlaceholder
          containerWidth={containerWidth}
          width={width}
          height={height}
          isImageFailed
          backgroundColor={backgroundColor}
          arrowColor={arrowColor}
        />
      ) : (
        <img
          onLoad={handleMediaSuccessfullyLoaded}
          // If media is not loaded - we set 'display: none', and show ImagePlaceholder
          style={{ display: displayImage }}
          src={fileUrl}
          alt="Uploaded asset"
          onError={handleMediaFailed}
        />
      )}
    </>
  )
}

ImageAssetPreview.propTypes = {
  containerWidth: PropTypes.number,
  fileUrl: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string,
  arrowColor: PropTypes.string
}

export default ImageAssetPreview
