import { createStyles } from '../../../../../../styles/helpers'

const useStyles = createStyles({
  signatorySelect: {
    width: 262,
    margin: '16px auto 45px',
    textAlign: 'left'
  }
})

export default useStyles
