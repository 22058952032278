import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { getRandomizedValues } from '../helpers'

import Button from '../../../../../../components/Button'
import { ReactComponent as InfoIcon } from '../../../../../../assets/icons/info-circle.svg'

import { DESCRIPTIONS_LIST, HEADLINES_LIST } from '../../../fields'

import useStyles from './styles'

const GoogleAdPreviewDiscover2 = React.memo(({ values = {}, squareImageFileUlr, squareLogoFileUrl }) => {
  const { t } = useTranslation()

  const classes = useStyles()

  const randomizedValues = useMemo(() => {
    return getRandomizedValues(values)
  }, [values])

  const description = randomizedValues[DESCRIPTIONS_LIST]?.[0].text
  const headline = randomizedValues[HEADLINES_LIST]?.[0].text

  return (
    <div className={classes.container}>
      <div className={classes.adPreview}>
        <div className={classes.icon}>
          <InfoIcon />
        </div>
        <div className={classes.top}>
          <img src={squareImageFileUlr} alt="asset" />
        </div>
        <div className={classes.bottom}>
          <img className={classes.image} src={squareLogoFileUrl} alt="asset" />
          <p className={classes.headline}>{headline}</p>
          <p className={classes.description}>{description}</p>
          <div className={classes.buttons}>
            <Button className={classes.closeButton}>{t('Close')}</Button>
            <Button className={classes.openButton}>{t('Open')}</Button>
          </div>
        </div>
      </div>
    </div>
  )
})

export default GoogleAdPreviewDiscover2
