import { createStyles } from '../../../../../../../../styles/helpers'

import { lightGrey2 } from '../../../../../../../../styles/const/colors'

const useStyles = createStyles(theme => ({
  title: {
    marginBottom: '32px',
    color: '#000'
  },
  graphSection: {
    padding: '18px 24px',
    background: 'white',
    border: `1px solid ${lightGrey2}`,
    borderRadius: theme.defaultBorderRadius
  }
}))

export default useStyles
