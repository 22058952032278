import React from 'react'
import { useSelector } from 'react-redux'

import DatesFields from './DatesFields'
import BudgetFields from './BudgetFields'
import ErrorMessage from '../../../../../../../components/Form/ErrorMessage'

import { getNumberOfDays } from '../../../../../../../helpers/date'

import { selectedAdAccountsSelector } from '../../../../../../../modules/selectors/app'

import { END_DATE, START_DATE } from '../../../../../../ReusableFormFields/StartEndDateRangeFields/fields'
import { GOOGLE_PLATFORM } from '../../../../../../../constants/selectLists/platformList'

import useStyles from '../styles'

const NewCampaignFields = ({ formik, remainingBudget }) => {
  const classes = useStyles()

  const { [GOOGLE_PLATFORM]: adAccountData } = useSelector(selectedAdAccountsSelector)
  const { currency_symbol: currencySymbol } = adAccountData

  const { values } = formik

  const startDate = values[START_DATE]
  const endDate = values[END_DATE]

  const days = getNumberOfDays(startDate, endDate)

  const maxDailyBudget = remainingBudget / days

  const showBudgetSection = startDate && endDate

  if (remainingBudget < 1) {
    return (
      <ErrorMessage
        className={classes.remainingBudgetError}
        error={'Your total remaining budget is too low to create a campaign.'}
      />
    )
  }

  return (
    <>
      <DatesFields formik={formik} />
      {
        // Show budget fields only when user selected dateStart and dateStop,
        // because budget daily is based on dateRange
        showBudgetSection && (
          <BudgetFields
            currencySymbol={currencySymbol}
            formik={formik}
            remainingBudget={remainingBudget}
            maxDailyBudget={maxDailyBudget}
          />
        )
      }
    </>
  )
}

export default NewCampaignFields
