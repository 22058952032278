import update from 'immutability-helper'
import {
  campaignFiltersList,
  DATE_STATIC_PRESET,
  LIFETIME_DATE_PRESET
} from '../../constants/selectLists/listItemFiltersList'
import { getCookie } from '../../helpers/common'
import { getSavedDateRange } from '../../helpers/date'
import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  FILTER,
  DATE_RANGE,
  SORT,
  SET_LINE_ITEMS_SUMMARY_FILTER,
  SET_LINE_ITEMS_SUMMARY_DATE_RANGE,
  SET_LINE_ITEMS_SUMMARY_SORT,
  CLEAR_LINE_ITEMS_SUMMARY_FILTERS,
  GET_LINE_ITEMS_SUMMARY_REPORTS,
  GET_LINE_ITEMS_SUMMARY_REPORTS_SUCCESS,
  GET_LINE_ITEMS_SUMMARY_REPORTS_FAILURE,
  CLEAR_LINE_ITEMS_SUMMARY_REPORTS
} from '../actions/lineItemsSummary'

const savedDateRange = getSavedDateRange(DATE_RANGE)

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  filter: getCookie(FILTER) || campaignFiltersList[0].value,
  dateRange: savedDateRange || {
    [DATE_STATIC_PRESET]: LIFETIME_DATE_PRESET
  },
  sort: getCookie(SORT) || {},
  reportsLoading: false,
  reportsData: {
    graph_data: [],
    reports_data: []
  }
}

export default function lineItemsSummary(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    case SET_LINE_ITEMS_SUMMARY_FILTER:
      return update(state, {
        filter: { $set: action.filter }
      })
    case SET_LINE_ITEMS_SUMMARY_DATE_RANGE:
      return update(state, {
        dateRange: { $set: action.dateRange }
      })
    case SET_LINE_ITEMS_SUMMARY_SORT:
      return update(state, {
        sort: { $set: action.sort }
      })
    case CLEAR_LINE_ITEMS_SUMMARY_FILTERS:
      return update(state, {
        // back to initial values, set manually because cookie clearing can happen later
        filter: { $set: campaignFiltersList[0].value },
        dateRange: { $set: { [DATE_STATIC_PRESET]: LIFETIME_DATE_PRESET } },
        sort: { $set: {} }
      })
    case GET_LINE_ITEMS_SUMMARY_REPORTS:
      return update(state, {
        reportsLoading: { $set: true }
      })
    case GET_LINE_ITEMS_SUMMARY_REPORTS_SUCCESS:
      return update(state, {
        reportsLoading: { $set: false },
        reportsData: {
          $set: action.reportsData.reports_data ? action.reportsData : initialState.reportsData
        }
      })
    case GET_LINE_ITEMS_SUMMARY_REPORTS_FAILURE:
      return update(state, {
        reportsLoading: { $set: false }
      })
    case CLEAR_LINE_ITEMS_SUMMARY_REPORTS:
      return update(state, {
        reportsData: { $set: initialState.reportsData }
      })
    default:
      return state
  }
}
