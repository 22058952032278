import { createStyles } from '../../../styles/helpers'
import { lightGrey, darkChipGreen } from '../../../styles/const/colors'

const useAudiencesStyles = createStyles({
  audienceCard: {
    '&:before': {
      // If approximate_count_lower_bound  = 1000 then grey, otherwise green
      // (e.g. if 456 then green, if 1044 then green, if 1000 then grey)
      background: ({ lower } = {}) => (Number(lower) === 1000 ? lightGrey : darkChipGreen)
    }
  }
})

export default useAudiencesStyles
