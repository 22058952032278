import {
  ASSET_ID,
  BUSINESS_NAME,
  CLICK_THROUGH_LINK,
  DESCRIPTIONS_LIST,
  FILE_NAME,
  FILE_TYPE,
  FILE_URL,
  getFormattedImageAssetData,
  HEADLINES_LIST,
  LANDSCAPE_IMAGE,
  LANDSCAPE_LOGO,
  LONG_HEADLINES_LIST,
  SQUARE_IMAGE,
  SQUARE_LOGO
} from '../../fields'
import { CALL_TO_ACTION } from '../../../../ReusableFormFields/CallToActionFields/fields'

export const getAdBusinessName = ad => ad?.ad?.['responsive_display_ad']?.['business_name']
export const getAdCallToActionText = ad => ad?.ad?.['responsive_display_ad']?.['call_to_action_text']
export const getAdClickThroughLink = ad => ad?.ad?.[CLICK_THROUGH_LINK]?.[0]
export const getAdHeadlines = ad => ad?.ad?.['responsive_display_ad']?.headlines
export const getAdDescriptions = ad => ad?.ad?.['responsive_display_ad']?.descriptions
export const getAdLongHeadline = ad => ad?.ad?.['responsive_display_ad']?.['long_headline']
export const getAdLandscapeImageAssets = ad => ad?.ad?.['responsive_display_ad']?.['marketing_images']
export const getAdLandscapeLogoAssets = ad => ad?.ad?.['responsive_display_ad']?.['logo_images']
export const getAdSquareImageAssets = ad => ad?.ad?.['responsive_display_ad']?.['square_marketing_images']
export const getAdSquareLogoAssets = ad => ad?.ad?.['responsive_display_ad']?.['square_logo_images']

export const getAdImageAssetInitialValues = asset => ({
  [ASSET_ID]: asset.asset?.resource_name,
  [FILE_URL]: asset.asset?.image_asset?.['full_size']?.url,
  [FILE_NAME]: asset.asset?.name,
  [FILE_TYPE]: 'image'
})

const getFormattedTextFields = arr => {
  return arr
    ? arr.map(text => ({
        text
      }))
    : []
}

export const getAdGoogleTransformedValues = ad => {
  const landscapeImageAssets = getAdLandscapeImageAssets(ad)
  const landscapeLogoAssets = getAdLandscapeLogoAssets(ad)
  const squareImageAssets = getAdSquareImageAssets(ad)
  const squareLogoAssets = getAdSquareLogoAssets(ad)

  return {
    [BUSINESS_NAME]: getAdBusinessName(ad) || '',
    [CLICK_THROUGH_LINK]: getAdClickThroughLink(ad) || '',
    [HEADLINES_LIST]: getFormattedTextFields(getAdHeadlines(ad)),
    [DESCRIPTIONS_LIST]: getFormattedTextFields(getAdDescriptions(ad)),
    [LONG_HEADLINES_LIST]: getFormattedTextFields([getAdLongHeadline(ad)]),

    [LANDSCAPE_IMAGE]: landscapeImageAssets
      ? landscapeImageAssets.map(asset => getFormattedImageAssetData(asset.asset))
      : [],
    [SQUARE_IMAGE]: squareImageAssets ? squareImageAssets.map(asset => getFormattedImageAssetData(asset.asset)) : [],
    [SQUARE_LOGO]: squareLogoAssets ? squareLogoAssets.map(asset => getFormattedImageAssetData(asset.asset)) : [],
    [LANDSCAPE_LOGO]: landscapeLogoAssets
      ? landscapeLogoAssets.map(asset => getFormattedImageAssetData(asset.asset))
      : [],

    [CALL_TO_ACTION]: getAdCallToActionText(ad) || ''
  }
}
