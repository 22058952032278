import { endOfYear, startOfYear } from 'date-fns'

export const INITIAL_START_DATE = startOfYear(Date.now())
export const INITIAL_END_DATE = endOfYear(Date.now())

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const breakpoints = {
  mobile: {
    down: 767,
    up: 768
  }
}

export const ONE_SECOND = 1000
export const ONE_MINUTE = 60 * ONE_SECOND
export const ONE_HOUR = 60 * ONE_MINUTE
export const ONE_DAY = ONE_HOUR * 24
export const ONE_WEEK = ONE_DAY * 7

export const MIN_DISTANCE_BETWEEN_BARS = 2

export const EVENT_DATE_FORMAT = 'dd MMM'

export const SIDEBAR_WIDTH = 0.3 // width of first column
export const SIDEBAR_WIDTH_PERCENT = SIDEBAR_WIDTH * 100

export const TOOLTIP_LEFT_CLASS = 'so-tooltip-left' // class to display tooltip more to the left
export const TOOLTIP_RIGHT_CLASS = 'so-tooltip-right' // class to display tooltip more to the right
export const EDGE_BAR_MIN_WIDTH = 14 // when bar is on the left/right side - change tooltip styles. This variable represents the min visible width of the bar
export const TOOLTIP_MIN_GAP = 4 // distance from tooltip to left/right side of events area
export const EVENTS_AREA_PADDING = 1 // padding of events area
export const TOOLTIP__SHORT_BAR_WIDTH = 14 // change tooltip position if bar width is less than TOOLTIP__SHORT_BAR_WIDTH. See also TOOLTIP_TRANSLATE_FOR_SHORT_BAR
export const TOOLTIP_TRANSLATE_FOR_SHORT_BAR = -8 // how much to translate tooltip position for bars which are shorter than TOOLTIP__SHORT_BAR_WIDTH

export const EMPTY_CALENDAR = {
  id: 0,
  name: '',
  event_groups: [
    {
      id: 0,
      color: '#fff',
      type: 'placeholder',
      calendar: 0,
      name: '',
      order: 1,
      events: []
    }
  ]
}
