import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import UploadTikTokAdSuccessModal from './UploadTikTokAdSuccessModal'
import SocialAuthChecker from '../../../features/components/SocialAuthChecker'
import UploadTikTokAdCreativeContent, { CAMPAIGN_FORM } from './UploadTikTokAdCreativeContent'
import FormDrawerWrapper from '../../../features/components/FormDrawerWrapper'
import SkeletonForm from '../../../components/Form/Skeleton'

import useStepFormRoutes from '../../../hooks/formHooks/useStepFormRoutes'
import useUploadAdCreativeConstants from '../../Multiplatform/MediaOrderForms/UploadAdCreative/useUploadAdCreativeConstants'
import { useGetAuthFailureText } from '../../Multiplatform/UploadAdCreative/hooks'

import { clearMediaOrderUploadCreative } from '../../../modules/actions/mediaOrders'
import { selectedTikTokAdAccountIdSelector } from '../../../modules/selectors/app'

import { TIKTOK_PLATFORM } from '../../../constants/selectLists/platformList'
import { UPLOAD_TIKTOK_AD_CREATIVE } from '../../../constants/forms'

export function UploadTiktokAdCreative() {
  const dispatch = useDispatch()
  const [createdCreativeData, setCreatedCreativeData] = useState({})

  const { isInternalAdCreation } = useUploadAdCreativeConstants()
  const manageStepFormRoutes = useStepFormRoutes({ initialRoute: CAMPAIGN_FORM })
  const authFailureText = useGetAuthFailureText(TIKTOK_PLATFORM)

  const { isFormLoading } = useUploadAdCreativeConstants()

  const adAccountId = useSelector(selectedTikTokAdAccountIdSelector)

  const { handleFormClose, setSelectedRoute } = manageStepFormRoutes

  const onAfterFormCloseHandler = useCallback(() => {
    // reset routes state
    handleFormClose()
    setSelectedRoute(CAMPAIGN_FORM)

    dispatch(clearMediaOrderUploadCreative())
  }, [dispatch, handleFormClose, setSelectedRoute])

  return (
    <>
      <FormDrawerWrapper
        formName={UPLOAD_TIKTOK_AD_CREATIVE}
        closeOnSubmit={false}
        showOpenButton={false}
        isFormLoading={isFormLoading}
        SkeletonFormComponent={<SkeletonForm stepsLength={3} />}
        onAfterFormClose={onAfterFormCloseHandler}
        hasDefaultDrawerContentSpacing={false}
        isWideDrawer
      >
        <SocialAuthChecker platform={TIKTOK_PLATFORM} authFailureText={authFailureText}>
          <UploadTikTokAdCreativeContent
            adAccountId={adAccountId}
            setCreatedCreativeData={setCreatedCreativeData}
            manageStepFormRoutes={manageStepFormRoutes}
          />
        </SocialAuthChecker>
      </FormDrawerWrapper>
      <UploadTikTokAdSuccessModal
        createdCreativeData={createdCreativeData}
        isInternalAdCreation={isInternalAdCreation}
        setCreatedCreativeData={setCreatedCreativeData}
      />
    </>
  )
}
