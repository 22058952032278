import React from 'react'

import SocialAuthChecker from '../../../../features/components/SocialAuthChecker'
import ContentRow from '../../../../features/components/ContentSection/ContentRow'
import IdentityTikTokCreate from '../../../../forms/Tiktok/IdentityTikTokCreate'
import TikTokIdentitiesTable from './TikTokIdentitiesTable'

import { TIKTOK_PLATFORM } from '../../../../constants/selectLists/platformList'

const TikTokIdentities = () => {
  return (
    <ContentRow
      title="TikTok Identities"
      description="Here you can create and manage the TikTok identities that TikTok ads will be delivered from."
      leftColumnChildren={
        <SocialAuthChecker
          platform={TIKTOK_PLATFORM}
          authFailureText="Please authenticate with TikTok to add an identity"
          contentAlign="left"
          hasPadding={false}
        >
          <IdentityTikTokCreate />
        </SocialAuthChecker>
      }
    >
      <TikTokIdentitiesTable />
    </ContentRow>
  )
}

export default TikTokIdentities
