import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import AdCardMetrics from './AdCardMetrics'
import AdCardPreview from './AdCardPreview'
import SkeletonFacebookAdCardListItem from './Skeleton'
import Can from '../../../../../features/components/Can'
import ActionsDropdown from '../../../../../features/components/ActionsDropdown'
import InternalAdActionButtons from '../../../../AdApprovals/InternalAdActionButtons'
import AdCardListItem from '../../../../../features/components/AdCardListItem'
import AdCardDetails from '../../../../../features/components/AdCardListItem/AdCardDetails'
import AdStatusChip from './AdStatusChip'

import useFacebookAdActions from './useFacebookAdActions'

import { getPlacementPositions } from '../../../../../forms/Facebook/AdForms/AdFacebookEdit/helpers'

import {
  adDeleteIsLoadingSelector,
  adDeleteItemSelector,
  adUpdateIsLoadingSelector,
  adUpdateSelector,
  approveInternalAdErrorSelector,
  approveInternalAdIsLoadingSelector,
  approveInternalAdSelector,
  approveInternalAdWasApprovedSelector
} from '../../../../../modules/selectors/ads'
import { lineItemSelector } from '../../../../../modules/selectors/lineItems'
import { currentUserSelfAccountsListSelector } from '../../../../../modules/selectors/app'

import { INTERNAL_ADS_PERMISSION } from '../../../../../constants/permissions'

import useStyles from './styles'

function FacebookAdCardListItem({
  ad,
  currencySymbol,
  showStatus = true,
  showDetails = true,
  showMetrics = true,
  showActionsDropdown = true,
  showActionsButtons = false,
  showPurchaseMetric,
  checkPlacements,
  lineItems,
  isLineItemSummaryPage,
  isAdApprovalsStyle,
  disapproveInternalAdHandler,
  handleDeleteRequest
}) {
  const classes = useStyles({ hasTopNavbar: showStatus || showActionsDropdown })

  const lineItem = useSelector(lineItemSelector)
  const updateAdItem = useSelector(adUpdateSelector)
  const updateAdIsLoading = useSelector(adUpdateIsLoadingSelector)
  const deleteAdItem = useSelector(adDeleteItemSelector)
  const deleteAdIsLoading = useSelector(adDeleteIsLoadingSelector)
  const selfAccounts = useSelector(currentUserSelfAccountsListSelector)

  const { actionsDropdownOptions, approveInternalAdHandler } = useFacebookAdActions({
    ad,
    disapproveInternalAdHandler,
    handleDeleteRequest
  })

  const placementPositions = useMemo(() => {
    if (checkPlacements) {
      return isLineItemSummaryPage
        ? getPlacementPositions({ ad, lineItem, lineItemWasLoaded: true })
        : getPlacementPositions({ ad, lineItems, lineItemsWasLoaded: true })
    } else {
      return []
    }
  }, [checkPlacements, isLineItemSummaryPage, ad, lineItem, lineItems])

  const { id, name, line_item_name: lineItemName } = ad

  // On approvals page we have ads from different self accounts.
  // Find self account name which created current internal ad
  const adSelfAccountName = selfAccounts.find(({ id }) => Number(id) === Number(ad.account_id))?.name || ''

  // We show adSelfAccountName on AdApprovals page, or lineItemName on other pages
  const subTitle = isAdApprovalsStyle ? adSelfAccountName : lineItemName
  const adIsUpdating = updateAdIsLoading && updateAdItem[id]
  const adIsDeleting = deleteAdIsLoading && deleteAdItem[id]

  if (adIsUpdating || adIsDeleting) {
    return (
      <SkeletonFacebookAdCardListItem
        showStatus={showStatus}
        showDetails={showDetails}
        showMetrics={showMetrics}
        showActionsDropdown={showActionsDropdown}
        showActionsButtons={showActionsButtons}
        isAdApprovalsStyle={isAdApprovalsStyle}
      />
    )
  }

  return (
    <AdCardListItem>
      {(showStatus || showActionsDropdown) && (
        <div className={classes.cardHeader}>
          {showStatus && <AdStatusChip ad={ad} />}
          {showActionsDropdown && !!actionsDropdownOptions.length && (
            <ActionsDropdown
              itemId={ad.id}
              options={actionsDropdownOptions}
              actionsListWrapperClassName={classes.actionsListWrapper}
              className={classes.actionsDropdownMenu}
              closeOnInsideClick
            />
          )}
        </div>
      )}

      <AdCardPreview ad={ad} placementPositions={placementPositions} isAdApprovalsStyle={isAdApprovalsStyle} />

      {showDetails && <AdCardDetails title={name} subTitle={subTitle} />}

      {showActionsButtons && (
        <Can I="manage" a={INTERNAL_ADS_PERMISSION}>
          <InternalAdActionButtons
            adId={id}
            approveInternalAdSelector={approveInternalAdSelector}
            approveInternalAdIsLoadingSelector={approveInternalAdIsLoadingSelector}
            approveInternalAdErrorSelector={approveInternalAdErrorSelector}
            approveInternalAdWasApprovedSelector={approveInternalAdWasApprovedSelector}
            approveInternalAdHandler={approveInternalAdHandler}
            disapproveInternalAdHandler={() => disapproveInternalAdHandler(ad)}
          />
        </Can>
      )}
      {showMetrics && <AdCardMetrics ad={ad} currencySymbol={currencySymbol} showPurchaseMetric={showPurchaseMetric} />}
    </AdCardListItem>
  )
}

export default FacebookAdCardListItem

FacebookAdCardListItem.propTypes = {
  ad: PropTypes.object.isRequired,
  currencySymbol: PropTypes.string,
  showStatus: PropTypes.bool,
  showDetails: PropTypes.bool,
  showMetrics: PropTypes.bool,
  showActionsDropdown: PropTypes.bool,
  showActionsButtons: PropTypes.bool,
  showPurchaseMetric: PropTypes.bool,
  lineItems: PropTypes.array,
  isLineItemSummaryPage: PropTypes.bool,
  isAdApprovalsStyle: PropTypes.bool,
  disapproveInternalAdHandler: PropTypes.func
}
