import { useCallback, useMemo, useState } from 'react'

export default function useContinuousForm({ initialItemId, initialForm }) {
  // ContinuousForm allow to have multiple forms in row dependent on each other
  const [itemId, setItemId] = useState(initialItemId)
  const [activatedForm, setActivatedForm] = useState(initialForm)
  // CampaignGoogleCreate consists from several forms
  const [successSubmit, setSuccessSubmit] = useState(false)

  const handleResetForm = useCallback(() => {
    setItemId(initialItemId)
    setActivatedForm(initialForm)
    setSuccessSubmit(false)
  }, [initialItemId, initialForm])

  return useMemo(
    () => ({
      activatedForm,
      setActivatedForm,
      itemId,
      setItemId,
      handleResetForm,
      successSubmit,
      setSuccessSubmit
    }),
    [handleResetForm, itemId, activatedForm, successSubmit, setSuccessSubmit]
  )
}
