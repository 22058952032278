export const getAllRelatedFilesInBookedMediaGroup = (sequentialList, fileId, fileName) => {
  // if it's a group of booked media - we need to update files in each booked media in sequentialList
  // each booked media has the same file, but with different ids,
  // so we match them by id or name
  return sequentialList.reduce(
    (acc, { uploaded_files }) => [
      ...acc,
      ...uploaded_files
        .filter(
          // match by id or name
          ({ id, name }) => id === fileId || name === fileName
        )
        .map(({ id }) => id)
    ],
    []
  )
}

export const getFileIdsForBulkUpdate = (uploadedFile, sequentialList) => {
  return sequentialList
    ? // if current row has sequentialList - it's a group of booked media
      // we need to update all files in the group
      getAllRelatedFilesInBookedMediaGroup(sequentialList, uploadedFile.id, uploadedFile.name)
    : [uploadedFile.id]
}
