export const columnsSize = {
  checkbox: 42,
  id: 60,
  account: 120,
  dates: 180,
  campaign: 180,
  createdBy: 130,
  created: 85,
  updated: 85,
  status: 90,
  rate: 110,
  disc: 60,
  cost: 110,
  actions: 40
}
export const quotationsColumnsSchema = [
  {
    style: { maxWidth: columnsSize.checkbox }
  },
  {
    style: { maxWidth: columnsSize.id }
  },
  {
    style: { maxWidth: columnsSize.account }
  },
  {
    style: { maxWidth: columnsSize.campaign }
  },
  {
    style: { maxWidth: columnsSize.dates }
  },
  {
    style: { maxWidth: columnsSize.createdBy }
  },
  {
    style: { maxWidth: columnsSize.created }
  },
  {
    style: { maxWidth: columnsSize.updated }
  },
  {
    style: { maxWidth: columnsSize.status }
  },
  {
    style: { maxWidth: columnsSize.rate }
  },
  {
    style: { maxWidth: columnsSize.disc }
  },
  {
    style: { maxWidth: columnsSize.cost }
  },
  {
    style: { maxWidth: columnsSize.actions }
  }
]
