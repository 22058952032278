import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import TabItem from './TabItem'

import useStyles from './styles'

const Tabs = ({ options, selectedValue, onSelectValue, className, tabItemClassName }) => {
  const classes = useStyles()

  const selectTabHandler = useCallback(
    value => {
      onSelectValue && onSelectValue(value)
    },
    [onSelectValue]
  )

  const filteredOptions = useMemo(
    () =>
      options.filter(item => {
        return item.value && item.label
      }),
    [options]
  )

  return (
    <div className={classnames(classes.tabsContainer, className)}>
      {filteredOptions.map(item => (
        <TabItem
          className={tabItemClassName}
          key={item.value}
          tabItem={item}
          isDisabled={item.disabled}
          isActive={item.value === selectedValue}
          onClick={() => selectTabHandler(item.value)}
        />
      ))}
    </div>
  )
}

Tabs.propTypes = {
  options: PropTypes.array,
  selectedValue: PropTypes.string,
  onSelectValue: PropTypes.func,
  className: PropTypes.string,
  tabItemClassName: PropTypes.string
}

export default Tabs
