import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles({
  addAnotherWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  loadMoreButton: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    marginBottom: 4
  },
  addAnother: {
    marginRight: 'auto'
  }
})

export default useStyles
