import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import ButtonIcon from '../../Buttons/ButtonIcon'

import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash.svg'
import { ReactComponent as ExpandIcon } from '../../../../assets/icons/expand.svg'
import { ReactComponent as MinimizeIcon } from '../../../../assets/icons/minimize.svg'

import useStyles from './styles'
import useDrawerFormStyles from '../../../../styles/common/drawerForms'

const CollapsibleFormFields = ({
  isOpened,
  onDelete,
  closeForm,
  selectForm,
  index,
  title = '',
  isRemovable,
  children,
  isItemActive,
  isValid,
  mediaFileError,
  allowClose
}) => {
  const classes = useStyles({ isItemActive, isValid, mediaFileError })
  const drawerFormClasses = useDrawerFormStyles()

  const { t } = useTranslation()

  return isOpened ? (
    <div className={classes.carouselItemForm}>
      <div className={classes.title}>{t('ordinalTitle', { count: index + 1, ordinal: true, title: title })}</div>
      {allowClose && (
        <ButtonIcon
          className={classnames(drawerFormClasses.sectionIcon, classes.iconButton)}
          onClick={closeForm}
          Icon={MinimizeIcon}
        />
      )}
      {isRemovable && <ButtonIcon className={classes.removePostBtn} Icon={TrashIcon} onClick={onDelete} />}
      {children}
    </div>
  ) : (
    <div className={classnames(classes.carouselItemForm, classes.carouselItemFormMinimized)} onClick={selectForm}>
      <div className={classes.title}>{t('ordinalTitle', { count: index + 1, ordinal: true, title: title })}</div>
      <ButtonIcon className={classnames(drawerFormClasses.sectionIcon, classes.iconButton)} Icon={ExpandIcon} />
    </div>
  )
}

CollapsibleFormFields.propTypes = {
  isOpened: PropTypes.bool,
  onDelete: PropTypes.func,
  closeForm: PropTypes.func,
  selectForm: PropTypes.func,
  index: PropTypes.number,
  title: PropTypes.string,
  isRemovable: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isItemActive: PropTypes.bool,
  isValid: PropTypes.bool,
  mediaFileError: PropTypes.string
}

export default CollapsibleFormFields
