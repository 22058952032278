import { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'

import { concat } from '../../../../helpers/common'

import { FILE_URL, MEDIA_TYPE } from '../../../Google/AdForms/fields'
import { FILE_TYPE } from '../../../../constants/product'
import { MEDIA_FILE_OPTION } from '../../../Facebook/AdForms/fields'

export function useMediaUpload(props) {
  PropTypes.checkPropTypes(mediaPropTypes, props, 'prop', 'useMediaUpload')

  const { formik, itemPath, onMediaRemove, initialMediaFileValues, errorFieldName } = props
  const { values, setFieldValue, errors, touched } = formik

  const fileURLKey = concat(itemPath, '.', FILE_URL)
  const fileTypeKey = concat(itemPath, '.', FILE_TYPE)
  const mediaOptionKey = concat(itemPath, '.', MEDIA_FILE_OPTION)
  const uploadedMediaTypeKey = concat(itemPath, '.', MEDIA_TYPE)

  // select field based on which the main validation is applied to
  const errorField = errorFieldName || FILE_URL
  const mediaFile = getIn(values, itemPath)
  const mediaError = getIn(errors, `${itemPath}[${errorField}]`)
  const mediaTouched = getIn(touched, `${itemPath}[${errorField}]`)
  const selectedMediaOption = getIn(values, mediaOptionKey)
  const mediaMissingError = mediaTouched && mediaError

  const handleMediaRemove = useCallback(() => {
    // set initial media file values for deleted file
    setFieldValue(itemPath, initialMediaFileValues)
    onMediaRemove && onMediaRemove()
  }, [itemPath, setFieldValue, onMediaRemove, initialMediaFileValues])

  return useMemo(
    () => ({
      fileURLKey,
      fileTypeKey,
      mediaOptionKey,
      uploadedMediaTypeKey,
      mediaFile,
      mediaError,
      mediaTouched,
      selectedMediaOption,
      mediaMissingError,
      handleMediaRemove
    }),
    [
      fileURLKey,
      fileTypeKey,
      mediaOptionKey,
      uploadedMediaTypeKey,
      mediaFile,
      mediaError,
      mediaTouched,
      selectedMediaOption,
      mediaMissingError,
      handleMediaRemove
    ]
  )
}

const mediaPropTypes = {
  formik: PropTypes.object.isRequired,
  itemPath: PropTypes.string.isRequired,
  onMediaRemove: PropTypes.func,
  initialMediaFileValues: PropTypes.object.isRequired
}
