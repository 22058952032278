import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import FieldsSection from '../../../../../../../features/components/Form/FieldsSection'
import Checkbox from '../../../../../../../components/Form/Checkbox'

import { SIGNATORY_PERMISSION } from '../../../../../../../constants/permissions'

const AgencyPermissionsFields = ({ formik }) => {
  const { t } = useTranslation()

  const { values, setFieldValue } = formik

  const checkboxHandler = useCallback(
    e => {
      const { name } = e.target
      setFieldValue(name, !values[name])
    },
    [values, setFieldValue]
  )

  return (
    <FieldsSection title="Permissions">
      <Checkbox
        id={SIGNATORY_PERMISSION}
        name={SIGNATORY_PERMISSION}
        checked={values[SIGNATORY_PERMISSION]}
        title={t('Authorised Signatory')}
        onCheck={checkboxHandler}
      />
    </FieldsSection>
  )
}

export default AgencyPermissionsFields
