import { createStyles } from '../../styles/helpers'

import { transitionShort } from '../../styles/const/common'
import { phonesDown } from '../../styles/const/breakpoints'
import { darkGrey } from '../../styles/const/colors'

const useStyles = createStyles({
  spoiler: {
    marginTop: 24
  },
  arrowIcon: {
    width: 12,
    height: 8,
    display: 'inline-block',
    marginRight: 8,
    transition: transitionShort,
    transform: opened => (opened ? 'rotate(0deg)' : 'rotate(-90deg)')
  },
  spoilerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    color: darkGrey,
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: 16
  },
  [`@media screen and (${phonesDown})`]: {
    spoiler: {
      '& .radioBlock:first-child': {
        marginTop: 16
      }
    }
  }
})

export default useStyles
