import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

export const membersSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.membersList.members.results,
  results => results
)

export const membersNextSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.membersList.members.next,
  next => next
)

export const membersIsLoadingSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.membersList.members.isLoading,
  isLoading => isLoading
)

export const membersWasLoadedSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.membersList.members.wasLoaded,
  wasLoaded => wasLoaded
)

export const membersErrorSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.membersList.members.error,
  error => error
)

export const updatedMemberSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.membersList.updatedMember,
  updatedMember => updatedMember
)
export const updateMemberWasUpdatedSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.membersList.updatedMember.wasUpdated,
  wasUpdated => wasUpdated
)

export const updateMemberIsLoadingSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.membersList.updatedMember.isLoading,
  isLoading => isLoading
)

export const updateMemberErrorSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.membersList.updatedMember.error,
  error => error
)

export const invitesSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.invitesList.invites.results,
  results => results
)

export const invitesNextSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.invitesList.invites.next,
  next => next
)

export const invitesIsLoadingSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.invitesList.invites.isLoading,
  isLoading => isLoading
)

export const invitesWasLoadedSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.invitesList.invites.wasLoaded,
  wasLoaded => wasLoaded
)

export const invitesErrorSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.invitesList.invites.error,
  error => error
)

export const reinvitedUserSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.invitesList.reinvitedUser,
  reinvitedUser => reinvitedUser
)

export const deletedInvitationSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.invitesList.deletedInvite,
  deletedInvite => deletedInvite
)

export const deletedInvitationIsLoadingSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.invitesList.deletedInvite.isLoading,
  isLoading => isLoading
)

export const memberCreateIsLoadingSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.membersList.createdMember.isLoading,
  isLoading => isLoading
)

export const memberCreateErrorSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.membersList.createdMember.error,
  error => error
)

export const memberWasCreatedSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.membersList.createdMember.wasCreated,
  wasCreated => wasCreated
)

export const inviteCreateErrorSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.invitesList.createdInvite.error,
  error => error
)

export const inviteCreateIsLoadingSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.invitesList.createdInvite.isLoading,
  isLoading => isLoading
)

export const inviteWasCreatedSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.invitesList.createdInvite.wasCreated,
  wasCreated => wasCreated
)

// account invites
export const acceptedInviteIsLoadingSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.invitesList.acceptedInvite.isLoading,
  isLoading => isLoading
)

export const acceptedInviteErrorSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.invitesList.acceptedInvite.error,
  error => error
)

export const inviteWasAcceptedSelector = createSelector(
  ({ selfAccountTeam }) => selfAccountTeam.invitesList.acceptedInvite.wasAccepted,
  wasAccepted => wasAccepted
)
