import { createStyles } from '../../../../../../../../styles/helpers'
import { darkGrey } from '../../../../../../../../styles/const/colors'

const useStyles = createStyles({
  descriptionForField: {
    marginTop: 4,
    color: darkGrey,
    fontSize: 12
  }
})

export default useStyles
