import { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

function useRequestProcessor({
  manualSuccess,
  successSelector,
  dataSelector = () => {},
  onSuccessSubmit,
  errorSelector,
  setError
}) {
  // process request error or success
  const requestError = useSelector(errorSelector)
  // manualSuccess is used when request to BE was ignored
  const requestSuccess = useSelector(successSelector) || manualSuccess
  const requestSuccessData = useSelector(dataSelector)

  useEffect(() => {
    // if requests is successful, run next and pass success data
    if (requestSuccess) {
      onSuccessSubmit(requestSuccessData)
    }
  }, [requestSuccess, requestSuccessData, onSuccessSubmit])

  useEffect(() => {
    // if requests is failure, set error state for combined requests
    if (requestError) {
      setError(requestError)
    }
  }, [setError, requestError])
}

const initialState = {
  isLoading: false,
  error: null,
  successSubmit: false,
  submitValues: {},
  firstRequestSuccessData: {},
  activeRequestIndex: 0
}

// sometimes we need to perform 2 requests on 1 button click to submit the form
// if you need to do 3 or more requests, you should probably ask for possible BE simplification
// or consider using tasks processing or continuous form approach
export default function useDoubleRequestSubmit(props) {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'useDoubleRequestSubmit')

  const { requests, clearSubmitHandler, keepFirstRequestProgress = false } = props

  const [isLoading, setIsLoading] = useState(initialState.isLoading)
  const [error, setError] = useState(initialState.error)
  const [successSubmit, setSuccessSubmit] = useState(initialState.successSubmit)
  const [submitValues, setSubmitValues] = useState(initialState.submitValues)
  const [firstRequestSuccessData, setFirstRequestSuccessData] = useState(initialState.firstRequestSuccessData)
  const [activeRequestIndex, setActiveRequestIndex] = useState(0)

  const activeRequest = useMemo(() => requests[activeRequestIndex], [requests, activeRequestIndex])
  const firstRequest = useMemo(() => requests[0], [requests])
  const secondRequest = useMemo(() => requests[1], [requests])

  const handleNextRequest = useCallback(
    previousRequestSuccessData => {
      secondRequest.requestHandler(submitValues, previousRequestSuccessData)
      setFirstRequestSuccessData(previousRequestSuccessData)
      setActiveRequestIndex(1)
    },
    [secondRequest, submitValues]
  )

  useRequestProcessor({
    successSelector: firstRequest.successSelector,
    dataSelector: firstRequest.dataSelector,
    errorSelector: firstRequest.errorSelector,
    manualSuccess: secondRequest.manualSuccess,
    // run nextRequest
    onSuccessSubmit: handleNextRequest,
    setError
  })

  useRequestProcessor({
    successSelector: secondRequest.successSelector,
    errorSelector: secondRequest.errorSelector,
    manualSuccess: secondRequest.manualSuccess,
    // make overall successful submit for last request finalize
    onSuccessSubmit: () => setSuccessSubmit(true),
    setError
  })

  const resetInternalState = useCallback(() => {
    if (!keepFirstRequestProgress) {
      // this allows to save the progress of first request and skip it processing second time
      setFirstRequestSuccessData(initialState.firstRequestSuccessData)
      setActiveRequestIndex(initialState.activeRequestIndex)
    }

    setIsLoading(initialState.isLoading)
    setError(initialState.error)
    setSuccessSubmit(initialState.successSubmit)
    setSubmitValues(initialState.submitValues)
  }, [keepFirstRequestProgress])

  const onSubmit = useCallback(
    values => {
      setIsLoading(true)
      // save formik values to internal state and pass to active request handler
      setSubmitValues(values)

      activeRequest.requestHandler(values, firstRequestSuccessData)
    },
    [activeRequest, firstRequestSuccessData]
  )

  const onClearSubmit = useCallback(() => {
    clearSubmitHandler()
    resetInternalState()
  }, [resetInternalState, clearSubmitHandler])

  // imitate selector to pass inside form
  const isLoadingSelector = useCallback(() => isLoading, [isLoading])
  const errorSelector = useCallback(() => error, [error])

  return useMemo(
    () => ({
      onSubmit,
      onClearSubmit,
      isLoadingSelector,
      errorSelector,
      successSubmit
    }),
    [onSubmit, onClearSubmit, isLoadingSelector, errorSelector, successSubmit]
  )
}

const propTypes = {
  requests: PropTypes.arrayOf(
    PropTypes.shape({
      requestHandler: PropTypes.func.isRequired,
      errorSelector: PropTypes.func.isRequired,
      successSelector: PropTypes.func.isRequired,
      dataSelector: PropTypes.func,
      manualSuccess: PropTypes.bool
    })
  ).isRequired,
  clearSubmitHandler: PropTypes.func.isRequired,
  // once second request fails by default it will reset the form progress
  // so once user make new submit first request will be send again, to avoid that set the property to true:
  keepFirstRequestProgress: PropTypes.bool
}
