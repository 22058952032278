import { useCallback, useEffect, useMemo, useState } from 'react'
const useHandleImageLoading = fileUrl => {
  const [isMediaLoaded, setIsMediaLoaded] = useState(false)
  const [isMediaFailed, setIsMediaFailed] = useState(false)

  const handleMediaSuccessfullyLoaded = useCallback(() => setIsMediaLoaded(true), [])
  const handleMediaFailed = useCallback(() => setIsMediaFailed(true), [])

  // On file remove - set isMediaLoaded and isMediaFailed to false
  useEffect(() => {
    if (!fileUrl) {
      setIsMediaLoaded(false)
      setIsMediaFailed(false)
    }
  }, [fileUrl])

  return useMemo(
    () => ({
      isMediaLoaded,
      isMediaFailed,
      handleMediaSuccessfullyLoaded,
      handleMediaFailed
    }),
    [isMediaLoaded, isMediaFailed, handleMediaSuccessfullyLoaded, handleMediaFailed]
  )
}

export default useHandleImageLoading
