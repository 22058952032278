import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Can from '../../../features/components/Can'
import DigitalTable from '../../../features/components/DigitalTable'
import CampaignFacebookEdit from '../../../forms/Facebook/CampaignForms/CampaignFacebookEdit'
import CampaignGoogleEdit from '../../../forms/Google/CampaignForms/CampaignGoogleEdit'
import CampaignTikTokEdit from '../../../forms/Tiktok/CampaignForms/CampaignTikTokEdit'
import CampaignFacebookDuplicate from '../../../forms/Facebook/CampaignForms/CampaignFacebookDuplicate'
// import AutomationRuleCreate from '../../../forms/Multiplatform/CampaignForms/AutomationRuleCreate'
import DeleteItemModal from '../../../features/components/Modals/DeleteModal/DeleteItemModal'

import useSearch from '../../../hooks/useSearch'
import usePermissions from '../../../hooks/usePermissions'
import useDeleteAction from '../../../features/hooks/useDeleteAction'
import useGetCampaignActionsDropdownOptions from './useGetCampaignActionsDropdownOptions'

import { getCampaignSummaryRedirectLinkHandler } from '../../../constants/campaigns'

import {
  deleteCampaign,
  updateCampaign,
  clearDeleteCampaign,
  clearUpdateCampaign
} from '../../../modules/actions/campaigns'
import {
  campaignDeleteErrorSelector,
  campaignDeleteIsLoadingSelector,
  campaignDeleteItemSelector,
  campaignsDateRangeSelector,
  campaignsErrorSelector,
  campaignsFilterSelector,
  campaignsIsLoadingSelector,
  campaignsNextSelector,
  campaignsSelector,
  campaignsTotalsSelector,
  campaignsWasLoadedSelector,
  campaignUpdateErrorSelector,
  campaignUpdateIsLoadingSelector,
  campaignUpdateSelector,
  campaignWasUpdatedSelector
} from '../../../modules/selectors/campaigns'
import {
  selectedPlatformSelector,
  selectedAdAccountIdSelector,
  selectedAdAccountDataSelector,
  selectedSelfAccountSelector
} from '../../../modules/selectors/app'

import { FACEBOOK_PLATFORM, GOOGLE_PLATFORM, TIKTOK_PLATFORM } from '../../../constants/selectLists/platformList'
import {
  CAMPAIGN_FILTERS_DEFAULT,
  DATE_STATIC_PRESET,
  LIFETIME_DATE_PRESET
} from '../../../constants/selectLists/listItemFiltersList'
import { listItemChangeStatus } from '../../../constants/other'
import { CAMPAIGN_PERMISSION } from '../../../constants/permissions'

const CampaignsContent = ({ searchId, loadMoreCampaignsHandler }) => {
  const [isStatusUpdateRunning, setIsStatusUpdateRunning] = useState(false)

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const filter = useSelector(campaignsFilterSelector)
  const dateRange = useSelector(campaignsDateRangeSelector)

  const searchTerm = useSearch(searchId)
  const selfAccountId = useSelector(selectedSelfAccountSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const campaigns = useSelector(campaignsSelector)
  const campaignsNext = useSelector(campaignsNextSelector)
  const totalsItem = useSelector(campaignsTotalsSelector)
  const selectedPlatform = useSelector(selectedPlatformSelector)
  const campaignUpdate = useSelector(campaignUpdateSelector)
  const campaignWasUpdated = useSelector(campaignWasUpdatedSelector)
  const campaignUpdateIsLoading = useSelector(campaignUpdateIsLoadingSelector)
  const campaignUpdateError = useSelector(campaignUpdateErrorSelector)
  const campaignDelete = useSelector(campaignDeleteItemSelector)
  const campaignDeleteIsLoading = useSelector(campaignDeleteIsLoadingSelector)
  const { currency_symbol: currencySymbol } = useSelector(selectedAdAccountDataSelector)

  const permissions = usePermissions()
  const hasStatusChangePermissions = permissions.can('update', CAMPAIGN_PERMISSION)

  const itemUpdatingId = useMemo(() => {
    // avoid representation of skeleton row during status update (happens from toggle button)
    if (campaignUpdateIsLoading && !isStatusUpdateRunning) {
      return campaignUpdate.id
    } else if (campaignDeleteIsLoading) {
      return campaignDelete.id
    }

    return null
  }, [isStatusUpdateRunning, campaignUpdate.id, campaignUpdateIsLoading, campaignDelete.id, campaignDeleteIsLoading])

  const isSomeFilterApplied =
    filter !== CAMPAIGN_FILTERS_DEFAULT ||
    !!searchTerm ||
    (dateRange && dateRange[DATE_STATIC_PRESET] !== LIFETIME_DATE_PRESET)

  const noDataMessageText = useMemo(() => {
    return isSomeFilterApplied ? (
      <>
        {t("There aren't any campaigns that match those criteria.")}
        <br />
        {t('Try clearing or modifying your search or filter.')}
      </>
    ) : (
      <>
        {t('There are no campaigns to show.')}
        <br />
        {t("Click 'Create Campaign' to set one up.")}
      </>
    )
  }, [isSomeFilterApplied, t])

  const updateCampaignStatusHandler = useCallback(
    campaign => {
      setIsStatusUpdateRunning(true)

      dispatch(
        updateCampaign(
          {
            account: selectedAdAccountId,
            status: listItemChangeStatus[campaign.status]?.changeTo,
            ...(campaign.objective && { campaign_objective: campaign.objective })
          },
          campaign.id
        )
      )
    },
    [dispatch, selectedAdAccountId]
  )

  const deleteModalMessage = useMemo(
    () => (
      <>
        <p>
          {t(
            'If there has been recent delivery on this campaign, we recommend you archive it. Otherwise, you can delete if it is not required anymore.'
          )}
        </p>
        <p>{t('This cannot be undone.')}</p>
      </>
    ),
    [t]
  )

  const deleteCampaignHandler = useCallback(
    params => {
      dispatch(deleteCampaign(params))
    },
    [dispatch]
  )

  const clearDeleteCampaignHandler = useCallback(() => {
    dispatch(clearDeleteCampaign())
  }, [dispatch])

  const {
    handleDelete,
    handleDeleteRequest,
    handleCloseDeleteModal,
    isDeleteModalOpened,
    deleteItemIsLoading,
    dataWaitingForDelete
  } = useDeleteAction({
    onDelete: deleteCampaignHandler,
    onClearDelete: clearDeleteCampaignHandler,
    deletedItemSelector: campaignDeleteItemSelector,
    deleteItemErrorSelector: campaignDeleteErrorSelector,
    deleteItemIsLoadingSelector: campaignDeleteIsLoadingSelector,
    deleteModalMessage: deleteModalMessage
  })

  const archiveProps = useMemo(
    () => ({
      updateItemAction: updateCampaign,
      updatedItemSelector: campaignUpdateSelector,
      updateItemErrorSelector: campaignUpdateErrorSelector,
      updateItemIsLoadingSelector: campaignUpdateIsLoadingSelector,
      clearUpdateAction: clearUpdateCampaign,
      dataWaitingForDelete
    }),
    [dataWaitingForDelete]
  )

  const getCampaignActionsDropdownOptions = useGetCampaignActionsDropdownOptions({ handleDeleteRequest })

  const getCampaignLinkClickHandler = useCallback(
    ({ item, platform, adAccountId }) =>
      getCampaignSummaryRedirectLinkHandler({
        campaign: item,
        platform,
        adAccountId: adAccountId,
        selfAccountId: selfAccountId
      }),
    [selfAccountId]
  )

  useEffect(() => {
    if (isStatusUpdateRunning && (campaignWasUpdated || campaignUpdateError)) {
      dispatch(clearUpdateCampaign())

      setIsStatusUpdateRunning(false)
    }
  }, [dispatch, campaignUpdateError, campaignWasUpdated, isStatusUpdateRunning])

  return (
    <>
      <DigitalTable
        platform={selectedPlatform}
        data={campaigns}
        totalsItem={totalsItem}
        noDataMessageText={noDataMessageText}
        isLoadingSelector={campaignsIsLoadingSelector}
        errorSelector={campaignsErrorSelector}
        wasLoadedSelector={campaignsWasLoadedSelector}
        allowStatusChange={hasStatusChangePermissions}
        onStatusChange={updateCampaignStatusHandler}
        itemUpdatingId={itemUpdatingId}
        currencySymbol={currencySymbol}
        loadMoreHandler={campaignsNext ? loadMoreCampaignsHandler : null}
        getItemLinkClickHandler={getCampaignLinkClickHandler}
        getActionsDropdownOptions={getCampaignActionsDropdownOptions}
      />

      {/* edit forms*/}
      {selectedPlatform === FACEBOOK_PLATFORM && (
        <Can I="update" a={CAMPAIGN_PERMISSION}>
          <CampaignFacebookEdit shouldBeCleared />
        </Can>
      )}
      {selectedPlatform === GOOGLE_PLATFORM && (
        <Can I="update" a={CAMPAIGN_PERMISSION}>
          <CampaignGoogleEdit shouldBeCleared />
        </Can>
      )}
      {selectedPlatform === TIKTOK_PLATFORM && (
        <Can I="update" a={CAMPAIGN_PERMISSION}>
          <CampaignTikTokEdit shouldBeCleared />
        </Can>
      )}

      {/* duplicate forms*/}
      {selectedPlatform === FACEBOOK_PLATFORM && (
        <Can I="create" a={CAMPAIGN_PERMISSION}>
          <CampaignFacebookDuplicate />
        </Can>
      )}

      {/* automation rule business logic is hidden for now */}
      {/* <Can I="manage" a={CAMPAIGN_PERMISSION} ><AutomationRuleCreate /></Can> */}

      <Can I="delete" a={CAMPAIGN_PERMISSION}>
        <DeleteItemModal
          handleDelete={handleDelete}
          handleCloseDeleteModal={handleCloseDeleteModal}
          isDeleteModalOpened={isDeleteModalOpened}
          deleteItemIsLoading={deleteItemIsLoading}
          deleteModalMessage={deleteModalMessage}
          // props for archive button, works only for facebook
          archiveProps={selectedPlatform === FACEBOOK_PLATFORM ? archiveProps : null}
        />
      </Can>
    </>
  )
}

export default CampaignsContent
