import * as Yup from 'yup'
import { LOCATION_LIST } from '../../../../../../ReusableFormFields/GeolocationsFields/fields'
import { COUNTRY_OR_REGION, CUSTOM_LOCATIONS, GEO_TARGETING } from '../../../fields'
import { locationListValidation } from '../../../../../../ReusableFormFields/LineItemForms/fields'

const geolocationsValidation = {
  [GEO_TARGETING]: Yup.object().when([COUNTRY_OR_REGION, CUSTOM_LOCATIONS, LOCATION_LIST], {
    // at least one of the GEO_TARGETING fields should be selected, otherwise show error:
    is: (country, custom, customList) => !country.length && !custom.length && !customList,
    then: () => Yup.object().required('At least one location targeting should be selected')
  }),
  [COUNTRY_OR_REGION]: Yup.array().test(
    COUNTRY_OR_REGION,
    'There is an overlap in your location targeting. You are trying to target a region and a city in that' +
      ' region. Remove one of these so that the locations to do not overlap.',
    value => {
      let hasOverlap

      value.forEach(({ region_id }) => {
        if (region_id) {
          hasOverlap = value.find(item => {
            return String(region_id) === String(item.value)
          })
        }
      })

      return !hasOverlap
    }
  ),
  [LOCATION_LIST]: locationListValidation
}

export default geolocationsValidation
