import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAmendment, clearActiveAmendment, clearGetAmendment } from '../../../../../modules/actions/amendments'
import {
  activeAmendmentContractSelector,
  getAmendmentIsLoadingSelector,
  getAmendmentWasLoadedSelector
} from '../../../../../modules/selectors/amendments'

export default function useAmendmentData(contractId, tokenParams) {
  const dispatch = useDispatch()

  const amendmentContract = useSelector(activeAmendmentContractSelector)
  const contractIsLoading = useSelector(getAmendmentIsLoadingSelector)
  const contractWasLoaded = useSelector(getAmendmentWasLoadedSelector)

  const isFormLoading = contractIsLoading || !contractWasLoaded

  const handleClearSelectedContract = useCallback(() => {
    dispatch(clearActiveAmendment())
    dispatch(clearGetAmendment())
  }, [dispatch])

  useEffect(() => {
    if (contractId) {
      dispatch(getAmendment(contractId, tokenParams))
    }
  }, [dispatch, contractId, tokenParams])

  return useMemo(
    () => ({
      amendmentContract,
      isDataLoading: isFormLoading,
      handleClearSelectedContract
    }),
    [amendmentContract, isFormLoading, handleClearSelectedContract]
  )
}
