import * as Yup from 'yup'
import { t } from 'i18next'

import { marginFeeValidation } from '../../../../../../forms/ReusableFormFields/MarginFields/MarginFee/validation'

import { CATEGORY, NAME, nameValidation, SUBCATEGORY } from '../../fields'
import {
  MARGIN_OPTION,
  MARGINS,
  marginsInitialValues
} from '../../../../../../forms/ReusableFormFields/MarginFields/fields'
import {
  DV_360_PLATFORM,
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  TIKTOK_PLATFORM
} from '../../../../../../constants/selectLists/platformList'

import { ALLOW_CUSTOM_CLICK_THROUGH_LINK } from '../../../../../../forms/Multiplatform/UploadAdCreative/fields'
import { REPRESENTATIVE } from '../../../../../../constants/mediaOrders'

export const FACEBOOK_AD_ACCOUNT_ID = 'facebook_ad_account_id'
export const FACEBOOK_AD_ACCOUNT_DATA = 'facebook_ad_account_data'

export const AUTH_USER = 'auth_user'

export const GOOGLE_AD_ACCOUNT_ID = 'google_ad_account_id'
export const GOOGLE_AD_ACCOUNT_DATA = 'google_ad_account_data'

export const DV360_AD_ACCOUNT_CHECKED = 'dv360_ad_account_checked'
export const DV360_AD_ACCOUNT_ID = 'dv360_ad_account_id'
export const DV360_AD_ACCOUNT_DATA = 'dv360_ad_account_data'

export const TIKTOK_AD_ACCOUNT_ID = 'tiktok_ad_account_id'
export const TIKTOK_AD_ACCOUNT_DATA = 'tiktok_ad_account_data'
export const TAGS = 'tags'
export const BRANDS = 'brands'
export const CURRENCY = 'currency'
// BRANDS
export const BRAND_NAME = 'brand_name'
export const SQUARE_LOGO = 'square_logo'
export const SQUARE_LOGO_FILE_NAME = 'square_logo_file_name'
export const LANDSCAPE_LOGO = 'landscape_logo'
export const LANDSCAPE_LOGO_FILE_NAME = 'landscape_logo_file_name'
export const OFFSITE_CAMPAIGN_TYPES = 'offsite_campaign_types'
export const CUSTOMER_ID = 'customer_id'

export const initialBrandValue = {
  [BRAND_NAME]: '',
  [CATEGORY]: '',
  [SUBCATEGORY]: '',
  [SQUARE_LOGO]: '',
  [SQUARE_LOGO_FILE_NAME]: '',
  [LANDSCAPE_LOGO]: '',
  [LANDSCAPE_LOGO_FILE_NAME]: ''
}

export const getInitialValues = controllerCurrency => {
  return {
    [NAME]: '',
    [AUTH_USER]: '',
    ...marginsInitialValues,
    // to replace default value from ...marginsInitialValues
    [MARGIN_OPTION]: '',
    // facebook
    [FACEBOOK_AD_ACCOUNT_ID]: '',
    [FACEBOOK_AD_ACCOUNT_DATA]: {},
    // google
    [GOOGLE_AD_ACCOUNT_ID]: '',
    [GOOGLE_AD_ACCOUNT_DATA]: {},
    // dv360
    [DV360_AD_ACCOUNT_ID]: '',
    [DV360_AD_ACCOUNT_DATA]: {},
    // tiktok
    [TIKTOK_AD_ACCOUNT_ID]: '',
    [TIKTOK_AD_ACCOUNT_DATA]: {},

    [BRANDS]: [initialBrandValue],
    [TAGS]: [],
    [CURRENCY]: controllerCurrency,
    [ALLOW_CUSTOM_CLICK_THROUGH_LINK]: false,
    [REPRESENTATIVE]: '',
    [CUSTOMER_ID]: ''
  }
}

export const validationSchema = Yup.object({
  ...nameValidation,
  ...marginFeeValidation,
  [MARGINS]: Yup.object().shape({
    [FACEBOOK_PLATFORM]: Yup.number().max(100, 'Maximum number of margin - 100%'),
    [GOOGLE_PLATFORM]: Yup.number().max(100, 'Maximum number of margin - 100%'),
    [TIKTOK_PLATFORM]: Yup.number().max(100, 'Maximum number of margin - 100%'),
    [DV_360_PLATFORM]: Yup.number().max(100, 'Maximum number of margin - 100%')
  }),
  [CURRENCY]: Yup.string().required('This field should not be empty')
})

export const SHOPPING_CAMPAIGNS_ROUTE = 'shopping_campaigns'
export const PRODUCT_RETARGETING_ROUTE = 'product_retargeting'
export const AUDIENCE_RETARGETING_ROUTE = 'audience_retargeting'
export const EXISTING_POST_ROUTE = 'existing_post'
export const PROXIMITY_ROUTE = 'proximity'
// GOOGLE
export const PERFORMANCE_MAX_ROUTE = 'performance_max'
export const DISPLAY_ROUTE = 'display'
export const routesList = [
  // SHOP-2031 Comment out Shopping campaigns for now, todo uncomment when needed
  // {
  //   title: t('Shopping campaigns'),
  //   name: SHOPPING_CAMPAIGNS_ROUTE
  // },
  {
    title: t('Product retargeting'),
    name: PRODUCT_RETARGETING_ROUTE
  },
  {
    title: t('Proximity campaigns'),
    name: PROXIMITY_ROUTE
  },
  {
    title: t('Audience retargeting'),
    name: AUDIENCE_RETARGETING_ROUTE
  },
  {
    title: t('Existing post'),
    name: EXISTING_POST_ROUTE
  },
  {
    title: t('Performance max'),
    name: PERFORMANCE_MAX_ROUTE
  },
  {
    title: t('Display'),
    name: DISPLAY_ROUTE
  }
]
