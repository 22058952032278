import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

export const CalendarInventoryFiltersContext = React.createContext()

export function CalendarInventoryDataProvider({ children, onFiltersChange }) {
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedSubCategories, setSelectedSubCategories] = useState([])
  const [selectedSubSubCategories, setSelectedSubSubCategories] = useState([])

  const [productFilterOption, setProductFilterOption] = useState([])
  const [selectedLocations, setSelectedLocations] = useState([])

  const filterRequestParams = useMemo(
    () => ({
      ...(selectedCategories.length && { media_category: selectedCategories.join(',') }),
      ...(selectedSubCategories.length && { media_sub_category: selectedSubCategories.join(',') }),
      ...(selectedSubSubCategories.length && { media_sub_sub_category: selectedSubSubCategories.join(',') }),
      ...(productFilterOption.length && { media_product: productFilterOption.join(',') }),
      ...(selectedLocations.length && { location: selectedLocations.join(',') })
    }),
    [productFilterOption, selectedLocations, selectedCategories, selectedSubCategories, selectedSubSubCategories]
  )

  const bookedMediaFilterProps = useMemo(
    () => ({
      filterRequestParams,
      selectedCategories,
      setSelectedCategories,
      selectedSubCategories,
      setSelectedSubCategories,
      selectedSubSubCategories,
      setSelectedSubSubCategories,
      productFilterOption,
      setProductFilterOption,
      selectedLocations,
      setSelectedLocations
    }),
    [
      filterRequestParams,
      productFilterOption,
      selectedLocations,
      selectedCategories,
      selectedSubCategories,
      selectedSubSubCategories
    ]
  )
  // make a type check for the props
  PropTypes.checkPropTypes(propTypes, bookedMediaFilterProps, 'prop', 'useRequestHandler')

  useEffect(() => {
    onFiltersChange(filterRequestParams)
  }, [filterRequestParams, onFiltersChange])

  return (
    <CalendarInventoryFiltersContext.Provider value={bookedMediaFilterProps}>
      {children}
    </CalendarInventoryFiltersContext.Provider>
  )
}

export const filterRequestParamsTypes = PropTypes.shape({
  media_category: PropTypes.string,
  media_product: PropTypes.string,
  location: PropTypes.string
}).isRequired

const propTypes = {
  filterRequestParams: filterRequestParamsTypes,
  categoryFilterOption: PropTypes.string,
  setCategoryFilterOption: PropTypes.func.isRequired,
  productFilterOption: PropTypes.array,
  setProductFilterOption: PropTypes.func.isRequired,
  selectedLocations: PropTypes.array,
  setSelectedLocations: PropTypes.func.isRequired
}
