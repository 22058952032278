import { createStyles } from '../helpers'
import { darkGrey } from '../const/colors'

const useStyles = createStyles({
  graphsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: 40,
    marginTop: 28
  },
  section: {
    marginTop: 44
  },
  sectionTitle: {
    color: darkGrey,
    fontSize: 18,
    fontWeight: 700,
    margin: 0
  },
  itemsList: {
    paddingLeft: 0,
    marginTop: 12,
    listStyle: 'none'
  },
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: -8,
    '& > *': {
      marginBottom: 8
    },
    '& > *:not(:last-child)': {
      marginRight: 8
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 2,
    '& > div': {
      marginTop: 3
    }
  }
})

export default useStyles
