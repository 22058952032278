import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Step from '../../../../../../../../features/components/Forms/StepForm/Step'
import ProductOrPageSelector from '../../../../../../ReusableFormFIelds/ProductOrPageSelector'

import { PRODUCT_ID } from '../../../../../../../ReusableFormFields/ProductFields/fields'
import { AD_LINK, FILE_URL, NAME, SINGLE_MEDIA_ITEM } from '../../../../../fields'
import { BRAND_CATEGORY_PAGE_ID } from '../../../../../../../ReusableFormFields/ProductsAndPagesFields/fields'

const stepFields = [PRODUCT_ID, BRAND_CATEGORY_PAGE_ID]

const ProductSelectionStep = ({ formik, handleStepChange }) => {
  const { values, setValues } = formik

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  const onProductSelectHandler = useCallback(
    (productSelectionValues, productData) => {
      const updatedValues = {
        ...values,
        ...productSelectionValues,
        [NAME]: productData.name,
        [SINGLE_MEDIA_ITEM]: {
          ...values[SINGLE_MEDIA_ITEM],
          [AD_LINK]: productData.link,
          [FILE_URL]: productData['image_urls']?.[0]
        }
      }

      setValues(updatedValues)
    },
    [setValues, values]
  )

  return (
    <Step stepTitle="Which product would you like to promote?" handleContinue={handleContinue}>
      <ProductOrPageSelector formik={formik} onProductOrPageDataSelect={onProductSelectHandler} />
    </Step>
  )
}

ProductSelectionStep.propTypes = {
  initialValues: PropTypes.object.isRequired
}

export default ProductSelectionStep
