import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../components/Button'
import RadioBlock from '../../../../../../components/Form/RadioBlock'
import Checkbox from '../../../../../../components/Form/Checkbox'

import { CHIP_COLORS } from '../../../../../../constants/other'
import { OPTION_NO, OPTION_YES } from '../../../../../../constants/forms'

import { DO_NOT_ASK_ENABLE_COMMENTS_AGAIN, ENABLE_COMMENTS_OPTION } from '../fields'

import useStyles from '../../../../../../styles/common/stepForms'

const EnableCommentsStep = ({ formik, handleStepChange }) => {
  const { t } = useTranslation()

  const classes = useStyles()

  const { values, setFieldValue } = formik

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create a TikTok ad group')}</div>
      <div className={classes.stepTitle}>{t('Should user comments be enabled on your ads?')}</div>
      <div className={classes.stepBody}>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_enable_comments"
          name={ENABLE_COMMENTS_OPTION}
          value={OPTION_YES}
          selectedValue={values[ENABLE_COMMENTS_OPTION]}
          label={t('Yes')}
          badgeText={'Recommended'}
          badgeColor={CHIP_COLORS.green}
        >
          <p>{t('You can hide, pin, and reply to comments using TikTok’s comment management tools.')}</p>
        </RadioBlock>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_disable_comments"
          name={ENABLE_COMMENTS_OPTION}
          value={OPTION_NO}
          selectedValue={values[ENABLE_COMMENTS_OPTION]}
          label={t('No')}
        />
        <Checkbox
          id="checkbox_do_not_ask_enable_comments_again"
          className={classes.separateCheckbox}
          checked={values[DO_NOT_ASK_ENABLE_COMMENTS_AGAIN]}
          onCheck={() => {
            setFieldValue(DO_NOT_ASK_ENABLE_COMMENTS_AGAIN, !values[DO_NOT_ASK_ENABLE_COMMENTS_AGAIN])
          }}
          title="Don’t ask me this again"
          isLarge
        />
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" className="dark" onClick={handleStepChange}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default EnableCommentsStep
