import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import AgencyEditForm from './AgencyEditForm'

import useManageEditFormData from '../../../../hooks/formHooks/useManageEditFormData'

import { agencySelector, getAgencyIsLoadingSelector } from '../../../../modules/selectors/agencies'
import { clearGetAgency, getAgency } from '../../../../modules/actions/agencies'

import { AGENCY_EDIT } from '../../../../constants/forms'

function AgencyEdit() {
  const dispatch = useDispatch()

  const getAgencyIsLoading = useSelector(getAgencyIsLoadingSelector)
  const agency = useSelector(agencySelector)

  const getDataHandler = useCallback(
    selectedEditItemId => {
      dispatch(getAgency({ id: selectedEditItemId }))
    },
    [dispatch]
  )

  const clearDataHandler = useCallback(() => {
    dispatch(clearGetAgency())
  }, [dispatch])

  useManageEditFormData({
    formName: AGENCY_EDIT,
    loadedDataId: agency.id,
    getDataHandler,
    clearDataHandler
  })

  return (
    <FormDrawerWrapper
      formName={AGENCY_EDIT}
      title="Edit agency"
      isFormLoading={getAgencyIsLoading}
      showOpenButton={false}
      closeOnSubmit={true}
    >
      <AgencyEditForm />
    </FormDrawerWrapper>
  )
}

export default AgencyEdit
