import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import CustomLocationsPreview from '../../../../../ReusableFormSections/CustomLocationsSection/CustomLocationsPreview'

import { formatGeoTargetingToFE } from '../../formatters'

import { CUSTOM_LOCATIONS, GEO_TARGETING } from '../../fields'

import { choicesCountriesSelector } from '../../../../../../modules/selectors/choices'
import { lineItemSelector } from '../../../../../../modules/selectors/lineItems'

const GeoTargetingSectionPreview = () => {
  const lineItem = useSelector(lineItemSelector)
  const choicesCountries = useSelector(choicesCountriesSelector)

  const { [GEO_TARGETING]: geoTargeting } = lineItem

  const { [CUSTOM_LOCATIONS]: customLocations } = geoTargeting

  const formattedGeoTargeting = useMemo(
    () => formatGeoTargetingToFE(geoTargeting, choicesCountries),
    [geoTargeting, choicesCountries]
  )

  return (
    <p>
      {!!formattedGeoTargeting.length
        ? formattedGeoTargeting.map(({ label, value }) => <span key={value}>{label}</span>)
        : null}
      <CustomLocationsPreview customLocations={customLocations} />
    </p>
  )
}

export default GeoTargetingSectionPreview
