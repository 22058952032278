import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles({
  pdfContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 900,
    // this is workaround for pdf viewer to avoid width re-calculation when page scrollbar appears
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: '16px',
    marginBottom: '16px'
  }
})

export default useStyles
