import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import PageSection from '../../PageSection'
import BarGraph from '../../Graphs/BarGraph'

import { formatNumber } from '../../../../helpers/numbers'

import useStyles from '../styles'

const ImpressionsGraph = ({ rawGraphData }) => {
  const classes = useStyles()

  const impressionsGraphData = useMemo(
    () => rawGraphData && rawGraphData.data && rawGraphData.data.map(data => data.value),
    [rawGraphData]
  )
  const impressionsGraphLabels = useMemo(
    () => rawGraphData && rawGraphData.data && rawGraphData.data.map(data => data.segment),
    [rawGraphData]
  )

  return (
    <PageSection className={classes.sectionContainer}>
      <div className={classes.headingContainer}>
        <div className={classes.title}>Impressions</div>
        <div className={classes.value}>{formatNumber(rawGraphData.total)}</div>
      </div>
      <BarGraph
        className={classes.graphWrapper}
        data={impressionsGraphData}
        labels={impressionsGraphLabels}
        format="integer"
      />
    </PageSection>
  )
}

ImpressionsGraph.propTypes = {
  rawGraphData: PropTypes.object.isRequired
}

export default ImpressionsGraph
