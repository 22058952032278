import { createStyles } from '../../../styles/helpers'

import { zIndex1 } from '../../../styles/const/common'

const green = '#008744'
const blue = '#0057e7'
const red = '#d62d20'
const yellow = '#ffa700'

const useStyles = createStyles({
  filterItem: {
    marginTop: 0,
    marginBottom: 0,

    '&:not(:first-child)': {
      marginLeft: '10px'
    }
  },

  loader: {
    position: 'relative',
    margin: '0 auto',
    width: ({ loaderWidth }) => loaderWidth || 50,

    '&:before': {
      content: '""',
      display: 'block',
      paddingTop: '100%'
    },

    '& .circular': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto',
      width: '100%',
      height: '100%',
      animation: '$rotate 2s linear infinite',
      transformOrigin: 'center center'
    },

    '& .path': {
      strokeDasharray: '1, 200',
      strokeDashoffset: '0',
      strokeLinecap: 'round',
      animation: '$dash 1.5s ease-in-out infinite, $color 6s ease-in-out infinite'
    }
  },

  overlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(255, 255, 255, .7)',
    zIndex: zIndex1,

    '& $loader': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  },

  // animations
  '@keyframes rotate': {
    '100%': { transform: 'rotate(360deg)' }
  },

  '@keyframes dash': {
    '0%': {
      strokeDasharray: '1, 200',
      strokeDashoffset: '0'
    },
    '50%': {
      strokeDasharray: '89, 200',
      strokeDashoffset: '-35px'
    },
    '100%': {
      strokeDasharray: '89, 200',
      strokeDashoffset: '-124px'
    }
  },

  '@keyframes color': {
    '0%, 100%': { stroke: red },
    '40%': { stroke: blue },
    '66%': { stroke: green },
    '80%, 90%': { stroke: yellow }
  }
})

export default useStyles
