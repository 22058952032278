import { concat } from '../../helpers/common'

const MODULE_NAME = 'CONTROLLER/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_CONTROLLERS = concat(MODULE_NAME, 'GET_CONTROLLERS')
export const GET_CONTROLLERS_SUCCESS = concat(MODULE_NAME, 'GET_CONTROLLERS_SUCCESS')
export const GET_CONTROLLERS_FAILURE = concat(MODULE_NAME, 'GET_CONTROLLERS_FAILURE')
export const CLEAR_CONTROLLERS = concat(MODULE_NAME, 'CLEAR_CONTROLLERS')

export const GET_CONTROLLER_MEMBERS = concat(MODULE_NAME, 'GET_CONTROLLER_MEMBERS')
export const GET_CONTROLLER_MEMBERS_SUCCESS = concat(MODULE_NAME, 'GET_CONTROLLER_MEMBERS_SUCCESS')
export const GET_CONTROLLER_MEMBERS_FAILURE = concat(MODULE_NAME, 'GET_CONTROLLER_MEMBERS_FAILURE')

export const GET_CONTROLLER_INVITES = concat(MODULE_NAME, 'GET_CONTROLLER_INVITES')
export const GET_CONTROLLER_INVITES_SUCCESS = concat(MODULE_NAME, 'GET_CONTROLLER_INVITES_SUCCESS')
export const GET_CONTROLLER_INVITES_FAILURE = concat(MODULE_NAME, 'GET_CONTROLLER_INVITES_FAILURE')

export const CREATE_CONTROLLER_INVITE = concat(MODULE_NAME, 'CREATE_CONTROLLER_INVITE')
export const CREATE_CONTROLLER_INVITE_SUCCESS = concat(MODULE_NAME, 'CREATE_CONTROLLER_INVITE_SUCCESS')
export const CREATE_CONTROLLER_INVITE_FAILURE = concat(MODULE_NAME, 'CREATE_CONTROLLER_INVITE_FAILURE')

export const ACCEPT_CONTROLLER_INVITE = concat(MODULE_NAME, 'ACCEPT_CONTROLLER_INVITE')
export const ACCEPT_CONTROLLER_INVITE_SUCCESS = concat(MODULE_NAME, 'ACCEPT_CONTROLLER_INVITE_SUCCESS')
export const ACCEPT_CONTROLLER_INVITE_FAILURE = concat(MODULE_NAME, 'ACCEPT_CONTROLLER_INVITE_FAILURE')

// update controller member
export const UPDATE_CONTROLLER_MEMBER = concat(MODULE_NAME, 'UPDATE_CONTROLLER_MEMBER')
export const UPDATE_CONTROLLER_MEMBER_SUCCESS = concat(MODULE_NAME, 'UPDATE_CONTROLLER_MEMBER_SUCCESS')
export const UPDATE_CONTROLLER_MEMBER_FAILURE = concat(MODULE_NAME, 'UPDATE_CONTROLLER_MEMBER_FAILURE')
export const CLEAR_UPDATE_CONTROLLER_MEMBER = concat(MODULE_NAME, 'CLEAR_UPDATE_CONTROLLER_MEMBER')

export const UPDATE_CONTROLLER_MEMBER_STATUS = concat(MODULE_NAME, 'UPDATE_CONTROLLER_MEMBER_STATUS')
export const UPDATE_CONTROLLER_MEMBER_STATUS_SUCCESS = concat(MODULE_NAME, 'UPDATE_CONTROLLER_MEMBER_STATUS_SUCCESS')
export const UPDATE_CONTROLLER_MEMBER_STATUS_FAILURE = concat(MODULE_NAME, 'UPDATE_CONTROLLER_MEMBER_STATUS_FAILURE')

export const DELETE_CONTROLLER_INVITE = concat(MODULE_NAME, 'DELETE_CONTROLLER_INVITE')
export const DELETE_CONTROLLER_INVITE_SUCCESS = concat(MODULE_NAME, 'DELETE_CONTROLLER_INVITE_SUCCESS')
export const DELETE_CONTROLLER_INVITE_FAILURE = concat(MODULE_NAME, 'DELETE_CONTROLLER_INVITE_FAILURE')

export const RESEND_CONTROLLER_INVITE = concat(MODULE_NAME, 'RESEND_CONTROLLER_INVITE')
export const RESEND_CONTROLLER_INVITE_SUCCESS = concat(MODULE_NAME, 'RESEND_CONTROLLER_INVITE_SUCCESS')
export const RESEND_CONTROLLER_INVITE_FAILURE = concat(MODULE_NAME, 'RESEND_CONTROLLER_INVITE_FAILURE')

export const CLEAR_CONTROLLER_MEMBERS = concat(MODULE_NAME, 'CLEAR_CONTROLLER_MEMBERS')
export const CLEAR_CONTROLLER_INVITES = concat(MODULE_NAME, 'CLEAR_CONTROLLER_INVITES')
export const CLEAR_CREATE_CONTROLLER_INVITE = concat(MODULE_NAME, 'CLEAR_CREATE_CONTROLLER_INVITE')
export const CLEAR_ACCEPT_CONTROLLER_INVITE = concat(MODULE_NAME, 'CLEAR_ACCEPT_CONTROLLER_INVITE')

export const UPDATE_CONTROLLER = concat(MODULE_NAME, 'UPDATE_CONTROLLER')
export const UPDATE_CONTROLLER_SUCCESS = concat(MODULE_NAME, 'UPDATE_CONTROLLER_SUCCESS')
export const UPDATE_CONTROLLER_FAILURE = concat(MODULE_NAME, 'UPDATE_CONTROLLER_FAILURE')
export const CLEAR_UPDATE_CONTROLLER = concat(MODULE_NAME, 'CLEAR_UPDATE_CONTROLLER')

export const GET_CONTROLLER = concat(MODULE_NAME, 'GET_CONTROLLER')
export const GET_CONTROLLER_SUCCESS = concat(MODULE_NAME, 'GET_CONTROLLER_SUCCESS')
export const GET_CONTROLLER_FAILURE = concat(MODULE_NAME, 'GET_CONTROLLER_FAILURE')
export const CLEAR_GET_CONTROLLER = concat(MODULE_NAME, 'CLEAR_GET_CONTROLLER')

export const GET_CONTROLLER_MEMBER = concat(MODULE_NAME, 'GET_CONTROLLER_MEMBER')
export const GET_CONTROLLER_MEMBER_SUCCESS = concat(MODULE_NAME, 'GET_CONTROLLER_MEMBER_SUCCESS')
export const GET_CONTROLLER_MEMBER_FAILURE = concat(MODULE_NAME, 'GET_CONTROLLER_MEMBER_FAILURE')
export const CLEAR_GET_CONTROLLER_MEMBER = concat(MODULE_NAME, 'CLEAR_GET_CONTROLLER_MEMBER')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// get controllers
export function getControllers(params) {
  return { type: GET_CONTROLLERS, params }
}

export function getControllersSuccess(controllersData) {
  return { type: GET_CONTROLLERS_SUCCESS, controllersData }
}

export function getControllersFailure(error) {
  return { type: GET_CONTROLLERS_FAILURE, error }
}

export function clearControllers() {
  return { type: CLEAR_CONTROLLERS }
}

// get controller members
export function getControllerMembers(
  params,
  loadOptions = {
    shouldClearExistingState: false
  }
) {
  return { type: GET_CONTROLLER_MEMBERS, params, loadOptions }
}

export function getControllerMembersSuccess(controllerMembersData) {
  return { type: GET_CONTROLLER_MEMBERS_SUCCESS, controllerMembersData }
}

export function getControllerMembersFailure(error) {
  return { type: GET_CONTROLLER_MEMBERS_FAILURE, error }
}

export function clearControllerMembers() {
  return { type: CLEAR_CONTROLLER_MEMBERS }
}

// get controller invites
export function getControllerInvites(
  params,
  loadOptions = {
    shouldClearExistingState: false
  }
) {
  return { type: GET_CONTROLLER_INVITES, params, loadOptions }
}

export function getControllerInvitesSuccess(invitesData) {
  return { type: GET_CONTROLLER_INVITES_SUCCESS, invitesData }
}

export function getControllerInvitesFailure(error) {
  return { type: GET_CONTROLLER_INVITES_FAILURE, error }
}

export function clearControllerInvites() {
  return { type: CLEAR_CONTROLLER_INVITES }
}

// create controller invite
export function createControllerInvite(inviteData) {
  return { type: CREATE_CONTROLLER_INVITE, inviteData }
}

export function createControllerInviteSuccess(inviteData) {
  return { type: CREATE_CONTROLLER_INVITE_SUCCESS, inviteData }
}

export function createControllerInviteFailure(error) {
  return { type: CREATE_CONTROLLER_INVITE_FAILURE, error }
}

export function clearCreateControllerInvite() {
  return { type: CLEAR_CREATE_CONTROLLER_INVITE }
}

// accept controller invite
export function acceptControllerInvite(inviteData, submitLoginOnSuccess, removeInviteOnSuccess) {
  return { type: ACCEPT_CONTROLLER_INVITE, inviteData, submitLoginOnSuccess, removeInviteOnSuccess }
}

export function acceptControllerInviteSuccess() {
  return { type: ACCEPT_CONTROLLER_INVITE_SUCCESS }
}

export function acceptControllerInviteFailure(error) {
  return { type: ACCEPT_CONTROLLER_INVITE_FAILURE, error }
}

export function clearAcceptControllerInvite() {
  return { type: CLEAR_UPDATE_CONTROLLER_MEMBER }
}

// update controller member
export function updateControllerMember(id, data) {
  return { type: UPDATE_CONTROLLER_MEMBER, id, data }
}

export function updateControllerMemberSuccess(updatedMemberData) {
  return { type: UPDATE_CONTROLLER_MEMBER_SUCCESS, updatedMemberData }
}

export function updateControllerMemberFailure(error) {
  return { type: UPDATE_CONTROLLER_MEMBER_FAILURE, error }
}

export function clearUpdateControllerMember() {
  return { type: CLEAR_UPDATE_CONTROLLER_MEMBER }
}

// update controller member status
export function updateControllerMemberStatus(id, status) {
  return { type: UPDATE_CONTROLLER_MEMBER_STATUS, id, status }
}

export function updateControllerMemberStatusSuccess(updatedMemberData) {
  return { type: UPDATE_CONTROLLER_MEMBER_STATUS_SUCCESS, updatedMemberData }
}

export function updateControllerMemberStatusFailure(error) {
  return { type: UPDATE_CONTROLLER_MEMBER_STATUS_FAILURE, error }
}

// delete controller invite
export function deleteControllerInvite(id, removeInviteOnSuccess) {
  return { type: DELETE_CONTROLLER_INVITE, id, removeInviteOnSuccess }
}

export function deleteControllerInviteSuccess(id) {
  return { type: DELETE_CONTROLLER_INVITE_SUCCESS, id }
}

export function deleteControllerInviteFailure(error) {
  return { type: DELETE_CONTROLLER_INVITE_FAILURE, error }
}

// resend controller invite
export function resendControllerInvite(id) {
  return { type: RESEND_CONTROLLER_INVITE, id }
}

export function resendControllerInviteSuccess() {
  return { type: RESEND_CONTROLLER_INVITE_SUCCESS }
}

export function resendControllerInviteFailure(error) {
  return { type: RESEND_CONTROLLER_INVITE_FAILURE, error }
}

export function updateController(id, controllerData) {
  return { type: UPDATE_CONTROLLER, id, controllerData }
}

export function updateControllerSuccess(controllerData) {
  return { type: UPDATE_CONTROLLER_SUCCESS, controllerData }
}

export function updateControllerFailure(error) {
  return { type: UPDATE_CONTROLLER_FAILURE, error }
}

export function clearUpdateController() {
  return { type: CLEAR_UPDATE_CONTROLLER }
}

// Get controller
export function getController(params) {
  return { type: GET_CONTROLLER, params }
}

export function getControllerSuccess(data) {
  return { type: GET_CONTROLLER_SUCCESS, data }
}

export function getControllerFailure(error) {
  return { type: GET_CONTROLLER_FAILURE, error }
}

export function clearGetController() {
  return { type: CLEAR_GET_CONTROLLER }
}

// Get controller member
export function getControllerMember(params) {
  return { type: GET_CONTROLLER_MEMBER, params }
}

export function getControllerMemberSuccess(data) {
  return { type: GET_CONTROLLER_MEMBER_SUCCESS, data }
}

export function getControllerMemberFailure(error) {
  return { type: GET_CONTROLLER_MEMBER_FAILURE, error }
}

export function clearGetControllerMember() {
  return { type: CLEAR_GET_CONTROLLER_MEMBER }
}
