import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import ItemsLoading from '../../../../../components/Loaders/ItemsLoading'
import TikTokAdPreviewCard from './TikTokAdPreviewCard'
import SkeletonTikTokAdPreviewCard from './TikTokAdPreviewCard/Skeleton'

import { adsErrorSelector, adsLoadingSelector, adsWasLoadedSelector } from '../../../../../modules/selectors/ads'

import useStyles from './styles'

const TikTokInternalAdsPreviewList = ({ ads = [], noDataText, disapproveInternalAdHandler }) => {
  const classes = useStyles()

  const adsIsLoading = useSelector(adsLoadingSelector)

  return (
    <ItemsLoading
      itemsLength={ads.length}
      className={classes.cardsList}
      noDataText={noDataText}
      isLoadingSelector={adsLoadingSelector}
      wasLoadedSelector={adsWasLoadedSelector}
      errorSelector={adsErrorSelector}
      additionalDataIsLoading={adsIsLoading}
      initialLoadingSkeletonsNumber={5}
      SkeletonComponent={SkeletonTikTokAdPreviewCard}
    >
      {ads.map(ad => (
        <TikTokAdPreviewCard key={ad.id} ad={ad} disapproveInternalAdHandler={() => disapproveInternalAdHandler(ad)} />
      ))}
    </ItemsLoading>
  )
}

TikTokInternalAdsPreviewList.propTypes = {
  ads: PropTypes.arrayOf(PropTypes.object).isRequired,
  noDataText: PropTypes.string,
  disapproveInternalAdHandler: PropTypes.func
}

export default TikTokInternalAdsPreviewList
