import React from 'react'

import FieldsSection from '../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../features/components/Form/FieldsSection/FieldRow'
import MultiRowFields from '../../../../../features/components/Form/MultiRowFields'
import Field from '../../../../../components/Form/Field'
import DiscountFields from './DiscountsFields'

import { NAME } from '../../../../Google/AdForms/fields'
import { newDiscountRowInitialValues } from '../DiscountPresetCreate/DiscountPresetCreateForm/fields'
import { DISCOUNT_PERCENTAGE, DISCOUNTS, PERIODS, QUANTITY } from '../fields'

import useStyles from './styles'

const mainValueName = DISCOUNTS
const subValueNames = [DISCOUNT_PERCENTAGE, QUANTITY, PERIODS]

const DiscountPresetFormContent = ({ formik }) => {
  const classes = useStyles()

  return (
    <FieldsSection title="Discount Preset">
      <FieldRow title="Name" rightSideClassName={classes.rightSide}>
        <Field placeholder="Name" formik={formik} name={NAME} />
      </FieldRow>
      <FieldRow title="Discounts" rightSideClassName={classes.rightSide}>
        <MultiRowFields
          formik={formik}
          FieldsComponent={DiscountFields}
          initialValueTemplate={newDiscountRowInitialValues}
          mainValueName={mainValueName}
          subValueNames={subValueNames}
          addNewRowText="+ Add another discount"
        />
      </FieldRow>
    </FieldsSection>
  )
}

export default DiscountPresetFormContent
