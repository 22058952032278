import React from 'react'

import FieldsSection from '../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../features/components/Form/FieldsSection/FieldRow'
import StartEndDateRangeFields from '../../../../ReusableFormFields/StartEndDateRangeFields'
import Field from '../../../../../components/Form/Field'
import PriceChangePeriodsFields from './PriceChangePeriodsFields'
import LocationsPaginatedMultiSelect from '../../../../../features/components/locationsFields/LocationsPaginatedMultiSelect'

import { mediaCategories } from '../../../../../constants/mediaOrders'

import { END_DATE, MEDIA_CATEGORY, START_DATE } from '../fields'

import useStyles from './styles'

const PriceChangePeriodFormContent = ({ formik }) => {
  const classes = useStyles()

  return (
    <FieldsSection title="Price Change Period">
      <FieldRow rightSideClassName={classes.rightSide} title="Date period">
        <StartEndDateRangeFields
          formik={formik}
          startDateName={START_DATE}
          endDateName={END_DATE}
          minDate={new Date()}
          placeholder={'Select Dates'}
        />
      </FieldRow>
      <FieldRow
        rightSideClassName={classes.rightSide}
        title="Media Category"
        description="The price change will only apply to this category of media product"
      >
        <Field name={MEDIA_CATEGORY} placeholder="Select Media Category" options={mediaCategories} formik={formik} />
      </FieldRow>
      <FieldRow
        rightSideClassName={classes.rightSide}
        title="Locations (optional)"
        description="Limit this price change to specific locations"
      >
        <LocationsPaginatedMultiSelect formik={formik} />
      </FieldRow>
      <FieldRow
        rightSideClassName={classes.rightSide}
        title="Price Change Percentage"
        description="Please indicate whether the percentage change should be an increase or decrease"
      >
        <PriceChangePeriodsFields formik={formik} />
      </FieldRow>
    </FieldsSection>
  )
}

export default PriceChangePeriodFormContent
