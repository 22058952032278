import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getBatchInfoService(params) {
  return madeRequest('POST', {
    url: ENDPOINTS.batchInfo,
    data: params
  })
}
