import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import UsersAudiencesSelect from '../../../LineItemGoogleCreate/LineItemGoogleCreateForm/LineItemGoogleAudienceForm/LineItemGoogleAudienceFields/UsersAudiencesSelect'
import EditForm from '../../../../../../features/components/Forms/EditForm'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import { useEditSectionFormSubmit } from '../../../../../../hooks/formHooks/useEditSectionFormSubmit'

import { createLineItemCriterions, clearCreateLineItemCriterions } from '../../../../../../modules/actions/lineItems'
import { getGoogleUsersList, clearGetGoogleUsersList } from '../../../../../../modules/actions/googleConstants'
import {
  createLineItemCriterionsErrorSelector,
  createLineItemCriterionsIsLoadingSelector,
  createLineItemCriterionsWasCreatedSelector,
  lineItemCriterionsSelector
} from '../../../../../../modules/selectors/lineItems'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { formatValuesToFE, transformValuesToBE } from './formatters'

import { GOOGLE_AD_GROUP_USER_LIST_CRITERION_TYPE } from '../../../../../../constants/lineItems'

function AudienceSectionForm({ editItemData: item, ...formProps }) {
  const dispatch = useDispatch()

  const criterionsWasCreated = useSelector(createLineItemCriterionsWasCreatedSelector)
  const lineItemCriterions = useSelector(lineItemCriterionsSelector)

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const initialAudienceCriterions = lineItemCriterions.filter(
    criterion => criterion['type_'] === GOOGLE_AD_GROUP_USER_LIST_CRITERION_TYPE
  )

  const initialValues = useMemo(() => formatValuesToFE(initialAudienceCriterions), [initialAudienceCriterions])

  const handleSubmit = useCallback(
    (values, setManualSuccessSubmit) => {
      const formattedUpdateItemData = transformValuesToBE({
        values,
        initialValues,
        account: selectedAdAccountId,
        adGroupId: item.id
      })

      const { operations, remove_operations: removeOperations } = formattedUpdateItemData

      // if nothing was added or deleted, set manual success to true
      if (!operations.length && !removeOperations.length) {
        setManualSuccessSubmit(true)
      } else {
        dispatch(createLineItemCriterions(formattedUpdateItemData, formattedUpdateItemData['remove_operations']))
      }
    },
    [dispatch, initialValues, item.id, selectedAdAccountId]
  )

  const { onSubmit, manualSuccessSubmit } = useEditSectionFormSubmit({ initialValues, handleSubmit })

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const handleClearUpdateItem = useCallback(() => {
    dispatch(clearCreateLineItemCriterions())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      getGoogleUsersList({
        account: selectedAdAccountId,
        page_size: 50,
        eligible_for_display: true,
        membership_status: 2
      })
    )

    return () => {
      dispatch(clearGetGoogleUsersList())
    }
  }, [dispatch, selectedAdAccountId])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={manualSuccessSubmit || criterionsWasCreated}
      clearEditItem={handleClearUpdateItem}
      errorSelector={createLineItemCriterionsErrorSelector}
      isLoadingSelector={createLineItemCriterionsIsLoadingSelector}
      {...formProps}
    >
      <UsersAudiencesSelect formik={purifiedFormik} />
    </EditForm>
  )
}

export default AudienceSectionForm
