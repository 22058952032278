import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import usePermissions from '../../../../hooks/usePermissions'

import { insertIf } from '../../../../helpers/common'
import { showToasts } from '../../../../helpers/toasts'

import { openForm } from '../../../../modules/actions/forms'
import { approveInternalAd, deleteInternalAd, setSelectedAdForCopy } from '../../../../modules/actions/ads'
import { selectedPlatformSelector, selectedAdAccountIdSelector } from '../../../../modules/selectors/app'
import { campaignSelector } from '../../../../modules/selectors/campaigns'

import { AD_FACEBOOK_EDIT, AD_GOOGLE_EDIT, AD_TIK_TOK_EDIT } from '../../../../constants/forms'
import { FACEBOOK_PLATFORM, GOOGLE_PLATFORM, TIKTOK_PLATFORM } from '../../../../constants/selectLists/platformList'
import { TOAST_TYPE } from '../../../../constants/other'
import { AD_PERMISSION, INTERNAL_ADS_PERMISSION } from '../../../../constants/permissions'

const useGetAdActionsDropdownOptions = ({ handleDeleteRequest }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const selectedPlatform = useSelector(selectedPlatformSelector)
  const campaign = useSelector(campaignSelector)

  const permissions = usePermissions()

  const { objective: campaignObjective } = campaign

  const editFormName = useMemo(() => {
    switch (selectedPlatform) {
      case FACEBOOK_PLATFORM:
        return AD_FACEBOOK_EDIT
      case GOOGLE_PLATFORM:
        return AD_GOOGLE_EDIT
      case TIKTOK_PLATFORM:
        return AD_TIK_TOK_EDIT
      default:
        return ''
    }
  }, [selectedPlatform])

  return useCallback(
    ad => {
      const { id, status, internal: isInternal, preview: previewLink } = ad

      const isItemArchived = status && status.toLowerCase() === 'archived'

      const approveInternalAdHandler = () => {
        dispatch(approveInternalAd(id))
      }

      const deleteInternalAdHandler = () => {
        dispatch(deleteInternalAd(id))
      }

      const deleteAdHandler = () => {
        handleDeleteRequest({
          item: ad,
          account: selectedAdAccountId,
          campaignObjective
        })
      }

      const openEditFormHandler = () => {
        dispatch(
          openForm({
            id,
            formName: editFormName,
            ...(isInternal && { otherParams: 'internal=true' })
          })
        )
      }

      const copyAdHandler = () => {
        // Copy Ad is saving in redux and is reset on AdAccount change
        // it allows to represent paste options in LineItemsList item actions dropdown or on LineItem summary page
        dispatch(setSelectedAdForCopy(ad))

        showToasts({
          type: TOAST_TYPE.success,
          message: t('adCopiedSuccess', { adName: ad.name })
        })
      }

      return [
        ...insertIf(!isInternal && selectedPlatform === FACEBOOK_PLATFORM, {
          text: 'View preview on Facebook',
          link: previewLink,
          isLinkExternal: true
        }),
        ...insertIf(
          isInternal && permissions.can('manage', INTERNAL_ADS_PERMISSION),
          {
            text: 'Approve',
            onClickHandler: approveInternalAdHandler
          },
          {
            text: 'Delete',
            onClickHandler: deleteInternalAdHandler
          }
        ),
        ...insertIf(!isInternal && permissions.can('delete', AD_PERMISSION), {
          text: isItemArchived || selectedPlatform !== FACEBOOK_PLATFORM ? 'Delete' : 'Archive / Delete',
          onClickHandler: deleteAdHandler
        }),
        ...insertIf(permissions.can('update', AD_PERMISSION), {
          text: 'Edit',
          onClickHandler: openEditFormHandler
        }),
        ...insertIf(!isInternal && selectedPlatform === FACEBOOK_PLATFORM && permissions.can('create', AD_PERMISSION), {
          text: 'Copy Ad',
          onClickHandler: copyAdHandler
        })
      ]
    },
    [
      t,
      dispatch,
      permissions,
      selectedPlatform,
      handleDeleteRequest,
      selectedAdAccountId,
      campaignObjective,
      editFormName
    ]
  )
}

export default useGetAdActionsDropdownOptions
