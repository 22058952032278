import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import Table from '../../../../../../components/Table'
import DiscountPresetActions from './DiscountPresetActions'

import { tableColumnsSize } from '../index'

import { phonesDownSize } from '../../../../../../styles/const/breakpoints'

import { discountPresetsSelector } from '../../../../../../modules/selectors/discounts'

const DiscountPresetsTableData = () => {
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const discountPresets = useSelector(discountPresetsSelector)

  const discountPresetsColumns = useMemo(
    () => [
      {
        header: 'Name',
        Cell: ({ name }) => <div>{name}</div>
      },

      {
        Cell: data => <DiscountPresetActions itemData={data} />,
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [isMobile]
  )

  return (
    <Table
      data={discountPresets}
      cols={discountPresetsColumns}
      tableId="DiscountPresetsTableData"
      hideHeadlineRow
      hideFooterRow
    />
  )
}

export default DiscountPresetsTableData
