import update from 'immutability-helper'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_ADS,
  GET_ADS_SUCCESS,
  GET_ADS_FAILURE,
  CLEAR_GET_ADS,
  CREATE_AD,
  CREATE_AD_SUCCESS,
  CREATE_AD_FAILURE,
  CLEAR_CREATE_AD,
  VALIDATE_AD,
  VALIDATE_AD_SUCCESS,
  VALIDATE_AD_FAILURE,
  CLEAR_VALIDATE_AD,
  UPDATE_AD,
  UPDATE_AD_SUCCESS,
  UPDATE_AD_FAILURE,
  CLEAR_UPDATE_AD,
  DELETE_AD,
  DELETE_AD_SUCCESS,
  DELETE_AD_FAILURE,
  CLEAR_DELETE_AD,
  GET_AD,
  GET_AD_SUCCESS,
  GET_AD_FAILURE,
  CLEAR_AD,
  SET_SELECTED_AD_FOR_COPY,
  CLEAR_SELECTED_AD_FOR_COPY,
  COPY_AD,
  COPY_AD_SUCCESS,
  COPY_AD_FAILURE,
  CLEAR_COPY_AD,
  APPROVE_INTERNAL_AD,
  APPROVE_INTERNAL_AD_SUCCESS,
  APPROVE_INTERNAL_AD_FAILURE,
  CLEAR_APPROVE_INTERNAL_AD,
  DISAPPROVE_INTERNAL_AD,
  DISAPPROVE_INTERNAL_AD_SUCCESS,
  DISAPPROVE_INTERNAL_AD_FAILURE,
  CLEAR_DISAPPROVE_INTERNAL_AD,
  DELETE_INTERNAL_AD,
  DELETE_INTERNAL_AD_SUCCESS,
  DELETE_INTERNAL_AD_FAILURE,
  CLEAR_DELETE_INTERNAL_AD,
  SET_AD_MODIFIED_DATA,
  SET_AD_CAROUSEL_SELECTED_INDEX,
  CLEAR_AD_PREVIEW,
  TOGGLE_IS_SOUND_MUTED,
  CREATE_ADS_BATCH,
  CREATE_ADS_BATCH_SUCCESS,
  CREATE_ADS_BATCH_FAILURE,
  CLEAR_CREATE_ADS_BATCH,
  GET_INTERNAL_ADS_ITEMS_COUNT,
  GET_INTERNAL_ADS_ITEMS_COUNT_SUCCESS,
  GET_INTERNAL_ADS_ITEMS_COUNT_FAILURE,
  CLEAR_INTERNAL_ADS_ITEMS_COUNT,
  DECREMENT_INTERNAL_ADS_ITEMS_COUNT
} from '../actions/ads'
import { deleteItemById, updateItem } from '../../helpers/modules/reducerHelpers'
import {
  INITIAL_CREATE_REDUCER,
  INITIAL_UPDATE_REDUCER,
  INITIAL_GET_REDUCER,
  INITIAL_GET_RESULTS_REDUCER,
  INITIAL_LOADING_REDUCER,
  INITIAL_GET_RESULTS_PAGING_REDUCER
} from '../../constants/reducer'

const INITIAL_VALIDATE_AD_REDUCER = {
  ...INITIAL_LOADING_REDUCER,
  wasValidated: false,
  isValid: false
}

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  ad: INITIAL_GET_REDUCER,
  ads: INITIAL_GET_RESULTS_PAGING_REDUCER,
  adPreview: {
    adModifiedData: {},
    adCarouselSelectedIndexes: {},
    isSoundMuted: true
  },
  approveInternalAd: INITIAL_UPDATE_REDUCER,
  copyAd: INITIAL_CREATE_REDUCER,
  createAdsBatch: INITIAL_CREATE_REDUCER,
  createdAd: INITIAL_CREATE_REDUCER,
  deleteInternalAd: INITIAL_UPDATE_REDUCER,
  deletedAd: {},
  disapproveInternalAd: INITIAL_UPDATE_REDUCER,
  selectedAdForCopy: null,
  updatedAd: INITIAL_UPDATE_REDUCER,
  validatedAd: {
    currentDomain: '',
    domains: {}
  },
  internalAdsItemsCount: INITIAL_GET_RESULTS_REDUCER
}

export default function ads(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // get ads
    case GET_ADS:
      return update(state, {
        ads: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_ADS_SUCCESS:
      // Currently we use pagination only for facebook, it has different response structure
      const adsList = action.data.data ? action.data.data : action.data

      return update(state, {
        ads: {
          results: {
            $push: adsList
          },
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: {
              before: action.data.paging?.cursors?.before,
              after: action.data.paging?.cursors?.after
            }
          }
        }
      })
    case GET_ADS_FAILURE:
      return update(state, {
        ads: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_ADS:
      return update(state, {
        ads: { $set: initialState.ads }
      })

    // get single ad data
    case GET_AD:
      return update(state, {
        ad: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_AD_SUCCESS:
      return update(state, {
        ad: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.adData
          }
        },
        // push to list if was requested
        ...(action.pushToList && {
          ads: {
            results: {
              $unshift: [action.adData]
            }
          }
        })
      })
    case GET_AD_FAILURE:
      return update(state, {
        ad: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_AD: {
      return update(state, {
        ad: { $set: initialState.ad }
      })
    }

    // create ad
    case CREATE_AD:
      return update(state, {
        createdAd: {
          $merge: {
            isLoading: true
          }
        }
      })
    case CREATE_AD_SUCCESS:
      return update(state, {
        createdAd: {
          $merge: {
            isLoading: false,
            wasCreated: true
          }
        },
        ads: {
          results: {
            $unshift: [action.adData]
          }
        }
      })
    case CREATE_AD_FAILURE:
      return update(state, {
        createdAd: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_AD: {
      return update(state, {
        createdAd: { $set: initialState.createdAd }
      })
    }

    // Create ads batch
    case CREATE_ADS_BATCH:
      return update(state, {
        createAdsBatch: {
          $merge: {
            isLoading: true
          }
        }
      })
    case CREATE_ADS_BATCH_SUCCESS:
      return update(state, {
        createAdsBatch: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.adsData
          }
        }
      })
    case CREATE_ADS_BATCH_FAILURE:
      return update(state, {
        createAdsBatch: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_ADS_BATCH: {
      return update(state, {
        createAdsBatch: { $set: initialState.createAdsBatch }
      })
    }

    // validate ad
    case VALIDATE_AD:
      const conversionDomain = action.adData.conversion_domain
      return update(state, {
        validatedAd: {
          domains: {
            $merge: {
              [conversionDomain]: {
                ...INITIAL_VALIDATE_AD_REDUCER,
                isLoading: true
              }
            }
          }
        }
      })
    case VALIDATE_AD_SUCCESS:
      return update(state, {
        validatedAd: {
          domains: {
            $merge: {
              [action.conversionDomain]: {
                ...INITIAL_VALIDATE_AD_REDUCER,
                isLoading: false,
                wasValidated: true,
                isValid: true
              }
            }
          }
        }
      })
    case VALIDATE_AD_FAILURE:
      return update(state, {
        validatedAd: {
          domains: {
            $merge: {
              [action.conversionDomain]: {
                isLoading: false,
                wasValidated: true,
                isValid: false,
                error: action.error
              }
            }
          }
        }
      })
    case CLEAR_VALIDATE_AD: {
      return update(state, {
        validatedAd: { $set: initialState.validatedAd }
      })
    }

    // update ad
    case UPDATE_AD:
      return update(state, {
        updatedAd: {
          $merge: {
            [action.id]: true,
            id: action.id,
            isLoading: true
          }
        }
      })

    case UPDATE_AD_SUCCESS:
      const isArchived = action.adData.status === 'archived'

      return update(state, {
        ads: {
          // if we archive ad we should remove this ad from active/paused ads list,
          // we use for this purpose deleteItemById() method
          results: {
            $apply: isArchived
              ? items => deleteItemById(items, action.adData.id)
              : items => updateItem(items, action.adData)
          }
        },
        updatedAd: {
          $merge: {
            isLoading: false,
            id: action.adData.id,
            wasUpdated: true
          }
        },
        ad: {
          $merge: {
            data: action.adData
          }
        }
      })

    case UPDATE_AD_FAILURE:
      return update(state, {
        updatedAd: {
          $set: {
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_AD:
      return update(state, {
        updatedAd: { $set: initialState.updatedAd }
      })

    // delete ad
    case DELETE_AD:
      return update(state, {
        deletedAd: { $set: { isLoading: true, [action.id]: true, id: action.id } }
      })
    case DELETE_AD_SUCCESS:
      return update(state, {
        ads: {
          results: {
            $apply: items => deleteItemById(items, action.id)
          }
        },
        deletedAd: { $set: { isLoading: false, id: action.id, wasDeleted: true } }
      })
    case DELETE_AD_FAILURE:
      return update(state, {
        deletedAd: { $set: { isLoading: false, error: action.error } }
      })
    case CLEAR_DELETE_AD: {
      return update(state, {
        deletedAd: { $set: initialState.deletedAd }
      })
    }

    // copy ad
    case SET_SELECTED_AD_FOR_COPY: {
      return update(state, {
        selectedAdForCopy: { $set: action.adData }
      })
    }
    case CLEAR_SELECTED_AD_FOR_COPY: {
      return update(state, {
        selectedAdForCopy: { $set: initialState.selectedAdForCopy }
      })
    }
    case COPY_AD:
      return update(state, {
        copyAd: {
          $merge: {
            isLoading: true
          }
        }
      })
    case COPY_AD_SUCCESS:
      return update(state, {
        copyAd: {
          $merge: {
            isLoading: false,
            data: action.copiedAdDetails,
            wasCreated: true
          }
        }
      })
    case COPY_AD_FAILURE:
      return update(state, {
        copyAd: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_COPY_AD: {
      return update(state, {
        copyAd: { $set: initialState.copyAd }
      })
    }

    // Approve internal ad
    case APPROVE_INTERNAL_AD:
      return update(state, {
        approveInternalAd: { $merge: { isLoading: true, [action.id]: true, id: action.id } }
      })
    case APPROVE_INTERNAL_AD_SUCCESS:
      return update(state, {
        approveInternalAd: {
          $merge: {
            isLoading: false,
            wasUpdated: true
          }
        },
        ads: {
          results: { $apply: items => updateItem(items, action.data, action.id) }
        }
      })
    case APPROVE_INTERNAL_AD_FAILURE:
      return update(state, {
        approveInternalAd: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_APPROVE_INTERNAL_AD:
      return update(state, {
        approveInternalAd: { $set: initialState.approveInternalAd }
      })

    // Disapprove internal ad
    case DISAPPROVE_INTERNAL_AD:
      return update(state, {
        disapproveInternalAd: { $merge: { isLoading: true, id: action.id } }
      })
    case DISAPPROVE_INTERNAL_AD_SUCCESS:
      return update(state, {
        disapproveInternalAd: {
          $merge: {
            isLoading: false,
            wasUpdated: true
          }
        },
        ads: {
          results: { $apply: items => deleteItemById(items, action.id) }
        }
      })
    case DISAPPROVE_INTERNAL_AD_FAILURE:
      return update(state, {
        disapproveInternalAd: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DISAPPROVE_INTERNAL_AD:
      return update(state, {
        disapproveInternalAd: { $set: initialState.disapproveInternalAd }
      })

    // Delete internal ad
    case DELETE_INTERNAL_AD:
      return update(state, {
        deleteInternalAd: { $merge: { isLoading: true, [action.id]: true, id: action.id } }
      })
    case DELETE_INTERNAL_AD_SUCCESS:
      return update(state, {
        deleteInternalAd: {
          $merge: {
            isLoading: false,
            wasDeleted: true
          }
        },
        ads: {
          results: { $apply: items => deleteItemById(items, action.id) }
        }
      })
    case DELETE_INTERNAL_AD_FAILURE:
      return update(state, {
        deleteInternalAd: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DELETE_INTERNAL_AD:
      return update(state, {
        deleteInternalAd: { $set: initialState.deleteInternalAd }
      })

    case GET_INTERNAL_ADS_ITEMS_COUNT:
      return update(state, {
        internalAdsItemsCount: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_INTERNAL_ADS_ITEMS_COUNT_SUCCESS:
      return update(state, {
        internalAdsItemsCount: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.results
          }
        }
      })
    case GET_INTERNAL_ADS_ITEMS_COUNT_FAILURE:
      return update(state, {
        internalAdsItemsCount: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case DECREMENT_INTERNAL_ADS_ITEMS_COUNT:
      const provider = action.provider
      const internalAdsItemsCount = state.internalAdsItemsCount.results
      const currentProvider = internalAdsItemsCount.find(item => item.provider === provider)
      const currentProviderCount = currentProvider?.count

      if (currentProviderCount) {
        const updatedData = [...internalAdsItemsCount]
        updatedData.find(item => item.provider === provider).count = Number(currentProviderCount) - 1

        return update(state, {
          internalAdsItemsCount: {
            $merge: {
              results: updatedData
            }
          }
        })
      } else {
        return state
      }
    case CLEAR_INTERNAL_ADS_ITEMS_COUNT:
      return update(state, {
        internalAdsItemsCount: { $set: initialState.internalAdsItemsCount }
      })

    // modified data
    case SET_AD_MODIFIED_DATA:
      return update(state, {
        adPreview: { $merge: { adModifiedData: action.adModifiedData } }
      })
    case SET_AD_CAROUSEL_SELECTED_INDEX:
      return update(state, {
        adPreview: {
          adCarouselSelectedIndexes: {
            $merge: {
              [action.adId]: action.selectedIndex
            }
          }
        }
      })
    case CLEAR_AD_PREVIEW:
      return update(state, {
        adPreview: { $set: initialState.adPreview }
      })
    case TOGGLE_IS_SOUND_MUTED:
      return update(state, {
        adPreview: { $merge: { isSoundMuted: !state.adPreview.isSoundMuted } }
      })

    default:
      return state
  }
}
