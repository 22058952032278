import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../components/Form'
import ProductLocationFormContent from '../../ProductLocationFormContent'

import { transformValuesToBE } from './formatters'

import {
  clearCreateMediaProductLocation,
  createMediaProductLocation
} from '../../../../../modules/actions/mediaOrdersProductLocations'
import {
  createMediaProductLocationErrorSelector,
  createMediaProductLocationIsLoadingSelector,
  createMediaProductLocationWasCreatedSelector
} from '../../../../../modules/selectors/mediaOrdersProductLocations'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'
import { controllerSelector } from '../../../../../modules/selectors/controller'

import { MEDIA_PRODUCT_LOCATION_CREATE } from '../../../../../constants/forms'
import { initialValues } from './fields'
import { validationSchema } from '../../validation'

function MediaProductLocationCreateForm({ onSuccessSubmit }) {
  const dispatch = useDispatch()

  const locationWasCreated = useSelector(createMediaProductLocationWasCreatedSelector)
  const controllerId = useSelector(selectedControllerIdSelector)
  const { timezone: controllerTimezone } = useSelector(controllerSelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE({ values, controllerTimezone, controllerId })

      dispatch(
        createMediaProductLocation({
          controller: controllerId,
          ...transformedData
        })
      )
    },
    [dispatch, controllerId, controllerTimezone]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const clearCreateItemHandler = useCallback(() => dispatch(clearCreateMediaProductLocation()), [dispatch])

  return (
    <Form
      formik={formik}
      formName={MEDIA_PRODUCT_LOCATION_CREATE}
      // processing
      successSubmit={locationWasCreated}
      errorSelector={createMediaProductLocationErrorSelector}
      isLoadingSelector={createMediaProductLocationIsLoadingSelector}
      // after form submit
      clearHandler={clearCreateItemHandler}
    >
      <ProductLocationFormContent formik={formik} />
    </Form>
  )
}

export default MediaProductLocationCreateForm
