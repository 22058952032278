import { createStyles } from '../../../styles/helpers'
import { grey, lightGrey2 } from '../../../styles/const/colors'
import { textEllipsis } from '../../../styles/mixins/text'

const useStyles = createStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',

    '&:not(:last-child)': {
      borderBottom: {
        width: 1,
        style: 'solid',
        color: lightGrey2
      }
    },
    '&:last-child': {
      paddingBottom: 0
    }
  },
  firstRow: {
    paddingTop: 0
  },
  headlineRow: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    color: grey,
    marginBottom: 15,
    '& div': {
      fontSize: 10
    }
  },
  col: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 5px',
    width: '100%',
    fontSize: 14,
    textAlign: 'right',
    ...textEllipsis(),

    '&> span': {
      // skeleton fix to stretch it for column width
      width: '100%'
    }
  }
})

export default useStyles
