import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import WelcomeSection from './Sections/WelcomeSection'
import MediaPackagesSection from './Sections/MediaPackagesSection'
import MediaOrderCreate from '../../../forms/Multiplatform/MediaOrderForms/MediaOrderCreate'
import SkeletonLandingPageContent from './Skeleton'

import { clearMediaPackages, getMediaPackages } from '../../../modules/actions/mediaPackages'
import { selectedControllerIdSelector, selectedSelfAccountSelector } from '../../../modules/selectors/app'
import {
  mediaPackagesErrorSelector,
  mediaPackagesSelector,
  mediaPackagesWasLoadedSelector
} from '../../../modules/selectors/mediaPackages'

const LandingPageContent = () => {
  const dispatch = useDispatch()

  const selfAccountId = useSelector(selectedSelfAccountSelector)
  const controllerId = useSelector(selectedControllerIdSelector)
  const mediaPackages = useSelector(mediaPackagesSelector)
  const mediaPackagesWasLoaded = useSelector(mediaPackagesWasLoadedSelector)
  const mediaPackagesError = useSelector(mediaPackagesErrorSelector)
  const mediaPackagesDidLoading = mediaPackagesWasLoaded || mediaPackagesError

  const showLoading = !mediaPackagesDidLoading

  const hasMediaPackages = !!mediaPackages.length

  useEffect(() => {
    dispatch(getMediaPackages({ controller: controllerId, status: 'active', availability: true }))
  }, [dispatch, controllerId, selfAccountId])

  useEffect(
    () => () => {
      dispatch(clearMediaPackages())
    },
    [dispatch]
  )

  return (
    <>
      {showLoading ? (
        <SkeletonLandingPageContent />
      ) : (
        <>
          <WelcomeSection />
          {hasMediaPackages && <MediaPackagesSection />}
          <MediaOrderCreate />
        </>
      )}
    </>
  )
}

export default LandingPageContent
