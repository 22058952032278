import { createStyles } from '../../../../styles/helpers'

import { grey, greyOutline } from '../../../../styles/const/colors'

export default createStyles(theme => ({
  productCard: {
    position: 'relative',
    padding: ({ isSelected }) => (isSelected ? '10px 39px 10px 13px' : '11px 40px 11px 14px'),
    cursor: 'pointer',
    background: 'white',
    display: 'flex',
    columnGap: 8,
    border: {
      width: 1,
      style: 'solid',
      radius: theme.defaultBorderRadius
    },
    borderWidth: ({ isSelected }) => (isSelected ? 2 : 1),
    borderColor: ({ isSelected }) => (isSelected ? theme.brandPrimary : greyOutline),
    marginBottom: ({ withMarginBottom }) => (withMarginBottom ? 8 : 0)
  },
  productLink: {
    position: 'absolute',
    top: ({ withRemoveButton }) => (withRemoveButton ? 50 : 10),
    right: 10
  },
  productLinkIcon: {
    width: 16,
    height: 16,
    '& path': {
      fill: theme.brandPrimary
    }
  },
  productLinkText: {
    color: grey,
    textDecoration: 'none',
    fontSize: [10, '!important'],
    marginTop: [2, '!important']
  },
  productCardImageContainer: {
    width: 56,
    height: 56,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  productCardImage: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  productCardDetails: {
    width: '100%',
    paddingTop: 4
  },
  productName: {
    fontSize: 12
  },
  productChips: {
    marginTop: 8,
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 2,
    rowGap: 8
  },
  removeButton: {
    position: 'absolute',
    top: 10,
    right: 8
  }
}))
