import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR
export const marginsSelector = createSelector(
  ({ margins }) => margins.margins.results,
  results => results
)

export const marginsIsLoadingSelector = createSelector(
  ({ margins }) => margins.margins.isLoading,
  isLoading => isLoading
)

export const marginsErrorSelector = createSelector(
  ({ margins }) => margins.margins.error,
  error => error
)

export const marginsWasLoadedSelector = createSelector(
  ({ margins }) => margins.margins.wasLoaded,
  wasLoaded => wasLoaded
)

export const createMarginsIsLoadingSelector = createSelector(
  ({ margins }) => margins.createMargins.isLoading,
  isLoading => isLoading
)

export const createMarginsErrorSelector = createSelector(
  ({ margins }) => margins.createMargins.error,
  error => error
)

export const createMarginsWasCreatedSelector = createSelector(
  ({ margins }) => margins.createMargins.wasCreated,
  wasLoaded => wasLoaded
)

export const createMarginOverrideIsLoadingSelector = createSelector(
  ({ margins }) => margins.createMarginOverride.isLoading,
  isLoading => isLoading
)

export const createMarginOverrideErrorSelector = createSelector(
  ({ margins }) => margins.createMarginOverride.error,
  error => error
)

export const createMarginOverrideWasCreatedSelector = createSelector(
  ({ margins }) => margins.createMarginOverride.wasCreated,
  wasLoaded => wasLoaded
)
