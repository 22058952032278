import rtl from 'rtl-css-js'

const convert = rtl['default'] || rtl

// this helper is replica of the jss-rtl plugin from npm
// the original plugin doesn't cover functional dynamic styles and the case of updating the style
function jssRTL(_a) {
  const _b = _a === void 0 ? {} : _a,
    _c = _b.enabled,
    enabled = _c === void 0 ? true : _c,
    _d = _b.opt,
    opt = _d === void 0 ? 'out' : _d
  return {
    onProcessStyle: function (style, rule, sheet) {
      if (rule.type === 'font-face') {
        return style
      }
      if (!enabled) {
        if (typeof style.flip === 'boolean') {
          delete style.flip
        }
        return style
      }
      let flip = opt === 'out' // If it's set to opt-out, then it should flip by default
      if (typeof sheet.options.flip === 'boolean') {
        flip = sheet.options.flip
      }
      if (typeof style.flip === 'boolean') {
        flip = style.flip
        delete style.flip
      }
      if (!flip) {
        return style
      }

      return convert(typeof rule.toJSON === 'function' ? rule.toJSON() : style)
    },
    onChangeValue: function (value, prop, rule) {
      if (enabled && value) {
        const converted = convert({ [prop]: value })
        const newProp = Object.keys(converted)[0]

        if (newProp !== prop) {
          // console.log('newProp !== prop: ', rule.key, rule.style, newProp, prop, 'converted', converted)
          // when there is a new prop after converting - delete current prop and add new one
          // this covers the case when the prop is being flipped for example:
          // margin-left -> margin-right the left prop will be deleted and right will be added

          delete rule.style[prop]
          rule.style = {
            ...rule.style,
            // add new prop
            ...converted
          }
        }

        // when null or undefined is returned the prop will be deleted
        return converted[prop]
      } else {
        return value
      }

      // if (newProp !== prop) {
      //   //   // it means the prop was flipped for example: margin-left -> margin-right
      //   //
      //   const mirrorProp = rule.style[newProp]
      //   const hasMirror = !!rule.style[newProp] || mirrorProp === 0
      //
      //   console.log("newProp", rule.key, newProp,  rule.style, mirrorProp, hasMirror)
      //
      //   if(hasMirror){
      //     // console.log("convertedQueryCache", convertedQueryCache)
      //     console.log("hasMirror", rule.key, prop, mirrorProp)
      //     const convertedMirrorProp = convertedQueryCache[rule.key] && convertedQueryCache[rule.key][prop]
      //     console.log("convertedMirrorProp", rule.key, convertedMirrorProp)
      //
      //     if (convertedMirrorProp){
      //       rule.style = {
      //         ...rule.style,
      //         // add new prop
      //         ...converted
      //       }
      //
      //       return convertedMirrorProp[prop]
      //     } else {
      //       console.log("add newProp to cache ->", rule.key, newProp)
      //       !convertedQueryCache[rule.key] && (convertedQueryCache[rule.key] = {})
      //       convertedQueryCache[rule.key][newProp] = converted
      //       return value
      //     }
      //
      //   } else {
      //     // when there is a new prop after converting - delete current prop and add new one
      //     // this covers the case when the prop is being flipped for example:
      //     // margin-left -> margin-right the left prop will be deleted and right will be added
      //
      //     rule.style = {
      //       ...rule.style,
      //       // add new prop
      //       ...converted
      //     }
      //   }
      //
    }
    // leave the ideas below for future
    // onUpdate: function (data, rule, sheet) {
    //   if (enabled) {
    //     const style = typeof rule.toJSON === 'function' ? rule.toJSON() : rule.style
    //
    //     const converted = convert(style)
    //
    //     Object.keys(style).forEach((key) => {
    //       if (style[key] !== converted[key]) {
    //         console.log("rule, key, converted[key]", rule, key, converted[key])
    //         rule.style[key] = converted[key]
    //         // rule.renderer.removeProperty(rule, key)
    //
    //         // rule.renderer.setProperty({
    //         //   selectorText: rule.selectorText
    //         // }, key, converted[key])
    //       }
    //     })
    //
    //     return rule
    //
    //     // const updateStyle = {
    //     //   ...style,
    //     //   ...converted
    //     // }
    //     //
    //     // rule.style = {
    //     //   ...updateStyle
    //     // }
    //     // rule.style = {
    //     //     ...converted
    //     // }
    //     // console.log('onUpdate ', rule, rule.key, 'style: ', style, 'converted', converted)
    //   }
    //   // var style = (typeof rule.toJSON === 'function') ? rule.toJSON() : rule.style;
    //   // const convertedStyle = convert(style);
    //
    //   // rule.renderer.setProperty(rule, 'style', converted);
    //
    //   // console.log("data, rule", rule.id, data, rule)
    //
    //   // var style = (typeof rule.toJSON === 'function') ? rule.toJSON() : rule.style;
    //   // var converted = convert(style);
    //   // Object.assign(rule.style, converted);
    //   // console.log("on update rule.renderer", rule.renderer)
    //   // rule.renderer?.update(rule);
    //   // return convertedStyle;
    // }
  }
}

export default jssRTL
