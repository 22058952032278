import React from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import Radio from '../Radio'
import ErrorMessage from '../ErrorMessage'
import Chip from '../../../components/Chip'

import useStyles from './style'

function RadioBlock({
  children,
  selectedValue,
  setFieldValue,
  error,
  touched,
  badgeText,
  badgeColor,
  disabled,
  description,
  sideContent,
  sideContentClassName,
  Icon,
  iconClassName,
  hasOrSpacing,
  ...props
}) {
  const { t } = useTranslation()

  const isChecked = selectedValue === props.value
  const classes = useStyles({ isChecked, hasDecoration: !!description, hasOrSpacing })
  const onChange = e => {
    setFieldValue(e.target.name, e.target.value)
  }

  return (
    <>
      <div className={classnames('radioBlock', classes.radioBlock)}>
        <div className={classes.radioBlockContent}>
          <Radio
            isBig
            disabled={disabled}
            checked={isChecked}
            onChange={onChange}
            titleClassName={classes.radioBlockTitle}
            {...props}
          />
          {description && <div className={classes.description}>{description}</div>}
          {children && isChecked && (
            <div className={classes.radioBody}>
              {/*show horizontal line only if description is present*/}
              {description && <hr className={classes.radioBlockHr} />}
              {children}
              {error && touched && <ErrorMessage error={error} />}
            </div>
          )}
        </div>
        {(sideContent || Icon) && (
          <div className={classnames(classes.sideContent, sideContentClassName)}>
            {sideContent}
            {Icon && <Icon className={classnames(classes.radioBlockIcon, iconClassName)} />}
          </div>
        )}
        {badgeText && <Chip text={badgeText} isBadge color={badgeColor} />}
      </div>
      {hasOrSpacing && <div className={classes.orSpacing}>{t('Or')}</div>}
    </>
  )
}

export default RadioBlock
