import React, { memo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Col from '../Col'
import MobileHiddenCols from '../MobileHiddenCols'
import MinimisedSection from '../../../../MinimisedSection'

import useStyles from '../../../styles'

const TableRowMobile = memo(
  ({
    cols,
    rowData,
    rowIndex,
    isBigRow,
    mobileColumnsWrapperClassName,
    mobileMinimisedSectionBtnWrapperClassName,
    renderExpandedContent,
    rowClassName
  }) => {
    const classes = useStyles({ isBigRow })

    return (
      <MinimisedSection
        isFullyClickable={false}
        toggleButtonClassName={classes.minimisedSectionButton}
        headerContent={
          <div className={classnames(classes.mobileColumnsWrapper, mobileColumnsWrapperClassName)}>
            {cols
              .filter(({ showOnMobile }) => showOnMobile !== false)
              .map(colData => (
                <Col colData={colData} rowData={rowData} rowIndex={rowIndex} key={uuidv4()} />
              ))}
          </div>
        }
        className={classnames(classes.mobileRow, rowClassName)}
        toggleWrapperClassName={classnames(classes.filesSectionToggle, mobileMinimisedSectionBtnWrapperClassName)}
      >
        <div className={classes.minimisedSectionBody}>
          <MobileHiddenCols cols={cols} rowData={rowData} rowIndex={rowIndex} />
        </div>
        {renderExpandedContent && <div>{renderExpandedContent(rowData)}</div>}
      </MinimisedSection>
    )
  }
)

const colsPropTypeShape = {
  // header
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]),
  headClassName: PropTypes.string,
  // main cell
  Cell: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  fieldKey: PropTypes.string,
  className: PropTypes.string,
  // footer
  footerClassName: PropTypes.string
}

TableRowMobile.propTypes = {
  rowData: PropTypes.object.isRequired,
  cols: PropTypes.arrayOf(PropTypes.shape(colsPropTypeShape)).isRequired,
  isBigRow: PropTypes.bool,
  rowIndex: PropTypes.number,
  mobileColumnsWrapperClassName: PropTypes.string,
  mobileMinimisedSectionBtnWrapperClassName: PropTypes.string
}

export default TableRowMobile
