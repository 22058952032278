import React from 'react'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import MarginOverrideRoute from './Routes/MarginOverrideRoute'
import MarginRoute from './Routes/MarginRoute'
import MarginCreateRouteSelector from './Steps/MarginCreateRouteSelector'

import useStepFormRoutes from '../../../../hooks/formHooks/useStepFormRoutes'

import { MARGIN_CREATE } from '../../../../constants/forms'
import { ALL_CAMPAIGNS, SPECIFIC_CAMPAIGN } from './fields'

const MarginCreate = () => {
  const manageStepFormRoutes = useStepFormRoutes({ initialRoute: ALL_CAMPAIGNS })

  const getSelectedRoute = props => {
    switch (manageStepFormRoutes.selectedRoute) {
      case ALL_CAMPAIGNS:
        return <MarginRoute formatOption={ALL_CAMPAIGNS} {...props} />
      case SPECIFIC_CAMPAIGN:
      default:
        return <MarginOverrideRoute formatOption={SPECIFIC_CAMPAIGN} {...props} />
    }
  }

  return (
    <FormDrawerWrapper
      openButtonText="Add Margin"
      formName={MARGIN_CREATE}
      onAfterFormClose={manageStepFormRoutes.handleFormClose}
    >
      {getSelectedRoute({
        formName: MARGIN_CREATE,
        submitText: 'Save and apply new margin',
        // FormRouteSelector component is adding in each Route as a first step and allows to control Route Selecting
        FormRouteSelector: <MarginCreateRouteSelector manageStepFormRoutes={manageStepFormRoutes} />
      })}
    </FormDrawerWrapper>
  )
}

export default MarginCreate
