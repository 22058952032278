import { CHIP_COLORS } from '../../../../../../../../constants/other'
import { greyOutline } from '../../../../../../../../styles/const/colors'

export const formatPixelItems = items => {
  if (items && items.length) {
    return items.map(item => {
      const isItemActive = item.status !== 'No recent activity'

      return {
        value: item.id,
        label: item.name,
        tag: isItemActive ? 'Active' : 'Inactive',
        tagColor: isItemActive ? CHIP_COLORS.green : CHIP_COLORS.grey,
        labelColor: !isItemActive && greyOutline
      }
    })
  } else {
    return []
  }
}
