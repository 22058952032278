import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import EditForm from '../../../../../../features/components/Forms/EditForm'
import CallToActionFields from '../../../../../ReusableFormFields/CallToActionFields'

import useUpdateTikTokAdHandler from '../../hooks'
import useEditAdPreview from '../../../../../../features/hooks/useEditAdPreview'
import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import { useEditSectionFormSubmit } from '../../../../../../hooks/formHooks/useEditSectionFormSubmit'

import { clearUpdateAd } from '../../../../../../modules/actions/ads'

import {
  adUpdateIsLoadingSelector,
  adUpdateErrorSelector,
  adWasUpdatedSelector
} from '../../../../../../modules/selectors/ads'

import { formatCTA } from '../../../fields'
import { getInitialValues, validationSchema } from './fields'
import tikTokCallToActionsList from '../../../../../../constants/selectLists/tikTokCallToActionsList'
import { CALL_TO_ACTION } from '../../../../../ReusableFormFields/CallToActionFields/fields'

import useStyles from './styles'

const CTASectionForm = ({ editItemData: adData, ...formProps }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const itemWasUpdated = useSelector(adWasUpdatedSelector)

  const initialValues = useMemo(() => getInitialValues(adData), [adData])

  const handleUpdateSubmit = useUpdateTikTokAdHandler({ adData })
  const handleSubmit = useCallback(
    values => {
      const ctaValue = formatCTA(values)
      handleUpdateSubmit({ [CALL_TO_ACTION]: ctaValue })
    },
    [handleUpdateSubmit]
  )

  const { onSubmit, manualSuccessSubmit } = useEditSectionFormSubmit({ initialValues, handleSubmit })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)
  useEditAdPreview({ [CALL_TO_ACTION]: purifiedFormik.values[CALL_TO_ACTION] })

  const handleClearUpdateItem = useCallback(() => {
    dispatch(clearUpdateAd())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      className={classes.ctaForm}
      initialValues={initialValues}
      successSubmit={itemWasUpdated || manualSuccessSubmit}
      clearEditItem={handleClearUpdateItem}
      errorSelector={adUpdateErrorSelector}
      isLoadingSelector={adUpdateIsLoadingSelector}
      {...formProps}
    >
      <CallToActionFields formik={formik} actionsList={tikTokCallToActionsList} />
    </EditForm>
  )
}

export default CTASectionForm
