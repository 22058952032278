import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import StatusCell from './StatusCell'
import Table from '../../../../../components/Table'
import MediaProductAction from './MediaProductAction'
import ThumbnailCell from '../../../../../components/Table/ReusableCells/Thumbnail'
import DeleteProductModal from '../../DeleteProductModal'
import { ReactComponent as DragableDots } from '../../../../../assets/icons/draggable-dots.svg'

import useMediaProductActions from './MediaProductAction/useMediaProductActions'

import { formatCurrency } from '../../../../../helpers/numbers'
import useOrderList from '../../../../../hooks/useOrderList'
import { transformResponseValueToLabel } from './helpers'

import { updateProductsOrder } from '../../../../../modules/actions/mediaOrdersProducts'
import { selectedControllerDataSelector } from '../../../../../modules/selectors/app'
import { deleteProductSelector, updateProductIdSelector } from '../../../../../modules/selectors/mediaOrdersProducts'

import { tableColumnsSize } from '../index'
import { PRODUCT_STATUS } from '../../../../../forms/Multiplatform/MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'

import { phonesDownSize } from '../../../../../styles/const/breakpoints'

import useStyles from './styles'

const ProductsTableData = ({ products }) => {
  const classes = useStyles()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const updateProductId = useSelector(updateProductIdSelector)
  const { id: deleteProductId } = useSelector(deleteProductSelector)
  const selectedSelfAccountController = useSelector(selectedControllerDataSelector)
  const controllerCurrency = selectedSelfAccountController && selectedSelfAccountController.currency

  const {
    hideUpdateSkeleton,
    handleDelete,
    handleCloseDeleteModal,
    isDeleteModalOpened,
    deleteItemIsLoading,
    dataWaitingForDelete,
    openMangeInventoryFormHandler,
    openEditFormHandler,
    deleteProductHandler,
    updatePackageStatusHandler
  } = useMediaProductActions({ products })

  const { orderedList: orderedProducts, handleOrderUpdate: handleProductsOrderUpdate } = useOrderList(
    products,
    updateProductsOrder
  )

  const productsListColumns = useMemo(
    () => [
      {
        Cell: () => <DragableDots />,
        style: { maxWidth: tableColumnsSize.dragBtn }
      },
      {
        header: 'Active',
        Cell: productData => <StatusCell productData={productData} onStatusChange={updatePackageStatusHandler} />,
        style: { maxWidth: tableColumnsSize.statusToggle },
        showOnMobile: false
      },
      {
        Cell: ({ images }) => !!images?.length && <ThumbnailCell imageUrl={images[0]?.thumbnail} />,
        style: { maxWidth: tableColumnsSize.thumbnail }
      },
      {
        header: 'Product Name',
        fieldKey: 'name',
        className: classes.mobileNameFieldStyle,
        style: { whiteSpace: 'normal' }
      },
      {
        header: 'Category',
        Cell: ({ media_category }) => transformResponseValueToLabel(media_category),
        style: { maxWidth: tableColumnsSize.category },
        showOnMobile: false
      },
      {
        header: 'Price',
        Cell: ({ prices }) => {
          // show the price that has the same currency as controller
          const productPrice = controllerCurrency && prices.find(price => price.currency === controllerCurrency)
          const formattedPrice =
            productPrice && formatCurrency(productPrice?.price, { max: 0 }, { symbol: productPrice.currency_symbol })

          return productPrice && formattedPrice
        },
        style: { maxWidth: tableColumnsSize.price },
        showOnMobile: false
      },
      {
        Cell: ({ id, [PRODUCT_STATUS]: status, group }) => (
          <MediaProductAction
            isProductGroup={group}
            productId={id}
            status={status}
            updatePackageStatusHandler={updatePackageStatusHandler}
            openEditFormHandler={openEditFormHandler}
            deleteProductHandler={deleteProductHandler}
            openMangeInventoryFormHandler={openMangeInventoryFormHandler}
          />
        ),
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [
      controllerCurrency,
      isMobile,
      classes,
      updatePackageStatusHandler,
      openEditFormHandler,
      deleteProductHandler,
      openMangeInventoryFormHandler
    ]
  )

  // We don't need to show skeleton when update status.
  const updateItemId = hideUpdateSkeleton ? null : updateProductId

  const rowTemplate = useMemo(() => {
    return {
      // product group has marker on the row
      determineRowMarker: rowData => Boolean(rowData.group)
    }
  }, [])

  return (
    <>
      <Table
        tableId="ProductsTableData"
        data={orderedProducts}
        cols={productsListColumns}
        itemUpdatingId={updateItemId || deleteProductId}
        hideFooterRow
        onRowDrag={handleProductsOrderUpdate}
        mobileColumnsWrapperClassName={classes.mobileHeaderStyle}
        rowTemplate={rowTemplate}
      />
      <DeleteProductModal
        onDelete={handleDelete}
        productDeleteIsLoading={deleteItemIsLoading}
        product={dataWaitingForDelete?.item}
        isOpen={isDeleteModalOpened}
        onClose={handleCloseDeleteModal}
      />
    </>
  )
}

ProductsTableData.propTypes = {
  products: PropTypes.array.isRequired
}

export default ProductsTableData
