import { concat } from '../../helpers/common'

const MODULE_NAME = 'SELF_ACCOUNT_TEAM/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_MEMBERS_LIST = concat(MODULE_NAME, 'GET_MEMBERS_LIST')
export const GET_MEMBERS_LIST_SUCCESS = concat(MODULE_NAME, 'GET_MEMBERS_LIST_SUCCESS')
export const GET_MEMBERS_LIST_FAILURE = concat(MODULE_NAME, 'GET_MEMBERS_LIST_FAILURE')

export const GET_INVITES_LIST = concat(MODULE_NAME, 'GET_INVITES_LIST')
export const GET_INVITES_LIST_SUCCESS = concat(MODULE_NAME, 'GET_INVITES_LIST_SUCCESS')
export const GET_INVITES_LIST_FAILURE = concat(MODULE_NAME, 'GET_INVITES_LIST_FAILURE')

export const CREATE_MEMBER = concat(MODULE_NAME, 'CREATE_MEMBER')
export const CREATE_MEMBER_SUCCESS = concat(MODULE_NAME, 'CREATE_MEMBER_SUCCESS')
export const CREATE_MEMBER_FAILURE = concat(MODULE_NAME, 'CREATE_MEMBER_FAILURE')

export const CREATE_INVITE = concat(MODULE_NAME, 'CREATE_INVITE')
export const CREATE_INVITE_SUCCESS = concat(MODULE_NAME, 'CREATE_INVITE_SUCCESS')
export const CREATE_INVITE_FAILURE = concat(MODULE_NAME, 'CREATE_INVITE_FAILURE')

export const ACCEPT_INVITE = concat(MODULE_NAME, 'ACCEPT_INVITE')
export const ACCEPT_INVITE_SUCCESS = concat(MODULE_NAME, 'ACCEPT_INVITE_SUCCESS')
export const ACCEPT_INVITE_FAILURE = concat(MODULE_NAME, 'ACCEPT_INVITE_FAILURE')

export const UPDATE_SELF_ACCOUNT_MEMBER = concat(MODULE_NAME, 'UPDATE_SELF_ACCOUNT_MEMBER')
export const UPDATE_SELF_ACCOUNT_MEMBER_SUCCESS = concat(MODULE_NAME, 'UPDATE_SELF_ACCOUNT_MEMBER_SUCCESS')
export const UPDATE_SELF_ACCOUNT_MEMBER_FAILURE = concat(MODULE_NAME, 'UPDATE_SELF_ACCOUNT_MEMBER_FAILURE')

export const UPDATE_MEMBER_STATUS = concat(MODULE_NAME, 'UPDATE_MEMBER_STATUS')
export const UPDATE_MEMBER_STATUS_SUCCESS = concat(MODULE_NAME, 'UPDATE_MEMBER_STATUS_SUCCESS')
export const UPDATE_MEMBER_STATUS_FAILURE = concat(MODULE_NAME, 'UPDATE_MEMBER_STATUS_FAILURE')

export const DELETE_INVITE = concat(MODULE_NAME, 'DELETE_INVITE')
export const DELETE_INVITE_SUCCESS = concat(MODULE_NAME, 'DELETE_INVITE_SUCCESS')
export const DELETE_INVITE_FAILURE = concat(MODULE_NAME, 'DELETE_INVITE_FAILURE')

export const RESEND_INVITE = concat(MODULE_NAME, 'RESEND_INVITE')
export const RESEND_INVITE_SUCCESS = concat(MODULE_NAME, 'RESEND_INVITE_SUCCESS')
export const RESEND_INVITE_FAILURE = concat(MODULE_NAME, 'RESEND_INVITE_FAILURE')

export const CLEAR_MEMBERS_LIST = concat(MODULE_NAME, 'CLEAR_MEMBERS_LIST')
export const CLEAR_INVITES_LIST = concat(MODULE_NAME, 'CLEAR_INVITES_LIST')
export const CLEAR_UPDATE_MEMBER = concat(MODULE_NAME, 'CLEAR_UPDATE_MEMBER')
export const CLEAR_CREATE_MEMBER = concat(MODULE_NAME, 'CLEAR_CREATE_MEMBER')
export const CLEAR_CREATE_INVITE = concat(MODULE_NAME, 'CLEAR_CREATE_INVITE')
export const CLEAR_ACCEPT_INVITE = concat(MODULE_NAME, 'CLEAR_ACCEPT_INVITE')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

export function getMembersList(params) {
  return { type: GET_MEMBERS_LIST, params }
}

export function getMembersListSuccess(membersListData) {
  return { type: GET_MEMBERS_LIST_SUCCESS, membersListData }
}

export function getMembersListFailure(error) {
  return { type: GET_MEMBERS_LIST_FAILURE, error }
}

export function getInvitesList(params) {
  return { type: GET_INVITES_LIST, params }
}

export function getInvitesListSuccess(invitesListData) {
  return { type: GET_INVITES_LIST_SUCCESS, invitesListData }
}

export function getInvitesListFailure(error) {
  return { type: GET_INVITES_LIST_FAILURE, error }
}

export function createMember(memberData) {
  return { type: CREATE_MEMBER, memberData }
}

export function createMemberSuccess(memberData) {
  return { type: CREATE_MEMBER_SUCCESS, memberData }
}

export function createMemberFailure(error) {
  return { type: CREATE_MEMBER_FAILURE, error }
}

export function createInvite(inviteData) {
  return { type: CREATE_INVITE, inviteData }
}

export function createInviteSuccess(inviteData) {
  return { type: CREATE_INVITE_SUCCESS, inviteData }
}

export function createInviteFailure(error) {
  return { type: CREATE_INVITE_FAILURE, error }
}

export function acceptInvite(inviteData, submitLoginOnSuccess, removeInviteOnSuccess) {
  return { type: ACCEPT_INVITE, inviteData, submitLoginOnSuccess, removeInviteOnSuccess }
}

export function acceptInviteSuccess() {
  return { type: ACCEPT_INVITE_SUCCESS }
}

export function acceptInviteFailure(error) {
  return { type: ACCEPT_INVITE_FAILURE, error }
}

export function updateSelfAccountMember(id, data) {
  return { type: UPDATE_SELF_ACCOUNT_MEMBER, id, data }
}

export function updateSelfAccountMemberSuccess(updatedMemberData) {
  return { type: UPDATE_SELF_ACCOUNT_MEMBER_SUCCESS, updatedMemberData }
}

export function updateSelfAccountMemberFailure(error) {
  return { type: UPDATE_SELF_ACCOUNT_MEMBER_FAILURE, error }
}

export function updateMemberStatus(id, status) {
  return { type: UPDATE_MEMBER_STATUS, id, status }
}

export function updateMemberStatusSuccess(updatedMemberData) {
  return { type: UPDATE_MEMBER_STATUS_SUCCESS, updatedMemberData }
}

export function updateMemberStatusFailure(error) {
  return { type: UPDATE_MEMBER_STATUS_FAILURE, error }
}

export function clearUpdateMember() {
  return { type: CLEAR_UPDATE_MEMBER }
}

export function deleteInvite(id, removeInviteOnSuccess) {
  return { type: DELETE_INVITE, id, removeInviteOnSuccess }
}

export function deleteInviteSuccess(id) {
  return { type: DELETE_INVITE_SUCCESS, id }
}

export function deleteInviteFailure(error) {
  return { type: DELETE_INVITE_FAILURE, error }
}

export function resendInvite(id) {
  return { type: RESEND_INVITE, id }
}

export function resendInviteSuccess() {
  return { type: RESEND_INVITE_SUCCESS }
}

export function resendInviteFailure(error) {
  return { type: RESEND_INVITE_FAILURE, error }
}

export function clearMembersList() {
  return { type: CLEAR_MEMBERS_LIST }
}

export function clearInvitesList() {
  return { type: CLEAR_INVITES_LIST }
}

export function clearAcceptInvite() {
  return { type: CLEAR_ACCEPT_INVITE }
}

export function clearCreateMember() {
  return { type: CLEAR_CREATE_MEMBER }
}

export function clearCreateInvite() {
  return { type: CLEAR_CREATE_INVITE }
}
