import { calc } from '../../../helpers/numbers'

export const formatNumericInputValue = (value, decimalScale) => {
  // format value with decimal scale using calc, to have the correct precision to avoid internal library round
  // handle cases when library transform 40.215 to 40.21, when other calculations are 40.22
  // check if value is defined and value is valid Number to avoid error in calc
  if (value && Number(value)) {
    return calc(value).toDP(decimalScale).toString()
  } else {
    return value
  }
}
