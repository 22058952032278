import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Can from '../../../features/components/Can'
import ClientAccountCreate from './ClientAccountForms/ClientAccountCreate'
import ClientAccountWithMarginsCreate from './ClientAccountForms/ClientAccountWithMarginsCreate'
import ClientAccountEdit from './ClientAccountForms/ClientAccountEdit'
import ClientAccountsTable from './ClientAccountsTable'
import ContentSection from '../../../features/components/ContentSection'
import ContentRow from '../../../features/components/ContentSection/ContentRow'
import Tags from './Tags'
import FiltersSearch from '../../../features/components/Filters/FiltersSearch'

import { useLoadPaginatedList } from '../../../features/hooks/useLoadPaginatedList'
import useSearch from '../../../hooks/useSearch'

import { clearSelfAccounts, getSelfAccounts } from '../../../modules/actions/selfAccounts'
import { selectedAgencyIdSelector, selectedControllerIdSelector } from '../../../modules/selectors/app'
import { selfAccountsNextSelector, selfAccountsSelector } from '../../../modules/selectors/selfAccounts'

import { SELF_ACCOUNT_TAGS_MANAGE } from '../../../constants/permissions'
import { ADVERTISER_ACCOUNTS_FILTERS_SEARCH } from './fields'

import useSettingsClasses from '../styles'
import useStyles from './styles'

const AdvertiserAccounts = () => {
  const settingsClasses = useSettingsClasses()
  const classes = useStyles()

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const selfAccounts = useSelector(selfAccountsSelector)

  const selfAccountsNext = useSelector(selfAccountsNextSelector)
  const controllerId = useSelector(selectedControllerIdSelector)
  const agencyId = useSelector(selectedAgencyIdSelector)
  const searchTerm = useSearch(ADVERTISER_ACCOUNTS_FILTERS_SEARCH)

  const params = useMemo(() => {
    return {
      ...(controllerId ? { controller: controllerId } : {}),
      ...(agencyId ? { agency: agencyId } : {}),
      ...(searchTerm && { search: searchTerm })
    }
  }, [controllerId, agencyId, searchTerm])

  const loadMore = useLoadPaginatedList({
    params,
    action: getSelfAccounts,
    clearAction: clearSelfAccounts,
    next: selfAccountsNext
  })

  useEffect(
    () => () => {
      dispatch(clearSelfAccounts())
    },
    [dispatch]
  )

  return (
    <>
      <h1 className={settingsClasses.title}>{t('Manage your client accounts')}</h1>
      <ContentSection title={t('Client Accounts')}>
        <FiltersSearch searchId={ADVERTISER_ACCOUNTS_FILTERS_SEARCH} className={classes.accountsSearch} />
        <ContentRow
          title={t('Accounts list')}
          description={t(
            'Your client accounts are separate accounts which can contain one or more linked ad accounts. Each client account can have separate users so that your clients only access their client account.'
          )}
          leftColumnChildren={
            !!controllerId ? <ClientAccountWithMarginsCreate controllerId={controllerId} /> : <ClientAccountCreate />
          }
        >
          <ClientAccountsTable clientAccounts={selfAccounts} loadMore={loadMore} />
        </ContentRow>
        <Can I="manage" a={SELF_ACCOUNT_TAGS_MANAGE}>
          <Tags />
        </Can>
      </ContentSection>
      <ClientAccountEdit />
    </>
  )
}

export default AdvertiserAccounts
