import React, { useCallback, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'

import ActionText from '../../../../../../../components/ActionText'
import { MediaOrderFormContext } from '../../../../MediaOrderFormContext'

import { useClearMediaOrderForm } from '../../../../ContractMediaOrderGenerate/hooks/useClearMediaOrderForm'

import { redirectTo } from '../../../../../../../helpers/url'

import { closeForm } from '../../../../../../../modules/actions/forms'
import { createContract, clearCreateContract } from '../../../../../../../modules/actions/contracts'

import {
  contractWasCreatedSelector,
  createContractsErrorSelector,
  createContractsIsLoadingSelector,
  createdContractSelector
} from '../../../../../../../modules/selectors/contracts'
import { selectedSelfAccountSelector } from '../../../../../../../modules/selectors/app'

import { ROUTE_PARAMS, ROUTES } from '../../../../../../../constants/routes'

import useStyles from '../styles'

function CreateNoTermsMediaOrder() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { createdContractPayload } = useContext(MediaOrderFormContext)
  const createMediaOrderError = useSelector(createContractsErrorSelector)
  const selectedSelfAccountId = useSelector(selectedSelfAccountSelector)
  const createdContractData = useSelector(createdContractSelector)
  // in case of the media order creation without contract we should use the createContract endpoint
  const mediaOrderWasCreated = useSelector(contractWasCreatedSelector)
  const createMediaOrderIsLoading = useSelector(createContractsIsLoadingSelector)

  const handleCreateMediaOrder = useCallback(() => {
    // the contract with without_requirements creates the media order without need of sign or approve
    // so even though the contract is creating, the media order is created behind the scenes.

    dispatch(
      createContract({
        ...createdContractPayload,
        without_requirements: true // allows to create media order without terms in contract
      })
    )
  }, [dispatch, createdContractPayload])

  const handleSubmitFormClear = useClearMediaOrderForm()

  const handleMediaOrderCreateClear = useCallback(() => {
    dispatch(clearCreateContract())
  }, [dispatch])

  useEffect(() => {
    if (mediaOrderWasCreated) {
      handleSubmitFormClear()
      handleMediaOrderCreateClear()
      dispatch(closeForm())
      // redirectToGeneratedMediaOrder
      redirectTo(ROUTES.mediaOrderSummary, {
        // use the id of the method that was used - generated or created
        [ROUTE_PARAMS.mediaOrderId]: createdContractData?.media_order?.id,
        [ROUTE_PARAMS.selfAccount]: selectedSelfAccountId
      })
    }
  }, [
    handleMediaOrderCreateClear,
    selectedSelfAccountId,
    handleSubmitFormClear,
    mediaOrderWasCreated,
    dispatch,
    createdContractData?.media_order?.id
  ])

  useEffect(() => {
    if (createMediaOrderError) {
      handleMediaOrderCreateClear()
    }
  }, [createMediaOrderError, handleMediaOrderCreateClear])

  return createMediaOrderIsLoading ? (
    <div className={classes.createMediaOrderAction}>
      <Skeleton width="100px" />
    </div>
  ) : (
    <ActionText onClick={handleCreateMediaOrder} className={classes.createMediaOrderAction} isBold>
      {t('Create without contract')}
    </ActionText>
  )
}

export default CreateNoTermsMediaOrder
