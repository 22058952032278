import { DV_360_PLATFORM, FACEBOOK_PLATFORM, GOOGLE_PLATFORM, TIKTOK_PLATFORM } from './selectLists/platformList'
import { DOMAINS } from './selectLists/languagesList'
import { MANAGEMENT_REPORTING_SUB_ROUTES } from '../pages/LandingPage/ControllerLandingPageContent/ManagementReporting'

export const ROUTE_PARAMS = {
  selfAccount: 'selfAccount',
  platform: 'platform',
  adAccount: 'adAccount',
  campaignId: 'campaign_id',
  lineItemId: 'line_item_id',
  reportId: 'report_id',
  mediaOrderId: 'media_order_id'
}

export const MEDIA_ORDERS_SUB_ROUTES = {
  REQUESTED_FILES: 'requested-files',
  ONSITE_DIGITAL: 'onsite-digital',
  OFF_NETWORK_DIGITAL: 'off-network-digital',
  DIGITAL_SCREENS: 'digital-screens',
  DETAILS: 'details',
  DOCUMENTS: 'documents',
  AMENDMENTS: 'amendments'
}

export const SETTINGS_ROUTES = {
  MY_INFORMATION: 'my-information',
  MY_PASSWORD: 'my-password',
  PLAN_AND_BILLING: 'plan-and-billing',
  MANAGE_LOCATION_LISTS: 'manage-location-lists',
  PAGES_AND_IDENTITIES: 'pages-and-identities',
  MANAGE_TRIGGERS: 'manage-triggers',
  MANAGE_AUTOMATION_RULES: 'manage-automation-rules',
  HELP: 'help',
  AGENCY: 'agency',
  CONTROLLER_TEAM: 'controller_team',
  CONTROLLER: 'controller',
  ADVERTISER_ACCOUNTS: 'advertiser-accounts',
  AGENCY_ACCOUNTS: 'agency-accounts',
  MEDIA_PRODUCTS: 'media-products',
  DISCOUNTS: 'discounts',
  MEDIA_PACKAGES: 'media-packages',
  BOOKINGS: 'bookings',
  INVENTORY: 'inventory',
  REPORTING: 'reporting',
  MANAGE_MARGINS: 'manage-margins',
  BOOKED_MEDIA_AND_FILES: 'booked-media-and-files',
  INVOICING: 'invoicing',
  BRAND_PAGES: 'brand-pages'
}

const getPlatformUrl = () => {
  const currentAppUrl = window.location.href

  if (currentAppUrl.includes(DOMAINS.aduhu)) {
    return 'https://aduhu.com'
  } else if (currentAppUrl.includes(DOMAINS.cenomispace)) {
    return 'https://cenomispace.com'
  } else if (currentAppUrl.includes(DOMAINS.oneviu)) {
    return 'https://media.oneviu.ai'
  } else if (currentAppUrl.includes(DOMAINS.nexus)) {
    return 'https://nexus.cedarcom.co.uk'
  } else {
    return 'https://shopometry.com'
  }
}
// routes should not include trailing slash "/" in the end

export const ROUTE_SEGMENTS = {
  campaigns: 'campaigns',
  audiences: 'audiences',
  adApprovals: 'ad-approvals',
  calendars: 'calendars',
  dashboard: 'dashboard',
  authorise: 'authorise',
  adAccountAccessMissing: 'ad-account-access-missing',
  mediaOrders: 'media-orders',
  proposals: 'proposals',
  settings: 'settings'
}

const MEDIA_ORDERS_MAIN_ROUTE = `/:${ROUTE_PARAMS.selfAccount}/${ROUTE_SEGMENTS.mediaOrders}/:${ROUTE_PARAMS.mediaOrderId}`
const MANAGEMENT_REPORTING_MAIN_ROUTE = `/:${ROUTE_PARAMS.selfAccount}/${ROUTE_SEGMENTS.dashboard}`
const SETTINGS_MAIN_ROUTE = `/:${ROUTE_PARAMS.selfAccount}/${ROUTE_SEGMENTS.settings}`

export const dashboardTabId = 'dashboardTab'

export const ROUTES = {
  platformDomain: getPlatformUrl(),

  home: '/',
  selfAccountHome: `/:${ROUTE_PARAMS.selfAccount}`, // the `?` is to allow Route to match the path and render page
  // when the selfAccount id will be missed i.e. just '/' url

  // auth
  login: '/login',
  acceptInvite: '/accept-invite',
  resetPassword: '/reset-password', // request password reset
  createPassword: '/create-password', // create password after signup
  forgotPassword: '/forgot-password', // set password after reset password request
  resendRegister: '/resend-register',

  // first login flow account and agency setup
  accountSetup: '/account-setup',
  // mfa setup
  mfaSetup: '/mfa-setup',

  // campaigns
  campaigns: `/:${ROUTE_PARAMS.selfAccount}/:${ROUTE_PARAMS.platform}/:${ROUTE_PARAMS.adAccount}/${ROUTE_SEGMENTS.campaigns}`,
  campaignsSummary: `/:${ROUTE_PARAMS.selfAccount}/:${ROUTE_PARAMS.platform}/:${ROUTE_PARAMS.adAccount}/${ROUTE_SEGMENTS.campaigns}/:${ROUTE_PARAMS.campaignId}`,

  // other
  lineItemsSummary: `/:${ROUTE_PARAMS.selfAccount}/:${ROUTE_PARAMS.platform}/:${ROUTE_PARAMS.adAccount}/${ROUTE_SEGMENTS.campaigns}/:${ROUTE_PARAMS.campaignId}/lineitem/:${ROUTE_PARAMS.lineItemId}`,
  audiences: `/:${ROUTE_PARAMS.platform}/:${ROUTE_PARAMS.adAccount}/${ROUTE_SEGMENTS.audiences}`,
  authorisePlatform: `/:${ROUTE_PARAMS.selfAccount}/:${ROUTE_PARAMS.platform}/${ROUTE_SEGMENTS.authorise}`,
  authorisePlatformCallback: `/:${ROUTE_PARAMS.platform}/authorise-callback`,
  adAccountAccessMissing: `/:${ROUTE_PARAMS.selfAccount}/:${ROUTE_PARAMS.platform}/:${ROUTE_PARAMS.adAccount}/${ROUTE_SEGMENTS.adAccountAccessMissing}`,
  calendar: `/:${ROUTE_PARAMS.selfAccount}/${ROUTE_SEGMENTS.calendars}`,

  // management reporting
  dashboard: MANAGEMENT_REPORTING_MAIN_ROUTE,
  breakdownCharts: `${MANAGEMENT_REPORTING_MAIN_ROUTE}/${MANAGEMENT_REPORTING_SUB_ROUTES.BREAKDOWN_CHARTS}`,
  inventoryCalendarReport: `${MANAGEMENT_REPORTING_MAIN_ROUTE}/${MANAGEMENT_REPORTING_SUB_ROUTES.INVENTORY_CALENDAR_REPORT}`,
  bookedMediaCalendarReport: `${MANAGEMENT_REPORTING_MAIN_ROUTE}/${MANAGEMENT_REPORTING_SUB_ROUTES.BOOKED_MEDIA_REPORT}`,
  installationReport: `${MANAGEMENT_REPORTING_MAIN_ROUTE}/${MANAGEMENT_REPORTING_SUB_ROUTES.INSTALLATION_REPORT}`,
  bookedRevenueReport: `${MANAGEMENT_REPORTING_MAIN_ROUTE}/${MANAGEMENT_REPORTING_SUB_ROUTES.BOOKED_REVENUE_REPORT}`,

  adApprovals: `/:${ROUTE_PARAMS.selfAccount}/${ROUTE_SEGMENTS.adApprovals}`,
  adApprovalsFacebook: `/:${ROUTE_PARAMS.selfAccount}/${FACEBOOK_PLATFORM}/${ROUTE_SEGMENTS.adApprovals}`,
  adApprovalsGoogle: `/:${ROUTE_PARAMS.selfAccount}/${GOOGLE_PLATFORM}/${ROUTE_SEGMENTS.adApprovals}`,
  adApprovalsDv360: `/:${ROUTE_PARAMS.selfAccount}/${DV_360_PLATFORM}/${ROUTE_SEGMENTS.adApprovals}`,
  adApprovalsTiktok: `/:${ROUTE_PARAMS.selfAccount}/${TIKTOK_PLATFORM}/${ROUTE_SEGMENTS.adApprovals}`,

  //media Orders
  mediaOrders: `/:${ROUTE_PARAMS.selfAccount}/${ROUTE_SEGMENTS.mediaOrders}`,

  // media orders summary page
  mediaOrderSummary: MEDIA_ORDERS_MAIN_ROUTE,
  mediaOrderSummaryRequestedFiles: `${MEDIA_ORDERS_MAIN_ROUTE}/${MEDIA_ORDERS_SUB_ROUTES.REQUESTED_FILES}`,
  mediaOrderSummaryOnsiteDigital: `${MEDIA_ORDERS_MAIN_ROUTE}/${MEDIA_ORDERS_SUB_ROUTES.ONSITE_DIGITAL}`,
  mediaOrderSummaryOffNetworkDigital: `${MEDIA_ORDERS_MAIN_ROUTE}/${MEDIA_ORDERS_SUB_ROUTES.OFF_NETWORK_DIGITAL}`,
  mediaOrderSummaryDigitalScreens: `${MEDIA_ORDERS_MAIN_ROUTE}/${MEDIA_ORDERS_SUB_ROUTES.DIGITAL_SCREENS}`,
  mediaOrderSummaryDetails: `${MEDIA_ORDERS_MAIN_ROUTE}/${MEDIA_ORDERS_SUB_ROUTES.DETAILS}`,
  mediaOrderSummaryDocuments: `${MEDIA_ORDERS_MAIN_ROUTE}/${MEDIA_ORDERS_SUB_ROUTES.DOCUMENTS}`,
  mediaOrderSummaryAmendments: `${MEDIA_ORDERS_MAIN_ROUTE}/${MEDIA_ORDERS_SUB_ROUTES.AMENDMENTS}`,

  // quotations
  proposals: `/:${ROUTE_PARAMS.selfAccount}/${ROUTE_SEGMENTS.proposals}`,
  proposalsCreate: `/:${ROUTE_PARAMS.selfAccount}/${ROUTE_SEGMENTS.proposals}/create`,
  proposalsEdit: `/:${ROUTE_PARAMS.selfAccount}/${ROUTE_SEGMENTS.proposals}/:id/edit`,
  // quotations page for not authorized users
  notAuthQuotations: '/quotation-sign',
  notAuthAmendments: '/amendment-submit',

  // settings
  settings: `${SETTINGS_MAIN_ROUTE}`,
  myInformation: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.MY_INFORMATION}`,
  myPassword: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.MY_PASSWORD}`,
  planAndBilling: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.PLAN_AND_BILLING}`,
  manageLocationLists: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.MANAGE_LOCATION_LISTS}`,
  pagesAndIdentities: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.PAGES_AND_IDENTITIES}`,
  manageTriggers: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.MANAGE_TRIGGERS}`,
  manageAutomationRules: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.MANAGE_AUTOMATION_RULES}`,
  help: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.HELP}`,
  agency: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.AGENCY}`,
  controllerTeam: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.CONTROLLER_TEAM}`,
  controller: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.CONTROLLER}`,
  advertiserAccounts: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.ADVERTISER_ACCOUNTS}`,
  agencyAccounts: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.AGENCY_ACCOUNTS}`,
  mediaProducts: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.MEDIA_PRODUCTS}`,
  discounts: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.DISCOUNTS}`,
  mediaPackages: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.MEDIA_PACKAGES}`,
  bookings: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.BOOKINGS}`,
  inventory: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.INVENTORY}`,
  reporting: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.REPORTING}`,
  manageMargins: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.MANAGE_MARGINS}`,
  bookedMediaAndFiles: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.BOOKED_MEDIA_AND_FILES}`,
  invoicing: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.INVOICING}`,
  brandPages: `${SETTINGS_MAIN_ROUTE}/${SETTINGS_ROUTES.BRAND_PAGES}`
}
