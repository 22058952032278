import React, { useCallback, useEffect, useMemo, useState } from 'react'
import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import { MANAGE_INVENTORY } from '../../../../constants/forms'
import { useDispatch, useSelector } from 'react-redux'

import ContentSection from '../../../../features/components/ContentSection'
import ContentRow from '../../../../features/components/ContentSection/ContentRow'
import InventoryBatchUpdateForm from '../../../../forms/Multiplatform/InventoryForms/InventoryBatchUpdate/InventoryBatchUpdateForm'
import ProductInventoryGraph from './ProductInventoryGraph'
import InventoryDateRangeFilter from './InventoryDateRangeFilter'

import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'
import { useLoadPaginatedList } from '../../../../features/hooks/useLoadPaginatedList'

import {
  inventoryLastItemWasLoadedSelector,
  inventoryNextSelector,
  inventoryWasLoadedSelector,
  productSelector,
  productWasLoadedSelector
} from '../../../../modules/selectors/mediaOrdersProducts'
import {
  clearGetInventoryLastItem,
  clearInventory,
  clearProduct,
  getInventory,
  getInventoryLastItem,
  getProduct
} from '../../../../modules/actions/mediaOrdersProducts'

import useStyles from './styles'

import { formatDateToBE } from '../../../../constants/dates'

const ProductInventory = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const product = useSelector(productSelector)
  const productWasLoaded = useSelector(productWasLoadedSelector)

  const inventoryWasLoaded = useSelector(inventoryWasLoadedSelector)
  const inventoryLastItemWasLoaded = useSelector(inventoryLastItemWasLoadedSelector)

  const next = useSelector(inventoryNextSelector)

  const { selectedEditItemId } = useManageFormsDrawer({
    formName: MANAGE_INVENTORY
  })

  const { name: productName } = product

  const [dateStart, setDateStart] = useState(new Date())

  const inventoryParams = useMemo(
    () => ({
      media_product: selectedEditItemId,
      date_from: formatDateToBE(dateStart)
    }),
    [selectedEditItemId, dateStart]
  )

  const isFormLoading = useMemo(
    // We check for !inventoryWasLoaded instead of inventoryIsLoading because inside the form users may press LoadMore,
    // and we don't want to show the form as loading in this case
    () => !productWasLoaded || !inventoryWasLoaded || !inventoryLastItemWasLoaded,
    [productWasLoaded, inventoryWasLoaded, inventoryLastItemWasLoaded]
  )

  const loadMoreHandler = useLoadPaginatedList({
    params: inventoryParams,
    action: getInventory,
    clearAction: clearInventory,
    next,
    allowFetch: !!selectedEditItemId
  })

  useEffect(() => {
    if (selectedEditItemId) {
      dispatch(getProduct({ id: selectedEditItemId }))
    }
  }, [dispatch, selectedEditItemId])

  useEffect(() => {
    dispatch(
      getInventoryLastItem({
        media_product: selectedEditItemId
      })
    )
  }, [selectedEditItemId, dispatch])

  const clearHandler = useCallback(() => {
    setDateStart(new Date())
    dispatch(clearInventory())
    dispatch(clearProduct())
    dispatch(clearGetInventoryLastItem())
  }, [setDateStart, dispatch])

  const onAfterFormClose = useCallback(() => {
    clearHandler()
  }, [clearHandler])

  useEffect(() => {
    return () => {
      clearHandler()
    }
  }, [clearHandler])

  return (
    <FormDrawerWrapper
      formName={MANAGE_INVENTORY}
      isFormLoading={isFormLoading}
      title="Manage inventory"
      showOpenButton={false}
      isWideDrawer
      onAfterFormClose={onAfterFormClose}
    >
      <>
        <p className={classes.formDescription}>{`View and update inventory for ${productName}`}</p>
        <div className={classes.container}>
          <ContentSection title={`Inventory - ${productName}`}>
            <ContentRow title="Utilisation report" description="Booked and unsold media inventory.">
              <ProductInventoryGraph productId={selectedEditItemId} />
            </ContentRow>
            <ContentRow title="Inventory" description="Manage media product inventory.">
              <InventoryDateRangeFilter dateStart={dateStart} setDateStart={setDateStart} />
              <InventoryBatchUpdateForm selectedMediaProduct={product} loadMoreHandler={loadMoreHandler} />
            </ContentRow>
          </ContentSection>
        </div>
      </>
    </FormDrawerWrapper>
  )
}

export default ProductInventory
