import React from 'react'
import { useSelector } from 'react-redux'

import { adSelector } from '../../../../../../modules/selectors/ads'

import { LANDING_PAGE_URL } from '../../../fields'

const ClickThroughLinkSectionPreview = () => {
  const ad = useSelector(adSelector)

  const { [LANDING_PAGE_URL]: url } = ad

  return <p>{url}</p>
}

export default ClickThroughLinkSectionPreview
