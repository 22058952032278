import React from 'react'
import classnames from 'classnames'

import InternalLink from '../../../components/InternalLink'

import { ReactComponent as AngleIcon } from '../../../assets/icons/chevron-arrow-down.svg'

import useStyles from './styles'

const rightArrow = <AngleIcon className={'right-arrow'} />

function SettingsNavMenu({ ...props }) {
  const { settings, subPagePath, closeMenu } = props

  const classes = useStyles()

  function isSelectedSetting(path) {
    return path === subPagePath
  }

  const isActiveItem = path => {
    return isSelectedSetting(path) ? 'selected' : ''
  }

  // hide nav if settings is empty
  if (!settings?.length) {
    return null
  }

  return (
    <nav className={classes.nav}>
      {settings.map(({ path, pathParam, title, icon }) => (
        <div key={path} className={classes.navItem}>
          <InternalLink onClick={closeMenu} to={path} className={classnames(classes.navLink, isActiveItem(pathParam))}>
            {title}
            <div className={classes.navItemRight}>
              <p className={classes.navIcon}>{icon || rightArrow}</p>
            </div>
          </InternalLink>
        </div>
      ))}
    </nav>
  )
}

export default SettingsNavMenu
