import { createStyles } from '../../../../../styles/helpers'
import { drawerContentPadding } from '../../../../../styles/common/drawerForms'

const useStyles = createStyles({
  formContainer: {
    padding: ({ hasDrawerPadding }) => hasDrawerPadding && drawerContentPadding
  },
  warningFooter: {
    textAlign: 'center',
    maxWidth: '80%',
    margin: '20px auto 0'
  }
})

export default useStyles
