import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import TableDataLoader from '../../../../../components/Table/TableDataLoader'
import FacebookPagesTableData from './FacebookPagesTableData'

import {
  accessibleFacebookPagesErrorSelector,
  accessibleFacebookPagesLoadingSelector,
  accessibleFacebookPagesSelector,
  accessibleFacebookPagesWasLoadedSelector
} from '../../../../../modules/selectors/socialAccounts'

import { phonesDownSize } from '../../../../../styles/const/breakpoints'

import useCommonStyles from '../../../../../styles/common/listPage'

export const facebookPagesTableColumnsSize = {
  avatar: 50,
  dropdown: 30
}

const spaceForMargin = 5
const mobileAvatarWidth = 25

const mobileColumnsSchema = [
  { style: { maxWidth: mobileAvatarWidth, marginInlineEnd: spaceForMargin } },
  { style: { maxWidth: '100%' } }
]

const FacebookPagesTable = () => {
  const facebookPages = useSelector(accessibleFacebookPagesSelector)

  const { t } = useTranslation()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const commonClasses = useCommonStyles()

  const noDataContent = useMemo(
    () => <div className={commonClasses.noDataContent}>{t('There are no Facebook pages')}</div>,
    [t, commonClasses.noDataContent]
  )

  const facebookPagesColumnsSchema = useMemo(
    () => [
      {
        fieldKey: 'avatar',
        style: { maxWidth: facebookPagesTableColumnsSize.avatar }
      },
      {
        header: 'Name',
        fieldKey: 'name'
      },
      {
        header: 'Page ID',
        fieldKey: 'external_id'
      },
      {
        fieldKey: 'dropdown',
        style: { maxWidth: facebookPagesTableColumnsSize.dropdown }
      }
    ],
    []
  )

  return (
    <>
      <TableDataLoader
        itemsLength={facebookPages?.length}
        errorSelector={accessibleFacebookPagesErrorSelector}
        wasLoadedSelector={accessibleFacebookPagesWasLoadedSelector}
        isLoadingSelector={accessibleFacebookPagesLoadingSelector}
        noDataContent={noDataContent}
        skeletonProps={{ cols: isMobile ? mobileColumnsSchema : facebookPagesColumnsSchema }}
      >
        <FacebookPagesTableData />
      </TableDataLoader>
    </>
  )
}

export default FacebookPagesTable
