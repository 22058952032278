import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import ActionsDropdown from '../../../../../features/components/ActionsDropdown'
import ActionsButtonsList from '../../../../../features/components/ActionsButtonsList'

import { openForm } from '../../../../../modules/actions/forms'
import { deleteProductTag } from '../../../../../modules/actions/tags'

import { EDIT_PRODUCT_TAGS } from '../../../../../constants/forms'

import { phonesDownSize } from '../../../../../styles/const/breakpoints'

const MediaTagActions = ({ tag, className }) => {
  const { t } = useTranslation()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const dispatch = useDispatch()

  const { id } = tag

  const openEditFormHandler = useCallback(() => {
    dispatch(openForm({ id, formName: EDIT_PRODUCT_TAGS }))
  }, [dispatch, id])

  const deleteTagHandler = useCallback(() => {
    dispatch(deleteProductTag(id))
  }, [dispatch, id])

  const dropdownOptions = useMemo(
    () => [
      {
        text: t('Edit'),
        onClickHandler: openEditFormHandler
      },
      {
        text: t('Delete'),
        onClickHandler: deleteTagHandler
      }
    ],
    [t, openEditFormHandler, deleteTagHandler]
  )

  return isMobile ? (
    <ActionsButtonsList options={dropdownOptions} className={className} />
  ) : (
    <ActionsDropdown options={dropdownOptions} />
  )
}

export default MediaTagActions
