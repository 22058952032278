import {
  ASSET_ID,
  FILE_NAME,
  FILE_TYPE,
  FILE_URL,
  LANDSCAPE_IMAGE,
  MEDIA_TYPE,
  RESOURCE_NAME
} from '../../../../fields'

export const getInitialValues = assets => {
  return {
    [LANDSCAPE_IMAGE]: assets.map(asset => ({
      [FILE_URL]: asset.asset_data.image_asset.full_size.url,
      [FILE_NAME]: asset.asset_data.name,
      [RESOURCE_NAME]: asset.resource_name,
      [FILE_TYPE]: 'image',
      [MEDIA_TYPE]: 'media_file_upload',
      [ASSET_ID]: asset.asset
    }))
  }
}
