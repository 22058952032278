export const googleAdsCallToActionList = [
  {
    value: 'APPLY_NOW',
    label: 'Apply Now'
  },
  {
    value: 'DOWNLOAD',
    label: 'Download'
  },
  {
    value: 'GET_QUOTE',
    label: 'Get Quote'
  },
  {
    value: 'LEARN_MORE',
    label: 'Learn More'
  },
  {
    value: 'SHOP_NOW',
    label: 'Shop Now'
  },
  {
    value: 'SIGN_UP',
    label: 'Sign Up'
  },
  {
    value: 'SUBSCRIBE',
    label: 'Subscribe'
  },
  {
    value: 'CONTACT_US',
    label: 'Contact Us'
  },
  {
    value: 'BOOK_NOW',
    label: 'Book Now'
  }
]
