import { CAMPAIGN_GOOGLE_PERFORMANCE_MAX_TYPE, CAMPAIGN_GOOGLE_DISPLAY_TYPE } from '../../../constants/campaigns'

export const checkIfCampaignHasLineItem = advertisingChanelType => {
  switch (advertisingChanelType) {
    case CAMPAIGN_GOOGLE_PERFORMANCE_MAX_TYPE:
      // PerformanceMaxCampaign doesn't have line items
      return false
    case CAMPAIGN_GOOGLE_DISPLAY_TYPE:
    default:
      return true
  }
}
