import { createStyles } from '../../../../../../../../../styles/helpers'
import { tabletDown } from '../../../../../../../../../styles/const/breakpoints'

const useStyles = createStyles({
  quantityField: {
    flexGrow: 1,
    minWidth: 56
  },
  quantityFieldWithBooked: {
    flexBasis: 55
  },
  [`@media screen and (${tabletDown})`]: {
    quantityField: {
      maxWidth: 'initial'
    },
    quantityFieldWithBooked: {
      flexBasis: 80
    }
  }
})

export default useStyles
