// image
export const FILE_NAME = 'name'
export const FILE_URL = 'file_url'
// file upload
export const FILE_UPLOAD_OPTION = 'file_upload_option'
export const FILE_UPLOAD = 'file_upload'
// existing file selection
export const FILE_SELECT_EXISTING = 'file_select_existing'
export const FILE_SELECTED_NAME = 'selected_file_name'
export const FILE_SELECTED_URL = 'selected_file_url'
export const SELECTED_PERIODS = 'booked_medias'
export const OVERRIDE_VALIDATION = 'override_validation'

export const initialImageFileValues = {
  [FILE_NAME]: '',
  [FILE_URL]: ''
}

export const getInitialValues = ({ selectedFileId, selectedBookedMediaId, allAvailableMediaToUpload }) => {
  // each available booked media has its own period, so we need to set initially all available booked media periods
  const selectedBookedMediaPeriods = {}
  allAvailableMediaToUpload.forEach(media => {
    const mediaFiles = media.file_requirements
    mediaFiles.forEach(file => {
      selectedBookedMediaPeriods[file.id] = {
        ...selectedBookedMediaPeriods[file.id],
        [media.id]: false
      }
    })
  })

  return {
    ...initialImageFileValues,
    [FILE_UPLOAD_OPTION]: FILE_UPLOAD,
    [FILE_SELECTED_NAME]: '',
    [FILE_SELECTED_URL]: '',
    [SELECTED_PERIODS]: {
      // user can set the uploaded file to other booked media and products
      ...selectedBookedMediaPeriods,
      // Depending on which period the user clicked to open the form, period is preselected.
      [selectedFileId]: {
        ...selectedBookedMediaPeriods[selectedFileId],
        [selectedBookedMediaId]: true
      }
    },
    [OVERRIDE_VALIDATION]: false
  }
}
