import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Drawer from '../../../../../components/Drawer'
import GoogleAssetsSliderPreview from '../../GoogleAssetsSliderPreview'
import EditSectionsList from '../../../../../features/components/Forms/EditForm/EditSectionsList'
import NameSectionPreview from './Sections/NameSection/NameSectionPreview'
import NameSectionForm from './Sections/NameSection/NameSectionForm'
import LinkSectionPreview from './Sections/LinkSection/LinkSectionPreview'
import LinkSectionForm from './Sections/LinkSection/LinkSectionForm'
import HeadlinesSectionPreview from './Sections/HeadlinesSection/HeadlinesSectionPreview'
import HeadlinesSectionForm from './Sections/HeadlinesSection/HeadlinesSectionForm'
import DescriptionsSectionPreview from './Sections/DescriptionsSection/DescriptionsSectionPreview'
import DescriptionsSectionForm from './Sections/DescriptionsSection/DescriptionsSectionForm'
import LongHeadlineSectionPreview from './Sections/LongHeadlineSection/LongHeadlineSectionPreview'
import LongHeadlineSectionForm from './Sections/LongHeadlineSection/LongHeadlineSectionForm'
import LandscapeImageSectionPreview from './Sections/LandscapeImageSection/LandscapeImageSectionPreview'
import LandscapeImageSectionForm from './Sections/LandscapeImageSection/LandscapeImageSectionForm'
import SquareImageSectionPreview from './Sections/SquareImageSection/SquareImageSectionPreview'
import SquareImageSectionForm from './Sections/SquareImageSection/SquareImageSectionForm'
import SquareLogoSectionPreview from './Sections/SquareLogoSection/SquareLogoSectionPreview'
import SquareLogoSectionForm from './Sections/SquareLogoSection/SquareLogoSectionForm'
import LandscapeLogoSectionPreview from './Sections/LandscapeLogoSection/LandscapeLogoSectionPreview'
import LandscapeLogoSectionForm from './Sections/LandscapeLogoSection/LandscapeLogoSectionForm'

import useManageFormsDrawer from '../../../../../hooks/formHooks/useManageFormsDrawer'
import useManageEditFormData from '../../../../../hooks/formHooks/useManageEditFormData'

import { getAdGoogleTransformedValues } from './helpers'
import { createJsonFromQueryString } from '../../../../../helpers/url'

import { getAd, clearAd, clearUpdateAd } from '../../../../../modules/actions/ads'
import {
  adSelector,
  adLoadingSelector,
  adWasLoadedSelector,
  adWasUpdatedSelector,
  adUpdateErrorSelector,
  adUpdateIsLoadingSelector,
  getAdsSelector
} from '../../../../../modules/selectors/ads'
import { selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'

import { AD_GOOGLE_EDIT } from '../../../../../constants/forms'
import { GENERAL_SETTINGS } from './fields'
import {
  CLICK_THROUGH_LINK,
  DESCRIPTIONS_LIST,
  HEADLINES_LIST,
  LANDSCAPE_IMAGE,
  LANDSCAPE_LOGO,
  SQUARE_IMAGE,
  SQUARE_LOGO
} from '../../fields'
import { GOOGLE_PLATFORM } from '../../../../../constants/selectLists/platformList'
import { LONG_HEADLINE } from '../DisplayAdGoogleCreateForm/fields'

// here defined initial open states for function AdGoogleEdit sections
const sectionsInitialOpenStates = {
  [GENERAL_SETTINGS]: false,
  [CLICK_THROUGH_LINK]: false,
  [HEADLINES_LIST]: false,
  [DESCRIPTIONS_LIST]: false,
  [LONG_HEADLINE]: false,
  [LANDSCAPE_IMAGE]: false,
  [SQUARE_IMAGE]: false,
  [SQUARE_LOGO]: false,
  [LANDSCAPE_LOGO]: false
}

function AdGoogleEdit() {
  const dispatch = useDispatch()

  const ad = useSelector(adSelector)
  const ads = useSelector(getAdsSelector)
  const adIsLoading = useSelector(adLoadingSelector)
  const adWasLoaded = useSelector(adWasLoadedSelector)
  const adWasUpdated = useSelector(adWasUpdatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  // if ad is internal, it appears in query params when we open edit form
  const { internal: internalQueryParam } = createJsonFromQueryString(window.location.search)

  const isInternalAd = internalQueryParam === 'true'

  // to avoid loading representation
  const isInitialDataLoading = adIsLoading || !adWasLoaded

  const getItemDataHandler = useCallback(
    selectedEditItemId => {
      const currentAd = ads.find(({ id }) => Number(id) === Number(selectedEditItemId))
      const currentAdAdAccountId = currentAd?.ad_account_id

      const adAccount = currentAdAdAccountId || selectedAdAccountId

      dispatch(
        getAd({
          platform: GOOGLE_PLATFORM,
          id: selectedEditItemId,
          account: adAccount,
          ...(isInternalAd && {
            internal: true
          })
        })
      )
    },
    [dispatch, isInternalAd, ads, selectedAdAccountId]
  )

  const clearItemDataHandler = useCallback(() => {
    dispatch(clearAd())
  }, [dispatch])

  const { isFormOpen, handleFormClose } = useManageFormsDrawer({
    formName: AD_GOOGLE_EDIT
  })

  useManageEditFormData({
    formName: AD_GOOGLE_EDIT,
    loadedDataId: ad.id,
    getDataHandler: getItemDataHandler,
    clearDataHandler: clearItemDataHandler
  })

  // here defined all edit sections for AdGoogleEdit
  const editSections = useMemo(
    () => [
      {
        sectionName: GENERAL_SETTINGS,
        title: 'General Settings',
        PreviewComponent: <NameSectionPreview ad={ad} />,
        FormComponent: <NameSectionForm />
      },
      {
        sectionName: CLICK_THROUGH_LINK,
        title: 'Link',
        PreviewComponent: <LinkSectionPreview ad={ad} />,
        FormComponent: <LinkSectionForm />
      },
      {
        sectionName: HEADLINES_LIST,
        title: 'Headlines',
        PreviewComponent: <HeadlinesSectionPreview ad={ad} />,
        FormComponent: <HeadlinesSectionForm />
      },
      {
        sectionName: DESCRIPTIONS_LIST,
        title: 'Descriptions',
        PreviewComponent: <DescriptionsSectionPreview ad={ad} />,
        FormComponent: <DescriptionsSectionForm />
      },
      {
        sectionName: LONG_HEADLINE,
        title: 'Long Headline',
        PreviewComponent: <LongHeadlineSectionPreview ad={ad} />,
        FormComponent: <LongHeadlineSectionForm />
      },
      {
        sectionName: LANDSCAPE_IMAGE,
        title: 'Landscape Images',
        PreviewComponent: <LandscapeImageSectionPreview ad={ad} />,
        FormComponent: <LandscapeImageSectionForm />
      },
      {
        sectionName: SQUARE_IMAGE,
        title: 'Square Images',
        PreviewComponent: <SquareImageSectionPreview ad={ad} />,
        FormComponent: <SquareImageSectionForm />
      },
      {
        sectionName: SQUARE_LOGO,
        title: 'Square Logos',
        PreviewComponent: <SquareLogoSectionPreview ad={ad} />,
        FormComponent: <SquareLogoSectionForm />
      },
      {
        sectionName: LANDSCAPE_LOGO,
        title: 'Landscape Logo',
        PreviewComponent: <LandscapeLogoSectionPreview ad={ad} />,
        FormComponent: <LandscapeLogoSectionForm />
      }
    ],
    [ad]
  )

  const handleClearUpdateAd = useCallback(() => {
    dispatch(clearUpdateAd())
  }, [dispatch])

  const formProps = useMemo(
    () => ({
      successSubmit: adWasUpdated,
      errorSelector: adUpdateErrorSelector,
      isLoadingSelector: adUpdateIsLoadingSelector,
      clearEditItem: handleClearUpdateAd,
      isInternalAd
    }),
    [adWasUpdated, handleClearUpdateAd, isInternalAd]
  )

  const adTransformedValues = useMemo(() => getAdGoogleTransformedValues(ad), [ad])

  return (
    <Drawer
      title="Update the settings of your display ad"
      subTitle="Edit a Google display ad"
      isOpen={isFormOpen}
      onClose={handleFormClose}
    >
      <>
        <GoogleAssetsSliderPreview values={adTransformedValues} showSkeleton={!adWasLoaded} showSearchPreview={false} />
        <EditSectionsList
          editSections={editSections}
          isEditDataLoading={isInitialDataLoading}
          editItemData={ad}
          sectionsInitialOpenStates={sectionsInitialOpenStates}
          formProps={formProps}
        />
      </>
    </Drawer>
  )
}

export default AdGoogleEdit
