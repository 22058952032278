import { ENDPOINT_PLATFORM, ENDPOINTS } from '../../constants/api'
import { DV_360_PLATFORM } from '../../constants/selectLists/platformList'
import { madeRequest } from '../../helpers/api'
import { replaceRouteParams } from '../../helpers/url'

// PLOP_APPEND_PATTERN_ANCHOR

export function getAdAccountsService(platform) {
  const isDV360 = platform === DV_360_PLATFORM

  const params = {
    // todo we need extra field partner_id only for dv360 platform
    // todo probably need to be refactored in some better way
    ...(isDV360 && { partner_id: 1891333 })
  }

  return madeRequest('GET', {
    params,
    url: replaceRouteParams(ENDPOINTS.adAccounts, { platform: ENDPOINT_PLATFORM[platform] })
  })
}

export function getAdAccountService(id, platform) {
  return madeRequest('GET', {
    url: replaceRouteParams(`${ENDPOINTS.adAccounts}:id/`, { platform: ENDPOINT_PLATFORM[platform], id })
  })
}
