import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../components/Button'
import Icon from '../../../components/Icon'

import { ReactComponent as DV360Icon } from '../../../assets/logos/dv360/dv360-icon.svg'
import { ReactComponent as GoogleIcon } from '../../../assets/logos/google-icon.svg'
import { ReactComponent as TikTikIcon } from '../../../assets/logos/tt-icon.svg'

import { setLocalStorage } from '../../../helpers/storage'

import {
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  DV_360_PLATFORM,
  TIKTOK_PLATFORM
} from '../../../constants/selectLists/platformList'

import useStyles from './style'

function SocialAuthorizeButtons({ selectedPlatform }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const onAuthLinkClickHandler = () => {
    // save the destination URL which could be used for redirection after authentication
    // this localStorage property is used on auth-callback page and is cleaned up after redirection
    setLocalStorage('authTriggeringUrl', window.location.pathname)
  }

  return (
    <>
      {selectedPlatform === FACEBOOK_PLATFORM && (
        <a
          href="/api/allauth/facebook/login/?process=connect"
          rel="noreferrer nofollow"
          onClick={onAuthLinkClickHandler}
        >
          <Button solid className={classes.facebookBtn} type="button">
            {t('Continue with Facebook')}
          </Button>
        </a>
      )}
      {selectedPlatform === GOOGLE_PLATFORM && (
        <a href="/api/allauth/google/login/?process=connect" rel="noreferrer nofollow" onClick={onAuthLinkClickHandler}>
          <Button solid className={classes.googleButton} type="button">
            <Icon className={classes.googleButtonIcon}>
              <GoogleIcon />
            </Icon>
            <span className={classes.googleButtonText}>{t('Authenticate with Google Ads')}</span>
          </Button>
        </a>
      )}
      {selectedPlatform === DV_360_PLATFORM && (
        <a href="/api/allauth/dv360/login/?process=connect" rel="noreferrer nofollow" onClick={onAuthLinkClickHandler}>
          <Button solid className={classes.dv360Button} type="button">
            <Icon className={classes.googleButtonIcon}>
              <DV360Icon />
            </Icon>
            <span className={classes.googleButtonText}>{t('Authenticate with Display Video 360')}</span>
          </Button>
        </a>
      )}
      {selectedPlatform === TIKTOK_PLATFORM && (
        <a href="/api/allauth/tiktok/login/?process=connect" rel="noreferrer nofollow" onClick={onAuthLinkClickHandler}>
          <Button solid className={classes.tikTokButton} type="button">
            <TikTikIcon className={classes.tikTokIcon} />
            <span className="text">{t('Continue with TikTok')}</span>
          </Button>
        </a>
      )}
    </>
  )
}

export default SocialAuthorizeButtons
