import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import MediaOrderNameCell from '../../../../../features/components/MediaOrderTableCells/MediaOrderNameCell'
import Table from '../../../../../components/Table'
import AdaptiveActions from '../../../../../features/components/AdaptiveActions'
import MediaOrderCostCell from '../../../../../features/components/MediaOrderTableCells/MediaOrderCostCell'
import Chip from '../../../../../components/Chip'
import AccountBrandCell from '../../../../../components/Table/ReusableCells/AccountBrandCell'

import { useRedirectToMediaOrderSummary } from '../../../../MediaOrders/MediaOrdersTable/MediaOrdersTableData/hooks'

import { mediaOrdersSelector } from '../../../../../modules/selectors/mediaOrders'

import { formatDateFullYear } from '../../../../../constants/dates'
import { tableColumnMinSize, tableColumnsSize } from '../index'
import { bookedMediaStatusesLabels, statusColors } from '../../../../../constants/bookedMedia'
import { phonesDownSize } from '../../../../../styles/const/breakpoints'

import useStyles from './styles'

const BookingsTableData = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const mediaOrders = useSelector(mediaOrdersSelector)

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const goToMediaOrderSummaryHandler = useRedirectToMediaOrderSummary()

  const getDropdownOptions = useCallback(
    id => {
      return [
        {
          text: t('Go to media order'),
          onClickHandler: () => goToMediaOrderSummaryHandler(id)
        }
      ]
    },
    [t, goToMediaOrderSummaryHandler]
  )

  const mediaOrdersColumns = useMemo(
    () => [
      {
        header: 'ID',
        Cell: ({ sequential_id, id }) => <span onClick={() => goToMediaOrderSummaryHandler(id)}>{sequential_id}</span>,
        style: { maxWidth: tableColumnsSize.id, cursor: 'pointer' }
      },
      {
        header: 'Name',
        Cell: mediaOrder => (
          <MediaOrderNameCell
            mediaOrder={mediaOrder}
            onClick={isMobile ? undefined : goToMediaOrderSummaryHandler}
            showIcon={false}
            infoTextClassName={classes.infoTextClassName}
          />
        ),
        showOnMobile: true,
        style: { maxWidth: tableColumnsSize.name, minWidth: tableColumnMinSize.name, whiteSpace: 'normal' }
      },
      {
        header: 'Account',
        Cell: ({ account_name, brand_name }) => {
          return <AccountBrandCell account={account_name} brand={brand_name} />
        },
        showOnMobile: false,
        style: {
          maxWidth: tableColumnsSize.account
        }
      },
      {
        header: 'Campaign Name',
        Cell: ({ campaign_name }) => campaign_name || '-',
        showOnMobile: false,
        style: {
          maxWidth: tableColumnsSize.campaignName,
          minWidth: tableColumnMinSize.campaignName,
          whiteSpace: 'normal'
        }
      },
      {
        header: 'Created',
        Cell: ({ created }) => (created ? formatDateFullYear(created) : '-'),
        showOnMobile: false,
        style: { maxWidth: tableColumnsSize.created, minWidth: tableColumnMinSize.created }
      },
      {
        header: 'Created By',
        Cell: ({ created_by = {} }) => {
          const { username } = created_by
          return username || '-'
        },
        showOnMobile: false,
        style: { maxWidth: tableColumnsSize.createdBy, minWidth: tableColumnMinSize.createdBy, whiteSpace: 'normal' }
      },
      {
        header: 'Cost',
        Cell: mediaOrder => <MediaOrderCostCell mediaOrder={mediaOrder} />,
        showOnMobile: false,
        // numerical column right aligned
        style: { maxWidth: tableColumnsSize.cost, justifyContent: 'flex-end' }
      },
      {
        header: 'Status',
        Cell: ({ status }) => {
          return status ? <Chip text={bookedMediaStatusesLabels[status]} color={statusColors[status]} /> : '-'
        },
        showOnMobile: false,
        style: { maxWidth: tableColumnsSize.status, minWidth: tableColumnMinSize.status }
      },
      {
        Cell: ({ id }) => {
          const options = getDropdownOptions(id)
          return <AdaptiveActions itemId={id} options={options} />
        },
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [getDropdownOptions, goToMediaOrderSummaryHandler, isMobile, classes]
  )

  const rowTemplate = useMemo(
    () => ({
      rowClassName: classes.row,
      columnClassName: classes.column
    }),
    [classes]
  )

  return <Table data={mediaOrders} cols={mediaOrdersColumns} hideFooterRow rowTemplate={rowTemplate} />
}

export default BookingsTableData
