import * as Yup from 'yup'

import {
  ASSET_IMAGE_LIST,
  MEDIA_HEIGHT_PIXELS,
  MEDIA_WIDTH_PIXELS
} from '../../AdForms/GoogleAssetGroup/GoogleAssetGroupMainForm/fields'

import { landscapeRatio, portraitRatio } from '../../AdForms/validations'

export const getAssetsByRatio = (assets = [], allowedRatio) => {
  return assets.filter(imageAsset => {
    const ratio = imageAsset[MEDIA_WIDTH_PIXELS] / imageAsset[MEDIA_HEIGHT_PIXELS]
    return Math.round(ratio * 100) / 100 === allowedRatio
  })
}
export const getAssetImageListValidation = ({
  isLandscapeImageRequired = true,
  isSquareImageRequired = true,
  isPortraitImageRequired = true
} = {}) => {
  return {
    [ASSET_IMAGE_LIST]: Yup.array().test({
      name: 'Custom image assets list validation',
      test: (value, context) => {
        const imageAssets = context.parent[ASSET_IMAGE_LIST]
        const filledImageAssets = imageAssets?.filter(({ file_url }) => file_url)

        const landscapeImageAssets = getAssetsByRatio(filledImageAssets, landscapeRatio)
        const squareImageAssets = getAssetsByRatio(filledImageAssets, 1)
        const portraitImageAssets = getAssetsByRatio(filledImageAssets, portraitRatio)

        if (!filledImageAssets?.length) {
          return context.createError({
            message: 'Please upload or select images',
            path: ASSET_IMAGE_LIST
          })
        }

        const requiredRatios = [
          ...(isLandscapeImageRequired ? [landscapeImageAssets] : []),
          ...(isSquareImageRequired ? [squareImageAssets] : []),
          ...(isPortraitImageRequired ? [portraitImageAssets] : [])
        ]

        if (requiredRatios.some(assets => !assets?.length)) {
          return context.createError({
            message: 'Please upload or select at least 1 of each ratio',
            path: ASSET_IMAGE_LIST
          })
        }

        return true
      }
    })
  }
}
