import React, { useCallback } from 'react'
import classnames from 'classnames'
import { format } from 'date-fns'
import PropTypes from 'prop-types'

import {
  INVENTORY_LIST_DATA,
  INVENTORY_START_DATE
} from '../../../../../../../../MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'
import { DATES_FORMAT_FULL_YEAR } from '../../../../../../../../../../constants/dates'

import useDatePickerOptimizedStyles from '../styles'
import useStyles from './styles'

// This component is used in the InventoryRow component,
// It looks like a date picker, the purpose of this component is to not render the date picker in each row of long list,
// because rendering the date picker in each row will cause performance issues.
// When the user clicks on this component, the date picker will be rendered in the row.
const DatePickerOptimizedPlaceholder = ({ values, setIsFocused, isDatePickerDisabled, index }) => {
  const datePickerOptimizedClasses = useDatePickerOptimizedStyles()

  const classes = useStyles(isDatePickerDisabled)

  const onFocusHandler = useCallback(() => {
    setIsFocused(true)
  }, [setIsFocused])

  return (
    <div
      className={classnames(datePickerOptimizedClasses.startDateField, classes.datePickerOptimizedPlaceholder)}
      onClick={onFocusHandler}
    >
      {format(values[INVENTORY_LIST_DATA][index][INVENTORY_START_DATE], DATES_FORMAT_FULL_YEAR)}
    </div>
  )
}

DatePickerOptimizedPlaceholder.propTypes = {
  values: PropTypes.object.isRequired,
  setIsFocused: PropTypes.func.isRequired,
  isDatePickerDisabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired
}

export default DatePickerOptimizedPlaceholder
