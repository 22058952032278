import React, { useCallback, useMemo, useState } from 'react'
import { getIn } from 'formik'
import { isBefore } from 'date-fns'
import PropTypes from 'prop-types'

import DatePicker from '../../../../../../../../../components/DatePicker'
import DatePickerOptimizedPlaceholder from './DatePickerOptimizedPlaceholder'

import {
  INVENTORY_LIST_DATA,
  INVENTORY_START_DATE
} from '../../../../../../../MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'

import useStyles from './styles'

const DatePickerOptimized = ({ formik, index, rowIndexInFocus }) => {
  const classes = useStyles()

  const [isFocused, setIsFocused] = useState(false)

  const { values, errors, touched, setFieldValue } = formik

  const selectedInventories = values[INVENTORY_LIST_DATA]

  const disabledDates = useMemo(() => {
    return selectedInventories.filter(item => !!item).map(({ [INVENTORY_START_DATE]: startDate }) => startDate)
  }, [selectedInventories])

  const isDatePickerDisabled = useMemo(() => {
    const item = values[INVENTORY_LIST_DATA][index]
    return isBefore(new Date(item[INVENTORY_START_DATE]), new Date())
  }, [index, values])

  const startDateError = getIn(errors, `${INVENTORY_LIST_DATA}[${index}].${INVENTORY_START_DATE}`)
  const startDateTouched = getIn(touched, `${INVENTORY_LIST_DATA}[${index}].${INVENTORY_START_DATE}`)

  const handleDatePickerChange = useCallback(
    value => {
      setFieldValue(`${INVENTORY_LIST_DATA}[${index}].${INVENTORY_START_DATE}`, value)
    },
    [index, setFieldValue]
  )

  const onDropdownVisibilityChangeHandler = useCallback(show => {
    if (!show) {
      setIsFocused(false)
    }
  }, [])

  return (
    <div>
      {isFocused &&
      // We additionally check if the row is in focus, because DatePicker doesn't have onBlur event,
      // so if user click outside isFocused state may stay 'true'
      // but we want to render the date picker only for the row in focus
      rowIndexInFocus === index ? (
        <DatePicker
          isPreOpen={true}
          className={classes.startDateField}
          value={values[INVENTORY_LIST_DATA][index][INVENTORY_START_DATE]}
          changeHandler={handleDatePickerChange}
          error={startDateTouched ? startDateError : ''}
          isSmall={false}
          disabled={isDatePickerDisabled}
          disabledDates={disabledDates}
          onDropdownVisibilityChange={onDropdownVisibilityChangeHandler}
        />
      ) : (
        <DatePickerOptimizedPlaceholder
          values={values}
          setIsFocused={setIsFocused}
          isDatePickerDisabled={isDatePickerDisabled}
          index={index}
        />
      )}
    </div>
  )
}

DatePickerOptimized.propTypes = {
  formik: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  rowIndexInFocus: PropTypes.number
}

export default DatePickerOptimized
