import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useStyles from './style'

function Radio(props) {
  const {
    id = '',
    name = '',
    value = '',
    className = '',
    titleClassName = '',
    isBig = false,
    label = '',
    onChange = f => f,
    checked = false,
    disabled = false,
    ...restProps
  } = props

  const classes = useStyles({ checked, disabled, isBig })

  const handleOnChange = event => {
    event.persist()
    onChange(event)
  }

  return (
    <span className={classnames(classes.radio, className)}>
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        onChange={handleOnChange}
        checked={checked}
        disabled={disabled}
        className={classes.radioInput}
        {...restProps}
      />
      <label htmlFor={id} className={classes.radioLabel}>
        <div className={classes.radioContainer}>
          <div className={classes.checkmark} />
        </div>
        {label && <h4 className={classnames(classes.radioTitle, titleClassName)}>{label}</h4>}
      </label>
    </span>
  )
}

Radio.propTypes = {
  id: PropTypes.string.isRequired
}

export default Radio
