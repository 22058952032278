import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../components/Button'

import Schedule from '../../../../../ReusableFormFields/ScheduleFields/Schedule'

import { SCHEDULE_DATES, SCHEDULE_OPTION } from '../../../../../ReusableFormFields/ScheduleFields/fields'
import { FORECAST_RANGE, LOCATION, TEMPERATURE } from '../../../../../ReusableFormFields/TriggerFields/fields'

import useStyles from '../../../../../../styles/common/stepForms'

const stepFields = [SCHEDULE_OPTION, SCHEDULE_DATES, LOCATION, TEMPERATURE, FORECAST_RANGE]

const ScheduleStep = ({ formik, handleStepChange }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create a TikTok ad group')}</div>
      <div className={classes.stepTitle}>{t('When should this ad group run?')}</div>
      <div className={classes.stepBody}>
        <Schedule formik={formik} showBasedOnTheWeather={false} />
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" className="dark" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default ScheduleStep
