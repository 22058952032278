import React from 'react'
import classnames from 'classnames'

import useContentTabsHeaderStyles from './contentTabsHeaderStyles'

const TabsHeader = ({ children }) => {
  const contentTabsHeaderClasses = useContentTabsHeaderStyles()

  return (
    <div
      className={classnames(contentTabsHeaderClasses.containerWrapper, contentTabsHeaderClasses.headerContainerWrapper)}
    >
      <div className={contentTabsHeaderClasses.headerContainer}>{children}</div>
    </div>
  )
}

export default TabsHeader
