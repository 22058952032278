import { createStyles } from '../../../../../../../styles/helpers'
import { black } from '../../../../../../../styles/const/colors'

const useStyles = createStyles({
  description: {
    fontSize: 18,
    fontWeight: 600,
    color: black,
    marginTop: 10
  },
  picturesLogo: {
    marginLeft: 15
  }
})

export default useStyles
