import * as Yup from 'yup'

import {
  ADMIN_CHOICE_ADD_AGENCY_MEMBER,
  ADMIN_CHOICE_ADD_USER_BY_EMAIL,
  ADMIN_CHOICE_OPTION,
  EMAIL,
  USER,
  ROLE
} from './fields'

const emailValidation = Yup.string()
  .required('This field should not be empty')
  .email('Please enter a valid email address')

export const getValidationSchema = isUserAdminOfAgency => {
  if (isUserAdminOfAgency) {
    return Yup.object({
      [USER]: Yup.string().when(ADMIN_CHOICE_OPTION, {
        is: ADMIN_CHOICE_ADD_AGENCY_MEMBER,
        then: () => Yup.string().required('Please select user')
      }),
      [EMAIL]: Yup.string().when(ADMIN_CHOICE_OPTION, {
        is: ADMIN_CHOICE_ADD_USER_BY_EMAIL,
        then: () => emailValidation
      })
    })
  } else {
    return Yup.object({
      [EMAIL]: emailValidation,
      [ROLE]: Yup.string().required('This field should not be empty')
    })
  }
}
