import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Step from '../../../../../../../../../../features/components/Forms/StepForm/Step'
import StartEndDateRangeFields from '../../../../../../../../../ReusableFormFields/StartEndDateRangeFields'

import { END_DATE, START_DATE } from '../../../../../../../../../ReusableFormFields/StartEndDateRangeFields/fields'

import useDrawerFormStyles from '../../../../../../../../../../styles/common/drawerForms'

const stepFields = [START_DATE, END_DATE]

const DatesStep = ({ formik, handleStepChange }) => {
  const drawerFormClasses = useDrawerFormStyles()

  const { t } = useTranslation()

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  return (
    <Step stepTitle="Between which dates should the ad set run?" handleContinue={handleContinue}>
      <section className={drawerFormClasses.section}>
        <h4 className={drawerFormClasses.sectionTitle}>{t('Dates')}</h4>
        <StartEndDateRangeFields
          formik={formik}
          startDateName={START_DATE}
          endDateName={END_DATE}
          minDate={new Date()}
        />
      </section>
    </Step>
  )
}

export default DatesStep
