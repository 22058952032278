import { all, takeEvery, call, put } from 'redux-saga/effects'

import {
  CREATE_AMENDMENT,
  createAmendmentFailure,
  createAmendmentSuccess,
  SIGN_AMENDMENT_CONTRACT,
  signAmendmentContractFailure,
  signAmendmentContractSuccess,
  DELETE_AMENDMENT_CONTRACT_SIGNATURE,
  deleteAmendmentContractSignatureSuccess,
  deleteAmendmentContractSignatureFailure,
  sendAmendmentContractSuccess,
  sendAmendmentContractFailure,
  SEND_AMENDMENT_CONTRACT,
  getAmendmentsListSuccess,
  getAmendmentsListFailure,
  GET_AMENDMENTS_LIST,
  getAmendmentSuccess,
  getAmendmentFailure,
  GET_AMENDMENT,
  SUBMIT_AMENDMENT,
  submitAmendmentSuccess,
  submitAmendmentFailure
} from '../actions/amendments'

import {
  createAmendmentService,
  deleteAmendmentContractSignatureService,
  getAmendmentService,
  getAmendmentsListService,
  sendAmendmentContractService,
  signAmendmentContractService,
  submitAmendmentService
} from '../services/amendments'
import { updateMediaOrderData } from '../actions/mediaOrders'

function* amendmentsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_AMENDMENT, getAmendmentWorker),
    takeEvery(GET_AMENDMENTS_LIST, getAmendmentsListWorker),
    takeEvery(CREATE_AMENDMENT, createAmendmentWorker),
    takeEvery(SIGN_AMENDMENT_CONTRACT, signAmendmentContractWorker),
    takeEvery(DELETE_AMENDMENT_CONTRACT_SIGNATURE, deleteAmendmentContractSignatureWorker),
    takeEvery(SEND_AMENDMENT_CONTRACT, sendAmendmentContractWorker),
    takeEvery(SUBMIT_AMENDMENT, submitAmendmentWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getAmendmentWorker({ id, params }) {
  try {
    const response = yield call(getAmendmentService, id, params)
    yield put(getAmendmentSuccess(response))
  } catch (error) {
    yield put(getAmendmentFailure(error))
  }
}

function* getAmendmentsListWorker({ params }) {
  try {
    const response = yield call(getAmendmentsListService, params)
    yield put(getAmendmentsListSuccess(response))
  } catch (error) {
    yield put(getAmendmentsListFailure(error))
  }
}

function* createAmendmentWorker({ data }) {
  try {
    const response = yield call(createAmendmentService, data)
    yield put(createAmendmentSuccess(response))
  } catch (error) {
    yield put(createAmendmentFailure(error))
  }
}

function* signAmendmentContractWorker({ data }) {
  try {
    const response = yield call(signAmendmentContractService, data)
    yield put(signAmendmentContractSuccess(response))
  } catch (error) {
    yield put(signAmendmentContractFailure(error))
  }
}

function* deleteAmendmentContractSignatureWorker({ data }) {
  try {
    const response = yield call(deleteAmendmentContractSignatureService, data)
    yield put(deleteAmendmentContractSignatureSuccess(response))
  } catch (error) {
    yield put(deleteAmendmentContractSignatureFailure(error))
  }
}

function* sendAmendmentContractWorker({ id, data }) {
  try {
    const response = yield call(sendAmendmentContractService, id, data)
    yield put(sendAmendmentContractSuccess(response))
    // handle case when the amendment was created, and we need to represent amendments tab on Media order page
    yield put(updateMediaOrderData({ has_amendment_contracts: true }))
  } catch (error) {
    yield put(sendAmendmentContractFailure(error))
  }
}

function* submitAmendmentWorker({ id, params }) {
  try {
    yield call(submitAmendmentService, id, params)
    yield put(submitAmendmentSuccess(id))
  } catch (error) {
    yield put(submitAmendmentFailure(error))
  }
}

export default amendmentsWatcher
