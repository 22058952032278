import { updateItem } from './index'

const updateItemsBySequentialIds = (items, updatedMedia) => {
  return items.map(item => {
    if (String(item.sequential_ids[0]) === String(updatedMedia.sequential_ids[0])) {
      return updatedMedia
    }
    return item
  })
}

const updateGroupBookedMediaFile = ({ items, oldMedia, action }) => {
  const updatedMedia = {
    ...oldMedia,
    uploaded_files: oldMedia.uploaded_files.map(file => {
      if (action.data.ids.includes(file.id)) {
        // update the approval status of the files that were updated
        return {
          ...file,
          approval_status: action.data.approval_status
        }
      } else {
        return file
      }
    }),
    sequential_list: oldMedia.sequential_list.map(sequentialItem => {
      return {
        ...sequentialItem,
        uploaded_files: sequentialItem.uploaded_files.map(file => {
          if (action.data.ids.includes(file.id)) {
            // update the approval status of the files that were updated
            return {
              ...file,
              approval_status: action.data.approval_status
            }
          } else {
            return file
          }
        })
      }
    })
  }

  // regular updateItem doesn't work here because group doesn't have id
  return updateItemsBySequentialIds(items, updatedMedia)
}

const updateRegularBookedMediaFile = ({ items, oldMedia, action }) => {
  const updatedMedia = {
    ...oldMedia,
    uploaded_files: oldMedia.uploaded_files.map(file => {
      if (action.data.ids.includes(file.id)) {
        // update the approval status of the files that were updated
        return {
          ...file,
          approval_status: action.data.approval_status
        }
      } else {
        return file
      }
    })
  }

  return updateItem(items, updatedMedia, action.data.booked_media)
}

export const updateInstallationReportItemsFiles = (items, action) => {
  const oldMedia = items.find(
    item =>
      // find by id
      item.id === action.data.booked_media ||
      // or by first sequential id
      item.sequential_ids?.includes(action.data.booked_media)
  )
  const isGroup = action.data.ids.length > 1

  if (isGroup) {
    // if it's a group - update the file in all the groups
    return updateGroupBookedMediaFile({ items, oldMedia, action })
  } else {
    return updateRegularBookedMediaFile({ items, oldMedia, action })
  }
}
