import { madeRequest } from '../../helpers/api'
import { ENDPOINTS } from '../../constants/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getCombinedCampaignsService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.combinedCampaigns
  })
}

export function getCombinedLineItemsService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.combinedLineItems
  })
}

export function getCombinedGraphsService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.combinedGraphs
  })
}

export function getCombinedSegmentedDataService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.combinedSegmentedData
  })
}

export function getCombinedAdsService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.combinedAds
  })
}
