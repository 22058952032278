import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import BudgetContent from './BudgetContent/BudgetContent'
import EditForm from '../../../../../../features/components/Forms/EditForm'
import SpendContent from './SpendContent/SpendContent'
import TargetCostCapContent from './TargetCostCapContent/TargetCostCapContent'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'

import { getSpendType } from '../../typeHelpers'
import { getBudgetType } from '../../../../../Multiplatform/LineItemForms/typeHelpers'

import { clearUpdateLineItem, updateLineItem } from '../../../../../../modules/actions/lineItems'
import { campaignSelector } from '../../../../../../modules/selectors/campaigns'
import {
  lineItemUpdateErrorSelector,
  lineItemUpdateIsLoadingSelector,
  lineItemWasUpdatedSelector
} from '../../../../../../modules/selectors/lineItems'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import {
  MIN_SPEND_TARGET_DAILY,
  MIN_SPEND_TARGET_LIFETIME,
  ROAS_AVERAGE_FLOOR,
  SPEND_CAP_DAILY,
  SPEND_CAP_LIFETIME,
  TARGET_COST_CAP,
  BUDGET_LIFETIME,
  BUDGET_DAILY
} from '../../../../../ReusableFormFields/LineItemForms/fields'
import { SPEND_DAILY, SPEND_LIFETIME } from '../../fields'
import { COST_CAP, MANUAL, MINIMUM_ROAS } from '../../../../../../constants/campaigns'

const BudgetSectionForm = ({ editItemData: lineItem, ...formProps }) => {
  const dispatch = useDispatch()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const campaign = useSelector(campaignSelector)
  const lineItemWasUpdated = useSelector(lineItemWasUpdatedSelector)

  const { budget_lifetime: lineItemBudgetLifetime, budget_daily: lineItemBudgetDaily } = lineItem
  const {
    budget_lifetime: campaignBudgetLifetime,
    budget_daily: campaignBudgetDaily,
    objective: campaignObjective,
    bidding_type: campaignBiddingType
  } = campaign

  const budgetType = getBudgetType(lineItemBudgetLifetime, lineItemBudgetDaily)
  const spendType = getSpendType(
    lineItemBudgetLifetime,
    lineItemBudgetDaily,
    campaignBudgetLifetime,
    campaignBudgetDaily
  )

  const handleSubmit = useCallback(
    values => {
      const isBudgetLifetime = budgetType === BUDGET_LIFETIME
      const isBudgetDaily = budgetType === BUDGET_DAILY
      const isSpendLifetime = spendType === SPEND_LIFETIME
      const isSpendDaily = spendType === SPEND_DAILY

      const updateLineItemData = {
        account: selectedAdAccountId,
        campaign_objective: campaignObjective,
        ...(isBudgetLifetime && { budget_lifetime: values[BUDGET_LIFETIME] }),
        ...(isBudgetDaily && { budget_daily: values[BUDGET_DAILY] }),
        ...(isSpendLifetime && {
          min_spend_target_lifetime: values[MIN_SPEND_TARGET_LIFETIME],
          spend_cap_lifetime: values[SPEND_CAP_LIFETIME]
        }),
        ...(isSpendDaily && {
          min_spend_target_daily: values[MIN_SPEND_TARGET_DAILY],
          spend_cap_daily: values[SPEND_DAILY]
        }),
        ...((campaignBiddingType === MANUAL || campaignBiddingType === COST_CAP) && {
          target_cost_cap: values[TARGET_COST_CAP]
        }),
        ...(campaignBiddingType === MINIMUM_ROAS && { roas_average_floor: values[ROAS_AVERAGE_FLOOR] })
      }

      dispatch(updateLineItem(updateLineItemData, lineItem.id))
    },
    [budgetType, spendType, selectedAdAccountId, campaignObjective, campaignBiddingType, dispatch, lineItem.id]
  )

  const initialValues = useMemo(
    () => ({
      [BUDGET_LIFETIME]: lineItem[BUDGET_LIFETIME] || '',
      [BUDGET_DAILY]: lineItem[BUDGET_DAILY] || '',
      [MIN_SPEND_TARGET_LIFETIME]: lineItem[MIN_SPEND_TARGET_LIFETIME] || '',
      [SPEND_CAP_LIFETIME]: lineItem[SPEND_CAP_LIFETIME] || '',
      [MIN_SPEND_TARGET_DAILY]: lineItem[MIN_SPEND_TARGET_DAILY] || '',
      [SPEND_CAP_DAILY]: lineItem[SPEND_CAP_DAILY] || '',
      [TARGET_COST_CAP]: lineItem[TARGET_COST_CAP] || '',
      [ROAS_AVERAGE_FLOOR]: lineItem[ROAS_AVERAGE_FLOOR] || ''
    }),
    [lineItem]
  )

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const handleClearUpdateLineItem = useCallback(() => {
    dispatch(clearUpdateLineItem())
  }, [dispatch])

  return (
    <>
      <EditForm
        formik={purifiedFormik}
        initialValues={initialValues}
        successSubmit={lineItemWasUpdated}
        clearEditItem={handleClearUpdateLineItem}
        errorSelector={lineItemUpdateErrorSelector}
        isLoadingSelector={lineItemUpdateIsLoadingSelector}
        {...formProps}
      >
        <BudgetContent
          formik={purifiedFormik}
          budgetType={budgetType}
          campaignBudgetLifetime={campaignBudgetLifetime}
          campaignBudgetDaily={campaignBudgetDaily}
        />
        <SpendContent formik={purifiedFormik} spendType={spendType} />
        <TargetCostCapContent
          formik={purifiedFormik}
          campaignBiddingType={campaignBiddingType}
          campaignObjective={campaignObjective}
        />
      </EditForm>
    </>
  )
}

export default BudgetSectionForm
