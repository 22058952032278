import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import MediaFilesLibrary from '../../../MediaFilesLibrary'
import { getFacebookVideoPicture } from '../../../../../features/components/FacebookPreviews/AdCard/FacebookSingleMediaAdThumbnail/FacebookVideoPreview/helpers'

import { getFacebookMediaVideos, clearFacebookMediaVideos } from '../../../../../modules/actions/mediaFiles'
import {
  facebookImageUrlsSelector,
  facebookVideoPreviewsAfterSelector,
  facebookVideoPreviewsErrorSelector,
  facebookVideoPreviewsIsLoadingSelector,
  facebookVideoPreviewsNextSelector,
  facebookVideoPreviewsSelector,
  facebookVideoPreviewsWasLoadedSelector
} from '../../../../../modules/selectors/mediaFiles'
import { selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'

import { FILE_HASH, VIDEO_PICTURE } from '../../../../Facebook/AdForms/fields'

const FacebookVideoFilesLibrary = ({ adAccountId, initialLoadSize = 12, itemPath, ...props }) => {
  const dispatch = useDispatch()

  const { values } = props

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const afterVideos = useSelector(facebookVideoPreviewsAfterSelector)
  const imageUrls = useSelector(facebookImageUrlsSelector)

  // when ad account is manually passed from outside, use it instead of selected
  const providedAdAccountId = adAccountId || selectedAdAccountId

  const videoPicture = useMemo(
    () =>
      getFacebookVideoPicture(
        {
          [FILE_HASH]: values[itemPath]?.[FILE_HASH],
          [VIDEO_PICTURE]: values[itemPath]?.[VIDEO_PICTURE]
        },
        imageUrls
      ),
    [values, itemPath, imageUrls]
  )

  const loadInitialMediaHandler = useCallback(() => {
    dispatch(
      getFacebookMediaVideos({
        account: providedAdAccountId,
        limit: initialLoadSize
      })
    )
  }, [dispatch, initialLoadSize, providedAdAccountId])

  const loadMoreHandler = useCallback(() => {
    dispatch(
      getFacebookMediaVideos({
        account: providedAdAccountId,
        limit: initialLoadSize,
        after: afterVideos
      })
    )
  }, [dispatch, initialLoadSize, providedAdAccountId, afterVideos])

  const clearVideosHandler = useCallback(() => {
    dispatch(clearFacebookMediaVideos())
  }, [dispatch])

  return (
    <MediaFilesLibrary
      videoPicture={videoPicture}
      type="video"
      mediaThumbnailFieldName="picture"
      mediaFilesSelector={facebookVideoPreviewsSelector}
      mediaFilesIsLoadingSelector={facebookVideoPreviewsIsLoadingSelector}
      mediaFilesErrorSelector={facebookVideoPreviewsErrorSelector}
      mediaFilesWasLoadedSelector={facebookVideoPreviewsWasLoadedSelector}
      mediaFilesNextSelector={facebookVideoPreviewsNextSelector}
      loadInitialMediaHandler={loadInitialMediaHandler}
      loadMoreHandler={loadMoreHandler}
      clearMediaHandler={clearVideosHandler}
      initialLoadingSkeletonsNumber={initialLoadSize}
      {...props}
    />
  )
}

export default FacebookVideoFilesLibrary
