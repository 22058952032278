import React from 'react'
import Skeleton from 'react-loading-skeleton'

import SkeletonChip from '../../../../components/Chip/Skeleton'

import useStyles from './styles'

const SkeletonProductCard = () => {
  const classes = useStyles({})

  return (
    <div className={classes.productCard}>
      <div className={classes.productCardImageContainer}>
        <Skeleton style={{ width: 56, height: 56 }} />
      </div>
      <div className={classes.productCardDetails}>
        <h6 className={classes.productName}>
          <Skeleton style={{ width: '75%', maxWidth: 280 }} />
        </h6>
        <div className={classes.productChips} style={{ marginTop: 4 }}>
          <SkeletonChip width={50} style={{ paddingBottom: 0, fontSize: 12 }} />
          <SkeletonChip width={50} style={{ paddingBottom: 0, fontSize: 12 }} />
        </div>
      </div>
    </div>
  )
}

export default SkeletonProductCard
