import { createStyles } from '../../../../styles/helpers'

import { phonesDown } from '../../../../styles/const/breakpoints'

const useStyles = createStyles({
  chipWrapper: {
    '&:not(:last-child)': {
      marginRight: 6
    },
    '& > span': {
      height: 15,
      display: 'flex',
      alignItems: 'center'
    }
  },
  [`@media screen and (${phonesDown})`]: {
    chipWrapper: {
      display: 'inline-block'
    }
  }
})

export default useStyles
