import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import LineItemFormContent from './LineItemFormContent'
import SkeletonForm from '../../../../../components/Form/Skeleton'
import WarningMessage from '../../../../../features/components/Form/WarningMessage'

import { clearChoicesCountries, getChoicesCountries } from '../../../../../modules/actions/choices'
import {
  choicesCountriesErrorSelector,
  choicesCountriesWasLoadedSelector
} from '../../../../../modules/selectors/choices'
import { selectedTikTokAdAccountIdSelector } from '../../../../../modules/selectors/app'

const LineItemForm = ({ onSuccessSubmit }) => {
  const dispatch = useDispatch()

  const choicesCountriesWasLoaded = useSelector(choicesCountriesWasLoadedSelector)
  const choicesCountriesError = useSelector(choicesCountriesErrorSelector)
  const tikTokAdAccountId = useSelector(selectedTikTokAdAccountIdSelector)

  useEffect(() => {
    if (!choicesCountriesWasLoaded) {
      dispatch(getChoicesCountries())
    }
  }, [dispatch, choicesCountriesWasLoaded])

  // if choices countries were loaded with error, clear them, so user can re-start the flow and re-load them
  useEffect(
    () => () => {
      if (choicesCountriesError) {
        dispatch(clearChoicesCountries())
      }
    },
    [dispatch, choicesCountriesError]
  )

  if (!choicesCountriesWasLoaded && !choicesCountriesError) {
    return <SkeletonForm stepsLength={2} />
  }

  if (choicesCountriesError) {
    return (
      <WarningMessage
        title="Something went wrong"
        subTitle="Launch an off-network campaign"
        description="Sorry, something went wrong with identifying user country. Please try again later or contact your account manager."
      />
    )
  }

  return <LineItemFormContent adAccountId={tikTokAdAccountId} onSuccessSubmit={onSuccessSubmit} />
}

LineItemForm.propTypes = {
  onSuccessSubmit: PropTypes.func.isRequired
}

export default LineItemForm
