import { addDays, getMonth, setMonth } from 'date-fns'

import {
  PERIOD_DAY,
  PERIOD_FIVE_DAY,
  PERIOD_FOUR_DAY,
  PERIOD_ONE_MONTH,
  PERIOD_ONE_WEEK,
  PERIOD_SIX_DAY,
  PERIOD_TEN_DAY,
  PERIOD_THREE_DAY,
  PERIOD_THREE_MONTH,
  PERIOD_TWO_DAY,
  PERIOD_TWO_MONTH,
  PERIOD_TWO_WEEKS
} from '../../../../../constants/mediaOrders'

export const getNextInventoryDate = ({ lastInventoryDate = new Date(), periodOption }) => {
  switch (periodOption) {
    case PERIOD_THREE_MONTH:
      return setMonth(lastInventoryDate, getMonth(lastInventoryDate) + 3)
    case PERIOD_TWO_MONTH:
      return setMonth(lastInventoryDate, getMonth(lastInventoryDate) + 2)
    case PERIOD_ONE_MONTH:
      return setMonth(lastInventoryDate, getMonth(lastInventoryDate) + 1)
    case PERIOD_TWO_WEEKS:
      return addDays(lastInventoryDate, 14)
    case PERIOD_ONE_WEEK:
      return addDays(lastInventoryDate, 7)
    case PERIOD_TEN_DAY:
      return addDays(lastInventoryDate, 10)
    case PERIOD_SIX_DAY:
      return addDays(lastInventoryDate, 6)
    case PERIOD_FIVE_DAY:
      return addDays(lastInventoryDate, 5)
    case PERIOD_FOUR_DAY:
      return addDays(lastInventoryDate, 4)
    case PERIOD_THREE_DAY:
      return addDays(lastInventoryDate, 3)
    case PERIOD_TWO_DAY:
      return addDays(lastInventoryDate, 2)
    case PERIOD_DAY:
    default:
      return addDays(lastInventoryDate, 1)
  }
}
