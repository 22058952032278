import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

// CREATE MULTIPLE ASSETS
export const assetsCreateLoadingSelector = createSelector(
  ({ assets }) => assets.createdAssets.isLoading,
  isLoading => isLoading
)

export const assetsWasCreatedSelector = createSelector(
  ({ assets }) => assets.createdAssets.wasCreated,
  wasCreated => wasCreated
)

export const assetsCreatedDataSelector = createSelector(
  ({ assets }) => assets.createdAssets.data,
  data => data
)

export const assetsCreateErrorSelector = createSelector(
  ({ assets }) => assets.createdAssets.error,
  error => error
)

// GET ASSETS
export const assetsIsLoadingSelector = createSelector(
  ({ assets }) => assets.assets.isLoading,
  isLoading => isLoading
)

export const assetsWasLoadedSelector = createSelector(
  ({ assets }) => assets.assets.wasLoaded,
  wasLoaded => wasLoaded
)

export const assetsSelector = createSelector(
  ({ assets }) => assets.assets.data.results,
  results => results
)

export const imageAssetsSelector = createSelector(
  ({ assets }) => assets.assets.data.results,
  results => {
    return results
      .filter(asset => asset.image_asset)
      .map(imageAsset => ({
        id: imageAsset.id,
        resource_name: imageAsset.resource_name,
        name: imageAsset.name,
        url: imageAsset.image_asset.full_size.url,
        width: imageAsset.image_asset.full_size.width_pixels,
        height: imageAsset.image_asset.full_size.height_pixels
      }))
  }
)

export const assetsNextPageTokenSelector = createSelector(
  ({ assets }) => assets.assets.data.next_page_token,
  next_page_token => next_page_token
)

export const assetsErrorSelector = createSelector(
  ({ assets }) => assets.assets.error,
  error => error
)

// CREATE ASSET GROUP
export const assetGroupCreateDataSelector = createSelector(
  ({ assets }) => assets.createdAssetGroup.data,
  data => data
)

export const assetGroupCreateLoadingSelector = createSelector(
  ({ assets }) => assets.createdAssetGroup.isLoading,
  isLoading => isLoading
)

export const assetGroupWasCreatedSelector = createSelector(
  ({ assets }) => assets.createdAssetGroup.wasCreated,
  wasCreated => wasCreated
)

export const assetGroupCreateErrorSelector = createSelector(
  ({ assets }) => assets.createdAssetGroup.error,
  error => error
)

// GET ASSET GROUPS
export const assetGroupsSelector = createSelector(
  ({ assets }) => assets.assetGroups.results,
  // filter only real asset groups, remove totals item
  results => results.filter(item => item.id)
)

export const assetGroupsNextPageTokenSelector = createSelector(
  ({ assets }) => assets.assetGroups.next_page_token,
  next_page_token => next_page_token
)

export const assetGroupsIsLoadingSelector = createSelector(
  ({ assets }) => assets.assetGroups.isLoading,
  isLoading => isLoading
)

export const assetGroupsWasLoadedSelector = createSelector(
  ({ assets }) => assets.assetGroups.wasLoaded,
  wasLoaded => wasLoaded
)

export const assetGroupsErrorSelector = createSelector(
  ({ assets }) => assets.assetGroups.error,
  error => error
)

// GET ASSET GROUP
export const assetGroupSelector = createSelector(
  ({ assets }) => assets.assetGroup.data,
  data => data
)

export const assetGroupIsLoadingSelector = createSelector(
  ({ assets }) => assets.assetGroup.isLoading,
  isLoading => isLoading
)

export const assetGroupWasLoadedSelector = createSelector(
  ({ assets }) => assets.assetGroup.wasLoaded,
  wasLoaded => wasLoaded
)

export const assetGroupErrorSelector = createSelector(
  ({ assets }) => assets.assetGroup.error,
  error => error
)

// UPDATE ASSET GROUP
export const updateAssetGroupSelector = createSelector(
  ({ assets }) => assets.updatedAssetGroup,
  updatedAssetGroup => updatedAssetGroup
)

export const updateAssetGroupIsLoadingSelector = createSelector(
  ({ assets }) => assets.updatedAssetGroup.isLoading,
  isLoading => isLoading
)

export const updateAssetGroupWaUpdatedSelector = createSelector(
  ({ assets }) => assets.updatedAssetGroup.wasUpdated,
  wasUpdated => wasUpdated
)

export const updateAssetGroupErrorSelector = createSelector(
  ({ assets }) => assets.updatedAssetGroup.error,
  error => error
)

// Approve internal asset group
export const approveInternalAssetGroupSelector = createSelector(
  ({ assets }) => assets.approveInternalAssetGroup,
  approveInternalAssetGroup => approveInternalAssetGroup
)

export const approveInternalAssetGroupIsLoadingSelector = createSelector(
  ({ assets }) => assets.approveInternalAssetGroup.isLoading,
  isLoading => isLoading
)

export const approveInternalAssetGroupErrorSelector = createSelector(
  ({ assets }) => assets.approveInternalAssetGroup.error,
  error => error
)

export const approveInternalAssetGroupWasApprovedSelector = createSelector(
  ({ assets }) => assets.approveInternalAssetGroup.wasUpdated,
  wasUpdated => wasUpdated
)

// Disapprove internal asset group
export const disapproveInternalAssetGroupSelector = createSelector(
  ({ assets }) => assets.disapproveInternalAssetGroup,
  disapproveInternalAssetGroup => disapproveInternalAssetGroup
)

export const disapproveInternalAssetGroupIsLoadingSelector = createSelector(
  ({ assets }) => assets.disapproveInternalAssetGroup.isLoading,
  isLoading => isLoading
)

export const disapproveInternalAssetGroupErrorSelector = createSelector(
  ({ assets }) => assets.disapproveInternalAssetGroup.error,
  error => error
)

export const disapproveInternalAssetGroupWasDisapprovedSelector = createSelector(
  ({ assets }) => assets.disapproveInternalAssetGroup.wasUpdated,
  wasUpdated => wasUpdated
)

// Delete internal asset group
export const deleteInternalAssetGroupSelector = createSelector(
  ({ assets }) => assets.deleteInternalAssetGroup,
  deleteInternalAssetGroup => deleteInternalAssetGroup
)

export const deleteInternalAssetGroupIsLoadingSelector = createSelector(
  ({ assets }) => assets.deleteInternalAssetGroup.isLoading,
  isLoading => isLoading
)

export const deleteInternalAssetGroupErrorSelector = createSelector(
  ({ assets }) => assets.deleteInternalAssetGroup.error,
  error => error
)

export const deleteInternalAssetGroupWasDeletedSelector = createSelector(
  ({ assets }) => assets.deleteInternalAssetGroup.wasDeleted,
  wasDeleted => wasDeleted
)

// CREATE ASSET GROUP SIGNAL
export const createAssetGroupSignalIsLoadingSelector = createSelector(
  ({ assets }) => assets.createAssetGroupSignal.isLoading,
  isLoading => isLoading
)

export const createAssetGroupSignalWasCreatedSelector = createSelector(
  ({ assets }) => assets.createAssetGroupSignal.wasCreated,
  wasCreated => wasCreated
)

export const createAssetGroupSignalErrorSelector = createSelector(
  ({ assets }) => assets.createAssetGroupSignal.error,
  error => error
)
