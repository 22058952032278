import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import MultiFieldItem from '../../../features/components/Form/MultiFieldItem'

import { findNestedObj } from '../../../helpers/arrays'

import useStyles from './styles'

const MultiLevelSelectedValues = ({ values, options, optionsState, onOptionDeselect }) => {
  const classes = useStyles()

  const selectedOptionsData = values.map(id => findNestedObj(options, 'id', id))

  const renderValueItemContent = useCallback(
    option => {
      const optionParentId = option && option.parent

      // if parent is selected > skip rendering children options
      const isParentSelected = optionParentId && optionsState[optionParentId]?.isChecked

      if (!option || !option.name || isParentSelected) {
        return null
      }

      return (
        <MultiFieldItem
          key={option.id}
          value={option}
          label={option.name}
          deleteHandler={onOptionDeselect}
          className={classes.selectedItem}
        />
      )
    },
    [classes.selectedItem, onOptionDeselect, optionsState]
  )

  if (!values.length || !options.length) {
    return null
  }

  return (
    <div className={classes.selectedOptionsWrapper}>
      {selectedOptionsData.map(option => renderValueItemContent(option))}
    </div>
  )
}

MultiLevelSelectedValues.propTypes = {
  values: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  optionsState: PropTypes.object.isRequired,
  onOptionDeselect: PropTypes.func.isRequired
}

export default MultiLevelSelectedValues
