import { createStyles } from '../../../../../../../../styles/helpers'

const useStyles = createStyles({
  discountTabs: {
    marginBottom: 10
  },
  customDiscountCheckbox: {
    marginBottom: 10
  }
})

export default useStyles
