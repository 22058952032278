import { createStyles } from '../../../../../styles/helpers'
import { red } from '../../../../../styles/const/colors'

const useAudienceStyles = createStyles({
  deleteBtn: {
    borderColor: 'transparent',
    color: red
  }
})

export default useAudienceStyles
