import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import useDoubleRequestSubmit from '../../../../../../hooks/formHooks/useDoubleRequestSubmit'

import {
  createQuotation,
  clearCreateQuotation,
  sendQuotation,
  clearSendQuotation
} from '../../../../../../modules/actions/quotations'
import {
  createdQuotationSelector,
  createQuotationErrorSelector,
  quotationWasCreatedSelector,
  sendQuotationErrorSelector,
  sendQuotationWasSentSelector
} from '../../../../../../modules/selectors/quotations'

// QuotationCreateAndSend use 2 requests:
// first need to create quotation and after creating send it to signatory member
export function useCreateAndSendQuotation(contractId) {
  const dispatch = useDispatch()

  const createQuotationHandler = useCallback(
    values => {
      dispatch(
        createQuotation(contractId, {
          quotation: true
        })
      )
    },
    [dispatch, contractId]
  )

  const sendQuotationHandler = useCallback(
    values => {
      dispatch(sendQuotation(values, contractId))
    },
    [dispatch, contractId]
  )

  const clearSubmitHandler = useCallback(() => {
    // after form submit
    dispatch(clearCreateQuotation())
    dispatch(clearSendQuotation())
  }, [dispatch])

  const createQuotationRequest = useMemo(
    () => ({
      requestHandler: createQuotationHandler,
      errorSelector: createQuotationErrorSelector,
      successSelector: quotationWasCreatedSelector,
      dataSelector: createdQuotationSelector
    }),
    [createQuotationHandler]
  )

  const createLineItemRequest = useMemo(
    () => ({
      requestHandler: sendQuotationHandler,
      errorSelector: sendQuotationErrorSelector,
      successSelector: sendQuotationWasSentSelector
    }),
    [sendQuotationHandler]
  )

  const requests = useMemo(
    () => [createQuotationRequest, createLineItemRequest],
    [createQuotationRequest, createLineItemRequest]
  )

  return useDoubleRequestSubmit({ requests, clearSubmitHandler, keepFirstRequestProgress: true })
}
