import React from 'react'
import { useTranslation } from 'react-i18next'

import RadioBlock from '../../../../../components/Form/RadioBlock'
import Button from '../../../../../components/Button'

import {
  AIRLINE_DURATION_OPTION,
  CUSTOM_DAYS,
  CUSTOM_DURATION,
  PAST_1_MONTHS,
  PAST_2_WEEKS,
  PAST_3_MONTHS,
  PAST_WEEK
} from '../fields'

import useStyles from '../../../../../styles/common/stepForms'

const stepFields = [CUSTOM_DAYS]

const AirlineDurationStep = ({ formik, handleStepChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { values, setFieldValue } = formik

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create an audience')}</div>
      <div className={classes.stepTitle}>{t('How recently should the search or booking have been made?')}</div>
      <div className={classes.stepBody}>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_past_3_month"
          name={AIRLINE_DURATION_OPTION}
          value={PAST_3_MONTHS}
          selectedValue={values[AIRLINE_DURATION_OPTION]}
          label={t('In the past 3 months')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_past_1_month"
          name={AIRLINE_DURATION_OPTION}
          value={PAST_1_MONTHS}
          selectedValue={values[AIRLINE_DURATION_OPTION]}
          label={t('In the past 1 month')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_past_2_weeks"
          name={AIRLINE_DURATION_OPTION}
          value={PAST_2_WEEKS}
          selectedValue={values[AIRLINE_DURATION_OPTION]}
          label={t('In the past 2 weeks')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_past_week"
          name={AIRLINE_DURATION_OPTION}
          value={PAST_WEEK}
          selectedValue={values[AIRLINE_DURATION_OPTION]}
          label={t('In the past week')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_custom_days_duration"
          name={AIRLINE_DURATION_OPTION}
          value={CUSTOM_DURATION}
          selectedValue={values[AIRLINE_DURATION_OPTION]}
          label={t('Custom')}
        />
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" className="dark" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default AirlineDurationStep
