import { createStyles } from '../../../../../../styles/helpers'

const useAudienceStyles = createStyles({
  drawerForm: {
    '& .field': {
      marginBottom: 15
    }
  }
})

export default useAudienceStyles
