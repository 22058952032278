import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { isFuture, isSameDay } from 'date-fns'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { combineDateAndTime, getNumberOfDays } from '../../../../../../../helpers/date'

import { formatDateFullYear } from '../../../../../../../constants/dates'

import useStyles from './styles'

const DueDateField = ({ deadline }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const deadlineDate = useMemo(() => new Date(deadline), [deadline])
  const numberOfDays = getNumberOfDays(new Date(), new Date(deadline))
  const isDueToday = isSameDay(new Date(), deadlineDate)
  const isDeadlineInFuture = isFuture(combineDateAndTime(deadlineDate, '23:59'))

  return (
    <div className={classes.deadline}>
      <div>{formatDateFullYear(deadline)}</div>
      {/* Show 'Due today' if the deadline is today */}
      {isDueToday && (
        <div className={classnames(classes.deadlineInDays, classes.deadlineInDaysError)}>{t('Due today')}</div>
      )}
      {/* Show 'in X days' if the deadline is in the future, (we need to check if it's not today, today is 1 day, and may be future */}
      {isDeadlineInFuture && numberOfDays > 0 && !isDueToday && (
        <div className={numberOfDays < 8 ? classes.deadlineInDaysError : classes.deadlineInDays}>
          {t('in')} {numberOfDays} {numberOfDays > 1 ? t('days') : t('day')}
        </div>
      )}
      {/* Show 'Overdue if deadline is in the past */}
      {numberOfDays <= 0 && <div className={classes.deadlineInDaysError}>{t('Overdue')}</div>}
    </div>
  )
}

DueDateField.propTypes = {
  deadline: PropTypes.string.isRequired
}

export default DueDateField
