import { createStyles } from '../../../../../styles/helpers'

import { darkGrey, mediumGrey } from '../../../../../styles/const/colors'
import { tabletDown } from '../../../../../styles/const/breakpoints'

const useStyles = createStyles({
  fieldRow: {
    columnGap: 16,
    '&:not(:first-child)': {
      paddingTop: 16
    },
    '&:not(:last-child)': {
      paddingBottom: 16,
      borderBottom: `1px solid ${mediumGrey}`
    }
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  leftSide: {
    width: '100%',
    maxWidth: 170
  },
  rightSide: {
    width: '100%',
    maxWidth: 225
  },
  title: {
    fontSize: 14,
    fontWeight: 600
  },
  description: {
    marginTop: 4,
    color: darkGrey,
    fontSize: 12
  },
  descriptionBlock: {
    extend: 'description',
    marginTop: 8,
    '& p:not(:last-child)': {
      marginBottom: 8
    }
  },
  footer: {
    marginTop: 16
  },
  [`@media screen and (${tabletDown})`]: {
    fieldRow: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16
    },
    main: {
      flexDirection: 'column'
    },
    description: {
      marginTop: 8
    },
    leftSide: {
      maxWidth: 'none'
    },
    rightSide: {
      marginTop: 16,
      maxWidth: 'none'
    }
  }
})

export default useStyles
