import * as Yup from 'yup'

import {
  END_DATE,
  endDateValidation,
  START_DATE,
  startDateValidation
} from '../../../../../../../ReusableFormFields/StartEndDateRangeFields/fields'
import {
  AUDIENCES_OPTION,
  CUSTOM_AUDIENCES,
  CUSTOM_AUDIENCES_EXCLUSION,
  CUSTOM_AUDIENCES_EXCLUSION_CHECKED,
  customAudiencesInitialValues
} from '../../../../../../../ReusableFormFields/CustomAudiencesFields/fields'
import { OPTION_YES } from '../../../../../../../../constants/forms'
import {
  LINE_ITEM,
  LINE_ITEM_OPTION,
  OPTION_EXISTING_LINE_ITEM,
  OPTION_NEW_LINE_ITEM
} from '../../../AdvancedSetupRoute/LineItemAdvancedForm/LineItemAdvancedFormContent/fields'

export const getInitialValues = isLineItemsExist => ({
  // line_item
  [LINE_ITEM_OPTION]: isLineItemsExist ? OPTION_EXISTING_LINE_ITEM : OPTION_NEW_LINE_ITEM,
  [LINE_ITEM]: '',
  [START_DATE]: '',
  [END_DATE]: '',
  ...customAudiencesInitialValues,
  [AUDIENCES_OPTION]: OPTION_YES
})

export const validationSchema = Yup.object({
  // line item
  [LINE_ITEM]: Yup.string().when(LINE_ITEM_OPTION, {
    is: OPTION_EXISTING_LINE_ITEM,
    then: () => Yup.string().required('Please select line item')
  }),
  // dates
  [START_DATE]: Yup.string().when(LINE_ITEM_OPTION, {
    is: OPTION_NEW_LINE_ITEM,
    then: () => startDateValidation
  }),
  [END_DATE]: Yup.string().when(LINE_ITEM_OPTION, {
    is: OPTION_NEW_LINE_ITEM,
    then: () => endDateValidation
  }),
  // duplication of customAudiencesValidation but with check LINE_ITEM_OPTION rule
  [CUSTOM_AUDIENCES]: Yup.array().when(LINE_ITEM_OPTION, {
    is: OPTION_NEW_LINE_ITEM,
    then: () =>
      Yup.array().when(AUDIENCES_OPTION, {
        is: OPTION_YES,
        then: () => Yup.array().min(1, 'Please select at least one option')
      })
  }),
  [CUSTOM_AUDIENCES_EXCLUSION]: Yup.array().when(LINE_ITEM_OPTION, {
    is: OPTION_NEW_LINE_ITEM,
    then: () =>
      Yup.array().when(CUSTOM_AUDIENCES_EXCLUSION_CHECKED, {
        is: true,
        then: () => Yup.array().min(1, 'Please select at least one option')
      })
  })
})
