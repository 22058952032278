import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Field from '../../../../../../../components/Form/Field'

import { capitalizeFirstLetter } from '../../../../../../../helpers/common'

import { mediaPackageCategoriesSelector } from '../../../../../../../modules/selectors/mediaPackages'

import { CATEGORY } from '../../../../fields'

import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

const CategorySection = ({ formik }) => {
  const drawerFormClasses = useDrawerFormStyles()

  const { t } = useTranslation()

  const categories = useSelector(mediaPackageCategoriesSelector)

  const formattedCategories = useMemo(
    () =>
      categories.map(category => ({
        label: capitalizeFirstLetter(category.name),
        value: category.id
      })),
    [categories]
  )

  return (
    <div className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>{t('Package Category')}</h3>
      <Field formik={formik} name={CATEGORY} options={formattedCategories} placeholder="Category" />
    </div>
  )
}

export default CategorySection
