import { isPast } from 'date-fns'
import { combineDateAndTime, formatDateWithTimeToBE } from '../../../../helpers/date'
import { capitalizeFirstLetter, formatStringWithUnderscore } from '../../../../helpers/common'

import {
  COUNTRY,
  CITY,
  REGION,
  ZIP,
  COUNTRY_GROUP,
  GEO_MARKET,
  PLACE,
  MEDIUM_GEO_AREA,
  LARGE_GEO_AREA,
  SMALL_GEO_AREA,
  SUBCITY,
  NEIGHBORHOOD,
  SUBNEIGHBORHOOD,
  METRO_AREA
} from './fields'

import {
  getPlacementsList,
  placementPositionInitialValues
} from '../../../ReusableFormFields/LineItemForms/facebookLineItemPlacementsHelpers'

export const formatDatesToBE = (startDate, startTime, stopDate, stopTime) => {
  const isStartDateInPast = isPast(combineDateAndTime(startDate, startTime))
  const isStopDateInPast = isPast(combineDateAndTime(stopDate, stopTime))

  return {
    ...(!isStartDateInPast && { time_start: formatDateWithTimeToBE(combineDateAndTime(startDate, startTime)) }),
    ...(!isStopDateInPast && { time_stop: formatDateWithTimeToBE(combineDateAndTime(stopDate, stopTime)) })
  }
}

export const formatGeoTargetingToFE = (geoTargeting, choicesCountries) => {
  // create a copy of object, so we doesn't influence original one, when use delete
  const copiedGeoTargeting = geoTargeting && { ...geoTargeting }

  const geoTargetingFieldToType = {
    countries: COUNTRY,
    cities: CITY,
    regions: REGION,
    zips: ZIP,
    geo_markets: GEO_MARKET,
    places: PLACE,
    country_groups: COUNTRY_GROUP,
    medium_geo_areas: MEDIUM_GEO_AREA,
    large_geo_areas: LARGE_GEO_AREA,
    small_geo_areas: SMALL_GEO_AREA,
    subcities: SUBCITY,
    neighborhoods: NEIGHBORHOOD,
    subneighborhoods: SUBNEIGHBORHOOD,
    metro_areas: METRO_AREA
  }

  const combinedGeoTargeting = []

  if (copiedGeoTargeting) {
    // we don't need to support "location_types" property
    delete copiedGeoTargeting['location_types']
    // custom locations (location list) are handled separately
    delete copiedGeoTargeting['custom_locations']

    for (let field in copiedGeoTargeting) {
      if (copiedGeoTargeting.hasOwnProperty(field)) {
        // map over all geo_targeting properties and combine them in one array
        // for countries and country_groups we need different value formatting then for other properties

        if (field === 'countries') {
          const filteredChoicesCounties = choicesCountries.filter(({ iso2 }) =>
            copiedGeoTargeting[field].includes(iso2)
          )

          filteredChoicesCounties.forEach(({ iso2, name }) => {
            combinedGeoTargeting.push({
              value: iso2,
              label: `${name} (${iso2})`,
              type: geoTargetingFieldToType[field]
            })
          })
        } else if (field === 'country_groups') {
          copiedGeoTargeting[field].forEach(item => {
            combinedGeoTargeting.push({
              value: item,
              label: capitalizeFirstLetter(formatStringWithUnderscore(item)),
              type: geoTargetingFieldToType[field]
            })
          })
        } else {
          copiedGeoTargeting[field].forEach(({ key, name, region, region_id }) => {
            // include region name in label if it is exist in data
            const itemRegion = region ? `, ${region}` : ''

            combinedGeoTargeting.push({
              value: key,
              label: name + itemRegion,
              region,
              region_id,
              type: geoTargetingFieldToType[field]
            })
          })
        }
      }
    }
  }

  return combinedGeoTargeting
}
export const formatGeoTargetingToBE = geo_targeting => {
  const geoTargetingTypeToField = {
    [COUNTRY]: 'countries',
    [CITY]: 'cities',
    [REGION]: 'regions',
    [ZIP]: 'zips',
    [GEO_MARKET]: 'geo_markets',
    [PLACE]: 'places',
    [COUNTRY_GROUP]: 'country_groups',
    [MEDIUM_GEO_AREA]: 'medium_geo_areas',
    [LARGE_GEO_AREA]: 'large_geo_areas',
    [SMALL_GEO_AREA]: 'small_geo_areas',
    [SUBCITY]: 'subcities',
    [NEIGHBORHOOD]: 'neighborhoods',
    [SUBNEIGHBORHOOD]: 'subneighborhoods',
    [METRO_AREA]: 'metro_areas'
  }

  // define initial fields as empty arrays, because we need to send every field, even empty arrays, for proper updating
  let formattedGeoTargeting = {
    [geoTargetingTypeToField[COUNTRY]]: [],
    [geoTargetingTypeToField[CITY]]: [],
    [geoTargetingTypeToField[REGION]]: [],
    [geoTargetingTypeToField[ZIP]]: [],
    [geoTargetingTypeToField[GEO_MARKET]]: [],
    [geoTargetingTypeToField[PLACE]]: [],
    [geoTargetingTypeToField[COUNTRY_GROUP]]: [],
    [geoTargetingTypeToField[MEDIUM_GEO_AREA]]: [],
    [geoTargetingTypeToField[LARGE_GEO_AREA]]: [],
    [geoTargetingTypeToField[SMALL_GEO_AREA]]: [],
    [geoTargetingTypeToField[SUBCITY]]: [],
    [geoTargetingTypeToField[NEIGHBORHOOD]]: [],
    [geoTargetingTypeToField[SUBNEIGHBORHOOD]]: [],
    [geoTargetingTypeToField[METRO_AREA]]: []
  }

  geo_targeting.forEach(({ type, value }) => {
    const targetingFieldName = geoTargetingTypeToField[type]
    const targetingField = formattedGeoTargeting[targetingFieldName]

    let formattedValue

    if (type === COUNTRY || type === COUNTRY_GROUP) {
      formattedValue = value
    } else {
      formattedValue = {
        key: value
      }
    }

    // create an array if field didn't have values
    formattedGeoTargeting[targetingFieldName] = [...targetingField, formattedValue]
  })

  return formattedGeoTargeting
}

export const formatPlacementPositionsToFE = (positions, campaignObjective) => {
  const placements = getPlacementsList(campaignObjective)
  const activePlacements = placements.filter(({ disabled }) => disabled === false)

  const formattedPositions = { ...placementPositionInitialValues }

  if (positions && positions.length) {
    positions.forEach(position => {
      formattedPositions[position] = true
    })
  } else {
    // if there is no placements positions in BE response, we need to set all active placements to true
    activePlacements.forEach(({ name }) => {
      formattedPositions[name] = true
    })
  }

  return formattedPositions
}
