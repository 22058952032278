import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getGenderType } from '../../../typeHelpers'

import { lineItemSelector } from '../../../../../../../modules/selectors/lineItems'

import { GENDER_OPTION_FEMALES, GENDER_OPTION_MALES } from '../../../../../../ReusableFormFields/GenderFields/fields'

const GenderPreview = () => {
  const { t } = useTranslation()

  const { gender } = useSelector(lineItemSelector)

  const genderType = gender && getGenderType(gender)

  if (genderType === GENDER_OPTION_MALES) {
    return <span>{t('Gender: Male')}</span>
  } else if (genderType === GENDER_OPTION_FEMALES) {
    return <span>{t('Gender: Female')}</span>
  } else {
    return <span>{t('Gender: All')}</span>
  }
}

export default GenderPreview
