import { concat } from '../../helpers/common'

const MODULE_NAME = 'ASSETS/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const CREATE_ASSET = concat(MODULE_NAME, 'CREATE_ASSET')
export const CREATE_ASSET_SUCCESS = concat(MODULE_NAME, 'CREATE_ASSET_SUCCESS')
export const CREATE_ASSET_FAILURE = concat(MODULE_NAME, 'CREATE_ASSET_FAILURE')
export const CLEAR_CREATE_ASSET = concat(MODULE_NAME, 'CLEAR_CREATE_ASSET')

export const GET_ASSETS = concat(MODULE_NAME, 'GET_ASSETS')
export const GET_ASSETS_SUCCESS = concat(MODULE_NAME, 'GET_ASSETS_SUCCESS')
export const GET_ASSETS_FAILURE = concat(MODULE_NAME, 'GET_ASSETS_FAILURE')
export const CLEAR_ASSETS = concat(MODULE_NAME, 'CLEAR_ASSETS')

export const CREATE_ASSETS_BATCH = concat(MODULE_NAME, 'CREATE_ASSETS_BATCH')
export const CREATE_ASSETS_BATCH_SUCCESS = concat(MODULE_NAME, 'CREATE_ASSETS_BATCH_SUCCESS')
export const CREATE_ASSETS_BATCH_FAILURE = concat(MODULE_NAME, 'CREATE_ASSETS_BATCH_FAILURE')
export const CLEAR_CREATE_ASSETS_BATCH = concat(MODULE_NAME, 'CLEAR_CREATE_ASSETS_BATCH')

export const CREATE_ASSET_GROUP = concat(MODULE_NAME, 'CREATE_ASSET_GROUP')
export const CREATE_ASSET_GROUP_SUCCESS = concat(MODULE_NAME, 'CREATE_ASSET_GROUP_SUCCESS')
export const CREATE_ASSET_GROUP_FAILURE = concat(MODULE_NAME, 'CREATE_ASSET_GROUP_FAILURE')
export const CLEAR_CREATE_ASSET_GROUP = concat(MODULE_NAME, 'CLEAR_CREATE_ASSET_GROUP')

export const GET_ASSET_GROUPS = concat(MODULE_NAME, 'GET_ASSET_GROUPS')
export const GET_ASSET_GROUPS_SUCCESS = concat(MODULE_NAME, 'GET_ASSET_GROUPS_SUCCESS')
export const GET_ASSET_GROUPS_FAILURE = concat(MODULE_NAME, 'GET_ASSET_GROUPS_FAILURE')
export const CLEAR_ASSET_GROUPS = concat(MODULE_NAME, 'CLEAR_ASSET_GROUPS')

export const GET_ASSET_GROUP = concat(MODULE_NAME, 'GET_ASSET_GROUP')
export const GET_ASSET_GROUP_SUCCESS = concat(MODULE_NAME, 'GET_ASSET_GROUP_SUCCESS')
export const GET_ASSET_GROUP_FAILURE = concat(MODULE_NAME, 'GET_ASSET_GROUP_FAILURE')
export const CLEAR_ASSET_GROUP = concat(MODULE_NAME, 'CLEAR_ASSET_GROUP')

export const UPDATE_ASSET_GROUP = concat(MODULE_NAME, 'UPDATE_ASSET_GROUP')
export const UPDATE_ASSET_GROUP_SUCCESS = concat(MODULE_NAME, 'UPDATE_ASSET_GROUP_SUCCESS')
export const UPDATE_ASSET_GROUP_FAILURE = concat(MODULE_NAME, 'UPDATE_ASSET_GROUP_FAILURE')
export const CLEAR_UPDATE_ASSET_GROUP = concat(MODULE_NAME, 'CLEAR_UPDATE_ASSET_GROUP')

export const APPROVE_INTERNAL_ASSET_GROUP = concat(MODULE_NAME, 'APPROVE_INTERNAL_ASSET_GROUP')
export const APPROVE_INTERNAL_ASSET_GROUP_SUCCESS = concat(MODULE_NAME, 'APPROVE_INTERNAL_ASSET_GROUP_SUCCESS')
export const APPROVE_INTERNAL_ASSET_GROUP_FAILURE = concat(MODULE_NAME, 'APPROVE_INTERNAL_ASSET_GROUP_FAILURE')
export const CLEAR_APPROVE_INTERNAL_ASSET_GROUP = concat(MODULE_NAME, 'CLEAR_APPROVE_INTERNAL_ASSET_GROUP')

export const DISAPPROVE_INTERNAL_ASSET_GROUP = concat(MODULE_NAME, 'DISAPPROVE_INTERNAL_ASSET_GROUP')
export const DISAPPROVE_INTERNAL_ASSET_GROUP_SUCCESS = concat(MODULE_NAME, 'DISAPPROVE_INTERNAL_ASSET_GROUP_SUCCESS')
export const DISAPPROVE_INTERNAL_ASSET_GROUP_FAILURE = concat(MODULE_NAME, 'DISAPPROVE_INTERNAL_ASSET_GROUP_FAILURE')
export const CLEAR_DISAPPROVE_INTERNAL_ASSET_GROUP = concat(MODULE_NAME, 'CLEAR_DISAPPROVE_INTERNAL_ASSET_GROUP')

export const DELETE_INTERNAL_ASSET_GROUP = concat(MODULE_NAME, 'DELETE_INTERNAL_ASSET_GROUP')
export const DELETE_INTERNAL_ASSET_GROUP_SUCCESS = concat(MODULE_NAME, 'DELETE_INTERNAL_ASSET_GROUP_SUCCESS')
export const DELETE_INTERNAL_ASSET_GROUP_FAILURE = concat(MODULE_NAME, 'DELETE_INTERNAL_ASSET_GROUP_FAILURE')
export const CLEAR_DELETE_INTERNAL_ASSET_GROUP = concat(MODULE_NAME, 'CLEAR_DELETE_INTERNAL_ASSET_GROUP')

export const CREATE_ASSET_GROUP_SIGNAL = concat(MODULE_NAME, 'CREATE_ASSET_GROUP_SIGNAL')
export const CREATE_ASSET_GROUP_SIGNAL_SUCCESS = concat(MODULE_NAME, 'CREATE_ASSET_GROUP_SIGNAL_SUCCESS')
export const CREATE_ASSET_GROUP_SIGNAL_FAILURE = concat(MODULE_NAME, 'CREATE_ASSET_GROUP_SIGNAL_FAILURE')
export const CLEAR_CREATE_ASSET_GROUP_SIGNAL = concat(MODULE_NAME, 'CLEAR_CREATE_ASSET_GROUP_SIGNAL')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// create single asset
export function createAsset(assetData, isFileUploading, platform) {
  // isFileUploading allows to handle asset creation within the file uploading process
  // this is needed to fire action about success/error for file uploading for saga worker - createAssetWorker
  return { type: CREATE_ASSET, assetData, isFileUploading, platform }
}

export function createAssetSuccess(assetData) {
  return { type: CREATE_ASSET_SUCCESS, assetData }
}

export function createAssetFailure(error) {
  return { type: CREATE_ASSET_FAILURE, error }
}

export function clearCreateAsset() {
  return { type: CLEAR_CREATE_ASSET }
}

// get assets
export function getAssets(params) {
  return { type: GET_ASSETS, params }
}

export function getAssetsSuccess(assetsData) {
  return { type: GET_ASSETS_SUCCESS, assetsData }
}

export function getAssetsFailure(error) {
  return { type: GET_ASSETS_FAILURE, error }
}

export function clearAssets() {
  return { type: CLEAR_ASSETS }
}

// create multiple assets
export function createAssetsBatch(assetsData, platform) {
  return { type: CREATE_ASSETS_BATCH, assetsData, platform }
}

export function createAssetsBatchSuccess(assetsData) {
  return { type: CREATE_ASSETS_BATCH_SUCCESS, assetsData }
}

export function createAssetsBatchFailure(error) {
  return { type: CREATE_ASSETS_BATCH_FAILURE, error }
}

export function clearCreateAssetsBatch() {
  return { type: CLEAR_CREATE_ASSETS_BATCH }
}

// create ad
export function createAssetGroup(assetGroupData, platform) {
  return { type: CREATE_ASSET_GROUP, assetGroupData, platform }
}

export function createAssetGroupSuccess(assetGroupData) {
  return { type: CREATE_ASSET_GROUP_SUCCESS, assetGroupData }
}

export function createAssetGroupFailure(error) {
  return { type: CREATE_ASSET_GROUP_FAILURE, error }
}

export function clearCreateAssetGroup() {
  return { type: CLEAR_CREATE_ASSET_GROUP }
}

// get assetsGroups
export function getAssetGroups(params) {
  return { type: GET_ASSET_GROUPS, params }
}

export function getAssetGroupsSuccess(assetGroupsData) {
  return { type: GET_ASSET_GROUPS_SUCCESS, assetGroupsData }
}

export function getAssetGroupsFailure(error) {
  return { type: GET_ASSET_GROUPS_FAILURE, error }
}

export function clearAssetGroups() {
  return { type: CLEAR_ASSET_GROUPS }
}

// get assetGroup
export function getAssetGroup(params) {
  return { type: GET_ASSET_GROUP, params }
}

export function getAssetGroupSuccess(assetGroupData) {
  return { type: GET_ASSET_GROUP_SUCCESS, assetGroupData }
}

export function getAssetGroupFailure(error) {
  return { type: GET_ASSET_GROUP_FAILURE, error }
}

export function clearAssetGroup() {
  return { type: CLEAR_ASSET_GROUP }
}

// update assetGroup
export function updateAssetGroup(data, id, platform) {
  return { type: UPDATE_ASSET_GROUP, data, id, platform }
}

export function updateAssetGroupSuccess(data) {
  return { type: UPDATE_ASSET_GROUP_SUCCESS, data }
}

export function updateAssetGroupFailure(error) {
  return { type: UPDATE_ASSET_GROUP_FAILURE, error }
}

export function clearUpdateAssetGroup() {
  return { type: CLEAR_UPDATE_ASSET_GROUP }
}

// approve internal asset group
export function approveInternalAssetGroup(id, provider) {
  return { type: APPROVE_INTERNAL_ASSET_GROUP, id, provider }
}

export function approveInternalAssetGroupSuccess(id, data) {
  return { type: APPROVE_INTERNAL_ASSET_GROUP_SUCCESS, id, data }
}

export function approveInternalAssetGroupFailure(error) {
  return { type: APPROVE_INTERNAL_ASSET_GROUP_FAILURE, error }
}

export function clearApproveInternalAssetGroup() {
  return { type: CLEAR_APPROVE_INTERNAL_ASSET_GROUP }
}

// Disapprove internal asset group
export function disapproveInternalAssetGroup(id, data, provider) {
  return { type: DISAPPROVE_INTERNAL_ASSET_GROUP, id, data, provider }
}

export function disapproveInternalAssetGroupSuccess(id) {
  return { type: DISAPPROVE_INTERNAL_ASSET_GROUP_SUCCESS, id }
}

export function disapproveInternalAssetGroupFailure(error) {
  return { type: DISAPPROVE_INTERNAL_ASSET_GROUP_FAILURE, error }
}

export function clearDisapproveInternalAssetGroup() {
  return { type: CLEAR_DISAPPROVE_INTERNAL_ASSET_GROUP }
}

// Delete internal asset group
export function deleteInternalAssetGroup(id) {
  return { type: DELETE_INTERNAL_ASSET_GROUP, id }
}

export function deleteInternalAssetGroupSuccess(id) {
  return { type: DELETE_INTERNAL_ASSET_GROUP_SUCCESS, id }
}

export function deleteInternalAssetGroupFailure(error) {
  return { type: DELETE_INTERNAL_ASSET_GROUP_FAILURE, error }
}

export function clearDeleteInternalAssetGroup() {
  return { type: CLEAR_DELETE_INTERNAL_ASSET_GROUP }
}

// create asset group signal
export function createAssetGroupSignal(assetGroupSignalData, platform) {
  return { type: CREATE_ASSET_GROUP_SIGNAL, assetGroupSignalData, platform }
}

export function createAssetGroupSignalSuccess(assetGroupSignalData) {
  return { type: CREATE_ASSET_GROUP_SIGNAL_SUCCESS, assetGroupSignalData }
}

export function createAssetGroupSignalFailure(error) {
  return { type: CREATE_ASSET_GROUP_SIGNAL_FAILURE, error }
}

export function clearCreateAssetGroupSignal() {
  return { type: CLEAR_CREATE_ASSET_GROUP_SIGNAL }
}
