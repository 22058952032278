import {
  add,
  set,
  parseISO,
  format,
  getHours,
  getMinutes,
  differenceInDays,
  parse,
  endOfWeek,
  addWeeks,
  endOfMonth,
  addMonths,
  addDays
} from 'date-fns'

import { getCookie } from './common'
import { DATES_FORMAT_BE, initialDatePeriod } from '../constants/dates'
import { DATE_STATIC_PRESET } from '../constants/selectLists/listItemFiltersList'
import {
  PERIOD_ONE_MONTH,
  PERIOD_ONE_WEEK,
  PERIOD_THREE_MONTH,
  PERIOD_TWO_MONTH,
  PERIOD_TWO_WEEKS,
  periodDayValues
} from '../constants/mediaOrders'

export const getInitialDatePeriod = ({ date_preset, startDate, endDate } = {}) => {
  if (date_preset) {
    return initialDatePeriod
  } else {
    return {
      startDate: startDate || null,
      endDate: endDate || new Date(),
      key: 'selection'
    }
  }
}

export const getNumberOfDays = (dateStart, dateStop) => {
  return differenceInDays(new Date(dateStop), new Date(dateStart)) + 1
}

export const getSavedDateRange = rangeCookieName => {
  const savedDateRange = getCookie(rangeCookieName)
  if (savedDateRange) {
    // savedDateRange could be either string of staticPeriods list either datePeriod
    if (savedDateRange[DATE_STATIC_PRESET]) {
      return savedDateRange
    } else {
      // datePeriod object requires to be parsed into Date format
      return {
        ...savedDateRange,
        startDate: parseISO(savedDateRange.startDate),
        endDate: parseISO(savedDateRange.endDate)
      }
    }
  } else {
    return null
  }
}

export const getDateFromTomorrowByDays = days => {
  return add(new Date(), { days })
}

export const formatDateWithTimeToBE = date => {
  // timezone should be skipped
  return format(date, "yyyy-MM-dd'T'HH:mm")
}

export const extractTimeStringFromDate = date => {
  const hours = getHours(date)
  const minutes = getMinutes(date)

  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`
}

export const combineDateAndTime = (date, time) => {
  const [hours, minutes, seconds] = time.split(':')

  return set(new Date(date), { hours, minutes, seconds: seconds || 0 })
}

export const clearTimeZone = timeZonedDate => {
  // clears the timezone offset to current userPlatform/timezone
  // i.e. Tue Dec 11 2017 19:00:00 GMT-0500 (EST) => Tue Dec 11 2017 19:00:00 GMT+0200
  // all of the dates returned from BE is in adAccount timezone, the platform manage dates in current user timezone but
  // actually is meant to be in Ad account timezone

  if (timeZonedDate) {
    // return date only
    const date = new Date(timeZonedDate)
    return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000)
  } else {
    // avoid unexpected crashes
    return timeZonedDate
  }
}

export const changeDateFormatFromNumberToCommon = dateString => {
  if (!dateString) {
    return ''
  }
  if (dateString.toLowerCase() === 'ongoing') {
    return dateString
  }
  const date = parse(dateString, 'dd/MM/yyyy', new Date())

  return format(date, 'dd MMM yyyy')
}

export const getEndDate = (startDate, period) => {
  if (period === PERIOD_ONE_WEEK) {
    return format(
      endOfWeek(new Date(startDate), {
        weekStartsOn: 1
      }),
      DATES_FORMAT_BE
    )
  } else if (period === PERIOD_TWO_WEEKS) {
    return format(
      addWeeks(
        endOfWeek(new Date(startDate), {
          weekStartsOn: 1
        }),
        1
      ),
      DATES_FORMAT_BE
    )
  } else if (period === PERIOD_THREE_MONTH) {
    return format(endOfMonth(addMonths(new Date(startDate), 2)), DATES_FORMAT_BE)
  } else if (period === PERIOD_TWO_MONTH) {
    return format(endOfMonth(addMonths(new Date(startDate), 1)), DATES_FORMAT_BE)
  } else if (period === PERIOD_ONE_MONTH) {
    return format(endOfMonth(new Date(startDate)), DATES_FORMAT_BE)
  } else if (periodDayValues[period]) {
    // if period is any of day type
    const amountOfDays = periodDayValues[period]
    return format(addDays(new Date(startDate), amountOfDays - 1), DATES_FORMAT_BE)
  }
}
