import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import MemberPermissionsEditForm from './MemberPermissionsEditForm'

import useManageFormsDrawer from '../../../../../hooks/formHooks/useManageFormsDrawer'

import { SELF_ACCOUNT_PERMISSIONS_EDIT } from '../../../../../constants/forms'
import { membersSelector, membersIsLoadingSelector } from '../../../../../modules/selectors/selfAccountTeam'

const MemberPermissionsEdit = () => {
  const { t } = useTranslation()

  const selfAccountMembers = useSelector(membersSelector)
  const selfAccountMembersIsLoading = useSelector(membersIsLoadingSelector)

  const { selectedEditItemId } = useManageFormsDrawer({
    formName: SELF_ACCOUNT_PERMISSIONS_EDIT
  })

  const memberData = selfAccountMembers?.find(item => String(item.id) === String(selectedEditItemId))

  return (
    <FormDrawerWrapper
      isFormLoading={selfAccountMembersIsLoading}
      formName={SELF_ACCOUNT_PERMISSIONS_EDIT}
      title={t('Edit advertiser account permissions')}
      showOpenButton={false}
    >
      <MemberPermissionsEditForm memberData={memberData} />
    </FormDrawerWrapper>
  )
}

export default MemberPermissionsEdit
