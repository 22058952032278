import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import Icon from '../../../../../../../../components/Icon'
import ButtonIcon from '../../../../../../../../features/components/Buttons/ButtonIcon'

import { ReactComponent as PencilIcon } from '../../../../../../../../assets/icons/pencil.svg'
import { ReactComponent as CheckmarkIcon } from '../../../../../../../../assets/icons/checked-circle.svg'
import { ReactComponent as CloseIcon } from '../../../../../../../../assets/icons/close-circle-2.svg'

import { MEDIA_PRODUCTS, CUSTOM_ORDER_PRICE, CUSTOM_ORDER_DISCOUNT } from '../../../../fields'

import useStyles from './styles'

export const EDIT_TOTAL = 'edit_total'

const Actions = ({ setFieldValue, productsValues, onTotalSave, disableEditStart, isEditMode, handleTotalEdit }) => {
  const classes = useStyles()

  const [initialValues, setInitialValues] = useState(productsValues)

  const handleStartEditProduct = useCallback(() => {
    // save the values before edit start, to be able to reset on these
    setInitialValues(productsValues)
    handleTotalEdit(EDIT_TOTAL)
  }, [productsValues, handleTotalEdit])

  const handleSaveTotal = useCallback(() => {
    // all data is already saved in the formik, so we just turn off edit mode
    handleTotalEdit(null)
    onTotalSave && onTotalSave()
  }, [handleTotalEdit, onTotalSave])

  const handleResetProducts = useCallback(() => {
    setFieldValue(MEDIA_PRODUCTS, initialValues)
    // reset custom total to 0 on close
    setFieldValue(CUSTOM_ORDER_PRICE, 0)
    setFieldValue(CUSTOM_ORDER_DISCOUNT, 0)
    handleTotalEdit(null)
  }, [initialValues, handleTotalEdit, setFieldValue])

  if (!isEditMode) {
    return (
      <div className={classes.buttonsContainer}>
        <ButtonIcon
          disabled={disableEditStart}
          className={classes.smallButton}
          Icon={PencilIcon}
          onClick={handleStartEditProduct}
        />
      </div>
    )
  }

  return (
    <div className={classes.buttonsContainer}>
      <div className={classes.bigButton} onClick={handleResetProducts}>
        <Icon>
          <CloseIcon />
        </Icon>
      </div>
      <div className={classes.bigButton} onClick={handleSaveTotal}>
        <Icon>
          <CheckmarkIcon />
        </Icon>
      </div>
    </div>
  )
}

Actions.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  productsValues: PropTypes.array.isRequired,
  onTotalSave: PropTypes.func,
  disableEditStart: PropTypes.bool,
  isEditMode: PropTypes.bool,
  handleTotalEdit: PropTypes.func.isRequired
}

export default Actions
