import React from 'react'
import PropTypes from 'prop-types'

import { formatCurrency } from '../../../../../../../helpers/numbers'

import useStyles from './styles'

const FeeItem = ({ fee, totalOrderPrice, discountedPrice, currencySymbol }) => {
  const classes = useStyles()

  const feeCost = totalOrderPrice.regularPrice * fee.percent
  const formattedFeePercent = fee.percent * 100
  const discountedFeeCost = discountedPrice ? discountedPrice * fee.percent : null

  return (
    <div className={classes.feeItem}>
      <div>
        {fee.title} ({formattedFeePercent}%)
      </div>
      <div className={classes.feeCostWrapper}>
        <div className={classes.feeCost}>
          {formatCurrency(
            discountedPrice ? discountedFeeCost : feeCost,
            { min: 2, max: 2 },
            { symbol: currencySymbol }
          )}
        </div>
      </div>
    </div>
  )
}

FeeItem.propTypes = {
  fee: PropTypes.object.isRequired,
  totalOrderPrice: PropTypes.object.isRequired,
  discountedPrice: PropTypes.number,
  currencySymbol: PropTypes.string.isRequired
}

export default FeeItem
