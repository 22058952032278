import { concat } from '../../helpers/common'

const MODULE_NAME = 'SOCIAL_ACCOUNTS/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_FACEBOOK_PAGES = concat(MODULE_NAME, 'GET_FACEBOOK_PAGES')
export const GET_FACEBOOK_PAGES_SUCCESS = concat(MODULE_NAME, 'GET_FACEBOOK_PAGES_SUCCESS')
export const GET_FACEBOOK_PAGES_FAILURE = concat(MODULE_NAME, 'GET_FACEBOOK_PAGES_FAILURE')
export const CLEAR_FACEBOOK_PAGES = concat(MODULE_NAME, 'CLEAR_FACEBOOK_PAGES')

export const GET_ACCESSIBLE_FACEBOOK_PAGES = concat(MODULE_NAME, 'GET_ACCESSIBLE_FACEBOOK_PAGES')
export const GET_ACCESSIBLE_FACEBOOK_PAGES_SUCCESS = concat(MODULE_NAME, 'GET_ACCESSIBLE_FACEBOOK_PAGES_SUCCESS')
export const GET_ACCESSIBLE_FACEBOOK_PAGES_FAILURE = concat(MODULE_NAME, 'GET_ACCESSIBLE_FACEBOOK_PAGES_FAILURE')
export const CLEAR_ACCESSIBLE_FACEBOOK_PAGES = concat(MODULE_NAME, 'CLEAR_ACCESSIBLE_FACEBOOK_PAGES')

export const DELETE_ACCESSIBLE_FACEBOOK_PAGES = concat(MODULE_NAME, 'DELETE_ACCESSIBLE_FACEBOOK_PAGES')
export const DELETE_ACCESSIBLE_FACEBOOK_PAGES_SUCCESS = concat(MODULE_NAME, 'DELETE_ACCESSIBLE_FACEBOOK_PAGES_SUCCESS')
export const DELETE_ACCESSIBLE_FACEBOOK_PAGES_FAILURE = concat(MODULE_NAME, 'DELETE_ACCESSIBLE_FACEBOOK_PAGES_FAILURE')
export const CLEAR_DELETE_ACCESSIBLE_FACEBOOK_PAGES = concat(MODULE_NAME, 'CLEAR_DELETE_ACCESSIBLE_FACEBOOK_PAGES')

export const ADD_ACCESSIBLE_FACEBOOK_PAGES = concat(MODULE_NAME, 'ADD_ACCESSIBLE_FACEBOOK_PAGES')
export const ADD_ACCESSIBLE_FACEBOOK_PAGES_SUCCESS = concat(MODULE_NAME, 'ADD_ACCESSIBLE_FACEBOOK_PAGES_SUCCESS')
export const ADD_ACCESSIBLE_FACEBOOK_PAGES_FAILURE = concat(MODULE_NAME, 'ADD_ACCESSIBLE_FACEBOOK_PAGES_FAILURE')
export const CLEAR_ADD_ACCESSIBLE_FACEBOOK_PAGES = concat(MODULE_NAME, 'CLEAR_ADD_ACCESSIBLE_FACEBOOK_PAGES')

export const GET_FACEBOOK_PAGE_ACCESS_TOKEN = concat(MODULE_NAME, 'GET_FACEBOOK_PAGE_ACCESS_TOKEN')
export const GET_FACEBOOK_PAGE_ACCESS_TOKEN_SUCCESS = concat(MODULE_NAME, 'GET_FACEBOOK_PAGE_ACCESS_TOKEN_SUCCESS')
export const GET_FACEBOOK_PAGE_ACCESS_TOKEN_FAILURE = concat(MODULE_NAME, 'GET_FACEBOOK_PAGE_ACCESS_TOKEN_FAILURE')
export const CLEAR_FACEBOOK_PAGE_ACCESS_TOKEN = concat(MODULE_NAME, 'CLEAR_FACEBOOK_PAGE_ACCESS_TOKEN')

export const CREATE_FACEBOOK_PAGE_ACCESS_REQUEST = concat(MODULE_NAME, 'CREATE_FACEBOOK_PAGE_ACCESS_REQUEST')
export const CREATE_FACEBOOK_PAGE_ACCESS_REQUEST_SUCCESS = concat(
  MODULE_NAME,
  'CREATE_FACEBOOK_PAGE_ACCESS_REQUEST_SUCCESS'
)
export const CREATE_FACEBOOK_PAGE_ACCESS_REQUEST_FAILURE = concat(
  MODULE_NAME,
  'CREATE_FACEBOOK_PAGE_ACCESS_REQUEST_FAILURE'
)
export const CLEAR_CREATE_FACEBOOK_PAGE_ACCESS_REQUEST = concat(
  MODULE_NAME,
  'CLEAR_CREATE_FACEBOOK_PAGE_ACCESS_REQUEST'
)

export const GET_INSTAGRAM_ACCOUNTS = concat(MODULE_NAME, 'GET_INSTAGRAM_ACCOUNTS')
export const GET_INSTAGRAM_ACCOUNTS_SUCCESS = concat(MODULE_NAME, 'GET_INSTAGRAM_ACCOUNTS_SUCCESS')
export const GET_INSTAGRAM_ACCOUNTS_FAILURE = concat(MODULE_NAME, 'GET_INSTAGRAM_ACCOUNTS_FAILURE')
export const CLEAR_INSTAGRAM_ACCOUNTS = concat(MODULE_NAME, 'CLEAR_INSTAGRAM_ACCOUNTS')

export const GET_BACKED_INSTAGRAM_ACCOUNTS = concat(MODULE_NAME, 'GET_BACKED_INSTAGRAM_ACCOUNTS')
export const GET_BACKED_INSTAGRAM_ACCOUNTS_SUCCESS = concat(MODULE_NAME, 'GET_BACKED_INSTAGRAM_ACCOUNTS_SUCCESS')
export const GET_BACKED_INSTAGRAM_ACCOUNTS_FAILURE = concat(MODULE_NAME, 'GET_BACKED_INSTAGRAM_ACCOUNTS_FAILURE')
export const CLEAR_BACKED_INSTAGRAM_ACCOUNTS = concat(MODULE_NAME, 'CLEAR_BACKED_INSTAGRAM_ACCOUNTS')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// facebook pages
export function getFacebookPages() {
  return { type: GET_FACEBOOK_PAGES }
}

export function getFacebookPagesSuccess(choicesData) {
  return { type: GET_FACEBOOK_PAGES_SUCCESS, choicesData }
}

export function getFacebookPagesFailure(error) {
  return { type: GET_FACEBOOK_PAGES_FAILURE, error }
}

export function clearFacebookPages() {
  return { type: CLEAR_FACEBOOK_PAGES }
}

// add accessible facebook pages
export function addAccessibleFacebookPages(pageData) {
  return { type: ADD_ACCESSIBLE_FACEBOOK_PAGES, pageData }
}

export function addAccessibleFacebookPagesSuccess(pageData) {
  return { type: ADD_ACCESSIBLE_FACEBOOK_PAGES_SUCCESS, pageData }
}

export function addAccessibleFacebookPagesFailure(error) {
  return { type: ADD_ACCESSIBLE_FACEBOOK_PAGES_FAILURE, error }
}

export function clearAddAccessibleFacebookPages() {
  return { type: CLEAR_ADD_ACCESSIBLE_FACEBOOK_PAGES }
}

// accessible facebook pages(shared to the current user/partnership)
export function getAccessibleFacebookPages() {
  return { type: GET_ACCESSIBLE_FACEBOOK_PAGES }
}

export function getAccessibleFacebookPagesSuccess(pagesData) {
  return { type: GET_ACCESSIBLE_FACEBOOK_PAGES_SUCCESS, pagesData }
}

export function getAccessibleFacebookPagesFailure(error) {
  return { type: GET_ACCESSIBLE_FACEBOOK_PAGES_FAILURE, error }
}

export function clearAccessibleFacebookPages() {
  return { type: CLEAR_ACCESSIBLE_FACEBOOK_PAGES }
}

// delete accessible facebook pages(shared to the current user/partnership)
export function deleteAccessibleFacebookPages(id) {
  return { type: DELETE_ACCESSIBLE_FACEBOOK_PAGES, id }
}

export function deleteAccessibleFacebookPagesSuccess(id) {
  return { type: DELETE_ACCESSIBLE_FACEBOOK_PAGES_SUCCESS, id }
}

export function deleteAccessibleFacebookPagesFailure(error) {
  return { type: DELETE_ACCESSIBLE_FACEBOOK_PAGES_FAILURE, error }
}

export function clearDeleteAccessibleFacebookPages() {
  return { type: CLEAR_DELETE_ACCESSIBLE_FACEBOOK_PAGES }
}

// facebook page access token
export function getFacebookPageAccessToken(facebookPageId, adAccountId) {
  return { type: GET_FACEBOOK_PAGE_ACCESS_TOKEN, facebookPageId, adAccountId }
}

export function getFacebookPageAccessTokenSuccess(accessToken, facebookPageId) {
  return { type: GET_FACEBOOK_PAGE_ACCESS_TOKEN_SUCCESS, accessToken, facebookPageId }
}

export function getFacebookPageAccessTokenFailure(error, facebookPageId) {
  return { type: GET_FACEBOOK_PAGE_ACCESS_TOKEN_FAILURE, error, facebookPageId }
}

export function clearFacebookPageAccessToken() {
  return { type: CLEAR_FACEBOOK_PAGE_ACCESS_TOKEN }
}

// Create facebook page access request
export function createFacebookPageAccessRequest(data) {
  return { type: CREATE_FACEBOOK_PAGE_ACCESS_REQUEST, data }
}

export function createFacebookPageAccessRequestSuccess(data) {
  return { type: CREATE_FACEBOOK_PAGE_ACCESS_REQUEST_SUCCESS, data }
}

export function createFacebookPageAccessRequestFailure(error) {
  return { type: CREATE_FACEBOOK_PAGE_ACCESS_REQUEST_FAILURE, error }
}

export function clearCreateFacebookPageAccessRequest() {
  return { type: CLEAR_CREATE_FACEBOOK_PAGE_ACCESS_REQUEST }
}

// instagram accounts
export function getInstagramAccounts(facebookPageId, accessToken) {
  return { type: GET_INSTAGRAM_ACCOUNTS, facebookPageId, accessToken }
}

export function getInstagramAccountsSuccess(accountsData, facebookPageId) {
  return { type: GET_INSTAGRAM_ACCOUNTS_SUCCESS, accountsData, facebookPageId }
}

export function getInstagramAccountsFailure(error, facebookPageId) {
  return { type: GET_INSTAGRAM_ACCOUNTS_FAILURE, error, facebookPageId }
}

export function clearInstagramAccounts() {
  return { type: CLEAR_INSTAGRAM_ACCOUNTS }
}

// baked instagram accounts
export function getBackedInstagramAccounts(facebookPageId, accessToken) {
  return { type: GET_BACKED_INSTAGRAM_ACCOUNTS, facebookPageId, accessToken }
}

export function getBackedInstagramAccountsSuccess(accountsData, facebookPageId) {
  return { type: GET_BACKED_INSTAGRAM_ACCOUNTS_SUCCESS, accountsData, facebookPageId }
}

export function getBackedInstagramAccountsFailure(error) {
  return { type: GET_BACKED_INSTAGRAM_ACCOUNTS_FAILURE, error }
}

export function clearBackedInstagramAccounts() {
  return { type: CLEAR_BACKED_INSTAGRAM_ACCOUNTS }
}
