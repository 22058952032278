import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  clearBackedInstagramAccounts,
  clearFacebookPageAccessToken,
  clearInstagramAccounts,
  // getBackedInstagramAccounts,
  getFacebookPageAccessToken,
  getInstagramAccounts
} from '../../modules/actions/socialAccounts'

import useGetInstagramAccounts from './useGetInstagramAccounts'

export default function useLoadInstagramAccounts({
  facebookPageId,
  shouldLoadPageAccessToken,
  shouldLoadInstagramAccounts,
  shouldLoadBackedInstagramAccounts,
  adAccountId
}) {
  const dispatch = useDispatch()

  const {
    selectedInstagramAccounts,
    // selectedBackedInstagramAccounts,
    selectedPageTokenData
  } = useGetInstagramAccounts(facebookPageId)

  const { wasLoaded: instagramAccountsWasLoaded } = selectedInstagramAccounts
  // const { wasLoaded: backedInstagramAccountsWasLoaded } = selectedBackedInstagramAccounts
  const { wasLoaded: selectedPageTokenWasLoaded, token: selectedPageToken } = selectedPageTokenData

  // get facebook page access token, /pages/{id}/access_token/
  useEffect(() => {
    if (facebookPageId && shouldLoadPageAccessToken && !selectedPageTokenWasLoaded) {
      dispatch(getFacebookPageAccessToken(facebookPageId, adAccountId))
    }
  }, [dispatch, adAccountId, facebookPageId, selectedPageTokenWasLoaded, shouldLoadPageAccessToken])

  // Once FB page is selected, and we've got AccessToken - get Instagram Accounts,
  // /pages/{id}/instagram_accounts/
  useEffect(() => {
    if (facebookPageId && selectedPageToken && shouldLoadInstagramAccounts && !instagramAccountsWasLoaded) {
      dispatch(getInstagramAccounts(facebookPageId, selectedPageToken))
    }
  }, [dispatch, facebookPageId, selectedPageToken, shouldLoadInstagramAccounts, instagramAccountsWasLoaded])

  // // Get backed instagram accounts
  // todo find out if we need it, or we can remove it
  // useEffect(() => {
  //   if (facebookPageId && selectedPageToken && shouldLoadBackedInstagramAccounts && !backedInstagramAccountsWasLoaded) {
  //     dispatch(getBackedInstagramAccounts(facebookPageId, selectedPageToken))
  //   }
  // }, [dispatch, facebookPageId, selectedPageToken, shouldLoadBackedInstagramAccounts, backedInstagramAccountsWasLoaded])

  useEffect(() => {
    return () => {
      if (shouldLoadPageAccessToken) {
        dispatch(clearFacebookPageAccessToken())
      }

      if (shouldLoadInstagramAccounts) {
        dispatch(clearInstagramAccounts())
      }

      if (shouldLoadBackedInstagramAccounts) {
        dispatch(clearBackedInstagramAccounts())
      }
    }
  }, [dispatch, shouldLoadPageAccessToken, shouldLoadInstagramAccounts, shouldLoadBackedInstagramAccounts])
}
