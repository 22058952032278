import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { addMonths, startOfMonth } from 'date-fns'
import PropTypes from 'prop-types'

import BreakdownTable from './BreakdownTable'
import GraphTableSection from '../UI/GraphTableSection'
import BookedRevenueByMonthGraph from '../UI/graphs/BookedRevenueByMonthGraph'
import SkeletonBookedRevenueByMonthGraph from '../UI/graphs/Skeleton'

import { formatDateToBE } from '../../../../../../constants/dates'

import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'
import { getBookedRevenueReportService } from '../../../../../../modules/services/mediaOrdersBookings'

const BookedRevenueBreakdown = ({ setupData }) => {
  const [monthRevenueReportData, setMonthRevenueReportData] = useState(null)
  const selfAccountControllerId = useSelector(selectedControllerIdSelector)

  const graphFilters = setupData?.graph?.filters
  const setupDataTable = setupData?.table

  useEffect(() => {
    // start of current month:
    const startDate = startOfMonth(new Date())
    // end in 6months
    const endDate = addMonths(startOfMonth(new Date()), 6)

    getBookedRevenueReportService({
      controller_id: selfAccountControllerId,
      breakdown: 'month',
      date_start_after: formatDateToBE(startDate),
      date_start_before: formatDateToBE(endDate),
      ...graphFilters
    }).then(response => {
      setMonthRevenueReportData(response)
    })
  }, [graphFilters, selfAccountControllerId])

  return (
    <GraphTableSection title="Booked Revenue Breakdown">
      {monthRevenueReportData?.length > 0 ? (
        <BookedRevenueByMonthGraph monthRevenueReportData={monthRevenueReportData} />
      ) : (
        <SkeletonBookedRevenueByMonthGraph />
      )}
      <BreakdownTable setupData={setupDataTable} />
    </GraphTableSection>
  )
}

BookedRevenueBreakdown.propTypes = {
  setupData: PropTypes.object
}

export default BookedRevenueBreakdown
