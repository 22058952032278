import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import ControllerUserContent from './ControllerUserContent'
import AgencyUserContent from './AgencyUserContent'

import { userSelfAccountTypeSelector } from '../../../../modules/selectors/app'

import { AGENCY_TYPE, CONTROLLER_TYPE } from '../../../../constants/permissions'

const ManageAgencyContent = ({ setMemberId, openModalHandler }) => {
  const userAccountType = useSelector(userSelfAccountTypeSelector)
  const isControllerType = useMemo(() => userAccountType === CONTROLLER_TYPE, [userAccountType])
  const isAgencyType = useMemo(() => userAccountType === AGENCY_TYPE, [userAccountType])

  if (isControllerType) {
    return <ControllerUserContent setMemberId={setMemberId} openModalHandler={openModalHandler} />
  }

  if (isAgencyType) {
    return <AgencyUserContent setMemberId={setMemberId} openModalHandler={openModalHandler} />
  }

  return null
}

ManageAgencyContent.propTypes = {
  setMemberId: PropTypes.func.isRequired,
  openModalHandler: PropTypes.func.isRequired
}

export default ManageAgencyContent
