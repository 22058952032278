import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import PackageBreakdown from './PackageBreakdown'
import AdditionalMediaOrderInfo from './AdditionalMediaOrderInfo'
import ActionText from '../../../../../../../../components/ActionText'
import ErrorMessage from '../../../../../../../../components/Form/ErrorMessage'
import Step from '../../../../../../../../features/components/Forms/StepForm/Step'

import { setFormMinimize } from '../../../../../../../../modules/actions/forms'
import { removeSelectedMediaPackage } from '../../../../../../../../modules/actions/mediaOrders'

import { SELECTED_PACKAGES } from '../../fields'

import useStyles from './styles'

function OrderCartStep({ formik, currency }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  const { values, setFieldValue } = formik
  const selectedPackages = values[SELECTED_PACKAGES]

  const addPackage = useCallback(() => {
    dispatch(setFormMinimize(true))
  }, [dispatch])

  const handlePackageRemove = useCallback(
    id => {
      const purifiedPackages = selectedPackages.filter(item => item.id !== id)
      setFieldValue(SELECTED_PACKAGES, purifiedPackages)
      dispatch(removeSelectedMediaPackage(id))
    },
    [dispatch, selectedPackages, setFieldValue]
  )

  return (
    <Step stepTitle="Your media booking" stepDescription="Add packages and products until you are ready to book">
      {/* render all packages section */}
      {selectedPackages.map((selectedMediaPackage, index) => (
        <PackageBreakdown
          key={selectedMediaPackage.id}
          formik={formik}
          currency={currency}
          selectedMediaPackage={selectedMediaPackage}
          onPackageRemove={handlePackageRemove}
          packageIndex={index}
        />
      ))}
      {selectedPackages.length === 0 && <ErrorMessage error={'Select at least one package'} />}
      <ActionText onClick={addPackage} className={classes.addPackage} isDark isBold>
        {t('+ Add another media channel or package')}
      </ActionText>
      <AdditionalMediaOrderInfo formik={formik} />
    </Step>
  )
}

export default OrderCartStep
