import { createStyles } from '../../../styles/helpers'
import { red, redHover } from '../../../styles/const/colors'

const useStyles = createStyles({
  clearButton: {
    marginTop: 16,
    color: red,
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      color: redHover
    }
  }
})

export default useStyles
