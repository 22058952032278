import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import Form from '../../../../../components/Form'
import DrawerHeadline from '../../../../../components/Drawer/DrawerHeadline'
import FieldsSection from '../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../features/components/Form/FieldsSection/FieldRow'
import GoogleLanguages from '../../../../ReusableFormFields/LanguagesFields/GoogleLanguages'

import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'
import useSuccessGoogleLanguagesCreated from './hooks'

import { clearCreateCampaignCriterions, createCampaignCriterions } from '../../../../../modules/actions/campaigns'
import {
  createCampaignCriterionsIsLoadingSelector,
  createCampaignCriterionsErrorSelector
} from '../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'

import { transformLanguagesValuesToBE, initialLanguagesValues } from './fields'
import { LANGUAGES } from '../../../../ReusableFormFields/LineItemForms/fields'

import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

function CampaignGoogleLanguagesForm({ onSuccessLanguagesSubmit, campaignId, adAccountId: providedAdAccountId }) {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormStyles()

  const dispatch = useDispatch()

  const { successSubmit, setSuccessSubmit } = useSuccessGoogleLanguagesCreated()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const adAccountId = providedAdAccountId || selectedAdAccountId

  const onSubmit = useCallback(
    values => {
      if (!values[LANGUAGES].length) {
        // if no languages option is selected > don't send request
        setSuccessSubmit(true)
      } else {
        // create criterions for languages
        const formattedCriterions = transformLanguagesValuesToBE(values)

        const formattedValues = {
          account: adAccountId,
          operations: formattedCriterions.map(operation => ({
            campaign: campaignId,
            ...operation
          }))
        }

        dispatch(createCampaignCriterions(formattedValues))
      }
    },
    [dispatch, campaignId, adAccountId, setSuccessSubmit]
  )

  const formik = useFormik({
    initialValues: initialLanguagesValues,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const { values, setFieldValue } = purifiedFormik

  const clearCreateCriterionsHandler = useCallback(() => {
    dispatch(clearCreateCampaignCriterions())
  }, [dispatch])

  return (
    <div className={drawerFormClasses.formContainer}>
      <DrawerHeadline title="Set the language(s)" subTitle="Define the language(s) of the user (if any)" />
      <Form
        formName="campaignGoogleLanguages"
        formik={purifiedFormik}
        submitText="Save and continue"
        successSubmit={successSubmit}
        onSuccessSubmit={onSuccessLanguagesSubmit}
        clearHandler={clearCreateCriterionsHandler}
        errorSelector={createCampaignCriterionsErrorSelector}
        isLoadingSelector={createCampaignCriterionsIsLoadingSelector}
      >
        <FieldsSection title={t('Package contents')}>
          <FieldRow title={t('Languages (optional)')} description={t('Select which languages you want to target')}>
            <GoogleLanguages
              name={LANGUAGES}
              setFieldValue={setFieldValue}
              value={values[LANGUAGES]}
              placeholder={t('Select language')}
              adAccountId={selectedAdAccountId}
            />
          </FieldRow>
        </FieldsSection>
      </Form>
    </div>
  )
}

export default CampaignGoogleLanguagesForm
