import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import Page from '../index'
import QuotationsTable from './QuotationsTable'
import QuotationReview from './QuotationReview'
import QuotationSend from '../../forms/Multiplatform/QuotationSend'
import Can from '../../features/components/Can'
import ProposalsPageHeader from './ProposalsPageHeader'
import SuccessQuotationSentModal from './SuccessQuotationSentModal'

import { QuotationTableContextProvider } from './QuotationsTable/QuotationTableContext'

import {
  isClientViewSelector,
  isUserControllerSelector,
  selectedSelfAccountSelector
} from '../../modules/selectors/app'

import { SEND_QUOTATION_PERMISSION } from '../../constants/permissions'

const Proposals = () => {
  const isClientView = useSelector(isClientViewSelector)
  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)
  const isUserController = useSelector(isUserControllerSelector)

  const [accountFilterOption, setAccountFilterOption] = useState(undefined)

  const accountFilter = useMemo(() => {
    // filter is shown only to controller user when View as client OFF
    if (isUserController && !isClientView) {
      return accountFilterOption
    } else {
      return selectedSelfAccount
    }
  }, [accountFilterOption, isClientView, isUserController, selectedSelfAccount])

  return (
    <Page helmetTitle="helmetTitle.ProposalsPage">
      <ProposalsPageHeader />
      <QuotationTableContextProvider accountFilter={accountFilter}>
        <QuotationsTable accountFilterOption={accountFilterOption} setAccountFilterOption={setAccountFilterOption} />
      </QuotationTableContextProvider>
      <SuccessQuotationSentModal />
      <QuotationReview />
      <Can I="manage" a={SEND_QUOTATION_PERMISSION}>
        <QuotationSend />
      </Can>
    </Page>
  )
}

export default Proposals
