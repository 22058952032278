import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import CenomiDomainPage from './CenomiDomainPage'
import PageContentWrapper from '../../../components/PageContentWrapper'
import UnauthorizedHeader from '../../../features/components/Header/UnauthorizedHeader'

import { getAppDomainName } from '../../../features/helpers/getAppDomainName'

import { DOMAINS } from '../../../constants/selectLists/languagesList'

function UnauthorizedPage({ helmetTitle, mainClassName, className, children, defaultWrapper = false }) {
  const { t } = useTranslation()
  const appName = getAppDomainName()

  const PageWrapper = useMemo(() => {
    // this function determines the template of unauthorised pages
    // this will be rendered based on the current app domain url
    if (defaultWrapper) {
      return PageContentWrapper
    }

    const currentAppUrl = window.location.href
    if (currentAppUrl.includes(DOMAINS.cenomispace)) {
      return CenomiDomainPage
    } else {
      return PageContentWrapper
    }
  }, [defaultWrapper])

  const hasHeader = useMemo(() => {
    if (defaultWrapper) {
      return true
    }

    const currentAppUrl = window.location.href
    if (currentAppUrl.includes(DOMAINS.cenomispace)) {
      // CenomiSpace domain doesn't have a header
      return false
    } else {
      return true
    }
  }, [defaultWrapper])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>{t(helmetTitle, { appName })}</title>
      </Helmet>
      {hasHeader && <UnauthorizedHeader />}
      <PageWrapper mainClassName={mainClassName} className={className}>
        {children}
      </PageWrapper>
    </>
  )
}

export default UnauthorizedPage

UnauthorizedPage.propTypes = {
  helmetTitle: PropTypes.string,
  mainClassName: PropTypes.string,
  className: PropTypes.string,
  // allows to determine if the page should be wrapped with the default Page component or Domain specific component
  defaultWrapper: PropTypes.bool,
  children: PropTypes.node
}
