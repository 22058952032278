import { createStyles } from '../../../../../styles/helpers'
import { greyOutline, textGrey } from '../../../../../styles/const/colors'

const useStyles = createStyles({
  historyIcon: {
    height: 25,
    width: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px solid ${greyOutline}`,
    color: greyOutline,
    fontSize: 10,
    padding: 0,
    '& svg': {
      width: 16,
      height: 16
    },
    '& path': {
      fill: 'none'
    },

    '&:hover': {
      color: textGrey,
      borderColor: textGrey,
      '& path': {
        fill: ['none', '!important']
      }
    }
  }
})

export default useStyles
