import { getSectionSplitByPage } from '../../../../../features/components/PdfGeneration/generatePdfReport/contentPreparation'
import { cleanUpTemporaryPDFElements, processVideos } from '../../../../../helpers/pdf'
import { getSectionsScreenshots } from '../../../../../features/components/PdfGeneration/generatePdfReport'

import { TABLE_ROW_CLASSNAME } from '../../../../../constants/pdf'
import { facebookAdsTableId, googleAdsTableId, tiktokAdsTableId, dv360AdsTableId } from '../../../../../constants/other'
import { rtlStylePrefix } from '../../../../../features/components/App/StylesProvider'

export const getClassnamePrefix = (className, isRtl) => {
  return isRtl ? `[class^="${rtlStylePrefix}${className}"]` : `[class^="${className}"]`
}

export const getPDFSections = async (brandColor, isRtl) => {
  const campaignsSection = getSectionSplitByPage({
    sectionId: '#media-order-campaigns-table',
    listItemSelector: getClassnamePrefix(TABLE_ROW_CLASSNAME, isRtl),
    chunkSize: 23,
    isRtl
  })

  const facebookAdsSection = getSectionSplitByPage({
    sectionId: '#facebook-ad-cards-list',
    listItemSelector: getClassnamePrefix('cardListItem', isRtl),
    chunkSize: 4,
    processListItems: newUl => processVideos(newUl, brandColor),
    listSelector: 'ul',
    childSectionsSelector: 'ul',
    isRtl
  })
  const facebookAdsTableSection = getSectionSplitByPage({
    sectionId: `#${facebookAdsTableId}`,
    listItemSelector: getClassnamePrefix(TABLE_ROW_CLASSNAME, isRtl),
    chunkSize: 23,
    processListItems: newUl => processVideos(newUl, brandColor),
    isRtl
  })

  const googleAdsSection = getSectionSplitByPage({
    sectionId: '#google-ad-cards-list',
    listItemSelector: getClassnamePrefix('cardListItem', isRtl),
    chunkSize: 4,
    processListItems: newUl => processVideos(newUl, brandColor),
    listSelector: 'ul',
    childSectionsSelector: 'ul',
    isRtl
  })
  const googleAdsTableSection = getSectionSplitByPage({
    sectionId: `#${googleAdsTableId}`,
    listItemSelector: getClassnamePrefix(TABLE_ROW_CLASSNAME, isRtl),
    chunkSize: 23,
    processListItems: newUl => processVideos(newUl, brandColor),
    isRtl
  })

  const tikTokAdsTableSection = getSectionSplitByPage({
    sectionId: `#${tiktokAdsTableId}`,
    listItemSelector: getClassnamePrefix(TABLE_ROW_CLASSNAME, isRtl),
    chunkSize: 23,
    processListItems: newUl => processVideos(newUl, brandColor),
    isRtl
  })
  const tiktokAdsSection = getSectionSplitByPage({
    sectionId: '#tiktok-ad-cards-list',
    listItemSelector: getClassnamePrefix('cardListItem', isRtl),
    chunkSize: 4,
    processListItems: newUl => processVideos(newUl, brandColor),
    listSelector: 'ul',
    childSectionsSelector: 'ul',
    isRtl
  })

  const dv360AdsTableSection = getSectionSplitByPage({
    sectionId: `#${dv360AdsTableId}`,
    listItemSelector: getClassnamePrefix(TABLE_ROW_CLASSNAME, isRtl),
    chunkSize: 23,
    processListItems: newUl => processVideos(newUl, brandColor),
    isRtl
  })
  const dv360AdsSection = getSectionSplitByPage({
    sectionId: '#dv360-ad-cards-list',
    listItemSelector: getClassnamePrefix('cardListItem', isRtl),
    chunkSize: 4,
    processListItems: newUl => processVideos(newUl, brandColor),
    listSelector: 'ul',
    childSectionsSelector: 'ul',
    isRtl
  })

  // takes DOM elements which will be taken as screenshots for PDF
  const reportSectionHeader = document.querySelector('#rights-report-header')
  const graphsSection = document.querySelector('#graphs-report')
  const facebookSectionHeader = document.querySelector('#facebook-ads-header')
  const googleSectionHeader = document.querySelector('#google-ads-header')
  const tikTokSectionHeader = document.querySelector('#tiktok-ads-header')
  const dv360SectionHeader = document.querySelector('#dv360-ads-header')

  const sections = [
    // all table rows could not fit one PDF page, and will be cut by library in unexpected places,
    // so we need to split it by pages manually
    ...campaignsSection,
    reportSectionHeader,
    graphsSection,
    facebookSectionHeader,
    ...facebookAdsTableSection,
    ...facebookAdsSection,
    googleSectionHeader,
    ...googleAdsTableSection,
    ...googleAdsSection,
    tikTokSectionHeader,
    ...tikTokAdsTableSection,
    ...tiktokAdsSection,
    dv360SectionHeader,
    ...dv360AdsTableSection,
    ...dv360AdsSection
  ]

  // create screenshots for each section
  const content = await getSectionsScreenshots(sections)
  // clean up all temporary elements with ".temporary-pdf-wrapper" class
  cleanUpTemporaryPDFElements()

  return content
}
