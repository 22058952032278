import React from 'react'
import PropTypes from 'prop-types'
import CreatableSelectLibrary from 'react-select/creatable'

import Select from '../Select'

const SelectCreatable = ({ onCreateOption, ...props }) => {
  return (
    <Select {...props}>
      <CreatableSelectLibrary onCreateOption={onCreateOption} />
    </Select>
  )
}

SelectCreatable.propTypes = {
  onCreateOption: PropTypes.func.isRequired
}

export default SelectCreatable
