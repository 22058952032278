import { useEffect, useState } from 'react'

import { determineUserCountry } from '../../modules/services/thirdPartyServices'
import { INTERCOM_APP_ID } from '../../constants/api'

export default function useDetermineUserCountry() {
  const [userCountry, setUserCountry] = useState('')

  useEffect(() => {
    // determine user location by ip - make request to Segment api
    determineUserCountry(INTERCOM_APP_ID)
      .then(data => {
        const userCountry = data && data.user && data.user.country_code
        setUserCountry(userCountry)
      })
      .catch(error => console.error('ping segment error', error))
  }, [])

  return userCountry
}
