import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles({
  agencySelect: {
    minWidth: 200,
    maxWidth: 200,
    marginBottom: 20,
    marginLeft: 'auto'
  }
})

export default useStyles
