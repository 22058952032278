import update from 'immutability-helper'
import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAILURE,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAILURE,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAILURE,
  GET_CAMPAIGN,
  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_FAILURE,
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGNS_PAGINATION_SUCCESS,
  GET_CAMPAIGNS_FAILURE,
  CLEAR_CAMPAIGN,
  CLEAR_DELETE_CAMPAIGN,
  CLEAR_UPDATE_CAMPAIGN,
  CLEAR_CREATE_CAMPAIGN,
  CLEAR_CAMPAIGNS,
  GET_SHORT_INFO_CAMPAIGNS,
  GET_SHORT_INFO_CAMPAIGNS_SUCCESS,
  GET_SHORT_INFO_CAMPAIGNS_FAILURE,
  GET_CAMPAIGN_CRITERIONS,
  GET_CAMPAIGN_CRITERIONS_SUCCESS,
  GET_CAMPAIGN_CRITERIONS_FAILURE,
  CLEAR_CAMPAIGN_CRITERIONS,
  GET_CAMPAIGN_AD_SCHEDULE_CRITERIONS,
  GET_CAMPAIGN_AD_SCHEDULE_CRITERIONS_SUCCESS,
  GET_CAMPAIGN_AD_SCHEDULE_CRITERIONS_FAILURE,
  CLEAR_CAMPAIGN_AD_SCHEDULE_CRITERIONS,
  CREATE_CAMPAIGN_CRITERIONS,
  CREATE_CAMPAIGN_CRITERIONS_SUCCESS,
  CREATE_CAMPAIGN_SCHEDULE_CRITERIONS_SUCCESS,
  CREATE_CAMPAIGN_CRITERIONS_FAILURE,
  CLEAR_CREATE_CAMPAIGN_CRITERIONS,
  SET_CAMPAIGNS_FILTER,
  SET_CAMPAIGNS_DATE_RANGE,
  SET_CAMPAIGNS_SORT,
  CLEAR_CAMPAIGNS_FILTERS,
  DATE_RANGE,
  FILTER,
  SORT,
  DELETE_CAMPAIGN_CRITERIONS,
  DUPLICATE_CAMPAIGN,
  DUPLICATE_CAMPAIGN_SUCCESS,
  DUPLICATE_CAMPAIGN_FAILURE,
  CLEAR_DUPLICATE_CAMPAIGN,
  DUPLICATE_CAMPAIGN_COMPLETE
} from '../actions/campaigns'
import {
  campaignFiltersList,
  DATE_STATIC_PRESET,
  LIFETIME_DATE_PRESET
} from '../../constants/selectLists/listItemFiltersList'

import { getCookie } from '../../helpers/common'
import { getSavedDateRange } from '../../helpers/date'
import { updateItem, deleteItemById, deleteItemsByPropertyName } from '../../helpers/modules/reducerHelpers'
import {
  INITIAL_GET_RESULTS_REDUCER,
  INITIAL_GET_RESULTS_PAGING_REDUCER,
  INITIAL_GET_REDUCER,
  INITIAL_CREATE_REDUCER,
  INITIAL_UPDATE_REDUCER,
  INITIAL_DUPLICATE_REDUCER,
  INITIAL_DELETE_REDUCER
} from '../../constants/reducer'

// saved to cookies Date range
const savedDateRange = getSavedDateRange(DATE_RANGE)

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  filter: getCookie(FILTER) || campaignFiltersList[0].value,
  // set saved to cookies Date range or default static preset
  dateRange: savedDateRange || { [DATE_STATIC_PRESET]: LIFETIME_DATE_PRESET },
  sort: getCookie(SORT) || {},
  campaign: INITIAL_GET_REDUCER,
  campaigns: INITIAL_GET_RESULTS_PAGING_REDUCER,
  createdCampaign: INITIAL_CREATE_REDUCER,
  updatedCampaign: INITIAL_UPDATE_REDUCER,
  duplicateCampaign: INITIAL_DUPLICATE_REDUCER,
  deletedCampaign: INITIAL_DELETE_REDUCER,
  campaignCriterions: INITIAL_GET_RESULTS_REDUCER,
  campaignAdScheduleCriterions: INITIAL_GET_REDUCER,
  createCampaignCriterions: INITIAL_CREATE_REDUCER,
  createCampaignCriterionSchedule: INITIAL_CREATE_REDUCER,
  shortInfoCampaigns: INITIAL_GET_RESULTS_REDUCER
}

export default function campaigns(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    // get campaign
    case GET_CAMPAIGN:
      return update(state, {
        campaign: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_CAMPAIGN_SUCCESS:
      return update(state, {
        campaign: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.campaignData
          }
        }
      })
    case GET_CAMPAIGN_FAILURE:
      return update(state, {
        campaign: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CAMPAIGN:
      return update(state, {
        campaign: { $set: initialState.campaign }
      })

    // get campaigns
    case GET_CAMPAIGNS:
      return update(state, {
        campaigns: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_CAMPAIGNS_SUCCESS:
      return update(state, {
        campaigns: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            // todo change condition to only action.campaignsData.data when pagination is available for google
            results: action.campaignsData.data ? action.campaignsData.data : action.campaignsData,
            // paging can be just empty object = {}, that's why we need such structure to prevent errors
            paging: {
              cursors: {
                before: action.campaignsData.paging?.cursors?.before,
                after: action.campaignsData.paging?.cursors?.after
              },
              previous: action.campaignsData.paging?.previous,
              next: action.campaignsData.paging?.next
            }
          }
        }
      })
    case GET_CAMPAIGNS_PAGINATION_SUCCESS:
      return update(state, {
        campaigns: {
          results: {
            $push: action.campaignsData.data
          },
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: {
              cursors: {
                before: action.campaignsData.paging?.cursors?.before,
                after: action.campaignsData.paging?.cursors?.after
              },
              previous: action.campaignsData.paging?.previous,
              next: action.campaignsData.paging?.next
            }
          }
        }
      })
    case GET_CAMPAIGNS_FAILURE:
      return update(state, {
        campaigns: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CAMPAIGNS: {
      return update(state, {
        campaigns: { $set: initialState.campaigns }
      })
    }

    // create campaign
    case CREATE_CAMPAIGN:
      return update(state, {
        createdCampaign: { $merge: { isLoading: true } }
      })
    case CREATE_CAMPAIGN_SUCCESS:
      return update(state, {
        createdCampaign: {
          $merge: {
            id: action.campaignData && action.campaignData.id,
            isLoading: false,
            wasCreated: true,
            data: action.campaignData
          }
        },
        campaigns: {
          results: {
            $unshift: [action.campaignData]
          }
        }
      })
    case CREATE_CAMPAIGN_FAILURE:
      return update(state, {
        createdCampaign: { $merge: { error: action.error, isLoading: false } }
      })
    case CLEAR_CREATE_CAMPAIGN:
      return update(state, {
        createdCampaign: { $set: initialState.campaign }
      })

    // update campaign
    case UPDATE_CAMPAIGN:
      return update(state, {
        updatedCampaign: { $merge: { isLoading: true, [action.id]: true, id: action.id } }
      })
    case UPDATE_CAMPAIGN_SUCCESS:
      const isArchived = action.campaignData.status === 'archived'
      return update(state, {
        campaigns: {
          results: {
            // if we archive campaign we should remove this campaign from active/paused campaigns list,
            // we use for this purpose deleteItemById() method
            $apply: isArchived
              ? items => deleteItemById(items, action.campaignData.id)
              : items => updateItem(items, action.campaignData)
          }
        },
        updatedCampaign: { $merge: { isLoading: false, id: action.campaignData.id, wasUpdated: true } },
        campaign: {
          $merge: {
            data: action.campaignData
          }
        }
      })
    case UPDATE_CAMPAIGN_FAILURE:
      return update(state, {
        updatedCampaign: { $merge: { isLoading: false, error: action.error } }
      })
    case CLEAR_UPDATE_CAMPAIGN:
      return update(state, {
        updatedCampaign: { $set: initialState.updatedCampaign }
      })

    // duplicate campaign
    case DUPLICATE_CAMPAIGN:
      return update(state, {
        duplicateCampaign: {
          $merge: {
            isLoading: true
          }
        }
      })
    case DUPLICATE_CAMPAIGN_SUCCESS:
      return update(state, {
        duplicateCampaign: {
          $merge: {
            isLoading: false,
            duplicateProcessWasStarted: true,
            asyncSessionData: action.asyncSessionData
          }
        }
      })
    case DUPLICATE_CAMPAIGN_COMPLETE:
      return update(state, {
        campaigns: {
          results: {
            $unshift: [action.duplicatedCampaign]
          }
        }
      })
    case DUPLICATE_CAMPAIGN_FAILURE:
      return update(state, {
        duplicateCampaign: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DUPLICATE_CAMPAIGN:
      return update(state, {
        duplicateCampaign: { $set: initialState.duplicateCampaign }
      })

    // delete campaign
    case DELETE_CAMPAIGN:
      return update(state, {
        deletedCampaign: { $set: { isLoading: true, [action.params.id]: true, id: action.params.id } }
      })
    case DELETE_CAMPAIGN_SUCCESS:
      return update(state, {
        campaigns: {
          results: {
            $apply: items => deleteItemById(items, action.id)
          }
        },
        deletedCampaign: { $set: { isLoading: false, id: action.id, wasDeleted: true } }
      })
    case DELETE_CAMPAIGN_FAILURE:
      return update(state, {
        deletedCampaign: { $set: { isLoading: false, error: action.error } }
      })
    case CLEAR_DELETE_CAMPAIGN:
      return update(state, {
        deletedCampaign: { $set: initialState.deletedCampaign }
      })

    // get campaign criterions
    case GET_CAMPAIGN_CRITERIONS:
      return update(state, {
        campaignCriterions: { $merge: { isLoading: true } }
      })
    case GET_CAMPAIGN_CRITERIONS_SUCCESS:
      return update(state, {
        campaignCriterions: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.criterionsData
          }
        }
      })
    case GET_CAMPAIGN_CRITERIONS_FAILURE:
      return update(state, {
        campaignCriterions: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CAMPAIGN_CRITERIONS:
      return update(state, {
        campaignCriterions: { $set: initialState.campaignCriterions }
      })

    // get campaign ad schedule criterions
    case GET_CAMPAIGN_AD_SCHEDULE_CRITERIONS:
      return update(state, {
        campaignAdScheduleCriterions: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_CAMPAIGN_AD_SCHEDULE_CRITERIONS_SUCCESS:
      return update(state, {
        campaignAdScheduleCriterions: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.adScheduleCriterionsData['ad_schedule']
          }
        }
      })
    case GET_CAMPAIGN_AD_SCHEDULE_CRITERIONS_FAILURE:
      return update(state, {
        campaignAdScheduleCriterions: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CAMPAIGN_AD_SCHEDULE_CRITERIONS:
      return update(state, {
        campaignAdScheduleCriterions: { $set: initialState.campaignAdScheduleCriterions }
      })

    // create campaign criterions
    case CREATE_CAMPAIGN_CRITERIONS:
      return update(state, {
        createCampaignCriterions: {
          $merge: {
            isLoading: true
          }
        }
      })
    case CREATE_CAMPAIGN_CRITERIONS_SUCCESS:
      return update(state, {
        createCampaignCriterions: {
          $merge: {
            data: action.criterionsData,
            isLoading: false,
            wasCreated: true
          }
        },
        ...(action.criterionsData.operations && {
          campaignCriterions: {
            results: {
              $push: [...action.criterionsData.operations]
            }
          }
        })
      })
    case CREATE_CAMPAIGN_SCHEDULE_CRITERIONS_SUCCESS:
      return update(state, {
        createCampaignCriterions: {
          $merge: {
            data: action.criterionsData,
            isLoading: false,
            wasCreated: true
          }
        },
        campaignAdScheduleCriterions: {
          data: { $set: action.criterionsData['ad_schedule'] }
        }
      })
    case CREATE_CAMPAIGN_CRITERIONS_FAILURE:
      return update(state, {
        createCampaignCriterions: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_CAMPAIGN_CRITERIONS:
      return update(state, {
        createCampaignCriterions: { $set: initialState.createCampaignCriterions }
      })

    // delete campaign criterions
    case DELETE_CAMPAIGN_CRITERIONS:
      return update(state, {
        campaignCriterions: {
          results: {
            $apply: items => deleteItemsByPropertyName(items, action.criterionsToDelete, 'resource_name')
          }
        }
      })

    // get short info campaigns
    case GET_SHORT_INFO_CAMPAIGNS:
      return update(state, {
        shortInfoCampaigns: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_SHORT_INFO_CAMPAIGNS_SUCCESS:
      return update(state, {
        shortInfoCampaigns: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.campaignsData
          }
        }
      })
    case GET_SHORT_INFO_CAMPAIGNS_FAILURE:
      return update(state, {
        shortInfoCampaigns: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })

    // set / clear campaign filters
    case SET_CAMPAIGNS_FILTER:
      return update(state, {
        filter: { $set: action.filter }
      })
    case SET_CAMPAIGNS_DATE_RANGE:
      return update(state, {
        dateRange: { $set: action.dateRange }
      })
    case SET_CAMPAIGNS_SORT:
      return update(state, {
        sort: { $set: action.sort }
      })
    case CLEAR_CAMPAIGNS_FILTERS:
      return update(state, {
        // back to initial values, set manually because cookie clearing can happen later
        filter: { $set: campaignFiltersList[0].value },
        dateRange: { $set: { [DATE_STATIC_PRESET]: LIFETIME_DATE_PRESET } },
        sort: { $set: {} }
      })
    default:
      return state
  }
}
