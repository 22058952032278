import React from 'react'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import CampaignTikTokCreateForm from './CampaignTikTokCreateForm'

import useRedirectFormDrawer from '../../../../features/components/FormDrawerWrapper/useRedirectFormDrawer'

import { CAMPAIGN_TIKTOK_CREATE } from '../../../../constants/forms'
import { ROUTES } from '../../../../constants/routes'
import { MODAL_TYPE } from '../../../../constants/other'

const CampaignTikTokCreate = () => {
  const handleSuccessRedirect = useRedirectFormDrawer(
    `${ROUTES.campaignsSummary}?message=${MODAL_TYPE.newCampaignCreated}`
  )

  return (
    <FormDrawerWrapper openButtonText="New Campaign" formName={CAMPAIGN_TIKTOK_CREATE} closeOnSubmit={false}>
      <CampaignTikTokCreateForm onSuccessSubmit={handleSuccessRedirect} />
    </FormDrawerWrapper>
  )
}

export default CampaignTikTokCreate
