import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import EditForm from '../../../../../../features/components/Forms/EditForm'
import SelectMultiLevel from '../../../../../../components/SelectMultiLevel'
import TikTokHashtagsFields from '../../../../ReusableFormFields/TikTokHashtagsFields'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import { useEditSectionFormSubmit } from '../../../../../../hooks/formHooks/useEditSectionFormSubmit'

import { filterObjectEmptyValues, insertIf } from '../../../../../../helpers/common'
import { getMultiLevelSelectPayloadValues } from '../../../../../../components/SelectMultiLevel/formatters'
import { formatValuesToFE } from './formatters'

import { updateLineItem, clearUpdateLineItem } from '../../../../../../modules/actions/lineItems'
import {
  lineItemUpdateErrorSelector,
  lineItemUpdateIsLoadingSelector,
  lineItemWasUpdatedSelector
} from '../../../../../../modules/selectors/lineItems'
import {
  tikTokActionCategoriesSelector,
  tikTokInterestsSelector
} from '../../../../../../modules/selectors/tikTokConstants'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { CREATOR_RELATED, HASHTAG_RELATED, VIDEO_RELATED } from '../../../../../../constants/lineItems'
import { INTERESTS } from '../../../../../ReusableFormFields/InterestsFields/fields'
import { ACCOUNT_CATEGORIES, VIDEO_CATEGORIES } from '../../../LineItemTikTokCreate/LineItemTikTokCreateForm/fields'
import { HASHTAGS } from '../../../../ReusableFormFields/TikTokHashtagsFields/fields'

import useDrawerFormStyles from '../../../../../../styles/common/drawerForms'

const AudienceSectionForm = ({ editItemData: lineItem, ...formProps }) => {
  const drawerFormClasses = useDrawerFormStyles()

  const dispatch = useDispatch()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const lineItemWasUpdated = useSelector(lineItemWasUpdatedSelector)
  const tikTokInterests = useSelector(tikTokInterestsSelector)
  const tikTokActionCategories = useSelector(tikTokActionCategoriesSelector)

  const videoCategories = useMemo(() => {
    return tikTokActionCategories.filter(item => item.action_scene === VIDEO_RELATED)
  }, [tikTokActionCategories])

  const accountCategories = useMemo(() => {
    return tikTokActionCategories.filter(item => item.action_scene === CREATOR_RELATED)
  }, [tikTokActionCategories])

  const initialValues = useMemo(
    () =>
      formatValuesToFE({
        lineItem,
        interestOptions: tikTokInterests,
        videoCategories,
        accountCategories
      }),
    [lineItem, tikTokInterests, videoCategories, accountCategories]
  )

  const handleSubmit = useCallback(
    values => {
      const { action: actionItems } = lineItem

      const videoCategoriesAction = actionItems.find(({ action_scene: type }) => type === VIDEO_RELATED)
      const accountCategoriesAction = actionItems.find(({ action_scene: type }) => type === CREATOR_RELATED)
      const hashtagsAction = actionItems.find(({ action_scene: type }) => type === HASHTAG_RELATED)

      // as we use PUT for update tiktok campaign, we should pass full data in payload
      dispatch(
        updateLineItem(
          {
            ...filterObjectEmptyValues(lineItem),
            account: selectedAdAccountId,
            interest_category: getMultiLevelSelectPayloadValues(values[INTERESTS], tikTokInterests),
            action: [
              ...insertIf(values[VIDEO_CATEGORIES].length, {
                ...(videoCategoriesAction
                  ? videoCategoriesAction
                  : {
                      action_scene: VIDEO_RELATED,
                      action_period: 15,
                      user_actions: ['WATCHED_TO_END', 'LIKED', 'COMMENTED', 'SHARED']
                    }),
                action_categories: getMultiLevelSelectPayloadValues(values[VIDEO_CATEGORIES], videoCategories)
              }),
              ...insertIf(values[ACCOUNT_CATEGORIES].length, {
                ...(accountCategoriesAction
                  ? accountCategoriesAction
                  : {
                      action_scene: CREATOR_RELATED,
                      action_period: 0,
                      user_actions: ['FOLLOWING', 'VIEW_HOMEPAGE']
                    }),
                action_categories: getMultiLevelSelectPayloadValues(values[ACCOUNT_CATEGORIES], accountCategories)
              }),
              ...insertIf(values[HASHTAGS].length, {
                ...(hashtagsAction
                  ? hashtagsAction
                  : {
                      action_scene: HASHTAG_RELATED,
                      action_period: 0,
                      user_actions: ['VIEW_HASHTAG']
                    }),
                action_categories: values[HASHTAGS].map(item => item.value)
              })
            ]
          },
          lineItem.id,
          {
            requestMethod: 'PUT'
          }
        )
      )
    },
    [dispatch, lineItem, selectedAdAccountId, tikTokInterests, videoCategories, accountCategories]
  )

  const { onSubmit, manualSuccessSubmit } = useEditSectionFormSubmit({ initialValues, handleSubmit })

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const { setFieldValue, values } = formik

  const handleClearUpdateLineItem = useCallback(() => {
    dispatch(clearUpdateLineItem())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={lineItemWasUpdated || manualSuccessSubmit}
      clearEditItem={handleClearUpdateLineItem}
      errorSelector={lineItemUpdateErrorSelector}
      isLoadingSelector={lineItemUpdateIsLoadingSelector}
      {...formProps}
    >
      <div className={drawerFormClasses.subSection}>
        <h4 className={drawerFormClasses.sectionSubtitle}>Interests</h4>
        <SelectMultiLevel
          placeholder="Add an Interest"
          setFieldValue={setFieldValue}
          value={values[INTERESTS]}
          name={INTERESTS}
          options={tikTokInterests}
        />
      </div>
      <div className={drawerFormClasses.subSection}>
        <h4 className={drawerFormClasses.sectionSubtitle}>Video Categories</h4>
        <SelectMultiLevel
          placeholder="Add a Video Category"
          setFieldValue={setFieldValue}
          value={values[VIDEO_CATEGORIES]}
          name={VIDEO_CATEGORIES}
          options={videoCategories}
        />
      </div>
      <div className={drawerFormClasses.subSection}>
        <h4 className={drawerFormClasses.sectionSubtitle}>Account Categories</h4>
        <SelectMultiLevel
          placeholder="Add an Account Category"
          setFieldValue={setFieldValue}
          value={values[ACCOUNT_CATEGORIES]}
          name={ACCOUNT_CATEGORIES}
          options={accountCategories}
        />
      </div>
      <div className={drawerFormClasses.subSection}>
        <h4 className={drawerFormClasses.sectionSubtitle}>Hashtags</h4>
        <TikTokHashtagsFields formik={formik} />
      </div>
    </EditForm>
  )
}

export default AudienceSectionForm
