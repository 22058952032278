import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useLoadPaginatedList } from '../../../../features/hooks/useLoadPaginatedList'

import { clearGetQuotations, getQuotations } from '../../../../modules/actions/quotations'
import { clearSelfAccounts, getSelfAccounts } from '../../../../modules/actions/selfAccounts'

import { quotationsNextSelector, quotationsSelector } from '../../../../modules/selectors/quotations'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'

import { SORT } from '../../../../constants/other'
import { SORT_CREATED } from '../QuotationsTableData/helpers'

export const QuotationTableContext = React.createContext()

const statuses = ['new', 'signed']
export const QuotationTableContextProvider = ({ children, accountFilter }) => {
  const dispatch = useDispatch()
  const [rowSelection, setRowSelection] = useState({})
  const [tableSort, setTableSort] = useState({
    parameter: SORT_CREATED,
    direction: SORT.DESC
  })

  const quotations = useSelector(quotationsSelector)
  const quotationsNext = useSelector(quotationsNextSelector)
  const controllerId = useSelector(selectedControllerIdSelector)

  const rowIds = useMemo(() => {
    return quotations?.map(({ id }) => id)
  }, [quotations])

  const quotationsParams = useMemo(() => {
    const sortDirectionParam = tableSort.direction === SORT.DESC ? '-' : ''
    return {
      controller: controllerId,
      // we need to add a - so that the ordering is newest first
      ordering: `${sortDirectionParam}${tableSort.parameter}`,
      calculate_proposals: true, // to return total value for all elements - total_value
      status: statuses.join(','),
      ...(accountFilter && { account: accountFilter }),
      limit: 20
    }
  }, [tableSort, controllerId, accountFilter])

  const loadMoreQuotationsHandler = useLoadPaginatedList({
    params: quotationsParams,
    action: getQuotations,
    clearAction: clearGetQuotations,
    next: quotationsNext
  })

  useEffect(() => {
    dispatch(
      getSelfAccounts({
        controllerId: controllerId,
        limit: 999
      })
    )
  }, [dispatch, controllerId])

  useEffect(() => {
    return () => {
      dispatch(clearSelfAccounts())
    }
  }, [dispatch])

  useEffect(() => {
    // there is case when the data element(row) could be removed from the data array
    // so we need to remove the row from the selection
    setRowSelection(prevRowSelection => {
      const newSelection = Object.keys(prevRowSelection).reduce((acc, rowId) => {
        // convert to number after getting from object keys
        const numericRowId = Number(rowId)

        if (rowIds.includes(numericRowId)) {
          acc[numericRowId] = prevRowSelection[numericRowId]
        }
        return acc
      }, {})
      return newSelection
    })
  }, [rowIds])

  return (
    <QuotationTableContext.Provider
      value={{
        rowIds,
        rowSelection,
        setRowSelection,
        tableSort,
        setTableSort,
        loadMoreQuotationsHandler
      }}
    >
      {children}
    </QuotationTableContext.Provider>
  )
}
