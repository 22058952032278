export const bigDesktopDownSize = '1459px'
export const smallDesktopDownSize = '1199px'
export const tabletDownSize = '991px'
export const phonesDownSize = '767px'
export const smallPhonesDownSize = '539px'

export const smallPhonesUpSize = '540px'
export const phonesUpSize = '768px'
export const tabletUpSize = '992px'
export const smallDesktopUpSize = '1200px'
export const bigDesktopUpSize = '1460px'

export const bigDesktopDown = `max-width: ${bigDesktopDownSize}`
export const smallDesktopDown = `max-width: ${smallDesktopDownSize}`
export const tabletDown = `max-width: ${tabletDownSize}`
export const phonesDown = `max-width: ${phonesDownSize}`
export const smallPhonesDown = `max-width: ${smallPhonesDownSize}`

export const smallPhonesUp = `min-width: ${smallPhonesUpSize}`
export const phonesUp = `min-width: ${phonesUpSize}`
export const tabletUp = `min-width: ${tabletUpSize}`
export const smallDesktopUp = `min-width: ${smallDesktopUpSize}`
export const bigDesktopUp = `min-width: ${bigDesktopUpSize}`
