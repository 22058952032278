import { createStyles } from '../../../../../../styles/helpers'

import { cubicBezier } from '../../../../../../styles/const/animation'
import { zIndex5 } from '../../../../../../styles/const/common'

const useStyles = createStyles({
  videoPicture: {
    maxWidth: '100%'
  },
  container: {
    position: 'relative',
    width: '100%'
  },
  playPauseButtons: {
    opacity: 0,
    transition: `opacity 600ms ${cubicBezier}`
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: zIndex5,

    '&:hover': {
      '& $playPauseButtons': {
        opacity: 1
      }
    }
  },
  icon: {
    cursor: 'pointer',
    opacity: 0.9,
    color: 'white',
    '& svg': {
      width: '50px',
      height: '50px',
      '& path': {
        color: 'white'
      }
    }
  }
})

export default useStyles
