import { useMemo } from 'react'

export const useSortHighestPerformingAds = (ads = []) => {
  return useMemo(() => {
    const isConversionExist = ads.some(ad => ad.conversions > 0)

    if (isConversionExist) {
      return ads
        .slice() // Create a shallow copy of the array before sorting
        .sort((a, b) => {
          // First, compare by conversions
          const conversionDiff = b.conversions - a.conversions
          if (conversionDiff !== 0) {
            return conversionDiff
          }
          // If conversions are equal, then sort by CTR
          return b.ctr - a.ctr
        })
        .slice(0, 5)
    } else {
      return ads
        .slice() // Create a shallow copy of the array before sorting
        .sort((a, b) => b.ctr - a.ctr)
        .slice(0, 5)
    }
  }, [ads])
}
