import { IMAGE, IMAGES } from '../../../MediaProductCreate/MediaProductCreateForm/fields'
import { ORDER } from '../../../../../../features/components/Form/SortableUploadImagesList/fields'
import { FILE_URL, initialImageFileValues } from '../../../../../../constants/files'

export const getImagesSectionInitialValues = editProductData => {
  return {
    [IMAGES]: [
      ...editProductData[IMAGES].map(imageItem => ({ ...imageItem, [FILE_URL]: imageItem?.[IMAGE] })),
      {
        // order starts from 1 if no images
        ...initialImageFileValues,
        [ORDER]: editProductData[IMAGES]?.length || 1
      }
    ]
  }
}
