import React, { lazy, Suspense, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import InfoBlock from '../../../../../features/components/InfoBlock'
import { MobileDown, MobileUp } from '../../../../../components/hoc/ResponsiveRendering'
import AppLoader from '../../../../../components/Loaders/AppLoader'
import { BookedMediaDataPeriodsProvider } from './CalendarDesktop/BookedMediaDataPeriodsContext'
import CalendarBookedMediaFilters from './CalendarInventoryFilters'
import { ReactComponent as CalendarIcon } from '../../../../../assets/calendarTimeline/icons/calendar-colorful.svg'

import { getInitialDateRange } from '../../../../../features/components/Calendar/CalendarTable'

import { clearGetInventoryReport, getInventoryReport } from '../../../../../modules/actions/mediaOrdersProducts'
import { getInventoryReportErrorSelector } from '../../../../../modules/selectors/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'

import { CALENDAR_VIEW_TYPE } from '../../../../../constants/selectLists/calendarList'
import { formatDateToBE } from '../../../../../constants/dates'

// lazy load Calendar component to reduce the initial bundle file size
const CalendarDesktop = lazy(() => import('./CalendarDesktop'))

const InventoryReport = () => {
  const inventoryReportError = useSelector(getInventoryReportErrorSelector)
  const selfAccountControllerId = useSelector(selectedControllerIdSelector)
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [params, setParams] = useState(null)
  // find if there is params and some keys in it once empty object:
  const hasSelectedFilters = params && Object.keys(params).length
  // set date range to ref, to avoid the component re-render and handleDataFetch change, when the date range changes
  // as if handleDataFetch changes it will trigger the data fetch automatically in the CalendarBookedMediaFilters useEffect
  const dateRangeRef = useRef(getInitialDateRange(CALENDAR_VIEW_TYPE.MONTH))

  const handleDateRangeChange = useCallback(dateRange => {
    dateRangeRef.current = dateRange
  }, [])

  const handleDataFetch = useCallback(
    filterRequestParams => {
      setParams(filterRequestParams)
      // find if there is params and some keys in it once empty object:
      const hasSelectedFilters = filterRequestParams && Object.keys(filterRequestParams).length

      if (hasSelectedFilters) {
        // due to performance issues to get all data without filters we allow to fetch data only when some filter is
        // applied.

        const { startDate, endDate } = dateRangeRef.current
        dispatch(
          getInventoryReport({
            params: {
              controller: selfAccountControllerId,
              ordering: 'name',
              ...filterRequestParams,
              date_start_after: formatDateToBE(startDate),
              date_start_before: formatDateToBE(endDate),
              group_by_location: true
            },
            loadOptions: {
              shouldClearExistingState: true
            }
          })
        )
      } else {
        // clear the data if no filters are applied
        dispatch(clearGetInventoryReport())
      }
    },
    [dispatch, selfAccountControllerId]
  )

  useEffect(() => {
    return () => {
      dispatch(clearGetInventoryReport())
    }
  }, [dispatch])

  if (inventoryReportError) {
    return (
      <InfoBlock title={t('Sorry, something went wrong')} centered greyDescription>
        <div>{t('Please try again later')}</div>
      </InfoBlock>
    )
  }

  return (
    <>
      <MobileUp>
        <CalendarBookedMediaFilters onFiltersChange={handleDataFetch} />
        <Suspense fallback={<AppLoader isFixed />}>
          <BookedMediaDataPeriodsProvider params={params} onDateRangeChange={handleDateRangeChange}>
            <CalendarDesktop hasSelectedFilters={hasSelectedFilters} />
          </BookedMediaDataPeriodsProvider>
        </Suspense>
      </MobileUp>
      <MobileDown>
        <InfoBlock Icon={CalendarIcon} title={t('The planner is best viewed on desktop')}>
          {t('Please use the desktop version to view your planner')}
        </InfoBlock>
      </MobileDown>
    </>
  )
}

export default InventoryReport
