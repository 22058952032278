import { createStyles } from '../helpers'
import { phonesDown } from '../const/breakpoints'
import { siteLink } from '../common'
import { black, darkGrey, mediumGrey } from '../const/colors'

export const authPageContainerWidth = 425
const useStyles = createStyles({
  authPage: {
    textAlign: 'center'
  },
  authPageSubtitle: {
    color: darkGrey,
    fontSize: 14,
    margin: '12px auto 0',
    fontWeight: 400,
    '& a': {
      extend: siteLink
    }
  },
  authPageFakeLink: {
    extend: siteLink
  },
  fieldsContainer: {
    marginTop: 24
  },
  formRow: {
    display: 'flex',
    columnGap: 10,
    '&:nth-child(2)': {
      marginBottom: 40
    }
  },
  description: {
    marginTop: [4, '!important'],
    fontSize: [12, '!important']
  },
  authBtnWrapper: {
    textAlign: ['right', '!important']
  },
  authBtn: {
    color: black,
    border: `1px solid ${mediumGrey}`
  },
  formContainer: {
    maxWidth: authPageContainerWidth,
    margin: '40px auto 0',
    '& .field': {
      marginBottom: 16
    },
    '& button': {
      marginTop: 8
    }
  },
  authPageFooter: {
    marginTop: 30
  },
  [`@media screen and (${phonesDown})`]: {
    formContainer: {
      maxWidth: '100%'
    }
  }
})

export default useStyles
