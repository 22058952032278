import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Spoiler from '../../../../../../../components/Spoiler'
import Field from '../../../../../../../components/Form/Field'
import FieldLabelWrapper from '../../../../../../../features/components/Form/FieldLabelWrapper'

import { SPEND_LIFETIME } from '../../../fields'

import { selectedAdAccountDataSelector } from '../../../../../../../modules/selectors/app'

import {
  MIN_SPEND_TARGET_DAILY,
  MIN_SPEND_TARGET_LIFETIME,
  SPEND_CAP_DAILY,
  SPEND_CAP_LIFETIME
} from '../../../../../../ReusableFormFields/LineItemForms/fields'

import useStyles from '../../../../../../../styles/common/drawerForms'

const SpendContent = ({ formik, spendType }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const { currency_symbol } = useSelector(selectedAdAccountDataSelector)

  if (spendType) {
    const spendTypeMessage = spendType === SPEND_LIFETIME ? t('Lifetime') : t('Daily')
    const minSpendFieldName = spendType === SPEND_LIFETIME ? MIN_SPEND_TARGET_LIFETIME : MIN_SPEND_TARGET_DAILY
    const maxSpendFieldName = spendType === SPEND_LIFETIME ? SPEND_CAP_LIFETIME : SPEND_CAP_DAILY

    return (
      <Spoiler title={t('View advanced budget options')}>
        <div className={classes.sectionSubtitle}>{t('spendTypeLimit', { spendTypeMessage: spendTypeMessage })}</div>
        <p>{t('You can set either a minimum or maximum spend on this ad set, or both.')}</p>
        <p>
          {t(
            'This will force Facebook to spend at least the amount you set in the case of a minimum spend, or it won’t spend more than the maximum spend.'
          )}
        </p>
        <FieldLabelWrapper label={`${spendTypeMessage} Minimum Spend`}>
          <Field
            type="number"
            formik={formik}
            name={minSpendFieldName}
            placeholder={`${spendTypeMessage} Minimum Spend`}
            symbol={currency_symbol}
            autoComplete="off"
          />
        </FieldLabelWrapper>
        <FieldLabelWrapper label={`${spendTypeMessage} Maximum Spend`}>
          <Field
            type="number"
            formik={formik}
            name={maxSpendFieldName}
            placeholder={`${spendTypeMessage} Maximum Spend`}
            symbol={currency_symbol}
            autoComplete="off"
          />
        </FieldLabelWrapper>
      </Spoiler>
    )
  }

  return null
}

export default SpendContent
