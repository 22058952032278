import React, { useMemo } from 'react'

import ButtonIcon from '../../../../../../features/components/Buttons/ButtonIcon'
import { ReactComponent as InfoIcon } from '../../../../../../assets/icons/info-circle.svg'
import { ReactComponent as AngleIcon } from '../../../../../../assets/icons/chevron-arrow-down.svg'

import { getRandomizedValues } from '../helpers'

import { DESCRIPTIONS_LIST, HEADLINES_LIST } from '../../../fields'

import useStyles from './styles'

const GoogleAdPreviewDisplay3 = React.memo(({ values = {}, fileUrl, backgroundImageUrl }) => {
  const classes = useStyles(backgroundImageUrl)

  const randomizedValues = useMemo(() => {
    return getRandomizedValues(values)
  }, [values])

  const description = randomizedValues[DESCRIPTIONS_LIST]?.[0].text
  const headline = randomizedValues[HEADLINES_LIST]?.[0].text

  return (
    <div className={classes.adPreview}>
      <div className={classes.filter}></div>
      <div className={classes.icon}>
        <InfoIcon />
      </div>
      <div className={classes.blurArea}>
        <p className={classes.headline}>{headline}</p>
        <div className={classes.bottom}>
          <img src={fileUrl} alt="asset" />
          <p className={classes.description}>{description}</p>
          <span className={classes.buttonWrapper}>
            <ButtonIcon className={classes.button} Icon={AngleIcon} />
          </span>
        </div>
      </div>
    </div>
  )
})

export default GoogleAdPreviewDisplay3
