import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../../components/Form'
import Field from '../../../../../../components/Form/Field'

import { clearCreateEventGroup, createEventGroup } from '../../../../../../modules/actions/calendarPlanner'
import {
  calendarRowErrorSelector,
  calendarRowIsLoadingSelector,
  calendarRowWasCreatedSelector
} from '../../../../../../modules/selectors/calendarPlanner'

import { PLANNER_EVENT_GROUP_CREATE } from '../../../../../../constants/forms'
import { eventGroupTypesList } from '../../../../../../constants/selectLists/calendarList'

import { NAME, TYPE, getInitialValues, validationSchema } from '../../fields'

import { lightGrey } from '../../../../../../styles/const/colors'
import useStyles from './styles'

// row is the same as event_group
function CalendarCreateRowForm({ calendarId, eventGroups }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const eventGroupWasCreated = useSelector(calendarRowWasCreatedSelector)

  const getLastOrder = useCallback(() => {
    // find last order, as the order of EventGroup in array is not matching the EventGroup "order" field
    // also it's not reflected to length of the eventGroups array as items could be deleted
    let lastOrder = 0
    eventGroups.forEach(({ order }) => {
      if (order > lastOrder) {
        lastOrder = order
      }
    })
    return lastOrder
  }, [eventGroups])

  const onSubmit = useCallback(
    values => {
      dispatch(
        createEventGroup({
          ...values,
          calendar: calendarId,
          order: getLastOrder() + 1,
          // todo remove hardcoded value after post MVP
          color: lightGrey
        })
      )
    },
    [dispatch, getLastOrder, calendarId]
  )

  const clearCreateEventGroupSubmit = useCallback(() => dispatch(clearCreateEventGroup()), [dispatch])

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit
  })

  return (
    <Form
      formName={PLANNER_EVENT_GROUP_CREATE}
      formik={formik}
      className={classes.drawerForm}
      clearHandler={clearCreateEventGroupSubmit}
      successSubmit={eventGroupWasCreated}
      errorSelector={calendarRowErrorSelector}
      isLoadingSelector={calendarRowIsLoadingSelector}
    >
      <Field label="Event Name" formik={formik} name={NAME} />
      <Field label="Row Type" formik={formik} name={TYPE} options={eventGroupTypesList} />
    </Form>
  )
}

export default CalendarCreateRowForm
