import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import Select from '../../../../components/Select'
import MultiFieldItem from '../MultiFieldItem'
import ManualSearchableSelect from '../ManualSearchableSelect'
import ErrorMessage from '../../../../components/Form/ErrorMessage'
import SelectCreatable from '../../../../components/SelectCreatable'
import SelectPaginated from '../../../../components/SelectPaginated'

const defaultValue = []

// MultiSelectBox - is a Select component with possibility to select multiple values, but with UI differences:
// Instead of rendering selected values in the Select body as Chip - values renders in the separate container
// Also it allows to make BE or FE search within <Select/> component
function MultiSelectBox({
  name,
  setFieldValue,
  value = defaultValue,
  error,
  touched,
  className,
  onSearch,
  noOptionsMessageText,
  triggerClearSearch,
  onCreateOption,
  ...selectProps
}) {
  const { t } = useTranslation()

  const onValueChange = useCallback(values => setFieldValue(name, values), [setFieldValue, name])

  const onSelectedValueRemove = removeValue => {
    onValueChange(value.filter(item => item.value !== removeValue))
  }

  const selectedValues = value.map(({ value, label }) => (
    <MultiFieldItem value={value} key={value} label={label} deleteHandler={onSelectedValueRemove} />
  ))

  const noOptionsMessage = useCallback(() => t(noOptionsMessageText || 'No Results'), [t, noOptionsMessageText])

  const [searchValue, setSearchValue] = useState('')
  const handleSearchChange = useCallback(
    (newValue, data) => {
      if (data.action !== 'set-value' && data.action !== 'menu-close') {
        // don't clear search text in input, when user selects an option
        setSearchValue(newValue)
      }
    },
    [setSearchValue]
  )

  const commonMultiSelectProps = useMemo(
    () => ({
      isMulti: true,
      value,
      formatValue: false,
      backspaceRemovesValue: false,
      controlShouldRenderValue: false,
      onChange: onValueChange,
      noOptionsMessage,
      inputValue: searchValue,
      onInputChange: handleSearchChange
    }),
    [value, onValueChange, noOptionsMessage, searchValue, handleSearchChange]
  )

  const renderSelectComponent = useCallback(() => {
    const loadOptions = selectProps.loadOptions

    if (loadOptions) {
      return <SelectPaginated isSearchable loadOptions={loadOptions} {...commonMultiSelectProps} {...selectProps} />
    } else if (onSearch) {
      return (
        // Select component with search making on BE, i.e. requesting BE on search
        // refactor this component to use import Async, { useAsync } from 'react-select/async';
        <ManualSearchableSelect
          onSearch={onSearch}
          triggerClearSearch={triggerClearSearch}
          {...commonMultiSelectProps}
          {...selectProps}
        />
      )
    } else if (onCreateOption) {
      return (
        // Select component which can create new options
        <SelectCreatable onCreateOption={onCreateOption} isSearchable {...commonMultiSelectProps} {...selectProps} />
      )
    } else {
      return (
        // regular Select component with FE search
        <Select value={value} onChange={onValueChange} isSearchable {...commonMultiSelectProps} {...selectProps} />
      )
    }
  }, [selectProps, onSearch, onCreateOption, commonMultiSelectProps, triggerClearSearch, value, onValueChange])

  return (
    <>
      <div className={classnames('field', className)}>
        <div>{selectedValues}</div>
        {renderSelectComponent()}
      </div>
      {error && touched && <ErrorMessage error={error} />}
    </>
  )
}

MultiSelectBox.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  options: PropTypes.array,
  isLoading: PropTypes.bool,
  value: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  onCreateOption: PropTypes.func, // allows to create new options
  noOptionsMessageText: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.array])
}

export default MultiSelectBox
