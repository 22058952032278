import { createStyles } from '../../../../styles/helpers'

import { container } from '../../../../styles/common'
import { zIndex9 } from '../../../../styles/const/common'

export const headerHeight = 50

const useStyles = createStyles({
  header: {
    width: '100%',
    padding: '0 20px',
    backgroundColor: 'white',
    boxShadow: '0 1px 5px 2px rgba(0,0,0,.05)',
    minHeight: headerHeight,
    position: 'fixed',
    zIndex: zIndex9,
    top: 0,
    left: 0
  },
  container: {
    extend: container,
    maxWidth: 1500,
    display: 'flex',
    alignItems: 'center',
    minHeight: headerHeight,
    '&.is-centered': {
      justifyContent: 'center'
    }
  },
  logo: {
    display: 'flex',
    fontSize: 0,
    width: 'auto',
    maxWidth: '70px',
    height: 30,
    position: 'relative',
    '& svg, & img': {
      width: '100%',
      height: 'auto',
      objectFit: 'contain'
    }
  },
  logoutBtn: {
    position: 'absolute',
    right: 20,
    top: '50%',
    transform: 'translateY(-50%)',
    margin: 0
  }
})

export default useStyles
