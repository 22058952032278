import { createStyles } from '../../../../../../styles/helpers'

export default createStyles({
  total: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '16px',
    fontSize: 16
  },
  smallPrice: {
    fontSize: 12
  }
})
