import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  confirmTitle: {
    marginTop: 40,
    textAlign: 'center'
  },
  buttonContainer: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'center'
  }
})

export default useStyles
