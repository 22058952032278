import React from 'react'
import { reorderElement } from '../../../../helpers/arrays'
import { getIn } from 'formik'
import PropTypes from 'prop-types'

import DragAndDropWrapper from '../../DragAndDropWrapper'
import SortableUploadImagesListItem from './SortableUploadImagesListItem'
import ErrorMessage from '../../../../components/Form/ErrorMessage'

import { FILE_URL } from '../../../../forms/ReusableFormFields/AdFileUpload/fields'
import { IMAGES, ORDER } from './fields'

const SortableUploadImagesList = ({ formik, CustomImagePreview, isInDrawer }) => {
  const { values, setFieldValue, errors, touched } = formik

  const imageError = getIn(errors, IMAGES)
  const imageTouched = getIn(touched, IMAGES)
  const imageMissingError = imageTouched && imageError

  const images = values[IMAGES]

  const handleDragEnd = event => {
    const {
      source: { index: sourceIndex },
      destination: { index: destinationIndex }
    } = event
    const newImages = reorderElement(images, sourceIndex, destinationIndex)
    // order field is used for sorting images on backend
    newImages.forEach((image, index) => {
      image[ORDER] = index + 1
    })

    setFieldValue(IMAGES, newImages)
  }

  // if imageItem has fileUrl it can be draggable, values[IMAGES] always have empty item at the end
  const uploadedImages = images.filter(imageItem => !!imageItem?.[FILE_URL])
  const lastEmptyImageIndex = images.length - 1
  const lastEmptyImage = images[lastEmptyImageIndex]

  return (
    <>
      <DragAndDropWrapper droppableId={'mediaProductLocationImages'} onDragEnd={handleDragEnd}>
        {uploadedImages.map((image, index) => (
          <SortableUploadImagesListItem
            key={image.id}
            formik={formik}
            index={index}
            CustomImagePreview={CustomImagePreview}
            isInDrawer={isInDrawer}
          />
        ))}
      </DragAndDropWrapper>
      <SortableUploadImagesListItem
        formik={formik}
        key={lastEmptyImage[ORDER]}
        index={lastEmptyImageIndex}
        accept="image/png"
      />
      {imageMissingError && <ErrorMessage error={imageError} />}
    </>
  )
}

SortableUploadImagesList.propTypes = {
  formik: PropTypes.object,
  CustomImagePreview: PropTypes.elementType
}

export default SortableUploadImagesList
