import { lightGrey2, darkGrey, red, transparent, greyOutline } from '../../styles/const/colors'
import { zIndex10 } from '../../styles/const/common'
import { selectValueSize, leftSpaceSize } from '../Select/styles'
import { tabletDown } from '../../styles/const/breakpoints'

const getBackgroundColorForControl = ({ isDisable, hasValue, menuIsOpen, brandPrimary }) => {
  if (isDisable) {
    return lightGrey2
  }
  if (hasValue && !menuIsOpen) {
    // show highligthed background only when value is selected and menu is closed for 2 cases:
    // 1 - search visibility
    // 2 - don't trigger color change when value is selected
    return brandPrimary
  }

  return 'white'
}
// pseudoDisable/pseudoOpened is used for representing only UI layer of Select
export function getHighlightedValueSelectStyles(props) {
  const selectActiveBorderColor = props.theme.selectActiveBorderColor

  return {
    menu: provided => ({
      ...provided,
      marginTop: '2px',
      color: darkGrey,
      borderRadius: props.theme.defaultBorderRadius,
      right: props.menuPosition === 'right' ? 0 : 'unset',
      minWidth: '100%',
      maxWidth: 250,
      // auto width makes menu container wider then select container if option content doesn't fit
      width: props.iconStyleSelect ? '150px' : 'max-content', // changes from Select
      zIndex: zIndex10
    }),
    clearIndicator: provided => ({
      ...provided,
      cursor: 'pointer'
    }),
    control: (provided, { menuIsOpen, isDisabled, hasValue }) => ({
      ...provided,
      fontSize: 14,
      minHeight: 38,
      maxHeight: 38,
      borderRadius: props.theme.defaultBorderRadius,
      borderColor: getBorderColor(props, menuIsOpen),
      boxShadow: 'none',
      backgroundColor: getBackgroundColorForControl({
        isDisabled,
        menuIsOpen,
        hasValue,
        brandPrimary: props.theme.brandPrimary
      }),
      flexWrap: 'no-wrap',
      maxWidth: '100%',

      ':hover': {
        ...provided[':hover'],
        borderColor: props.noBorder ? transparent : selectActiveBorderColor
      },
      ':active': {
        ...provided[':active'],
        borderColor: props.noBorder ? transparent : selectActiveBorderColor
      },
      ':focused': {
        ...provided[':active'],
        borderColor: props.noBorder ? transparent : selectActiveBorderColor
      },
      [`@media screen and (${tabletDown})`]: {
        minHeight: 40,
        maxHeight: 40
      }
    }),
    placeholder: provided => ({
      ...provided,
      color: props.theme.brandPrimary,
      fontWeight: 600, // changes from Select
      marginLeft: props.isMulti ? leftSpaceSize : '1px',
      fontSize: selectValueSize
    })
  }
}

const getBorderColor = (props, menuIsOpen) => {
  const selectActiveBorderColor = props.theme.selectActiveBorderColor

  if (props.isDisabled) {
    return lightGrey2
  } else if (props.noBorder) {
    return transparent
  } else if (props.hasError) {
    return red
  } else if (menuIsOpen || props.pseudoOpened || props.focused || props.isHovered) {
    return selectActiveBorderColor
  } else {
    return greyOutline
  }
}
