import { useEffect, useMemo, useState } from 'react'
import { clearCampaignCriterions } from '../../../../../modules/actions/campaigns'
import { useDispatch, useSelector } from 'react-redux'

import { createCampaignCriterionsWasCreatedSelector } from '../../../../../modules/selectors/campaigns'

const useSuccessGoogleLanguagesCreated = () => {
  const dispatch = useDispatch()

  const [successSubmit, setSuccessSubmit] = useState(false)

  const criterionsWasCreated = useSelector(createCampaignCriterionsWasCreatedSelector)

  useEffect(() => {
    if (criterionsWasCreated) {
      setSuccessSubmit(true)

      dispatch(clearCampaignCriterions())
    }
  }, [dispatch, criterionsWasCreated])

  return useMemo(
    () => ({
      successSubmit,
      setSuccessSubmit
    }),
    [successSubmit, setSuccessSubmit]
  )
}

export default useSuccessGoogleLanguagesCreated
