import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import EditForm from '../../../../../../features/components/Forms/EditForm'
import MultiSelectBox from '../../../../../../features/components/Form/MultiSelectBox'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import { useEditSectionFormSubmit } from '../../../../../../hooks/formHooks/useEditSectionFormSubmit'

import { filterObjectEmptyValues } from '../../../../../../helpers/common'

import { updateLineItem, clearUpdateLineItem } from '../../../../../../modules/actions/lineItems'
import {
  lineItemUpdateErrorSelector,
  lineItemUpdateIsLoadingSelector,
  lineItemWasUpdatedSelector
} from '../../../../../../modules/selectors/lineItems'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { LANGUAGES } from '../../../../../ReusableFormFields/LanguagesFields/fields'
import { tikTokLanguagesList } from '../../../../../../constants/selectLists/languagesList'

const LanguagesSectionForm = ({ editItemData: lineItem, ...formProps }) => {
  const dispatch = useDispatch()

  const lineItemWasUpdated = useSelector(lineItemWasUpdatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const initialValues = useMemo(
    () => ({
      [LANGUAGES]: tikTokLanguagesList.filter(item => lineItem[LANGUAGES].includes(item.value))
    }),
    [lineItem]
  )

  const handleSubmit = useCallback(
    values => {
      // as we use PUT for update TikTok campaign, we should pass full data in payload
      dispatch(
        updateLineItem(
          {
            ...filterObjectEmptyValues(lineItem),
            account: selectedAdAccountId,
            [LANGUAGES]: values[LANGUAGES].map(item => item.value)
          },
          lineItem.id,
          {
            requestMethod: 'PUT'
          }
        )
      )
    },
    [dispatch, lineItem, selectedAdAccountId]
  )

  const { onSubmit, manualSuccessSubmit } = useEditSectionFormSubmit({ initialValues, handleSubmit })

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const { setFieldValue, values } = formik

  const handleClearUpdateLineItem = useCallback(() => {
    dispatch(clearUpdateLineItem())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={lineItemWasUpdated || manualSuccessSubmit}
      clearEditItem={handleClearUpdateLineItem}
      errorSelector={lineItemUpdateErrorSelector}
      isLoadingSelector={lineItemUpdateIsLoadingSelector}
      {...formProps}
    >
      <MultiSelectBox
        placeholder="Add a Language"
        name={LANGUAGES}
        options={tikTokLanguagesList}
        value={values[LANGUAGES]}
        setFieldValue={setFieldValue}
      />
    </EditForm>
  )
}

export default LanguagesSectionForm
