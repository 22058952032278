import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addMonths, startOfMonth } from 'date-fns'
import PropTypes from 'prop-types'

import useSearch from '../../../../../hooks/useSearch'

import { selectedControllerDataSelector, selectedSelfAccountSelector } from '../../../../../modules/selectors/app'

import { formatDateToBE } from '../../../../../constants/dates'
import { BOOKED_MEDIA_AND_FILES_SEARCH } from '../index'
import {
  STATUS_FILTER_AWAITING_FILES,
  STATUS_FILTER_FILES_OVERDUE,
  STATUS_FILTER_FILES_PENDING_REVIEW
} from '../../../../../features/components/Filters/BookedMediaStatusFilter/options'

export const BookedMediaFiltersContext = React.createContext()

export function BookedMediaDataProvider({ children, onFiltersChange }) {
  const dispatch = useDispatch()

  const searchTerm = useSearch(BOOKED_MEDIA_AND_FILES_SEARCH)
  const selfAccount = useSelector(selectedSelfAccountSelector)
  const { category: selectedControllerCategory } = useSelector(selectedControllerDataSelector)

  const [dateRangeFilter, setDateRangeFilter] = useState({
    startDate: startOfMonth(new Date()),
    endDate: addMonths(startOfMonth(new Date()), 3)
  })

  const [statusFilterOption, setStatusFilterOption] = useState(undefined)
  const [multipleAccountFilterOption, setMultipleAccountFilterOption] = useState([selfAccount])

  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedSubCategories, setSelectedSubCategories] = useState([])
  const [selectedSubSubCategories, setSelectedSubSubCategories] = useState([])

  const [productFilterOption, setProductFilterOption] = useState(undefined)
  const [selectedTag, setSelectedTag] = useState(undefined)
  const [selectedLocations, setSelectedLocations] = useState([])

  const [brandCategory, setBrandCategory] = useState(
    selectedControllerCategory ? [Number(selectedControllerCategory?.id)] : []
  )
  const [brandSubCategory, setBrandSubCategory] = useState([])

  const filterRequestParams = useMemo(
    () => ({
      // date
      ...(dateRangeFilter.startDate && { date_start: formatDateToBE(dateRangeFilter.startDate) }),
      ...(dateRangeFilter.endDate && { date_end: formatDateToBE(dateRangeFilter.endDate) }),
      // search
      ...(searchTerm && { search: searchTerm }),
      // status
      ...(statusFilterOption === STATUS_FILTER_AWAITING_FILES && { file_pending: true }),
      ...(statusFilterOption === STATUS_FILTER_FILES_PENDING_REVIEW && { files_pending_review: true }),
      ...(statusFilterOption === STATUS_FILTER_FILES_OVERDUE && { files_overdue: true }),
      // account
      ...(multipleAccountFilterOption?.length && { account: multipleAccountFilterOption }),
      // categories
      ...(selectedCategories.length && { media_category: selectedCategories }),
      ...(selectedSubCategories.length && { media_sub_category: selectedSubCategories }),
      ...(selectedSubSubCategories.length && { media_sub_sub_category: selectedSubSubCategories }),
      // media_product
      ...(productFilterOption && { media_product: productFilterOption }),
      // location
      ...(selectedLocations.length && { location: selectedLocations }),
      // tag
      ...(selectedTag && { tags: selectedTag }),
      // brand category
      ...(brandCategory.length && { brand_category: brandCategory }),
      // brand sub category
      ...(brandSubCategory.length && { brand_subcategory: brandSubCategory })
    }),
    [
      searchTerm,
      statusFilterOption,
      multipleAccountFilterOption,
      productFilterOption,
      dateRangeFilter,
      selectedCategories,
      selectedSubCategories,
      selectedSubSubCategories,
      selectedLocations,
      selectedTag,
      brandCategory,
      brandSubCategory
    ]
  )

  const bookedMediaFilterProps = useMemo(
    () => ({
      filterRequestParams,
      dateRangeFilter,
      setDateRangeFilter,
      statusFilterOption,
      setStatusFilterOption,
      multipleAccountFilterOption,
      setMultipleAccountFilterOption,
      selectedCategories,
      setSelectedCategories,
      selectedSubCategories,
      setSelectedSubCategories,
      selectedSubSubCategories,
      setSelectedSubSubCategories,
      productFilterOption,
      setProductFilterOption,
      selectedLocations,
      setSelectedLocations,
      selectedTag,
      setSelectedTag,
      brandCategory,
      setBrandCategory,
      brandSubCategory,
      setBrandSubCategory
    }),
    [
      filterRequestParams,
      multipleAccountFilterOption,
      selectedCategories,
      selectedSubCategories,
      selectedSubSubCategories,
      dateRangeFilter,
      statusFilterOption,
      productFilterOption,
      selectedLocations,
      selectedTag,
      brandCategory,
      brandSubCategory
    ]
  )
  // make a type check for the props
  PropTypes.checkPropTypes(propTypes, bookedMediaFilterProps, 'prop', 'useRequestHandler')

  useEffect(() => {
    onFiltersChange(filterRequestParams)
  }, [dispatch, filterRequestParams, onFiltersChange])

  return (
    <BookedMediaFiltersContext.Provider value={bookedMediaFilterProps}>{children}</BookedMediaFiltersContext.Provider>
  )
}

export const filterRequestParamsTypes = PropTypes.shape({
  date_start: PropTypes.string,
  date_end: PropTypes.string,
  search: PropTypes.string,
  file_pending: PropTypes.bool,
  files_pending_review: PropTypes.bool,
  files_overdue: PropTypes.bool,
  account: PropTypes.array,
  media_category: PropTypes.array,
  media_sub_category: PropTypes.array,
  media_sub_sub_category: PropTypes.array,
  media_product: PropTypes.array,
  location: PropTypes.array,
  tags: PropTypes.string
}).isRequired

const propTypes = {
  filterRequestParams: filterRequestParamsTypes,
  dateRangeFilter: PropTypes.object.isRequired,
  setDateRangeFilter: PropTypes.func.isRequired,
  statusFilterOption: PropTypes.string,
  setStatusFilterOption: PropTypes.func.isRequired,
  multipleAccountFilterOption: PropTypes.array,
  setMultipleAccountFilterOption: PropTypes.func.isRequired,
  selectedCategories: PropTypes.array,
  setSelectedCategories: PropTypes.func.isRequired,
  selectedSubCategories: PropTypes.array,
  setSelectedSubCategories: PropTypes.func.isRequired,
  selectedSubSubCategories: PropTypes.array,
  setSelectedSubSubCategories: PropTypes.func.isRequired,
  productFilterOption: PropTypes.array,
  setProductFilterOption: PropTypes.func.isRequired,
  paramsData: PropTypes.object,
  selectedTag: PropTypes.string,
  setSelectedTag: PropTypes.func.isRequired,
  selectedLocations: PropTypes.array,
  setSelectedLocations: PropTypes.func.isRequired
}
