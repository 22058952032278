import { formatAdCreativeToBE } from './formatters'

import getConversionDomain from './helpers/getConversionDomain'
import getConversionDomainValidation from './helpers/getConversionDomainValidation'

import { NAME, PIXEL_CHOICE } from '../../fields'

export const transformValuesToBE = ({
  values,
  adAccountId,
  adSetId,
  campaignId,
  validateOnly,
  validatedDomains,
  isInternalAdCreation,
  selfAccountController,
  selectedSelfAccountId
}) => {
  const conversionDomain = getConversionDomain(values)

  const { wasDomainValidated, isDomainValid } = getConversionDomainValidation(conversionDomain, validatedDomains)

  return {
    name: values[NAME],
    status: 'active',
    account: adAccountId,
    account_id: selectedSelfAccountId,
    adset_id: adSetId,
    campaign_id: campaignId,
    engagement_audience: false,
    ...(isInternalAdCreation && {
      quarantine: true,
      controller_id: selfAccountController
    }),
    creative: {
      degrees_of_freedom_spec: {
        creative_features_spec: {
          standard_enhancements: {
            enroll_status: 'OPT_OUT'
          }
        }
      },
      ...formatAdCreativeToBE({ values, adAccountId })
    },

    ...(values[PIXEL_CHOICE] && {
      tracking_specs: [
        {
          // Expected a list of items
          fb_pixel: [values[PIXEL_CHOICE]],
          action_type: ['offsite_conversion']
        }
      ],
      // facebook doesn't have an endpoint to tell us what the valid domains are.
      // Therefore, we need to have a workaround to check whether the domain is valid.
      // If domain is not valid, we don't include it into payload
      ...(((wasDomainValidated && isDomainValid) || validateOnly) && { conversion_domain: conversionDomain })
    }),
    // this field is used to test whether the ad will give an error, without creation the ad
    ...(validateOnly && { execution_options: ['validate_only'] })
  }
}
