import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  formsButtons: {
    '& > button': {
      marginBottom: 10
    }
  }
})

export default useStyles
