import { createStyles } from '../../../../../../styles/helpers'
import { googleBlue, lightGrey } from '../../../../../../styles/const/colors'

export default createStyles({
  adPreview: {
    position: 'relative',
    backgroundImage: backgroundImageUrl => `url("${backgroundImageUrl}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 10,
    fontFamily: 'Arial',
    width: 400,
    height: 350
  },
  icon: {
    background: '#fff',
    color: googleBlue,
    position: 'absolute',
    top: 2,
    right: 2,
    display: 'flex',
    padding: 3
  },
  filter: {
    position: 'absolute',
    background: '#fff',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    opacity: 0.3
  },
  blurArea: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '50%',
    backdropFilter: 'blur(20px)',
    mask: 'linear-gradient(transparent, black 30%)',
    padding: 15,
    paddingTop: 45
  },
  bottom: {
    display: 'flex',
    alignItems: 'center',

    '& img': {
      display: 'block',
      width: '20%',
      marginRight: 15
    }
  },
  description: {
    flexGrow: 1
  },
  button: {
    background: lightGrey,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: '50%',
    transform: 'rotate(-90deg)',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.35)',
    marginLeft: 'auto',

    '& path': {
      fill: 'white'
    }
  },
  headline: {
    fontSize: 22,
    fontWeight: 700,
    color: '#fff',
    margin: '10px 0'
  }
})
