import React from 'react'
import PropTypes from 'prop-types'

import MuteButton from '../MuteButton'

import useStyles from './styles'

function AdCardControlsPanel({ onMuteSoundToggle, isSoundMuted }) {
  const classes = useStyles()

  return (
    <div className={classes.controlsPanel}>
      <MuteButton onMuteSoundToggle={onMuteSoundToggle} isSoundMuted={isSoundMuted} />
    </div>
  )
}

AdCardControlsPanel.propTypes = {
  onToggleIsMuted: PropTypes.func,
  isSoundMuted: PropTypes.bool
}

export default AdCardControlsPanel
