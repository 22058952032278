import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { Link, NavLink } from 'react-router-dom'

import { replaceRouteParams, checkIfParamsRelatedLink } from '../../helpers/url'

import { useTranslatedChildren } from '../../hooks/useTranslatedChildren'

import {
  selectedAdAccountIdSelector,
  selectedPlatformSelector,
  selectedSelfAccountSelector
} from '../../modules/selectors/app'

import { ROUTE_PARAMS } from '../../constants/routes'

function InternalLink({
  to,
  navLink,
  children,
  className,
  // isMultiPlatformLink,
  ...props
}) {
  // are a lot of pages related on different parameters like selected platform,AdAccount and etc
  // this component dynamically replace links through app on parameters change
  const [linkTouched, setLinkTouched] = useState(false)

  const translatedChildren = useTranslatedChildren(children)

  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)
  const selectedPlatform = useSelector(selectedPlatformSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const isParamsRelatedLink = useMemo(() => checkIfParamsRelatedLink(to), [to])
  const populatedParams = {
    [ROUTE_PARAMS.selfAccount]: selectedSelfAccount,
    [ROUTE_PARAMS.platform]: selectedPlatform,
    [ROUTE_PARAMS.adAccount]: selectedAdAccountId
  }

  let newLinkTo = ''

  if (isParamsRelatedLink) {
    newLinkTo = replaceRouteParams(to, populatedParams)
  } else {
    newLinkTo = to
  }

  const touchStartEvent = () => {
    setLinkTouched(true)
  }

  useEffect(() => {
    // we need this to simulate changing css on click events for mobile devices (tap in our case)
    let timeOut

    if (linkTouched) {
      timeOut = setTimeout(() => {
        setLinkTouched(false)
      }, 100)
    }
    return () => {
      clearTimeout(timeOut)
    }
  }, [linkTouched])

  return navLink ? (
    <NavLink
      {...props}
      className={className}
      to={newLinkTo}
      onTouchStart={touchStartEvent}
      // onClick={handleRouteChange}
    >
      {translatedChildren}
    </NavLink>
  ) : (
    <Link
      onTouchStart={touchStartEvent}
      className={classnames(className, { linkTouched: linkTouched })}
      // onClick={handleRouteChange}
      {...props}
      to={newLinkTo}
    >
      {translatedChildren}
    </Link>
  )
}

export default InternalLink
