import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SocialAuthChecker from '../../../../features/components/SocialAuthChecker'
import FacebookInternalAdsSection from '../../InternalAdsSections/FacebookInternalAdsSection'
import GoogleInternalAdsSection from '../../InternalAdsSections/GoogleInternalAdsSection'
import TikTokInternalAdsSection from '../../InternalAdsSections/TikTokInternalAdsSection'

import {
  selectedControllerIdSelector,
  selectedAdAccountIdSelector,
  userSelfAccountTypeSelector
} from '../../../../modules/selectors/app'
import { getAds } from '../../../../modules/actions/ads'

import {
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  PLATFORM_LABELS,
  TIKTOK_PLATFORM
} from '../../../../constants/selectLists/platformList'
import { LIFETIME_DATE_PRESET } from '../../../../constants/selectLists/listItemFiltersList'
import { CONTROLLER_TYPE } from '../../../../constants/permissions'

const InternalAdsSection = ({ platform }) => {
  const dispatch = useDispatch()

  const userAccountType = useSelector(userSelfAccountTypeSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const selectedSelfAccountControllerId = useSelector(selectedControllerIdSelector)

  const loadInternalAds = useCallback(() => {
    dispatch(
      getAds({
        // on adApprovals page we don't pass account param, and pass platform prop from outside
        platform,
        controller_id: selectedSelfAccountControllerId,
        internal: true,
        detail: true,
        approval_status: 'pending_approval',
        date_preset: LIFETIME_DATE_PRESET
      })
    )
  }, [dispatch, platform, selectedSelfAccountControllerId])

  const authFailureText = useMemo(() => {
    if (userAccountType === CONTROLLER_TYPE) {
      return `Please authenticate with ${PLATFORM_LABELS[platform]} to retrieve the ads waiting for approval.`
    } else {
      return 'Please contact your account manager to access the ads waiting for approval.'
    }
  }, [platform, userAccountType])

  if (platform === FACEBOOK_PLATFORM) {
    return (
      <SocialAuthChecker
        platform={FACEBOOK_PLATFORM}
        dataFetch={loadInternalAds}
        adAccountId={selectedAdAccountId}
        authFailureText={authFailureText}
      >
        <FacebookInternalAdsSection />
      </SocialAuthChecker>
    )
  }

  if (platform === GOOGLE_PLATFORM) {
    return (
      <SocialAuthChecker
        platform={GOOGLE_PLATFORM}
        dataFetch={loadInternalAds}
        adAccountId={selectedAdAccountId}
        authFailureText={authFailureText}
      >
        <GoogleInternalAdsSection />
      </SocialAuthChecker>
    )
  }

  if (platform === TIKTOK_PLATFORM) {
    return (
      <SocialAuthChecker
        platform={TIKTOK_PLATFORM}
        dataFetch={loadInternalAds}
        adAccountId={selectedAdAccountId}
        authFailureText={authFailureText}
      >
        <TikTokInternalAdsSection />
      </SocialAuthChecker>
    )
  }

  return null
}

export default InternalAdsSection
