import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import TableDataLoader from '../../../../components/Table/TableDataLoader'
import ControllerMembersTableData from './ControllerMembersTableData'

import {
  controllerMembersErrorSelector,
  controllerMembersIsLoadingSelector,
  controllerMembersSelector,
  controllerMembersWasLoadedSelector
} from '../../../../modules/selectors/controller'

import { mobileOneLineColumnsSchema } from '../../../../constants/other'

import { phonesDownSize } from '../../../../styles/const/breakpoints'
import useCommonStyles from '../../../../styles/common/listPage'

export const tableColumnsSize = {
  status: 140,
  role: 124,
  actions: 40
}

const ControllerMembersTable = ({ onChangeMemberStatus, onChangeMemberRole, loadMore }) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const commonClasses = useCommonStyles()

  const controllerMembers = useSelector(controllerMembersSelector)

  const membersColumnsSchema = useMemo(
    () => [
      {
        header: 'Name'
      },
      {
        header: 'Status',
        style: { maxWidth: tableColumnsSize.status }
      },
      {
        header: 'Role',
        style: { maxWidth: tableColumnsSize.role }
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no users')}</div>
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <TableDataLoader
      loadMore={loadMore}
      itemsLength={controllerMembers.length}
      errorSelector={controllerMembersErrorSelector}
      wasLoadedSelector={controllerMembersWasLoadedSelector}
      isLoadingSelector={controllerMembersIsLoadingSelector}
      skeletonProps={{ cols: isMobile ? mobileOneLineColumnsSchema : membersColumnsSchema }}
      noDataContent={noDataContent}
    >
      <ControllerMembersTableData onChangeMemberStatus={onChangeMemberStatus} onChangeMemberRole={onChangeMemberRole} />
    </TableDataLoader>
  )
}

ControllerMembersTable.propTypes = {
  loadMore: PropTypes.func,
  onChangeMemberStatus: PropTypes.func.isRequired,
  onChangeMemberRole: PropTypes.func.isRequired
}

export default ControllerMembersTable
