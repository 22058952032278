import * as Yup from 'yup'
import { format } from 'date-fns'

import { productPublicationDatesValidation } from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/validation'
import { getProductStartPeriod } from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/periodHelpers'
import { CAMPAIGN_START_PERIOD_OPTION } from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/fields'
import { DATES_FORMAT_BE } from '../../../../../../../constants/dates'
import { REPRESENTATIVE } from '../../../../../../../constants/mediaOrders'
import { PRODUCT_GROUPED_PUBLICATIONS_DATES, PRODUCT_PERIODS_DATES, QUANTITY } from '../../../../fields'

// field to store the product that being added and setup
export const ADDED_PRODUCT = 'added_product'

export const initialValues = {
  [ADDED_PRODUCT]: null
}
export const getInitialProductValues = product => {
  // todo check how to replace
  const startPeriodDate = getProductStartPeriod(product.period, new Date())

  return {
    [CAMPAIGN_START_PERIOD_OPTION]: format(startPeriodDate, DATES_FORMAT_BE), // todo check if needed
    id: product.id,
    [PRODUCT_PERIODS_DATES]: [],
    [PRODUCT_GROUPED_PUBLICATIONS_DATES]: [],
    [QUANTITY]: 1,
    [REPRESENTATIVE]: product[REPRESENTATIVE],
    // next products required for productPublicationDatesValidation
    min_periods: product.min_periods,
    max_periods: product.max_periods,
    period: product.period
  }
}

export const validationSchema = Yup.object({
  [ADDED_PRODUCT]: Yup.object({
    ...productPublicationDatesValidation.fields,
    [QUANTITY]: Yup.number().required('Quantity is required').min(1, 'Quantity must be more than 0')
  })
})
