export type InitialId = null | string

// initial loading reducer
export interface InitialLoadingReducer {
  isLoading: boolean
  error: object | null
}

export const INITIAL_LOADING_REDUCER: InitialLoadingReducer = {
  isLoading: false,
  error: null
}

// initial get reducer
export interface InitialGetReducer extends InitialLoadingReducer {
  data: object
  wasLoaded: boolean
}

export const INITIAL_GET_REDUCER: InitialGetReducer = {
  ...INITIAL_LOADING_REDUCER,
  data: {},
  wasLoaded: false
}

// initial get results reducer
export interface InitialGetResultsReducer extends InitialLoadingReducer {
  results: any[]
  wasLoaded: boolean
}

export const INITIAL_GET_RESULTS_REDUCER: InitialGetResultsReducer = {
  ...INITIAL_LOADING_REDUCER,
  results: [],
  wasLoaded: false
}

// initial get results paging reducer
export interface InitialGetResultsPagingReducer extends InitialGetResultsReducer {
  paging: {
    cursors: {
      before: string
      after: string
    }
    previous: boolean
    next: boolean
  }
  sessionId: string
}

export const INITIAL_GET_RESULTS_PAGING_REDUCER: InitialGetResultsPagingReducer = {
  ...INITIAL_GET_RESULTS_REDUCER,
  paging: {
    cursors: {
      before: '',
      after: ''
    },
    previous: false,
    next: false
  },
  sessionId: ''
}

// initial create reducer
export interface InitialCreateReducer extends InitialLoadingReducer {
  id: InitialId
  wasCreated: boolean
  data: object
}

export const INITIAL_CREATE_REDUCER: InitialCreateReducer = {
  ...INITIAL_LOADING_REDUCER,
  id: null,
  wasCreated: false,
  data: {}
}

// initial update reducer
export interface InitialUpdateReducer extends InitialLoadingReducer {
  id: InitialId
  wasUpdated: boolean
  data: object
}

export const INITIAL_UPDATE_REDUCER: InitialUpdateReducer = {
  ...INITIAL_LOADING_REDUCER,
  id: null,
  wasUpdated: false,
  data: {}
}

// initial delete reducer
export interface InitialDeleteReducer extends InitialLoadingReducer {
  id: InitialId
  wasDeleted: boolean
}

export const INITIAL_DELETE_REDUCER: InitialDeleteReducer = {
  ...INITIAL_LOADING_REDUCER,
  id: null,
  wasDeleted: false
}

// initial duplicate reducer
export interface InitialDuplicateReducer extends InitialLoadingReducer {
  duplicateProcessWasStarted: boolean
  asyncSessionData: {
    id: InitialId
    name: string
  }
}

export const INITIAL_DUPLICATE_REDUCER: InitialDuplicateReducer = {
  ...INITIAL_LOADING_REDUCER,
  duplicateProcessWasStarted: false,
  asyncSessionData: {
    id: null,
    name: ''
  }
}
