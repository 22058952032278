import { createStyles } from '../../../../../../../../../styles/helpers'

const useStyles = createStyles({
  uploadedImageText: {
    display: 'flex',
    alignItems: 'center'
  }
})

export default useStyles
