import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Field from '../../../../../../../components/Form/Field'
import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import RadioBlock from '../../../../../../../components/Form/RadioBlock'

import { formatOptionsList } from '../../../../../../../features/formatters'

import { getTikTokIdentities, clearTikTokIdentities } from '../../../../../../../modules/actions/tikTokConstants'
import {
  tikTokIdentitiesSelector,
  tikTokIdentitiesIsLoadingSelector
} from '../../../../../../../modules/selectors/tikTokConstants'

import { IDENTITY_ID } from '../../fields'

function IdentityStep({ formik, handleStepChange, adAccountId }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const tikTokIdentities = useSelector(tikTokIdentitiesSelector)
  const tikTokIdentitiesIsLoading = useSelector(tikTokIdentitiesIsLoadingSelector)

  const formattedTikTokIdentities = useMemo(
    () =>
      formatOptionsList({
        list: tikTokIdentities,
        valueName: 'identity_id',
        labelName: 'display_name'
      }),
    [tikTokIdentities]
  )

  const handleContinue = useCallback(() => {
    handleStepChange()
  }, [handleStepChange])

  useEffect(() => {
    dispatch(getTikTokIdentities({ account: adAccountId }))

    return () => {
      dispatch(clearTikTokIdentities())
    }
  }, [dispatch, adAccountId])

  return (
    <Step
      formTitle="Create an ad"
      stepTitle="Please select a TikTok identity to run the ad from"
      handleContinue={handleContinue}
    >
      <RadioBlock
        setFieldValue={() => {}}
        id="radio_tiktok_identity"
        name={IDENTITY_ID}
        value={IDENTITY_ID}
        selectedValue={IDENTITY_ID}
        label={t('Select TikTok identity')}
      >
        <Field
          formik={formik}
          name={IDENTITY_ID}
          options={formattedTikTokIdentities}
          isLoading={tikTokIdentitiesIsLoading}
          placeholder="TikTok Identity"
          autoComplete="off"
        />
      </RadioBlock>
    </Step>
  )
}

IdentityStep.propTypes = {
  adAccountId: PropTypes.string.isRequired
}

export default IdentityStep
