import React, { useCallback, useEffect, useMemo } from 'react'
import FacebookAdSetBreakdown from '../LineItemsBreakdown/FacebookAdSetBreakdown'
import { useDispatch, useSelector } from 'react-redux'

import PageSectionHeader from '../../../../../features/components/PageSectionHeader'
import TikTokAdGroupBreakdown from '../LineItemsBreakdown/TiktokAdGroupBreakdown'
import GoogleAdGroupBreakdown from '../LineItemsBreakdown/GoogleAdGroupBreakdown'
import DV360AdGroupBreakdown from '../LineItemsBreakdown/DV360LineItemBreakdown'
import FacebookHighestPerformingAds from '../HighestPerformingAds/FacebookHighestPerformingAds'
import GooglePerformingAds from '../HighestPerformingAds/GooglePerformingAds'
import TikTokPerformingAds from '../HighestPerformingAds/TikTokPerformingAds'
import DV360PerformingAds from '../HighestPerformingAds/DV360PerformingAds'

import { useGetProvidersFromCampaigns } from '../hooks'
import useGetLineItemActionsDropdownOptions from '../useGetLineItemActionsDropdownOptions'

import { groupCampaignsByProvider } from '../../../helpers'
import { getLineItemSummaryRedirectLinkHandler } from '../../../../../constants/lineItems'

import { updateLineItem } from '../../../../../modules/actions/lineItems'
import {
  clearCombinedAds,
  clearCombinedLineItems,
  getCombinedAds,
  getCombinedLineItems
} from '../../../../../modules/actions/combinedData'
import { mediaOrderSelector } from '../../../../../modules/selectors/mediaOrders'
import {
  combinedCampaignsSelector,
  combinedLineItemsIsLoadingSelector,
  combinedAdsIsLoadingSelector,
  combinedAdsWasLoadedSelector,
  combinedAdsErrorSelector,
  combinedLineItemsSelector,
  combinedAdsSelector
} from '../../../../../modules/selectors/combinedData'
import { selectedSelfAccountSelector } from '../../../../../modules/selectors/app'

import {
  DV_360_PLATFORM,
  DV_360_PROVIDER_PLATFORM,
  FACEBOOK_PLATFORM,
  FACEBOOK_PROVIDER_PLATFORM,
  GOOGLE_PLATFORM,
  GOOGLE_PROVIDER_PLATFORM,
  PROVIDER_TO_PLATFORMS,
  TIKTOK_PLATFORM,
  TIKTOK_PROVIDER_PLATFORM
} from '../../../../../constants/selectLists/platformList'
import { listItemChangeStatus } from '../../../../../constants/other'

const lineItemInsights = [
  'impressions',
  'cpa',
  'cpm',
  'cpc',
  'reach',
  'frequency',
  'clicks',
  'ctr',
  'purchase',
  'roas',
  'spend',
  'engagement',
  'active_view_impressions',
  'video_view_rate',
  'video_views'
]

const adInsights = [
  'impressions',
  'cpm',
  'cpc',
  'actions_like',
  'actions_post',
  'actions_post_reaction',
  'reach',
  'frequency',
  'clicks',
  'ctr',
  'purchase',
  'roas',
  'spend',
  'engagement',
  'active_view_impressions',
  'video_view_rate',
  'video_views'
]

const ReportsSections = () => {
  const dispatch = useDispatch()

  const combinedLineItems = useSelector(combinedLineItemsSelector)
  const combinedLineItemsIsLoading = useSelector(combinedLineItemsIsLoadingSelector)
  const selfAccountId = useSelector(selectedSelfAccountSelector)
  const mediaOrder = useSelector(mediaOrderSelector)
  const { campaigns_date_range: combinedCampaignsDateRange } = useSelector(combinedCampaignsSelector)

  const { campaigns: mediaOrderCampaigns } = mediaOrder

  const providers = useGetProvidersFromCampaigns(mediaOrderCampaigns)

  const campaignsGroupedByProvider = useMemo(() => groupCampaignsByProvider(mediaOrderCampaigns), [mediaOrderCampaigns])

  const facebookCombinedLineItems = useMemo(
    () =>
      combinedLineItems
        .filter(({ platform }) => platform === FACEBOOK_PLATFORM)
        .map(campaign => campaign['line_items'])
        .flat(),
    [combinedLineItems]
  )

  const googleCombinedLineItems = useMemo(
    () =>
      combinedLineItems
        .filter(({ platform }) => platform === GOOGLE_PLATFORM)
        .map(campaign => campaign['line_items'])
        .flat(),
    [combinedLineItems]
  )

  const tiktokCombinedLineItems = useMemo(
    () =>
      combinedLineItems
        .filter(({ platform }) => platform === TIKTOK_PLATFORM)
        .map(campaign => campaign['line_items'])
        .flat(),
    [combinedLineItems]
  )

  const dv360CombinedLineItems = useMemo(
    () =>
      combinedLineItems
        .filter(({ platform }) => platform === DV_360_PLATFORM)
        .map(campaign => campaign['line_items'])
        .flat(),
    [combinedLineItems]
  )

  const showFacebookSections = useMemo(
    () =>
      providers.includes(FACEBOOK_PROVIDER_PLATFORM) &&
      (!!facebookCombinedLineItems.length || combinedLineItemsIsLoading),
    [providers, facebookCombinedLineItems, combinedLineItemsIsLoading]
  )

  const showGoogleSections = useMemo(
    () =>
      providers.includes(GOOGLE_PROVIDER_PLATFORM) && (!!googleCombinedLineItems.length || combinedLineItemsIsLoading),
    [providers, googleCombinedLineItems, combinedLineItemsIsLoading]
  )

  const showTiktokSections = useMemo(
    () =>
      providers.includes(TIKTOK_PROVIDER_PLATFORM) && (!!tiktokCombinedLineItems.length || combinedLineItemsIsLoading),
    [providers, tiktokCombinedLineItems, combinedLineItemsIsLoading]
  )

  const showDv360Sections = useMemo(
    () =>
      providers.includes(DV_360_PROVIDER_PLATFORM) && (!!dv360CombinedLineItems.length || combinedLineItemsIsLoading),
    [providers, dv360CombinedLineItems, combinedLineItemsIsLoading]
  )

  const getLineItemLinkClickHandler = useCallback(
    ({ item, platform, adAccountId }) =>
      getLineItemSummaryRedirectLinkHandler({
        lineItem: item,
        platform,
        adAccountId: adAccountId,
        selfAccountId: selfAccountId
      }),
    [selfAccountId]
  )

  const getLineItemActionsDropdownOptions = useGetLineItemActionsDropdownOptions({
    getLineItemLinkClickHandler
  })

  const updateLineItemStatusHandler = useCallback(
    lineItem => {
      dispatch(
        updateLineItem(
          {
            account: lineItem['ad_account_id'],
            status: listItemChangeStatus[lineItem.status]?.changeTo
          },
          lineItem.id,
          {
            platform: PROVIDER_TO_PLATFORMS[lineItem.provider]
          }
        )
      )
    },
    [dispatch]
  )

  const reusableProps = useMemo(
    () => ({
      updateLineItemStatusHandler,
      getLineItemLinkClickHandler,
      getLineItemActionsDropdownOptions
    }),
    [getLineItemActionsDropdownOptions, getLineItemLinkClickHandler, updateLineItemStatusHandler]
  )

  useEffect(() => {
    dispatch(
      getCombinedLineItems({
        insights: lineItemInsights,
        ...combinedCampaignsDateRange,
        providers: campaignsGroupedByProvider
      })
    )
  }, [dispatch, campaignsGroupedByProvider, combinedCampaignsDateRange])

  useEffect(() => {
    dispatch(
      getCombinedAds({
        insights: adInsights,
        ...combinedCampaignsDateRange,
        providers: campaignsGroupedByProvider
      })
    )
  }, [dispatch, campaignsGroupedByProvider, combinedCampaignsDateRange])

  useEffect(() => {
    return () => {
      dispatch(clearCombinedAds())
      dispatch(clearCombinedLineItems())
    }
  }, [dispatch])

  const highestPerformingAdsProps = useMemo(() => {
    return {
      dataSelector: combinedAdsSelector,
      loadingSelector: combinedAdsIsLoadingSelector,
      errorSelector: combinedAdsErrorSelector,
      wasLoadedSelector: combinedAdsWasLoadedSelector
    }
  }, [])

  return (
    <>
      {showFacebookSections && (
        <>
          <PageSectionHeader id="facebook-ads-header" title="Facebook - Ad Sets" />
          <FacebookAdSetBreakdown lineItems={facebookCombinedLineItems} {...reusableProps} />
          <FacebookHighestPerformingAds />
        </>
      )}
      {showGoogleSections && (
        <>
          <PageSectionHeader id="google-ads-header" title="Google Ads - Ad Groups" />
          <GoogleAdGroupBreakdown lineItems={googleCombinedLineItems} {...reusableProps} />
          <GooglePerformingAds {...highestPerformingAdsProps} />
        </>
      )}
      {showTiktokSections && (
        <>
          <PageSectionHeader id="tiktok-ads-header" title="TikTok - Ad Groups" />
          <TikTokAdGroupBreakdown lineItems={tiktokCombinedLineItems} {...reusableProps} />
          <TikTokPerformingAds {...highestPerformingAdsProps} />
        </>
      )}
      {showDv360Sections && (
        <>
          <PageSectionHeader id="dv360-ads-header" title="Display or Video - Line Items" />
          <DV360AdGroupBreakdown lineItems={dv360CombinedLineItems} {...reusableProps} />
          <DV360PerformingAds {...highestPerformingAdsProps} />
        </>
      )}
    </>
  )
}

export default ReportsSections
