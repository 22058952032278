import { all, call, put, takeEvery } from 'redux-saga/effects'

import {
  createDoohlyApiKeySuccess,
  createDoohlyApiKeyFailure,
  updateDoohlyApiKeySuccess,
  updateDoohlyApiKeyFailure,
  getDoohlyApiKeySuccess,
  getDoohlyApiKeyFailure,
  CREATE_DOOHLY_API_KEY,
  UPDATE_DOOHLY_API_KEY,
  GET_DOOHLY_API_KEY,
  GET_DOOHLY_CAMPAIGNS,
  getDoohlyCampaignsSuccess,
  getDoohlyCampaignsFailure,
  CREATE_DOOHLY_CAMPAIGN,
  createDoohlyCampaignFailure,
  createDoohlyCampaignSuccess,
  CREATE_DOOHLY_LINE_ITEM,
  createDoohlyLineItemSuccess,
  createDoohlyLineItemFailure
} from '../actions/doohly'
import {
  updateDoohlyApiKeyService,
  createDoohlyApiKeyService,
  getDoohlyApiKeyService,
  getDoohlyCampaignsService,
  createDoohlyCampaignService,
  createDoohlyLineItemService
} from '../services/doohly'

function* doohlyWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(CREATE_DOOHLY_LINE_ITEM, createDoohlyLineItemWorker),
    takeEvery(CREATE_DOOHLY_CAMPAIGN, createDoohlyCampaignWorker),
    takeEvery(GET_DOOHLY_CAMPAIGNS, getDoohlyCampaignsWorker),
    takeEvery(GET_DOOHLY_API_KEY, getDoohlyApiKeyWorker),
    takeEvery(UPDATE_DOOHLY_API_KEY, updateDoohlyApiKeyWorker),
    takeEvery(CREATE_DOOHLY_API_KEY, createDoohlyApiKeyWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* createDoohlyLineItemWorker({ data }) {
  try {
    const response = yield call(createDoohlyLineItemService, data)
    yield put(createDoohlyLineItemSuccess(response))
  } catch (error) {
    yield put(createDoohlyLineItemFailure(error))
  }
}

function* createDoohlyCampaignWorker({ data }) {
  try {
    const response = yield call(createDoohlyCampaignService, data)
    yield put(createDoohlyCampaignSuccess(response))
  } catch (error) {
    yield put(createDoohlyCampaignFailure(error))
  }
}

function* getDoohlyApiKeyWorker({ params }) {
  try {
    const response = yield call(getDoohlyApiKeyService, params)
    yield put(getDoohlyApiKeySuccess(response))
  } catch (error) {
    yield put(getDoohlyApiKeyFailure(error))
  }
}

function* getDoohlyCampaignsWorker({ params }) {
  try {
    const response = yield call(getDoohlyCampaignsService, params)
    yield put(getDoohlyCampaignsSuccess(response))
  } catch (error) {
    yield put(getDoohlyCampaignsFailure(error))
  }
}

function* updateDoohlyApiKeyWorker({ id, data }) {
  try {
    const response = yield call(updateDoohlyApiKeyService, id, data)
    yield put(updateDoohlyApiKeySuccess(response))
  } catch (error) {
    yield put(updateDoohlyApiKeyFailure(error))
  }
}

function* createDoohlyApiKeyWorker({ data }) {
  try {
    const response = yield call(createDoohlyApiKeyService, data)
    yield put(createDoohlyApiKeySuccess(response))
  } catch (error) {
    yield put(createDoohlyApiKeyFailure(error))
  }
}

export default doohlyWatcher
