import { createStyles } from '../../../../../styles/helpers'
import { textGrey } from '../../../../../styles/const/colors'

const useStyles = createStyles({
  contentContainer: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    color: textGrey
  },
  tokenContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  qrCodeWrapper: {
    height: 165,
    width: 165
  },
  tokenCopyRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 30,
    gap: 4,
    width: '100%'
  },
  tokenText: {
    fontSize: 14,
    wordBreak: 'break-all',
    width: '100%',
    flex: 1,
    '& > span': {
      width: '100%',
      display: 'block'
    }
  },
  tokenCopyIcon: {
    width: 24,
    cursor: 'pointer'
  },
  passcodeText: {
    textAlign: 'left',
    color: textGrey,
    marginBottom: 14,
    fontWeight: '600'
  },
  modalMessage: {
    color: textGrey
  }
})

export default useStyles
