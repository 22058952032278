import { IDENTITY_ICON_ID, IDENTITY_ICON_URL, DISPLAY_NAME } from './fields'

export const transformValuesToBE = (values, adAccountId) => {
  return {
    account: adAccountId,
    [DISPLAY_NAME]: values[DISPLAY_NAME],
    [IDENTITY_ICON_ID]: values[IDENTITY_ICON_ID],
    [IDENTITY_ICON_URL]: values[IDENTITY_ICON_URL]
  }
}
