import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Chip from '../../../../components/Chip'

import { CHIP_COLORS } from '../../../../constants/other'

const StatusField = ({ isActive, className }) => {
  const { t } = useTranslation()

  if (isActive) {
    return <Chip text={t('Active')} className={className} color={CHIP_COLORS.green} />
  } else if (isActive === false) {
    return <Chip text={t('Deactivated')} className={className} color={CHIP_COLORS.grey} />
  }

  return <Chip text={t('Invited')} className={className} color={CHIP_COLORS.yellow} />
}

StatusField.propTypes = {
  isActive: PropTypes.bool,
  className: PropTypes.string
}

export default StatusField
