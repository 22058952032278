import { createStyles } from '../../../../../../styles/helpers'

export const quotationBuilderColPadding = 8
const useStyles = createStyles({
  quotationBuilderCol: {
    fontSize: '12px',
    padding: `0 ${quotationBuilderColPadding}px`
  },
  name: {
    fontWeight: 600
  },
  footer: {
    backgroundColor: 'white'
  },
  actionsHeader: {
    // huck to pass the `header` to column for correct `key`, setup but don't show it visually
    opacity: 0
  }
})

export default useStyles
