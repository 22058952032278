import { call, put, all, takeEvery, select } from 'redux-saga/effects'

import {
  GET_LINE_ITEM,
  getLineItemSuccess,
  getLineItemFailure,
  GET_LINE_ITEMS,
  getLineItemsSuccess,
  getLineItemsFailure,
  CREATE_LINE_ITEM,
  createLineItemSuccess,
  createLineItemFailure,
  DELETE_LINE_ITEM,
  deleteLineItemSuccess,
  deleteLineItemFailure,
  UPDATE_LINE_ITEM,
  updateLineItemSuccess,
  updateLineItemFailure,
  GET_LOCATIONS,
  getLocationsSuccess,
  getLocationsFailure,
  GET_GOALS,
  getGoalsSuccess,
  getGoalsFailure,
  GET_PIXELS_STATS,
  getPixelsStatsSuccess,
  getPixelsStatsFailure,
  DUPLICATE_LINE_ITEM,
  duplicateLineItemSuccess,
  duplicateLineItemFailure,
  GET_LINE_ITEM_CRITERIONS,
  getLineItemCriterionsSuccess,
  getLineItemCriterionsFailure,
  CREATE_LINE_ITEM_CRITERIONS,
  createLineItemCriterionsSuccess,
  createLineItemCriterionsFailure,
  deleteLineItemCriterions
} from '../actions/lineItems'
import { updateCurrentUserProfile } from '../actions/app'
import { currentUserIdSelector, selectedPlatformSelector } from '../selectors/app'

import {
  getLineItemService,
  getLineItemsService,
  createLineItemService,
  deleteLineItemService,
  updateLineItemService,
  getLocationsService,
  getGoalsService,
  getPixelsStatsService,
  duplicateLineItemService,
  getLineItemCriterionsService,
  createLineItemCriterionsService
} from '../services/lineItems'
import { combinedCampaignsSelector } from '../selectors/combinedData'
import { updateCombinedCampaign } from '../actions/combinedData'

import { FACEBOOK_PLATFORM, TIKTOK_PLATFORM } from '../../constants/selectLists/platformList'

function* lineItemsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_LINE_ITEM, getLineItemWorker),
    takeEvery(GET_LINE_ITEMS, getLineItemsWorker),
    takeEvery(CREATE_LINE_ITEM, createLineItemWorker),
    takeEvery(DELETE_LINE_ITEM, deleteLineItemWorker),
    takeEvery(UPDATE_LINE_ITEM, updateLineItemWorker),
    takeEvery(GET_LINE_ITEM_CRITERIONS, getLineItemCriterionsWorker),
    takeEvery(CREATE_LINE_ITEM_CRITERIONS, createLineItemCriterionsWorker),
    takeEvery(GET_LOCATIONS, getLocationsWorker),
    takeEvery(GET_GOALS, getGoalsWorker),
    takeEvery(GET_PIXELS_STATS, getPixelsStatsWorker),
    takeEvery(DUPLICATE_LINE_ITEM, duplicateLineItemWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getLineItemWorker({ params }) {
  try {
    const platform = yield select(selectedPlatformSelector)
    const response = yield call(getLineItemService, params, platform)

    yield put(getLineItemSuccess(response))
  } catch (e) {
    yield put(getLineItemFailure(e))
  }
}

function* getLineItemsWorker({ params, platform }) {
  try {
    const selectedPlatform = yield select(selectedPlatformSelector)
    const response = yield call(getLineItemsService, params, platform || selectedPlatform)

    yield put(getLineItemsSuccess(response))
  } catch (e) {
    yield put(getLineItemsFailure(e))
  }
}

function* createLineItemWorker({ lineItemData, updatedSettings, platform }) {
  try {
    const selectedPlatform = yield select(selectedPlatformSelector)

    const response = yield call(createLineItemService, lineItemData, platform || selectedPlatform)

    yield put(createLineItemSuccess(response))

    if (updatedSettings) {
      const userId = yield select(currentUserIdSelector)
      yield put(
        updateCurrentUserProfile({
          userId,
          userData: updatedSettings,
          partialUpdate: true
        })
      )
    }

    const combinedCampaignsData = yield select(combinedCampaignsSelector)
    const { campaigns: combinedCampaigns = [] } = combinedCampaignsData
    const { campaign_id: campaignId, budget_lifetime: budgetLifetime } = lineItemData

    // When we create facebook/tiktok line item with budget,
    // we need to update parent campaign's budget_lifetime_line_item field
    if (
      (platform === FACEBOOK_PLATFORM || platform === TIKTOK_PLATFORM) &&
      combinedCampaigns.length &&
      budgetLifetime
    ) {
      const parentCampaign = combinedCampaigns.find(item => item.id === campaignId)

      const prevBudgetLifetimeItem = parentCampaign.budget_lifetime_line_item || 0
      const updatedBudgetLifetimeItem = Number(prevBudgetLifetimeItem) + Number(budgetLifetime)

      const updatedParentCampaign = {
        ...parentCampaign,
        budget_lifetime_line_item: updatedBudgetLifetimeItem
      }

      yield put(updateCombinedCampaign(updatedParentCampaign))
    }
  } catch (e) {
    yield put(createLineItemFailure(e))
  }
}

function* deleteLineItemWorker({ params }) {
  try {
    const platform = yield select(selectedPlatformSelector)
    yield call(deleteLineItemService, params, platform)

    yield put(deleteLineItemSuccess(params.id))
  } catch (e) {
    yield put(deleteLineItemFailure(e))
  }
}

function* updateLineItemWorker({ lineItemData, id, updateOptions }) {
  try {
    const { platform, requestMethod } = updateOptions

    const selectedPlatform = yield select(selectedPlatformSelector)
    const response = yield call(updateLineItemService, lineItemData, id, platform || selectedPlatform, requestMethod)

    // frequency field is returned in another format, so we just ignore it (+ it's not mutable)
    delete response.frequency

    yield put(updateLineItemSuccess(response))
  } catch (e) {
    yield put(updateLineItemFailure(e))
  }
}

function* duplicateLineItemWorker({ duplicateData, id, platform }) {
  try {
    const response = yield call(duplicateLineItemService, duplicateData, id, platform)
    yield put(duplicateLineItemSuccess(response))
  } catch (e) {
    yield put(duplicateLineItemFailure(e))
  }
}

function* getLineItemCriterionsWorker({ params }) {
  try {
    const response = yield call(getLineItemCriterionsService, params)
    yield put(getLineItemCriterionsSuccess(response))
  } catch (error) {
    yield put(getLineItemCriterionsFailure(error))
  }
}

function* createLineItemCriterionsWorker({ data, criterionsToDelete }) {
  try {
    const response = yield call(createLineItemCriterionsService, data)
    yield put(createLineItemCriterionsSuccess(response))

    // filter inner store of criterions when using remove_operations in create request
    if (criterionsToDelete && criterionsToDelete.length) {
      yield put(deleteLineItemCriterions(criterionsToDelete))
    }
  } catch (error) {
    yield put(createLineItemCriterionsFailure(error))
  }
}

function* getLocationsWorker() {
  try {
    const response = yield call(getLocationsService)
    yield put(getLocationsSuccess(response))
  } catch (e) {
    yield put(getLocationsFailure(e))
  }
}

function* getGoalsWorker({ objective }) {
  try {
    const response = yield call(getGoalsService, objective)
    yield put(getGoalsSuccess(response))
  } catch (e) {
    yield put(getGoalsFailure(e))
  }
}

function* getPixelsStatsWorker({ id }) {
  try {
    const response = yield call(getPixelsStatsService, id)
    yield put(getPixelsStatsSuccess(response))
  } catch (e) {
    yield put(getPixelsStatsFailure(e))
  }
}

export default lineItemsWatcher
