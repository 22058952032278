import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { BRAND_CATEGORY_BREAKDOWN } from '../../../../../../../../features/components/Filters/BreakdownFilter/constants'

export const BookedRevenueContext = React.createContext()

export function BookedRevenueDataProvider({ children, onFiltersChange, initialBreakdownFilter }) {
  const [breakdownFilter, setBreakdownFilter] = useState(initialBreakdownFilter || BRAND_CATEGORY_BREAKDOWN)

  const filterRequestParams = useMemo(
    () => ({
      ...(breakdownFilter && { breakdown: breakdownFilter })
    }),
    [breakdownFilter]
  )

  const bookedRevenueFilterProps = useMemo(
    () => ({
      filterRequestParams,
      breakdownFilter,
      setBreakdownFilter
    }),
    [filterRequestParams, breakdownFilter, setBreakdownFilter]
  )
  // make a type check for the props
  PropTypes.checkPropTypes(propTypes, bookedRevenueFilterProps, 'prop', 'useRequestHandler')

  useEffect(() => {
    onFiltersChange(filterRequestParams)
  }, [filterRequestParams, onFiltersChange])

  return <BookedRevenueContext.Provider value={bookedRevenueFilterProps}>{children}</BookedRevenueContext.Provider>
}

export const filterRequestParamsTypes = PropTypes.shape({
  breakdown: PropTypes.string
}).isRequired

const propTypes = {
  filterRequestParams: filterRequestParamsTypes,
  breakdownFilter: PropTypes.string,
  setBreakdownFilter: PropTypes.func.isRequired
}
