import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles({
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 10
  },
  statusChip: {},
  actionsDropdownMenu: {
    marginRight: -2,
    // marginLeft 'auto' helps to always position dropdown menu to the right
    marginLeft: 'auto'
  },
  actionsListWrapper: {
    minWidth: 150
  }
})

export default useStyles
