import update from 'immutability-helper'
import { INITIAL_CREATE_REDUCER } from '../../constants/reducer'
import {
  CREATE_DV360_BULK_TARGETING,
  CREATE_DV360_BULK_TARGETING_SUCCESS,
  CREATE_DV360_BULK_TARGETING_FAILURE,
  CLEAR_CREATE_DV360_BULK_TARGETING
} from '../actions/dv360Constants'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER

  createDv360BulkTargeting: INITIAL_CREATE_REDUCER
}

export default function dv360Constants(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    // Create bulk targeting
    case CREATE_DV360_BULK_TARGETING:
      return update(state, {
        createDv360BulkTargeting: { $merge: { isLoading: true } }
      })
    case CREATE_DV360_BULK_TARGETING_SUCCESS:
      return update(state, {
        createDv360BulkTargeting: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        }
      })
    case CREATE_DV360_BULK_TARGETING_FAILURE:
      return update(state, {
        createDv360BulkTargeting: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_DV360_BULK_TARGETING:
      return update(state, {
        createDv360BulkTargeting: { $set: initialState.createDv360BulkTargeting }
      })

    default:
      return state
  }
}
