import { createStyles } from '../../../../styles/helpers'
import { navy } from '../../../../styles/const/colors'
import { phonesDown } from '../../../../styles/const/breakpoints'

const useStyles = createStyles({
  nameField: {
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    cursor: 'pointer'
  },
  Icon: {
    color: navy
  },
  texts: {
    display: 'flex',
    flexDirection: 'column'
  },
  [`@media screen and (${phonesDown})`]: {
    texts: {
      marginLeft: 0
    }
  }
})

export default useStyles
