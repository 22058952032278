import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import LineItemFormContent from './LineItemFormContent'
import SkeletonForm from '../../../../../../../../components/Form/Skeleton'
import WarningMessage from '../../../../../../../../features/components/Form/WarningMessage'

import {
  clearChoicesCountries,
  clearChoicesPixels,
  getChoicesCountries,
  getChoicesPixels
} from '../../../../../../../../modules/actions/choices'
import {
  choicesCountriesErrorSelector,
  choicesCountriesWasLoadedSelector,
  choicesPixelsErrorSelector,
  choicesPixelsSelector,
  choicesPixelsWasLoadedSelector
} from '../../../../../../../../modules/selectors/choices'

const LineItemFormContentWrapper = ({ adAccountId, ...props }) => {
  const dispatch = useDispatch()

  const choicesCountriesWasLoaded = useSelector(choicesCountriesWasLoadedSelector)
  const choicesCountriesError = useSelector(choicesCountriesErrorSelector)
  const pixels = useSelector(choicesPixelsSelector)
  const pixelsWasLoaded = useSelector(choicesPixelsWasLoadedSelector)
  const pixelsError = useSelector(choicesPixelsErrorSelector)

  const countriesDidLoading = choicesCountriesWasLoaded || choicesCountriesError
  const pixelsDidLoading = pixelsWasLoaded || pixelsError

  const showLoading = !countriesDidLoading || !pixelsDidLoading

  useEffect(() => {
    if (!choicesCountriesWasLoaded) {
      dispatch(getChoicesCountries())
    }
  }, [dispatch, choicesCountriesWasLoaded])

  useEffect(() => {
    dispatch(getChoicesPixels({ account: adAccountId }))
  }, [dispatch, adAccountId])

  // if choices countries were loaded with error, clear them, so user can re-start the flow and re-load them
  useEffect(
    () => () => {
      if (choicesCountriesError) {
        dispatch(clearChoicesCountries())
      }
    },
    [dispatch, choicesCountriesError]
  )
  useEffect(
    () => () => {
      dispatch(clearChoicesPixels())
    },
    [dispatch]
  )

  if (showLoading) {
    return <SkeletonForm stepsLength={3} />
  }

  if (choicesCountriesError) {
    return (
      <WarningMessage
        title="Something went wrong"
        subTitle="Launch an off-network campaign"
        description="Sorry, something went wrong with identifying user country. Please try again later or contact your account manager."
      />
    )
  }

  if (!pixels.length) {
    return (
      <WarningMessage
        title="No audience source has been found"
        subTitle="Launch an off-network campaign"
        description="No audience source has been found, please contact your account manager."
      />
    )
  }

  return <LineItemFormContent adAccountId={adAccountId} {...props} />
}

LineItemFormContentWrapper.propTypes = {
  adAccountId: PropTypes.string.isRequired,
  onSuccessSubmit: PropTypes.func.isRequired
}

export default LineItemFormContentWrapper
