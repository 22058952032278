import { gatherMultiLevelSelectInitialValues } from '../../../../../../components/SelectMultiLevel/formatters'
import { formatOptionsList } from '../../../../../../features/formatters'

import { INTERESTS } from '../../../../../ReusableFormFields/InterestsFields/fields'
import { ACCOUNT_CATEGORIES, VIDEO_CATEGORIES } from '../../../LineItemTikTokCreate/LineItemTikTokCreateForm/fields'
import { CREATOR_RELATED, VIDEO_RELATED, HASHTAG_RELATED } from '../../../../../../constants/lineItems'
import { HASHTAGS } from '../../../../ReusableFormFields/TikTokHashtagsFields/fields'

export const formatValuesToFE = ({ lineItem = {}, interestOptions, videoCategories, accountCategories }) => {
  const { interest_category: interestIds, action: actionItems } = lineItem

  const videoCategoriesIds = actionItems.find(({ action_scene: type }) => type === VIDEO_RELATED)?.action_categories
  const accountCategoriesIds = actionItems.find(({ action_scene: type }) => type === CREATOR_RELATED)?.action_categories
  const hashtags = actionItems.find(({ action_scene: type }) => type === HASHTAG_RELATED)?.action_categories

  return {
    [INTERESTS]: (interestIds && gatherMultiLevelSelectInitialValues(interestIds, interestOptions)) || [],
    [VIDEO_CATEGORIES]:
      (videoCategoriesIds && gatherMultiLevelSelectInitialValues(videoCategoriesIds, videoCategories)) || [],
    [ACCOUNT_CATEGORIES]:
      (accountCategoriesIds && gatherMultiLevelSelectInitialValues(accountCategoriesIds, accountCategories)) || [],
    [HASHTAGS]: (hashtags?.length && formatOptionsList({ list: hashtags, valueName: 'id', labelName: 'name' })) || []
  }
}
