import * as Yup from 'yup'

export const DISAPPROVED_REASON = 'disapproved_reason'
export const BUYER_DISAPPROVED_REASON = 'buyer_disapproved_reason'

export const initialValues = {
  [DISAPPROVED_REASON]: ''
}

export const validationSchema = Yup.object({
  [DISAPPROVED_REASON]: Yup.string().required('This field should not be empty')
})
