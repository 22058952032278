import { all, call, put } from 'redux-saga/effects'
import { takeEvery } from 'typed-redux-saga'

import {
  CREATE_MEDIA_PRODUCT_VARIABLE,
  createMediaProductVariableFailure,
  createMediaProductVariableSuccess,
  DELETE_MEDIA_PRODUCT_VARIABLE,
  deleteMediaProductVariableFailure,
  deleteMediaProductVariableSuccess,
  GET_MEDIA_PRODUCT_VARIABLE_OPTIONS,
  GET_MEDIA_PRODUCT_VARIABLES,
  GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST,
  getMediaProductVariableOptionsFailure,
  getMediaProductVariableOptionsSuccess,
  getMediaProductVariablesBulkListFailure,
  getMediaProductVariablesBulkListSuccess,
  getMediaProductVariablesFailure,
  getMediaProductVariablesSuccess,
  UPDATE_MEDIA_PRODUCT_VARIABLE,
  updateMediaProductVariableFailure,
  updateMediaProductVariableSuccess
} from '../actions/mediaOrdersProductVariables'
import {
  createProductVariableService,
  deleteMediaProductVariableService,
  getMediaProductVariableOptionsService,
  getMediaProductVariablesBulkListService,
  getMediaProductVariablesService,
  updateProductVariableService
} from '../services/mediaOrdersProductVariables'

function* mediaOrdersProductVariablesWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_MEDIA_PRODUCT_VARIABLES, getMediaProductVariablesWorker),
    takeEvery(CREATE_MEDIA_PRODUCT_VARIABLE, createProductVariableWorker),
    takeEvery(UPDATE_MEDIA_PRODUCT_VARIABLE, updateProductVariableWorker),
    takeEvery(DELETE_MEDIA_PRODUCT_VARIABLE, deleteMediaProductVariableWorker),
    takeEvery(GET_MEDIA_PRODUCT_VARIABLE_OPTIONS, getMediaProductVariableOptionsWorker),
    takeEvery(GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST, getMediaProductVariablesBulkListWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getMediaProductVariablesWorker({ params }) {
  try {
    const response = yield call(getMediaProductVariablesService, params)
    yield put(getMediaProductVariablesSuccess(response))
  } catch (error) {
    yield put(getMediaProductVariablesFailure(error))
  }
}

function* createProductVariableWorker({ data }) {
  try {
    const response = yield call(createProductVariableService, data)
    yield put(createMediaProductVariableSuccess(response))
  } catch (error) {
    yield put(createMediaProductVariableFailure(error))
  }
}

function* updateProductVariableWorker({ data, id }) {
  try {
    const response = yield call(updateProductVariableService, data, id)
    yield put(updateMediaProductVariableSuccess(response))
  } catch (error) {
    yield put(updateMediaProductVariableFailure(error))
  }
}

function* deleteMediaProductVariableWorker({ id }) {
  try {
    yield call(deleteMediaProductVariableService, id)
    yield put(deleteMediaProductVariableSuccess({ id }))
  } catch (error) {
    yield put(deleteMediaProductVariableFailure(error))
  }
}

function* getMediaProductVariableOptionsWorker({ params }) {
  const mediaProductVariableId = params.media_product_variable
  try {
    const response = yield call(getMediaProductVariableOptionsService, params)
    yield put(getMediaProductVariableOptionsSuccess(mediaProductVariableId, response))
  } catch (error) {
    yield put(getMediaProductVariableOptionsFailure(mediaProductVariableId, error))
  }
}

function* getMediaProductVariablesBulkListWorker({ params }) {
  try {
    const response = yield call(getMediaProductVariablesBulkListService, params)
    yield put(getMediaProductVariablesBulkListSuccess(response))
  } catch (error) {
    yield put(getMediaProductVariablesBulkListFailure(error))
  }
}

export default mediaOrdersProductVariablesWatcher
