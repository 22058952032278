import React from 'react'
import TimeField from 'react-simple-timefield'
import classnames from 'classnames'

import useStyles from './styles'

const TimePicker = ({ className, value, changeHandler, ...props }) => {
  const classes = useStyles()

  const handleInputFocus = e => {
    if (value === '00:00') {
      e.target.focus()
      e.target.setSelectionRange(0, 0)
    }
  }

  return (
    <div className={classnames('field', className)}>
      <TimeField
        className={classes.timePicker}
        value={value}
        onFocus={handleInputFocus}
        onClick={handleInputFocus}
        inputMode="numeric"
        onChange={(event, time) => changeHandler(time)}
        {...props}
      />
    </div>
  )
}

export default TimePicker
