import { createStyles } from '../../../../../../styles/helpers'

import { darkBlue, darkGreen } from '../../../../../../styles/const/colors'

const useStyles = createStyles({
  change: {
    width: '100%',
    textAlign: 'right',
    fontSize: 14,
    fontWeight: 600,
    color: darkBlue,
    '&.increase': {
      color: darkGreen
    }
  }
})

export default useStyles
