import update from 'immutability-helper'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  CLEAR_AGENCY_INVITES,
  CLEAR_AGENCY_MEMBERS,
  CLEAR_CREATE_AGENCY_INVITE,
  CLEAR_UPDATE_AGENCY_MEMBER,
  CREATE_AGENCY_INVITE,
  CREATE_AGENCY_INVITE_FAILURE,
  CREATE_AGENCY_INVITE_SUCCESS,
  ACCEPT_AGENCY_INVITE,
  ACCEPT_AGENCY_INVITE_SUCCESS,
  ACCEPT_AGENCY_INVITE_FAILURE,
  DELETE_AGENCY_INVITE,
  DELETE_AGENCY_INVITE_FAILURE,
  DELETE_AGENCY_INVITE_SUCCESS,
  GET_AGENCY_INVITES,
  GET_AGENCY_INVITES_FAILURE,
  GET_AGENCY_INVITES_SUCCESS,
  GET_AGENCY_MEMBERS,
  GET_AGENCY_MEMBERS_FAILURE,
  GET_AGENCY_MEMBERS_SUCCESS,
  RESEND_AGENCY_INVITE,
  RESEND_AGENCY_INVITE_FAILURE,
  RESEND_AGENCY_INVITE_SUCCESS,
  UPDATE_AGENCY_MEMBER,
  UPDATE_AGENCY_MEMBER_FAILURE,
  UPDATE_AGENCY_MEMBER_SUCCESS,
  UPDATE_AGENCY_MEMBER_STATUS,
  UPDATE_AGENCY_MEMBER_STATUS_FAILURE,
  UPDATE_AGENCY_MEMBER_STATUS_SUCCESS,
  CLEAR_ACCEPT_AGENCY_INVITE
} from '../actions/agencyTeam'
import { MEMBER_CHANGE_DATA, MEMBER_CHANGE_STATUS } from '../../constants/forms'
import { deleteItemById, updateItem } from '../../helpers/modules/reducerHelpers'
import {
  INITIAL_CREATE_REDUCER,
  INITIAL_DELETE_REDUCER,
  INITIAL_GET_RESULTS_REDUCER,
  INITIAL_LOADING_REDUCER
} from '../../constants/reducer'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  agencyMembers: {
    members: INITIAL_GET_RESULTS_REDUCER,
    updatedMember: {}
  },
  agencyInvites: {
    invites: INITIAL_GET_RESULTS_REDUCER,
    createdInvite: INITIAL_CREATE_REDUCER,
    acceptedInvite: {
      ...INITIAL_LOADING_REDUCER,
      wasAccepted: false
    },
    deletedInvite: INITIAL_DELETE_REDUCER,
    reinvitedUser: {
      wasSent: false,
      id: null,
      error: null
    }
  }
}

export default function agencyTeam(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    case GET_AGENCY_MEMBERS:
      return update(state, {
        agencyMembers: {
          members: {
            $merge: {
              isLoading: true
            }
          }
        }
      })
    case GET_AGENCY_MEMBERS_SUCCESS:
      return update(state, {
        agencyMembers: {
          members: {
            $merge: {
              isLoading: false,
              wasLoaded: true,
              results: action.agencyMembersData.results
            }
          }
        }
      })
    case GET_AGENCY_MEMBERS_FAILURE:
      return update(state, {
        agencyMembers: {
          members: {
            $merge: {
              isLoading: false,
              error: action.error
            }
          }
        }
      })
    case GET_AGENCY_INVITES:
      return update(state, {
        agencyInvites: {
          invites: {
            $merge: {
              isLoading: true
            }
          }
        }
      })
    case GET_AGENCY_INVITES_SUCCESS:
      return update(state, {
        agencyInvites: {
          invites: {
            $merge: {
              isLoading: false,
              wasLoaded: true,
              results: action.invitesData.results
            }
          }
        }
      })
    case GET_AGENCY_INVITES_FAILURE:
      return update(state, {
        agencyInvites: {
          invites: {
            $merge: {
              isLoading: false,
              error: action.error
            }
          }
        }
      })
    case CREATE_AGENCY_INVITE:
      return update(state, {
        agencyInvites: {
          createdInvite: { $set: { isLoading: true } }
        }
      })
    case CREATE_AGENCY_INVITE_SUCCESS:
      return update(state, {
        agencyInvites: {
          createdInvite: { $set: { ...action.inviteData, wasCreated: true } },
          invites: {
            results: {
              $push: [action.inviteData]
            }
          }
        }
      })
    case CREATE_AGENCY_INVITE_FAILURE:
      return update(state, {
        agencyInvites: {
          createdInvite: { $set: { error: action.error } }
        }
      })
    case ACCEPT_AGENCY_INVITE:
      return update(state, {
        agencyInvites: {
          acceptedInvite: {
            $merge: {
              isLoading: true
            }
          }
        }
      })
    case ACCEPT_AGENCY_INVITE_SUCCESS:
      return update(state, {
        agencyInvites: {
          acceptedInvite: {
            $merge: {
              isLoading: false,
              wasAccepted: true
            }
          }
        }
      })
    case ACCEPT_AGENCY_INVITE_FAILURE:
      return update(state, {
        agencyInvites: {
          acceptedInvite: {
            $merge: {
              isLoading: true,
              error: action.error
            }
          }
        }
      })
    case UPDATE_AGENCY_MEMBER:
      return update(state, {
        agencyMembers: {
          updatedMember: {
            $set: {
              isLoading: true,
              id: action.id,
              updatingType: MEMBER_CHANGE_DATA
            }
          }
        }
      })
    case UPDATE_AGENCY_MEMBER_STATUS:
      return update(state, {
        agencyMembers: {
          updatedMember: {
            $set: {
              isLoading: true,
              id: action.id,
              updatingType: MEMBER_CHANGE_STATUS
            }
          }
        }
      })
    case UPDATE_AGENCY_MEMBER_SUCCESS:
    case UPDATE_AGENCY_MEMBER_STATUS_SUCCESS:
      return update(state, {
        agencyMembers: {
          updatedMember: {
            $merge: {
              isLoading: false,
              wasUpdated: true
            }
          },
          members: {
            results: {
              $apply: items => updateItem(items, action.updatedMemberData)
            }
          }
        }
      })
    case UPDATE_AGENCY_MEMBER_FAILURE:
    case UPDATE_AGENCY_MEMBER_STATUS_FAILURE:
      return update(state, {
        agencyMembers: {
          updatedMember: {
            $merge: {
              isLoading: false,
              wasUpdated: false,
              error: action.error
            }
          }
        }
      })
    case DELETE_AGENCY_INVITE:
      return update(state, {
        agencyInvites: {
          deletedInvite: { $set: { isLoading: true, id: action.id } }
        }
      })
    case DELETE_AGENCY_INVITE_SUCCESS:
      return update(state, {
        agencyInvites: {
          invites: {
            results: {
              $apply: items => deleteItemById(items, action.id)
            }
          },
          deletedInvite: { $merge: { isLoading: false } }
        }
      })
    case DELETE_AGENCY_INVITE_FAILURE:
      return update(state, {
        agencyInvites: {
          deletedInvite: { $merge: { isLoading: false, error: action.error } }
        }
      })
    case RESEND_AGENCY_INVITE:
      return update(state, {
        agencyInvites: {
          reinvitedUser: { $set: { isLoading: true, id: action.id } }
        }
      })
    case RESEND_AGENCY_INVITE_SUCCESS:
      return update(state, {
        agencyInvites: {
          reinvitedUser: { $merge: { isLoading: false, wasSent: true } }
        }
      })
    case RESEND_AGENCY_INVITE_FAILURE:
      return update(state, {
        agencyInvites: {
          reinvitedUser: { $merge: { isLoading: false, wasSent: false, error: action.error } }
        }
      })
    case CLEAR_AGENCY_MEMBERS:
      return update(state, {
        agencyMembers: { $set: initialState.agencyMembers }
      })
    case CLEAR_AGENCY_INVITES:
      return update(state, {
        agencyInvites: { $set: initialState.agencyInvites }
      })
    case CLEAR_UPDATE_AGENCY_MEMBER:
      return update(state, {
        agencyMembers: {
          updatedMember: {
            $set: initialState.agencyMembers.updatedMember
          }
        }
      })
    case CLEAR_CREATE_AGENCY_INVITE:
      return update(state, {
        agencyInvites: {
          createdInvite: {
            $set: initialState.agencyInvites.createdInvite
          }
        }
      })
    case CLEAR_ACCEPT_AGENCY_INVITE:
      return update(state, {
        agencyInvites: {
          acceptedInvite: {
            $set: initialState.agencyInvites.acceptedInvite
          }
        }
      })
    default:
      return state
  }
}
