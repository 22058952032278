import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles(theme => ({
  mediaOrderActions: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 16
  },
  cancelAmendmentButton: {
    width: '170px',
    marginRight: '16px'
  },
  formSubmitFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '315px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  contractProductsTable: {
    // workaround to have spacing between end of the screen and DatesSelector when it in the end of the screen
    paddingBottom: '240px'
  }
}))

export default useStyles
