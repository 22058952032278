import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getSquareLogoData } from '../HeadlinesSection/helpers'
import { isAllImageAssetsReady } from '../../helpers'
import { useEditAssetGroupSubmit } from '../../hooks'

import EditForm from '../../../../../../../features/components/Forms/EditForm'
import MultipleGoogleMediaUpload from '../../../../ReusableFields/MultipleGoogleMediaUpload'

import { googleAssetFieldType } from '../../../../../../../constants/ads'
import { getInitialValues } from './fields'
import { SQUARE_LOGO, squareLogoHeight, squareLogoWidth } from '../../../../fields'
import { getSquareLogoValidationSchema } from '../../../../validations'
import { validationSchema } from './validation'
import { selectedAdAccountIdSelector } from '../../../../../../../modules/selectors/app'

const SquareLogoSectionForm = ({ editItemData, isInternalAssetGroup, ...formProps }) => {
  const { assets, id: assetGroupId, account } = editItemData

  const selectedAdAccount = useSelector(selectedAdAccountIdSelector)
  const adAccount = account || selectedAdAccount

  const originalSquareLogoAssets = useMemo(() => getSquareLogoData(assets), [assets])

  const initialValues = useMemo(() => getInitialValues(originalSquareLogoAssets), [originalSquareLogoAssets])
  const squareLogoValidationSchema = useMemo(() => getSquareLogoValidationSchema(), [])

  const purifiedFormik = useEditAssetGroupSubmit({
    originalSectionAssets: originalSquareLogoAssets,
    fieldType: googleAssetFieldType.LOGO,
    sectionValueKey: SQUARE_LOGO,
    isInternalAssetGroup,
    assetGroupId,
    initialValues,
    validationSchema,
    adAccount
  })

  const { values } = purifiedFormik

  const isImagesReady = isAllImageAssetsReady(values, SQUARE_LOGO)

  const isEmptyList = !values[SQUARE_LOGO].length

  return (
    <EditForm formik={purifiedFormik} initialValues={initialValues} submitBtnDisabled={!isImagesReady} {...formProps}>
      <MultipleGoogleMediaUpload
        formik={purifiedFormik}
        FileItemPath={SQUARE_LOGO}
        validationSchema={squareLogoValidationSchema}
        addAnotherButtonText={isEmptyList ? 'Add square image' : 'Add another square image'}
        width={squareLogoWidth}
        height={squareLogoHeight}
        adAccountId={adAccount}
      />
    </EditForm>
  )
}

export default SquareLogoSectionForm
