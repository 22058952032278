import { createStyles } from '../../../../styles/helpers'

import { lightYellow } from '../../../../styles/const/colors'
import { transitionShort } from '../../../../styles/const/common'

export default createStyles(theme => ({
  option: {
    lineHeight: 1.1,
    display: 'flex',
    paddingLeft: 11,
    position: 'relative',
    borderRadius: theme.defaultBorderRadius,
    '&:focus, &:active': {
      background: lightYellow
    },
    '@media (hover: hover)': {
      '&:hover': {
        background: lightYellow
      }
    }
  },
  angleIconContainer: {
    padding: '0 4px',
    position: 'absolute',
    height: '100%',
    left: 5,
    display: 'flex',
    justifyContent: 'center'
  },
  optionAngleIcon: {
    width: 9,
    height: 'auto',
    transition: transitionShort,
    transform: ({ isExpanded }) => (isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)')
  },
  optionCheckbox: {
    padding: '11px 12px 11px 16px',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '17px',
    flexGrow: 1,
    columnGap: 6,
    '& .checkbox': {
      margin: '0 !important'
    }
  },
  expandableContainer: {
    paddingLeft: 20
  }
}))
