import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import TableDataLoader from '../../../../components/Table/TableDataLoader'
import MarginsTableData from './MarginsTableData'

import {
  marginsErrorSelector,
  marginsIsLoadingSelector,
  marginsWasLoadedSelector
} from '../../../../modules/selectors/margins'

import { phonesDownSize } from '../../../../styles/const/breakpoints'

import { mobileOneLineColumnsSchema } from '../../../../constants/other'

import useCommonStyles from '../../../../styles/common/table'

export const tableColumnsSize = {
  name: 500,
  status: 90,
  effectiveDates: 200
}

const MarginsTable = ({ margins }) => {
  const { t } = useTranslation()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const commonClasses = useCommonStyles()

  const marginsColumnsSchema = useMemo(
    () => [
      {
        header: t('Name'),
        style: { maxWidth: tableColumnsSize.name }
      },
      {
        header: t('Status'),
        style: { maxWidth: tableColumnsSize.status }
      },
      {
        header: t('Effective dates'),
        style: { maxWidth: tableColumnsSize.effectiveDates }
      }
    ],
    [t]
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no margins')}</div>
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <TableDataLoader
      itemsLength={margins.length}
      errorSelector={marginsErrorSelector}
      wasLoadedSelector={marginsWasLoadedSelector}
      isLoadingSelector={marginsIsLoadingSelector}
      skeletonProps={{ cols: isMobile ? mobileOneLineColumnsSchema : marginsColumnsSchema }}
      noDataContent={noDataContent}
    >
      <MarginsTableData margins={margins} />
    </TableDataLoader>
  )
}

MarginsTable.propTypes = {
  margins: PropTypes.array.isRequired
}

export default MarginsTable
