import React from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from '../../../components/Button'
import ProgressButton from '../../../components/Form/ProgressButton'

import useStyles from './styles'

const InternalAdActionButtons = ({
  adId,
  className,
  approveInternalAdSelector,
  approveInternalAdIsLoadingSelector,
  approveInternalAdErrorSelector,
  approveInternalAdWasApprovedSelector,
  approveInternalAdHandler,
  disapproveInternalAdHandler
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const approveInternalAd = useSelector(approveInternalAdSelector)
  const approveInternalAdIsLoading = useSelector(approveInternalAdIsLoadingSelector)
  const approveInternalAdError = useSelector(approveInternalAdErrorSelector)
  const approveInternalAdWasApproved = useSelector(approveInternalAdWasApprovedSelector)

  const isCurrentAdUnderApprove = !!approveInternalAd[adId]

  return (
    <div className={classnames(classes.btnsContainer, className)}>
      <Button className={classnames(classes.actionBtn, classes.disapproveBtn)} onClick={disapproveInternalAdHandler}>
        {t('Disapprove')}
      </Button>
      <ProgressButton
        className={classnames(classes.actionBtn, classes.approveBtn)}
        wrapperClassName={classes.approveBtnWrapper}
        isFormLoading={isCurrentAdUnderApprove && approveInternalAdIsLoading}
        formError={isCurrentAdUnderApprove ? approveInternalAdError : null}
        successSubmit={isCurrentAdUnderApprove && approveInternalAdWasApproved}
        onClick={approveInternalAdHandler}
        solid={false}
      >
        {t('Approve')}
      </ProgressButton>
    </div>
  )
}

InternalAdActionButtons.propTypes = {
  adId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  approveInternalAdSelector: PropTypes.func.isRequired,
  approveInternalAdIsLoadingSelector: PropTypes.func.isRequired,
  approveInternalAdErrorSelector: PropTypes.func.isRequired,
  approveInternalAdWasApprovedSelector: PropTypes.func.isRequired,
  approveInternalAdHandler: PropTypes.func.isRequired,
  disapproveInternalAdHandler: PropTypes.func.isRequired
}

export default InternalAdActionButtons
