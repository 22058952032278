import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import MultipleOptionsField from '../../../../../../../ReusableFormFields/MultipleOptionsField'
import TextSuggestions from '../../../../../../../../features/components/Form/TextSuggestions'

import { googleAdSuggestionsIsLoadingSelector } from '../../../../../../../../modules/selectors/tools'

import { headlineMaxLength, HEADLINES_LIST } from '../../../../../fields'

function HeadlinesSection({ formik, suggestions }) {
  const [selectedField, setSelectedField] = useState('')

  const suggestionsLoading = useSelector(googleAdSuggestionsIsLoadingSelector)
  const { setFieldValue } = formik

  const handlePrimaryTextChange = useCallback(
    selectedChange => {
      setFieldValue(selectedField, selectedChange)
      setSelectedField('')
    },
    [setFieldValue, selectedField]
  )

  const handleSelectedFieldChange = useCallback(selectedField => {
    setSelectedField(selectedField)
  }, [])

  return (
    <FieldRow
      title="Headlines"
      description="30 characters or less"
      footer={
        <TextSuggestions
          showLogo={false}
          selectedSuggestion={null}
          suggestions={suggestions}
          onSelectSuggestion={handlePrimaryTextChange}
          typingAnimation={suggestionsLoading}
        />
      }
    >
      <MultipleOptionsField
        isTextarea
        ignoreBlur
        fieldPlaceholder="Headline"
        fieldMaxLength={headlineMaxLength}
        listFieldName={HEADLINES_LIST}
        formik={formik}
        minListQuantity={3}
        maxListQuantity={5}
        onFieldSelect={handleSelectedFieldChange}
        selectedFieldName={selectedField}
        preselectField={!!suggestions?.length}
        addAnotherButtonText="+ Add another headline"
      />
    </FieldRow>
  )
}

HeadlinesSection.propTypes = {
  suggestions: PropTypes.array
}

export default HeadlinesSection
