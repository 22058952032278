import update from 'immutability-helper'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  SUBMIT_AMENDMENT,
  SUBMIT_AMENDMENT_SUCCESS,
  SUBMIT_AMENDMENT_FAILURE,
  CLEAR_SUBMIT_AMENDMENT,
  GET_AMENDMENT,
  GET_AMENDMENT_SUCCESS,
  GET_AMENDMENT_FAILURE,
  CLEAR_GET_AMENDMENT,
  CLEAR_ACTIVE_AMENDMENT,
  GET_AMENDMENTS_LIST,
  GET_AMENDMENTS_LIST_SUCCESS,
  GET_AMENDMENTS_LIST_FAILURE,
  CLEAR_GET_AMENDMENTS_LIST,
  SEND_AMENDMENT_CONTRACT,
  SEND_AMENDMENT_CONTRACT_SUCCESS,
  SEND_AMENDMENT_CONTRACT_FAILURE,
  CLEAR_SEND_AMENDMENT_CONTRACT,
  DELETE_AMENDMENT_CONTRACT_SIGNATURE,
  DELETE_AMENDMENT_CONTRACT_SIGNATURE_SUCCESS,
  DELETE_AMENDMENT_CONTRACT_SIGNATURE_FAILURE,
  CLEAR_DELETE_AMENDMENT_CONTRACT_SIGNATURE,
  SIGN_AMENDMENT_CONTRACT,
  SIGN_AMENDMENT_CONTRACT_SUCCESS,
  SIGN_AMENDMENT_CONTRACT_FAILURE,
  CLEAR_SIGN_AMENDMENT_CONTRACT,
  CREATE_AMENDMENT,
  CREATE_AMENDMENT_SUCCESS,
  CREATE_AMENDMENT_FAILURE,
  CLEAR_CREATE_AMENDMENT
} from '../actions/amendments'
import {
  INITIAL_GET_REDUCER,
  INITIAL_CREATE_REDUCER,
  INITIAL_DELETE_REDUCER,
  INITIAL_GET_RESULTS_REDUCER
} from '../../constants/reducer'
import { deleteItemById } from '../../helpers/modules/reducerHelpers'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  getAmendment: INITIAL_GET_REDUCER,
  getAmendmentsList: INITIAL_GET_RESULTS_REDUCER,
  createAmendment: INITIAL_CREATE_REDUCER,
  signAmendmentContract: INITIAL_CREATE_REDUCER,
  deleteAmendmentContractSignature: INITIAL_DELETE_REDUCER,
  sendAmendmentContract: INITIAL_CREATE_REDUCER,
  submitAmendment: INITIAL_CREATE_REDUCER,
  activeAmendmentContract: null
}

export default function amendments(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // Get amendment
    case GET_AMENDMENT:
      return update(state, {
        getAmendment: { $merge: { isLoading: true } }
      })
    case GET_AMENDMENT_SUCCESS:
      return update(state, {
        getAmendment: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        },
        activeAmendmentContract: { $set: action.data }
      })
    case GET_AMENDMENT_FAILURE:
      return update(state, {
        getAmendment: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_AMENDMENT:
      return update(state, {
        getAmendment: { $set: initialState.getAmendment }
      })

    case CLEAR_ACTIVE_AMENDMENT:
      return update(state, {
        activeAmendmentContract: { $set: initialState.activeAmendmentContract }
      })

    // Get amendments list
    case GET_AMENDMENTS_LIST:
      return update(state, {
        getAmendmentsList: { $merge: { isLoading: true } }
      })
    case GET_AMENDMENTS_LIST_SUCCESS:
      return update(state, {
        getAmendmentsList: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data.results
          }
        }
      })
    case GET_AMENDMENTS_LIST_FAILURE:
      return update(state, {
        getAmendmentsList: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_AMENDMENTS_LIST:
      return update(state, {
        getAmendmentsList: { $set: initialState.getAmendmentsList }
      })

    // Create amendment
    case CREATE_AMENDMENT:
      return update(state, {
        createAmendment: { $merge: { isLoading: true } }
      })
    case CREATE_AMENDMENT_SUCCESS:
      return update(state, {
        createAmendment: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },
        activeAmendmentContract: { $set: action.data }
      })
    case CREATE_AMENDMENT_FAILURE:
      return update(state, {
        createAmendment: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_AMENDMENT:
      return update(state, {
        createAmendment: { $set: initialState.createAmendment }
      })

    // Sign amendment contract
    case SIGN_AMENDMENT_CONTRACT:
      return update(state, {
        signAmendmentContract: { $merge: { isLoading: true } }
      })
    case SIGN_AMENDMENT_CONTRACT_SUCCESS:
      return update(state, {
        signAmendmentContract: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },
        activeAmendmentContract: { $set: action.data }
      })
    case SIGN_AMENDMENT_CONTRACT_FAILURE:
      return update(state, {
        signAmendmentContract: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_SIGN_AMENDMENT_CONTRACT:
      return update(state, {
        signAmendmentContract: { $set: initialState.signAmendmentContract }
      })

    // Delete amendment contract signature
    case DELETE_AMENDMENT_CONTRACT_SIGNATURE:
      return update(state, {
        deleteAmendmentContractSignature: { $merge: { isLoading: true } }
      })
    case DELETE_AMENDMENT_CONTRACT_SIGNATURE_SUCCESS:
      return update(state, {
        deleteAmendmentContractSignature: {
          $merge: {
            isLoading: false,
            wasDeleted: true,
            data: action.data
          }
        },
        activeAmendmentContract: { $set: action.data }
      })
    case DELETE_AMENDMENT_CONTRACT_SIGNATURE_FAILURE:
      return update(state, {
        deleteAmendmentContractSignature: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DELETE_AMENDMENT_CONTRACT_SIGNATURE:
      return update(state, {
        deleteAmendmentContractSignature: { $set: initialState.deleteAmendmentContractSignature }
      })

    // Send amendment contract
    case SEND_AMENDMENT_CONTRACT:
      return update(state, {
        sendAmendmentContract: { $merge: { isLoading: true } }
      })
    case SEND_AMENDMENT_CONTRACT_SUCCESS:
      return update(state, {
        sendAmendmentContract: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        }
      })
    case SEND_AMENDMENT_CONTRACT_FAILURE:
      return update(state, {
        sendAmendmentContract: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_SEND_AMENDMENT_CONTRACT:
      return update(state, {
        sendAmendmentContract: { $set: initialState.sendAmendmentContract }
      })

    // Submit amendment
    case SUBMIT_AMENDMENT:
      return update(state, {
        submitAmendment: { $merge: { isLoading: true } }
      })
    case SUBMIT_AMENDMENT_SUCCESS:
      return update(state, {
        submitAmendment: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.id
          }
        },
        // when amendment is submitted it should not be accessible anymore
        getAmendmentsList: {
          results: { $apply: items => deleteItemById(items, action.id) }
        }
      })
    case SUBMIT_AMENDMENT_FAILURE:
      return update(state, {
        submitAmendment: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_SUBMIT_AMENDMENT:
      return update(state, {
        submitAmendment: { $set: initialState.submitAmendment }
      })

    default:
      return state
  }
}
