import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import useIsInternalAdCreation from '../../../../features/hooks/useIsInternalAdCreation'

import {
  combinedCampaignsErrorSelector,
  combinedCampaignsWasLoadedSelector
} from '../../../../modules/selectors/combinedData'

const useUploadAdCreativeConstants = () => {
  const combinedCampaignsWasLoaded = useSelector(combinedCampaignsWasLoadedSelector)
  const combinedCampaignsError = useSelector(combinedCampaignsErrorSelector)

  // if get error state for combined campaigns, still give access to the form
  const isFormLoading = !combinedCampaignsWasLoaded && !combinedCampaignsError

  // all users which are not rights_holder type, can create only quarantine (internal) ads
  const isInternalAdCreation = useIsInternalAdCreation()

  return useMemo(
    () => ({
      isFormLoading,
      isInternalAdCreation
    }),
    [isFormLoading, isInternalAdCreation]
  )
}

export default useUploadAdCreativeConstants
