import {
  FILES_REQUIREMENTS_SUPPORTED_FORMATS,
  initialFileSpecifications
} from '../../../MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'

const comparisonKeys = [...Object.keys(initialFileSpecifications), FILES_REQUIREMENTS_SUPPORTED_FORMATS]

export const compareFileRequirements = (selectedFileRequirements, mediaFileRequirements) => {
  // selectedFileRequirements and mediaFileRequirements are objects, each object field should be equal, all fields
  return comparisonKeys.every(key => {
    if (key === FILES_REQUIREMENTS_SUPPORTED_FORMATS) {
      // supported_file_types is an array, and we need to check if the arrays values are the same
      const selectedFileTypes = [...selectedFileRequirements[key]].sort()
      const mediaFileTypes = [...mediaFileRequirements[key]].sort()
      return selectedFileTypes.join(',') === mediaFileTypes.join(',')
    } else {
      return selectedFileRequirements[key] === mediaFileRequirements[key]
    }
  })
}
