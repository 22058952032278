import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import useStyles from '../styles'

const RestartLogin = ({ onRestartLogin, isSMSMFA }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <p className={classes.restartLoginMessage}>
      {t(isSMSMFA ? 'Didn’t receive a code? ' : 'Having trouble? ')}
      <button type="button" onClick={onRestartLogin} className={classes.restartLoginButton}>
        {t('Restart Login')}
      </button>
    </p>
  )
}

RestartLogin.propTypes = {
  onRestartLogin: PropTypes.func.isRequired,
  isSMSMFA: PropTypes.bool
}

export default RestartLogin
