import * as Yup from 'yup'
import { t } from 'i18next'

import { getAdLongHeadline } from '../../helpers'
import { longHeadlineMaxLength } from '../../../../fields'
import { LONG_HEADLINE } from '../../../DisplayAdGoogleCreateForm/fields'

export const getInitialValues = ad => {
  const longHeadline = getAdLongHeadline(ad)

  return {
    [LONG_HEADLINE]: longHeadline || ''
  }
}

export const validationSchema = Yup.object({
  [LONG_HEADLINE]: Yup.string()
    .max(longHeadlineMaxLength, t('longHeadlineMaxLengthErrorMessage', { maxLength: longHeadlineMaxLength }))
    .required('This field should not be empty')
})
