import React from 'react'
import useStyles from './styles'

function ThumbnailCell({ imageUrl }) {
  const classes = useStyles()

  return (
    <img
      className={classes.thumbnail}
      src={imageUrl}
      alt="thumbnail"
      // hide image completely if it fails to load instead of showing broken image icon
      onError={i => (i.target.style.display = 'none')}
    />
  )
}

export default React.memo(ThumbnailCell)
