import { MEDIA_FILE_OPTION } from '../../Facebook/AdForms/fields'
import { MEDIA_FILE_UPLOAD } from '../../ReusableFormFields/AdFileUpload/fields'

export const NAME = 'name'
export const BUSINESS_NAME = 'business_name'
export const CLICK_THROUGH_LINK = 'final_urls'
export const HEADLINES_LIST = 'headlines_list'
export const DESCRIPTIONS_LIST = 'descriptions_list'
export const LONG_HEADLINES_LIST = 'long_headlines_list'

// asset object is missing id field, so instead use resource_name field which is unique as identifier
export const RESOURCE_NAME = 'resource_name'

export const headlineMaxLength = 30
export const longHeadlineMaxLength = 90

export const descriptionsMaxLengthSchema = {
  0: 60,
  default: 90
}

// media sizes
export const landscapeImageWidth = 1200
export const landscapeImageHeight = 628
export const landscapeLogoWidth = 1200
export const landscapeLogoHeight = 300
export const squareImageWidth = 1200
export const squareImageHeight = 1200
export const squareLogoWidth = 1200
export const squareLogoHeight = 1200
export const portraitImageWidth = 960
export const portraitImageHeight = 1200

export const VIDEO_ADS_OPTION = 'VIDEO_ADS_OPTION'
export const VIDEO_ADS_ENTER = 'VIDEO_ADS_ENTER'
export const VIDEO_ADS_URL = 'VIDEO_ADS_URL'

export const ASSET_GROUP_TYPE = 'assetGroupType'

// FILE FIELDS:
export const FILE_NAME = 'file_name'
export const FILE_URL = 'file_url'
export const FILE_TYPE = 'file_type'
export const MEDIA_TYPE = 'media_type'
export const ASSET_ID = 'asset'
export const ASSET_TYPE = 'field_type'

// IMAGES FIELDS:
export const LANDSCAPE_IMAGE = 'landscape_image'
export const SQUARE_IMAGE = 'square_image'
export const PORTRAIT_IMAGE = 'portrait_image'

// LOGOS FIELDS:
export const SQUARE_LOGO = 'square_logo'
export const LANDSCAPE_LOGO = 'landscape_logo'

// STATUS
export const STATUS = 'status'

export const youtubeVideoIdUrlParams = 'watch?v='

export const initialGoogleFile = {
  [FILE_NAME]: '',
  [FILE_URL]: '',
  [FILE_TYPE]: '',
  [MEDIA_TYPE]: '',
  [ASSET_ID]: null,
  [MEDIA_FILE_OPTION]: MEDIA_FILE_UPLOAD
}

export const getImageAssets = images => {
  return images.map(image => ({ asset: image[ASSET_ID] }))
}

export const getFormattedImageAssetData = (assetData = {}) => {
  return {
    [FILE_NAME]: assetData.name,
    [FILE_URL]: assetData.image_asset?.full_size?.url,
    [ASSET_ID]: assetData.resource_name
  }
}
