import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getCurrentUserSelector, selectedControllerDataSelector } from '../../modules/selectors/app'
import { DISABLED_MFA } from '../../pages/Settings/EditProfile/MFAContent/fields'

export default function useCheckMFASetupRequired() {
  // check if user require to enable MFA based on controller settings or user settings
  const user = useSelector(getCurrentUserSelector)
  const selectedController = useSelector(selectedControllerDataSelector)

  const isUserMfaRequired = user?.mfa_is_required
  // BE field is_mfa_enabled is not a boolean, it's a string which represents mfa type
  const isUserMfaEnabled = user?.is_mfa_enabled && user?.is_mfa_enabled !== DISABLED_MFA
  const isControllerMfaRequired = selectedController?.mfa_is_required

  const userSettingsForceMfa = isUserMfaRequired && !isUserMfaEnabled
  const controllerForceMFA = isControllerMfaRequired && !isUserMfaEnabled

  return useMemo(() => {
    // mfa could be required on user level or controller level
    return userSettingsForceMfa || controllerForceMFA
  }, [userSettingsForceMfa, controllerForceMFA])
}
