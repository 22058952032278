import React, { useCallback, useMemo } from 'react'
import { getIn } from 'formik'
import PropTypes from 'prop-types'

import ProductBreakdown from '../ProductBreakdown'
import ActionText from '../../../../../../../../../../components/ActionText'
import ErrorMessage from '../../../../../../../../../../components/Form/ErrorMessage'

import { useProductDelete } from '../../../../hooks/useProductDelete'

import { getProductsPath } from '../../../../helpers/products'

import { ALLOWED_CATEGORY_PRODUCTS_SELECTION, SELECTED_PACKAGES } from '../../../../fields'
import {
  DIGITAL,
  EDM,
  IN_STORE,
  OOH,
  PHYSICAL_SPACES,
  PRINT
} from '../../../../../../../../../../constants/mediaOrders'

import useStyles from './styles'

function CategoryOrderedProducts({ formik, packageIndex, productCategory, currency, showError, minQuantity }) {
  const classes = useStyles()

  const { values, setFieldValue, errors } = formik

  const addProductText = useMemo(() => {
    switch (productCategory) {
      case PRINT:
        return '+ Add another print product'
      case DIGITAL:
        return '+ Add another digital product'
      case EDM:
        return '+ Add another edm product'
      case OOH:
        return '+ Add another ooh product'
      case IN_STORE:
        return '+ Add another in store product'
      case PHYSICAL_SPACES:
        return '+ Add another promotional spaces product'
      default:
        return ''
    }
  }, [productCategory])

  const allowSelectProduct = useCallback(() => {
    const allowedSelectionPath = `${SELECTED_PACKAGES}[${packageIndex}].${ALLOWED_CATEGORY_PRODUCTS_SELECTION}.${productCategory}`

    setFieldValue(allowedSelectionPath, true)
  }, [packageIndex, productCategory, setFieldValue])

  const productsPath = getProductsPath(productCategory)

  const mediaProductsPath = `${SELECTED_PACKAGES}[${packageIndex}].${productsPath}`
  const selectedProducts = getIn(values, mediaProductsPath)
  const productCategoryError = getIn(errors, mediaProductsPath)

  // productCategoryError could be array if some of the subproducts has error it self
  // to avoid app crash show only error for the category i.e. when it is string error
  const productCategoryErrorMessage = typeof productCategoryError === 'string' ? productCategoryError : null

  const handleProductDelete = useProductDelete({ mediaProductsPath, selectedProducts, setFieldValue })

  return (
    <section className={classes.productCategory}>
      {selectedProducts.map((productValues, index) => (
        <ProductBreakdown
          key={productValues.data.id}
          formik={formik}
          currency={currency}
          productCategory={productCategory}
          productValues={productValues}
          productPath={`${mediaProductsPath}[${index}]`}
          onProductRemove={() => handleProductDelete(productValues.data.id)}
          minQuantity={minQuantity}
        />
      ))}
      <ActionText onClick={allowSelectProduct} isDark isBold>
        {addProductText}
      </ActionText>
      {showError && productCategoryErrorMessage && <ErrorMessage error={productCategoryErrorMessage} />}
    </section>
  )
}

CategoryOrderedProducts.propTypes = {
  formik: PropTypes.object.isRequired,
  packageIndex: PropTypes.number.isRequired,
  productCategory: PropTypes.string.isRequired,
  currency: PropTypes.object.isRequired,
  showError: PropTypes.bool,
  minQuantity: PropTypes.number
}

export default CategoryOrderedProducts
