import { bannerGrey, black, greyOutline, lightGrey } from '../../../styles/const/colors'
import { formatReportNumber } from '../../formatters'

export const toolTipStyles = {
  displayColors: false,
  backgroundColor: '#ffffff',
  titleColor: black,
  titleFont: {
    size: 13
  },
  bodyFont: {
    size: 13
  },
  bodyColor: black,
  footerColor: black,
  footerFont: {
    weight: 400,
    size: 13
  },
  borderWidth: 1,
  borderColor: lightGrey,
  cornerRadius: 4,
  padding: {
    left: 15,
    right: 15,
    top: 10,
    bottom: 10
  }
}

const getReusableGraphOptions = ({ format, currency, theme, graphColor = '', autoSkipPadding = 15 }) => ({
  responsive: true,
  maintainAspectRatio: false,
  hover: {
    mode: 'index',
    intersect: false
  },
  scales: {
    x: {
      grid: {
        display: false
      },
      ticks: {
        padding: 10,
        color: greyOutline,
        maxRotation: 0,
        minRotation: 0,
        beginAtZero: true,
        autoSkipPadding,
        font: {
          size: 12
        }
      }
    },
    y: {
      grid: {
        color: bannerGrey,
        drawBorder: false
      },
      ticks: {
        padding: 5,
        color: greyOutline,
        textAlign: 'left',
        maxTicksLimit: 6,
        maxRotation: 0,
        minRotation: 0,
        font: {
          size: 12
        }
      },
      beginAtZero: true
    }
  },
  elements: {
    point: {
      // this -1 radius is needed to fix behaviour when pointer was under axis line
      radius: -1,
      borderWidth: 3,
      hoverRadius: 5,
      hoverBorderColor: graphColor || theme.brandPrimary,
      hoverBackgroundColor: '#ffffff',
      hoverBorderWidth: 3
    }
  },
  plugins: {
    legend: { display: false },
    tooltip: {
      callbacks: {
        label: tooltipItem => formatReportNumber({ value: tooltipItem.raw, format, currency })
      },
      intersect: false,
      mode: 'index',
      ...toolTipStyles
    }
  }
})

export default getReusableGraphOptions
