import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './styles'

const CardFooter = ({ isFixedPrice, price, cardButton }) => {
  const classes = useStyles()

  return (
    <div className={classes.footer}>
      <p className={classes.price}>
        {!isFixedPrice && 'from '}
        {price}
      </p>
      {cardButton && cardButton}
    </div>
  )
}

CardFooter.propTypes = {
  isFixedPrice: PropTypes.bool,
  price: PropTypes.string,
  cardButton: PropTypes.node
}

export default CardFooter
