import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR
// Get tik tok video
export const getTikTokVideoSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokVideo.data,
  data => data
)

export const getTikTokVideoIsLoadingSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokVideo.isLoading,
  isLoading => isLoading
)

export const getTikTokVideoErrorSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokVideo.error,
  error => error
)

export const getTikTokVideoWasLoadedSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokVideo.wasLoaded,
  wasLoaded => wasLoaded
)

// TikTok regions
export const tikTokRegionsSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokRegions.results,
  results => results
)

export const tikTokRegionsIsLoadingSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokRegions.isLoading,
  isLoading => isLoading
)

export const tikTokRegionsErrorSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokRegions.error,
  error => error
)

export const tikTokRegionsWasLoadedSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokRegions.wasLoaded,
  wasLoaded => wasLoaded
)

// TikTok pixels
export const tikTokPixelsSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokPixels.results,
  results => results
)

export const tikTokPixelsIsLoadingSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokPixels.isLoading,
  isLoading => isLoading
)

export const tikTokPixelsErrorSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokPixels.error,
  error => error
)

export const tikTokPixelsWasLoadedSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokPixels.wasLoaded,
  wasLoaded => wasLoaded
)

// TikTok identities
export const tikTokIdentitiesSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokIdentities.results,
  results => results
)

export const tikTokIdentitiesIsLoadingSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokIdentities.isLoading,
  isLoading => isLoading
)

export const tikTokIdentitiesErrorSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokIdentities.error,
  error => error
)

export const tikTokIdentitiesWasLoadedSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokIdentities.wasLoaded,
  wasLoaded => wasLoaded
)

// Create tik tok identities
export const createTikTokIdentityIsLoadingSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.createTikTokIdentity.isLoading,
  isLoading => isLoading
)

export const createTikTokIdentityErrorSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.createTikTokIdentity.error,
  error => error
)

export const createTikTokIdentityWasCreatedSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.createTikTokIdentity.wasCreated,
  wasCreated => wasCreated
)

// TikTok interests
export const tikTokInterestsSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.interests.results,
  results => results
)

export const tikTokInterestsIsLoadingSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.interests.isLoading,
  isLoading => isLoading
)

export const tikTokInterestsWasLoadedSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.interests.wasLoaded,
  wasLoaded => wasLoaded
)

export const tikTokInterestsErrorSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.interests.error,
  error => error
)

// TikTok action categories
export const tikTokActionCategoriesSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.actionCategories.results,
  results => results
)

export const tikTokActionCategoriesIsLoadingSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.actionCategories.isLoading,
  isLoading => isLoading
)

export const tikTokActionCategoriesWasLoadedSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.actionCategories.wasLoaded,
  wasLoaded => wasLoaded
)

export const tikTokActionCategoriesErrorSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.actionCategories.error,
  error => error
)

// TikTok hashtags
export const tikTokHashtagsSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.hashtags.results,
  results => results
)

export const tikTokHashtagsIsLoadingSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.hashtags.isLoading,
  isLoading => isLoading
)

// TikTok custom audiences
export const tikTokCustomAudiencesSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.customAudiences.results,
  results => results
)

export const tikTokCustomAudiencesIsLoadingSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.customAudiences.isLoading,
  isLoading => isLoading
)

export const tikTokCustomAudiencesWasLoadedSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.customAudiences.wasLoaded,
  wasLoaded => wasLoaded
)

export const tikTokCustomAudiencesErrorSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.customAudiences.error,
  error => error
)

// Get tik tok video thumbnails
export const tikTokVideoThumbnailsSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokVideoThumbnails.results,
  results => results
)

export const tikTokVideoThumbnailsIsLoadingSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokVideoThumbnails.isLoading,
  isLoading => isLoading
)

export const tikTokVideoThumbnailsErrorSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokVideoThumbnails.error,
  error => error
)

export const tikTokVideoThumbnailsWasLoadedSelector = createSelector(
  ({ tikTokConstants }) => tikTokConstants.tikTokVideoThumbnails.wasLoaded,
  wasLoaded => wasLoaded
)
