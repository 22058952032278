import { createStyles } from '../helpers'

const useStyles = createStyles({
  form: {
    width: '100%'
  },
  content: {
    marginTop: 24
  },
  formFooter: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 32,
    '& button': {
      width: 'auto'
    }
  }
})

export default useStyles
