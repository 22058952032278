import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  adCreativeSuggestionsLoadingSelector,
  adCreativeSuggestionsSelector
} from '../../../../../../../../modules/selectors/tools'
import { useAiSuggestionsData } from '../../../../../../UploadFacebookAdCreative/UploadFacebookAdCreativeContent/ReusableForms/AdProductForm/useAiSuggestionsData'

export function useProductSuggestions({ selectedProduct, isActiveStep }) {
  const [suggestionsLoadedProduct, setSuggestionsLoadedProduct] = useState(null)
  const suggestions = useSelector(adCreativeSuggestionsSelector)
  const suggestionsLoading = useSelector(adCreativeSuggestionsLoadingSelector)

  const selectedProductId = selectedProduct?.id

  const { regenerateSuggestions } = useAiSuggestionsData({
    selectedProduct,
    // fetch suggestions only when product is selected and step is active
    allowLoadSuggestions: false
  })

  useEffect(() => {
    // load suggestions only when product is selected and step is active
    // and when product is changed
    if (isActiveStep && suggestionsLoadedProduct !== selectedProductId) {
      setSuggestionsLoadedProduct(selectedProductId)
      regenerateSuggestions()
    }
  }, [isActiveStep, suggestionsLoadedProduct, regenerateSuggestions, selectedProductId])

  return useMemo(
    () => ({
      regenerateSuggestions,
      suggestions,
      suggestionsLoading
    }),
    [regenerateSuggestions, suggestions, suggestionsLoading]
  )
}
