import { createStyles } from '../../../../../../../../../styles/helpers'

import { textEllipsis } from '../../../../../../../../../styles/mixins/text'
import { borderDarkGrey, textGrey } from '../../../../../../../../../styles/const/colors'
import { ELEMENT_HEIGHT } from '../../../../../BookedRevenueBreakdownReport/CalendarTable/CalendarRow/helpers'

export default createStyles(theme => ({
  root: {
    textAlign: 'left',
    padding: '6px 8px',
    cursor: 'pointer',
    fontSize: '12px',
    color: textGrey
  },
  tooltip: {
    width: '100%'
  },
  tooltipTrigger: {
    display: 'block !important'
  },
  barPlaceholder: {
    height: `${ELEMENT_HEIGHT - 2}px`, // minus 2px for the border width
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    ...textEllipsis(),
    fontSize: 12,
    padding: '6px 4px'
  },
  revenueProgress: {
    height: '9px !important'
  },
  title: {
    fontSize: '12px',
    lineHeight: '13px',
    color: borderDarkGrey,
    margin: 0,
    whiteSpace: 'nowrap'
  }
}))
