import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'

import ProductCardImages from './ProductCardImages'
import Button from '../../../../components/Button'

import useStyles from './styles'

const ProductCard = ({ onProductSelect, productData = {}, onProductThumbnailClick }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { images, name: productName, subtext, tags = [], id: productId } = productData

  const tagsWithIds = useMemo(() => tags.map(tag => ({ name: tag, id: uuidv4() })), [tags])

  const isProductDisabled = !productData.in_stock

  const productCardTitle = useMemo(() => {
    return productData.internal_id ? `${productData.internal_id} - ${productName}` : productName
  }, [productData.internal_id, productName])

  const handleProductSelect = useCallback(() => {
    onProductSelect && onProductSelect(productData)
  }, [onProductSelect, productData])

  const ProductCardButton = useCallback(() => {
    if (isProductDisabled) {
      return (
        <Button solid className={classnames(classes.productCardButton, classes.soldOut)} type="button" disabled>
          {t('Sold out')}
        </Button>
      )
    } else {
      return (
        <Button solid className={classes.productCardButton} type="button">
          {t('Select')}
        </Button>
      )
    }
  }, [classes, isProductDisabled, t])

  const onProductImageClick = useCallback(
    imageId => {
      onProductThumbnailClick(productId, imageId)
    },
    [onProductThumbnailClick, productId]
  )

  return (
    <div
      className={classnames(classes.container, { hoverable: !isProductDisabled })}
      onClick={isProductDisabled ? null : handleProductSelect}
    >
      <ProductCardImages images={images} productName={productName} onImageClick={onProductImageClick} />
      <div className={classes.info}>
        <h3 className={classes.title}>{productCardTitle}</h3>
        <p className={classes.description}>{subtext}</p>
        <div className={classes.tags}>
          {tagsWithIds.map(tag => (
            <span key={tag.id} className={classes.tag}>
              {tag.name}
            </span>
          ))}
        </div>
      </div>
      <ProductCardButton />
    </div>
  )
}

ProductCard.propTypes = {
  productData: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    graph: PropTypes.array,
    discounts: PropTypes.array,
    prices: PropTypes.array,
    in_stock: PropTypes.bool,
    files: PropTypes.array,
    images: PropTypes.array,
    created: PropTypes.string,
    modified: PropTypes.string,
    creative_deadline_days: PropTypes.number,
    media_category: PropTypes.string,
    name: PropTypes.string,
    providers: PropTypes.array,
    period: PropTypes.string,
    offsite: PropTypes.bool,
    subtext: PropTypes.string,
    file_name: PropTypes.string,
    controller: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tags: PropTypes.array,
    campaign_start_period_option: PropTypes.string,
    quantity: PropTypes.number,
    formattedPrice: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string
  }),
  onProductSelect: PropTypes.func
}

export default ProductCard
