import { createStyles } from '../../../../../../../styles/helpers'

import { darkGrey } from '../../../../../../../styles/const/colors'

const useStyles = createStyles({
  period: {
    color: darkGrey,
    fontSize: 12,
    marginTop: 6
  },
  productName: {
    whiteSpace: 'normal'
  }
})

export default useStyles
