import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { addMonths, endOfMonth, startOfMonth } from 'date-fns'

import ProductDetails from '../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/ProductSetupStep/ProductSetupFields/ProductDetails'
import SelectPeriodsFields from '../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/ProductSetupStep/ProductSetupFields/SelectPeriodsFields'
import Button from '../../../../../../../../components/Button'
import Field from '../../../../../../../../components/Form/Field'
import ErrorMessage from '../../../../../../../../components/Form/ErrorMessage'
import BackButton from '../../../../../../../../features/components/Forms/StepForm/BackButton'
import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import FieldsSection from '../../../../../../../../features/components/Form/FieldsSection'
import { SelectPeriodsFieldsContextProvider } from '../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/ProductSetupStep/ProductSetupFields/SelectPeriodsFields/SelectPeriodsFieldsContextProvider'

import { formatDateToBE } from '../../../../../../../../constants/dates'
import { useProductQuantityLimits } from '../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/OrderCartStep/PackageBreakdown/ProductBreakdown/useProductQuantityLimits'
import { SelectedMediaProductsContext } from '../../SelectedMediaProductsContext'

import { clearInventory, getInventory } from '../../../../../../../../modules/actions/mediaOrdersProducts'
import { mediaOrderSelector } from '../../../../../../../../modules/selectors/mediaOrders'

import { ADDED_PRODUCT } from '../fields'
import { PERIOD_DAY, PRINT } from '../../../../../../../../constants/mediaOrders'
import { PRODUCT_PERIODS_DATES, QUANTITY } from '../../../../../fields'

import useStyles from './styles'

function ProductSetup({ formik, selectedProduct, onBack }) {
  const mediaOrder = useSelector(mediaOrderSelector)
  const { currency } = mediaOrder
  const productId = selectedProduct.id
  const classes = useStyles()
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { selectedProductsData, contractSelectedProducts } = useContext(SelectedMediaProductsContext)
  const selectedProductData = selectedProductsData.find(productData => productData.id === productId)
  const mediaOrderProductId = selectedProductData?.mediaOrderProductId

  const productValues = formik.values[ADDED_PRODUCT]
  const productPeriods = productValues[PRODUCT_PERIODS_DATES]
  const productPeriod = selectedProduct.period
  const selectedProductCategory = productValues.media_category
  // allow show and select the product which is already added.
  // When it is adding again - we show warning and replace the existed product
  const isAlreadySelected = contractSelectedProducts.some(product => product.id === productId)

  const { errors, touched, setFieldValue } = formik

  const maxProductQuantity = useProductQuantityLimits(productPeriods)

  useEffect(() => {
    const dateFrom = formatDateToBE(startOfMonth(addMonths(new Date(), -1)))
    const dateTo = formatDateToBE(endOfMonth(addMonths(new Date(), 1)))

    dispatch(
      getInventory({
        date_from: dateFrom,
        // for PERIOD_DAY DatePickerPeriodSelector is shown where we do have the paginated loading
        // for other periods we show the regular select and fetch all available periods
        ...(productPeriod === PERIOD_DAY && { date_to: formatDateToBE(dateTo) }),
        media_product: productId,
        // passing product is product ID from media order, this will ask BE to respond with additional
        // product_booked_quantity field which is equalent to the quantity of booked periods within the media order
        ...(mediaOrderProductId && { product: mediaOrderProductId }),
        currency: currency.code
      })
    )
  }, [dispatch, productId, productPeriod, currency.code, mediaOrderProductId])

  useEffect(() => {
    // reset quantity to 1 when user is setting up periods to avoid inconsistency when there are not enough
    // inventory for the selected quantity
    setFieldValue(`${[ADDED_PRODUCT]}.${[QUANTITY]}`, 1)
  }, [setFieldValue, productPeriods])

  useEffect(() => {
    return () => {
      dispatch(clearInventory())
    }
  }, [dispatch])

  return (
    <>
      <ProductDetails productData={selectedProduct} currency={currency} />
      <FieldsSection title="Booking dates">
        <FieldRow
          title={selectedProductCategory === PRINT ? 'Publications' : 'Booking Periods'}
          description="Select one or more delivery date periods."
        >
          <SelectPeriodsFieldsContextProvider
            product={selectedProduct}
            currency={currency}
            allowToFetchPreviousPeriods={true}
          >
            <SelectPeriodsFields
              formik={formik}
              productItemPath={ADDED_PRODUCT}
              error={errors[ADDED_PRODUCT]?.[PRODUCT_PERIODS_DATES]}
              showError={!!touched[ADDED_PRODUCT]?.[PRODUCT_PERIODS_DATES]}
            />
          </SelectPeriodsFieldsContextProvider>
        </FieldRow>
      </FieldsSection>
      <FieldsSection title="Quantity">
        <FieldRow title={'Product Quantity'}>
          {/* ProductQuantity wasn't working on QUANTITY reset by periods change,
          so for simple solution the number input was used */}
          <Field
            type="number"
            formik={formik}
            name={`${[ADDED_PRODUCT]}.${[QUANTITY]}`}
            placeholder="Quantity"
            // A checker function to validate the input value. If this function returns false, the onChange method will not get triggered and the input value will not change.
            isAllowed={values => {
              const { formattedValue, floatValue } = values
              if (floatValue === null) {
                return formattedValue === ''
              } else {
                return floatValue <= maxProductQuantity && floatValue >= 1
              }
            }}
            enableReinitialize
            autoComplete="off"
            inputMode="numeric"
          />
        </FieldRow>
      </FieldsSection>
      {isAlreadySelected && (
        <ErrorMessage className={classes.sameProductWarning}>
          {t('This will replace the existing periods and pricing for this product.')}
          <br />
          {t('If you would like to include the already added periods, please include them here as well.')}
        </ErrorMessage>
      )}
      <Button type="button" onClick={formik.handleSubmit} className={classes.submitBtn}>
        {t('Add product')}
      </Button>
      <BackButton onStepBack={onBack} showButton />
    </>
  )
}

ProductSetup.propTypes = {
  formik: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object.isRequired
}
export default ProductSetup
