import { createStyles } from '../../styles/helpers'

const useStyles = createStyles({
  '@global': {
    // this style influence body tag only in scope of the landing page
    body: {
      background: '#fff'
    }
  },
  main: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0
  },
  pageContainer: {
    maxWidth: 'none'
  }
})

export default useStyles
