import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import InfoModal from '../../../../features/components/Modals/InfoModal'
import ProgressButton from '../../../../components/Form/ProgressButton'

import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning-icon.svg'

import { MEMBER_CHANGE_STATUS } from '../../../../constants/forms'

function DeactivateUserModal({
  member,
  deactivateHandler,
  isModalOpened,
  closeModalHandler,
  memberStatusWasUpdated,
  accountName,
  clearHandler
}) {
  const { t } = useTranslation()

  const { isLoading: isUpdatedMemberLoading, error: updateMemberError, updatingType } = member

  const isUpdateMemberStatusLoading = isUpdatedMemberLoading && updatingType === MEMBER_CHANGE_STATUS

  const updateMemberStatusError = updateMemberError && updatingType === MEMBER_CHANGE_STATUS

  const modalTitle = 'Are you sure?'
  const modalMessage = (
    <>
      <p>{t('deactivateUser', { accountName: accountName })}</p>
      <p>{t('You can reactivate them later if needed.')}</p>
    </>
  )

  const handleSuccessDeactivate = useCallback(() => {
    closeModalHandler()
  }, [closeModalHandler])

  return (
    <InfoModal
      Icon={WarningIcon}
      ModalMessage={modalMessage}
      isOpen={isModalOpened}
      onClose={closeModalHandler}
      modalTitle={modalTitle}
    >
      <ProgressButton
        isFormLoading={isUpdateMemberStatusLoading}
        formError={updateMemberStatusError}
        clearHandler={clearHandler}
        successSubmit={memberStatusWasUpdated}
        onSuccessSubmit={handleSuccessDeactivate}
        onClick={deactivateHandler}
      >
        {t('Deactivate')}
      </ProgressButton>
    </InfoModal>
  )
}

export default DeactivateUserModal
