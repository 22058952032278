import React, { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import StepForm from '../../../../../../../features/components/Forms/StepForm'
import FacebookPageStep from '../../Steps/FacebookPageStep'
import StoriesStep from '../../Steps/StoriesStep'

import { usePurifiedFormik } from '../../../../../../../hooks/formHooks/usePurifiedFormik'
import useCampaignDetails from '../../hooks/useCampaignDetails'
import useLineItemDetails from '../../hooks/useLineItemDetails'
import usePreselectPixel from '../../hooks/usePreselectPixel'
import getConversionDomain from '../../helpers/getConversionDomain'
import useFormattedFacebookPages from '../../hooks/useFormattedFacebookPages'
import getConversionDomainValidation from '../../helpers/getConversionDomainValidation'
import useIsInternalAdCreation from '../../../../../../../features/hooks/useIsInternalAdCreation'

import { createAd } from '../../../../../../../modules/actions/ads'
import { validatedDomainsSelector } from '../../../../../../../modules/selectors/ads'
import {
  selectedAdAccountIdSelector,
  selectedSelfAccountDataSelector
} from '../../../../../../../modules/selectors/app'
import { accessibleFacebookPagesSelector } from '../../../../../../../modules/selectors/socialAccounts'

import { transformValuesToBE } from '../../fields'
import { PIXEL_CHOICE } from '../../../../fields'
import { FACEBOOK_PLATFORM } from '../../../../../../../constants/selectLists/platformList'
import { getInitialValues } from './initialValues'
import { getValidationSchema } from './validation'

const StoryAdRoute = ({
  FormRouteSelector,
  eventSourceStep,
  adAccountId,
  isQuarantineAd,
  warningSteps,
  ...formProps
}) => {
  const dispatch = useDispatch()

  const facebookPages = useSelector(accessibleFacebookPagesSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const validatedDomains = useSelector(validatedDomainsSelector)
  const { controller: selfAccountController } = useSelector(selectedSelfAccountDataSelector)

  const { lineItemId, showAsStory } = useLineItemDetails()
  const { campaignId } = useCampaignDetails()
  const formattedFacebookPages = useFormattedFacebookPages(facebookPages)
  const isInternalAdCreation = useIsInternalAdCreation()

  const providedAdAccountId = adAccountId || selectedAdAccountId

  const onSubmit = useCallback(
    values => {
      dispatch(
        createAd(
          transformValuesToBE({
            values,
            adAccountId: providedAdAccountId,
            adSetId: lineItemId,
            campaignId,
            validatedDomains,
            isInternalAdCreation,
            selfAccountController
          }),
          FACEBOOK_PLATFORM
        )
      )
    },
    [
      dispatch,
      providedAdAccountId,
      lineItemId,
      campaignId,
      validatedDomains,
      isInternalAdCreation,
      selfAccountController
    ]
  )

  const formik = useFormik({
    initialValues: getInitialValues({ facebookPages: formattedFacebookPages }),
    validationSchema: getValidationSchema({ showProductSelectionStep: false }),
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const { values, setFieldValue } = purifiedFormik

  const selectedPixel = values[PIXEL_CHOICE]

  const conversionDomain = getConversionDomain(values)

  const { isDomainValidating } = getConversionDomainValidation(conversionDomain, validatedDomains)

  usePreselectPixel({ selectedPixel, setFieldValue })

  const steps = useMemo(
    () => [
      ...warningSteps,
      {
        component: FormRouteSelector,
        show: !showAsStory
      },
      {
        component: <FacebookPageStep adAccountId={providedAdAccountId} />,
        show: true
      },
      {
        component: <StoriesStep adAccountId={providedAdAccountId} />,
        show: true
      },
      eventSourceStep
    ],
    [FormRouteSelector, providedAdAccountId, eventSourceStep, showAsStory, warningSteps]
  )

  return <StepForm steps={steps} formik={purifiedFormik} {...formProps} isSubmitDataLoading={isDomainValidating} />
}

export default StoryAdRoute
