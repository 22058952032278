import React from 'react'
import { useTranslation } from 'react-i18next'

import LocationListCreateForm from './LocationListCreateForm'
import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'

import { LOCATION_LIST_CREATE } from '../../../../../constants/forms'

const LocationListCreate = () => {
  const { t } = useTranslation()

  return (
    <FormDrawerWrapper
      formName={LOCATION_LIST_CREATE}
      title={t('Create a new location list')}
      description={t('This location list will be made available for campaign location targeting')}
      openButtonText="Create location list"
    >
      <LocationListCreateForm />
    </FormDrawerWrapper>
  )
}

export default LocationListCreate
