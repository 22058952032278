import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

const CardFooterSkeleton = ({ cardButton }) => {
  const classes = useStyles()

  return (
    <div className={classes.footer}>
      <p className={classes.price}>
        <Skeleton width={'40px'} />
      </p>
      {cardButton && <Skeleton width={'60px'} />}
    </div>
  )
}

CardFooterSkeleton.propTypes = {
  cardButton: PropTypes.bool
}

export default CardFooterSkeleton
