import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Can from '../../../../../../features/components/Can'
import GoogleAssetsSliderPreview from '../../../../../../forms/Google/AdForms/GoogleAssetsSliderPreview'
import InternalAdActionButtons from '../../../../InternalAdActionButtons'
import ActionsDropdown from '../../../../../../features/components/ActionsDropdown'
import AdCardDetails from '../../../../../../features/components/AdCardListItem/AdCardDetails'

import usePermissions from '../../../../../../hooks/usePermissions'

import { insertIf } from '../../../../../../helpers/common'
import { getAdGoogleTransformedValues } from '../../../../../../forms/Google/AdForms/GoogleDisplayAd/AdGoogleEdit/helpers'

import { approveInternalAd } from '../../../../../../modules/actions/ads'
import { openForm } from '../../../../../../modules/actions/forms'
import {
  approveInternalAdErrorSelector,
  approveInternalAdIsLoadingSelector,
  approveInternalAdSelector,
  approveInternalAdWasApprovedSelector
} from '../../../../../../modules/selectors/ads'
import { currentUserSelfAccountsListSelector } from '../../../../../../modules/selectors/app'

import { AD_GOOGLE_EDIT } from '../../../../../../constants/forms'
import { GOOGLE_PROVIDER_PLATFORM } from '../../../../../../constants/selectLists/platformList'
import { INTERNAL_ADS_PERMISSION } from '../../../../../../constants/permissions'

import useStyles from '../../../../../../styles/common/adPreviewCard'

// this component currently support only ad approvals page style
const GoogleAdPreviewCard = ({ ad, disapproveInternalAdHandler }) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const { id: adId, account_id: selfAccountId, name: adName } = ad

  const permissions = usePermissions()

  const selfAccounts = useSelector(currentUserSelfAccountsListSelector)
  const adSelfAccountName = selfAccounts.find(({ id }) => Number(id) === Number(selfAccountId))?.name || ''

  const hasUpdateInternalAdsPermission = permissions.can('update', INTERNAL_ADS_PERMISSION)
  const hasManageInternalAdsPermission = permissions.can('manage', INTERNAL_ADS_PERMISSION)
  const transformedAssetGroupValues = useMemo(() => getAdGoogleTransformedValues(ad), [ad])

  const approveInternalAdHandler = useCallback(() => {
    dispatch(approveInternalAd(adId, GOOGLE_PROVIDER_PLATFORM))
  }, [dispatch, adId])

  const editInternalAdHandler = useCallback(() => {
    dispatch(
      openForm({
        id: adId,
        formName: AD_GOOGLE_EDIT,
        otherParams: 'internal=true'
      })
    )
  }, [dispatch, adId])

  const actionsDropdownOptions = useMemo(() => {
    return [
      ...insertIf(hasManageInternalAdsPermission, {
        text: 'Approve',
        onClickHandler: approveInternalAdHandler
      }),
      ...insertIf(hasManageInternalAdsPermission, {
        text: 'Disapprove',
        onClickHandler: disapproveInternalAdHandler
      }),
      ...insertIf(hasUpdateInternalAdsPermission, {
        text: 'Edit',
        onClickHandler: editInternalAdHandler
      })
    ]
  }, [
    disapproveInternalAdHandler,
    approveInternalAdHandler,
    editInternalAdHandler,
    hasManageInternalAdsPermission,
    hasUpdateInternalAdsPermission
  ])

  return (
    <div className={classes.adPreviewCard}>
      {!!actionsDropdownOptions.length && (
        <ActionsDropdown
          itemId={ad.id}
          options={actionsDropdownOptions}
          className={classes.actionsDropdownMenu}
          closeOnInsideClick
        />
      )}
      <div className={classes.cardSliderPreviewWrapper}>
        <GoogleAssetsSliderPreview values={transformedAssetGroupValues} showSearchPreview={false} />
      </div>
      <AdCardDetails title={adName} subTitle={adSelfAccountName} />
      <Can I="manage" a={INTERNAL_ADS_PERMISSION}>
        <InternalAdActionButtons
          className={classes.actionBtnsContainer}
          adId={adId}
          approveInternalAdSelector={approveInternalAdSelector}
          approveInternalAdIsLoadingSelector={approveInternalAdIsLoadingSelector}
          approveInternalAdErrorSelector={approveInternalAdErrorSelector}
          approveInternalAdWasApprovedSelector={approveInternalAdWasApprovedSelector}
          approveInternalAdHandler={approveInternalAdHandler}
          disapproveInternalAdHandler={disapproveInternalAdHandler}
        />
      </Can>
    </div>
  )
}

export default GoogleAdPreviewCard
