import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Dropdown from '../../../../components/Dropdown'
import Button from '../../../../components/Button'

import { ReactComponent as ArrowIcon } from '../../../../assets/icons/chevron-arrow-down.svg'
import { ReactComponent as MetaIcon } from '../../../../assets/logos/meta-icon.svg'
import { ReactComponent as GoogleIcon } from '../../../../assets/logos/google-icon.svg'
import { ReactComponent as TikTokIcon } from '../../../../assets/logos/tt-icon.svg'
import { ReactComponent as DV360Icon } from '../../../../assets/logos/dv360/dv360-platform-display.svg'

import { openForm } from '../../../../modules/actions/forms'
import { selectedControllerDataSelector } from '../../../../modules/selectors/app'

import {
  UPLOAD_DV360_AD_CREATIVE,
  UPLOAD_FACEBOOK_CREATIVE,
  UPLOAD_GOOGLE_AD_CREATIVE,
  UPLOAD_TIKTOK_AD_CREATIVE
} from '../../../../constants/forms'
import {
  DV_360_PROVIDER_PLATFORM,
  FACEBOOK_PROVIDER_PLATFORM,
  GOOGLE_PROVIDER_PLATFORM,
  TIKTOK_PROVIDER_PLATFORM
} from '../../../../constants/selectLists/platformList'

import useStyles from './styles'

function OpenUploadCreativeFormTrigger({ availableAdAccounts }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const showFacebookButton = !!availableAdAccounts[FACEBOOK_PROVIDER_PLATFORM]
  const showGoogleButton = !!availableAdAccounts[GOOGLE_PROVIDER_PLATFORM]
  const showTikTokButton = !!availableAdAccounts[TIKTOK_PROVIDER_PLATFORM]
  const showDV360Button = !!availableAdAccounts[DV_360_PROVIDER_PLATFORM]

  const selectedSelfAccountController = useSelector(selectedControllerDataSelector)
  const controllerName = selectedSelfAccountController?.name || ''

  const openDrawerForm = useCallback(
    formName => {
      dispatch(openForm({ formName }))
    },
    [dispatch]
  )

  return (
    <Dropdown
      className={classes.dropdownBody}
      placement="bottom-start"
      closeOnInsideClick
      triggerElement={
        <Button solid>
          {t('Create Campaign')} <ArrowIcon className={classes.arrowIcon} />
        </Button>
      }
    >
      <div className={classes.dropdownContainer}>
        {showFacebookButton && (
          <div className={classes.dropdownItem} onClick={() => openDrawerForm(UPLOAD_FACEBOOK_CREATIVE)}>
            <div className={classes.dropdownItemMainPart}>
              <MetaIcon className={classes.platformIcon} />
              Facebook & Instagram
            </div>
            <ArrowIcon className={classes.dropdownItemArrowIcon} />
          </div>
        )}
        {showGoogleButton && (
          <div className={classes.dropdownItem} onClick={() => openDrawerForm(UPLOAD_GOOGLE_AD_CREATIVE)}>
            <div className={classes.dropdownItemMainPart}>
              <GoogleIcon className={classes.platformIcon} />
              Display, search and YouTube
            </div>
            <ArrowIcon className={classes.dropdownItemArrowIcon} />
          </div>
        )}
        {showTikTokButton && (
          <div className={classes.dropdownItem} onClick={() => openDrawerForm(UPLOAD_TIKTOK_AD_CREATIVE)}>
            <div className={classes.dropdownItemMainPart}>
              <TikTokIcon className={classes.platformIcon} />
              TikTok
            </div>
            <ArrowIcon className={classes.dropdownItemArrowIcon} />
          </div>
        )}
        {showDV360Button && (
          <div className={classes.dropdownItem} onClick={() => openDrawerForm(UPLOAD_DV360_AD_CREATIVE)}>
            <div className={classes.dropdownItemMainPart}>
              <DV360Icon className={classes.platformIcon} />
              {`${controllerName} Display Network`}
            </div>
            <ArrowIcon className={classes.dropdownItemArrowIcon} />
          </div>
        )}
      </div>
    </Dropdown>
  )
}

export default OpenUploadCreativeFormTrigger
