import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import TextSuggestions from '../../../../../../../../features/components/Form/TextSuggestions'
import Field from '../../../../../../../../components/Form/Field'

import { googleAdSuggestionsIsLoadingSelector } from '../../../../../../../../modules/selectors/tools'

import { LONG_HEADLINE } from '../../../../../GoogleDisplayAd/DisplayAdGoogleCreateForm/fields'

function LongHeadlineSection({ formik, suggestions }) {
  const suggestionsLoading = useSelector(googleAdSuggestionsIsLoadingSelector)

  const { setFieldValue } = formik

  const handleLongHeadlineTextChange = useCallback(
    selectedChange => {
      setFieldValue(LONG_HEADLINE, selectedChange)
    },
    [setFieldValue]
  )

  return (
    <FieldRow
      title="Long Headline"
      description="90 characters or less"
      footer={
        <TextSuggestions
          showLogo={false}
          selectedSuggestion={null}
          suggestions={suggestions}
          onSelectSuggestion={handleLongHeadlineTextChange}
          typingAnimation={suggestionsLoading}
        />
      }
    >
      <Field
        isTextarea
        ignoreBlur
        placeholder="Long Headline"
        enableReinitialize
        name={LONG_HEADLINE}
        formik={formik}
        maxLength={90}
      />
    </FieldRow>
  )
}

LongHeadlineSection.propTypes = {
  suggestions: PropTypes.array
}

export default LongHeadlineSection
