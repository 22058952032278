import { madeRequest } from '../../helpers/api'
import { ENDPOINTS } from '../../constants/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getMediaProductLocationService({ id, ...params }) {
  return madeRequest('GET', {
    params,
    url: `${ENDPOINTS.mediaProductLocations}${id}/`
  })
}

export function getMediaProductLocationsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.mediaProductLocations
  })
}

export function createMediaProductLocationService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.mediaProductLocations
  })
}

export function updateMediaProductLocationService(data, id) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.mediaProductLocations}${id}/`
  })
}

export function getMediaProductAttachedLocationsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.mediaProductAttachedLocations
  })
}

export function getBookedMediaAttachedLocationsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.bookedMediaAttachedLocations
  })
}

export function getProductStoresService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.mediaProductStores
  })
}
