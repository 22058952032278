import * as Yup from 'yup'

import { AD_TEXT, LANDING_PAGE_URL, VIDEO } from '../../../../AdForms/fields'
import { CALL_TO_ACTION } from '../../../../../ReusableFormFields/CallToActionFields/fields'
import {
  IDENTITY_ID,
  initialTikTokVideoFileValues,
  NAME
} from '../../../../AdForms/AdTikTokCreate/AdTikTokCreateForm/fields'
import { tikTokAdTextValidation } from '../../../../AdForms/validation'
import { FILE_ID, FILE_NAME } from '../../../../../ReusableFormFields/AdFileUpload/fields'
import { SHOP_NOW } from '../../../../../../constants/selectLists/tikTokCallToActionsList'
import { initialLink } from '../../../../../Facebook/AdForms/fields'

export const initialValues = {
  [NAME]: '',
  [LANDING_PAGE_URL]: '',
  [AD_TEXT]: '',
  [CALL_TO_ACTION]: SHOP_NOW,
  [LANDING_PAGE_URL]: initialLink,
  ...initialTikTokVideoFileValues
}
export const getValidationSchema = ({ showProductSelection }) => {
  return Yup.object({
    [AD_TEXT]: tikTokAdTextValidation,
    [CALL_TO_ACTION]: Yup.string().required('Please select Call to action'),
    [LANDING_PAGE_URL]: Yup.string().required(
      showProductSelection ? 'Please select product for page URL' : 'Please enter page URL'
    ),
    [FILE_ID]: Yup.string().required('Please select a video file')
  })
}

export const transformValuesToBE = ({
  values,
  account,
  campaignId,
  lineItemId,
  controllerId,
  isInternalAdCreation,
  identitiesList,
  productsList,
  tikTokVideoThumbnails,
  selectedSelfAccountId,
  showProductSelection
}) => {
  // currently the selected identity is the first one in the list
  const selectedIdentity = identitiesList[0]
  // display_name is set the same as selected identity name
  const identityName = selectedIdentity.display_name

  // depending on custom_click_through_link field we allow either to select a product or enter a link
  const link = showProductSelection
    ? productsList.find(product => product.id === values[LANDING_PAGE_URL])?.link
    : values[LANDING_PAGE_URL]

  const fileName = values[FILE_NAME]
  const fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.')

  return {
    account,
    account_id: selectedSelfAccountId,
    campaign_id: campaignId,
    adgroup_id: lineItemId,
    ad_format: 'SINGLE_VIDEO',
    [NAME]: fileNameWithoutExtension,
    [IDENTITY_ID]: selectedIdentity?.identity_id,
    display_name: identityName,
    identity_type: 'CUSTOMIZED_USER',
    [CALL_TO_ACTION]: values[CALL_TO_ACTION],
    [LANDING_PAGE_URL]: link,
    ad_text: values[AD_TEXT],
    [VIDEO]: values[FILE_ID],
    image_urls: tikTokVideoThumbnails,
    ...(isInternalAdCreation && {
      quarantine: true,
      ...(controllerId && { controller_id: controllerId })
    })
  }
}
