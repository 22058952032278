import { getYoutubeVideoId } from './helpers'

import { googleAssetFieldType } from '../../../../constants/ads'
import {
  ASSET_GROUP_TYPE,
  ASSET_ID,
  ASSET_TYPE,
  DESCRIPTIONS_LIST,
  HEADLINES_LIST,
  LONG_HEADLINES_LIST,
  VIDEO_ADS_URL
} from '../fields'

export const formatYoutubeLinkToAsset = ({ links, assets, adAccountId }) => {
  links.forEach(link => {
    assets.push({
      account: adAccountId,
      youtube_video_asset: {
        youtube_video_id: getYoutubeVideoId(link.text)
      },
      [ASSET_GROUP_TYPE]: googleAssetFieldType.YOUTUBE_VIDEO
    })
  })
}

export const formatTextValuesToAssetsCreation = ({ values, adAccountId, allowIncludeYoutubeLink }) => {
  const assets = []

  const formatTextListToAsset = (textList, assetType) => {
    textList &&
      textList.forEach(text => {
        // name field is set having possibility to differentiate asset type according to AssetGroup asset field_type
        assets.push({ account: adAccountId, text_asset: text, [ASSET_GROUP_TYPE]: assetType })
      })
  }

  formatTextListToAsset(values[HEADLINES_LIST], googleAssetFieldType.HEADLINE)
  formatTextListToAsset(values[DESCRIPTIONS_LIST], googleAssetFieldType.DESCRIPTION)
  formatTextListToAsset(values[LONG_HEADLINES_LIST], googleAssetFieldType.LONG_HEADLINE)

  allowIncludeYoutubeLink &&
    formatYoutubeLinkToAsset({
      links: values[VIDEO_ADS_URL] || [],
      assets,
      adAccountId
    })

  return assets
}

export const formatImageListToAssets = (imageList, assetType) => {
  const assets = []

  imageList.forEach(image =>
    assets.push({
      asset: image[ASSET_ID],
      [ASSET_TYPE]: assetType,
      // hardcode status for 2 - ENABLED(AssetLinkStatus)
      status: 2
    })
  )

  return assets
}
