import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import usePermissions from '../../../../hooks/usePermissions'

import {
  // redirectTo,
  replaceRouteParams
} from '../../../../helpers/url'
import { insertIf } from '../../../../helpers/common'
import { getLineItemSummaryLinkText } from '../../../../constants/lineItems'

import { openForm } from '../../../../modules/actions/forms'
import { copyAd } from '../../../../modules/actions/ads'
// import { setLineItemTriggersFilter } from '../../../../modules/actions/triggers'
import { selectedPlatformSelector, selectedAdAccountIdSelector } from '../../../../modules/selectors/app'
import { campaignSelector } from '../../../../modules/selectors/campaigns'
import { selectedAdForCopySelector } from '../../../../modules/selectors/ads'

import { ROUTES } from '../../../../constants/routes'
import { FACEBOOK_PLATFORM, GOOGLE_PLATFORM, TIKTOK_PLATFORM } from '../../../../constants/selectLists/platformList'
import {
  LINE_ITEM_FACEBOOK_DUPLICATE,
  LINE_ITEM_FACEBOOK_EDIT,
  LINE_ITEM_GOOGLE_ADS_EDIT,
  LINE_ITEM_TIKTOK_EDIT
} from '../../../../constants/forms'
import { AD_PERMISSION, LINE_ITEM_PERMISSION } from '../../../../constants/permissions'

const useGetLineItemActionsDropdownOptions = ({ handleDeleteRequest }) => {
  const dispatch = useDispatch()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const selectedPlatform = useSelector(selectedPlatformSelector)
  const campaign = useSelector(campaignSelector)
  const selectedAdForCopy = useSelector(selectedAdForCopySelector)

  const permissions = usePermissions()

  const { id: campaignId, objective: campaignObjective } = campaign
  const adForCopyId = selectedAdForCopy?.id

  const editFormName = useMemo(() => {
    switch (selectedPlatform) {
      case FACEBOOK_PLATFORM:
        return LINE_ITEM_FACEBOOK_EDIT
      case GOOGLE_PLATFORM:
        return LINE_ITEM_GOOGLE_ADS_EDIT
      case TIKTOK_PLATFORM:
        return LINE_ITEM_TIKTOK_EDIT
      default:
        return ''
    }
  }, [selectedPlatform])

  return useCallback(
    lineItem => {
      const {
        id,
        // name,
        status
      } = lineItem

      const isItemArchived = status && status.toLowerCase() === 'archived'

      const lineItemSummaryLink = replaceRouteParams(ROUTES.lineItemsSummary, {
        line_item_id: id,
        campaign_id: campaignId
      })

      const deleteLineItemHandler = () => {
        handleDeleteRequest({
          item: lineItem,
          account: selectedAdAccountId,
          campaignObjective: campaignObjective
        })
      }

      const openEditFormHandler = () => {
        dispatch(openForm({ id, formName: editFormName }))
      }

      // const triggersHandler = () => {
      //   dispatch(setLineItemTriggersFilter(id, name))
      //
      //   redirectTo(ROUTES.manageTriggers)
      // }

      const pasteAdHandler = () => {
        const data = {
          account: selectedAdAccountId,
          adset_id: id
        }

        dispatch(copyAd(data, adForCopyId))
      }

      const duplicateLineItemHandler = () => {
        dispatch(openForm({ id, formName: LINE_ITEM_FACEBOOK_DUPLICATE }))
      }

      return [
        {
          text: getLineItemSummaryLinkText(selectedPlatform),
          link: lineItemSummaryLink
        },
        ...insertIf(permissions.can('delete', LINE_ITEM_PERMISSION), {
          text: isItemArchived || selectedPlatform !== FACEBOOK_PLATFORM ? 'Delete' : 'Archive / Delete',
          onClickHandler: deleteLineItemHandler
        }),
        ...insertIf(permissions.can('update', LINE_ITEM_PERMISSION), {
          text: 'Edit',
          onClickHandler: openEditFormHandler
        }),
        // todo uncomment this option when adding Triggers back on settings page
        // {
        //   text: 'Triggers',
        //   onClickHandler: triggersHandler
        // },
        ...insertIf(
          selectedPlatform === FACEBOOK_PLATFORM && !!adForCopyId && permissions.can('create', AD_PERMISSION),
          {
            text: 'Paste Ad',
            onClickHandler: pasteAdHandler
          }
        ),
        ...insertIf(selectedPlatform === FACEBOOK_PLATFORM && permissions.can('create', LINE_ITEM_PERMISSION), {
          text: 'Duplicate',
          onClickHandler: duplicateLineItemHandler
        })
      ]
    },
    [
      dispatch,
      permissions,
      selectedAdAccountId,
      selectedPlatform,
      handleDeleteRequest,
      campaignId,
      campaignObjective,
      editFormName,
      adForCopyId
    ]
  )
}

export default useGetLineItemActionsDropdownOptions
