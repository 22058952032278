import * as Yup from 'yup'
import {
  BRAND_SHOPPERS,
  CATEGORY_SHOPPERS,
  CUSTOM_DAYS,
  LIFESTYLE_CATEGORIES,
  ORIGIN_OPTION,
  SHOPPER_TYPE_OPTION,
  SUPERMARKET,
  YOUR_WEBSITE
} from './fields'

export const validationSchema = Yup.object({
  [CUSTOM_DAYS]: Yup.number().when(ORIGIN_OPTION, {
    is: YOUR_WEBSITE,
    then: () => Yup.number().max(180, 'Maximum number of days - 180'),
    otherwise: () => Yup.number().max(365, 'Maximum number of days - 365')
  }),
  [BRAND_SHOPPERS]: Yup.array().when(ORIGIN_OPTION, {
    is: SUPERMARKET,
    then: () =>
      Yup.array().when(SHOPPER_TYPE_OPTION, {
        is: BRAND_SHOPPERS,
        then: () => Yup.array().min(1, 'Please select at least one option')
      })
  }),
  [CATEGORY_SHOPPERS]: Yup.array().when(ORIGIN_OPTION, {
    is: SUPERMARKET,
    then: () =>
      Yup.array().when(SHOPPER_TYPE_OPTION, {
        is: CATEGORY_SHOPPERS,
        then: () => Yup.array().min(1, 'Please select at least one option')
      })
  }),
  [LIFESTYLE_CATEGORIES]: Yup.array().when(ORIGIN_OPTION, {
    is: SUPERMARKET,
    then: () =>
      Yup.array().when(SHOPPER_TYPE_OPTION, {
        is: LIFESTYLE_CATEGORIES,
        then: () => Yup.array().min(1, 'Please select at least one option')
      })
  })
})
