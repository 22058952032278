import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import MultiSelectBox from '../../../../../../../../features/components/Form/MultiSelectBox'
import Checkbox from '../../../../../../../../components/Form/Checkbox'
import MediaProductsPaginatedMultiSelect from '../../../../../components/MediaProductsPaginatedMultiSelect'

import { mediaCategories } from '../../../../../../../../constants/mediaOrders'
import { MEDIA_CATEGORIES, MEDIA_PRODUCTS } from '../../../../../fields'
import { MEDIA_ITEMS_OPTION, USER_CAN_SELECT_PRODUCTS_FROM_LIST } from '../../../fields'

import useStyles from './styles'

const PackageContentDropdown = ({ formik }) => {
  const classes = useStyles()

  const { setFieldValue, values, errors, touched } = formik

  const showUserCanSelectProductsCheckbox = values[MEDIA_PRODUCTS].length > 1

  const userCanSelectProductsFromList = values[USER_CAN_SELECT_PRODUCTS_FROM_LIST]
  const selectedProductsList = values[MEDIA_PRODUCTS]

  const mediaItemsOption = values[MEDIA_ITEMS_OPTION]

  useEffect(() => {
    // We show userCanSelectProductFromList checkbox only if the user selects more than one product
    // If checkbox is checked and the user removes second product from the list, we uncheck the checkbox
    if (userCanSelectProductsFromList && selectedProductsList.length === 1) {
      setFieldValue(USER_CAN_SELECT_PRODUCTS_FROM_LIST, false)
    }
  }, [setFieldValue, userCanSelectProductsFromList, selectedProductsList])

  return mediaItemsOption === MEDIA_CATEGORIES ? (
    <MultiSelectBox
      placeholder="Media categories"
      setFieldValue={setFieldValue}
      value={values[MEDIA_CATEGORIES]}
      name={MEDIA_CATEGORIES}
      options={mediaCategories}
      error={errors[MEDIA_CATEGORIES]}
      touched={touched[MEDIA_CATEGORIES]}
    />
  ) : (
    <>
      <MediaProductsPaginatedMultiSelect formik={formik} />
      {showUserCanSelectProductsCheckbox && (
        <Checkbox
          id="user_can_select_products_from_lsit"
          title={'User can select product(s) from this list '}
          className={classes.userCanSelectProductsCheckbox}
          checked={values[USER_CAN_SELECT_PRODUCTS_FROM_LIST]}
          onCheck={() => setFieldValue(USER_CAN_SELECT_PRODUCTS_FROM_LIST, !values[USER_CAN_SELECT_PRODUCTS_FROM_LIST])}
        />
      )}
    </>
  )
}

PackageContentDropdown.propTypes = {
  formik: PropTypes.object.isRequired
}

export default PackageContentDropdown
