import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getIn } from 'formik'

import BrandsContent from '../../../reusalbeFormFields/BrandsContent'
import Field from '../../../../../../../components/Form/Field'
import FieldsSection from '../../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'
import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import MultiSelectBox from '../../../../../../../features/components/Form/MultiSelectBox'
import CustomClickThoughLinkContent from './CustomClickThoughLinkContent'

import { tagsSelector, tagsIsLoadingSelector } from '../../../../../../../modules/selectors/tags'
import { isUserControllerSelector, selectedControllerDataSelector } from '../../../../../../../modules/selectors/app'
import { controllerMembersSelector } from '../../../../../../../modules/selectors/controller'

import { insertIf } from '../../../../../../../helpers/common'
import { formatOptionsList } from '../../../../../../../features/formatters'

import { NAME } from '../../../fields'
import { TAGS, BRANDS, CURRENCY, CUSTOMER_ID } from '../fields'
import currenciesList from '../../../../../../../constants/selectLists/currencies'
import { REPRESENTATIVE } from '../../../../../../../constants/mediaOrders'

function AccountDetailsStep({ formik, handleStepChange, isLastStep }) {
  const { t } = useTranslation()

  const tags = useSelector(tagsSelector)
  const tagsIsLoading = useSelector(tagsIsLoadingSelector)
  const controllerMembers = useSelector(controllerMembersSelector)
  const selectedController = useSelector(selectedControllerDataSelector)
  const isUserController = useSelector(isUserControllerSelector)

  const selectedControllerName = selectedController?.name || ''

  const formattedControllerMembers = useMemo(
    () =>
      formatOptionsList({
        list: controllerMembers,
        labelName: 'full_name',
        valueName: 'user'
      }),
    [controllerMembers]
  )

  const { values, errors, setFieldValue } = formik

  const handleContinue = () => {
    const stepFields = [NAME, BRANDS, ...insertIf(isUserController, TAGS), CURRENCY]
    handleStepChange(stepFields, values)
  }

  const handleCreateTag = newTag => {
    const { values, setFieldValue } = formik
    setFieldValue(TAGS, [...values[TAGS], { value: newTag, label: newTag }])
  }

  const tagOptions = useMemo(() => {
    return formatOptionsList({
      list: tags,
      labelName: 'title',
      valueName: 'title'
    })
  }, [tags])

  return (
    <Step
      stepDescription={t('Please provide the account name and the brand name(s) for this new advertiser account')}
      stepTitle={t('Enter the new account details')}
      handleContinue={isLastStep ? null : handleContinue}
    >
      <FieldsSection title={t('Advertiser account details')}>
        <FieldRow title={t('Account name')} description={t('The advertising client’s trading name')}>
          <Field formik={formik} name={NAME} placeholder="Account name" autoComplete="off" />
        </FieldRow>
        <FieldRow
          title={t('Brand(s)')}
          description={t('The brand or brands that will be advertised through this account')}
          footer={<BrandsContent formik={formik} />}
        />
        {isUserController && (
          <>
            <FieldRow
              title={t('Tags')}
              description={t(
                'Add category labels to this account, which will make it easier to group accounts and apply bulk actions'
              )}
            >
              <MultiSelectBox
                placeholder={t('Add tags')}
                name={TAGS}
                onCreateOption={handleCreateTag}
                options={tagOptions}
                value={getIn(values, TAGS)}
                setFieldValue={setFieldValue}
                error={errors[TAGS]}
                touched
                isLoading={tagsIsLoading}
              />
            </FieldRow>
            <FieldRow
              title={t('representative', { controllerName: selectedControllerName })}
              description="The default account manager for this advertiser."
            >
              <Field
                formik={formik}
                name={REPRESENTATIVE}
                placeholder="Representative"
                options={formattedControllerMembers}
                showClearInputIcon={Boolean(values[REPRESENTATIVE])}
              />
            </FieldRow>
            <FieldRow title="Customer ID" description="An internal ID to identify this customer">
              <Field formik={formik} name={CUSTOMER_ID} placeholder="Customer ID" autoComplete="off" />
            </FieldRow>
          </>
        )}
        <FieldRow
          title={t('Advertiser Currency')}
          description={t(
            'The currency that this partner will be billed in. This must match their ad account currencies.'
          )}
        >
          <Field formik={formik} name={CURRENCY} options={currenciesList} placeholder="Currency" autoComplete="off" />
        </FieldRow>
        {isUserController && <CustomClickThoughLinkContent formik={formik} />}
      </FieldsSection>
    </Step>
  )
}

export default AccountDetailsStep
