import { NAME, CPM_BID, SET_CPM_BID } from './fields'

import { OPTION_YES } from '../../../../../../constants/forms'

export const transformValuesToBE = values => {
  return {
    [NAME]: values[NAME],
    ...(values[SET_CPM_BID] === OPTION_YES && { [CPM_BID]: values[CPM_BID] })
  }
}
