import React from 'react'

import Skeleton from 'react-loading-skeleton'
import FormField from '../../EditProfile/UserProfileContent/EditProfileFormContent/FormField'
import { TabletDown, TabletUp } from '../../../../components/hoc/ResponsiveRendering'

import { inputHeight } from '../../../../styles/common/components'
import useStyles from '../../EditProfile/styles'

const ManageControllerFormSkeleton = () => {
  const classes = useStyles()

  return (
    <>
      <TabletUp>
        <FormField label="Meta Business Manager ID">
          <Skeleton height={inputHeight} />
        </FormField>
        <FormField label="Meta Dataset ID">
          <Skeleton height={inputHeight} />
        </FormField>
        <FormField label="Require 2 factor authentication" isLastFormField>
          <Skeleton height={inputHeight} />
        </FormField>
      </TabletUp>

      <TabletDown>
        <div className={classes.formRow}>
          <Skeleton height={inputHeight} />
          <Skeleton height={inputHeight} />
        </div>
      </TabletDown>
    </>
  )
}

export default ManageControllerFormSkeleton
