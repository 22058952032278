export const CAMPAIGN_FILTERS_DEFAULT = 'default'
export const CAMPAIGN_FILTERS_ACTIVE = 'active'
export const CAMPAIGN_FILTERS_PAUSED = 'paused'
export const CAMPAIGN_CUSTOM_RANGE_VALUE = 'custom'
export const LIFETIME_DATE_PRESET = 'lifetime'
export const DATE_STATIC_PRESET = 'date_preset'

export const campaignFiltersList = [
  {
    value: CAMPAIGN_FILTERS_DEFAULT,
    label: 'Active & Paused'
  },
  {
    value: CAMPAIGN_FILTERS_ACTIVE,
    label: 'Active'
  },
  {
    value: CAMPAIGN_FILTERS_PAUSED,
    label: 'Paused'
  },
  {
    value: 'archived',
    label: 'Archived'
  },
  {
    value: 'deleted',
    label: 'Deleted'
  }
]

export const campaignSortList = [
  {
    value: 'impressions',
    sort_reverse: false,
    label: 'Impressions (Low to High)'
  },
  {
    value: 'impressions_reverse',
    sort_reverse: true,
    label: 'Impressions (High to Low)'
  },
  {
    value: 'cpm',
    sort_reverse: false,
    label: 'CPM (Low to High)'
  },
  {
    value: 'cpm_reverse',
    sort_reverse: true,
    label: 'CPM (High to Low)'
  },
  {
    value: 'reach',
    sort_reverse: false,
    label: 'Reach (Low to High)'
  },
  {
    value: 'reach_reverse',
    sort_reverse: true,
    label: 'Reach (High to Low)'
  },
  {
    value: 'frequency',
    sort_reverse: false,
    label: 'Frequency (Low to High)'
  },
  {
    value: 'frequency_reverse',
    sort_reverse: true,
    label: 'Frequency (High to Low)'
  },
  {
    value: 'clicks',
    sort_reverse: false,
    label: 'Clicks (Low to High)'
  },
  {
    value: 'clicks_reverse',
    sort_reverse: true,
    label: 'Clicks (High to Low)'
  },
  {
    value: 'ctr',
    sort_reverse: false,
    label: 'CTR (Low to High)'
  },
  {
    value: 'ctr_reverse',
    sort_reverse: true,
    label: 'CTR (High to Low)'
  },
  {
    value: 'cpc',
    sort_reverse: false,
    label: 'CPC (Low to High)'
  },
  {
    value: 'cpc_reverse',
    sort_reverse: true,
    label: 'CPC (High to Low)'
  },
  {
    value: 'spend',
    sort_reverse: false,
    label: 'Spend (Low to High)'
  },
  {
    value: 'spend_reverse',
    sort_reverse: true,
    label: 'Spend (High to Low)'
  }
]

export const staticPeriodsList = [
  {
    value: 'today',
    label: 'Today'
  },
  {
    value: 'yesterday',
    label: 'Yesterday'
  },
  {
    value: 'this_month',
    label: 'This Month'
  },
  {
    value: 'last_month',
    label: 'Last Month'
  },
  {
    value: 'last_7d',
    label: 'Last 7d'
  },
  {
    value: 'last_14d',
    label: 'Last 14d'
  },
  {
    value: 'last_30d',
    label: 'Last 30d'
  },
  {
    value: 'last_90d',
    label: 'Last 90d'
  },
  {
    value: 'last_week_mon_sun',
    label: 'Last week'
  },
  {
    value: 'this_week_mon_today',
    label: 'This week'
  },
  {
    value: LIFETIME_DATE_PRESET,
    label: 'Maximum'
  }
]

export const staticPeriodsListWithCustom = [
  ...staticPeriodsList,
  { value: CAMPAIGN_CUSTOM_RANGE_VALUE, label: 'Custom' }
]
