import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import MediaOrderPrice from '../../../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/MediaOrderPrice'

import { calculateDiscountedPrice } from '../../../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/price'

function PeriodPrice({ className, productQuantity, currentDiscount, periodPrice, currencySymbol }) {
  const discountedPrice = useMemo(() => {
    if (currentDiscount) {
      return calculateDiscountedPrice(periodPrice, productQuantity, currentDiscount)
    } else {
      return null
    }
  }, [periodPrice, productQuantity, currentDiscount])

  return (
    <MediaOrderPrice
      regularPrice={periodPrice * productQuantity}
      discountedPrice={discountedPrice}
      currencySymbol={currencySymbol}
      className={className}
    />
  )
}

PeriodPrice.propTypes = {
  productQuantity: PropTypes.number.isRequired,
  currentDiscount: PropTypes.number,
  periodPrice: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  // styles
  className: PropTypes.string
}

export default PeriodPrice
