import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

import GoogleAssetGroupForm from './GoogleAssetGroupForm'

import { mediaOrderUploadCreativeDataSelector } from '../../../../../modules/selectors/mediaOrders'

import { ROUTE_PARAMS } from '../../../../../constants/routes'

const AssetGroupCreative = ({ assetGroupRouter, onSuccessSubmit, adAccountId }) => {
  const { campaign } = useSelector(mediaOrderUploadCreativeDataSelector)

  const handleSuccessUploadCreative = useCallback(() => {
    onSuccessSubmit(
      {
        [ROUTE_PARAMS.campaignId]: campaign.id
      },
      campaign['advertising_channel_type']
    )
  }, [campaign, onSuccessSubmit])

  return (
    <GoogleAssetGroupForm
      assetGroupRouter={assetGroupRouter}
      onSuccessSubmit={handleSuccessUploadCreative}
      adAccountId={adAccountId}
    />
  )
}

export default AssetGroupCreative
