import {
  FB_PAGE_CHOICE,
  FORMAT_OPTION,
  FORMAT_STORY,
  initialStoriesItem,
  IS_ITEM_FORM_OPENED,
  NAME,
  PIXEL_CHOICE,
  PRIMARY_TEXT_LIST,
  STORIES_ITEM_SELECTED_INDEX,
  STORIES_ITEMS
} from '../../../../fields'

export const getInitialValues = ({ facebookPages }) => ({
  [NAME]: '',
  [FORMAT_OPTION]: FORMAT_STORY,
  [PRIMARY_TEXT_LIST]: [{ text: '' }],
  [FB_PAGE_CHOICE]: facebookPages[0]?.value, // preselect first facebook page
  // STORIES
  [STORIES_ITEMS]: [{ ...initialStoriesItem }],
  [STORIES_ITEM_SELECTED_INDEX]: 0,
  [IS_ITEM_FORM_OPENED]: true,
  [PIXEL_CHOICE]: ''
})
