import React, { useEffect } from 'react'
import { getIn } from 'formik'
import { Portal } from 'react-portal'

import AdStoriesCard from '../../../../features/components/FacebookPreviews/AdStoriesCard'
import FacebookMediaUpload from '../../../ReusableFormFields/FacebookMediaUpload'

import { FILE_URL, STORIES_ITEMS, AD_LINK, SINGLE_MEDIA_ITEM } from '../../AdForms/fields'
import { CALL_TO_ACTION } from '../../../ReusableFormFields/CallToActionFields/fields'
import { storyMediaValidation } from '../../AdForms/AdFacebookCreate/AdFacebookCreateForm/validation/mediaValidation'

const CustomizedMedia = ({ formik, adAccountId, showPreview }) => {
  const { values, errors, touched, setFieldValue } = formik

  const itemPath = `${STORIES_ITEMS}[${0}]`

  const fileUrl = getIn(values, itemPath + `.${FILE_URL}`)

  const instagramCallToAction = getIn(values, itemPath + `.${CALL_TO_ACTION}`)
  const singleCallToAction = values[SINGLE_MEDIA_ITEM]?.[CALL_TO_ACTION]

  const instagramAdLink = getIn(values, itemPath + `.${AD_LINK}`)
  const singleAdLink = values[SINGLE_MEDIA_ITEM]?.[AD_LINK]

  const itemErrors = getIn(errors, itemPath)
  const itemTouched = getIn(touched, itemPath)
  const showFileUrlError = itemTouched?.[FILE_URL]

  const mediaUrlError = showFileUrlError && itemErrors?.[FILE_URL]

  useEffect(() => {
    if (instagramCallToAction !== singleCallToAction) {
      setFieldValue(`${STORIES_ITEMS}[0].${CALL_TO_ACTION}`, singleCallToAction)
    }
  }, [instagramCallToAction, singleCallToAction, setFieldValue])

  useEffect(() => {
    if (instagramAdLink !== singleAdLink) {
      setFieldValue(`${STORIES_ITEMS}[0].${AD_LINK}`, singleAdLink)
    }
  }, [instagramAdLink, singleAdLink, setFieldValue])

  return (
    <>
      {showPreview && (
        <Portal node={document && document.getElementById('formSideColumnPortal')}>
          <AdStoriesCard formikValues={values} elementInProgress={STORIES_ITEMS} fileUrl={fileUrl} />
        </Portal>
      )}
      <FacebookMediaUpload
        formik={formik}
        itemPath={itemPath}
        title="Story/reels image or video"
        description="Upload an image or video, or select from existed"
        mediaValidationSchema={storyMediaValidation}
        mediaUrlError={mediaUrlError}
        adAccountId={adAccountId}
        isItemActive
      />
    </>
  )
}

export default CustomizedMedia
