import { createStyles } from '../../../../../../../../../../styles/helpers'

import { mediumGrey } from '../../../../../../../../../../styles/const/colors'

export default createStyles({
  product: {
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${mediumGrey}`,
      marginBottom: '16px'
    }
  },
  fixedPriceTotal: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '16px'
  }
})
