import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Button from '../../Button'
import ItemsLoading from './ItemsLoading'

import useLoadMore from '../../../hooks/useLoadMore'

import { TABLE_CLASSNAME } from '../../../constants/pdf'

import useStyles from '../styles'

const TableDataLoader = React.memo(
  ({
    loadMore,
    className,
    itemsLength = 0,
    isLoadingSelector,
    wasLoadedSelector,
    errorSelector,
    loadersClassName,
    children,
    noDataContent,
    additionalDataIsLoading,
    skeletonProps
  }) => {
    const classes = useStyles({})

    const { t } = useTranslation()

    const itemsIsLoading = useSelector(isLoadingSelector)
    const itemsWasLoaded = useSelector(wasLoadedSelector)
    const itemsError = useSelector(errorSelector)

    const { isLoadingMoreItems, loadMoreItemsHandler, showLoadMoreButton } = useLoadMore(loadMore, itemsIsLoading)

    const itemsIsEmpty = useMemo(() => itemsLength === 0, [itemsLength])

    const isInitialLoading = useMemo(
      () => (itemsIsLoading && itemsIsEmpty) || additionalDataIsLoading,
      [itemsIsLoading, itemsIsEmpty, additionalDataIsLoading]
    )

    // filtering means that items were loaded, but new request running (some filters were changed)
    const isFilteringRunning = useMemo(() => itemsWasLoaded && itemsIsLoading, [itemsWasLoaded, itemsIsLoading])

    // show if initial loading was done and items are not filtering, or we loading more items
    const showMainContent = useMemo(
      () => (!isInitialLoading && !isFilteringRunning && !itemsIsEmpty) || isLoadingMoreItems,
      [isInitialLoading, isFilteringRunning, itemsIsEmpty, isLoadingMoreItems]
    )

    // request done, but data is empty or received error
    const showNoDataMessage = useMemo(
      () => itemsIsEmpty && (itemsWasLoaded || itemsError) && !itemsIsLoading && !additionalDataIsLoading,
      [itemsIsEmpty, itemsWasLoaded, itemsError, itemsIsLoading, additionalDataIsLoading]
    )

    if (showNoDataMessage) {
      return noDataContent
    }

    return (
      <>
        {showMainContent && (
          <div
            className={classnames(className, {
              [classes.loadingMoreMainTableWrapper]: isLoadingMoreItems
            })}
          >
            {children}
          </div>
        )}
        <ItemsLoading
          className={classnames(classes[TABLE_CLASSNAME], loadersClassName, {
            [classes.loadingMoreTable]: isLoadingMoreItems
          })}
          isLoadingMoreItems={isLoadingMoreItems}
          isInitialLoading={isInitialLoading}
          isFilteringRunning={isFilteringRunning}
          initialLoadingSkeletonsNumber={3}
          filteringLoadingSkeletonsNumber={3}
          skeletonProps={skeletonProps}
        />
        {/* don't show load more when additionalData is loading */}
        {showLoadMoreButton && !additionalDataIsLoading && (
          <Button className={classes.loadMoreBtn} onClick={loadMoreItemsHandler}>
            {t('Load more')}
          </Button>
        )}
      </>
    )
  }
)

TableDataLoader.propTypes = {
  // list all proptypes
  itemsLength: PropTypes.number,
  isLoadingSelector: PropTypes.func.isRequired,
  wasLoadedSelector: PropTypes.func.isRequired,
  errorSelector: PropTypes.func.isRequired,
  className: PropTypes.string,
  noDataContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  additionalDataIsLoading: PropTypes.bool,
  skeletonProps: PropTypes.object,
  loadMore: PropTypes.func
}

export default TableDataLoader
