import {
  CUSTOM_PRODUCT_DISCOUNT,
  CUSTOM_PRODUCT_PRICE,
  ORIGINAL_PRODUCT_PRICE,
  PRODUCT_PERIODS_DATES,
  QUANTITY
} from '../../../fields'
import { calc } from '../../../../../../../helpers/numbers'
import {
  calculateDiscountedPrice,
  getDecimalDiscount
} from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/price'
import { SORT_PARAMETERS } from '../constants'

export const getOriginalProductPrice = productValues => {
  const unitPrice = productValues[ORIGINAL_PRODUCT_PRICE]
  const totalPrice = calc(unitPrice).mul(productValues[QUANTITY]).toDP(2).toNumber()
  return totalPrice
}

export const getCustomProductPrice = productValues => {
  const customPrice = productValues[CUSTOM_PRODUCT_PRICE]
  const discount = getDecimalDiscount(productValues[CUSTOM_PRODUCT_DISCOUNT])
  // product price is already calculated with correct quantity, so for discount calculation we need to use quantity 1
  const calculatedPrice = calculateDiscountedPrice(customPrice, 1, discount)
  return calculatedPrice
}

export const getValue = (product, sortParameter) => {
  // todo move the values calculation to the context level, so we have the same values in the table and in the
  //  sorting these could also be used for copy table
  const data = product.data || {}
  switch (sortParameter) {
    case SORT_PARAMETERS.CATEGORY:
      return data.media_sub_category_name || ''
    case SORT_PARAMETERS.LOCATION:
      return data.location_name || ''
    case SORT_PARAMETERS.ASSET:
      return data.name || ''
    case SORT_PARAMETERS.DATES:
      const firstPeriodDate = product[PRODUCT_PERIODS_DATES]?.[0]?.date_start
      return firstPeriodDate && new Date(firstPeriodDate)
    case SORT_PARAMETERS.RATE:
      return getOriginalProductPrice(product)
    case SORT_PARAMETERS.COST:
      return getCustomProductPrice(product)
    default:
      return ''
  }
}
