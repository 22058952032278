import { createStyles } from '../../../../../../../../../../styles/helpers'

import { bannerGrey, darkGrey } from '../../../../../../../../../../styles/const/colors'

const previewSize = '128px'
export default createStyles(theme => ({
  productDetails: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `${previewSize} 1fr`,
    gap: '16px',
    marginBottom: 24
  },
  preview: {
    backgroundColor: bannerGrey,
    width: previewSize,
    minHeight: previewSize,
    maxHeight: previewSize,
    borderRadius: theme.defaultBorderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    cursor: 'pointer'
  },
  details: {},
  name: {
    marginBottom: '8px'
  },
  description: {
    color: darkGrey,
    marginBottom: '8px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))
