import {
  formatAirlineAudienceToBE,
  formatFacebookAudienceToBE,
  formatSupermarketAudienceToBE,
  formatYourWebsiteAudienceToBE
} from './formatters'
// AUDIENCE_ORIGIN
export const ORIGIN_OPTION = 'origin_option'
export const SUPERMARKET = 'supermarket'
export const AIRLINE = 'airline'
export const YOUR_WEBSITE = 'your_website'
export const FACEBOOK_USERS = 'facebook_users'
export const INSTAGRAM_USERS = 'instagram_users'

// SHOPPER TYPE
export const SHOPPER_TYPE_OPTION = 'shopper_type_option'
export const BRAND_SHOPPERS = 'brand_shoppers'
export const CATEGORY_SHOPPERS = 'category_shoppers'
export const COMPETITOR_BUYERS = 'competitor_buyers'
export const LAPSED_CUSTOMERS = 'lapsed_customers'
export const LIFESTYLE_CATEGORIES = 'lifestyle_categories'

// TRAVELER TYPE
export const TRAVELER_TYPE_OPTION = 'traveler_type_option'
export const SEARCHED_OR_BOOKED = 'searched_or_booked'
export const SEARCHED = 'searched'
export const CONFIRMED = 'confirmed'
export const PRE_DEPARTURE = 'pre_departure'
export const AT_AIRPORT = 'at_airport'
export const IN_DESTINATION = 'in_destination'
export const ALL_TRAVELERS = 'all_travelers'

export const DEPARTURE_DESTINATIONS = 'departure_destinations'
export const ARRIVAL_DESTINATIONS = 'arrival_destinations'
export const DESTINATIONS_OPERATOR = 'destination_operator'

export const PIXEL_CHOICE = 'pixel_choice'
export const FACEBOOK_PAGE_CHOICE = 'facebook_page_choice'
export const INSTAGRAM_ACCOUNT_CHOICE = 'instagram_account_choice'

export const TARGET_OPTION = 'target_option'
export const ANY_VISITORS = 'any_visitors'
export const SPECIFIC_VISITORS = 'specific_visitors'
export const ON_TIME_BASED_VISITORS = 'on_time_based_visitors'

export const URL_CONTAINS = 'url_contains'

export const DURATION_OPTION = 'duration_option'
export const THIRTY_DAYS_DURATION = 'thirty_days_duration'
export const BASED_ON_CHOICE_DURATION = 'based_on_choice_duration'
export const CUSTOM_DURATION = 'custom_duration'
export const CUSTOM_DAYS = 'custom_days'

// todo probably have to be refactored under one duration option
export const AIRLINE_DURATION_OPTION = 'airline_duration_option'
export const PAST_1_MONTHS = 'past 1 months'
export const PAST_3_MONTHS = 'past 3 months'
export const PAST_WEEK = 'past week'
export const PAST_2_WEEKS = 'past 2 weeks'

export const PASSENGER_STATUS_OPTION = 'passenger_status_option'
export const ALL_PASSENGERS = 'all_passengers'
export const PREMIUM_PASSENGERS = 'premium'
export const ECONOMY_PASSENGERS = 'economy'

export const NAME = 'name'

export const initialValues = {
  [ORIGIN_OPTION]: YOUR_WEBSITE,
  [SHOPPER_TYPE_OPTION]: BRAND_SHOPPERS,
  [TRAVELER_TYPE_OPTION]: SEARCHED_OR_BOOKED,
  [BRAND_SHOPPERS]: [],
  [CATEGORY_SHOPPERS]: [],
  [LIFESTYLE_CATEGORIES]: [],
  [DEPARTURE_DESTINATIONS]: [],
  [ARRIVAL_DESTINATIONS]: [],
  [DESTINATIONS_OPERATOR]: 'or',
  [PASSENGER_STATUS_OPTION]: ALL_PASSENGERS,
  [PIXEL_CHOICE]: '',
  [FACEBOOK_PAGE_CHOICE]: '',
  [INSTAGRAM_ACCOUNT_CHOICE]: '',
  [TARGET_OPTION]: ANY_VISITORS,
  [URL_CONTAINS]: [],
  [DURATION_OPTION]: THIRTY_DAYS_DURATION,
  [AIRLINE_DURATION_OPTION]: PAST_3_MONTHS,
  [NAME]: ''
}

export const transformValuesToBE = (values, account, firstPixelId) => {
  const { origin_option } = values

  switch (origin_option) {
    case SUPERMARKET:
      return formatSupermarketAudienceToBE(values, account, firstPixelId)
    case AIRLINE:
      return formatAirlineAudienceToBE(values, account, firstPixelId)
    case YOUR_WEBSITE:
      return formatYourWebsiteAudienceToBE(values, account)
    case FACEBOOK_USERS:
      return formatFacebookAudienceToBE(values, account)
    case INSTAGRAM_USERS:
      break
    default:
      return formatYourWebsiteAudienceToBE(values, account)
  }
}
