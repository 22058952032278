import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Field from '../../../../../components/Form/Field'
import Tabs from '../../../../../features/components/Tabs'
import Button from '../../../../../components/Button'
import ProgressButton from '../../../../../components/Form/ProgressButton'

import { formatOptionsList } from '../../../../../features/formatters'

import { clearMembersList, getMembersList } from '../../../../../modules/actions/selfAccountTeam'
import { selectedSelfAccountSelector } from '../../../../../modules/selectors/app'
import { membersSelector, membersIsLoadingSelector } from '../../../../../modules/selectors/selfAccountTeam'
import { clearSendAmendmentContract, deleteAmendmentContractSignature } from '../../../../../modules/actions/amendments'
import {
  sendAmendmentContractErrorSelector,
  sendAmendmentContractIsLoadingSelector,
  sendAmendmentContractWasSendSelector
} from '../../../../../modules/selectors/amendments'

import { EMAIL_RECEIVER, PLATFORM_USER_RECEIVER, receiverOptions, SEND_TO_PLATFORM_USER, USER_OPTION } from '../fields'
import { SIGNATORY_PERMISSION } from '../../../../../constants/permissions'

import useStyles from './styles'
import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

const AmendmentContractSendFormContent = ({ formik, contractId, signType, tokenParams, onSuccessSubmit }) => {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormStyles()
  const classes = useStyles()

  const dispatch = useDispatch()

  const amendmentWasSend = useSelector(sendAmendmentContractWasSendSelector)
  const isFormLoading = useSelector(sendAmendmentContractIsLoadingSelector)
  const formError = useSelector(sendAmendmentContractErrorSelector)

  const selfAccountId = useSelector(selectedSelfAccountSelector)
  const membersIsLoading = useSelector(membersIsLoadingSelector)
  const selfAccountTeamMembers = useSelector(membersSelector)

  const signatoryUsers = useMemo(() => {
    return selfAccountTeamMembers.filter(member =>
      member.permissions.find(permission => permission === SIGNATORY_PERMISSION)
    )
  }, [selfAccountTeamMembers])

  const formattedSelfAccountTeamMembers = useMemo(() => {
    return formatOptionsList({
      // show only signatory users
      list: signatoryUsers,
      labelName: 'full_name',
      valueName: 'user'
    })
  }, [signatoryUsers])

  const { values, setFieldValue } = formik

  const handleSignatureClear = useCallback(() => {
    dispatch(deleteAmendmentContractSignature({ id: contractId, params: tokenParams, signType }))
  }, [dispatch, contractId, tokenParams, signType])

  const clearItemSubmit = useCallback(() => dispatch(clearSendAmendmentContract()), [dispatch])

  useEffect(() => {
    dispatch(getMembersList({ account: selfAccountId }))
  }, [dispatch, selfAccountId])

  useEffect(() => {
    return () => {
      dispatch(clearMembersList())
    }
  }, [dispatch])

  return (
    <>
      <div className={classnames(drawerFormClasses.section, classes.clientSignatorySection)}>
        <div>
          <h4>{t('Client signatory')}</h4>
          <div className={classes.signatoryDescription}>
            {t('User or email address of the user who will countersign the Amendment')}
          </div>
        </div>
        <div className={classes.receiverTabsContainer}>
          <Tabs
            options={receiverOptions}
            selectedValue={values[USER_OPTION]}
            onSelectValue={value => setFieldValue(USER_OPTION, value)}
          />
          {values[USER_OPTION] === SEND_TO_PLATFORM_USER ? (
            <Field
              formik={formik}
              name={PLATFORM_USER_RECEIVER}
              placeholder={'Please select user'}
              options={formattedSelfAccountTeamMembers}
              isLoading={membersIsLoading}
            />
          ) : (
            <Field formik={formik} name={EMAIL_RECEIVER} placeholder="Please enter email" />
          )}
        </div>
      </div>
      <h4 className={classes.approveTitle}>{t('Ready to reserve the new products and send the Amendment?')}</h4>
      <div className={classes.buttons}>
        <Button type="button" onClick={handleSignatureClear}>
          {t('Clear signature')}
        </Button>
        <ProgressButton
          wrapperClassName={classes.submitButtonWrapper}
          type="submit"
          solid
          successSubmit={amendmentWasSend}
          isFormLoading={isFormLoading}
          formError={formError}
          onSuccessSubmit={onSuccessSubmit}
          clearHandler={clearItemSubmit}
        >
          {t('Confirm and Send')}
        </ProgressButton>
      </div>
    </>
  )
}

AmendmentContractSendFormContent.propTypes = {
  formik: PropTypes.object,
  contractId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  signType: PropTypes.string,
  tokenParams: PropTypes.object,
  onSuccessSubmit: PropTypes.func
}

export default AmendmentContractSendFormContent
