import * as Yup from 'yup'
import { FEE, FEE_OPTION } from '../fields'

import { OPTION_YES } from '../../../../constants/forms'

export const marginFeeValidation = {
  [FEE]: Yup.number().when(FEE_OPTION, {
    is: OPTION_YES,
    then: () => Yup.number().required('Fee Required').max(50, 'Maximum number of fee - 50%')
  })
}
