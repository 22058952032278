import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import ProgressButton from '../../../../components/Form/ProgressButton'

import { createdAudienceBySuggestionSelector } from '../../../../modules/selectors/audiences'

import useStyles from './styles'

function AudienceCreateButton({ suggestion, onCreateAudienceBySuggestion, suggestionTypeInProgress }) {
  const { suggestionType } = suggestion

  const { t } = useTranslation()

  const {
    error: audienceCreatedBySuggestionError,
    isLoading: isAudienceCreateBySuggestionLoading,
    wasCreated: audienceCreatedBySuggestionWasCreated
  } = useSelector(createdAudienceBySuggestionSelector)

  const isSuggestionCardInProgress = suggestionTypeInProgress === suggestionType

  const createAudienceBySuggestionHandler = useCallback(() => {
    onCreateAudienceBySuggestion(suggestionType)
  }, [onCreateAudienceBySuggestion, suggestionType])

  const classes = useStyles()

  return (
    <ProgressButton
      wrapperClassName={classes.suggestionButtonWrapper}
      className={classnames('dark', classes.suggestionButton)}
      onClick={createAudienceBySuggestionHandler}
      isFormLoading={isSuggestionCardInProgress && isAudienceCreateBySuggestionLoading}
      successSubmit={isSuggestionCardInProgress && audienceCreatedBySuggestionWasCreated}
      formError={isSuggestionCardInProgress ? audienceCreatedBySuggestionError : null}
      disabled={isAudienceCreateBySuggestionLoading}
      transparent
      solid={false}
    >
      {t('Create')}
    </ProgressButton>
  )
}

AudienceCreateButton.propTypes = {
  suggestion: PropTypes.object.isRequired,
  onCreateAudienceBySuggestion: PropTypes.func,
  suggestionTypeInProgress: PropTypes.string
}

export default AudienceCreateButton
