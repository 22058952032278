import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Portal } from 'react-portal'

import Icon from '../../../../components/Icon'
import MediaOrderCreateForm, { MEDIA_ORDER_GENERATE_FORM } from './MediaOrderCreateForm'
import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'

import { ReactComponent as ShoppingCart } from '../../../../assets/icons/shopping-cart.svg'

import useHandleNewPackageSelection from './useHandleNewPackageSelection'
import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'

import { clearGetProductTags, getProductTags } from '../../../../modules/actions/tags'
import { openForm, setFormMinimize } from '../../../../modules/actions/forms'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import { selectedMediaPackagesSelector } from '../../../../modules/selectors/mediaOrders'

import { MEDIA_ORDER_CREATE } from '../../../../constants/forms'

import useStyles from './styles'

function MediaOrderCreate() {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [activeForm, setActiveForm] = useState(null)

  const controllerId = useSelector(selectedControllerIdSelector)
  const selectedMediaPackages = useSelector(selectedMediaPackagesSelector)

  // MediaOrderGenerateForm should have grey background instead of white and don't have progress
  const isMediaOrderFormActive = activeForm === MEDIA_ORDER_GENERATE_FORM

  const { isPackageDataLoading } = useHandleNewPackageSelection()
  const { isFormOpen } = useManageFormsDrawer({ formName: MEDIA_ORDER_CREATE })

  const handleShoppingCartClick = useCallback(() => {
    if (isFormOpen) {
      // show back the form to user if it was minimized
      dispatch(setFormMinimize(false))
    } else {
      // open form which was closed after the page change
      dispatch(openForm({ formName: MEDIA_ORDER_CREATE }))
    }
  }, [dispatch, isFormOpen])

  const handleShowFormProgressChange = useCallback(formName => {
    setActiveForm(formName)
  }, [])

  const isSelectedMediaPackageSet = !!selectedMediaPackages && !!selectedMediaPackages.length

  useEffect(() => {
    // tags are used on Product select step, we load it on form open to avoid clear and re-fetch for all
    // products selection steps
    dispatch(getProductTags({ controller: controllerId }))
  }, [dispatch, controllerId])

  useEffect(() => {
    return () => {
      dispatch(clearGetProductTags())
    }
  }, [dispatch])

  return (
    <>
      <FormDrawerWrapper
        formName={MEDIA_ORDER_CREATE}
        isFormLoading={isPackageDataLoading}
        showOpenButton={false}
        drawerContentClassName={isMediaOrderFormActive ? classes.mediaOrderContent : null}
        // form progress should be hidden on last step(Contract manage) and it should be full width
        hasDefaultDrawerContentSpacing={isMediaOrderFormActive}
        isWideDrawer
      >
        <MediaOrderCreateForm onFormChange={handleShowFormProgressChange} />
      </FormDrawerWrapper>
      <Portal node={document && document.getElementById('headerSidePortal')}>
        {/* the shopping cart is represented when some of the media packages were selected */}
        {isSelectedMediaPackageSet && (
          <div className={classes.shoppingCart} onClick={handleShoppingCartClick}>
            <Icon className={classes.shoppingCartIcon}>
              <ShoppingCart />
            </Icon>
            <div className={classes.quantity}>{selectedMediaPackages.length}</div>
          </div>
        )}
      </Portal>
    </>
  )
}

export default MediaOrderCreate
