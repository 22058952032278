import { calc } from '../../../helpers/numbers'

import { DIGITAL } from '../../../constants/mediaOrders'
import {
  CHANNEL_OPTION,
  CHANNEL_OPTION_OFFSITE,
  CUSTOM_DISCOUNT_CHECKBOX,
  DISCOUNT_PAIRS,
  DISCOUNT_PERCENTAGE,
  DISCOUNT_PRESET,
  DISCOUNT_QUANTITY,
  DISCOUNTS,
  DISCOUNTS_TAB_CUSTOM,
  DISCOUNTS_TAB_PRESET,
  DISCOUNTS_TABS,
  FILE_SPECIFICATION_TYPE,
  FILE_SPECIFICATION_VALUE,
  FILE_SPECIFICATIONS_PAIRS,
  FILES_REQUIREMENTS,
  FILES_REQUIREMENTS_SUPPORTED_FORMATS,
  initialFileSpecifications,
  MEDIA_CATEGORY,
  PLATFORMS
} from './MediaProductCreate/MediaProductCreateForm/fields'
import { MEDIA_SUB_CATEGORY, MEDIA_SUB_SUB_CATEGORY } from './fields'
import { PLATFORMS_TO_PROVIDER } from '../../../constants/selectLists/platformList'

export const transformFilesToBE = values => {
  const includeFiles = values[MEDIA_CATEGORY] !== DIGITAL
  const hasFiles = values[FILES_REQUIREMENTS]?.length

  if (includeFiles && hasFiles) {
    return {
      [FILES_REQUIREMENTS]: values[FILES_REQUIREMENTS].map(
        // destruct "internalId" to skip sending it to BE. internalId is used to avoid managing id which is part of
        // BE management during the editing workflow:
        // When files are passed in the list without an id field, the system will consider them as new files and create them accordingly.
        // When files have id field, the system interprets them as existing files and proceeds to edit them.
        // When files have not included in the list will be automatically deleted by the system.
        ({
          internalId,
          // destruct file_specifications_pairs because we use this field for managing file requirements as list of pairs
          [FILE_SPECIFICATIONS_PAIRS]: fileSpecificationsPairs,
          ...fileRequirements
        }) => {
          const formattedSelectedFileSpecifications =
            // create object from array of objects
            fileSpecificationsPairs.reduce((acc, item) => {
              return {
                ...acc,
                [item[FILE_SPECIFICATION_TYPE]]: item[FILE_SPECIFICATION_VALUE]
              }
            }, {})

          return {
            ...fileRequirements,
            ...initialFileSpecifications,
            ...formattedSelectedFileSpecifications,
            // supported formats should be array of strings
            [FILES_REQUIREMENTS_SUPPORTED_FORMATS]: fileRequirements[FILES_REQUIREMENTS_SUPPORTED_FORMATS].map(
              ({ value }) => value
            )
          }
        }
      )
    }
  } else if (includeFiles && !hasFiles) {
    // this helps to support all files removal on edit form
    return {
      [FILES_REQUIREMENTS]: []
    }
  } else {
    return null
  }
}
export const formatDiscountsToBE = ({ editFormInitialValues = {}, values, discountPresets }) => {
  // This formatter is used in both create and edit form, so we need to handle the case when the form is in edit mode
  // and the discount preset is selected, then we need to reset the discounts field
  // and vice versa, when the custom discounts are selected, we need to reset the discount preset field
  const isDiscountPresetTabSelected = values[DISCOUNTS_TABS] === DISCOUNTS_TAB_PRESET
  const isCustomDiscountsTabSelected = values[DISCOUNTS_TABS] === DISCOUNTS_TAB_CUSTOM
  const isCustomDiscountsCheckboxChecked = values[CUSTOM_DISCOUNT_CHECKBOX]

  const selectedPreset = discountPresets.find(preset => preset.id === values[DISCOUNT_PRESET])

  if (isDiscountPresetTabSelected && selectedPreset) {
    // use discount preset when the tab is selected and discount preset is selected
    return {
      [DISCOUNT_PRESET]: values[DISCOUNT_PRESET],
      ...(editFormInitialValues[DISCOUNTS]?.length && { [DISCOUNTS]: [] })
    }
  } else if (isCustomDiscountsTabSelected || isCustomDiscountsCheckboxChecked) {
    // use custom discounts when the discountPresetTab is selected or the checkbox is checked
    const discountsFilled = values[DISCOUNT_PAIRS].filter(item => item[DISCOUNT_QUANTITY] && item[DISCOUNT_PERCENTAGE])

    return {
      [DISCOUNTS]: discountsFilled.map(discountPair => ({
        discount_type: 'quantity',
        value: discountPair[DISCOUNT_QUANTITY],
        [DISCOUNT_PERCENTAGE]: calc(discountPair[DISCOUNT_PERCENTAGE]).div(100).toNumber()
      })),
      ...(editFormInitialValues[DISCOUNT_PRESET] && { [DISCOUNT_PRESET]: '' })
    }
  } else {
    return {
      ...(editFormInitialValues[DISCOUNT_PRESET] && { [DISCOUNT_PRESET]: '' }),
      ...(editFormInitialValues[DISCOUNTS]?.length && {
        [DISCOUNTS]: []
      })
    }
  }
}

export const formatCategoriesToBE = values => {
  return {
    [MEDIA_CATEGORY]: values[MEDIA_CATEGORY],
    [MEDIA_SUB_CATEGORY]: values[MEDIA_SUB_CATEGORY],
    [MEDIA_SUB_SUB_CATEGORY]: values[MEDIA_SUB_SUB_CATEGORY]
  }
}

export const formatDigitalProvidersToBE = values => {
  return {
    ...(values[CHANNEL_OPTION] === CHANNEL_OPTION_OFFSITE
      ? {
          offsite: true,
          providers: values[PLATFORMS].map(item => PLATFORMS_TO_PROVIDER[item.value])
        }
      : {
          offsite: false
        })
  }
}
