import { createStyles } from '../../../styles/helpers'

import { green, mediumGrey } from '../../../styles/const/colors'
import { transitionShort } from '../../../styles/const/common'

const useStyles = createStyles({
  toggleContainer: {
    padding: '3px 4px',
    position: 'relative',
    background: mediumGrey,
    borderRadius: '11px',
    height: 23,
    minWidth: 40,
    maxWidth: 40,
    cursor: 'pointer',
    outline: 'none',
    '-webkit-tap-highlight-color': 'transparent'
  },
  isEnabledContainer: {
    background: ({ toggleColor }) => toggleColor || green
  },
  toggleBtn: {
    position: 'absolute',
    transition: transitionShort,
    display: 'inline-block',
    background: '#fff',
    borderRadius: '50%',
    left: 4,

    height: 17,
    width: 17
  },
  isEnabledBtn: {
    left: 19
  }
})

export default useStyles
