import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import FacebookAdCardList from '../../../../CampaignsSummary/SummaryPagesAdsContent/FacebookAdCardList'

import { clearApproveInternalAd } from '../../../../../modules/actions/ads'
import {
  adsErrorSelector,
  adsLoadingSelector,
  adsWasLoadedSelector,
  approveInternalAdErrorSelector,
  approveInternalAdWasApprovedSelector,
  getAdsSelector
} from '../../../../../modules/selectors/ads'

import { selectedAdAccountDataSelector } from '../../../../../modules/selectors/app'

const internalAdCardProps = {
  showStatus: false,
  showDetails: true,
  showMetrics: false,
  showActionsDropdown: true,
  showActionsButtons: true,
  isAdApprovalsStyle: true
}

const FacebookInternalAdsList = ({ disapproveInternalAdHandler }) => {
  const dispatch = useDispatch()
  const ads = useSelector(getAdsSelector)

  // pass only internal ads to FacebookAdCardList
  // when ad get approved its status changes, but it still stuck in the reducer list, it helps to clean it up
  const internalAds = ads.filter(({ effective_status }) => effective_status === 'pending_approval')

  const approveInternalAdWasApproved = useSelector(approveInternalAdWasApprovedSelector)
  const approveInternalAdError = useSelector(approveInternalAdErrorSelector)
  const selectedAdAccount = useSelector(selectedAdAccountDataSelector)

  const { currency_symbol: currencySymbol } = selectedAdAccount

  useEffect(() => {
    if (approveInternalAdWasApproved || approveInternalAdError) {
      dispatch(clearApproveInternalAd())
    }
  }, [dispatch, approveInternalAdWasApproved, approveInternalAdError])

  return (
    <FacebookAdCardList
      // list props
      adsList={internalAds}
      noDataText="There are no ads waiting for approval."
      skeletonProps={internalAdCardProps}
      // request selectors
      loadingSelector={adsLoadingSelector}
      errorSelector={adsErrorSelector}
      wasLoadedSelector={adsWasLoadedSelector}
      // cardListItem props
      currencySymbol={currencySymbol}
      {...internalAdCardProps}
      // handlers
      disapproveInternalAdHandler={disapproveInternalAdHandler}
    />
  )
}

FacebookInternalAdsList.propTypes = {
  disapproveInternalAdHandler: PropTypes.func.isRequired
}

export default FacebookInternalAdsList
