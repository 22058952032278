import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import NameCell from './NameCell'
import StatusField from './StatusField'
import AdaptiveActions from '../../components/AdaptiveActions'

import { formatStringWithUnderscore, insertIf } from '../../../helpers/common'

import { ADMIN, MANAGER, REPORT } from '../../../constants/permissions'
import { tableColumnsSize } from '../../../pages/Settings/ManageControllerTeam/ControllerMembersTable'

import { phonesDownSize } from '../../../styles/const/breakpoints'
import useStyles from './styles'

export const useMembersColumns = ({ members, onChangeMemberStatus, onChangeMemberRole, getAdditionalActions }) => {
  const classes = useStyles()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const { t } = useTranslation()

  const updateMemberRoleHandler = useCallback(
    (id, newRole) => {
      onChangeMemberRole({ id, newRole })
    },
    [onChangeMemberRole]
  )

  const changeMemberStatusHandler = useCallback(
    (e, id) => {
      const isActive = members.find(user => user.id === id)?.is_active
      onChangeMemberStatus({ id, isActive })
    },
    [members, onChangeMemberStatus]
  )

  const getDropdownOptions = useCallback(
    (role, isActive, id) => {
      return [
        ...insertIf(role !== ADMIN, {
          text: t('Make admin'),
          onClickHandler: () => updateMemberRoleHandler(id, ADMIN)
        }),
        ...insertIf(role !== MANAGER, {
          text: t('Make manager'),
          onClickHandler: () => updateMemberRoleHandler(id, MANAGER)
        }),
        ...insertIf(role !== REPORT, {
          text: t('Make reporting'),
          onClickHandler: () => updateMemberRoleHandler(id, REPORT)
        }),
        {
          text: isActive ? t('Deactivate user') : t('Activate user'),
          onClickHandler: e => changeMemberStatusHandler(e, id)
        },
        ...(getAdditionalActions ? getAdditionalActions(id) : [])
      ]
    },
    [t, getAdditionalActions, updateMemberRoleHandler, changeMemberStatusHandler]
  )

  return useMemo(
    () => [
      {
        header: 'Name',
        Cell: ({ full_name, email }) => <NameCell email={email} name={full_name} />,
        className: classes.mobileNameHeader
      },
      {
        header: 'Status',
        Cell: ({ is_active }) => <StatusField isActive={is_active} className={classes.statusMobileField} />,
        style: { maxWidth: tableColumnsSize.status },
        showOnMobile: false
      },
      {
        header: 'Role',
        Cell: ({ role = '' }) => formatStringWithUnderscore(role),
        style: { maxWidth: tableColumnsSize.role, textTransform: 'capitalize' },
        showOnMobile: false
      },
      {
        Cell: ({ role, is_active, id }) => (
          <AdaptiveActions itemId={id} options={getDropdownOptions(role, is_active, id)} />
        ),
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [getDropdownOptions, isMobile, classes]
  )
}
