import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import StatusBlock from '../../../../../features/components/StatusBlock'

import { clearAsyncSession } from '../../../../../modules/actions/app'
import { setFormStepPrevious } from '../../../../../modules/actions/forms'
import { clearDuplicateLineItem, duplicateLineItemComplete } from '../../../../../modules/actions/lineItems'
import { campaignSelector } from '../../../../../modules/selectors/campaigns'
import { asyncSessionDataSelector } from '../../../../../modules/selectors/app'

import { CAMPAIGN_OPTION, CAMPAIGN_SAME, SELECTED_CAMPAIGN } from '../fields'

import useStyles from '../../../../../styles/common/stepForms'
import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

const LineItemDuplicationProgress = ({ onSuccessSubmit, formik }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const classes = useStyles()
  const drawerFormClasses = useDrawerFormStyles()

  const asyncSessionData = useSelector(asyncSessionDataSelector)

  const { id: currentCampaignId } = useSelector(campaignSelector)

  const duplicatedLineItem = asyncSessionData.item

  const { values } = formik

  // push new item to lineItems list if lineItem was duplicated to current campaign
  const pushToList = values[CAMPAIGN_OPTION] === CAMPAIGN_SAME || values[SELECTED_CAMPAIGN] === currentCampaignId

  const status = useMemo(() => {
    switch (asyncSessionData.status) {
      case 'COMPLETED':
        return 'success'
      case 'FAILED':
        return 'error'
      default:
        return 'process'
    }
  }, [asyncSessionData.status])

  useEffect(() => {
    let timer

    // close the form after async session COMPLETED
    if (asyncSessionData.status === 'COMPLETED') {
      // Update line items list if line item was duplicated to current campaign
      if (pushToList) {
        dispatch(duplicateLineItemComplete(duplicatedLineItem))
      }

      // Wait for success loader animation before closing the form
      timer = setTimeout(() => {
        onSuccessSubmit()
      }, 700)

      // If asyncSession FAILED - jump back to the submit step and clear submitted data
    } else if (asyncSessionData.status === 'FAILED') {
      // Wait for error loader animation
      timer = setTimeout(() => {
        dispatch(clearDuplicateLineItem())
        dispatch(clearAsyncSession())
        dispatch(setFormStepPrevious())
      }, 700)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [dispatch, onSuccessSubmit, asyncSessionData.status, duplicatedLineItem, pushToList])

  return (
    <>
      <div className={classes.stepTitle}>{t('Please do not close this window while we duplicate your ad set')}</div>
      <div className={classes.stepBody}>
        <section className={drawerFormClasses.section}>
          <StatusBlock description={t('Duplicating ad set')} status={status} />
        </section>
      </div>
    </>
  )
}

export default LineItemDuplicationProgress
