import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Field from '../../../../../../../../components/Form/Field'
import RadioBlock from '../../../../../../../../components/Form/RadioBlock'
import Step from '../../../../../../../../features/components/Forms/StepForm/Step'
import LifetimeBudgetField from '../../../../../../../../forms/Multiplatform/UploadAdCreative/LifetimeBudgetField'

import { ReactComponent as OnsiteDigitalIcon } from '../../../../../../../../assets/icons/onsite-digital-icon.svg'

import { formatOptionsList } from '../../../../../../../../features/formatters'
import { useMediaOrderRemainingBudgetCalculation } from '../../../../../../../../forms/Multiplatform/MediaOrderForms/UploadAdCreative/useMediaOrderRemainingBudgetCalculation'

import { selectedControllerDataSelector, selectedPlatformSelector } from '../../../../../../../../modules/selectors/app'

import {
  BUDGET_LIFETIME,
  CAMPAIGN,
  CAMPAIGN_OPTION,
  CAMPAIGN_OPTION_EXISTING_CAMPAIGN,
  CAMPAIGN_OPTION_NEW_CAMPAIGN
} from '../../fields'
import { AUDIENCE_TARGETING } from '../../index'

import useStyles from './styles'

const stepFields = [BUDGET_LIFETIME, CAMPAIGN]

function CampaignTypeStep({ formik, handleStepChange, manageStepFormRoutes, onChooseRoute }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const selectedPlatform = useSelector(selectedPlatformSelector)
  const remainingBudget = useMediaOrderRemainingBudgetCalculation()

  const selectedSelfAccountController = useSelector(selectedControllerDataSelector)
  const controllerName = selectedSelfAccountController?.name || ''

  const formattedCampaigns = formatOptionsList({
    list: [],
    valueName: 'id',
    labelName: 'name'
  })

  const { values, setFieldValue } = formik

  const { selectedRoute, handleSelectRoute, routeWasSelected } = manageStepFormRoutes
  // chooseRoute just handle the UI and selectedRoute manage actual route
  const [chooseRoute, setChooseRoute] = useState(selectedRoute)

  const handleContinue = useCallback(() => {
    if (selectedRoute === chooseRoute) {
      // route wasn't change
      handleStepChange(stepFields)
    } else {
      // selected new route
      handleSelectRoute(chooseRoute)
    }
  }, [handleStepChange, handleSelectRoute, chooseRoute, selectedRoute])

  const handleChooseRoute = useCallback(
    route => {
      setChooseRoute(route)
      onChooseRoute && onChooseRoute(route)
    },
    [onChooseRoute]
  )

  useEffect(() => {
    routeWasSelected && handleStepChange()
    // eslint-disable-next-line
  }, [])

  return (
    <Step
      stepDescription={t('learnMoreCampaigns', { controllerName })}
      stepTitle={t('Choose your onsite digital campaign type')}
      handleContinue={handleContinue}
    >
      <RadioBlock
        setFieldValue={(fieldName, route) => handleChooseRoute(route)}
        id="campaign_option_new_campaign"
        name={CAMPAIGN_OPTION}
        label={t('Sponsored search')}
        value={CAMPAIGN_OPTION_NEW_CAMPAIGN}
        selectedValue={chooseRoute}
        Icon={OnsiteDigitalIcon}
        iconClassName={classes.onsiteIcon}
        description={
          <>
            <p>
              {t(
                'Increase awareness and drive product sales with Sponsored Search online ads that place your brand at the top of search results pages'
              )}
            </p>
            <p>{t('Ads are generated automatically from your product listings.')}</p>
          </>
        }
      >
        <LifetimeBudgetField formik={formik} remainingBudget={remainingBudget} platform={selectedPlatform} />
      </RadioBlock>

      <RadioBlock
        setFieldValue={(fieldName, route) => handleChooseRoute(route)}
        id="campaign_option_audience_targeting"
        name={CAMPAIGN_OPTION}
        label={t('Audience Targeting')}
        value={AUDIENCE_TARGETING}
        selectedValue={chooseRoute}
        Icon={OnsiteDigitalIcon}
        iconClassName={classes.onsiteIcon}
        description={
          <>
            <p>{t('Target travellers while they are in travel planning and booking mode.')}</p>
            <p>{t('Website placements targeted by audience segment.')}</p>
          </>
        }
      >
        <LifetimeBudgetField formik={formik} remainingBudget={remainingBudget} platform={selectedPlatform} />
      </RadioBlock>

      {!!formattedCampaigns?.length && (
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_existing_campaign"
          name={CAMPAIGN_OPTION}
          label={t('Upload an ad to an existing campaign')}
          value={CAMPAIGN_OPTION_EXISTING_CAMPAIGN}
          selectedValue={values[CAMPAIGN_OPTION]}
          description={<p>{t('Upload a new ad to a new or existing ad set in one of your campaigns')}</p>}
        >
          <Field formik={formik} name={CAMPAIGN} placeholder={t('Campaign')} options={formattedCampaigns} />
        </RadioBlock>
      )}
    </Step>
  )
}

export default CampaignTypeStep
