import React from 'react'
import { components } from 'react-select'

import SelectMenuListReusable from './SelectMenuListReusable'

const SelectMenuList = props => {
  return (
    <components.MenuList {...props}>
      <SelectMenuListReusable {...props} />
    </components.MenuList>
  )
}

export default SelectMenuList
