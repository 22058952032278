import { useCallback, useMemo } from 'react'
import { getIn } from 'formik'

const useFieldErrorReset = ({ error, errors, name, setFieldError, serverError, setStatus, status }) => {
  const resetError = useCallback(() => {
    if (error) {
      // hide existed error on value change
      getIn(errors, name) && setFieldError(name, '')
      // reset server error which saved in status
      serverError &&
        setStatus({
          serverErrors: {
            ...status.serverErrors,
            [name]: ''
          }
        })
    }
  }, [error, errors, name, setFieldError, serverError, setStatus, status])

  return useMemo(() => ({ resetError }), [resetError])
}

export default useFieldErrorReset
