import * as Yup from 'yup'
import { OPTION_YES } from '../../../../../../constants/forms'

export const AUDIENCE = 'user_list'
export const AUDIENCE_OPTION = 'audience_option'

export const initialValues = {
  [AUDIENCE_OPTION]: OPTION_YES,
  [AUDIENCE]: []
}

export const validationSchema = Yup.object({
  [AUDIENCE]: Yup.array().when(AUDIENCE_OPTION, {
    is: OPTION_YES,
    then: () => Yup.array().min(1, 'Please select at least one option')
  })
})
