import { createStyles } from '../../../../../../../styles/helpers'

export default createStyles({
  costPrice: {
    fontWeight: 600
  },
  costField: {
    fontSize: 12,
    height: '32px',
    padding: '0 8px'
  }
})
