import { createStyles } from '../helpers'

import { card } from '../common'

export default createStyles(theme => ({
  adPreviewCard: {
    extend: card(theme),
    borderRadius: theme.bigBorderRadius,
    padding: '16px 8px 16px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overFlow: 'hidden'
  },
  cardSliderPreviewWrapper: {
    flexGrow: 1
  },
  actionsDropdownMenu: {
    marginLeft: 'auto',
    marginBottom: 10
  },
  actionBtnsContainer: {
    padding: '0 8px'
  }
}))
