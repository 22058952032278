import { createStyles } from '../../../styles/helpers'

import { zIndex1 } from '../../../styles/const/common'

const getArrowColor = props => {
  return props.arrowColor || 'white'
}
const arrowSize = 8
const arrowBorder = 2

const useStyles = createStyles(theme => ({
  imagePlaceholder: {
    width: '100%',
    position: 'relative',
    backgroundColor: props => props.backgroundColor || theme.brandPrimary,
    borderRadius: 2
  },
  size: {
    fontSize: 20,
    fontWeight: 700,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 4,
    backgroundColor: props => props.backgroundColor || theme.brandPrimary,
    color: props => getArrowColor(props),
    zIndex: zIndex1
  },

  axis: {
    position: 'absolute'
  },
  horizontalAxis: {
    width: '100%',
    height: arrowSize * 2,
    extend: 'axis',
    bottom: 20,
    left: 0
  },
  verticalAxis: {
    // vertical arrow is rotated so it's height will be actually width which is set by JS directly
    height: '20px',
    extend: 'axis',
    top: '50%',
    left: 0,
    transform: 'translate(-25%, -50%) rotate(90deg)',

    '& $size': {
      transform: 'translate(-50%, -50%) rotate(-90deg)'
    }
  },
  arrow: {
    position: 'absolute',
    top: 0,
    width: '100%',
    display: 'block',
    padding: `${arrowSize}px ${arrowSize * 3}px ${arrowSize}px 0`,
    fontSize: '30px',
    fontWeight: 'bold',
    textDecoration: 'none',

    '&:after, &:before': {
      content: '""',
      display: 'block',
      position: 'absolute'
    },
    '&:before': {
      top: '50%',
      width: arrowSize,
      height: arrowSize,
      transformOrigin: '50% 50%',
      transform: `translate(0, ${-(arrowSize / 2)}px) rotate(225deg)`,
      boxShadow: props => `inset ${-arrowBorder}px ${arrowBorder}px 0 0 ${getArrowColor(props)}`,
      borderRadius: `0 ${arrowSize * 0.15}px 0 0`
    },

    '&:after': {
      top: '50%',
      width: '100%',
      height: arrowBorder,
      transform: `translate(0, ${-(arrowBorder / 2)}px)`,
      backgroundColor: props => getArrowColor(props)
    }
  },
  left: {
    transform: 'rotate(180deg)'
  }
}))

export default useStyles
