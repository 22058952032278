import update from 'immutability-helper'
import { INITIAL_GET_RESULTS_REDUCER } from '../../constants/reducer'
import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_GOOGLE_USERS_LIST,
  GET_GOOGLE_USERS_LIST_SUCCESS,
  GET_GOOGLE_USERS_LIST_FAILURE,
  CLEAR_GET_GOOGLE_USERS_LIST,
  GET_GOOGLE_GEOLOCATIONS,
  GET_GOOGLE_GEOLOCATIONS_SUCCESS,
  GET_GOOGLE_GEOLOCATIONS_FAILURE,
  CLEAR_GOOGLE_GEOLOCATIONS,
  GET_GOOGLE_LANGUAGES,
  GET_GOOGLE_LANGUAGES_SUCCESS,
  GET_GOOGLE_LANGUAGES_FAILURE,
  CLEAR_GOOGLE_LANGUAGES
} from '../actions/googleConstants'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  getGoogleUsersList: INITIAL_GET_RESULTS_REDUCER,
  googleGeolocations: INITIAL_GET_RESULTS_REDUCER,
  googleLanguages: INITIAL_GET_RESULTS_REDUCER
}

export default function googleConstants(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    // Get google users list
    case GET_GOOGLE_USERS_LIST:
      return update(state, {
        getGoogleUsersList: { $merge: { isLoading: true } }
      })
    case GET_GOOGLE_USERS_LIST_SUCCESS:
      return update(state, {
        getGoogleUsersList: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data.results
          }
        }
      })
    case GET_GOOGLE_USERS_LIST_FAILURE:
      return update(state, {
        getGoogleUsersList: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_GOOGLE_USERS_LIST:
      return update(state, {
        getGoogleUsersList: { $set: initialState.getGoogleUsersList }
      })

    // get GoogleGeolocations
    case GET_GOOGLE_GEOLOCATIONS:
      return update(state, {
        googleGeolocations: { $merge: { isLoading: true } }
      })
    case GET_GOOGLE_GEOLOCATIONS_SUCCESS:
      return update(state, {
        googleGeolocations: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.geolocationsData
          }
        }
      })
    case GET_GOOGLE_GEOLOCATIONS_FAILURE:
      return update(state, {
        googleGeolocations: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GOOGLE_GEOLOCATIONS:
      return update(state, {
        googleGeolocations: {
          $set: initialState.googleGeolocations
        }
      })
    case GET_GOOGLE_LANGUAGES:
      return update(state, {
        googleLanguages: { $merge: { isLoading: true } }
      })
    case GET_GOOGLE_LANGUAGES_SUCCESS:
      return update(state, {
        googleLanguages: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.languagesData
          }
        }
      })
    case GET_GOOGLE_LANGUAGES_FAILURE:
      return update(state, {
        googleLanguages: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GOOGLE_LANGUAGES:
      return update(state, {
        googleLanguages: {
          $set: initialState.googleLanguages
        }
      })
    default:
      return state
  }
}
