import { AD_FORMAT } from '../../../../../../constants/ads'
import { PRIMARY_TEXT_LIST, SINGLE_MEDIA_ITEM } from '../../../fields'
import { formatSingleItemFeedToBE } from '../../../helpers'

import { CALL_TO_ACTION } from '../../../../../ReusableFormFields/CallToActionFields/fields'

export function getCreative(values, originalAd, adFormat) {
  const singleMediaItem = values[SINGLE_MEDIA_ITEM]
  const facebookPageId = originalAd.creative.object_story_spec?.page_id

  switch (adFormat) {
    case AD_FORMAT.carousel:
      return {
        object_story_spec: {
          link_data: {
            message: values[PRIMARY_TEXT_LIST]?.[0].text
          }
        }
      }
    case AD_FORMAT.single:
      return {
        object_story_spec: {
          ...formatSingleItemFeedToBE(values)
        }
      }
    case AD_FORMAT.singleCustom:
    default:
      const adText = values[PRIMARY_TEXT_LIST]?.[0].text
      return {
        object_story_spec: {
          page_id: facebookPageId
        },
        asset_feed_spec: {
          bodies: [
            {
              adlabels: [
                {
                  name: 'label_other_primarytext'
                },
                {
                  name: 'label_story_primarytext'
                }
              ],
              text: adText
            }
          ],
          call_to_action_types: [singleMediaItem?.[CALL_TO_ACTION]?.toLowerCase()]
        }
      }
  }
}
