import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getAutomationRulesService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.automationRules
  })
}

export async function createAutomationRuleService(automationRuleData) {
  return madeRequest('POST', {
    data: automationRuleData,
    url: ENDPOINTS.automationRules
  })
}

export async function deleteAutomationRuleService(id) {
  return madeRequest('DELETE', {
    url: `${ENDPOINTS.automationRules}${id}/`
  })
}
