import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import FormStepProgress from '../../../features/components/Form/FormStepProgress'

import useDrawerFormStyles from '../../../styles/common/drawerForms'
import useStyles from './styles'

function DrawerHeadline({ activeStepNumber, customStepsLength, formProgressItems, title, description }) {
  const drawerFormClasses = useDrawerFormStyles()

  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <div className={classes.drawerHeadlineContainer}>
      {(customStepsLength || formProgressItems) && (
        <FormStepProgress
          activeStepNumber={activeStepNumber}
          stepsLength={customStepsLength || formProgressItems?.length}
        />
      )}
      {title && <h2 className={drawerFormClasses.formTitle}>{t(title)}</h2>}
      {description && <p className={drawerFormClasses.formDescription}>{t(description)}</p>}
    </div>
  )
}

DrawerHeadline.propTypes = {
  activeStepNumber: PropTypes.number,
  customStepsLength: PropTypes.number,
  formProgressItems: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string
}

export default DrawerHeadline
