import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Field from '../../../../../../components/Form/Field'
import EditForm from '../../../../../../features/components/Forms/EditForm'

import useUpdateTikTokAdHandler from '../../hooks'
import useEditAdPreview from '../../../../../../features/hooks/useEditAdPreview'
import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import { useEditSectionFormSubmit } from '../../../../../../hooks/formHooks/useEditSectionFormSubmit'

import { clearUpdateAd } from '../../../../../../modules/actions/ads'

import {
  adUpdateIsLoadingSelector,
  adUpdateErrorSelector,
  adWasUpdatedSelector
} from '../../../../../../modules/selectors/ads'

import { AD_TEXT } from '../../../fields'
import { adTextMaxLength } from '../../../fields'
import { getInitialValues, validationSchema } from './fields'

const TextSectionForm = ({ editItemData: adData, ...formProps }) => {
  const dispatch = useDispatch()

  const itemWasUpdated = useSelector(adWasUpdatedSelector)

  const initialValues = useMemo(() => getInitialValues(adData), [adData])

  const handleUpdateSubmit = useUpdateTikTokAdHandler({ adData })
  const handleSubmit = useCallback(
    values => {
      handleUpdateSubmit(values)
    },
    [handleUpdateSubmit]
  )

  const { onSubmit, manualSuccessSubmit } = useEditSectionFormSubmit({ initialValues, handleSubmit })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)
  useEditAdPreview(purifiedFormik.values)

  const handleClearUpdateItem = useCallback(() => {
    dispatch(clearUpdateAd())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={itemWasUpdated || manualSuccessSubmit}
      clearEditItem={handleClearUpdateItem}
      errorSelector={adUpdateErrorSelector}
      isLoadingSelector={adUpdateIsLoadingSelector}
      {...formProps}
    >
      <Field formik={formik} name={AD_TEXT} label="Text" autoComplete="off" maxLength={adTextMaxLength} />
    </EditForm>
  )
}

export default TextSectionForm
