import React from 'react'

import FileDetailsPreview from '../../../../components/FileDetailsPreview'

const GoogleMediaFilesPreview = ({ assets, assetDataName = 'asset_data' }) => {
  if (!assets || !assets.length) {
    return null
  }

  return (
    <div>
      {assets.map(({ [assetDataName]: assetData }, index) => {
        const fileUrl = assetData?.image_asset?.['full_size']?.url
        const fileName = assetData?.name

        return (
          <FileDetailsPreview
            key={index}
            fileName={fileName}
            previewUrl={fileUrl}
            filePreviewType={'image'}
            isFileLoading={false}
            allowDelete={false}
          />
        )
      })}
    </div>
  )
}

export default GoogleMediaFilesPreview
