import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { getIn } from 'formik'

import GoogleMediaUpload from '../GoogleMediaUpload'
import Button from '../../../../../components/Button'
import FileDetailsPreview from '../../../../../components/FileDetailsPreview'

import { FILE_NAME, FILE_TYPE, FILE_URL, initialGoogleFile } from '../../fields'

function MultipleGoogleMediaUpload({
  formik,
  FileItemPath,
  validationSchema,
  maxCount = 10,
  addAnotherButtonText = 'Add another image',
  adAccountId,
  width,
  height
}) {
  const { t } = useTranslation()

  const { values, setValues } = formik

  const carouselItems = getIn(values, FileItemPath)

  const handleAddAnotherItem = useCallback(() => {
    if (carouselItems.length < maxCount) {
      // add new initial item to carousel items array
      const updatedValues = { [FileItemPath]: [...carouselItems, initialGoogleFile] }

      setValues({ ...values, ...updatedValues })
    }
  }, [FileItemPath, carouselItems, values, setValues, maxCount])

  const handleDeleteItem = useCallback(
    index => {
      // delete item from carousel items array
      let newCarouselItems = [...carouselItems]

      newCarouselItems.splice(index, 1)

      if (newCarouselItems.length === 0) {
        // if all files were removed add empty file to represent UploadAd component
        newCarouselItems.push(initialGoogleFile)
      }

      const updatedValues = { [FileItemPath]: newCarouselItems }

      setValues({ ...values, ...updatedValues })
    },
    [FileItemPath, carouselItems, setValues, values]
  )

  const hasEmptyMedia = !!values[FileItemPath].find(item => !item[FILE_URL])

  return (
    <>
      {carouselItems.map((item, index) => {
        const itemPath = `${FileItemPath}[${index}]`
        const fileUrl = getIn(values, `${itemPath}.${FILE_URL}`)
        const fileName = getIn(values, `${itemPath}.${FILE_NAME}`)
        const filePreviewType = getIn(values, `${itemPath}.${FILE_TYPE}`)

        return fileUrl ? (
          <FileDetailsPreview
            key={index}
            previewUrl={fileUrl}
            fileName={fileName}
            filePreviewType={filePreviewType}
            isFileLoading={false}
            allowDelete={true}
            removeFile={() => handleDeleteItem(index)}
          />
        ) : (
          <GoogleMediaUpload
            key={index}
            formik={formik}
            itemPath={itemPath}
            fileValidationSchema={validationSchema}
            onMediaRemove={() => handleDeleteItem(index)}
            adAccountId={adAccountId}
            width={width}
            height={height}
          />
        )
      })}
      <br />
      {!hasEmptyMedia && carouselItems.length < maxCount && (
        <Button solid type="button" className="btnBlock" onClick={handleAddAnotherItem}>
          {t(addAnotherButtonText)}
        </Button>
      )}
    </>
  )
}

MultipleGoogleMediaUpload.propTypes = {
  formik: PropTypes.object.isRequired,
  FileItemPath: PropTypes.string.isRequired,
  validationSchema: PropTypes.object.isRequired,
  adAccountId: PropTypes.string
}

export default MultipleGoogleMediaUpload
