import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import Form from '../../../../../components/Form'
import Field from '../../../../../components/Form/Field'
import CategorySection from './Sections/CategorySection'
import StartEndDateRangeFields from '../../../../ReusableFormFields/StartEndDateRangeFields'
import ImageSection from './Sections/ImageSection'
import MediaItemsSection from './Sections/MediaItemsSection'
import MultiRowFields from '../../../../../features/components/Form/MultiRowFields'
import PricePairFields from '../../../../ReusableFormFields/PricePairFields'
import TagsSection from './Sections/TagsSection'
import AccountsSection from './Sections/AccountsSection'
import LocationsSelectPaginated from '../../../../../features/components/locationsFields/LocationsSelectPaginated'

import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'
import { useClearMediaPackageUpdate, useGetInitialValues, useOnSubmit } from './hooks'
import { showLocationFilter } from '../../MediaPackageCreate/MediaPackageCreateForm/helpers'

import {
  selectedControllerRelatedSelfAccountsListSelector,
  selectedSelfAccountDataSelector
} from '../../../../../modules/selectors/app'
import { tagsSelector } from '../../../../../modules/selectors/tags'
import {
  mediaPackageSelector,
  updateMediaPackageErrorSelector,
  updateMediaPackageIsLoadingSelector,
  updateMediaPackageWasUpdatedSelector
} from '../../../../../modules/selectors/mediaPackages'
import { clearGetMediaProductLocations } from '../../../../../modules/actions/mediaOrdersProductLocations'
import { mediaProductLocationsWasLoadedSelector } from '../../../../../modules/selectors/mediaOrdersProductLocations'

import { MEDIA_PACKAGE_EDIT } from '../../../../../constants/forms'
import {
  AVAILABILITY_DATE_END,
  AVAILABILITY_DATE_START,
  DATE_END,
  DATE_START,
  MEDIA_CATEGORIES,
  NAME,
  SUBTEXT
} from '../../fields'
import {
  PRICE_CURRENCY,
  PRICE_PAIRS,
  PRICE_VALUE,
  pricePairInitialValue
} from '../../../../ReusableFormFields/PricePairFields/fields'
import { getValidationSchema } from './validation'

import { LOCATION } from '../../../MediaProductForms/fields'

import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

function MediaPackageEditForm() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const drawerFormClasses = useDrawerFormStyles()

  const mediaPackage = useSelector(mediaPackageSelector)
  const mediaPackageWasUpdated = useSelector(updateMediaPackageWasUpdatedSelector)
  const selectedSelfAccountData = useSelector(selectedSelfAccountDataSelector)
  const tags = useSelector(tagsSelector)

  const { id: packageId } = mediaPackage
  const { controller: controllerId } = selectedSelfAccountData
  const controllerSelfAccountsList = useSelector(selectedControllerRelatedSelfAccountsListSelector)
  const mediaProductLocationsWasLoaded = useSelector(mediaProductLocationsWasLoadedSelector)

  // either media_categories or media_products are selected, so we allow to edit only one of them
  const isPackageWithMediaCategories = !!mediaPackage[MEDIA_CATEGORIES]?.length
  // if there are no dates or quantity on package, we don't show related section to edit
  const isPackageWithDates = !!mediaPackage[DATE_START]

  const onSubmit = useOnSubmit({ packageId, isPackageWithMediaCategories })

  const initialValues = useGetInitialValues({
    editPackageData: mediaPackage,
    isPackageWithMediaCategories,
    accountsOptions: controllerSelfAccountsList,
    tags
  })

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema({ isPackageWithDates, isPackageWithMediaCategories }),
    onSubmit,
    enableReinitialize: true
  })
  const { values, setFieldValue } = formik

  const showProductsLocationFilter = useMemo(
    () => isPackageWithMediaCategories && showLocationFilter(values),
    [isPackageWithMediaCategories, values]
  )
  const productsLocationFilter = values[LOCATION]

  const purifiedFormik = usePurifiedFormik(formik)

  const clearEditMediaPackage = useClearMediaPackageUpdate()

  const clearProductsLocationFilter = useCallback(() => {
    setFieldValue(LOCATION, '')
  }, [setFieldValue])

  useEffect(() => {
    if (productsLocationFilter && !showProductsLocationFilter) {
      // Clear location filter if physical_spaces category is removed
      clearProductsLocationFilter()
    }
  }, [productsLocationFilter, showProductsLocationFilter, clearProductsLocationFilter])

  useEffect(() => {
    return () => {
      if (mediaProductLocationsWasLoaded) {
        dispatch(clearGetMediaProductLocations())
      }
    }
  }, [dispatch, mediaProductLocationsWasLoaded])

  return (
    <Form
      formik={formik}
      formName={MEDIA_PACKAGE_EDIT}
      successSubmit={mediaPackageWasUpdated}
      errorSelector={updateMediaPackageErrorSelector}
      isLoadingSelector={updateMediaPackageIsLoadingSelector}
      clearHandler={clearEditMediaPackage}
    >
      <div className={drawerFormClasses.section}>
        <h3 className={drawerFormClasses.sectionTitle}>{t('Package Name')}</h3>
        <Field formik={formik} name={NAME} placeholder="Name" enableReinitialize autoComplete="off" />
      </div>
      <CategorySection formik={purifiedFormik} />
      <div className={drawerFormClasses.section}>
        <h3 className={drawerFormClasses.sectionTitle}>{t('Availability Dates')}</h3>
        <StartEndDateRangeFields
          formik={formik}
          startDateName={AVAILABILITY_DATE_START}
          endDateName={AVAILABILITY_DATE_END}
        />
      </div>
      {isPackageWithDates && (
        <div className={drawerFormClasses.section}>
          <h3 className={drawerFormClasses.sectionTitle}>{t('Campaign Dates')}</h3>
          <StartEndDateRangeFields formik={formik} startDateName={DATE_START} endDateName={DATE_END} />
        </div>
      )}
      <div className={drawerFormClasses.section}>
        <h3 className={drawerFormClasses.sectionTitle}>{t('Description')}</h3>
        <Field
          formik={formik}
          name={SUBTEXT}
          isTextarea
          enableReinitialize
          placeholder="Description"
          autoComplete="off"
        />
      </div>
      <ImageSection formik={purifiedFormik} />
      <MediaItemsSection formik={purifiedFormik} isPackageWithMediaCategories={isPackageWithMediaCategories} />
      {showProductsLocationFilter && (
        <div className={drawerFormClasses.section}>
          <h3 className={drawerFormClasses.sectionTitle}>{t('Location filter')}</h3>
          <p>{t('Automatically filter the products shown in this package by a location.')}</p>
          <br />
          <LocationsSelectPaginated
            formik={formik}
            isClearable
            shouldFetchDefaultOptions={true}
            // todo refactor to not fetch all locations,
            // Currently we fetch all locations to be able to show selected location label in the select
            limit={9999}
          />
        </div>
      )}
      <div className={drawerFormClasses.section}>
        <h3 className={drawerFormClasses.sectionTitle}>{t('Prices')}</h3>
        <MultiRowFields
          formik={formik}
          initialValueTemplate={pricePairInitialValue}
          mainValueName={PRICE_PAIRS}
          subValueNames={[PRICE_CURRENCY, PRICE_VALUE]}
          FieldsComponent={PricePairFields}
          addNewRowText="Add another price"
        />
      </div>
      <TagsSection formik={formik} controllerId={controllerId} />
      <AccountsSection formik={formik} />
    </Form>
  )
}

export default MediaPackageEditForm
