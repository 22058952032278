import React from 'react'
import PropTypes from 'prop-types'

import AdCardMetrics from './AdCardMetrics'
import AdCardActions from './AdCardActions'
import AdCardListItem from '../../../../../../../features/components/AdCardListItem'
import GoogleAdImageType from '../../../../../../../forms/Google/AdForms/GoogleAssetsSliderPreview/Previews/GoogleAdImageType'
import GoogleAdPreviewDiscover1 from '../../../../../../../forms/Google/AdForms/GoogleAssetsSliderPreview/Previews/GoogleAdPreviewDiscover1'

import { getAdGoogleTransformedValues } from '../../../../../../../forms/Google/AdForms/GoogleDisplayAd/AdGoogleEdit/helpers'

import {
  FILE_URL,
  LANDSCAPE_IMAGE,
  landscapeImageHeight,
  landscapeImageWidth
} from '../../../../../../../forms/Google/AdForms/fields'

const GoogleAdCard = ({ ad, getAdActionsDropdownOptions, updateAdStatusHandler }) => {
  const transformedAdValues = getAdGoogleTransformedValues(ad)

  return (
    <AdCardListItem>
      <AdCardActions
        ad={ad}
        updateAdStatusHandler={updateAdStatusHandler}
        getAdActionsDropdownOptions={getAdActionsDropdownOptions}
      />
      {
        // todo refactor to switch case and ad data transformation when this type of ad will be supported
        ad.ad?.type === 'IMAGE_AD' ? (
          <GoogleAdImageType adData={ad} />
        ) : (
          <GoogleAdPreviewDiscover1
            values={transformedAdValues}
            fileUrl={transformedAdValues[LANDSCAPE_IMAGE][0]?.[FILE_URL]}
            width={landscapeImageWidth}
            height={landscapeImageHeight}
          />
        )
      }
      <AdCardMetrics ad={ad} />
    </AdCardListItem>
  )
}

GoogleAdCard.propTypes = {
  ad: PropTypes.object.isRequired,
  getAdActionsDropdownOptions: PropTypes.func,
  updateAdStatusHandler: PropTypes.func
}

export default GoogleAdCard
