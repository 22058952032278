import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import Field from '../../../../../../../components/Form/Field'
import RadioBlock from '../../../../../../../components/Form/RadioBlock'

import { BID_PRICE, BID_PRICE_OPTION, getBidPriceGoalText } from '../../fields'
import { OPTION_NO, OPTION_YES } from '../../../../../../../constants/forms'

import { selectedAdAccountDataSelector } from '../../../../../../../modules/selectors/app'

import useDrawerFormClasses from '../../../../../../../styles/common/drawerForms'

const stepFields = [BID_PRICE]

const BidPriceStep = ({ formik, handleStepChange, campaignObjective, isBidTypeCustomPopulated }) => {
  const drawerFormClasses = useDrawerFormClasses()

  const { t } = useTranslation()

  const { currency_symbol: currencySymbol } = useSelector(selectedAdAccountDataSelector)

  const { values, setFieldValue } = formik

  const goalText = getBidPriceGoalText(campaignObjective)

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  return (
    <Step formTitle="Create a TikTok Ad Group" stepTitle="Please enter a bid price" handleContinue={handleContinue}>
      {isBidTypeCustomPopulated ? (
        <section className={drawerFormClasses.section}>
          <h3 className={drawerFormClasses.sectionTitle}>{t('Bid Price')}</h3>
          <Field formik={formik} name={BID_PRICE} label="Bid Price" autoComplete="off" />
        </section>
      ) : (
        <>
          <RadioBlock
            setFieldValue={setFieldValue}
            id="radio_bid_price_no"
            name={BID_PRICE_OPTION}
            label={t('No')}
            value={OPTION_NO}
            selectedValue={values[BID_PRICE_OPTION]}
          />
          <RadioBlock
            setFieldValue={setFieldValue}
            id="radio_bid_price_yes"
            name={BID_PRICE_OPTION}
            label={t('Yes')}
            value={OPTION_YES}
            selectedValue={values[BID_PRICE_OPTION]}
          >
            <p>
              {t(
                `A bid control will limit the cost per ${goalText} to the amount you set. This might limit the campaign’s delivery if it is too low.`
              )}
            </p>
            <Field
              symbol={currencySymbol}
              formik={formik}
              name={BID_PRICE}
              label="Bid Price"
              autoComplete="off"
              type="number"
            />
          </RadioBlock>
        </>
      )}
    </Step>
  )
}

export default BidPriceStep
