import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ReactComponent as SuccessIcon } from '../../../../assets/icons/success-icon.svg'

import InfoModal from '../InfoModal'
import Button from '../../../../components/Button'
import ProgressButton from '../../../../components/Form/ProgressButton'

import useModalManage from '../../../../hooks/useModalManage'

import { redirectTo } from '../../../../helpers/url'

import { clearAd, clearCopyAd } from '../../../../modules/actions/ads'

import {
  copyAdDataSelector,
  copyAdErrorSelector,
  copyAdIsLoadingSelector,
  copyAdWasCreatedSelector,
  selectedAdForCopySelector
} from '../../../../modules/selectors/ads'
import { ROUTE_PARAMS, ROUTES } from '../../../../constants/routes'

import useStyles from './styles'

const AdDuplicationModal = ({ showRedirectionBtn }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const [adWasCopied, setAdCopied] = useState(false)
  const { isModalOpened, openModalHandler, closeModalHandler } = useModalManage({})

  const copiedAdData = useSelector(copyAdDataSelector)
  const copyAdError = useSelector(copyAdErrorSelector)
  const copyAdIsLoading = useSelector(copyAdIsLoadingSelector)
  const copyAdWasCreated = useSelector(copyAdWasCreatedSelector)
  const selectedAdForCopy = useSelector(selectedAdForCopySelector)

  const handleAdCopied = useCallback(() => {
    setAdCopied(true)
  }, [])

  const handleModalClose = useCallback(() => {
    closeModalHandler()
    setAdCopied(false)
    dispatch(clearCopyAd())
    dispatch(clearAd())
  }, [dispatch, closeModalHandler])

  const goToAdSetHandler = useCallback(() => {
    redirectTo(ROUTES.lineItemsSummary, {
      [ROUTE_PARAMS.lineItemId]: copiedAdData.adset_id
    })

    dispatch(clearCopyAd())
    dispatch(clearAd())
  }, [dispatch, copiedAdData])

  useEffect(() => {
    // open AdDuplication modal on start ad coping
    if (copyAdIsLoading) {
      openModalHandler()
    }
  }, [copyAdIsLoading, openModalHandler])

  return (
    <InfoModal
      Icon={adWasCopied ? SuccessIcon : null}
      isOpen={isModalOpened}
      onClose={handleModalClose}
      modalTitle={adWasCopied ? 'Success!' : 'Ad is duplicating!'}
      ModalMessage={
        adWasCopied
          ? t('Your ad was duplicated successfully.')
          : `Ad '${selectedAdForCopy && selectedAdForCopy.name}' is duplicating!`
      }
    >
      {adWasCopied ? (
        <>
          {showRedirectionBtn && (
            <Button solid onClick={goToAdSetHandler}>
              {t('Go to Ad set')}
            </Button>
          )}
          <Button onClick={handleModalClose} className={classes.closeBtn}>
            {t('Close')}
          </Button>
        </>
      ) : (
        <ProgressButton
          formError={copyAdError}
          isFormLoading={copyAdIsLoading}
          successSubmit={copyAdWasCreated}
          onErrorSubmit={handleModalClose}
          onSuccessSubmit={handleAdCopied}
        >
          {t('Ad duplication')}
        </ProgressButton>
      )}
    </InfoModal>
  )
}

AdDuplicationModal.propTypes = {
  showRedirectionBtn: PropTypes.bool
}

export default AdDuplicationModal
