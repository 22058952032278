import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { activeStepSelector } from '../../../../../../../modules/selectors/forms'

const useFormProgressItems = ({ steps }) => {
  const activeStep = useSelector(activeStepSelector)

  const stepsLength = steps.length

  const progressItems = useMemo(
    () => [
      {
        label: 'Select media products',
        isActive: activeStep !== stepsLength,
        isSuccess: activeStep === stepsLength
      },
      {
        label: 'Review and book',
        isActive: activeStep === stepsLength,
        isSuccess: false
      }
    ],
    [activeStep, stepsLength]
  )

  return useMemo(
    () => ({
      progressItems
    }),
    [progressItems]
  )
}

export default useFormProgressItems
