import { createStyles } from '../../../../../../../../styles/helpers'

export default createStyles({
  container: {
    padding: '10px 0 0 0'
  },
  requirementNum: {
    margin: '0 !important'
  }
})
