import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addMonths, endOfMonth } from 'date-fns'
import { getIn } from 'formik'

import SelectPeriodsFields from './SelectPeriodsFields'

import { SelectPeriodsFieldsContextProvider } from './SelectPeriodsFields/SelectPeriodsFieldsContextProvider'

import {
  clearGetInventoryGroup,
  clearInventory,
  getInventory,
  getInventoryGroup
} from '../../../../../../../../../modules/actions/mediaOrdersProducts'

import { CAMPAIGN_START_PERIOD_OPTION } from '../../../fields'
import { PERIOD_DAY } from '../../../../../../../../../constants/mediaOrders'
import { formatDateToBE } from '../../../../../../../../../constants/dates'
import { PRODUCT_PERIODS_DATES } from '../../../../../../fields'

function ProductSetupFields({ formik, productItemPath, isActive, currency, showError, productErrors }) {
  const dispatch = useDispatch()

  const productValues = getIn(formik.values, productItemPath)
  const productData = productValues.data

  const productId = productData.id
  const productPeriod = productData.period
  const isProductGroup = productData.group
  const productStartPeriodDate = productValues[CAMPAIGN_START_PERIOD_OPTION]

  useEffect(() => {
    if (isActive) {
      const dateFrom = productPeriod === PERIOD_DAY ? formatDateToBE(new Date()) : productStartPeriodDate
      const dateTo = endOfMonth(addMonths(new Date(dateFrom), 1))

      const inventoryParams = {
        media_product: productId,
        date_from: dateFrom,
        // for PERIOD_DAY DatePickerPeriodSelector is shown where we do have the paginated loading
        // for other periods we show the regular select and fetch all available periods
        ...(productPeriod === PERIOD_DAY && { date_to: formatDateToBE(dateTo) }),
        currency: currency.code
      }
      if (isProductGroup) {
        dispatch(getInventoryGroup(inventoryParams))
      } else {
        dispatch(getInventory(inventoryParams))
      }
    }
  }, [dispatch, productId, productPeriod, currency.code, productStartPeriodDate, isActive, isProductGroup])

  useEffect(() => {
    return () => {
      if (isProductGroup) {
        dispatch(clearGetInventoryGroup())
      } else {
        dispatch(clearInventory())
      }
    }
  }, [dispatch, isProductGroup])

  return (
    <SelectPeriodsFieldsContextProvider currency={currency} product={productData}>
      <SelectPeriodsFields
        formik={formik}
        productItemPath={productItemPath}
        showError={showError}
        error={productErrors?.[PRODUCT_PERIODS_DATES]}
      />
    </SelectPeriodsFieldsContextProvider>
  )
}

export default ProductSetupFields
