import { all, put, call, takeEvery } from 'redux-saga/effects'

import {
  CREATE_DISCOUNT_PRESET,
  CREATE_PRICE_CHANGE_PERIOD,
  createDiscountPresetFailure,
  createDiscountPresetSuccess,
  createPriceChangePeriodFailure,
  createPriceChangePeriodSuccess,
  DELETE_PRICE_CHANGE_PERIOD,
  deletePriceChangePeriodFailure,
  deletePriceChangePeriodSuccess,
  GET_DISCOUNT_PRESETS,
  GET_PRICE_CHANGE_PERIODS,
  getDiscountPresetsFailure,
  getDiscountPresetsSuccess,
  getPriceChangePeriodsFailure,
  getPriceChangePeriodsSuccess,
  UPDATE_DISCOUNT_PRESET,
  UPDATE_PRICE_CHANGE_PERIOD,
  updateDiscountPresetFailure,
  updateDiscountPresetSuccess,
  updatePriceChangePeriodFailure,
  updatePriceChangePeriodSuccess
} from '../actions/discounts'
import {
  createDiscountPresetService,
  createPriceChangePeriodService,
  deletePriceChangePeriodService,
  getDiscountPresetsService,
  getPriceChangePeriodsService,
  updateDiscountPresetService,
  updatePriceChangePeriodService
} from '../services/discounts'

function* discountsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_DISCOUNT_PRESETS, getDiscountPresetsWorker),
    takeEvery(CREATE_DISCOUNT_PRESET, createDiscountPresetWorker),
    takeEvery(UPDATE_DISCOUNT_PRESET, updateDiscountPresetWorker),
    takeEvery(GET_PRICE_CHANGE_PERIODS, getPriceChangePeriodsWorker),
    takeEvery(CREATE_PRICE_CHANGE_PERIOD, createPriceChangePeriodWorker),
    takeEvery(UPDATE_PRICE_CHANGE_PERIOD, updatePriceChangePeriodWorker),
    takeEvery(DELETE_PRICE_CHANGE_PERIOD, deletePriceChangePeriodWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getDiscountPresetsWorker({ params }) {
  try {
    const response = yield call(getDiscountPresetsService, params)
    yield put(getDiscountPresetsSuccess(response))
  } catch (error) {
    yield put(getDiscountPresetsFailure(error))
  }
}

function* createDiscountPresetWorker({ data }) {
  try {
    const response = yield call(createDiscountPresetService, data)
    yield put(createDiscountPresetSuccess(response))
  } catch (error) {
    yield put(createDiscountPresetFailure(error))
  }
}

function* updateDiscountPresetWorker({ data, id }) {
  try {
    const response = yield call(updateDiscountPresetService, data, id)
    yield put(updateDiscountPresetSuccess(response))
  } catch (error) {
    yield put(updateDiscountPresetFailure(error))
  }
}

function* getPriceChangePeriodsWorker({ params }) {
  try {
    const response = yield call(getPriceChangePeriodsService, params)
    yield put(getPriceChangePeriodsSuccess(response))
  } catch (error) {
    yield put(getPriceChangePeriodsFailure(error))
  }
}

function* createPriceChangePeriodWorker({ data }) {
  try {
    const response = yield call(createPriceChangePeriodService, data)
    yield put(createPriceChangePeriodSuccess(response))
  } catch (error) {
    yield put(createPriceChangePeriodFailure(error))
  }
}

function* updatePriceChangePeriodWorker({ data, id }) {
  try {
    const response = yield call(updatePriceChangePeriodService, data, id)
    yield put(updatePriceChangePeriodSuccess(response))
  } catch (error) {
    yield put(updatePriceChangePeriodFailure(error))
  }
}

function* deletePriceChangePeriodWorker({ id }) {
  try {
    yield call(deletePriceChangePeriodService, id)
    yield put(deletePriceChangePeriodSuccess(id))
  } catch (error) {
    yield put(deletePriceChangePeriodFailure(error))
  }
}

export default discountsWatcher
