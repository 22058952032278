import React, { useMemo } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'

import EditForm from '../../../features/components/Forms/EditForm'
import Field from '../../../components/Form/Field'

import { usePurifiedFormik } from '../../../hooks/formHooks/usePurifiedFormik'

import { NAME } from './fields'
import nameValidation from '../../../features/validations/name'

const validationSchema = Yup.object(nameValidation)

const NameSectionForm = ({ placeholder, editItem, handleSubmit, ...formProps }) => {
  const initialValues = useMemo(() => ({ [NAME]: editItem[NAME] || '' }), [editItem])

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  return (
    <EditForm formik={purifiedFormik} initialValues={initialValues} {...formProps}>
      <Field formik={purifiedFormik} name={NAME} placeholder={placeholder} autoComplete="off" enableReinitialize />
    </EditForm>
  )
}

NameSectionForm.propTypes = {
  // manual props
  placeholder: PropTypes.string.isRequired,
  editItem: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  clearEditItem: PropTypes.func.isRequired,
  successSubmit: PropTypes.bool.isRequired,
  isLoadingSelector: PropTypes.func.isRequired,
  errorSelector: PropTypes.func.isRequired,

  // automatic edit section props
  onEditClose: PropTypes.func.isRequired,
  setUnsavedChanges: PropTypes.func.isRequired,
  onSuccessSubmit: PropTypes.func.isRequired
}

export default NameSectionForm
