import { createStyles } from '../../../../styles/helpers'

import { darkGrey, lightGrey, mediumGrey, textGrey } from '../../../../styles/const/colors'

const contentPadding = '16px 23px'
const useStyles = createStyles({
  infoModal: {
    border: `1px solid ${mediumGrey}`,
    maxWidth: 430,
    padding: 0
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: contentPadding,
    backgroundColor: lightGrey,
    borderBottom: `1px solid ${mediumGrey}`
  },
  title: {
    color: textGrey
  },
  closeIcon: {
    padding: 6,
    maxHeight: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& path': {
      fill: darkGrey
    }
  },
  body: {
    margin: '0 auto',
    padding: contentPadding,
    paddingBottom: '20px',
    textAlign: 'left'
  },
  modalMessage: {
    color: darkGrey,
    fontSize: '14px',
    '& p': {
      marginTop: 8
    }
  },
  contentContainer: {
    marginTop: 32
  }
})

export default useStyles
