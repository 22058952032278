import { concat } from '../../helpers/common'

const MODULE_NAME = 'AD_ACCOUNTS/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_AD_ACCOUNTS = concat(MODULE_NAME, 'GET_AD_ACCOUNTS')
export const GET_AD_ACCOUNTS_SUCCESS = concat(MODULE_NAME, 'GET_AD_ACCOUNTS_SUCCESS')
export const GET_AD_ACCOUNTS_FAILURE = concat(MODULE_NAME, 'GET_AD_ACCOUNTS_FAILURE')

export const GET_AD_ACCOUNT = concat(MODULE_NAME, 'GET_AD_ACCOUNT')
export const GET_AD_ACCOUNT_SUCCESS = concat(MODULE_NAME, 'GET_AD_ACCOUNT_SUCCESS')
export const GET_AD_ACCOUNT_FAILURE = concat(MODULE_NAME, 'GET_AD_ACCOUNT_FAILURE')
export const CLEAR_ALL_PLATFORMS_SINGLE_AD_ACCOUNT = concat(MODULE_NAME, 'CLEAR_ALL_PLATFORMS_SINGLE_AD_ACCOUNT')
export const CLEAR_SPECIFIC_PLATFORM_SINGLE_AD_ACCOUNT = concat(
  MODULE_NAME,
  'CLEAR_SPECIFIC_PLATFORM_SINGLE_AD_ACCOUNT'
)

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

export function getAdAccounts() {
  return { type: GET_AD_ACCOUNTS }
}

export function getAdAccountsSuccess(adAccountsData) {
  return { type: GET_AD_ACCOUNTS_SUCCESS, adAccountsData }
}

export function getAdAccountsFailure(error) {
  return { type: GET_AD_ACCOUNTS_FAILURE, error }
}

export function getAdAccount(id, platform) {
  return { type: GET_AD_ACCOUNT, id, platform }
}

export function getAdAccountSuccess(adAccountData, platform) {
  return { type: GET_AD_ACCOUNT_SUCCESS, adAccountData, platform }
}

export function getAdAccountFailure(error, platform) {
  return { type: GET_AD_ACCOUNT_FAILURE, error, platform }
}

export function clearAllPlatformsSingleAdAccount() {
  return { type: CLEAR_ALL_PLATFORMS_SINGLE_AD_ACCOUNT }
}

export function clearSpecificPlatformSingleAdAccount(platform) {
  return { type: CLEAR_SPECIFIC_PLATFORM_SINGLE_AD_ACCOUNT, platform }
}
