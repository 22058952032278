import { createStyles } from '../../../../../styles/helpers'

import { mediumGrey } from '../../../../../styles/const/colors'

const useStyles = createStyles({
  spacer: {
    height: 1,
    margin: hasMargin => hasMargin && '16px 0',
    border: 'none',
    backgroundColor: mediumGrey
  }
})

export default useStyles
