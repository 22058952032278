import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Select from '../../../../components/Select'

import { useDebouncedSearch } from '../../../../hooks/useDebouncedSearch'

function ManualSearchableSelect({
  value,
  options,
  onChange,
  onSearch,
  triggerClearSearch,
  noOptionsMessage,
  placeholder = 'Search...',
  ...selectProps
}) {
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState('')

  const dynamicNoOptionsMessage = useMemo(() => {
    // react-select library requires noOptionsMessage prop to be a function that returns either a string or null.
    return !!searchValue ? noOptionsMessage : () => t('Type to search')
  }, [t, noOptionsMessage, searchValue])

  const onManualSearch = useCallback(
    searchValue => {
      setSearchValue(searchValue)
      onSearch(searchValue)
    },
    [onSearch, setSearchValue]
  )

  const handleSearchChange = useCallback(
    searchValue => {
      // make BE search every debouncedSearchValue value change
      onManualSearch(searchValue)
    },
    [onManualSearch]
  )

  const { searchText, clearSearchValue, handleSearchTextChange } = useDebouncedSearch(handleSearchChange)

  const onSearchInputChange = (value, { action }) => {
    if (action === 'input-change') {
      handleSearchTextChange(value)
    }
  }

  const onChangeHandler = useCallback(
    value => {
      onChange(value)
    },
    [onChange]
  )

  useEffect(() => {
    // external trigger to reset internal state
    if (triggerClearSearch) {
      clearSearchValue()
    }
  }, [clearSearchValue, triggerClearSearch])

  return (
    <Select
      value={value}
      onChange={onChangeHandler}
      onInputChange={onSearchInputChange}
      onClearInput={clearSearchValue}
      inputValue={searchText}
      showClearInputIcon={!!searchText}
      placeholder={placeholder}
      noOptionsMessage={dynamicNoOptionsMessage}
      {...selectProps}
    />
  )
}

ManualSearchableSelect.propTypes = {
  options: PropTypes.array.isRequired,
  noOptionsMessage: PropTypes.func,
  isLoading: PropTypes.bool,
  value: PropTypes.array,
  onChange: PropTypes.func,
  onSearch: PropTypes.func
}

export default ManualSearchableSelect
