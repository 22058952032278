import React from 'react'
import PropTypes from 'prop-types'
import { DragDropContext } from 'react-beautiful-dnd'

import { StrictModeDroppable } from './StrictModeDroppable'

const DragAndDropWrapper = ({ children, onDragEnd, droppableId }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StrictModeDroppable droppableId={droppableId}>
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {children}
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  )
}

DragAndDropWrapper.propTypes = {
  droppableId: PropTypes.string.isRequired,
  onDragEnd: PropTypes.func.isRequired
}

export default DragAndDropWrapper
