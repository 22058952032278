import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { getIn } from 'formik'

import FixedPriceProducts from './FixedPriceProducts'
import AllOrderedProducts from './AllOrderedProducts'
import CategoryOrderedProducts from './CategoryOrderedProducts'
import ActionText from '../../../../../../../../../components/ActionText'
import FieldsSection from '../../../../../../../../../features/components/Form/FieldsSection'
import ErrorMessage from '../../../../../../../../../components/Form/ErrorMessage'

import { getAvailableCategories, getPackageFlow } from '../../../helpers'

import { DIGITAL, EDM, IN_STORE, OOH, PHYSICAL_SPACES, PRINT } from '../../../../../../../../../constants/mediaOrders'
import {
  SELECTED_PACKAGES,
  CATEGORY_PRODUCTS_PACKAGE,
  FIXED_PRE_DEFINED_PRODUCTS__FIXED_DATES_PACKAGE,
  FIXED_PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE,
  PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE
} from '../../../fields'

function PackageBreakdown({ formik, currency, selectedMediaPackage, packageIndex, onPackageRemove }) {
  const { t } = useTranslation()

  const packagePath = `${SELECTED_PACKAGES}[${packageIndex}]`
  const isTouched = getIn(formik.touched, packagePath)
  const error = getIn(formik.errors, packagePath)
  const showErrors = !!isTouched && !!error
  const productsPackageFlow = getPackageFlow(selectedMediaPackage)

  function renderProducts() {
    switch (productsPackageFlow) {
      case PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE:
        return (
          <AllOrderedProducts
            formik={formik}
            selectedMediaPackage={selectedMediaPackage}
            packageIndex={packageIndex}
            currency={currency}
            showError={showErrors}
            minQuantity={0}
          />
        )
      case FIXED_PRE_DEFINED_PRODUCTS__FIXED_DATES_PACKAGE:
        return (
          <FixedPriceProducts
            errors={getIn(formik.errors, packagePath)}
            showError={showErrors}
            selectedMediaPackage={selectedMediaPackage}
            currency={currency}
          />
        )
      case CATEGORY_PRODUCTS_PACKAGE:
      case FIXED_PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE:
        const availableCategories = getAvailableCategories(selectedMediaPackage)
        const reusableProps = {
          packageIndex,
          formik,
          currency,
          showError: showErrors,
          // don't allow to delete product but allow to increase/decrease quantity
          minQuantity: productsPackageFlow === FIXED_PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE ? 1 : 0
        }

        return (
          <>
            {/* order is Print -> OOH -> InStore -> PhysicalSpaces -> EDM -> Digital*/}
            {availableCategories[PRINT] && <CategoryOrderedProducts {...reusableProps} productCategory={PRINT} />}
            {availableCategories[OOH] && <CategoryOrderedProducts {...reusableProps} productCategory={OOH} />}
            {availableCategories[IN_STORE] && <CategoryOrderedProducts {...reusableProps} productCategory={IN_STORE} />}
            {availableCategories[PHYSICAL_SPACES] && (
              <CategoryOrderedProducts {...reusableProps} productCategory={PHYSICAL_SPACES} />
            )}
            {availableCategories[EDM] && <CategoryOrderedProducts {...reusableProps} productCategory={EDM} />}
            {availableCategories[DIGITAL] && <CategoryOrderedProducts {...reusableProps} productCategory={DIGITAL} />}
          </>
        )
      default:
        return null
    }
  }

  return (
    <FieldsSection
      key={selectedMediaPackage.id}
      title={selectedMediaPackage?.name}
      rightColumn={
        <ActionText isDark isBold onClick={() => onPackageRemove(selectedMediaPackage.id)}>
          {t('Remove')}
        </ActionText>
      }
      hasMargin
    >
      {
        // Error message for cases where there is no currency for a product in a media package (except in the case the user can select products and there is at least 1 valid product):
        selectedMediaPackage.hasSkippedPreselectedProducts ? (
          <ErrorMessage
            error={t(
              'Sorry, there is an issue with the pricing of one or more of the media products in this package. Please contact your account manager to book this package'
            )}
          />
        ) : (
          renderProducts()
        )
      }
    </FieldsSection>
  )
}

PackageBreakdown.propTypes = {
  formik: PropTypes.object,
  currency: PropTypes.object,
  selectedMediaPackage: PropTypes.object,
  packageIndex: PropTypes.number,
  showErrors: PropTypes.bool,
  onPackageRemove: PropTypes.func
}

export default PackageBreakdown
