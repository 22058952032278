import { createStyles } from '../../../../../../styles/helpers'
import { textGrey } from '../../../../../../styles/const/colors'
import { textEllipsis } from '../../../../../../styles/mixins/text'

const useStyles = createStyles({
  wrapper: {
    width: '100%'
  },
  mainText: {
    color: textGrey,
    fontSize: '14px',
    fontWeight: 600,
    ...textEllipsis()
  }
})

export default useStyles
