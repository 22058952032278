import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import MediaProductFilter from '../../../../../../features/components/Filters/MediaProductFilter'
import BookedMediaLocationsFilter from '../../../../../../features/components/Filters/BookedMediaLocationsFilter'
import RepresentativeFilter from '../../../../../../features/components/Filters/RepresentativeFilter'
import MultipleCategoriesFilters from '../../../../../../features/components/Filters/MultipleCategoriesFilters'
import MultipleControllerSelfAccountsFilter from '../../../../../../features/components/Filters/MultipleControllerSelfAccountsFilter'
import MultipleSelfAccountCategoriesFilters from '../../../../../../features/components/Filters/MultipleSelfAccountCategoriesFilters'

import { userSelfAccountTypeSelector } from '../../../../../../modules/selectors/app'

import {
  BookedMediaCalendarDataProvider,
  BookedMediaCalendarFiltersContext
} from '../../../../../ManagementReporting/BookedMediaReport/CalendarBookedMediaFilters/BookedMediaCalendarFiltersContext'
import { CONTROLLER_TYPE } from '../../../../../../constants/permissions'

import useStyles from './styles'

const CalendarBookedMediaFilters = ({ onFiltersChange }) => {
  const classes = useStyles()

  const userAccountType = useSelector(userSelfAccountTypeSelector)

  return (
    <BookedMediaCalendarDataProvider onFiltersChange={onFiltersChange}>
      <div className={classes.filtersRow}>
        <div className={classes.rightFiltersRow}>
          {userAccountType === CONTROLLER_TYPE && (
            <MultipleControllerSelfAccountsFilter Context={BookedMediaCalendarFiltersContext} />
          )}
          <RepresentativeFilter Context={BookedMediaCalendarFiltersContext} />
          <BookedMediaLocationsFilter Context={BookedMediaCalendarFiltersContext} />
          <MultipleCategoriesFilters Context={BookedMediaCalendarFiltersContext} />
          <MediaProductFilter Context={BookedMediaCalendarFiltersContext} />
          <MultipleSelfAccountCategoriesFilters Context={BookedMediaCalendarFiltersContext} />

          {/*this is used for portalled filters - don't remove until check id usage */}
          <div id="booked_media_calendar_view_portal" />
          {/*this is used for pdf generation */}
          <div id="pdf_generation_portal" />
        </div>
      </div>
    </BookedMediaCalendarDataProvider>
  )
}

CalendarBookedMediaFilters.propTypes = {
  onFiltersChange: PropTypes.func
}

export default CalendarBookedMediaFilters
