import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../../components/Form'
import PriceChangePeriodFormContent from '../../PriceChangePeriodFormContent'

import { transformValuesToBE } from '../../formatters'

import {
  createPriceChangePeriodErrorSelector,
  createPriceChangePeriodIsLoadingSelector,
  createPriceChangePeriodWasCreatedSelector
} from '../../../../../../modules/selectors/discounts'
import { clearCreatePriceChangePeriod, createPriceChangePeriod } from '../../../../../../modules/actions/discounts'

import { PRICE_CHANGE_PERIOD_CREATE } from '../../../../../../constants/forms'
import { validationSchema } from '../../validation'
import { initialValues } from './fields'
import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'

function PriceChangePeriodCreateForm() {
  const dispatch = useDispatch()

  const priceChangePeriodWasCreated = useSelector(createPriceChangePeriodWasCreatedSelector)
  const controllerId = useSelector(selectedControllerIdSelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values)

      dispatch(
        createPriceChangePeriod({
          controller: controllerId,
          ...transformedData
        })
      )
    },
    [dispatch, controllerId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const clearCreateItemHandler = useCallback(() => dispatch(clearCreatePriceChangePeriod()), [dispatch])

  return (
    <Form
      formik={formik}
      formName={PRICE_CHANGE_PERIOD_CREATE}
      successSubmit={priceChangePeriodWasCreated}
      errorSelector={createPriceChangePeriodErrorSelector}
      isLoadingSelector={createPriceChangePeriodIsLoadingSelector}
      // after form submit
      clearHandler={clearCreateItemHandler}
    >
      <PriceChangePeriodFormContent formik={formik} />
    </Form>
  )
}

export default PriceChangePeriodCreateForm
