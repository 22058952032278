import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import AskReasonModal from '../../../../../../../features/components/Modals/AskReasonModal'

import { clearUpdateBookingMediaFile } from '../../../../../../../modules/actions/mediaOrdersBookings'
import {
  updateBookedMediaFileIsLoadingSelector,
  updateBookedMediaFileErrorSelector,
  updateBookedMediaFileWasUpdatedSelector
} from '../../../../../../../modules/selectors/mediaOrdersBookings'

import { DISAPPROVED_REASON } from '../../../../../../../features/components/Modals/AskReasonModal/fields'

const ModalForDisapprovalReason = ({ onSubmit, isOpen, onClose, requirementFileId, uploadedFile }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleSubmit = useCallback(
    values => {
      onSubmit({
        requirementFileId,
        uploadedFile,
        [DISAPPROVED_REASON]: values[DISAPPROVED_REASON]
      })
    },
    [onSubmit, uploadedFile, requirementFileId]
  )

  const clearHandler = useCallback(() => {
    dispatch(clearUpdateBookingMediaFile())
  }, [dispatch])

  return (
    <AskReasonModal
      title={t('Disapproval Reason')}
      confirmButtonText={t('Disapprove')}
      cancelButtonText={t('Cancel')}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      onClose={onClose}
      ModalMessage={t(
        'We will notify the user that uploaded this file that it has been disapproved. Please provide a reason.'
      )}
      successSelector={updateBookedMediaFileWasUpdatedSelector}
      errorSelector={updateBookedMediaFileErrorSelector}
      isLoadingSelector={updateBookedMediaFileIsLoadingSelector}
      clearFormHandler={clearHandler}
      onSuccessSubmit={onClose}
    />
  )
}

export default ModalForDisapprovalReason
