import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles(theme => ({
  refreshIcon: {
    width: 38,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fff',
    '& path': {
      fill: theme.brandPrimary
    }
  }
}))

export default useStyles
