import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import MultipleCheckboxesSelect from '../../../../components/MultipleCheckboxesSelect'

import { formatOptionsList } from '../../../formatters'

import { getMediaSubCategories } from '../../../../modules/actions/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import { mediaSubCategoriesSelector } from '../../../../modules/selectors/mediaOrdersProducts'

import { mediaCategories } from '../../../../constants/mediaOrders'

import useStyles from './styles'

// default empty object to avoid errors when Context is not provided
const defaultContext = {}

const MultipleCategoriesFilters = ({ Context = defaultContext, filters }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const filtersContext = useContext(Context)

  // MultipleCategoriesFilters may use filters from Context or from props,
  // if Context is not provided, it will use filters from props
  const filtersObject = useMemo(() => (filtersContext ? filtersContext : filters), [filtersContext, filters])

  const {
    selectedCategories,
    setSelectedCategories,
    selectedSubCategories,
    setSelectedSubCategories,
    selectedSubSubCategories,
    setSelectedSubSubCategories
  } = filtersObject

  const mediaSubCategories = useSelector(mediaSubCategoriesSelector)
  const controllerId = useSelector(selectedControllerIdSelector)

  const formattedMediaSubCategoriesOptions = useMemo(() => {
    return formatOptionsList({
      list: mediaSubCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [mediaSubCategories])

  const formattedAllSubSubCategoriesOptions = useMemo(() => {
    const allSubSubCategories = mediaSubCategories.reduce((acc, subCategory) => {
      return [...acc, ...subCategory.sub_sub_categories]
    }, [])

    return formatOptionsList({
      list: allSubSubCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [mediaSubCategories])

  const selectCategoryHandler = useCallback(
    data => {
      setSelectedCategories(data)
    },
    [setSelectedCategories]
  )

  const selectSubCategoryHandler = useCallback(
    data => {
      setSelectedSubCategories(data)
    },
    [setSelectedSubCategories]
  )

  const selectSubSubCategoryHandler = useCallback(
    data => {
      setSelectedSubSubCategories(data)
    },
    [setSelectedSubSubCategories]
  )
  useEffect(() => {
    dispatch(
      getMediaSubCategories({
        controller: controllerId
      })
    )
  }, [dispatch, controllerId])

  return (
    <>
      <MultipleCheckboxesSelect
        className={classes.categoryFilter}
        options={mediaCategories}
        value={selectedCategories}
        onChange={selectCategoryHandler}
        placeholder="Category"
        multipleOptionsLabel="Category"
        isSearchable={true}
      />
      {!!formattedMediaSubCategoriesOptions.length && (
        <MultipleCheckboxesSelect
          className={classes.subCategory}
          options={formattedMediaSubCategoriesOptions}
          value={selectedSubCategories}
          onChange={selectSubCategoryHandler}
          placeholder="Asset Type"
          multipleOptionsLabel="Asset Type"
          isSearchable={true}
        />
      )}
      {!!formattedAllSubSubCategoriesOptions.length && (
        <MultipleCheckboxesSelect
          className={classes.subSubCategory}
          options={formattedAllSubSubCategoriesOptions}
          value={selectedSubSubCategories}
          onChange={selectSubSubCategoryHandler}
          placeholder="Asset Subtype"
          multipleOptionsLabel="Asset Subtype"
          isSearchable={true}
        />
      )}
    </>
  )
}

MultipleCategoriesFilters.propTypes = {
  Context: PropTypes.object
}

export default MultipleCategoriesFilters
