import React, { useContext, useEffect, useMemo } from 'react'

import Field from '../../../../../../../../../components/Form/Field'
import FieldRow from '../../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import FieldsSection from '../../../../../../../../../features/components/Form/FieldsSection'

import { useRequiredFields } from '../../../hooks/useRequiredFields'

import { formatOptionsList } from '../../../../../../../../../features/formatters'

import { BRAND, CAMPAIGN_NAME } from '../../../fields'
import { MediaOrderFormContext } from '../../../../../../MediaOrderFormContext'

function AdditionalMediaOrderInfo({ formik }) {
  const { contextSelfAccountData } = useContext(MediaOrderFormContext)

  const brands = contextSelfAccountData?.brands

  const brandsOptions = useMemo(() => {
    return formatOptionsList({
      list: brands,
      valueName: 'id',
      labelName: 'brand_name'
    })
  }, [brands])

  const { setFieldValue } = formik

  const { askBrandName, askCampaignName, preselectFirstBrand, isBrandRequired } =
    useRequiredFields(contextSelfAccountData)
  const showBrandName = askBrandName && isBrandRequired
  const showAdditionalFields = showBrandName || askCampaignName

  useEffect(() => {
    if (askBrandName && preselectFirstBrand) {
      // if there is only one brand, dont ask the question and just add it automatically
      setFieldValue(BRAND, brandsOptions[0].value)
    }
  }, [setFieldValue, askBrandName, preselectFirstBrand, brandsOptions])

  return (
    showAdditionalFields && (
      <FieldsSection title="Additional info">
        {askBrandName && isBrandRequired && (
          <FieldRow title="Brand" description="Please select the brand that will be promoted.">
            <Field formik={formik} name={BRAND} options={brandsOptions} placeholder="Brand" />
          </FieldRow>
        )}
        {askCampaignName && (
          <FieldRow title="Campaign name" description="Please enter a campaign name for this booking.">
            <Field formik={formik} name={CAMPAIGN_NAME} placeholder="Campaign name" />
          </FieldRow>
        )}
      </FieldsSection>
    )
  )
}

export default AdditionalMediaOrderInfo
