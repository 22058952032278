import React from 'react'
import classnames from 'classnames'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import PropTypes from 'prop-types'
import { useTheme } from 'react-jss'

import { lightGrey, transparent } from '../../../../styles/const/colors'
import useStyles from './styles'

function ShowSkeleton({ isInProgress, count, className, showLoadingAnimation }) {
  const classes = useStyles()
  const theme = useTheme()

  const updatedProps = isInProgress
    ? { baseColor: theme.brandPrimary }
    : { highlightColor: showLoadingAnimation ? lightGrey : transparent }

  const wrapper = ({ children }) => {
    return <p className={classnames(classes.skeleton, className)}>{children}</p>
  }

  return (
    <SkeletonTheme {...updatedProps}>
      <Skeleton wrapper={wrapper} count={count} height={'100%'} width={'100%'} />
    </SkeletonTheme>
  )
}

ShowSkeleton.propTypes = {
  isInProgress: PropTypes.bool,
  count: PropTypes.number,
  className: PropTypes.string,
  showLoadingAnimation: PropTypes.bool
}

export default ShowSkeleton
