import React, { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'

import MultipleCheckboxesSelect from '../../../../components/MultipleCheckboxesSelect'

import { formatOptionsList } from '../../../formatters'
import { createJsonFromQueryString } from '../../../../helpers/url'
import { getProductsService } from '../../../../modules/services/mediaOrdersProducts'

import { selectedControllerIdSelector } from '../../../../modules/selectors/app'

import useStyles from '../../../../pages/Settings/BookedMediaAndFiles/BookedMediaAndFilesFilters/styles'

const MediaProductFilter = ({ Context }) => {
  const classes = useStyles()

  const selfAccountControllerId = useSelector(selectedControllerIdSelector)

  const { productFilterOption, setProductFilterOption } = useContext(Context)

  const formatProductOptions = useCallback(
    products =>
      formatOptionsList({
        list: products,
        valueName: 'id',
        labelName: 'name'
      }),
    []
  )

  const onProductChange = useCallback(
    selectedOptions => {
      setProductFilterOption(selectedOptions)
    },
    [setProductFilterOption]
  )

  const loadOptions = useCallback(
    async (search, loadedOptions, { next }) => {
      const response = await getProductsService({
        search,
        ordering: 'name',
        controller: selfAccountControllerId,
        fields: ['id', 'name'].join(','),
        limit: 100,
        ...createJsonFromQueryString(`?${next.split('?')[1]}`)
      })
      const newProductsOptions = formatProductOptions(response.results)

      return {
        options: newProductsOptions,
        hasMore: !!response.next,
        additional: {
          next: response.next
        }
      }
    },
    [selfAccountControllerId, formatProductOptions]
  )

  return (
    <MultipleCheckboxesSelect
      value={productFilterOption}
      onChange={onProductChange}
      loadOptions={loadOptions}
      // features:
      isSearchable={true}
      handleChangeOnClose={true}
      allowSelectAll={true}
      // styles:
      placeholder="Product"
      className={classes.productSelect}
      isHighlighted={true}
      expandMenu={true}
    />
  )
}

export default MediaProductFilter
