import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Field from '../../../../components/Form/Field'
import InternalLink from '../../../../components/InternalLink'
import RadioBlock from '../../../../components/Form/RadioBlock'

import { formatOptionsList } from '../../../../features/formatters'

import { getLocationLists } from '../../../../modules/actions/location'

import {
  locationListsLoadingSelector,
  locationListsSelector,
  locationListsWasLoadedSelector
} from '../../../../modules/selectors/location'
import { selectedSelfAccountSelector } from '../../../../modules/selectors/app'

import { SHOW_OPTION, SHOW_OPTION_LOCATION_LIST, LOCATION_LIST } from '../fields'

import { ROUTES } from '../../../../constants/routes'

const Locations = ({ formik }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { values, errors, touched, setFieldValue } = formik

  const locationListError = errors[LOCATION_LIST]
  const locationListTouched = touched[LOCATION_LIST]

  const locationLists = useSelector(locationListsSelector)
  const locationListsWasLoaded = useSelector(locationListsWasLoadedSelector)
  const locationListsLoading = useSelector(locationListsLoadingSelector)
  const selectedSelfAccountId = useSelector(selectedSelfAccountSelector)

  const formattedLocationsList = useMemo(
    () =>
      formatOptionsList({
        list: locationLists.map(list => ({
          ...list,
          name: `${list.name} (${list.size})`
        })),
        valueName: 'id',
        labelName: 'name'
      }),
    [locationLists]
  )

  useEffect(() => {
    // getLocationLists for SHOW_OPTION_LOCATION_LIST
    if (!locationListsWasLoaded && !locationListsLoading) {
      dispatch(getLocationLists({ account: selectedSelfAccountId }))
    }
  }, [dispatch, locationListsWasLoaded, locationListsLoading, selectedSelfAccountId])

  return (
    <RadioBlock
      setFieldValue={setFieldValue}
      id="radio_show_option_supermarkets_locations"
      name={SHOW_OPTION}
      value={SHOW_OPTION_LOCATION_LIST}
      selectedValue={values[SHOW_OPTION]}
      label={t('proximityOption.title')}
      error={locationListError}
      touched={locationListTouched}
    >
      <p>{t('proximityOption.description')}</p>
      {locationLists && !!locationLists.length ? (
        <Field
          placeholder="Select a Locations List"
          formik={formik}
          name={LOCATION_LIST}
          options={formattedLocationsList}
          showErrorMessage={false}
        />
      ) : (
        <p>
          {t(`To use this feature, you must have already uploaded the list of locations on the `)}
          <InternalLink to={ROUTES.manageLocationLists}>{t('settings')}</InternalLink>
          {t(' page.')}
        </p>
      )}
    </RadioBlock>
  )
}

export default Locations
