import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles({
  accountId: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    marginLeft: 4,
    transform: 'translateY(-1px)'
  },
  btn: {
    width: 330,
    marginTop: 15
  }
})

export default useStyles
