import React from 'react'

import Field from '../../../../../../../components/Form/Field'
import Step from '../../../../../../../features/components/Forms/StepForm/Step'

import { NAME } from '../../fields'

const stepFields = [NAME]

function NameStep({ formik, handleStepChange }) {
  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  return (
    <Step formTitle="Create an Ad" stepTitle="Give the new ad a name" handleContinue={handleContinue}>
      <Field formik={formik} name={NAME} placeholder="Ad Name" autoComplete="off" />
    </Step>
  )
}

export default NameStep
