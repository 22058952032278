import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import CustomLocationsPreview from '../../../../../ReusableFormSections/CustomLocationsSection/CustomLocationsPreview'

import { campaignCriterionsSelector } from '../../../../../../modules/selectors/campaigns'
import { CAMPAIGN_GOOGLE_CUSTOM_LOCATION_CRITERION_TYPE } from '../../../../../../constants/campaigns'

const CustomLocationsSectionPreview = () => {
  const campaignCriterions = useSelector(campaignCriterionsSelector)

  const filteredCustomLocations = useMemo(
    () =>
      campaignCriterions
        .filter(criterion => criterion.type_ === CAMPAIGN_GOOGLE_CUSTOM_LOCATION_CRITERION_TYPE)
        .map(({ display_name: displayName }) => ({ name: displayName })),
    [campaignCriterions]
  )

  if (!filteredCustomLocations.length) {
    return null
  }

  return (
    <p>
      <CustomLocationsPreview customLocations={filteredCustomLocations} />
    </p>
  )
}

export default CustomLocationsSectionPreview
