import React, { useCallback } from 'react'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import FieldsSection from '../../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../../components/Form/Field'
import FacebookLanguages from '../../../../../../ReusableFormFields/LanguagesFields/FacebookLanguages'
import StartEndDateRangeFields from '../../../../../../ReusableFormFields/StartEndDateRangeFields'
import LocationsRedesign from '../../../../../../ReusableFormFields/GeolocationsFields/LocationsRedesign'
import FacebookRegions from '../../../../../../ReusableFormFields/GeolocationsFields/LocationsRedesign/FacebookRegions'
import BudgetTabs from '../../../../../../ReusableFormFields/BudgetTabs'

import { LANGUAGES, NAME } from '../../../../../../ReusableFormFields/LineItemForms/fields'

import { TIME_START, TIME_STOP } from '../../fields'

import {
  LOCATION_LIST,
  SHOW_COUNTRIES,
  SHOW_REGIONS
} from '../../../../../../ReusableFormFields/GeolocationsFields/fields'

import { FACEBOOK_PLATFORM } from '../../../../../../../constants/selectLists/platformList'
import { BUDGET_DAILY, BUDGET_LIFETIME } from '../../../../../../ReusableFormFields/CampaignForms/fields'

const stepFields = [
  NAME,
  TIME_START,
  TIME_STOP,
  SHOW_COUNTRIES,
  SHOW_REGIONS,
  LOCATION_LIST,
  BUDGET_LIFETIME,
  BUDGET_DAILY
]

const DetailsStep = ({ formik, handleStepChange, showBudgetFormRow }) => {
  const { values, setFieldValue } = formik

  const selectedLanguages = values[LANGUAGES]

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  return (
    <Step
      stepTitle="Enter the ad set details"
      stepDescription="Please provide the following details for the new Meta ad set"
      handleContinue={handleContinue}
    >
      <FieldsSection title="Ad set details">
        <FieldRow title="Name" description="Ad set name">
          <Field formik={formik} name={NAME} placeholder="Ad Set Name" autoComplete="off" />
        </FieldRow>
        <FieldRow title="Dates" description="Start and end date of the ad set">
          <StartEndDateRangeFields
            formik={formik}
            startDateName={TIME_START}
            endDateName={TIME_STOP}
            minDate={new Date()}
          />
        </FieldRow>
        {showBudgetFormRow && (
          <FieldRow
            title="Budget"
            description="Set a lifetime budget (runs for the whole lifetime of the ad set) or a daily budget"
          >
            <BudgetTabs formik={formik} />
          </FieldRow>
        )}
        <LocationsRedesign
          formik={formik}
          rowTitle="Location targeting"
          rowDescription="Select the countries or regions to target, or select a location list"
          TargetByRegionComponent={
            <FacebookRegions formik={formik} showRegionsCountryFilter={true} platform={FACEBOOK_PLATFORM} />
          }
        />
        <FieldRow title="Languages (optional)" description="Select which languages you want to target">
          <FacebookLanguages name={LANGUAGES} value={selectedLanguages} setFieldValue={setFieldValue} />
        </FieldRow>
      </FieldsSection>
    </Step>
  )
}

export default DetailsStep
