import React from 'react'

import MultipleOptionsPreview from '../../MultipleOptionsPreivew'

import { PRIMARY_TEXT_LIST } from '../../../fields'

const PrimaryTextSectionPreview = ({ adValues }) => {
  const primaryTextList = adValues[PRIMARY_TEXT_LIST]

  return <MultipleOptionsPreview optionsList={primaryTextList} />
}

export default PrimaryTextSectionPreview
