import React, { useCallback } from 'react'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import Languages from '../../../../../../ReusableFormFields/LanguagesFields/Languages'

import { LANGUAGES } from '../../../../../../ReusableFormFields/LanguagesFields/fields'

const stepFields = [LANGUAGES]

const LanguagesStep = ({ formik, handleStepChange }) => {
  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  return (
    <Step stepTitle="Which language(s) do you want to target?" handleContinue={handleContinue}>
      <Languages formik={formik} />
    </Step>
  )
}

export default LanguagesStep
