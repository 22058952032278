import { createStyles } from '../../../../../styles/helpers'
import { tabletDown } from '../../../../../styles/const/breakpoints'
import { darkGrey } from '../../../../../styles/const/colors'

const useStyles = createStyles({
  clientSignatorySection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 20
  },
  signatoryDescription: {
    color: darkGrey,
    marginTop: 4
  },
  receiverTabsContainer: {
    minWidth: 225
  },
  approveTitle: {
    marginTop: 40,
    textAlign: 'center'
  },
  submitButtonWrapper: {
    width: 'unset'
  },
  buttons: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,

    [`@media screen and (${tabletDown})`]: {
      '& button': {
        // reset width 100% on tabletDown
        width: ['unset', '!important']
      }
    }
  }
})

export default useStyles
