import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import AuthorizePlatformDetails from '../../../pages/SocialAuthorize/AuthorizePlatformDetails'

import usePlatformConnected from '../../../features/hooks/usePlatformConnected'

const SocialAuthChecker = ({
  children,
  dataFetch,
  adAccountId,
  platform,
  authFailureText,
  hasPadding,
  contentAlign
}) => {
  const dispatch = useDispatch()

  const isPlatformConnected = usePlatformConnected(platform)

  useEffect(() => {
    if (adAccountId && isPlatformConnected) {
      // fetch data if auth correct
      dataFetch && dataFetch()
    }
  }, [dispatch, adAccountId, dataFetch, isPlatformConnected])

  return isPlatformConnected ? (
    children
  ) : (
    <AuthorizePlatformDetails
      selectedPlatform={platform}
      explanationText={authFailureText}
      hasPadding={hasPadding}
      contentAlign={contentAlign}
    />
  )
}

SocialAuthChecker.propTypes = {
  dataFetch: PropTypes.func,
  adAccountId: PropTypes.string,
  platform: PropTypes.string.isRequired,
  authFailureText: PropTypes.string,
  // styling pops:
  hasPadding: PropTypes.bool,
  contentAlign: PropTypes.string
}

export default SocialAuthChecker
