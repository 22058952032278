import { concat } from '../../helpers/common'

const MODULE_NAME = 'COMBINED_DATA/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_COMBINED_CAMPAIGNS = concat(MODULE_NAME, 'GET_COMBINED_CAMPAIGNS')
export const GET_COMBINED_CAMPAIGNS_SUCCESS = concat(MODULE_NAME, 'GET_COMBINED_CAMPAIGNS_SUCCESS')
export const GET_COMBINED_CAMPAIGNS_FAILURE = concat(MODULE_NAME, 'GET_COMBINED_CAMPAIGNS_FAILURE')
export const CLEAR_COMBINED_CAMPAIGNS = concat(MODULE_NAME, 'CLEAR_COMBINED_CAMPAIGNS')

// action is used to manually update data in combined campaigns redux store
export const UPDATE_COMBINED_CAMPAIGN = concat(MODULE_NAME, 'UPDATE_COMBINED_CAMPAIGN')

export const GET_COMBINED_LINE_ITEMS = concat(MODULE_NAME, 'GET_COMBINED_LINE_ITEMS')
export const GET_COMBINED_LINE_ITEMS_SUCCESS = concat(MODULE_NAME, 'GET_COMBINED_LINE_ITEMS_SUCCESS')
export const GET_COMBINED_LINE_ITEMS_FAILURE = concat(MODULE_NAME, 'GET_COMBINED_LINE_ITEMS_FAILURE')
export const CLEAR_COMBINED_LINE_ITEMS = concat(MODULE_NAME, 'CLEAR_COMBINED_LINE_ITEMS')

export const GET_COMBINED_GRAPHS = concat(MODULE_NAME, 'GET_COMBINED_GRAPHS')
export const GET_COMBINED_GRAPHS_SUCCESS = concat(MODULE_NAME, 'GET_COMBINED_GRAPHS_SUCCESS')
export const GET_COMBINED_GRAPHS_FAILURE = concat(MODULE_NAME, 'GET_COMBINED_GRAPHS_FAILURE')
export const CLEAR_COMBINED_GRAPHS = concat(MODULE_NAME, 'CLEAR_COMBINED_GRAPHS')

export const GET_COMBINED_SEGMENTED_DATA = concat(MODULE_NAME, 'GET_COMBINED_SEGMENTED_DATA')
export const GET_COMBINED_SEGMENTED_DATA_SUCCESS = concat(MODULE_NAME, 'GET_COMBINED_SEGMENTED_DATA_SUCCESS')
export const GET_COMBINED_SEGMENTED_DATA_FAILURE = concat(MODULE_NAME, 'GET_COMBINED_SEGMENTED_DATA_FAILURE')
export const CLEAR_COMBINED_SEGMENTED_DATA = concat(MODULE_NAME, 'CLEAR_COMBINED_SEGMENTED_DATA')

export const GET_COMBINED_ADS = concat(MODULE_NAME, 'GET_COMBINED_ADS')
export const GET_COMBINED_ADS_SUCCESS = concat(MODULE_NAME, 'GET_COMBINED_ADS_SUCCESS')
export const GET_COMBINED_ADS_FAILURE = concat(MODULE_NAME, 'GET_COMBINED_ADS_FAILURE')
export const CLEAR_COMBINED_ADS = concat(MODULE_NAME, 'CLEAR_COMBINED_ADS')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// combined campaigns
export function getCombinedCampaigns(data) {
  return { type: GET_COMBINED_CAMPAIGNS, data }
}

export function getCombinedCampaignsSuccess(campaignsData) {
  return { type: GET_COMBINED_CAMPAIGNS_SUCCESS, campaignsData }
}

export function getCombinedCampaignsFailure(error) {
  return { type: GET_COMBINED_CAMPAIGNS_FAILURE, error }
}

export function clearCombinedCampaigns() {
  return { type: CLEAR_COMBINED_CAMPAIGNS }
}

// update combined campaign
export function updateCombinedCampaign(campaignData) {
  return { type: UPDATE_COMBINED_CAMPAIGN, campaignData }
}

// combined line items
export function getCombinedLineItems(data) {
  return { type: GET_COMBINED_LINE_ITEMS, data }
}

export function getCombinedLineItemsSuccess(lineItemsData) {
  return { type: GET_COMBINED_LINE_ITEMS_SUCCESS, lineItemsData }
}

export function getCombinedLineItemsFailure(error) {
  return { type: GET_COMBINED_LINE_ITEMS_FAILURE, error }
}

export function clearCombinedLineItems() {
  return { type: CLEAR_COMBINED_LINE_ITEMS }
}

// combined graphs
export function getCombinedGraphs(data) {
  return { type: GET_COMBINED_GRAPHS, data }
}

export function getCombinedGraphsSuccess(graphsData) {
  return { type: GET_COMBINED_GRAPHS_SUCCESS, graphsData }
}

export function getCombinedGraphsFailure(error) {
  return { type: GET_COMBINED_GRAPHS_FAILURE, error }
}

export function clearCombinedGraphs() {
  return { type: CLEAR_COMBINED_GRAPHS }
}

// combined segmented data
export function getCombinedSegmentedData(data) {
  return { type: GET_COMBINED_SEGMENTED_DATA, data }
}

export function getCombinedSegmentedDataSuccess(segmentedData) {
  return { type: GET_COMBINED_SEGMENTED_DATA_SUCCESS, segmentedData }
}

export function getCombinedSegmentedDataFailure(error) {
  return { type: GET_COMBINED_SEGMENTED_DATA_FAILURE, error }
}

export function clearCombinedSegmentedData() {
  return { type: CLEAR_COMBINED_SEGMENTED_DATA }
}

// combined ads
export function getCombinedAds(data) {
  return { type: GET_COMBINED_ADS, data }
}

export function getCombinedAdsSuccess(adsData) {
  return { type: GET_COMBINED_ADS_SUCCESS, adsData }
}

export function getCombinedAdsFailure(error) {
  return { type: GET_COMBINED_ADS_FAILURE, error }
}

export function clearCombinedAds() {
  return { type: CLEAR_COMBINED_ADS }
}
