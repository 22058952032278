import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { BUDGET_DAILY, BUDGET_LIFETIME } from '../../../../../../ReusableFormFields/LineItemForms/fields'
import { CAMPAIGN_BUDGET } from '../../../fields'
import { formatCurrency } from '../../../../../../../helpers/numbers'

import { lineItemSelector } from '../../../../../../../modules/selectors/lineItems'
import { selectedAdAccountDataSelector } from '../../../../../../../modules/selectors/app'

const BudgetPreview = ({ budgetType }) => {
  const { t } = useTranslation()

  const { currency_symbol } = useSelector(selectedAdAccountDataSelector)
  const lineItem = useSelector(lineItemSelector)

  const { budget_lifetime: budgetLifetime, budget_daily: budgetDaily } = lineItem

  const formattedBudgetLifetime = formatCurrency(budgetLifetime, { min: 2, max: 2 }, { symbol: currency_symbol })

  const formattedBudgetDaily = formatCurrency(budgetDaily, { min: 2, max: 2 }, { symbol: currency_symbol })

  if (budgetType === BUDGET_LIFETIME) {
    return (
      <span>
        {formattedBudgetLifetime} ({t('Lifetime')})
      </span>
    )
  }

  if (budgetType === BUDGET_DAILY) {
    return (
      <span>
        {formattedBudgetDaily} ({t('Daily')})
      </span>
    )
  }

  if (budgetType === CAMPAIGN_BUDGET) {
    return <span>{t('Campaign level budget is set')}</span>
  }

  return null
}

export default BudgetPreview
