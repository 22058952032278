import React from 'react'
import { useTranslation } from 'react-i18next'

import MultiSelectBox from '../../../features/components/Form/MultiSelectBox'

import { tikTokLanguagesList } from '../../../constants/selectLists/languagesList'

const TikTokLanguages = ({ name, value, setFieldValue }) => {
  const { t } = useTranslation()

  return (
    <MultiSelectBox
      placeholder={t('Add a Language')}
      name={name}
      options={tikTokLanguagesList}
      value={value}
      setFieldValue={setFieldValue}
    />
  )
}

export default TikTokLanguages
