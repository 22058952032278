import update from 'immutability-helper'
import {
  INITIAL_CREATE_REDUCER,
  INITIAL_GET_RESULTS_PAGING_REDUCER,
  INITIAL_GET_REDUCER,
  INITIAL_UPDATE_REDUCER
} from '../../constants/reducer'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_SELF_ACCOUNT_CATEGORY,
  GET_SELF_ACCOUNT_CATEGORY_SUCCESS,
  GET_SELF_ACCOUNT_CATEGORY_FAILURE,
  CLEAR_GET_SELF_ACCOUNT_CATEGORY,
  GET_SELF_ACCOUNT_SUB_CATEGORIES,
  GET_SELF_ACCOUNT_SUB_CATEGORIES_SUCCESS,
  GET_SELF_ACCOUNT_SUB_CATEGORIES_FAILURE,
  CLEAR_GET_SELF_ACCOUNT_SUB_CATEGORIES,
  GET_SELF_ACCOUNT_CATEGORIES,
  GET_SELF_ACCOUNT_CATEGORIES_SUCCESS,
  GET_SELF_ACCOUNT_CATEGORIES_FAILURE,
  CLEAR_GET_SELF_ACCOUNT_CATEGORIES,
  GET_SELF_ACCOUNT,
  GET_SELF_ACCOUNT_SUCCESS,
  GET_SELF_ACCOUNT_FAILURE,
  CLEAR_GET_SELF_ACCOUNT,
  GET_SELF_ACCOUNTS,
  GET_SELF_ACCOUNTS_SUCCESS,
  GET_SELF_ACCOUNTS_FAILURE,
  CLEAR_SELF_ACCOUNTS,
  CREATE_SELF_ACCOUNT,
  CREATE_SELF_ACCOUNT_SUCCESS,
  CREATE_SELF_ACCOUNT_FAILURE,
  UPDATE_SELF_ACCOUNT,
  UPDATE_SELF_ACCOUNT_SUCCESS,
  UPDATE_SELF_ACCOUNT_FAILURE,
  CLEAR_UPDATE_SELF_ACCOUNT,
  CLEAR_CREATE_SELF_ACCOUNT
} from '../actions/selfAccounts'
import { updateItem } from '../../helpers/modules/reducerHelpers'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  selfAccounts: INITIAL_GET_RESULTS_PAGING_REDUCER,
  createSelfAccount: INITIAL_CREATE_REDUCER,
  updateSelfAccount: INITIAL_UPDATE_REDUCER,
  categories: INITIAL_GET_RESULTS_PAGING_REDUCER,
  subCategories: INITIAL_GET_RESULTS_PAGING_REDUCER,
  getSelfAccount: INITIAL_GET_REDUCER,
  category: INITIAL_GET_REDUCER
}

export default function selfAccounts(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    // self accounts
    case GET_SELF_ACCOUNTS:
      return update(state, {
        selfAccounts: {
          $merge: {
            ...(action.loadOptions.shouldClearExistingState && INITIAL_GET_RESULTS_PAGING_REDUCER),
            isLoading: true
          }
        }
      })
    case GET_SELF_ACCOUNTS_SUCCESS:
      return update(state, {
        selfAccounts: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: {
              next: action.selfAccountsData?.next
            }
          },
          results: {
            $push: action.selfAccountsData.results
          }
        }
      })
    case GET_SELF_ACCOUNTS_FAILURE:
      return update(state, {
        selfAccounts: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_SELF_ACCOUNTS:
      return update(state, {
        selfAccounts: { $set: initialState.selfAccounts }
      })
    case CREATE_SELF_ACCOUNT:
      return update(state, {
        createSelfAccount: {
          $merge: {
            isLoading: true
          }
        }
      })
    case CREATE_SELF_ACCOUNT_SUCCESS:
      return update(state, {
        selfAccounts: {
          results: { $push: [action.selfAccountData] }
        },
        createSelfAccount: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.selfAccountData
          }
        }
      })
    case CREATE_SELF_ACCOUNT_FAILURE:
      return update(state, {
        createSelfAccount: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_SELF_ACCOUNT:
      return update(state, {
        createSelfAccount: {
          $set: initialState.createSelfAccount
        }
      })
    case UPDATE_SELF_ACCOUNT:
      return update(state, {
        updateSelfAccount: {
          $merge: {
            isLoading: true,
            id: action.id
          }
        }
      })
    case UPDATE_SELF_ACCOUNT_SUCCESS:
      return update(state, {
        selfAccounts: {
          results: {
            $apply: items => updateItem(items, action.selfAccountData)
          }
        },
        updateSelfAccount: {
          $merge: {
            isLoading: false,
            wasUpdated: true
          }
        }
      })
    case UPDATE_SELF_ACCOUNT_FAILURE:
      return update(state, {
        updateSelfAccount: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_SELF_ACCOUNT:
      return update(state, {
        updateSelfAccount: {
          $set: initialState.updateSelfAccount
        }
      })

    // Get categories
    case GET_SELF_ACCOUNT_CATEGORIES:
      return update(state, {
        categories: { $merge: { isLoading: true } }
      })
    case GET_SELF_ACCOUNT_CATEGORIES_SUCCESS:
      return update(state, {
        categories: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data.results
          }
        }
      })
    case GET_SELF_ACCOUNT_CATEGORIES_FAILURE:
      return update(state, {
        categories: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_SELF_ACCOUNT_CATEGORIES:
      return update(state, {
        categories: { $set: initialState.categories }
      })

    // Get sub categories
    case GET_SELF_ACCOUNT_SUB_CATEGORIES:
      return update(state, {
        subCategories: { $merge: { isLoading: true } }
      })
    case GET_SELF_ACCOUNT_SUB_CATEGORIES_SUCCESS:
      return update(state, {
        subCategories: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data.results
          }
        }
      })
    case GET_SELF_ACCOUNT_SUB_CATEGORIES_FAILURE:
      return update(state, {
        subCategories: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_SELF_ACCOUNT_SUB_CATEGORIES:
      return update(state, {
        subCategories: { $set: initialState.subCategories }
      })

    // Get self account
    case GET_SELF_ACCOUNT:
      return update(state, {
        getSelfAccount: { $merge: { isLoading: true } }
      })
    case GET_SELF_ACCOUNT_SUCCESS:
      return update(state, {
        getSelfAccount: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        }
      })
    case GET_SELF_ACCOUNT_FAILURE:
      return update(state, {
        getSelfAccount: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_SELF_ACCOUNT:
      return update(state, {
        getSelfAccount: { $set: initialState.getSelfAccount }
      })

    // Get category
    case GET_SELF_ACCOUNT_CATEGORY:
      return update(state, {
        category: { $merge: { isLoading: true } }
      })
    case GET_SELF_ACCOUNT_CATEGORY_SUCCESS:
      return update(state, {
        category: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        }
      })
    case GET_SELF_ACCOUNT_CATEGORY_FAILURE:
      return update(state, {
        category: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_SELF_ACCOUNT_CATEGORY:
      return update(state, {
        category: { $set: initialState.category }
      })

    default:
      return state
  }
}
