import { createStyles } from '../../../../../../../styles/helpers'
import { smallDesktopDown, smallPhonesDown } from '../../../../../../../styles/const/breakpoints'

const useStyles = createStyles({
  graphTableSection: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '30px',
    gap: '30px',
    alignItems: 'flex-start' /* Prevents height stretching */
  },
  [`@media screen and (${smallDesktopDown})`]: {
    graphTableSection: {
      flexDirection: 'column'
    }
  },
  [`@media screen and (${smallPhonesDown})`]: {
    graphTableSection: {
      padding: 10
    }
  }
})

export default useStyles
