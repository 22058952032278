import React from 'react'
import { components } from 'react-select'
import classnames from 'classnames'

import { useStyles } from '../styles'

const SelectValueContainer = props => {
  const { title, isIconSelect, showIndicator, showClearInputIcon, valueContainerClassName } = props.selectProps

  const classes = useStyles({ isIconSelect, showIndicator, showClearInputIcon })

  return (
    <div className={classnames(classes.valueContainer, valueContainerClassName)}>
      {title && <span className={classes.title}>{title}</span>}
      <components.ValueContainer {...props} />
    </div>
  )
}

export default SelectValueContainer
