import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as InfoIcon } from '../../../../../../assets/icons/info-circle.svg'

import { getRandomizedValues } from '../helpers'
import { capitalizeWords, formatStringWithUnderscore } from '../../../../../../helpers/common'
import { formatOptionsList } from '../../../../../../features/formatters'

import { googleAdsCallToActionList } from '../../../../../../constants/selectLists/googleAdsCallToActionList'

import { BUSINESS_NAME, DESCRIPTIONS_LIST, FILE_URL, HEADLINES_LIST, SQUARE_LOGO } from '../../../fields'
import { CALL_TO_ACTION } from '../../../../../ReusableFormFields/CallToActionFields/fields'

import useStyles from './styles'

const GoogleAdPreviewDisplay1 = React.memo(({ values }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const randomizedValues = useMemo(() => {
    return getRandomizedValues(values)
  }, [values])

  const actionValue = randomizedValues[CALL_TO_ACTION]

  const description = randomizedValues[DESCRIPTIONS_LIST][0]?.text
  const headline = randomizedValues[HEADLINES_LIST][0]?.text
  const businessName = randomizedValues[BUSINESS_NAME]
  const fileUrl = randomizedValues[SQUARE_LOGO][0]?.[FILE_URL]

  const formattedActionsList = useMemo(
    () =>
      formatOptionsList({
        list: googleAdsCallToActionList,
        labelName: 'label',
        valueName: 'name'
      }),
    []
  )

  const selectedAction = formattedActionsList?.find(action => action.value === actionValue)

  const selectedActionLabel = selectedAction?.label || capitalizeWords(formatStringWithUnderscore(actionValue), false)

  return (
    <section className={classes.adPreviewContainer}>
      <div className={classes.adPreview}>
        <header className={classes.header}>
          <div className={classes.title}>{t(headline)}</div>
          <div className={classes.icon}>
            <InfoIcon />
          </div>
        </header>
        <div className={classes.content}>
          <div className={classes.logo}>
            <img src={fileUrl} alt="logo" />
          </div>
          <div className={classes.info}>
            <p className={classes.description}>{t(description)}</p>
            <div className={classes.footer}>
              <div>
                <span className={classes.adIcon}>{t('Ad')}</span>
                <span className={classes.businessName}>{t(businessName)}</span>
              </div>
              <div className={classes.callToActionButton}>{t(selectedActionLabel)}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})

export default GoogleAdPreviewDisplay1
