import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from '../../../../../../helpers/numbers'
import { AMOUNT_MICROS, CAMPAIGN_BUDGET } from '../../../../../ReusableFormFields/CampaignForms/fields'

import { campaignSelector } from '../../../../../../modules/selectors/campaigns'
import { selectedAdAccountsSelector } from '../../../../../../modules/selectors/app'
import { GOOGLE_PLATFORM } from '../../../../../../constants/selectLists/platformList'

const BudgetDailySectionPreview = () => {
  const { t } = useTranslation()

  const { [GOOGLE_PLATFORM]: adAccountData } = useSelector(selectedAdAccountsSelector)
  const { currency_symbol } = adAccountData
  const campaign = useSelector(campaignSelector)

  const formattedBudget = useMemo(
    () => formatCurrency(campaign[CAMPAIGN_BUDGET][AMOUNT_MICROS], { min: 2, max: 2 }, { symbol: currency_symbol }),
    [campaign, currency_symbol]
  )

  return (
    <p>
      {formattedBudget} ({t('Daily')})
    </p>
  )
}

export default BudgetDailySectionPreview
