import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Icon from '../../../../../components/Icon'
import QuotationCreateAndSend from './QuotationCreateAndSend'
import InfoModal from '../../../Modals/InfoModal'
import { ReactComponent as AlertIcon } from '../../../../../assets/icons/alertIcon.svg'

import {
  selectedControllerDataSelector,
  selectedSelfAccountDataSelector,
  selectedSelfAccountSelector
} from '../../../../../modules/selectors/app'

import { clearMembersList, getMembersList } from '../../../../../modules/actions/selfAccountTeam'

import useStyles from './styles'

function NoSignPermissionsModal({ isOpen, onModalClose, contractId, allowCreateQuotation }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const selfAccount = useSelector(selectedSelfAccountSelector)
  const selectedController = useSelector(selectedControllerDataSelector)
  const selectedSelfAccount = useSelector(selectedSelfAccountDataSelector)

  const controllerName = selectedController?.name
  const selfAccountName = selectedSelfAccount?.name

  useEffect(() => {
    dispatch(getMembersList({ account: selfAccount }))
  }, [dispatch, selfAccount])

  useEffect(() => {
    return () => {
      dispatch(clearMembersList())
    }
  }, [dispatch])

  const article = useMemo(() => {
    const startsWithVowel = ['a', 'e', 'i', 'o', 'u'].includes(selfAccountName.charAt(0).toLowerCase())
    return startsWithVowel ? 'an' : 'a'
  }, [selfAccountName])

  return (
    <InfoModal
      isOpen={isOpen}
      onClose={onModalClose}
      classNameBody={classes.modalBody}
      contentClassName={classes.modalContent}
    >
      <Icon notHoverable className={classes.alertIcon}>
        <AlertIcon />
      </Icon>
      <h2 className={classes.headline}>{t('You don’t have the signatory permission to sign contracts')}</h2>

      {allowCreateQuotation && (
        <>
          <p>{t('noSignatoryPermissions', { controllerName, selfAccountName, article })}</p>
          <QuotationCreateAndSend contractId={contractId} buttonText={t('Send as Quotation')} />
        </>
      )}
    </InfoModal>
  )
}

NoSignPermissionsModal.propTypes = {
  isOpen: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  contractId: PropTypes.number,
  allowCreateQuotation: PropTypes.bool
}
export default NoSignPermissionsModal
