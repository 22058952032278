import {
  DV_360_PLATFORM,
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  TIKTOK_PLATFORM
} from '../../../constants/selectLists/platformList'
import { OPTION_NO } from '../../../constants/forms'

export const MARGIN_OPTION = 'marginOption'
export const SAME_MARGIN = 'sameMargin'
export const DIFFERENT_MARGIN = 'differentMargin'
export const NO_MARGIN = 'noMargin'

export const GENERAL_MARGIN = 'generalMargin'
export const MARGINS = 'margins'

export const FEE_OPTION = 'feeOption'
export const FEE = 'fee'

export const initialMargins = {
  [FACEBOOK_PLATFORM]: '',
  [GOOGLE_PLATFORM]: '',
  [TIKTOK_PLATFORM]: '',
  [DV_360_PLATFORM]: ''
}

export const marginsInitialValues = {
  [MARGIN_OPTION]: SAME_MARGIN,
  [GENERAL_MARGIN]: '',
  [MARGINS]: { ...initialMargins },
  [FEE_OPTION]: OPTION_NO,
  [FEE]: ''
}

export const getMarginWithFee = (margin, fee) => (margin * (1 - fee) + fee).toFixed(4)
