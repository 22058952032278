import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  categoryFilter: {
    width: 120
  },
  subCategory: {
    minWidth: 140,
    maxWidth: 140
  },
  subSubCategory: {
    minWidth: 160,
    maxWidth: 160
  }
})

export default useStyles
