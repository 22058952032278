import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './styles'

function AdCardDetails({ title, subTitle }) {
  const classes = useStyles()

  return (
    <div className={classes.details}>
      {title && <h3 className={classes.title}>{title}</h3>}
      {subTitle && <h4 className={classes.subTitle}>{subTitle}</h4>}
    </div>
  )
}

export default AdCardDetails

AdCardDetails.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string
}
