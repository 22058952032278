import update from 'immutability-helper'
import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  VERIFY_TOTP_TOKEN,
  VERIFY_TOTP_TOKEN_SUCCESS,
  VERIFY_TOTP_TOKEN_FAILURE,
  CLEAR_VERIFY_TOTP_TOKEN,
  SUBMIT_LOGIN,
  SUBMIT_LOGIN_SUCCESS,
  SUBMIT_LOGIN_MFA_REQUIRED_SUCCESS,
  SUBMIT_LOGIN_FAILURE,
  CLEAR_SUBMIT_LOGIN,
  SUBMIT_MFA_CODE,
  SUBMIT_MFA_CODE_SUCCESS,
  SUBMIT_MFA_CODE_FAILURE,
  CLEAR_SUBMIT_MFA_CODE,
  SUBMIT_LOGOUT_SUCCESS,
  CHECK_AUTH_STATUS_FAILURE,
  CHECK_AUTH_STATUS_SUCCESS,
  CREATE_PASSWORD,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_FAILURE,
  CREATE_NEW_PASSWORD,
  CREATE_NEW_PASSWORD_SUCCESS,
  CREATE_NEW_PASSWORD_FAILURE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  GET_TOTP_TOKEN,
  GET_TOTP_TOKEN_SUCCESS,
  GET_TOTP_TOKEN_FAILURE,
  CLEAR_GET_TOTP_TOKEN,
  CLEAR_COGNITO_STORE,
  SUBMIT_MFA_TYPE,
  SUBMIT_MFA_TYPE_SUCCESS,
  SUBMIT_MFA_TYPE_FAILURE,
  CLEAR_SUBMIT_MFA_TYPE
} from '../actions/cognito'
import { INITIAL_CREATE_REDUCER, INITIAL_LOADING_REDUCER } from '../../constants/reducer'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  createPassword: {},
  createPasswordError: {},
  resetPassword: {},
  resetPasswordError: '',
  isAuthorized: false,
  initialAuthChecking: true,
  isLoading: false,
  mfaWasRequested: false,
  mfaType: '',
  nextStep: {},
  mfaTypeSubmit: {
    ...INITIAL_LOADING_REDUCER,
    wasAccepted: false
  },
  mfaCodeSubmit: {
    ...INITIAL_LOADING_REDUCER,
    wasAccepted: false
  },
  getTOTPToken: {
    ...INITIAL_LOADING_REDUCER,
    wasLoaded: false,
    token: null
  },
  verifyTOTPToken: INITIAL_CREATE_REDUCER,
  user: {},
  error: {}
}

export default function cognito(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // submit login
    case SUBMIT_LOGIN:
      return update(state, {
        isLoading: { $set: true }
      })
    case SUBMIT_LOGIN_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        isAuthorized: { $set: true },
        user: { $set: action.userData }
      })
    case SUBMIT_LOGIN_MFA_REQUIRED_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        mfaWasRequested: { $set: true },
        mfaType: { $set: action.mfaType },
        nextStep: { $set: action.nextStep }
      })
    case SUBMIT_LOGIN_FAILURE:
      return update(state, {
        isLoading: { $set: false },
        error: { $set: action.error }
      })
    case CLEAR_SUBMIT_LOGIN:
      return update(state, {
        isLoading: { $set: initialState.isLoading },
        error: { $set: initialState.error },
        mfaWasRequested: { $set: initialState.mfaWasRequested },
        mfaType: { $set: initialState.mfaType },
        user: { $set: initialState.user }
      })

    // submit mfa type to cognito
    case SUBMIT_MFA_TYPE:
      return update(state, {
        mfaTypeSubmit: {
          $merge: {
            isLoading: true
          }
        }
      })
    case SUBMIT_MFA_TYPE_SUCCESS:
      return update(state, {
        mfaTypeSubmit: {
          $merge: {
            isLoading: false,
            wasAccepted: true
          }
        }
      })
    case SUBMIT_MFA_TYPE_FAILURE:
      return update(state, {
        mfaTypeSubmit: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_SUBMIT_MFA_TYPE:
      return update(state, {
        mfaTypeSubmit: {
          $set: initialState.mfaTypeSubmit
        }
      })
    // submit mfa code
    case SUBMIT_MFA_CODE:
      return update(state, {
        mfaCodeSubmit: {
          $merge: {
            isLoading: true
          }
        }
      })
    case SUBMIT_MFA_CODE_SUCCESS:
      return update(state, {
        mfaCodeSubmit: {
          $merge: {
            isLoading: false,
            wasAccepted: true
          }
        },
        isAuthorized: { $set: true },
        user: { $set: action.userData }
      })
    case SUBMIT_MFA_CODE_FAILURE:
      return update(state, {
        mfaCodeSubmit: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_SUBMIT_MFA_CODE:
      return update(state, {
        mfaCodeSubmit: {
          $set: initialState.mfaCodeSubmit
        }
      })

    // submit logout
    case SUBMIT_LOGOUT_SUCCESS:
      return update(state, {
        initialAuthChecking: { $set: false },
        isAuthorized: { $set: false }
      })
    case CHECK_AUTH_STATUS_SUCCESS:
      return update(state, {
        initialAuthChecking: { $set: false },
        isAuthorized: { $set: true },
        user: { $set: action.authData }
      })
    case CHECK_AUTH_STATUS_FAILURE:
      return update(state, {
        initialAuthChecking: { $set: false },
        isAuthorized: { $set: false },
        error: { $set: action.error }
      })
    case CREATE_PASSWORD:
    case CREATE_NEW_PASSWORD:
    case RESET_PASSWORD:
      return update(state, {
        isLoading: { $set: true }
      })
    case CREATE_PASSWORD_SUCCESS:
    case CREATE_NEW_PASSWORD_SUCCESS:
      return update(state, {
        createPassword: { $set: action.createPasswordData },
        isLoading: { $set: false }
      })
    case CREATE_PASSWORD_FAILURE:
    case CREATE_NEW_PASSWORD_FAILURE:
      return update(state, {
        isLoading: { $set: false },
        createPasswordError: { $set: action.error }
      })
    case RESET_PASSWORD_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        resetPassword: { $set: action.resetPasswordData }
      })
    case RESET_PASSWORD_FAILURE:
      return update(state, {
        isLoading: { $set: false },
        resetPasswordError: { $set: action.error }
      })

    // Get TOTP token
    case GET_TOTP_TOKEN:
      return update(state, {
        getTOTPToken: { $merge: { isLoading: true } }
      })
    case GET_TOTP_TOKEN_SUCCESS:
      return update(state, {
        getTOTPToken: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            token: action.data?.sharedSecret
          }
        }
      })
    case GET_TOTP_TOKEN_FAILURE:
      return update(state, {
        getTOTPToken: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_TOTP_TOKEN:
      return update(state, {
        getTOTPToken: { $set: initialState.getTOTPToken }
      })

    // Verify TOTP token
    case VERIFY_TOTP_TOKEN:
      return update(state, {
        verifyTOTPToken: { $merge: { isLoading: true } }
      })
    case VERIFY_TOTP_TOKEN_SUCCESS:
      return update(state, {
        verifyTOTPToken: {
          $merge: {
            isLoading: false,
            wasCreated: true
          }
        }
      })
    case VERIFY_TOTP_TOKEN_FAILURE:
      return update(state, {
        verifyTOTPToken: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_VERIFY_TOTP_TOKEN:
      return update(state, {
        verifyTOTPToken: { $set: initialState.verifyTOTPToken }
      })

    case CLEAR_COGNITO_STORE:
      return update(state, {
        $set: initialState
      })
    default:
      return state
  }
}
