import React from 'react'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import PriceChangePeriodCreateForm from './PriceChangePeriodCreateForm'

import { PRICE_CHANGE_PERIOD_CREATE } from '../../../../../constants/forms'

function PriceChangePeriodCreate() {
  return (
    <FormDrawerWrapper
      formName={PRICE_CHANGE_PERIOD_CREATE}
      title="Create a new price change period"
      description="Increase or decrease the price of products by media category and location during specific time periods"
      openButtonText="Create Price Change Period"
    >
      <PriceChangePeriodCreateForm />
    </FormDrawerWrapper>
  )
}

export default PriceChangePeriodCreate
