import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import MultiSelectBox from '../../../../../../../features/components/Form/MultiSelectBox'
import FieldLabelWrapper from '../../../../../../../features/components/Form/FieldLabelWrapper'

import { getChoicesCategories } from '../../../../../../../modules/actions/choices'
import {
  choicesParentCategoriesLoadingSelector,
  choicesParentCategoriesSelector
} from '../../../../../../../modules/selectors/choices'
import { selectedAdAccountIdSelector } from '../../../../../../../modules/selectors/app'

import { PARENT_CATEGORIES } from '../../../../../../ReusableFormFields/LineItemForms/fields'
import { formatNumber } from '../../../../../../../helpers/numbers'

const CategoriesContent = ({ values, setFieldValue }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const categories = useSelector(choicesParentCategoriesSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const categoriesLoading = useSelector(choicesParentCategoriesLoadingSelector)

  const formattedCategories = useMemo(
    () =>
      categories &&
      categories.map(item => {
        const { audience_size_lower_bound: audienceSizeLowerBound, audience_size_upper_bound: audienceSizeUpperBound } =
          item

        // add comma separators to the numbers, and add the word people after it
        const description =
          audienceSizeLowerBound &&
          audienceSizeUpperBound &&
          `${formatNumber(audienceSizeLowerBound, { min: 0, max: 0 })} - ${formatNumber(audienceSizeUpperBound, {
            min: 0,
            max: 0
          })} ${t('people')}`

        return {
          value: item.id,
          label: item.name,
          ...(description && {
            description
          })
        }
      }),
    [categories, t]
  )

  useEffect(() => {
    // get options for PARENT_CATEGORIES
    dispatch(
      getChoicesCategories({
        // the ad account is needed for user auth and selection auth_user
        account: selectedAdAccountId
      })
    )
  }, [dispatch, selectedAdAccountId])

  return (
    <FieldLabelWrapper label="Family Status">
      <MultiSelectBox
        placeholder={t('Add a Category')}
        options={formattedCategories}
        name={PARENT_CATEGORIES}
        value={values[PARENT_CATEGORIES]}
        isLoading={categoriesLoading}
        setFieldValue={setFieldValue}
        isSearchable={false}
      />
    </FieldLabelWrapper>
  )
}

export default CategoriesContent
