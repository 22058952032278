import { createStyles } from '../../../../../../styles/helpers'

const useStyles = createStyles({
  recipientsHeader: {
    alignItems: 'center'
  },
  recipientsHeaderTitle: {
    fontSize: 14,
    minWidth: 110
  },
  horizontalRow: {
    margin: '12px 0',
    opacity: '0.4'
  }
})

export default useStyles
