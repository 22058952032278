import { createStyles } from '../../../../../../styles/helpers'

import { darkGrey, lightGrey2 } from '../../../../../../styles/const/colors'

const useStyles = createStyles(theme => ({
  previewContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexGrow: 1
  },
  filePreview: {
    width: 60,
    height: 60,
    objectFit: 'contain'
  },
  uploadedFile: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 0'
  },
  uploadedFileControls: {
    width: '50px',
    borderLeft: `1px solid ${lightGrey2}`,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fileTypeIcon: {
    position: 'relative',
    color: lightGrey2,

    '& svg': {
      height: 42
    }
  },
  fileFormat: {
    width: '100%',
    position: 'absolute',
    fontSize: 10,
    bottom: 6,
    fontStyle: 'normal',
    display: 'block',
    textAlign: 'center',
    fontWeight: '700'
  },
  downloadIcon: {
    width: '25px',
    height: '25px',
    fontSize: '14px',

    '& svg path': {
      fill: darkGrey
    },
    '&:hover': {
      '& svg path': {
        fill: [theme.brandPrimary, '!important']
      }
    }
  },
  deleteBtn: {
    width: '25px !important',
    height: '25px !important',
    minWidth: 'unset',
    minHeight: 'unset'
  }
}))

export default useStyles
