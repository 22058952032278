import { formatScheduleDatesToBE } from '../../../features/formatters'

export const SCHEDULE_OPTION = 'schedule_option'
export const SCHEDULE_OPTION_ALL_THE_TIME = 'schedule_option_all_the_time'
export const SCHEDULE_OPTION_SPECIFIC_DAYS = 'schedule_option_specific_days'
export const SCHEDULE_OPTION_SPECIFIC_TIMES = 'schedule_option_specific_times'
export const SCHEDULE_OPTION_BASED_ON_WEATHER = 'schedule_option_based_on_weather'
export const SCHEDULE_MONDAY = 'schedule_monday'
export const SCHEDULE_TUESDAY = 'schedule_tuesday'
export const SCHEDULE_WEDNESDAY = 'schedule_wednesday'
export const SCHEDULE_THURSDAY = 'schedule_thursday'
export const SCHEDULE_FRIDAY = 'schedule_friday'
export const SCHEDULE_SATURDAY = 'schedule_saturday'
export const SCHEDULE_SUNDAY = 'schedule_sunday'
export const SCHEDULE_DATES = 'schedule_dates'

export const scheduleInitialValues = {
  [SCHEDULE_OPTION]: SCHEDULE_OPTION_ALL_THE_TIME,
  [SCHEDULE_MONDAY]: false,
  [SCHEDULE_TUESDAY]: false,
  [SCHEDULE_WEDNESDAY]: false,
  [SCHEDULE_THURSDAY]: false,
  [SCHEDULE_FRIDAY]: false,
  [SCHEDULE_SATURDAY]: false,
  [SCHEDULE_SUNDAY]: false,
  [SCHEDULE_DATES]: []
}

export const formatScheduleToBE = ({
  schedule_option,
  schedule_monday,
  schedule_tuesday,
  schedule_wednesday,
  schedule_thursday,
  schedule_friday,
  schedule_saturday,
  schedule_sunday,
  schedule_dates,
  location,
  temperature,
  forecastRange,
  selectedAdAccountTimezone,
  selectedSelfAccountId
}) => {
  switch (schedule_option) {
    case SCHEDULE_OPTION_ALL_THE_TIME:
      return {
        pacing: ['standard']
      }
    case SCHEDULE_OPTION_SPECIFIC_DAYS:
      const fullDayTemplate = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]

      return {
        pacing: ['day_parting'],
        schedule: {
          ...(schedule_sunday && { 0: fullDayTemplate }),
          ...(schedule_monday && { 1: fullDayTemplate }),
          ...(schedule_tuesday && { 2: fullDayTemplate }),
          ...(schedule_wednesday && { 3: fullDayTemplate }),
          ...(schedule_thursday && { 4: fullDayTemplate }),
          ...(schedule_friday && { 5: fullDayTemplate }),
          ...(schedule_saturday && { 6: fullDayTemplate })
        }
      }
    case SCHEDULE_OPTION_SPECIFIC_TIMES:
      return {
        pacing: ['day_parting'],
        schedule: formatScheduleDatesToBE(schedule_dates)
      }
    case SCHEDULE_OPTION_BASED_ON_WEATHER:
      return {
        trigger: {
          direction: 'raise',
          timezone: selectedAdAccountTimezone,
          temperature: Number(temperature),
          forecast_range: forecastRange,
          location: location,
          account: selectedSelfAccountId
        }
      }
    default:
      return {}
  }
}
