import { createStyles, isColorDark } from '../../../../../styles/helpers'

import { textGrey } from '../../../../../styles/const/colors'
import { tabletDown } from '../../../../../styles/const/breakpoints'

const useStyles = createStyles(theme => ({
  '@keyframes fadeInBottom': {
    from: {
      opacity: 0,
      transform: 'translateY(100%)'
    },
    to: {
      opacity: 1
    }
  },
  fadeInHeader: {
    animationName: '$fadeInBottom',
    animation: '1s ease-in-out'
  },
  fadeInDescription: {
    animationName: '$fadeInBottom',
    animation: '1.2s ease-in-out'
  },
  fadeInButton: {
    animationName: '$fadeInBottom',
    animation: '1.3s ease-in-out'
  },

  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 675,
    backgroundImage: data => (data?.banner ? `url(${data.banner})` : theme.brandPrimary),
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    padding: '40px 150px'
  },
  content: {
    maxWidth: '55%'
  },
  bannerColumn: {
    width: '100%',
    position: 'relative',
    maxWidth: 515,
    maxHeight: 410
  },
  header: {
    color: theme.brandPrimaryTextContrastColor,
    fontSize: 48,
    fontWeight: 600,
    marginBottom: 28
  },
  description: {
    fontSize: 16,
    marginTop: 14,
    color: theme.brandPrimaryTextContrastColor
  },
  button: () => {
    // the button will be rendered on brandPrimary background, if it's dark, we need to custom white button
    // todo think of moving these styles to Button level, if we ever need it in the other place
    const isThemeBrandPrimaryDark = isColorDark(theme.brandPrimary)

    return {
      marginTop: 32,
      ...(isThemeBrandPrimaryDark
        ? {
            color: `${theme.brandPrimary} !important`,
            borderColor: 'white !important',
            backgroundColor: 'white !important',
            '&.buttonTouched, &:active': {
              color: 'white !important',
              borderColor: 'white !important',
              backgroundColor: `${theme.brandPrimary} !important`
            },
            '@media (hover: hover)': {
              '&:hover': {
                color: 'white !important',
                borderColor: 'white !important',
                backgroundColor: `${theme.brandPrimary} !important`
              }
            }
          }
        : {
            color: `${theme.brandPrimaryTextContrastColor} !important`,
            borderColor: `${textGrey} !important`,
            '&.buttonTouched, &:active': {
              borderColor: `${textGrey} !important`
            },
            '@media (hover: hover)': {
              '&:hover': {
                borderColor: `${textGrey} !important`
              }
            }
          })
    }
  },
  banner: {
    maxWidth: '100%',
    maxHeight: '100%'
  },

  [`@media screen and (${tabletDown})`]: {
    section: {
      padding: '32px 24px'
    },
    content: {
      maxWidth: 'unset',
      padding: 0
    },
    header: {
      fontSize: 32,
      marginBottom: 20
    },
    description: {
      fontSize: 14
    },
    button: () => ({
      width: 'auto',
      marginTop: 32
    }),
    bannerColumn: {
      maxWidth: '100%',
      height: 'auto',
      display: 'flex',
      justifyContent: 'flex-end'
    },
    banner: {
      maxWidth: 'unset'
    }
  }
}))

export default useStyles
