import { createStyles } from '../../../../../../../styles/helpers'

export const useStyles = createStyles({
  increase: {
    color: '#36BF54'
  },
  decrease: {
    color: '#FF4B55'
  }
})
