import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import TikTokVideoUpload from '../../../../../../AdForms/AdTikTokCreate/AdTikTokCreateForm/Steps/VideoStep/TikTokVideoUpload'

import {
  clearTikTokVideoThumbnails,
  getTikTokVideoThumbnails
} from '../../../../../../../../modules/actions/tikTokConstants'
import { FILE_URL } from '../../../../../../../ReusableFormFields/AdFileUpload/fields'

const VideoUploadField = ({ adAccountId, formik }) => {
  const dispatch = useDispatch()

  const onVideoRemoveHandler = useCallback(() => {
    dispatch(clearTikTokVideoThumbnails())
  }, [dispatch])

  const videoUrl = formik.values[FILE_URL]

  // if new video is selected, and it's different from previous one, load new thumbnails
  useEffect(() => {
    if (videoUrl) {
      dispatch(
        getTikTokVideoThumbnails({
          video_url: videoUrl,
          num_of_covers: 1
        })
      )
    }
  }, [dispatch, videoUrl])

  return (
    <FieldRow
      title="Video file"
      description="File Type: .mp4, .mov, .mpeg, .3gp, or .avi
Aspect ratio: 9:16
Min Resolution: 540x960px
Duration: 5 - 60s
Bitrate: ≥516 kbps"
    >
      <TikTokVideoUpload formik={formik} adAccountId={adAccountId} onVideoRemoveHandler={onVideoRemoveHandler} />
    </FieldRow>
  )
}

VideoUploadField.propTypes = {
  adAccountId: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired
}

export default VideoUploadField
