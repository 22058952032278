import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { renderArrayObjectValueWithComas } from '../../../../../Facebook/LineItemForms/LineItemFacebookEdit/Sections/CustomAudiencesSection/helpers'
import { populateItemsByIdsArray } from '../../helpers'

import { lineItemSelector } from '../../../../../../modules/selectors/lineItems'
import { tikTokCustomAudiencesSelector } from '../../../../../../modules/selectors/tikTokConstants'

const CustomAudiencesSectionPreview = () => {
  const { t } = useTranslation()

  const { audience, excluded_audience: excludedAudience } = useSelector(lineItemSelector)
  const tikTokAudiences = useSelector(tikTokCustomAudiencesSelector)

  const populatedAudience = useMemo(
    () =>
      populateItemsByIdsArray({
        idsArray: audience,
        itemsList: tikTokAudiences,
        itemIdFieldName: 'audience_id',
        itemFallbackNameString: 'Audience'
      }),
    [audience, tikTokAudiences]
  )

  const populatedExcludedAudience = useMemo(
    () =>
      populateItemsByIdsArray({
        idsArray: excludedAudience,
        itemsList: tikTokAudiences,
        itemIdFieldName: 'audience_id',
        itemFallbackNameString: 'Audience'
      }),
    [excludedAudience, tikTokAudiences]
  )

  if (!audience?.length && !excludedAudience?.length) {
    return null
  }

  return (
    <p>
      {!!populatedAudience?.length && (
        <span>
          {t('Custom audience inclusions: ')}
          {renderArrayObjectValueWithComas(populatedAudience, 'name')}
        </span>
      )}
      {!!populatedExcludedAudience?.length && (
        <span>
          {t('Custom audience exclusions: ')}
          {renderArrayObjectValueWithComas(populatedExcludedAudience, 'name')}
        </span>
      )}
    </p>
  )
}

export default CustomAudiencesSectionPreview
