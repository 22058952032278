import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Field from '../../../../../components/Form/Field'
import Form from '../../../../../components/Form'

import { transformValuesToBE } from '../../formatters'
import { clearCreateAgency, createAgency } from '../../../../../modules/actions/agencies'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'

import {
  createAgencyErrorSelector,
  createAgencyIsLoadingSelector,
  createAgencyWasCreatedSelector
} from '../../../../../modules/selectors/agencies'

import { AGENCY_CREATE } from '../../../../../constants/forms'
import { validationSchema } from '../../validation'
import { initialValues } from './fields'
import { NAME } from '../../fields'

function AgencyCreateForm() {
  const dispatch = useDispatch()

  const agencyWasCreated = useSelector(createAgencyWasCreatedSelector)
  const selectedControllerId = useSelector(selectedControllerIdSelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values)

      dispatch(
        createAgency({
          controller: selectedControllerId,
          ...transformedData
        })
      )
    },
    [dispatch, selectedControllerId]
  )
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const clearCreateItemHandler = useCallback(() => dispatch(clearCreateAgency()), [dispatch])

  return (
    <Form
      formik={formik}
      formName={AGENCY_CREATE}
      // processing
      successSubmit={agencyWasCreated}
      errorSelector={createAgencyErrorSelector}
      isLoadingSelector={createAgencyIsLoadingSelector}
      // after form submit
      clearHandler={clearCreateItemHandler}
    >
      <Field formik={formik} id={NAME} name={NAME} placeholder="Name" />
    </Form>
  )
}

export default AgencyCreateForm
