import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import ActionsDropdown from '../../../../../features/components/ActionsDropdown'
import NameField from './NameField'
import Table from '../../../../../components/Table'
import UploadedFilesField from '../../../../../features/components/UploadedFilesField'
import ActionsButtonsList from '../../../../../features/components/ActionsButtonsList'

import { redirectTo } from '../../../../../helpers/url'
import { formatCurrency } from '../../../../../helpers/numbers'

import {
  updateBookedMediaFileSelector,
  updateBookedMediaFileWasUpdatedSelector
} from '../../../../../modules/selectors/mediaOrdersBookings'
import { clearUpdateBookingMediaFile, updateBookingMediaFile } from '../../../../../modules/actions/mediaOrdersBookings'
import { isControllerBuyerApprovalSelector } from '../../../../../modules/selectors/app'

import { tableColumnsSize } from '../index'

import { APPROVED, getApprovalActionStatus } from '../../../../../features/components/UploadedFilesField/helpers'
import { useChangeFileStatusOnDisapproved } from '../../../../../features/hooks/bookedMediaUploadedFilesHooks'

import { ROUTE_PARAMS, ROUTES } from '../../../../../constants/routes'
import { INTERNAL_ID } from '../../../../../forms/Multiplatform/MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'

import { phonesDownSize } from '../../../../../styles/const/breakpoints'

const BookedMediaAndFilesTableData = ({ bookedMedias }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const { booked_media: bookedMediaId, isLoading, data, wasUpdated } = useSelector(updateBookedMediaFileSelector)
  const isControllerBuyerApproval = useSelector(isControllerBuyerApprovalSelector)
  const bookedMediaFileWasUpdated = useSelector(updateBookedMediaFileWasUpdatedSelector)

  const itemUpdatingId = useMemo(() => {
    if (isLoading) {
      return bookedMediaId
    } else {
      return undefined
    }
  }, [isLoading, bookedMediaId])

  const goToMediaOrderSummaryHandler = useCallback(
    (e, itemId) => {
      const currentItem = bookedMedias?.find(item => item.id === itemId)
      redirectTo(ROUTES.mediaOrderSummary, {
        [ROUTE_PARAMS.selfAccount]: Number(currentItem?.account_id),
        [ROUTE_PARAMS.mediaOrderId]: currentItem?.media_order
      })
    },
    [bookedMedias]
  )

  const approveFileHandler = useCallback(
    ({ requirementFileId, uploadedFile }) => {
      dispatch(
        updateBookingMediaFile(uploadedFile?.id, {
          booked_media: uploadedFile?.booked_media,
          requirementFileId,
          approval_status: getApprovalActionStatus(isControllerBuyerApproval, uploadedFile)
        })
      )
    },
    [dispatch, isControllerBuyerApproval]
  )

  const handleChangeStatusOnDisapproval = useChangeFileStatusOnDisapproved()

  const dropdownOptions = useMemo(() => {
    return [
      {
        text: t('Go to media booking'),
        onClickHandler: goToMediaOrderSummaryHandler
      },
      {
        text: t('View files'),
        onClickHandler: goToMediaOrderSummaryHandler
      }
    ]
  }, [t, goToMediaOrderSummaryHandler])

  const bookedMediaColumns = useMemo(
    () => [
      {
        header: 'Booking',
        Cell: data => {
          const { id: bookedMediaId, media_order_sequential_id: mediaOrderSequentialId } = data

          return <div onClick={e => goToMediaOrderSummaryHandler(e, bookedMediaId)}>{mediaOrderSequentialId}</div>
        },
        style: { maxWidth: tableColumnsSize.id, cursor: 'pointer' }
      },
      {
        header: 'Product ID',
        fieldKey: INTERNAL_ID,
        style: {
          display: 'block', // for overflow ellipsis to work
          maxWidth: tableColumnsSize.internalProductId
        }
      },
      {
        header: 'Name',
        Cell: ({ name, date_start, date_end }) => <NameField name={name} startedDate={date_start} endDate={date_end} />,
        style: {
          minWidth: tableColumnsSize.name
        }
      },
      {
        header: 'Account',
        fieldKey: 'account_name',
        style: { maxWidth: tableColumnsSize.account },
        showOnMobile: false
      },
      {
        header: 'Qty',
        fieldKey: 'quantity',
        style: { maxWidth: tableColumnsSize.quantity },
        showOnMobile: false
      },
      {
        header: 'Uploaded files',
        Cell: ({ uploaded_files: uploadedFiles, id: bookedMediaId }) =>
          !!uploadedFiles?.length && (
            <UploadedFilesField
              uploadedFiles={uploadedFiles}
              bookedMediaId={bookedMediaId}
              onApproveHandler={approveFileHandler}
              onDisapproveHandler={handleChangeStatusOnDisapproval}
              goToMediaOrderSummaryHandler={e => goToMediaOrderSummaryHandler(e, bookedMediaId)}
            />
          ),
        style: { maxWidth: tableColumnsSize.uploadedFiles },
        showOnMobile: false
      },
      {
        header: 'Cost',
        Cell: ({ price, currency_symbol }) => formatCurrency(price, { min: 2, max: 2 }, { symbol: currency_symbol }),
        style: { maxWidth: tableColumnsSize.cost },
        showOnMobile: false
      },
      {
        Cell: ({ id }) =>
          isMobile ? (
            <ActionsButtonsList itemId={id} options={dropdownOptions} />
          ) : (
            <ActionsDropdown itemId={id} options={dropdownOptions} />
          ),
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [dropdownOptions, isMobile, goToMediaOrderSummaryHandler, approveFileHandler, handleChangeStatusOnDisapproval]
  )

  useEffect(() => {
    // needs to clear when upload_file status changed on approved
    // clearing needs only for approved status, since disapproved status will clear in disapproved reason modal
    if (wasUpdated && bookedMediaId && data?.approval_status === APPROVED) {
      dispatch(clearUpdateBookingMediaFile())
    }
  }, [wasUpdated, dispatch, bookedMediaId, data?.approval_status])

  useEffect(() => {
    if (bookedMediaFileWasUpdated) {
      dispatch(clearUpdateBookingMediaFile())
    }
  }, [dispatch, bookedMediaFileWasUpdated])

  return <Table hideFooterRow data={bookedMedias} cols={bookedMediaColumns} itemUpdatingId={itemUpdatingId} />
}

BookedMediaAndFilesTableData.propTypes = {
  bookedMedias: PropTypes.array.isRequired
}

export default BookedMediaAndFilesTableData
