import useAuthStyles from '../../styles/common/authPages'

export const useAuthButtonProps = () => {
  const authClasses = useAuthStyles()

  return {
    wrapperClassName: authClasses.authBtnWrapper,
    className: authClasses.authBtn,
    solid: false
  }
}
