import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import CustomAudiencesFields from '../../../ReusableFormFields/CustomAudiencesFields'

import { formatOptionsList } from '../../../../features/formatters'

import { getTikTokCustomAudiences, clearTikTokCustomAudiences } from '../../../../modules/actions/tikTokConstants'
import {
  tikTokCustomAudiencesIsLoadingSelector,
  tikTokCustomAudiencesSelector
} from '../../../../modules/selectors/tikTokConstants'
import { selectedAdAccountIdSelector } from '../../../../modules/selectors/app'

const TikTokAudiencesFields = ({ formik, adAccountId }) => {
  const dispatch = useDispatch()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const tikTokCustomAudiences = useSelector(tikTokCustomAudiencesSelector)
  const tikTokCustomAudiencesIsLoading = useSelector(tikTokCustomAudiencesIsLoadingSelector)

  const formattedAudiences = useMemo(
    () =>
      formatOptionsList({
        list: tikTokCustomAudiences.filter(audience => audience['is_valid']),
        valueName: 'audience_id',
        labelName: 'name'
      }),
    [tikTokCustomAudiences]
  )

  useEffect(() => {
    dispatch(
      getTikTokCustomAudiences({
        account: adAccountId || selectedAdAccountId,
        page_size: 100
      })
    )
  }, [dispatch, adAccountId, selectedAdAccountId])

  useEffect(
    () => () => {
      dispatch(clearTikTokCustomAudiences())
    },
    [dispatch]
  )

  return (
    <CustomAudiencesFields
      formik={formik}
      audiencesIsLoading={tikTokCustomAudiencesIsLoading}
      formattedAudiences={formattedAudiences}
    />
  )
}

TikTokAudiencesFields.propTypes = {
  formik: PropTypes.object.isRequired,
  adAccountId: PropTypes.string
}

export default TikTokAudiencesFields
