import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Field from '../../../../../../../components/Form/Field'
import EditForm from '../../../../../../../features/components/Forms/EditForm'

import { usePurifiedFormik } from '../../../../../../../hooks/formHooks/usePurifiedFormik'
import { useEditSectionFormSubmit } from '../../../../../../../hooks/formHooks/useEditSectionFormSubmit'

import { updateAd } from '../../../../../../../modules/actions/ads'

import { formatValuesToFE, transformValuesToBE } from './formatters'

import { selectedAdAccountIdSelector } from '../../../../../../../modules/selectors/app'

import { BUSINESS_NAME } from '../../../../fields'
import { CALL_TO_ACTION } from '../../../../../../ReusableFormFields/CallToActionFields/fields'
import { googleAdsCallToActionList } from '../../../../../../../constants/selectLists/googleAdsCallToActionList'
import { validationSchema } from './validation'
import { GOOGLE_PLATFORM } from '../../../../../../../constants/selectLists/platformList'

function NameSectionForm({ editItemData: ad, successSubmit, isInternalAd, ...formProps }) {
  const dispatch = useDispatch()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const { id: adId, ad_account_id: adAccountId } = ad
  const adAccount = adAccountId || selectedAdAccountId

  const initialValues = useMemo(() => formatValuesToFE(ad), [ad])

  const handleSubmit = useCallback(
    values => {
      const formattedUpdateItemData = {
        ...transformValuesToBE(values),
        // On AdApprovals page we have ads from different ad accounts,
        // so we use ad_account_id from ad item
        account: adAccount,
        ...(isInternalAd && { internal: true })
      }

      dispatch(updateAd(formattedUpdateItemData, adId, 'PATCH', GOOGLE_PLATFORM))
    },
    [dispatch, adAccount, isInternalAd, adId]
  )

  const { onSubmit, manualSuccessSubmit } = useEditSectionFormSubmit({ initialValues, handleSubmit })

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={successSubmit || manualSuccessSubmit}
      {...formProps}
    >
      <Field formik={formik} name={BUSINESS_NAME} placeholder="Business name" autoComplete="off" />
      <Field placeholder="Call To Action" formik={formik} name={CALL_TO_ACTION} options={googleAdsCallToActionList} />
    </EditForm>
  )
}

export default NameSectionForm
