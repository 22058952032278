import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { getBusinessNameData, getCTAData } from '../HeadlinesSection/helpers'
import { getKeyByValue } from '../../../../../../../helpers/common'

import { googleCallToActionEnums } from '../../../../../../../constants/ads'
import { googleAdsCallToActionList } from '../../../../../../../constants/selectLists/googleAdsCallToActionList'

const NameSectionPreview = ({ data }) => {
  const { t } = useTranslation()
  const businessNameData = getBusinessNameData(data.assets)

  const businessName = businessNameData?.['asset_data'].text_asset.text

  const ctaData = getCTAData(data.assets)

  const ctaEnumValue = ctaData?.['asset_data'].call_to_action_asset.call_to_action

  const cta = getKeyByValue(googleCallToActionEnums, ctaEnumValue)

  const ctaLabel = googleAdsCallToActionList.find(item => item.value === cta)?.label

  const { name } = data

  if (!name && !businessName && !cta) {
    return null
  }

  return (
    <>
      {name && <p>{name}</p>}

      {businessName && (
        <p>
          <span>
            <strong>{t('Business Name')}</strong>
          </span>
          <span>{businessName}</span>
        </p>
      )}

      {cta && (
        <p>
          <span>
            <strong>{t('Call To Action')}</strong>
          </span>
          <span>{ctaLabel}</span>
        </p>
      )}
    </>
  )
}

NameSectionPreview.propTypes = {
  data: PropTypes.object
}

export default NameSectionPreview
