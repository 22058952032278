import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { DateRange as ExternalDateRange } from 'react-date-range'
import { useDispatch, useSelector } from 'react-redux'

import SelectDropdown from '../../../../../../../../../../../../../components/SelectDropdown'

import useDateRange, { useCheckAndFetchInventoryByDateRange } from './hooks'
import useStartWeekDay from '../../../../../../../../../../../../../features/hooks/useStartWeekDay'
import { getIsMatchDateRange } from './helpers'

import {
  fetchedInventoryDateRangesSelector,
  inventoryLastItemSelector
} from '../../../../../../../../../../../../../modules/selectors/mediaOrdersProducts'
import { getInventoryLastItem } from '../../../../../../../../../../../../../modules/actions/mediaOrdersProducts'

import { initialDatePeriod } from '../../../../../../../../../../../../../constants/dates'

import { SelectPeriodsFieldsContext } from '../../../SelectPeriodsFieldsContextProvider'

import useStyles from './styles'

const inputRanges = []

// this component is a duplication of DateRange component with some customizations,
// to avoid make original component more complex, as it often reusable
function PeriodsDatePicker({
  className,
  onDateRangeChange,
  minDate,
  onStartDateSelect,
  disabledDates,
  isLoading,
  onClose,
  CustomDayComponent
}) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { productId, currencyCode, allowToFetchPreviousPeriods } = useContext(SelectPeriodsFieldsContext)

  const [datePeriod, setDatePeriod] = useState(initialDatePeriod)

  const fetchedInventoryDateRanges = useSelector(fetchedInventoryDateRangesSelector)
  const inventoryLastItem = useSelector(inventoryLastItemSelector)
  const [shownMonth, setShownMonth] = useState(new Date())

  const weekStartsOn = useStartWeekDay()
  const { isOpen, setIsOpen, rangeColors, rangeSelectedTextColor } = useDateRange()

  // ref for tracking start date selection status
  const isStartDateSelected = useRef(false)

  const checkAndFetchInventoryByDateRange = useCheckAndFetchInventoryByDateRange({
    requestParams: { media_product: productId, currency: currencyCode }
  })

  const onShownDateChangeHandler = useCallback(
    dateRange => {
      checkAndFetchInventoryByDateRange(dateRange, allowToFetchPreviousPeriods)
      setShownMonth(dateRange)
    },
    [checkAndFetchInventoryByDateRange, allowToFetchPreviousPeriods]
  )

  const handleDropdownVisibility = useCallback(
    show => {
      setIsOpen(show)
      if (isOpen && !show) {
        onClose && onClose()
        setDatePeriod(initialDatePeriod)

        // reset isStartDateSelected state when user closes without selection
        isStartDateSelected.current = false
      }
    },
    [isOpen, setIsOpen, onClose]
  )

  const handleDateRangeChange = useCallback(
    period => {
      setDatePeriod(period.selection)
      if (isStartDateSelected.current) {
        // we want to execute onDateRangeChange callback only when user selects full date range
        // for that reason we add extra state using ref to track double selection
        setDatePeriod(initialDatePeriod)
        onDateRangeChange(period.selection)
      } else {
        onStartDateSelect && onStartDateSelect(period?.selection?.startDate)
        isStartDateSelected.current = true
      }
    },
    [onDateRangeChange, onStartDateSelect]
  )

  const handleFocusChange = useCallback(
    focus => {
      if (focus && !focus[1]) {
        // close dropdown and reset isDateSelected when endDate is selected:
        setIsOpen(false)
        onClose && onClose()
        isStartDateSelected.current = false
      }
    },
    [setIsOpen, onClose]
  )

  const CellSkeleton = useCallback(() => {
    return <Skeleton width={55} height={36} />
  }, [])

  const showCellSkeleton = useMemo(() => {
    return !getIsMatchDateRange(fetchedInventoryDateRanges, shownMonth)
  }, [shownMonth, fetchedInventoryDateRanges])

  useEffect(() => {
    dispatch(
      getInventoryLastItem({
        media_product: productId
      })
    )
  }, [dispatch, productId])

  // ExternalDateRange supports to have multiple ranges in one time, as we use only one - we set only first array element make sure it memoized to avoid new array create each time
  const ranges = useMemo(() => [datePeriod], [datePeriod])

  return (
    <div className={classnames('field', className)}>
      <SelectDropdown
        className={classes.dateRange}
        value={''}
        placeholder="Add booking period"
        isManuallyOpened={isOpen}
        onVisibilityChange={handleDropdownVisibility}
        isSmall={false}
        isLoading={isLoading}
        isDisabled={isLoading}
      >
        <div className={classes.dateRangeDropdown}>
          <div className={classes.dateRanges}>
            <ExternalDateRange
              // start week day
              weekStartsOn={weekStartsOn}
              onChange={handleDateRangeChange}
              className={classes.range}
              color={rangeSelectedTextColor}
              showDateDisplay={false}
              ranges={ranges}
              inputRanges={inputRanges}
              rangeColors={rangeColors}
              onRangeFocusChange={handleFocusChange}
              minDate={minDate && new Date(minDate)}
              maxDate={inventoryLastItem?.date_start ? new Date(inventoryLastItem?.date_start) : undefined}
              // represent current date in calendar
              direction="horizontal"
              // disable drag selection if there are disabled dates, because drag selection still selects disabled dates
              dragSelectionEnabled={!disabledDates}
              disabledDates={disabledDates}
              dayContentRenderer={showCellSkeleton ? CellSkeleton : CustomDayComponent}
              onShownDateChange={onShownDateChangeHandler}
            />
          </div>
        </div>
      </SelectDropdown>
    </div>
  )
}

PeriodsDatePicker.propTypes = {
  className: PropTypes.string,
  onDateRangeChange: PropTypes.func.isRequired,
  minDate: PropTypes.object,
  disabledDates: PropTypes.array,
  isLoading: PropTypes.bool,
  CustomDayComponent: PropTypes.func,
  onStartDateSelect: PropTypes.func,
  onClose: PropTypes.func
}

export default PeriodsDatePicker
