import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import ProgressButton from '../../../../components/Form/ProgressButton'

import { clearSetMFAType, setMFAType } from '../../../../modules/actions/app'
import {
  setMFATypeErrorSelector,
  setMFATypeIsLoadingSelector,
  setMFATypeWasCreatedSelector
} from '../../../../modules/selectors/app'

import { LOGIN_SMS_MFA } from '../../../../constants/other'

import useDrawerFormsStyles from '../../../../styles/common/drawerForms'

const MfaTypeSet = ({ onSuccessSubmit, mfaType }) => {
  const dispatch = useDispatch()
  const drawerFormClasses = useDrawerFormsStyles()
  const { t } = useTranslation()

  const setMFAError = useSelector(setMFATypeErrorSelector)
  const setMFAIsLoading = useSelector(setMFATypeIsLoadingSelector)
  const setMFATypeWasCreated = useSelector(setMFATypeWasCreatedSelector)

  const clearSetMFATypeHandler = () => {
    dispatch(clearSetMFAType())
  }

  const submitMFAType = useCallback(() => {
    dispatch(
      setMFAType({
        mfa: mfaType
      })
    )
  }, [dispatch, mfaType])

  useEffect(() => {
    submitMFAType()
  }, [submitMFAType])

  const title =
    mfaType === LOGIN_SMS_MFA
      ? t('SMS verification is setting up. Please wait.')
      : t('Authenticator verification is setting up. Please wait.')

  return (
    <>
      <p>{title}</p>
      <div className={drawerFormClasses.formFooter}>
        <ProgressButton
          successSubmit={setMFATypeWasCreated}
          formError={setMFAError}
          isFormLoading={setMFAIsLoading}
          clearHandler={clearSetMFATypeHandler}
          onSuccessSubmit={onSuccessSubmit}
          onClick={submitMFAType}
        >
          {mfaType === LOGIN_SMS_MFA ? t('Save SMS verification') : t('Save Authenticator verification')}
        </ProgressButton>
      </div>
    </>
  )
}

export default MfaTypeSet
