import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import EditForm from '../../../../../../features/components/Forms/EditForm'
import SelectMultiLevel from '../../../../../../components/SelectMultiLevel'
import GenderContent from './ContentSections/GenderContent'
import AgeContent from './ContentSections/AgeContent'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import { useEditSectionFormSubmit } from '../../../../../../hooks/formHooks/useEditSectionFormSubmit'

import { filterObjectEmptyValues } from '../../../../../../helpers/common'
import { getMultiLevelSelectPayloadValues } from '../../../../../../components/SelectMultiLevel/formatters'
import { formatValuesToFE } from './formatters'

import { updateLineItem, clearUpdateLineItem } from '../../../../../../modules/actions/lineItems'
import {
  lineItemUpdateErrorSelector,
  lineItemUpdateIsLoadingSelector,
  lineItemWasUpdatedSelector
} from '../../../../../../modules/selectors/lineItems'
import {
  tikTokRegionsSelector,
  tikTokRegionsWasLoadedSelector
} from '../../../../../../modules/selectors/tikTokConstants'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { AGE } from '../../../../../Multiplatform/LineItemForms/fields'
import { GEOLOCATIONS } from '../../fields'
import { GENDER_OPTION } from '../../../../../ReusableFormFields/GenderFields/fields'
import { validationSchema } from './validation'

import useDrawerFormStyles from '../../../../../../styles/common/drawerForms'

const DemographicsSectionForm = ({ editItemData: lineItem, ...formProps }) => {
  const drawerFormClasses = useDrawerFormStyles()

  const dispatch = useDispatch()

  const lineItemWasUpdated = useSelector(lineItemWasUpdatedSelector)
  const tikTokRegions = useSelector(tikTokRegionsSelector)
  const tikTokRegionsWasLoaded = useSelector(tikTokRegionsWasLoadedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const allowLocationsEdit = tikTokRegionsWasLoaded

  const formattedRegionOptions = useMemo(
    () =>
      tikTokRegions.map(region => ({
        ...region,
        parent: region['parent_id']
      })),
    [tikTokRegions]
  )

  const flatToNestedRegionsSortFunction = useCallback((a, b) => a.name.localeCompare(b.name), [])

  const initialValues = useMemo(
    () => formatValuesToFE({ lineItem, allowLocationsEdit, formattedRegionOptions }),
    [lineItem, formattedRegionOptions, allowLocationsEdit]
  )

  const handleSubmit = useCallback(
    values => {
      // as we use PUT for update TikTok campaign, we should pass full data in payload
      dispatch(
        updateLineItem(
          {
            ...filterObjectEmptyValues(lineItem),
            account: selectedAdAccountId,
            age: Object.keys(values[AGE]).filter(range => values[AGE][range]),
            gender: values[GENDER_OPTION],
            ...(allowLocationsEdit && {
              location: getMultiLevelSelectPayloadValues(values[GEOLOCATIONS], tikTokRegions, 'parent_id')
            })
          },
          lineItem.id,
          {
            requestMethod: 'PUT'
          }
        )
      )
    },
    [dispatch, lineItem, tikTokRegions, selectedAdAccountId, allowLocationsEdit]
  )

  const { onSubmit, manualSuccessSubmit } = useEditSectionFormSubmit({ initialValues, handleSubmit })

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const { setFieldValue, values } = formik

  const handleClearUpdateLineItem = useCallback(() => {
    dispatch(clearUpdateLineItem())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={lineItemWasUpdated || manualSuccessSubmit}
      clearEditItem={handleClearUpdateLineItem}
      errorSelector={lineItemUpdateErrorSelector}
      isLoadingSelector={lineItemUpdateIsLoadingSelector}
      {...formProps}
    >
      <AgeContent formik={purifiedFormik} />
      <GenderContent formik={purifiedFormik} />
      {allowLocationsEdit && (
        <div className={drawerFormClasses.subSection}>
          <h4 className={drawerFormClasses.sectionSubtitle}>Age</h4>
          <SelectMultiLevel
            placeholder="Add a region"
            setFieldValue={setFieldValue}
            value={values[GEOLOCATIONS]}
            name={GEOLOCATIONS}
            options={formattedRegionOptions}
            flatToNestedArrSortFunction={flatToNestedRegionsSortFunction}
          />
        </div>
      )}
    </EditForm>
  )
}

export default DemographicsSectionForm
