import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import MediaCategoryActions from './MediaCategoryActions'
import Table from '../../../../../../components/Table'

import { ReactComponent as DraggableDots } from '../../../../../../assets/icons/draggable-dots.svg'

import { updateMediaPackageCategoryIdSelector } from '../../../../../../modules/selectors/mediaPackages'

import { tableColumnsSize } from '../index'
import { phonesDownSize } from '../../../../../../styles/const/breakpoints'

import useStyles from './styles'

const MediaPackageCategoriesTableData = ({ categories, onOrderChange }) => {
  const classes = useStyles()

  const updateMediaCategoryId = useSelector(updateMediaPackageCategoryIdSelector)

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const categoriesColumns = useMemo(
    () => [
      {
        Cell: () => <DraggableDots />,
        style: { maxWidth: tableColumnsSize.dragBtn }
      },
      {
        fieldKey: 'name'
      },
      {
        Cell: data => <MediaCategoryActions category={data} className={classes.actionsStyles} />,
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [isMobile, classes]
  )

  return (
    <Table
      hideHeadlineRow
      data={categories}
      cols={categoriesColumns}
      onRowDrag={onOrderChange}
      itemUpdatingId={updateMediaCategoryId}
      tableId="MediaPackageCategoriesTableData"
      hideFooterRow
      mobileColumnsWrapperClassName={classes.mobileHeaderStyle}
    />
  )
}

MediaPackageCategoriesTableData.propTypes = {
  categories: PropTypes.array.isRequired,
  onOrderChange: PropTypes.func.isRequired
}

export default MediaPackageCategoriesTableData
