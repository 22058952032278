export const columnSizes = {
  status: 65,
  platform: 60,
  name: 300,
  objective: 150,
  impressions: 85,
  cpm: 75,
  reach: 85,
  clicks: 85,
  cpc: 75,
  purchase: 85,
  engagements: 105,
  activeViewImpressions: 110,
  videoViews: 85,
  spend: 85,
  dropdown: 30
}

export const statusColumnSizes = { minWidth: columnSizes.status, maxWidth: columnSizes.status }
export const statusColumnSizesMobile = { maxWidth: 40, marginInlineEnd: 16 }
export const platformColumnSizes = { minWidth: columnSizes.platform, maxWidth: columnSizes.platform }
export const platformColumnSizesMobile = { maxWidth: 45 }
export const nameColumnSizes = { minWidth: columnSizes.name, maxWidth: columnSizes.name }
export const nameColumnSizesMobile = {}
export const objectiveColumnSizes = { minWidth: columnSizes.objective, maxWidth: 180 }
export const impressionsColumnSizes = { minWidth: columnSizes.impressions }
export const cpmColumnSizes = { minWidth: columnSizes.cpm }
export const reachColumnSizes = { minWidth: columnSizes.reach }
export const clicksColumnSizes = { minWidth: columnSizes.clicks }
export const cpcColumnSizes = { minWidth: columnSizes.cpc, maxWidth: columnSizes.cpc }
export const purchaseColumnSizes = { minWidth: columnSizes.purchase }
export const engagementsColumnSizes = { minWidth: columnSizes.engagements }
export const activeViewImpressionsColumnSizes = { minWidth: columnSizes.activeViewImpressions }
export const videoViewsColumnSizes = { minWidth: columnSizes.videoViews }
export const spendColumnSizes = { minWidth: columnSizes.spend }
export const dropdownColumnSizes = { minWidth: columnSizes.dropdown, maxWidth: columnSizes.dropdown }

// columns schema is template for table skeleton, includes mixed digital table columns
export const columnsSchema = [
  { style: statusColumnSizes },
  { style: platformColumnSizes },
  { style: nameColumnSizes },
  { style: objectiveColumnSizes },
  { style: impressionsColumnSizes },
  { style: cpmColumnSizes },
  { style: reachColumnSizes },
  { style: clicksColumnSizes },
  { style: cpcColumnSizes },
  { style: purchaseColumnSizes },
  { style: spendColumnSizes },
  { style: dropdownColumnSizes }
]

export const mobileColumnsSchema = [{ style: statusColumnSizesMobile }, { style: nameColumnSizesMobile }]
