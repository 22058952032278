import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import LifetimeBudgetField from '../../../../../Multiplatform/UploadAdCreative/LifetimeBudgetField'
import Field from '../../../../../../components/Form/Field'
import RadioBlock from '../../../../../../components/Form/RadioBlock'

import { ReactComponent as TikTokAudienceIcon } from '../../../../../../assets/icons/tiktok/tiktokAudience.svg'

import useAccessibleCampaignsRoutes from '../../../../../Multiplatform/RoutesMissingWarning/useAccessibleCampaignsRoutes'

import { selectedControllerDataSelector } from '../../../../../../modules/selectors/app'

import {
  CAMPAIGN,
  CAMPAIGN_OPTION,
  CAMPAIGN_OPTION_AUDIENCE_TARGETING,
  CAMPAIGN_OPTION_EXISTING_CAMPAIGN
} from '../../../../../Multiplatform/UploadAdCreative/fields'
import { TIKTOK_PLATFORM } from '../../../../../../constants/selectLists/platformList'

import { AUDIENCE_RETARGETING_ROUTE } from '../../../../../../pages/Settings/AdvertiserAccounts/ClientAccountForms/ClientAccountWithMarginsCreate/ClientAccountWithMarginsCreateForm/fields'

import useStepFormStyles from '../../../../../../styles/common/stepForms'

const CampaignFormContent = ({ formik, formattedCampaigns, remainingBudget }) => {
  const stepFormClasses = useStepFormStyles()
  const { t } = useTranslation()

  const controller = useSelector(selectedControllerDataSelector)

  const allowedRoute = useAccessibleCampaignsRoutes(TIKTOK_PLATFORM)
  const hasAccessibleRoutes = Object.values(allowedRoute).some(route => route)

  const { setFieldValue, values } = formik

  return (
    <div className={stepFormClasses.stepBody}>
      {!!formattedCampaigns?.length && (
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_existing_campaign"
          name={CAMPAIGN_OPTION}
          label={t('Upload an ad to an existing campaign')}
          value={CAMPAIGN_OPTION_EXISTING_CAMPAIGN}
          selectedValue={values[CAMPAIGN_OPTION]}
          description={<p>{t('Upload a new ad to a new or existing ad set in one of your campaigns')}</p>}
          hasOrSpacing={hasAccessibleRoutes}
        >
          <Field formik={formik} name={CAMPAIGN} placeholder={t('Campaign')} options={formattedCampaigns} />
        </RadioBlock>
      )}
      {allowedRoute[AUDIENCE_RETARGETING_ROUTE] && (
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_option_advanced_shopping"
          name={CAMPAIGN_OPTION}
          label={t('Audience Targeting')}
          value={CAMPAIGN_OPTION_AUDIENCE_TARGETING}
          selectedValue={values[CAMPAIGN_OPTION]}
          Icon={TikTokAudienceIcon}
          description={
            <>
              <p>{t('targetAudiences', { controllerName: controller?.name })}</p>
              <p>{t('Select from a wide range of customer and product category audiences')}</p>
            </>
          }
        >
          <LifetimeBudgetField formik={formik} remainingBudget={remainingBudget} platform={TIKTOK_PLATFORM} />
        </RadioBlock>
      )}
    </div>
  )
}

CampaignFormContent.propTypes = {
  formik: PropTypes.object.isRequired,
  formattedCampaigns: PropTypes.array.isRequired
}

export default CampaignFormContent
