import update from 'immutability-helper'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_AD_ACCOUNTS,
  GET_AD_ACCOUNTS_FAILURE,
  GET_AD_ACCOUNTS_SUCCESS,
  GET_AD_ACCOUNT,
  GET_AD_ACCOUNT_SUCCESS,
  GET_AD_ACCOUNT_FAILURE,
  CLEAR_ALL_PLATFORMS_SINGLE_AD_ACCOUNT,
  CLEAR_SPECIFIC_PLATFORM_SINGLE_AD_ACCOUNT
} from '../actions/adAccounts'
import {
  DV_360_PLATFORM,
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  TIKTOK_PLATFORM
} from '../../constants/selectLists/platformList'
import { INITIAL_GET_REDUCER } from '../../constants/reducer'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  isLoading: false,
  adAccounts: [],
  adAccount: {
    [FACEBOOK_PLATFORM]: INITIAL_GET_REDUCER,
    [GOOGLE_PLATFORM]: INITIAL_GET_REDUCER,
    [DV_360_PLATFORM]: INITIAL_GET_REDUCER,
    [TIKTOK_PLATFORM]: INITIAL_GET_REDUCER
  }
}

export default function adAccounts(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    case GET_AD_ACCOUNTS:
      return update(state, {
        isLoading: { $set: true },
        adAccounts: { $set: initialState.adAccounts }
      })
    case GET_AD_ACCOUNTS_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        adAccounts: { $set: action.adAccountsData }
      })
    case GET_AD_ACCOUNTS_FAILURE:
      return update(state, {
        isLoading: { $set: false }
      })
    case GET_AD_ACCOUNT:
      return update(state, {
        adAccount: {
          [action.platform]: {
            $merge: {
              isLoading: true
            }
          }
        }
      })
    case GET_AD_ACCOUNT_SUCCESS:
      return update(state, {
        adAccount: {
          [action.platform]: {
            $merge: {
              isLoading: false,
              data: action.adAccountData,
              wasLoaded: true
            }
          }
        }
      })
    case GET_AD_ACCOUNT_FAILURE:
      return update(state, {
        adAccount: {
          [action.platform]: {
            $merge: {
              isLoading: false,
              error: action.error
            }
          }
        }
      })
    case CLEAR_ALL_PLATFORMS_SINGLE_AD_ACCOUNT:
      return update(state, {
        adAccount: { $set: initialState.adAccount }
      })
    case CLEAR_SPECIFIC_PLATFORM_SINGLE_AD_ACCOUNT:
      return update(state, {
        adAccount: {
          [action.platform]: { $set: initialState.adAccount[action.platform] }
        }
      })
    default:
      return state
  }
}
