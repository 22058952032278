import { call, put, all, take, race, takeLatest, takeEvery } from 'redux-saga/effects'

import {
  getCombinedCampaignsService,
  getCombinedLineItemsService,
  getCombinedGraphsService,
  getCombinedSegmentedDataService,
  getCombinedAdsService
} from '../services/combinedData'
import {
  GET_COMBINED_CAMPAIGNS,
  getCombinedCampaignsSuccess,
  getCombinedCampaignsFailure,
  CLEAR_COMBINED_CAMPAIGNS,
  GET_COMBINED_LINE_ITEMS,
  getCombinedLineItemsSuccess,
  getCombinedLineItemsFailure,
  GET_COMBINED_GRAPHS,
  getCombinedGraphsSuccess,
  getCombinedGraphsFailure,
  GET_COMBINED_SEGMENTED_DATA,
  getCombinedSegmentedDataSuccess,
  getCombinedSegmentedDataFailure,
  GET_COMBINED_ADS,
  getCombinedAdsSuccess,
  getCombinedAdsFailure
} from '../actions/combinedData'

function* reportsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeLatest(GET_COMBINED_CAMPAIGNS, getCombinedCampaignsWorker),
    takeEvery(GET_COMBINED_LINE_ITEMS, getCombinedLineItemsWorker),
    takeEvery(GET_COMBINED_GRAPHS, getCombinedGraphsWorker),
    takeEvery(GET_COMBINED_SEGMENTED_DATA, getCombinedSegmentedDataWorker),
    takeEvery(GET_COMBINED_ADS, getCombinedAdsWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getCombinedCampaignsWorker({ data }) {
  try {
    const { response } = yield race({
      response: call(getCombinedCampaignsService, data),
      // need to cancel previous task, if user change the page, but task still in progress
      cancel: take(CLEAR_COMBINED_CAMPAIGNS)
    })

    if (response) {
      yield put(getCombinedCampaignsSuccess(response))
    }
  } catch (e) {
    yield put(getCombinedCampaignsFailure(e))
  }
}

function* getCombinedLineItemsWorker({ data }) {
  try {
    const response = yield call(getCombinedLineItemsService, data)
    yield put(getCombinedLineItemsSuccess(response))
  } catch (e) {
    yield put(getCombinedLineItemsFailure(e))
  }
}

function* getCombinedGraphsWorker({ data }) {
  try {
    const response = yield call(getCombinedGraphsService, data)
    yield put(getCombinedGraphsSuccess(response))
  } catch (e) {
    yield put(getCombinedGraphsFailure(e))
  }
}

function* getCombinedSegmentedDataWorker({ data }) {
  try {
    const response = yield call(getCombinedSegmentedDataService, data)
    yield put(getCombinedSegmentedDataSuccess(response))
  } catch (e) {
    yield put(getCombinedSegmentedDataFailure(e))
  }
}

function* getCombinedAdsWorker({ data }) {
  try {
    const response = yield call(getCombinedAdsService, data)
    yield put(getCombinedAdsSuccess(response))
  } catch (e) {
    yield put(getCombinedAdsFailure(e))
  }
}

export default reportsWatcher
