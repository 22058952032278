import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

import LineItemGoogleCreateForm from './LineItemGoogleCreateForm'
import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'

import useRedirectFormDrawer from '../../../../features/components/FormDrawerWrapper/useRedirectFormDrawer'

import { campaignSelector } from '../../../../modules/selectors/campaigns'

import { CAMPAIGN_GOOGLE_DISPLAY_TYPE } from '../../../../constants/campaigns'
import { LINE_ITEM_GOOGLE_ADS_CREATE } from '../../../../constants/forms'
import { ROUTES, ROUTE_PARAMS } from '../../../../constants/routes'
import { MODAL_TYPE } from '../../../../constants/other'

function LineItemGoogleCreate() {
  const campaign = useSelector(campaignSelector)

  const { advertising_channel_type: advertisingChanelType, id: campaignId } = campaign
  const isDisplayTypeCampaign = advertisingChanelType === CAMPAIGN_GOOGLE_DISPLAY_TYPE

  const handleSuccessRedirect = useRedirectFormDrawer(
    `${ROUTES.lineItemsSummary}?message=${MODAL_TYPE.newLineItemCreated}`
  )

  const handleSuccessCreate = useCallback(
    createdLineItem => {
      // redirect user to the lineItemsSummary with success modal with appropriate MODAL_TYPE
      handleSuccessRedirect({
        [ROUTE_PARAMS.campaignId]: campaignId,
        [ROUTE_PARAMS.lineItemId]: createdLineItem.id
      })
    },
    [campaignId, handleSuccessRedirect]
  )

  if (!isDisplayTypeCampaign) {
    return null
  }

  // on campaigns summary page show Create Ad Group button and form for CAMPAIGN_GOOGLE_DISPLAY_TYPE
  return (
    <FormDrawerWrapper formName={LINE_ITEM_GOOGLE_ADS_CREATE} closeOnSubmit={false} openButtonText="Create Ad Group">
      <LineItemGoogleCreateForm campaignId={campaignId} onSuccessSubmit={handleSuccessCreate} />
    </FormDrawerWrapper>
  )
}

export default LineItemGoogleCreate
