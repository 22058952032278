import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Field from '../../../../../../../../../components/Form/Field'
import FieldOptimizedPlaceholder from './FieldOptimizedPlaceholder'

import {
  BOOKED_QUANTITY,
  INVENTORY_LIST_DATA,
  INVENTORY_QUANTITY
} from '../../../../../../../MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'

import useStyles from './styles'

const FieldOptimized = ({ formik, index }) => {
  const { values } = formik

  const item = values[INVENTORY_LIST_DATA][index]
  const { [INVENTORY_QUANTITY]: inventoryQuantity, [BOOKED_QUANTITY]: bookedQuantity } = item

  const withBookedField = useMemo(() => !!bookedQuantity || !!inventoryQuantity, [bookedQuantity, inventoryQuantity])

  const classes = useStyles()

  const [isFocused, setIsFocused] = useState(false)

  const onFocusHandler = useCallback(() => {
    setIsFocused(true)
  }, [])

  const onBlurHandler = useCallback(() => {
    setIsFocused(false)
  }, [])

  return (
    <div
      onClick={onFocusHandler}
      className={classnames(classes.quantityField, {
        [classes.quantityFieldWithBooked]: !!bookedQuantity || !!inventoryQuantity
      })}
    >
      {isFocused ? (
        <Field
          onBlur={onBlurHandler}
          formik={formik}
          name={`${INVENTORY_LIST_DATA}[${index}].${INVENTORY_QUANTITY}`}
          type="number"
          placeholder="Qty"
          autoComplete="off"
          focusFromOutside={true}
          // highlight the value so that if the user clicks/double clicks and types they replace what was in there
          // instead of adding it on to the existing text
          highlightValueFromOutside={true}
        />
      ) : (
        <FieldOptimizedPlaceholder formik={formik} index={index} withBookedField={withBookedField} />
      )}
    </div>
  )
}

FieldOptimized.propTypes = {
  formik: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}

export default FieldOptimized
