import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import FieldsSection from '../../../../../../../../features/components/Form/FieldsSection'
import Step from '../../../../../../../../features/components/Forms/StepForm/Step'
import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../../../components/Form/Field'

import { usePurifiedFormik } from '../../../../../../../../hooks/formHooks/usePurifiedFormik'

import { selectedSelfAccountDataSelector } from '../../../../../../../../modules/selectors/app'

import { BID_AMOUNT } from '../../fields'

import useStyles from './styles'

const stepFields = [BID_AMOUNT]

function CampaignDatesStep({ formik, handleStepChange }) {
  const { t } = useTranslation()

  // we  use currencySymbol from facebook ad account until we update this form to connect to a real external account
  const { fb_ad_account: adAccountData } = useSelector(selectedSelfAccountDataSelector)
  const { currency_symbol: currencySymbol } = adAccountData

  const classes = useStyles()

  const purifiedFormik = usePurifiedFormik(formik)

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  return (
    <Step
      stepTitle={t('Set your bid per click')}
      stepDescription={t('You only pay when a shopper clicks on your sponsored result')}
      handleContinue={handleContinue}
    >
      <FieldsSection title={t('Maximum bid per click')}>
        <FieldRow
          title={t('Bid amount')}
          description={t(
            'This bid amount will be used in an auction for the search results ad space. This should be the maximum amount you are willing to pay, and you will often pay less per click, but never more.'
          )}
        >
          <Field
            type="number"
            symbol={currencySymbol}
            formik={purifiedFormik}
            placeholder={t('Enter bid')}
            name={BID_AMOUNT}
            autoComplete="off"
          />
          <p className={classes.descriptionForField}>
            {t('suggestedBidRange', { currencySymbol: currencySymbol, minBid: '1.78', maxBid: '2.60' })}
          </p>
        </FieldRow>
      </FieldsSection>
    </Step>
  )
}

export default CampaignDatesStep
