import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import AdVideoElement from './AdVideoElement'
import Skeleton from 'react-loading-skeleton'
import Icon from '../../../../../components/Icon'

import footer from '../../../../../assets/images/tt-preview-footer.jpg'
import { ReactComponent as CommentIcon } from '../../../../../assets/icons/tiktok/comment.svg'
import { ReactComponent as LikeIcon } from '../../../../../assets/icons/tiktok/like.svg'
import { ReactComponent as ShareIcon } from '../../../../../assets/icons/tiktok/share.svg'

import useStyles from './styles'

function SkeletonTikTokAdPreview({ className }) {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <div className={classnames(className, classes.previewContainer)} style={{ backgroundColor: '#c3c3c3' }}>
      <AdVideoElement videoUrl="" />
      <div className={classes.content}>
        <ul className={classes.menu}>
          <li className={classes.inactive}>{t('Following')}</li>
          <li className={classes.dot}></li>
          <li className={classes.active}>{t('For You')}</li>
        </ul>
        <div className={classes.info}>
          <div className={classes.left}>
            <p className={classes.displayName}>
              <Skeleton width="60%" />
            </p>
            <p className={classes.adText}>
              <Skeleton width="90%" />
            </p>
            <div className={classes.cta}>
              <div className={classes.link}>
                <Skeleton width="50px" />
              </div>
            </div>
          </div>
          <div className={classes.right}>
            <Skeleton className={classes.logo} circle={true} />
            <ul className={classes.stats}>
              <li>
                <Icon className={classes.icon}>
                  <LikeIcon />
                </Icon>{' '}
                <span>71.1k</span>
              </li>
              <li>
                <Icon className={classes.icon}>
                  <CommentIcon />
                </Icon>{' '}
                <span>1281</span>
              </li>
              <li>
                <Icon className={classes.icon}>
                  <ShareIcon />
                </Icon>{' '}
                <span>232</span>
              </li>
            </ul>
          </div>
        </div>
        <img className={classes.footer} src={footer} alt="TikTokMenu" />
      </div>
    </div>
  )
}

SkeletonTikTokAdPreview.propTypes = {
  className: PropTypes.string
}

export default SkeletonTikTokAdPreview
