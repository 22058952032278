import React from 'react'
import { useSelector } from 'react-redux'

import { ReactComponent as DoubleArrow } from '../../../assets/icons/double-arrow.svg'
import { ReactComponent as FbIcon } from '../../../assets/logos/fb-circle-icon.svg'
import { ReactComponent as GoogleIcon } from '../../../assets/logos/google-icon.svg'
import { ReactComponent as DV360Icon } from '../../../assets/logos/dv360/dv360-icon.svg'
import { ReactComponent as TikTokIcon } from '../../../assets/logos/tt-icon.svg'

import AppLogo from '../../../features/components/AppLogo'

import { selectedPlatformSelector } from '../../../modules/selectors/app'

import {
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  DV_360_PLATFORM,
  TIKTOK_PLATFORM
} from '../../../constants/selectLists/platformList'

import useStyles from './style'

function SocialAuthorizeLogos() {
  const classes = useStyles()
  const selectedPlatform = useSelector(selectedPlatformSelector)

  return (
    <div className={classes.logoContainer}>
      <div className={classes.logo}>
        <AppLogo />
      </div>
      <div className={classes.logoMiddle}>
        <DoubleArrow />
      </div>
      <div className={classes.logo}>
        {selectedPlatform === FACEBOOK_PLATFORM && <FbIcon />}
        {selectedPlatform === GOOGLE_PLATFORM && <GoogleIcon />}
        {selectedPlatform === DV_360_PLATFORM && <DV360Icon />}
        {selectedPlatform === TIKTOK_PLATFORM && <TikTokIcon />}
      </div>
    </div>
  )
}

export default SocialAuthorizeLogos
