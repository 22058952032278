import * as Yup from 'yup'
import {
  FILE_TYPE,
  IMAGE,
  IMAGE_FORMATS,
  IMAGE_MAX_SIZE,
  IMAGE_MIN_HEIGHT,
  IMAGE_MIN_WIDTH,
  MEDIA_DURATION,
  MEDIA_FORMAT,
  MEDIA_HEIGHT,
  MEDIA_MAX_RATIO_16_9,
  MEDIA_MAX_RATIO_1_91,
  MEDIA_MIN_RATIO_1,
  MEDIA_MIN_RATIO_9_16,
  MEDIA_RATIO,
  MEDIA_SIZE,
  MEDIA_TYPE,
  MEDIA_WIDTH,
  STORY_MEDIA_MIN_WIDTH,
  STORY_VIDEO_MAX_DURATION,
  STORY_VIDEO_MAX_SIZE,
  VIDEO,
  VIDEO_FORMATS,
  VIDEO_MAX_DURATION,
  VIDEO_MAX_SIZE,
  VIDEO_MIN_DURATION,
  VIDEO_MIN_HEIGHT,
  VIDEO_MIN_WIDTH
} from '../../../fields'

const singleMediaRatioValidation = Yup.number().when(MEDIA_TYPE, {
  // single
  // video: 16:9 to 9:16
  // image: 1.91:1 to 1:1
  is: IMAGE,
  then: () =>
    Yup.number()
      .min(MEDIA_MIN_RATIO_1, 'Image ratio should be from 1.91:1 to 1:1')
      .max(MEDIA_MAX_RATIO_1_91, 'Image ratio should be from 1.91:1 to 1:1'),
  otherwise: () =>
    Yup.number().when(MEDIA_TYPE, {
      is: VIDEO,
      then: () =>
        Yup.number()
          .min(MEDIA_MIN_RATIO_9_16, 'Video ratio should be from 16:9 to 9:16')
          .max(MEDIA_MAX_RATIO_16_9, 'Video ratio should be from 16:9 to 9:16')
    })
})

const carouselMediaRatioValidation = Yup.number().when(MEDIA_TYPE, {
  // carousel
  // video: 1.91:1 to 1:1
  // image: 1.91:1 to 1:1
  is: IMAGE,
  then: () =>
    Yup.number()
      .min(MEDIA_MIN_RATIO_1, 'Image ratio should be from 1.91:1 to 1:1')
      .max(MEDIA_MAX_RATIO_1_91, 'Image ratio should be from 1.91:1 to 1:1'),
  otherwise: () =>
    Yup.number().when(MEDIA_TYPE, {
      is: VIDEO,
      then: () =>
        Yup.number()
          .min(MEDIA_MIN_RATIO_1, 'Video ratio should be from 1.91:1 to 1:1')
          .max(MEDIA_MAX_RATIO_1_91, 'Video ratio should be from 1.91:1 to 1:1')
    })
})

const storyMediaRatioValidation = Yup.number()
  // story
  // video and image: 16:9 to 9:16
  .min(MEDIA_MIN_RATIO_9_16, 'Image ratio should be from 16:9 to 9:16')
  .max(MEDIA_MAX_RATIO_16_9, 'Image ratio should be from 16:9 to 9:16')

const mediaFormatValidation = Yup.string().when([MEDIA_TYPE, FILE_TYPE], {
  // check the format only for loaded files from device
  is: (MEDIA_TYPE, FILE_TYPE) => MEDIA_TYPE && FILE_TYPE && MEDIA_TYPE === IMAGE,
  then: () =>
    Yup.string().test(MEDIA_FORMAT, 'Allowed image types are JPG, JPEG, PNG', (value, { parent }) => {
      return IMAGE_FORMATS.includes(parent[MEDIA_FORMAT])
    }),
  otherwise: () =>
    Yup.string().when([MEDIA_TYPE, FILE_TYPE], {
      is: (MEDIA_TYPE, FILE_TYPE) => MEDIA_TYPE && FILE_TYPE && MEDIA_TYPE === VIDEO,
      then: () =>
        Yup.string().test(MEDIA_FORMAT, 'Allowed video types are MP4, MOV or GIF', (value, { parent }) => {
          return VIDEO_FORMATS.includes(parent[MEDIA_FORMAT])
        })
    })
})

// SINGLE or CAROUSEL

const singleOrCarouselSizeValidation = Yup.number().when(MEDIA_TYPE, {
  is: IMAGE,
  then: () => Yup.number().max(IMAGE_MAX_SIZE, 'The maximum supported image file size is 30 MB'),
  otherwise: () =>
    Yup.number().when(MEDIA_TYPE, {
      is: VIDEO,
      then: () => Yup.number().max(VIDEO_MAX_SIZE, 'The maximum supported video file size is 4 GB')
    })
})

const singleOrCarouselMediaWidthValidation = Yup.number().when(MEDIA_TYPE, {
  is: IMAGE,
  then: () => Yup.number().min(IMAGE_MIN_WIDTH, 'The minimum supported image width is 600px'),
  otherwise: () =>
    Yup.number().when(MEDIA_TYPE, {
      is: VIDEO,
      then: () => Yup.number().min(VIDEO_MIN_WIDTH, 'The minimum supported video width is 120px')
    })
})

const singleOrCarouselMediaHeightValidation = Yup.number().when(MEDIA_TYPE, {
  is: IMAGE,
  then: () => Yup.number().min(IMAGE_MIN_HEIGHT, 'The minimum supported image height is 600px'),
  otherwise: () =>
    Yup.number().when(MEDIA_TYPE, {
      is: VIDEO,
      then: () => Yup.number().min(VIDEO_MIN_HEIGHT, 'The minimum supported video height is 120px')
    })
})

const singleOrCarouselMediaDurationValidation = Yup.number().when(MEDIA_TYPE, {
  is: VIDEO,
  then: () =>
    Yup.number()
      .min(VIDEO_MIN_DURATION, 'Video duration must be at least 1 second')
      .max(VIDEO_MAX_DURATION, 'Video duration should be less than 241 minutes')
})

// STORY
const storyMediaSizeValidation = Yup.number().when(MEDIA_TYPE, {
  is: IMAGE,
  then: () => Yup.number().max(IMAGE_MAX_SIZE, 'The maximum supported image file size is 30 MB'),
  otherwise: () =>
    Yup.number().when(MEDIA_TYPE, {
      is: VIDEO,
      then: () => Yup.number().max(STORY_VIDEO_MAX_SIZE, 'The maximum supported video file size is 250 MB')
    })
})

const storyMediaWidthValidation = Yup.number().when(MEDIA_TYPE, {
  is: IMAGE,
  then: () => Yup.number().min(STORY_MEDIA_MIN_WIDTH, 'The minimum supported image width is 500px'),
  otherwise: () =>
    Yup.number().when(MEDIA_TYPE, {
      is: VIDEO,
      then: () => Yup.number().min(STORY_MEDIA_MIN_WIDTH, 'The minimum supported video width is 500px')
    })
})

const storyMediaHeightValidation = Yup.number().when(MEDIA_TYPE, {
  is: IMAGE,
  then: () => Yup.number().min(IMAGE_MIN_HEIGHT, 'The minimum supported image height is 600px'),
  otherwise: () =>
    Yup.number().when(MEDIA_TYPE, {
      is: VIDEO,
      then: () => Yup.number().min(VIDEO_MIN_HEIGHT, 'The minimum supported video height is 120px')
    })
})

const storyMediaDurationValidation = Yup.number().when(MEDIA_TYPE, {
  is: VIDEO,
  then: () =>
    Yup.number()
      .min(VIDEO_MIN_DURATION, 'Video duration must be at least 1 second')
      .max(STORY_VIDEO_MAX_DURATION, 'Video duration should be less than 60 seconds')
})

export const singleMediaValidation = Yup.object({
  [MEDIA_FORMAT]: mediaFormatValidation,
  [MEDIA_SIZE]: singleOrCarouselSizeValidation,
  [MEDIA_WIDTH]: singleOrCarouselMediaWidthValidation,
  [MEDIA_HEIGHT]: singleOrCarouselMediaHeightValidation,
  [MEDIA_RATIO]: singleMediaRatioValidation,
  [MEDIA_DURATION]: singleOrCarouselMediaDurationValidation
})
export const carouselMediaValidation = Yup.object({
  [MEDIA_FORMAT]: mediaFormatValidation,
  [MEDIA_SIZE]: singleOrCarouselSizeValidation,
  [MEDIA_WIDTH]: singleOrCarouselMediaWidthValidation,
  [MEDIA_HEIGHT]: singleOrCarouselMediaHeightValidation,
  [MEDIA_RATIO]: carouselMediaRatioValidation,
  [MEDIA_DURATION]: singleOrCarouselMediaDurationValidation
})
export const storyMediaValidation = Yup.object({
  [MEDIA_FORMAT]: mediaFormatValidation,
  [MEDIA_SIZE]: storyMediaSizeValidation,
  [MEDIA_WIDTH]: storyMediaWidthValidation,
  [MEDIA_HEIGHT]: storyMediaHeightValidation,
  [MEDIA_RATIO]: storyMediaRatioValidation,
  [MEDIA_DURATION]: storyMediaDurationValidation
})
