import React, { useCallback } from 'react'

import Step from '../../../../../../../../features/components/Forms/StepForm/Step'
import FieldsSection from '../../../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import StartEndDateRangeFields from '../../../../../../../ReusableFormFields/StartEndDateRangeFields'
import TikTokPixelFields from '../../../../../../LineItemForms/LineItemTikTokCreate/LineItemTikTokCreateForm/Steps/PixelStep/TikTokPixelFields'

import { END_DATE, START_DATE } from '../../../../../../../ReusableFormFields/StartEndDateRangeFields/fields'

const stepFields = [START_DATE, END_DATE]

const DatesStep = ({ formik, handleStepChange, showPixelFields, adAccountId }) => {
  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  return (
    <Step
      stepTitle="Set the campaign dates"
      stepDescription="Please enter the start and end dates for the campaign"
      handleContinue={handleContinue}
    >
      <FieldsSection title="Campaign period">
        <FieldRow title="Campaigns dates" description="Start and end date">
          <StartEndDateRangeFields
            formik={formik}
            startDateName={START_DATE}
            endDateName={END_DATE}
            minDate={new Date()}
          />
        </FieldRow>
      </FieldsSection>
      {showPixelFields && (
        <>
          <br />
          <TikTokPixelFields formik={formik} adAccountId={adAccountId} />
        </>
      )}
    </Step>
  )
}

export default DatesStep
