import React, { memo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { TABLE_COL_CLASSNAME } from '../../../../../constants/pdf'

import useStyles from './styles'
import useColumnStyles from '../Col/styles'

const MobileHiddenCols = memo(({ cols, rowData, rowIndex }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const columnClasses = useColumnStyles()

  // render only columns which are not possible to show on mobile:
  return cols
    .filter(({ showOnMobile }) => showOnMobile === false)
    .map(
      colData =>
        // Column
        // hide column if nothing to render
        (colData.header || colData.fieldKey || colData.Cell(rowData, rowIndex)) && (
          <div className={classes.mobileHiddenColumnsWrapper} key={uuidv4()}>
            <div className={classnames(colData.header && classes.mobileRowHeader, colData.headerClassName)}>
              {typeof colData.header === 'function' ? colData.header() : t(colData.header)}
            </div>
            <div
              className={classnames(columnClasses[TABLE_COL_CLASSNAME], classes.colInsideBody, colData.className)}
              style={colData.style}
            >
              {/* return either simple fieldKey value or column component */}
              {colData.fieldKey ? rowData[colData.fieldKey] || '-' : colData.Cell(rowData, rowIndex)}
            </div>
          </div>
        )
    )
})

const colsPropTypeShape = {
  // header
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]),
  headClassName: PropTypes.string,
  // main cell
  Cell: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  fieldKey: PropTypes.string,
  className: PropTypes.string,
  // footer
  footerClassName: PropTypes.string
}

MobileHiddenCols.propTypes = {
  rowData: PropTypes.object.isRequired,
  cols: PropTypes.arrayOf(PropTypes.shape(colsPropTypeShape)).isRequired,
  rowIndex: PropTypes.number
}

export default MobileHiddenCols
