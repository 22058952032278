import { createStyles } from '../../../styles/helpers'

import { transitionShort } from '../../../styles/const/common'
import { phonesDown } from '../../../styles/const/breakpoints'
import { cardWithBorder } from '../../../styles/mixins/common'
import { borderDarkGrey } from '../../../styles/const/colors'

const useStyles = createStyles(theme => ({
  suggestionCard: {
    extend: cardWithBorder(theme.brandPrimary, theme),
    '&:before': {
      background: theme.brandPrimary
    },
    padding: '10px 13px 10px 16px',
    justifyContent: 'space-between',
    height: 50
  },
  suggestionIcon: {
    margin: 'auto 15px auto 0'
  },
  suggestionContent: {
    display: 'flex',
    width: '100%'
  },
  suggestionTitle: {
    color: borderDarkGrey,
    fontSize: 14,
    fontWeight: '700'
  },
  suggestionText: {
    color: borderDarkGrey,
    fontSize: 12,
    fontWeight: '500',
    marginTop: 5
  },
  suggestionInfoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  suggestionArrowIconWrapper: {
    paddingLeft: 10
  },
  suggestionArrowIcon: {
    display: ({ linkCard }) => !linkCard && 'none',
    position: 'relative',
    top: ({ linkCard }) => linkCard && 9,
    width: 16,
    height: 16,
    transform: ({ isOpened }) => (isOpened ? 'rotate(0deg)' : 'rotate(-90deg)'),
    transition: transitionShort,
    color: theme.brandPrimary,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  suggestionLink: {
    textDecoration: 'none'
  },
  [`@media screen and (${phonesDown})`]: {
    suggestionCard: {
      display: 'block',
      flexWrap: 'wrap',
      height: 'auto',
      padding: ({ linkCard }) => !linkCard && 17,
      '&:before': {
        display: ({ linkCard }) => !linkCard && 'none'
      }
    },
    suggestionTitle: {
      fontSize: ({ linkCard }) => !linkCard && 18
    },
    suggestionButton: {
      width: '100%',
      marginTop: 16,
      height: 48
    },
    suggestionArrowIcon: {
      display: ({ linkCard }) => !linkCard && 'block'
    }
  }
}))

export default useStyles
