import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import Field from '../../../../../../components/Form/Field'
import EditForm from '../../../../../../features/components/Forms/EditForm'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'

import { filterObjectEmptyValues } from '../../../../../../helpers/common'

import { updateCampaign, clearUpdateCampaign } from '../../../../../../modules/actions/campaigns'
import {
  campaignUpdateErrorSelector,
  campaignUpdateIsLoadingSelector,
  campaignWasUpdatedSelector
} from '../../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector, selectedAdAccountDataSelector } from '../../../../../../modules/selectors/app'

import { BUDGET_DAILY, BUDGET_LIFETIME } from '../../../../../ReusableFormFields/CampaignForms/fields'
import { TIKTOK_PLATFORM } from '../../../../../../constants/selectLists/platformList'
import { getValidationSchema } from './validation'

const BudgetSectionForm = ({ campaign, budgetType, ...formProps }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const campaignWasUpdated = useSelector(campaignWasUpdatedSelector)
  const { currency_symbol: currencySymbol } = useSelector(selectedAdAccountDataSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const handleSubmit = useCallback(
    values => {
      const updateCampaignData = {
        ...filterObjectEmptyValues(campaign),
        ...(budgetType === BUDGET_LIFETIME && { [BUDGET_LIFETIME]: Number(values[BUDGET_LIFETIME]) }),
        ...(budgetType === BUDGET_DAILY && { [BUDGET_DAILY]: values[BUDGET_DAILY] }),
        account: selectedAdAccountId
      }

      // we use PUT to update TikTok campaign
      dispatch(
        updateCampaign(updateCampaignData, campaign.id, {
          platform: TIKTOK_PLATFORM,
          requestMethod: 'PUT'
        })
      )
    },
    [campaign, budgetType, selectedAdAccountId, dispatch]
  )

  const initialValues = useMemo(
    () => ({
      [BUDGET_LIFETIME]: (campaign && campaign[BUDGET_LIFETIME]) || '',
      [BUDGET_DAILY]: (campaign && campaign[BUDGET_DAILY]) || ''
    }),
    [campaign]
  )

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: getValidationSchema(budgetType),
    onSubmit: handleSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const handleClearUpdateCampaign = useCallback(() => {
    dispatch(clearUpdateCampaign())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={campaignWasUpdated}
      clearEditItem={handleClearUpdateCampaign}
      errorSelector={campaignUpdateErrorSelector}
      isLoadingSelector={campaignUpdateIsLoadingSelector}
      {...formProps}
    >
      {budgetType === BUDGET_LIFETIME && (
        <>
          <p>{t('A total budget for the whole campaign.')}</p>
          <p>{t('TikTok will allocate your budget across all your Ad Sets based on performance.')}</p>
          <Field
            type="number"
            formik={purifiedFormik}
            name={BUDGET_LIFETIME}
            placeholder="Lifetime Budget"
            symbol={currencySymbol}
            autoComplete="off"
          />
        </>
      )}
      {budgetType === BUDGET_DAILY && (
        <>
          <p>{t('Set an average amount you want to spend each day.')}</p>
          <p>{t('TikTok will allocate your budget across all your Ad Groups based on performance.')}</p>
          <Field
            type="number"
            formik={purifiedFormik}
            name={BUDGET_DAILY}
            placeholder="Daily Budget"
            symbol={currencySymbol}
            autoComplete="off"
          />
        </>
      )}
    </EditForm>
  )
}

export default BudgetSectionForm
