import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import SelectPaginated from '../SelectPaginated'
import SelectOptionCheckbox from './SelectOptionCheckbox'
import MultipleCheckboxesValueContainer from './MultipleCheckboxesValueContainer'
import HighlightedValueSelect from '../HighlightedValueSelect'
import SelectMultiplePlaceholder from './SelectMultiplePlaceholder'

const MultipleCheckboxesSelect = ({
  options,
  value = [],
  onChange,
  allowSelectAll,
  handleChangeOnClose,
  placeholder,
  className,
  isSearchable,
  multipleOptionsLabel,
  modifySelectedOptions,
  ...props
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [selectedOptions, setSelectedOptions] = useState(value)
  // State to hold the temporary selected options
  const [tempSelectedOptions, setTempSelectedOptions] = useState(value)

  const handleSearchChange = useCallback(
    (newValue, data) => {
      if (data.action !== 'set-value') {
        // don't clear search text in input, when user selects an option
        setSearchValue(newValue)
      }
    },
    [setSearchValue]
  )

  const onChangeHandler = useCallback(
    data => {
      let updatedOptions = data.map(item => item.value)
      if (modifySelectedOptions) {
        // in some cases we need to update (select/deselect) options from outside
        updatedOptions = modifySelectedOptions(updatedOptions)
      }

      if (handleChangeOnClose) {
        setTempSelectedOptions(updatedOptions)
      } else {
        setSelectedOptions(updatedOptions)
        onChange && onChange(updatedOptions)
      }
    },
    [handleChangeOnClose, onChange, modifySelectedOptions]
  )

  // Function to handle menu close
  const handleMenuClose = useCallback(() => {
    if (handleChangeOnClose) {
      setSelectedOptions(tempSelectedOptions)
      onChange && onChange(tempSelectedOptions)
    }
  }, [onChange, tempSelectedOptions, handleChangeOnClose])

  const clearOptions = useCallback(() => {
    setSelectedOptions([])

    onChange && onChange([])
  }, [setSelectedOptions, onChange])

  const SelectComponent = props?.loadOptions ? SelectPaginated : HighlightedValueSelect

  return (
    <SelectComponent
      isMulti
      inputValue={searchValue}
      onInputChange={handleSearchChange}
      value={handleChangeOnClose ? tempSelectedOptions : selectedOptions}
      placeholder={placeholder}
      className={className}
      options={options}
      onChange={onChangeHandler}
      onSelectAll={allowSelectAll ? onChangeHandler : undefined}
      onClearInput={clearOptions}
      isSearchable={isSearchable}
      CustomPlaceholder={SelectMultiplePlaceholder}
      CustomOption={SelectOptionCheckbox}
      CustomValueContainer={MultipleCheckboxesValueContainer}
      backspaceRemovesValue={false}
      // hide value, as we render in custom MultipleCheckboxesValueContainer
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      // next 2 props are used to prevent closing the dropdown when user select an option
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      onMenuClose={handleMenuClose}
      multipleOptionsLabel={multipleOptionsLabel}
      {...props}
    />
  )
}

MultipleCheckboxesSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ),
  value: PropTypes.array,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool, // show select all option
  handleChangeOnClose: PropTypes.bool, // don't make a onChange callback until the Select menu is closed
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isSearchable: PropTypes.bool,
  multipleOptionsLabel: PropTypes.string,
  modifySelectedOptions: PropTypes.func
}

export default MultipleCheckboxesSelect
