import * as Yup from 'yup'
import { urlValidation } from '../../../../features/validations/other'

export const NAME = 'name'
export const LINK = 'link'
export const THUMBNAIL_URL = 'thumbnail_url'
export const IMAGE_URLS = 'image_urls'

export const validationSchema = Yup.object({
  [NAME]: Yup.string().required('Name required'),
  [LINK]: urlValidation,
  [THUMBNAIL_URL]: urlValidation,
  [IMAGE_URLS]: Yup.array().of(
    Yup.object({
      text: urlValidation
    })
  )
})

export const transformValuesToBE = values => {
  return {
    [NAME]: values[NAME],
    [LINK]: values[LINK],
    [THUMBNAIL_URL]: values[THUMBNAIL_URL],
    [IMAGE_URLS]: values[IMAGE_URLS].map(({ text }) => text)
  }
}
