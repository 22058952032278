import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'

import DeepCopyStep from './Steps/DeepCopyStep'
import LineItemStartTimeStep from '../../../../ReusableFormSteps/LineItemStartTimeStep'
import LineItemStopTimeStep from '../../../../ReusableFormSteps/LineItemStopTimeStep'
import StatusStep from './Steps/StatusStep'
import CampaignDuplicationProgressStep from './Steps/CampaignDuplicationProgressStep'
import StepForm from '../../../../../features/components/Forms/StepForm'

import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'
import useManageFormsDrawer from '../../../../../hooks/formHooks/useManageFormsDrawer'

import {
  duplicateCampaignErrorSelector,
  duplicateCampaignIsLoadingSelector
} from '../../../../../modules/selectors/campaigns'
import { clearDuplicateCampaign } from '../../../../../modules/actions/campaigns'
import { clearAsyncSession } from '../../../../../modules/actions/app'

import { CAMPAIGN_FACEBOOK_DUPLICATE } from '../../../../../constants/forms'
import { DEEP_COPY_OPTION, DEEP_COPY_YES, initialValues, validationSchema } from './fields'

function CampaignFacebookDuplicateForm() {
  const dispatch = useDispatch()

  const clearDuplicateCampaignHandler = useCallback(() => {
    dispatch(clearDuplicateCampaign())
    dispatch(clearAsyncSession())
  }, [dispatch])

  const { handleFormClose, selectedEditItemId } = useManageFormsDrawer({
    onAfterFormClose: clearDuplicateCampaignHandler,
    formName: CAMPAIGN_FACEBOOK_DUPLICATE
  })

  const onSuccessSubmit = useCallback(() => {
    handleFormClose()
  }, [handleFormClose])

  const formik = useFormik({
    initialValues,
    validationSchema
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const { values } = purifiedFormik

  const isDeepCopy = values[DEEP_COPY_OPTION] === DEEP_COPY_YES

  const steps = useMemo(
    () => [
      {
        component: <DeepCopyStep />,
        show: true
      },
      {
        component: (
          <LineItemStartTimeStep
            stepSubTitle="Duplicate a campaign"
            stepTitle="When would you like the ad sets to start?"
          />
        ),
        show: isDeepCopy
      },
      {
        component: (
          <LineItemStopTimeStep
            stepTitle="When would you like the ad sets to end?"
            stepSubTitle="Duplicate a campaign"
            indefiniteOptionDescription="The ad sets will run indefinitely until paused"
            allowMultiple
          />
        ),
        show: isDeepCopy
      },
      {
        component: <StatusStep selectedEditItemId={selectedEditItemId} />,
        show: true
      },
      {
        component: <CampaignDuplicationProgressStep onSuccessSubmit={onSuccessSubmit} />,
        show: true
      }
    ],
    [selectedEditItemId, onSuccessSubmit, isDeepCopy]
  )

  return (
    <StepForm
      formName={CAMPAIGN_FACEBOOK_DUPLICATE}
      steps={steps}
      formik={purifiedFormik}
      submitOnLastStep={false}
      errorSelector={duplicateCampaignErrorSelector}
      isLoadingSelector={duplicateCampaignIsLoadingSelector}
    />
  )
}

export default CampaignFacebookDuplicateForm
