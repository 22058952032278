import React from 'react'
import { getIn } from 'formik'

import ProductSelectStep from '../Steps/ProductSelectStep'

import { getPackageFlow } from '../helpers'
import { getAllPackageProducts } from '../helpers/products'
import { insertIf } from '../../../../../../../helpers/common'
import { getProductSetupStep } from '../helpers/stepsGeneration'

import {
  ALLOWED_CATEGORY_PRODUCTS_SELECTION,
  ALLOWED_PRODUCT_SELECTION,
  CATEGORY_PRODUCTS_PACKAGE,
  FIXED_PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE,
  PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE
} from '../fields'

export function useMediaOrderStepsGenerate({ selectedCurrency, selectedPackages }) {
  return selectedPackages.flatMap((selectedMediaPackage, packageIndex) => {
    const productsPackageFlow = getPackageFlow(selectedMediaPackage)
    const generalPackageProps = {
      selectedCurrency,
      selectedMediaPackage,
      packageIndex,
      productsPackageFlow
    }

    if (productsPackageFlow === CATEGORY_PRODUCTS_PACKAGE) {
      // user sees first media category’s products, selects one and setup it
      // after that can add the next until all available categories are added.

      const allowedProductsSelection = getIn(selectedPackages, `${packageIndex}.${ALLOWED_CATEGORY_PRODUCTS_SELECTION}`)
      // we are looking for first allowed category to select products
      const nextCategoryToSelectProducts = Object.keys(allowedProductsSelection).find(
        key => allowedProductsSelection[key] === true
      )
      const packageProducts = getAllPackageProducts(selectedMediaPackage)
      // we are checking if there is any product that is ready to setup
      const productReadyToSetup = packageProducts.find(product => product.isSetupAllowed)
      // don't show product selection step until previous product is not set up to the end
      const allowProductSelection = nextCategoryToSelectProducts && !productReadyToSetup

      return [
        ...insertIf(allowProductSelection, {
          component: (
            <ProductSelectStep
              packageFlow={productsPackageFlow}
              packageIndex={packageIndex}
              currency={selectedCurrency}
              selectedMediaPackage={selectedMediaPackage}
              categoryName={nextCategoryToSelectProducts}
            />
          ),
          show: true
        }),
        ...getProductSetupStep(generalPackageProps)
      ]
    } else if (productsPackageFlow === PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE) {
      // user sees list of pre-defined products, selects one, then can optionally add other(s) from that same list
      const allowProductSelection = getIn(selectedPackages, `${packageIndex}.${ALLOWED_PRODUCT_SELECTION}`)

      // pass pre-defined products ids to filter only them, pass as string because of re-fetching issue on new
      // array creation based on mapping. Could be replaced when BE will return ID only instead of full object
      // we fetch products data to avoid inconsistency with other cases(now all products for selection will be
      // fetched) as well as be able to filter data with BE filters
      const preDefinedProductsFilter = selectedMediaPackage.media_products.map(product => product.id)?.join(',')

      return [
        ...insertIf(allowProductSelection, {
          component: (
            <ProductSelectStep
              packageFlow={productsPackageFlow}
              packageIndex={packageIndex}
              currency={selectedCurrency}
              selectedMediaPackage={selectedMediaPackage}
              preDefinedProductsFilter={preDefinedProductsFilter}
            />
          ),
          show: true
        }),
        ...getProductSetupStep(generalPackageProps)
      ]
    } else if (productsPackageFlow === FIXED_PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE) {
      // user sees period selector page for each pre-defined product and then can buy
      return [...getProductSetupStep(generalPackageProps)]
    } else {
      // productsPackageFlow === FIXED_PRE_DEFINED_PRODUCTS__FIXED_DATES_PACKAGE
      // user see last step with all products and periods and only can buy
      return []
    }
  })
}
