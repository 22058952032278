import { createStyles } from '../../styles/helpers'

export default createStyles({
  actionText: {
    display: 'inline-flex',
    fontSize: 14,
    color: ({ colorsSchema }) => colorsSchema.color,
    cursor: ({ isDisabled }) => (isDisabled ? 'unset' : 'pointer'),
    fontWeight: ({ isBold }) => (isBold ? 600 : 400),
    textDecoration: ({ isUnderlined }) => (isUnderlined ? 'underline' : 'none'),

    '&:hover': {
      color: ({ colorsSchema }) => colorsSchema.hoverColor
    },

    '&.isTouched': {
      color: ({ colorsSchema }) => colorsSchema.touchedColor
    }
  }
})
