import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'

import { ReactComponent as ImageIcon } from '../../../../../assets/icons/image.svg'

import { uploadedFileSelector } from '../../../../../modules/selectors/files'

import { FILE_IS_PROCESSING } from '../../../../../constants/ads'

import { singleAdThumbnailHeight } from '../AdCardSingleContent/styles'
import useStyles from './styles'

function SkeletonAdMediaThumbnail({ isInProgress, className, mediaPreviewHeight = singleAdThumbnailHeight, fileName }) {
  const classes = useStyles({ mediaPreviewHeight })
  const { t } = useTranslation()

  const { [fileName]: uploadedFile } = useSelector(uploadedFileSelector)
  const isFileProcessing = uploadedFile && uploadedFile.status === FILE_IS_PROCESSING

  const ImageSkeleton = ({ isInProgress }) => {
    const accentStyle = isInProgress ? classes.adImageYellow : classes.adImageGrey

    return (
      <div className={classnames(classes.adImage, className, accentStyle)}>
        <ImageIcon />
      </div>
    )
  }

  if (isInProgress) {
    // when file is processing by Facebook show animation
    return isFileProcessing ? (
      <div className={classes.skeletonContainer}>
        <Skeleton style={{ height: mediaPreviewHeight, position: 'relative' }} />
        <p className={classes.processingMessage}>{t('Please wait while the video file is processing')}</p>
      </div>
    ) : (
      <ImageSkeleton isInProgress={isInProgress} />
    )
  } else {
    return <ImageSkeleton isInProgress={false} />
  }
}

SkeletonAdMediaThumbnail.propTypes = {
  isInProgress: PropTypes.bool,
  fileName: PropTypes.string,
  className: PropTypes.string,
  mediaPreviewHeight: PropTypes.string
}
export default SkeletonAdMediaThumbnail
