import * as Yup from 'yup'

import {
  BIDDING_TYPE,
  COST_CAP,
  OUTCOME_AWARENESS,
  OUTCOME_ENGAGEMENT,
  OUTCOME_SALES,
  OUTCOME_TRAFFIC
} from '../../../../../constants/campaigns'
import {
  BUDGET_DAILY,
  BUDGET_LIFETIME,
  BUDGET_OPTION,
  NAME,
  OBJECTIVE,
  SPECIAL_AD_CATEGORIES,
  SPECIAL_AD_CATEGORY_COUNTRY,
  STATUS,
  STATUS_ACTIVE
} from '../../../../ReusableFormFields/CampaignForms/fields'
import nameValidation from '../../../../../features/validations/name'

export const optimizationGoalOptions = [
  {
    label: 'Awareness',
    value: OUTCOME_AWARENESS
  },
  {
    label: 'Sales',
    value: OUTCOME_SALES
  },
  {
    label: 'Traffic',
    value: OUTCOME_TRAFFIC
  },
  {
    label: 'Engagement',
    value: OUTCOME_ENGAGEMENT
  }
]

export const initialValues = {
  [NAME]: '',
  [OBJECTIVE]: OUTCOME_AWARENESS,
  [BUDGET_OPTION]: BUDGET_LIFETIME,
  [BUDGET_LIFETIME]: '',
  [BUDGET_DAILY]: '',
  [BIDDING_TYPE]: COST_CAP,
  [SPECIAL_AD_CATEGORIES]: [],
  [SPECIAL_AD_CATEGORY_COUNTRY]: []
}

export const validationSchema = Yup.object().shape({
  ...nameValidation,
  [BUDGET_LIFETIME]: Yup.string().when([BUDGET_OPTION, OBJECTIVE], {
    // When user select OUTCOME_AWARENESS we hide budget field
    is: (CAMPAIGN_BUDGET, OBJECTIVE) => CAMPAIGN_BUDGET === BUDGET_LIFETIME && OBJECTIVE !== OUTCOME_AWARENESS,
    then: () => Yup.string().required('Budget Required')
  }),
  [BUDGET_DAILY]: Yup.string().when([BUDGET_OPTION, OBJECTIVE], {
    // When user select OUTCOME_AWARENESS we hide budget field
    is: (CAMPAIGN_BUDGET, OBJECTIVE) => CAMPAIGN_BUDGET === BUDGET_DAILY && OBJECTIVE !== OUTCOME_AWARENESS,
    then: () => Yup.string().required('Budget Required')
  }),
  // When any special_ad_categories are added to a Facebook campaign, you must also set a special_ad_category_country.
  // write validation so countries required only when there are selected categories
  [SPECIAL_AD_CATEGORY_COUNTRY]: Yup.array().when(SPECIAL_AD_CATEGORIES, {
    is: specialAdCategories => specialAdCategories.length > 0,
    then: () => Yup.array().min(1, 'Country is required')
  })
})

export const transformValuesToBE = (values, selectedAdAccountId) => {
  const specialAdCategories = values[SPECIAL_AD_CATEGORIES]
  const specialAdCategoriesCountries = values[SPECIAL_AD_CATEGORY_COUNTRY]

  const showBudgetField = values[OBJECTIVE] !== OUTCOME_AWARENESS
  const isLifetimeBudget = values[BUDGET_OPTION] === BUDGET_LIFETIME
  const isDailyBudget = values[BUDGET_OPTION] === BUDGET_DAILY

  return {
    [STATUS]: STATUS_ACTIVE,
    account: selectedAdAccountId,
    [NAME]: values[NAME],
    [OBJECTIVE]: values[OBJECTIVE],
    // BIDDING_TYPE ( cost cap approach ) - only show this field if user selects Sales
    ...(values[OBJECTIVE] === OUTCOME_SALES && { [BIDDING_TYPE]: values[BIDDING_TYPE] }),

    // BUDGET
    ...(showBudgetField && isLifetimeBudget && { budget_lifetime: values[BUDGET_LIFETIME] }),
    ...(showBudgetField && isDailyBudget && { budget_daily: values[BUDGET_DAILY] }),

    // SPECIAL_AD_CATEGORIES
    ...(specialAdCategories.length && { [SPECIAL_AD_CATEGORIES]: specialAdCategories.map(item => item.value) }),
    ...(specialAdCategoriesCountries.length && {
      [SPECIAL_AD_CATEGORY_COUNTRY]: specialAdCategoriesCountries.map(item => item.iso2)
    })
  }
}
