import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Form from '../../../../../components/Form'
import FormField from './FormField'
import UserPhoneField from '../UserPhoneField'
import Field from '../../../../../components/Form/Field'
import { TabletDown, TabletUp } from '../../../../../components/hoc/ResponsiveRendering'

import { formatOptionsList } from '../../../../../features/formatters'

import {
  getCurrentUserSelector,
  updateCurrentUserErrorSelector,
  updateCurrentUserLoadingSelector,
  updateCurrentUserWasUpdatedSelector
} from '../../../../../modules/selectors/app'
import { choicesCountriesSelector } from '../../../../../modules/selectors/choices'

import { COUNTRY, FIRST_NAME, getInitialValues, LAST_NAME } from '../fields'
import { getValuesWereChanged } from '../../../../../components/FileDetailsPreview/helpers'

import useFormStyles from '../../styles'

const EditProfileFormContent = ({ formik, onSuccessSubmit, clearHandler }) => {
  const formClasses = useFormStyles()

  const { t } = useTranslation()

  const [updatedValue, setUpdatedValue] = useState(null)

  const user = useSelector(getCurrentUserSelector)
  const countries = useSelector(choicesCountriesSelector)
  const userWasUpdated = useSelector(updateCurrentUserWasUpdatedSelector)

  const { values } = formik

  const valuesWereChanged = useMemo(() => {
    const initialValues = getInitialValues(user)
    // valueWasUpdated needs to check that values were updated before onBlur
    return getValuesWereChanged(initialValues, values, updatedValue)
  }, [user, values, updatedValue])

  const formattedCountries = formatOptionsList({
    list: countries,
    valueName: 'id',
    labelName: 'name'
  })

  // need for checking that field was changed before blur
  const handleChange = (value, fieldName) => {
    if (value !== updatedValue?.value || fieldName !== updatedValue?.fieldName) {
      setUpdatedValue({ value, fieldName })
    }
  }

  return (
    <>
      <TabletUp>
        <div className={formClasses.formWrapper}>
          <Form
            formName="editProfile"
            formik={formik}
            method="post"
            successSubmit={userWasUpdated}
            errorSelector={updateCurrentUserErrorSelector}
            isLoadingSelector={updateCurrentUserLoadingSelector}
            onSuccessSubmit={onSuccessSubmit}
            clearHandler={clearHandler}
            submitText={t('Save changes')}
            formFooterClassName={formClasses.formFooter}
            buttonProps={{
              disabled: !valuesWereChanged,
              wrapperClassName: formClasses.submitButtonWrapper
            }}
          >
            <FormField label={t('First name')}>
              <Field
                onValueChange={handleChange}
                formik={formik}
                id={FIRST_NAME}
                name={FIRST_NAME}
                placeholder="First Name"
              />
            </FormField>
            <FormField label={t('Last name')}>
              <Field
                onValueChange={handleChange}
                formik={formik}
                id={LAST_NAME}
                name={LAST_NAME}
                placeholder="Last Name"
              />
            </FormField>
            <FormField label={t('Phone')}>
              <UserPhoneField formik={formik} onValueChange={handleChange} />
            </FormField>
            <FormField label={t('Country')} isLastFormField>
              <Field
                onValueChange={handleChange}
                options={formattedCountries}
                formik={formik}
                id={COUNTRY}
                name={COUNTRY}
                placeholder="Default Country"
              />
            </FormField>
          </Form>
        </div>
      </TabletUp>
      <TabletDown>
        <Form
          formName="editProfile"
          formik={formik}
          method="post"
          successSubmit={userWasUpdated}
          errorSelector={updateCurrentUserErrorSelector}
          isLoadingSelector={updateCurrentUserLoadingSelector}
          onSuccessSubmit={onSuccessSubmit}
          clearHandler={clearHandler}
          submitText={t('Save')}
          className={formClasses.form}
          buttonProps={{
            disabled: !valuesWereChanged
          }}
        >
          <div className={formClasses.formRow}>
            <Field formik={formik} id={FIRST_NAME} name={FIRST_NAME} placeholder="First Name" />
            <Field formik={formik} id={LAST_NAME} name={LAST_NAME} placeholder="Last Name" />
          </div>
          <div className={formClasses.formRow}>
            <UserPhoneField formik={formik} onValueChange={handleChange} />
            <Field
              options={formattedCountries}
              formik={formik}
              id={COUNTRY}
              name={COUNTRY}
              placeholder="Default Country"
            />
          </div>
        </Form>
      </TabletDown>
    </>
  )
}

EditProfileFormContent.propTypes = {
  formik: PropTypes.object.isRequired,
  onSuccessSubmit: PropTypes.func.isRequired,
  clearHandler: PropTypes.func.isRequired
}

export default EditProfileFormContent
