import { call, put, all, takeEvery, select } from 'redux-saga/effects'

import { createAudienceService, deleteAudienceService, getAudiencesService } from '../services/audiences'
import {
  GET_AUDIENCES,
  getAudiencesFailure,
  getAudiencesSuccess,
  CREATE_AUDIENCE,
  createAudienceFailure,
  createAudienceSuccess,
  DELETE_AUDIENCE,
  deleteAudienceFailure,
  deleteAudienceSuccess,
  CREATE_AUDIENCE_BY_SUGGESTION,
  createAudienceBySuggestionSuccess,
  createAudienceBySuggestionFailure
} from '../actions/audiences'
import { selectedPlatformSelector } from '../selectors/app'

function* audiencesWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_AUDIENCES, getAudiencesWorker),
    takeEvery(CREATE_AUDIENCE, createAudienceWorker),
    takeEvery(CREATE_AUDIENCE_BY_SUGGESTION, createAudienceBySuggestionWorker),
    takeEvery(DELETE_AUDIENCE, deleteAudienceWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getAudiencesWorker({ params, platform }) {
  try {
    const selectedPlatform = yield select(selectedPlatformSelector)

    const response = yield call(getAudiencesService, params, platform || selectedPlatform)
    yield put(
      getAudiencesSuccess(response, {
        isPaginationLoad: !!response.paging
      })
    )
  } catch (e) {
    yield put(getAudiencesFailure(e))
  }
}

function* createAudienceWorker({ data, platform }) {
  try {
    const selectedPlatform = yield select(selectedPlatformSelector)

    const response = yield call(createAudienceService, data, platform || selectedPlatform)
    yield put(createAudienceSuccess(response))
  } catch (e) {
    yield put(createAudienceFailure(e))
  }
}

function* createAudienceBySuggestionWorker({ params, platform }) {
  try {
    const selectedPlatform = yield select(selectedPlatformSelector)

    const response = yield call(createAudienceService, params, platform || selectedPlatform)
    yield put(createAudienceBySuggestionSuccess(response))
  } catch (e) {
    yield put(createAudienceBySuggestionFailure(e))
  }
}

function* deleteAudienceWorker({ params, platform }) {
  try {
    const selectedPlatform = yield select(selectedPlatformSelector)

    yield call(deleteAudienceService, params, platform || selectedPlatform)
    yield put(deleteAudienceSuccess(params.id))
  } catch (e) {
    yield put(deleteAudienceFailure(e))
  }
}

export default audiencesWatcher
