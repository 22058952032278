import * as Yup from 'yup'
import { t } from 'i18next'

import { MEDIA_HEIGHT, MEDIA_RATIO, MEDIA_WIDTH } from '../../../../../ReusableFormFields/AdFileUpload/fields'
import {
  landscapeMinHeight,
  landscapeMinWidth,
  landscapeRatio,
  portraitMinHeight,
  portraitMinWidth,
  portraitRatio
} from '../../../validations'
import { ASSET_IMAGE_LIST } from '../fields'

export const imageRatioValidationSchema = Yup.object().test({
  name: 'Custom Asset validation',
  test: (value, context) => {
    const ratio = value[MEDIA_RATIO]
    const width = value[MEDIA_WIDTH]
    const height = value[MEDIA_HEIGHT]
    const roundedCurrentRatio = Math.round(ratio * 100) / 100

    if (roundedCurrentRatio !== landscapeRatio && roundedCurrentRatio !== 1 && roundedCurrentRatio !== portraitRatio) {
      return context.createError({
        message: 'Sorry, the image ratio of that file is not correct. Please upload an image with a specified ratio.',
        path: ASSET_IMAGE_LIST
      })
    }

    if (roundedCurrentRatio === landscapeRatio) {
      // Check landscape image minWidth and minHeight
      if (width < landscapeMinWidth) {
        return context.createError({
          message: t('minSupportedImageWidth', { minWidth: landscapeMinWidth }),
          path: ASSET_IMAGE_LIST
        })
      }
      if (height < landscapeMinHeight) {
        return context.createError({
          message: t('minSupportedImageHeight', { minHeight: landscapeMinHeight }),
          path: ASSET_IMAGE_LIST
        })
      }
    }

    if (roundedCurrentRatio === portraitRatio) {
      // Check portrait image minWidth and minHeight
      if (width < portraitMinWidth) {
        return context.createError({
          message: t('minSupportedImageWidth', { minWidth: portraitMinWidth }),
          path: ASSET_IMAGE_LIST
        })
      }
      if (width < portraitMinHeight) {
        return context.createError({
          message: t('minSupportedImageHeight', { minHeight: portraitMinHeight }),
          path: ASSET_IMAGE_LIST
        })
      }
    }

    return true
  }
})
