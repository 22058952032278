import { gatherMultiLevelSelectInitialValues } from '../../../../../../components/SelectMultiLevel/formatters'

import { GENDER_OPTION } from '../../../../../ReusableFormFields/GenderFields/fields'
import { GEOLOCATIONS } from '../../fields'
import { AGE } from '../../../../../Multiplatform/LineItemForms/fields'
import {
  AGE_13_17,
  AGE_18_24,
  AGE_25_34,
  AGE_35_44,
  AGE_45_54,
  AGE_55_100
} from '../../../../../ReusableFormFields/AgeRangesFields/fields'

export const formatValuesToFE = ({ lineItem = {}, allowLocationsEdit, formattedRegionOptions }) => {
  const { age, gender, location } = lineItem

  return {
    [AGE]: {
      [AGE_13_17]: (age && age.includes(AGE_13_17)) || false,
      [AGE_18_24]: (age && age.includes(AGE_18_24)) || false,
      [AGE_25_34]: (age && age.includes(AGE_25_34)) || false,
      [AGE_35_44]: (age && age.includes(AGE_35_44)) || false,
      [AGE_45_54]: (age && age.includes(AGE_45_54)) || false,
      [AGE_55_100]: (age && age.includes(AGE_55_100)) || false
    },
    [GENDER_OPTION]: gender || '',
    // geolocations
    ...(allowLocationsEdit && {
      [GEOLOCATIONS]: (location && gatherMultiLevelSelectInitialValues(location, formattedRegionOptions)) || []
    })
  }
}
