import * as Yup from 'yup'

import { SELECTED_PRODUCTS } from './AmendmentContractCreateForm/fields'
import { PRODUCT_PERIODS_DATES } from '../../../fields'

export const validationSchema = Yup.object({
  [SELECTED_PRODUCTS]: Yup.array().of(
    Yup.object().shape({
      [PRODUCT_PERIODS_DATES]: Yup.array().of(
        Yup.object().shape({
          price: Yup.number().required('Price is required')
        })
      )
    })
  )
})
