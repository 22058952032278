import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import LineItemFacebookCreateForm from './LineItemFacebookCreateForm'

import useRedirectFormDrawer from '../../../../features/components/FormDrawerWrapper/useRedirectFormDrawer'

import { clearLocationLists } from '../../../../modules/actions/location'
import { lineItemsWasLoadedSelector } from '../../../../modules/selectors/lineItems'

import { LINE_ITEM_FACEBOOK_CREATE } from '../../../../constants/forms'
import { ROUTES } from '../../../../constants/routes'
import { MODAL_TYPE } from '../../../../constants/other'

const LineItemFacebookCreate = () => {
  const dispatch = useDispatch()

  const lineItemsWasLoaded = useSelector(lineItemsWasLoadedSelector)

  const handleSuccessRedirect = useRedirectFormDrawer(
    `${ROUTES.lineItemsSummary}?message=${MODAL_TYPE.newLineItemCreated}`
  )

  useEffect(() => {
    return () => {
      dispatch(clearLocationLists())
    }
  }, [dispatch])

  return (
    <FormDrawerWrapper
      openButtonText="Create Ad Set"
      formName={LINE_ITEM_FACEBOOK_CREATE}
      closeOnSubmit={false}
      // LineItemFacebookCreateForm requires line items to be loaded for correct route or initial values setup
      isFormLoading={!lineItemsWasLoaded}
      hasDefaultDrawerContentSpacing={false}
      isWideDrawer
    >
      <LineItemFacebookCreateForm onSuccessSubmit={handleSuccessRedirect} />
    </FormDrawerWrapper>
  )
}

export default LineItemFacebookCreate
