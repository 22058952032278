import { createStyles } from '../../../../styles/helpers'

import { darkGrey, mediumGrey } from '../../../../styles/const/colors'
import { tabletDown } from '../../../../styles/const/breakpoints'

const useStyles = createStyles({
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 32,
    '&:not(:first-child)': {
      paddingTop: 24
    },
    '&:not(:last-child)': {
      paddingBottom: 24,
      borderBottom: `1px solid ${mediumGrey}`
    }
  },
  leftSide: {
    width: '100%',
    minWidth: 160,
    maxWidth: 225
  },
  rightSide: {
    width: '100%',
    overflowX: 'hidden'
  },
  title: {
    fontSize: 14,
    fontWeight: 600
  },
  description: {
    marginTop: 8,
    color: darkGrey,
    fontSize: 12,
    whiteSpace: 'pre-wrap'
  },
  leftColumnChildren: {
    marginTop: 16
  },
  [`@media screen and (${tabletDown})`]: {
    item: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
      columnGap: 32,
      '&:not(:last-child)': {
        paddingBottom: 8,
        borderBottom: `1px solid ${mediumGrey}`
      }
    },
    leftSide: {
      maxWidth: 'none'
    },
    rightSide: {
      maxWidth: 'none'
    }
  }
})

export default useStyles
