import { createStyles } from '../../../../../styles/helpers'

import { darkGrey, textGrey } from '../../../../../styles/const/colors'

const useStyles = createStyles(theme => ({
  icon: {
    '& path': {
      fill: theme.brandPrimary
    }
  },
  content: {
    marginTop: 44
  },
  resendMessage: {
    marginTop: [40, '!important'],
    textAlign: 'center',
    color: textGrey
  },
  resendButton: {
    border: 'none',
    background: 'none',
    padding: 0,
    fontSize: 14,
    textDecoration: 'underline',
    outline: 'none',
    color: textGrey,
    cursor: 'pointer',
    '&:hover': {
      color: darkGrey
    }
  },
  modalMessage: {
    color: textGrey
  }
}))

export default useStyles
