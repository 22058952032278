import { addDays } from 'date-fns'

export function findLimitDates(availableDates, selectedDate) {
  let before = null
  let after = null
  const selectedTime = selectedDate.getTime()

  // Loop through availableDates once to find closest dates before and after selectedDate
  for (const dateInput of availableDates) {
    // Parse each date string to a Date object only once
    const date = dateInput instanceof Date ? dateInput : new Date(dateInput)
    const time = date.getTime()

    if (time < selectedTime) {
      // If the date is before selectedDate, update 'before' if it's closer
      if (before === null || time > before.getTime()) {
        before = date
      }
    } else if (time > selectedTime) {
      // If the date is after selectedDate, update 'after' if it's closer
      if (after === null || time < after.getTime()) {
        after = date
      }
    }
    // Dates equal to selectedDate are ignored
  }

  // Return the closest available dates before and after the selected date
  return { minDate: before, maxDate: after }
}

export function sortByStartDateValue(a, b) {
  // this helper is used to sort periods by start_date field from earliest to latest date
  // it expects to work with Select component options which has value field as date string
  // so before compare it should be converted to Date object
  return new Date(a.value) - new Date(b.value)
}

export const getStartAdjustedDate = (date, days) => {
  return date ? addDays(new Date(date), -days) : date
}

export const getEndAdjustedDate = (date, days) => {
  return date ? addDays(new Date(date), days) : date
}
