import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useTranslatedChildren } from '../../../../hooks/useTranslatedChildren'

import useStyles from './styles'

function FieldLabelWrapper({
  label,
  labelFor,
  className,
  contentClassName,
  children,
  labelClassName,
  horizontalView = false
}) {
  const { t } = useTranslation()

  const translatedChildren = useTranslatedChildren(children)

  const classes = useStyles({ horizontalView })

  return (
    <div className={classnames(classes.fieldWrapper, className)}>
      <label className={classnames(classes.fieldLabel, labelClassName)} htmlFor={labelFor}>
        {t(label)}
      </label>
      <div className={classnames(classes.fieldContent, contentClassName)}>{translatedChildren}</div>
    </div>
  )
}

FieldLabelWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  labelFor: PropTypes.string,
  className: PropTypes.string,
  contentClassName: PropTypes.string
}

export default FieldLabelWrapper
