import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getIn } from 'formik'

import ActionText from '../../../../../components/ActionText'
import ExternalRecipientSection from './ExternalRecipientSection'

import { EMAIL, EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS, initialExternalRecipientValue } from '../../fields'

const ExternalRecipientsContent = ({ formik }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(0)
  const { values, setFieldValue } = formik

  const carouselItems = getIn(values, EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS)

  const addNewItemButtonText = values[EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS].length
    ? '+ Add another external recipient'
    : '+ Add an external recipient'

  const handleAddAnotherItem = useCallback(() => {
    const currentExternalRecipients = values[EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS]

    // open new item in carousel
    setSelected(currentExternalRecipients.length)
    setFieldValue(EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS, [
      ...currentExternalRecipients,
      initialExternalRecipientValue
    ])
  }, [setFieldValue, values])

  const handleDeleteItem = useCallback(
    index => {
      // delete item from carousel items array
      const newCarouselItems = [...carouselItems]
      newCarouselItems.splice(index, 1)

      // open previous item in carousel
      setSelected(newCarouselItems.length - 1)
      setFieldValue(EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS, newCarouselItems)
    },
    [carouselItems, setFieldValue]
  )

  return (
    <>
      {carouselItems.map((item, index) => {
        return (
          <ExternalRecipientSection
            key={item[EMAIL] || index}
            formik={formik}
            itemPath={`${EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS}[${index}]`}
            index={index}
            selected={selected === index}
            allowDelete={carouselItems.length > 1}
            onSelect={() => setSelected(index)}
            onClose={() => setSelected(0)}
            onDelete={() => handleDeleteItem(index)}
          />
        )
      })}
      <ActionText onClick={handleAddAnotherItem} isDark>
        {t(addNewItemButtonText)}
      </ActionText>
    </>
  )
}

export default ExternalRecipientsContent
