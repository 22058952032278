import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'

import { redirectTo, replaceRouteParams } from '../../helpers/url'

import { selectedAdAccountIdSelector, selectedPlatformSelector } from '../../modules/selectors/app'

import { ROUTE_PARAMS, ROUTES } from '../../constants/routes'

export default function useSelectedAdAccountValidation(path) {
  const dispatch = useDispatch()

  const selectedPlatform = useSelector(selectedPlatformSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const { [ROUTE_PARAMS.adAccount]: pathAdAccountId } = useParams()

  useEffect(() => {
    // validate adAccount and redirect to missing Access page in case of invalid type
    if (pathAdAccountId && selectedPlatform) {
      const isValidAdAccount = pathAdAccountId === selectedAdAccountId

      if (pathAdAccountId && !isValidAdAccount) {
        // when adAccount which set in the path is not valid i.e.:
        // is not in the selectedSelectedSelf adAccounts, then redirect to the missing access page
        redirectTo(
          replaceRouteParams(ROUTES.adAccountAccessMissing, {
            [ROUTE_PARAMS.platform]: selectedPlatform,
            [ROUTE_PARAMS.adAccount]: pathAdAccountId
          })
        )
      }
    }
  }, [dispatch, pathAdAccountId, selectedAdAccountId, selectedPlatform, path])
}
