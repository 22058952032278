import { useSelector } from 'react-redux'
import {
  inventoryGroupDataSelector,
  inventoryGroupProductsSelector
} from '../../../../../../../../modules/selectors/mediaOrdersProducts'

export default function useProductGroupSubProducts({ selectedPeriods, isProductGroup }) {
  const inventoryGroupData = useSelector(inventoryGroupDataSelector)
  const inventoryGroupProducts = useSelector(inventoryGroupProductsSelector)

  if (isProductGroup) {
    // find the products that are available for all selected periods - availableProducts:
    return selectedPeriods.reduce((available, selectedPeriod) => {
      const inventoryData = inventoryGroupData.find(inventory => inventory.date_start === selectedPeriod.value)

      // Convert available_for_products to a Set of IDs for O(1) lookup
      const availableProductIds = new Set(
        inventoryData?.available_for_products.map(availableProduct => availableProduct.id)
      )

      // keep only the products that are available for current selectedPeriod:
      return available.filter(product => availableProductIds.has(product.id))
    }, inventoryGroupProducts)
  } else {
    return null
  }
}
