import { replaceRouteParams } from '../../helpers/url'
import { getFormData, madeRequest } from '../../helpers/api'

import { ENDPOINTS } from '../../constants/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getContractService(id, params) {
  return madeRequest('GET', {
    params,
    url: `${ENDPOINTS.contracts}${id}/`
  })
}

export function createContractService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.contracts
  })
}

export function updateContractService({ id, data, requestMethod }) {
  return madeRequest(requestMethod, {
    data,
    url: `${ENDPOINTS.contracts}${id}/`
  })
}

export function updateContractsStatusBulkService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.contractsStatusBulk
  })
}

export function signContractService(id, data, params) {
  return madeRequest('POST', {
    params,
    data: getFormData(data), // send signature File as formData
    url: replaceRouteParams(ENDPOINTS.signContract, {
      id
    })
  })
}

export function deleteContractSignatureService(id, params) {
  return madeRequest('POST', {
    params,
    url: replaceRouteParams(ENDPOINTS.contractClearSign, {
      id
    })
  })
}

export function getContractsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.contracts
  })
}
