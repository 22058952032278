import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import ContentRow from '../../../features/components/ContentSection/ContentRow'
import ContentSection from '../../../features/components/ContentSection'
import MarginsTable from './MarginsTable'
import MarginCreate from './MarginCreate'

import { clearMargins, getMargins } from '../../../modules/actions/margins'
import { selectedSelfAccountDataSelector, selectedSelfAccountSelector } from '../../../modules/selectors/app'
import { marginsSelector } from '../../../modules/selectors/margins'

import useSettingsClasses from '../styles'

const ManageMargins = () => {
  const settingsClasses = useSettingsClasses()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const margins = useSelector(marginsSelector)
  const selectedSelfAccountId = useSelector(selectedSelfAccountSelector)
  const selfAccountData = useSelector(selectedSelfAccountDataSelector)

  useEffect(() => {
    if (selectedSelfAccountId) {
      dispatch(getMargins({ account: selectedSelfAccountId }))
    }

    return () => {
      dispatch(clearMargins())
    }
  }, [dispatch, selectedSelfAccountId])

  return (
    <>
      <h1 className={settingsClasses.title}>{t('accountMargins', { selfAccountName: selfAccountData?.name })}</h1>
      <ContentSection title={t('Margin settings')}>
        <ContentRow
          title={t('Off-network campaign margin')}
          description={t(
            'If you update the margin, it will only apply to campaigns created from today onwards and the old margin will continue to apply to previously created campaigns (because it might have already been reported). To make a margin update retrospectively please contact your account manager.'
          )}
          leftColumnChildren={<MarginCreate />}
        >
          <MarginsTable margins={margins} />
        </ContentRow>
      </ContentSection>
    </>
  )
}

export default ManageMargins
