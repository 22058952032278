import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AnimateHeight from 'react-animate-height'
import PropTypes from 'prop-types'

import { ReactComponent as AngleIcon } from '../../../assets/icons/chevron-arrow-down.svg'
import { MobileDown, MobileUp } from '../../../components/hoc/ResponsiveRendering'
import InternalLink from '../../../components/InternalLink'

import useStyles from './styles'

function SuggestionCard({ suggestion, linkCard, children }) {
  const { title, icon, info } = suggestion

  const { t } = useTranslation()

  const [height, setHeight] = useState(0)

  const onMobileToggle = useCallback(() => {
    setHeight(height === 0 ? 'auto' : 0)
  }, [height])

  const classes = useStyles({ isOpened: !!height, linkCard })

  const cardTemplate = (
    <div className={classes.suggestionCard}>
      <div className={classes.suggestionContent}>
        <div className={classes.suggestionIcon}>{icon}</div>
        <div className={classes.suggestionInfoWrapper}>
          <div>
            <div className={classes.suggestionTitle}>{t(title)}</div>
            <div className={classes.suggestionText}>{t(info)}</div>
          </div>
          <div className={classes.suggestionArrowIconWrapper}>
            <AngleIcon className={classes.suggestionArrowIcon} onClick={!linkCard ? onMobileToggle : null} />
          </div>
        </div>
      </div>
      {!linkCard && (
        <>
          <MobileUp>{children}</MobileUp>
          <MobileDown>
            <AnimateHeight duration={300} height={height}>
              {children}
            </AnimateHeight>
          </MobileDown>
        </>
      )}
    </div>
  )

  return linkCard ? (
    <InternalLink className={classes.suggestionLink} to={suggestion.link} navLink>
      {cardTemplate}
    </InternalLink>
  ) : (
    cardTemplate
  )
}

SuggestionCard.propTypes = {
  suggestion: PropTypes.object.isRequired,
  linkCard: PropTypes.bool
}

export default SuggestionCard
