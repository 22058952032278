import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clearChoicesPixels, getChoicesPixels } from '../../../../../../../modules/actions/choices'
import { clearAudiences, getAudiences } from '../../../../../../../modules/actions/audiences'
import {
  choicesPixelsErrorSelector,
  choicesPixelsWasLoadedSelector
} from '../../../../../../../modules/selectors/choices'
import { audiencesErrorSelector, audiencesWasLoadedSelector } from '../../../../../../../modules/selectors/audiences'

import { FACEBOOK_PLATFORM } from '../../../../../../../constants/selectLists/platformList'

const useAudiencesAndPixelsLoading = adAccountId => {
  const dispatch = useDispatch()

  const pixelsWasLoaded = useSelector(choicesPixelsWasLoadedSelector)
  const pixelsError = useSelector(choicesPixelsErrorSelector)
  const audiencesWasLoaded = useSelector(audiencesWasLoadedSelector)
  const audiencesError = useSelector(audiencesErrorSelector)

  const pixelsDidLoading = pixelsWasLoaded || pixelsError
  const audiencesDidLoading = audiencesWasLoaded || audiencesError

  const dataIsLoading = !pixelsDidLoading || !audiencesDidLoading

  useEffect(() => {
    dispatch(getChoicesPixels({ account: adAccountId }))
    dispatch(getAudiences({ account: adAccountId, limit: 25 }, FACEBOOK_PLATFORM))
  }, [dispatch, adAccountId])

  useEffect(
    () => () => {
      dispatch(clearChoicesPixels())
      dispatch(clearAudiences())
    },
    [dispatch]
  )

  return dataIsLoading
}

export default useAudiencesAndPixelsLoading
