import i18n from 'i18next'

import {
  ADUHU_LOCALIZATION,
  SHOPOMETRY_LOCALIZATION,
  getSelectedLanguageData,
  DEFAULT_LANGUAGE,
  DOMAINS,
  CENOMI_LOCALIZATION,
  ONEVIU_LOCALIZATION,
  NEXUS_LOCALIZATION
} from '../constants/selectLists/languagesList'
import { setLocalStorage } from './storage'
import { SELECTED_LANGUAGES } from '../pages/Settings/ManageController/ManageControllerForm/fields'

export const getLocalizationValue = languageCode => {
  // app language management is done by lanauge code, and where the localization is needed this function should be
  // called to determine which localization file do we use based on domain app(aduhu, cenomi and etc..)
  const currentAppUrl = window.location.href

  if (currentAppUrl.includes(DOMAINS.aduhu)) {
    return ADUHU_LOCALIZATION[languageCode]
  } else if (currentAppUrl.includes(DOMAINS.cenomispace)) {
    return CENOMI_LOCALIZATION[languageCode]
  } else if (currentAppUrl.includes(DOMAINS.oneviu)) {
    return ONEVIU_LOCALIZATION[languageCode]
  } else if (currentAppUrl.includes(DOMAINS.nexus)) {
    return NEXUS_LOCALIZATION[languageCode]
  } else {
    return SHOPOMETRY_LOCALIZATION[languageCode]
  }
}

export const LANGUAGE = 'language'
export const SWITCHED_LANGUAGE = 'switched_language'

export const handleChangeLanguage = code => {
  setLocalStorage(LANGUAGE, code)
  i18n.changeLanguage(getLocalizationValue(code))
  setLanguageAttributes(code)
}

export const setLanguageAttributes = code => {
  const selectedLanguageData = getSelectedLanguageData(code)

  // set html element attributes and styles according to selected language
  const htmlEl = document.querySelector('html')
  htmlEl.setAttribute('lang', code)
  document.querySelector('html').style.direction = selectedLanguageData.textDirection
}

// Function to check if text is Arabic
export const isArabic = (text = '') => {
  const arabicPattern = /[\u0600-\u06FF\u0750-\u077F]/
  return arabicPattern.test(text)
}

export const getControllerDefaultLanguage = (newController, currentSelectedLanguage) => {
  // there are 3 scenarios of the language selection:
  // 1. display_languages is true and there are more than one language in display_languages
  // 2. display_languages is true but there is only one language in display_languages then dont show selector but set the user language as the first of display_languages
  // 3. display_languages is false then the default language is used

  const allowLanguageSelection = newController?.display_language_selection
  const controllerLanguages = allowLanguageSelection && newController?.[SELECTED_LANGUAGES]

  if (allowLanguageSelection && controllerLanguages?.length === 1) {
    // 2. if display_languages is true but there is only one language in display_languages then dont show selector but
    // set the user language as the first of display_languages
    return controllerLanguages[0]
  } else if (allowLanguageSelection && controllerLanguages?.length > 1) {
    // 1. display_languages is true and there are more than one language in display_languages
    // check if current selected language is available for new controller
    const isCurrentLanguageAvailable = controllerLanguages.includes(currentSelectedLanguage)
    return isCurrentLanguageAvailable ? currentSelectedLanguage : controllerLanguages[0]
  } else {
    // if controller is not found, set default language or:
    // 3. display_languages is false - preselect default language if some other was selected.
    return DEFAULT_LANGUAGE
  }
}
