import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-grey.svg'

import useStyles from './styles'

function ButtonClose({ className, onClick, disabled = false }) {
  const classes = useStyles()

  return (
    <button
      type="button"
      disabled={disabled}
      className={classnames(classes.closeBtn, className, { [classes.disabled]: disabled })}
      onClick={onClick}
    >
      <CloseIcon />
    </button>
  )
}

ButtonClose.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}

export default ButtonClose
