import React from 'react'

import SkeletonUtilisationGraph from './MediaProductsRow/UtilisationGraph/Skeleton'

import useStyles from './styles'

const SkeletonReportingContent = () => {
  const classes = useStyles()

  return (
    <div className={classes.graphsGrid}>
      <SkeletonUtilisationGraph />
      <SkeletonUtilisationGraph />
      <SkeletonUtilisationGraph />
      <SkeletonUtilisationGraph />
      <SkeletonUtilisationGraph />
      <SkeletonUtilisationGraph />
    </div>
  )
}

export default SkeletonReportingContent
