import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import ProductFields from '../ProductFields'

import useLoadCatalogueProducts from './hooks/useLoadCatalogueProducts'
import useLoadCategoryPages from './hooks/useLoadCategoryPages'

import {
  catalogueProductsErrorSelector,
  catalogueProductsIsLoadingSelector,
  catalogueProductsNextSelector,
  catalogueProductsSelector,
  catalogueProductsWasLoadedSelector,
  categoryPagesErrorSelector,
  categoryPagesIsLoadingSelector,
  categoryPagesNextSelector,
  categoryPagesSelector,
  categoryPagesWasLoadedSelector
} from '../../../modules/selectors/mediaOrders'

import { BRAND_CATEGORY_PAGE_ID } from './fields'

import useStyles from './styles'

const ProductsAndPagesFields = ({ formik, onBrandCategoryPageSelectHandler, onProductPageSelectHandler }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const categoryPagesNext = useSelector(categoryPagesNextSelector)
  const catalogueProductsNext = useSelector(catalogueProductsNextSelector)
  const categoryPages = useSelector(categoryPagesSelector)
  const categoryPagesWasLoaded = useSelector(categoryPagesWasLoadedSelector)
  const catalogueProducts = useSelector(catalogueProductsSelector)
  const catalogueProductsWasLoaded = useSelector(catalogueProductsWasLoadedSelector)
  const catalogueProductsIsLoading = useSelector(catalogueProductsIsLoadingSelector)

  // it needs for saving search value for checking needs to show products section or not
  const [searchValue, setSearchValue] = useState('')

  const onSearchChange = search => {
    if (search !== searchValue) {
      setSearchValue(search)
    }
  }

  // Hide products section if initial request (with empty search) was loaded and there are no products
  const hideProductsSection = useMemo(() => {
    return !searchValue && !catalogueProducts.length && catalogueProductsWasLoaded && !catalogueProductsIsLoading
  }, [searchValue, catalogueProducts.length, catalogueProductsWasLoaded, catalogueProductsIsLoading])

  const { loadMoreCategoryPagesHandler } = useLoadCategoryPages()
  const { loadInitialProductsHandler, loadMoreProductsHandler } = useLoadCatalogueProducts(10, onSearchChange)

  return (
    <>
      {!!categoryPages?.length && categoryPagesWasLoaded && (
        <div className={classes.brandPagesSection}>
          <h3 className={classes.sectionTitle}>{t('Brand pages')}</h3>
          <ProductFields
            formik={formik}
            searchInputPlaceholder="Search page name"
            productFieldName={BRAND_CATEGORY_PAGE_ID}
            loadMoreProductsHandler={categoryPagesNext ? loadMoreCategoryPagesHandler : null}
            onProductSelect={onBrandCategoryPageSelectHandler}
            dataSelector={categoryPagesSelector}
            errorSelector={categoryPagesErrorSelector}
            wasLoadedSelector={categoryPagesWasLoadedSelector}
            isLoadingSelector={categoryPagesIsLoadingSelector}
            showSearchField={false}
            showInventoryChip={false}
            showLinkText
          />
        </div>
      )}
      {!hideProductsSection && (
        <div className={classnames(classes.productsSection)}>
          <h3 className={classes.sectionTitle}>{t('Products')}</h3>
          <ProductFields
            formik={formik}
            searchInputPlaceholder="Search"
            loadInitialProductsHandler={loadInitialProductsHandler}
            loadMoreProductsHandler={catalogueProductsNext ? loadMoreProductsHandler : null}
            onProductSelect={onProductPageSelectHandler}
            dataSelector={catalogueProductsSelector}
            errorSelector={catalogueProductsErrorSelector}
            wasLoadedSelector={catalogueProductsWasLoadedSelector}
            isLoadingSelector={catalogueProductsIsLoadingSelector}
          />
        </div>
      )}
    </>
  )
}

ProductsAndPagesFields.propTypes = {
  onBrandCategoryPageSelectHandler: PropTypes.func,
  onProductPageSelectHandler: PropTypes.func
}

export default ProductsAndPagesFields
