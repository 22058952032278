import React, { useCallback } from 'react'
import { getIn } from 'formik'
import PropTypes from 'prop-types'

import Tabs from '../../../../features/components/Tabs'
import FacebookImageFilesLibrary from './FacebookImageFilesLibrary'
import FacebookVideoFilesLibrary from './FacebookVideoFilesLibrary'

import { concat } from '../../../../helpers/common'

import { UPLOADED_MEDIA_TYPE } from '../../AdFileUpload/fields'
import {
  FILE_HASH,
  FILE_ID,
  FILE_NAME,
  FILE_URL,
  initialFileValues,
  initialMediaValues,
  MEDIA_FILE_OPTION,
  MEDIA_FILE_IMAGE_USE_LIBRARY,
  MEDIA_FILE_VIDEO_USE_LIBRARY,
  MEDIA_TYPE,
  VIDEO_PICTURE
} from '../../../Facebook/AdForms/fields'

import useStyles from './styles'

const packageContentOptions = [
  { label: 'Images', value: MEDIA_FILE_IMAGE_USE_LIBRARY },
  { label: 'Videos', value: MEDIA_FILE_VIDEO_USE_LIBRARY }
]
const FacebookExistedMedia = ({ formik, itemPath, setMediaFiles, adAccountId, showTabsSelector, initialLoadSize }) => {
  const classes = useStyles()
  const { values, setFieldValue } = formik

  const mediaOptionValueName = concat(itemPath, '.', MEDIA_FILE_OPTION)

  const selectedMediaOption = getIn(values, mediaOptionValueName)

  const handleMediaRemove = useCallback(() => {
    const mediaItem = getIn(values, itemPath)
    const initialItemMediaValues = {
      ...mediaItem,
      ...initialFileValues,
      ...initialMediaValues
    }
    setFieldValue(itemPath, initialItemMediaValues)
  }, [itemPath, setFieldValue, values])

  const handleExistingMediaSelected = useCallback(
    (selectedMedia, mediaType) => {
      const { name, id, url, hash, picture } = selectedMedia
      // remove file when select file from another type
      handleMediaRemove()
      const newFileValues = {
        ...initialFileValues,
        ...initialMediaValues,
        [FILE_NAME]: name || id,
        [FILE_URL]: url,
        [FILE_HASH]: hash,
        [FILE_ID]: id,
        [UPLOADED_MEDIA_TYPE]: selectedMediaOption,
        [MEDIA_TYPE]: mediaType,
        // if type of media is video, we have additional property VIDEO_PICTURE
        ...(picture && { [VIDEO_PICTURE]: picture })
      }

      setMediaFiles(newFileValues)
    },
    [handleMediaRemove, setMediaFiles, selectedMediaOption]
  )

  return (
    <>
      {showTabsSelector && (
        <Tabs
          options={packageContentOptions}
          selectedValue={selectedMediaOption}
          onSelectValue={mediaOption => setFieldValue(mediaOptionValueName, mediaOption)}
          className={classes.existedMediaTabs}
        />
      )}
      {selectedMediaOption === MEDIA_FILE_IMAGE_USE_LIBRARY && (
        <FacebookImageFilesLibrary
          values={values}
          onMediaSelect={handleExistingMediaSelected}
          onFileRemove={handleMediaRemove}
          mediaType={MEDIA_FILE_IMAGE_USE_LIBRARY}
          fileNameKey={concat(itemPath, '.', FILE_NAME)}
          fileURLKey={concat(itemPath, '.', FILE_URL)}
          uploadedMediaTypeKey={concat(itemPath, '.', UPLOADED_MEDIA_TYPE)}
          adAccountId={adAccountId}
          initialLoadSize={initialLoadSize}
        />
      )}
      {selectedMediaOption === MEDIA_FILE_VIDEO_USE_LIBRARY && (
        <FacebookVideoFilesLibrary
          values={values}
          onMediaSelect={handleExistingMediaSelected}
          onFileRemove={handleMediaRemove}
          mediaType={MEDIA_FILE_VIDEO_USE_LIBRARY}
          fileNameKey={concat(itemPath, '.', FILE_NAME)}
          fileURLKey={concat(itemPath, '.', FILE_URL)}
          itemPath={itemPath}
          uploadedMediaTypeKey={concat(itemPath, '.', UPLOADED_MEDIA_TYPE)}
          adAccountId={adAccountId}
          initialLoadSize={initialLoadSize}
        />
      )}
    </>
  )
}

FacebookExistedMedia.propTypes = {
  formik: PropTypes.object,
  itemPath: PropTypes.string,
  setMediaFiles: PropTypes.func,
  showTabsSelector: PropTypes.bool,
  initialLoadSize: PropTypes.number,
  adAccountId: PropTypes.string
}

export default FacebookExistedMedia
