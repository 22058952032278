import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles({
  field: {
    position: 'relative'
  },
  inputField: {
    display: 'inline-flex',
    position: 'relative',
    transition: 'all 0.3s',
    width: '100%'
  },
  selectField: {
    width: '100%'
  }
})

export default useStyles
