import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import RadioBlock from '../../../../../../../components/Form/RadioBlock'
import TikTokVideoUpload from './TikTokVideoUpload'
import TikTokVideoFilesLibrary from './TikTokVideoFilesLibrary'
import TikTokVideoThumbnailLibrary from './TikTokVideoThumbnailLibrary'
import ErrorMessage from '../../../../../../../components/Form/ErrorMessage'

import { clearTikTokVideoThumbnails } from '../../../../../../../modules/actions/tikTokConstants'

import { VIDEO_FROM_LIBRARY_OPTION, VIDEO_OPTION, VIDEO_THUMBNAIL_URL, VIDEO_UPLOAD_OPTION } from '../../fields'
import {
  FILE_ID,
  MEDIA_FILE_UPLOAD,
  MEDIA_FILE_VIDEO_USE_LIBRARY,
  MEDIA_TYPE
} from '../../../../../../ReusableFormFields/AdFileUpload/fields'

const stepFields = [FILE_ID, VIDEO_THUMBNAIL_URL]

function VideoStep({ formik, handleStepChange, adAccountId }) {
  const dispatch = useDispatch()

  const [previousVideoUrl, setPreviousVideoUrl] = useState('')

  const { setFieldValue, values, errors, touched } = formik

  const showSelectVideoError = errors[FILE_ID] && touched[FILE_ID]

  const thumbnailLibraryProps = useMemo(
    () => ({
      formik,
      previousVideoUrl,
      setPreviousVideoUrl
    }),
    [formik, previousVideoUrl]
  )

  const onVideoRemoveHandler = useCallback(() => {
    dispatch(clearTikTokVideoThumbnails())

    setPreviousVideoUrl('')
  }, [dispatch])

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  useEffect(
    () => () => {
      dispatch(clearTikTokVideoThumbnails())
    },
    [dispatch]
  )

  return (
    <Step formTitle="Create an ad" stepTitle="Select a video" handleContinue={handleContinue}>
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_tiktok_video_upload"
        name={VIDEO_OPTION}
        value={VIDEO_UPLOAD_OPTION}
        selectedValue={values[VIDEO_OPTION]}
        label="Upload a file from my device"
      >
        <TikTokVideoUpload
          formik={formik}
          adAccountId={adAccountId}
          onVideoRemoveHandler={onVideoRemoveHandler}
          recommendedSize="1080x1920 px"
        />
        <TikTokVideoThumbnailLibrary
          allowShowingThumbnailsLibrary={values[MEDIA_TYPE] === MEDIA_FILE_UPLOAD}
          {...thumbnailLibraryProps}
        />
      </RadioBlock>
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_tiktok_video_from_library"
        name={VIDEO_OPTION}
        value={VIDEO_FROM_LIBRARY_OPTION}
        selectedValue={values[VIDEO_OPTION]}
        label="Use an existing video from the library"
      >
        <TikTokVideoFilesLibrary
          formik={formik}
          adAccountId={adAccountId}
          onVideoRemoveHandler={onVideoRemoveHandler}
        />
        {showSelectVideoError && <ErrorMessage error={errors[FILE_ID]} />}
        <TikTokVideoThumbnailLibrary
          allowShowingThumbnailsLibrary={values[MEDIA_TYPE] === MEDIA_FILE_VIDEO_USE_LIBRARY}
          {...thumbnailLibraryProps}
        />
      </RadioBlock>
    </Step>
  )
}

export default VideoStep
