import React, { useCallback, useMemo, useState } from 'react'
import { getIn } from 'formik'
import { Portal } from 'react-portal'

import ProductSelection from './ProductSelection'
import ProductSetupSection from './ProductSetupSection'
import ProductsSummaryList from '../../ProductsSummaryList'
import AccountSelectionSection from './AccountSelectionSection'
import Step from '../../../../../../../features/components/Forms/StepForm/Step'

import useGetSelectedProduct from './useGetSelectedProduct'
import { useGetSelectedCurrency } from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/hooks/useGetSelectedCurrency'

import { MEDIA_PRODUCTS, MODIFIED_PRODUCTS } from '../../fields'

const stepFields = [MEDIA_PRODUCTS]

function ProductsSelectionStep({ formik, handleStepChange, isActive }) {
  const { values, setFieldValue } = formik
  const selectedProducts = getIn(values, MEDIA_PRODUCTS)

  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectedCurrency = useGetSelectedCurrency()
  const { isProductLoading, loadingProductId, handleAddAnotherItem } = useGetSelectedProduct({
    selectedProducts,
    setSelectedIndex,
    setFieldValue,
    values
  })

  const selectedProductsIds = useMemo(() => {
    return selectedProducts.map(product => product?.data?.id)
  }, [selectedProducts])

  const handleDeleteItem = useCallback(
    index => {
      // delete item from carousel items array
      const newSelectedProducts = [...selectedProducts]
      newSelectedProducts.splice(index, 1)

      // open previous item in carousel
      setSelectedIndex(newSelectedProducts.length - 1)
      setFieldValue(MEDIA_PRODUCTS, newSelectedProducts)
    },
    [selectedProducts, setFieldValue]
  )

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields, values)
    // copy selected products to modified
    setFieldValue(MODIFIED_PRODUCTS, values[MEDIA_PRODUCTS])
  }, [handleStepChange, setFieldValue, values])

  const hasProducts = selectedProducts.length > 0

  const productsSections = useMemo(() => {
    if (!isProductLoading) {
      return selectedProducts
    } else {
      // add placeholder product to carousel items array while product data is loading
      return [
        ...selectedProducts,
        {
          internalId: loadingProductId // the same id as the loading product
        }
      ]
    }
  }, [isProductLoading, loadingProductId, selectedProducts])

  return (
    <Step
      stepTitle="Select media products and booking periods"
      stepDescription="Search for media products by name or by id."
      handleContinue={hasProducts ? handleContinue : null}
    >
      <AccountSelectionSection setFieldValue={setFieldValue} />
      {productsSections.map((item, index) => {
        return (
          <ProductSetupSection
            formik={formik}
            key={item.internalId}
            itemPath={`${MEDIA_PRODUCTS}[${index}]`}
            selected={selectedIndex === index}
            onSelect={() => setSelectedIndex(index)}
            onClose={() => setSelectedIndex(null)}
            onDelete={() => handleDeleteItem(index)}
            currency={selectedCurrency}
          />
        )
      })}
      <ProductSelection
        currency={selectedCurrency}
        setFieldValue={setFieldValue}
        onAddProduct={handleAddAnotherItem}
        selectedProductsIds={selectedProductsIds}
      />
      {hasProducts && isActive && (
        <Portal node={document && document.getElementById('formSideColumnPortal')}>
          <ProductsSummaryList
            formik={formik}
            showFees={false}
            showPreSubTotalFees={false}
            showTotal={false}
            selectedCurrency={selectedCurrency}
            isSmall={true}
          />
        </Portal>
      )}
    </Step>
  )
}

export default ProductsSelectionStep
