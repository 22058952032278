import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useStyles from './styles'

const ImagePlaceholder = ({ width, height, backgroundColor, arrowColor, containerWidth }) => {
  const classes = useStyles({ backgroundColor, arrowColor })
  const ratio = height / width

  const imageContainerRef = useRef()

  const widthSize = (imageContainerRef.current && imageContainerRef.current.offsetWidth) || containerWidth
  const heightSize = Math.round(widthSize * ratio)

  return (
    <div className={classes.imagePlaceholder} ref={imageContainerRef} style={{ height: heightSize }}>
      <div className={classes.horizontalAxis}>
        <div className={classnames(classes.arrow, classes.left)} />
        <span className={classes.size}>{width}px</span>
        <div className={classnames(classes.arrow, classes.right)} />
      </div>
      {/* set heightSize to vertical width, as it is rotated by 90degrees so width become height */}
      <div className={classes.verticalAxis} style={{ width: heightSize }}>
        <div>
          <div className={classnames(classes.arrow, classes.left)} />
          <span className={classes.size}>{height}px</span>
          <div className={classnames(classes.arrow, classes.right)} />
        </div>
      </div>
    </div>
  )
}

ImagePlaceholder.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string,
  arrowColor: PropTypes.string
}

export default ImagePlaceholder
