import { useSelector } from 'react-redux'
import { useCallback, useState } from 'react'

import MediaOrderAmendForm, { AMENDMENT_SEND_FORM } from './MediaOrderAmendForm'
import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'

import { mediaOrderIsLoadingSelector } from '../../../../modules/selectors/mediaOrders'

import { MEDIA_ORDER_AMEND } from '../../../../constants/forms'

import useStyles from './styles'

const MediaOrderAmend = () => {
  const [activeForm, setActiveForm] = useState(null)
  const mediaOrderIsLoading = useSelector(mediaOrderIsLoadingSelector)
  const classes = useStyles()

  // MediaOrderGenerateForm should have grey background instead of white and don't have progress
  const isAmendmentSendFormActive = activeForm === AMENDMENT_SEND_FORM

  const handleShowFormProgressChange = useCallback(formName => {
    setActiveForm(formName)
  }, [])

  return (
    <FormDrawerWrapper
      formName={MEDIA_ORDER_AMEND}
      isFormLoading={mediaOrderIsLoading}
      showOpenButton={false}
      drawerContentClassName={isAmendmentSendFormActive ? classes.amendmentContractSend : null}
      isWideDrawer={true}
    >
      <MediaOrderAmendForm onFormChange={handleShowFormProgressChange} />
    </FormDrawerWrapper>
  )
}

export default MediaOrderAmend
