import { createStyles } from '../../../../styles/helpers'

import { black, darkGreen, darkYellow } from '../../../../styles/const/colors'

export default createStyles({
  root: {
    fontSize: '14px',
    lineHeight: 1.1
  },
  day: {
    display: 'block',
    marginBottom: '2px'
  },
  callToBookDate: {
    color: [darkYellow, '!important'] // reset library color
  },
  callToBook: {
    display: 'block',
    fontSize: '10px',
    color: [darkYellow, '!important'] // reset library color
  },
  price: {
    display: 'block',
    fontSize: '10px',
    color: [darkGreen, '!important'], // reset library color
    fontWeight: 'bold'
  },
  pseudoDisabled: {
    backgroundColor: 'rgb(248, 248, 248)',
    height: 'calc(100% + 10px)', // cover top and bottom spacing
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column', // adjust text with pricing
    color: '#aeb9bf !important' // replicate library color
  },
  '@global': {
    '.rdrDayPassive': {
      opacity: 0.3,

      '& .rdrDayNumber span': {
        // when day is passive, the library sets the color to grey, we set it to black because we added opacity to the parent
        color: black
      }
    },
    '.rdrDayDisabled .rdrDayNumber span': {
      // fix color reset issue which is caused by library(visible only in build version)
      color: '#aeb9bf !important' // replicate library color,
    },
    '.rdrDayDisabled, .rdrDayPassive': {
      '& $price': {
        fontWeight: 'normal'
      }
    }
  }
})
