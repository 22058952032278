import { createStyles } from '../../../../../styles/helpers'

import { zIndex1, zIndex2, zIndex3 } from '../../../../../styles/const/common'

const slide = data => {
  if (!data) return 0
  const { selectedStoriesItemIndex } = data

  if (!selectedStoriesItemIndex) {
    return 0
  } else {
    return -195 * selectedStoriesItemIndex
  }
}

export default createStyles({
  AdStoriesItem: {
    minWidth: 195,
    minHeight: 345,
    flex: 1,
    position: 'relative',
    transition: 'all .5s',

    '&:first-child': {
      marginLeft: data => slide(data)
    }
  },
  adStoriesCardMedia: {
    zIndex: zIndex1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  // MediaThumbnail
  adImageInstagram: {
    maxHeight: '100%',
    height: '100%',

    '& svg path': {
      fill: 'white'
    },
    '& img': {
      width: 'auto',
      maxWidth: ['unset', '!important'],
      height: '100%'
    }
  },
  // actionButton
  adStoriesCardButton: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: zIndex2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    background: 'linear-gradient(0deg, rgba(85, 85, 85, 0.28) 0%, rgba(196, 196, 196, 0) 100%)'
  },
  adStoriesCardIcon: {
    transform: 'rotate(180deg)',
    marginBottom: 2,

    '& path': {
      fill: 'white'
    }
  },
  storyActionButton: {
    borderRadius: 20,
    borderColor: '#fff',
    fontSize: 6,
    backgroundColor: 'white',
    paddingBottom: 1
  },
  adStoriesCardSide: {
    top: 0,
    // width is decreased from 50% to 35% to allow to put play button between 2 sides of story item
    width: '35%',
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    zIndex: zIndex3
  },
  adStoriesCardLeftSide: {
    left: 0
  },
  adStoriesCardRightSide: {
    right: 0
  }
})
