import { createStyles } from '../../../../../../../styles/helpers'

import { red } from '../../../../../../../styles/const/colors'

const useStyles = createStyles({
  disapprovalReasonTooltipContainer: {
    minWidth: 80,
    textAlign: 'left',
    padding: '6px 8px',
    cursor: 'pointer',
    fontSize: '12px',
    color: red
  }
})

export default useStyles
