import React from 'react'

import LineItemFormContent from './LineItemFormContent'
import useLoadLineItems from '../../useLoadLineItems'
import SkeletonForm from '../../../../../../../components/Form/Skeleton'

const ProximityLineItemForm = ({ adAccountId, onSuccessSubmit, isExistingCampaignFlow, onLineItemOptionChange }) => {
  const { isLineItemsLoading } = useLoadLineItems({ adAccountId, isExistingCampaignFlow })

  if (isLineItemsLoading) {
    return <SkeletonForm stepsLength={2} />
  }

  return (
    <LineItemFormContent
      adAccountId={adAccountId}
      onSuccessSubmit={onSuccessSubmit}
      isExistingCampaignFlow={isExistingCampaignFlow}
      onLineItemOptionChange={onLineItemOptionChange}
    />
  )
}

export default ProximityLineItemForm
