import React, { useState, Fragment, useCallback } from 'react'
import { Document, Page } from 'react-pdf'
import classnames from 'classnames'
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
// react-pdf library requires to set workerSrc to work properly
// this method is used as a workaround to set workerSrc, because recommended way by setting the library -
// import.meta.url doesn't work with our CRA setup, so we just import the pdf worker from the pdfjs-dist which is
// subdepenency of 'react-pdf'. This file will import and set the worker inside it, but the library will still push:
// Warning: Setting up fake worker.
import 'pdfjs-dist/build/pdf.worker.min.mjs'

import PdfPageSkeleton from './Skeleton/PdfPageSkeleton'

import useStyles from './styles'

function ContractPdfViewer({ pdfFile, pdfWidth, onLastPageClick, showPages = true, onLoadSuccess }) {
  const [numPages, setNumPages] = useState(null)
  const classes = useStyles()

  const handlePdfPageClick = useCallback(
    (clickedPageNumber, documentPagesAmount) => {
      // ContractPdfViewer library can't identify click on some part of the page
      // so we may handle it only by full page click
      // in Media order contract the signature field is located on the last page
      const isLastPageClicked = clickedPageNumber === documentPagesAmount
      // show sign contract modal only if last page was clicked - request contract sign
      isLastPageClicked && onLastPageClick && onLastPageClick()
    },
    [onLastPageClick]
  )

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    onLoadSuccess && onLoadSuccess()
  }

  if (!pdfFile) {
    return null
  }

  return (
    <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess} loading={<PdfPageSkeleton />}>
      {Array.from(new Array(numPages), (el, index) => {
        const pageNumber = index + 1
        const isLastPage = numPages === pageNumber

        return (
          <Fragment key={index}>
            {showPages && (
              <h4 className={classes.pageHeadline}>
                Page {pageNumber} of {numPages}
              </h4>
            )}
            <div
              // mark page as clickable if it's the last page and contract wasn't signed yet
              className={classnames(classes.page, { [classes.clickablePage]: isLastPage && !!onLastPageClick })}
              onClick={() => handlePdfPageClick(pageNumber, numPages)}
            >
              <Page key={`page_${pageNumber}`} pageNumber={pageNumber} width={pdfWidth} loading={<PdfPageSkeleton />} />
            </div>
          </Fragment>
        )
      })}
    </Document>
  )
}

export default React.memo(ContractPdfViewer)
