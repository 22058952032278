import React, { useCallback, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import Page from '../../index'
import AppLoader from '../../../components/Loaders/AppLoader'
import InfoBlock from '../../../features/components/InfoBlock'

import usePlatformConnected from '../../../features/hooks/usePlatformConnected'

import { createJsonFromQueryString, redirectTo } from '../../../helpers/url'

import { socialAuth } from '../../../modules/actions/auth'
import { selectedPlatformSelector } from '../../../modules/selectors/app'
import { isSocialAuthLoadingSelector, socialAuthErrorSelector } from '../../../modules/selectors/auth'

import { showToasts } from '../../../helpers/toasts'
import { getLocalStorage, setLocalStorage } from '../../../helpers/storage'

import { TOAST_TYPE } from '../../../constants/other'

import useCommonStyles from '../style'

function SocialAuthorizeCallback() {
  const commonClasses = useCommonStyles()
  const dispatch = useDispatch()

  const socialAuthError = useSelector(socialAuthErrorSelector)
  const isSocialAuthLoading = useSelector(isSocialAuthLoadingSelector)
  const selectedPlatform = useSelector(selectedPlatformSelector)

  const isPlatformConnected = usePlatformConnected(selectedPlatform, false)

  const { search } = useLocation()
  const { state, code, scope, auth_code } = createJsonFromQueryString(search)

  // auth_code is related to TikTok auth only
  const authParams = useMemo(() => {
    return { state, code, scope, ...(auth_code && { auth_code }) }
  }, [state, code, scope, auth_code])

  const handleRedirectToMainApp = useCallback(() => {
    // redirect for landing to auth URL after login
    const authTriggeringUrl = getLocalStorage('authTriggeringUrl')

    redirectTo(authTriggeringUrl || '/')

    setLocalStorage('authTriggeringUrl', '')
  }, [])

  useEffect(() => {
    // Make ajax GET request to /api/allauth/google/login/callback/
    dispatch(socialAuth({ authParams, selectedPlatform }))
  }, [authParams, selectedPlatform, dispatch])

  useEffect(() => {
    // Once ajax GET request is done
    if (!isSocialAuthLoading && isPlatformConnected) {
      showToasts({
        type: TOAST_TYPE.success,
        message: 'Platform was connected successfully.'
      })

      // redirect back to the page where user started authorise
      handleRedirectToMainApp()
    }
  }, [handleRedirectToMainApp, isSocialAuthLoading, isPlatformConnected])

  return (
    <Page helmetTitle="helmetTitle.SocialAuthorizeCallback" className={commonClasses.socialAuthPage}>
      {socialAuthError ? (
        <InfoBlock title={socialAuthError.message} centered greyDescription />
      ) : (
        <AppLoader isFixed={true} />
      )}
    </Page>
  )
}

export default SocialAuthorizeCallback
