import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './styles'

const MediaField = ({ mediaText, moreProductsText }) => {
  const classes = useStyles(moreProductsText)
  return (
    <div className={classes.mediaFieldWrapper}>
      <p className={classes.mediaField}>{mediaText}</p>
      {moreProductsText && <p className={classes.moreProductsText}>{moreProductsText}</p>}
    </div>
  )
}

MediaField.propTypes = {
  mediaText: PropTypes.string.isRequired,
  moreProductsText: PropTypes.string
}

export default MediaField
