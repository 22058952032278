import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../../../../components/Button'
import Geolocations from '../../../../../../../../../ReusableFormFields/GeolocationsFields/Geolocations'

import {
  LOCATION_LIST,
  SHOW_COUNTRIES,
  SHOW_REGIONS
} from '../../../../../../../../../ReusableFormFields/GeolocationsFields/fields'
import { FACEBOOK_PLATFORM } from '../../../../../../../../../../constants/selectLists/platformList'

import useStyles from '../../../../../../../../../../styles/common/stepForms'

const stepFields = [SHOW_COUNTRIES, SHOW_REGIONS, LOCATION_LIST]

const GeolocationsStep = ({ formik, handleStepChange }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  return (
    <>
      <div className={classes.stepTitle}>{t('Where would you like the ads to be shown?')}</div>
      <div className={classes.stepBody}>
        <Geolocations formik={formik} platform={FACEBOOK_PLATFORM} />
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" className="dark" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default GeolocationsStep
