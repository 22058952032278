import React, { useCallback, useMemo, useState } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { APPROVED, BUYER_DISAPPROVED, DISAPPROVED, getApprovalFileStatus } from '../helpers'
import { findFileRequirementName, getUploadByName } from './helpers'
import useGetAccessibleUrl from '../../../hooks/useGetAccessibleUrl'

import { usersSelector } from '../../../../modules/selectors/users'
import { getBookedMediaSelector } from '../../../../modules/selectors/mediaOrdersBookings'

import Button from '../../../../components/Button'
import ModalForDisapprovalReason from '../../../../pages/MediaOrderSummary/MediaOrderSummaryContent/RequestedFilesSection/RequestedFilesTable/RequestedFilesTableData/ModalForDisapprovalReason'

import useStyles from '../styles'

const DropdownContent = ({ file, onApproveHandler, onDisapproveHandler }) => {
  const { t } = useTranslation()

  const classes = useStyles()

  const bookedMedia = useSelector(getBookedMediaSelector)
  const { file_requirements: fileRequirements = [], uploaded_files: uploadedFiles } = bookedMedia

  const fileDetails = useMemo(
    () => uploadedFiles.find(uploadedFile => uploadedFile.id === file.id) || {},
    [uploadedFiles, file]
  )
  const {
    name: fileName,
    approval_status: status,
    uploaded_by: uploadedBy,
    media_product_file: mediaProductFileId,
    disapproved_reason: disapprovedReason,
    buyer_disapproved_reason: buyerDisapprovedReason
  } = fileDetails

  const users = useSelector(usersSelector)

  const uploadedByUser = users.find(user => user.id === uploadedBy)

  const [showDisapprovalReasonModal, setShowDisapprovalReasonModal] = useState(false)
  const [selectedRequirementId, setSelectedRequirementId] = useState('')
  const [selectedUploadedFile, setSelectedUploadedFile] = useState({})

  const { handleFileDownloading } = useGetAccessibleUrl()

  const handleFileDownload = useCallback(() => {
    handleFileDownloading(fileDetails.file_url)
  }, [handleFileDownloading, fileDetails.file_url])

  const handleShowModal = useCallback(() => {
    setShowDisapprovalReasonModal(true)
    setSelectedRequirementId(mediaProductFileId)
    setSelectedUploadedFile(fileDetails)
  }, [mediaProductFileId, fileDetails])

  const handleCloseModal = useCallback(() => {
    setShowDisapprovalReasonModal(false)
    setSelectedRequirementId('')
    setSelectedUploadedFile({})
  }, [setShowDisapprovalReasonModal, setSelectedRequirementId])

  const approveFileHandler = useCallback(() => {
    onApproveHandler({
      requirementFileId: mediaProductFileId,
      uploadedFile: fileDetails
    })
  }, [onApproveHandler, mediaProductFileId, fileDetails])

  const { text: fileApprovalStatus } = getApprovalFileStatus(status)

  return (
    <>
      <p className={classes.tooltipTitle}>{fileName}</p>
      <p className={classes.tooltipSubTitle}>{findFileRequirementName(mediaProductFileId, fileRequirements)}</p>
      {(uploadedByUser?.first_name || uploadedByUser?.last_name) && (
        <p className={classes.tooltipSubTitle}>
          {t('Upload by')}: {getUploadByName(uploadedByUser)}
        </p>
      )}
      <div
        className={classnames(classes.status, {
          [classes.statusApproved]: status === APPROVED,
          [classes.statusDisapproved]: status === DISAPPROVED || status === BUYER_DISAPPROVED
        })}
      >
        {t(fileApprovalStatus)}
      </div>
      {disapprovedReason && status === DISAPPROVED && <p className={classes.disapprovedReason}>{disapprovedReason}</p>}
      {buyerDisapprovedReason && status === BUYER_DISAPPROVED && (
        <p className={classes.disapprovedReason}>{buyerDisapprovedReason}</p>
      )}
      {status !== DISAPPROVED && (
        <Button solid onClick={handleShowModal} className={classes.disapprovedButton}>
          {t('Disapprove')}
        </Button>
      )}
      {status !== APPROVED && (
        <Button solid className={classes.approvedButton} onClick={approveFileHandler}>
          {t('Approve')}
        </Button>
      )}
      <Button onClick={handleFileDownload}>{t('Download')}</Button>
      <ModalForDisapprovalReason
        onSubmit={onDisapproveHandler}
        requirementFileId={selectedRequirementId}
        uploadedFile={selectedUploadedFile}
        onClose={handleCloseModal}
        isOpen={showDisapprovalReasonModal}
      />
    </>
  )
}

DropdownContent.propTypes = {
  file: PropTypes.object.isRequired,
  onApproveHandler: PropTypes.func.isRequired,
  onDisapproveHandler: PropTypes.func.isRequired
}

export default DropdownContent
