import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import FileDetailsPreview from '../../../../../../../components/FileDetailsPreview'
import MultipleOptionsPreview from '../../../MultipleOptionsPreivew'

import { getFacebookVideoPicture } from '../../../../../../../features/components/FacebookPreviews/AdCard/FacebookSingleMediaAdThumbnail/FacebookVideoPreview/helpers'

import { choicesCTAByGoalSelector } from '../../../../../../../modules/selectors/choices'
import { facebookImageUrlsSelector } from '../../../../../../../modules/selectors/mediaFiles'

import {
  AD_LINK,
  DESCRIPTIONS_LIST,
  FILE_HASH,
  FILE_NAME,
  FILE_URL,
  HEADLINES_LIST,
  MEDIA_FILE_IMAGE_PREVIEW,
  MEDIA_FILE_PREVIEW,
  MEDIA_TYPE
} from '../../../../fields'
import { CALL_TO_ACTION } from '../../../../../../ReusableFormFields/CallToActionFields/fields'
import { AdFacebookEditContext } from '../../../AdFacebookEditContextProvider'

import useStyles from './styles'

const MediaPreview = ({ adValues }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { campaignObjective } = useContext(AdFacebookEditContext)

  const actionsList = useSelector(choicesCTAByGoalSelector(campaignObjective))
  const imageUrls = useSelector(facebookImageUrlsSelector)

  const selectedAction = actionsList.find(action => action.name === adValues[CALL_TO_ACTION])
  const selectedActionLabel = selectedAction && selectedAction.label

  const headlinesList = adValues[HEADLINES_LIST]
  const descriptionsList = adValues[DESCRIPTIONS_LIST]
  const isImagePreview = adValues[MEDIA_FILE_PREVIEW] === MEDIA_FILE_IMAGE_PREVIEW

  const mediaType = adValues[MEDIA_TYPE]
  const fileUrl = adValues[FILE_URL]
  const videoPicture = getFacebookVideoPicture({ [FILE_HASH]: adValues[FILE_HASH] }, imageUrls)

  return (
    <>
      {/*file*/}
      <FileDetailsPreview
        className={classes.filePreview}
        fileName={adValues[FILE_NAME]}
        previewUrl={mediaType === 'video' ? videoPicture : fileUrl}
        filePreviewType={'image'}
        isFileLoading={false}
        allowDelete={false}
        isImagePreview={isImagePreview}
      />

      {/*headlines*/}
      <MultipleOptionsPreview optionsTitle={'Headline(s):'} optionsList={headlinesList} />
      {/*descriptions*/}
      <MultipleOptionsPreview optionsTitle={'Description(s):'} optionsList={descriptionsList} />

      {/*link*/}
      <p>
        <b>{t('Call-to-action:')} </b> {t(selectedActionLabel)}
      </p>
      <p>
        <b>{t('Click through link:')} </b> {adValues[AD_LINK]}
      </p>
    </>
  )
}

export default MediaPreview
