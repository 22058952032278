import { useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { clearUpdateMediaPackage, updateMediaPackage } from '../../../../../modules/actions/mediaPackages'

import { transformValuesToBE } from './formatters'
import { getInitialValues } from './fields'

export const useClearMediaPackageUpdate = () => {
  const dispatch = useDispatch()
  return useCallback(() => dispatch(clearUpdateMediaPackage()), [dispatch])
}

export const useOnSubmit = ({ packageId, isPackageWithMediaCategories }) => {
  const dispatch = useDispatch()

  return useCallback(
    values => {
      const transformedData = transformValuesToBE({ values, isPackageWithMediaCategories })

      dispatch(updateMediaPackage(packageId, transformedData))
    },
    [dispatch, isPackageWithMediaCategories, packageId]
  )
}

export const useGetInitialValues = ({ editPackageData, isPackageWithMediaCategories, accountsOptions, tags }) => {
  return useMemo(
    () => getInitialValues({ editPackageData, isPackageWithMediaCategories, accountsOptions, tags }),
    [editPackageData, isPackageWithMediaCategories, accountsOptions, tags]
  )
}
