import React from 'react'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import DiscountPresetCreateForm from './DiscountPresetCreateForm'

import { DISCOUNT_PRESET_CREATE } from '../../../../../constants/forms'

function DiscountPresetCreate() {
  return (
    <FormDrawerWrapper
      formName={DISCOUNT_PRESET_CREATE}
      title="Create Discount Preset"
      openButtonText="Create Discount Preset"
    >
      <DiscountPresetCreateForm />
    </FormDrawerWrapper>
  )
}

export default DiscountPresetCreate
