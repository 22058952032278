import { FILE_NAME, FILE_URL, FILE_TYPE, OTHER_TYPE, DESCRIPTION } from './fields'

export const transformValuesToBE = values => {
  return {
    [FILE_NAME]: values[FILE_NAME],
    [FILE_URL]: values[FILE_URL],
    [FILE_TYPE]: values[FILE_TYPE],
    ...(values[FILE_TYPE] === OTHER_TYPE && { [DESCRIPTION]: values[DESCRIPTION] })
  }
}
