import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import MultipleCheckboxesSelect from '../../../../components/MultipleCheckboxesSelect'

import { clearControllerMembers, getControllerMembers } from '../../../../modules/actions/controller'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import { controllerMembersSelector } from '../../../../modules/selectors/controller'

import { formatOptionsList } from '../../../formatters'

import useStyles from './styles'

const RepresentativeFilter = ({ Context }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  // Representative, is the user which is added to representatives to the selfAccount.
  // Currently, we are using the controllerMembers because there is no endpoint to get the representatives.
  // So just to admit - not all the controllerMembers are representatives.
  const controllerMembers = useSelector(controllerMembersSelector)
  const controllerId = useSelector(selectedControllerIdSelector)

  const { multipleRepresentativeFilterOption, setMultipleRepresentativeFilterOption } = useContext(Context)

  const formattedControllerMembers = useMemo(() => {
    return formatOptionsList({
      list: controllerMembers,
      valueName: 'user',
      labelName: 'full_name'
    })
  }, [controllerMembers])

  const onMultipleRepresentativeFilterChange = useCallback(
    data => {
      setMultipleRepresentativeFilterOption(data)
    },
    [setMultipleRepresentativeFilterOption]
  )

  useEffect(() => {
    dispatch(getControllerMembers({ controller: controllerId }))

    return () => {
      dispatch(clearControllerMembers())
    }
  }, [dispatch, controllerId])

  return (
    <MultipleCheckboxesSelect
      options={formattedControllerMembers}
      value={multipleRepresentativeFilterOption}
      onChange={onMultipleRepresentativeFilterChange}
      className={classes.representativeSelect}
      placeholder="Representatives"
      multipleOptionsLabel="Representatives"
      isSearchable={true}
    />
  )
}

RepresentativeFilter.propTypes = {
  Context: PropTypes.object
}

export default RepresentativeFilter
