import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ButtonIcon from '../../features/components/Buttons/ButtonIcon'

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg'
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg'
import { ReactComponent as MinusIcon } from '../../assets/icons/minus.svg'

import useStyles from './styles'

function Counter({
  className,
  value = 0,
  onValueDecrease,
  onValueIncrease,
  callbackProps,
  minCount,
  maxCount,
  onDelete
}) {
  const isMinCountSet = minCount || minCount === 0
  const isMaxCountSet = maxCount || maxCount === 0

  const hideDecreaseButton = isMinCountSet && value === minCount
  const hideIncreaseButton = isMaxCountSet && value >= maxCount

  const classes = useStyles({ hideDecreaseButton: !!hideDecreaseButton, hideIncreaseButton: !!hideIncreaseButton })

  const handleIncrease = e => {
    e.stopPropagation()

    const newValue = value + 1
    onValueIncrease && onValueIncrease({ ...callbackProps, newValue })
    return newValue
  }

  const handleDecrease = e => {
    e.stopPropagation()

    const newValue = value - 1
    onValueDecrease && onValueDecrease({ ...callbackProps, newValue })
    return newValue
  }

  const isDeleteAllowed = value === 1 && !!onDelete

  return (
    <div className={classnames(classes.counter, className)}>
      {isDeleteAllowed ? (
        <ButtonIcon className={classes.btnDecrease} onClick={() => onDelete(callbackProps)} Icon={TrashIcon} />
      ) : (
        <ButtonIcon className={classes.btnDecrease} onClick={handleDecrease} Icon={MinusIcon} />
      )}
      <div className={classes.counterValue}>{value}</div>
      <ButtonIcon className={classes.btnIncrease} onClick={handleIncrease} Icon={PlusIcon} />
    </div>
  )
}

Counter.propTypes = {
  className: PropTypes.string,
  minCount: PropTypes.number,
  maxCount: PropTypes.number,
  value: PropTypes.number.isRequired,
  onValueDecrease: PropTypes.func,
  onValueIncrease: PropTypes.func,
  // represents delete icon and allow to decrease value to 0 after what expected the component to be deleted in Parent
  onDelete: PropTypes.func,
  callbackProps: PropTypes.object
}

export default Counter
