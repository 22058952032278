import { createStyles } from '../../../../../../../styles/helpers'

const useStyles = createStyles({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    rowGap: 8,
    columnGap: 8
  },
  dateField: {
    flexBasis: 145
  },
  field: {
    flexGrow: 2
  }
})

export default useStyles
