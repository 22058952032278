import React, { useCallback } from 'react'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import MultiRowFields from '../../../../../../../features/components/Form/MultiRowFields'
import PricePairFields from '../../../../../../ReusableFormFields/PricePairFields'
import FieldsSection from '../../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'

import { FIXED_DELIVERY_DATES_CHECKBOX, MEDIA_ITEMS_OPTION } from '../../fields'
import { MEDIA_PRODUCTS } from '../../../../fields'
import {
  PRICE_CURRENCY,
  PRICE_PAIRS,
  PRICE_VALUE,
  pricePairInitialValue
} from '../../../../../../ReusableFormFields/PricePairFields/fields'

const stepFields = [PRICE_PAIRS, PRICE_CURRENCY, PRICE_VALUE]

function PricesStep({ formik, handleStepChange }) {
  const { values } = formik

  const isFixedPrice = values[FIXED_DELIVERY_DATES_CHECKBOX] && values[MEDIA_ITEMS_OPTION] === MEDIA_PRODUCTS

  const stepTitle = isFixedPrice ? 'What is the price of this package?' : 'What is the starting price of this package?'

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields, values)
  }, [handleStepChange, values])

  return (
    <Step formTitle="Create a package" stepTitle={stepTitle} handleContinue={handleContinue}>
      <FieldsSection title="Pricing">
        <FieldRow title="Starting price" description="The ‘from’ price displayed on the media package item">
          <MultiRowFields
            formik={formik}
            initialValueTemplate={pricePairInitialValue}
            mainValueName={PRICE_PAIRS}
            subValueNames={[PRICE_CURRENCY, PRICE_VALUE]}
            FieldsComponent={PricePairFields}
            addNewRowText="+ Another currency"
          />
        </FieldRow>
      </FieldsSection>
    </Step>
  )
}

export default PricesStep
