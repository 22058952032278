const convertStringToObj = string => {
  // todo delete this helper when BE fix structure of detial field
  // Replace single quotes with double quotes to make it a valid JSON string
  let correctedStr = string.replace(/'/g, '"')

  // Replace Python-style None with JavaScript null
  correctedStr = correctedStr.replace(/\bNone\b/g, 'null')

  // Parse the corrected string into a JavaScript object
  return JSON.parse(correctedStr)
}

export const generateDetailsText = historyItem => {
  let formattedDetail = {}
  if (historyItem.detail) {
    formattedDetail = convertStringToObj(historyItem.detail)
  }

  switch (historyItem.action) {
    case 'created':
      return `Media booking was created by ${formattedDetail.user}`
    case 'updated':
      return `Media booking was updated by ${formattedDetail.user}`
    case 'deleted':
      return `Media booking was deleted by ${formattedDetail.user}`
    case 'media_order_file_created':
      return `Media booking file was uploaded by ${formattedDetail.user}`
    case 'media_order_file_deleted':
      return `Media booking file was removed by ${formattedDetail.user}`
    case 'cancelled':
      return `Media booking was cancelled by ${formattedDetail.user}. Reason for cancellation: ${formattedDetail.reason}`
    case 'contract_created':
      return `Booking contract was created by ${formattedDetail.user}`
    case 'contract_updated':
      return `Booking contract was updated by ${formattedDetail.user}`
    case 'contract_signed':
      return `Booking contract was signed by ${formattedDetail.user}`
    case 'contract_generate_media_order':
      return `Media booking was created by ${formattedDetail.user}`
    case 'save_as_quotation':
      return `Quotation was created by ${formattedDetail.user}`
    case 'send_quotation':
      return `Quotation was sent for signature by ${formattedDetail.user}`
    case 'updated_quotation_cost':
      return `Quotation cost was updated by ${formattedDetail.user}`
    case 'quotation_expired':
      return `Quotation expired ${formattedDetail.user}`
    case 'amendment_created':
      return `Amendment was created by ${formattedDetail.user}`
    case 'amendment_updated':
      return `Amendment was updated by ${formattedDetail.user}`
    case 'amendment_signed':
      return `Amendment was signed by ${formattedDetail.user}`
    case 'amendment_controller_signed':
      return `Amendment was signed by ${formattedDetail.user}`
    case 'send_amendment':
      return `Amendment was sent for signature by ${formattedDetail.user}`
    case 'submit_amendment':
      return `Booking was created by ${formattedDetail.user}`
    case 'updated_booked_media_status':
      return `Status of booked media “${formattedDetail.booked_media_name}“ was updated to “${formattedDetail.booked_media_status}” by ${formattedDetail.user}`
    case 'uploaded_file_created':
      return `A file “${formattedDetail.file_name}” was uploaded to product “${formattedDetail.product_name}“ by ${formattedDetail.user}`
    case 'uploaded_file_updated':
      return `A file “${formattedDetail.file_name}” linked to product “${formattedDetail.product_name}“ was updated by ${formattedDetail.user}`
    case 'uploaded_file_deleted':
      return `A file “${formattedDetail.file_name}” linked to product “${formattedDetail.product_name}“ was removed by ${formattedDetail.user}`
    case 'upload_file_approval_status_updated':
      return `Status of uploaded file “${formattedDetail.file_name}“ was updated to “${formattedDetail.file_status}” by ${formattedDetail.user}`
    case 'upload_file_status_updated':
      return `Status of uploaded file “${formattedDetail.file_name}“ was updated to “${formattedDetail.file_status}” by ${formattedDetail.user}`
    default:
      return 'Unknown action'
  }
}
