import { add, set, lastDayOfMonth } from 'date-fns'
import { formatDateToBE } from '../dates'

export const NEXT_6_MONTH = 'next_6_month'
export const NEXT_12_MONTH = 'next_12_month'
export const LAST_6_MONTH = 'last_6_month'
export const LAST_12_MONTH = 'last_12_month'
export const LAST_YEAR = 'last_year'
export const THIS_YEAR = 'this_year'
export const NEXT_YEAR = 'next_year'
export const ALL_TIME = 'all_time'

export const graphDatesListExcludedAll = [
  // Order is -1Y, -6M, +6M, +1Y, All
  {
    label: '-1Y',
    value: LAST_12_MONTH
  },
  {
    label: '-6M',
    value: LAST_6_MONTH
  },
  {
    label: '+6M',
    value: NEXT_6_MONTH
  },
  {
    label: '+1Y',
    value: NEXT_12_MONTH
  }
]

export const graphDatesOptionsListShort = [
  // Order is -1Y, -6M, +6M, +1Y, All
  ...graphDatesListExcludedAll,
  {
    label: 'All',
    value: ALL_TIME
  }
]

export const getDatesFromGraphDateOption = dateOption => {
  // start date for all calculations
  const currentMonthFirstDay = set(new Date(), {
    date: 1
  })

  switch (dateOption) {
    case NEXT_6_MONTH:
      // calculated as the 1st of current month until last date of 6th month.. so if today Nov 3 it would be 01 Nov 2022 - 30 Apr 2023
      return {
        date_from: formatDateToBE(currentMonthFirstDay),
        date_to: formatDateToBE(lastDayOfMonth(add(currentMonthFirstDay, { months: 5 })))
      }

    case NEXT_12_MONTH:
      // calculated as the 1st of current month until last date of 12th month
      return {
        date_from: formatDateToBE(currentMonthFirstDay),
        date_to: formatDateToBE(lastDayOfMonth(add(currentMonthFirstDay, { months: 11 })))
      }
    case LAST_6_MONTH:
      // calculated as the 1st of 6 months before last date of last month until last date of last month. so if today Nov 3 it would be - 31 Oct 2022
      return {
        date_from: formatDateToBE(add(currentMonthFirstDay, { months: -6 })),
        date_to: formatDateToBE(lastDayOfMonth(add(currentMonthFirstDay, { months: -1 })))
      }
    case LAST_12_MONTH:
      return {
        date_from: formatDateToBE(add(currentMonthFirstDay, { months: -12 })),
        date_to: formatDateToBE(lastDayOfMonth(add(currentMonthFirstDay, { months: -1 })))
      }
    case LAST_YEAR:
      return {
        date_from: formatDateToBE(
          set(add(currentMonthFirstDay, { years: -1 }), {
            month: 0
          })
        ),
        date_to: formatDateToBE(lastDayOfMonth(set(add(currentMonthFirstDay, { years: -1 }), { month: 11 })))
      }
    case THIS_YEAR:
      return {
        date_from: formatDateToBE(
          set(currentMonthFirstDay, {
            month: 0
          })
        ),
        date_to: formatDateToBE(lastDayOfMonth(set(currentMonthFirstDay, { month: 11 })))
      }
    case NEXT_YEAR:
      return {
        date_from: formatDateToBE(
          set(add(currentMonthFirstDay, { years: 1 }), {
            month: 0
          })
        ),
        date_to: formatDateToBE(lastDayOfMonth(set(add(currentMonthFirstDay, { years: 1 }), { month: 11 })))
      }
    case ALL_TIME:
    default:
      return {}
  }
}
