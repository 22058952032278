import { useCallback } from 'react'

import { getLineItemSummaryLinkText } from '../../../../constants/lineItems'

import { PROVIDER_TO_PLATFORMS } from '../../../../constants/selectLists/platformList'

const useGetLineItemActionsDropdownOptions = ({ getLineItemLinkClickHandler }) => {
  return useCallback(
    lineItem => {
      const { provider } = lineItem

      const linkClickHandler =
        lineItem &&
        getLineItemLinkClickHandler({
          item: lineItem,
          platform: PROVIDER_TO_PLATFORMS[provider],
          adAccountId: lineItem['ad_account_id']
        })

      return [
        {
          text: getLineItemSummaryLinkText(PROVIDER_TO_PLATFORMS[provider]),
          onClickHandler: linkClickHandler
        }
      ]
    },
    [getLineItemLinkClickHandler]
  )
}

export default useGetLineItemActionsDropdownOptions
