import React from 'react'
import Skeleton from 'react-loading-skeleton'
import PageSection from '../../../../../../../features/components/PageSection'

import useStyles from './styles'

function SkeletonBookedRevenueByMonthGraph() {
  const classes = useStyles()

  return (
    <PageSection className={classes.sectionContainer}>
      <div className={classes.headingContainer}>
        <div className={classes.title}>
          <Skeleton width={80} height={17} />
        </div>
      </div>
      <Skeleton height={180} style={{ width: '100%', marginTop: 5 }} />
    </PageSection>
  )
}

export default SkeletonBookedRevenueByMonthGraph
