import React, { forwardRef, useMemo } from 'react'
import { useTheme } from 'react-jss'
import PropTypes from 'prop-types'

import { Line } from 'react-chartjs-2'
// import graphs initial setting
import '../index'
import { useGraph } from '../hooks'

import getReusableGraphOptions from '../reusableGraphOptions'

const LineGraph = forwardRef((props, ref) => {
  const theme = useTheme()
  useGraph()

  const { data, labels, className, graphColor, format } = props

  const graphData = {
    labels,
    datasets: [
      {
        data: data,
        backgroundColor: graphColor,
        borderColor: graphColor || theme.brandPrimary,
        borderRadius: 5,
        pointBackgroundColor: '#ffffff',
        lineTension: 0.4
      }
    ]
  }

  const graphOptions = useMemo(
    () => getReusableGraphOptions({ format, theme, graphColor }),
    [format, graphColor, theme]
  )

  return (
    <div className={className}>
      <Line type="line" data={graphData} options={graphOptions} ref={ref} />
    </div>
  )
})

LineGraph.propTypes = {
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  className: PropTypes.string,
  format: PropTypes.string,
  graphColor: PropTypes.string
}

export default LineGraph
