import React from 'react'
import classnames from 'classnames'
import { useKeenSlider } from 'keen-slider/react'
import PropTypes from 'prop-types'

import SkeletonGoogleAssetsSliderPreview from './Skeleton'

import GoogleAdPreviewSearch1 from './Previews/GoogleAdPreviewSearch1'
import GoogleAdPreviewDiscover1 from './Previews/GoogleAdPreviewDiscover1'
import GoogleAdPreviewDisplay1 from './Previews/GoogleAdPreviewDisplay1'
import GoogleAdPreviewDisplay2 from './Previews/GoogleAdPreviewDisplay2'
import GoogleAdPreviewDisplay3 from './Previews/GoogleAdPreviewDisplay3'
import GoogleAdPreviewDiscover2 from './Previews/GoogleAdPreviewDiscover2'

import {
  FILE_URL,
  LANDSCAPE_IMAGE,
  PORTRAIT_IMAGE,
  SQUARE_IMAGE,
  SQUARE_LOGO,
  landscapeImageHeight,
  landscapeImageWidth,
  squareImageWidth,
  squareImageHeight,
  portraitImageWidth,
  portraitImageHeight
} from '../fields'

import { sliderPreviewParams } from './sliderPreviewParams'

import useStyles from './styles'

const GoogleAssetsSliderPreview = ({ values, showSkeleton, showSearchPreview = true }) => {
  const classes = useStyles()

  // Slider configuration
  const [sliderRef] = useKeenSlider(...sliderPreviewParams)

  if (showSkeleton) {
    return <SkeletonGoogleAssetsSliderPreview />
  }

  return (
    <div ref={sliderRef} className={classnames('keen-slider', classes.sliderPreview)}>
      {showSearchPreview && (
        <div className={classnames('keen-slider__slide', classes.slide)}>
          <GoogleAdPreviewSearch1 values={values} />
        </div>
      )}
      {/*Landscape image*/}
      {!!values[LANDSCAPE_IMAGE]?.length && (
        <div className={classnames('keen-slider__slide', classes.slide)}>
          <GoogleAdPreviewDiscover1
            values={values}
            fileUrl={values[LANDSCAPE_IMAGE][0][FILE_URL]}
            width={landscapeImageWidth}
            height={landscapeImageHeight}
          />
        </div>
      )}
      {/* Square image */}
      {!!values[SQUARE_IMAGE]?.length && (
        <div className={classnames('keen-slider__slide', classes.slide)}>
          <GoogleAdPreviewDiscover1
            values={values}
            fileUrl={values[SQUARE_IMAGE][0][FILE_URL]}
            width={squareImageWidth}
            height={squareImageHeight}
          />
        </div>
      )}
      {/* Portrait image */}
      {!!values[PORTRAIT_IMAGE]?.length && (
        <div className={classnames('keen-slider__slide', classes.slide)}>
          <GoogleAdPreviewDiscover1
            values={values}
            fileUrl={values[PORTRAIT_IMAGE][0][FILE_URL]}
            width={portraitImageWidth}
            height={portraitImageHeight}
          />
        </div>
      )}
      {/* Square logo */}
      {!!values[SQUARE_LOGO]?.length && (
        <div className={classnames('keen-slider__slide', classes.slide)}>
          <GoogleAdPreviewDisplay2 values={values} fileUrl={values[SQUARE_LOGO][0][FILE_URL]} />
        </div>
      )}
      {/* Landscape image and Square logo */}
      {!!values[SQUARE_LOGO]?.length && (
        <div className={classnames('keen-slider__slide', classes.slide)}>
          <GoogleAdPreviewDisplay3
            values={values}
            fileUrl={values[SQUARE_LOGO][0][FILE_URL]}
            backgroundImageUrl={values[LANDSCAPE_IMAGE][0][FILE_URL]}
          />
        </div>
      )}
      {/* Square image and Square logo */}
      {!!values[SQUARE_LOGO]?.length && (
        <div className={classnames('keen-slider__slide', classes.slide)}>
          <GoogleAdPreviewDiscover2
            values={values}
            squareImageFileUlr={values[SQUARE_IMAGE][0][FILE_URL]}
            squareLogoFileUrl={values[SQUARE_LOGO][0][FILE_URL]}
          />
        </div>
      )}
      <div className={classnames('keen-slider__slide', classes.slide)}>
        <GoogleAdPreviewDisplay1 values={values} />
      </div>
    </div>
  )
}

export default GoogleAssetsSliderPreview

GoogleAssetsSliderPreview.propTypes = {
  values: PropTypes.object.isRequired,
  showSkeleton: PropTypes.bool,
  showSearchPreview: PropTypes.bool
}
