import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useInteractions,
  useClick,
  FloatingArrow,
  arrow,
  FloatingPortal
} from '@floating-ui/react'
import classnames from 'classnames'

import ConditionalWrapper from '../hoc/ConditionalWrapper'
import { ReactComponent as AngleIcon } from '../../assets/icons/chevron-arrow-down.svg'

import { insertIf } from '../../helpers/common'
import { mediumGrey } from '../../styles/const/colors'

import useStyles from './style'

const arrowHeight = 6
const defaultGap = 2
const arrowOffset = arrowHeight + defaultGap
const floatingArrowBorderColor = mediumGrey
const floatingArrowBackgroundColor = 'white'

function Dropdown({
  className,
  triggerClassName,
  arrowClassName,
  showArrowIndicator = false,
  isDisabled,
  strategy = 'fixed',
  portaled,
  triggerElement,
  isOpen,
  onOpenChange,
  placement = 'bottom',
  allowPlacementFlip = true,
  offsetSize = 0,
  closeOnInsideClick = false,
  children,
  withFloatingArrow = false
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const arrowRef = useRef(null)

  const classes = useStyles({ dropdownOpen, isDisabled })

  const onVisibilityChange = useCallback(
    isOpen => {
      if (!isDisabled && dropdownOpen !== isOpen) {
        setDropdownOpen(isOpen)
        onOpenChange && onOpenChange(isOpen)
      }
    },
    [dropdownOpen, onOpenChange, isDisabled]
  )

  const handleInsideClick = useCallback(() => {
    closeOnInsideClick && onVisibilityChange(false)
  }, [closeOnInsideClick, onVisibilityChange])

  const { refs, floatingStyles, context } = useFloating({
    open: dropdownOpen,
    onOpenChange: onVisibilityChange,
    whileElementsMounted: autoUpdate,
    placement,
    // the fixes strategy is used because of the issue with wrong positioning in Step forms
    // also cutting the dropdown which go outside relative overflow container
    // also expanding the header width on mobile and horizontal scroll appearing based on that
    strategy: strategy,
    middleware: [
      ...insertIf(allowPlacementFlip, flip({ fallbackAxisSideDirection: 'start' })),
      offset(withFloatingArrow ? arrowOffset + offsetSize : offsetSize),
      arrow({ element: arrowRef }),
      shift()
    ]
  })

  const click = useClick(context)
  // adds the ability to dismiss the tooltip when the user presses the esc key or click outside.
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'menu' })
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role])

  useEffect(() => {
    // handle manual visibility change from the Parent component
    setDropdownOpen(!!isOpen)
  }, [isOpen])

  return (
    <div className="dropdown">
      <div
        className={classnames(triggerClassName, classes.triggerElement)}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {triggerElement}
        {showArrowIndicator && <AngleIcon className={classnames(arrowClassName, classes.arrowIndicator)} />}
      </div>
      {/* dropdown is not rendered until it will be opened to avoid re-rendering on scroll */}
      {dropdownOpen && (
        <ConditionalWrapper condition={portaled} wrapper={children => <FloatingPortal>{children}</FloatingPortal>}>
          <div
            ref={refs.setFloating}
            {...getFloatingProps()}
            onClick={handleInsideClick}
            style={floatingStyles}
            className={classnames(className, classes.dropdownBody)}
          >
            {withFloatingArrow && (
              <FloatingArrow
                ref={arrowRef}
                context={context}
                width={12}
                height={arrowHeight}
                tipRadius={2}
                strokeWidth={1}
                stroke={floatingArrowBorderColor}
                fill={floatingArrowBackgroundColor}
              />
            )}
            {children}
          </div>
        </ConditionalWrapper>
      )}
    </div>
  )
}

export default Dropdown

Dropdown.propTypes = {
  className: PropTypes.string,
  triggerClassName: PropTypes.string,
  arrowClassName: PropTypes.string,
  placement: PropTypes.string,
  allowPlacementFlip: PropTypes.bool,
  // allow to close Dropdown on the options/body click
  closeOnInsideClick: PropTypes.bool,
  showArrowIndicator: PropTypes.bool,
  isDisabled: PropTypes.bool,
  withFloatingArrow: PropTypes.bool,
  triggerElement: PropTypes.node,
  children: PropTypes.node.isRequired
}
