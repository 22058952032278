import update from 'immutability-helper'

import { INITIAL_CREATE_REDUCER, INITIAL_DELETE_REDUCER, INITIAL_GET_RESULTS_REDUCER } from '../../constants/reducer'
import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_MARGINS,
  GET_MARGINS_SUCCESS,
  GET_MARGINS_FAILURE,
  CLEAR_MARGINS,
  CREATE_MARGINS,
  CREATE_MARGINS_SUCCESS,
  CREATE_MARGINS_FAILURE,
  CLEAR_CREATE_MARGINS,
  CREATE_MARGIN_OVERRIDE,
  CREATE_MARGIN_OVERRIDE_SUCCESS,
  CREATE_MARGIN_OVERRIDE_FAILURE,
  CLEAR_CREATE_MARGIN_OVERRIDE,
  DELETE_MARGIN_OVERRIDE,
  DELETE_MARGIN_OVERRIDE_SUCCESS,
  DELETE_MARGIN_OVERRIDE_FAILURE
} from '../actions/margins'
import { deleteItemById } from '../../helpers/modules/reducerHelpers'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  margins: INITIAL_GET_RESULTS_REDUCER,
  createMargins: INITIAL_CREATE_REDUCER,
  createMarginOverride: INITIAL_CREATE_REDUCER,
  deleteMarginOverride: INITIAL_DELETE_REDUCER
}

export default function margins(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    // get margins
    case GET_MARGINS:
      return update(state, {
        margins: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_MARGINS_SUCCESS:
      return update(state, {
        margins: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.marginsData
          }
        }
      })
    case GET_MARGINS_FAILURE:
      return update(state, {
        margins: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_MARGINS:
      return update(state, {
        margin: { $set: initialState.margins }
      })

    // create margins
    case CREATE_MARGINS:
      return update(state, {
        createMargins: {
          $merge: {
            isLoading: true
          }
        }
      })
    case CREATE_MARGINS_SUCCESS:
      return update(state, {
        createMargins: {
          $merge: {
            isLoading: false,
            wasCreated: true
          }
        }
      })
    case CREATE_MARGINS_FAILURE:
      return update(state, {
        createMargins: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_MARGINS:
      return update(state, {
        createMargins: { $set: initialState.createMargins }
      })

    // create margin override
    case CREATE_MARGIN_OVERRIDE:
      return update(state, {
        createMarginOverride: {
          $merge: {
            isLoading: true
          }
        }
      })
    case CREATE_MARGIN_OVERRIDE_SUCCESS:
      return update(state, {
        createMarginOverride: {
          $merge: {
            isLoading: false,
            wasCreated: true
          }
        }
      })
    case CREATE_MARGIN_OVERRIDE_FAILURE:
      return update(state, {
        createMarginOverride: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_MARGIN_OVERRIDE:
      return update(state, {
        createMarginOverride: { $set: initialState.createMargins }
      })

    // delete margin override
    case DELETE_MARGIN_OVERRIDE:
      return update(state, {
        deleteMarginOverride: {
          $merge: { isLoading: true }
        }
      })
    case DELETE_MARGIN_OVERRIDE_SUCCESS:
      return update(state, {
        deleteMarginOverride: {
          $merge: { isLoading: false }
        },
        margins: {
          results: {
            $apply: items => deleteItemById(items, action.id)
          }
        }
      })
    case DELETE_MARGIN_OVERRIDE_FAILURE:
      return update(state, {
        deleteMarginOverride: {
          $merge: { isLoading: false }
        }
      })
    default:
      return state
  }
}
