import { createStyles } from '../../../../../../../styles/helpers'
import { quotationBuilderColPadding } from '../styles'

const useStyles = createStyles({
  footerContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  newProductButton: {
    fontSize: 12
  },
  totalCol: {
    padding: `0 ${quotationBuilderColPadding}px`
  },
  totalsContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    flexGrow: 1,
    justifyContent: 'flex-end'
  },
  totalInput: {
    fontSize: 12,
    height: '32px',
    padding: '0 8px'
  },
  discountField: {
    width: 62
  },
  priceField: {
    width: 95
  },
  totalFieldContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '20px'
  },
  totalTitle: {
    marginRight: 16
  },
  totalCost: {
    fontWeight: 600
  }
})

export default useStyles
