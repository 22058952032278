import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

export const selfAccountsSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.selfAccounts.results,
  results => results
)

export const selfAccountsIsLoadingSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.selfAccounts.isLoading,
  isLoading => isLoading
)

export const selfAccountsWasLoadedSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.selfAccounts.wasLoaded,
  wasLoaded => wasLoaded
)

export const selfAccountsErrorSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.selfAccounts.error,
  error => error
)

export const createSelfAccountDataSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.createSelfAccount.data,
  data => data
)

export const selfAccountsNextSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.selfAccounts.paging.next,
  next => next
)

export const createSelfAccountIsLoadingSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.createSelfAccount.isLoading,
  isLoading => isLoading
)

export const createSelfAccountErrorSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.createSelfAccount.error,
  error => error
)

export const selfAccountWasCreatedSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.createSelfAccount.wasCreated,
  wasCreated => wasCreated
)

export const updateSelfAccountSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.updateSelfAccount,
  updateSelfAccount => updateSelfAccount
)

export const updateSelfAccountIsLoadingSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.updateSelfAccount.isLoading,
  isLoading => isLoading
)

export const updateSelfAccountErrorSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.updateSelfAccount.error,
  error => error
)

export const wasSelfAccountUpdatedSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.updateSelfAccount.wasUpdated,
  wasUpdated => wasUpdated
)

// Get categories
export const selfAccountCategoriesSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.categories.results,
  results => results
)

export const getSelfAccountCategoriesIsLoadingSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.categories.isLoading,
  isLoading => isLoading
)

export const getSelfAccountCategoriesErrorSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.categories.error,
  error => error
)

export const selfAccountCategoriesWasLoadedSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.categories.wasLoaded,
  wasLoaded => wasLoaded
)

// Get sub categories
export const selfAccountSubCategoriesSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.subCategories.results,
  results => results
)

export const selfAccountSubCategoriesIsLoadingSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.subCategories.isLoading,
  isLoading => isLoading
)

export const selfAccountSubCategoriesErrorSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.subCategories.error,
  error => error
)

export const selfAccountSubCategoriesWasLoadedSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.subCategories.wasLoaded,
  wasLoaded => wasLoaded
)

// Get self account
export const selfAccountSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.getSelfAccount.data,
  data => data
)

export const getSelfAccountIsLoadingSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.getSelfAccount.isLoading,
  isLoading => isLoading
)

export const getSelfAccountErrorSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.getSelfAccount.error,
  error => error
)

export const selfAccountWasLoadedSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.getSelfAccount.wasLoaded,
  wasLoaded => wasLoaded
)

// Get category
export const selfAccountCategorySelector = createSelector(
  ({ selfAccounts }) => selfAccounts.category.data,
  data => data
)

export const getSelfAccountCategoryIsLoadingSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.category.isLoading,
  isLoading => isLoading
)

export const getSelfAccountCategoryErrorSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.category.error,
  error => error
)

export const selfAccountCategoryWasLoadedSelector = createSelector(
  ({ selfAccounts }) => selfAccounts.category.wasLoaded,
  wasLoaded => wasLoaded
)
