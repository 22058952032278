import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import CampaignForm from './CampaignForm'
import AssetGroupCreative from './AssetGroupCreative'
import AdCreativeForm from './AdCreative'
import FormWrapper from '../../../../features/components/Form/FormWrapper'

import useContinuousForm from '../../../../hooks/formHooks/useContinuousForm'
import { useFormProgressItems } from './useFormProgressItems'
import { useInitialCampaignTypeOption } from '../useInitialCampaignTypeOption'

import { checkIfCampaignHasLineItem } from '../../CampaignForms/helpers'

import { mediaOrderUploadCreativeDataSelector } from '../../../../modules/selectors/mediaOrders'
import { selectedGoogleAdAccountIdSelector } from '../../../../modules/selectors/app'

import {
  DISPLAY_TYPE,
  PERFORMANCE_MAX_TYPE
} from '../../CampaignForms/CampaignGoogleCreate/CampaignGoogleCreateForm/fields'
import { MAIN_FORM } from './AssetGroupCreative/GoogleAssetGroupForm'
import { CAMPAIGN_OPTION_EXISTING_CAMPAIGN } from './CampaignForm/CampaignFormContent/fields'

export const CAMPAIGN_FORM = 'campaign_form'
export const AD_FORM = 'ad_form'
export const LINE_ITEM_FORM = 'line_item_form'

const UploadGoogleAdCreativeForm = ({ onSuccessSubmit }) => {
  const [isExistingCampaignFlow, setIsExistingCampaignFlow] = useState(false)

  const initialCampaignOption = useInitialCampaignTypeOption()

  const [campaignRoute, setCampaignRoute] = useState(initialCampaignOption)

  const { campaign } = useSelector(mediaOrderUploadCreativeDataSelector)

  const adAccountId = useSelector(selectedGoogleAdAccountIdSelector)

  const { activatedForm, setActivatedForm } = useContinuousForm({
    initialForm: CAMPAIGN_FORM
  })

  const assetGroupRouter = useContinuousForm({
    initialForm: MAIN_FORM
  })

  const { activatedForm: assetGroupActivatedForm } = assetGroupRouter

  const campaignHasLineItem = checkIfCampaignHasLineItem(campaign?.advertising_channel_type)

  const formProgressItems = useFormProgressItems({
    activatedForm,
    assetGroupActivatedForm,
    campaignType:
      campaignRoute === CAMPAIGN_OPTION_EXISTING_CAMPAIGN
        ? campaignHasLineItem
          ? DISPLAY_TYPE
          : PERFORMANCE_MAX_TYPE
        : campaignRoute
  })

  const onSelectExistingCampaign = useCallback(() => {
    setActivatedForm(campaignHasLineItem ? LINE_ITEM_FORM : AD_FORM)

    setIsExistingCampaignFlow(true)
  }, [campaignHasLineItem, setActivatedForm])

  const onCampaignRouteSelect = useCallback(campaignRouteOption => {
    setCampaignRoute(campaignRouteOption)
  }, [])

  const getActivatedForm = useCallback(() => {
    switch (activatedForm) {
      case CAMPAIGN_FORM:
        return (
          <CampaignForm
            onSuccessSubmit={() => setActivatedForm(campaignHasLineItem ? LINE_ITEM_FORM : AD_FORM)}
            onCampaignRouteSelect={onCampaignRouteSelect}
            onSelectExistingCampaign={onSelectExistingCampaign}
          />
        )
      case AD_FORM:
      default:
        if (campaignHasLineItem) {
          return (
            <AdCreativeForm
              activatedForm={activatedForm}
              isExistingCampaignFlow={isExistingCampaignFlow}
              setActivatedForm={setActivatedForm}
              onSuccessSubmit={onSuccessSubmit}
              adAccountId={adAccountId}
            />
          )
        } else {
          return (
            <AssetGroupCreative
              assetGroupRouter={assetGroupRouter}
              onSuccessSubmit={onSuccessSubmit}
              adAccountId={adAccountId}
            />
          )
        }
    }
  }, [
    activatedForm,
    adAccountId,
    campaignHasLineItem,
    isExistingCampaignFlow,
    onSelectExistingCampaign,
    onSuccessSubmit,
    setActivatedForm,
    assetGroupRouter,
    onCampaignRouteSelect
  ])

  return <FormWrapper formProgressItems={formProgressItems}>{getActivatedForm()}</FormWrapper>
}

export default UploadGoogleAdCreativeForm
