import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import ActionText from '../../../../components/ActionText'
import FacebookPageSelectionFields from '../FacebookPageSelectionFields'
import CustomizedMediaFields from '../CustomizedMediaFields'
import FieldsSection from '../../../../features/components/Form/FieldsSection'
import FacebookProductAdFields from './FacebookProductAdFields'

import { adCreativeSuggestionsLoadingSelector } from '../../../../modules/selectors/tools'

const FacebookAdCreativeFields = ({
  formik,
  regenerateSuggestions,
  selectedProduct = {},
  adAccountId,
  allowMediaUpload,
  placementPositions,
  showCustomizedMediaQuestion,
  showCustomizedPreview,
  showNameField,
  showCustomClickThroughLink
}) => {
  const suggestionsLoading = useSelector(adCreativeSuggestionsLoadingSelector)

  return (
    <FieldsSection
      title="Creative assets"
      rightColumn={
        // we don't allow AI suggestions for custom click through link
        !showCustomClickThroughLink &&
        !suggestionsLoading && (
          <ActionText onClick={regenerateSuggestions} isDark>
            Regenerate content
          </ActionText>
        )
      }
    >
      <FacebookPageSelectionFields formik={formik} adAccountId={adAccountId} placementPositions={placementPositions} />
      <FacebookProductAdFields
        formik={formik}
        adAccountId={adAccountId}
        galleryImages={selectedProduct['image_urls']}
        allowMediaUpload={allowMediaUpload}
        showNameField={showNameField}
        showCustomClickThroughLink={showCustomClickThroughLink}
      />
      {showCustomizedMediaQuestion && (
        <CustomizedMediaFields
          formik={formik}
          adAccountId={adAccountId}
          showCustomizedPreview={showCustomizedPreview}
        />
      )}
    </FieldsSection>
  )
}

FacebookAdCreativeFields.propTypes = {
  formik: PropTypes.object.isRequired,
  regenerateSuggestions: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object,
  adAccountId: PropTypes.string.isRequired,
  allowMediaUpload: PropTypes.bool,
  hasFacebookSelection: PropTypes.bool,
  placementPositions: PropTypes.array,
  showCustomizedMediaQuestion: PropTypes.bool,
  showCustomizedPreview: PropTypes.bool,
  showNameField: PropTypes.bool,
  showCustomClickThroughLink: PropTypes.bool
}

export default FacebookAdCreativeFields
