import { LANGUAGE } from './language'
import { getLocalStorage } from './storage'
import { DEFAULT_LANGUAGE } from '../constants/selectLists/languagesList'

export const getSelectedLanguage = () => getLocalStorage(LANGUAGE) || DEFAULT_LANGUAGE

export const downloadCSVFile = ({ data, fileName }) => {
  const url = window.URL.createObjectURL(new Blob([data]))
  downloadFileByBrowser({ url, fileName, fileFormat: 'csv' })
}

export const downloadFileByBrowser = ({ url, fileName, fileFormat }) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}.${fileFormat}`)
  document.body.appendChild(link)
  link.click()
  link.remove()
}
