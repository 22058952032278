import { useSelector } from 'react-redux'

import { activeStepSelector } from '../../../../modules/selectors/forms'

import { AD_FORM, CAMPAIGN_FORM, LINE_ITEM_FORM } from './index'
import { AUDIENCE_FORM } from './AssetGroupCreative/GoogleAssetGroupForm'
import { PERFORMANCE_MAX_TYPE } from '../../CampaignForms/CampaignGoogleCreate/CampaignGoogleCreateForm/fields'
import { useShowProductOrPageSelectionStep } from '../../../Facebook/AdForms/AdFacebookCreate/AdFacebookCreateForm/hooks/useShowProductOrPageSelectionStep'
import { insertIf } from '../../../../helpers/common'

export function useFormProgressItems({ activatedForm, assetGroupActivatedForm, campaignType }) {
  const activeStep = useSelector(activeStepSelector)

  const showProductSelectionStep = useShowProductOrPageSelectionStep()
  const isAdCreativeDetailsStepActive = showProductSelectionStep ? activeStep === 1 : activeStep === 0

  return [
    {
      label: 'Campaign type and dates',
      isActive: activatedForm === CAMPAIGN_FORM,
      isSuccess: activatedForm === AD_FORM || activatedForm === LINE_ITEM_FORM
    },

    ...(campaignType === PERFORMANCE_MAX_TYPE
      ? // Asset group route
        [
          ...insertIf(showProductSelectionStep, {
            label: 'Select product',
            isActive: showProductSelectionStep && activatedForm === AD_FORM && activeStep === 0,
            isSuccess:
              showProductSelectionStep &&
              ((activatedForm === AD_FORM && activeStep === 1) || assetGroupActivatedForm === AUDIENCE_FORM)
          }),
          {
            label: 'Review ad creative',
            isActive: activatedForm === AD_FORM && isAdCreativeDetailsStepActive,
            isSuccess: assetGroupActivatedForm === AUDIENCE_FORM
          },
          {
            label: 'Select audience',
            isActive: assetGroupActivatedForm === AUDIENCE_FORM,
            isSuccess: false
          }
        ]
      : // Display Ad route
        [
          {
            label: 'Select audience',
            isActive: activatedForm === LINE_ITEM_FORM,
            isSuccess: activatedForm === AD_FORM
          },
          ...insertIf(showProductSelectionStep, {
            label: 'Select product',
            isActive: showProductSelectionStep && activatedForm === AD_FORM && activeStep === 0,
            isSuccess: showProductSelectionStep && activatedForm === AD_FORM && activeStep === 1
          }),
          {
            label: 'Review ad creative',
            isActive: activatedForm === AD_FORM && isAdCreativeDetailsStepActive,
            isSuccess: false
          }
        ])
  ]
}
