import { useCallback } from 'react'
import PropTypes from 'prop-types'

import DatePicker from '../../../../../components/DatePicker'
import { formatDateFullYear } from '../../../../../constants/dates'

import useStyles from './styles'

const InventoryDateRangeFilter = ({ dateStart, setDateStart }) => {
  const classes = useStyles()

  const onDateStartChange = useCallback(
    value => {
      setDateStart(value)
    },
    [setDateStart]
  )

  return (
    <DatePicker
      isPastAllowed={true}
      className={classes.dateStart}
      formattedLabel={'Start date: ' + formatDateFullYear(new Date(dateStart))}
      value={dateStart}
      changeHandler={onDateStartChange}
      placeholder={'Start date: '}
    />
  )
}

InventoryDateRangeFilter.propTypes = {
  dateStart: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  setDateStart: PropTypes.func.isRequired
}

export default InventoryDateRangeFilter
