import { createStyles } from '../../../../../styles/helpers'

import { greyOutline } from '../../../../../styles/const/colors'
import { transitionShort } from '../../../../../styles/const/common'

export default createStyles(theme => ({
  muteButton: {
    padding: 5,
    borderRadius: '50%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    border: `2px solid ${greyOutline}`,
    transition: transitionShort,
    cursor: 'pointer',

    '& path': {
      fill: theme.brandPrimary
    },

    '&:hover': {
      border: `2px solid ${theme.brandPrimary}`,
      boxShadow: '0 1px 5px 2px rgba(0,0,0,.05)'
    }
  }
}))
