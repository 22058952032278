import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import LineItemAdvancedForm from './LineItemAdvancedForm'
import AdFacebookCreateForm from '../../../../AdForms/AdFacebookCreate/AdFacebookCreateForm'
import { AdFacebookCreateContextProvider } from '../../../../formsContexts/AdFacebookCreateFormContextProvider'

import useContinuousForm from '../../../../../../hooks/formHooks/useContinuousForm'

import { mediaOrderUploadCreativeDataSelector } from '../../../../../../modules/selectors/mediaOrders'

export const LINE_ITEM_ADVANCED_FORM = 'line_item_advanced_form'
export const AD_FORM = 'ad_form'

const AdvancedSetupRoute = ({
  adAccountId,
  isExistingCampaignFlow,
  onSuccessAdFormSubmit,
  setActivatedInternalForm
}) => {
  const { campaign, lineItem } = useSelector(mediaOrderUploadCreativeDataSelector)

  const { objective: campaignObjective } = campaign
  const isLineItemPopulated = !!Object.keys(lineItem).length

  const { activatedForm, setActivatedForm } = useContinuousForm({
    initialForm: LINE_ITEM_ADVANCED_FORM
  })

  const handleSetActivatedForm = useCallback(
    formName => {
      setActivatedForm(formName)
      // set internal selected form for progress representation
      setActivatedInternalForm(formName)
    },
    [setActivatedForm, setActivatedInternalForm]
  )

  switch (activatedForm) {
    case AD_FORM:
      return (
        <AdFacebookCreateContextProvider campaignObjective={campaignObjective}>
          <AdFacebookCreateForm
            showFormProgressItems={false}
            // campaignObjective={campaignObjective}
            lineItemWasLoaded={isLineItemPopulated}
            onSuccessSubmit={onSuccessAdFormSubmit}
            adAccountId={adAccountId}
          />
        </AdFacebookCreateContextProvider>
      )
    case LINE_ITEM_ADVANCED_FORM:
    default:
      return (
        <LineItemAdvancedForm
          adAccountId={adAccountId}
          isExistingCampaignFlow={isExistingCampaignFlow}
          onSuccessSubmit={() => {
            handleSetActivatedForm(AD_FORM)
          }}
        />
      )
  }
}

AdvancedSetupRoute.propTypes = {
  adAccountId: PropTypes.string.isRequired,
  isExistingCampaignFlow: PropTypes.bool.isRequired,
  onSuccessAdFormSubmit: PropTypes.func.isRequired
}

export default AdvancedSetupRoute
