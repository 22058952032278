import * as Yup from 'yup'

import { getAdImageAssetInitialValues, getAdSquareLogoAssets } from '../../helpers'
import { SQUARE_LOGO } from '../../../../fields'
import { listOfImagesShape } from '../../../../validations'

export const getInitialValues = ad => {
  const squareLogoAssets = getAdSquareLogoAssets(ad)

  return {
    [SQUARE_LOGO]: squareLogoAssets ? squareLogoAssets.map(asset => getAdImageAssetInitialValues(asset)) : []
  }
}

export const validationSchema = Yup.object({
  [SQUARE_LOGO]: listOfImagesShape
})
