import { createStyles } from '../../../../styles/helpers'
import { grey, lightGrey2 } from '../../../../styles/const/colors'

export default createStyles({
  adCard: {
    width: '100%',
    maxWidth: 243,
    border: `1px solid ${grey}`,
    margin: '0 auto',
    backgroundColor: '#fff',
    overflow: 'hidden',
    marginBottom: 20,
    wordWrap: 'break-word',
    boxSizing: 'border-box',
    fontFamily: 'Roboto, sans-serif',
    '& p': {
      marginTop: [0, '!important']
    }
  },
  adCardHeader: {
    padding: 8,
    paddingBottom: 6
  },
  headerTop: {
    display: 'flex',
    marginBottom: 8
  },
  avatar: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: lightGrey2,
    marginRight: 8,
    overflow: 'hidden',

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  name: {
    fontSize: 11,
    fontWeight: 700,
    fontStyle: 'normal',
    letterSpacing: 'normal',
    minWidth: 55
  },
  description: {
    fontSize: 9
  },

  // AdText
  adText: {
    padding: '0 4px',
    fontSize: 10,
    lineHeight: '12px',
    color: '#000',
    whiteSpace: 'pre-wrap'
  },

  // Styles for Skeleton text line
  skeleton: {
    '& span': {
      lineHeight: 'inherit',
      borderRadius: '3px',
      display: 'block'
    }
  },
  skeletonPageName: {
    maxHeight: 10,
    height: 10,
    margin: 0
  },
  skeletonPrimaryText: {
    maxHeight: 7,
    height: 7,
    margin: 0,
    marginBottom: 3,
    '&:last-child': {
      maxWidth: '70%'
    }
  },
  skeletonWebsiteText: {
    maxWidth: '50px',
    maxHeight: 7,
    height: 7,
    margin: 0,
    marginBottom: 3
  }
})
