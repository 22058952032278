import React from 'react'

import { TabletDown, TabletUp } from '../../../../../components/hoc/ResponsiveRendering'
import FieldLabelWrapper from '../../../../../features/components/Form/FieldLabelWrapper'
import FormField from '../../../EditProfile/UserProfileContent/EditProfileFormContent/FormField'
import PasswordField from '../../../../../forms/ReusableFormFields/PasswordField'

import { DOOHLY_API_KEY } from '../fields'

import useStyles from '../../../EditProfile/styles'

const ManageControllerFormContent = ({ formik }) => {
  const classes = useStyles()

  return (
    <>
      <TabletUp>
        <FormField label="Dooh.ly API Key" isLastFormField>
          <PasswordField autoComplete="off" name={DOOHLY_API_KEY} formik={formik} placeholder="API key" />
        </FormField>
      </TabletUp>

      <TabletDown>
        <div className={classes.formRow}>
          <FieldLabelWrapper label="Dooh.ly API Key">
            <PasswordField autoComplete="off" name={DOOHLY_API_KEY} formik={formik} placeholder="API key" />
          </FieldLabelWrapper>
        </div>
      </TabletDown>
    </>
  )
}

export default ManageControllerFormContent
