// Generate numbers from 18 to 64 and also 65+
const generateYearsList = () => {
  const yearsList = []
  for (let year = 18; year <= 64; year++) {
    yearsList.push({
      value: year,
      label: `${year}`
    })
  }

  yearsList.push({
    value: 65,
    label: '65+'
  })

  return yearsList
}

export const ageYearsList = generateYearsList()
