import { insertIf } from '../../../../../helpers/common'

import {
  ANYONE_OVER_18,
  BIDDING_TYPE,
  DESTINATION_TYPE,
  ENGAGEMENT_TYPE,
  GEN_Z,
  IS_SPEND_LIFETIME,
  LANDING_PAGE_VIEWS,
  LANGUAGES_OPTION,
  MILLENNIALS,
  OBJECTIVE,
  ON_PAGE,
  ON_POST,
  ON_VIDEO,
  OPTIMIZATION_TYPE,
  PAGE_LIKES,
  PARENT_OPTION,
  SELECT_LANGUAGES,
  SHOW_REGIONS_CITY_TYPE,
  SHOW_REGIONS_REGION_TYPE,
  SPEND_OPTION,
  THRUPLAY,
  WEBSITE
} from './fields'
import {
  LOCATION_LIST,
  SHOW_OPTION_COUNTRIES,
  SHOW_OPTION_LOCATION_LIST,
  SHOW_OPTION_REGIONS,
  SHOW_OPTION_WORLDWIDE
} from '../../../../ReusableFormFields/GeolocationsFields/fields'
import {
  AUDIENCE_NETWORK_CLASSIC,
  AUDIENCE_NETWORK_INSTREAM_VIDEO,
  AUDIENCE_NETWORK_REWARDED_VIDEO,
  BUDGET_OPTION,
  BUDGET_DAILY,
  BUDGET_LIFETIME,
  FACEBOOK_FEED,
  FACEBOOK_INSTANT_ARTICLE,
  FACEBOOK_INSTREAM_VIDEO,
  FACEBOOK_MARKETPLACE,
  FACEBOOK_RIGHT_HAND_COLUMN,
  FACEBOOK_SEARCH,
  FACEBOOK_STORY,
  FACEBOOK_VIDEO_FEEDS,
  INSTAGRAM_EXPLORE,
  INSTAGRAM_REELS,
  INSTAGRAM_SHOP,
  INSTAGRAM_STORY,
  INSTAGRAM_STREAM,
  MESSENGER_MESSENGER_HOME,
  MESSENGER_SPONSORED_MESSAGES,
  MESSENGER_STORY,
  PLACEMENT_OPTION,
  PLACEMENT_OPTION_ALL_EXCLUDING_AUDIENCE,
  PLACEMENT_OPTION_CUSTOM,
  PLACEMENT_OPTION_INSTAGRAM,
  PLACEMENT_OPTION_NEWS_FEEDS,
  PLACEMENT_OPTION_NEWS_FEEDS_AND_STORIES,
  PLACEMENT_OPTION_STORIES,
  PLACEMENT_POSITIONS
} from '../../../../ReusableFormFields/LineItemForms/fields'
import { OPTION_CUSTOM, OPTION_YES } from '../../../../../constants/forms'
import {
  APP_INSTALLS,
  AUTOMATIC,
  BRAND_AWARENESS,
  CONVERSIONS,
  COST_CAP,
  LEAD_GENERATION,
  LINK_CLICKS,
  MANUAL,
  MESSAGES,
  MINIMUM_ROAS,
  OUTCOME_AWARENESS,
  OUTCOME_ENGAGEMENT,
  OUTCOME_TRAFFIC,
  POST_ENGAGEMENT,
  PRODUCT_CATALOG_SALES,
  REACH,
  STORE_VISITS,
  VIDEO_VIEWS
} from '../../../../../constants/campaigns'
import {
  formatScheduleToBE,
  SCHEDULE_DATES,
  SCHEDULE_FRIDAY,
  SCHEDULE_MONDAY,
  SCHEDULE_OPTION,
  SCHEDULE_SATURDAY,
  SCHEDULE_SUNDAY,
  SCHEDULE_THURSDAY,
  SCHEDULE_TUESDAY,
  SCHEDULE_WEDNESDAY
} from '../../../../ReusableFormFields/ScheduleFields/fields'
import { GEO_TARGETING } from '../../LineItemFacebookEdit/fields'
import {
  formatTimeStartToBE,
  START_DATE,
  START_OPTION,
  START_TIME
} from '../../../../ReusableFormFields/StartTimeFields/fields'
import {
  formatTimeStopToBE,
  STOP_DATE,
  STOP_OPTION,
  STOP_OPTION_INDEFINITELY,
  STOP_TIME
} from '../../../../ReusableFormFields/StopTimeFields/fields'
import { FORECAST_RANGE, LOCATION, TEMPERATURE } from '../../../../ReusableFormFields/TriggerFields/fields'
import {
  GENDER_OPTION_ALL,
  GENDER_OPTION_FEMALES,
  GENDER_OPTION_MALES
} from '../../../../ReusableFormFields/GenderFields/fields'
import { INTERESTS_OPTION } from '../../../../ReusableFormFields/InterestsFields/fields'
import {
  AUDIENCES_OPTION,
  CUSTOM_AUDIENCES_EXCLUSION_CHECKED
} from '../../../../ReusableFormFields/CustomAudiencesFields/fields'
import { PROMOTED_PIXEL } from '../../../../ReusableFormFields/PromotedObjectFields/fields'
import {
  FREQUENCY_OPTION,
  FREQUENCY_OPTION_AUTOMATIC,
  FREQUENCY_OPTION_DEFAULT
} from '../../../../ReusableFormFields/FrequencyFields/fields'
import { placementSafetyRules } from '../../../../ReusableFormFields/LineItemForms/facebookLineItemPlacementsHelpers'
import { getIsCampaignObjectiveConversionsOrSales } from '../../lineItemsHelpers'

export const formatSpendValuesToBE = (
  isSpendLifetime,
  min_spend_target_lifetime,
  spend_cap_lifetime,
  min_spend_target_daily,
  spend_cap_daily
) => {
  return isSpendLifetime
    ? {
        ...(min_spend_target_lifetime && { min_spend_target_lifetime }),
        ...(spend_cap_lifetime && { spend_cap_lifetime })
      }
    : {
        ...(min_spend_target_daily && { min_spend_target_daily }),
        ...(spend_cap_daily && { spend_cap_daily })
      }
}

export const formatShowTargetToBE = (show_option, show_countries, show_regions) => {
  switch (show_option) {
    case SHOW_OPTION_COUNTRIES:
      return {
        countries: show_countries.map(({ code }) => code)
      }
    case SHOW_OPTION_REGIONS:
      const filteredRegions = show_regions.filter(item => item.type === SHOW_REGIONS_REGION_TYPE)
      const filteredCities = show_regions.filter(item => item.type === SHOW_REGIONS_CITY_TYPE)

      return {
        regions: filteredRegions.map(({ value }) => ({
          key: value
        })),
        cities: filteredCities.map(({ value }) => ({
          key: value
        }))
      }
    case SHOW_OPTION_WORLDWIDE:
      return {
        country_groups: ['worldwide']
      }
    default:
      return {}
  }
}

export const formatGenderToBE = gender_option => {
  switch (gender_option) {
    case GENDER_OPTION_ALL:
      return {
        male: true,
        female: true
      }
    case GENDER_OPTION_MALES:
      return {
        male: true,
        female: false
      }
    case GENDER_OPTION_FEMALES:
      return {
        male: false,
        female: true
      }
    default:
      return {}
  }
}

export const formatAgeToBE = (age_option, min_age_choice, max_age_choice) => {
  switch (age_option) {
    case ANYONE_OVER_18:
      return {
        age_min: 18,
        age_max: 65
      }
    case MILLENNIALS:
      const minAgeMillennials = new Date().getFullYear() - 1994
      const maxAgeMillennials = new Date().getFullYear() - 1980

      return {
        age_min: minAgeMillennials,
        age_max: maxAgeMillennials
      }
    case GEN_Z:
      const maxAgeZ = new Date().getFullYear() - 1995

      return {
        age_min: 18,
        age_max: maxAgeZ
      }
    case OPTION_CUSTOM:
      return {
        ...(min_age_choice >= 18 && { age_min: min_age_choice }),
        ...(max_age_choice >= 18 && { age_max: max_age_choice })
      }
    default: {
      return {}
    }
  }
}

export const formatInterestsToBE = interests => {
  return interests.map(item => ({
    id: item.value,
    name: item.label
  }))
}

export const formatCategoriesToBE = parent_categories => {
  return parent_categories.map(item => ({
    id: item.value,
    name: item.label
  }))
}

export const formatLanguagesToBE = languages => {
  return languages.map(item => item.value)
}

export const formatFrequencyToBE = (frequency_option, frequency_impressions, frequency_days) => {
  switch (frequency_option) {
    case OPTION_CUSTOM:
      return {
        impressions: Number(frequency_impressions),
        event: 'impressions',
        days: Number(frequency_days)
      }
    case FREQUENCY_OPTION_DEFAULT:
    default:
      return {
        impressions: 2,
        event: 'impressions',
        days: 7
      }
  }
}

export const formatAudiencesToBE = audiences => {
  return audiences.map(item => ({
    id: item.value
  }))
}

export const getSelectedPlacementPositions = customPlacementPositions => {
  let selectedPlacementPositions = []

  for (let position in customPlacementPositions) {
    if (customPlacementPositions.hasOwnProperty(position) && customPlacementPositions[position]) {
      selectedPlacementPositions.push(position)
    }
  }

  return selectedPlacementPositions
}

export const formatPlacementToBE = (placementOption, customPlacementPositions, campaignObjective) => {
  switch (placementOption) {
    case PLACEMENT_OPTION_ALL_EXCLUDING_AUDIENCE:
      return {
        [PLACEMENT_POSITIONS]: [
          FACEBOOK_FEED,
          INSTAGRAM_STREAM,
          FACEBOOK_MARKETPLACE,

          ...insertIf(campaignObjective !== POST_ENGAGEMENT, INSTAGRAM_STORY, FACEBOOK_STORY),
          ...insertIf(
            campaignObjective !== STORE_VISITS,
            FACEBOOK_VIDEO_FEEDS,
            INSTAGRAM_EXPLORE,
            FACEBOOK_INSTREAM_VIDEO,
            FACEBOOK_SEARCH,
            FACEBOOK_INSTANT_ARTICLE
          ),
          ...insertIf(
            ![
              APP_INSTALLS,
              BRAND_AWARENESS,
              REACH,
              POST_ENGAGEMENT,
              VIDEO_VIEWS,
              LEAD_GENERATION,
              MESSAGES,
              STORE_VISITS
            ].includes(campaignObjective),
            FACEBOOK_RIGHT_HAND_COLUMN,
            INSTAGRAM_SHOP
          ),
          ...insertIf(
            ![BRAND_AWARENESS, REACH, POST_ENGAGEMENT, VIDEO_VIEWS, LEAD_GENERATION, STORE_VISITS].includes(
              campaignObjective
            ),
            MESSENGER_MESSENGER_HOME
          ),
          ...insertIf(
            ![POST_ENGAGEMENT, LEAD_GENERATION, PRODUCT_CATALOG_SALES, STORE_VISITS].includes(campaignObjective),
            MESSENGER_STORY
          ),
          ...insertIf(
            ![POST_ENGAGEMENT, LEAD_GENERATION, MESSAGES, PRODUCT_CATALOG_SALES, STORE_VISITS].includes(
              campaignObjective
            ),
            INSTAGRAM_REELS
          ),
          ...insertIf(
            ![
              APP_INSTALLS,
              BRAND_AWARENESS,
              REACH,
              LINK_CLICKS,
              POST_ENGAGEMENT,
              VIDEO_VIEWS,
              LEAD_GENERATION,
              MESSAGES,
              CONVERSIONS,
              PRODUCT_CATALOG_SALES
            ].includes(campaignObjective),
            MESSENGER_SPONSORED_MESSAGES
          )
        ]
      }
    case PLACEMENT_OPTION_NEWS_FEEDS_AND_STORIES:
      return {
        [PLACEMENT_POSITIONS]: [FACEBOOK_FEED, INSTAGRAM_STREAM, INSTAGRAM_STORY, FACEBOOK_STORY]
      }
    case PLACEMENT_OPTION_NEWS_FEEDS:
      return {
        [PLACEMENT_POSITIONS]: [FACEBOOK_FEED, INSTAGRAM_STREAM]
      }
    case PLACEMENT_OPTION_STORIES:
      return {
        [PLACEMENT_POSITIONS]: [INSTAGRAM_STORY, FACEBOOK_STORY]
      }
    case PLACEMENT_OPTION_INSTAGRAM:
      return {
        [PLACEMENT_POSITIONS]: [INSTAGRAM_STREAM, INSTAGRAM_STORY]
      }
    case PLACEMENT_OPTION_CUSTOM:
      return {
        [PLACEMENT_POSITIONS]: getSelectedPlacementPositions(customPlacementPositions)
      }
    default:
      return {}
  }
}

const getDestinationType = engagementType => {
  switch (engagementType) {
    case THRUPLAY:
      return ON_VIDEO
    case PAGE_LIKES:
      return ON_PAGE
    case POST_ENGAGEMENT:
    default:
      return ON_POST
  }
}

const formatOptimizationValuesToBE = ({ campaignObjective, optimizationType, engagementType }) => {
  switch (campaignObjective) {
    case OUTCOME_ENGAGEMENT:
      return {
        [OBJECTIVE]: engagementType,
        [DESTINATION_TYPE]: getDestinationType(engagementType)
      }
    case OUTCOME_TRAFFIC:
      return {
        [OBJECTIVE]: LANDING_PAGE_VIEWS,
        [DESTINATION_TYPE]: WEBSITE
      }
    case OUTCOME_AWARENESS:
      return {
        [OBJECTIVE]: optimizationType
      }
    default:
      return {}
  }
}

export const transformValuesToBE = (
  values,
  includeValuesInRequest,
  isCampaignBudgetExist,
  selectedAdAccountId,
  selectedSelfAccountId,
  selectedAdAccountTimezone,
  campaignId,
  campaignObjective,
  campaignBiddingType
) => {
  const {
    // todo need to refactor values destructuring here, because we lose constants benefits
    // RECOMMENDED SETUP
    recommended_setup_option,
    // START TIME
    [START_OPTION]: startOption,
    [START_DATE]: startDate,
    [START_TIME]: startTime,
    // STOP TIME
    [STOP_OPTION]: stopOption,
    [STOP_DATE]: stopDate,
    [STOP_TIME]: stopTime,
    // SCHEDULE
    schedule_option,
    schedule_monday,
    schedule_tuesday,
    schedule_wednesday,
    schedule_thursday,
    schedule_friday,
    schedule_saturday,
    schedule_sunday,
    schedule_dates,
    location,
    temperature,
    forecast_range: forecastRange,
    // BUDGET
    budget_option,
    budget_lifetime,
    budget_daily,
    // SPEND
    spend_option,
    min_spend_target_lifetime,
    spend_cap_lifetime,
    min_spend_target_daily,
    spend_cap_daily,
    // GEOLOCATIONS
    show_option,
    show_countries,
    show_country_choice,
    show_regions,
    [LOCATION_LIST]: locationList,
    // GENDER
    gender_option,
    // AGE
    age_option,
    min_age_years,
    max_age_years,
    min_age_choice,
    max_age_choice,
    // INTERESTS,
    interests_option,
    interests,
    // CATEGORIES
    parent_option,
    parent_categories,
    // LANGUAGES
    languages_option,
    languages,
    // FREQUENCY
    frequency_option,
    frequency_days,
    frequency_impressions,
    // AUDIENCES
    audiences_option,
    custom_audiences,
    custom_audiences_exclusions_checked,
    custom_audiences_exclusions,
    // BIDDING TYPE
    [BIDDING_TYPE]: lineItemBiddingType,
    // TARGET COST CAP
    target_cost_cap,
    roas_average_floor,
    objective,
    // PROMOTED OBJECT,
    promoted_pixel,
    custom_event_type,
    // PLACEMENT
    [PLACEMENT_OPTION]: placementOption,
    [FACEBOOK_FEED]: facebookFeed,
    [INSTAGRAM_STREAM]: instagramStream,
    [FACEBOOK_MARKETPLACE]: facebookMarketplace,
    [FACEBOOK_VIDEO_FEEDS]: facebookVideoFeeds,
    [FACEBOOK_RIGHT_HAND_COLUMN]: facebookRightHandColumn,
    [INSTAGRAM_EXPLORE]: instagramExplore,
    [INSTAGRAM_SHOP]: instagramShop,
    [MESSENGER_MESSENGER_HOME]: messengerHome,
    [INSTAGRAM_STORY]: instagramStory,
    [FACEBOOK_STORY]: facebookStory,
    [MESSENGER_STORY]: messengerStory,
    [INSTAGRAM_REELS]: instagramReels,
    [FACEBOOK_INSTREAM_VIDEO]: facebookInstreamVideo,
    [AUDIENCE_NETWORK_INSTREAM_VIDEO]: audienceNetworkInstreamVideo,
    [FACEBOOK_SEARCH]: facebookSearch,
    [MESSENGER_SPONSORED_MESSAGES]: messengerSponsoredMessages,
    [FACEBOOK_INSTANT_ARTICLE]: facebookInstantArticle,
    [AUDIENCE_NETWORK_CLASSIC]: audienceNetworkClassic,
    [AUDIENCE_NETWORK_REWARDED_VIDEO]: audienceNetworkRewardedVideo,

    //ENGAGEMENT_TYPE
    [ENGAGEMENT_TYPE]: engagementType,
    [OPTIMIZATION_TYPE]: optimizationType,

    ...purifiedValues
  } = values

  const formattedTimeStart = formatTimeStartToBE({ startOption, startDate, startTime })

  // BUDGET
  const includeBudget = includeValuesInRequest[BUDGET_OPTION]

  // SPEND
  const includeSpend = includeValuesInRequest[SPEND_OPTION]
  const isSpendLifetime = includeValuesInRequest[IS_SPEND_LIFETIME]
  const spendValues = formatSpendValuesToBE(
    isSpendLifetime,
    min_spend_target_lifetime,
    spend_cap_lifetime,
    min_spend_target_daily,
    spend_cap_daily
  )

  // PROMOTED OBJECT
  const includePromotedObject = includeValuesInRequest[PROMOTED_PIXEL]

  const isCampaignObjectiveConversionsOrSales = getIsCampaignObjectiveConversionsOrSales(campaignObjective)

  // if campaign is conversions/outcome_sales and doesn't have own bidding type,
  // use line item bidding type value selected on BiddingTypeStep
  const biddingTypeValue =
    isCampaignObjectiveConversionsOrSales && !campaignBiddingType ? lineItemBiddingType : campaignBiddingType

  return {
    ...purifiedValues,
    account: selectedAdAccountId,
    campaign_id: campaignId,
    campaign_objective: campaignObjective,
    ...(isCampaignObjectiveConversionsOrSales && !campaignBiddingType && { bidding_type: lineItemBiddingType }),
    ...(!isCampaignObjectiveConversionsOrSales && { bidding_type: AUTOMATIC }),
    time_start: formattedTimeStart,
    ...(values[STOP_OPTION] !== STOP_OPTION_INDEFINITELY && {
      time_stop: formatTimeStopToBE({ stopOption, stopDate, stopTime, timeStart: formattedTimeStart })
    }),
    ...formatScheduleToBE({
      schedule_option: values[SCHEDULE_OPTION],
      schedule_monday: values[SCHEDULE_MONDAY],
      schedule_tuesday: values[SCHEDULE_TUESDAY],
      schedule_wednesday: values[SCHEDULE_WEDNESDAY],
      schedule_thursday: values[SCHEDULE_THURSDAY],
      schedule_friday: values[SCHEDULE_FRIDAY],
      schedule_saturday: values[SCHEDULE_SATURDAY],
      schedule_sunday: values[SCHEDULE_SUNDAY],
      schedule_dates: values[SCHEDULE_DATES],
      location: values[LOCATION],
      temperature: values[TEMPERATURE],
      forecastRange: values[FORECAST_RANGE],
      selectedAdAccountTimezone,
      selectedSelfAccountId
    }),
    ...(includeBudget && values[BUDGET_OPTION] === BUDGET_LIFETIME && { budget_lifetime }),
    ...(includeBudget && values[BUDGET_OPTION] === BUDGET_DAILY && { budget_daily }),
    ...(includeSpend && values[SPEND_OPTION] === OPTION_CUSTOM && spendValues),
    // GEO_LOCATION
    ...(show_option === SHOW_OPTION_LOCATION_LIST && { [LOCATION_LIST]: locationList }),
    // GEO_TARGETING could be send within LOCATION_LIST
    [GEO_TARGETING]: formatShowTargetToBE(show_option, show_countries, show_regions),
    // todo remove hardcoded value when create step for this field
    billing_event: 'impressions',
    gender: formatGenderToBE(gender_option),
    age: formatAgeToBE(age_option, min_age_choice, max_age_choice),
    ...(values[INTERESTS_OPTION] === OPTION_YES && { interests: formatInterestsToBE(interests) }),
    ...(values[PARENT_OPTION] === OPTION_YES && { family_statuses: formatCategoriesToBE(parent_categories) }),
    ...(values[LANGUAGES_OPTION] === SELECT_LANGUAGES && { languages: formatLanguagesToBE(languages) }),
    ...(values[FREQUENCY_OPTION] !== FREQUENCY_OPTION_AUTOMATIC &&
      (campaignObjective === REACH || values[OPTIMIZATION_TYPE] === REACH) && {
        frequency: formatFrequencyToBE(frequency_option, frequency_impressions, frequency_days)
      }),
    ...(values[AUDIENCES_OPTION] === OPTION_YES && { custom_audiences: formatAudiencesToBE(custom_audiences) }),
    ...(values[CUSTOM_AUDIENCES_EXCLUSION_CHECKED] && {
      custom_audiences_exclusions: formatAudiencesToBE(custom_audiences_exclusions)
    }),
    ...((biddingTypeValue === MANUAL || biddingTypeValue === COST_CAP) && { target_cost_cap, objective }),
    ...(biddingTypeValue === MINIMUM_ROAS && { roas_average_floor, objective: 'value' }),
    ...(includePromotedObject && {
      promoted_object: {
        pixel_id: promoted_pixel,
        custom_event_type
      }
    }),
    ...formatOptimizationValuesToBE({
      campaignObjective,
      optimizationType,
      engagementType
    }),
    ...formatPlacementToBE(
      placementOption,
      {
        [FACEBOOK_FEED]: facebookFeed,
        [INSTAGRAM_STREAM]: instagramStream,
        [FACEBOOK_MARKETPLACE]: facebookMarketplace,
        [FACEBOOK_VIDEO_FEEDS]: facebookVideoFeeds,
        [FACEBOOK_RIGHT_HAND_COLUMN]: facebookRightHandColumn,
        [INSTAGRAM_EXPLORE]: instagramExplore,
        [INSTAGRAM_SHOP]: instagramShop,
        [MESSENGER_MESSENGER_HOME]: messengerHome,
        [INSTAGRAM_STORY]: instagramStory,
        [FACEBOOK_STORY]: facebookStory,
        [MESSENGER_STORY]: messengerStory,
        [INSTAGRAM_REELS]: instagramReels,
        [FACEBOOK_INSTREAM_VIDEO]: facebookInstreamVideo,
        [AUDIENCE_NETWORK_INSTREAM_VIDEO]: audienceNetworkInstreamVideo,
        [FACEBOOK_SEARCH]: facebookSearch,
        [MESSENGER_SPONSORED_MESSAGES]: messengerSponsoredMessages,
        [FACEBOOK_INSTANT_ARTICLE]: facebookInstantArticle,
        [AUDIENCE_NETWORK_CLASSIC]: audienceNetworkClassic,
        [AUDIENCE_NETWORK_REWARDED_VIDEO]: audienceNetworkRewardedVideo
      },
      campaignObjective
    ),
    ...placementSafetyRules
  }
}
