import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles({
  roleDescription: {
    '&:not(:last-child)': {
      marginBottom: 14
    }
  }
})

export default useStyles
