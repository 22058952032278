import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getCampaignInitialValues } from './helpers'

import { combinedCampaignsSelector } from '../../../modules/selectors/combinedData'

import { GOOGLE_PROVIDER_PLATFORM } from '../../../constants/selectLists/platformList'
import { CAMPAIGN_OPTION } from './UploadGoogleAdCreativeForm/CampaignForm/CampaignFormContent/fields'

export const useInitialCampaignTypeOption = () => {
  const combinedCampaigns = useSelector(combinedCampaignsSelector)
  const { campaigns = [] } = combinedCampaigns

  const googleCampaigns = useMemo(
    () => campaigns.filter(campaign => campaign.provider === GOOGLE_PROVIDER_PLATFORM && campaign.status !== 'deleted'),
    [campaigns]
  )

  const isCampaignsExist = !!googleCampaigns.length

  return getCampaignInitialValues(isCampaignsExist)?.[CAMPAIGN_OPTION]
}
