import { concat } from '../../helpers/common'

const MODULE_NAME = 'CHOICES/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_CHOICES_GEOLOCATIONS = concat(MODULE_NAME, 'GET_CHOICES_GEOLOCATIONS')
export const GET_CHOICES_GEOLOCATIONS_SUCCESS = concat(MODULE_NAME, 'GET_CHOICES_GEOLOCATIONS_SUCCESS')
export const GET_CHOICES_GEOLOCATIONS_FAILURE = concat(MODULE_NAME, 'GET_CHOICES_GEOLOCATIONS_FAILURE')

export const GET_CHOICES_COUNTRIES = concat(MODULE_NAME, 'GET_CHOICES_COUNTRIES')
export const GET_CHOICES_COUNTRIES_SUCCESS = concat(MODULE_NAME, 'GET_CHOICES_COUNTRIES_SUCCESS')
export const GET_CHOICES_COUNTRIES_FAILURE = concat(MODULE_NAME, 'GET_CHOICES_COUNTRIES_FAILURE')
export const CLEAR_CHOICES_COUNTRIES = concat(MODULE_NAME, 'CLEAR_CHOICES_COUNTRIES')

export const GET_CHOICES_INTERESTS = concat(MODULE_NAME, 'GET_CHOICES_INTERESTS')
export const GET_CHOICES_INTERESTS_SUCCESS = concat(MODULE_NAME, 'GET_CHOICES_INTERESTS_SUCCESS')
export const GET_CHOICES_INTERESTS_FAILURE = concat(MODULE_NAME, 'GET_CHOICES_INTERESTS_FAILURE')
export const CLEAR_CHOICES_INTERESTS = concat(MODULE_NAME, 'CLEAR_CHOICES_INTERESTS')

export const GET_CHOICES_CATEGORIES = concat(MODULE_NAME, 'GET_CHOICES_CATEGORIES')
export const GET_CHOICES_CATEGORIES_SUCCESS = concat(MODULE_NAME, 'GET_CHOICES_CATEGORIES_SUCCESS')
export const GET_CHOICES_CATEGORIES_FAILURE = concat(MODULE_NAME, 'GET_CHOICES_CATEGORIES_FAILURE')

export const GET_CHOICES_LANGUAGES = concat(MODULE_NAME, 'GET_CHOICES_LANGUAGES')
export const GET_CHOICES_LANGUAGES_SUCCESS = concat(MODULE_NAME, 'GET_CHOICES_LANGUAGES_SUCCESS')
export const GET_CHOICES_LANGUAGES_FAILURE = concat(MODULE_NAME, 'GET_CHOICES_LANGUAGES_FAILURE')

export const GET_CHOICES_PIXELS = concat(MODULE_NAME, 'GET_CHOICES_PIXELS')
export const GET_CHOICES_PIXELS_SUCCESS = concat(MODULE_NAME, 'GET_CHOICES_PIXELS_SUCCESS')
export const GET_CHOICES_PIXELS_FAILURE = concat(MODULE_NAME, 'GET_CHOICES_PIXELS_FAILURE')
export const CLEAR_CHOICES_PIXELS = concat(MODULE_NAME, 'CLEAR_CHOICES_PIXELS')

export const GET_CHOICES_CTA = concat(MODULE_NAME, 'GET_CHOICES_CTA')
export const GET_CHOICES_CTA_SUCCESS = concat(MODULE_NAME, 'GET_CHOICES_CTA_SUCCESS')
export const GET_CHOICES_CTA_FAILURE = concat(MODULE_NAME, 'GET_CHOICES_CTA_FAILURE')
export const CLEAR_CHOICES_CTA = concat(MODULE_NAME, 'CLEAR_CHOICES_CTA')

export const GET_CHOICES_CTA_BY_GOAL = concat(MODULE_NAME, 'GET_CHOICES_CTA_BY_GOAL')
export const GET_CHOICES_CTA_BY_GOAL_SUCCESS = concat(MODULE_NAME, 'GET_CHOICES_CTA_BY_GOAL_SUCCESS')
export const GET_CHOICES_CTA_BY_GOAL_FAILURE = concat(MODULE_NAME, 'GET_CHOICES_CTA_BY_GOAL_FAILURE')
export const CLEAR_CHOICES_CTA_BY_GOAL = concat(MODULE_NAME, 'CLEAR_CHOICES_CTA_BY_GOAL')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

export function getChoicesGeolocations(params) {
  return { type: GET_CHOICES_GEOLOCATIONS, params }
}

export function getChoicesGeolocationsSuccess(choicesData) {
  return { type: GET_CHOICES_GEOLOCATIONS_SUCCESS, choicesData }
}

export function getChoicesGeolocationsFailure(error) {
  return { type: GET_CHOICES_GEOLOCATIONS_FAILURE, error }
}

export function getChoicesCountries() {
  return { type: GET_CHOICES_COUNTRIES }
}

export function getChoicesCountriesSuccess(choicesData) {
  return { type: GET_CHOICES_COUNTRIES_SUCCESS, choicesData }
}

export function getChoicesCountriesFailure(error) {
  return { type: GET_CHOICES_COUNTRIES_FAILURE, error }
}

export function clearChoicesCountries() {
  return { type: CLEAR_CHOICES_COUNTRIES }
}

export function getChoicesInterests(params) {
  return { type: GET_CHOICES_INTERESTS, params }
}

export function getChoicesInterestsSuccess(choicesData) {
  return { type: GET_CHOICES_INTERESTS_SUCCESS, choicesData }
}

export function getChoicesInterestsFailure(error) {
  return { type: GET_CHOICES_INTERESTS_FAILURE, error }
}

export function clearChoicesInterests() {
  return { type: CLEAR_CHOICES_INTERESTS }
}

export function getChoicesCategories(params) {
  return { type: GET_CHOICES_CATEGORIES, params }
}

export function getChoicesCategoriesSuccess(choicesData) {
  return { type: GET_CHOICES_CATEGORIES_SUCCESS, choicesData }
}

export function getChoicesCategoriesFailure(error) {
  return { type: GET_CHOICES_CATEGORIES_FAILURE, error }
}

export function getChoicesLanguages(params) {
  return { type: GET_CHOICES_LANGUAGES, params }
}

export function getChoicesLanguagesSuccess(choicesData) {
  return { type: GET_CHOICES_LANGUAGES_SUCCESS, choicesData }
}

export function getChoicesLanguagesFailure(error) {
  return { type: GET_CHOICES_LANGUAGES_FAILURE, error }
}

export function getChoicesPixels(params) {
  return { type: GET_CHOICES_PIXELS, params }
}

export function getChoicesPixelsSuccess(choicesData) {
  return { type: GET_CHOICES_PIXELS_SUCCESS, choicesData }
}

export function getChoicesPixelsFailure(error) {
  return { type: GET_CHOICES_PIXELS_FAILURE, error }
}
export function clearChoicesPixels() {
  return { type: CLEAR_CHOICES_PIXELS }
}

// call to actions
export function getChoicesCTA(params) {
  return { type: GET_CHOICES_CTA, params }
}

export function getChoicesCTASuccess(choicesData) {
  return { type: GET_CHOICES_CTA_SUCCESS, choicesData }
}

export function getChoicesCTAFailure(error) {
  return { type: GET_CHOICES_CTA_FAILURE, error }
}

export function clearChoicesCTA() {
  return { type: CLEAR_CHOICES_CTA }
}

// call to actions by goal
export function getChoicesCTAByGoal(params) {
  return { type: GET_CHOICES_CTA_BY_GOAL, params }
}

export function getChoicesCTAByGoalSuccess(choicesData, goal) {
  return { type: GET_CHOICES_CTA_BY_GOAL_SUCCESS, choicesData, goal }
}

export function getChoicesCTAByGoalFailure(error) {
  return { type: GET_CHOICES_CTA_BY_GOAL_FAILURE, error }
}

export function clearChoicesCTAByGoal() {
  return { type: CLEAR_CHOICES_CTA_BY_GOAL }
}
