import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Can from '../../../features/components/Can'

import useManageFormsDrawer from '../../../hooks/formHooks/useManageFormsDrawer'
import useQuotationData from './useQuotationData'

import FormDrawerWrapper from '../../../features/components/FormDrawerWrapper'
import PdfPageSkeleton from '../../../features/components/Contract/ResponsiveContractPdfViewer/ContractPdfViewer/Skeleton/PdfPageSkeleton'
import QuotationReviewContent from './QuotationReviewContent'
import Button from '../../../components/Button'
import ContractButtonSection from './ContractButtonSection'

import { closeForm, openForm } from '../../../modules/actions/forms'

import { PROPOSAL_REVIEW, QUOTATION_SEND } from '../../../constants/forms'

import { SEND_QUOTATION_PERMISSION } from '../../../constants/permissions'

import useStyles from './styles'

const QuotationReview = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { t } = useTranslation()

  const { selectedEditItemId } = useManageFormsDrawer({
    formName: PROPOSAL_REVIEW
  })

  const { quotationContract, isDataLoading, handleClearSelectedContract } = useQuotationData(selectedEditItemId)
  const { quotation_sent: quotationSent } = quotationContract || {}

  const { quotation_status: quotationStatus } = quotationContract || {}
  const withoutRequirements = quotationContract?.without_requirements
  const allowSend = !withoutRequirements && !quotationSent

  const isQuotationExpired = quotationStatus === 'expired'

  const openSendQuotationDrawer = useCallback(() => {
    // Open send quotation drawer
    dispatch(closeForm())
    dispatch(openForm({ formName: QUOTATION_SEND, id: selectedEditItemId }))
  }, [dispatch, selectedEditItemId])

  const title = useMemo(() => {
    if (withoutRequirements) {
      return 'Review, and book'
    } else if (!isQuotationExpired) {
      return 'Review, sign and book'
    } else {
      // Don't show the title and description if the quotation is expired, we show warning message instead
      return ''
    }
  }, [isQuotationExpired, withoutRequirements])

  const description = useMemo(() => {
    if (withoutRequirements) {
      return 'Please review, and submit the booking contract.'
    } else if (!isQuotationExpired) {
      return 'Please review, sign and submit the booking contract.'
    } else {
      // Don't show the title and description if the quotation is expired, we show warning message instead
      return ''
    }
  }, [isQuotationExpired, withoutRequirements])

  return (
    <FormDrawerWrapper
      formName={PROPOSAL_REVIEW}
      title={title}
      description={description}
      showOpenButton={false}
      isFormLoading={isDataLoading}
      onAfterFormClose={handleClearSelectedContract}
      SkeletonFormComponent={<PdfPageSkeleton />}
      isWideDrawer
      drawerContentClassName={classes.quotationReviewDrawerContent}
    >
      <Can I="manage" a={SEND_QUOTATION_PERMISSION}>
        {allowSend && (
          <ContractButtonSection
            title={t('Send this quotation to the client signatory')}
            description={t('Request a signatory from a client signatory user, or send to any email address')}
          >
            <Button onClick={openSendQuotationDrawer}>Send Quotation</Button>
          </ContractButtonSection>
        )}
      </Can>
      <QuotationReviewContent quotationContract={quotationContract} />
    </FormDrawerWrapper>
  )
}

export default QuotationReview
