import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { redirectTo } from '../../../../helpers/url'

import { mediaOrdersSelector } from '../../../../modules/selectors/mediaOrders'

import { ROUTE_PARAMS, ROUTES } from '../../../../constants/routes'

export const useRedirectToMediaOrderSummary = () => {
  const mediaOrders = useSelector(mediaOrdersSelector)

  return useCallback(
    mediaOrderId => {
      const currentItem = mediaOrders?.find(item => item.id === mediaOrderId)
      redirectTo(ROUTES.mediaOrderSummary, {
        [ROUTE_PARAMS.selfAccount]: currentItem?.account,
        [ROUTE_PARAMS.mediaOrderId]: currentItem?.id
      })
    },
    [mediaOrders]
  )
}
