import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles({
  listImage: {
    maxWidth: '100%'
  },
  mobileHeaderStyle: {
    minHeight: 24
  }
})

export default useStyles
