import { useRef, useEffect } from 'react'

export function useDidMount() {
  // State and setters for debounced value
  const isMountRef = useRef(false)

  useEffect(() => {
    isMountRef.current = true
  }, [])
  return isMountRef.current
}
