import React, { useCallback } from 'react'

import FieldsSection from '../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../components/Form/Field'
import BudgetTabs from '../../../../../ReusableFormFields/BudgetTabs'
import CheckboxBlock from '../../../../../../features/components/Form/CheckboxBlock'
import MultiSelectBox from '../../../../../../features/components/Form/MultiSelectBox'

import { NAME, OBJECTIVE, specialAdCategoriesOptions } from '../../../../../ReusableFormFields/CampaignForms/fields'
import {
  optimizationGoalOptions,
  TIKTOK_TARGET_ROAS,
  TARGET_ROAS_CHECKBOX,
  TIKTOK_SPECIAL_AD_CATEGORIES,
  getShowBudgetSection
} from '../fields'

import useStyles from './styles'

const CampaignTikTokCreateFormContent = ({ formik }) => {
  const classes = useStyles()

  const { values, setFieldValue } = formik

  const targetRoasCheckbox = !!values[TARGET_ROAS_CHECKBOX]

  const showBudgetSection = getShowBudgetSection(values[OBJECTIVE])

  const targetRoasCheckboxHandler = useCallback(() => {
    setFieldValue(TARGET_ROAS_CHECKBOX, !targetRoasCheckbox)
  }, [targetRoasCheckbox, setFieldValue])

  return (
    <FieldsSection title="Campaign details">
      <FieldRow title="Name" description="Campaign name">
        <Field formik={formik} name={NAME} placeholder="Campaign Name" autoComplete="off" />
      </FieldRow>
      <FieldRow title="Optimisation goal" description="The goal of the campaign">
        <Field formik={formik} name={OBJECTIVE} placeholder="Optimisation goal" options={optimizationGoalOptions} />
      </FieldRow>
      {showBudgetSection && (
        <FieldRow
          title="Budget"
          description="Set a lifetime budget (runs for the whole lifetime of the campaign) or a daily budget"
        >
          <BudgetTabs formik={formik} />
        </FieldRow>
      )}
      <FieldRow title="Target ROAS" description="If checked, you can set a target return on ad spend (ROAS) goal">
        <CheckboxBlock
          id="tiktok_targer_roas_checkbox"
          checked={targetRoasCheckbox}
          title="Set a target ROAS"
          onCheck={targetRoasCheckboxHandler}
        >
          <Field
            className={classes.targetRoasInput}
            type="number"
            formik={formik}
            name={TIKTOK_TARGET_ROAS}
            placeholder="ROAS"
            autoComplete="off"
            inputMode="numeric"
          />
        </CheckboxBlock>
      </FieldRow>
      <FieldRow
        title="Special ad categories"
        description="If this campaign will advertise any special ad categories, select them here"
      >
        <MultiSelectBox
          placeholder="Special ad categories"
          setFieldValue={setFieldValue}
          name={TIKTOK_SPECIAL_AD_CATEGORIES}
          value={values[TIKTOK_SPECIAL_AD_CATEGORIES]}
          options={specialAdCategoriesOptions}
        />
      </FieldRow>
    </FieldsSection>
  )
}

export default CampaignTikTokCreateFormContent
