import { call, put, all, takeEvery } from 'redux-saga/effects'
import { race, take } from 'typed-redux-saga'

import {
  GET_TIK_TOK_REGIONS,
  getTikTokRegionsSuccess,
  getTikTokRegionsFailure,
  GET_TIK_TOK_PIXELS,
  getTikTokPixelsSuccess,
  getTikTokPixelsFailure,
  GET_TIKTOK_ACTION_CATEGORIES,
  getTikTokActionCategoriesFailure,
  getTikTokActionCategoriesSuccess,
  GET_TIKTOK_CUSTOM_AUDIENCES,
  getTikTokCustomAudiencesSuccess,
  getTikTokCustomAudiencesFailure,
  GET_TIKTOK_HASHTAGS,
  getTikTokHashtagsSuccess,
  getTikTokHashtagsFailure,
  GET_TIK_TOK_IDENTITIES,
  getTikTokIdentitiesSuccess,
  getTikTokIdentitiesFailure,
  CREATE_TIK_TOK_IDENTITY,
  createTikTokIdentitySuccess,
  createTikTokIdentityFailure,
  GET_TIKTOK_INTERESTS,
  getTikTokInterestsSuccess,
  getTikTokInterestsFailure,
  GET_TIK_TOK_VIDEO,
  getTikTokVideoSuccess,
  getTikTokVideoFailure,
  GET_TIK_TOK_VIDEO_THUMBNAILS,
  getTikTokVideoThumbnailsSuccess,
  getTikTokVideoThumbnailsFailure,
  CLEAR_TIK_TOK_VIDEO_THUMBNAILS
} from '../actions/tikTokConstants'

import {
  getTikTokRegionsService,
  getTikTokPixelsService,
  getTikTokActionCategoriesService,
  getTikTokCustomAudiencesService,
  getTikTokHashtagsService,
  getTikTokIdentitiesService,
  createTikTokIdentityService,
  getTikTokInterestsService,
  getTikTokVideoService,
  getTikTokVideoThumbnailsService
} from '../services/tikTokConstants'

function* tikTokConstantsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_TIK_TOK_REGIONS, getTikTokRegionsWorker),
    takeEvery(GET_TIK_TOK_PIXELS, getTikTokPixelsWorker),
    takeEvery(GET_TIK_TOK_IDENTITIES, getTikTokIdentitiesWorker),
    takeEvery(CREATE_TIK_TOK_IDENTITY, createTikTokIdentityWorker),
    takeEvery(GET_TIKTOK_INTERESTS, getTikTokInterestsWorker),
    takeEvery(GET_TIKTOK_ACTION_CATEGORIES, getTikTokActionCategoriesWorker),
    takeEvery(GET_TIKTOK_HASHTAGS, getTikTokHashtagsWorker),
    takeEvery(GET_TIKTOK_CUSTOM_AUDIENCES, getTikTokCustomAudiencesWorker),
    takeEvery(GET_TIK_TOK_VIDEO, getTikTokVideoWorker),
    takeEvery(GET_TIK_TOK_VIDEO_THUMBNAILS, getTikTokVideoThumbnailsWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getTikTokRegionsWorker({ params }) {
  try {
    const response = yield call(getTikTokRegionsService, params)
    yield put(getTikTokRegionsSuccess(response))
  } catch (error) {
    yield put(getTikTokRegionsFailure(error))
  }
}

function* getTikTokPixelsWorker({ params }) {
  try {
    const response = yield call(getTikTokPixelsService, params)
    yield put(getTikTokPixelsSuccess(response))
  } catch (error) {
    yield put(getTikTokPixelsFailure(error))
  }
}

function* getTikTokIdentitiesWorker({ params }) {
  try {
    const response = yield call(getTikTokIdentitiesService, params)
    yield put(getTikTokIdentitiesSuccess(response))
  } catch (error) {
    yield put(getTikTokIdentitiesFailure(error))
  }
}

function* createTikTokIdentityWorker({ data }) {
  try {
    const response = yield call(createTikTokIdentityService, data)
    // response is returned as just identity id, so we have to manually add data fields
    yield put(createTikTokIdentitySuccess({ ...data, ...response }))
  } catch (error) {
    yield put(createTikTokIdentityFailure(error))
  }
}

function* getTikTokInterestsWorker({ params }) {
  try {
    const response = yield call(getTikTokInterestsService, params)
    yield put(getTikTokInterestsSuccess(response))
  } catch (e) {
    yield put(getTikTokInterestsFailure(e))
  }
}

function* getTikTokActionCategoriesWorker({ params }) {
  try {
    const response = yield call(getTikTokActionCategoriesService, params)
    yield put(getTikTokActionCategoriesSuccess(response))
  } catch (e) {
    yield put(getTikTokActionCategoriesFailure(e))
  }
}

function* getTikTokHashtagsWorker({ params }) {
  try {
    const response = yield call(getTikTokHashtagsService, params)
    yield put(getTikTokHashtagsSuccess(response))
  } catch (e) {
    yield put(getTikTokHashtagsFailure(e))
  }
}

function* getTikTokCustomAudiencesWorker({ params }) {
  try {
    const response = yield call(getTikTokCustomAudiencesService, params)
    yield put(getTikTokCustomAudiencesSuccess(response))
  } catch (e) {
    yield put(getTikTokCustomAudiencesFailure(e))
  }
}

function* getTikTokVideoWorker({ params }) {
  try {
    const response = yield call(getTikTokVideoService, params)
    yield put(getTikTokVideoSuccess(response))
  } catch (error) {
    yield put(getTikTokVideoFailure(error))
  }
}

function* getTikTokVideoThumbnailsWorker({ params }) {
  try {
    const { response } = yield race({
      response: call(getTikTokVideoThumbnailsService, params),
      // need to cancel previous task, if user change the page, but task still in progress
      cancel: take(CLEAR_TIK_TOK_VIDEO_THUMBNAILS)
    })

    if (response) {
      yield put(getTikTokVideoThumbnailsSuccess(response))
    }
  } catch (error) {
    yield put(getTikTokVideoThumbnailsFailure(error))
  }
}

export default tikTokConstantsWatcher
