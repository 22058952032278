import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import useStyles from './styles'
import useCommonStyles from '../../../../../styles/common/stepForms'

const StepTitle = ({ stepTitle, headerFilter }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  if (stepTitle && headerFilter) {
    return (
      <div className={classes.stepTitleWithFilter}>
        <div className={commonClasses.stepTitle}>{t(stepTitle)}</div>
        {headerFilter}
      </div>
    )
  }

  if (stepTitle) {
    return <div className={commonClasses.stepTitle}>{t(stepTitle)}</div>
  }

  if (headerFilter) {
    return <div className={classes.filterWrapper}>{headerFilter}</div>
  }

  return null
}

StepTitle.propTypes = {
  stepTitle: PropTypes.string,
  headerFilter: PropTypes.node
}

export default StepTitle
