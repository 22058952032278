import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import ShowSkeleton from './ShowSkeleton'

import { FB_PAGE_CHOICE } from '../../../../forms/Facebook/AdForms/fields'

import { accessibleFacebookPagesSelector } from '../../../../modules/selectors/socialAccounts'

import useStyles from './styles'

function PageName({ elementInProgress, formikValues }) {
  const classes = useStyles()

  const facebookPages = useSelector(accessibleFacebookPagesSelector)

  const pageId = formikValues[FB_PAGE_CHOICE]
  const page = facebookPages?.find(page => page.id === pageId)
  const pageName = page?.name

  const pageNameInProgress = elementInProgress === FB_PAGE_CHOICE

  if (pageName) {
    return pageName
  } else {
    return <ShowSkeleton isInProgress={pageNameInProgress} count={1} className={classes.skeletonPageName} />
  }
}

PageName.propTypes = {
  elementInProgress: PropTypes.string,
  formikValues: PropTypes.object
}

export default PageName
