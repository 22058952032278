import {
  AGE_13_17,
  AGE_18_24,
  AGE_25_34,
  AGE_35_44,
  AGE_45_54,
  AGE_55_100
} from '../../../../../ReusableFormFields/AgeRangesFields/fields'
import { GENDER_FEMALE, GENDER_MALE } from '../../../LineItemTikTokCreate/LineItemTikTokCreateForm/fields'

// Function to transform age ranges to preview text. Example output:
// Input: ['AGE_18_24', 'AGE_35_44', 'AGE_55_100']
// Output: "18-24, 35-44, 55+"
export const getAgeRangePreviewText = selectedRanges => {
  // return 'None' if selectedRanges is empty array or null
  if (!selectedRanges || !selectedRanges.length) {
    return 'No Limit'
  }

  // Define the age numbers for each age range
  const ageNumbers = {
    [AGE_13_17]: [13, 17],
    [AGE_18_24]: [18, 24],
    [AGE_25_34]: [25, 34],
    [AGE_35_44]: [35, 44],
    [AGE_45_54]: [45, 54],
    [AGE_55_100]: [55, 100]
  }

  let result = ''
  let rangeStart = ageNumbers[selectedRanges[0]][0]
  let prevRangeEnd = ageNumbers[selectedRanges[0]][1]

  selectedRanges.forEach((range, i) => {
    // Skip the first age range as it's already set as the range start
    if (i === 0) return

    // Get the current age range start and end values
    const [currentRangeStart, currentRangeEnd] = ageNumbers[range]

    // Check if the current range start is the previous range end plus 1
    if (currentRangeStart === prevRangeEnd + 1) {
      // Update the previous range end value
      prevRangeEnd = currentRangeEnd
    } else {
      // Add the current range to the result string
      result += rangeStart !== prevRangeEnd ? `${rangeStart}-${prevRangeEnd}, ` : `${rangeStart}, `

      // Update the range start and previous range end values
      rangeStart = currentRangeStart
      prevRangeEnd = currentRangeEnd
    }
  })

  // Add the last age range to the result string
  if (prevRangeEnd === 100) {
    result += `${rangeStart}-55+`
  } else if (rangeStart !== prevRangeEnd) {
    result += `${rangeStart}-${prevRangeEnd}`
  } else {
    result += `${rangeStart}`
  }

  return result
}

export const getGenderPreviewText = gender => {
  if (gender === GENDER_MALE) {
    return 'Male'
  } else if (gender === GENDER_FEMALE) {
    return 'Female'
  } else {
    return 'All'
  }
}
