import { createStyles } from '../../../styles/helpers'

import { cardWithBorder } from '../../../styles/mixins/common'
import { facebookBlue, googleBlue, lightGrey } from '../../../styles/const/colors'
import { FACEBOOK_PLATFORM, GOOGLE_PLATFORM } from '../../../constants/selectLists/platformList'
import { phonesDown } from '../../../styles/const/breakpoints'

const defineCardColor = ({ isInactive, platform, mainColor }) => {
  if (isInactive) {
    return lightGrey
  } else {
    switch (platform) {
      case FACEBOOK_PLATFORM:
        return facebookBlue
      case GOOGLE_PLATFORM:
        return googleBlue
      default:
        return mainColor
    }
  }
}

export default createStyles(theme => ({
  card: {
    extend: cardWithBorder(theme.brandPrimary, theme),
    '&:before': {
      background: ({ isInactive, platform, mainColor = theme.brandPrimary }) =>
        defineCardColor({ isInactive, platform, mainColor })
    }
  },
  [`@media screen and (${phonesDown})`]: {
    card: {
      display: 'block',
      flexWrap: 'wrap',
      padding: 16,
      '&:before': {
        display: 'none'
      }
    }
  }
}))
