import React, { useCallback, useEffect } from 'react'
import history from '../../../../history'
import { useDispatch, useSelector } from 'react-redux'

import DownloadPdfButton from '../../../../features/components/PdfGeneration/DownloadPdfButton'

import { createJsonFromQueryString } from '../../../../helpers/url'

import { getContract } from '../../../../modules/actions/contracts'
import { contractSelector, getContractIsLoadingSelector } from '../../../../modules/selectors/contracts'

import useStyles from './styles'

function DownloadContract({ contractId }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const contract = useSelector(contractSelector)
  const contractIsLoading = useSelector(getContractIsLoadingSelector)
  const contractFile = contract?.file

  // token param is used to provide auth access to not registered users
  const { token } = createJsonFromQueryString(history.location.search, false)

  const handleFileDownload = useCallback(() => {
    // contract is using private file which has token in url, when user want to download file we need to get new url with token
    // useGetAccessibleUrl is not working in this case, as our user is not registered and token doesn't work for
    // getAccessibleUrl due to security reasons

    dispatch(getContract(contractId, { token }))
  }, [dispatch, contractId, token])

  useEffect(() => {
    if (contractFile) {
      window.open(contractFile)
    }
  }, [contractFile])

  return (
    <DownloadPdfButton
      className={classes.downloadBtn}
      onClick={handleFileDownload}
      isLoading={contractIsLoading}
      text="Download Signed Copy"
    />
  )
}

export default DownloadContract
