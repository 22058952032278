export const STATUS_AUTHORISED = 'authorised'
export const STATUS_DRAFT = 'draft'
export const STATUS_INVOICED = 'invoiced'
export const STATUS_OVERDUE = 'overdue'
export const STATUS_PAID = 'paid'
export const STATUS_PENDING = 'pending'
export const STATUS_READY = 'ready'
export const STATUS_SUBMITTED = 'submitted'
export const STATUS_WITH_ISSUES = 'with_issues'
export const STATUS_UNKNOWN = 'unknown'

export const statusFilterOptions = t => [
  {
    label: t('Authorised'),
    value: STATUS_AUTHORISED
  },
  {
    label: t('Draft'),
    value: STATUS_DRAFT
  },
  {
    label: t('Invoiced'),
    value: STATUS_INVOICED
  },
  {
    label: t('Overdue'),
    value: STATUS_OVERDUE
  },
  {
    label: t('Paid'),
    value: STATUS_PAID
  },
  {
    label: t('Pending'),
    value: STATUS_PENDING
  },
  {
    label: t('Ready'),
    value: STATUS_READY
  },
  {
    label: t('Submitted'),
    value: STATUS_SUBMITTED
  },
  {
    label: t('With issues'),
    value: STATUS_WITH_ISSUES
  },
  {
    label: t('Unknown'),
    value: STATUS_UNKNOWN
  }
]
