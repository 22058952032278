import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import GoogleAssetGroupMainForm from '../../GoogleAssetGroupMainForm'
import GoogleAssetGroupAudienceForm from '../../GoogleAssetGroupAudienceForm'
import FormWrapper from '../../../../../../features/components/Form/FormWrapper'

import useContinuousForm from '../../../../../../hooks/formHooks/useContinuousForm'
import useIsInternalAdCreation from '../../../../../../features/hooks/useIsInternalAdCreation'
import { useFormProgressItems } from './useFormProgressItems'

export const MAIN_FORM = 'main_form'
export const AUDIENCE_FORM = 'audience_form'

const GoogleAssetGroupForm = ({ onSuccessSubmit, adAccountId }) => {
  const [assetGroupCreatedData, setAssetGroupCreatedData] = useState({})

  const isInternalAssetGroupCreation = useIsInternalAdCreation()

  const { activatedForm, setActivatedForm } = useContinuousForm({
    initialForm: MAIN_FORM
  })
  const formProgressItems = useFormProgressItems({ activatedForm })

  const onSuccessMainFormSubmit = useCallback(
    assetGroupData => {
      setAssetGroupCreatedData(assetGroupData)
      setActivatedForm(AUDIENCE_FORM)
    },
    [setActivatedForm]
  )

  const getActivatedForm = useCallback(() => {
    switch (activatedForm) {
      case AUDIENCE_FORM:
        return (
          <GoogleAssetGroupAudienceForm
            assetGroupCreatedData={assetGroupCreatedData}
            isInternalAssetGroupCreation={isInternalAssetGroupCreation}
            onSuccessSubmit={onSuccessSubmit}
            adAccountId={adAccountId}
          />
        )
      case MAIN_FORM:
      default:
        return (
          <GoogleAssetGroupMainForm
            isInternalAssetGroupCreation={isInternalAssetGroupCreation}
            onSuccessSubmit={onSuccessMainFormSubmit}
            adAccountId={adAccountId}
          />
        )
    }
  }, [
    activatedForm,
    onSuccessMainFormSubmit,
    assetGroupCreatedData,
    isInternalAssetGroupCreation,
    onSuccessSubmit,
    adAccountId
  ])

  return <FormWrapper formProgressItems={formProgressItems}>{getActivatedForm()}</FormWrapper>
}

GoogleAssetGroupForm.propTypes = {
  onSuccessSubmit: PropTypes.func,
  adAccountId: PropTypes.string.isRequired
}

export default GoogleAssetGroupForm
