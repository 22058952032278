import { useSelector } from 'react-redux'
import { add } from 'date-fns'

import { selectedControllerDataSelector } from '../../modules/selectors/app'

import { formatDateDetailed } from '../../constants/dates'

export const useCalculateQuotationValidDate = quotationCreateDate => {
  const selectedController = useSelector(selectedControllerDataSelector)

  if (!!quotationCreateDate) {
    const { contract_quotation: contractQuotationHours = 0 } = selectedController || {}

    const createDate = new Date(quotationCreateDate)
    const expirationDate = add(createDate, { hours: contractQuotationHours })

    return formatDateDetailed(expirationDate)
  } else {
    return ''
  }
}
