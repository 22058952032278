import { createStyles } from '../../../../../styles/helpers'
import { textEllipsis } from '../../../../../styles/mixins/text'
import { phonesDown } from '../../../../../styles/const/breakpoints'

const moreProductsSectionWidth = '60px'

const useStyles = createStyles({
  mediaFieldWrapper: {
    width: '100%',
    display: 'flex'
  },
  mediaField: {
    ...textEllipsis(),
    maxWidth: moreProductsText => (moreProductsText ? `calc(100% - ${moreProductsSectionWidth})` : '100%')
  },
  moreProductsText: {
    marginLeft: 3
  },

  [`@media screen and (${phonesDown})`]: {
    mediaFieldWrapper: {
      justifyContent: 'flex-end'
    },
    mediaField: {
      maxWidth: '100%'
    }
  }
})

export default useStyles
