import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'

import LineItemStep from '../ReusableForms/LineItemStep'

import { lineItemsSelector } from '../../../../../modules/selectors/lineItems'
import { setMediaOrderUploadCreative } from '../../../../../modules/actions/mediaOrders'

import {
  LINE_ITEM,
  LINE_ITEM_OPTION,
  OPTION_EXISTING_LINE_ITEM
} from './AdvancedSetupRoute/LineItemAdvancedForm/LineItemAdvancedFormContent/fields'

export const useAllowExistingLineItems = lineItems => {
  const [isFirstLineItemCreation, setIsFirstLineItemCreation] = useState(false)

  const isLineItemsExist = !!lineItems.length
  const showLineItemSelectionStep = isLineItemsExist && !isFirstLineItemCreation

  // separate state to prevent bug, when LineItemStep becomes visible after first line item creation
  useEffect(() => {
    if (!lineItems.length) {
      setIsFirstLineItemCreation(true)
    }
  }, [lineItems.length, setIsFirstLineItemCreation])

  return useMemo(() => ({ isLineItemsExist, showLineItemSelectionStep }), [isLineItemsExist, showLineItemSelectionStep])
}

export const useManageExistingLineItemFormStep = ({ values, newLineItemSteps, onLineItemOptionChange }) => {
  // In some forms, we allow to select existing line item, and not create new one,
  // so we need to show only line item selection step
  // This hook helps to manage which steps to show, only 1 existing line item step or new line item steps
  const lineItems = useSelector(lineItemsSelector)
  const { showLineItemSelectionStep } = useAllowExistingLineItems(lineItems)

  const lineItemOption = values[LINE_ITEM_OPTION]
  const showOnlyLineItemStep = values[LINE_ITEM_OPTION] === OPTION_EXISTING_LINE_ITEM

  const steps = useMemo(() => {
    return [
      ...(showLineItemSelectionStep ? [{ component: <LineItemStep />, show: true }] : []),
      ...(!showOnlyLineItemStep ? newLineItemSteps : [])
    ]
  }, [showLineItemSelectionStep, showOnlyLineItemStep, newLineItemSteps])

  useEffect(() => {
    if (lineItemOption) {
      onLineItemOptionChange(lineItemOption)
    }
  }, [lineItemOption, onLineItemOptionChange])

  return steps
}

export const useManageExistingLineItemFormSubmit = ({ onSubmit, setSuccessSubmit }) => {
  // This hook helps to manage form submit, when user selects existing line item
  // If user selects existing line item, we need to set line item data to redux state and setSuccessSubmit(true)
  const dispatch = useDispatch()
  const lineItems = useSelector(lineItemsSelector)

  return useCallback(
    values => {
      if (values[LINE_ITEM_OPTION] === OPTION_EXISTING_LINE_ITEM) {
        const selectedLineItemFullData = lineItems.find(lineItem => values[LINE_ITEM] === lineItem.id)

        dispatch(setMediaOrderUploadCreative({ lineItem: selectedLineItemFullData }))

        setSuccessSubmit(true)
      } else {
        onSubmit(values)
      }
    },
    [dispatch, lineItems, onSubmit, setSuccessSubmit]
  )
}
