import React, { useCallback } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import EditForm from '../../../../../../../features/components/Forms/EditForm'
import Field from '../../../../../../../components/Form/Field'

import { usePurifiedFormik } from '../../../../../../../hooks/formHooks/usePurifiedFormik'

import { updateAssetGroup } from '../../../../../../../modules/actions/assets'
import { selectedAdAccountIdSelector } from '../../../../../../../modules/selectors/app'

import { CLICK_THROUGH_LINK } from '../../../../fields'
import { getInitialValues, validationSchema } from './fields'

const LinkSectionForm = ({ editItemData, isInternalAssetGroup, ...formProps }) => {
  const dispatch = useDispatch()

  const { id: assetGroupId, account } = editItemData

  const selectedAdAccount = useSelector(selectedAdAccountIdSelector)
  const adAccount = account || selectedAdAccount

  const handleSubmit = useCallback(
    values => {
      dispatch(
        updateAssetGroup(
          {
            account: adAccount,
            [CLICK_THROUGH_LINK]: [values[CLICK_THROUGH_LINK]],
            ...(isInternalAssetGroup && { internal: true })
          },
          assetGroupId
        )
      )
    },
    [dispatch, assetGroupId, adAccount, isInternalAssetGroup]
  )

  const initialValues = getInitialValues(editItemData)

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema
  })

  const purifiedFormik = usePurifiedFormik(formik)

  return (
    <EditForm formik={purifiedFormik} initialValues={initialValues} {...formProps}>
      <Field formik={formik} name={CLICK_THROUGH_LINK} placeholder="Click Through Link" autoComplete="off" />
    </EditForm>
  )
}

export default LinkSectionForm
