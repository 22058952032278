import { createStyles } from '../../../styles/helpers'

import { lightGrey } from '../../../styles/const/colors'

const useStyles = createStyles({
  quotationReviewDrawerContent: {
    backgroundColor: lightGrey
  },
  requestSignatoryDescription: {
    fontSize: 12,
    marginTop: [8, '!important']
  },
  sendQuotationSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

export default useStyles
