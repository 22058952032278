import { initialLink, STATUS } from '../../../../Facebook/AdForms/fields'

import { getInitialTextList } from '../../../../ReusableFormFields/MultipleOptionsField/helpers'

import { HEADLINES_LIST, DESCRIPTIONS_LIST, BUSINESS_NAME, initialGoogleFile, CLICK_THROUGH_LINK } from '../../fields'
import { CALL_TO_ACTION, LEARN_MORE } from '../../../../ReusableFormFields/CallToActionFields/fields'
import { ASSET_IMAGE_LIST } from '../../GoogleAssetGroup/GoogleAssetGroupMainForm/fields'
import {
  initialBrandCategoryValues,
  initialProductValues,
  PRODUCT_OPTION,
  PRODUCT_OPTION_BRAND_CATEGORY_PAGES
} from '../../../../ReusableFormFields/ProductsAndPagesFields/fields'

export const LONG_HEADLINE = 'long_headline'

export const initialValues = {
  // Product selection step
  [PRODUCT_OPTION]: PRODUCT_OPTION_BRAND_CATEGORY_PAGES,
  ...initialBrandCategoryValues,
  ...initialProductValues,

  [BUSINESS_NAME]: '',
  [HEADLINES_LIST]: getInitialTextList(3, ''),
  [DESCRIPTIONS_LIST]: getInitialTextList(2, ''),
  [LONG_HEADLINE]: '',
  [ASSET_IMAGE_LIST]: [initialGoogleFile],
  [CALL_TO_ACTION]: LEARN_MORE,
  [CLICK_THROUGH_LINK]: initialLink,
  [STATUS]: 'paused'
}
