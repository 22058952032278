import { MEDIA_SUB_CATEGORIES, ROLE } from './fields'
import { ADD_TO_ALL_CONTROLLER_ACCOUNTS, BRAND_CATEGORY, SELF_ACCOUNTS } from '../../fields'

import { getAllowSelfAccounts, getShowBrandCategorySelect, getShowMediaSubCategories } from '../../helpers'

export const transformValuesToBE = values => {
  const { email, role, accounts } = values
  const allowSelfAccounts = getAllowSelfAccounts(values[ROLE])
  const showBrandCategorySelect = getShowBrandCategorySelect(values[ROLE])
  const accessToAllControllerAccounts = values[ADD_TO_ALL_CONTROLLER_ACCOUNTS]
  const showMediaSubCategories = getShowMediaSubCategories(values[ROLE])

  return {
    email,
    role,
    ...(allowSelfAccounts && {
      ...(accessToAllControllerAccounts
        ? { [ADD_TO_ALL_CONTROLLER_ACCOUNTS]: true }
        : { [SELF_ACCOUNTS]: accounts.map(account => account.id) })
    }),
    ...(showBrandCategorySelect && { [BRAND_CATEGORY]: values[BRAND_CATEGORY] }),
    ...(showMediaSubCategories && { [MEDIA_SUB_CATEGORIES]: values[MEDIA_SUB_CATEGORIES].map(category => category.id) })
  }
}
