import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles({
  mobileHeaderStyle: {
    minHeight: 24
  },
  chipMobileField: {
    marginRight: 0
  }
})

export default useStyles
