import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import InternalLink from '../../../components/InternalLink'

import MobileTabsSelect from './MobileTabsSelect'

import { phonesDownSize } from '../../../styles/const/breakpoints'

import useStyles from './styles'

const ContentTabs = ({ tabs, subPagePath, rightSideChildren }) => {
  const classes = useStyles()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const visibleContentTabs = useMemo(() => tabs.filter(tab => !!tab.show), [tabs])

  const defaultTab = useMemo(() => visibleContentTabs[0], [visibleContentTabs])

  const selectedRouteTab = useMemo(
    () => visibleContentTabs.find(tab => tab.pathParam === subPagePath) || defaultTab,
    [visibleContentTabs, defaultTab, subPagePath]
  )

  const showContentTabsSelection = visibleContentTabs.length > 1

  const isSelectedTab = tab => {
    if (selectedRouteTab === defaultTab) {
      return tab === defaultTab
    }
    return tab.pathParam === subPagePath
  }

  return (
    <>
      {showContentTabsSelection &&
        (isMobile ? (
          <>
            <MobileTabsSelect
              visibleContentTabs={visibleContentTabs}
              subPagePath={subPagePath}
              defaultTab={defaultTab}
            />
            {rightSideChildren && (
              <div className={classes.rightSideWrapper}>
                <div className={classes.rightSide}>{rightSideChildren}</div>
              </div>
            )}
          </>
        ) : (
          <div className={classes.tabsWrapper}>
            <div className={classes.tabsContainer}>
              <div className={classes.tabsRow}>
                {visibleContentTabs.map((tab, index) => (
                  <InternalLink
                    key={index}
                    to={tab.path}
                    className={classnames(classes.tabItem, {
                      selected: isSelectedTab(tab)
                    })}
                  >
                    {tab.title}
                  </InternalLink>
                ))}
              </div>
              {rightSideChildren && <div className={classes.rightSide}>{rightSideChildren}</div>}
            </div>
          </div>
        ))}

      {!showContentTabsSelection && rightSideChildren && (
        <div className={classes.rightSideWrapper}>
          <div className={classes.rightSide}>{rightSideChildren}</div>
        </div>
      )}

      <div className={classes.contentWrapper}>
        <div className={classes.contentContainer}>{selectedRouteTab?.component}</div>
      </div>
    </>
  )
}

ContentTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      mobileTitle: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      pathParam: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
      show: PropTypes.bool.isRequired
    })
  ),
  subPagePath: PropTypes.string,
  rightSideChildren: PropTypes.node
}

export default ContentTabs
