import { createStyles } from '../../../../../../../styles/helpers'

import { card } from '../../../../../../../styles/common'

export default createStyles(theme => ({
  mediaCard: {
    extend: card(theme),
    padding: 10,
    marginTop: 20
  },
  mediaTitle: {
    margin: '0'
  },
  filePreview: {
    margin: '12px 0'
  }
}))
