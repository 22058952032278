import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import FieldsSection from '../../../../../../features/components/Form/FieldsSection'
import ActionText from '../../../../../../components/ActionText'
import AssetGroupDetails from './AssetGroupDetails'
import AdDetails from './AdDetails'
import FieldRow from '../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../components/Form/Field'

import { useAiSuggestionsData } from '../../../../../Facebook/UploadFacebookAdCreative/UploadFacebookAdCreativeContent/ReusableForms/AdProductForm/useAiSuggestionsData'

import {
  googleAdSuggestionsIsLoadingSelector,
  googleAdSuggestionsSelector
} from '../../../../../../modules/selectors/tools'
import { selectedSelfAccountDataSelector } from '../../../../../../modules/selectors/app'

import { BUSINESS_NAME, DESCRIPTIONS_LIST, HEADLINES_LIST, LONG_HEADLINES_LIST } from '../../../fields'
import { SUGGESTIONS_GOOGLE_AD } from '../../../../../../constants/tools'

import { PRODUCT_DATA } from '../../../../../ReusableFormFields/ProductFields/fields'
import { LONG_HEADLINE } from '../../../GoogleDisplayAd/DisplayAdGoogleCreateForm/fields'
import { BRAND_CATEGORY_PAGE_DATA } from '../../../../../ReusableFormFields/ProductsAndPagesFields/fields'

const defaultProductObject = {}

function AdCreative({
  formik,
  adAccountId,
  showImagesSection,
  isAssetGroup,
  showNameField,
  showCustomClickThroughLink
}) {
  const suggestions = useSelector(googleAdSuggestionsSelector)
  const suggestionsLoading = useSelector(googleAdSuggestionsIsLoadingSelector)
  const { brands } = useSelector(selectedSelfAccountDataSelector)

  const formattedBrandNames = useMemo(
    () => brands?.map(brand => ({ value: brand.brand_name, label: brand.brand_name })) || [],
    [brands]
  )

  const { values } = formik

  const productData = useMemo(() => {
    if (values[PRODUCT_DATA]?.id) {
      return values[PRODUCT_DATA]
    } else if (values[BRAND_CATEGORY_PAGE_DATA]?.id) {
      return values[BRAND_CATEGORY_PAGE_DATA]
    }
    return defaultProductObject
  }, [values])

  const headlinesList = values[HEADLINES_LIST]
  const descriptionList = values[DESCRIPTIONS_LIST]

  const longHeadlineList = isAssetGroup
    ? // on asset group form we have long headlines list
      values[LONG_HEADLINES_LIST]
    : // on ad form we have only 1 long headline
      [{ text: values[LONG_HEADLINE], id: 1 }]

  const groupedSuggestions = {
    headline: [],
    description: [],
    long_headline: []
  }

  suggestions?.forEach(suggestion => {
    groupedSuggestions.headline.push(suggestion.headline)
    groupedSuggestions.description.push(suggestion.description)
    groupedSuggestions.long_headline.push(suggestion.long_headline)
  })

  const filteredGroupedSuggestions = {
    headline: groupedSuggestions.headline.filter(item => !headlinesList.some(headline => headline.text === item)),
    description: groupedSuggestions.description.filter(
      item => !descriptionList.some(description => description.text === item)
    ),
    long_headline: groupedSuggestions.long_headline.filter(
      item => !longHeadlineList.some(longHeadline => longHeadline.text === item)
    )
  }

  const { regenerateSuggestions } = useAiSuggestionsData({
    selectedProduct: productData,
    allowLoadSuggestions: !!productData?.name,
    suggestionsSlug: SUGGESTIONS_GOOGLE_AD
  })

  return (
    <FieldsSection
      title="Creative assets"
      rightColumn={
        !suggestionsLoading && (
          <ActionText onClick={regenerateSuggestions} isDark>
            Regenerate content
          </ActionText>
        )
      }
    >
      {!!formattedBrandNames.length && (
        <FieldRow title="Brand Name">
          <Field
            formik={formik}
            name={BUSINESS_NAME}
            placeholder="Business name"
            options={formattedBrandNames}
            preselectFirstOptionValue
          />
        </FieldRow>
      )}

      {isAssetGroup ? (
        <AssetGroupDetails
          groupedSuggestions={filteredGroupedSuggestions}
          showImagesSection={showImagesSection}
          adAccountId={adAccountId}
          formik={formik}
          showNameField={showNameField}
          showCustomClickThroughLink={showCustomClickThroughLink}
        />
      ) : (
        <AdDetails
          groupedSuggestions={filteredGroupedSuggestions}
          showImagesSection={showImagesSection}
          adAccountId={adAccountId}
          formik={formik}
          showNameField={showNameField}
          showCustomClickThroughLink={showCustomClickThroughLink}
        />
      )}
    </FieldsSection>
  )
}

AdCreative.propTypes = {
  adAccountId: PropTypes.string,
  showImagesSection: PropTypes.bool,
  isAssetGroup: PropTypes.bool
}

export default AdCreative
