import React from 'react'

import Skeleton from 'react-loading-skeleton'
import AdCardListItem from '../../../../../features/components/AdCardListItem'
import SkeletonAdCard from '../../../../../features/components/FacebookPreviews/AdCard/Skeleton'
import SkeletonInternalAdActionButtons from '../../../../AdApprovals/InternalAdActionButtons/Skeleton'
import SkeletonAdCardDetails from '../../../../../features/components/AdCardListItem/AdCardDetails/Skeleton'
import AdCardMetrics from '../../../../../features/components/AdCardListItem/AdCardMetrics'
import AdCardMetricSkeleton from '../../../../../features/components/AdCardListItem/AdCardMetrics/AdCardMetricContainer/AdCardMetricSkeleton'

import useStyles from './styles'
import usePreviewStyles from './AdCardPreview/styles'

function SkeletonFacebookAdCardListItem({
  showStatus = true,
  showDetails = true,
  showMetrics = true,
  showActionsButtons = false,
  isAdApprovalsStyle = false
}) {
  const classes = useStyles()
  const previewClasses = usePreviewStyles({ isAdApprovalsStyle })

  return (
    <AdCardListItem>
      <div className={classes.cardHeader}>
        {showStatus && (
          <div className={classes.statusChip} style={{ width: 62 }}>
            <Skeleton />
          </div>
        )}
      </div>
      <div className={previewClasses.adPreviewWrapper} style={{ height: isAdApprovalsStyle ? 340 : 195 }}>
        <SkeletonAdCard className={previewClasses.adPreview} />
      </div>
      {showDetails && <SkeletonAdCardDetails />}
      {showMetrics && (
        <AdCardMetrics>
          <AdCardMetricSkeleton />
          <AdCardMetricSkeleton />
          <AdCardMetricSkeleton />
          <AdCardMetricSkeleton />
          <AdCardMetricSkeleton />
        </AdCardMetrics>
      )}
      {showActionsButtons && <SkeletonInternalAdActionButtons />}
    </AdCardListItem>
  )
}

export default SkeletonFacebookAdCardListItem
