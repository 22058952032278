import { getAllMediaOrderProducts } from '../SelectedMediaProductsContext/helpers'
import { REPRESENTATIVE } from '../../../../../../../constants/mediaOrders'
import { PRODUCT_PERIODS_DATES, QUANTITY } from '../../../../fields'

export const SELECTED_PRODUCTS = 'selected_products'
export const PRICE = 'price'

function formatMediaOrderProductsToFE(products) {
  if (!!products?.length) {
    return products.map(product => ({
      id: product.id,
      [PRODUCT_PERIODS_DATES]: product?.prices || [],
      [QUANTITY]: product[QUANTITY],
      [REPRESENTATIVE]: product[REPRESENTATIVE]
    }))
  } else {
    return []
  }
}

export const getInitialValues = mediaOrder => {
  const allMediaOrderProducts = getAllMediaOrderProducts(mediaOrder)

  return {
    [SELECTED_PRODUCTS]: formatMediaOrderProductsToFE(allMediaOrderProducts)
  }
}
