import React from 'react'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import PlacementsSection from '../../../../PlacementsSection'

const PlacementsStep = ({ formik }) => {
  return (
    <Step
      stepTitle="Meta Placements"
      stepDescription="Select your ad placements across Facebook, Instagram, Messenger and Audience Network"
    >
      <PlacementsSection formik={formik} />
    </Step>
  )
}

export default PlacementsStep
