import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useMemo } from 'react'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import MediaProductGroupEditForm from './MediaProductGroupEditForm'

import useManageEditFormData from '../../../../../hooks/formHooks/useManageEditFormData'

import { productSelector, productWasLoadedSelector } from '../../../../../modules/selectors/mediaOrdersProducts'
import { clearProduct, getProduct } from '../../../../../modules/actions/mediaOrdersProducts'

import { MEDIA_PRODUCT_GROUP_EDIT } from '../../../../../constants/forms'

const MediaProductGroupEdit = () => {
  const dispatch = useDispatch()

  const product = useSelector(productSelector)

  const productWasLoaded = useSelector(productWasLoadedSelector)

  const getProductHandler = useCallback(
    selectedEditItemId => {
      dispatch(getProduct({ id: selectedEditItemId }))
    },
    [dispatch]
  )

  const clearProductHandler = useCallback(() => {
    dispatch(clearProduct())
  }, [dispatch])

  useManageEditFormData({
    formName: MEDIA_PRODUCT_GROUP_EDIT,
    getDataHandler: getProductHandler,
    clearDataHandler: clearProductHandler,
    loadedDataId: product.id
  })

  const isFormLoading = useMemo(() => !productWasLoaded, [productWasLoaded])
  return (
    <FormDrawerWrapper
      formName={MEDIA_PRODUCT_GROUP_EDIT}
      isFormLoading={isFormLoading}
      title="Edit media product grouping"
      showOpenButton={false}
    >
      <MediaProductGroupEditForm editProductData={product} />
    </FormDrawerWrapper>
  )
}

export default MediaProductGroupEdit
