import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { encryptJWE } from '../../helpers/encrypt'
import { deleteItemById } from '../../helpers/modules/reducerHelpers'

import { submitLogin } from '../actions/cognito'
import { getUserInvitesSuccess, updateCurrentUserController } from '../actions/app'
import {
  ACCEPT_CONTROLLER_INVITE,
  acceptControllerInviteFailure,
  acceptControllerInviteSuccess,
  CREATE_CONTROLLER_INVITE,
  createControllerInviteFailure,
  createControllerInviteSuccess,
  DELETE_CONTROLLER_INVITE,
  deleteControllerInviteFailure,
  deleteControllerInviteSuccess,
  GET_CONTROLLER_INVITES,
  GET_CONTROLLER_MEMBERS,
  GET_CONTROLLERS,
  getControllerInvitesFailure,
  getControllerInvitesSuccess,
  getControllerMembersFailure,
  getControllerMembersSuccess,
  getControllersFailure,
  getControllersSuccess,
  RESEND_CONTROLLER_INVITE,
  resendControllerInviteFailure,
  resendControllerInviteSuccess,
  UPDATE_CONTROLLER,
  UPDATE_CONTROLLER_MEMBER,
  UPDATE_CONTROLLER_MEMBER_STATUS,
  updateControllerFailure,
  updateControllerMemberFailure,
  updateControllerMemberSuccess,
  updateControllerMemberStatusFailure,
  updateControllerMemberStatusSuccess,
  updateControllerSuccess,
  GET_CONTROLLER,
  getControllerSuccess,
  getControllerFailure,
  GET_CONTROLLER_MEMBER,
  getControllerMemberSuccess,
  getControllerMemberFailure
} from '../actions/controller'
import { userInvitesSelector } from '../selectors/app'
import {
  acceptControllerInviteService,
  createControllerInviteService,
  deleteControllerInviteService,
  getControllerInvitesService,
  getControllerMemberService,
  getControllerMembersService,
  getControllerService,
  getControllersService,
  resendControllerInviteService,
  updateControllerMemberService,
  updateControllerMemberStatusService,
  updateControllerService
} from '../services/controller'

import { EMAIL } from '../../pages/Unauthorized/Login/fields'
import { PASSWORD } from '../../pages/Unauthorized/CreatePassword/fields'

function* controllerWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_CONTROLLERS, getControllersWorker),
    takeEvery(GET_CONTROLLER_MEMBERS, getControllerMembersWorker),
    takeEvery(GET_CONTROLLER_INVITES, getControllerInvitesWorker),
    takeEvery(CREATE_CONTROLLER_INVITE, createControllerInviteWorker),
    takeEvery(ACCEPT_CONTROLLER_INVITE, acceptControllerInviteWorker),
    takeEvery(UPDATE_CONTROLLER_MEMBER, updateControllerMemberWorker),
    takeEvery(UPDATE_CONTROLLER_MEMBER_STATUS, updateControllerMemberStatusWorker),
    takeEvery(DELETE_CONTROLLER_INVITE, deleteControllerInviteWorker),
    takeEvery(RESEND_CONTROLLER_INVITE, resendControllerInviteWorker),
    takeEvery(UPDATE_CONTROLLER, updateControllerWorker),
    takeEvery(GET_CONTROLLER, getControllerWorker),
    takeEvery(GET_CONTROLLER_MEMBER, getControllerMemberWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getControllersWorker({ params }) {
  try {
    const response = yield call(getControllersService, params)
    yield put(getControllersSuccess(response))
  } catch (e) {
    yield put(getControllersFailure(e))
  }
}

function* getControllerMembersWorker({ params }) {
  try {
    const response = yield call(getControllerMembersService, params)
    yield put(getControllerMembersSuccess(response))
  } catch (e) {
    yield put(getControllerMembersFailure(e))
  }
}

function* getControllerInvitesWorker({ params }) {
  try {
    const response = yield call(getControllerInvitesService, params)
    yield put(getControllerInvitesSuccess(response))
  } catch (e) {
    yield put(getControllerInvitesFailure(e))
  }
}

function* createControllerInviteWorker({ inviteData }) {
  try {
    const response = yield call(createControllerInviteService, inviteData)
    yield put(createControllerInviteSuccess(response))
  } catch (e) {
    yield put(createControllerInviteFailure(e))
  }
}

function* acceptControllerInviteWorker({ inviteData, submitLoginOnSuccess, removeInviteOnSuccess }) {
  try {
    const password = inviteData[PASSWORD]
    const encryptedPassword = yield call(encryptJWE, { [PASSWORD]: password })
    // password should be encrypted to token when sends to BE for security

    const response = yield call(acceptControllerInviteService, { ...inviteData, [PASSWORD]: encryptedPassword })
    yield put(acceptControllerInviteSuccess())

    if (submitLoginOnSuccess) {
      yield put(
        submitLogin({
          [EMAIL]: response[EMAIL],
          [PASSWORD]: inviteData[PASSWORD]
        })
      )
    }

    if (removeInviteOnSuccess) {
      const invites = yield select(userInvitesSelector)

      const { controller_invites: controllerInvites } = invites
      const filteredControllerInvites = controllerInvites.filter(item => item.token !== inviteData.token)

      yield put(getUserInvitesSuccess({ ...invites, controller_invites: filteredControllerInvites }))
    }
  } catch (e) {
    // code is added as key for parsing errors inside the ProgressButton
    yield put(acceptControllerInviteFailure({ ...e, code: 'encryption_error' }))
  }
}

function* updateControllerMemberWorker({ id, data }) {
  try {
    const response = yield call(updateControllerMemberService, id, data)
    yield put(updateControllerMemberSuccess(response))
  } catch (e) {
    yield put(updateControllerMemberFailure(e))
  }
}

function* updateControllerMemberStatusWorker({ id, status }) {
  const isActive = status === 'active'

  try {
    yield call(updateControllerMemberStatusService, id, status)
    yield put(updateControllerMemberStatusSuccess({ id, is_active: !isActive }))
  } catch (e) {
    yield put(updateControllerMemberStatusFailure(e))
  }
}

function* deleteControllerInviteWorker({ id, removeInviteOnSuccess }) {
  try {
    yield call(deleteControllerInviteService, id)
    yield put(deleteControllerInviteSuccess(id))

    if (removeInviteOnSuccess) {
      const invites = yield select(userInvitesSelector)

      const { controller_invites: controllerInvites } = invites

      yield put(getUserInvitesSuccess({ ...invites, controller_invites: deleteItemById(controllerInvites, id) }))
    }
  } catch (e) {
    yield put(deleteControllerInviteFailure(e))
  }
}

function* resendControllerInviteWorker({ id }) {
  try {
    yield call(resendControllerInviteService, id)
    yield put(resendControllerInviteSuccess(id))
  } catch (e) {
    yield put(resendControllerInviteFailure(e))
  }
}

function* updateControllerWorker({ id, controllerData }) {
  try {
    const response = yield call(updateControllerService, id, controllerData)
    yield put(updateControllerSuccess(response))
    // we store controller on user level as well, based on user data the selectedControllerDataSelector takes the data
    yield put(updateCurrentUserController(response))
  } catch (e) {
    yield put(updateControllerFailure(e))
  }
}

function* getControllerWorker({ params }) {
  try {
    const response = yield call(getControllerService, params)
    yield put(getControllerSuccess(response))
  } catch (error) {
    yield put(getControllerFailure(error))
  }
}

function* getControllerMemberWorker({ params }) {
  try {
    const response = yield call(getControllerMemberService, params)
    yield put(getControllerMemberSuccess(response))
  } catch (error) {
    yield put(getControllerMemberFailure(error))
  }
}

export default controllerWatcher
