import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { getIn } from 'formik'

import FieldRow from '../../../../../../features/components/Form/FieldsSection/FieldRow'
import AdFileUpload from '../../../../../ReusableFormFields/AdFileUpload'
import ProductGallery from './ProductGallery'

import { concat } from '../../../../../../helpers/common'

import { fileUpload } from '../../../../../../modules/actions/files'

import { getFileDetailsError } from '../../../../AdForms/AdFacebookCreate/AdFacebookCreateForm/validation'
import { MEDIA_FILE_UPLOAD, UPLOADED_MEDIA_TYPE } from '../../../../../ReusableFormFields/AdFileUpload/fields'
import {
  FILE_HASH,
  FILE_ID,
  FILE_NAME,
  FILE_URL,
  FILE_URL_BLOB,
  initialFileValues,
  initialMediaValues,
  MEDIA_TYPE,
  SINGLE_MEDIA_ITEM,
  VIDEO_PICTURE
} from '../../../../AdForms/fields'
import { FACEBOOK_PLATFORM } from '../../../../../../constants/selectLists/platformList'

const ProductMediaSection = ({ formik, adAccountId, galleryImages, allowMediaUpload }) => {
  const dispatch = useDispatch()

  const { values, setFieldValue, errors } = formik

  const itemPath = SINGLE_MEDIA_ITEM
  const productErrors = errors[itemPath] || {}

  const fileDetailsError = getFileDetailsError(productErrors)
  const fileUrlError = productErrors[FILE_URL]

  const fileNameKey = concat(itemPath, '.', FILE_NAME)
  const fileUrlKey = concat(itemPath, '.', FILE_URL)
  const fileUrlBlobKey = concat(itemPath, '.', FILE_URL_BLOB)
  const fileTypeKey = concat(itemPath, '.', MEDIA_TYPE)
  const uploadedMediaTypeKey = concat(itemPath, '.', UPLOADED_MEDIA_TYPE)

  const selectedImage = getIn(values, fileUrlKey)

  const handleExistingMediaSelect = useCallback(
    image => {
      // when setting existing media, clear all the previous values (in case custom image was uploaded),
      // and specify file url and type
      setFieldValue(SINGLE_MEDIA_ITEM, {
        ...values[SINGLE_MEDIA_ITEM],
        ...initialFileValues,
        ...initialMediaValues,
        [FILE_URL]: image,
        [MEDIA_TYPE]: 'image'
      })
    },
    [values, setFieldValue]
  )

  const handleMediaRemove = useCallback(() => {
    // set initial media file values for deleted file
    setFieldValue(SINGLE_MEDIA_ITEM, {
      ...values[SINGLE_MEDIA_ITEM],
      ...initialFileValues,
      ...initialMediaValues
    })
  }, [values, setFieldValue])

  const handleMediaStartUpload = useCallback(
    // file is loading to Server, on success file uploading the handleMediaUploaded is called and set rest fields
    (file, newFileValues, fileUrlBlob) => {
      // remove file when select file from another type
      handleMediaRemove()

      const newMediaValues = {
        ...initialFileValues,
        ...initialMediaValues,
        ...newFileValues,
        ...(fileUrlBlob && { [FILE_URL_BLOB]: fileUrlBlob })
      }
      setFieldValue(SINGLE_MEDIA_ITEM, {
        ...values[SINGLE_MEDIA_ITEM],
        ...newMediaValues
      })

      // upload file to server
      dispatch(
        fileUpload(file, { isPlatformRelated: true, platform: FACEBOOK_PLATFORM, adAccountId, isPrivate: false })
      )
    },
    [dispatch, adAccountId, handleMediaRemove, values, setFieldValue]
  )

  const handleMediaUploaded = useCallback(
    uploadedFile => {
      const newFileValues = {
        [FILE_URL]: uploadedFile.url,
        [FILE_HASH]: uploadedFile.hash,
        [FILE_ID]: uploadedFile.id,
        // if type of media is video, we have additional property VIDEO_PICTURE
        ...(uploadedFile.picture && { [VIDEO_PICTURE]: uploadedFile.picture })
      }

      setFieldValue(SINGLE_MEDIA_ITEM, {
        ...values[SINGLE_MEDIA_ITEM],
        ...newFileValues
      })
    },
    [setFieldValue, values]
  )

  return (
    <FieldRow title="Image" description="Select from one of the product images">
      {allowMediaUpload && (
        <AdFileUpload
          values={formik.values}
          validateForm={formik.validateForm}
          onFileStartUpload={handleMediaStartUpload}
          onFileUploaded={handleMediaUploaded}
          onFileRemove={handleMediaRemove}
          /* In case if user try to load not valid media,
      we have both mediaFileError and mediaUrlError,
      but we must show an error about wrong media properties */
          error={fileDetailsError || fileUrlError}
          mediaType={MEDIA_FILE_UPLOAD}
          fileNameKey={fileNameKey}
          fileURLKey={fileUrlKey}
          fileUrlBlobKey={fileUrlBlobKey}
          fileTypeKey={fileTypeKey}
          uploadedMediaTypeKey={uploadedMediaTypeKey}
          isDraggable
        />
      )}
      <ProductGallery
        selectedImage={selectedImage}
        mediaThumbnailFieldName="thumbnail_url"
        onMediaSelect={image => handleExistingMediaSelect(image)}
        images={galleryImages}
      />
    </FieldRow>
  )
}

ProductMediaSection.propTypes = {
  formik: PropTypes.object.isRequired,
  adAccountId: PropTypes.string.isRequired,
  galleryImages: PropTypes.array.isRequired
}

export default ProductMediaSection
