import React, { lazy, Suspense, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import InfoBlock from '../../../../../features/components/InfoBlock'
import { MobileDown, MobileUp } from '../../../../../components/hoc/ResponsiveRendering'
import AppLoader from '../../../../../components/Loaders/AppLoader'
import { BookedRevenueDataPeriodsProvider } from './CalendarDesktop/BookedRevenueDataPeriodsContext'
import CalendarBookedMediaFilters from './BookedRevenueFilters'
import { ReactComponent as CalendarIcon } from '../../../../../assets/calendarTimeline/icons/calendar-colorful.svg'

import { formatDateToBE } from '../../../../../constants/dates'
import { getInitialDateRange } from '../../../../../features/components/Calendar/CalendarTable'

import { getBookedRevenueReport, clearGetBookedRevenueReport } from '../../../../../modules/actions/mediaOrdersBookings'
import {
  getBookedRevenueReportErrorSelector,
  getBookedRevenueReportWasLoadedSelector
} from '../../../../../modules/selectors/mediaOrdersBookings'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'

import { CALENDAR_VIEW_TYPE } from '../../../../../constants/selectLists/calendarList'

// lazy load Calendar component to reduce the initial bundle file size
const CalendarDesktop = lazy(() => import('./CalendarDesktop'))

const BookedRevenueBreakdownReport = () => {
  const bookedMediaWasLoaded = useSelector(getBookedRevenueReportWasLoadedSelector)
  const bookedRevenueReportError = useSelector(getBookedRevenueReportErrorSelector)
  const selfAccountControllerId = useSelector(selectedControllerIdSelector)
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [params, setParams] = useState(null)
  const [mainDataWasLoaded, setMainDataWasLoaded] = useState(false)
  // set date range to ref, to avoid the component re-render and handleDataFetch change, when the date range changes
  // as if handleDataFetch changes it will trigger the data fetch automatically in the CalendarBookedMediaFilters useEffect
  const dateRangeRef = useRef(getInitialDateRange(CALENDAR_VIEW_TYPE.YEAR))

  const handleDateRangeChange = useCallback(dateRange => {
    dateRangeRef.current = dateRange
  }, [])

  const handleDataFetch = useCallback(
    filterRequestParams => {
      setParams(filterRequestParams)

      const { startDate, endDate } = dateRangeRef.current
      setMainDataWasLoaded(false)
      dispatch(
        getBookedRevenueReport({
          params: {
            controller_id: selfAccountControllerId,
            ...filterRequestParams,
            date_start_after: formatDateToBE(startDate),
            date_start_before: formatDateToBE(endDate)
          },
          loadOptions: {
            shouldClearExistingState: true
          }
        })
      )
    },
    [dispatch, selfAccountControllerId]
  )

  useEffect(() => {
    if (bookedMediaWasLoaded) {
      // the bookedMediaWasLoaded could not be used directly inside the BookedRevenueDataPeriodsProvider as it leads
      // to unexpected data fetching during navigation from Dashboard to BookedRevenueBreakdownReport
      // the issue is that code inside the BookedRevenueBreakdownReport is running faster than clear redux storage
      // actions are fired up on unmount of Dashboard
      setMainDataWasLoaded(true)
    }
  }, [bookedMediaWasLoaded])

  useEffect(() => {
    return () => {
      dispatch(clearGetBookedRevenueReport())
    }
  }, [dispatch])

  if (bookedRevenueReportError) {
    return (
      <InfoBlock title={t('Sorry, something went wrong')} centered greyDescription>
        <div>{t('Please try again later')}</div>
      </InfoBlock>
    )
  }

  return (
    <>
      <MobileUp>
        <CalendarBookedMediaFilters onFiltersChange={handleDataFetch} />
        <Suspense fallback={<AppLoader isFixed />}>
          <BookedRevenueDataPeriodsProvider
            mainDataWasLoaded={mainDataWasLoaded}
            params={params}
            onDateRangeChange={handleDateRangeChange}
          >
            <CalendarDesktop breakdown={params?.breakdown} />
          </BookedRevenueDataPeriodsProvider>
        </Suspense>
      </MobileUp>
      <MobileDown>
        <InfoBlock Icon={CalendarIcon} title={t('The planner is best viewed on desktop')}>
          {t('Please use the desktop version to view your planner')}
        </InfoBlock>
      </MobileDown>
    </>
  )
}

export default BookedRevenueBreakdownReport
