import { createStyles } from '../../../../../styles/helpers'

import { grey, lightGrey } from '../../../../../styles/const/colors'
import { zIndex2 } from '../../../../../styles/const/common'

export default createStyles(theme => ({
  adImage: {
    height: ({ mediaPreviewHeight }) => mediaPreviewHeight,
    minHeight: ({ mediaPreviewHeight }) => (mediaPreviewHeight === 'auto' ? 'unset' : mediaPreviewHeight),
    maxHeight: ({ mediaPreviewHeight }) => (mediaPreviewHeight === 'auto' ? 'unset' : mediaPreviewHeight),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      width: ({ isImagePreview }) => (isImagePreview ? '100%' : null)
    },
    '& video': {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  coverPreviewSize: {
    objectFit: 'cover',
    height: '100%',
    width: '100%'
  },
  adImageYellow: {
    backgroundColor: theme.brandPrimary
  },
  adImageGrey: {
    backgroundColor: lightGrey
  },
  skeletonContainer: {
    position: 'relative',
    height: '100%'
  },
  processingMessage: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 200,
    textAlign: 'center',
    position: 'absolute',
    paddingLeft: 5,
    paddingRight: 5,
    margin: 0,
    fontSize: 14,
    lineHeight: '16px',
    color: grey,
    fontWeight: 'bold',
    zIndex: zIndex2
  }
}))
