import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getIn } from 'formik'

import Field from '../../../../../components/Form/Field'
import MultipleOptionsField from '../../../MultipleOptionsField'
import FieldRow from '../../../../../features/components/Form/FieldsSection/FieldRow'
import TextSuggestions from '../../../../../features/components/Form/TextSuggestions'

import { adCreativeSuggestionsLoadingSelector } from '../../../../../modules/selectors/tools'

import { headlineMaxLength } from '../../../../Facebook/AdForms/validation'
import { HEADLINES_LIST } from '../../../../Facebook/AdForms/fields'

import useDrawerStyles from '../../../../../styles/common/drawerForms'

const HeadlineField = ({ formik, itemPath, suggestions = [], allowMultiple }) => {
  const { t } = useTranslation()
  const { values, setFieldValue } = formik
  const commonClasses = useDrawerStyles()

  const suggestionsLoading = useSelector(adCreativeSuggestionsLoadingSelector)

  const fieldPath = `${itemPath}.${HEADLINES_LIST}[0].text`
  const selectedHeadline = getIn(values, fieldPath)

  const handleHeadlineChange = useCallback(value => setFieldValue(fieldPath, value), [fieldPath, setFieldValue])

  return (
    <FieldRow
      title="Headline (optional)"
      description="45 characters or less recommended"
      footer={
        <TextSuggestions
          selectedSuggestion={selectedHeadline}
          showLogo={false}
          suggestions={suggestions}
          onSelectSuggestion={handleHeadlineChange}
          typingAnimation={suggestionsLoading}
        />
      }
    >
      {allowMultiple ? (
        <>
          <h3 className={commonClasses.sectionTitle}>{t('Headline(s)')}</h3>
          <MultipleOptionsField
            fieldPlaceholder="Headline"
            fieldMaxLength={headlineMaxLength}
            listFieldName={HEADLINES_LIST}
            formik={formik}
            itemPath={itemPath}
            maxListQuantity={5}
          />
        </>
      ) : (
        <Field
          placeholder="Headline"
          formik={formik}
          name={fieldPath}
          isTextarea
          maxLength={headlineMaxLength}
          enableReinitialize
        />
      )}
    </FieldRow>
  )
}

HeadlineField.propTypes = {
  formik: PropTypes.object.isRequired,
  suggestions: PropTypes.array,
  allowMultiple: PropTypes.bool
}

export default HeadlineField
