import React, { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import MultipleCheckboxesSelect from '../../../../components/MultipleCheckboxesSelect'
import { formatOptionsList } from '../../../formatters'

import { selectedControllerIdSelector } from '../../../../modules/selectors/app'

import { createJsonFromQueryString } from '../../../../helpers/url'
import { getMediaProductLocationsService } from '../../../../modules/services/mediaOrdersProductLocations'

import useStyles from './styles'

const BookedMediaLocationsFilter = ({ Context, placeholder, multipleOptionsLabel }) => {
  const classes = useStyles()

  const controllerId = useSelector(selectedControllerIdSelector)

  const { selectedLocations, setSelectedLocations } = useContext(Context)

  const formatLocations = useCallback(
    locations =>
      formatOptionsList({
        list: locations,
        valueName: 'id',
        labelName: 'name'
      }),
    []
  )

  const loadOptions = useCallback(
    async (search, loadedOptions, { next }) => {
      const response = await getMediaProductLocationsService({
        search,
        ordering: 'name',
        controller: controllerId,
        fields: ['id', 'name'].join(','),
        limit: 100,
        ...createJsonFromQueryString(`?${next.split('?')[1]}`)
      })

      const newLocationsOptions = formatLocations(response.results)

      return {
        options: newLocationsOptions,
        hasMore: !!response.next,
        additional: {
          next: response.next
        }
      }
    },
    [controllerId, formatLocations]
  )

  const onLocationsChange = useCallback(
    selectedOptions => {
      setSelectedLocations(selectedOptions)
    },
    [setSelectedLocations]
  )

  return (
    <MultipleCheckboxesSelect
      value={selectedLocations}
      onChange={onLocationsChange}
      loadOptions={loadOptions}
      // features:
      isSearchable={true}
      handleChangeOnClose={true}
      allowSelectAll={true}
      // styles:
      placeholder={placeholder || 'Locations'}
      multipleOptionsLabel={multipleOptionsLabel || 'Locations'}
      className={classes.locationFilter}
      isHighlighted={true}
      expandMenu={true}
    />
  )
}

BookedMediaLocationsFilter.propTypes = {
  Context: PropTypes.object.isRequired,
  placeholder: PropTypes.string
}

export default BookedMediaLocationsFilter
