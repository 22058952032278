import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Form from '../../../../../../../components/Form'
import RadioBlock from '../../../../../../../components/Form/RadioBlock'
import DrawerHeadline from '../../../../../../../components/Drawer/DrawerHeadline'
import UsersAudiencesSelect from '../../../../../LineItemForms/LineItemGoogleCreate/LineItemGoogleCreateForm/LineItemGoogleAudienceForm/LineItemGoogleAudienceFields/UsersAudiencesSelect'

import { useLineItemGoogleCreate } from './useLineItemGoogleCreate'

import { setMediaOrderUploadCreative } from '../../../../../../../modules/actions/mediaOrders'
import {
  lineItemCreatedDataSelector,
  lineItemWasCreatedSelector
} from '../../../../../../../modules/selectors/lineItems'
import { selectedControllerDataSelector, selectedAdAccountIdSelector } from '../../../../../../../modules/selectors/app'
import { clearGetGoogleUsersList, getGoogleUsersList } from '../../../../../../../modules/actions/googleConstants'

import { AUDIENCE_OPTION } from '../../../../../AdForms/GoogleAssetGroup/GoogleAssetGroupAudienceForm/AudienceForm/fields'
import { OPTION_NO, OPTION_YES } from '../../../../../../../constants/forms'
import { validationSchema, initialValues } from './fields'
import { LINE_ITEM_FORM } from '../../../index'
import { GOOGLE_PLATFORM } from '../../../../../../../constants/selectLists/platformList'

const LineItemFormContent = ({ adAccountId, onSuccessSubmit }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const lineItemWasCreated = useSelector(lineItemWasCreatedSelector)
  const lineItemCreatedData = useSelector(lineItemCreatedDataSelector)

  const selectedController = useSelector(selectedControllerDataSelector)
  const selectedControllerName = selectedController?.name || ''

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const {
    onSubmit: onLineItemCreateSubmit,
    onClearSubmit,
    isLoadingSelector,
    errorSelector,
    successSubmit
  } = useLineItemGoogleCreate()

  const onSubmit = useCallback(values => onLineItemCreateSubmit(values), [onLineItemCreateSubmit])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const { values, setFieldValue } = formik

  useEffect(() => {
    if (lineItemWasCreated) {
      dispatch(setMediaOrderUploadCreative({ lineItem: lineItemCreatedData }, GOOGLE_PLATFORM))
    }
  }, [dispatch, lineItemWasCreated, lineItemCreatedData])

  useEffect(() => {
    dispatch(
      getGoogleUsersList({
        account: adAccountId || selectedAdAccountId,
        page_size: 50,
        eligible_for_display: true,
        membership_status: 2
      })
    )

    return () => {
      dispatch(clearGetGoogleUsersList())
    }
  }, [dispatch, adAccountId, selectedAdAccountId])

  return (
    <>
      <DrawerHeadline
        activeStepNumber={1}
        customStepsLength={2}
        title="Select an audience"
        description={t('targetShopperAudiencesGoogleAds', { selectedControllerName })}
      />
      <Form
        submitText="Save and continue"
        formik={formik}
        formName={LINE_ITEM_FORM}
        errorSelector={errorSelector}
        isLoadingSelector={isLoadingSelector}
        successSubmit={successSubmit}
        onSuccessSubmit={onSuccessSubmit}
        clearHandler={onClearSubmit}
      >
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_audience_option_yes"
          name={AUDIENCE_OPTION}
          value={OPTION_YES}
          selectedValue={values[AUDIENCE_OPTION]}
          label={t('Select target audience(s)')}
        >
          <UsersAudiencesSelect formik={formik} />
        </RadioBlock>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_audience_option_no"
          name={AUDIENCE_OPTION}
          value={OPTION_NO}
          selectedValue={values[AUDIENCE_OPTION]}
          label={t('No audience targeting')}
        />
      </Form>
    </>
  )
}

LineItemFormContent.propTypes = {
  adAccountId: PropTypes.string,
  onSuccessSubmit: PropTypes.func.isRequired
}

export default LineItemFormContent
