import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Icon from '../../../../components/Icon'
import Button from '../../../../components/Button'
import PageSectionHeader from '../../../../features/components/PageSectionHeader'
import Graphs from './Graphs'
import ReportsSections from './ReportsSections'
import { ReactComponent as ReportIcon } from '../../../../assets/icons/report.svg'

import { useGetProvidersFromCampaigns } from './hooks'

import { combinedCampaignsSelector } from '../../../../modules/selectors/combinedData'

import useStyles from './styles'

const MediaOrderCampaignsReports = () => {
  const classes = useStyles()

  const { t } = useTranslation()

  const [generateReports, setGenerateReports] = useState(false)
  const [showReports, setShowReports] = useState(false)

  const { campaigns = [] } = useSelector(combinedCampaignsSelector)

  const providers = useGetProvidersFromCampaigns(campaigns)

  const isMultiplatform = useMemo(() => {
    return providers.length > 1
  }, [providers])

  const toggleShowReports = useCallback(() => {
    // We use generateReports state to show reports (trigger all requests) only once,
    // and then we use showReports state to show/hide reports
    if (!generateReports) {
      setGenerateReports(true)
    }
    setShowReports(!showReports)
  }, [generateReports, showReports])

  return (
    <section className={classes.reportSection}>
      {!showReports && (
        <Button className={classes.reportBtn} onClick={toggleShowReports}>
          <Icon className={classes.reportIcon}>
            <ReportIcon />
          </Icon>
          {isMultiplatform ? t('Generate cross-platform report') : t('Generate report')}
        </Button>
      )}
      {generateReports && (
        <div style={{ display: showReports ? 'block' : 'none' }}>
          <PageSectionHeader
            id="rights-report-header"
            title="Performance report"
            RightPanel={<Button onClick={toggleShowReports}>{t('Hide')}</Button>}
          />
          <Graphs />
          <ReportsSections />
        </div>
      )}
    </section>
  )
}

export default MediaOrderCampaignsReports
