import { useSelector } from 'react-redux'

import { activeStepSelector } from '../../../../../../modules/selectors/forms'

import { CAMPAIGN_OPTION_NEW_CAMPAIGN } from './fields'

export const useProgressItems = chosenRoute => {
  const activeStep = useSelector(activeStepSelector)

  const firstRouteProgressItems = [
    {
      show: true,
      label: 'Choose campaign type'
    },
    {
      show: true,
      label: 'Set campaign dates'
    },
    {
      show: true,
      label: 'Set bid'
    },
    {
      show: true,
      label: 'Select products'
    }
  ]

  const audienceTargetingRouteProgressItems = [
    {
      show: true,
      label: 'Choose campaign type'
    },
    {
      show: true,
      label: 'Set campaign dates'
    },
    {
      show: true,
      label: 'Select target audience'
    },
    {
      show: true,
      label: 'Upload files'
    }
  ]

  const progressItems =
    chosenRoute === CAMPAIGN_OPTION_NEW_CAMPAIGN ? firstRouteProgressItems : audienceTargetingRouteProgressItems

  return progressItems
    .filter(item => item.show)
    .map((item, index) => {
      return {
        ...item,
        isActive: activeStep === index,
        isSuccess: activeStep > index
      }
    })
}
