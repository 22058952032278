import React from 'react'
import { useTranslation } from 'react-i18next'

import CheckboxGroup from '../../../../../../../features/components/Form/CheckboxGroup'

import {
  OFFSITE_CAMPAIGN_TYPES,
  routesList
} from '../../../ClientAccountWithMarginsCreate/ClientAccountWithMarginsCreateForm/fields'

const OfflineCampaignsTypeField = ({ formik }) => {
  const { t } = useTranslation()

  const formattedRoutesList = routesList.map(route => ({
    ...route,
    // for easier data management and structure in formik wrap types checkboxes inside the OFFSITE_CAMPAIGN_TYPES field:
    name: `${OFFSITE_CAMPAIGN_TYPES}.${route.name}`
  }))

  const { values, setFieldValue, setValues } = formik

  return (
    <CheckboxGroup
      values={values}
      setFieldValue={setFieldValue}
      setValues={setValues}
      title={t('Allowed types:')}
      isLarge={false}
      options={formattedRoutesList}
    />
  )
}

export default OfflineCampaignsTypeField
