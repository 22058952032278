import React, { useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { ReactComponent as BrokenPencil } from '../../../assets/icons/broken-pencil.svg'

import useStyles from './styles'

function InfoBlock({ Icon, hideIcon, greyDescription, title, children, className, centered }) {
  const classes = useStyles({ centered, greyDescription })

  const { t } = useTranslation()

  const renderChildren = useCallback(() => {
    if (typeof children === 'string') {
      return t(children)
    } else {
      return children
    }
  }, [children, t])

  return (
    <div className={classnames(classes.infoContainer, className)}>
      {!hideIcon && (Icon ? <Icon /> : <BrokenPencil />)}
      {title && <h3 className={classes.title}>{t(title)}</h3>}
      <div className={classes.description}>{renderChildren()}</div>
    </div>
  )
}

export default InfoBlock

InfoBlock.propTypes = {
  Icon: PropTypes.object,
  hideIcon: PropTypes.bool,
  // control color of description text
  greyDescription: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  centered: PropTypes.bool
}
