import update from 'immutability-helper'

import {
  CLEAR_CREATE_DISCOUNT_PRESET,
  CLEAR_CREATE_PRICE_CHANGE_PERIOD,
  CLEAR_DELETE_PRICE_CHANGE_PERIOD,
  CLEAR_GET_DISCOUNT_PRESETS,
  CLEAR_GET_PRICE_CHANGE_PERIODS,
  CLEAR_UPDATE_DISCOUNT_PRESET,
  CLEAR_UPDATE_PRICE_CHANGE_PERIOD,
  CREATE_DISCOUNT_PRESET,
  CREATE_DISCOUNT_PRESET_FAILURE,
  CREATE_DISCOUNT_PRESET_SUCCESS,
  CREATE_PRICE_CHANGE_PERIOD,
  CREATE_PRICE_CHANGE_PERIOD_FAILURE,
  CREATE_PRICE_CHANGE_PERIOD_SUCCESS,
  DELETE_PRICE_CHANGE_PERIOD,
  DELETE_PRICE_CHANGE_PERIOD_FAILURE,
  DELETE_PRICE_CHANGE_PERIOD_SUCCESS,
  GET_DISCOUNT_PRESETS,
  GET_DISCOUNT_PRESETS_FAILURE,
  GET_DISCOUNT_PRESETS_SUCCESS,
  GET_PRICE_CHANGE_PERIODS,
  GET_PRICE_CHANGE_PERIODS_FAILURE,
  GET_PRICE_CHANGE_PERIODS_SUCCESS,
  UPDATE_DISCOUNT_PRESET,
  UPDATE_DISCOUNT_PRESET_FAILURE,
  UPDATE_DISCOUNT_PRESET_SUCCESS,
  UPDATE_PRICE_CHANGE_PERIOD,
  UPDATE_PRICE_CHANGE_PERIOD_FAILURE,
  UPDATE_PRICE_CHANGE_PERIOD_SUCCESS
} from '../actions/discounts'

import {
  INITIAL_CREATE_REDUCER,
  INITIAL_DELETE_REDUCER,
  INITIAL_GET_RESULTS_PAGING_REDUCER,
  INITIAL_UPDATE_REDUCER
} from '../../constants/reducer'
import { deleteItemById, updateItem } from '../../helpers/modules/reducerHelpers'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  discountPresets: INITIAL_GET_RESULTS_PAGING_REDUCER,
  createDiscountPreset: INITIAL_CREATE_REDUCER,
  updateDiscountPreset: INITIAL_UPDATE_REDUCER,
  priceChangePeriods: INITIAL_GET_RESULTS_PAGING_REDUCER,
  createPriceChangePeriod: INITIAL_CREATE_REDUCER,
  updatePriceChangePeriod: INITIAL_UPDATE_REDUCER,
  deletePriceChangePeriod: INITIAL_DELETE_REDUCER
}

export default function discounts(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // Get discount presets
    case GET_DISCOUNT_PRESETS:
      return update(state, {
        discountPresets: { $merge: { isLoading: true } }
      })
    case GET_DISCOUNT_PRESETS_SUCCESS:
      return update(state, {
        discountPresets: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data.results
          }
        }
      })
    case GET_DISCOUNT_PRESETS_FAILURE:
      return update(state, {
        discountPresets: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_DISCOUNT_PRESETS:
      return update(state, {
        discountPresets: { $set: initialState.discountPresets }
      })

    // Create discount preset
    case CREATE_DISCOUNT_PRESET:
      return update(state, {
        createDiscountPreset: { $merge: { isLoading: true } }
      })
    case CREATE_DISCOUNT_PRESET_SUCCESS:
      return update(state, {
        createDiscountPreset: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },
        discountPresets: {
          results: {
            $unshift: [action.data]
          }
        }
      })
    case CREATE_DISCOUNT_PRESET_FAILURE:
      return update(state, {
        createDiscountPreset: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_DISCOUNT_PRESET:
      return update(state, {
        createDiscountPreset: { $set: initialState.createDiscountPreset }
      })

    // Update discount preset
    case UPDATE_DISCOUNT_PRESET:
      return update(state, {
        updateDiscountPreset: { $merge: { isLoading: true } }
      })
    case UPDATE_DISCOUNT_PRESET_SUCCESS:
      return update(state, {
        updateDiscountPreset: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            data: action.data
          }
        },
        discountPresets: {
          results: {
            $apply: items => updateItem(items, action.data)
          }
        }
      })
    case UPDATE_DISCOUNT_PRESET_FAILURE:
      return update(state, {
        updateDiscountPreset: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_DISCOUNT_PRESET:
      return update(state, {
        updateDiscountPreset: { $set: initialState.updateDiscountPreset }
      })

    // Get price change periods
    case GET_PRICE_CHANGE_PERIODS:
      return update(state, {
        priceChangePeriods: { $merge: { isLoading: true } }
      })
    case GET_PRICE_CHANGE_PERIODS_SUCCESS:
      return update(state, {
        priceChangePeriods: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data.results
          }
        }
      })
    case GET_PRICE_CHANGE_PERIODS_FAILURE:
      return update(state, {
        priceChangePeriods: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_PRICE_CHANGE_PERIODS:
      return update(state, {
        priceChangePeriods: { $set: initialState.priceChangePeriods }
      })

    // Create price change period
    case CREATE_PRICE_CHANGE_PERIOD:
      return update(state, {
        createPriceChangePeriod: { $merge: { isLoading: true } }
      })
    case CREATE_PRICE_CHANGE_PERIOD_SUCCESS:
      return update(state, {
        createPriceChangePeriod: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },
        priceChangePeriods: {
          results: {
            $unshift: [action.data]
          }
        }
      })
    case CREATE_PRICE_CHANGE_PERIOD_FAILURE:
      return update(state, {
        createPriceChangePeriod: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_PRICE_CHANGE_PERIOD:
      return update(state, {
        createPriceChangePeriod: { $set: initialState.createPriceChangePeriod }
      })

    // Update price change period
    case UPDATE_PRICE_CHANGE_PERIOD:
      return update(state, {
        updatePriceChangePeriod: { $merge: { isLoading: true } }
      })
    case UPDATE_PRICE_CHANGE_PERIOD_SUCCESS:
      return update(state, {
        updatePriceChangePeriod: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            data: action.data
          }
        },
        priceChangePeriods: {
          results: {
            $apply: items => updateItem(items, action.data)
          }
        }
      })
    case UPDATE_PRICE_CHANGE_PERIOD_FAILURE:
      return update(state, {
        updatePriceChangePeriod: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_PRICE_CHANGE_PERIOD:
      return update(state, {
        updatePriceChangePeriod: { $set: initialState.updatePriceChangePeriod }
      })

    // Delete price change period
    case DELETE_PRICE_CHANGE_PERIOD:
      return update(state, {
        deletePriceChangePeriod: { $merge: { isLoading: true } }
      })
    case DELETE_PRICE_CHANGE_PERIOD_SUCCESS:
      return update(state, {
        deletePriceChangePeriod: {
          $merge: {
            isLoading: false,
            wasDeleted: true,
            data: action.data
          }
        },
        priceChangePeriods: {
          results: {
            $apply: items => deleteItemById(items, action.id)
          }
        }
      })
    case DELETE_PRICE_CHANGE_PERIOD_FAILURE:
      return update(state, {
        deletePriceChangePeriod: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DELETE_PRICE_CHANGE_PERIOD:
      return update(state, {
        deletePriceChangePeriod: { $set: initialState.deletePriceChangePeriod }
      })

    default:
      return state
  }
}
