import { createStyles } from '../helpers'
import { darkChipBlue, darkGrey, lightGrey } from '../const/colors'
import { phonesDown } from '../const/breakpoints'
import { transitionShort } from '../const/common'

const getCursorProperty = ({ isCardDraggable, isHoverable }) => {
  if (isCardDraggable) {
    return 'grab'
  } else if (isHoverable) {
    return 'pointer'
  } else {
    return 'auto'
  }
}

const useStyles = createStyles({
  cardInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: '1 1 0%',
    alignItems: 'center'
  },
  cardName: {
    color: ({ isInactive }) => (isInactive ? lightGrey : darkGrey),
    fontSize: 14,
    fontWeight: '700',
    minWidth: '48%',
    maxWidth: '70%',
    paddingRight: 5,
    marginRight: 'auto',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    textDecoration: 'none',
    transition: transitionShort,
    '&:hover, &:active': {
      color: ({ isHoverable }) => (isHoverable ? darkChipBlue : 'inherit'),
      cursor: ({ isCardDraggable, isHoverable }) => getCursorProperty({ isCardDraggable, isHoverable })
    }
  },
  cardLink: {
    textDecoration: 'none',
    color: darkGrey,
    transition: transitionShort,
    '&.linkTouched, &:hover, &:active': {
      color: darkChipBlue
    }
  },
  cardButtons: {
    display: 'flex',
    paddingTop: 28,
    columnGap: 12,
    '& .btn': {
      flexGrow: 1,
      flexShrink: 1,
      width: '100%',
      height: 48,
      padding: 0
    },
    '& .btnHalf': {
      flexBasis: '50%'
    }
  },
  cardChip: {
    marginRight: 6
  },
  cardImage: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    cursor: 'pointer',

    '& > svg': {
      width: 20,
      height: 20
    }
  },
  valuesRow: {
    display: 'flex'
  },
  [`@media screen and (${phonesDown})`]: {
    cardInfo: {
      display: 'block',
      marginBottom: 24
    },
    cardName: {
      fontSize: 18,
      minWidth: '90%',
      maxWidth: '90%',
      marginBottom: 4
    },
    valuesRow: {
      display: 'grid',
      gap: 16,
      marginTop: 16,
      gridTemplateColumns: 'repeat(auto-fit, minmax(90px, 1fr))'
    }
  }
})

export default useStyles
