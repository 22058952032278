import { madeRequest } from '../../helpers/api'
import { ENDPOINTS } from '../../constants/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getDiscountPresetsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.discountPresets
  })
}

export function createDiscountPresetService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.discountPresets
  })
}

export function updateDiscountPresetService(data, id) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.discountPresets}${id}/`
  })
}

export function getPriceChangePeriodsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.priceChangePeriods
  })
}

export function createPriceChangePeriodService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.priceChangePeriods
  })
}

export function updatePriceChangePeriodService(data, id) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.priceChangePeriods}${id}/`
  })
}

export function deletePriceChangePeriodService(id) {
  return madeRequest('DELETE', {
    url: `${ENDPOINTS.priceChangePeriods}${id}/`
  })
}
