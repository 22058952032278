import { createStyles } from '../../../../../../styles/helpers'
import { black, darkGrey } from '../../../../../../styles/const/colors'

export default createStyles({
  historyItem: {
    marginTop: 16,
    '& $historyDate': {
      margin: 0,
      color: '#848484'
    },
    '& $historyText': {
      marginTop: 3,
      color: ({ isUpdated }) => (isUpdated ? black : darkGrey)
    }
  },
  historyDate: {},
  historyText: {}
})
