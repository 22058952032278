import { OPTION_NO, OPTION_YES } from '../../../constants/forms'
import * as Yup from 'yup'

export const AUDIENCES_OPTION = 'audiences_option'
export const CUSTOM_AUDIENCES_EXCLUSION_CHECKED = 'custom_audiences_exclusions_checked'
export const CUSTOM_AUDIENCES = 'custom_audiences'
export const CUSTOM_AUDIENCES_EXCLUSION = 'custom_audiences_exclusions'

export const customAudiencesInitialValues = {
  [AUDIENCES_OPTION]: OPTION_NO,
  [CUSTOM_AUDIENCES]: [],
  [CUSTOM_AUDIENCES_EXCLUSION_CHECKED]: false,
  [CUSTOM_AUDIENCES_EXCLUSION]: []
}

export const customAudiencesValidation = {
  [CUSTOM_AUDIENCES]: Yup.array().when(AUDIENCES_OPTION, {
    is: OPTION_YES,
    then: () => Yup.array().min(1, 'Please select at least one option')
  }),
  [CUSTOM_AUDIENCES_EXCLUSION]: Yup.array().when(CUSTOM_AUDIENCES_EXCLUSION_CHECKED, {
    is: true,
    then: () => Yup.array().min(1, 'Please select at least one option')
  })
}
