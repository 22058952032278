import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import classnames from 'classnames'

import Form from '../../../components/Form'
import UnauthorizedPage from '../UnauthorizedPage'
import Field from '../../../components/Form/Field'
import InternalLink from '../../../components/InternalLink'
import FieldLabelWrapper from '../../../features/components/Form/FieldLabelWrapper'

import { ROUTES } from '../../../constants/routes'

import { useAuthButtonProps } from '../useAuthButtonProps'

import { EMAIL, initialValues, validationSchema } from './fields'
import { resetPassword } from '../../../modules/actions/cognito'
import {
  cognitoLoadingSelector,
  resetPasswordSelector,
  resetPasswordErrorSelector
} from '../../../modules/selectors/cognito'

import useAuthStyles from '../../../styles/common/authPages'
import useDrawerFormsStyles from '../../../styles/common/drawerForms'

function ResetPassword() {
  const { t } = useTranslation()
  const authClasses = useAuthStyles()
  const drawerFormClasses = useDrawerFormsStyles()
  const dispatch = useDispatch()

  const [submittedEmail, setSubmittedEmail] = useState('')

  const { nextStep } = useSelector(resetPasswordSelector)

  const buttonProps = useAuthButtonProps()

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      const email = values[EMAIL]
      setSubmittedEmail(email)
      dispatch(resetPassword(email))
    }
  })

  const resetPasswordAgain = () => {
    dispatch(resetPassword(submittedEmail, true))
  }

  const isSuccess = !!nextStep?.codeDeliveryDetails

  return (
    <UnauthorizedPage helmetTitle="helmetTitle.ResetPasswordPage" className={authClasses.authPage}>
      {!isSuccess ? (
        <>
          <div className={authClasses.formContainer}>
            <Form
              formName="resetPassword"
              formik={formik}
              method="post"
              successSubmit={isSuccess}
              errorSelector={resetPasswordErrorSelector}
              isLoadingSelector={cognitoLoadingSelector}
              submitText={t('Reset password')}
              buttonProps={buttonProps}
            >
              <section className={drawerFormClasses.section}>
                <h3 className={drawerFormClasses.sectionTitle}>Forgotten your password?</h3>
                <p>
                  {t(
                    'No stress, we will send you a link to create a new one. Please enter the email address you registered with.'
                  )}
                </p>
                <div className={authClasses.fieldsContainer}>
                  <FieldLabelWrapper label={'Email'} labelFor="email">
                    <Field formik={formik} id="email" name="email" placeholder={t('Your work email')} />
                  </FieldLabelWrapper>
                </div>
              </section>
            </Form>
          </div>
          <h3 className={classnames(authClasses.authPageSubtitle, authClasses.authPageFooter)}>
            {t('Already have an account?')} <InternalLink to={ROUTES.login}>{t('Log in')}</InternalLink>
          </h3>
        </>
      ) : (
        <div className={authClasses.formContainer}>
          <section className={drawerFormClasses.section}>
            <h3 className={drawerFormClasses.sectionTitle}>Check your inbox</h3>
            <p>
              {t('We’ve sent a secure link to')} <strong>{formik.values.email}</strong>{' '}
              {t('that you can use to log in')}
            </p>
            <p>
              {t('Didn’t receive the email? Check your junk folder or')}{' '}
              <span onClick={resetPasswordAgain} className={authClasses.authPageFakeLink}>
                {t(' send email again')}
              </span>
            </p>
          </section>
        </div>
      )}
    </UnauthorizedPage>
  )
}

export default ResetPassword
