import React from 'react'

import DescriptionsSection from '../sections/DescriptionsSection'
import HeadlinesSection from '../sections/HeadlinesSection'
import LongHeadlineSection from '../sections/LongHeadlineSection'
import ImagesSection from '../sections/ImagesSection'
import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../../components/Form/Field'

import useProductsAndPages from '../../../../../../ReusableFormFields/MediaFields/ItemMediaFields/ClickThroughLinkField/useProductsAndPages'

import { googleAdsCallToActionList } from '../../../../../../../constants/selectLists/googleAdsCallToActionList'
import { CALL_TO_ACTION } from '../../../../../../ReusableFormFields/CallToActionFields/fields'
import { CLICK_THROUGH_LINK, NAME } from '../../../../fields'

const AdDetails = ({
  groupedSuggestions,
  showImagesSection,
  formik,
  adAccountId,
  showNameField,
  showCustomClickThroughLink
}) => {
  const formattedPageLinksList = useProductsAndPages()

  return (
    <>
      {showNameField && (
        <FieldRow title="Name">
          <Field placeholder="Name" formik={formik} name={NAME} />
        </FieldRow>
      )}
      <HeadlinesSection formik={formik} suggestions={groupedSuggestions.headline} />
      <DescriptionsSection formik={formik} suggestions={groupedSuggestions.description} />
      <LongHeadlineSection formik={formik} suggestions={groupedSuggestions.long_headline} />

      {showImagesSection && <ImagesSection formik={formik} adAccountId={adAccountId} />}

      <FieldRow title="Call to action">
        <Field placeholder="Call To Action" formik={formik} name={CALL_TO_ACTION} options={googleAdsCallToActionList} />
      </FieldRow>
      <FieldRow title="Page link" description="Which category or product page the ad will click through to.">
        <Field
          formik={formik}
          placeholder="Page link"
          name={CLICK_THROUGH_LINK}
          // if showCustomClickThroughLink is true - we don't show select, but just "text" input
          options={showCustomClickThroughLink ? null : formattedPageLinksList}
        />
      </FieldRow>
    </>
  )
}

export default AdDetails
