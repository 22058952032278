import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import HighlightedValueSelect from '../../../../components/HighlightedValueSelect'

import { selectedControllerRelatedSelfAccountsListSelector } from '../../../../modules/selectors/app'

import useStyles from './styles'

const AccountFilter = ({ accountFilterOption, setAccountFilterOption, className }) => {
  const classes = useStyles()
  const controllerSelfAccountsList = useSelector(selectedControllerRelatedSelfAccountsListSelector)

  const onAccountFilterChange = useCallback(
    option => {
      setAccountFilterOption(option?.value)
    },
    [setAccountFilterOption]
  )

  return (
    <HighlightedValueSelect
      className={classnames(classes.accountFilter, className)}
      value={accountFilterOption}
      onChange={onAccountFilterChange}
      options={controllerSelfAccountsList}
      placeholder="Account"
      isSmall
    />
  )
}

AccountFilter.propTypes = {
  className: PropTypes.string,
  accountFilterOption: PropTypes.number,
  setAccountFilterOption: PropTypes.func
}

export default AccountFilter
