import React, { useCallback } from 'react'

import DownloadPdfButton from '../../../../../features/components/PdfGeneration/DownloadPdfButton'

import useGetAccessibleUrl from '../../../../../features/hooks/useGetAccessibleUrl'

function DownloadContract({ pdfUrl, className }) {
  const { handleFileDownloading, isAccessibleUrlLoading } = useGetAccessibleUrl()

  const handleFileDownload = useCallback(() => {
    handleFileDownloading(pdfUrl)
  }, [handleFileDownloading, pdfUrl])

  return <DownloadPdfButton className={className} onClick={handleFileDownload} isLoading={isAccessibleUrlLoading} />
}

export default DownloadContract
