import { createStyles } from '../../../../../../styles/helpers'

const useStyles = createStyles({
  tableActions: {
    marginBottom: '8px'
  },
  copyTableBtn: {
    width: '128px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    marginLeft: 'auto'
  }
})

export default useStyles
