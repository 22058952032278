import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import SignatureCanvas from 'react-signature-canvas'
import dataURLtoBlob from 'blueimp-canvas-to-blob'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'

import SignHere from '../../../../../../assets/icons/signHere.svg'

import Form from '../../../../../../components/Form'
import ActionText from '../../../../../../components/ActionText'
import FieldRow from '../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../components/Form/Field'
import ErrorMessage from '../../../../../../components/Form/ErrorMessage'
import FieldsSection from '../../../../../../features/components/Form/FieldsSection'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'

import { getCurrentUserSelector, selectedControllerIdSelector } from '../../../../../../modules/selectors/app'

import { getInitialValues, NAME, SIGNATURE_FILE, validationSchema } from './fields'
import { SIGN_CONTRACT } from '../../../../../../constants/forms'

import { darkGrey, greyOutline } from '../../../../../../styles/const/colors'
import useStyles from './styles'

const CreateSignatureForm = ({ formProps, onSuccessSubmit }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [signatureError, setSignatureError] = useState('')

  const { handleSubmit, contractWasSignedSelector, errorSelector, isLoadingSelector, clearHandler } = formProps

  const controllerId = useSelector(selectedControllerIdSelector)
  const contractWasSigned = useSelector(contractWasSignedSelector)
  const { full_name: name } = useSelector(getCurrentUserSelector)

  const signatureRef = useRef(null)

  const onSubmit = useCallback(
    values => {
      if (signatureRef.current.isEmpty()) {
        setSignatureError('Signature is required')
      } else {
        // get the signature and submit it

        // get signature as base64
        const signatureDataURL = signatureRef.current.toDataURL('image/png')
        // convert base64 in to binary(blob)
        const blobFile = dataURLtoBlob(signatureDataURL)
        // Now, convert this blob to a File object
        const signatureFile = new File([blobFile], 'signature.png', {
          type: 'image/png'
        })

        handleSubmit({
          [NAME]: values[NAME],
          [SIGNATURE_FILE]: signatureFile,
          controller: controllerId
        })
      }
    },
    [controllerId, handleSubmit]
  )

  const initialValues = useMemo(() => getInitialValues(name), [name])
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const handleClearSignature = useCallback(() => {
    signatureRef?.current.clear()
  }, [signatureRef])

  return (
    <Form
      formName={SIGN_CONTRACT}
      formik={purifiedFormik}
      submitText="Apply Signature"
      successSubmit={contractWasSigned}
      onSuccessSubmit={onSuccessSubmit}
      errorSelector={errorSelector}
      isLoadingSelector={isLoadingSelector}
      clearHandler={clearHandler}
      buttonProps={{ solid: false }}
      formFooterClassName={classes.applyBtn}
    >
      <div>
        <FieldsSection title={t('Sign')} isDark>
          <FieldRow title={t('Your name')}>
            <Field formik={formik} name={NAME} placeholder="Name" autoComplete="off" />
          </FieldRow>
          <FieldRow
            title={t('Draw your signature')}
            footer={
              <>
                <div className={classes.signatureDrawerContainer}>
                  <img className={classes.signHereIcon} src={SignHere} alt="sign here" />

                  <div>
                    <SignatureCanvas
                      penColor="black"
                      ref={signatureRef}
                      canvasProps={{
                        className: classes.signElement
                      }}
                    />
                  </div>
                  <ActionText
                    className={classes.clearButton}
                    onClick={handleClearSignature}
                    customColorSchema={{
                      color: greyOutline,
                      hoverColor: darkGrey,
                      touchedColor: darkGrey
                    }}
                    isBold
                  >
                    {t('Clear')}
                  </ActionText>
                  <hr className={classes.horizontalLine} />
                </div>
                {signatureError && <ErrorMessage error={signatureError} />}
              </>
            }
          ></FieldRow>
        </FieldsSection>
        <h4 className={classes.terms}>{t('I understand that this is a legal representation of my signature')}</h4>
      </div>
    </Form>
  )
}

CreateSignatureForm.propTypes = {
  onSuccessSubmit: PropTypes.func,
  formProps: PropTypes.shape({
    handleSubmit: PropTypes.func.isRequired,
    contractWasSignedSelector: PropTypes.func.isRequired,
    errorSelector: PropTypes.func.isRequired,
    isLoadingSelector: PropTypes.func.isRequired,
    clearHandler: PropTypes.func.isRequired
  }).isRequired
}

export default CreateSignatureForm
