import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { addMonths, startOfMonth } from 'date-fns'

import TableWrapper from '../../UI/TableWrapper'
import BookedRevenueTable from './BookedRevenueTableData'
import InfoBlock from '../../../../../../../features/components/InfoBlock'
import TableDataLoader from '../../../../../../../components/Table/TableDataLoader'
import { BookedRevenueContext, BookedRevenueDataProvider } from './BookedRevenueContext'
import BreakdownFilter from '../../../../../../../features/components/Filters/BreakdownFilter'

import { formatDateToBE } from '../../../../../../../constants/dates'

import {
  getBookedRevenueReport,
  clearGetBookedRevenueReport
} from '../../../../../../../modules/actions/mediaOrdersBookings'
import {
  bookedRevenueReportSelector,
  getBookedRevenueReportErrorSelector,
  getBookedRevenueReportIsLoadingSelector,
  getBookedRevenueReportWasLoadedSelector
} from '../../../../../../../modules/selectors/mediaOrdersBookings'
import { selectedControllerIdSelector } from '../../../../../../../modules/selectors/app'

import useCommonTableStyles from '../../../../../../../styles/common/table'

export const tableColumnsSize = {
  month: 80
}
const BreakdownTable = ({ setupData }) => {
  const { t } = useTranslation()
  const commonClasses = useCommonTableStyles()

  const bookedRevenueReport = useSelector(bookedRevenueReportSelector)
  const bookedRevenueReportError = useSelector(getBookedRevenueReportErrorSelector)
  const selfAccountControllerId = useSelector(selectedControllerIdSelector)

  const dispatch = useDispatch()

  const tableFilters = setupData?.filters
  const handleDataFetch = useCallback(
    filterRequestParams => {
      // start of current month:
      const startDate = startOfMonth(new Date())
      // end in 6months
      const endDate = addMonths(startOfMonth(new Date()), 6)
      dispatch(
        getBookedRevenueReport({
          params: {
            controller_id: selfAccountControllerId,
            ...filterRequestParams,
            date_start_after: formatDateToBE(startDate),
            date_start_before: formatDateToBE(endDate),
            ...tableFilters
          },
          loadOptions: {
            shouldClearExistingState: true
          }
        })
      )
    },
    [dispatch, selfAccountControllerId, tableFilters]
  )

  useEffect(() => {
    return () => {
      dispatch(clearGetBookedRevenueReport())
    }
  }, [dispatch])

  const skeletonProps = useMemo(() => {
    return {
      cols: [
        {
          header: 'Name'
        },
        {
          header: 'Month',
          style: { maxWidth: tableColumnsSize.month }
        },
        {
          header: 'Month',
          style: { maxWidth: tableColumnsSize.month }
        },
        {
          header: 'Month',
          style: { maxWidth: tableColumnsSize.month }
        },
        {
          header: 'Month',
          style: { maxWidth: tableColumnsSize.month }
        },
        {
          header: 'Month',
          style: { maxWidth: tableColumnsSize.month }
        },
        {
          header: 'Month',
          style: { maxWidth: tableColumnsSize.month }
        }
      ]
    }
  }, [])

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContentInTheMiddleOfTheTable}>
        <div>{t('No revenue')}</div>
        <br />
      </div>
    ),
    [commonClasses.noDataContentInTheMiddleOfTheTable, t]
  )

  if (bookedRevenueReportError) {
    return (
      <InfoBlock title={t('Sorry, something went wrong')} centered greyDescription>
        <div>{t('Please try again later')}</div>
      </InfoBlock>
    )
  }

  return (
    <BookedRevenueDataProvider
      onFiltersChange={handleDataFetch}
      initialBreakdownFilter={setupData?.default_filters?.breakdown}
    >
      <TableWrapper title={t('Booked Revenue Breakdown')} Filters={<BreakdownFilter Context={BookedRevenueContext} />}>
        <TableDataLoader
          itemsLength={bookedRevenueReport?.length}
          errorSelector={getBookedRevenueReportErrorSelector}
          wasLoadedSelector={getBookedRevenueReportWasLoadedSelector}
          isLoadingSelector={getBookedRevenueReportIsLoadingSelector}
          skeletonProps={skeletonProps}
          noDataContent={noDataContent}
        >
          <BookedRevenueTable />
        </TableDataLoader>
      </TableWrapper>
    </BookedRevenueDataProvider>
  )
}

export default BreakdownTable
