import * as Yup from 'yup'

export const REASON = 'reason'

export const initialValues = {
  [REASON]: ''
}

export const validationSchema = Yup.object({
  [REASON]: Yup.string().required('Please enter a disapprove reason')
})
