import { add, isPast, set } from 'date-fns'
import { formatDateWithTimeToBE } from '../../../../../../helpers/date'

export const formatStartDate = startDate => {
  const initialStartDate = set(new Date(startDate), {
    hours: 0,
    minutes: 0
  })

  // if start date is in past > set final start date as (current time + one minute)
  if (isPast(initialStartDate)) {
    return formatDateWithTimeToBE(add(new Date(), { minutes: 1 }))
  }

  return formatDateWithTimeToBE(initialStartDate)
}

export const formatEndDate = endDate => {
  return formatDateWithTimeToBE(
    set(new Date(endDate), {
      hours: 23,
      minutes: 59
    })
  )
}

export const createTikTokGender = gender => {
  switch (gender) {
    case 'MALE':
      return 'GENDER_MALE'
    case 'FEMALE':
      return 'GENDER_FEMALE'
    case 'ALL':
    default:
      return 'GENDER_UNLIMITED'
  }
}
