import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import MediaFilesLibrary from '../../../../../../../ReusableFormFields/MediaFilesLibrary'

import { getTikTokVideos, clearTikTokVideos } from '../../../../../../../../modules/actions/mediaFiles'
import {
  tikTokVideosAfterSelector,
  tikTokVideosErrorSelector,
  tikTokVideosIsLoadingSelector,
  tikTokVideosNextSelector,
  tikTokVideosSelector,
  tikTokVideosWasLoadedSelector
} from '../../../../../../../../modules/selectors/mediaFiles'
import { selectedAdAccountIdSelector } from '../../../../../../../../modules/selectors/app'

import { initialTikTokVideoFileValues, VIDEO_THUMBNAIL_URL } from '../../../fields'
import {
  FILE_ID,
  FILE_NAME,
  FILE_TYPE,
  FILE_URL,
  MEDIA_FILE_VIDEO_USE_LIBRARY,
  MEDIA_TYPE
} from '../../../../../../../ReusableFormFields/AdFileUpload/fields'

const TikTokVideoFilesLibrary = ({ formik, adAccountId, onVideoRemoveHandler }) => {
  const dispatch = useDispatch()

  const { setValues, values, setTouched, touched } = formik

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const tikTokVideosAfter = useSelector(tikTokVideosAfterSelector)

  // when ad account is manually passed from outside, use it instead of selected
  const providedAdAccountId = adAccountId || selectedAdAccountId

  const loadInitialVideosHandler = useCallback(() => {
    dispatch(
      getTikTokVideos({
        account: providedAdAccountId,
        limit: 12
      })
    )
  }, [dispatch, providedAdAccountId])

  const loadMoreVideosHandler = useCallback(() => {
    dispatch(getTikTokVideos({ account: providedAdAccountId, after: tikTokVideosAfter, limit: 12 }))
  }, [dispatch, providedAdAccountId, tikTokVideosAfter])

  const handleVideoFileRemove = useCallback(() => {
    setValues({ ...values, ...initialTikTokVideoFileValues })
    // run remove video callback from outside (reset state used for generating thumbnails)
    onVideoRemoveHandler && onVideoRemoveHandler()
  }, [setValues, values, onVideoRemoveHandler])

  const handleVideoFileSelect = useCallback(
    (selectedMedia, fileType) => {
      const { name, id, url } = selectedMedia

      const newFileValues = {
        ...initialTikTokVideoFileValues,
        [FILE_NAME]: name || id,
        [FILE_URL]: url,
        [FILE_ID]: id,
        [FILE_TYPE]: fileType,
        [MEDIA_TYPE]: MEDIA_FILE_VIDEO_USE_LIBRARY
      }

      // also reset touched when selecting new video
      setTouched({ ...touched, [FILE_ID]: false, [VIDEO_THUMBNAIL_URL]: false })
      setValues({ ...values, ...initialTikTokVideoFileValues, ...newFileValues })
      // run remove video callback from outside (reset state used for generating thumbnails)
      onVideoRemoveHandler && onVideoRemoveHandler()
    },
    [onVideoRemoveHandler, setTouched, setValues, touched, values]
  )

  const clearTikTokVideosHandler = useCallback(() => {
    dispatch(clearTikTokVideos())
  }, [dispatch])

  return (
    <MediaFilesLibrary
      values={values}
      type="video"
      mediaThumbnailFieldName="poster_url"
      fileNameKey={FILE_NAME}
      fileURLKey={FILE_URL}
      uploadedMediaTypeKey={MEDIA_TYPE}
      loadInitialMediaHandler={loadInitialVideosHandler}
      loadMoreHandler={loadMoreVideosHandler}
      clearMediaHandler={clearTikTokVideosHandler}
      mediaType={MEDIA_FILE_VIDEO_USE_LIBRARY}
      mediaFilesSelector={tikTokVideosSelector}
      mediaFilesIsLoadingSelector={tikTokVideosIsLoadingSelector}
      mediaFilesErrorSelector={tikTokVideosErrorSelector}
      mediaFilesWasLoadedSelector={tikTokVideosWasLoadedSelector}
      mediaFilesNextSelector={tikTokVideosNextSelector}
      onMediaSelect={handleVideoFileSelect}
      onFileRemove={handleVideoFileRemove}
    />
  )
}

TikTokVideoFilesLibrary.propTypes = {
  formik: PropTypes.object.isRequired,
  adAccountId: PropTypes.string,
  onVideoRemoveHandler: PropTypes.func.isRequired
}

export default TikTokVideoFilesLibrary
