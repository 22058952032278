import React, { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import PrimaryTextField from './PrimaryTextField'
import ProductMediaSection from './ProductMediaSection'
import Field from '../../../../../components/Form/Field'
import CallToActionFields from '../../CallToActionFields'
import FacebookMediaUpload from '../../../../ReusableFormFields/FacebookMediaUpload'
import FieldRow from '../../../../../features/components/Form/FieldsSection/FieldRow'
import TextSuggestions from '../../../../../features/components/Form/TextSuggestions'

import { concat } from '../../../../../helpers/common'

import {
  adCreativeSuggestionsLoadingSelector,
  adCreativeSuggestionsSelector
} from '../../../../../modules/selectors/tools'

import { descriptionMaxLength, headlineMaxLength } from '../../../AdForms/validation'

import { getFileDetailsError } from '../../../AdForms/AdFacebookCreate/AdFacebookCreateForm/validation'
import { singleMediaValidation } from '../../../AdForms/AdFacebookCreate/AdFacebookCreateForm/validation/mediaValidation'
import { AD_LINK, DESCRIPTIONS_LIST, FILE_URL, HEADLINES_LIST, NAME, SINGLE_MEDIA_ITEM } from '../../../AdForms/fields'
import { CALL_TO_ACTION } from '../../../../ReusableFormFields/CallToActionFields/fields'
import { AdFacebookCreateContext } from '../../../formsContexts/AdFacebookCreateFormContextProvider'

const itemPath = SINGLE_MEDIA_ITEM

const FacebookProductAdFields = ({
  formik,
  adAccountId,
  galleryImages,
  allowMediaUpload = true,
  showCustomClickThroughLink = false,
  showNameField = false
}) => {
  const { values, setFieldValue, errors, touched } = formik

  const suggestions = useSelector(adCreativeSuggestionsSelector)
  const suggestionsLoading = useSelector(adCreativeSuggestionsLoadingSelector)

  const { campaignObjective } = useContext(AdFacebookCreateContext)

  const AD_HEADLINE_VALUES_KEY = concat(itemPath, '.', HEADLINES_LIST, '[0].text')
  const AD_DESCRIPTION_VALUES_KEY = concat(itemPath, '.', DESCRIPTIONS_LIST, '[0].text')

  const AD_LINK_VALUES_KEY = concat(itemPath, '.', AD_LINK)
  const CTA_VALUES_KEY = concat(itemPath, '.', CALL_TO_ACTION)

  const singleMediaItemErrors = errors[itemPath] || {}
  const singleMediaItemTouched = touched[itemPath] || {}

  const fileDetailsError = getFileDetailsError(singleMediaItemErrors)
  const fileUrlError = singleMediaItemErrors[FILE_URL]
  const fileUrlTouched = singleMediaItemTouched[FILE_URL]

  const selectedHeadline = values[AD_HEADLINE_VALUES_KEY]
  const selectedDescription = values[AD_DESCRIPTION_VALUES_KEY]

  const groupedSuggestions = {
    primary_text: [],
    headline: [],
    description: []
  }

  suggestions?.forEach(suggestion => {
    groupedSuggestions.primary_text.push(suggestion.primary_text)
    groupedSuggestions.headline.push(suggestion.headline)
    groupedSuggestions.description.push(suggestion.description)
  })

  const handleHeadlineChange = useCallback(
    value => {
      setFieldValue(SINGLE_MEDIA_ITEM, {
        ...values[SINGLE_MEDIA_ITEM],
        [HEADLINES_LIST]: [
          {
            text: value
          }
        ]
      })
    },
    [setFieldValue, values]
  )
  const handleDescriptionChange = useCallback(
    value =>
      setFieldValue(SINGLE_MEDIA_ITEM, {
        ...values[SINGLE_MEDIA_ITEM],
        [DESCRIPTIONS_LIST]: [
          {
            text: value
          }
        ]
      }),
    [setFieldValue, values]
  )

  return (
    <>
      {showNameField && (
        <FieldRow title="Name" description="An ad name to identify this ad in reporting">
          <Field formik={formik} name={NAME} placeholder="Ad Name" autoComplete="off" enableReinitialize />
        </FieldRow>
      )}
      <PrimaryTextField formik={formik} suggestions={groupedSuggestions.primary_text} />
      {galleryImages ? (
        <ProductMediaSection
          adAccountId={adAccountId}
          formik={formik}
          galleryImages={galleryImages}
          allowMediaUpload={allowMediaUpload}
        />
      ) : (
        <FacebookMediaUpload
          formik={formik}
          mediaUrlError={fileDetailsError || (fileUrlTouched && fileUrlError)}
          adAccountId={adAccountId}
          itemPath={itemPath}
          mediaValidationSchema={singleMediaValidation}
          initialExistedLoadSize={3}
        />
      )}
      <FieldRow
        title="Headline"
        description="45 characters or less recommended"
        footer={
          <TextSuggestions
            selectedSuggestion={selectedHeadline}
            showLogo={false}
            suggestions={groupedSuggestions.headline}
            onSelectSuggestion={handleHeadlineChange}
            typingAnimation={suggestionsLoading}
          />
        }
      >
        <Field
          placeholder="Headline"
          label="HEADLINE"
          formik={formik}
          name={AD_HEADLINE_VALUES_KEY}
          isTextarea
          maxLength={headlineMaxLength}
          enableReinitialize
        />
      </FieldRow>
      <FieldRow
        title="Description (optional)"
        description="27 characters or less recommended"
        footer={
          <TextSuggestions
            selectedSuggestion={selectedDescription}
            showLogo={false}
            suggestions={groupedSuggestions.description}
            onSelectSuggestion={handleDescriptionChange}
            typingAnimation={suggestionsLoading}
          />
        }
      >
        <Field
          placeholder="Description"
          label="DESCRIPTION"
          formik={formik}
          name={AD_DESCRIPTION_VALUES_KEY}
          maxLength={descriptionMaxLength}
          isTextarea
          enableReinitialize
        />
      </FieldRow>
      <CallToActionFields formik={formik} fieldName={CTA_VALUES_KEY} campaignObjective={campaignObjective} />
      {showCustomClickThroughLink && (
        <FieldRow title="Click through link" description="Please add a click through link">
          <Field formik={formik} name={AD_LINK_VALUES_KEY} placeholder="Click through link" autoComplete="off" />
        </FieldRow>
      )}
    </>
  )
}

FacebookProductAdFields.propTypes = {
  formik: PropTypes.object.isRequired,
  adAccountId: PropTypes.string.isRequired,
  galleryImages: PropTypes.array,
  allowMediaUpload: PropTypes.bool,
  showCustomClickThroughLink: PropTypes.bool,
  showNameField: PropTypes.bool
}

export default FacebookProductAdFields
