import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'

import Form from '../../../../../components/Form'
import ControllerPermissionsEditFormContent from './ControllerPermissionsEditFormContent'

import {
  clearUpdateControllerMember,
  updateControllerMember,
  updateControllerMemberSuccess
} from '../../../../../modules/actions/controller'
import {
  updatedControllerMemberErrorSelector,
  updatedControllerMemberIsLoadingSelector,
  updatedControllerMemberSelector
} from '../../../../../modules/selectors/controller'

import { transformValuesToBE } from './formatters'
import { isEqual } from '../../../../../helpers/common'

import { CONTROLLER_PERMISSIONS_EDIT } from '../../../../../constants/forms'

import { getInitialValues } from './fields'

const ControllerPermissionsEditForm = ({ memberData = {} }) => {
  const dispatch = useDispatch()

  const { id: memberId } = memberData

  const { wasUpdated: controllerMemberWasUpdated } = useSelector(updatedControllerMemberSelector)

  const initialValues = useMemo(() => getInitialValues(memberData), [memberData])

  const clearUpdateCategoryPageHandler = useCallback(() => {
    dispatch(clearUpdateControllerMember())
  }, [dispatch])

  const onSubmit = useCallback(
    values => {
      const hasChanges = !isEqual(values, initialValues)
      const formattedData = transformValuesToBE(values)

      if (hasChanges) {
        dispatch(updateControllerMember(memberId, formattedData))
      } else {
        dispatch(updateControllerMemberSuccess(formattedData))
      }
    },
    [dispatch, memberId, initialValues]
  )

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true
  })

  return (
    <Form
      formName={CONTROLLER_PERMISSIONS_EDIT}
      formik={formik}
      successSubmit={controllerMemberWasUpdated}
      errorSelector={updatedControllerMemberErrorSelector}
      isLoadingSelector={updatedControllerMemberIsLoadingSelector}
      clearHandler={clearUpdateCategoryPageHandler}
    >
      <ControllerPermissionsEditFormContent formik={formik} />
    </Form>
  )
}

export default ControllerPermissionsEditForm
