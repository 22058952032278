import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import MediaOrderNameCell from '../../../../features/components/MediaOrderTableCells/MediaOrderNameCell'
import RequestedFilesField from './RequestedFilesField'
import Table from '../../../../components/Table'
import MediaField from './MediaField'
import MediaOrderCostCell from '../../../../features/components/MediaOrderTableCells/MediaOrderCostCell'
import AdaptiveActions from '../../../../features/components/AdaptiveActions'

import { generateMediaFieldText } from './helpers'
import { useRedirectToMediaOrderSummary } from './hooks'

import { tableColumnsSize, dropdownColumnSizes, mediaColumnSizesMobile } from '../columnSizes'
import { phonesDownSize } from '../../../../styles/const/breakpoints'
import { red } from '../../../../styles/const/colors'

const MediaOrdersTableData = ({ mediaOrders }) => {
  const { t } = useTranslation()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const goToMediaOrderSummaryHandler = useRedirectToMediaOrderSummary()

  const actionOptions = useMemo(() => {
    return [
      {
        text: t('Go to media order'),
        onClickHandler: (e, id) => goToMediaOrderSummaryHandler(id)
      }
    ]
  }, [t, goToMediaOrderSummaryHandler])

  const mediaOrdersColumns = useMemo(
    () => [
      {
        header: 'ID',
        Cell: ({ sequential_id, id }) => <span onClick={() => goToMediaOrderSummaryHandler(id)}>{sequential_id}</span>,
        style: { maxWidth: tableColumnsSize.id, cursor: 'pointer' }
      },
      {
        header: 'Name',
        Cell: mediaOrder => (
          <MediaOrderNameCell
            mediaOrder={mediaOrder}
            onClick={isMobile ? undefined : goToMediaOrderSummaryHandler}
            showIcon={!isMobile}
          />
        )
      },
      {
        header: 'Media',
        Cell: ({ products_names: productsNames }) => {
          const { fieldName, moreItemText } = generateMediaFieldText(productsNames)
          return <MediaField mediaText={fieldName} moreProductsText={moreItemText} />
        },
        showOnMobile: false,
        style: isMobile
          ? mediaColumnSizesMobile
          : { maxWidth: tableColumnsSize.media, minWidth: tableColumnsSize.media - 200 }
      },
      {
        header: 'Requested Files',
        Cell: ({ outstanding_required_files, id }) => {
          const hasRequiredFiles = outstanding_required_files > 0
          return (
            hasRequiredFiles && (
              <RequestedFilesField
                id={id}
                onClick={goToMediaOrderSummaryHandler}
                outstandingRequiredFiles={outstanding_required_files}
              />
            )
          )
        },
        showOnMobile: false,
        style: { maxWidth: tableColumnsSize.requestedFiles }
      },
      {
        header: 'Cost',
        Cell: mediaOrder => <MediaOrderCostCell mediaOrder={mediaOrder} />,
        showOnMobile: false,
        // numerical column right aligned
        style: { maxWidth: tableColumnsSize.cost, justifyContent: 'flex-end' }
      },
      {
        Cell: ({ id }) => {
          return <AdaptiveActions itemId={id} options={actionOptions} />
        },
        style: isMobile ? { padding: 0 } : { ...dropdownColumnSizes },
        showOnMobile: false
      }
    ],
    [actionOptions, goToMediaOrderSummaryHandler, isMobile]
  )

  const rowTemplate = useMemo(() => {
    return {
      // product group has marker on the row
      determineRowMarker: rowData => rowData.status === 'cancelled',
      markerColor: red
    }
  }, [])

  return <Table data={mediaOrders} rowTemplate={rowTemplate} cols={mediaOrdersColumns} hideFooterRow />
}

MediaOrdersTableData.propTypes = {
  mediaOrders: PropTypes.array.isRequired
}

export default MediaOrdersTableData
