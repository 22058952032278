import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Geolocations from '../../../../../ReusableFormFields/GeolocationsFields/Geolocations'
import Button from '../../../../../../components/Button'

import {
  SHOW_COUNTRIES,
  SHOW_REGIONS,
  LOCATION_LIST
} from '../../../../../ReusableFormFields/GeolocationsFields/fields'
import { TIKTOK_PLATFORM } from '../../../../../../constants/selectLists/platformList'

import useStyles from '../../../../../../styles/common/stepForms'

const stepFields = [SHOW_COUNTRIES, SHOW_REGIONS, LOCATION_LIST]

const GeolocationsStep = ({ formik, handleStepChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  // show only countries with tiktok_id
  const countriesFilter = useCallback(country => country['tiktok_id'], [])

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create a TikTok ad group')}</div>
      <div className={classes.stepTitle}>{t('Where would you like the ads to be shown?')}</div>
      <div className={classes.stepBody}>
        <Geolocations
          formik={formik}
          countriesFilter={countriesFilter}
          showRegionsCountryFilter={false}
          showCustomLocations={false}
          showWorldwide={false}
          platform={TIKTOK_PLATFORM}
        />
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" className="dark" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default GeolocationsStep
