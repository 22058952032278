import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect, useMemo } from 'react'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import ControllerMemberEditForm from './ControllerMemberEditForm'

import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'
import useManageEditFormData from '../../../../hooks/formHooks/useManageEditFormData'

import { getSelfAccountCategoriesIsLoadingSelector } from '../../../../modules/selectors/selfAccounts'
import { clearGetSelfAccountCategories, getSelfAccountCategories } from '../../../../modules/actions/selfAccounts'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import { clearGetControllerMember, getControllerMember } from '../../../../modules/actions/controller'
import { controllerMemberSelector, controllerMemberWasLoadedSelector } from '../../../../modules/selectors/controller'

import { CONTROLLER_MEMBER_EDIT } from '../../../../constants/forms'

const ControllerMemberEdit = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const controllerId = useSelector(selectedControllerIdSelector)
  const getSelfAccountCategoriesIsLoading = useSelector(getSelfAccountCategoriesIsLoadingSelector)
  const controllerMember = useSelector(controllerMemberSelector)
  const controllerMemberWasLoaded = useSelector(controllerMemberWasLoadedSelector)

  const { selectedEditItemId, isFormOpen } = useManageFormsDrawer({
    formName: CONTROLLER_MEMBER_EDIT
  })

  const getControllerMemberDataHandler = useCallback(() => {
    dispatch(getControllerMember({ id: selectedEditItemId }))
  }, [dispatch, selectedEditItemId])

  const clearControllerMemberDataHandler = useCallback(() => {
    dispatch(clearGetControllerMember())
  }, [dispatch])

  useManageEditFormData({
    formName: CONTROLLER_MEMBER_EDIT,
    loadedDataId: controllerMember.id,
    getDataHandler: getControllerMemberDataHandler,
    clearDataHandler: clearControllerMemberDataHandler
  })

  const isFormLoading = useMemo(() => {
    return !controllerMemberWasLoaded || getSelfAccountCategoriesIsLoading
  }, [controllerMemberWasLoaded, getSelfAccountCategoriesIsLoading])

  useEffect(() => {
    if (isFormOpen) {
      dispatch(
        getSelfAccountCategories({
          controller: controllerId
        })
      )
    } else {
      dispatch(clearGetSelfAccountCategories())
    }
  }, [dispatch, controllerId, isFormOpen])

  return (
    <FormDrawerWrapper
      isFormLoading={isFormLoading}
      formName={CONTROLLER_MEMBER_EDIT}
      title={t('Edit controller member')}
      showOpenButton={false}
    >
      <ControllerMemberEditForm />
    </FormDrawerWrapper>
  )
}

export default ControllerMemberEdit
