import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

// get controllers selectors
export const controllersSelector = createSelector(
  ({ controller }) => controller.controllers.results,
  results => results
)

export const controllersIsLoadingSelector = createSelector(
  ({ controller }) => controller.controllers.isLoading,
  isLoading => isLoading
)

export const controllersWasLoadedSelector = createSelector(
  ({ controller }) => controller.controllers.wasLoaded,
  wasLoaded => wasLoaded
)

export const controllersErrorSelector = createSelector(
  ({ controller }) => controller.controllers.error,
  error => error
)

export const controllerMembersSelector = createSelector(
  ({ controller }) => controller.controllerMembers.members.results,
  results => results
)

export const controllerMembersIsLoadingSelector = createSelector(
  ({ controller }) => controller.controllerMembers.members.isLoading,
  isLoading => isLoading
)

export const controllerMembersWasLoadedSelector = createSelector(
  ({ controller }) => controller.controllerMembers.members.wasLoaded,
  wasLoaded => wasLoaded
)

export const controllerMembersErrorSelector = createSelector(
  ({ controller }) => controller.controllerMembers.members.error,
  error => error
)

export const controllerMembersNextSelector = createSelector(
  ({ controller }) => controller.controllerMembers.members.paging.next,
  next => next
)

export const controllerInvitesSelector = createSelector(
  ({ controller }) => controller.controllerInvites.invites.results,
  results => results
)

export const controllerInvitesIsLoadingSelector = createSelector(
  ({ controller }) => controller.controllerInvites.invites.isLoading,
  isLoading => isLoading
)

export const controllerInvitesWasLoadedSelector = createSelector(
  ({ controller }) => controller.controllerInvites.invites.wasLoaded,
  wasLoaded => wasLoaded
)

export const controllerInvitesErrorSelector = createSelector(
  ({ controller }) => controller.controllerInvites.invites.error,
  error => error
)

export const controllerInvitesNextSelector = createSelector(
  ({ controller }) => controller.controllerInvites.invites.paging.next,
  next => next
)

export const controllerInviteCreateIsLoadingSelector = createSelector(
  ({ controller }) => controller.controllerInvites.createdInvite.isLoading,
  isLoading => isLoading
)

export const controllerInviteWasCreatedSelector = createSelector(
  ({ controller }) => controller.controllerInvites.createdInvite.wasCreated,
  wasCreated => wasCreated
)

export const reinvitedControllerUserSelector = createSelector(
  ({ controller }) => controller.controllerInvites.reinvitedUser,
  reinvitedUser => reinvitedUser
)

export const deletedControllerInvitationSelector = createSelector(
  ({ controller }) => controller.controllerInvites.deletedInvite,
  deletedInvite => deletedInvite
)

export const deletedControllerInviteIsLoadingSelector = createSelector(
  ({ controller }) => controller.controllerInvites.deletedInvite.isLoading,
  isLoading => isLoading
)

export const controllerInviteCreateErrorSelector = createSelector(
  ({ controller }) => controller.controllerInvites.createdInvite.error,
  error => error
)

export const updatedControllerMemberSelector = createSelector(
  ({ controller }) => controller.updateControllerMember,
  updatedMember => updatedMember
)

export const updatedControllerMemberIsLoadingSelector = createSelector(
  ({ controller }) => controller.updateControllerMember.isLoading,
  isLoading => isLoading
)

export const updatedControllerMemberErrorSelector = createSelector(
  ({ controller }) => controller.updateControllerMember.error,
  error => error
)

export const acceptedControllerInviteIsLoadingSelector = createSelector(
  ({ controller }) => controller.controllerInvites.acceptedInvite.isLoading,
  isLoading => isLoading
)

export const acceptedControllerInviteErrorSelector = createSelector(
  ({ controller }) => controller.controllerInvites.acceptedInvite.error,
  error => error
)

export const controllerInviteWasAcceptedSelector = createSelector(
  ({ controller }) => controller.controllerInvites.acceptedInvite.wasAccepted,
  wasAccepted => wasAccepted
)

export const updateControllerSelector = createSelector(
  ({ controller }) => controller.updateController,
  updateController => updateController
)

export const updateControllerIsLoadingSelector = createSelector(
  ({ controller }) => controller.updateController.isLoading,
  isLoading => isLoading
)

export const updateControllerWasUpdatedSelector = createSelector(
  ({ controller }) => controller.updateController.wasUpdated,
  wasUpdated => wasUpdated
)

export const updateControllerErrorSelector = createSelector(
  ({ controller }) => controller.updateController.error,
  error => error
)

// Get controller
export const controllerSelector = createSelector(
  ({ controller }) => controller.controller.data,
  data => data
)

export const getControllerIsLoadingSelector = createSelector(
  ({ controller }) => controller.controller.isLoading,
  isLoading => isLoading
)

export const getControllerErrorSelector = createSelector(
  ({ controller }) => controller.controller.error,
  error => error
)

export const controllerWasLoadedSelector = createSelector(
  ({ controller }) => controller.controller.wasLoaded,
  wasLoaded => wasLoaded
)

// Get controller member
export const controllerMemberSelector = createSelector(
  ({ controller }) => controller.controllerMember.data,
  data => data
)

export const getControllerMemberIsLoadingSelector = createSelector(
  ({ controller }) => controller.controllerMember.isLoading,
  isLoading => isLoading
)

export const getControllerMemberErrorSelector = createSelector(
  ({ controller }) => controller.controllerMember.error,
  error => error
)

export const controllerMemberWasLoadedSelector = createSelector(
  ({ controller }) => controller.controllerMember.wasLoaded,
  wasLoaded => wasLoaded
)
