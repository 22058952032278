import React from 'react'

import CalendarCreateRowForm from './CalendarCreateRowForm'
import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'

import { PLANNER_EVENT_GROUP_CREATE } from '../../../../../constants/forms'

import useStyles from './styles'

// row is the same as event_group
function CalendarCreateRow({ calendarId, eventGroups }) {
  const classes = useStyles()

  return (
    <FormDrawerWrapper
      title={'Create Row'}
      subTitle={'Create a new row'}
      openButtonText={'Add New Row'}
      formName={PLANNER_EVENT_GROUP_CREATE}
      openBtnClassName={classes.newRowBtn}
    >
      <CalendarCreateRowForm calendarId={calendarId} eventGroups={eventGroups} />
    </FormDrawerWrapper>
  )
}

export default CalendarCreateRow
