import { createStyles } from '../../../styles/helpers'

import { darkGrey, textGrey } from '../../../styles/const/colors'
import { transitionShort } from '../../../styles/const/common'

const useStyles = createStyles({
  breadcrumbs: {
    color: darkGrey,
    fontSize: 12,
    marginBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: 4
  },
  spacer: {
    margin: '0 10px'
  },
  breadcrumbsLink: {
    color: darkGrey,
    textDecoration: 'none',
    borderBottom: '1px solid transparent',
    paddingBottom: 1,
    // To avoid shifting the text during the hover effect, a pseudo-element before has been added.
    // This pseudo element is visually hidden, has a bold font,
    // and occupies the width we need due to the same text as the link.
    // This way, the link doesn't require any extra width during the hover effect.
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: transitionShort,
    '&.linkTouched, &:hover, &:active': {
      borderBottom: `1px solid ${darkGrey}`
    },
    '&:before': {
      content: 'attr(title)',
      height: 0,
      visibility: 'hidden',
      overflow: 'hidden',
      userSelect: 'none',
      pointerEvents: 'none',
      fontWeight: 'bold'
    }
  },
  breadCrumb: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
  },
  icon: {
    fontSize: '15px'
  },
  isActive: {
    fontWeight: '600',
    color: textGrey
  }
})

export default useStyles
