import React, { useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'
import { format, isWithinInterval } from 'date-fns'

import { useGetDisabledDates } from './hooks'

import PeriodDateCell from '../../../../../../../../../../../../features/components/MediaOrder/PeriodDateCell'
import SelectedPeriodsList from './SelectedPeriodsList'
import PeriodsDatePicker from './PeriodsDatePicker'

import { findLimitDates, sortByStartDateValue } from './helpers'

import { formatDateToBE } from '../../../../../../../../../../../../constants/dates'

import { SelectPeriodsFieldsContext } from '../../SelectPeriodsFieldsContextProvider'

import { PRODUCT_GROUPED_PUBLICATIONS_DATES } from '../../../../../../../../../fields'

import useStyles from './styles'

// This component allows to select booking period for the product using the date range
// Each of selected date range is represented as a group of periods, in meanwhile we still manage the selected periods separately
// The grouped periods are used ONLY to display the selected date range in this component
// The selected periods are used to manage the selected dates in the form, send to BE and etc..
function DatePickerPeriodSelector({
  formik,
  selectedPeriods,
  periodOptions,
  productItemPath,
  productPublicationsDatesPath
}) {
  const classes = useStyles()

  const { allowToFetchPreviousPeriods, inventoryIsLoading, currencySymbol } = useContext(SelectPeriodsFieldsContext)

  const [limitDates, setLimitDates] = useState(null)
  const { values, setFieldValue } = formik

  const firstPeriod = periodOptions[0]?.value
  const lastPeriod = periodOptions[periodOptions.length - 1]?.value
  // overdue are not available to select by user
  const availablePeriods = periodOptions.filter(period => !period.disabled && !period.overdue)

  const selectedGroupedPeriods = getIn(values, `${productItemPath}.${PRODUCT_GROUPED_PUBLICATIONS_DATES}`)

  const disabledDates = useGetDisabledDates({
    firstPeriod,
    lastPeriod,
    availablePeriods,
    selectedPeriods
  })

  const handleStartDateSelect = useCallback(
    startDate => {
      setLimitDates(findLimitDates(disabledDates, startDate))
    },
    [disabledDates]
  )

  const handleDaysPeriodSelection = useCallback(
    ({ endDate, startDate }) => {
      // select available periods data between selected date range
      const newPeriods = availablePeriods.filter(period => {
        return isWithinInterval(
          // set newDate time to 00:00:00 to compare only dates
          new Date(period.value).setHours(0, 0, 0, 0),
          {
            start: startDate.setHours(0, 0, 0, 0),
            end: endDate.setHours(0, 0, 0, 0)
          }
        )
      })
      setLimitDates(null)

      // push new group of periods to represent selected date range
      const sortedNewGroupPeriods = [
        ...selectedGroupedPeriods,
        // push group of periods to already selected groups
        newPeriods
        // newly added periods should not just be pushed, these should be set by startDate order
      ].sort((a, b) => new Date(a[0].start_date) - new Date(b[0].start_date))
      setFieldValue(`${productItemPath}.${PRODUCT_GROUPED_PUBLICATIONS_DATES}`, sortedNewGroupPeriods)

      // push new periods to manage the selected dates in the form, send to BE and etc..
      const sortedNewPeriods = [
        ...selectedPeriods,
        // push each of new period to already selected periods
        ...newPeriods
        // newly added periods should not just be pushed, these should be set by startDate order
      ].sort(sortByStartDateValue)
      setFieldValue(productPublicationsDatesPath, sortedNewPeriods)
    },
    [
      selectedGroupedPeriods,
      availablePeriods,
      selectedPeriods,
      setFieldValue,
      productItemPath,
      productPublicationsDatesPath
    ]
  )

  const handleClose = useCallback(() => {
    setLimitDates(null)
  }, [])

  // if a user tries to book a broken date period, they can select a later date but system automatically selects the end date as the last continuous day..
  // to avoid that we use limitDates, which are set when user select startDate
  // the idea is to limit the available periods by minDate which will be closest disabled dates
  const minDate = limitDates?.minDate || (firstPeriod && new Date(firstPeriod))

  const CustomDayComponent = useCallback(
    dayDate => {
      const formattedDate = format(dayDate, 'dd')
      // for performance reason we pass only formatted dates as well as make the search for the periodData only inside
      // when the data actually changed
      return (
        <PeriodDateCell
          currencySymbol={currencySymbol}
          formattedDate={formattedDate}
          formattedDateBE={formatDateToBE(dayDate)}
          periodOptions={periodOptions}
        />
      )
    },
    [periodOptions, currencySymbol]
  )

  return (
    <>
      <SelectedPeriodsList
        setFieldValue={setFieldValue}
        selectedPeriods={selectedPeriods}
        selectedGroupedPeriods={selectedGroupedPeriods}
        productItemPath={productItemPath}
        productPublicationsDatesPath={productPublicationsDatesPath}
      />
      <PeriodsDatePicker
        className={classes.dateRange}
        isLoading={inventoryIsLoading}
        onDateRangeChange={handleDaysPeriodSelection}
        minDate={allowToFetchPreviousPeriods ? undefined : minDate}
        disabledDates={disabledDates}
        onStartDateSelect={handleStartDateSelect}
        onClose={handleClose}
        CustomDayComponent={CustomDayComponent}
      />
    </>
  )
}

DatePickerPeriodSelector.propTypes = {
  formik: PropTypes.object,
  periodOptions: PropTypes.array,
  productItemPath: PropTypes.string,
  productPublicationsDatesPath: PropTypes.string,
  selectedPeriods: PropTypes.array
}
export default DatePickerPeriodSelector
