import React from 'react'
import { useTranslation } from 'react-i18next'

import Can from '../../../../features/components/Can'
import CreateOnsiteDigital from './CreateOnsiteDigital'

import { CAMPAIGN_PERMISSION } from '../../../../constants/permissions'

import useStyles from './styles'

const OnsiteDigitalSection = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  // todo add table when we will have some campaigns
  return (
    <div className={classes.container}>
      <p className={classes.emptyDataText}>{t('There are no onsite digital campaigns')}</p>
      <Can I="create" a={CAMPAIGN_PERMISSION}>
        <CreateOnsiteDigital />
      </Can>
    </div>
  )
}

export default OnsiteDigitalSection
