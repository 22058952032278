import { useMemo, useState } from 'react'
import { useTheme } from 'react-jss'

import useStartWeekDay from '../../../../../../../../../../../features/hooks/useStartWeekDay'

import { initialDatePeriod } from '../../../../../../../../../../../constants/dates'

export default function useDateRange() {
  const theme = useTheme()
  const [datePeriod, setDatePeriod] = useState(initialDatePeriod)

  const weekStartsOn = useStartWeekDay()

  const rangeColors = useMemo(() => [theme.brandPrimary], [theme.brandPrimary])
  const rangeSelectedTextColor = useMemo(
    () => theme.brandPrimaryTextContrastColor,
    [theme.brandPrimaryTextContrastColor]
  )

  // ExternalDateRange supports to have multiple ranges in one time, as we use only one - we set only first array element make sure it memoized to avoid new array create each time
  const ranges = useMemo(() => [datePeriod], [datePeriod])

  return useMemo(
    () => ({
      weekStartsOn,
      datePeriod,
      setDatePeriod,
      ranges,
      rangeColors,
      rangeSelectedTextColor
    }),
    [datePeriod, rangeColors, rangeSelectedTextColor, ranges, weekStartsOn]
  )
}
