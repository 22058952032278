import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import {
  GET_AGENCY_MEMBERS,
  getAgencyMembersSuccess,
  getAgencyMembersFailure,
  GET_AGENCY_INVITES,
  getAgencyInvitesSuccess,
  getAgencyInvitesFailure,
  CREATE_AGENCY_INVITE,
  createAgencyInviteSuccess,
  createAgencyInviteFailure,
  ACCEPT_AGENCY_INVITE,
  acceptAgencyInviteSuccess,
  acceptAgencyInviteFailure,
  UPDATE_AGENCY_MEMBER_STATUS,
  updateAgencyMemberStatusSuccess,
  updateAgencyMemberStatusFailure,
  updateAgencyMemberSuccess,
  updateAgencyMemberFailure,
  UPDATE_AGENCY_MEMBER,
  DELETE_AGENCY_INVITE,
  RESEND_AGENCY_INVITE,
  deleteAgencyInviteSuccess,
  deleteAgencyInviteFailure,
  resendAgencyInviteSuccess,
  resendAgencyInviteFailure
} from '../actions/agencyTeam'
import {
  createAgencyInviteService,
  acceptAgencyInviteService,
  deleteAgencyInviteService,
  getAgencyInvitesService,
  getAgencyMembersService,
  resendAgencyInviteService,
  updateAgencyMemberService,
  updateAgencyMemberStatusService
} from '../services/agencyTeam'
import { submitLogin } from '../actions/cognito'
import { getUserInvitesSuccess } from '../actions/app'
import { userInvitesSelector } from '../selectors/app'

import { encryptJWE } from '../../helpers/encrypt'
import { deleteItemById } from '../../helpers/modules/reducerHelpers'

import { EMAIL } from '../../pages/Unauthorized/Login/fields'
import { PASSWORD } from '../../pages/Unauthorized/CreatePassword/fields'

function* agencyTeamWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_AGENCY_MEMBERS, getAgencyMembersWorker),
    takeEvery(GET_AGENCY_INVITES, getAgencyInvitesWorker),
    takeEvery(CREATE_AGENCY_INVITE, createAgencyInviteWorker),
    takeEvery(ACCEPT_AGENCY_INVITE, acceptAgencyInviteWorker),
    takeEvery(UPDATE_AGENCY_MEMBER, updateAgencyMemberWorker),
    takeEvery(UPDATE_AGENCY_MEMBER_STATUS, updateAgencyMemberStatusWorker),
    takeEvery(DELETE_AGENCY_INVITE, deleteAgencyInviteWorker),
    takeEvery(RESEND_AGENCY_INVITE, resendAgencyInviteWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getAgencyMembersWorker({ params }) {
  try {
    const response = yield call(getAgencyMembersService, params)
    yield put(getAgencyMembersSuccess(response))
  } catch (e) {
    yield put(getAgencyMembersFailure(e))
  }
}

function* getAgencyInvitesWorker({ params }) {
  try {
    const response = yield call(getAgencyInvitesService, params)
    yield put(getAgencyInvitesSuccess(response))
  } catch (e) {
    yield put(getAgencyInvitesFailure(e))
  }
}

function* createAgencyInviteWorker({ inviteData }) {
  try {
    const response = yield call(createAgencyInviteService, inviteData)
    yield put(createAgencyInviteSuccess(response))
  } catch (e) {
    yield put(createAgencyInviteFailure(e))
  }
}

function* acceptAgencyInviteWorker({ inviteData, submitLoginOnSuccess, removeInviteOnSuccess }) {
  try {
    const password = inviteData[PASSWORD]
    const encryptedPassword = yield call(encryptJWE, { [PASSWORD]: password })
    // password should be encrypted to token when sends to BE for security

    const response = yield call(acceptAgencyInviteService, { ...inviteData, [PASSWORD]: encryptedPassword })
    yield put(acceptAgencyInviteSuccess())

    if (submitLoginOnSuccess) {
      yield put(
        submitLogin({
          [EMAIL]: response[EMAIL],
          [PASSWORD]: inviteData[PASSWORD]
        })
      )
    }

    if (removeInviteOnSuccess) {
      const invites = yield select(userInvitesSelector)

      const { agency_invites: agencyInvites } = invites

      const filteredAgencyInvites = agencyInvites.filter(item => item.token !== inviteData.token)

      yield put(getUserInvitesSuccess({ ...invites, agency_invites: filteredAgencyInvites }))
    }
  } catch (e) {
    // code is added as key for parsing errors inside the ProgressButton
    yield put(acceptAgencyInviteFailure({ ...e, code: 'encryption_error' }))
  }
}

function* updateAgencyMemberWorker({ id, data }) {
  try {
    const response = yield call(updateAgencyMemberService, id, data)
    yield put(updateAgencyMemberSuccess(response))
  } catch (e) {
    yield put(updateAgencyMemberFailure(e))
  }
}

function* updateAgencyMemberStatusWorker({ id, status }) {
  const isActive = status === 'active'

  try {
    yield call(updateAgencyMemberStatusService, id, status)
    yield put(updateAgencyMemberStatusSuccess({ id, is_active: !isActive }))
  } catch (e) {
    yield put(updateAgencyMemberStatusFailure(e))
  }
}

function* deleteAgencyInviteWorker({ id, removeInviteOnSuccess }) {
  try {
    yield call(deleteAgencyInviteService, id)
    yield put(deleteAgencyInviteSuccess(id))

    if (removeInviteOnSuccess) {
      const invites = yield select(userInvitesSelector)

      const { agency_invites: agencyInvites } = invites

      yield put(getUserInvitesSuccess({ ...invites, agency_invites: deleteItemById(agencyInvites, id) }))
    }
  } catch (e) {
    yield put(deleteAgencyInviteFailure(e))
  }
}

function* resendAgencyInviteWorker({ id }) {
  try {
    yield call(resendAgencyInviteService, id)
    yield put(resendAgencyInviteSuccess(id))
  } catch (e) {
    yield put(resendAgencyInviteFailure(e))
  }
}

export default agencyTeamWatcher
