import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import List from '../List'
import Chip from '../../../components/Chip'
import InternalLink from '../../../components/InternalLink'
import ItemsLoading from '../../../components/Loaders/ItemsLoading'
import SkeletonListRow from '../List/Skeleton'
import ActionsDropdown from '../ActionsDropdown'
import PageSection from '../PageSection'
import PlatformIcon from '../../../components/Table/ReusableCells/PlatformIcon'

import { getStatusTagColor } from '../../helpers/componentsHelpers'
import { insertIf } from '../../../helpers/common'
import { useReusableInsightsColumns } from './Columns/useReusableInsightsColumns'

import { formatListItemValues } from '../../formatters'

import { formatDateShort } from '../../../constants/dates'
import { TIKTOK_PROVIDER_PLATFORM } from '../../../constants/selectLists/platformList'
import { listItemsTableColumnsMaxWidth, listItemsTableColumnsMinWidth } from './columnsWidth'

import useStyles from './styles'

const ListItemsTable = ({
  items = [],
  platform,
  currencySymbol,
  isLoadingSelector,
  errorSelector,
  wasLoadedSelector,
  additionalDataIsLoading,
  showItemPlatformColumn = true,
  createItemLinkHelper,
  createItemLinkExtraParams,
  getActionsDropdownOptions,
  itemUpdatingId,
  itemDeletingId,
  noDataText,
  nameColumnLabel,
  itemsLoadingProps,
  loadMoreDataHandler,
  ContentWrapper = PageSection,
  children
}) => {
  const classes = useStyles()

  const formattedItems = useMemo(
    () =>
      items.map(item => ({
        ...item,
        ...formatListItemValues(item, { symbol: currencySymbol })
      })),
    [items, currencySymbol]
  )

  const insightColumns = useReusableInsightsColumns({ platform, items })

  const itemsListColumns = useMemo(
    () => [
      ...insertIf(showItemPlatformColumn, {
        Component: ({ provider }) => <PlatformIcon provider={provider} />,
        style: {
          minWidth: listItemsTableColumnsMinWidth.platform,
          maxWidth: listItemsTableColumnsMinWidth.platform
        }
      }),
      {
        headTitle: nameColumnLabel || 'Name',
        Component: item => {
          const { name, date_start: dateStart, date_stop: dateStop, provider } = item

          const summaryPageLink = createItemLinkHelper && createItemLinkHelper(item, createItemLinkExtraParams)

          return (
            <>
              {summaryPageLink ? (
                <InternalLink to={summaryPageLink} className={classes.nameLink}>
                  {name}
                </InternalLink>
              ) : (
                name
              )}
              {dateStart && (
                <div className={classes.columnSubText}>
                  {provider === TIKTOK_PROVIDER_PLATFORM ? (
                    // render only start date for tiktok
                    <>{formatDateShort(dateStart)}</>
                  ) : (
                    <>
                      {formatDateShort(dateStart)}
                      {' - '}
                      {dateStop ? formatDateShort(dateStop) : 'Ongoing'}
                    </>
                  )}
                </div>
              )}
            </>
          )
        },
        style: {
          minWidth: listItemsTableColumnsMinWidth.name,
          maxWidth: listItemsTableColumnsMaxWidth.name
        }
      },
      ...insightColumns,
      {
        Component: ({ status }) => {
          return <Chip text={status} color={getStatusTagColor(status)} />
        },
        style: {
          minWidth: listItemsTableColumnsMinWidth.status,
          maxWidth: listItemsTableColumnsMaxWidth.status,
          textOverflow: 'unset'
        }
      },
      // if actions menu is empty then hide it
      ...insertIf(!!getActionsDropdownOptions && !!getActionsDropdownOptions({}).length, {
        Component: item => <ActionsDropdown options={getActionsDropdownOptions(item)} />,
        style: {
          minWidth: listItemsTableColumnsMinWidth.dropdown,
          maxWidth: listItemsTableColumnsMinWidth.dropdown
        }
      })
    ],
    [
      showItemPlatformColumn,
      nameColumnLabel,
      classes,
      insightColumns,
      getActionsDropdownOptions,
      createItemLinkHelper,
      createItemLinkExtraParams
    ]
  )

  const skeletonProps = useMemo(() => ({ cols: itemsListColumns }), [itemsListColumns])

  return (
    <ItemsLoading
      className={classes.list}
      itemsLength={items && items.length}
      SkeletonComponent={SkeletonListRow}
      skeletonProps={skeletonProps}
      errorSelector={errorSelector}
      isLoadingSelector={isLoadingSelector}
      wasLoadedSelector={wasLoadedSelector}
      additionalDataIsLoading={additionalDataIsLoading}
      noDataText={noDataText}
      ContentWrapper={ContentWrapper}
      loadMore={loadMoreDataHandler}
      {...itemsLoadingProps}
    >
      <List
        cols={itemsListColumns}
        listData={formattedItems}
        itemUpdatingId={itemUpdatingId}
        itemDeletingId={itemDeletingId}
        Skeleton={<SkeletonListRow {...skeletonProps} key={uuidv4()} />}
        isBigRow
      />
      {children}
    </ItemsLoading>
  )
}

ListItemsTable.propTypes = {
  items: PropTypes.array.isRequired,
  platform: PropTypes.string,
  currencySymbol: PropTypes.string,
  isLoadingSelector: PropTypes.func.isRequired,
  errorSelector: PropTypes.func.isRequired,
  wasLoadedSelector: PropTypes.func.isRequired,
  additionalDataIsLoading: PropTypes.bool,
  showItemPlatformColumn: PropTypes.bool,
  createItemLinkHelper: PropTypes.func,
  createItemLinkExtraParams: PropTypes.object,
  getActionsDropdownOptions: PropTypes.func,
  itemUpdatingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  itemDeletingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  noDataText: PropTypes.string,
  nameColumnLabel: PropTypes.string,
  itemsLoadingProps: PropTypes.object,
  loadMoreDataHandler: PropTypes.func,
  ContentWrapper: PropTypes.elementType
}

export default ListItemsTable
