import { useSelector } from 'react-redux'

import { searchTermSelector } from '../modules/selectors/app'

export default function useSearch(searchId) {
  // search is set and read by pathname key
  // it's done to avoid late clearing issue in redux queue
  const { [searchId]: searchTerm = '' } = useSelector(searchTermSelector)

  return searchTerm
}
