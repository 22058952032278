import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  thumbnail: {
    width: 40,
    height: 40,
    objectFit: 'contain'
  }
})

export default useStyles
