import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectedSelfAccountDataSelector } from '../../../modules/selectors/app'
import {
  DV_360_PLATFORM,
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  TIKTOK_PLATFORM
} from '../../../constants/selectLists/platformList'
import {
  AUDIENCE_RETARGETING_ROUTE,
  DISPLAY_ROUTE,
  EXISTING_POST_ROUTE,
  PERFORMANCE_MAX_ROUTE,
  PRODUCT_RETARGETING_ROUTE,
  PROXIMITY_ROUTE,
  SHOPPING_CAMPAIGNS_ROUTE
} from '../../../pages/Settings/AdvertiserAccounts/ClientAccountForms/ClientAccountWithMarginsCreate/ClientAccountWithMarginsCreateForm/fields'

const useAccessibleCampaignsRoutes = platform => {
  const { offsite_campaign_types } = useSelector(selectedSelfAccountDataSelector)

  return useMemo(() => {
    switch (platform) {
      case FACEBOOK_PLATFORM:
        return {
          [PROXIMITY_ROUTE]: offsite_campaign_types?.includes(PROXIMITY_ROUTE),
          [SHOPPING_CAMPAIGNS_ROUTE]: offsite_campaign_types?.includes(SHOPPING_CAMPAIGNS_ROUTE),
          [PRODUCT_RETARGETING_ROUTE]: offsite_campaign_types?.includes(PRODUCT_RETARGETING_ROUTE),
          [AUDIENCE_RETARGETING_ROUTE]: offsite_campaign_types?.includes(AUDIENCE_RETARGETING_ROUTE),
          [EXISTING_POST_ROUTE]: offsite_campaign_types?.includes(EXISTING_POST_ROUTE)
        }
      case GOOGLE_PLATFORM:
        return {
          [PERFORMANCE_MAX_ROUTE]: offsite_campaign_types?.includes(PERFORMANCE_MAX_ROUTE),
          [DISPLAY_ROUTE]: offsite_campaign_types?.includes(DISPLAY_ROUTE)
        }
      case TIKTOK_PLATFORM:
      case DV_360_PLATFORM:
      default:
        return {
          [AUDIENCE_RETARGETING_ROUTE]: offsite_campaign_types?.includes(AUDIENCE_RETARGETING_ROUTE)
        }
    }
  }, [offsite_campaign_types, platform])
}

export default useAccessibleCampaignsRoutes
