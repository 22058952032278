import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../../components/Form'
import LocationListFormFields from '../../LocationListFormFields'

import useManageFormsDrawer from '../../../../../../hooks/formHooks/useManageFormsDrawer'

import handleAddressesErrors from '../../helpers'

import { selectedSelfAccountSelector } from '../../../../../../modules/selectors/app'
import {
  updateLocationListLoadingSelector,
  updateLocationListErrorSelector,
  locationAddressesIsValidationSuccessSelector,
  locationAddressesValidationErrorSelector,
  locationAddressesValidationLoadingSelector,
  locationAddressesValidationSelector,
  locationListSelector,
  updateLocationListSelector
} from '../../../../../../modules/selectors/location'
import {
  validateLocationAddresses,
  clearValidateLocationAddresses,
  updateLocationList,
  clearUpdateLocationList
} from '../../../../../../modules/actions/location'

import {
  VALIDATION_ERROR_CODE,
  getInitialValues,
  validationSchema,
  transformAddressesValidationFieldsToBE,
  transformLocationListFieldsToBE
} from '../../fields'

import { LOCATION_LIST_EDIT } from '../../../../../../constants/forms'

const LocationListEditForm = () => {
  const dispatch = useDispatch()

  const [addressesErrorIndexes, setAddressesErrorIndexes] = useState([])

  const updatedLocationList = useSelector(updateLocationListSelector)
  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)
  const locationList = useSelector(locationListSelector)
  const locationAddressesValidation = useSelector(locationAddressesValidationSelector)
  const locationAddressesValidationError = useSelector(locationAddressesValidationErrorSelector)
  const locationAddressesIsValidationSuccess = useSelector(locationAddressesIsValidationSuccessSelector)

  const { isFormOpen, selectedEditItemId } = useManageFormsDrawer({
    formName: LOCATION_LIST_EDIT
  })

  const isLocationAddressesValidationError =
    locationAddressesValidationError && locationAddressesValidationError.code === VALIDATION_ERROR_CODE

  const clearUpdateLocationsSubmit = useCallback(() => {
    dispatch(clearUpdateLocationList())
  }, [dispatch])

  const onSubmit = useCallback(
    values => {
      // reset addressesErrorIndexes and clear redux state before doing new validation request
      // if there was already an error before
      if (isLocationAddressesValidationError) {
        dispatch(clearValidateLocationAddresses())
        setAddressesErrorIndexes([])
      }

      dispatch(validateLocationAddresses(transformAddressesValidationFieldsToBE(values)))
    },
    [dispatch, isLocationAddressesValidationError]
  )

  const formik = useFormik({
    initialValues: getInitialValues(locationList),
    enableReinitialize: true,
    validationSchema,
    onSubmit
  })

  const { values } = formik

  const { addresses } = values

  useEffect(() => {
    if (
      !locationAddressesIsValidationSuccess &&
      isLocationAddressesValidationError &&
      !addressesErrorIndexes.length &&
      isFormOpen
    ) {
      handleAddressesErrors(addresses, locationAddressesValidation, setAddressesErrorIndexes)
    }
  }, [
    locationAddressesIsValidationSuccess,
    isLocationAddressesValidationError,
    locationAddressesValidation,
    addresses,
    addressesErrorIndexes,
    isFormOpen
  ])

  useEffect(() => {
    if (locationAddressesIsValidationSuccess && selectedEditItemId) {
      dispatch(
        updateLocationList(
          transformLocationListFieldsToBE(values, locationAddressesValidation, selectedSelfAccount),
          selectedEditItemId
        )
      )
    }
  }, [
    dispatch,
    locationAddressesIsValidationSuccess,
    values,
    locationAddressesValidation,
    selectedSelfAccount,
    selectedEditItemId
  ])

  return (
    <Form
      formName={LOCATION_LIST_EDIT}
      formik={formik}
      successSubmit={updatedLocationList.wasUpdated}
      errorSelector={
        locationAddressesIsValidationSuccess
          ? updateLocationListErrorSelector
          : locationAddressesValidationErrorSelector
      }
      isLoadingSelector={
        locationAddressesIsValidationSuccess
          ? updateLocationListLoadingSelector
          : locationAddressesValidationLoadingSelector
      }
      clearHandler={clearUpdateLocationsSubmit}
    >
      <LocationListFormFields formik={formik} addressesErrorIndexes={addressesErrorIndexes} isEditForm />
    </Form>
  )
}

export default LocationListEditForm
