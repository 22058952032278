import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getIn } from 'formik'
import { useTranslation } from 'react-i18next'

import MinimisedSectionHeader from '../../../../../components/MinimisedSectionNew/MinimisedSectionHeader'
import MinimisedSectionNew from '../../../../../components/MinimisedSectionNew'

import { keys } from '../../../../../helpers/common'
import { isStepFieldsValid } from '../../../../../features/components/Forms/StepForm/helpers'

import { adCarouselSelectedIndexesSelector } from '../../../../../modules/selectors/ads'

import { AD_PREVIEW_CAROUSEL_INDEX_KEY, IS_ITEM_FORM_OPENED } from '../../../../Facebook/AdForms/fields'

const CarouselItemForm = ({ formik, listPath, index, deleteItem, onItemClick, children }) => {
  const { t } = useTranslation()
  const selectedItemIndexes = useSelector(adCarouselSelectedIndexesSelector)

  const { values, setFieldValue, errors, touched } = formik

  const selectedItemIndexKey = values[AD_PREVIEW_CAROUSEL_INDEX_KEY]
  const selectedItemIndex = selectedItemIndexes[selectedItemIndexKey] || 0

  // Constant that represent path ot item in formik values
  const itemPath = `${listPath}[${index}]`

  const itemErrors = getIn(errors, itemPath)
  const itemTouched = getIn(touched, itemPath)
  const carouselItems = getIn(values, `${listPath}`)
  const allowFormOpen = getIn(values, `${IS_ITEM_FORM_OPENED}`)
  const isItemActive = selectedItemIndex === index

  // determine if section has errors only by checking touched fields
  const isValid = itemErrors && itemTouched ? isStepFieldsValid(itemErrors, keys(itemTouched)) : true

  // carousel should have at least 2 items
  const isRemovable = carouselItems.length > 2

  const isOpened = isItemActive && allowFormOpen

  const handleDeleteCarouselItem = useCallback(() => {
    deleteItem(index)
  }, [index, deleteItem])

  const toggleCarouselForm = useCallback(() => {
    isOpened ? setFieldValue(IS_ITEM_FORM_OPENED, false) : onItemClick({ index, isFormOpened: true })
  }, [isOpened, setFieldValue, onItemClick, index])

  return (
    <MinimisedSectionNew
      headerContent={
        <MinimisedSectionHeader
          title={t('imageOrVideo', { count: index + 1, ordinal: true })}
          onDelete={isRemovable ? handleDeleteCarouselItem : null}
          deleteBtnText="Remove media"
        />
      }
      isOpened={isOpened}
      onHeaderClick={toggleCarouselForm}
      hasError={!isValid}
      isFocused={isItemActive}
    >
      {/*render fields only when section is opened for avoid issue with existing media clear as well as for performance optimization*/}
      {isOpened && children}
    </MinimisedSectionNew>
  )
}

CarouselItemForm.propTypes = {
  formik: PropTypes.object,
  listPath: PropTypes.string.isRequired,
  index: PropTypes.number,
  deleteItem: PropTypes.func,
  onItemClick: PropTypes.func
}

export default CarouselItemForm
