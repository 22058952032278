import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR
export async function getGoogleUsersListService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.googleUsersList
  })
}

export function getGoogleGeolocationsService(params) {
  return madeRequest('GET', {
    url: ENDPOINTS.googleConstants,
    params: {
      constant_name: 'geo_target_constant',
      target_type: 'all',
      ...params
    }
  })
}

export function getGoogleLanguagesService(params) {
  return madeRequest('GET', {
    url: ENDPOINTS.googleConstants,
    params: {
      constant_name: 'language_constant',
      target_type: 'all',
      ...params
    }
  })
}

// todo if there is one more similar constant service as above, then create on reusable service
