import {
  DESCRIPTION,
  INTERNAL_ID,
  NAME,
  PERIOD_OPTION
} from '../../../MediaProductCreate/MediaProductCreateForm/fields'
import { SUB_PRODUCTS } from '../../../fields'

import { getTagsSectionInitialValues } from '../../MediaProductEdit/MediaProductEditForm/TagsSection/fields'
import { getImagesSectionInitialValues } from '../../sections/ImagesSection/fields'
import { getLocationSectionInitialValues } from '../../sections/LocationSection/fields'
import { getVariablesSectionInitialValues } from '../../sections/VariablesSection/fields'
import { CURRENCY } from '../../../MediaProductGroupCreate/MediaProductGroupCreateForm/fields'

import { getInitialCategories } from '../../fields'

export const getInitialValues = ({ editProductData, productTags, formattedSubProductsOptions }) => {
  return {
    [NAME]: editProductData[NAME] || '',
    [DESCRIPTION]: editProductData[DESCRIPTION] || '',
    ...getInitialCategories(editProductData),
    [PERIOD_OPTION]: editProductData.period || '',
    ...getImagesSectionInitialValues(editProductData),
    ...getTagsSectionInitialValues(editProductData, productTags),
    [SUB_PRODUCTS]: formattedSubProductsOptions || [],
    ...getLocationSectionInitialValues(editProductData),
    ...getVariablesSectionInitialValues(editProductData),
    [INTERNAL_ID]: editProductData[INTERNAL_ID] || '',
    [CURRENCY]: editProductData.prices[0]?.currency || ''
  }
}
