export const DESCRIPTION = 'description'
export const FILE_URL = 'file'
export const FILE_TYPE = 'file_type'
export const FILE_NAME = 'file_name'

const BOOKING_CONTRACT_TYPE = 'booking_contract'
export const OTHER_TYPE = 'other'

export const mediaOrderFileTypesList = [
  { value: BOOKING_CONTRACT_TYPE, label: 'Booking contract' },
  { value: 'booking_notification_form', label: 'Booking notification form' },
  { value: 'invoice', label: 'Invoice' },
  { value: 'pro_forma_invoice', label: 'Pro-forma invoice' },
  { value: OTHER_TYPE, label: 'Other' }
]

export const initialFileValues = {
  [FILE_NAME]: '',
  [FILE_URL]: '',
  [DESCRIPTION]: ''
}
export const initialValues = {
  ...initialFileValues,
  [FILE_TYPE]: BOOKING_CONTRACT_TYPE
}
