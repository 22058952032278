import React from 'react'
import { useSelector } from 'react-redux'

import LineGraph from '../../Graphs/LineGraph'

import { formatReportNumber } from '../../../formatters'

import { selectedAdAccountDataSelector } from '../../../../modules/selectors/app'

import useStyles from './styles'

function SummaryReportGraph({ data = [], mainMetric, report, graphLabels, title }) {
  const classes = useStyles()

  const { currency_symbol } = useSelector(selectedAdAccountDataSelector)

  const metricValue = mainMetric
    ? formatReportNumber({ value: mainMetric.value, format: mainMetric.format, currency: currency_symbol })
    : 0
  let graphData = []
  let color = ''

  if (report === 'Clicks') {
    graphData = data.map(report => report.ctr)
    color = '#D4A11D'
  } else if (report === 'Delivery') {
    graphData = data.map(report => report.impressions)
    color = '#3C8BD9'
  } else if (report === 'Engagement') {
    graphData = data.map(report => report.engagement_rate || 0)
    color = '#41479B'
  } else if (report === 'Conversions') {
    graphData = data.map(report => report.cost_per_conversion)
    color = '#e28743'
  } else if (report === 'Video views') {
    graphData = data.map(report => report.cost_per_view)
    color = '#063970'
  }

  return (
    <div className={classes.graphCard}>
      <h5 className={classes.graphTitle}>{title}</h5>
      <h5 className={classes.graphTitle} style={{ color: color }}>
        {metricValue}
      </h5>
      <LineGraph
        data={graphData}
        labels={graphLabels}
        graphColor={color}
        format={mainMetric.format}
        className={classes.graphWrapper}
      />
    </div>
  )
}

export default SummaryReportGraph
