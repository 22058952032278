import { useEffect } from 'react'
import { clearAdPreview, setAdModifiedData } from '../../modules/actions/ads'
import { useDispatch } from 'react-redux'

export default function useEditAdPreview(values) {
  const dispatch = useDispatch()

  useEffect(() => {
    // set modified values to represent these in AdPreview
    dispatch(setAdModifiedData(values))
  }, [dispatch, values])

  useEffect(() => {
    // clear AdPreview data on close
    return () => {
      dispatch(clearAdPreview())
    }
  }, [dispatch])
}
