const currenciesList = [
  {
    value: 'AED',
    label: 'AED',
    currency_symbol: 'DH'
  },
  {
    value: 'AUD',
    label: 'AUD',
    currency_symbol: 'A$'
  },
  {
    value: 'BHD',
    label: 'BHD',
    currency_symbol: 'BD'
  },
  {
    value: 'CAD',
    label: 'CAD',
    currency_symbol: 'C$'
  },
  {
    value: 'EUR',
    label: 'EUR',
    currency_symbol: '€'
  },
  {
    value: 'GBP',
    label: 'GBP',
    currency_symbol: '£'
  },
  {
    value: 'NZD',
    label: 'NZD',
    currency_symbol: 'NZ$'
  },
  {
    value: 'SAR',
    label: 'SAR',
    currency_symbol: 'SR'
  },
  {
    value: 'USD',
    label: 'USD',
    currency_symbol: '$'
  }
]

export default currenciesList
