import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classnames from 'classnames'

import useStyles from './styles'
import usePreviewStyles from './FilePreview/styles'

function SkeletonFileDetailsPreview({ className }) {
  const classes = useStyles({})
  const classesPreview = usePreviewStyles({})

  return (
    <div className={classnames(className, classes.fileDetails)}>
      <div className={classes.uploadedContainer}>
        <div className={classesPreview.preview}>
          <Skeleton height="100%" />
        </div>
        <div className={classesPreview.details}>
          <div className={classesPreview.fileName}>
            <Skeleton width="70%" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SkeletonFileDetailsPreview
