import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'

import MediaOrderPrice from '../../../../MediaOrderPrice'
import ImagesModal from '../../../../../../../../../../features/components/Modals/ImagesModal'

import {
  inventoryIsLoadingSelector,
  inventorySelector
} from '../../../../../../../../../../modules/selectors/mediaOrdersProducts'

import useStyles from './styles'

function ProductDetails({ productData, currency }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const inventory = useSelector(inventorySelector)
  const inventoryIsLoading = useSelector(inventoryIsLoadingSelector)

  const [showProductImagesModal, setShowProductImagesModal] = useState(false)
  const [selectedImageId, setSelectedImageId] = useState(null)

  const formattedProductImages = useMemo(() => {
    return productData.images.map((image, index) => {
      return {
        image: image.image,
        id: image.id,
        title: `Image ${index + 1}`
      }
    })
  }, [productData.images])

  // we show product price only for the regular products, grouped products have no price
  const hasInventory = inventory.length > 0
  // Extract prices from periods
  const prices = useMemo(
    () => inventory.filter(({ available_to_book }) => available_to_book).map(({ period_price }) => period_price),
    [inventory]
  )

  const hasDifferentPrices = useMemo(() => {
    // Convert prices to a Set to remove duplicates
    const uniquePrices = new Set(prices)

    // Check if there are more than one unique prices
    return uniquePrices.size > 1
  }, [prices])

  // represent the lowest period price, if there are more then 1 different price we show from
  const lowestPrice = Math.min(...prices)

  const handleSelectImage = useCallback(
    imageId => {
      setSelectedImageId(imageId)
    },
    [setSelectedImageId]
  )

  const openProductImagesModal = useCallback(() => {
    setShowProductImagesModal(true)
    setSelectedImageId(productData.images?.[0]?.id)
  }, [setShowProductImagesModal, setSelectedImageId, productData.images])

  return (
    <>
      <ImagesModal
        title={productData.name}
        images={formattedProductImages}
        showModal={showProductImagesModal}
        onModalClose={() => setShowProductImagesModal(false)}
        selectedImageId={selectedImageId}
        handleSelectImage={handleSelectImage}
      />
      <div className={classes.productDetails}>
        <div className={classes.preview}>
          {!!productData.images?.length && (
            <img
              className={classes.thumbnail}
              src={productData.images[0].image}
              alt={productData.name}
              onClick={openProductImagesModal}
            />
          )}
        </div>
        <div className={classes.details}>
          <h2 className={classes.name}>{productData.name}</h2>
          {productData.description && <div className={classes.description}>{productData.description}</div>}
          <div>
            {hasDifferentPrices && t('from ')}
            {inventoryIsLoading ? (
              <Skeleton width={70} />
            ) : hasInventory ? (
              <MediaOrderPrice currencySymbol={currency.symbol} regularPrice={lowestPrice} isBold />
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

ProductDetails.propTypes = {
  productData: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired
}

export default ProductDetails
