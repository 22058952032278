import React from 'react'

export const AdFacebookCreateContext = React.createContext()

export const defaultAdFacebookCreateContext = {
  campaignObjective: ''
}

export const AdFacebookCreateContextProvider = ({
  children,
  campaignObjective = defaultAdFacebookCreateContext.campaignObjective
}) => {
  return <AdFacebookCreateContext.Provider value={{ campaignObjective }}>{children}</AdFacebookCreateContext.Provider>
}
