export function manageContractsBulkDelete(action) {
  // contracts and quotations list are the same endpoint and have same business logic
  return {
    count: {
      $apply: count => {
        if (action.data.status === 'deleted') {
          // if items where deleted - update count of deleted items:
          return count - action.data.ids.length
        } else {
          return count
        }
      }
    },
    // update results according to list of id
    // in case if status change to 'deleted' - just delete it from list
    results: {
      $apply: items => {
        const updatedIdsSet = new Set(action.data.ids)
        // BE support only one status type update per one request
        const newStatus = action.data.status

        if (newStatus === 'deleted') {
          // remove deleted contracts from list
          return items.filter(contract => !updatedIdsSet.has(contract.id))
        } else {
          return items.map(contract =>
            updatedIdsSet.has(contract.id)
              ? { ...contract, status: newStatus } // wasUpdated
              : contract
          )
        }
      }
    }
  }
}
