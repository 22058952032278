export const DATA_QUANTITY = 'quantity'
export const DATA_COST = 'cost'
export const DATA_RATE = 'rate'
export const DATA_DATES = 'dates'

export const SORT_PARAMETERS = {
  CATEGORY: 'category',
  LOCATION: 'location',
  ASSET: 'asset',
  DATES: 'dates',
  RATE: 'rate',
  COST: 'cost'
}
