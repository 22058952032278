import { getAdBusinessName, getAdCallToActionText } from '../../helpers'

import { BUSINESS_NAME } from '../../../../fields'
import { CALL_TO_ACTION } from '../../../../../../ReusableFormFields/CallToActionFields/fields'
import { googleAdsCallToActionList } from '../../../../../../../constants/selectLists/googleAdsCallToActionList'

export const formatValuesToFE = ad => {
  const businessName = getAdBusinessName(ad)
  const callToActionText = getAdCallToActionText(ad)

  const callToActionValue = googleAdsCallToActionList.find(text => text.label === callToActionText)?.value

  return {
    [BUSINESS_NAME]: businessName || '',
    [CALL_TO_ACTION]: callToActionValue || ''
  }
}

export const transformValuesToBE = values => {
  const callToActionLabel = googleAdsCallToActionList.find(text => text.value === values[CALL_TO_ACTION])?.label

  return {
    responsive_display_ad: {
      business_name: values[BUSINESS_NAME],
      call_to_action_text: callToActionLabel
    }
  }
}
