import React from 'react'

import ButtonIcon from '../../../../../features/components/Buttons/ButtonIcon'
import { ReactComponent as HistoryIcon } from '../../../../../assets/icons/history-icon.svg'

import useStyles from './styles'

const MediaOrderHistoryButton = ({ onClick }) => {
  const classes = useStyles()

  return <ButtonIcon className={classes.historyIcon} Icon={HistoryIcon} onClick={onClick} />
}

export default MediaOrderHistoryButton
