import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import ProgressButton from '../../../../../../components/Form/ProgressButton'

function ArchiveButton({
  updateItemAction,
  updatedItemSelector,
  updateItemErrorSelector,
  updateItemIsLoadingSelector,
  clearUpdateAction,
  dataWaitingForDelete,
  handleCloseDeleteModal,
  wrapperClassName
}) {
  // this state is used for clear understanding when exactly archive update is happening
  // because we have one update reducer for many cases: status update, edit form, etc.
  const [isArchiveRunning, setIsArchiveRunning] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const updateItemError = useSelector(updateItemErrorSelector)
  const { wasUpdated } = useSelector(updatedItemSelector)
  const updateItemIsLoading = useSelector(updateItemIsLoadingSelector)

  const handleArchive = useCallback(() => {
    const { item, account, campaignObjective } = dataWaitingForDelete

    setIsArchiveRunning(true)

    dispatch(
      updateItemAction(
        {
          account,
          status: 'archived',
          campaign_objective: campaignObjective
        },
        item.id
        // before adding 3rd parameter check all updateItemAction to avoid passing not correct data
      )
    )
  }, [dataWaitingForDelete, dispatch, updateItemAction])

  const clearErrorHandler = useCallback(() => {
    clearUpdateAction && dispatch(clearUpdateAction())
  }, [dispatch, clearUpdateAction])

  useEffect(() => {
    if (isArchiveRunning && (wasUpdated || updateItemError)) {
      setIsArchiveRunning(false)

      handleCloseDeleteModal()
      clearErrorHandler()
    }
  }, [isArchiveRunning, wasUpdated, updateItemError, clearErrorHandler, handleCloseDeleteModal])

  return (
    <ProgressButton
      onClick={handleArchive}
      formError={isArchiveRunning ? updateItemError : {}}
      isFormLoading={isArchiveRunning && updateItemIsLoading}
      successSubmit={isArchiveRunning && wasUpdated}
      wrapperClassName={wrapperClassName}
      isSmall={true}
    >
      {t('Archive')}
    </ProgressButton>
  )
}

ArchiveButton.propTypes = {
  updateItemAction: PropTypes.func,
  updatedItemSelector: PropTypes.func,
  updateItemErrorSelector: PropTypes.func,
  updateItemIsLoadingSelector: PropTypes.func,
  clearUpdateAction: PropTypes.func,
  dataWaitingForDelete: PropTypes.object,
  handleCloseDeleteModal: PropTypes.func,
  wrapperClassName: PropTypes.string
}

export default ArchiveButton
