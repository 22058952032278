import { createSelector } from 'reselect'
import { getCurrentUserSelector } from './app'

// PLOP_APPEND_PATTERN_ANCHOR

export const choicesGeolocationsSelector = createSelector(
  ({ choices }) => choices.geolocations.items,
  items => items
)

export const choicesGeolocationsLoadingSelector = createSelector(
  ({ choices }) => choices.geolocations.isLoading,
  isLoading => isLoading
)

export const choicesCountriesSelector = createSelector(
  ({ choices }) => choices.countries.results,
  results => results
)

export const currentUserCountrySelector = createSelector(
  getCurrentUserSelector,
  ({ choices }) => choices.countries.results,
  (currentUser, countries) => countries.find(country => country.id === currentUser.country)
)

export const choicesCountriesLoadingSelector = createSelector(
  ({ choices }) => choices.countries.isLoading,
  isLoading => isLoading
)

export const choicesCountriesWasLoadedSelector = createSelector(
  ({ choices }) => choices.countries.wasLoaded,
  wasLoaded => wasLoaded
)

export const choicesCountriesErrorSelector = createSelector(
  ({ choices }) => choices.countries.error,
  error => error
)

export const choicesInterestsSelector = createSelector(
  ({ choices }) => choices.interests.items,
  items => items
)

export const choicesInterestsLoadingSelector = createSelector(
  ({ choices }) => choices.interests.isLoading,
  isLoading => isLoading
)

export const choicesParentCategoriesSelector = createSelector(
  ({ choices }) => choices.categories.items,
  items => items
)

export const choicesParentCategoriesLoadingSelector = createSelector(
  ({ choices }) => choices.categories.isLoading,
  isLoading => isLoading
)

export const choicesLanguagesSelector = createSelector(
  ({ choices }) => choices.languages.results,
  results => results
)

export const choicesLanguagesLoadingSelector = createSelector(
  ({ choices }) => choices.languages.isLoading,
  isLoading => isLoading
)

export const choicesLanguagesWasLoadedSelector = createSelector(
  ({ choices }) => choices.languages.wasLoaded,
  wasLoaded => wasLoaded
)

export const choicesPixelsSelector = createSelector(
  ({ choices }) => choices.pixels.results,
  results => results
)

export const choicesPixelsLoadingSelector = createSelector(
  ({ choices }) => choices.pixels.isLoading,
  isLoading => isLoading
)

export const choicesPixelsWasLoadedSelector = createSelector(
  ({ choices }) => choices.pixels.wasLoaded,
  wasLoaded => wasLoaded
)

export const choicesPixelsErrorSelector = createSelector(
  ({ choices }) => choices.pixels.error,
  error => error
)

export const choicesCTASelector = createSelector(
  ({ choices }) => choices?.cta?.results,
  results => results
)

export const choicesCTAWasLoadedSelector = createSelector(
  ({ choices }) => choices.cta.wasLoaded,
  wasLoaded => wasLoaded
)

export const choicesCTAByGoalSelector = goal => {
  return createSelector(
    ({ choices }) => choices.ctaByGoal[goal]?.results,
    results => results || []
  )
}

export const choicesCTAByGoalWasLoadedSelector = goal => {
  return createSelector(
    ({ choices }) => choices.ctaByGoal[goal]?.wasLoaded,
    wasLoaded => wasLoaded
  )
}
