import React, { cloneElement, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import MultiSelectBox from '../../../../../features/components/Form/MultiSelectBox'
import Field from '../../../../../components/Form/Field'
import Checkbox from '../../../../../components/Form/Checkbox'
import ErrorMessage from '../../../../../components/Form/ErrorMessage'

import usePreselectUserCountry from '../../../../../features/hooks/usePreselectUserCountry'

import { getChoicesCountries } from '../../../../../modules/actions/choices'
import { choicesCountriesSelector, choicesCountriesWasLoadedSelector } from '../../../../../modules/selectors/choices'

import { TARGET_BY_REGION, SHOW_COUNTRIES, SHOW_COUNTRY_CHOICE, SHOW_REGIONS } from '../../fields'

import useStyles from './styles'
import { selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'

const LocationsTabContent = ({ formik, TargetByRegionComponent, checkboxClassName }) => {
  const classes = useStyles()

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { values, errors, touched, setFieldValue } = formik

  const countriesError = errors[SHOW_COUNTRIES]
  const countriesTouched = touched[SHOW_COUNTRIES]
  const regionsError = errors[SHOW_REGIONS]
  const regionsTouched = touched[SHOW_REGIONS]

  const countries = useSelector(choicesCountriesSelector)
  const countriesWasLoaded = useSelector(choicesCountriesWasLoadedSelector)

  const targetByRegion = values[TARGET_BY_REGION]
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const formattedCountries = useMemo(() => {
    return countries
      .sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1

        return 0
      })
      .map(item => {
        return {
          value: item.id,
          label: item.name,
          code: item.iso2,
          resource_name: item.resource_name
        }
      })
  }, [countries])

  const renderFormComponentWithProps = () => {
    // populate component with props during the rendering
    return cloneElement(TargetByRegionComponent, {
      formattedCountries,
      selectedAdAccountId
    })
  }

  useEffect(() => {
    // getChoicesCountries for SHOW_OPTION_COUNTRIES
    if (!countriesWasLoaded) {
      dispatch(getChoicesCountries())
    }
  }, [dispatch, countriesWasLoaded])

  usePreselectUserCountry({ formik, formattedCountries })

  return (
    <>
      {targetByRegion ? (
        <Field placeholder="Select country" formik={formik} name={SHOW_COUNTRY_CHOICE} options={formattedCountries} />
      ) : (
        <>
          <MultiSelectBox
            placeholder={t('Select country')}
            name={SHOW_COUNTRIES}
            options={formattedCountries}
            value={values[SHOW_COUNTRIES]}
            setFieldValue={setFieldValue}
          />
          {countriesError && countriesTouched && <ErrorMessage error={countriesError} />}
        </>
      )}

      <Checkbox
        id="target_regions"
        title={t('Target by region')}
        className={classnames(classes.targetByRegionCheckbox, checkboxClassName)}
        checked={values[TARGET_BY_REGION]}
        onCheck={() => setFieldValue(TARGET_BY_REGION, !values[TARGET_BY_REGION], false)}
      />

      {targetByRegion && (
        <>
          {TargetByRegionComponent && renderFormComponentWithProps()}
          {regionsError && regionsTouched && <ErrorMessage error={regionsError} />}
        </>
      )}
    </>
  )
}

LocationsTabContent.propTypes = {
  formik: PropTypes.object.isRequired,
  TargetByRegionComponent: PropTypes.node,
  checkboxClassName: PropTypes.string
}

export default LocationsTabContent
