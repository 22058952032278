import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Can from '../../../../features/components/Can'
import CampaignsSectionActions from './CampaignsSectionActions'
import CampaignsOptimisationPanel from './CampaignsOptimisationPanel'
import DigitalTable from '../../../../features/components/DigitalTable'
import MediaOrderCampaignsReports from '../MediaOrderCampaignsReports'
import OpenUploadCreativeFormTrigger from '../../../../forms/Multiplatform/UploadAdCreative/OpenUploadCreativeFormTrigger'
import { UploadFacebookAdCreative } from '../../../../forms/Facebook/UploadFacebookAdCreative'
import { UploadGoogleAdCreative } from '../../../../forms/Google/UploadGoogleAdCreative'
import { UploadDv360AdCreative } from '../../../../forms/DV360/UploadDV360AdCreative'
import { UploadTiktokAdCreative } from '../../../../forms/Tiktok/UploadTikTokAdCreative/UploadTiktokAdCreative'

import usePermissions from '../../../../hooks/usePermissions'
import useAvailableAdAccountsList from '../../hooks/useAvailableAdAccountsList'
import useGetCampaignActionsDropdownOptions from './hooks/useGetCampaignsActionsDropdownOptions'
import useLoadCombinedCampaigns from '../../hooks/useLoadCombinedCampaigns'

import { getCampaignSummaryRedirectLinkHandler } from '../../../../constants/campaigns'

import { clearUpdateCampaign, updateCampaign } from '../../../../modules/actions/campaigns'
import {
  combinedCampaignsErrorSelector,
  combinedCampaignsIsLoadingSelector,
  combinedCampaignsSelector,
  combinedCampaignsWasLoadedSelector
} from '../../../../modules/selectors/combinedData'
import { campaignUpdateErrorSelector, campaignWasUpdatedSelector } from '../../../../modules/selectors/campaigns'
import { selectedSelfAccountSelector } from '../../../../modules/selectors/app'

import { listItemChangeStatus } from '../../../../constants/other'
import {
  DV_360_PROVIDER_PLATFORM,
  FACEBOOK_PROVIDER_PLATFORM,
  GOOGLE_PROVIDER_PLATFORM,
  PROVIDER_TO_PLATFORMS,
  TIKTOK_PROVIDER_PLATFORM
} from '../../../../constants/selectLists/platformList'
import { AUTOMATIC_OPTIMIZE_PERMISSIONS, CAMPAIGN_PERMISSION } from '../../../../constants/permissions'

import useStyles from './styles'

const CampaignSection = () => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const selfAccountId = useSelector(selectedSelfAccountSelector)
  const combinedCampaigns = useSelector(combinedCampaignsSelector)
  const combinedCampaignsWasLoaded = useSelector(combinedCampaignsWasLoadedSelector)
  const combinedCampaignsError = useSelector(combinedCampaignsErrorSelector)
  const campaignWasUpdated = useSelector(campaignWasUpdatedSelector)
  const campaignUpdateError = useSelector(campaignUpdateErrorSelector)

  const permissions = usePermissions()
  const hasStatusChangePermissions = permissions.can('update', CAMPAIGN_PERMISSION)

  const { campaigns = [], campaigns_summary: totalsItem = {}, currency_symbol: currencySymbol } = combinedCampaigns
  const hasImpressions = !!totalsItem?.impressions
  const campaignHasError = campaigns?.some(campaign => campaign.error)

  const combinedCampaignsDidLoading = combinedCampaignsWasLoaded || combinedCampaignsError

  const hasCampaigns = !!campaigns?.length
  const showEmptyCampaignsMessage = combinedCampaignsDidLoading && !hasCampaigns

  const updateCampaignStatusHandler = useCallback(
    campaign => {
      dispatch(
        updateCampaign(
          {
            account: campaign['ad_account_id'],
            status: listItemChangeStatus[campaign.status]?.changeTo,
            ...(campaign.objective && { campaign_objective: campaign.objective })
          },
          campaign.id,
          {
            platform: PROVIDER_TO_PLATFORMS[campaign.provider]
          }
        )
      )
    },
    [dispatch]
  )

  const getCampaignLinkClickHandler = useCallback(
    ({ item, platform, adAccountId }) =>
      getCampaignSummaryRedirectLinkHandler({
        campaign: item,
        platform,
        adAccountId: adAccountId,
        selfAccountId: selfAccountId
      }),
    [selfAccountId]
  )

  const getCampaignsActionsDropdownOptions = useGetCampaignActionsDropdownOptions()

  const availableAdAccounts = useAvailableAdAccountsList()
  useLoadCombinedCampaigns()

  const showFacebookUpload = !!availableAdAccounts[FACEBOOK_PROVIDER_PLATFORM]
  const showGoogleUpload = !!availableAdAccounts[GOOGLE_PROVIDER_PLATFORM]
  const showTikTokUpload = !!availableAdAccounts[TIKTOK_PROVIDER_PLATFORM]
  const showDV360Upload = !!availableAdAccounts[DV_360_PROVIDER_PLATFORM]

  useEffect(() => {
    if (campaignWasUpdated || campaignUpdateError) {
      dispatch(clearUpdateCampaign())
    }
  }, [dispatch, campaignUpdateError, campaignWasUpdated])

  return (
    <>
      {showEmptyCampaignsMessage ? (
        <div className={classes.emptyDataContainer}>
          <p className={classes.emptyDataText}>
            {combinedCampaignsError
              ? t('Sorry, there has been an error retrieving that data. Please try to reload the page.')
              : t('There are no off-network digital campaigns')}
          </p>
          {/* hide create campaign button when there campaigns failed to load to avoid cases of the creation
           campaigns out of budget. As budget is calculated based on created campaigns */}
          {!combinedCampaignsError && (
            <Can I="create" a={CAMPAIGN_PERMISSION}>
              <OpenUploadCreativeFormTrigger availableAdAccounts={availableAdAccounts} />
            </Can>
          )}
        </div>
      ) : (
        <>
          <Can I="manage" a={AUTOMATIC_OPTIMIZE_PERMISSIONS}>
            <CampaignsOptimisationPanel />
          </Can>
          <div className={classes.filters}>
            <Can I="create" a={CAMPAIGN_PERMISSION}>
              <OpenUploadCreativeFormTrigger availableAdAccounts={availableAdAccounts} />
            </Can>
            <CampaignsSectionActions />
          </div>
          <DigitalTable
            id="media-order-campaigns-table"
            className={classes.tableContainer}
            data={campaigns}
            totalsItem={totalsItem}
            isLoadingSelector={combinedCampaignsIsLoadingSelector}
            errorSelector={combinedCampaignsErrorSelector}
            wasLoadedSelector={combinedCampaignsWasLoadedSelector}
            allowStatusChange={hasStatusChangePermissions}
            onStatusChange={updateCampaignStatusHandler}
            getItemLinkClickHandler={getCampaignLinkClickHandler}
            getActionsDropdownOptions={getCampaignsActionsDropdownOptions}
            currencySymbol={currencySymbol}
          />
          {/*don't show reports if there are no impressions or some campaign has error*/}
          {hasImpressions && !campaignHasError && <MediaOrderCampaignsReports />}
        </>
      )}
      {/* !!these components cannot be duplicated otherwise it re-renders and all internal states progress are missed */}
      {/* form closing and other issues appears */}
      {showFacebookUpload && (
        <Can I="create" a={CAMPAIGN_PERMISSION}>
          <UploadFacebookAdCreative />
        </Can>
      )}
      {showGoogleUpload && (
        <Can I="create" a={CAMPAIGN_PERMISSION}>
          <UploadGoogleAdCreative />
        </Can>
      )}
      {showDV360Upload && (
        <Can I="create" a={CAMPAIGN_PERMISSION}>
          <UploadDv360AdCreative />
        </Can>
      )}
      {showTikTokUpload && (
        <Can I="create" a={CAMPAIGN_PERMISSION}>
          <UploadTiktokAdCreative />
        </Can>
      )}
    </>
  )
}

export default CampaignSection
