import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles({
  targetByRegionCheckbox: {
    marginTop: 8,
    marginBottom: 20
  }
})

export default useStyles
