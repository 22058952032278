import { concat } from '../../helpers/common'

const MODULE_NAME = 'CONTRACTS/'

// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_CONTRACT = concat(MODULE_NAME, 'GET_CONTRACT')
export const GET_CONTRACT_SUCCESS = concat(MODULE_NAME, 'GET_CONTRACT_SUCCESS')
export const GET_CONTRACT_FAILURE = concat(MODULE_NAME, 'GET_CONTRACT_FAILURE')
export const CLEAR_GET_CONTRACT = concat(MODULE_NAME, 'CLEAR_GET_CONTRACT')

export const SET_ACTIVE_CONTRACT = concat(MODULE_NAME, 'SET_ACTIVE_CONTRACT')
export const CLEAR_ACTIVE_CONTRACT = concat(MODULE_NAME, 'CLEAR_ACTIVE_CONTRACT')

export const CREATE_CONTRACT = concat(MODULE_NAME, 'CREATE_CONTRACT')
export const CREATE_CONTRACT_SUCCESS = concat(MODULE_NAME, 'CREATE_CONTRACT_SUCCESS')
export const CREATE_CONTRACT_FAILURE = concat(MODULE_NAME, 'CREATE_CONTRACT_FAILURE')
export const CLEAR_CREATE_CONTRACT = concat(MODULE_NAME, 'CLEAR_CREATE_CONTRACT')

export const UPDATE_CONTRACT = concat(MODULE_NAME, 'UPDATE_CONTRACT')
export const UPDATE_CONTRACT_SUCCESS = concat(MODULE_NAME, 'UPDATE_CONTRACT_SUCCESS')
export const UPDATE_CONTRACT_FAILURE = concat(MODULE_NAME, 'UPDATE_CONTRACT_FAILURE')
export const CLEAR_UPDATE_CONTRACT = concat(MODULE_NAME, 'CLEAR_UPDATE_CONTRACT')

export const UPDATE_CONTRACTS_STATUS_BULK = concat(MODULE_NAME, 'UPDATE_CONTRACTS_STATUS_BULK')
export const UPDATE_CONTRACTS_STATUS_BULK_SUCCESS = concat(MODULE_NAME, 'UPDATE_CONTRACTS_STATUS_BULK_SUCCESS')
export const UPDATE_CONTRACTS_STATUS_BULK_FAILURE = concat(MODULE_NAME, 'UPDATE_CONTRACTS_STATUS_BULK_FAILURE')
export const CLEAR_UPDATE_CONTRACTS_STATUS_BULK = concat(MODULE_NAME, 'CLEAR_UPDATE_CONTRACTS_STATUS_BULK')

export const SIGN_CONTRACT = concat(MODULE_NAME, 'SIGN_CONTRACT')
export const SIGN_CONTRACT_SUCCESS = concat(MODULE_NAME, 'SIGN_CONTRACT_SUCCESS')
export const SIGN_CONTRACT_FAILURE = concat(MODULE_NAME, 'SIGN_CONTRACT_FAILURE')
export const CLEAR_SIGN_CONTRACT = concat(MODULE_NAME, 'CLEAR_SIGN_CONTRACT')

export const DELETE_CONTRACT_SIGNATURE = concat(MODULE_NAME, 'DELETE_CONTRACT_SIGNATURE')
export const DELETE_CONTRACT_SIGNATURE_SUCCESS = concat(MODULE_NAME, 'DELETE_CONTRACT_SIGNATURE_SUCCESS')
export const DELETE_CONTRACT_SIGNATURE_FAILURE = concat(MODULE_NAME, 'DELETE_CONTRACT_SIGNATURE_FAILURE')
export const CLEAR_DELETE_CONTRACT_SIGNATURE = concat(MODULE_NAME, 'CLEAR_DELETE_CONTRACT_SIGNATURE')

export const GET_CONTRACTS = concat(MODULE_NAME, 'GET_CONTRACTS')
export const GET_CONTRACTS_SUCCESS = concat(MODULE_NAME, 'GET_CONTRACTS_SUCCESS')
export const GET_CONTRACTS_FAILURE = concat(MODULE_NAME, 'GET_CONTRACTS_FAILURE')
export const CLEAR_GET_CONTRACTS = concat(MODULE_NAME, 'CLEAR_GET_CONTRACTS')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// Get contract
export function getContract(id, params) {
  return { type: GET_CONTRACT, id, params }
}

export function getContractSuccess(data) {
  return { type: GET_CONTRACT_SUCCESS, data }
}

export function getContractFailure(error) {
  return { type: GET_CONTRACT_FAILURE, error }
}

export function clearGetContract() {
  return { type: CLEAR_GET_CONTRACT }
}

export function setActiveContract(contract) {
  return { type: SET_ACTIVE_CONTRACT, contract }
}

export function clearActiveContract() {
  return { type: CLEAR_ACTIVE_CONTRACT }
}

// Create contract
export function createContract(data) {
  return { type: CREATE_CONTRACT, data }
}

export function createContractSuccess(data) {
  return { type: CREATE_CONTRACT_SUCCESS, data }
}

export function createContractFailure(error) {
  return { type: CREATE_CONTRACT_FAILURE, error }
}

export function clearCreateContract() {
  return { type: CLEAR_CREATE_CONTRACT }
}

// Update contract
export function updateContract(id, data, requestMethod = 'PUT') {
  return { type: UPDATE_CONTRACT, id, data, requestMethod }
}

export function updateContractSuccess(data) {
  return { type: UPDATE_CONTRACT_SUCCESS, data }
}

export function updateContractFailure(error) {
  return { type: UPDATE_CONTRACT_FAILURE, error }
}

export function clearUpdateContract() {
  return { type: CLEAR_UPDATE_CONTRACT }
}

// Update contracts status bulk
export function updateContractsStatusBulk(data) {
  return { type: UPDATE_CONTRACTS_STATUS_BULK, data }
}

export function updateContractsStatusBulkSuccess(data) {
  return { type: UPDATE_CONTRACTS_STATUS_BULK_SUCCESS, data }
}

export function updateContractsStatusBulkFailure(error) {
  return { type: UPDATE_CONTRACTS_STATUS_BULK_FAILURE, error }
}

export function clearUpdateContractsStatusBulk() {
  return { type: CLEAR_UPDATE_CONTRACTS_STATUS_BULK }
}

// Sign contract
export function signContract(id, data, params) {
  return { type: SIGN_CONTRACT, id, data, params }
}

export function signContractSuccess(data) {
  return { type: SIGN_CONTRACT_SUCCESS, data }
}

export function signContractFailure(error) {
  return { type: SIGN_CONTRACT_FAILURE, error }
}

export function clearSignContract() {
  return { type: CLEAR_SIGN_CONTRACT }
}

// Delete contract signature
export function deleteContractSignature(id, params) {
  return { type: DELETE_CONTRACT_SIGNATURE, id, params }
}

export function deleteContractSignatureSuccess(data) {
  return { type: DELETE_CONTRACT_SIGNATURE_SUCCESS, data }
}

export function deleteContractSignatureFailure(error) {
  return { type: DELETE_CONTRACT_SIGNATURE_FAILURE, error }
}

export function clearDeleteContractSignature() {
  return { type: CLEAR_DELETE_CONTRACT_SIGNATURE }
}

// Get contracts
export function getContracts(params) {
  return { type: GET_CONTRACTS, params }
}

export function getContractsSuccess(data) {
  return { type: GET_CONTRACTS_SUCCESS, data }
}

export function getContractsFailure(error) {
  return { type: GET_CONTRACTS_FAILURE, error }
}

export function clearGetContracts() {
  return { type: CLEAR_GET_CONTRACTS }
}
