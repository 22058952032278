import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useFormik } from 'formik'

import StepForm from '../../../../../features/components/Forms/StepForm'

import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'
import useManageUserSettings from '../../../../../features/hooks/useManageUserSettings'
import useLineItemCreateHandlers from './useLineItemCreateHandlers'
import useLineItemCreateSteps from './useLineItemCreateSteps'
import useLineItemCreateShowSteps from './useLineItemCreateShowSteps'

import { campaignSelector } from '../../../../../modules/selectors/campaigns'
import {
  lineItemCreateErrorSelector,
  lineItemCreateIsLoadingSelector,
  lineItemWasCreatedSelector
} from '../../../../../modules/selectors/lineItems'
import {
  tikTokActionCategoriesSelector,
  tikTokInterestsSelector,
  tikTokRegionsSelector
} from '../../../../../modules/selectors/tikTokConstants'
import { selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'

import { getValidationSchema } from './validation'
import { LINE_ITEM_TIKTOK_CREATE } from '../../../../../constants/forms'
import { ENABLE_COMMENTS_OPTION, getInitialValues } from './fields'
import { REACH } from '../../../../../constants/campaigns'

const LineItemTikTokCreateForm = ({ onSuccessSubmit }) => {
  const { campaign_id: campaignId } = useParams()

  const campaign = useSelector(campaignSelector)
  const lineItemWasCreated = useSelector(lineItemWasCreatedSelector)
  const tikTokRegions = useSelector(tikTokRegionsSelector)
  const actionCategories = useSelector(tikTokActionCategoriesSelector)
  const interests = useSelector(tikTokInterestsSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const {
    date_start: campaignDateStart,
    objective: campaignObjective = '',
    buying_type: campaignBuyingType,
    budget_optimize_on: isCampaignLevelBudgetSet
  } = campaign

  const { getSavedSettings } = useManageUserSettings(selectedAdAccountId)
  const savedSettings = getSavedSettings(LINE_ITEM_TIKTOK_CREATE)

  const showEnableCommentsStep = useMemo(() => !savedSettings[ENABLE_COMMENTS_OPTION], [savedSettings])

  const {
    // bid type
    isBidTypeStepInitiallyShown,
    showBidPriceStep,
    isBidTypeCustomPopulated,
    setIsBidTypeStepInitiallyShown,
    // pixel
    showPixelStep,
    pixelIdFromExistingLineItem,
    externalActionFromExistingLineItem,
    isPixelStepInitiallyShown,
    setIsPixelStepInitiallyShown
  } = useLineItemCreateShowSteps({
    isCampaignLevelBudgetSet,
    campaignObjective
  })

  const { onSubmit, onSuccessSubmitHandler, clearCreateAdGroupHandler } = useLineItemCreateHandlers({
    selectedAdAccountId,
    campaignId,
    campaignDateStart,
    campaignObjective,
    showBidPriceStep,
    isBidTypeCustomPopulated,
    isCampaignLevelBudgetSet,
    tikTokRegions,
    actionCategories,
    interests,
    onSuccessSubmit,
    showPixelStep,
    pixelIdFromExistingLineItem,
    externalActionFromExistingLineItem
  })

  const formik = useFormik({
    initialValues: getInitialValues(savedSettings, campaignBuyingType),
    validationSchema: getValidationSchema({
      isCampaignLevelBudgetSet,
      campaignObjective,
      showPixelStep,
      showBidPriceStep,
      isBidTypeCustomPopulated
    }),
    onSubmit,
    enableReinitialize: true
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const showBudgetStep = !isCampaignLevelBudgetSet

  const showFrequencyStep = useMemo(() => campaignObjective.toLowerCase() === REACH, [campaignObjective])

  const steps = useLineItemCreateSteps({
    campaignBuyingType,
    showBudgetStep,
    showFrequencyStep,
    showEnableCommentsStep,
    showPixelStep,
    campaignObjective,
    isBidTypeCustomPopulated,
    showBidPriceStep,
    isBidTypeStepInitiallyShown,
    isPixelStepInitiallyShown
  })

  // useEffects to fix steps flickering UI bugs after successful creations
  useEffect(() => {
    if (showBidPriceStep) {
      setIsBidTypeStepInitiallyShown(true)
    }
  }, [showBidPriceStep, setIsBidTypeStepInitiallyShown])

  useEffect(() => {
    if (showPixelStep) {
      setIsPixelStepInitiallyShown(true)
    }
  }, [showPixelStep, setIsPixelStepInitiallyShown])

  return (
    <StepForm
      steps={steps}
      formik={purifiedFormik}
      formName={LINE_ITEM_TIKTOK_CREATE}
      isLoadingSelector={lineItemCreateIsLoadingSelector}
      errorSelector={lineItemCreateErrorSelector}
      successSubmit={lineItemWasCreated}
      onSuccessSubmit={onSuccessSubmitHandler}
      clearSubmitHandler={clearCreateAdGroupHandler}
    />
  )
}

export default LineItemTikTokCreateForm
