import { concat } from '../../helpers/common'

const MODULE_NAME = 'DV360_CONSTANTS/'

// create bulk targeting
export const CREATE_DV360_BULK_TARGETING = concat(MODULE_NAME, 'CREATE_DV360_BULK_TARGETING')
export const CREATE_DV360_BULK_TARGETING_SUCCESS = concat(MODULE_NAME, 'CREATE_DV360_BULK_TARGETING_SUCCESS')
export const CREATE_DV360_BULK_TARGETING_FAILURE = concat(MODULE_NAME, 'CREATE_DV360_BULK_TARGETING_FAILURE')
export const CLEAR_CREATE_DV360_BULK_TARGETING = concat(MODULE_NAME, 'CLEAR_CREATE_DV360_BULK_TARGETING')

// Create bulk targeting
export function createDv360BulkTargeting(params) {
  return { type: CREATE_DV360_BULK_TARGETING, params }
}

export function createDv360BulkTargetingSuccess(data) {
  return { type: CREATE_DV360_BULK_TARGETING_SUCCESS, data }
}

export function createDv360BulkTargetingFailure(error) {
  return { type: CREATE_DV360_BULK_TARGETING_FAILURE, error }
}

export function clearCreateDv360BulkTargeting() {
  return { type: CLEAR_CREATE_DV360_BULK_TARGETING }
}
