import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import ActionText from '../../../../ActionText'

import useStyles from './styles'

const SelectAllOptions = ({ selectProps, options }) => {
  const classes = useStyles()

  const selectedOptions = selectProps?.value
  const onSelectAll = selectProps?.onSelectAll
  const isAllSelected = options?.length === selectedOptions?.length

  const handleSelectAll = useCallback(() => {
    const newSelectedOptions = isAllSelected
      ? []
      : // selectedOptions could have options that are not in the options list
        // concat the selected options with the options that are not selected
        [
          ...selectedOptions,
          ...options.filter(
            option =>
              // also need check if there are no duplication of options so that the options are not selected twice
              !selectedOptions.some(selected => selected.value === option.value)
          )
        ]

    onSelectAll(newSelectedOptions)
  }, [onSelectAll, options, selectedOptions, isAllSelected])

  return (
    <ActionText onClick={handleSelectAll} className={classes.selectAll} isDark>
      {isAllSelected ? 'Deselect All' : `Select All (${options.length})`}
    </ActionText>
  )
}

SelectAllOptions.propTypes = {
  selectProps: PropTypes.object,
  options: PropTypes.array
}

export default SelectAllOptions
