import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import Drawer from '../../../../components/Drawer'
import AdFacebookEditContent from './AdFacebookEditContent'

import useLoadInstagramAccounts from '../../../../hooks/instagramAccountsHooks/useLoadInstagramAccounts'
import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'
import useManageEditFormData from '../../../../hooks/formHooks/useManageEditFormData'

import { transformValuesToFE, getPlacementPositions } from './helpers'
import { createJsonFromQueryString } from '../../../../helpers/url'
import { useFacebookMediaLoading } from '../../../../pages/CampaignsSummary/SummaryPagesAdsContent/FacebookAdCardList/useFacebookMediaLoading'
import { getImageHashes, getVideoIds } from './helpers/mediaFileHelpers'

import { adSelector, adWasLoadedSelector, getAdsSelector } from '../../../../modules/selectors/ads'
import {
  lineItemSelector,
  lineItemsSelector,
  lineItemsWasLoadedSelector,
  lineItemWasLoadedSelector
} from '../../../../modules/selectors/lineItems'
import {
  facebookImageUrlsSelector,
  facebookRequestedVideoIdsSelector,
  facebookVideoUrlsSelector
} from '../../../../modules/selectors/mediaFiles'
import { campaignSelector } from '../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../modules/selectors/app'
import { getFacebookVideoUrls } from '../../../../modules/actions/mediaFiles'
import { clearChoicesCTAByGoal, getChoicesCTAByGoal } from '../../../../modules/actions/choices'
import { clearAd, getAd } from '../../../../modules/actions/ads'
import { clearInstagramAccounts, getAccessibleFacebookPages } from '../../../../modules/actions/socialAccounts'
import { clearCampaign, getCampaign } from '../../../../modules/actions/campaigns'

import { AD_FACEBOOK_EDIT } from '../../../../constants/forms'
import { FB_PAGE_CHOICE } from '../fields'

import { FACEBOOK_PLATFORM } from '../../../../constants/selectLists/platformList'

const AdFacebookEdit = ({ shouldCheckForPlacementsPositionsLoading = true, filter, isAdApprovalsPage }) => {
  const dispatch = useDispatch()

  // if ad is internal, it appears in query params when we open edit form
  const { internal: internalQueryParam } = createJsonFromQueryString(window.location.search)

  const isInternalAd = internalQueryParam === 'true'

  const ad = useSelector(adSelector)
  const ads = useSelector(getAdsSelector)

  const adWasLoaded = useSelector(adWasLoadedSelector)

  const lineItems = useSelector(lineItemsSelector)
  const lineItem = useSelector(lineItemSelector)

  const lineItemWasLoaded = useSelector(lineItemWasLoadedSelector)
  const lineItemsWasLoaded = useSelector(lineItemsWasLoadedSelector)

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const videoUrls = useSelector(facebookVideoUrlsSelector)
  const imageUrls = useSelector(facebookImageUrlsSelector)
  const requestedVideoIds = useSelector(facebookRequestedVideoIdsSelector)
  const { objective: campaignObjective } = useSelector(campaignSelector)

  const placementPositions = getPlacementPositions({ ad, lineItem, lineItems, lineItemWasLoaded, lineItemsWasLoaded })

  const imageHashes = useMemo(() => {
    return adWasLoaded ? getImageHashes(ad) : []
  }, [adWasLoaded, ad])

  const videoIds = useMemo(() => {
    return adWasLoaded ? getVideoIds(ad) : []
  }, [adWasLoaded, ad])

  const getAdDataHandler = useCallback(
    selectedEditItemId => {
      const currentAd = ads.find(({ id }) => Number(id) === Number(selectedEditItemId))
      const currentAdAdAccountId = currentAd?.ad_account_id

      const adAccount = currentAdAdAccountId || selectedAdAccountId

      dispatch(getAccessibleFacebookPages())
      dispatch(
        getAd({
          platform: FACEBOOK_PLATFORM,
          account: adAccount,
          id: selectedEditItemId,
          ...(isInternalAd && { internal: true })
        })
      )

      if (isAdApprovalsPage) {
        // on AdApprovals page we need to fetch campaign to get campaignObjective
        const internalAd = ads.find(item => item.id === selectedEditItemId)
        const campaignId = internalAd?.campaign_id
        const adAccountId = internalAd?.ad_account_id
        dispatch(
          getCampaign(
            {
              id: campaignId,
              account: adAccountId
            },
            FACEBOOK_PLATFORM
          )
        )
      }
    },
    [dispatch, ads, selectedAdAccountId, isInternalAd, isAdApprovalsPage]
  )

  const clearAdDataHandler = useCallback(() => {
    dispatch(clearAd())
    dispatch(clearInstagramAccounts())
    dispatch(clearChoicesCTAByGoal())

    if (isAdApprovalsPage) {
      dispatch(clearCampaign())
    }
  }, [dispatch, isAdApprovalsPage])

  const { isFormOpen, handleFormClose } = useManageFormsDrawer({ formName: AD_FACEBOOK_EDIT })

  useManageEditFormData({
    formName: AD_FACEBOOK_EDIT,
    loadedDataId: ad.id,
    getDataHandler: getAdDataHandler,
    clearDataHandler: clearAdDataHandler
  })

  const transformedOriginalValues = useMemo(
    () =>
      transformValuesToFE({
        ad,
        adWasLoaded,
        placementPositions,
        imageUrls,
        videoUrls,
        selectedAdAccountId,
        isEditForm: true
      }),
    [ad, adWasLoaded, placementPositions, imageUrls, videoUrls, selectedAdAccountId]
  )

  const originalFacebookPage = transformedOriginalValues && transformedOriginalValues[FB_PAGE_CHOICE]

  const [selectedFacebookPage, setSelectedFacebookPage] = useState(originalFacebookPage)
  useLoadInstagramAccounts({
    facebookPageId: selectedFacebookPage || originalFacebookPage,
    shouldLoadPageAccessToken: isFormOpen,
    shouldLoadInstagramAccounts: isFormOpen,
    shouldLoadBackedInstagramAccounts: isFormOpen,
    adAccountId: selectedAdAccountId
  })

  const { initialMediaIsLoading } = useFacebookMediaLoading({
    shouldLoadMedia: isFormOpen,
    imageHashes,
    videoIds
  })

  const notRequestedVideoIds = useMemo(() => {
    // Check if ad has videoIds which were not fetched yet
    // if it has - we need to fetch it to be able to represent video file name.
    if (adWasLoaded) {
      const adVideoIds = getVideoIds(ad)
      return adVideoIds.filter(videoId => !requestedVideoIds.includes(videoId))
    } else {
      return []
    }
  }, [adWasLoaded, ad, requestedVideoIds])

  // get call-to-action list
  useEffect(() => {
    if (campaignObjective) {
      dispatch(getChoicesCTAByGoal({ goals: campaignObjective }))
    }
  }, [dispatch, campaignObjective])

  useEffect(() => {
    // Usually we fetch video url only when user press "play"
    // but in edit form we also need to represent video file name,
    // so we fetch all video info when edit form is opened
    if (notRequestedVideoIds.length) {
      dispatch(getFacebookVideoUrls({ account: selectedAdAccountId, video_ids: notRequestedVideoIds }))
    }
  }, [notRequestedVideoIds, dispatch, selectedAdAccountId])

  return (
    <Drawer
      title="Update the settings of your ad"
      subTitle="Edit a Facebook / Instagram ad"
      isOpen={isFormOpen}
      onClose={handleFormClose}
    >
      <AdFacebookEditContent
        shouldCheckForPlacementsPositionsLoading={shouldCheckForPlacementsPositionsLoading}
        initialMediaIsLoading={initialMediaIsLoading}
        setSelectedFacebookPage={setSelectedFacebookPage}
        filter={filter}
        notRequestedVideoIds={notRequestedVideoIds}
      />
    </Drawer>
  )
}

AdFacebookEdit.propTypes = {
  shouldCheckForPlacementsPositionsLoading: PropTypes.bool,
  filter: PropTypes.string
}

export default AdFacebookEdit
