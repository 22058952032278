import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { isFuture } from 'date-fns'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import UploadedFile from './UploadedFile'
import Chip from '../../../../../components/Chip'
import Icon from '../../../../../components/Icon'

import { ReactComponent as UploadIcon } from '../../../../../assets/icons/upload.svg'

import { getFileStatus } from '../helpers'
import { combineDateAndTime } from '../../../../../helpers/date'

import { openForm } from '../../../../../modules/actions/forms'

import { UPLOAD_BOOKED_MEDIA_FILES } from '../../../../../constants/forms'

import useStyles from './styles'

const BookedMediaFile = ({ file, deadline, mediaId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { required: requirements, uploaded: uploadedFile } = file
  const uploadedFileIsApproved = uploadedFile?.approval_status === 'approved'

  const classes = useStyles(uploadedFileIsApproved)
  const deadlineDate = useMemo(() => new Date(deadline), [deadline])
  const isDeadlineInFuture = isFuture(combineDateAndTime(deadlineDate, '23:59'))

  const fileName = requirements ? `${requirements.file_name}${requirements.required_file ? '*' : ''}` : 'Uploaded file'
  const fileStatus = getFileStatus(uploadedFile, deadlineDate, isDeadlineInFuture)

  const openUploadFileForm = useCallback(() => {
    dispatch(
      openForm({
        id: requirements?.id,
        formName: UPLOAD_BOOKED_MEDIA_FILES,
        otherParams: `mediaId=${mediaId}`
      })
    )
  }, [dispatch, requirements?.id, mediaId])

  return (
    <div className={classes.fileCard}>
      <div className={classes.fileManage}>
        {!!uploadedFile ? (
          <UploadedFile uploadedFile={uploadedFile} isDeadlineInFuture={isDeadlineInFuture} />
        ) : (
          <div className={classes.uploadFileBtn} onClick={openUploadFileForm}>
            <Icon inheritColor className={classes.uploadIcon}>
              <UploadIcon />
            </Icon>
            <div className={classes.cta}>{t('Click here to upload file')}</div>
          </div>
        )}
      </div>
      <div className={classes.fileDetails}>
        <h5 className={classes.fileName}>{fileName}</h5>
        <Chip className={classes.statusChip} text={fileStatus.text} color={fileStatus.color} />
      </div>
    </div>
  )
}

BookedMediaFile.propTypes = {
  file: PropTypes.shape({
    required: PropTypes.shape({
      id: PropTypes.number,
      file_name: PropTypes.string,
      required_file: PropTypes.bool
    }),
    uploaded: PropTypes.shape({
      approval_status: PropTypes.string,
      file_format: PropTypes.string
    })
  }),
  deadline: PropTypes.string,
  mediaId: PropTypes.number
}
export default BookedMediaFile
