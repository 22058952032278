import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import StepForm from '../../../../../features/components/Forms/StepForm'
import DetailsStep from './Steps/DetailsStep'
import ImageStep from './Steps/ImageStep'
import MediaItemsStep from './Steps/MediaItemsStep'
import PricesStep from './Steps/PricesStep'

import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'
import { useProgressItems } from './useProgressItems'

import { clearCreateMediaPackage, createMediaPackage } from '../../../../../modules/actions/mediaPackages'
import {
  createMediaPackageErrorSelector,
  createMediaPackageIsLoadingSelector,
  createMediaPackageWasCreatedSelector,
  mediaPackagesSelector
} from '../../../../../modules/selectors/mediaPackages'
import { selectedControllerDataSelector } from '../../../../../modules/selectors/app'

import { validationSchema } from './validation'
import { getInitialValues } from './fields'
import { transformValuesToBE } from './formatters'

import { MEDIA_PACKAGE_CREATE } from '../../../../../constants/forms'

function MediaPackageCreateForm() {
  const dispatch = useDispatch()

  const mediaPackages = useSelector(mediaPackagesSelector)
  const mediaPackageWasCreated = useSelector(createMediaPackageWasCreatedSelector)
  const controllerData = useSelector(selectedControllerDataSelector)

  const progressItems = useProgressItems()

  const controllerId = controllerData?.id
  const controllerCurrency = controllerData?.currency

  const onSubmit = useCallback(
    values => {
      const currentBiggestOrderNumber = mediaPackages.length ? Math.max(...mediaPackages.map(item => item.order)) : 0

      const formattedData = transformValuesToBE({ values, controllerId, currentBiggestOrderNumber })

      dispatch(createMediaPackage(formattedData))
    },
    [dispatch, controllerId, mediaPackages]
  )

  const formik = useFormik({
    initialValues: getInitialValues({ controllerCurrency }),
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const steps = useMemo(
    () => [
      {
        component: <DetailsStep />,
        show: true
      },
      {
        component: <MediaItemsStep controllerId={controllerId} />,
        show: true
      },
      {
        component: <PricesStep />,
        show: true
      },
      {
        component: <ImageStep />,
        show: true
      }
    ],
    [controllerId]
  )

  const clearCreateMediaPackageHandler = useCallback(() => {
    dispatch(clearCreateMediaPackage())
  }, [dispatch])

  return (
    <StepForm
      steps={steps}
      formik={purifiedFormik}
      formName={MEDIA_PACKAGE_CREATE}
      // processing
      isLoadingSelector={createMediaPackageIsLoadingSelector}
      errorSelector={createMediaPackageErrorSelector}
      successSubmit={mediaPackageWasCreated}
      // after form submit
      clearSubmitHandler={clearCreateMediaPackageHandler}
      formProgressItems={progressItems}
    />
  )
}

export default MediaPackageCreateForm
