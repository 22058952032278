import React from 'react'
import { useSelector } from 'react-redux'

import FacebookGeolocationRegion from '../../Regions/FacebookGeolocationRegion'

import { choicesCountriesSelector } from '../../../../../modules/selectors/choices'

import { SHOW_COUNTRY_CHOICE, SHOW_REGIONS } from '../../fields'

const FacebookRegions = ({ formik }) => {
  const { values, setFieldValue } = formik

  const countries = useSelector(choicesCountriesSelector)

  const selectedRegions = values[SHOW_REGIONS]
  const countryChoice = values[SHOW_COUNTRY_CHOICE]
  // Find the country selected by user in countries array
  // and after that dispatch getChoicesGeolocations action
  // with country code for proper sorting in regions select
  const userChoiceCountry = countries.find(item => item.id === countryChoice)

  return (
    <FacebookGeolocationRegion
      selectedCountry={userChoiceCountry}
      name={SHOW_REGIONS}
      value={selectedRegions}
      setFieldValue={setFieldValue}
    />
  )
}

export default FacebookRegions
