import { useEffect, useCallback, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'

import { isTextAssetType } from '../helpers'
import { formatTextValuesToAssetsCreation } from '../formatters'
import { formatAssetsToAssetGroupUpdate, formatImageAssetsToAssetGroupUpdate, formatUpdatedAssets } from './formatters'

import {
  clearCreateAssetsBatch,
  createAssetsBatch,
  updateAssetGroup,
  updateAssetGroupSuccess
} from '../../../../../modules/actions/assets'
import { assetsCreatedDataSelector, assetsWasCreatedSelector } from '../../../../../modules/selectors/assets'

import { GOOGLE_PLATFORM } from '../../../../../constants/selectLists/platformList'
import { FILE_URL } from '../../fields'

export function useEditAssetGroupSubmit({
  originalSectionAssets,
  fieldType,
  assetGroupId,
  isInternalAssetGroup,
  initialValues,
  sectionValueKey,
  validationSchema,
  adAccount
}) {
  const dispatch = useDispatch()

  const [assetsReady, setAssetsReady] = useState(false)
  const [newAssets, setNewAssets] = useState([])
  const [deleteAssets, setDeleteAssets] = useState([])

  const assetsWasCreated = useSelector(assetsWasCreatedSelector)
  const createdAssetsData = useSelector(assetsCreatedDataSelector)

  const isTextAsset = useMemo(() => isTextAssetType(fieldType), [fieldType])

  const createNewTextAssets = useCallback(
    createAssets => {
      const formattedAssets = formatTextValuesToAssetsCreation({
        values: { [sectionValueKey]: createAssets },
        adAccountId: adAccount,
        allowIncludeYoutubeLink: true
      })
      // formatTextListToAsset
      dispatch(
        createAssetsBatch(
          {
            account: adAccount,
            operations: formattedAssets
          },
          GOOGLE_PLATFORM
        )
      )
    },
    [dispatch, sectionValueKey, adAccount]
  )

  const handleSubmit = useCallback(
    // AssetGroupGoogleEdit form submission consists from 2 different requests:
    // createAssetsBatch for newly added assets and then updateAssetGroup once first successfully submitted the
    // 2nd is triggerred

    values => {
      // each asset after update should have some "action": create/update/delete
      const { createAssets, deleteAssets } = formatUpdatedAssets(
        originalSectionAssets,
        values[sectionValueKey],
        isInternalAssetGroup
      )

      if (isTextAsset && !!createAssets.length) {
        createNewTextAssets(createAssets)
      } else {
        // skip asset creation and run assetGroup update directly
        setAssetsReady(true)
        // image assets are already created when edit form is submitting

        // filter not uploaded image, in case user pressed 'add one more image', but have not selected file
        const uploadedCreateAssets = createAssets.filter(item => {
          return !!item[FILE_URL]
        })

        setNewAssets(uploadedCreateAssets)
      }

      setDeleteAssets(deleteAssets)
    },
    [sectionValueKey, createNewTextAssets, originalSectionAssets, isTextAsset, isInternalAssetGroup]
  )

  useEffect(() => {
    if (assetsReady) {
      // send update request when all data ready(create assets request was finished)
      let formattedCreateAssets
      if (isTextAsset) {
        formattedCreateAssets = formatAssetsToAssetGroupUpdate(createdAssetsData.operations, fieldType, assetGroupId)
      } else {
        formattedCreateAssets = formatImageAssetsToAssetGroupUpdate(newAssets, fieldType, assetGroupId)
      }

      const updatedAssetsList = [...deleteAssets, ...formattedCreateAssets]

      if (!!updatedAssetsList.length) {
        dispatch(
          updateAssetGroup(
            {
              account: adAccount,
              assets: updatedAssetsList,
              ...(isInternalAssetGroup && { internal: true })
            },
            assetGroupId,
            GOOGLE_PLATFORM
          )
        )
      } else {
        // ignore sending the request if the assets list is empty and dispatch success update for trigering all successSubmit actions i.e. closing form, clearing data and etc..
        dispatch(updateAssetGroupSuccess({}))
      }

      setAssetsReady(false)
    }
  }, [
    dispatch,
    isTextAsset,
    fieldType,
    assetsReady,
    adAccount,
    deleteAssets,
    createdAssetsData,
    newAssets,
    assetGroupId,
    isInternalAssetGroup
  ])

  useEffect(() => {
    if (assetsWasCreated) {
      setAssetsReady(true)
    }
  }, [assetsWasCreated])

  useEffect(() => {
    return () => {
      dispatch(clearCreateAssetsBatch())
    }
  }, [dispatch])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema
  })
  return usePurifiedFormik(formik)
}
