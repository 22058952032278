export function textEllipsis() {
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}

export function textEllipsisMultiline(maxLines = 2) {
  return {
    lineClamp: maxLines,
    overflow: 'hidden',
    display: 'box',
    boxOrient: 'vertical'
  }
}
