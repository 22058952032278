import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import MediaCategoryForm from '../../MediaCategoryForm'

import {
  clearUpdateMediaPackageCategory,
  updateMediaPackageCategory
} from '../../../../../modules/actions/mediaPackages'
import {
  updateMediaPackageCategoryErrorSelector,
  updateMediaPackageCategoryIsLoadingSelector,
  updateMediaPackageCategoryWasUpdatedSelector
} from '../../../../../modules/selectors/mediaPackages'

import { MEDIA_CATEGORY_EDIT } from '../../../../../constants/forms'

function MediaCategoryCreateForm({ editCategoryData = {} }) {
  const dispatch = useDispatch()

  const categoryWasUpdated = useSelector(updateMediaPackageCategoryWasUpdatedSelector)

  const { id } = editCategoryData

  const onSubmit = useCallback(
    values => {
      dispatch(
        updateMediaPackageCategory(id, {
          ...values
        })
      )
    },
    [dispatch, id]
  )

  const clearUpdateCategoryHandler = useCallback(() => dispatch(clearUpdateMediaPackageCategory()), [dispatch])

  return (
    <MediaCategoryForm
      editCategoryData={editCategoryData}
      onSubmit={onSubmit}
      formName={MEDIA_CATEGORY_EDIT}
      successSubmit={categoryWasUpdated}
      clearHandler={clearUpdateCategoryHandler}
      errorSelector={updateMediaPackageCategoryErrorSelector}
      isLoadingSelector={updateMediaPackageCategoryIsLoadingSelector}
    />
  )
}

export default MediaCategoryCreateForm
