import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'

import MultipleCheckboxesSelect from '../../../../components/MultipleCheckboxesSelect'

import { bookedMediaStatusesArray, bookedMediaStatusesLabels } from '../../../../constants/bookedMedia'

import useStyles from './styles'

const formattedStatusesOptions = bookedMediaStatusesArray.map(item => {
  return { value: item, label: bookedMediaStatusesLabels[item] }
})

const BookedMediaMultipleStatusesFilter = ({ Context }) => {
  const classes = useStyles()

  const { selectedStatuses, setSelectedStatuses } = useContext(Context)

  const selectStatusHandler = useCallback(
    data => {
      setSelectedStatuses(data)
    },
    [setSelectedStatuses]
  )

  return (
    <MultipleCheckboxesSelect
      className={classes.statusFilter}
      options={formattedStatusesOptions}
      value={selectedStatuses}
      onChange={selectStatusHandler}
      placeholder="Status"
      multipleOptionsLabel="Status"
      isSearchable={false}
    />
  )
}

BookedMediaMultipleStatusesFilter.propTypes = {
  Context: PropTypes.object.isRequired
}

export default BookedMediaMultipleStatusesFilter
