import { formatChildAttachments, formatSingleItemFeedToBE } from '../../helpers'
import { getPlatformUtmCode } from './helpers/getPlatformUtmCode'

import {
  AD_LINK,
  CAROUSEL_ITEMS,
  IS_CUSTOMIZED_MEDIA,
  FB_PAGE_CHOICE,
  FILE_HASH,
  FILE_ID,
  FORMAT_CAROUSEL,
  FORMAT_OPTION,
  FORMAT_PRODUCT,
  FORMAT_SINGLE,
  FORMAT_STORY,
  INSTA_ACCOUNT_CHOICE,
  MEDIA_TYPE,
  NAME,
  PRIMARY_TEXT_LIST,
  STORIES_ITEMS,
  VIDEO_PICTURE,
  SINGLE_MEDIA_ITEM,
  HEADLINES_LIST,
  DESCRIPTIONS_LIST
} from '../../fields'
import { CALL_TO_ACTION } from '../../../../ReusableFormFields/CallToActionFields/fields'

export const formatAdUrlTagsToBE = url => {
  const platformUtmCode = getPlatformUtmCode()

  const urlHasUtmCode = url.includes('utm_')
  if (urlHasUtmCode) {
    // pass utmCode only when URL is without it
    return null
  } else {
    // utm_campaign, utm_content should be hardcoded as {{campaign.id}} and {{ad.name}} these will be parsed by Facebook
    return `utm_source=Facebook&utm_medium=${platformUtmCode}&utm_campaign={{campaign.id}}&utm_content={{ad.name}}`
  }
}

export const formatAdCreativeToBE = ({ values, adAccountId }) => {
  const { [FORMAT_OPTION]: formatOption, [CAROUSEL_ITEMS]: carouselItems } = values

  switch (formatOption) {
    case FORMAT_CAROUSEL:
      return formatCarouselItemsToBE({ values, items: carouselItems })
    case FORMAT_STORY:
      return formatStoriesItemsToBE({ values })
    case FORMAT_SINGLE:
    case FORMAT_PRODUCT:
    default:
      return formatSingleImageOrVideoToBE({ values, adAccountId })
  }
}

const formatSingleImageOrVideoToBE = ({ values, adAccountId }) => {
  if (!!values[IS_CUSTOMIZED_MEDIA]) {
    return formatSingleItemWithStoryToBE({ values, adAccountId })
  } else {
    return formatSingleItemToBE({ values })
  }
}

export const formatSingleItemToBE = ({ values }) => {
  const { [INSTA_ACCOUNT_CHOICE]: instagramAccountChoice, [FB_PAGE_CHOICE]: facebookPageChoice } = values

  const mediaItem = values[SINGLE_MEDIA_ITEM]
  const adLink = mediaItem[AD_LINK]

  const tags = formatAdUrlTagsToBE(adLink)
  return {
    ...(instagramAccountChoice && { instagram_actor_id: instagramAccountChoice }),
    ...(tags && { url_tags: tags }),
    object_story_spec: {
      ...formatSingleItemFeedToBE(values),
      page_id: facebookPageChoice
    }
  }
}

export const formatSingleItemWithStoryToBE = ({ values, adAccountId }) => {
  const {
    [NAME]: name,
    [INSTA_ACCOUNT_CHOICE]: instagramAccountChoice,
    [FB_PAGE_CHOICE]: facebookPageChoice,
    [STORIES_ITEMS]: storiesItems
  } = values

  const mediaItem = values[SINGLE_MEDIA_ITEM]
  const {
    [MEDIA_TYPE]: mediaType,
    [VIDEO_PICTURE]: feedVideoPicture,
    [FILE_ID]: feedVideoId,
    [AD_LINK]: adLink,
    [FILE_HASH]: fileHash,
    [CALL_TO_ACTION]: callToAction
  } = mediaItem

  const tags = formatAdUrlTagsToBE(adLink)
  // use first element from primary text list
  const adText = values[PRIMARY_TEXT_LIST]?.[0].text
  const adHeadline = mediaItem[HEADLINES_LIST]?.[0].text
  const adDescription = mediaItem[DESCRIPTIONS_LIST]?.[0].text

  const imageHashForStoryImage = storiesItems[0][FILE_HASH]
  const storyMediaType = storiesItems[0][MEDIA_TYPE]
  const storyVideoId = storiesItems[0][FILE_ID]
  const storyVideoPicture = storiesItems[0][VIDEO_PICTURE]

  return {
    account_id: adAccountId,
    actor_id: facebookPageChoice,
    ...(instagramAccountChoice && { instagram_actor_id: instagramAccountChoice }),
    name,
    object_type: 'share',
    object_story_spec: {
      page_id: facebookPageChoice
    },
    ...(tags && { url_tags: tags }),
    asset_feed_spec: {
      ad_formats: ['AUTOMATIC_FORMAT'],
      additional_data: {
        multi_share_end_card: false
      },
      asset_customization_rules: [
        {
          ...(!!adText && {
            body_label: {
              name: 'label_story_primarytext'
            }
          }),
          customization_spec: {
            age_max: '65',
            age_min: '13',
            publisher_platforms: ['facebook', 'instagram', 'audience_network', 'messenger'],
            facebook_positions: ['story'],
            instagram_positions: ['story', 'reels'],
            messenger_positions: ['story'],
            audience_network_positions: ['classic', 'rewarded_video']
          },
          ...(storyMediaType === 'image'
            ? {
                image_label: {
                  name: 'label_story_image'
                }
              }
            : {
                video_label: {
                  name: 'label_story_video'
                }
              }),
          link_url_label: {
            name: 'label_story_link'
          },
          priority: 1,
          ...(!!adHeadline && {
            title_label: {
              name: 'label_story_headline'
            }
          })
        },
        {
          ...(!!adText && {
            body_label: {
              name: 'label_other_primarytext'
            }
          }),
          customization_spec: {
            age_max: '65',
            age_min: '13'
          },
          ...(mediaType === 'image'
            ? // MAIN FEED IS AN IMAGE
              {
                image_label: {
                  name: 'label_other_image'
                }
              }
            : // MAIN FEED IS A VIDEO
              {
                video_label: {
                  name: 'label_other_video'
                }
              }),
          link_url_label: {
            name: 'label_other_link'
          },
          priority: 2,
          ...(!!adHeadline && {
            title_label: {
              name: 'label_other_headline'
            }
          })
        }
      ],

      ...(!!adText && {
        bodies: [
          {
            adlabels: [
              {
                name: 'label_other_primarytext'
              },
              {
                name: 'label_story_primarytext'
              }
            ],
            text: adText
          }
        ]
      }),
      call_to_action_types: [callToAction.toLowerCase()],
      ...(adDescription && { descriptions: [{ text: adDescription }] }),
      // MAIN FEED OR STORY IS AN IMAGE
      ...((mediaType === 'image' || storyMediaType === 'image') && {
        images: [
          // MAIN FEED IS AN IMAGE
          ...(mediaType === 'image'
            ? [
                {
                  adlabels: [
                    {
                      name: 'label_other_image'
                    }
                  ],
                  hash: fileHash
                }
              ]
            : []),
          // STORY IS AN IMAGE
          ...(storyMediaType === 'image'
            ? [
                {
                  adlabels: [
                    {
                      name: 'label_story_image'
                    }
                  ],
                  hash: imageHashForStoryImage
                }
              ]
            : [])
        ]
      }),
      link_urls: [
        {
          adlabels: [
            {
              name: 'label_story_link'
            },
            {
              name: 'label_other_link'
            }
          ],
          website_url: adLink
        }
      ],
      ...(!!adHeadline && {
        titles: [
          {
            adlabels: [
              {
                name: 'label_other_headline'
              },
              {
                name: 'label_story_headline'
              }
            ],
            text: adHeadline
          }
        ]
      }),
      // MAIN FEED OR STORY IS A VIDEO
      ...((mediaType === 'video' || storyMediaType === 'video') && {
        videos: [
          // MAIN FEED IS A VIDEO
          ...(mediaType === 'video'
            ? [
                {
                  adlabels: [
                    {
                      name: 'label_other_video'
                    }
                  ],
                  thumbnail_url: feedVideoPicture,
                  video_id: feedVideoId
                }
              ]
            : []),
          // STORY IS A VIDEO
          ...(storyMediaType === 'video'
            ? [
                {
                  adlabels: [
                    {
                      name: 'label_story_video'
                    }
                  ],
                  thumbnail_url: storyVideoPicture,
                  video_id: storyVideoId
                }
              ]
            : [])
        ]
      })
    }
  }
}

export const formatCarouselItemsToBE = ({ values, items }) => {
  const { [INSTA_ACCOUNT_CHOICE]: instagramAccountChoice, [FB_PAGE_CHOICE]: facebookPageChoice } = values

  // use first element from primary text list
  const adText = values[PRIMARY_TEXT_LIST]?.[0].text

  // FB requires this field for a carousel ad, so the link is taken from first Carousel item
  const adLink = items[0][AD_LINK]
  const tags = formatAdUrlTagsToBE(adLink)

  return {
    ...(instagramAccountChoice && { instagram_actor_id: instagramAccountChoice }),
    ...(tags && { url_tags: tags }),
    object_story_spec: {
      link_data: {
        ...(adText && { message: adText }),
        multi_share_end_card: false,
        multi_share_optimized: false,
        link: adLink,
        child_attachments: formatChildAttachments(items)
      },
      page_id: facebookPageChoice
    }
  }
}

const formatStoriesItemsToBE = ({ values }) => {
  const { [STORIES_ITEMS]: storiesItems } = values

  const storyItemValues = storiesItems[0]

  const preparedValues = { ...values, ...storyItemValues }

  if (storiesItems.length === 1) {
    return formatSingleItemToBE({ values: preparedValues })
  } else {
    return formatCarouselItemsToBE({ values, items: storiesItems })
  }
}
