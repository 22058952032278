import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import usePermissions from '../../../../../../hooks/usePermissions'

import { getRowIdByMediaIdAndRequiredFileId } from '../../helpers'
import { insertIf } from '../../../../../../helpers/common'
import {
  APPROVED,
  BUYER_DISAPPROVED,
  DISAPPROVED,
  getApprovalActionStatusForBuyerApproval,
  PENDING_APPROVAL,
  PENDING_BUYER_APPROVAL
} from '../../../../../../features/components/UploadedFilesField/helpers'

import {
  deleteBookingMediaFileIsLoadingSelector,
  deleteBookingMediaFileSelector,
  updateBookedMediaFileIsLoadingSelector,
  updateBookedMediaFileSelector,
  uploadBookingMediaFileIsLoadingSelector,
  uploadBookingMediaFileSelector
} from '../../../../../../modules/selectors/mediaOrdersBookings'
import { openForm } from '../../../../../../modules/actions/forms'
import { deleteBookingMediaFile, updateBookingMediaFile } from '../../../../../../modules/actions/mediaOrdersBookings'
import { isControllerBuyerApprovalSelector } from '../../../../../../modules/selectors/app'

import { UPLOAD_BOOKED_MEDIA_FILES } from '../../../../../../constants/forms'
import { MEDIA_ORDER_FILES_PERMISSIONS } from '../../../../../../constants/permissions'

export const useGetUpdatingId = () => {
  const { requestedFileId: updatedId } = useSelector(uploadBookingMediaFileSelector)
  const uploadBookingMediaFileIsLoading = useSelector(uploadBookingMediaFileIsLoadingSelector)
  const { requirementFileId, booked_media: mediaId } = useSelector(deleteBookingMediaFileSelector)
  const deleteBookingMediaFileIsLoading = useSelector(deleteBookingMediaFileIsLoadingSelector)
  const { requirementFileId: updatingRequirementFileId, booked_media: updatingMediaId } =
    useSelector(updateBookedMediaFileSelector)
  const updateBookedMediaFileIsLoading = useSelector(updateBookedMediaFileIsLoadingSelector)

  return useMemo(() => {
    if (uploadBookingMediaFileIsLoading) {
      return updatedId
    } else if (deleteBookingMediaFileIsLoading) {
      return getRowIdByMediaIdAndRequiredFileId(mediaId, requirementFileId)
    } else if (updateBookedMediaFileIsLoading) {
      return getRowIdByMediaIdAndRequiredFileId(updatingMediaId, updatingRequirementFileId)
    }

    return undefined
  }, [
    uploadBookingMediaFileIsLoading,
    updatedId,
    deleteBookingMediaFileIsLoading,
    mediaId,
    requirementFileId,
    updateBookedMediaFileIsLoading,
    updatingMediaId,
    updatingRequirementFileId
  ])
}

export const useOpenFileForm = () => {
  const dispatch = useDispatch()

  return useCallback(
    (requestedFileId, mediaId) => () => {
      dispatch(
        openForm({
          id: requestedFileId,
          formName: UPLOAD_BOOKED_MEDIA_FILES,
          otherParams: `mediaId=${mediaId}`
        })
      )
    },
    [dispatch]
  )
}

export const useHandleDeleteUploadFile = () => {
  const dispatch = useDispatch()

  return useCallback(
    ({ requirementFileId, uploadedFile }) =>
      () => {
        dispatch(
          deleteBookingMediaFile(uploadedFile.id, {
            booked_media: uploadedFile.booked_media,
            requirementFileId
          })
        )
      },
    [dispatch]
  )
}

export const useGetDropdownOptions = ({ handleDelete, openUploadFileForm, handleFileDownload, handleShowModal }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isControllerBuyerApproval = useSelector(isControllerBuyerApprovalSelector)

  const permissions = usePermissions()

  const handleChangeStatus = useCallback(
    ({ status, requirementFileId, uploadedFile }) =>
      () => {
        dispatch(
          updateBookingMediaFile(uploadedFile?.id, {
            booked_media: uploadedFile?.booked_media,
            requirementFileId,
            approval_status: status
          })
        )
      },
    [dispatch]
  )

  const hasApprovePermissions = permissions.can('manage', MEDIA_ORDER_FILES_PERMISSIONS)

  const getApprovalOptions = useCallback(
    ({ uploadedFile, requirementFileId }) => {
      const fileStatus = uploadedFile?.approval_status
      if (isControllerBuyerApproval) {
        // pending_approval, approved, disapproved, pending_buyer_approval, buyer_disapproved

        const allowDisapprove =
          // if 'pending_approval' - user can disapprove it, this will change status to 'disapproved'
          fileStatus === PENDING_APPROVAL ||
          // if 'pending_buyer_approval' - user can disapprove it, this will change status to 'disapproved'
          fileStatus === PENDING_BUYER_APPROVAL ||
          // if 'approved' - user can disapprove it, this will change status to 't buyer_disapproved'
          fileStatus === APPROVED ||
          // if 'buyer_disapproved' - user can disapprove it, this will change status to 'disapproved'
          fileStatus === BUYER_DISAPPROVED

        const allowApprove =
          // if 'pending_approval' - user can approve it, this will change status to 'pending_buyer_approval'
          fileStatus === PENDING_APPROVAL ||
          // if 'pending_buyer_approval' - user can approve it, this will change status to 'approved'
          fileStatus === PENDING_BUYER_APPROVAL ||
          // if 'disapproved' - user can approve it, this will change status to 'pending_buyer_approval'
          fileStatus === DISAPPROVED ||
          // if 'buyer_disapproved' - user can approve it, this will change status to 'approved'
          fileStatus === BUYER_DISAPPROVED

        return [
          ...insertIf(allowApprove, {
            text: t('Approve'),
            onClickHandler: handleChangeStatus({
              status: getApprovalActionStatusForBuyerApproval(uploadedFile),
              requirementFileId,
              uploadedFile
            })
          }),
          ...insertIf(allowDisapprove, {
            text: t('Disapprove'),
            onClickHandler: handleShowModal(requirementFileId, uploadedFile)
          })
        ]
      } else {
        return [
          ...insertIf(fileStatus !== 'approved', {
            text: t('Approve'),
            onClickHandler: handleChangeStatus({
              status: 'approved',
              requirementFileId,
              uploadedFile
            })
          }),
          ...insertIf(fileStatus !== 'disapproved', {
            text: t('Disapprove'),
            onClickHandler: handleShowModal(requirementFileId, uploadedFile)
          })
        ]
      }
    },
    [t, handleChangeStatus, handleShowModal, isControllerBuyerApproval]
  )

  return useCallback(
    ({ requirementFileId, mediaId, uploadedFile }) => {
      if (uploadedFile) {
        return [
          ...insertIf(uploadedFile.approval_status !== APPROVED, {
            text: t('Replace file'),
            onClickHandler: handleDelete({ requirementFileId, uploadedFile })
          }),
          {
            text: t('Upload another file'),
            onClickHandler: openUploadFileForm(requirementFileId, mediaId)
          },
          {
            text: t('Download file'),
            onClickHandler: handleFileDownload(uploadedFile)
          },
          ...insertIf(hasApprovePermissions, ...getApprovalOptions({ uploadedFile, requirementFileId }))
        ]
      } else {
        return [
          {
            text: t('Upload'),
            onClickHandler: openUploadFileForm(requirementFileId, mediaId)
          }
        ]
      }
    },
    [t, handleDelete, openUploadFileForm, handleFileDownload, hasApprovePermissions, getApprovalOptions]
  )
}
