import { createStyles } from '../../../../styles/helpers'

import IconSearch from '../../../../assets/icons/search.svg'

import { darkGrey } from '../../../../styles/const/colors'
import { inputHeight } from '../../../../styles/common/components'

const useStyles = createStyles({
  filtersSearch: {
    width: 175,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: inputHeight,
    '&:before': {
      content: '""',
      width: 14,
      height: 14,
      position: 'absolute',
      zIndex: 1,
      top: 'calc(50% - 7px)',
      left: 12,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url("${IconSearch}")`
    },
    '& input': {
      paddingLeft: 32,
      paddingRight: 30,
      lineHeight: 0.9
    }
  },
  clear: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    right: '0',
    width: '30px',
    height: '30px',
    transform: 'translate(0, -50%)',
    cursor: 'pointer',

    '& svg': {
      margin: 'auto',
      fontSize: '15px',

      '& path': {
        fill: darkGrey
      }
    }
  },
  clearActive: {
    display: 'flex'
  }
})

export default useStyles
