import { createStyles } from '../../../styles/helpers'

import { fileNameMargin } from '../styles'
import { darkGrey, grey } from '../../../styles/const/colors'
import { textEllipsisMultiline } from '../../../styles/mixins/text'

const previewSize = 24
const useStyles = createStyles({
  preview: {
    width: previewSize,
    height: previewSize,
    objectFit: 'contain',
    marginRight: fileNameMargin,
    borderRadius: 2
  },
  icon: {
    fontSize: previewSize,
    marginRight: fileNameMargin,
    color: ({ iconColor }) => iconColor
  },
  details: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center'
  },
  fileName: {
    fontSize: [12, '!important'],
    wordBreak: 'break-word',
    marginTop: '0 !important',
    marginBottom: '0 !important',
    color: darkGrey,
    ...textEllipsisMultiline(1)
  },
  description: {
    extend: 'fileName',
    fontSize: 14,
    color: grey
  }
})
export default useStyles
