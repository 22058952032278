import { useLoadPaginatedList } from '../../features/hooks/useLoadPaginatedList'
import { useSelector } from 'react-redux'

import { clearMediaOrders, getMediaOrders } from '../../modules/actions/mediaOrders'
import { mediaOrdersNextSelector } from '../../modules/selectors/mediaOrders'

const defaultParams = {}

export const useLoadPaginatedMediaOrders = (params = defaultParams) => {
  const next = useSelector(mediaOrdersNextSelector)

  return useLoadPaginatedList({
    params,
    action: getMediaOrders,
    clearAction: clearMediaOrders,
    next
  })
}
