import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

export const isInitialAuthCheckingSelector = createSelector(
  ({ cognito }) => cognito.initialAuthChecking,
  initialAuthChecking => initialAuthChecking
)

export const isAuthorizedSelector = createSelector(
  ({ cognito }) => cognito.isAuthorized,
  isAuthorized => isAuthorized
)

export const cognitoUserSelector = createSelector(
  ({ cognito }) => cognito.user,
  user => user
)

export const cognitoNextStepDataSelector = createSelector(
  ({ cognito }) => cognito.nextStep,
  nextStep => nextStep
)

export const loginErrorSelector = createSelector(
  ({ cognito }) => cognito.error,
  cognito => cognito
)

export const cognitoLoadingSelector = createSelector(
  ({ cognito }) => cognito.isLoading,
  isLoading => isLoading
)

export const isLoadingSelector = createSelector(
  ({ cognito }) => cognito.isLoading,
  isLoading => isLoading
)

export const createPasswordSelector = createSelector(
  ({ cognito }) => cognito.createPassword,
  createPassword => createPassword
)

export const createPasswordErrorSelector = createSelector(
  ({ cognito }) => cognito.createPasswordError,
  createPasswordError => createPasswordError
)

export const resetPasswordSelector = createSelector(
  ({ cognito }) => cognito.resetPassword,
  resetPassword => resetPassword
)

export const resetPasswordErrorSelector = createSelector(
  ({ cognito }) => cognito.resetPasswordError,
  resetPasswordError => resetPasswordError
)

// mfa related selectors
export const loginMFAWasRequestedSelector = createSelector(
  ({ cognito }) => cognito.mfaWasRequested,
  mfaWasRequested => mfaWasRequested
)

export const loginMFATypeSelector = createSelector(
  ({ cognito }) => cognito.mfaType,
  mfaType => mfaType
)

// Submit MFA type
export const submitMfaTypeIsLoadingSelector = createSelector(
  ({ cognito }) => cognito.mfaTypeSubmit.isLoading,
  isLoading => isLoading
)

export const submitMfaTypeWasAcceptedSelector = createSelector(
  ({ cognito }) => cognito.mfaTypeSubmit.wasAccepted,
  wasAccepted => wasAccepted
)

export const submitMfaTypeErrorSelector = createSelector(
  ({ cognito }) => cognito.mfaTypeSubmit.error,
  error => error
)

// Submit MFA code

export const submitMFACodeIsLoadingSelector = createSelector(
  ({ cognito }) => cognito.mfaCodeSubmit.isLoading,
  isLoading => isLoading
)

export const submitMFACodeWasAcceptedSelector = createSelector(
  ({ cognito }) => cognito.mfaCodeSubmit.wasAccepted,
  wasAccepted => wasAccepted
)

export const submitMFACodeErrorSelector = createSelector(
  ({ cognito }) => cognito.mfaCodeSubmit.error,
  error => error
)

// Get TOTP token
export const getTOTPTokenSelector = createSelector(
  ({ cognito }) => cognito.getTOTPToken.token,
  token => token
)

export const getTOTPTokenIsLoadingSelector = createSelector(
  ({ cognito }) => cognito.getTOTPToken.isLoading,
  isLoading => isLoading
)

export const getTOTPTokenErrorSelector = createSelector(
  ({ cognito }) => cognito.getTOTPToken.error,
  error => error
)

export const getTOTPTokenWasLoadedSelector = createSelector(
  ({ cognito }) => cognito.getTOTPToken.wasLoaded,
  wasLoaded => wasLoaded
)

// Verify TOTP token
export const verifyTOTPTokenIsLoadingSelector = createSelector(
  ({ cognito }) => cognito.verifyTOTPToken.isLoading,
  isLoading => isLoading
)

export const verifyTOTPTokenErrorSelector = createSelector(
  ({ cognito }) => cognito.verifyTOTPToken.error,
  error => error
)

export const verifyTOTPTokenWasCreatedSelector = createSelector(
  ({ cognito }) => cognito.verifyTOTPToken.wasCreated,
  wasCreated => wasCreated
)
