import { createStyles } from '../../../styles/helpers'

const useProductCardsStyles = createStyles({
  mobileTableHeader: {
    minHeight: 22
  },
  actionsStyles: {
    paddingTop: 0
  }
})

export default useProductCardsStyles
