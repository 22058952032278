import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import AdFacebookCreate from '../../../../forms/Facebook/AdForms/AdFacebookCreate'
import AdGoogleCreate from '../../../../forms/Google/AdForms/GoogleDisplayAd/AdGoogleCreate'
import AdTikTokCreate from '../../../../forms/Tiktok/AdForms/AdTikTokCreate'

import { selectedPlatformSelector } from '../../../../modules/selectors/app'
import { campaignSelector } from '../../../../modules/selectors/campaigns'
import { lineItemWasLoadedSelector } from '../../../../modules/selectors/lineItems'

import { FACEBOOK_PLATFORM, GOOGLE_PLATFORM, TIKTOK_PLATFORM } from '../../../../constants/selectLists/platformList'

const AdCreateFormComponent = () => {
  const selectedPlatform = useSelector(selectedPlatformSelector)
  const campaign = useSelector(campaignSelector)
  const lineItemWasLoaded = useSelector(lineItemWasLoadedSelector)

  const { line_item_id: lineItemId, campaign_id: campaignId } = useParams()

  const { objective: campaignObjective } = campaign

  switch (selectedPlatform) {
    case FACEBOOK_PLATFORM:
      return <AdFacebookCreate campaignObjective={campaignObjective} lineItemWasLoaded={lineItemWasLoaded} />
    case GOOGLE_PLATFORM:
      return <AdGoogleCreate campaignId={campaignId} lineItemId={lineItemId} />
    case TIKTOK_PLATFORM:
      return <AdTikTokCreate />
    default:
      return null
  }
}

export default AdCreateFormComponent
