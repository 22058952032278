import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import AdCardCarouselItem from './AdCardCarouselItem'

import { setAdCarouselSelectedIndex } from '../../../../../modules/actions/ads'
import { adCarouselSelectedIndexesSelector } from '../../../../../modules/selectors/ads'

import { AD_PREVIEW_CAROUSEL_INDEX_KEY, CAROUSEL_ITEMS } from '../../../../../forms/Facebook/AdForms/fields'

import useStyles from './styles'

function AdCardCarouselContent({ viewAdOnFacebook, formikValues, elementInProgress, showSkeletonPlaceholders }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const selectedCarouselIndexes = useSelector(adCarouselSelectedIndexesSelector)

  const carouselItems = formikValues[CAROUSEL_ITEMS]
  const adPreviewCarouselIndexKey = formikValues[AD_PREVIEW_CAROUSEL_INDEX_KEY]
  const lastItemIndex = carouselItems.length - 1

  const selectedCarouselItemIndex = selectedCarouselIndexes[adPreviewCarouselIndexKey] || 0

  const isCarouselInProgress = elementInProgress === CAROUSEL_ITEMS

  const handleCarouselItemClick = useCallback(
    ({ index }) => {
      dispatch(setAdCarouselSelectedIndex(adPreviewCarouselIndexKey, index))
    },
    [dispatch, adPreviewCarouselIndexKey]
  )

  return (
    <div className={classes.carousel}>
      {!!carouselItems.length &&
        carouselItems.map((item, index) => (
          <AdCardCarouselItem
            viewAdOnFacebook={viewAdOnFacebook}
            key={index}
            formikValues={formikValues}
            selectedCarouselItemIndex={selectedCarouselItemIndex}
            lastItemIndex={lastItemIndex}
            item={item}
            isActive={selectedCarouselItemIndex === index && isCarouselInProgress}
            elementInProgress={elementInProgress}
            handleItemClick={handleCarouselItemClick}
            index={index}
            showSkeletonPlaceholders={showSkeletonPlaceholders}
          />
        ))}
      {
        // carousel should have at least 2 items, when no 2nd item - show AdCardCarouselItem as placeholder
        carouselItems.length === 1 && <AdCardCarouselItem />
      }
    </div>
  )
}

AdCardCarouselContent.propTypes = {
  viewAdOnFacebook: PropTypes.string,
  formikValues: PropTypes.object,
  elementInProgress: PropTypes.string,
  showSkeletonPlaceholders: PropTypes.bool
}

export default AdCardCarouselContent
