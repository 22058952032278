import { useCallback, useMemo, useState } from 'react'

export default function useStepFormRoutes({ initialRoute }) {
  // StepFormRoutes are adding additional step component - FormRouteSelector on the beginning of each the route steps
  // FormRouteSelector allows to select the Route
  const [selectedRoute, setSelectedRoute] = useState(initialRoute)
  // when selectedRoute is changed - new routes with steps are rendered to the DOM
  // the first step will be the FormRouteSelector again, so it should trigger showing next step
  // routeWasSelected allows to trigger next step or avoid jumping into next step on form opening
  const [routeWasSelected, setRouteWasSelected] = useState(false)

  const handleSelectRoute = useCallback(route => {
    setSelectedRoute(route)
    // this allows to make step change from FormRouteSelector when selectedRoute was changed
    setRouteWasSelected(true)
  }, [])

  const handleFormClose = useCallback(() => {
    // avoid FormRouteSelector step change on opening form after closing
    setRouteWasSelected(false)
  }, [])

  return useMemo(
    () => ({
      selectedRoute,
      routeWasSelected,
      setSelectedRoute,
      handleSelectRoute,
      handleFormClose
    }),
    [selectedRoute, routeWasSelected, setSelectedRoute, handleSelectRoute, handleFormClose]
  )
}
