import * as Yup from 'yup'

import {
  AD_LINK,
  DESCRIPTIONS_LIST,
  FILE_URL,
  HEADLINES_LIST,
  IS_CUSTOMIZED_MEDIA,
  PRIMARY_TEXT_LIST,
  SINGLE_MEDIA_ITEM,
  STORIES_ITEMS
} from '../../../../fields'
import nameValidation from '../../../../../../../features/validations/name'
import { urlValidation } from '../../../../../../../features/validations/other'
import { productOrPagesValidation } from '../../../../../../ReusableFormFields/ProductsAndPagesFields/fields'
import { descriptionValidation, headlineValidation, primaryTextValidation, storiesItemShape } from '../../validation'

export const getValidationSchema = ({ showProductSelectionStep = false }) => {
  return Yup.object({
    ...nameValidation,
    [PRIMARY_TEXT_LIST]: primaryTextValidation,
    [SINGLE_MEDIA_ITEM]: Yup.object().shape({
      [FILE_URL]: Yup.string().required('Please select Image or Video file'),
      [AD_LINK]: urlValidation,
      [HEADLINES_LIST]: Yup.array().of(Yup.object({ text: headlineValidation })),
      [DESCRIPTIONS_LIST]: Yup.array().of(Yup.object({ text: descriptionValidation }))
    }),
    [STORIES_ITEMS]: Yup.array().when(IS_CUSTOMIZED_MEDIA, {
      is: true,
      then: () => Yup.array().of(storiesItemShape)
    }),
    // CATALOGUE PRODUCT
    ...(showProductSelectionStep && productOrPagesValidation)
  })
}
