import React from 'react'
import { useSelector } from 'react-redux'

import SkeletonReportingContent from './Skeleton'
import MediaProductsRow from './MediaProductsRow'
import DateRangePanel from '../../../../../../Settings/MediaProducts/ProductInventory/DateRangePanel'

import {
  utilisationReportSelector,
  getUtilisationReportWasLoadedSelector
} from '../../../../../../../modules/selectors/mediaOrdersProducts'
import { graphDatesListExcludedAll } from '../../../../../../../constants/selectLists/graphDatesList'

import useStyles from './styles'

const ReportingContent = ({ selectedDateRange, setSelectedDateRange }) => {
  const classes = useStyles()

  const utilisationReportData = useSelector(utilisationReportSelector)
  const utilisationReportWasLoaded = useSelector(getUtilisationReportWasLoadedSelector)

  if (!utilisationReportWasLoaded) {
    // initial data loading: show 6 skeletons
    return <SkeletonReportingContent />
  }

  const hasData = utilisationReportData.length > 0

  return (
    <section>
      {hasData && (
        <div className={classes.filters}>
          <DateRangePanel
            dateRangeList={graphDatesListExcludedAll}
            selectedDateRange={selectedDateRange}
            onSelectDateRange={newDateRange => {
              setSelectedDateRange(newDateRange)
            }}
          />
        </div>
      )}
      <div className={classes.graphsGrid}>
        {utilisationReportData.map(({ breakdown, data }) => {
          return <MediaProductsRow key={breakdown} rawData={data} title={breakdown} />
        })}
      </div>
    </section>
  )
}

export default ReportingContent
