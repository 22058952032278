import React, { memo } from 'react'
import classnames from 'classnames'
import { useTheme } from 'react-jss'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { format, addYears } from 'date-fns'

import Tooltip from '../../../../../../../../../components/Tooltip'
import ProgressBar from '../../../../../../../../../components/ProgressBar'

import { formatCurrency } from '../../../../../../../../../helpers/numbers'
import { selectedControllerCurrencySymbolSelector } from '../../../../../../../../../modules/selectors/app'

import { green } from '../../../../../../../../../styles/const/colors'
import useStyles from './styles'

const RevenueTooltip = memo(({ startDate, totalRevenue, target, previousYear }) => {
  const classes = useStyles()
  const theme = useTheme()

  const controllerCurrencySymbol = useSelector(selectedControllerCurrencySymbolSelector)

  const isTargetAchieved = target && totalRevenue > target

  return (
    <Tooltip
      placement="top-start"
      className={classes.tooltip}
      triggerClassName={classes.tooltipTrigger}
      portaled={true}
      borderColor={isTargetAchieved ? green : theme.brandPrimary}
      triggerElement={
        <div className={classnames('js-event-bar-placeholder', classes.barPlaceholder)}>
          <div>{formatCurrency(totalRevenue, { min: 0, max: 0 }, { symbol: controllerCurrencySymbol })}</div>
          {Boolean(target) && (
            <ProgressBar
              className={classes.revenueProgress}
              value={totalRevenue}
              max={target}
              barColor={isTargetAchieved ? green : undefined}
            />
          )}
        </div>
      }
    >
      <div className={classes.root}>
        <h2 className={classes.title}>
          Total Revenue: {formatCurrency(totalRevenue, { min: 2, max: 2 }, { symbol: controllerCurrencySymbol })}
        </h2>
        {Boolean(target) && (
          <div>Target: {formatCurrency(target, { min: 2, max: 2 }, { symbol: controllerCurrencySymbol })}</div>
        )}
        {Boolean(previousYear) && (
          <div>
            {format(addYears(new Date(startDate), -1), 'yyyy')} Total:{' '}
            {formatCurrency(previousYear, { min: 2, max: 2 }, { symbol: controllerCurrencySymbol })}
          </div>
        )}
      </div>
    </Tooltip>
  )
})

RevenueTooltip.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  totalRevenue: PropTypes.number,
  target: PropTypes.number,
  previousYear: PropTypes.number
}

export default RevenueTooltip
