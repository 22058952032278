import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

export const campaignsReportsSelector = createSelector(
  ({ campaignsSummary }) => campaignsSummary.reportsData,
  reportsData => reportsData
)

export const campaignsReportsLoadingSelector = createSelector(
  ({ campaignsSummary }) => campaignsSummary.reportsLoading,
  reportsLoading => reportsLoading
)

export const campaignsSummaryFilterSelector = createSelector(
  ({ campaignsSummary }) => campaignsSummary.filter,
  filter => filter
)

export const campaignsSummaryDateRangeSelector = createSelector(
  ({ campaignsSummary }) => campaignsSummary.dateRange,
  dateRange => dateRange
)

export const campaignsSummarySortSelector = createSelector(
  ({ campaignsSummary }) => campaignsSummary.sort,
  sort => sort
)

export const selectedLineItemsSelector = createSelector(
  ({ campaignsSummary }) => campaignsSummary.selectedLineItems,
  selectedLineItems => selectedLineItems
)
