import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import SelectMultiLevel from '../../../../components/SelectMultiLevel'

import { clearTikTokRegions, getTikTokRegions } from '../../../../modules/actions/tikTokConstants'
import { campaignSelector } from '../../../../modules/selectors/campaigns'
import { tikTokRegionsIsLoadingSelector, tikTokRegionsSelector } from '../../../../modules/selectors/tikTokConstants'

import { selectedAdAccountIdSelector } from '../../../../modules/selectors/app'

const TiktokGeolocationRegion = ({ name, value, setFieldValue, adAccountId: providedAdAccountId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const campaign = useSelector(campaignSelector)
  const tikTokRegions = useSelector(tikTokRegionsSelector)
  const tikTokRegionsIsLoading = useSelector(tikTokRegionsIsLoadingSelector)

  const { objective: campaignObjective } = campaign

  const formattedRegionOptions = useMemo(
    () =>
      tikTokRegions.map(region => ({
        ...region,
        parent: region['parent_id']
      })),
    [tikTokRegions]
  )

  const flatToNestedRegionsSortFunction = useCallback((a, b) => a.name.localeCompare(b.name), [])

  const adAccountId = providedAdAccountId || selectedAdAccountId

  useEffect(() => {
    dispatch(
      getTikTokRegions({
        objective_type: campaignObjective,
        account: adAccountId,
        placement: ['PLACEMENT_TIKTOK']
      })
    )
  }, [dispatch, campaignObjective, adAccountId])

  useEffect(
    () => () => {
      dispatch(clearTikTokRegions())
    },
    [dispatch]
  )

  return (
    <SelectMultiLevel
      placeholder={t('Add a region')}
      setFieldValue={setFieldValue}
      value={value}
      name={name}
      options={formattedRegionOptions}
      isLoading={tikTokRegionsIsLoading}
      flatToNestedArrSortFunction={flatToNestedRegionsSortFunction}
    />
  )
}

TiktokGeolocationRegion.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  setFieldValue: PropTypes.func.isRequired,
  adAccountId: PropTypes.string
}

export default TiktokGeolocationRegion
