import {
  AD_DESCRIPTION,
  AD_DISPLAY_URL,
  AD_LINK,
  HEADLINES_LIST,
  initialFileValues,
  initialLink,
  initialMediaValues,
  MEDIA_FILE_OPTION
} from '../../../fields'
import { MEDIA_FILE_IMAGE_USE_LIBRARY } from '../../../../../ReusableFormFields/AdFileUpload/fields'
import { CALL_TO_ACTION, LEARN_MORE } from '../../../../../ReusableFormFields/CallToActionFields/fields'

export const singleMediaItemInitialValues = {
  [MEDIA_FILE_OPTION]: MEDIA_FILE_IMAGE_USE_LIBRARY,
  ...initialFileValues,
  ...initialMediaValues,
  [AD_LINK]: initialLink,
  [AD_DISPLAY_URL]: '',
  [HEADLINES_LIST]: [{ text: '' }],
  [AD_DESCRIPTION]: [{ text: '' }],
  [CALL_TO_ACTION]: LEARN_MORE
}
