import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import DrawerHeadline from '../../../../../components/Drawer/DrawerHeadline'
import Form from '../../../../../components/Form'
import CampaignFormContent from './CampaignFormContent'
import RoutesMissingWarning from '../../../../Multiplatform/RoutesMissingWarning'

import useAccessibleCampaignsRoutes from '../../../../Multiplatform/RoutesMissingWarning/useAccessibleCampaignsRoutes'
import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'
import { useMediaOrderRemainingBudgetCalculation } from '../../../../Multiplatform/MediaOrderForms/UploadAdCreative/useMediaOrderRemainingBudgetCalculation'
import useCampaignFormSuccessSubmit from '../../../../Multiplatform/MediaOrderForms/UploadAdCreative/useCampaignFormSuccessSubmit'
import { useInitialCampaignOption } from '../../useInitialCampaignOption'

import { formatOptionsList } from '../../../../../features/formatters'

import { setMediaOrderUploadCreative } from '../../../../../modules/actions/mediaOrders'
import { clearCampaigns, clearCreateCampaign, createCampaign } from '../../../../../modules/actions/campaigns'
import { mediaOrderSelector } from '../../../../../modules/selectors/mediaOrders'
import { combinedCampaignsSelector } from '../../../../../modules/selectors/combinedData'
import { selectedAdAccountsSelector, selectedControllerDataSelector } from '../../../../../modules/selectors/app'
import {
  campaignCreateErrorSelector,
  campaignCreateIsLoadingSelector
} from '../../../../../modules/selectors/campaigns'

import { FACEBOOK_PLATFORM, FACEBOOK_PROVIDER_PLATFORM } from '../../../../../constants/selectLists/platformList'
import {
  CAMPAIGN,
  CAMPAIGN_OPTION,
  CAMPAIGN_OPTION_ADVANTAGE_SHOPPING,
  CAMPAIGN_OPTION_PRODUCT_RETARGETING,
  CAMPAIGN_OPTION_EXISTING_CAMPAIGN,
  CAMPAIGN_OPTION_ADVANCED_SETUP,
  CAMPAIGN_OPTION_AUDIENCE_TARGETING,
  getInitialValues,
  getValidationSchema,
  transformValuesToAdvancedCampaignBE,
  transformValuesToRetargetingCampaignBE,
  transformValuesToAudienceTargetingCampaignBE,
  transformValuesToAdvantageShoppingCampaignBE,
  CAMPAIGN_OPTION_PROXIMITY_CAMPAIGN,
  transformValuesToProximityCampaignBE
} from './fields'

import { ADVANCED_SETUP_ROUTE, ADVANTAGE_SHOPPING_ROUTE, getFacebookCampaignRoute } from '../../fields'

import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

const CampaignForm = ({ onSuccessSubmit, setIsExistingCampaignFlow, onCampaignOptionSelect }) => {
  const drawerFormClasses = useDrawerFormStyles()
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [manualSuccessSubmit, setManualSuccessSubmit] = useState(false)

  const mediaOrder = useSelector(mediaOrderSelector)
  const combinedCampaigns = useSelector(combinedCampaignsSelector)
  const controller = useSelector(selectedControllerDataSelector)
  const { [FACEBOOK_PLATFORM]: adAccountData } = useSelector(selectedAdAccountsSelector)
  const { currency_symbol: currencySymbol, id: adAccountId } = adAccountData

  const { campaigns = [] } = combinedCampaigns

  const remainingBudget = useMediaOrderRemainingBudgetCalculation()

  const facebookCampaigns = useMemo(
    () =>
      campaigns.filter(campaign => campaign.provider === FACEBOOK_PROVIDER_PLATFORM && campaign.status !== 'deleted'),
    [campaigns]
  )

  const formattedCampaigns = formatOptionsList({
    list: facebookCampaigns,
    valueName: 'id',
    labelName: 'name'
  })

  const isCampaignsExist = !!formattedCampaigns?.length

  const allowedRoute = useAccessibleCampaignsRoutes(FACEBOOK_PLATFORM)
  const hasAccessibleRoutes = Object.values(allowedRoute).some(route => route)
  const hasRoutes = isCampaignsExist || hasAccessibleRoutes

  const onSubmit = useCallback(
    values => {
      if (values[CAMPAIGN_OPTION] === CAMPAIGN_OPTION_EXISTING_CAMPAIGN) {
        setManualSuccessSubmit(true)
      } else {
        let campaignData = {}

        if (values[CAMPAIGN_OPTION] === CAMPAIGN_OPTION_ADVANTAGE_SHOPPING) {
          campaignData = transformValuesToAdvantageShoppingCampaignBE(adAccountId)
        } else if (values[CAMPAIGN_OPTION] === CAMPAIGN_OPTION_PRODUCT_RETARGETING) {
          campaignData = transformValuesToRetargetingCampaignBE(values, adAccountId)
        } else if (values[CAMPAIGN_OPTION] === CAMPAIGN_OPTION_PROXIMITY_CAMPAIGN) {
          campaignData = transformValuesToProximityCampaignBE(values, adAccountId)
        } else if (values[CAMPAIGN_OPTION] === CAMPAIGN_OPTION_AUDIENCE_TARGETING) {
          campaignData = transformValuesToAudienceTargetingCampaignBE(values, adAccountId, mediaOrder)
        } else if (values[CAMPAIGN_OPTION] === CAMPAIGN_OPTION_ADVANCED_SETUP) {
          campaignData = transformValuesToAdvancedCampaignBE(values, adAccountId, mediaOrder)
        }

        dispatch(
          createCampaign({ campaignData }, FACEBOOK_PLATFORM, {
            pushToCombinedCampaignsList: true
          })
        )
      }
    },
    [dispatch, adAccountId, mediaOrder]
  )

  const initialCampaignOption = useInitialCampaignOption(allowedRoute)
  const initialValues = useMemo(() => getInitialValues(initialCampaignOption), [initialCampaignOption])

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema({ remainingBudget, currencySymbol }),
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const { values } = purifiedFormik

  const { [CAMPAIGN_OPTION]: campaignOption } = values

  const successSubmit = useCampaignFormSuccessSubmit({ campaignOption, platform: FACEBOOK_PLATFORM, adAccountId })

  const onSuccessCampaignFormSubmitHandler = useCallback(() => {
    let selectedRoute = getFacebookCampaignRoute(campaignOption)

    if (campaignOption === CAMPAIGN_OPTION_EXISTING_CAMPAIGN) {
      const selectedCampaignFullData = formattedCampaigns.find(campaign => values[CAMPAIGN] === campaign.id)

      // if selected campaign is advantage shopping one, use ADVANTAGE_SHOPPING_ROUTE
      if (selectedCampaignFullData['smart_promotion_type'] === 'AUTOMATED_SHOPPING_ADS') {
        selectedRoute = ADVANTAGE_SHOPPING_ROUTE
      } else {
        selectedRoute = ADVANCED_SETUP_ROUTE
      }

      setIsExistingCampaignFlow(true)
      dispatch(setMediaOrderUploadCreative({ campaign: selectedCampaignFullData }))
    }

    onSuccessSubmit(selectedRoute)
  }, [dispatch, campaignOption, formattedCampaigns, onSuccessSubmit, setIsExistingCampaignFlow, values])

  const clearHandler = useCallback(() => {
    dispatch(clearCampaigns())
    dispatch(clearCreateCampaign())
  }, [dispatch])

  // We need to trigger onCampaignRouteSelect on each RadioBlock change to represent correct corresponding FormProgress items
  useEffect(() => {
    onCampaignOptionSelect && onCampaignOptionSelect(campaignOption)
  }, [onCampaignOptionSelect, campaignOption])

  return hasRoutes ? (
    <div className={drawerFormClasses.formContainer}>
      <DrawerHeadline
        activeStepNumber={0}
        customStepsLength={1}
        description={t('learnMoreAboutCampaigns', { controllerName: controller?.name })}
        title="Choose your Meta campaign type"
      />

      <Form
        formName="campaignFormContent"
        formik={purifiedFormik}
        submitText="Save and continue"
        successSubmit={successSubmit || manualSuccessSubmit}
        onSuccessSubmit={onSuccessCampaignFormSubmitHandler}
        errorSelector={campaignCreateErrorSelector}
        isLoadingSelector={campaignCreateIsLoadingSelector}
        clearHandler={clearHandler}
      >
        <CampaignFormContent
          formik={purifiedFormik}
          formattedCampaigns={formattedCampaigns}
          remainingBudget={remainingBudget}
          facebookCampaigns={facebookCampaigns}
        />
      </Form>
    </div>
  ) : (
    <RoutesMissingWarning />
  )
}

CampaignForm.propTypes = {
  onSuccessSubmit: PropTypes.func.isRequired,
  setIsExistingCampaignFlow: PropTypes.func.isRequired,
  onCampaignOptionSelect: PropTypes.func,
  routeWasSelected: PropTypes.bool
}

export default CampaignForm
