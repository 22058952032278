import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { mediaOrderUploadCreativeDataSelector } from '../../../../../../modules/selectors/mediaOrders'
import { campaignSelector } from '../../../../../../modules/selectors/campaigns'

// there can be two cases of ad create form: 1) simple usage on line item summary page
// 2) usage inside multi-form on media orders page
// in the second case, we store newly created campaign inside separate redux store
export default function useCampaignDetails() {
  const { campaign: uploadCreativeCampaignData } = useSelector(mediaOrderUploadCreativeDataSelector)
  const { id: campaignId } = useSelector(campaignSelector)

  const { id: uploadCreativeCampaignId } = uploadCreativeCampaignData

  // if has data from media order upload creative data selector, use its campaign data
  const isMediaOrderUploadCreative = !!uploadCreativeCampaignId

  const getRegularCampaignData = useCallback(() => {
    return {
      campaignId
    }
  }, [campaignId])

  const getUploadCreativeCampaignData = useCallback(() => {
    return {
      campaignId: uploadCreativeCampaignId
    }
  }, [uploadCreativeCampaignId])

  return useMemo(
    () => (isMediaOrderUploadCreative ? getUploadCreativeCampaignData() : getRegularCampaignData()),
    [isMediaOrderUploadCreative, getUploadCreativeCampaignData, getRegularCampaignData]
  )
}
