import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import useManageEditFormData from '../../../../../hooks/formHooks/useManageEditFormData'

import LocationListEditForm from './LocationListEditForm'
import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'

import { getLocationList, clearLocationList } from '../../../../../modules/actions/location'
import { locationListLoadingSelector, locationListSelector } from '../../../../../modules/selectors/location'

import { LOCATION_LIST_EDIT } from '../../../../../constants/forms'

const LocationListEdit = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const locationList = useSelector(locationListSelector)
  const locationListLoading = useSelector(locationListLoadingSelector)

  const getLocationListDataHandler = useCallback(
    selectedEditItemId => {
      dispatch(getLocationList(selectedEditItemId))
    },
    [dispatch]
  )

  const clearLocationListDataHandler = useCallback(() => {
    dispatch(clearLocationList())
  }, [dispatch])

  useManageEditFormData({
    formName: LOCATION_LIST_EDIT,
    loadedDataId: locationList.id,
    getDataHandler: getLocationListDataHandler,
    clearDataHandler: clearLocationListDataHandler
  })

  return (
    <FormDrawerWrapper
      formName={LOCATION_LIST_EDIT}
      title={t('Edit location targeting list')}
      subTitle={t('Location targeting lists')}
      showOpenButton={false}
      isFormLoading={locationListLoading}
    >
      <LocationListEditForm />
    </FormDrawerWrapper>
  )
}

export default LocationListEdit
