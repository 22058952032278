import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import InventoryGraph from '../../../../../features/components/InventoryGraph'
import SkeletonInventoryGraph from '../../../../../features/components/InventoryGraph/Skeleton'
import { useInventoryDatesSelector } from '../../../../../features/components/InventoryGraph/useInventoryDatesSelector'

import { clearGetProductGraph, getProductGraph } from '../../../../../modules/actions/mediaOrdersProducts'
import {
  getProductGraphIsLoadingSelector,
  getProductGraphSelector,
  graphDatesPeriodSelector,
  updateBatchInventoryWasUpdatedSelector
} from '../../../../../modules/selectors/mediaOrdersProducts'

import { getDatesFromGraphDateOption } from '../../../../../constants/selectLists/graphDatesList'

const ProductInventoryGraph = ({ productId }) => {
  const dispatch = useDispatch()

  const productGraph = useSelector(getProductGraphSelector)
  const graphDatesPeriod = useSelector(graphDatesPeriodSelector)
  const productGraphIsLoading = useSelector(getProductGraphIsLoadingSelector)
  const batchInventoryWasUpdated = useSelector(updateBatchInventoryWasUpdatedSelector)

  const { DatesSelector, sortedInventoryLimitedByDateRange, formattedLabels } = useInventoryDatesSelector({
    rawData: productGraph
  })

  useEffect(() => {
    // re-fetch graph data if inventory was updated
    if (batchInventoryWasUpdated) {
      const selectedPeriodDates = getDatesFromGraphDateOption(graphDatesPeriod)
      dispatch(getProductGraph(productId, selectedPeriodDates))
    }
  }, [dispatch, productId, batchInventoryWasUpdated, graphDatesPeriod])

  useEffect(() => {
    // always fetch data according to selected graph date range period
    const selectedPeriodDates = getDatesFromGraphDateOption(graphDatesPeriod)
    dispatch(getProductGraph(productId, selectedPeriodDates))
  }, [dispatch, productId, graphDatesPeriod])

  useEffect(() => {
    return () => {
      dispatch(clearGetProductGraph())
    }
  }, [dispatch])

  if (productGraphIsLoading) {
    return <SkeletonInventoryGraph />
  }

  if (!productGraph.length) {
    return null
  }

  return (
    <InventoryGraph
      DatesSelector={DatesSelector}
      sortedInventoryLimitedByDateRange={sortedInventoryLimitedByDateRange}
      formattedLabels={formattedLabels}
    />
  )
}

export default ProductInventoryGraph
