import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import NameForm from '../../../../../ReusableFormSections/NameSection/NameSectionForm'

import useUpdateTikTokAdHandler from '../../hooks'

import { clearUpdateAd } from '../../../../../../modules/actions/ads'

import {
  adUpdateIsLoadingSelector,
  adUpdateErrorSelector,
  adWasUpdatedSelector
} from '../../../../../../modules/selectors/ads'

const NameSectionForm = ({ editItemData: adData, ...formProps }) => {
  const dispatch = useDispatch()
  const adWasUpdated = useSelector(adWasUpdatedSelector)

  const handleUpdateSubmit = useUpdateTikTokAdHandler({ adData })
  const handleSubmit = useCallback(
    values => {
      handleUpdateSubmit(values)
    },
    [handleUpdateSubmit]
  )

  const handleClearUpdateAd = useCallback(() => {
    dispatch(clearUpdateAd())
  }, [dispatch])

  return (
    <NameForm
      placeholder="Ad Name"
      editItem={adData}
      handleSubmit={handleSubmit}
      clearEditItem={handleClearUpdateAd}
      successSubmit={adWasUpdated}
      isLoadingSelector={adUpdateIsLoadingSelector}
      errorSelector={adUpdateErrorSelector}
      {...formProps}
    />
  )
}

export default NameSectionForm
