import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'

import StepForm from '../../../../../features/components/Forms/StepForm'
import UploadFileStep from './Steps/UploadFileStep'
import PeriodStep from './Steps/PeriodStep'

import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'

import { useProgressItems } from './useProgressItems'
import useAvailableProducts from './useAvailableProducts'

import { uploadBookingMediaFile, clearUploadBookingMediaFile } from '../../../../../modules/actions/mediaOrdersBookings'
import {
  uploadBookingMediaFileIsLoadingSelector,
  uploadBookingMediaFileErrorSelector,
  uploadBookingMediaFileWasCreatedSelector
} from '../../../../../modules/selectors/mediaOrdersBookings'

import { UPLOAD_BOOKED_MEDIA_FILES } from '../../../../../constants/forms'

import { transformValuesToBE } from './formatters'
import { validationSchema } from './validation'
import { getInitialValues } from './fields'

function UploadBookedMediaFilesForm({ selectedFileRequirements, selectedFileMediaData, selectedFileId }) {
  const dispatch = useDispatch()
  const progressItems = useProgressItems()

  const fileWasUploaded = useSelector(uploadBookingMediaFileWasCreatedSelector)

  const selectedBookedMediaId = selectedFileMediaData?.id

  const availableProducts = useAvailableProducts(selectedFileRequirements)
  const allAvailableMediaToUpload = useMemo(() => {
    return availableProducts.reduce((acc, product) => {
      return acc.concat(product.bookedMedia)
    }, [])
  }, [availableProducts])

  const onSubmit = useCallback(
    values => {
      const formattedData = transformValuesToBE({ values })

      dispatch(uploadBookingMediaFile(formattedData))
    },
    [dispatch]
  )

  const initialValues = useMemo(
    () =>
      getInitialValues({
        selectedFileId,
        selectedBookedMediaId,
        allAvailableMediaToUpload
      }),
    [selectedFileId, selectedBookedMediaId, allAvailableMediaToUpload]
  )
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const showPeriodStep = allAvailableMediaToUpload?.length > 1

  const steps = useMemo(
    () => [
      {
        component: (
          <UploadFileStep
            mediaData={selectedFileMediaData}
            fileRequirements={selectedFileRequirements}
            showPeriodStep={showPeriodStep}
          />
        ),
        show: true
      },
      {
        component: <PeriodStep availableProducts={availableProducts} />,
        show: showPeriodStep
      }
    ],
    [selectedFileMediaData, selectedFileRequirements, availableProducts, showPeriodStep]
  )

  const clearCreateItemHandler = useCallback(() => {
    dispatch(clearUploadBookingMediaFile())
  }, [dispatch])

  return (
    <StepForm
      steps={steps}
      formik={purifiedFormik}
      formName={UPLOAD_BOOKED_MEDIA_FILES}
      // processing
      isLoadingSelector={uploadBookingMediaFileIsLoadingSelector}
      successSubmit={fileWasUploaded}
      errorSelector={uploadBookingMediaFileErrorSelector}
      // after form submit
      clearSubmitHandler={clearCreateItemHandler}
      formProgressItems={progressItems}
    />
  )
}

UploadBookedMediaFilesForm.propTypes = {
  selectedFileRequirements: PropTypes.object.isRequired,
  selectedFileMediaData: PropTypes.object.isRequired,
  selectedFileId: PropTypes.number.isRequired
}

export default UploadBookedMediaFilesForm
