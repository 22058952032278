import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import useStyles from '../styles'

const GroupNameCol = React.memo(({ eventGroupData }) => {
  const classes = useStyles()

  return (
    <td className={classes.nameContainer} key={'name'}>
      <div className={classnames(classes.name)}>{eventGroupData.name}</div>
    </td>
  )
})

GroupNameCol.propTypes = {
  eventGroupData: PropTypes.object.isRequired
}

export default GroupNameCol
