import React from 'react'
import { useSelector } from 'react-redux'

import useLoadCatalogueProducts from '../../../../../../ReusableFormFields/ProductsAndPagesFields/hooks/useLoadCatalogueProducts'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import ProductFields from '../../../../../../ReusableFormFields/ProductFields'

import {
  catalogueProductsErrorSelector,
  catalogueProductsIsLoadingSelector,
  catalogueProductsNextSelector,
  catalogueProductsSelector,
  catalogueProductsWasLoadedSelector
} from '../../../../../../../modules/selectors/mediaOrders'

import { PRODUCT_DATA, PRODUCT_ID } from '../../../../../../ReusableFormFields/ProductFields/fields'
import { CLICK_THROUGH_LINK, NAME } from '../../../../fields'

const stepFields = [PRODUCT_ID]

const ProductsStep = ({ formik, handleStepChange }) => {
  const catalogueProductsNext = useSelector(catalogueProductsNextSelector)

  const { setValues, values } = formik

  const { loadInitialProductsHandler, loadMoreProductsHandler } = useLoadCatalogueProducts()

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  const onProductSelectHandler = product => {
    setValues({
      ...values,
      [NAME]: product.name,
      [PRODUCT_ID]: product.id,
      [PRODUCT_DATA]: product,
      [CLICK_THROUGH_LINK]: product.link
    })
  }

  return (
    <Step
      stepTitle="Select a product to promote"
      stepDescription="You can add more product ads to this campaign after successful creation"
      handleContinue={handleContinue}
    >
      <ProductFields
        formik={formik}
        loadInitialProductsHandler={loadInitialProductsHandler}
        loadMoreProductsHandler={catalogueProductsNext ? loadMoreProductsHandler : null}
        onProductSelect={onProductSelectHandler}
        dataSelector={catalogueProductsSelector}
        errorSelector={catalogueProductsErrorSelector}
        wasLoadedSelector={catalogueProductsWasLoadedSelector}
        isLoadingSelector={catalogueProductsIsLoadingSelector}
      />
    </Step>
  )
}

export default ProductsStep
