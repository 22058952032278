import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import PhoneVerificationForm from './PhoneVerificationForm'
import InfoModal from '../../../../../features/components/Modals/InfoModal'

import { ReactComponent as LockIcon } from '../../../../../assets/icons/lock.svg'

import useStyles from './styles'

const PhoneNumberVerificationModal = ({ isModalOpened, modalData = {}, onClose, onVerificationResend }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <InfoModal
      isOpen={isModalOpened}
      onClose={() => onClose({ shouldPopulateInitialPhone: true })}
      Icon={LockIcon}
      iconClassName={classes.icon}
      contentClassName={classes.content}
      modalTitle="Please verify your number"
      ModalMessage={
        <p className={classes.modalMessage}>
          {t(
            'A message with a verification code has been sent to your registered device. Please enter the code to continue.'
          )}
        </p>
      }
    >
      <PhoneVerificationForm
        modalData={modalData}
        onVerificationResend={onVerificationResend}
        onSuccessSubmit={onClose}
      />
    </InfoModal>
  )
}

PhoneNumberVerificationModal.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  modalData: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onVerificationResend: PropTypes.func.isRequired
}

export default PhoneNumberVerificationModal
