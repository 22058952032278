import { createStyles } from '../../../../../../../../../../styles/helpers'

import { lightGrey2, mediumGrey } from '../../../../../../../../../../styles/const/colors'

const useStyles = createStyles({
  datePickerOptimizedPlaceholder: {
    border: `1px solid ${mediumGrey}`,
    borderRadius: 3,
    height: 38,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 14,
    backgroundColor: isDatePickerDisabled => (isDatePickerDisabled ? lightGrey2 : 'transparent')
  }
})

export default useStyles
