import { createStyles } from '../../../../styles/helpers'
import { transitionShort } from '../../../../styles/const/common'
import { darkGrey, lightChipRed, red } from '../../../../styles/const/colors'

export default createStyles({
  btnTrash: {
    minHeight: 48,
    minWidth: 48,
    cursor: 'pointer',
    transition: transitionShort,
    backgroundColor: 'transparent',
    '&:hover': {
      color: darkGrey,
      fill: red,
      '& path': {
        fill: [lightChipRed, '!important']
      },
      '& rect': {
        stroke: red
      }
    },
    '& path': {
      transition: transitionShort,
      fill: [red, '!important'],
      stroke: 'none !important'
    },
    '&:active': {
      padding: 1
    }
  }
})
