import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles({
  editSectionContent: {
    '& > *:first-child': {
      marginTop: 12
    }
  }
})

export default useStyles
