import { useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { reorderElement } from '../helpers/arrays'

const useOrderList = (list, updateOrderActionCreator) => {
  const dispatch = useDispatch()

  const orderedList = useMemo(() => {
    // This creates a new sorted array without modifying the original array
    return [...list].sort((a, b) => a.order - b.order)
  }, [list])

  const handleOrderUpdate = useCallback(
    ({ destination, source }) => {
      if (destination && destination.index !== source.index) {
        const reorderedList = reorderElement(orderedList, source.index, destination.index)
        const reorderedPayload = reorderedList.map((item, index) => ({
          ...item,
          order: index
        }))
        dispatch(updateOrderActionCreator(reorderedPayload))
      }
    },

    [dispatch, orderedList, updateOrderActionCreator]
  )

  return { orderedList, handleOrderUpdate }
}

export default useOrderList
