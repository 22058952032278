import React, { useMemo } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { calc, formatCurrency } from '../../../../helpers/numbers'
import { getProductsTotalPrice } from '../../Contract/helpers'

import useStyles from './styles'

const ProductsCostSummary = ({ currencySymbol, allProducts, discount = 0 }) => {
  const { t } = useTranslation()

  const classes = useStyles()

  const allProductsTotalPrice = useMemo(() => getProductsTotalPrice(allProducts), [allProducts])

  const discountedTotalPrice = useMemo(() => {
    return allProductsTotalPrice - calc(allProductsTotalPrice).mul(discount).div(100).toNumber()
  }, [allProductsTotalPrice, discount])

  const formattedTotal = formatCurrency(discountedTotalPrice, { min: 2, max: 2 }, { symbol: currencySymbol })

  return (
    <h3 className={classnames(classes.summaryRow, classes.total)}>
      <span>{t('Subtotal')}</span>
      <span>{formattedTotal}</span>
    </h3>
  )
}

export default ProductsCostSummary
