import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'

import Form from '../../../../../../components/Form'
import AuthCodeInput from '../../../../../../components/Form/AuthCodeInput'

import { getToken } from '../../../../../../helpers/api/getToken'

import { verifyCurrentUser, clearVerifyCurrentUser } from '../../../../../../modules/actions/app'
import {
  verifyCurrentUserErrorSelector,
  verifyCurrentUserIsLoadingSelector,
  verifyCurrentUserWasVerifiedSelector
} from '../../../../../../modules/selectors/app'

import { VERIFICATION_CODE } from './fields'
import { VERIFY_PHONE_NUMBER_FORM } from '../../../../../../constants/forms'
import { initialValues, validationSchema } from './fields'

import useModalFormsStyles from '../../../../../../styles/common/modalForms'
import useStyles from '../styles'

const PhoneVerificationForm = ({ modalData = {}, onSuccessSubmit, onVerificationResend, showSubmitButton = true }) => {
  const modalFormsClasses = useModalFormsStyles()
  const classes = useStyles()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const phoneWasVerified = useSelector(verifyCurrentUserWasVerifiedSelector)

  const onSubmit = useCallback(
    async values => {
      const cognitoUserToken = await getToken()

      dispatch(
        verifyCurrentUser({
          access_token: cognitoUserToken,
          code: values[VERIFICATION_CODE],
          phone_number: modalData.phone_number
        })
      )
    },
    [dispatch, modalData]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const { setFieldValue, errors, touched } = formik

  const onVerificationCodeChange = value => {
    setFieldValue(VERIFICATION_CODE, value)
  }

  const clearVerifyPhoneNumberHandler = useCallback(() => {
    dispatch(clearVerifyCurrentUser())
  }, [dispatch])

  return (
    <Form
      formik={formik}
      formName={VERIFY_PHONE_NUMBER_FORM}
      submitText={t('Verify')}
      className={modalFormsClasses.form}
      formFooterClassName={modalFormsClasses.formFooter}
      successSubmit={phoneWasVerified}
      errorSelector={verifyCurrentUserErrorSelector}
      isLoadingSelector={verifyCurrentUserIsLoadingSelector}
      clearHandler={clearVerifyPhoneNumberHandler}
      onSuccessSubmit={onSuccessSubmit}
      showSubmit={showSubmitButton}
    >
      <AuthCodeInput
        onChange={onVerificationCodeChange}
        error={errors[VERIFICATION_CODE]}
        touched={touched[VERIFICATION_CODE]}
      />
      <p className={classes.resendMessage}>
        {t('Didn’t receive a code? ')}
        <button type="button" onClick={onVerificationResend} className={classes.resendButton}>
          {t('Resend')}
        </button>
      </p>
    </Form>
  )
}

PhoneVerificationForm.propTypes = {
  modalData: PropTypes.object,
  onSuccessSubmit: PropTypes.func,
  onVerificationResend: PropTypes.func.isRequired
}

export default PhoneVerificationForm
