import React from 'react'
import PropTypes from 'prop-types'

import { calc } from '../../../../../../../helpers/numbers'

import { useStyles } from './styles'

const PriceChangeCell = ({ data }) => {
  const classes = useStyles()

  const { price_change, increase } = data

  return (
    <div>
      {increase ? <span className={classes.increase}>↑</span> : <span className={classes.decrease}>↓</span>}{' '}
      {increase ? '+' : '-'}
      {calc(price_change).mul(100).toString()}%
    </div>
  )
}

PriceChangeCell.propTypes = {
  data: PropTypes.shape({
    price_change: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    increase: PropTypes.bool.isRequired
  }).isRequired
}

export default PriceChangeCell
