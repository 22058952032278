import { addDays, format } from 'date-fns'

import { getEndPeriodDate } from '../../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/periodHelpers'
import { DATES_FORMAT_BE } from '../../../../../../../../../constants/dates'

export function findLastContinuousPeriod(availablePeriods, selectedStartDate, productPeriod) {
  const selectedEndDate = getEndPeriodDate(selectedStartDate, productPeriod)
  const selectedPeriod = availablePeriods.find(
    ({ date_start }) => date_start === format(selectedStartDate, DATES_FORMAT_BE)
  )

  // find if there is a continuous period after the selected period
  // with simple words check if there is a period that starts the next day after the selected period ends
  const nextDayAfterEnd = format(addDays(selectedEndDate, 1), DATES_FORMAT_BE)
  let nextContinuesPeriod = availablePeriods.find(({ date_start }) => date_start === nextDayAfterEnd)

  if (nextContinuesPeriod) {
    // find latest continuous period after the selected period
    // continues period is a period that starts the next day after the previous period ends
    let lastContinuousPeriod = nextContinuesPeriod
    while (nextContinuesPeriod) {
      lastContinuousPeriod = nextContinuesPeriod
      const nextDayAfterLast = format(addDays(new Date(lastContinuousPeriod.date_end), 1), DATES_FORMAT_BE)
      nextContinuesPeriod = availablePeriods.find(({ date_start }) => date_start === nextDayAfterLast)
    }
    return lastContinuousPeriod
  } else {
    // when there is no continuous period after the selected period, it means the last one is the selected period
    return selectedPeriod
  }
}
