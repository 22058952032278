import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getChoicesGeolocationsService(params) {
  return madeRequest('GET', {
    url: ENDPOINTS.choicesGeolocations,
    params
  })
}

export function getChoicesCountriesService() {
  return madeRequest('GET', {
    params: {
      limit: 9999
    },
    url: ENDPOINTS.choicesCountries
  })
}

export function getChoicesInterestsService(params) {
  return madeRequest('GET', {
    url: ENDPOINTS.choicesInterests,
    params
  })
}

export function getChoicesCategoriesService(params) {
  return madeRequest('GET', {
    url: ENDPOINTS.choicesCategories,
    params
  })
}

export function getChoicesLanguagesService(params) {
  return madeRequest('GET', {
    url: ENDPOINTS.choicesLanguages,
    params
  })
}

export function getChoicesPixelsService(params) {
  return madeRequest('GET', {
    url: ENDPOINTS.choicesPixels,
    params
  })
}

export function getChoicesCTAService(params) {
  return madeRequest('GET', {
    url: ENDPOINTS.choicesCTA,
    params
  })
}
