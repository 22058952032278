import { reusableTransformValuesToBE } from '../../formatters'
import { INTERNAL_ID, STORE_NAME, STORES } from '../../fields'

export const transformValuesToBE = ({ initialStores, values, controllerTimezone, controllerId }) => {
  const reusableTransformedValues = reusableTransformValuesToBE(values, controllerTimezone)

  let updatedStores = values[STORES].filter(item => initialStores.find(store => store.id === item.id))

  const newFilledStores = values[STORES].filter(
    item => item[STORE_NAME] && !initialStores.find(store => store.id === item.id)
  ).map(item => ({
    controller: controllerId,
    [STORE_NAME]: item[STORE_NAME],
    [INTERNAL_ID]: item[INTERNAL_ID]
  }))

  updatedStores = updatedStores.concat(newFilledStores)

  return {
    ...reusableTransformedValues,
    [STORES]: updatedStores,
    [INTERNAL_ID]: values[INTERNAL_ID]
  }
}
