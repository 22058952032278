import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import Form from '../../../../components/Form'
import Field from '../../../../components/Form/Field'
import IdentityIconSection from './IdentityIconSection'

import { usePurifiedFormik } from '../../../../hooks/formHooks/usePurifiedFormik'
import { transformValuesToBE } from './formatters'

import {
  createTikTokIdentityErrorSelector,
  createTikTokIdentityIsLoadingSelector,
  createTikTokIdentityWasCreatedSelector
} from '../../../../modules/selectors/tikTokConstants'
import { clearCreateTikTokIdentity, createTikTokIdentity } from '../../../../modules/actions/tikTokConstants'
import { selectedTikTokAdAccountIdSelector } from '../../../../modules/selectors/app'

import { IDENTITY_TIK_TOK_CREATE } from '../../../../constants/forms'
import { initialValues, DISPLAY_NAME } from './fields'
import { validationSchema } from './validation'

import useDrawerFormStyles from '../../../../styles/common/drawerForms'

const IdentityTikTokCreateForm = () => {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormStyles()
  const dispatch = useDispatch()

  const tikTokAdAccountId = useSelector(selectedTikTokAdAccountIdSelector)

  const tikTokIdentityWasCreated = useSelector(createTikTokIdentityWasCreatedSelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values, tikTokAdAccountId)

      dispatch(createTikTokIdentity(transformedData))
    },
    [dispatch, tikTokAdAccountId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const clearCreateIdentityHandler = useCallback(() => dispatch(clearCreateTikTokIdentity()), [dispatch])

  return (
    <Form
      formik={purifiedFormik}
      formName={IDENTITY_TIK_TOK_CREATE}
      // processing
      successSubmit={tikTokIdentityWasCreated}
      errorSelector={createTikTokIdentityErrorSelector}
      isLoadingSelector={createTikTokIdentityIsLoadingSelector}
      // after form submit
      clearHandler={clearCreateIdentityHandler}
    >
      <div className={drawerFormClasses.section}>
        <h3 className={drawerFormClasses.sectionTitle}>{t('Identity name')}</h3>
        <Field formik={purifiedFormik} id={DISPLAY_NAME} name={DISPLAY_NAME} placeholder="Identity Name" />
      </div>
      <IdentityIconSection formik={formik} />
    </Form>
  )
}

export default IdentityTikTokCreateForm
