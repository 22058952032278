import { concat } from '../../helpers/common'

const MODULE_NAME = 'AUDIENCES/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_AUDIENCES = concat(MODULE_NAME, 'GET_AUDIENCES')
export const GET_AUDIENCES_SUCCESS = concat(MODULE_NAME, 'GET_AUDIENCES_SUCCESS')
export const GET_AUDIENCES_FAILURE = concat(MODULE_NAME, 'GET_AUDIENCES_FAILURE')

export const CREATE_AUDIENCE = concat(MODULE_NAME, 'CREATE_AUDIENCE')
export const CREATE_AUDIENCE_SUCCESS = concat(MODULE_NAME, 'CREATE_AUDIENCE_SUCCESS')
export const CREATE_AUDIENCE_FAILURE = concat(MODULE_NAME, 'CREATE_AUDIENCE_FAILURE')

export const CREATE_AUDIENCE_BY_SUGGESTION = concat(MODULE_NAME, 'CREATE_AUDIENCE_BY_SUGGESTION')
export const CREATE_AUDIENCE_BY_SUGGESTION_SUCCESS = concat(MODULE_NAME, 'CREATE_AUDIENCE_BY_SUGGESTION_SUCCESS')
export const CREATE_AUDIENCE_BY_SUGGESTION_FAILURE = concat(MODULE_NAME, 'CREATE_AUDIENCE_BY_SUGGESTION_FAILURE')

export const DELETE_AUDIENCE = concat(MODULE_NAME, 'DELETE_AUDIENCE')
export const DELETE_AUDIENCE_SUCCESS = concat(MODULE_NAME, 'DELETE_AUDIENCE_SUCCESS')
export const DELETE_AUDIENCE_FAILURE = concat(MODULE_NAME, 'DELETE_AUDIENCE_FAILURE')

export const CLEAR_AUDIENCES = concat(MODULE_NAME, 'CLEAR_AUDIENCES')
export const CLEAR_CREATE_AUDIENCE = concat(MODULE_NAME, 'CLEAR_CREATE_AUDIENCES')
export const CLEAR_CREATE_AUDIENCE_BY_SUGGESTION = concat(MODULE_NAME, 'CLEAR_CREATE_AUDIENCES_BY_SUGGESTION')
export const CLEAR_DELETE_AUDIENCE = concat(MODULE_NAME, 'CLEAR_DELETE_AUDIENCE')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

export function getAudiences(params, platform) {
  return { type: GET_AUDIENCES, params, platform }
}

export function getAudiencesSuccess(
  audiencesData,
  options = {
    isPaginationLoad: false
  }
) {
  return { type: GET_AUDIENCES_SUCCESS, audiencesData, options }
}

export function getAudiencesFailure(error) {
  return { type: GET_AUDIENCES_FAILURE, error }
}

export function createAudience(data, platform) {
  return { type: CREATE_AUDIENCE, data, platform }
}

export function createAudienceSuccess(audienceData) {
  return { type: CREATE_AUDIENCE_SUCCESS, audienceData }
}

export function createAudienceFailure(error) {
  return { type: CREATE_AUDIENCE_FAILURE, error }
}

export function createAudienceBySuggestion(params) {
  return { type: CREATE_AUDIENCE_BY_SUGGESTION, params }
}

export function createAudienceBySuggestionSuccess(audienceData) {
  return { type: CREATE_AUDIENCE_BY_SUGGESTION_SUCCESS, audienceData }
}

export function createAudienceBySuggestionFailure(error) {
  return { type: CREATE_AUDIENCE_BY_SUGGESTION_FAILURE, error }
}

export function deleteAudience(params) {
  return { type: DELETE_AUDIENCE, params }
}

export function deleteAudienceSuccess(id) {
  return { type: DELETE_AUDIENCE_SUCCESS, id }
}

export function deleteAudienceFailure(error) {
  return { type: DELETE_AUDIENCE_FAILURE, error }
}

export function clearAudiences() {
  return { type: CLEAR_AUDIENCES }
}

export function clearCreateAudience() {
  return { type: CLEAR_CREATE_AUDIENCE }
}

export function clearCreateAudiencesBySuggestion() {
  return { type: CLEAR_CREATE_AUDIENCE_BY_SUGGESTION }
}

export function clearDeleteAudience() {
  return { type: CLEAR_DELETE_AUDIENCE }
}
