import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  representativeSelect: {
    minWidth: 162,
    maxWidth: 162
  }
})

export default useStyles
