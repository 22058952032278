import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import RadioBlock from '../../../../../../components/Form/RadioBlock'
import Field from '../../../../../../components/Form/Field'
import Button from '../../../../../../components/Button'

import { formatOptionsList } from '../../../../../../features/formatters'

import { lineItemsSelector } from '../../../../../../modules/selectors/lineItems'

import {
  LINE_ITEM_OPTION,
  OPTION_EXISTING_LINE_ITEM,
  OPTION_NEW_LINE_ITEM,
  LINE_ITEM
} from '../../Routes/AdvancedSetupRoute/LineItemAdvancedForm/LineItemAdvancedFormContent/fields'

import useStepFormStyles from '../../../../../../styles/common/stepForms'

const stepFields = [LINE_ITEM]

const LineItemStep = ({ formik, handleStepChange, isLastStep }) => {
  const stepFormClasses = useStepFormStyles()
  const { t } = useTranslation()

  const lineItems = useSelector(lineItemsSelector)

  const { values, setFieldValue } = formik

  const formattedLineItems = formatOptionsList({
    list: lineItems,
    valueName: 'id',
    labelName: 'name'
  })

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  return (
    <>
      <div className={stepFormClasses.stepTitle}>{t('Which ad set should the ad be uploaded to?')}</div>
      <div className={stepFormClasses.stepBody}>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_existing_ad_set"
          name={LINE_ITEM_OPTION}
          label={t('An existing ad set')}
          value={OPTION_EXISTING_LINE_ITEM}
          selectedValue={values[LINE_ITEM_OPTION]}
        >
          <Field formik={formik} name={LINE_ITEM} placeholder={t('Existing Ad Set')} options={formattedLineItems} />
        </RadioBlock>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_new_ad_set"
          name={LINE_ITEM_OPTION}
          label={t('A new ad set')}
          value={OPTION_NEW_LINE_ITEM}
          selectedValue={values[LINE_ITEM_OPTION]}
        />
      </div>
      {!isLastStep && (
        <div className={stepFormClasses.stepFooter}>
          <Button type="button" className="dark" onClick={handleContinue}>
            {t('Continue')}
          </Button>
        </div>
      )}
    </>
  )
}

export default LineItemStep
