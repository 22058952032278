import React, { useMemo } from 'react'
import { insertIf } from '../../../../helpers/common'

import { DV_360_PLATFORM, GOOGLE_PLATFORM, TIKTOK_PLATFORM } from '../../../../constants/selectLists/platformList'
import { listItemsTableColumnsMinWidth } from '../columnsWidth'

import useStyles from '../styles'

export const useReusableInsightsColumns = ({ platform, items }) => {
  const classes = useStyles()

  const advancedMetrics = true //  todo SHOP-1519 Fin out if show metrics
  const showAdditionalMetrics = true //  todo SHOP-1519

  // depending on platform we should dynamically hide or show columns
  const isGooglePlatform = platform === GOOGLE_PLATFORM
  const isDV360Platform = platform === DV_360_PLATFORM
  const isTiktokPlatform = platform === TIKTOK_PLATFORM

  const isItemWithConversionsExist = useMemo(() => items.find(item => item.conversions), [items])
  const isItemWithVideoViewsExist = useMemo(
    () => (isGooglePlatform || isDV360Platform) && items.find(item => item['video_views']),
    [isGooglePlatform, isDV360Platform, items]
  )

  return useMemo(() => {
    return [
      {
        Header: 'Impressions',
        Component: ({ impressions, cpm }) => (
          <div className={classes.column}>
            {impressions || '-'}
            {showAdditionalMetrics && <div className={classes.columnSubText}>CPM: {cpm || '-'}</div>}
          </div>
        ),
        headClassName: classes.valueColumn,
        className: classes.valueColumn,
        style: {
          minWidth: listItemsTableColumnsMinWidth.impressions
        }
      },
      ...insertIf(isGooglePlatform, {
        Header: 'Viewable Impr.',
        Component: ({ active_view_impressions }) => active_view_impressions || '-',
        headClassName: classes.valueColumn,
        className: classes.valueColumn,
        style: {
          minWidth: listItemsTableColumnsMinWidth.activeViewImpressions
        }
      }),
      ...insertIf(!isGooglePlatform, {
        Header: 'Reach',
        Component: ({ reach, frequency }) => (
          <div className={classes.column}>
            {reach || '-'}
            <div className={classes.columnSubText}>Frequency {frequency || '-'}</div>
          </div>
        ),
        headClassName: classes.valueColumn,
        className: classes.valueColumn,
        style: {
          minWidth: listItemsTableColumnsMinWidth.reach
        }
      }),
      {
        Header: 'Clicks',
        Component: ({ clicks, ctr }) => (
          <div className={classes.column}>
            {clicks || '-'}
            <div className={classes.columnSubText}>CTR: {ctr || '-'}</div>
          </div>
        ),
        headClassName: classes.valueColumn,
        className: classes.valueColumn,
        style: {
          minWidth: listItemsTableColumnsMinWidth.clicks
        }
      },
      ...insertIf(!isGooglePlatform && !isDV360Platform && !isTiktokPlatform, {
        Header: 'Engagements',
        Component: ({ engagement }) => engagement || '-',
        headClassName: classes.valueColumn,
        className: classes.valueColumn,
        style: {
          minWidth: listItemsTableColumnsMinWidth.engagements
        }
      }),
      ...insertIf(isItemWithVideoViewsExist, {
        Header: 'Video Views',
        Component: ({ video_views, video_view_rate }) => (
          <div className={classes.column}>
            {video_views || '-'}
            {<div className={classes.columnSubText}>View Rate: {video_view_rate || '-'}</div>}
          </div>
        ),
        headClassName: classes.valueColumn,
        className: classes.valueColumn,
        style: {
          minWidth: listItemsTableColumnsMinWidth.videoViews
        }
      }),
      ...insertIf(isItemWithConversionsExist, {
        Header: 'Conversions',
        Component: ({ conversions, cpa }) => (
          <div className={classes.column}>
            {conversions || '-'}
            {advancedMetrics && <div className={classes.columnSubText}>CPA: {cpa || '-'}</div>}
          </div>
        ),
        headClassName: classes.valueColumn,
        className: classes.valueColumn,
        style: {
          minWidth: listItemsTableColumnsMinWidth.conversions
        }
      }),
      ...insertIf(showAdditionalMetrics, {
        Header: 'Spend',
        Component: ({ spend }) => {
          return spend || '-'
        },
        headClassName: classes.valueColumn,
        className: classes.valueColumn,
        style: {
          minWidth: listItemsTableColumnsMinWidth.spend
        }
      })
    ]
  }, [
    classes.column,
    advancedMetrics,
    classes.columnSubText,
    classes.valueColumn,
    isGooglePlatform,
    isDV360Platform,
    isTiktokPlatform,
    isItemWithConversionsExist,
    isItemWithVideoViewsExist,
    showAdditionalMetrics
  ])
}
