import { BUDGET_DAILY, BUDGET_LIFETIME } from '../../../../../Multiplatform/LineItemForms/fields'
import { BID_PRICE } from '../../../LineItemTikTokCreate/LineItemTikTokCreateForm/fields'

export const formatValuesToFE = values => {
  return {
    [BUDGET_LIFETIME]: values[BUDGET_LIFETIME] || '',
    [BUDGET_DAILY]: values[BUDGET_DAILY] || '',
    [BID_PRICE]: values.bid || ''
  }
}
