import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { format, parse } from 'date-fns'
import PropTypes from 'prop-types'

import SummaryReportGraph from '../SummaryReportGraph'

import { formatReportNumber } from '../../../formatters'

import { selectedAdAccountDataSelector } from '../../../../modules/selectors/app'

import useStyles from '../styles'

const SummaryReportContent = ({ graphData, reportsData }) => {
  const classes = useStyles()

  const { currency_symbol } = useSelector(selectedAdAccountDataSelector)

  // get only reports data and replace the variables
  const reports = useMemo(
    () =>
      reportsData &&
      reportsData
        .filter(({ description }) => !!description)
        .map(report => {
          let { context } = report
          for (let key in context) {
            // replace variables in description with actual values
            if (context.hasOwnProperty(key)) {
              const reportValue = context[key].value
              // for decimal values format should have min 2 decimal i.e 0.00 not 0
              const formatValue = context[key].format
              report.description = report.description.replaceAll(
                '${' + key + '}',
                formatReportNumber({ value: reportValue, format: formatValue, key, currency: currency_symbol })
              )
            }
          }
          return report
        }),
    [currency_symbol, reportsData]
  )

  const graphLabels = useMemo(
    () =>
      graphData &&
      graphData.map(report => {
        // parse BE date to format it for Graph
        if (report.format === 'month') {
          let date = report.segment && parse(report.segment, 'MMM-yyyy', new Date())
          return format(date, 'MMM')
        } else if (report.format === 'day') {
          let date = report.segment && parse(report.segment, 'dd-MMM-yyyy', new Date())
          return format(date, 'dd-MMM')
        } else {
          // report.format === "week"
          return report.segment
        }
      }),
    [graphData]
  )

  if (reports && reports.length) {
    return reports.map(({ title, description, graph_title, main_metric, context }, i) => (
      <div key={i} className={classes.contentRow}>
        <div className={classes.contentText}>
          <h4>{title}</h4>
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <div className={classes.contentGraph}>
          <SummaryReportGraph
            title={graph_title}
            data={graphData}
            report={title}
            graphLabels={graphLabels}
            mainMetric={context[main_metric]}
          />
        </div>
      </div>
    ))
  }

  return null
}

SummaryReportContent.propTypes = {
  graphData: PropTypes.array.isRequired,
  reportsData: PropTypes.array.isRequired
}

export default SummaryReportContent
