import React, { useCallback } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Form from '../../../../../components/Form'
import AuthCodeInput from '../../../../../components/Form/AuthCodeInput'

import { useAuthButtonProps } from '../../../useAuthButtonProps'

import { submitMFACode, clearSubmitMFACode } from '../../../../../modules/actions/cognito'
import {
  loginMFATypeSelector,
  submitMFACodeErrorSelector,
  submitMFACodeIsLoadingSelector,
  submitMFACodeWasAcceptedSelector
} from '../../../../../modules/selectors/cognito'

import { LOGIN_MFA_FORM } from '../../../../../constants/forms'
import { LOGIN_SMS_MFA } from '../../../../../constants/other'

import useStyles from '../styles'
import useAuthStyles from '../../../../../styles/common/authPages'
import useDrawerFormsStyles from '../../../../../styles/common/drawerForms'

const VERIFICATION_CODE = 'verification_code'

const MfaAuth = ({ onRestartLogin }) => {
  const classes = useStyles()
  const authClasses = useAuthStyles()
  const drawerFormClasses = useDrawerFormsStyles()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const mfaCodeWasAccepted = useSelector(submitMFACodeWasAcceptedSelector)
  const mfaType = useSelector(loginMFATypeSelector)

  const isSMSMFA = mfaType === LOGIN_SMS_MFA

  const onSubmitMFACode = useCallback(
    values => {
      dispatch(submitMFACode(values[VERIFICATION_CODE]))
    },
    [dispatch]
  )

  const formik = useFormik({
    initialValues: {
      [VERIFICATION_CODE]: ''
    },
    validationSchema: Yup.object({
      [VERIFICATION_CODE]: Yup.string()
        .required('Please enter received verification code')
        .min(6, 'Please enter received verification code')
    }),
    onSubmit: onSubmitMFACode
  })

  const buttonProps = useAuthButtonProps()

  const { setFieldValue, touched, errors } = formik

  const onVerificationCodeChange = value => {
    setFieldValue(VERIFICATION_CODE, value)
  }

  const clearMFACodeSubmit = () => {
    dispatch(clearSubmitMFACode())
  }

  return (
    <Form
      formName={LOGIN_MFA_FORM}
      formik={formik}
      successSubmit={mfaCodeWasAccepted}
      clearHandler={clearMFACodeSubmit}
      errorSelector={submitMFACodeErrorSelector}
      isLoadingSelector={submitMFACodeIsLoadingSelector}
      submitText={t('Submit')}
      formFooterClassName={authClasses.formFooter}
      buttonProps={buttonProps}
    >
      <div className={drawerFormClasses.section}>
        <h3 className={drawerFormClasses.sectionTitle}>🔒 {t('Verification')}</h3>
        <p>
          {t(
            isSMSMFA
              ? 'A message with a verification code has been sent to your registered device. Please enter the code to continue.'
              : 'Please enter the code from your authenticator app to continue.'
          )}
        </p>
        <div className={classes.verificationSection}>
          <h4 className={classes.verificationSectionTitle}>{t('Verification code')}</h4>
          <AuthCodeInput
            onChange={onVerificationCodeChange}
            error={errors[VERIFICATION_CODE]}
            touched={touched[VERIFICATION_CODE]}
          />
          <p className={classes.restartLoginMessage}>
            {t(isSMSMFA ? 'Didn’t receive a code? ' : 'Having trouble? ')}
            <button type="button" onClick={onRestartLogin} className={classes.restartLoginButton}>
              {t('Restart Login')}
            </button>
          </p>
        </div>
      </div>
    </Form>
  )
}

MfaAuth.propTypes = {
  onRestartLogin: PropTypes.func.isRequired
}

export default MfaAuth
