import React from 'react'

import ContentRow from '../../../../features/components/ContentSection/ContentRow'
import ProductLocationsTable from './ProductLocationsTable'
import MediaProductLocationCreate from '../../../../forms/Multiplatform/MediaProductLocationForms/MediaProductLocationCreate'
import MediaProductLocationEdit from '../../../../forms/Multiplatform/MediaProductLocationForms/MediaProductLocationEdit'

const ProductLocations = () => {
  return (
    <ContentRow
      title="Product Locations"
      description="Enter a list of locations (e.g. malls, stores) to group physical locations"
      leftColumnChildren={<MediaProductLocationCreate />}
    >
      <ProductLocationsTable />
      <MediaProductLocationEdit />
    </ContentRow>
  )
}

export default ProductLocations
