import React, { memo } from 'react'
import { format } from 'date-fns'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { DATES_FORMAT_FULL_YEAR } from '../../../../../constants/dates'

import useStyles from './styles'

const CalendarBar = memo(({ startTimestamp, endTimestamp }) => {
  const classes = useStyles()

  if (!startTimestamp || !endTimestamp) {
    return null
  }

  const start = new Date(startTimestamp)
  const end = new Date(endTimestamp)

  const formattedDate = `${format(start, DATES_FORMAT_FULL_YEAR)} - ${format(end, DATES_FORMAT_FULL_YEAR)}`

  return <div className={classnames('js-event-bar-placeholder', classes.barPlaceholder)}>{formattedDate}</div>
})

CalendarBar.propTypes = {
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired
}

export default CalendarBar
