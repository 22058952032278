import { createStyles } from '../../../../../styles/helpers'

import { mediumGrey } from '../../../../../styles/const/colors'

const useStyles = createStyles({
  filePreviewWithAltText: {
    border: `1px solid ${mediumGrey}`,
    borderBottom: 'none',
    marginBottom: 0,
    borderRadius: '4px 4px 0 0',
    padding: 5,
    marginTop: [0, '!important']
  },
  imageWithAltTextContainer: {
    cursor: 'pointer',
    marginBottom: 10
  },
  altTextFieldContainer: {
    borderRadius: '0 0  4px 4px',
    padding: 5,
    border: `1px solid ${mediumGrey}`
  },
  altTextField: {
    fontSize: 12,
    '& input': {
      height: 30
    }
  }
})

export default useStyles
