import * as Yup from 'yup'

export const EMAIL = 'email'
export const PASSWORD = 'password'

export const initialValues = {
  [EMAIL]: '',
  [PASSWORD]: ''
}

export const validationSchema = Yup.object({
  [EMAIL]: Yup.string().required('Please enter your email').email('Please enter correct email'),
  [PASSWORD]: Yup.string().required('Please enter your password').min(8, 'Must contain 8 characters')
})
