import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useStyles from './styles'

function Icon({ children, className, ...props }) {
  const classes = useStyles(props)

  return <i className={classnames('icon', classes.icon, className)}>{children}</i>
}

Icon.propTypes = {
  children: PropTypes.node.isRequired,
  hasBackground: PropTypes.bool,
  isRounded: PropTypes.bool,
  inheritColor: PropTypes.bool,
  // turn off direct hover color change, just inherit color
  notHoverable: PropTypes.bool
}

export default Icon
