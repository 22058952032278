import { createStyles } from '../../../../../../styles/helpers'
import { darkGrey, greyOutline } from '../../../../../../styles/const/colors'
import { backBtnMarginTop } from '../../../../../../features/components/Forms/StepForm/BackButton/styles'
import { phonesUp } from '../../../../../../styles/const/breakpoints'

export default createStyles({
  actionsContainer: {
    position: 'relative'
  },
  createMediaOrderAction: {
    left: 0,
    top: 0,
    color: [greyOutline, '!important'],
    marginTop: backBtnMarginTop,

    '&:hover': {
      color: [darkGrey, '!important']
    }
  },
  [`@media screen and (${phonesUp})`]: {
    createMediaOrderAction: {
      position: 'absolute'
    }
  }
})
