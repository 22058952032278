import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { ReactComponent as SuccessEmailSent } from '../../../../assets/icons/success-email.svg'
import InfoBlock from '../../../../features/components/InfoBlock'

import useStyles from './styles'

const CheckYourInbox = ({ email, onResendEmail }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <InfoBlock title="Check your inbox" Icon={SuccessEmailSent} greyDescription>
      <div>
        {t('We’ve sent a secure link to')} <strong>{email}</strong> {t('that you can use to log in')}
      </div>
      <br />
      <div>
        {t('Didn’t receive the email? Check your junk folder or')}{' '}
        <span onClick={onResendEmail} className={classes.link}>
          {t('send email again')}
        </span>
      </div>
    </InfoBlock>
  )
}

CheckYourInbox.propTypes = {
  email: PropTypes.string.isRequired,
  onResendEmail: PropTypes.func.isRequired
}

export default CheckYourInbox
