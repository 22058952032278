import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  bulkUpdateBookedMediaWasUpdatedSelector,
  bulkUpdateBookingMediaFileSelector,
  getBookedMediaSelector,
  getBookedMediaWasLoadedSelector
} from '../../../../../../modules/selectors/mediaOrdersBookings'
import {
  bulkUpdateBookedMediaSuccess,
  clearBulkUpdateBookedMedia,
  clearBulkUpdateBookingMediaFile,
  clearGetBookedMedia,
  getBookedMedia
} from '../../../../../../modules/actions/mediaOrdersBookings'

export const useRefreshInstallationReportRowStatusOnFileStatusUpdate = () => {
  // When the status of the file is updated, status of the row in the table should be refreshed
  // so, we fetch booked_media to get the updated status, and then update the row in the table
  const dispatch = useDispatch()

  const [isBookedMediaRowRefreshing, setIsBookedMediaRowRefreshing] = useState(false)

  const {
    booked_media: bookedMediaId,
    isLoading: updateBookingMediaFileIsLoading,
    wasUpdated: updateBookingMediaFileWasUpdated,
    sequentialIds: installationRowSequentialIds
  } = useSelector(bulkUpdateBookingMediaFileSelector)

  const bookedMediaWasLoaded = useSelector(getBookedMediaWasLoadedSelector)
  const { status: bookedMediaStatus } = useSelector(getBookedMediaSelector)
  const bulkUpdateBookedMediaWasUpdated = useSelector(bulkUpdateBookedMediaWasUpdatedSelector)

  useEffect(() => {
    // 1. If we update the status of the file, start refreshing the row
    if (updateBookingMediaFileIsLoading) {
      setIsBookedMediaRowRefreshing(true)
    }
  }, [updateBookingMediaFileIsLoading])

  useEffect(() => {
    // 2. If the status of the file was updated, fetch the booked_media to get the updated status
    if (isBookedMediaRowRefreshing && updateBookingMediaFileWasUpdated) {
      dispatch(getBookedMedia({ id: bookedMediaId }))
    }
  }, [dispatch, bookedMediaId, isBookedMediaRowRefreshing, updateBookingMediaFileWasUpdated])

  useEffect(() => {
    // 3. If the status of the file was updated and the booked_media was loaded, update the row in the table
    if (isBookedMediaRowRefreshing && updateBookingMediaFileWasUpdated && bookedMediaWasLoaded) {
      dispatch(
        bulkUpdateBookedMediaSuccess({
          ids: installationRowSequentialIds,
          status: bookedMediaStatus
        })
      )
    }
  }, [
    bookedMediaWasLoaded,
    bookedMediaStatus,
    dispatch,
    isBookedMediaRowRefreshing,
    updateBookingMediaFileWasUpdated,
    installationRowSequentialIds
  ])

  useEffect(() => {
    // 4. after the row was updated, stop refreshing it and clear the data
    if (isBookedMediaRowRefreshing && bulkUpdateBookedMediaWasUpdated) {
      setIsBookedMediaRowRefreshing(false)
      dispatch(clearGetBookedMedia())
      dispatch(clearBulkUpdateBookedMedia())
      dispatch(clearBulkUpdateBookingMediaFile())
    }
  }, [dispatch, isBookedMediaRowRefreshing, bulkUpdateBookedMediaWasUpdated, updateBookingMediaFileWasUpdated])

  return isBookedMediaRowRefreshing
}
