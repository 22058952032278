import React, { useMemo } from 'react'
import classnames from 'classnames'
import Skeleton from 'react-loading-skeleton'
import PropTypes from 'prop-types'

import CalendarBar from './CalendarBar'

import * as helpers from './helpers'

import { VERTICAL_SPACE_BETWEEN_BARS } from '../CalendarRow/helpers'

import useStyles from './styles'

const CalendarEventBar = React.memo(
  ({
    event: { id, name, isSkeleton, title, start_timestamp, end_timestamp, price, currency },
    timeToPx,
    tableInnerRowWidth,
    calendarEndDate,
    calendarStartDate
  }) => {
    const classes = useStyles()

    // distance from the start of events area to event start
    const startPositionX = useMemo(
      () =>
        helpers.calculateDistanceFromStart({
          timeToPx,
          timestamp: start_timestamp,
          tableWidth: tableInnerRowWidth
        }),
      [timeToPx, start_timestamp, tableInnerRowWidth]
    )

    const calculatedBarWidth = useMemo(() => {
      // find where the end of the event from the start of events area
      const endPositionX = helpers.calculateDistanceFromStart({
        timeToPx,
        timestamp: end_timestamp,
        tableWidth: tableInnerRowWidth
      })
      // calculated width of event bar
      return endPositionX - startPositionX
    }, [end_timestamp, startPositionX, tableInnerRowWidth, timeToPx])

    const eventBarClassName = useMemo(() => classnames('event-bar', classes.root), [classes.root])
    const barStyle = useMemo(
      () => ({
        width: calculatedBarWidth,
        transform: `translateX(${startPositionX}px)`
      }),
      [calculatedBarWidth, startPositionX]
    )

    if (isSkeleton) {
      return (
        <div
          className={classnames(classes.root)}
          style={{
            width: calculatedBarWidth,
            transform: `translateX(${startPositionX}px)`,
            // top: VERTICAL_SPACE_BETWEEN_BARS,
            // have spacing on top and bottom as regular bar:
            height: `calc(100% - ${VERTICAL_SPACE_BETWEEN_BARS}px * 2)`
          }}
        >
          <Skeleton height="100%" />
          <div className="js-event-bar-placeholder"></div>
        </div>
      )
    }

    const rootId = `so-calendar-event-${id}`

    return (
      <div
        id={rootId}
        data-timestamp={start_timestamp} // used in resizeRow
        className={eventBarClassName}
        style={barStyle}
      >
        <CalendarBar
          title={name}
          startTimestamp={start_timestamp}
          endTimestamp={end_timestamp}
          price={price}
          currencySymbol={currency.symbol}
        />
      </div>
    )
  }
)

CalendarEventBar.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    start_timestamp: PropTypes.number, // use timestamps instead of dates
    end_timestamp: PropTypes.number, // use timestamps instead of dates
    name: PropTypes.string,
    price: PropTypes.number,
    currency: PropTypes.shape({
      symbol: PropTypes.string
    }),
    isSkeleton: PropTypes.bool
  }),
  timeToPx: PropTypes.array,
  tableInnerRowWidth: PropTypes.number,
  calendarEndDate: PropTypes.instanceOf(Date),
  calendarStartDate: PropTypes.instanceOf(Date)
}

export default CalendarEventBar
