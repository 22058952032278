import { createStyles } from '../../../styles/helpers'

const buttonWidth = 63

const useStyles = createStyles({
  passwordInputWrapper: {
    position: 'relative'
  },
  hideInputText: {
    textSecurity: 'disc',
    paddingRight: buttonWidth
  },
  input: {
    paddingRight: buttonWidth
  },
  button: {
    position: 'absolute',
    right: 8,
    top: 8,
    height: 24,
    padding: '6px 8px',
    fontSize: 12,
    width: 'fit-content'
  }
})

export default useStyles
