import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Field from '../../../../components/Form/Field'
import ErrorMessage from '../../../../components/Form/ErrorMessage'

import { formatNumber } from '../../../../helpers/numbers'
import { getAdAccountFieldNameByPlatform } from '../../../../constants/selectLists/platformList'

import { selectedSelfAccountDataSelector } from '../../../../modules/selectors/app'

import { BUDGET_LIFETIME } from '../../CampaignForms/fields'

import useDrawerFormStyles from '../../../../styles/common/drawerForms'

const LifetimeBudgetField = ({ formik, remainingBudget, platform }) => {
  const drawerFormClasses = useDrawerFormStyles()

  const { t } = useTranslation()

  const adAccountFieldName = getAdAccountFieldNameByPlatform(platform)
  const { [adAccountFieldName]: adAccountData } = useSelector(selectedSelfAccountDataSelector)
  const { currency_symbol: currencySymbol } = adAccountData

  if (remainingBudget < 1) {
    return <ErrorMessage error={t('Your total remaining budget is too low to create a campaign.')} />
  }

  return (
    <>
      <h4 className={drawerFormClasses.sectionSubtitle}>{t('Budget')}</h4>
      <p>{t('A total (lifetime) budget for the entire runtime of this campaign')}</p>
      <p>
        {t('remainingBudget', {
          currencySymbol: currencySymbol,
          remainingBudget: formatNumber(remainingBudget, { max: 2 })
        })}
      </p>
      <Field
        placeholder="Budget"
        type="number"
        formik={formik}
        name={BUDGET_LIFETIME}
        symbol={currencySymbol}
        inputMode="numeric"
      />
    </>
  )
}

LifetimeBudgetField.propTypes = {
  formik: PropTypes.object.isRequired,
  remainingBudget: PropTypes.number.isRequired,
  platform: PropTypes.string.isRequired
}

export default LifetimeBudgetField
