import React from 'react'

import MediaThumbnail from '../../../../../features/components/MediaThumbnail'

const ImagesPreview = React.memo(({ images, fileRemove }) => {
  return images.map((file, index) => {
    const imgUrl = file.image || URL.createObjectURL(file)

    return (
      <MediaThumbnail
        key={index}
        // media={{ thumbnailUrl: imgUrl }}
        thumbnailUrl={imgUrl}
        mediaType={'image'}
        // download image on click
        onMediaClick={() => window.open(imgUrl)}
        onRemoveClick={() => fileRemove(index)}
      />
    )
  })
})

export default ImagesPreview
