import React from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import ItemsLoading from '../../../../../components/Loaders/ItemsLoading'
import GoogleAssetGroupPreviewCard from './GoogleAssetGroupPreviewCard'
import GoogleAdPreviewCard from './GoogleAdPreviewCard'
import SkeletonGoogleAdPreviewCard from './GoogleAdPreviewCard/Skeleton'

import {
  assetGroupsIsLoadingSelector,
  assetGroupsErrorSelector,
  assetGroupsWasLoadedSelector
} from '../../../../../modules/selectors/assets'
import { adsLoadingSelector } from '../../../../../modules/selectors/ads'

import useStyles from './styles'

const GoogleAdsInternalPreviewList = ({
  assetGroups = [],
  ads = [],
  noDataText,
  disapproveInternalAssetGroupHandler,
  disapproveInternalAdHandler
}) => {
  const classes = useStyles()

  const assetGroupsIsLoading = useSelector(assetGroupsIsLoadingSelector)
  const adsIsLoading = useSelector(adsLoadingSelector)

  return (
    <ItemsLoading
      itemsLength={assetGroups.length || ads.length}
      className={classnames({
        // minor tweak to use cardsList class while showing skeletons to keep grid styles
        [classes.cardsList]: assetGroupsIsLoading || adsIsLoading
      })}
      noDataText={noDataText}
      isLoadingSelector={assetGroupsIsLoadingSelector}
      wasLoadedSelector={assetGroupsWasLoadedSelector}
      errorSelector={assetGroupsErrorSelector}
      additionalDataIsLoading={adsIsLoading}
      initialLoadingSkeletonsNumber={4}
      filteringLoadingSkeletonsNumber={4}
      SkeletonComponent={SkeletonGoogleAdPreviewCard}
    >
      <div className={classes.cardsList}>
        {/*we combine assets groups with usual ads to show in one section */}
        {assetGroups.map(assetGroup => (
          <GoogleAssetGroupPreviewCard
            key={assetGroup.id}
            assetGroup={assetGroup}
            disapproveInternalAssetGroupHandler={() => disapproveInternalAssetGroupHandler(assetGroup)}
          />
        ))}
        {ads.map(ad => (
          <GoogleAdPreviewCard
            key={ad.id}
            ad={ad}
            disapproveInternalAdHandler={() => disapproveInternalAdHandler(ad)}
          />
        ))}
      </div>
    </ItemsLoading>
  )
}

export default GoogleAdsInternalPreviewList
