import { getAllowSelfAccounts } from '../../helpers'

import { ADD_TO_ALL_CONTROLLER_ACCOUNTS, BRAND_CATEGORY, SELF_ACCOUNTS } from '../../fields'

export const transformValuesToBE = (values, memberRole) => {
  const accounts = values[SELF_ACCOUNTS]

  const allowSelfAccounts = getAllowSelfAccounts(memberRole)
  const accessToAllControllerAccounts = values[ADD_TO_ALL_CONTROLLER_ACCOUNTS]

  return {
    [BRAND_CATEGORY]: values[BRAND_CATEGORY] ? values[BRAND_CATEGORY] : null,

    ...(allowSelfAccounts && {
      ...(accessToAllControllerAccounts
        ? {
            [SELF_ACCOUNTS]: [],
            [ADD_TO_ALL_CONTROLLER_ACCOUNTS]: true
          }
        : {
            [SELF_ACCOUNTS]: accounts.map(account => account.value),
            [ADD_TO_ALL_CONTROLLER_ACCOUNTS]: false
          })
    })
  }
}
