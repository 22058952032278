import { createStyles } from '../../../../../styles/helpers'
import { phonesDown } from '../../../../../styles/const/breakpoints'
import { red } from '../../../../../styles/const/colors'

const defaultBannerColor = red

export default createStyles({
  banner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    padding: '10px 20px',
    height: 48,
    backgroundColor: data => data.color || defaultBannerColor
  },
  bannerText: {
    marginRight: 7,
    textAlign: 'center',
    margin: '5px 0'
  },
  bannerLink: {
    color: 'white',
    marginRight: 7
  },

  [`@media screen and (${phonesDown})`]: {
    banner: {
      height: 100,
      flexDirection: 'column'
    }
  }
})
