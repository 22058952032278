import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Field from '../../../../../../../components/Form/Field'

import {
  CUSTOM_PRODUCT_DISCOUNT,
  CUSTOM_PRODUCT_PRICE,
  MEDIA_PRODUCTS,
  PRODUCT_PERIODS_DATES,
  QUANTITY
} from '../../../fields'

import { calculateDiscountPercentage } from './helpers'
import calculateProductPrice from '../helpers/calculateProductPrice'
import { discountFieldValueValidator } from '../../../../../../../helpers/fieldValidation'
import { getDecimalDiscount } from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/price'

import useStyles from './styles'

const DiscountCell = ({ formik, productValues, productIndex, isEditMode }) => {
  const classes = useStyles()
  const { setFieldValue } = formik

  const productPath = `${MEDIA_PRODUCTS}[${productIndex}]`
  const periods = productValues[PRODUCT_PERIODS_DATES]
  const quantity = productValues[QUANTITY]
  const customPrice = productValues[CUSTOM_PRODUCT_PRICE]
  const discount = productValues[CUSTOM_PRODUCT_DISCOUNT]
  const formattedDiscount = getDecimalDiscount(discount)
  const discountPath = `${productPath}.${CUSTOM_PRODUCT_DISCOUNT}`

  const calculatedPrice = calculateProductPrice(periods, quantity, formattedDiscount)
  const originalPrice = calculatedPrice.totalPrice
  const wasCustomPriceSet = customPrice !== originalPrice

  const handleDiscountChange = useCallback(
    (value, discountPath) => {
      // cost and discount should be changed immediately and not on blur
      // to avoid saving on blur, and loose save data progress when user press save button
      setFieldValue(discountPath, value)
    },
    [setFieldValue]
  )

  if (wasCustomPriceSet) {
    // don't show edit mode and just calculate the discount percentage
    return (
      <div className={classes.discountCol}>
        {/* find the discount percentage amount between calculatedPrice and originalPrice:*/}
        {calculateDiscountPercentage(originalPrice, customPrice)}%
      </div>
    )
  }

  return (
    <div className={classes.discountCol}>
      {isEditMode ? (
        <Field
          type="number"
          inputMode="decimal"
          name={discountPath}
          formik={formik}
          placeholder="0"
          symbol="%"
          symbolPosition="end"
          autoComplete="off"
          // A checker function to validate the input value. If this function returns false, the onChange method will not get triggered and the input value will not change.
          isAllowed={discountFieldValueValidator}
          decimalScale={2}
          enableReinitialize={true}
          onValueChange={handleDiscountChange}
          inputClassName={classes.discountField}
        />
      ) : (
        `${discount || 0}%`
      )}
    </div>
  )
}

DiscountCell.propTypes = {
  formik: PropTypes.object,
  productValues: PropTypes.object,
  productIndex: PropTypes.number,
  isEditMode: PropTypes.bool
}

export default DiscountCell
