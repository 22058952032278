import { concat } from '../../helpers/common'

const MODULE_NAME = 'LOCATION/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_LOCATION_LISTS = concat(MODULE_NAME, 'GET_LOCATION_LISTS')
export const GET_LOCATION_LISTS_SUCCESS = concat(MODULE_NAME, 'GET_LOCATION_LISTS_SUCCESS')
export const GET_LOCATION_LISTS_FAILURE = concat(MODULE_NAME, 'GET_LOCATION_LISTS_FAILURE')
export const CLEAR_LOCATION_LISTS = concat(MODULE_NAME, 'CLEAR_GET_LOCATION_LISTS')

export const GET_LOCATION_LIST = concat(MODULE_NAME, 'GET_LOCATION_LIST')
export const GET_LOCATION_LIST_SUCCESS = concat(MODULE_NAME, 'GET_LOCATION_LIST_SUCCESS')
export const GET_LOCATION_LIST_FAILURE = concat(MODULE_NAME, 'GET_LOCATION_LIST_FAILURE')
export const CLEAR_LOCATION_LIST = concat(MODULE_NAME, 'CLEAR_GET_LOCATION_LIST')

export const CREATE_LOCATION_LIST = concat(MODULE_NAME, 'CREATE_LOCATION_LIST')
export const CREATE_LOCATION_LIST_SUCCESS = concat(MODULE_NAME, 'CREATE_LOCATION_LIST_SUCCESS')
export const CREATE_LOCATION_LIST_FAILURE = concat(MODULE_NAME, 'CREATE_LOCATION_LIST_FAILURE')
export const CLEAR_CREATE_LOCATION_LIST = concat(MODULE_NAME, 'CLEAR_CREATE_LOCATION_LIST')

export const UPDATE_LOCATION_LIST = concat(MODULE_NAME, 'UPDATE_LOCATION_LIST')
export const UPDATE_LOCATION_LIST_SUCCESS = concat(MODULE_NAME, 'UPDATE_LOCATION_LIST_SUCCESS')
export const UPDATE_LOCATION_LIST_FAILURE = concat(MODULE_NAME, 'UPDATE_LOCATION_LIST_FAILURE')
export const CLEAR_UPDATE_LOCATION_LIST = concat(MODULE_NAME, 'CLEAR_UPDATE_LOCATION_LIST')

export const DELETE_LOCATION_LIST = concat(MODULE_NAME, 'DELETE_LOCATION_LIST')
export const DELETE_LOCATION_LIST_SUCCESS = concat(MODULE_NAME, 'DELETE_LOCATION_LIST_SUCCESS')
export const DELETE_LOCATION_LIST_FAILURE = concat(MODULE_NAME, 'DELETE_LOCATION_LIST_FAILURE')

export const VALIDATE_LOCATION_ADDRESSES = concat(MODULE_NAME, 'VALIDATE_LOCATION_ADDRESSES')
export const VALIDATE_LOCATION_ADDRESSES_SUCCESS = concat(MODULE_NAME, 'VALIDATE_LOCATION_ADDRESSES_SUCCESS')
export const VALIDATE_LOCATION_ADDRESSES_FAILURE = concat(MODULE_NAME, 'VALIDATE_LOCATION_ADDRESSES_FAILURE')
export const CLEAR_VALIDATE_LOCATION_ADDRESSES = concat(MODULE_NAME, 'CLEAR_VALIDATE_LOCATION_ADDRESSES')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// get location lists
export function getLocationLists(params) {
  return { type: GET_LOCATION_LISTS, params }
}

export function getLocationListsSuccess(locationListsData) {
  return { type: GET_LOCATION_LISTS_SUCCESS, locationListsData }
}

export function getLocationListsFailure(error) {
  return { type: GET_LOCATION_LISTS_FAILURE, error }
}

export function clearLocationLists() {
  return { type: CLEAR_LOCATION_LISTS }
}

// get location list
export function getLocationList(id) {
  return { type: GET_LOCATION_LIST, id }
}

export function getLocationListSuccess(locationListData) {
  return { type: GET_LOCATION_LIST_SUCCESS, locationListData }
}

export function getLocationListFailure(error) {
  return { type: GET_LOCATION_LIST_SUCCESS, error }
}

export function clearLocationList() {
  return { type: CLEAR_LOCATION_LIST }
}

// create location list
export function createLocationList(locationListData) {
  return { type: CREATE_LOCATION_LIST, locationListData }
}

export function createLocationListSuccess(locationListData) {
  return { type: CREATE_LOCATION_LIST_SUCCESS, locationListData }
}

export function createLocationListFailure(error) {
  return { type: CREATE_LOCATION_LIST_FAILURE, error }
}

export function clearCreateLocationList() {
  return { type: CLEAR_CREATE_LOCATION_LIST }
}

// update location list
export function updateLocationList(locationListData, id) {
  return { type: UPDATE_LOCATION_LIST, locationListData, id }
}

export function updateLocationListSuccess(locationListData) {
  return { type: UPDATE_LOCATION_LIST_SUCCESS, locationListData }
}

export function updateLocationListFailure(error) {
  return { type: UPDATE_LOCATION_LIST_FAILURE, error }
}

export function clearUpdateLocationList() {
  return { type: CLEAR_UPDATE_LOCATION_LIST }
}

// delete location list
export function deleteLocationList(id) {
  return { type: DELETE_LOCATION_LIST, id }
}

export function deleteLocationListSuccess(id) {
  return { type: DELETE_LOCATION_LIST_SUCCESS, id }
}

export function deleteLocationListFailure(error) {
  return { type: DELETE_LOCATION_LIST_FAILURE, error }
}

// validate location addresses
export function validateLocationAddresses(locationAddressesData) {
  return { type: VALIDATE_LOCATION_ADDRESSES, locationAddressesData }
}

export function validateLocationAddressesSuccess(locationAddressesData) {
  return { type: VALIDATE_LOCATION_ADDRESSES_SUCCESS, locationAddressesData }
}

export function validateLocationAddressesFailure(error, locationAddressesData) {
  return { type: VALIDATE_LOCATION_ADDRESSES_FAILURE, error, locationAddressesData }
}

export function clearValidateLocationAddresses() {
  return { type: CLEAR_VALIDATE_LOCATION_ADDRESSES }
}
