import React from 'react'
import PropTypes from 'prop-types'

import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import UploadedImagesInfoBlock from './UploadedImagesInfoBlock'
import MultipleGoogleMediaUpload from '../../../../../GoogleAssetGroup/GoogleAssetGroupMainForm/MultipleGoogleMediaUpload'

import { ASSET_IMAGE_LIST } from '../../../../../GoogleAssetGroup/GoogleAssetGroupMainForm/fields'

const ImagesSection = ({ formik, adAccountId, showPortraitImage }) => {
  const { values } = formik

  return (
    <FieldRow
      title="Images"
      description={<UploadedImagesInfoBlock values={values} showPortraitImage={showPortraitImage} />}
    >
      <MultipleGoogleMediaUpload formik={formik} FileItemPath={ASSET_IMAGE_LIST} adAccountId={adAccountId} />
    </FieldRow>
  )
}

ImagesSection.propTypes = {
  formik: PropTypes.object.isRequired,
  adAccountId: PropTypes.string.isRequired,
  showPortraitImage: PropTypes.bool
}

export default ImagesSection
