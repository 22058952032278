import * as Yup from 'yup'

import { DIFFERENT_MARGIN, GENERAL_MARGIN, MARGIN_OPTION, MARGINS, SAME_MARGIN } from '../fields'
import {
  DV_360_PLATFORM,
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  TIKTOK_PLATFORM
} from '../../../../constants/selectLists/platformList'

export const allCampaignsMarginValidationSchema = {
  [GENERAL_MARGIN]: Yup.number().when(MARGIN_OPTION, {
    is: SAME_MARGIN,
    then: () => Yup.number().required('Margin Required').max(100, 'Maximum number of margin - 100%')
  }),
  [MARGINS]: Yup.object().when(MARGIN_OPTION, {
    is: DIFFERENT_MARGIN,
    then: () =>
      Yup.object().shape({
        [FACEBOOK_PLATFORM]: Yup.number().required('Margin Required').max(100, 'Maximum number of margin - 100%'),
        [GOOGLE_PLATFORM]: Yup.number().required('Margin Required').max(100, 'Maximum number of margin - 100%'),
        [TIKTOK_PLATFORM]: Yup.number().required('Margin Required').max(100, 'Maximum number of margin - 100%'),
        [DV_360_PLATFORM]: Yup.number().required('Margin Required').max(100, 'Maximum number of margin - 100%')
      })
  })
}
