import { createStyles } from '../../../../../../../../../../styles/helpers'

import { textGrey } from '../../../../../../../../../../styles/const/colors'

const useStyles = createStyles({
  description: {
    fontSize: 14,
    fontWeight: 600,
    color: textGrey
  }
})

export default useStyles
