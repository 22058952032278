import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import NameSectionForm from '../../../../../ReusableFormSections/NameSection/NameSectionForm'

import {
  campaignUpdateErrorSelector,
  campaignUpdateIsLoadingSelector,
  campaignWasUpdatedSelector
} from '../../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'
import { clearUpdateCampaign, updateCampaign } from '../../../../../../modules/actions/campaigns'

const NameSection = ({ editItemData: campaign, ...formProps }) => {
  const dispatch = useDispatch()

  const campaignWasUpdated = useSelector(campaignWasUpdatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const handleSubmit = useCallback(
    values => {
      const updateCampaignData = {
        account: selectedAdAccountId,
        ...values
      }

      dispatch(updateCampaign(updateCampaignData, campaign.id))
    },
    [dispatch, campaign.id, selectedAdAccountId]
  )

  const handleClearUpdateCampaign = useCallback(() => {
    dispatch(clearUpdateCampaign())
  }, [dispatch])

  return (
    <NameSectionForm
      placeholder="Campaign Name"
      editItem={campaign}
      handleSubmit={handleSubmit}
      clearEditItem={handleClearUpdateCampaign}
      successSubmit={campaignWasUpdated}
      isLoadingSelector={campaignUpdateIsLoadingSelector}
      errorSelector={campaignUpdateErrorSelector}
      {...formProps}
    />
  )
}

export default NameSection
