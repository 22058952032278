import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { ReactComponent as CloseIcon } from '../../assets/icons/close-grey.svg'

import { ReactComponent as IconSearch } from '../../assets/icons/search.svg'

import { capitalizeFirstLetter } from '../../helpers/common'

import { useDebouncedSearch } from '../../hooks/useDebouncedSearch'

import useStyles from './styles'

const SearchInput = ({ className, placeholder = 'Search...', id = 'search_input', onSearchChange }) => {
  const { t } = useTranslation()

  const { searchText, clearSearchValue, handleSearchTextChange } = useDebouncedSearch(onSearchChange)

  const classes = useStyles({ hasValue: !!searchText })

  const formattedPlaceholder = placeholder ? capitalizeFirstLetter(placeholder) : ''

  const translatedPlaceholder = t(formattedPlaceholder)

  const onInputChange = e => {
    handleSearchTextChange(e.target.value)
  }

  return (
    <div className={classnames(classes.searchInputContainer, className)}>
      <IconSearch className={classes.searchIcon} />
      <input
        type="text"
        id={id}
        value={searchText}
        className={classes.searchInput}
        onInput={onInputChange}
        placeholder={translatedPlaceholder}
      />
      <div
        className={classnames(classes.clearIconContainer, {
          [classes.clearIconContainerActive]: !!searchText.length
        })}
        onClick={clearSearchValue}
      >
        <CloseIcon />
      </div>
    </div>
  )
}

SearchInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired
}

export default SearchInput
