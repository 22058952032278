import { createStyles } from '../../../../../../styles/helpers'

import { tabletDown } from '../../../../../../styles/const/breakpoints'

const useStyles = createStyles({
  section: {
    padding: '70px 100px',
    height: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  textColumn: {
    maxWidth: 700
  },
  header: {
    marginBottom: 28
  },
  description: {
    marginTop: 14
  },
  [`@media screen and (${tabletDown})`]: {
    section: {
      padding: '32px 24px'
    },
    header: {
      marginBottom: 20
    }
  }
})

export default useStyles
