import * as Yup from 'yup'
import { DESCRIPTION, FILE_NAME, FILE_TYPE, FILE_URL, OTHER_TYPE } from './fields'

export const validationSchema = Yup.object({
  [FILE_URL]: Yup.string().required('Please upload a pdf file'),
  [FILE_NAME]: Yup.string().required('Name required'),
  [DESCRIPTION]: Yup.string().when(FILE_TYPE, {
    is: OTHER_TYPE,
    // set the description length to 50 characters to avoid it more then 2 lines
    then: () => Yup.string().required('Description required').max(50, 'Description is too long')
  })
})
