import { useMemo } from 'react'

export function useGraphData(data) {
  const ctrData = useMemo(() => data && data.find(dataSet => dataSet.metric === 'ctr'), [data])
  const clicksData = useMemo(() => data && data.find(dataSet => dataSet.metric === 'clicks'), [data])
  const impressionsData = useMemo(() => data && data.find(dataSet => dataSet.metric === 'impressions'), [data])
  const engagementsData = useMemo(() => data && data.find(dataSet => dataSet.metric === 'engagement'), [data])
  const roasData = useMemo(() => data && data.find(dataSet => dataSet.metric === 'roas'), [data])
  const cpaData = useMemo(() => data && data.find(dataSet => dataSet.metric === 'cpa'), [data])

  return useMemo(
    () => ({
      ctrData,
      clicksData,
      impressionsData,
      engagementsData,
      roasData,
      cpaData
    }),
    [ctrData, clicksData, impressionsData, engagementsData, roasData, cpaData]
  )
}
