import React from 'react'

import useManageFormsDrawer from '../../../../../hooks/formHooks/useManageFormsDrawer'
import useAmendmentData from './useAmendmentData'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import PdfPageSkeleton from '../../../../../features/components/Contract/ResponsiveContractPdfViewer/ContractPdfViewer/Skeleton/PdfPageSkeleton'
import AmendmentReviewContent from './AmendmentReviewContent'

import { AMENDMENT_REVIEW } from '../../../../../constants/forms'

const AmendmentReview = () => {
  const { selectedEditItemId } = useManageFormsDrawer({
    formName: AMENDMENT_REVIEW
  })

  const { amendmentContract, isDataLoading, handleClearSelectedContract } = useAmendmentData(selectedEditItemId)

  return (
    <FormDrawerWrapper
      formName={AMENDMENT_REVIEW}
      title={'Review, sign'}
      showOpenButton={false}
      isFormLoading={isDataLoading}
      onAfterFormClose={handleClearSelectedContract}
      SkeletonFormComponent={<PdfPageSkeleton />}
      isWideDrawer
    >
      <AmendmentReviewContent contract={amendmentContract} />
    </FormDrawerWrapper>
  )
}

export default AmendmentReview
