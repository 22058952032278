import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import PageContentWrapper from '../components/PageContentWrapper'

import { getAppDomainName } from '../features/helpers/getAppDomainName'

function Page({ helmetTitle, mainClassName, className, children }) {
  const { t } = useTranslation()
  const appName = getAppDomainName()

  useEffect(() => {
    if (window.analytics) {
      // call Segment analytics on every authorized page
      window.analytics.page()
    }

    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>{t(helmetTitle, { appName })}</title>
      </Helmet>
      <PageContentWrapper className={className} mainClassName={mainClassName}>
        {children}
      </PageContentWrapper>
    </>
  )
}

export default Page

Page.propTypes = {
  helmetTitle: PropTypes.string,
  mainClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
}
