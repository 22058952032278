import { concat } from '../../helpers/common'

const MODULE_NAME = 'MEDIA_FILES/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_TIK_TOK_VIDEOS = concat(MODULE_NAME, 'GET_TIK_TOK_VIDEOS')
export const GET_TIK_TOK_VIDEOS_SUCCESS = concat(MODULE_NAME, 'GET_TIK_TOK_VIDEOS_SUCCESS')
export const GET_TIK_TOK_VIDEOS_FAILURE = concat(MODULE_NAME, 'GET_TIK_TOK_VIDEOS_FAILURE')
export const CLEAR_TIK_TOK_VIDEOS = concat(MODULE_NAME, 'CLEAR_TIK_TOK_VIDEOS')

export const GET_FACEBOOK_IMAGES = concat(MODULE_NAME, 'GET_FACEBOOK_IMAGES')
export const GET_FACEBOOK_IMAGES_SUCCESS = concat(MODULE_NAME, 'GET_FACEBOOK_IMAGES_SUCCESS')
export const GET_FACEBOOK_IMAGES_FAILURE = concat(MODULE_NAME, 'GET_FACEBOOK_IMAGES_FAILURE')
export const CLEAR_FACEBOOK_IMAGES = concat(MODULE_NAME, 'CLEAR_FACEBOOK_IMAGES')

export const GET_FACEBOOK_VIDEOS = concat(MODULE_NAME, 'GET_FACEBOOK_VIDEOS')
export const GET_FACEBOOK_VIDEOS_SUCCESS = concat(MODULE_NAME, 'GET_FACEBOOK_VIDEOS_SUCCESS')
export const GET_FACEBOOK_VIDEOS_FAILURE = concat(MODULE_NAME, 'GET_FACEBOOK_VIDEOS_FAILURE')
export const CLEAR_FACEBOOK_VIDEOS = concat(MODULE_NAME, 'CLEAR_FACEBOOK_VIDEOS')

export const GET_FACEBOOK_VIDEO = concat(MODULE_NAME, 'GET_FACEBOOK_VIDEO')
export const GET_FACEBOOK_VIDEO_SUCCESS = concat(MODULE_NAME, 'GET_FACEBOOK_VIDEO_SUCCESS')
export const GET_FACEBOOK_VIDEO_FAILURE = concat(MODULE_NAME, 'GET_FACEBOOK_VIDEO_FAILURE')
export const CLEAR_FACEBOOK_VIDEO = concat(MODULE_NAME, 'CLEAR_FACEBOOK_VIDEO')

export const GET_FACEBOOK_VIDEO_URLS = concat(MODULE_NAME, 'GET_FACEBOOK_VIDEO_URLS')
export const GET_FACEBOOK_VIDEO_URLS_SUCCESS = concat(MODULE_NAME, 'GET_FACEBOOK_VIDEO_URLS_SUCCESS')
export const GET_FACEBOOK_VIDEO_URLS_FAILURE = concat(MODULE_NAME, 'GET_FACEBOOK_VIDEO_URLS_FAILURE')
export const CLEAR_FACEBOOK_VIDEO_URLS = concat(MODULE_NAME, 'CLEAR_FACEBOOK_VIDEO_URLS')

export const GET_FACEBOOK_IMAGE_URLS = concat(MODULE_NAME, 'GET_FACEBOOK_IMAGE_URLS')
export const GET_FACEBOOK_IMAGE_URLS_SUCCESS = concat(MODULE_NAME, 'GET_FACEBOOK_IMAGE_URLS_SUCCESS')
export const GET_FACEBOOK_IMAGE_URLS_FAILURE = concat(MODULE_NAME, 'GET_FACEBOOK_IMAGE_URLS_FAILURE')
export const CLEAR_FACEBOOK_IMAGE_URLS = concat(MODULE_NAME, 'CLEAR_FACEBOOK_IMAGE_URLS')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// Get tik tok videos
export function getTikTokVideos(params) {
  return { type: GET_TIK_TOK_VIDEOS, params }
}

export function getTikTokVideosSuccess(tikTokVideosData) {
  return { type: GET_TIK_TOK_VIDEOS_SUCCESS, tikTokVideosData }
}

export function getTikTokVideosFailure(error) {
  return { type: GET_TIK_TOK_VIDEOS_FAILURE, error }
}

export function clearTikTokVideos() {
  return { type: CLEAR_TIK_TOK_VIDEOS }
}

export function getFacebookMediaImages(params) {
  return { type: GET_FACEBOOK_IMAGES, params }
}

export function getFacebookMediaImagesSuccess(mediaFilesData) {
  return { type: GET_FACEBOOK_IMAGES_SUCCESS, mediaFilesData }
}

export function getFacebookMediaImagesFailure(error) {
  return { type: GET_FACEBOOK_IMAGES_FAILURE, error }
}

export function clearFacebookMediaImages() {
  return { type: CLEAR_FACEBOOK_IMAGES }
}

export function getFacebookMediaVideos(params) {
  return { type: GET_FACEBOOK_VIDEOS, params }
}

export function getFacebookMediaVideosSuccess(mediaFilesData) {
  return { type: GET_FACEBOOK_VIDEOS_SUCCESS, mediaFilesData }
}

export function getFacebookMediaVideosFailure(error) {
  return { type: GET_FACEBOOK_VIDEOS_FAILURE, error }
}

export function clearFacebookMediaVideos() {
  return { type: CLEAR_FACEBOOK_VIDEOS }
}

export function getFacebookMediaVideo(params) {
  return { type: GET_FACEBOOK_VIDEO, params }
}

export function getFacebookMediaVideoSuccess(mediaFilesData) {
  return { type: GET_FACEBOOK_VIDEO_SUCCESS, mediaFilesData }
}

export function getFacebookMediaVideoFailure(error) {
  return { type: GET_FACEBOOK_VIDEO_FAILURE, error }
}

export function clearFacebookMediaVideo() {
  return { type: CLEAR_FACEBOOK_VIDEO }
}

export function getFacebookVideoUrls(params) {
  return { type: GET_FACEBOOK_VIDEO_URLS, params }
}

export function getFacebookVideoUrlsSuccess(videoUrls, requestedVideoIds) {
  return { type: GET_FACEBOOK_VIDEO_URLS_SUCCESS, videoUrls, requestedVideoIds }
}

export function getFacebookVideoUrlsFailure(error) {
  return { type: GET_FACEBOOK_VIDEO_URLS_FAILURE, error }
}

export function clearFacebookVideoUrls() {
  return { type: CLEAR_FACEBOOK_VIDEO_URLS }
}

export function getFacebookImageUrls(params) {
  return { type: GET_FACEBOOK_IMAGE_URLS, params }
}

export function getFacebookImageUrlsSuccess(imageUrls, requestedImageHashes) {
  return { type: GET_FACEBOOK_IMAGE_URLS_SUCCESS, imageUrls, requestedImageHashes }
}

export function getFacebookImageUrlsFailure(error) {
  return { type: GET_FACEBOOK_IMAGE_URLS_FAILURE, error }
}

export function clearFacebookImageUrls() {
  return { type: CLEAR_FACEBOOK_IMAGE_URLS }
}
