import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import UploadBookedMediaFilesForm from './UploadBookedMediaFilesForm'
import WarningMessage from '../../../../features/components/Form/WarningMessage'

import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'

import { createJsonFromQueryString } from '../../../../helpers/url'
import { bookedMediaSelector } from '../../../../modules/selectors/mediaOrdersBookings'

import { UPLOAD_BOOKED_MEDIA_FILES } from '../../../../constants/forms'

import useStyles from './styles'

function UploadBookedMediaFiles() {
  const classes = useStyles()

  const bookedMedia = useSelector(bookedMediaSelector)
  const { mediaId } = createJsonFromQueryString(window.location.search)
  const { selectedEditItemId } = useManageFormsDrawer({
    formName: UPLOAD_BOOKED_MEDIA_FILES
  })

  const selectedFileId = Number(selectedEditItemId)

  const selectedFileMediaData = useMemo(
    () => bookedMedia.find(media => media.id === Number(mediaId)),
    [bookedMedia, mediaId]
  )

  const selectedFileRequirements = useMemo(
    () =>
      selectedFileMediaData?.file_requirements.find(fileRequirement => Number(fileRequirement.id) === selectedFileId),
    [selectedFileMediaData?.file_requirements, selectedFileId]
  )

  return (
    <FormDrawerWrapper
      formName={UPLOAD_BOOKED_MEDIA_FILES}
      showOpenButton={false}
      hasDefaultDrawerContentSpacing={false}
      isWideDrawer
    >
      {selectedFileRequirements ? (
        <UploadBookedMediaFilesForm
          selectedFileRequirements={selectedFileRequirements}
          selectedFileMediaData={selectedFileMediaData}
          selectedFileId={selectedFileId}
        />
      ) : (
        <WarningMessage
          description="Requested file was not found"
          sectionClassName={classes.warningMessageSection}
          descriptionClassName={classes.warningMessageDescription}
        />
      )}
    </FormDrawerWrapper>
  )
}

export default UploadBookedMediaFiles
