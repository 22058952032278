import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import TableDataLoader from '../../../../../components/Table/TableDataLoader'
import RequestedFilesTableData from './RequestedFilesTableData'

import {
  bookedMediaErrorSelector,
  bookedMediaIsLoadingSelector,
  bookedMediaWasLoadedSelector
} from '../../../../../modules/selectors/mediaOrdersBookings'

export const tableColumnsSize = {
  mediaPeriod: 140,
  status: 185,
  dueDate: 150,
  uploadButton: 100,
  actions: 40
}

const RequestedFilesTable = ({ files }) => {
  const requestedFilesColumnsSchema = useMemo(
    () => [
      {
        header: 'Requested file'
      },
      {
        header: 'Media Period',
        style: { maxWidth: tableColumnsSize.mediaPeriod }
      },
      {
        header: 'Status',
        style: { maxWidth: tableColumnsSize.status }
      },
      {
        header: 'Due date',
        style: { maxWidth: tableColumnsSize.dueDate }
      },
      {
        style: { maxWidth: tableColumnsSize.uploadButton }
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  return (
    <TableDataLoader
      itemsLength={files.length}
      errorSelector={bookedMediaErrorSelector}
      wasLoadedSelector={bookedMediaWasLoadedSelector}
      isLoadingSelector={bookedMediaIsLoadingSelector}
      skeletonProps={{ cols: requestedFilesColumnsSchema }}
      noDataContent={<div />}
    >
      <RequestedFilesTableData files={files} />
    </TableDataLoader>
  )
}

RequestedFilesTable.propTypes = {
  files: PropTypes.array.isRequired
}

export default RequestedFilesTable
