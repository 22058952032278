import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import ProductCard from './ProductCard'
import Button from '../../../components/Button'
import ProductCardSkeleton from './ProductCard/Skeleton'
import ProductsGridSkeleton from './ProductsGridSkeleton'
import ImagesModal from '../Modals/ImagesModal'
import ProductLocationImages from '../../../forms/Multiplatform/MediaOrderForms/MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/ProductSelectStep/ProductSelectionContent/ProductLocationImages'

import { getProductPrice } from '../../../forms/Multiplatform/MediaOrderForms/MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/price'
import { formatCurrency } from '../../../helpers/numbers'

import {
  productsIsLoadingSelector,
  productsSelector,
  productsWasLoadedSelector
} from '../../../modules/selectors/mediaOrdersProducts'

import useStyles from './styles'

function ProductsSelectionList({
  currency,
  availableProducts,
  onProductSelect,
  loadMoreProducts,
  className,
  noProductsMessageClassName,
  selectedLocationId
}) {
  const { t } = useTranslation()
  const classes = useStyles()

  const products = useSelector(productsSelector)
  const productsIsLoading = useSelector(productsIsLoadingSelector)
  const productsWasLoaded = useSelector(productsWasLoadedSelector)

  const [showProductImagesModal, setShowProductImagesModal] = useState(false)
  const [selectedProductId, setSelectedProductId] = useState(null)
  const [selectedImageId, setSelectedImageId] = useState(null)

  const productsFormatted = useMemo(() => {
    // sort products by in stock true first
    const sortedProducts = [...availableProducts].sort((a, b) => (a.in_stock === b.in_stock ? 0 : a.in_stock ? -1 : 1))

    return sortedProducts.map(product => {
      const productPrice = getProductPrice(product.prices, currency.code)
      const formattedProductPrice =
        productPrice && formatCurrency(productPrice.price, { min: 2, max: 2 }, { symbol: productPrice.currency_symbol })

      return {
        ...product,
        formattedPrice: formattedProductPrice,
        value: String(product.id),
        label: `${t(product.name)} (${formattedProductPrice})`
      }
    })
  }, [t, currency.code, availableProducts])

  const selectedProduct = useMemo(
    () => products.find(product => product.id === selectedProductId) || {},
    [products, selectedProductId]
  )

  const formattedProductImages = useMemo(() => {
    const images = selectedProduct.images || []

    return images.map((image, index) => {
      return {
        image: image.image,
        id: image.id,
        title: `Image ${index + 1}`
      }
    })
  }, [selectedProduct])

  const openProductImagesModal = useCallback(
    (productId, imageId) => {
      setShowProductImagesModal(true)
      setSelectedProductId(productId)
      setSelectedImageId(imageId)
    },
    [setShowProductImagesModal]
  )

  const handleSelectImage = useCallback(
    imageId => {
      setSelectedImageId(imageId)
    },
    [setSelectedImageId]
  )

  const closeProductImagesModal = useCallback(() => {
    setShowProductImagesModal(false)
  }, [setShowProductImagesModal])

  if (productsIsLoading && !products.length) {
    return <ProductsGridSkeleton />
  }

  if (productsWasLoaded && !productsFormatted.length) {
    return <p className={noProductsMessageClassName}>{t('No products found')}</p>
  }

  return (
    <>
      <ImagesModal
        title={selectedProduct.name}
        images={formattedProductImages}
        showModal={showProductImagesModal}
        onModalClose={closeProductImagesModal}
        selectedImageId={selectedImageId}
        handleSelectImage={handleSelectImage}
      />
      <div className={classnames(classes.productsGrid, className)}>
        {selectedLocationId && <ProductLocationImages selectedLocationId={selectedLocationId} />}
        {productsFormatted.map(product => {
          return (
            <ProductCard
              key={product.id}
              productData={product}
              onProductSelect={onProductSelect}
              onProductThumbnailClick={openProductImagesModal}
            />
          )
        })}
        {productsIsLoading && Array.from({ length: 5 }, (_, index) => <ProductCardSkeleton key={index} />)}
        {loadMoreProducts && !productsIsLoading && (
          <div className={classes.btnContainer}>
            <Button onClick={loadMoreProducts} className={classes.loadMoreBtn} solid>
              {t('Load more')}
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

ProductsSelectionList.propTypes = {
  currency: PropTypes.object.isRequired,
  availableProducts: PropTypes.array.isRequired,
  onProductSelect: PropTypes.func.isRequired,
  loadMoreProducts: PropTypes.func,
  className: PropTypes.string,
  noProductsMessageClassName: PropTypes.string,
  selectedLocationId: PropTypes.number
}
export default ProductsSelectionList
