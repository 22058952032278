import { createStyles } from '../../../../../../../../styles/helpers'
import { mediumGrey } from '../../../../../../../../styles/const/colors'

const titleWidth = '150px'
const useStyles = createStyles({
  addProductContainer: {
    padding: '16px 22px',
    background: mediumGrey,
    borderRadius: 6,
    display: 'flex',
    width: '100%'
  },
  addProductTitle: {
    width: titleWidth,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center'
  },
  productSelect: {
    width: `calc(100% - ${titleWidth})`
  },
  addAction: {
    width: '75px',
    justifyContent: 'end',
    alignItems: 'center'
  }
})

export default useStyles
