import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../components/Button'
import RadioBlock from '../../../../../../components/Form/RadioBlock'
import TikTokHashtagsFields from '../../../../ReusableFormFields/TikTokHashtagsFields'

import { OPTION_NO, OPTION_YES } from '../../../../../../constants/forms'
import { HASHTAGS_OPTION } from '../fields'
import { HASHTAGS } from '../../../../ReusableFormFields/TikTokHashtagsFields/fields'

import useStyles from '../../../../../../styles/common/stepForms'

const stepFields = [HASHTAGS]

const HashtagsStep = ({ formik, handleStepChange, adAccountId }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const { values, errors, touched, setFieldValue } = formik

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create a TikTok ad group')}</div>
      <div className={classes.stepTitle}>
        {t('Do you want to target people who have watched videos with specific hashtags?')}
      </div>
      <div className={classes.stepBody}>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_not_target_by_hashtags"
          name={HASHTAGS_OPTION}
          value={OPTION_NO}
          selectedValue={values[HASHTAGS_OPTION]}
          label={t('No')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_target_by_hashtags"
          name={HASHTAGS_OPTION}
          value={OPTION_YES}
          selectedValue={values[HASHTAGS_OPTION]}
          label={t('Yes, I have a list of hashtags I want to target')}
          error={errors[HASHTAGS]}
          touched={touched[HASHTAGS]}
        >
          <TikTokHashtagsFields formik={formik} adAccountId={adAccountId} />
        </RadioBlock>
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" className="dark" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default HashtagsStep
