import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as SuccessIcon } from '../../../assets/icons/checked-circle.svg'
import { ReactComponent as CrossIcon } from '../../../assets/icons/close-grey.svg'
import Loader from '../../../components/Loaders/Loader'

import { ERROR, PROCESS, SUCCESS } from '../../../constants/other'

import useStyles from './styles'

const StatusBlock = ({ status, description }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const statusProcessing = status === PROCESS
  const statusSuccess = status === SUCCESS
  const statusError = status === ERROR

  return (
    <div className={classes.container}>
      <div className={classes.iconWrapper}>
        {statusSuccess && <SuccessIcon />}
        {statusError && <CrossIcon className={classes.errorIcon} />}
        {statusProcessing && <Loader className={classes.loaderIcon} />}
      </div>
      <div className={classes.description}>{t(description)}</div>
    </div>
  )
}

export default StatusBlock
