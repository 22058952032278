import React, { useCallback, useMemo } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import EditForm from '../../../../../../features/components/Forms/EditForm'
import MultiSelectBox from '../../../../../../features/components/Form/MultiSelectBox'
import ErrorMessage from '../../../../../../components/Form/ErrorMessage'

import { formatOptionsList } from '../../../../../../features/formatters'
import { formatLanguagesToBE } from '../../../LineItemFacebookCreateOld/LineItemFacebookCreateFormOld/formatters'
import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'

import {
  lineItemUpdateErrorSelector,
  lineItemUpdateIsLoadingSelector,
  lineItemWasUpdatedSelector
} from '../../../../../../modules/selectors/lineItems'
import { campaignSelector } from '../../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'
import { clearUpdateLineItem, updateLineItem } from '../../../../../../modules/actions/lineItems'
import { choicesLanguagesLoadingSelector, choicesLanguagesSelector } from '../../../../../../modules/selectors/choices'

import { languagesEditValidation } from '../../../../../../features/validations/languages'
import { LANGUAGES } from '../../../../../ReusableFormFields/LineItemForms/fields'

const validationSchema = Yup.object({ ...languagesEditValidation })

const LanguagesSectionForm = ({ editItemData: lineItem, ...formProps }) => {
  const dispatch = useDispatch()

  const choicesLanguages = useSelector(choicesLanguagesSelector)
  const choicesLanguagesLoading = useSelector(choicesLanguagesLoadingSelector)
  const lineItemWasUpdated = useSelector(lineItemWasUpdatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const { objective: campaignObjective } = useSelector(campaignSelector)

  const { languages: lineItemLanguages } = lineItem

  const handleSubmit = useCallback(
    values => {
      const updateLineItemData = {
        account: selectedAdAccountId,
        campaign_objective: campaignObjective,
        [LANGUAGES]: formatLanguagesToBE(values[LANGUAGES])
      }

      dispatch(updateLineItem(updateLineItemData, lineItem.id))
    },
    [dispatch, lineItem.id, campaignObjective, selectedAdAccountId]
  )

  const initialValues = useMemo(() => ({ [LANGUAGES]: lineItemLanguages || [] }), [lineItemLanguages])

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const { setFieldValue, values, errors } = purifiedFormik

  const languagesError = errors[LANGUAGES]

  const formattedChoicesLanguages = useMemo(
    () =>
      formatOptionsList({
        list: choicesLanguages,
        valueName: 'key',
        labelName: 'name'
      }),
    [choicesLanguages]
  )

  const formattedSelectedLanguages =
    typeof values[LANGUAGES][0] !== 'object'
      ? formattedChoicesLanguages.filter(item => values[LANGUAGES].includes(item.value))
      : values[LANGUAGES]

  const handleClearUpdateLineItem = useCallback(() => {
    dispatch(clearUpdateLineItem())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={lineItemWasUpdated}
      clearEditItem={handleClearUpdateLineItem}
      errorSelector={lineItemUpdateErrorSelector}
      isLoadingSelector={lineItemUpdateIsLoadingSelector}
      {...formProps}
    >
      <MultiSelectBox
        placeholder="Add a language"
        options={formattedChoicesLanguages}
        value={formattedSelectedLanguages}
        isLoading={choicesLanguagesLoading}
        name={LANGUAGES}
        setFieldValue={setFieldValue}
      />
      {languagesError && <ErrorMessage error={languagesError} />}
    </EditForm>
  )
}

export default LanguagesSectionForm
