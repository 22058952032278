import { createStyles } from '../../../../../../styles/helpers'

const useStyles = createStyles(theme => ({
  adPreviewContainer: {
    display: 'flex',
    flexGrow: '1',
    alignItems: 'center',
    marginBottom: '10px',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '270px'
  },
  adPreview: {
    transform: 'scale(0.5)',
    position: 'absolute'
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 12
  },
  actionsDropdownMenu: {
    // marginLeft 'auto' helps to always position dropdown menu to the right
    marginLeft: 'auto',
    marginRight: -2
  },
  actionsListWrapper: {
    minWidth: 150
  },
  previewContainer: {
    flexGrow: 1
  },
  metricAdditional: {
    color: theme.brandPrimary
  }
}))

export default useStyles
