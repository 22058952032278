import { createStyles } from '../../../../../styles/helpers'

import { singleAdThumbnailHeight } from '../AdCardSingleContent/styles'

const useStyles = createStyles({
  adMedia: {
    height: ({ height }) => height,
    // crop the video to 4:5 ratio
    // crop the image to 1:1 ratio
    // maxHeight for single video ad is 300 because width is 240, 4:5 = 240:300
    maxHeight: ({ mediaType }) => (mediaType === 'video' ? '300px' : singleAdThumbnailHeight),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      width: '100%'
    },

    videoPreviewContainer: {
      width: '100%'
    },
    '& video': {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  }
})

export default useStyles
