import { createStyles } from '../../../../../../../../styles/helpers'

const useStyles = createStyles({
  accountSelectSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '32px'
  },
  accountSelect: {
    width: '200px'
  }
})

export default useStyles
