import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Select from '../../../../../../../components/Select'

import { mediaSubCategoriesSelector } from '../../../../../../../modules/selectors/mediaOrdersProducts'

import { formatOptionsList } from '../../../../../../../features/formatters'

import useStyles from './styles'

const CategorySelection = ({ selectedCategory, setSelectedCategory }) => {
  const classes = useStyles()

  const mediaSubCategories = useSelector(mediaSubCategoriesSelector)

  const formattedMediaSubCategoriesOptions = useMemo(() => {
    return formatOptionsList({
      list: mediaSubCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [mediaSubCategories])

  const onChangeHandler = useCallback(
    selectedCategory => {
      setSelectedCategory(selectedCategory.value)
    },
    [setSelectedCategory]
  )

  return (
    <Select
      placeholder="Category"
      value={selectedCategory}
      onChange={onChangeHandler}
      className={classes.categorySelect}
      options={formattedMediaSubCategoriesOptions}
      portaled={true}
      isSmall
    />
  )
}

CategorySelection.propTypes = {
  selectedCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSelectedCategory: PropTypes.func.isRequired
}

export default CategorySelection
