import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import MessageBlock from '../../../../../features/components/Form/MessageBlock'
import { AdFacebookEditContextProvider } from '../AdFacebookEditContextProvider'
import AdFacebookEditSections from '../Sections'
import AdPreview from '../AdPreview'

import {
  getAdFormat,
  getPlacementPositions,
  getSelectedFilterLabel,
  hasCorrespondingFilterStatus,
  transformValuesToFE
} from '../helpers'
import { createJsonFromQueryString } from '../../../../../helpers/url'

import { adSelector, adUpdateIsLoadingSelector, adWasLoadedSelector } from '../../../../../modules/selectors/ads'
import {
  lineItemSelector,
  lineItemsSelector,
  lineItemsWasLoadedSelector,
  lineItemWasLoadedSelector
} from '../../../../../modules/selectors/lineItems'
import {
  accessibleFacebookPagesErrorSelector,
  accessibleFacebookPagesWasLoadedSelector
} from '../../../../../modules/selectors/socialAccounts'
import { selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'
import { facebookImageUrlsSelector, facebookVideoUrlsSelector } from '../../../../../modules/selectors/mediaFiles'
import { campaignSelector } from '../../../../../modules/selectors/campaigns'
import { choicesCTAByGoalWasLoadedSelector } from '../../../../../modules/selectors/choices'

import { STATUS } from '../../fields'

import useStyles from './styles'

const AdFacebookEditContent = ({
  shouldCheckForPlacementsPositionsLoading,
  initialMediaIsLoading,
  setSelectedFacebookPage,
  filter,
  notRequestedVideoIds
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  // if ad is internal, it appears in query params when we open edit form
  const { internal: internalQueryParam } = createJsonFromQueryString(window.location.search)

  const isInternalAd = internalQueryParam === 'true'

  const ad = useSelector(adSelector)
  const adWasLoaded = useSelector(adWasLoadedSelector)
  const adIsUpdating = useSelector(adUpdateIsLoadingSelector)

  const lineItems = useSelector(lineItemsSelector)
  const lineItem = useSelector(lineItemSelector)

  const lineItemWasLoaded = useSelector(lineItemWasLoadedSelector)
  const lineItemsWasLoaded = useSelector(lineItemsWasLoadedSelector)
  const placementPositionsWasLoaded = lineItemWasLoaded || lineItemsWasLoaded

  const facebookPagesWasLoaded = useSelector(accessibleFacebookPagesWasLoadedSelector)
  const facebookPagesError = useSelector(accessibleFacebookPagesErrorSelector)
  const videoUrls = useSelector(facebookVideoUrlsSelector)
  const imageUrls = useSelector(facebookImageUrlsSelector)
  const { objective: campaignObjective } = useSelector(campaignSelector)
  const ctaByGoalWasLoaded = useSelector(choicesCTAByGoalWasLoadedSelector(campaignObjective))

  const placementPositions = getPlacementPositions({ ad, lineItem, lineItems, lineItemWasLoaded, lineItemsWasLoaded })

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  // ad may have different status from the AdsList status - as a result Ad is accessible during edit but not showing
  // in ads list, for that case warning message is showing to user
  const showStatusWarning = ad && ad[STATUS] && !hasCorrespondingFilterStatus(filter, ad[STATUS])
  const selectedFilterLabel = getSelectedFilterLabel(filter)

  const transformedOriginalValues = useMemo(
    () =>
      transformValuesToFE({
        ad,
        adWasLoaded,
        placementPositions,
        imageUrls,
        videoUrls,
        selectedAdAccountId,
        isEditForm: true
      }),
    [ad, adWasLoaded, placementPositions, imageUrls, videoUrls, selectedAdAccountId]
  )

  const adFormat = getAdFormat(transformedOriginalValues)

  const isDataLoading = useMemo(() => {
    // check that all needed data for ad previews is loaded and allow pages to be loaded with error
    return (
      !adWasLoaded ||
      (shouldCheckForPlacementsPositionsLoading && !placementPositionsWasLoaded) ||
      initialMediaIsLoading ||
      !(facebookPagesWasLoaded || facebookPagesError) ||
      !!notRequestedVideoIds.length ||
      !ctaByGoalWasLoaded
    )
  }, [
    adWasLoaded,
    shouldCheckForPlacementsPositionsLoading,
    placementPositionsWasLoaded,
    initialMediaIsLoading,
    facebookPagesWasLoaded,
    facebookPagesError,
    notRequestedVideoIds,
    ctaByGoalWasLoaded
  ])

  return (
    <>
      {showStatusWarning && (
        <MessageBlock title={t('adStatusMessage', { status: ad[STATUS] })} className={classes.statusWarning}>
          {t(
            `Please note that this ad won't show in the list of ads due to the '${selectedFilterLabel}' filter currently applied.`
          )}
        </MessageBlock>
      )}
      <AdPreview
        originalValues={transformedOriginalValues}
        ad={ad}
        placementPositions={placementPositions}
        isLoading={isDataLoading || adIsUpdating}
        isEditForm
      />
      <AdFacebookEditContextProvider campaignObjective={campaignObjective}>
        <AdFacebookEditSections
          adFormat={adFormat}
          ad={ad}
          transformedOriginalValues={transformedOriginalValues}
          isDataLoading={isDataLoading}
          isInternalAd={isInternalAd}
          setSelectedFacebookPage={setSelectedFacebookPage}
        />
      </AdFacebookEditContextProvider>
    </>
  )
}

AdFacebookEditContent.propTypes = {
  filter: PropTypes.string,
  initialMediaIsLoading: PropTypes.bool,
  notRequestedVideoIds: PropTypes.array,
  setSelectedFacebookPage: PropTypes.func,
  shouldCheckForPlacementsPositionsLoading: PropTypes.bool
}

export default AdFacebookEditContent
