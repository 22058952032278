import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { getIn } from 'formik'

import Button from '../../../../../../../../../../components/Button'
import SingleFileUpload from '../../../../../../../../../../forms/ReusableFormFields/SingleFileUpload'

import { initialGoogleFile } from '../../../../../../../../../../forms/Google/AdForms/fields'
import { FILE_URL, FILE_NAME } from '../../../../../../../../../../forms/ReusableFormFields/AdFileUpload/fields'
import { MEDIA_HEIGHT, MEDIA_WIDTH } from '../../../../../../../../../../forms/Facebook/AdForms/fields'
import { imageValidationSchema } from './validation'

function MultipleOnsiteMediaUpload({
  formik,
  FileItemPath,
  maxCount = 10,
  addAnotherButtonText = 'Add another image'
}) {
  const { t } = useTranslation()

  const { values, setValues, errors, touched, setFieldValue } = formik

  const carouselItems = getIn(values, FileItemPath)

  const handleAddAnotherItem = useCallback(() => {
    if (carouselItems.length < maxCount) {
      // add new initial item to carousel items array
      const updatedValues = { [FileItemPath]: [...carouselItems, initialGoogleFile] }

      setValues({ ...values, ...updatedValues })
    }
  }, [FileItemPath, carouselItems, values, setValues, maxCount])

  const handleDeleteItem = useCallback(
    index => {
      // delete item from carousel items array
      let newCarouselItems = [...carouselItems]

      newCarouselItems.splice(index, 1)

      if (newCarouselItems.length === 0) {
        // if all files were removed add empty file to represent UploadAd component
        newCarouselItems.push(initialGoogleFile)
      }

      const updatedValues = { [FileItemPath]: newCarouselItems }

      setValues({ ...values, ...updatedValues })
    },
    [FileItemPath, carouselItems, setValues, values]
  )
  const logoValidationHandler = useCallback(file => {
    return new Promise(resolve => {
      const url = URL.createObjectURL(file)

      const image = new Image()
      image.src = url

      image.onload = () => {
        resolve({
          [MEDIA_WIDTH]: image.width,
          [MEDIA_HEIGHT]: image.height
        })
      }
    }).then(values => imageValidationSchema().validate(values))
  }, [])

  const hasEmptyMedia = !!values[FileItemPath].find(item => !item[FILE_URL])

  return (
    <>
      {carouselItems.map((item, index) => {
        const itemPath = `${FileItemPath}[${index}]`
        const mediaFile = getIn(values, itemPath)
        const fileUrl = getIn(values, `${itemPath}.${FILE_URL}`)
        const fileName = getIn(values, `${itemPath}.${FILE_NAME}`)
        const mediaError = getIn(errors, `${itemPath}[${FILE_URL}]`)
        const mediaTouched = getIn(touched, `${itemPath}[${FILE_URL}]`)
        const mediaMissingError = mediaTouched && mediaError

        const handleMediaStartUpload = file => {
          return new Promise(resolve => {
            const url = URL.createObjectURL(file)

            const image = new Image()
            image.src = url

            image.onload = () => {
              resolve({
                [MEDIA_WIDTH]: image.width,
                [MEDIA_HEIGHT]: image.height
              })
            }
          }).then(values => {
            const fileData = {
              ...mediaFile,
              ...values
            }
            setFieldValue(itemPath, fileData)
          })
        }

        const handleMediaUploaded = uploadedFile => {
          const assetFileFields = {
            ...mediaFile,
            [FILE_URL]: uploadedFile.url,
            [FILE_NAME]: uploadedFile.name
          }
          setFieldValue(itemPath, assetFileFields)
        }
        return (
          <SingleFileUpload
            fileName={fileName}
            fileURL={fileUrl}
            onFileUploaded={handleMediaUploaded}
            onStartFileUploading={handleMediaStartUpload}
            onFileRemove={() => handleDeleteItem(index)}
            fileValidationHandler={logoValidationHandler}
            error={mediaMissingError}
            accept="image/png,image/jpeg"
            isImagePreview
          />
        )
      })}
      <br />
      {!hasEmptyMedia && carouselItems.length < maxCount && (
        <Button solid type="button" className="btnBlock" onClick={handleAddAnotherItem}>
          {t(addAnotherButtonText)}
        </Button>
      )}
    </>
  )
}

MultipleOnsiteMediaUpload.propTypes = {
  formik: PropTypes.object.isRequired,
  FileItemPath: PropTypes.string.isRequired
}

export default MultipleOnsiteMediaUpload
