import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { openForm } from '../../../../../../../modules/actions/forms'
import { deletePriceChangePeriod } from '../../../../../../../modules/actions/discounts'

import { PRICE_CHANGE_PERIOD_EDIT } from '../../../../../../../constants/forms'

export function usePriceChangeActions(discountPresetData) {
  const dispatch = useDispatch()

  const { id } = discountPresetData

  const openEditFormHandler = useCallback(() => {
    dispatch(openForm({ id, formName: PRICE_CHANGE_PERIOD_EDIT }))
  }, [dispatch, id])

  const deleteHandler = useCallback(() => {
    dispatch(deletePriceChangePeriod(id))
  }, [dispatch, id])

  const dropdownOptions = useMemo(
    () => [
      {
        text: 'Edit',
        onClickHandler: openEditFormHandler
      },
      {
        text: 'Delete',
        onClickHandler: deleteHandler
      }
    ],
    [openEditFormHandler, deleteHandler]
  )

  return useMemo(
    () => ({
      dropdownOptions
    }),
    [dropdownOptions]
  )
}
