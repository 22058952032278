import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { MAXIMIZE_CONVERSION_VALUE, TARGET_ROAS } from '../../../../../ReusableFormFields/CampaignForms/fields'
import { formatNumber } from '../../../../../../helpers/numbers'

import { campaignSelector } from '../../../../../../modules/selectors/campaigns'

const TargetRoasSectionPreview = () => {
  const { t } = useTranslation()

  const campaign = useSelector(campaignSelector)

  const targetRoas = campaign[MAXIMIZE_CONVERSION_VALUE][TARGET_ROAS]

  if (targetRoas) {
    const formattedTargetRoas = formatNumber(targetRoas, { min: 2, max: 2 })

    return (
      <p>
        {t('Target ROAS')} {formattedTargetRoas}
      </p>
    )
  }

  return null
}

export default TargetRoasSectionPreview
