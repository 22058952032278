import { SPEND_LIFETIME, SPEND_DAILY } from './fields'
import {
  GENDER_OPTION_ALL,
  GENDER_OPTION_FEMALES,
  GENDER_OPTION_MALES
} from '../../../ReusableFormFields/GenderFields/fields'

export const getSpendType = (
  lineItemBudgetLifetime,
  lineItemBudgetDaily,
  campaignBudgetLifetime,
  campaignBudgetDaily
) => {
  if (!lineItemBudgetDaily && !lineItemBudgetLifetime && campaignBudgetLifetime) {
    return SPEND_LIFETIME
  }

  if (!lineItemBudgetDaily && !lineItemBudgetLifetime && campaignBudgetDaily) {
    return SPEND_DAILY
  }
}

export const getGenderType = gender => {
  const { male, female } = gender

  if (male && female) {
    return GENDER_OPTION_ALL
  }

  if (male) {
    return GENDER_OPTION_MALES
  }

  if (female) {
    return GENDER_OPTION_FEMALES
  }
}
