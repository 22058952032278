import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { getIn } from 'formik'

import MediaOrderPrice from '../../../../MediaOrderPrice'
import SelectedPeriodsList from '../ProductBreakdown/SelectedPeriodsList'
import ErrorMessage from '../../../../../../../../../../components/Form/ErrorMessage'

import { getAllPackageProducts, getProductsPath } from '../../../../helpers/products'

import { PRODUCT_PERIODS_DATES } from '../../../../../../../fields'

import useStyles from './styles'
import useProductStyles from '../ProductBreakdown/styles'

function FixedPriceProducts({ selectedMediaPackage, currency, showError, errors }) {
  const classes = useStyles()
  const productClasses = useProductStyles()
  const { t } = useTranslation()

  const selectedProducts = getAllPackageProducts(selectedMediaPackage)
  const mediaPackagePrice = selectedMediaPackage.prices.find(price => price.currency === currency.code)?.price

  return (
    <section>
      {selectedProducts.map(productValues => {
        const productData = productValues.data
        // get product index in category products array:
        const productCategory = productData.media_category
        const productsInCategory = getIn(selectedMediaPackage, getProductsPath(productCategory))
        const productIndex = productsInCategory.findIndex(categoryProduct => categoryProduct.data.id === productData.id)
        const productPathInPackage = `${getProductsPath(productCategory)}.[${productIndex}]`
        const productError = showError && getIn(errors, productPathInPackage)
        const productPublicationsError = showError && getIn(errors, productPathInPackage)?.[PRODUCT_PERIODS_DATES]

        return (
          <div className={classnames(productClasses.product, classes.product)} key={productData.id}>
            <div className={productClasses.productDetails}>
              <h4 className={productClasses.name}>{productData.name}</h4>
            </div>
            <SelectedPeriodsList
              currencySymbol={currency.symbol}
              productPeriods={productValues[PRODUCT_PERIODS_DATES]}
              showPeriodPrice={false}
            />
            {productError && <ErrorMessage error={productPublicationsError || productError} />}
          </div>
        )
      })}
      {/*we don't show product total price, but instead just show mediaPackage price*/}
      <div className={classes.fixedPriceTotal}>
        <h4>{t('Total:')}</h4>
        <MediaOrderPrice regularPrice={mediaPackagePrice} currencySymbol={currency.symbol} isBold />
      </div>
    </section>
  )
}

FixedPriceProducts.propTypes = {
  selectedMediaPackage: PropTypes.object.isRequired,
  showError: PropTypes.bool,
  errors: PropTypes.object,
  currency: PropTypes.object.isRequired
}

export default FixedPriceProducts
