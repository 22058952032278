import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Field from '../../../../components/Form/Field'
import GoogleGeolocationRegion from './GoogleGeolocationRegion'
import FacebookGeolocationRegion from './FacebookGeolocationRegion'
import RadioBlock from '../../../../components/Form/RadioBlock'
import TiktokGeolocationRegion from './TiktokGeolocationRegion'

import { choicesCountriesSelector } from '../../../../modules/selectors/choices'
import { selectedPlatformSelector } from '../../../../modules/selectors/app'

import { SHOW_OPTION, SHOW_COUNTRY_CHOICE, SHOW_REGIONS, SHOW_OPTION_REGIONS } from '../fields'

import { FACEBOOK_PLATFORM, GOOGLE_PLATFORM, TIKTOK_PLATFORM } from '../../../../constants/selectLists/platformList'

import useStyles from './styles'

const Regions = ({ formik, showRegionsCountryFilter = true, formattedCountries, adAccountId, platform }) => {
  const classes = useStyles()

  const { t } = useTranslation()
  const { values, errors, touched, setFieldValue } = formik

  const regionsError = errors[SHOW_REGIONS]
  const regionsTouched = touched[SHOW_REGIONS]

  const selectedRegions = values[SHOW_REGIONS]
  const countryChoice = values[SHOW_COUNTRY_CHOICE]

  const selectedPlatform = useSelector(selectedPlatformSelector)
  const countries = useSelector(choicesCountriesSelector)

  // sometimes selected platform is not defined, like media-orders page
  const chosenPlatform = platform || selectedPlatform

  // Find the country selected by user in countries array
  // and after that dispatch getChoicesGeolocations action
  // with country code for proper sorting in regions select
  const userChoiceCountry = useMemo(() => countries.find(item => item.id === countryChoice), [countries, countryChoice])

  const renderRegionSelectionByPlatform = useMemo(() => {
    switch (chosenPlatform) {
      case FACEBOOK_PLATFORM:
        return (
          <FacebookGeolocationRegion
            selectedCountry={userChoiceCountry}
            name={SHOW_REGIONS}
            value={selectedRegions}
            setFieldValue={setFieldValue}
          />
        )
      case GOOGLE_PLATFORM:
        return (
          <GoogleGeolocationRegion
            selectedCountry={userChoiceCountry}
            name={SHOW_REGIONS}
            value={selectedRegions}
            setFieldValue={setFieldValue}
            adAccountId={adAccountId}
          />
        )
      case TIKTOK_PLATFORM:
        return (
          <TiktokGeolocationRegion
            name={SHOW_REGIONS}
            value={selectedRegions}
            setFieldValue={setFieldValue}
            adAccountId={adAccountId}
          />
        )
      default:
        return null
    }
  }, [chosenPlatform, selectedRegions, adAccountId, userChoiceCountry, setFieldValue])

  return (
    <RadioBlock
      setFieldValue={setFieldValue}
      id="radio_show_option_regions"
      name={SHOW_OPTION}
      value={SHOW_OPTION_REGIONS}
      selectedValue={values[SHOW_OPTION]}
      label={t('In specific regions or cities')}
      error={regionsError}
      touched={regionsTouched}
    >
      {showRegionsCountryFilter && (
        <Field
          placeholder="Country"
          formik={formik}
          name={SHOW_COUNTRY_CHOICE}
          options={formattedCountries}
          className={classes.countryField}
        />
      )}
      {renderRegionSelectionByPlatform}
    </RadioBlock>
  )
}

export default Regions
