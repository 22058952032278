import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Loader from '../Loaders/Loader'

import useTouchManage from '../../hooks/useTouchManage'

import useStyles from './styles'

function Button(props) {
  const {
    children,
    isLoading,
    solid = false,
    className,
    danger,
    iconHoverColor,
    disabled,
    isSmall,
    ...restProps
  } = props
  const classes = useStyles({ iconHoverColor, isSmall })

  const { isTouched, touchStartEvent } = useTouchManage()

  return (
    <button
      onTouchStart={touchStartEvent}
      className={classnames(classes.btn, className, {
        buttonTouched: isTouched,
        solid: solid,
        danger: danger
      })}
      disabled={disabled || isLoading}
      {...restProps}
    >
      {isLoading ? <Loader className={classes.loader} /> : children}
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  // style:
  isSmall: PropTypes.bool,
  disabled: PropTypes.bool,
  solid: PropTypes.bool,
  iconHoverColor: PropTypes.string,
  danger: PropTypes.bool
}

export default Button
