import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import ProductsManage from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/ProductsManage'
import AdditionalMediaOrderInfo from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/AdditionalMediaOrderInfo'

import { activeContractSelector } from '../../../../modules/selectors/contracts'

function ContractBuilder({
  formik,
  hasTopMargin = true,
  isAdditionalInfoEdit,
  setIsAdditionalInfoEdit,
  allowEdit = true,
  allowAutoSave = true,
  checkInventory,
  isAmendment
}) {
  // active contract is used to check if we are in edit mode(user may crete new contract or edit existing one)
  const activeContract = useSelector(activeContractSelector)
  const createdContractId = activeContract?.id

  return (
    <>
      <AdditionalMediaOrderInfo
        formik={formik}
        hasTopMargin={hasTopMargin}
        isEditContract={Boolean(createdContractId)}
        isEditMode={isAdditionalInfoEdit}
        setIsEditMode={setIsAdditionalInfoEdit}
        // allow save the amendment independently of the main form
        allowAutoSave={true}
      />
      <ProductsManage
        formik={formik}
        isAdditionalInfoEdit={isAdditionalInfoEdit}
        allowEdit={allowEdit}
        allowAutoSave={allowAutoSave}
        checkInventory={checkInventory}
        isAmendment={isAmendment}
      />
    </>
  )
}

ContractBuilder.propTypes = {
  formik: PropTypes.object.isRequired,
  hasTopMargin: PropTypes.bool,
  allowEdit: PropTypes.bool,
  allowAutoSave: PropTypes.bool,
  isAdditionalInfoEdit: PropTypes.bool,
  setIsAdditionalInfoEdit: PropTypes.func,
  checkInventory: PropTypes.bool,
  isAmendment: PropTypes.bool
}

export default ContractBuilder
