import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import EditSectionsList from '../../../../features/components/Forms/EditForm/EditSectionsList'
import NameSectionPreview from '../../../ReusableFormSections/NameSection/NameSectionPreview'
import NameSectionForm from './Sections/NameSection/NameSectionForm'
import BudgetSectionPreview from './Sections/BudgetSection/BudgetSectionPreview'
import BudgetSectionForm from './Sections/BudgetSection/BudgetSectionForm'
import DatesSectionPreview from './Sections/DatesSection/DatesSectionPreview'
import DatesSectionForm from './Sections/DatesSection/DatesSectionForm'
import ScheduleSectionPreview from './Sections/ScheduleSection/ScheduleSectionPreview'
import ScheduleSectionForm from './Sections/ScheduleSection/ScheduleSectionForm'
import DemographicsSectionPreview from './Sections/DemographicsSection/DemographicsSectionPreview'
import DemographicsSectionForm from './Sections/DemographicsSection/DemographicsSectionForm'
import AudienceSectionPreview from './Sections/AudienceSection/AudienceSectionPreview'
import AudienceSectionForm from './Sections/AudienceSection/AudienceSectionForm'
import LanguagesSectionPreview from './Sections/LanguagesSection/LanguagesSectionPreview'
import LanguagesSectionForm from './Sections/LanguagesSection/LanguagesSectionForm'
import CustomAudiencesSectionPreview from './Sections/CustomAudiencesSection/CustomAudiencesSectionPreview'
import CustomAudiencesSectionForm from './Sections/CustomAudiencesSection/CustomAudiencesSectionForm'

import useManageEditFormData from '../../../../hooks/formHooks/useManageEditFormData'
import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'
import { useLoadAdditionalData } from './useLoadAdditionalData'

import { getLineItem, clearLineItem } from '../../../../modules/actions/lineItems'
import { selectedAdAccountIdSelector } from '../../../../modules/selectors/app'
import { campaignWasLoadedSelector } from '../../../../modules/selectors/campaigns'
import { lineItemSelector, lineItemWasLoadedSelector } from '../../../../modules/selectors/lineItems'

import { LINE_ITEM_TIKTOK_EDIT } from '../../../../constants/forms'
import { NAME, BUDGET, DATES, SCHEDULE, DEMOGRAPHICS, AUDIENCE, LANGUAGES, CUSTOM_AUDIENCES } from './fields'
import { TIKTOK_PLATFORM } from '../../../../constants/selectLists/platformList'

// here defined initial open states for function LineItemTikTokEdit sections
const sectionsInitialOpenStates = {
  [NAME]: false,
  [BUDGET]: false,
  [DATES]: false,
  [SCHEDULE]: false,
  [DEMOGRAPHICS]: false,
  [AUDIENCE]: false,
  [LANGUAGES]: false,
  [CUSTOM_AUDIENCES]: false
}

const LineItemTikTokEdit = ({ shouldBeCleared }) => {
  const dispatch = useDispatch()

  const lineItem = useSelector(lineItemSelector)
  const lineItemWasLoaded = useSelector(lineItemWasLoadedSelector)
  const campaignWasLoaded = useSelector(campaignWasLoadedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const { isFormOpen } = useManageFormsDrawer({
    formName: LINE_ITEM_TIKTOK_EDIT
  })

  const isAdditionalDataLoading = useLoadAdditionalData({ isFormOpen })

  const isInitialDataLoading = !lineItemWasLoaded || !campaignWasLoaded || isAdditionalDataLoading

  const getLineItemHandler = useCallback(
    selectedEditItemId => {
      dispatch(getLineItem({ id: selectedEditItemId, account: selectedAdAccountId }, TIKTOK_PLATFORM))
    },
    [dispatch, selectedAdAccountId]
  )

  const clearLineItemHandler = useCallback(() => {
    if (shouldBeCleared) {
      dispatch(clearLineItem())
    }
  }, [dispatch, shouldBeCleared])

  useManageEditFormData({
    formName: LINE_ITEM_TIKTOK_EDIT,
    loadedDataId: lineItem.id,
    getDataHandler: getLineItemHandler,
    clearDataHandler: clearLineItemHandler
  })

  // here defined all edit sections for LineItemTikTokEdit
  const editSections = useMemo(
    () => [
      {
        sectionName: NAME,
        title: 'Name',
        PreviewComponent: <NameSectionPreview dataSelector={lineItemSelector} />,
        FormComponent: <NameSectionForm />
      },
      {
        sectionName: BUDGET,
        title: 'Budget',
        PreviewComponent: <BudgetSectionPreview />,
        FormComponent: <BudgetSectionForm />
      },
      {
        sectionName: DATES,
        title: 'Dates',
        PreviewComponent: <DatesSectionPreview />,
        FormComponent: <DatesSectionForm />
      },
      {
        sectionName: SCHEDULE,
        title: 'Schedule',
        PreviewComponent: <ScheduleSectionPreview />,
        FormComponent: <ScheduleSectionForm />
      },
      {
        sectionName: DEMOGRAPHICS,
        title: 'Demographics',
        PreviewComponent: <DemographicsSectionPreview />,
        FormComponent: <DemographicsSectionForm />
      },
      {
        sectionName: AUDIENCE,
        title: 'Audience Targeting',
        PreviewComponent: <AudienceSectionPreview />,
        FormComponent: <AudienceSectionForm />
      },
      {
        sectionName: LANGUAGES,
        title: 'Languages',
        PreviewComponent: <LanguagesSectionPreview />,
        FormComponent: <LanguagesSectionForm />
      },
      {
        sectionName: CUSTOM_AUDIENCES,
        title: 'Custom Audiences',
        PreviewComponent: <CustomAudiencesSectionPreview />,
        FormComponent: <CustomAudiencesSectionForm />
      }
    ],
    []
  )

  return (
    <FormDrawerWrapper
      title="Update the settings of your ad group"
      subTitle="Edit a TikTok ad group"
      formName={LINE_ITEM_TIKTOK_EDIT}
      showOpenButton={false}
    >
      <EditSectionsList
        editSections={editSections}
        isEditDataLoading={isInitialDataLoading}
        editItemData={lineItem}
        sectionsInitialOpenStates={sectionsInitialOpenStates}
      />
    </FormDrawerWrapper>
  )
}

LineItemTikTokEdit.propTypes = {
  shouldBeCleared: PropTypes.bool
}

export default LineItemTikTokEdit
