import { findNestedObj } from '../../helpers/arrays'

// create object state for all options passed to select and make id as root object key
export const prepareOptionsSelectState = (options, selectedValues) => {
  const state = {}

  options.forEach(option => {
    const isInitiallyChecked = selectedValues.includes(option.id)

    state[option.id] = {
      isChecked: isInitiallyChecked,
      isExpanded: false
    }
  })

  return state
}

// recursively gather all values that are children to clicked option
const gatherBasicUpdatedValues = option => {
  const { id, options } = option

  let values = []

  values.push(id)

  if (options) {
    options.forEach(option => {
      const { id, options: subOptions } = option

      if (subOptions) {
        values = [...values, ...gatherBasicUpdatedValues(option)]
      } else {
        values.push(id)
      }
    })
  }

  return values
}

const getUpdatedParentValuesOnSelect = (checkedOptionId, parentId, options, optionsState) => {
  // if parent has all options as selected, then select parent option as well
  // run this function in recursion mode to check all levels
  let values = []

  // default to true
  let areAllOptionsChecked = true

  const parentOption = findNestedObj(options, 'id', parentId)

  const { options: parentChildrenOptions, parent: parentOptionParentId } = parentOption

  for (let i = 0; i < parentChildrenOptions.length; i++) {
    const currentOption = parentChildrenOptions[i]

    if (currentOption.id !== checkedOptionId && !optionsState[currentOption.id]?.isChecked) {
      areAllOptionsChecked = false

      break
    }
  }

  if (areAllOptionsChecked) {
    values.push(parentId)

    // start recursion here if there's one level above current parent
    if (parentOptionParentId) {
      values = [...values, ...getUpdatedParentValuesOnSelect(parentId, parentOptionParentId, options, optionsState)]
    }
  }

  return values
}

const getUpdatedParentValuesOnDeselect = (parentId, options, optionsState) => {
  // if parent is selected, but children was deselected, then deselect parent option as well
  // run this function in recursion mode to check all levels
  let values = []

  // parentId is pushed by default
  values.push(parentId)

  const parentOption = findNestedObj(options, 'id', parentId)

  const { parent: parentOptionParentId } = parentOption

  if (parentOptionParentId && optionsState[parentOptionParentId]?.isChecked) {
    values = [...values, ...getUpdatedParentValuesOnDeselect(parentOptionParentId, options, optionsState)]
  }

  return values
}

// this function consists of three logical steps to gather all updated values
// ____________________________________________________________________________________________
// 1) Get current value with all children values if they exist (recursively)
// ____________________________________________________________________________________________
// 2) If run in isSelectMode, recursively check if parent value to current option is unselected
// If true, but all children values are selected, make parent option selected as well
// ____________________________________________________________________________________________
// 3) If run in !isSelectMode (deselect), recursively check if parent value is selected
// If true, include it in values to deselect
export const getOptionValuesToModify = ({ option = {}, options = [], optionsState = {}, isSelectMode = false }) => {
  const { id: checkedOptionId, parent: parentId } = option

  // 1 step - gather basic children values
  let values = gatherBasicUpdatedValues(option)

  // 2 step - check whether to select parent if run in isSelectMode
  if (isSelectMode && parentId && !optionsState[parentId]?.isChecked) {
    values = [...values, ...getUpdatedParentValuesOnSelect(checkedOptionId, parentId, options, optionsState)]
  }

  // 3 step - check whether to unselect parent if run in !isSelectMode (deselect)
  if (!isSelectMode && parentId && optionsState[parentId]?.isChecked) {
    values = [...values, ...getUpdatedParentValuesOnDeselect(parentId, options, optionsState)]
  }

  return values
}

// this function just toggle isChecked options for all provided array of values
export const createNewOptionsState = (prevState, newValues, isSelectMode = false) => {
  const newState = { ...prevState }

  // check or uncheck all provided values inside optionsState
  newValues.forEach(id => {
    newState[id] = {
      ...newState[id],
      // if called with isSelectMode > uncheck option
      isChecked: isSelectMode
    }
  })

  return newState
}
