import React, { useRef, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  FloatingArrow,
  arrow,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal
} from '@floating-ui/react'
import classnames from 'classnames'

import ConditionalWrapper from '../hoc/ConditionalWrapper'
import { mediumGrey } from '../../styles/const/colors'

import useStyles from './style'

const arrowHeight = 6
const defaultGap = 2
const arrowOffset = arrowHeight + defaultGap

function Tooltip({
  triggerElement,
  placement = 'top',
  children,
  style,
  className,
  bodyClassName,
  portaled = false,
  borderWidth = 2,
  color = mediumGrey,
  borderColor = mediumGrey,
  backgroundColor = 'white',
  triggerClassName,
  offsetSize = 0
}) {
  const [isOpen, setIsOpen] = useState(false)
  const arrowRef = useRef(null)

  const middleware = useMemo(
    () => [
      offset(arrowOffset + offsetSize),
      arrow({ element: arrowRef }),
      flip({ fallbackAxisSideDirection: 'start' }),
      shift()
    ],
    [offsetSize]
  )

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    placement,
    strategy: 'fixed',
    middleware
  })

  // Memoize interaction options
  const interactionOptions = useMemo(() => ({ move: false }), [])
  const roleOptions = useMemo(() => ({ role: 'tooltip' }), [])

  // Event listeners to change the open state
  const hover = useHover(context, interactionOptions)
  const focus = useFocus(context)
  // adds the ability to dismiss the tooltip when the user presses the esc key.
  const dismiss = useDismiss(context)
  const role = useRole(context, roleOptions)

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role])

  const classes = useStyles({ backgroundColor, color, borderColor, borderWidth })

  return (
    <div className={classnames('tooltip', className)} style={style}>
      <div
        className={classnames(triggerClassName, classes.triggerElement)}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {triggerElement}
      </div>
      {/* Tooltip body is not rendered until it will be opened to avoid re-rendering on scroll
      and adding horizontal scrolls when it is outside the isOpen container like in table  */}
      {isOpen && (
        <ConditionalWrapper condition={portaled} wrapper={children => <FloatingPortal>{children}</FloatingPortal>}>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className={classnames(classes.tooltipBody, bodyClassName)}
            {...getFloatingProps()}
          >
            <div>
              <FloatingArrow
                ref={arrowRef}
                context={context}
                width={12}
                height={arrowHeight}
                tipRadius={2}
                strokeWidth={borderWidth}
                stroke={borderColor}
                fill={backgroundColor}
              />
              {children}
            </div>
          </div>
        </ConditionalWrapper>
      )}
    </div>
  )
}

export default Tooltip

Tooltip.propTypes = {
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  triggerClassName: PropTypes.string,
  color: PropTypes.string,
  portaled: PropTypes.bool,
  backgroundColor: PropTypes.string,
  placement: PropTypes.string,
  borderWidth: PropTypes.number,
  offsetSize: PropTypes.number,
  triggerElement: PropTypes.node,
  children: PropTypes.node.isRequired
}
