import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import CampaignForm from './CampaignForm'
import AdvantageShoppingRoute from './Routes/AdvantageShoppingRoute'
import ProductRetargetingRoute from './Routes/ProductRetargetingRoute'
import AdvancedSetupRoute from './Routes/AdvancedSetupRoute'
import AudienceTargetingRoute from './Routes/AudienceTargetingRoute'
import FormWrapper from '../../../../features/components/Form/FormWrapper'
import ProximityCampaignRoute from './Routes/ProximityCampaignRoute'

import useFormProgressItems from './useFormProgressItems'
import useRedirectFormDrawer from '../../../../features/components/FormDrawerWrapper/useRedirectFormDrawer'

import { clearAds } from '../../../../modules/actions/ads'
import { selectedFacebookAdAccountIdSelector } from '../../../../modules/selectors/app'
import { mediaOrderUploadCreativeDataSelector } from '../../../../modules/selectors/mediaOrders'

import {
  ADVANCED_SETUP_ROUTE,
  ADVANTAGE_SHOPPING_ROUTE,
  AUDIENCE_TARGETING_ROUTE,
  getFacebookCampaignRoute,
  PRODUCT_RETARGETING_ROUTE,
  PROXIMITY_CAMPAIGN_ROUTE
} from '../fields'
import { ROUTES } from '../../../../constants/routes'
import { MODAL_TYPE } from '../../../../constants/other'

const UploadFacebookAdCreativeContent = ({ onSuccessSubmit, manageStepFormRoutes }) => {
  const dispatch = useDispatch()

  const [activatedInternalForm, setActivatedInternalForm] = useState(null)
  const [currentCampaignOption, setCurrentCampaignOption] = useState('')
  // currently used for AudienceTargetingRoute and ProximityCampaignRoute
  const [currentLineItemOption, setCurrentLineItemOption] = useState('')

  const { campaign, lineItem } = useSelector(mediaOrderUploadCreativeDataSelector)
  const adAccountId = useSelector(selectedFacebookAdAccountIdSelector)

  // if campaign was added to the redux mediaOrderUploadCreativeData during this component initialization it means
  // user is adding another ad after creation previous one(using UploadAdCreativeSuccessModal - uploadAnotherAdHandler)
  const [isExistingCampaignFlow, setIsExistingCampaignFlow] = useState(!!campaign?.id || false)

  const { selectedRoute, handleSelectRoute, routeWasSelected } = manageStepFormRoutes

  const formProgressItems = useFormProgressItems({
    isCampaignFormSuccess: routeWasSelected,
    // When routeWasSelected - selectedRoute var is available,
    // but we need currentCampaignOption to represent progressItems of current RadioButton
    selectedRoute: routeWasSelected ? selectedRoute : currentCampaignOption,
    activatedForm: activatedInternalForm,
    currentLineItemOption
  })

  const onSuccessCampaignFormSubmit = useCallback(
    selectedRoute => {
      handleSelectRoute(selectedRoute)
    },
    [handleSelectRoute]
  )

  const onCampaignOptionSelect = useCallback(
    campaignOption => {
      let selectedRoute = getFacebookCampaignRoute(campaignOption)
      setCurrentCampaignOption(selectedRoute)
    },
    [setCurrentCampaignOption]
  )

  const handleSuccessRedirect = useRedirectFormDrawer(
    `${ROUTES.mediaOrderSummaryOffNetworkDigital}?message=${MODAL_TYPE.mediaOrderFacebookCreativeUploaded}`
  )

  const onSuccessAdFormSubmit = useCallback(() => {
    dispatch(clearAds())

    // when user successfully create ad, he can move straight to ad form creation from success modal
    // as far as we skip campaign form, we populate campaign data back on success modal level
    onSuccessSubmit({
      campaign,
      lineItem,
      adAccountId
    })

    // open success modal
    handleSuccessRedirect()
  }, [dispatch, campaign, lineItem, onSuccessSubmit, adAccountId, handleSuccessRedirect])

  const reusableRouteProps = useMemo(
    () => ({
      adAccountId,
      onSuccessAdFormSubmit,
      activatedInternalForm,
      setActivatedInternalForm
    }),
    [adAccountId, activatedInternalForm, onSuccessAdFormSubmit]
  )

  const ActivatedForm = useMemo(() => {
    switch (selectedRoute) {
      case ADVANTAGE_SHOPPING_ROUTE:
        return <AdvantageShoppingRoute isExistingCampaignFlow={isExistingCampaignFlow} {...reusableRouteProps} />
      case PRODUCT_RETARGETING_ROUTE:
        return <ProductRetargetingRoute {...reusableRouteProps} />
      case AUDIENCE_TARGETING_ROUTE:
        return (
          <AudienceTargetingRoute
            isExistingCampaignFlow={isExistingCampaignFlow}
            onLineItemOptionChange={setCurrentLineItemOption}
            {...reusableRouteProps}
          />
        )
      case PROXIMITY_CAMPAIGN_ROUTE:
        return (
          <ProximityCampaignRoute
            isExistingCampaignFlow={isExistingCampaignFlow}
            onLineItemOptionChange={setCurrentLineItemOption}
            {...reusableRouteProps}
          />
        )
      case ADVANCED_SETUP_ROUTE:
      default:
        return <AdvancedSetupRoute isExistingCampaignFlow={isExistingCampaignFlow} {...reusableRouteProps} />
    }
  }, [selectedRoute, isExistingCampaignFlow, reusableRouteProps])

  return (
    <FormWrapper formProgressItems={formProgressItems}>
      {routeWasSelected ? (
        ActivatedForm
      ) : (
        <CampaignForm
          onSuccessSubmit={onSuccessCampaignFormSubmit}
          setIsExistingCampaignFlow={setIsExistingCampaignFlow}
          onCampaignOptionSelect={onCampaignOptionSelect}
        />
      )}
    </FormWrapper>
  )
}

UploadFacebookAdCreativeContent.propTypes = {
  onSuccessSubmit: PropTypes.func.isRequired,
  manageStepFormRoutes: PropTypes.object.isRequired
}

export default UploadFacebookAdCreativeContent
