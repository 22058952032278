import { createStyles } from '../../../styles/helpers'

import { black, darkGrey, textGrey } from '../../../styles/const/colors'
import { scrollableList } from '../../../styles/common/components'

const useStyles = createStyles({
  list: {
    extend: scrollableList
  },
  nameLink: {
    color: black,
    textDecoration: 'none',
    '&:hover': {
      color: darkGrey
    }
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  columnSubText: {
    color: textGrey,
    marginTop: 2,
    fontSize: 12
  },
  valueColumn: {
    textAlign: 'right'
  }
})

export default useStyles
