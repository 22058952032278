import { DIGITAL, EDM, IN_STORE, OOH, PHYSICAL_SPACES, PRINT } from '../../../../../../../constants/mediaOrders'
import {
  DIGITAL_PRODUCTS,
  EDM_PRODUCTS,
  IN_STORE_PRODUCTS,
  MAGAZINE_PRODUCTS,
  OOH_PRODUCTS,
  PHYSICAL_SPACES_PRODUCTS
} from '../fields'

export const getAllPackageProducts = packageValues => {
  return [
    ...packageValues[MAGAZINE_PRODUCTS],
    ...packageValues[DIGITAL_PRODUCTS],
    ...packageValues[EDM_PRODUCTS],
    ...packageValues[OOH_PRODUCTS],
    ...packageValues[IN_STORE_PRODUCTS],
    ...packageValues[PHYSICAL_SPACES_PRODUCTS]
  ]
}

export const getAvailableProducts = ({ products, selectedProducts, productCategory }) => {
  return products.filter(
    // when selected category - filter products by selected category
    // filter products by already selected
    product => {
      const isSameCategory = productCategory ? product.media_category === productCategory : true
      const wasSelected = selectedProducts?.find(selectedProduct => selectedProduct.data.id === product.id)

      return isSameCategory && !wasSelected
    }
  )
}

export const getProductsPath = mediaCategory => {
  switch (mediaCategory) {
    case PRINT:
      return MAGAZINE_PRODUCTS
    case OOH:
      return OOH_PRODUCTS
    case EDM:
      return EDM_PRODUCTS
    case IN_STORE:
      return IN_STORE_PRODUCTS
    case PHYSICAL_SPACES:
      return PHYSICAL_SPACES_PRODUCTS
    case DIGITAL:
    default:
      return DIGITAL_PRODUCTS
  }
}
