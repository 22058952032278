import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Step from '../../../../../../../../features/components/Forms/StepForm/Step'
import ProductFields from '../../../../../../../../forms/ReusableFormFields/ProductFields'
import ProductCard from '../../../../../../../../forms/ReusableFormFields/ProductFields/ProductCard'

import useLoadCatalogueProducts from '../../../../../../../../forms/ReusableFormFields/ProductsAndPagesFields/hooks/useLoadCatalogueProducts'

import {
  catalogueProductsErrorSelector,
  catalogueProductsIsLoadingSelector,
  catalogueProductsNextSelector,
  catalogueProductsSelector,
  catalogueProductsWasLoadedSelector
} from '../../../../../../../../modules/selectors/mediaOrders'

import { PRODUCT_DATA, PRODUCT_IDS } from '../../fields'

import useDrawerFormStyles from '../../../../../../../../styles/common/drawerForms'
import useStyles from './styles'

function SelectProductsStep({ formik }) {
  const classes = useStyles()
  const drawerFormClasses = useDrawerFormStyles()

  const catalogueProductsNext = useSelector(catalogueProductsNextSelector)
  const { t } = useTranslation()

  const { setFieldValue, values } = formik

  const onProductSelectHandler = useCallback(
    product => {
      if (!values[PRODUCT_IDS].includes(product?.id)) {
        setFieldValue(PRODUCT_IDS, [...values[PRODUCT_IDS], product?.id])
        setFieldValue(PRODUCT_DATA, [...values[PRODUCT_DATA], product])
      }
    },
    [setFieldValue, values]
  )

  const onProductRemoveHandler = useCallback(
    product => {
      if (values[PRODUCT_IDS].includes(product?.id)) {
        setFieldValue(
          PRODUCT_IDS,
          values[PRODUCT_IDS].filter(itemId => itemId !== product?.id)
        )
        setFieldValue(
          PRODUCT_DATA,
          values[PRODUCT_DATA].filter(item => item?.id !== product?.id)
        )
      }
    },
    [setFieldValue, values]
  )

  const { loadInitialProductsHandler, loadMoreProductsHandler } = useLoadCatalogueProducts()

  return (
    <Step
      stepTitle={t('Select the products you want to promote')}
      stepDescription={t(
        'Selected products will be shown at the top of search results when considered relevant to the search term'
      )}
    >
      <div className={drawerFormClasses.sectionSubtitle}>{t('Selected products')}</div>
      {values[PRODUCT_DATA]?.length
        ? values[PRODUCT_DATA].map(product => (
            <ProductCard
              key={product.id}
              product={product}
              onProductSelectHandler={() => {}}
              handleRemoveProduct={onProductRemoveHandler}
              withMarginBottom
            />
          ))
        : null}
      <div className={classes.lineSeparation} />
      <div className={drawerFormClasses.sectionSubtitle}>{t('Products')}</div>
      <ProductFields
        formik={formik}
        loadInitialProductsHandler={loadInitialProductsHandler}
        loadMoreProductsHandler={catalogueProductsNext ? loadMoreProductsHandler : null}
        onProductSelect={onProductSelectHandler}
        dataSelector={catalogueProductsSelector}
        errorSelector={catalogueProductsErrorSelector}
        wasLoadedSelector={catalogueProductsWasLoadedSelector}
        isLoadingSelector={catalogueProductsIsLoadingSelector}
        selectedProductsIDs={values[PRODUCT_IDS]}
      />
    </Step>
  )
}

export default SelectProductsStep
