import { calc } from '../../../../helpers/numbers'

import { formatDateToBE } from '../../../../constants/dates'
import {
  END_DATE,
  INCREASE,
  INCREASE_SELECT_OPTION,
  LOCATIONS,
  MEDIA_CATEGORY,
  PRICE_CHANGE,
  START_DATE
} from './fields'

export const transformValuesToBE = values => {
  return {
    [START_DATE]: formatDateToBE(values[START_DATE]),
    [END_DATE]: formatDateToBE(values[END_DATE]),
    [MEDIA_CATEGORY]: values[MEDIA_CATEGORY],
    [PRICE_CHANGE]: calc(values[PRICE_CHANGE]).div(100).toNumber(),
    [INCREASE]: values[INCREASE] === INCREASE_SELECT_OPTION,
    [LOCATIONS]: values[LOCATIONS].map(location => location.value)
  }
}
