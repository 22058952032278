import { REPRESENTATIVE } from '../../../../../../../constants/mediaOrders'
import { SELECTED_PRODUCTS } from './fields'
import { formatPeriods, getProductsTotalPrice } from '../../../../../../../features/components/Contract/helpers'
import { PRODUCT_PERIODS_DATES, QUANTITY } from '../../../../fields'

export const transformValuesToBE = (values, selectedProductsData) => {
  const selectedProducts = values[SELECTED_PRODUCTS]

  const formattedProducts = getFormattedProducts({
    selectedProductsValues: selectedProducts,
    selectedProductsData
  })

  // get the total price of all products, considering the quantity and discount which added in formatProductsPeriodsPrice
  const totalProductsPrices = getProductsTotalPrice(selectedProducts)

  return {
    products: formattedProducts,
    cost: totalProductsPrices,
    cost_with_discount: totalProductsPrices
  }
}

// contract create:
const getFormattedProducts = ({ selectedProductsValues, selectedProductsData }) => {
  return selectedProductsValues.map(product => {
    const productData = selectedProductsData.find(({ id }) => id === product.id)
    const periods = product[PRODUCT_PERIODS_DATES]

    return {
      media_product: product.id,
      original_product_name: productData.original_product_name || productData.name,
      periods_price: formatPeriods(periods),
      [QUANTITY]: product[QUANTITY],
      [REPRESENTATIVE]: productData[REPRESENTATIVE]
    }
  })
}
