import { createStyles } from '../../../styles/helpers'

export default createStyles({
  productCardsListContainer: {
    // limit maxHeight so content doesn't overflow drawer screen (bottom scroll) when load lots of products
    maxHeight: 400,
    overflowY: 'auto',
    marginTop: 16
  },
  productCardsList: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8
  },
  noProductsDataInfoBlock: {
    marginTop: 16,
    paddingBottom: 16
  },
  searchInput: {
    marginTop: 16
  }
})
