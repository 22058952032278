import store from '../../store'

import { submitLogout } from '../../modules/actions/cognito'

import { showToasts } from '../toasts'
import { captureErrorToSentry } from './requestErrorsCapturing'
import { createErrorObject, getNonFieldErrorMessage, handleTokenExpiration } from '../errors'

import { REACT_APP_ENVIRONMENTS, TOAST_TYPE } from '../../constants/other'

export const handleRequestError = (error, requestData) => {
  const { showErrorToast } = requestData

  if (error.response) {
    const { data } = error.response
    // represent general error for forms or etc. in toast message
    // there are 2 types of errors(detail and form errors)
    // form errors are parsed and represents on form fields level, but for non_field_errors it represents in toast
    if (data) {
      // BE field to skip_notification is string - convert the string 'true' or 'false' to a boolean value
      const skipToast = data.skip_notification?.toLowerCase() === 'true'

      const nonFieldErrorMessage = getNonFieldErrorMessage(data.non_field_errors)
      const errorMessage = data.detail || nonFieldErrorMessage
      const allowToast = !skipToast && showErrorToast

      errorMessage &&
        allowToast &&
        showToasts({
          type: TOAST_TYPE.error,
          message: errorMessage
        })
      handleSentryError(error, requestData, { showToast: allowToast, toastMessage: errorMessage })
    } else {
      handleSentryError(error, requestData)
    }

    throw parseError(error.response)
  } else {
    const { message, showErrorToast = true } = error
    // hardcoded error once response is missed.
    // One of the case is the FE request timeout in this case the response is missed
    const hardcodedError = {
      message: message,
      code: error.code
    }
    showErrorToast &&
      showToasts({
        type: TOAST_TYPE.error,
        message: message
      })

    handleSentryError(error, requestData, { showToast: showErrorToast, toastMessage: message })

    throw hardcodedError
  }
}

function parseError({ data, status, statusText }) {
  switch (status) {
    case 500:
    case 502:
    case 503:
    case 504:
    case 508:
      const errorMessage = 'Sorry, there has been an error retrieving that data. Please try again later.'
      // for all 500+ errors represent toast with error message
      showToasts({
        type: TOAST_TYPE.error,
        message: errorMessage
      })

      return {
        message: errorMessage,
        code: status
      }
    case 404:
      return {
        message: 'invalid api path',
        code: status
      }
    // 403 is handled by default error managing i.e. toast is represented
    case 401:
      // handle case when auth token is expired or auth details are wrong
      // in this case the logout is only done for Cognito, as on our sever it is already logged out
      store.dispatch(submitLogout())

      return {
        message: data?.detail || statusText,
        code: status
      }
    case 400:
      data && handleTokenExpiration(data)

      return createErrorObject(data, status)
    default:
      return createErrorObject(data, status)
  }
}

const handleSentryError = (error, requestData, toast) => {
  const { data, method, otherProps, params } = requestData
  const status = error.response?.status

  if (status === 404 || status === 429) {
    let errorMessages = ''
    if (status === 429) {
      // 429 status usually represents when user got blocked by a big amount of requests
      // track when user gets throttled because of too many requests
      errorMessages = '429 error - got blocked by breaking the limit of requests'
    } else if (status === 404) {
      errorMessages = '404 error - item not found'
    }

    captureErrorToSentry({
      message: errorMessages,
      method,
      otherProps,
      data,
      params,
      error,
      toast
    })
  } else if (process.env.REACT_APP_ENVIRONMENT === REACT_APP_ENVIRONMENTS.prod) {
    // track all requests errors happened on production
    captureErrorToSentry({
      status,
      message: `${status}-${otherProps.url}`,
      method,
      otherProps,
      data,
      params,
      error,
      toast
    })
  }
}
