import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useStyles from './styles'

function PageContentWrapper({ mainClassName, className, children }) {
  const classes = useStyles()

  return (
    <main className={classnames(classes.main, mainClassName)}>
      <div className={classnames(classes.container, className)}>{children}</div>
    </main>
  )
}

PageContentWrapper.propTypes = {
  mainClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
}

export default PageContentWrapper
