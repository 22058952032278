import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from '../../../../components/Button'
import ActionText from '../../../../components/ActionText'

function DrawerOpenTrigger({
  buttonType = 'button',
  OpenDrawerTrigger,
  openButtonText,
  openBtnClassName,
  openDrawerForm
}) {
  const { t } = useTranslation()

  const getOpenTrigger = () => {
    switch (buttonType) {
      case 'actionText':
        return (
          <ActionText onClick={openDrawerForm} className={openBtnClassName} isDark>
            {t(openButtonText)}
          </ActionText>
        )
      case 'button':
      default:
        return (
          <Button solid onClick={openDrawerForm} className={openBtnClassName}>
            {t(openButtonText)}
          </Button>
        )
    }
  }

  return OpenDrawerTrigger ? <OpenDrawerTrigger onClick={openDrawerForm} /> : getOpenTrigger()
}

DrawerOpenTrigger.propTypes = {
  buttonType: PropTypes.oneOf(['button', 'actionText']),
  OpenDrawerTrigger: PropTypes.func,
  openButtonText: PropTypes.string,
  openBtnClassName: PropTypes.string,
  openDrawerForm: PropTypes.func.isRequired
}

export default DrawerOpenTrigger
