import React, { forwardRef, useMemo } from 'react'
import { useTheme } from 'react-jss'
import PropTypes from 'prop-types'

import { Bar } from 'react-chartjs-2'
// import graphs initial setting
import '../index'
import { useGraph } from '../hooks'

import { formatReportNumber } from '../../../formatters'

import { greyOutline, lightGrey2 } from '../../../../styles/const/colors'
import { toolTipStyles } from '../reusableGraphOptions'
import { formatNumber } from '../../../../helpers/numbers'

const StackedBarGraph = forwardRef((props, ref) => {
  const theme = useTheme()
  useGraph()

  const {
    labels,
    primaryData,
    secondaryData,
    percentageData,
    className,
    graphColor,
    format,
    tooltipCallbackOptions = {},
    chartOptions,
    borderRadiusForPrimaryData,
    borderRadiusForSecondaryData,
    maxYValue,
    categoryPercentage
  } = props

  const graphData = {
    labels,
    datasets: [
      {
        data: primaryData,
        backgroundColor: graphColor || theme.brandPrimary,
        borderColor: graphColor || theme.brandPrimary,
        borderSkipped: false,
        maxBarThickness: 275,
        ...(categoryPercentage && { categoryPercentage }),
        borderRadius: borderRadiusForPrimaryData || {
          topLeft: 4,
          topRight: 4
        }
      },
      {
        data: secondaryData,
        backgroundColor: lightGrey2,
        borderColor: lightGrey2,
        borderRadius: borderRadiusForSecondaryData || {
          topLeft: 4,
          topRight: 4
        },
        ...(categoryPercentage && { categoryPercentage }),
        borderSkipped: false,
        maxBarThickness: 275
      }
    ]
  }
  // Custom plugin to draw percentage on each bar
  const percentagePlugin = {
    id: 'percentagePlugin',
    afterDatasetsDraw: chart => {
      const { ctx } = chart
      ctx.save()
      ctx.textAlign = 'center'
      ctx.textBaseline = 'bottom'
      ctx.fillStyle = graphColor || theme.brandPrimary // Text color
      // Extract the current font settings (e.g., "16px Arial")
      const currentFont = ctx.font
      // Split the font string to get the size and family (e.g., ["16px", "Arial"])
      const fontParts = currentFont.split(' ')
      // Set your desired font size (e.g., 20px), and keep the font family the same
      const fontSize = '8px'
      ctx.font = `${fontSize} ${fontParts.slice(1).join(' ')}` // Rebuild the font string

      const primaryData = chart.getDatasetMeta(1).data
      primaryData.forEach((bar, index) => {
        const value = percentageData[index]
        const percentage = `${formatNumber(value, { min: 0, max: 0 })}%`
        const x = bar.x
        const y = bar.y - 5 // Adjust the position of the label above the bar
        ctx.fillText(percentage, x, y)
      })

      ctx.restore()
    }
  }

  const graphOptions = useMemo(
    () => ({
      scales: {
        y: {
          stacked: true,
          grid: {
            color: 'transparent',
            drawBorder: false
          },
          ticks: {
            color: greyOutline,
            textAlign: 'left',
            maxTicksLimit: 10,
            font: {
              size: 12
            }
          },
          beginAtZero: true,
          ...(maxYValue && { max: maxYValue })
        },
        x: {
          stacked: true,
          grid: {
            display: false
          },
          ticks: {
            padding: 10,
            color: greyOutline,
            font: {
              size: 12
            }
          }
        }
      },
      layout: {
        padding: {
          top: 20 // Add padding at the top to avoid cutting off the labels
        }
      },
      plugins: {
        legend: {
          display: false
        },
        percentagePlugin: true, // Activate the custom plugin
        stacked100: { enable: true, replaceTooltipLabel: false },
        tooltip: {
          ...toolTipStyles,
          callbacks: {
            label: tooltipItem => formatReportNumber({ value: tooltipItem.raw, format }),
            ...tooltipCallbackOptions
          }
        }
      }
    }),
    [format, tooltipCallbackOptions, maxYValue]
  )

  return (
    <div className={className}>
      <Bar type="bar" data={graphData} options={chartOptions || graphOptions} ref={ref} plugins={[percentagePlugin]} />
    </div>
  )
})

StackedBarGraph.propTypes = {
  labels: PropTypes.array.isRequired,
  primaryData: PropTypes.array.isRequired,
  secondaryData: PropTypes.array.isRequired,
  className: PropTypes.string,
  graphColor: PropTypes.string,
  format: PropTypes.string,
  tooltipCallbackOptions: PropTypes.object,
  chartOptions: PropTypes.object,
  borderRadiusForPrimaryData: PropTypes.object,
  borderRadiusForSecondaryData: PropTypes.object,
  maxYValue: PropTypes.number,
  categoryPercentage: PropTypes.number
}

export default StackedBarGraph
