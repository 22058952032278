import {
  CALL_TO_ACTION,
  CALL_TO_ACTION_LEARN_MORE,
  CALL_TO_ACTION_OPTION
} from '../../../../ReusableFormFields/CallToActionFields/fields'

import {
  AD_FILE,
  FILE_ID,
  FILE_NAME,
  FILE_TYPE,
  FILE_URL,
  MEDIA_TYPE,
  UPLOADED_MEDIA_TYPE
} from '../../../../ReusableFormFields/AdFileUpload/fields'
import { AD_TEXT, LANDING_PAGE_URL } from '../../fields'

export const NAME = 'name'
// identity
export const IDENTITY_ID = 'identity_id'
// video uploading
export const VIDEO_OPTION = 'video_option'
export const VIDEO_UPLOAD_OPTION = 'video_upload_option'
export const VIDEO_FROM_LIBRARY_OPTION = 'video_from_library_option'
export const VIDEO_THUMBNAIL_URL = 'video_thumbnail_url'

export const initialTikTokVideoFileValues = {
  [AD_FILE]: {},
  [FILE_NAME]: '',
  [FILE_URL]: '',
  [FILE_ID]: '',
  [FILE_TYPE]: '',
  [MEDIA_TYPE]: '',
  [UPLOADED_MEDIA_TYPE]: '',
  [VIDEO_THUMBNAIL_URL]: ''
}

export const initialValues = {
  [NAME]: '',
  [IDENTITY_ID]: '',
  [LANDING_PAGE_URL]: 'https://',
  [AD_TEXT]: '',
  [CALL_TO_ACTION_OPTION]: CALL_TO_ACTION_LEARN_MORE,
  [CALL_TO_ACTION]: '',

  // video file
  [VIDEO_OPTION]: VIDEO_UPLOAD_OPTION,
  ...initialTikTokVideoFileValues
}
