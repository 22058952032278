// get products
import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

// Get inventory report
export const inventoryReportSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventoryReport.results,
  results => results
)

export const inventoryReportLoadingPeriodsSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventoryReport.loadingPeriods,
  loadingPeriods => loadingPeriods
)

export const getInventoryReportIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventoryReport.isLoading,
  isLoading => isLoading
)

export const getInventoryReportErrorSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventoryReport.error,
  error => error
)

export const getInventoryReportWasLoadedSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventoryReport.wasLoaded,
  wasLoaded => wasLoaded
)

// Get inventory last item
export const inventoryLastItemSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.getInventoryLastItem.data,
  data => data
)

export const getInventoryLastItemIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.getInventoryLastItem.isLoading,
  isLoading => isLoading
)

export const getInventoryLastItemErrorSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.getInventoryLastItem.error,
  error => error
)

export const inventoryLastItemWasLoadedSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.getInventoryLastItem.wasLoaded,
  wasLoaded => wasLoaded
)

// get products tags
export const productsTagsIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.productsTags.isLoading,
  isLoading => isLoading
)
export const productsTagsWasLoadedSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.productsTags.wasLoaded,
  wasLoaded => wasLoaded
)
export const productsTagsSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.productsTags.results,
  results => results
)
export const productsTagsErrorSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.productsTags.error,
  error => error
)

export const productsIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.products.isLoading,
  isLoading => isLoading
)
export const productsWasLoadedSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.products.wasLoaded,
  wasLoaded => wasLoaded
)
export const productsSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.products.results,
  results => results
)
export const productsErrorSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.products.error,
  error => error
)
export const productsNextSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.products.paging.next,
  next => next
)

// get product
export const productIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.product.isLoading,
  isLoading => isLoading
)
export const productWasLoadedSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.product.wasLoaded,
  wasLoaded => wasLoaded
)
export const productSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.product.data,
  data => data
)
export const productErrorSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.product.error,
  error => error
)

// product graph dates period
export const graphDatesPeriodSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.graphDatesPeriod,
  graphDatesPeriod => graphDatesPeriod
)

// Get product graph
export const getProductGraphSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.productGraph.results,
  results => results
)

export const getProductGraphIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.productGraph.isLoading,
  isLoading => isLoading
)

export const getProductGraphErrorSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.productGraph.error,
  error => error
)

export const getProductGraphWasLoadedSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.productGraph.wasLoaded,
  wasLoaded => wasLoaded
)
// get products graph
export const productsGraphIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.productsGraph.isLoading,
  isLoading => isLoading
)
export const productsGraphWasLoadedSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.productsGraph.wasLoaded,
  wasLoaded => wasLoaded
)
export const productsGraphSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.productsGraph.results,
  results => results
)
export const productsGraphErrorSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.productsGraph.error,
  error => error
)

// Get utilisation report
export const utilisationReportSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.utilisationReport.results,
  results => results
)

export const getUtilisationReportIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.utilisationReport.isLoading,
  isLoading => isLoading
)

export const getUtilisationReportErrorSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.utilisationReport.error,
  error => error
)

export const getUtilisationReportWasLoadedSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.utilisationReport.wasLoaded,
  wasLoaded => wasLoaded
)

// create product
export const createProductIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.createProduct.isLoading,
  isLoading => isLoading
)
export const createProductWasCreatedSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.createProduct.wasCreated,
  wasCreated => wasCreated
)
export const createProductErrorSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.createProduct.error,
  error => error
)
// Update product
export const updateProductIdSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.updateProduct.id,
  id => id
)
export const updateProductSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.updateProduct.results,
  results => results
)
export const updateProductIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.updateProduct.isLoading,
  isLoading => isLoading
)
export const updateProductErrorSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.updateProduct.error,
  error => error
)
export const updateProductWasUpdatedSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.updateProduct.wasUpdated,
  wasUpdated => wasUpdated
)
// Delete product
export const deleteProductSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.deleteProduct,
  deleteProduct => deleteProduct
)
export const deleteProductIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.deleteProduct.isLoading,
  isLoading => isLoading
)
export const deleteProductErrorSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.deleteProduct.error,
  error => error
)
export const deleteProductWasDeletedSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.deleteProduct.wasDeleted,
  wasDeleted => wasDeleted
)
// get inventory
export const inventoryIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventory.isLoading,
  isLoading => isLoading
)
export const inventoryWasLoadedSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventory.wasLoaded,
  wasLoaded => wasLoaded
)
export const inventorySelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventory.results,
  results => results
)
export const inventoryErrorSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventory.error,
  error => error
)
export const inventoryNextSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventory.paging.next,
  results => results
)
export const requestedInventoryDateRangesSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventory.requestedDateRanges,
  requestedDateRanges => requestedDateRanges
)

export const fetchedInventoryDateRangesSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventory.fetchedDateRanges,
  fetchedDateRanges => fetchedDateRanges
)

export const lastFetchedPortionOfInventorySelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventory.lastFetchedPortion.results,
  results => results
)

export const lastFetchedPortionOfInventoryWasLoadedSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventory.lastFetchedPortion.wasLoaded,
  wasLoaded => wasLoaded
)

// update inventory
export const updateInventorySelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.updateInventory,
  updateInventory => updateInventory
)
export const updateInventoryIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.updateInventory.isLoading,
  isLoading => isLoading
)
export const updateInventoryErrorSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.updateInventory.error,
  error => error
)
// create inventory
export const createInventorySelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.createInventory,
  createInventory => createInventory
)
export const createInventoryIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.createInventory.isLoading,
  isLoading => isLoading
)
export const createInventoryErrorSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.createInventory.error,
  error => error
)
// Create batch inventory
export const updateBatchInventorySelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.updateBatchInventory.data,
  data => data
)
export const updateBatchInventoryIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.updateBatchInventory.isLoading,
  isLoading => isLoading
)
export const updateBatchInventoryErrorSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.updateBatchInventory.error,
  error => error
)
export const updateBatchInventoryWasUpdatedSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.updateBatchInventory.wasUpdated,
  wasUpdated => wasUpdated
)
// Delete inventory
export const deleteInventorySelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.deleteInventory,
  deleteInventory => deleteInventory
)
export const deleteInventoryIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.deleteInventory.isLoading,
  isLoading => isLoading
)
export const deleteInventoryErrorSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.deleteInventory.error,
  error => error
)
export const deleteInventoryWasLoadedSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.deleteInventory.wasDeleted,
  wasDeleted => wasDeleted
)

// Get inventory group
export const inventoryGroupSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventoryGroup.data,
  data => data
)

export const inventoryGroupDataSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventoryGroup.data.inventory,
  inventory => inventory || []
)

export const inventoryGroupProductsSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventoryGroup.data.products_list,
  products => (products ? [...products].sort((a, b) => a.name.localeCompare(b.name)) : [])
)

export const getInventoryGroupIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventoryGroup.isLoading,
  isLoading => isLoading
)

export const getInventoryGroupErrorSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventoryGroup.error,
  error => error
)

export const getInventoryGroupWasLoadedSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.inventoryGroup.wasLoaded,
  wasLoaded => wasLoaded
)

// Update products order
export const updateProductsOrderIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.updateProductsOrder.isLoading,
  isLoading => isLoading
)

// get media products as csv
export const getMediaProductsAsCsvSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.mediaProductsAsCsv.data,
  data => data
)

export const getMediaProductsAsCsvIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.mediaProductsAsCsv.isLoading,
  isLoading => isLoading
)

export const getMediaProductsAsCsvErrorSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.mediaProductsAsCsv.error,
  error => error
)

export const getMediaProductsAsCsvWasLoadedSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.mediaProductsAsCsv.wasLoaded,
  wasLoaded => wasLoaded
)

// Get media sub categories
export const mediaSubCategoriesSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.mediaSubCategories.results,
  results => results
)

export const getMediaSubCategoriesIsLoadingSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.mediaSubCategories.isLoading,
  isLoading => isLoading
)

export const getMediaSubCategoriesErrorSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.mediaSubCategories.error,
  error => error
)

export const mediaSubCategoriesWasLoadedSelector = createSelector(
  ({ mediaOrdersProducts }) => mediaOrdersProducts.mediaSubCategories.wasLoaded,
  wasLoaded => wasLoaded
)
