import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { lineItemSelector } from '../../../../../../modules/selectors/lineItems'
import { tikTokLanguagesList } from '../../../../../../constants/selectLists/languagesList'

const LanguagesSectionPreview = () => {
  const { t } = useTranslation()

  const { languages: lineItemLanguages } = useSelector(lineItemSelector)

  if (lineItemLanguages.length) {
    const filteredLanguages = tikTokLanguagesList.filter(item => lineItemLanguages.includes(item.value))

    return (
      <p>
        {filteredLanguages.map(language => {
          return <span key={language.value}>{language.label}</span>
        })}
      </p>
    )
  }

  return <p>{t('All languages')}</p>
}

export default LanguagesSectionPreview
