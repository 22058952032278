import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../components/Form'
import Field from '../../../../../components/Form/Field'

import {
  agencySelector,
  updateAgencyErrorSelector,
  updateAgencyIsLoadingSelector,
  updateAgencyWasUpdatedSelector
} from '../../../../../modules/selectors/agencies'
import { clearUpdateAgency, updateAgency } from '../../../../../modules/actions/agencies'

import { AGENCY_EDIT } from '../../../../../constants/forms'

import { validationSchema } from '../../validation'
import { NAME } from '../../fields'
import { getInitialValues } from './fields'
import { transformValuesToBE } from '../../formatters'

function AgencyEditForm() {
  const dispatch = useDispatch()

  const agency = useSelector(agencySelector)
  const { id: agencyId } = agency

  const agencyWasUpdated = useSelector(updateAgencyWasUpdatedSelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values)

      dispatch(updateAgency(transformedData, agencyId))
    },
    [dispatch, agencyId]
  )

  const clearHandler = useCallback(() => {
    dispatch(clearUpdateAgency())
  }, [dispatch])

  const initialValues = useMemo(() => {
    return getInitialValues(agency)
  }, [agency])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  return (
    <Form
      formik={formik}
      formName={AGENCY_EDIT}
      successSubmit={agencyWasUpdated}
      errorSelector={updateAgencyErrorSelector}
      isLoadingSelector={updateAgencyIsLoadingSelector}
      clearHandler={clearHandler}
    >
      <Field formik={formik} id={NAME} name={NAME} placeholder="Name" />
    </Form>
  )
}

export default AgencyEditForm
