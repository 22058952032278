import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import Table from '../../../../../../../../components/Table'

import { bookedRevenueReportSelector } from '../../../../../../../../modules/selectors/mediaOrdersBookings'

import { generateTableColumns } from './helpers'

import useRevenueTablesStyles from '../../../revenueTablesStyles'

const BookedRevenueTable = () => {
  const revenueTableClasses = useRevenueTablesStyles()

  const bookedRevenueReport = useSelector(bookedRevenueReportSelector)

  const formattedBookedRevenueReport = useMemo(() => {
    return bookedRevenueReport.map(categoryItem => {
      const columnsObject = {}
      categoryItem.items.forEach(columnData => {
        columnsObject[columnData.date_start] = columnData
      })

      return {
        ...categoryItem,
        name: categoryItem.breakdown_item || 'Uncategorized',
        columns: columnsObject,
        breakdown_item: categoryItem.breakdown_item
      }
    })
  }, [bookedRevenueReport])

  const cols = useMemo(
    () => [
      {
        fieldKey: 'name',
        headClassName: revenueTableClasses.revenueCol,
        className: classnames(revenueTableClasses.revenueCol, revenueTableClasses.nameCol)
      },
      ...generateTableColumns(revenueTableClasses)
    ],
    [revenueTableClasses]
  )

  const rowTemplate = useMemo(
    () => ({
      rowClassName: revenueTableClasses.revenueRow,
      headlineRowClassName: revenueTableClasses.revenueRow
    }),
    [revenueTableClasses]
  )

  return <Table cols={cols} rowTemplate={rowTemplate} data={formattedBookedRevenueReport} hideFooterRow />
}

export default BookedRevenueTable
