import { createStyles } from '../helpers'

import { black, darkGrey, mediumGrey, textGrey } from '../const/colors'
import { transitionShort } from '../const/common'
import { formFooter } from '../../components/Form/styles'

export const topDrawerPadding = 50
export const topDrawerMobilePadding = 66
export const bottomDrawerPadding = 120
const fieldMargin = 16

export const formContainerMaxWidth = 580

export const headerLimitWidth = 340

export const drawerContentPadding = `${topDrawerPadding}px 40px ${bottomDrawerPadding}px`
export const drawerContentMobilePadding = `${topDrawerMobilePadding}px 24px ${bottomDrawerPadding}px`

export const drawerFormTitle = {
  fontSize: 20,
  color: textGrey,
  maxWidth: headerLimitWidth,
  fontWeight: 600,
  marginBottom: 8
}

export const drawerFormDescription = {
  fontSize: 14,
  marginTop: 8,
  color: darkGrey,
  maxWidth: headerLimitWidth
}

export const formAdditionalElementsStyles = {
  '& .field, & p': {
    marginTop: 16
  }
}

export const drawerFormSection = theme => ({
  position: 'relative',
  padding: '16px 20px 18px',
  marginTop: 16,
  marginBottom: 16,
  backgroundColor: 'white',
  textAlign: 'left',
  border: {
    style: 'solid',
    width: 1,
    radius: theme.defaultBorderRadius,
    color: mediumGrey
  },
  '& .checkbox': {
    marginTop: 20,
    marginLeft: 0
  },
  '& > *:first-child': {
    marginTop: 0
  },

  ...formAdditionalElementsStyles
})

export const drawerFormSectionTitle = {
  fontSize: 16,
  fontWeight: 600,
  marginBottom: 16,
  marginTop: 24,
  '&:first-child': {
    marginTop: 0
  }
}

export default createStyles(theme => ({
  formTitle: {
    extend: drawerFormTitle
  },
  formDescription: {
    extend: drawerFormDescription
  },
  formFooter: {
    extend: formFooter
  },
  afterFormText: {
    fontSize: 14,
    marginTop: 16
  },
  fieldsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: fieldMargin
  },
  section: {
    extend: drawerFormSection(theme)
  },
  sectionTitle: {
    extend: drawerFormSectionTitle
  },
  sectionSubtitle: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 16,
    marginBottom: 8
  },
  dateTimeInput: {
    marginTop: 12,
    maxWidth: 216,
    position: 'relative'
  },
  sectionPreview: {
    '& textarea': {
      color: textGrey,
      padding: 0,
      fontSize: 16
    },
    '& > p, & > div': {
      marginTop: 16,
      lineHeight: 1.2,
      fontSize: 14,
      '& span': {
        display: 'inline-block',
        width: '100%'
      },
      '&:first-child': {
        marginTop: 12
      }
    }
  },
  sectionPreviewTitle: {
    marginBottom: 0
  },
  sectionIcon: {
    position: 'absolute',
    top: 11,
    right: 9,

    '& path': {
      transition: transitionShort
    },
    '&:hover': {
      cursor: 'pointer',

      '& path': {
        fill: darkGrey
      }
    }
  },
  deleteIcon: {
    extend: 'sectionIcon',
    right: 45
  },
  link: {
    color: 'inherit',

    '&:hover': {
      color: black
    }
  },
  subSection: {
    '&:not(:first-child)': {
      marginTop: 24
    }
  },
  sectionSelectBlock: {
    '& .field': {
      marginTop: '8px !important'
    }
  },
  sectionInfoIconWrapper: {
    textAlign: 'center',
    marginTop: 24,
    marginBottom: 24
  },
  sectionInfoStatusRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 16,
    '& > p:first-of-type': {
      margin: 0
    }
  },
  sectionInfoStatusIcon: {
    flexShrink: 0,
    marginRight: 12,
    width: 20,
    height: 20
  },
  checkboxesGrid: {
    display: 'grid',
    gridTemplateColumns: '80px 80px 80px',
    columnGap: 16,
    rowGap: 8,
    marginTop: 16,
    '& .checkbox': {
      marginTop: 0
    }
  }
}))
