import { useTranslation } from 'react-i18next'
import { getAdStructureLabel } from './helpers'

export const useTranslatedAdStructureLabel = platform => {
  const { t } = useTranslation()

  const adStructureLabel = getAdStructureLabel(platform)

  return t(adStructureLabel)
}
