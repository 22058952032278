import { createStyles } from '../../../../../styles/helpers'
import { phonesDown } from '../../../../../styles/const/breakpoints'

const useStyles = createStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 10,
    rowGap: 12
  },
  downloadButton: {
    minWidth: 131,
    maxWidth: 131
  },
  campaignsDateRangeWrapper: {
    width: 'fit-content'
  },

  [`@media screen and (${phonesDown})`]: {
    container: {
      width: '100%'
    },
    campaignsDateRangeWrapper: {
      width: '100%'
    }
  }
})

export default useStyles
