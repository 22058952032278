import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import LineItemGoogleCreateMainForm from './LineItemGoogleCreateMainForm'
import LineItemGoogleAudienceForm from './LineItemGoogleAudienceForm'

import useContinuousForm from '../../../../../hooks/formHooks/useContinuousForm'

import { LINE_ITEM_GOOGLE_ADS_CREATE } from '../../../../../constants/forms'

const MAIN_FORM = 'main_form'
const AUDIENCE_FORM = 'audience_form'

const LineItemGoogleCreateForm = ({ onSuccessSubmit, campaignId }) => {
  const [adGroupCreatedData, setAdGroupCreatedData] = useState({})

  const { activatedForm, setActivatedForm, setSuccessSubmit, successSubmit } = useContinuousForm({
    initialForm: MAIN_FORM,
    generalFormName: LINE_ITEM_GOOGLE_ADS_CREATE
  })

  const onSuccessMainFormSubmit = useCallback(
    adGroupData => {
      setAdGroupCreatedData(adGroupData)
      setActivatedForm(AUDIENCE_FORM)
    },
    [setActivatedForm]
  )

  useEffect(() => {
    if (successSubmit) {
      onSuccessSubmit(adGroupCreatedData)
    }
  }, [successSubmit, adGroupCreatedData, onSuccessSubmit])

  switch (activatedForm) {
    case AUDIENCE_FORM:
      return (
        <LineItemGoogleAudienceForm
          lineItemCreatedData={adGroupCreatedData}
          onSuccessSubmit={() => setSuccessSubmit(true)}
        />
      )
    case MAIN_FORM:
    default:
      return <LineItemGoogleCreateMainForm onSuccessSubmit={onSuccessMainFormSubmit} campaignId={campaignId} />
  }
}

LineItemGoogleCreateForm.propTypes = {
  onSuccessSubmit: PropTypes.func,
  campaignId: PropTypes.string.isRequired,
  cpmBid: PropTypes.string
}
export default LineItemGoogleCreateForm
