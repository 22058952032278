import React from 'react'
import PropTypes from 'prop-types'

import ActionButton from '../ActionButton'
import DescriptionText from '../DescriptionText'
import HeadlineText from '../HeadlineText'
import WebsiteText from './WebsiteText'
import FacebookSingleMediaAdThumbnail from '../FacebookSingleMediaAdThumbnail'

import {
  DESCRIPTIONS_LIST,
  FILE_HASH,
  FILE_ID,
  FILE_NAME,
  FILE_URL,
  HEADLINES_LIST,
  MEDIA_TYPE,
  SINGLE_MEDIA_ITEM,
  VIDEO_PICTURE
} from '../../../../../forms/Facebook/AdForms/fields'
import {
  CALL_TO_ACTION,
  CALL_TO_ACTION_OPTION
} from '../../../../../forms/ReusableFormFields/CallToActionFields/fields'

import useStyles from './styles'

function AdCardSingleContent({
  viewAdOnFacebook,
  elementInProgress,
  formikValues,
  typingAnimation,
  showSkeletonPlaceholders
}) {
  const singleMediaItem = formikValues[SINGLE_MEDIA_ITEM]

  // ACTION BUTTON
  const actionValue = singleMediaItem[CALL_TO_ACTION]
  const actionButtonInProgress = elementInProgress === CALL_TO_ACTION_OPTION

  // MEDIA
  const mediaType = singleMediaItem[MEDIA_TYPE]
  const mediaFile = {
    [MEDIA_TYPE]: formikValues[SINGLE_MEDIA_ITEM][MEDIA_TYPE],
    [FILE_NAME]: formikValues[SINGLE_MEDIA_ITEM][FILE_NAME],
    [FILE_URL]: formikValues[SINGLE_MEDIA_ITEM][FILE_URL],
    [FILE_HASH]: formikValues[SINGLE_MEDIA_ITEM][FILE_HASH],
    [VIDEO_PICTURE]: formikValues[SINGLE_MEDIA_ITEM][VIDEO_PICTURE],
    [FILE_ID]: formikValues[SINGLE_MEDIA_ITEM][FILE_ID]
  }

  const descriptionText = singleMediaItem[DESCRIPTIONS_LIST]?.[0]?.text
  const headlineText = singleMediaItem[HEADLINES_LIST]?.[0]?.text

  const classes = useStyles(mediaType)

  return (
    <>
      <FacebookSingleMediaAdThumbnail viewAdOnFacebook={viewAdOnFacebook} mediaFile={mediaFile} isInProgress={true} />
      <div className={classes.adFooter}>
        <div className={classes.footerInfo}>
          <div className={classes.footerWebsite}>
            <WebsiteText formikValues={formikValues} itemPath={SINGLE_MEDIA_ITEM} />
          </div>
          <HeadlineText
            headlineText={headlineText}
            className={classes.singleHeadlineText}
            skeletonClassName={classes.skeletonSingleHeadline}
            typingAnimation={typingAnimation}
            showSkeletonPlaceholder={showSkeletonPlaceholders}
          />
          <DescriptionText
            descriptionText={descriptionText}
            className={classes.singleDescriptionText}
            typingAnimation={typingAnimation}
            showSkeletonPlaceholder={showSkeletonPlaceholders}
          />
        </div>
        <ActionButton
          actionValue={actionValue}
          isInProgress={actionButtonInProgress}
          className={classes.singleContentActionButton}
        />
      </div>
    </>
  )
}

AdCardSingleContent.propTypes = {
  formikValues: PropTypes.object,
  elementInProgress: PropTypes.string,
  typingAnimation: PropTypes.bool,
  showSkeletonPlaceholders: PropTypes.bool
}

export default AdCardSingleContent
