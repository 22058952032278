import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import ProgressButton from './ProgressButton'

import useSuccessFormSubmit from './hooks'

import { setFormIsSubmitting } from '../../modules/actions/forms'

import { keys } from '../../helpers/common'
import { handleScrollToFieldWithError } from './helpers'

import useDrawerFormClasses from '../../styles/common/drawerForms'

function Form({
  children,
  className,
  formName,
  formik,
  submitText = 'Save',
  componentBeforeSubmit,
  componentAfterSubmit,
  successSubmit,
  errorSelector,
  clearHandler,
  isLoadingSelector,
  onErrorSubmit,
  onSuccessSubmit,
  showSubmit = true,
  buttonProps,
  formFooterClassName,
  activeStep,
  ...props
}) {
  const dispatch = useDispatch()
  const classes = useDrawerFormClasses()

  const { t } = useTranslation()

  const error = useSelector(errorSelector)
  const isLoading = useSelector(isLoadingSelector)

  const { setStatus, resetForm, handleSubmit, validateForm, status } = formik

  const serverErrors = status?.serverErrors

  const handleSuccessSubmit = useSuccessFormSubmit({ onSuccessSubmit, formName, resetForm })

  const handleFormSubmit = useCallback(
    e => {
      e.preventDefault()
      handleSubmit()

      validateForm().then(err => {
        const errArray = keys(err)
        if (errArray.length) {
          handleScrollToFieldWithError(formName, activeStep)
        }
      })
    },
    [handleSubmit, validateForm, formName, activeStep]
  )

  useEffect(() => {
    // keep tracking form loading state to be able controlling other logic from outside for example block drawer closing
    dispatch(setFormIsSubmitting(formName, isLoading))
  }, [dispatch, formName, isLoading])

  useEffect(
    () => {
      // Form errors handling doesn't support array errors, object expected only
      if (error && error.errors && !Array.isArray(error.errors)) {
        // BE errors sets to Formik status
        setStatus({ serverErrors: error.errors })
        // error callback
        onErrorSubmit && onErrorSubmit(error)
      }
    },
    // eslint-disable-next-line
    [setStatus, error]
  )

  // Scroll to field with server error
  useEffect(() => {
    if (serverErrors) {
      handleScrollToFieldWithError(formName, activeStep)
    }
  }, [serverErrors, formName, activeStep])

  return (
    <form {...props} className={className} onSubmit={handleFormSubmit} data-form-name={formName}>
      {children}
      {showSubmit && (
        <div className={classnames(classes.formFooter, formFooterClassName)}>
          {componentBeforeSubmit}
          <ProgressButton
            formError={error}
            isFormLoading={isLoading}
            successSubmit={successSubmit}
            clearHandler={clearHandler}
            onSuccessSubmit={handleSuccessSubmit}
            {...buttonProps}
          >
            {t(submitText)}
          </ProgressButton>
          {componentAfterSubmit}
        </div>
      )}
    </form>
  )
}

Form.propTypes = {
  formik: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  showSubmit: PropTypes.bool,
  submitText: PropTypes.string,
  componentBeforeSubmit: PropTypes.node,
  componentAfterSubmit: PropTypes.node,
  onErrorSubmit: PropTypes.func,
  successSubmit: PropTypes.bool,
  onSuccessSubmit: PropTypes.func,
  errorSelector: PropTypes.func.isRequired,
  isLoadingSelector: PropTypes.func.isRequired,
  clearHandler: PropTypes.func,
  buttonProps: PropTypes.object,
  formFooterClassName: PropTypes.string,
  activeStep: PropTypes.number
}

export default Form
