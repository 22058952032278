import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Can from '../../../../features/components/Can'
import FacebookInternalAdsList from './FacebookInternalAdsList'
import AdFacebookEdit from '../../../../forms/Facebook/AdForms/AdFacebookEdit'
import DisapproveInternalAdModal from '../../DisapproveInternalAdModal'

import useModalManage from '../../../../hooks/useModalManage'

import { clearAds } from '../../../../modules/actions/ads'

import { INTERNAL_ADS_PERMISSION } from '../../../../constants/permissions'

const FacebookInternalAdsSection = () => {
  const dispatch = useDispatch()

  const {
    isModalOpened: isDisapproveAdModalOpened,
    openModalHandler: disapproveAdOpenModalHandler,
    closeModalHandler: disapproveAdCloseModalHandler,
    modalData: disapproveAdModalData
  } = useModalManage({})

  useEffect(() => {
    return () => {
      dispatch(clearAds())
    }
  }, [dispatch])

  return (
    <>
      <FacebookInternalAdsList disapproveInternalAdHandler={disapproveAdOpenModalHandler} />
      <Can I="update" a={INTERNAL_ADS_PERMISSION}>
        <AdFacebookEdit shouldCheckForPlacementsPositionsLoading={false} isAdApprovalsPage={true} />
      </Can>
      <Can I="manage" a={INTERNAL_ADS_PERMISSION}>
        <DisapproveInternalAdModal
          isModalOpened={isDisapproveAdModalOpened}
          modalData={disapproveAdModalData}
          onClose={disapproveAdCloseModalHandler}
        />
      </Can>
    </>
  )
}

export default FacebookInternalAdsSection
