import * as Yup from 'yup'

import { PRODUCT_ID, PRODUCT_DATA } from '../ProductFields/fields'

export const PRODUCT_OPTION = 'product_option'
export const PRODUCT_OPTION_BRAND_CATEGORY_PAGES = 'product_option_brand_category_pages'
export const PRODUCT_OPTION_PRODUCT_PAGES = 'product_option_product_pages'

export const BRAND_CATEGORY_PAGE_ID = 'brand_category_page_id'
export const BRAND_CATEGORY_PAGE_DATA = 'brand_category_page_data'

export const initialBrandCategoryValues = {
  [BRAND_CATEGORY_PAGE_ID]: '',
  [BRAND_CATEGORY_PAGE_DATA]: {}
}

export const initialProductValues = {
  [PRODUCT_ID]: '',
  [PRODUCT_DATA]: {}
}

export const initialProductOrPagesValues = {
  [PRODUCT_OPTION]: PRODUCT_OPTION_BRAND_CATEGORY_PAGES,
  ...initialBrandCategoryValues,
  ...initialProductValues
}

const validateEitherProductOrPage = (path, errorMessage) => {
  return Yup.string().test({
    name: `${path} validation`,
    test: function (value) {
      const isProductSelected = this.parent[PRODUCT_ID]
      const isBrandCategoryPageSelected = this.parent[BRAND_CATEGORY_PAGE_ID]

      if (!isProductSelected && !isBrandCategoryPageSelected) {
        return this.createError({
          message: errorMessage,
          path: path
        })
      }

      return true
    }
  })
}

export const productOrPagesValidation = {
  [BRAND_CATEGORY_PAGE_ID]: validateEitherProductOrPage(BRAND_CATEGORY_PAGE_ID, 'Please select brand page'),
  [PRODUCT_ID]: validateEitherProductOrPage(PRODUCT_ID, 'Please select product')
}
