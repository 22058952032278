import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import TableDataLoader from '../../../../../components/Table/TableDataLoader'
import TikTokIdentitiesTableData from './TikTokIdentitiesTableData'

import {
  tikTokIdentitiesErrorSelector,
  tikTokIdentitiesIsLoadingSelector,
  tikTokIdentitiesSelector,
  tikTokIdentitiesWasLoadedSelector
} from '../../../../../modules/selectors/tikTokConstants'
import { selectedTikTokAdAccountIdSelector } from '../../../../../modules/selectors/app'
import { clearTikTokIdentities, getTikTokIdentities } from '../../../../../modules/actions/tikTokConstants'

import useCommonStyles from '../../../../../styles/common/listPage'

export const tikTokTableColumnsSize = {
  avatar: 50
}

const TikTokIdentitiesTable = () => {
  const dispatch = useDispatch()
  const tikTokIdentities = useSelector(tikTokIdentitiesSelector)

  const { t } = useTranslation()

  const commonClasses = useCommonStyles()

  const tikTokAdAccountId = useSelector(selectedTikTokAdAccountIdSelector)

  const tikTokIdentitiesColumnsSchema = useMemo(
    () => [
      {
        fieldKey: 'avatar',
        style: { maxWidth: tikTokTableColumnsSize.avatar }
      },

      {
        header: 'Name',
        fieldKey: 'name'
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => <div className={commonClasses.noDataContent}>{t('There are no TikTok identities')}</div>,
    [t, commonClasses.noDataContent]
  )

  useEffect(() => {
    dispatch(getTikTokIdentities({ account: tikTokAdAccountId }))
  }, [dispatch, tikTokAdAccountId])

  useEffect(
    () => () => {
      dispatch(clearTikTokIdentities())
    },
    [dispatch]
  )

  return (
    <TableDataLoader
      itemsLength={tikTokIdentities?.length}
      errorSelector={tikTokIdentitiesErrorSelector}
      wasLoadedSelector={tikTokIdentitiesWasLoadedSelector}
      isLoadingSelector={tikTokIdentitiesIsLoadingSelector}
      noDataContent={noDataContent}
      skeletonProps={{ cols: tikTokIdentitiesColumnsSchema }}
    >
      <TikTokIdentitiesTableData />
    </TableDataLoader>
  )
}

export default TikTokIdentitiesTable
