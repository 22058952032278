import * as Yup from 'yup'

import { endDateValidation, startDateValidation } from '../../ReusableFormFields/StartEndDateRangeFields/fields'
import { PRICE_PAIRS, pricePairsValidation } from '../../ReusableFormFields/PricePairFields/fields'
import { IN_STORE, OOH, PHYSICAL_SPACES } from '../../../constants/mediaOrders'
import { FILE_URL } from '../../../constants/files'

// name
export const NAME = 'name'
// category
export const CATEGORY = 'category'
// availability dates
export const AVAILABILITY_DATE_START = 'availability_date_start'
export const AVAILABILITY_DATE_END = 'availability_date_end'
// dates
export const DATE_START = 'date_start'
export const DATE_END = 'date_end'
// subtext
export const SUBTEXT = 'subtext'

// media items
export const MEDIA_CATEGORIES = 'media_categories'
export const MEDIA_PRODUCTS = 'media_products'
export const TAGS = 'tags'
export const ACCESS_ACCOUNTS = 'access_accounts'

export const categoriesWithAllowedPreselectedLocationFilter = [OOH, IN_STORE, PHYSICAL_SPACES]

export const reusableValidation = {
  [NAME]: Yup.string().required('Name required').max(255, 'Name should have maximum 255 characters'),
  [CATEGORY]: Yup.string().required('Package category required'),
  [AVAILABILITY_DATE_START]: startDateValidation,
  [AVAILABILITY_DATE_END]: endDateValidation,
  [FILE_URL]: Yup.string().required('Please upload an image'),
  [PRICE_PAIRS]: pricePairsValidation
}

export const mediaCategoriesValidation = Yup.array().min(1, 'Please select at least one category')
export const mediaProductsValidation = Yup.array().min(1, 'Please select at least one product')
