import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import usePermissions from '../../../../hooks/usePermissions'

import { insertIf } from '../../../../helpers/common'
import { redirectTo } from '../../../../helpers/url'

import { openForm } from '../../../../modules/actions/forms'
import { resendQuotation, updateQuotationsStatusBulk } from '../../../../modules/actions/quotations'
import { quotationsSelector } from '../../../../modules/selectors/quotations'

import { ROUTES } from '../../../../constants/routes'
import { PROPOSAL_REVIEW, QUOTATION_SEND } from '../../../../constants/forms'
import {
  MODIFY_QUOTATION_PRICING,
  QUOTATION_PERMISSION,
  SEND_QUOTATION_PERMISSION
} from '../../../../constants/permissions'

export default function useQuotationActions() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const quotations = useSelector(quotationsSelector)

  const permissions = usePermissions()
  const allowDelete = permissions.can('delete', QUOTATION_PERMISSION)
  const hasSendPermission = permissions.can('manage', SEND_QUOTATION_PERMISSION)
  const showModifyPricingOption = permissions.can('manage', MODIFY_QUOTATION_PRICING)

  const viewQuotationHandler = useCallback(
    (e, id) => {
      dispatch(openForm({ id, formName: PROPOSAL_REVIEW }))
    },
    [dispatch]
  )
  const sendQuotationHandler = useCallback(
    (e, itemId) => {
      dispatch(openForm({ formName: QUOTATION_SEND, id: itemId }))
    },
    [dispatch]
  )

  const deleteQuotationHandler = useCallback(
    (e, itemId) => {
      // contract delete is just status replacement to deleted
      dispatch(
        updateQuotationsStatusBulk({
          ids: [itemId],
          status: 'deleted'
        })
      )
    },
    [dispatch]
  )

  const resendQuotationHandler = useCallback(
    (e, itemId) => {
      dispatch(resendQuotation(itemId))
    },
    [dispatch]
  )

  const handleEditRedirect = useCallback((e, id) => {
    redirectTo(ROUTES.proposalsEdit, {
      id: id
    })
  }, [])

  const getDropdownOptions = useCallback(
    id => {
      const currentQuotation = quotations.find(({ id: quotationId }) => quotationId === id) || {}
      const {
        media_order: mediaOrder,
        quotation_status: quotationStatus,
        quotation_sent: quotationSent,
        quotation_receiver_email: quotationReceiverEmail,
        quotation_receiver_user: quotationReceiverUser
      } = currentQuotation

      // media order without_requirements is not possible to be send
      const isSendPossible = !mediaOrder?.without_requirements
      const quotationReceiver = quotationReceiverUser || quotationReceiverEmail
      const isExpired = quotationStatus === 'expired'
      const allowSend = isSendPossible && hasSendPermission

      const showResendOption =
        allowSend &&
        // We don't allow to resend expired quotation
        !isExpired &&
        // don't show resend quotation if the initial send quotation need be done. In this case it doesn't have receiver
        quotationReceiver

      return [
        ...insertIf(allowSend && !quotationSent, {
          text: t('Send'),
          onClickHandler: sendQuotationHandler
        }),
        ...insertIf(allowSend && showResendOption, {
          text: t('Resend'),
          onClickHandler: resendQuotationHandler
        }),
        {
          text: t('View'),
          onClickHandler: viewQuotationHandler
        },
        ...insertIf(showModifyPricingOption, {
          text: t('Edit'),
          onClickHandler: handleEditRedirect
        }),
        ...insertIf(allowDelete, {
          text: t('Delete'),
          onClickHandler: deleteQuotationHandler
        })
      ]
    },
    [
      quotations,
      hasSendPermission,
      t,
      sendQuotationHandler,
      resendQuotationHandler,
      viewQuotationHandler,
      showModifyPricingOption,
      handleEditRedirect,
      allowDelete,
      deleteQuotationHandler
    ]
  )

  return useMemo(
    () => ({
      handleEditRedirect,
      getDropdownOptions
    }),
    [getDropdownOptions, handleEditRedirect]
  )
}
