import { createStyles } from '../../../styles/helpers'

import { greyOutline } from '../../../styles/const/colors'

const useStyles = createStyles(theme => ({
  header: {
    paddingRight: 30,
    display: 'flex',
    justifyContent: 'space-between'
  },
  removeFileBtn: {
    color: greyOutline
  }
}))

export default useStyles
