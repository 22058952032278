import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import Form from '../../../../../components/Form'
import Field from '../../../../../components/Form/Field'
import CalendarCreateEventDates from '../CalendarCreateEventDates'
import ErrorMessage from '../../../../../components/Form/ErrorMessage'
import FileUploadInput from '../../../../../components/Form/FileUploadInput'
import ImagesPreview from './ImagesPreview'

import { getFormikFieldError } from '../../../../../helpers/errors'
import { formatOptionsList } from '../../../../../features/formatters'

import { eventColors } from '../../../../../constants/other'
import { NAME, ROW, COLOR, IMAGES } from './fields'

import useStyles from './styles'
import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

function CalendarEventForm({
  eventGroups,
  successSubmit,
  onSuccessSubmit,
  formName,
  formik,
  clearHandler,
  errorSelector,
  isLoadingSelector
}) {
  const { t } = useTranslation()

  const classes = useStyles()
  const drawerFormClasses = useDrawerFormStyles()

  const eventGroupsList = formatOptionsList({
    list: eventGroups,
    valueName: 'id',
    labelName: 'name'
  })

  const { values, setFieldValue, errors, status } = formik

  const images = values[IMAGES]

  const handleFileUpload = useCallback(
    file => {
      const newFiles = [...images, file]
      setFieldValue(IMAGES, newFiles)
    },
    [images, setFieldValue]
  )

  const fileRemove = useCallback(
    fileIndex => {
      const newFiles = images.filter((item, index) => index !== fileIndex)
      setFieldValue(IMAGES, newFiles)
    },
    [images, setFieldValue]
  )

  return (
    <Form
      formik={formik}
      formName={formName}
      errorSelector={errorSelector}
      isLoadingSelector={isLoadingSelector}
      successSubmit={successSubmit}
      onSuccessSubmit={onSuccessSubmit}
      clearHandler={clearHandler}
    >
      <section className={drawerFormClasses.section}>
        <h3 className={drawerFormClasses.sectionTitle}>{t('Name')}</h3>
        <Field label="Event Name" formik={formik} name={NAME} enableReinitialize />
        <Field label="Row" formik={formik} name={ROW} options={eventGroupsList} enableReinitialize />
      </section>
      <section className={drawerFormClasses.section}>
        <h4 className={drawerFormClasses.sectionTitle}>Dates</h4>
        <CalendarCreateEventDates formik={formik} />
      </section>
      <section className={drawerFormClasses.section}>
        <h4 className={drawerFormClasses.sectionTitle}>{t('Colour')}</h4>
        <p>{t('The colour selected here will show on the border of the event on the planner.')}</p>
        <div className={classes.colorsContainer}>
          {eventColors.map(color => {
            return (
              <div
                key={color}
                className={classnames(classes.colorPicker, { [classes.isActive]: values[COLOR] === color })}
                style={{ backgroundColor: color }}
                onClick={() => setFieldValue(COLOR, color)}
              />
            )
          })}
        </div>
        {getFormikFieldError(errors, status, COLOR).error && (
          <ErrorMessage error={getFormikFieldError(errors, status, COLOR).error} />
        )}
      </section>
      <section className={drawerFormClasses.section}>
        <h4 className={drawerFormClasses.sectionTitle}>{t('Images (optional)')}</h4>
        <p>
          {t('You can upload images here, and we will display up to 3 of these as thumbnails on the planner view.')}
        </p>
        <p>{t('You can download the full size images from this page also.')}</p>
        {!!images.length && (
          <div className={classes.mediaLibrary}>
            <ImagesPreview images={images} fileRemove={fileRemove} />
          </div>
        )}
        <FileUploadInput onFileUpload={handleFileUpload} name={IMAGES} />
      </section>
    </Form>
  )
}

export default CalendarEventForm
