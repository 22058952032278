import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import Form from '../../../../../components/Form'
import Field from '../../../../../components/Form/Field'
import FieldsSection from '../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../features/components/Form/FieldsSection/FieldRow'
import MultiSelectBox from '../../../../../features/components/Form/MultiSelectBox'

import { clearCreateAgencyInvite, createAgencyInvite } from '../../../../../modules/actions/agencyTeam'
import {
  agencyInviteCreateErrorSelector,
  agencyInviteCreateIsLoadingSelector,
  agencyInviteWasCreatedSelector
} from '../../../../../modules/selectors/agencyTeam'
import {
  currentUserSelfAccountsListSelector,
  selectedSelfAccountDataSelector
} from '../../../../../modules/selectors/app'

import { AGENCY_INVITE_CREATE } from '../../../../../constants/forms'

import { initialValues, EMAIL, ROLE, SELF_ACCOUNTS, roleOptions } from './fields'
import { validationSchema } from './validation'
import { MANAGER, REPORT } from '../../../../../constants/permissions'

import useCommonStyles from '../../../../../styles/common/stepForms'

import useStyles from './styles'

const AgencyInviteCreateForm = ({ agency }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const commonClasses = useCommonStyles()

  const inviteWasCreated = useSelector(agencyInviteWasCreatedSelector)
  const selectedSelfAccountData = useSelector(selectedSelfAccountDataSelector)
  const currentUserSelfAccountsList = useSelector(currentUserSelfAccountsListSelector)
  const agencyId = selectedSelfAccountData.agency

  const selfAccountsForCurrentAgency = useMemo(() => {
    return currentUserSelfAccountsList.filter(account => account.agency === agencyId)
  }, [currentUserSelfAccountsList, agencyId])

  const handleSubmit = values => {
    const { email, role, accounts } = values
    const allowSelfAccounts = role === MANAGER || role === REPORT

    dispatch(
      createAgencyInvite({
        email,
        agency,
        role,
        ...(allowSelfAccounts && { [SELF_ACCOUNTS]: accounts.map(account => account.id) })
      })
    )
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: handleSubmit
  })

  const clearCreateAgencyInviteHandler = useCallback(() => {
    dispatch(clearCreateAgencyInvite())
  }, [dispatch])

  const { values, errors, setFieldValue } = formik
  const allowSelfAccounts = values[ROLE] === MANAGER || values[ROLE] === REPORT

  return (
    <Form
      formik={formik}
      formName={AGENCY_INVITE_CREATE}
      errorSelector={agencyInviteCreateErrorSelector}
      isLoadingSelector={agencyInviteCreateIsLoadingSelector}
      successSubmit={inviteWasCreated}
      clearHandler={clearCreateAgencyInviteHandler}
    >
      <div className={commonClasses.stepBody}>
        <FieldsSection title={t('User email')}>
          <FieldRow title="Email address" description="This user will receive an email inviting them to this agency.">
            <Field
              formik={formik}
              name={EMAIL}
              label="email address"
              autoComplete="off"
              placeholder={t('email address')}
            />
          </FieldRow>
          <FieldRow
            title={t('Role')}
            description={
              <>
                <div className={classes.roleDescription}>
                  <b>{t('Admin')}</b>
                  {t(': Full access')}
                </div>
                <div className={classes.roleDescription}>
                  <b>{t('Manager')}</b>
                  {t(': Full campaign management access')}
                </div>
                <div className={classes.roleDescription}>
                  <b>{t('Reporting')}</b>
                  {t(': Reporting only')}
                </div>
              </>
            }
          >
            <Field placeholder={t('Select Role')} formik={formik} name={ROLE} options={roleOptions} />
          </FieldRow>
          {allowSelfAccounts && (
            <FieldRow
              title={t('Account(s) access')}
              description={t('Please add this manager to at least one client account')}
            >
              <MultiSelectBox
                placeholder={t('Select account')}
                name={SELF_ACCOUNTS}
                options={selfAccountsForCurrentAgency}
                value={values[SELF_ACCOUNTS]}
                setFieldValue={setFieldValue}
                error={errors[SELF_ACCOUNTS]}
                touched
              />
            </FieldRow>
          )}
        </FieldsSection>
      </div>
    </Form>
  )
}

export default AgencyInviteCreateForm
