import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  warningMessageSection: {
    border: 'none'
  },
  warningMessageDescription: {
    textAlign: 'center'
  }
})

export default useStyles
