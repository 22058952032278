import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import InfoModal from '../../../../../features/components/Modals/InfoModal'
import SetupTOTPTokenForm from './SetupTOTPTokenForm'

import useStyles from './styles'

const SetupTOTPTokenModal = ({ isModalOpened, onClose, onSuccessSubmitHandler }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const onSuccessSubmit = () => {
    onClose()

    onSuccessSubmitHandler()
  }

  return (
    <InfoModal
      isOpen={isModalOpened}
      onClose={onClose}
      contentClassName={classes.contentContainer}
      modalTitle={`🔒 ${t('Register your authenticator app')}`}
      ModalMessage={
        <p className={classes.modalMessage}>
          {t(
            'Open your authenticator app and scan the QR code (or manually enter the token) in the app. Use Google Authenticator, Microsoft Authenticator or any other authenticator app.'
          )}
        </p>
      }
    >
      <SetupTOTPTokenForm onSuccessSubmitHandler={onSuccessSubmit} />
    </InfoModal>
  )
}

SetupTOTPTokenModal.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccessSubmitHandler: PropTypes.func.isRequired
}

export default SetupTOTPTokenModal
