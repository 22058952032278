import * as Yup from 'yup'

import { nameValidation } from '../../../../../../pages/Settings/AdvertiserAccounts/ClientAccountForms/fields'
import { PRICE_PAIRS, pricePairsValidation } from '../../../../../ReusableFormFields/PricePairFields/fields'
import { imagesValidation } from '../../sections/ImagesSection/validation'

export const validationSchema = Yup.object({
  ...nameValidation,
  [PRICE_PAIRS]: pricePairsValidation,
  ...imagesValidation
})
