import React from 'react'
import { useTranslation } from 'react-i18next'

const CustomLocationsPreview = ({ title = 'Custom locations: ', customLocations }) => {
  const { t } = useTranslation()

  if (customLocations && customLocations.length) {
    return (
      <span>
        {t(title)}
        {customLocations
          .slice(0, 10)
          .map(({ name }) => name)
          .join(', ')}
        {customLocations.length > 10 &&
          t('additionalLocationsDisplay', { excessLocationsCount: customLocations.length - 10 })}
      </span>
    )
  }

  return null
}

export default CustomLocationsPreview
