import {
  formatVariablesToBE,
  getFormattedProducts
} from '../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/formatters/productsFormatters'
import {
  calculateDiscountedPrice,
  calculateTotalOrderPrice,
  getDecimalDiscount
} from '../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/price'

import { REPRESENTATIVE } from '../../../../../constants/mediaOrders'
import { PRODUCT_PERIODS_DATES, QUANTITY } from '../../fields'
import { VARIABLES } from '../../../MediaProductForms/fields'
import { formatDateShort } from '../../../../../constants/dates'
import { BRAND, CAMPAIGN_NAME } from '../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/fields'
import { CUSTOM_DISCOUNT, MEDIA_PRODUCTS, MODIFIED_PRODUCTS } from './fields'
import {
  determineDiscountsFromProducts,
  DISCOUNT_PERCENTAGE
} from '../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/DiscountsContext/helpers'

export const getFormattedContractData = ({
  hasEditPricingPermissions,
  values,
  selfAccountRepresentative,
  selectedSelfAccountId,
  controllerId,
  selectedCurrency
}) => {
  let totalOrderPrice
  let formattedProducts

  if (hasEditPricingPermissions) {
    // workaround to fix formatting
    const modifiedProducts = values[MODIFIED_PRODUCTS]
    const customDiscount = getDecimalDiscount(values[CUSTOM_DISCOUNT])
    const productsDiscount = getModifiedProductsDiscount(modifiedProducts, customDiscount)

    formattedProducts = formatModifiedProducts(modifiedProducts, customDiscount, selfAccountRepresentative)
    totalOrderPrice = calculateTotalOrderPrice(modifiedProducts, productsDiscount)
  } else {
    const productsDiscount = determineDiscountsFromProducts(values[MEDIA_PRODUCTS])
    const selectedProducts = values[MEDIA_PRODUCTS]
    totalOrderPrice = calculateTotalOrderPrice(selectedProducts, productsDiscount)
    formattedProducts = formatProducts({
      selectedProducts,
      productsDiscount,
      selfAccountRepresentative
    })
  }

  return {
    name: 'Media Booking - ' + formatDateShort(new Date()),
    account: selectedSelfAccountId,
    controller: controllerId,
    cost: Number(totalOrderPrice.regularPrice),
    cost_with_discount: Number(totalOrderPrice.discountedPrice),
    currency: selectedCurrency?.code,
    [BRAND]: values[BRAND],
    [CAMPAIGN_NAME]: values[CAMPAIGN_NAME],
    [MEDIA_PRODUCTS]: formattedProducts
  }
}

export const getModifiedProductsDiscount = (modifiedProducts, customDiscount) => {
  return modifiedProducts.map(productValues => {
    // mock-up productsDiscount structure according to determineDiscountsFromProducts
    const productId = productValues.data.id
    return {
      id: productId,
      [DISCOUNT_PERCENTAGE]: customDiscount || 0
    }
  })
}

export const formatProducts = ({ selectedProducts, productsDiscount, selfAccountRepresentative }) => {
  const preFormattedProducts = selectedProducts.map(productValues => ({
    ...productValues,
    discount: productsDiscount.find(discount => discount.id === productValues.data.id)?.discountPercentage,
    // add representative to each product if any, order should be mediaPackageRepresentative -> selfAccountRepresentative
    [REPRESENTATIVE]: selfAccountRepresentative || ''
  }))

  return getFormattedProducts(preFormattedProducts)
}

export const formatModifiedProducts = (modifiedProducts, customDiscount, selfAccountRepresentative) => {
  return modifiedProducts.map(productValues => {
    const productData = productValues.data
    const selectedPeriods = productValues[PRODUCT_PERIODS_DATES]

    const periodsDatesPrice = selectedPeriods.map(({ inventory, price, value }) => ({
      inventory,
      date_start: value,
      // we send the price per 1 period, BE will calculate the total price based on the quantity
      price: calculateDiscountedPrice(price, 1, customDiscount)
    }))

    return {
      media_product: productData.id,
      discount: customDiscount || 0,
      original_product_name: productData.name,
      periods_price: periodsDatesPrice,
      [QUANTITY]: productValues[QUANTITY],
      [REPRESENTATIVE]: selfAccountRepresentative || '',
      [VARIABLES]: formatVariablesToBE(productValues[VARIABLES])
    }
  })
}
