import React from 'react'

import AgePreview from './AgeContent/AgePreview'
import GenderPreview from './GenderContent/GenderPreview'
import InterestsPreview from './InterestsContent/InterestsPreview'
import CategoriesPreview from './CategoriesContent/CategoriesPreview'

const AudienceSectionPreview = () => {
  return (
    <p>
      <AgePreview />
      <GenderPreview />
      <InterestsPreview />
      <CategoriesPreview />
    </p>
  )
}

export default AudienceSectionPreview
