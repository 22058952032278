import React from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'

import MaxLengthMessage from '../../../../features/components/MaxLengthMessage'
import FieldTextSuggestionsWrapper from '../FieldTextSuggestionsWrapper'

const FieldPortalContent = ({ value, maxLength, portalElement, textSuggestionsProps, onSelectTextSuggestion }) => {
  return createPortal(
    <>
      {maxLength && <MaxLengthMessage maxLength={maxLength} length={value.length} />}
      {textSuggestionsProps && onSelectTextSuggestion && (
        <FieldTextSuggestionsWrapper
          suggestionsSelector={textSuggestionsProps.suggestionsSelector}
          suggestionsLoadingSelector={textSuggestionsProps.suggestionsLoadingSelector}
          onSelectSuggestion={onSelectTextSuggestion}
          value={value}
        />
      )}
    </>,
    portalElement
  )
}

FieldPortalContent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.number,
  portalElement: PropTypes.object.isRequired,
  textSuggestionsProps: PropTypes.object,
  onSelectTextSuggestion: PropTypes.func
}

export default FieldPortalContent
