import { createStyles } from '../../../styles/helpers'
import { grey, lightGrey2, red } from '../../../styles/const/colors'
import { zIndex10 } from '../../../styles/const/common'

const useStyles = createStyles({
  media: {
    width: 'calc(100% / 3 - 7px)',
    marginRight: 7,
    marginBottom: 7,
    paddingTop: 'calc(100% / 3 - 7px)',
    backgroundColor: lightGrey2,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    borderRadius: 2,
    position: 'relative',
    cursor: onMediaClick => !!onMediaClick && 'pointer'
  },
  overlay: {
    position: 'absolute',
    top: 5,
    right: 5,
    backgroundColor: 'white',
    padding: 3,
    border: `1px solid ${grey}`,
    borderRadius: 2,
    fontSize: 8
  },
  deleteBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'transparent',
    width: 22,
    height: 22,
    padding: 5,
    zIndex: zIndex10,

    '&:hover path': {
      fill: red
    }
  }
})

export default useStyles
