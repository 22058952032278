import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Loader from '../../Loaders/Loader'
import Button from '../../Button'

import { ReactComponent as CrossIcon } from '../../../assets/icons/cross.svg'
import { ReactComponent as CheckmarkIcon } from '../../../assets/icons/checkmark.svg'

import useStyles from './styles'

const defaultStatus = ''

function ProgressButton({
  formError,
  isFormLoading,
  wrapperClassName,
  className,
  iconClassName,
  transparent,
  children,
  successSubmit,
  onErrorSubmit,
  onSuccessSubmit,
  clearHandler,
  // this prop for changing from submit button to common with action
  onClick,
  // this prop for showing button inside InputField
  disabled = false,
  solid = true,
  // value is taken via cloneElement
  value,
  ...props
}) {
  const { t } = useTranslation()

  const [buttonState, setButtonState] = useState(defaultStatus)
  const isLoading = buttonState === 'loading'
  const success = buttonState === 'success'
  const error = buttonState === 'error'

  const classes = useStyles({
    isLoading,
    success,
    error
  })

  useEffect(() => {
    if (successSubmit) {
      setButtonState('success')
    }
  }, [successSubmit])

  useEffect(() => {
    if (isFormLoading) {
      setButtonState('loading')
    }
  }, [isFormLoading])

  useEffect(() => {
    if (formError && formError.code) {
      setButtonState('error')
    }
  }, [formError])

  useEffect(() => {
    if (buttonState === 'success' || buttonState === 'error') {
      // show success state for shorter period of time to call onSuccessSubmit asap
      const resetDelay = buttonState === 'success' ? 750 : 1500
      const timer = setTimeout(() => {
        setButtonState(defaultStatus)
        buttonState === 'error' && onErrorSubmit && onErrorSubmit()
        buttonState === 'success' && onSuccessSubmit && onSuccessSubmit()
        clearHandler && clearHandler()
      }, resetDelay)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [buttonState, onErrorSubmit, onSuccessSubmit, clearHandler])

  const handleOnClick = () => {
    if (onClick) {
      if (value) {
        onClick(value)
      } else {
        onClick()
      }
    }
  }

  return (
    <div className={classnames(classes.pbContainer, wrapperClassName)}>
      <Button
        className={classnames(classes.pbButton, className)}
        type={onClick ? 'button' : 'submit'}
        // during loading set button styles to solid so it always have colored background
        solid={isLoading ? true : solid}
        onClick={handleOnClick}
        // During the update and after a success, while the CheckmarkIcon is displayed,
        // the button remains clickable and it can be clicked by the user, causing a delay in the item's status display.
        // Disabled button when updating and after success update.
        disabled={disabled || success || isLoading}
        {...props}
      >
        <div
          className={classes.text}
          style={{
            // jss library works not correctly with conditional styles, so we need to use inline styles
            opacity: isLoading || success || error ? 0 : 1
          }}
        >
          {t(children)}
        </div>
        <div className={classnames(classes.icon, iconClassName)}>
          {success && <CheckmarkIcon />}
          {error && <CrossIcon />}
        </div>
        <Loader show={isLoading} />
      </Button>
    </div>
  )
}

ProgressButton.propTypes = {
  formStatus: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
  formError: PropTypes.object,
  isFormLoading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  successSubmit: PropTypes.any,
  onErrorSubmit: PropTypes.func,
  onSuccessSubmit: PropTypes.func,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  transparent: PropTypes.bool,
  onClick: PropTypes.func,
  solid: PropTypes.bool,
  danger: PropTypes.bool,
  clearHandler: PropTypes.func,
  iconClassName: PropTypes.string
}

export default ProgressButton
