import React from 'react'
import classnames from 'classnames'
import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

const SkeletonContentTabs = () => {
  const classes = useStyles()

  const SkeletonTab = ({ isSelected }) => (
    <div
      className={classnames(classes.tabItem, {
        selected: isSelected
      })}
    >
      <Skeleton width={120} />
    </div>
  )

  return (
    <div className={classes.tabsWrapper}>
      <div className={classes.tabsContainer}>
        <div className={classes.tabsRow}>
          <SkeletonTab isSelected />
          <SkeletonTab />
          <SkeletonTab />
          <SkeletonTab />
        </div>
      </div>
    </div>
  )
}

export default SkeletonContentTabs
