import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import classnames from 'classnames'
import Skeleton from 'react-loading-skeleton'
import { getIn } from 'formik'

import ButtonIcon from '../../Buttons/ButtonIcon'
import ActionText from '../../../../components/ActionText'

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-grey.svg'

import useStyles from './styles'

const DefaultSkeletonRowComponent = () => {
  return <Skeleton height={38} />
}

const defaultEmptyObject = {}

// this component allow to pass flexible template of fields, which is represented in rows structure
const MultiRowFields = ({
  formik,
  FieldsComponent,
  initialValueTemplate = defaultEmptyObject,
  mainValueName,
  subValueNames,
  getFieldsComponentProps,
  fieldsComponentProps = defaultEmptyObject,
  addNewRowText = 'Add new row',
  rowClassName,
  minRows = 1,
  loadMoreHandler,
  loadMoreText = 'Load more',
  isMoreItemsLoading,
  allowAddMore = true,
  SkeletonRowComponent = DefaultSkeletonRowComponent
}) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const { values, setFieldValue, setFieldTouched } = formik

  const mainValue = getIn(values, mainValueName)

  const showRemoveRowButton = mainValue?.length !== minRows

  const addNewRowHandler = useCallback(() => {
    const newMainValue = [...mainValue, { ...initialValueTemplate, id: uuidv4() }]

    setFieldValue(mainValueName, newMainValue)
  }, [initialValueTemplate, mainValue, mainValueName, setFieldValue])

  const removeRowHandler = useCallback(
    (id, index) => {
      const filteredMainValue = mainValue.filter(item => item.id !== id)

      // reset touched fields for this index
      subValueNames?.forEach(subValueName => {
        setFieldTouched(`${mainValueName}[${index}].${subValueName}`, false)
      })

      setFieldValue(mainValueName, filteredMainValue)
    },
    [mainValue, subValueNames, setFieldValue, mainValueName, setFieldTouched]
  )

  return (
    <div className={classes.fieldsContainer}>
      {mainValue?.map((item, index) => {
        const itemFieldsComponentProps = getFieldsComponentProps
          ? getFieldsComponentProps(item, index)
          : fieldsComponentProps

        return (
          <div className={classnames(classes.fieldsRow, rowClassName)} key={item.id}>
            <FieldsComponent formik={formik} index={index} {...itemFieldsComponentProps} />
            {showRemoveRowButton && (
              <ButtonIcon
                onClick={() => removeRowHandler(item.id, index)}
                className={classes.deleteRowButton}
                Icon={CloseIcon}
              />
            )}
          </div>
        )
      })}
      {loadMoreHandler && !isMoreItemsLoading && (
        <ActionText className={classes.loadMoreButton} onClick={loadMoreHandler}>
          {t(loadMoreText)}
        </ActionText>
      )}
      {isMoreItemsLoading && <SkeletonRowComponent />}
      {allowAddMore && (
        <ActionText className={classes.addNewRowButton} onClick={addNewRowHandler}>
          {t(addNewRowText)}
        </ActionText>
      )}
    </div>
  )
}

MultiRowFields.propTypes = {
  formik: PropTypes.object.isRequired,
  FieldsComponent: PropTypes.func.isRequired,
  initialValueTemplate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  mainValueName: PropTypes.string.isRequired,
  subValueNames: PropTypes.arrayOf(PropTypes.string),
  getFieldsComponentProps: PropTypes.func,
  fieldsComponentProps: PropTypes.object,
  addNewRowText: PropTypes.string,
  rowClassName: PropTypes.string,
  minRows: PropTypes.number,
  loadMoreHandler: PropTypes.func,
  loadMoreText: PropTypes.string,
  isMoreItemsLoading: PropTypes.bool,
  allowAddMore: PropTypes.bool,
  SkeletonRowComponent: PropTypes.func
}

export default MultiRowFields
