import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

const PageSectionHeader = ({ id, title, titleIsLoading, className, LeftPanel, RightPanel }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <div id={id} className={classnames(classes.sectionHeaderContainer, className)}>
      {title && <h2 className={classes.title}>{titleIsLoading ? <Skeleton width={160} /> : t(title)}</h2>}
      <div className={classes.panels} data-html2canvas-ignore>
        {LeftPanel && <div className={classes.leftPanel}>{LeftPanel}</div>}
        {RightPanel && <div className={classes.rightPanel}>{RightPanel}</div>}
      </div>
    </div>
  )
}

PageSectionHeader.propTypes = {
  title: PropTypes.string,
  titleIsLoading: PropTypes.bool,
  className: PropTypes.string,
  CreateFormButtonComponent: PropTypes.node,
  TabsComponent: PropTypes.node
}

export default PageSectionHeader
