import { useSelector } from 'react-redux'

import { PLATFORM_LABELS } from '../../../constants/selectLists/platformList'
import { CONTROLLER_TYPE } from '../../../constants/permissions'
import { userSelfAccountTypeSelector } from '../../../modules/selectors/app'

export function useGetAuthFailureText(platform) {
  const userAccountType = useSelector(userSelfAccountTypeSelector)

  if (userAccountType === CONTROLLER_TYPE) {
    return `Please authenticate with ${PLATFORM_LABELS[platform]} to upload ad creative.`
  } else {
    return `Please contact your account manager to access ${PLATFORM_LABELS[platform]} ad creative upload.`
  }
}
