import { DIGITAL, EDM, IN_STORE, OOH, PHYSICAL_SPACES, PRINT } from '../../../../../../../../constants/mediaOrders'

export const getStepTitle = categoryName => {
  switch (categoryName) {
    case PRINT:
      return 'Select a print media product'
    case OOH:
      return 'Select a OOH media product'
    case EDM:
      return 'Please select a edm media product'
    case IN_STORE:
      return 'Please select a in-store media product'
    case PHYSICAL_SPACES:
      return 'Please select a promotional spaces media product'
    case DIGITAL:
      return 'Please select a digital media product'
    default:
      // no category name
      return 'Please select a media product'
  }
}
