import {
  addDays,
  addMonths,
  addWeeks,
  format,
  getDate,
  getYear,
  isMonday,
  nextMonday,
  parseISO,
  setYear,
  startOfMonth
} from 'date-fns'

import { DATES_FORMAT_BE, formatDateFullYear, formatDateShort } from '../../../../../../../constants/dates'
import {
  PERIOD_DAY,
  PERIOD_FIVE_DAY,
  PERIOD_FOUR_DAY,
  PERIOD_ONE_MONTH,
  PERIOD_ONE_WEEK,
  PERIOD_SIX_DAY,
  PERIOD_TEN_DAY,
  PERIOD_THREE_DAY,
  PERIOD_THREE_MONTH,
  PERIOD_TWO_DAY,
  PERIOD_TWO_MONTH,
  PERIOD_TWO_WEEKS,
  periodDayValues
} from '../../../../../../../constants/mediaOrders'

export const getProductStartDate = selectedProduct => {
  return addDays(new Date(), selectedProduct?.creative_deadline_days || 0)
}

export function getInventoryPeriod(date, period) {
  switch (period) {
    case PERIOD_ONE_WEEK:
      return getWeekPeriod(date, 1)
    case PERIOD_TWO_WEEKS:
      return getWeekPeriod(date, 2)
    case PERIOD_ONE_MONTH:
      return getMonthPeriod(date)
    case PERIOD_TWO_MONTH:
      return getTwoMonthsPeriod(date)
    case PERIOD_THREE_MONTH:
      return getThreeMonthsPeriod(date)
    case PERIOD_DAY:
    case PERIOD_TWO_DAY:
    case PERIOD_THREE_DAY:
    case PERIOD_FOUR_DAY:
    case PERIOD_FIVE_DAY:
    case PERIOD_SIX_DAY:
    case PERIOD_TEN_DAY:
    default:
      return getDaysPeriod(date, period)
  }
}

export const getProductStartPeriod = (productPeriod, productStartDate) => {
  switch (productPeriod) {
    case PERIOD_ONE_WEEK:
    case PERIOD_TWO_WEEKS:
      // the earliest {Period start date} should be the first Monday productStartDate
      return isMonday(productStartDate) ? productStartDate : nextMonday(productStartDate)
    case PERIOD_ONE_MONTH:
    case PERIOD_TWO_MONTH:
    case PERIOD_THREE_MONTH:
      const dayInMonth = getDate(productStartDate)
      const isFirstDayInMonth = dayInMonth === 1

      // the earliest {Month name} should be the first full month from productStartDate
      return isFirstDayInMonth ? productStartDate : startOfMonth(addMonths(productStartDate, 1))
    case PERIOD_DAY:
    case PERIOD_TWO_DAY:
    case PERIOD_THREE_DAY:
    case PERIOD_FOUR_DAY:
    case PERIOD_FIVE_DAY:
    case PERIOD_SIX_DAY:
    case PERIOD_TEN_DAY:
    default:
      return productStartDate
  }
}

// WEEK
export const getEndWeekPeriodDate = (startDate, weeksInPeriod) => {
  if (typeof startDate === 'string') {
    startDate = parseISO(startDate)
  }
  // remove one day so we have period Mon-Sun instead of Mon-Mon
  return addWeeks(addDays(startDate, -1), weeksInPeriod)
}

export const getWeekPeriod = (date, weeksInPeriod) => {
  const startPeriod = formatDateFullYear(date)
  const endPeriod = formatDateFullYear(getEndWeekPeriodDate(date, weeksInPeriod))

  return {
    label: `${startPeriod} - ${endPeriod}`,
    // DATES_FORMAT_BE used to avoid additional formatting during form submit
    value: format(date, DATES_FORMAT_BE)
  }
}

// MONTH
export const getMonthName = date => {
  return format(date, 'MMMM')
}

export const generateMonths = ({ startMonth, startYear, monthCountStep = 1 }) => {
  const months = []

  for (let i = 0; i < 12; i += monthCountStep) {
    // set day of month to 1st
    months.push(addMonths(setYear(new Date(startMonth).setDate(1), startYear), i + startMonth))
  }

  return months
}

export const getMonthPeriod = date => {
  return {
    label: `${getMonthName(date)} ${getYear(date)}`,
    // DATES_FORMAT_BE used to avoid additional formatting during form submit
    value: format(date, DATES_FORMAT_BE)
  }
}

// TWO MONTHS
export const getEndMonthPeriodDate = (startDate, monthsDifferenceAmount) => {
  if (typeof startDate === 'string') {
    startDate = parseISO(startDate)
  }

  return addMonths(startDate, monthsDifferenceAmount)
}

export const getTwoMonthsPeriod = date => {
  const secondMonthDate = getEndMonthPeriodDate(date, 1)

  const startDate = `${getMonthName(date)} ${getYear(date)}`
  const endDate = `${getMonthName(secondMonthDate)} ${getYear(secondMonthDate)}`

  return {
    label: `${startDate} - ${endDate}`,
    // DATES_FORMAT_BE used to avoid additional formatting during form submit
    value: format(date, DATES_FORMAT_BE)
  }
}

export const getThreeMonthsPeriod = date => {
  const thirdMonthDate = addMonths(date, 2)

  const startDate = `${getMonthName(date)} ${getYear(date)}`
  const endDate = `${getMonthName(thirdMonthDate)} ${getYear(thirdMonthDate)}`

  return {
    label: `${startDate} - ${endDate}`,
    // DATES_FORMAT_BE used to avoid additional formatting during form submit
    value: format(date, DATES_FORMAT_BE)
  }
}

export const getDaysPeriod = (date, period) => {
  const amountOfDays = periodDayValues[period]

  const currentDate = new Date(date)

  const endDate = addDays(currentDate, amountOfDays - 1)

  const label =
    period === PERIOD_DAY
      ? formatDateFullYear(currentDate)
      : `${formatDateShort(currentDate)} - ${formatDateShort(endDate)}`

  return {
    label: label,
    // DATES_FORMAT_BE used to avoid additional formatting during form submit
    value: format(date, DATES_FORMAT_BE)
  }
}

export const getEndPeriodDate = (startDateOriginal, period) => {
  // add 1 day, so we don't overlap the next period i.e. 1-7, 8-14
  const startDate = addDays(startDateOriginal, -1)

  if (period === PERIOD_ONE_WEEK) {
    return format(addWeeks(startDate, 1), DATES_FORMAT_BE)
  } else if (period === PERIOD_TWO_WEEKS) {
    return format(addWeeks(startDate, 2), DATES_FORMAT_BE)
  } else if (period === PERIOD_THREE_MONTH) {
    return format(addMonths(new Date(startDate), 3), DATES_FORMAT_BE)
  } else if (period === PERIOD_TWO_MONTH) {
    return format(addMonths(new Date(startDate), 2), DATES_FORMAT_BE)
  } else if (period === PERIOD_ONE_MONTH) {
    return format(addMonths(new Date(startDate), 1), DATES_FORMAT_BE)
  } else if (periodDayValues[period]) {
    // if period is any of day type
    const amountOfDays = periodDayValues[period]
    return format(addDays(new Date(startDate), amountOfDays - 1), DATES_FORMAT_BE)
  }
}
