import React from 'react'
import { useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import AdTikTokCreateForm from './AdTikTokCreateForm'

import { lineItemSelector, lineItemWasLoadedSelector } from '../../../../modules/selectors/lineItems'
import { campaignSelector } from '../../../../modules/selectors/campaigns'

import { AD_TIK_TOK_CREATE } from '../../../../constants/forms'

function AdTikTokCreate() {
  const lineItemWasLoaded = useSelector(lineItemWasLoadedSelector)
  const { id: lineItemId } = useSelector(lineItemSelector)
  const { id: campaignId } = useSelector(campaignSelector)

  return (
    <FormDrawerWrapper formName={AD_TIK_TOK_CREATE} openButtonText="Create Ad" isFormLoading={!lineItemWasLoaded}>
      <AdTikTokCreateForm campaignId={campaignId} lineItemId={lineItemId} />
    </FormDrawerWrapper>
  )
}

export default AdTikTokCreate
