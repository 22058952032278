import React from 'react'
import { components } from 'react-select'
import { useTranslation } from 'react-i18next'

import useStyles from './styles'

export default function SelectSingleValue(props) {
  const { t } = useTranslation()

  const classes = useStyles({
    bigIcon: props.selectProps.bigIcon,
    isLabelBrandPrimary: props.selectProps.isSelectedValueBrandPrimary
  })
  const Icon = props.data.icon
  const label = props.data.label
  const prefix = props.selectProps.prefix

  return (
    <components.SingleValue {...props}>
      <div className={classes.value}>
        {Icon && (
          <div className={classes.iconContainer}>
            <Icon />
          </div>
        )}
        <div className={classes.info}>
          <div className={classes.label}>
            {prefix} {t(label)}
          </div>
        </div>
      </div>
    </components.SingleValue>
  )
}
