import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import AgencyContentSection from '../AgencyContentSection'
import AgencyMembersAndInvitesTable from '../../AgencyMembersAndInvitesTable'

import { selectedAgencyIdSelector } from '../../../../../modules/selectors/app'
import { getAgency } from '../../../../../modules/actions/agencies'
import { agencySelector } from '../../../../../modules/selectors/agencies'
import { getAgencyInvites, getAgencyMembers } from '../../../../../modules/actions/agencyTeam'

import useSettingsClasses from '../../../styles'

const AgencyUserContent = ({ setMemberId, openModalHandler }) => {
  const { t } = useTranslation()

  const settingsClasses = useSettingsClasses()
  const dispatch = useDispatch()

  const agency = useSelector(agencySelector)
  const { name: agencyName } = agency

  const selectedAgencyId = useSelector(selectedAgencyIdSelector)

  useEffect(() => {
    // we don't need to use loadMore handlers for members and invites, just load all
    dispatch(getAgencyMembers({ agency: selectedAgencyId, limit: 999 }))
    dispatch(getAgencyInvites({ agency: selectedAgencyId, limit: 999 }))
  }, [dispatch, selectedAgencyId])

  useEffect(() => {
    dispatch(getAgency({ id: selectedAgencyId }))
  }, [dispatch, selectedAgencyId])

  return (
    <>
      <h1 className={settingsClasses.title}>{t('manageAgencyTeam', { agencyName })}</h1>
      <AgencyContentSection agency={selectedAgencyId} setMemberId={setMemberId} openModalHandler={openModalHandler}>
        <AgencyMembersAndInvitesTable setMemberId={setMemberId} openModalHandler={openModalHandler} />
      </AgencyContentSection>
    </>
  )
}

AgencyUserContent.propTypes = {
  setMemberId: PropTypes.func.isRequired,
  openModalHandler: PropTypes.func.isRequired
}

export default AgencyUserContent
