import { call, put, all, takeEvery, select } from 'redux-saga/effects'

import { resendRegisterService, socialAuthService } from '../services/auth'
import {
  RESEND_REGISTER,
  resendRegisterSuccess,
  resendRegisterFailure,
  SOCIAL_AUTH,
  socialAuthSuccess,
  socialAuthFailure
} from '../actions/auth'
import { approvePlatformConnection } from '../actions/app'
import { showToasts } from '../../helpers/toasts'
import { TOAST_TYPE } from '../../constants/other'
import { selectedPlatformSelector } from '../selectors/app'
import {
  DV_360_PLATFORM,
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  TIKTOK_PLATFORM
} from '../../constants/selectLists/platformList'

function* authWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(RESEND_REGISTER, resendRegisterWorker),
    takeEvery(SOCIAL_AUTH, socialAuthWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* resendRegisterWorker({ resendData, showToastAfterSuccess }) {
  try {
    yield call(resendRegisterService, resendData)
    yield put(resendRegisterSuccess())
    if (showToastAfterSuccess) {
      showToasts({
        type: TOAST_TYPE.success,
        message: 'The invitation email has been re-sent, please check your email again.'
      })
    }
  } catch (e) {
    yield put(resendRegisterFailure(e))
  }
}

function* socialAuthWorker({ params }) {
  try {
    const response = yield call(socialAuthService, params)
    yield put(socialAuthSuccess(response))

    const platform = yield select(selectedPlatformSelector)

    // update userProfile data fields reflected to platform connection
    let connectedPlatformField

    if (platform === FACEBOOK_PLATFORM) {
      connectedPlatformField = { facebook_is_connected: true }
    } else if (platform === GOOGLE_PLATFORM) {
      connectedPlatformField = { google_is_connected: true }
    } else if (platform === DV_360_PLATFORM) {
      connectedPlatformField = { dv360_is_connected: true }
    } else if (platform === TIKTOK_PLATFORM) {
      connectedPlatformField = { tiktok_is_connected: true }
    }

    if (connectedPlatformField) {
      yield put(approvePlatformConnection(connectedPlatformField))
    }
  } catch (e) {
    yield put(socialAuthFailure(e))
  }
}

export default authWatcher
