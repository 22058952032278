import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../components/Form'
import DrawerHeadline from '../../../../../components/Drawer/DrawerHeadline'
import Locations from './Locations'

import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'

import {
  clearCampaignCriterions,
  clearCreateCampaignCriterions,
  createCampaignCriterions
} from '../../../../../modules/actions/campaigns'
import {
  createCampaignCriterionsIsLoadingSelector,
  createCampaignCriterionsErrorSelector,
  createCampaignCriterionsWasCreatedSelector
} from '../../../../../modules/selectors/campaigns'
import { getCurrentUserSelector, selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'
import { choicesCountriesSelector } from '../../../../../modules/selectors/choices'

import {
  LOCATION_LIST,
  LOCATIONS_SELECTED_TAB,
  LOCATIONS_LIST_TAB
} from '../../../../ReusableFormFields/GeolocationsFields/fields'
import { validationSchema, transformLocationsToBE } from './fields'
import { criterionTypes } from '../../../../../constants/campaigns'
import { getInitialValues } from './fields'

import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

function CampaignGoogleGeolocationsForm({ onSuccessGeolocationsSubmit, adAccountId: providedAdAccountId, campaignId }) {
  const drawerFormClasses = useDrawerFormStyles()
  const dispatch = useDispatch()

  const [successSubmit, setSuccessSubmit] = useState(false)

  const criterionsWasCreated = useSelector(createCampaignCriterionsWasCreatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const userData = useSelector(getCurrentUserSelector)

  const { country: countryId } = userData

  const countries = useSelector(choicesCountriesSelector)

  const adAccountId = providedAdAccountId || selectedAdAccountId

  const onSubmit = values => {
    if (values[LOCATIONS_SELECTED_TAB] === LOCATIONS_LIST_TAB) {
      // proximity to specific supermarkets or other locations
      const formattedValues = {
        account: adAccountId,
        campaign: campaignId,
        location_list: values[LOCATION_LIST]
      }
      dispatch(createCampaignCriterions(formattedValues, criterionTypes.proximity))
    } else {
      // create criterions for location tab
      const formattedCriterions = transformLocationsToBE(values, campaignId)
      const formattedValues = {
        account: adAccountId,
        ...formattedCriterions,
        remove_operations: []
      }
      dispatch(createCampaignCriterions(formattedValues))
    }
  }

  const initialValues = useMemo(() => {
    const userCountry = countries.find(country => country.id === countryId)
    const userCountryOption = {
      ...userCountry,
      value: userCountry?.id,
      label: userCountry?.name,
      code: userCountry?.iso2
    }
    return getInitialValues(userCountryOption)
  }, [countries, countryId])

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const clearCreateCriterionsHandler = useCallback(() => {
    dispatch(clearCreateCampaignCriterions())
  }, [dispatch])

  useEffect(() => {
    if (criterionsWasCreated) {
      setSuccessSubmit(true)

      dispatch(clearCampaignCriterions())
    }
  }, [dispatch, criterionsWasCreated])

  return (
    <div className={drawerFormClasses.formContainer}>
      <DrawerHeadline
        title="Set the campaign location targeting"
        description="Define the location(s) where the ads will show"
      />
      <Form
        formName="campaignGoogleGeolocations"
        formik={purifiedFormik}
        method="post"
        submitText="Save and continue"
        successSubmit={successSubmit}
        clearHandler={clearCreateCriterionsHandler}
        onSuccessSubmit={onSuccessGeolocationsSubmit}
        errorSelector={createCampaignCriterionsErrorSelector}
        isLoadingSelector={createCampaignCriterionsIsLoadingSelector}
      >
        <Locations formik={purifiedFormik} />
      </Form>
    </div>
  )
}

export default CampaignGoogleGeolocationsForm
