import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import CustomLocationsPreview from './CustomLocationsPreview'
import Field from '../../../components/Form/Field'
import InternalLink from '../../../components/InternalLink'
import ErrorMessage from '../../../components/Form/ErrorMessage'

import { formatOptionsList } from '../../../features/formatters'

import { locationListsSelector } from '../../../modules/selectors/location'

import { LOCATION_LIST } from '../../ReusableFormFields/GeolocationsFields/fields'
import { ROUTES } from '../../../constants/routes'
import { CUSTOM_LOCATIONS } from '../../Facebook/LineItemForms/LineItemFacebookEdit/fields'

import useStyles from './styles'
import useDrawerFormsStyles from '../../../styles/common/drawerForms'

const CustomLocationsContent = ({ formik, showTitle = true, isDisabled }) => {
  const classes = useStyles()
  const drawerFormsClasses = useDrawerFormsStyles()

  const { t } = useTranslation()

  const locationLists = useSelector(locationListsSelector)

  const { values, setFieldValue, errors } = formik

  const { [CUSTOM_LOCATIONS]: customLocations } = values

  const { [CUSTOM_LOCATIONS]: customLocationError } = errors

  const clearCustomLocationsHandler = () => {
    setFieldValue(CUSTOM_LOCATIONS, [])
  }

  const formattedLocationsList = useMemo(
    () =>
      formatOptionsList({
        list: locationLists.map(list => ({
          ...list,
          name: `${list.name} (${list.size})`
        })),
        valueName: 'id',
        labelName: 'name'
      }),
    [locationLists]
  )

  return (
    <>
      {showTitle && <h4 className={drawerFormsClasses.sectionTitle}>{t('Custom locations')}</h4>}
      {customLocations.length ? (
        <>
          <CustomLocationsPreview title="Targeted locations: " customLocations={customLocations} />
          <p>
            {t(
              'You can clear the current locations, and optionally replace them with an updated or different location list'
            )}
          </p>
          <p className={classes.clearButton} onClick={clearCustomLocationsHandler}>
            {t('Clear list')}
          </p>
        </>
      ) : (
        <>
          {locationLists && !!locationLists.length ? (
            <>
              <Field
                placeholder="Select a Locations List"
                formik={formik}
                name={LOCATION_LIST}
                options={formattedLocationsList}
                isDisabled={isDisabled}
                showClearInputIcon={!!values[LOCATION_LIST]}
              />
              {customLocationError && <ErrorMessage error={customLocationError} />}
            </>
          ) : (
            <p>
              {t(`To use this feature, you must have already uploaded the list of locations on the `)}
              <InternalLink className={drawerFormsClasses.link} to={ROUTES.manageLocationLists}>
                {t('settings')}
              </InternalLink>
              {t(' page.')}
            </p>
          )}
        </>
      )}
    </>
  )
}

CustomLocationsContent.propTypes = {
  formik: PropTypes.object.isRequired,
  showTitle: PropTypes.bool,
  isDisabled: PropTypes.bool
}

export default CustomLocationsContent
