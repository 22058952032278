import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'

import AdFormFields from './AdFormFields'
import Form from '../../../../../../components/Form'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import useIsInternalAdCreation from '../../../../../../features/hooks/useIsInternalAdCreation'
import { useShowProductOrPageSelectionStep } from '../../../../../Facebook/AdForms/AdFacebookCreate/AdFacebookCreateForm/hooks/useShowProductOrPageSelectionStep'

import { clearCreateAd, createAd } from '../../../../../../modules/actions/ads'
import {
  catalogueProductsSelector,
  mediaOrderUploadCreativeDataSelector
} from '../../../../../../modules/selectors/mediaOrders'
import {
  adCreateErrorSelector,
  adCreateLoadingSelector,
  adWasCreatedSelector
} from '../../../../../../modules/selectors/ads'
import {
  tikTokIdentitiesSelector,
  tikTokVideoThumbnailsSelector,
  tikTokVideoThumbnailsWasLoadedSelector
} from '../../../../../../modules/selectors/tikTokConstants'
import { selectedControllerIdSelector, selectedSelfAccountDataSelector } from '../../../../../../modules/selectors/app'

import { TIKTOK_PLATFORM } from '../../../../../../constants/selectLists/platformList'
import { initialValues, getValidationSchema, transformValuesToBE } from './fields'
import { AD_TIK_TOK_CREATE } from '../../../../../../constants/forms'

const AdFormContent = ({ adAccountId, onSuccessSubmit }) => {
  const dispatch = useDispatch()

  const tikTokVideoThumbnails = useSelector(tikTokVideoThumbnailsSelector)
  const tikTokVideoThumbnailsWasLoaded = useSelector(tikTokVideoThumbnailsWasLoadedSelector)
  const adWasCreated = useSelector(adWasCreatedSelector)
  const tikTokIdentities = useSelector(tikTokIdentitiesSelector)
  const catalogueProducts = useSelector(catalogueProductsSelector)
  const { campaign, lineItem } = useSelector(mediaOrderUploadCreativeDataSelector)
  const selectedSelfAccountControllerId = useSelector(selectedControllerIdSelector)

  const selectedSelfAccountData = useSelector(selectedSelfAccountDataSelector)
  const { id: selectedSelfAccountId } = selectedSelfAccountData

  const showProductSelection = useShowProductOrPageSelectionStep()

  const campaignId = campaign?.id
  const lineItemId = lineItem?.id

  const isInternalAdCreation = useIsInternalAdCreation()
  const onSubmit = useCallback(
    values => {
      const formattedData = transformValuesToBE({
        values,
        account: adAccountId,
        campaignId,
        lineItemId,
        isInternalAdCreation,
        controllerId: selectedSelfAccountControllerId,
        identitiesList: tikTokIdentities,
        productsList: catalogueProducts,
        tikTokVideoThumbnails,
        selectedSelfAccountId,
        showProductSelection
      })
      dispatch(createAd(formattedData, TIKTOK_PLATFORM))
    },
    [
      dispatch,
      adAccountId,
      campaignId,
      lineItemId,
      selectedSelfAccountControllerId,
      tikTokIdentities,
      catalogueProducts,
      tikTokVideoThumbnails,
      isInternalAdCreation,
      selectedSelfAccountId,
      showProductSelection
    ]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema({ showProductSelection }),
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const clearCreateAdHandler = useCallback(() => {
    dispatch(clearCreateAd())
  }, [dispatch])

  return (
    <Form
      formName={AD_TIK_TOK_CREATE}
      formik={purifiedFormik}
      onSuccessSubmit={onSuccessSubmit}
      // processing
      successSubmit={adWasCreated}
      errorSelector={adCreateErrorSelector}
      isLoadingSelector={adCreateLoadingSelector}
      // after form submit
      clearHandler={clearCreateAdHandler}
      disabled={!tikTokVideoThumbnailsWasLoaded}
    >
      <AdFormFields formik={formik} adAccountId={adAccountId} />
    </Form>
  )
}

AdFormContent.propTypes = {
  adAccountId: PropTypes.string.isRequired,
  onSuccessSubmit: PropTypes.func.isRequired
}

export default AdFormContent
