import * as Yup from 'yup'
import { t } from 'i18next'

import { AD_LINK, DESCRIPTIONS_LIST, FILE_URL, FORMAT_OPTION, HEADLINES_LIST } from './fields'
import { urlValidation } from '../../../features/validations/other'
import { AD_FORMAT } from '../../../constants/ads'

export const primaryTextMaxLength = 400
export const descriptionMaxLength = 255
export const headlineMaxLength = 255

export const primaryTextItemShape = Yup.object({
  text: Yup.string().max(
    primaryTextMaxLength,
    t('primaryTextMaxLengthErrorMessage', { maxLength: primaryTextMaxLength })
  )
})

export const getHeadlineShape = headlineMaxLength =>
  Yup.object({
    text: Yup.string()
      .max(headlineMaxLength, t('headlineMaxLengthErrorMessage', { maxLength: headlineMaxLength }))
      .when(FORMAT_OPTION, {
        is: AD_FORMAT.single,
        then: () => Yup.string().required('Please enter a headline')
      })
  })

export const headlineShape = getHeadlineShape(headlineMaxLength)

export const descriptionShape = Yup.object({
  text: Yup.string().max(
    descriptionMaxLength,
    t('descriptionMaxLengthErrorMessage', { maxLength: descriptionMaxLength })
  )
})

export const mediaItemShape = {
  // FILE
  [FILE_URL]: Yup.string().required('Please select Image or Video file'),
  // CLICK TROUGH LINK
  [AD_LINK]: urlValidation,
  // HEADLINE
  [HEADLINES_LIST]: Yup.array().of(headlineShape),
  // DESCRIPTION
  [DESCRIPTIONS_LIST]: Yup.array().of(descriptionShape)
}

export const mediaItemSchema = Yup.object(mediaItemShape)
