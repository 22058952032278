import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

// Get contract
export const contractSelector = createSelector(
  ({ contracts }) => contracts.getContract.data,
  data => data
)

export const getContractIsLoadingSelector = createSelector(
  ({ contracts }) => contracts.getContract.isLoading,
  isLoading => isLoading
)

export const getContractErrorSelector = createSelector(
  ({ contracts }) => contracts.getContract.error,
  error => error
)

export const contractWasLoadedSelector = createSelector(
  ({ contracts }) => contracts.getContract.wasLoaded,
  wasLoaded => wasLoaded
)

export const activeContractSelector = createSelector(
  ({ contracts }) => contracts.activeContract,
  contractData => contractData
)

// Create contract
export const createdContractSelector = createSelector(
  ({ contracts }) => contracts.createContract.data,
  data => data
)

export const createContractsIsLoadingSelector = createSelector(
  ({ contracts }) => contracts.createContract.isLoading,
  isLoading => isLoading
)

export const createContractsErrorSelector = createSelector(
  ({ contracts }) => contracts.createContract.error,
  error => error
)

export const contractWasCreatedSelector = createSelector(
  ({ contracts }) => contracts.createContract.wasCreated,
  wasCreated => wasCreated
)

// Update contract
export const updateContractSelector = createSelector(
  ({ contracts }) => contracts.updateContract.data,
  data => data
)

export const updateContractIsLoadingSelector = createSelector(
  ({ contracts }) => contracts.updateContract.isLoading,
  isLoading => isLoading
)

export const updateContractErrorSelector = createSelector(
  ({ contracts }) => contracts.updateContract.error,
  error => error
)

export const contractWasUpdatedSelector = createSelector(
  ({ contracts }) => contracts.updateContract.wasUpdated,
  wasUpdated => wasUpdated
)

// Update contracts status bulk
export const updateContractsStatusBulkSelector = createSelector(
  ({ contracts }) => contracts.updateContractsStatusBulk.data,
  data => data
)

export const updateContractsStatusBulkIsLoadingSelector = createSelector(
  ({ contracts }) => contracts.updateContractsStatusBulk.isLoading,
  isLoading => isLoading
)

export const updateContractsStatusBulkErrorSelector = createSelector(
  ({ contracts }) => contracts.updateContractsStatusBulk.error,
  error => error
)

export const updateContractsStatusBulkWasLoadedSelector = createSelector(
  ({ contracts }) => contracts.updateContractsStatusBulk.wasUpdated,
  wasUpdated => wasUpdated
)

// Sign contract
export const signContractSelector = createSelector(
  ({ contracts }) => contracts.signContract.results,
  results => results
)

export const signContractIsLoadingSelector = createSelector(
  ({ contracts }) => contracts.signContract.isLoading,
  isLoading => isLoading
)

export const signContractErrorSelector = createSelector(
  ({ contracts }) => contracts.signContract.error,
  error => error
)

export const signContractWasCreatedSelector = createSelector(
  ({ contracts }) => contracts.signContract.wasCreated,
  wasCreated => wasCreated
)

// Delete contract signature
export const deleteContractSignatureSelector = createSelector(
  ({ contracts }) => contracts.deleteContractSignature.data,
  data => data
)

export const deleteContractSignatureIsLoadingSelector = createSelector(
  ({ contracts }) => contracts.deleteContractSignature.isLoading,
  isLoading => isLoading
)

export const deleteContractSignatureErrorSelector = createSelector(
  ({ contracts }) => contracts.deleteContractSignature.error,
  error => error
)

export const contractSignatureWasDeletedSelector = createSelector(
  ({ contracts }) => contracts.deleteContractSignature.wasDeleted,
  wasDeleted => wasDeleted
)

// Get contracts
export const contractsSelector = createSelector(
  ({ contracts }) => contracts.contracts.results,
  results => results
)

export const contractsIsLoadingSelector = createSelector(
  ({ contracts }) => contracts.contracts.isLoading,
  isLoading => isLoading
)

export const contractsErrorSelector = createSelector(
  ({ contracts }) => contracts.contracts.error,
  error => error
)

export const contractsWasLoadedSelector = createSelector(
  ({ contracts }) => contracts.contracts.wasLoaded,
  wasLoaded => wasLoaded
)
