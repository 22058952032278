import React from 'react'
import PropTypes from 'prop-types'

import DigitalTable from '../../../../../../features/components/DigitalTable'

import usePermissions from '../../../../../../hooks/usePermissions'

import {
  combinedLineItemsErrorSelector,
  combinedLineItemsIsLoadingSelector,
  combinedLineItemsWasLoadedSelector
} from '../../../../../../modules/selectors/combinedData'

import { TIKTOK_PLATFORM } from '../../../../../../constants/selectLists/platformList'
import { tiktokAdsTableId } from '../../../../../../constants/other'
import { LINE_ITEM_PERMISSION } from '../../../../../../constants/permissions'

const TikTokAdGroupBreakdown = ({
  lineItems,
  updateLineItemStatusHandler,
  getLineItemLinkClickHandler,
  getLineItemActionsDropdownOptions
}) => {
  const permissions = usePermissions()
  const hasStatusChangePermissions = permissions.can('update', LINE_ITEM_PERMISSION)

  return (
    <DigitalTable
      id={tiktokAdsTableId}
      platform={TIKTOK_PLATFORM}
      data={lineItems}
      isLoadingSelector={combinedLineItemsIsLoadingSelector}
      errorSelector={combinedLineItemsErrorSelector}
      wasLoadedSelector={combinedLineItemsWasLoadedSelector}
      allowStatusChange={hasStatusChangePermissions}
      onStatusChange={updateLineItemStatusHandler}
      getItemLinkClickHandler={getLineItemLinkClickHandler}
      getActionsDropdownOptions={getLineItemActionsDropdownOptions}
    />
  )
}

TikTokAdGroupBreakdown.propTypes = {
  lineItems: PropTypes.array.isRequired,
  updateLineItemStatusHandler: PropTypes.func.isRequired,
  getLineItemLinkClickHandler: PropTypes.func.isRequired,
  getLineItemActionsDropdownOptions: PropTypes.func.isRequired
}

export default TikTokAdGroupBreakdown
