import React from 'react'
import { useTranslation } from 'react-i18next'

import Field from '../../../../../../../components/Form/Field'

import { formatNumber } from '../../../../../../../helpers/numbers'

import { CURRENCY_FORMAT } from '../../../../../../../constants/currency'
import { BUDGET_DAILY, TARGET_ROAS } from '../../../../../../Multiplatform/CampaignForms/fields'
import { DISPLAY_TYPE } from '../../../../../CampaignForms/CampaignGoogleCreate/CampaignGoogleCreateForm/fields'
import { CAMPAIGN_OPTION } from '../fields'

import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

const BudgetFields = ({ formik, currencySymbol, remainingBudget, maxDailyBudget }) => {
  const drawerFormClasses = useDrawerFormStyles()

  const { t } = useTranslation()

  const { values } = formik

  const isDisplayTypeCampaign = values[CAMPAIGN_OPTION] === DISPLAY_TYPE

  return (
    <>
      <div className={drawerFormClasses.subSection}>
        <h4 className={drawerFormClasses.sectionSubtitle}>Daily Budget</h4>
        <p>{t('Set an average amount you want to spend each day.')}</p>
        <p>
          {t(
            `Your total remaining budget is ${currencySymbol}${formatNumber(
              remainingBudget,
              CURRENCY_FORMAT
            )} therefore the maximum daily budget you can enter here is ${currencySymbol}${formatNumber(
              maxDailyBudget,
              CURRENCY_FORMAT
            )}`
          )}
        </p>
        <Field
          type="number"
          formik={formik}
          name={BUDGET_DAILY}
          placeholder={t('Daily Budget')}
          symbol={currencySymbol}
          autoComplete="off"
          inputMode="numeric"
        />
      </div>
      {!isDisplayTypeCampaign && (
        <div className={drawerFormClasses.subSection}>
          <h4 className={drawerFormClasses.sectionSubtitle}>{t('Target ROAS (Optional)')}</h4>
          <p>
            {t(
              "If you track return on ad spend (ROAS), please enter a target for this campaign. If you don't track this metric, or aren't sure, leave this blank."
            )}
          </p>
          <Field
            type="number"
            formik={formik}
            name={TARGET_ROAS}
            placeholder={t('Target ROAS')}
            autoComplete="off"
            inputMode="numeric"
          />
        </div>
      )}
    </>
  )
}

export default BudgetFields
