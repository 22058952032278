import React, { useEffect, useRef } from 'react'

import data from '@emoji-mart/data/sets/14/facebook.json'

import { Picker } from 'emoji-mart'

const EmojiPicker = props => {
  const ref = useRef()

  useEffect(() => {
    new Picker({ ...props, data, ref, set: 'facebook' })
    // eslint-disable-next-line
  }, [])

  return <div ref={ref} />
}

export default EmojiPicker
