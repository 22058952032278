import { useEffect, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import usePermissions from '../../../../hooks/usePermissions'
import { insertIf } from '../../../../helpers/common'

import { openForm } from '../../../../modules/actions/forms'
import { deleteMediaPackage, updateMediaPackage } from '../../../../modules/actions/mediaPackages'
import {
  deleteMediaPackageSelector,
  updateMediaPackageErrorSelector,
  updateMediaPackageIdSelector,
  updateMediaPackageWasUpdatedSelector
} from '../../../../modules/selectors/mediaPackages'

import { MEDIA_PACKAGE_EDIT } from '../../../../constants/forms'
import {
  PACKAGE_PENDING_APPROVAL,
  STATUS_ACTIVE,
  STATUS_PAUSED
} from '../../../../forms/Multiplatform/MediaPackageForms/MediaPackageCreate/MediaPackageCreateForm/fields'
import { PACKAGE_APPROVE_PERMISSION } from '../../../../constants/permissions'

export function usePackageActions(packageItem) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isStatusUpdateRunning, setIsStatusUpdateRunning] = useState(false)
  const [isFeaturedUpdateRunning, setIsFeaturedUpdateRunning] = useState(false)

  const updateMediaPackageId = useSelector(updateMediaPackageIdSelector)
  const updateMediaPackageError = useSelector(updateMediaPackageErrorSelector)
  const updateMediaPackageWasUpdated = useSelector(updateMediaPackageWasUpdatedSelector)
  const { id: deleteMediaPackageId } = useSelector(deleteMediaPackageSelector)

  const { id, status, featured: isFeatured } = packageItem

  const isActive = status === STATUS_ACTIVE

  const isCurrentPackageUnderUpdate = id === updateMediaPackageId || id === deleteMediaPackageId
  const mediaPackageWasUpdated = isCurrentPackageUnderUpdate && updateMediaPackageWasUpdated
  const mediaPackageUpdateError = isCurrentPackageUnderUpdate ? updateMediaPackageError : null

  const openEditFormHandler = useCallback(() => {
    dispatch(openForm({ id, formName: MEDIA_PACKAGE_EDIT }))
  }, [dispatch, id])

  const updatePackageStatusHandler = useCallback(
    status => {
      setIsStatusUpdateRunning(true)

      dispatch(updateMediaPackage(id, { status }))
    },
    [dispatch, id]
  )

  const makeFeaturedPackageHandler = useCallback(() => {
    setIsFeaturedUpdateRunning(true)

    dispatch(updateMediaPackage(id, { featured: true }))
  }, [dispatch, id])

  const permissions = usePermissions()
  const deletePackageHandler = useCallback(() => {
    setIsStatusUpdateRunning(true)

    dispatch(deleteMediaPackage(id))
  }, [dispatch, id])

  const isApproved = packageItem.status !== PACKAGE_PENDING_APPROVAL
  const hasPackageApprovePermissions = permissions.can('manage', PACKAGE_APPROVE_PERMISSION)
  const allowApprove = hasPackageApprovePermissions && !isApproved

  const dropdownOptions = useMemo(
    () => [
      {
        text: t('Edit'),
        onClickHandler: openEditFormHandler
      },
      ...insertIf(allowApprove, {
        text: t('Approve'),
        // on approve we just send active status
        onClickHandler: () => updatePackageStatusHandler(STATUS_ACTIVE)
      }),
      ...insertIf(isApproved, {
        text: isActive ? t('Pause') : t('Activate'),
        onClickHandler: () => updatePackageStatusHandler(isActive ? STATUS_PAUSED : STATUS_ACTIVE)
      }),
      ...insertIf(!isFeatured, {
        text: t('Make Featured'),
        onClickHandler: makeFeaturedPackageHandler
      }),
      {
        text: t('Delete'),
        onClickHandler: deletePackageHandler
      }
    ],
    [
      t,
      isActive,
      isFeatured,
      isApproved,
      allowApprove,
      openEditFormHandler,
      makeFeaturedPackageHandler,
      updatePackageStatusHandler,
      deletePackageHandler
    ]
  )

  useEffect(() => {
    const updateIsDone = mediaPackageWasUpdated || mediaPackageUpdateError

    if (isFeaturedUpdateRunning && updateIsDone) {
      setIsFeaturedUpdateRunning(false)
    } else if (isStatusUpdateRunning && updateIsDone) {
      setIsStatusUpdateRunning(false)
    }
  }, [isFeaturedUpdateRunning, isStatusUpdateRunning, mediaPackageUpdateError, mediaPackageWasUpdated])

  return useMemo(
    () => ({
      dropdownOptions,
      isCurrentPackageUnderUpdate,
      mediaPackageWasUpdated,
      mediaPackageUpdateError,
      isStatusUpdateRunning,
      isFeaturedUpdateRunning,
      openEditFormHandler,
      makeFeaturedPackageHandler,
      isActive,
      updatePackageStatusHandler
    }),
    [
      dropdownOptions,
      isCurrentPackageUnderUpdate,
      mediaPackageWasUpdated,
      mediaPackageUpdateError,
      isStatusUpdateRunning,
      openEditFormHandler,
      isFeaturedUpdateRunning,
      makeFeaturedPackageHandler,
      isActive,
      updatePackageStatusHandler
    ]
  )
}
