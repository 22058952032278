import React from 'react'
import { useTranslation } from 'react-i18next'

import FieldsSection from '../../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../../components/Form/Field'
import MultipleOptionsField from '../../../../../../../forms/ReusableFormFields/MultipleOptionsField'

import { IMAGE_URLS, LINK, NAME, THUMBNAIL_URL } from '../../../fields'

const BrandPageCreateFormContent = ({ formik }) => {
  const { t } = useTranslation()

  return (
    <FieldsSection title={t('Brand page')}>
      <FieldRow
        title={t('Name')}
        description={t('This name will be displayed to the user so should be descriptive and clear')}
      >
        <Field name={NAME} formik={formik} placeholder={'name'} />
      </FieldRow>
      <FieldRow title={t('URL')} description={t('The click through link including the https:// prefix')}>
        <Field name={LINK} formik={formik} placeholder={'Click through link URL'} />
      </FieldRow>
      <FieldRow title={t('Thumbnail URL')} description={t('A link to the thumbnail URL for this brand page')}>
        <Field name={THUMBNAIL_URL} formik={formik} placeholder={'Thumbnail URL'} />
      </FieldRow>
      <FieldRow
        title={t('Image URLs')}
        description={t('A link to the image URLs that will be used to generate the ad creative')}
      >
        <MultipleOptionsField
          fieldPlaceholder="Image URL"
          listFieldName={IMAGE_URLS}
          formik={formik}
          minListQuantity={1}
          maxListQuantity={9}
          addAnotherButtonText={t('+ Another image URL')}
        />
      </FieldRow>
    </FieldsSection>
  )
}

export default BrandPageCreateFormContent
