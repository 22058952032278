import React from 'react'

import ContentSection from '../../../../../features/components/ContentSection'
import ContentRow from '../../../../../features/components/ContentSection/ContentRow'
import AgencyInviteCreate from '../../AgencyInviteCreate'
import PropTypes from 'prop-types'

const AgencyContentSection = ({ agency, children }) => {
  return (
    <ContentSection title="Agency members">
      <ContentRow
        title="Users and invites"
        description={agency ? 'Invite new users to this agency' : ''}
        leftColumnChildren={agency ? <AgencyInviteCreate agency={agency} /> : null}
      >
        {children}
      </ContentRow>
    </ContentSection>
  )
}

AgencyContentSection.propTypes = {
  agency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node
}

export default AgencyContentSection
