import React from 'react'
import AnimateHeight from 'react-animate-height'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { ReactComponent as AngleIcon } from '../../assets/icons/chevron-arrow-down.svg'

import useStyles from './styles'

const MinimisedSectionNew = ({
  onHeaderClick,
  isOpened = false,
  showToggleButton = true,
  headerContent,
  children,
  className,
  headerClassName,
  hasError = false,
  isFocused,
  toggleWrapperClassName
}) => {
  const height = isOpened ? 'auto' : 0

  const classes = useStyles({ isOpened: !!height, isFocused })

  return (
    <section className={classnames(classes.minimizeSection, className, { [classes.hasError]: hasError })}>
      <div className={classnames(classes.header, headerClassName)} onClick={onHeaderClick}>
        {showToggleButton && (
          <div className={classnames(classes.toggleButtonWrapper, toggleWrapperClassName)}>
            <button type="button" className={classnames(classes.toggleButton)}>
              <AngleIcon />
            </button>
          </div>
        )}
        {headerContent && headerContent}
      </div>
      <AnimateHeight duration={300} height={height}>
        <div className={classes.body}>{children}</div>
      </AnimateHeight>
    </section>
  )
}

MinimisedSectionNew.propTypes = {
  onHeaderClick: PropTypes.func,
  isOpened: PropTypes.bool,
  showToggleButton: PropTypes.bool,
  headerContent: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  toggleWrapperClassName: PropTypes.string
}

export default MinimisedSectionNew
