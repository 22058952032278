// combined line items
import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

// combined campaigns
export const combinedCampaignsSelector = createSelector(
  ({ combinedData }) => combinedData.combinedCampaigns.data,
  data => data
)

export const combinedCampaignsIsLoadingSelector = createSelector(
  ({ combinedData }) => combinedData.combinedCampaigns.isLoading,
  isLoading => isLoading
)

export const combinedCampaignsErrorSelector = createSelector(
  ({ combinedData }) => combinedData.combinedCampaigns.error,
  error => error
)

export const combinedCampaignsWasLoadedSelector = createSelector(
  ({ combinedData }) => combinedData.combinedCampaigns.wasLoaded,
  wasLoaded => wasLoaded
)

// combined line items
export const combinedLineItemsSelector = createSelector(
  ({ combinedData }) => combinedData.combinedLineItems.results,
  results => results
)

export const combinedLineItemsIsLoadingSelector = createSelector(
  ({ combinedData }) => combinedData.combinedLineItems.isLoading,
  isLoading => isLoading
)

export const combinedLineItemsErrorSelector = createSelector(
  ({ combinedData }) => combinedData.combinedLineItems.error,
  error => error
)

export const combinedLineItemsWasLoadedSelector = createSelector(
  ({ combinedData }) => combinedData.combinedLineItems.wasLoaded,
  wasLoaded => wasLoaded
)

// combined graphs
export const combinedGraphsSelector = createSelector(
  ({ combinedData }) => combinedData.combinedGraphs.results,
  results => results
)

export const combinedGraphsIsLoadingSelector = createSelector(
  ({ combinedData }) => combinedData.combinedGraphs.isLoading,
  isLoading => isLoading
)

export const combinedGraphsErrorSelector = createSelector(
  ({ combinedData }) => combinedData.combinedGraphs.error,
  error => error
)

export const combinedGraphsWasLoadedSelector = createSelector(
  ({ combinedData }) => combinedData.combinedGraphs.wasLoaded,
  wasLoaded => wasLoaded
)

// combined segmented data
export const combinedSegmentedDataSelector = createSelector(
  ({ combinedData }) => combinedData.combinedSegmentedData.data,
  data => data
)

export const combinedSegmentedDataIsLoadingSelector = createSelector(
  ({ combinedData }) => combinedData.combinedSegmentedData.isLoading,
  isLoading => isLoading
)

export const combinedSegmentedDataWasLoadedSelector = createSelector(
  ({ combinedData }) => combinedData.combinedSegmentedData.wasLoaded,
  wasLoaded => wasLoaded
)

export const combinedSegmentedDataErrorSelector = createSelector(
  ({ combinedData }) => combinedData.combinedSegmentedData.error,
  error => error
)

// combined ads
export const combinedAdsSelector = createSelector(
  ({ combinedData }) => combinedData.combinedAds.results,
  results => results
)

export const combinedAdsIsLoadingSelector = createSelector(
  ({ combinedData }) => combinedData.combinedAds.isLoading,
  isLoading => isLoading
)

export const combinedAdsWasLoadedSelector = createSelector(
  ({ combinedData }) => combinedData.combinedAds.wasLoaded,
  wasLoaded => wasLoaded
)

export const combinedAdsErrorSelector = createSelector(
  ({ combinedData }) => combinedData.combinedAds.error,
  error => error
)
