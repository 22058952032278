import Color from 'color'

import { createStyles } from '../../styles/helpers'

import { CHIP_COLORS } from '../../constants/other'

import {
  lightYellow,
  lightGreen,
  darkGreen,
  darkYellow,
  textGrey,
  darkPurple,
  darkBlue,
  red,
  bannerGrey,
  lightPurple,
  lightBlue,
  lightRed,
  white
} from '../../styles/const/colors'
import { zIndex1 } from '../../styles/const/common'
import { textEllipsis } from '../../styles/mixins/text'

export const textColorValues = {
  [CHIP_COLORS.green]: darkGreen,
  [CHIP_COLORS.yellow]: darkYellow,
  [CHIP_COLORS.grey]: textGrey,
  [CHIP_COLORS.purple]: darkPurple,
  [CHIP_COLORS.blue]: darkBlue,
  [CHIP_COLORS.red]: red,
  [CHIP_COLORS.redWithWhiteColor]: white
}

export const textColorBackgroundValues = {
  [CHIP_COLORS.green]: lightGreen,
  [CHIP_COLORS.yellow]: lightYellow,
  [CHIP_COLORS.grey]: bannerGrey,
  [CHIP_COLORS.purple]: lightPurple,
  [CHIP_COLORS.blue]: lightBlue,
  [CHIP_COLORS.red]: lightRed,
  [CHIP_COLORS.redWithWhiteColor]: red
}

export default createStyles(theme => ({
  tagWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    position: ({ isBadge }) => (isBadge ? 'absolute' : 'relative'),
    top: ({ isBadge }) => (isBadge ? '-9px' : 'unset'),
    right: ({ isBadge }) => (isBadge ? '0' : 'unset'),
    padding: ({ isBadge }) => (isBadge ? '1px 5px' : 'unset')
  },
  tagCommonStyles: {
    position: 'relative',
    borderRadius: theme.chips?.borderRadius || 4,
    marginRight: 6,
    textTransform: 'capitalize',
    padding: '5px 10px'
  },
  tag: {
    extend: 'tagCommonStyles',
    display: 'flex',
    alignItems: 'center',
    padding: ({ size }) => (size === 'big' ? '5px 10px' : '3px 6px'),
    height: ({ size }) => (size === 'big' ? 25 : 20),
    minWidth: ({ size }) => (size === 'big' ? 'initial' : 20),
    textAlign: ({ size }) => (size === 'big' ? 'initial' : 'center'),
    justifyContent: ({ size }) => (size === 'big' ? 'initial' : 'center'),
    fontSize: ({ fontSize }) => fontSize,
    color: ({ color }) => theme.chips?.textColor || textColorValues[color],
    ...textEllipsis(),
    backgroundColor: ({ color }) => theme.chips?.backgroundColors?.[color] || textColorBackgroundValues[color],
    '&.hoverable': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: ({ color }) => {
          const backgroundColor = theme.chips?.backgroundColors?.[color] || textColorBackgroundValues[color]
          return Color(backgroundColor).darken(0.1).rgb().string()
        }
      }
    },
    '&.withSubTagSeparator': {
      display: 'inline-block',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderRight: 0,
      marginRight: 0,
      zIndex: zIndex1,
      // we use these pseudo classes to create rotated border design for sub tags with custom triangles
      '&:before': {
        content: "''",
        position: 'absolute',
        top: 0,
        right: -10,
        zIndex: '-50',
        width: 0,
        height: 0,
        borderLeft: '10px solid transparent',
        borderTop: ({ color }) =>
          `25px solid ${theme.chips?.backgroundColors?.[color] || textColorBackgroundValues[color]}`,
        borderRight: '10px solid transparent'
      },
      '&:after': {
        content: "''",
        position: 'absolute',
        top: 0,
        right: -8,
        zIndex: '-40',
        width: 0,
        height: 0,
        borderLeft: '10px solid transparent',
        borderTop: ({ color }) =>
          `13px solid ${theme.chips?.backgroundColors?.[color] || textColorBackgroundValues[color]}`,
        borderRight: '9px solid transparent'
      }
    }
  },
  subTag: {
    height: 25,
    extend: 'tagCommonStyles',
    display: 'inline-block',
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    paddingLeft: 13,
    fontSize: ({ fontSize }) => fontSize,
    backgroundColor: ({ subTagColor }) =>
      theme.chips?.backgroundColors?.[subTagColor] || textColorBackgroundValues[subTagColor],
    color: ({ subTagColor }) => theme.chips?.textColor || textColorValues[subTagColor]
  }
}))
