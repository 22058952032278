import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { selectedControllerDataSelector } from '../../../../../modules/selectors/app'

import { ASSET_MANAGER } from '../../../../../constants/permissions'
import { MEDIA_SUB_CATEGORIES } from '../../../../Settings/ManageControllerTeam/ControllerInviteCreate/ControllerInviteCreateForm/fields'

export const BookedMediaCalendarFiltersContext = React.createContext()

export function BookedMediaCalendarDataProvider({ children, onFiltersChange }) {
  const {
    category: selectedControllerCategory,
    role: selectedControllerRole,
    [MEDIA_SUB_CATEGORIES]: selectedControllerMediaSubCategories
  } = useSelector(selectedControllerDataSelector)

  // account
  const [multipleAccountFilterOption, setMultipleAccountFilterOption] = useState([])
  // representative
  const [multipleRepresentativeFilterOption, setMultipleRepresentativeFilterOption] = useState([])
  // categories
  const [selectedCategories, setSelectedCategories] = useState([])

  const selectedControllerMediaSubCategoriesIds = useMemo(
    () => selectedControllerMediaSubCategories?.map(item => item.id) || [],
    [selectedControllerMediaSubCategories]
  )

  const [selectedSubCategories, setSelectedSubCategories] = useState(
    selectedControllerRole === ASSET_MANAGER ? selectedControllerMediaSubCategoriesIds : []
  )

  const [selectedSubSubCategories, setSelectedSubSubCategories] = useState([])
  // product
  const [productFilterOption, setProductFilterOption] = useState([])
  // locations
  const [selectedLocations, setSelectedLocations] = useState([])
  // brand category
  const [brandCategory, setBrandCategory] = useState(
    selectedControllerCategory ? [Number(selectedControllerCategory?.id)] : []
  )
  // brand sub category
  const [brandSubCategory, setBrandSubCategory] = useState([])

  const filterRequestParams = useMemo(() => {
    return {
      ...(multipleAccountFilterOption?.length && { account: multipleAccountFilterOption }),
      ...(multipleRepresentativeFilterOption?.length && {
        representative: multipleRepresentativeFilterOption
      }),
      ...(selectedCategories.length && { media_category: selectedCategories }),
      ...(selectedSubCategories.length && { media_sub_category: selectedSubCategories }),
      ...(selectedSubSubCategories.length && { media_sub_sub_category: selectedSubSubCategories }),
      ...(productFilterOption.length && { media_product: productFilterOption }),
      ...(selectedLocations.length && { location: selectedLocations }),
      ...(brandCategory?.length && { brand_category: brandCategory }),
      ...(brandSubCategory?.length && { brand_subcategory: brandSubCategory })
    }
  }, [
    multipleAccountFilterOption,
    multipleRepresentativeFilterOption,
    selectedCategories,
    selectedSubCategories,
    selectedSubSubCategories,
    productFilterOption,
    selectedLocations,
    brandCategory,
    brandSubCategory
  ])

  const bookedMediaCalendarFilterProps = useMemo(() => {
    return {
      filterRequestParams,
      multipleAccountFilterOption,
      setMultipleAccountFilterOption,
      multipleRepresentativeFilterOption,
      setMultipleRepresentativeFilterOption,
      selectedCategories,
      setSelectedCategories,
      selectedSubCategories,
      setSelectedSubCategories,
      selectedSubSubCategories,
      setSelectedSubSubCategories,
      productFilterOption,
      setProductFilterOption,
      selectedLocations,
      setSelectedLocations,
      brandCategory,
      setBrandCategory,
      brandSubCategory,
      setBrandSubCategory
    }
  }, [
    filterRequestParams,
    multipleAccountFilterOption,
    setMultipleAccountFilterOption,
    multipleRepresentativeFilterOption,
    setMultipleRepresentativeFilterOption,
    selectedCategories,
    setSelectedCategories,
    selectedSubCategories,
    setSelectedSubCategories,
    selectedSubSubCategories,
    setSelectedSubSubCategories,
    productFilterOption,
    setProductFilterOption,
    selectedLocations,
    setSelectedLocations,
    brandCategory,
    setBrandCategory,
    brandSubCategory,
    setBrandSubCategory
  ])

  // make a type check for the props
  PropTypes.checkPropTypes(propTypes, bookedMediaCalendarFilterProps, 'prop', 'useRequestHandler')

  useEffect(() => {
    onFiltersChange(filterRequestParams)
  }, [onFiltersChange, filterRequestParams])

  return (
    <BookedMediaCalendarFiltersContext.Provider value={bookedMediaCalendarFilterProps}>
      {children}
    </BookedMediaCalendarFiltersContext.Provider>
  )
}

export const filterRequestParamsTypes = {
  account: PropTypes.array,
  representative: PropTypes.array,
  media_category: PropTypes.array,
  media_sub_category: PropTypes.array,
  media_sub_sub_category: PropTypes.array,
  media_product: PropTypes.array,
  location: PropTypes.array,
  brand_category: PropTypes.array,
  brand_subcategory: PropTypes.array
}

const propTypes = {
  filterRequestParamsTypes: PropTypes.shape(filterRequestParamsTypes),
  multipleAccountFilterOption: PropTypes.array,
  setMultipleAccountFilterOption: PropTypes.func,
  multipleRepresentativeFilterOption: PropTypes.array,
  setMultipleRepresentativeFilterOption: PropTypes.func,
  selectedCategories: PropTypes.array,
  setSelectedCategories: PropTypes.func,
  selectedSubCategories: PropTypes.array,
  setSelectedSubCategories: PropTypes.func,
  selectedSubSubCategories: PropTypes.array,
  setSelectedSubSubCategories: PropTypes.func,
  productFilterOption: PropTypes.array,
  setProductFilterOption: PropTypes.func,
  selectedLocations: PropTypes.array,
  setSelectedLocations: PropTypes.func,
  brandCategory: PropTypes.array,
  setBrandCategory: PropTypes.func,
  brandSubCategory: PropTypes.array,
  setBrandSubCategory: PropTypes.func
}
