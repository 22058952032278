import React from 'react'
import { components } from 'react-select'

export default function SelectMultiValueLabel(props) {
  return (
    <components.MultiValueLabel {...props}>
      {props.selectProps.tagAsLabel ? props.data.tag : props.data.label}
    </components.MultiValueLabel>
  )
}
