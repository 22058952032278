import { createStyles } from '../../../../../../../styles/helpers'
import { greyOutline, textGrey } from '../../../../../../../styles/const/colors'

const useStyles = createStyles({
  formHeadline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  newProductButton: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 30,
    color: textGrey
  },
  resetPricesButton: {
    margin: '0 auto',
    color: greyOutline,
    fontSize: 14,
    fontWeight: 600
  },
  resetPricesButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20
  }
})

export default useStyles
