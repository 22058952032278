import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import TableDataLoader from '../../../components/Table/TableDataLoader'
import QuotationsTableData from './QuotationsTableData'
import QuotationsTableActions from './QuotationsTableActions'
import { QuotationTableContext } from './QuotationTableContext'

import {
  quotationsErrorSelector,
  quotationsIsLoadingSelector,
  quotationsNextSelector,
  quotationsSelector,
  quotationsWasLoadedSelector
} from '../../../modules/selectors/quotations'
import { selfAccountsIsLoadingSelector } from '../../../modules/selectors/selfAccounts'

import { quotationsColumnsSchema } from './helpers'

import useCommonStyles from '../../../styles/common/table'

const QuotationsTable = ({ accountFilterOption, setAccountFilterOption }) => {
  const { t } = useTranslation()

  const commonClasses = useCommonStyles()

  const quotations = useSelector(quotationsSelector)
  const quotationsNext = useSelector(quotationsNextSelector)
  const selfAccountsIsLoading = useSelector(selfAccountsIsLoadingSelector)

  const { loadMoreQuotationsHandler } = useContext(QuotationTableContext)

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no quotations')}</div>
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <TableDataLoader
      itemsLength={quotations.length}
      errorSelector={quotationsErrorSelector}
      wasLoadedSelector={quotationsWasLoadedSelector}
      isLoadingSelector={quotationsIsLoadingSelector}
      skeletonProps={{ cols: quotationsColumnsSchema }}
      noDataContent={noDataContent}
      loadMore={quotationsNext ? loadMoreQuotationsHandler : null}
      additionalDataIsLoading={selfAccountsIsLoading}
    >
      <QuotationsTableActions
        accountFilterOption={accountFilterOption}
        setAccountFilterOption={setAccountFilterOption}
      />
      <QuotationsTableData />
    </TableDataLoader>
  )
}

QuotationsTable.propTypes = {
  loadMoreHandler: PropTypes.func,
  accountFilterOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setAccountFilterOption: PropTypes.func.isRequired
}

export default QuotationsTable
