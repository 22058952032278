import { createStyles } from '../../../../../../../../../../styles/helpers'
import { mediumGrey, red } from '../../../../../../../../../../styles/const/colors'

const useStyles = createStyles({
  quantityFieldPlaceholder: {
    display: 'flex',
    alignItems: 'center',
    height: 38,
    flexGrow: 2,
    borderRadius: 3,
    paddingLeft: 14,
    border: ({ error, isTouched }) => (error && isTouched ? `1px solid ${red}` : `1px solid ${mediumGrey}`),
    color: ({ error, isTouched }) => (error && isTouched ? mediumGrey : 'unset')
  }
})

export default useStyles
