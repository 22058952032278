import { createStyles } from '../../../../../../../../../../styles/helpers'
import { darkGrey } from '../../../../../../../../../../styles/const/colors'

export default createStyles({
  name: {},
  product: {
    paddingBottom: '16px',

    '&:not(:last-of-type)': {
      marginBottom: '16px'
    }
  },
  productDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  subProductsList: {
    color: darkGrey,
    fontSize: 12,
    marginTop: 16
  },
  productVariableName: {
    fontSize: 12,
    fontWeight: 600
  },
  publicationsList: {
    paddingLeft: 0
  },
  publicationDetails: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '&:not(:last-child)': {
      marginBottom: '8px'
    }
  },
  editBtn: {
    marginRight: 'auto',
    paddingTop: 0,
    paddingBottom: 0
  },
  editIcon: {
    width: '15px',
    height: '15px'
  },
  price: {
    marginLeft: 'auto'
  },
  total: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '16px'
  }
})
