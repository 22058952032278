import React from 'react'
import PropTypes from 'prop-types'

import InvoicingStatusFilter from './InvoicingStatusFilter'
import InvoicingDateRangeFilter from './InvoicingDateRangeFilter'
import InvoicingAccountFilter from './InvoicingAccountFilter'
import InvoicingDownloadCSV from './InvoicingDownloadCSV'

import { DesktopUp, DesktopDown } from '../../../../components/hoc/ResponsiveRendering'

import useStyles from './styles'

const InvoicingFilters = ({
  dateRangeFilter,
  setDateRangeFilter,
  statusFilterOption,
  setStatusFilterOption,
  accountFilterOption,
  setAccountFilterOption,
  paramsData,
  hideAccountFilter = false
}) => {
  const classes = useStyles()

  return (
    <>
      <DesktopUp>
        <div className={classes.rightFiltersRow}>
          <InvoicingStatusFilter
            statusFilterOption={statusFilterOption}
            setStatusFilterOption={setStatusFilterOption}
          />
          {!hideAccountFilter && (
            <InvoicingAccountFilter
              accountFilterOption={accountFilterOption}
              setAccountFilterOption={setAccountFilterOption}
            />
          )}
          <InvoicingDateRangeFilter dateRangeFilter={dateRangeFilter} setDateRangeFilter={setDateRangeFilter} />
          <InvoicingDownloadCSV paramsData={paramsData} />
        </div>
      </DesktopUp>
      <DesktopDown>
        <div className={classes.filtersRow}>
          <InvoicingStatusFilter
            statusFilterOption={statusFilterOption}
            setStatusFilterOption={setStatusFilterOption}
          />
          {!hideAccountFilter && (
            <InvoicingAccountFilter
              accountFilterOption={accountFilterOption}
              setAccountFilterOption={setAccountFilterOption}
            />
          )}
          <InvoicingDateRangeFilter dateRangeFilter={dateRangeFilter} setDateRangeFilter={setDateRangeFilter} />
          <InvoicingDownloadCSV paramsData={paramsData} />
        </div>
      </DesktopDown>
    </>
  )
}

InvoicingFilters.propTypes = {
  dateRangeFilter: PropTypes.object.isRequired,
  setDateRangeFilter: PropTypes.func.isRequired,
  statusFilterOption: PropTypes.string,
  setStatusFilterOption: PropTypes.func.isRequired,
  accountFilterOption: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setAccountFilterOption: PropTypes.func.isRequired,
  paramsData: PropTypes.object,
  hideAccountFilter: PropTypes.bool
}

export default InvoicingFilters
