import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { eachDayOfInterval, isWithinInterval } from 'date-fns'
import { formatDateToBE } from '../../../../../../../../../../constants/dates'

export function useGetDisabledDates(props) {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'useGetDisabledDates')

  const { firstPeriod, lastPeriod, availablePeriods, selectedPeriods, additionalAvailableDays } = props

  return useMemo(() => {
    if (firstPeriod && lastPeriod) {
      // find earliest and latest date in periodOptions to generate all available dates in range
      const allInventoryDates = eachDayOfInterval({
        start: new Date(firstPeriod),
        end: new Date(lastPeriod)
      })

      const disabledDates = []
      const datesStatuses = {}

      // iterate over allInventoryDates to determine disabledDates and datesStatuses
      allInventoryDates.forEach(date => {
        const dateKey = formatDateToBE(date, 'yyyy-MM-dd')

        // Check if the date is within any selected period
        const isSelected = selectedPeriods?.some(period => {
          const periodStart = new Date(period.date_start)
          periodStart.setHours(0, 0, 0, 0)
          const periodEnd = new Date(period.date_end)
          periodEnd.setHours(23, 59, 59, 999)
          return isWithinInterval(date, { start: periodStart, end: periodEnd })
        })

        let isAvailable = false
        let isInAdjustDate = false
        let isInOriginalPeriod = false
        let isOriginalStartPeriod = false
        let isOriginalEndPeriod = false

        if (availablePeriods) {
          // Check if the date is within any original period dates
          availablePeriods.forEach(period => {
            const originalStartDate = new Date(period.date_start)
            originalStartDate.setHours(0, 0, 0, 0)

            const originalEndDate = new Date(period.date_end)
            originalEndDate.setHours(23, 59, 59, 999)

            if (dateKey === period.date_start) {
              // Check if the date is the original period start date
              isOriginalStartPeriod = true
            }
            if (dateKey === period.date_end) {
              // Check if the date is the original period end date
              isOriginalEndPeriod = true
            }

            if (
              !isInOriginalPeriod &&
              isWithinInterval(date, {
                start: originalStartDate,
                end: originalEndDate
              })
            ) {
              isInOriginalPeriod = true
            }
          })

          // Check if the date is within any adjusted available period
          isAvailable = availablePeriods.some(period => {
            // Adjust start and end dates with additionalAvailableDays
            const adjustedStartDate = new Date(period.date_start)
            adjustedStartDate.setDate(adjustedStartDate.getDate() - additionalAvailableDays)
            adjustedStartDate.setHours(0, 0, 0, 0)

            const adjustedEndDate = new Date(period.date_end)
            adjustedEndDate.setDate(adjustedEndDate.getDate() + additionalAvailableDays)
            adjustedEndDate.setHours(23, 59, 59, 999)

            return isWithinInterval(date, { start: adjustedStartDate, end: adjustedEndDate })
          })

          // Determine if the date is in adjustment days
          isInAdjustDate = isAvailable && !isInOriginalPeriod
        }

        // Disable dates that are already selected or not available
        const isDisabled = isSelected || !isAvailable
        if (isDisabled) {
          disabledDates.push(date)
        }

        // Store the status of each date
        datesStatuses[dateKey] = {
          isSelected,
          isAvailable,
          isInAdjustDate,
          isOriginalStartPeriod,
          isOriginalEndPeriod
        }
      })

      return { disabledDates, datesStatuses }
    } else if (lastPeriod) {
      // when no first period is available disable all dates from today to last period
      // that could be the case if the first available period is in few months from now
      const disabledDates = eachDayOfInterval({
        start: new Date(),
        end: new Date(lastPeriod)
      })

      const datesStatuses = {}
      disabledDates.forEach(date => {
        const dateKey = formatDateToBE(date, 'yyyy-MM-dd')
        datesStatuses[dateKey] = {
          isSelected: false,
          isAvailable: false,
          isInAdjustDate: false,
          isOriginalStartPeriod: false,
          isOriginalEndPeriod: false
        }
      })

      return { disabledDates, datesStatuses }
    } else {
      return { disabledDates: [], datesStatuses: {} }
    }
  }, [firstPeriod, lastPeriod, selectedPeriods, availablePeriods, additionalAvailableDays])
}

const propTypes = {
  firstPeriod: PropTypes.instanceOf(Date),
  lastPeriod: PropTypes.instanceOf(Date),
  availablePeriods: PropTypes.array,
  selectedPeriods: PropTypes.array,
  additionalAvailableDays: PropTypes.number
}
