import { createStyles } from '../../../../../styles/helpers'

export default createStyles({
  headlineText: {
    fontWeight: 700,
    padding: 0,
    width: '100%'
  },
  skeletonHeadline: {
    margin: 0,
    marginBottom: 3
  }
})
