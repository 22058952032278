import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import PageName from './PageName'
import Avatar from './Avatar'
import AdPrimaryText from './AdPrimaryText'
import AdCardControlsPanel from './ControlsPanel'
import AdCardSingleContent from './AdCardSingleContent'
import AdCardCarouselContent from './AdCardCarouselContent'

import { toggleIsSoundMuted } from '../../../../modules/actions/ads'
import { adPreviewIsSoundMutedSelector } from '../../../../modules/selectors/ads'

import {
  CAROUSEL_ITEMS,
  FORMAT_OPTION,
  FORMAT_PRODUCT,
  FORMAT_SINGLE,
  MEDIA_TYPE
} from '../../../../forms/Facebook/AdForms/fields'

import useStyles from './styles'

function AdCard({
  viewAdOnFacebook,
  elementInProgress,
  formikValues,
  hideControlsPanel,
  typingAnimation,
  showSkeletonPlaceholders
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const classes = useStyles()

  const isSoundMuted = useSelector(adPreviewIsSoundMutedSelector)

  const showControlsPanel = useMemo(
    () =>
      !hideControlsPanel &&
      (formikValues[MEDIA_TYPE] === 'video' ||
        formikValues[CAROUSEL_ITEMS]?.some(item => item[MEDIA_TYPE] === 'video')),
    [hideControlsPanel, formikValues]
  )

  const toggleIsSoundMutedHandler = useCallback(() => {
    dispatch(toggleIsSoundMuted())
  }, [dispatch])

  const optionFormat = formikValues[FORMAT_OPTION]

  const renderAdCardContent = () => {
    return optionFormat === FORMAT_SINGLE || optionFormat === FORMAT_PRODUCT ? (
      <AdCardSingleContent
        viewAdOnFacebook={viewAdOnFacebook}
        elementInProgress={elementInProgress}
        formikValues={formikValues}
        typingAnimation={typingAnimation}
        showSkeletonPlaceholders={showSkeletonPlaceholders}
      />
    ) : (
      <AdCardCarouselContent
        viewAdOnFacebook={viewAdOnFacebook}
        elementInProgress={elementInProgress}
        formikValues={formikValues}
        showSkeletonPlaceholders={showSkeletonPlaceholders}
      />
    )
  }

  return (
    <>
      <div className={classes.adCard}>
        <div className={classes.adCardHeader}>
          <div className={classes.headerTop}>
            <div className={classes.avatar}>
              <Avatar formikValues={formikValues} />
            </div>
            <div className={classes.info}>
              <div className={classes.name}>
                <PageName elementInProgress={elementInProgress} formikValues={formikValues} />
              </div>
              <div className={classes.description}>{t('Sponsored')}</div>
            </div>
          </div>
          <AdPrimaryText
            formikValues={formikValues}
            typingAnimation={typingAnimation}
            showSkeletonPlaceholder={showSkeletonPlaceholders}
          />
        </div>
        {renderAdCardContent()}
      </div>
      {showControlsPanel && (
        <AdCardControlsPanel onMuteSoundToggle={toggleIsSoundMutedHandler} isSoundMuted={isSoundMuted} />
      )}
    </>
  )
}

export default AdCard

AdCard.propTypes = {
  viewAdOnFacebook: PropTypes.string,
  elementInProgress: PropTypes.string,
  formikValues: PropTypes.object,
  hideControlsPanel: PropTypes.bool,
  // allows to show the content of the ad card as if it was being typed
  typingAnimation: PropTypes.bool,
  // allows to show the skeleton placeholders, is used on create ad forms
  showSkeletonPlaceholders: PropTypes.bool
}
