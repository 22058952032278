import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../components/Button'
import InfoModal from '../../features/components/Modals/InfoModal'

import { ReactComponent as WarningIcon } from '../../assets/icons/warning-icon.svg'

import { keys } from '../../helpers/common'

export default function useManageEditSections({ sectionsInitialOpenStates }) {
  const { t } = useTranslation()
  // manage edit form sections opening state
  const [openSections, setOpenSections] = useState(sectionsInitialOpenStates)
  // manage unSaved changes within the section to avoid accidental closing without save
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false)
  // keep name of the triggered section while the Modal is opened. It's used when user still decide to close
  const [unsavedSectionName, setUnsavedSectionName] = useState(null)

  const changeSectionVisibility = useCallback(
    name => {
      // close previous opened section
      const closedStates = {}
      keys(sectionsInitialOpenStates).forEach(sectionName => (closedStates[sectionName] = false))
      // open or close section depending on current state
      const newOpenStates = {
        ...closedStates,
        [name]: !openSections[name]
      }
      setOpenSections(newOpenStates)
    },
    [openSections, sectionsInitialOpenStates]
  )

  const openSectionHandler = useCallback(
    name => {
      if (!hasUnsavedChanges) {
        changeSectionVisibility(name)
      } else {
        // show Warning modal
        setShowWarningModal(true)
        setUnsavedSectionName(name)
      }
    },
    [changeSectionVisibility, hasUnsavedChanges]
  )

  const closeWithoutSave = useCallback(() => {
    // close modal, close section and reset the form
    changeSectionVisibility(unsavedSectionName)
    setShowWarningModal(false)
    setHasUnsavedChanges(false)
  }, [changeSectionVisibility, unsavedSectionName])

  const proceedEditing = useCallback(() => {
    // don't close the section and proceed editing
    setShowWarningModal(false)
  }, [])

  const UnsavedChangesWarningModal = useCallback(
    () => (
      <InfoModal
        isOpen={showWarningModal}
        Icon={WarningIcon}
        onClose={proceedEditing}
        modalTitle={t("You've got unsaved changes!")}
        ModalMessage={
          <>
            <p>{t('If you close, you will lose your unsaved changes')}</p>
          </>
        }
      >
        <Button type="button" className="dark" onClick={proceedEditing}>
          {t('Continue editing')}
        </Button>

        {/*todo move to styles*/}
        <Button type="button" style={{ marginLeft: 15 }} solid onClick={closeWithoutSave}>
          {t('Close')}
        </Button>
      </InfoModal>
    ),
    [t, showWarningModal, closeWithoutSave, proceedEditing]
  )

  return useMemo(
    () => ({
      openSections,
      openSectionHandler,
      setHasUnsavedChanges,
      UnsavedChangesWarningModal
    }),
    [openSections, openSectionHandler, UnsavedChangesWarningModal]
  )
}
