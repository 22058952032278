import { createStyles } from '../../../../../../../styles/helpers'

export default createStyles({
  discountCol: {
    width: '100%'
  },
  discountField: {
    fontSize: 12,
    height: '32px',
    padding: '0 8px'
  }
})
