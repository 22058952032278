import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import ControllerPermissionsEditForm from './ControllerPermissionsEditForm'

import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'

import { controllerMembersIsLoadingSelector, controllerMembersSelector } from '../../../../modules/selectors/controller'

import { CONTROLLER_PERMISSIONS_EDIT } from '../../../../constants/forms'

const ControllerPermissionsEdit = () => {
  const { t } = useTranslation()

  const controllerMembers = useSelector(controllerMembersSelector)
  const controllerMembersIsLoading = useSelector(controllerMembersIsLoadingSelector)

  const { selectedEditItemId } = useManageFormsDrawer({
    formName: CONTROLLER_PERMISSIONS_EDIT
  })

  const memberData = controllerMembers?.find(item => String(item.id) === String(selectedEditItemId))

  return (
    <FormDrawerWrapper
      isFormLoading={controllerMembersIsLoading}
      formName={CONTROLLER_PERMISSIONS_EDIT}
      title={t('Edit controller permissions')}
      showOpenButton={false}
    >
      <ControllerPermissionsEditForm memberData={memberData} />
    </FormDrawerWrapper>
  )
}

export default ControllerPermissionsEdit
