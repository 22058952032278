// https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
import PropTypes from 'prop-types'

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

export default ConditionalWrapper

ConditionalWrapper.propTypes = {
  condition: PropTypes.bool,
  wrapper: PropTypes.func,
  children: PropTypes.node
}
