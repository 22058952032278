import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { formatCurrency } from '../../../../../../../helpers/numbers'

import useStyles from './styles'

const TotalWithFees = ({ fees, totalOrderPrice, discountedPrice, currencySymbol }) => {
  const classes = useStyles()

  const calculatedTotalWithFees = useMemo(() => {
    return fees.reduce((acc, fee) => {
      const feeCost = totalOrderPrice.regularPrice * fee.percent
      return acc + feeCost
    }, totalOrderPrice.regularPrice)
  }, [fees, totalOrderPrice.regularPrice])

  const calculatedDiscountedTotalWithFees = useMemo(() => {
    return fees.reduce((acc, fee) => {
      const feeCost = discountedPrice * fee.percent
      return acc + feeCost
    }, discountedPrice)
  }, [fees, discountedPrice])

  return (
    <div className={classes.totalWithFeesWrapper}>
      <div className={classes.totalWithFees}>
        {formatCurrency(
          discountedPrice ? calculatedDiscountedTotalWithFees : calculatedTotalWithFees,
          { min: 2, max: 2 },
          { symbol: currencySymbol }
        )}
      </div>
    </div>
  )
}

TotalWithFees.propTypes = {
  fees: PropTypes.array.isRequired,
  totalOrderPrice: PropTypes.object.isRequired,
  discountedPrice: PropTypes.number,
  currencySymbol: PropTypes.string.isRequired
}

export default TotalWithFees
