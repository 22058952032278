import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Field from '../../../../../../../../components/Form/Field'

import { formatCurrency } from '../../../../../../../../helpers/numbers'

import { campaignSelector } from '../../../../../../../../modules/selectors/campaigns'
import { selectedAdAccountDataSelector } from '../../../../../../../../modules/selectors/app'

import { CAMPAIGN_BUDGET } from '../../../../../../../Multiplatform/CampaignForms/fields'
import { BUDGET_DAILY, BUDGET_LIFETIME } from '../../../../../../../Multiplatform/LineItemForms/fields'

const BudgetContent = ({ formik, budgetType }) => {
  const { t } = useTranslation()

  const campaign = useSelector(campaignSelector)
  const { currency_symbol: currencySymbol } = useSelector(selectedAdAccountDataSelector)

  const { budget_lifetime: campaignBudgetLifetime, budget_daily: campaignBudgetDaily } = campaign

  if (budgetType === CAMPAIGN_BUDGET) {
    const formattedCampaignBudgetLifetime = formatCurrency(
      campaignBudgetLifetime,
      { min: 2, max: 2 },
      { symbol: currencySymbol }
    )

    const formattedCampaignBudgetDaily = formatCurrency(
      campaignBudgetDaily,
      { min: 2, max: 2 },
      { symbol: currencySymbol }
    )

    const budgetTypeMessage = campaignBudgetLifetime ? t('lifetime') : t('daily')
    const budgetValue = campaignBudgetLifetime ? formattedCampaignBudgetLifetime : formattedCampaignBudgetDaily

    return (
      <>
        <p>
          {t('campaignBudgetDetail', { budgetTypeMessage: budgetTypeMessage, budgetValue: budgetValue })}{' '}
          {t('This will be allocated across ad groups based on performance.')}
        </p>
        <p>{t('If you want to edit this budget, edit the campaign.')}</p>
      </>
    )
  }

  if (budgetType === BUDGET_LIFETIME) {
    return (
      <Field
        type="number"
        formik={formik}
        name={BUDGET_LIFETIME}
        label={t('Lifetime Budget')}
        symbol={currencySymbol}
        autoComplete="off"
      />
    )
  }

  if (budgetType === BUDGET_DAILY) {
    return (
      <Field
        type="number"
        formik={formik}
        name={BUDGET_DAILY}
        label={t('Daily Budget')}
        symbol={currencySymbol}
        autoComplete="off"
      />
    )
  }

  return null
}

BudgetContent.propTypes = {
  formik: PropTypes.object.isRequired,
  budgetType: PropTypes.string.isRequired
}

export default BudgetContent
