import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import RadioBlock from '../../../../../../components/Form/RadioBlock'
import Button from '../../../../../../components/Button'

import { FORMAT_OPTION, FORMAT_SINGLE, FORMAT_CAROUSEL, FORMAT_PRODUCT } from '../../../fields'

import useStyles from '../../../../../../styles/common/stepForms'

const AdCreateRouteSelector = ({ manageStepFormRoutes, handleStepChange }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { selectedRoute, handleSelectRoute, routeWasSelected } = manageStepFormRoutes
  // chooseRoute just handle the UI and selectedRoute manage actual route
  const [chooseRoute, setChooseRoute] = useState(selectedRoute)

  const handleContinue = () => {
    if (selectedRoute === chooseRoute) {
      // route wasn't change
      handleStepChange()
    } else {
      // selected new route
      handleSelectRoute(chooseRoute)
    }
  }

  useEffect(() => {
    routeWasSelected && handleStepChange()
    // eslint-disable-next-line
  }, [])

  // The answers to this question will define which route the user takes (Route A, Route B or Route C)
  return (
    <>
      <div className={classes.stepTitle}>{t('Which type of ad do you want to create?')}</div>
      <div className={classes.stepBody}>
        <RadioBlock
          setFieldValue={(fieldName, route) => setChooseRoute(route)}
          id="radio_format_product_ad"
          name={FORMAT_OPTION}
          value={FORMAT_PRODUCT}
          selectedValue={chooseRoute}
          label={t('Brand or product page ad')}
        >
          <p>
            {t(
              'Ad content and imagery generated from your selected product page and linking back to a specific product.'
            )}
          </p>
        </RadioBlock>
        <RadioBlock
          setFieldValue={(fieldName, route) => setChooseRoute(route)}
          id="radio_format_single"
          name={FORMAT_OPTION}
          value={FORMAT_SINGLE}
          selectedValue={chooseRoute}
          label={t('A newsfeed ad with a single image or video')}
        />
        <RadioBlock
          setFieldValue={(fieldName, route) => setChooseRoute(route)}
          id="radio_format_carousel"
          name={FORMAT_OPTION}
          value={FORMAT_CAROUSEL}
          selectedValue={chooseRoute}
          label={t('A newsfeed carousel ad with multiple images or videos')}
        />
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" className="dark" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default AdCreateRouteSelector
