import { call, put, all, takeEvery, select, takeLatest } from 'redux-saga/effects'
import { format } from 'date-fns'

import { showToasts } from '../../helpers/toasts'
import { combinedCampaignsInsights } from '../../pages/MediaOrderSummary/hooks/useLoadCombinedCampaigns'

import {
  GET_CAMPAIGN,
  getCampaignSuccess,
  getCampaignFailure,
  GET_CAMPAIGNS,
  getCampaignsSuccess,
  getCampaignsPaginationSuccess,
  getCampaignsFailure,
  CREATE_CAMPAIGN,
  createCampaignSuccess,
  createCampaignFailure,
  UPDATE_CAMPAIGN,
  updateCampaignSuccess,
  updateCampaignFailure,
  DELETE_CAMPAIGN,
  deleteCampaignSuccess,
  deleteCampaignFailure,
  GET_SHORT_INFO_CAMPAIGNS,
  getShortInfoCampaignsSuccess,
  getShortInfoCampaignsFailure,
  GET_CAMPAIGN_CRITERIONS,
  getCampaignCriterionsSuccess,
  getCampaignCriterionsFailure,
  GET_CAMPAIGN_AD_SCHEDULE_CRITERIONS,
  getCampaignAdScheduleCriterionsSuccess,
  getCampaignAdScheduleCriterionsFailure,
  CREATE_CAMPAIGN_CRITERIONS,
  createCampaignCriterionsSuccess,
  createCampaignScheduleCriterionsSuccess,
  createCampaignCriterionsFailure,
  deleteCampaignCriterions,
  duplicateCampaignSuccess,
  duplicateCampaignFailure,
  DUPLICATE_CAMPAIGN
} from '../actions/campaigns'

import { updateCurrentUserProfile } from '../actions/app'
import {
  getCombinedCampaigns,
  getCombinedCampaignsSuccess,
  UPDATE_COMBINED_CAMPAIGN,
  updateCombinedCampaign
} from '../actions/combinedData'
import { combinedCampaignsSelector } from '../selectors/combinedData'
import {
  selectedAdAccountIdByPlatformSelector,
  selectedPlatformSelector,
  selectedSelfAccountDataSelector
} from '../selectors/app'

import {
  getCampaignsService,
  getCampaignService,
  createCampaignService,
  deleteCampaignService,
  updateCampaignService,
  getShortInfoCampaignsService,
  getCampaignCriterionsService,
  getCampaignAdScheduleCriterionsService,
  createCampaignCriterionsService,
  createCampaignCriterionScheduleService,
  createCampaignCriterionProximityService,
  duplicateCampaignService
} from '../services/campaigns'
import { criterionTypes } from '../../constants/campaigns'
import { TOAST_TYPE } from '../../constants/other'
import { DATES_FORMAT_BE } from '../../constants/dates'
import { getAdAccountFieldNameByPlatform, PROVIDER_TO_PLATFORMS } from '../../constants/selectLists/platformList'
import { mediaOrderCampaignsDateRangeSelector } from '../selectors/mediaOrders'

function* campaignsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_CAMPAIGN, getCampaignWorker),
    takeLatest(GET_CAMPAIGNS, getCampaignsWorker),
    takeEvery(GET_SHORT_INFO_CAMPAIGNS, getShortInfoCampaignsWorker),
    takeEvery(CREATE_CAMPAIGN, createCampaignWorker),
    takeEvery(UPDATE_CAMPAIGN, updateCampaignWorker),
    takeEvery(DELETE_CAMPAIGN, deleteCampaignWorker),
    takeEvery(GET_CAMPAIGN_CRITERIONS, getCampaignCriterionsWorker),
    takeEvery(GET_CAMPAIGN_AD_SCHEDULE_CRITERIONS, getCampaignAdScheduleCriterionsWorker),
    takeEvery(CREATE_CAMPAIGN_CRITERIONS, createCampaignCriterionsWorker),
    takeEvery(DUPLICATE_CAMPAIGN, duplicateCampaignWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getCampaignWorker({ params, platform }) {
  try {
    const selectedPlatform = yield select(selectedPlatformSelector)
    // On AdApprovals page we need to fetch campaign when edit facebook ad,
    // but we don't have selectedPlatform on this page, therefore we pass it as a param
    const currentPlatform = platform || selectedPlatform
    const response = yield call(getCampaignService, params, currentPlatform)
    yield put(getCampaignSuccess(response))
  } catch (e) {
    yield put(getCampaignFailure(e))
  }
}

function* getCampaignsWorker({ params }) {
  try {
    const platform = yield select(selectedPlatformSelector)
    const response = yield call(getCampaignsService, params, platform)

    // if request is called with after param (meaning user used pagination with Load more button)
    // then use different action for pushing items and not re-setting whole state
    if (params.after) {
      yield put(getCampaignsPaginationSuccess(response))
    } else {
      yield put(getCampaignsSuccess(response))
    }
  } catch (e) {
    yield put(getCampaignsFailure(e))
  }
}

function* getShortInfoCampaignsWorker({ params }) {
  try {
    const response = yield call(getShortInfoCampaignsService, params)
    yield put(getShortInfoCampaignsSuccess(response))
  } catch (e) {
    yield put(getShortInfoCampaignsFailure(e))
  }
}

function* createCampaignWorker({ data, platform, createOptions }) {
  try {
    const selectedPlatform = yield select(selectedPlatformSelector)
    const { campaignData, updatedSettings, userId } = data
    const { pushToCombinedCampaignsList } = createOptions

    // if platform doesn't provided take selectedPlatform
    const createdCampaignData = yield call(createCampaignService, campaignData, platform || selectedPlatform)
    yield put(createCampaignSuccess(createdCampaignData))

    if (updatedSettings) {
      yield put(
        updateCurrentUserProfile({
          userId,
          userData: updatedSettings,
          partialUpdate: true
        })
      )
    }

    if (pushToCombinedCampaignsList) {
      yield call(handleCombinedCampaignsListUpdate, createdCampaignData, platform)
    }
  } catch (e) {
    if (e.errors?.performance_goal_type) {
      showToasts({
        type: TOAST_TYPE.error,
        message: e.errors?.performance_goal_type
      })
    }

    yield put(createCampaignFailure(e))
  }
}

function* handleCombinedCampaignsListUpdate(createdCampaignData, platform) {
  const combinedCampaigns = yield select(combinedCampaignsSelector)
  const adAccountId = yield select(selectedAdAccountIdByPlatformSelector(platform))
  const { campaigns = [], campaigns_date_range: combinedCampaignsDateRange } = combinedCampaigns

  if (!combinedCampaignsDateRange) {
    // if we don't have combinedCampaignsDateRange - we need to fetch it, because we use it for all next requests
    const mediaOrderCampaignsDateRange = yield select(mediaOrderCampaignsDateRangeSelector)
    const { date_preset: datePreset, startDate, endDate } = mediaOrderCampaignsDateRange

    yield put(
      getCombinedCampaigns({
        insights: combinedCampaignsInsights,
        date_from: startDate && format(startDate, DATES_FORMAT_BE),
        date_to: endDate && format(endDate, DATES_FORMAT_BE),
        date_preset: datePreset,
        providers: [
          {
            ad_account_id: adAccountId,
            platform: PROVIDER_TO_PLATFORMS[createdCampaignData?.provider],
            campaigns: [createdCampaignData?.id]
          }
        ]
      })
    )
  } else {
    // if we already have combinedCampaignsDateRange - we can just add new campaign to store
    const adAccountFieldName = getAdAccountFieldNameByPlatform(platform)
    const { [adAccountFieldName]: adAccountData = {} } = yield select(selectedSelfAccountDataSelector)
    const { currency_symbol: currencySymbol } = adAccountData
    const newCampaign = {
      ...createdCampaignData,
      ...(currencySymbol && { currency_symbol: currencySymbol }),
      ad_account_id: createdCampaignData.account
    }
    yield put(getCombinedCampaignsSuccess({ ...combinedCampaigns, campaigns: [newCampaign, ...campaigns] }))
  }
}

function* updateCampaignWorker({ campaignData, id, updateOptions }) {
  try {
    const { platform, updateType, requestMethod } = updateOptions

    const selectedPlatform = yield select(selectedPlatformSelector)
    const response = yield call(updateCampaignService, campaignData, id, platform || selectedPlatform, requestMethod)

    yield put(updateCampaignSuccess(response))

    // sometimes we need to also update data inside combined campaigns store
    if (updateType === UPDATE_COMBINED_CAMPAIGN) {
      yield put(updateCombinedCampaign(response))
    }
  } catch (e) {
    yield put(updateCampaignFailure(e))
  }
}

function* duplicateCampaignWorker({ duplicateData, id, platform }) {
  try {
    const response = yield call(duplicateCampaignService, duplicateData, id, platform)
    yield put(duplicateCampaignSuccess(response))
  } catch (e) {
    yield put(duplicateCampaignFailure(e))
  }
}

function* deleteCampaignWorker({ params }) {
  try {
    const platform = yield select(selectedPlatformSelector)
    yield call(deleteCampaignService, params, platform)
    yield put(deleteCampaignSuccess(params.id))
  } catch (e) {
    yield put(deleteCampaignFailure(e))
  }
}

function* getCampaignCriterionsWorker({ params }) {
  try {
    const response = yield call(getCampaignCriterionsService, params)
    yield put(getCampaignCriterionsSuccess(response))
  } catch (e) {
    yield put(getCampaignCriterionsFailure(e))
  }
}

function* getCampaignAdScheduleCriterionsWorker({ params }) {
  try {
    const response = yield call(getCampaignAdScheduleCriterionsService, params)
    yield put(getCampaignAdScheduleCriterionsSuccess(response))
  } catch (e) {
    yield put(getCampaignAdScheduleCriterionsFailure(e))
  }
}

function* createCampaignCriterionsWorker({ criterionsData, criterionType, criterionsToDelete }) {
  try {
    let response

    switch (criterionType) {
      case criterionTypes.proximity:
        response = yield call(createCampaignCriterionProximityService, criterionsData)
        break
      case criterionTypes.schedule:
        response = yield call(createCampaignCriterionScheduleService, criterionsData)
        break
      default:
        response = yield call(createCampaignCriterionsService, criterionsData)
    }

    if (criterionType === criterionTypes.schedule) {
      yield put(createCampaignScheduleCriterionsSuccess(response))
    } else {
      yield put(createCampaignCriterionsSuccess(response))
    }

    if (criterionsToDelete && criterionsToDelete.length) {
      // filter inner store of criterions when using remove_operations in create request
      yield put(deleteCampaignCriterions(criterionsToDelete))
    }
  } catch (e) {
    yield put(createCampaignCriterionsFailure(e))
  }
}

export default campaignsWatcher
