import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Portal } from 'react-portal'
import { useTranslation } from 'react-i18next'

import Form from '../../../../../components/Form'
import CancelMediaOrder from './CancelMediaOrder'
import Button from '../../../../../components/Button'
import ContractBuilder from '../../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder'

import { MediaOrderFormContext } from '../../../../../forms/Multiplatform/MediaOrderForms/MediaOrderFormContext'
import { ContractBuilderContext } from '../../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/ProductsManage/ContractBuilderContext'

import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'
import { useRequiredFields } from '../../../../../forms/Multiplatform/MediaOrderForms/MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/hooks/useRequiredFields'

import { createAmendment, clearCreateAmendment } from '../../../../../modules/actions/amendments'
import { contractSelector } from '../../../../../modules/selectors/contracts'
import { mediaOrderSelector } from '../../../../../modules/selectors/mediaOrders'
import { selectedControllerIdSelector, selectedSelfAccountSelector } from '../../../../../modules/selectors/app'
import {
  amendmentWasCreatedSelector,
  createAmendmentErrorSelector,
  createAmendmentIsLoadingSelector,
  createdAmendmentSelector
} from '../../../../../modules/selectors/amendments'

import { transformValuesToBE } from '../../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/formatters'
import { getInitialValues } from '../../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/fields'
import { getValidationSchema } from '../../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/validation'

import { MEDIA_ORDER_AMEND_CONTRACT_CREATE } from '../../../../../constants/forms'

import useStyles from './styles'

const ContractDetails = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  const { contextSelfAccountData } = useContext(MediaOrderFormContext)
  const { editProductId } = useContext(ContractBuilderContext)

  const mediaOrder = useSelector(mediaOrderSelector)
  const controllerId = useSelector(selectedControllerIdSelector)
  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)
  const amendmentContract = useSelector(createdAmendmentSelector)
  const amendmentWasCreated = useSelector(amendmentWasCreatedSelector)
  const createAmendmentIsLoading = useSelector(createAmendmentIsLoadingSelector)
  // loaded contract is used to get initial values for the form
  const loadedContract = useSelector(contractSelector)

  const [activeContract, setActiveContract] = useState(loadedContract)

  const mediaOrderId = mediaOrder.id
  const isOrderCancelled = mediaOrder?.status === 'cancelled'

  const [isAmendmentProcess, setIsAmendmentProcess] = useState(false)
  const [isAdditionalInfoEdit, setIsAdditionalInfoEdit] = useState(false)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE({
        selfAccountData: contextSelfAccountData,
        controllerId,
        selectedCurrency: mediaOrder.currency,
        values
      })

      const amendmentData = {
        detail: {
          ...transformedData,
          account: selectedSelfAccount,
          controller: controllerId,
          // copy paste fields from media order:
          name: mediaOrder.name
        },
        amendment: true,
        // This field is used to manually update the cost of the amendment
        set_amendment_cost: true,
        original_media_order: mediaOrderId,
        account: selectedSelfAccount,
        controller: controllerId,
        without_requirements: mediaOrder.without_requirements
      }

      dispatch(createAmendment(amendmentData))
    },
    [contextSelfAccountData, controllerId, mediaOrder, selectedSelfAccount, mediaOrderId, dispatch]
  )

  const clearCreateHandler = useCallback(() => dispatch(clearCreateAmendment()), [dispatch])

  const initialValues = useMemo(() => {
    return getInitialValues(activeContract)
  }, [activeContract])

  const { askBrandName, askCampaignName, isBrandRequired } = useRequiredFields()
  const validationSchema = useMemo(() => {
    return getValidationSchema({
      askBrandName,
      askCampaignName,
      isBrandRequired
    })
  }, [askBrandName, askCampaignName, isBrandRequired])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)
  const { resetForm } = purifiedFormik

  const handleStopAmendmentProcess = useCallback(() => {
    setIsAmendmentProcess(true)
    resetForm()
  }, [resetForm])

  const cancelAmendmentProcessBtn = useMemo(() => {
    return (
      <Button
        type="button"
        onClick={() => {
          setIsAmendmentProcess(false)
          resetForm()
        }}
        className={classes.cancelAmendmentButton}
        disabled={createAmendmentIsLoading}
      >
        {t('Cancel')}
      </Button>
    )
  }, [classes.cancelAmendmentButton, createAmendmentIsLoading, resetForm, t])

  useEffect(() => {
    if (amendmentWasCreated) {
      setActiveContract(amendmentContract)
    }
  }, [amendmentWasCreated, amendmentContract, setActiveContract])

  return (
    <Form
      submitText="Generate Amendment"
      formik={purifiedFormik}
      showSubmit={isAmendmentProcess}
      buttonProps={{ disabled: Boolean(editProductId) || isAdditionalInfoEdit }}
      componentBeforeSubmit={isAmendmentProcess ? cancelAmendmentProcessBtn : null}
      formName={MEDIA_ORDER_AMEND_CONTRACT_CREATE}
      className={classes.contractProductsTable}
      formFooterClassName={classes.formSubmitFooter}
      // processing
      successSubmit={amendmentWasCreated}
      errorSelector={createAmendmentErrorSelector}
      isLoadingSelector={createAmendmentIsLoadingSelector}
      // after form submit
      clearHandler={clearCreateHandler}
      // set edit more after successful creation to use update handlers and selectors
      onSuccessSubmit={() => setIsAmendmentProcess(false)}
    >
      <Portal node={document && document.getElementById('mediaOrderInfoFieldsPortal')}>
        {!isOrderCancelled && !isAmendmentProcess && (
          <div className={classes.mediaOrderActions}>
            <CancelMediaOrder mediaOrderId={mediaOrderId} isDisabled={createAmendmentIsLoading} />
            <Button
              type="button"
              onClick={handleStopAmendmentProcess}
              isSmall={true}
              solid={true}
              disabled={createAmendmentIsLoading}
            >
              {t('Amend Booking')}
            </Button>
          </div>
        )}
      </Portal>
      <ContractBuilder
        formik={purifiedFormik}
        hasTopMargin={false}
        isAdditionalInfoEdit={isAdditionalInfoEdit}
        setIsAdditionalInfoEdit={setIsAdditionalInfoEdit}
        allowEdit={isAmendmentProcess}
        allowAutoSave={false}
        checkInventory={false}
        isAmendment={true}
      />
    </Form>
  )
}

export default ContractDetails
