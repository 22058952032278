import { createStyles } from '../../../../../../../styles/helpers'

import { red, textGrey } from '../../../../../../../styles/const/colors'

const useStyles = createStyles({
  deadline: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    fontWeight: 400,
    color: textGrey
  },
  deadlineInDays: {
    fontSize: 12,
    marginTop: 3
  },
  deadlineInDaysError: {
    color: red,
    fontSize: 12,
    marginTop: 3
  }
})

export default useStyles
