import { ReactComponent as FbIcon } from '../../assets/logos/fb-icon.svg'
import { ReactComponent as GoogleIcon } from '../../assets/logos/google-icon.svg'
import { ReactComponent as Dv360Icon } from '../../assets/logos/dv360/dv360-icon.svg'
import { ReactComponent as TikTokIcon } from '../../assets/logos/tt-icon.svg'

export const FACEBOOK_PLATFORM = 'fb'
export const GOOGLE_PLATFORM = 'ga'
export const DV_360_PLATFORM = 'dv360'
export const TIKTOK_PLATFORM = 'tiktok'

export const FACEBOOK_PROVIDER_PLATFORM = 'facebook'
export const GOOGLE_PROVIDER_PLATFORM = 'google'
export const DV_360_PROVIDER_PLATFORM = 'dv360'
export const TIKTOK_PROVIDER_PLATFORM = 'tiktok'

export const PROVIDER_TO_PLATFORMS = {
  [FACEBOOK_PROVIDER_PLATFORM]: FACEBOOK_PLATFORM,
  [GOOGLE_PROVIDER_PLATFORM]: GOOGLE_PLATFORM,
  [DV_360_PROVIDER_PLATFORM]: DV_360_PLATFORM,
  [TIKTOK_PROVIDER_PLATFORM]: TIKTOK_PLATFORM
}

export const PLATFORMS_TO_PROVIDER = {
  [FACEBOOK_PLATFORM]: FACEBOOK_PROVIDER_PLATFORM,
  [GOOGLE_PLATFORM]: GOOGLE_PROVIDER_PLATFORM,
  [DV_360_PLATFORM]: DV_360_PROVIDER_PLATFORM,
  [TIKTOK_PLATFORM]: TIKTOK_PROVIDER_PLATFORM
}

export const PLATFORM_LABELS = {
  [FACEBOOK_PLATFORM]: 'Facebook',
  [GOOGLE_PLATFORM]: 'Google Ads',
  [DV_360_PLATFORM]: 'Display Video 360',
  [TIKTOK_PLATFORM]: 'TikTok'
}
export const platformList = [
  {
    label: 'Facebook',
    value: FACEBOOK_PLATFORM,
    description: 'Facebook & Instagram',
    icon: FbIcon
  },
  {
    label: 'Google Ads',
    value: GOOGLE_PLATFORM,
    description: 'Display & Search',
    icon: GoogleIcon
  },
  {
    label: 'DV360',
    value: DV_360_PLATFORM,
    description: 'Display Video 360',
    icon: Dv360Icon
  },
  {
    label: 'TikTok',
    value: TIKTOK_PLATFORM,
    description: 'TikTok',
    icon: TikTokIcon
  }
]

export default platformList

export const getAdAccountFieldNameByPlatform = platform => {
  switch (platform) {
    case FACEBOOK_PLATFORM:
      return 'fb_ad_account'
    case GOOGLE_PLATFORM:
      return 'ga_ad_account'
    case DV_360_PLATFORM:
      return 'dv360_ad_account'
    case TIKTOK_PLATFORM:
      return 'tiktok_ad_account'
    default:
      return ''
  }
}
