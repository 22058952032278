import { FACEBOOK_PLATFORM, GOOGLE_PLATFORM, TIKTOK_PLATFORM } from '../../constants/selectLists/platformList'
import { CAMPAIGN_FACEBOOK_EDIT, CAMPAIGN_GOOGLE_ADS_EDIT, CAMPAIGN_TIKTOK_EDIT } from '../../constants/forms'

export const getCampaignEditFormNameByPlatform = selectedPlatform => {
  switch (selectedPlatform) {
    case FACEBOOK_PLATFORM:
      return CAMPAIGN_FACEBOOK_EDIT
    case GOOGLE_PLATFORM:
      return CAMPAIGN_GOOGLE_ADS_EDIT
    case TIKTOK_PLATFORM:
      return CAMPAIGN_TIKTOK_EDIT
    default:
      return ''
  }
}
