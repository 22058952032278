import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { useLoadPaginatedMediaOrders } from '../../../../MediaOrders/hooks'

import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'

import { formatSetupFilters } from '../../../../LandingPage/ControllerLandingPageContent/ManagementReporting/Dashboard/helpers'

import { validMediaOrdersStatuses } from '../../../../../constants/mediaOrders'
import { formatDateToBE } from '../../../../../constants/dates'

export const BookingsFiltersContext = React.createContext()

export function BookingsDataProvider({ children }) {
  const controllerId = useSelector(selectedControllerIdSelector)

  const [createdDateRangeFilter, setCreatedDateRangeFilter] = useState({
    startDate: '',
    endDate: ''
  })
  const [dateRangeFilter, setDateRangeFilter] = useState({
    startDate: '',
    endDate: ''
  })
  // account
  const [multipleAccountFilterOption, setMultipleAccountFilterOption] = useState([])
  const [multipleControllerMembersFilter, setMultipleControllerMembersFilter] = useState([])

  const filterRequestParams = useMemo(() => {
    return {
      controller: controllerId,
      status: validMediaOrdersStatuses,
      // account
      ...(multipleAccountFilterOption?.length && { account: multipleAccountFilterOption }),
      // created
      ...(createdDateRangeFilter.startDate && {
        created_after: formatDateToBE(new Date(createdDateRangeFilter.startDate))
      }),
      ...(createdDateRangeFilter.endDate && {
        created_before: formatDateToBE(new Date(createdDateRangeFilter.endDate))
      }),
      // start_date
      ...(dateRangeFilter.startDate && {
        start_date_after: formatDateToBE(new Date(dateRangeFilter.startDate))
      }),
      ...(dateRangeFilter.endDate && {
        start_date_before: formatDateToBE(new Date(dateRangeFilter.endDate))
      }),
      // created_by
      ...(multipleControllerMembersFilter?.length && { created_by: multipleControllerMembersFilter })
    }
  }, [
    multipleAccountFilterOption,
    controllerId,
    createdDateRangeFilter,
    dateRangeFilter,
    multipleControllerMembersFilter
  ])

  const paginatedMediaOrdersParams = useMemo(() => formatSetupFilters(filterRequestParams), [filterRequestParams])

  const loadMoreMediaOrdersHandler = useLoadPaginatedMediaOrders(paginatedMediaOrdersParams)

  const bookingsFilterProps = useMemo(() => {
    return {
      filterRequestParams,
      loadMoreMediaOrdersHandler,
      multipleAccountFilterOption,
      setMultipleAccountFilterOption,
      createdDateRangeFilter,
      setCreatedDateRangeFilter,
      dateRangeFilter,
      setDateRangeFilter,
      multipleControllerMembersFilter,
      setMultipleControllerMembersFilter
    }
  }, [
    filterRequestParams,
    loadMoreMediaOrdersHandler,
    multipleAccountFilterOption,
    setMultipleAccountFilterOption,
    createdDateRangeFilter,
    setCreatedDateRangeFilter,
    dateRangeFilter,
    setDateRangeFilter,
    multipleControllerMembersFilter,
    setMultipleControllerMembersFilter
  ])

  return <BookingsFiltersContext.Provider value={bookingsFilterProps}>{children}</BookingsFiltersContext.Provider>
}
