import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

const CardContentSkeleton = ({ contentClassName, imageHeight = 100 }) => {
  const classes = useStyles()

  return (
    <div className={classnames(classes.content, contentClassName)}>
      <Skeleton height={imageHeight} />
    </div>
  )
}

CardContentSkeleton.propTypes = {
  contentClassName: PropTypes.string,
  imageHeight: PropTypes.number
}

export default CardContentSkeleton
