import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import UploadedFilesField from '../../../../../../../features/components/UploadedFilesField'

import { getFileIdsForBulkUpdate } from './helpers'
import { useOpenMediaOrderSummaryInNewTab } from '../hooks'
import {
  BUYER_DISAPPROVED,
  DISAPPROVED,
  getApprovalActionStatus,
  getStatusForDisapprovalAction
} from '../../../../../../../features/components/UploadedFilesField/helpers'

import { bulkUpdateBookingMediaFile } from '../../../../../../../modules/actions/mediaOrdersBookings'
import { isControllerBuyerApprovalSelector } from '../../../../../../../modules/selectors/app'
import {
  BUYER_DISAPPROVED_REASON,
  DISAPPROVED_REASON
} from '../../../../../../../features/components/Modals/AskReasonModal/fields'

const UploadedFilesCell = ({
  uploaded_files: uploadedFiles,
  bookedMediaId,
  sequentialIds,
  sequentialList,
  id,
  mediaOrderId,
  accountId
}) => {
  const dispatch = useDispatch()

  const isSubRow = useMemo(() => !sequentialIds && id, [sequentialIds, id])

  const isControllerBuyerApproval = useSelector(isControllerBuyerApprovalSelector)

  const onApproveHandler = useCallback(
    ({ uploadedFile }) => {
      const ids = getFileIdsForBulkUpdate(uploadedFile, sequentialList)

      dispatch(
        bulkUpdateBookingMediaFile({
          ids,
          approval_status: getApprovalActionStatus(isControllerBuyerApproval, uploadedFile),
          booked_media: uploadedFile.booked_media,
          sequentialIds
        })
      )
    },
    [dispatch, sequentialList, isControllerBuyerApproval, sequentialIds]
  )

  const onDisapproveHandler = useCallback(
    ({ uploadedFile, [DISAPPROVED_REASON]: disapprovalReason }) => {
      const ids = getFileIdsForBulkUpdate(uploadedFile, sequentialList)

      const disapprovalStatus = getStatusForDisapprovalAction(uploadedFile, isControllerBuyerApproval)

      dispatch(
        bulkUpdateBookingMediaFile({
          ids,
          approval_status: disapprovalStatus,
          ...(disapprovalReason && disapprovalStatus === DISAPPROVED && { [DISAPPROVED_REASON]: disapprovalReason }),
          ...(disapprovalReason &&
            disapprovalStatus === BUYER_DISAPPROVED && { [BUYER_DISAPPROVED_REASON]: disapprovalReason }),
          booked_media: uploadedFile.booked_media,
          sequentialIds
        })
      )
    },
    [dispatch, sequentialList, isControllerBuyerApproval, sequentialIds]
  )

  const goToMediaOrderSummaryHandler = useOpenMediaOrderSummaryInNewTab({ mediaOrderId, accountId })

  if (
    !uploadedFiles?.length ||
    // don't show files on sub rows
    isSubRow
  ) {
    return null
  }

  return (
    <UploadedFilesField
      uploadedFiles={uploadedFiles}
      bookedMediaId={bookedMediaId}
      onApproveHandler={onApproveHandler}
      onDisapproveHandler={onDisapproveHandler}
      goToMediaOrderSummaryHandler={goToMediaOrderSummaryHandler}
    />
  )
}

UploadedFilesCell.propTypes = {
  uploaded_files: PropTypes.array,
  bookedMediaId: PropTypes.number,
  sequentialIds: PropTypes.array,
  sequentialList: PropTypes.array,
  id: PropTypes.number,
  mediaOrderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default UploadedFilesCell
