import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

import { calc } from '../../../../../../../../../../../helpers/numbers'

import { SelectPeriodsFieldsContext } from '../SelectPeriodsFieldsContextProvider'

import useStyles from './styles'

function DiscountsSuggestions({ publicationQuantity }) {
  const classes = useStyles()

  const { productDiscounts } = useContext(SelectPeriodsFieldsContext)

  const sortedDiscounts = useMemo(() => {
    return (
      // lowest discount first:
      [...productDiscounts].sort((a, b) => {
        if (a.value > b.value) return 1
        if (a.value < b.value) return -1

        return 0
      })
    )
  }, [productDiscounts])

  const nextDiscount = useMemo(() => {
    return (
      sortedDiscounts &&
      sortedDiscounts.find(({ value }) => {
        return value > publicationQuantity
      })
    )
  }, [sortedDiscounts, publicationQuantity])

  return (
    !!publicationQuantity &&
    nextDiscount && (
      <p className={classes.discountHint}>
        {/* calc is used to avoid cases of calculation like 0.07 * 100 = 7,000000001 */}
        Add {nextDiscount.value - publicationQuantity} more for a{' '}
        {calc(nextDiscount.discount_percentage).mul(100).toString()}% discount
      </p>
    )
  )
}

DiscountsSuggestions.propTypes = {
  publicationQuantity: PropTypes.number
}
export default DiscountsSuggestions
