import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const MobileHeaderWithTextInBrackets = ({ mainText, bracketsText, containerClassName, bracketsTextClassName }) => {
  const { t } = useTranslation()

  return (
    <span className={containerClassName}>
      {mainText ? t(mainText) : '-'}{' '}
      <span className={bracketsTextClassName}>({bracketsText ? t(bracketsText) : '-'})</span>
    </span>
  )
}

MobileHeaderWithTextInBrackets.propTypes = {
  mainText: PropTypes.string,
  bracketsText: PropTypes.string,
  containerClassName: PropTypes.string,
  bracketsTextClassName: PropTypes.string
}

export default MobileHeaderWithTextInBrackets
