import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import NameSectionForm from '../../../../../ReusableFormSections/NameSection/NameSectionForm'

import {
  lineItemUpdateErrorSelector,
  lineItemUpdateIsLoadingSelector,
  lineItemWasUpdatedSelector
} from '../../../../../../modules/selectors/lineItems'
import { campaignSelector } from '../../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'
import { clearUpdateLineItem, updateLineItem } from '../../../../../../modules/actions/lineItems'

const NameSection = ({ editItemData: lineItem, ...formProps }) => {
  const dispatch = useDispatch()

  const lineItemWasUpdated = useSelector(lineItemWasUpdatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const { objective: campaignObjective } = useSelector(campaignSelector)

  const handleSubmit = useCallback(
    values => {
      const updateLineItemData = {
        account: selectedAdAccountId,
        campaign_objective: campaignObjective,
        ...values
      }

      dispatch(updateLineItem(updateLineItemData, lineItem.id))
    },
    [dispatch, lineItem.id, campaignObjective, selectedAdAccountId]
  )

  const handleClearUpdateLineItem = useCallback(() => {
    dispatch(clearUpdateLineItem())
  }, [dispatch])

  return (
    <NameSectionForm
      placeholder="Ad Set Name"
      editItem={lineItem}
      handleSubmit={handleSubmit}
      clearEditItem={handleClearUpdateLineItem}
      successSubmit={lineItemWasUpdated}
      isLoadingSelector={lineItemUpdateIsLoadingSelector}
      errorSelector={lineItemUpdateErrorSelector}
      {...formProps}
    />
  )
}

export default NameSection
