import * as Yup from 'yup'

import { AD_FORMAT } from '../../../../../../../constants/ads'

import { CAROUSEL_ITEMS, FORMAT_OPTION } from '../../../../fields'
import { mediaItemSchema, mediaItemShape } from '../../../../validation'

export function getValidationSchema() {
  return Yup.object({
    [CAROUSEL_ITEMS]: Yup.array().when(FORMAT_OPTION, {
      is: AD_FORMAT.carousel,
      then: () => Yup.array().of(mediaItemSchema),
      otherwise: () => Yup.array().notRequired()
    }),
    singleMediaItem: Yup.object().when(FORMAT_OPTION, {
      is: AD_FORMAT.single,
      then: () => Yup.object().shape(mediaItemShape),
      otherwise: () => Yup.object().notRequired()
    })
  })
}
