import { addDays, getDay, getHours, setHours } from 'date-fns'

import { formatCurrency, formatNumber } from '../../helpers/numbers'

import { spreadSheetInitialData } from '../../components/SpreadSheet'
import { EMPTY_SELECT_VALUE } from '../../constants/selectLists'
import { scheduleSelectorMondayDate } from '../../constants/dates'

export const formatSelectValue = (value, options) => {
  if (value || value === 0) {
    let selectedValue = options.find(option => option.value.toString() === value.toString())
    return selectedValue ? { ...selectedValue, value: value, label: selectedValue.label } : EMPTY_SELECT_VALUE
  } else {
    return EMPTY_SELECT_VALUE
  }
}

export function formatOptionsList({ list, valueName = null, labelName = null, tagName, ...additionalFields }) {
  // set valueName or labelName to null for list array of strings
  if (list && !!list.length) {
    return list.map(listItem => ({
      ...listItem,
      value: valueName ? listItem[valueName] : listItem,
      label: labelName ? listItem[labelName] : listItem,
      ...(tagName && {
        tagName: listItem[tagName],
        tag: listItem[tagName]
      }),
      ...additionalFields
    }))
  } else {
    return []
  }
}

export function formatReportNumber({ value, format = 'decimal', currency }) {
  if (format === 'decimal') {
    return formatNumber(value, { min: 2, max: 2 })
  } else if (format === 'currency') {
    return formatCurrency(value, { min: 2, max: 2 }, { symbol: currency })
  } else {
    return formatNumber(value, { max: 0 })
  }
}

export function formatDigitalItemValues(values, currency) {
  const {
    impressions,
    cpm,
    ctr,
    clicks,
    cpc,
    reach,
    frequency,
    engagement,
    engagement_rate,
    spend,
    budget_lifetime,
    purchase,
    roas,
    active_view_impressions,
    video_views,
    video_view_rate
  } = values

  return {
    impressions: impressions && formatNumber(impressions),
    cpm: cpm && formatCurrency(cpm, { min: 2, max: 2 }, currency),
    reach: reach && formatNumber(reach),
    frequency: frequency && formatNumber(frequency, { min: 2, max: 2 }),
    clicks: clicks && formatNumber(clicks),
    ctr: ctr && `${formatNumber(ctr, { min: 2, max: 2 })}%`,
    cpc: cpc && formatNumber(cpc, { min: 2, max: 2 }),
    engagement: engagement && formatNumber(engagement),
    engagement_rate: engagement_rate && formatNumber(engagement_rate),
    purchase: purchase && formatNumber(purchase),
    roas: roas && formatNumber(roas, { min: 2, max: 2 }),
    budget_lifetime: budget_lifetime && formatCurrency(budget_lifetime, { min: 2, max: 2 }, currency),
    spend: spend && formatCurrency(spend, { min: 2, max: 2 }, currency),
    active_view_impressions: active_view_impressions && formatNumber(active_view_impressions),
    video_views: video_views && formatNumber(video_views),
    video_view_rate: video_view_rate && `${formatNumber(video_view_rate * 100, { min: 2, max: 2 })}%`
  }
}

export const formatSortParams = sort => {
  return sort.value && sort.value !== '' && { sort: sort.value.split('_')[0], sort_reverse: sort.sort_reverse }
}
export const formatScheduleDatesToBE = dates => {
  let schedule = {}

  dates.forEach(date => {
    // get number of week day, e.g. 0 - Sunday, 5 - Friday
    const weekDayNumber = getDay(new Date(date))
    const hours = getHours(new Date(date))

    schedule[weekDayNumber] = schedule[weekDayNumber] ? [...schedule[weekDayNumber], hours] : [hours]
  })

  return schedule
}
export const formatScheduleDatesToFE = schedule => {
  // 0 - Sunday, 6 - Saturday
  const dates = []

  for (let dayNumber in schedule) {
    if (schedule.hasOwnProperty(dayNumber)) {
      let daysToAdd = 0

      if (dayNumber === '0') {
        // if day is 0(Sunday) - we should add 6 days
        daysToAdd = 6
      } else {
        // else we should use "day count - 1"
        daysToAdd = Number(dayNumber) - 1
      }

      // create date by adding needed number of days to initial one
      const createdDate = addDays(new Date(scheduleSelectorMondayDate), daysToAdd)

      schedule[dayNumber].forEach(hours => {
        // set needed hours to created date
        dates.push(setHours(createdDate, hours))
      })
    }
  }

  return dates
}
export const formatRawDataForSpreadSheet = (data, columnLabels) => {
  // start from SpreadSheet initial data and then modify it
  const formattedData = [...spreadSheetInitialData]

  data &&
    data.forEach((item, index) => {
      formattedData[index] = [
        {
          value: item[columnLabels[0]]
        },
        {
          value: item[columnLabels[1]]
        }
      ]
    })

  return formattedData
}

export function formatListItemValues(values, currency) {
  const {
    impressions,
    active_view_impressions,
    cpm,
    ctr,
    clicks,
    cpc,
    reach,
    frequency,
    engagement,
    engagement_rate,
    spend,
    budget_lifetime,
    conversions,
    cpa,
    actions_like,
    actions_post,
    actions_post_reaction,
    video_views,
    video_view_rate
  } = values

  return {
    impressions: getFormattedValue({ value: impressions, type: 'impressions' }),
    active_view_impressions: getFormattedValue({ value: active_view_impressions, type: 'active_view_impressions' }),
    cpm: getFormattedValue({ value: cpm, type: 'cpm' }),
    reach: getFormattedValue({ value: reach, type: 'reach' }),
    frequency: getFormattedValue({ value: frequency, type: 'frequency' }),
    clicks: getFormattedValue({ value: clicks, type: 'clicks' }),
    ctr: getFormattedValue({ value: ctr, type: 'ctr' }),
    cpc: getFormattedValue({ value: cpc, type: 'cpc' }),
    engagement: getFormattedValue({ value: engagement, type: 'engagement' }),
    engagement_rate: getFormattedValue({ value: engagement_rate, type: 'engagement_rate' }),
    budget_lifetime: getFormattedValue({ value: budget_lifetime, type: 'budget_lifetime', currency }),
    spend: getFormattedValue({ value: spend, type: 'spend', currency }),
    conversions: getFormattedValue({ value: conversions, type: 'conversions' }),
    cpa: getFormattedValue({ value: cpa, type: 'cpa', currency }),
    actions_like: getFormattedValue({ value: actions_like, type: 'actions_like', shouldFormatZero: true }),
    actions_post: getFormattedValue({ value: actions_post, type: 'actions_post', shouldFormatZero: true }),
    actions_post_reaction: getFormattedValue({
      value: actions_post_reaction,
      type: 'actions_post_reaction',
      shouldFormatZero: true
    }),
    video_views: getFormattedValue({ value: video_views, type: 'video_views' }),
    video_view_rate: getFormattedValue({ value: video_view_rate, type: 'video_view_rate' })
  }
}

export const getFormattedValue = ({ value, type, currency, shouldFormatZero }) => {
  if (value || (value === 0 && shouldFormatZero)) {
    switch (type) {
      case 'cpm':
      case 'frequency':
      case 'cpc':
        return formatNumber(value, { min: 2, max: 2 })
      case 'ctr':
        return `${formatNumber(value, { min: 2, max: 2 })}%`
      case 'video_view_rate':
        return `${formatNumber(value * 100, { min: 2, max: 2 })}%`
      case 'spend':
      case 'budget_lifetime':
      case 'cpa':
        return formatCurrency(value, { min: 2, max: 2 }, currency)
      default:
        return formatNumber(value)
    }
  }
}
