import { createStyles } from '../../../styles/helpers'

import { green, red } from '../../../styles/const/colors'

export default createStyles(theme => ({
  btnsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10,
    marginTop: 16
  },
  actionBtn: {
    borderRadius: theme.defaultBorderRadius,
    color: 'white',
    border: 'none',
    width: '50%'
  },
  approveBtnWrapper: {
    maxWidth: '50%'
  },
  approveBtn: {
    background: green,
    width: '100%'
  },
  disapproveBtn: {
    backgroundColor: red
  }
}))
