import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import GoogleAudienceForm from './AudienceForm'

import { formatOptionsList } from '../../../../../features/formatters'

import { clearAudiences, getAudiences } from '../../../../../modules/actions/audiences'
import { createAssetGroupSignal, clearCreateAssetGroupSignal } from '../../../../../modules/actions/assets'
import { audiencesLoadingSelector, audiencesSelector } from '../../../../../modules/selectors/audiences'
import {
  createAssetGroupSignalErrorSelector,
  createAssetGroupSignalIsLoadingSelector,
  createAssetGroupSignalWasCreatedSelector
} from '../../../../../modules/selectors/assets'
import { selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'

import { GOOGLE_PLATFORM } from '../../../../../constants/selectLists/platformList'
import { AUDIENCE } from './AudienceForm/fields'

const GoogleAssetGroupAudienceForm = ({
  assetGroupCreatedData,
  onSuccessSubmit,
  isInternalAssetGroupCreation,
  adAccountId: providedAdAccountId
}) => {
  const dispatch = useDispatch()

  const assetGroupSignalWasCreated = useSelector(createAssetGroupSignalWasCreatedSelector)
  const audiences = useSelector(audiencesSelector)
  const audiencesIsLoading = useSelector(audiencesLoadingSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const adAccountId = providedAdAccountId || selectedAdAccountId

  const formattedAudiences = useMemo(
    () =>
      formatOptionsList({
        list: audiences,
        labelName: 'name',
        valueName: 'resource_name'
      }),
    [audiences]
  )

  const onSubmit = useCallback(
    values => {
      const { resource_name: assetGroupResourceName, id: assetGroupInternalId } = assetGroupCreatedData

      dispatch(
        createAssetGroupSignal(
          {
            account: adAccountId,
            [AUDIENCE]: {
              [AUDIENCE]: values[AUDIENCE]
            },
            // audiences signal can be also created internally for internal asset group
            ...(isInternalAssetGroupCreation
              ? {
                  asset_group: assetGroupInternalId,
                  quarantine: true
                }
              : {
                  asset_group: assetGroupResourceName
                })
          },
          GOOGLE_PLATFORM
        )
      )
    },
    [dispatch, assetGroupCreatedData, adAccountId, isInternalAssetGroupCreation]
  )

  const clearCreateAssetGroupSignalHandler = useCallback(() => {
    dispatch(clearCreateAssetGroupSignal())
  }, [dispatch])

  useEffect(() => {
    dispatch(getAudiences({ account: adAccountId }, GOOGLE_PLATFORM))

    return () => {
      dispatch(clearAudiences())
    }
  }, [dispatch, adAccountId])

  return (
    <GoogleAudienceForm
      formName="asset_group_audiences"
      audiencesList={formattedAudiences}
      audiencesIsLoading={audiencesIsLoading}
      audienceWasCreated={assetGroupSignalWasCreated}
      clearHandler={clearCreateAssetGroupSignalHandler}
      errorSelector={createAssetGroupSignalErrorSelector}
      isLoadingSelector={createAssetGroupSignalIsLoadingSelector}
      onSuccessSubmit={onSuccessSubmit}
      handleSubmit={onSubmit}
    />
  )
}

export default GoogleAssetGroupAudienceForm
