import * as Yup from 'yup'
import { OPTION_YES } from '../../../../../../constants/forms'

export const AUDIENCE_OPTION = 'audience_option'
export const AUDIENCE = 'audience'

export const initialValues = {
  [AUDIENCE_OPTION]: OPTION_YES,
  [AUDIENCE]: ''
}

export const validationSchema = Yup.object({
  [AUDIENCE]: Yup.string().when(AUDIENCE_OPTION, {
    is: OPTION_YES,
    then: () => Yup.string().required('Please select audience')
  })
})
