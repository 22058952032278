import { createStyles } from '../../../../styles/helpers'

import { textGrey } from '../../../../styles/const/colors'
import { tabletDown } from '../../../../styles/const/breakpoints'

const useStyles = createStyles({
  title: {
    fontSize: 24,
    color: textGrey,
    fontWeight: 'bold',
    margin: '16px 0'
  },
  closeIcon: {
    position: 'absolute',
    top: 8,
    right: 14,
    padding: 10,
    cursor: 'pointer'
  },
  successText: {
    '& p': {
      marginTop: 8
    }
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 8,
    marginTop: 32
  },
  [`@media screen and (${tabletDown})`]: {
    btnContainer: {
      flexDirection: 'column'
    }
  }
})

export default useStyles
