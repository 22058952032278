import { createStyles } from '../../../styles/helpers'

import chevronArrowDown from '../../../assets/icons/chevron-arrow-down.svg'

import { darkGrey, greyOutline, bannerGrey } from '../../../styles/const/colors'
import { transitionShort } from '../../../styles/const/common'
import { inputHeight, inputMobileHeight } from '../../../styles/common/components'
import { tabletDown } from '../../../styles/const/breakpoints'

export default createStyles(theme => ({
  input: {
    height: `${inputHeight}px !important`,
    fontSize: '14px !important',
    borderColor: `${greyOutline} !important`,
    borderRadius: `${theme.defaultBorderRadius} !important`,
    paddingLeft: '79px !important',
    color: `${darkGrey} !important`,
    width: '100% !important',
    '&.open': {
      zIndex: '0 !important'
    }
  },
  dropdown: {
    borderColor: `${greyOutline} !important`,
    background: `${bannerGrey} !important`,
    '& .arrow': {
      width: '12px !important',
      height: '8px !important',
      background: `url(${chevronArrowDown}) no-repeat center center`,
      border: 'none !important',
      left: '32px !important',
      transition: transitionShort
    },
    '&.open .selected-flag': {
      background: `${bannerGrey} !important`
    },
    '&.open .arrow': {
      transform: 'rotate(180deg)'
    },
    '& .selected-flag': {
      paddingLeft: 10,
      width: 63
    },
    '& .country-list .country.highlight': {
      background: `${bannerGrey} !important`
    }
  },
  [`@media screen and (${tabletDown})`]: {
    input: {
      height: `${inputMobileHeight}px !important`
    }
  }
}))
