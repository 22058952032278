import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import Can from '../../../features/components/Can'
import AssetGroupsContent from './AssetGroupsContent'
import AssetGroupGoogleEdit from '../../../forms/Google/AdForms/GoogleAssetGroup/AssetGroupGoogleEdit'

import { clearAssetGroups, getAssetGroups } from '../../../modules/actions/assets'
import { assetGroupsNextPageTokenSelector } from '../../../modules/selectors/assets'

import { AD_PERMISSION } from '../../../constants/permissions'
import { selectedAdAccountIdSelector } from '../../../modules/selectors/app'

function AssetGroups() {
  const dispatch = useDispatch()

  const { campaign_id } = useParams()

  const nextPageToken = useSelector(assetGroupsNextPageTokenSelector)
  const selectedAdAccount = useSelector(selectedAdAccountIdSelector)

  const getAdsParams = useMemo(() => {
    return {
      account: selectedAdAccount,
      campaign: campaign_id,
      detail: true,
      page_size: 8
    }
  }, [campaign_id, selectedAdAccount])

  const loadAdsHandler = useCallback(() => {
    dispatch(getAssetGroups(getAdsParams))
  }, [dispatch, getAdsParams])

  const loadMoreAdsHandler = useCallback(() => {
    dispatch(getAssetGroups({ ...getAdsParams, page_token: nextPageToken }))
  }, [dispatch, getAdsParams, nextPageToken])

  useEffect(() => {
    loadAdsHandler()
  }, [loadAdsHandler])

  useEffect(() => {
    return () => {
      dispatch(clearAssetGroups())
    }
  }, [dispatch])

  return (
    <>
      <AssetGroupsContent loadMoreHandler={nextPageToken ? loadMoreAdsHandler : null} />
      <Can I="update" a={AD_PERMISSION}>
        <AssetGroupGoogleEdit />
      </Can>
    </>
  )
}

export default AssetGroups
