import { createStyles } from '../../../../../styles/helpers'
import { phonesDown, tabletDown } from '../../../../../styles/const/breakpoints'

const useStyles = createStyles({
  section: {
    padding: '0 48px'
  },
  content: {
    width: '100%',
    maxWidth: 1345,
    margin: '0 auto'
  },
  headerSection: {
    paddingTop: 100,
    paddingBottom: 24
  },
  header: {
    fontSize: 36,
    margin: '0 auto 18px',
    maxWidth: 540,
    textAlign: 'center'
  },
  headerDescription: {
    margin: '0 auto',
    maxWidth: 540,
    textAlign: 'center',
    marginBottom: 50
  },
  filters: {
    display: 'flex',
    gap: '16px',
    marginBottom: '32px',
    justifyContent: 'flex-end'
  },
  categorySelect: {
    minWidth: 170,
    maxWidth: 170
  },
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(312px, 1fr))',
    gap: 32,
    marginTop: 16
  },
  [`@media screen and (${tabletDown})`]: {
    section: {
      padding: '0 24px',
      marginTop: 16
    }
  },
  [`@media screen and (${phonesDown})`]: {
    cardsContainer: {
      gridTemplateColumns: 'repeat(1, 1fr)'
    }
  }
})

export default useStyles
