import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles(theme => ({
  fieldWrapper: {
    position: 'relative',

    '&:not(:last-child)': {
      marginBottom: 8
    }
  },
  field: {
    // padding for covering the space for X button
    paddingRight: 30
  },
  selected: {
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '14px',
      left: -20,
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      backgroundColor: theme.brandPrimary
    }
  },
  removeButtonSymbol: {
    position: 'absolute',
    top: 8,
    right: 8
  }
}))

export default useStyles
