import { createStyles } from '../../../../../styles/helpers'

import { mediumGrey } from '../../../../../styles/const/colors'

export default createStyles(theme => ({
  page: {
    borderRadius: theme.bigBorderRadius,
    border: `1px solid ${mediumGrey}`,
    width: '100%',
    overflow: 'hidden',

    '&:not(:last-child)': {
      marginBottom: 25
    }
  },
  clickablePage: {
    cursor: 'pointer'
  },
  pageHeadline: {
    marginBottom: 5
  }
}))
