import React, { useMemo } from 'react'
import { components } from 'react-select'

const SelectPlaceholder = props => {
  const { isFocused, selectProps } = props

  const { hidePlaceholderOnFocus = true } = selectProps

  const children = useMemo(() => {
    if (hidePlaceholderOnFocus) {
      return !isFocused ? props.children : ''
    } else {
      return props.children
    }
  }, [isFocused, hidePlaceholderOnFocus, props.children])

  return <components.Placeholder {...props} children={children} />
}

export default SelectPlaceholder
