import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import useStyles from './styles'

const TabItem = ({ tabItem, isActive, onClick, isDisabled, className }) => {
  const classes = useStyles({ isActive, isDisabled })

  const { t } = useTranslation()

  return (
    <div className={classnames(classes.tabItem, className)} onClick={onClick}>
      {t(tabItem.label)}
    </div>
  )
}

TabItem.propTypes = {
  tabItem: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool
}

export default TabItem
