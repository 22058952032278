import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../components/Button'
import AskReasonModal from '../../../../../../features/components/Modals/AskReasonModal'

import useModalManage from '../../../../../../hooks/useModalManage'

import {
  cancelMediaOrder,
  clearCancelMediaOrder,
  getMediaOrderFiles
} from '../../../../../../modules/actions/mediaOrders'
import { closeForm } from '../../../../../../modules/actions/forms'
import {
  cancelMediaOrderWasCreatedSelector,
  cancelMediaOrderErrorSelector,
  cancelMediaOrderIsLoadingSelector
} from '../../../../../../modules/selectors/mediaOrders'

import { DISAPPROVED_REASON } from '../../../../../../features/components/Modals/AskReasonModal/fields'

const CancelMediaOrder = ({ mediaOrderId, isDisabled }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const mediaOrderWasCanceled = useSelector(cancelMediaOrderWasCreatedSelector)
  const { isModalOpened, openModalHandler, closeModalHandler } = useModalManage({})

  const handleSubmit = useCallback(
    values => {
      dispatch(cancelMediaOrder(mediaOrderId, { reason: values[DISAPPROVED_REASON] }))
    },
    [dispatch, mediaOrderId]
  )

  const handleSuccessSubmit = useCallback(() => {
    dispatch(closeForm())
  }, [dispatch])

  const clearHandler = useCallback(() => {
    dispatch(clearCancelMediaOrder())
  }, [dispatch])

  useEffect(() => {
    if (mediaOrderWasCanceled) {
      // workaround to represent canceled contract
      dispatch(getMediaOrderFiles({ media_order: mediaOrderId }))
    }
  }, [mediaOrderWasCanceled, dispatch, mediaOrderId])

  return (
    <>
      <Button onClick={openModalHandler} isSmall={true} disabled={isDisabled} danger>
        {t('Cancel Booking')}
      </Button>
      <AskReasonModal
        title={t('Are you sure you want to cancel?')}
        confirmButtonText={t('Cancel Booking')}
        showCancelButton={false}
        onSubmit={handleSubmit}
        isOpen={isModalOpened}
        onClose={closeModalHandler}
        ModalMessage={t(
          'Please provide a reason for this cancellation. Once you cancel this booking, the media inventory will be rereleased and made available for other advertisers to book.'
        )}
        placeholder={t('Cancellation reason')}
        successSelector={cancelMediaOrderWasCreatedSelector}
        errorSelector={cancelMediaOrderErrorSelector}
        isLoadingSelector={cancelMediaOrderIsLoadingSelector}
        onSuccessSubmit={handleSuccessSubmit}
        clearFormHandler={clearHandler}
      />
    </>
  )
}

export default CancelMediaOrder
