import update from 'immutability-helper'
import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  CLEAR_USERS
} from '../actions/users'
import { INITIAL_GET_RESULTS_REDUCER } from '../../constants/reducer'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  users: INITIAL_GET_RESULTS_REDUCER
}

export default function users(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    case GET_USERS:
      return update(state, {
        users: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_USERS_SUCCESS:
      return update(state, {
        users: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.usersData.results
          }
        }
      })
    case GET_USERS_FAILURE:
      return update(state, {
        users: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_USERS:
      return update(state, {
        users: { $set: initialState.users }
      })
    default:
      return state
  }
}
