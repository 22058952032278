import React from 'react'
import { useSelector } from 'react-redux'

import { formatNumber } from '../../../../../../../../helpers/numbers'

import { lineItemSelector } from '../../../../../../../../modules/selectors/lineItems'

const BidPricePreview = () => {
  const lineItem = useSelector(lineItemSelector)

  const { bid: bidPrice } = lineItem

  if (bidPrice) {
    const formattedBidPrice = formatNumber(bidPrice, { min: 2, max: 2 })

    return <span>Bid Price: {formattedBidPrice}</span>
  }

  return null
}

export default BidPricePreview
