import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import { getTotalBudgetInMediaOrder } from '../../../../features/helpers/mediaOrder'

import { combinedCampaignsSelector } from '../../../../modules/selectors/combinedData'
import { mediaOrderDigitalProductsSelector } from '../../../../modules/selectors/mediaOrders'

export const useMediaOrderRemainingBudgetCalculation = () => {
  const { campaigns: combinedCampaigns = [] } = useSelector(combinedCampaignsSelector)
  const digitalProducts = useSelector(mediaOrderDigitalProductsSelector)

  const totalDigitalProductsBudget = digitalProducts.reduce((partialSum, product) => {
    return partialSum + product.total_price_with_discount
  }, 0)

  const mediaOrderUsedBudget = useMemo(() => {
    return getTotalBudgetInMediaOrder(combinedCampaigns)
  }, [combinedCampaigns])

  // remainingBudget
  return totalDigitalProductsBudget - mediaOrderUsedBudget
}
