import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import Field from '../../../../../components/Form/Field'
import Form from '../../../../../components/Form'

import { createProductTag, clearCreateProductTag } from '../../../../../modules/actions/tags'
import {
  createProductTagIsLoadingSelector,
  createProductTagErrorSelector,
  createProductTagWasCreatedSelector
} from '../../../../../modules/selectors/tags'

import { CREATE_PRODUCT_TAGS } from '../../../../../constants/forms'

import { validationSchema } from './validation'
import { initialValues, TAG } from './fields'
import { selectedSelfAccountDataSelector } from '../../../../../modules/selectors/app'

function MediaProductTagsCreateForm() {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const itemWasCreated = useSelector(createProductTagWasCreatedSelector)
  const { controller } = useSelector(selectedSelfAccountDataSelector)

  const onSubmit = useCallback(
    values => {
      dispatch(createProductTag({ title: values[TAG], controller }))
    },
    [dispatch, controller]
  )

  const handleClearHandler = useCallback(() => {
    dispatch(clearCreateProductTag())
  }, [dispatch])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  return (
    <Form
      formik={formik}
      formName={CREATE_PRODUCT_TAGS}
      successSubmit={itemWasCreated}
      errorSelector={createProductTagErrorSelector}
      clearHandler={handleClearHandler}
      isLoadingSelector={createProductTagIsLoadingSelector}
    >
      <Field formik={formik} id={TAG} name={TAG} placeholder={t('Enter tag')} />
    </Form>
  )
}

export default MediaProductTagsCreateForm
