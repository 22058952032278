import React from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'

import { ValidateId } from './components/hoc/ValidateId'
import AuthorizedRoute from './components/hoc/AuthorizedRoute'

import AcceptInvite from './pages/Unauthorized/AcceptInvite'
import CreatePassword from './pages/Unauthorized/CreatePassword'
import LandingPage from './pages/LandingPage'
import Login from './pages/Unauthorized/Login'
import ResendRegister from './pages/Unauthorized/ResendRegister'
import ResetPassword from './pages/Unauthorized/ResetPassword'
import SetForgotPassword from './pages/Unauthorized/SetForgotPassword'
import AccountSetup from './pages/AccountSetup'
import AdAccountAccessMissing from './pages/SocialAuthorize/AdAccountAccessMissing'
import AudiencesPage from './pages/Audiences'
import CalendarPlannerPage from './pages/CalendarPlanner'
import CampaignsPage from './pages/Campaigns'
import CampaignsSummaryPage from './pages/CampaignsSummary'
import AdApprovalsPage from './pages/AdApprovals'
import LineItemsSummaryPage from './pages/LineItemsSummary'
import MediaOrderSummary from './pages/MediaOrderSummary'
import Settings from './pages/Settings'
import SocialAuthorizeCallback from './pages/SocialAuthorize/SocialAuthorizeCallback'
import MFASetupPage from './pages/MfaSetup'
import MediaOrders from './pages/MediaOrders'
import Proposals from './pages/Proposals'
import QuotationBuilderPage from './pages/Proposals/QuotationBuilderPage'
import QuotationSign from './pages/Unauthorized/QuotationSign'
import AmendmentSign from './pages/Unauthorized/AmendmentSign'

import { dashboardTabId, ROUTE_PARAMS, ROUTE_SEGMENTS, ROUTES as ROUTES_CONST } from './constants/routes'
import { getDefaultPageUrl } from './helpers/url'

const RemoveTrailingSlash = ({ ...rest }) => {
  const location = useLocation()

  // If the last character of the url is '/'
  if (location.pathname.match('/.*/$')) {
    return (
      <Navigate
        replace
        {...rest}
        to={{
          pathname: location.pathname.replace(/\/+$/, ''),
          search: location.search
        }}
      />
    )
  } else return null
}

export const AuthorizedRoutes = ({ permissions }) => (
  <React.Fragment>
    {/* the row below is for cutting off trailing slash */}
    <RemoveTrailingSlash />

    <Routes>
      <Route
        path={ROUTES_CONST.campaigns}
        element={
          <AuthorizedRoute path={ROUTES_CONST.campaigns}>
            <CampaignsPage />
          </AuthorizedRoute>
        }
      />
      <Route
        path={ROUTES_CONST.campaignsSummary}
        element={
          <AuthorizedRoute path={ROUTES_CONST.campaignsSummary}>
            <CampaignsSummaryPage />
          </AuthorizedRoute>
        }
      />
      <Route
        path={ROUTES_CONST.lineItemsSummary}
        element={
          <AuthorizedRoute path={ROUTES_CONST.lineItemsSummary}>
            <LineItemsSummaryPage />
          </AuthorizedRoute>
        }
      />
      {permissions.can('read', ROUTES_CONST.adApprovals) && (
        <Route
          path={ROUTES_CONST.adApprovals}
          element={
            <AuthorizedRoute path={ROUTES_CONST.adApprovals}>
              <AdApprovalsPage />
            </AuthorizedRoute>
          }
        />
      )}
      {permissions.can('read', ROUTES_CONST.adApprovals) && (
        <Route
          path={`/:${ROUTE_PARAMS.selfAccount}/:providerTab/${ROUTE_SEGMENTS.adApprovals}`}
          element={
            <AuthorizedRoute path={`/:${ROUTE_PARAMS.selfAccount}/:providerTab/${ROUTE_SEGMENTS.adApprovals}`}>
              <AdApprovalsPage />
            </AuthorizedRoute>
          }
        />
      )}
      <Route
        path={ROUTES_CONST.audiences}
        element={
          <AuthorizedRoute path={ROUTES_CONST.audiences}>
            <AudiencesPage />
          </AuthorizedRoute>
        }
      />
      <Route
        path={ROUTES_CONST.authorisePlatformCallback}
        element={
          <AuthorizedRoute path={ROUTES_CONST.authorisePlatformCallback}>
            <SocialAuthorizeCallback />
          </AuthorizedRoute>
        }
      />
      <Route
        path={ROUTES_CONST.adAccountAccessMissing}
        element={
          <AuthorizedRoute path={ROUTES_CONST.adAccountAccessMissing}>
            <AdAccountAccessMissing />
          </AuthorizedRoute>
        }
      />
      <Route
        path={ROUTES_CONST.calendar}
        element={
          <AuthorizedRoute path={ROUTES_CONST.calendar}>
            <CalendarPlannerPage />
          </AuthorizedRoute>
        }
      />
      <Route
        // support the /
        path={ROUTES_CONST.home}
        element={
          <AuthorizedRoute path={ROUTES_CONST.home}>
            <LandingPage />
          </AuthorizedRoute>
        }
      />
      <Route
        // support the /:id
        path={ROUTES_CONST.selfAccountHome}
        element={
          // the ValidateId is used to avoid cases when the path after / is considered as id
          // for example /reset-password will be considered as id of the landing page, so it lead to
          <ValidateId>
            <AuthorizedRoute path={ROUTES_CONST.selfAccountHome}>
              <LandingPage />
            </AuthorizedRoute>
          </ValidateId>
        }
      />
      {permissions.can('read', ROUTES_CONST.dashboard) && (
        <Route
          path={`${ROUTES_CONST.dashboard}/:${dashboardTabId}`}
          element={
            <AuthorizedRoute path={`${ROUTES_CONST.dashboard}/:${dashboardTabId}`}>
              <LandingPage />
            </AuthorizedRoute>
          }
        />
      )}
      {permissions.can('read', ROUTES_CONST.dashboard) && (
        <Route
          path={ROUTES_CONST.dashboard}
          element={
            <AuthorizedRoute path={ROUTES_CONST.dashboard}>
              <LandingPage />
            </AuthorizedRoute>
          }
        />
      )}
      <Route
        path={ROUTES_CONST.settings}
        element={
          <AuthorizedRoute path={ROUTES_CONST.settings}>
            <Settings />
          </AuthorizedRoute>
        }
      />
      <Route
        path={`${ROUTES_CONST.settings}/:settingSection`}
        element={
          <AuthorizedRoute path={`${ROUTES_CONST.settings}/:settingSection`}>
            <Settings />
          </AuthorizedRoute>
        }
      />
      <Route
        path={ROUTES_CONST.mediaOrders}
        element={
          <AuthorizedRoute path={ROUTES_CONST.mediaOrders}>
            <MediaOrders />
          </AuthorizedRoute>
        }
      />
      <Route
        path={ROUTES_CONST.mediaOrderSummary}
        element={
          <AuthorizedRoute path={ROUTES_CONST.mediaOrderSummary}>
            <MediaOrderSummary />
          </AuthorizedRoute>
        }
      />
      <Route
        path={`${ROUTES_CONST.mediaOrderSummary}/:mediaOrderSummaryTab`}
        element={
          <AuthorizedRoute path={`${ROUTES_CONST.mediaOrderSummary}/:mediaOrderSummaryTab`}>
            <MediaOrderSummary />
          </AuthorizedRoute>
        }
      />
      {permissions.can('read', ROUTES_CONST.proposals) && (
        <Route
          path={ROUTES_CONST.proposals}
          element={
            <AuthorizedRoute path={ROUTES_CONST.proposals}>
              <Proposals />
            </AuthorizedRoute>
          }
        />
      )}
      {permissions.can('read', ROUTES_CONST.proposalsCreate) && (
        <Route
          path={ROUTES_CONST.proposalsCreate}
          element={
            <AuthorizedRoute path={ROUTES_CONST.proposalsCreate}>
              <QuotationBuilderPage />
            </AuthorizedRoute>
          }
        />
      )}
      {permissions.can('read', ROUTES_CONST.proposalsEdit) && (
        <Route
          path={ROUTES_CONST.proposalsEdit}
          element={
            <AuthorizedRoute path={ROUTES_CONST.proposalsEdit}>
              <QuotationBuilderPage />
            </AuthorizedRoute>
          }
        />
      )}

      {/*Redirect to default page if path doesn't match any of the routes above*/}
      <Route path="*" element={<Navigate to={getDefaultPageUrl()} replace />} />
    </Routes>
  </React.Fragment>
)

// routes used when user is required to set make some settings before App usage
export const SetupRoutes = () => (
  <Routes>
    <Route
      path={ROUTES_CONST.accountSetup}
      element={
        <AuthorizedRoute path={ROUTES_CONST.accountSetup}>
          <AccountSetup />
        </AuthorizedRoute>
      }
    />
    <Route
      path={ROUTES_CONST.mfaSetup}
      element={
        <AuthorizedRoute path={ROUTES_CONST.mfaSetup}>
          <MFASetupPage />
        </AuthorizedRoute>
      }
    />
    <Route path="*" element={<Navigate to={ROUTES_CONST.mfaSetup} replace />} />
  </Routes>
)

export const UnauthorizedRoutes = () => (
  <Routes>
    <Route path={ROUTES_CONST.acceptInvite} element={<AcceptInvite />} />
    <Route path={ROUTES_CONST.createPassword} element={<CreatePassword />} />
    <Route path={ROUTES_CONST.forgotPassword} element={<SetForgotPassword />} />
    <Route path={ROUTES_CONST.login} element={<Login />} />
    <Route path={ROUTES_CONST.resendRegister} element={<ResendRegister />} />
    <Route path={ROUTES_CONST.resetPassword} element={<ResetPassword />} />
    <Route path={ROUTES_CONST.notAuthQuotations} element={<QuotationSign />} />
    <Route path={ROUTES_CONST.notAuthAmendments} element={<AmendmentSign />} />
    <Route path="*" element={<Navigate to={ROUTES_CONST.login} />} />
  </Routes>
)
