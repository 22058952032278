import update from 'immutability-helper'
import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  CLEAR_REPORTS,
  GET_CAMPAIGNS_REPORTS,
  GET_CAMPAIGNS_REPORTS_SUCCESS,
  GET_CAMPAIGNS_REPORTS_FAILURE,
  GET_CAMPAIGN_GRAPHS,
  GET_CAMPAIGN_GRAPHS_SUCCESS,
  GET_CAMPAIGN_GRAPHS_FAILURE,
  SET_CAMPAIGNS_SUMMARY_FILTER,
  SET_CAMPAIGNS_SUMMARY_DATE_RANGE,
  SET_CAMPAIGNS_SUMMARY_SORT,
  FILTER,
  DATE_RANGE,
  SORT,
  CLEAR_CAMPAIGNS_SUMMARY_FILTERS,
  LINE_ITEMS_SELECTION_CHANGE,
  CLEAR_LINE_ITEMS_SELECTION_CHANGE
} from '../actions/campaignsSummary'
import {
  campaignFiltersList,
  DATE_STATIC_PRESET,
  LIFETIME_DATE_PRESET
} from '../../constants/selectLists/listItemFiltersList'
import { getCookie } from '../../helpers/common'
import { getSavedDateRange } from '../../helpers/date'

// saved to cookies Date range
const savedDateRange = getSavedDateRange(DATE_RANGE)

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  isLoading: false,
  reportsLoading: false,
  reportsData: {
    graph_data: [],
    reports_data: []
  },
  campaignGraphs: {},
  selectedLineItems: {},
  filter: getCookie(FILTER) || campaignFiltersList[0].value,
  dateRange: savedDateRange || { [DATE_STATIC_PRESET]: LIFETIME_DATE_PRESET },
  sort: getCookie(SORT) || {}
}

export default function campaignsSummary(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    case GET_CAMPAIGNS_REPORTS:
      return update(state, {
        reportsLoading: { $set: true }
      })
    case GET_CAMPAIGNS_REPORTS_SUCCESS:
      return update(state, {
        reportsLoading: { $set: false },
        // BE sends detail object when there is no data
        reportsData: { $set: action.reportsData.reports_data ? action.reportsData : initialState.reportsData }
      })
    case GET_CAMPAIGNS_REPORTS_FAILURE:
      return update(state, {
        reportsLoading: { $set: false }
      })
    case GET_CAMPAIGN_GRAPHS:
      return update(state, {
        campaignGraphs: { $set: { isLoading: true } }
      })
    case GET_CAMPAIGN_GRAPHS_SUCCESS:
      return update(state, {
        campaignGraphs: { $set: { isLoading: false } }
      })
    case GET_CAMPAIGN_GRAPHS_FAILURE:
      return update(state, {
        campaignGraphs: { $set: { isLoading: false } }
      })
    case CLEAR_REPORTS:
      return update(state, {
        reportsData: { $set: initialState.reportsData }
      })
    case SET_CAMPAIGNS_SUMMARY_FILTER:
      return update(state, {
        filter: { $set: action.filter }
      })
    case SET_CAMPAIGNS_SUMMARY_DATE_RANGE:
      return update(state, {
        dateRange: { $set: action.dateRange }
      })
    case SET_CAMPAIGNS_SUMMARY_SORT:
      return update(state, {
        sort: { $set: action.sort }
      })
    case CLEAR_CAMPAIGNS_SUMMARY_FILTERS:
      return update(state, {
        // back to initial values, set manually because cookie clearing can happen later
        filter: { $set: campaignFiltersList[0].value },
        dateRange: { $set: { [DATE_STATIC_PRESET]: LIFETIME_DATE_PRESET } },
        sort: { $set: {} }
      })
    case LINE_ITEMS_SELECTION_CHANGE:
      const newSelections = { ...state.selectedLineItems }
      if (state.selectedLineItems[action.lineItemId]) {
        delete newSelections[action.lineItemId]
      } else {
        newSelections[action.lineItemId] = true
      }

      return update(state, {
        selectedLineItems: { $set: newSelections }
      })
    case CLEAR_LINE_ITEMS_SELECTION_CHANGE:
      return update(state, {
        selectedLineItems: { $set: initialState.selectedLineItems }
      })
    default:
      return state
  }
}
