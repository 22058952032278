import * as Yup from 'yup'

import { getAssetImageListValidation } from '../../../UploadGoogleAdCreative/UploadGoogleAdCreativeForm/imageAssetsListValidationHelper'
import { getDuplicatedTextAssetsValidation } from '../../../UploadGoogleAdCreative/duplicatedTextAssetsValiation'

import {
  callToActionValidation,
  customFirstDescriptionValidation,
  descriptionShape,
  headlineShape
} from '../../validations'
import {
  CLICK_THROUGH_LINK,
  DESCRIPTIONS_LIST,
  HEADLINES_LIST,
  LONG_HEADLINES_LIST,
  NAME,
  VIDEO_ADS_URL
} from '../../fields'
import { youtubeLinkShape, longHeadlineShape } from '../validations'
import { CALL_TO_ACTION } from '../../../../ReusableFormFields/CallToActionFields/fields'
import { PRODUCT_ID } from '../../../../ReusableFormFields/ProductFields/fields'
import { urlValidation } from '../../../../../features/validations/other'

export const getValidationSchema = ({ showProductSelectionStep }) => {
  return Yup.object({
    ...(showProductSelectionStep && {
      [PRODUCT_ID]: Yup.string().required('Product required')
    }),
    [NAME]: Yup.string().required('Name required'),
    [CLICK_THROUGH_LINK]: urlValidation,
    [CALL_TO_ACTION]: callToActionValidation,
    [HEADLINES_LIST]: Yup.array().of(headlineShape),
    ...getDuplicatedTextAssetsValidation(true),
    [VIDEO_ADS_URL]: Yup.array().of(youtubeLinkShape),
    [DESCRIPTIONS_LIST]: Yup.array().of(descriptionShape),
    ...customFirstDescriptionValidation,
    [LONG_HEADLINES_LIST]: Yup.array().of(longHeadlineShape),
    ...getAssetImageListValidation()
  })
}
