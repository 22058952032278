import React, { useCallback } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import RestartLogin from '../RestartLogin'
import Form from '../../../../../components/Form'
import RadioButton from '../../../../../components/Form/RadioButton'

import { useAuthButtonProps } from '../../../useAuthButtonProps'

import { clearSubmitMfaType, submitMfaType } from '../../../../../modules/actions/cognito'
import {
  cognitoNextStepDataSelector,
  submitMfaTypeErrorSelector,
  submitMfaTypeIsLoadingSelector,
  submitMfaTypeWasAcceptedSelector
} from '../../../../../modules/selectors/cognito'

import { LOGIN_MFA_FORM } from '../../../../../constants/forms'

import useAuthStyles from '../../../../../styles/common/authPages'
import useDrawerFormsStyles from '../../../../../styles/common/drawerForms'

const MfaAuth = ({ onRestartLogin }) => {
  const authClasses = useAuthStyles()
  const drawerFormClasses = useDrawerFormsStyles()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const nextStep = useSelector(cognitoNextStepDataSelector)
  const mfaTypeWasAccepted = useSelector(submitMfaTypeWasAcceptedSelector)

  const onSubmitMfaType = useCallback(
    values => {
      dispatch(submitMfaType(values['mfa_type']))
    },
    [dispatch]
  )

  // leave in case we decide preselect TOTP without possibility switch to SMS
  // useEffect(() => {
  //   if(nextStep?.signInStep === 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION'){
  //     console.log("nextStep?.allowedMFATypes.includes(\"TOTP\")", nextStep?.allowedMFATypes.includes("TOTP") )
  //     nextStep?.allowedMFATypes.includes("TOTP")
  //       ? handleMFASelection("CONTINUE_SIGN_IN_WITH_MFA_SELECTION", "TOTP")
  //       : handleMFASelection("CONTINUE_SIGN_IN_WITH_MFA_SELECTION", "SMS")
  //   }
  //
  // }, [nextStep]);

  const formik = useFormik({
    initialValues: {
      mfa_type: ''
    },
    validationSchema: Yup.object({
      mfa_type: Yup.string().required('Please select type')
    }),
    onSubmit: onSubmitMfaType
  })

  const buttonProps = useAuthButtonProps()

  const { setFieldValue, values } = formik

  const clearMfaSTypeSubmit = () => {
    // clear on success is done on saga level
    dispatch(clearSubmitMfaType())
  }

  return (
    <Form
      formName={LOGIN_MFA_FORM}
      formik={formik}
      successSubmit={mfaTypeWasAccepted}
      clearHandler={clearMfaSTypeSubmit}
      errorSelector={submitMfaTypeErrorSelector}
      isLoadingSelector={submitMfaTypeIsLoadingSelector}
      submitText={t('Submit')}
      formFooterClassName={authClasses.formFooter}
      buttonProps={buttonProps}
    >
      <div className={drawerFormClasses.section}>
        <h3 className={drawerFormClasses.sectionTitle}>🔒 {t('Select MFA type')}</h3>
        {nextStep?.allowedMFATypes?.map(type => {
          return (
            <RadioButton
              id={`mfa_${type}`}
              name={'mfa_type'}
              value={type}
              setFieldValue={setFieldValue}
              selectedValue={values['mfa_type']}
              label={t(type)}
              key={type}
            />
          )
        })}
        <RestartLogin onRestartLogin={onRestartLogin} />
      </div>
    </Form>
  )
}

MfaAuth.propTypes = {
  onRestartLogin: PropTypes.func.isRequired
}

export default MfaAuth
