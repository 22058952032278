import React from 'react'
import { useTranslation } from 'react-i18next'

import Field from '../../../../../../../components/Form/Field'
import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import RadioBlock from '../../../../../../../components/Form/RadioBlock'

import { AD_TEXT, adTextMaxLength } from '../../../../fields'

const stepFields = [AD_TEXT]

function AdTextStep({ formik, handleStepChange }) {
  const { t } = useTranslation()

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  return (
    <Step formTitle="Create an ad" stepTitle="What should the ad text be?" handleContinue={handleContinue}>
      <RadioBlock
        setFieldValue={() => {}}
        id="radio_text_ad_text"
        name={AD_TEXT}
        value={AD_TEXT}
        selectedValue={AD_TEXT}
        label={t('Enter text')}
      >
        <Field formik={formik} name={AD_TEXT} placeholder="Text" autoComplete="off" maxLength={adTextMaxLength} />
      </RadioBlock>
    </Step>
  )
}

export default AdTextStep
