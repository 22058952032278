import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import HighlightedValueSelect from '../../../../../components/HighlightedValueSelect'

import { selectedControllerRelatedSelfAccountsListSelector } from '../../../../../modules/selectors/app'

import useStyles from '../styles'

const InvoicingAccountFilter = ({ accountFilterOption, setAccountFilterOption }) => {
  const classes = useStyles()

  const controllerSelfAccountsList = useSelector(selectedControllerRelatedSelfAccountsListSelector)

  const onAccountFilterChange = useCallback(
    option => {
      setAccountFilterOption(option?.value)
    },
    [setAccountFilterOption]
  )

  return (
    <HighlightedValueSelect
      value={accountFilterOption}
      onChange={onAccountFilterChange}
      className={classes.select}
      options={controllerSelfAccountsList}
      placeholder="Account"
      isSmall
    />
  )
}

InvoicingAccountFilter.propTypes = {
  accountFilterOption: PropTypes.number,
  setAccountFilterOption: PropTypes.func
}

export default InvoicingAccountFilter
