import { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import Dropdown from '../../../components/Dropdown'
import Chip from '../../../components/Chip'

import { bookedMediaStatusesLabels, statusColors } from '../../../constants/bookedMedia'

import useStyles from './styles'

const StatusUpdateDropdown = ({ currentStatus, statuses = [], onStatusUpdate }) => {
  const classes = useStyles()

  // filter current status to not display in update options
  const filteredStatuses = useMemo(() => statuses.filter(status => status !== currentStatus), [currentStatus, statuses])

  const statusClickHandler = useCallback(
    status => {
      onStatusUpdate && onStatusUpdate(status)
    },
    [onStatusUpdate]
  )

  return (
    <Dropdown
      closeOnInsideClick
      placement="bottom-start"
      triggerElement={<Chip text={bookedMediaStatusesLabels[currentStatus]} color={statusColors[currentStatus]} />}
    >
      <div className={classes.container}>
        {filteredStatuses.map(status => (
          <Chip
            key={status}
            isHoverable
            className={classes.statusChip}
            text={bookedMediaStatusesLabels[status]}
            color={statusColors[status]}
            onClick={() => statusClickHandler(status)}
          />
        ))}
      </div>
    </Dropdown>
  )
}

StatusUpdateDropdown.propTypes = {
  itemId: PropTypes.number,
  currentStatus: PropTypes.string,
  statuses: PropTypes.array.isRequired,
  onStatusUpdate: PropTypes.func.isRequired
}

export default StatusUpdateDropdown
