import { createStyles } from '../../../styles/helpers'

import { phonesDown } from '../../../styles/const/breakpoints'

const useStyles = createStyles({
  graphsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, calc((100% - 32px) / 3))',
    gridGap: 16,
    marginTop: 16
  },
  sectionContainer: {
    padding: '12px 16px',
    margin: 0
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 14
  },
  title: {
    fontWeight: 600
  },
  value: {
    fontWeight: 400,
    fontSize: 18
  },
  graphWrapper: {
    height: 195
  },
  [`@media screen and (${phonesDown})`]: {
    graphsContainer: {
      display: 'flex',
      flexDirection: 'column'
    }
  }
})

export default useStyles
