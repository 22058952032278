import { FACEBOOK_PLATFORM, GOOGLE_PLATFORM } from './selectLists/platformList'
import { redirectTo } from '../helpers/url'
import { ROUTE_PARAMS, ROUTES } from './routes'

export const campaignChangeStatusByPlatform = {
  [FACEBOOK_PLATFORM]: {
    active: {
      status: 'active',
      changeTo: 'paused',
      changeLabel: 'Pause'
    },
    paused: {
      status: 'paused',
      changeTo: 'active',
      changeLabel: 'Activate'
    }
  },
  [GOOGLE_PLATFORM]: {
    active: {
      status: 2,
      changeTo: 3,
      changeLabel: 'Pause'
    },
    paused: {
      status: 3,
      changeTo: 2,
      changeLabel: 'Activate'
    }
  }
}

export const campaignEffectiveStatuses = ['in_process', 'with_issues']

// campaign optimize objectives
export const BRAND_AWARENESS = 'brand_awareness'
export const REACH = 'reach'
export const CONVERSIONS = 'conversions'
export const APP_INSTALLS = 'app_installs'
export const LEAD_GENERATION = 'lead_generation'
export const VIDEO_VIEWS = 'video_views'
export const POST_ENGAGEMENT = 'post_engagement'
export const MESSAGES = 'messages'
export const LINK_CLICKS = 'link_clicks'
export const PRODUCT_CATALOG_SALES = 'product_catalog_sales'
export const STORE_VISITS = 'store_visits'
export const OFFER_CLAIMS = 'offer_claims'
export const AD_RECALL_LIFT = 'ad_recall_lift'
// tik tok unique
export const TRAFFIC = 'traffic'
export const ENGAGEMENT = 'engagement'

// new optimize objectives
export const OUTCOME_SALES = 'outcome_sales'
export const OUTCOME_AWARENESS = 'outcome_awareness'
export const OUTCOME_LEADS = 'outcome_leads'
export const OUTCOME_APP_PROMOTION = 'outcome_app_promotion'
export const OUTCOME_ENGAGEMENT = 'outcome_engagement'
export const OUTCOME_TRAFFIC = 'outcome_traffic'

// bidding types
export const BIDDING_TYPE = 'bidding_type'
export const COST_CAP = 'cost_cap'
export const MANUAL = 'manual'
export const MINIMUM_ROAS = 'minimum_roas'
export const AUTOMATIC = 'automatic'

// singular label is used so that it fits with the user facing text such as “cost per …”
export const campaignOptimizeObjectiveLabels = {
  [BRAND_AWARENESS]: 'awareness',
  [OUTCOME_AWARENESS]: 'awareness',
  [APP_INSTALLS]: 'app install',
  [CONVERSIONS]: 'conversion',
  [OUTCOME_SALES]: 'conversion',
  [LEAD_GENERATION]: 'lead generated',
  [OUTCOME_LEADS]: 'lead generated',
  [LINK_CLICKS]: 'click',
  [OUTCOME_TRAFFIC]: 'click',
  [POST_ENGAGEMENT]: 'engagement',
  [OUTCOME_ENGAGEMENT]: 'engagement',
  [VIDEO_VIEWS]: 'video view',
  [PRODUCT_CATALOG_SALES]: 'conversion',
  [MESSAGES]: 'message',
  [STORE_VISITS]: 'store visit',
  [REACH]: 'thousand people reached',
  [OUTCOME_APP_PROMOTION]: 'action'
}

export const objectiveUpperCaseWords = ['CPA', 'CPC', 'CPM', 'CPV']

export const criterionTypes = {
  proximity: 'proximity',
  schedule: 'schedule'
}

// google campaign criterion types
export const CAMPAIGN_GOOGLE_LOCATION_CRITERION_TYPE = 7 // simple locations (countries / regions)
export const CAMPAIGN_GOOGLE_CUSTOM_LOCATION_CRITERION_TYPE = 17 // from location list
export const CAMPAIGN_GOOGLE_LANGUAGE_CRITERION_TYPE = 20

export const CAMPAIGN_GOOGLE_DISPLAY_TYPE = 3
export const CAMPAIGN_GOOGLE_PERFORMANCE_MAX_TYPE = 10

export const getCampaignSummaryRedirectLinkHandler = ({ campaign, platform, selfAccountId, adAccountId }) => {
  return () =>
    redirectTo(ROUTES.campaignsSummary, {
      [ROUTE_PARAMS.platform]: platform,
      [ROUTE_PARAMS.selfAccount]: selfAccountId,
      [ROUTE_PARAMS.adAccount]: adAccountId,
      [ROUTE_PARAMS.campaignId]: campaign.id
    })
}
