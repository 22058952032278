import React from 'react'
import { toast } from 'react-toastify'

import ToastNotification from '../components/ToastNotification'

let toastId = null
export function showToasts({ type, message, autoClose = true, customIcon }) {
  if (!toastId) {
    // show toast only if there is no other opened toasts
    // prevent objects passing inside message prop, which will crash ToastNotification
    const toastMessage = typeof message === 'string' ? message : 'Something went wrong. Please try again later.'

    toastId = toast[type](<ToastNotification appearance={type} message={toastMessage} customIcon={customIcon} />, {
      autoClose,
      closeButton: false,
      icon: false,
      onClose: () => (toastId = null)
    })
  }
}
