import React from 'react'

import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import CheckboxBlock from '../../../../../../../../features/components/Form/CheckboxBlock'

import { ALLOW_CUSTOM_CLICK_THROUGH_LINK } from '../../../../../../../../forms/Multiplatform/UploadAdCreative/fields'

const CustomClickThoughLinkContent = ({ formik }) => {
  const { values, setFieldValue } = formik

  return (
    <FieldRow
      title="Custom click through link"
      description="If checked, this advertiser will be able to enter any click through link on their ads. Leave unchecked to enforce product or brand page ad click through links."
    >
      <CheckboxBlock
        id="custom_link_checkbox"
        title="Allow custom click through links"
        checked={!!values[ALLOW_CUSTOM_CLICK_THROUGH_LINK]}
        onCheck={() => setFieldValue(ALLOW_CUSTOM_CLICK_THROUGH_LINK, !values[ALLOW_CUSTOM_CLICK_THROUGH_LINK])}
      />
    </FieldRow>
  )
}

export default CustomClickThoughLinkContent
