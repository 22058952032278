import { createStyles } from '../../../../../../../../styles/helpers'
import { mediumGrey, textGrey } from '../../../../../../../../styles/const/colors'

const useStyles = createStyles({
  yearRevenueCol: {
    fontSize: 12,
    fontWeight: 400,
    color: textGrey,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:not(:last-child)': {
      borderRight: `1px solid ${mediumGrey}`
    }
  }
})

export default useStyles
