import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import SingleMediaFileRoute from './Routes/SingleMediaFileRoute'
import MultipleMediaFileRoute from './Routes/MultipleMediaFileRoute'
import StoryAdRoute from './Routes/StoryRoute'
import EventSourceStep from './Steps/EventSourceStep'
import AdCreateRouteSelector from './Steps/AdCreateRouteSelector'
import ProductAdRoute from './Routes/ProductAdRoute'
import WarningStep from './Steps/WarningStep'
import FacebookPagesMissingWarning from '../FacebookPagesMissingWarning'

import useLineItemDetails from './hooks/useLineItemDetails'
import useFormattedFacebookPages from './hooks/useFormattedFacebookPages'
import useStepFormRoutes from '../../../../../hooks/formHooks/useStepFormRoutes'

import { isStory } from '../../helpers'

import { clearCreateAd } from '../../../../../modules/actions/ads'
import {
  clearChoicesCTAByGoal,
  clearChoicesPixels,
  getChoicesCTAByGoal,
  getChoicesPixels
} from '../../../../../modules/actions/choices'
import { getAccessibleFacebookPages } from '../../../../../modules/actions/socialAccounts'
import { choicesPixelsSelector } from '../../../../../modules/selectors/choices'
import {
  adWasCreatedSelector,
  adCreateLoadingSelector,
  adCreateErrorSelector
} from '../../../../../modules/selectors/ads'
import {
  accessibleFacebookPagesSelector,
  accessibleFacebookPagesWasLoadedSelector
} from '../../../../../modules/selectors/socialAccounts'
import { selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'

import { AD_FACEBOOK_CREATE } from '../../../../../constants/forms'
import { FORMAT_SINGLE, FORMAT_CAROUSEL, FORMAT_STORY, FORMAT_PRODUCT } from '../../fields'
import { AdFacebookCreateContext } from '../../../formsContexts/AdFacebookCreateFormContextProvider'

const AdFacebookCreateForm = ({ onSuccessSubmit, lineItemWasLoaded, adAccountId, showFormProgressItems = true }) => {
  const dispatch = useDispatch()

  const pixels = useSelector(choicesPixelsSelector)
  const adWasCreated = useSelector(adWasCreatedSelector)
  const facebookPages = useSelector(accessibleFacebookPagesSelector)
  const facebookPagesWasLoaded = useSelector(accessibleFacebookPagesWasLoadedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const { campaignObjective } = useContext(AdFacebookCreateContext)

  const formattedFacebookPages = useFormattedFacebookPages(facebookPages)
  const hasFacebookPages = facebookPagesWasLoaded && !!formattedFacebookPages.length

  const { isAdSetEndDateInThePast } = useLineItemDetails()

  const providedAdAccountId = adAccountId || selectedAdAccountId

  const { placementPositions } = useLineItemDetails()
  const isStoryRoute = isStory(placementPositions)

  const manageStepFormRoutes = useStepFormRoutes({ initialRoute: FORMAT_PRODUCT })

  const getSelectedRoute = useCallback(
    props => {
      switch (manageStepFormRoutes.selectedRoute) {
        case FORMAT_PRODUCT:
          return <ProductAdRoute {...props} />
        case FORMAT_CAROUSEL:
          return <MultipleMediaFileRoute {...props} />
        case FORMAT_STORY:
          return <StoryAdRoute {...props} />
        case FORMAT_SINGLE:
        default:
          return <SingleMediaFileRoute {...props} />
      }
    },
    [manageStepFormRoutes.selectedRoute]
  )

  const warningSteps = useMemo(
    () => [
      {
        component: <WarningStep adAccountId={providedAdAccountId} />,
        show: isAdSetEndDateInThePast
      },
      {
        component: <FacebookPagesMissingWarning />,
        show: !hasFacebookPages
      }
    ],
    [providedAdAccountId, isAdSetEndDateInThePast, hasFacebookPages]
  )

  const clearCreateAdHandler = useCallback(() => {
    dispatch(clearCreateAd())
  }, [dispatch])

  const FormContent = useMemo(
    () =>
      getSelectedRoute({
        formName: AD_FACEBOOK_CREATE,
        successSubmit: adWasCreated,
        errorSelector: adCreateErrorSelector,
        isLoadingSelector: adCreateLoadingSelector,
        // this allows to clearCreateAd on error
        clearSubmitHandler: clearCreateAdHandler,
        eventSourceStep: {
          component: <EventSourceStep />,
          show: pixels.length > 1
        },
        // FormRouteSelector component is adding in each Route as a first step and allows to control Route Selecting
        FormRouteSelector: <AdCreateRouteSelector manageStepFormRoutes={manageStepFormRoutes} />,
        // this props passed to StepForm were the Skeleton is represented while data is loading
        isInitialDataLoading: !lineItemWasLoaded || !facebookPagesWasLoaded,
        onSuccessSubmit,
        // We pass 'showFormProgressItems = false' on media order page (existing campaign route) to avoid 2 formProgressItems rendered
        formProgressItems: showFormProgressItems ? [] : null,
        adAccountId,
        warningSteps
      }),
    [
      getSelectedRoute,
      adWasCreated,
      clearCreateAdHandler,
      pixels.length,
      manageStepFormRoutes,
      lineItemWasLoaded,
      facebookPagesWasLoaded,
      onSuccessSubmit,
      adAccountId,
      warningSteps,
      showFormProgressItems
    ]
  )

  useEffect(() => {
    dispatch(getAccessibleFacebookPages())
  }, [dispatch])

  useEffect(() => {
    dispatch(getChoicesPixels({ account: adAccountId || selectedAdAccountId }))
  }, [dispatch, adAccountId, selectedAdAccountId])

  useEffect(() => {
    if (campaignObjective) {
      dispatch(getChoicesCTAByGoal({ goals: campaignObjective }))
    }
  }, [dispatch, campaignObjective])

  useEffect(() => {
    if (lineItemWasLoaded && isStoryRoute) {
      manageStepFormRoutes.handleSelectRoute(FORMAT_STORY)
    }
  }, [manageStepFormRoutes, lineItemWasLoaded, isStoryRoute])

  useEffect(
    () => () => {
      dispatch(clearChoicesPixels())
      dispatch(clearChoicesCTAByGoal())
    },
    [dispatch]
  )

  return FormContent
}

AdFacebookCreateForm.propTypes = {
  onSuccessSubmit: PropTypes.func,
  // some of the props controlled outside of the form
  // it helps to make it more dynamic, like using on rights summary page with multi-form approach
  lineItemWasLoaded: PropTypes.bool.isRequired,
  // ad account can be passed from outside (e.g. media-orders upload creative form)
  adAccountId: PropTypes.string
}

export default AdFacebookCreateForm
