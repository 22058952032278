import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { googleLanguagesSelector } from '../../../../../../modules/selectors/googleConstants'
import { campaignCriterionsSelector } from '../../../../../../modules/selectors/campaigns'

import { CAMPAIGN_GOOGLE_LANGUAGE_CRITERION_TYPE } from '../../../../../../constants/campaigns'

const LanguagesSectionPreview = () => {
  const languages = useSelector(googleLanguagesSelector)
  const criterions = useSelector(campaignCriterionsSelector)

  const formattedLanguages = useMemo(
    () =>
      criterions
        .filter(criterion => criterion.type_ === CAMPAIGN_GOOGLE_LANGUAGE_CRITERION_TYPE)
        .map(languageCriterion => languages.find(language => language.id === languageCriterion['criterion_id']).name),
    [criterions, languages]
  )

  return (
    !!formattedLanguages.length && (
      <p>
        {formattedLanguages.map((language, index) => (
          <span key={index}>{language}</span>
        ))}
      </p>
    )
  )
}

export default LanguagesSectionPreview
