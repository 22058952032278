import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import ContractReview from '../../../../../../features/components/Contract/ContractReview'
import AmendmentSubmit from '../../../../../../forms/Multiplatform/MediaOrderForms/AmendmentSubmit'

import usePermissions from '../../../../../../hooks/usePermissions'

import { closeForm } from '../../../../../../modules/actions/forms'
import { isUserControllerSelector } from '../../../../../../modules/selectors/app'

import { SIGNATORY_PERMISSION } from '../../../../../../constants/permissions'

const AmendmentReviewContent = ({ contract }) => {
  const dispatch = useDispatch()
  const permissions = usePermissions()

  const isUserController = useSelector(isUserControllerSelector)

  const allowSignContract = permissions.can('manage', SIGNATORY_PERMISSION)

  const handleSuccessSubmit = () => {
    dispatch(closeForm())
  }

  return !isUserController && allowSignContract ? (
    // Agency/Regular user have permissions- sign created Amendment contract, create the final Amendment
    <AmendmentSubmit contract={contract} onSuccessSubmit={handleSuccessSubmit} />
  ) : (
    // Controller have permissions - create Amendment contract, sign Amendment contract for controller signature
    // so when contract is created and signed they can only review it
    <ContractReview contract={contract} allowCreateQuotation={false} />
  )
}

AmendmentReviewContent.propTypes = {
  quotationContract: PropTypes.object
}

export default AmendmentReviewContent
