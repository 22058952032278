import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import FormDrawerWrapper from '../../../features/components/FormDrawerWrapper'
import ShoptimiseOptimisationForm from './ShoptimiseOptimisationForm'
import Button from '../../../components/Button'

import useManageFormsDrawer from '../../../hooks/formHooks/useManageFormsDrawer'

import { mediaOrderSelector } from '../../../modules/selectors/mediaOrders'
import {
  mediaOrderSuggestedChangesWasLoadedSelector,
  optimisationHistoryWasLoadedSelector
} from '../../../modules/selectors/mediaOrderOptimisations'
import {
  clearMediaOrderSuggestedChanges,
  clearOptimisationHistory,
  getMediaOrderSuggestedChanges,
  getOptimisationHistory
} from '../../../modules/actions/mediaOrderOptimisations'
import { openForm } from '../../../modules/actions/forms'

import { SHOPTIMISE_OPTIMISATION } from '../../../constants/forms'

const ShoptimiseOptimisation = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const mediaOrder = useSelector(mediaOrderSelector)
  const mediaOrderSuggestedChangesWasLoaded = useSelector(mediaOrderSuggestedChangesWasLoadedSelector)
  const optimisationHistoryWasLoaded = useSelector(optimisationHistoryWasLoadedSelector)

  const { id: mediaOrderId } = mediaOrder

  const isFormLoading = !mediaOrderSuggestedChangesWasLoaded || !optimisationHistoryWasLoaded

  const { isFormOpen } = useManageFormsDrawer({ formName: SHOPTIMISE_OPTIMISATION })

  const openFormHandler = useCallback(() => {
    dispatch(openForm({ formName: SHOPTIMISE_OPTIMISATION }))
  }, [dispatch])

  const ButtonTrigger = () => {
    return <Button onClick={openFormHandler}>{t('Check for optimisations')}</Button>
  }

  useEffect(() => {
    if (isFormOpen && mediaOrderId && !mediaOrderSuggestedChangesWasLoaded) {
      dispatch(getMediaOrderSuggestedChanges(mediaOrderId))
      dispatch(getOptimisationHistory({ media_order: mediaOrderId, limit: 5 }))
    }
  }, [dispatch, mediaOrderId, mediaOrderSuggestedChangesWasLoaded, isFormOpen])

  useEffect(() => {
    return () => {
      dispatch(clearMediaOrderSuggestedChanges())
      dispatch(clearOptimisationHistory())
    }
  }, [dispatch])

  return (
    <FormDrawerWrapper
      OpenDrawerTrigger={ButtonTrigger}
      formName={SHOPTIMISE_OPTIMISATION}
      showOpenButton={true}
      openButtonText="Check for optimisations"
      isFormLoading={isFormLoading}
      skeletonSectionsNumber={2}
    >
      <ShoptimiseOptimisationForm />
    </FormDrawerWrapper>
  )
}

export default ShoptimiseOptimisation
