import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

import LineItemForm from './LineItemForm/'
import DisplayAdGoogleCreateForm from '../../../AdForms/GoogleDisplayAd/DisplayAdGoogleCreateForm'

import { mediaOrderUploadCreativeDataSelector } from '../../../../../modules/selectors/mediaOrders'

import { ROUTE_PARAMS } from '../../../../../constants/routes'
import { AD_FORM, LINE_ITEM_FORM } from '../index'
import { activeStepSelector } from '../../../../../modules/selectors/forms'

const AdCreativeForm = ({ activatedForm, isExistingCampaignFlow, setActivatedForm, onSuccessSubmit, adAccountId }) => {
  const activeStep = useSelector(activeStepSelector)
  const { campaign, lineItem } = useSelector(mediaOrderUploadCreativeDataSelector)

  const { id: lineItemId } = lineItem
  const { id: campaignId, advertising_channel_type: campaignType } = campaign

  const handleSuccessUploadCreative = useCallback(() => {
    onSuccessSubmit(
      {
        [ROUTE_PARAMS.campaignId]: campaignId,
        [ROUTE_PARAMS.lineItemId]: lineItemId
      },
      campaignType
    )
  }, [campaignId, campaignType, lineItemId, onSuccessSubmit])

  const handleSuccessLineItemCreation = useCallback(() => {
    setActivatedForm(AD_FORM)
  }, [setActivatedForm])

  const getActivatedForm = useCallback(() => {
    switch (activatedForm) {
      case AD_FORM:
        return (
          <DisplayAdGoogleCreateForm
            campaignId={campaignId}
            lineItemId={lineItemId}
            adAccountId={adAccountId}
            onSuccessSubmit={handleSuccessUploadCreative}
            formProps={{
              // We show activeStep + 2 because this is third form, first and second forms have only one step
              customActiveStep: activeStep + 2,
              customStepsForProgress: 4
            }}
          />
        )
      case LINE_ITEM_FORM:
      default:
        return (
          <LineItemForm
            adAccountId={adAccountId}
            isExistingCampaignFlow={isExistingCampaignFlow}
            onSuccessSubmit={handleSuccessLineItemCreation}
          />
        )
    }
  }, [
    activeStep,
    activatedForm,
    campaignId,
    lineItemId,
    adAccountId,
    isExistingCampaignFlow,
    handleSuccessUploadCreative,
    handleSuccessLineItemCreation
  ])

  return <>{getActivatedForm()}</>
}

export default AdCreativeForm
