import React from 'react'
import classnames from 'classnames'

import useStyles from './styles'

const CardContainer = ({ className, isDisabled, onClick, children }) => {
  const classes = useStyles({ isDisabled })

  return (
    <div className={classnames(classes.card, className)} onClick={isDisabled ? null : onClick}>
      {children}
    </div>
  )
}

export default CardContainer
