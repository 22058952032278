import { call, put, all, takeEvery } from 'redux-saga/effects'

import { handleWebSocketRequest } from '../../helpers/modules/saga'
import {
  GET_CHANGE_HISTORY,
  getChangeHistorySuccess,
  getChangeHistoryFailure,
  GET_AI_SUGGESTIONS,
  CLEAR_GET_AI_SUGGESTIONS,
  getAiSuggestionsSuccess,
  getAiSuggestionsFailure
} from '../actions/tools'

import { getAiSuggestionsService, getChangeHistoryService } from '../services/tools'

function* toolsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_AI_SUGGESTIONS, getAiSuggestionsWorker),
    takeEvery(GET_CHANGE_HISTORY, getChangeHistoryWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getAiSuggestionsWorker({ slug, params }) {
  yield call(handleWebSocketRequest, {
    service: getAiSuggestionsService,
    serviceProps: {
      slug,
      params
    },
    cancelActionType: `${CLEAR_GET_AI_SUGGESTIONS}_${slug}`,
    successAction: response => getAiSuggestionsSuccess(slug, response),
    failureAction: error =>
      getAiSuggestionsFailure(
        slug,
        "Sorry, looks like the AI is having a bad day. Please regenerate if you're not happy with what has been generated"
      )
  })
}

function* getChangeHistoryWorker({ params }) {
  try {
    const response = yield call(getChangeHistoryService, params)
    yield put(getChangeHistorySuccess(response))
  } catch (error) {
    yield put(getChangeHistoryFailure(error))
  }
}

export default toolsWatcher
