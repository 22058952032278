import { AUDIENCE } from '../../../LineItemGoogleCreate/LineItemGoogleCreateForm/LineItemGoogleAudienceForm/fields'
import {
  getDeletedCriterionsArray,
  removeExistingCriterions
} from '../../../../../../features/helpers/googleCriterionsHelpers'

export const formatValuesToFE = initialAudienceCriterions => ({
  [AUDIENCE]: initialAudienceCriterions.map(criterion => ({
    label: criterion['display_name'],
    value: criterion['user_list']?.['user_list'],
    criterionResourceName: criterion['resource_name']
  }))
})

export const transformValuesToBE = ({ values, initialValues, account, adGroupId }) => {
  const audiencesToDelete = getDeletedCriterionsArray(initialValues[AUDIENCE], values[AUDIENCE])

  const audiencesToCreate = removeExistingCriterions(initialValues[AUDIENCE], values[AUDIENCE])

  return {
    account,
    remove_operations: audiencesToDelete,
    operations: audiencesToCreate.map(audience => ({
      account,
      ad_group: adGroupId,
      user_list: {
        user_list: audience.value
      }
    }))
  }
}
