import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

export const tikTokVideosSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.tikTokVideos.results,
  results => results
)

export const tikTokVideosIsLoadingSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.tikTokVideos.isLoading,
  isLoading => isLoading
)

export const tikTokVideosErrorSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.tikTokVideos.error,
  error => error
)

export const tikTokVideosWasLoadedSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.tikTokVideos.wasLoaded,
  wasLoaded => wasLoaded
)

export const tikTokVideosNextSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.tikTokVideos.paging.next,
  next => next
)

export const tikTokVideosAfterSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.tikTokVideos.paging.after,
  after => after
)

// IMAGES
export const facebookMediaImagesSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.images.results,
  results => results
)

export const facebookImagesIsLoadingSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.images.isLoading,
  isLoading => isLoading
)

export const facebookImagesWasLoadedSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.images.wasLoaded,
  wasLoaded => wasLoaded
)

export const facebookImagesErrorSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.images.error,
  error => error
)

export const facebookImagesIsNextSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.images.paging.next,
  next => next
)

export const facebookImagesAfterSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.images.paging,
  paging => paging.cursors?.after
)

// VIDEOS PREVIEWS
export const facebookVideoPreviewsSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.videoPreviews.results,
  results => results
)

export const facebookVideoPreviewsIsLoadingSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.videoPreviews.isLoading,
  isLoading => isLoading
)

export const facebookVideoPreviewsWasLoadedSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.videoPreviews.wasLoaded,
  wasLoaded => wasLoaded
)

export const facebookVideoPreviewsErrorSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.videoPreviews.error,
  error => error
)

export const facebookVideoPreviewsNextSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.videoPreviews.paging.next,
  next => next
)

export const facebookVideoPreviewsAfterSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.videoPreviews.paging,
  paging => paging.cursors?.after
)

// VIDEO
export const facebookMediaVideoSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.video.data,
  data => data
)

// VIDEO URLS
export const facebookVideoUrlsSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.videoUrls.results,
  results => results
)

export const facebookRequestedVideoIdsSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.videoUrls.requestedIds,
  requestedIds => requestedIds
)

export const facebookVideoUrlsWasLoadedSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.videoUrls.wasLoaded,
  wasLoaded => wasLoaded
)

// IMAGE URL
export const facebookImageUrlsSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.imageUrls.results,
  results => results
)

export const facebookRequestedImageHashesSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.imageUrls.requestedHashes,
  requestedHashes => requestedHashes
)

export const facebookImageUrlsWasLoadedSelector = createSelector(
  ({ mediaFiles }) => mediaFiles.imageUrls.wasLoaded,
  wasLoaded => wasLoaded
)
