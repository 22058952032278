import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useStyles from './style'

function Checkbox({
  id,
  name,
  title,
  titleColor,
  checked = false,
  onCheck,
  className,
  readOnly,
  isLarge = false,
  centerVertically,
  // partially checked is just UI state (dash in the middle) for unchecked checkbox
  // currently used on SelectMultiLevel to indicate that child to current checkbox is checked
  isPartiallyChecked,
  disabled,
  children
}) {
  const classes = useStyles({
    isLarge,
    isPartiallyChecked,
    // if isPartiallyChecked > center by default
    centerVertically: centerVertically || isPartiallyChecked,
    titleColor,
    disabled
  })

  const onChangeHandler = useCallback(
    e => {
      if (!disabled && onCheck) {
        onCheck(e)
      }
    },
    [onCheck, disabled]
  )

  return (
    <div className={classnames('checkbox', classes.checkbox, className)}>
      <input
        className={classes.checkboxInput}
        type="checkbox"
        id={id}
        onChange={onChangeHandler}
        checked={checked}
        readOnly={readOnly}
        name={name}
        disabled={disabled}
      />
      <label className={classes.checkboxLabel} htmlFor={id}>
        <div className={classes.checkboxContainer}>
          <div className={classes.checkmark} />
        </div>
        {title && <div className={classes.checkboxTitle}>{title}</div>}
        {children && <div className={classes.checkboxBody}>{children}</div>}
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleColor: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  isLarge: PropTypes.bool,
  centerVertically: PropTypes.bool,
  isPartiallyCheck: PropTypes.bool,
  disabled: PropTypes.bool
}

export default Checkbox
