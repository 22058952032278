import { ADMIN, ASSET_MANAGER, BUYER, INSTALLER, MANAGER, REPORT } from '../../../../../constants/permissions'
import { ADD_TO_ALL_CONTROLLER_ACCOUNTS, BRAND_CATEGORY, SELF_ACCOUNTS } from '../../fields'

export const EMAIL = 'email'
export const ROLE = 'role'

export const MEDIA_SUB_CATEGORIES = 'media_sub_categories'

export const roleOptions = [
  { label: 'Admin', value: ADMIN },
  { label: 'Manager', value: MANAGER },
  { label: 'Reporting', value: REPORT },
  { label: 'Buyer', value: BUYER },
  { label: 'Asset Manager', value: ASSET_MANAGER },
  { label: 'Installer', value: INSTALLER }
]

export const initialValues = {
  // todo ask about self accounts access for new types of users
  [EMAIL]: '',
  [ROLE]: MANAGER,
  [ADD_TO_ALL_CONTROLLER_ACCOUNTS]: true,
  [SELF_ACCOUNTS]: [],
  [BRAND_CATEGORY]: '',
  [MEDIA_SUB_CATEGORIES]: []
}
