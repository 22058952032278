import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import FacebookAudiencesFields from '../../../../../../../../ReusableFormFIelds/FacebookAudiencesFields'
import Step from '../../../../../../../../../../features/components/Forms/StepForm/Step'

import { selectedControllerDataSelector } from '../../../../../../../../../../modules/selectors/app'

const CustomAudiencesStep = ({ formik, adAccountId }) => {
  const selectedController = useSelector(selectedControllerDataSelector)

  const selectedControllerName = selectedController?.name

  return (
    <Step
      stepTitle="Select an audience"
      stepDescription={`Target  ${
        selectedControllerName ? selectedControllerName : ''
      } shopper audiences on Facebook and Instagram`}
    >
      <FacebookAudiencesFields formik={formik} adAccountId={adAccountId} />
    </Step>
  )
}

CustomAudiencesStep.propTypes = {
  adAccountId: PropTypes.string.isRequired
}

export default CustomAudiencesStep
