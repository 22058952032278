import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { selectedControllerDataSelector } from '../../../../../modules/selectors/app'

import Button from '../../../../../components/Button'

import useStyles from './styles'

const WelcomeSection = () => {
  const { t } = useTranslation()

  const selectedSelfAccountController = useSelector(selectedControllerDataSelector)

  const controllerName = selectedSelfAccountController?.name
  const banner = selectedSelfAccountController?.banner

  const classes = useStyles({ banner })

  const shortIntro = selectedSelfAccountController?.['short_intro']
  const mediaKitFile = selectedSelfAccountController?.['media_kit_file']

  const paragraphs = shortIntro?.split('\r\n\r\n')

  const title = useMemo(() => {
    // check if the controller name starts with “The ” and if it does don’t add an extra one.
    // Make sure to check for the space after the 'the' so we don’t hide it if the controller name is Theragun or something.
    const article = controllerName?.toLowerCase().startsWith('the ') ? '' : 'the '
    return t('welcomeToMediaHubMessage', { controllerName, article })
  }, [t, controllerName])

  return (
    <section className={classes.section}>
      <div className={classes.content}>
        <h1 className={classnames(classes.header, classes.fadeInHeader)}>{title}</h1>
        <div className={classes.fadeInDescription}>
          {paragraphs &&
            paragraphs.map((paragraph, index) => (
              <p key={index} className={classes.description}>
                {paragraph}
              </p>
            ))}
        </div>
        {mediaKitFile && (
          <Button
            className={classnames(classes.button, classes.fadeInButton)}
            onClick={() => window.open(mediaKitFile)}
          >
            {t('Download rate card')}
          </Button>
        )}
      </div>
    </section>
  )
}

export default WelcomeSection
