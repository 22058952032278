import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import MultiSelectBox from '../../../../../../../features/components/Form/MultiSelectBox'
import FieldLabelWrapper from '../../../../../../../features/components/Form/FieldLabelWrapper'

import {
  choicesInterestsLoadingSelector,
  choicesInterestsSelector
} from '../../../../../../../modules/selectors/choices'
import { selectedAdAccountIdSelector } from '../../../../../../../modules/selectors/app'
import { clearChoicesInterests, getChoicesInterests } from '../../../../../../../modules/actions/choices'

import { formatNumber } from '../../../../../../../helpers/numbers'

import { INTERESTS } from '../../../../../../ReusableFormFields/InterestsFields/fields'

const InterestsContent = ({ values, setFieldValue }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const interests = useSelector(choicesInterestsSelector)
  const interestsLoading = useSelector(choicesInterestsLoadingSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const onSearchInterests = useCallback(
    value => {
      if (value) {
        dispatch(
          getChoicesInterests({
            account: selectedAdAccountId,
            search: value
          })
        )
      } else {
        dispatch(clearChoicesInterests())
      }
    },
    [dispatch, selectedAdAccountId]
  )

  const formattedInterests = useMemo(
    () =>
      interests &&
      interests.map(item => {
        const { audience_size_lower_bound: audienceSizeLowerBound, audience_size_upper_bound: audienceSizeUpperBound } =
          item

        // add comma separators to the numbers, and add the word people after it
        const description =
          audienceSizeLowerBound &&
          audienceSizeUpperBound &&
          `${formatNumber(audienceSizeLowerBound, { min: 0, max: 0 })} - ${formatNumber(audienceSizeUpperBound, {
            min: 0,
            max: 0
          })} ${t('people')}`

        return {
          value: item.id,
          label: item.name,
          ...(description && {
            description
          })
        }
      }),
    [interests, t]
  )

  return (
    <FieldLabelWrapper label="Interests">
      <MultiSelectBox
        placeholder={t('Add an Interest')}
        name={INTERESTS}
        options={formattedInterests}
        value={values[INTERESTS]}
        isLoading={interestsLoading}
        onSearch={onSearchInterests}
        setFieldValue={setFieldValue}
      />
    </FieldLabelWrapper>
  )
}

export default InterestsContent
