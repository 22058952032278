import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'

import Checkbox from '../../../Form/Checkbox'

import { ReactComponent as AngleIcon } from '../../../../assets/icons/chevron-arrow-down.svg'

import { getIsPartiallyCheckedState } from './helpers'

import useStyles from './styles'

const MultiLevelSelectOption = memo(
  ({ option, optionsState = {}, isSearchApplied, searchOptions, onOptionSelect, onOptionDeselect, onOptionExpand }) => {
    const { id, name, options } = option

    const isChecked = optionsState[id]?.isChecked
    const isExpanded = optionsState[id]?.isExpanded

    const isPartiallyChecked = !isChecked && options && getIsPartiallyCheckedState(option, optionsState)

    const classes = useStyles({ isExpanded })

    const optionCheckHandler = useCallback(
      e => {
        e.preventDefault()

        // partially checked checkbox fire deselect logic
        isChecked || isPartiallyChecked ? onOptionDeselect(option) : onOptionSelect(option)
      },
      [isChecked, isPartiallyChecked, onOptionDeselect, onOptionSelect, option]
    )

    const optionExpandHandler = () => {
      onOptionExpand(id)
    }

    if (isSearchApplied && !searchOptions.includes(id)) {
      return null
    }

    return (
      <>
        <div className={classes.option}>
          {options && (
            <div className={classes.angleIconContainer} onClick={optionExpandHandler}>
              <AngleIcon className={classes.optionAngleIcon} />
            </div>
          )}
          <div className={classes.optionCheckbox} onClick={optionCheckHandler}>
            <Checkbox
              id={id}
              checked={isChecked}
              isPartiallyChecked={isPartiallyChecked}
              onCheck={optionCheckHandler}
            />
            <span>{name}</span>
          </div>
        </div>
        {options && (
          <AnimateHeight duration={200} height={isExpanded ? 'auto' : 0}>
            <div className={classes.expandableContainer}>
              {options.map(option => (
                <MultiLevelSelectOption
                  key={option.id}
                  option={option}
                  optionsState={optionsState}
                  isSearchApplied={isSearchApplied}
                  searchOptions={searchOptions}
                  onOptionSelect={onOptionSelect}
                  onOptionDeselect={onOptionDeselect}
                  onOptionExpand={onOptionExpand}
                />
              ))}
            </div>
          </AnimateHeight>
        )}
      </>
    )
  }
)

MultiLevelSelectOption.propTypes = {
  option: PropTypes.object.isRequired,
  optionsState: PropTypes.object,
  isSearchApplied: PropTypes.bool,
  searchOptions: PropTypes.array,
  onOptionSelect: PropTypes.func,
  onOptionDeselect: PropTypes.func,
  onOptionExpand: PropTypes.func
}

export default MultiLevelSelectOption
