import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import EditSectionsList from '../../../../features/components/Forms/EditForm/EditSectionsList'
import NameSectionForm from './Sections/NameSection/NameSectionForm'
import NameSectionPreview from './Sections/NameSection/NameSectionPreview'
import BudgetSection from './Sections/BudgetSection'
import BudgetSectionPreview from './Sections/BudgetSection/BudgetSectionPreview'

import useManageEditFormData from '../../../../hooks/formHooks/useManageEditFormData'

import { getCampaign, clearCampaign } from '../../../../modules/actions/campaigns'
import {
  campaignSelector,
  campaignLoadingSelector,
  campaignWasLoadedSelector
} from '../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../modules/selectors/app'

import { CAMPAIGN_TIKTOK_EDIT } from '../../../../constants/forms'
import { NAME, BUDGET } from './fields'

// here defined initial open states for function CampaignTikTokEdit sections
const sectionsInitialOpenStates = {
  [NAME]: false,
  [BUDGET]: false
}

function CampaignTikTokEdit({ shouldBeCleared }) {
  const dispatch = useDispatch()

  const campaign = useSelector(campaignSelector)
  const campaignIsLoading = useSelector(campaignLoadingSelector)
  const campaignWasLoaded = useSelector(campaignWasLoadedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  // to avoid loading representation
  const isInitialDataLoading = campaignIsLoading || !campaignWasLoaded

  const getItemDataHandler = useCallback(
    selectedEditItemId => {
      dispatch(getCampaign({ id: selectedEditItemId, account: selectedAdAccountId }))
    },
    [dispatch, selectedAdAccountId]
  )

  const clearItemDataHandler = useCallback(() => {
    if (shouldBeCleared) {
      dispatch(clearCampaign())
    }
  }, [dispatch, shouldBeCleared])

  useManageEditFormData({
    formName: CAMPAIGN_TIKTOK_EDIT,
    loadedDataId: campaign.id,
    getDataHandler: getItemDataHandler,
    clearDataHandler: clearItemDataHandler
  })

  // here defined all edit sections for CampaignTikTokEdit
  const editSections = useMemo(
    () => [
      {
        sectionName: NAME,
        title: 'Name',
        PreviewComponent: <NameSectionPreview />,
        FormComponent: <NameSectionForm />
      },
      {
        sectionName: BUDGET,
        title: 'Budget',
        PreviewComponent: <BudgetSectionPreview />,
        FormComponent: <BudgetSection />
      }
    ],
    []
  )

  return (
    <FormDrawerWrapper
      title="Update the settings of your campaign"
      subTitle="Edit a TikTok campaign"
      formName={CAMPAIGN_TIKTOK_EDIT}
      showOpenButton={false}
    >
      <EditSectionsList
        editSections={editSections}
        isEditDataLoading={isInitialDataLoading}
        editItemData={campaign}
        sectionsInitialOpenStates={sectionsInitialOpenStates}
      />
    </FormDrawerWrapper>
  )
}

export default CampaignTikTokEdit
