import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import RadioBlock from '../../../../../../components/Form/RadioBlock'
import Field from '../../../../../../components/Form/Field'
// import AdvancedSetupFields from './AdvancedSetupFields'
import LifetimeBudgetField from '../../../../../Multiplatform/UploadAdCreative/LifetimeBudgetField'

import useAccessibleCampaignsRoutes from '../../../../../Multiplatform/RoutesMissingWarning/useAccessibleCampaignsRoutes'

import { ReactComponent as ProductRetargetingIcon } from '../../../../../../assets/icons/campaigns/productRetargeting.svg'
import { ReactComponent as AdvantageShoppingIcon } from '../../../../../../assets/icons/campaigns/advantageShopping.svg'
import { ReactComponent as ProximityCampaignIcon } from '../../../../../../assets/icons/campaigns/proximityCampaign.svg'
import { ReactComponent as AudienceTargetingIcon } from '../../../../../../assets/icons/campaigns/audienceTargeting.svg'

import {
  selectedSelfAccountDataSelector,
  selectedControllerDataSelector
} from '../../../../../../modules/selectors/app'

import {
  CAMPAIGN,
  CAMPAIGN_OPTION,
  // CAMPAIGN_OPTION_ADVANCED_SETUP,
  CAMPAIGN_OPTION_ADVANTAGE_SHOPPING,
  CAMPAIGN_OPTION_AUDIENCE_TARGETING,
  CAMPAIGN_OPTION_EXISTING_CAMPAIGN,
  CAMPAIGN_OPTION_PRODUCT_RETARGETING,
  CAMPAIGN_OPTION_PROXIMITY_CAMPAIGN
} from '../fields'
import { FACEBOOK_PLATFORM } from '../../../../../../constants/selectLists/platformList'
import {
  AUDIENCE_RETARGETING_ROUTE,
  PRODUCT_RETARGETING_ROUTE,
  PROXIMITY_ROUTE,
  SHOPPING_CAMPAIGNS_ROUTE
} from '../../../../../../pages/Settings/AdvertiserAccounts/ClientAccountForms/ClientAccountWithMarginsCreate/ClientAccountWithMarginsCreateForm/fields'

import useStepFormStyles from '../../../../../../styles/common/stepForms'

const CampaignFormContent = ({ formik, formattedCampaigns, remainingBudget }) => {
  const stepFormClasses = useStepFormStyles()
  const { t } = useTranslation()

  const selectedSelfAccountData = useSelector(selectedSelfAccountDataSelector)
  const controller = useSelector(selectedControllerDataSelector)

  const selectedControllerName = controller?.name

  const allowedRoute = useAccessibleCampaignsRoutes(FACEBOOK_PLATFORM)
  const hasAccessibleRoutes = Object.values(allowedRoute).some(route => route)

  const { setFieldValue, values } = formik

  return (
    <div className={stepFormClasses.stepBody}>
      {!!formattedCampaigns.length && (
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_existing_campaign"
          name={CAMPAIGN_OPTION}
          label={t('Upload an ad to an existing campaign')}
          value={CAMPAIGN_OPTION_EXISTING_CAMPAIGN}
          selectedValue={values[CAMPAIGN_OPTION]}
          description={<p>{t('Upload a new ad to a new or existing ad set in one of your campaigns')}</p>}
          hasOrSpacing={hasAccessibleRoutes}
        >
          <Field formik={formik} name={CAMPAIGN} placeholder={t('Campaign')} options={formattedCampaigns} />
        </RadioBlock>
      )}
      {allowedRoute[SHOPPING_CAMPAIGNS_ROUTE] && (
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_option_advanced_shopping"
          name={CAMPAIGN_OPTION}
          label={t('driveOnlineSalesFacebook', { accountName: selectedSelfAccountData.name })}
          value={CAMPAIGN_OPTION_ADVANTAGE_SHOPPING}
          selectedValue={values[CAMPAIGN_OPTION]}
          Icon={AdvantageShoppingIcon}
          description={
            <>
              <p>{t('Promote to new and existing customers with an Advantage+ Shopping campaign')}</p>
              <p>{t('Creatives are generated from your product catalogue')}</p>
            </>
          }
        />
      )}
      {allowedRoute[PRODUCT_RETARGETING_ROUTE] && (
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_option_product_retargeting"
          name={CAMPAIGN_OPTION}
          label={t('Individual product retargeting')}
          value={CAMPAIGN_OPTION_PRODUCT_RETARGETING}
          selectedValue={values[CAMPAIGN_OPTION]}
          Icon={ProductRetargetingIcon}
          description={
            <>
              <p>{t('Retarget shoppers that have viewed but not yet purchased specific products')}</p>
              <p>{t('Creatives generated from the imagery and description on your product page')}</p>
            </>
          }
        >
          <LifetimeBudgetField remainingBudget={remainingBudget} formik={formik} platform={FACEBOOK_PLATFORM} />
        </RadioBlock>
      )}

      {allowedRoute[PROXIMITY_ROUTE] && (
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_proximity_campaign"
          name={CAMPAIGN_OPTION}
          label={t('Proximity Campaign')}
          value={CAMPAIGN_OPTION_PROXIMITY_CAMPAIGN}
          selectedValue={values[CAMPAIGN_OPTION]}
          Icon={ProximityCampaignIcon}
          description={
            <>
              <p>{t('proximityCampaignAudiences', { controllerName: selectedControllerName })}</p>
              <p>{t('Advertise your promotional activity with social ads to people most likely to visit.')}</p>
            </>
          }
        >
          <LifetimeBudgetField remainingBudget={remainingBudget} formik={formik} platform={FACEBOOK_PLATFORM} />
        </RadioBlock>
      )}

      {allowedRoute[AUDIENCE_RETARGETING_ROUTE] && (
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_audience_targeting"
          name={CAMPAIGN_OPTION}
          label={t('Audience Targeting')}
          value={CAMPAIGN_OPTION_AUDIENCE_TARGETING}
          selectedValue={values[CAMPAIGN_OPTION]}
          Icon={AudienceTargetingIcon}
          description={
            <>
              <p>{t('targetAudiencesOptimisation', { controllerName: controller?.name })}</p>
              <p>{t('Select from a wide range of customer and product category audiences')}</p>
            </>
          }
        >
          <LifetimeBudgetField remainingBudget={remainingBudget} formik={formik} platform={FACEBOOK_PLATFORM} />
        </RadioBlock>
      )}
      {/*todo revert - this should be hidden until demo*/}
      {/*<RadioBlock*/}
      {/*  setFieldValue={setFieldValue}*/}
      {/*  id="radio_new_campaign"*/}
      {/*  name={CAMPAIGN_OPTION}*/}
      {/*  label={t('Advanced Setup')}*/}
      {/*  value={CAMPAIGN_OPTION_ADVANCED_SETUP}*/}
      {/*  selectedValue={values[CAMPAIGN_OPTION]}*/}
      {/*>*/}
      {/*  <AdvancedSetupFields {...contentFieldsProps} />*/}
      {/*</RadioBlock>*/}
    </div>
  )
}

CampaignFormContent.propTypes = {
  formik: PropTypes.object.isRequired,
  formattedCampaigns: PropTypes.array.isRequired,
  currencySymbol: PropTypes.string
}

export default CampaignFormContent
