import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ContractDetails from './ContractDetails'
import SkeletonQuotationBuilderForm from '../../../Proposals/QuotationBuilderPage/QuotationForm/Skeleton'

import { MediaOrderFormProvider } from '../../../../forms/Multiplatform/MediaOrderForms/MediaOrderFormContext'
import { ContractBuilderProvider } from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/ProductsManage/ContractBuilderContext'

import { clearActiveContract, clearGetContract, getContract } from '../../../../modules/actions/contracts'
import { activeContractSelector, getContractIsLoadingSelector } from '../../../../modules/selectors/contracts'
import { mediaOrderSelector } from '../../../../modules/selectors/mediaOrders'

const DetailsSection = () => {
  const dispatch = useDispatch()

  const mediaOrder = useSelector(mediaOrderSelector)
  const mediaOrderContractId = mediaOrder?.contract_id

  const activeContract = useSelector(activeContractSelector)
  const contractIsLoading = useSelector(getContractIsLoadingSelector)

  useEffect(() => {
    if (mediaOrderContractId) {
      dispatch(
        getContract(mediaOrderContractId, {
          inventory_detail: true
        })
      )
    }
  }, [dispatch, mediaOrderContractId])

  useEffect(() => {
    return () => {
      dispatch(clearActiveContract())
      dispatch(clearGetContract())
    }
  }, [dispatch])

  return contractIsLoading ? (
    <SkeletonQuotationBuilderForm showHeading={false} />
  ) : (
    <MediaOrderFormProvider initialSelfAccount={activeContract?.account}>
      <ContractBuilderProvider>
        <ContractDetails />
      </ContractBuilderProvider>
    </MediaOrderFormProvider>
  )
}

export default DetailsSection
