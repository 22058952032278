import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

// Get current statement
export const currentStatementSelector = createSelector(
  ({ statements }) => statements.currentStatement.data,
  data => data
)

export const currentStatementIsLoadingSelector = createSelector(
  ({ statements }) => statements.currentStatement.isLoading,
  isLoading => isLoading
)

export const currentStatementErrorSelector = createSelector(
  ({ statements }) => statements.currentStatement.error,
  error => error
)

export const currentStatementWasLoadedSelector = createSelector(
  ({ statements }) => statements.currentStatement.wasLoaded,
  wasLoaded => wasLoaded
)

// Get statements as csv
export const csvStatementSelector = createSelector(
  ({ statements }) => statements.csvStatement.data,
  data => data
)

export const csvStatementIsLoadingSelector = createSelector(
  ({ statements }) => statements.csvStatement.isLoading,
  isLoading => isLoading
)

export const csvStatementErrorSelector = createSelector(
  ({ statements }) => statements.csvStatement.error,
  error => error
)

export const csvStatementWasLoadedSelector = createSelector(
  ({ statements }) => statements.csvStatement.wasLoaded,
  wasLoaded => wasLoaded
)

// Update statement
export const updateStatementSelector = createSelector(
  ({ statements }) => statements.updateStatement.results,
  results => results
)

export const updateStatementIsLoadingSelector = createSelector(
  ({ statements }) => statements.updateStatement.isLoading,
  isLoading => isLoading
)

export const updateStatementErrorSelector = createSelector(
  ({ statements }) => statements.updateStatement.error,
  error => error
)

export const updateStatementWasLoadedSelector = createSelector(
  ({ statements }) => statements.updateStatement.wasLoaded,
  wasLoaded => wasLoaded
)

export const updateStatementIDSelector = createSelector(
  ({ statements }) => statements.updateStatement.id,
  id => id
)

// Get statements
export const getStatementsSelector = createSelector(
  ({ statements }) => statements.statements.results,
  results => results
)

export const getStatementsIsLoadingSelector = createSelector(
  ({ statements }) => statements.statements.isLoading,
  isLoading => isLoading
)

export const getStatementsErrorSelector = createSelector(
  ({ statements }) => statements.statements.error,
  error => error
)

export const getStatementsWasLoadedSelector = createSelector(
  ({ statements }) => statements.statements.wasLoaded,
  wasLoaded => wasLoaded
)
