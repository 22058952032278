import { ENDPOINT_PLATFORM, ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'
import { replaceRouteParams } from '../../helpers/url'

// PLOP_APPEND_PATTERN_ANCHOR

export function getAudiencesService(params, platform) {
  return madeRequest('GET', {
    params,
    url: replaceRouteParams(ENDPOINTS.audiences, { platform: ENDPOINT_PLATFORM[platform] })
  })
}

export function createAudienceService(data, platform) {
  return madeRequest('POST', {
    data,
    url: replaceRouteParams(ENDPOINTS.audiences, { platform: ENDPOINT_PLATFORM[platform] })
  })
}

export function deleteAudienceService({ id, account }, platform) {
  return madeRequest('DELETE', {
    params: {
      account
    },
    url: replaceRouteParams(`${ENDPOINTS.audiences}${id}/`, { platform: ENDPOINT_PLATFORM[platform] })
  })
}
