import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIn, useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import Form from '../../../../../components/Form'
import Field from '../../../../../components/Form/Field'
import SingleFileUpload from '../../../../ReusableFormFields/SingleFileUpload'
import FieldsSection from '../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../features/components/Form/FieldsSection/FieldRow'

import { addMediaOrderFile, clearAddMediaOrderFile } from '../../../../../modules/actions/mediaOrders'
import {
  addMediaOrderFileWasCreatedSelector,
  addMediaOrderFileErrorSelector,
  addMediaOrderFileIsLoadingSelector
} from '../../../../../modules/selectors/mediaOrders'

import { UPLOAD_MEDIA_ORDER_FILE } from '../../../../../constants/forms'

import { validationSchema } from './validation'
import { transformValuesToBE } from './formatters'
import {
  FILE_NAME,
  FILE_TYPE,
  FILE_URL,
  initialValues,
  initialFileValues,
  mediaOrderFileTypesList,
  DESCRIPTION,
  OTHER_TYPE
} from './fields'

const fileUploadOptions = { isPrivate: true }
function UploadMediaOrderFileForm({ mediaOrderId }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const fileWasAdded = useSelector(addMediaOrderFileWasCreatedSelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values)
      dispatch(
        addMediaOrderFile({
          ...transformedData,
          media_order: mediaOrderId
        })
      )
    },
    [dispatch, mediaOrderId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const { values, errors, touched, setFieldValue, setValues } = formik

  const imageError = getIn(errors, FILE_URL)
  const imageTouched = getIn(touched, FILE_URL)
  const imageMissingError = imageTouched && imageError

  const handleMediaUploaded = useCallback(
    uploadedFile => {
      setFieldValue(FILE_URL, uploadedFile.url)
      setFieldValue(FILE_NAME, uploadedFile.name)
    },
    [setFieldValue]
  )

  const handleMediaFileRemove = useCallback(() => {
    setValues({ ...values, ...initialFileValues })
  }, [setValues, values])

  const clearAddFileHandler = useCallback(() => dispatch(clearAddMediaOrderFile()), [dispatch])

  return (
    <Form
      formik={formik}
      formName={UPLOAD_MEDIA_ORDER_FILE}
      // processing
      successSubmit={fileWasAdded}
      errorSelector={addMediaOrderFileErrorSelector}
      isLoadingSelector={addMediaOrderFileIsLoadingSelector}
      // after form submit
      clearHandler={clearAddFileHandler}
    >
      <FieldsSection title="Media booking file">
        <FieldRow title="Upload File" description="File type: pdf">
          <SingleFileUpload
            fileName={values[FILE_NAME]}
            fileURL={values[FILE_URL]}
            onFileUploaded={handleMediaUploaded}
            onFileRemove={handleMediaFileRemove}
            error={imageMissingError}
            fileUploadOptions={fileUploadOptions}
            accept="application/pdf"
          />
        </FieldRow>
        <FieldRow title="Document type" description="Please select the type of document being uploaded">
          <Field
            formik={formik}
            name={FILE_TYPE}
            placeholder={t('Select signatory')}
            options={mediaOrderFileTypesList}
          />
          {/*todo restyle*/}
          <br />
          {values[FILE_TYPE] === OTHER_TYPE && (
            <Field formik={formik} name={DESCRIPTION} placeholder={t('Description')} />
          )}
        </FieldRow>
      </FieldsSection>
    </Form>
  )
}

export default UploadMediaOrderFileForm
