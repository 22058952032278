import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import ListItemsTable from '../../../../../../features/components/ListItemsTable'
import AdsWrapper from '../AdsWrapper'

import { selectedAdAccountDataSelector } from '../../../../../../modules/selectors/app'

import { DV_360_PLATFORM, DV_360_PROVIDER_PLATFORM } from '../../../../../../constants/selectLists/platformList'

const DV360PerformingAds = ({
  dataSelector,
  loadingSelector,
  errorSelector,
  wasLoadedSelector,
  showOnlyHighestPerforming = true,
  loadMoreHandler
}) => {
  const { currency_symbol: selectedAdAccountCurrencySymbol } = useSelector(selectedAdAccountDataSelector)
  const currencySymbol = selectedAdAccountCurrencySymbol

  const combinedAds = useSelector(dataSelector)

  const dv360Ads = useMemo(() => {
    return combinedAds.filter(ad => ad.provider === DV_360_PROVIDER_PLATFORM)
  }, [combinedAds])

  const sortedDV360Ads = useMemo(() => {
    return showOnlyHighestPerforming
      ? dv360Ads.sort((a, b) => b.ctr - a.ctr).slice(0, 5)
      : dv360Ads.sort((a, b) => b.ctr - a.ctr)
  }, [dv360Ads, showOnlyHighestPerforming])

  return (
    <section id="dv360-ad-cards-list">
      {/*TODO: Replace with AdsGallery when it's ready*/}
      <ListItemsTable
        items={sortedDV360Ads}
        isLoadingSelector={loadingSelector}
        errorSelector={errorSelector}
        wasLoadedSelector={wasLoadedSelector}
        platform={DV_360_PLATFORM}
        currencySymbol={currencySymbol}
        noDataText={'There are no highest performing Display or Video ads'}
        ContentWrapper={AdsWrapper}
        loadMoreDataHandler={loadMoreHandler}
      />
    </section>
  )
}

export default DV360PerformingAds
