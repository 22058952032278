import { useCallback, useMemo } from 'react'

import useSaveData from './useSaveData'

import { getValue } from './helpers/getProductValue'
import { reorderElement } from '../../../../../../helpers/arrays'

import { MEDIA_PRODUCTS } from '../../fields'
import { SORT } from '../../../../../../constants/other'

export default function useOrderProducts({ values, products, setFieldValue, setTableSort, allowEdit }) {
  const { handleSaveDataToBE } = useSaveData({ values, allowEdit })

  const orderedProducts = useMemo(() => {
    // This creates a new sorted array without modifying the original array
    return [...products]
      .sort((a, b) => a.order - b.order)
      .map(product => {
        if (!product.data) {
          // If product is not selected yet - disable drag and drop
          return {
            ...product,
            forbidDragAndDrop: true
          }
        } else {
          return product
        }
      })
  }, [products])

  const handleProductsOrderUpdate = useCallback(
    ({ destination, source }) => {
      if (destination && destination.index !== source.index) {
        const orderedProducts = [...products].sort((a, b) => a.order - b.order)
        const reorderedProducts = reorderElement(orderedProducts, source.index, destination.index)
        const reorderedProductsPayload = reorderedProducts.map((product, index) => ({
          ...product,
          order: index
        }))
        setFieldValue(MEDIA_PRODUCTS, reorderedProductsPayload)

        handleSaveDataToBE({ updatedProductValues: reorderedProductsPayload })
      }
    },
    [products, setFieldValue, handleSaveDataToBE]
  )

  const handleSortChange = useCallback(
    newSort => {
      const { parameter, direction } = newSort

      const compare = (aValue, bValue) => {
        if (aValue > bValue) return direction === SORT.ASC ? 1 : -1
        if (aValue < bValue) return direction === SORT.ASC ? -1 : 1
        return 0
      }

      if (parameter) {
        const sortedProducts = [...orderedProducts]
          .sort((productA, productB) => {
            const aValue = getValue(productA, parameter)
            const bValue = getValue(productB, parameter)
            return compare(aValue, bValue)
          })
          .map((product, index) => ({
            ...product,
            // set new order after sorting
            order: index
          }))

        setFieldValue(MEDIA_PRODUCTS, sortedProducts)
        handleSaveDataToBE({ updatedProductValues: sortedProducts })
      }

      setTableSort(newSort)
    },
    [handleSaveDataToBE, orderedProducts, setFieldValue, setTableSort]
  )

  return useMemo(
    () => ({
      orderedProducts,
      handleSortChange,
      handleProductsOrderUpdate
    }),
    [handleProductsOrderUpdate, handleSortChange, orderedProducts]
  )
}
