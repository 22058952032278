import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Drawer from '../../../../components/Drawer'
import EditSectionsList from '../../../../features/components/Forms/EditForm/EditSectionsList'
import NameSectionPreview from '../../../ReusableFormSections/NameSection/NameSectionPreview'
import NameSection from './Sections/NameSection'
import BudgetSectionPreview from './Sections/BudgetSection/BudgetSectionPreview'
import BudgetSection from './Sections/BudgetSection'
import SpecialAdCategoriesSectionPreview from './Sections/SpecialAdCategoriesSection/SpecialAdCategoriesSectionPreview'
import SpecialAdCategoriesSectionForm from './Sections/SpecialAdCategoriesSection/SpecialAdCategoriesSectionForm'

import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'
import useManageEditFormData from '../../../../hooks/formHooks/useManageEditFormData'

import { getCampaign, clearCampaign } from '../../../../modules/actions/campaigns'
import {
  campaignLoadingSelector,
  campaignSelector,
  campaignWasLoadedSelector
} from '../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../modules/selectors/app'

import { CAMPAIGN_FACEBOOK_EDIT } from '../../../../constants/forms'
import { NAME, SPECIAL_AD_CATEGORIES, CAMPAIGN_BUDGET } from '../../../ReusableFormFields/CampaignForms/fields'

// here defined initial open states for CampaignFacebookEdit sections
const sectionsInitialOpenStates = {
  [NAME]: false,
  [CAMPAIGN_BUDGET]: false,
  [SPECIAL_AD_CATEGORIES]: false
}

const CampaignFacebookEdit = ({ shouldBeCleared }) => {
  const dispatch = useDispatch()

  const campaign = useSelector(campaignSelector)
  const isCampaignLoading = useSelector(campaignLoadingSelector)
  const campaignWasLoaded = useSelector(campaignWasLoadedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const isInitialDataLoading = isCampaignLoading || !campaignWasLoaded

  const getCampaignDataHandler = useCallback(
    selectedEditItemId => {
      // Load campaign data only if user is on campaigns list page
      // if user is on campaign summary page - we already have campaign data in store
      if (!campaign.id) {
        dispatch(getCampaign({ id: selectedEditItemId, account: selectedAdAccountId }))
      }
    },
    [dispatch, selectedAdAccountId, campaign.id]
  )

  const clearCampaignDataHandler = useCallback(() => {
    if (shouldBeCleared) {
      dispatch(clearCampaign())
    }
  }, [dispatch, shouldBeCleared])

  const { isFormOpen, handleFormClose } = useManageFormsDrawer({
    formName: CAMPAIGN_FACEBOOK_EDIT
  })

  useManageEditFormData({
    formName: CAMPAIGN_FACEBOOK_EDIT,
    loadedDataId: campaign.id,
    getDataHandler: getCampaignDataHandler,
    clearDataHandler: clearCampaignDataHandler
  })

  // here defined all edit sections for CampaignFacebookEdit
  const editSections = useMemo(
    () => [
      {
        sectionName: NAME,
        title: 'Name',
        PreviewComponent: <NameSectionPreview dataSelector={campaignSelector} />,
        FormComponent: <NameSection />
      },
      {
        sectionName: CAMPAIGN_BUDGET,
        title: 'Budget',
        PreviewComponent: <BudgetSectionPreview />,
        // there is case for BudgetSection when edit form should not be represented
        // so instead of rendering form the description is represented
        FormComponent: <BudgetSection />
      },
      {
        sectionName: SPECIAL_AD_CATEGORIES,
        title: 'Special Ad Categories',
        PreviewComponent: <SpecialAdCategoriesSectionPreview />,
        FormComponent: <SpecialAdCategoriesSectionForm />
      }
    ],
    []
  )

  return (
    <Drawer
      title="Update the settings of your campaign"
      subTitle="Edit a Facebook campaign"
      isOpen={isFormOpen}
      onClose={handleFormClose}
    >
      <EditSectionsList
        editSections={editSections}
        isEditDataLoading={isInitialDataLoading}
        editItemData={campaign}
        sectionsInitialOpenStates={sectionsInitialOpenStates}
      />
    </Drawer>
  )
}

export default CampaignFacebookEdit
