import Color from 'color'
import { textGrey } from '../const/colors'
import { isColorDark } from '../helpers'

export const getControllerTheme = controllerColor => {
  const isControllerColorDark = isColorDark(controllerColor)

  return {
    brandPrimary: controllerColor,
    brandPrimaryLight: Color(controllerColor).lightness(80).rgb().string(),
    brandPrimaryHover: Color(controllerColor).darken(0.3).rgb().string(),
    // use white color when brand primary color is dark
    brandPrimaryTextContrastColor: isControllerColorDark ? '#fff' : textGrey,
    selectActiveBorderColor: controllerColor
  }
}
