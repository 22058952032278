import { createStyles } from '../../../../styles/helpers'

import { darkChipGreen, grey, red } from '../../../../styles/const/colors'

const getHeaderColor = color => {
  switch (color) {
    case 'success':
      return darkChipGreen
    default:
      return red
  }
}

const getContainerBorderColor = borderColor => {
  switch (borderColor) {
    case 'success':
      return darkChipGreen
    case 'warning':
      return red
    default:
      return grey
  }
}

export default createStyles(theme => ({
  container: {
    marginTop: 16,
    padding: 10,
    border: ({ borderColor }) => `1px solid ${getContainerBorderColor(borderColor)}`,
    borderRadius: theme.defaultBorderRadius
  },
  icon: {
    fill: ({ color }) => getHeaderColor(color)
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  header: {
    color: ({ color }) => getHeaderColor(color),
    fontSize: 12,
    marginLeft: 9,
    textTransform: 'uppercase'
  },
  warningText: {
    color: grey,
    fontSize: 12,
    marginTop: 9,
    '& a': {
      color: grey
    }
  }
}))
