import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import EditSectionsList from '../../../../../features/components/Forms/EditForm/EditSectionsList'
import NameAndPageSectionPreview from './NameAndPage/NameAndPageSectionPreview'
import NameAndPageForm from './NameAndPage/NameAndPageForm'
import PrimaryTextSectionPreview from './PrimaryText/PrimaryTextSectionPreview'
import PrimaryTextForm from './PrimaryText/PrimaryTextForm'
import MediaSectionPreview from './Media/Preview'
import MediaForm from './Media/Edit'

import { MEDIA, NAME_AND_PAGE, PRIMARY_TEXT } from '../fields'
import { AD_FORMAT } from '../../../../../constants/ads'

// here defined initial open states for CampaignFacebookEdit sections
const sectionsInitialOpenStates = {
  [NAME_AND_PAGE]: false,
  [PRIMARY_TEXT]: false,
  [MEDIA]: false
}

const AdFacebookEditSections = ({
  ad,
  transformedOriginalValues,
  adFormat,
  isDataLoading,
  isInternalAd,
  setSelectedFacebookPage
}) => {
  const mediaSectionTitle = useMemo(() => {
    switch (adFormat) {
      case AD_FORMAT.carousel:
      case AD_FORMAT.carouselCustom:
        return 'Images or Videos'
      case AD_FORMAT.single:
      case AD_FORMAT.singleCustom:
      default:
        return 'Image or Video'
    }
  }, [adFormat])

  // here defined all edit sections for AdFacebookEdit
  const editSections = useMemo(
    () => [
      {
        sectionName: NAME_AND_PAGE,
        title: 'Name and Page',
        PreviewComponent: <NameAndPageSectionPreview adValues={transformedOriginalValues} />,
        FormComponent: (
          <NameAndPageForm
            adValues={transformedOriginalValues}
            isInternalAd={isInternalAd}
            setSelectedFacebookPage={setSelectedFacebookPage}
          />
        )
      },
      {
        sectionName: PRIMARY_TEXT,
        title: 'Primary Text',
        PreviewComponent: <PrimaryTextSectionPreview adValues={transformedOriginalValues} />,
        FormComponent: (
          <PrimaryTextForm adValues={transformedOriginalValues} adFormat={adFormat} isInternalAd={isInternalAd} />
        )
      },
      {
        sectionName: MEDIA,
        title: mediaSectionTitle,
        PreviewComponent: <MediaSectionPreview adValues={transformedOriginalValues} adFormat={adFormat} />,
        FormComponent: (
          <MediaForm adValues={transformedOriginalValues} adFormat={adFormat} isInternalAd={isInternalAd} />
        )
      }
    ],

    [adFormat, mediaSectionTitle, transformedOriginalValues, isInternalAd, setSelectedFacebookPage]
  )

  return (
    <EditSectionsList
      editSections={editSections}
      editItemData={ad}
      isEditDataLoading={isDataLoading}
      sectionsInitialOpenStates={sectionsInitialOpenStates}
    />
  )
}

AdFacebookEditSections.propTypes = {
  ad: PropTypes.object,
  transformedOriginalValues: PropTypes.object,
  adFormat: PropTypes.string,
  isDataLoading: PropTypes.bool,
  isInternalAd: PropTypes.bool,
  setSelectedFacebookPage: PropTypes.func.isRequired
}

export default AdFacebookEditSections
