import update from 'immutability-helper'
import { createReducer } from '@reduxjs/toolkit'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  FILE_UPLOAD_START,
  FILE_UPLOAD_START_SUCCESS,
  FILE_UPLOAD_START_FAILURE,
  FILE_UPLOAD_TO_SERVER_SUCCESS,
  FILE_UPLOAD_TO_SERVER_FAILURE,
  FILE_UPLOAD_TO_PLATFORM_SUCCESS,
  FILE_UPLOAD_TO_PLATFORM_FAILURE,
  UPDATE_FILE_UPLOAD_PROGRESS,
  CLEAR_UPLOADED_FILE,
  GET_UPLOADED_VIDEO_DETAILS_SUCCESS,
  GET_UPLOADED_VIDEO_DETAILS_FAILURE,
  GET_FILE_ACCESSIBLE_URL,
  GET_FILE_ACCESSIBLE_URL_SUCCESS,
  GET_FILE_ACCESSIBLE_URL_FAILURE,
  CLEAR_FILE_ACCESSIBLE_URLS
} from '../actions/files'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  fileUploadProgress: {},
  uploadedFile: {},
  fileUploadError: false,
  accessibleURL: {}
}

export default createReducer(initialState, builder => {
  // export default function files(state = initialState, action) {
  builder
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    .addCase(FILE_UPLOAD_START, (state, action) => {
      return update(state, {
        fileUploadProgress: { $merge: { [action.file.name]: 1 } }
      })
    })
    .addCase(FILE_UPLOAD_START_SUCCESS, (state, action) => {
      return update(state, {
        fileUploadProgress: { $merge: { [action.signedFileData.file.name]: 5 } }
      })
    })
    .addCase(FILE_UPLOAD_START_FAILURE, state => {
      return update(state, {
        fileUploadError: { $set: true }
      })
    })
    .addCase(FILE_UPLOAD_TO_SERVER_FAILURE, state => {
      return update(state, {
        fileUploadError: { $set: true }
      })
    })
    .addCase(FILE_UPLOAD_TO_PLATFORM_FAILURE, state => {
      return update(state, {
        fileUploadError: { $set: true }
      })
    })
    .addCase(GET_UPLOADED_VIDEO_DETAILS_FAILURE, state => {
      return update(state, {
        fileUploadError: { $set: true }
      })
    })
    .addCase(UPDATE_FILE_UPLOAD_PROGRESS, (state, action) => {
      return update(state, {
        // send action.uploadProgress in such structure - { fileName: progressValue }
        fileUploadProgress: { $merge: action.uploadProgress }
      })
    })
    .addCase(FILE_UPLOAD_TO_SERVER_SUCCESS, (state, action) => {
      return update(state, {
        uploadedFile: { $merge: { [action.fileName]: { url: action.fileUrl, name: action.fileName } } },
        // when file is not uploading to platform it uploading is finished => set progress to 100
        ...(!action.isPlatformRelated && { fileUploadProgress: { $merge: { [action.fileName]: 100 } } })
      })
    })
    .addCase(FILE_UPLOAD_TO_PLATFORM_SUCCESS, (state, action) => {
      return update(state, {
        uploadedFile: { $merge: { [action.fileName]: action.fileData } },
        fileUploadProgress: { $merge: { [action.fileName]: 100 } }
      })
    })
    .addCase(GET_UPLOADED_VIDEO_DETAILS_SUCCESS, (state, action) => {
      return update(state, {
        uploadedFile: { $merge: { [action.fileName]: action.fileData } }
      })
    })
    .addCase(CLEAR_UPLOADED_FILE, (state, action) => {
      return update(state, {
        uploadedFile: { $merge: { [action.fileName]: null } },
        fileUploadError: { $set: initialState.fileUploadError }
      })
    })
    .addCase(GET_FILE_ACCESSIBLE_URL, (state, action) => {
      return update(state, {
        accessibleURL: {
          $merge: {
            [action.url]: {
              data: null,
              isLoading: true
            }
          }
        }
      })
    })
    .addCase(GET_FILE_ACCESSIBLE_URL_SUCCESS, (state, action) => {
      return update(state, {
        accessibleURL: {
          $merge: {
            [action.originalUrl]: {
              data: action.accessibleUrl,
              isLoading: false
            }
          }
        }
      })
    })
    .addCase(GET_FILE_ACCESSIBLE_URL_FAILURE, (state, action) => {
      return update(state, {
        accessibleURL: {
          $merge: {
            [action.url]: {
              error: action.error,
              isLoading: false
            }
          }
        }
      })
    })
    .addCase(CLEAR_FILE_ACCESSIBLE_URLS, (state, action) => {
      return update(state, {
        accessibleURL: { $set: initialState.accessibleURL }
      })
    })
    .addDefaultCase(state => state)
})
