import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Actions from './Actions'
import AccountSelection from './AccountSelection'
import Field from '../../../../../components/Form/Field'

import { useRequiredFields } from '../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/hooks/useRequiredFields'
import { MediaOrderFormContext } from '../../MediaOrderFormContext'

import { formatOptionsList } from '../../../../../features/formatters'

import { BRAND, CAMPAIGN_NAME } from '../fields'

import useStyles from './styles'

function AdditionalMediaOrderInfo({ formik, isEditContract, isEditMode, setIsEditMode, hasTopMargin, allowAutoSave }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { contextSelfAccountData } = useContext(MediaOrderFormContext)

  const brands = contextSelfAccountData?.brands

  const brandsOptions = useMemo(() => {
    return formatOptionsList({
      list: brands,
      valueName: 'id',
      labelName: 'brand_name'
    })
  }, [brands])

  const { setFieldValue } = formik

  const { askBrandName, askCampaignName, preselectFirstBrand, isBrandRequired } =
    useRequiredFields(contextSelfAccountData)
  const showBrandName = askBrandName && isBrandRequired
  const showAdditionalFields = showBrandName || askCampaignName

  const getBrandLabel = useCallback(
    selectedBrand => {
      return brandsOptions.find(brand => brand.value === selectedBrand)?.label || ''
    },
    [brandsOptions]
  )

  useEffect(() => {
    if (askBrandName && preselectFirstBrand) {
      // if there is only one brand, dont ask the question and just add it automatically
      setFieldValue(BRAND, brandsOptions[0].value)
    }
  }, [setFieldValue, askBrandName, preselectFirstBrand, brandsOptions])

  return (
    <div className={classnames(classes.additionalFieldsContainer, { [classes.topMargin]: hasTopMargin })}>
      <div className={classes.fieldWrapper}>
        <h4>{t('Account')}:</h4>
        {isEditMode && !isEditContract ? (
          <AccountSelection setFieldValue={setFieldValue} />
        ) : (
          contextSelfAccountData?.name
        )}
      </div>
      {showAdditionalFields && (
        <>
          {askBrandName && isBrandRequired && (
            <div className={classes.fieldWrapper}>
              <h4>{t('Brand')}:</h4>
              {isEditMode ? (
                <Field
                  formik={formik}
                  name={BRAND}
                  options={brandsOptions}
                  placeholder="Brand Name"
                  className={classes.brandSelect}
                  errorClassName={classes.errorClassName}
                />
              ) : (
                <p>{getBrandLabel(formik.values[BRAND])}</p>
              )}
            </div>
          )}
          {askCampaignName && (
            <div className={classes.fieldWrapper}>
              <h4>{t('Campaign name')}:</h4>
              {isEditMode ? (
                <Field
                  formik={formik}
                  errorClassName={classes.errorClassName}
                  name={CAMPAIGN_NAME}
                  placeholder="Campaign name"
                />
              ) : (
                <p>{formik.values[CAMPAIGN_NAME]}</p>
              )}
            </div>
          )}
        </>
      )}
      <Actions
        formik={formik}
        isEditMode={isEditMode}
        allowAutoSave={allowAutoSave}
        setIsEditMode={setIsEditMode}
        defaultValues={{
          [BRAND]: formik.values[BRAND],
          [CAMPAIGN_NAME]: formik.values[CAMPAIGN_NAME]
        }}
      />
      {/*this is used for portals don't remove*/}
      <div className={classes.portal} id="mediaOrderInfoFieldsPortal"></div>
    </div>
  )
}

AdditionalMediaOrderInfo.propTypes = {
  formik: PropTypes.object,
  isEditMode: PropTypes.bool,
  setIsEditMode: PropTypes.func,
  isEditContract: PropTypes.bool,
  allowEdit: PropTypes.bool,
  allowAutoSave: PropTypes.bool,
  hasTopMargin: PropTypes.bool
}

export default AdditionalMediaOrderInfo
