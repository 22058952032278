import React from 'react'
import { useSelector } from 'react-redux'

import Breadcrumbs from '../../../../features/components/Breadcrumbs'

import { ReactComponent as AddFileIcon } from '../../../../assets/icons/addFile.svg'

import { selectedControllerDataSelector } from '../../../../modules/selectors/app'

import { ROUTES } from '../../../../constants/routes'

const ProposalBuilderPageBreadcrumbs = () => {
  const selectedController = useSelector(selectedControllerDataSelector)

  const breadcrumbs = [
    {
      title: selectedController?.name,
      url: ''
    },
    {
      title: 'Proposals List',
      url: ROUTES.proposals
    },
    {
      title: 'Proposal',
      Icon: AddFileIcon,
      url: ''
    }
  ]

  return <Breadcrumbs routes={breadcrumbs} />
}

export default ProposalBuilderPageBreadcrumbs
