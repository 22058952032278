import { useCallback, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MediaOrderFormContext } from '../../../../forms/Multiplatform/MediaOrderForms/MediaOrderFormContext'

import { transformValuesToBE } from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/formatters'
import {
  clearCreateContract,
  createContract,
  updateContract,
  clearUpdateContract
} from '../../../../modules/actions/contracts'

import {
  activeContractSelector,
  contractWasCreatedSelector,
  createContractsErrorSelector,
  createContractsIsLoadingSelector,
  contractWasUpdatedSelector,
  updateContractIsLoadingSelector,
  updateContractErrorSelector
} from '../../../../modules/selectors/contracts'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'

export function useHandleSubmitForm() {
  const dispatch = useDispatch()

  const activeContract = useSelector(activeContractSelector)
  const createdContractId = activeContract?.id

  // if contract was created during creation or loaded for editing the quotation, we are in edit mode
  const [isEditMode, setEditMode] = useState(Boolean(createdContractId))
  const controllerId = useSelector(selectedControllerIdSelector)
  const contractWasCreated = useSelector(contractWasCreatedSelector)
  const contractWasUpdated = useSelector(contractWasUpdatedSelector)
  const { contextSelfAccountData, currency } = useContext(MediaOrderFormContext)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE({
        selfAccountData: contextSelfAccountData,
        controllerId,
        selectedCurrency: currency,
        values
      })

      if (createdContractId) {
        dispatch(
          updateContract(
            createdContractId,
            {
              quotation: true, // BE need to get it passed for correct work
              without_requirements: true, // allows to create contract without terms
              set_amendment_cost: true, // allows to set modified pricing
              detail: transformedData
            },
            'PATCH'
          )
        )
      } else {
        dispatch(
          createContract({
            without_requirements: true, // allows to create contract without terms
            set_amendment_cost: true, // allows to set modified pricing
            account: contextSelfAccountData.id,
            controller: controllerId,
            quotation: true,
            detail: transformedData
          })
        )
      }
    },
    [createdContractId, contextSelfAccountData, controllerId, currency, dispatch]
  )

  const clearCreateHandler = useCallback(() => dispatch(clearCreateContract()), [dispatch])
  const clearUpdateHandler = useCallback(() => dispatch(clearUpdateContract()), [dispatch])

  return {
    onSubmit,
    setEditMode,
    clearSubmitHandler: isEditMode ? clearUpdateHandler : clearCreateHandler,
    successSubmit: isEditMode ? contractWasUpdated : contractWasCreated,
    errorSelector: isEditMode ? updateContractErrorSelector : createContractsErrorSelector,
    isLoadingSelector: isEditMode ? updateContractIsLoadingSelector : createContractsIsLoadingSelector
  }
}
