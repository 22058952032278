import React from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Page from '../../index'
import Chip from '../../../components/Chip'
import SocialAuthorizeLogos from '../SocialAuthorizeLogos'

import { selectedPlatformSelector } from '../../../modules/selectors/app'

import platformList from '../../../constants/selectLists/platformList'
import { CHIP_COLORS } from '../../../constants/other'
import { ROUTE_PARAMS } from '../../../constants/routes'

import useStyles from './style'
import useCommonStyles from '../style'

function AdAccountAccessMissing() {
  const { t } = useTranslation()
  const commonClasses = useCommonStyles()
  const classes = useStyles()

  const selectedPlatform = useSelector(selectedPlatformSelector)
  const { [ROUTE_PARAMS.adAccount]: pathAdAccountId } = useParams()

  const platform = platformList.find(({ value }) => value === selectedPlatform)

  return (
    <Page helmetTitle="helmetTitle.AdAccountAccessMissing" className={commonClasses.socialAuthPage}>
      <SocialAuthorizeLogos />
      <h2 className={commonClasses.title}>{t('Ad account access missing')}</h2>
      <div className="info">
        {t('The ad account with id')}{' '}
        <Chip wrapperClassName={classes.accountId} text={pathAdAccountId} color={CHIP_COLORS.yellow} />{' '}
        {t('adAccountAccessMissing', { platformLabel: platform.label })}
      </div>
    </Page>
  )
}

export default AdAccountAccessMissing
