import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { updateAd } from '../../../../modules/actions/ads'
import { VIDEO } from '../fields'
import { TIKTOK_PLATFORM } from '../../../../constants/selectLists/platformList'

export default function useUpdateTikTokAdHandler({ adData }) {
  const dispatch = useDispatch()

  const handleSubmit = useCallback(
    updatedValues => {
      const updateAdData = {
        // TikTok endpoint accepts only the PUT method, so we need to send all original data
        ...adData,
        // during update only the id of the video is sent, not the full video details object
        [VIDEO]: adData[VIDEO].id,
        ...updatedValues
      }
      dispatch(updateAd(updateAdData, adData.id, 'PUT', TIKTOK_PLATFORM))
    },
    [dispatch, adData]
  )

  return useMemo(() => handleSubmit, [handleSubmit])
}
