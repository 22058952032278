import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../components/Button'
import AgeRangesFields from '../../../../../ReusableFormFields/AgeRangesFields'

import { CUSTOM_AGE_RANGES } from '../../../../../ReusableFormFields/AgeRangesFields/fields'

import useStyles from '../../../../../../styles/common/stepForms'

const stepFields = [CUSTOM_AGE_RANGES]

const AgesStep = ({ formik, handleStepChange }) => {
  const { t } = useTranslation()

  const classes = useStyles()

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create a TikTok ad group')}</div>
      <div className={classes.stepTitle}>{t('What ages would you like to target?')}</div>
      <div className={classes.stepBody}>
        <AgeRangesFields formik={formik} />
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" className="dark" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default AgesStep
