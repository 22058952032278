import { useState, useEffect, useMemo, useCallback } from 'react'
import { setProperty } from 'dot-prop'

const initialErrors = {}
// this hooks it used to handle the validation, and touch within one Step
// this could be helpful for nested values which shared through the multiple steps
const useStepValidation = ({
  isActive, // isStepActive to optimize performance and avoid unnecessary validation
  stepValidation, // Yup validation schema
  stepValues, // values based on which the validation will be done
  handleStepChange
}) => {
  const [errors, setErrors] = useState(initialErrors)
  // step treats touched when user pressed continue button on Step
  const [isStepTouched, setIsStepTouched] = useState(false)

  useEffect(() => {
    if (isActive) {
      stepValidation
        .validate(stepValues, { abortEarly: false }) // disable abortEarly to get all errors
        .then(() => setErrors(initialErrors))
        .catch(error => {
          const formattedErrors = {}
          error.inner.forEach(err => {
            const { path, message } = err
            setProperty(formattedErrors, path, message)
          })
          setErrors(formattedErrors)
        })
    }
  }, [isActive, stepValidation, stepValues])

  const handleContinue = useCallback(() => {
    const hasErrors = !!Object.keys(errors).length
    setIsStepTouched(true)

    if (!hasErrors) {
      handleStepChange()
    }
  }, [setIsStepTouched, errors, handleStepChange])

  return useMemo(
    () => ({
      errors,
      isStepTouched,
      handleContinue
    }),
    [errors, isStepTouched, handleContinue]
  )
}

export default useStepValidation
