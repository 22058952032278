import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import MembersTableData from './MembersTableData'
import TableDataLoader from '../../../../components/Table/TableDataLoader'
import { useMediaQuery } from 'react-responsive'

import {
  invitesIsLoadingSelector,
  invitesSelector,
  invitesWasLoadedSelector,
  membersSelector,
  membersErrorSelector,
  membersWasLoadedSelector,
  membersIsLoadingSelector
} from '../../../../modules/selectors/selfAccountTeam'

import { mobileOneLineColumnsSchema } from '../../../../constants/other'

import { phonesDownSize } from '../../../../styles/const/breakpoints'

export const tableColumnsSize = {
  status: 140,
  role: 74,
  actions: 40
}

const MembersTable = ({ onChangeMemberStatus, onChangeMemberRole, onResendInvite, onDeleteInvite }) => {
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const membersLists = useSelector(membersSelector)
  const invitesLists = useSelector(invitesSelector)
  const invitesIsLoading = useSelector(invitesIsLoadingSelector)
  const invitesWasLoaded = useSelector(invitesWasLoadedSelector)

  const membersColumnsSchema = useMemo(
    () => [
      {
        header: 'Name'
      },
      {
        header: 'Status',
        style: { maxWidth: tableColumnsSize.status }
      },
      {
        header: 'Role',
        style: { maxWidth: tableColumnsSize.role }
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const membersAndInvites = useMemo(() => {
    return [...membersLists, ...invitesLists]
  }, [membersLists, invitesLists])
  const additionalDataIsLoading = invitesIsLoading || !invitesWasLoaded

  return (
    <TableDataLoader
      itemsLength={membersAndInvites.length}
      errorSelector={membersErrorSelector}
      wasLoadedSelector={membersWasLoadedSelector}
      isLoadingSelector={membersIsLoadingSelector}
      skeletonProps={{ cols: isMobile ? mobileOneLineColumnsSchema : membersColumnsSchema }}
      noDataContent={<div />}
      additionalDataIsLoading={additionalDataIsLoading}
    >
      <MembersTableData
        membersAndInvites={membersAndInvites}
        onChangeMemberStatus={onChangeMemberStatus}
        onChangeMemberRole={onChangeMemberRole}
        onResendInvite={onResendInvite}
        onDeleteInvite={onDeleteInvite}
        membersLists={membersLists}
      />
    </TableDataLoader>
  )
}

MembersTable.propTypes = {
  onChangeMemberStatus: PropTypes.func.isRequired,
  onChangeMemberRole: PropTypes.func.isRequired,
  onResendInvite: PropTypes.func.isRequired,
  onDeleteInvite: PropTypes.func.isRequired
}

export default MembersTable
