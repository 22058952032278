import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'

import Form from '../../../../../components/Form'
import CampaignFacebookCreateFormContent from './CampaignFacebookCreateFormContent'

import { clearCreateCampaign, createCampaign } from '../../../../../modules/actions/campaigns'
import {
  campaignCreateErrorSelector,
  campaignCreateIsLoadingSelector,
  campaignCreateWasCreatedSelector,
  createdCampaignIdSelector
} from '../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'

import { CAMPAIGN_FACEBOOK_CREATE } from '../../../../../constants/forms'
import { FACEBOOK_PLATFORM } from '../../../../../constants/selectLists/platformList'
import { ROUTE_PARAMS } from '../../../../../constants/routes'
import { initialValues, transformValuesToBE, validationSchema } from './fields'

const CampaignFacebookCreateForm = ({ onSuccessSubmit }) => {
  const dispatch = useDispatch()

  const createdCampaignId = useSelector(createdCampaignIdSelector)
  const campaignWasCreated = useSelector(campaignCreateWasCreatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const onSubmit = useCallback(
    values => {
      const transformedValues = transformValuesToBE(values, selectedAdAccountId)

      const data = {
        campaignData: transformedValues
      }

      dispatch(createCampaign(data, FACEBOOK_PLATFORM, { pushToCombinedCampaignsList: true }))
    },
    [dispatch, selectedAdAccountId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const handleSuccessSubmit = useCallback(() => {
    onSuccessSubmit({
      [ROUTE_PARAMS.campaignId]: createdCampaignId,
      [ROUTE_PARAMS.platform]: FACEBOOK_PLATFORM,
      [ROUTE_PARAMS.adAccount]: selectedAdAccountId
    })
  }, [onSuccessSubmit, selectedAdAccountId, createdCampaignId])

  const clearCreateCampaignHandler = useCallback(() => {
    dispatch(clearCreateCampaign())
  }, [dispatch])

  return (
    <Form
      formik={purifiedFormik}
      formName={CAMPAIGN_FACEBOOK_CREATE}
      clearHandler={clearCreateCampaignHandler}
      successSubmit={campaignWasCreated}
      onSuccessSubmit={handleSuccessSubmit}
      errorSelector={campaignCreateErrorSelector}
      isLoadingSelector={campaignCreateIsLoadingSelector}
    >
      <CampaignFacebookCreateFormContent formik={purifiedFormik} />
    </Form>
  )
}

export default CampaignFacebookCreateForm
