import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import GoogleAssetsSliderPreview from '../../../../../forms/Google/AdForms/GoogleAssetsSliderPreview'
import AdCardActions from '../../../../MediaOrderSummary/MediaOrderSummaryContent/MediaOrderCampaignsReports/HighestPerformingAds/GooglePerformingAds/GoogleAdCard/AdCardActions'
import AdCardDetails from '../../../../../features/components/AdCardListItem/AdCardDetails'
import AdCardMetrics from '../../../../MediaOrderSummary/MediaOrderSummaryContent/MediaOrderCampaignsReports/HighestPerformingAds/GooglePerformingAds/GoogleAdCard/AdCardMetrics'

import { getAssetGroupTransformedValues } from '../../../../../forms/Google/AdForms/GoogleAssetGroup/AssetGroupGoogleEdit/helpers'

import { currentUserSelfAccountsListSelector } from '../../../../../modules/selectors/app'

import useStyles from '../../../../../styles/common/adPreviewCard'

// this component currently support only ad approvals page style
const GoogleAssetGroupPreviewCard = ({ assetGroup, updateAdStatusHandler, getAdActionsDropdownOptions }) => {
  const classes = useStyles()

  const { account_id: selfAccountId, name: assetGroupName } = assetGroup

  const selfAccounts = useSelector(currentUserSelfAccountsListSelector)
  const adSelfAccountName = selfAccounts.find(({ id }) => Number(id) === Number(selfAccountId))?.name || ''

  const transformedAssetGroupValues = useMemo(() => getAssetGroupTransformedValues(assetGroup), [assetGroup])

  return (
    <div className={classes.adPreviewCard}>
      <AdCardActions
        ad={assetGroup}
        updateAdStatusHandler={updateAdStatusHandler}
        getAdActionsDropdownOptions={getAdActionsDropdownOptions}
      />
      <div className={classes.cardSliderPreviewWrapper}>
        <GoogleAssetsSliderPreview values={transformedAssetGroupValues} />
      </div>
      <AdCardDetails title={assetGroupName} subTitle={adSelfAccountName} />
      <AdCardMetrics ad={assetGroup} />
    </div>
  )
}

export default GoogleAssetGroupPreviewCard
