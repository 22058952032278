import React from 'react'
import PropTypes from 'prop-types'

import GoogleMediaFilesPreview from '../../../../GoogleMediaFilesPreview'

import { getAdLandscapeImageAssets } from '../../helpers'

const LandscapeImageSectionPreview = ({ ad }) => {
  const landscapeImageAssets = getAdLandscapeImageAssets(ad)

  return <GoogleMediaFilesPreview assets={landscapeImageAssets} assetDataName="asset" />
}

LandscapeImageSectionPreview.propTypes = {
  ad: PropTypes.object
}

export default LandscapeImageSectionPreview
