import { useCallback, useState, useEffect, useMemo } from 'react'

const useModalManage = ({ onClose, triggerClose }) => {
  const [isModalOpened, setIsModalOpened] = useState(false)
  // any data you want to keep when open modal
  const [modalData, setModalData] = useState({})

  const closeModalHandler = useCallback(() => {
    setIsModalOpened(false)
    setModalData({})
    onClose && onClose()
  }, [onClose])

  const openModalHandler = useCallback(data => {
    setIsModalOpened(true)

    data && setModalData(data)
  }, [])

  useEffect(() => {
    if (triggerClose) {
      closeModalHandler()
    }
    // eslint-disable-next-line
  }, [triggerClose])

  return useMemo(
    () => ({
      isModalOpened,
      modalData,
      openModalHandler,
      closeModalHandler
    }),
    [isModalOpened, modalData, openModalHandler, closeModalHandler]
  )
}

export default useModalManage
