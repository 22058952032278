import { v4 as uuidv4 } from 'uuid'
import { addYears } from 'date-fns'

import {
  ACCESS_ACCOUNTS,
  AVAILABILITY_DATE_END,
  AVAILABILITY_DATE_START,
  CATEGORY,
  DATE_END,
  DATE_START,
  MEDIA_CATEGORIES,
  MEDIA_PRODUCTS,
  NAME,
  SUBTEXT,
  TAGS
} from '../../fields'
import {
  PRICE_CURRENCY,
  PRICE_PAIRS,
  pricePairInitialValue
} from '../../../../ReusableFormFields/PricePairFields/fields'

import { REPRESENTATIVE } from '../../../../../constants/mediaOrders'
import { initialImageFileValues } from '../../../../../constants/files'

export const FIXED_DELIVERY_DATES_CHECKBOX = 'fixed_delivery_dates_checkbox'

export const MEDIA_ITEMS_OPTION = 'media_items_option'

export const QUANTITY_OPTION = 'quantity_option'
export const USER_CAN_SELECT_PRODUCTS_FROM_LIST = 'can_select_products'

export const STATUS_ACTIVE = 'active'
export const STATUS_PAUSED = 'paused'
export const PACKAGE_PENDING_APPROVAL = 'pending_approval'

export const PRODUCTS_FILTER = 'products_filter'

export const getInitialValues = ({ controllerCurrency }) => ({
  [NAME]: '',
  // category
  [CATEGORY]: '',
  // availability dates
  [AVAILABILITY_DATE_START]: new Date(),
  [AVAILABILITY_DATE_END]: addYears(new Date(), 10),
  // dates
  [FIXED_DELIVERY_DATES_CHECKBOX]: false,
  [DATE_START]: '',
  [DATE_END]: '',
  // subtext
  [SUBTEXT]: '',
  // image
  ...initialImageFileValues,
  // media items
  [MEDIA_ITEMS_OPTION]: MEDIA_CATEGORIES,
  [MEDIA_CATEGORIES]: [],
  [MEDIA_PRODUCTS]: [],
  // price
  [PRICE_PAIRS]: [
    {
      ...pricePairInitialValue,
      ...(controllerCurrency && {
        [PRICE_CURRENCY]: controllerCurrency
      }),
      id: uuidv4()
    }
  ],
  [USER_CAN_SELECT_PRODUCTS_FROM_LIST]: false,
  [TAGS]: [],
  [ACCESS_ACCOUNTS]: [],
  [REPRESENTATIVE]: ''
})
