import React from 'react'
import PropTypes from 'prop-types'

import { getPortraitImageData } from '../HeadlinesSection/helpers'

import FileDetailsPreview from '../../../../../../../components/FileDetailsPreview'

const PortraitImageSectionPreview = ({ data }) => {
  const { assets } = data
  const portraitImageData = getPortraitImageData(assets)

  if (!portraitImageData || !portraitImageData.length) {
    return null
  }

  return (
    <div>
      {portraitImageData.map(({ asset_data: assetData }) => {
        const fileUrl = assetData?.image_asset?.full_size?.url
        const fileName = assetData?.name

        return (
          <FileDetailsPreview
            key={fileUrl}
            fileName={fileName}
            previewUrl={fileUrl}
            filePreviewType={'image'}
            isFileLoading={false}
            allowDelete={false}
          />
        )
      })}
    </div>
  )
}

PortraitImageSectionPreview.propTypes = {
  data: PropTypes.object
}

export default PortraitImageSectionPreview
