import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import PageSection from '../../PageSection'
import LineGraph from '../../Graphs/LineGraph'

import { formatNumber } from '../../../../helpers/numbers'

import useStyles from '../styles'

const RoasGraphSection = ({ rawGraphData }) => {
  const classes = useStyles()

  const roasGraphData = useMemo(
    () => rawGraphData && rawGraphData.data && rawGraphData.data.map(data => data.value),
    [rawGraphData]
  )
  const roasGraphLabels = useMemo(
    () => rawGraphData && rawGraphData.data && rawGraphData.data.map(data => data.segment),
    [rawGraphData]
  )

  return (
    <PageSection className={classes.sectionContainer}>
      <div className={classes.headingContainer}>
        <div className={classes.title}>ROAS</div>
        {/*<div className={classes.value}>{formatNumber(rawGraphData.total, { min: 0, max: 0 })}</div>*/}
        {/*// todo SHOP-1580 ROAS total is hardcoded for now, fix it later*/}
        <div className={classes.value}>{formatNumber(380.78, { min: 0, max: 0 })}</div>
      </div>
      <LineGraph className={classes.graphWrapper} data={roasGraphData} labels={roasGraphLabels} format="integer" />
    </PageSection>
  )
}

RoasGraphSection.propTypes = {
  rawGraphData: PropTypes.object.isRequired
}

export default RoasGraphSection
