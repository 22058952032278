import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Field from '../../../../../../../components/Form/Field'

import { selectedAdAccountDataSelector } from '../../../../../../../modules/selectors/app'

import { TARGET_COST_CAP, ROAS_AVERAGE_FLOOR } from '../../../../../../ReusableFormFields/LineItemForms/fields'
import {
  campaignOptimizeObjectiveLabels,
  COST_CAP,
  MANUAL,
  MINIMUM_ROAS
} from '../../../../../../../constants/campaigns'

import useStyles from '../../../../../../../styles/common/drawerForms'

const TargetCostCapContent = ({ formik, campaignBiddingType, campaignObjective }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const { currency_symbol } = useSelector(selectedAdAccountDataSelector)

  const optimizeObjectiveLabel = campaignOptimizeObjectiveLabels[campaignObjective]

  if (campaignBiddingType === MANUAL) {
    return (
      <div className={classes.sectionItem}>
        <div className={classes.sectionSubtitle}>{t('Average Cost Goal')}</div>
        <p>
          {t(
            `Set a maximum cost per ${optimizeObjectiveLabel}. The average cost per conversion will be less than this.`
          )}
        </p>
        <p>{t('If this is set too low, your campaign delivery will be limited.')}</p>
        <Field
          type="number"
          formik={formik}
          name={TARGET_COST_CAP}
          placeholder="Average Cost Goal"
          symbol={currency_symbol}
          autoComplete="off"
        />
      </div>
    )
  }

  if (campaignBiddingType === COST_CAP) {
    return (
      <div className={classes.sectionItem}>
        <div className={classes.sectionSubtitle}>{t('Bid Cap')}</div>
        <p>
          {t(
            `Set an average cost per ${optimizeObjectiveLabel} goal. Some conversions will cost less, some will cost more, but the average will be approximately this goal.`
          )}
        </p>
        <p>{t('If this is set too low, your campaign delivery will be limited.')}</p>
        <Field
          type="number"
          formik={formik}
          name={TARGET_COST_CAP}
          placeholder="Bid Cap"
          symbol={currency_symbol}
          autoComplete="off"
        />
      </div>
    )
  }

  if (campaignBiddingType === MINIMUM_ROAS) {
    return (
      <div className={classes.sectionItem}>
        <div className={classes.sectionSubtitle}>{t('Minimum ROAS')}</div>
        <p>{t('Set a minimum ROAS for each ad set (conversion value / spend).')}</p>
        <p>
          {t(
            `For example, if for every ${currency_symbol}1 you spend on ads, you need at least ${currency_symbol}2.50 in revenue then your minimum ROAS is 2.5`
          )}
        </p>
        <Field type="number" formik={formik} name={ROAS_AVERAGE_FLOOR} placeholder="Minimum Roas" autoComplete="off" />
      </div>
    )
  }

  return null
}

export default TargetCostCapContent
