export default function getConversionDomainValidation(domain, validatedDomains) {
  const currentDomainValidation = validatedDomains?.[domain]

  const isDomainValidating = currentDomainValidation?.isLoading
  const wasDomainValidated = currentDomainValidation?.wasValidated

  // We can say that domain is valid only when there is no error at all,
  // If there is an error not related to domain, user can make change on the form,
  // and then we validate the ad again
  const isDomainValid = Boolean(currentDomainValidation?.isValid)

  return { isDomainValidating, wasDomainValidated, isDomainValid }
}
