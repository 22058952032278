import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import Table from '../../../../components/Table'
import Toggle from '../../../../components/Toggle'
import Chip from '../../../../components/Chip'
import TableValueMetric from '../../TableValueMetric'
import NameCol from './Cols/NameCol'
import PlatformIcon from '../../../../components/Table/ReusableCells/PlatformIcon'
import ActionsDropdown from '../../ActionsDropdown'
import ActionsButtonsList from '../../ActionsButtonsList'
import MobileHeaderWithTextInBrackets from './MobileHeaderWithTextInBrackets'

import { getFormattedListItemObjective } from '../../../helpers/componentsHelpers'
import { formatDigitalItemValues } from '../../../formatters'
import { insertIf } from '../../../../helpers/common'

import {
  activeViewImpressionsColumnSizes,
  clicksColumnSizes,
  cpcColumnSizes,
  cpmColumnSizes,
  dropdownColumnSizes,
  engagementsColumnSizes,
  impressionsColumnSizes,
  nameColumnSizes,
  nameColumnSizesMobile,
  objectiveColumnSizes,
  platformColumnSizes,
  platformColumnSizesMobile,
  purchaseColumnSizes,
  reachColumnSizes,
  spendColumnSizes,
  statusColumnSizes,
  statusColumnSizesMobile,
  videoViewsColumnSizes
} from '../columnSizes'
import {
  DV_360_PLATFORM,
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  PLATFORM_LABELS,
  PROVIDER_TO_PLATFORMS,
  TIKTOK_PLATFORM
} from '../../../../constants/selectLists/platformList'
import { CHIP_COLORS } from '../../../../constants/other'
import { phonesDownSize } from '../../../../styles/const/breakpoints'

import useStyles from './styles'

// the table can be used for rendering both mixed platforms (media order summary) and single platform data
// when render for single platform, pass platform prop and some metrics will be rendered dynamically
// when render for mixed platforms, don't pass platform prop, and you will get basic list of metrics
const DigitalTableData = React.memo(
  ({
    platform,
    data,
    totalsItem,
    isLoadingSelector,
    itemUpdatingId,
    allowStatusChange = true,
    onStatusChange,
    currencySymbol,
    showGoalColumn = true,
    getItemLinkClickHandler,
    getActionsDropdownOptions
  }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const dataIsLoading = useSelector(isLoadingSelector)

    const formattedData = useMemo(
      () =>
        data.map(item => ({
          ...item,
          ...formatDigitalItemValues(item, { symbol: item['currency_symbol'] || currencySymbol })
        })),
      [data, currencySymbol]
    )

    const formattedTotalsItem = useMemo(
      () => totalsItem && formatDigitalItemValues(totalsItem, { symbol: currencySymbol }),
      [totalsItem, currencySymbol]
    )

    const hideTotalsItem = useMemo(
      () => !totalsItem || data.length < 1 || dataIsLoading,
      [data.length, dataIsLoading, totalsItem]
    )

    // limit name column width on mixed table, so it doesn't overflow on laptop size
    const shouldLimitNameColumn = !platform

    const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

    const isFacebookPlatform = platform === FACEBOOK_PLATFORM
    const isGooglePlatform = platform === GOOGLE_PLATFORM
    const isDV360Platform = platform === DV_360_PLATFORM
    const isTikTokPlatform = platform === TIKTOK_PLATFORM

    const isItemWithVideoViewsExist = !!data.find(item => item['video_views'])

    const showReachColumn = !platform || isFacebookPlatform || isDV360Platform || isTikTokPlatform
    const showEngagementsMetric = isFacebookPlatform
    const showActiveViewImpressionsMetric = isGooglePlatform
    const showVideoViewsMetric = (isGooglePlatform || isDV360Platform) && isItemWithVideoViewsExist

    const columns = useMemo(
      () => [
        ...insertIf(allowStatusChange, {
          header: 'Active',
          Cell: item => <Toggle onToggle={() => onStatusChange(item)} isInitiallyEnabled={item.status === 'active'} />,
          style: isMobile ? { ...statusColumnSizesMobile, padding: 0 } : { ...statusColumnSizes }
        }),
        ...insertIf(!isMobile, {
          Cell: ({ provider }) => <PlatformIcon platform={platform} provider={provider} />,
          style: isMobile ? { ...platformColumnSizesMobile } : { ...platformColumnSizes }
        }),
        {
          header: 'Name',
          // make name not clickable on mobile
          Cell: item => (
            <NameCol
              itemData={item}
              platform={platform}
              getItemLinkClickHandler={isMobile ? undefined : getItemLinkClickHandler}
            />
          ),
          footer: () => <h3 className={classes.totalsTitle}>Totals</h3>,
          style: isMobile
            ? { ...nameColumnSizesMobile }
            : { ...nameColumnSizes, ...(shouldLimitNameColumn && { maxWidth: 385 }) }
        },
        ...insertIf(showGoalColumn, {
          header: 'Goal',
          Cell: ({ objective, provider }) => {
            return objective ? (
              <Chip
                text={getFormattedListItemObjective(PROVIDER_TO_PLATFORMS[provider], objective)}
                className={isMobile ? classes.mobileGoalClassName : undefined}
                color={CHIP_COLORS.blue}
              />
            ) : null
          },
          style: { ...objectiveColumnSizes },
          showOnMobile: false
        }),
        {
          header: 'Impressions',
          Cell: ({ impressions }) => <TableValueMetric value={impressions} />,
          headClassName: classes.metricHeaderColumn,
          footer: () => (
            <TableValueMetric value={formattedTotalsItem.impressions} valueClassName={classes.totalsValue} />
          ),
          style: { ...impressionsColumnSizes },
          showOnMobile: false
        },
        {
          header: 'CPM',
          Cell: ({ cpm }) => <TableValueMetric value={cpm} />,
          headClassName: classes.metricHeaderColumn,
          footer: () => <TableValueMetric value={formattedTotalsItem.cpm} valueClassName={classes.totalsValue} />,
          style: { ...cpmColumnSizes },
          showOnMobile: false
        },
        ...insertIf(showActiveViewImpressionsMetric, {
          header: 'Viewable Impr.',
          Cell: ({ active_view_impressions }) => <TableValueMetric value={active_view_impressions} />,
          headClassName: classes.metricHeaderColumn,
          footer: () => (
            <TableValueMetric
              value={formattedTotalsItem.active_view_impressions}
              valueClassName={classes.totalsValue}
            />
          ),
          style: { ...activeViewImpressionsColumnSizes },
          showOnMobile: false
        }),
        ...insertIf(showReachColumn, {
          header: () =>
            isMobile ? (
              <MobileHeaderWithTextInBrackets
                mainText={'Reach'}
                bracketsText={'Frequency'}
                containerClassName={classes.mobileHeaderWithOptionalDescription}
                bracketsTextClassName={classes.bracketsText}
              />
            ) : (
              <span className={classes.doubleLineHeader}>
                {t('Reach')} <br /> ({t('Frequency')})
              </span>
            ),
          Cell: ({ reach, frequency }) => <TableValueMetric value={reach} subValue={frequency} showEmptySubValue />,
          headClassName: classes.metricHeaderColumn,
          style: { ...reachColumnSizes },
          showOnMobile: false
        }),
        {
          header: () =>
            isMobile ? (
              <MobileHeaderWithTextInBrackets
                mainText={'Clicks'}
                bracketsText={'CTR'}
                containerClassName={classes.mobileHeaderWithOptionalDescription}
                bracketsTextClassName={classes.bracketsText}
              />
            ) : (
              <span className={classes.doubleLineHeader}>
                {t('Clicks')} <br /> ({t('CTR')})
              </span>
            ),
          Cell: ({ clicks, ctr }) => <TableValueMetric value={clicks} subValue={ctr} showEmptySubValue />,
          headClassName: classes.metricHeaderColumn,
          footer: () => <TableValueMetric value={formattedTotalsItem.clicks} valueClassName={classes.totalsValue} />,
          style: { ...clicksColumnSizes },
          showOnMobile: false
        },
        ...insertIf(showEngagementsMetric, {
          header: 'Engagements',
          Cell: ({ engagement }) => <TableValueMetric value={engagement} />,
          headClassName: classes.metricHeaderColumn,
          footer: () => (
            <TableValueMetric value={formattedTotalsItem.engagement} valueClassName={classes.totalsValue} />
          ),
          style: { ...engagementsColumnSizes },
          showOnMobile: false
        }),
        ...insertIf(showVideoViewsMetric, {
          header: () =>
            isMobile ? (
              <MobileHeaderWithTextInBrackets
                mainText={'Video Views'}
                bracketsText={'View Rate'}
                containerClassName={classes.mobileHeaderWithOptionalDescription}
                bracketsTextClassName={classes.bracketsText}
              />
            ) : (
              <span className={classes.doubleLineHeader}>
                {t('Video Views')} <br /> ({t('View Rate')})
              </span>
            ),
          Cell: ({ video_views, video_view_rate }) => (
            <TableValueMetric value={video_views} subValue={video_view_rate} showEmptySubValue />
          ),
          headClassName: classes.metricHeaderColumn,
          footer: () => (
            <TableValueMetric value={formattedTotalsItem.video_views} valueClassName={classes.totalsValue} />
          ),
          style: { ...videoViewsColumnSizes },
          showOnMobile: false
        }),
        {
          header: 'CPC',
          Cell: ({ cpc }) => <TableValueMetric value={cpc} />,
          headClassName: classes.metricHeaderColumn,
          footer: () => <TableValueMetric value={formattedTotalsItem.cpc} valueClassName={classes.totalsValue} />,
          style: { ...cpcColumnSizes },
          showOnMobile: false
        },
        {
          header: () =>
            isMobile ? (
              <MobileHeaderWithTextInBrackets
                mainText={'Purchases'}
                bracketsText={'ROAS'}
                containerClassName={classes.mobileHeaderWithOptionalDescriptio}
                bracketsTextClassName={classes.bracketsText}
              />
            ) : (
              <span className={classes.doubleLineHeader}>
                {t('Purchases')} <br />({t('ROAS')})
              </span>
            ),
          Cell: ({ purchase, roas }) => <TableValueMetric value={purchase} subValue={roas} showEmptySubValue />,
          headClassName: classes.metricHeaderColumn,
          footer: () => <TableValueMetric value={formattedTotalsItem.purchase} valueClassName={classes.totalsValue} />,
          style: { ...purchaseColumnSizes },
          showOnMobile: false
        },
        {
          header: 'Spend',
          Cell: ({ spend }) => <TableValueMetric value={spend} />,
          headClassName: classes.metricHeaderColumn,
          footer: () => <TableValueMetric value={formattedTotalsItem.spend} valueClassName={classes.totalsValue} />,
          style: { ...spendColumnSizes },
          showOnMobile: false
        },
        // if actions menu is empty then hide it
        ...insertIf(!!getActionsDropdownOptions && !!getActionsDropdownOptions({})?.length, {
          Cell: item => {
            // show actions as buttons list on mobile
            return isMobile ? (
              <ActionsButtonsList options={getActionsDropdownOptions(item)} />
            ) : (
              <ActionsDropdown options={getActionsDropdownOptions(item)} />
            )
          },
          style: isMobile ? { padding: 0 } : { ...dropdownColumnSizes },
          showOnMobile: false
        })
      ],
      [
        classes,
        platform,
        isMobile,
        shouldLimitNameColumn,
        showReachColumn,
        allowStatusChange,
        showActiveViewImpressionsMetric,
        showEngagementsMetric,
        showVideoViewsMetric,
        showGoalColumn,
        onStatusChange,
        formattedTotalsItem,
        getItemLinkClickHandler,
        getActionsDropdownOptions,
        t
      ]
    )

    const errorColumns = useMemo(
      () => [
        {
          Cell: () => '',
          style: { ...statusColumnSizes }
        },
        {
          Cell: ({ provider }) => <PlatformIcon platform={platform} provider={provider} />,
          style: { ...platformColumnSizes }
        },
        {
          className: classes.errorMessage,
          Cell: ({ provider: itemProvider }) => {
            const itemPlatform = PROVIDER_TO_PLATFORMS[itemProvider] || platform

            return `There was an authorisation error from ${PLATFORM_LABELS[itemPlatform]}, the data cannot be displayed`
          }
        }
      ],
      [classes, platform]
    )

    const customColumns = useMemo(() => {
      // memoized to avoid new object creation each render
      return { error: errorColumns }
    }, [errorColumns])

    return (
      <Table
        data={formattedData}
        cols={columns}
        itemUpdatingId={itemUpdatingId}
        isBigRow
        customColumns={customColumns}
        hideFooterRow={hideTotalsItem || isMobile}
        mobileMinimisedSectionBtnWrapperClassName={classes.mobileMinimisedSectionBtnWrapper}
        mobileColumnsWrapperClassName={classes.mobileMinimisedSectionHeader}
      />
    )
  }
)

DigitalTableData.propTypes = {
  platform: PropTypes.string,
  data: PropTypes.array.isRequired,
  totalsItem: PropTypes.object,
  isLoadingSelector: PropTypes.func,
  itemUpdatingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allowStatusChange: PropTypes.bool,
  onStatusChange: PropTypes.func,
  currencySymbol: PropTypes.string,
  showGoalColumn: PropTypes.bool,
  getItemLinkClickHandler: PropTypes.func,
  getActionsDropdownOptions: PropTypes.func
}

export default DigitalTableData
