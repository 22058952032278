import { calc } from '../../../../../../../helpers/numbers'
import { getProductDiscount } from '../helpers/price'

import { PRODUCT_PERIODS_DATES, QUANTITY } from '../../../../fields'

export const DISCOUNT_PERCENTAGE = 'discountPercentage'

export function determineDiscountsFromProducts(allSelectedProducts) {
  const discountPresetCalculations = getDiscountPreset(allSelectedProducts)
  // productsDiscount is array of objects with product id and discount percentage
  return getProductsDiscount(allSelectedProducts, discountPresetCalculations)
}

// allSelectedProducts is array of products. Each product object may have discount_preset field(optional). There
// could be different discount_preset for each product. So, we need to group discount_preset by its value and make
// a calculation for each group.
const getDiscountPreset = allSelectedProducts => {
  const presetGroups = allSelectedProducts.reduce((acc, productValues) => {
    const { data: productData, quantity, [PRODUCT_PERIODS_DATES]: periodsDates } = productValues
    const periods_amount = periodsDates?.length || 0
    const totalPeriodsPrice = periodsDates?.reduce((acc, { price }) => acc + price * quantity, 0) || 0
    const discountPreset = productData.discount_preset

    if (discountPreset?.id) {
      if (!acc[discountPreset.id]) {
        acc[discountPreset.id] = {
          currentTotalQuantity: 0,
          currentTotalPeriods: 0,
          discounts: discountPreset.discounts.map(discount => ({
            ...discount,
            isMatched: false,
            isActive: false
          })),
          subgroups: []
        }
      }

      // Create a new subgroup for the current product
      const subgroup = {
        quantity,
        periods_amount,
        activeDiscount: null,
        productId: productData.id,
        totalPrice: totalPeriodsPrice
      }
      acc[discountPreset.id].subgroups.push(subgroup)

      // Update the total quantity and periods for the discount_preset group
      acc[discountPreset.id].currentTotalQuantity += quantity
      acc[discountPreset.id].currentTotalPeriods += quantity * periods_amount
    }

    return acc
  }, {})

  // Calculate the average periods for each discount_preset group and apply discounts
  Object.values(presetGroups).forEach(group => {
    group.averagePeriods = group.currentTotalPeriods / group.currentTotalQuantity

    // Update isMatched for each discount based on the conditions for the entire group
    group.discounts = group.discounts.map(discount => ({
      ...discount,
      isActive: false,
      isMatched: group.currentTotalQuantity >= discount.quantity && group.averagePeriods >= discount.periods
    }))

    // Find the maximum discount for the entire group
    const matchedDiscounts = group.discounts.filter(discount => discount.isMatched)
    let maxDiscount = null
    if (matchedDiscounts.length > 0) {
      maxDiscount = matchedDiscounts.reduce(
        (max, discount) =>
          parseFloat(discount.discount_percentage) > parseFloat(max.discount_percentage) ? discount : max,
        matchedDiscounts[0]
      )
      maxDiscount.isActive = true
    }

    // 1. Calculate total group price for maxDiscount
    const totalGroupPrice = group.subgroups.reduce((acc, subgroup) => acc + subgroup.totalPrice, 0)
    const priceCoefficient = maxDiscount?.discount_percentage ? 1 - maxDiscount?.discount_percentage : 1
    const totalGroupPriceWithDiscount = calc(totalGroupPrice).mul(priceCoefficient).toNumber()

    // 2. Calculate total group price with subgroup discounts
    let totalGroupPriceWithSubgroupDiscounts = 0
    group.subgroups.forEach(subgroup => {
      const subgroupDiscount = group.discounts.reduce(
        (max, discount) =>
          subgroup.quantity >= discount.quantity &&
          subgroup.periods_amount >= discount.periods &&
          parseFloat(discount.discount_percentage) > parseFloat(max?.discount_percentage || 0)
            ? discount
            : max,
        null
      )

      // set individual subgroup discount
      subgroup.activeDiscount = subgroupDiscount
      const priceCoefficient = subgroupDiscount?.discount_percentage ? 1 - subgroupDiscount?.discount_percentage : 1
      const subgroupDiscountedPrice = calc(subgroup.totalPrice).mul(priceCoefficient).toNumber()
      totalGroupPriceWithSubgroupDiscounts += subgroupDiscountedPrice
    })

    //  Check if applying subgroup discounts would result in a higher discount if yes, then disable the maxDiscount
    if (totalGroupPriceWithSubgroupDiscounts < totalGroupPriceWithDiscount) {
      group.discounts.forEach(discount => (discount.isActive = false))
    }
  })

  // if individual product discount is higher than group discount, then individual(subgroup) should be used
  return presetGroups
}

// determine each product discount
const getProductsDiscount = (allSelectedProducts, discountPresetCalculations) =>
  allSelectedProducts.map(productValues => {
    const productData = productValues.data
    // find preset which is used in the product
    const productDiscountPresetId = productData.discount_preset?.id
    const selectedDiscountPreset = productDiscountPresetId && discountPresetCalculations[productDiscountPresetId]
    // group discount
    const activePresetGroupDiscount = selectedDiscountPreset?.discounts.find(discount => discount.isActive)
    // individual product discount based on discount_preset rules
    const activePresetSubGroupDiscount = selectedDiscountPreset?.subgroups.find(
      ({ productId }) => productId === productData.id
    )?.activeDiscount
    const activePresetDiscount = activePresetGroupDiscount || activePresetSubGroupDiscount

    const productsQuantity = productValues[QUANTITY]
    const periodsAmount = productValues[PRODUCT_PERIODS_DATES].length
    // totalPeriodsAmount - total amount of publications for current product to calculate discount
    const totalPeriodsAmount = periodsAmount * productsQuantity
    const discount = getProductDiscount({
      discounts: productData.discounts,
      activePresetDiscount,
      periodsAmount: totalPeriodsAmount
    })

    return {
      id: productData.id,
      [DISCOUNT_PERCENTAGE]: discount
    }
  })
