import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import GoogleAssetGroupForm from './GoogleAssetGroupForm'
import BrandsMissingWarning from '../../../ReusableFormFields/BrandsMissingWarning'
import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'

import useClearAssetsGroupCreate from './hook'

import { closeForm } from '../../../../../modules/actions/forms'

import { ASSET_GROUP_GOOGLE_ADS_CREATE } from '../../../../../constants/forms'
import { selectedAdAccountIdSelector, selectedSelfAccountDataSelector } from '../../../../../modules/selectors/app'

// If google ads campaign type = performance max (identified by AdvertisingChannelType = 10 ) then:
// Campaign will have no line items and will only have a list of ads, and the ads are different (not visuals, but asset groups)
const GoogleAssetGroupCreate = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { brands } = useSelector(selectedSelfAccountDataSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const hasBrands = brands?.length > 0

  const clearCreateAssetsGroupHandler = useClearAssetsGroupCreate()

  const onAfterFormClose = useCallback(() => {
    clearCreateAssetsGroupHandler()
  }, [clearCreateAssetsGroupHandler])

  const onSuccessSubmit = useCallback(() => {
    dispatch(closeForm())
  }, [dispatch])

  return (
    <FormDrawerWrapper
      openButtonText={t('Create Ad')}
      formName={ASSET_GROUP_GOOGLE_ADS_CREATE}
      closeOnSubmit={false}
      onAfterFormClose={onAfterFormClose}
      hasDefaultDrawerContentSpacing={false}
      isWideDrawer
    >
      {hasBrands ? (
        <GoogleAssetGroupForm onSuccessSubmit={onSuccessSubmit} adAccountId={selectedAdAccountId} />
      ) : (
        <BrandsMissingWarning hasDrawerPadding />
      )}
    </FormDrawerWrapper>
  )
}

export default GoogleAssetGroupCreate
