import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import MediaTagActions from '../../MediaTagActions'
import Table from '../../../../../../components/Table'

import { ReactComponent as DraggableDots } from '../../../../../../assets/icons/draggable-dots.svg'

import {
  updateTagIdSelector,
  deleteTagIdSelector,
  updateTagIsLoadingSelector,
  deleteTagIsLoadingSelector
} from '../../../../../../modules/selectors/tags'

import { tableColumnsSize } from '../index'

import { phonesDownSize } from '../../../../../../styles/const/breakpoints'

import useStyles from './styles'

const TagsTableData = ({ tags, onOrderChange }) => {
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })
  const classes = useStyles()

  const updateTagId = useSelector(updateTagIdSelector)
  const updateTagIsLoading = useSelector(updateTagIsLoadingSelector)
  const deleteTagId = useSelector(deleteTagIdSelector)
  const deleteTagIsLoading = useSelector(deleteTagIsLoadingSelector)

  const loadingTagId = useMemo(() => {
    if (updateTagIsLoading) {
      return updateTagId
    }
    if (deleteTagIsLoading) {
      return deleteTagId
    }

    // default value null if nothing is updating or deleting
    return null
  }, [updateTagId, updateTagIsLoading, deleteTagId, deleteTagIsLoading])

  const tagsColumns = useMemo(
    () => [
      {
        Cell: () => <DraggableDots />,
        style: { maxWidth: tableColumnsSize.dragBtn }
      },
      {
        fieldKey: 'title'
      },
      {
        Cell: data => <MediaTagActions tag={data} className={classes.actionsStyles} />,
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [classes.actionsStyles, isMobile]
  )

  return (
    <Table
      hideHeadlineRow
      data={tags}
      cols={tagsColumns}
      onRowDrag={onOrderChange}
      itemUpdatingId={loadingTagId}
      tableId="TagsTableData"
      hideFooterRow
      mobileColumnsWrapperClassName={classes.mobileTableHeader}
    />
  )
}

TagsTableData.propTypes = {
  tags: PropTypes.array.isRequired,
  onOrderChange: PropTypes.func.isRequired
}

export default TagsTableData
