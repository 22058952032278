import React from 'react'
import { useTranslation } from 'react-i18next'

import FieldRow from '../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../components/Form/Field'
import FieldsSection from '../../../../../../features/components/Form/FieldsSection'
import StartEndDateRangeFields from '../../../../../ReusableFormFields/StartEndDateRangeFields'
import Tabs from '../../../../../../features/components/Tabs'
import CheckboxBlock from '../../../../../../features/components/Form/CheckboxBlock'
import BudgetField from '../../../../../ReusableFormFields/BudgetTabs/BudgetField'

import {
  campaignTypeOptions,
  OPTIMISE_TARGET_ROAS,
  SET_TARGET_ROAS,
  TARGET_ROAS_OPTION,
  CAMPAIGN_TYPE,
  START_DATE,
  STOP_DATE
} from '../fields'
import { NAME } from '../../../../../Multiplatform/CampaignForms/fields'
import { BUDGET_DAILY, TARGET_ROAS } from '../../../../../ReusableFormFields/CampaignForms/fields'
import { budgetOptions } from '../fields'

import useStyles from './styles'

const AdDetails = ({ formik }) => {
  const { t } = useTranslation()

  const classes = useStyles()

  const { values, setFieldValue } = formik

  const handleCheck = () => {
    if (values[TARGET_ROAS_OPTION] === SET_TARGET_ROAS) {
      setFieldValue(TARGET_ROAS_OPTION, OPTIMISE_TARGET_ROAS)
    } else {
      setFieldValue(TARGET_ROAS_OPTION, SET_TARGET_ROAS)
    }
  }

  return (
    <FieldsSection title="Campaign details">
      <FieldRow title="Name" description="Campaign name">
        <Field formik={formik} name={NAME} placeholder="Campaign name" autoComplete="off" />
      </FieldRow>
      <FieldRow
        title="Campaign Type"
        description="Performance Max are conversion optimised campaigns that run across all of Google's channels like YouTube, Display, Search, Discover, Gmail, and Maps."
      >
        <Field
          formik={formik}
          name={CAMPAIGN_TYPE}
          placeholder="Campaign type"
          options={campaignTypeOptions}
          autoComplete="off"
        />
      </FieldRow>
      <FieldRow title="Dates" description="Start and end date of the campaign">
        <StartEndDateRangeFields formik={formik} startDateName={START_DATE} endDateName={STOP_DATE} />
      </FieldRow>
      <FieldRow title="Budget" description="Set a daily budget for the campaign">
        <Tabs
          options={budgetOptions}
          selectedValue={BUDGET_DAILY}
          // block tabs changing, should be selected only BUDGET_DAILY
          onSelectValue={() => {}}
          className={classes.budgetOptionsTabs}
        />
        <BudgetField formik={formik} />
      </FieldRow>
      <FieldRow title="Target ROAS" description="If checked, you can set a target return on ad spend (ROAS) goal">
        <CheckboxBlock
          checked={values[TARGET_ROAS_OPTION] === SET_TARGET_ROAS}
          title={t('Set a target ROAS')}
          onCheck={handleCheck}
          id="fee-checkbox-block"
        >
          <Field
            className={classes.roasField}
            type="number"
            formik={formik}
            name={TARGET_ROAS}
            placeholder="ROAS"
            autoComplete="off"
            inputMode="numeric"
          />
        </CheckboxBlock>
      </FieldRow>
    </FieldsSection>
  )
}

export default AdDetails
