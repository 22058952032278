import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import LifetimeBudgetField from '../../../../../Multiplatform/UploadAdCreative/LifetimeBudgetField'
import Field from '../../../../../../components/Form/Field'
import RadioBlock from '../../../../../../components/Form/RadioBlock'

import useAccessibleCampaignsRoutes from '../../../../../Multiplatform/RoutesMissingWarning/useAccessibleCampaignsRoutes'

import { ReactComponent as DV360Icon } from '../../../../../../assets/logos/dv360/dv360-display-ads-icon.svg'

import {
  CAMPAIGN,
  CAMPAIGN_OPTION,
  CAMPAIGN_OPTION_EXISTING_CAMPAIGN,
  CAMPAIGN_OPTION_NEW_CAMPAIGN
} from '../../../../../Multiplatform/UploadAdCreative/fields'
import { DV_360_PLATFORM } from '../../../../../../constants/selectLists/platformList'
import { AUDIENCE_RETARGETING_ROUTE } from '../../../../../../pages/Settings/AdvertiserAccounts/ClientAccountForms/ClientAccountWithMarginsCreate/ClientAccountWithMarginsCreateForm/fields'

import useStepFormStyles from '../../../../../../styles/common/stepForms'

const CampaignFormContent = ({ formik, formattedCampaigns, remainingBudget }) => {
  const stepFormClasses = useStepFormStyles()
  const { t } = useTranslation()

  const allowedRoute = useAccessibleCampaignsRoutes(DV_360_PLATFORM)
  const hasAccessibleRoutes = Object.values(allowedRoute).some(route => route)

  const { setFieldValue, values } = formik

  return (
    <div className={stepFormClasses.stepBody}>
      {!!formattedCampaigns?.length && (
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_existing_campaign"
          name={CAMPAIGN_OPTION}
          label={t('Upload an ad to an existing campaign')}
          value={CAMPAIGN_OPTION_EXISTING_CAMPAIGN}
          selectedValue={values[CAMPAIGN_OPTION]}
          description={<p>{t('Upload a new ad to a new or existing ad set in one of your campaigns')}</p>}
          hasOrSpacing={hasAccessibleRoutes}
        >
          <Field formik={formik} name={CAMPAIGN} placeholder={t('Campaign')} options={formattedCampaigns} />
        </RadioBlock>
      )}
      {allowedRoute[AUDIENCE_RETARGETING_ROUTE] && (
        <RadioBlock
          setFieldValue={setFieldValue}
          id="campaign_option_new_campaign"
          name={CAMPAIGN_OPTION}
          label={t('Drive in-store sales with programmatic display')}
          value={CAMPAIGN_OPTION_NEW_CAMPAIGN}
          selectedValue={values[CAMPAIGN_OPTION]}
          Icon={DV360Icon}
          description={
            <>
              <p>{t('Run a display campaign across our exclusive network of leading publishers.')}</p>
              <p>{t('3rd party verification by Integral Ad Science is applied to all media buys.')}</p>
            </>
          }
        >
          <LifetimeBudgetField formik={formik} remainingBudget={remainingBudget} platform={DV_360_PLATFORM} />
        </RadioBlock>
      )}
    </div>
  )
}

CampaignFormContent.propTypes = {
  formik: PropTypes.object.isRequired,
  formattedCampaigns: PropTypes.array.isRequired
}

export default CampaignFormContent
