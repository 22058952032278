import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import EditForm from '../../../../../../features/components/Forms/EditForm'
import MultiSelectBox from '../../../../../../features/components/Form/MultiSelectBox'
import ErrorMessage from '../../../../../../components/Form/ErrorMessage'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import {
  getDeletedCriterionsArray,
  removeExistingCriterions
} from '../../../../../../features/helpers/googleCriterionsHelpers'

import { getGoogleGeolocations } from '../../../../../../modules/actions/googleConstants'
import { clearCreateCampaignCriterions, createCampaignCriterions } from '../../../../../../modules/actions/campaigns'
import {
  campaignCriterionsSelector,
  createCampaignCriterionsErrorSelector,
  createCampaignCriterionsIsLoadingSelector,
  createCampaignCriterionsWasCreatedSelector
} from '../../../../../../modules/selectors/campaigns'
import {
  googleGeolocationsIsLoadingSelector,
  googleGeolocationsSelector
} from '../../../../../../modules/selectors/googleConstants'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { CAMPAIGN_GOOGLE_LOCATION_CRITERION_TYPE } from '../../../../../../constants/campaigns'
import { LOCATIONS } from '../../../../../ReusableFormFields/CampaignForms/fields'

const validationSchema = Yup.object({ [LOCATIONS]: Yup.array().min(1, 'Please select at least one option') })

const GeoTargetingSectionForm = ({ editItemData: campaign, ...formProps }) => {
  const dispatch = useDispatch()

  const [manualSuccessSubmit, setManualSuccessSubmit] = useState(false)

  const campaignCriterions = useSelector(campaignCriterionsSelector)
  const criterionsWasCreated = useSelector(createCampaignCriterionsWasCreatedSelector)
  const geolocations = useSelector(googleGeolocationsSelector)
  const geolocationsIsLoading = useSelector(googleGeolocationsIsLoadingSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const { id: campaignId } = campaign

  const formattedInitialGeoLocations = useMemo(
    () =>
      campaignCriterions
        .filter(criterion => criterion.type_ === CAMPAIGN_GOOGLE_LOCATION_CRITERION_TYPE)
        .map(locationCriterion => ({
          label: locationCriterion['display_name'],
          value: locationCriterion['criterion_id'],
          criterionResourceName: locationCriterion.resource_name
        })),
    [campaignCriterions]
  )

  const formattedGeolocations = useMemo(
    () =>
      geolocations &&
      geolocations.map(item => {
        const { id, canonical_name: canonicalLabel, resource_name: resourceName } = item

        return { value: id, label: canonicalLabel, resourceName }
      }),
    [geolocations]
  )

  const onSubmit = useCallback(
    values => {
      const criterionsToDelete = getDeletedCriterionsArray(formattedInitialGeoLocations, values[LOCATIONS])
      const criterionsToCreate = removeExistingCriterions(formattedInitialGeoLocations, values[LOCATIONS])

      if (!criterionsToDelete.length && !criterionsToCreate.length) {
        setManualSuccessSubmit(true)
      } else {
        const formattedCriterionsData = {
          account: selectedAdAccountId,
          operations: criterionsToCreate.map(location => ({
            campaign: campaignId,
            location: {
              geo_target_constant: location.resourceName
            }
          })),
          remove_operations: criterionsToDelete
        }

        dispatch(createCampaignCriterions(formattedCriterionsData, null, criterionsToDelete))
      }
    },
    [dispatch, campaignId, formattedInitialGeoLocations, selectedAdAccountId]
  )

  const initialValues = useMemo(
    () => ({
      [LOCATIONS]: formattedInitialGeoLocations || []
    }),
    [formattedInitialGeoLocations]
  )

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const { setFieldValue, values, errors } = purifiedFormik

  const locationsError = errors[LOCATIONS]

  const handleGetNewLocations = useCallback(
    searchValue => {
      if (searchValue) {
        dispatch(
          getGoogleGeolocations({
            account: selectedAdAccountId,
            search: searchValue
          })
        )
      }
    },
    [dispatch, selectedAdAccountId]
  )

  const handleClearCreateCriterions = useCallback(() => {
    dispatch(clearCreateCampaignCriterions())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      clearEditItem={handleClearCreateCriterions}
      successSubmit={criterionsWasCreated || manualSuccessSubmit}
      errorSelector={createCampaignCriterionsErrorSelector}
      isLoadingSelector={createCampaignCriterionsIsLoadingSelector}
      {...formProps}
    >
      <MultiSelectBox
        placeholder="Add a country or region"
        name={LOCATIONS}
        setFieldValue={setFieldValue}
        options={formattedGeolocations}
        value={values[LOCATIONS]}
        isLoading={geolocationsIsLoading}
        onSearch={handleGetNewLocations}
      />
      {locationsError && <ErrorMessage error={locationsError} />}
    </EditForm>
  )
}

export default GeoTargetingSectionForm
