import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import AmendmentsTable from './AmendmentsTable'
import AmendmentReview from './AmendmentReview'

import { clearGetAmendmentsList, getAmendmentsList } from '../../../../modules/actions/amendments'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'

import { ROUTE_PARAMS } from '../../../../constants/routes'

import useStyles from './styles'

const AmendmentsSection = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { [ROUTE_PARAMS.mediaOrderId]: mediaOrderId } = useParams()

  const controllerId = useSelector(selectedControllerIdSelector)

  useEffect(() => {
    dispatch(
      getAmendmentsList({
        original_media_order: mediaOrderId,
        singed_by_controller: true,
        amendment_sent: true,
        // '-' helps to revert order from latest
        ordering: '-created',
        controller: controllerId
      })
    )
  }, [dispatch, mediaOrderId, controllerId])

  useEffect(() => {
    return () => {
      dispatch(clearGetAmendmentsList())
    }
  }, [dispatch])

  return (
    <div className={classes.container}>
      <AmendmentsTable />
      <AmendmentReview />
    </div>
  )
}

export default AmendmentsSection
