import { createStyles } from '../../../../styles/helpers'

import { red, greyOutline, white } from '../../../../styles/const/colors'

const useStyles = createStyles({
  wrapper: {
    width: 330,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto'
  },
  warningIcon: {
    width: 34,
    height: 34
  },
  form: {
    width: '100%'
  },
  formSubmitButton: {
    backgroundColor: `${red} !important`,
    borderColor: `${red} !important`,

    '&:hover': {
      backgroundColor: 'transparent !important',
      color: `${red} !important`
    }
  },
  cancelButton: {
    borderColor: 'transparent !important',
    color: `${greyOutline} !important`,
    marginTop: 12,

    '&:hover': {
      backgroundColor: `${greyOutline} !important`,
      color: `${white} !important`
    }
  }
})

export default useStyles
