import { createStyles } from '../../../../../../styles/helpers'

const useStyles = createStyles(theme => ({
  fieldsContainer: {
    marginTop: 2,
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8
  },
  loadMoreButton: {
    margin: '0 auto',
    marginTop: 10
  },
  addNewRowButton: {
    alignSelf: 'flex-start',
    marginTop: 10
  },
  submitButton: {
    margin: '16px 0'
  }
}))

export default useStyles
