import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { formatCurrency } from '../../../../helpers/numbers'

import { selectedSelfAccountDataSelector } from '../../../../modules/selectors/app'

const useGetPackagePrice = ({ prices }) => {
  const selectedSelfAccountData = useSelector(selectedSelfAccountDataSelector)

  const selectedSelfAccountCurrency = selectedSelfAccountData?.currency

  // try to find price with currency which is equal to currency on self account
  // if there's no match, use first price in the list
  const accountCurrencyPrice = useMemo(
    () => prices.find(price => price.currency === selectedSelfAccountCurrency),
    [selectedSelfAccountCurrency, prices]
  )

  const packagePrice = accountCurrencyPrice ? accountCurrencyPrice : prices[0]

  return useMemo(
    () => packagePrice?.price && formatCurrency(packagePrice.price, {}, { symbol: packagePrice['currency_symbol'] }),
    [packagePrice]
  )
}

export default useGetPackagePrice
