import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  backedInstagramAccountsSelector,
  facebookPageAccessTokensSelector,
  instagramAccountsSelector
} from '../../modules/selectors/socialAccounts'

import { INITIAL_GET_RESULTS_REDUCER } from '../../constants/reducer'

export default function useGetInstagramAccounts(facebookPageId) {
  const { [facebookPageId]: selectedPageTokenData } = useSelector(facebookPageAccessTokensSelector)
  const { [facebookPageId]: selectedInstagramAccounts } = useSelector(instagramAccountsSelector)
  const { [facebookPageId]: selectedBackedInstagramAccounts } = useSelector(backedInstagramAccountsSelector)

  return useMemo(
    () => ({
      // instagramAccounts are loaded and forming the state dynamically based on facebookPageId,
      // so when instagramAccounts weren't loaded INITIAL loading data is returned

      selectedPageTokenData: {
        isLoading: Boolean(selectedPageTokenData?.isLoading),
        wasLoaded: Boolean(selectedPageTokenData?.wasLoaded),
        token: selectedPageTokenData?.token || '',
        errors: selectedPageTokenData?.errors || null
      },

      selectedInstagramAccounts: {
        ...INITIAL_GET_RESULTS_REDUCER,
        isLoading: Boolean(selectedInstagramAccounts?.isLoading),
        wasLoaded: Boolean(selectedInstagramAccounts?.wasLoaded),
        results: selectedInstagramAccounts?.results || [],
        errors: selectedInstagramAccounts?.errors || null
      },

      selectedBackedInstagramAccounts: {
        ...INITIAL_GET_RESULTS_REDUCER,
        isLoading: Boolean(selectedBackedInstagramAccounts?.isLoading),
        wasLoaded: Boolean(selectedBackedInstagramAccounts?.wasLoaded),
        results: selectedBackedInstagramAccounts?.results || [],
        errors: selectedBackedInstagramAccounts?.errors || null
      }
    }),
    [selectedInstagramAccounts, selectedBackedInstagramAccounts, selectedPageTokenData]
  )
}
