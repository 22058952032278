import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useStyles from './styles'

// controllable Toggle which is just a UI representation, could be used in cases where need to manage state externally
function ToggleControllable({ isEnabled, toggleColor, onToggle }) {
  const classes = useStyles({ toggleColor })

  return (
    <div
      className={classnames(classes.toggleContainer, { [classes.isEnabledContainer]: isEnabled })}
      onClick={onToggle}
    >
      <span className={classnames(classes.toggleBtn, { [classes.isEnabledBtn]: isEnabled })} />
    </div>
  )
}

ToggleControllable.propTypes = {
  isEnabled: PropTypes.bool,
  toggleColor: PropTypes.string,
  onToggle: PropTypes.func.isRequired
}

export default ToggleControllable
