import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { format } from 'date-fns'

import Can from '../../../features/components/Can'
import LineItemsContent from './LineItemsContent'
import LineItemFacebookEdit from '../../../forms/Facebook/LineItemForms/LineItemFacebookEdit'
import LineItemGoogleEdit from '../../../forms/Google/LineItemForms/LineItemGoogleEdit'
import LineItemFacebookDuplicate from '../../../forms/Facebook/LineItemForms/LineItemFacebookDuplicate'
import LineItemTikTokEdit from '../../../forms/Tiktok/LineItemForms/LineItemTikTokEdit'

import useSearch from '../../../hooks/useSearch'

import { formatSortParams } from '../../../features/formatters'
import { getListItemInsights } from '../../../features/helpers/componentsHelpers'

import { getLineItems } from '../../../modules/actions/lineItems'
import {
  // setCampaignsSummarySelectedLineItems,
  clearCampaignsSummarySelectedLineItems
} from '../../../modules/actions/campaignsSummary'
import { campaignSelector } from '../../../modules/selectors/campaigns'
import {
  campaignsSummaryDateRangeSelector,
  campaignsSummaryFilterSelector,
  campaignsSummarySortSelector
} from '../../../modules/selectors/campaignsSummary'
import { selectedPlatformSelector, selectedAdAccountIdSelector } from '../../../modules/selectors/app'

import {
  CAMPAIGN_FILTERS_DEFAULT,
  DATE_STATIC_PRESET,
  LIFETIME_DATE_PRESET
} from '../../../constants/selectLists/listItemFiltersList'
import { DATES_FORMAT_BE } from '../../../constants/dates'
import { LINE_ITEM_PERMISSION } from '../../../constants/permissions'
import { FACEBOOK_PLATFORM, GOOGLE_PLATFORM, TIKTOK_PLATFORM } from '../../../constants/selectLists/platformList'

function LineItems({ searchId }) {
  const dispatch = useDispatch()

  const searchTerm = useSearch(searchId)
  const { campaign_id } = useParams()

  const selectedPlatform = useSelector(selectedPlatformSelector)
  const selectedAdAccount = useSelector(selectedAdAccountIdSelector)
  const sort = useSelector(campaignsSummarySortSelector)
  const filter = useSelector(campaignsSummaryFilterSelector)
  const dateRange = useSelector(campaignsSummaryDateRangeSelector)
  const { objective: campaignObjective } = useSelector(campaignSelector)

  const { date_preset, startDate, endDate } = dateRange

  // todo decide if we need to move it to tables (graying out not related ads), and proceed with implementation
  // const onLineItemSelect = useCallback(
  //   lineItemId => {
  //     dispatch(setCampaignsSummarySelectedLineItems(lineItemId))
  //   },
  //   [dispatch]
  // )

  const noDataMessageText = useMemo(() => {
    const mainText = `There are no ad ${selectedPlatform === FACEBOOK_PLATFORM ? 'sets' : 'groups'} to show. `
    const subText =
      searchTerm ||
      filter !== CAMPAIGN_FILTERS_DEFAULT ||
      (dateRange && dateRange[DATE_STATIC_PRESET] !== LIFETIME_DATE_PRESET)
        ? 'Try clearing or modifying your search or filter.'
        : `Click ‘Create Ad ${selectedPlatform === FACEBOOK_PLATFORM ? 'Set' : 'Group'}’ to set one up.`

    return (
      <>
        {mainText}
        <br />
        {subText}
      </>
    )
  }, [selectedPlatform, searchTerm, filter, dateRange])

  const allowGetLineItems = useMemo(() => {
    switch (selectedPlatform) {
      case FACEBOOK_PLATFORM:
        // campaignObjective currently available for facebook
        return !!campaignObjective
      default:
        return true
    }
  }, [selectedPlatform, campaignObjective])

  const dynamicLineItemsPayload = useMemo(() => {
    switch (selectedPlatform) {
      case FACEBOOK_PLATFORM:
        // campaignObjective currently available for facebook
        return { campaign_objective: campaignObjective }
      default:
        return null
    }
  }, [selectedPlatform, campaignObjective])

  useEffect(() => {
    if (allowGetLineItems) {
      dispatch(
        getLineItems({
          account: selectedAdAccount,
          campaign_id,
          ...(dynamicLineItemsPayload && dynamicLineItemsPayload),
          date_from: startDate && format(startDate, DATES_FORMAT_BE),
          date_to: endDate && format(endDate, DATES_FORMAT_BE),
          date_preset,
          insights: getListItemInsights(selectedPlatform),
          status: filter === CAMPAIGN_FILTERS_DEFAULT ? undefined : filter,
          ...(searchTerm && { search: searchTerm }),
          ...formatSortParams(sort)
        })
      )
    }
  }, [
    dispatch,
    allowGetLineItems,
    dynamicLineItemsPayload,
    searchTerm,
    selectedAdAccount,
    campaign_id,
    selectedPlatform,
    startDate,
    endDate,
    date_preset,
    filter,
    sort
  ])

  useEffect(() => {
    return () => {
      dispatch(clearCampaignsSummarySelectedLineItems())
    }
  }, [dispatch])

  return (
    <>
      <LineItemsContent noDataMessageText={noDataMessageText} />

      {/*edit forms*/}
      {selectedPlatform === FACEBOOK_PLATFORM && (
        <Can I="update" a={LINE_ITEM_PERMISSION}>
          <LineItemFacebookEdit shouldBeCleared />
        </Can>
      )}
      {selectedPlatform === GOOGLE_PLATFORM && (
        <Can I="update" a={LINE_ITEM_PERMISSION}>
          <LineItemGoogleEdit shouldBeCleared />
        </Can>
      )}
      {selectedPlatform === TIKTOK_PLATFORM && (
        <Can I="update" a={LINE_ITEM_PERMISSION}>
          <LineItemTikTokEdit shouldBeCleared />
        </Can>
      )}

      {/*duplicate forms*/}
      {selectedPlatform === FACEBOOK_PLATFORM && (
        <Can I="create" a={LINE_ITEM_PERMISSION}>
          <LineItemFacebookDuplicate />
        </Can>
      )}
    </>
  )
}

export default LineItems
