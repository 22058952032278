import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classnames from 'classnames'

import useStyles from './styles'

// SkeletonListRow is rendering the Skeleton with same quantity of columns as real row component
// also each column have similar width to the real one
function SkeletonListRow({ cols = [{}, {}, {}] }) {
  const classes = useStyles()

  return (
    <li className={classes.row}>
      {cols.map((colData, index) => (
        <div key={index} className={classnames(classes.col, colData.className)} style={colData.style}>
          <Skeleton width="100%" />
        </div>
      ))}
    </li>
  )
}

export default SkeletonListRow
