import React from 'react'
import PropTypes from 'prop-types'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import ProductOrPageSelector from '../../../../../ReusableFormFIelds/ProductOrPageSelector'

import { PRODUCT_ID } from '../../../../../../ReusableFormFields/ProductFields/fields'
import { BRAND_CATEGORY_PAGE_ID } from '../../../../../../ReusableFormFields/ProductsAndPagesFields/fields'

const stepFields = [PRODUCT_ID, BRAND_CATEGORY_PAGE_ID]
const ProductOrPageStep = ({ formik, handleStepChange, onProductOrPageDataSelect }) => {
  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  return (
    <Step stepTitle="Select a page or product to click through to" handleContinue={handleContinue}>
      <ProductOrPageSelector formik={formik} onProductOrPageDataSelect={onProductOrPageDataSelect} />
    </Step>
  )
}

ProductOrPageStep.propTypes = {
  onProductOrPageDataSelect: PropTypes.func.isRequired
}

export default ProductOrPageStep
