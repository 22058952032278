import { createStyles } from '../../../../../../styles/helpers'

const useStyles = createStyles({
  priceChangeContainer: {
    display: 'flex',
    gap: '5px'
  },
  increase: {
    flexGrow: 1
  },
  priceChange: {
    maxWidth: 85
  }
})

export default useStyles
