import {
  AD_LINK,
  CAROUSEL_ITEMS,
  FORMAT_CAROUSEL,
  FORMAT_OPTION,
  FORMAT_SINGLE,
  FORMAT_STORY,
  STORIES_ITEMS
} from '../../../fields'

import { getUrlDomain } from '../../../../../../helpers/url'

export default function getConversionDomain(values) {
  const formatOption = values[FORMAT_OPTION]

  switch (formatOption) {
    case FORMAT_CAROUSEL:
      return getUrlDomain(values[CAROUSEL_ITEMS][0][AD_LINK])
    case FORMAT_STORY:
      return getUrlDomain(values[STORIES_ITEMS][0][AD_LINK])
    case FORMAT_SINGLE:
    default:
      return getUrlDomain(values[AD_LINK])
  }
}
