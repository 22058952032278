import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'

import Form from '../../../../../components/Form'
import DrawerHeadline from '../../../../../components/Drawer/DrawerHeadline'
import FieldsSection from '../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../features/components/Form/FieldsSection/FieldRow'
import StartEndDateRangeFields from '../../../../ReusableFormFields/StartEndDateRangeFields'

import { clearCreateLineItem, createLineItem } from '../../../../../modules/actions/lineItems'
import { selectedDv360AdAccountIdSelector } from '../../../../../modules/selectors/app'
import { mediaOrderUploadCreativeDataSelector } from '../../../../../modules/selectors/mediaOrders'
import {
  lineItemCreatedDataSelector,
  lineItemCreateErrorSelector,
  lineItemCreateIsLoadingSelector,
  lineItemWasCreatedSelector
} from '../../../../../modules/selectors/lineItems'
import { setMediaOrderUploadCreative } from '../../../../../modules/actions/mediaOrders'

import { END_DATE, START_DATE } from '../../../../ReusableFormFields/StartEndDateRangeFields/fields'
import { initialValues, transformValuesToBE, validationSchema } from './fields'
import { DV_360_PLATFORM } from '../../../../../constants/selectLists/platformList'

import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

const LineItemForm = ({ onSuccessSubmit }) => {
  const dispatch = useDispatch()
  const drawerFormClasses = useDrawerFormStyles()

  const lineItemWasCreated = useSelector(lineItemWasCreatedSelector)
  const lineItemCreatedData = useSelector(lineItemCreatedDataSelector)

  const { campaign } = useSelector(mediaOrderUploadCreativeDataSelector)

  const dv360AdAccountId = useSelector(selectedDv360AdAccountIdSelector)

  const onSubmit = useCallback(
    values => {
      const formattedData = transformValuesToBE(values, campaign)

      const lineItemData = {
        account: dv360AdAccountId,
        campaign_id: campaign.id,
        ...formattedData
      }

      dispatch(createLineItem(lineItemData, DV_360_PLATFORM))
    },
    [dispatch, dv360AdAccountId, campaign]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const clearHandler = useCallback(() => {
    dispatch(clearCreateLineItem())
  }, [dispatch])

  useEffect(() => {
    if (lineItemWasCreated) {
      dispatch(setMediaOrderUploadCreative({ lineItem: lineItemCreatedData }, DV_360_PLATFORM))
    }
  }, [lineItemCreatedData, lineItemWasCreated, dispatch])

  return (
    <div className={drawerFormClasses.formContainer}>
      <DrawerHeadline
        activeStepNumber={1}
        customStepsLength={4}
        title="Set the campaign dates"
        description={'Please enter the start and end dates for the campaign'}
      />
      <Form
        formName="campaignFormContent"
        formik={purifiedFormik}
        submitText="Save and continue"
        successSubmit={lineItemWasCreated}
        onSuccessSubmit={onSuccessSubmit}
        errorSelector={lineItemCreateErrorSelector}
        isLoadingSelector={lineItemCreateIsLoadingSelector}
        clearHandler={clearHandler}
      >
        <FieldsSection title="Campaign period">
          <FieldRow title="Campaigns dates" description="Start and end date">
            <StartEndDateRangeFields
              formik={purifiedFormik}
              startDateName={START_DATE}
              endDateName={END_DATE}
              minDate={new Date()}
            />
          </FieldRow>
        </FieldsSection>
      </Form>
    </div>
  )
}

export default LineItemForm
