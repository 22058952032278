import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import MultipleCheckboxesSelect from '../../../../components/MultipleCheckboxesSelect'

import { formatOptionsList } from '../../../formatters'

import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import { clearProducts, getProducts } from '../../../../modules/actions/mediaOrdersProducts'
import { productsSelector } from '../../../../modules/selectors/mediaOrdersProducts'

import useStyles from './styles'

const MultipleMediaProductsFilter = ({ Context }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const selfAccountControllerId = useSelector(selectedControllerIdSelector)

  const products = useSelector(productsSelector)

  const { selectedProducts, setSelectedProducts } = useContext(Context)

  const mediaProductsParams = useMemo(
    () => ({
      controller: selfAccountControllerId,
      ordering: 'order'
    }),
    [selfAccountControllerId]
  )

  const formattedProductsOptions = useMemo(
    () =>
      formatOptionsList({
        list: products,
        valueName: 'id',
        labelName: 'name'
      }),
    [products]
  )

  const selectProductHandler = useCallback(
    data => {
      setSelectedProducts(data)
    },
    [setSelectedProducts]
  )

  useEffect(() => {
    dispatch(getProducts(mediaProductsParams))
  }, [dispatch, mediaProductsParams])

  useEffect(
    () => () => {
      dispatch(clearProducts())
    },
    [dispatch]
  )

  return (
    <MultipleCheckboxesSelect
      className={classes.productFilter}
      options={formattedProductsOptions}
      value={selectedProducts}
      onChange={selectProductHandler}
      placeholder="Product"
      multipleOptionsLabel="Product"
      isSearchable={true}
    />
  )
}

MultipleMediaProductsFilter.propTypes = {
  Context: PropTypes.object.isRequired
}

export default MultipleMediaProductsFilter
