import { createStyles } from '../../../../../styles/helpers'
import { textGrey } from '../../../../../styles/const/colors'

const useStyles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  formHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24
  },
  formHeaderText: {
    color: textGrey,
    fontSize: 20,
    fontWeight: 600,
    marginRight: 12
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24
  },
  totalContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 48,
    marginTop: 24
  },
  totalText: {
    color: textGrey,
    fontSize: 20,
    fontWeight: 600,
    marginRight: 12
  },
  total: {
    color: textGrey,
    fontSize: 20,
    fontWeight: 600
  }
})

export default useStyles
