import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import ProgressButton from '../../../../../components/Form/ProgressButton'

import { submitAmendment, clearSubmitAmendment, clearActiveAmendment } from '../../../../../modules/actions/amendments'
import {
  submitAmendmentErrorSelector,
  submitAmendmentIsLoadingSelector,
  submitAmendmentWasLoadedSelector
} from '../../../../../modules/selectors/amendments'

import useStyles from './styles'

const AmendmentSubmitButton = ({ contractId, tokenParams, onSuccessSubmit }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  const submitAmendmentError = useSelector(submitAmendmentErrorSelector)
  const submitAmendmentIsLoading = useSelector(submitAmendmentIsLoadingSelector)
  const submitAmendmentWasCreated = useSelector(submitAmendmentWasLoadedSelector)

  const onSubmit = useCallback(() => {
    dispatch(submitAmendment(contractId, tokenParams))
  }, [dispatch, contractId, tokenParams])

  const clearSubmitAmendmentHandler = useCallback(() => {
    dispatch(clearSubmitAmendment())
  }, [dispatch])

  const handleSuccessSubmitAmendment = useCallback(() => {
    dispatch(clearActiveAmendment())
    onSuccessSubmit && onSuccessSubmit()
  }, [dispatch, onSuccessSubmit])

  return (
    <ProgressButton
      onClick={onSubmit}
      successSubmit={submitAmendmentWasCreated}
      isFormLoading={submitAmendmentIsLoading}
      formError={submitAmendmentError}
      clearHandler={clearSubmitAmendmentHandler}
      onSuccessSubmit={handleSuccessSubmitAmendment}
      wrapperClassName={classes.confirmButtonWrapper}
    >
      {t('Confirm and Update Booking')}
    </ProgressButton>
  )
}

AmendmentSubmitButton.propTypes = {
  contractId: PropTypes.number.isRequired,
  tokenParams: PropTypes.object,
  onSuccessSubmit: PropTypes.func
}

export default AmendmentSubmitButton
