import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ButtonClose from '../Buttons/ButtonClose'

import useStyles from './styles'

const MediaThumbnail = React.memo(({ media, mediaType, className, onMediaClick, onRemoveClick, thumbnailUrl }) => {
  const width = media?.width
  const height = media?.height

  const classes = useStyles(onMediaClick)

  const handleMediaClick = useCallback(() => {
    onMediaClick && onMediaClick(media)
  }, [media, onMediaClick])

  const handleRemoveClick = useCallback(
    e => {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
      onRemoveClick && onRemoveClick()
    },
    [onRemoveClick]
  )

  return (
    <div
      className={classnames(classes.media, className)}
      onClick={handleMediaClick}
      style={{ backgroundImage: `url(${thumbnailUrl})` }}
    >
      {onRemoveClick ? (
        <ButtonClose className={classes.deleteBtn} onClick={handleRemoveClick} />
      ) : (
        mediaType === 'image' && (
          <div className={classes.overlay}>
            {width}x{height}
          </div>
        )
      )}
    </div>
  )
})

MediaThumbnail.propTypes = {
  media: PropTypes.object,
  mediaType: PropTypes.string,
  className: PropTypes.string,
  onMediaClick: PropTypes.func,
  onRemoveClick: PropTypes.func
}

export default MediaThumbnail
