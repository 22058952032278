import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import SuccessModal from './SuccessModal'

import { createJsonFromQueryString } from '../../../helpers/url'

import { openForm } from '../../../modules/actions/forms'
import { selectedPlatformSelector } from '../../../modules/selectors/app'

import {
  ASSET_GROUP_GOOGLE_ADS_CREATE,
  LINE_ITEM_FACEBOOK_CREATE,
  LINE_ITEM_GOOGLE_ADS_CREATE,
  LINE_ITEM_TIKTOK_CREATE
} from '../../../constants/forms'
import {
  DV_360_PLATFORM,
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  TIKTOK_PLATFORM
} from '../../../constants/selectLists/platformList'
import { CAMPAIGN_GOOGLE_PERFORMANCE_MAX_TYPE } from '../../../constants/campaigns'
import { MODAL_TYPE } from '../../../constants/other'
import { lineItemNameByPlatform } from '../../../constants/lineItems'

function NewCampaignCreatedModal() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const selectedPlatform = useSelector(selectedPlatformSelector)

  const { search } = useLocation()
  const { advertisingChanelType } = createJsonFromQueryString(search)

  const openLineItemFacebookCreateForm = useCallback(() => {
    dispatch(openForm({ formName: LINE_ITEM_FACEBOOK_CREATE }))
  }, [dispatch])

  const openLineItemGoogleAdsCreateForm = useCallback(() => {
    dispatch(openForm({ formName: LINE_ITEM_GOOGLE_ADS_CREATE }))
  }, [dispatch])

  const openAssetGroupGoogleAdsCreateForm = useCallback(() => {
    dispatch(openForm({ formName: ASSET_GROUP_GOOGLE_ADS_CREATE }))
  }, [dispatch])

  const openLineItemTikTokCreateForm = useCallback(() => {
    dispatch(openForm({ formName: LINE_ITEM_TIKTOK_CREATE }))
  }, [dispatch])

  const campaignCreatedReusableParagraph = useMemo(() => <p>{t('Your campaign was created successfully.')}</p>, [t])

  const getCampaignCreatedMessage = useCallback(
    ({ platform } = {}) => {
      const article = platform === DV_360_PLATFORM ? 'a' : 'an'
      return (
        <>
          {campaignCreatedReusableParagraph}
          <p>
            {t(
              `The next step is to create ${article} ${lineItemNameByPlatform[
                platform
              ].toLowerCase()}, which will define when, where and who to show the ads.`
            )}
          </p>
        </>
      )
    },
    [t, campaignCreatedReusableParagraph]
  )

  const performanceMaxCampaignGoogleAdsCreatedMessage = useMemo(
    () => (
      <>
        {campaignCreatedReusableParagraph}
        <p>{t('The next step is to create an ad, where you will define what images and text to show.')}</p>
      </>
    ),
    [t, campaignCreatedReusableParagraph]
  )

  const modalProps = useMemo(() => {
    switch (selectedPlatform) {
      case GOOGLE_PLATFORM:
        return Number(advertisingChanelType) === CAMPAIGN_GOOGLE_PERFORMANCE_MAX_TYPE
          ? {
              successButtonAction: openAssetGroupGoogleAdsCreateForm,
              successButtonText: 'Create Ad',
              children: performanceMaxCampaignGoogleAdsCreatedMessage
            }
          : {
              successButtonAction: openLineItemGoogleAdsCreateForm,
              successButtonText: 'Create Ad Group',
              children: getCampaignCreatedMessage({ platform: GOOGLE_PLATFORM })
            }

      case TIKTOK_PLATFORM:
        return {
          successButtonAction: openLineItemTikTokCreateForm,
          successButtonText: 'Create Ad Group',
          children: getCampaignCreatedMessage({ platform: TIKTOK_PLATFORM })
        }

      case FACEBOOK_PLATFORM:
      default:
        return {
          successButtonAction: openLineItemFacebookCreateForm,
          successButtonText: 'Create Ad Set',
          children: getCampaignCreatedMessage({ platform: FACEBOOK_PLATFORM })
        }
    }
  }, [
    selectedPlatform,
    getCampaignCreatedMessage,
    openLineItemGoogleAdsCreateForm,
    openLineItemFacebookCreateForm,
    openLineItemTikTokCreateForm,
    advertisingChanelType,
    performanceMaxCampaignGoogleAdsCreatedMessage,
    openAssetGroupGoogleAdsCreateForm
  ])

  return <SuccessModal modalType={MODAL_TYPE.newCampaignCreated} {...modalProps} />
}

export default NewCampaignCreatedModal
