import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import FileDetailsPreview from '../../../../../../../../../../components/FileDetailsPreview'
import DropFileUploaderProgress from '../../../../../../../../../../components/Form/DropFileUploader/DropFileUploaderProgress'
import ProgressBar from '../../../../../../../../../../components/ProgressBar'

import { getFileFormat, getFileType } from '../../../../../../../../../../features/helpers/other'

const FilePreviewOrIsLoading = ({
  showPreview,
  selectedFile,
  fileURLBlob,
  handleRemoveFile,
  isDragAndDrop,
  isFileLoading,
  fileName,
  fileURL,
  fileUploadProgress
}) => {
  const filePreviewType = useMemo(() => selectedFile.type && getFileType(selectedFile), [selectedFile])
  const fileFormat = useMemo(() => selectedFile.name && getFileFormat(selectedFile), [selectedFile])

  if (showPreview) {
    return (
      <FileDetailsPreview
        key={selectedFile.name || fileName}
        fileName={selectedFile.name || fileName}
        isFileLoading={isFileLoading}
        fileUploadProgress={fileUploadProgress}
        previewUrl={fileURLBlob || fileURL}
        allowDelete={!!fileURL}
        fileFormat={fileFormat}
        filePreviewType={filePreviewType}
        removeFile={handleRemoveFile}
      />
    )
  }

  return isDragAndDrop ? (
    <DropFileUploaderProgress fileUploadProgress={fileUploadProgress} />
  ) : (
    <ProgressBar value={fileUploadProgress || 0} />
  )
}

FilePreviewOrIsLoading.propTypes = {
  showPreview: PropTypes.bool.isRequired,
  selectedFile: PropTypes.object,
  fileURLBlob: PropTypes.string,
  handleRemoveFile: PropTypes.func,
  isDragAndDrop: PropTypes.bool,
  isFileLoading: PropTypes.bool,
  fileName: PropTypes.string,
  fileURL: PropTypes.string,
  fileUploadProgress: PropTypes.number
}

export default FilePreviewOrIsLoading
