import { createStyles } from '../../../../../../../../styles/helpers'

import { inputHeight } from '../../../../../../../../styles/common/components'
import { greyOutline } from '../../../../../../../../styles/const/colors'

const useStyles = createStyles(theme => ({
  fieldsRow: {
    display: 'flex',
    rowGap: 8,
    columnGap: 8,
    '& .field': {
      marginTop: 0
    }
  },
  deleteRowButton: {
    // Negative margin is workaround to give second field in row more space
    marginRight: -10,
    marginLeft: -2,
    // Max height fixes wrong positions when field has error
    maxHeight: inputHeight,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& path': {
      fill: greyOutline
    }
  }
}))

export default useStyles
