import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import TableDataLoader from '../../../../components/Table/TableDataLoader'
import LocationsTableData from './LocationsTableData'

import {
  locationListsLoadingSelector,
  locationListsErrorSelector,
  locationListsWasLoadedSelector
} from '../../../../modules/selectors/location'

import { phonesDownSize } from '../../../../styles/const/breakpoints'

import { mobileOneLineColumnsSchema } from '../../../../constants/other'

import useCommonStyles from '../../../../styles/common/table'

export const tableColumnsSize = {
  listType: 143,
  size: 120,
  actions: 40
}

const LocationsTable = ({ locations }) => {
  const { t } = useTranslation()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const commonClasses = useCommonStyles()

  const locationsColumnsSchema = useMemo(
    () => [
      {
        header: 'Name'
      },
      {
        header: 'List Type',
        style: { maxWidth: tableColumnsSize.listType }
      },
      {
        header: 'No. of Locations',
        style: { maxWidth: tableColumnsSize.size }
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no locations')}</div>
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <TableDataLoader
      itemsLength={locations.length}
      errorSelector={locationListsErrorSelector}
      wasLoadedSelector={locationListsWasLoadedSelector}
      isLoadingSelector={locationListsLoadingSelector}
      skeletonProps={{ cols: isMobile ? mobileOneLineColumnsSchema : locationsColumnsSchema }}
      noDataContent={noDataContent}
    >
      <LocationsTableData locations={locations} />
    </TableDataLoader>
  )
}

LocationsTable.propTypes = {
  locations: PropTypes.array.isRequired
}

export default LocationsTable
