import { concat } from '../../helpers/common'

const MODULE_NAME = 'AMENDMENTS/'

// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_AMENDMENT = concat(MODULE_NAME, 'GET_AMENDMENT')
export const GET_AMENDMENT_SUCCESS = concat(MODULE_NAME, 'GET_AMENDMENT_SUCCESS')
export const GET_AMENDMENT_FAILURE = concat(MODULE_NAME, 'GET_AMENDMENT_FAILURE')
export const CLEAR_GET_AMENDMENT = concat(MODULE_NAME, 'CLEAR_GET_AMENDMENT')

export const CLEAR_ACTIVE_AMENDMENT = concat(MODULE_NAME, 'CLEAR_ACTIVE_AMENDMENT')

export const GET_AMENDMENTS_LIST = concat(MODULE_NAME, 'GET_AMENDMENTS_LIST')
export const GET_AMENDMENTS_LIST_SUCCESS = concat(MODULE_NAME, 'GET_AMENDMENTS_LIST_SUCCESS')
export const GET_AMENDMENTS_LIST_FAILURE = concat(MODULE_NAME, 'GET_AMENDMENTS_LIST_FAILURE')
export const CLEAR_GET_AMENDMENTS_LIST = concat(MODULE_NAME, 'CLEAR_GET_AMENDMENTS_LIST')

export const SEND_AMENDMENT_CONTRACT = concat(MODULE_NAME, 'SEND_AMENDMENT_CONTRACT')
export const SEND_AMENDMENT_CONTRACT_SUCCESS = concat(MODULE_NAME, 'SEND_AMENDMENT_CONTRACT_SUCCESS')
export const SEND_AMENDMENT_CONTRACT_FAILURE = concat(MODULE_NAME, 'SEND_AMENDMENT_CONTRACT_FAILURE')
export const CLEAR_SEND_AMENDMENT_CONTRACT = concat(MODULE_NAME, 'CLEAR_SEND_AMENDMENT_CONTRACT')

export const DELETE_AMENDMENT_CONTRACT_SIGNATURE = concat(MODULE_NAME, 'DELETE_AMENDMENT_CONTRACT_SIGNATURE')
export const DELETE_AMENDMENT_CONTRACT_SIGNATURE_SUCCESS = concat(
  MODULE_NAME,
  'DELETE_AMENDMENT_CONTRACT_SIGNATURE_SUCCESS'
)
export const DELETE_AMENDMENT_CONTRACT_SIGNATURE_FAILURE = concat(
  MODULE_NAME,
  'DELETE_AMENDMENT_CONTRACT_SIGNATURE_FAILURE'
)
export const CLEAR_DELETE_AMENDMENT_CONTRACT_SIGNATURE = concat(
  MODULE_NAME,
  'CLEAR_DELETE_AMENDMENT_CONTRACT_SIGNATURE'
)

export const SIGN_AMENDMENT_CONTRACT = concat(MODULE_NAME, 'SIGN_AMENDMENT_CONTRACT')
export const SIGN_AMENDMENT_CONTRACT_SUCCESS = concat(MODULE_NAME, 'SIGN_AMENDMENT_CONTRACT_SUCCESS')
export const SIGN_AMENDMENT_CONTRACT_FAILURE = concat(MODULE_NAME, 'SIGN_AMENDMENT_CONTRACT_FAILURE')
export const CLEAR_SIGN_AMENDMENT_CONTRACT = concat(MODULE_NAME, 'CLEAR_SIGN_AMENDMENT_CONTRACT')

export const CREATE_AMENDMENT = concat(MODULE_NAME, 'CREATE_AMENDMENT')
export const CREATE_AMENDMENT_SUCCESS = concat(MODULE_NAME, 'CREATE_AMENDMENT_SUCCESS')
export const CREATE_AMENDMENT_FAILURE = concat(MODULE_NAME, 'CREATE_AMENDMENT_FAILURE')
export const CLEAR_CREATE_AMENDMENT = concat(MODULE_NAME, 'CLEAR_CREATE_AMENDMENT')

export const SUBMIT_AMENDMENT = concat(MODULE_NAME, 'SUBMIT_AMENDMENT')
export const SUBMIT_AMENDMENT_SUCCESS = concat(MODULE_NAME, 'SUBMIT_AMENDMENT_SUCCESS')
export const SUBMIT_AMENDMENT_FAILURE = concat(MODULE_NAME, 'SUBMIT_AMENDMENT_FAILURE')
export const CLEAR_SUBMIT_AMENDMENT = concat(MODULE_NAME, 'CLEAR_SUBMIT_AMENDMENT')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// Get amendment
export function getAmendment(id, params) {
  return { type: GET_AMENDMENT, id, params }
}

export function getAmendmentSuccess(data) {
  return { type: GET_AMENDMENT_SUCCESS, data }
}

export function getAmendmentFailure(error) {
  return { type: GET_AMENDMENT_FAILURE, error }
}

export function clearGetAmendment() {
  return { type: CLEAR_GET_AMENDMENT }
}

export function clearActiveAmendment() {
  return { type: CLEAR_ACTIVE_AMENDMENT }
}

// Get amendments list
export function getAmendmentsList(params) {
  return { type: GET_AMENDMENTS_LIST, params }
}

export function getAmendmentsListSuccess(data) {
  return { type: GET_AMENDMENTS_LIST_SUCCESS, data }
}

export function getAmendmentsListFailure(error) {
  return { type: GET_AMENDMENTS_LIST_FAILURE, error }
}

export function clearGetAmendmentsList() {
  return { type: CLEAR_GET_AMENDMENTS_LIST }
}

// Create amendment
export function createAmendment(data) {
  return { type: CREATE_AMENDMENT, data }
}

export function createAmendmentSuccess(data) {
  return { type: CREATE_AMENDMENT_SUCCESS, data }
}

export function createAmendmentFailure(error) {
  return { type: CREATE_AMENDMENT_FAILURE, error }
}

export function clearCreateAmendment() {
  return { type: CLEAR_CREATE_AMENDMENT }
}

// Sign amendment contract
export function signAmendmentContract(data) {
  return { type: SIGN_AMENDMENT_CONTRACT, data }
}

export function signAmendmentContractSuccess(data) {
  return { type: SIGN_AMENDMENT_CONTRACT_SUCCESS, data }
}

export function signAmendmentContractFailure(error) {
  return { type: SIGN_AMENDMENT_CONTRACT_FAILURE, error }
}

export function clearSignAmendmentContract() {
  return { type: CLEAR_SIGN_AMENDMENT_CONTRACT }
}

// Delete amendment contract signature
export function deleteAmendmentContractSignature(data) {
  return { type: DELETE_AMENDMENT_CONTRACT_SIGNATURE, data }
}

export function deleteAmendmentContractSignatureSuccess(data) {
  return { type: DELETE_AMENDMENT_CONTRACT_SIGNATURE_SUCCESS, data }
}

export function deleteAmendmentContractSignatureFailure(error) {
  return { type: DELETE_AMENDMENT_CONTRACT_SIGNATURE_FAILURE, error }
}

export function clearDeleteAmendmentContractSignature() {
  return { type: CLEAR_DELETE_AMENDMENT_CONTRACT_SIGNATURE }
}

// Send amendment contract
export function sendAmendmentContract(id, data) {
  return { type: SEND_AMENDMENT_CONTRACT, id, data }
}

export function sendAmendmentContractSuccess(data) {
  return { type: SEND_AMENDMENT_CONTRACT_SUCCESS, data }
}

export function sendAmendmentContractFailure(error) {
  return { type: SEND_AMENDMENT_CONTRACT_FAILURE, error }
}

export function clearSendAmendmentContract() {
  return { type: CLEAR_SEND_AMENDMENT_CONTRACT }
}

// Submit amendment
export function submitAmendment(id, params) {
  return { type: SUBMIT_AMENDMENT, id, params }
}

export function submitAmendmentSuccess(id) {
  return { type: SUBMIT_AMENDMENT_SUCCESS, id }
}

export function submitAmendmentFailure(error) {
  return { type: SUBMIT_AMENDMENT_FAILURE, error }
}

export function clearSubmitAmendment() {
  return { type: CLEAR_SUBMIT_AMENDMENT }
}
