import React from 'react'

import SkeletonGoogleAssetsSliderPreview from '../../../../../../../forms/Google/AdForms/GoogleAssetsSliderPreview/Skeleton'

import useStyles from '../../../../../../../styles/common/adPreviewCard'

const GoogleAdCardSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.adPreviewCard}>
      <div className={classes.cardSliderPreviewWrapper}>
        <SkeletonGoogleAssetsSliderPreview />
      </div>
    </div>
  )
}

export default GoogleAdCardSkeleton
