import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'
import { differenceInMonths } from 'date-fns'

import CalendarRows from './CalendarRows'
import CalendarTableHeader from './CalendarTableHeader'

import { useWindowSize } from '../../../hooks/useWindowSize'
import { ProductsCalendarContext } from './ProductsCalendarContext'

import { getTitleCols } from '../../../features/components/Calendar/CalendarTable'
import { getSubtitleMonthBasedCols } from '../../../features/components/Calendar/CalendarTable/year'
import { getQuarterSubtitleCols } from '../../../features/components/Calendar/CalendarTable/quarter'
import { getSubtitleDaysColumns } from '../../../features/components/Calendar/CalendarTable/month'

import { ID_COL_WIDTH, NAME_COL_WIDTH } from './constatns'
import { EVENTS_AREA_PADDING } from '../../../constants/timeline'
import { CALENDAR_VIEW_TYPE } from '../../../constants/selectLists/calendarList'

import useStyles from './styles'

const ProductsCalendarPreview = ({ calendar }) => {
  const classes = useStyles()

  const { startDate, endDate, viewType } = useContext(ProductsCalendarContext)

  const tableRef = useRef(null)
  const [width] = useWindowSize()
  const [tableWidth, setTableWidth] = useState(1000)

  const tableElementWidth = tableRef.current && tableRef.current.offsetWidth

  useEffect(() => {
    // change table width on window width change
    if (!tableRef.current) return
    setTableWidth(tableElementWidth)
  }, [width, tableElementWidth])

  const getSubtitleCols = (viewType, startDate, endDate) => {
    switch (viewType) {
      case CALENDAR_VIEW_TYPE.YEAR:
        const monthsCount = differenceInMonths(endDate, startDate) + 1
        return getSubtitleMonthBasedCols(startDate, monthsCount)
      case CALENDAR_VIEW_TYPE.QUARTER:
        return getQuarterSubtitleCols(startDate, endDate)
      case CALENDAR_VIEW_TYPE.MONTH:
        return getSubtitleDaysColumns(startDate, endDate)
      default:
        throw new Error(t('unsupportedViewType', { viewType }))
    }
  }

  const [titleCols, subtitleCols] = useMemo(() => {
    return [getTitleCols(viewType, startDate, endDate), getSubtitleCols(viewType, startDate, endDate)]

    // there is no need to add selectedViewType as changing selectedViewType will update dates so dates and selectedViewType will always be in sync here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  const tableInnerRowWidth = tableWidth - NAME_COL_WIDTH - ID_COL_WIDTH - 2 * EVENTS_AREA_PADDING

  return (
    <div id="so-calendar" className={classes.root}>
      <table className={classes.tableContainer} ref={tableRef}>
        <CalendarTableHeader titleCols={titleCols} subtitleCols={subtitleCols} />
        <CalendarRows
          products={calendar.products}
          selectedViewType={viewType}
          colsCount={subtitleCols.length}
          tableInnerRowWidth={tableInnerRowWidth}
          startDate={startDate}
          endDate={endDate}
        />
      </table>
    </div>
  )
}

ProductsCalendarPreview.propTypes = {
  calendar: PropTypes.object
}

export default ProductsCalendarPreview
