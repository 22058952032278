import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles({
  library: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0
  },
  infoBlockLibrary: {
    marginTop: 32,
    paddingBottom: 16
  }
})

export default useStyles
