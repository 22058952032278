import React, { cloneElement, useCallback, useMemo, useRef } from 'react'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTheme } from 'react-jss'

import BackButton from '../BackButton'
import StepFormSubmit from '../StepFormSubmit'
import Form from '../../../../../components/Form'

import useStepForm from '../hooks/useStepForm'
import useSuccessFormSubmit from '../../../../../components/Form/hooks'

import { keys } from '../../../../../helpers/common'
import { getStepWithError } from '../../../../../components/Form/helpers'

import { activeStepSelector } from '../../../../../modules/selectors/forms'

import useStyles from '../style'
import useCommonStyles from '../../../../../styles/common/stepForms'

const StepFormContent = ({
  steps,
  formik,
  formName,
  successSubmit,
  clearSubmitHandler,
  formProgressItems,
  // automatically place the submit button on last step
  submitOnLastStep = true,
  isLoadingSelector,
  isSubmitDataLoading,
  errorSelector,
  submitText,
  onSuccessSubmit,
  stepProps,
  onBackButtonClick,
  className,
  ...props
}) => {
  const activeStep = useSelector(activeStepSelector)
  const stepsContainerEl = useRef(null)

  const { resetForm, touched, setTouched, errors } = formik
  // for useStepForm purified stepFormik is used to avoid unnecessary functions changes on original formik change
  const stepFormik = useMemo(() => ({ touched, setTouched, errors }), [touched, setTouched, errors])

  const { changeStep, backStep, resetSteps, handleStepChange } = useStepForm({
    // todo saved form data functionality is disabled till post MVP
    // initialStep: savedLineItemFacebookForm.step,
    stepFormik,
    stepsContainerEl,
    formName,
    onBackButtonClick
  })

  const classes = useStyles({ activeStep, isFormProgressExist: !!formProgressItems })
  const commonClasses = useCommonStyles()
  const theme = useTheme()

  const visibleSteps = useMemo(() => steps.filter(step => step.show), [steps])

  const getComponentProps = (component, index, isLastStep) => {
    // populate component with props during the rendering
    return cloneElement(component, {
      key: index,
      isActive: activeStep === index,
      isLastStep,
      formik,
      handleStepChange,
      stepsContainerEl,
      ...(stepProps && stepProps)
    })
  }

  const handleStepFormErrorSubmit = useCallback(
    error => {
      // handle StepForm errors - focus error field Step in the view
      const errorFields = keys(error.errors)
      const scrollToStep = getStepWithError(error.errors, errorFields)

      if (scrollToStep !== null && scrollToStep >= 0) {
        // push user to found step if it's found
        changeStep(scrollToStep)
      }
    },
    [changeStep]
  )

  const handleStepFormSuccessSubmit = useCallback(() => {
    resetSteps()
    onSuccessSubmit && onSuccessSubmit()
  }, [resetSteps, onSuccessSubmit])

  const handleSuccessSubmit = useSuccessFormSubmit({
    onSuccessSubmit: handleStepFormSuccessSubmit,
    formName,
    resetForm
  })

  // todo update after research this topic. This version has some bugs,
  //  like working only on create campaign form, history.goBack() only in Chrome
  // const onBackButtonEvent = useCallback(() => {
  //   if (activeStep !== 0) {
  //     window.history.pushState(null, '', null)
  //     changeStep(activeStep - 1)
  //   } else {
  //     // if activeStep is 0 - redirect to previous URL
  //     history.goBack()
  //   }
  // }, [activeStep, changeStep, history])
  //
  // useEffect(() => {
  //   // prevent back button and move user to previous question on clicking it
  //   window.history.pushState(null, '', null)
  //   window.addEventListener('popstate', onBackButtonEvent)
  //
  //   return () => window.removeEventListener('popstate', onBackButtonEvent)
  // }, [onBackButtonEvent])

  // steps container spacing to represent active step
  // added here instead of the classes because classes sometimes not update with JSS
  const shownStepSpacing = useMemo(() => activeStep * (theme.textDirection === 'ltr' ? -100 : 100), [activeStep, theme])

  return (
    <Form
      formName={formName}
      formik={formik}
      className={classnames(classes.form, className)}
      onErrorSubmit={handleStepFormErrorSubmit}
      successSubmit={successSubmit}
      showSubmit={false}
      isLoadingSelector={isLoadingSelector}
      errorSelector={errorSelector}
      clearHandler={clearSubmitHandler}
      activeStep={activeStep}
      {...props}
    >
      <div className={classes.steps} ref={stepsContainerEl} style={{ transform: `translateX(${shownStepSpacing}%)` }}>
        {visibleSteps.map(({ component, requireMinHeight, hideBackButton, key }, index) => {
          const isLastStep = visibleSteps.length - 1 === index

          return (
            // data-step attribute is required here!
            // it's used for tracking field's step at the BE error stage
            <div
              key={key || index}
              className={classnames(classes.stepItem, {
                requireMinHeight
              })}
              data-step={index}
            >
              {getComponentProps(component, index, isLastStep)}
              {submitOnLastStep && isLastStep && (
                <div className={commonClasses.stepFooter}>
                  {/* it's recommended to keep the submit button within the form logic. Also it allows to keep steps
                     component logic simple and avoid selector usage duplications */}
                  <StepFormSubmit
                    successSubmit={successSubmit}
                    isLoadingSelector={isLoadingSelector}
                    errorSelector={errorSelector}
                    clearHandler={clearSubmitHandler}
                    onSuccessSubmit={handleSuccessSubmit}
                    submitText={submitText}
                    isSubmitDataLoading={isSubmitDataLoading}
                  />
                </div>
              )}
              {!hideBackButton && <BackButton onStepBack={backStep} activeStep={activeStep} />}
            </div>
          )
        })}
      </div>
    </Form>
  )
}

StepFormContent.propTypes = {
  steps: PropTypes.array,
  formName: PropTypes.string.isRequired,
  formik: PropTypes.object,
  submitText: PropTypes.string,
  formProgressItems: PropTypes.array,
  isInitialDataLoading: PropTypes.bool,
  isSubmitDataLoading: PropTypes.bool,
  successSubmit: PropTypes.bool,
  submitOnLastStep: PropTypes.bool,
  sideColumnContent: PropTypes.node,
  isLoadingSelector: PropTypes.func,
  errorSelector: PropTypes.func,
  onSuccessSubmit: PropTypes.func,
  stepProps: PropTypes.object,
  className: PropTypes.string,
  onBackButtonClick: PropTypes.func
}

export default StepFormContent
