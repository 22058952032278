import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import useStyles from './styles'

const FormField = ({ label, children, isLastFormField, className }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div
      className={classnames(classes.formField, className, {
        isLastFormField
      })}
    >
      <p className={classes.formFieldLabel}>{t(label)}</p>
      <div className={classes.childrenWrapper}>{children}</div>
    </div>
  )
}

FormField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default FormField
