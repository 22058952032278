let buffer = {}
let allowUpdate = {}

// function closeOpenedBrackets(text) {
// todo remove this function once test in production done
//   // allows to automatically close opened brackets and quotes
//   var specialSymbols = ['"', '{', '[', '}', ']']
//   var count = {}
//
//   for (var i = 0; i < text.length; i++) {
//     var char = text.charAt(i)
//     if (specialSymbols.includes(char)) {
//       if (count[char]) {
//         count[char]++
//       } else {
//         count[char] = 1
//       }
//     }
//   }
//
//   var diff = ''
//   if ((count['"'] || 0) % 2 === 1) {
//     diff += '"'
//   }
//   if ((count['['] || 0) > (count[']'] || 0)) {
//     diff += ']'.repeat((count['['] || 0) - (count[']'] || 0))
//   }
//   if ((count['{'] || 0) > (count['}'] || 0)) {
//     diff += '}'.repeat((count['{'] || 0) - (count['}'] || 0))
//   }
//
//   return diff
// }

function getOpenedBracketsOrQuotes(str) {
  const stack = []
  const map = {
    '{': '}',
    '[': ']',
    '"': '"'
  }

  for (let i = 0; i < str.length; i++) {
    if (str[i] === '"') {
      if (stack[stack.length - 1] === '"') {
        stack.pop()
      } else {
        stack.push(str[i])
      }
    } else if (str[i] === '{' || str[i] === '[') {
      stack.push(str[i])
    } else if (str[i] === '}' || str[i] === ']') {
      let last = stack.pop()
      if (str[i] !== map[last]) {
        return []
      }
    }
  }
  return stack
}

function closeBracketsOrQuotes(openBracketsOrQuotes) {
  let map = {
    '"': '"',
    '{': '}',
    '[': ']'
  }

  let closingBracketsOrQuotes = openBracketsOrQuotes.reverse().map(bracketOrQuote => map[bracketOrQuote])

  return closingBracketsOrQuotes
}

function fixSyntax(brokenCode) {
  // const find all opened brackets and quotes which are not closed
  const openedBrackets = getOpenedBracketsOrQuotes(brokenCode)
  // find the closing brackets and quotes in the opposite order to the opened ones
  const closedBrackets = closeBracketsOrQuotes(openedBrackets)

  // add missing closing brackets and quotes to the end of the string to make the json valid
  return brokenCode + closedBrackets.join('')
}

function fixJSON(jsonString) {
  // Trim the string to remove extra spaces and newlines
  const trimmedString = jsonString.trim()

  // Add closing double-quote and curly brace to make it valid JSON
  // just a note it doesn't return valid JSON every time
  // but it's enough to the frequency the server sends data to support typing
  return fixSyntax(trimmedString)
}

export function handleIncomingChunk(chunk, requestId) {
  // Buffer the incoming chunks handle initial requestId buffer value
  const bufferValue = buffer[requestId] || ''
  if (buffer[requestId] === undefined) {
    // preset allowUpdate to true if it is a new request
    allowUpdate[requestId] = true
  }
  // Allow update only when it is allowed by setup or when it is a new request
  const allowUpdateData = allowUpdate[requestId]

  buffer[requestId] = bufferValue + chunk
  let tempBuffer = buffer[requestId]

  try {
    if (allowUpdateData) {
      // Parse JSON string, catching any errors - make this with the debouncing help of allowUpdateData
      const validJson = fixJSON(tempBuffer)
      const parsedJson = JSON.parse(validJson)
      // debounce update data with 200ms
      allowUpdate[requestId] = false
      // allow to make update to redux every 200ms
      setTimeout(() => {
        allowUpdate[requestId] = true
      }, 200)

      return parsedJson
    }
  } catch (e) {
    // console.error("Invalid JSON:", e);
  }
}
