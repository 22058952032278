import React from 'react'
import PropTypes from 'prop-types'

import SocialAuthorizeButtons from '../SocialAuthorizeButtons'

import useStyles from './style'

function AuthorizePlatformDetails({ selectedPlatform, explanationText, hasPadding = true, contentAlign = 'center' }) {
  const classes = useStyles({ hasPadding, contentAlign })

  return (
    <div className={classes.details}>
      {explanationText && <p className="info">{explanationText}</p>}
      <SocialAuthorizeButtons selectedPlatform={selectedPlatform} />
    </div>
  )
}

AuthorizePlatformDetails.propTypes = {
  selectedPlatform: PropTypes.string.isRequired,
  explanationText: PropTypes.string,
  // styling pops:
  hasPadding: PropTypes.bool,
  contentAlign: PropTypes.string
}

export default AuthorizePlatformDetails
