import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'
import Skeleton from 'react-loading-skeleton'

import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import MinimisedSectionNew from '../../../../../../../../components/MinimisedSectionNew'
import MinimisedSectionHeader from '../../../../../../../../components/MinimisedSectionNew/MinimisedSectionHeader'
import ProductSetupFields from '../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/ProductSetupStep/ProductSetupFields'
import SubProductsList from '../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/ProductSetupStep/SubProductsList'
import ProductQuantity from '../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/OrderCartStep/PackageBreakdown/ProductBreakdown/ProductQuantity'

import { useDidMount } from '../../../../../../../../hooks/useDidMount'
import useProductGroupSubProducts from '../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/ProductSetupStep/useProductGroupSubProducts'
import { useProductQuantityLimits } from '../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/OrderCartStep/PackageBreakdown/ProductBreakdown/useProductQuantityLimits'

import { PRODUCT_PERIODS_DATES, QUANTITY } from '../../../../../fields'

import useStyles from './styles'

const ProductSetupSection = ({ formik, itemPath, selected, onSelect, onClose, onDelete, currency }) => {
  const classes = useStyles()
  const didMount = useDidMount()

  const selectedProductValues = getIn(formik.values, itemPath)
  const selectedProductData = selectedProductValues?.data
  const isProductGroup = selectedProductData?.group

  const onClickHandler = useCallback(() => {
    if (selected) {
      onClose()
    } else {
      onSelect()
    }
  }, [selected, onSelect, onClose])

  const { values, setFieldValue } = formik

  const productError = getIn(formik.errors, itemPath)
  const productTouched = Boolean(getIn(formik.touched, itemPath))

  const productPeriodsDatesPath = `${itemPath}.${PRODUCT_PERIODS_DATES}`
  const productSelectedPeriods = getIn(values, productPeriodsDatesPath)

  const selectedPeriods = useMemo(() => {
    return getIn(values, productPeriodsDatesPath)
  }, [values, productPeriodsDatesPath])

  const maxProductQuantity = useProductQuantityLimits(productSelectedPeriods || [])
  const selectedSubProducts = useProductGroupSubProducts({ selectedPeriods, isProductGroup })

  const quantityPath = `${itemPath}.${QUANTITY}`
  const productsQuantity = getIn(values, `${itemPath}.${QUANTITY}`)

  useEffect(() => {
    // reset quantity to 1 when user is setting up periods to avoid inconsistency when there are not enough
    // inventory for the selected quantity
    if (didMount) {
      setFieldValue(quantityPath, 1)
    }
  }, [didMount, setFieldValue, productSelectedPeriods, quantityPath])

  const renderProductSetupFields = () => {
    if (!selectedProductData) {
      // represent skeleton until product data is not loaded
      return <Skeleton height={38} />
    }

    if (!selected) {
      // If the section is not opened, do not render the product setup fields.
      // this is done to manage the API calls to getInventory inside the ProductSetupFields only to the selected
      // product, as well as clean it up, once the product is closed
      return null
    }

    return (
      <ProductSetupFields
        formik={formik}
        showProductDetails={false}
        productItemPath={itemPath}
        isActive={selected}
        currency={currency}
        showError={productTouched}
        productErrors={productError}
      />
    )
  }

  return (
    <MinimisedSectionNew
      headerContent={
        <MinimisedSectionHeader className={classes.productSectionHeader} title={selectedProductData?.name || 'Product'}>
          {productsQuantity && (
            <ProductQuantity
              formik={formik}
              className={classes.productQuantity}
              productsQuantity={Number(productsQuantity)}
              productPath={itemPath}
              maxQuantity={maxProductQuantity}
              onProductRemove={onDelete}
            />
          )}
        </MinimisedSectionHeader>
      }
      hasError={productTouched && Boolean(productError)}
      isOpened={selected}
      onHeaderClick={onClickHandler}
    >
      <FieldRow title="Booking period(s)" description="The start and end dates for this media product.">
        {renderProductSetupFields()}
      </FieldRow>
      {isProductGroup && (
        <FieldRow
          title="Group Products"
          description="Any products in red are not available for the selected booking periods and will not be included in this booking."
        >
          <SubProductsList availableProducts={selectedSubProducts} />
        </FieldRow>
      )}
    </MinimisedSectionNew>
  )
}

ProductSetupSection.propTypes = {
  formik: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  itemPath: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default ProductSetupSection
