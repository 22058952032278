import { formatDateWithTimeToBE } from '../../../../../helpers/date'

import { LOCATIONS_SELECTED_TAB, LOCATIONS_TAB, TARGET_BY_REGION, TIME_START, TIME_STOP } from './fields'
import { NAME, PLACEMENT_POSITIONS, TARGET_COST_CAP } from '../../../../ReusableFormFields/LineItemForms/fields'
import { LOCATION_LIST, SHOW_COUNTRIES, SHOW_REGIONS } from '../../../../ReusableFormFields/GeolocationsFields/fields'
import {
  MAX_AGE_CHOICE,
  MIN_AGE_CHOICE,
  SHOW_REGIONS_CITY_TYPE,
  SHOW_REGIONS_REGION_TYPE
} from '../../LineItemFacebookCreateOld/LineItemFacebookCreateFormOld/fields'
import { LANGUAGES } from '../../../../Multiplatform/LineItemForms/fields'
import { GEO_TARGETING } from '../../LineItemFacebookEdit/fields'
import { add, isToday } from 'date-fns'
import {
  formatAudiencesToBE,
  formatGenderToBE,
  formatInterestsToBE
} from '../../LineItemFacebookCreateOld/LineItemFacebookCreateFormOld/formatters'

import { GENDER, GENDER_OPTION } from '../../../../ReusableFormFields/GenderFields/fields'
import {
  CUSTOM_AUDIENCES,
  CUSTOM_AUDIENCES_EXCLUSION,
  CUSTOM_AUDIENCES_EXCLUSION_CHECKED
} from '../../../../ReusableFormFields/CustomAudiencesFields/fields'
import { INTERESTS } from '../../../../ReusableFormFields/InterestsFields/fields'
import { CUSTOM_EVENT_TYPE, PROMOTED_PIXEL } from '../../../../ReusableFormFields/PromotedObjectFields/fields'
import { BUDGET_DAILY, BUDGET_LIFETIME, BUDGET_OPTION } from '../../../../ReusableFormFields/CampaignForms/fields'
import {
  getPlacementsList,
  placementPositionInitialValues,
  placementSafetyRules
} from '../../../../ReusableFormFields/LineItemForms/facebookLineItemPlacementsHelpers'
import { AUTOMATIC } from '../../../../../constants/campaigns'
import { getIsCampaignObjectiveConversionsOrSales } from '../../lineItemsHelpers'

const formatLocationsTab = values => {
  const regions = values[SHOW_REGIONS]

  const filteredRegions = regions.filter(item => item.type === SHOW_REGIONS_REGION_TYPE)
  const filteredCities = regions.filter(item => item.type === SHOW_REGIONS_CITY_TYPE)

  return {
    [GEO_TARGETING]: {
      // locations
      ...(values[TARGET_BY_REGION]
        ? {
            regions: filteredRegions.map(({ value }) => ({
              key: value
            })),
            cities: filteredCities.map(({ value }) => ({
              key: value
            }))
          }
        : {
            // countries
            countries: values[SHOW_COUNTRIES].map(({ code }) => code)
          })
    }
  }
}

const formatLocationsListTab = values => {
  return {
    // location list
    [LOCATION_LIST]: values[LOCATION_LIST]
  }
}

export const formatStartDateToBE = startDate => {
  if (isToday(startDate)) {
    // adding one minute to allow BE process request (start time should be in the future)
    return formatDateWithTimeToBE(add(new Date(), { minutes: 1 }))
  } else {
    return formatDateWithTimeToBE(startDate)
  }
}

export const getSelectedPlacementPositions = values => {
  let selectedPlacementPositions = []

  for (let position in placementPositionInitialValues) {
    if (placementPositionInitialValues.hasOwnProperty(position) && !!values[position]) {
      selectedPlacementPositions.push(position)
    }
  }

  return selectedPlacementPositions
}

export const transformPlacementsToBE = (values, campaignObjective) => {
  const placements = getPlacementsList(campaignObjective)
  const activePlacements = placements.filter(({ disabled }) => disabled === false)

  const isAllActivePlacementsChecked = activePlacements.every(placement => !!values[placement.name])

  return {
    ...(isAllActivePlacementsChecked
      ? // if all active placements are checked, we don't need to send placements to BE
        {}
      : {
          [PLACEMENT_POSITIONS]: getSelectedPlacementPositions(values)
        })
  }
}

export const transformValuesToBE = ({
  values,
  selectedAdAccountId,
  showPromotedObjectFields,
  showTargetCostCapField,
  includeBudget,
  campaign
}) => {
  const { id: campaignId, objective: campaignObjective } = campaign

  const audiences = values[CUSTOM_AUDIENCES]
  const exclusionAudiences = values[CUSTOM_AUDIENCES_EXCLUSION]
  const interests = values[INTERESTS]
  const languages = values[LANGUAGES]

  const isLifetimeBudget = values[BUDGET_OPTION] === BUDGET_LIFETIME
  const isDailyBudget = values[BUDGET_OPTION] === BUDGET_DAILY

  const isCampaignObjectiveConversionsOrSales = getIsCampaignObjectiveConversionsOrSales(campaignObjective)

  return {
    billing_event: 'impressions',

    account: selectedAdAccountId,
    campaign_id: campaignId,
    campaign_objective: campaignObjective,
    [NAME]: values[NAME],
    [TIME_START]: formatStartDateToBE(values[TIME_START]),
    [TIME_STOP]: formatDateWithTimeToBE(values[TIME_STOP]),

    ...(!isCampaignObjectiveConversionsOrSales && { bidding_type: AUTOMATIC }),

    // budget
    ...(includeBudget && isLifetimeBudget && { budget_lifetime: values[BUDGET_LIFETIME] }),
    ...(includeBudget && isDailyBudget && { budget_daily: values[BUDGET_DAILY] }),

    // locations tabs
    ...(values[LOCATIONS_SELECTED_TAB] === LOCATIONS_TAB ? formatLocationsTab(values) : formatLocationsListTab(values)),
    // languages
    ...(languages?.length && { languages: languages.map(({ value }) => value) }),
    // audiences
    ...(audiences?.length && { [CUSTOM_AUDIENCES]: formatAudiencesToBE(audiences) }),
    ...(values[CUSTOM_AUDIENCES_EXCLUSION_CHECKED] && {
      custom_audiences_exclusions: formatAudiencesToBE(exclusionAudiences)
    }),
    // interests
    ...(interests?.length && { interests: formatInterestsToBE(interests) }),
    // gender
    [GENDER]: formatGenderToBE(values[GENDER_OPTION]),
    // age
    age: {
      [MIN_AGE_CHOICE]: values[MIN_AGE_CHOICE],
      [MAX_AGE_CHOICE]: values[MAX_AGE_CHOICE]
    },
    ...(showPromotedObjectFields && {
      // promoted object
      promoted_object: {
        [PROMOTED_PIXEL]: values[PROMOTED_PIXEL],
        [CUSTOM_EVENT_TYPE]: values[CUSTOM_EVENT_TYPE]
      }
    }),
    ...(showTargetCostCapField && {
      // target_cost_cap
      [TARGET_COST_CAP]: values[TARGET_COST_CAP]
    }),
    // placement
    ...transformPlacementsToBE(values, campaignObjective),
    ...placementSafetyRules
  }
}
