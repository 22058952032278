export const MEDIA_PRODUCTS = 'products'
// keep the edite pricing products and discounts in separate fields:
export const MODIFIED_PRODUCTS = 'modified_products'
export const CUSTOM_DISCOUNT = 'custom_discount'
// additional data:
export const CAMPAIGN_NAME = 'campaign_name'
export const BRAND = 'brand'

export const initialMediaProducts = []
export const initialBrand = ''
export const initialValues = {
  [MEDIA_PRODUCTS]: initialMediaProducts,
  [MODIFIED_PRODUCTS]: [],
  [CUSTOM_DISCOUNT]: 0,
  [CAMPAIGN_NAME]: '',
  [BRAND]: initialBrand
}
