import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import AdFileUpload from '../../../../../../../ReusableFormFields/AdFileUpload'

import { fileUpload } from '../../../../../../../../modules/actions/files'
import { selectedAdAccountIdSelector } from '../../../../../../../../modules/selectors/app'

import {
  AD_FILE,
  FILE_ID,
  FILE_NAME,
  FILE_TYPE,
  FILE_URL,
  MEDIA_FILE_UPLOAD,
  MEDIA_TYPE,
  UPLOADED_MEDIA_TYPE
} from '../../../../../../../ReusableFormFields/AdFileUpload/fields'
import { initialTikTokVideoFileValues, VIDEO_THUMBNAIL_URL } from '../../../fields'
import { TIKTOK_PLATFORM } from '../../../../../../../../constants/selectLists/platformList'
import { tikTokVideoValidation } from '../../../../../validation'

const TikTokVideoUpload = ({ formik, adAccountId, onVideoRemoveHandler, recommendedSize }) => {
  const dispatch = useDispatch()

  const { setFieldValue, setValues, setTouched, values, errors, touched, validateForm } = formik

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  // when ad account is manually passed from outside, use it instead of selected
  const providedAdAccountId = adAccountId || selectedAdAccountId

  const fileIdError = errors[FILE_ID]
  const fileIdTouched = touched[FILE_ID]

  const handleMediaFileRemove = useCallback(() => {
    setValues({ ...values, ...initialTikTokVideoFileValues })
    // run remove video callback from outside (reset state used for generating thumbnails)
    onVideoRemoveHandler && onVideoRemoveHandler()
  }, [setValues, values, onVideoRemoveHandler])

  const handleMediaStartUpload = useCallback(
    (file, newFileValues) => {
      const newValues = {
        [AD_FILE]: newFileValues[AD_FILE],
        [FILE_NAME]: newFileValues[FILE_NAME],
        [FILE_TYPE]: newFileValues[FILE_TYPE],
        [MEDIA_TYPE]: newFileValues[UPLOADED_MEDIA_TYPE]
      }

      // also reset touched when uploading new video
      setTouched({ ...touched, [FILE_ID]: false, [VIDEO_THUMBNAIL_URL]: false })
      setValues({ ...values, ...initialTikTokVideoFileValues, ...newValues })
      // run remove video callback from outside (reset state used for generating thumbnails)
      onVideoRemoveHandler && onVideoRemoveHandler()

      // upload file to server
      dispatch(
        fileUpload(file, { isPlatformRelated: true, platform: TIKTOK_PLATFORM, adAccountId: providedAdAccountId })
      )
    },
    [dispatch, setTouched, touched, setValues, values, onVideoRemoveHandler, providedAdAccountId]
  )

  const handleMediaUploaded = useCallback(
    uploadedFile => {
      setFieldValue(FILE_ID, uploadedFile.id)
      setFieldValue(FILE_URL, uploadedFile.url)
    },
    [setFieldValue]
  )

  return (
    <AdFileUpload
      values={values}
      validateForm={validateForm}
      onFileStartUpload={handleMediaStartUpload}
      onFileUploaded={handleMediaUploaded}
      onFileRemove={handleMediaFileRemove}
      error={fileIdTouched && fileIdError}
      fileValidationSchema={tikTokVideoValidation}
      mediaType={MEDIA_FILE_UPLOAD}
      fileKey={AD_FILE}
      fileNameKey={FILE_NAME}
      fileURLKey={FILE_URL}
      fileTypeKey={FILE_TYPE}
      uploadedMediaTypeKey={MEDIA_TYPE}
      recommendedSize={recommendedSize} // todo remove after redesign
      accept="video/mp4,video/x-m4v,video/*"
      isDraggable
    />
  )
}

TikTokVideoUpload.propTypes = {
  formik: PropTypes.object.isRequired,
  adAccountId: PropTypes.string,
  onVideoRemoveHandler: PropTypes.func.isRequired
}

export default TikTokVideoUpload
