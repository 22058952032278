import React, { forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import SelectComponent from 'react-select'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useTheme } from 'react-jss'

import { capitalizeFirstLetter } from '../../helpers/common'
import { useFormattedValue } from './hooks'

import SelectOption from './SelectOption'
import SelectSingleValue from './SelectSingleValue'
import SelectMultiValueLabel from './SelectMultiValueLabel'
import SelectDropdownIndicator from './SelectDropdownIndicator'
import SelectValueContainer from './SelectValueContainer'
import SelectMenu from './SelectMenu'
import SelectMenuList from './SelectMenuList'
import SelectPlaceholder from './SelectPlaceholder'
import SelectClearIndicator from './SelectClearIndicator'
import SelectIndicatorsContainer from './SelectIndicatorsContainer'

import { useStyles, getSelectStyles } from './styles'

const Select = forwardRef(
  (
    {
      portaled,
      value,
      formatValue = true,
      defaultValue = null,
      options = [],
      showIndicator = true,
      customMultiValueLabel,
      optionWithTag,
      isIconSelect,
      title,
      placeholder = 'Select...',
      isDisabled,
      isClearable,
      // custom components:
      CustomValue,
      CustomOption,
      CustomMenuList,
      CustomPlaceholder,
      CustomValueContainer,
      CustomMultiValueContainer,
      customComponentsReset,
      // styling
      className,
      customStyles,
      selectClassName,
      menuWidth,
      menuHeightLimit,
      children,
      ...props
    },
    ref
  ) => {
    const theme = useTheme()
    const classes = useStyles({ isDisabled })

    const { t } = useTranslation()
    const showClearButton = isClearable && value && !isDisabled

    const customComponents = {
      ValueContainer: CustomValueContainer ? CustomValueContainer : SelectValueContainer,
      MultiValueLabel: SelectMultiValueLabel,
      MultiValueContainer: CustomMultiValueContainer ? CustomMultiValueContainer : SelectMultiValueLabel,
      DropdownIndicator: showIndicator ? SelectDropdownIndicator : null,
      ClearIndicator: showClearButton ? SelectClearIndicator : undefined,
      IndicatorsContainer: SelectIndicatorsContainer,
      Placeholder: CustomPlaceholder ? CustomPlaceholder : SelectPlaceholder,
      Option: CustomOption ? CustomOption : SelectOption,
      SingleValue: CustomValue ? CustomValue : SelectSingleValue,
      Menu: SelectMenu,
      MenuList: CustomMenuList ? CustomMenuList : SelectMenuList,
      ...customComponentsReset
    }

    const formattedTitle = useMemo(() => (title ? capitalizeFirstLetter(title) : ''), [title])

    const formattedPlaceholder = useMemo(() => (placeholder ? capitalizeFirstLetter(placeholder) : ''), [placeholder])

    const getFormattedValue = useFormattedValue(options)

    const translatedOptions = useMemo(
      () => options?.map(option => ({ ...option, label: t(option.label) })),
      [t, options]
    )

    const componentProps = {
      title: t(formattedTitle),
      placeholder: t(formattedPlaceholder),
      defaultValue,
      options: translatedOptions,
      isIconSelect,
      isClearable,
      isDisabled,
      showIndicator,
      menuWidth,
      value: (formatValue ? getFormattedValue(value) : value) || defaultValue,
      styles: {
        ...getSelectStyles({ ...props, isIconSelect, isDisabled, menuHeightLimit, theme, menuWidth }),
        ...customStyles
      },
      menuPortalTarget: portaled && document && document.body,
      components: customComponents,
      ...props
    }

    return (
      <div className={classnames(classes.select, className)}>
        {children ? (
          React.cloneElement(children, {
            ...componentProps,
            // when AsyncPaginate is passed to the Select component as a child and ref is needed, then it should
            // be passed as selectRef prop, default ref is not working - check the react-select-async-paginate
            selectRef: ref // workaround for the issue with the ref forwarding
          })
        ) : (
          <SelectComponent {...componentProps} ref={ref} />
        )}
      </div>
    )
  }
)

const valuePropTypeShape = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

Select.propTypes = {
  CustomDownIcon: PropTypes.node,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  portaled: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date)
  ]),
  customComponentsReset: PropTypes.object,
  defaultValue: PropTypes.shape(valuePropTypeShape),
  options: PropTypes.arrayOf(PropTypes.shape(valuePropTypeShape)),
  onChange: PropTypes.func,
  bigIcon: PropTypes.bool,
  showIndicator: PropTypes.bool,
  showClearInputIcon: PropTypes.bool,
  onClearInput: PropTypes.func,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  isPlaceholderBrandPrimary: PropTypes.bool,
  isSelectedValueBrandPrimary: PropTypes.bool,
  showArrowIndicator: PropTypes.bool,
  isIconSelect: PropTypes.bool,
  isHovered: PropTypes.bool,
  CustomValue: PropTypes.func,
  CustomMenuList: PropTypes.func,
  isLoading: PropTypes.bool,
  // styling
  className: PropTypes.string,
  customStyles: PropTypes.object,
  menuHeightLimit: PropTypes.number,
  menuWidth: PropTypes.number,
  expandMenu: PropTypes.bool, // helps to expand menu width to it content
  CustomValueContainer: PropTypes.func,
  CustomMultiValueContainer: PropTypes.func
}

export default Select
