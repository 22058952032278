import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import TableDataLoader from '../../../components/Table/TableDataLoader'
import DigitalTableData from './DigitalTableData'

import { columnsSchema, mobileColumnsSchema } from './columnSizes'

import useStyles from './DigitalTableData/styles'
import { useMediaQuery } from 'react-responsive'
import { phonesDownSize } from '../../../styles/const/breakpoints'

const DigitalTable = React.memo(
  ({
    id,
    className,
    platform,
    data,
    totalsItem,
    isLoadingSelector,
    errorSelector,
    wasLoadedSelector,
    additionalDataIsLoading,
    noDataContent,
    noDataMessageText,
    itemUpdatingId,
    allowStatusChange,
    onStatusChange,
    currencySymbol,
    showGoalColumn,
    getItemLinkClickHandler,
    getActionsDropdownOptions,
    loadMoreHandler
  }) => {
    const classes = useStyles()
    const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

    const noDataGeneralContent = (
      <p className={classes.noDataMessage}>{noDataMessageText || 'There are no items to show.'}</p>
    )

    const skeletonProps = useMemo(() => {
      if (isMobile) {
        return { cols: mobileColumnsSchema, isBigRow: true }
      } else {
        return { cols: columnsSchema, isBigRow: true }
      }
    }, [isMobile])

    return (
      <div className={className} id={id}>
        <TableDataLoader
          loadMore={loadMoreHandler}
          itemsLength={data.length}
          isLoadingSelector={isLoadingSelector}
          errorSelector={errorSelector}
          wasLoadedSelector={wasLoadedSelector}
          skeletonProps={skeletonProps}
          // you can pass completely custom noDataContent, specify just noDataMessageText or use general message
          noDataContent={noDataContent || noDataGeneralContent}
          additionalDataIsLoading={additionalDataIsLoading}
        >
          <DigitalTableData
            platform={platform}
            data={data}
            totalsItem={totalsItem}
            isLoadingSelector={isLoadingSelector}
            itemUpdatingId={itemUpdatingId}
            allowStatusChange={allowStatusChange}
            onStatusChange={onStatusChange}
            currencySymbol={currencySymbol}
            showGoalColumn={showGoalColumn}
            getItemLinkClickHandler={getItemLinkClickHandler}
            getActionsDropdownOptions={getActionsDropdownOptions}
          />
        </TableDataLoader>
      </div>
    )
  }
)

DigitalTable.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  platform: PropTypes.string,
  data: PropTypes.array.isRequired,
  totalsItem: PropTypes.object,
  isLoadingSelector: PropTypes.func.isRequired,
  errorSelector: PropTypes.func.isRequired,
  wasLoadedSelector: PropTypes.func.isRequired,
  additionalDataIsLoading: PropTypes.bool,
  noDataContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  noDataMessageText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  itemUpdatingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allowStatusChange: PropTypes.bool,
  onStatusChange: PropTypes.func,
  currencySymbol: PropTypes.string,
  showGoalColumn: PropTypes.bool,
  getItemLinkClickHandler: PropTypes.func,
  getActionsDropdownOptions: PropTypes.func,
  loadMoreHandler: PropTypes.func
}

export default DigitalTable
