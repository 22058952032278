import { add, format } from 'date-fns'

import { DATES_FORMAT_BE } from '../../../../../constants/dates'
import {
  AMOUNT_MICROS,
  BUDGET_DAILY,
  CAMPAIGN_BUDGET,
  MAXIMIZE_CONVERSION_VALUE,
  NAME,
  TARGET_ROAS
} from '../../../../ReusableFormFields/CampaignForms/fields'
import {
  defaultGoogleCampaignPayload,
  SET_TARGET_ROAS,
  START_DATE,
  START_OPTION,
  START_OPTION_NOW,
  STATUS,
  STOP_DATE,
  STOP_OPTION,
  STOP_OPTION_INDEFINITELY,
  STOP_OPTION_ONE_MONTH,
  STOP_OPTION_ONE_WEEK,
  TARGET_ROAS_OPTION
} from './fields'

export const formatDateStartToBE = ({ startOption, startDate }) => {
  if (startOption === START_OPTION_NOW) {
    // add one minute for BE handling time to be not in the past
    return format(add(new Date(), { minutes: 1 }), DATES_FORMAT_BE)
  } else {
    return format(startDate, DATES_FORMAT_BE)
  }
}

export const formatDateStopToBE = ({ stopOption, stopDate, startDate }) => {
  switch (stopOption) {
    case STOP_OPTION_ONE_WEEK:
      return format(add(new Date(startDate), { days: 7 }), DATES_FORMAT_BE)
    case STOP_OPTION_ONE_MONTH:
      return format(add(new Date(startDate), { days: 30 }), DATES_FORMAT_BE)
    default:
      return format(stopDate, DATES_FORMAT_BE)
  }
}

export const transformValuesToBE = (values, selectedAdAccountId) => {
  const {
    // START DATE
    [START_OPTION]: startOption,
    [START_DATE]: startDate,
    // STOP DATE
    [STOP_OPTION]: stopOption,
    [STOP_DATE]: stopDate,
    //NAME
    [NAME]: name,
    // STATUS
    [STATUS]: status
  } = values

  const formattedStartDate = formatDateStartToBE({ startOption, startDate })

  return {
    account: selectedAdAccountId,
    status,
    [START_DATE]: formattedStartDate,
    ...(values[STOP_OPTION] !== STOP_OPTION_INDEFINITELY && {
      [STOP_DATE]: formatDateStopToBE({ stopOption, stopDate, startDate: formattedStartDate })
    }),
    name,
    [CAMPAIGN_BUDGET]: {
      delivery_method: 2,
      period: 2,
      type: 2,
      [NAME]: `Budget ${name}`,
      [AMOUNT_MICROS]: values[BUDGET_DAILY],
      explicitly_shared: false
    },
    [MAXIMIZE_CONVERSION_VALUE]: {
      [TARGET_ROAS]: values[TARGET_ROAS_OPTION] === SET_TARGET_ROAS ? values[TARGET_ROAS] : 0
    },
    ...defaultGoogleCampaignPayload(values)
  }
}
