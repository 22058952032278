import { FORMAT_CAROUSEL, FORMAT_SINGLE } from '../forms/Facebook/AdForms/fields'

export const FILE_IS_PROCESSING = 'processing'
export const FILE_IS_ACTIVE = 'active'

export const AD_FORMAT = {
  single: FORMAT_SINGLE,
  carousel: FORMAT_CAROUSEL,
  singleCustom: 'singleCustom',
  carouselCustom: 'carouselCustom'
}

export const adsEffectiveStatuses = [
  'pending_review',
  'pending_approval',
  'disapproved',
  'preapproved',
  'pending_billing_info',
  'campaign_paused',
  'adset_paused',
  'in_process',
  'with_issues'
]

export const recommendedFeedMediaSize = '1080x1080 px'

export const googleAssetFieldType = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  HEADLINE: 2,
  DESCRIPTION: 3,
  MANDATORY_AD_TEXT: 4,
  MARKETING_IMAGE: 5,
  MEDIA_BUNDLE: 6,
  YOUTUBE_VIDEO: 7,
  BOOK_ON_GOOGLE: 8,
  LEAD_FORM: 9,
  PROMOTION: 10,
  CALLOUT: 11,
  STRUCTURED_SNIPPET: 12,
  SITELINK: 13,
  MOBILE_APP: 14,
  HOTEL_CALLOUT: 15,
  CALL: 16,
  LONG_HEADLINE: 17,
  BUSINESS_NAME: 18,
  SQUARE_MARKETING_IMAGE: 19,
  PORTRAIT_MARKETING_IMAGE: 20,
  LOGO: 21,
  LANDSCAPE_LOGO: 22,
  VIDEO: 23,
  PRICE: 24,
  CALL_TO_ACTION_SELECTION: 25
}

export const googleCallToActionEnums = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  LEARN_MORE: 2,
  GET_QUOTE: 3,
  APPLY_NOW: 4,
  SIGN_UP: 5,
  CONTACT_US: 6,
  SUBSCRIBE: 7,
  DOWNLOAD: 8,
  BOOK_NOW: 9,
  SHOP_NOW: 10
}
