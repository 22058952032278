import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import InternalLink from '../../../components/InternalLink'

import useStyles from './styles'

// The component receives an array of routes.
// If there is a url in the route, the InternalLink component is rendered, if not - plain text
const Breadcrumbs = ({ routes }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <div className={classes.breadcrumbs}>
      {routes.map(route => {
        const Icon = route.Icon
        const isLast = routes.indexOf(route) === routes.length - 1

        return (
          <React.Fragment key={route.title}>
            {route.url ? (
              <InternalLink
                title={route.title}
                to={route.url}
                className={classnames(classes.breadcrumbsLink, {
                  [classes.isActive]: isLast
                })}
              >
                {Icon && <Icon className={classes.icon} />}
                {t(route.title)}
              </InternalLink>
            ) : (
              <span
                className={classnames(classes.breadCrumb, {
                  [classes.isActive]: isLast
                })}
                key={route.title}
              >
                {Icon && <Icon className={classes.icon} />}
                {t(route.title)}
              </span>
            )}
            {!isLast && <span className={classes.spacer}>/</span>}
          </React.Fragment>
        )
      })}
    </div>
  )
}

Breadcrumbs.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      Icon: PropTypes.elementType,
      url: PropTypes.string
    })
  )
}

export default Breadcrumbs
