import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

// Get discount presets
export const discountPresetsSelector = createSelector(
  ({ discounts }) => discounts.discountPresets.results,
  results => results
)

export const discountPresetsIsLoadingSelector = createSelector(
  ({ discounts }) => discounts.discountPresets.isLoading,
  isLoading => isLoading
)

export const discountPresetsErrorSelector = createSelector(
  ({ discounts }) => discounts.discountPresets.error,
  error => error
)

export const discountPresetsWasLoadedSelector = createSelector(
  ({ discounts }) => discounts.discountPresets.wasLoaded,
  wasLoaded => wasLoaded
)

// Create discount preset
export const createDiscountPresetSelector = createSelector(
  ({ discounts }) => discounts.createDiscountPreset.results,
  results => results
)

export const createDiscountPresetIsLoadingSelector = createSelector(
  ({ discounts }) => discounts.createDiscountPreset.isLoading,
  isLoading => isLoading
)

export const createDiscountPresetErrorSelector = createSelector(
  ({ discounts }) => discounts.createDiscountPreset.error,
  error => error
)

export const createDiscountPresetWasCreatedSelector = createSelector(
  ({ discounts }) => discounts.createDiscountPreset.wasCreated,
  wasCreated => wasCreated
)

// Update discount preset
export const updateDiscountPresetSelector = createSelector(
  ({ discounts }) => discounts.updateDiscountPreset.results,
  results => results
)

export const updateDiscountPresetIsLoadingSelector = createSelector(
  ({ discounts }) => discounts.updateDiscountPreset.isLoading,
  isLoading => isLoading
)

export const updateDiscountPresetErrorSelector = createSelector(
  ({ discounts }) => discounts.updateDiscountPreset.error,
  error => error
)

export const updateDiscountPresetWasUpdatedSelector = createSelector(
  ({ discounts }) => discounts.updateDiscountPreset.wasUpdated,
  wasUpdated => wasUpdated
)

// Get price change periods
export const priceChangePeriodsSelector = createSelector(
  ({ discounts }) => discounts.priceChangePeriods.results,
  results => results
)

export const priceChangePeriodsIsLoadingSelector = createSelector(
  ({ discounts }) => discounts.priceChangePeriods.isLoading,
  isLoading => isLoading
)

export const priceChangePeriodsErrorSelector = createSelector(
  ({ discounts }) => discounts.priceChangePeriods.error,
  error => error
)

export const priceChangePeriodsWasLoadedSelector = createSelector(
  ({ discounts }) => discounts.priceChangePeriods.wasLoaded,
  wasLoaded => wasLoaded
)

// Create price change period
export const createPriceChangePeriodSelector = createSelector(
  ({ discounts }) => discounts.createPriceChangePeriod.data,
  data => data
)

export const createPriceChangePeriodIsLoadingSelector = createSelector(
  ({ discounts }) => discounts.createPriceChangePeriod.isLoading,
  isLoading => isLoading
)

export const createPriceChangePeriodErrorSelector = createSelector(
  ({ discounts }) => discounts.createPriceChangePeriod.error,
  error => error
)

export const createPriceChangePeriodWasCreatedSelector = createSelector(
  ({ discounts }) => discounts.createPriceChangePeriod.wasCreated,
  wasCreated => wasCreated
)

// Update price change period
export const updatePriceChangePeriodSelector = createSelector(
  ({ discounts }) => discounts.updatePriceChangePeriod.data,
  data => data
)

export const updatePriceChangePeriodIsLoadingSelector = createSelector(
  ({ discounts }) => discounts.updatePriceChangePeriod.isLoading,
  isLoading => isLoading
)

export const updatePriceChangePeriodErrorSelector = createSelector(
  ({ discounts }) => discounts.updatePriceChangePeriod.error,
  error => error
)

export const updatePriceChangePeriodWasUpdatedSelector = createSelector(
  ({ discounts }) => discounts.updatePriceChangePeriod.wasUpdated,
  wasUpdated => wasUpdated
)

// Delete price change period
export const deletePriceChangePeriodSelector = createSelector(
  ({ discounts }) => discounts.deletePriceChangePeriod.data,
  data => data
)

export const deletePriceChangePeriodIsLoadingSelector = createSelector(
  ({ discounts }) => discounts.deletePriceChangePeriod.isLoading,
  isLoading => isLoading
)

export const deletePriceChangePeriodErrorSelector = createSelector(
  ({ discounts }) => discounts.deletePriceChangePeriod.error,
  error => error
)

export const deletePriceChangePeriodWasDeletedSelector = createSelector(
  ({ discounts }) => discounts.deletePriceChangePeriod.wasDeleted,
  wasDeleted => wasDeleted
)
