import * as Yup from 'yup'

export const NAME = 'signer_name'
export const SIGNATURE_FILE = 'sign'

export const getInitialValues = name => ({
  [NAME]: name || ''
})

export const nameValidation = {
  [NAME]: Yup.string().required('Please enter name').max(255, 'Name should have maximum 255 characters')
}

export const validationSchema = Yup.object({
  ...nameValidation
})
