import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import useStyles from './styles'

const ListCardItem = ({ className, isInactive, platform, mainColor, children, ...props }) => {
  const classes = useStyles({ isInactive, platform, mainColor })

  return (
    <div className={classnames(classes.card, className)} {...props}>
      {children}
    </div>
  )
}

ListCardItem.propTypes = {
  className: PropTypes.string,
  isInactive: PropTypes.bool,
  platform: PropTypes.string,
  mainColor: PropTypes.string
}

export default ListCardItem
