import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import MediaPackagesTable from './MediaPackagesTable'
import ContentSection from '../../../features/components/ContentSection'
import ContentRow from '../../../features/components/ContentSection/ContentRow'
import HighlightedValueSelect from '../../../components/HighlightedValueSelect'
import MediaPackageEdit from '../../../forms/Multiplatform/MediaPackageForms/MediaPackageEdit'
import MediaPackageCategories from './MediaPackageCategories'
import CreatePackageBtn from './CreatePackageBtn'
import ExportMediaPackagesAsCsv from './ExportMediaPackagesAsCsv'

import {
  clearMediaPackageCategories,
  clearMediaPackages,
  getMediaPackageCategories,
  getMediaPackages
} from '../../../modules/actions/mediaPackages'
import { clearProducts } from '../../../modules/actions/mediaOrdersProducts'

import { mediaPackagesFiltersList } from './constants'
import { selectedSelfAccountDataSelector } from '../../../modules/selectors/app'

import useSettingsClasses from '../styles'

const MediaPackages = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const settingsClasses = useSettingsClasses()

  const [statusFilter, setStatusFilter] = useState('')

  const selectedSelfAccountData = useSelector(selectedSelfAccountDataSelector)

  const controllerId = selectedSelfAccountData.controller

  useEffect(() => {
    if (controllerId) {
      dispatch(getMediaPackages({ controller: controllerId, status: statusFilter }))
    }
  }, [dispatch, controllerId, statusFilter])

  useEffect(() => {
    if (controllerId) {
      dispatch(getMediaPackageCategories({ controller: controllerId }))
    }
  }, [dispatch, controllerId])

  useEffect(
    () => () => {
      dispatch(clearMediaPackages())
      dispatch(clearMediaPackageCategories())
      dispatch(clearProducts())
    },
    [dispatch]
  )

  return (
    <>
      <h1 className={settingsClasses.title}>{t('Media Packages')}</h1>

      <ContentSection title="Media packages and types">
        <ContentRow
          //todo SHOP-1476 Think how CreatePackageBtn should look like on mobile
          leftColumnChildren={<CreatePackageBtn />}
          title="Media Packages"
          description="These are bookable media packages containing one or more media categories or products. All active packages will be displayed on the platform homepage."
        >
          <div className={settingsClasses.dataContent}>
            <div className={settingsClasses.filtersContainer}>
              <ExportMediaPackagesAsCsv />
              <div className={settingsClasses.selectWrapper}>
                <HighlightedValueSelect
                  className={settingsClasses.filterSelect}
                  placeholder="Status"
                  options={mediaPackagesFiltersList}
                  value={statusFilter}
                  onChange={status => setStatusFilter(status?.value)}
                  isSearchable={false}
                />
              </div>
            </div>
            <MediaPackagesTable />
          </div>
        </ContentRow>

        {/* media package categories */}
        <MediaPackageCategories />
      </ContentSection>
      <MediaPackageEdit controllerId={controllerId} />
    </>
  )
}

export default MediaPackages
