import React from 'react'

import { useTranslation } from 'react-i18next'

import useStyles from './styles'

const ExpiredQuotationWarning = () => {
  const { t } = useTranslation()

  const classes = useStyles()

  return (
    <div className={classes.warningContainer}>
      <h4 className={classes.warningTitle}>{t('Sorry, this quotation has expired')}</h4>
      <p>{t('Please ask your account manager to generate a new quotation')}</p>
    </div>
  )
}

export default ExpiredQuotationWarning
