import { format, parseISO } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'

import { getSelectedAdAccountTimezone } from '../modules/selectors/app'

export const MINIMAL_PAST_DATE = '01/01/2021'

// initialDatePeriod doesn't highlight any date in DateRange component
export const initialDatePeriod = {
  startDate: null,
  endDate: new Date(),
  key: 'selection'
}

export const getTimezoneDate = date => {
  // timezone should be used from selectedAdAccount
  const timezone = getSelectedAdAccountTimezone()
  return toZonedTime(date, timezone)
}

export const DATES_FORMAT_BE = 'yyyy-MM-dd'
export const DATES_FORMAT_FULL_MONTH = 'MMMM'
export const DATES_FORMAT_SHORT = 'dd MMM yy'
export const DATES_FORMAT_MONTH_DAY = 'MMM dd'
export const DATES_FORMAT_MONTH_YEAR = 'MMM yyyy'
export const DATES_FORMAT_FULL_YEAR = 'dd MMM yyyy'
export const DATES_FORMAT_DETAILED = 'dd MMM yyyy HH:mm'
export const DATES_FORMAT_FULL_MONTH_AND_YEAR = 'MMMM yyy'
export const DATES_FORMAT_MONTH_AND_SHORT_YEAR = 'MMM yy'
export const DATES_FORMAT_MONTH_DAY_YEAR = 'MMM dd yyyy'
export const DATES_FORMAT_FULL_DAY_MONTH_AND_YEAR = 'dd MMMM yyy'

export const scheduleSelectorMondayDate = '2019-06-10T00:00:00'

export const formatDateToBE = date => {
  if (typeof date === 'string') {
    date = parseISO(date)
  }
  return format(date, DATES_FORMAT_BE)
}

export const formatDateShort = date => {
  if (typeof date === 'string') {
    date = parseISO(date)
  }
  return format(date, DATES_FORMAT_SHORT)
}

export const formatDateFullYear = date => {
  if (typeof date === 'string') {
    date = parseISO(date)
  }
  return format(date, DATES_FORMAT_FULL_YEAR)
}

export const formatDateDetailed = date => {
  if (typeof date === 'string') {
    date = parseISO(date)
  }
  return format(date, DATES_FORMAT_DETAILED)
}

export const formatDateFullMonthAndYear = date => {
  if (typeof date === 'string') {
    date = parseISO(date)
  }
  return format(date, DATES_FORMAT_FULL_MONTH_AND_YEAR)
}

export const formatDateFullDayMonthAndYear = date => {
  if (typeof date === 'string') {
    date = parseISO(date)
  }
  return format(date, DATES_FORMAT_FULL_DAY_MONTH_AND_YEAR)
}

export const formatDateFullMonth = date => {
  if (typeof date === 'string') {
    date = parseISO(date)
  }
  return format(date, DATES_FORMAT_FULL_MONTH)
}

export const formatDateFullMonthAndShortYear = date => {
  if (typeof date === 'string') {
    date = parseISO(date)
  }
  return format(date, DATES_FORMAT_MONTH_AND_SHORT_YEAR)
}
