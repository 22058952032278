import React from 'react'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import CampaignFacebookDuplicateForm from './CampaignFacebookDuplicateForm'

import { CAMPAIGN_FACEBOOK_DUPLICATE } from '../../../../constants/forms'

function CampaignFacebookDuplicate() {
  return (
    <FormDrawerWrapper showOpenButton={false} formName={CAMPAIGN_FACEBOOK_DUPLICATE} closeOnSubmit={false}>
      <CampaignFacebookDuplicateForm />
    </FormDrawerWrapper>
  )
}

export default CampaignFacebookDuplicate
