import { CAN_SELECT_MULTIPLE, NAME, OPTIONS, REQUIRED } from '../../fields'

export const formatUpdatedOptionsToBE = (initialOptions, options) => {
  const createOptions = options
    .filter(
      option =>
        // we also check
        !initialOptions.find(initialOption => initialOption.text === option.text)
    )
    .map(option => option.text)
  const deleteOptions = initialOptions
    .filter(
      // we also check if text is the same, because
      initialOption => !options.find(option => option.text === initialOption.text)
    )
    .map(option => option.id)

  return {
    create: createOptions,
    delete: deleteOptions
  }
}

export const transformValuesToBE = (initialValues, values) => {
  const initialOptions = initialValues[OPTIONS]
  const options = values[OPTIONS]
  const { create: createOptions, delete: deleteOptions } = formatUpdatedOptionsToBE(initialOptions, options)
  const shouldSendOptions = createOptions.length || deleteOptions.length

  return {
    [NAME]: values[NAME],
    [CAN_SELECT_MULTIPLE]: Boolean(values[CAN_SELECT_MULTIPLE]),
    [REQUIRED]: Boolean(values[REQUIRED]),
    ...(shouldSendOptions && {
      [OPTIONS]: formatUpdatedOptionsToBE(initialOptions, options)
    })
  }
}
