import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Select from '../Select'
import MultiLevelSelectMenuList from './MultiLevelSelectMenuList'
import MultiLevelSelectedValues from './MultiLevelSelectedValues'

import { useDebouncedSearch } from '../../hooks/useDebouncedSearch'
import useMultiLevelSelectSearch from './useMultiLevelSelectSearch'

import { createNewOptionsState, getOptionValuesToModify, prepareOptionsSelectState } from './helpers'
import { convertFlatToNestedArr } from '../../helpers/arrays'
import { convertFlatDataIdsToMatchValuesType } from './formatters'

const SelectMultiLevel = ({
  className,
  value,
  setFieldValue,
  name,
  // IMPORTANT: when you pass options it should match such field namings ("id" - own id, "parent" - id of parent)
  options: optionsFlatData = [],
  isLoading,
  flatToNestedArrSortFunction,
  ...selectProps
}) => {
  const [isDataInitiallyFormatted, setIsDataInitiallyFormatted] = useState(false)
  const [options, setOptions] = useState([])
  const [optionsState, setOptionsState] = useState({})

  const isInitialLoading = isLoading || (optionsFlatData.length && !isDataInitiallyFormatted)

  const onMultiValueOptionSelectHandler = useCallback(
    option => {
      const newSelectedValues = getOptionValuesToModify({ option, options, optionsState, isSelectMode: true })

      // push newly selected values to main value, swap isChecked state to true for options state
      setFieldValue(name, value.concat(newSelectedValues))
      setOptionsState(prevState => createNewOptionsState(prevState, newSelectedValues, true))
    },
    [name, options, optionsState, setFieldValue, value]
  )

  const onMultiValueOptionDeselectHandler = useCallback(
    option => {
      const newDeselectedValues = getOptionValuesToModify({ option, options, optionsState })

      const filteredNewValue = value.filter(id => !newDeselectedValues.includes(id))

      // remove deselected values from main value, swap isChecked state to false for options state
      setFieldValue(name, filteredNewValue)
      setOptionsState(prevState => createNewOptionsState(prevState, newDeselectedValues))
    },
    [name, options, optionsState, setFieldValue, value]
  )

  const onOptionExpand = useCallback(id => {
    setOptionsState(prevState => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        isExpanded: !prevState[id].isExpanded
      }
    }))
  }, [])

  const { isSearchApplied, searchOptions, handleSearchChange } = useMultiLevelSelectSearch({
    isDataInitiallyFormatted,
    options,
    optionsState,
    setOptionsState
  })

  const { searchText, handleSearchTextChange } = useDebouncedSearch(handleSearchChange)

  const onSearchInputChange = (value, { action }) => {
    if (action === 'input-change') {
      handleSearchTextChange(value)
    }
  }

  // if search is applied and nothing found, show empty options ('No options' message)
  const finalOptions = useMemo(
    () => (isSearchApplied && !searchOptions.length ? [] : options),
    [isSearchApplied, options, searchOptions.length]
  )

  useEffect(() => {
    if (!isDataInitiallyFormatted && optionsFlatData.length) {
      // this helps to make component dynamically work with different types of values (string, number)
      const formattedOptionsFlatData = convertFlatDataIdsToMatchValuesType(optionsFlatData, value)

      setOptions(convertFlatToNestedArr(formattedOptionsFlatData, flatToNestedArrSortFunction))
      setOptionsState(prepareOptionsSelectState(formattedOptionsFlatData, value))

      setIsDataInitiallyFormatted(true)
    }
  }, [isDataInitiallyFormatted, optionsFlatData, flatToNestedArrSortFunction, value])

  return (
    <div className={classnames('field', className)}>
      <MultiLevelSelectedValues
        values={value}
        options={options}
        optionsState={optionsState}
        onOptionDeselect={onMultiValueOptionDeselectHandler}
      />
      <Select
        // manually controlled selected value
        selectedValues={value}
        optionsState={optionsState}
        onOptionSelect={onMultiValueOptionSelectHandler}
        onOptionDeselect={onMultiValueOptionDeselectHandler}
        onOptionExpand={onOptionExpand}
        options={finalOptions}
        isSearchApplied={isSearchApplied}
        searchOptions={searchOptions}
        closeMenuOnSelect={false}
        closeMenuOnScroll={false}
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        isLoading={isInitialLoading}
        CustomMenuList={MultiLevelSelectMenuList}
        onInputChange={onSearchInputChange}
        inputValue={searchText}
        {...selectProps}
      />
    </div>
  )
}

SelectMultiLevel.propTypes = {
  className: PropTypes.string,
  value: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  isLoading: PropTypes.bool
}

export default SelectMultiLevel
