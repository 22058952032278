import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ScheduleSectionForm from '../../../../../ReusableFormSections/ScheduleSection/ScheduleSectionForm'

import { filterObjectEmptyValues } from '../../../../../../helpers/common'
import { formatScheduleDatesToBE, formatScheduleDatesToFE } from '../../../../../../features/formatters'

import { updateLineItem, clearUpdateLineItem } from '../../../../../../modules/actions/lineItems'
import {
  lineItemUpdateErrorSelector,
  lineItemUpdateIsLoadingSelector,
  lineItemWasUpdatedSelector
} from '../../../../../../modules/selectors/lineItems'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { SCHEDULE } from '../../../../../ReusableFormSections/ScheduleSection/fields'

function ScheduleSection({ editItemData: lineItem, ...formProps }) {
  const dispatch = useDispatch()

  const lineItemWasUpdated = useSelector(lineItemWasUpdatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const { dayparting: schedule } = lineItem

  const formattedSchedule = formatScheduleDatesToFE(schedule)

  const handleSubmit = useCallback(
    values => {
      // as we use PUT for update TikTok campaign, we should pass full data in payload
      dispatch(
        updateLineItem(
          {
            ...filterObjectEmptyValues(lineItem),
            account: selectedAdAccountId,
            dayparting: formatScheduleDatesToBE(values[SCHEDULE])
          },
          lineItem.id,
          {
            requestMethod: 'PUT'
          }
        )
      )
    },
    [dispatch, lineItem, selectedAdAccountId]
  )

  const handleClearUpdateLineItem = useCallback(() => {
    dispatch(clearUpdateLineItem())
  }, [dispatch])

  return (
    <ScheduleSectionForm
      schedule={formattedSchedule}
      handleSubmit={handleSubmit}
      clearEditItem={handleClearUpdateLineItem}
      successSubmit={lineItemWasUpdated}
      isLoadingSelector={lineItemUpdateIsLoadingSelector}
      errorSelector={lineItemUpdateErrorSelector}
      {...formProps}
    />
  )
}

export default ScheduleSection
