import * as Yup from 'yup'

import { getAdImageAssetInitialValues, getAdSquareImageAssets } from '../../helpers'
import { SQUARE_IMAGE } from '../../../../fields'
import { listOfImagesShape } from '../../../../validations'

export const getInitialValues = ad => {
  const squareImageAssets = getAdSquareImageAssets(ad)

  return {
    [SQUARE_IMAGE]: squareImageAssets ? squareImageAssets.map(asset => getAdImageAssetInitialValues(asset)) : []
  }
}

export const validationSchema = Yup.object({
  [SQUARE_IMAGE]: listOfImagesShape
})
