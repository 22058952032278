import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { TEAM_MEMBER_CREATE } from '../../../../constants/forms'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import NewUserCreateForm from './NewUserCreateForm'

import { selectedSelfAccountDataSelector } from '../../../../modules/selectors/app'

const NewUserCreate = () => {
  const { t } = useTranslation()
  const selfAccountData = useSelector(selectedSelfAccountDataSelector)

  return (
    <FormDrawerWrapper
      formName={TEAM_MEMBER_CREATE}
      title={t('addNewMember', { accountName: selfAccountData?.name })}
      description="The user will be able to book and launch campaigns, and access reporting."
      openButtonText={t('Add new user')}
    >
      <NewUserCreateForm />
    </FormDrawerWrapper>
  )
}

export default NewUserCreate
