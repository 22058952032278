import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import UploadedImageIcon from './UploadedImageIcon'

import { availableDimensions } from '../constants'

import { IMAGE_LIST } from '../../../../fields'
import useStyles from './styles'

const UploadedImagesInfoBlock = ({ values }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const imageList = values[IMAGE_LIST]

  return (
    <>
      <p>{t('Upload or select at least 1 of each of the following:')}</p>
      {availableDimensions.map(({ width, height }) => {
        return (
          <p key={`${width}x${height}`} className={classes.uploadedImageText}>
            <UploadedImageIcon uploadedImages={imageList} width={width} height={height} />
            {t(`${width} x ${height}`)}
          </p>
        )
      })}
    </>
  )
}

UploadedImagesInfoBlock.propTypes = {
  values: PropTypes.object.isRequired,
  showPortraitImage: PropTypes.bool
}

export default UploadedImagesInfoBlock
