import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import ProductSelectionContent from './ProductSelectionContent'
import Step from '../../../../../../../../features/components/Forms/StepForm/Step'

import { useLoadProductsData } from './useLoadProductsData'

import { getStepTitle } from './helpers'
import { scrollToContainerTop } from '../../../../../../../../features/components/Forms/StepForm/helpers'

import {
  ALLOWED_CATEGORY_PRODUCTS_SELECTION,
  ALLOWED_PRODUCT_SELECTION,
  SELECTED_PACKAGES,
  PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE
} from '../../fields'

import useStyles from './styles'

// multi-category products component
function ProductSelectStep({
  formik,
  currency,
  packageFlow,
  packageIndex,
  stepsContainerEl,
  selectedMediaPackage,
  preDefinedProductsFilter,
  isActive: isStepActive,
  categoryName
}) {
  const classes = useStyles()
  const { setFieldValue } = formik

  const { filters, loadMoreProducts } = useLoadProductsData({
    selectedMediaPackage,
    preDefinedProductsFilter,
    isStepActive,
    categoryName,
    currencyCode: currency.code
  })

  const allowBack = selectedMediaPackage?.allowBack

  const selectedLocationId = filters.selectedLocations[0]

  const handleStepSubmit = useCallback(() => {
    // as current step is removed from DOM after product setup is over it doesn't use standard handleStepChange
    // so the Step forms stays on the same scroll position when new Step is presented, for this case we need to
    // scroll to top manually:
    const carouselContainer = stepsContainerEl?.current?.closest('div[data-content]')
    scrollToContainerTop(carouselContainer)
  }, [stepsContainerEl])

  const stopProductSelection = useCallback(() => {
    // close current ProductSelection step
    if (packageFlow === PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE) {
      // all products
      setFieldValue(`${SELECTED_PACKAGES}[${packageIndex}].${ALLOWED_PRODUCT_SELECTION}`, false)
    } else {
      // category related products
      setFieldValue(
        `${SELECTED_PACKAGES}[${packageIndex}].${ALLOWED_CATEGORY_PRODUCTS_SELECTION}.${categoryName}`,
        false
      )
    }
  }, [packageIndex, packageFlow, categoryName, setFieldValue])

  const stepTitle = getStepTitle(categoryName)

  return (
    <Step
      stepTitle={stepTitle}
      stepDescription={'Please select one of the following media products to add it to your media booking'}
      backButtonClassName={classes.backButton}
      onBackButtonClick={allowBack ? stopProductSelection : undefined}
    >
      <ProductSelectionContent
        categoryName={categoryName}
        formik={formik}
        currency={currency}
        packageIndex={packageIndex}
        loadMoreProducts={loadMoreProducts}
        selectedTag={filters.selectedTag}
        selectedLocationId={selectedLocationId}
        handleStepSubmit={handleStepSubmit}
        stopProductSelection={stopProductSelection}
        filters={filters}
      />
    </Step>
  )
}

ProductSelectStep.propTypes = {
  selectedMediaPackage: PropTypes.object.isRequired,
  packageIndex: PropTypes.number.isRequired,
  preDefinedProductsFilter: PropTypes.string,
  currency: PropTypes.object.isRequired,
  categoryName: PropTypes.string,
  stepsContainerEl: PropTypes.object
}
export default ProductSelectStep
