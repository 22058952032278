import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const NameSectionPreview = ({ dataSelector }) => {
  const { name } = useSelector(dataSelector)

  return <p>{name}</p>
}

NameSectionPreview.propTypes = {
  dataSelector: PropTypes.func.isRequired
}

export default NameSectionPreview
