import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Portal } from 'react-portal'

import AdCard from '../../../../../../features/components/FacebookPreviews/AdCard'
import AdStoriesCard from '../../../../../../features/components/FacebookPreviews/AdStoriesCard'
import Step from '../../../../../../features/components/Forms/StepForm/Step'
import FieldsSection from '../../../../../../features/components/Form/FieldsSection'
import FacebookPageSelectionFields from '../../../../ReusableFormFIelds/FacebookPageSelectionFields'

import useLineItemDetails from '../hooks/useLineItemDetails'

import { FB_PAGE_CHOICE, INSTA_ACCOUNT_CHOICE, FORMAT_OPTION, FORMAT_STORY } from '../../../fields'

const fields = [FB_PAGE_CHOICE, INSTA_ACCOUNT_CHOICE]

// todo This component currently only in, story route currently is not used
const FacebookPageStep = ({ formik, handleStepChange, adAccountId, isActive }) => {
  const { values } = formik

  const { placementPositions } = useLineItemDetails()

  const optionFormat = values[FORMAT_OPTION]

  const handleContinue = useCallback(() => {
    handleStepChange(fields)
  }, [handleStepChange])

  return (
    <Step stepTitle="Please select a page to run the ad from" handleContinue={handleContinue}>
      <>
        {isActive && (
          <Portal node={document && document.getElementById('formSideColumnPortal')}>
            {optionFormat === FORMAT_STORY ? (
              <AdStoriesCard formikValues={values} elementInProgress={FB_PAGE_CHOICE} />
            ) : (
              <AdCard elementInProgress={FB_PAGE_CHOICE} formikValues={values} showSkeletonPlaceholders={true} />
            )}
          </Portal>
        )}
        <FieldsSection title="Page">
          <FacebookPageSelectionFields
            formik={formik}
            adAccountId={adAccountId}
            placementPositions={placementPositions}
          />
        </FieldsSection>
      </>
    </Step>
  )
}

FacebookPageStep.propTypes = {
  adAccountId: PropTypes.string.isRequired
}
export default FacebookPageStep
