import React, { useCallback } from 'react'

import Step from '../../../../../../../../../../features/components/Forms/StepForm/Step'
import StartEndDateRangeFields from '../../../../../../../../../ReusableFormFields/StartEndDateRangeFields'
import FieldsSection from '../../../../../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../../../../../features/components/Form/FieldsSection/FieldRow'

import { END_DATE, START_DATE } from '../../../../../../../../../ReusableFormFields/StartEndDateRangeFields/fields'

const stepFields = [START_DATE, END_DATE]

const DatesStep = ({ formik, handleStepChange }) => {
  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  return (
    <Step
      stepTitle="Set the campaign dates"
      stepDescription="Please enter the start and end dates for the campaign"
      handleContinue={handleContinue}
    >
      <FieldsSection title="Campaign period">
        <FieldRow title="Campaigns dates" description="Start and end date">
          <StartEndDateRangeFields
            formik={formik}
            startDateName={START_DATE}
            endDateName={END_DATE}
            minDate={new Date()}
          />
        </FieldRow>
      </FieldsSection>
    </Step>
  )
}

export default DatesStep
