import * as Yup from 'yup'

export const EMAIL = 'email'

export const initialValues = {
  [EMAIL]: ''
}

export const validationSchema = Yup.object({
  [EMAIL]: Yup.string().required('Please enter your email').email('Please enter correct email')
})
