import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'

import Form from '../../../../../components/Form'
import Field from '../../../../../components/Form/Field'

import { validationSchema, initialValues, NAME } from './fields'

import {
  createSelfAccountErrorSelector,
  createSelfAccountIsLoadingSelector,
  selfAccountWasCreatedSelector
} from '../../../../../modules/selectors/selfAccounts'
import { clearCreateSelfAccount, createSelfAccount } from '../../../../../modules/actions/selfAccounts'

import useAuthStyles from '../../../../../styles/common/authPages'

const SelfAccountCreateForm = () => {
  const authClasses = useAuthStyles()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const selfAccountWasCreated = useSelector(selfAccountWasCreatedSelector)

  const onSubmit = useCallback(
    values => {
      dispatch(createSelfAccount(values, true))
    },
    [dispatch]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  useEffect(() => {
    if (selfAccountWasCreated) {
      dispatch(clearCreateSelfAccount())
    }
  }, [dispatch, selfAccountWasCreated])

  return (
    <>
      <div className={authClasses.formContainer}>
        <Form
          formName="selfAccountCreate"
          formik={formik}
          successSubmit={selfAccountWasCreated}
          errorSelector={createSelfAccountErrorSelector}
          isLoadingSelector={createSelfAccountIsLoadingSelector}
          submitText={t('Save')}
        >
          <Field formik={formik} id={NAME} name={NAME} placeholder="Your brand or company name" />
        </Form>
      </div>
    </>
  )
}

SelfAccountCreateForm.propTypes = {
  fieldPlaceholder: PropTypes.string,
  targetName: PropTypes.string
}

export default SelfAccountCreateForm
