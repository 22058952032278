export const listItemsTableColumnsMinWidth = {
  platform: 45,
  name: 290,
  impressions: 120,
  activeViewImpressions: 120,
  reach: 125,
  clicks: 120,
  engagements: 120,
  videoViews: 120,
  conversions: 120,
  spend: 110,
  status: 90,
  dropdown: 35
}

export const listItemsTableColumnsMaxWidth = {
  platform: 45,
  name: 290,
  impressions: 120,
  activeViewImpressions: 120,
  reach: 125,
  clicks: 120,
  engagements: 120,
  videoViews: 120,
  conversions: 120,
  spend: 110,
  status: 100,
  dropdown: 35
}
