import React, { useCallback, useEffect } from 'react'

import MediaOrderGenerateForm from './MediaOrderGenerateForm'
import ContractCreateForm from './ContractCreateForm'

import { MediaOrderFormProvider } from '../../MediaOrderFormContext'
import useContinuousForm from '../../../../../hooks/formHooks/useContinuousForm'

export const CONTRACT_CREATE_FORM = 'contract_create_form'
export const MEDIA_ORDER_GENERATE_FORM = 'media_order_generate_form'

function MediaOrderCreateForm({ onFormChange }) {
  const { activatedForm, setActivatedForm } = useContinuousForm({
    initialForm: CONTRACT_CREATE_FORM
  })

  const handleFormSwitch = useCallback(
    newActiveFormName => {
      setActivatedForm(newActiveFormName)
      onFormChange(newActiveFormName)
    },
    [setActivatedForm, onFormChange]
  )

  useEffect(() => {
    return () => {
      // reset active form to default when forms are unmounted
      onFormChange(null)
    }
  }, [onFormChange])

  const getActivatedForm = useCallback(() => {
    switch (activatedForm) {
      case MEDIA_ORDER_GENERATE_FORM:
        return <MediaOrderGenerateForm onStepBack={() => handleFormSwitch(CONTRACT_CREATE_FORM)} />
      case CONTRACT_CREATE_FORM:
      default:
        return <ContractCreateForm onSuccessSubmit={() => handleFormSwitch(MEDIA_ORDER_GENERATE_FORM)} />
    }
  }, [activatedForm, handleFormSwitch])

  return <MediaOrderFormProvider>{getActivatedForm()}</MediaOrderFormProvider>
}

export default MediaOrderCreateForm
