import React from 'react'
import PropTypes from 'prop-types'

import GoogleMediaFilesPreview from '../../../../GoogleMediaFilesPreview'

import { getAdLandscapeLogoAssets } from '../../helpers'

const LandscapeLogoSectionPreview = ({ ad }) => {
  const landscapeLogoAssets = getAdLandscapeLogoAssets(ad)

  return <GoogleMediaFilesPreview assets={landscapeLogoAssets} assetDataName="asset" />
}

LandscapeLogoSectionPreview.propTypes = {
  ad: PropTypes.object
}

export default LandscapeLogoSectionPreview
