import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import ControllerInviteCreateForm from './ControllerInviteCreateForm'

import { selectedControllerDataSelector } from '../../../../modules/selectors/app'

import { CONTROLLER_INVITE_CREATE } from '../../../../constants/forms'

const ControllerInviteCreate = ({ controller }) => {
  const { t } = useTranslation()
  const selectedController = useSelector(selectedControllerDataSelector)

  return (
    <FormDrawerWrapper
      formName={CONTROLLER_INVITE_CREATE}
      title={t('addNewMemberToTeam', { controllerName: selectedController?.name })}
      description="The user will be able to manage client accounts."
      openButtonText="New user"
    >
      <ControllerInviteCreateForm controller={controller} />
    </FormDrawerWrapper>
  )
}

export default ControllerInviteCreate
