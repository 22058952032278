import React from 'react'
import { useTranslation } from 'react-i18next'

import Field from '../../../../../../components/Form/Field'

import { inChargePeriods } from '../../../../../../constants/mediaOrders'
import { PERIOD_OPTION } from '../../../MediaProductCreate/MediaProductCreateForm/fields'

import useDrawerFormStyles from '../../../../../../styles/common/drawerForms'

const PeriodSection = ({ formik }) => {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormStyles()

  return (
    <section className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>{t('In-charge period')}</h3>
      <Field
        formik={formik}
        name={PERIOD_OPTION}
        placeholder="In-charge period"
        enableReinitialize
        options={inChargePeriods}
      />
    </section>
  )
}

export default PeriodSection
