import { BUDGET_DAILY, BUDGET_LIFETIME, CAMPAIGN_BUDGET } from './fields'

export const getBudgetType = (lineItemBudgetLifetime, lineItemBudgetDaily) => {
  // we're sure that if there's campaign budget, there's no line item budgets, and vice versa
  if (lineItemBudgetLifetime) {
    return BUDGET_LIFETIME
  }

  if (lineItemBudgetDaily) {
    return BUDGET_DAILY
  }

  return CAMPAIGN_BUDGET
}
