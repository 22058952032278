import React, { useCallback, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import MultiSelectBox from '../../../../../../../../features/components/Form/MultiSelectBox'

import { selectedControllerIdSelector } from '../../../../../../../../modules/selectors/app'
import { getProductsService } from '../../../../../../../../modules/services/mediaOrdersProducts'

import { formatOptionsList } from '../../../../../../../../features/formatters'
import { createJsonFromQueryString } from '../../../../../../../../helpers/url'

import { CURRENCY } from '../../../fields'
import { MEDIA_CATEGORY, PERIOD_OPTION } from '../../../../../MediaProductCreate/MediaProductCreateForm/fields'
import { SUB_PRODUCTS } from '../../../../../fields'

function SubProductsField({ formik, formattedSubProductsOptions }) {
  const { values, setFieldValue, errors, touched } = formik

  const controllerId = useSelector(selectedControllerIdSelector)

  const groupCurrencyValue = values[CURRENCY]
  const groupCategoryValue = values[MEDIA_CATEGORY]
  const groupPeriodValue = values[PERIOD_OPTION]
  const isDisabled = !groupCategoryValue || !groupPeriodValue
  const triggerSearchClear = `${groupCategoryValue}-${groupPeriodValue}-${groupCurrencyValue}`

  const formatProducts = useCallback(
    products =>
      formatOptionsList({
        list: products,
        valueName: 'id',
        labelName: 'name'
      }),
    []
  )

  const loadOptions = useCallback(
    async (search, loadedOptions, { next }) => {
      const response = await getProductsService({
        search,
        ordering: 'name',
        controller: controllerId,
        period: groupPeriodValue,
        media_category: groupCategoryValue,
        currency_code: groupCurrencyValue,
        fields: ['id', 'name'].join(','),
        limit: 100,
        ...createJsonFromQueryString(`?${next.split('?')[1]}`)
      })

      const newProductsOptions = formatProducts(response.results)

      return {
        options: newProductsOptions,
        hasMore: !!response.next,
        additional: {
          next: response.next
        }
      }
    },
    [controllerId, formatProducts, groupCategoryValue, groupCurrencyValue, groupPeriodValue]
  )

  const fieldKey = useMemo(
    // re-render field to clear options and fetch them again on filters change
    // We use the key prop on a non-list component to force a full remount,
    // which can be useful if you need to reset the component's state entirely when certain props change.
    () => groupCategoryValue + groupPeriodValue + groupCurrencyValue,
    [groupCategoryValue, groupPeriodValue, groupCurrencyValue]
  )

  useEffect(() => {
    // reset selected SUB_PRODUCTS on category or period change
    if (groupCategoryValue && groupPeriodValue && groupCurrencyValue) {
      setFieldValue(SUB_PRODUCTS, [])
    }
  }, [groupCategoryValue, groupPeriodValue, setFieldValue, groupCurrencyValue])

  return (
    <MultiSelectBox
      key={fieldKey}
      placeholder="Select products"
      name={SUB_PRODUCTS}
      value={values[SUB_PRODUCTS]}
      error={errors[SUB_PRODUCTS]}
      touched={touched[SUB_PRODUCTS]}
      isDisabled={isDisabled}
      loadOptions={loadOptions}
      // clear search on filters change and trigger re-fetch data
      triggerClearSearch={triggerSearchClear}
      setFieldValue={setFieldValue}
      expandMenu={true}
      portaled={true}
      defaultOptionsList={formattedSubProductsOptions ? formattedSubProductsOptions : undefined}
    />
  )
}

SubProductsField.propTypes = {
  formik: PropTypes.object.isRequired,
  formattedSubProductsOptions: PropTypes.array
}

export default SubProductsField
