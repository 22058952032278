import React from 'react'
import { useTranslation } from 'react-i18next'

import PromotedObjectFields from '../../../../../../../../../ReusableFormFields/PromotedObjectFields'

import useStepFormStyles from '../../../../../../../../../../styles/common/stepForms'

const PromotedObjectStep = ({ formik, adAccountId }) => {
  const stepFormClasses = useStepFormStyles()

  const { t } = useTranslation()

  return (
    <>
      <div className={stepFormClasses.stepTitle}>{t('Select a conversion event to optimise towards')}</div>
      <div className={stepFormClasses.stepBody}>
        <PromotedObjectFields formik={formik} adAccountId={adAccountId} />
      </div>
    </>
  )
}

export default PromotedObjectStep
