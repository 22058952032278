import Color from 'color'

const mainColor = '#34B6FF'

export const aduhuTheme = {
  brandPrimary: mainColor,
  brandPrimaryLight: Color(mainColor).lightness(80).rgb().string(),
  brandPrimaryHover: Color(mainColor).darken(0.2).rgb().string(),
  brandPrimaryTextContrastColor: '#fff',
  selectActiveBorderColor: mainColor
}
