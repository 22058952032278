import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  sectionControls: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 15,
    marginTop: 30
  },
  submitBtn: {
    width: '100%'
  }
})

export default useStyles
