import React, { useState } from 'react'
import PropTypes from 'prop-types'

import NoSignPermissionsModal from './NoSignPermissionsModal'
import ResponsiveContractPdfViewer from '../ResponsiveContractPdfViewer'

function ContractReview({ contract, allowCreateQuotation }) {
  const [noSignPermissionsModal, setNoSignPermissionsModal] = useState(false)

  const pdfFile = contract?.file

  return (
    <>
      <ResponsiveContractPdfViewer
        pdfFile={pdfFile}
        onLastPageClick={() => setNoSignPermissionsModal(true)}
        showPagesCount={true}
      />
      <NoSignPermissionsModal
        isOpen={noSignPermissionsModal}
        onModalClose={() => setNoSignPermissionsModal(false)}
        contractId={contract?.id}
        allowCreateQuotation={allowCreateQuotation}
      />
    </>
  )
}

ContractReview.propTypes = {
  contract: PropTypes.object,
  allowCreateQuotation: PropTypes.bool.isRequired
}

export default ContractReview
