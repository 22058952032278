import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { addYears } from 'date-fns'

import RadioBlock from '../../../components/Form/RadioBlock'
import DatePicker from '../../../components/DatePicker'
import TimePicker from '../../../components/TimePicker'
import Spoiler from '../../../components/Spoiler'

import { OPTION_CUSTOM } from '../../../constants/forms'

import { campaignSelector } from '../../../modules/selectors/campaigns'
import { mediaOrderUploadCreativeDataSelector } from '../../../modules/selectors/mediaOrders'

import { START_DATE } from '../StartTimeFields/fields'
import { BUDGET_LIFETIME } from '../LineItemForms/fields'
import {
  STOP_DATE,
  STOP_OPTION,
  STOP_OPTION_INDEFINITELY,
  STOP_OPTION_ONE_MONTH,
  STOP_OPTION_ONE_WEEK,
  STOP_TIME
} from './fields'

import useStepFormStyles from '../../../styles/common/stepForms'
import useDrawerFormsStyles from '../../../styles/common/drawerForms'

const StopTimeFields = ({
  formik,
  showTimePicker = true,
  showIndefiniteOption = true,
  indefiniteOptionDescription
}) => {
  const stepFormClasses = useStepFormStyles()
  const drawerFormsClasses = useDrawerFormsStyles()

  const { t } = useTranslation()

  const { budget_lifetime: campaignBudgetLifetime } = useSelector(campaignSelector)

  const { campaign: mediaOrderCampaign = {} } = useSelector(mediaOrderUploadCreativeDataSelector)
  const { budget_lifetime: mediaOrderCampaignBudgetLifetime } = mediaOrderCampaign

  const { values, errors, setFieldValue, touched } = formik

  const { [BUDGET_LIFETIME]: budgetLifetime } = values

  // hide spoiler if campaign, line item or media order has lifetime budget
  const hideSpoilerBlock = !!campaignBudgetLifetime || !!mediaOrderCampaignBudgetLifetime || !!budgetLifetime

  const timeError = errors[STOP_TIME]
  const timeTouched = touched[STOP_TIME]

  const handleDatePickerChange = value => {
    setFieldValue(STOP_DATE, value)
  }

  const handleTimePickerChange = value => {
    setFieldValue(STOP_TIME, value || '00:00')
  }

  return (
    <>
      <div className={stepFormClasses.stepBody}>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_stop_one_week"
          name={STOP_OPTION}
          value={STOP_OPTION_ONE_WEEK}
          selectedValue={values[STOP_OPTION]}
          label={t('One week after the start date')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_stop_one_month"
          name={STOP_OPTION}
          value={STOP_OPTION_ONE_MONTH}
          selectedValue={values[STOP_OPTION]}
          label={t('One month after the start date')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_stop_specific"
          name={STOP_OPTION}
          value={OPTION_CUSTOM}
          selectedValue={values[STOP_OPTION]}
          label={t('At a specific date and time')}
          error={timeError}
          touched={timeTouched}
        >
          <DatePicker
            className={drawerFormsClasses.dateTimeInput}
            value={values[STOP_DATE]}
            title={t('End Date')}
            minDate={values[START_DATE]}
            // limit maxDate to one year from today
            maxDate={addYears(new Date(), 1)}
            changeHandler={handleDatePickerChange}
          />
          {showTimePicker && (
            <TimePicker
              className={drawerFormsClasses.dateTimeInput}
              value={values[STOP_TIME]}
              changeHandler={handleTimePickerChange}
            />
          )}
        </RadioBlock>
        {!hideSpoilerBlock && showIndefiniteOption && (
          <Spoiler title={t('View more end date options')}>
            <RadioBlock
              setFieldValue={setFieldValue}
              id="radio_stop_indefinitely"
              name={STOP_OPTION}
              value={STOP_OPTION_INDEFINITELY}
              selectedValue={values[STOP_OPTION]}
              label={t('Don’t set an end date')}
            >
              {indefiniteOptionDescription && <p>{t(indefiniteOptionDescription)}</p>}
            </RadioBlock>
          </Spoiler>
        )}
      </div>
    </>
  )
}

export default StopTimeFields
