import * as Yup from 'yup'

import { CAROUSEL_ITEMS, PRIMARY_TEXT_LIST } from '../../../../fields'
import nameValidation from '../../../../../../../features/validations/name'
import { productOrPagesValidation } from '../../../../../../ReusableFormFields/ProductsAndPagesFields/fields'
import { mediaItemSchema } from '../../../../validation'
import { primaryTextValidation } from '../../validation'

export const getValidationSchema = ({ showProductSelectionStep = false }) => {
  return Yup.object({
    ...nameValidation,
    [PRIMARY_TEXT_LIST]: primaryTextValidation,
    [CAROUSEL_ITEMS]: Yup.array().of(mediaItemSchema),
    // CATALOGUE PRODUCT
    ...(showProductSelectionStep && productOrPagesValidation)
  })
}
