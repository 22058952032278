import React, { useEffect, useMemo, useState } from 'react'
import { differenceInDays, startOfDay, endOfDay, addDays } from 'date-fns'

import { CALENDAR_VIEW_TYPE } from '../../../constants/selectLists/calendarList'
import { initialPeriod } from './helpers'

export const ProductsCalendarContext = React.createContext()

export function ProductsCalendarProvider({ children, calendar }) {
  const [{ startDate, endDate }, setDateRange] = useState(initialPeriod)

  const viewType = useMemo(() => {
    const days = differenceInDays(endDate, startDate) + 1

    if (days > 180) {
      return CALENDAR_VIEW_TYPE.YEAR
    } else if (days > 30) {
      return CALENDAR_VIEW_TYPE.QUARTER
    } else {
      return CALENDAR_VIEW_TYPE.MONTH
    }
  }, [startDate, endDate])

  const { earliestDate, latestDate } = calendar
  useEffect(() => {
    if (earliestDate && latestDate) {
      setDateRange({
        // Add at least 2 days on either side of the calendar so the bars dont touch the end of the calendar
        startDate: startOfDay(addDays(earliestDate, -2)),
        endDate: endOfDay(addDays(latestDate, 2))
      })
    }
  }, [earliestDate, latestDate])

  return (
    <ProductsCalendarContext.Provider
      value={{
        startDate,
        endDate,
        setDateRange,
        viewType
      }}
    >
      {children}
    </ProductsCalendarContext.Provider>
  )
}
