import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import FacebookAdCardList from '../../../../../CampaignsSummary/SummaryPagesAdsContent/FacebookAdCardList'

import { useSortHighestPerformingAds } from '../useSortHighestPerformingAds'

import {
  combinedAdsErrorSelector,
  combinedAdsIsLoadingSelector,
  combinedAdsSelector,
  combinedAdsWasLoadedSelector,
  combinedLineItemsIsLoadingSelector
} from '../../../../../../modules/selectors/combinedData'
import { selectedAdAccountsSelector } from '../../../../../../modules/selectors/app'

import { FACEBOOK_PLATFORM, FACEBOOK_PROVIDER_PLATFORM } from '../../../../../../constants/selectLists/platformList'

const combinedFacebookAdProps = {
  showStatus: false,
  showDetails: true,
  showMetrics: true,
  showActionsDropdown: false,
  showActionsButtons: false,
  showPurchaseMetric: true
}

const FacebookHighestPerformingAds = () => {
  const { t } = useTranslation()

  const combinedLineItemsIsLoading = useSelector(combinedLineItemsIsLoadingSelector)
  const combinedAds = useSelector(combinedAdsSelector)

  const selectedAdAccounts = useSelector(selectedAdAccountsSelector)
  const selectedSelfAccountFacebookAdAccountId = selectedAdAccounts[FACEBOOK_PLATFORM].id
  const selectedSelfAccountCurrencySymbol = selectedAdAccounts[FACEBOOK_PLATFORM].currency_symbol

  const facebookAds = useMemo(() => {
    return combinedAds.filter(ad => ad.provider === FACEBOOK_PROVIDER_PLATFORM)
  }, [combinedAds])

  const sortedFacebookAds = useSortHighestPerformingAds(facebookAds)

  return (
    <FacebookAdCardList
      adAccountId={selectedSelfAccountFacebookAdAccountId}
      currencySymbol={selectedSelfAccountCurrencySymbol}
      adsList={sortedFacebookAds}
      loadingSelector={combinedAdsIsLoadingSelector}
      errorSelector={combinedAdsErrorSelector}
      wasLoadedSelector={combinedAdsWasLoadedSelector}
      lineItemsIsLoading={combinedLineItemsIsLoading}
      contentWrapperText={t('Highest performing ads')}
      noDataText={t('There are no highest performing Facebook ads')}
      initialLoadingSkeletonsNumber={5}
      {...combinedFacebookAdProps}
    />
  )
}

export default FacebookHighestPerformingAds
