import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import RadioBlock from '../../../../../../components/Form/RadioBlock'
import ProgressButton from '../../../../../../components/Form/ProgressButton'

import {
  duplicateCampaignProcessWasStartedSelector,
  duplicateCampaignErrorSelector,
  duplicateCampaignIsLoadingSelector,
  duplicateCampaignAsyncSessionIdSelector
} from '../../../../../../modules/selectors/campaigns'
import { selectedPlatformSelector, selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'
import { duplicateCampaign } from '../../../../../../modules/actions/campaigns'
import { getAsyncSession } from '../../../../../../modules/actions/app'

import { transformValuesToBE } from '../fields'
import { STATUS, STATUS_ACTIVE, STATUS_PAUSED } from '../../../../../ReusableFormFields/CampaignForms/fields'

import useStyles from '../../../../../../styles/common/stepForms'

function StatusStep({ formik, handleStepChange, selectedEditItemId }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const classes = useStyles()

  const { values, setFieldValue } = formik

  const selectedPlatform = useSelector(selectedPlatformSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const duplicateCampaignError = useSelector(duplicateCampaignErrorSelector)
  const duplicateCampaignIsLoading = useSelector(duplicateCampaignIsLoadingSelector)
  const duplicateCampaignProcessWasStarted = useSelector(duplicateCampaignProcessWasStartedSelector)
  const duplicateCampaignAsyncSessionId = useSelector(duplicateCampaignAsyncSessionIdSelector)

  const duplicateCampaignHandler = useCallback(() => {
    const formattedValues = transformValuesToBE(values, selectedAdAccountId)

    dispatch(duplicateCampaign(formattedValues, selectedEditItemId, selectedPlatform))
  }, [dispatch, values, selectedEditItemId, selectedAdAccountId, selectedPlatform])

  const onDuplicateCampaignSuccess = useCallback(() => {
    handleStepChange()
    dispatch(getAsyncSession(duplicateCampaignAsyncSessionId, selectedPlatform, { account: selectedAdAccountId }))
  }, [dispatch, handleStepChange, duplicateCampaignAsyncSessionId, selectedPlatform, selectedAdAccountId])

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Duplicate a campaign')}</div>
      <div className={classes.stepTitle}>{t('Do you want the campaign to go live immediately?')}</div>
      <div className={classes.stepBody}>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_status_paused"
          name={STATUS}
          label={t('No, set it as paused')}
          value={STATUS_PAUSED}
          selectedValue={values[STATUS]}
        >
          <p>{t('Your campaign will not go live until you activate it later.')}</p>
        </RadioBlock>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_status_active"
          name={STATUS}
          label={t('Yes, set it as active')}
          value={STATUS_ACTIVE}
          selectedValue={values[STATUS]}
        >
          <p>{t('Your campaign will go live immediately upon ad approval.')}</p>
        </RadioBlock>
      </div>
      <div className={classes.stepFooter}>
        <ProgressButton
          type="button"
          onClick={duplicateCampaignHandler}
          solid
          isFormLoading={duplicateCampaignIsLoading}
          formError={duplicateCampaignError}
          successSubmit={duplicateCampaignProcessWasStarted}
          onSuccessSubmit={onDuplicateCampaignSuccess}
        >
          {t('Duplicate')}
        </ProgressButton>
      </div>
    </>
  )
}

export default StatusStep
