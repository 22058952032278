import React, { useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Field from '../../../../components/Form/Field'
import ButtonIcon from '../../../../features/components/Buttons/ButtonIcon'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-grey.svg'

import useStyles from './styles'

const OptionItemRow = ({
  formik,
  fieldsList,
  listFieldName,
  selectedFieldName,
  fieldMaxLength,
  maxLengthSchema,
  fieldName,
  index,
  item,
  fieldPlaceholder,
  minListQuantity,
  allowDelete,
  ignoreBlur,
  isTextarea,
  selectFirstEmptyField,
  onFieldSelect
}) => {
  const classes = useStyles()
  const { setFieldValue } = formik

  const removeFieldItem = useCallback(
    ({ e, deleteIndex, fieldName }) => {
      // avoid firing wrapper onClick event
      e.stopPropagation()

      if (fieldsList.length > minListQuantity) {
        const filteredList = fieldsList.filter((item, index) => {
          return index !== deleteIndex
        })
        setFieldValue(listFieldName, filteredList)

        // if the deleted field is selected, select the first empty field
        if (selectedFieldName === fieldName) {
          selectFirstEmptyField(filteredList)
        }
      }
    },
    [setFieldValue, minListQuantity, fieldsList, listFieldName, selectedFieldName, selectFirstEmptyField]
  )

  const getMaxLength = useCallback(({ maxLengthSchema, fieldMaxLength, index }) => {
    if (maxLengthSchema) {
      return maxLengthSchema[index] || maxLengthSchema.default
    } else if (fieldMaxLength) {
      return fieldMaxLength
    } else {
      return null
    }
  }, [])

  return (
    <div
      className={classnames(classes.fieldWrapper, { [classes.selected]: selectedFieldName === fieldName })}
      key={item.id}
      onClick={() => onFieldSelect && onFieldSelect(fieldName)}
    >
      <Field
        placeholder={`${fieldPlaceholder} ${index + 1}`}
        formik={formik}
        name={fieldName}
        ignoreBlur={ignoreBlur}
        maxLength={getMaxLength({ maxLengthSchema, fieldMaxLength, index })}
        isTextarea={isTextarea}
        inputClassName={classnames({ [classes.field]: allowDelete })}
        enableReinitialize
      />
      {allowDelete ? (
        <ButtonIcon
          className={classes.removeButtonSymbol}
          onClick={e => removeFieldItem({ e, deleteIndex: index, fieldName })}
          Icon={CloseIcon}
        />
      ) : null}
    </div>
  )
}

OptionItemRow.propTypes = {
  formik: PropTypes.object.isRequired,
  fieldsList: PropTypes.array.isRequired,
  listFieldName: PropTypes.string.isRequired,
  selectedFieldName: PropTypes.string,
  fieldMaxLength: PropTypes.number,
  maxLengthSchema: PropTypes.object,
  fieldName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  fieldPlaceholder: PropTypes.string.isRequired,
  minListQuantity: PropTypes.number,
  allowDelete: PropTypes.bool,
  ignoreBlur: PropTypes.bool,
  isTextarea: PropTypes.bool,
  selectFirstEmptyField: PropTypes.func,
  onFieldSelect: PropTypes.func
}

export default OptionItemRow
