import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'
import { replaceRouteParams } from '../../helpers/url'

// PLOP_APPEND_PATTERN_ANCHOR

export function getTikTokVideosService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.tikTokVideos
  })
}

export function getFacebookMediaImagesService(params) {
  return madeRequest('GET', {
    url: ENDPOINTS.facebookMediaImages,
    params
  })
}

export function getFacebookMediaVideosService(params) {
  return madeRequest('GET', {
    url: ENDPOINTS.facebookMediaVideos,
    params
  })
}

export function getFacebookMediaVideoService({ id, ...params }) {
  return madeRequest('GET', {
    url: replaceRouteParams(ENDPOINTS.facebookMediaVideoDetail, {
      id
    }),
    params
  })
}

export function getFacebookVideoUrlsService(params) {
  return madeRequest('GET', {
    url: ENDPOINTS.facebookVideoUrls,
    params
  })
}

export function getFacebookImageUrlsService(params) {
  return madeRequest('GET', {
    url: ENDPOINTS.facebookImageUrls,
    params
  })
}
