import { createStyles } from '../../../../../../styles/helpers'

const useStyles = createStyles({
  mobileTableHeader: {
    minHeight: 22
  },
  actionsStyles: {
    paddingTop: 0
  }
})

export default useStyles
