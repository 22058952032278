import React from 'react'
import { useTranslation } from 'react-i18next'

import Page from '../index'
import InfoBlock from '../../features/components/InfoBlock'

function AppCrashedPage() {
  const { t } = useTranslation()

  return (
    <Page helmetTitle="helmetTitle.AppCrashedPage">
      <InfoBlock title={t('Sorry, something went wrong')} centered greyDescription>
        <div>{t('We have alerted our team and will fix it as soon as possible')}</div>
      </InfoBlock>
    </Page>
  )
}

export default AppCrashedPage
