import React from 'react'
import classnames from 'classnames'

import ShowSkeleton from '../ShowSkeleton'

import useStyles from './styles'

function SkeletonHeadlineText({ className, count, isInProgress, showLoadingAnimation }) {
  const classes = useStyles()

  return (
    <ShowSkeleton
      isInProgress={isInProgress}
      count={count}
      className={classnames(classes.skeletonHeadline, className)}
      showLoadingAnimation={showLoadingAnimation}
    />
  )
}

export default SkeletonHeadlineText
