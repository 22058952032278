import React from 'react'
import { useTranslation } from 'react-i18next'

import StartEndDateRangeFields from '../../../../../../ReusableFormFields/StartEndDateRangeFields'

import { END_DATE, START_DATE } from '../../../../../../ReusableFormFields/StartEndDateRangeFields/fields'

import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

const DatesFields = ({ formik }) => {
  const drawerFormClasses = useDrawerFormStyles()

  const { t } = useTranslation()

  return (
    <div className={drawerFormClasses.subSection}>
      <h4 className={drawerFormClasses.sectionSubtitle}>Start and end dates</h4>
      <p>{t('Please enter the start and end date for this campaign.')}</p>
      <StartEndDateRangeFields startDateName={START_DATE} endDateName={END_DATE} formik={formik} minDate={new Date()} />
    </div>
  )
}

export default DatesFields
