import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../components/Button'
import TikTokAudiencesFields from '../../../../ReusableFormFields/TikTokAudiencesFields'

import {
  CUSTOM_AUDIENCES,
  CUSTOM_AUDIENCES_EXCLUSION
} from '../../../../../ReusableFormFields/CustomAudiencesFields/fields'

import useStyles from '../../../../../../styles/common/stepForms'

const stepFields = [CUSTOM_AUDIENCES, CUSTOM_AUDIENCES_EXCLUSION]

const CustomAudiencesStep = ({ formik, handleStepChange, adAccountId }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create a TikTok ad group')}</div>
      <div className={classes.stepTitle}>{t('Do you want to target your custom audiences?')}</div>
      <div className={classes.stepBody}>
        <TikTokAudiencesFields formik={formik} adAccountId={adAccountId} />
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" className="dark" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default CustomAudiencesStep
