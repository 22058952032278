import PropTypes from 'prop-types'

const NameCell = ({ name, email }) => {
  if (name && email) {
    return `${name} (${email})`
  } else if (email) {
    return `(${email})`
  } else if (name) {
    return name
  }
  return '-'
}

NameCell.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string
}

export default NameCell
