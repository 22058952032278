import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Can from '../../../../features/components/Can'
import TikTokInternalAdsPreviewList from './TikTokInternalAdsPreviewList'
import AdTikTokEdit from '../../../../forms/Tiktok/AdForms/AdTikTokEdit'
import DisapproveInternalAdModal from '../../DisapproveInternalAdModal'

import useModalManage from '../../../../hooks/useModalManage'

import { clearAds, clearApproveInternalAd } from '../../../../modules/actions/ads'
import {
  approveInternalAdErrorSelector,
  approveInternalAdWasApprovedSelector,
  getAdsSelector
} from '../../../../modules/selectors/ads'

import { INTERNAL_ADS_PERMISSION } from '../../../../constants/permissions'

const TikTokInternalAdsSection = () => {
  const dispatch = useDispatch()

  const ads = useSelector(getAdsSelector)
  const internalAdWasApproved = useSelector(approveInternalAdWasApprovedSelector)
  const internalAdApproveError = useSelector(approveInternalAdErrorSelector)

  // pass only pending approvals ads to TikTokInternalAdsPreviewList
  // const pendingApprovalAds = ads.filter(({ approval_status }) => approval_status === 'pending_approval')
  // todo SHOP-1659 Uncomment when BE add approval_status to the response
  const pendingApprovalAds = ads

  const {
    isModalOpened: isDisapproveInternalAdModalOpened,
    openModalHandler: disapproveInternalAdOpenModalHandler,
    closeModalHandler: disapproveInternalAdCloseModalHandler,
    modalData: disapproveInternalAdModalData
  } = useModalManage({})

  useEffect(() => {
    if (internalAdWasApproved || internalAdApproveError) {
      dispatch(clearApproveInternalAd())
    }
  }, [dispatch, internalAdApproveError, internalAdWasApproved])

  useEffect(() => {
    return () => {
      dispatch(clearAds())
    }
  }, [dispatch])

  return (
    <>
      <TikTokInternalAdsPreviewList
        ads={pendingApprovalAds}
        noDataText="There are no ads waiting for approval."
        disapproveInternalAdHandler={disapproveInternalAdOpenModalHandler}
      />
      <Can I="update" a={INTERNAL_ADS_PERMISSION}>
        <AdTikTokEdit />
      </Can>
      <Can I="manage" a={INTERNAL_ADS_PERMISSION}>
        <DisapproveInternalAdModal
          isModalOpened={isDisapproveInternalAdModalOpened}
          modalData={disapproveInternalAdModalData}
          onClose={disapproveInternalAdCloseModalHandler}
        />
      </Can>
    </>
  )
}

export default TikTokInternalAdsSection
