import { call, put, all, takeEvery } from 'redux-saga/effects'

import {
  getTagsListService,
  getProductTagsService,
  createProductTagService,
  updateProductTagService,
  deleteProductTagService,
  createTagService,
  updateTagService,
  deleteTagService
} from '../services/tags'
import {
  getTagsListFailure,
  getTagsListSuccess,
  GET_TAGS_LIST,
  GET_PRODUCT_TAGS,
  getProductTagsSuccess,
  getProductTagsFailure,
  CREATE_PRODUCT_TAG,
  createProductTagSuccess,
  createProductTagFailure,
  UPDATE_PRODUCT_TAG,
  updateProductTagSuccess,
  updateProductTagFailure,
  DELETE_PRODUCT_TAG,
  deleteProductTagSuccess,
  deleteProductTagFailure,
  CREATE_TAG,
  createTagSuccess,
  createTagFailure,
  UPDATE_TAG,
  updateTagSuccess,
  updateTagFailure,
  DELETE_TAG,
  deleteTagSuccess,
  deleteTagFailure
} from '../actions/tags'

function* tagsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_TAGS_LIST, getTagsWorker),
    takeEvery(CREATE_TAG, createTagWorker),
    takeEvery(UPDATE_TAG, updateTagWorker),
    takeEvery(DELETE_TAG, deleteTagWorker),
    takeEvery(GET_PRODUCT_TAGS, getProductTagsWorker),
    takeEvery(CREATE_PRODUCT_TAG, createProductTagWorker),
    takeEvery(UPDATE_PRODUCT_TAG, updateProductTagWorker),
    takeEvery(DELETE_PRODUCT_TAG, deleteProductTagWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getTagsWorker({ params }) {
  try {
    const response = yield call(getTagsListService, params)
    yield put(getTagsListSuccess(response))
  } catch (e) {
    yield put(getTagsListFailure(e))
  }
}

function* createTagWorker({ data }) {
  try {
    const response = yield call(createTagService, data)
    yield put(createTagSuccess(response))
  } catch (error) {
    yield put(createTagFailure(error))
  }
}

function* updateTagWorker({ id, data }) {
  try {
    const response = yield call(updateTagService, id, data)
    yield put(updateTagSuccess(response))
  } catch (error) {
    yield put(updateTagFailure(error))
  }
}

function* deleteTagWorker({ id }) {
  try {
    const response = yield call(deleteTagService, id)
    yield put(deleteTagSuccess(response))
  } catch (error) {
    yield put(deleteTagFailure(error))
  }
}

function* getProductTagsWorker({ params }) {
  try {
    const response = yield call(getProductTagsService, params)
    yield put(getProductTagsSuccess(response))
  } catch (error) {
    yield put(getProductTagsFailure(error))
  }
}

function* createProductTagWorker({ data }) {
  try {
    const response = yield call(createProductTagService, data)
    yield put(createProductTagSuccess(response))
  } catch (error) {
    yield put(createProductTagFailure(error))
  }
}

function* updateProductTagWorker({ id, data }) {
  try {
    const response = yield call(updateProductTagService, id, data)
    yield put(updateProductTagSuccess(response))
  } catch (error) {
    yield put(updateProductTagFailure(error))
  }
}

function* deleteProductTagWorker({ id }) {
  try {
    const response = yield call(deleteProductTagService, id)
    yield put(deleteProductTagSuccess(response))
  } catch (error) {
    yield put(deleteProductTagFailure(error))
  }
}

export default tagsWatcher
