import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'

import StepForm from '../../../../../../../../features/components/Forms/StepForm'
import LineItemStep from '../../../../ReusableForms/LineItemStep'
import BudgetStep from './Steps/BudgetStep'
import CustomAudiencesStep from './Steps/CustomAudiencesStep'
import PromotedObjectStep from './Steps/PromotedObjectStep'
import DatesStep from './Steps/DatesStep'
import GeolocationsStep from './Steps/GeolocationsStep'

import { useAllowExistingLineItems } from '../../../hooks'
import { usePurifiedFormik } from '../../../../../../../../hooks/formHooks/usePurifiedFormik'

import { clearCreateLineItem, createLineItem } from '../../../../../../../../modules/actions/lineItems'
import { setMediaOrderUploadCreative } from '../../../../../../../../modules/actions/mediaOrders'
import {
  lineItemCreatedDataSelector,
  lineItemCreateErrorSelector,
  lineItemCreateIsLoadingSelector,
  lineItemsSelector,
  lineItemWasCreatedSelector
} from '../../../../../../../../modules/selectors/lineItems'
import {
  mediaOrderSelector,
  mediaOrderUploadCreativeDataSelector
} from '../../../../../../../../modules/selectors/mediaOrders'
import { selectedSelfAccountDataSelector } from '../../../../../../../../modules/selectors/app'

import { getInitialValues, getValidationSchema, LINE_ITEM, LINE_ITEM_OPTION, OPTION_EXISTING_LINE_ITEM } from './fields'
import { transformValuesToBE } from './formatters'

import { OUTCOME_SALES } from '../../../../../../../../constants/campaigns'
import { FACEBOOK_PLATFORM } from '../../../../../../../../constants/selectLists/platformList'
import { LINE_ITEM_ADVANCED_FORM } from '../../index'

const LineItemAdvancedFormContent = ({ adAccountId, onSuccessSubmit }) => {
  const dispatch = useDispatch()

  const [successSubmit, setSuccessSubmit] = useState(false)

  // line items are loaded when submitting previous CampaignForm with existing campaign option
  const lineItems = useSelector(lineItemsSelector)
  const lineItemFacebookCreatedData = useSelector(lineItemCreatedDataSelector)
  const lineItemWasCreated = useSelector(lineItemWasCreatedSelector)
  const mediaOrder = useSelector(mediaOrderSelector)
  const { currency_symbol: currencySymbol } = useSelector(selectedSelfAccountDataSelector)
  const { campaign } = useSelector(mediaOrderUploadCreativeDataSelector)

  const { isLineItemsExist, showLineItemSelectionStep } = useAllowExistingLineItems(lineItems)

  const isCampaignBudgetEmpty = !campaign['budget_lifetime']
  const isOutcomeSalesCampaign = campaign.objective === OUTCOME_SALES

  const onSubmit = useCallback(
    values => {
      if (values[LINE_ITEM_OPTION] === OPTION_EXISTING_LINE_ITEM) {
        const selectedLineItemFullData = lineItems.find(lineItem => values[LINE_ITEM] === lineItem.id)

        dispatch(setMediaOrderUploadCreative({ lineItem: selectedLineItemFullData }))

        setSuccessSubmit(true)
      } else {
        dispatch(createLineItem(transformValuesToBE({ values, adAccountId, mediaOrder, campaign }), FACEBOOK_PLATFORM))
      }
    },
    [dispatch, lineItems, adAccountId, mediaOrder, campaign]
  )

  const formik = useFormik({
    initialValues: getInitialValues(isLineItemsExist),
    validationSchema: getValidationSchema(campaign),
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)
  const { values } = purifiedFormik

  const showOnlyLineItemStep = values[LINE_ITEM_OPTION] === OPTION_EXISTING_LINE_ITEM
  const showBudgetStep = !showOnlyLineItemStep && isCampaignBudgetEmpty
  const showCustomAudiencesStep = !showOnlyLineItemStep
  const showPromotedObjectStep = !showOnlyLineItemStep && isOutcomeSalesCampaign

  const steps = useMemo(
    () => [
      {
        component: <LineItemStep />,
        show: showLineItemSelectionStep
      },
      {
        component: <BudgetStep currencySymbol={currencySymbol} />,
        show: showBudgetStep
      },
      {
        component: <DatesStep />,
        show: !showOnlyLineItemStep
      },
      {
        component: <GeolocationsStep />,
        show: !showOnlyLineItemStep
      },
      {
        component: <CustomAudiencesStep adAccountId={adAccountId} isLastStep={!showPromotedObjectStep} />,
        show: showCustomAudiencesStep
      },
      {
        component: <PromotedObjectStep adAccountId={adAccountId} />,
        show: showPromotedObjectStep
      }
    ],
    [
      showOnlyLineItemStep,
      showLineItemSelectionStep,
      currencySymbol,
      showBudgetStep,
      adAccountId,
      showPromotedObjectStep,
      showCustomAudiencesStep
    ]
  )

  const clearCreateLineItemHandler = useCallback(() => {
    dispatch(clearCreateLineItem())
  }, [dispatch])

  useEffect(() => {
    if (lineItemWasCreated) {
      dispatch(setMediaOrderUploadCreative({ lineItem: lineItemFacebookCreatedData }))

      setSuccessSubmit(true)
    }
  }, [dispatch, lineItemWasCreated, lineItemFacebookCreatedData])

  return (
    <StepForm
      steps={steps}
      formik={purifiedFormik}
      formName={LINE_ITEM_ADVANCED_FORM}
      submitText="Save and continue"
      onSuccessSubmit={onSuccessSubmit}
      clearSubmitHandler={clearCreateLineItemHandler}
      successSubmit={successSubmit}
      errorSelector={lineItemCreateErrorSelector}
      isLoadingSelector={lineItemCreateIsLoadingSelector}
    />
  )
}

LineItemAdvancedFormContent.propTypes = {
  adAccountId: PropTypes.string.isRequired,
  stepSubTitle: PropTypes.string,
  onSuccessSubmit: PropTypes.func.isRequired
}

export default LineItemAdvancedFormContent
