import React from 'react'
import Skeleton from 'react-loading-skeleton'
import PropTypes from 'prop-types'

import useSettingsClasses from '../../../../../pages/Settings/styles'

const MediaProductsFiltersSkeleton = ({ showSearchField }) => {
  const settingsClasses = useSettingsClasses()

  return (
    <div className={settingsClasses.filtersContainer}>
      <Skeleton height={40} width={90} />
      <div className={settingsClasses.selectWrapper}>
        <Skeleton height={40} width={'100%'} />
      </div>
      <div className={settingsClasses.selectWrapper}>
        <Skeleton height={40} width={'100%'} />
      </div>
      {showSearchField && <Skeleton height={40} width={175} />}
    </div>
  )
}

MediaProductsFiltersSkeleton.propTypes = {
  showSearchField: PropTypes.bool
}

export default MediaProductsFiltersSkeleton
