import * as Yup from 'yup'
import { passwordValidation } from '../../../features/validations/other'
import { PASSWORD } from '../CreatePassword/fields'

export const USER_FIRST_NAME = 'first_name'
export const USER_LAST_NAME = 'last_name'
export const USER_PASSWORD = 'new_password'

export const initialValues = {
  [USER_FIRST_NAME]: '',
  [USER_LAST_NAME]: '',
  [USER_PASSWORD]: ''
}

export const validationSchema = Yup.object({
  [USER_FIRST_NAME]: Yup.string().required('Please enter first name'),
  [USER_LAST_NAME]: Yup.string().required('Please enter last name'),
  [USER_PASSWORD]: passwordValidation
})

// avoid passing new_password to BE
export const transformValuesToBE = ({ [USER_PASSWORD]: newPassword, ...values }, userCountry, token) => {
  return {
    ...values,
    // "password" value is not using in Formik to avoid incorrect fields presetting PBR-118
    [PASSWORD]: newPassword,
    ...(userCountry && { country: userCountry }),
    token
  }
}
