import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles({
  container: {
    minWidth: 120,
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8
  },
  statusChip: {
    marginRight: 'unset'
  }
})

export default useStyles
