import { createStyles } from '../../../../styles/helpers'

import { radioCheckboxStyles } from '../../../../styles/common/components'
import { bigRadioLeftPadding } from '../../../../components/Form/Radio/style'
import { darkGrey } from '../../../../styles/const/colors'

const useStyles = createStyles(theme => ({
  checkBoxBlockContainer: ({ withBorder, checked }) => ({
    padding: withBorder && 16,
    ...radioCheckboxStyles({
      isChecked: checked,
      theme,
      hasBorder: withBorder
    }),

    '& .checkbox': {
      margin: [0, '!important']
    }
  }),
  checkBoxBlockBody: {
    paddingTop: 12,
    paddingBottom: 8,
    paddingLeft: ({ withBorder }) => withBorder && bigRadioLeftPadding,
    fontSize: 12,
    color: darkGrey,
    '& p:not(:last-child)': {
      marginBottom: 12
    }
  }
}))

export default useStyles
