import React from 'react'
import classnames from 'classnames'

import ShowSkeleton from '../ShowSkeleton'

import useStyles from './styles'

function SkeletonDescriptionText({ className, isInProgress, showLoadingAnimation }) {
  const classes = useStyles()

  return (
    <ShowSkeleton
      isInProgress={isInProgress}
      className={classnames(classes.skeletonDescription, className)}
      showLoadingAnimation={showLoadingAnimation}
    />
  )
}

export default SkeletonDescriptionText
