import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import FileDetailsPreview from '../../../../../../../../../components/FileDetailsPreview'
import ItemsLoading from '../../../../../../../../../components/Loaders/ItemsLoading'
import SkeletonFileDetailsPreview from '../../../../../../../../../components/FileDetailsPreview/Skeleton'

import useMediaFilesLibrary from '../../../../../../../../ReusableFormFields/MediaFilesLibrary/hooks'
import { createJsonFromQueryString } from '../../../../../../../../../helpers/url'

import {
  clearGetBookingMediaFiles,
  getBookingMediaFiles
} from '../../../../../../../../../modules/actions/mediaOrdersBookings'

import {
  selectedControllerIdSelector,
  selectedSelfAccountSelector
} from '../../../../../../../../../modules/selectors/app'

import { FILE_SELECTED_NAME, FILE_SELECTED_URL } from '../../../../fields'
import {
  bookingMediaFilesSelector,
  getBookingMediaFilesIsLoadingSelector,
  getBookingMediaFilesErrorSelector,
  getBookingMediaFilesWasLoadedSelector,
  getBookedMediaFilesNextSelector
} from '../../../../../../../../../modules/selectors/mediaOrdersBookings'

import useStyles from '../styles'

function BookedMediaFilesLibrary({ values, setFieldValue, fileFormats }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const existedFiles = useSelector(bookingMediaFilesSelector)
  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)
  const nextMedia = useSelector(getBookedMediaFilesNextSelector)
  const selectedSelfAccountController = useSelector(selectedControllerIdSelector)

  const onMediaSelect = useCallback(
    selectedFile => {
      setFieldValue(FILE_SELECTED_NAME, selectedFile.name)
      setFieldValue(FILE_SELECTED_URL, selectedFile.file_url)
    },
    [setFieldValue]
  )

  const loadMoreHandler = useCallback(() => {
    dispatch(getBookingMediaFiles(createJsonFromQueryString(`?${nextMedia.split('?')[1]}`)))
  }, [dispatch, nextMedia])

  const loadInitialMediaHandler = useCallback(() => {
    dispatch(
      getBookingMediaFiles({
        account: selectedSelfAccount,
        controller: selectedSelfAccountController,
        // Filters by file extension. This filter applies to both file_url and file_name fields.
        // Accepts multiple extensions separated by &. Example: /api/uploaded_files/?extension=png&jpeg
        extension: fileFormats.join('&'),
        ordering: 'created',
        limit: 8
      })
    )
  }, [dispatch, selectedSelfAccount, selectedSelfAccountController, fileFormats])

  const clearMediaHandler = useCallback(() => {
    dispatch(clearGetBookingMediaFiles())
  }, [dispatch])

  useMediaFilesLibrary({
    clearMediaHandler,
    loadInitialMediaHandler
  })

  return (
    <ItemsLoading
      SkeletonComponent={SkeletonFileDetailsPreview}
      isLoadingSelector={getBookingMediaFilesIsLoadingSelector}
      wasLoadedSelector={getBookingMediaFilesWasLoadedSelector}
      errorSelector={getBookingMediaFilesErrorSelector}
      itemsLength={existedFiles.length}
      initialLoadingSkeletonsNumber={8}
      loadMore={nextMedia ? loadMoreHandler : null}
      noDataText={'No media files found'}
      noDataWithoutIconAndDescription
    >
      {existedFiles.map(file => (
        <FileDetailsPreview
          className={classnames(classes.fileDetailContainer, {
            [classes.activeFileDetailContainer]: values[FILE_SELECTED_URL] === file.file_url
          })}
          key={file.id}
          fileName={file.name}
          previewUrl={file.thumbnail}
          fileFormat={file.file_format}
          filePreviewType={'image'}
          onClick={() => onMediaSelect(file)}
        />
      ))}
    </ItemsLoading>
  )
}

BookedMediaFilesLibrary.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  fileFormats: PropTypes.array
}

export default BookedMediaFilesLibrary
