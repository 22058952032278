import * as Yup from 'yup'

import {
  AUDIENCES_OPTION,
  CUSTOM_AUDIENCES,
  CUSTOM_AUDIENCES_EXCLUSION,
  CUSTOM_AUDIENCES_EXCLUSION_CHECKED,
  customAudiencesInitialValues
} from '../../../../../../../ReusableFormFields/CustomAudiencesFields/fields'
import { OPTION_YES } from '../../../../../../../../constants/forms'
import { promotedObjectInitialValues } from '../../../../../../../ReusableFormFields/PromotedObjectFields/fields'
import {
  initialGeolocationsValues,
  LOCATION_LIST,
  SHOW_COUNTRIES,
  SHOW_OPTION,
  SHOW_OPTION_COUNTRIES,
  SHOW_OPTION_LOCATION_LIST,
  SHOW_OPTION_REGIONS,
  SHOW_REGIONS
} from '../../../../../../../ReusableFormFields/GeolocationsFields/fields'
import {
  regionsOverlapValidation,
  showCountriesValidation
} from '../../../../../../../../features/validations/gelolocations'
import { locationListValidation } from '../../../../../../../ReusableFormFields/LineItemForms/fields'
import {
  END_DATE,
  endDateValidation,
  START_DATE,
  startDateValidation
} from '../../../../../../../ReusableFormFields/StartEndDateRangeFields/fields'

// line_item
export const LINE_ITEM_OPTION = 'line_item_option'
export const OPTION_EXISTING_LINE_ITEM = 'option_existing_line_item'
export const OPTION_NEW_LINE_ITEM = 'option_new_line_item'
export const LINE_ITEM = 'line_item'
// budget
export const BUDGET_LIFETIME = 'budget_lifetime'

export const getInitialValues = isLineItemsExist => ({
  // line_item
  [LINE_ITEM_OPTION]: isLineItemsExist ? OPTION_EXISTING_LINE_ITEM : OPTION_NEW_LINE_ITEM,
  [LINE_ITEM]: '',
  // budget
  [BUDGET_LIFETIME]: '',
  // dates
  [START_DATE]: '',
  [END_DATE]: '',
  // geolocations
  ...initialGeolocationsValues,
  // custom audiences,
  ...customAudiencesInitialValues,
  [AUDIENCES_OPTION]: OPTION_YES,
  // promoted object
  ...promotedObjectInitialValues
})

export const getValidationSchema = campaign =>
  Yup.object({
    // line item
    [LINE_ITEM]: Yup.string().when(LINE_ITEM_OPTION, {
      is: OPTION_EXISTING_LINE_ITEM,
      then: () => Yup.string().required('Please select line item')
    }),
    // dates
    [START_DATE]: Yup.string().when(LINE_ITEM_OPTION, {
      is: OPTION_NEW_LINE_ITEM,
      then: () => startDateValidation
    }),
    [END_DATE]: Yup.string().when(LINE_ITEM_OPTION, {
      is: OPTION_NEW_LINE_ITEM,
      then: () => endDateValidation
    }),
    // budget
    ...(!campaign['budget_lifetime'] && {
      [BUDGET_LIFETIME]: Yup.string().when(LINE_ITEM_OPTION, {
        is: OPTION_NEW_LINE_ITEM,
        then: () => Yup.string().required('Budget Required')
      })
    }),
    // geolocations
    [SHOW_COUNTRIES]: Yup.array().when(LINE_ITEM_OPTION, {
      is: OPTION_NEW_LINE_ITEM,
      then: () =>
        Yup.array().when(SHOW_OPTION, {
          is: SHOW_OPTION_COUNTRIES,
          then: () => showCountriesValidation
        })
    }),
    [LOCATION_LIST]: Yup.string().when(LINE_ITEM_OPTION, {
      is: OPTION_NEW_LINE_ITEM,
      then: () =>
        Yup.string().when(SHOW_OPTION, {
          is: SHOW_OPTION_LOCATION_LIST,
          then: () => locationListValidation.required('Please select location list')
        })
    }),
    [SHOW_REGIONS]: Yup.array().when(LINE_ITEM_OPTION, {
      is: OPTION_NEW_LINE_ITEM,
      then: () =>
        Yup.array().when(SHOW_OPTION, {
          is: SHOW_OPTION_REGIONS,
          then: () => regionsOverlapValidation
        })
    }),
    // custom audiences,
    [CUSTOM_AUDIENCES]: Yup.array().when(LINE_ITEM_OPTION, {
      is: OPTION_NEW_LINE_ITEM,
      then: () =>
        Yup.array().when(AUDIENCES_OPTION, {
          is: OPTION_YES,
          then: () => Yup.array().min(1, 'Please select at least one option')
        })
    }),
    [CUSTOM_AUDIENCES_EXCLUSION]: Yup.array().when(LINE_ITEM_OPTION, {
      is: OPTION_NEW_LINE_ITEM,
      then: () =>
        Yup.array().when(CUSTOM_AUDIENCES_EXCLUSION_CHECKED, {
          is: true,
          then: () => Yup.array().min(1, 'Please select at least one option')
        })
    })
  })
