import React from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Field from '../../../../components/Form/Field'
import Form from '../../../../components/Form'

import { validationSchema } from './validation'
import { getInitialValues, NAME } from './fields'

import useDrawerFormsStyles from '../../../../styles/common/drawerForms'

function MediaCategoryForm({
  editCategoryData,
  formName,
  onSubmit,
  isCreateForm,
  successSubmit,
  isLoadingSelector,
  errorSelector,
  clearHandler
}) {
  const drawerFormsClasses = useDrawerFormsStyles()

  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: getInitialValues(editCategoryData),
    validationSchema,
    onSubmit,
    enableReinitialize: !isCreateForm
  })

  return (
    <Form
      formik={formik}
      formName={formName}
      successSubmit={successSubmit}
      errorSelector={errorSelector}
      isLoadingSelector={isLoadingSelector}
      clearHandler={clearHandler}
    >
      <section className={drawerFormsClasses.section}>
        <h4 className={drawerFormsClasses.sectionTitle}>{t(isCreateForm ? 'New category name' : 'Category name')}</h4>
        <Field formik={formik} id={NAME} name={NAME} label="Name" />
      </section>
    </Form>
  )
}

MediaCategoryForm.propTypes = {
  editCategoryData: PropTypes.object,
  isCreateForm: PropTypes.bool,
  formName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  successSubmit: PropTypes.bool.isRequired,
  isLoadingSelector: PropTypes.func.isRequired,
  errorSelector: PropTypes.func.isRequired,
  clearHandler: PropTypes.func.isRequired
}

export default MediaCategoryForm
