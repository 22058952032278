import React from 'react'

import Chip from '../../../../../../components/Chip'

import { getStatusTagColor } from '../../../../../../features/helpers/componentsHelpers'

import { CHIP_COLORS } from '../../../../../../constants/other'

const AdStatusChip = ({ ad }) => {
  let status = ad?.status

  const isDisapproved =
    // effective_status is property on real ad (not internal ad)
    ad?.effective_status === 'disapproved' ||
    // approval_status is property on internal ad
    ad?.approval_status === 'disapproved'

  const isPendingApproval = ad.internal && ad.approval_status === 'pending_approval'

  let color = getStatusTagColor(status)

  if (isDisapproved) {
    status = 'Disapproved'
    color = CHIP_COLORS.error
  }

  if (isPendingApproval) {
    status = 'In Review'
    color = CHIP_COLORS.neutral
  }

  if (!status && ad?.effective_status !== 'disapproved') {
    return null
  }

  return <Chip text={status} color={color} />
}

export default AdStatusChip
