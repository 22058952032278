import { createStyles } from '../../../../styles/helpers'

import { lightGrey, red } from '../../../../styles/const/colors'
import { drawerFormSection, drawerFormSectionTitle } from '../../../../styles/common/drawerForms'
import { transitionShort } from '../../../../styles/const/common'

// When the component renders for the first time, isValid is undefined
// So we must check if isValid is true or undefined and there are no errors with a media file
// to add a proper border to carousel item form component
// (red if errors, brandPrimary if active without errors, and grey if not active without errors)
const borderColor = (props, defaultColor) => {
  const { isItemActive, isValid, mediaFileError } = props
  const isNoErrors = (isValid || isValid === undefined) && !mediaFileError

  if (!isItemActive && isNoErrors) {
    return lightGrey
  } else if (isItemActive && isNoErrors) {
    return defaultColor
  } else if (!isNoErrors) {
    return red
  }
}

const useStyles = createStyles(theme => ({
  carouselItemForm: {
    extend: drawerFormSection(theme),
    marginBottom: 16,
    borderColor: props => borderColor(props, theme.brandPrimary),
    '& .checkbox': {
      marginTop: 24
    }
  },
  carouselItemFormMinimized: {
    cursor: 'pointer',
    transition: transitionShort,

    '&:hover': {
      boxShadow: '0 1px 5px 2px rgba(0,0,0,.05)'
    }
  },
  title: {
    extend: drawerFormSectionTitle,
    marginBottom: 0,
    paddingRight: 32
  },
  iconButton: {
    width: 'auto',
    '& svg': {
      width: 20
    }
  },
  removePostBtn: {
    position: 'absolute',
    top: 10,
    right: 42
  },
  addAnotherButton: {
    marginTop: 20,
    width: '100%',
    padding: '15px 25px'
  }
}))

export default useStyles
