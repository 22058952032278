import { createStyles } from '../../../../../styles/helpers'

import { darkGrey, mediumGrey } from '../../../../../styles/const/colors'

const useStyles = createStyles(theme => ({
  container: {
    height: 30,
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '30px',
    border: `1px solid ${mediumGrey}`,
    backgroundColor: 'white',
    padding: '6px 7px',
    marginLeft: 'auto',
    marginBottom: 10
  },
  rangeItem: {
    color: darkGrey,
    padding: '2px 5px',
    cursor: 'pointer',

    '&:not(:last-child)': {
      borderRight: `1px solid ${mediumGrey}`
    },

    '&:hover': {
      color: theme.brandPrimary
    },

    '&.active': {
      color: theme.brandPrimary,
      fontWeight: 600
    }
  }
}))

export default useStyles
