import { createStyles } from '../../../../styles/helpers'

const useAudienceStyles = createStyles({
  suggestionButton: {
    width: 88,
    height: 34,
    padding: '0 20px',
    marginTop: 2
  },
  suggestionButtonWrapper: {
    width: 'unset',
    overflow: 'unset'
  }
})

export default useAudienceStyles
