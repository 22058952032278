import { delay, call, put, all, takeEvery, select } from 'redux-saga/effects'

import {
  FILE_UPLOAD_START,
  fileUploadSuccess,
  fileUploadFailure,
  fileUploadToServer,
  FILE_UPLOAD_TO_SERVER,
  fileUploadToServerSuccess,
  fileUploadToServerFailure,
  FILE_UPLOAD_TO_FACEBOOK_PLATFORM,
  fileUploadToFacebookPlatform,
  FILE_UPLOAD_TO_TIKTOK_PLATFORM,
  fileUploadToTikTokPlatform,
  fileUploadToPlatformSuccess,
  fileUploadToPlatformFailure,
  GET_FACEBOOK_UPLOADED_VIDEO_DETAILS,
  getFacebookUploadedVideoDetails,
  GET_TIKTOK_UPLOADED_VIDEO_DETAILS,
  getTikTokUploadedVideoDetails,
  getUploadedVideoDetailsSuccess,
  getUploadedVideoDetailsFailure,
  GET_FILE_ACCESSIBLE_URL,
  getAccessibleUrlSuccess,
  getAccessibleUrlFailure
} from '../actions/files'

import {
  fileSignService,
  fileUploadToServerService,
  fileUploadToFacebookPlatformService,
  fileUploadToTikTokPlatformService,
  getFacebookVideoDetailsService,
  getTikTokVideoDetailsService,
  accessibleUrlService
} from '../services/files'

import { getFileType } from '../../features/helpers/other'
import { showToasts } from '../../helpers/toasts'
import { getErrorMessage } from '../../helpers/errors'

import { createAsset } from '../actions/assets'

import { selectedPlatformSelector, selectedAdAccountIdSelector } from '../selectors/app'

import { TOAST_TYPE } from '../../constants/other'
import { FILE_IS_ACTIVE, FILE_IS_PROCESSING } from '../../constants/ads'
import {
  DV_360_PLATFORM,
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  TIKTOK_PLATFORM
} from '../../constants/selectLists/platformList'

function* filesWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(FILE_UPLOAD_START, fileUploadWorker),
    takeEvery(FILE_UPLOAD_TO_SERVER, fileUploadToServerWorker),
    takeEvery(FILE_UPLOAD_TO_FACEBOOK_PLATFORM, fileUploadToFacebookPlatformWorker),
    takeEvery(FILE_UPLOAD_TO_TIKTOK_PLATFORM, fileUploadToTikTokPlatformWorker),
    takeEvery(GET_FACEBOOK_UPLOADED_VIDEO_DETAILS, getFacebookUploadedVideoDetailsWorker),
    takeEvery(GET_TIKTOK_UPLOADED_VIDEO_DETAILS, getTikTokUploadedVideoDetailsWorker),
    takeEvery(GET_FILE_ACCESSIBLE_URL, getFileAccessibleUrlWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* fileUploadWorker({ file, uploadOptions }) {
  const { isPrivate } = uploadOptions
  try {
    const signedData = yield call(fileSignService, file, isPrivate)
    const signedFileData = {
      file,
      ...signedData
    }

    yield put(fileUploadSuccess(signedFileData))
    yield put(fileUploadToServer(signedFileData, uploadOptions))
  } catch (e) {
    yield put(fileUploadFailure(e, file.name))
  }
}

function* fileUploadToServerWorker({ signedFile, uploadOptions }) {
  try {
    const { adAccountId, isPlatformRelated, platform: providedPlatform } = uploadOptions

    const selectedAdAccount = yield select(selectedAdAccountIdSelector)

    yield call(fileUploadToServerService, signedFile)
    const fileUrl = `${signedFile.url}${signedFile.fields.key}`
    const fileName = signedFile.file.name

    if (isPlatformRelated) {
      const selectedPlatform = yield select(selectedPlatformSelector)

      const platform = providedPlatform || selectedPlatform

      if (platform === FACEBOOK_PLATFORM) {
        yield put(
          fileUploadToFacebookPlatform(
            fileUrl,
            fileName,
            getFileType(signedFile.file),
            adAccountId || selectedAdAccount
          )
        )
      } else if (platform === GOOGLE_PLATFORM) {
        const assetData = {
          // if ad account is passed from outside > use it instead of selected one
          account: adAccountId || selectedAdAccount,
          name: fileName,
          image_asset: {
            data: fileUrl
          }
        }

        // to upload the file to GOOGLE_PLATFORM the asset should be created:
        yield put(createAsset(assetData, true, platform))
      } else if (platform === TIKTOK_PLATFORM) {
        yield put(fileUploadToTikTokPlatform(fileUrl, fileName, getFileType(signedFile.file), adAccountId))
      } else if (platform === DV_360_PLATFORM) {
        const assetData = {
          account: adAccountId,
          name: fileName,
          url: fileUrl
        }
        // to upload the file to DV_360_PLATFORM the asset should be created:
        yield put(createAsset(assetData, true, DV_360_PLATFORM))
      }
    } else {
      // when the file uploading not PlatformRelated then the file uploading process finishes here:
      yield put(fileUploadToServerSuccess(fileUrl, signedFile.file.name, isPlatformRelated))
    }
  } catch (e) {
    yield put(fileUploadToServerFailure(e, signedFile.file.name))
    showToasts({
      type: TOAST_TYPE.error,
      message:
        'Sorry, there was an error uploading that file, please try again. If you keep experiencing errors please check the file or contact support.'
    })
  }
}

function* fileUploadToFacebookPlatformWorker({ fileData, fileName, fileType, adAccountId }) {
  try {
    const platformFileData = yield call(fileUploadToFacebookPlatformService, fileData, fileType, fileName, adAccountId)
    if (fileType === 'video') {
      // video requires the processing by facebook which takes some time
      // so the source URL is returned only after processing is finished
      // for this reason getFacebookUploadedVideoDetails is calling periodically to get the status
      yield put(fileUploadToPlatformSuccess({ ...platformFileData, status: FILE_IS_PROCESSING }, fileName))
      yield put(getFacebookUploadedVideoDetails(platformFileData.id, fileName, adAccountId))
    } else {
      yield put(fileUploadToPlatformSuccess(platformFileData, fileName))
    }
  } catch (e) {
    yield put(fileUploadToPlatformFailure(e, fileName))

    const errorMessage = e && getErrorMessage(e)
    errorMessage &&
      showToasts({
        type: TOAST_TYPE.error,
        message: errorMessage
      })
  }
}

function* fileUploadToTikTokPlatformWorker({ fileUrl, fileName, fileType, adAccountId }) {
  try {
    const payloadData = {
      [fileType === 'image' ? 'image_url' : 'video_url']: fileUrl,
      account: adAccountId
    }

    const platformFileData = yield call(fileUploadToTikTokPlatformService, payloadData, fileName, fileType)

    yield put(
      fileUploadToPlatformSuccess(
        {
          ...platformFileData,
          // duplicate some video fields to our FE expected namings (url, id)
          ...(fileType === 'video' && {
            url: fileUrl,
            id: platformFileData['video_id']
          })
        },
        fileName
      )
    )
  } catch (e) {
    yield put(fileUploadToPlatformFailure(e, fileName))

    const errorMessage = e && getErrorMessage(e)
    errorMessage &&
      showToasts({
        type: TOAST_TYPE.error,
        message: errorMessage
      })
  }
}

function* getFacebookUploadedVideoDetailsWorker({ videoId, fileName, adAccountId }) {
  try {
    const videoFileData = yield call(getFacebookVideoDetailsService, videoId, adAccountId)

    if (videoFileData.status === 'active') {
      // get url after processing
      yield put(getUploadedVideoDetailsSuccess(videoFileData, fileName))
    } else if (videoFileData.status === 'error') {
      showToasts({
        type: TOAST_TYPE.error,
        message:
          'Sorry, there was an error uploading that file, please try again. If you keep experiencing errors please check the file or contact support.'
      })
      yield put(getUploadedVideoDetailsFailure(videoFileData, fileName))
    } else {
      // SHOP-453 Periodically check the endpoint until either we get a URL for the video
      // or get a failure message from FB.
      yield delay(3000)
      yield put(getFacebookUploadedVideoDetails(videoId, fileName, adAccountId))
    }
  } catch (e) {
    yield put(getUploadedVideoDetailsFailure(e, fileName))
  }
}

function* getTikTokUploadedVideoDetailsWorker({ videoId, fileName, adAccountId }) {
  try {
    const videoFileData = yield call(getTikTokVideoDetailsService, videoId, adAccountId)

    if (videoFileData.url) {
      yield put(getUploadedVideoDetailsSuccess({ ...videoFileData, status: FILE_IS_ACTIVE }, fileName, adAccountId))
    } else {
      // periodically check the endpoint until we get a URL for the video
      yield delay(3000)
      yield put(getTikTokUploadedVideoDetails(videoId, fileName, adAccountId))
    }
  } catch (e) {
    yield put(getUploadedVideoDetailsFailure(e, fileName))
  }
}

function* getFileAccessibleUrlWorker({ url, params }) {
  try {
    const accessibleUrl = yield call(accessibleUrlService, url, params)

    yield put(getAccessibleUrlSuccess(url, accessibleUrl.url))
  } catch (e) {
    yield put(getAccessibleUrlFailure(e, url))
  }
}

export default filesWatcher
