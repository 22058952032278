import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

export const isLoadingSelector = createSelector(
  ({ auth }) => auth.isLoading,
  isLoading => isLoading
)

export const acceptInviteDataSelector = createSelector(
  ({ auth }) => auth.acceptInvite.data,
  data => data
)

export const acceptInviteLoadingSelector = createSelector(
  ({ auth }) => auth.acceptInvite.isLoading,
  isLoading => isLoading
)

export const acceptInviteErrorSelector = createSelector(
  ({ auth }) => auth.acceptInvite.error,
  error => error
)

export const isSocialAuthLoadingSelector = createSelector(
  ({ auth }) => auth.socialAuth.isLoading,
  isLoading => isLoading
)

export const socialAuthErrorSelector = createSelector(
  ({ auth }) => auth.socialAuth.error,
  error => error
)

export const resendRegisterLoadingSelector = createSelector(
  ({ auth }) => auth.resendRegister.isLoading,
  isLoading => isLoading
)

export const resendRegisterErrorSelector = createSelector(
  ({ auth }) => auth.resendRegister.error,
  error => error
)
