import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import MultipleCheckboxesSelect from '../../../../components/MultipleCheckboxesSelect'

import { formatOptionsList } from '../../../formatters'

import { mediaSubCategoriesSelector } from '../../../../modules/selectors/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import { getSelfAccountCategories, getSelfAccountSubCategories } from '../../../../modules/actions/selfAccounts'
import {
  selfAccountCategoriesSelector,
  selfAccountSubCategoriesSelector
} from '../../../../modules/selectors/selfAccounts'

import useStyles from './styles'

const MultipleSelfAccountCategoriesFilters = ({ Context }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { brandCategory, setBrandCategory, brandSubCategory, setBrandSubCategory } = useContext(Context)

  const mediaSubCategories = useSelector(mediaSubCategoriesSelector)
  const controllerId = useSelector(selectedControllerIdSelector)
  const selfAccountCategories = useSelector(selfAccountCategoriesSelector)
  const selfAccountSubCategories = useSelector(selfAccountSubCategoriesSelector)

  const formattedMediaSubCategoriesOptions = useMemo(() => {
    return formatOptionsList({
      list: mediaSubCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [mediaSubCategories])

  const formattedSelfAccountCategoriesOptions = useMemo(() => {
    return formatOptionsList({
      list: selfAccountCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [selfAccountCategories])

  const formattedSelfAccountSubCategoriesOptions = useMemo(() => {
    return formatOptionsList({
      list: selfAccountSubCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [selfAccountSubCategories])

  const selectBrandCategoryHandler = useCallback(
    data => {
      setBrandCategory(data)
    },
    [setBrandCategory]
  )

  const selectBrandSubCategoryHandler = useCallback(
    data => {
      setBrandSubCategory(data)
    },
    [setBrandSubCategory]
  )

  useEffect(() => {
    dispatch(
      getSelfAccountCategories({
        controller: controllerId
      })
    )
  }, [dispatch, controllerId])

  useEffect(() => {
    dispatch(
      getSelfAccountSubCategories({
        controller: controllerId
      })
    )
  }, [dispatch, controllerId])

  return (
    // <div className={classes.categoriesFiltersContainer}>
    <>
      <MultipleCheckboxesSelect
        className={classes.brandCategorySelect}
        options={formattedSelfAccountCategoriesOptions}
        value={brandCategory}
        onChange={selectBrandCategoryHandler}
        placeholder="Brand Category"
        multipleOptionsLabel="Brand Category"
        isSearchable={true}
      />
      {!!formattedMediaSubCategoriesOptions.length && (
        <MultipleCheckboxesSelect
          className={classes.brandSubCategorySelect}
          options={formattedSelfAccountSubCategoriesOptions}
          value={brandSubCategory}
          onChange={selectBrandSubCategoryHandler}
          placeholder="Brand Sub Category"
          multipleOptionsLabel="Brand Sub Category"
          isSearchable={true}
        />
      )}
    </>
  )
}

MultipleSelfAccountCategoriesFilters.propTypes = {
  Context: PropTypes.object.isRequired
}

export default MultipleSelfAccountCategoriesFilters
