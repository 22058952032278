import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  footer: {
    marginTop: 30
  },
  logout: {
    cursor: 'pointer'
  }
})

export default useStyles
