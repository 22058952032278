import { createStyles } from '../../styles/helpers'

import { greyOutline, lightGrey, textGrey } from '../../styles/const/colors'
import { tabletDown } from '../../styles/const/breakpoints'
import { inputHeight, inputMobileHeight } from '../../styles/common/components'

export default createStyles(theme => ({
  timePicker: {
    width: '100% !important',
    height: inputHeight,
    background: 'white',
    fontSize: 14,
    color: textGrey,
    padding: '0 14px',
    outline: 'none',
    appearance: 'none',
    border: {
      width: 1,
      style: 'solid',
      color: greyOutline,
      radius: theme.defaultBorderRadius
    },
    '&:focus': {
      borderColor: theme.brandPrimary
    },
    '&:disabled': {
      background: lightGrey
    }
  },
  [`@media screen and (${tabletDown})`]: {
    timePicker: {
      height: inputMobileHeight
    }
  }
}))
