import { createStyles } from '../../../../../../styles/helpers'
import { textGrey, mediumGrey } from '../../../../../../styles/const/colors'

const useStyles = createStyles({
  formField: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 20px 16px',
    borderBottom: `1px solid ${mediumGrey}`,
    paddingBottom: 16,
    gap: 16,
    '&.isLastFormField': {
      border: 'none',
      paddingBottom: 0,
      marginBottom: 20
    }
  },
  formFieldLabel: {
    color: textGrey,
    minWidth: 80,
    fontWeight: 600
  },
  childrenWrapper: {
    width: 300
  }
})

export default useStyles
