import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Spreadsheet from 'react-spreadsheet'

// memoize SpreadSheetTable to avoid infinity onChange triggering after frequent data update
const SpreadSheetTable = memo(function WrappedSpreadSheetTable({
  data,
  onChange,
  RowIndicator,
  className,
  columnLabels
}) {
  return (
    <Spreadsheet
      data={data}
      onChange={onChange}
      className={className}
      RowIndicator={RowIndicator}
      columnLabels={columnLabels}
    />
  )
})

SpreadSheetTable.propTypes = {
  debouncedCurrentValue: PropTypes.array,
  onChange: PropTypes.func,
  RowIndicator: PropTypes.func,
  className: PropTypes.string,
  columnLabels: PropTypes.array
}

export default SpreadSheetTable
