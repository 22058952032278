import { createStyles } from '../../../../../../../styles/helpers'
import { grey } from '../../../../../../../styles/const/colors'

const useStyles = createStyles({
  additionalInfo: {
    textAlign: 'center',
    marginTop: 16,
    fontSize: 14,
    color: grey
  }
})

export default useStyles
