import React, { useCallback, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { ReactComponent as AngleIcon } from '../../assets/icons/chevron-arrow-down.svg'

import useStyles from './styles'
import useSidebarPageStyles from '../../styles/common/sidebarPage'

const MinimisedSection = ({
  defaultOpened = false,
  showToggleButton = true,
  headerContent,
  children,
  className,
  headerClassName,
  toggleWrapperClassName,
  isFullyClickable = true,
  toggleButtonClassName
}) => {
  const [height, setHeight] = useState(defaultOpened ? 'auto' : 0)

  const sidebarPageClasses = useSidebarPageStyles()

  const classes = useStyles({ isOpened: !!height })

  const toggleHandler = useCallback(() => {
    if (showToggleButton) {
      setHeight(height === 0 ? 'auto' : 0)
    }
  }, [height, showToggleButton])

  return (
    <div className={classnames(sidebarPageClasses.section, classes.section, className)}>
      <div
        className={classnames(classes.header, headerClassName)}
        onClick={isFullyClickable ? toggleHandler : undefined}
      >
        {showToggleButton && (
          <div
            onClick={isFullyClickable ? undefined : toggleHandler}
            className={classnames(classes.toggleButtonWrapper, toggleWrapperClassName)}
          >
            <button type="button" className={classnames(classes.toggleButton, toggleButtonClassName)}>
              <AngleIcon />
            </button>
          </div>
        )}
        {headerContent && headerContent}
      </div>
      <AnimateHeight duration={300} height={height}>
        {children}
      </AnimateHeight>
    </div>
  )
}

MinimisedSection.propTypes = {
  defaultOpened: PropTypes.bool,
  showToggleButton: PropTypes.bool,
  headerContent: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  toggleWrapperClassName: PropTypes.string,
  toggleButtonClassName: PropTypes.string
}

export default MinimisedSection
