import { createStyles } from '../../../../../../../../../styles/helpers'

import { red, textGrey } from '../../../../../../../../../styles/const/colors'

const useStyles = createStyles({
  product: {
    fontSize: 12,
    color: textGrey,
    marginTop: 8
  },
  isNotAvailable: {
    color: red
  }
})

export default useStyles
