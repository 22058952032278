import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import TableDataLoader from '../../../../../components/Table/TableDataLoader'
import AmendmentsTableData from './AmendmentsTableData'

import {
  amendmentsListSelector,
  getAmendmentsListIsLoadingSelector,
  getAmendmentsListErrorSelector,
  getAmendmentsListWasLoadedSelector
} from '../../../../../modules/selectors/amendments'

import useCommonStyles from '../../../../../styles/common/table'

export const columnsSize = {
  icon: 60,
  name: 200,
  account: 100,
  sendOn: 100,
  status: 100,
  actions: 40
}

const AmendmentsTable = ({ loadMoreHandler }) => {
  const { t } = useTranslation()

  const commonClasses = useCommonStyles()

  const amendments = useSelector(amendmentsListSelector)

  const amendmentsColumnsSchema = useMemo(
    () => [
      {
        style: { maxWidth: columnsSize.icon }
      },
      {
        header: 'Name'
      },
      {
        header: 'Controller sign'
      },
      {
        header: 'Sent on'
      },
      {
        header: 'Status'
      },
      {
        style: { maxWidth: columnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no amendments')}</div>
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <TableDataLoader
      itemsLength={amendments.length}
      errorSelector={getAmendmentsListErrorSelector}
      wasLoadedSelector={getAmendmentsListWasLoadedSelector}
      isLoadingSelector={getAmendmentsListIsLoadingSelector}
      skeletonProps={{ cols: amendmentsColumnsSchema }}
      noDataContent={noDataContent}
      loadMore={loadMoreHandler}
    >
      <AmendmentsTableData />
    </TableDataLoader>
  )
}

AmendmentsTable.propTypes = {
  loadMoreHandler: PropTypes.func
}

export default AmendmentsTable
