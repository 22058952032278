import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles({
  dateStart: {
    marginLeft: 'auto',
    maxWidth: 210
  }
})

export default useStyles
