import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { eachDayOfInterval } from 'date-fns'

import { formatDateToBE } from '../../../../../../../../../../../../constants/dates'

export function useGetDisabledDates(props) {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'useGetDisabledDates')

  const { firstPeriod, lastPeriod, availablePeriods, selectedPeriods, isEndDateSelection } = props

  return useMemo(() => {
    if (firstPeriod && lastPeriod) {
      // find earliest and latest date in periodOptions to generate all available dates in range
      const allInventoryDates = eachDayOfInterval({
        start: new Date(firstPeriod),
        end: new Date(lastPeriod)
      })

      // filter not selected and not available day cells to be selected from allInventoryDates
      return allInventoryDates.filter(date => {
        // check if date is already selected
        const isDateSelected = !!selectedPeriods?.find(period => period.value === formatDateToBE(date))
        // disable if period is not available
        const isPeriodAvailable = !!availablePeriods.find(period => {
          if (isEndDateSelection) {
            return period.date_end === formatDateToBE(date)
          } else {
            return period.value === formatDateToBE(date)
          }
        })

        // disable if period is disabled or date is already selected
        return !isPeriodAvailable || isDateSelected
      })
    } else if (lastPeriod) {
      // when no first period is available disable all dates from today to last period
      // that could be the case if the first available period is in few months from now
      return eachDayOfInterval({
        start: new Date(),
        end: new Date(lastPeriod)
      })
    } else {
      return []
    }
  }, [availablePeriods, firstPeriod, isEndDateSelection, lastPeriod, selectedPeriods])
}

const propTypes = {
  firstPeriod: PropTypes.string,
  lastPeriod: PropTypes.string,
  availablePeriods: PropTypes.array,
  selectedPeriods: PropTypes.array,
  isEndDateSelection: PropTypes.bool
}
