import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectedSelfAccountDataSelector } from '../../../modules/selectors/app'
import { mediaOrderDigitalProductsSelector } from '../../../modules/selectors/mediaOrders'

import {
  DV_360_PROVIDER_PLATFORM,
  FACEBOOK_PROVIDER_PLATFORM,
  GOOGLE_PROVIDER_PLATFORM,
  TIKTOK_PROVIDER_PLATFORM
} from '../../../constants/selectLists/platformList'

const useAvailableAdAccountsList = () => {
  const digitalProducts = useSelector(mediaOrderDigitalProductsSelector)

  const selectedSelfAccount = useSelector(selectedSelfAccountDataSelector)

  const platformsToAdAccounts = useMemo(
    () => ({
      [FACEBOOK_PROVIDER_PLATFORM]: selectedSelfAccount['fb_ad_account'],
      [GOOGLE_PROVIDER_PLATFORM]: selectedSelfAccount['ga_ad_account'],
      [DV_360_PROVIDER_PLATFORM]: selectedSelfAccount['dv360_ad_account'],
      [TIKTOK_PROVIDER_PLATFORM]: selectedSelfAccount['tiktok_ad_account']
    }),
    [selectedSelfAccount]
  )

  // if ad account exists under selected self account, and any digital product has corresponding provider
  // then return it as available ad account
  return useMemo(
    () =>
      [...new Set(digitalProducts.flatMap(product => product.providers))].reduce((result, provider) => {
        const adAccount = platformsToAdAccounts[provider]
        const isAdAccountAvailable = adAccount && Object.keys(adAccount).length

        return isAdAccountAvailable ? { ...result, [provider]: adAccount } : result
      }, {}),
    [digitalProducts, platformsToAdAccounts]
  )
}

export default useAvailableAdAccountsList
