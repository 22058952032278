import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import FacebookAudiencesFields from '../../../../../../../../ReusableFormFIelds/FacebookAudiencesFields'
import Button from '../../../../../../../../../../components/Button'

import { selectedControllerDataSelector } from '../../../../../../../../../../modules/selectors/app'

import {
  CUSTOM_AUDIENCES,
  CUSTOM_AUDIENCES_EXCLUSION
} from '../../../../../../../../../ReusableFormFields/CustomAudiencesFields/fields'

import useStyles from '../../../../../../../../../../styles/common/stepForms'

const stepFields = [CUSTOM_AUDIENCES, CUSTOM_AUDIENCES_EXCLUSION]

const CustomAudiencesStep = ({ formik, handleStepChange, adAccountId, isLastStep }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const selectedController = useSelector(selectedControllerDataSelector)

  const selectedControllerName = selectedController?.name

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  return (
    <>
      <div className={classes.stepTitle}>
        {t(selectedControllerName ? 'targetAudiencesQuestion' : 'targetAudiencesQuestionGeneric', {
          controllerName: selectedControllerName
        })}
      </div>
      <div className={classes.stepBody}>
        <FacebookAudiencesFields formik={formik} adAccountId={adAccountId} />
      </div>
      {!isLastStep && (
        <div className={classes.stepFooter}>
          <Button type="button" className="dark" onClick={handleContinue}>
            {t('Continue')}
          </Button>
        </div>
      )}
    </>
  )
}

export default CustomAudiencesStep
