import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as MuteIcon } from '../../../../../assets/icons/mute.svg'
import { ReactComponent as UnmuteIcon } from '../../../../../assets/icons/unmute.svg'

import useStyles from './styles'

function MuteButton({ onMuteSoundToggle, isSoundMuted }) {
  const classes = useStyles()

  const muteHandler = useCallback(() => {
    onMuteSoundToggle && onMuteSoundToggle()
  }, [onMuteSoundToggle])

  return (
    <span className={classes.muteButton} onClick={muteHandler}>
      {isSoundMuted ? <UnmuteIcon /> : <MuteIcon />}
    </span>
  )
}

MuteButton.propTypes = {
  onMuteToggle: PropTypes.func,
  isSoundMuted: PropTypes.bool
}

export default MuteButton
