import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Field from '../../../../../components/Form/Field'
import InternalLink from '../../../../../components/InternalLink'

import {
  locationListsErrorSelector,
  locationListsLoadingSelector,
  locationListsSelector,
  locationListsWasLoadedSelector
} from '../../../../../modules/selectors/location'
import { getLocationLists } from '../../../../../modules/actions/location'

import { LOCATION_LIST } from '../../fields'
import { ROUTES } from '../../../../../constants/routes'
import { selectedSelfAccountSelector } from '../../../../../modules/selectors/app'

const LocationsListTabContent = ({ formik }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const locationLists = useSelector(locationListsSelector)
  const locationListsWasLoaded = useSelector(locationListsWasLoadedSelector)
  const locationListsError = useSelector(locationListsErrorSelector)
  const locationListsLoading = useSelector(locationListsLoadingSelector)
  const selectedSelfAccountId = useSelector(selectedSelfAccountSelector)

  const locationDidLoading = locationListsWasLoaded || locationListsError

  const formattedLocationsList = useMemo(
    () =>
      locationLists.map(list => ({
        ...list,
        value: list.id,
        label: list.name,
        name: `${list.name} (${list.size})`
      })),

    [locationLists]
  )

  useEffect(() => {
    if (!locationListsWasLoaded && !locationListsLoading) {
      dispatch(
        getLocationLists({
          account: selectedSelfAccountId
        })
      )
    }
  }, [dispatch, locationListsWasLoaded, locationListsLoading, selectedSelfAccountId])

  if (locationDidLoading && !locationLists?.length) {
    return (
      <p>
        {t(`To use this feature, you must have already uploaded the list of locations on the `)}
        <InternalLink to={ROUTES.manageLocationLists}>{t('settings')}</InternalLink>
        {t(' page.')}
      </p>
    )
  }

  return (
    <Field
      isLoading={locationListsLoading}
      placeholder="Select Locations"
      formik={formik}
      name={LOCATION_LIST}
      options={formattedLocationsList}
    />
  )
}

export default LocationsListTabContent
