import React, { memo } from 'react'
import PropTypes from 'prop-types'
import TableRow from './TableRow'
import TableRowMobile from './TableRowMobile'
import TableRowExpandable from './TableRowExpandable'

const TableRowWrapper = memo(function WrappedTableRow({
  cols,
  isExpandable,
  renderExpandedContent,
  rowData,
  rowIndex,
  isBigRow,
  hasMobileHiddenColumns,
  mobileColumnsWrapperClassName,
  mobileMinimisedSectionBtnWrapperClassName,
  hasExpandRowSpacing,
  rowClassName,
  mobileRowClassName,
  columnClassName
}) {
  if (isExpandable) {
    if (hasMobileHiddenColumns) {
      return (
        <TableRowMobile
          cols={cols}
          rowData={rowData}
          rowIndex={rowIndex}
          isBigRow={isBigRow}
          mobileColumnsWrapperClassName={mobileColumnsWrapperClassName}
          mobileMinimisedSectionBtnWrapperClassName={mobileMinimisedSectionBtnWrapperClassName}
          renderExpandedContent={renderExpandedContent}
          rowClassName={mobileRowClassName}
        />
      )
    } else {
      return (
        <TableRowExpandable
          cols={cols}
          rowData={rowData}
          rowIndex={rowIndex}
          isBigRow={isBigRow}
          hasMobileHiddenColumns={hasMobileHiddenColumns}
          renderExpandedContent={renderExpandedContent}
        />
      )
    }
  } else if (hasMobileHiddenColumns) {
    return (
      <TableRowMobile
        cols={cols}
        rowData={rowData}
        rowIndex={rowIndex}
        isBigRow={isBigRow}
        mobileColumnsWrapperClassName={mobileColumnsWrapperClassName}
        mobileMinimisedSectionBtnWrapperClassName={mobileMinimisedSectionBtnWrapperClassName}
        rowClassName={mobileRowClassName}
      />
    )
  } else {
    return (
      <TableRow
        cols={cols}
        rowData={rowData}
        isBigRow={isBigRow}
        rowIndex={rowIndex}
        hasExpandRowSpacing={hasExpandRowSpacing}
        rowClassName={rowClassName}
        columnClassName={columnClassName}
      />
    )
  }
})

const colsPropTypeShape = {
  // header
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]),
  headClassName: PropTypes.string,
  // main cell
  Cell: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  fieldKey: PropTypes.string,
  className: PropTypes.string,
  // footer
  footerClassName: PropTypes.string
}

TableRowWrapper.propTypes = {
  rowData: PropTypes.object.isRequired,
  cols: PropTypes.arrayOf(PropTypes.shape(colsPropTypeShape)).isRequired,
  isBigRow: PropTypes.bool,
  rowIndex: PropTypes.number,
  mobileColumnsWrapperClassName: PropTypes.string,
  mobileMinimisedSectionBtnWrapperClassName: PropTypes.string,
  isExpandable: PropTypes.bool,
  renderExpandedContent: PropTypes.func,
  hasMobileHiddenColumns: PropTypes.bool,
  hasExpandRowSpacing: PropTypes.bool,
  rowClassName: PropTypes.string,
  columnClassName: PropTypes.string
}

export default TableRowWrapper
