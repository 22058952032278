import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useStyles from './styles'

const PageSection = ({ className, children, ...props }) => {
  const classes = useStyles()

  return (
    <div className={classnames(classes.container, className)} {...props}>
      {children}
    </div>
  )
}

PageSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default PageSection
