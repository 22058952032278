import React from 'react'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import CampaignFacebookCreateForm from './CampaignFacebookCreateForm'

import useRedirectFormDrawer from '../../../../features/components/FormDrawerWrapper/useRedirectFormDrawer'

import { CAMPAIGN_FACEBOOK_CREATE } from '../../../../constants/forms'
import { MODAL_TYPE } from '../../../../constants/other'
import { ROUTES } from '../../../../constants/routes'

function CampaignFacebookCreate() {
  const handleSuccessRedirect = useRedirectFormDrawer(
    `${ROUTES.campaignsSummary}?message=${MODAL_TYPE.newCampaignCreated}`
  )

  return (
    <FormDrawerWrapper
      title="Enter the campaign details"
      description="Please provide the following details for the new Meta campaign"
      openButtonText="Create Campaign"
      formName={CAMPAIGN_FACEBOOK_CREATE}
      closeOnSubmit={false}
    >
      <CampaignFacebookCreateForm onSuccessSubmit={handleSuccessRedirect} />
    </FormDrawerWrapper>
  )
}

export default CampaignFacebookCreate
