import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import MediaPackagesTableData from './MediaPackagesTableData'
import TableDataLoader from '../../../../components/Table/TableDataLoader'
import MediaPackageCreate from '../../../../forms/Multiplatform/MediaPackageForms/MediaPackageCreate'

import useOrderList from '../../../../hooks/useOrderList'

import {
  clearDeleteMediaPackage,
  clearUpdateMediaPackage,
  updateMediaPackagesOrder
} from '../../../../modules/actions/mediaPackages'
import {
  deleteMediaPackageWasDeletedSelector,
  mediaPackageCategoriesIsLoadingSelector,
  mediaPackagesErrorSelector,
  mediaPackagesIsLoadingSelector,
  mediaPackagesSelector,
  mediaPackagesWasLoadedSelector,
  updateMediaPackageErrorSelector,
  updateMediaPackageWasUpdatedSelector
} from '../../../../modules/selectors/mediaPackages'

import { phonesDownSize } from '../../../../styles/const/breakpoints'

import useCommonStyles from '../../../../styles/common/listPage'

export const tableColumnsSize = {
  dragBtn: 40,
  statusToggle: 70,
  thumbnail: 64,
  type: 90,
  status: 90,
  actions: 30
}

const spaceForMargin = 5

const mobileColumnsSchema = [
  { style: { maxWidth: `calc(${tableColumnsSize.dragBtn}px - ${spaceForMargin}px)`, marginInlineEnd: spaceForMargin } },
  {
    style: { maxWidth: `calc(${tableColumnsSize.thumbnail}px - ${spaceForMargin}px)`, marginInlineEnd: spaceForMargin }
  },
  { style: { maxWidth: '100%' } }
]

const MediaPackagesTable = () => {
  const { t } = useTranslation()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const commonClasses = useCommonStyles()
  const dispatch = useDispatch()

  const packageColumnsSchema = useMemo(
    () => [
      {
        style: { maxWidth: tableColumnsSize.dragBtn }
      },
      {
        style: { maxWidth: tableColumnsSize.statusToggle }
      },
      {
        style: { maxWidth: tableColumnsSize.thumbnail }
      },
      {
        header: 'Product Name'
      },
      {
        style: { maxWidth: tableColumnsSize.type }
      },
      {
        style: { maxWidth: tableColumnsSize.status }
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )
  const mediaPackages = useSelector(mediaPackagesSelector)
  const mediaPackageCategoriesIsLoading = useSelector(mediaPackageCategoriesIsLoadingSelector)
  const updateMediaPackageWasUpdated = useSelector(updateMediaPackageWasUpdatedSelector)
  const updateMediaPackageError = useSelector(updateMediaPackageErrorSelector)
  const mediaPackageWasDeleted = useSelector(deleteMediaPackageWasDeletedSelector)

  const { orderedList: orderedMediaPackages, handleOrderUpdate: handleMediaOrderUpdate } = useOrderList(
    mediaPackages,
    updateMediaPackagesOrder
  )

  useEffect(() => {
    if (updateMediaPackageWasUpdated || updateMediaPackageError) {
      dispatch(clearUpdateMediaPackage())
    }
  }, [dispatch, updateMediaPackageError, updateMediaPackageWasUpdated])

  useEffect(() => {
    if (mediaPackageWasDeleted) {
      dispatch(clearDeleteMediaPackage())
    }
  }, [dispatch, mediaPackageWasDeleted])

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no media packages')}</div>
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <>
      <TableDataLoader
        itemsLength={orderedMediaPackages.length}
        errorSelector={mediaPackagesErrorSelector}
        wasLoadedSelector={mediaPackagesWasLoadedSelector}
        isLoadingSelector={mediaPackagesIsLoadingSelector}
        skeletonProps={{ cols: isMobile ? mobileColumnsSchema : packageColumnsSchema }}
        noDataContent={noDataContent}
        additionalDataIsLoading={mediaPackageCategoriesIsLoading}
      >
        <MediaPackagesTableData packages={orderedMediaPackages} onOrderChange={handleMediaOrderUpdate} />
      </TableDataLoader>

      <MediaPackageCreate />
    </>
  )
}

export default MediaPackagesTable
