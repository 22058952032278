import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import Select from '../../Select'
import SelectCreatable from '../../SelectCreatable'
import SelectPaginated from '../../SelectPaginated'

function SelectField(props) {
  const { setFieldValue, setFieldTouched, isMulti, name, value, options, preselectFirstOptionValue, onValueChange } =
    props

  const handleChange = useCallback(
    (value, selectData) => {
      const newValue = value.value

      setFieldValue(selectData.name, newValue)
      setFieldTouched(selectData.name, true, false)
      // callback to handle some actions from outside
      onValueChange && onValueChange(newValue)
    },
    [setFieldValue, setFieldTouched, onValueChange]
  )

  const handleMultiChange = useCallback(
    (value, selectData) => {
      const newValue = value || []

      setFieldValue(selectData.name, newValue)
      setFieldTouched(selectData.name, true, false)
      // callback to handle some actions from outside
      onValueChange && onValueChange(newValue)
    },
    [setFieldValue, setFieldTouched, onValueChange]
  )

  const handleClear = useCallback(() => {
    setFieldValue(name, '')
  }, [setFieldValue, name])

  // If preselectFirstOptionValue prop is passed to the component
  // this useEffect preselects automatically preselects first option from the options list
  useEffect(() => {
    if (preselectFirstOptionValue && options.length && !value) {
      setFieldValue(name, options[0].value)
    }
  }, [preselectFirstOptionValue, options, name, value, setFieldValue])

  if (props.loadOptions) {
    return (
      <SelectPaginated onClearInput={handleClear} onChange={isMulti ? handleMultiChange : handleChange} {...props} />
    )
  }
  return props.onCreateOption ? (
    <SelectCreatable onClearInput={handleClear} {...props} onChange={isMulti ? handleMultiChange : handleChange} />
  ) : (
    <Select onClearInput={handleClear} {...props} onChange={isMulti ? handleMultiChange : handleChange} />
  )
}

export default SelectField

SelectField.propTypes = {
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  preselectFirstOptionValue: PropTypes.bool,
  onCreateOption: PropTypes.func,
  isLoading: PropTypes.bool
}
