import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getControllersService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.controllers
  })
}

export function getControllerMembersService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.controllerMembers
  })
}

export function getControllerInvitesService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.controllerInvites
  })
}

export function createControllerInviteService(inviteData) {
  return madeRequest('POST', {
    data: inviteData,
    url: ENDPOINTS.controllerInvites
  })
}

export function acceptControllerInviteService(inviteData) {
  return madeRequest('POST', {
    data: inviteData,
    url: `${ENDPOINTS.controllerInvites}accept/`
  })
}

export function updateControllerMemberService(id, data) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.controllerMembers}${id}/`
  })
}

export function updateControllerMemberStatusService(id, status) {
  return madeRequest('POST', {
    url: `${ENDPOINTS.controllerMembers}${id}/${status === 'active' ? 'deactivate/' : 'activate/'}`
  })
}

export function deleteControllerInviteService(id) {
  return madeRequest('DELETE', {
    url: `${ENDPOINTS.controllerInvites}${id}/`
  })
}

export function resendControllerInviteService(id) {
  return madeRequest('POST', {
    url: `${ENDPOINTS.controllerInvites}${id}/resend/`
  })
}

export function updateControllerService(id, controllerData) {
  return madeRequest('PATCH', {
    data: controllerData,
    url: `${ENDPOINTS.controllers}${id}/`
  })
}

export function getControllerService({ id }) {
  return madeRequest('GET', {
    url: `${ENDPOINTS.controllers}${id}/`
  })
}

export function getControllerMemberService({ id }) {
  return madeRequest('GET', {
    url: `${ENDPOINTS.controllerMembers}${id}/`
  })
}
