import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import React, { useMemo } from 'react'

import TableDataLoader from '../../../../../components/Table/TableDataLoader'
import DiscountPresetsTableData from './DiscountPresetsTableData'

import useCommonStyles from '../../../../../styles/common/listPage'

import {
  discountPresetsErrorSelector,
  discountPresetsIsLoadingSelector,
  discountPresetsSelector,
  discountPresetsWasLoadedSelector
} from '../../../../../modules/selectors/discounts'

export const tableColumnsSize = {
  actions: 30
}

const DiscountPresetsTable = () => {
  const { t } = useTranslation()

  const commonClasses = useCommonStyles()

  const discountPresetColumnsSchema = useMemo(
    () => [
      {
        header: 'Name'
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const discountPresets = useSelector(discountPresetsSelector)

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no discount presets')}</div>
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <>
      <TableDataLoader
        itemsLength={discountPresets.length}
        errorSelector={discountPresetsErrorSelector}
        wasLoadedSelector={discountPresetsWasLoadedSelector}
        isLoadingSelector={discountPresetsIsLoadingSelector}
        skeletonProps={{ cols: discountPresetColumnsSchema }}
        noDataContent={noDataContent}
      >
        <DiscountPresetsTableData />
      </TableDataLoader>
    </>
  )
}

export default DiscountPresetsTable
