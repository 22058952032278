import { createStyles } from '../../../../../../../styles/helpers'

const useStyles = createStyles(theme => ({
  galleryWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 7,
    paddingLeft: 0,
    listStyle: 'none',
    paddingTop: 16
  },
  selected: {
    border: {
      width: 1,
      style: 'solid',
      color: theme.brandPrimary
    }
  },
  imageContainer: {
    width: '100%',
    height: '70px'
  }
}))

export default useStyles
