import { ONE_DAY } from '../../../../constants/timeline'
import { calc } from '../../../../helpers/numbers'

export const calculateDistanceFromStart = ({ timeToPx, timestamp, tableWidth }) => {
  const columnsCount = timeToPx.length
  const firstCol = timeToPx[0]
  const lastCol = timeToPx[columnsCount - 1]

  if (timestamp === firstCol.start) {
    // if timestamp is the same as the first day in the table
    // we show spacing when the event starts on the edge of the calendar
    return 3
  } else if (timestamp < firstCol.start) {
    // if timestamp is before the first day in the table
    // when event is represented in the previous period we don't have spacing on the calendar edge
    return -3
  } else if (timestamp > lastCol.end) {
    // if timestamp is after the last day in the table
    // when event is represented in the next period we don't have spacing on the calendar edge
    return tableWidth + 3
  } else {
    let distance = 0
    for (let i = 0; i < columnsCount; i++) {
      const col = timeToPx[i]

      if (timestamp > col.end) {
        distance += col.oneDayInPx * col.daysCount
      } else {
        // calculated diff between start date and end date
        const diff = timestamp - col.start
        distance += calc(diff).div(ONE_DAY).mul(col.oneDayInPx).toNumber()
        break
      }
    }
    return distance
  }
}
