import { createStyles } from '../../../../../../../../styles/helpers'

import { greyOutline } from '../../../../../../../../styles/const/colors'

const useStyles = createStyles({
  productSection: {
    marginBottom: 30
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 0'
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 600
  },
  removeButton: {
    color: greyOutline,
    fontSize: 14,
    fontWeight: 600
  }
})

export default useStyles
