import { createStyles } from '../../../../../styles/helpers'
import { PAGE_WRAPPER_TITLE_CLASSNAME } from '../../../../../constants/pdf'

const useStyles = createStyles({
  [PAGE_WRAPPER_TITLE_CLASSNAME]: {
    fontSize: 16,
    fontWeight: 700,
    margin: 20,
    marginBottom: 16
  }
})

export default useStyles
