import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../../components/Form'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import PriceChangePeriodFormContent from '../../PriceChangePeriodFormContent'

import { transformValuesToBE } from '../../formatters'

import { clearUpdatePriceChangePeriod, updatePriceChangePeriod } from '../../../../../../modules/actions/discounts'
import {
  updatePriceChangePeriodErrorSelector,
  updatePriceChangePeriodIsLoadingSelector,
  updatePriceChangePeriodWasUpdatedSelector
} from '../../../../../../modules/selectors/discounts'

import { PRICE_CHANGE_PERIOD_EDIT } from '../../../../../../constants/forms'

import { getInitialValues } from './fields'
import { validationSchema } from '../../validation'

function PriceChangePeriodEditForm({ editPriceChangePeriodData }) {
  const dispatch = useDispatch()

  const { id } = editPriceChangePeriodData

  const priceChangePeriodWasUpdated = useSelector(updatePriceChangePeriodWasUpdatedSelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values)

      dispatch(updatePriceChangePeriod(transformedData, id))
    },
    [dispatch, id]
  )

  const initialValues = useMemo(() => getInitialValues(editPriceChangePeriodData), [editPriceChangePeriodData])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const clearHandler = useCallback(() => {
    dispatch(clearUpdatePriceChangePeriod())
  }, [dispatch])

  return (
    <Form
      formik={purifiedFormik}
      formName={PRICE_CHANGE_PERIOD_EDIT}
      successSubmit={priceChangePeriodWasUpdated}
      errorSelector={updatePriceChangePeriodErrorSelector}
      isLoadingSelector={updatePriceChangePeriodIsLoadingSelector}
      clearHandler={clearHandler}
    >
      <PriceChangePeriodFormContent formik={formik} />
    </Form>
  )
}

export default PriceChangePeriodEditForm
