import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import LocationSectionContent from '../../../ReusableFormFIelds/LocationSectionContent'

import { LOCATION } from '../../../fields'

import useDrawerFormStyles from '../../../../../../styles/common/drawerForms'

const LocationSection = ({ formik, editProductData }) => {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormStyles()

  const preselectedLocationOption = useMemo(() => {
    if (editProductData[LOCATION].id) {
      return {
        ...editProductData[LOCATION],
        value: editProductData[LOCATION].id,
        label: editProductData[LOCATION].name
      }
    } else {
      return null
    }
  }, [editProductData])

  return (
    <section className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>{t('Location')}</h3>
      <LocationSectionContent formik={formik} preselectedLocationOption={preselectedLocationOption} />
    </section>
  )
}

LocationSection.propTypes = {
  formik: PropTypes.object.isRequired,
  editProductData: PropTypes.object
}

export default LocationSection
