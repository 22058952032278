import update from 'immutability-helper'

import { deleteItemById, updateItem } from '../../helpers/modules/reducerHelpers'

import {
  INITIAL_GET_REDUCER,
  INITIAL_UPDATE_REDUCER,
  INITIAL_CREATE_REDUCER,
  INITIAL_GET_RESULTS_PAGING_REDUCER,
  INITIAL_GET_RESULTS_REDUCER,
  INITIAL_DELETE_REDUCER
} from '../../constants/reducer'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_MEDIA_ORDER_AS_CSV,
  GET_MEDIA_ORDER_AS_CSV_SUCCESS,
  GET_MEDIA_ORDER_AS_CSV_FAILURE,
  CLEAR_GET_MEDIA_ORDER_AS_CSV,
  GET_MEDIA_ORDER_HISTORY,
  GET_MEDIA_ORDER_HISTORY_SUCCESS,
  GET_MEDIA_ORDER_HISTORY_FAILURE,
  CLEAR_GET_MEDIA_ORDER_HISTORY,
  GET_CONTRACT_ENTITIES,
  GET_CONTRACT_ENTITIES_SUCCESS,
  GET_CONTRACT_ENTITIES_FAILURE,
  CLEAR_GET_CONTRACT_ENTITIES,
  DELETE_MEDIA_ORDER_FILE,
  DELETE_MEDIA_ORDER_FILE_SUCCESS,
  DELETE_MEDIA_ORDER_FILE_FAILURE,
  CLEAR_DELETE_MEDIA_ORDER_FILE,
  ADD_MEDIA_ORDER_FILE,
  ADD_MEDIA_ORDER_FILE_SUCCESS,
  ADD_MEDIA_ORDER_FILE_FAILURE,
  CLEAR_ADD_MEDIA_ORDER_FILE,
  GET_MEDIA_ORDER_FILES,
  GET_MEDIA_ORDER_FILES_SUCCESS,
  GET_MEDIA_ORDER_FILES_FAILURE,
  CLEAR_GET_MEDIA_ORDER_FILES,
  GENERATE_MEDIA_ORDER,
  GENERATE_MEDIA_ORDER_SUCCESS,
  GENERATE_MEDIA_ORDER_FAILURE,
  CLEAR_GENERATE_MEDIA_ORDER,
  GET_MEDIA_ORDERS,
  GET_MEDIA_ORDERS_SUCCESS,
  GET_MEDIA_ORDERS_FAILURE,
  CLEAR_MEDIA_ORDERS,
  GET_MEDIA_ORDER,
  GET_MEDIA_ORDER_SUCCESS,
  GET_MEDIA_ORDER_FAILURE,
  CLEAR_MEDIA_ORDER,
  CREATE_MEDIA_ORDER,
  CREATE_MEDIA_ORDER_SUCCESS,
  CREATE_MEDIA_ORDER_FAILURE,
  UPDATE_MEDIA_ORDER,
  UPDATE_MEDIA_ORDER_SUCCESS,
  UPDATE_MEDIA_ORDER_FAILURE,
  CLEAR_UPDATE_MEDIA_ORDER,
  CANCEL_MEDIA_ORDER,
  CANCEL_MEDIA_ORDER_SUCCESS,
  CANCEL_MEDIA_ORDER_FAILURE,
  CLEAR_CANCEL_MEDIA_ORDER,
  UPDATE_MEDIA_ORDER_DATA,
  CLEAR_CREATE_MEDIA_ORDER,
  SET_MEDIA_ORDER_UPLOAD_CREATIVE,
  CLEAR_MEDIA_ORDER_UPLOAD_CREATIVE,
  ADD_SELECTED_MEDIA_PACKAGE,
  REMOVE_SELECTED_MEDIA_PACKAGE,
  CLEAR_SELECTED_MEDIA_PACKAGES,
  GET_CATALOGUE_PRODUCTS,
  GET_CATALOGUE_PRODUCTS_SUCCESS,
  GET_CATALOGUE_PRODUCTS_FAILURE,
  CLEAR_CATALOGUE_PRODUCTS,
  GET_CATEGORY_PAGES,
  GET_CATEGORY_PAGES_SUCCESS,
  GET_CATEGORY_PAGES_FAILURE,
  CLEAR_CATEGORY_PAGES,
  SET_MEDIA_ORDER_CAMPAIGNS_DATE_RANGE,
  SET_MEDIA_ORDER_DATA,
  CLEAR_MEDIA_ORDER_DATA,
  CREATE_CATEGORY_PAGE,
  CREATE_CATEGORY_PAGE_SUCCESS,
  CREATE_CATEGORY_PAGE_FAILURE,
  CLEAR_CREATE_CATEGORY_PAGE,
  UPDATE_CATEGORY_PAGE,
  UPDATE_CATEGORY_PAGE_SUCCESS,
  CLEAR_UPDATE_CATEGORY_PAGE,
  UPDATE_CATEGORY_PAGE_FAILURE,
  SET_PRODUCT_PERIODS_UPDATE
} from '../actions/mediaOrders'
import { DATE_STATIC_PRESET, LIFETIME_DATE_PRESET } from '../../constants/selectLists/listItemFiltersList'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  generateMediaOrder: INITIAL_CREATE_REDUCER,
  selectedMediaPackages: [],
  mediaOrders: INITIAL_GET_RESULTS_PAGING_REDUCER,
  mediaOrder: INITIAL_GET_REDUCER,
  mediaOrderFiles: INITIAL_GET_RESULTS_REDUCER,
  addMediaOrderFile: INITIAL_CREATE_REDUCER,
  deleteMediaOrderFile: INITIAL_DELETE_REDUCER,
  createMediaOrder: INITIAL_CREATE_REDUCER,
  updateMediaOrder: INITIAL_UPDATE_REDUCER,
  cancelMediaOrder: INITIAL_CREATE_REDUCER,
  mediaOrderUploadCreative: {
    campaign: {},
    lineItem: {},
    catalogueProduct: {},
    audience: {},
    pixelId: ''
  },
  categoryPages: INITIAL_GET_RESULTS_PAGING_REDUCER,
  createCategoryPage: INITIAL_CREATE_REDUCER,
  updateCategoryPage: INITIAL_UPDATE_REDUCER,
  catalogueProducts: INITIAL_GET_RESULTS_PAGING_REDUCER,
  mediaOrderCampaignsDateRange: { [DATE_STATIC_PRESET]: LIFETIME_DATE_PRESET },
  mediaOrderData: null,
  productPeriodsUpdate: false, // uses to know if product periods setup is initial or update
  getContractEntities: INITIAL_GET_RESULTS_REDUCER,
  mediaOrderHistory: INITIAL_GET_RESULTS_PAGING_REDUCER,
  mediaOrderAsCsv: INITIAL_GET_REDUCER
}

export default function mediaOrders(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // Generate media order
    case GENERATE_MEDIA_ORDER:
      return update(state, {
        generateMediaOrder: { $merge: { isLoading: true } }
      })
    case GENERATE_MEDIA_ORDER_SUCCESS:
      return update(state, {
        generateMediaOrder: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        }
      })
    case GENERATE_MEDIA_ORDER_FAILURE:
      return update(state, {
        generateMediaOrder: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GENERATE_MEDIA_ORDER:
      return update(state, {
        generateMediaOrder: { $set: initialState.generateMediaOrder }
      })

    // create media order helper
    case SET_PRODUCT_PERIODS_UPDATE:
      return update(state, {
        productPeriodsUpdate: { $set: action.isPeriodsUpdate }
      })

    // selected media package
    case ADD_SELECTED_MEDIA_PACKAGE:
      return update(state, {
        selectedMediaPackages: { $push: [action.data] }
      })
    case REMOVE_SELECTED_MEDIA_PACKAGE:
      return update(state, {
        selectedMediaPackages: { $apply: items => deleteItemById(items, action.id) }
      })
    case CLEAR_SELECTED_MEDIA_PACKAGES:
      return update(state, {
        selectedMediaPackages: { $set: initialState.selectedMediaPackages }
      })

    // get media orders
    case GET_MEDIA_ORDERS:
      return update(state, {
        mediaOrders: {
          $merge: {
            ...(action.loadOptions.shouldClearExistingState && INITIAL_GET_RESULTS_PAGING_REDUCER),
            isLoading: true
          }
        }
      })
    case GET_MEDIA_ORDERS_SUCCESS:
      return update(state, {
        mediaOrders: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: {
              next: action.mediaOrdersData?.next
            }
          },
          results: {
            $push: action.mediaOrdersData.results
          }
        }
      })
    case GET_MEDIA_ORDERS_FAILURE:
      return update(state, {
        mediaOrders: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_MEDIA_ORDERS:
      return update(state, {
        mediaOrders: { $set: initialState.mediaOrders }
      })

    // load more

    // get media order
    case GET_MEDIA_ORDER:
      return update(state, {
        mediaOrder: {
          $merge: { isLoading: true }
        }
      })
    case GET_MEDIA_ORDER_SUCCESS:
      return update(state, {
        mediaOrder: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.mediaOrderData
          }
        }
      })
    case GET_MEDIA_ORDER_FAILURE:
      return update(state, {
        mediaOrder: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_MEDIA_ORDER:
      return update(state, {
        mediaOrder: { $set: initialState.mediaOrder }
      })

    // Add media order file
    case ADD_MEDIA_ORDER_FILE:
      return update(state, {
        addMediaOrderFile: { $merge: { isLoading: true } }
      })
    case ADD_MEDIA_ORDER_FILE_SUCCESS:
      return update(state, {
        addMediaOrderFile: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },
        mediaOrderFiles: {
          results: { $unshift: [action.data] }
        }
      })
    case ADD_MEDIA_ORDER_FILE_FAILURE:
      return update(state, {
        addMediaOrderFile: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_ADD_MEDIA_ORDER_FILE:
      return update(state, {
        addMediaOrderFile: { $set: initialState.addMediaOrderFile }
      })

    // Delete media order file
    case DELETE_MEDIA_ORDER_FILE:
      return update(state, {
        deleteMediaOrderFile: { $merge: { isLoading: true } }
      })
    case DELETE_MEDIA_ORDER_FILE_SUCCESS:
      return update(state, {
        deleteMediaOrderFile: {
          $merge: {
            isLoading: false,
            wasDeleted: true,
            id: action.data?.id
          }
        },
        mediaOrderFiles: {
          results: {
            $apply: items => deleteItemById(items, action.data?.id)
          }
        }
      })
    case DELETE_MEDIA_ORDER_FILE_FAILURE:
      return update(state, {
        deleteMediaOrderFile: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DELETE_MEDIA_ORDER_FILE:
      return update(state, {
        deleteMediaOrderFile: { $set: initialState.deleteMediaOrderFile }
      })

    // Get media order files
    case GET_MEDIA_ORDER_FILES:
      return update(state, {
        mediaOrderFiles: { $merge: { isLoading: true } }
      })
    case GET_MEDIA_ORDER_FILES_SUCCESS:
      return update(state, {
        mediaOrderFiles: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data.results
          }
        }
      })
    case GET_MEDIA_ORDER_FILES_FAILURE:
      return update(state, {
        mediaOrderFiles: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_MEDIA_ORDER_FILES:
      return update(state, {
        mediaOrderFiles: { $set: initialState.mediaOrderFiles }
      })

    // create mediaOrder
    case CREATE_MEDIA_ORDER:
      return update(state, {
        createMediaOrder: {
          $merge: {
            isLoading: true
          }
        }
      })
    case CREATE_MEDIA_ORDER_SUCCESS:
      return update(state, {
        createMediaOrder: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.mediaOrderData
          }
        }
      })
    case CREATE_MEDIA_ORDER_FAILURE:
      return update(state, {
        createMediaOrder: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_MEDIA_ORDER:
      return update(state, {
        createMediaOrder: { $set: initialState.createMediaOrder }
      })

    // Update media order
    case UPDATE_MEDIA_ORDER:
      return update(state, {
        updateMediaOrder: { $merge: { isLoading: true } }
      })
    case UPDATE_MEDIA_ORDER_SUCCESS:
      return update(state, {
        updateMediaOrder: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            data: action.data
          }
        },
        mediaOrder: {
          $merge: {
            data: action.data
          }
        }
      })
    case UPDATE_MEDIA_ORDER_FAILURE:
      return update(state, {
        updateMediaOrder: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case UPDATE_MEDIA_ORDER_DATA:
      // reducer for internal media order updates
      return update(state, {
        mediaOrder: {
          $apply: currentMediaOrder => {
            // update media order data with new data only if there is media order loaded
            if (currentMediaOrder.wasLoaded) {
              return {
                ...currentMediaOrder,
                data: {
                  ...currentMediaOrder.data,
                  ...action.data
                }
              }
            }
            return currentMediaOrder
          }
        }
      })

    case CLEAR_UPDATE_MEDIA_ORDER:
      return update(state, {
        updateMediaOrder: { $set: initialState.updateMediaOrder }
      })

    // Cancel media order
    case CANCEL_MEDIA_ORDER:
      return update(state, {
        cancelMediaOrder: { $merge: { isLoading: true } }
      })
    case CANCEL_MEDIA_ORDER_SUCCESS:
      return update(state, {
        cancelMediaOrder: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },
        // set media order status to cancelled:
        mediaOrder: {
          $merge: {
            data: action.data
          }
        }
      })
    case CANCEL_MEDIA_ORDER_FAILURE:
      return update(state, {
        cancelMediaOrder: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CANCEL_MEDIA_ORDER:
      return update(state, {
        cancelMediaOrder: { $set: initialState.cancelMediaOrder }
      })

    // order media upload
    case SET_MEDIA_ORDER_UPLOAD_CREATIVE:
      return update(state, {
        mediaOrderUploadCreative: {
          // add campaign, lineItem and other data to already existed
          $merge: {
            ...state.mediaOrderUploadCreative,
            ...action.data
          }
        }
      })
    case CLEAR_MEDIA_ORDER_UPLOAD_CREATIVE:
      return update(state, {
        mediaOrderUploadCreative: { $set: initialState.mediaOrderUploadCreative }
      })

    // Get catalogue products
    case GET_CATALOGUE_PRODUCTS:
      return update(state, {
        catalogueProducts: {
          $merge: {
            isLoading: true,
            ...(action.loadOptions.shouldClearExistingProducts && { results: [] })
          }
        }
      })
    case GET_CATALOGUE_PRODUCTS_SUCCESS:
      return update(state, {
        catalogueProducts: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: {
              next: action.data?.next
            }
          },
          results: {
            $push: action.data.results
          }
        }
      })
    case GET_CATALOGUE_PRODUCTS_FAILURE:
      return update(state, {
        catalogueProducts: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CATALOGUE_PRODUCTS:
      return update(state, {
        catalogueProducts: { $set: initialState.catalogueProducts }
      })

    // Get category pages
    case GET_CATEGORY_PAGES:
      return update(state, {
        categoryPages: { $merge: { isLoading: true } }
      })
    case GET_CATEGORY_PAGES_SUCCESS:
      return update(state, {
        categoryPages: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: {
              next: action.data?.next
            }
          },
          results: {
            $push: action.data.results
          }
        }
      })
    case GET_CATEGORY_PAGES_FAILURE:
      return update(state, {
        categoryPages: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CATEGORY_PAGES:
      return update(state, {
        categoryPages: { $set: initialState.categoryPages }
      })

    // create category page
    case CREATE_CATEGORY_PAGE:
      return update(state, {
        createCategoryPage: {
          $merge: {
            isLoading: true
          }
        }
      })
    case CREATE_CATEGORY_PAGE_SUCCESS:
      return update(state, {
        createCategoryPage: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },
        categoryPages: {
          results: {
            $push: [action.data]
          }
        }
      })
    case CREATE_CATEGORY_PAGE_FAILURE:
      return update(state, {
        createCategoryPage: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_CATEGORY_PAGE:
      return update(state, {
        createCategoryPage: { $set: initialState.createCategoryPage }
      })

    // update category page
    case UPDATE_CATEGORY_PAGE:
      return update(state, {
        updateCategoryPage: {
          $merge: {
            isLoading: true,
            id: action.id
          }
        }
      })
    case UPDATE_CATEGORY_PAGE_SUCCESS:
      return update(state, {
        updateCategoryPage: {
          $merge: {
            isLoading: false,
            wasUpdated: true
          }
        },
        categoryPages: {
          results: {
            $apply: items => updateItem(items, action.data)
          }
        }
      })
    case UPDATE_CATEGORY_PAGE_FAILURE:
      return update(state, {
        updateCategoryPage: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_CATEGORY_PAGE:
      return update(state, {
        updateCategoryPage: { $set: initialState.updateCategoryPage }
      })

    // set media order campaigns date range
    case SET_MEDIA_ORDER_CAMPAIGNS_DATE_RANGE:
      return update(state, {
        mediaOrderCampaignsDateRange: { $set: action.dateRange }
      })

    // set media order data to be used in create media form after it was closed
    case SET_MEDIA_ORDER_DATA:
      return update(state, {
        mediaOrderData: { $set: action.data }
      })
    case CLEAR_MEDIA_ORDER_DATA:
      return update(state, {
        mediaOrderData: { $set: initialState.mediaOrderData }
      })

    // Get contract entities
    case GET_CONTRACT_ENTITIES:
      return update(state, {
        getContractEntities: { $merge: { isLoading: true } }
      })
    case GET_CONTRACT_ENTITIES_SUCCESS:
      return update(state, {
        getContractEntities: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data.results
          }
        }
      })
    case GET_CONTRACT_ENTITIES_FAILURE:
      return update(state, {
        getContractEntities: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_CONTRACT_ENTITIES:
      return update(state, {
        getContractEntities: { $set: initialState.getContractEntities }
      })

    // Get media order history
    case GET_MEDIA_ORDER_HISTORY:
      return update(state, {
        mediaOrderHistory: {
          $merge: {
            ...(action.loadOptions.shouldClearExistingState && INITIAL_GET_RESULTS_PAGING_REDUCER),
            isLoading: true
          }
        }
      })
    case GET_MEDIA_ORDER_HISTORY_SUCCESS:
      return update(state, {
        mediaOrderHistory: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: {
              next: action.data?.next
            }
          },
          results: {
            $push: action.data.results
          }
        }
      })
    case GET_MEDIA_ORDER_HISTORY_FAILURE:
      return update(state, {
        mediaOrderHistory: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_MEDIA_ORDER_HISTORY:
      return update(state, {
        mediaOrderHistory: { $set: initialState.mediaOrderHistory }
      })

    // Get media order as csv
    case GET_MEDIA_ORDER_AS_CSV:
      return update(state, {
        mediaOrderAsCsv: { $merge: { isLoading: true } }
      })
    case GET_MEDIA_ORDER_AS_CSV_SUCCESS:
      return update(state, {
        mediaOrderAsCsv: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        }
      })
    case GET_MEDIA_ORDER_AS_CSV_FAILURE:
      return update(state, {
        mediaOrderAsCsv: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_MEDIA_ORDER_AS_CSV:
      return update(state, {
        mediaOrderAsCsv: { $set: initialState.mediaOrderAsCsv }
      })

    default:
      return state
  }
}
