import React from 'react'

import DrawerHeadline from '../../../../components/Drawer/DrawerHeadline'
import SuggestedChangesTable from './SuggestedChangesTable'
import HistoryList from './HistoryList'

const ShoptimiseOptimisationForm = () => {
  return (
    <div>
      <DrawerHeadline
        title="Cross-platform campaign optimisations"
        description="Updates to budget allocation that will result in an improved performance of your campaigns"
      />
      <SuggestedChangesTable />
      <HistoryList />
    </div>
  )
}

export default ShoptimiseOptimisationForm
