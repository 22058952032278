import { createStyles } from '../../../styles/helpers'

import { lightGrey2, textGrey } from '../../../styles/const/colors'
import { transitionShort } from '../../../styles/const/common'

export const bigRadioLeftPadding = 38
export const smallRadioLeftPadding = 30

const useStyles = createStyles(theme => ({
  radio: {
    position: 'relative'
  },
  radioInput: {
    width: 0,
    height: 0,
    opacity: 0,
    position: 'absolute'
  },
  radioLabel: {
    display: 'block',
    minWidth: 25,
    minHeight: 25,
    position: 'relative',
    cursor: ({ disabled }) => (disabled ? 'auto' : 'pointer'),

    '&:hover& $radioContainer': {
      backgroundColor: ({ disabled }) => !disabled && theme.brandPrimaryLight
    }
  },
  radioTitle: {
    padding: ({ isBig }) => (isBig ? `3px 0 4px ${bigRadioLeftPadding}px` : `1px 0 4px ${smallRadioLeftPadding}px`),
    position: 'relative',
    color: ({ disabled }) => (disabled ? lightGrey2 : textGrey),
    fontWeight: 400
  },
  radioContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    borderColor: ({ disabled }) => (disabled ? lightGrey2 : theme.brandPrimary),
    transition: transitionShort,
    cursor: ({ disabled }) => (disabled ? 'auto' : 'pointer'),
    border: {
      style: 'solid',
      width: 2,
      radius: '50%'
    },
    width: ({ isBig }) => (isBig ? 25 : 20),
    height: ({ isBig }) => (isBig ? 25 : 20)
  },
  checkmark: {
    width: ({ isBig }) => (isBig ? 15 : 12),
    height: ({ isBig }) => (isBig ? 15 : 12),
    borderRadius: '50%',
    backgroundColor: ({ checked }) => (checked ? theme.brandPrimary : 'transparent'),
    transition: transitionShort
  }
}))

export default useStyles
