import React, { useMemo } from 'react'
import { components } from 'react-select'
import classnames from 'classnames'

import useSingleSelectValueStyles from '../../Select/SelectSingleValue/styles'
import useStyles from './styles'

const MultipleCheckboxesValueContainer = props => {
  const { multipleOptionsLabel = 'Multiple' } = props.selectProps

  const classes = useStyles()
  const singleValueClasses = useSingleSelectValueStyles({})

  const selectProps = props.selectProps
  const menuIsOpen = selectProps.menuIsOpen
  const selectedOptions = selectProps.value

  const label = useMemo(() => {
    if (!selectedOptions?.length) {
      return ''
    } else if (selectedOptions?.length > 1) {
      return multipleOptionsLabel
    } else {
      return selectedOptions[0].label
    }
  }, [selectedOptions, multipleOptionsLabel])

  return (
    <components.ValueContainer {...props}>
      {!menuIsOpen && (
        <div className={classes.valueWrapper}>
          <div className={classnames(singleValueClasses.label, classes.label)}>{label}</div>
        </div>
      )}
      {props.children}
    </components.ValueContainer>
  )
}

export default MultipleCheckboxesValueContainer
