import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getIn } from 'formik'

import MultiSelectBox from '../../../../../../../features/components/Form/MultiSelectBox'

import { tagsIsLoadingSelector, tagsSelector } from '../../../../../../../modules/selectors/tags'

import { formatOptionsList } from '../../../../../../../features/formatters'
import { TAGS } from '../../../../fields'

import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

const TagsSection = ({ formik }) => {
  const { t } = useTranslation()

  const { values, setFieldValue, errors } = formik

  const drawerFormClasses = useDrawerFormStyles()

  const tags = useSelector(tagsSelector)
  const tagsIsLoading = useSelector(tagsIsLoadingSelector)

  const tagOptions = useMemo(() => {
    return formatOptionsList({
      list: tags,
      labelName: 'title',
      valueName: 'title'
    })
  }, [tags])

  return (
    <div className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>{t('Select tags (optional)')}</h3>
      <MultiSelectBox
        placeholder={t('Select tags')}
        name={TAGS}
        options={tagOptions}
        value={getIn(values, TAGS)}
        setFieldValue={setFieldValue}
        error={errors[TAGS]}
        isLoading={tagsIsLoading}
      />
    </div>
  )
}

export default TagsSection
