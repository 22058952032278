import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import Button from '../../Button'

import { showToasts } from '../../../helpers/toasts'

import { TOAST_TYPE } from '../../../constants/other'

import useStyles from './styles'

function FileUploadInput({
  onFileUpload,
  multiple = false,
  accept = 'image/*, video/*',
  maxSize,
  buttonText,
  ...props
}) {
  const { t } = useTranslation()
  const classes = useStyles()

  const inputFileRef = useRef()

  const inputClickHandler = () => {
    inputFileRef.current.click()
  }

  const validSize = fileSize => {
    if (fileSize < maxSize) {
      return true
    } else {
      const maxSizeKb = Math.round(maxSize / 1024)
      showToasts({
        type: TOAST_TYPE.error,

        message: t('unsupportedFileSize', { maxSizeKb })
      })
      return fileSize === maxSize
    }
  }

  const selectFile = e => {
    const { files } = e.target
    if (files && files[0]) {
      if (maxSize) {
        // size validation
        // todo handle multiple files validation with filtering out not valid files
        validSize(files[0].size) && onFileUpload(multiple ? files : files[0])
      } else {
        onFileUpload(multiple ? files : files[0])
      }
    }

    // this line right below will reset the
    // input field so if you removed it you can re-add the same file
    e.target.value = ''
  }

  return (
    <div className={classes.buttonWrap}>
      <label htmlFor="upload" className={classes.uploadFileBtnLabel}>
        <Button className={classnames('dark', classes.uploadFileBtn)} type={'button'} onClick={inputClickHandler}>
          {t(buttonText || 'Select File')}
        </Button>
      </label>
      <input
        id="upload"
        ref={inputFileRef}
        className={classes.inputBtn}
        type="file"
        multiple={multiple}
        onChange={selectFile}
        accept={accept}
        {...props}
      />
    </div>
  )
}

FileUploadInput.propTypes = {
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  buttonText: PropTypes.string,
  maxSize: PropTypes.number,
  onFileUpload: PropTypes.func.isRequired
}

export default FileUploadInput
