import React from 'react'
import { useSelector } from 'react-redux'

import { campaignSelector } from '../../../../../../modules/selectors/campaigns'

import { NAME } from '../../../../../ReusableFormFields/CampaignForms/fields'

function NameSectionPreview() {
  const campaign = useSelector(campaignSelector)

  const { [NAME]: name } = campaign

  return <p>{name}</p>
}

export default NameSectionPreview
