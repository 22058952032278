import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Field from '../../../../../../components/Form/Field'
import EditForm from '../../../../../../features/components/Forms/EditForm'
import SocialAccountsFields from './SocialAccountsFields'
import FieldLabelWrapper from '../../../../../../features/components/Form/FieldLabelWrapper'

import useEditAdPreview from '../../../../../../features/hooks/useEditAdPreview'
import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import useGetInstagramAccounts from '../../../../../../hooks/instagramAccountsHooks/useGetInstagramAccounts'

import { clearUpdateAd, updateAd } from '../../../../../../modules/actions/ads'

import {
  adUpdateErrorSelector,
  adUpdateIsLoadingSelector,
  adWasUpdatedSelector
} from '../../../../../../modules/selectors/ads'
import { lineItemSelector } from '../../../../../../modules/selectors/lineItems'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { FB_PAGE_CHOICE, INSTA_ACCOUNT_CHOICE, NAME } from '../../../fields'
import nameValidation from '../../../../../../features/validations/name'
import { FACEBOOK_PLATFORM } from '../../../../../../constants/selectLists/platformList'

const validationSchema = Yup.object(nameValidation)

const NameAndPageForm = ({ editItemData: ad, adValues, isInternalAd, setSelectedFacebookPage, ...formProps }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const lineItem = useSelector(lineItemSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const successUpdate = useSelector(adWasUpdatedSelector)

  const { id } = ad

  const initialValues = useMemo(
    () => ({
      [NAME]: adValues[NAME] || '',
      [FB_PAGE_CHOICE]: adValues[FB_PAGE_CHOICE] || '',
      [INSTA_ACCOUNT_CHOICE]: adValues[INSTA_ACCOUNT_CHOICE] || ''
    }),
    [adValues]
  )

  const handleSubmit = useCallback(
    values => {
      const isFacebookPageChanged = initialValues[FB_PAGE_CHOICE] !== values[FB_PAGE_CHOICE]
      const isInstagramAccountChanged = initialValues[INSTA_ACCOUNT_CHOICE] !== values[INSTA_ACCOUNT_CHOICE]
      const isOnlyNameChanged = !isFacebookPageChanged && !isInstagramAccountChanged

      let updateAdData = {}

      if (isOnlyNameChanged) {
        updateAdData = {
          account: selectedAdAccountId,
          adset_id: lineItem.id,
          [NAME]: values[NAME],
          ...(isInternalAd && { internal: true })
        }
      } else {
        updateAdData = {
          account: selectedAdAccountId,
          adset_id: lineItem.id,
          [NAME]: values[NAME],
          creative: {
            // if instagram accounts is empty array - use backed instagram account
            instagram_actor_id: values[INSTA_ACCOUNT_CHOICE],
            object_story_spec: {
              page_id: values[FB_PAGE_CHOICE]
            }
          },
          ...(isInternalAd && { internal: true })
        }
      }

      dispatch(updateAd(updateAdData, id, 'PATCH', FACEBOOK_PLATFORM))
    },
    [dispatch, initialValues, lineItem, id, isInternalAd, selectedAdAccountId]
  )

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)
  const { values } = purifiedFormik

  useEditAdPreview(purifiedFormik.values)

  const { selectedInstagramAccounts, selectedBackedInstagramAccounts, selectedPageTokenData } = useGetInstagramAccounts(
    values[FB_PAGE_CHOICE]
  )

  const { isLoading: instagramAccountsLoading } = selectedInstagramAccounts
  const { isLoading: backedInstagramAccountsLoading } = selectedBackedInstagramAccounts
  const { isLoading: facebookPageAccessTokenIsLoading } = selectedPageTokenData

  const handleClearUpdateAd = useCallback(() => {
    dispatch(clearUpdateAd())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={successUpdate}
      clearEditItem={handleClearUpdateAd}
      errorSelector={adUpdateErrorSelector}
      isLoadingSelector={adUpdateIsLoadingSelector}
      submitBtnDisabled={instagramAccountsLoading || backedInstagramAccountsLoading || facebookPageAccessTokenIsLoading}
      {...formProps}
    >
      <FieldLabelWrapper label="Ad Name">
        <Field formik={purifiedFormik} name={NAME} placeholder={t('Ad Name')} autoComplete="off" enableReinitialize />
      </FieldLabelWrapper>

      <SocialAccountsFields
        adValues={adValues}
        formik={purifiedFormik}
        selectedInstagramAccounts={selectedInstagramAccounts}
        selectedBackedInstagramAccounts={selectedBackedInstagramAccounts}
      />
    </EditForm>
  )
}

export default NameAndPageForm
