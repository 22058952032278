import * as Yup from 'yup'
import { t } from 'i18next'

import { urlValidation } from '../../../features/validations/other'

import {
  CLICK_THROUGH_LINK,
  BUSINESS_NAME,
  HEADLINES_LIST,
  DESCRIPTIONS_LIST,
  descriptionsMaxLengthSchema,
  FILE_URL,
  headlineMaxLength,
  LANDSCAPE_IMAGE,
  NAME,
  SQUARE_IMAGE,
  SQUARE_LOGO
} from './fields'
import { MEDIA_HEIGHT, MEDIA_RATIO, MEDIA_WIDTH } from '../../Facebook/AdForms/fields'
import { CALL_TO_ACTION } from '../../ReusableFormFields/CallToActionFields/fields'

export const getHeadlineShape = headlineMaxLength =>
  Yup.object({
    text: Yup.string()
      .max(headlineMaxLength, t('headlineMaxLengthErrorMessage', { maxLength: headlineMaxLength }))
      .required('Please enter a headline')
  })

export const headlineShape = getHeadlineShape(headlineMaxLength)

export const descriptionShape = Yup.object({
  text: Yup.string()
    .max(
      descriptionsMaxLengthSchema.default,
      t('descriptionMaxLengthErrorMessage', { maxLength: descriptionsMaxLengthSchema.default })
    )
    .required('This field should not be empty')
})

export const listOfImagesShape = Yup.array()
  .of(
    Yup.object({
      [FILE_URL]: Yup.string().required('Please select image')
    })
  )
  .min(1, 'Please select at least one image')

export const nameValidation = Yup.string().max(400, 'Name should have maximum 400 characters').required('Name Required')

export const businessNameValidation = Yup.string()
  .max(25, 'Business name should have maximum 25 characters')
  .required('Business Name Required')

export const callToActionValidation = Yup.string().required('Please select Call To Action')

export const customFirstDescriptionValidation = {
  [`${DESCRIPTIONS_LIST}[0]['text']`]: Yup.string().test({
    name: 'Custom first description validation',
    test: (value, context) => {
      const firstDescription = context.parent[DESCRIPTIONS_LIST][0]?.text
      const firstDescriptionMaxLength = descriptionsMaxLengthSchema[0]

      if (firstDescription?.length) {
        return firstDescription?.length > firstDescriptionMaxLength
          ? context.createError({
              message: t('descriptionMaxLengthErrorMessage', { maxLength: firstDescriptionMaxLength }),
              path: `${DESCRIPTIONS_LIST}[0]['text']`
            })
          : true
      }

      return context.createError({
        message: 'This field should not be empty',
        path: `${DESCRIPTIONS_LIST}[0]['text']`
      })
    }
  })
}

export const generalGoogleAdValidation = {
  [NAME]: nameValidation,
  [BUSINESS_NAME]: businessNameValidation,
  [CALL_TO_ACTION]: callToActionValidation,
  [CLICK_THROUGH_LINK]: urlValidation,
  [HEADLINES_LIST]: Yup.array().of(headlineShape),

  [DESCRIPTIONS_LIST]: Yup.array().of(descriptionShape),
  ...customFirstDescriptionValidation,

  [LANDSCAPE_IMAGE]: listOfImagesShape,
  [SQUARE_IMAGE]: listOfImagesShape,
  [SQUARE_LOGO]: listOfImagesShape
}

const getImageAssetValidation = ({ minWidth, minHeight, allowedRatio }) => {
  return Yup.object().shape({
    [MEDIA_WIDTH]: Yup.number().min(minWidth, t('minSupportedImageWidth', { minWidth })),
    [MEDIA_HEIGHT]: Yup.number().min(minHeight, t('minSupportedImageHeight', { minHeight })),
    [MEDIA_RATIO]: Yup.number().test(MEDIA_RATIO, t('imageRatioErrorMessage', { allowedRatio }), ratio => {
      // rounded for 2 decimal places
      return Math.round(ratio * 100) / 100 === allowedRatio
    })
  })
}

// LANDSCAPE
export const landscapeRatio = 1.91
export const landscapeMinWidth = 600
export const landscapeMinHeight = 314

// PORTRAIT
export const portraitRatio = 0.8 // 0.8 = 4:5
export const portraitMinWidth = 480
export const portraitMinHeight = 600

// SQUARE LOGO
export const squareLogoRatio = 1
export const squareLogoMinWidth = 128
export const squareLogoMinHeight = 128

// LANDSCAPE LOGO
export const landscapeLogoRatio = 4
export const landscapeLogoMinWidth = 512
export const landscapeLogoMinHeight = 128

// Landscape image (1.91:1), Min. size: 600 x 314, JPG (and jpeg), PNG, static GIF, 5120kb max size.
export const getLandscapeImageValidationSchema = () => {
  // This helper needs to be a function invoked on component leve, because it uses t() function from i18next,
  // don't refactor it to a constant, because translation may not work properly
  return getImageAssetValidation({
    minWidth: landscapeMinWidth,
    minHeight: landscapeMinHeight,
    allowedRatio: landscapeRatio
  })
}

// Landscape logo (4:1), Min. size: 512  x 128, JPG (and jpeg), PNG, static GIF, 5120kb max size.
export const getLandscapeLogoValidationSchema = () => {
  // This helper needs to be a function invoked on component leve, because it uses t() function from i18next,
  // don't refactor it to a constant, because translation may not work properly
  return getImageAssetValidation({
    minWidth: landscapeLogoMinWidth,
    minHeight: landscapeLogoMinHeight,
    allowedRatio: landscapeLogoRatio
  })
}

// Portrait image (4:5), Min. size: 480 x 600, JPG (and jpeg), PNG, static GIF, 5120kb max size.
export const getPortraitImageValidationSchema = () => {
  // This helper needs to be a function invoked on component leve, because it uses t() function from i18next,
  // don't refactor it to a constant, because translation may not work properly
  return getImageAssetValidation({
    minWidth: portraitMinWidth,
    minHeight: portraitMinHeight,
    allowedRatio: portraitRatio
  })
}

// Square image (1:1), Min. size: 300 x 300, JPG (and jpeg), PNG, static GIF, 5120kb max size.
export const getSquareImageValidationSchema = () => {
  // This helper needs to be a function invoked on component leve, because it uses t() function from i18next,
  // don't refactor it to a constant, because translation may not work properly
  return getImageAssetValidation({
    minWidth: 300,
    minHeight: 300,
    allowedRatio: 1
  })
}

// Square logo (1:1), Min. size: 128 x 128, JPG (and jpeg), PNG, static GIF, 5120kb max size.
export const getSquareLogoValidationSchema = () => {
  // This helper needs to be a function invoked on component leve, because it uses t() function from i18next,
  // don't refactor it to a constant, because translation may not work properly
  return getImageAssetValidation({
    minWidth: squareLogoMinWidth,
    minHeight: squareLogoMinHeight,
    allowedRatio: squareLogoRatio
  })
}
