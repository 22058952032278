import React, { useCallback } from 'react'
import { getIn } from 'formik'

import SingleFileUpload from '../../../../../../../ReusableFormFields/SingleFileUpload'

import { FILE_NAME, FILE_URL, initialImageFileValues } from '../../../../../../../../constants/files'

const MediaPackageImageUpload = ({ formik }) => {
  const { values, setFieldValue, setValues, errors, touched } = formik

  const imageError = getIn(errors, FILE_URL)
  const imageTouched = getIn(touched, FILE_URL)
  const imageMissingError = imageTouched && imageError

  const handleMediaUploaded = useCallback(
    uploadedFile => {
      setFieldValue(FILE_URL, uploadedFile.url)
      setFieldValue(FILE_NAME, uploadedFile.name)
    },
    [setFieldValue]
  )

  const handleMediaFileRemove = useCallback(() => {
    setValues({ ...values, ...initialImageFileValues })
  }, [setValues, values])

  return (
    <SingleFileUpload
      fileName={values[FILE_NAME]}
      fileURL={values[FILE_URL]}
      onFileUploaded={handleMediaUploaded}
      onFileRemove={handleMediaFileRemove}
      error={imageMissingError}
      accept="image/png,image/jpg,image/jpeg"
      maxSize={205000}
      buttonText="Upload Image"
    />
  )
}

export default MediaPackageImageUpload
