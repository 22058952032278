import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'

import Form from '../../../../../../components/Form'
import AgencyPermissionsFields from './AgencyPermissionsFields'

import {
  clearUpdateAgencyMember,
  updateAgencyMember,
  updateAgencyMemberSuccess
} from '../../../../../../modules/actions/agencyTeam'
import {
  agencyMemberWasUpdatedSelector,
  updateAgencyMemberErrorSelector,
  updateAgencyMemberIsLoadingSelector
} from '../../../../../../modules/selectors/agencyTeam'

import { transformValuesToBE } from './formatters'
import { isEqual } from '../../../../../../helpers/common'

import { AGENCY_PERMISSIONS_EDIT } from '../../../../../../constants/forms'
import { getInitialValues } from './fields'

const AgencyMemberPermissionsEditForm = ({ memberData = {} }) => {
  const dispatch = useDispatch()

  const { id: memberId } = memberData

  const agencyMemberWasUpdated = useSelector(agencyMemberWasUpdatedSelector)

  const initialValues = useMemo(() => getInitialValues(memberData), [memberData])

  const clearUpdateCategoryPageHandler = useCallback(() => {
    dispatch(clearUpdateAgencyMember())
  }, [dispatch])

  const onSubmit = useCallback(
    values => {
      const hasChanges = !isEqual(values, initialValues)
      const formattedData = transformValuesToBE(values)

      if (hasChanges) {
        dispatch(updateAgencyMember(memberId, formattedData))
      } else {
        dispatch(updateAgencyMemberSuccess(formattedData))
      }
    },
    [dispatch, memberId, initialValues]
  )

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true
  })

  return (
    <Form
      formName={AGENCY_PERMISSIONS_EDIT}
      formik={formik}
      successSubmit={agencyMemberWasUpdated}
      errorSelector={updateAgencyMemberErrorSelector}
      isLoadingSelector={updateAgencyMemberIsLoadingSelector}
      clearHandler={clearUpdateCategoryPageHandler}
    >
      <AgencyPermissionsFields formik={formik} />
    </Form>
  )
}

export default AgencyMemberPermissionsEditForm
