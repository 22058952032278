import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import EditForm from '../../../../../../features/components/Forms/EditForm'
import MultiSelectBox from '../../../../../../features/components/Form/MultiSelectBox'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import { useEditSectionFormSubmit } from '../../../../../../hooks/formHooks/useEditSectionFormSubmit'

import { filterObjectEmptyValues } from '../../../../../../helpers/common'
import { populateItemsByIdsArray } from '../../helpers'
import { formatOptionsList } from '../../../../../../features/formatters'

import { updateLineItem, clearUpdateLineItem } from '../../../../../../modules/actions/lineItems'
import {
  lineItemSelector,
  lineItemUpdateErrorSelector,
  lineItemUpdateIsLoadingSelector,
  lineItemWasUpdatedSelector
} from '../../../../../../modules/selectors/lineItems'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'
import { tikTokCustomAudiencesSelector } from '../../../../../../modules/selectors/tikTokConstants'

import { formatAudiencesToBE } from '../../../LineItemTikTokCreate/LineItemTikTokCreateForm/formatters'

import {
  CUSTOM_AUDIENCES,
  CUSTOM_AUDIENCES_EXCLUSION
} from '../../../../../ReusableFormFields/CustomAudiencesFields/fields'

import useDrawerFormStyles from '../../../../../../styles/common/drawerForms'

const CustomAudiencesSectionForm = ({ editItemData: lineItem, ...formProps }) => {
  const drawerFormClasses = useDrawerFormStyles()

  const dispatch = useDispatch()

  const lineItemWasUpdated = useSelector(lineItemWasUpdatedSelector)
  const tikTokAudiences = useSelector(tikTokCustomAudiencesSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const { audience, excluded_audience: excludedAudience } = useSelector(lineItemSelector)

  const formattedAudiences = useMemo(
    () =>
      formatOptionsList({
        list: tikTokAudiences.filter(audience => audience['is_valid']),
        valueName: 'audience_id',
        labelName: 'name'
      }),
    [tikTokAudiences]
  )

  const initialValues = useMemo(
    () => ({
      [CUSTOM_AUDIENCES]: populateItemsByIdsArray({
        idsArray: audience,
        itemsList: tikTokAudiences,
        itemIdFieldName: 'audience_id',
        itemFallbackNameString: 'Audience'
      }),
      [CUSTOM_AUDIENCES_EXCLUSION]: populateItemsByIdsArray({
        idsArray: excludedAudience,
        itemsList: tikTokAudiences,
        itemIdFieldName: 'audience_id',
        itemFallbackNameString: 'Audience'
      })
    }),
    [audience, excludedAudience, tikTokAudiences]
  )

  const handleSubmit = useCallback(
    values => {
      // as we use PUT for update TikTok campaign, we should pass full data in payload
      dispatch(
        updateLineItem(
          {
            ...filterObjectEmptyValues(lineItem),
            account: selectedAdAccountId,
            audience: formatAudiencesToBE(values[CUSTOM_AUDIENCES]),
            excluded_audience: formatAudiencesToBE(values[CUSTOM_AUDIENCES_EXCLUSION])
          },
          lineItem.id,
          {
            requestMethod: 'PUT'
          }
        )
      )
    },
    [dispatch, lineItem, selectedAdAccountId]
  )

  const { onSubmit, manualSuccessSubmit } = useEditSectionFormSubmit({ initialValues, handleSubmit })

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const { setFieldValue, values } = formik

  const handleClearUpdateLineItem = useCallback(() => {
    dispatch(clearUpdateLineItem())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={lineItemWasUpdated || manualSuccessSubmit}
      clearEditItem={handleClearUpdateLineItem}
      errorSelector={lineItemUpdateErrorSelector}
      isLoadingSelector={lineItemUpdateIsLoadingSelector}
      {...formProps}
    >
      <div className={drawerFormClasses.subSection}>
        <h4 className={drawerFormClasses.sectionSubtitle}>Custom audience inclusions</h4>
        <MultiSelectBox
          placeholder="Add an audience inclusion"
          options={formattedAudiences}
          name={CUSTOM_AUDIENCES}
          value={values[CUSTOM_AUDIENCES]}
          setFieldValue={setFieldValue}
        />
      </div>
      <div className={drawerFormClasses.subSection}>
        <h4 className={drawerFormClasses.sectionSubtitle}>Custom audience exclusions</h4>
        <MultiSelectBox
          placeholder="Add an audience exclusion"
          options={formattedAudiences}
          name={CUSTOM_AUDIENCES_EXCLUSION}
          value={values[CUSTOM_AUDIENCES_EXCLUSION]}
          setFieldValue={setFieldValue}
        />
      </div>
    </EditForm>
  )
}

export default CustomAudiencesSectionForm
