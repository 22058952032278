import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import RadioBlock from '../../../components/Form/RadioBlock'
import FacebookLanguages from './FacebookLanguages'
import GoogleLanguages from './GoogleLanguages'
import TikTokLanguages from './TikTokLanguages'

import { selectedPlatformSelector } from '../../../modules/selectors/app'

import { LANGUAGES_OPTION, SELECT_LANGUAGES, ANY_LANGUAGES, LANGUAGES } from './fields'
import { FACEBOOK_PLATFORM, GOOGLE_PLATFORM, TIKTOK_PLATFORM } from '../../../constants/selectLists/platformList'

const Languages = ({ formik, adAccountId }) => {
  const { t } = useTranslation()
  const { values, errors, touched, setFieldValue } = formik

  const languagesError = errors[LANGUAGES]
  const languagesTouched = touched[LANGUAGES]

  const selectedLanguages = values[LANGUAGES]

  const selectedPlatform = useSelector(selectedPlatformSelector)

  const renderLanguageSelectionByPlatform = useMemo(() => {
    switch (selectedPlatform) {
      case FACEBOOK_PLATFORM:
        return <FacebookLanguages name={LANGUAGES} setFieldValue={setFieldValue} value={selectedLanguages} />
      case GOOGLE_PLATFORM:
        return (
          <GoogleLanguages
            name={LANGUAGES}
            setFieldValue={setFieldValue}
            value={selectedLanguages}
            adAccountId={adAccountId}
          />
        )
      case TIKTOK_PLATFORM:
        return <TikTokLanguages name={LANGUAGES} setFieldValue={setFieldValue} value={selectedLanguages} />
      default:
        return null
    }
  }, [selectedPlatform, selectedLanguages, setFieldValue, adAccountId])

  return (
    <>
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_languages_option_select_languages"
        name={LANGUAGES_OPTION}
        value={SELECT_LANGUAGES}
        selectedValue={values[LANGUAGES_OPTION]}
        label={t('Select language(s)')}
        error={languagesError}
        touched={languagesTouched}
      >
        {renderLanguageSelectionByPlatform}
      </RadioBlock>
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_languages_option_any"
        name={LANGUAGES_OPTION}
        value={ANY_LANGUAGES}
        selectedValue={values[LANGUAGES_OPTION]}
        label={t('Any')}
      />
    </>
  )
}

export default Languages
