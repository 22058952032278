import { createStyles } from '../../../../../styles/helpers'
import { textEllipsis } from '../../../../../styles/mixins/text'
import { textGrey } from '../../../../../styles/const/colors'

const useStyles = createStyles({
  nameField: {
    cursor: 'pointer',
    color: textGrey,
    fontSize: 14,
    fontWeight: 600,
    ...textEllipsis()
  },
  mobileHeaderStyle: {
    minHeight: 24
  }
})

export default useStyles
