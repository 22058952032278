import * as Yup from 'yup'

import { PRIMARY_TEXT_LIST, STORIES_ITEMS } from '../../../../fields'
import { primaryTextValidation, storiesItemShape } from '../../validation'
import nameValidation from '../../../../../../../features/validations/name'

export const getValidationSchema = () => {
  return Yup.object({
    ...nameValidation,
    [PRIMARY_TEXT_LIST]: primaryTextValidation,
    [STORIES_ITEMS]: Yup.array().of(storiesItemShape)
  })
}
