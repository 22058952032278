import { END_DATE, START_DATE } from '../../../../../../forms/ReusableFormFields/StartEndDateRangeFields/fields'

export const OBJECTIVE_PERCENTAGE = 'objective_percentage'
export const PERFORMANCE_GOAL_TYPE_CTR = 'PERFORMANCE_GOAL_TYPE_CTR'

export const timeUnitsValues = {
  TIME_UNIT_LIFETIME: 'TIME_UNIT_LIFETIME',
  TIME_UNIT_MONTHS: 'TIME_UNIT_MONTHS',
  TIME_UNIT_WEEKS: 'TIME_UNIT_WEEKS'
}

export const PRODUCT_IDS = 'product_ids'
export const PRODUCT_DATA = 'product_data'
export const DIGITAL = 'digital'
export const NAME = 'name'
export const BUDGET_LIFETIME = 'budget_lifetime'
export const CAMPAIGN = 'campaign'
export const CAMPAIGN_OPTION = 'campaign_option'
export const CAMPAIGN_OPTION_EXISTING_CAMPAIGN = 'campaign_option_existing_campaign'
export const CAMPAIGN_OPTION_NEW_CAMPAIGN = 'campaign_option_new_campaign'

export const BID_AMOUNT = 'bid_amount'
export const IMAGE_LIST = 'IMAGE_LIST'

export const initialValues = {
  [START_DATE]: '',
  [END_DATE]: '',
  [CAMPAIGN_OPTION]: CAMPAIGN_OPTION_NEW_CAMPAIGN,
  [CAMPAIGN]: '',
  [BUDGET_LIFETIME]: '',
  [BID_AMOUNT]: '',
  [PRODUCT_IDS]: [],
  [PRODUCT_DATA]: []
}
