import { v4 as uuidv4 } from 'uuid'
import { getAssetsByRatio } from '../../../UploadGoogleAdCreative/UploadGoogleAdCreativeForm/imageAssetsListValidationHelper'

import {
  CLICK_THROUGH_LINK,
  NAME,
  HEADLINES_LIST,
  DESCRIPTIONS_LIST,
  BUSINESS_NAME,
  getImageAssets,
  ASSET_ID,
  ASSET_GROUP_TYPE
} from '../../fields'
import { LONG_HEADLINE } from './fields'
import { googleAdsCallToActionList } from '../../../../../constants/selectLists/googleAdsCallToActionList'
import { CALL_TO_ACTION } from '../../../../ReusableFormFields/CallToActionFields/fields'
import { ASSET_IMAGE_LIST } from '../../GoogleAssetGroup/GoogleAssetGroupMainForm/fields'
import { landscapeRatio } from '../../validations'

import { googleAssetFieldType } from '../../../../../constants/ads'

export const transformValuesToBE = ({ values, selectedSelfAccountData, createdAssetsData, submittedAssetsList }) => {
  const callToActionLabel = googleAdsCallToActionList.find(text => text.value === values[CALL_TO_ACTION])?.label

  const assetImageList = values[ASSET_IMAGE_LIST]

  const filledImageAssets = assetImageList?.filter(({ file_url }) => file_url)

  const landscapeImageAssets = getAssetsByRatio(filledImageAssets, landscapeRatio)
  const squareImageAssets = getAssetsByRatio(filledImageAssets, 1)

  // preselect square and landscape logos from
  let landscapeLogoAssets
  let squareLogoAssets
  createdAssetsData.operations.forEach((asset, index) => {
    // createdAssetsData doesn't have asset type, so instead use submittedAssetsList to check which asset type was used
    const assetTypeLabel = submittedAssetsList[index][ASSET_GROUP_TYPE]

    if (assetTypeLabel === googleAssetFieldType.LANDSCAPE_LOGO) {
      landscapeLogoAssets = [
        {
          [ASSET_ID]: asset.resource_name
        }
      ]
    } else if (assetTypeLabel === googleAssetFieldType.LOGO) {
      squareLogoAssets = [
        {
          [ASSET_ID]: asset.resource_name
        }
      ]
    }
  })

  return {
    // in our create form we have just 1 link, but BE requires link to be an array:
    [CLICK_THROUGH_LINK]: [values[CLICK_THROUGH_LINK]],
    // when create internal display ad, add unique id to the name so it doesn't fail during approval process
    [NAME]: `${values[NAME]} - ${uuidv4().slice(0, 7)}`,
    responsive_display_ad: {
      marketing_images: getImageAssets(landscapeImageAssets),
      square_marketing_images: getImageAssets(squareImageAssets),
      logo_images: getImageAssets(landscapeLogoAssets),
      square_logo_images: getImageAssets(squareLogoAssets),
      headlines: values[HEADLINES_LIST],
      [LONG_HEADLINE]: { text: values[LONG_HEADLINE] },
      descriptions: values[DESCRIPTIONS_LIST],

      // if self account has brand names - user can select one of them,
      // otherwise we automatically use self account name as business name
      business_name: values[BUSINESS_NAME] || selectedSelfAccountData?.name,
      call_to_action_text: callToActionLabel
    }
  }
}
