import { call, put, all, takeEvery } from 'redux-saga/effects'

import {
  GET_CAMPAIGNS_REPORTS,
  getCampaignsReportsSuccess,
  getCampaignsReportsFailure,
  GET_CAMPAIGN_GRAPHS,
  getCampaignGraphsSuccess,
  getCampaignGraphsFailure
} from '../actions/campaignsSummary'

import { getCampaignsReportsService, getCampaignsGraphsService } from '../services/campaignsSummary'

function* campaignsSummaryWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_CAMPAIGNS_REPORTS, getCampaignsReportsWorker),
    takeEvery(GET_CAMPAIGN_GRAPHS, getCampaignsGraphsWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getCampaignsReportsWorker({ params }) {
  try {
    const response = yield call(getCampaignsReportsService, params)
    yield put(getCampaignsReportsSuccess(response))
  } catch (e) {
    yield put(getCampaignsReportsFailure(e))
  }
}

function* getCampaignsGraphsWorker({ params }) {
  try {
    const response = yield call(getCampaignsGraphsService, params)
    yield put(getCampaignGraphsSuccess(response))
  } catch (e) {
    yield put(getCampaignGraphsFailure(e))
  }
}

export default campaignsSummaryWatcher
