import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'

import ProgressButton from '../../../../components/Form/ProgressButton'

import useStyles from './style'

function StepFormSubmit({
  errorSelector,
  isLoadingSelector,
  clearHandler,
  submitText = 'Save',
  isSubmitDataLoading,
  ...props
}) {
  const { t } = useTranslation()

  const classes = useStyles({})

  const formError = useSelector(errorSelector)
  const isFormLoading = useSelector(isLoadingSelector)

  return isSubmitDataLoading ? (
    <div className={classes.btnSkeleton}>
      <Skeleton height={40} />
    </div>
  ) : (
    <ProgressButton
      formError={formError}
      isFormLoading={isFormLoading}
      clearHandler={clearHandler}
      disabled={isFormLoading}
      {...props}
    >
      {t(submitText)}
    </ProgressButton>
  )
}

export default StepFormSubmit

StepFormSubmit.propTypes = {
  submitText: PropTypes.string,
  errorSelector: PropTypes.func.isRequired,
  isLoadingSelector: PropTypes.func.isRequired,
  isSubmitDataLoading: PropTypes.bool,
  clearHandler: PropTypes.func
}
