import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { useMemo } from 'react'

import AgencyMemberPermissionsEdit from './AgencyMemberPermissionsEdit'
import TableDataLoader from '../../../../components/Table/TableDataLoader'
import AgencyMembersAndInvitesTableData from './AgencyMembersAndInvitesTableData'

import { mobileOneLineColumnsSchema } from '../../../../constants/other'

import { phonesDownSize } from '../../../../styles/const/breakpoints'
import {
  agencyInvitesIsLoadingSelector,
  agencyInvitesSelector,
  agencyInvitesWasLoadedSelector,
  agencyMembersErrorSelector,
  agencyMembersIsLoadingSelector,
  agencyMembersSelector,
  agencyMembersWasLoadedSelector
} from '../../../../modules/selectors/agencyTeam'

export const tableColumnsSize = {
  status: 140,
  role: 74,
  actions: 40
}

const AgencyMembersAndInvitesTable = ({ setMemberId, openModalHandler }) => {
  const agencyInvitesIsLoading = useSelector(agencyInvitesIsLoadingSelector)
  const agencyInvitesWasLoaded = useSelector(agencyInvitesWasLoadedSelector)

  const agencyMembers = useSelector(agencyMembersSelector)
  const agencyInvites = useSelector(agencyInvitesSelector)
  const agencyMembersAndInvites = useMemo(() => [...agencyMembers, ...agencyInvites], [agencyMembers, agencyInvites])

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const membersColumnsSchema = useMemo(
    () => [
      {
        header: 'Name'
      },
      {
        header: 'Status',
        style: { maxWidth: tableColumnsSize.status }
      },
      {
        header: 'Role',
        style: { maxWidth: tableColumnsSize.role }
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const additionalDataIsLoading = agencyInvitesIsLoading || !agencyInvitesWasLoaded

  return (
    <>
      <TableDataLoader
        itemsLength={agencyMembersAndInvites.length}
        errorSelector={agencyMembersErrorSelector}
        wasLoadedSelector={agencyMembersWasLoadedSelector}
        isLoadingSelector={agencyMembersIsLoadingSelector}
        skeletonProps={{ cols: isMobile ? mobileOneLineColumnsSchema : membersColumnsSchema }}
        noDataContent={<div />}
        additionalDataIsLoading={additionalDataIsLoading}
      >
        <AgencyMembersAndInvitesTableData setMemberId={setMemberId} openModalHandler={openModalHandler} />
      </TableDataLoader>
      <AgencyMemberPermissionsEdit />
    </>
  )
}

AgencyMembersAndInvitesTable.propTypes = {
  setMemberId: PropTypes.func.isRequired,
  openModalHandler: PropTypes.func.isRequired
}

export default AgencyMembersAndInvitesTable
