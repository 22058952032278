// import './whyDidYouRender'

import React from 'react'
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { Amplify } from 'aws-amplify'
import { HelmetProvider } from 'react-helmet-async'
// eslint-disable-next-line
import Symbol_observable from 'symbol-observable' // fix for redux-toolkit not updating the store

import * as Sentry from '@sentry/react'

import store from './store'
import './i18n'

import App from './features/components/App/index'
import StylesProvider from './features/components/App/StylesProvider'
import { REACT_APP_ENVIRONMENTS } from './constants/other'

const productionPool = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_dCbmtzb84',
  userPoolClientId: 'btcommi5hepumu28ohoqpah97'
}

const developmentPool = {
  //  Amazon Cognito User Pool ID
  userPoolId: 'eu-west-1_IGWFxR1Jj',
  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolClientId: 'j7mvi7fkeikv7toh608hktouh',
  region: 'eu-west-1'
}

Amplify.configure({
  Auth: {
    Cognito: process.env.REACT_APP_ENVIRONMENT === REACT_APP_ENVIRONMENTS.prod ? productionPool : developmentPool
  }
})

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_BUILD_VERSION,
    devtool: 'source-map', // Source map generation must be turned on
    integrations: [
      // Add browser profiling integration to the list of integrations
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.sessionTimingIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration()
    ],
    enableTracing: true,
    // // Capture Replay for 0% of all sessions,
    // replaysSessionSampleRate: 0,
    // // but 100% of sessions with an error
    // replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Increase the size limit to capture big objects and etc.. (default is 250)
    maxValueLength: 10000
  })

  // const { replayIntegration } = await import('@sentry/browser')
  // // lazyload the replay integration to speed up initial app load
  // Sentry.addIntegration(
  //   replayIntegration({
  //     // Additional SDK configuration goes in here, for example:
  //     maskAllText: true,
  //     blockAllMedia: true
  //   })
  // )
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <StylesProvider>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </StylesProvider>
  </Provider>
)
