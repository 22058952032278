import { createStyles } from '../../../styles/helpers'

import { darkGrey, lightGrey, lightGrey2, mediumGrey } from '../../../styles/const/colors'
import { bigRadioLeftPadding } from '../Radio/style'

const useStyles = createStyles(theme => ({
  radioBlock: {
    position: 'relative',
    display: 'flex',
    // todo refactor to reusable later - commented due to border dissapearing
    // ...radioCheckboxStyles({ theme, isChecked, hasBorder: true }),
    backgroundColor: 'white',
    border: {
      style: 'solid',
      width: 1,
      radius: theme.bigBorderRadius
    },
    borderColor: ({ isChecked }) => (isChecked ? theme.brandPrimary : mediumGrey),

    '& p:not(:last-child)': {
      marginBottom: 12
    },
    '&:not(:last-child)': {
      marginBottom: 16
    }
  },
  radioBlockTitle: {
    color: ({ disabled }) => (disabled ? lightGrey2 : theme.brandPrimary),
    fontWeight: 600
  },
  orSpacing: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 600,
    height: 40,
    marginBottom: 16,
    color: theme.brandPrimary
  },
  radioBlockContent: {
    padding: 16,
    flexGrow: 1
  },
  sideContent: {
    display: 'flex', // that is used in radioBlockIcon
    width: 140,
    padding: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: lightGrey,
    borderRadius: theme.bigBorderRadius
  },
  description: {
    fontSize: 12,
    color: darkGrey,
    paddingLeft: bigRadioLeftPadding,
    marginTop: 8
  },
  radioBlockIcon: {
    color: theme.brandPrimary,
    justifySelf: 'center',
    alignSelf: 'center',
    margin: '0 auto'
  },
  radioBlockHr: {
    marginTop: 0,
    marginBottom: 16,
    borderTop: mediumGrey
  },
  radioBody: {
    paddingLeft: bigRadioLeftPadding,
    color: darkGrey,
    paddingBottom: 8,
    paddingTop: 12,
    fontSize: 12,

    '& h2,h3,h4,h5': {
      color: 'black'
    },
    '& p': {
      '& a': {
        color: 'inherit'
      }
    },
    '& > .field:first-child': {
      marginTop: 8
    },
    '& > p:first-child': {
      marginTop: [0, '!important']
    }
  }
}))

export default useStyles
