import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import Table from '../../../../../../components/Table'

import { tikTokIdentitiesSelector } from '../../../../../../modules/selectors/tikTokConstants'

import { tikTokTableColumnsSize } from '../index'
import useStyles from '../../../styles'

const TikTokIdentitiesTableData = () => {
  const classes = useStyles()

  const tikTokIdentities = useSelector(tikTokIdentitiesSelector)

  const tiktokIdentitiesColumns = useMemo(
    () => [
      {
        Cell: ({ profile_image: profileImage }) => (
          <img
            className={classes.listImage}
            src={profileImage}
            alt="icon"
            onError={i => (i.target.style.display = 'none')}
          />
        ),

        style: { paddingInlineEnd: 5, maxWidth: tikTokTableColumnsSize.avatar }
      },
      {
        header: 'Name',
        fieldKey: 'display_name'
      }
    ],
    [classes.listImage]
  )

  return <Table hideFooterRow data={tikTokIdentities} cols={tiktokIdentitiesColumns} />
}

export default TikTokIdentitiesTableData
