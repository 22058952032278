import React from 'react'

import ProductCardSkeleton from '../ProductCard/Skeleton'

import useStyles from '../styles'

const ProductsGridSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.productsGrid}>
      {Array.from({ length: 5 }, (_, index) => (
        <ProductCardSkeleton key={index} />
      ))}
    </div>
  )
}

export default ProductsGridSkeleton
