import { createStyles } from '../../../../styles/helpers'
import { darkGrey } from '../../../../styles/const/colors'

const useStyles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 28
  },
  emptyDataText: {
    marginBottom: 24,
    color: darkGrey,
    fontWeight: 600
  }
})

export default useStyles
