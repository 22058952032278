import { createStyles } from '../../../styles/helpers'

import { lightGrey, mediumGrey } from '../../../styles/const/colors'
import { phonesDown } from '../../../styles/const/breakpoints'

export default createStyles(theme => ({
  container: {
    border: `1px solid ${mediumGrey}`,
    borderRadius: theme.bigBorderRadius,
    overflow: 'hidden'
  },
  header: {
    padding: '20px 24px',
    borderBottom: `1px solid ${mediumGrey}`,
    background: lightGrey,
    fontSize: 16,
    fontWeight: 600
  },
  content: {
    padding: '32px 24px',
    background: 'white'
  },
  [`@media screen and (${phonesDown})`]: {
    header: {
      padding: '16px 20px'
    },
    content: {
      padding: '24px 20px'
    }
  }
}))
