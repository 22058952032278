import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import TagsEditForm from './TagsEditForm'
import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'

import { tagsSelector, tagsIsLoadingSelector, tagsWasLoadedSelector } from '../../../../modules/selectors/tags'

import { EDIT_SELF_ACCOUNT_TAG } from '../../../../constants/forms'

function TagsEdit() {
  const { selectedEditItemId } = useManageFormsDrawer({
    formName: EDIT_SELF_ACCOUNT_TAG
  })

  const tagsIsLoading = useSelector(tagsIsLoadingSelector)
  const tagsWasLoaded = useSelector(tagsWasLoadedSelector)

  const tags = useSelector(tagsSelector)

  const isFormLoading = useMemo(() => {
    return tagsIsLoading || !tagsWasLoaded
  }, [tagsIsLoading, tagsWasLoaded])

  const editTagData = useMemo(
    () => tags.find(tag => tag?.id === Number(selectedEditItemId)) || {},
    [tags, selectedEditItemId]
  )

  return (
    <FormDrawerWrapper
      formName={EDIT_SELF_ACCOUNT_TAG}
      title="Edit tag"
      isFormLoading={isFormLoading}
      showOpenButton={false}
      closeOnSubmit={true}
    >
      <TagsEditForm editTagData={editTagData} />
    </FormDrawerWrapper>
  )
}

export default TagsEdit
