import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles(theme => ({
  selectedValueWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px 14px',
    height: 'auto',
    minHeight: 27,
    marginBottom: 4,
    color: theme.brandPrimaryTextContrastColor,
    borderRadius: theme.defaultBorderRadius,
    backgroundColor: theme.brandPrimary
  },
  selectedValueLabel: {
    fontSize: 12,
    lineClamp: 1,
    display: 'block',
    wordBreak: 'break-word',
    boxOrient: 'vertical',
    height: 'auto'
  },
  selectedValueRemove: {
    marginLeft: 10,
    padding: 0,
    '& path': {
      fill: 'white'
    }
  }
}))

export default useStyles
