import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

import {
  END_DATE,
  endDateValidation,
  START_DATE,
  startDateValidation,
  startEndDateRangeInitialValues
} from '../../../../../ReusableFormFields/StartEndDateRangeFields/fields'
import {
  PIXEL_EVENT,
  PIXEL_ID,
  PLACEMENT_TIKTOK
} from '../../../../LineItemForms/LineItemTikTokCreate/LineItemTikTokCreateForm/fields'
import {
  formatAudiencesToBE,
  getBillingEvent
} from '../../../../LineItemForms/LineItemTikTokCreate/LineItemTikTokCreateForm/formatters'
import { formatEndDate, formatStartDate, createTikTokGender } from './formatters'
import { createNameFromCustomAudiences } from '../../../../../Multiplatform/LineItemForms/formatters'

import { formatDateToBE } from '../../../../../../constants/dates'
import { CONVERSIONS } from '../../../../../../constants/campaigns'
import {
  LINE_ITEM_OPTION,
  OPTION_NEW_LINE_ITEM
} from '../../../../../Multiplatform/UploadAdCreative/UploadAdCreativeLineItemForm/fields'
import {
  AUDIENCES_OPTION,
  CUSTOM_AUDIENCES,
  CUSTOM_AUDIENCES_EXCLUSION,
  CUSTOM_AUDIENCES_EXCLUSION_CHECKED,
  customAudiencesInitialValues,
  customAudiencesValidation
} from '../../../../../ReusableFormFields/CustomAudiencesFields/fields'
import { OPTION_YES } from '../../../../../../constants/forms'
import { optimizeGoals } from '../../../../CampaignForms/fields'

export const initialValues = {
  ...startEndDateRangeInitialValues,
  [LINE_ITEM_OPTION]: OPTION_NEW_LINE_ITEM,
  ...customAudiencesInitialValues,
  [AUDIENCES_OPTION]: OPTION_YES
}

export const getValidationSchema = showPixelFields => {
  return Yup.object({
    [START_DATE]: startDateValidation,
    [END_DATE]: endDateValidation,
    ...(showPixelFields && {
      [PIXEL_ID]: Yup.string().when(LINE_ITEM_OPTION, {
        is: OPTION_NEW_LINE_ITEM,
        then: () => Yup.string().required('Please select pixel')
      }),
      [PIXEL_EVENT]: Yup.string().when(LINE_ITEM_OPTION, {
        is: OPTION_NEW_LINE_ITEM,
        then: () => Yup.string().required('Please select pixel event')
      })
    }),
    ...customAudiencesValidation
  })
}

export const transformValuesToBE = ({
  values,
  adAccountId,
  showPixelFields,
  country,
  campaign,
  pixelIdFromExistingLineItem,
  externalActionFromExistingLineItem
}) => {
  const { date_start: campaignDateStart, objective: campaignObjective = '' } = campaign

  const shouldIncludePixel = campaignObjective.toLowerCase() === CONVERSIONS
  const hasCustomAudiences = values[AUDIENCES_OPTION] === OPTION_YES
  const name = hasCustomAudiences
    ? createNameFromCustomAudiences(values[CUSTOM_AUDIENCES])
    : `Sales optimised ${formatDateToBE(values[END_DATE])} - ${uuidv4().slice(0, 7)}`

  return {
    name,
    status: 'active',
    account: adAccountId,
    campaign_id: campaign.id,
    campaign_start_date: formatDateToBE(campaignDateStart),
    objective: optimizeGoals[campaignObjective.toLowerCase()],
    schedule_start_time: formatStartDate(values[START_DATE]),
    schedule_end_time: formatEndDate(values[END_DATE]),
    location: [country?.tiktok_id],
    gender: createTikTokGender('ALL'),
    age: ['AGE_18_24', 'AGE_25_34', 'AGE_35_44', 'AGE_45_54', 'AGE_55_100'],
    bid_type: 'BID_TYPE_NO_BID',
    buying_type: 'BUDGET_MODE_INFINITE',
    schedule_type: 'SCHEDULE_START_END',
    ...(hasCustomAudiences && {
      audience: formatAudiencesToBE(values[CUSTOM_AUDIENCES])
    }),
    ...(values[CUSTOM_AUDIENCES_EXCLUSION_CHECKED] && {
      excluded_audience: formatAudiencesToBE(values[CUSTOM_AUDIENCES_EXCLUSION])
    }),
    // pixel
    ...(shouldIncludePixel && {
      // if pixel step was not shown, but campaign is conversions (type that requires pixel),
      // then we use the pixel settings from the existing line item
      pixel_id: showPixelFields ? values[PIXEL_ID] : pixelIdFromExistingLineItem,
      external_action: showPixelFields ? values[PIXEL_EVENT] : externalActionFromExistingLineItem
    }),
    languages: ['en'],
    placement: [PLACEMENT_TIKTOK],
    is_comment_disable: 0,
    billing_event: getBillingEvent(campaignObjective.toLowerCase()),
    // dayparting: { pacing: ['standard'] }, // was failing on BE
    dayparting: {
      0: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      1: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      2: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      3: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      4: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      5: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      6: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
    },
    external_type: 'WEBSITE',
    pacing: 'PACING_MODE_SMOOTH',
    creative_material_mode: 'CUSTOM',
    placement_type: 'PLACEMENT_TYPE_NORMAL',
    conversion_bid: 1
  }
}
