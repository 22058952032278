import * as Yup from 'yup'

import { EMAIL_RECEIVER, PLATFORM_USER_RECEIVER, SEND_BY_EMAIL, SEND_TO_PLATFORM_USER, USER_OPTION } from './fields'

export const validationSchema = Yup.object({
  [PLATFORM_USER_RECEIVER]: Yup.string().when(USER_OPTION, {
    is: SEND_TO_PLATFORM_USER,
    then: () => Yup.string().required('Please select user')
  }),
  [EMAIL_RECEIVER]: Yup.string().when(USER_OPTION, {
    is: SEND_BY_EMAIL,
    then: () => Yup.string().email('Invalid email').required('Please enter email')
  })
})
