import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateRange } from 'react-date-range'
import classnames from 'classnames'
import { useTheme } from 'react-jss'
import { useTranslation } from 'react-i18next'

import Select from '../../../components/Select'
import Button from '../../../components/Button'
import SelectDropdown from '../../../components/SelectDropdown'

import useStartWeekDay from '../../../features/hooks/useStartWeekDay'

import { getInitialDatePeriod } from '../../../helpers/date'
import { formatDateShort, initialDatePeriod } from '../../../constants/dates'
import {
  CAMPAIGN_CUSTOM_RANGE_VALUE,
  DATE_STATIC_PRESET,
  LIFETIME_DATE_PRESET,
  staticPeriodsList,
  staticPeriodsListWithCustom
} from '../../../constants/selectLists/listItemFiltersList'

import useStyles from './styles'

const getStaticRangeLabel = staticRange => {
  const rangeLabel = staticPeriodsListWithCustom.find(period => period.value === staticRange).label

  return `Date Range: ${rangeLabel}`
}

const initialStaticRange = null
const inputRanges = []

function CampaignsDateRange({ isMobile = false, selectDateRange, dateRangeSelector, mobileMenuHeightLimit }) {
  // component select either DateRange from calendar either staticRange from staticPeriodsList
  // on staticRange selection the component closes
  // staticPeriodsList on Mobile shows as a regular select, but it have additional value - custom
  // when custom value selected the DatePicker will appear
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const rangeColors = useMemo(() => [theme.brandPrimary], [theme.brandPrimary])

  const weekStartsOn = useStartWeekDay()
  const savedDateRange = useSelector(dateRangeSelector)
  const initStaticRange = savedDateRange[DATE_STATIC_PRESET]

  const [isOpen, setIsOpen] = useState(false)
  const [datePeriod, setDatePeriod] = useState(getInitialDatePeriod(savedDateRange))
  const [staticRange, setStaticRange] = useState(initStaticRange)

  const handleDropdownVisibility = useCallback(
    show => {
      setIsOpen(show)
      if (isOpen && !show) {
        // return to default on close without update
        setDatePeriod(getInitialDatePeriod(savedDateRange))
        setStaticRange(initStaticRange)
      }
    },
    [isOpen, setIsOpen, savedDateRange, initStaticRange]
  )

  const handleMobileDateChange = useCallback(
    ({ value: newStaticRange }) => {
      setStaticRange(newStaticRange)
      if (newStaticRange === CAMPAIGN_CUSTOM_RANGE_VALUE) {
        setIsOpen(true)
      } else {
        dispatch(selectDateRange({ [DATE_STATIC_PRESET]: newStaticRange }))
      }
    },
    [dispatch, selectDateRange]
  )

  const handleDateRangeChange = useCallback(item => {
    setDatePeriod(item.selection)
    setStaticRange(initialStaticRange)
  }, [])

  const handleSave = useCallback(
    range => {
      setIsOpen(false)
      dispatch(selectDateRange(range))
      if (range[DATE_STATIC_PRESET] === CAMPAIGN_CUSTOM_RANGE_VALUE) {
        // set to default when custom was selected but closed without selection range)
        setStaticRange(LIFETIME_DATE_PRESET)
      }
    },
    [dispatch, selectDateRange]
  )

  const handleStaticRangeSelect = useCallback(
    range => {
      setStaticRange(range)
      handleSave({ [DATE_STATIC_PRESET]: range })
    },
    [handleSave]
  )

  const handleUpdateSelect = useCallback(() => {
    const { startDate, endDate } = datePeriod
    const range = staticRange ? { [DATE_STATIC_PRESET]: staticRange } : { startDate, endDate }
    handleSave(range)
  }, [datePeriod, staticRange, handleSave])

  const { startDate, endDate } = datePeriod
  const dateRangeLabel = startDate && endDate && `${formatDateShort(startDate)} - ${formatDateShort(endDate)}`

  const ranges = useMemo(() => [staticRange ? initialDatePeriod : datePeriod], [staticRange, datePeriod])

  return (
    <>
      {isMobile && (
        <Select
          isSearchable={false}
          className={classes.dateRangeMobile}
          placeholder="Date range"
          formatValue={false}
          // represent full dateRange for Custom staticRange
          value={
            staticRange
              ? { label: getStaticRangeLabel(staticRange), value: staticRange }
              : { label: dateRangeLabel, value: CAMPAIGN_CUSTOM_RANGE_VALUE }
          }
          // close DateRange on Select opening
          onMenuOpen={() => handleDropdownVisibility(false)}
          options={staticPeriodsListWithCustom}
          onChange={handleMobileDateChange}
          menuHeightLimit={mobileMenuHeightLimit}
          isSelectedValueBrandPrimary
        />
      )}
      <SelectDropdown
        className={classes.dateRange}
        value={staticRange ? getStaticRangeLabel(staticRange) : dateRangeLabel}
        placeholder="Date range"
        isManuallyOpened={isOpen}
        onVisibilityChange={handleDropdownVisibility}
        isSelectedValueBrandPrimary
      >
        <div className={classes.dateRangeDropdown}>
          <div className={classes.dateRanges}>
            {!isMobile && (
              <ul className={classes.staticRangeList}>
                {staticPeriodsList.map(({ value, label }) => {
                  return (
                    <li
                      key={value}
                      className={classnames(classes.staticRangeItem, {
                        [classes.rangeItemActive]: value === staticRange
                      })}
                      onClick={() => handleStaticRangeSelect(value)}
                    >
                      {label}
                    </li>
                  )
                })}
              </ul>
            )}
            <DateRange
              onChange={handleDateRangeChange}
              // start week day
              weekStartsOn={weekStartsOn}
              className={classes.range}
              color={theme.brandPrimaryTextContrastColor}
              showDateDisplay={false}
              months={isMobile ? 1 : 2}
              ranges={ranges}
              inputRanges={inputRanges}
              rangeColors={rangeColors}
              direction="horizontal"
            />
          </div>
          <Button onClick={handleUpdateSelect} className={classes.saveBtn} solid>
            {t('Update')}
          </Button>
        </div>
      </SelectDropdown>
    </>
  )
}

export default CampaignsDateRange
