import { createStyles } from '../../../styles/helpers'

const useAudienceStyles = createStyles({
  suggestions: {
    // blank space under suggestion section so that the user can scroll down a little more
    marginBottom: 80
  },
  moreAudienceTitle: {
    fontWeight: '700',
    fontSize: 18
  }
})

export default useAudienceStyles
