import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import EditForm from '../../../../../../features/components/Forms/EditForm'
import StartEndTimeFields from '../../../../../ReusableFormFields/StartEndTimeFields'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import { useEditSectionFormSubmit } from '../../../../../../hooks/formHooks/useEditSectionFormSubmit'

import { filterObjectEmptyValues } from '../../../../../../helpers/common'
import { combineDateAndTime, formatDateWithTimeToBE } from '../../../../../../helpers/date'
import { formatValuesToFE } from './formatters'

import { updateLineItem, clearUpdateLineItem } from '../../../../../../modules/actions/lineItems'
import {
  lineItemUpdateErrorSelector,
  lineItemUpdateIsLoadingSelector,
  lineItemWasUpdatedSelector
} from '../../../../../../modules/selectors/lineItems'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { START_DATE, START_TIME, STOP_DATE, STOP_TIME } from '../../../../../Multiplatform/LineItemForms/fields'

const DatesSectionForm = ({ editItemData: lineItem, ...formProps }) => {
  const dispatch = useDispatch()

  const lineItemWasUpdated = useSelector(lineItemWasUpdatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const handleSubmit = useCallback(
    values => {
      // as we use PUT for update TikTok campaign, we should pass full data in payload
      dispatch(
        updateLineItem(
          {
            ...filterObjectEmptyValues(lineItem),
            account: selectedAdAccountId,
            schedule_start_time: formatDateWithTimeToBE(combineDateAndTime(values[START_DATE], values[START_TIME])),
            ...(values[STOP_TIME] && {
              schedule_end_time: formatDateWithTimeToBE(combineDateAndTime(values[STOP_DATE], values[STOP_TIME]))
            })
          },
          lineItem.id,
          {
            requestMethod: 'PUT'
          }
        )
      )
    },
    [dispatch, lineItem, selectedAdAccountId]
  )

  const initialValues = useMemo(() => formatValuesToFE(lineItem), [lineItem])

  const { onSubmit, manualSuccessSubmit } = useEditSectionFormSubmit({ initialValues, handleSubmit })

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const handleClearUpdateLineItem = useCallback(() => {
    dispatch(clearUpdateLineItem())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={lineItemWasUpdated || manualSuccessSubmit}
      clearEditItem={handleClearUpdateLineItem}
      errorSelector={lineItemUpdateErrorSelector}
      isLoadingSelector={lineItemUpdateIsLoadingSelector}
      {...formProps}
    >
      <StartEndTimeFields
        formik={purifiedFormik}
        indefiniteText="This ad group has been set up to run indefinitely until paused."
      />
    </EditForm>
  )
}

export default DatesSectionForm
