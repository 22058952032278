import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import EditForm from '../../../../../../../features/components/Forms/EditForm'
import MultipleOptionsField from '../../../../../../ReusableFormFields/MultipleOptionsField'

import { useEditAssetGroupSubmit } from '../../hooks'

import { getHeadlineData } from './helpers'

import { selectedAdAccountIdSelector } from '../../../../../../../modules/selectors/app'
import { assetsCreateErrorSelector, assetsCreateLoadingSelector } from '../../../../../../../modules/selectors/assets'

import { HEADLINES_LIST } from '../../../../fields'
import { getInitialValues, headlineMaxLength, validationSchema } from './fields'
import { googleAssetFieldType } from '../../../../../../../constants/ads'

const HeadlinesSectionForm = ({ editItemData, isInternalAssetGroup, ...formProps }) => {
  const { assets, id: assetGroupId, account } = editItemData

  const assetsCreateError = useSelector(assetsCreateErrorSelector)
  const assetsCreateLoading = useSelector(assetsCreateLoadingSelector)

  const selectedAdAccount = useSelector(selectedAdAccountIdSelector)
  const adAccount = account || selectedAdAccount

  const originalHeadlineAssets = useMemo(() => getHeadlineData(assets), [assets])

  const initialValues = useMemo(() => getInitialValues(originalHeadlineAssets), [originalHeadlineAssets])

  const purifiedFormik = useEditAssetGroupSubmit({
    originalSectionAssets: originalHeadlineAssets,
    fieldType: googleAssetFieldType.HEADLINE,
    sectionValueKey: HEADLINES_LIST,
    isInternalAssetGroup,
    assetGroupId,
    initialValues,
    validationSchema,
    adAccount
  })

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      requestError={assetsCreateError}
      isLoading={assetsCreateLoading}
      {...formProps}
    >
      <MultipleOptionsField
        fieldPlaceholder="Headline"
        fieldMaxLength={headlineMaxLength}
        listFieldName={HEADLINES_LIST}
        formik={purifiedFormik}
        minListQuantity={3}
      />
    </EditForm>
  )
}

export default HeadlinesSectionForm
