import * as Yup from 'yup'
import { isAfter, isEqual } from 'date-fns'
import { clearTimeZone } from '../../../../../helpers/date'

export const NAME = 'title'
export const ROW = 'event_group'
export const START_DATE = 'start_datetime'
export const END_DATE = 'end_datetime'
export const COLOR = 'color'
export const IMAGES = 'images'
export const NEW_IMAGE = 'new_image'

export const getInitialValues = (editedEvent = {}) => {
  return {
    [NAME]: editedEvent[NAME] || '',
    [ROW]: editedEvent[ROW] || '',
    [START_DATE]: clearTimeZone(editedEvent[START_DATE]) || '',
    [END_DATE]: clearTimeZone(editedEvent[END_DATE]) || '',
    [COLOR]: editedEvent[COLOR] || '',
    [IMAGES]: editedEvent[IMAGES] || []
  }
}

export const validationSchema = Yup.object({
  [NAME]: Yup.string().required('Please enter name').max(255, 'Name should have maximum 255 characters'),
  [ROW]: Yup.number().required('Please select row'),
  [COLOR]: Yup.string().required('Please select colour'),
  [NAME]: Yup.string().required('Please enter name'),
  [START_DATE]: Yup.string().required('Please select start date'),
  [END_DATE]: Yup.string()
    .required('Please select end date')
    .test(END_DATE, `The end date of the event should not be earlier than the start date`, (value, { parent }) => {
      const startDate = new Date(parent[START_DATE])
      const endDate = new Date(value)
      return isAfter(endDate, startDate) || isEqual(startDate, endDate)
    })
})
