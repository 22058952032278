import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

export const lineItemsSummaryFilterSelector = createSelector(
  ({ lineItemsSummary }) => lineItemsSummary.filter,
  filter => filter
)

export const lineItemsSummaryDateRangerSelector = createSelector(
  ({ lineItemsSummary }) => lineItemsSummary.dateRange,
  dateRange => dateRange
)

export const lineItemsSummarySortSelector = createSelector(
  ({ lineItemsSummary }) => lineItemsSummary.sort,
  sort => sort
)

export const lineItemsSummaryReportsSelector = createSelector(
  ({ lineItemsSummary }) => lineItemsSummary.reportsData,
  reportsData => reportsData
)

export const lineItemsSummaryReportsLoadingSelector = createSelector(
  ({ lineItemsSummary }) => lineItemsSummary.reportsLoading,
  reportsLoading => reportsLoading
)
