import { COUNTRY, PHONE_NUMBER } from '../fields'

export const getInitialPhoneValues = user => {
  const { phone_number, country } = user

  return {
    // remove + sign from BE number
    [PHONE_NUMBER]: phone_number.split('+')[1] || '',
    [COUNTRY]: country || '' // country also used to preset country in phone input
  }
}

// when phone has a value, check length
export const validatePhoneNumber = value => value?.length > 6

export function formatPhoneNumber(phoneNumber) {
  // we need to add + sign before phone number to match BE requirements
  if (phoneNumber) {
    const formattedPhoneNumber = phoneNumber
    return formattedPhoneNumber.charAt(0) === '+' ? formattedPhoneNumber : `+${formattedPhoneNumber}`
  } else {
    // phone number is optional, allow to remove phone with sending ""
    return ''
  }
}
