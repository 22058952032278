import {
  DV_360_PLATFORM,
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  TIKTOK_PLATFORM
} from '../../../constants/selectLists/platformList'

export const MIN_PERIODS = 'min_periods'
export const INVENTORY_AMOUNT = 'default_inventory_amount'
export const INVENTORY_PERIOD = 'default_inventory_period'
export const MAX_PERIODS = 'max_periods'
export const VARIABLES = 'variables'
export const TRIGGER_BOOKING_NOTIFICATION = 'trigger_bnf'
export const SUB_PRODUCTS = 'sub_products'
// OTHER
export const LOCATION = 'location'
export const MEDIA_SUB_CATEGORY = 'media_sub_category'
export const MEDIA_SUB_SUB_CATEGORY = 'media_sub_sub_category'

export const platforms = [
  {
    label: 'Meta (Facebook & Instagram)',
    value: FACEBOOK_PLATFORM
  },
  {
    label: 'Google Ads',
    value: GOOGLE_PLATFORM
  },
  {
    label: 'Display Video 360',
    value: DV_360_PLATFORM
  },
  {
    label: 'TikTok',
    value: TIKTOK_PLATFORM
  }
]
