import { v4 as uuidv4 } from 'uuid'
import { isCarousel, isCustomAdWithStory } from './index'

export const isAllImageUrlsWasRequested = ({ hashes, requestedHashes }) => {
  return hashes?.every(item => {
    return requestedHashes?.includes(item)
  })
}

export const getMissingImageHashes = ({ hashes, requestedHashes }) => {
  return hashes.filter(item => !requestedHashes.includes(item))
}

export const getSingleVideoId = ad => {
  return (
    ad.creative?.object_story_spec?.video_data?.video_id ||
    // existing post
    ad.creative?.video_id
  )
}

export const getFeedVideoInCustomizedAd = ad => {
  return ad.creative?.asset_feed_spec?.videos?.find(item => {
    return item.adlabels.find(label => {
      return label.name === 'label_other_video'
    })
  })
}
export const getStoryVideoInCustomizedAd = ad => {
  return ad.creative?.asset_feed_spec?.videos?.find(item => {
    return item.adlabels.find(label => {
      return label.name === 'label_story_video'
    })
  })
}

export const isSingleImage = ad => !!ad.creative?.image_hash || ad.creative?.object_story_spec?.link_data?.image_hash

export const getImageHashes = ad => {
  let hashes = []

  // carousel
  if (isCarousel(ad)) {
    hashes = getCarouselImageHashes(ad)
  }

  // single image
  if (isSingleImage(ad)) {
    hashes = [ad.creative?.image_hash || ad.creative?.object_story_spec?.link_data?.image_hash]
  }

  // single image with custom story
  if (isCustomAdWithStory(ad) && !getFeedVideoInCustomizedAd(ad)) {
    hashes = [getFeedImageHashInCustomizedAd(ad)]
  }

  return hashes.filter(hash => !!hash)
}

export const getVideoIds = ad => {
  let ids = []

  // single video
  if (!!getSingleVideoId(ad)) {
    ids = [ad.creative?.object_story_spec?.video_data?.video_id]
  }

  // single video with custom story
  if (getFeedVideoInCustomizedAd(ad) || getStoryVideoInCustomizedAd(ad)) {
    ids = ad.creative?.asset_feed_spec.videos.map(item => item.video_id)
  }

  // carousel
  if (isCarousel(ad)) {
    ids = ad.creative?.object_story_spec.link_data.child_attachments
      .filter(item => item.video_id)
      .map(item => item.video_id)
  }

  return ids.filter(id => !!id)
}

export const getVideoImageHashes = ad => {
  const imageHashes = []
  // single video
  if (!!getSingleVideoId(ad)) {
    const imageHash = ad?.creative?.object_story_spec?.video_data?.image_hash
    imageHashes.push(imageHash)
  }

  // video feed and video story hashes
  if (getFeedVideoInCustomizedAd(ad) || getStoryVideoInCustomizedAd(ad)) {
    const videoImageHashes = ad.creative.asset_feed_spec.videos.map(item => item.thumbnail_hash)
    imageHashes.push(...videoImageHashes)
  }

  // carousel
  if (isCarousel(ad)) {
    const carouselVideoImageHashes = ad.creative.object_story_spec.link_data.child_attachments
      .filter(item => item.video_id)
      .map(item => item.image_hash)
    imageHashes.push(...carouselVideoImageHashes)
  }

  return imageHashes.filter(hash => !!hash)
}

export const getFeedImageHashInCustomizedAd = ad => {
  // Single image with custom story has 3 images with different ratio (width/height)
  // we have to find the correct one, (with ratio 1:1)
  // Correct asset_customization_rule has facebook_position that includes "feed",
  // or doesn't have facebook_positions at all
  const feed = ad.creative?.asset_feed_spec.asset_customization_rules?.find(
    item => item.customization_spec?.facebook_positions?.includes('feed') || !item.customization_spec.facebook_positions
  )
  const feedImageLabelName = feed?.image_label?.name

  return ad.creative?.asset_feed_spec.images?.find(item => item?.adlabels?.[0].name === feedImageLabelName)?.hash
}

export const getStoryImageHashInCustomizedAd = ad => {
  const story = ad.creative?.asset_feed_spec.asset_customization_rules?.find(item =>
    item.customization_spec?.facebook_positions?.includes('story')
  )
  const storyImageLabelName = story?.image_label?.name

  return ad.creative?.asset_feed_spec.images?.find(item => item?.adlabels?.[0].name === storyImageLabelName)?.hash
}

export const getCarouselImageHashes = ad => {
  return ad.creative?.object_story_spec.link_data.child_attachments
    .filter(item => !item.video_id)
    .map(item => item.image_hash)
}

export const isVideoIframe = (video, videoUrls) => {
  const videoData = videoUrls.find(videoUrl => videoUrl.id === video?.video_id)
  return !!videoData?.iframe_src
}

export const getItemFileInfo = ({ item, imageUrls, videoUrls }) => {
  if (item?.video_id) {
    return videoUrls.find(video => video.id === item?.video_id)
  } else {
    return getImageInfo({ imageUrls, imageHash: item?.image_hash })
  }
}

export const getImageInfo = ({ ad = {}, imageUrls, imageHash }) => {
  const isInternal = !!ad.internal
  const internalAdPicture = ad.creative?.object_story_spec?.link_data?.picture
  if (isInternal && internalAdPicture) {
    // In case we create ad using "picture" field, instead of image_hash
    // e.g. product route (each product has only image_url)
    // we don't have image_hash and therefore can't get image_name, so we hardcode it
    return { url: internalAdPicture, id: uuidv4(), name: 'product image ' }
  }

  const imageDetails = imageUrls?.find(item => imageHash === item?.hash)

  if (imageDetails?.name === 'untitled') {
    // If we have image_hash, but name is 'untitled', use hardcoded name
    imageDetails.name = 'product image'
  }

  return imageDetails
}
