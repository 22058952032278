import * as Yup from 'yup'

import { getAdDescriptions } from '../../helpers'
import { descriptionShape } from '../../../../validations'
import { DESCRIPTIONS_LIST } from '../../../../fields'

export const getInitialValues = ad => {
  const descriptionsData = getAdDescriptions(ad)

  return {
    [DESCRIPTIONS_LIST]: descriptionsData
      ? descriptionsData.map(description => ({
          text: description
        }))
      : []
  }
}

export const validationSchema = Yup.object({
  [DESCRIPTIONS_LIST]: Yup.array().of(descriptionShape)
})
