import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ContentRow from '../../../../features/components/ContentSection/ContentRow'
import PriceChangePeriodsTable from './PriceChangePeriodsTable'
import PriceChangePeriodCreate from '../../../../forms/Multiplatform/DiscountsForms/PriceChangePeriodForms/PriceChangePeriodCreate'
import PriceChangePeriodEdit from '../../../../forms/Multiplatform/DiscountsForms/PriceChangePeriodForms/PriceChangePeriodEdit'

import { clearGetDiscountPresets, getPriceChangePeriods } from '../../../../modules/actions/discounts'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'

const PriceChangePeriods = () => {
  const dispatch = useDispatch()

  const controllerId = useSelector(selectedControllerIdSelector)

  useEffect(() => {
    dispatch(
      getPriceChangePeriods({
        controller: controllerId
      })
    )
  }, [dispatch, controllerId])

  useEffect(() => {
    return () => {
      dispatch(clearGetDiscountPresets())
    }
  }, [dispatch, controllerId])

  return (
    <>
      <ContentRow
        leftColumnChildren={<PriceChangePeriodCreate />}
        title="Price Change Periods"
        description="Increase or decrease the price of products by media category and location during specific time periods"
      >
        <PriceChangePeriodsTable />
      </ContentRow>
      <PriceChangePeriodEdit />
    </>
  )
}

export default PriceChangePeriods
