import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import FacebookPages from './FacebookPages'
import ContentSection from '../../../features/components/ContentSection'
import TikTokIdentities from './TikTokIdentities'

import {
  selectedFacebookAdAccountIdSelector,
  selectedSelfAccountDataSelector,
  selectedTikTokAdAccountIdSelector
} from '../../../modules/selectors/app'

import useSettingsClasses from '../styles'

const PagesAndIdentities = () => {
  const { t } = useTranslation()
  const settingsClasses = useSettingsClasses()

  const { name: selfAccountName } = useSelector(selectedSelfAccountDataSelector)

  const facebookAdAccountId = useSelector(selectedFacebookAdAccountIdSelector)
  const tikTokAdAccountId = useSelector(selectedTikTokAdAccountIdSelector)

  return (
    <>
      <h1 className={settingsClasses.title}>{t('Pages and Identities')}</h1>
      <ContentSection title={t('pagesAndIdentitiesForSelfAccountName', { selfAccountName })}>
        {!!facebookAdAccountId && <FacebookPages />}
        {!!tikTokAdAccountId && <TikTokIdentities />}
      </ContentSection>
    </>
  )
}

export default PagesAndIdentities
