import React from 'react'

import TikTokPixelFields from './TikTokPixelFields'
import Step from '../../../../../../../features/components/Forms/StepForm/Step'

import { PIXEL_EVENT, PIXEL_ID } from '../../fields'

const stepFields = [PIXEL_ID, PIXEL_EVENT]

const PixelStep = ({ formik, handleStepChange, adAccountId }) => {
  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  return (
    <Step
      formTitle="Create a TikTok Ad Group"
      stepTitle="Select a conversion event to optimize towards"
      handleContinue={handleContinue}
    >
      <TikTokPixelFields formik={formik} adAccountId={adAccountId} />
    </Step>
  )
}

export default PixelStep
