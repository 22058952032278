import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import MediaProductLocationEditForm from './MediaProductLocationEditForm'

import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'
import useManageEditFormData from '../../../../hooks/formHooks/useManageEditFormData'

import { MEDIA_PRODUCT_LOCATION_EDIT } from '../../../../constants/forms'
import {
  mediaProductLocationSelector,
  mediaProductLocationWasLoadedSelector
} from '../../../../modules/selectors/mediaOrdersProductLocations'
import { controllerWasLoadedSelector } from '../../../../modules/selectors/controller'
import { clearGetController, getController } from '../../../../modules/actions/controller'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import {
  clearGetMediaProductLocation,
  getMediaProductLocation
} from '../../../../modules/actions/mediaOrdersProductLocations'

const MediaProductLocationEdit = () => {
  const dispatch = useDispatch()
  const controllerWasLoaded = useSelector(controllerWasLoadedSelector)
  const selectedControllerId = useSelector(selectedControllerIdSelector)

  const mediaProductLocation = useSelector(mediaProductLocationSelector)
  const mediaProductLocationWasLoaded = useSelector(mediaProductLocationWasLoadedSelector)

  const { selectedEditItemId } = useManageFormsDrawer({
    formName: MEDIA_PRODUCT_LOCATION_EDIT
  })

  const isFormLoading = useMemo(
    () => !mediaProductLocation || !mediaProductLocationWasLoaded || !controllerWasLoaded,
    [mediaProductLocation, mediaProductLocationWasLoaded, controllerWasLoaded]
  )

  const getDataHandler = useCallback(() => {
    dispatch(getController({ id: selectedControllerId }))
    dispatch(getMediaProductLocation({ id: selectedEditItemId }))
  }, [dispatch, selectedControllerId, selectedEditItemId])

  const clearDataHandler = useCallback(() => {
    dispatch(clearGetController())
    dispatch(clearGetMediaProductLocation())
  }, [dispatch])

  useManageEditFormData({
    formName: MEDIA_PRODUCT_LOCATION_EDIT,
    loadedDataId: mediaProductLocation.id,
    getDataHandler,
    clearDataHandler
  })

  return (
    <FormDrawerWrapper
      formName={MEDIA_PRODUCT_LOCATION_EDIT}
      isFormLoading={isFormLoading}
      title="Edit media product location"
      showOpenButton={false}
    >
      <MediaProductLocationEditForm editProductLocationData={mediaProductLocation} />
    </FormDrawerWrapper>
  )
}

export default MediaProductLocationEdit
