import React from 'react'
import PropTypes from 'prop-types'

import { getVideoUrlsData } from '../HeadlinesSection/helpers'

import { youtubeVideoIdUrlParams } from '../../../../fields'

const VideoUrlsSectionPreview = ({ data }) => {
  const { assets } = data
  const videoUrlData = getVideoUrlsData(assets)

  if (!videoUrlData || !videoUrlData.length) {
    return null
  }

  return (
    <p>
      {videoUrlData.map(({ resource_name, asset_data }) => (
        <span key={resource_name}>
          {`https://www.youtube.com/` + youtubeVideoIdUrlParams + asset_data?.youtube_video_asset?.youtube_video_id}
        </span>
      ))}
    </p>
  )
}

VideoUrlsSectionPreview.propTypes = {
  data: PropTypes.object
}

export default VideoUrlsSectionPreview
