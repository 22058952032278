import * as Yup from 'yup'
import { LANGUAGES, LANGUAGES_OPTION, SELECT_LANGUAGES } from '../../forms/ReusableFormFields/LanguagesFields/fields'

export const languagesCreateValidation = {
  [LANGUAGES]: Yup.array().when(LANGUAGES_OPTION, {
    is: SELECT_LANGUAGES,
    then: () => Yup.array().min(1, 'Please select at least one option')
  })
}

export const languagesEditValidation = {
  [LANGUAGES]: Yup.array().min(1, 'Please select at least one option')
}
