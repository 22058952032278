import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  clearGenerateMediaOrder,
  clearMediaOrderData,
  clearSelectedMediaPackages
} from '../../../../../modules/actions/mediaOrders'
import { clearActiveContract } from '../../../../../modules/actions/contracts'

export function useClearMediaOrderForm() {
  const dispatch = useDispatch()

  const handleSubmitFormClear = useCallback(() => {
    // !!! if any other clear handlers are added - add it to handleSelfAccountChange as well
    dispatch(clearSelectedMediaPackages())
    dispatch(clearActiveContract())
    dispatch(clearMediaOrderData())
    dispatch(clearGenerateMediaOrder())
  }, [dispatch])

  return handleSubmitFormClear
}
