import React, { cloneElement } from 'react'
import PropTypes from 'prop-types'

import EditSection from '../EditSection'
import SkeletonEditSection from '../EditSection/Skeleton'

import useManageEditSections from '../../../../../hooks/formHooks/useManageEditSections'

import useStyles from './style'

// this component allows to control visibility by Preview and edit Form/Fields
const EditSectionsList = ({ editSections, isEditDataLoading, editItemData, sectionsInitialOpenStates, formProps }) => {
  const classes = useStyles()

  const { openSections, openSectionHandler, setHasUnsavedChanges, UnsavedChangesWarningModal } = useManageEditSections({
    sectionsInitialOpenStates
  })

  const renderFormComponentWithProps = (FormComponent, sectionName) => {
    // populate component with props during the rendering
    return cloneElement(FormComponent, {
      ...formProps,
      formName: sectionName,
      editItemData,
      onEditClose: () => openSectionHandler(sectionName),
      onSuccessSubmit: () => openSectionHandler(sectionName),
      setUnsavedChanges: setHasUnsavedChanges
    })
  }

  return (
    <div className={classes.editSections}>
      {editSections.map(editSection =>
        isEditDataLoading ? (
          <SkeletonEditSection key={editSection.sectionName} />
        ) : (
          <EditSection
            key={editSection.title}
            title={editSection.title}
            isSectionOpen={openSections[editSection.sectionName]}
            toggleSectionVisibility={() => openSectionHandler(editSection.sectionName)}
            onDelete={editSection.onDelete}
            preview={editSection.PreviewComponent}
          >
            {renderFormComponentWithProps(editSection.FormComponent, editSection.sectionName)}
          </EditSection>
        )
      )}
      <UnsavedChangesWarningModal />
    </div>
  )
}

EditSectionsList.propTypes = {
  editSections: PropTypes.array.isRequired,
  editItemData: PropTypes.object,
  sectionsInitialOpenStates: PropTypes.object,
  isEditDataLoading: PropTypes.bool
}

export default EditSectionsList
