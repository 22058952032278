import React from 'react'

import CardContainer from '../../../../../../features/components/reusableCardComponents/CardContainer'
import CardHeaderSkeleton from '../../../../../../features/components/reusableCardComponents/CardHeader/Skeleton'
import CardContentSkeleton from '../../../../../../features/components/reusableCardComponents/CardContent/Skeleton'
import CardFooterSkeleton from '../../../../../../features/components/reusableCardComponents/CardFooter/Skeleton'

const SkeletonMediaPackageCard = () => {
  return (
    <CardContainer>
      <CardHeaderSkeleton />
      <CardContentSkeleton />
      <CardFooterSkeleton cardButton />
    </CardContainer>
  )
}

export default SkeletonMediaPackageCard
