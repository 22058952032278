import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import Page from '../index'
import LineItemSummaryContent from './LineItemSummaryContent'
import InfoBlock from '../../features/components/InfoBlock'
import SocialAuthChecker from '../../features/components/SocialAuthChecker'

import usePlatformConnected from '../../features/hooks/usePlatformConnected'

import { clearAds } from '../../modules/actions/ads'
import { getLineItem, clearLineItem } from '../../modules/actions/lineItems'
import { clearCampaign, getCampaign } from '../../modules/actions/campaigns'

import { lineItemErrorSelector, lineItemWasLoadedSelector } from '../../modules/selectors/lineItems'
import {
  selectedPlatformSelector,
  selectedAdAccountIdSelector,
  userSelfAccountTypeSelector
} from '../../modules/selectors/app'
import { campaignErrorSelector, campaignSelector, campaignWasLoadedSelector } from '../../modules/selectors/campaigns'

import { GOOGLE_PLATFORM, PLATFORM_LABELS } from '../../constants/selectLists/platformList'
import { CONTROLLER_TYPE } from '../../constants/permissions'
import { useTranslatedAdStructureLabel } from './hooks'

const LineItemsSummaryPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { line_item_id: lineItemId, campaign_id: campaignId } = useParams()

  const campaignError = useSelector(campaignErrorSelector)
  const lineItemError = useSelector(lineItemErrorSelector)
  const lineItemWasLoaded = useSelector(lineItemWasLoadedSelector)
  const selectedPlatform = useSelector(selectedPlatformSelector)
  const userAccountType = useSelector(userSelfAccountTypeSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const campaign = useSelector(campaignSelector)
  const campaignWasLoaded = useSelector(campaignWasLoadedSelector)
  const { objective: campaignObjective } = campaign

  const isPlatformConnected = usePlatformConnected(selectedPlatform)

  const translatedAdStructureLabel = useTranslatedAdStructureLabel(selectedPlatform)

  const renderErrorMessage = useCallback(() => {
    if (campaignError) {
      return (
        <InfoBlock title={t('Sorry, we can’t find that campaign')} centered greyDescription>
          <div>{t('You might not have access to it, or it may have been deleted')}</div>
        </InfoBlock>
      )
    } else {
      return (
        <InfoBlock title={t('adNotFound', { adStructure: translatedAdStructureLabel })} centered greyDescription>
          <div>{t('You might not have access to it, or it may have been deleted')}</div>
        </InfoBlock>
      )
    }
  }, [t, campaignError, translatedAdStructureLabel])

  const authFailureText = useMemo(() => {
    if (userAccountType === CONTROLLER_TYPE) {
      return t('authenticateToRetrieveDetails', {
        platform: PLATFORM_LABELS[selectedPlatform],
        adStructure: translatedAdStructureLabel
      })
    } else {
      return t('contactAccountManagerForAccess', {
        adStructure: translatedAdStructureLabel
      })
    }
  }, [t, translatedAdStructureLabel, selectedPlatform, userAccountType])

  const loadLineItem = useCallback(() => {
    // google platform doesn't have campaignObjective
    if (!lineItemWasLoaded && (campaignObjective || selectedPlatform === GOOGLE_PLATFORM)) {
      dispatch(getLineItem({ id: lineItemId, campaign_objective: campaignObjective, account: selectedAdAccountId }))
    }
  }, [dispatch, selectedPlatform, lineItemId, campaignObjective, lineItemWasLoaded, selectedAdAccountId])

  useEffect(() => {
    // make sure social auth is correct
    if (isPlatformConnected && !campaignWasLoaded) {
      dispatch(getCampaign({ account: selectedAdAccountId, id: campaignId }))
    }
  }, [dispatch, campaignId, isPlatformConnected, selectedAdAccountId, campaignWasLoaded])

  useEffect(() => {
    return () => {
      dispatch(clearCampaign())
      dispatch(clearLineItem())
      dispatch(clearAds())
    }
  }, [dispatch])

  return (
    <Page helmetTitle="helmetTitle.CampaignsPage">
      {campaignError || lineItemError ? (
        renderErrorMessage()
      ) : (
        <SocialAuthChecker
          platform={selectedPlatform}
          dataFetch={loadLineItem}
          adAccountId={selectedAdAccountId}
          authFailureText={authFailureText}
        >
          <LineItemSummaryContent />
        </SocialAuthChecker>
      )}
    </Page>
  )
}

export default LineItemsSummaryPage
