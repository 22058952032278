import React from 'react'
import PropTypes from 'prop-types'

import { getAdHeadlines } from '../../helpers'

const HeadlinesSectionPreview = ({ ad }) => {
  const headlinesData = getAdHeadlines(ad)

  if (!headlinesData || !headlinesData.length) {
    return null
  }

  return (
    <p>
      {headlinesData.map((headline, index) => (
        <span key={index}>{headline}</span>
      ))}
    </p>
  )
}

HeadlinesSectionPreview.propTypes = {
  ad: PropTypes.object
}

export default HeadlinesSectionPreview
