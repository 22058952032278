import * as Yup from 'yup'

import {
  LINE_ITEM,
  LINE_ITEM_OPTION,
  OPTION_EXISTING_LINE_ITEM,
  OPTION_NEW_LINE_ITEM
} from '../../../../../../../Multiplatform/UploadAdCreative/UploadAdCreativeLineItemForm/fields'
import {
  END_DATE,
  endDateValidation,
  START_DATE,
  startDateValidation
} from '../../../../../../../ReusableFormFields/StartEndDateRangeFields/fields'
import {
  initialGeolocationsValues,
  LOCATION_LIST,
  SHOW_OPTION,
  SHOW_OPTION_LOCATION_LIST,
  SHOW_OPTION_REGIONS,
  SHOW_REGIONS
} from '../../../../../../../ReusableFormFields/GeolocationsFields/fields'

import { regionsOverlapValidation } from '../../../../../../../../features/validations/gelolocations'
import { locationListValidation } from '../../../../../../../ReusableFormFields/LineItemForms/fields'

export const getInitialValues = isLineItemsExist => ({
  // line_item
  [LINE_ITEM_OPTION]: isLineItemsExist ? OPTION_EXISTING_LINE_ITEM : OPTION_NEW_LINE_ITEM,
  [LINE_ITEM]: '',
  // dates
  [START_DATE]: '',
  [END_DATE]: '',
  // geolocations
  ...initialGeolocationsValues,
  [SHOW_OPTION]: SHOW_OPTION_LOCATION_LIST
})

export const validationSchema = Yup.object({
  // line item
  [LINE_ITEM]: Yup.string().when(LINE_ITEM_OPTION, {
    is: OPTION_EXISTING_LINE_ITEM,
    then: () => Yup.string().required('Please select line item')
  }),
  // dates
  [START_DATE]: Yup.string().when(LINE_ITEM_OPTION, {
    is: OPTION_NEW_LINE_ITEM,
    then: () => startDateValidation
  }),
  [END_DATE]: Yup.string().when(LINE_ITEM_OPTION, {
    is: OPTION_NEW_LINE_ITEM,
    then: () => endDateValidation
  }),
  // duplication of geolocation validation but with check LINE_ITEM_OPTION rule
  [LOCATION_LIST]: Yup.string().when(LINE_ITEM_OPTION, {
    is: OPTION_NEW_LINE_ITEM,
    then: () =>
      Yup.string().when(SHOW_OPTION, {
        is: SHOW_OPTION_LOCATION_LIST,
        then: () => locationListValidation.required('Please select location list')
      })
  }),
  [SHOW_REGIONS]: Yup.array().when(LINE_ITEM_OPTION, {
    is: OPTION_NEW_LINE_ITEM,
    then: () =>
      Yup.array().when(SHOW_OPTION, {
        is: SHOW_OPTION_REGIONS,
        then: () => regionsOverlapValidation
      })
  })
})
