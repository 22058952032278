import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { getIn } from 'formik'

import AdFileUpload from '../../../../../ReusableFormFields/AdFileUpload'
import GoogleImageFilesLibrary from './GoogleImageFilesLibrary'
import FieldSectionSpacer from '../../../../../../features/components/Form/FieldsSection/FieldSectionSpacer'

import { concat } from '../../../../../../helpers/common'

import { fileUpload } from '../../../../../../modules/actions/files'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { GOOGLE_PLATFORM } from '../../../../../../constants/selectLists/platformList'
import { ASSET_ID, FILE_NAME, FILE_TYPE, FILE_URL, initialGoogleFile, MEDIA_TYPE } from '../../../fields'
import {
  MEDIA_FILE_UPLOAD,
  MEDIA_FILE_IMAGE_USE_LIBRARY,
  MEDIA_FILE_OPTION,
  UPLOADED_MEDIA_TYPE
} from '../../../../../ReusableFormFields/AdFileUpload/fields'
import { MEDIA_HEIGHT_PIXELS, MEDIA_WIDTH_PIXELS } from '../fields'

import useStyles from './styles'

const GoogleMediaUpload = ({
  formik,
  itemPath,
  fileValidationSchema,
  onMediaRemove,
  adAccountId: providedAdAccountId,
  isDraggable
}) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  // use provided from outside ad account, if it's exists
  const adAccountId = providedAdAccountId || selectedAdAccountId

  const { values, setFieldValue, errors, touched } = formik

  const fileNameKey = concat(itemPath, '.', FILE_NAME)
  const fileURLKey = concat(itemPath, '.', FILE_URL)
  const fileTypeKey = concat(itemPath, '.', FILE_TYPE)
  const mediaOptionKey = concat(itemPath, '.', MEDIA_FILE_OPTION)
  const uploadedMediaTypeKey = concat(itemPath, '.', MEDIA_TYPE)

  const mediaFile = getIn(values, itemPath)
  const mediaError = getIn(errors, `${itemPath}[${FILE_URL}]`)
  const mediaTouched = getIn(touched, `${itemPath}[${FILE_URL}]`)
  const selectedMediaOption = getIn(values, mediaOptionKey)
  const mediaMissingError = mediaTouched && mediaError

  const handleMediaRemove = useCallback(() => {
    // set initial media file values for deleted file
    setFieldValue(itemPath, initialGoogleFile)
    onMediaRemove && onMediaRemove()
  }, [itemPath, setFieldValue, onMediaRemove])

  const handleMediaStartUpload = useCallback(
    (file, newFileValues) => {
      setFieldValue(`${itemPath}[${FILE_NAME}]`, newFileValues.file_name)
      setFieldValue(`${itemPath}[${FILE_TYPE}]`, newFileValues[MEDIA_TYPE])
      setFieldValue(`${itemPath}[${MEDIA_TYPE}]`, newFileValues[UPLOADED_MEDIA_TYPE])

      // upload file to server
      dispatch(fileUpload(file, { isPlatformRelated: true, adAccountId: adAccountId, platform: GOOGLE_PLATFORM }))
    },
    [dispatch, itemPath, setFieldValue, adAccountId]
  )

  const handleMediaUploaded = useCallback(
    uploadedFile => {
      const assetFileFields = {
        ...mediaFile,
        [MEDIA_WIDTH_PIXELS]: uploadedFile.image_asset.full_size?.[MEDIA_WIDTH_PIXELS],
        [MEDIA_HEIGHT_PIXELS]: uploadedFile.image_asset.full_size?.[MEDIA_HEIGHT_PIXELS],
        [FILE_URL]: uploadedFile.image_asset.full_size.url,
        [ASSET_ID]: uploadedFile.resource_name
      }
      setFieldValue(itemPath, assetFileFields)
    },
    [itemPath, setFieldValue, mediaFile]
  )

  const handleExistingMediaSelected = useCallback(
    (selectedMedia, mediaType) => {
      const { name, url, resource_name, width, height } = selectedMedia
      // remove file when select file from another type
      const newFileValues = {
        ...mediaFile,

        [MEDIA_WIDTH_PIXELS]: width,
        [MEDIA_HEIGHT_PIXELS]: height,
        [FILE_NAME]: name,
        [FILE_URL]: url,
        [ASSET_ID]: resource_name,
        [UPLOADED_MEDIA_TYPE]: selectedMediaOption,
        [FILE_TYPE]: mediaType
      }

      setFieldValue(itemPath, newFileValues)
    },
    [setFieldValue, selectedMediaOption, mediaFile, itemPath]
  )

  return (
    <div className={classes.googleMediaUpload}>
      <AdFileUpload
        values={formik.values}
        fileValidationSchema={fileValidationSchema}
        onFileStartUpload={handleMediaStartUpload}
        onFileUploaded={handleMediaUploaded}
        onFileRemove={handleMediaRemove}
        error={mediaMissingError}
        mediaType={MEDIA_FILE_UPLOAD}
        fileNameKey={fileNameKey}
        fileURLKey={fileURLKey}
        uploadedMediaTypeKey={uploadedMediaTypeKey}
        fileTypeKey={fileTypeKey}
        accept="image/png,image/jpeg,image/gif"
        maxSize={5242880}
        isDraggable={isDraggable}
      />
      <FieldSectionSpacer hasMargin />
      <GoogleImageFilesLibrary
        values={values}
        onMediaSelect={handleExistingMediaSelected}
        onFileRemove={handleMediaRemove}
        mediaType={MEDIA_FILE_IMAGE_USE_LIBRARY}
        fileNameKey={fileNameKey}
        fileURLKey={fileURLKey}
        uploadedMediaTypeKey={uploadedMediaTypeKey}
        adAccountId={adAccountId}
        // redesign props
        initialLoadPageSize={3}
        className={classes.imagesLibrary}
        mediaThumbnailClassName={classes.mediaThumbnail}
        loadMoreButtonClassName={classes.loadMoreButton}
        loadMoreButtonText="Load more images"
        shouldLoadInitialMedia={false}
      />
    </div>
  )
}

GoogleMediaUpload.propTypes = {
  formik: PropTypes.object.isRequired,
  itemPath: PropTypes.string.isRequired,
  fileValidationSchema: PropTypes.object,
  onMediaRemove: PropTypes.func,
  adAccountId: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

export default GoogleMediaUpload
