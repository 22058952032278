import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import ProgressButton from '../../../../../components/Form/ProgressButton'
import ArchiveButton from './ArchiveButton'
import DeleteModalWrapper from '../DeleteModalWrapper'
import CancelButton from '../../../Buttons/CancelButton'

import useStyles from './styles'

function DeleteItemModal({
  handleDelete,
  deleteItemError,
  deleteItemIsLoading,
  successDelete,
  clearErrorHandler,
  deleteModalMessage,
  isDeleteModalOpened,
  handleCloseDeleteModal,
  showCancelButton = false,
  modalTitle,
  // props for archive button
  archiveProps
}) {
  const { t } = useTranslation()

  const classes = useStyles()

  const defaultModalTitle = archiveProps ? t('Archive or delete?') : t('Are you sure?')

  return (
    <DeleteModalWrapper
      ModalMessage={deleteModalMessage}
      modalTitle={modalTitle || defaultModalTitle}
      isOpen={isDeleteModalOpened}
      onClose={handleCloseDeleteModal}
    >
      {archiveProps && (
        <ArchiveButton
          {...archiveProps}
          handleCloseDeleteModal={handleCloseDeleteModal}
          wrapperClassName={classes.deleteButtonWrapper}
        />
      )}
      {showCancelButton && <CancelButton onClick={handleCloseDeleteModal} />}
      <ProgressButton
        onClick={handleDelete}
        formError={deleteItemError}
        isFormLoading={deleteItemIsLoading}
        successSubmit={successDelete}
        clearHandler={clearErrorHandler}
        wrapperClassName={classes.deleteButtonWrapper}
        danger={true}
        isSmall={true}
      >
        {t('Delete')}
      </ProgressButton>
    </DeleteModalWrapper>
  )
}

DeleteItemModal.propTypes = {
  handleDelete: PropTypes.func,
  deleteItemError: PropTypes.func,
  deleteItemIsLoading: PropTypes.bool,
  successDelete: PropTypes.bool,
  clearErrorHandler: PropTypes.func,
  modalTitle: PropTypes.string,
  deleteModalMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isDeleteModalOpened: PropTypes.bool,
  handleCloseDeleteModal: PropTypes.func,
  showCancelButton: PropTypes.bool,
  archiveProps: PropTypes.object
}

export default DeleteItemModal
