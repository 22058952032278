import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

export const usersSelector = createSelector(
  ({ users }) => users.users.results,
  results => results
)

export const usersIsLoadingSelector = createSelector(
  ({ users }) => users.users.isLoading,
  isLoading => isLoading
)

export const usersWasLoadedSelector = createSelector(
  ({ users }) => users.users.wasLoaded,
  wasLoaded => wasLoaded
)
