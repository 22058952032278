import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { FB_PAGE_CHOICE } from '../../../../forms/Facebook/AdForms/fields'

import { accessibleFacebookPagesSelector } from '../../../../modules/selectors/socialAccounts'

function Avatar({ formikValues }) {
  const { t } = useTranslation()

  const facebookPages = useSelector(accessibleFacebookPagesSelector)

  const pageId = formikValues[FB_PAGE_CHOICE]
  const page = facebookPages?.find(page => page.id === pageId)
  const pageThumbnail = page && page.thumbnail

  return <>{pageThumbnail && <img src={pageThumbnail} alt={t('thumbnail')} />}</>
}

Avatar.propTypes = {
  formikValues: PropTypes.object
}

export default Avatar
