import React from 'react'

import SkeletonMediaPackagesSection from './Sections/MediaPackagesSection/Skeleton'
import SkeletonWelcomeSection from './Sections/WelcomeSection/WelcomeSectionSkeleton'

const SkeletonLandingPageContent = () => {
  return (
    <>
      <SkeletonWelcomeSection />
      <SkeletonMediaPackagesSection />
    </>
  )
}

export default SkeletonLandingPageContent
