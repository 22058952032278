import React from 'react'

import useStyles from './styles'

const AdCardMetrics = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.adCardMetrics}>{children}</div>
}

export default AdCardMetrics
