import * as Yup from 'yup'

import { getAdImageAssetInitialValues, getAdLandscapeImageAssets } from '../../helpers'
import { LANDSCAPE_IMAGE } from '../../../../fields'
import { listOfImagesShape } from '../../../../validations'

export const getInitialValues = ad => {
  const landscapeImageAssets = getAdLandscapeImageAssets(ad)

  return {
    [LANDSCAPE_IMAGE]: landscapeImageAssets
      ? landscapeImageAssets.map(asset => getAdImageAssetInitialValues(asset))
      : []
  }
}

export const validationSchema = Yup.object({
  [LANDSCAPE_IMAGE]: listOfImagesShape
})
