import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import EditForm from '../../../../../../features/components/Forms/EditForm'
import PlacementsSection from '../../../PlacementsSection'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import { formatPlacementPositionsToFE } from '../../formatters'

import {
  lineItemUpdateErrorSelector,
  lineItemUpdateIsLoadingSelector,
  lineItemWasUpdatedSelector
} from '../../../../../../modules/selectors/lineItems'
import { campaignSelector } from '../../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'
import { clearUpdateLineItem, updateLineItem } from '../../../../../../modules/actions/lineItems'

import { PLACEMENT_POSITIONS } from '../../../../../ReusableFormFields/LineItemForms/fields'
import { transformValuesToPlacementBE } from '../../fields'

const PlacementSectionForm = ({ editItemData: lineItem, ...formProps }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const lineItemWasUpdated = useSelector(lineItemWasUpdatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const { objective: campaignObjective } = useSelector(campaignSelector)

  const handleSubmit = useCallback(
    values => {
      const updateLineItemData = {
        account: selectedAdAccountId,
        campaign_objective: campaignObjective,
        ...transformValuesToPlacementBE(values, campaignObjective)
      }

      dispatch(updateLineItem(updateLineItemData, lineItem.id))
    },
    [dispatch, lineItem.id, campaignObjective, selectedAdAccountId]
  )

  const initialValues = useMemo(
    () => ({ ...formatPlacementPositionsToFE(lineItem[PLACEMENT_POSITIONS], campaignObjective) }),
    [lineItem, campaignObjective]
  )

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const handleClearUpdateLineItem = useCallback(() => {
    dispatch(clearUpdateLineItem())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={lineItemWasUpdated}
      clearEditItem={handleClearUpdateLineItem}
      errorSelector={lineItemUpdateErrorSelector}
      isLoadingSelector={lineItemUpdateIsLoadingSelector}
      {...formProps}
    >
      <p>{t('Select your ad placements across Facebook, Instagram, Messenger and Audience Network')}</p>
      <br />
      <PlacementsSection formik={formik} />
    </EditForm>
  )
}

export default PlacementSectionForm
