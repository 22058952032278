import { createStyles } from '../../../../../styles/helpers'

import { textEllipsis } from '../../../../../styles/mixins/text'
import { darkGrey } from '../../../../../styles/const/colors'

export const singleAdThumbnailHeight = '240px'

export default createStyles({
  adFooter: {
    padding: '8px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  footerInfo: {
    minHeight: 35,
    width: 140,
    padding: 0,
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  footerWebsite: {
    fontSize: 8,
    textTransform: 'lowercase',
    ...textEllipsis()
  },
  footerDescription: {
    fontSize: 9,
    fontWeight: 400,
    color: darkGrey
  },

  // HEADLINE
  singleHeadlineText: {
    margin: '2px 0 2px',
    fontSize: 11,
    ...textEllipsis()
  },
  skeletonSingleHeadline: {
    maxHeight: 10,
    height: 10
  },

  // DESCRIPTION
  singleDescriptionText: {
    fontSize: 9
  },
  singleContentActionButton: {
    minWidth: 55,
    padding: '0 2px'
  },

  // SINGLE THUMBNAIL
  singleThumbnail: {
    // crop the video to 4:5 ratio
    // crop the image to 1:1 ratio
    // maxHeight for single video ad is 300 because width is 240, 4:5 = 240:300
    maxHeight: mediaType => (mediaType === 'video' ? '300px' : singleAdThumbnailHeight)
  }
})
