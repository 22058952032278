import { createStyles } from '../helpers'

import { darkGrey, mediumGrey, textGrey } from '../const/colors'

const useStyles = createStyles({
  noDataContent: {
    color: mediumGrey,
    fontWeight: 600
  },
  mainText: {
    color: textGrey,
    fontSize: '14px',
    fontWeight: 600
  },
  infoText: {
    color: darkGrey,
    fontSize: '12px',
    fontWeight: 400
  },
  noDataContentInTheMiddleOfTheTable: {
    color: mediumGrey,
    fontWeight: 600,
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '40px'
  }
})

export default useStyles
