import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'

import { clearProduct, getProduct } from '../../../../../../../modules/actions/mediaOrdersProducts'
import { productSelector } from '../../../../../../../modules/selectors/mediaOrdersProducts'

import { initialProductValues } from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers'
import { CAMPAIGN_START_PERIOD_OPTION } from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/fields'
import { DATES_FORMAT_BE } from '../../../../../../../constants/dates'
import { MEDIA_PRODUCTS } from '../../fields'

export default function useGetSelectedProduct({ selectedProducts, setSelectedIndex, setFieldValue, values }) {
  const dispatch = useDispatch()

  const loadingProductIdRef = useRef(null)
  const [isProductLoading, setIsProductLoading] = useState(false)
  const loadedProduct = useSelector(productSelector)

  const productsCount = selectedProducts.length
  const handleAddAnotherItem = useCallback(
    productData => {
      dispatch(getProduct({ id: productData.value }))
      loadingProductIdRef.current = productData.value

      // open new item in carousel with skeleton loading while product data is loading
      setSelectedIndex(productsCount)
      setIsProductLoading(true)
    },
    [dispatch, productsCount, setSelectedIndex]
  )

  useEffect(() => {
    if (loadedProduct.id && isProductLoading) {
      // add new initial product values to carousel items array
      const newProduct = {
        ...initialProductValues,
        // ignore creative_deadline and just allow to select all periods from today(new Date()):
        [CAMPAIGN_START_PERIOD_OPTION]: format(new Date(), DATES_FORMAT_BE),
        data: {
          ...loadedProduct,
          // add internal id to product name if it exists
          name: loadedProduct.internal_id ? `${loadedProduct.internal_id} - ${loadedProduct.name}` : loadedProduct.name
        },
        internalId: loadedProduct.id // set the internal id to replace placeholder product section
      }

      // add loaded data to carousel items array
      setFieldValue(MEDIA_PRODUCTS, [...selectedProducts, newProduct])
      loadingProductIdRef.current = null
      setIsProductLoading(false)
      dispatch(clearProduct())
    }
  }, [dispatch, isProductLoading, loadedProduct, selectedProducts, setFieldValue, values])

  return useMemo(
    () => ({ isProductLoading, loadingProductId: loadingProductIdRef.current, handleAddAnotherItem }),
    [isProductLoading, loadingProductIdRef, handleAddAnotherItem]
  )
}
