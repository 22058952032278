import * as Yup from 'yup'

import { combineDateAndTime, formatDateWithTimeToBE } from '../../../../../../../helpers/date'

import {
  END_DATE,
  endDateValidation,
  START_DATE,
  startDateValidation
} from '../../../../../../ReusableFormFields/StartEndDateRangeFields/fields'
import { formatStartDateToBE } from '../../formatters'

export const initialValues = {
  [START_DATE]: '',
  [END_DATE]: ''
}

export const validationSchema = Yup.object({
  [START_DATE]: startDateValidation,
  [END_DATE]: endDateValidation
})

export const transformValuesToBE = ({ values, adAccountId, campaign, product, audience, country, pixelId }) => {
  return {
    name: `[${product['product_item_number']}] ${product.name}`,
    status: 'active',
    account: adAccountId,
    campaign_id: campaign.id,
    campaign_objective: campaign.objective,
    time_start: formatStartDateToBE(new Date(values[START_DATE])),
    time_stop: formatDateWithTimeToBE(combineDateAndTime(new Date(values[END_DATE]), '23:59')),
    pacing: ['standard'],
    geo_targeting: { countries: [country] },
    billing_event: 'impressions',
    gender: { male: true, female: true },
    age: { age_min: 18, age_max: 65 },
    custom_audiences: [{ id: audience.id }],
    promoted_object: { pixel_id: pixelId, custom_event_type: 'purchase' },
    brand_safety_content_filter_levels: ['facebook_strict', 'an_strict'],
    excluded_publisher_categories: [
      'dating',
      'gambling',
      'debated_social_issues',
      'mature_audiences',
      'tragedy_and_conflict'
    ]
  }
}
