import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  summaryRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 14
  },

  total: {
    marginBottom: 16
  }
})

export default useStyles
