import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import GoogleRegions from '../../../../ReusableFormFields/GoogleRegions'
import LocationsRedesign from '../../../../../ReusableFormFields/GeolocationsFields/LocationsRedesign'
import FieldsSection from '../../../../../../features/components/Form/FieldsSection'

import { clearGoogleGeolocations } from '../../../../../../modules/actions/googleConstants'
import { googleGeolocationsWasLoadedSelector } from '../../../../../../modules/selectors/googleConstants'

import {
  LOCATIONS_SELECTED_TAB,
  LOCATIONS_TAB,
  SHOW_COUNTRIES,
  TARGET_BY_REGION
} from '../../../../../ReusableFormFields/GeolocationsFields/fields'

const Locations = ({ formik }) => {
  const [prevSelectedTargetByRegion, setPrevSelectedTargetByRegion] = useState(false)
  const dispatch = useDispatch()

  const googleLocationsWasLoaded = useSelector(googleGeolocationsWasLoadedSelector)

  const { values } = formik

  useEffect(() => {
    // clear google locations data when target by region was checked
    // (firstly should be no option when search is empty firstly)
    if (
      values[LOCATIONS_SELECTED_TAB] === LOCATIONS_TAB &&
      googleLocationsWasLoaded &&
      values[TARGET_BY_REGION] &&
      !prevSelectedTargetByRegion
    ) {
      dispatch(clearGoogleGeolocations())
      setPrevSelectedTargetByRegion(true)
      // trigger clearGoogleGeolocations when target by region checkbox was checked and then change on not checked
    } else if (!values[TARGET_BY_REGION] && prevSelectedTargetByRegion) {
      dispatch(clearGoogleGeolocations())
      setPrevSelectedTargetByRegion(false)
    }
  }, [values, dispatch, googleLocationsWasLoaded, prevSelectedTargetByRegion])

  return (
    <FieldsSection title="Package contents">
      <LocationsRedesign
        formik={formik}
        TargetByRegionComponent={
          <GoogleRegions formik={formik} placeholder="Search for a region" countryChoiceConstants={SHOW_COUNTRIES} />
        }
        rowTitle="Location targeting"
        rowDescription="Select the countries or regions to target, or select a location list"
      />
    </FieldsSection>
  )
}

export default Locations
