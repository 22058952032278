import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Portal } from 'react-portal'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import Form from '../../../../../../components/Form'
import AdCard from '../../../../../../features/components/FacebookPreviews/AdCard'
import DrawerHeadline from '../../../../../../components/Drawer/DrawerHeadline'
import FacebookAdCreativeFields from '../../../../ReusableFormFIelds/FacebookAdCreativeFields'

import { useAiSuggestionsData } from './useAiSuggestionsData'
import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import useIsInternalAdCreation from '../../../../../../features/hooks/useIsInternalAdCreation'
import useLineItemDetails from '../../../../AdForms/AdFacebookCreate/AdFacebookCreateForm/hooks/useLineItemDetails'
import useFormattedFacebookPages from '../../../../AdForms/AdFacebookCreate/AdFacebookCreateForm/hooks/useFormattedFacebookPages'
import { AdFacebookCreateContextProvider } from '../../../../formsContexts/AdFacebookCreateFormContextProvider'

import { clearCreateAd, createAd } from '../../../../../../modules/actions/ads'
import { clearChoicesCTAByGoal, getChoicesCTAByGoal } from '../../../../../../modules/actions/choices'
import {
  clearAccessibleFacebookPages,
  getAccessibleFacebookPages
} from '../../../../../../modules/actions/socialAccounts'
import {
  adCreateLoadingSelector,
  adWasCreatedSelector,
  adCreateErrorSelector
} from '../../../../../../modules/selectors/ads'
import { selectedSelfAccountDataSelector } from '../../../../../../modules/selectors/app'
import { mediaOrderUploadCreativeDataSelector } from '../../../../../../modules/selectors/mediaOrders'
import { accessibleFacebookPagesSelector } from '../../../../../../modules/selectors/socialAccounts'

import { getInitialValues, validationSchema } from './fields'
import { FACEBOOK_PLATFORM } from '../../../../../../constants/selectLists/platformList'
import { AD_PRODUCT_FORM } from '../../Routes/ProductRetargetingRoute'
import { transformValuesToBE } from '../../../../AdForms/AdFacebookCreate/AdFacebookCreateForm/fields'

import useDrawerFormStyles from '../../../../../../styles/common/drawerForms'
import { tabletDownSize } from '../../../../../../styles/const/breakpoints'

const AdProductForm = ({
  onSuccessSubmit,
  adAccountId,
  activeStepNumber,
  customStepsLength,
  allowMediaUpload,
  showNameField,
  showCustomClickThroughLink,
  callToAction = ''
}) => {
  const drawerFormClasses = useDrawerFormStyles()
  const isTablet = useMediaQuery({ maxWidth: tabletDownSize })

  const dispatch = useDispatch()

  const {
    campaign,
    lineItem,
    catalogueProduct: selectedProduct,
    pixelId
  } = useSelector(mediaOrderUploadCreativeDataSelector)
  const adWasCreated = useSelector(adWasCreatedSelector)
  const facebookPages = useSelector(accessibleFacebookPagesSelector)
  const selectedSelfAccountData = useSelector(selectedSelfAccountDataSelector)
  const { controller: selfAccountController, id: selectedSelfAccountId } = selectedSelfAccountData

  const { objective: campaignObjective } = campaign

  const allowLoadSuggestions = !!selectedProduct.name

  const { placementPositions } = useLineItemDetails()
  const isInternalAdCreation = useIsInternalAdCreation()
  const { regenerateSuggestions } = useAiSuggestionsData({
    selectedProduct,
    allowLoadSuggestions
  })
  const formattedFacebookPages = useFormattedFacebookPages(facebookPages)

  const onSubmit = useCallback(
    values => {
      dispatch(
        createAd(
          transformValuesToBE({
            values,
            adAccountId,
            adSetId: lineItem.id,
            campaignId: campaign.id,
            isInternalAdCreation,
            selfAccountController,
            selectedSelfAccountId
          }),
          FACEBOOK_PLATFORM
        )
      )
    },
    [
      dispatch,
      adAccountId,
      lineItem.id,
      campaign.id,
      isInternalAdCreation,
      selfAccountController,
      selectedSelfAccountId
    ]
  )

  const initialValues = useMemo(
    () =>
      getInitialValues({
        ad: selectedProduct,
        pixelId,
        facebookPages: formattedFacebookPages,
        callToAction
      }),
    [selectedProduct, pixelId, formattedFacebookPages, callToAction]
  )
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)
  const { values } = purifiedFormik

  const clearHandler = useCallback(() => {
    dispatch(clearCreateAd())
  }, [dispatch])

  useEffect(() => {
    dispatch(getAccessibleFacebookPages())

    return () => {
      dispatch(clearAccessibleFacebookPages())
    }
  }, [dispatch])

  useEffect(() => {
    if (campaignObjective) {
      dispatch(getChoicesCTAByGoal({ goals: campaignObjective }))
    }
  }, [dispatch, campaignObjective])

  useEffect(
    () => () => {
      dispatch(clearChoicesCTAByGoal())
    },
    [dispatch]
  )

  return (
    <div className={drawerFormClasses.formContainer}>
      <DrawerHeadline
        customStepsLength={customStepsLength}
        activeStepNumber={activeStepNumber}
        description={
          allowLoadSuggestions
            ? 'You can use from the AI suggested options or customise the ad with your own content'
            : ''
        }
        title="Review and customise the ad creative"
      />
      {isTablet ? (
        <AdCard
          // initial values consists from product data
          formikValues={values}
          showSkeletonPlaceholders={true}
        />
      ) : (
        <Portal node={document && document.getElementById('formSideColumnPortal')}>
          <AdCard
            // initial values consists from product data
            formikValues={values}
            showSkeletonPlaceholders={true}
          />
        </Portal>
      )}

      <Form
        formName={AD_PRODUCT_FORM}
        formik={purifiedFormik}
        successSubmit={adWasCreated}
        onSuccessSubmit={onSuccessSubmit}
        errorSelector={adCreateErrorSelector}
        isLoadingSelector={adCreateLoadingSelector}
        clearHandler={clearHandler}
      >
        <AdFacebookCreateContextProvider campaignObjective={campaignObjective}>
          <FacebookAdCreativeFields
            formik={formik}
            adAccountId={adAccountId}
            regenerateSuggestions={regenerateSuggestions}
            selectedProduct={selectedProduct}
            allowMediaUpload={allowMediaUpload}
            placementPositions={placementPositions}
            showNameField={showNameField}
            showCustomClickThroughLink={showCustomClickThroughLink}
          />
        </AdFacebookCreateContextProvider>
      </Form>
    </div>
  )
}

AdProductForm.propTypes = {
  activeStepNumber: PropTypes.number,
  customStepsLength: PropTypes.number,
  onSuccessSubmit: PropTypes.func.isRequired,
  adAccountId: PropTypes.string,
  allowMediaUpload: PropTypes.bool,
  showNameField: PropTypes.bool,
  showCustomClickThroughLink: PropTypes.bool,
  callToAction: PropTypes.string
}

export default AdProductForm
