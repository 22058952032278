import { formatDateToBE } from '../../../../../constants/dates'

export const createDV360LineItemPayload = ({ name, status, startDate, endDate }) => {
  return {
    name: name,
    status: status,

    line_item_type: 'display_default',

    flight: {
      flight_date_type: 'LINE_ITEM_FLIGHT_DATE_TYPE_CUSTOM',
      date_range: {
        start_date: formatDateToBE(new Date(startDate)),
        end_date: formatDateToBE(new Date(endDate))
      }
    },
    pacing: {
      pacing_period: 'PACING_PERIOD_FLIGHT',
      pacing_type: 'PACING_TYPE_ASAP'
    },
    budget: {
      budget_allocation_type: 'LINE_ITEM_BUDGET_ALLOCATION_TYPE_UNLIMITED',
      budget_unit: 'BUDGET_UNIT_CURRENCY'
    },
    bid_strategy: {
      maximize_spend_auto_bid: {
        performance_goal_type: 'BIDDING_STRATEGY_PERFORMANCE_GOAL_TYPE_CPC'
      }
    },
    frequency_cap: {
      unlimited: true
    },
    partner_revenue_model: {
      markup_type: 'PARTNER_REVENUE_MODEL_MARKUP_TYPE_TOTAL_MEDIA_COST_MARKUP'
    }
  }
}
