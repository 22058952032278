import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import TableDataLoader from '../../../../components/Table/TableDataLoader'
import AgencyAccountsTableData from './AgencyAccountsTableData'

import {
  agenciesSelector,
  agenciesWasLoadedSelector,
  getAgenciesErrorSelector,
  getAgenciesIsLoadingSelector
} from '../../../../modules/selectors/agencies'

import useCommonStyles from '../../../../styles/common/table'

export const tableColumnsSize = {
  actions: 30
}

const AgencyAccountsTable = ({ loadMoreHandler }) => {
  const { t } = useTranslation()

  const agencies = useSelector(agenciesSelector)

  const commonClasses = useCommonStyles()

  const accountsSchema = useMemo(
    () => [
      {
        header: 'Name'
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no agency accounts')}</div>
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <TableDataLoader
      loadMore={loadMoreHandler}
      itemsLength={agencies.length}
      errorSelector={getAgenciesErrorSelector}
      wasLoadedSelector={agenciesWasLoadedSelector}
      isLoadingSelector={getAgenciesIsLoadingSelector}
      skeletonProps={{ cols: accountsSchema }}
      noDataContent={noDataContent}
    >
      <AgencyAccountsTableData />
    </TableDataLoader>
  )
}

AgencyAccountsTable.propTypes = {
  loadMoreHandler: PropTypes.func
}

export default AgencyAccountsTable
