import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import Field from '../../../../../components/Form/Field'
import Form from '../../../../../components/Form'

import { createTag, clearCreateTag } from '../../../../../modules/actions/tags'
import {
  createTagIsLoadingSelector,
  createTagErrorSelector,
  createTagWasCreatedSelector
} from '../../../../../modules/selectors/tags'
import { selectedSelfAccountDataSelector } from '../../../../../modules/selectors/app'

import { CREATE_SELF_ACCOUNT_TAG } from '../../../../../constants/forms'

import { validationSchema } from './validation'
import { initialValues, TAG } from './fields'

function TagsCreateForm() {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const itemWasCreated = useSelector(createTagWasCreatedSelector)
  const { controller } = useSelector(selectedSelfAccountDataSelector)

  const onSubmit = useCallback(
    values => {
      dispatch(createTag({ title: values[TAG], controller }))
    },
    [dispatch, controller]
  )

  const handleClearHandler = useCallback(() => {
    dispatch(clearCreateTag())
  }, [dispatch])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  return (
    <Form
      formik={formik}
      formName={CREATE_SELF_ACCOUNT_TAG}
      successSubmit={itemWasCreated}
      errorSelector={createTagErrorSelector}
      clearHandler={handleClearHandler}
      isLoadingSelector={createTagIsLoadingSelector}
    >
      <Field formik={formik} id={TAG} name={TAG} placeholder={t('Enter tag')} />
    </Form>
  )
}

export default TagsCreateForm
