import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Field from '../../../../../../components/Form/Field'
import Step from '../../../../../../features/components/Forms/StepForm/Step'

import { formatOptionsList } from '../../../../../../features/formatters'
import { choicesPixelsSelector } from '../../../../../../modules/selectors/choices'

import { PIXEL_CHOICE } from '../../../fields'

import useDrawerFormStyles from '../../../../../../styles/common/drawerForms'

const EventSourceStep = ({ formik }) => {
  const { t } = useTranslation()

  const pixels = useSelector(choicesPixelsSelector)

  const drawerFormClasses = useDrawerFormStyles()

  const formattedPixels = useMemo(
    () =>
      formatOptionsList({
        list: pixels,
        labelName: 'name',
        valueName: 'id'
      }),
    [pixels]
  )

  return (
    <Step stepTitle="Select the pixel that is installed on your website">
      <section className={drawerFormClasses.section}>
        <h4 className={drawerFormClasses.sectionTitle}>{t('Event Source')}</h4>
        <Field placeholder="Event Source" formik={formik} name={PIXEL_CHOICE} options={formattedPixels} />
      </section>
    </Step>
  )
}

export default EventSourceStep
