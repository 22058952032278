import React from 'react'
import PropTypes from 'prop-types'

import ImageAssetPreview from '../ImageAssetPreview'

import useStyles from './styles'

const containerWidth = 260

const GoogleAdImageType = React.memo(({ adData }) => {
  // styles are duplicated from GoogleAdPreviewDiscover1
  const classes = useStyles(containerWidth)
  const { name, ad } = adData

  const fileUrl = ad.image_ad?.image_url
  const width = ad.image_ad?.pixel_width
  const height = ad.image_ad?.pixel_height

  const url = ad.final_urls?.[0]
  const urlText = ad.display_url

  return (
    <div className={classes.adPreview}>
      <ImageAssetPreview containerWidth={containerWidth} width={width} height={height} fileUrl={fileUrl} />
      <footer className={classes.footer}>
        <div className={classes.footerTop}>{name}</div>
        <div className={classes.footerBottom}>
          <a className={classes.link} href={url} rel="noreferrer nofollow" target="_blank">
            {urlText}
          </a>
        </div>
      </footer>
    </div>
  )
})

GoogleAdImageType.propTypes = {
  adData: PropTypes.object
}

export default GoogleAdImageType
