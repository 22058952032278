import React from 'react'
import { useSelector } from 'react-redux'

import LineItemTikTokCreateForm from './LineItemTikTokCreateForm'
import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'

import useRedirectFormDrawer from '../../../../features/components/FormDrawerWrapper/useRedirectFormDrawer'

import { campaignWasLoadedSelector } from '../../../../modules/selectors/campaigns'
import { lineItemsWasLoadedSelector } from '../../../../modules/selectors/lineItems'

import { LINE_ITEM_TIKTOK_CREATE } from '../../../../constants/forms'
import { ROUTES } from '../../../../constants/routes'
import { MODAL_TYPE } from '../../../../constants/other'

const LineItemTikTokCreate = () => {
  const campaignWasLoaded = useSelector(campaignWasLoadedSelector)
  const lineItemsWasLoaded = useSelector(lineItemsWasLoadedSelector)

  const isInitialDataLoading = !campaignWasLoaded || !lineItemsWasLoaded

  const handleSuccessRedirect = useRedirectFormDrawer(
    `${ROUTES.lineItemsSummary}?message=${MODAL_TYPE.newLineItemCreated}`
  )

  return (
    <FormDrawerWrapper
      formName={LINE_ITEM_TIKTOK_CREATE}
      openButtonText="Create Ad Group"
      isFormLoading={isInitialDataLoading}
      closeOnSubmit={false}
    >
      <LineItemTikTokCreateForm onSuccessSubmit={handleSuccessRedirect} />
    </FormDrawerWrapper>
  )
}

export default LineItemTikTokCreate
