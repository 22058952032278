import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { campaignCriterionsSelector } from '../../../../../../modules/selectors/campaigns'
import { CAMPAIGN_GOOGLE_LOCATION_CRITERION_TYPE } from '../../../../../../constants/campaigns'

const GeoTargetingSectionPreview = () => {
  const campaignCriterions = useSelector(campaignCriterionsSelector)

  // simple location (countries / regions)
  const filteredLocations = useMemo(
    () => campaignCriterions.filter(criterion => criterion.type_ === CAMPAIGN_GOOGLE_LOCATION_CRITERION_TYPE),
    [campaignCriterions]
  )

  return (
    !!filteredLocations.length && (
      <p>
        {filteredLocations.map(({ criterion_id: criterionId, display_name: displayName }) => (
          <span key={criterionId}>{displayName}</span>
        ))}
      </p>
    )
  )
}

export default GeoTargetingSectionPreview
