import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import PeriodPrice from './PeriodPrice'
import Field from '../../../../../../../../../components/Form/Field'

import {
  // calculateDiscountedPrice,
  getDecimalDiscount
} from '../../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/price'
import { formatDateFullYear } from '../../../../../../../../../constants/dates'
import { PRODUCT_PERIODS_DATES, DISCOUNT } from '../../../../../../fields'

import useStyles from './styles'

function SelectedPeriodsList({
  formik,
  itemPath,
  productPeriods,
  productsQuantity,
  currencySymbol,
  showPeriodPrice = true
}) {
  const classes = useStyles()
  const discount = getDecimalDiscount(formik.values[DISCOUNT])
  const getPeriodPath = useCallback(
    periodIndex => {
      return `${itemPath}.${PRODUCT_PERIODS_DATES}[${periodIndex}].price`
    },
    [itemPath]
  )

  return (
    <ul className={classes.publicationsList}>
      {productPeriods.map((period, periodIndex) => {
        const periodPricePath = getPeriodPath(periodIndex)

        // uncomment if the custom discount will be implemented:
        // const price = period.price || 0
        // const discountedPrice = calculateDiscountedPrice(price, 1, discount)

        return (
          <li className={classes.publicationDetails} key={period.value}>
            <div className={classes.periodLabel}>{formatDateFullYear(new Date(period.value))}</div>
            <Field
              type="number"
              inputMode="decimal"
              className={classes.priceField}
              formik={formik}
              disabled={Number(discount) > 0}
              name={periodPricePath}
              symbol={currencySymbol}
              placeholder="Unit Price"
              enableReinitialize
              autoComplete="off"
              fixedDecimalScale={true}
              decimalScale={2}
            />
            <div>{`x ${productsQuantity}`}</div>
            {showPeriodPrice && (
              <PeriodPrice
                className={classes.price}
                productQuantity={productsQuantity}
                periodPrice={period.price}
                currentDiscount={discount}
                currencySymbol={currencySymbol}
              />
            )}
          </li>
        )
      })}
    </ul>
  )
}

SelectedPeriodsList.propTypes = {
  productPeriods: PropTypes.array.isRequired,
  currentDiscount: PropTypes.number,
  currencySymbol: PropTypes.string.isRequired,
  productsQuantity: PropTypes.number,
  showPeriodPrice: PropTypes.bool
}

export default SelectedPeriodsList
