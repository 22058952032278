import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import FieldsSection from '../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../components/Form/Field'
import AccountsAccessFieldRow from '../../../reusableFormFields/AccountsAccessFieldRow'
import MultiSelectBox from '../../../../../../features/components/Form/MultiSelectBox'

import { getAllowSelfAccounts, getShowBrandCategorySelect, getShowMediaSubCategories } from '../../../helpers'
import { formatOptionsList } from '../../../../../../features/formatters'

import { selfAccountCategoriesSelector } from '../../../../../../modules/selectors/selfAccounts'
import {
  getMediaSubCategoriesIsLoadingSelector,
  mediaSubCategoriesSelector
} from '../../../../../../modules/selectors/mediaOrdersProducts'

import { EMAIL, MEDIA_SUB_CATEGORIES, ROLE, roleOptions } from '../fields'
import { BRAND_CATEGORY } from '../../../fields'

import useCommonStyles from '../../../../../../styles/common/stepForms'
import useStyles from './styles'

const ControllerInviteCreateFormContent = ({ formik }) => {
  const { t } = useTranslation()

  const commonClasses = useCommonStyles()
  const classes = useStyles()

  const selfAccountCategories = useSelector(selfAccountCategoriesSelector)
  const mediaSubCategories = useSelector(mediaSubCategoriesSelector)
  const getMediaSubCategoriesIsLoading = useSelector(getMediaSubCategoriesIsLoadingSelector)

  const { values, errors, setFieldValue } = formik

  const allowSelfAccounts = getAllowSelfAccounts(values[ROLE])
  const showBrandCategorySelect = getShowBrandCategorySelect(values[ROLE])
  const showMediaSubCategories = getShowMediaSubCategories(values[ROLE])

  const formattedSelfAccountCategoriesOptions = useMemo(() => {
    return formatOptionsList({
      list: selfAccountCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [selfAccountCategories])

  const formattedMediaSubCategoriesOptions = useMemo(() => {
    return formatOptionsList({
      list: mediaSubCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [mediaSubCategories])

  return (
    <div className={commonClasses.stepBody}>
      <FieldsSection title={t('User email')}>
        <FieldRow
          title={t('Email address')}
          description={t('This user will receive an email inviting them to this account.')}
        >
          <Field placeholder={t('email address')} formik={formik} name={EMAIL} />
        </FieldRow>
        <FieldRow
          title={t('Role')}
          description={
            <>
              <div className={classes.roleDescription}>
                <b>{t('Admin')}</b>
                {t(': Full access')}
              </div>
              <div className={classes.roleDescription}>
                <b>{t('Manager')}</b>
                {t(': Full campaign management access')}
              </div>
              <div className={classes.roleDescription}>
                <b>{t('Reporting')}</b>
                {t(': Reporting only')}
              </div>
              <div className={classes.roleDescription}>
                <b>{t('Buyer')}</b>
                {t(': Reporting for a buyer’s specific brand category')}
              </div>
            </>
          }
        >
          <Field placeholder={t('Select Role')} formik={formik} name={ROLE} options={roleOptions} />
        </FieldRow>
        {allowSelfAccounts && <AccountsAccessFieldRow formik={formik} />}
        {showBrandCategorySelect && (
          <FieldRow title={t('Brand Category')}>
            <Field
              placeholder={t('Brand Category')}
              formik={formik}
              name={BRAND_CATEGORY}
              options={formattedSelfAccountCategoriesOptions}
            />
          </FieldRow>
        )}

        {showMediaSubCategories && (
          <FieldRow title={t('Media Sub Categories')}>
            <MultiSelectBox
              isLoading={getMediaSubCategoriesIsLoading}
              placeholder={t('Media Sub Categories')}
              name={MEDIA_SUB_CATEGORIES}
              options={formattedMediaSubCategoriesOptions}
              value={values[MEDIA_SUB_CATEGORIES]}
              setFieldValue={setFieldValue}
              error={errors[MEDIA_SUB_CATEGORIES]}
              touched
            />
          </FieldRow>
        )}
      </FieldsSection>
    </div>
  )
}

export default ControllerInviteCreateFormContent
