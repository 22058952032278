import { TAGS } from '../../../../MediaProductCreate/MediaProductCreateForm/fields'

export const getTagsSectionInitialValues = (editProductData, productTags) => {
  const productsTagsTitles = productTags.map(tag => tag.title)

  return {
    [TAGS]:
      editProductData?.[TAGS].filter(tag => productsTagsTitles.includes(tag)).map(tag => ({
        value: tag,
        label: tag
      })) || []
  }
}
