import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import RadioBlock from '../../../components/Form/RadioBlock'

import { INTERESTS_OPTION } from './fields'
import { OPTION_NO, OPTION_YES } from '../../../constants/forms'

const InterestsFields = ({ formik, interestsError, interestsTouched, InterestsCustomField }) => {
  const { t } = useTranslation()

  const { values, setFieldValue } = formik

  return (
    <>
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_interests_no"
        name={INTERESTS_OPTION}
        value={OPTION_NO}
        selectedValue={values[INTERESTS_OPTION]}
        label={t('No')}
      />
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_interests_interests_yes"
        name={INTERESTS_OPTION}
        value={OPTION_YES}
        selectedValue={values[INTERESTS_OPTION]}
        label={t('Yes, only target people who have these selected interests')}
        error={interestsError}
        touched={interestsTouched}
      >
        <p>
          {t('If you select multiple interests, you will target people with at least one of the selected interests')}
        </p>
        {InterestsCustomField}
      </RadioBlock>
    </>
  )
}

InterestsFields.propTypes = {
  formik: PropTypes.object.isRequired,
  interestsError: PropTypes.string,
  interestsTouched: PropTypes.bool,
  InterestCustomField: PropTypes.node.isRequired
}

export default InterestsFields
