import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import AdCardMetricContainer from '../../../../../../features/components/AdCardListItem/AdCardMetrics/AdCardMetricContainer'
import AdCardMetricsWrapper from '../../../../../../features/components/AdCardListItem/AdCardMetrics'

import { renderMetricValue } from '../../../../../../features/components/AdCardListItem/AdCardMetrics/AdCardMetricContainer/renderMetricValue'

import { formatDigitalItemValues } from '../../../../../../features/formatters'

import useStyles from './styles'

function AdCardMetrics({ ad, currencySymbol, showPurchaseMetric }) {
  const classes = useStyles()

  const { t } = useTranslation()

  const formattedData = useMemo(() => formatDigitalItemValues(ad, { symbol: currencySymbol }), [ad, currencySymbol])

  const { impressions, cpm, reach, frequency, clicks, ctr, engagement, purchase, roas } = formattedData

  return (
    <AdCardMetricsWrapper>
      <AdCardMetricContainer>
        <div>
          {t('Impressions')}
          <span className={classes.metricAdditional}> ({t('CPM')})</span>
        </div>
        <div>
          {renderMetricValue(impressions)}
          <span className={classes.metricAdditional}> ({renderMetricValue(cpm)})</span>
        </div>
      </AdCardMetricContainer>

      <AdCardMetricContainer>
        <div>
          {t('Reach')} <span className={classes.metricAdditional}>({t('Frequency')})</span>
        </div>
        <div>
          {renderMetricValue(reach)} <span className={classes.metricAdditional}>({renderMetricValue(frequency)})</span>
        </div>
      </AdCardMetricContainer>

      <AdCardMetricContainer>
        <div>
          {t('Clicks')} <span className={classes.metricAdditional}>({t('CTR')})</span>
        </div>
        <div>
          {renderMetricValue(clicks)} <span className={classes.metricAdditional}>({renderMetricValue(ctr)})</span>
        </div>
      </AdCardMetricContainer>

      <AdCardMetricContainer>
        <div>{t('Engagement')}</div>
        <div>{renderMetricValue(engagement)}</div>
      </AdCardMetricContainer>

      {showPurchaseMetric && (
        <AdCardMetricContainer>
          <div>
            {t('Purchases')} <span className={classes.metricAdditional}>({t('ROAS')})</span>
          </div>
          <div>
            {renderMetricValue(purchase)} <span className={classes.metricAdditional}>({renderMetricValue(roas)})</span>
          </div>
        </AdCardMetricContainer>
      )}
    </AdCardMetricsWrapper>
  )
}

export default AdCardMetrics

AdCardMetrics.propTypes = {
  ad: PropTypes.object.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  showPurchaseMetric: PropTypes.bool
}
