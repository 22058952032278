import update from 'immutability-helper'
import { updateItem } from '../../helpers/modules/reducerHelpers'

import {
  INITIAL_CREATE_REDUCER,
  INITIAL_GET_REDUCER,
  INITIAL_GET_RESULTS_PAGING_REDUCER,
  INITIAL_UPDATE_REDUCER
} from '../../constants/reducer'
import {
  GET_MEDIA_PRODUCT_LOCATION,
  GET_MEDIA_PRODUCT_LOCATION_SUCCESS,
  GET_MEDIA_PRODUCT_LOCATION_FAILURE,
  CLEAR_GET_MEDIA_PRODUCT_LOCATION,
  GET_MEDIA_PRODUCT_LOCATIONS,
  GET_MEDIA_PRODUCT_LOCATIONS_FAILURE,
  GET_MEDIA_PRODUCT_LOCATIONS_SUCCESS,
  CLEAR_CREATE_MEDIA_PRODUCT_LOCATION,
  CLEAR_GET_MEDIA_PRODUCT_LOCATIONS,
  CLEAR_UPDATE_MEDIA_PRODUCT_LOCATION,
  CREATE_MEDIA_PRODUCT_LOCATION,
  CREATE_MEDIA_PRODUCT_LOCATION_FAILURE,
  CREATE_MEDIA_PRODUCT_LOCATION_SUCCESS,
  UPDATE_MEDIA_PRODUCT_LOCATION,
  UPDATE_MEDIA_PRODUCT_LOCATION_FAILURE,
  UPDATE_MEDIA_PRODUCT_LOCATION_SUCCESS,
  GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS,
  GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS_SUCCESS,
  GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS_FAILURE,
  CLEAR_GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS,
  GET_BOOKED_MEDIA_ATTACHED_LOCATIONS,
  GET_BOOKED_MEDIA_ATTACHED_LOCATIONS_SUCCESS,
  GET_BOOKED_MEDIA_ATTACHED_LOCATIONS_FAILURE,
  CLEAR_GET_BOOKED_MEDIA_ATTACHED_LOCATIONS,
  GET_PRODUCT_STORES,
  GET_PRODUCT_STORES_SUCCESS,
  GET_PRODUCT_STORES_FAILURE,
  CLEAR_GET_PRODUCT_STORES
} from '../actions/mediaOrdersProductLocations'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  getMediaProductLocation: INITIAL_GET_REDUCER,
  mediaProductLocations: INITIAL_GET_RESULTS_PAGING_REDUCER,
  createMediaProductLocation: INITIAL_CREATE_REDUCER,
  updateMediaProductLocation: INITIAL_UPDATE_REDUCER,
  mediaProductAttachedLocations: INITIAL_GET_RESULTS_PAGING_REDUCER,
  bookedMediaAttachedLocations: INITIAL_GET_RESULTS_PAGING_REDUCER,
  getProductStores: INITIAL_GET_RESULTS_PAGING_REDUCER
}

export default function mediaOrdersProductLocations(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // Get media product location
    case GET_MEDIA_PRODUCT_LOCATION:
      return update(state, {
        getMediaProductLocation: { $merge: { isLoading: true } }
      })
    case GET_MEDIA_PRODUCT_LOCATION_SUCCESS:
      return update(state, {
        getMediaProductLocation: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        }
      })
    case GET_MEDIA_PRODUCT_LOCATION_FAILURE:
      return update(state, {
        getMediaProductLocation: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_MEDIA_PRODUCT_LOCATION:
      return update(state, {
        getMediaProductLocation: { $set: initialState.getMediaProductLocation }
      })

    // Get media product locations
    case GET_MEDIA_PRODUCT_LOCATIONS:
      return update(state, {
        mediaProductLocations: { $merge: { isLoading: true } }
      })
    case GET_MEDIA_PRODUCT_LOCATIONS_SUCCESS:
      return update(state, {
        mediaProductLocations: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: {
              next: action.data.next
            }
          },
          results: {
            $push: action.data.results
          }
        }
      })
    case GET_MEDIA_PRODUCT_LOCATIONS_FAILURE:
      return update(state, {
        mediaProductLocations: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_MEDIA_PRODUCT_LOCATIONS:
      return update(state, {
        mediaProductLocations: { $set: initialState.mediaProductLocations }
      })

    // Create media product location
    case CREATE_MEDIA_PRODUCT_LOCATION:
      return update(state, {
        createMediaProductLocation: { $merge: { isLoading: true } }
      })
    case CREATE_MEDIA_PRODUCT_LOCATION_SUCCESS:
      return update(state, {
        createMediaProductLocation: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },

        // update mediaProductLocations results
        mediaProductLocations: {
          results: {
            $unshift: [action.data]
          }
        }
      })
    case CREATE_MEDIA_PRODUCT_LOCATION_FAILURE:
      return update(state, {
        createMediaProductLocation: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_MEDIA_PRODUCT_LOCATION:
      return update(state, {
        createMediaProductLocation: { $set: initialState.createMediaProductLocation }
      })

    // Update media product location
    case UPDATE_MEDIA_PRODUCT_LOCATION:
      return update(state, {
        updateMediaProductLocation: { $merge: { isLoading: true } }
      })
    case UPDATE_MEDIA_PRODUCT_LOCATION_SUCCESS:
      return update(state, {
        updateMediaProductLocation: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            data: action.data
          }
        },
        mediaProductLocations: {
          results: {
            $apply: items => updateItem(items, action.data)
          }
        }
      })
    case UPDATE_MEDIA_PRODUCT_LOCATION_FAILURE:
      return update(state, {
        updateMediaProductLocation: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_MEDIA_PRODUCT_LOCATION:
      return update(state, {
        updateMediaProductLocation: { $set: initialState.updateMediaProductLocation }
      })

    // Get media product attached locations
    case GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS:
      return update(state, {
        mediaProductAttachedLocations: { $merge: { isLoading: true } }
      })
    case GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS_SUCCESS:
      return update(state, {
        mediaProductAttachedLocations: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data
          }
        }
      })
    case GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS_FAILURE:
      return update(state, {
        mediaProductAttachedLocations: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_MEDIA_PRODUCT_ATTACHED_LOCATIONS:
      return update(state, {
        mediaProductAttachedLocations: { $set: initialState.mediaProductAttachedLocations }
      })

    // Get booked media attached locations
    case GET_BOOKED_MEDIA_ATTACHED_LOCATIONS:
      return update(state, {
        bookedMediaAttachedLocations: { $merge: { isLoading: true } }
      })
    case GET_BOOKED_MEDIA_ATTACHED_LOCATIONS_SUCCESS:
      return update(state, {
        bookedMediaAttachedLocations: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data
          }
        }
      })
    case GET_BOOKED_MEDIA_ATTACHED_LOCATIONS_FAILURE:
      return update(state, {
        bookedMediaAttachedLocations: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_BOOKED_MEDIA_ATTACHED_LOCATIONS:
      return update(state, {
        bookedMediaAttachedLocations: { $set: initialState.bookedMediaAttachedLocations }
      })

    // Get product stores
    case GET_PRODUCT_STORES:
      return update(state, {
        getProductStores: { $merge: { isLoading: true } }
      })
    case GET_PRODUCT_STORES_SUCCESS:
      return update(state, {
        getProductStores: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data.results
          }
        }
      })
    case GET_PRODUCT_STORES_FAILURE:
      return update(state, {
        getProductStores: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_PRODUCT_STORES:
      return update(state, {
        getProductStores: { $set: initialState.getProductStores }
      })

    default:
      return state
  }
}
