import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import MultiSelectBox from '../../../../features/components/Form/MultiSelectBox'

import { getGoogleGeolocations } from '../../../../modules/actions/googleConstants'
import {
  googleGeolocationsIsLoadingSelector,
  googleGeolocationsSelector
} from '../../../../modules/selectors/googleConstants'
import { selectedAdAccountIdSelector } from '../../../../modules/selectors/app'

const GoogleGeolocationRegion = ({
  selectedCountry,
  name,
  value,
  setFieldValue,
  adAccountId: providedAdAccountId,
  placeholder = 'Add a region'
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const geolocations = useSelector(googleGeolocationsSelector)
  const geolocationsIsLoading = useSelector(googleGeolocationsIsLoadingSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const adAccountId = providedAdAccountId || selectedAdAccountId

  const formattedGeolocations = useMemo(
    () =>
      geolocations &&
      geolocations.map(item => {
        const { id, canonical_name, target_type, resource_name } = item

        return { value: id, label: canonical_name, type: target_type, resource_name }
      }),
    [geolocations]
  )

  const getGeolocations = useCallback(
    searchValue => {
      dispatch(
        getGoogleGeolocations({
          country_code: selectedCountry && selectedCountry.iso2,
          account: adAccountId,
          search: searchValue
        })
      )
    },
    [dispatch, selectedCountry, adAccountId]
  )

  const onSearchChange = useCallback(
    searchValue => {
      getGeolocations(searchValue)
    },
    [getGeolocations]
  )

  return (
    <MultiSelectBox
      placeholder={t(placeholder)}
      name={name}
      options={formattedGeolocations}
      value={value}
      isLoading={geolocationsIsLoading}
      setFieldValue={setFieldValue}
      // clear search on selectedCountry change
      triggerClearSearch={selectedCountry?.iso2}
      onSearch={onSearchChange}
    />
  )
}

GoogleGeolocationRegion.propTypes = {
  selectedCountry: PropTypes.object,
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  setFieldValue: PropTypes.func.isRequired,
  adAccountId: PropTypes.string,
  placeholder: PropTypes.string
}

export default GoogleGeolocationRegion
