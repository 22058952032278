import update from 'immutability-helper'

import {
  INITIAL_CREATE_REDUCER,
  INITIAL_GET_REDUCER,
  INITIAL_GET_RESULTS_PAGING_REDUCER
} from '../../constants/reducer'
import {
  APPLY_MEDIA_ORDER_SUGGESTED_CHANGES,
  APPLY_MEDIA_ORDER_SUGGESTED_CHANGES_FAILURE,
  APPLY_MEDIA_ORDER_SUGGESTED_CHANGES_SUCCESS,
  CLEAR_APPLY_MEDIA_ORDER_SUGGESTED_CHANGES,
  CLEAR_MEDIA_ORDER_SUGGESTED_CHANGES,
  CLEAR_OPTIMISATION_HISTORY,
  GET_MEDIA_ORDER_SUGGESTED_CHANGES,
  GET_MEDIA_ORDER_SUGGESTED_CHANGES_FAILURE,
  GET_MEDIA_ORDER_SUGGESTED_CHANGES_SUCCESS,
  GET_OPTIMISATION_HISTORY,
  GET_OPTIMISATION_HISTORY_FAILURE,
  GET_OPTIMISATION_HISTORY_SUCCESS
} from '../actions/mediaOrderOptimisations'

const initialState = {
  mediaOrderSuggestedChanges: INITIAL_GET_REDUCER,
  applyMediaOrderSuggestedChanges: INITIAL_CREATE_REDUCER,
  optimisationHistory: INITIAL_GET_RESULTS_PAGING_REDUCER
}

export default function mediaOrderOptimisations(state = initialState, action) {
  switch (action.type) {
    // get media order suggested changes
    case GET_MEDIA_ORDER_SUGGESTED_CHANGES:
      return update(state, {
        mediaOrderSuggestedChanges: { $merge: { isLoading: true } }
      })
    case GET_MEDIA_ORDER_SUGGESTED_CHANGES_SUCCESS:
      return update(state, {
        mediaOrderSuggestedChanges: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        }
      })
    case GET_MEDIA_ORDER_SUGGESTED_CHANGES_FAILURE:
      return update(state, {
        mediaOrderSuggestedChanges: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_MEDIA_ORDER_SUGGESTED_CHANGES:
      return update(state, {
        mediaOrderSuggestedChanges: { $set: initialState.mediaOrderSuggestedChanges }
      })

    // Apply media order suggested changes
    case APPLY_MEDIA_ORDER_SUGGESTED_CHANGES:
      return update(state, {
        applyMediaOrderSuggestedChanges: { $merge: { isLoading: true } }
      })
    case APPLY_MEDIA_ORDER_SUGGESTED_CHANGES_SUCCESS:
      return update(state, {
        applyMediaOrderSuggestedChanges: {
          $merge: {
            isLoading: false,
            wasCreated: true
          }
        }
      })
    case APPLY_MEDIA_ORDER_SUGGESTED_CHANGES_FAILURE:
      return update(state, {
        applyMediaOrderSuggestedChanges: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_APPLY_MEDIA_ORDER_SUGGESTED_CHANGES:
      return update(state, {
        applyMediaOrderSuggestedChanges: { $set: initialState.applyMediaOrderSuggestedChanges }
      })

    // Get optimisation history
    case GET_OPTIMISATION_HISTORY:
      return update(state, {
        optimisationHistory: { $merge: { isLoading: true } }
      })
    case GET_OPTIMISATION_HISTORY_SUCCESS:
      return update(state, {
        optimisationHistory: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data.results,
            paging: {
              next: action.data.next
            }
          }
        }
      })
    case GET_OPTIMISATION_HISTORY_FAILURE:
      return update(state, {
        optimisationHistory: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_OPTIMISATION_HISTORY:
      return update(state, {
        optimisationHistory: { $set: initialState.optimisationHistory }
      })

    default:
      return state
  }
}
