import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import Form from '../../../../../components/Form'
import CampaignFormContent from './CampaignFormContent'
import DrawerHeadline from '../../../../../components/Drawer/DrawerHeadline'
import RoutesMissingWarning from '../../../../Multiplatform/RoutesMissingWarning'

import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'
import { useMediaOrderRemainingBudgetCalculation } from '../../../../Multiplatform/MediaOrderForms/UploadAdCreative/useMediaOrderRemainingBudgetCalculation'
import useCampaignFormSuccessSubmit from '../../../../Multiplatform/MediaOrderForms/UploadAdCreative/useCampaignFormSuccessSubmit'
import useAccessibleCampaignsRoutes from '../../../../Multiplatform/RoutesMissingWarning/useAccessibleCampaignsRoutes'

import { formatOptionsList } from '../../../../../features/formatters'

import { setMediaOrderUploadCreative } from '../../../../../modules/actions/mediaOrders'
import { clearCampaigns, clearCreateCampaign, createCampaign } from '../../../../../modules/actions/campaigns'
import {
  campaignCreateErrorSelector,
  campaignCreateIsLoadingSelector
} from '../../../../../modules/selectors/campaigns'
import { mediaOrderSelector } from '../../../../../modules/selectors/mediaOrders'
import { selectedAdAccountsSelector, selectedControllerDataSelector } from '../../../../../modules/selectors/app'
import { combinedCampaignsSelector } from '../../../../../modules/selectors/combinedData'

import {
  CAMPAIGN,
  CAMPAIGN_OPTION,
  CAMPAIGN_OPTION_EXISTING_CAMPAIGN
} from '../../../../Multiplatform/UploadAdCreative/fields'
import { getInitialValues, getValidationSchema, transformValuesToBE } from './fields'

import { TIKTOK_PLATFORM, TIKTOK_PROVIDER_PLATFORM } from '../../../../../constants/selectLists/platformList'

import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

const CampaignForm = ({ onSuccessSubmit }) => {
  const drawerFormClasses = useDrawerFormStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [manualSuccessSubmit, setManualSuccessSubmit] = useState(false)

  const mediaOrder = useSelector(mediaOrderSelector)
  const combinedCampaigns = useSelector(combinedCampaignsSelector)
  const controller = useSelector(selectedControllerDataSelector)
  const { [TIKTOK_PLATFORM]: adAccountData } = useSelector(selectedAdAccountsSelector)

  const { currency_symbol: currencySymbol, external_id: tikTokAdAccountId } = adAccountData

  const { campaigns = [] } = combinedCampaigns

  const remainingBudget = useMediaOrderRemainingBudgetCalculation()

  const tikTokCampaigns = useMemo(
    () => campaigns.filter(campaign => campaign.provider === TIKTOK_PROVIDER_PLATFORM),
    [campaigns]
  )

  const isCampaignsExist = !!tikTokCampaigns?.length

  const allowedRoute = useAccessibleCampaignsRoutes(TIKTOK_PLATFORM)
  const hasAccessibleRoutes = Object.values(allowedRoute).some(route => route)
  const hasRoutes = isCampaignsExist || hasAccessibleRoutes

  const formattedCampaigns = formatOptionsList({
    list: tikTokCampaigns,
    valueName: 'id',
    labelName: 'name'
  })

  const onSubmit = useCallback(
    values => {
      // if existing campaign is selected, find full data and set to redux state + fetch line items
      if (values[CAMPAIGN_OPTION] === CAMPAIGN_OPTION_EXISTING_CAMPAIGN) {
        const selectedCampaignFullData = formattedCampaigns.find(campaign => values[CAMPAIGN] === campaign.id)

        dispatch(setMediaOrderUploadCreative({ campaign: selectedCampaignFullData }))
        setManualSuccessSubmit(true)
      } else {
        const campaignData = transformValuesToBE(values, tikTokAdAccountId, mediaOrder)

        dispatch(
          createCampaign({ campaignData }, TIKTOK_PLATFORM, {
            pushToCombinedCampaignsList: true
          })
        )
      }
    },
    [dispatch, formattedCampaigns, mediaOrder, tikTokAdAccountId]
  )

  const formik = useFormik({
    initialValues: getInitialValues({ isCampaignsExist }),
    validationSchema: getValidationSchema({ remainingBudget, currencySymbol }),
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)
  const { [CAMPAIGN_OPTION]: campaignOption } = purifiedFormik.values

  const successSubmit = useCampaignFormSuccessSubmit({
    campaignOption,
    platform: TIKTOK_PLATFORM,
    adAccountId: tikTokAdAccountId
  })

  const clearHandler = useCallback(() => {
    dispatch(clearCampaigns())
    dispatch(clearCreateCampaign())
  }, [dispatch])

  return hasRoutes ? (
    <div className={drawerFormClasses.formContainer}>
      <DrawerHeadline
        activeStepNumber={0}
        customStepsLength={4}
        title="Choose your TikTok campaign type"
        description={t('learnMoreAboutCampaigns', { controllerName: controller?.name })}
      />
      <Form
        formName="campaignFormContent"
        formik={purifiedFormik}
        submitText="Save and continue"
        successSubmit={successSubmit || manualSuccessSubmit}
        onSuccessSubmit={onSuccessSubmit}
        errorSelector={campaignCreateErrorSelector}
        isLoadingSelector={campaignCreateIsLoadingSelector}
        clearHandler={clearHandler}
      >
        <CampaignFormContent
          formik={purifiedFormik}
          remainingBudget={remainingBudget}
          formattedCampaigns={formattedCampaigns}
        />
      </Form>
    </div>
  ) : (
    <RoutesMissingWarning />
  )
}

export default CampaignForm
