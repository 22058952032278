import { BUDGET_DAILY, BUDGET_LIFETIME } from '../CampaignForms/fields'

export const budgetOptions = [
  {
    label: 'Lifetime',
    value: BUDGET_LIFETIME
  },
  {
    label: 'Daily',
    value: BUDGET_DAILY
  }
]
