import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useEditAssetGroupSubmit } from '../../hooks'

import { getLandscapeImageData } from '../HeadlinesSection/helpers'
import { isAllImageAssetsReady } from '../../helpers'

import EditForm from '../../../../../../../features/components/Forms/EditForm'
import MultipleGoogleMediaUpload from '../../../../ReusableFields/MultipleGoogleMediaUpload'

import { selectedAdAccountIdSelector } from '../../../../../../../modules/selectors/app'

import { googleAssetFieldType } from '../../../../../../../constants/ads'
import { getInitialValues } from './fields'
import { LANDSCAPE_IMAGE, landscapeImageHeight, landscapeImageWidth } from '../../../../fields'
import { getLandscapeImageValidationSchema } from '../../../../validations'
import { validationSchema } from './validation'

const LandscapeImageSectionForm = ({ editItemData, isInternalAssetGroup, ...formProps }) => {
  const { assets, id: assetGroupId, account } = editItemData

  const selectedAdAccount = useSelector(selectedAdAccountIdSelector)
  const adAccount = account || selectedAdAccount

  const originalLandscapeImageAssets = useMemo(() => getLandscapeImageData(assets), [assets])

  const initialValues = useMemo(() => getInitialValues(originalLandscapeImageAssets), [originalLandscapeImageAssets])
  const landscapeImageValidationSchema = useMemo(() => getLandscapeImageValidationSchema(), [])

  const purifiedFormik = useEditAssetGroupSubmit({
    originalSectionAssets: originalLandscapeImageAssets,
    fieldType: googleAssetFieldType.MARKETING_IMAGE,
    sectionValueKey: LANDSCAPE_IMAGE,
    isInternalAssetGroup,
    assetGroupId,
    initialValues,
    validationSchema,
    adAccount
  })

  const { values } = purifiedFormik

  const isImagesReady = isAllImageAssetsReady(values, LANDSCAPE_IMAGE)

  const isEmptyList = !values[LANDSCAPE_IMAGE].length

  return (
    <EditForm formik={purifiedFormik} initialValues={initialValues} submitBtnDisabled={!isImagesReady} {...formProps}>
      <MultipleGoogleMediaUpload
        formik={purifiedFormik}
        FileItemPath={LANDSCAPE_IMAGE}
        validationSchema={landscapeImageValidationSchema}
        addAnotherButtonText={isEmptyList ? 'Add landscape image' : 'Add another landscape image'}
        width={landscapeImageWidth}
        height={landscapeImageHeight}
        adAccountId={adAccount}
      />
    </EditForm>
  )
}

export default LandscapeImageSectionForm
