import * as Yup from 'yup'

import { BUDGET_LIFETIME } from '../../fields'
import { getBudgetValidation } from '../../../../../../../../forms/Multiplatform/UploadAdCreative/fields'
import {
  END_DATE,
  endDateValidation,
  START_DATE,
  startDateValidation
} from '../../../../../../../../forms/ReusableFormFields/StartEndDateRangeFields/fields'
import { customAudiencesValidation } from '../../../../../../../../forms/ReusableFormFields/CustomAudiencesFields/fields'

export const getValidationSchema = ({ remainingBudget, currencySymbol }) => {
  return Yup.object().shape({
    [BUDGET_LIFETIME]: getBudgetValidation({ remainingBudget, currencySymbol }),
    [START_DATE]: startDateValidation,
    [END_DATE]: endDateValidation,
    ...customAudiencesValidation
  })
}
