import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

export const getAdsSelector = createSelector(
  ({ ads }) => ads.ads.results,
  // filter only real ads, remove totals item
  results => results.filter(item => item.id)
)

export const adsTotalsSelector = createSelector(
  ({ ads }) => ads.ads.results,
  results => results.filter(item => !item.name && !item.id)[0]
)

export const adsLoadingSelector = createSelector(
  ({ ads }) => ads.ads.isLoading,
  isLoading => isLoading
)

export const adsErrorSelector = createSelector(
  ({ ads }) => ads.ads.error,
  error => error
)

export const adsWasLoadedSelector = createSelector(
  ({ ads }) => ads.ads.wasLoaded,
  wasLoaded => wasLoaded
)

export const adsAfterSelector = createSelector(
  ({ ads }) => ads.ads.paging?.after,
  after => after
)

export const adUpdateSelector = createSelector(
  ({ ads }) => ads.updatedAd,
  updatedAd => updatedAd
)

// CREATE
export const adCreateDataSelector = createSelector(
  ({ ads }) => ads.createdAd.data,
  data => data
)

export const adCreateLoadingSelector = createSelector(
  ({ ads }) => ads.createdAd.isLoading,
  isLoading => isLoading
)

export const adWasCreatedSelector = createSelector(
  ({ ads }) => ads.createdAd.wasCreated,
  wasCreated => wasCreated
)

export const adCreateErrorSelector = createSelector(
  ({ ads }) => ads.createdAd.error,
  error => error
)

export const createAdsBatchSelector = createSelector(
  ({ ads }) => ads.createAdsBatch.data,
  data => data
)

export const createAdsBatchIsLoadingSelector = createSelector(
  ({ ads }) => ads.createAdsBatch.isLoading,
  isLoading => isLoading
)

export const createAdsBatchErrorSelector = createSelector(
  ({ ads }) => ads.createAdsBatch.error,
  error => error
)

export const createAdsBatchWasCreatedSelector = createSelector(
  ({ ads }) => ads.createAdsBatch.wasCreated,
  wasCreated => wasCreated
)

// VALIDATE CREATE AD
export const validatedDomainsSelector = createSelector(
  ({ ads }) => ads.validatedAd.domains,
  domains => domains
)

// UPDATE
export const adUpdateErrorSelector = createSelector(
  ({ ads }) => ads.updatedAd.error,
  error => error
)

export const adUpdateIsLoadingSelector = createSelector(
  ({ ads }) => ads.updatedAd.isLoading,
  isLoading => isLoading
)

export const adWasUpdatedSelector = createSelector(
  ({ ads }) => ads.updatedAd.wasUpdated,
  wasUpdated => wasUpdated
)

export const adDeleteIsLoadingSelector = createSelector(
  ({ ads }) => ads.deletedAd.isLoading,
  isLoading => isLoading
)

export const adDeleteErrorSelector = createSelector(
  ({ ads }) => ads.deletedAd.error,
  error => error
)

export const adDeleteItemSelector = createSelector(
  ({ ads }) => ads.deletedAd,
  deletedAd => deletedAd
)

export const adLoadingSelector = createSelector(
  ({ ads }) => ads.ad.isLoading,
  isLoading => isLoading
)

export const adWasLoadedSelector = createSelector(
  ({ ads }) => ads.ad.wasLoaded,
  wasLoaded => wasLoaded
)

export const adSelector = createSelector(
  ({ ads }) => ads.ad.data,
  data => data
)

export const selectedAdForCopySelector = createSelector(
  ({ ads }) => ads.selectedAdForCopy,
  selectedAdForCopy => selectedAdForCopy
)

export const copyAdIsLoadingSelector = createSelector(
  ({ ads }) => ads.copyAd.isLoading,
  copyAd => copyAd
)
export const copyAdErrorSelector = createSelector(
  ({ ads }) => ads.copyAd.error,
  error => error
)
export const copyAdWasCreatedSelector = createSelector(
  ({ ads }) => ads.copyAd.wasCreated,
  wasCreated => wasCreated
)
export const copyAdDataSelector = createSelector(
  ({ ads }) => ads.copyAd.data,
  data => data
)

// Approve internal ad
export const approveInternalAdSelector = createSelector(
  ({ ads }) => ads.approveInternalAd,
  approveInternalAd => approveInternalAd
)

export const approveInternalAdIsLoadingSelector = createSelector(
  ({ ads }) => ads.approveInternalAd.isLoading,
  isLoading => isLoading
)

export const approveInternalAdErrorSelector = createSelector(
  ({ ads }) => ads.approveInternalAd.error,
  error => error
)

export const approveInternalAdWasApprovedSelector = createSelector(
  ({ ads }) => ads.approveInternalAd.wasUpdated,
  wasUpdated => wasUpdated
)

// Disapprove internal ad
export const disapproveInternalAdSelector = createSelector(
  ({ ads }) => ads.disapproveInternalAd,
  disapproveInternalAd => disapproveInternalAd
)

export const disapproveInternalAdIsLoadingSelector = createSelector(
  ({ ads }) => ads.disapproveInternalAd.isLoading,
  isLoading => isLoading
)

export const disapproveInternalAdErrorSelector = createSelector(
  ({ ads }) => ads.disapproveInternalAd.error,
  error => error
)

export const disapproveInternalAdWasDisapprovedSelector = createSelector(
  ({ ads }) => ads.disapproveInternalAd.wasUpdated,
  wasUpdated => wasUpdated
)

// Delete internal ad
export const deleteInternalAdSelector = createSelector(
  ({ ads }) => ads.deleteInternalAd,
  deleteInternalAd => deleteInternalAd
)

export const deleteInternalAdIsLoadingSelector = createSelector(
  ({ ads }) => ads.deleteInternalAd.isLoading,
  isLoading => isLoading
)

export const deleteInternalAdErrorSelector = createSelector(
  ({ ads }) => ads.deleteInternalAd.error,
  error => error
)

export const deleteInternalAdWasDeletedSelector = createSelector(
  ({ ads }) => ads.deleteInternalAd.wasDeleted,
  wasDeleted => wasDeleted
)

// modified adData used for AdPreview to represent changed from EditForm
export const adModifiedDataSelector = createSelector(
  ({ ads }) => ads.adPreview.adModifiedData,
  adModifiedData => adModifiedData
)

// adCarouselSelectedIndexes used for AdPreview and Carousel form to preselect active carousel item
export const adCarouselSelectedIndexesSelector = createSelector(
  ({ ads }) => ads.adPreview.adCarouselSelectedIndexes,
  adCarouselSelectedIndexes => adCarouselSelectedIndexes
)

export const adPreviewIsSoundMutedSelector = createSelector(
  ({ ads }) => ads.adPreview.isSoundMuted,
  isSoundMuted => isSoundMuted
)

export const internalAdsItemsCountSelector = createSelector(
  ({ ads }) => ads.internalAdsItemsCount.results,
  results => results
)

export const internalAdsItemsCountIsLoadingSelector = createSelector(
  ({ ads }) => ads.internalAdsItemsCount.isLoading,
  isLoading => isLoading
)

export const internalAdsItemsCountErrorSelector = createSelector(
  ({ ads }) => ads.internalAdsItemsCount.error,
  error => error
)

export const internalAdsItemsCountWasLoadedSelector = createSelector(
  ({ ads }) => ads.internalAdsItemsCount.wasLoaded,
  wasLoaded => wasLoaded
)
