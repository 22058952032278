import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DropdownSkeleton from './DropdownSkeleton'

import { clearGetBookedMedia, getBookedMedia } from '../../../../modules/actions/mediaOrdersBookings'
import { getBookedMediaWasLoadedSelector } from '../../../../modules/selectors/mediaOrdersBookings'

const DropdownContentWrapper = ({ children, bookedMediaId }) => {
  const dispatch = useDispatch()

  const bookedMediaWasLoaded = useSelector(getBookedMediaWasLoadedSelector)

  useEffect(() => {
    dispatch(getBookedMedia({ id: bookedMediaId }))
  }, [dispatch, bookedMediaId])

  useEffect(() => {
    return () => {
      dispatch(clearGetBookedMedia())
    }
  }, [dispatch])

  if (!bookedMediaWasLoaded) {
    return <DropdownSkeleton />
  }

  return children
}

export default DropdownContentWrapper
