import React, { useCallback, useState } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import Form from '../../../../../../components/Form'
import LineItemGoogleAudienceFields from './LineItemGoogleAudienceFields'
import DrawerHeadline from '../../../../../../components/Drawer/DrawerHeadline'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import {
  createLineItemCriterions,
  clearCreateLineItemCriterions,
  clearLineItemCriterions
} from '../../../../../../modules/actions/lineItems'

import {
  createLineItemCriterionsIsLoadingSelector,
  createLineItemCriterionsErrorSelector,
  createLineItemCriterionsWasCreatedSelector
} from '../../../../../../modules/selectors/lineItems'
import { selectedControllerDataSelector, selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { OPTION_NO } from '../../../../../../constants/forms'
import { AUDIENCE, AUDIENCE_OPTION, initialValues, validationSchema } from './fields'

import useDrawerFormStyles from '../../../../../../styles/common/drawerForms'

const LineItemGoogleAudienceForm = ({ lineItemCreatedData, onSuccessSubmit }) => {
  const dispatch = useDispatch()

  const drawerFormClasses = useDrawerFormStyles()

  const [successSubmit, setSuccessSubmit] = useState(false)

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const selectedSelfAccountController = useSelector(selectedControllerDataSelector)
  const lineItemAudienceWasCreated = useSelector(createLineItemCriterionsWasCreatedSelector)

  const controllerName = selectedSelfAccountController?.name

  const onSubmit = useCallback(
    values => {
      if (values[AUDIENCE_OPTION] === OPTION_NO) {
        // if no audiences option is selected > don't send request
        setSuccessSubmit(true)
      } else {
        const { id: assetGroupInternalId } = lineItemCreatedData
        const audienceCriterions = values[AUDIENCE].map(({ value }) => ({
          account: selectedAdAccountId,
          ad_group: assetGroupInternalId,
          user_list: {
            user_list: value
          }
        }))

        dispatch(
          createLineItemCriterions({
            account: selectedAdAccountId,
            operations: audienceCriterions
          })
        )
      }
    },
    [dispatch, selectedAdAccountId, lineItemCreatedData]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const clearCreateAssetGroupSignalHandler = useCallback(() => {
    dispatch(clearCreateLineItemCriterions())
    dispatch(clearLineItemCriterions())
  }, [dispatch])

  return (
    <div className={drawerFormClasses.formContainer}>
      <DrawerHeadline title={`Do you want to target${controllerName ? ` ${controllerName}` : ''} audiences?`} />
      <Form
        formName="asset_group_audiences"
        formik={purifiedFormik}
        successSubmit={successSubmit || lineItemAudienceWasCreated}
        onSuccessSubmit={onSuccessSubmit}
        clearHandler={clearCreateAssetGroupSignalHandler}
        errorSelector={createLineItemCriterionsErrorSelector}
        isLoadingSelector={createLineItemCriterionsIsLoadingSelector}
      >
        <LineItemGoogleAudienceFields formik={purifiedFormik} />
      </Form>
    </div>
  )
}

export default LineItemGoogleAudienceForm
