import React from 'react'
import { useTranslation } from 'react-i18next'

import AdSetEndDateWarning from '../../AdSetEndDateWarning'
import Step from '../../../../../../features/components/Forms/StepForm/Step'

const WarningStep = ({ adAccountId }) => {
  const { t } = useTranslation()

  return (
    <Step stepTitle={t('Sorry, your ad set end date is in the past')}>
      <AdSetEndDateWarning adAccountId={adAccountId} />
    </Step>
  )
}

export default WarningStep
