// use selected audiences for line item name, separate with comas
export const createNameFromCustomAudiences = customAudiences => {
  const audiencesNames = customAudiences.map(audience => audience.label)

  if (audiencesNames.length) {
    let finalName = ''

    const isMoreThanThreeAudiences = audiencesNames.length > 3

    // use only first three audiences, if there are more provided
    const audiencesToCreateName = isMoreThanThreeAudiences ? audiencesNames.slice(0, 3) : audiencesNames

    for (let i = 0; i < audiencesToCreateName.length; i++) {
      if (i > 0) {
        const newFinalName = finalName + `, ${audiencesToCreateName[i]}`

        // limit final name to 400 characters, break loop if length exceeds
        if (newFinalName.length > 400) break

        finalName = newFinalName
      } else {
        finalName += audiencesToCreateName[i]
      }
    }

    return isMoreThanThreeAudiences ? `${finalName}...` : finalName
  } else {
    return 'Broad targeting'
  }
}
