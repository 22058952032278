import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import SuccessModal from '../../../../features/components/Modals/SuccessModal'
import Button from '../../../../components/Button'

import { redirectTo } from '../../../../helpers/url'

import { openForm } from '../../../../modules/actions/forms'

import { MODAL_TYPE } from '../../../../constants/other'
import { ROUTE_PARAMS, ROUTES } from '../../../../constants/routes'
import { DV_360_PLATFORM } from '../../../../constants/selectLists/platformList'
import { UPLOAD_DV360_AD_CREATIVE } from '../../../../constants/forms'

const UploadDv360AdCreativeModal = ({ isInternalAdCreation, createdCreativeData, setCreatedCreativeData }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const openUploadCreativeFormHandler = useCallback(() => {
    dispatch(openForm({ formName: UPLOAD_DV360_AD_CREATIVE }))
  }, [dispatch])

  const onModalCloseHandler = useCallback(() => {
    setCreatedCreativeData({})
  }, [setCreatedCreativeData])

  const onViewAd = useCallback(() => {
    redirectTo(ROUTES.lineItemsSummary, {
      [ROUTE_PARAMS.platform]: DV_360_PLATFORM,
      [ROUTE_PARAMS.adAccount]: createdCreativeData.adAccountId,
      [ROUTE_PARAMS.campaignId]: createdCreativeData.campaignId,
      [ROUTE_PARAMS.lineItemId]: createdCreativeData.lineItemId
    })
  }, [createdCreativeData])

  return (
    <>
      <SuccessModal
        successButtonText="Upload another ad"
        successButtonAction={openUploadCreativeFormHandler}
        modalType={MODAL_TYPE.mediaOrderDv360CreativeUploaded}
        onAfterClose={onModalCloseHandler}
        ButtonLeft={<Button onClick={onViewAd}>{t('View Ad')}</Button>}
        showCloseButton
      >
        <p>
          {t(
            isInternalAdCreation
              ? 'Your ad has been uploaded successfully and is pending review.'
              : 'Your ad has been uploaded successfully.'
          )}
        </p>
      </SuccessModal>
    </>
  )
}

export default UploadDv360AdCreativeModal
