import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'

import Form from '../../../../components/Form'
import UserPhoneField from '../../../Settings/EditProfile/UserProfileContent/UserPhoneField'

import { clearUpdateCurrentUserProfile, updateCurrentUserProfile } from '../../../../modules/actions/app'
import {
  getCurrentUserSelector,
  updateCurrentUserErrorSelector,
  updateCurrentUserLoadingSelector,
  updateCurrentUserWasUpdatedSelector
} from '../../../../modules/selectors/app'

import {
  formatPhoneNumber,
  getInitialPhoneValues,
  validatePhoneNumber
} from '../../../Settings/EditProfile/UserProfileContent/UserPhoneField/fields'
import { PHONE_NUMBER } from '../../../Settings/EditProfile/UserProfileContent/fields'
import { SETUP_PHONE_NUMBER_FORM } from '../../../../constants/forms'

const SetupPhoneNumberForm = ({ onSuccessSubmit, onPhoneApprove }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // to re-send verification, we need to re-submit values with the same update action
  const [submittedUserPhoneValues, setSubmittedUserPhoneValues] = useState(null)

  const user = useSelector(getCurrentUserSelector)
  const userWasUpdated = useSelector(updateCurrentUserWasUpdatedSelector)
  const { id: userId } = user

  const initialPhoneNumber = user[PHONE_NUMBER]

  const onSubmit = values => {
    const formattedNewPhoneNumber = formatPhoneNumber(values[PHONE_NUMBER])

    const isPhoneNumberChanged = initialPhoneNumber !== formattedNewPhoneNumber

    if (isPhoneNumberChanged) {
      // runs verification process if phone was added or changed
      dispatch(
        updateCurrentUserProfile({
          userId: userId,
          userData: { phone_number: formattedNewPhoneNumber }
        })
      )
      setSubmittedUserPhoneValues({ phone_number: formattedNewPhoneNumber })
    } else {
      // don't verify phone number if it wasn't changed
      onPhoneApprove()
    }
  }

  const formik = useFormik({
    initialValues: getInitialPhoneValues(user),
    validationSchema: Yup.object({
      [PHONE_NUMBER]: Yup.string().test({
        message: 'Please enter a valid phone number',
        // allow empty value or min 6 chars
        test: value => validatePhoneNumber(value)
      })
    }),
    onSubmit
  })

  const formattedNewPhoneNumber = formatPhoneNumber(formik.values[PHONE_NUMBER])
  const isPhoneNumberChanged = initialPhoneNumber !== formattedNewPhoneNumber

  const clearHandler = () => {
    dispatch(clearUpdateCurrentUserProfile())
  }

  return (
    <>
      <p>
        {!!initialPhoneNumber
          ? t('Approve or enter new phone number and receive a verification code.')
          : t('Enter your phone number and receive a verification code.')}
      </p>

      <Form
        formik={formik}
        formName={SETUP_PHONE_NUMBER_FORM}
        submitText={!!initialPhoneNumber && !isPhoneNumberChanged ? t('Approve') : t('Send code')}
        successSubmit={userWasUpdated}
        errorSelector={updateCurrentUserErrorSelector}
        isLoadingSelector={updateCurrentUserLoadingSelector}
        onSuccessSubmit={() => onSuccessSubmit(submittedUserPhoneValues)}
        clearHandler={clearHandler}
      >
        <UserPhoneField formik={formik} />
      </Form>
    </>
  )
}

SetupPhoneNumberForm.propTypes = {
  onSuccessSubmit: PropTypes.func,
  onPhoneApprove: PropTypes.func
}

export default SetupPhoneNumberForm
