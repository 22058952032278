import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { endOfYear, startOfYear, subYears } from 'date-fns'

import { getBrandRevenueReport } from '../../../../../../modules/actions/mediaOrdersBookings'
import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'

import { formatDateToBE } from '../../../../../../constants/dates'
import { selfAccountCategoriesSelector } from '../../../../../../modules/selectors/selfAccounts'

export const useFetchBrandRevenueReport = () => {
  const dispatch = useDispatch()

  const selfAccountControllerId = useSelector(selectedControllerIdSelector)

  return useCallback(
    filterRequestParams => {
      const now = new Date()
      // First day of the first month of last year
      const startDate = startOfYear(subYears(now, 1))
      // Last day of the last month of current year
      const endDate = endOfYear(now)

      dispatch(
        getBrandRevenueReport(
          {
            controller: selfAccountControllerId,
            ...filterRequestParams,
            date_start_after: formatDateToBE(startDate),
            date_start_before: formatDateToBE(endDate)
          },
          { shouldClearExistingState: true }
        )
      )
    },
    [dispatch, selfAccountControllerId]
  )
}

export const useSelectedSelfAccountCategoryLabel = selectedSelfAccountCategory => {
  const selfAccountCategories = useSelector(selfAccountCategoriesSelector)

  return useMemo(() => {
    const selectedCategory = selfAccountCategories.find(
      category => String(category.id) === String(selectedSelfAccountCategory)
    )
    return selectedCategory?.name || ''
  }, [selectedSelfAccountCategory, selfAccountCategories])
}
