import React from 'react'
import { useTranslation } from 'react-i18next'

import RadioBlock from '../../../components/Form/RadioBlock'

import { GENDER_OPTION, GENDER_OPTION_ALL, GENDER_OPTION_FEMALES, GENDER_OPTION_MALES } from './fields'

const GenderFields = ({ values, setFieldValue }) => {
  const { t } = useTranslation()

  return (
    <>
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_gender_all"
        name={GENDER_OPTION}
        value={GENDER_OPTION_ALL}
        selectedValue={values[GENDER_OPTION]}
        label={t('All')}
      />
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_gender_only_males"
        name={GENDER_OPTION}
        value={GENDER_OPTION_MALES}
        selectedValue={values[GENDER_OPTION]}
        label={t('Only males')}
      />
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_gender_only_females"
        name={GENDER_OPTION}
        value={GENDER_OPTION_FEMALES}
        selectedValue={values[GENDER_OPTION]}
        label={t('Only females')}
      />
    </>
  )
}

export default GenderFields
