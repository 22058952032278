import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import useGetPackagePrice from '../../../hooks/useGetPackagePrice'
import MediaPackageBookNowButton from '../../../MediaPackageBookNowButton'
import CardContainer from '../../../../../../features/components/reusableCardComponents/CardContainer'
import CardHeader from '../../../../../../features/components/reusableCardComponents/CardHeader'
import CardContent from '../../../../../../features/components/reusableCardComponents/CardContent'
import CardFooter from '../../../../../../features/components/reusableCardComponents/CardFooter'

import { useIsMediaPackageDisabled } from './hooks'

import { getMediaPackage } from '../../../../../../modules/actions/mediaPackages'
import { openForm, setFormMinimize } from '../../../../../../modules/actions/forms'

import { MEDIA_ORDER_CREATE } from '../../../../../../constants/forms'

const MediaPackageCard = ({ mediaPackage }) => {
  const dispatch = useDispatch()

  const { name, subtext, image, prices, fixed_price: isFixedPrice, category = {} } = mediaPackage
  const { name: categoryName } = category
  const isDisabled = useIsMediaPackageDisabled({ mediaPackage })

  const packagePrice = useGetPackagePrice({ prices })

  const openCreateMediaOrderForm = useCallback(() => {
    // fetch added media package to add full media package object data to selected media packages list
    // adding of the media package to the list is done in the MediaOrderCreate
    dispatch(
      getMediaPackage(mediaPackage?.id, {
        ...(mediaPackage?.fixed_price && { return_inventory: mediaPackage?.fixed_price })
      })
    )
    dispatch(openForm({ formName: MEDIA_ORDER_CREATE }))
    // if form drawer was minimized to select additional package - reset minimization and show drawer again
    dispatch(setFormMinimize(false))
  }, [dispatch, mediaPackage])

  return (
    <CardContainer isDisabled={isDisabled} onClick={openCreateMediaOrderForm}>
      <CardHeader itemCategory={categoryName} name={name} subtext={subtext} />
      <CardContent image={image} altText="package" />
      <CardFooter
        price={packagePrice}
        cardButton={<MediaPackageBookNowButton mediaPackage={mediaPackage} />}
        isFixedPrice={isFixedPrice}
      />
    </CardContainer>
  )
}

MediaPackageCard.propTypes = {
  mediaPackage: PropTypes.object.isRequired
}

export default MediaPackageCard
