import React from 'react'

import Icon from '../../../../../../../../../../../components/Icon'
import { ReactComponent as CheckedCircle } from '../../../../../../../../../../../assets/icons/checked-circle.svg'

import {
  MEDIA_HEIGHT,
  MEDIA_WIDTH
} from '../../../../../../../../../../../forms/ReusableFormFields/AdFileUpload/fields'
import useStyles from './styles'

const UploadedImageIcon = ({ uploadedImages, width, height }) => {
  const classes = useStyles()

  const wasImageLoaded = uploadedImages.find(image => image[MEDIA_WIDTH] === width && image[MEDIA_HEIGHT] === height)

  return wasImageLoaded ? (
    <Icon className={classes.icon}>
      <CheckedCircle />
    </Icon>
  ) : (
    <span className={classes.emptyIcon} />
  )
}

export default UploadedImageIcon
