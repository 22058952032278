import React, { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import StepForm from '../../../../../../../features/components/Forms/StepForm'
import ProductSelectionStep from '../../Steps/ProductAdRoute/ProductSelectionStep'
import ProductAdStep from '../../Steps/ProductAdRoute/ProductAdStep'

import { usePurifiedFormik } from '../../../../../../../hooks/formHooks/usePurifiedFormik'
import useLineItemDetails from '../../hooks/useLineItemDetails'
import useCampaignDetails from '../../hooks/useCampaignDetails'
import useIsInternalAdCreation from '../../../../../../../features/hooks/useIsInternalAdCreation'
import usePreselectPixel from '../../hooks/usePreselectPixel'
import useFormattedFacebookPages from '../../hooks/useFormattedFacebookPages'

import { createAd } from '../../../../../../../modules/actions/ads'
import {
  selectedAdAccountIdSelector,
  selectedSelfAccountDataSelector
} from '../../../../../../../modules/selectors/app'
import { accessibleFacebookPagesSelector } from '../../../../../../../modules/selectors/socialAccounts'

import { transformValuesToBE } from '../../fields'
import { FACEBOOK_PLATFORM } from '../../../../../../../constants/selectLists/platformList'
import { PIXEL_CHOICE } from '../../../../fields'

import { getInitialValues } from './initialValues'
import { validationSchema } from './validation'

const ProductAdRoute = ({
  FormRouteSelector,
  eventSourceStep,
  adAccountId,
  isQuarantineAd,
  warningSteps,
  ...formProps
}) => {
  const dispatch = useDispatch()

  const facebookPages = useSelector(accessibleFacebookPagesSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const { controller: selfAccountController } = useSelector(selectedSelfAccountDataSelector)

  const { lineItemId, showAsStory, placementPositions } = useLineItemDetails()
  const { campaignId } = useCampaignDetails()

  const isInternalAdCreation = useIsInternalAdCreation()
  const formattedFacebookPages = useFormattedFacebookPages(facebookPages)

  const providedAdAccountId = adAccountId || selectedAdAccountId

  const onSubmit = useCallback(
    values => {
      dispatch(
        createAd(
          transformValuesToBE({
            values,
            adAccountId: providedAdAccountId,
            adSetId: lineItemId,
            campaignId,
            isInternalAdCreation,
            selfAccountController
          }),
          FACEBOOK_PLATFORM
        )
      )
    },
    [dispatch, campaignId, isInternalAdCreation, lineItemId, providedAdAccountId, selfAccountController]
  )

  const initialValues = useMemo(() => {
    return getInitialValues({ facebookPages: formattedFacebookPages })
  }, [formattedFacebookPages])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const { values, setFieldValue } = purifiedFormik

  usePreselectPixel({ selectedPixel: values[PIXEL_CHOICE], setFieldValue })

  const steps = useMemo(
    () => [
      ...warningSteps,
      {
        component: FormRouteSelector,
        show: !showAsStory
      },
      {
        component: <ProductSelectionStep initialValues={initialValues} />,
        show: true
      },
      {
        component: <ProductAdStep adAccountId={providedAdAccountId} placementPositions={placementPositions} />,
        show: true
      }
    ],
    [FormRouteSelector, showAsStory, initialValues, providedAdAccountId, placementPositions, warningSteps]
  )

  return <StepForm steps={steps} formik={purifiedFormik} {...formProps} />
}

export default ProductAdRoute
