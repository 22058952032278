import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import DiscountPresetFormContent from '../../DiscountPresetFormContent'
import Form from '../../../../../../components/Form'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import { transformValuesToBE } from '../../formatters'

import { clearUpdateDiscountPreset, updateDiscountPreset } from '../../../../../../modules/actions/discounts'
import {
  updateDiscountPresetErrorSelector,
  updateDiscountPresetIsLoadingSelector,
  updateDiscountPresetWasUpdatedSelector
} from '../../../../../../modules/selectors/discounts'

import { DISCOUNT_PRESET_EDIT } from '../../../../../../constants/forms'

import { getInitialValues } from './fields'
import { validationSchema } from '../../validation'

function DiscountPresetEditForm({ editDiscountPresetData }) {
  const dispatch = useDispatch()

  const { id } = editDiscountPresetData

  const discountPresetWasUpdated = useSelector(updateDiscountPresetWasUpdatedSelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values)

      dispatch(updateDiscountPreset(transformedData, id))
    },
    [dispatch, id]
  )

  const initialValues = useMemo(() => getInitialValues(editDiscountPresetData), [editDiscountPresetData])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const clearHandler = useCallback(() => {
    dispatch(clearUpdateDiscountPreset())
  }, [dispatch])

  return (
    <Form
      formik={purifiedFormik}
      formName={DISCOUNT_PRESET_EDIT}
      successSubmit={discountPresetWasUpdated}
      errorSelector={updateDiscountPresetErrorSelector}
      isLoadingSelector={updateDiscountPresetIsLoadingSelector}
      clearHandler={clearHandler}
    >
      <DiscountPresetFormContent formik={purifiedFormik} />
    </Form>
  )
}

export default DiscountPresetEditForm
