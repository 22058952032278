import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Can from '../../../../features/components/Can'
import DeleteItemModal from '../../../../features/components/Modals/DeleteModal/DeleteItemModal'
import AdDuplicationModal from '../../../../features/components/Modals/AdDuplicationModal'
import DigitalTable from '../../../../features/components/DigitalTable'

import usePermissions from '../../../../hooks/usePermissions'
import useDeleteAction from '../../../../features/hooks/useDeleteAction'
import useGetLineItemActionsDropdownOptions from './useGetLineItemActionsDropdownOptions'

import { getLineItemSummaryRedirectLinkHandler } from '../../../../constants/lineItems'

import {
  clearDeleteLineItem,
  clearUpdateLineItem,
  deleteLineItem,
  updateLineItem
} from '../../../../modules/actions/lineItems'
import {
  lineItemsSelector,
  lineItemDeleteSelector,
  lineItemDeleteIsLoadingSelector,
  lineItemDeleteErrorSelector,
  lineItemUpdateSelector,
  lineItemUpdateErrorSelector,
  lineItemUpdateIsLoadingSelector,
  lineItemsTotalsSelector,
  lineItemsLoadingSelector,
  lineItemsWasLoadedSelector,
  lineItemsErrorSelector,
  lineItemWasUpdatedSelector
} from '../../../../modules/selectors/lineItems'
import { campaignSelector, campaignWasLoadedSelector } from '../../../../modules/selectors/campaigns'
import {
  selectedPlatformSelector,
  selectedAdAccountIdSelector,
  selectedAdAccountDataSelector,
  selectedSelfAccountSelector
} from '../../../../modules/selectors/app'

import { FACEBOOK_PLATFORM } from '../../../../constants/selectLists/platformList'
import { listItemChangeStatus } from '../../../../constants/other'
import { AD_PERMISSION, LINE_ITEM_PERMISSION } from '../../../../constants/permissions'

function LineItemsContent({ noDataMessageText }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [isStatusUpdateRunning, setIsStatusUpdateRunning] = useState(false)

  const campaignWasLoaded = useSelector(campaignWasLoadedSelector)
  const selfAccountId = useSelector(selectedSelfAccountSelector)
  const lineItems = useSelector(lineItemsSelector)
  const totalsItem = useSelector(lineItemsTotalsSelector)
  const lineItemUpdate = useSelector(lineItemUpdateSelector)
  const lineItemWasUpdated = useSelector(lineItemWasUpdatedSelector)
  const lineItemUpdateError = useSelector(lineItemUpdateErrorSelector)
  const lineItemUpdateIsLoading = useSelector(lineItemUpdateIsLoadingSelector)
  const lineItemDelete = useSelector(lineItemDeleteSelector)
  const lineItemDeleteIsLoading = useSelector(lineItemDeleteIsLoadingSelector)
  const selectedPlatform = useSelector(selectedPlatformSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const { currency_symbol: currencySymbol } = useSelector(selectedAdAccountDataSelector)
  const campaign = useSelector(campaignSelector)

  const permissions = usePermissions()
  const hasStatusChangePermissions = permissions.can('update', LINE_ITEM_PERMISSION)

  const { objective: campaignObjective } = campaign

  const itemUpdatingId = useMemo(() => {
    // avoid representation of skeleton row during status update (happens from toggle button)
    if (lineItemUpdateIsLoading && !isStatusUpdateRunning) {
      return lineItemUpdate.id
    } else if (lineItemDeleteIsLoading) {
      return lineItemDelete.id
    }

    return null
  }, [isStatusUpdateRunning, lineItemDelete.id, lineItemDeleteIsLoading, lineItemUpdate.id, lineItemUpdateIsLoading])

  const deleteLineItemHandler = useCallback(
    params => {
      dispatch(deleteLineItem(params))
    },
    [dispatch]
  )

  const clearDeleteLineItemHandler = useCallback(() => {
    dispatch(clearDeleteLineItem())
  }, [dispatch])

  const deleteModalMessage = (
    <>
      <p>
        {t(
          `If there has been recent delivery on this ad ${
            selectedPlatform === FACEBOOK_PLATFORM ? 'set' : 'group'
          }, we recommend you archive it. Otherwise, you can delete if it is not required anymore.`
        )}
      </p>
      <p>{t('This cannot be undone.')}</p>
    </>
  )

  const {
    handleDelete,
    handleDeleteRequest,
    handleCloseDeleteModal,
    isDeleteModalOpened,
    deleteItemIsLoading,
    dataWaitingForDelete
  } = useDeleteAction({
    onDelete: deleteLineItemHandler,
    onClearDelete: clearDeleteLineItemHandler,
    deletedItemSelector: lineItemDeleteSelector,
    deleteItemIsLoadingSelector: lineItemDeleteIsLoadingSelector,
    deleteItemErrorSelector: lineItemDeleteErrorSelector,
    deleteModalMessage
  })

  const lineItemArchiveProps = useMemo(() => {
    return {
      updateItemAction: updateLineItem,
      updatedItemSelector: lineItemUpdateSelector,
      updateItemErrorSelector: lineItemUpdateErrorSelector,
      updateItemIsLoadingSelector: lineItemUpdateIsLoadingSelector,
      clearUpdateAction: clearUpdateLineItem,
      dataWaitingForDelete
    }
  }, [dataWaitingForDelete])

  const updateLineItemStatusHandler = useCallback(
    lineItem => {
      setIsStatusUpdateRunning(true)

      dispatch(
        updateLineItem(
          {
            account: selectedAdAccountId,
            status: listItemChangeStatus[lineItem.status]?.changeTo,
            ...(campaignObjective && { campaign_objective: campaignObjective })
          },
          lineItem.id
        )
      )
    },
    [dispatch, selectedAdAccountId, campaignObjective]
  )

  const getLineItemActionsDropdownOptions = useGetLineItemActionsDropdownOptions({ handleDeleteRequest })

  const getLineItemLinkClickHandler = useCallback(
    ({ item, platform, adAccountId }) =>
      getLineItemSummaryRedirectLinkHandler({
        campaign,
        lineItem: item,
        platform,
        adAccountId: adAccountId,
        selfAccountId: selfAccountId
      }),
    [campaign, selfAccountId]
  )

  useEffect(() => {
    if (isStatusUpdateRunning && (lineItemWasUpdated || lineItemUpdateError)) {
      dispatch(clearUpdateLineItem())

      setIsStatusUpdateRunning(false)
    }
  }, [dispatch, lineItemWasUpdated, lineItemUpdateError, isStatusUpdateRunning])

  return (
    <>
      <DigitalTable
        platform={selectedPlatform}
        data={lineItems}
        totalsItem={totalsItem}
        noDataMessageText={noDataMessageText}
        isLoadingSelector={lineItemsLoadingSelector}
        errorSelector={lineItemsErrorSelector}
        wasLoadedSelector={lineItemsWasLoadedSelector}
        additionalDataIsLoading={!campaignWasLoaded}
        allowStatusChange={hasStatusChangePermissions}
        onStatusChange={updateLineItemStatusHandler}
        itemUpdatingId={itemUpdatingId}
        currencySymbol={currencySymbol}
        getItemLinkClickHandler={getLineItemLinkClickHandler}
        getActionsDropdownOptions={getLineItemActionsDropdownOptions}
      />

      <Can I="create" a={AD_PERMISSION}>
        <AdDuplicationModal showRedirectionBtn />
      </Can>
      <Can I="delete" a={LINE_ITEM_PERMISSION}>
        <DeleteItemModal
          handleDelete={handleDelete}
          handleCloseDeleteModal={handleCloseDeleteModal}
          isDeleteModalOpened={isDeleteModalOpened}
          deleteItemIsLoading={deleteItemIsLoading}
          deleteModalMessage={deleteModalMessage}
          // props for archive button, works only for facebook
          archiveProps={selectedPlatform === FACEBOOK_PLATFORM ? lineItemArchiveProps : null}
        />
      </Can>
    </>
  )
}

LineItemsContent.propTypes = {
  noDataMessageText: PropTypes.object
}

export default LineItemsContent
