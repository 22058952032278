import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

// Get media package
export const mediaPackageSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.getMediaPackage.data,
  data => data
)

export const getMediaPackageIsLoadingSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.getMediaPackage.isLoading,
  isLoading => isLoading
)

export const getMediaPackageErrorSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.getMediaPackage.error,
  error => error
)

export const mediaPackageWasLoadedSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.getMediaPackage.wasLoaded,
  wasLoaded => wasLoaded
)
// Get media package categories
export const mediaPackageCategoriesSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.mediaPackageCategories.results,
  results => results
)
export const mediaPackageCategoriesIsLoadingSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.mediaPackageCategories.isLoading,
  isLoading => isLoading
)
export const mediaPackageCategoriesErrorSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.mediaPackageCategories.error,
  error => error
)
export const mediaPackageCategoriesWasLoadedSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.mediaPackageCategories.wasLoaded,
  wasLoaded => wasLoaded
)
// Create media package category
export const createMediaPackageCategoryIsLoadingSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.createMediaPackageCategory.isLoading,
  isLoading => isLoading
)
export const createMediaPackageCategoryErrorSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.createMediaPackageCategory.error,
  error => error
)
export const createMediaPackageCategoryWasCreatedSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.createMediaPackageCategory.wasCreated,
  wasCreated => wasCreated
)
// Update media package category
export const updateMediaPackageCategoryIdSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.updateMediaPackageCategory.id,
  id => id
)
export const updateMediaPackageCategoryIsLoadingSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.updateMediaPackageCategory.isLoading,
  isLoading => isLoading
)
export const updateMediaPackageCategoryErrorSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.updateMediaPackageCategory.error,
  error => error
)
export const updateMediaPackageCategoryWasUpdatedSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.updateMediaPackageCategory.wasUpdated,
  wasUpdated => wasUpdated
)
// grouped media packages
export const groupedMediaPackagesSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.groupedMediaPackages.results,
  results => results
)
export const groupedMediaPackagesIsLoadingSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.groupedMediaPackages.isLoading,
  isLoading => isLoading
)
export const groupedMediaPackagesErrorSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.groupedMediaPackages.error,
  error => error
)
export const groupedMediaPackagesWasLoadedSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.groupedMediaPackages.wasLoaded,
  wasLoaded => wasLoaded
)
// media packages
export const mediaPackagesSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.mediaPackages.results,
  results => results
)
export const mediaPackagesIsLoadingSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.mediaPackages.isLoading,
  isLoading => isLoading
)
export const mediaPackagesErrorSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.mediaPackages.error,
  error => error
)
export const mediaPackagesWasLoadedSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.mediaPackages.wasLoaded,
  wasLoaded => wasLoaded
)
// Create media package
export const createMediaPackageIsLoadingSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.createMediaPackage.isLoading,
  isLoading => isLoading
)
export const createMediaPackageErrorSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.createMediaPackage.error,
  error => error
)
export const createMediaPackageWasCreatedSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.createMediaPackage.wasCreated,
  wasCreated => wasCreated
)
// Update media package
export const updateMediaPackageIdSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.updateMediaPackage.id,
  id => id
)
export const updateMediaPackageIsLoadingSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.updateMediaPackage.isLoading,
  isLoading => isLoading
)
export const updateMediaPackageErrorSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.updateMediaPackage.error,
  error => error
)
export const updateMediaPackageWasUpdatedSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.updateMediaPackage.wasUpdated,
  wasUpdated => wasUpdated
)
// Delete media package
export const deleteMediaPackageIdSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.deleteMediaPackage.id,
  id => id
)
export const deleteMediaPackageSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.deleteMediaPackage,
  deleteMediaPackage => deleteMediaPackage
)
export const deleteMediaPackageIsLoadingSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.deleteMediaPackage.isLoading,
  isLoading => isLoading
)
export const deleteMediaPackageErrorSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.deleteMediaPackage.error,
  error => error
)
export const deleteMediaPackageWasDeletedSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.deleteMediaPackage.wasDeleted,
  wasDeleted => wasDeleted
)
// Update media packages order
export const updateMediaPackagesOrderIsLoadingSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.updateMediaPackagesOrder.isLoading,
  isLoading => isLoading
)

// get media packages as csv
export const getMediaPackagesAsCsvSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.mediaPackagesAsCsv.data,
  data => data
)

export const getMediaPackagesAsCsvIsLoadingSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.mediaPackagesAsCsv.isLoading,
  isLoading => isLoading
)

export const getMediaPackagesAsCsvErrorSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.mediaPackagesAsCsv.error,
  error => error
)

export const getMediaPackagesAsCsvWasLoadedSelector = createSelector(
  ({ mediaPackages }) => mediaPackages.mediaPackagesAsCsv.wasLoaded,
  wasLoaded => wasLoaded
)
