import { concat } from '../../helpers/common'

const MODULE_NAME = 'DOOHLY/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const CREATE_DOOHLY_LINE_ITEM = concat(MODULE_NAME, 'CREATE_DOOHLY_LINE_ITEM')
export const CREATE_DOOHLY_LINE_ITEM_SUCCESS = concat(MODULE_NAME, 'CREATE_DOOHLY_LINE_ITEM_SUCCESS')
export const CREATE_DOOHLY_LINE_ITEM_FAILURE = concat(MODULE_NAME, 'CREATE_DOOHLY_LINE_ITEM_FAILURE')
export const CLEAR_CREATE_DOOHLY_LINE_ITEM = concat(MODULE_NAME, 'CLEAR_CREATE_DOOHLY_LINE_ITEM')

export const CREATE_DOOHLY_CAMPAIGN = concat(MODULE_NAME, 'CREATE_DOOHLY_CAMPAIGN')
export const CREATE_DOOHLY_CAMPAIGN_SUCCESS = concat(MODULE_NAME, 'CREATE_DOOHLY_CAMPAIGN_SUCCESS')
export const CREATE_DOOHLY_CAMPAIGN_FAILURE = concat(MODULE_NAME, 'CREATE_DOOHLY_CAMPAIGN_FAILURE')
export const CLEAR_CREATE_DOOHLY_CAMPAIGN = concat(MODULE_NAME, 'CLEAR_CREATE_DOOHLY_CAMPAIGN')

export const GET_DOOHLY_API_KEY = concat(MODULE_NAME, 'GET_DOOHLY_API_KEY')
export const GET_DOOHLY_API_KEY_SUCCESS = concat(MODULE_NAME, 'GET_DOOHLY_API_KEY_SUCCESS')
export const GET_DOOHLY_API_KEY_FAILURE = concat(MODULE_NAME, 'GET_DOOHLY_API_KEY_FAILURE')
export const CLEAR_GET_DOOHLY_API_KEY = concat(MODULE_NAME, 'CLEAR_GET_DOOHLY_API_KEY')

export const UPDATE_DOOHLY_API_KEY = concat(MODULE_NAME, 'UPDATE_DOOHLY_API_KEY')
export const UPDATE_DOOHLY_API_KEY_SUCCESS = concat(MODULE_NAME, 'UPDATE_DOOHLY_API_KEY_SUCCESS')
export const UPDATE_DOOHLY_API_KEY_FAILURE = concat(MODULE_NAME, 'UPDATE_DOOHLY_API_KEY_FAILURE')
export const CLEAR_UPDATE_DOOHLY_API_KEY = concat(MODULE_NAME, 'CLEAR_UPDATE_DOOHLY_API_KEY')

export const CREATE_DOOHLY_API_KEY = concat(MODULE_NAME, 'CREATE_DOOHLY_API_KEY')
export const CREATE_DOOHLY_API_KEY_SUCCESS = concat(MODULE_NAME, 'CREATE_DOOHLY_API_KEY_SUCCESS')
export const CREATE_DOOHLY_API_KEY_FAILURE = concat(MODULE_NAME, 'CREATE_DOOHLY_API_KEY_FAILURE')
export const CLEAR_CREATE_DOOHLY_API_KEY = concat(MODULE_NAME, 'CLEAR_CREATE_DOOHLY_API_KEY')

export const GET_DOOHLY_CAMPAIGNS = concat(MODULE_NAME, 'GET_DOOHLY_CAMPAIGNS')
export const GET_DOOHLY_CAMPAIGNS_SUCCESS = concat(MODULE_NAME, 'GET_DOOHLY_CAMPAIGNS_SUCCESS')
export const GET_DOOHLY_CAMPAIGNS_FAILURE = concat(MODULE_NAME, 'GET_DOOHLY_CAMPAIGNS_FAILURE')
export const CLEAR_GET_DOOHLY_CAMPAIGNS = concat(MODULE_NAME, 'CLEAR_GET_DOOHLY_CAMPAIGNS')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// Create doohly line item
export function createDoohlyLineItem(data) {
  return { type: CREATE_DOOHLY_LINE_ITEM, data }
}

export function createDoohlyLineItemSuccess(data) {
  return { type: CREATE_DOOHLY_LINE_ITEM_SUCCESS, data }
}

export function createDoohlyLineItemFailure(error) {
  return { type: CREATE_DOOHLY_LINE_ITEM_FAILURE, error }
}

export function clearCreateDoohlyLineItem() {
  return { type: CLEAR_CREATE_DOOHLY_LINE_ITEM }
}

// Create doohly campaign
export function createDoohlyCampaign(data) {
  return { type: CREATE_DOOHLY_CAMPAIGN, data }
}

export function createDoohlyCampaignSuccess(data) {
  return { type: CREATE_DOOHLY_CAMPAIGN_SUCCESS, data }
}

export function createDoohlyCampaignFailure(error) {
  return { type: CREATE_DOOHLY_CAMPAIGN_FAILURE, error }
}

export function clearCreateDoohlyCampaign() {
  return { type: CLEAR_CREATE_DOOHLY_CAMPAIGN }
}

// Get doohly api key
export function getDoohlyApiKey(params) {
  return { type: GET_DOOHLY_API_KEY, params }
}

export function getDoohlyApiKeySuccess(data) {
  return { type: GET_DOOHLY_API_KEY_SUCCESS, data }
}

export function getDoohlyApiKeyFailure(error) {
  return { type: GET_DOOHLY_API_KEY_FAILURE, error }
}

export function clearGetDoohlyApiKey() {
  return { type: CLEAR_GET_DOOHLY_API_KEY }
}

// Update doohly api key
export function updateDoohlyApiKey(id, data) {
  return { type: UPDATE_DOOHLY_API_KEY, id, data }
}

export function updateDoohlyApiKeySuccess(data) {
  return { type: UPDATE_DOOHLY_API_KEY_SUCCESS, data }
}

export function updateDoohlyApiKeyFailure(error) {
  return { type: UPDATE_DOOHLY_API_KEY_FAILURE, error }
}

export function clearUpdateDoohlyApiKey() {
  return { type: CLEAR_UPDATE_DOOHLY_API_KEY }
}

// Create doohly api key
export function createDoohlyApiKey(data) {
  return { type: CREATE_DOOHLY_API_KEY, data }
}

export function createDoohlyApiKeySuccess(data) {
  return { type: CREATE_DOOHLY_API_KEY_SUCCESS, data }
}

export function createDoohlyApiKeyFailure(error) {
  return { type: CREATE_DOOHLY_API_KEY_FAILURE, error }
}

export function clearCreateDoohlyApiKey() {
  return { type: CLEAR_CREATE_DOOHLY_API_KEY }
}

// Get doohly campaigns
export function getDoohlyCampaigns(params) {
  return { type: GET_DOOHLY_CAMPAIGNS, params }
}

export function getDoohlyCampaignsSuccess(data) {
  return { type: GET_DOOHLY_CAMPAIGNS_SUCCESS, data }
}

export function getDoohlyCampaignsFailure(error) {
  return { type: GET_DOOHLY_CAMPAIGNS_FAILURE, error }
}

export function clearGetDoohlyCampaigns() {
  return { type: CLEAR_GET_DOOHLY_CAMPAIGNS }
}
