import { createStyles } from '../../styles/helpers'

import { phonesDown, tabletDown } from '../../styles/const/breakpoints'
import { backBtn } from '../../styles/common/components'
import { textGrey } from '../../styles/const/colors'
import { settingsMenuWidth } from './SettingsNavMenu/styles'

const useStyles = createStyles(theme => ({
  settingsPage: {
    display: 'flex',
    columnGap: 44
  },
  settingsContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flexBasis: 'content',
    flexGrow: 1,
    width: '100%',
    margin: '0 auto',
    overflowX: 'hidden'
  },
  navContainer: {
    maxWidth: settingsMenuWidth,
    flexBasis: settingsMenuWidth,
    minHeight: '100vh',
    marginTop: 62
  },
  title: {
    marginBottom: 32,
    color: textGrey,
    fontWeight: '600',
    fontSize: 24
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 16,
    marginTop: 40
  },
  text: {
    textAlign: 'center',
    maxWidth: 466,
    fontSize: 14,
    marginTop: 16,
    '&:first-of-type': {
      marginTop: 24
    }
  },
  mobileCardsList: {
    marginTop: 16
  },
  // SETTINGS TABLE FILTER START:
  filtersContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    marginBottom: 25,
    gap: 10
  },
  dataContent: {
    // We need this to make sure that the Select options menu is not cut off when no data
    minHeight: 350
  },
  selectWrapper: {
    minWidth: 200
  },
  filterSelect: {
    minWidth: 200
  },
  [`@media screen and (${phonesDown})`]: {
    filterSelect: {
      width: '100%'
    }
  },
  // SETTINGS TABLE FILTER END

  [`@media screen and (${tabletDown})`]: {
    navContainer: {
      maxWidth: '100%',
      flexBasis: '100%',
      paddingRight: 0,
      transition: 'all ease 500ms',
      minHeight: 'unset',

      '&.swiped': {
        transform: 'translateX(-110%)'
      }
    },
    settingsContent: {
      paddingTop: 24
    }
  },
  backBtn: {
    opacity: isMenuOpened => (isMenuOpened ? 0 : 'initial'),
    pointerEvents: isMenuOpened => (isMenuOpened ? 'none' : 'auto'),
    extend: backBtn(theme),
    '& svg': {
      color: theme.brandPrimary
    }
  },
  textMarginTop: {
    marginTop: 24
  }
}))

export default useStyles
