import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import ContentSection from '../../../features/components/ContentSection'
import ContentRow from '../../../features/components/ContentSection/ContentRow'
import LocationListCreate from './LocationListForms/LocationListCreate'
import LocationListEdit from './LocationListForms/LocationListEdit'
import LocationsTable from './LocationsTable'

import { getChoicesCountries } from '../../../modules/actions/choices'
import { clearLocationLists, clearValidateLocationAddresses, getLocationLists } from '../../../modules/actions/location'
import { locationListsSelector } from '../../../modules/selectors/location'
import { selectedSelfAccountSelector } from '../../../modules/selectors/app'
import { choicesCountriesWasLoadedSelector } from '../../../modules/selectors/choices'

import useSettingsClasses from '../styles'

function ManageLocationLists() {
  const settingsClasses = useSettingsClasses()

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const locationLists = useSelector(locationListsSelector)
  const choicesCountriesWasLoaded = useSelector(choicesCountriesWasLoadedSelector)
  const selfAccount = useSelector(selectedSelfAccountSelector)

  useEffect(() => {
    dispatch(getLocationLists({ account: selfAccount, limit: 10 }))

    return () => {
      dispatch(clearLocationLists())
      dispatch(clearValidateLocationAddresses())
    }
  }, [dispatch, selfAccount])

  useEffect(() => {
    if (!choicesCountriesWasLoaded) {
      dispatch(getChoicesCountries())
    }
  }, [dispatch, choicesCountriesWasLoaded])

  return (
    <>
      <h1 className={settingsClasses.title}>{t('Location Targeting Lists')}</h1>
      <ContentSection title={t('Location targeting')}>
        <ContentRow
          title={t('Location list')}
          description={t(
            'Custom lists of addresses used to target campaigns around specific locations. A list of stocked stores, for example, could then be used to target a campaign to people that live near those stores.'
          )}
          leftColumnChildren={<LocationListCreate />}
        >
          <LocationsTable locations={locationLists} />
        </ContentRow>
      </ContentSection>
      <LocationListEdit />
    </>
  )
}

export default ManageLocationLists
