import { LOCATION, SUB_PRODUCTS, VARIABLES, MEDIA_SUB_CATEGORY, MEDIA_SUB_SUB_CATEGORY } from '../../fields'
import { ORDER } from '../../../../../features/components/Form/SortableUploadImagesList/fields'
import {
  CHANNEL_OPTION,
  CHANNEL_OPTION_ONSITE,
  DESCRIPTION,
  IMAGES,
  INTERNAL_ID,
  MEDIA_CATEGORY,
  NAME,
  PERIOD_OPTION,
  SUB_LOCATION,
  TAGS
} from '../../MediaProductCreate/MediaProductCreateForm/fields'
import { initialImageFileValues } from '../../../../../constants/files'

export const CURRENCY = 'currency'

export const getInitialValues = (controllerCurrency = '') => {
  return {
    [NAME]: '',
    [DESCRIPTION]: '',
    [MEDIA_CATEGORY]: '',
    [MEDIA_SUB_CATEGORY]: '',
    [MEDIA_SUB_SUB_CATEGORY]: '',
    [PERIOD_OPTION]: '',
    [CURRENCY]: controllerCurrency,
    [SUB_PRODUCTS]: [],
    [CHANNEL_OPTION]: CHANNEL_OPTION_ONSITE,
    // order starts from 1
    [IMAGES]: [{ ...initialImageFileValues, [ORDER]: 1 }],
    [LOCATION]: '',
    [TAGS]: [],
    [VARIABLES]: [],
    [SUB_LOCATION]: '',
    [INTERNAL_ID]: ''
  }
}
