import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getAgencyMembersService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.agencyMembers
  })
}

export function getAgencyInvitesService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.agencyInvites
  })
}

export function createAgencyInviteService(inviteData) {
  return madeRequest('POST', {
    data: inviteData,
    url: ENDPOINTS.agencyInvites
  })
}

export function acceptAgencyInviteService(inviteData) {
  return madeRequest('POST', {
    data: inviteData,
    url: `${ENDPOINTS.agencyInvites}accept/`
  })
}

export function updateAgencyMemberService(id, data) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.agencyMembers}${id}/`
  })
}

export function updateAgencyMemberStatusService(id, status) {
  return madeRequest('POST', {
    url: `${ENDPOINTS.agencyMembers}${id}/${status === 'active' ? 'deactivate/' : 'activate/'}`
  })
}

export function deleteAgencyInviteService(id) {
  return madeRequest('DELETE', {
    url: `${ENDPOINTS.agencyInvites}${id}/`
  })
}

export function resendAgencyInviteService(id) {
  return madeRequest('POST', {
    url: `${ENDPOINTS.agencyInvites}${id}/resend/`
  })
}
