import { createStyles } from '../../../styles/helpers'

import { phonesDown } from '../../../styles/const/breakpoints'

const useStyles = createStyles({
  inputBtn: {
    position: 'absolute',
    zIndex: '-1',
    opacity: 0,
    display: 'none'
  },
  buttonWrap: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
    padding: 0,
    marginTop: 25
  },
  uploadFileBtnLabel: {
    maxWidth: '100%'
  },
  uploadFileBtn: {
    width: 350,
    maxWidth: '100%'
  },
  [`@media screen and (${phonesDown})`]: {
    buttonWrap: {
      display: 'block',
      width: '100%'
    },
    uploadFileBtn: {
      width: '100%'
    }
  }
})

export default useStyles
