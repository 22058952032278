import { madeRequest } from '../../helpers/api'
import { replaceRouteParams } from '../../helpers/url'
import { ENDPOINT_PLATFORM, ENDPOINTS } from '../../constants/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getLineItemService({ id, ...params }, platform) {
  return madeRequest('GET', {
    params,
    url: replaceRouteParams(`${ENDPOINTS.lineItems}${id}/`, {
      platform: ENDPOINT_PLATFORM[platform]
    })
  })
}

export function getLineItemsService(params, platform) {
  return madeRequest('GET', {
    params,
    url: replaceRouteParams(ENDPOINTS.lineItems, { platform: ENDPOINT_PLATFORM[platform] })
  })
}

export function createLineItemService(lineItemData, platform) {
  return madeRequest('POST', {
    data: lineItemData,
    url: replaceRouteParams(ENDPOINTS.lineItems, { platform: ENDPOINT_PLATFORM[platform] })
  })
}

export function deleteLineItemService({ id, ...params }, platform) {
  return madeRequest('DELETE', {
    params,
    url: replaceRouteParams(`${ENDPOINTS.lineItems}${id}/`, {
      platform: ENDPOINT_PLATFORM[platform]
    })
  })
}

export function updateLineItemService(lineItemData, id, platform, requestMethod = 'PATCH') {
  return madeRequest(requestMethod, {
    data: lineItemData,
    url: replaceRouteParams(`${ENDPOINTS.lineItems}${id}/`, {
      platform: ENDPOINT_PLATFORM[platform]
    })
  })
}

export function duplicateLineItemService(duplicateData, id, platform) {
  return madeRequest('POST', {
    data: duplicateData,
    url: replaceRouteParams(ENDPOINTS.duplicateLineItem, {
      id,
      platform: ENDPOINT_PLATFORM[platform]
    })
  })
}

export function getLocationsService() {
  return madeRequest('GET', {
    url: ENDPOINTS.locations
  })
}

export function getGoalsService(objective) {
  return madeRequest('GET', {
    params: {
      objective
    },
    url: ENDPOINTS.goals
  })
}

export function getPixelsStatsService(id) {
  return madeRequest('GET', {
    url: `${ENDPOINTS.pixels}${id}/stats/`
  })
}

export async function getLineItemCriterionsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.lineItemCriterions
  })
}

export async function createLineItemCriterionsService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.lineItemCriterionsBatch
  })
}
