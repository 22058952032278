import { NAME, IDENTITY_ID, VIDEO_THUMBNAIL_URL } from './fields'
import { CALL_TO_ACTION } from '../../../../ReusableFormFields/CallToActionFields/fields'
import { AD_TEXT, formatCTA, LANDING_PAGE_URL, VIDEO } from '../../fields'
import { FILE_ID } from '../../../../ReusableFormFields/AdFileUpload/fields'

export const transformValuesToBE = ({
  values,
  account,
  campaignId,
  lineItemId,
  controllerId,
  isInternalAdCreation,
  identitiesList
}) => {
  const ctaValue = formatCTA(values)
  // display_name is set the same as selected identity name
  const identityName = identitiesList.find(({ identity_id }) => identity_id === values[IDENTITY_ID])?.display_name

  return {
    account,
    campaign_id: campaignId,
    adgroup_id: lineItemId,
    ad_format: 'SINGLE_VIDEO',
    name: values[NAME],
    identity_id: values[IDENTITY_ID],
    display_name: identityName,
    identity_type: 'CUSTOMIZED_USER',
    [CALL_TO_ACTION]: ctaValue,
    landing_page_url: values[LANDING_PAGE_URL],
    ad_text: values[AD_TEXT],
    [VIDEO]: values[FILE_ID],
    image_urls: [values[VIDEO_THUMBNAIL_URL]],
    ...(isInternalAdCreation && {
      quarantine: true,
      ...(controllerId && { controller_id: controllerId })
    })
  }
}
