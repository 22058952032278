import React from 'react'
import { useSelector } from 'react-redux'

import { lineItemSelector } from '../../../../../../modules/selectors/lineItems'
import { choicesLanguagesSelector } from '../../../../../../modules/selectors/choices'
import { formatOptionsList } from '../../../../../../features/formatters'

// Since we only get an array of language IDs from BE,
// we need to know which languages are received.
// To do this, we get a formatted list of all languages as a prop
// from the languages section and filter it by the IDs obtained from the redux
const LanguagesSectionPreview = () => {
  const choicesLanguages = useSelector(choicesLanguagesSelector)
  const { languages: lineItemLanguages } = useSelector(lineItemSelector)

  const formattedChoicesLanguages = formatOptionsList({
    list: choicesLanguages,
    valueName: 'key',
    labelName: 'name'
  })

  if (lineItemLanguages.length) {
    const formattedLineItemLanguages =
      typeof lineItemLanguages[0] !== 'object'
        ? formattedChoicesLanguages.filter(item => lineItemLanguages.includes(item.value))
        : lineItemLanguages

    return (
      <p>
        {formattedLineItemLanguages.map(language => {
          return <span key={language.value}>{language.label}</span>
        })}
      </p>
    )
  }

  return null
}

export default LanguagesSectionPreview
