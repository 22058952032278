import React from 'react'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import CallToActionFields from '../../../../../../ReusableFormFields/CallToActionFields'

import tikTokCallToActionsList from '../../../../../../../constants/selectLists/tikTokCallToActionsList'

function CallToActionStep({ formik }) {
  return (
    <Step formTitle="Create an ad" stepTitle="Call to action">
      <CallToActionFields formik={formik} actionsList={tikTokCallToActionsList} />
    </Step>
  )
}

export default CallToActionStep
