import { useTranslation } from 'react-i18next'

export const useTranslatedChildren = children => {
  const { t } = useTranslation()

  if (typeof children === 'string') {
    return t(children)
  } else {
    return children
  }
}
