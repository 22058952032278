import { createStyles } from '../../../styles/helpers'

import { lightGrey2, mediumGrey, textGrey } from '../../../styles/const/colors'
import { tabletDown } from '../../../styles/const/breakpoints'

export const settingsMenuWidth = 250

const useStyles = createStyles(theme => ({
  nav: {
    minWidth: settingsMenuWidth,
    maxWidth: settingsMenuWidth,
    flexBasis: settingsMenuWidth,
    margin: 0,
    border: `1px solid ${mediumGrey}`,
    marginBottom: 16,
    position: 'sticky',
    overflow: 'hidden',
    borderRadius: theme.bigBorderRadius
  },
  navItem: {
    backgroundColor: '#fff',
    color: textGrey,

    '&:hover > a': {
      fontWeight: '600'
    },
    '&:hover .right-arrow path': {
      fill: theme.brandPrimary
    },
    '&:last-child > a': {
      borderBottom: 'none'
    }
  },
  navItemRight: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxHeight: 15
  },
  navItemName: {
    color: textGrey,
    fontWeight: 400,
    height: 20
  },
  navLink: {
    padding: '12px 14px',
    color: 'inherit',
    fontWeight: 'inherit',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 'auto',
    borderBottom: `1px solid ${mediumGrey}`,

    '&.selected': {
      fontWeight: '600',
      color: theme.brandPrimary
    },
    '&.selected .right-arrow > path': {
      fill: theme.brandPrimary
    }
  },
  navIcon: {
    marginLeft: 10,
    display: 'flex',

    '& .right-arrow': {
      transform: 'rotate(-90deg)',
      width: 12,
      height: 12,
      '& > path': {
        fill: lightGrey2
      }
    }
  },
  [`@media screen and (${tabletDown})`]: {
    nav: {
      maxWidth: '100%',
      flexBasis: '100%',
      fontSize: 18
    },
    navItem: {
      fontWeight: '600',

      '& .right-arrow > path': {
        fill: theme.brandPrimary
      }
    },
    navItemName: {
      fontWeight: 400
    }
  }
}))

export default useStyles
