import { createStyles } from '../../../../../../styles/helpers'

export default createStyles({
  adPreview: {
    width: containerWidth => containerWidth,
    borderRadius: 9,
    overflow: 'hidden',
    margin: '0 auto 22px',
    maxWidth: '100%',

    '& img': {
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },

  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 95,
    background: '#fff',
    padding: 10,
    color: '#000'
  },

  footerTop: {
    wordWrap: 'break-word',
    fontSize: 12
  },
  footerBottom: {
    fontSize: 10,
    marginTop: 5
  }
})
