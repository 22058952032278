import { createStyles } from '../../../../../../../styles/helpers'

import { greyOutline, white } from '../../../../../../../styles/const/colors'

const useStyles = createStyles({
  replaceButton: {
    border: 'none',
    color: greyOutline,

    '&:hover': {
      color: white
    }
  }
})

export default useStyles
