import { useDispatch } from 'react-redux'
import { useCallback, useEffect } from 'react'

import { createJsonFromQueryString } from '../../helpers/url'

const defaultParams = {}

const initialLoadOptions = {
  shouldClearExistingState: true
}

export const useLoadPaginatedList = ({ allowFetch = true, params = defaultParams, action, clearAction, next }) => {
  const dispatch = useDispatch()

  const load = useCallback(
    next => {
      dispatch(
        action({
          ...params,
          ...createJsonFromQueryString(`?${next.split('?')[1]}`)
        })
      )
    },
    [dispatch, params, action]
  )

  const loadMoreHandler = useCallback(() => {
    load(next)
  }, [load, next])

  useEffect(() => {
    // Initial load
    allowFetch && dispatch(action({ limit: 20, ...params }, initialLoadOptions))
  }, [dispatch, allowFetch, action, params])

  useEffect(() => {
    return () => {
      clearAction && dispatch(clearAction())
    }
  }, [clearAction, dispatch])

  return next ? loadMoreHandler : null
}
