import React from 'react'
import { format } from 'date-fns'

import useStyles from './styles'

const HistoryItem = ({ item }) => {
  const { modified: modifiedDate, statement, is_updated: isUpdated } = item

  const classes = useStyles({ isUpdated })

  const formattedDate = modifiedDate && format(new Date(modifiedDate), 'dd MMM yy, HH:mm')

  return (
    <div className={classes.historyItem}>
      <p className={classes.historyDate}>{formattedDate}</p>
      <p className={classes.historyText}>{statement}</p>
    </div>
  )
}

export default HistoryItem
