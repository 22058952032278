import { LANGUAGES } from '../../../../ReusableFormFields/LanguagesFields/fields'

export const initialLanguagesValues = {
  [LANGUAGES]: [
    {
      label: 'English',
      resource_name: 'languageConstants/1000',
      value: 1000
    }
  ]
}

export const transformLanguagesValuesToBE = values => {
  return values[LANGUAGES].map(({ resource_name }) => ({
    language: {
      language_constant: resource_name
    }
  }))
}
