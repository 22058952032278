import { createStyles } from '../../../../styles/helpers'

import { black, lightYellow } from '../../../../styles/const/colors'

const useStyles = createStyles(theme => ({
  container: {
    marginTop: 24
  },
  title: {
    color: black,
    fontSize: 16,
    marginTop: 24
  },
  logo: {
    width: 69
  },
  suggestionText: {},
  selectedSuggestion: {},
  suggestionsContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  suggestion: {
    marginTop: [4, '!important'],
    cursor: 'pointer',
    '& $suggestionText': {
      color: 'black',
      fontSize: 10,
      backgroundColor: lightYellow,
      padding: '2px 6px',
      '-webkit-box-decoration-break': 'clone',
      boxDecorationBreak: 'clone',
      borderRadius: 8,
      lineHeight: '21px'
    },
    '& $selectedSuggestion': {
      backgroundColor: theme.brandPrimary,
      color: theme.brandPrimaryTextContrastColor
    },
    '&:not(:last-child)': {
      marginRight: 8
    },
    '&:hover $suggestionText': {
      backgroundColor: theme.brandPrimary,
      color: theme.brandPrimaryTextContrastColor
    }
  }
}))

export default useStyles
