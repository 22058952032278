import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useDidMount } from '../useDidMount'

import { closeForm, openForm } from '../../modules/actions/forms'
import { formItemIdSelector, formsOpenStatesSelector } from '../../modules/selectors/forms'

export default function useManageFormsDrawer({ formName, onAfterFormClose }) {
  const dispatch = useDispatch()
  const didMount = useDidMount()

  const { [formName]: selectedEditItemId } = useSelector(formItemIdSelector)
  // when formName not found treat it as closed(false)
  const { [formName]: isFormOpen = false } = useSelector(formsOpenStatesSelector)

  const handleFormClose = useCallback(() => {
    dispatch(closeForm())
  }, [dispatch])

  const openDrawerForm = useCallback(
    itemId => {
      if (typeof itemId === 'number' || typeof itemId === 'string') {
        dispatch(openForm({ id: itemId, formName }))
      } else {
        // avoid sending event object
        dispatch(openForm({ formName }))
      }
    },
    [dispatch, formName]
  )

  useEffect(() => {
    // invoke a callback after the form become closed
    if (!isFormOpen && didMount) {
      onAfterFormClose && onAfterFormClose()
    }
    // avoid invoke useEffect on initial rendering
    // eslint-disable-next-line
  }, [isFormOpen])

  return useMemo(
    () => ({
      isFormOpen,
      selectedEditItemId,
      openDrawerForm,
      handleFormClose
    }),
    [isFormOpen, selectedEditItemId, openDrawerForm, handleFormClose]
  )
}
