import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { getIn } from 'formik'
import { v4 as uuidv4 } from 'uuid'

import AdFileUpload from '../../../../../../ReusableFormFields/AdFileUpload'

import { useMediaUpload } from '../../../../../../ReusableFormFields/AdFileUpload/hooks/useMediaUpload'
import { formatMediaFile } from '../../formatters'

import { DV_360_PLATFORM } from '../../../../../../../constants/selectLists/platformList'
import { MEDIA_FILE_UPLOAD } from '../../../../../../ReusableFormFields/AdFileUpload/fields'
import { AD_FILE } from '../../../../../../Facebook/AdForms/fields'
import { selectedDv360AdAccountIdSelector } from '../../../../../../../modules/selectors/app'
import { fileUpload } from '../../../../../../../modules/actions/files'

import { initialDv360File, MEDIA_ITEMS } from '../../fields'
import { ASSET_ID, FILE_NAME, FILE_URL } from '../../../../../../Google/AdForms/fields'

import useStyles from './styles'

function Dv360MediaUpload({ formik, itemPath, onMediaRemove, index, fileValidationSchema, allowMultipleFiles }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { setFieldValue, values } = formik

  const dv360AdAccountId = useSelector(selectedDv360AdAccountIdSelector)

  const { fileURLKey, fileTypeKey, uploadedMediaTypeKey, mediaFile, mediaMissingError, handleMediaRemove } =
    useMediaUpload({
      formik,
      itemPath,
      onMediaRemove,
      initialMediaFileValues: initialDv360File,
      errorFieldName: ASSET_ID
    })

  const handleSingleMediaStartUpload = useCallback(
    (file, newMediaItemValues) => {
      // once new file or files are uploaded this values should be updated with the first file in "files" array
      const currentMediaUpdatedValues = formatMediaFile(newMediaItemValues)

      // single media file was uploaded
      const currentMediaValues = getIn(values, itemPath)
      // update current media with new file values
      setFieldValue(itemPath, { ...currentMediaValues, ...currentMediaUpdatedValues })

      dispatch(
        fileUpload(file, {
          isPlatformRelated: true,
          platform: DV_360_PLATFORM,
          isPrivate: false,
          account: dv360AdAccountId,
          adAccountId: dv360AdAccountId
        })
      )
    },
    [dispatch, setFieldValue, dv360AdAccountId, values, itemPath]
  )

  const handleMediasStartUpload = useCallback(
    (files, newMediaItemsValues) => {
      // this component is shown by initial media item values which added to formik values
      // once new file or files are uploaded this values should be updated with the first file in "files" array

      const currentMediaUpdatedValues = formatMediaFile(newMediaItemsValues[0])
      const currentMediasValues = getIn(values, MEDIA_ITEMS)
      const newMedia = { id: uuidv4(), ...initialDv360File }

      // update current media
      const updatedMediasValues = currentMediasValues.map((media, i) => {
        if (i === index) {
          return { ...media, ...currentMediaUpdatedValues }
        }
        return media
      })

      if (files.length === 1) {
        // single media file was uploaded
        // update current media + add initial media item
        updatedMediasValues.push(newMedia)
      } else {
        // multiple media files were uploaded
        // add new media items with values excluding first file as it was updated within the current media
        const newAdsMediaItems = newMediaItemsValues.slice(1).map(item => {
          const newMediaItemValues = formatMediaFile(item)
          return {
            // this is a temporary id for the media file uploading to avoid preview disappearing on other media files removal
            id: uuidv4(),
            ...initialDv360File,
            ...newMediaItemValues
          }
        })

        // update current media + add new media items with values + add initial media item
        updatedMediasValues.push(...newAdsMediaItems, newMedia)
      }
      setFieldValue(MEDIA_ITEMS, updatedMediasValues)

      // todo SHOP-1542 implement multiple file upload after demo
      // // upload multiple files to server
      // dispatch(
      //   multipleFilesUpload(files, {
      //     isPlatformRelated: true,
      //     platform: DV_360_PLATFORM,
      //     isPrivate: false,
      //     account: selectedAdAccountId
      //   })
      // )
      dispatch(
        fileUpload(files[0], {
          isPlatformRelated: true,
          platform: DV_360_PLATFORM,
          isPrivate: false,
          adAccountId: dv360AdAccountId
        })
      )
    },
    [dispatch, values, setFieldValue, dv360AdAccountId, index]
  )

  const handleMediaUploaded = useCallback(
    uploadedFile => {
      const assetFileFields = {
        ...mediaFile,
        [ASSET_ID]: uploadedFile.mediaId,
        [FILE_URL]: uploadedFile.url
      }
      setFieldValue(itemPath, assetFileFields)
    },
    [itemPath, setFieldValue, mediaFile]
  )

  return (
    <div className={classes.fileContainer}>
      <AdFileUpload
        validateForm={formik.validateForm} //
        values={formik.values}
        fileValidationSchema={fileValidationSchema}
        onFileStartUpload={allowMultipleFiles ? handleMediasStartUpload : handleSingleMediaStartUpload}
        onFileUploaded={handleMediaUploaded}
        onFileRemove={handleMediaRemove}
        error={mediaMissingError}
        mediaType={MEDIA_FILE_UPLOAD}
        fileKey={`${itemPath}[${AD_FILE}]`}
        fileNameKey={`${itemPath}[${FILE_NAME}]`}
        fileURLKey={fileURLKey}
        uploadedMediaTypeKey={uploadedMediaTypeKey}
        fileTypeKey={fileTypeKey}
        accept="image/png,image/jpeg,image/gif"
        maxSize={5242880}
        // multiple={allowMultipleFiles}
        isDraggable
      />
    </div>
  )
}

Dv360MediaUpload.propTypes = {
  formik: PropTypes.object.isRequired,
  itemPath: PropTypes.string.isRequired,
  onMediaRemove: PropTypes.func
}

export default Dv360MediaUpload
