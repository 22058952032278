import React from 'react'
import { useTranslation } from 'react-i18next'

import Field from '../../../../../components/Form/Field'

import { NAME } from '../fields'
import useStyles from '../../../../../styles/common/stepForms'

const NameStep = ({ formik }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create an audience')}</div>
      <div className={classes.stepTitle}>{t('Finally, give the new audience a name')}</div>
      <div className={classes.stepBody}>
        <Field formik={formik} name={NAME} placeholder="Name" autoComplete="off" />
      </div>
    </>
  )
}

export default NameStep
