import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../components/Form'
import ProductVariableFormContent from './ProductVariableFormContent'

import { transformValuesToBE } from './formatters'

import {
  createMediaProductVariableErrorSelector,
  createMediaProductVariableIsLoadingSelector,
  createMediaProductVariableWasCreatedSelector
} from '../../../../../modules/selectors/mediaOrdersProductVariables'
import {
  clearCreateMediaProductVariable,
  createMediaProductVariable
} from '../../../../../modules/actions/mediaOrdersProductVariables'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'

import { MEDIA_PRODUCT_VARIABLE_CREATE } from '../../../../../constants/forms'
import { initialValues } from './fields'
import { validationSchema } from '../../validation'

function MediaProductVariableCreateForm({ onSuccessSubmit }) {
  const dispatch = useDispatch()

  const variableWasCreated = useSelector(createMediaProductVariableWasCreatedSelector)

  const controllerId = useSelector(selectedControllerIdSelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values)
      dispatch(
        createMediaProductVariable({
          controller: controllerId,
          ...transformedData
        })
      )
    },
    [dispatch, controllerId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const clearCreateItemHandler = useCallback(() => dispatch(clearCreateMediaProductVariable()), [dispatch])

  return (
    <Form
      formik={formik}
      formName={MEDIA_PRODUCT_VARIABLE_CREATE}
      // processing
      successSubmit={variableWasCreated}
      errorSelector={createMediaProductVariableErrorSelector}
      isLoadingSelector={createMediaProductVariableIsLoadingSelector}
      // after form submit
      clearHandler={clearCreateItemHandler}
    >
      <ProductVariableFormContent formik={formik} />
    </Form>
  )
}

export default MediaProductVariableCreateForm
