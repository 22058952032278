import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import MediaThumbnail from './MediaThumbnail'

import useStyles from './styles'

const emptyImage = []
const ProductGallery = ({ selectedImage, onMediaSelect, images = emptyImage }) => {
  const classes = useStyles()

  const handleMediaClick = media => {
    onMediaSelect && onMediaSelect(media)
  }

  return (
    <ul className={classes.galleryWrapper}>
      {images.map(image => (
        <li className={classes.imageContainer} key={image}>
          <MediaThumbnail
            className={classnames({ [classes.selected]: image === selectedImage })}
            onMediaClick={handleMediaClick}
            imageUrl={image}
          />
        </li>
      ))}
    </ul>
  )
}

ProductGallery.propTypes = {
  mediaThumbnailFieldName: PropTypes.string.isRequired,
  onMediaSelect: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired
}

export default ProductGallery
