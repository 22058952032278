import { DV_360_PLATFORM, FACEBOOK_PLATFORM, GOOGLE_PLATFORM, TIKTOK_PLATFORM } from './selectLists/platformList'
import { redirectTo } from '../helpers/url'
import { ROUTE_PARAMS, ROUTES } from './routes'

export const lineItemNameByPlatform = {
  [FACEBOOK_PLATFORM]: 'Ad Set',
  [DV_360_PLATFORM]: 'Line Item',
  [GOOGLE_PLATFORM]: 'Ad Group',
  [TIKTOK_PLATFORM]: 'Ad Group'
}

export const lineItemEffectiveStatuses = ['campaign_paused', 'in_process', 'with_issues']

export const facebookInterestsSuggestions = [
  {
    label: 'Vegetarianism',
    value: '6003155333705'
  },
  {
    label: 'Fitness and wellness',
    value: '6003384248805'
  },
  {
    label: 'Healthy diet',
    value: '6003382102565'
  },
  {
    label: 'Yoga',
    value: '6003306084421'
  },
  {
    label: 'Pilates',
    value: '6003159020675'
  },
  {
    label: 'Paleolithic diet',
    value: '6003456325403'
  }
]

export const GOOGLE_AD_GROUP_USER_LIST_CRITERION_TYPE = 16

// TikTok line item action categories constants
export const VIDEO_RELATED = 'VIDEO_RELATED'
export const CREATOR_RELATED = 'CREATOR_RELATED'
export const HASHTAG_RELATED = 'HASHTAG_RELATED'

export const getLineItemSummaryRedirectLinkHandler = ({
  lineItem = {},
  campaign = {},
  platform,
  selfAccountId,
  adAccountId
}) => {
  return () =>
    redirectTo(ROUTES.lineItemsSummary, {
      [ROUTE_PARAMS.platform]: platform,
      [ROUTE_PARAMS.selfAccount]: selfAccountId,
      [ROUTE_PARAMS.adAccount]: adAccountId,
      [ROUTE_PARAMS.campaignId]: lineItem.campaign_id || campaign.id,
      [ROUTE_PARAMS.lineItemId]: lineItem.id
    })
}

export const getLineItemSummaryLinkText = platform => {
  switch (platform) {
    case GOOGLE_PLATFORM:
    case TIKTOK_PLATFORM:
      return 'Go to Ad Group'
    case DV_360_PLATFORM:
      return 'Go to Line Item'
    case FACEBOOK_PLATFORM:
    default:
      return 'Go to Ad Set'
  }
}
