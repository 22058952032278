import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles({
  currencyField: {
    minWidth: 92,
    maxWidth: 92
  },
  valueField: {
    flexGrow: 1
  },
  symbol: {
    opacity: [1, '!important']
  }
})

export default useStyles
