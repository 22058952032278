import { createStyles } from '../../../styles/helpers'

import { black, darkGrey, green, greyOutline, red } from '../../../styles/const/colors'
import { transitionShort } from '../../../styles/const/common'

const useStyles = createStyles(theme => ({
  container: {
    marginTop: 16,
    marginBottom: 4
  },
  icon: {
    display: 'flex',
    width: 24,
    height: 24,
    color: theme.brandPrimary,
    marginBottom: 8
  },
  selectFiles: {
    display: 'block',
    textDecoration: 'underline'
  },
  dragInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  actionText: {
    margin: [0, '!important'],
    width: '100%',
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'center',
    color: theme.brandPrimary
  },
  clickHereText: {
    fontSize: 12,
    fontWeight: 400,
    marginTop: 8,
    color: darkGrey
  },
  progressBar: {
    marginBottom: 15
  },
  baseStyle: {
    minHeight: 158,
    flex: 1,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '30px 16px',
    borderWidth: 1,
    borderColor: greyOutline,
    borderStyle: 'dashed',
    borderRadius: theme.bigBorderRadius,
    backgroundColor: 'white',
    color: black,
    outline: 'none',
    transition: transitionShort,
    cursor: 'pointer',

    '&:hover': {
      borderColor: theme.brandPrimary
    }
  },
  activeStyle: {
    borderColor: theme.brandPrimary
  },
  acceptStyle: {
    borderColor: green
  },
  rejectStyle: {
    borderColor: red
  }
}))

export default useStyles
