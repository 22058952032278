import { createStyles } from '../../../../styles/helpers'
import { phonesDown } from '../../../../styles/const/breakpoints'

export default createStyles({
  slider: {
    maxHeight: [48, '!important']
  },
  slide: {
    maxHeight: [48, '!important'],
    height: [48, '!important']
  },

  [`@media screen and (${phonesDown})`]: {
    bannerContainer: {
      overflow: 'hidden',
      height: 100
    },
    slider: {
      maxHeight: [100, '!important'],
      height: [100, '!important']
    },
    slide: {
      maxHeight: [100, '!important'],
      height: [100, '!important']
    }
  }
})
