import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import MultipleOptionsField from '../../../../../../../ReusableFormFields/MultipleOptionsField'
import TextSuggestions from '../../../../../../../../features/components/Form/TextSuggestions'

import { googleAdSuggestionsIsLoadingSelector } from '../../../../../../../../modules/selectors/tools'

import { LONG_HEADLINES_LIST, longHeadlineMaxLength } from '../../../../../fields'

function LongHeadlinesSection({ formik, suggestions }) {
  const [selectedField, setSelectedField] = useState('')

  const suggestionsLoading = useSelector(googleAdSuggestionsIsLoadingSelector)

  const { setFieldValue } = formik

  const handleLongHeadlineTextChange = useCallback(
    selectedChange => {
      setFieldValue(selectedField, selectedChange)
      setSelectedField('')
    },
    [setFieldValue, selectedField]
  )

  const handleSelectedFieldChange = useCallback(selectedField => {
    setSelectedField(selectedField)
  }, [])

  return (
    <FieldRow
      title="Long Headlines"
      description="90 characters or less"
      footer={
        <TextSuggestions
          showLogo={false}
          selectedSuggestion={null}
          suggestions={suggestions}
          onSelectSuggestion={handleLongHeadlineTextChange}
          typingAnimation={suggestionsLoading}
        />
      }
    >
      <MultipleOptionsField
        isTextarea
        fieldPlaceholder="Long Headline"
        fieldMaxLength={longHeadlineMaxLength}
        listFieldName={LONG_HEADLINES_LIST}
        formik={formik}
        minListQuantity={2}
        maxListQuantity={5}
        onFieldSelect={handleSelectedFieldChange}
        selectedFieldName={selectedField}
        preselectField={!!suggestions?.length}
        addAnotherButtonText="+ Add another long headline"
      />
    </FieldRow>
  )
}

LongHeadlinesSection.propTypes = {
  suggestions: PropTypes.array
}

export default LongHeadlinesSection
