import update from 'immutability-helper'
import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_FACEBOOK_PAGES,
  GET_FACEBOOK_PAGES_SUCCESS,
  GET_FACEBOOK_PAGES_FAILURE,
  CLEAR_FACEBOOK_PAGES,
  GET_ACCESSIBLE_FACEBOOK_PAGES,
  GET_ACCESSIBLE_FACEBOOK_PAGES_SUCCESS,
  GET_ACCESSIBLE_FACEBOOK_PAGES_FAILURE,
  ADD_ACCESSIBLE_FACEBOOK_PAGES,
  ADD_ACCESSIBLE_FACEBOOK_PAGES_SUCCESS,
  ADD_ACCESSIBLE_FACEBOOK_PAGES_FAILURE,
  CLEAR_ADD_ACCESSIBLE_FACEBOOK_PAGES,
  DELETE_ACCESSIBLE_FACEBOOK_PAGES,
  DELETE_ACCESSIBLE_FACEBOOK_PAGES_SUCCESS,
  DELETE_ACCESSIBLE_FACEBOOK_PAGES_FAILURE,
  CLEAR_DELETE_ACCESSIBLE_FACEBOOK_PAGES,
  GET_INSTAGRAM_ACCOUNTS,
  GET_INSTAGRAM_ACCOUNTS_SUCCESS,
  GET_INSTAGRAM_ACCOUNTS_FAILURE,
  CLEAR_INSTAGRAM_ACCOUNTS,
  GET_FACEBOOK_PAGE_ACCESS_TOKEN,
  GET_FACEBOOK_PAGE_ACCESS_TOKEN_SUCCESS,
  GET_FACEBOOK_PAGE_ACCESS_TOKEN_FAILURE,
  CLEAR_FACEBOOK_PAGE_ACCESS_TOKEN,
  CREATE_FACEBOOK_PAGE_ACCESS_REQUEST,
  CREATE_FACEBOOK_PAGE_ACCESS_REQUEST_SUCCESS,
  CREATE_FACEBOOK_PAGE_ACCESS_REQUEST_FAILURE,
  CLEAR_CREATE_FACEBOOK_PAGE_ACCESS_REQUEST,
  CLEAR_ACCESSIBLE_FACEBOOK_PAGES,
  GET_BACKED_INSTAGRAM_ACCOUNTS,
  GET_BACKED_INSTAGRAM_ACCOUNTS_SUCCESS,
  GET_BACKED_INSTAGRAM_ACCOUNTS_FAILURE,
  CLEAR_BACKED_INSTAGRAM_ACCOUNTS
} from '../actions/socialAccounts'
import {
  INITIAL_CREATE_REDUCER,
  INITIAL_DELETE_REDUCER,
  INITIAL_GET_RESULTS_REDUCER,
  INITIAL_LOADING_REDUCER
} from '../../constants/reducer'
import { deleteItemById } from '../../helpers/modules/reducerHelpers'

const INITIAL_GET_TOKEN_REDUCER = {
  ...INITIAL_LOADING_REDUCER,
  token: '',
  wasLoaded: false
}

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  facebookPages: INITIAL_GET_RESULTS_REDUCER,
  accessibleFacebookPages: INITIAL_GET_RESULTS_REDUCER,
  addAccessibleFacebookPage: INITIAL_CREATE_REDUCER,
  deleteAccessibleFacebookPage: INITIAL_DELETE_REDUCER,
  facebookPageAccessTokens: {},
  createFacebookPageAccessRequest: INITIAL_CREATE_REDUCER,
  instagramAccounts: {},
  backedInstagramAccounts: {}
}

export default function socialAccounts(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    // facebook pages
    case GET_FACEBOOK_PAGES:
      return update(state, {
        facebookPages: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_FACEBOOK_PAGES_SUCCESS:
      return update(state, {
        facebookPages: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.choicesData
          }
        }
      })
    case GET_FACEBOOK_PAGES_FAILURE:
      return update(state, {
        facebookPages: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_FACEBOOK_PAGES:
      return update(state, {
        facebookPages: {
          $set: initialState.facebookPages
        }
      })

    // accessible facebook pages
    case GET_ACCESSIBLE_FACEBOOK_PAGES:
      return update(state, {
        accessibleFacebookPages: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_ACCESSIBLE_FACEBOOK_PAGES_SUCCESS:
      return update(state, {
        accessibleFacebookPages: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.pagesData.results
          }
        }
      })
    case GET_ACCESSIBLE_FACEBOOK_PAGES_FAILURE:
      return update(state, {
        accessibleFacebookPages: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_ACCESSIBLE_FACEBOOK_PAGES:
      return update(state, {
        accessibleFacebookPages: {
          $set: initialState.accessibleFacebookPages
        }
      })
    // POST accessible facebook pages
    case ADD_ACCESSIBLE_FACEBOOK_PAGES:
      return update(state, {
        addAccessibleFacebookPage: {
          $merge: {
            isLoading: true
          }
        }
      })
    case ADD_ACCESSIBLE_FACEBOOK_PAGES_SUCCESS:
      return update(state, {
        addAccessibleFacebookPage: {
          $merge: {
            isLoading: false,
            wasCreated: true
          }
        },
        accessibleFacebookPages: {
          results: { $unshift: [action.pageData] }
        }
      })
    case ADD_ACCESSIBLE_FACEBOOK_PAGES_FAILURE:
      return update(state, {
        addAccessibleFacebookPage: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_ADD_ACCESSIBLE_FACEBOOK_PAGES:
      return update(state, {
        addAccessibleFacebookPage: {
          $set: initialState.addAccessibleFacebookPage
        }
      })

    // DELETE accessible facebook pages
    case DELETE_ACCESSIBLE_FACEBOOK_PAGES:
      return update(state, {
        deleteAccessibleFacebookPage: {
          $merge: {
            isLoading: true,
            id: action.id
          }
        }
      })
    case DELETE_ACCESSIBLE_FACEBOOK_PAGES_SUCCESS:
      return update(state, {
        deleteAccessibleFacebookPage: {
          $merge: {
            isLoading: false,
            wasCreated: true
          }
        },
        accessibleFacebookPages: {
          results: { $apply: items => deleteItemById(items, action.id) }
        }
      })
    case DELETE_ACCESSIBLE_FACEBOOK_PAGES_FAILURE:
      return update(state, {
        deleteAccessibleFacebookPage: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DELETE_ACCESSIBLE_FACEBOOK_PAGES:
      return update(state, {
        deleteAccessibleFacebookPage: {
          $set: initialState.deleteAccessibleFacebookPage
        }
      })

    // facebook page access token
    case GET_FACEBOOK_PAGE_ACCESS_TOKEN:
      return update(state, {
        facebookPageAccessTokens: {
          $merge: {
            [action.facebookPageId]: {
              ...INITIAL_GET_TOKEN_REDUCER,
              isLoading: true
            }
          }
        }
      })
    case GET_FACEBOOK_PAGE_ACCESS_TOKEN_SUCCESS:
      return update(state, {
        facebookPageAccessTokens: {
          $merge: {
            [action.facebookPageId]: {
              isLoading: false,
              wasLoaded: true,
              token: action.accessToken
            }
          }
        }
      })
    case GET_FACEBOOK_PAGE_ACCESS_TOKEN_FAILURE:
      return update(state, {
        facebookPageAccessTokens: {
          $merge: {
            [action.facebookPageId]: {
              isLoading: false,
              error: action.error
            }
          }
        }
      })
    case CLEAR_FACEBOOK_PAGE_ACCESS_TOKEN:
      return update(state, {
        facebookPageAccessTokens: {
          $set: initialState.facebookPageAccessTokens
        }
      })

    // Create facebook page access request
    case CREATE_FACEBOOK_PAGE_ACCESS_REQUEST:
      return update(state, {
        createFacebookPageAccessRequest: { $merge: { isLoading: true } }
      })
    case CREATE_FACEBOOK_PAGE_ACCESS_REQUEST_SUCCESS:
      return update(state, {
        createFacebookPageAccessRequest: {
          $merge: {
            isLoading: false,
            wasCreated: true
          }
        }
      })
    case CREATE_FACEBOOK_PAGE_ACCESS_REQUEST_FAILURE:
      return update(state, {
        createFacebookPageAccessRequest: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_FACEBOOK_PAGE_ACCESS_REQUEST:
      return update(state, {
        createFacebookPageAccessRequest: { $set: initialState.createFacebookPageAccessRequest }
      })

    // instagram accounts
    case GET_INSTAGRAM_ACCOUNTS:
      return update(state, {
        instagramAccounts: {
          $merge: {
            [action.facebookPageId]: {
              ...INITIAL_GET_RESULTS_REDUCER,
              isLoading: true
            }
          }
        }
      })
    case GET_INSTAGRAM_ACCOUNTS_SUCCESS:
      return update(state, {
        instagramAccounts: {
          $merge: {
            [action.facebookPageId]: {
              isLoading: false,
              wasLoaded: true,
              results: action.accountsData
            }
          }
        }
      })
    case GET_INSTAGRAM_ACCOUNTS_FAILURE:
      return update(state, {
        instagramAccounts: {
          $merge: {
            [action.facebookPageId]: {
              isLoading: false,
              error: action.error
            }
          }
        }
      })
    case CLEAR_INSTAGRAM_ACCOUNTS:
      return update(state, {
        instagramAccounts: {
          $set: initialState.instagramAccounts
        }
      })
    // backed instagram accounts
    case GET_BACKED_INSTAGRAM_ACCOUNTS:
      return update(state, {
        backedInstagramAccounts: {
          $merge: {
            [action.facebookPageId]: {
              ...INITIAL_GET_RESULTS_REDUCER,
              isLoading: true
            }
          }
        }
      })
    case GET_BACKED_INSTAGRAM_ACCOUNTS_SUCCESS:
      return update(state, {
        backedInstagramAccounts: {
          $merge: {
            [action.facebookPageId]: {
              isLoading: false,
              wasLoaded: true,
              results: action.accountsData
            }
          }
        }
      })
    case GET_BACKED_INSTAGRAM_ACCOUNTS_FAILURE:
      return update(state, {
        backedInstagramAccounts: {
          $merge: {
            [action.facebookPageId]: {
              isLoading: false,
              error: action.error
            }
          }
        }
      })
    case CLEAR_BACKED_INSTAGRAM_ACCOUNTS:
      return update(state, {
        backedInstagramAccounts: {
          $set: initialState.backedInstagramAccounts
        }
      })
    default:
      return state
  }
}
