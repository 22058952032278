import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { selectedControllerIdSelector } from '../../../../modules/selectors/app'

import MultiSelectBox from '../../Form/MultiSelectBox'

import { formatOptionsList } from '../../../formatters'
import { createJsonFromQueryString } from '../../../../helpers/url'

import { getMediaProductLocationsService } from '../../../../modules/services/mediaOrdersProductLocations'

import { LOCATIONS } from '../../../../forms/Multiplatform/DiscountsForms/PriceChangePeriodForms/fields'

const LocationsPaginatedMultiSelect = ({ formik, limit = 20 }) => {
  const { values, setFieldValue, errors, touched } = formik
  const controllerId = useSelector(selectedControllerIdSelector)

  const formatOptions = useCallback(
    options =>
      formatOptionsList({
        list: options,
        valueName: 'id',
        labelName: 'name'
      }),
    []
  )

  const loadOptions = useCallback(
    async (search, loadedOptions, { next }) => {
      const response = await getMediaProductLocationsService({
        search,
        ordering: 'name',
        controller: controllerId,
        fields: ['id', 'name'].join(','),
        limit,
        ...createJsonFromQueryString(`?${next.split('?')[1]}`)
      })

      const newLocationsOptions = formatOptions(response.results)

      return {
        options: newLocationsOptions,
        hasMore: !!response.next,
        additional: {
          next: response.next
        }
      }
    },
    [controllerId, formatOptions, limit]
  )

  return (
    <MultiSelectBox
      placeholder="Select Location(s)"
      name={LOCATIONS}
      value={values[LOCATIONS]}
      error={errors[LOCATIONS]}
      touched={touched[LOCATIONS]}
      loadOptions={loadOptions}
      setFieldValue={setFieldValue}
      expandMenu={true}
      portaled={true}
    />
  )
}

LocationsPaginatedMultiSelect.propTypes = {
  formik: PropTypes.object.isRequired,
  defaultOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  limit: PropTypes.number
}

export default LocationsPaginatedMultiSelect
