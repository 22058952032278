import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classnames from 'classnames'

import ButtonIcon from '../../../Buttons/ButtonIcon'
import { ReactComponent as PencilIcon } from '../../../../../assets/icons/pencil.svg'

import useStyles from '../../../../../styles/common/drawerForms'

const SkeletonEditSection = () => {
  const classes = useStyles()

  return (
    <section className={classes.section}>
      <div className={classnames(classes.sectionTitle, classes.sectionPreviewTitle)}>
        <Skeleton width={166} />
      </div>
      <div className={classes.sectionPreview}>
        <p>
          <Skeleton width={133} />
        </p>
      </div>
      <ButtonIcon className={classes.sectionIcon} Icon={PencilIcon} />
    </section>
  )
}

export default SkeletonEditSection
