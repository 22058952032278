import React, { useCallback, useState } from 'react'

import CampaignTypeStep from './Steps/CampaignTypeStep'

import useStepFormRoutes from '../../../../../../hooks/formHooks/useStepFormRoutes'
import FirstRoute from './Routes/FirstRoute'
import AudienceTargetingRoute from './Routes/AudienceTargetingRoute'

import { useProgressItems } from './useProgressItem'

import { ONSITE_DIGITAL_FORMS } from '../../../../../../constants/forms'
import { CAMPAIGN_OPTION_NEW_CAMPAIGN } from './fields'

export const AUDIENCE_TARGETING = 'AUDIENCE_TARGETING'

function OnsiteDigitalFormsForm() {
  const manageStepFormRoutes = useStepFormRoutes({ initialRoute: CAMPAIGN_OPTION_NEW_CAMPAIGN })

  const selectedRoute = manageStepFormRoutes.selectedRoute
  const [chosenRoute, setChosenRoute] = useState(selectedRoute)

  const onChooseRoute = useCallback(
    route => {
      setChosenRoute(route)
    },
    [setChosenRoute]
  )

  const progressItems = useProgressItems(chosenRoute)

  const getSelectedRoute = props => {
    switch (selectedRoute) {
      case CAMPAIGN_OPTION_NEW_CAMPAIGN:
        return <FirstRoute {...props} />
      case AUDIENCE_TARGETING:
      default:
        return <AudienceTargetingRoute {...props} />
    }
  }

  return getSelectedRoute({
    formName: ONSITE_DIGITAL_FORMS,
    submitText: 'Save',
    FormRouteSelector: <CampaignTypeStep manageStepFormRoutes={manageStepFormRoutes} onChooseRoute={onChooseRoute} />,
    formProgressItems: progressItems
  })
}

export default OnsiteDigitalFormsForm
