import { getIn } from 'formik'

import {
  CLICK_THROUGH_LINK,
  ASSET_ID,
  DESCRIPTIONS_LIST,
  FILE_NAME,
  FILE_URL,
  HEADLINES_LIST,
  LANDSCAPE_IMAGE,
  LANDSCAPE_LOGO,
  LONG_HEADLINES_LIST,
  NAME,
  PORTRAIT_IMAGE,
  SQUARE_IMAGE,
  SQUARE_LOGO,
  VIDEO_ADS_ENTER,
  VIDEO_ADS_OPTION,
  VIDEO_ADS_URL,
  RESOURCE_NAME,
  FILE_TYPE
} from '../../fields'
import { BUSINESS_NAME } from '../../../../ReusableFormFields/LineItemForms/fields'
import { IS_ITEM_FORM_OPENED } from '../../../../Facebook/AdForms/fields'
import {
  CALL_TO_ACTION,
  CALL_TO_ACTION_LEARN_MORE,
  CALL_TO_ACTION_OPTION
} from '../../../../ReusableFormFields/CallToActionFields/fields'
import { googleAssetFieldType, googleCallToActionEnums } from '../../../../../constants/ads'

import { getKeyByValue } from '../../../../../helpers/common'

const getFormattedImageAssetData = assetData => {
  return {
    [FILE_NAME]: assetData?.name,
    [FILE_URL]: assetData?.image_asset?.full_size?.url,
    [ASSET_ID]: assetData?.resource_name
  }
}

const getFormattedTextAssetData = assetData => {
  return {
    id: assetData?.resource_name,
    text: assetData?.text_asset.text
  }
}

export const getImageAssetInitialValues = asset => ({
  [FILE_URL]: asset.asset_data?.image_asset?.full_size?.url,
  [FILE_NAME]: asset.asset_data?.name,
  [RESOURCE_NAME]: asset.resource_name,
  [FILE_TYPE]: 'image',
  [ASSET_ID]: asset.asset
})

export const getAssetGroupTransformedValues = assetGroup => {
  const assetGroupValues = {
    [NAME]: '',
    [BUSINESS_NAME]: '',
    [CLICK_THROUGH_LINK]: '', // CLICK TROUGH LINK
    [HEADLINES_LIST]: [],
    [DESCRIPTIONS_LIST]: [],
    [LONG_HEADLINES_LIST]: [],

    [VIDEO_ADS_OPTION]: VIDEO_ADS_ENTER,
    [IS_ITEM_FORM_OPENED]: true,

    [LANDSCAPE_IMAGE]: [],
    [SQUARE_IMAGE]: [],
    [PORTRAIT_IMAGE]: [],
    [SQUARE_LOGO]: [],
    [LANDSCAPE_LOGO]: [],
    [VIDEO_ADS_URL]: [],

    [CALL_TO_ACTION_OPTION]: CALL_TO_ACTION_LEARN_MORE,
    [CALL_TO_ACTION]: ''
  }

  if (!Object.keys(assetGroup).length) return assetGroupValues

  // name
  assetGroupValues[NAME] = assetGroup[NAME] // assetGroupData -> assetGroup
  // ad link
  assetGroupValues[CLICK_THROUGH_LINK] = assetGroup[CLICK_THROUGH_LINK][0] // assetGroupData -> assetGroup

  assetGroup.assets.forEach(assetGroupAsset => {
    // assetGroupData -> assetGroup
    const assetData = assetGroupAsset.asset_data
    // images
    if (assetGroupAsset.field_type === googleAssetFieldType.SQUARE_MARKETING_IMAGE) {
      assetGroupValues[SQUARE_IMAGE].push(getFormattedImageAssetData(assetData))
    }
    if (assetGroupAsset.field_type === googleAssetFieldType.MARKETING_IMAGE) {
      assetGroupValues[LANDSCAPE_IMAGE].push(getFormattedImageAssetData(assetData))
    }
    if (assetGroupAsset.field_type === googleAssetFieldType.PORTRAIT_MARKETING_IMAGE) {
      assetGroupValues[PORTRAIT_IMAGE].push(getFormattedImageAssetData(assetData))
    }
    if (assetGroupAsset.field_type === googleAssetFieldType.LOGO) {
      assetGroupValues[SQUARE_LOGO].push(getFormattedImageAssetData(assetData))
    }
    if (assetGroupAsset.field_type === googleAssetFieldType.LANDSCAPE_LOGO) {
      assetGroupValues[LANDSCAPE_LOGO].push(getFormattedImageAssetData(assetData))
    }

    // text fields
    if (assetGroupAsset.field_type === googleAssetFieldType.HEADLINE) {
      assetGroupValues[HEADLINES_LIST].push(getFormattedTextAssetData(assetData))
    }
    if (assetGroupAsset.field_type === googleAssetFieldType.DESCRIPTION) {
      assetGroupValues[DESCRIPTIONS_LIST].push(getFormattedTextAssetData(assetData))
    }
    if (assetGroupAsset.field_type === googleAssetFieldType.LONG_HEADLINE) {
      assetGroupValues[LONG_HEADLINES_LIST].push(getFormattedTextAssetData(assetData))
    }

    // youtube video ads
    if (assetGroupAsset.field_type === googleAssetFieldType.YOUTUBE_VIDEO) {
      const videoId = assetData?.youtube_video_asset?.youtube_video_id
      const videoUrl = 'https://www.youtube.com/watch?v=' + videoId
      assetGroupValues[VIDEO_ADS_URL].push({
        id: videoId,
        text: videoUrl
      })
    }

    // cta
    if (assetGroupAsset.field_type === googleAssetFieldType.CALL_TO_ACTION_SELECTION) {
      const ctaEnumValue = assetData?.call_to_action_asset?.call_to_action
      const cta = getKeyByValue(googleCallToActionEnums, ctaEnumValue)
      assetGroupValues[CALL_TO_ACTION] = cta
    }

    // business name
    if (assetGroupAsset.field_type === googleAssetFieldType.BUSINESS_NAME) {
      assetGroupValues[BUSINESS_NAME] = assetData?.text_asset?.text
    }
  })

  return assetGroupValues
}

export const isAllImageAssetsReady = (values, imageAssetName) => {
  const files = getIn(values, imageAssetName)

  // When user press ADD_ANOTHER button, we push object with empty fields to array, but uploading is not started yet.
  const filteredFiles = files.filter(item => item[FILE_NAME])

  // When uploading is finished, asset has ASSET_ID,
  // Check if every asset has ASSET_ID
  return filteredFiles.every(file => file[ASSET_ID])
}
