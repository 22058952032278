import {
  VIDEO_OPTION,
  VIDEO_THUMBNAIL_URL,
  VIDEO_UPLOAD_OPTION
} from '../../../AdTikTokCreate/AdTikTokCreateForm/fields'
import {
  AD_FILE,
  FILE_ID,
  FILE_NAME,
  FILE_TYPE,
  FILE_URL,
  MEDIA_FILE_UPLOAD,
  MEDIA_TYPE
} from '../../../../../ReusableFormFields/AdFileUpload/fields'
import { VIDEO } from '../../../fields'

export const formatValuesToFE = values => {
  return {
    // video file
    [VIDEO_OPTION]: VIDEO_UPLOAD_OPTION,
    [AD_FILE]: {},
    [FILE_NAME]: values['file_name'] || '',
    [FILE_URL]: values['url'] || '',
    [FILE_ID]: values['id'] || '',
    [FILE_TYPE]: 'video',
    [MEDIA_TYPE]: MEDIA_FILE_UPLOAD,
    [VIDEO_THUMBNAIL_URL]: values['poster_url'] || ''
  }
}

export const transformValuesToBE = values => {
  return {
    [VIDEO]: values[FILE_ID],
    image_ids: [],
    image_urls: [values[VIDEO_THUMBNAIL_URL]]
  }
}
