import React from 'react'

import GoogleAdPreviewDiscover1 from '../../../GoogleAssetsSliderPreview/Previews/GoogleAdPreviewDiscover1'
import GoogleAdPreviewSearch1 from '../../../GoogleAssetsSliderPreview/Previews/GoogleAdPreviewSearch1'

import {
  FILE_URL,
  landscapeImageHeight,
  landscapeImageWidth,
  portraitImageHeight,
  portraitImageWidth,
  squareImageHeight,
  squareImageWidth
} from '../../../fields'
import { ASSET_IMAGE_LIST } from '../../../GoogleAssetGroup/GoogleAssetGroupMainForm/fields'
import { landscapeRatio, portraitRatio } from '../../../validations'
import { getAssetsByRatio } from '../../../../UploadGoogleAdCreative/UploadGoogleAdCreativeForm/imageAssetsListValidationHelper'

const GooglePreviewsColumn = ({ values, showPortraitImage }) => {
  const imageAssets = values[ASSET_IMAGE_LIST]
  const filledImageAssets = imageAssets?.filter(({ file_url }) => file_url)

  const landscapeImageAssets = getAssetsByRatio(filledImageAssets, landscapeRatio)
  const squareImageAssets = getAssetsByRatio(filledImageAssets, 1)
  const portraitImageAssets = getAssetsByRatio(filledImageAssets, portraitRatio)

  const landscapeImageFileUrl = landscapeImageAssets[0]?.[FILE_URL]
  const squareImageFileUrl = squareImageAssets[0]?.[FILE_URL]
  const portraitImageFileUrl = portraitImageAssets[0]?.[FILE_URL]

  return (
    <div>
      <GoogleAdPreviewSearch1 values={values} />
      <GoogleAdPreviewDiscover1
        values={values}
        fileUrl={landscapeImageFileUrl}
        width={landscapeImageWidth}
        height={landscapeImageHeight}
      />
      <GoogleAdPreviewDiscover1
        values={values}
        fileUrl={squareImageFileUrl}
        width={squareImageWidth}
        height={squareImageHeight}
      />
      {showPortraitImage && (
        <GoogleAdPreviewDiscover1
          values={values}
          fileUrl={portraitImageFileUrl}
          width={portraitImageWidth}
          height={portraitImageHeight}
        />
      )}
    </div>
  )
}

export default GooglePreviewsColumn
