import { createStyles } from '../helpers'

import { lightGrey2 } from '../const/colors'
import { phonesDown, tabletDown } from '../const/breakpoints'

export default createStyles(theme => ({
  header: {
    fontSize: 24,
    marginTop: 0,
    marginBottom: 24,
    textTransform: 'capitalize'
  },
  subHeader: {
    fontSize: 18
  },
  sidebar: {
    borderRight: `1px solid ${lightGrey2}`,
    paddingRight: 40,
    minWidth: 360,
    flexBasis: 360,
    minHeight: '100vh'
  },
  content: {
    width: '100%',
    paddingLeft: 40,
    paddingBottom: 60,
    overflow: 'hidden'
  },
  itemLinksContainer: {
    display: 'grid',
    gap: 16,
    gridTemplateColumns: 'repeat(auto-fill, [col-start] minmax(200px, 200px) [col-end])',
    marginBottom: 40
  },
  section: {
    width: '100%',
    position: 'relative',
    background: 'white',
    border: `1px solid ${lightGrey2}`,
    borderRadius: theme.defaultBorderRadius,
    padding: '12px 14px',
    marginBottom: 16
  },
  sectionHeader: {
    margin: 0,
    fontSize: 18
  },
  sectionSubHeader: {
    fontSize: 14
  },
  [`@media screen and (${tabletDown})`]: {
    sidebar: {
      minWidth: 280,
      paddingRight: 30
    },
    content: {
      paddingLeft: 30
    }
  },
  [`@media screen and (${phonesDown})`]: {
    page: {
      flexDirection: 'column',
      paddingTop: 16
    },
    sidebar: {
      borderRight: `none`,
      paddingRight: 0,
      maxWidth: '100%',
      flexBasis: '100%',
      minHeight: '100%',
      marginBottom: 60
    },
    content: {
      paddingLeft: 0
    },
    itemLinksContainer: {
      gridTemplateColumns: '1fr 1fr',
      marginBottom: 32,
      gap: 12
    }
  }
}))
