import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../components/Button'
import Can from '../../../../../features/components/Can'
import DrawerHeadline from '../../../../../components/Drawer/DrawerHeadline'

import useRedirectFormDrawer from '../../../../../features/components/FormDrawerWrapper/useRedirectFormDrawer'

import { selectedSelfAccountSelector } from '../../../../../modules/selectors/app'

import { ROUTE_PARAMS, ROUTES } from '../../../../../constants/routes'

import useStyles from './styles'

const FacebookPagesMissingWarning = ({ hasDrawerPadding }) => {
  const { t } = useTranslation()
  const classes = useStyles({ hasDrawerPadding })

  const selfAccountId = useSelector(selectedSelfAccountSelector)

  const handleRedirect = useRedirectFormDrawer(ROUTES.pagesAndIdentities)
  const redirectToPages = useCallback(() => {
    handleRedirect({ [ROUTE_PARAMS.selfAccount]: selfAccountId })
  }, [handleRedirect, selfAccountId])

  return (
    <div className={classes.formContainer}>
      <DrawerHeadline title={t('Sorry, you are missing facebook pages')} />
      <p>{t('There are no Facebook pages')}</p>
      <div className={classes.warningFooter}>
        <Can I="create" a={ROUTES.pagesAndIdentities}>
          <Button className="btnBlock" onClick={redirectToPages}>
            {t('Add Facebook page')}
          </Button>
        </Can>
      </div>
    </div>
  )
}

export default FacebookPagesMissingWarning
