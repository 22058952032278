import * as Yup from 'yup'

import { startDateValidation, endDateValidation } from '../../../../ReusableFormFields/StartEndDateRangeFields/fields'

import {
  DATE_END,
  DATE_START,
  MEDIA_CATEGORIES,
  MEDIA_PRODUCTS,
  mediaCategoriesValidation,
  mediaProductsValidation,
  reusableValidation
} from '../../fields'
import { FIXED_DELIVERY_DATES_CHECKBOX, MEDIA_ITEMS_OPTION } from './fields'

export const validationSchema = Yup.object({
  ...reusableValidation,
  [DATE_START]: Yup.string().when(FIXED_DELIVERY_DATES_CHECKBOX, {
    is: true,
    then: () => startDateValidation
  }),
  [DATE_END]: Yup.string().when(FIXED_DELIVERY_DATES_CHECKBOX, {
    is: true,
    then: () => endDateValidation
  }),
  [MEDIA_CATEGORIES]: Yup.array().when(MEDIA_ITEMS_OPTION, {
    is: MEDIA_CATEGORIES,
    then: () => mediaCategoriesValidation
  }),
  [MEDIA_PRODUCTS]: Yup.array().when(MEDIA_ITEMS_OPTION, {
    is: MEDIA_PRODUCTS,
    then: () => mediaProductsValidation
  })
})
