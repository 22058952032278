import React from 'react'
import PropTypes from 'prop-types'

import { getDescriptionData } from '../HeadlinesSection/helpers'

const DescriptionsSectionPreview = ({ data }) => {
  const { assets } = data
  const descriptionData = getDescriptionData(assets)

  if (!descriptionData || !descriptionData.length) {
    return null
  }

  return (
    <p>
      {descriptionData.map(({ asset_data }, index) => (
        <span key={index}>{asset_data.text_asset.text}</span>
      ))}
    </p>
  )
}

DescriptionsSectionPreview.propTypes = {
  data: PropTypes.object
}

export default DescriptionsSectionPreview
