import { createStyles } from '../../../../../../../../styles/helpers'
import { darkGrey, mediumGrey } from '../../../../../../../../styles/const/colors'

export default createStyles({
  product: {
    paddingBottom: '16px',

    '&:not(:last-of-type)': {
      marginBottom: '16px',
      borderBottom: `1px solid ${mediumGrey}`
    }
  },
  productDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  subProductsList: {
    color: darkGrey,
    fontSize: 12,
    marginTop: 16
  }
})
