import { concat } from '../../helpers/common'

const MODULE_NAME = 'AUTH/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const RESEND_REGISTER = concat(MODULE_NAME, 'RESEND_REGISTER')
export const RESEND_REGISTER_SUCCESS = concat(MODULE_NAME, 'RESEND_REGISTER_SUCCESS')
export const RESEND_REGISTER_FAILURE = concat(MODULE_NAME, 'RESEND_REGISTER_SUCCESS_FAILURE')

export const CLEAR_AUTH_STORE = concat(MODULE_NAME, 'CLEAR_AUTH_STORE')

export const SOCIAL_AUTH = concat(MODULE_NAME, 'SOCIAL_AUTH')
export const SOCIAL_AUTH_SUCCESS = concat(MODULE_NAME, 'SOCIAL_AUTH_SUCCESS')
export const SOCIAL_AUTH_FAILURE = concat(MODULE_NAME, 'SOCIAL_AUTH_FAILURE')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

export function resendRegister(resendData, showToastAfterSuccess) {
  return { type: RESEND_REGISTER, resendData, showToastAfterSuccess }
}

export function resendRegisterSuccess() {
  return { type: RESEND_REGISTER_SUCCESS }
}

export function resendRegisterFailure(error) {
  return { type: RESEND_REGISTER_FAILURE, error }
}

export function clearAuthStore() {
  return { type: CLEAR_AUTH_STORE }
}

export function socialAuth(params) {
  return { type: SOCIAL_AUTH, params }
}

export function socialAuthSuccess(data) {
  return { type: SOCIAL_AUTH_SUCCESS, data }
}

export function socialAuthFailure(error) {
  return { type: SOCIAL_AUTH_FAILURE, error }
}
