import { createStyles } from '../../../../../../../../../styles/helpers'

const useStyles = createStyles({
  startDateField: {
    minWidth: 145,
    maxWidth: 145
  }
})

export default useStyles
