import history from '../../history'
import { concat } from '../../helpers/common'
import { URL_FORM_PARAMS } from '../../constants/forms'

const MODULE_NAME = 'FORMS/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const OPEN_FORM = concat(MODULE_NAME, 'OPEN_FORM')
export const CLOSE_FORM = concat(MODULE_NAME, 'CLOSE_FORM')
export const SET_FORM_MINIMIZE = concat(MODULE_NAME, 'SET_FORM_MINIMIZE')

export const SET_FORM_STEP = concat(MODULE_NAME, 'SET_FORM_STEP')
export const SET_FORM_STEP_NEXT = concat(MODULE_NAME, 'SET_FORM_STEP_NEXT')
export const SET_FORM_STEP_PREVIOUS = concat(MODULE_NAME, 'SET_FORM_STEP_PREVIOUS')

export const SET_FORM_SUCCESS_SUBMIT = concat(MODULE_NAME, 'SET_FORM_SUCCESS_SUBMIT')
export const SET_FORM_SUBMITTING = concat(MODULE_NAME, 'SET_FORM_SUBMITTING')
export const CLEAR_FORM_SUCCESS_SUBMIT = concat(MODULE_NAME, 'CLEAR_FORM_SUCCESS_SUBMIT')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

export function openForm({ id, formName, otherParams = '' }) {
  if (id || id === 0) {
    // set form name and id in query params
    history.replace({
      search: `${URL_FORM_PARAMS.FORM_NAME}=${formName}&${URL_FORM_PARAMS.ITEM_ID}=${id}${
        otherParams && `&${otherParams}`
      }`
    })
  } else {
    // set form name in query params
    history.replace({
      search: `${URL_FORM_PARAMS.FORM_NAME}=${formName}${otherParams && `&${otherParams}`}`
    })
  }

  return { type: OPEN_FORM, id, formName }
}

export function closeForm() {
  // clear query params
  history.replace({
    search: ''
  })

  return { type: CLOSE_FORM }
}

export function setFormMinimize(isMinimized) {
  return { type: SET_FORM_MINIMIZE, isMinimized }
}

export function setStep(newStep) {
  return { type: SET_FORM_STEP, newStep }
}

export function setFormStepNext() {
  return { type: SET_FORM_STEP_NEXT }
}

export function setFormStepPrevious() {
  return { type: SET_FORM_STEP_PREVIOUS }
}

export function setFormSuccessSubmit(formName) {
  return { type: SET_FORM_SUCCESS_SUBMIT, formName }
}

export function setFormIsSubmitting(formName, isSubmitting) {
  return { type: SET_FORM_SUBMITTING, formName, isSubmitting }
}

export function clearFormSuccessSubmit(formName) {
  return { type: CLEAR_FORM_SUCCESS_SUBMIT, formName }
}
