import React from 'react'
import { useTranslation } from 'react-i18next'

import AgencyInviteCreateForm from './AgencyInviteCreateForm'
import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'

import { AGENCY_INVITE_CREATE } from '../../../../constants/forms'

const AgencyInviteFormCreate = ({ agency }) => {
  const { t } = useTranslation()

  return (
    <FormDrawerWrapper formName={AGENCY_INVITE_CREATE} title={t('Add new member')} openButtonText={t('New User')}>
      <AgencyInviteCreateForm agency={agency} />
    </FormDrawerWrapper>
  )
}

export default AgencyInviteFormCreate
