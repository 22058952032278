import { createStyles } from '../../../../../styles/helpers'
import { black, red } from '../../../../../styles/const/colors'

export default createStyles({
  alertIcon: {
    color: red
  },
  headline: {
    color: black,
    margin: '16px 0'
  },
  modalContent: {
    display: 'block'
  },
  modalBody: {
    padding: '20px 0'
  }
})
