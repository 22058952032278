import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Step from '../../../../../../../../../../../features/components/Forms/StepForm/Step'
import Field from '../../../../../../../../../../../components/Form/Field'
import RadioBlock from '../../../../../../../../../../../components/Form/RadioBlock'

import { BUDGET_PERCENTAGE, BUDGET_PERCENTAGE_OPTION } from '../../fields'
import { OPTION_NO, OPTION_YES } from '../../../../../../../../../../../constants/forms'

import useStyles from './styles'

const stepFields = [BUDGET_PERCENTAGE]

const BudgetPercentageStep = ({ formik, handleStepChange }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const { values, setFieldValue } = formik

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  return (
    <Step
      formTitle="Launch an off-network campaign"
      stepTitle="What is the maximum percentage of your budget that you want to allocate to existing customers?"
      handleContinue={handleContinue}
    >
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_option_budget_percentage_no"
        name={BUDGET_PERCENTAGE_OPTION}
        label="Don't set a maximum"
        value={OPTION_NO}
        selectedValue={values[BUDGET_PERCENTAGE_OPTION]}
      />
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_option_budget_percentage_yes"
        name={BUDGET_PERCENTAGE_OPTION}
        label="Specify a maximum percentage"
        value={OPTION_YES}
        selectedValue={values[BUDGET_PERCENTAGE_OPTION]}
      >
        <p>
          {t(
            'Here you specify the maximum percentage of the budget that can be spent on the existing customers associated with this ad account. Lower values may lead to higher costs per conversion.'
          )}
        </p>
        <Field
          formik={formik}
          name={BUDGET_PERCENTAGE}
          className={classes.percentageField}
          type="number"
          placeholder="Percentage"
          autoComplete="off"
          symbol="%"
          symbolPosition="end"
          min={0}
          max={100}
          decimalScale={2}
        />
      </RadioBlock>
    </Step>
  )
}

export default BudgetPercentageStep
