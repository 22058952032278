import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import PropTypes from 'prop-types'

import ErrorMessage from '../../../components/Form/ErrorMessage'
import FileDetailsPreview from '../../../components/FileDetailsPreview'
import FileUploadInput from '../../../components/Form/FileUploadInput'
import DropFileUploader from '../../../components/Form/DropFileUploader'
import DropFileUploaderProgress from '../../../components/Form/DropFileUploader/DropFileUploaderProgress'
import ProgressBar from '../../../components/ProgressBar'

import { getFileFormat, getFileType } from '../../../features/helpers/other'
import useFileUpload from '../../../features/hooks/useFileUpload'
import { showToasts } from '../../../helpers/toasts'

import { fileUpload } from '../../../modules/actions/files'

import { TOAST_TYPE } from '../../../constants/other'

import { tabletDownSize } from '../../../styles/const/breakpoints'

const SingleFileUpload = ({
  fileName,
  fileURL,
  onFileRemove,
  onFileUploaded,
  onStartFileUploading,
  error,
  maxSize,
  accept,
  fileValidationHandler,
  fileUploadOptions,
  buttonText,
  isDraggable = true,
  containerClassName,
  onPreviewClick,
  isImagePreview
}) => {
  const dispatch = useDispatch()

  const {
    selectedFile,
    setSelectedFile,
    fileURLBlob,
    setFileURLBlob,
    isFileLoading,
    fileIsUploaded,
    fileUploadProgress,
    handleRemoveFile
  } = useFileUpload({
    onFileRemove,
    onFileUploaded
  })
  const showPreview = fileIsUploaded || fileURL

  const isTablet = useMediaQuery({ maxWidth: tabletDownSize })

  const isDragAndDrop = isDraggable && !isTablet

  const startFileUploading = useCallback(
    file => {
      dispatch(fileUpload(file, fileUploadOptions))

      setSelectedFile(file)
      onStartFileUploading && onStartFileUploading(file)
      setFileURLBlob(URL.createObjectURL(file))
    },
    [dispatch, onStartFileUploading, fileUploadOptions, setFileURLBlob, setSelectedFile]
  )

  const handleFileChange = useCallback(
    file => {
      if (fileValidationHandler) {
        fileValidationHandler(file)
          .then(() => startFileUploading(file))
          .catch(error => {
            showToasts({
              type: TOAST_TYPE.error,
              message: error.message
            })
          })
      } else {
        startFileUploading(file)
      }
    },
    [fileValidationHandler, startFileUploading]
  )

  const filePreviewType = useMemo(() => selectedFile.type && getFileType(selectedFile), [selectedFile])
  const fileFormat = useMemo(() => selectedFile.name && getFileFormat(selectedFile), [selectedFile])

  if (isFileLoading) {
    return isDragAndDrop ? (
      <DropFileUploaderProgress fileUploadProgress={fileUploadProgress} />
    ) : (
      <ProgressBar value={fileUploadProgress || 0} />
    )
  }

  if (showPreview) {
    return (
      <FileDetailsPreview
        key={selectedFile.name || fileName}
        fileName={selectedFile.name || fileName}
        isFileLoading={isFileLoading}
        fileUploadProgress={fileUploadProgress}
        previewUrl={fileURLBlob || fileURL}
        allowDelete={!!fileURL}
        fileFormat={fileFormat}
        filePreviewType={filePreviewType}
        removeFile={handleRemoveFile}
        isImagePreview={isImagePreview}
        onClick={onPreviewClick}
      />
    )
  }

  return (
    <>
      {isDragAndDrop ? (
        <DropFileUploader
          accept={accept}
          maxSize={maxSize}
          onUpload={handleFileChange}
          containerClassName={containerClassName}
        />
      ) : (
        <FileUploadInput
          name="single_file_upload"
          buttonText={buttonText}
          onFileUpload={handleFileChange}
          accept={accept}
          maxSize={maxSize}
        />
      )}
      {error && <ErrorMessage error={error} />}
    </>
  )
}

SingleFileUpload.propTypes = {
  fileName: PropTypes.string,
  fileURL: PropTypes.string,
  onFileRemove: PropTypes.func,
  onFileUploaded: PropTypes.func,
  error: PropTypes.string,
  maxSize: PropTypes.number,
  accept: PropTypes.string,
  buttonText: PropTypes.string,
  fileValidationHandler: PropTypes.func,
  fileUploadOptions: PropTypes.object,
  isDraggable: PropTypes.bool,
  isImagePreview: PropTypes.bool,
  containerClassName: PropTypes.string
}

export default SingleFileUpload
