import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../components/Button'
import { ReactComponent as InfoIcon } from '../../../../../../assets/icons/info-circle.svg'

import { getRandomizedValues } from '../helpers'

import { BUSINESS_NAME, DESCRIPTIONS_LIST, HEADLINES_LIST } from '../../../fields'

import useStyles from './styles'

const GoogleAdPreviewDisplay2 = React.memo(({ values = {}, fileUrl }) => {
  const { t } = useTranslation()

  const classes = useStyles()

  const randomizedValues = useMemo(() => {
    return getRandomizedValues(values)
  }, [values])

  const description = randomizedValues[DESCRIPTIONS_LIST]?.[0].text
  const headline = randomizedValues[HEADLINES_LIST]?.[0].text
  const businessName = randomizedValues[BUSINESS_NAME]

  return (
    <div className={classes.adPreview}>
      <div className={classes.icon}>
        <InfoIcon />
      </div>
      <div className={classes.top}>
        <img className={classes.image} src={fileUrl} alt="Uploaded asset" />
        <p className={classes.headline}>{headline}</p>
      </div>
      <p className={classes.description}>{description}</p>
      <p className={classes.businessName}>{businessName}</p>
      <Button className={classes.button}>{t('Learn More')}</Button>
    </div>
  )
})

export default GoogleAdPreviewDisplay2
