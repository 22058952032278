import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  activeContractSelector,
  contractWasLoadedSelector,
  deleteContractSignatureIsLoadingSelector,
  getContractIsLoadingSelector
} from '../../../modules/selectors/contracts'
import { clearActiveContract, clearGetContract, getContract } from '../../../modules/actions/contracts'

export default function useQuotationData(contractId, tokenParams) {
  const dispatch = useDispatch()

  // as we are sign contract in ManageContract we need to use activeContractSelector which represent the latest version of contract
  const quotationContract = useSelector(activeContractSelector)
  const contractIsLoading = useSelector(getContractIsLoadingSelector)
  const contractWasLoaded = useSelector(contractWasLoadedSelector)
  const deleteContractSignatureIsLoading = useSelector(deleteContractSignatureIsLoadingSelector)

  const isFormLoading = contractIsLoading || !contractWasLoaded || deleteContractSignatureIsLoading

  const handleClearSelectedContract = useCallback(() => {
    dispatch(clearActiveContract())
    dispatch(clearGetContract())
  }, [dispatch])

  useEffect(() => {
    if (contractId) {
      dispatch(getContract(contractId, tokenParams))
    }
  }, [dispatch, contractId, tokenParams])

  return useMemo(
    () => ({
      quotationContract,
      isDataLoading: isFormLoading,
      handleClearSelectedContract
    }),
    [quotationContract, isFormLoading, handleClearSelectedContract]
  )
}
