import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getLineItemsSummaryReportsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.lineItemsReportsBreakdown
  })
}
