import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Step from '../../../../../../../../../../../features/components/Forms/StepForm/Step'
import Field from '../../../../../../../../../../../components/Form/Field'

import { selectedSelfAccountDataSelector } from '../../../../../../../../../../../modules/selectors/app'

import { BUDGET_LIFETIME } from '../../fields'

import useDrawerFormStyles from '../../../../../../../../../../../styles/common/drawerForms'

const stepFields = [BUDGET_LIFETIME]

const BudgetStep = ({ formik, handleStepChange }) => {
  const drawerFormClasses = useDrawerFormStyles()

  const { t } = useTranslation()

  const { currency_symbol: currencySymbol } = useSelector(selectedSelfAccountDataSelector)

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  return (
    <Step
      formTitle="Launch an off-network campaign"
      stepTitle="What budget would you like to set for new ad set?"
      handleContinue={handleContinue}
    >
      <section className={drawerFormClasses.section}>
        <h4 className={drawerFormClasses.sectionTitle}>{t('Lifetime Budget')}</h4>
        <p>{t('A total budget for the entire run-time of the ad set.')}</p>
        <p>{t('The budget will be spent evenly over its lifetime.')}</p>
        <Field
          type="number"
          formik={formik}
          name={BUDGET_LIFETIME}
          placeholder={t('Lifetime Budget')}
          symbol={currencySymbol}
          autoComplete="off"
          inputMode="numeric"
        />
      </section>
    </Step>
  )
}

export default BudgetStep
