import * as Yup from 'yup'
import { add, isAfter } from 'date-fns'

import { combineDateAndTime, formatDateWithTimeToBE, getDateFromTomorrowByDays } from '../../../helpers/date'

import { getTimezoneDate } from '../../../constants/dates'
import { OPTION_CUSTOM } from '../../../constants/forms'
import { getSelectedAdAccountTimezone } from '../../../modules/selectors/app'

export const START_OPTION = 'start_option'
export const START_OPTION_NOW = 'start_option_now'

export const START_DATE = 'date_start'
export const START_TIME = 'start_time'

export const startTimeInitialValues = {
  [START_OPTION]: START_OPTION_NOW,
  [START_DATE]: getDateFromTomorrowByDays(1),
  [START_TIME]: '00:00'
}

export const startTimeValidation = () => {
  const getTimezoneErrorMessage = () => {
    const timezone = getSelectedAdAccountTimezone()
    return (
      'Please select a date and time at least 15 minutes in the future' +
      (timezone ? ` in the ${timezone} timezone` : '')
    )
  }

  return {
    [START_TIME]: Yup.string().when(START_OPTION, {
      is: OPTION_CUSTOM,
      then: () =>
        Yup.string()
          .required('Start time is required')
          .test(
            START_TIME,
            getTimezoneErrorMessage(),
            // check if the time is in the past, as well as at least 15 minutes in the future
            // the check should be done within SelectedAdAccount timezone
            (value, { parent }) => {
              const currentAdAccountTimezoneTime = getTimezoneDate(new Date())
              const selectedTime = add(combineDateAndTime(parent[START_DATE], value), { minutes: -15 })

              return isAfter(selectedTime, currentAdAccountTimezoneTime)
            }
          )
    })
  }
}

export const formatTimeStartToBE = ({ startOption, startDate, startTime }) => {
  if (startOption === START_OPTION_NOW) {
    // add one minute for BE handling time to be not in the past
    return formatDateWithTimeToBE(add(new Date(), { minutes: 1 }))
  } else {
    return formatDateWithTimeToBE(combineDateAndTime(startDate, startTime))
  }
}
