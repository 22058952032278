import React from 'react'
import { tableColumnsSize } from '../index'
import { formatCurrency } from '../../../../../../../../helpers/numbers'

const generateYears = brandRevenueReport => {
  // take min and max years from brandRevenueReport
  // and then generate list for years between min and max
  const minYear = Math.min(
    ...brandRevenueReport.map(brand => Math.min(...brand.revenue_breakdown.map(revenue => revenue.period)))
  )
  const maxYear = Math.max(
    ...brandRevenueReport.map(brand => Math.max(...brand.revenue_breakdown.map(revenue => revenue.period)))
  )

  // Generate an array of years between minYear and maxYear (inclusive)
  const years = []
  for (let year = minYear; year <= maxYear; year++) {
    years.push(year)
  }

  return years
}

export const generateTableColumns = ({
  brandRevenueReport,
  classes,
  revenueTablesClasses,
  controllerCurrencySymbol
}) => {
  const generatedColumns = generateYears(brandRevenueReport)

  return generatedColumns.map(year => {
    return {
      header: year?.toString(),
      Cell: data => {
        const columnData = data.columns[year]

        if (columnData) {
          return (
            <div className={classes.yearRevenueCol}>
              {formatCurrency(columnData.total_revenue, { min: 0, max: 0 }, { symbol: controllerCurrencySymbol })}
            </div>
          )
        } else {
          return null
        }
      },
      headClassName: revenueTablesClasses.revenueCol,
      className: revenueTablesClasses.revenueCol,
      style: { maxWidth: tableColumnsSize.year }
    }
  })
}
