import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// import Button from '../../../../../components/Button'
import Item from './Item'

import { formatCurrency } from '../../../../../helpers/numbers'

import { selectedControllerDataSelector } from '../../../../../modules/selectors/app'

import useStyles from './styles'

function InvoiceInformationSection({ currentStatement }) {
  const { t } = useTranslation()

  const classes = useStyles()

  const selectedSelfAccountController = useSelector(selectedControllerDataSelector)

  const formattedTotal = formatCurrency(
    currentStatement?.total,
    { min: 2, max: 2 },
    { symbol: selectedSelfAccountController?.currency_symbol }
  )

  return (
    <div className={classes.container}>
      <div className={classes.formHeader}>
        <div className={classes.formHeaderText}>{currentStatement.name}</div>
        {/* Add when backend will be updated */}
        {/*<Button solid>{t('Download PDF')}</Button>*/}
      </div>

      <div className={classes.itemsContainer}>
        {!!currentStatement?.media_orders?.length &&
          currentStatement.media_orders.map(mediaOrder => <Item item={mediaOrder} key={mediaOrder.id} />)}
      </div>

      {currentStatement?.total && (
        <div className={classes.totalContainer}>
          <div className={classes.totalText}>{t('Total (excl. GST)')}</div>
          <div className={classes.total}>{formattedTotal}</div>
        </div>
      )}
    </div>
  )
}

export default InvoiceInformationSection
