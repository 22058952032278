import React, { useMemo } from 'react'
import useStyles from '../../../../../features/hooks/useMembersAndInvitesColumns/styles'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Table from '../../../../../components/Table'

import { useAgencyMemberActions } from './useAgencyMemberActions'
import { useMembersAndInvitesColumns } from '../../../../../features/hooks/useMembersAndInvitesColumns'

import {
  agencyInvitesSelector,
  agencyMembersSelector,
  deleteAgencyInvitationIsLoadingSelector,
  deletedAgencyInvitationSelector,
  updateAgencyMemberIsLoadingSelector,
  updatedAgencyMemberSelector
} from '../../../../../modules/selectors/agencyTeam'

const AgencyMembersAndInvitesTableData = ({ setMemberId, openModalHandler }) => {
  const classes = useStyles()

  const { id: memberId } = useSelector(updatedAgencyMemberSelector)
  const { id: inviteId } = useSelector(deletedAgencyInvitationSelector)
  const updatedAgencyMemberIsLoading = useSelector(updateAgencyMemberIsLoadingSelector)
  const deletedAgencyInviteIsLoading = useSelector(deleteAgencyInvitationIsLoadingSelector)

  const itemUpdatingId = useMemo(() => {
    if (updatedAgencyMemberIsLoading) {
      return memberId
    } else if (deletedAgencyInviteIsLoading) {
      return inviteId
    }

    return undefined
  }, [memberId, updatedAgencyMemberIsLoading, inviteId, deletedAgencyInviteIsLoading])

  const agencyMembers = useSelector(agencyMembersSelector)
  const agencyInvites = useSelector(agencyInvitesSelector)
  const agencyMembersAndInvites = useMemo(() => [...agencyMembers, ...agencyInvites], [agencyMembers, agencyInvites])

  const agencyUserActions = useAgencyMemberActions({ agencyMembersAndInvites, setMemberId, openModalHandler })

  const membersAndInvitesColumns = useMembersAndInvitesColumns({
    membersAndInvites: agencyMembersAndInvites,
    onChangeMemberStatus: agencyUserActions.changeMemberStatusHandler,
    onChangeMemberRole: agencyUserActions.changeMemberRoleHandler,
    onDeleteInvite: agencyUserActions.deleteInviteHandler,
    onResendInvite: agencyUserActions.resendInviteHandler,
    getAdditionalActions: agencyUserActions.getAdditionalAgencyMemberActions
  })

  return (
    <Table
      mobileColumnsWrapperClassName={classes.mobileHeaderStyle}
      hideFooterRow
      data={agencyMembersAndInvites}
      cols={membersAndInvitesColumns}
      itemUpdatingId={itemUpdatingId}
    />
  )
}

AgencyMembersAndInvitesTableData.propTypes = {
  setMemberId: PropTypes.func.isRequired,
  openModalHandler: PropTypes.func.isRequired
}

export default AgencyMembersAndInvitesTableData
