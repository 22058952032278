import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import MultiSelectBox from '../../../../../../../../features/components/Form/MultiSelectBox'
import Checkbox from '../../../../../../../../components/Form/Checkbox'
import ErrorMessage from '../../../../../../../../components/Form/ErrorMessage'

import { formatOptionsList } from '../../../../../../../../features/formatters'

import { selectedAdAccountIdSelector } from '../../../../../../../../modules/selectors/app'
import { audiencesLoadingSelector, audiencesSelector } from '../../../../../../../../modules/selectors/audiences'
import { clearAudiences, getAudiences } from '../../../../../../../../modules/actions/audiences'

import { FACEBOOK_PLATFORM } from '../../../../../../../../constants/selectLists/platformList'
import {
  CUSTOM_AUDIENCES,
  CUSTOM_AUDIENCES_EXCLUSION,
  CUSTOM_AUDIENCES_EXCLUSION_CHECKED
} from '../../../../../../../ReusableFormFields/CustomAudiencesFields/fields'

import useStyles from './styles'

const CustomAudienceFields = ({ formik, adAccountId }) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { values, setFieldValue, errors, touched } = formik

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const audiences = useSelector(audiencesSelector)
  const audiencesIsLoading = useSelector(audiencesLoadingSelector)

  const formattedAudiences = useMemo(
    () =>
      formatOptionsList({
        list: audiences,
        valueName: 'id',
        labelName: 'name'
      }),
    [audiences]
  )

  useEffect(() => {
    dispatch(
      getAudiences(
        {
          account: adAccountId || selectedAdAccountId,
          limit: 25
        },
        FACEBOOK_PLATFORM
      )
    )
  }, [dispatch, adAccountId, selectedAdAccountId])

  useEffect(
    () => () => {
      dispatch(clearAudiences())
    },
    [dispatch]
  )

  return (
    <>
      <MultiSelectBox
        className={classes.selectAudienceInput}
        placeholder={t('Add an audience')}
        options={formattedAudiences}
        name={CUSTOM_AUDIENCES}
        value={values[CUSTOM_AUDIENCES]}
        isLoading={audiencesIsLoading}
        setFieldValue={setFieldValue}
      />
      <Checkbox
        className={classes.checkbox}
        id="checkbox_exclude_audiences"
        title={t('Exclude an audience')}
        checked={values[CUSTOM_AUDIENCES_EXCLUSION_CHECKED]}
        onCheck={() => {
          setFieldValue(CUSTOM_AUDIENCES_EXCLUSION_CHECKED, !values[CUSTOM_AUDIENCES_EXCLUSION_CHECKED])
        }}
      />
      {values[CUSTOM_AUDIENCES_EXCLUSION_CHECKED] && (
        <>
          <MultiSelectBox
            className={classes.selectAudienceInput}
            placeholder={t('Exclude an audience')}
            options={formattedAudiences}
            value={values[CUSTOM_AUDIENCES_EXCLUSION]}
            name={CUSTOM_AUDIENCES_EXCLUSION}
            isLoading={audiencesIsLoading}
            setFieldValue={setFieldValue}
          />
          {errors[CUSTOM_AUDIENCES_EXCLUSION] && touched[CUSTOM_AUDIENCES_EXCLUSION] && (
            <ErrorMessage error={errors[CUSTOM_AUDIENCES_EXCLUSION]} />
          )}
        </>
      )}
    </>
  )
}

export default CustomAudienceFields
