import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import TypeWriteAnimation from '../../../TypeWriteAnimation'
import SkeletonDescriptionText from './Skeleton'

import useStyles from './styles'

function DescriptionText({
  descriptionText,
  showSkeletonPlaceholder,
  isInProgress,
  className,
  skeletonClassName,
  typingAnimation
}) {
  const classes = useStyles()

  if (!descriptionText && !showSkeletonPlaceholder) {
    return null
  }

  if (!descriptionText && showSkeletonPlaceholder) {
    return <SkeletonDescriptionText isInProgress={isInProgress} className={skeletonClassName} />
  }

  return (
    <div className={classnames(classes.descriptionText, className)}>
      {typingAnimation ? <TypeWriteAnimation text={descriptionText} delay={40} /> : descriptionText}
    </div>
  )
}

DescriptionText.propTypes = {
  descriptionText: PropTypes.string,
  isInProgress: PropTypes.bool,
  className: PropTypes.string,
  skeletonClassName: PropTypes.string,
  typingAnimation: PropTypes.bool
}

export default DescriptionText
