import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { ReactComponent as IconTrash } from '../../../../assets/icons/trash-icon.svg'

import useStyles from './styles'

function ButtonTrash({ className, onClick }) {
  const classes = useStyles()

  return <IconTrash onClick={onClick} className={classnames(classes.btnTrash, className)} />
}

ButtonTrash.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
}

export default ButtonTrash
