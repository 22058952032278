import { useCallback, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Icon from '../../../../../../components/Icon'
import { ReactComponent as PlayIcon } from '../../../../../../assets/icons/play-icon3.svg'
import { ReactComponent as PauseIcon } from '../../../../../../assets/icons/pause-icon.svg'

import { getFacebookVideoPicture } from './helpers'

import {
  facebookImageUrlsSelector,
  facebookRequestedVideoIdsSelector,
  facebookVideoUrlsSelector
} from '../../../../../../modules/selectors/mediaFiles'
import { getFacebookVideoUrls } from '../../../../../../modules/actions/mediaFiles'
import { selectedFacebookAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { FILE_ID } from '../../../../../../forms/Facebook/AdForms/fields'
import { FILE_NAME, FILE_URL } from '../../../../../../forms/ReusableFormFields/AdFileUpload/fields'

import useStyles from './styles'

const FacebookVideoPreview = ({
  viewAdOnFacebook,
  className = '',
  videoPictureClassName,
  mediaFile = {},
  onLoad,
  pauseFromOutside = false,
  showControls = false
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const imageUrls = useSelector(facebookImageUrlsSelector)
  const videoUrls = useSelector(facebookVideoUrlsSelector)
  const selectedAdAccountId = useSelector(selectedFacebookAdAccountIdSelector)
  const requestedVideoIds = useSelector(facebookRequestedVideoIdsSelector)

  const videoRef = useRef(null)

  const videoPicture = getFacebookVideoPicture(mediaFile, imageUrls)
  const { [FILE_URL]: mediaUrl, [FILE_NAME]: fileName } = mediaFile
  const requestedVideoUrlData = videoUrls.find(video => video.id === mediaFile[FILE_ID])

  // Initially we don't show video element, and show video picture instead
  const [showVideoElement, setShowVideoElement] = useState(false)
  const [isVideoLoaded, setIsVideoLoaded] = useState(false)
  const [isPaused, setIsPaused] = useState(false)

  const videoOnLoadHandler = useCallback(() => {
    setIsVideoLoaded(true)

    onLoad && onLoad()
  }, [onLoad, setIsVideoLoaded])

  const togglePlay = useCallback(() => {
    const video = videoRef?.current
    if (video) {
      if (isPaused) {
        video.play()
        setIsPaused(false)
      } else {
        video.pause()
        setIsPaused(true)
      }
    }
  }, [isPaused])

  const onInitialPlayPress = useCallback(() => {
    setShowVideoElement(true)
    // Usually we don't fetch video info, and represent videoPicture instead,
    // we fetch video info only when users press "play".
    // (There is one exception, we also fetch video info in edit ad form to be able to represent fileName)
    if (!requestedVideoIds.includes(mediaFile[FILE_ID])) {
      dispatch(getFacebookVideoUrls({ account: selectedAdAccountId, video_ids: [mediaFile[FILE_ID]] }))
    }
  }, [dispatch, selectedAdAccountId, setShowVideoElement, requestedVideoIds, mediaFile])

  useEffect(() => {
    if (showVideoElement && pauseFromOutside && !isPaused) {
      videoRef?.current?.pause()
      setIsPaused(true)
    }
  }, [pauseFromOutside, videoRef, showVideoElement, isPaused])

  useEffect(() => {
    // If video url was loaded, but it has 'iframe_src=true' - we can't show the video
    // we decided to open the ad preview on facebook in new tab
    if (showVideoElement && requestedVideoUrlData?.iframe_src && viewAdOnFacebook) {
      window.open(viewAdOnFacebook, '_blank', 'noopener,noreferrer')
    }
  }, [showVideoElement, requestedVideoUrlData, viewAdOnFacebook])

  return (
    <div className={classnames(classes.container, className)} onClick={onInitialPlayPress}>
      {/*this icon is shown when the video is not loaded yet, and we actually show the video picture*/}
      {!showVideoElement && (
        <div className={classes.overlay}>
          <Icon className={classes.icon}>
            <PlayIcon />
          </Icon>
        </div>
      )}

      {/* this icon is custom play/pause button which is shown on hover */}
      {showVideoElement &&
        isVideoLoaded &&
        // do not show buttons if videoUrlData is loaded, but it has 'iframe_src=true',
        // because we can't show the video in that case
        !requestedVideoUrlData?.iframe_src && (
          <div className={classes.overlay} onClick={togglePlay}>
            <div className={classes.playPauseButtons}>
              {isPaused ? (
                <Icon className={classes.icon}>
                  <PlayIcon />
                </Icon>
              ) : (
                <Icon className={classes.icon}>
                  <PauseIcon />
                </Icon>
              )}
            </div>
          </div>
        )}

      {/*still show image until the video is loaded*/}
      {!isVideoLoaded && (
        <img
          onLoad={onLoad}
          src={videoPicture}
          alt={fileName}
          className={classnames(classes.videoPicture, videoPictureClassName)}
        />
      )}
      {/*don't show video element until user clicks on the thumbnail*/}
      {showVideoElement && (
        <video
          // don't show the video until it's loaded to avoid flickering
          style={{ display: showVideoElement && isVideoLoaded ? 'block' : 'none' }}
          onLoadedData={videoOnLoadHandler}
          // video element should have src too! In other case when mediaUrl is changed - it doesn't change the video
          // because of memoization
          src={mediaUrl}
          ref={videoRef}
          autoPlay={true}
          loop={true}
          controls={showControls}
        >
          <source src={mediaUrl} />
        </video>
      )}
    </div>
  )
}

FacebookVideoPreview.propTypes = {
  viewAdOnFacebook: PropTypes.string,
  className: PropTypes.string,
  videoPictureClassName: PropTypes.string,
  mediaFile: PropTypes.object,
  onLoad: PropTypes.func,
  pauseFromOutside: PropTypes.bool,
  showControls: PropTypes.bool,
  showPlayPauseButtonOnHover: PropTypes.bool
}

export default FacebookVideoPreview
