import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import FormStepProgress from '../../Form/FormStepProgress'
import FormProgress from '../../Form/FormProgress'

import useStyles from './styles'

const FormWrapper = React.memo(
  ({
    stepsForProgress,
    activeStep,
    formProgressItems,
    hasWidthLimit = true, // by default step form container has width limit
    children
  }) => {
    const classes = useStyles()

    return (
      <div className={classes.formWrapper}>
        <div
          className={classnames(classes.formContainer, {
            [classes.formProgressPadding]: !!formProgressItems,
            [classes.formContainerWidthLimit]: hasWidthLimit
          })}
        >
          {stepsForProgress > 1 && <FormStepProgress stepsLength={stepsForProgress} activeStepNumber={activeStep} />}

          {children}
        </div>

        {formProgressItems && (
          <>
            <div className={classes.sideColumn}>
              <div className={classes.progressItemsContainer}>
                <FormProgress items={formProgressItems} />
              </div>
              {/*this is used for portals don't remove*/}
              <div id="formSideColumnPortal"></div>
            </div>
          </>
        )}
      </div>
    )
  }
)

FormWrapper.propTypes = {
  activeStep: PropTypes.number,
  stepsForProgress: PropTypes.number,
  formProgressItems: PropTypes.array
}

export default FormWrapper
