import { AUTOMATIC, CONVERSIONS, OUTCOME_SALES } from '../../../constants/campaigns'

export const getIsCampaignBudgetExist = campaign => {
  const { budget_lifetime: campaignBudgetLifetime, budget_daily: campaignBudgetDaily } = campaign

  return campaignBudgetLifetime !== '' || campaignBudgetDaily !== ''
}

export const getIsCampaignObjectiveConversionsOrSales = campaignObjective =>
  campaignObjective === CONVERSIONS || campaignObjective === OUTCOME_SALES

export const getShowPromotedObjectFields = campaignObjective =>
  !!getIsCampaignObjectiveConversionsOrSales(campaignObjective)

export const getShowTargetCostCapField = ({ campaignObjective, campaignBiddingType, lineItemBiddingType }) => {
  // show step if we have appropriate campaign bidding type, e.g. not empty string or 'automatic'
  // or conversions campaign with empty bidding_type but selected line_item bidding_type on question
  const isCampaignObjectiveConversionsOrSales = getIsCampaignObjectiveConversionsOrSales(campaignObjective)
  return (
    (!!campaignBiddingType && campaignBiddingType !== AUTOMATIC) ||
    (isCampaignObjectiveConversionsOrSales && !campaignBiddingType && lineItemBiddingType !== AUTOMATIC)
  )
}
