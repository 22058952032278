import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import { selectedMediaPackagesSelector } from '../../../../../../modules/selectors/mediaOrders'

export const useIsMediaPackageDisabled = ({ mediaPackage }) => {
  const selectedMediaPackages = useSelector(selectedMediaPackagesSelector)

  const isMediaPackageSelected = useMemo(() => {
    return selectedMediaPackages.some(({ id }) => id === mediaPackage.id)
  }, [selectedMediaPackages, mediaPackage])

  const isInStock = mediaPackage?.inventory
  return !isInStock || isMediaPackageSelected
}
