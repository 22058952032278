import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Field from '../../../../../../components/Form/Field'
import FieldLabelWrapper from '../../../../../../features/components/Form/FieldLabelWrapper'

import { formatOptionsList } from '../../../../../../features/formatters'

import { accessibleFacebookPagesSelector } from '../../../../../../modules/selectors/socialAccounts'

import { FB_PAGE_CHOICE, INSTA_ACCOUNT_CHOICE } from '../../../fields'

const SocialAccountsFields = ({ formik, adValues, selectedInstagramAccounts, selectedBackedInstagramAccounts }) => {
  const { t } = useTranslation()

  const facebookPages = useSelector(accessibleFacebookPagesSelector)

  const { values, setFieldValue } = formik

  const selectedFacebookPage = values[FB_PAGE_CHOICE]
  const originalFacebookPage = adValues[FB_PAGE_CHOICE]

  const {
    isLoading: instagramAccountsLoading,
    wasLoaded: instagramAccountsWasLoaded,
    results: instagramAccounts
  } = selectedInstagramAccounts

  const { wasLoaded: backedInstagramAccountsWasLoaded, results: backedInstagramAccounts } =
    selectedBackedInstagramAccounts

  const formattedInstagramAccounts = formatOptionsList({
    list: instagramAccounts,
    valueName: 'id',
    labelName: 'username'
  })

  const formattedFacebookPages = formatOptionsList({
    list: facebookPages.filter(({ tasks }) => tasks.includes('advertise')),
    valueName: 'id',
    labelName: 'name'
  })

  // When user change facebook page - preselect instagram account
  useEffect(() => {
    if (instagramAccountsWasLoaded && backedInstagramAccountsWasLoaded) {
      if (selectedFacebookPage === originalFacebookPage) {
        // If user choose original facebook page - preselect original instagram account
        setFieldValue(INSTA_ACCOUNT_CHOICE, adValues[INSTA_ACCOUNT_CHOICE])
      } else {
        // If user choose another facebook page:
        if (instagramAccounts.length) {
          // preselect first account
          setFieldValue(INSTA_ACCOUNT_CHOICE, instagramAccounts[0]?.id)
        } else {
          // if there are no instagram accounts - use backed instagram account
          // todo find out if we need it, or we can remove it
          // setFieldValue(INSTA_ACCOUNT_CHOICE, backedInstagramAccounts[0]?.id)
        }
      }
    }
  }, [
    setFieldValue,
    selectedFacebookPage,
    originalFacebookPage,
    adValues,
    instagramAccounts,
    instagramAccountsWasLoaded,
    backedInstagramAccounts,
    backedInstagramAccountsWasLoaded
  ])

  return (
    <>
      <FieldLabelWrapper label="Facebook Page">
        <Field
          placeholder={t('Facebook page')}
          formik={formik}
          name={FB_PAGE_CHOICE}
          options={formattedFacebookPages}
          isLoading={false}
          isSearchable={false}
        />
      </FieldLabelWrapper>

      <FieldLabelWrapper label="Instagram Account">
        <Field
          placeholder={t('Instagram account')}
          formik={formik}
          name={INSTA_ACCOUNT_CHOICE}
          options={formattedInstagramAccounts}
          isLoading={instagramAccountsLoading}
          isSearchable={false}
        />
      </FieldLabelWrapper>
    </>
  )
}

export default SocialAccountsFields
