import update from 'immutability-helper'

import {
  INITIAL_CREATE_REDUCER,
  INITIAL_DELETE_REDUCER,
  INITIAL_GET_REDUCER,
  INITIAL_GET_RESULTS_PAGING_REDUCER,
  INITIAL_GET_RESULTS_REDUCER,
  INITIAL_UPDATE_REDUCER
} from '../../constants/reducer'
import {
  CLEAR_CREATE_MEDIA_PRODUCT_VARIABLE,
  CLEAR_DELETE_MEDIA_PRODUCT_VARIABLE,
  CLEAR_GET_MEDIA_PRODUCT_VARIABLE_OPTIONS,
  CLEAR_GET_MEDIA_PRODUCT_VARIABLES,
  CLEAR_GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST,
  CLEAR_UPDATE_MEDIA_PRODUCT_VARIABLE,
  CREATE_MEDIA_PRODUCT_VARIABLE,
  CREATE_MEDIA_PRODUCT_VARIABLE_FAILURE,
  CREATE_MEDIA_PRODUCT_VARIABLE_SUCCESS,
  DELETE_MEDIA_PRODUCT_VARIABLE,
  DELETE_MEDIA_PRODUCT_VARIABLE_FAILURE,
  DELETE_MEDIA_PRODUCT_VARIABLE_SUCCESS,
  GET_MEDIA_PRODUCT_VARIABLE_OPTIONS,
  GET_MEDIA_PRODUCT_VARIABLE_OPTIONS_FAILURE,
  GET_MEDIA_PRODUCT_VARIABLE_OPTIONS_SUCCESS,
  GET_MEDIA_PRODUCT_VARIABLES,
  GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST,
  GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST_FAILURE,
  GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST_SUCCESS,
  GET_MEDIA_PRODUCT_VARIABLES_FAILURE,
  GET_MEDIA_PRODUCT_VARIABLES_SUCCESS,
  UPDATE_MEDIA_PRODUCT_VARIABLE,
  UPDATE_MEDIA_PRODUCT_VARIABLE_FAILURE,
  UPDATE_MEDIA_PRODUCT_VARIABLE_SUCCESS
} from '../actions/mediaOrdersProductVariables'
import { deleteItemById, updateItem } from '../../helpers/modules/reducerHelpers'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  mediaProductVariables: INITIAL_GET_RESULTS_REDUCER,
  createProductVariable: INITIAL_CREATE_REDUCER,
  updateProductVariable: INITIAL_UPDATE_REDUCER,
  deleteMediaProductVariable: INITIAL_DELETE_REDUCER,
  mediaProductVariableOptions: {},
  getMediaProductVariablesBulkList: INITIAL_GET_REDUCER
}

function formatProductVariableOptionsBulkList(options) {
  const formattedOptions = {}

  for (const key in options) {
    formattedOptions[key] = {
      isLoading: false,
      wasLoaded: true,
      results: options[key]
    }
  }

  return formattedOptions
}

export default function mediaOrdersProductVariables(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // Get media product variables
    case GET_MEDIA_PRODUCT_VARIABLES:
      return update(state, {
        mediaProductVariables: { $merge: { isLoading: true } }
      })
    case GET_MEDIA_PRODUCT_VARIABLES_SUCCESS:
      return update(state, {
        mediaProductVariables: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data.results
          }
        }
      })
    case GET_MEDIA_PRODUCT_VARIABLES_FAILURE:
      return update(state, {
        mediaProductVariables: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_MEDIA_PRODUCT_VARIABLES:
      return update(state, {
        mediaProductVariables: { $set: initialState.mediaProductVariables }
      })

    // Create product variable
    case CREATE_MEDIA_PRODUCT_VARIABLE:
      return update(state, {
        createProductVariable: { $merge: { isLoading: true } }
      })
    case CREATE_MEDIA_PRODUCT_VARIABLE_SUCCESS:
      return update(state, {
        createProductVariable: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },

        // update mediaProductVariables results
        mediaProductVariables: {
          results: {
            $unshift: [action.data]
          }
        }
      })
    case CREATE_MEDIA_PRODUCT_VARIABLE_FAILURE:
      return update(state, {
        createProductVariable: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_MEDIA_PRODUCT_VARIABLE:
      return update(state, {
        createProductVariable: { $set: initialState.createProductVariable }
      })

    // Update product variable
    case UPDATE_MEDIA_PRODUCT_VARIABLE:
      return update(state, {
        // id is saved to show loading skeleton on the correct item
        updateProductVariable: { $merge: { isLoading: true, id: action.id } }
      })
    case UPDATE_MEDIA_PRODUCT_VARIABLE_SUCCESS:
      return update(state, {
        updateProductVariable: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            data: action.data
          }
        },
        mediaProductVariables: {
          results: {
            $apply: items => updateItem(items, action.data)
          }
        }
      })
    case UPDATE_MEDIA_PRODUCT_VARIABLE_FAILURE:
      return update(state, {
        updateProductVariable: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_MEDIA_PRODUCT_VARIABLE:
      return update(state, {
        updateProductVariable: { $set: initialState.updateProductVariable }
      })

    // Delete media product variable
    case DELETE_MEDIA_PRODUCT_VARIABLE:
      return update(state, {
        // id is saved to show loading skeleton on the correct item
        deleteMediaProductVariable: { $merge: { isLoading: true, id: action.id } }
      })
    case DELETE_MEDIA_PRODUCT_VARIABLE_SUCCESS:
      return update(state, {
        deleteMediaProductVariable: {
          $merge: {
            isLoading: false,
            wasDeleted: true
          }
        },
        mediaProductVariables: {
          results: {
            $apply: items => deleteItemById(items, action.data.id)
          }
        }
      })
    case DELETE_MEDIA_PRODUCT_VARIABLE_FAILURE:
      return update(state, {
        deleteMediaProductVariable: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DELETE_MEDIA_PRODUCT_VARIABLE:
      return update(state, {
        deleteMediaProductVariable: { $set: initialState.deleteMediaProductVariable }
      })

    // Get media product variable options
    case GET_MEDIA_PRODUCT_VARIABLE_OPTIONS:
      const variableOptionsStateExists = !!state.mediaProductVariableOptions[action.params.media_product_variable]
      return update(state, {
        mediaProductVariableOptions: {
          $merge: {
            [action.params.media_product_variable]: {
              // if state does not exist or shouldClearExistingState is true, set initial state
              ...(!variableOptionsStateExists || action.loadOptions.shouldClearExistingState
                ? INITIAL_GET_RESULTS_PAGING_REDUCER
                : // otherwise keep previous state
                  { ...state.mediaProductVariableOptions[action.params.media_product_variable] }),

              isLoading: true
            }
          }
        }
      })
    case GET_MEDIA_PRODUCT_VARIABLE_OPTIONS_SUCCESS:
      return update(state, {
        mediaProductVariableOptions: {
          $merge: {
            [action.mediaProductVariableId]: {
              isLoading: false,
              wasLoaded: true,
              paging: {
                next: action.data?.next
              },
              // push to previous results
              results: state.mediaProductVariableOptions[action.mediaProductVariableId].results.concat(
                action.data.results
              )
            }
          }
        }
      })
    case GET_MEDIA_PRODUCT_VARIABLE_OPTIONS_FAILURE:
      return update(state, {
        mediaProductVariableOptions: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_MEDIA_PRODUCT_VARIABLE_OPTIONS:
      return update(state, {
        mediaProductVariableOptions: { $set: initialState.mediaProductVariableOptions }
      })

    // Get media product variables bulk list
    case GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST:
      return update(state, {
        getMediaProductVariablesBulkList: { $merge: { isLoading: true } }
      })
    case GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST_SUCCESS:
      const formattedVariableOptions = formatProductVariableOptionsBulkList(action.data)
      return update(state, {
        getMediaProductVariablesBulkList: {
          $merge: {
            isLoading: false,
            wasLoaded: true
          }
        },
        // we use mediaProductVariableOptionsBulkList request to initially populate mediaProductVariableOptions
        mediaProductVariableOptions: { $set: formattedVariableOptions }
      })
    case GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST_FAILURE:
      return update(state, {
        getMediaProductVariablesBulkList: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_MEDIA_PRODUCT_VARIABLES_BULK_LIST:
      return update(state, {
        getMediaProductVariablesBulkList: { $set: initialState.getMediaProductVariablesBulkList }
      })

    default:
      return state
  }
}
