import { createStyles } from '../../../../../../../../styles/helpers'
import { darkGrey, lightGrey } from '../../../../../../../../styles/const/colors'

const useStyles = createStyles(theme => ({
  container: {
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    padding: 0
  },
  iconContainer: {
    width: 145,
    background: lightGrey,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6
  },
  forecastInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '16px 20px 18px',
    width: '100%'
  },
  forecastTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.brandPrimary,
    marginTop: [0, '!important']
  },
  forecastAmount: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.brandPrimary
  },
  forecastDescription: {
    fontSize: 12,
    color: darkGrey,
    lineHeight: 1.4
  }
}))

export default useStyles
