import update from 'immutability-helper'

import { updateItem } from '../../helpers/modules/reducerHelpers'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  UPDATE_QUOTATIONS_STATUS_BULK,
  UPDATE_QUOTATIONS_STATUS_BULK_SUCCESS,
  UPDATE_QUOTATIONS_STATUS_BULK_FAILURE,
  CLEAR_UPDATE_QUOTATIONS_STATUS_BULK,
  UPDATE_QUOTATION,
  UPDATE_QUOTATION_SUCCESS,
  UPDATE_QUOTATION_FAILURE,
  CLEAR_UPDATE_QUOTATION,
  RESEND_QUOTATION,
  RESEND_QUOTATION_SUCCESS,
  RESEND_QUOTATION_FAILURE,
  CLEAR_RESEND_QUOTATION,
  GET_QUOTATIONS,
  GET_QUOTATIONS_SUCCESS,
  GET_QUOTATIONS_FAILURE,
  CLEAR_GET_QUOTATIONS,
  CREATE_QUOTATION,
  CREATE_QUOTATION_SUCCESS,
  CREATE_QUOTATION_FAILURE,
  CLEAR_CREATE_QUOTATION,
  SEND_QUOTATION,
  SEND_QUOTATION_SUCCESS,
  SEND_QUOTATION_FAILURE,
  CLEAR_SEND_QUOTATION
} from '../actions/quotations'
import {
  INITIAL_CREATE_REDUCER,
  INITIAL_GET_REDUCER,
  INITIAL_GET_RESULTS_PAGING_REDUCER,
  INITIAL_UPDATE_REDUCER
} from '../../constants/reducer'
import { manageContractsBulkDelete } from './contracts/manageContractsBulkDelete'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  createQuotation: INITIAL_CREATE_REDUCER,
  quotations: INITIAL_GET_RESULTS_PAGING_REDUCER,
  sendQuotation: INITIAL_CREATE_REDUCER,
  resendQuotation: INITIAL_UPDATE_REDUCER,
  getQuotation: INITIAL_GET_REDUCER,
  updateQuotation: INITIAL_UPDATE_REDUCER,
  updateQuotationsStatusBulk: INITIAL_UPDATE_REDUCER
}

export default function quotations(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // Create quotation
    case CREATE_QUOTATION:
      return update(state, {
        createQuotation: { $merge: { isLoading: true } }
      })
    case CREATE_QUOTATION_SUCCESS:
      return update(state, {
        createQuotation: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        }
      })
    case CREATE_QUOTATION_FAILURE:
      return update(state, {
        createQuotation: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_QUOTATION:
      return update(state, {
        createQuotation: { $set: initialState.createQuotation }
      })

    // Get quotations
    case GET_QUOTATIONS:
      return update(state, {
        quotations: {
          $merge: {
            ...(action.loadOptions.shouldClearExistingState && INITIAL_GET_RESULTS_PAGING_REDUCER),
            isLoading: true
          }
        }
      })
    case GET_QUOTATIONS_SUCCESS:
      return update(state, {
        quotations: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: {
              next: action.data?.next
            },
            count: action.data.count,
            total_value: action.data.total_value
          },
          results: {
            $push: action.data.results
          }
        }
      })
    case GET_QUOTATIONS_FAILURE:
      return update(state, {
        quotations: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_QUOTATIONS:
      return update(state, {
        quotations: { $set: initialState.quotations }
      })

    // Send quotations
    case SEND_QUOTATION:
      return update(state, {
        sendQuotation: { $merge: { isLoading: true } }
      })
    case SEND_QUOTATION_SUCCESS:
      return update(state, {
        sendQuotation: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },
        quotations: {
          results: {
            $apply: items => updateItem(items, action.data)
          }
        }
      })
    case SEND_QUOTATION_FAILURE:
      return update(state, {
        sendQuotation: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_SEND_QUOTATION:
      return update(state, {
        sendQuotation: { $set: initialState.sendQuotation }
      })

    // Resend quotation
    case RESEND_QUOTATION:
      return update(state, {
        resendQuotation: { $merge: { isLoading: true } }
      })
    case RESEND_QUOTATION_SUCCESS:
      return update(state, {
        resendQuotation: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            data: action.data
          }
        }
      })
    case RESEND_QUOTATION_FAILURE:
      return update(state, {
        resendQuotation: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_RESEND_QUOTATION:
      return update(state, {
        resendQuotation: { $set: initialState.resendQuotation }
      })

    // Update quotation
    case UPDATE_QUOTATION:
      return update(state, {
        updateQuotation: { $merge: { isLoading: true } }
      })
    case UPDATE_QUOTATION_SUCCESS:
      return update(state, {
        updateQuotation: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            data: action.data
          }
        },
        quotations: {
          results: {
            $apply: items => updateItem(items, action.data)
          }
        }
      })
    case UPDATE_QUOTATION_FAILURE:
      return update(state, {
        updateQuotation: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_QUOTATION:
      return update(state, {
        updateQuotation: { $set: initialState.updateQuotation }
      })

    // Update quotations status bulk
    case UPDATE_QUOTATIONS_STATUS_BULK:
      return update(state, {
        updateQuotationsStatusBulk: { $merge: { isLoading: true } }
      })
    case UPDATE_QUOTATIONS_STATUS_BULK_SUCCESS:
      return update(state, {
        updateQuotationsStatusBulk: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            data: action.data
          }
        },
        quotations: {
          ...manageContractsBulkDelete(action)
        }
      })
    case UPDATE_QUOTATIONS_STATUS_BULK_FAILURE:
      return update(state, {
        updateQuotationsStatusBulk: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_QUOTATIONS_STATUS_BULK:
      return update(state, {
        updateQuotationsStatusBulk: { $set: initialState.updateQuotationsStatusBulk }
      })

    default:
      return state
  }
}
