import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import HighlightedValueSelect from '../../../../../components/HighlightedValueSelect'

import { statusFilterOptions } from './options'

import useStyles from '../styles'

const InvoicingStatusFilter = ({ statusFilterOption, setStatusFilterOption }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const onStatusFilterChange = useCallback(
    option => {
      setStatusFilterOption(option?.value)
    },
    [setStatusFilterOption]
  )

  return (
    <HighlightedValueSelect
      placeholder="Status"
      value={statusFilterOption}
      onChange={onStatusFilterChange}
      className={classes.select}
      options={statusFilterOptions(t)}
      isSmall
    />
  )
}

InvoicingStatusFilter.propTypes = {
  statusFilterOption: PropTypes.string,
  setStatusFilterOption: PropTypes.func.isRequired
}

export default InvoicingStatusFilter
