import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles({
  mobileHeaderStyle: {
    minHeight: 24
  },
  statusMobileField: {
    marginRight: 0
  },
  mobileNameHeader: {
    display: 'block'
  }
})

export default useStyles
