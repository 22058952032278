import React from 'react'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import OnsiteDigitalFormsForm from './CreateOnsiteDigitalForm'

import { ONSITE_DIGITAL_FORMS } from '../../../../../constants/forms'

function OnsiteDigitalForms() {
  return (
    <FormDrawerWrapper
      formName={ONSITE_DIGITAL_FORMS}
      openButtonText="Create campaign"
      hasDefaultDrawerContentSpacing={false}
      isWideDrawer
    >
      <OnsiteDigitalFormsForm />
    </FormDrawerWrapper>
  )
}

export default OnsiteDigitalForms
