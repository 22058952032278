import { createStyles } from '../../styles/helpers'

import { transitionShort } from '../../styles/const/common'

export default createStyles(theme => ({
  icon: {
    display: 'inline-flex',
    fontSize: '18px',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: ({ notHoverable }) => notHoverable && 'none',
    color: ({ inheritColor }) => (inheritColor ? 'inherit' : theme.brandPrimary),
    background: ({ hasBackground }) => hasBackground && theme.brandPrimaryLight,
    borderRadius: ({ isCircle }) => isCircle && '50%',

    '& svg': {
      '& path': {
        // transition is controlled by parent element
        transition: ({ inheritColor }) => (inheritColor ? 'none' : transitionShort)
      }
    },
    '&:hover': {
      color: ({ inheritColor, notHoverable }) => (inheritColor || notHoverable ? 'inherit' : theme.brandPrimaryLight)
    }
  }
}))
