import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'

import ImpressionsGraph from '../../../../../features/components/SummaryGraphs/ImpressionsGraph'
import RoasGraphSection from '../../../../../features/components/SummaryGraphs/RoasGraphSection'
import ClicksGraph from '../../../../../features/components/SummaryGraphs/ClicksGraph'
import CTRGraph from '../../../../../features/components/SummaryGraphs/CTRGraph'
import GenderGraphSection from '../../../../../features/components/SummaryGraphs/GenderGraphSection'
import AgeGraphSection from '../../../../../features/components/SummaryGraphs/AgeGraphSection'
import PlatformGraphSection from '../../../../../features/components/SummaryGraphs/PlatformGraphSection'
import SkeletonSummaryGraphs from '../../../../../features/components/SummaryGraphs/Skeleton'

import { useGraphData } from '../../../../../features/components/SummaryGraphs/useGraphData'
import { getFormattedCombinedGraphs } from './helpers'
import { groupCampaignsByProvider } from '../../../helpers'

import {
  clearCombinedGraphs,
  clearCombinedSegmentedData,
  getCombinedGraphs,
  getCombinedSegmentedData
} from '../../../../../modules/actions/combinedData'
import {
  combinedCampaignsSelector,
  combinedGraphsIsLoadingSelector,
  combinedGraphsSelector,
  combinedSegmentedDataIsLoadingSelector,
  combinedSegmentedDataSelector
} from '../../../../../modules/selectors/combinedData'
import { mediaOrderSelector } from '../../../../../modules/selectors/mediaOrders'
import { selectedAdAccountDataSelector } from '../../../../../modules/selectors/app'

import { DATES_FORMAT_BE } from '../../../../../constants/dates'

import useGraphsSummaryStyles from '../../../../../features/components/SummaryGraphs/styles'

const Graphs = () => {
  const graphsSummaryClasses = useGraphsSummaryStyles()

  const dispatch = useDispatch()

  const combinedGraphs = useSelector(combinedGraphsSelector)
  const combinedGraphsIsLoading = useSelector(combinedGraphsIsLoadingSelector)
  const combinedSegmentedData = useSelector(combinedSegmentedDataSelector)
  const combinedSegmentedDataIsLoading = useSelector(combinedSegmentedDataIsLoadingSelector)
  const { campaigns_date_range: campaignsDateRange } = useSelector(combinedCampaignsSelector)

  const { currency_symbol: selectedAdAccountCurrencySymbol } = useSelector(selectedAdAccountDataSelector)
  // todo think which platform to use to get currency symbol, currently using selectedAdAccount
  const currencySymbol = selectedAdAccountCurrencySymbol

  const { date_from: campaignsDateStart, date_to: campaignsDateEnd } = campaignsDateRange || {}

  const mediaOrder = useSelector(mediaOrderSelector)
  const { campaigns: mediaOrderCampaigns } = mediaOrder

  const graphsIsLoading = useMemo(
    () => combinedGraphsIsLoading || combinedSegmentedDataIsLoading,
    [combinedGraphsIsLoading, combinedSegmentedDataIsLoading]
  )
  const campaignsGroupedByProvider = useMemo(() => groupCampaignsByProvider(mediaOrderCampaigns), [mediaOrderCampaigns])
  const { impressionsData, ctrData, clicksData, roasData } = useGraphData(combinedGraphs)

  const formattedAgeData = useMemo(
    () => getFormattedCombinedGraphs(combinedSegmentedData?.age_data),
    [combinedSegmentedData?.age_data]
  )
  const formattedGenderData = useMemo(
    () => getFormattedCombinedGraphs(combinedSegmentedData?.gender_data),
    [combinedSegmentedData?.gender_data]
  )
  const formattedPlatformData = useMemo(
    () => getFormattedCombinedGraphs(combinedSegmentedData?.publisher_platform_data),
    [combinedSegmentedData?.publisher_platform_data]
  )

  const sortedPlatformData = useMemo(
    () => formattedPlatformData?.length && [...formattedPlatformData].sort((a, b) => b.percent - a.percent),
    [formattedPlatformData]
  )

  useEffect(() => {
    dispatch(
      getCombinedGraphs({
        insights: ['impressions', 'cpm', 'roas', 'clicks', 'ctr', 'conversions', 'engagement'],
        ...campaignsDateRange,
        providers: campaignsGroupedByProvider
      })
    )
  }, [dispatch, campaignsDateRange, campaignsGroupedByProvider])

  useEffect(() => {
    dispatch(
      getCombinedSegmentedData({
        insights: ['impressions', 'cpm', 'roas', 'clicks', 'ctr', 'conversions', 'engagement'],
        breakdowns: ['gender', 'age', 'publisher_platform'],
        // segmentedData endpoint doesn't support date_preset field, and requires date_from and date_to
        date_from: format(new Date(campaignsDateStart), DATES_FORMAT_BE),
        date_to: format(new Date(campaignsDateEnd), DATES_FORMAT_BE),
        providers: campaignsGroupedByProvider
      })
    )
  }, [dispatch, campaignsDateStart, campaignsDateEnd, campaignsGroupedByProvider])

  useEffect(() => {
    return () => {
      dispatch(clearCombinedGraphs())
      dispatch(clearCombinedSegmentedData())
    }
  }, [dispatch])

  return (
    <section id="graphs-report">
      {graphsIsLoading ? (
        <SkeletonSummaryGraphs />
      ) : (
        <div className={graphsSummaryClasses.graphsContainer}>
          {impressionsData && <ImpressionsGraph rawGraphData={impressionsData} />}
          {roasData?.total ? (
            <RoasGraphSection rawGraphData={roasData} />
          ) : clicksData ? (
            <ClicksGraph rawGraphData={clicksData} />
          ) : null}
          {ctrData && <CTRGraph rawGraphData={ctrData} />}
          {!!formattedGenderData?.length && (
            <GenderGraphSection rawGraphData={formattedGenderData} currencySymbol={currencySymbol} />
          )}
          {!!formattedAgeData?.length && (
            <AgeGraphSection rawGraphData={formattedAgeData} currencySymbol={currencySymbol} />
          )}
          {!!formattedPlatformData?.length && (
            <PlatformGraphSection rawGraphData={sortedPlatformData} currencySymbol={currencySymbol} />
          )}
        </div>
      )}
    </section>
  )
}

export default Graphs
