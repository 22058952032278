import store from '../../store'

import { concat } from '../../helpers/common'
import { clearLocalStorage, getLocalStorage, setLocalStorage } from '../../helpers/storage'
import { getDefaultPageUrl, redirectTo } from '../../helpers/url'

import { ROUTE_PARAMS, ROUTES } from '../../constants/routes'
import { selectedSelfAccountSelector } from '../selectors/app'

const MODULE_NAME = 'COGNITO/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const SUBMIT_LOGIN = concat(MODULE_NAME, 'SUBMIT_LOGIN')
export const SUBMIT_LOGIN_SUCCESS = concat(MODULE_NAME, 'SUBMIT_LOGIN_SUCCESS')
export const SUBMIT_LOGIN_MFA_REQUIRED_SUCCESS = concat(MODULE_NAME, 'SUBMIT_LOGIN_MFA_REQUIRED_SUCCESS')
export const SUBMIT_LOGIN_FAILURE = concat(MODULE_NAME, 'SUBMIT_LOGIN_FAILURE')
export const CLEAR_SUBMIT_LOGIN = concat(MODULE_NAME, 'CLEAR_SUBMIT_LOGIN')

export const SUBMIT_MFA_TYPE = concat(MODULE_NAME, 'SUBMIT_MFA_TYPE')
export const SUBMIT_MFA_TYPE_SUCCESS = concat(MODULE_NAME, 'SUBMIT_MFA_TYPE_SUCCESS')
export const SUBMIT_MFA_TYPE_FAILURE = concat(MODULE_NAME, 'SUBMIT_MFA_TYPE_FAILURE')
export const CLEAR_SUBMIT_MFA_TYPE = concat(MODULE_NAME, 'CLEAR_SUBMIT_MFA_TYPE')

export const SUBMIT_MFA_CODE = concat(MODULE_NAME, 'SUBMIT_MFA_CODE')
export const SUBMIT_MFA_CODE_SUCCESS = concat(MODULE_NAME, 'SUBMIT_MFA_CODE_SUCCESS')
export const SUBMIT_MFA_CODE_FAILURE = concat(MODULE_NAME, 'SUBMIT_MFA_CODE_FAILURE')
export const CLEAR_SUBMIT_MFA_CODE = concat(MODULE_NAME, 'CLEAR_SUBMIT_MFA_CODE')

export const CHECK_AUTH_STATUS = concat(MODULE_NAME, 'CHECK_AUTH_STATUS')
export const CHECK_AUTH_STATUS_SUCCESS = concat(MODULE_NAME, 'CHECK_AUTH_STATUS_SUCCESS')
export const CHECK_AUTH_STATUS_FAILURE = concat(MODULE_NAME, 'CHECK_AUTH_STATUS_FAILURE')

export const SUBMIT_LOGOUT = concat(MODULE_NAME, 'SUBMIT_LOGOUT')
export const SUBMIT_LOGOUT_SUCCESS = concat(MODULE_NAME, 'SUBMIT_LOGOUT_SUCCESS')
export const SUBMIT_LOGOUT_FAILURE = concat(MODULE_NAME, 'SUBMIT_LOGOUT_FAILURE')

// create password for newly created account
export const CREATE_PASSWORD = concat(MODULE_NAME, 'CREATE_PASSWORD')
export const CREATE_PASSWORD_SUCCESS = concat(MODULE_NAME, 'CREATE_PASSWORD_SUCCESS')
export const CREATE_PASSWORD_FAILURE = concat(MODULE_NAME, 'CREATE_PASSWORD_FAILURE')

// create password for forgot password flow
export const CREATE_NEW_PASSWORD = concat(MODULE_NAME, 'CREATE_NEW_PASSWORD')
export const CREATE_NEW_PASSWORD_SUCCESS = concat(MODULE_NAME, 'CREATE_NEW_PASSWORD_SUCCESS')
export const CREATE_NEW_PASSWORD_FAILURE = concat(MODULE_NAME, 'CREATE_NEW_PASSWORD_FAILURE')

export const RESET_PASSWORD = concat(MODULE_NAME, 'RESET_PASSWORD')
export const RESET_PASSWORD_SUCCESS = concat(MODULE_NAME, 'RESET_PASSWORD_SUCCESS')
export const RESET_PASSWORD_FAILURE = concat(MODULE_NAME, 'RESET_PASSWORD_FAILURE')

export const GET_TOTP_TOKEN = concat(MODULE_NAME, 'GET_TOTP_TOKEN')
export const GET_TOTP_TOKEN_SUCCESS = concat(MODULE_NAME, 'GET_TOTP_TOKEN_SUCCESS')
export const GET_TOTP_TOKEN_FAILURE = concat(MODULE_NAME, 'GET_TOTP_TOKEN_FAILURE')
export const CLEAR_GET_TOTP_TOKEN = concat(MODULE_NAME, 'CLEAR_GET_TOTP_TOKEN')

export const VERIFY_TOTP_TOKEN = concat(MODULE_NAME, 'VERIFY_TOTP_TOKEN')
export const VERIFY_TOTP_TOKEN_SUCCESS = concat(MODULE_NAME, 'VERIFY_TOTP_TOKEN_SUCCESS')
export const VERIFY_TOTP_TOKEN_FAILURE = concat(MODULE_NAME, 'VERIFY_TOTP_TOKEN_FAILURE')
export const CLEAR_VERIFY_TOTP_TOKEN = concat(MODULE_NAME, 'CLEAR_VERIFY_TOTP_TOKEN')

export const CLEAR_COGNITO_STORE = concat(MODULE_NAME, 'CLEAR_COGNITO_STORE')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

export function submitLogin(userCredentials) {
  return { type: SUBMIT_LOGIN, userCredentials }
}

export function submitLoginSuccess(userData) {
  const landingUrl = getLocalStorage('landingUrl')

  if (
    // ignore redirects from the login to login
    !!landingUrl &&
    landingUrl !== ROUTES.login
  ) {
    redirectTo(landingUrl)

    // clear landingUrl after redirection
    setTimeout(() => {
      setLocalStorage('landingUrl', '')
    }, 100)
  } else {
    const storeState = store.getState()
    const selectedSelfAccount = selectedSelfAccountSelector(storeState)
    redirectTo(getDefaultPageUrl(), {
      [ROUTE_PARAMS.selfAccount]: selectedSelfAccount
    })
  }

  if (window.analytics) {
    // Segment analytics implementation
    window.analytics.track('Logged in', {})
  }
  return { type: SUBMIT_LOGIN_SUCCESS, userData }
}

export function submitLoginMFARequiredSuccess(mfaType, nextStep) {
  return { type: SUBMIT_LOGIN_MFA_REQUIRED_SUCCESS, mfaType, nextStep }
}

export function submitLoginFailure(error) {
  return { type: SUBMIT_LOGIN_FAILURE, error }
}

export function clearSubmitLogin() {
  return { type: CLEAR_SUBMIT_LOGIN }
}

// submit mfa type to cognito
export function submitMfaType(mfaType) {
  return { type: SUBMIT_MFA_TYPE, mfaType }
}

export function submitMfaTypeSuccess() {
  return { type: SUBMIT_MFA_TYPE_SUCCESS }
}

export function submitMfaTypeFailure(error) {
  return { type: SUBMIT_MFA_TYPE_FAILURE, error }
}

export function clearSubmitMfaType() {
  return { type: CLEAR_SUBMIT_MFA_TYPE }
}

// submit mfa code
export function submitMFACode(code) {
  return { type: SUBMIT_MFA_CODE, code }
}

export function submitMFACodeSuccess(userData) {
  if (window.analytics) {
    // Segment analytics implementation
    window.analytics.track('Logged in', {})
  }
  return { type: SUBMIT_MFA_CODE_SUCCESS, userData }
}

export function submitMFACodeFailure(error) {
  return { type: SUBMIT_MFA_CODE_FAILURE, error }
}

export function clearSubmitMFACode() {
  return { type: CLEAR_SUBMIT_MFA_CODE }
}

export function checkAuthStatus() {
  return { type: CHECK_AUTH_STATUS }
}

export function checkAuthStatusSuccess(authData) {
  return { type: CHECK_AUTH_STATUS_SUCCESS, authData }
}

export function checkAuthStatusFailure() {
  return { type: CHECK_AUTH_STATUS_FAILURE }
}

export function submitLogout() {
  return { type: SUBMIT_LOGOUT }
}

export function submitLogoutSuccess(loginData) {
  sessionStorage.clear()
  clearLocalStorage()
  return { type: SUBMIT_LOGOUT_SUCCESS, loginData }
}

export function submitLogoutFailure(error) {
  return { type: SUBMIT_LOGOUT_FAILURE, error }
}

export function createPassword(data) {
  return { type: CREATE_PASSWORD, data }
}

export function createPasswordSuccess(createPasswordData) {
  return { type: CREATE_PASSWORD_SUCCESS, createPasswordData }
}

export function createPasswordFailure(error) {
  return { type: CREATE_PASSWORD_FAILURE, error }
}

export function createNewPassword(data) {
  return { type: CREATE_NEW_PASSWORD, data }
}

export function createNewPasswordSuccess(createPasswordData) {
  return { type: CREATE_NEW_PASSWORD_SUCCESS, createPasswordData }
}

export function createNewPasswordFailure(error) {
  return { type: CREATE_NEW_PASSWORD_FAILURE, error }
}

export function resetPassword(email, showSuccessToast) {
  return { type: RESET_PASSWORD, email, showSuccessToast }
}

export function resetPasswordSuccess(resetPasswordData) {
  return { type: RESET_PASSWORD_SUCCESS, resetPasswordData }
}

export function resetPasswordFailure(error) {
  return { type: RESET_PASSWORD_FAILURE, error }
}

// Get TOTP token
export function getTOTPToken(params) {
  return { type: GET_TOTP_TOKEN, params }
}

export function getTOTPTokenSuccess(data) {
  return { type: GET_TOTP_TOKEN_SUCCESS, data }
}

export function getTOTPTokenFailure(error) {
  return { type: GET_TOTP_TOKEN_FAILURE, error }
}

export function clearGetTOTPToken() {
  return { type: CLEAR_GET_TOTP_TOKEN }
}

// Verify TOTP token
export function verifyTOTPToken(code) {
  return { type: VERIFY_TOTP_TOKEN, code }
}

export function verifyTOTPTokenSuccess() {
  return { type: VERIFY_TOTP_TOKEN_SUCCESS }
}

export function verifyTOTPTokenFailure(error) {
  return { type: VERIFY_TOTP_TOKEN_FAILURE, error }
}

export function clearVerifyTOTPToken() {
  return { type: CLEAR_VERIFY_TOTP_TOKEN }
}

export function clearCognitoStore() {
  return { type: CLEAR_COGNITO_STORE }
}
