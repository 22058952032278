import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import { usePurifiedFormik } from '../../../../../../../hooks/formHooks/usePurifiedFormik'

import CancelMediaOrder from '../../../../../../../pages/MediaOrderSummary/MediaOrderSummaryContent/DetailsSection/ContractDetails/CancelMediaOrder'
import Form from '../../../../../../../components/Form'
import ActionText from '../../../../../../../components/ActionText'
import AmendmentContractCreateFields from './AmendmentContractCreateFields'
import ProductsCostSummary from '../../../../../../../features/components/mediaProductsComponents/ProductsCostSummary'
import ContractButtonSection from '../../../../../../../pages/Proposals/QuotationReview/ContractButtonSection'

import { SelectedMediaProductsContext } from '../SelectedMediaProductsContext'

import { clearCreateAmendment, createAmendment } from '../../../../../../../modules/actions/amendments'
import { mediaOrderSelector } from '../../../../../../../modules/selectors/mediaOrders'
import {
  amendmentWasCreatedSelector,
  createAmendmentErrorSelector,
  createAmendmentIsLoadingSelector
} from '../../../../../../../modules/selectors/amendments'
import { selectedControllerIdSelector, selectedSelfAccountSelector } from '../../../../../../../modules/selectors/app'

import { SELECTED_PRODUCTS } from './fields'
import { MEDIA_ORDER_AMEND_CONTRACT_CREATE } from '../../../../../../../constants/forms'

import { getInitialValues } from './fields'
import { transformValuesToBE } from './formatters'
import { validationSchema } from '../validation'
import { BRAND, CAMPAIGN_NAME } from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/fields'

import useStyles from './styles'

const AmendmentContractCreateForm = ({ onAddNewMediaProduct, onSuccessSubmit }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  const mediaOrder = useSelector(mediaOrderSelector)
  const controllerId = useSelector(selectedControllerIdSelector)
  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)
  const amendmentWasCreated = useSelector(amendmentWasCreatedSelector)

  const mediaOrderId = mediaOrder.id
  const currencySymbol = mediaOrder.currency?.symbol
  const { selectedProductsData, setContractSelectedProducts, newProductRef } = useContext(SelectedMediaProductsContext)

  const newProductValues = newProductRef.current
  const initialValues = useMemo(() => getInitialValues(mediaOrder), [mediaOrder])

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values, selectedProductsData)

      const amendmentData = {
        detail: {
          ...transformedData,
          account: selectedSelfAccount,
          controller: controllerId,
          // copy paste fields from media order:
          currency: mediaOrder.currency?.code,
          name: mediaOrder.name,
          [BRAND]: mediaOrder[BRAND]?.id, // BE returns full brand object
          [CAMPAIGN_NAME]: mediaOrder[CAMPAIGN_NAME]
        },
        amendment: true,
        // This field is used to manually update the cost of the amendment
        set_amendment_cost: true,
        original_media_order: mediaOrderId,
        account: selectedSelfAccount,
        controller: controllerId,
        without_requirements: mediaOrder.without_requirements
      }

      dispatch(createAmendment(amendmentData))
    },
    [dispatch, controllerId, mediaOrder, mediaOrderId, selectedSelfAccount, selectedProductsData]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const { resetForm, values, setFieldValue } = formik

  const selectedProductsValues = values[SELECTED_PRODUCTS]

  const clearAmendMediaOrderHandler = useCallback(() => {
    dispatch(clearCreateAmendment())
  }, [dispatch])

  const resetFormHandler = useCallback(() => {
    resetForm()
  }, [resetForm])

  useEffect(() => {
    // we determine the useEffect hook trigger based on newProductValues because ref doesn't provoke it to call
    // in general the newProduct is managed through the ref - because as using state was calling this hook infinite
    // that is because the context product state wasn't resetting to null faster then formik values were updating
    if (!!newProductValues) {
      // when new product was already added to the selectedProductsValues, replace it with the new one
      const productIndex = selectedProductsValues.findIndex(product => product.id === newProductValues.id)
      if (productIndex !== -1) {
        selectedProductsValues[productIndex] = newProductValues
        setFieldValue(SELECTED_PRODUCTS, selectedProductsValues)
      } else {
        // add new product to the selected products
        const newSelectedProductsValues = [...selectedProductsValues, newProductValues]
        setFieldValue(SELECTED_PRODUCTS, newSelectedProductsValues)
      }

      newProductRef.current = null
    }
  }, [newProductRef, newProductValues, selectedProductsValues, setFieldValue])

  useEffect(() => {
    setContractSelectedProducts(selectedProductsValues)
  }, [selectedProductsValues, setContractSelectedProducts])

  return (
    <>
      <Form
        submitText="Generate Amendment"
        formik={purifiedFormik}
        formName={MEDIA_ORDER_AMEND_CONTRACT_CREATE}
        successSubmit={amendmentWasCreated}
        errorSelector={createAmendmentErrorSelector}
        isLoadingSelector={createAmendmentIsLoadingSelector}
        onSuccessSubmit={onSuccessSubmit}
        clearHandler={clearAmendMediaOrderHandler}
      >
        <AmendmentContractCreateFields formik={purifiedFormik} />
        <ActionText className={classes.newProductButton} isDark onClick={onAddNewMediaProduct}>
          + New Media Product
        </ActionText>
        <ProductsCostSummary currencySymbol={currencySymbol} allProducts={values[SELECTED_PRODUCTS]} />
      </Form>
      <div className={classes.resetPricesButtonWrapper}>
        <ActionText className={classes.resetPricesButton} onClick={resetFormHandler}>
          Reset Prices
        </ActionText>
      </div>
      <ContractButtonSection
        title={t('Cancel this booking')}
        description={t('This will delete the booked media and release the media inventory.')}
      >
        <CancelMediaOrder mediaOrderId={mediaOrderId} />
      </ContractButtonSection>
    </>
  )
}

AmendmentContractCreateForm.propTypes = {
  onAddNewMediaProduct: PropTypes.func,
  onSuccessSubmit: PropTypes.func
}

export default AmendmentContractCreateForm
