import { createStyles } from '../../../../styles/helpers'
import { darkGrey } from '../../../../styles/const/colors'

const useStyles = createStyles({
  accountBrandContainer: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'normal'
  },
  brand: {
    fontSize: 12,
    color: darkGrey,
    marginTop: 6
  }
})

export default useStyles
