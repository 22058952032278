import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles({
  details: {
    textAlign: ({ contentAlign }) => contentAlign,
    paddingTop: ({ hasPadding }) => hasPadding && 28,
    paddingBottom: ({ hasPadding }) => hasPadding && 28,

    '& a': {
      textDecoration: 'none'
    }
  }
})

export default useStyles
