import { BUDGET_LIFETIME, BUDGET_DAILY, NO_BUDGET } from './fields'

export const getBudgetSectionType = (budgetLifetime, budgetDaily) => {
  if (!budgetLifetime && !budgetDaily) {
    return NO_BUDGET
  }

  if (budgetLifetime && !budgetDaily) {
    return BUDGET_LIFETIME
  }

  if (budgetDaily && !budgetLifetime) {
    return BUDGET_DAILY
  }
}
