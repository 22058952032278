import React, { useCallback, useContext } from 'react'
import useStyles from './styles'
import PropTypes from 'prop-types'

import Checkbox from '../../../Form/Checkbox'

const RowSelectionCell = ({ rowId, Context, isDisabled, enableMultiRowSelection = true }) => {
  const classes = useStyles()

  const { rowSelection, setRowSelection } = useContext(Context)
  const rowSelected = Boolean(rowSelection[rowId])

  const handleSelectRow = useCallback(() => {
    if (enableMultiRowSelection) {
      setRowSelection({
        ...rowSelection,
        [rowId]: !rowSelected
      })
    } else {
      setRowSelection({
        [rowId]: !rowSelected
      })
    }
  }, [enableMultiRowSelection, rowId, rowSelected, rowSelection, setRowSelection])

  return (
    <div className={classes.checkBoxContainer}>
      <Checkbox key={rowId} id={rowId} checked={rowSelected} onCheck={handleSelectRow} disabled={isDisabled} />
    </div>
  )
}

RowSelectionCell.propTypes = {
  Context: PropTypes.object.isRequired,
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  enableMultiRowSelection: PropTypes.bool,
  isDisabled: PropTypes.bool
}

export default RowSelectionCell
