import React, { useCallback, useEffect } from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Form from '../../../../components/Form'
import AgencyAdminSelfAccountCreateContent from './AgencyAdminSelfAccountCreateContent'

import { useAuthButtonProps } from '../../../../pages/Unauthorized/useAuthButtonProps'

import { handleLogout } from '../../../../helpers/auth'

import { clearCreateSelfAccount, createSelfAccount } from '../../../../modules/actions/selfAccounts'
import {
  createSelfAccountErrorSelector,
  createSelfAccountIsLoadingSelector,
  selfAccountWasCreatedSelector
} from '../../../../modules/selectors/selfAccounts'
import { userProfileAgenciesSelector } from '../../../../modules/selectors/app'

import { initialValues, NAME, validationSchema } from './fields'

import useAuthStyles from '../../../../styles/common/authPages'
import useStyles from './styles'

const AgencyAdminSelfAccountCreateForm = () => {
  const authClasses = useAuthStyles()
  const classes = useStyles()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const selfAccountWasCreated = useSelector(selfAccountWasCreatedSelector)
  const userProfileAgencies = useSelector(userProfileAgenciesSelector)
  const firstAgency = userProfileAgencies[0]
  const { id: agencyId, controller: controllerId } = firstAgency

  const buttonProps = useAuthButtonProps()

  const onSubmit = useCallback(
    values => {
      dispatch(
        createSelfAccount(
          {
            [NAME]: values[NAME],
            agency: agencyId,
            controller: controllerId
          },
          true
        )
      )
    },
    [dispatch, agencyId, controllerId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const logoutHandler = useCallback(() => {
    handleLogout()
  }, [])

  useEffect(() => {
    if (selfAccountWasCreated) {
      dispatch(clearCreateSelfAccount())
    }
  }, [dispatch, selfAccountWasCreated])

  return (
    <>
      <div className={authClasses.formContainer}>
        <Form
          formName="agencyAdminSelfAccountCreate"
          formik={formik}
          successSubmit={selfAccountWasCreated}
          errorSelector={createSelfAccountErrorSelector}
          isLoadingSelector={createSelfAccountIsLoadingSelector}
          submitText={t('Save')}
          buttonProps={buttonProps}
        >
          <AgencyAdminSelfAccountCreateContent formik={formik} />
        </Form>
        <p className={classes.footer}>
          {t('Not ready?')}{' '}
          <u className={classes.logout} onClick={logoutHandler}>
            {t('Log out')}
          </u>
        </p>
      </div>
    </>
  )
}

export default AgencyAdminSelfAccountCreateForm
