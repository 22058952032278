import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import Field from '../../../../../../components/Form/Field'
import EditForm from '../../../../../../features/components/Forms/EditForm'

import { clearUpdateCampaign, updateCampaign } from '../../../../../../modules/actions/campaigns'

import {
  campaignUpdateErrorSelector,
  campaignUpdateIsLoadingSelector,
  campaignWasUpdatedSelector
} from '../../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector, selectedAdAccountDataSelector } from '../../../../../../modules/selectors/app'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import { BUDGET_DAILY, BUDGET_LIFETIME } from '../../../../../ReusableFormFields/CampaignForms/fields'
import { getValidationSchema } from './validation'

const BudgetSectionForm = ({ campaign, budgetType, ...formProps }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const successUpdate = useSelector(campaignWasUpdatedSelector)
  const { currency_symbol } = useSelector(selectedAdAccountDataSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const handleSubmit = useCallback(
    values => {
      const { budget_lifetime, budget_daily } = values
      const updateCampaignData = {
        account: selectedAdAccountId,
        ...(budgetType === BUDGET_LIFETIME && { budget_lifetime }),
        ...(budgetType === BUDGET_DAILY && { budget_daily })
      }

      dispatch(updateCampaign(updateCampaignData, campaign.id))
    },
    [dispatch, budgetType, campaign.id, selectedAdAccountId]
  )

  const initialValues = useMemo(
    () => ({
      [BUDGET_LIFETIME]: (campaign && campaign.budget_lifetime) || '',
      [BUDGET_DAILY]: (campaign && campaign.budget_daily) || ''
    }),
    [campaign]
  )

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: getValidationSchema(budgetType),
    onSubmit: handleSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const handleClearUpdateCampaign = useCallback(() => {
    dispatch(clearUpdateCampaign())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={successUpdate}
      clearEditItem={handleClearUpdateCampaign}
      errorSelector={campaignUpdateErrorSelector}
      isLoadingSelector={campaignUpdateIsLoadingSelector}
      {...formProps}
    >
      {budgetType === BUDGET_LIFETIME && (
        <>
          <p>{t('A total budget for the whole campaign.')}</p>
          <p>{t('Facebook will allocate your budget across all your Ad Sets based on performance.')}</p>
          <Field
            type="number"
            formik={purifiedFormik}
            name={BUDGET_LIFETIME}
            placeholder="Lifetime Budget"
            symbol={currency_symbol}
            autoComplete="off"
          />
        </>
      )}
      {budgetType === BUDGET_DAILY && (
        <>
          <p>{t('Set an average amount you want to spend each day.')}</p>
          <p>
            {t(
              'Some functions (such as targeting ads during specific times of day) aren’t available if you select this option, so we don’t usually recommend it.'
            )}
          </p>
          <Field
            type="number"
            formik={purifiedFormik}
            name={BUDGET_DAILY}
            placeholder="Daily Budget"
            symbol={currency_symbol}
            autoComplete="off"
          />
        </>
      )}
    </EditForm>
  )
}

export default BudgetSectionForm
