import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import Table from '../../../../../components/Table'
import Chip from '../../../../../components/Chip'
import ActionsDropdown from '../../../../../features/components/ActionsDropdown'
import ActionsButtonsList from '../../../../../features/components/ActionsButtonsList'

import { getChipColorByStatus } from './helpers'
import { insertIf } from '../../../../../helpers/common'
import { formatCurrency } from '../../../../../helpers/numbers'

import { updateStatement } from '../../../../../modules/actions/statements'
import { openForm } from '../../../../../modules/actions/forms'
import { selectedControllerDataSelector } from '../../../../../modules/selectors/app'
import {
  updateStatementIDSelector,
  updateStatementIsLoadingSelector
} from '../../../../../modules/selectors/statements'

import { STATUS_INVOICED, STATUS_PAID, STATUS_READY } from '../../InvoicesFilters/InvoicingStatusFilter/options'
import { tableColumnsSize } from '../index'
import { INVOICE_INFORMATION_FORM } from '../../../../../constants/forms'
import { phonesDownSize } from '../../../../../styles/const/breakpoints'

import useStyles from './styles'

const InvoicesTableData = ({ invoices }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const updateStatementIsLoading = useSelector(updateStatementIsLoadingSelector)
  const updateStatementID = useSelector(updateStatementIDSelector)
  const selectedSelfAccountController = useSelector(selectedControllerDataSelector)

  const classes = useStyles()

  const handleChangeStatus = useCallback(
    ({ status, id }) =>
      () => {
        dispatch(updateStatement(id, { status }))
      },
    [dispatch]
  )

  const getItemUpdatingId = useMemo(() => {
    if (updateStatementIsLoading) {
      return updateStatementID
    } else {
      return undefined
    }
  }, [updateStatementIsLoading, updateStatementID])

  const openInvoiceFormHandler = useCallback(
    id => () => {
      dispatch(openForm({ id, formName: INVOICE_INFORMATION_FORM }))
    },
    [dispatch]
  )

  const dropdownOptions = useCallback(
    ({ status, id }) => {
      return [
        ...insertIf(status === STATUS_READY, {
          text: t('Set as invoiced'),
          onClickHandler: handleChangeStatus({ status: STATUS_INVOICED, id })
        }),
        ...insertIf(status === STATUS_READY || status === STATUS_INVOICED, {
          text: t('Set as paid'),
          onClickHandler: handleChangeStatus({ status: STATUS_PAID, id })
        }),
        ...insertIf(status !== STATUS_READY, {
          text: t('Clear status'),
          onClickHandler: handleChangeStatus({ status: STATUS_READY, id })
        }),
        {
          text: t('View statement'),
          onClickHandler: openInvoiceFormHandler(id)
        }
        // Add when backend will be updated
        // {
        //   text: t('Download statement'),
        //   onClickHandler: () => {}
        // }
      ]
    },
    [t, handleChangeStatus, openInvoiceFormHandler]
  )

  const invoicesListColumns = useMemo(
    () => [
      {
        header: 'ID',
        fieldKey: 'id',
        style: { maxWidth: tableColumnsSize.id }
      },
      {
        header: 'Name',
        Cell: ({ name, id }) => (
          <div className={classes.nameField} onClick={openInvoiceFormHandler(id)}>
            {name}
          </div>
        )
      },
      {
        header: 'Account',
        fieldKey: 'account_name',
        style: { maxWidth: tableColumnsSize.account },
        showOnMobile: false
      },
      {
        header: 'Status',
        Cell: ({ status }) => {
          if (!status || status === STATUS_READY) {
            return ''
          }
          return <Chip text={status} color={getChipColorByStatus(status)} />
        },
        style: { maxWidth: tableColumnsSize.status },
        showOnMobile: false
      },
      {
        header: 'Total (excl. GST)',
        Cell: ({ total_sum }) => {
          return formatCurrency(total_sum, { max: 0 }, { symbol: selectedSelfAccountController.currency_symbol })
        },
        style: { maxWidth: tableColumnsSize.total },
        showOnMobile: false
      },
      {
        Cell: invoice =>
          isMobile ? (
            <ActionsButtonsList itemId={invoice.id} options={dropdownOptions(invoice)} />
          ) : (
            <ActionsDropdown itemId={invoice.id} options={dropdownOptions(invoice)} />
          ),
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [dropdownOptions, selectedSelfAccountController, classes.nameField, openInvoiceFormHandler, isMobile]
  )

  return (
    <Table
      hideFooterRow
      data={invoices}
      cols={invoicesListColumns}
      itemUpdatingId={getItemUpdatingId}
      mobileColumnsWrapperClassName={classes.mobileHeaderStyle}
    />
  )
}

InvoicesTableData.propTypes = {
  invoices: PropTypes.array.isRequired
}

export default InvoicesTableData
