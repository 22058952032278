import React from 'react'
import PropTypes from 'prop-types'

import Toggle from '../../../../../../../../components/Toggle'
import Can from '../../../../../../../../features/components/Can'
import ActionsDropdown from '../../../../../../../../features/components/ActionsDropdown'

import { AD_PERMISSION } from '../../../../../../../../constants/permissions'

import useStyles from './styles'

const AdCardActions = ({ ad, getAdActionsDropdownOptions, updateAdStatusHandler }) => {
  const classes = useStyles()

  const hasManageAd = !!getAdActionsDropdownOptions || !!updateAdStatusHandler

  return (
    hasManageAd && (
      <div className={classes.manageAdWrapper}>
        {updateAdStatusHandler && (
          <Can I="update" a={AD_PERMISSION}>
            <Toggle onToggle={() => updateAdStatusHandler(ad)} isInitiallyEnabled={ad.status === 'active'} />
          </Can>
        )}
        {getAdActionsDropdownOptions && (
          <ActionsDropdown
            itemId={ad.id}
            options={getAdActionsDropdownOptions(ad)}
            actionsListWrapperClassName={classes.actionsListWrapper}
            className={classes.actionsDropdownMenu}
            closeOnInsideClick
          />
        )}
      </div>
    )
  )
}

AdCardActions.propTypes = {
  ad: PropTypes.object.isRequired,
  getAdActionsDropdownOptions: PropTypes.func,
  updateAdStatusHandler: PropTypes.func
}

export default AdCardActions
