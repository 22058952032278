import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../components/Button'

import useLineItemDetails from '../AdFacebookCreateForm/hooks/useLineItemDetails'
import useCampaignDetails from '../AdFacebookCreateForm/hooks/useCampaignDetails'

import { redirectTo } from '../../../../../helpers/url'

import { closeForm, openForm } from '../../../../../modules/actions/forms'
import { selectedSelfAccountSelector } from '../../../../../modules/selectors/app'

import { ROUTE_PARAMS, ROUTES } from '../../../../../constants/routes'
import { LINE_ITEM_FACEBOOK_EDIT } from '../../../../../constants/forms'
import { FACEBOOK_PLATFORM } from '../../../../../constants/selectLists/platformList'

import useStyles from './styles'

const AdSetEndDateWarning = ({ adAccountId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const selfAccountId = useSelector(selectedSelfAccountSelector)

  const classes = useStyles()

  const { lineItemId } = useLineItemDetails()
  const { campaignId } = useCampaignDetails()

  const editAdSetHandler = useCallback(() => {
    dispatch(closeForm())

    redirectTo(`${ROUTES.lineItemsSummary}?form=${LINE_ITEM_FACEBOOK_EDIT}&itemId=${lineItemId}`, {
      [ROUTE_PARAMS.platform]: FACEBOOK_PLATFORM,
      [ROUTE_PARAMS.selfAccount]: selfAccountId,
      [ROUTE_PARAMS.adAccount]: adAccountId,
      [ROUTE_PARAMS.campaignId]: campaignId,
      [ROUTE_PARAMS.lineItemId]: lineItemId
    })

    dispatch(openForm({ id: lineItemId, formName: LINE_ITEM_FACEBOOK_EDIT }))
  }, [dispatch, selfAccountId, adAccountId, campaignId, lineItemId])

  return (
    <>
      <p>
        {t(
          "Ads can't be created in ad sets that have ended. Please go back and edit the ad set end date to be able to create an ad."
        )}
      </p>
      <div className={classes.warningFooter}>
        {lineItemId && (
          <Button className="btnBlock" onClick={editAdSetHandler}>
            {t('Edit ad set')}
          </Button>
        )}
      </div>
    </>
  )
}

export default AdSetEndDateWarning
