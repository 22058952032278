// import { calculateMargins } from '../../../../../../forms/ReusableFormFields/MarginFields/formatters'
// import { keys } from '../../../../../../helpers/common'
import { formatBrandsToBE } from '../../formatters'

import { NAME } from '../../fields'
import {
  AUTH_USER,
  BRANDS,
  CURRENCY,
  CUSTOMER_ID,
  DV360_AD_ACCOUNT_DATA,
  FACEBOOK_AD_ACCOUNT_DATA,
  GOOGLE_AD_ACCOUNT_DATA,
  // OFFSITE_CAMPAIGN_TYPES,
  // routesList,
  TAGS,
  TIKTOK_AD_ACCOUNT_DATA
} from './fields'
import { ALLOW_CUSTOM_CLICK_THROUGH_LINK } from '../../../../../../forms/Multiplatform/UploadAdCreative/fields'
import { REPRESENTATIVE } from '../../../../../../constants/mediaOrders'

const createAdAccountPayload = (adAccountData, authUserValue) => ({
  ...adAccountData,
  ...(authUserValue && {
    auth_user: authUserValue
  }),
  // todo check this field, as currently BE fails
  tos_accepted: true
})
export const transformValuesToBE = ({ values, controllerId, isController }) => {
  // const calculatedMargins = calculateMargins(values)
  // const margins = keys(calculatedMargins).map(platform => ({
  //   provider: platform,
  //   margin: calculatedMargins[platform]
  // }))

  const providerAdAccounts = []

  if (Object.keys(values[FACEBOOK_AD_ACCOUNT_DATA]).length) {
    providerAdAccounts.push(createAdAccountPayload(values[FACEBOOK_AD_ACCOUNT_DATA], values[AUTH_USER]))
  }

  if (Object.keys(values[GOOGLE_AD_ACCOUNT_DATA]).length) {
    providerAdAccounts.push(createAdAccountPayload(values[GOOGLE_AD_ACCOUNT_DATA], values[AUTH_USER]))
  }

  if (Object.keys(values[DV360_AD_ACCOUNT_DATA]).length) {
    providerAdAccounts.push(createAdAccountPayload(values[DV360_AD_ACCOUNT_DATA], values[AUTH_USER]))
  }

  if (Object.keys(values[TIKTOK_AD_ACCOUNT_DATA]).length) {
    providerAdAccounts.push(createAdAccountPayload(values[TIKTOK_AD_ACCOUNT_DATA], values[AUTH_USER]))
  }

  const formattedFilledBrands = formatBrandsToBE(values[BRANDS])

  return {
    name: values[NAME],
    // ...{ margin_settings: margins },
    ...(providerAdAccounts.length && { provider_ad_accounts: providerAdAccounts }),
    [TAGS]: values[TAGS].map(item => item.value),
    ...(formattedFilledBrands.length && { [BRANDS]: formattedFilledBrands }),
    controller: controllerId,
    [CURRENCY]: values[CURRENCY],
    // custom click through link default it to checked for non controllers
    [ALLOW_CUSTOM_CLICK_THROUGH_LINK]: isController ? values[ALLOW_CUSTOM_CLICK_THROUGH_LINK] : true,
    // default all newly created accounts to have all campaign types options enabled:
    // [OFFSITE_CAMPAIGN_TYPES]: routesList.map(route => route.name),
    [REPRESENTATIVE]: values[REPRESENTATIVE],
    [CUSTOMER_ID]: values[CUSTOMER_ID]
  }
}
