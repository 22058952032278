import { createStyles } from '../../../../../../../styles/helpers'

import { textGrey } from '../../../../../../../styles/const/colors'

const useStyles = createStyles({
  modal: {
    maxWidth: 760,
    height: 'calc(100% - 70px)',
    overflow: 'hidden'
  },
  modalContent: {
    marginTop: 0,
    height: '100%',
    justifyContent: 'flex-start'
  },
  modalBody: {
    maxWidth: '100%',
    height: '100%'
  },
  container: {
    width: '100%',
    textAlign: 'left',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 8,
    color: textGrey
  },
  description: {
    marginBottom: 16
  },
  productsGrid: {
    width: '100%',
    overflowY: 'scroll'
  },
  noProductsMessage: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export default useStyles
