import React, { memo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import useStyles from '../styles'

const TikTokVideoThumbnailItem = memo(({ thumbnail, onThumbnailSelectHandler, isSelected }) => {
  const classes = useStyles()

  return (
    <img
      src={thumbnail}
      alt="thumbnail"
      onClick={() => onThumbnailSelectHandler(thumbnail)}
      className={classnames(classes.thumbnailImage, {
        [classes.thumbnailImageSelected]: isSelected
      })}
    />
  )
})

TikTokVideoThumbnailItem.propTypes = {
  thumbnail: PropTypes.string.isRequired,
  onThumbnailSelectHandler: PropTypes.func.isRequired,
  isSelected: PropTypes.bool
}

export default TikTokVideoThumbnailItem
