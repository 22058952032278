import { createStyles } from '../../../../styles/helpers'

import { grey } from '../../../../styles/const/colors'

const useStyles = createStyles(theme => ({
  header: {
    fontSize: 16,
    marginBottom: 8
  },
  description: {
    fontSize: 12,
    marginBottom: 20
  },
  itemCategory: {
    fontSize: 12,
    color: grey,
    textTransform: 'uppercase',
    marginBottom: 8
  }
}))

export default useStyles
