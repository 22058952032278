import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import HighlightedValueSelect from '../../../../components/HighlightedValueSelect'
import { BookedMediaFiltersContext } from '../../../../pages/Settings/BookedMediaAndFiles/BookedMediaAndFilesFilters/BookedMediaFiltersContext'

import { formatOptionsList } from '../../../formatters'

import { clearGetProductTags, getProductTags } from '../../../../modules/actions/tags'
import { mediaProductTagsParams } from '../../mediaProductsComponents/MediaProductsFilters'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import { productTagsSelector } from '../../../../modules/selectors/tags'

import useStyles from '../../../../pages/Settings/BookedMediaAndFiles/BookedMediaAndFilesFilters/styles'

const ProductTagsFilter = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const tags = useSelector(productTagsSelector)
  const selfAccountControllerId = useSelector(selectedControllerIdSelector)

  const { selectedTag, setSelectedTag } = useContext(BookedMediaFiltersContext)

  const tagsOptions = useMemo(() => {
    return formatOptionsList({
      list: tags,
      labelName: 'title',
      valueName: 'title'
    })
  }, [tags])

  const handleChangeFilter = useCallback(
    option => {
      setSelectedTag(option?.value)
    },
    [setSelectedTag]
  )

  useEffect(() => {
    dispatch(getProductTags({ ...mediaProductTagsParams, controller: selfAccountControllerId }))
  }, [dispatch, selfAccountControllerId])

  useEffect(
    () => () => {
      dispatch(clearGetProductTags())
    },
    [dispatch]
  )

  return (
    <HighlightedValueSelect
      value={selectedTag}
      onChange={handleChangeFilter}
      className={classes.select}
      options={tagsOptions}
      placeholder="Filter"
      isSmall
    />
  )
}

export default ProductTagsFilter
