import update from 'immutability-helper'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  SET_CLIENT_VIEW,
  GET_USER_INVITES,
  GET_USER_INVITES_SUCCESS,
  GET_USER_INVITES_FAILURE,
  CLEAR_GET_USER_INVITES,
  SUBMIT_SERVER_LOGOUT,
  SUBMIT_SERVER_LOGOUT_SUCCESS,
  SUBMIT_SERVER_LOGOUT_FAILURE,
  CLEAR_SUBMIT_SERVER_LOGOUT,
  GET_CURRENT_USER_PROFILE,
  GET_CURRENT_USER_PROFILE_SUCCESS,
  GET_CURRENT_USER_PROFILE_FAILURE,
  UPDATE_CURRENT_USER_PROFILE,
  UPDATE_CURRENT_USER_PROFILE_SUCCESS,
  UPDATE_CURRENT_USER_PROFILE_FAILURE,
  CLEAR_UPDATE_CURRENT_USER_PROFILE,
  VERIFY_CURRENT_USER,
  VERIFY_CURRENT_USER_SUCCESS,
  VERIFY_CURRENT_USER_FAILURE,
  CLEAR_VERIFY_CURRENT_USER,
  SET_MFA_TYPE,
  SET_MFA_TYPE_SUCCESS,
  SET_MFA_TYPE_FAILURE,
  CLEAR_SET_MFA_TYPE,
  CREATE_CURRENT_USER_SELF_ACCOUNT,
  UPDATE_CURRENT_USER_SELF_ACCOUNT,
  SET_SELECTED_PLATFORM,
  SET_SEARCH,
  CLEAR_SEARCH,
  UPDATE_CURRENT_USER_CONTROLLER,
  SET_SELECTED_SELF_ACCOUNT,
  APPROVE_PLATFORM_CONNECTION,
  GET_BANNERS,
  GET_BANNERS_SUCCESS,
  GET_BANNERS_FAILURE,
  GET_CONTROLLER_LANDING_PAGE_SETUP,
  GET_CONTROLLER_LANDING_PAGE_SETUP_SUCCESS,
  GET_CONTROLLER_LANDING_PAGE_SETUP_FAILURE,
  CLEAR_GET_CONTROLLER_LANDING_PAGE_SETUP,
  GET_ASYNC_SESSION,
  GET_ASYNC_SESSION_SUCCESS,
  GET_ASYNC_SESSION_FAILURE,
  CLEAR_ASYNC_SESSION,
  SET_PDF_GENERATION,
  SET_SELECTED_LANGUAGE,
  SHOW_MFA_SETUP_PAGE,
  UPDATE_CURRENT_USER_PROFILE_AGENCIES,
  SET_CONTEXT_SELF_ACCOUNT
} from '../actions/app'

import { updateItem } from '../../helpers/modules/reducerHelpers'
import { getInitialPlatform } from '../../helpers/handlePlatformSelection'
import { getParameterFromPath } from '../../helpers/url'
import { getSelectedLanguage } from '../../helpers/other'
import { getControllerDefaultLanguage, handleChangeLanguage, LANGUAGE, SWITCHED_LANGUAGE } from '../../helpers/language'
import { getInitialSelfAccount } from '../../helpers/handleSelfAccountSelection'

import { ROUTE_PARAMS } from '../../constants/routes'
import {
  INITIAL_CREATE_REDUCER,
  INITIAL_GET_REDUCER,
  INITIAL_GET_RESULTS_REDUCER,
  INITIAL_UPDATE_REDUCER
} from '../../constants/reducer'
import { getLocalStorage, setLocalStorage } from '../../helpers/storage'

const selfAccountFromPath = parseInt(getParameterFromPath(ROUTE_PARAMS.selfAccount))

export const SELECTED_PLATFORM = 'selectedPlatform'
export const SELECTED_SELF_ACCOUNT = 'selectedSelfAccount'
export const CONTROLLER_LANDING_PAGE_VIEW = 'controllerLandingPageView'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  isClientView: getLocalStorage(CONTROLLER_LANDING_PAGE_VIEW) === 'true',
  userProfile: INITIAL_GET_REDUCER,
  userInvites: INITIAL_GET_REDUCER,
  updateUserProfile: INITIAL_UPDATE_REDUCER,
  submitServerLogout: INITIAL_CREATE_REDUCER,
  verifyCurrentUser: {
    ...INITIAL_UPDATE_REDUCER,
    data: {}
  },
  showMFASetupPage: false,
  setMFAType: INITIAL_CREATE_REDUCER,
  [SELECTED_SELF_ACCOUNT]: selfAccountFromPath,
  contextSelfAccount: undefined, // higher priority self account then SELECTED_SELF_ACCOUNT, used in specific contexts
  [SELECTED_PLATFORM]: getInitialPlatform(),
  searchTerm: {},
  bannersData: INITIAL_GET_RESULTS_REDUCER,
  getControllerLandingPageSetup: INITIAL_GET_RESULTS_REDUCER,
  asyncSession: INITIAL_GET_REDUCER,
  selectedLanguage: getSelectedLanguage(),
  pdfGeneration: false
}

export default function app(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    case SET_CLIENT_VIEW:
      return update(state, {
        isClientView: { $set: action.isClientView }
      })
    case GET_CURRENT_USER_PROFILE:
      return update(state, {
        userProfile: {
          $merge: {
            isLoading: true,
            wasLoaded: false
          }
        }
      })
    case GET_CURRENT_USER_PROFILE_SUCCESS:
      // get SWITCHED_LANGUAGE if the data loaded after language switched
      // otherwise use saved language from user settings or default fallback
      const initialLanguage =
        getLocalStorage(SWITCHED_LANGUAGE) || action.userData.settings?.[LANGUAGE]?.code || state.selectedLanguage
      setLocalStorage(SWITCHED_LANGUAGE, undefined) // reset SWITCHED_LANGUAGE after use

      // todo replace with selectors
      const initialSelfAccount = getInitialSelfAccount(action.userData.accounts, state[SELECTED_SELF_ACCOUNT])
      const controllerId = action.userData.accounts?.find(
        account => parseInt(account.id) === parseInt(initialSelfAccount)
      )?.controller
      const controllerData = action.userData.controllers?.find(controller => controller.id === controllerId)

      // language to set, if controller is not found, set default language
      const newLanguage = getControllerDefaultLanguage(controllerData, initialLanguage)

      // if call only when language is changed, it will not cover case when user logout and login again with another language,
      // so it should be called everytime user profile is loaded

      handleChangeLanguage(newLanguage)

      return update(state, {
        userProfile: {
          $merge: {
            isLoading: false,
            data: action.userData,
            wasLoaded: true
          }
        },
        // initial account should be set only after validation of accounts received from BE
        [SELECTED_SELF_ACCOUNT]: {
          $set: initialSelfAccount
        },
        selectedLanguage: { $set: newLanguage }
      })
    case GET_CURRENT_USER_PROFILE_FAILURE:
      return update(state, {
        userProfile: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })

    // Submit server logout
    case SUBMIT_SERVER_LOGOUT:
      return update(state, {
        submitServerLogout: { $merge: { isLoading: true } }
      })

    // Get user invites
    case GET_USER_INVITES:
      return update(state, {
        userInvites: { $merge: { isLoading: true } }
      })
    case GET_USER_INVITES_SUCCESS:
      return update(state, {
        userInvites: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        }
      })
    case GET_USER_INVITES_FAILURE:
      return update(state, {
        userInvites: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_USER_INVITES:
      return update(state, {
        userInvites: { $set: initialState.userInvites }
      })

    case SUBMIT_SERVER_LOGOUT_SUCCESS:
      return update(state, {
        submitServerLogout: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        }
      })
    case SUBMIT_SERVER_LOGOUT_FAILURE:
      return update(state, {
        submitServerLogout: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_SUBMIT_SERVER_LOGOUT:
      return update(state, {
        submitServerLogout: { $set: initialState.submitServerLogout }
      })

    case UPDATE_CURRENT_USER_PROFILE:
      return update(state, {
        updateUserProfile: {
          isLoading: { $set: true }
        }
      })
    case UPDATE_CURRENT_USER_PROFILE_SUCCESS:
      return update(state, {
        userProfile: {
          data: { $merge: action.userData }
        },
        updateUserProfile: {
          isLoading: { $set: false },
          wasUpdated: { $set: true }
        }
      })
    case UPDATE_CURRENT_USER_PROFILE_FAILURE:
      return update(state, {
        updateUserProfile: {
          isLoading: { $set: false },
          error: { $set: action.error }
        }
      })
    case CLEAR_UPDATE_CURRENT_USER_PROFILE:
      return update(state, {
        updateUserProfile: { $set: initialState.updateUserProfile }
      })

    // Verify current user
    case VERIFY_CURRENT_USER:
      return update(state, {
        verifyCurrentUser: { $merge: { data: action.data, isLoading: true } }
      })
    case VERIFY_CURRENT_USER_SUCCESS:
      return update(state, {
        verifyCurrentUser: {
          $merge: {
            isLoading: false,
            wasUpdated: true
          }
        },
        userProfile: {
          // in case of successful verification, merge verified data with existing one
          data: { $merge: state.verifyCurrentUser.data }
        }
      })
    case VERIFY_CURRENT_USER_FAILURE:
      return update(state, {
        verifyCurrentUser: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_VERIFY_CURRENT_USER:
      return update(state, {
        verifyCurrentUser: { $set: initialState.verifyCurrentUser }
      })

    // Set mfa type
    case SHOW_MFA_SETUP_PAGE:
      return update(state, {
        showMFASetupPage: { $set: action.show }
      })
    // Set mfa type
    case SET_MFA_TYPE:
      return update(state, {
        setMFAType: { $merge: { isLoading: true } }
      })
    case SET_MFA_TYPE_SUCCESS:
      return update(state, {
        setMFAType: {
          $merge: {
            isLoading: false,
            wasCreated: true
          }
        },
        userProfile: {
          data: { $merge: action.data }
        }
      })
    case SET_MFA_TYPE_FAILURE:
      return update(state, {
        setMFAType: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_SET_MFA_TYPE:
      return update(state, {
        setMFAType: { $set: initialState.setMFAType }
      })

    case CREATE_CURRENT_USER_SELF_ACCOUNT:
      return update(state, {
        userProfile: {
          data: {
            accounts: { $push: [action.selfAccountData] }
          }
        }
      })
    case UPDATE_CURRENT_USER_SELF_ACCOUNT:
      return update(state, {
        userProfile: {
          data: {
            accounts: { $apply: items => updateItem(items, action.selfAccountData) }
          }
        }
      })

    case UPDATE_CURRENT_USER_PROFILE_AGENCIES:
      return update(state, {
        userProfile: {
          data: {
            agencies: { $set: action.agencies }
          }
        }
      })
    case UPDATE_CURRENT_USER_CONTROLLER:
      return update(state, {
        userProfile: {
          data: {
            controllers: { $apply: items => updateItem(items, action.controllerData) }
          }
        }
      })
    case SET_SELECTED_SELF_ACCOUNT:
      return update(state, {
        [SELECTED_SELF_ACCOUNT]: { $set: action.accountId },
        selectedLanguage: { $set: action.newSelectedLanguage || state.selectedLanguage }
      })
    case SET_CONTEXT_SELF_ACCOUNT:
      return update(state, {
        contextSelfAccount: { $set: action.accountId }
      })
    case SET_SELECTED_PLATFORM:
      return update(state, {
        [SELECTED_PLATFORM]: { $set: action.platform }
      })
    case SET_SEARCH:
      return update(state, {
        // all searchTerms should be set within the key
        searchTerm: { $set: { [action.searchKey]: action.searchTerm } }
      })
    case CLEAR_SEARCH:
      return update(state, {
        searchTerm: { $set: initialState.searchTerm }
      })

    case APPROVE_PLATFORM_CONNECTION:
      return update(state, {
        userProfile: {
          data: {
            $merge: action.authData
          }
        }
      })
    case GET_BANNERS:
      return update(state, {
        bannersData: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_BANNERS_SUCCESS:
      return update(state, {
        bannersData: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.bannersData.results
          }
        }
      })
    case GET_BANNERS_FAILURE:
      return update(state, {
        bannersData: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })

    // Get controller landing page setup
    case GET_CONTROLLER_LANDING_PAGE_SETUP:
      return update(state, {
        getControllerLandingPageSetup: { $merge: { isLoading: true } }
      })
    case GET_CONTROLLER_LANDING_PAGE_SETUP_SUCCESS:
      return update(state, {
        getControllerLandingPageSetup: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data?.results
          }
        }
      })
    case GET_CONTROLLER_LANDING_PAGE_SETUP_FAILURE:
      return update(state, {
        getControllerLandingPageSetup: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_CONTROLLER_LANDING_PAGE_SETUP:
      return update(state, {
        getControllerLandingPageSetup: { $set: initialState.getControllerLandingPageSetup }
      })

    // get async session
    case GET_ASYNC_SESSION:
      return update(state, {
        asyncSession: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_ASYNC_SESSION_SUCCESS:
      return update(state, {
        asyncSession: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.sessionData
          }
        }
      })
    case GET_ASYNC_SESSION_FAILURE:
      return update(state, {
        asyncSession: {
          $merge: {
            isLoading: false,
            data: action.sessionData,
            error: action.error
          }
        }
      })
    case CLEAR_ASYNC_SESSION:
      return update(state, {
        asyncSession: { $set: initialState.asyncSession }
      })
    case SET_PDF_GENERATION:
      return update(state, {
        pdfGeneration: { $set: action.isGenerating }
      })
    case SET_SELECTED_LANGUAGE:
      return update(state, {
        selectedLanguage: { $set: action.languageCode }
      })
    default:
      return state
  }
}
