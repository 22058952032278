import * as Yup from 'yup'
import { format } from 'date-fns'

import {
  OUTCOME_AWARENESS,
  OUTCOME_ENGAGEMENT,
  OUTCOME_SALES,
  OUTCOME_TRAFFIC,
  REACH
} from '../../../../../constants/campaigns'
import { DATES_FORMAT_BE, formatDateShort, formatDateToBE } from '../../../../../constants/dates'
import { getBudgetValidation } from '../../../../Multiplatform/UploadAdCreative/fields'

// campaign
export const CAMPAIGN_OPTION = 'campaign_option'
export const CAMPAIGN_OPTION_ADVANTAGE_SHOPPING = 'campaign_option_advantage_shopping'
export const CAMPAIGN_OPTION_PRODUCT_RETARGETING = 'campaign_option_product_retargeting'
export const CAMPAIGN_OPTION_PROXIMITY_CAMPAIGN = 'campaign_option_proximity_campaign'
export const CAMPAIGN_OPTION_AUDIENCE_TARGETING = 'campaign_option_audience_targeting'
export const CAMPAIGN_OPTION_ADVANCED_SETUP = 'campaign_option_advanced_setup'
export const CAMPAIGN_OPTION_EXISTING_CAMPAIGN = 'campaign_option_existing_campaign'

export const CAMPAIGN = 'campaign'

export const OBJECTIVE = 'objective'
export const BUDGET_LIFETIME = 'budget_lifetime'

export const getInitialValues = initialCampaignOption => {
  return {
    // campaign
    [CAMPAIGN_OPTION]: initialCampaignOption,
    [CAMPAIGN]: '',
    [OBJECTIVE]: '',
    [BUDGET_LIFETIME]: ''
  }
}

export const getValidationSchema = ({ remainingBudget, currencySymbol }) => {
  return Yup.object({
    [CAMPAIGN]: Yup.string().when(CAMPAIGN_OPTION, {
      is: CAMPAIGN_OPTION_EXISTING_CAMPAIGN,
      then: () => Yup.string().required('Please select campaign')
    }),
    [OBJECTIVE]: Yup.string().when(CAMPAIGN_OPTION, {
      is: CAMPAIGN_OPTION_ADVANCED_SETUP,
      then: () => Yup.string().required('Please select goal')
    }),
    [BUDGET_LIFETIME]: Yup.number()
      .when(CAMPAIGN_OPTION, {
        is: CAMPAIGN_OPTION_ADVANCED_SETUP,
        then: () =>
          Yup.number().when(OBJECTIVE, {
            is: value => value !== OUTCOME_AWARENESS && value !== REACH,
            then: () => getBudgetValidation({ remainingBudget, currencySymbol })
          })
      })
      .when(CAMPAIGN_OPTION, {
        is: value => value === CAMPAIGN_OPTION_PRODUCT_RETARGETING || value === CAMPAIGN_OPTION_AUDIENCE_TARGETING,
        then: () => getBudgetValidation({ remainingBudget, currencySymbol })
      })
  })
}

export const objectivesList = [
  {
    value: REACH,
    label: 'Reach'
  },
  {
    value: OUTCOME_AWARENESS,
    label: 'Awareness'
  },
  {
    value: OUTCOME_SALES,
    label: 'Sales'
  },
  {
    value: OUTCOME_ENGAGEMENT,
    label: 'Engagement'
  },
  {
    value: OUTCOME_TRAFFIC,
    label: 'Traffic'
  }
]

export const transformValuesToAdvantageShoppingCampaignBE = adAccountId => {
  const formattedTodayDate = formatDateShort(new Date())

  return {
    account: adAccountId,
    name: `Advantage+ shopping campaign - ${formattedTodayDate}`,
    objective: 'outcome_sales',
    buying_type: 'auction',
    smart_promotion_type: 'AUTOMATED_SHOPPING_ADS',
    status: 'active'
  }
}

export const transformValuesToRetargetingCampaignBE = (values, adAccountId) => {
  const formattedTodayDate = formatDateToBE(new Date())

  return {
    account: adAccountId,
    name: `${formattedTodayDate} Product Retargeting`,
    status: 'active',
    buying_type: 'auction',
    objective: 'outcome_sales',
    special_ad_categories: [],
    bidding_type: 'automatic',
    budget_lifetime: values[BUDGET_LIFETIME]
  }
}

export const transformValuesToProximityCampaignBE = (values, adAccountId) => {
  const formattedTodayDate = format(new Date(), DATES_FORMAT_BE)

  return {
    account: adAccountId,
    name: `Proximity Campaign - ${formattedTodayDate}`,
    status: 'active',
    buying_type: 'auction',
    objective: 'outcome_traffic',
    budget_lifetime: values[BUDGET_LIFETIME],
    bidding_type: 'automatic'
  }
}

export const transformValuesToAudienceTargetingCampaignBE = (values, adAccountId, mediaOrder) => {
  const { name: mediaOrderName } = mediaOrder

  return {
    account: adAccountId,
    name: `${mediaOrderName} (Sales) - Facebook`,
    status: 'active',
    buying_type: 'auction',
    objective: 'outcome_sales',
    budget_lifetime: values[BUDGET_LIFETIME],
    bidding_type: 'automatic'
  }
}

export const transformValuesToAdvancedCampaignBE = (values, adAccountId, mediaOrder) => {
  const { name: mediaOrderName } = mediaOrder

  const campaignObjectiveLabel = objectivesList.find(objective => objective.value === values[OBJECTIVE])?.label

  return {
    account: adAccountId,
    name: `${mediaOrderName} (${campaignObjectiveLabel}) - Facebook`,
    status: 'active',
    buying_type: 'auction',
    [OBJECTIVE]: values[OBJECTIVE],
    ...(values[OBJECTIVE] !== OUTCOME_AWARENESS &&
      values[OBJECTIVE] !== REACH && {
        [BUDGET_LIFETIME]: values[BUDGET_LIFETIME],
        bidding_type: 'automatic'
      })
  }
}
