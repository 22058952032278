import { createStyles } from '../../styles/helpers'

import { mediumGrey, red } from '../../styles/const/colors'
import { transitionShort } from '../../styles/const/common'
import { tabletDown } from '../../styles/const/breakpoints'

export default createStyles(theme => ({
  btn: {
    border: {
      width: 1,
      style: 'solid',
      color: theme.brandPrimary
    },
    fontSize: 14,
    borderRadius: theme.defaultBorderRadius,
    transition: transitionShort,
    padding: ({ isSmall }) => (isSmall ? '8px 16px' : '10px 16px'),
    height: ({ isSmall }) => (isSmall ? 32 : 38),
    fontWeight: '600',
    lineHeight: 1,
    cursor: 'pointer',
    color: theme.brandPrimary,
    fontFamily: theme.fontFamily.value,
    background: 'white',
    position: 'relative',

    '&:focus': {
      outline: 'none'
    },

    '&:disabled': {
      color: mediumGrey,
      borderColor: mediumGrey,
      pointerEvents: 'none'
    },

    '&.buttonTouched, &:active': {
      color: theme.brandPrimaryTextContrastColor,
      backgroundColor: theme.brandPrimary,
      '& svg': {
        color: ({ iconHoverColor }) => iconHoverColor
      },
      '& path': {
        stroke: ({ iconHoverColor }) => iconHoverColor,
        fill: ({ iconHoverColor }) => iconHoverColor
      }
    },
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.brandPrimaryTextContrastColor,
        backgroundColor: theme.brandPrimary,
        '& svg': {
          color: ({ iconHoverColor }) => iconHoverColor
        },
        '& path': {
          stroke: ({ iconHoverColor }) => iconHoverColor,
          fill: ({ iconHoverColor }) => iconHoverColor
        }
      }
    },

    '&.solid': {
      backgroundColor: theme.brandPrimary,
      color: theme.brandPrimaryTextContrastColor,

      '&:disabled': {
        backgroundColor: mediumGrey
      },

      '&.buttonTouched, &:active': {
        color: theme.brandPrimary,
        backgroundColor: 'white',
        '& svg': {
          color: ({ iconHoverColor }) => iconHoverColor
        },
        '& path': {
          stroke: ({ iconHoverColor }) => iconHoverColor,
          fill: ({ iconHoverColor }) => iconHoverColor
        }
      },
      '@media (hover: hover)': {
        '&:hover': {
          color: theme.brandPrimary,
          backgroundColor: 'white',
          '& svg': {
            color: ({ iconHoverColor }) => iconHoverColor
          },
          '& path': {
            stroke: ({ iconHoverColor }) => iconHoverColor,
            fill: ({ iconHoverColor }) => iconHoverColor
          }
        }
      }
    },

    '&.danger': {
      color: red,
      borderColor: red,
      '&.solid': {
        color: 'white',
        background: red
      },
      '&.buttonTouched, &:active': {
        color: 'white',
        backgroundColor: red,
        borderColor: red,
        '&.solid': {
          color: red
        }
      },
      '@media (hover: hover)': {
        '&:hover': {
          color: 'white',
          backgroundColor: red,
          borderColor: red,
          '&.solid': {
            color: red
          }
        }
      }
    }
  },

  loader: {
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    maxHeight: '80%'
  },

  [`@media screen and (${tabletDown})`]: {
    btn: {
      width: '100%',
      maxWidth: '100%',
      height: 40
    }
  }
}))
