import React, { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'
import { useTranslation } from 'react-i18next'

import DatePickerPeriodSelector from './DatePickerPeriodSelector'
import ErrorMessage from '../../../../../../../../../../../components/Form/ErrorMessage'
import MultiSelectBox from '../../../../../../../../../../../features/components/Form/MultiSelectBox'

import { sortByStartDateValue } from './DatePickerPeriodSelector/helpers'

import { PERIOD_DAY, PRINT } from '../../../../../../../../../../../constants/mediaOrders'
import { SelectPeriodsFieldsContext } from '../SelectPeriodsFieldsContextProvider'

function BookingPeriodSelector({
  formik,
  periodOptions,
  productItemPath,
  productPublicationsDatesPath,
  showError,
  error
}) {
  const { t } = useTranslation()

  const { values, setFieldValue } = formik

  const {
    inventoryIsLoading,
    productPeriod: selectedProductPeriod,
    selectedProductCategory
  } = useContext(SelectPeriodsFieldsContext)

  // custom handle of setFieldValue, to sort periods
  const handlePeriodsValueChange = useCallback(
    (name, values) => {
      const newPeriods = [...values]
      // sort periods by start_date field from earliest to latest date
      const sortedPeriods = newPeriods.sort(sortByStartDateValue)
      setFieldValue(name, sortedPeriods)
    },
    [setFieldValue]
  )

  const sortedPeriodOptions = useMemo(() => {
    const sortedPeriods = [...periodOptions]
    // sort periodOptions by start_date field from earliest to latest date
    sortedPeriods.sort(sortByStartDateValue)
    return sortedPeriods
  }, [periodOptions])

  const selectedPeriods = getIn(values, productPublicationsDatesPath)

  return (
    <>
      {selectedProductPeriod === PERIOD_DAY ? (
        <DatePickerPeriodSelector
          formik={formik}
          selectedPeriods={selectedPeriods}
          periodOptions={sortedPeriodOptions}
          productItemPath={productItemPath}
          productPublicationsDatesPath={productPublicationsDatesPath}
        />
      ) : (
        <MultiSelectBox
          placeholder={t(selectedProductCategory === PRINT ? 'Add publication' : 'Add booking period')}
          name={productPublicationsDatesPath}
          options={sortedPeriodOptions}
          value={selectedPeriods}
          setFieldValue={handlePeriodsValueChange}
          isLoading={inventoryIsLoading}
          isOptionDisabled={option => option.disabled}
          expandMenu={true}
        />
      )}
      {error && showError && <ErrorMessage error={error} />}
    </>
  )
}

BookingPeriodSelector.propTypes = {
  formik: PropTypes.object,
  productItemPath: PropTypes.string,
  productPublicationsDatesPath: PropTypes.string,
  showError: PropTypes.bool,
  error: PropTypes.string
}
export default BookingPeriodSelector
