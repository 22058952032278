import React, { memo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as PlayIcon } from '../../../../../../assets/icons/tiktok/play.svg'

import useStyles from './styles'

const AdVideoElement = memo(({ videoUrl }) => {
  const classes = useStyles()

  const [isPlaying, setIsPlaying] = useState(false)
  const [videoIsLoaded, setVideoIsLoaded] = useState(false)

  const videoRef = useRef(null)

  return (
    <div
      className={classes.videoContainer}
      onClick={() => {
        if (isPlaying) {
          videoRef.current?.pause()
          setIsPlaying(false)
        }
      }}
    >
      <video
        src={videoUrl}
        className={classes.video}
        onEnded={() => setIsPlaying(false)}
        onLoadedData={() => setVideoIsLoaded(true)}
        autoPlay={false}
        ref={videoRef}
        loop={false}
      >
        <source src={videoUrl} />
      </video>
      {videoIsLoaded && !isPlaying && (
        <button
          className={classes.playButton}
          onClick={() => {
            videoRef.current?.play()
            setIsPlaying(true)
          }}
        >
          <PlayIcon />
        </button>
      )}
    </div>
  )
})

AdVideoElement.propTypes = {
  videoUrl: PropTypes.string
}

export default AdVideoElement
