import React from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'

import HeadlineField from './HeadlineField'
import DescriptionField from './DescriptionField'
import FacebookMediaUpload from '../../FacebookMediaUpload'
import ClickThroughLinkField from './ClickThroughLinkField'
import CallToActionFields from '../../../Facebook/ReusableFormFIelds/CallToActionFields'

import { FILE_URL } from '../../../Facebook/AdForms/fields'
import { CALL_TO_ACTION } from '../../CallToActionFields/fields'

const defaultSuggestions = {}
const ItemMediaFields = ({
  formik,
  itemPath,
  allowMultiple,
  adAccountId,
  mediaValidationSchema,
  suggestions = defaultSuggestions,
  campaignObjective
}) => {
  const { errors, touched } = formik

  const itemErrors = getIn(errors, itemPath)
  const itemTouched = getIn(touched, itemPath)

  const mediaUrlError = itemTouched?.[FILE_URL] && itemErrors?.[FILE_URL]

  return (
    <>
      <FacebookMediaUpload
        formik={formik}
        itemPath={itemPath}
        mediaValidationSchema={mediaValidationSchema}
        mediaUrlError={mediaUrlError}
        adAccountId={adAccountId}
      />
      <HeadlineField
        formik={formik}
        itemPath={itemPath}
        allowMultiple={allowMultiple}
        suggestions={suggestions.headline}
      />
      <DescriptionField
        formik={formik}
        itemPath={itemPath}
        allowMultiple={allowMultiple}
        suggestions={suggestions.description}
      />
      <CallToActionFields
        formik={formik}
        fieldName={`${itemPath}.${CALL_TO_ACTION}`}
        campaignObjective={campaignObjective}
      />
      <ClickThroughLinkField formik={formik} itemPath={itemPath} />
    </>
  )
}

ItemMediaFields.propTypes = {
  formik: PropTypes.object,
  itemPath: PropTypes.string,
  isItemActive: PropTypes.bool,
  allowMultiple: PropTypes.bool,
  mediaValidationSchema: PropTypes.object,
  adAccountId: PropTypes.string,
  suggestions: PropTypes.object,
  isSingleMedia: PropTypes.bool,
  campaignObjective: PropTypes.string.isRequired
}

export default ItemMediaFields
