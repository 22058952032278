import { createStyles } from '../../../../styles/helpers'

export default createStyles(theme => ({
  root: {
    display: 'block',
    borderRadius: theme.defaultBorderRadius,
    position: 'absolute',
    left: 0,
    top: '2px', // also used for Skeleton
    transition: 'top 0.2s ease',
    border: 'none',
    outline: 'none',
    textDecoration: 'none'
  }
}))
