// FILE
export const MEDIA_FILE_OPTION = 'media_file_option'
export const MEDIA_FILE_UPLOAD = 'media_file_upload'
export const MEDIA_FILE_IMAGE_USE_LIBRARY = 'media_file_image_use_library_image'
export const MEDIA_FILE_VIDEO_USE_LIBRARY = 'media_file_video_use_library_video'
export const UPLOADED_MEDIA_TYPE = 'uploaded_media_type'
export const AD_FILE = 'ad_file'
export const FILE_NAME = 'file_name'
export const FILE_URL = 'file_url'
export const MEDIA_TYPE = 'media_type'
export const FILE_TYPE = 'file_type'
export const FILE_HASH = 'file_hash'
export const FILE_ID = 'file_id'
export const VIDEO_PICTURE = 'video_picture'
// MEDIA
export const MEDIA_SIZE = 'media_size'
export const MEDIA_WIDTH = 'media_width'
export const MEDIA_HEIGHT = 'media_height'
export const MEDIA_FORMAT = 'media_format'
export const MEDIA_RATIO = 'media_ratio'
export const MEDIA_DURATION = 'video_duration'
export const IFRAME_SRC = 'iframe_src'
