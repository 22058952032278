import { createStyles } from '../../../../../../../../../styles/helpers'
import { phonesDown } from '../../../../../../../../../styles/const/breakpoints'

const useStyles = createStyles({
  bookedContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
    minWidth: 130
  },
  [`@media screen and (${phonesDown})`]: {
    bookedContainer: {
      marginBottom: 16
    }
  }
})

export default useStyles
