import * as Yup from 'yup'

import { tikTokAdCTAValidation } from '../../../validation'
import {
  CALL_TO_ACTION,
  CALL_TO_ACTION_CUSTOM,
  CALL_TO_ACTION_LEARN_MORE,
  CALL_TO_ACTION_OPTION,
  LEARN_MORE
} from '../../../../../ReusableFormFields/CallToActionFields/fields'

export const getInitialValues = adData => ({
  [CALL_TO_ACTION_OPTION]: adData[CALL_TO_ACTION] === LEARN_MORE ? CALL_TO_ACTION_LEARN_MORE : CALL_TO_ACTION_CUSTOM,
  [CALL_TO_ACTION]: adData[CALL_TO_ACTION]
})

export const validationSchema = Yup.object({
  [CALL_TO_ACTION]: tikTokAdCTAValidation
})
