import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Icon from '../../../../components/Icon'

import { ReactComponent as FileIcon } from '../../../../assets/icons/file.svg'

import useStyles from './styles'

// this component helps to represent the file format inside the svg file icon
const FileFormatIcon = ({ fileFormat, icon, className, fileFormatClassName }) => {
  const classes = useStyles()

  return (
    <Icon className={classnames(className, classes.fileTypeIcon)}>
      {!!icon ? icon : <FileIcon />}
      <span className={classnames(classes.fileFormat, fileFormatClassName)}>{fileFormat}</span>
    </Icon>
  )
}

FileFormatIcon.propTypes = {
  className: PropTypes.string,
  fileFormat: PropTypes.string,
  icon: PropTypes.node
}

export default FileFormatIcon
