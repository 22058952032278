import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../components/Form'
import Field from '../../../../components/Form/Field'
import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'

import { createAudienceBySuggestion } from '../../../../modules/actions/audiences'
import {
  audiencesCreateBySuggestionErrorSelector,
  audiencesCreateBySuggestionIsLoadingSelector,
  createdAudienceBySuggestionSelector
} from '../../../../modules/selectors/audiences'
import { accessibleFacebookPagesSelector } from '../../../../modules/selectors/socialAccounts'
import { choicesPixelsSelector } from '../../../../modules/selectors/choices'

import { formatOptionsList } from '../../../../features/formatters'

import { AUDIENCE_SUGGESTION_TYPE, FACEBOOK_PAGE, PIXEL } from '../../../../constants/forms'
import { initialValues, transformValuesToBE, OPTION_CHOICE, OPTION_TYPE } from './fields'
import { selectedAdAccountIdSelector } from '../../../../modules/selectors/app'

const AudienceBySuggestionCreate = ({ suggestionType }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { wasCreated: audienceCreatedBySuggestionWasCreated } = useSelector(createdAudienceBySuggestionSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const facebookPages = useSelector(accessibleFacebookPagesSelector)
  const pixels = useSelector(choicesPixelsSelector)

  const options = useMemo(
    () =>
      suggestionType === 'facebookPageEngagers'
        ? facebookPages.filter(({ tasks }) => tasks.includes('advertise'))
        : pixels,
    [facebookPages, pixels, suggestionType]
  )

  const formattedOptions = useMemo(
    () =>
      formatOptionsList({
        list: options,
        labelName: 'name',
        valueName: 'id'
      }),
    [options]
  )

  const onSubmit = useCallback(
    values => {
      dispatch(
        createAudienceBySuggestion(
          transformValuesToBE({
            values,
            account: selectedAdAccountId,
            suggestionType
          })
        )
      )
    },
    [dispatch, selectedAdAccountId, suggestionType]
  )

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit
  })

  const { values, setValues } = formik

  const selectedOption = values[OPTION_CHOICE]
  const selectedOptionType = values[OPTION_TYPE]

  // preselect pixel or facebook page depending on which suggestion card was clicked
  // preselect option only if this type of option wasn't preselected already
  useEffect(() => {
    let optionType = suggestionType === AUDIENCE_SUGGESTION_TYPE.FACEBOOK_PAGE_ENGAGERS ? FACEBOOK_PAGE : PIXEL

    if (formattedOptions.length && selectedOption !== formattedOptions[0].value && selectedOptionType !== optionType) {
      setValues({
        [OPTION_CHOICE]: formattedOptions[0].value,
        [OPTION_TYPE]: optionType
      })
    }
  }, [formattedOptions, setValues, selectedOption, selectedOptionType, suggestionType])

  return (
    <FormDrawerWrapper
      formName={suggestionType || 'audience_by_suggestion_create'}
      title={t(
        suggestionType === 'facebookPageEngagers'
          ? 'Select a Facebook page'
          : 'Select the pixel that is installed on your website'
      )}
      subTitle={t('CREATE AN AUDIENCE')}
      showOpenButton={false}
    >
      <Form
        formName={suggestionType || 'audience_by_suggestion_create'}
        formik={formik}
        errorSelector={audiencesCreateBySuggestionErrorSelector}
        isLoadingSelector={audiencesCreateBySuggestionIsLoadingSelector}
        successSubmit={audienceCreatedBySuggestionWasCreated}
      >
        <Field
          formik={formik}
          name={OPTION_CHOICE}
          placeholder={suggestionType === 'facebookPageEngagers' ? 'Facebook Page' : 'Event Source'}
          options={formattedOptions}
        />
      </Form>
    </FormDrawerWrapper>
  )
}

export default AudienceBySuggestionCreate
