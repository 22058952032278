import { isStory } from '../../helpers'

import {
  AD_DESCRIPTION,
  AD_HEADLINE,
  AD_LINK,
  AD_PREVIEW_CAROUSEL_INDEX_KEY,
  CAROUSEL_ITEMS,
  DESCRIPTIONS_LIST,
  FB_PAGE_CHOICE,
  FILE_HASH,
  FILE_ID,
  FILE_NAME,
  FILE_URL,
  FORMAT_CAROUSEL,
  FORMAT_OPTION,
  FORMAT_SINGLE,
  HEADLINES_LIST,
  initialStoriesItem,
  INSTA_ACCOUNT_CHOICE,
  IS_CUSTOMIZED_MEDIA,
  MEDIA_FILE_OPTION,
  MEDIA_FILE_VIDEO_USE_LIBRARY,
  MEDIA_TYPE,
  NAME,
  PRIMARY_TEXT_LIST,
  STORIES_ITEMS,
  VIDEO_PICTURE
} from '../../fields'
import { CALL_TO_ACTION } from '../../../../ReusableFormFields/CallToActionFields/fields'

import { AD_FORMAT } from '../../../../../constants/ads'
import {
  CAMPAIGN_FILTERS_ACTIVE,
  CAMPAIGN_FILTERS_DEFAULT,
  CAMPAIGN_FILTERS_PAUSED,
  campaignFiltersList
} from '../../../../../constants/selectLists/listItemFiltersList'
import {
  IFRAME_SRC,
  MEDIA_FILE_IMAGE_USE_LIBRARY,
  UPLOADED_MEDIA_TYPE
} from '../../../../ReusableFormFields/AdFileUpload/fields'
import {
  getFeedImageHashInCustomizedAd,
  getFeedVideoInCustomizedAd,
  getImageInfo,
  getItemFileInfo,
  getSingleVideoId,
  getStoryImageHashInCustomizedAd,
  getStoryVideoInCustomizedAd,
  isVideoIframe
} from './mediaFileHelpers'

export const getAdFormat = values => {
  if (!!values && !!values[CAROUSEL_ITEMS]) {
    return values[IS_CUSTOMIZED_MEDIA] ? AD_FORMAT.carouselCustom : AD_FORMAT.carousel
  } else {
    return values?.[IS_CUSTOMIZED_MEDIA] ? AD_FORMAT.singleCustom : AD_FORMAT.single
  }
}

// ! Workaround to identify is ad "existing post ad"
const getSingleImageCta = ad => ad.creative?.object_story_spec?.link_data?.call_to_action?.type?.toUpperCase()
const getExistingPostCta = ad => ad.creative?.call_to_action_type

export const isExistingPostTypeAd = ad => {
  return !getSingleImageCta(ad) && !!getExistingPostCta(ad)
}

export const isCarousel = ad => !!ad.creative?.object_story_spec?.link_data?.child_attachments
const isCustomAd = ad => !!ad.creative?.asset_feed_spec
export const isCustomAdWithStory = ad =>
  !!ad.creative?.asset_feed_spec?.asset_customization_rules?.find(item =>
    item.customization_spec?.facebook_positions?.includes('story')
  )

export const isEditFormDisabled = ad => {
  if (isCustomAd(ad) && !isCustomAdWithStory(ad)) {
    // Currently we support editing ad customised with story, but there are types of customised ad that we don't support
    return true
  } else if (isExistingPostTypeAd(ad)) {
    // Currently we don't support editing existing post ad
    return true
  }
  return false
}

// On campaign summary page we don't have lineItem loaded, but have lineItems list loaded,
// to avoid unnecessary request we can get placement_positions from lineItems list
export const getPlacementPositions = ({ ad, lineItem, lineItems, lineItemWasLoaded, lineItemsWasLoaded }) => {
  if (lineItemWasLoaded) {
    return lineItem?.['placement_positions']
  }

  if (lineItemsWasLoaded) {
    const selectedLineItem = lineItems?.find(item => item?.id === ad?.adset_id)
    return selectedLineItem?.['placement_positions']
  }

  return []
}

const getInitialCarouselIndexIdentified = ({ adId, isEditForm }) => {
  // for AdCarsListItem previews we use ad ids, for edit/create form separate string keys
  // it helps to avoid switching carousel both in edit/ad list
  if (isEditForm) {
    return 'adEditForm'
  } else if (adId) {
    return adId
  } else {
    return ''
  }
}

export const getSingleImageOrVideo = ({ ad, imageUrls, videoUrls }) => {
  if (isCustomAd(ad)) {
    if (isCustomAdWithStory(ad)) {
      return getSingleCustomizedAdWithStory({ ad, imageUrls, videoUrls })
    } else {
      return getSingleCustomizedAd({ ad, imageUrls, videoUrls })
    }
  } else {
    return getSingleItem({ ad, imageUrls, videoUrls })
  }
}

const getCustomizedAdReusablePayload = ({ ad }) => {
  const primaryTextList = ad.creative?.asset_feed_spec?.bodies
  const descriptionsList = ad.creative?.asset_feed_spec?.descriptions
  const headlinesList = ad.creative?.asset_feed_spec?.titles

  const description = ad.creative?.asset_feed_spec?.descriptions?.[0]?.text
  const headline = ad.creative?.asset_feed_spec?.titles?.[0]?.text

  const link =
    ad.creative?.object_story_spec?.link_data?.link || ad.creative?.asset_feed_spec?.link_urls?.[0]?.website_url

  const callToAction =
    ad.creative?.object_story_spec?.link_data?.call_to_action?.type ||
    ad.creative?.asset_feed_spec?.call_to_action_types?.[0]?.toUpperCase()

  const singleMediaItem = {
    // description
    [AD_DESCRIPTION]: description,
    [DESCRIPTIONS_LIST]: descriptionsList ? descriptionsList : [{ text: '' }],
    // headline
    [AD_HEADLINE]: headline,
    [HEADLINES_LIST]: headlinesList ? headlinesList : [{ text: '' }],
    // link
    [AD_LINK]: link,
    [CALL_TO_ACTION]: callToAction
  }

  return {
    // name
    [NAME]: ad.name,
    // primary text
    [PRIMARY_TEXT_LIST]: primaryTextList ? primaryTextList : [{ text: '' }],
    // social accounts
    [FB_PAGE_CHOICE]: ad.creative?.actor_id,
    [INSTA_ACCOUNT_CHOICE]: ad.creative?.instagram_actor_id,

    singleMediaItem
  }
}

export const getSingleCustomizedAd = ({ ad, imageUrls, videoUrls }) => {
  const reusableCustomizedPayload = getCustomizedAdReusablePayload({ ad, imageUrls, videoUrls })

  const imageHash = ad.creative?.object_story_spec?.link_data?.image_hash
  const imageFileUrl = getItemFileInfo({ item: { image_hash: imageHash }, imageUrls })?.url
  const imageFileName = getItemFileInfo({ item: { image_hash: imageHash }, imageUrls })?.name

  // video
  const video = ad.creative?.asset_feed_spec?.videos?.[0]
  const videoFileUrl = getItemFileInfo({ item: video, videoUrls })?.url
  const videoFileName = getItemFileInfo({ item: video, videoUrls })?.name

  // Try to find square thumbnail, if not take just first
  const videoPicture =
    ad.creative?.asset_feed_spec?.videos?.find(item => item.thumbnail_url.includes('160x160'))?.thumbnail_url ||
    ad.creative?.asset_feed_spec?.videos?.find(item => item.adlabels?.name === 'label_other_video')?.thumbnail_url ||
    ad.creative?.asset_feed_spec?.videos?.[0]?.thumbnail_url

  const singleVideo = {
    [IFRAME_SRC]: isVideoIframe(video, videoUrls),
    [FILE_URL]: videoFileUrl,
    [VIDEO_PICTURE]: videoPicture,
    [FILE_ID]: video?.video_id,
    [FILE_NAME]: videoFileName,
    [MEDIA_TYPE]: 'video'
  }

  const singleImage = {
    [FILE_HASH]: imageHash,
    [FILE_URL]: imageFileUrl,
    [FILE_NAME]: imageFileName,
    [MEDIA_TYPE]: 'image'
  }

  const singleMediaItem = {
    ...reusableCustomizedPayload.singleMediaItem,
    ...(!!video ? singleVideo : singleImage)
  }

  return {
    ...reusableCustomizedPayload,
    ...singleMediaItem,
    singleMediaItem
  }
}

export const getSingleCustomizedAdWithStory = ({ ad, imageUrls, videoUrls }) => {
  const reusableCustomizedPayload = getCustomizedAdReusablePayload({ ad, imageUrls, videoUrls })

  const imageHash = getFeedImageHashInCustomizedAd(ad)
  const firstVideo = ad.creative.asset_feed_spec?.videos?.[0]
  const feedVideo = getFeedVideoInCustomizedAd(ad)

  const video = feedVideo || firstVideo // try to find video in feed, if not found - take first video

  const singleVideo = {
    [IFRAME_SRC]: isVideoIframe(video, videoUrls),
    [FILE_URL]: getItemFileInfo({ item: video, videoUrls })?.url,
    [VIDEO_PICTURE]: video?.thumbnail_url,
    [FILE_ID]: video?.video_id,
    [FILE_HASH]: video?.thumbnail_hash,
    [FILE_NAME]: getItemFileInfo({ item: video, videoUrls })?.name,
    [MEDIA_TYPE]: 'video',
    // todo SHOP-1851 Refactor to avoid one of fields bellow
    [MEDIA_FILE_OPTION]: MEDIA_FILE_VIDEO_USE_LIBRARY,
    [UPLOADED_MEDIA_TYPE]: MEDIA_FILE_VIDEO_USE_LIBRARY
  }

  const singleImage = {
    [FILE_HASH]: imageHash,
    [FILE_URL]: getItemFileInfo({ item: { image_hash: imageHash }, imageUrls })?.url,
    [FILE_NAME]: getItemFileInfo({ item: { image_hash: imageHash }, imageUrls })?.name,
    [MEDIA_TYPE]: 'image'
  }

  const story = {
    [FILE_HASH]: getStoryImageHashInCustomizedAd(ad),
    [FILE_ID]: getStoryVideoInCustomizedAd(ad)?.video_id,
    [MEDIA_TYPE]: getStoryImageHashInCustomizedAd(ad) ? 'image' : 'video'
  }

  const singleMediaItem = {
    ...reusableCustomizedPayload.singleMediaItem,
    ...(!!video ? singleVideo : singleImage)
  }

  return {
    [IS_CUSTOMIZED_MEDIA]: true,
    [STORIES_ITEMS]: [{ ...initialStoriesItem, ...story }],

    ...reusableCustomizedPayload,
    singleMediaItem
  }
}

const getSingleImage = ({ ad, imageUrls }) => {
  const singleImageAdText = ad.creative?.object_story_spec?.link_data?.message || ad.creative?.body
  const singleImageHeadline = ad.creative?.object_story_spec?.link_data?.name
  const singleImageDescription = ad.creative?.object_story_spec?.link_data?.description

  const imageHash = ad.creative?.image_hash || ad.creative?.object_story_spec?.link_data?.image_hash

  const facebookPageId = ad.creative?.actor_id || ad.creative?.object_story_spec?.page_id

  const singleMediaItem = {
    [DESCRIPTIONS_LIST]: singleImageDescription ? [{ text: singleImageDescription }] : [{ text: '' }],
    [HEADLINES_LIST]: singleImageHeadline ? [{ text: singleImageHeadline }] : [{ text: '' }],
    [AD_LINK]:
      ad.creative?.object_story_spec?.link_data?.call_to_action?.value?.link ||
      ad.creative?.object_story_spec?.link_data?.link,
    [CALL_TO_ACTION]: ad.creative?.object_story_spec?.link_data?.call_to_action?.type?.toUpperCase(),
    // file
    [MEDIA_TYPE]: 'image',
    // [FILE_URL]: imageUrls[0]?.url,
    [FILE_URL]: getImageInfo({ ad, imageUrls, imageHash })?.url,
    [FILE_NAME]: getImageInfo({ ad, imageUrls, imageHash })?.name,
    [FILE_HASH]: imageHash,
    // todo SHOP-1851 Refactor to avoid one of fields bellow
    [MEDIA_FILE_OPTION]: MEDIA_FILE_IMAGE_USE_LIBRARY,
    [UPLOADED_MEDIA_TYPE]: MEDIA_FILE_IMAGE_USE_LIBRARY
  }

  return {
    [NAME]: ad.name,
    // primary text
    [PRIMARY_TEXT_LIST]: singleImageAdText ? [{ text: singleImageAdText }] : [{ text: '' }],
    // social accounts
    [FB_PAGE_CHOICE]: facebookPageId,
    [INSTA_ACCOUNT_CHOICE]: ad.creative?.instagram_actor_id,
    singleMediaItem
  }
}

const getSingleVideo = ({ ad, videoUrls }) => {
  const singleVideoAdText = ad.creative?.object_story_spec?.video_data?.message
  const singleVideoHeadline = ad.creative?.object_story_spec?.video_data?.title
  const singleVideoDescription = ad.creative?.object_story_spec?.video_data?.link_description

  const singleMediaItem = {
    // description
    [AD_DESCRIPTION]: ad.creative?.object_story_spec?.video_data?.link_description,
    [DESCRIPTIONS_LIST]: singleVideoDescription ? [{ text: singleVideoDescription }] : [{ text: '' }],
    // headline
    [AD_HEADLINE]: singleVideoHeadline,
    [HEADLINES_LIST]: singleVideoHeadline ? [{ text: singleVideoHeadline }] : [{ text: '' }],
    // link
    [AD_LINK]:
      ad.creative?.object_story_spec?.video_data?.call_to_action?.value?.link ||
      ad.creative?.object_story_spec?.video_data?.link,
    [CALL_TO_ACTION]: ad.creative?.object_story_spec?.video_data?.call_to_action?.type?.toUpperCase(),
    // file
    [MEDIA_TYPE]: 'video',
    [FILE_ID]: ad.creative?.object_story_spec?.video_data?.video_id,
    [VIDEO_PICTURE]: ad.creative?.object_story_spec?.video_data?.image_url,
    [FILE_HASH]: ad.creative.object_story_spec?.video_data?.image_hash,
    [FILE_URL]: getItemFileInfo({ item: ad.creative?.object_story_spec?.video_data, videoUrls })?.url,
    [FILE_NAME]: getItemFileInfo({ item: ad.creative?.object_story_spec?.video_data, videoUrls })?.name,
    // todo SHOP-1851 Refactor to avoid one of fields bellow
    [MEDIA_FILE_OPTION]: MEDIA_FILE_VIDEO_USE_LIBRARY,
    [UPLOADED_MEDIA_TYPE]: MEDIA_FILE_VIDEO_USE_LIBRARY
  }

  return {
    [NAME]: ad.name,
    // primary text
    [PRIMARY_TEXT_LIST]: singleVideoAdText ? [{ text: singleVideoAdText }] : [{ text: '' }],

    // social accounts
    [FB_PAGE_CHOICE]: ad.creative?.actor_id,
    [INSTA_ACCOUNT_CHOICE]: ad.creative?.instagram_actor_id,

    // media
    singleMediaItem
  }
}

export const getSingleItem = ({ ad, imageUrls, videoUrls }) => {
  return !!getSingleVideoId(ad) ? getSingleVideo({ ad, videoUrls }) : getSingleImage({ ad, imageUrls })
}

export const getCarouselItems = ({ ad, imageUrls, videoUrls }) => {
  return ad.creative?.object_story_spec.link_data.child_attachments.map(item => {
    const headline = item.name
    const description = item.description

    const mediaType = item.video_id ? 'video' : 'image'

    return {
      // description
      [AD_DESCRIPTION]: description,
      [DESCRIPTIONS_LIST]: !!description ? [{ text: description }] : [{ text: '' }],
      // headline
      [AD_HEADLINE]: headline,
      [HEADLINES_LIST]: !!headline ? [{ text: headline }] : [{ text: '' }],
      // link
      [CALL_TO_ACTION]: item.call_to_action?.type?.toUpperCase(),
      [AD_LINK]: item.call_to_action?.type?.value || item.link,
      // file
      [MEDIA_TYPE]: mediaType,
      // todo check after editFinalize START NEW FIELDS
      [FILE_HASH]: item.image_hash,
      [FILE_ID]: item.video_id,
      [VIDEO_PICTURE]: item.image_url,
      // FINISH NEW FIELDS
      [FILE_URL]: getItemFileInfo({ item, imageUrls, videoUrls })?.url,
      [FILE_NAME]: getItemFileInfo({ item, imageUrls, videoUrls })?.name,
      // open existed library image or video
      // !!! We need both fields, otherwise edit form may be broken
      // todo SHOP-1851 Refactor to avoid one field bellow
      [MEDIA_FILE_OPTION]: mediaType === 'image' ? MEDIA_FILE_IMAGE_USE_LIBRARY : MEDIA_FILE_VIDEO_USE_LIBRARY,
      [UPLOADED_MEDIA_TYPE]: mediaType === 'image' ? MEDIA_FILE_IMAGE_USE_LIBRARY : MEDIA_FILE_VIDEO_USE_LIBRARY
    }
  })
}

export const getStoriesItems = ({ ad, imageUrls, videoUrls }) => {
  return isCarousel(ad)
    ? getCarouselItems({ ad, imageUrls, videoUrls })
    : [getSingleImageOrVideo({ ad, imageUrls, videoUrls })]
}

export const transformValuesToFE = ({ ad, adWasLoaded, placementPositions, imageUrls, videoUrls, isEditForm }) => {
  if (!adWasLoaded) return

  const { id: adId } = ad

  const isStoryAd = isStory(placementPositions)
  const isCarouselAd = isCarousel(ad)

  return {
    [FORMAT_OPTION]: isCarouselAd ? FORMAT_CAROUSEL : FORMAT_SINGLE,
    [AD_PREVIEW_CAROUSEL_INDEX_KEY]: getInitialCarouselIndexIdentified({ adId, isEditForm }),

    // Single item
    ...getSingleImageOrVideo({ ad, imageUrls, videoUrls }),

    // Carousel items
    ...(isCarouselAd && {
      [CAROUSEL_ITEMS]: getCarouselItems({ ad, imageUrls, videoUrls })
    }),

    // Story items
    ...(isStoryAd && {
      [STORIES_ITEMS]: getStoriesItems({ ad, imageUrls, videoUrls })
    })
  }
}

export const hasCorrespondingFilterStatus = (filter = CAMPAIGN_FILTERS_DEFAULT, status) => {
  // check if status is the same as selected filter for list
  if (filter === CAMPAIGN_FILTERS_DEFAULT) {
    return status === CAMPAIGN_FILTERS_ACTIVE || status === CAMPAIGN_FILTERS_PAUSED
  } else {
    return filter === status
  }
}

export const getSelectedFilterLabel = (filter = CAMPAIGN_FILTERS_DEFAULT) => {
  const selectedFilter = campaignFiltersList.find(
    filterItem => filterItem.value === (filter || CAMPAIGN_FILTERS_DEFAULT)
  )
  return selectedFilter?.label
}
