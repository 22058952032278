import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { transformSingleToCarousel } from './helpers'

import Button from '../../../../../../../components/Button'
import ItemMediaFields from '../../../../../../ReusableFormFields/MediaFields/ItemMediaFields'

import { AD_FORMAT } from '../../../../../../../constants/ads'

import { singleMediaValidation } from '../../../../AdFacebookCreate/AdFacebookCreateForm/validation/mediaValidation'
import { SINGLE_MEDIA_ITEM } from '../../../../fields'

import { AdFacebookEditContext } from '../../../AdFacebookEditContextProvider'

import useStyles from './styles'

const SingleMediaForm = ({ formik, adFormat }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const allowSwitchAdToCarousel = adFormat !== AD_FORMAT.singleCustom

  const { campaignObjective } = useContext(AdFacebookEditContext)

  const { values: modifiedAdValues, setValues } = formik

  const switchAdToCarousel = useCallback(() => {
    // transform formik modifiedAdValues to carousel values.
    // for that process single fields values are transformed to carousel item object
    // + newCarouselItem is added and pre-opened for edit
    const valuesWithCarousel = transformSingleToCarousel(modifiedAdValues)

    // set carousel items to formik
    setValues(valuesWithCarousel)
  }, [modifiedAdValues, setValues])

  return (
    <>
      <ItemMediaFields
        formik={formik}
        itemPath={SINGLE_MEDIA_ITEM}
        mediaValidationSchema={singleMediaValidation}
        campaignObjective={campaignObjective}
      />
      {allowSwitchAdToCarousel && (
        <Button className={classes.switchAdTypeBtn} type="button" onClick={switchAdToCarousel}>
          {t('Turn this ad into a carousel ad')}
        </Button>
      )}
    </>
  )
}

export default SingleMediaForm
