import { createSelector } from 'reselect'

// Create bulk targeting
export const createDv360BulkTargetingSelector = createSelector(
  ({ dv360Constants }) => dv360Constants.createDv360BulkTargeting.data,
  data => data
)

export const createDv360BulkTargetingIsLoadingSelector = createSelector(
  ({ dv360Constants }) => dv360Constants.createDv360BulkTargeting.isLoading,
  isLoading => isLoading
)

export const createDv360BulkTargetingErrorSelector = createSelector(
  ({ dv360Constants }) => dv360Constants.createDv360BulkTargeting.error,
  error => error
)

export const dv360BulkTargetingWasCreatedSelector = createSelector(
  ({ dv360Constants }) => dv360Constants.createDv360BulkTargeting.wasCreated,
  wasCreated => wasCreated
)
