import Color from 'color'
import { darkGrey, lightGrey, mediumGrey, textGrey } from '../../styles/const/colors'

export const getActionTextColor = (theme, { isDark, isDarkGrey, isDisabled }) => {
  if (isDisabled) {
    return {
      color: mediumGrey,
      hoverColor: mediumGrey,
      touchedColor: mediumGrey
    }
  } else {
    if (isDarkGrey) {
      return {
        color: textGrey,
        hoverColor: darkGrey,
        touchedColor: darkGrey
      }
    } else if (isDark) {
      return {
        color: theme.brandPrimary,
        hoverColor: Color(theme.brandPrimary).darken(0.3).rgb().string(),
        touchedColor: Color(theme.brandPrimary).darken(0.3).rgb().string()
      }
    } else {
      return {
        color: lightGrey,
        hoverColor: theme.brandPrimary,
        touchedColor: theme.brandPrimary
      }
    }
  }
}
