import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'

import CampaignsSection from './CampaignsSection'
import RequestedFilesSection from './RequestedFilesSection'
import ContentTabs from '../../../features/components/ContentTabs'
import OnsiteDigitalSection from './OnsiteDigitalSection'
import DetailsSection from './DetailsSection'
import DocumentsSection from './DocumentsSection'
import AmendmentsSection from './AmendmentsSection'
import TabTitle from '../../../features/components/ContentTabs/TabTitle'
// import DigitalScreensSection from './DigitalScreensSection'
import PaymentStatusDropdown from './DocumentsSection/PaymentStatusDropdown'
import Chip from '../../../components/Chip'
import TabsHeader from '../../../features/components/ContentTabs/TabsHeader'
import MediaOrderHistoryButton from './MediaOrderHistory/MediaOrderHistoryButton'
import MediaOrderHistory from './MediaOrderHistory'

import { useManageTabsRepresentation } from './useManageTabsRepresentation'
import { replaceRouteParams } from '../../../helpers/url'

import { mediaOrderSelector, updateMediaOrderIsLoadingSelector } from '../../../modules/selectors/mediaOrders'
import { selectedSelfAccountSelector } from '../../../modules/selectors/app'

import { MEDIA_ORDERS_SUB_ROUTES, ROUTE_PARAMS, ROUTES } from '../../../constants/routes'
import { CHIP_COLORS } from '../../../constants/other'

import { MEDIA_ORDER_HISTORY } from '../../../constants/forms'

import { openForm } from '../../../modules/actions/forms'

import useStyles from './styles'

const MediaOrderSummaryContent = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const params = useParams()

  const mediaOrder = useSelector(mediaOrderSelector)
  const updateMediaOrderIsLoading = useSelector(updateMediaOrderIsLoadingSelector)
  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)

  const mediaOrderId = mediaOrder?.id
  const isCancelled = mediaOrder?.status === 'cancelled'
  const subPagePath = params.mediaOrderSummaryTab

  const { name, outstanding_required_files: requestedFilesCount, has_amendment_contracts } = mediaOrder

  const showRequestedFilesCount = Number(requestedFilesCount) > 0

  const {
    // showDigitalScreensTab,
    showOnsiteDigital,
    showOffNetworkDigital,
    showRequestedFilesTab
  } = useManageTabsRepresentation()

  const getFormattedPath = useCallback(
    mainPath =>
      replaceRouteParams(mainPath, {
        [ROUTE_PARAMS.selfAccount]: selectedSelfAccount,
        [ROUTE_PARAMS.mediaOrderId]: mediaOrderId
      }),
    [mediaOrderId, selectedSelfAccount]
  )

  const openMediaOrderHistoryHandler = useCallback(() => {
    dispatch(openForm({ formName: MEDIA_ORDER_HISTORY }))
  }, [dispatch])

  const contentTabs = useMemo(
    () => [
      {
        title: <TabTitle title="Requested Files" chipText={showRequestedFilesCount ? requestedFilesCount : ''} />,
        mobileTitle: t('Requested Files'),
        path: getFormattedPath(ROUTES.mediaOrderSummaryRequestedFiles),
        pathParam: MEDIA_ORDERS_SUB_ROUTES.REQUESTED_FILES,
        component: <RequestedFilesSection mediaOrderId={mediaOrderId} />,
        show: showRequestedFilesTab
      },
      {
        title: t('Onsite Digital'),
        mobileTitle: t('Onsite Digital'),
        path: getFormattedPath(ROUTES.mediaOrderSummaryOnsiteDigital),
        pathParam: MEDIA_ORDERS_SUB_ROUTES.ONSITE_DIGITAL,
        component: <OnsiteDigitalSection />,
        show: showOnsiteDigital
      },
      {
        title: t('Off-network Digital'),
        mobileTitle: t('Off-network Digital'),
        path: getFormattedPath(ROUTES.mediaOrderSummaryOffNetworkDigital),
        pathParam: MEDIA_ORDERS_SUB_ROUTES.OFF_NETWORK_DIGITAL,
        component: <CampaignsSection />,
        show: showOffNetworkDigital
      },
      // SHOP-2274 (i.e. comment out the OOH tab as it is currently not needed)
      // {
      //   title: t('Digital Screens'),
      //   mobileTitle: t('Digital Screens'),
      //   path: getFormattedPath(ROUTES.mediaOrderSummaryDigitalScreens),
      //   pathParam: MEDIA_ORDERS_SUB_ROUTES.DIGITAL_SCREENS,
      //   component: <DigitalScreensSection />,
      //   show: showDigitalScreensTab
      // },
      {
        title: t('Details'),
        mobileTitle: t('Details'),
        path: getFormattedPath(ROUTES.mediaOrderSummaryDetails),
        pathParam: MEDIA_ORDERS_SUB_ROUTES.DETAILS,
        component: <DetailsSection />,
        show: true
      },
      {
        title: t('Documents'),
        mobileTitle: t('Documents'),
        path: getFormattedPath(ROUTES.mediaOrderSummaryDocuments),
        pathParam: MEDIA_ORDERS_SUB_ROUTES.DOCUMENTS,
        component: <DocumentsSection />,
        show: true
      },
      {
        title: t('Amendments'),
        mobileTitle: t('Amendments'),
        path: getFormattedPath(ROUTES.mediaOrderSummaryAmendments),
        pathParam: MEDIA_ORDERS_SUB_ROUTES.AMENDMENTS,
        component: <AmendmentsSection />,
        // show amendments tab only when there are available amendments
        show: has_amendment_contracts
      }
    ],
    [
      mediaOrderId,
      showRequestedFilesCount,
      requestedFilesCount,
      t,
      getFormattedPath,
      showRequestedFilesTab,
      showOnsiteDigital,
      showOffNetworkDigital,
      // showDigitalScreensTab,
      has_amendment_contracts
    ]
  )

  return (
    <>
      <TabsHeader>
        <h1>{name}</h1>
      </TabsHeader>
      <ContentTabs
        tabs={contentTabs}
        subPagePath={subPagePath}
        rightSideChildren={
          updateMediaOrderIsLoading ? (
            <Skeleton height={25} width={150} />
          ) : (
            <div className={classes.chipsContainer}>
              <PaymentStatusDropdown />
              <MediaOrderHistoryButton onClick={openMediaOrderHistoryHandler} />
              {isCancelled && <Chip text={t('Cancelled')} color={CHIP_COLORS.red} />}
            </div>
          )
        }
      />
      <MediaOrderHistory />
    </>
  )
}

export default MediaOrderSummaryContent
