import React, { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import StepForm from '../../../../../../features/components/Forms/StepForm'
import SpecificCampaignsMarginStep from '../../Steps/SpecificCampaignsMarginStep'
import MarginFeeStep from '../../../../../../forms/ReusableFormFields/MarginFields/MarginFee'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'

import { initialValues } from '../../fields'
import { validationSchema } from './validation'
import { clearCreateMarginOverride, createMarginOverride } from '../../../../../../modules/actions/margins'
import { selectedSelfAccountSelector } from '../../../../../../modules/selectors/app'
import { transformValuesToBE } from './formatters'
import {
  createMarginOverrideErrorSelector,
  createMarginOverrideIsLoadingSelector,
  createMarginOverrideWasCreatedSelector
} from '../../../../../../modules/selectors/margins'

const MarginOverrideRoute = ({ formatOption, FormRouteSelector, eventSourceStep, ...formProps }) => {
  const dispatch = useDispatch()

  const selfAccount = useSelector(selectedSelfAccountSelector)
  const marginsOverrideWasCreated = useSelector(createMarginOverrideWasCreatedSelector)

  const onSubmit = useCallback(
    values => {
      dispatch(createMarginOverride(transformValuesToBE(values, selfAccount)))
    },
    [dispatch, selfAccount]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const steps = useMemo(
    () => [
      {
        component: FormRouteSelector,
        show: true
      },
      {
        component: <SpecificCampaignsMarginStep />,
        show: true
      },
      {
        component: <MarginFeeStep />,
        show: true
      }
    ],
    [FormRouteSelector]
  )

  const clearCreateMarginHandler = () => {
    dispatch(clearCreateMarginOverride())
  }

  return (
    <StepForm
      steps={steps}
      formik={purifiedFormik}
      {...formProps}
      // this allow to clearCreateMargins on error
      clearSubmitHandler={clearCreateMarginHandler}
      successSubmit={marginsOverrideWasCreated}
      errorSelector={createMarginOverrideErrorSelector}
      isLoadingSelector={createMarginOverrideIsLoadingSelector}
    />
  )
}

export default MarginOverrideRoute
