import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import Form from '../../../../../../components/Form'
import Field from '../../../../../../components/Form/Field'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'

import { clearCreateSelfAccount, createSelfAccount } from '../../../../../../modules/actions/selfAccounts'
import {
  createSelfAccountErrorSelector,
  createSelfAccountIsLoadingSelector,
  selfAccountWasCreatedSelector
} from '../../../../../../modules/selectors/selfAccounts'

import { CLIENT_ACCOUNT_CREATE } from '../../../../../../constants/forms'
import { NAME, getInitialValues, validationSchema } from '../../fields'

import useCommonStyles from '../../../../../../styles/common/stepForms'
import useDrawerFormsStyles from '../../../../../../styles/common/drawerForms'

const ClientAccountCreateForm = () => {
  const commonClasses = useCommonStyles()
  const drawerFormsClasses = useDrawerFormsStyles()

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const selfAccountWasCreated = useSelector(selfAccountWasCreatedSelector)

  const clearCreateSelfAccountSubmit = useCallback(() => dispatch(clearCreateSelfAccount()), [dispatch])

  const onSubmit = useCallback(
    values => {
      dispatch(createSelfAccount(values))
    },
    [dispatch]
  )

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  return (
    <Form
      formName={CLIENT_ACCOUNT_CREATE}
      formik={purifiedFormik}
      successSubmit={selfAccountWasCreated}
      clearHandler={clearCreateSelfAccountSubmit}
      errorSelector={createSelfAccountErrorSelector}
      isLoadingSelector={createSelfAccountIsLoadingSelector}
    >
      <div className={commonClasses.stepBody}>
        <section className={drawerFormsClasses.section}>
          <h4 className={drawerFormsClasses.sectionTitle}>{t('Add new client account')}</h4>
          <Field placeholder="Client's Brand Name" formik={formik} name={NAME} />
        </section>
      </div>
    </Form>
  )
}

export default ClientAccountCreateForm
