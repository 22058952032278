import { createStyles } from '../helpers'

import { mediumGrey, textGrey } from '../const/colors'
import { tabletDown } from '../const/breakpoints'
import { smallPageContainer } from '../common'

const useStyles = createStyles({
  container: {
    extend: smallPageContainer,
    marginTop: 60
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
    '& button': {
      flexShrink: 0
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 40
  },
  icon: {
    marginTop: 40
  },
  text: {
    textAlign: 'center',
    maxWidth: 466,
    fontSize: 16,
    lineHeight: 1.1,
    marginTop: 16,
    '&:first-of-type': {
      marginTop: 24
    }
  },
  title: {
    color: textGrey,
    fontWeight: '600',
    fontSize: 24,
    margin: 0,
    paddingRight: 25
  },
  itemList: {
    paddingLeft: 0
  },
  noDataContent: {
    color: mediumGrey
  },

  [`@media screen and (${tabletDown})`]: {
    heading: {
      display: 'block'
    },
    title: {
      marginBottom: 30,
      paddingRight: 0
    },
    wrapper: {
      marginTop: 20
    }
  }
})

export default useStyles
