import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getPortraitImageData } from '../HeadlinesSection/helpers'
import { useEditAssetGroupSubmit } from '../../hooks'
import { isAllImageAssetsReady } from '../../helpers'

import EditForm from '../../../../../../../features/components/Forms/EditForm'
import MultipleGoogleMediaUpload from '../../../../ReusableFields/MultipleGoogleMediaUpload'

import { selectedAdAccountIdSelector } from '../../../../../../../modules/selectors/app'

import { googleAssetFieldType } from '../../../../../../../constants/ads'
import { getInitialValues } from './fields'
import { PORTRAIT_IMAGE, portraitImageHeight, portraitImageWidth } from '../../../../fields'
import { getPortraitImageValidationSchema } from '../../../../validations'

const PortraitImageSectionForm = ({ editItemData, isInternalAssetGroup, ...formProps }) => {
  const { assets, id: assetGroupId, account } = editItemData

  const selectedAdAccount = useSelector(selectedAdAccountIdSelector)
  const adAccount = account || selectedAdAccount

  const originalPortraitImageAssets = useMemo(() => getPortraitImageData(assets), [assets])

  const initialValues = useMemo(() => getInitialValues(originalPortraitImageAssets), [originalPortraitImageAssets])
  const portraitImageValidationSchema = useMemo(() => getPortraitImageValidationSchema(), [])

  const purifiedFormik = useEditAssetGroupSubmit({
    originalSectionAssets: originalPortraitImageAssets,
    fieldType: googleAssetFieldType.PORTRAIT_MARKETING_IMAGE,
    sectionValueKey: PORTRAIT_IMAGE,
    isInternalAssetGroup,
    assetGroupId,
    initialValues,
    adAccount
  })

  const { values } = purifiedFormik

  const isImagesReady = isAllImageAssetsReady(values, PORTRAIT_IMAGE)

  const isEmptyList = !values[PORTRAIT_IMAGE].length

  return (
    <EditForm formik={purifiedFormik} initialValues={initialValues} submitBtnDisabled={!isImagesReady} {...formProps}>
      <MultipleGoogleMediaUpload
        formik={purifiedFormik}
        FileItemPath={PORTRAIT_IMAGE}
        validationSchema={portraitImageValidationSchema}
        addAnotherButtonText={isEmptyList ? 'Add portrait image' : 'Add another portrait image'}
        width={portraitImageWidth}
        height={portraitImageHeight}
        adAccountId={adAccount}
      />
    </EditForm>
  )
}

export default PortraitImageSectionForm
