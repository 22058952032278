import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createJsonFromQueryString } from '../../../../helpers/url'

import { clearCatalogueProducts, getCatalogueProducts } from '../../../../modules/actions/mediaOrders'
import { selectedControllerIdSelector, selectedSelfAccountSelector } from '../../../../modules/selectors/app'
import { catalogueProductsNextSelector } from '../../../../modules/selectors/mediaOrders'

// onSearchChange needs to save search value outside component
const useLoadCatalogueProducts = (limit = 10, onSearchChange) => {
  const dispatch = useDispatch()

  const selectedSelfAccountId = useSelector(selectedSelfAccountSelector)
  const selectedSelfAccountControllerId = useSelector(selectedControllerIdSelector)
  const catalogueProductsNext = useSelector(catalogueProductsNextSelector)

  const loadInitialProductsHandler = useCallback(
    (searchTerm = '') => {
      // onSearchChange needs to save search value outside component
      onSearchChange && onSearchChange(searchTerm)
      dispatch(
        getCatalogueProducts(
          {
            account: selectedSelfAccountId,
            controller: selectedSelfAccountControllerId,
            limit,
            ...(searchTerm && { search: searchTerm })
          },
          {
            shouldClearExistingProducts: true
          }
        )
      )
    },
    [dispatch, selectedSelfAccountControllerId, selectedSelfAccountId, limit, onSearchChange]
  )

  const loadMoreProductsHandler = useCallback(() => {
    dispatch(getCatalogueProducts(createJsonFromQueryString(`?${catalogueProductsNext.split('?')[1]}`)))
  }, [dispatch, catalogueProductsNext])

  useEffect(
    () => () => {
      dispatch(clearCatalogueProducts())
    },
    [dispatch]
  )

  return useMemo(
    () => ({
      loadInitialProductsHandler,
      loadMoreProductsHandler
    }),
    [loadInitialProductsHandler, loadMoreProductsHandler]
  )
}

export default useLoadCatalogueProducts
