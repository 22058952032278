import React from 'react'
import { useSelector } from 'react-redux'

import { adSelector } from '../../../../../../modules/selectors/ads'

import tikTokCallToActionsList from '../../../../../../constants/selectLists/tikTokCallToActionsList'
import { CALL_TO_ACTION } from '../../../../../ReusableFormFields/CallToActionFields/fields'

const CTASectionPreview = () => {
  const ad = useSelector(adSelector)

  const { [CALL_TO_ACTION]: cta } = ad
  const ctaLabel = tikTokCallToActionsList.find(({ value }) => value === cta)?.label

  return <p>{ctaLabel}</p>
}

export default CTASectionPreview
