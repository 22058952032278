import React from 'react'
import { useSelector } from 'react-redux'

import BudgetPreview from './BudgetContent/BudgetPreview'
import SpendPreview from './SpendContent/SpendPreview'
import TargetCostCapPreview from './TargetCostCapContent/TargetCostCapPreview'

import { getSpendType } from '../../typeHelpers'
import { getBudgetType } from '../../../../../Multiplatform/LineItemForms/typeHelpers'

import { campaignSelector } from '../../../../../../modules/selectors/campaigns'
import { lineItemSelector } from '../../../../../../modules/selectors/lineItems'

const BudgetSectionPreview = () => {
  const campaign = useSelector(campaignSelector)
  const lineItem = useSelector(lineItemSelector)

  const { budget_lifetime: lineItemBudgetLifetime, budget_daily: lineItemBudgetDaily } = lineItem
  const {
    budget_lifetime: campaignBudgetLifetime,
    budget_daily: campaignBudgetDaily,
    bidding_type: campaignBiddingType
  } = campaign

  const budgetType = getBudgetType(lineItemBudgetLifetime, lineItemBudgetDaily)
  const spendType = getSpendType(
    lineItemBudgetLifetime,
    lineItemBudgetDaily,
    campaignBudgetLifetime,
    campaignBudgetDaily
  )

  return (
    <p>
      <BudgetPreview budgetType={budgetType} />
      <SpendPreview spendType={spendType} />
      <TargetCostCapPreview campaignBiddingType={campaignBiddingType} />
    </p>
  )
}

export default BudgetSectionPreview
