import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

const CardHeaderSkeleton = ({ itemCategory, itemCategoryClassName, headerClassName, descriptionClassName }) => {
  const classes = useStyles()

  return (
    <div>
      {itemCategory && (
        <p className={classnames(classes.itemCategory, itemCategoryClassName)}>
          <Skeleton width="50px" />
        </p>
      )}
      <h3 className={classnames(classes.header, headerClassName)}>
        <Skeleton />
      </h3>
      <p className={classnames(classes.description, descriptionClassName)}>
        <Skeleton width="50%" />
      </p>
    </div>
  )
}

CardHeaderSkeleton.propTypes = {
  itemCategory: PropTypes.string,
  itemCategoryClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  descriptionClassName: PropTypes.string
}

export default CardHeaderSkeleton
