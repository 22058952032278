import {
  CAMPAIGN_OPTION_ADVANCED_SETUP,
  CAMPAIGN_OPTION_ADVANTAGE_SHOPPING,
  CAMPAIGN_OPTION_AUDIENCE_TARGETING,
  CAMPAIGN_OPTION_EXISTING_CAMPAIGN,
  CAMPAIGN_OPTION_PRODUCT_RETARGETING,
  CAMPAIGN_OPTION_PROXIMITY_CAMPAIGN
} from './UploadFacebookAdCreativeContent/CampaignForm/fields'

export const ADVANTAGE_SHOPPING_ROUTE = 'advantage_shopping_route'
export const PRODUCT_RETARGETING_ROUTE = 'product_retargeting_route'
export const PROXIMITY_CAMPAIGN_ROUTE = 'proximity_campaign_route'
export const AUDIENCE_TARGETING_ROUTE = 'audience_targeting_route'
export const ADVANCED_SETUP_ROUTE = 'advanced_setup_route'

export const getFacebookCampaignRoute = campaignOption => {
  let selectedRoute

  switch (campaignOption) {
    case CAMPAIGN_OPTION_ADVANTAGE_SHOPPING:
      selectedRoute = ADVANTAGE_SHOPPING_ROUTE
      break
    case CAMPAIGN_OPTION_PRODUCT_RETARGETING:
      selectedRoute = PRODUCT_RETARGETING_ROUTE
      break
    case CAMPAIGN_OPTION_PROXIMITY_CAMPAIGN:
      selectedRoute = PROXIMITY_CAMPAIGN_ROUTE
      break
    case CAMPAIGN_OPTION_AUDIENCE_TARGETING:
      selectedRoute = AUDIENCE_TARGETING_ROUTE
      break
    case CAMPAIGN_OPTION_EXISTING_CAMPAIGN:
      selectedRoute = ADVANCED_SETUP_ROUTE
      break
    case CAMPAIGN_OPTION_ADVANCED_SETUP:
    default:
      selectedRoute = ADVANCED_SETUP_ROUTE
  }

  return selectedRoute
}
