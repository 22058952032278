import { v4 as uuidv4 } from 'uuid'

import { DISCOUNT_PERCENTAGE, DISCOUNTS, NAME } from '../../fields'
import { newDiscountRowInitialValues } from '../../DiscountPresetCreate/DiscountPresetCreateForm/fields'
import { calc } from '../../../../../../helpers/numbers'

export const getInitialValues = editDiscountPresetData => {
  const formattedInitialDiscounts = editDiscountPresetData[DISCOUNTS].map(item => ({
    ...item,
    [DISCOUNT_PERCENTAGE]: calc(item[DISCOUNT_PERCENTAGE]).mul(100).toString()
  }))

  return {
    [NAME]: editDiscountPresetData[NAME] || '',
    [DISCOUNTS]: editDiscountPresetData[DISCOUNTS]?.length
      ? formattedInitialDiscounts
      : [
          {
            ...newDiscountRowInitialValues,
            id: uuidv4()
          }
        ]
  }
}
