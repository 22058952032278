import * as Yup from 'yup'
import { t } from 'i18next'

import { getAssetImageListValidation } from '../../../UploadGoogleAdCreative/UploadGoogleAdCreativeForm/imageAssetsListValidationHelper'
import { getDuplicatedTextAssetsValidation } from '../../../UploadGoogleAdCreative/duplicatedTextAssetsValiation'

import { CLICK_THROUGH_LINK, DESCRIPTIONS_LIST, HEADLINES_LIST, longHeadlineMaxLength, NAME } from '../../fields'
import {
  callToActionValidation,
  customFirstDescriptionValidation,
  descriptionShape,
  headlineShape
} from '../../validations'
import { LONG_HEADLINE } from './fields'
import { CALL_TO_ACTION } from '../../../../ReusableFormFields/CallToActionFields/fields'
import { productOrPagesValidation } from '../../../../ReusableFormFields/ProductsAndPagesFields/fields'
import { urlValidation } from '../../../../../features/validations/other'

export const getValidationSchema = ({ showProductSelectionStep }) => {
  return Yup.object({
    ...(showProductSelectionStep && productOrPagesValidation),
    [NAME]: Yup.string().required('This field should not be empty'),
    [CALL_TO_ACTION]: callToActionValidation,
    [HEADLINES_LIST]: Yup.array().of(headlineShape),
    ...getDuplicatedTextAssetsValidation(),

    [DESCRIPTIONS_LIST]: Yup.array().of(descriptionShape),
    ...customFirstDescriptionValidation,

    ...getAssetImageListValidation({ isPortraitImageRequired: false }),
    [LONG_HEADLINE]: Yup.string()
      .max(longHeadlineMaxLength, t('longHeadlineMaxLengthErrorMessage', { maxLength: longHeadlineMaxLength }))
      .required('This field should not be empty'),
    [CLICK_THROUGH_LINK]: urlValidation
  })
}
