import * as Yup from 'yup'
import { IDENTITY_ID, NAME, VIDEO_THUMBNAIL_URL } from './fields'
import { CALL_TO_ACTION } from '../../../../ReusableFormFields/CallToActionFields/fields'
import { urlValidation } from '../../../../../features/validations/other'
import { tikTokAdCTAValidation, tikTokAdTextValidation } from '../../validation'
import { AD_TEXT, LANDING_PAGE_URL } from '../../fields'
import { FILE_ID } from '../../../../ReusableFormFields/AdFileUpload/fields'

export const validationSchema = Yup.object({
  [NAME]: Yup.string().required('Name required').max(512, 'Name should have maximum 512 characters'),
  [IDENTITY_ID]: Yup.string().required('Please select identity'),
  [LANDING_PAGE_URL]: urlValidation,
  [AD_TEXT]: tikTokAdTextValidation,
  [CALL_TO_ACTION]: tikTokAdCTAValidation,
  [FILE_ID]: Yup.string().required('Please select a video file'),
  [VIDEO_THUMBNAIL_URL]: Yup.string().required('Please select a video thumbnail')
})
