import { createStyles } from '../../../styles/helpers'

import { blue, darkGrey } from '../../../styles/const/colors'

export default createStyles({
  metricsItem: {
    textAlign: 'right',
    whiteSpace: 'nowrap',
    width: '100%'
  },
  metricsValue: {
    fontSize: 14,
    '& sub': {
      fontSize: 12,
      verticalAlign: 'baseline'
    },
    '& i': {
      fontStyle: 'normal'
    }
  },
  subValue: {
    color: darkGrey,
    marginTop: 2,
    fontSize: 12
  },
  mobileContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end'
  },
  bracketsText: {
    color: blue,
    marginLeft: 3
  }
})
