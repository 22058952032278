import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { getAppDomainName } from '../../../../features/helpers/getAppDomainName'

import { ROUTES } from '../../../../constants/routes'

import useAuthStyles from '../../../../styles/common/authPages'

function PolicyLinks() {
  const { t } = useTranslation()
  const authClasses = useAuthStyles()

  const appName = getAppDomainName()

  return (
    <h3 className={classnames(authClasses.authPageSubtitle, authClasses.authPageFooter)}>
      {t('By clicking "Sign Up" you agree to')} {''}
      <a href={ROUTES.platformDomain + '/terms'} rel="noreferrer nofollow">
        {t('SocialAuthorizePage.terms', { appName })}
      </a>
      , {''}
      <a href={ROUTES.platformDomain + '/platform-terms'} rel="noreferrer nofollow">
        {t('Platform Terms')}
      </a>{' '}
      {t('and')} {''}
      <a href={ROUTES.platformDomain + '/privacy-policy'} rel="noreferrer nofollow">
        {t('Privacy Policy')}
      </a>
    </h3>
  )
}

export default PolicyLinks
