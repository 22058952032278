import * as Yup from 'yup'

// campaign
export const CAMPAIGN_OPTION = 'campaign_option'
export const CAMPAIGN_OPTION_EXISTING_CAMPAIGN = 'campaign_option_existing_campaign'
export const CAMPAIGN = 'campaign'

export const campaignValidation = {
  [CAMPAIGN]: Yup.string().when(CAMPAIGN_OPTION, {
    is: CAMPAIGN_OPTION_EXISTING_CAMPAIGN,
    then: () => Yup.string().required('Please select campaign')
  })
}
