import { useEffect, useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'

// solution taken from https://github.com/atlassian/react-beautiful-dnd/issues/2396#issuecomment-1705672386
// to prevent the error during multiple drag and drop actions:
// Invariant failed: Cannot find droppable entry with id ...
export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false)
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true))
    return () => {
      cancelAnimationFrame(animation)
      setEnabled(false)
    }
  }, [])
  if (!enabled) {
    return null
  }
  return <Droppable {...props}>{children}</Droppable>
}
