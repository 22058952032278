import React from 'react'
import { useSelector } from 'react-redux'

import TabsHeader from '../../../features/components/ContentTabs/TabsHeader'
import ManagementReporting from './ManagementReporting'
import MediaOrderCreateSimple from '../../../forms/Multiplatform/MediaOrderForms/MediaOrderCreateSimple'
import Can from '../../../features/components/Can'

import { getCurrentUserSelector } from '../../../modules/selectors/app'

import { CREATE_DIRECT_MEDIA_ORDER } from '../../../constants/permissions'

import useClasses from './styles'

const ControllerLandingPageContent = () => {
  const classes = useClasses()

  const { full_name: fullName } = useSelector(getCurrentUserSelector)

  return (
    <>
      <TabsHeader>
        <div className={classes.pageHeader}>
          <h1>👋 Welcome, {fullName}</h1>
          <Can I="create" a={CREATE_DIRECT_MEDIA_ORDER}>
            <MediaOrderCreateSimple />
          </Can>
        </div>
      </TabsHeader>
      <ManagementReporting />
    </>
  )
}

export default ControllerLandingPageContent
