import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Button from '../../../../components/Button'
import CancelButton from '../../../../features/components/Buttons/CancelButton'
import DeleteItemModal from '../../../../features/components/Modals/DeleteModal/DeleteItemModal'
import DeleteModalWrapper from '../../../../features/components/Modals/DeleteModal/DeleteModalWrapper'

import { deleteProductErrorSelector } from '../../../../modules/selectors/mediaOrdersProducts'

import { redirectTo } from '../../../../helpers/url'

import { SETTINGS_ROUTES } from '../../../../constants/routes'

const DeleteProductModal = ({ isOpen, onClose, product = {}, onDelete, productDeleteIsLoading }) => {
  const { t } = useTranslation()

  const deleteProductError = useSelector(deleteProductErrorSelector)

  const handleViewPackages = () => {
    redirectTo(SETTINGS_ROUTES.MEDIA_PACKAGES)
  }

  const handleDelete = () => {
    onDelete(product)
  }

  const isPossibleToDelete = !product?.media_packages?.length && !product?.has_booked_media

  if (isPossibleToDelete) {
    return (
      <DeleteItemModal
        handleDelete={handleDelete}
        handleCloseDeleteModal={onClose}
        isDeleteModalOpened={isOpen}
        deleteItemIsLoading={productDeleteIsLoading}
        deleteItemError={deleteProductError}
        showCancelButton={true}
        modalTitle={t('Delete media product?')}
        deleteModalMessage={t('This can’t be undone')}
      />
    )
  } else {
    const hasPackages = product?.media_packages?.length > 0

    let modalContent = {}
    if (hasPackages) {
      modalContent = {
        showPackagesButton: true,
        title: t('There are active packages that contain this product.'),
        message: t('You can’t delete this product until all packages containing it are set to inactive.')
      }
    } else if (product?.has_booked_media) {
      modalContent = {
        title: t('There are booked media records with this product.'),
        message: t('It can’t be deleted')
      }
    }

    return (
      <DeleteModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        modalTitle={modalContent.title}
        ModalMessage={modalContent.message}
      >
        <CancelButton onClick={onClose} />
        {modalContent.showPackagesButton && (
          <Button onClick={handleViewPackages} isSmall={true}>
            {t('View packages')}
          </Button>
        )}
      </DeleteModalWrapper>
    )
  }
}

DeleteProductModal.propTypes = {
  product: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  productDeleteIsLoading: PropTypes.bool.isRequired
}

export default DeleteProductModal
