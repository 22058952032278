import { createStyles } from '../../styles/helpers'

export default createStyles({
  wrapper: {
    '& .rgdp__grid-cell > div': {
      height: 20
    }
  },
  dateLabel: {
    fontSize: 14,
    textAlign: 'center'
  },
  timeLabel: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    paddingRight: 2
  }
})
