import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

// Get media product variables
export const mediaProductVariablesSelector = createSelector(
  ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.mediaProductVariables.results,
  results => results
)

export const getMediaProductVariablesIsLoadingSelector = createSelector(
  ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.mediaProductVariables.isLoading,
  isLoading => isLoading
)

export const getMediaProductVariablesErrorSelector = createSelector(
  ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.mediaProductVariables.error,
  error => error
)

export const mediaProductVariablesWasLoadedSelector = createSelector(
  ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.mediaProductVariables.wasLoaded,
  wasLoaded => wasLoaded
)

// Create product variable
export const createMediaProductVariableSelector = createSelector(
  ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.createProductVariable.data,
  data => data
)

export const createMediaProductVariableIsLoadingSelector = createSelector(
  ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.createProductVariable.isLoading,
  isLoading => isLoading
)

export const createMediaProductVariableErrorSelector = createSelector(
  ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.createProductVariable.error,
  error => error
)

export const createMediaProductVariableWasCreatedSelector = createSelector(
  ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.createProductVariable.wasCreated,
  wasCreated => wasCreated
)

// Update product variable
export const updateMediaProductVariableIdSelector = createSelector(
  ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.updateProductVariable.id,
  id => id
)

export const updateMediaProductVariableIsLoadingSelector = createSelector(
  ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.updateProductVariable.isLoading,
  isLoading => isLoading
)

export const updateMediaProductVariableErrorSelector = createSelector(
  ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.updateProductVariable.error,
  error => error
)

export const updateMediaProductVariableWasUpdatedSelector = createSelector(
  ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.updateProductVariable.wasUpdated,
  wasUpdated => wasUpdated
)

// Delete media product variable
export const deleteMediaProductVariableIdSelector = createSelector(
  ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.deleteMediaProductVariable.id,
  id => id
)

export const deleteMediaProductVariableIsLoadingSelector = createSelector(
  ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.deleteMediaProductVariable.isLoading,
  isLoading => isLoading
)

export const deleteMediaProductVariableErrorSelector = createSelector(
  ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.deleteMediaProductVariable.error,
  error => error
)

export const deleteMediaProductVariableWasDeletedSelector = createSelector(
  ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.deleteMediaProductVariable.wasDeleted,
  wasDeleted => wasDeleted
)

// Get media product variable options

export const mediaProductVariableOptionsSelector = variableId =>
  createSelector(
    ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.mediaProductVariableOptions[variableId],
    variableOptions => variableOptions?.results
  )

export const getMediaProductVariableOptionsIsLoadingSelector = variableId =>
  createSelector(
    ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.mediaProductVariableOptions[variableId],
    variableOptions => variableOptions?.isLoading
  )

export const getMediaProductVariableOptionsErrorSelector = variableId =>
  createSelector(
    ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.mediaProductVariableOptions[variableId],
    variableOptions => variableOptions?.error
  )

export const mediaProductVariableOptionsWasLoadedSelector = variableId =>
  createSelector(
    ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.mediaProductVariableOptions[variableId],
    variableOptions => variableOptions?.wasLoaded
  )

export const mediaProductVariableOptionsNextSelector = variableId =>
  createSelector(
    ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.mediaProductVariableOptions[variableId],
    variableOptions => variableOptions?.paging?.next
  )

// Get media product variables bulk list
export const getMediaProductVariablesBulkListIsLoadingSelector = createSelector(
  ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.getMediaProductVariablesBulkList.isLoading,
  isLoading => isLoading
)

export const getMediaProductVariablesBulkListErrorSelector = createSelector(
  ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.getMediaProductVariablesBulkList.error,
  error => error
)

export const mediaProductVariablesBulkListWasLoadedSelector = createSelector(
  ({ mediaOrdersProductVariables }) => mediaOrdersProductVariables.getMediaProductVariablesBulkList.wasLoaded,
  wasLoaded => wasLoaded
)
