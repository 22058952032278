import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './styles'

const StatisticBlocksGrid = ({ blocks }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {blocks.map(({ label, value }) => (
        <div className={classes.block} key={label}>
          <h4 className={classes.label}>{label}</h4>
          <h4 className={classes.value}>{value}</h4>
        </div>
      ))}
    </div>
  )
}

StatisticBlocksGrid.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired
}

export default StatisticBlocksGrid
