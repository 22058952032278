import * as Yup from 'yup'
import { OPTION_NO, OPTION_YES } from '../../../constants/forms'

export const INTERESTS = 'interests'
export const INTERESTS_OPTION = 'interests_option'

export const interestsInitialValues = {
  [INTERESTS_OPTION]: OPTION_NO,
  [INTERESTS]: []
}

export const interestsValidation = {
  [INTERESTS]: Yup.array().when(INTERESTS_OPTION, {
    is: OPTION_YES,
    then: () => Yup.array().min(1, 'Please select at least one option')
  })
}
