import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useEditAssetGroupSubmit } from '../../hooks'

import { getSquareImageData } from '../HeadlinesSection/helpers'
import { isAllImageAssetsReady } from '../../helpers'

import EditForm from '../../../../../../../features/components/Forms/EditForm'
import MultipleGoogleMediaUpload from '../../../../ReusableFields/MultipleGoogleMediaUpload'

import { selectedAdAccountIdSelector } from '../../../../../../../modules/selectors/app'

import { googleAssetFieldType } from '../../../../../../../constants/ads'
import { getInitialValues } from './fields'
import { SQUARE_IMAGE, squareImageHeight, squareImageWidth } from '../../../../fields'
import { getSquareImageValidationSchema } from '../../../../validations'
import { validationSchema } from './validation'

const SquareImageSectionForm = ({ editItemData, isInternalAssetGroup, ...formProps }) => {
  const { assets, id: assetGroupId, account } = editItemData

  const selectedAdAccount = useSelector(selectedAdAccountIdSelector)
  const adAccount = account || selectedAdAccount

  const originalSquareImageAssets = useMemo(() => getSquareImageData(assets), [assets])

  const initialValues = useMemo(() => getInitialValues(originalSquareImageAssets), [originalSquareImageAssets])
  const squareImageValidationSchema = useMemo(() => getSquareImageValidationSchema(), [])

  const purifiedFormik = useEditAssetGroupSubmit({
    originalSectionAssets: originalSquareImageAssets,
    fieldType: googleAssetFieldType.SQUARE_MARKETING_IMAGE,
    sectionValueKey: SQUARE_IMAGE,
    isInternalAssetGroup,
    assetGroupId,
    initialValues,
    validationSchema,
    adAccount
  })

  const { values } = purifiedFormik

  const isImagesReady = isAllImageAssetsReady(values, SQUARE_IMAGE)

  const isEmptyList = !values[SQUARE_IMAGE].length

  return (
    <EditForm formik={purifiedFormik} initialValues={initialValues} submitBtnDisabled={!isImagesReady} {...formProps}>
      <MultipleGoogleMediaUpload
        formik={purifiedFormik}
        FileItemPath={SQUARE_IMAGE}
        validationSchema={squareImageValidationSchema}
        addAnotherButtonText={isEmptyList ? 'Add square image' : 'Add another square image'}
        width={squareImageWidth}
        height={squareImageHeight}
        adAccountId={adAccount}
      />
    </EditForm>
  )
}

export default SquareImageSectionForm
