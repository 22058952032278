import update from 'immutability-helper'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_CONTRACT,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_FAILURE,
  CLEAR_GET_CONTRACT,
  GET_CONTRACTS,
  GET_CONTRACTS_SUCCESS,
  GET_CONTRACTS_FAILURE,
  CLEAR_GET_CONTRACTS,
  DELETE_CONTRACT_SIGNATURE,
  DELETE_CONTRACT_SIGNATURE_SUCCESS,
  DELETE_CONTRACT_SIGNATURE_FAILURE,
  CLEAR_DELETE_CONTRACT_SIGNATURE,
  UPDATE_CONTRACT,
  UPDATE_CONTRACT_SUCCESS,
  UPDATE_CONTRACT_FAILURE,
  UPDATE_CONTRACTS_STATUS_BULK,
  UPDATE_CONTRACTS_STATUS_BULK_SUCCESS,
  UPDATE_CONTRACTS_STATUS_BULK_FAILURE,
  CLEAR_UPDATE_CONTRACTS_STATUS_BULK,
  CLEAR_UPDATE_CONTRACT,
  CREATE_CONTRACT,
  CREATE_CONTRACT_SUCCESS,
  CREATE_CONTRACT_FAILURE,
  CLEAR_CREATE_CONTRACT,
  SIGN_CONTRACT,
  SIGN_CONTRACT_SUCCESS,
  SIGN_CONTRACT_FAILURE,
  CLEAR_SIGN_CONTRACT,
  CLEAR_ACTIVE_CONTRACT,
  SET_ACTIVE_CONTRACT
} from '../../actions/contracts'

import {
  INITIAL_CREATE_REDUCER,
  INITIAL_UPDATE_REDUCER,
  INITIAL_DELETE_REDUCER,
  INITIAL_GET_RESULTS_PAGING_REDUCER,
  INITIAL_GET_REDUCER
} from '../../../constants/reducer'
import { manageContractsBulkDelete } from './manageContractsBulkDelete'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  getContract: INITIAL_GET_REDUCER,
  activeContract: null,
  createContract: INITIAL_CREATE_REDUCER,
  updateContract: INITIAL_UPDATE_REDUCER,
  updateContractsStatusBulk: INITIAL_UPDATE_REDUCER,
  signContract: INITIAL_CREATE_REDUCER,
  deleteContractSignature: INITIAL_DELETE_REDUCER,
  contracts: INITIAL_GET_RESULTS_PAGING_REDUCER
}

export default function contracts(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // Get contract
    case GET_CONTRACT:
      return update(state, {
        getContract: { $merge: { isLoading: true } }
      })
    case GET_CONTRACT_SUCCESS:
      return update(state, {
        getContract: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        },
        // this used in QuotationReview when user creates signature
        activeContract: { $set: action.data }
      })
    case GET_CONTRACT_FAILURE:
      return update(state, {
        getContract: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_CONTRACT:
      return update(state, {
        getContract: { $set: initialState.getContract }
      })

    case SET_ACTIVE_CONTRACT:
      return update(state, {
        activeContract: { $set: action.contract }
      })

    case CLEAR_ACTIVE_CONTRACT:
      return update(state, {
        activeContract: { $set: initialState.activeContract }
      })

    // Create contract
    case CREATE_CONTRACT:
      return update(state, {
        createContract: { $merge: { isLoading: true } }
      })
    case CREATE_CONTRACT_SUCCESS:
      return update(state, {
        createContract: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },
        activeContract: { $set: action.data }
      })
    case CREATE_CONTRACT_FAILURE:
      return update(state, {
        createContract: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_CONTRACT:
      return update(state, {
        createContract: { $set: initialState.createContract }
      })

    // Update contract
    case UPDATE_CONTRACT:
      return update(state, {
        updateContract: { $merge: { isLoading: true } }
      })
    case UPDATE_CONTRACT_SUCCESS:
      return update(state, {
        updateContract: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            data: action.data
          }
        },
        activeContract: { $set: action.data }
      })
    case UPDATE_CONTRACT_FAILURE:
      return update(state, {
        updateContract: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_CONTRACT:
      return update(state, {
        updateContract: { $set: initialState.updateContract }
      })

    // Update contracts status bulk
    case UPDATE_CONTRACTS_STATUS_BULK:
      return update(state, {
        updateContractsStatusBulk: { $merge: { isLoading: true } }
      })
    case UPDATE_CONTRACTS_STATUS_BULK_SUCCESS:
      return update(state, {
        updateContractsStatusBulk: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            data: action.data
          }
        },
        contracts: {
          ...manageContractsBulkDelete(action)
        }
      })
    case UPDATE_CONTRACTS_STATUS_BULK_FAILURE:
      return update(state, {
        updateContractsStatusBulk: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_CONTRACTS_STATUS_BULK:
      return update(state, {
        updateContractsStatusBulk: { $set: initialState.updateContractsStatusBulk }
      })

    // Sign contract
    case SIGN_CONTRACT:
      return update(state, {
        signContract: { $merge: { isLoading: true } }
      })
    case SIGN_CONTRACT_SUCCESS:
      return update(state, {
        signContract: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },
        activeContract: { $set: action.data }
      })
    case SIGN_CONTRACT_FAILURE:
      return update(state, {
        signContract: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_SIGN_CONTRACT:
      return update(state, {
        signContract: { $set: initialState.signContract }
      })

    // Delete contract signature
    case DELETE_CONTRACT_SIGNATURE:
      return update(state, {
        deleteContractSignature: { $merge: { isLoading: true } }
      })
    case DELETE_CONTRACT_SIGNATURE_SUCCESS:
      return update(state, {
        deleteContractSignature: {
          $merge: {
            isLoading: false,
            wadDeleted: true,
            data: action.data
          }
        },
        activeContract: { $set: action.data }
      })
    case DELETE_CONTRACT_SIGNATURE_FAILURE:
      return update(state, {
        deleteContractSignature: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DELETE_CONTRACT_SIGNATURE:
      return update(state, {
        deleteContractSignature: { $set: initialState.deleteContractSignature }
      })

    // Get contracts
    case GET_CONTRACTS:
      return update(state, {
        contracts: { $merge: { isLoading: true } }
      })
    case GET_CONTRACTS_SUCCESS:
      return update(state, {
        contracts: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data.results
          }
        }
      })
    case GET_CONTRACTS_FAILURE:
      return update(state, {
        contracts: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_CONTRACTS:
      return update(state, {
        contracts: { $set: initialState.contracts }
      })

    default:
      return state
  }
}
