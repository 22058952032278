import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  getInventoryGroupIsLoadingSelector,
  inventoryGroupSelector,
  inventoryIsLoadingSelector,
  inventorySelector
} from '../../../../../../../../../../../modules/selectors/mediaOrdersProducts'

export const SelectPeriodsFieldsContext = React.createContext()

export const SelectPeriodsFieldsContextProvider = ({ children, product, currency, allowToFetchPreviousPeriods }) => {
  const regularInventory = useSelector(inventorySelector)
  const regularInventoryIsLoading = useSelector(inventoryIsLoadingSelector)
  const groupInventory = useSelector(inventoryGroupSelector)
  const groupInventoryIsLoading = useSelector(getInventoryGroupIsLoadingSelector)

  const {
    id: productId,
    media_category: selectedProductCategory,
    period: productPeriod,
    discounts: productDiscounts,
    group: isProductGroup
  } = product

  // the values below helps to determine which inventory to use - is it regular product or the product group
  const inventory = useMemo(() => {
    return isProductGroup ? groupInventory?.inventory || [] : regularInventory
  }, [isProductGroup, groupInventory, regularInventory])

  const inventoryIsLoading = useMemo(() => {
    return isProductGroup ? groupInventoryIsLoading : regularInventoryIsLoading
  }, [isProductGroup, groupInventoryIsLoading, regularInventoryIsLoading])

  const { code: currencyCode, symbol: currencySymbol } = currency

  return (
    <SelectPeriodsFieldsContext.Provider
      value={{
        productId,
        selectedProductCategory,
        productDiscounts,
        productPeriod,
        currencyCode,
        currencySymbol,
        allowToFetchPreviousPeriods,
        isProductGroup,
        inventory,
        inventoryIsLoading
      }}
    >
      {children}
    </SelectPeriodsFieldsContext.Provider>
  )
}
