import React from 'react'
import { useSelector } from 'react-redux'

import { generateDetailsText } from '../helpers'

import { mediaOrderHistorySelector } from '../../../../../../modules/selectors/mediaOrders'

import { formatDateDetailed } from '../../../../../../constants/dates'

import useStyles from './styles'

const MediaOrderHistoryList = () => {
  const classes = useStyles()

  const mediaOrderHistory = useSelector(mediaOrderHistorySelector)

  return mediaOrderHistory.map(historyItem => (
    <div key={historyItem.id} className={classes.historyItem}>
      <p>{formatDateDetailed(historyItem.created)}</p>
      <p className={classes.details}>{generateDetailsText(historyItem)}</p>
    </div>
  ))
}

export default MediaOrderHistoryList
