import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { activeStepSelector } from '../../../../modules/selectors/forms'

import { CAMPAIGN_FORM, LINE_ITEM_FORM, AD_FORM } from './index'

const useFormProgressItems = ({ activatedForm }) => {
  const activeStep = useSelector(activeStepSelector)

  return useMemo(
    () => [
      {
        label: 'Choose campaign type',
        isActive: CAMPAIGN_FORM,
        isSuccess: activatedForm === LINE_ITEM_FORM || activatedForm === AD_FORM
      },
      {
        label: 'Set campaign dates',
        isActive: activeStep === 0 && activatedForm === LINE_ITEM_FORM,
        isSuccess: (activeStep === 1 && activatedForm === LINE_ITEM_FORM) || activatedForm === AD_FORM
      },
      {
        label: 'Select audience',
        isActive: activeStep === 1 && activatedForm === LINE_ITEM_FORM,
        isSuccess: (activeStep === 2 && activatedForm === LINE_ITEM_FORM) || activatedForm === AD_FORM
      },
      {
        label: 'Upload video',
        isActive: activatedForm === AD_FORM,
        isSuccess: false
      }
    ],
    [activatedForm, activeStep]
  )
}

export default useFormProgressItems
