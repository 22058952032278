import { createStyles } from '../../../../styles/helpers'
import { darkGrey, white } from '../../../../styles/const/colors'

export default createStyles({
  cancelButton: {
    borderColor: `${darkGrey} !important`,
    color: `${darkGrey} !important`,

    '&:hover': {
      backgroundColor: `${darkGrey} !important`,
      color: `${white} !important`
    }
  }
})
