import { googleAssetFieldType } from '../../../../../../../constants/ads'

export const getSpecificTypeAssets = (assets, assetType) => {
  return assets.filter(({ field_type }) => field_type === assetType)
}

export const getHeadlineData = assets => {
  // filter only headline assets
  return getSpecificTypeAssets(assets, googleAssetFieldType.HEADLINE)
}

export const getDescriptionData = assets => {
  // filter only description assets
  return getSpecificTypeAssets(assets, googleAssetFieldType.DESCRIPTION)
}

export const getLongHeadlineData = assets => {
  // filter only long headline assets
  return getSpecificTypeAssets(assets, googleAssetFieldType.LONG_HEADLINE)
}

export const getLandscapeImageData = assets => {
  return getSpecificTypeAssets(assets, googleAssetFieldType.MARKETING_IMAGE)
}

export const getSquareImageData = assets => {
  return getSpecificTypeAssets(assets, googleAssetFieldType.SQUARE_MARKETING_IMAGE)
}

export const getPortraitImageData = assets => {
  return getSpecificTypeAssets(assets, googleAssetFieldType.PORTRAIT_MARKETING_IMAGE)
}

export const getSquareLogoData = assets => {
  return getSpecificTypeAssets(assets, googleAssetFieldType.LOGO)
}

export const getLandscapeLogoData = assets => {
  return getSpecificTypeAssets(assets, googleAssetFieldType.LANDSCAPE_LOGO)
}

export const getVideoUrlsData = assets => {
  return getSpecificTypeAssets(assets, googleAssetFieldType.YOUTUBE_VIDEO)
}

export const getBusinessNameData = assets => {
  return assets.find(({ field_type }) => field_type === googleAssetFieldType.BUSINESS_NAME)
}

export const getCTAData = assets => {
  return assets.find(({ field_type }) => field_type === googleAssetFieldType.CALL_TO_ACTION_SELECTION)
}
