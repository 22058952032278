import React from 'react'
import Skeleton from 'react-loading-skeleton'

import ListCardItem from '../../ListCardItem'
import { MobileDown, MobileUp } from '../../../../components/hoc/ResponsiveRendering'

import useCardStyles from '../../../../styles/common/card'
import useStyles from './styles'

// for cards that we use on audiences, reports, different settings pages
const SkeletonCard = () => {
  const cardClasses = useCardStyles({ isInactive: true })
  const classes = useStyles()

  return (
    <ListCardItem isInactive>
      <MobileUp>
        <div className={cardClasses.cardInfo}>
          <div className={cardClasses.cardName} style={{ paddingRight: 15 }}>
            <Skeleton />
          </div>

          <div className={classes.chipWrapper}>
            <Skeleton width={60} />
          </div>

          <div className={classes.chipWrapper}>
            <Skeleton width={100} />
          </div>
        </div>
      </MobileUp>
      <MobileDown>
        <div className={cardClasses.cardName} style={{ minWidth: '90%' }}>
          <Skeleton />
        </div>
        <div className={classes.chipWrapper}>
          <Skeleton width={45} />
        </div>
        <div className={classes.chipWrapper}>
          <Skeleton width={95} />
        </div>
      </MobileDown>
    </ListCardItem>
  )
}

export default SkeletonCard
