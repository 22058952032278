import { concat } from '../../helpers/common'

const MODULE_NAME = 'SELF_ACCOUNTS/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_SELF_ACCOUNTS = concat(MODULE_NAME, 'GET_SELF_ACCOUNTS')
export const GET_SELF_ACCOUNTS_SUCCESS = concat(MODULE_NAME, 'GET_SELF_ACCOUNTS_SUCCESS')
export const GET_SELF_ACCOUNTS_FAILURE = concat(MODULE_NAME, 'GET_SELF_ACCOUNTS_FAILURE')
export const CLEAR_SELF_ACCOUNTS = concat(MODULE_NAME, 'CLEAR_SELF_ACCOUNTS')

export const CREATE_SELF_ACCOUNT = concat(MODULE_NAME, 'CREATE_SELF_ACCOUNT')
export const CREATE_SELF_ACCOUNT_SUCCESS = concat(MODULE_NAME, 'CREATE_SELF_ACCOUNT_SUCCESS')
export const CREATE_SELF_ACCOUNT_FAILURE = concat(MODULE_NAME, 'CREATE_SELF_ACCOUNT_FAILURE')
export const CLEAR_CREATE_SELF_ACCOUNT = concat(MODULE_NAME, 'CLEAR_CREATE_SELF_ACCOUNT')

export const UPDATE_SELF_ACCOUNT = concat(MODULE_NAME, 'UPDATE_SELF_ACCOUNT')
export const UPDATE_SELF_ACCOUNT_SUCCESS = concat(MODULE_NAME, 'UPDATE_SELF_ACCOUNT_SUCCESS')
export const UPDATE_SELF_ACCOUNT_FAILURE = concat(MODULE_NAME, 'UPDATE_SELF_ACCOUNT_FAILURE')
export const CLEAR_UPDATE_SELF_ACCOUNT = concat(MODULE_NAME, 'CLEAR_UPDATE_SELF_ACCOUNT')

export const GET_SELF_ACCOUNT_CATEGORIES = concat(MODULE_NAME, 'GET_SELF_ACCOUNT_CATEGORIES')
export const GET_SELF_ACCOUNT_CATEGORIES_SUCCESS = concat(MODULE_NAME, 'GET_SELF_ACCOUNT_CATEGORIES_SUCCESS')
export const GET_SELF_ACCOUNT_CATEGORIES_FAILURE = concat(MODULE_NAME, 'GET_SELF_ACCOUNT_CATEGORIES_FAILURE')
export const CLEAR_GET_SELF_ACCOUNT_CATEGORIES = concat(MODULE_NAME, 'CLEAR_GET_SELF_ACCOUNT_CATEGORIES')

export const GET_SELF_ACCOUNT_SUB_CATEGORIES = concat(MODULE_NAME, 'GET_SELF_ACCOUNT_SUB_CATEGORIES')
export const GET_SELF_ACCOUNT_SUB_CATEGORIES_SUCCESS = concat(MODULE_NAME, 'GET_SELF_ACCOUNT_SUB_CATEGORIES_SUCCESS')
export const GET_SELF_ACCOUNT_SUB_CATEGORIES_FAILURE = concat(MODULE_NAME, 'GET_SELF_ACCOUNT_SUB_CATEGORIES_FAILURE')
export const CLEAR_GET_SELF_ACCOUNT_SUB_CATEGORIES = concat(MODULE_NAME, 'CLEAR_GET_SELF_ACCOUNT_SUB_CATEGORIES')

export const GET_SELF_ACCOUNT = concat(MODULE_NAME, 'GET_SELF_ACCOUNT')
export const GET_SELF_ACCOUNT_SUCCESS = concat(MODULE_NAME, 'GET_SELF_ACCOUNT_SUCCESS')
export const GET_SELF_ACCOUNT_FAILURE = concat(MODULE_NAME, 'GET_SELF_ACCOUNT_FAILURE')
export const CLEAR_GET_SELF_ACCOUNT = concat(MODULE_NAME, 'CLEAR_GET_SELF_ACCOUNT')

export const GET_SELF_ACCOUNT_CATEGORY = concat(MODULE_NAME, 'GET_SELF_ACCOUNT_CATEGORY')
export const GET_SELF_ACCOUNT_CATEGORY_SUCCESS = concat(MODULE_NAME, 'GET_SELF_ACCOUNT_CATEGORY_SUCCESS')
export const GET_SELF_ACCOUNT_CATEGORY_FAILURE = concat(MODULE_NAME, 'GET_SELF_ACCOUNT_CATEGORY_FAILURE')
export const CLEAR_GET_SELF_ACCOUNT_CATEGORY = concat(MODULE_NAME, 'CLEAR_GET_SELF_ACCOUNT_CATEGORY')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

export function getSelfAccounts(
  params,
  loadOptions = {
    shouldClearExistingState: false
  }
) {
  return { type: GET_SELF_ACCOUNTS, params, loadOptions }
}

export function getSelfAccountsSuccess(selfAccountsData) {
  return { type: GET_SELF_ACCOUNTS_SUCCESS, selfAccountsData }
}

export function getSelfAccountsFailure(error) {
  return { type: GET_SELF_ACCOUNTS_SUCCESS, error }
}

export function clearSelfAccounts() {
  return { type: CLEAR_SELF_ACCOUNTS }
}

export function createSelfAccount(selfAccountData, selectSelfAccountOnSuccess = false) {
  return { type: CREATE_SELF_ACCOUNT, selfAccountData, selectSelfAccountOnSuccess }
}

export function createSelfAccountSuccess(selfAccountData) {
  return { type: CREATE_SELF_ACCOUNT_SUCCESS, selfAccountData }
}

export function createSelfAccountFailure(error) {
  return { type: CREATE_SELF_ACCOUNT_FAILURE, error }
}

export function clearCreateSelfAccount() {
  return { type: CLEAR_CREATE_SELF_ACCOUNT }
}

export function updateSelfAccount(id, selfAccountData) {
  return { type: UPDATE_SELF_ACCOUNT, id, selfAccountData }
}

export function updateSelfAccountSuccess(selfAccountData) {
  return { type: UPDATE_SELF_ACCOUNT_SUCCESS, selfAccountData }
}

export function updateSelfAccountFailure(error) {
  return { type: UPDATE_SELF_ACCOUNT_FAILURE, error }
}

export function clearUpdateSelfAccount() {
  return { type: CLEAR_UPDATE_SELF_ACCOUNT }
}

// Get categories
export function getSelfAccountCategories(params) {
  return { type: GET_SELF_ACCOUNT_CATEGORIES, params }
}

export function getSelfAccountCategoriesSuccess(data) {
  return { type: GET_SELF_ACCOUNT_CATEGORIES_SUCCESS, data }
}

export function getSelfAccountCategoriesFailure(error) {
  return { type: GET_SELF_ACCOUNT_CATEGORIES_FAILURE, error }
}

export function clearGetSelfAccountCategories() {
  return { type: CLEAR_GET_SELF_ACCOUNT_CATEGORIES }
}

// Get sub categories
export function getSelfAccountSubCategories(params) {
  return { type: GET_SELF_ACCOUNT_SUB_CATEGORIES, params }
}

export function getSelfAccountSubCategoriesSuccess(data) {
  return { type: GET_SELF_ACCOUNT_SUB_CATEGORIES_SUCCESS, data }
}

export function getSelfAccountSubCategoriesFailure(error) {
  return { type: GET_SELF_ACCOUNT_SUB_CATEGORIES_FAILURE, error }
}

export function clearGetSelfAccountSubCategories() {
  return { type: CLEAR_GET_SELF_ACCOUNT_SUB_CATEGORIES }
}

// Get self account
export function getSelfAccount(params) {
  return { type: GET_SELF_ACCOUNT, params }
}

export function getSelfAccountSuccess(data) {
  return { type: GET_SELF_ACCOUNT_SUCCESS, data }
}

export function getSelfAccountFailure(error) {
  return { type: GET_SELF_ACCOUNT_FAILURE, error }
}

export function clearGetSelfAccount() {
  return { type: CLEAR_GET_SELF_ACCOUNT }
}

// Get category
export function getSelfAccountCategory(params) {
  return { type: GET_SELF_ACCOUNT_CATEGORY, params }
}

export function getSelfAccountCategorySuccess(data) {
  return { type: GET_SELF_ACCOUNT_CATEGORY_SUCCESS, data }
}

export function getSelfAccountCategoryFailure(error) {
  return { type: GET_SELF_ACCOUNT_CATEGORY_FAILURE, error }
}

export function clearGetSelfAccountCategory() {
  return { type: CLEAR_GET_SELF_ACCOUNT_CATEGORY }
}
