import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import BrandPageCreateForm from './BrandPageCreateForm'

import { CATEGORY_PAGE_CREATE } from '../../../../../constants/forms'
import { selectedSelfAccountDataSelector } from '../../../../../modules/selectors/app'

const BrandPageCreate = () => {
  const { t } = useTranslation()

  const { name: selfAccountName } = useSelector(selectedSelfAccountDataSelector)

  return (
    <FormDrawerWrapper
      formName={CATEGORY_PAGE_CREATE}
      title={t('Add a new brand page to be used as a click through link')}
      description={t('selectPageForAds', { selfAccountName })}
      openButtonText="Create brand page"
    >
      <BrandPageCreateForm />
    </FormDrawerWrapper>
  )
}

export default BrandPageCreate
