import {
  AD_PREVIEW_CAROUSEL_INDEX_KEY,
  CAROUSEL_ITEMS,
  FB_PAGE_CHOICE,
  FORMAT_CAROUSEL,
  FORMAT_OPTION,
  initialCarouselItem,
  IS_ITEM_FORM_OPENED,
  NAME,
  PIXEL_CHOICE,
  PRIMARY_TEXT_LIST
} from '../../../../fields'

import { initialProductOrPagesValues } from '../../../../../../ReusableFormFields/ProductsAndPagesFields/fields'

export const getInitialValues = ({ defaultCarouselItemsCount = 2, facebookPages }) => ({
  [NAME]: '',
  [FORMAT_OPTION]: FORMAT_CAROUSEL,
  [PRIMARY_TEXT_LIST]: [{ text: '' }],
  [FB_PAGE_CHOICE]: facebookPages[0]?.value, // preselect first facebook page
  // CAROUSEL
  [AD_PREVIEW_CAROUSEL_INDEX_KEY]: 'adCreateForm',
  // set initial carousel items based on defaultCarouselItemsCount
  [CAROUSEL_ITEMS]: [...Array(defaultCarouselItemsCount).keys()].map(() => ({ ...initialCarouselItem })),
  [IS_ITEM_FORM_OPENED]: true,
  [PIXEL_CHOICE]: '',
  // CATALOGUE PRODUCT route fields (internal feature)
  ...initialProductOrPagesValues
})
