import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'

import { BUYER_DISAPPROVED, DISAPPROVED, getStatusForDisapprovalAction } from '../components/UploadedFilesField/helpers'

import { updateBookingMediaFile } from '../../modules/actions/mediaOrdersBookings'
import { isControllerBuyerApprovalSelector } from '../../modules/selectors/app'
import { DISAPPROVED_REASON } from '../components/Modals/AskReasonModal/fields'

export const useChangeFileStatusOnDisapproved = () => {
  const dispatch = useDispatch()

  const isControllerBuyerApproval = useSelector(isControllerBuyerApprovalSelector)

  return useCallback(
    ({ requirementFileId, uploadedFile, [DISAPPROVED_REASON]: disapprovedReason }) => {
      const disapprovalActionStatus = getStatusForDisapprovalAction(uploadedFile, isControllerBuyerApproval)

      dispatch(
        updateBookingMediaFile(uploadedFile.id, {
          booked_media: uploadedFile.booked_media,
          requirementFileId,
          approval_status: disapprovalActionStatus,
          ...(disapprovalActionStatus === DISAPPROVED && { disapproved_reason: disapprovedReason }),
          ...(disapprovalActionStatus === BUYER_DISAPPROVED && { buyer_disapproved_reason: disapprovedReason })
        })
      )
    },
    [dispatch, isControllerBuyerApproval]
  )
}
