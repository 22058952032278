import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getIn } from 'formik'

import ActionText from '../../../../../components/ActionText'
import StoreSection from './StoreSection'

import { initialStoreValue, STORES } from '../../fields'

const StoresContent = ({ formik }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(0)
  const { values, setFieldValue } = formik

  const stores = getIn(values, STORES)

  const addNewItemButtonText = values[STORES].length ? '+ Add another sub-location' : '+ Add a sub-location'

  const currentStores = values[STORES]

  const handleAddAnotherItem = useCallback(() => {
    // open new item in carousel
    setSelected(currentStores.length)
    setFieldValue(STORES, [...currentStores, initialStoreValue])
  }, [setFieldValue, currentStores])

  const handleDeleteItem = useCallback(
    index => {
      // delete item from carousel items array
      const newCarouselItems = [...stores]
      newCarouselItems.splice(index, 1)

      // open previous item in carousel
      setSelected(newCarouselItems.length - 1)
      setFieldValue(STORES, newCarouselItems)
    },
    [stores, setFieldValue]
  )

  return (
    <>
      {stores.map((item, index) => {
        return (
          <StoreSection
            key={item.id}
            formik={formik}
            itemPath={`${STORES}[${index}]`}
            index={index}
            selected={selected === index}
            allowDelete={stores.length > 1}
            onSelect={() => setSelected(index)}
            onClose={() => setSelected(0)}
            onDelete={() => handleDeleteItem(index)}
          />
        )
      })}
      <ActionText onClick={handleAddAnotherItem} isDark>
        {t(addNewItemButtonText)}
      </ActionText>
    </>
  )
}

export default StoresContent
