import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { getIn } from 'formik'

import InputField from '../InputField'
import SelectField from '../SelectField'
import TextareaField from '../TextareaField'
import ErrorMessage from '../ErrorMessage'

import useFieldErrorReset from './hook'

import { getFormikFieldError } from '../../../helpers/errors'
import { capitalizeFirstLetter } from '../../../helpers/common'

import useStyles from './styles'

function Field({
  formik,
  symbol,
  symbolPosition,
  symbolClassName,
  name,
  id,
  placeholder,
  options,
  className,
  autoComplete,
  decimalScale,
  isTextarea,
  hasEmojiPicker,
  maxLength,
  enableReinitialize,
  showErrorMessage = true,
  onValueChange,
  textSuggestionsProps,
  onBlur,
  isLoading = false,
  onCreateOption,
  requestButton,
  focusFromOutside,
  errorClassName,
  ...restFieldProps
}) {
  const classes = useStyles()

  // we use ref here to render MaxLengthMessage and TextSuggestions inside portal from child component
  const ref = useRef(null)
  const [portalElement, setPortalElement] = useState(null)

  const { values, errors, status, setStatus, touched, setFieldValue, setFieldError, setFieldTouched } = formik
  // The built-in getIn formik function allows to get and interact with nested fields like objects in array etc.
  const value = getIn(values, name)
  const formikValue = value === 0 ? 0 : value || ''
  // Same approach here, we can get errors and touched for nested fields
  const { error, serverError } = getFormikFieldError(errors, status, name)

  const isTouched = getIn(touched, name)
  const showError = !!(isTouched && error)

  const formattedPlaceholder = useMemo(() => (placeholder ? capitalizeFirstLetter(placeholder) : ''), [placeholder])

  const { resetError } = useFieldErrorReset({ error, errors, name, setFieldError, serverError, setStatus, status })

  const renderField = () => {
    if (options) {
      return (
        <SelectField
          id={id}
          name={name}
          placeholder={formattedPlaceholder}
          className={classes.selectField}
          value={formikValue}
          onBlur={onBlur}
          hasError={showError}
          resetError={resetError}
          options={options}
          onValueChange={onValueChange}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          onCreateOption={onCreateOption}
          isLoading={isLoading}
          {...restFieldProps}
        />
      )
    }

    if (isTextarea) {
      return (
        <TextareaField
          name={name}
          placeholder={formattedPlaceholder}
          formikValue={formikValue}
          maxLength={maxLength}
          symbol={symbol}
          hasEmojiPicker={hasEmojiPicker}
          showError={showError}
          resetError={resetError}
          onBlur={onBlur}
          onValueChange={onValueChange}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          enableReinitialize={enableReinitialize}
          portalElement={portalElement}
          textSuggestionsProps={textSuggestionsProps}
          {...restFieldProps}
        />
      )
    }

    return (
      <InputField
        id={id}
        name={name}
        placeholder={formattedPlaceholder}
        formikValue={formikValue}
        symbol={symbol}
        symbolPosition={symbolPosition}
        symbolClassName={symbolClassName}
        showError={showError}
        resetError={resetError}
        onBlur={onBlur}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        enableReinitialize={enableReinitialize}
        autoComplete={autoComplete ? autoComplete : 'on'}
        decimalScale={decimalScale}
        onValueChange={onValueChange}
        maxLength={maxLength}
        portalElement={portalElement}
        textSuggestionsProps={textSuggestionsProps}
        requestButton={requestButton}
        focusFromOutside={focusFromOutside}
        {...restFieldProps}
      />
    )
  }

  useEffect(() => {
    setPortalElement(ref.current)
  }, [])

  return (
    // to avoid unnecessary re-renders formik sets only onBlur and field value is state manageable
    <div className={classnames('field', className, classes.field)}>
      <div className={classes.inputField}>{renderField()}</div>
      {showError && showErrorMessage && <ErrorMessage className={errorClassName} error={error} />}
      {/* div to render portal content */}
      <div ref={ref} />
    </div>
  )
}

export default Field

Field.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  symbol: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  symbolPosition: PropTypes.string,
  symbolClassName: PropTypes.string,
  autoComplete: PropTypes.string,
  options: PropTypes.array,
  decimalScale: PropTypes.number,
  onEmojiChoose: PropTypes.func,
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  onValueChange: PropTypes.func,
  onBlur: PropTypes.func,
  isLoading: PropTypes.bool,
  onCreateOption: PropTypes.func,
  requestButton: PropTypes.node,
  textSuggestionsProps: PropTypes.shape({
    suggestions: PropTypes.array,
    suggestionsIsLoading: PropTypes.bool,
    suggestionsSelector: PropTypes.func,
    suggestionsLoadingSelector: PropTypes.func,
    logoSrc: PropTypes.string
  })
}
