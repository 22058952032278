import { ADMIN, MANAGER, REPORT } from '../../../../../constants/permissions'

export const EMAIL = 'email'
export const ROLE = 'role'
export const SELF_ACCOUNTS = 'accounts'

export const roleOptions = [
  { label: 'Admin', value: ADMIN },
  { label: 'Manager', value: MANAGER },
  { label: 'Reporting', value: REPORT }
]

export const initialValues = {
  [EMAIL]: '',
  [ROLE]: MANAGER,
  [SELF_ACCOUNTS]: []
}
