import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import useDoubleRequestSubmit from '../../../../../../../hooks/formHooks/useDoubleRequestSubmit'

import { createNameFromCustomAudiences } from '../../../../../../Multiplatform/LineItemForms/formatters'

import {
  clearCreateLineItem,
  clearCreateLineItemCriterions,
  clearLineItemCriterions,
  createLineItem,
  createLineItemCriterions
} from '../../../../../../../modules/actions/lineItems'

import {
  createLineItemCriterionsErrorSelector,
  createLineItemCriterionsWasCreatedSelector,
  lineItemCreatedDataSelector,
  lineItemCreateErrorSelector,
  lineItemWasCreatedSelector
} from '../../../../../../../modules/selectors/lineItems'
import { mediaOrderUploadCreativeDataSelector } from '../../../../../../../modules/selectors/mediaOrders'
import { selectedGoogleAdAccountIdSelector } from '../../../../../../../modules/selectors/app'

import { GOOGLE_PLATFORM } from '../../../../../../../constants/selectLists/platformList'
import {
  CPM_BID,
  NAME
} from '../../../../../LineItemForms/LineItemGoogleCreate/LineItemGoogleCreateForm/LineItemGoogleCreateMainForm/fields'
import {
  AUDIENCE,
  AUDIENCE_OPTION
} from '../../../../../LineItemForms/LineItemGoogleCreate/LineItemGoogleCreateForm/LineItemGoogleAudienceForm/fields'
import { OPTION_YES } from '../../../../../../../constants/forms'

export function useLineItemGoogleCreate() {
  const dispatch = useDispatch()

  const [adGroupAudienceSuccess, setAdGroupAudienceSuccess] = useState(false)

  const { campaign } = useSelector(mediaOrderUploadCreativeDataSelector)
  const googleAdAccountId = useSelector(selectedGoogleAdAccountIdSelector)

  const campaignId = campaign.id

  const createAdGroupHandler = useCallback(
    values => {
      const hasCustomAudiences = values[AUDIENCE_OPTION] === OPTION_YES

      const formattedData = {
        // GA doesn't allow to have the same name for ads, for this we use unique id generation
        [NAME]: `${createNameFromCustomAudiences(hasCustomAudiences ? values[AUDIENCE] : [])} - ${uuidv4().slice(
          0,
          7
        )}`,
        [CPM_BID]: values[CPM_BID]
      }

      dispatch(
        createLineItem(
          {
            ...formattedData,
            account: googleAdAccountId,
            campaign_id: campaignId,
            status: 'active'
          },
          GOOGLE_PLATFORM
        )
      )
    },
    [dispatch, campaignId, googleAdAccountId]
  )

  const setAdGroupAudience = useCallback(
    (values, lineItemCreatedData) => {
      if (values[AUDIENCE_OPTION] === OPTION_YES) {
        const { id: assetGroupInternalId } = lineItemCreatedData
        const audienceCriterions = values[AUDIENCE].map(({ value }) => ({
          account: googleAdAccountId,
          ad_group: assetGroupInternalId,
          user_list: {
            user_list: value
          }
        }))

        dispatch(
          createLineItemCriterions({
            account: googleAdAccountId,
            operations: audienceCriterions
          })
        )
      } else {
        setAdGroupAudienceSuccess(true)
      }
    },
    [dispatch, googleAdAccountId]
  )

  const clearSubmitHandler = useCallback(() => {
    // after form submit
    dispatch(clearCreateLineItem())
    dispatch(clearCreateLineItemCriterions())
    dispatch(clearLineItemCriterions())
  }, [dispatch])

  const createAdGroupRequest = useMemo(
    () => ({
      requestHandler: createAdGroupHandler,
      errorSelector: lineItemCreateErrorSelector,
      successSelector: lineItemWasCreatedSelector,
      dataSelector: lineItemCreatedDataSelector
    }),
    [createAdGroupHandler]
  )

  const setAdGroupAudienceRequest = useMemo(
    () => ({
      requestHandler: setAdGroupAudience,
      errorSelector: createLineItemCriterionsErrorSelector,
      successSelector: createLineItemCriterionsWasCreatedSelector,
      manualSuccess: !!adGroupAudienceSuccess
    }),
    [setAdGroupAudience, adGroupAudienceSuccess]
  )

  const requests = useMemo(
    () => [createAdGroupRequest, setAdGroupAudienceRequest],
    [createAdGroupRequest, setAdGroupAudienceRequest]
  )

  return useDoubleRequestSubmit({ requests, clearSubmitHandler, keepFirstRequestProgress: true })
}
