import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Field from '../../../components/Form/Field'
// import MessageBlock from '../../../../components/Form/MessageBlock'

import { formatOptionsList } from '../../../features/formatters'

// import { getPixelsStats } from '../../../../modules/actions/lineItems'
import { getChoicesPixels, clearChoicesPixels } from '../../../modules/actions/choices'
import { choicesPixelsSelector, choicesPixelsWasLoadedSelector } from '../../../modules/selectors/choices'

import { CUSTOM_EVENT_TYPE, PROMOTED_PIXEL } from './fields'
import customEventTypes from '../../../constants/selectLists/customEventTypes'

import useDrawerFormsStyles from '../../../styles/common/drawerForms'
import { selectedAdAccountIdSelector } from '../../../modules/selectors/app'

const PromotedObjectFields = ({ formik, adAccountId }) => {
  const drawerFormsClasses = useDrawerFormsStyles()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const pixels = useSelector(choicesPixelsSelector)
  const pixelsWasLoaded = useSelector(choicesPixelsWasLoadedSelector)

  const formattedPixels = useMemo(
    () =>
      formatOptionsList({
        list: pixels,
        labelName: 'name',
        valueName: 'id'
      }),
    [pixels]
  )

  useEffect(() => {
    if (!pixelsWasLoaded) {
      // adAccountId can be also provided from outside (e.g. media-orders page)
      dispatch(getChoicesPixels({ account: adAccountId || selectedAdAccountId }))
    }
  }, [dispatch, adAccountId, selectedAdAccountId, pixels, pixelsWasLoaded])

  // this request force app to reach request limit, so commented until fixed on BE
  // useEffect(() => {
  //   if (promoted_pixel) {
  //     dispatch(getPixelsStats(promoted_pixel))
  //   }
  // }, [dispatch, promoted_pixel])

  useEffect(
    () => () => {
      dispatch(clearChoicesPixels())
    },
    [dispatch]
  )

  return (
    <section className={drawerFormsClasses.section}>
      <h4 className={drawerFormsClasses.sectionTitle}>{t('Event Source')}</h4>
      <Field
        placeholder={t('Event Source')}
        formik={formik}
        name={PROMOTED_PIXEL}
        options={formattedPixels}
        preselectFirstOptionValue
      />
      {/*todo finish when have ability to get pixels stats not empty response*/}
      {/*<MessageBlock title={t('No events found')} color="warning">*/}
      {/*  {t(*/}
      {/*    'This pixel has not had any events fire in the past 28 days. Events must be tracked (e.g. purchase) for this campaign to be able to optimise effectively. '*/}
      {/*  )}*/}
      {/*  <a*/}
      {/*    href={`https://business.facebook.com/events_manager2/list?business_id=${selectedAdAccountId}`}*/}
      {/*    target="_blank"*/}
      {/*  >*/}
      {/*    {t('Click here ')}*/}
      {/*  </a>*/}
      {/*  {t('to launch Facebook’s event manager.')}*/}
      {/*</MessageBlock>*/}
      {/*todo options formatting finish when have ability to get pixels stats not empty response*/}
      <Field placeholder={t('Event')} formik={formik} name={CUSTOM_EVENT_TYPE} options={customEventTypes} />
    </section>
  )
}

PromotedObjectFields.propTypes = {
  formik: PropTypes.object.isRequired,
  adAccountId: PropTypes.string
}

export default PromotedObjectFields
