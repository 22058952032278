import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Field from '../../../../../components/Form/Field'
import SpreadSheet from '../../../../../components/SpreadSheet'
import FieldsSection from '../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../features/components/Form/FieldsSection/FieldRow'

import { formatOptionsList } from '../../../../../features/formatters'

import { NAME, LOCATION_LIST_TYPE, COUNTRY, ADDRESSES, VALIDATION_ERROR_CODE } from '../fields'
import locationListTypes from '../../../../../constants/selectLists/locationListTypes'

import { locationAddressesValidationErrorSelector } from '../../../../../modules/selectors/location'
import { choicesCountriesSelector } from '../../../../../modules/selectors/choices'

import useFormsStyles from '../../../../../styles/common/drawerForms'
import useStyles from '../styles'

const spreadSheetColumnLabels = ['Name', 'Address and/or Postcode']

const LocationListFormFields = ({ formik, addressesErrorIndexes, isEditForm }) => {
  const drawerFormsClasses = useFormsStyles()
  const classes = useStyles()

  const { t } = useTranslation()

  const countries = useSelector(choicesCountriesSelector)
  const locationAddressesValidationError = useSelector(locationAddressesValidationErrorSelector)

  const isLocationAddressesValidationError =
    locationAddressesValidationError && locationAddressesValidationError.code === VALIDATION_ERROR_CODE

  const { values, touched, errors, setFieldValue } = formik

  const formattedCountries = formatOptionsList({
    list: countries,
    labelName: 'name',
    valueName: 'id'
  })

  return (
    <FieldsSection title={t('Settings and Addresses')}>
      <FieldRow
        title={t('Name')}
        description={t('A descriptive name that will be shown when setting campaign location')}
      >
        <Field formik={formik} name={NAME} enableReinitialize={!!isEditForm} placeholder={t('Name')} />
      </FieldRow>
      <FieldRow title={t('Type')} description={t('The type of addresses contained in this list')}>
        <Field
          options={locationListTypes}
          formik={formik}
          id={LOCATION_LIST_TYPE}
          name={LOCATION_LIST_TYPE}
          placeholder={t('List type')}
        />
      </FieldRow>
      <FieldRow
        title={t('Country')}
        description={t('If your addresses are located in more than one country, split them into separate lists')}
      >
        <Field options={formattedCountries} formik={formik} id={COUNTRY} name={COUNTRY} placeholder={'Country'} />
      </FieldRow>
      <div className={drawerFormsClasses.sectionTitle}>{t('List of locations (max 500)')}</div>
      <p>{t('You can paste the list from another spreadhseet and we will automatically add enough rows.')}</p>
      <p>
        {t(
          'You can give the location a name (e.g. Tesco Dundee) and provide either the full address or just the postcode. We will then match the addresses to map locations.'
        )}
      </p>
      <p>
        {t(
          'The maximum number of locations you can target in an ad set is 500, so we also limit our lists to 500. Please don’t paste a list any longer than that.'
        )}
      </p>
      <SpreadSheet
        name={ADDRESSES}
        setFieldValue={setFieldValue}
        formikData={values[ADDRESSES]}
        columnLabels={spreadSheetColumnLabels}
        maxRows={500}
        className={classes.spreadSheet}
        error={
          (touched[ADDRESSES] && errors[ADDRESSES]) ||
          (isLocationAddressesValidationError
            ? t(
                'Sorry, one or more of the addresses you have provided could not be matched. Please update the address (highlighted with red) to correct any errors or remove it'
              )
            : null)
        }
        errorRowsIndexes={addressesErrorIndexes}
      />
    </FieldsSection>
  )
}

LocationListFormFields.propTypes = {
  formik: PropTypes.object,
  addressesErrorIndexes: PropTypes.array,
  isEditForm: PropTypes.bool
}

export default LocationListFormFields
