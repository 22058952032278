import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import ProductPricesTable from './ProductPeriodsPriceTable'
import ActionText from '../../../../../../../../components/ActionText'

import { SELECTED_PRODUCTS } from '../fields'

import useStyles from './styles'

const ProductsPricesSection = ({
  formik,
  productValues,
  productData,
  productPath,
  showRemoveButton,
  currencySymbol
}) => {
  const classes = useStyles()

  const { values, setFieldValue } = formik

  const productRemoveHandler = useCallback(() => {
    setFieldValue(
      SELECTED_PRODUCTS,
      values[SELECTED_PRODUCTS].filter(({ id }) => id !== productValues.id)
    )
  }, [values, setFieldValue, productValues])

  return (
    <section className={classes.productSection}>
      <div className={classes.sectionHeader}>
        <h3 className={classes.sectionTitle}>{productData.name || productData.original_product_name}</h3>
        {showRemoveButton && (
          <ActionText className={classes.removeButton} onClick={productRemoveHandler}>
            Remove
          </ActionText>
        )}
      </div>

      <ProductPricesTable
        formik={formik}
        product={productValues}
        productPath={productPath}
        currencySymbol={currencySymbol}
      />
    </section>
  )
}

ProductsPricesSection.propTypes = {
  formik: PropTypes.object.isRequired,
  productValues: PropTypes.object.isRequired,
  productData: PropTypes.object.isRequired,
  productPath: PropTypes.string.isRequired,
  showRemoveButton: PropTypes.bool,
  currencySymbol: PropTypes.string.isRequired
}

export default ProductsPricesSection
