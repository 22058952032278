import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import Form from '../../../../../../../components/Form'
import Field from '../../../../../../../components/Form/Field'
import Icon from '../../../../../../../components/Icon'
import { ReactComponent as PicturesLogo } from '../../../../../../../assets/icons/pictures-logo.svg'

import { formatOptionsList } from '../../../../../../../features/formatters'
import { usePurifiedFormik } from '../../../../../../../hooks/formHooks/usePurifiedFormik'

import {
  accessibleFacebookPagesSelector,
  addAccessibleFacebookPagesErrorSelector,
  addAccessibleFacebookPagesIsLoadingSelector,
  addAccessibleFacebookPagesWasCreatedSelector,
  facebookPagesSelector
} from '../../../../../../../modules/selectors/socialAccounts'
import {
  addAccessibleFacebookPages,
  clearAddAccessibleFacebookPages
} from '../../../../../../../modules/actions/socialAccounts'
import { selectedSelfAccountSelector } from '../../../../../../../modules/selectors/app'

import { ACCESSIBLE_FACEBOOK_PAGE_CREATE } from '../../../../../../../constants/forms'
import { initialValues, validationSchema, PAGE_ID } from './fields'

import useStyles from './styles'
import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

const AddFacebookPageForm = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const drawerFormClasses = useDrawerFormStyles()

  const facebookPages = useSelector(facebookPagesSelector)
  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)
  const accessibleFacebookPages = useSelector(accessibleFacebookPagesSelector)
  const wasFacebookPagesAdded = useSelector(addAccessibleFacebookPagesWasCreatedSelector)

  const { t } = useTranslation()

  // don't show already added pages filter out pages which exists in accessibleFacebookPages list
  const availablePages = facebookPages.filter(
    ({ id }) => !accessibleFacebookPages.find(({ external_id }) => external_id === id)
  )
  const formattedFacebookPages = formatOptionsList({
    list: availablePages,
    valueName: 'id',
    labelName: 'name'
  })

  const onSubmit = useCallback(
    values => {
      const newFacebookPage = facebookPages.find(({ id }) => values[PAGE_ID] === id)

      dispatch(
        addAccessibleFacebookPages({
          ...newFacebookPage,
          external_id: newFacebookPage.id,
          account: selectedSelfAccount
        })
      )
    },
    [dispatch, facebookPages, selectedSelfAccount]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const clearAddFacebookPageHandler = useCallback(() => {
    dispatch(clearAddAccessibleFacebookPages())
  }, [dispatch])

  return (
    <Form
      formName={ACCESSIBLE_FACEBOOK_PAGE_CREATE}
      formik={purifiedFormik}
      successSubmit={wasFacebookPagesAdded}
      errorSelector={addAccessibleFacebookPagesErrorSelector}
      isLoadingSelector={addAccessibleFacebookPagesIsLoadingSelector}
      clearHandler={clearAddFacebookPageHandler}
      submitText="Add"
    >
      <section className={drawerFormClasses.section}>
        <h3 className={drawerFormClasses.sectionTitle}>{t('Select facebook page')}</h3>
        <Field
          placeholder="Select Facebook page"
          formik={formik}
          name={PAGE_ID}
          options={formattedFacebookPages}
          Icon={
            <Icon className={classes.picturesLogo}>
              <PicturesLogo />
            </Icon>
          }
        />
      </section>
    </Form>
  )
}

export default AddFacebookPageForm
