import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import ActionText from '../../../../../../../../components/ActionText'

import { SELECTED_PERIODS } from '../../../fields'

import { darkGrey, greyOutline } from '../../../../../../../../styles/const/colors'

const customColorSchema = {
  color: greyOutline,
  hoverColor: darkGrey,
  touchedColor: darkGrey
}

const SelectOrDeselectAllButton = ({ formik, groupedBookedMediaByFile }) => {
  const { t } = useTranslation()

  const { values, setFieldValue } = formik

  const selectAllProductsPeriodsHandler = useCallback(() => {
    const updatedBookedMedia = Object.values(groupedBookedMediaByFile).reduce((result, file) => {
      const fileMedia = file.bookedMedia.reduce((mediaResult, media) => {
        const isFileAlreadyUploaded = media.uploaded_files.some(item => item.media_product_file === file.id)
        if (!isFileAlreadyUploaded) {
          mediaResult[media.id] = true
        }
        return mediaResult
      }, {})
      result[file.id] = fileMedia
      return result
    }, {})

    setFieldValue(SELECTED_PERIODS, updatedBookedMedia)
  }, [setFieldValue, groupedBookedMediaByFile])

  const deselectAllProductsPeriodsHandler = useCallback(() => {
    const updatedBookedMedia = Object.values(groupedBookedMediaByFile).reduce((result, file) => {
      const fileMedia = file.bookedMedia.reduce((mediaResult, media) => {
        mediaResult[media.id] = false
        return mediaResult
      }, {})
      result[file.id] = fileMedia
      return result
    }, {})

    setFieldValue(SELECTED_PERIODS, updatedBookedMedia)
  }, [setFieldValue, groupedBookedMediaByFile])

  const groupedBookedMediaByFileAvailableForSelect = useMemo(
    () =>
      Object.values(groupedBookedMediaByFile).filter(file => {
        return !file.bookedMedia.some(bookedMediaItem => {
          return bookedMediaItem.uploaded_files.find(item => item.media_product_file === file.id)
        })
      }),
    [groupedBookedMediaByFile]
  )

  const isAllSelected = useMemo(
    () =>
      !groupedBookedMediaByFileAvailableForSelect.some(file => {
        return file.bookedMedia.some(media => {
          return !values[SELECTED_PERIODS][file.id][media.id]
        })
      }),
    [groupedBookedMediaByFileAvailableForSelect, values]
  )

  const showSelectAll = useMemo(
    () => groupedBookedMediaByFileAvailableForSelect.length > 1 && !isAllSelected,
    [groupedBookedMediaByFileAvailableForSelect, isAllSelected]
  )
  const showDeselectAll = useMemo(
    () => groupedBookedMediaByFileAvailableForSelect.length > 1 && isAllSelected,
    [groupedBookedMediaByFileAvailableForSelect, isAllSelected]
  )

  return (
    <>
      {showSelectAll && (
        <ActionText customColorSchema={customColorSchema} onClick={selectAllProductsPeriodsHandler}>
          {t('Select All')}
        </ActionText>
      )}

      {showDeselectAll && (
        <ActionText customColorSchema={customColorSchema} onClick={deselectAllProductsPeriodsHandler}>
          {t('Deselect All')}
        </ActionText>
      )}
    </>
  )
}

SelectOrDeselectAllButton.propTypes = {
  formik: PropTypes.object,
  groupedBookedMediaByFile: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      bookedMedia: PropTypes.array.isRequired
    })
  ).isRequired
}

export default SelectOrDeselectAllButton
