import { createStyles } from '../../../../../styles/helpers'

import { black } from '../../../../../styles/const/colors'

export default createStyles(theme => ({
  actionButton: {
    border: `1px solid ${black}`,
    borderRadius: theme.defaultBorderRadius,
    cursor: 'pointer',
    minWidth: 48,
    height: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 8,
    whiteSpace: 'nowrap'
  },
  actionLabel: {
    padding: '2px 2px 1px',
    textTransform: 'uppercase',
    lineHeight: 0
  },
  skeletonButtonLine: {
    minWidth: 40,
    maxHeight: 7,
    height: 7,
    margin: 0
  }
}))
