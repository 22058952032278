import { createStyles } from '../../../styles/helpers'
import { grey } from '../../../styles/const/colors'
import { phonesDown, tabletDown } from '../../../styles/const/breakpoints'
import { siteLink } from '../../../styles/common'

const useStyles = createStyles({
  infoContainer: {
    textAlign: 'center',
    marginTop: ({ centered }) => (centered ? 200 : 32),
    maxWidth: 800,
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  title: {
    fontSize: 20,
    marginTop: 16,
    marginBottom: 8
  },
  description: {
    color: ({ greyDescription }) => greyDescription && grey,
    fontSize: 14,
    '& a': {
      extend: siteLink
    }
  },
  [`@media screen and (${tabletDown})`]: {
    infoContainer: {
      marginTop: ({ centered }) => (centered ? 120 : 65)
    }
  },
  [`@media screen and (${phonesDown})`]: {
    infoContainer: {
      // we use such workaround because in other way styles don't work, because we use conditions before
      marginTop: () => 65
    }
  }
})

export default useStyles
