import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Field from '../../../../../../../components/Form/Field'

import { CAMPAIGN_BUDGET } from '../../../fields'
import { formatCurrency } from '../../../../../../../helpers/numbers'
import { BUDGET_DAILY, BUDGET_LIFETIME } from '../../../../../../ReusableFormFields/LineItemForms/fields'

import { selectedAdAccountDataSelector } from '../../../../../../../modules/selectors/app'

const BudgetContent = ({ formik, budgetType, campaignBudgetLifetime, campaignBudgetDaily }) => {
  const { t } = useTranslation()

  const { currency_symbol } = useSelector(selectedAdAccountDataSelector)

  if (budgetType === CAMPAIGN_BUDGET) {
    const formattedCampaignBudgetLifetime = formatCurrency(
      campaignBudgetLifetime,
      { min: 2, max: 2 },
      { symbol: currency_symbol }
    )

    const formattedCampaignBudgetDaily = formatCurrency(
      campaignBudgetDaily,
      { min: 2, max: 2 },
      { symbol: currency_symbol }
    )

    const budgetTypeMessage = campaignBudgetLifetime ? 'lifetime' : 'daily'
    const budgetValue = campaignBudgetLifetime ? formattedCampaignBudgetLifetime : formattedCampaignBudgetDaily

    return (
      <>
        <p>
          {t('campaignBudgetDetail', { budgetTypeMessage: budgetTypeMessage, budgetValue: budgetValue })}{' '}
          {t('This will be allocated across ad groups based on performance.')}
        </p>
        <p>{t('If you want to edit this budget, edit the campaign.')}</p>
      </>
    )
  }

  if (budgetType === BUDGET_LIFETIME) {
    return (
      <Field
        type="number"
        formik={formik}
        name={BUDGET_LIFETIME}
        placeholder="Lifetime Budget"
        symbol={currency_symbol}
        autoComplete="off"
      />
    )
  }

  if (budgetType === BUDGET_DAILY) {
    return (
      <Field
        type="number"
        formik={formik}
        name={BUDGET_DAILY}
        placeholder="Daily Budget"
        symbol={currency_symbol}
        autoComplete="off"
      />
    )
  }

  return null
}

export default BudgetContent
