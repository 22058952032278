import { createStyles } from '../../styles/helpers'

import { angleIcon } from '../../styles/common/components'
import { smallDesktopDown, smallPhonesDown } from '../../styles/const/breakpoints'

export default createStyles(theme => ({
  datePickerSelect: {
    width: '100%'
  },
  calendar: {
    '& .rdrNextPrevButton': {
      width: 'auto',
      height: 'auto',
      background: 'none',

      '&:before': {
        extend: angleIcon
      },
      '& i': {
        display: 'none'
      }
    },
    '& .rdrDateDisplayWrapper': {
      display: 'none'
    },
    '& .rdrMonthAndYearWrapper': {
      height: 50,
      paddingTop: 0
    },
    '& .rdrMonth': {
      paddingBottom: 10
    },
    '& .rdrPprevButton': {
      transform: 'rotate(90deg)'
    },
    '& .rdrNextButton': {
      transform: 'rotate(-90deg)'
    },

    '& .rdrDayNumber, & .rdrDayToday .rdrDayNumber span': {
      fontWeight: 400
    },

    '& .rdrDayStartPreview': {
      color: `${theme.brandPrimary} !important`
    },
    '& .rdrSelected': {
      color: `${theme.brandPrimary} !important`
    },
    '& .rdrDayHovered': {
      color: `${theme.brandPrimary} !important`
    },
    '& .rdrDayToday .rdrDayNumber span:after': {
      color: theme.brandPrimaryTextContrastColor,
      background: theme.brandPrimary
    },
    '& .rdrDayPassive': {
      pointerEvents: 'all'
    }
  },
  [`@media screen and (${smallDesktopDown})`]: {
    calendar: {
      '& .rdrMonth': {
        width: '100%',
        paddingBottom: 5
      }
    }
  },
  [`@media screen and (${smallPhonesDown})`]: {
    datePickerDropdown: {
      transform: 'translate3d(-56px, 54px, 0px) !important',
      inset: '0px auto auto 0px !important'
    },
    calendar: {
      '& .rdrMonth': {
        width: '100%',
        paddingBottom: 5
      },
      '& .rdrDay': {
        height: 28
      },
      '& .rdrMonthAndYearWrapper': {
        height: 40
      },
      '& .rdrDayToday .rdrDayNumber span:after': {
        bottom: -1
      }
    }
  }
}))
