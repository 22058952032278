import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import Page from '../index'
import SelfAccountCreate from '../../forms/Multiplatform/SelfAccountForms/SelfAccountCreate'
import AgencyAdminSelfAccountCreateForm from '../../forms/Multiplatform/SelfAccountForms/AgencyAdminSelfAccountCreateForm'

import { createSelfAccountDataSelector } from '../../modules/selectors/selfAccounts'
import { userProfileAgenciesSelector } from '../../modules/selectors/app'

import { redirectTo } from '../../helpers/url'
import { ROUTE_PARAMS, ROUTES } from '../../constants/routes'

import useAuthStyles from '../../styles/common/authPages'

const AccountSetup = () => {
  const authClasses = useAuthStyles()

  const { id: createdSelfAccountId } = useSelector(createSelfAccountDataSelector)
  const userProfileAgencies = useSelector(userProfileAgenciesSelector)
  // The only one scenario when user logs in, and they don't have selfAccount, but have an agency,
  // is first login via inviting new user to agency.
  // So it's ok to take just first agency
  const firstAgency = userProfileAgencies[0]
  const isAgencyAdmin = firstAgency && firstAgency.role === 'admin'

  useEffect(() => {
    // if self account was created > redirect to homepage
    if (createdSelfAccountId) {
      redirectTo(ROUTES.selfAccountHome, {
        [ROUTE_PARAMS.selfAccount]: createdSelfAccountId
      })
    }
  }, [createdSelfAccountId])

  return (
    <Page helmetTitle="helmetTitle.AccountSetupPage" className={authClasses.authPage}>
      {/* For agency admin we have separate form */}
      {isAgencyAdmin ? <AgencyAdminSelfAccountCreateForm /> : <SelfAccountCreate />}
    </Page>
  )
}

export default AccountSetup
