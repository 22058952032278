import update from 'immutability-helper'

import { deleteItemById, updateItem } from '../../helpers/modules/reducerHelpers'

import {
  INITIAL_CREATE_REDUCER,
  INITIAL_UPDATE_REDUCER,
  INITIAL_DELETE_REDUCER,
  INITIAL_GET_REDUCER,
  INITIAL_GET_RESULTS_PAGING_REDUCER,
  INITIAL_GET_RESULTS_REDUCER
} from '../../constants/reducer'
import {
  GET_BOOKING_MEDIA_FILES,
  GET_BOOKING_MEDIA_FILES_SUCCESS,
  GET_BOOKING_MEDIA_FILES_FAILURE,
  CLEAR_GET_BOOKING_MEDIA_FILES,
  CLEAR_GET_ORDER_BOOKED_MEDIA,
  GET_ORDER_BOOKED_MEDIA,
  GET_ORDER_BOOKED_MEDIA_FAILURE,
  GET_ORDER_BOOKED_MEDIA_SUCCESS,
  UPLOAD_BOOKING_MEDIA_FILE,
  UPLOAD_BOOKING_MEDIA_FILE_SUCCESS,
  UPLOAD_BOOKING_MEDIA_FILE_FAILURE,
  CLEAR_UPLOAD_BOOKING_MEDIA_FILE,
  DELETE_BOOKING_MEDIA_FILE,
  DELETE_BOOKING_MEDIA_FILE_SUCCESS,
  DELETE_BOOKING_MEDIA_FILE_FAILURE,
  CLEAR_DELETE_BOOKING_MEDIA_FILE,
  UPDATE_BOOKING_MEDIA_FILE,
  UPDATE_BOOKING_MEDIA_FILE_FAILURE,
  CLEAR_UPDATE_BOOKING_MEDIA_FILE,
  UPDATE_BOOKING_MEDIA_FILE_SUCCESS,
  GET_MEDIA_ORDERS_BOOKINGS_AS_CSV,
  GET_MEDIA_ORDERS_BOOKINGS_AS_CSV_FAILURE,
  GET_MEDIA_ORDERS_BOOKINGS_AS_CSV_SUCCESS,
  CLEAR_GET_MEDIA_ORDERS_BOOKINGS_AS_CSV,
  GET_BOOKED_MEDIA_REPORT,
  GET_BOOKED_MEDIA_REPORT_SUCCESS,
  GET_BOOKED_MEDIA_REPORT_FAILURE,
  CLEAR_GET_BOOKED_MEDIA_REPORT,
  GET_INSTALLATION_BOOKED_MEDIA_REPORT,
  GET_INSTALLATION_BOOKED_MEDIA_REPORT_SUCCESS,
  GET_INSTALLATION_BOOKED_MEDIA_REPORT_FAILURE,
  CLEAR_GET_INSTALLATION_BOOKED_MEDIA_REPORT,
  GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV,
  GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV_SUCCESS,
  GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV_FAILURE,
  CLEAR_GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV,
  BULK_UPDATE_BOOKING_MEDIA_FILE,
  BULK_UPDATE_BOOKING_MEDIA_FILE_SUCCESS,
  BULK_UPDATE_BOOKING_MEDIA_FILE_FAILURE,
  CLEAR_BULK_UPDATE_BOOKING_MEDIA_FILE,
  BULK_UPDATE_BOOKED_MEDIA,
  BULK_UPDATE_BOOKED_MEDIA_SUCCESS,
  BULK_UPDATE_BOOKED_MEDIA_FAILURE,
  CLEAR_BULK_UPDATE_BOOKED_MEDIA,
  GET_BOOKED_MEDIA,
  GET_BOOKED_MEDIA_SUCCESS,
  GET_BOOKED_MEDIA_FAILURE,
  CLEAR_GET_BOOKED_MEDIA,
  GET_BOOKED_REVENUE_REPORT,
  GET_BOOKED_REVENUE_REPORT_SUCCESS,
  GET_BOOKED_REVENUE_REPORT_FAILURE,
  CLEAR_GET_BOOKED_REVENUE_REPORT,
  GET_BRAND_REVENUE_REPORT,
  GET_BRAND_REVENUE_REPORT_SUCCESS,
  GET_BRAND_REVENUE_REPORT_FAILURE,
  CLEAR_GET_BRAND_REVENUE_REPORT
} from '../actions/mediaOrdersBookings'
import { updateInstallationReportItemsFiles } from '../../helpers/modules/reducerHelpers/mediaOrdersBookings'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  mediaOrdersBookingsAsCSV: INITIAL_GET_REDUCER,
  bookingMediaFiles: INITIAL_GET_RESULTS_PAGING_REDUCER,
  deleteBookingMediaFile: INITIAL_DELETE_REDUCER,
  uploadBookingMediaFile: INITIAL_CREATE_REDUCER,
  updateBookingMediaFile: INITIAL_UPDATE_REDUCER,
  bookedMedia: INITIAL_GET_RESULTS_PAGING_REDUCER,
  bookedMediaReport: {
    ...INITIAL_GET_RESULTS_REDUCER,
    loadingPeriods: []
  },
  getBookedRevenueReport: {
    ...INITIAL_GET_RESULTS_REDUCER,
    loadingPeriods: []
  },
  brandRevenueReport: INITIAL_GET_RESULTS_REDUCER,
  installationBookedMediaReport: INITIAL_GET_RESULTS_REDUCER,
  installationBookedMediaReportAsCSV: INITIAL_GET_REDUCER,
  bulkUpdateBookingMediaFile: INITIAL_UPDATE_REDUCER,
  bulkUpdateBookedMedia: INITIAL_UPDATE_REDUCER,
  getBookedMedia: INITIAL_GET_REDUCER
}

export default function mediaOrdersBookings(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // Get media orders bookings as csv
    case GET_MEDIA_ORDERS_BOOKINGS_AS_CSV:
      return update(state, {
        mediaOrdersBookingsAsCSV: { $merge: { isLoading: true } }
      })
    case GET_MEDIA_ORDERS_BOOKINGS_AS_CSV_SUCCESS:
      return update(state, {
        mediaOrdersBookingsAsCSV: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        }
      })
    case GET_MEDIA_ORDERS_BOOKINGS_AS_CSV_FAILURE:
      return update(state, {
        mediaOrdersBookingsAsCSV: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_MEDIA_ORDERS_BOOKINGS_AS_CSV:
      return update(state, {
        mediaOrdersBookingsAsCSV: { $set: initialState.mediaOrdersBookingsAsCSV }
      })

    // Get booking media files
    case GET_BOOKING_MEDIA_FILES:
      return update(state, {
        bookingMediaFiles: { $merge: { isLoading: true } }
      })
    case GET_BOOKING_MEDIA_FILES_SUCCESS:
      return update(state, {
        bookingMediaFiles: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: {
              next: action.data?.next
            }
          },
          results: {
            $push: action.data.results
          }
        }
      })
    case GET_BOOKING_MEDIA_FILES_FAILURE:
      return update(state, {
        bookingMediaFiles: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_BOOKING_MEDIA_FILES:
      return update(state, {
        bookingMediaFiles: { $set: initialState.bookingMediaFiles }
      })

    // Upload booking media file
    case UPLOAD_BOOKING_MEDIA_FILE:
      return update(state, {
        uploadBookingMediaFile: { $merge: { isLoading: true, requestedFileId: action.data?.[0]?.media_product_file } }
      })
    case UPLOAD_BOOKING_MEDIA_FILE_SUCCESS:
      return update(state, {
        uploadBookingMediaFile: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },
        bookedMedia: {
          results: {
            $apply: items => {
              // Add uploaded file to the booked medias
              // one file could be added to multiple medias so need to loop through all of them and call updateItem
              // to each booked media which is updated
              return action.data.reduce((bookedMedias, file) => {
                // find data through the updated bookedMedia to avoid losing previous updates:
                // for cases when media have multiple files, and multiple files where uploaded for it
                const oldMedia = bookedMedias.find(item => item.id === file.booked_media)
                const updatedMedia = {
                  ...oldMedia,
                  // add the new file to already uploaded files
                  uploaded_files: [...oldMedia?.uploaded_files, file]
                }

                return updateItem(bookedMedias, updatedMedia, file.booked_media)
              }, items)
            }
          }
        }
      })
    case UPLOAD_BOOKING_MEDIA_FILE_FAILURE:
      return update(state, {
        uploadBookingMediaFile: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPLOAD_BOOKING_MEDIA_FILE:
      return update(state, {
        uploadBookingMediaFile: { $set: initialState.uploadBookingMediaFile }
      })

    // Update booking media file
    case UPDATE_BOOKING_MEDIA_FILE:
      return update(state, {
        updateBookingMediaFile: {
          $merge: {
            isLoading: true,
            id: action.id,
            requirementFileId: action?.data?.requirementFileId,
            booked_media: action?.data?.booked_media
          }
        }
      })
    case UPDATE_BOOKING_MEDIA_FILE_SUCCESS:
      return update(state, {
        updateBookingMediaFile: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            data: action.data
          }
        },
        bookedMedia: {
          results: {
            $apply: items => {
              const oldMedia = items.find(item => item.id === action.data.booked_media)
              const updatedMedia = {
                ...oldMedia,
                uploaded_files: updateItem(oldMedia.uploaded_files, action.data, action.data.id)
              }

              return updateItem(items, updatedMedia, action.data.booked_media)
            }
          }
        }
      })
    case UPDATE_BOOKING_MEDIA_FILE_FAILURE:
      return update(state, {
        updateBookingMediaFile: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_BOOKING_MEDIA_FILE:
      return update(state, {
        updateBookingMediaFile: { $set: initialState.updateBookingMediaFile }
      })

    // Delete booking media file
    case DELETE_BOOKING_MEDIA_FILE:
      return update(state, {
        deleteBookingMediaFile: {
          $merge: {
            isLoading: true,
            id: action.id,
            requirementFileId: action?.params?.requirementFileId,
            booked_media: action?.params?.booked_media
          }
        }
      })
    case DELETE_BOOKING_MEDIA_FILE_SUCCESS:
      return update(state, {
        deleteBookingMediaFile: {
          $merge: {
            isLoading: false,
            wasDeleted: true
          }
        },
        bookedMedia: {
          results: {
            $apply: items => {
              const oldMedia = items.find(item => item.id === action.data.booked_media)
              const updatedMedia = {
                ...oldMedia,
                uploaded_files: deleteItemById(oldMedia?.uploaded_files, action.data.id)
              }

              return updateItem(items, updatedMedia, action.data.booked_media)
            }
          }
        }
      })
    case DELETE_BOOKING_MEDIA_FILE_FAILURE:
      return update(state, {
        deleteBookingMediaFile: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DELETE_BOOKING_MEDIA_FILE:
      return update(state, {
        deleteBookingMediaFile: { $set: initialState.deleteBookingMediaFile }
      })

    // Get order booked media
    case GET_ORDER_BOOKED_MEDIA:
      return update(state, {
        bookedMedia: {
          $merge: {
            ...(action.loadOptions.shouldClearExistingState && INITIAL_GET_RESULTS_PAGING_REDUCER),
            isLoading: true
          }
        }
      })
    case GET_ORDER_BOOKED_MEDIA_SUCCESS:
      return update(state, {
        bookedMedia: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: {
              next: action.data?.next
            }
          },
          results: {
            $push: action.data.results
          }
        }
      })
    case GET_ORDER_BOOKED_MEDIA_FAILURE:
      return update(state, {
        bookedMedia: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_ORDER_BOOKED_MEDIA:
      return update(state, {
        bookedMedia: { $set: initialState.bookedMedia }
      })

    // Get booked media report
    case GET_BOOKED_MEDIA_REPORT:
      return update(state, {
        bookedMediaReport: {
          $merge: {
            ...(action.loadOptions.shouldClearExistingState && INITIAL_GET_RESULTS_REDUCER),
            isLoading: true
          },
          ...(action.period && {
            loadingPeriods: {
              $push: [action.period]
            }
          })
        }
      })
    case GET_BOOKED_MEDIA_REPORT_SUCCESS:
      return update(state, {
        bookedMediaReport: {
          $merge: {
            isLoading: false,
            wasLoaded: true
          },
          results: {
            $apply: oldData => {
              // Loop through the new array of data
              return action.data.reduce((updatedData, newItem) => {
                // Find the index of the new item in the old data
                const index = updatedData.findIndex(oldItem => oldItem.location_name === newItem.location_name)

                // If the new item exists in the old data, update it
                // Otherwise, add it to the old data
                return index >= 0
                  ? update(updatedData, {
                      [index]: {
                        $merge: {
                          ...newItem,
                          // push new booked media to the previous
                          booked_media: [...updatedData[index].booked_media, ...newItem.booked_media]
                        }
                      }
                    })
                  : [...updatedData, newItem]
              }, oldData)
            }
          },
          // remove loaded periods:
          ...(action.period && {
            loadingPeriods: {
              $apply: periods => periods.filter(period => period.startDate !== action.period.startDate)
            }
          })
        }
      })
    case GET_BOOKED_MEDIA_REPORT_FAILURE:
      return update(state, {
        bookedMediaReport: {
          $merge: {
            isLoading: false,
            error: action.error
          },
          // remove period from loading:
          ...(action.period && {
            loadingPeriods: {
              $apply: periods => periods.filter(period => period.startDate !== action.period.startDate)
            }
          })
        }
      })
    case CLEAR_GET_BOOKED_MEDIA_REPORT:
      return update(state, {
        bookedMediaReport: { $set: initialState.bookedMediaReport }
      })

    // Get booked revenue report
    case GET_BOOKED_REVENUE_REPORT:
      return update(state, {
        getBookedRevenueReport: {
          $merge: {
            ...(action.loadOptions.shouldClearExistingState && INITIAL_GET_RESULTS_REDUCER),
            isLoading: true
          },
          ...(action.period && {
            loadingPeriods: {
              $push: [action.period]
            }
          })
        }
      })
    case GET_BOOKED_REVENUE_REPORT_SUCCESS:
      return update(state, {
        getBookedRevenueReport: {
          $merge: {
            isLoading: false,
            wasLoaded: true
          },
          results: {
            $apply: oldData => {
              // Loop through the new array of data
              return action.data.reduce((updatedData, newItem) => {
                // Find the index of the new item in the old data
                const index = updatedData.findIndex(oldItem => oldItem.breakdown_item === newItem.breakdown_item)

                // If the new item exists in the old data, update it
                // Otherwise, add it to the old data
                return index >= 0
                  ? update(updatedData, {
                      [index]: {
                        $merge: {
                          ...newItem,
                          // push new booked media to the previous
                          items: [...updatedData[index].items, ...newItem.items]
                        }
                      }
                    })
                  : [...updatedData, newItem]
              }, oldData)
            }
          },
          // remove loaded periods:
          ...(action.period && {
            loadingPeriods: {
              $apply: periods => periods.filter(period => period.startDate !== action.period.startDate)
            }
          })
        }
      })
    case GET_BOOKED_REVENUE_REPORT_FAILURE:
      return update(state, {
        getBookedRevenueReport: {
          $merge: {
            isLoading: false,
            error: action.error
          },
          // remove period from loading:
          ...(action.period && {
            loadingPeriods: {
              $apply: periods => periods.filter(period => period.startDate !== action.period.startDate)
            }
          })
        }
      })
    case CLEAR_GET_BOOKED_REVENUE_REPORT:
      return update(state, {
        getBookedRevenueReport: { $set: initialState.getBookedRevenueReport }
      })

    // Get installation booked media report
    case GET_INSTALLATION_BOOKED_MEDIA_REPORT:
      return update(state, {
        installationBookedMediaReport: { $merge: { isLoading: true } }
      })
    case GET_INSTALLATION_BOOKED_MEDIA_REPORT_SUCCESS:
      return update(state, {
        installationBookedMediaReport: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data
          }
        }
      })
    case GET_INSTALLATION_BOOKED_MEDIA_REPORT_FAILURE:
      return update(state, {
        installationBookedMediaReport: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_INSTALLATION_BOOKED_MEDIA_REPORT:
      return update(state, {
        installationBookedMediaReport: { $set: initialState.installationBookedMediaReport }
      })

    // get installation booked media report as csv
    case GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV:
      return update(state, {
        installationBookedMediaReportAsCSV: { $merge: { isLoading: true } }
      })
    case GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV_SUCCESS:
      return update(state, {
        installationBookedMediaReportAsCSV: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        }
      })
    case GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV_FAILURE:
      return update(state, {
        installationBookedMediaReportAsCSV: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV:
      return update(state, {
        installationBookedMediaReportAsCSV: { $set: initialState.installationBookedMediaReportAsCSV }
      })

    // Bulk update booking media file
    case BULK_UPDATE_BOOKING_MEDIA_FILE:
      return update(state, {
        bulkUpdateBookingMediaFile: {
          $merge: {
            isLoading: true,
            ids: action.data?.ids,
            sequentialIds: action.data?.sequentialIds,
            booked_media: action?.data?.booked_media
          }
        }
      })
    case BULK_UPDATE_BOOKING_MEDIA_FILE_SUCCESS:
      return update(state, {
        bulkUpdateBookingMediaFile: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            data: action.data
          }
        },
        installationBookedMediaReport: {
          results: {
            $apply: items => updateInstallationReportItemsFiles(items, action)
          }
        }
      })
    case BULK_UPDATE_BOOKING_MEDIA_FILE_FAILURE:
      return update(state, {
        bulkUpdateBookingMediaFile: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_BULK_UPDATE_BOOKING_MEDIA_FILE:
      return update(state, {
        bulkUpdateBookingMediaFile: { $set: initialState.bulkUpdateBookingMediaFile }
      })

    // Bulk update booked media
    case BULK_UPDATE_BOOKED_MEDIA:
      return update(state, {
        bulkUpdateBookedMedia: { $merge: { isLoading: true, ids: action.data.ids } }
      })
    case BULK_UPDATE_BOOKED_MEDIA_SUCCESS:
      return update(state, {
        bulkUpdateBookedMedia: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            data: action.data
          }
        },
        installationBookedMediaReport: {
          results: {
            $apply: items => {
              return items.map(item => {
                if (
                  action.data.ids.some(id => {
                    return item.sequential_ids?.includes(id)
                  })
                ) {
                  return {
                    ...item,
                    // update status of the item
                    status: action.data.status,
                    ...(item.sequential_list && {
                      // and update status of all sub items if exist
                      sequential_list: item.sequential_list.map(sequentialItem => {
                        return {
                          ...sequentialItem,
                          status: action.data.status
                        }
                      })
                    })
                  }
                } else {
                  return item
                }
              })
            }
          }
        }
      })
    case BULK_UPDATE_BOOKED_MEDIA_FAILURE:
      return update(state, {
        bulkUpdateBookedMedia: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_BULK_UPDATE_BOOKED_MEDIA:
      return update(state, {
        bulkUpdateBookedMedia: { $set: initialState.bulkUpdateBookedMedia }
      })

    // Get booked media
    case GET_BOOKED_MEDIA:
      return update(state, {
        getBookedMedia: { $merge: { isLoading: true } }
      })
    case GET_BOOKED_MEDIA_SUCCESS:
      return update(state, {
        getBookedMedia: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        }
      })
    case GET_BOOKED_MEDIA_FAILURE:
      return update(state, {
        getBookedMedia: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_BOOKED_MEDIA:
      return update(state, {
        getBookedMedia: { $set: initialState.getBookedMedia }
      })

    // Get brand revenue report
    case GET_BRAND_REVENUE_REPORT:
      return update(state, {
        brandRevenueReport: { $merge: { isLoading: true } }
      })
    case GET_BRAND_REVENUE_REPORT_SUCCESS:
      return update(state, {
        brandRevenueReport: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        }
      })
    case GET_BRAND_REVENUE_REPORT_FAILURE:
      return update(state, {
        brandRevenueReport: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_BRAND_REVENUE_REPORT:
      return update(state, {
        brandRevenueReport: { $set: initialState.brandRevenueReport }
      })

    default:
      return state
  }
}
