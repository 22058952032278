function initPush(arrayName, obj, toPush) {
  if (obj[arrayName] === undefined) {
    obj[arrayName] = []
  }
  obj[arrayName].push(toPush)
}

function multiInitPush(arrayName, obj, toPushArray) {
  let len
  len = toPushArray.length
  if (obj[arrayName] === undefined) {
    obj[arrayName] = []
  }
  while (len-- > 0) {
    obj[arrayName].push(toPushArray.shift())
  }
}

function flatToNested(flat, config = {}) {
  const {
    // The name of the property with the node id in the flat representation
    id: idPropertyName = 'id',
    // The name of the property with the parent node id in the flat representation
    parent: parentPropertyName = 'parent',
    // The name of the property that will hold the children nodes in the nested representation
    children: optionsPropertyName = 'options'
  } = config

  let i, len, temp, roots, id, parent, pendingChildOf, flatEl
  i = 0
  roots = []
  temp = {}
  pendingChildOf = {}

  for (i, len = flat.length; i < len; i++) {
    flatEl = { ...flat[i] }
    id = flatEl[idPropertyName]
    parent = flatEl[parentPropertyName]
    temp[id] = flatEl

    if (!parent) {
      // Current object has no parent, so it's a root element.
      roots.push(flatEl)
    } else {
      if (!!temp[parent]) {
        // Parent is already in temp, adding the current object to its children array.
        initPush(optionsPropertyName, temp[parent], flatEl)
      } else {
        // Parent for this object is not yet in temp, adding it to pendingChildOf.
        initPush(parent, pendingChildOf, flatEl)
      }
    }
    if (!!pendingChildOf[id]) {
      // Current object has children pending for it. Adding these to the object.
      multiInitPush(optionsPropertyName, flatEl, pendingChildOf[id])
    }
  }

  return roots
}

export function convertFlatToNestedArr(arr = [], sortFunction = (a, b) => a.id - b.id) {
  // before converting, sort array by id property, so it's in right order
  return flatToNested(arr.sort(sortFunction))
}

export function findNestedObj(entireObj, keyToFind, valToFind) {
  let foundObj

  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue
    }
    return nestedValue
  })

  return foundObj
}

export function reorderElement(arr, from, to) {
  const result = [...arr]

  const [removed] = result.splice(from, 1)
  result.splice(to, 0, removed)

  return result
}
