import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import FieldsSection from '../../../../../../features/components/Form/FieldsSection'
import Checkbox from '../../../../../../components/Form/Checkbox'

import {
  BOOKING_CONFIRMATION_EMAILS_BCC_PERMISSION,
  BOOKING_CONFIRMATION_EMAILS_CC_PERMISSION,
  FINANCE_ADMIN_PERMISSION,
  MODIFY_QUOTATION_PRICING,
  PACKAGE_APPROVE_PERMISSION
} from '../../../../../../constants/permissions'

const ControllerPermissionsEditFormContent = ({ formik }) => {
  const { t } = useTranslation()

  const { values, setFieldValue } = formik

  const checkboxHandler = useCallback(
    e => {
      const { name } = e.target
      setFieldValue(name, !values[name])
    },
    [values, setFieldValue]
  )

  return (
    <FieldsSection title="Permissions">
      <Checkbox
        id={BOOKING_CONFIRMATION_EMAILS_CC_PERMISSION}
        name={BOOKING_CONFIRMATION_EMAILS_CC_PERMISSION}
        checked={values[BOOKING_CONFIRMATION_EMAILS_CC_PERMISSION]}
        title={t('Booking confirmation emails (cc)')}
        onCheck={checkboxHandler}
      />
      <br />
      <Checkbox
        id={BOOKING_CONFIRMATION_EMAILS_BCC_PERMISSION}
        name={BOOKING_CONFIRMATION_EMAILS_BCC_PERMISSION}
        checked={values[BOOKING_CONFIRMATION_EMAILS_BCC_PERMISSION]}
        title={t('Booking confirmation emails (bcc)')}
        onCheck={checkboxHandler}
      />
      <br />
      <Checkbox
        id={MODIFY_QUOTATION_PRICING}
        name={MODIFY_QUOTATION_PRICING}
        checked={values[MODIFY_QUOTATION_PRICING]}
        title={t('Modify quotation pricing')}
        onCheck={checkboxHandler}
      />
      <br />
      <Checkbox
        id={PACKAGE_APPROVE_PERMISSION}
        name={PACKAGE_APPROVE_PERMISSION}
        checked={values[PACKAGE_APPROVE_PERMISSION]}
        title={t('Media Package Approver')}
        onCheck={checkboxHandler}
      />
      <br />
      <Checkbox
        id={FINANCE_ADMIN_PERMISSION}
        name={FINANCE_ADMIN_PERMISSION}
        checked={values[FINANCE_ADMIN_PERMISSION]}
        title={t('Finance Admin')}
        onCheck={checkboxHandler}
      />
    </FieldsSection>
  )
}

export default ControllerPermissionsEditFormContent
