import update from 'immutability-helper'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  OPEN_FORM,
  CLOSE_FORM,
  SET_FORM_STEP,
  SET_FORM_STEP_NEXT,
  SET_FORM_STEP_PREVIOUS,
  SET_FORM_SUCCESS_SUBMIT,
  CLEAR_FORM_SUCCESS_SUBMIT,
  SET_FORM_MINIMIZE,
  SET_FORM_SUBMITTING
} from '../actions/forms'

import { getParameterFromUrl } from '../../helpers/url'

import { URL_FORM_PARAMS } from '../../constants/forms'

const initialOpenDrawerState = {}
const queryParamsFormName = getParameterFromUrl(URL_FORM_PARAMS.FORM_NAME)
const getInitialOpenedDrawers = () => {
  // if user try to access page by URL and pass form query param in link,
  // then we pre-open drawer, if it is match current form
  return !!queryParamsFormName ? { [queryParamsFormName]: true } : initialOpenDrawerState
}

const initialFormItemId = {}
const getFormItemId = () => {
  // if user try to access page by URL and pass form query param in link,
  // then we pre-open drawer, if it is match current form
  const queryParamsEditId = getParameterFromUrl(URL_FORM_PARAMS.ITEM_ID)
  return !!queryParamsFormName && !!queryParamsEditId ? { [queryParamsFormName]: queryParamsEditId } : initialFormItemId
}

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  formActiveStep: 0,
  formPrevActiveStep: null,
  formsOpenStates: getInitialOpenedDrawers(),
  formDrawerMinimize: false,
  formItemId: getFormItemId(),
  formSuccessfullySubmitted: {},
  formIsSubmitting: {}
}

export default function forms(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    case OPEN_FORM:
      return update(state, {
        formsOpenStates: {
          $merge: {
            [action.formName]: true
          }
        },
        ...(action.id && {
          formItemId: {
            $merge: {
              [action.formName]: action.id
            }
          }
        })
      })
    case CLOSE_FORM:
      return update(state, {
        formsOpenStates: { $set: initialOpenDrawerState },
        formItemId: { $set: initialFormItemId },
        formPrevActiveStep: { $set: null }
      })
    case SET_FORM_MINIMIZE:
      return update(state, {
        formDrawerMinimize: { $set: action.isMinimized }
      })
    case SET_FORM_STEP:
      return update(state, {
        formActiveStep: { $set: action.newStep }
      })
    case SET_FORM_STEP_NEXT:
      return update(state, {
        formActiveStep: { $set: state.formActiveStep + 1 },
        formPrevActiveStep: { $set: state.formActiveStep }
      })
    case SET_FORM_STEP_PREVIOUS:
      return update(state, {
        formActiveStep: { $set: state.formActiveStep - 1 },
        formPrevActiveStep: { $set: state.formActiveStep }
      })
    case SET_FORM_SUBMITTING:
      return update(state, {
        formIsSubmitting: { $set: { [action.formName]: action.isSubmitting } }
      })
    case SET_FORM_SUCCESS_SUBMIT:
      return update(state, {
        formSuccessfullySubmitted: { $set: { [action.formName]: true } }
      })
    case CLEAR_FORM_SUCCESS_SUBMIT:
      return update(state, {
        formSuccessfullySubmitted: { $set: initialState.formSuccessfullySubmitted }
      })
    default:
      return state
  }
}
