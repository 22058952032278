import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import ContentSection from '../../../features/components/ContentSection'
import ContentRow from '../../../features/components/ContentSection/ContentRow'
import BrandPagesTable from './BrandPagesTable'
import BrandPageCreate from './BrandPagesForms/BrandPageCreate'
import BrandPageEdit from './BrandPagesForms/BrandPagesEdit'

import { selectedControllerIdSelector, selectedSelfAccountSelector } from '../../../modules/selectors/app'
import { clearCategoryPages, getCategoryPages } from '../../../modules/actions/mediaOrders'

import useSettingsClasses from '../styles'

function BrandPages() {
  const settingsClasses = useSettingsClasses()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const selectedSelfAccountId = useSelector(selectedSelfAccountSelector)
  const selectedSelfAccountControllerId = useSelector(selectedControllerIdSelector)

  useEffect(() => {
    dispatch(
      getCategoryPages({
        account: selectedSelfAccountId,
        controller: selectedSelfAccountControllerId
      })
    )
  }, [dispatch, selectedSelfAccountControllerId, selectedSelfAccountId])

  useEffect(() => {
    return () => {
      dispatch(clearCategoryPages())
    }
  }, [dispatch])

  return (
    <>
      <h1 className={settingsClasses.title}>{t('Brand Pages')}</h1>
      <ContentSection title={t('Pages for Click Through Links')}>
        <ContentRow
          title={t('Brand pages')}
          description={t(
            'Pages listed here will be available for the advertiser to use as a click through link on their ads in cases where their account does not allow custom click through links.'
          )}
          leftColumnChildren={<BrandPageCreate />}
        >
          <BrandPagesTable />
        </ContentRow>
      </ContentSection>
      <BrandPageEdit />
    </>
  )
}

export default BrandPages
