import React, { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'react-jss'
import PropTypes from 'prop-types'
import { format } from 'date-fns'

import PageSection from '../../../../../../../../features/components/PageSection'

import { useRevenueGraphOptions } from '../hooks'

import useStyles from '../styles'

const BookedRevenueByMonthGraph = ({ monthRevenueReportData = [] }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()

  const data = useMemo(() => {
    return monthRevenueReportData.map(item => item.items?.[0]?.total_revenue || 0)
  }, [monthRevenueReportData])

  const labels = useMemo(() => {
    return monthRevenueReportData.map(item => {
      // format date to this format Aug ‘24
      return format(new Date(item.breakdown_item), 'MMM ‘yy')
    })
  }, [monthRevenueReportData])

  const graphOptions = useRevenueGraphOptions()

  const graphData = {
    labels,
    datasets: [
      {
        data: data,
        backgroundColor: theme.brandPrimary,
        borderColor: theme.brandPrimary,
        borderRadius: {
          topLeft: 2,
          topRight: 2
        },
        borderSkipped: false,
        maxBarThickness: 30
      }
    ]
  }

  return (
    <PageSection className={classes.sectionContainer}>
      <h5 className={classes.graphTitle}>{t('Booked Revenue by Month')}</h5>
      <div className={classes.graphWrapper}>
        <Bar type="bar" data={graphData} options={graphOptions} />
      </div>
    </PageSection>
  )
}

BookedRevenueByMonthGraph.propTypes = {
  monthRevenueReportData: PropTypes.array.isRequired
}

export default BookedRevenueByMonthGraph
