import { createStyles } from '../../../../../styles/helpers'
import { phonesDown } from '../../../../../styles/const/breakpoints'

const useStyles = createStyles({
  stepTitleWithFilter: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 8
  },
  filterWrapper: {
    marginBottom: 8
  },
  [`@media screen and (${phonesDown})`]: {
    stepTitleWithFilter: {
      flexDirection: 'column-reverse',
      gap: 8
    }
  }
})

export default useStyles
