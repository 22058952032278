import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { lineItemSelector } from '../../../../../../../modules/selectors/lineItems'

const AgePreview = () => {
  const { t } = useTranslation()

  const { age } = useSelector(lineItemSelector)

  return age ? <span>{`${age.age_min}-${age.age_max} ${t('year olds')}`}</span> : null
}

export default AgePreview
