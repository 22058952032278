import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getPackageFlow } from './MediaOrderCreateForm/ContractCreateForm/helpers'

import { getProductsService } from '../../../../modules/services/mediaOrdersProducts'
import { addSelectedMediaPackage } from '../../../../modules/actions/mediaOrders'
import { clearGetMediaPackage } from '../../../../modules/actions/mediaPackages'
import {
  getMediaPackageIsLoadingSelector,
  mediaPackageSelector,
  mediaPackagesWasLoadedSelector
} from '../../../../modules/selectors/mediaPackages'
import { FIXED_PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE } from './MediaOrderCreateForm/ContractCreateForm/fields'

const useHandleNewPackageSelection = () => {
  const dispatch = useDispatch()

  const [isProductsDataLoad, setIsProductsDataLoad] = useState(false)

  const fetchedMediaPackage = useSelector(mediaPackageSelector)
  const packagesWasLoaded = useSelector(mediaPackagesWasLoadedSelector)
  const mediaPackageIsLoading = useSelector(getMediaPackageIsLoadingSelector)

  useEffect(() => {
    // add recently added media package after data download to selectedMediaPackages
    if (fetchedMediaPackage?.id) {
      const packageFlow = getPackageFlow(fetchedMediaPackage)
      if (packageFlow === FIXED_PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE) {
        const productsIds = fetchedMediaPackage.media_products.map(product => product.id)?.join(',')

        // call is made through the service directly to avoid managing store,
        // as well as create additional hooks and effects to check when new data is loaded and merge with existing
        setIsProductsDataLoad(true)
        getProductsService({ id: productsIds })
          .then(response => {
            const mediaProducts = response.results
            dispatch(
              addSelectedMediaPackage({
                ...fetchedMediaPackage,
                media_products: mediaProducts
              })
            )
            setIsProductsDataLoad(false)
          })
          .catch(error => {
            setIsProductsDataLoad(false)
            console.error(error)
          })
      } else {
        dispatch(addSelectedMediaPackage(fetchedMediaPackage))
      }

      // after adding fetchedMediaPackage to selectedMediaPackages, clear the fetched media package data from store
      dispatch(clearGetMediaPackage())
    }
  }, [dispatch, fetchedMediaPackage])

  return useMemo(
    () => ({
      isPackageDataLoading: !packagesWasLoaded || mediaPackageIsLoading || isProductsDataLoad
    }),
    [isProductsDataLoad, mediaPackageIsLoading, packagesWasLoaded]
  )
}

export default useHandleNewPackageSelection
