import React from 'react'

import BudgetPreview from './Previews/BudgetPreview'
import BidPricePreview from './Previews/BidPricePreview'

const BudgetSectionPreview = () => {
  return (
    <p>
      <BudgetPreview />
      <BidPricePreview />
    </p>
  )
}

export default BudgetSectionPreview
