import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import FieldsSection from '../../../../../../../../features/components/Form/FieldsSection'
import Step from '../../../../../../../../features/components/Forms/StepForm/Step'
import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import StartEndDateRangeFields from '../../../../../../../../forms/ReusableFormFields/StartEndDateRangeFields'

import { usePurifiedFormik } from '../../../../../../../../hooks/formHooks/usePurifiedFormik'

import { END_DATE, START_DATE } from '../../../../../../../../forms/ReusableFormFields/StartEndDateRangeFields/fields'

const stepFields = [END_DATE, START_DATE]

function CampaignDatesStep({ formik, handleStepChange }) {
  const { t } = useTranslation()

  const purifiedFormik = usePurifiedFormik(formik)

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  return (
    <Step
      stepTitle={t('Set the campaign dates')}
      stepDescription={t('Please enter the start and end dates for the campaign')}
      handleContinue={handleContinue}
    >
      <FieldsSection title="Campaign period">
        <FieldRow title="Campaigns dates" description="Start and end date">
          <StartEndDateRangeFields
            formik={purifiedFormik}
            startDateName={START_DATE}
            endDateName={END_DATE}
            minDate={new Date()}
          />
        </FieldRow>
      </FieldsSection>
    </Step>
  )
}

export default CampaignDatesStep
