import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { clearMediaOrderUploadCreative } from '../../../modules/actions/mediaOrders'

import UploadDv360AdCreativeModal from './UploadDv360AdCreativeModal'
import SocialAuthChecker from '../../../features/components/SocialAuthChecker'
import UploadDv360AdCreativeContent, { CAMPAIGN_FORM } from './UploadDV360AdCreativeContent'
import FormDrawerWrapper from '../../../features/components/FormDrawerWrapper'
import SkeletonForm from '../../../components/Form/Skeleton'

import useUploadAdCreativeConstants from '../../Multiplatform/MediaOrderForms/UploadAdCreative/useUploadAdCreativeConstants'
import useStepFormRoutes from '../../../hooks/formHooks/useStepFormRoutes'

import { useGetAuthFailureText } from '../../Multiplatform/UploadAdCreative/hooks'

import { DV_360_PLATFORM } from '../../../constants/selectLists/platformList'

import { UPLOAD_DV360_AD_CREATIVE } from '../../../constants/forms'

export function UploadDv360AdCreative() {
  const dispatch = useDispatch()

  const [createdCreativeData, setCreatedCreativeData] = useState({})

  const manageStepFormRoutes = useStepFormRoutes({ initialRoute: CAMPAIGN_FORM })
  const authFailureText = useGetAuthFailureText(DV_360_PLATFORM)

  const { isFormLoading } = useUploadAdCreativeConstants()

  const { handleFormClose } = manageStepFormRoutes

  const onAfterFormCloseHandler = useCallback(() => {
    // reset routes state
    handleFormClose()

    dispatch(clearMediaOrderUploadCreative())
  }, [dispatch, handleFormClose])

  const Modals = useMemo(() => {
    return (
      <UploadDv360AdCreativeModal
        setCreatedCreativeData={setCreatedCreativeData}
        createdCreativeData={createdCreativeData}
      />
    )
  }, [createdCreativeData])

  return (
    <>
      <FormDrawerWrapper
        formName={UPLOAD_DV360_AD_CREATIVE}
        closeOnSubmit={false}
        showOpenButton={false}
        isFormLoading={isFormLoading}
        SkeletonFormComponent={<SkeletonForm stepsLength={4} />}
        onAfterFormClose={onAfterFormCloseHandler}
        hasDefaultDrawerContentSpacing={false}
        isWideDrawer
      >
        <SocialAuthChecker platform={DV_360_PLATFORM} authFailureText={authFailureText}>
          <UploadDv360AdCreativeContent
            setCreatedCreativeData={setCreatedCreativeData}
            createdCreativeData={createdCreativeData}
            manageStepFormRoutes={manageStepFormRoutes}
          />
        </SocialAuthChecker>
      </FormDrawerWrapper>
      {Modals}
    </>
  )
}
