import PlayIcon2 from '../assets/icons/play-icon2.png'

export const cloneElementForPDF = (elementSelector, newElementId) => {
  const element = document.querySelector(elementSelector)

  if (!element) {
    throw new Error('Element is missing')
  }

  const elementClone = element.cloneNode(true)
  elementClone.id = newElementId

  const wrapper = document.createElement('div')
  wrapper.className = 'temporary-pdf-wrapper'
  // move element out of the visible screen
  wrapper.style.position = 'absolute'
  wrapper.style.width = '1300px'
  wrapper.style.left = `-10000px`

  wrapper.appendChild(elementClone)
  document.body.appendChild(wrapper)

  return document.getElementById(newElementId)
}

export const cleanUpTemporaryPDFElements = () => {
  // Remove temporary wrappers which were created for modifying DOM and taking canvas screenshots
  const wrappers = document.querySelectorAll('.temporary-pdf-wrapper')
  wrappers.forEach(wrapper => wrapper.parentNode.removeChild(wrapper))
}

// html2canvas doesn't support video elements and just crashes if the video exists
// for this reason video elements are replacing with image placeholders
export const processVideos = (newUl, brandColor) => {
  const videos = newUl.querySelectorAll('video')

  // Create the play icon image element outside the loop
  const playIconImage = document.createElement('img')
  playIconImage.src = PlayIcon2
  playIconImage.style.position = 'absolute'
  playIconImage.style.opacity = '0.8'
  playIconImage.style.top = '50%'
  playIconImage.style.left = '50%'
  playIconImage.style.transform = 'translate(-50%, -50%)'
  playIconImage.style.width = 50 + 'px'
  playIconImage.style.height = 50 + 'px'

  // Iterate through each video element and replace it with an image placeholder
  videos.forEach(video => {
    const placeholder = document.createElement('div')

    // make placeholder square which takes full width with brand color background and centered play icon
    placeholder.style.width = 100 + '%'
    placeholder.style.position = 'relative'
    placeholder.style.backgroundColor = brandColor
    placeholder.style.paddingBottom = 'calc(100% - 50px)'
    placeholder.style.display = 'flex'
    placeholder.style.alignItems = 'center'
    placeholder.style.justifyContent = 'center'

    // Clone the play icon image element and append it to the placeholder
    const image = playIconImage.cloneNode(true)
    placeholder.appendChild(image)

    // Replace the video element with the placeholder
    video.parentNode.replaceChild(placeholder, video)
  })
}
