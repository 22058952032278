import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import AppLoader from '../../../components/Loaders/AppLoader'
import Form from '../../../components/Form'
import Field from '../../../components/Form/Field'
import UnauthorizedPage from '../UnauthorizedPage'
import InfoBlock from '../../../features/components/InfoBlock'
import InternalLink from '../../../components/InternalLink'

import { useAuthButtonProps } from '../useAuthButtonProps'

import { createPassword } from '../../../modules/actions/cognito'
import { signInService } from '../../../modules/services/cognito'
import {
  createPasswordSelector,
  createPasswordErrorSelector,
  cognitoLoadingSelector
} from '../../../modules/selectors/cognito'

import history from '../../../history'
import { ROUTES } from '../../../constants/routes'
import { createJsonFromQueryString } from '../../../helpers/url'

import { initialValues, PASSWORD, validationSchema } from './fields'

import useAuthStyles from '../../../styles/common/authPages'

// the page is to handle the first login flow when user land to the page from temporary email link
function CreatePassword() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const authClasses = useAuthStyles()

  const createdPassword = useSelector(createPasswordSelector)

  const buttonProps = useAuthButtonProps()

  const [user, setUser] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const queryParams = history.location.search

  const onSubmit = useCallback(
    values => {
      dispatch(
        createPassword({
          user, // the Cognito User Object
          password: values[PASSWORD] // the new password
        })
      )
    },
    [dispatch, user]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  useEffect(() => {
    // take parameters from link and send to AWS for login
    const queryParameters = createJsonFromQueryString(queryParams, false)
    signInService({
      email: queryParameters.cl,
      password: queryParameters.co
    })
      .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setIsLoading(false)
          setUser(user)
        }
      })
      .catch(() => setIsLoading(false))
  }, [queryParams])

  const showForm = user.challengeName === 'NEW_PASSWORD_REQUIRED'

  return (
    <UnauthorizedPage helmetTitle="helmetTitle.CreatePasswordPage" className={authClasses.authPage}>
      {isLoading ? (
        <AppLoader />
      ) : showForm ? (
        <>
          <InfoBlock title={t('CreatePasswordPage title')} hideIcon />
          <div className={authClasses.formContainer}>
            <Form
              formName="createPassword"
              formik={formik}
              method="post"
              errorSelector={createPasswordErrorSelector}
              isLoadingSelector={cognitoLoadingSelector}
              successSubmit={createdPassword.Session}
              submitText="Create Password"
              buttonProps={buttonProps}
            >
              <Field formik={formik} type="password" id="password" name="password" placeholder="New Password" />
            </Form>
          </div>
        </>
      ) : (
        <InfoBlock title="This link is expired." hideIcon>
          <div>
            Please <InternalLink to={ROUTES.resetPassword}>{t('reset your password')}</InternalLink>.
          </div>
        </InfoBlock>
      )}
    </UnauthorizedPage>
  )
}

export default CreatePassword
