import { ADMIN, MANAGER, REPORT } from '../../../../../constants/permissions'

export const AGENCY = 'agency'
export const USER = 'user'
export const EMAIL = 'email'
export const ADMIN_CHOICE_OPTION = 'admin_choice_option'
export const ADMIN_CHOICE_ADD_AGENCY_MEMBER = 'admin_choice_add_agency_member'
export const ADMIN_CHOICE_ADD_USER_BY_EMAIL = 'admin_choice_add_user_by_email'
export const ROLE = 'role'

export const roleOptions = [
  { label: 'Admin', value: ADMIN },
  { label: 'Manager', value: MANAGER },
  { label: 'Reporting', value: REPORT }
]

export const initialValues = {
  [ADMIN_CHOICE_OPTION]: ADMIN_CHOICE_ADD_AGENCY_MEMBER,
  [AGENCY]: '',
  [USER]: '',
  [EMAIL]: '',
  [ROLE]: MANAGER
}
