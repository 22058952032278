import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import useStyles from './styles'

const ChangeCell = ({ data }) => {
  const classes = useStyles()

  const { budget, new_budget: newBudget } = data
  const isIncrease = Number(newBudget) > Number(budget)

  const diff = newBudget - budget
  const formattedDiff = diff && Math.abs(diff)

  return (
    <div className={classnames(classes.change, { increase: isIncrease })}>
      {isIncrease ? '+' : '-'}
      {formattedDiff}
    </div>
  )
}

ChangeCell.propTypes = {
  data: PropTypes.shape({
    budget: PropTypes.number.isRequired,
    new_budget: PropTypes.number.isRequired
  }).isRequired
}

export default ChangeCell
