import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import classnames from 'classnames'

import OpenAILogo from '../../../../assets/logos/openAI-logo.png'

import TypeWriteAnimation from '../../TypeWriteAnimation'

import useStyles from './styles'

const TextSuggestions = ({
  title,
  suggestions = [],
  suggestionsIsLoading,
  onSelectSuggestion,
  selectedSuggestion,
  logoSrc,
  showLogo = true,
  typingAnimation
}) => {
  const classes = useStyles()

  const isSuggestionsEmpty = !suggestionsIsLoading && !suggestions.length

  const onSuggestionClick = value => {
    onSelectSuggestion && onSelectSuggestion(value)
  }

  if (isSuggestionsEmpty) {
    return null
  }

  return (
    <div className={classes.container}>
      {showLogo && <img src={logoSrc || OpenAILogo} alt="logo" className={classes.logo} />}
      {title && <h4 className={classes.title}>{title}</h4>}
      <div className={classes.suggestionsContainer}>
        {suggestionsIsLoading ? (
          <>
            {Array(5)
              .fill(Math.random())
              .map((item, index) => (
                <p className={classes.suggestion} key={index}>
                  <Skeleton style={{ width: '95%' }} />
                </p>
              ))}
          </>
        ) : (
          suggestions.map((suggestion, index) => {
            const isLastItem = index === suggestions.length - 1

            return (
              <p key={index} onClick={() => onSuggestionClick(suggestion)} className={classes.suggestion}>
                <span
                  className={classnames(classes.suggestionText, {
                    [classes.selectedSuggestion]: selectedSuggestion === suggestion
                  })}
                >
                  {isLastItem && typingAnimation ? <TypeWriteAnimation text={suggestion} /> : suggestion}
                </span>
              </p>
            )
          })
        )}
      </div>
    </div>
  )
}

TextSuggestions.propTypes = {
  title: PropTypes.string,
  suggestions: PropTypes.array.isRequired,
  suggestionsIsLoading: PropTypes.bool,
  onSelectSuggestion: PropTypes.func.isRequired,
  logoSrc: PropTypes.string,
  showLogo: PropTypes.bool,
  typingAnimation: PropTypes.bool
}

export default TextSuggestions
