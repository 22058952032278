import update from 'immutability-helper'
import { deleteItemById, updateItem } from '../../helpers/modules/reducerHelpers'

import {
  INITIAL_CREATE_REDUCER,
  INITIAL_DELETE_REDUCER,
  INITIAL_GET_REDUCER,
  INITIAL_GET_RESULTS_PAGING_REDUCER,
  INITIAL_GET_RESULTS_REDUCER,
  INITIAL_LOADING_REDUCER,
  INITIAL_UPDATE_REDUCER
} from '../../constants/reducer'
import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_CONTROLLER_MEMBER,
  GET_CONTROLLER_MEMBER_SUCCESS,
  GET_CONTROLLER_MEMBER_FAILURE,
  CLEAR_GET_CONTROLLER_MEMBER,
  GET_CONTROLLER,
  GET_CONTROLLER_SUCCESS,
  GET_CONTROLLER_FAILURE,
  CLEAR_GET_CONTROLLER,
  ACCEPT_CONTROLLER_INVITE,
  ACCEPT_CONTROLLER_INVITE_FAILURE,
  ACCEPT_CONTROLLER_INVITE_SUCCESS,
  CLEAR_ACCEPT_CONTROLLER_INVITE,
  CLEAR_CONTROLLER_INVITES,
  CLEAR_CONTROLLER_MEMBERS,
  CLEAR_CONTROLLERS,
  CLEAR_CREATE_CONTROLLER_INVITE,
  CLEAR_UPDATE_CONTROLLER,
  CLEAR_UPDATE_CONTROLLER_MEMBER,
  CREATE_CONTROLLER_INVITE,
  CREATE_CONTROLLER_INVITE_FAILURE,
  CREATE_CONTROLLER_INVITE_SUCCESS,
  DELETE_CONTROLLER_INVITE,
  DELETE_CONTROLLER_INVITE_FAILURE,
  DELETE_CONTROLLER_INVITE_SUCCESS,
  GET_CONTROLLER_INVITES,
  GET_CONTROLLER_INVITES_FAILURE,
  GET_CONTROLLER_INVITES_SUCCESS,
  GET_CONTROLLER_MEMBERS,
  GET_CONTROLLER_MEMBERS_FAILURE,
  GET_CONTROLLER_MEMBERS_SUCCESS,
  GET_CONTROLLERS,
  GET_CONTROLLERS_FAILURE,
  GET_CONTROLLERS_SUCCESS,
  RESEND_CONTROLLER_INVITE,
  RESEND_CONTROLLER_INVITE_FAILURE,
  RESEND_CONTROLLER_INVITE_SUCCESS,
  UPDATE_CONTROLLER,
  UPDATE_CONTROLLER_FAILURE,
  UPDATE_CONTROLLER_MEMBER,
  UPDATE_CONTROLLER_MEMBER_FAILURE,
  UPDATE_CONTROLLER_MEMBER_SUCCESS,
  UPDATE_CONTROLLER_MEMBER_STATUS,
  UPDATE_CONTROLLER_MEMBER_STATUS_FAILURE,
  UPDATE_CONTROLLER_MEMBER_STATUS_SUCCESS,
  UPDATE_CONTROLLER_SUCCESS
} from '../actions/controller'

import { MEMBER_CHANGE_DATA, MEMBER_CHANGE_STATUS } from '../../constants/forms'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  controllers: INITIAL_GET_RESULTS_REDUCER,
  controllerMembers: {
    members: INITIAL_GET_RESULTS_PAGING_REDUCER
  },
  updateControllerMember: INITIAL_UPDATE_REDUCER,
  controllerInvites: {
    invites: INITIAL_GET_RESULTS_PAGING_REDUCER,
    createdInvite: INITIAL_CREATE_REDUCER,
    acceptedInvite: {
      ...INITIAL_LOADING_REDUCER,
      wasAccepted: false
    },
    deletedInvite: INITIAL_DELETE_REDUCER,
    reinvitedUser: {
      wasSent: false,
      id: null,
      error: null
    }
  },
  updateController: INITIAL_UPDATE_REDUCER,
  controller: INITIAL_GET_REDUCER,
  controllerMember: INITIAL_GET_REDUCER
}

export default function controller(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // get controllers
    case GET_CONTROLLERS:
      return update(state, {
        controllers: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_CONTROLLERS_SUCCESS:
      return update(state, {
        controllers: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.controllersData.results
          }
        }
      })
    case GET_CONTROLLERS_FAILURE:
      return update(state, {
        controllers: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CONTROLLERS:
      return update(state, {
        controllers: { $set: initialState.controllers }
      })

    // get controller members
    case GET_CONTROLLER_MEMBERS:
      return update(state, {
        controllerMembers: {
          members: {
            $merge: {
              ...(action.loadOptions.shouldClearExistingState && INITIAL_GET_RESULTS_PAGING_REDUCER),
              isLoading: true
            }
          }
        }
      })
    case GET_CONTROLLER_MEMBERS_SUCCESS:
      return update(state, {
        controllerMembers: {
          members: {
            $merge: {
              isLoading: false,
              wasLoaded: true,
              paging: {
                next: action.controllerMembersData?.next
              }
            },
            results: {
              $push: action.controllerMembersData.results
            }
          }
        }
      })
    case GET_CONTROLLER_MEMBERS_FAILURE:
      return update(state, {
        controllerMembers: {
          members: {
            $merge: {
              isLoading: false,
              error: action.error
            }
          }
        }
      })
    case CLEAR_CONTROLLER_MEMBERS:
      return update(state, {
        controllerMembers: { $set: initialState.controllerMembers }
      })

    // get controller invites
    case GET_CONTROLLER_INVITES:
      return update(state, {
        controllerInvites: {
          invites: {
            $merge: {
              ...(action.loadOptions.shouldClearExistingState && INITIAL_GET_RESULTS_PAGING_REDUCER),
              isLoading: true
            }
          }
        }
      })
    case GET_CONTROLLER_INVITES_SUCCESS:
      return update(state, {
        controllerInvites: {
          invites: {
            $merge: {
              isLoading: false,
              wasLoaded: true,
              paging: {
                next: action.invitesData?.next
              }
            },
            results: {
              $push: action.invitesData.results
            }
          }
        }
      })
    case GET_CONTROLLER_INVITES_FAILURE:
      return update(state, {
        controllerInvites: {
          invites: {
            $merge: {
              isLoading: false,
              error: action.error
            }
          }
        }
      })
    case CLEAR_CONTROLLER_INVITES:
      return update(state, {
        controllerInvites: { $set: initialState.controllerInvites }
      })

    // create controller invite
    case CREATE_CONTROLLER_INVITE:
      return update(state, {
        controllerInvites: {
          createdInvite: { $set: { isLoading: true } }
        }
      })
    case CREATE_CONTROLLER_INVITE_SUCCESS:
      return update(state, {
        controllerInvites: {
          createdInvite: { $set: { ...action.inviteData, wasCreated: true } },
          invites: {
            results: {
              $push: [action.inviteData]
            }
          }
        }
      })
    case CREATE_CONTROLLER_INVITE_FAILURE:
      return update(state, {
        controllerInvites: {
          createdInvite: { $set: { error: action.error } }
        }
      })
    case CLEAR_CREATE_CONTROLLER_INVITE:
      return update(state, {
        controllerInvites: {
          createdInvite: {
            $set: initialState.controllerInvites.createdInvite
          }
        }
      })

    // accept controller invite
    case ACCEPT_CONTROLLER_INVITE:
      return update(state, {
        controllerInvites: {
          acceptedInvite: {
            $merge: {
              isLoading: true
            }
          }
        }
      })
    case ACCEPT_CONTROLLER_INVITE_SUCCESS:
      return update(state, {
        controllerInvites: {
          acceptedInvite: {
            $merge: {
              isLoading: false,
              wasAccepted: true
            }
          }
        }
      })
    case ACCEPT_CONTROLLER_INVITE_FAILURE:
      return update(state, {
        controllerInvites: {
          acceptedInvite: {
            $merge: {
              isLoading: true,
              error: action.error
            }
          }
        }
      })
    case CLEAR_ACCEPT_CONTROLLER_INVITE:
      return update(state, {
        controllerInvites: {
          acceptedInvite: {
            $set: initialState.controllerInvites.acceptedInvite
          }
        }
      })

    // update controller member
    case UPDATE_CONTROLLER_MEMBER:
      return update(state, {
        updateControllerMember: {
          $set: {
            isLoading: true,
            id: action.id,
            updatingType: MEMBER_CHANGE_DATA
          }
        }
      })
    case UPDATE_CONTROLLER_MEMBER_STATUS:
      return update(state, {
        updateControllerMember: {
          $set: {
            isLoading: true,
            id: action.id,
            updatingType: MEMBER_CHANGE_STATUS
          }
        }
      })
    case UPDATE_CONTROLLER_MEMBER_SUCCESS:
    case UPDATE_CONTROLLER_MEMBER_STATUS_SUCCESS:
      return update(state, {
        updateControllerMember: {
          $merge: {
            isLoading: false,
            wasUpdated: true
          }
        },
        controllerMembers: {
          members: {
            results: {
              $apply: items => updateItem(items, action.updatedMemberData)
            }
          }
        }
      })
    case UPDATE_CONTROLLER_MEMBER_FAILURE:
    case UPDATE_CONTROLLER_MEMBER_STATUS_FAILURE:
      return update(state, {
        updateControllerMember: {
          $merge: {
            isLoading: false,
            wasUpdated: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_CONTROLLER_MEMBER:
      return update(state, {
        updateControllerMember: {
          $set: initialState.updateControllerMember
        }
      })

    // delete controller invite
    case DELETE_CONTROLLER_INVITE:
      return update(state, {
        controllerInvites: {
          deletedInvite: { $set: { isLoading: true, id: action.id } }
        }
      })
    case DELETE_CONTROLLER_INVITE_SUCCESS:
      return update(state, {
        controllerInvites: {
          invites: {
            results: {
              $apply: items => deleteItemById(items, action.id)
            }
          },
          deletedInvite: { $merge: { isLoading: false } }
        }
      })
    case DELETE_CONTROLLER_INVITE_FAILURE:
      return update(state, {
        controllerInvites: {
          deletedInvite: { $merge: { isLoading: false, error: action.error } }
        }
      })

    // resend controller invite
    case RESEND_CONTROLLER_INVITE:
      return update(state, {
        controllerInvites: {
          reinvitedUser: { $set: { isLoading: true, id: action.id } }
        }
      })
    case RESEND_CONTROLLER_INVITE_SUCCESS:
      return update(state, {
        controllerInvites: {
          reinvitedUser: { $merge: { isLoading: false, wasSent: true } }
        }
      })
    case RESEND_CONTROLLER_INVITE_FAILURE:
      return update(state, {
        controllerInvites: {
          reinvitedUser: { $merge: { isLoading: false, wasSent: false, error: action.error } }
        }
      })

    // update controller
    case UPDATE_CONTROLLER:
      return update(state, {
        updateController: {
          $merge: {
            isLoading: true
          }
        }
      })
    case UPDATE_CONTROLLER_SUCCESS:
      return update(state, {
        updateController: {
          $merge: {
            isLoading: false,
            wasUpdated: true
          }
        },
        controllers: {
          results: {
            $apply: items => {
              return updateItem(items, action.controllerData)
            }
          }
        }
      })
    case UPDATE_CONTROLLER_FAILURE:
      return update(state, {
        updateController: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_CONTROLLER:
      return update(state, {
        updateController: {
          $set: initialState.updateController
        }
      })

    // Get controller
    case GET_CONTROLLER:
      return update(state, {
        controller: { $merge: { isLoading: true } }
      })
    case GET_CONTROLLER_SUCCESS:
      return update(state, {
        controller: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        }
      })
    case GET_CONTROLLER_FAILURE:
      return update(state, {
        controller: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_CONTROLLER:
      return update(state, {
        controller: { $set: initialState.controller }
      })

    // Get controller member
    case GET_CONTROLLER_MEMBER:
      return update(state, {
        controllerMember: { $merge: { isLoading: true } }
      })
    case GET_CONTROLLER_MEMBER_SUCCESS:
      return update(state, {
        controllerMember: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        }
      })
    case GET_CONTROLLER_MEMBER_FAILURE:
      return update(state, {
        controllerMember: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_CONTROLLER_MEMBER:
      return update(state, {
        controllerMember: { $set: initialState.controllerMember }
      })

    default:
      return state
  }
}
