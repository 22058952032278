import React from 'react'

import MediaProductVariableCreateForm from './MediaProductVariableCreateForm'
import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'

import { MEDIA_PRODUCT_VARIABLE_CREATE } from '../../../../constants/forms'

function MediaProductVariableCreate() {
  return (
    <FormDrawerWrapper
      formName={MEDIA_PRODUCT_VARIABLE_CREATE}
      title="Product Variables"
      openButtonText="Add new variable"
    >
      <MediaProductVariableCreateForm />
    </FormDrawerWrapper>
  )
}

export default MediaProductVariableCreate
