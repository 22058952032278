import React, { useCallback, useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import HeaderCell from './HeaderCell'
import SortableCell from './SortableCell'

import { TABLE_ROW_CLASSNAME } from '../../../constants/pdf'
import { ROW_WITH_EXPAND_BUTTON_SPACING, SKIP_CELL_FOR_COPY } from '../constants'

import { phonesDownSize } from '../../../styles/const/breakpoints'

import useStyles from '../styles'

const TableHeader = React.memo(
  ({ cols, rowTemplate, tableSort, hasMobileHiddenColumns, hasExpandableRow, isBigRow }) => {
    const isMobile = useMediaQuery({ maxWidth: phonesDownSize })
    const classes = useStyles({ isBigRow, isMobile, hasMobileHiddenColumns })

    const colsTemplate = useMemo(() => {
      return cols || rowTemplate.cols
    }, [cols, rowTemplate])

    const HeadLineColumns = useCallback(() => {
      // if isMobile do filter for showing only mobile headers
      return (isMobile ? colsTemplate.filter(({ showOnMobile }) => showOnMobile !== false) : colsTemplate).map(
        (colData, index) => {
          // index is used as key because columns are not removing or adding dynamically
          const isSortable = Boolean(colData.onSortingChange)

          return isSortable ? (
            <SortableCell key={index} {...colData} tableSort={tableSort} />
          ) : (
            <HeaderCell key={index} {...colData} />
          )
        }
      )
    }, [isMobile, colsTemplate, tableSort])

    return (
      <div
        className={classnames(
          classes[TABLE_ROW_CLASSNAME],
          classes.headlineRow,
          {
            [classes[ROW_WITH_EXPAND_BUTTON_SPACING]]: hasExpandableRow
          },
          rowTemplate?.headlineRowClassName
        )}
      >
        <HeadLineColumns />
      </div>
    )
  }
)

const colsPropTypeShape = {
  showOnMobile: PropTypes.bool,
  attributes: PropTypes.shape({
    // allow to add HTML elements attributes to cell
    [SKIP_CELL_FOR_COPY]: PropTypes.bool // allow to skip cell for copy
  }),
  // header
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]),
  headClassName: PropTypes.string,
  // main cell
  Cell: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  fieldKey: PropTypes.string,
  className: PropTypes.string,
  // footer
  footerClassName: PropTypes.string
}

TableHeader.propTypes = {
  cols: PropTypes.arrayOf(PropTypes.shape(colsPropTypeShape)),
  rowTemplate: PropTypes.shape({
    cols: PropTypes.arrayOf(PropTypes.shape(colsPropTypeShape)),
    params: PropTypes.shape({
      isExpandable: PropTypes.func,
      expandedContent: PropTypes.func
    }),
    markerColor: PropTypes.string,
    determineRowMarker: PropTypes.func,
    rowClassName: PropTypes.string,
    headlineRowClassName: PropTypes.string,
    getRowClassName: PropTypes.func,
    getMobileRowClassName: PropTypes.func
  }),
  hasMobileHiddenColumns: PropTypes.bool,
  hasExpandableRow: PropTypes.bool,
  isBigRow: PropTypes.bool
}

export default TableHeader
