import { createStyles } from '../../../../styles/helpers'

import { transitionLong } from '../../../../styles/const/common'
import { hideVisibleScrollbar } from '../../../../styles/common'
import { phonesDown } from '../../../../styles/const/breakpoints'
import { lighterGrey } from '../../../../styles/const/colors'
import { drawerContentPadding, formContainerMaxWidth } from '../../../../styles/common/drawerForms'

const useStyles = createStyles({
  formWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  formContainer: {
    width: '100%',
    maxWidth: formContainerMaxWidth,
    padding: ({ isFormProgressExist }) => isFormProgressExist && drawerContentPadding,
    margin: '0 auto'
  },
  form: {
    // clip was used instead of hidden to prevent unexpected cut of the content
    // one of use casses is Select component in the bottom of the form
    overflowX: 'clip',
    // hide visible scrollbar
    extend: [hideVisibleScrollbar]
  },
  sideColumn: {
    width: 330,
    background: lighterGrey,
    padding: '70px 20px'
  },
  steps: {
    display: 'flex',
    alignItems: 'flex-start',
    whiteSpace: 'nowrap',
    transition: transitionLong
  },
  stepItem: {
    whiteSpace: 'initial',
    minWidth: '100%',
    // some edges of previous step may be visible on the next step, padding 1px fixes it
    padding: '0 1px'
  },
  btnSkeleton: {
    maxWidth: 350,
    margin: '0 auto'
  },
  [`@media screen and (${phonesDown})`]: {
    formContainer: {
      maxWidth: '100%',
      padding: () => [0, '!important']
    }
  }
})

export default useStyles
