import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import { formatDateShort } from '../../../../../constants/dates'

import { PROVIDER_TO_PLATFORMS } from '../../../../../constants/selectLists/platformList'

import useStyles from './styles'
import { selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'

const NameCol = ({ itemData, platform, getItemLinkClickHandler }) => {
  const classes = useStyles()

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const { name, ad_account_id: adAccountId, provider, date_start: dateStart, date_stop: dateStop } = itemData

  const itemLinkClickHandler =
    getItemLinkClickHandler &&
    getItemLinkClickHandler({
      item: itemData,
      platform: PROVIDER_TO_PLATFORMS[provider] || platform,
      adAccountId: adAccountId || selectedAdAccountId
    })

  const formattedDateStart = dateStart && formatDateShort(dateStart)
  const formattedDateEnd = dateStop ? formatDateShort(dateStop) : 'Ongoing'
  const date = dateStart && `${formattedDateStart} - ${formattedDateEnd}`

  return (
    <div className={classes.nameContainer}>
      <p
        className={classnames(classes.nameText, {
          [classes.nameLink]: !!itemLinkClickHandler
        })}
        onClick={itemLinkClickHandler}
      >
        {name}
      </p>
      {date && <p className={classes.date}>{date}</p>}
    </div>
  )
}

NameCol.propTypes = {
  platform: PropTypes.string,
  itemData: PropTypes.object,
  getItemLinkClickHandler: PropTypes.func
}

export default NameCol
