import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Spoiler from '../../../../../../../../components/Spoiler'
import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../../../components/Form/Field'
import MultiSelectBox from '../../../../../../../../features/components/Form/MultiSelectBox'
import CreatableTagsMultiSelect from '../CreatableTagsMultiSelect'

import { mediaProductVariablesSelector } from '../../../../../../../../modules/selectors/mediaOrdersProductVariables'

import { INTERNAL_ID } from '../../../fields'
import { VARIABLES } from '../../../../../fields'

const AdvancedSettingsFields = ({ formik }) => {
  const { t } = useTranslation()
  const { values, setFieldValue } = formik

  const mediaProductVariables = useSelector(mediaProductVariablesSelector)

  const variablesOptions = useMemo(() => {
    // used regular map instead of formatOptionsList because spreading all fields leads to unexpected behavior in MultiSelectBox
    return mediaProductVariables.map(variable => ({
      label: variable.name,
      value: variable.id
    }))
  }, [mediaProductVariables])

  return (
    <Spoiler title="Advanced settings">
      <FieldRow
        title={t('Filter tags (optional)')}
        description={t('User will be able to filter product lists with any tags added or selected here')}
      >
        <CreatableTagsMultiSelect formik={formik} />
      </FieldRow>
      <FieldRow
        title={t('Product variables (optional)')}
        description={t('Product variables are options or parameters that the user selects during the booking process.')}
      >
        <MultiSelectBox
          placeholder={t('Select variables')}
          name={VARIABLES}
          options={variablesOptions}
          value={values[VARIABLES]}
          setFieldValue={setFieldValue}
        />
      </FieldRow>
      <FieldRow title={t('Internal ID (optional)')}>
        <Field formik={formik} name={INTERNAL_ID} autoComplete="off" placeholder="Internal ID" />
      </FieldRow>
    </Spoiler>
  )
}

export default AdvancedSettingsFields
