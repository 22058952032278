import React, { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import MultipleCheckboxesSelect from '../../../../components/MultipleCheckboxesSelect'

import { formatOptionsList } from '../../../formatters'
import { createJsonFromQueryString } from '../../../../helpers/url'

import { getControllerMembersService } from '../../../../modules/services/controller'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'

import useStyles from '../../../../pages/Settings/BookedMediaAndFiles/BookedMediaAndFilesFilters/styles'

const MultipleControllerMembersFilter = ({ Context }) => {
  const classes = useStyles()

  const controllerId = useSelector(selectedControllerIdSelector)

  const { multipleControllerMembersFilter, setMultipleControllerMembersFilter } = useContext(Context)

  const formatControllerMembers = useCallback(controllerMembers => {
    return formatOptionsList({
      list: controllerMembers,
      valueName: 'id',
      labelName: 'full_name'
    })
  }, [])

  const loadOptions = useCallback(
    async (search, loadedOptions, { next }) => {
      const response = await getControllerMembersService({
        ...(search && { search }),
        controller: controllerId,
        limit: 100,
        ...createJsonFromQueryString(`?${next.split('?')[1]}`)
      })

      const newControllerMembersOptions = formatControllerMembers(response.results)

      return {
        options: newControllerMembersOptions,
        hasMore: !!response.next,
        additional: {
          next: response.next
        }
      }
    },
    [controllerId, formatControllerMembers]
  )

  const onFilterChangeHandler = useCallback(
    data => {
      setMultipleControllerMembersFilter(data)
    },
    [setMultipleControllerMembersFilter]
  )

  return (
    <MultipleCheckboxesSelect
      value={multipleControllerMembersFilter}
      onChange={onFilterChangeHandler}
      loadOptions={loadOptions}
      // features:
      isSearchable={true}
      handleChangeOnClose={true}
      // styles:
      className={classes.select}
      placeholder="Owner"
      multipleOptionsLabel="Owner"
      isHighlighted={true}
      expandMenu={true}
    />
  )
}

MultipleControllerMembersFilter.propTypes = {
  Context: PropTypes.object
}

export default MultipleControllerMembersFilter
