import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import useStyles from './styles'

const DateRangePanel = ({ dateRangeList, selectedDateRange, onSelectDateRange }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {dateRangeList.map((item, index) => (
        <div
          key={index}
          className={classnames(classes.rangeItem, { active: item.value === selectedDateRange })}
          onClick={() => onSelectDateRange(item.value)}
        >
          {item.label}
        </div>
      ))}
    </div>
  )
}

DateRangePanel.propTypes = {
  dateRangeList: PropTypes.array,
  selectedDateRange: PropTypes.string,
  onSelectDateRange: PropTypes.func
}

export default DateRangePanel
