import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import MediaFilesLibrary from '../../../../../ReusableFormFields/MediaFilesLibrary'

import { getAssets, clearAssets } from '../../../../../../modules/actions/assets'
import {
  assetsErrorSelector,
  assetsIsLoadingSelector,
  assetsNextPageTokenSelector,
  assetsWasLoadedSelector,
  imageAssetsSelector
} from '../../../../../../modules/selectors/assets'

const GoogleImageFilesLibrary = ({ adAccountId, width, height, ...props }) => {
  const dispatch = useDispatch()

  const nextPageToken = useSelector(assetsNextPageTokenSelector)

  const loadInitialMediaHandler = useCallback(() => {
    dispatch(
      getAssets({
        account: adAccountId,
        asset_type: 4,
        page_size: 12,
        height,
        width
      })
    )
  }, [dispatch, height, adAccountId, width])

  const loadMoreHandler = useCallback(() => {
    dispatch(
      getAssets({
        account: adAccountId,
        asset_type: 4,
        page_size: 12,
        page_token: nextPageToken,
        height,
        width
      })
    )
  }, [dispatch, adAccountId, nextPageToken, height, width])

  const clearAssetsHandler = useCallback(() => {
    dispatch(clearAssets())
  }, [dispatch])

  return (
    <MediaFilesLibrary
      type="image"
      mediaThumbnailFieldName="url"
      mediaFilesSelector={imageAssetsSelector}
      mediaFilesIsLoadingSelector={assetsIsLoadingSelector}
      mediaFilesErrorSelector={assetsErrorSelector}
      mediaFilesWasLoadedSelector={assetsWasLoadedSelector}
      mediaFilesNextSelector={assetsNextPageTokenSelector}
      loadInitialMediaHandler={loadInitialMediaHandler}
      loadMoreHandler={loadMoreHandler}
      clearMediaHandler={clearAssetsHandler}
      {...props}
    />
  )
}

export default GoogleImageFilesLibrary
