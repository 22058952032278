import update from 'immutability-helper'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
  CLEAR_DELETE_TAG,
  UPDATE_TAG,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAILURE,
  CLEAR_UPDATE_TAG,
  CREATE_TAG,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILURE,
  CLEAR_CREATE_TAG,
  DELETE_PRODUCT_TAG,
  DELETE_PRODUCT_TAG_SUCCESS,
  DELETE_PRODUCT_TAG_FAILURE,
  CLEAR_DELETE_PRODUCT_TAG,
  UPDATE_PRODUCT_TAG,
  UPDATE_PRODUCT_TAG_SUCCESS,
  UPDATE_PRODUCT_TAG_FAILURE,
  CLEAR_UPDATE_PRODUCT_TAG,
  CREATE_PRODUCT_TAG,
  CREATE_PRODUCT_TAG_SUCCESS,
  CREATE_PRODUCT_TAG_FAILURE,
  CLEAR_CREATE_PRODUCT_TAG,
  GET_TAGS_LIST_FAILURE,
  GET_TAGS_LIST,
  GET_TAGS_LIST_SUCCESS,
  CLEAR_GET_TAGS_LIST,
  GET_PRODUCT_TAGS,
  GET_PRODUCT_TAGS_SUCCESS,
  GET_PRODUCT_TAGS_FAILURE,
  CLEAR_GET_PRODUCT_TAGS
} from '../actions/tags'

import {
  INITIAL_GET_RESULTS_REDUCER,
  INITIAL_CREATE_REDUCER,
  INITIAL_UPDATE_REDUCER,
  INITIAL_DELETE_REDUCER
} from '../../constants/reducer'
import { deleteItemById, updateItem } from '../../helpers/modules/reducerHelpers'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  deleteTag: INITIAL_DELETE_REDUCER,
  updateTag: INITIAL_UPDATE_REDUCER,
  createTag: INITIAL_CREATE_REDUCER,
  deleteProductTag: INITIAL_DELETE_REDUCER,
  updateProductTag: INITIAL_UPDATE_REDUCER,
  createProductTag: INITIAL_CREATE_REDUCER,
  tags: INITIAL_GET_RESULTS_REDUCER,
  productsTags: INITIAL_GET_RESULTS_REDUCER
}

export default function tags(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    case GET_TAGS_LIST:
      return update(state, {
        tags: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_TAGS_LIST_SUCCESS:
      return update(state, {
        tags: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.tagsListData.results
          }
        }
      })
    case GET_TAGS_LIST_FAILURE:
      return update(state, {
        tags: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_TAGS_LIST:
      return update(state, {
        tags: { $set: initialState.tags }
      })

    // Create tag
    case CREATE_TAG:
      return update(state, {
        createTag: { $merge: { isLoading: true } }
      })
    case CREATE_TAG_SUCCESS:
      return update(state, {
        createTag: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },
        tags: {
          results: {
            $push: [action.data]
          }
        }
      })
    case CREATE_TAG_FAILURE:
      return update(state, {
        createTag: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_TAG:
      return update(state, {
        createTag: { $set: initialState.createTag }
      })

    // Update tag
    case UPDATE_TAG:
      return update(state, {
        updateTag: { $merge: { isLoading: true, id: action.id } }
      })
    case UPDATE_TAG_SUCCESS:
      return update(state, {
        updateTag: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            data: action.data
          }
        },
        tags: {
          results: {
            $apply: items => updateItem(items, action.data)
          }
        }
      })
    case UPDATE_TAG_FAILURE:
      return update(state, {
        updateTag: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_TAG:
      return update(state, {
        updateTag: { $set: initialState.updateTag }
      })

    // Delete tag
    case DELETE_TAG:
      return update(state, {
        deleteTag: { $merge: { isLoading: true, id: action.id } }
      })
    case DELETE_TAG_SUCCESS:
      return update(state, {
        deleteTag: {
          $merge: {
            isLoading: false,
            wasDeleted: true,
            data: action.data
          }
        },
        tags: {
          results: {
            $apply: items => deleteItemById(items, state.deleteTag.id)
          }
        }
      })
    case DELETE_TAG_FAILURE:
      return update(state, {
        deleteTag: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DELETE_TAG:
      return update(state, {
        deleteTag: { $set: initialState.deleteTag }
      })

    // Get product tags
    case GET_PRODUCT_TAGS:
      return update(state, {
        productsTags: { $merge: { isLoading: true } }
      })
    case GET_PRODUCT_TAGS_SUCCESS:
      return update(state, {
        productsTags: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data?.results
          }
        }
      })
    case GET_PRODUCT_TAGS_FAILURE:
      return update(state, {
        productsTags: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_PRODUCT_TAGS:
      return update(state, {
        productsTags: { $set: initialState.productsTags }
      })

    // Create product tag
    case CREATE_PRODUCT_TAG:
      return update(state, {
        createProductTag: { $merge: { isLoading: true } }
      })
    case CREATE_PRODUCT_TAG_SUCCESS:
      return update(state, {
        createProductTag: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },
        productsTags: {
          results: {
            $push: [action.data]
          }
        }
      })
    case CREATE_PRODUCT_TAG_FAILURE:
      return update(state, {
        createProductTag: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_PRODUCT_TAG:
      return update(state, {
        createProductTag: { $set: initialState.createProductTag }
      })

    // Update product tag
    case UPDATE_PRODUCT_TAG:
      return update(state, {
        updateProductTag: {
          $merge: {
            isLoading: true,
            id: action.id
          }
        }
      })
    case UPDATE_PRODUCT_TAG_SUCCESS:
      return update(state, {
        updateProductTag: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            data: action.data
          }
        },
        productsTags: {
          results: {
            $apply: items => updateItem(items, action.data)
          }
        }
      })
    case UPDATE_PRODUCT_TAG_FAILURE:
      return update(state, {
        updateProductTag: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_PRODUCT_TAG:
      return update(state, {
        updateProductTag: { $set: initialState.updateProductTag }
      })

    // Delete product tag
    case DELETE_PRODUCT_TAG:
      return update(state, {
        deleteProductTag: { $merge: { isLoading: true, id: action.id } }
      })
    case DELETE_PRODUCT_TAG_SUCCESS:
      return update(state, {
        deleteProductTag: {
          $merge: {
            isLoading: false,
            wasDeleted: true,
            data: action.data
          }
        },
        productsTags: {
          results: {
            $apply: items => deleteItemById(items, state.deleteProductTag.id)
          }
        }
      })
    case DELETE_PRODUCT_TAG_FAILURE:
      return update(state, {
        deleteProductTag: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DELETE_PRODUCT_TAG:
      return update(state, {
        deleteProductTag: { $set: initialState.deleteProductTag }
      })

    default:
      return state
  }
}
