import React, { useCallback, useEffect, useState, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import history from '../../../../history'

import Modal from '../../../../components/Modal'
import Button from '../../../../components/Button'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-grey.svg'
import { ReactComponent as SuccessIcon } from '../../../../assets/icons/success-icon.svg'

import { createJsonFromQueryString } from '../../../../helpers/url'

import useStyles from './styles'

function SuccessModal({
  children,
  title,
  successButtonText,
  successButtonAction,
  modalType,
  showCloseButton,
  ButtonLeft,
  ButtonRight,
  closeOnBtnClick = true,
  ...modalProps
}) {
  const { t } = useTranslation()
  const classes = useStyles()

  const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false)

  const { search } = useLocation()
  const { message: queryParamsModalType } = createJsonFromQueryString(search)

  const handleCloseSuccessModal = useCallback(() => {
    // Close modal and clear query params
    history.replace({
      search: ''
    })
    setIsSuccessModalOpened(false)
  }, [])

  const successButtonHandler = useCallback(() => {
    handleCloseSuccessModal()
    successButtonAction()
  }, [successButtonAction, handleCloseSuccessModal])

  const getButtonProps = element => {
    // populate component with props during the rendering handle modal close on buttons click
    return cloneElement(element, {
      onClick: () => {
        closeOnBtnClick && handleCloseSuccessModal()
        // propagate original component callback
        element.props.onClick && element.props.onClick()
      }
    })
  }

  useEffect(() => {
    if (queryParamsModalType === modalType && !isSuccessModalOpened) {
      setIsSuccessModalOpened(true)
    }
  }, [queryParamsModalType, isSuccessModalOpened, modalType])

  return (
    <Modal
      isOpen={isSuccessModalOpened}
      onRequestClose={handleCloseSuccessModal}
      shouldFocusAfterRender={true}
      {...modalProps}
    >
      <span onClick={handleCloseSuccessModal} className={classes.closeIcon}>
        <CloseIcon />
      </span>
      <SuccessIcon />
      <p className={classes.title}>{title || t('Success!')}</p>
      <div className={classes.successText}>{children}</div>
      <div className={classes.btnContainer}>
        {showCloseButton && <Button onClick={handleCloseSuccessModal}>{t('Close')}</Button>}
        {ButtonLeft && getButtonProps(ButtonLeft)}
        {successButtonAction && (
          <Button onClick={successButtonHandler} solid className={classes.successButton}>
            {t(successButtonText)}
          </Button>
        )}
        {ButtonRight && getButtonProps(ButtonRight)}
      </div>
    </Modal>
  )
}

SuccessModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  successButtonText: PropTypes.string,
  successButtonClassName: PropTypes.string,
  successButtonAction: PropTypes.func,
  modalType: PropTypes.string,
  showCloseButton: PropTypes.bool,
  ButtonLeft: PropTypes.node,
  ButtonRight: PropTypes.node,
  // control ability to close drawer for custom buttons
  closeOnBtnClick: PropTypes.bool
}

export default SuccessModal
