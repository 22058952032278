import { createStyles } from '../../../../styles/helpers'
import { textGrey } from '../../../../styles/const/colors'

const useStyles = createStyles({
  verificationSection: {
    paddingTop: 42,
    paddingBottom: 32
  },
  verificationSectionTitle: {
    marginBottom: 14
  },
  restartLoginMessage: {
    marginTop: [40, '!important'],
    textAlign: 'center'
  },
  restartLoginButton: {
    border: 'none',
    background: 'none',
    padding: 0,
    fontSize: 14,
    textDecoration: 'underline',
    outline: 'none',
    color: textGrey,
    cursor: 'pointer'
  }
})

export default useStyles
