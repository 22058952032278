import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import AgencyMemberPermissionsEditForm from './AgencyMemberPermissionsEditForm'

import useManageFormsDrawer from '../../../../../hooks/formHooks/useManageFormsDrawer'

import { AGENCY_PERMISSIONS_EDIT } from '../../../../../constants/forms'
import { agencyMembersIsLoadingSelector, agencyMembersSelector } from '../../../../../modules/selectors/agencyTeam'

const AgencyMemberPermissionsEdit = () => {
  const { t } = useTranslation()

  const agencyMembers = useSelector(agencyMembersSelector)
  const agencyMembersIsLoading = useSelector(agencyMembersIsLoadingSelector)

  const { selectedEditItemId } = useManageFormsDrawer({
    formName: AGENCY_PERMISSIONS_EDIT
  })

  const memberData = agencyMembers?.find(item => String(item.id) === String(selectedEditItemId))

  return (
    <FormDrawerWrapper
      isFormLoading={agencyMembersIsLoading}
      formName={AGENCY_PERMISSIONS_EDIT}
      title={t('Edit agency permissions')}
      showOpenButton={false}
    >
      <AgencyMemberPermissionsEditForm memberData={memberData} />
    </FormDrawerWrapper>
  )
}

export default AgencyMemberPermissionsEdit
