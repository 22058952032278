import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import SelfAccountCreateForm from './SelfAccountCreateForm'
import InfoBlock from '../../../../features/components/InfoBlock'
import { ReactComponent as WelcomeIcon } from '../../../../assets/icons/welcome.svg'

import { getAppDomainName } from '../../../../features/helpers/getAppDomainName'

const SelfAccountCreate = () => {
  const { t } = useTranslation()

  const appName = getAppDomainName()

  return (
    <>
      <InfoBlock Icon={WelcomeIcon} title={t('InfoBlock.title', { appName })}>
        {t('SelfAccountSetupPage.InfoBlock.description')}
      </InfoBlock>
      <SelfAccountCreateForm />
    </>
  )
}

SelfAccountCreate.propTypes = {
  setSelectedModule: PropTypes.func
}

export default SelfAccountCreate
