import { createStyles } from '../../../../../../../styles/helpers'
import { greyOutline } from '../../../../../../../styles/const/colors'

export const discountedStyle = {
  color: greyOutline,
  textDecoration: 'line-through',
  marginRight: '15px'
}

export default createStyles({
  prices: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  price: {
    fontWeight: ({ isBold }) => (isBold ? 600 : 400)
  },
  previousPrice: {
    fontWeight: ({ isBold }) => (isBold ? 600 : 400),
    ...discountedStyle
  }
})
