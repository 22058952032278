import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  locationsTabs: {
    marginBottom: 8
  },
  checkbox: {
    marginTop: 8
  }
})

export default useStyles
