import React from 'react'
import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

const InventoryRowSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.dateField}>
        <Skeleton height={38} />
      </div>
      <div className={classes.field}>
        <Skeleton height={38} />
      </div>
      <div className={classes.field}>
        <Skeleton height={38} />
      </div>
    </div>
  )
}

export default InventoryRowSkeleton
