import React from 'react'

import Field from '../../../../../../../components/Form/Field'
import Step from '../../../../../../../features/components/Forms/StepForm/Step'

import { LANDING_PAGE_URL } from '../../../../fields'

const stepFields = [LANDING_PAGE_URL]

function ClickThroughLinkStep({ formik, handleStepChange }) {
  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  return (
    <Step formTitle="Create an ad" stepTitle="Please provide a click through link" handleContinue={handleContinue}>
      <Field formik={formik} name={LANDING_PAGE_URL} placeholder="Click Through Link" autoComplete="off" />
    </Step>
  )
}

export default ClickThroughLinkStep
