import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import DeleteItemModal from '../Modals/DeleteModal/DeleteItemModal'
import SummaryPagesFacebookAdsList from './SummaryPagesFacebookAdsList'
import GooglePerformingAds from '../../../pages/MediaOrderSummary/MediaOrderSummaryContent/MediaOrderCampaignsReports/HighestPerformingAds/GooglePerformingAds'
import TikTokPerformingAds from '../../../pages/MediaOrderSummary/MediaOrderSummaryContent/MediaOrderCampaignsReports/HighestPerformingAds/TikTokPerformingAds'
import DV360PerformingAds from '../../../pages/MediaOrderSummary/MediaOrderSummaryContent/MediaOrderCampaignsReports/HighestPerformingAds/DV360PerformingAds'

import useAdActions from '../../../pages/CampaignsSummary/SummaryPagesAdsContent/adsHooks/useAdActions'

import {
  getAdsSelector,
  adsLoadingSelector,
  adsWasLoadedSelector,
  adsErrorSelector
} from '../../../modules/selectors/ads'

import {
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  DV_360_PLATFORM,
  TIKTOK_PLATFORM
} from '../../../constants/selectLists/platformList'

const SummaryPagesAdsLists = ({ selectedPlatform, loadMoreHandler, adAccountId }) => {
  const adsWasLoaded = useSelector(adsWasLoadedSelector)
  const adsIsLoading = useSelector(adsLoadingSelector)

  const showSectionIfWasLoadedOrIsLoading = adsWasLoaded || adsIsLoading

  const {
    getAdActionsDropdownOptions,
    updateAdStatusHandler,
    handleDelete,
    handleDeleteRequest,
    handleCloseDeleteModal,
    isDeleteModalOpened,
    deleteItemIsLoading,
    deleteModalMessage,
    archiveProps,
    itemUpdatingId
  } = useAdActions({ selectedAdAccountId: adAccountId, selectedPlatform })

  const summaryPagesAdsProps = useMemo(() => {
    return {
      dataSelector: getAdsSelector,
      loadingSelector: adsLoadingSelector,
      errorSelector: adsErrorSelector,
      wasLoadedSelector: adsWasLoadedSelector,
      showOnlyHighestPerforming: false,
      initialLoadingSkeletonsNumber: 8,
      loadMoreHandler: loadMoreHandler,
      handleDeleteRequest,
      getAdActionsDropdownOptions, // this one is used only for GooglePerformingAds - remove once refactored
      updateAdStatusHandler, // this one is used only for GooglePerformingAds - remove once refactored
      itemUpdatingId // todo add to SummaryPagesFacebookAdsList
    }
  }, [loadMoreHandler, updateAdStatusHandler, handleDeleteRequest, getAdActionsDropdownOptions, itemUpdatingId])

  const isFacebookPlatform = selectedPlatform === FACEBOOK_PLATFORM
  const isGooglePlatform = selectedPlatform === GOOGLE_PLATFORM
  const isTiktokPlatform = selectedPlatform === TIKTOK_PLATFORM
  const isDV360Platform = selectedPlatform === DV_360_PLATFORM

  return (
    <>
      {isFacebookPlatform && showSectionIfWasLoadedOrIsLoading && (
        <SummaryPagesFacebookAdsList loadMoreHandler={loadMoreHandler} handleDeleteRequest={handleDeleteRequest} />
      )}
      {isGooglePlatform && showSectionIfWasLoadedOrIsLoading && <GooglePerformingAds {...summaryPagesAdsProps} />}
      {isTiktokPlatform && showSectionIfWasLoadedOrIsLoading && <TikTokPerformingAds {...summaryPagesAdsProps} />}
      {isDV360Platform && showSectionIfWasLoadedOrIsLoading && <DV360PerformingAds {...summaryPagesAdsProps} />}
      <DeleteItemModal
        handleDelete={handleDelete}
        handleDeleteRequest={handleDeleteRequest}
        handleCloseDeleteModal={handleCloseDeleteModal}
        isDeleteModalOpened={isDeleteModalOpened}
        deleteItemIsLoading={deleteItemIsLoading}
        deleteModalMessage={deleteModalMessage}
        // props for archive button, works only for facebook
        archiveProps={selectedPlatform === FACEBOOK_PLATFORM ? archiveProps : null}
      />
    </>
  )
}

SummaryPagesAdsLists.propTypes = {
  selectedPlatform: PropTypes.string.isRequired,
  loadMoreHandler: PropTypes.func
}

export default SummaryPagesAdsLists
