import { createStyles } from '../../../styles/helpers'

import { greyOutline, textGrey } from '../../../styles/const/colors'
import { tabletDown } from '../../../styles/const/breakpoints'
import { card } from '../../../styles/common'
import { transitionShort } from '../../../styles/const/common'

const useStyles = createStyles(theme => ({
  header: {
    fontSize: 24,
    color: textGrey
  },
  filtersContainer: {
    marginTop: 32,
    marginBottom: 16
  },
  desktopFiltersWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: 10
  },
  createFormComponent: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexGrow: 1
  },
  filtersRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: 10,
    '& [class*="btn-"]': {
      paddingTop: 10,
      paddingBottom: 10,
      whiteSpace: 'nowrap'
    }
  },
  select: {
    width: 160
  },
  actionBtn: {
    extend: card(theme),
    borderColor: greyOutline,
    width: 38,
    minWidth: 38,
    height: 38,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      transition: transitionShort,
      borderColor: theme.brandPrimary
    }
  },
  mobileDateRangeWrapper: {},

  [`@media screen and (${tabletDown})`]: {
    filtersContainer: {
      marginTop: 24
    },
    filtersRow: {
      marginTop: 18,
      width: '100%'
    },
    mobileDateRangeWrapper: {
      flex: 1,
      '& > div': {
        width: '100%',
        maxWidth: '100%'
      }
    },
    actionBtn: {
      width: 40,
      minWidth: 40,
      height: 40
    },
    select: {
      width: '100%'
    }
  }
}))

export default useStyles
