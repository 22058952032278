import { defaults } from 'chart.js'
import { useTheme } from 'react-jss'

import { lightGrey } from '../../../styles/const/colors'

export function useGraph() {
  const theme = useTheme()
  defaults.color = lightGrey
  defaults.font.family = theme.fontFamily.value
}
