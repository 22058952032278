import React from 'react'
import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

const AdCardMetricSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.metric}>
      <div style={{ width: '45%' }}>
        <Skeleton />
      </div>
      <div style={{ width: '22%' }}>
        <Skeleton />
      </div>
    </div>
  )
}

export default AdCardMetricSkeleton
