import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../components/Form'
import ControllerMemberEditFormContent from './ControllerMemberEditFormContent'

import { isEqual } from '../../../../../helpers/common'
import { transformValuesToBE } from './formatters'

import {
  controllerMemberSelector,
  updatedControllerMemberErrorSelector,
  updatedControllerMemberIsLoadingSelector,
  updatedControllerMemberSelector
} from '../../../../../modules/selectors/controller'
import {
  clearUpdateControllerMember,
  updateControllerMember,
  updateControllerMemberSuccess
} from '../../../../../modules/actions/controller'
import { selectedControllerRelatedSelfAccountsListSelector } from '../../../../../modules/selectors/app'

import { CONTROLLER_MEMBER_EDIT } from '../../../../../constants/forms'
import { getInitialValues } from './fields'

const ControllerMemberEditForm = () => {
  const dispatch = useDispatch()

  const controllerMember = useSelector(controllerMemberSelector)
  const { id: memberId, role: memberRole } = controllerMember

  const { wasUpdated: controllerMemberWasUpdated } = useSelector(updatedControllerMemberSelector)
  const controllerSelfAccountsList = useSelector(selectedControllerRelatedSelfAccountsListSelector)

  const initialValues = useMemo(
    () => getInitialValues(controllerMember, controllerSelfAccountsList),
    [controllerMember, controllerSelfAccountsList]
  )

  const clearUpdateCategoryMemberHandler = useCallback(() => {
    dispatch(clearUpdateControllerMember())
  }, [dispatch])

  const onSubmit = useCallback(
    values => {
      const hasChanges = !isEqual(values, initialValues)
      const formattedData = transformValuesToBE(values, memberRole)

      if (hasChanges) {
        dispatch(updateControllerMember(memberId, formattedData))
      } else {
        dispatch(updateControllerMemberSuccess(formattedData))
      }
    },
    [dispatch, memberId, initialValues, memberRole]
  )

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true
  })

  return (
    <Form
      formName={CONTROLLER_MEMBER_EDIT}
      formik={formik}
      successSubmit={controllerMemberWasUpdated}
      errorSelector={updatedControllerMemberErrorSelector}
      isLoadingSelector={updatedControllerMemberIsLoadingSelector}
      clearHandler={clearUpdateCategoryMemberHandler}
    >
      <ControllerMemberEditFormContent formik={formik} memberRole={memberRole} />
    </Form>
  )
}

export default ControllerMemberEditForm
