import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import FieldRow from '../../../../../../features/components/Form/FieldsSection/FieldRow'
import FieldsSection from '../../../../../../features/components/Form/FieldsSection'
import BookedMediaCampaignsField from './BookedMediaCampaignsField'

import { formatCurrency } from '../../../../../../helpers/numbers'

import { selectedControllerDataSelector } from '../../../../../../modules/selectors/app'

import useStyles from './styles'

const Item = ({ item = {} }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const selectedSelfAccountController = useSelector(selectedControllerDataSelector)
  const { sequential_id: sequentialId, name: itemName, media_order_total: total, booked_media: bookedMedia } = item

  return (
    <FieldsSection title={`${sequentialId} - ${itemName}`}>
      {!!bookedMedia?.length
        ? bookedMedia.map(
            (
              {
                media_product_name: name,
                booked_media_date_quantity: quantity,
                booked_media_cost: cost,
                booked_media_period: period,
                offsite,
                booked_media_campaigns: bookedMediaCampaigns
              },
              index
            ) =>
              offsite ? (
                <BookedMediaCampaignsField
                  key={index}
                  productName={itemName}
                  bookedMediaCampaigns={bookedMediaCampaigns}
                />
              ) : (
                <FieldRow
                  key={index}
                  title={name}
                  description={period && quantity && `${period}x${quantity}`}
                  leftSideClassName={classes.leftSide}
                >
                  {cost && (
                    <div className={classes.costField}>
                      {formatCurrency(
                        cost,
                        { min: 2, max: 2 },
                        { symbol: selectedSelfAccountController?.currency_symbol }
                      )}
                    </div>
                  )}
                </FieldRow>
              )
          )
        : null}
      {total && (
        <FieldRow title={t('Subtotal')}>
          <div className={classes.subTotalField}>
            {formatCurrency(total, { min: 2, max: 2 }, { symbol: selectedSelfAccountController.currency_symbol })}
          </div>
        </FieldRow>
      )}
    </FieldsSection>
  )
}

export default Item
