import React, { useCallback } from 'react'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import ProductBreakdown from '../ProductBreakdown'
import ActionText from '../../../../../../../../../../components/ActionText'
import ErrorMessage from '../../../../../../../../../../components/Form/ErrorMessage'

import { getAllPackageProducts, getProductsPath } from '../../../../helpers/products'

import { ALLOWED_PRODUCT_SELECTION, SELECTED_PACKAGES } from '../../../../fields'
import { PACKAGE_ERROR } from '../../../../validation'

import useStyles from './styles'

function AllOrderedProducts({ formik, selectedMediaPackage, packageIndex, currency, showError, minQuantity }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { values, setFieldValue, errors } = formik

  const packageError = getIn(errors, `${SELECTED_PACKAGES}.[${packageIndex}]`)?.[PACKAGE_ERROR]

  const allowSelectProduct = useCallback(() => {
    setFieldValue(`${SELECTED_PACKAGES}.[${packageIndex}].${ALLOWED_PRODUCT_SELECTION}`, true)
  }, [packageIndex, setFieldValue])

  const selectedProducts = getAllPackageProducts(selectedMediaPackage)

  const handleProductDelete = useCallback(
    deleteProduct => {
      const categorySelectedProductsName = `${SELECTED_PACKAGES}.[${packageIndex}].${getProductsPath(deleteProduct.media_category)}`
      const categorySelectedProducts = getIn(values, categorySelectedProductsName)

      setFieldValue(
        categorySelectedProductsName,
        categorySelectedProducts.filter(productValues => productValues.data.id !== deleteProduct.id)
      )
    },
    [packageIndex, setFieldValue, values]
  )

  return (
    <section className={classes.productCategory}>
      {selectedProducts.map((productValues, index) => (
        <ProductBreakdown
          key={productValues.data.id}
          formik={formik}
          currency={currency}
          productValues={productValues}
          productPath={`${SELECTED_PACKAGES}[${packageIndex}].${getProductsPath(productValues.data.media_category)}.[${index}]`}
          onProductRemove={() => handleProductDelete(productValues.data)}
          minQuantity={minQuantity}
        />
      ))}
      <ActionText onClick={allowSelectProduct} isDark isBold>
        {t('+ Add another product')}
      </ActionText>
      {showError && packageError && <ErrorMessage error={packageError} />}
    </section>
  )
}

AllOrderedProducts.propTypes = {
  formik: PropTypes.object.isRequired,
  selectedMediaPackage: PropTypes.object.isRequired,
  packageIndex: PropTypes.number.isRequired,
  currency: PropTypes.object.isRequired,
  showError: PropTypes.bool
}

export default AllOrderedProducts
