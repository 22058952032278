import { createStyles } from '../../../styles/helpers'

import { darkGrey, greyOutline, red, textGrey } from '../../../styles/const/colors'
import { transitionShort } from '../../../styles/const/common'
import { placeholderColor } from '../../../styles/mixins/common'
import { tabletDown } from '../../../styles/const/breakpoints'

const useStyles = createStyles(theme => ({
  wrapper: {
    width: '100%'
  },
  textAreaWrapper: {
    position: 'relative',
    paddingTop: 5
  },
  textarea: {
    fontFamily: theme.fontFamily.value,
    fontSize: 14,
    color: textGrey,
    padding: ({ hasEmojiPicker }) => (hasEmojiPicker ? '10px 36px 10px 12px' : '10px 12px'),
    resize: 'none',
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: 38,
    margin: 0,
    overflow: 'hidden',
    border: '1px solid',
    borderRadius: theme.defaultBorderRadius,
    borderColor: props => (props.showError ? [red, '!important'] : greyOutline),
    outline: 'none',
    ...placeholderColor(greyOutline),
    '&:focus': {
      borderColor: theme.brandPrimary
    },
    [`@media screen and (${tabletDown})`]: {
      minHeight: 40
    }
  },
  icon: {
    cursor: 'pointer',
    marginTop: '1px',
    position: 'absolute',
    right: 12,
    top: 14,
    transition: transitionShort,
    fontSize: '14px',
    lineHeight: '20px',
    color: darkGrey,
    '& svg': {
      width: '18px'
    }
  }
}))

export default useStyles
