import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles({
  topMargin: {
    marginTop: 32
  },
  additionalFieldsContainer: {
    display: 'flex',
    columnGap: 16,
    marginBottom: 32
  },
  portal: {
    marginLeft: 'auto'
  },
  brandSelect: {
    width: 200
  },
  fieldWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8
  },
  errorClassName: {
    position: 'absolute' // field don't jump when the error
  }
})

export default useStyles
