import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { getIn } from 'formik'
import { default as PhoneInputComponent } from 'react-phone-input-2'
import { useTranslation } from 'react-i18next'

import ErrorMessage from '../ErrorMessage'

import useFieldErrorReset from '../Field/hook'

import { getFormikFieldError } from '../../../helpers/errors'

import useStyles from './styles'

const PhoneInput = ({ className, formik, name, initialValue, country, onValueChange }) => {
  const { t } = useTranslation()

  const [focused, setFocused] = useState(false)
  const [countryWasPreselected, setCountryWasPreselected] = useState(false)
  const [value, setValue] = useState(initialValue || '')
  const [inputElement, setInputElement] = useState(null)

  const hasValue = !!value

  const classes = useStyles({ hasValue, focused })

  const { setFieldValue, errors, touched, setFieldError, setFieldTouched, setStatus, status } = formik
  const { error, serverError } = getFormikFieldError(errors, status, name)
  const { resetError } = useFieldErrorReset({ error, errors, name, setFieldError, serverError, setStatus, status })
  const isTouched = getIn(touched, name)
  const showError = !!(isTouched && error)

  const onChange = (value, additionalDetails) => {
    // don't set dial code to formik when phone number is not typed
    const phoneNumber = value.length > additionalDetails.dialCode.length ? value : ''

    setValue(value)
    setFieldValue(name, phoneNumber)
    onValueChange && onValueChange(phoneNumber, name)
    resetError()
  }

  const onFocus = () => {
    setFocused(true)
  }

  const onBlur = () => {
    setFocused(false)
    setFieldTouched(name, true)
  }

  // preselect dial code for specified country
  useEffect(() => {
    if (inputElement && country && !initialValue && !countryWasPreselected) {
      const countryData = inputElement.state.onlyCountries.find(item => item.iso2 === country)
      const dialCode = countryData && countryData.dialCode

      setValue(dialCode)
      setCountryWasPreselected(true)
    }
  }, [inputElement, country, countryWasPreselected, initialValue, setFieldValue, name])

  return (
    <div className={classnames('field', className)}>
      <PhoneInputComponent
        ref={setInputElement}
        country={!initialValue && !countryWasPreselected ? country : ''}
        value={value}
        onChange={onChange}
        inputClass={classes.input}
        buttonClass={classes.dropdown}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={t('Phone number')}
      />
      {showError && <ErrorMessage error={error} />}
    </div>
  )
}

PhoneInput.propTypes = {
  formik: PropTypes.object,
  className: PropTypes.string,
  name: PropTypes.string,
  initialValue: PropTypes.string,
  onValueChange: PropTypes.func,
  country: PropTypes.string
}

export default PhoneInput
