import React, { useCallback, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { format } from 'date-fns'

import Can from '../../../features/components/Can'
import SummaryPagesAdsContent from '../SummaryPagesAdsContent'
import LineItems from '../LineItems'
import AssetGroups from '../AssetGroups'
import SummaryReport from '../../../features/components/SummaryReport'
import ListItemsFilters from '../../../features/components/ListItemsFilters'
import LineItemFacebookCreate from '../../../forms/Facebook/LineItemForms/LineItemFacebookCreate'
import Chip from '../../../components/Chip'
import Breadcrumbs from '../../../features/components/Breadcrumbs'
import CampaignFacebookEdit from '../../../forms/Facebook/CampaignForms/CampaignFacebookEdit'
import CampaignGoogleEdit from '../../../forms/Google/CampaignForms/CampaignGoogleEdit'
import SuccessModal from '../../../features/components/Modals/SuccessModal'
import NewCampaignCreatedModal from '../../../features/components/Modals/NewCampaignCreatedModal'
import AssetGroupGoogleCreate from '../../../forms/Google/AdForms/GoogleAssetGroup/GoogleAssetGroupCreate'
import LineItemTikTokCreate from '../../../forms/Tiktok/LineItemForms/LineItemTikTokCreate'
import LineItemGoogleCreate from '../../../forms/Google/LineItemForms/LineItemGoogleCreate'

import usePermissions from '../../../hooks/usePermissions'

import { getStatusTagColor } from '../../../features/helpers/componentsHelpers'
import { formatStringWithUnderscore } from '../../../helpers/common'
import { redirectTo } from '../../../helpers/url'
import { getCampaignEditFormNameByPlatform } from '../../../features/helpers/constantHelpers'

import { openForm } from '../../../modules/actions/forms'
import {
  clearReports,
  getCampaignsReports,
  setCampaignsSummaryDateRange,
  setCampaignsSummaryFilter,
  setCampaignsSummarySort
} from '../../../modules/actions/campaignsSummary'

import { campaignSelector } from '../../../modules/selectors/campaigns'
import {
  campaignsSummaryFilterSelector,
  campaignsSummaryDateRangeSelector,
  campaignsSummarySortSelector,
  campaignsReportsSelector,
  campaignsReportsLoadingSelector
} from '../../../modules/selectors/campaignsSummary'
import {
  lineItemsErrorSelector,
  lineItemsSelector,
  lineItemsWasLoadedSelector
} from '../../../modules/selectors/lineItems'
import { adsWasLoadedSelector, getAdsSelector } from '../../../modules/selectors/ads'
import { selectedPlatformSelector, selectedAdAccountIdSelector } from '../../../modules/selectors/app'

import { ROUTE_PARAMS, ROUTES } from '../../../constants/routes'
import { CHIP_COLORS, MODAL_TYPE } from '../../../constants/other'
import { DATES_FORMAT_BE, formatDateShort } from '../../../constants/dates'
import { AD_FACEBOOK_CREATE, AD_GOOGLE_CREATE } from '../../../constants/forms'
import { CAMPAIGN_GOOGLE_PERFORMANCE_MAX_TYPE } from '../../../constants/campaigns'
import { AD_PERMISSION, CAMPAIGN_PERMISSION, LINE_ITEM_PERMISSION } from '../../../constants/permissions'
import { FACEBOOK_PLATFORM, GOOGLE_PLATFORM, TIKTOK_PLATFORM } from '../../../constants/selectLists/platformList'

import useStyles from '../../../styles/common/summaryPages'

const searchId = 'campaignsSummaryContent'

function CampaignSummaryContent() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { campaign_id: campaignId } = useParams()

  const campaign = useSelector(campaignSelector)
  const selectedPlatform = useSelector(selectedPlatformSelector)
  const selectedAdAccount = useSelector(selectedAdAccountIdSelector)
  const sort = useSelector(campaignsSummarySortSelector)
  const lineItems = useSelector(lineItemsSelector)
  const lineItemsError = useSelector(lineItemsErrorSelector)
  const lineItemsWasLoaded = useSelector(lineItemsWasLoadedSelector)
  const adsList = useSelector(getAdsSelector)
  const adsWasLoaded = useSelector(adsWasLoadedSelector)
  const dateRange = useSelector(campaignsSummaryDateRangeSelector)

  const permissions = usePermissions()

  const { date_preset, startDate, endDate } = dateRange

  const isLineItemsEmpty = !lineItems.length
  const isAdsEmpty = !adsList.length

  const { status, objective, date_start, date_stop, advertising_channel_type: advertisingChanelType } = campaign
  const startCampaignDate = date_start && formatDateShort(date_start)
  const endCampaignDate = date_stop ? formatDateShort(date_stop) : 'Ongoing'
  const date = (startCampaignDate || date_stop) && `${startCampaignDate} - ${endCampaignDate}`

  // currently reports are implemented only for these platforms from BE side
  const showSummaryReport =
    (selectedPlatform === FACEBOOK_PLATFORM || selectedPlatform === GOOGLE_PLATFORM) && adsWasLoaded && !isAdsEmpty

  const getReportsHandler = useCallback(() => {
    dispatch(
      getCampaignsReports({
        platform: selectedPlatform,
        external_id: selectedAdAccount,
        campaign_id: campaignId,
        date_from: startDate && format(startDate, DATES_FORMAT_BE),
        date_to: endDate && format(endDate, DATES_FORMAT_BE),
        date_preset
      })
    )
  }, [selectedPlatform, selectedAdAccount, campaignId, startDate, endDate, date_preset, dispatch])

  const chips = (
    <div className={classes.chips}>
      {status && <Chip text={status} color={getStatusTagColor(status)} />}
      {objective && <Chip text={formatStringWithUnderscore(objective)} color={CHIP_COLORS.yellow} />}
      {date && <Chip text={date} />}
    </div>
  )

  const automatedLineItemCreatedMessage = (
    <>
      <p>{t('Your ad sets were created successfully.')}</p>
      <p>
        {t(
          'The next step is to create ads in all the ad sets. We recommend creating around 3 significantly different ads (if possible) per ad set for best results.'
        )}
      </p>
    </>
  )

  const createFormName = useMemo(() => {
    switch (selectedPlatform) {
      case FACEBOOK_PLATFORM:
        return AD_FACEBOOK_CREATE
      case GOOGLE_PLATFORM:
        return AD_GOOGLE_CREATE
      default:
        return ''
    }
  }, [selectedPlatform])

  const openAdCreateFormInFirstLineItem = useCallback(() => {
    // Redirect to first created lineItem and open create ad form
    // here we use index [1] because we always have total item, which is index [0]
    const firstLineItem = lineItems[1]

    redirectTo(`${ROUTES.lineItemsSummary}`, {
      [ROUTE_PARAMS.campaignId]: campaignId,
      line_item_id: firstLineItem.id
    })
    dispatch(openForm({ formName: createFormName }))
  }, [dispatch, campaignId, lineItems, createFormName])

  const breadcrumbs = [
    {
      title: 'Campaigns list',
      url: ROUTES.campaigns
    },
    {
      title: 'Campaign',
      url: ''
    }
  ]

  const CreateForm = useMemo(() => {
    if (selectedPlatform === GOOGLE_PLATFORM && advertisingChanelType === CAMPAIGN_GOOGLE_PERFORMANCE_MAX_TYPE) {
      // For this type of campaign we don't show LineItem create form, but show AssetGroup create form instead
      // as this form is actually ad creation form, we use permissions from ads(lineItemsSummary page)
      return (
        <Can I="create" a={AD_PERMISSION}>
          <AssetGroupGoogleCreate />
        </Can>
      )
    } else if (selectedPlatform === GOOGLE_PLATFORM) {
      return (
        <Can I="create" a={LINE_ITEM_PERMISSION}>
          <LineItemGoogleCreate />
        </Can>
      )
    } else if (selectedPlatform === TIKTOK_PLATFORM) {
      return (
        <Can I="create" a={LINE_ITEM_PERMISSION}>
          <LineItemTikTokCreate />
        </Can>
      )
    } else if (selectedPlatform === FACEBOOK_PLATFORM) {
      return (
        <Can I="create" a={LINE_ITEM_PERMISSION}>
          <LineItemFacebookCreate />
        </Can>
      )
    }
  }, [selectedPlatform, advertisingChanelType])

  return (
    <>
      <ListItemsFilters
        breadcrumbsComponent={<Breadcrumbs routes={breadcrumbs} />}
        createFormComponent={CreateForm}
        pageTitle={campaign.name || <Skeleton width={280} />}
        setFilter={setCampaignsSummaryFilter}
        setDateRange={setCampaignsSummaryDateRange}
        setSort={setCampaignsSummarySort}
        searchId={searchId}
        filterSelector={campaignsSummaryFilterSelector}
        dateRangeSelector={campaignsSummaryDateRangeSelector}
        sortSelector={campaignsSummarySortSelector}
        chips={chips}
        editButtonOptions={
          permissions.can('update', CAMPAIGN_PERMISSION)
            ? {
                id: campaignId,
                formName: getCampaignEditFormNameByPlatform(selectedPlatform)
              }
            : undefined
        }
      />
      {/*<DesktopUp>*/}
      {/*  <div className={classes.graphsGrid}>*/}
      {/*    <GraphBlock icon={<IconImpressions />} caption="Impressions" value="564,563" />*/}
      {/*    <GraphBlock icon={<IconReach />} caption="Reach" value="470,469" />*/}
      {/*    <GraphBlock icon={<IconClicks />} caption="Link Clicks" value="1,599" />*/}
      {/*    <GraphBlock icon={<IconEngagement />} caption="Engagement" value="6,210" />*/}
      {/*  </div>*/}
      {/*</DesktopUp>*/}

      {/* Currently for GOOGLE_PLATFORM we support only campaigns with advertising_channel_type = 10 */}
      {/* For this type of campaign we don't show LineItems list, but show AssetGroups list instead  */}
      {/* todo fix bug when we have campaign with asset groups, it initially renders LineItems and make line items request, because advertisingChanelType condition is not true while we wait for campaign  */}
      {selectedPlatform === GOOGLE_PLATFORM && advertisingChanelType === CAMPAIGN_GOOGLE_PERFORMANCE_MAX_TYPE ? (
        <AssetGroups />
      ) : (
        <LineItems searchId={searchId} />
      )}

      {!isLineItemsEmpty && !lineItemsError && lineItemsWasLoaded && (
        // render ads and report after we know that lineItems was fetched
        // to prevent flicking these sections with "no data" text
        <>
          <SummaryPagesAdsContent dateRange={dateRange} sort={sort} />
          {showSummaryReport && (
            <SummaryReport
              pageTitle={t('Campaign Report and Insights')}
              getReports={getReportsHandler}
              clearReportsAction={clearReports}
              reportsSelector={campaignsReportsSelector}
              reportsLoadingSelector={campaignsReportsLoadingSelector}
            />
          )}
        </>
      )}

      {selectedPlatform === FACEBOOK_PLATFORM && (
        <Can I="update" a={CAMPAIGN_PERMISSION}>
          <CampaignFacebookEdit />
        </Can>
      )}
      {selectedPlatform === GOOGLE_PLATFORM && (
        <Can I="update" a={CAMPAIGN_PERMISSION}>
          <CampaignGoogleEdit />
        </Can>
      )}

      <NewCampaignCreatedModal />
      <SuccessModal
        successButtonAction={openAdCreateFormInFirstLineItem}
        successButtonText={t('Create ad in first ad set')}
        modalType={MODAL_TYPE.automatedLineItemCreated}
      >
        {automatedLineItemCreatedMessage}
      </SuccessModal>
    </>
  )
}

export default CampaignSummaryContent
