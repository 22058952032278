import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import FormHeadline from '../../../../../../../../components/Drawer/DrawerHeadline'
import ProductsPricesSection from '../ProductsPricesSection'

import { SelectedMediaProductsContext } from '../../SelectedMediaProductsContext'

import { mediaOrderSelector } from '../../../../../../../../modules/selectors/mediaOrders'

import { SELECTED_PRODUCTS } from '../fields'

import useStyles from '../styles'

const AmendmentContractCreateFields = ({ formik }) => {
  const classes = useStyles()

  const mediaOrder = useSelector(mediaOrderSelector)

  const currencySymbol = mediaOrder.currency?.symbol
  const { selectedProductsData } = useContext(SelectedMediaProductsContext)
  const { values } = formik

  return (
    <>
      <div className={classes.formHeadline}>
        <FormHeadline title="Amendment" description="Generate an amendment to this booking contract" />
      </div>
      {values[SELECTED_PRODUCTS].map((productValues, productIndex) => {
        const productData = selectedProductsData.find(product => product.id === productValues.id)

        return (
          <ProductsPricesSection
            key={productValues.id}
            formik={formik}
            productPath={`${SELECTED_PRODUCTS}[${productIndex}]`}
            productValues={productValues}
            productData={productData}
            showRemoveButton={true}
            currencySymbol={currencySymbol}
          />
        )
      })}
    </>
  )
}

AmendmentContractCreateFields.propTypes = {
  formik: PropTypes.object.isRequired
}

export default AmendmentContractCreateFields
