import React from 'react'
import { useSelector } from 'react-redux'

import Page from '../index'
import RegularLandingPageContent from './RegularLandingPageContent'
import ControllerLandingPageContent from './ControllerLandingPageContent'

import { isClientViewSelector, isUserControllerSelector } from '../../modules/selectors/app'

import useStyles from './styles'

const LandingPage = () => {
  const classes = useStyles()

  const isClientView = useSelector(isClientViewSelector)
  const isUserController = useSelector(isUserControllerSelector)

  return (
    <Page helmetTitle="helmetTitle.HomePage" mainClassName={classes.main} className={classes.pageContainer}>
      {isUserController && !isClientView ? <ControllerLandingPageContent /> : <RegularLandingPageContent />}
    </Page>
  )
}

export default LandingPage
