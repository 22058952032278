import { endOfMonth, isAfter, isBefore } from 'date-fns'
import { formatDateToBE } from '../../../../../../../../../../../../../constants/dates'
import { getStartAdjustedDate } from '../helpers'

export const getIsMatchDateRange = (fetchedInventoryDateRanges, dateRange, additionalAvailableDays) => {
  return fetchedInventoryDateRanges.find(item => {
    const dateRangeDate = new Date(dateRange)

    // additionalAvailableDays - helps to check the available period which doesn't need to be fetched
    const startDate = additionalAvailableDays
      ? getStartAdjustedDate(item.date_from, additionalAvailableDays)
      : new Date(item.date_from)
    const startDateMatch =
      isBefore(startDate, dateRangeDate) || formatDateToBE(startDate) === formatDateToBE(dateRangeDate)

    const endDateMatch =
      isAfter(new Date(item.date_to), dateRangeDate) || item.date_to === formatDateToBE(endOfMonth(dateRangeDate))

    return startDateMatch && endDateMatch
  })
}
