import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import SkeletonForm from '../../../../../../../components/Form/Skeleton'
import LineItemFormContentWrapper from './LineItemFormContentWrapper'
import WarningMessage from '../../../../../../../features/components/Form/WarningMessage'

import { setMediaOrderUploadCreative } from '../../../../../../../modules/actions/mediaOrders'

import {
  lineItemsErrorSelector,
  lineItemsSelector,
  lineItemsWasLoadedSelector
} from '../../../../../../../modules/selectors/lineItems'

import useLoadLineItems from '../../useLoadLineItems'

const LineItemForm = ({ isExistingCampaignFlow, adAccountId, onSuccessSubmit, ...props }) => {
  const dispatch = useDispatch()

  const lineItems = useSelector(lineItemsSelector)
  const lineItemsWasLoaded = useSelector(lineItemsWasLoadedSelector)
  const lineItemsError = useSelector(lineItemsErrorSelector)

  const { isLineItemsLoading } = useLoadLineItems({ adAccountId, isExistingCampaignFlow })

  // advantage shopping campaign can have only one line item, so when we see that line items are not empty
  // we manually skip this form to ad form, and set line item data to media upload state
  // otherwise we render next form's layer - LineItemFormContentWrapper, where we have few extra requests
  useEffect(() => {
    if (lineItemsWasLoaded) {
      if (!!lineItems.length) {
        dispatch(setMediaOrderUploadCreative({ lineItem: lineItems[0] }))

        onSuccessSubmit()
      }
    }
  }, [dispatch, lineItems, lineItems.length, lineItemsWasLoaded, onSuccessSubmit])

  if (isLineItemsLoading) {
    return <SkeletonForm stepsLength={3} />
  }

  if (lineItemsError) {
    return (
      <WarningMessage
        title="Something went wrong"
        subTitle="Launch an off-network campaign"
        description="Sorry, something went wrong. Please try again later or contact your account manager."
      />
    )
  }

  return <LineItemFormContentWrapper adAccountId={adAccountId} onSuccessSubmit={onSuccessSubmit} {...props} />
}

LineItemForm.propTypes = {
  isExistingCampaignFlow: PropTypes.bool.isRequired,
  adAccountId: PropTypes.string.isRequired,
  onSuccessSubmit: PropTypes.func.isRequired
}

export default LineItemForm
