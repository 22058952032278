import { concat } from '../../helpers/common'

const MODULE_NAME = 'AGENCY_TEAM/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_AGENCY_MEMBERS = concat(MODULE_NAME, 'GET_AGENCY_MEMBERS')
export const GET_AGENCY_MEMBERS_SUCCESS = concat(MODULE_NAME, 'GET_AGENCY_MEMBERS_SUCCESS')
export const GET_AGENCY_MEMBERS_FAILURE = concat(MODULE_NAME, 'GET_AGENCY_MEMBERS_FAILURE')

export const GET_AGENCY_INVITES = concat(MODULE_NAME, 'GET_AGENCY_INVITES')
export const GET_AGENCY_INVITES_SUCCESS = concat(MODULE_NAME, 'GET_AGENCY_INVITES_SUCCESS')
export const GET_AGENCY_INVITES_FAILURE = concat(MODULE_NAME, 'GET_AGENCY_INVITES_FAILURE')

export const CREATE_AGENCY_INVITE = concat(MODULE_NAME, 'CREATE_AGENCY_INVITE')
export const CREATE_AGENCY_INVITE_SUCCESS = concat(MODULE_NAME, 'CREATE_AGENCY_INVITE_SUCCESS')
export const CREATE_AGENCY_INVITE_FAILURE = concat(MODULE_NAME, 'CREATE_AGENCY_INVITE_FAILURE')

export const ACCEPT_AGENCY_INVITE = concat(MODULE_NAME, 'ACCEPT_AGENCY_INVITE')
export const ACCEPT_AGENCY_INVITE_SUCCESS = concat(MODULE_NAME, 'ACCEPT_AGENCY_INVITE_SUCCESS')
export const ACCEPT_AGENCY_INVITE_FAILURE = concat(MODULE_NAME, 'ACCEPT_AGENCY_INVITE_FAILURE')

export const UPDATE_AGENCY_MEMBER = concat(MODULE_NAME, 'UPDATE_AGENCY_MEMBER')
export const UPDATE_AGENCY_MEMBER_SUCCESS = concat(MODULE_NAME, 'UPDATE_AGENCY_MEMBER_SUCCESS')
export const UPDATE_AGENCY_MEMBER_FAILURE = concat(MODULE_NAME, 'UPDATE_AGENCY_MEMBER_FAILURE')

export const UPDATE_AGENCY_MEMBER_STATUS = concat(MODULE_NAME, 'UPDATE_AGENCY_MEMBER_STATUS')
export const UPDATE_AGENCY_MEMBER_STATUS_SUCCESS = concat(MODULE_NAME, 'UPDATE_AGENCY_MEMBER_STATUS_SUCCESS')
export const UPDATE_AGENCY_MEMBER_STATUS_FAILURE = concat(MODULE_NAME, 'UPDATE_AGENCY_MEMBER_STATUS_FAILURE')

export const DELETE_AGENCY_INVITE = concat(MODULE_NAME, 'DELETE_AGENCY_INVITE')
export const DELETE_AGENCY_INVITE_SUCCESS = concat(MODULE_NAME, 'DELETE_AGENCY_INVITE_SUCCESS')
export const DELETE_AGENCY_INVITE_FAILURE = concat(MODULE_NAME, 'DELETE_AGENCY_INVITE_FAILURE')

export const RESEND_AGENCY_INVITE = concat(MODULE_NAME, 'RESEND_AGENCY_INVITE')
export const RESEND_AGENCY_INVITE_SUCCESS = concat(MODULE_NAME, 'RESEND_AGENCY_INVITE_SUCCESS')
export const RESEND_AGENCY_INVITE_FAILURE = concat(MODULE_NAME, 'RESEND_AGENCY_INVITE_FAILURE')

export const CLEAR_AGENCY_MEMBERS = concat(MODULE_NAME, 'CLEAR_AGENCY_MEMBERS')
export const CLEAR_AGENCY_INVITES = concat(MODULE_NAME, 'CLEAR_AGENCY_INVITES')
export const CLEAR_CREATE_AGENCY_INVITE = concat(MODULE_NAME, 'CLEAR_CREATE_AGENCY_INVITE')
export const CLEAR_ACCEPT_AGENCY_INVITE = concat(MODULE_NAME, 'CLEAR_ACCEPT_AGENCY_INVITE')
export const CLEAR_UPDATE_AGENCY_MEMBER = concat(MODULE_NAME, 'CLEAR_UPDATE_AGENCY_MEMBER')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

export function getAgencyMembers(params) {
  return { type: GET_AGENCY_MEMBERS, params }
}

export function getAgencyMembersSuccess(agencyMembersData) {
  return { type: GET_AGENCY_MEMBERS_SUCCESS, agencyMembersData }
}

export function getAgencyMembersFailure(error) {
  return { type: GET_AGENCY_MEMBERS_FAILURE, error }
}

export function getAgencyInvites(params) {
  return { type: GET_AGENCY_INVITES, params }
}

export function getAgencyInvitesSuccess(invitesData) {
  return { type: GET_AGENCY_INVITES_SUCCESS, invitesData }
}

export function getAgencyInvitesFailure(error) {
  return { type: GET_AGENCY_INVITES_FAILURE, error }
}

export function createAgencyInvite(inviteData) {
  return { type: CREATE_AGENCY_INVITE, inviteData }
}

export function createAgencyInviteSuccess(inviteData) {
  return { type: CREATE_AGENCY_INVITE_SUCCESS, inviteData }
}

export function createAgencyInviteFailure(error) {
  return { type: CREATE_AGENCY_INVITE_FAILURE, error }
}

export function acceptAgencyInvite(inviteData, submitLoginOnSuccess, removeInviteOnSuccess) {
  return { type: ACCEPT_AGENCY_INVITE, inviteData, submitLoginOnSuccess, removeInviteOnSuccess }
}

export function acceptAgencyInviteSuccess() {
  return { type: ACCEPT_AGENCY_INVITE_SUCCESS }
}

export function acceptAgencyInviteFailure(error) {
  return { type: ACCEPT_AGENCY_INVITE_FAILURE, error }
}

export function updateAgencyMember(id, data) {
  return { type: UPDATE_AGENCY_MEMBER, id, data }
}

export function updateAgencyMemberSuccess(updatedMemberData) {
  return { type: UPDATE_AGENCY_MEMBER_SUCCESS, updatedMemberData }
}

export function updateAgencyMemberFailure(error) {
  return { type: UPDATE_AGENCY_MEMBER_FAILURE, error }
}

export function updateAgencyMemberStatus(id, status) {
  return { type: UPDATE_AGENCY_MEMBER_STATUS, id, status }
}

export function updateAgencyMemberStatusSuccess(updatedMemberData) {
  return { type: UPDATE_AGENCY_MEMBER_STATUS_SUCCESS, updatedMemberData }
}

export function updateAgencyMemberStatusFailure(error) {
  return { type: UPDATE_AGENCY_MEMBER_STATUS_FAILURE, error }
}

export function deleteAgencyInvite(id, removeInviteOnSuccess) {
  return { type: DELETE_AGENCY_INVITE, id, removeInviteOnSuccess }
}

export function deleteAgencyInviteSuccess(id) {
  return { type: DELETE_AGENCY_INVITE_SUCCESS, id }
}

export function deleteAgencyInviteFailure(error) {
  return { type: DELETE_AGENCY_INVITE_FAILURE, error }
}

export function resendAgencyInvite(id) {
  return { type: RESEND_AGENCY_INVITE, id }
}

export function resendAgencyInviteSuccess() {
  return { type: RESEND_AGENCY_INVITE_SUCCESS }
}

export function resendAgencyInviteFailure(error) {
  return { type: RESEND_AGENCY_INVITE_FAILURE, error }
}

export function clearAgencyMembers() {
  return { type: CLEAR_AGENCY_MEMBERS }
}

export function clearAgencyInvites() {
  return { type: CLEAR_AGENCY_INVITES }
}

export function clearCreateAgencyInvite() {
  return { type: CLEAR_CREATE_AGENCY_INVITE }
}

export function clearUpdateAgencyMember() {
  return { type: CLEAR_UPDATE_AGENCY_MEMBER }
}

export function clearAcceptAgencyInvite() {
  return { type: CLEAR_ACCEPT_AGENCY_INVITE }
}
