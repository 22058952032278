import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { lineItemSelector } from '../../../../../../../modules/selectors/lineItems'

import { formatOptionsList } from '../../../../../../../features/formatters'

const InterestsPreview = () => {
  const { t } = useTranslation()

  const { interests } = useSelector(lineItemSelector)

  const isInterestsExists = interests && interests.length

  if (isInterestsExists) {
    return (
      <span>
        {t('Interested in: ') +
          formatOptionsList({
            list: interests,
            valueName: 'id',
            labelName: 'name'
          })
            .map(interest => interest.label)
            .join(', ')}
      </span>
    )
  }

  return null
}

export default InterestsPreview
