// START TIME
import { startTimeInitialValues } from '../../../../ReusableFormFields/StartTimeFields/fields'
// STOP_TIME
import { stopTimeInitialValues } from '../../../../ReusableFormFields/StopTimeFields/fields'
// SCHEDULE
import { scheduleInitialValues } from '../../../../ReusableFormFields/ScheduleFields/fields'
// GEOLOCATIONS
import { initialGeolocationsValues } from '../../../../ReusableFormFields/GeolocationsFields/fields'
// GENDER
import { GENDER_OPTION, GENDER_OPTION_ALL } from '../../../../ReusableFormFields/GenderFields/fields'
// INTERESTS
import { interestsInitialValues } from '../../../../ReusableFormFields/InterestsFields/fields'
import { OPTION_NO, OPTION_YES } from '../../../../../constants/forms'
import {
  AGE_OPTION,
  ageRangesInitialValues,
  ANYONE_OVER_18
} from '../../../../ReusableFormFields/AgeRangesFields/fields'
import { BUDGET_OPTION, BUDGET_LIFETIME, BUDGET_DAILY, LANGUAGES } from '../../../../Multiplatform/LineItemForms/fields'
import { frequencyInitialValues } from '../../../../ReusableFormFields/FrequencyFields/fields'
import { LANGUAGES_OPTION, SELECT_LANGUAGES } from '../../../../ReusableFormFields/LanguagesFields/fields'
import { ENGLISH_CODE } from '../../../../../constants/selectLists/languagesList'
import { REACH, TRAFFIC, VIDEO_VIEWS } from '../../../../../constants/campaigns'
import { HASHTAGS } from '../../../ReusableFormFields/TikTokHashtagsFields/fields'
import { customAudiencesInitialValues } from '../../../../ReusableFormFields/CustomAudiencesFields/fields'

// gender payload fields
export const GENDER_MALE = 'GENDER_MALE'
export const GENDER_FEMALE = 'GENDER_FEMALE'
export const GENDER_UNLIMITED = 'GENDER_UNLIMITED'

// CATEGORIES OF VIDEOS
export const TARGET_VIDEO_CATEGORIES_OPTION = 'target_video_categories_option'
export const WATCHED_OR_ENGAGED_OPTION = 'watched_or_engaged_option'
export const ENGAGED_OPTION = 'engaged_option'
export const VIDEO_CATEGORIES = 'video_categories'
// CATEGORIES OF ACCOUNTS
export const TARGET_ACCOUNT_CATEGORIES_OPTION = 'target_account_categories_option'
export const ACCOUNT_CATEGORIES = 'account_categories'
// HASHTAGS
export const HASHTAGS_OPTION = 'hashtags_option'

export const PLACEMENT_TIKTOK = 'PLACEMENT_TIKTOK'

// COMMENTS
export const ENABLE_COMMENTS_OPTION = 'enable_comments_option'
export const DO_NOT_ASK_ENABLE_COMMENTS_AGAIN = 'do_not_ask_enable_comments_again'

// PIXEL
export const PIXEL_ID = 'pixel_id'
export const PIXEL_EVENT = 'pixel_event'
// BID_PRICE
export const BID_PRICE_OPTION = 'bid_price_option'
export const BID_PRICE = 'big_price'

export const BID_TYPE_CUSTOM = 'BID_TYPE_CUSTOM'
export const BID_TYPE_NO_BID = 'BID_TYPE_NO_BID'

export const NAME = 'name'

export const pixelInitialValues = {
  [PIXEL_ID]: '',
  [PIXEL_EVENT]: ''
}

export const getBidPriceGoalText = (campaignObjective = '') => {
  switch (campaignObjective.toLowerCase()) {
    case VIDEO_VIEWS:
      return '6-second view'
    case REACH:
      return 'thousand impressions'
    case TRAFFIC:
      return 'click'
    default:
      return ''
  }
}

export const getInitialValues = (savedSettings, campaignBuyingType) => {
  return {
    [NAME]: '',
    [BUDGET_OPTION]: campaignBuyingType === 'BUDGET_MODE_DAY' ? BUDGET_DAILY : BUDGET_LIFETIME,
    [BUDGET_LIFETIME]: '',
    [BUDGET_DAILY]: '',
    // START TIME
    ...startTimeInitialValues,
    // STOP TIME
    ...stopTimeInitialValues,
    // SCHEDULE
    ...scheduleInitialValues,
    // GEOLOCATIONS
    ...initialGeolocationsValues,
    // LANGUAGES
    [LANGUAGES_OPTION]: SELECT_LANGUAGES,
    [LANGUAGES]: [
      {
        label: 'English',
        value: ENGLISH_CODE
      }
    ],
    // GENDER
    [GENDER_OPTION]: GENDER_OPTION_ALL,
    // AGES
    ...ageRangesInitialValues,
    [AGE_OPTION]: ANYONE_OVER_18,
    // INTERESTS
    ...interestsInitialValues,
    // CATEGORIES OF VIDEOS
    [TARGET_VIDEO_CATEGORIES_OPTION]: OPTION_NO,
    [VIDEO_CATEGORIES]: [],
    // CATEGORIES OF ACCOUNTS
    [TARGET_ACCOUNT_CATEGORIES_OPTION]: OPTION_NO,
    [ACCOUNT_CATEGORIES]: [],
    // HASHTAGS
    [HASHTAGS_OPTION]: OPTION_NO,
    [HASHTAGS]: [],
    // COMMENTS
    [ENABLE_COMMENTS_OPTION]: savedSettings[ENABLE_COMMENTS_OPTION] || OPTION_YES,
    [DO_NOT_ASK_ENABLE_COMMENTS_AGAIN]: false,
    // FREQUENCY
    ...frequencyInitialValues,
    // CUSTOM AUDIENCES
    ...customAudiencesInitialValues,
    // PIXEL
    ...pixelInitialValues,
    // BID PRICE
    [BID_PRICE_OPTION]: OPTION_NO,
    [BID_PRICE]: ''
  }
}
