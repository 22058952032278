import { createStyles } from '../../../../../../../../styles/helpers'

import { lightGrey2 } from '../../../../../../../../styles/const/colors'

export default createStyles(theme => ({
  fileDetailContainer: {
    borderColor: lightGrey2,
    cursor: 'pointer',

    '&:hover': {
      borderColor: theme.brandPrimary
    }
  },
  activeFileDetailContainer: {
    borderColor: theme.brandPrimary,
    borderWidth: 2
  }
}))
