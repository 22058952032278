import { createStyles } from '../../../../styles/helpers'

export default createStyles(theme => ({
  infoChip: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 8px',
    height: 21,
    fontSize: '12px',
    fontWeight: 400,
    color: ({ color }) => color || theme.brandPrimary,
    border: ({ color }) => `1px solid ${color || theme.brandPrimary}`,
    backgroundColor: ({ isTransparent }) => (isTransparent ? 'transparent' : 'white'),
    borderRadius: 2,
    textTransform: 'capitalize'
  }
}))
