import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ArcElement,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
  Legend
} from 'chart.js'
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100'

ChartJS.register(
  CategoryScale,
  Legend,
  LinearScale,
  LineController,
  ArcElement,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  ChartjsPluginStacked100
)
