import React, { useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import SelectPaginated from '../../../../../../../components/SelectPaginated'

import { MediaOrderFormContext } from '../../../../../../../forms/Multiplatform/MediaOrderForms/MediaOrderFormContext'

import { createJsonFromQueryString } from '../../../../../../../helpers/url'

import { getProductsService } from '../../../../../../../modules/services/mediaOrdersProducts'
import { productIsLoadingSelector } from '../../../../../../../modules/selectors/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../../../../modules/selectors/app'

import useStyles from './styles'
import { v4 as uuidv4 } from 'uuid'

const ProductSelection = ({ onAddProduct, selectedProductsIds, selectedSubCategory }) => {
  const classes = useStyles()

  const [isDataLoading, setIsDataLoading] = useState(false)
  const selectedProductIsLoading = useSelector(productIsLoadingSelector)
  const selfAccountControllerId = useSelector(selectedControllerIdSelector)

  const { currency } = useContext(MediaOrderFormContext)
  const currencyCode = currency?.code

  const formatProductOptions = useCallback(products => {
    if (products) {
      return products.map(product => ({
        ...product,
        // BE `fields` feature returns double underscored fields, so we need to replace them with single underscore
        // to match with the regular object data structure
        location_name: product.location__name,
        media_sub_category_name: product.media_sub_category__name,
        value: product.id,
        label: product.internal_id ? `${product.internal_id} - ${product.name}` : product.name
      }))
    } else {
      return []
    }
  }, [])

  const loadOptions = useCallback(
    async (search, loadedOptions, { next }) => {
      setIsDataLoading(true)
      const response = await getProductsService({
        search,
        ordering: 'name',
        controller: selfAccountControllerId,
        media_sub_category: selectedSubCategory,
        fields: [
          'id',
          'name',
          'internal_id',
          'location',
          'location__name',
          'creative_deadline_days',
          'period',
          'media_sub_category',
          'media_sub_category__name'
        ].join(','),
        in_stock: true,
        currency_code: currencyCode,
        limit: 100,
        status: 'active', // filter by active only(but currently for amendments we will fetch all)
        ...createJsonFromQueryString(`?${next.split('?')[1]}`)
      })
      const newProductsOptions = formatProductOptions(response.results)

      setIsDataLoading(false)

      return {
        options: newProductsOptions,
        hasMore: !!response.next,
        additional: {
          next: response.next
        }
      }
    },
    [selfAccountControllerId, selectedSubCategory, currencyCode, formatProductOptions]
  )

  const onChangeHandler = useCallback(
    selectedProductData => {
      onAddProduct(selectedProductData)
    },
    [onAddProduct]
  )

  const filtersKey = useMemo(() => {
    return selectedSubCategory + uuidv4()
  }, [selectedSubCategory])

  return (
    <SelectPaginated
      className={classes.productSelect}
      onChange={onChangeHandler}
      loadOptions={loadOptions}
      isLoading={isDataLoading}
      resetOptionsKey={filtersKey} // reset fetched data on category change
      placeholder="Select media product"
      autoComplete="off"
      portaled={true}
      selectedOptionsIds={selectedProductsIds}
      isDisabled={selectedProductIsLoading}
    />
  )
}

ProductSelection.propTypes = {
  onAddProduct: PropTypes.func.isRequired,
  selectedProductsIds: PropTypes.array
}

export default ProductSelection
