import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../components/Form'
import AdDetails from './AdDetails'
import DrawerHeadline from '../../../../../components/Drawer/DrawerHeadline'

import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'

import { clearCreateCampaign, createCampaign } from '../../../../../modules/actions/campaigns'
import {
  campaignCreateErrorSelector,
  campaignCreateIsLoadingSelector,
  campaignCreateWasCreatedSelector,
  createdCampaignDataSelector,
  createdCampaignIdSelector
} from '../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'

import { transformValuesToBE } from './formatters'
import { initialValues, validationSchema } from './fields'

import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

function CampaignGoogleCreateForm({ onSuccessCampaignCreate }) {
  const dispatch = useDispatch()

  const drawerFormClasses = useDrawerFormStyles()

  const createdCampaignId = useSelector(createdCampaignIdSelector)
  const createdCampaignData = useSelector(createdCampaignDataSelector)
  const campaignWasCreated = useSelector(campaignCreateWasCreatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const onSubmit = useCallback(
    values => {
      const campaignData = {
        campaignData: transformValuesToBE(values, selectedAdAccountId)
      }
      dispatch(createCampaign(campaignData))
    },
    [dispatch, selectedAdAccountId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const clearSubmitHandler = useCallback(() => {
    dispatch(clearCreateCampaign())
  }, [dispatch])

  const handleSuccessSubmit = useCallback(() => {
    onSuccessCampaignCreate(createdCampaignId, createdCampaignData)
  }, [createdCampaignId, createdCampaignData, onSuccessCampaignCreate])

  return (
    <div className={drawerFormClasses.formContainer}>
      <DrawerHeadline
        activeStepNumber={0}
        customStepsLength={3}
        title="Enter the campaign details"
        description="Please provide the following details for the new Google Ads Performance Max campaign"
      />
      <Form
        formName="campaignGoogleAdDetails"
        formik={purifiedFormik}
        submitText="Save and continue"
        successSubmit={createdCampaignId && campaignWasCreated}
        onSuccessSubmit={handleSuccessSubmit}
        clearHandler={clearSubmitHandler}
        errorSelector={campaignCreateErrorSelector}
        isLoadingSelector={campaignCreateIsLoadingSelector}
      >
        <AdDetails formik={purifiedFormik} />
      </Form>
    </div>
  )
}

export default CampaignGoogleCreateForm
