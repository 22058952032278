import { call, put, all, takeEvery } from 'redux-saga/effects'

import {
  GET_CHOICES_GEOLOCATIONS,
  getChoicesGeolocationsSuccess,
  getChoicesGeolocationsFailure,
  GET_CHOICES_COUNTRIES,
  getChoicesCountriesSuccess,
  getChoicesCountriesFailure,
  GET_CHOICES_INTERESTS,
  getChoicesInterestsSuccess,
  getChoicesInterestsFailure,
  GET_CHOICES_CATEGORIES,
  getChoicesCategoriesSuccess,
  getChoicesCategoriesFailure,
  GET_CHOICES_LANGUAGES,
  getChoicesLanguagesSuccess,
  getChoicesLanguagesFailure,
  GET_CHOICES_PIXELS,
  getChoicesPixelsSuccess,
  getChoicesPixelsFailure,
  GET_CHOICES_CTA,
  getChoicesCTASuccess,
  getChoicesCTAFailure,
  getChoicesCTAByGoalSuccess,
  getChoicesCTAByGoalFailure,
  GET_CHOICES_CTA_BY_GOAL
} from '../actions/choices'

import {
  getChoicesGeolocationsService,
  getChoicesCountriesService,
  getChoicesInterestsService,
  getChoicesCategoriesService,
  getChoicesLanguagesService,
  getChoicesPixelsService,
  getChoicesCTAService
} from '../services/choices'

function* choicesWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_CHOICES_GEOLOCATIONS, getChoicesGeolocationsWatcher),
    takeEvery(GET_CHOICES_COUNTRIES, getChoicesCountriesWatcher),
    takeEvery(GET_CHOICES_INTERESTS, getChoicesInterestsWatcher),
    takeEvery(GET_CHOICES_CATEGORIES, getChoicesCategoriesWatcher),
    takeEvery(GET_CHOICES_LANGUAGES, getChoicesLanguagesWatcher),
    takeEvery(GET_CHOICES_PIXELS, getChoicesPixelsWatcher),
    takeEvery(GET_CHOICES_CTA, getChoicesCTAWatcher),
    takeEvery(GET_CHOICES_CTA_BY_GOAL, getChoicesCTAByGoalWatcher)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getChoicesGeolocationsWatcher({ params }) {
  try {
    const response = yield call(getChoicesGeolocationsService, params)
    yield put(getChoicesGeolocationsSuccess(response))
  } catch (e) {
    yield put(getChoicesGeolocationsFailure(e))
  }
}

function* getChoicesCountriesWatcher() {
  try {
    const response = yield call(getChoicesCountriesService)
    yield put(getChoicesCountriesSuccess(response.results))
  } catch (e) {
    yield put(getChoicesCountriesFailure(e))
  }
}

function* getChoicesInterestsWatcher({ params }) {
  try {
    const response = yield call(getChoicesInterestsService, params)
    yield put(getChoicesInterestsSuccess(response))
  } catch (e) {
    yield put(getChoicesInterestsFailure(e))
  }
}

function* getChoicesCategoriesWatcher({ params }) {
  try {
    const response = yield call(getChoicesCategoriesService, params)
    yield put(getChoicesCategoriesSuccess(response))
  } catch (e) {
    yield put(getChoicesCategoriesFailure(e))
  }
}

function* getChoicesLanguagesWatcher({ params }) {
  try {
    const response = yield call(getChoicesLanguagesService, params)
    yield put(getChoicesLanguagesSuccess(response))
  } catch (e) {
    yield put(getChoicesLanguagesFailure(e))
  }
}

function* getChoicesPixelsWatcher({ params }) {
  try {
    const response = yield call(getChoicesPixelsService, params)
    yield put(getChoicesPixelsSuccess(response))
  } catch (e) {
    yield put(getChoicesPixelsFailure(e))
  }
}

function* getChoicesCTAWatcher({ params }) {
  try {
    const response = yield call(getChoicesCTAService, params)
    yield put(getChoicesCTASuccess(response))
  } catch (e) {
    yield put(getChoicesCTAFailure(e))
  }
}

function* getChoicesCTAByGoalWatcher({ params }) {
  const goal = params?.goals
  try {
    const response = yield call(getChoicesCTAService, params)
    yield put(getChoicesCTAByGoalSuccess(response, goal))
  } catch (e) {
    yield put(getChoicesCTAByGoalFailure(e))
  }
}

export default choicesWatcher
