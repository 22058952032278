import React from 'react'
import { useTranslation } from 'react-i18next'

import RadioBlock from '../../../../../components/Form/RadioBlock'

import { ALL_PASSENGERS, ECONOMY_PASSENGERS, PASSENGER_STATUS_OPTION, PREMIUM_PASSENGERS } from '../fields'

import useStyles from '../../../../../styles/common/stepForms'

const PassengerStatusStep = ({ formik }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { values, setFieldValue } = formik

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create an audience')}</div>
      <div className={classes.stepTitle}>{t('Do you want to target by passenger status (e.g. platinum)?')}</div>
      <div className={classes.stepBody}>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_all_passengers"
          name={PASSENGER_STATUS_OPTION}
          value={ALL_PASSENGERS}
          selectedValue={values[PASSENGER_STATUS_OPTION]}
          label={t('No, target all passenger statuses')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_premium_passengers"
          name={PASSENGER_STATUS_OPTION}
          value={PREMIUM_PASSENGERS}
          selectedValue={values[PASSENGER_STATUS_OPTION]}
          label={t('Yes, only target premium passengers')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_economy_passengers"
          name={PASSENGER_STATUS_OPTION}
          value={ECONOMY_PASSENGERS}
          selectedValue={values[PASSENGER_STATUS_OPTION]}
          label={t('Yes, only target economy passengers')}
        />
      </div>
    </>
  )
}

export default PassengerStatusStep
