import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ToggleControllable from './ToggleControllable'

function Toggle({ isInitiallyEnabled, toggleColor, onToggle, triggerUpdate, updatedState }) {
  const [isEnabled, setIsEnabled] = useState(isInitiallyEnabled)

  const handleToggle = useCallback(() => {
    setIsEnabled(prevState => {
      onToggle(!prevState)

      return !prevState
    })
  }, [onToggle])

  useEffect(() => {
    // outside toggle trigger to update:
    if (triggerUpdate) {
      setIsEnabled(updatedState)
    }
  }, [triggerUpdate, updatedState])

  return <ToggleControllable onToggle={handleToggle} toggleColor={toggleColor} isEnabled={isEnabled} />
}

Toggle.propTypes = {
  isInitiallyEnabled: PropTypes.bool,
  toggleColor: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  // to be able to reset toggle the 2 props below are needed
  // we use triggerUpdate to trigger the hook and updatedState to set the new state value
  triggerUpdate: PropTypes.bool,
  updatedState: PropTypes.bool
}

export default Toggle
