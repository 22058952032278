import * as Yup from 'yup'
import { ASSET_ID, FILE_NAME } from '../../../../Google/AdForms/fields'
import { MEDIA_FORMAT, MEDIA_SIZE } from '../../../../Facebook/AdForms/fields'
import { CLICK_THROUGH_LINK, MEDIA_ITEMS } from './fields'
import { MEDIA_HEIGHT, MEDIA_WIDTH } from '../../../../ReusableFormFields/AdFileUpload/fields'
import { urlValidation } from '../../../../../features/validations/other'

export const adSizes = [
  // type: 'Square and rectangle',
  { width: 250, height: 250 },
  { width: 300, height: 250 },
  { width: 320, height: 320 },
  { width: 336, height: 280 },
  // type: 'Skyscraper',
  { width: 120, height: 600 },
  { width: 160, height: 600 },
  { width: 300, height: 600 },
  { width: 300, height: 1050 },
  // type: 'Leaderboard',
  { width: 468, height: 60 },
  { width: 728, height: 90 },
  { width: 800, height: 250 },
  { width: 970, height: 90 },
  { width: 970, height: 250 },
  // type: 'Mobile',
  { width: 300, height: 50 },
  { width: 300, height: 100 },
  { width: 320, height: 50 },
  { width: 320, height: 100 },
  { width: 320, height: 480 },
  { width: 360, height: 592 },
  { width: 360, height: 640 },
  { width: 375, height: 667 }
]

// insert to dv360imageFormats .jpg, .jpeg, .gif, and .png
export const dv360imageFormats = ['jpeg', 'gif', 'png', 'jpg']

// Custom validation function
const isValidSize = (width, height) => {
  return adSizes.some(size => size.width === width && size.height === height)
}

export const dv360FileShape = {
  [MEDIA_SIZE]: Yup.number().max(10000000, 'The maximum supported image file size is 10 MB'),
  [MEDIA_FORMAT]: Yup.string().test('validFormat', 'Allowed image types are: .jpg, .jpeg, .gif, and .png', format => {
    return dv360imageFormats.includes(format)
  })
}

export const mediaItemSchema = Yup.object().shape({
  // FILE
  [ASSET_ID]: Yup.string()
    .nullable()
    .when(FILE_NAME, {
      is: val => !!val,
      then: () => Yup.string().nullable().required('Please wait until image upload is complete')
    })
})

// Yup validation schema
export const dv360AdSizeValidationSchema = Yup.object()
  .shape(dv360FileShape)
  .test('validSize', 'Invalid image dimensions, please check the accessible image sizes list.', ad => {
    return isValidSize(ad[MEDIA_WIDTH], ad[MEDIA_HEIGHT])
  })

export const dv360MediaItemsValidationSchema = Yup.array()
  .of(mediaItemSchema)
  .min(1, 'Please select at least one image')
  .test('firstAdFile', 'Please select at least one image', function (ads) {
    // Represent error for the first media upload element if first element has no ASSET_ID
    const firstAd = ads[0]
    if (!firstAd[ASSET_ID]) {
      // If the ASSET_ID field is missing or empty for first media add an error to the first element of the array
      return this.createError({
        path: `${MEDIA_ITEMS}[0].${ASSET_ID}`,
        message: 'Please select at least one image'
      })
    } else {
      // Pass validation if the first media has ASSET ID
      return true
    }
  })
export const getValidationSchema = ({ showProductSelection }) => {
  return Yup.object({
    [MEDIA_ITEMS]: dv360MediaItemsValidationSchema,
    // urlValidation is not needed for dropdown
    [CLICK_THROUGH_LINK]: showProductSelection ? Yup.string().required('Link required') : urlValidation
  })
}
