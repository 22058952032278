import React from 'react'

import Tabs from '../../../features/components/Tabs'
import BudgetField from './BudgetField'

import { BUDGET_OPTION } from '../CampaignForms/fields'
import { budgetOptions } from './fields'

import useStyles from './styles'

const BudgetTabs = ({ formik }) => {
  const classes = useStyles()

  const { values, setFieldValue } = formik

  return (
    <>
      <Tabs
        options={budgetOptions}
        selectedValue={values[BUDGET_OPTION]}
        onSelectValue={value => setFieldValue(BUDGET_OPTION, value)}
        className={classes.budgetOptionsTabs}
      />
      <BudgetField formik={formik} />
    </>
  )
}

export default BudgetTabs
