import { createStyles } from '../../../styles/helpers'
import { zIndexLoader } from '../../../styles/const/common'

export default createStyles({
  screensaver: {
    // don't cover the Header when fixed
    position: isFixed => (isFixed ? 'fixed' : 'absolute'),
    top: isFixed => (isFixed ? 50 : 0),
    left: 0,
    minWidth: '100vW',
    minHeight: isFixed => (isFixed ? 'calc(100vh - 50px)' : '100vh'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    zIndex: zIndexLoader,

    '& svg': {
      width: 120,
      height: 'auto'
    }
  }
})
