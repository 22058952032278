import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { ReactComponent as ReportIcon } from '../../../assets/icons/upload-file-icon.svg'

import { showToasts } from '../../../helpers/toasts'
import { formatFileMaxSizeErrorMessage } from './helpers'

import useStyles from './styles'

export const NAME = 'name'

function DropFileUploader({
  accept,
  multiple,
  uploadText,
  uploadActiveText,
  containerClassName,
  onUpload,
  rejectFileMessage = 'Sorry, that file type is not supported. Please upload an image or video file',
  maxSize = Infinity
}) {
  const { t } = useTranslation()
  const classes = useStyles()

  const onDropAccepted = useCallback(
    acceptedFiles => {
      onUpload(multiple ? acceptedFiles : acceptedFiles[0])
    },
    [multiple, onUpload]
  )

  const convertedAccept = useMemo(() => {
    if (typeof accept === 'string') {
      // by default we pass accept as string with coma separated types for example image/jpeg, image/png
      // but Dropzone supports only object, so these should be converted
      const typesArray = accept.split(',').map(type => type.trim())

      // return object with such structure example: { 'image/jpeg': [], 'image/png': []}
      return typesArray.reduce((acc, type) => {
        acc[type] = []
        return acc
      }, {})
    } else {
      return accept
    }
  }, [accept])

  const { getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept, fileRejections } = useDropzone({
    accept: convertedAccept,
    multiple,
    onDropAccepted,
    maxSize,
    useFsAccessApi: false
  })

  const className = classnames(classes.baseStyle, {
    [classes.activeStyle]: isDragActive,
    [classes.acceptStyle]: isDragAccept,
    [classes.rejectStyle]: isDragReject || !!fileRejections.length
  })

  useEffect(() => {
    // show error toast when file was rejected
    if (!!fileRejections.length) {
      const errorMessage = fileRejections[0]?.errors[0]?.message

      const formattedFileSizeMessage = formatFileMaxSizeErrorMessage(errorMessage)

      showToasts({
        type: 'error',
        message: formattedFileSizeMessage || rejectFileMessage
      })
    }
  }, [fileRejections, rejectFileMessage])

  return (
    <div className={classnames(classes.container, containerClassName)}>
      <div {...getRootProps({ className })}>
        <input {...getInputProps()} />
        <div className={classes.dragInfo}>
          <ReportIcon className={classes.icon} />
          {isDragActive ? (
            <div className={classes.actionText}>{uploadActiveText || t('Drop file here ...')}</div>
          ) : (
            <>
              <div className={classes.actionText}>{uploadText || t('Drag and drop file')}</div>
              <div className={classes.clickHereText}>
                {t('or')} <u>{t('click here')}</u>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

DropFileUploader.propTypes = {
  accept: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  multiple: PropTypes.bool,
  uploadText: PropTypes.string,
  rejectFileMessage: PropTypes.string,
  uploadActiveText: PropTypes.string,
  containerClassName: PropTypes.string,
  onUpload: PropTypes.func
}

export default DropFileUploader
