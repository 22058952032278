import React from 'react'

import Field from '../../../../../../../../components/Form/Field'

import {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_UNLIMITED
} from '../../../../../LineItemTikTokCreate/LineItemTikTokCreateForm/fields'
import { GENDER_OPTION } from '../../../../../../../ReusableFormFields/GenderFields/fields'

import useDrawerFormStyles from '../../../../../../../../styles/common/drawerForms'

const tikTokGenderOptions = [
  { value: GENDER_MALE, label: 'Only males' },
  { value: GENDER_FEMALE, label: 'Only females' },
  { value: GENDER_UNLIMITED, label: 'All' }
]

const GenderContent = ({ formik }) => {
  const drawerFormClasses = useDrawerFormStyles()

  return (
    <div className={drawerFormClasses.subSection}>
      <h4 className={drawerFormClasses.sectionSubtitle}>Gender</h4>
      <Field formik={formik} name={GENDER_OPTION} label="Gender" options={tikTokGenderOptions} isSearchable={false} />
    </div>
  )
}

export default GenderContent
