import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Field from '../../../../../components/Form/Field'
import FileDetailsPreview from '../../../../../components/FileDetailsPreview'

import { FILE_NAME, FILE_URL } from '../../../../../constants/files'
import { ALT_TEXT } from '../../fields'
import { IMAGES } from '../../../../../features/components/Form/SortableUploadImagesList/fields'

import useStyles from './styles'

const ImagePreviewWithAltText = ({ formik, image, index, handleMediaFileRemove }) => {
  const classes = useStyles()

  const altTextFieldName = `${IMAGES}[${index}][${ALT_TEXT}]`

  return (
    <div className={classes.imageWithAltTextContainer}>
      <FileDetailsPreview
        className={classnames(classes.filePreviewWithAltText)}
        fileName={image[FILE_NAME]}
        previewUrl={image[FILE_URL]}
        allowDelete={!!image[FILE_URL]}
        filePreviewType={'image'}
        removeFile={handleMediaFileRemove}
      />
      <div className={classes.altTextFieldContainer}>
        <Field name={altTextFieldName} formik={formik} placeholder={'Title'} className={classes.altTextField} />
      </div>
    </div>
  )
}

ImagePreviewWithAltText.propTypes = {
  formik: PropTypes.object,
  image: PropTypes.object,
  index: PropTypes.number,
  handleMediaFileRemove: PropTypes.func
}

export default ImagePreviewWithAltText
