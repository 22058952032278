import { addDays, subDays } from 'date-fns'
import { useCallback, useMemo, useRef } from 'react'

function calculateFetchPeriod(oldDateRange, nextDateRange) {
  let startDate = null
  let endDate = null

  // Don't fetch already fetched dates, example:
  // initial date range: date_start: '01.01.2024' date_end: '01.06.2024'
  // New date range: date_start: '01.10.2023' date_end: '01.02.2024'
  // In this case new data should be fetched only for the different period:
  // date_start: '01.10.2023' date_end: '31.12.2023'

  if (nextDateRange.startDate < oldDateRange.startDate && nextDateRange.endDate > oldDateRange.endDate) {
    // New date range starts earlier and ends later than the old date range
    startDate = nextDateRange.startDate
    endDate = nextDateRange.endDate
  } else if (nextDateRange.startDate < oldDateRange.startDate) {
    // New date range starts earlier than the old date range
    startDate = nextDateRange.startDate
    endDate = subDays(oldDateRange.startDate, 1) // exclude the day already fetched
  } else if (nextDateRange.endDate > oldDateRange.endDate) {
    // New date range ends later than the old date range
    startDate = addDays(oldDateRange.endDate, 1)
    endDate = nextDateRange.endDate
  } else if (nextDateRange.startDate > oldDateRange.endDate || nextDateRange.endDate < oldDateRange.startDate) {
    // New date range is completely outside the old date range
    startDate = nextDateRange.startDate
    endDate = nextDateRange.endDate
  }

  return { startDate, endDate }
}

function useFetchedDateRange(initialRange) {
  const fetchedDateRange = useRef({
    startDate: initialRange.startDate,
    endDate: initialRange.endDate
  })

  const checkAndFetchData = useCallback((newStartDate, newEndDate) => {
    let fetchedDateRangeData = fetchedDateRange.current
    let shouldFetch = false
    let fetchStartDate = newStartDate
    let fetchEndDate = newEndDate

    // If the new start date is earlier than the fetched start date
    if (newStartDate < fetchedDateRangeData.startDate) {
      shouldFetch = true
      fetchEndDate = fetchedDateRangeData.startDate
    }

    // If the new end date is later than the fetched end date
    if (newEndDate > fetchedDateRangeData.endDate) {
      shouldFetch = true
      fetchStartDate = fetchedDateRangeData.endDate
    }

    if (shouldFetch) {
      // Fetch data for the range fetchStartDate to fetchEndDate
      // Update the fetchedDateRangeData
      fetchedDateRange.current = {
        startDate: Math.min(fetchedDateRangeData.startDate, fetchStartDate),
        endDate: Math.max(fetchedDateRangeData.endDate, fetchEndDate)
      }
      return {
        shouldFetch: true,
        datesToFetch: calculateFetchPeriod(fetchedDateRangeData, {
          startDate: newStartDate,
          endDate: newEndDate
        })
      }
    } else {
      // No need to fetch data
      return { shouldFetch: false }
    }
  }, [])

  const resetFetchedData = useCallback(dateRange => {
    fetchedDateRange.current = dateRange
  }, [])

  return useMemo(() => ({ checkAndFetchData, resetFetchedData }), [checkAndFetchData, resetFetchedData])
}

export default useFetchedDateRange
