import * as Yup from 'yup'
import {
  SPECIFIC_CAMPAIGN_MARGIN,
  SPECIFIC_CAMPAIGN,
  SPECIFIC_CAMPAIGN_PLATFORM,
  SPECIFIC_MARGIN_OPTION
} from '../../fields'

import { OPTION_YES } from '../../../../../../constants/forms'
import { marginFeeValidation } from '../../../../../../forms/ReusableFormFields/MarginFields/MarginFee/validation'

export const storiesItemShape = Yup.object({})

export const validationSchema = Yup.object({
  ...marginFeeValidation,
  [SPECIFIC_CAMPAIGN_PLATFORM]: Yup.string().required('Platform Required'),
  [SPECIFIC_CAMPAIGN]: Yup.string().required('Campaign Id Required'),
  [SPECIFIC_CAMPAIGN_MARGIN]: Yup.number().when(SPECIFIC_MARGIN_OPTION, {
    is: OPTION_YES,
    then: () => Yup.number().required('Margin Required').max(100, 'Maximum number of margin - 100%')
  })
})
