import React from 'react'
import PropTypes from 'prop-types'

import Select from '../Select'

const PseudoSelect = ({ isOpen, ...props }) => {
  // component recreates UI of Select component but not functional it self
  return (
    <Select
      pseudoOpened={isOpen}
      formatValue={false}
      isSearchable={false}
      options={[]}
      usePortal
      // PseudoSelect is used as triggerElement for SelectDropdown - so it should not be opened,
      // instead dropdown body will be opened
      menuIsOpen={false}
      hidePlaceholderOnFocus={false}
      {...props}
    />
  )
}

PseudoSelect.propTypes = {
  // isOpen used for representing UI layer of Select(like dropdownIndicator position)
  isOpen: PropTypes.bool
}

export default PseudoSelect
