import React, { forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'react-jss'

import { Bar } from 'react-chartjs-2'
// import graphs initial setting
import '../index'
import { useGraph } from '../hooks'

import getReusableGraphOptions from '../reusableGraphOptions'

const BigBarGraph = forwardRef((props, ref) => {
  const { data, labels, className, graphColor, format } = props

  const theme = useTheme()
  useGraph()

  const graphData = {
    labels,
    datasets: [
      {
        data: data,
        color: theme.brandPrimary,
        backgroundColor: graphColor || theme.brandPrimary,
        borderColor: graphColor || theme.brandPrimary,
        borderRadius: 4,
        borderSkipped: false,
        maxBarThickness: 34
      }
    ]
  }

  const graphOptions = useMemo(
    () => ({
      ...getReusableGraphOptions({ format, theme, graphColor }),
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false
          },
          ticks: {
            color: graphColor || theme.brandPrimary,
            font: {
              size: 15
            }
          }
        },
        y: {
          ...getReusableGraphOptions({ format, theme, graphColor }).scales.y
        }
      }
    }),
    [format, theme, graphColor]
  )

  return (
    <div className={className}>
      <Bar type="bar" data={graphData} options={graphOptions} ref={ref} />
    </div>
  )
})

BigBarGraph.propTypes = {
  data: PropTypes.array.isRequired,
  labels: PropTypes.array,
  className: PropTypes.string,
  graphColor: PropTypes.string,
  format: PropTypes.string
}

export default BigBarGraph
