import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { getIn } from 'formik'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import BookedMediaFilesLibrary from './BookedMediaFilesLibrary'
import RadioBlock from '../../../../../../../../components/Form/RadioBlock'
import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import FileRequirementsInfoBlock from '../FileRequirementsInfoBlock'
import SingleFileUploadWithValidation from './SingleFileUploadWithValidation'
import Checkbox from '../../../../../../../../components/Form/Checkbox'

import { isUserControllerSelector } from '../../../../../../../../modules/selectors/app'

import { getFileValidationSchema } from '../../../validation'

import {
  FILE_NAME,
  FILE_SELECT_EXISTING,
  FILE_UPLOAD,
  FILE_UPLOAD_OPTION,
  FILE_URL,
  FILE_SELECTED_URL,
  initialImageFileValues,
  OVERRIDE_VALIDATION
} from '../../../fields'

function UploadBookedMediaFile({ formik, fileFormats, fileRequirements }) {
  const { t } = useTranslation()

  const { values, setFieldValue, setValues, errors, touched } = formik

  const isUserController = useSelector(isUserControllerSelector)

  const uploadError = getIn(errors, FILE_URL)
  const uploadTouched = getIn(touched, FILE_URL)
  const uploadedFileMissingError = uploadTouched && uploadError
  const selectedFileError = getIn(errors, FILE_SELECTED_URL)
  const selectedFileTouched = getIn(touched, FILE_SELECTED_URL)

  const handleMediaUploaded = useCallback(
    uploadedFile => {
      setFieldValue(FILE_NAME, uploadedFile.name)
      setFieldValue(FILE_URL, uploadedFile.url)
    },
    [setFieldValue]
  )

  const handleMediaFileRemove = useCallback(() => {
    setValues({ ...values, ...initialImageFileValues })
  }, [setValues, values])

  const validationSchemaForFile = useMemo(() => {
    if (values[OVERRIDE_VALIDATION]) {
      // Controller can see checkbox to override validation
      return Yup.object().shape({})
    } else {
      return getFileValidationSchema(fileRequirements)
    }
  }, [values, fileRequirements])

  const onCheckboxCheck = useCallback(() => {
    setFieldValue(OVERRIDE_VALIDATION, !values[OVERRIDE_VALIDATION])
  }, [setFieldValue, values])

  return (
    <>
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_media_file_upload_new"
        name={FILE_UPLOAD_OPTION}
        value={FILE_UPLOAD}
        selectedValue={values[FILE_UPLOAD_OPTION]}
        label={t('Upload file from my device')}
      >
        <FieldRow description={<FileRequirementsInfoBlock fileRequirements={fileRequirements} />}>
          <SingleFileUploadWithValidation
            fileName={values[FILE_NAME]}
            fileURL={values[FILE_URL]}
            onFileUploaded={handleMediaUploaded}
            onFileRemove={handleMediaFileRemove}
            error={uploadedFileMissingError}
            accept="*"
            maxSize={Infinity}
            isPrivateUpload
            values={values}
            fileValidationSchema={validationSchemaForFile}
          />
          {isUserController && (
            <Checkbox
              id={`checkbox_${OVERRIDE_VALIDATION}`}
              title="Override validation"
              isLarge
              checked={values[OVERRIDE_VALIDATION]}
              onCheck={onCheckboxCheck}
            />
          )}
        </FieldRow>
      </RadioBlock>
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_media_file_upload_existed"
        name={FILE_UPLOAD_OPTION}
        value={FILE_SELECT_EXISTING}
        selectedValue={values[FILE_UPLOAD_OPTION]}
        label={t('Select from previously uploaded files')}
        touched={selectedFileTouched}
        error={selectedFileError}
      >
        <BookedMediaFilesLibrary values={values} setFieldValue={setFieldValue} fileFormats={fileFormats} />
      </RadioBlock>
    </>
  )
}

UploadBookedMediaFile.propTypes = {
  fileFormats: PropTypes.array
}

export default UploadBookedMediaFile
