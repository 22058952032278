import update from 'immutability-helper'
import { insertInSortedArray, updateItem } from '../../helpers/modules/reducerHelpers'

import {
  CLEAR_CREATE_AGENCY,
  CLEAR_GET_AGENCIES,
  CLEAR_GET_AGENCY,
  CLEAR_UPDATE_AGENCY,
  CREATE_AGENCY,
  CREATE_AGENCY_FAILURE,
  CREATE_AGENCY_SUCCESS,
  GET_AGENCIES,
  GET_AGENCIES_FAILURE,
  GET_AGENCIES_SUCCESS,
  GET_AGENCY,
  GET_AGENCY_FAILURE,
  GET_AGENCY_SUCCESS,
  UPDATE_AGENCY,
  UPDATE_AGENCY_FAILURE,
  UPDATE_AGENCY_SUCCESS
} from '../actions/agencies'

import {
  INITIAL_CREATE_REDUCER,
  INITIAL_GET_REDUCER,
  INITIAL_GET_RESULTS_PAGING_REDUCER,
  INITIAL_UPDATE_REDUCER
} from '../../constants/reducer'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  agency: INITIAL_GET_REDUCER,
  agencies: INITIAL_GET_RESULTS_PAGING_REDUCER,
  createAgency: INITIAL_CREATE_REDUCER,
  updateAgency: INITIAL_UPDATE_REDUCER
}

export default function agencies(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // Get agencies
    case GET_AGENCIES:
      return update(state, {
        agencies: { $merge: { isLoading: true } }
      })
    case GET_AGENCIES_SUCCESS:
      return update(state, {
        agencies: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: {
              next: action.data?.next
            }
          },
          results: {
            $push: action.data.results
          }
        }
      })
    case GET_AGENCIES_FAILURE:
      return update(state, {
        agencies: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_AGENCIES:
      return update(state, {
        agencies: { $set: initialState.agencies }
      })

    // Create agency
    case CREATE_AGENCY:
      return update(state, {
        createAgency: { $merge: { isLoading: true } }
      })

    case CREATE_AGENCY_SUCCESS:
      // This is a helper function to insert an item in a sorted array.
      const newAgency = action.agencyData
      const newAgencies = insertInSortedArray([...state.agencies.results], newAgency, (a, b) =>
        a.name.localeCompare(b.name)
      )

      return update(state, {
        createAgency: {
          $merge: {
            isLoading: false,
            wasCreated: true
          }
        },
        agencies: {
          results: {
            $set: newAgencies
          }
        }
      })

    case CREATE_AGENCY_FAILURE:
      return update(state, {
        agencies: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_AGENCY:
      return update(state, {
        createAgency: { $set: initialState.createAgency }
      })

    // Update agency
    case UPDATE_AGENCY:
      return update(state, {
        updateAgency: { $merge: { isLoading: true } }
      })
    case UPDATE_AGENCY_SUCCESS:
      return update(state, {
        updateAgency: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            data: action.data
          }
        },
        agencies: {
          results: {
            $apply: items => updateItem(items, action.data)
          }
        }
      })
    case UPDATE_AGENCY_FAILURE:
      return update(state, {
        updateAgency: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_AGENCY:
      return update(state, {
        updateAgency: { $set: initialState.updateAgency }
      })

    // Get agency
    case GET_AGENCY:
      return update(state, {
        agency: { $merge: { isLoading: true } }
      })
    case GET_AGENCY_SUCCESS:
      return update(state, {
        agency: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        }
      })
    case GET_AGENCY_FAILURE:
      return update(state, {
        agency: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_AGENCY:
      return update(state, {
        agency: { $set: initialState.agency }
      })

    default:
      return state
  }
}
