import { createStyles } from '../../../../../styles/helpers'

import { darkChipGreen, darkGrey, lighterGrey, lightGreen } from '../../../../../styles/const/colors'
import { card, cardBorderColor } from '../../../../../styles/common'
import { transitionShort } from '../../../../../styles/const/common'

const useStyles = createStyles(theme => ({
  fileCard: {
    extend: card(theme),
    width: 145,
    borderColor: approved => approved && darkChipGreen
  },
  fileName: {
    minHeight: '14px',
    fontSize: '12px',
    fontWeight: '400'
  },
  uploadFileBtn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: '5px',
    color: darkGrey,
    padding: 10,
    cursor: 'pointer',
    width: '100%',
    height: '100%',

    '&:hover': {
      outline: `1px solid ${theme.brandPrimary}`,
      '& .icon': {
        color: theme.brandPrimary
      }
    }
  },
  uploadIcon: {
    fontSize: '22px',
    transition: transitionShort
  },
  cta: {
    width: '80px',
    margin: '0 auto',
    fontSize: '10px'
  },
  fileDetails: {
    padding: 8,
    backgroundColor: approved => (approved ? lightGreen : lighterGrey),
    borderTop: approved => `1px solid ${approved ? darkChipGreen : cardBorderColor}`
  },
  statusChip: {
    marginTop: 8,
    textTransform: 'none'
  },
  fileManage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 80
  }
}))

export default useStyles
