import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { setStep } from '../../../../modules/actions/forms'
import { ReactComponent as CheckedCircle } from '../../../../assets/icons/checked-circle.svg'

import useStyles from './styles'

const FormProgress = ({ items }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const progressItemClickHandler = useCallback(
    (item, index) => {
      if (item.isSuccess) {
        dispatch(setStep(index))
      }
    },
    [dispatch]
  )

  return items.map((item, index) => (
    <div
      key={index}
      onClick={() => progressItemClickHandler(item, index)}
      className={classnames(classes.item, { active: item.isActive, success: item.isSuccess })}
    >
      {item.isSuccess ? (
        <CheckedCircle className={classes.checkIcon} />
      ) : (
        <div className={classes.number}>{index + 1}</div>
      )}
      <div>{t(item.label)}</div>
    </div>
  ))
}

FormProgress.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      isSuccess: PropTypes.bool.isRequired
    })
  ).isRequired
}

export default FormProgress
