import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import useStyles from './styles'

export default function BackButton({ onStepBack, activeStep, className, showButton }) {
  const { t } = useTranslation()
  const classes = useStyles({ activeStep })

  const showBackButton = activeStep !== 0 || showButton

  return (
    <button
      type="button"
      className={classnames(classes.backBtn, className)}
      onClick={onStepBack}
      // jss library works not correctly with conditional styles, so we need to use inline styles
      style={{
        opacity: showBackButton ? 1 : 0,
        pointerEvents: showBackButton ? 'auto' : 'none'
      }}
    >
      {t('Back')}
    </button>
  )
}
BackButton.propTypes = {
  onStepBack: PropTypes.func.isRequired,
  activeStep: PropTypes.number,
  className: PropTypes.string,
  showButton: PropTypes.bool
}
