import React from 'react'
import PropTypes from 'prop-types'

import FeesAndTotals from '../FeesAndTotals'
import ProductBreakdown from './ProductBreakdown'
import { DiscountsProvider } from '../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/DiscountsContext'

import { calculateTotalOrderPrice } from '../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/price'
import { determineDiscountsFromProducts } from '../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/DiscountsContext/helpers'

import { MEDIA_PRODUCTS } from '../fields'

function ProductsSummaryList({
  formik,
  showFees = true,
  showPreSubTotalFees,
  showTotal = true,
  selectedCurrency,
  isSmall
}) {
  const selectedProducts = formik.values[MEDIA_PRODUCTS]

  const productsDiscount = determineDiscountsFromProducts(selectedProducts)

  const totalOrderPrice = calculateTotalOrderPrice(selectedProducts, productsDiscount)

  return (
    <DiscountsProvider allSelectedProducts={selectedProducts}>
      {selectedProducts.map(product => (
        <ProductBreakdown
          key={product.data.id}
          productValues={product}
          productData={product.data}
          currency={selectedCurrency}
          isSmall={isSmall}
        />
      ))}
      <FeesAndTotals
        totalOrderPrice={totalOrderPrice}
        selectedCurrency={selectedCurrency}
        showFees={showFees}
        showPreSubTotalFees={showPreSubTotalFees}
        showTotal={showTotal}
        isSmall={isSmall}
      />
    </DiscountsProvider>
  )
}

ProductsSummaryList.propTypes = {
  formik: PropTypes.object.isRequired,
  showFees: PropTypes.bool,
  showPreSubTotalFees: PropTypes.bool,
  showTotal: PropTypes.bool,
  selectedCurrency: PropTypes.object.isRequired
}

export default ProductsSummaryList
