import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import InfoModal from '../InfoModal'
import NavItemsMenu from '../../NavItemsMenu'

import useStyles from './styles'

const ImagesModal = ({ title, images = [], showModal, onModalClose, selectedImageId, handleSelectImage }) => {
  const classes = useStyles()

  const selectedImage = useMemo(() => images.find(image => image.id === selectedImageId), [images, selectedImageId])

  return (
    <InfoModal
      isOpen={showModal}
      onClose={onModalClose}
      className={classes.modal}
      classNameBody={classes.modalBody}
      contentClassName={classes.contentContainer}
    >
      <div className={classes.modalContent}>
        <div className={classes.sideMenu}>
          {title && <h3 className={classes.modalTitle}>{title}</h3>}
          <NavItemsMenu itemsList={images} onMenuItemClick={handleSelectImage} selectedItemId={selectedImageId} />
        </div>
        <div className={classes.imageContainer}>
          {selectedImage && <img className={classes.image} src={selectedImage.image} alt={selectedImage.alt_text} />}
        </div>
      </div>
    </InfoModal>
  )
}

ImagesModal.propTypes = {
  title: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      image: PropTypes.string.isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ),
  showModal: PropTypes.bool,
  onModalClose: PropTypes.func,
  selectedImageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleSelectImage: PropTypes.func
}

export default ImagesModal
