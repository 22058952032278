import { createStyles } from '../../../../styles/helpers'

import { darkGrey, textGrey } from '../../../../styles/const/colors'

const useStyles = createStyles({
  body: {
    maxWidth: 335,
    margin: '0 auto'
  },
  title: {
    fontSize: 20,
    fontWeight: '600',
    margin: '10px 0 16px',
    color: textGrey
  },
  closeIcon: {
    position: 'absolute',
    top: 14,
    right: 14,
    padding: 10,
    maxHeight: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& path': {
      fill: darkGrey
    }
  },
  modalMessage: {
    color: darkGrey,
    textAlign: 'center',
    '& p': {
      marginTop: 8
    }
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 32,
    columnGap: 8
  }
})

export default useStyles
