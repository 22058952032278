import * as Yup from 'yup'
import { MEDIA_HEIGHT, MEDIA_WIDTH } from '../../../../../../../../../../forms/Facebook/AdForms/fields'
import { t } from 'i18next'

import { availableDimensions } from '../constants'

export const imageValidationSchema = () => {
  return Yup.object().shape({
    [MEDIA_WIDTH]: Yup.number().test(
      'is-valid-width',
      t('Please upload an image with the correct dimensions'),
      value => {
        return availableDimensions.some(dim => dim.width === value)
      }
    ),
    [MEDIA_HEIGHT]: Yup.number().test(
      'is-valid-height',
      t('Please upload an image with the correct dimensions'),
      value => availableDimensions.some(dim => dim.height === value)
    )
  })
}
