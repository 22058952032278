import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import HistoryItem from './HistoryItem'
import Spoiler from '../../../../../components/Spoiler'

import { optimisationHistorySelector } from '../../../../../modules/selectors/mediaOrderOptimisations'

import useStyles from './styles'

const HistoryList = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const optimisationsHistory = useSelector(optimisationHistorySelector)

  if (!optimisationsHistory.length) {
    return <p>{t('There are no history items to show.')}</p>
  }

  return (
    <Spoiler title="History of changes" spoilerTitleClassName={classes.spoilerTitle}>
      {optimisationsHistory.map(item => (
        <HistoryItem item={item} />
      ))}
    </Spoiler>
  )
}

export default HistoryList
