import { addDays, differenceInDays } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'

const generateRandomDateRange = (startDate, endDate) => {
  const diffInDays = differenceInDays(endDate, startDate)
  const randomStartDay = Math.floor(Math.random() * diffInDays)
  const randomEndDay = randomStartDay + Math.floor(Math.random() * (diffInDays - randomStartDay))

  const randomStartDate = addDays(startDate, randomStartDay)
  const randomEndDate = addDays(startDate, randomEndDay)

  return { startDate: randomStartDate, endDate: randomEndDate }
}

export const getRandomEvents = (period, amount) => {
  const events = []
  for (let i = 0; i < amount; i++) {
    // there is date range in the period, and we need to generate random date range for the event in that period,
    // write code:

    const randomDateRange = generateRandomDateRange(period.startDate, period.endDate)

    events.push({
      id: uuidv4(),
      name: `Event ${i}`,
      start_timestamp: +randomDateRange.startDate, // use timestamps instead of dates
      end_timestamp: +randomDateRange.endDate, // use timestamps instead of dates
      color: '#fff',
      isSkeleton: true
    })
  }
  return events
}

export const getRandomEventGroups = (amount, startDate, endDate) => {
  return [...Array(amount)].map((_, index) => {
    return {
      id: index,
      color: '#fff',
      type: 'placeholder',
      name: '',
      order: index + 1,
      group: {
        id: index,
        name: '',
        isLocationGroup: false
      },
      events: getRandomEvents({ startDate, endDate }, 3)
    }
  })
}
