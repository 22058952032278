import React from 'react'
import { useTranslation } from 'react-i18next'

import RadioBlock from '../../../../components/Form/RadioBlock'
import MarginField from '../MarginField'

import { OPTION_NO, OPTION_YES } from '../../../../constants/forms'
import { FEE, FEE_OPTION } from '../fields'

import useCommonStyles from '../../../../styles/common/stepForms'

const MarginFee = ({ formik, subTitle = 'MARGINS' }) => {
  const { t } = useTranslation()
  const commonClasses = useCommonStyles()

  const { values, setFieldValue } = formik

  return (
    <>
      <div className={commonClasses.stepSubTitle}>{t(subTitle)}</div>
      <div className={commonClasses.stepTitle}>{t('Do you want to account for any fees on partner spend?')}</div>
      <div className={commonClasses.stepBody}>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_no_fee"
          name={FEE_OPTION}
          value={OPTION_NO}
          selectedValue={values[FEE_OPTION]}
          label={t('No, I’m happy to account for any fees on partner spend off-platform')}
        />
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_set_fee"
          name={FEE_OPTION}
          value={OPTION_YES}
          selectedValue={values[FEE_OPTION]}
          label={t('Yes, recalculate my margin so that it also accounts for a fee')}
        >
          <p>{t('We will first account for this fee on partner spend, and then calculate the margin.')}</p>

          <p>
            {t(
              'For example, if you are required to deduct a 15% platform fee on partner spend, then when a partner spends \n\n $100, we will first deduct $15 and calculate the margin on the remaining $85.'
            )}
          </p>

          <p>
            Example breakdown: <br />
            Partner spend: $100 <br />
            Platform fee @ 15%: $15 <br />
            Margin @ 60%: $51 <br />
            Media cost: $34
          </p>

          <p>
            {t(
              'Please note that we will update your margin to account for this fee, so the margin applied will be higher\n\n than the one you entered on the previous step.'
            )}
          </p>
          <MarginField formik={formik} name={FEE} label={t('Fee')} />
        </RadioBlock>
      </div>
    </>
  )
}

export default MarginFee
