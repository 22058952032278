import { useSelector } from 'react-redux'
import { isPast } from 'date-fns'
import { useCallback, useMemo } from 'react'

import { isStory } from '../../../helpers'

import { lineItemSelector } from '../../../../../../modules/selectors/lineItems'
import { mediaOrderUploadCreativeDataSelector } from '../../../../../../modules/selectors/mediaOrders'

// there can be two cases of ad create form: 1) simple usage on line item summary page
// 2) usage inside multi-form on media orders page
// in the second case, we store newly created line item data inside separate redux store
export default function useLineItemDetails() {
  const { lineItem: uploadCreativeLineItemData } = useSelector(mediaOrderUploadCreativeDataSelector)
  const {
    id: lineItemId,
    time_stop: timeStop,
    placement_positions: placementPositions = []
  } = useSelector(lineItemSelector)

  // if has data from media order upload creative data selector, use its line item data for
  const isMediaOrderUploadCreative = !!uploadCreativeLineItemData.id

  const getRegularLineItemData = useCallback(() => {
    return {
      lineItemId,
      isAdSetEndDateInThePast: isPast(new Date(timeStop)),
      showAsStory: isStory(placementPositions),
      placementPositions
    }
  }, [lineItemId, placementPositions, timeStop])

  const getUploadCreativeLineItemData = useCallback(() => {
    const {
      id: uploadCreativeLineItemId,
      date_stop: uploadCreativeLineItemDateStop,
      placement_positions: uploadCreativeLineItemPlacementPositions
    } = uploadCreativeLineItemData

    return {
      lineItemId: uploadCreativeLineItemId,
      isAdSetEndDateInThePast: isPast(new Date(uploadCreativeLineItemDateStop)),
      showAsStory: isStory(uploadCreativeLineItemPlacementPositions),
      placementPositions: uploadCreativeLineItemPlacementPositions
    }
  }, [uploadCreativeLineItemData])

  return useMemo(
    () => (isMediaOrderUploadCreative ? getUploadCreativeLineItemData() : getRegularLineItemData()),
    [isMediaOrderUploadCreative, getUploadCreativeLineItemData, getRegularLineItemData]
  )
}
