import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles({
  numberField: {
    width: 90
  },
  marginValue: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
    '&:first-of-type': {
      marginTop: 16
    },

    '& $numberField': {
      marginTop: [0, '!important']
    }
  },
  platform: {
    minWidth: 90,
    fontWeight: 'bold'
  }
})

export default useStyles
