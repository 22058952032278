import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import TableDataLoader from '../../../../../components/Table/TableDataLoader'
import MediaPackageCategoriesTableData from './MediaPackageCategoriesTableData'

import {
  mediaPackageCategoriesErrorSelector,
  mediaPackageCategoriesIsLoadingSelector,
  mediaPackageCategoriesWasLoadedSelector
} from '../../../../../modules/selectors/mediaPackages'

import useCommonStyles from '../../../../../styles/common/listPage'
import { phonesDownSize } from '../../../../../styles/const/breakpoints'

export const tableColumnsSize = {
  dragBtn: 40,
  actions: 30
}

const spaceForMargin = 5

const mobileColumnsSchema = [
  { style: { maxWidth: `calc(${tableColumnsSize.dragBtn}px - ${spaceForMargin}px)`, marginInlineEnd: spaceForMargin } },
  { style: { maxWidth: '100%' } }
]

const MediaPackageCategoriesTable = ({ categories, handleDragEnd }) => {
  const { t } = useTranslation()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const commonClasses = useCommonStyles()

  const categoryColumnsSchema = useMemo(
    () => [
      {
        style: { maxWidth: tableColumnsSize.dragBtn }
      },
      {
        fieldKey: 'name'
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no media packages')}</div>
        <br />
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <TableDataLoader
      itemsLength={categories.length}
      errorSelector={mediaPackageCategoriesErrorSelector}
      wasLoadedSelector={mediaPackageCategoriesWasLoadedSelector}
      isLoadingSelector={mediaPackageCategoriesIsLoadingSelector}
      skeletonProps={{ cols: isMobile ? mobileColumnsSchema : categoryColumnsSchema }}
      noDataContent={noDataContent}
    >
      <MediaPackageCategoriesTableData categories={categories} onOrderChange={handleDragEnd} />
    </TableDataLoader>
  )
}

MediaPackageCategoriesTable.propTypes = {
  categories: PropTypes.array.isRequired,
  handleDragEnd: PropTypes.func.isRequired
}

export default MediaPackageCategoriesTable
