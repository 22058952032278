import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import MultiSelectBox from '../../../../features/components/Form/MultiSelectBox'

import { getChoicesGeolocations } from '../../../../modules/actions/choices'
import { choicesGeolocationsLoadingSelector, choicesGeolocationsSelector } from '../../../../modules/selectors/choices'

import {
  SHOW_REGIONS_REGION_TYPE,
  SHOW_REGIONS_CITY_TYPE
} from '../../../Facebook/LineItemForms/LineItemFacebookCreateOld/LineItemFacebookCreateFormOld/fields'
import { selectedAdAccountIdSelector } from '../../../../modules/selectors/app'

const FacebookGeolocationRegion = ({ selectedCountry, name, value, setFieldValue }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const geolocations = useSelector(choicesGeolocationsSelector)
  const geolocationsLoading = useSelector(choicesGeolocationsLoadingSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const formattedGeolocations = useMemo(
    () =>
      geolocations &&
      geolocations
        .filter(({ type }) => type === SHOW_REGIONS_REGION_TYPE || type === SHOW_REGIONS_CITY_TYPE)
        .map(item => {
          const { key, name, region, type, region_id } = item

          // include region name if it is exist in data
          const itemRegion = region ? `, ${region}` : ''

          return { value: key, label: name + itemRegion, type, region_id }
        }),
    [geolocations]
  )

  const onSearchRegions = useCallback(
    value => {
      dispatch(
        getChoicesGeolocations({
          search: value,
          account: selectedAdAccountId,
          country_code: selectedCountry && selectedCountry.iso2
        })
      )
    },
    [dispatch, selectedAdAccountId, selectedCountry]
  )

  return (
    <MultiSelectBox
      showIndicator={false}
      placeholder={t('Search a region')}
      name={name}
      options={formattedGeolocations}
      value={value}
      isLoading={geolocationsLoading}
      setFieldValue={setFieldValue}
      onSearch={onSearchRegions}
      // clear search on selectedCountry change
      triggerClearSearch={selectedCountry?.iso2}
    />
  )
}

FacebookGeolocationRegion.propTypes = {
  selectedCountry: PropTypes.object,
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  setFieldValue: PropTypes.func.isRequired
}

export default FacebookGeolocationRegion
