import { createStyles } from '../../../styles/helpers'

export default createStyles({
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 'padding: 30px 0'
  }
})
