import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import Table from '../../../../../../components/Table'
import PriceChangeCell from './PriceChangeCell'
import PriceChangePeriodsActions from './PriceChangePeriodsActions'

import { parseCategoryName } from './helpers'

import { priceChangePeriodsSelector } from '../../../../../../modules/selectors/discounts'

import { formatDateFullYear } from '../../../../../../constants/dates'
import { tableColumnsSize } from '../index'

import { phonesDownSize } from '../../../../../../styles/const/breakpoints'

const PriceChangePeriodsTableData = () => {
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const priceChangePeriods = useSelector(priceChangePeriodsSelector)

  const priceChangePeriodsColumns = useMemo(
    () => [
      {
        header: 'Period',
        Cell: ({ start_date, end_date }) => (
          <div>
            {formatDateFullYear(start_date)} - {formatDateFullYear(end_date)}
          </div>
        )
      },
      {
        header: 'Media Category',
        Cell: ({ media_category }) => <div>{parseCategoryName(media_category)}</div>,
        style: { maxWidth: tableColumnsSize.mediaCategory }
      },
      {
        header: 'Locations',
        Cell: ({ locations }) => locations?.length || 'All',
        style: { maxWidth: tableColumnsSize.locations }
      },
      {
        header: 'Price Change',
        Cell: data => <PriceChangeCell data={data} />,
        style: { maxWidth: tableColumnsSize.priceChange }
      },
      {
        Cell: data => <PriceChangePeriodsActions itemData={data} />,
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [isMobile]
  )

  return (
    <Table
      data={priceChangePeriods}
      cols={priceChangePeriodsColumns}
      tableId="PriceChangePeriodsTableData"
      hideFooterRow
    />
  )
}

export default PriceChangePeriodsTableData
