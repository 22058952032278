// PLOP_APPEND_PATTERN_ANCHOR

// sections
export const DATES = 'dates'
export const BUDGET = 'budget'
export const NAME = 'name'
export const SCHEDULE = 'schedule'
export const DEMOGRAPHICS = 'demographics'
export const AUDIENCE = 'audience'
export const LANGUAGES = 'languages'
export const CUSTOM_AUDIENCES = 'custom_audiences'

// demographics
export const GEOLOCATIONS = 'geolocations'
