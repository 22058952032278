import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import EditForm from '../../../../../../features/components/Forms/EditForm'
import MultiSelectBox from '../../../../../../features/components/Form/MultiSelectBox'

import { formatAudiencesToBE } from '../../../LineItemFacebookCreateOld/LineItemFacebookCreateFormOld/formatters'
import { formatList } from '../../fields'
import { formatOptionsList } from '../../../../../../features/formatters'
import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'

import { clearAudiences, getAudiences } from '../../../../../../modules/actions/audiences'
import {
  lineItemUpdateErrorSelector,
  lineItemUpdateIsLoadingSelector,
  lineItemWasUpdatedSelector
} from '../../../../../../modules/selectors/lineItems'
import { campaignSelector } from '../../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'
import { audiencesLoadingSelector, audiencesSelector } from '../../../../../../modules/selectors/audiences'
import { clearUpdateLineItem, updateLineItem } from '../../../../../../modules/actions/lineItems'

import {
  CUSTOM_AUDIENCES,
  CUSTOM_AUDIENCES_EXCLUSION
} from '../../../../../ReusableFormFields/CustomAudiencesFields/fields'
import { FACEBOOK_PLATFORM } from '../../../../../../constants/selectLists/platformList'

import useStyles from '../../../../../../styles/common/drawerForms'

const CustomAudiencesSectionForm = ({ editItemData: lineItem, ...formProps }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const lineItemWasUpdated = useSelector(lineItemWasUpdatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const audiences = useSelector(audiencesSelector)
  const audiencesIsLoading = useSelector(audiencesLoadingSelector)
  const { objective: campaignObjective } = useSelector(campaignSelector)

  const formattedAudiences = useMemo(
    () =>
      formatOptionsList({
        list: audiences,
        valueName: 'id',
        labelName: 'name'
      }),
    [audiences]
  )

  const handleSubmit = useCallback(
    values => {
      const updateLineItemData = {
        account: selectedAdAccountId,
        campaign_objective: campaignObjective,
        [CUSTOM_AUDIENCES]: formatAudiencesToBE(values[CUSTOM_AUDIENCES]),
        [CUSTOM_AUDIENCES_EXCLUSION]: formatAudiencesToBE(values[CUSTOM_AUDIENCES_EXCLUSION])
      }

      dispatch(updateLineItem(updateLineItemData, lineItem.id))
    },
    [dispatch, lineItem.id, campaignObjective, selectedAdAccountId]
  )

  const initialValues = useMemo(
    () => ({
      [CUSTOM_AUDIENCES]: formatList(lineItem[CUSTOM_AUDIENCES]),
      [CUSTOM_AUDIENCES_EXCLUSION]: formatList(lineItem[CUSTOM_AUDIENCES_EXCLUSION])
    }),
    [lineItem]
  )

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const { values, setFieldValue } = purifiedFormik

  const handleClearUpdateLineItem = useCallback(() => {
    dispatch(clearUpdateLineItem())
  }, [dispatch])

  const audiencesSearchHandler = useCallback(
    searchText => {
      dispatch(getAudiences({ search: searchText, account: selectedAdAccountId, limit: 25 }, FACEBOOK_PLATFORM))
    },
    [dispatch, selectedAdAccountId]
  )

  useEffect(() => {
    return () => {
      dispatch(clearAudiences())
    }
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      successSubmit={lineItemWasUpdated}
      clearEditItem={handleClearUpdateLineItem}
      errorSelector={lineItemUpdateErrorSelector}
      isLoadingSelector={lineItemUpdateIsLoadingSelector}
      {...formProps}
    >
      <h5 className={classes.sectionSubtitle}>{t('Custom audience inclusions')}</h5>
      <MultiSelectBox
        placeholder="Add an Audience Inclusion"
        options={formattedAudiences}
        name={CUSTOM_AUDIENCES}
        value={values[CUSTOM_AUDIENCES]}
        isLoading={audiencesIsLoading}
        showIndicator={false}
        setFieldValue={setFieldValue}
        onSearch={audiencesSearchHandler}
      />
      <h5 className={classes.sectionSubtitle}>{t('Custom audience exclusions')}</h5>
      <MultiSelectBox
        placeholder="Add an audience exclusion"
        options={formattedAudiences}
        name={CUSTOM_AUDIENCES_EXCLUSION}
        value={values[CUSTOM_AUDIENCES_EXCLUSION]}
        isLoading={audiencesIsLoading}
        showIndicator={false}
        setFieldValue={setFieldValue}
        onSearch={audiencesSearchHandler}
      />
    </EditForm>
  )
}

export default CustomAudiencesSectionForm
