import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Step from '../../../../../../../../features/components/Forms/StepForm/Step'
import TikTokAudiencesFields from '../../../../../../ReusableFormFields/TikTokAudiencesFields'

import { selectedControllerDataSelector } from '../../../../../../../../modules/selectors/app'

const CustomAudiencesStep = ({ formik, adAccountId }) => {
  const selectedController = useSelector(selectedControllerDataSelector)

  const selectedControllerName = selectedController?.name

  return (
    <Step
      stepTitle="Select an audience"
      stepDescription={`Target ${selectedControllerName ? selectedControllerName : ''} shopper audiences on TikTok`}
    >
      <TikTokAudiencesFields formik={formik} adAccountId={adAccountId} />
    </Step>
  )
}

CustomAudiencesStep.propTypes = {
  adAccountId: PropTypes.string.isRequired
}

export default CustomAudiencesStep
