import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Field from '../../../../../../components/Form/Field'
import FieldRow from '../../../../../../features/components/Form/FieldsSection/FieldRow'
import TextSuggestions from '../../../../../../features/components/Form/TextSuggestions'

import { adCreativeSuggestionsLoadingSelector } from '../../../../../../modules/selectors/tools'
import { primaryTextMaxLength } from '../../../../AdForms/validation'

import { PRIMARY_TEXT_LIST } from '../../../../AdForms/fields'

const PrimaryTextField = ({ formik, suggestions = [] }) => {
  const { values, setFieldValue } = formik

  const suggestionsLoading = useSelector(adCreativeSuggestionsLoadingSelector)

  const selectedPrimaryText = values[PRIMARY_TEXT_LIST][0]?.text

  const handlePrimaryTextChange = useCallback(
    value => setFieldValue(`${PRIMARY_TEXT_LIST}[0].text`, value),
    [setFieldValue]
  )

  return (
    <FieldRow
      title="Primary Text"
      description="125 characters or less recommended"
      footer={
        <TextSuggestions
          showLogo={false}
          selectedSuggestion={selectedPrimaryText}
          suggestions={suggestions}
          onSelectSuggestion={handlePrimaryTextChange}
          typingAnimation={suggestionsLoading}
        />
      }
    >
      <Field
        placeholder="Primary Text"
        label="PRIMARY TEXT"
        formik={formik}
        name={`${PRIMARY_TEXT_LIST}[0].text`}
        maxLength={primaryTextMaxLength}
        isTextarea
        hasEmojiPicker
        enableReinitialize
      />
    </FieldRow>
  )
}

PrimaryTextField.propTypes = {
  formik: PropTypes.object.isRequired,
  suggestions: PropTypes.array
}

export default PrimaryTextField
