import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import EditForm from '../../../../../../features/components/Forms/EditForm'
import CustomLocationsContent from '../../../../../ReusableFormSections/CustomLocationsSection/CustomLocationsContent'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import { getDeletedCriterionsArray } from '../../../../../../features/helpers/googleCriterionsHelpers'

import { clearCreateCampaignCriterions, createCampaignCriterions } from '../../../../../../modules/actions/campaigns'
import {
  campaignCriterionsSelector,
  createCampaignCriterionsErrorSelector,
  createCampaignCriterionsIsLoadingSelector,
  createCampaignCriterionsWasCreatedSelector
} from '../../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { CAMPAIGN_GOOGLE_CUSTOM_LOCATION_CRITERION_TYPE, criterionTypes } from '../../../../../../constants/campaigns'
import { CUSTOM_LOCATIONS } from '../../../../../ReusableFormFields/CampaignForms/fields'
import { LOCATION_LIST } from '../../../../../ReusableFormFields/GeolocationsFields/fields'
import { locationListValidation } from '../../../../../ReusableFormFields/LineItemForms/fields'

const validationSchema = Yup.object({ [LOCATION_LIST]: locationListValidation })

const CustomLocationsSectionForm = ({ editItemData: campaign, ...formProps }) => {
  const dispatch = useDispatch()

  const [manualSuccessSubmit, setManualSuccessSubmit] = useState(false)

  const campaignCriterions = useSelector(campaignCriterionsSelector)
  const criterionsWasCreated = useSelector(createCampaignCriterionsWasCreatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const { id: campaignId } = campaign

  const formattedInitialCustomLocations = useMemo(
    () =>
      campaignCriterions
        .filter(criterion => criterion.type_ === CAMPAIGN_GOOGLE_CUSTOM_LOCATION_CRITERION_TYPE)
        .map(({ display_name: displayName, criterion_id: criterionId, resource_name: criterionResourceName }) => ({
          name: displayName,
          value: criterionId,
          criterionResourceName
        })),
    [campaignCriterions]
  )

  const onSubmit = useCallback(
    values => {
      const criterionsToDelete = getDeletedCriterionsArray(formattedInitialCustomLocations, values[CUSTOM_LOCATIONS])

      const listWasCleared = criterionsToDelete.length

      if (!listWasCleared && !values[LOCATION_LIST]) {
        setManualSuccessSubmit(true)
      } else {
        const formattedValues = {
          account: selectedAdAccountId,
          campaign: campaignId,
          // list was cleared = all items were deleted
          ...(listWasCleared && { remove_old: true }),
          // if user selected location list, add it to payload
          ...(values[LOCATION_LIST] && { location_list: values[LOCATION_LIST] })
        }

        dispatch(createCampaignCriterions(formattedValues, criterionTypes.proximity, criterionsToDelete))
      }
    },
    [dispatch, formattedInitialCustomLocations, selectedAdAccountId, campaignId]
  )

  const initialValues = useMemo(
    () => ({
      [CUSTOM_LOCATIONS]: formattedInitialCustomLocations,
      [LOCATION_LIST]: ''
    }),
    [formattedInitialCustomLocations]
  )

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const handleClearCreateCriterions = useCallback(() => {
    dispatch(clearCreateCampaignCriterions())
  }, [dispatch])

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      clearEditItem={handleClearCreateCriterions}
      successSubmit={criterionsWasCreated || manualSuccessSubmit}
      errorSelector={createCampaignCriterionsErrorSelector}
      isLoadingSelector={createCampaignCriterionsIsLoadingSelector}
      {...formProps}
    >
      <CustomLocationsContent formik={purifiedFormik} showTitle={false} />
    </EditForm>
  )
}

export default CustomLocationsSectionForm
