import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import ActionsDropdown from '../../../../../../../features/components/ActionsDropdown'
import ActionsButtonsList from '../../../../../../../features/components/ActionsButtonsList'

import { openForm } from '../../../../../../../modules/actions/forms'

import { MEDIA_CATEGORY_EDIT } from '../../../../../../../constants/forms'

import { phonesDownSize } from '../../../../../../../styles/const/breakpoints'

const MediaCategoryActions = ({ category, className }) => {
  const { t } = useTranslation()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const dispatch = useDispatch()

  const { id } = category

  const openEditFormHandler = useCallback(() => {
    dispatch(openForm({ id, formName: MEDIA_CATEGORY_EDIT }))
  }, [dispatch, id])

  const dropdownOptions = useMemo(
    () => [
      {
        text: t('Edit'),
        onClickHandler: openEditFormHandler
      }
    ],
    [t, openEditFormHandler]
  )

  return isMobile ? (
    <ActionsButtonsList options={dropdownOptions} className={className} />
  ) : (
    <ActionsDropdown options={dropdownOptions} />
  )
}

export default MediaCategoryActions
