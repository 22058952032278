import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import StatusUpdateDropdown from '../../../../../../../features/components/StatusUpdateDropdown'

import { bulkUpdateBookedMedia } from '../../../../../../../modules/actions/mediaOrdersBookings'

import { bookedMediaStatusesArray } from '../../../../../../../constants/bookedMedia'

const StatusCell = ({ status, sequentialIds }) => {
  const dispatch = useDispatch()

  const updateStatusHandler = useCallback(
    status => {
      dispatch(
        bulkUpdateBookedMedia({
          ids: sequentialIds,
          status: status
        })
      )
    },
    [dispatch, sequentialIds]
  )

  if (!sequentialIds?.length) {
    // Don't show status dropdown inside sub rows
    return null
  }

  return (
    <StatusUpdateDropdown
      currentStatus={status}
      statuses={bookedMediaStatusesArray}
      onStatusUpdate={updateStatusHandler}
    />
  )
}

StatusCell.propTypes = {
  status: PropTypes.string,
  sequentialIds: PropTypes.array
}

export default StatusCell
