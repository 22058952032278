import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import SelectPaginated from '../../../../../../../../components/SelectPaginated'

import { createJsonFromQueryString } from '../../../../../../../../helpers/url'

import { getProductsService } from '../../../../../../../../modules/services/mediaOrdersProducts'
import { productIsLoadingSelector } from '../../../../../../../../modules/selectors/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../../../../../modules/selectors/app'

import useStyles from './styles'

const ProductSelection = ({ onAddProduct, currency, selectedProductsIds }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const containerRef = React.useRef(null)

  const currencyCode = currency.code
  const [isDataLoading, setIsDataLoading] = React.useState(false)
  const selectedProductIsLoading = useSelector(productIsLoadingSelector)
  const selfAccountControllerId = useSelector(selectedControllerIdSelector)

  const formatProductOptions = useCallback(products => {
    if (products) {
      return products.map(product => ({
        value: product.id,
        label: product.internal_id ? `${product.internal_id} - ${product.name}` : product.name
      }))
    } else {
      return []
    }
  }, [])

  const loadOptions = useCallback(
    async (search, loadedOptions, { next }) => {
      setIsDataLoading(true)
      const response = await getProductsService({
        search,
        ordering: 'name',
        controller: selfAccountControllerId,
        fields: ['id', 'name', 'internal_id'].join(','),
        in_stock: true,
        currency_code: currencyCode,
        limit: 100,
        status: 'active', // filter by active only(but currently for amendments we will fetch all)
        ...createJsonFromQueryString(`?${next.split('?')[1]}`)
      })
      const newProductsOptions = formatProductOptions(response.results)

      setIsDataLoading(false)

      return {
        options: newProductsOptions,
        hasMore: !!response.next,
        additional: {
          next: response.next
        }
      }
    },
    [selfAccountControllerId, currencyCode, formatProductOptions]
  )

  const onChangeHandler = useCallback(
    selectedProductData => {
      onAddProduct(selectedProductData)

      // we focus on the container to show placeholder on the select again
      containerRef.current?.focus()
    },
    [onAddProduct]
  )

  return (
    // tabIndex is added to make the container focusable
    <div className={classes.addProductContainer} tabIndex={0} ref={containerRef}>
      <span className={classes.addProductTitle}>{t('Add a product')}</span>
      <SelectPaginated
        resetOptionsKey={currencyCode} // Reset the select when currency changes
        className={classes.productSelect}
        onChange={onChangeHandler}
        loadOptions={loadOptions}
        isLoading={isDataLoading}
        placeholder="Search or select media product"
        autoComplete="off"
        selectedOptionsIds={selectedProductsIds}
        isDisabled={selectedProductIsLoading}
      />
    </div>
  )
}

ProductSelection.propTypes = {
  currency: PropTypes.object.isRequired,
  onAddProduct: PropTypes.func.isRequired,
  selectedProductsIds: PropTypes.array
}

export default ProductSelection
