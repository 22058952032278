import React, { useCallback, useEffect, useRef } from 'react'
import { Portal } from 'react-portal'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

import DrawerHeadline from './DrawerHeadline'
import ButtonIcon from '../../features/components/Buttons/ButtonIcon'
import ButtonClose from '../../features/components/Buttons/ButtonClose'

import { ReactComponent as ExpandIcon } from '../../assets/icons/expand.svg'
import { ReactComponent as MinimizeIcon } from '../../assets/icons/minimize.svg'

import { useDidMount } from '../../hooks/useDidMount'

import { setFormMinimize } from '../../modules/actions/forms'
import { formDrawerMinimizeStatesSelector } from '../../modules/selectors/forms'

import { DRAWER_CONTAINER_ID } from './constants'

import useStyles from './style'

function Drawer(props) {
  const {
    isOpen,
    onClose,
    className,
    children,
    portalProps,
    title,
    description,
    disabledClose = false,
    isWideDrawer = false,
    showMinimizeBtn = false,
    hasDefaultDrawerContentSpacing = true,
    contentClassName
  } = props
  const didMount = useDidMount()
  const dispatch = useDispatch()

  const isDrawerMinimized = useSelector(formDrawerMinimizeStatesSelector)
  const classes = useStyles({ isOpen, isMinimized: isDrawerMinimized, isWideDrawer, hasDefaultDrawerContentSpacing })

  const bodyContainer = useRef(null)

  const hasHeadline = title || description

  const handleClose = useCallback(() => {
    if (!disabledClose) {
      onClose()
    }
  }, [onClose, disabledClose])

  useEffect(() => {
    // avoid scrolling the app body when drawer is open
    if (isOpen) {
      document.querySelector('body').style.overflow = 'hidden'
      // autoScrollToTop
      bodyContainer.current.scrollTo(0, 0)
    } else {
      document.querySelector('body').style.overflow = ''
    }
  }, [isOpen])

  useEffect(() => {
    if (didMount) {
      // opposite to open - allow to scroll the app body when drawer is minimized
      if (isDrawerMinimized) {
        document.querySelector('body').style.overflow = ''
      } else {
        document.querySelector('body').style.overflow = 'hidden'
        // autoScrollToTop
        bodyContainer.current.scrollTo(0, 0)
      }
    }
    // avoid invoke useEffect on initial rendering
    // eslint-disable-next-line
  }, [isDrawerMinimized])

  const toggleFormMinimize = () => {
    dispatch(setFormMinimize(!isDrawerMinimized))
  }

  return (
    <Portal node={document && document.body} {...portalProps}>
      <div className={className}>
        <div onClick={handleClose} className={classes.backdrop} />
        {/*data-content is used as an identifier to have ability scroll drawer content*/}
        {/*for example that is done on step change form*/}
        <div className={classnames(classes.content, contentClassName)} data-content={DRAWER_CONTAINER_ID}>
          <ButtonClose className={classes.closeBtn} onClick={handleClose} disabled={disabledClose} />
          {showMinimizeBtn &&
            (isDrawerMinimized ? (
              <ButtonIcon className={classes.minimize} onClick={toggleFormMinimize} Icon={ExpandIcon} />
            ) : (
              <ButtonIcon className={classes.minimize} onClick={toggleFormMinimize} Icon={MinimizeIcon} />
            ))}
          <div ref={bodyContainer} className={classes.body}>
            {hasHeadline && <DrawerHeadline title={title} description={description} />}
            {isOpen && children}
          </div>
        </div>
      </div>
    </Portal>
  )
}

Drawer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  portalProps: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  showMinimizeBtn: PropTypes.bool,
  disabledClose: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  // styles:
  className: PropTypes.string,
  hasDefaultDrawerContentSpacing: PropTypes.bool,
  contentClassName: PropTypes.string
}

export default Drawer
