import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../components/Form'
import RegisterFields from './RegisterFields'

import { useAuthButtonProps } from '../../useAuthButtonProps'

import { initialValues, transformValuesToBE, validationSchema } from '../fields'

import { acceptInvite, clearAcceptInvite } from '../../../../modules/actions/selfAccountTeam'
import {
  acceptedInviteErrorSelector,
  acceptedInviteIsLoadingSelector,
  inviteWasAcceptedSelector
} from '../../../../modules/selectors/selfAccountTeam'

const AcceptInviteForm = ({ token, userCountry }) => {
  const dispatch = useDispatch()

  const inviteWasAccepted = useSelector(inviteWasAcceptedSelector)

  const buttonProps = useAuthButtonProps()

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values, userCountry, token)
      dispatch(acceptInvite(transformedData, true))
    },
    [dispatch, token, userCountry]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  useEffect(() => {
    return () => {
      dispatch(clearAcceptInvite())
    }
  }, [dispatch])

  return (
    <Form
      formName="acceptInvite"
      formik={formik}
      method="post"
      successSubmit={inviteWasAccepted}
      isLoadingSelector={acceptedInviteIsLoadingSelector}
      errorSelector={acceptedInviteErrorSelector}
      submitText="Sign Up"
      buttonProps={buttonProps}
    >
      <RegisterFields formik={formik} />
    </Form>
  )
}

export default AcceptInviteForm
