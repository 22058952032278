import { useCallback } from 'react'

export function useFormattedValue(options = []) {
  return useCallback(
    value => {
      // react-select operates with object value
      // to be able to represent selected option it should be converted to object or array of objects
      if (Array.isArray(value)) {
        return options.filter(option => value.includes(option.value))
      } else if (value || value === 0) {
        const selectedValue = options.find(option => option.value.toString() === value.toString())
        return selectedValue || ''
      } else {
        return ''
      }
    },
    [options]
  )
}
