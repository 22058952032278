import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import AppLogo from '../../AppLogo'
import ActionText from '../../../../components/ActionText'

import { handleLogout } from '../../../../helpers/auth'

import { ROUTES } from '../../../../constants/routes'

import useStyles from './styles'

function SimpleHeaderTemplate({ logoUrl = ROUTES.platformDomain, showLogout = false }) {
  const classes = useStyles()

  const logoutHandler = () => {
    handleLogout()
  }

  return (
    <header className={classes.header}>
      <div className={classnames(classes.container, 'is-centered')}>
        <a href={logoUrl} rel="noreferrer nofollow" className={classes.logo}>
          <AppLogo />
        </a>
      </div>
      {showLogout && (
        <ActionText className={classes.logoutBtn} onClick={logoutHandler} isDark>
          Logout
        </ActionText>
      )}
    </header>
  )
}

SimpleHeaderTemplate.propTypes = {
  logoUrl: PropTypes.string,
  showLogout: PropTypes.bool
}

export default SimpleHeaderTemplate
