import { createStyles } from '../../../../styles/helpers'

import { grey } from '../../../../styles/const/colors'

const useStyles = createStyles({
  details: {
    padding: '0 8px',
    marginTop: 20
  },
  title: {
    wordWrap: 'break-word',
    fontSize: 14,
    lineHeight: '17px',
    margin: '0 0 1px',
    fontWeight: 400
  },
  subTitle: {
    wordWrap: 'break-word',
    fontSize: 10,
    lineHeight: '12px',
    color: grey,
    margin: 0,
    fontWeight: 400
  }
})

export default useStyles
