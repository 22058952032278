import {
  BRAND_NAME,
  LANDSCAPE_LOGO,
  SQUARE_LOGO
} from './ClientAccountWithMarginsCreate/ClientAccountWithMarginsCreateForm/fields'
import { CATEGORY, SUBCATEGORY } from './fields'

export const formatBrandsToBE = brands => {
  return brands
    .filter(brand => brand[BRAND_NAME])
    .map(brand => {
      return {
        [BRAND_NAME]: brand[BRAND_NAME],
        ...(brand[CATEGORY] && { [CATEGORY]: brand[CATEGORY] }),
        ...(brand[SUBCATEGORY] && { [SUBCATEGORY]: brand[SUBCATEGORY] }),
        ...(brand[SQUARE_LOGO] && { [SQUARE_LOGO]: brand[SQUARE_LOGO] }),
        ...(brand[LANDSCAPE_LOGO] && { [LANDSCAPE_LOGO]: brand[LANDSCAPE_LOGO] })
      }
    })
}
