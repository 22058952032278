import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import NameSectionForm from '../../../../../ReusableFormSections/NameSection/NameSectionForm'

import { updateLineItem, clearUpdateLineItem } from '../../../../../../modules/actions/lineItems'
import {
  lineItemUpdateErrorSelector,
  lineItemUpdateIsLoadingSelector,
  lineItemWasUpdatedSelector
} from '../../../../../../modules/selectors/lineItems'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { GOOGLE_PLATFORM } from '../../../../../../constants/selectLists/platformList'

function NameSection({ editItemData: item, ...formProps }) {
  const dispatch = useDispatch()

  const itemWasUpdated = useSelector(lineItemWasUpdatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const handleSubmit = useCallback(
    values => {
      const updateLineItemData = {
        account: selectedAdAccountId,
        ...values
      }

      dispatch(updateLineItem(updateLineItemData, item.id, GOOGLE_PLATFORM))
    },
    [dispatch, item.id, selectedAdAccountId]
  )

  const handleClearUpdateItem = useCallback(() => {
    dispatch(clearUpdateLineItem())
  }, [dispatch])

  return (
    <NameSectionForm
      placeholder="Ad Group Name"
      editItem={item}
      handleSubmit={handleSubmit}
      clearEditItem={handleClearUpdateItem}
      successSubmit={itemWasUpdated}
      isLoadingSelector={lineItemUpdateIsLoadingSelector}
      errorSelector={lineItemUpdateErrorSelector}
      {...formProps}
    />
  )
}

export default NameSection
