import React from 'react'

import AdCardListItem from '../../../../../../features/components/AdCardListItem'
import SkeletonInternalAdActionButtons from '../../../../InternalAdActionButtons/Skeleton'
import SkeletonTikTokAdPreview from '../../../../../../forms/Tiktok/AdForms/AdTikTokEdit/AdTikTokPreview/Skeleton'

import useStyles from './styles'

function SkeletonTikTokAdPreviewCard() {
  const classes = useStyles()

  return (
    <AdCardListItem>
      <div className={classes.adPreviewContainer}>
        <SkeletonTikTokAdPreview className={classes.adPreview} />
      </div>
      <div className={classes.previewContainer} />
      <SkeletonInternalAdActionButtons />
    </AdCardListItem>
  )
}

export default SkeletonTikTokAdPreviewCard
