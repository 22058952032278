import React from 'react'
import { useTranslation } from 'react-i18next'

import Chip from '../../../../components/Chip'

import { CHIP_COLORS } from '../../../../constants/other'

import useStyles from './styles'

const TabTitle = ({ title, chipText }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {t(title)}
      {chipText && <Chip size="small" text={chipText} color={CHIP_COLORS.redWithWhiteColor} />}
    </div>
  )
}

export default TabTitle
