import { useTheme } from 'react-jss'
import { Bar } from 'react-chartjs-2'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { format, toDate } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { useSelectedSelfAccountCategoryLabel } from '../../../BrandCategoryBreakdown/hooks'
import PageSection from '../../../../../../../../features/components/PageSection'

import { useRevenueGraphOptions } from '../hooks'

import useStyles from '../styles'

const PeriodBookedMediaReportGraph = ({ monthRevenueReportData = [], selectedSelfAccountCategory }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()

  const selectedSelfAccountCategoryLabel = useSelectedSelfAccountCategoryLabel(selectedSelfAccountCategory)

  const data = useMemo(() => {
    return monthRevenueReportData.map(item => item.booked_media?.total_revenue || 0)
  }, [monthRevenueReportData])

  const labels = useMemo(() => {
    return monthRevenueReportData.map(item => {
      const parsedDate = toDate(item.period)
      // format date to this format Aug ‘24
      return format(parsedDate, 'MMM ‘yy')
    })
  }, [monthRevenueReportData])

  const graphOptions = useRevenueGraphOptions()

  const graphData = {
    labels,
    datasets: [
      {
        data: data,
        backgroundColor: theme.brandPrimary,
        borderColor: theme.brandPrimary,
        borderRadius: {
          topLeft: 2,
          topRight: 2
        },
        borderSkipped: false,
        maxBarThickness: 30
      }
    ]
  }

  return (
    <PageSection className={classes.sectionContainer}>
      <h5 className={classes.graphTitle}>{t('bookedRevenueByMonthTitle', { selectedSelfAccountCategoryLabel })}</h5>
      <div className={classes.graphWrapper}>
        <Bar type="bar" data={graphData} options={graphOptions} />
      </div>
    </PageSection>
  )
}

PeriodBookedMediaReportGraph.propTypes = {
  monthRevenueReportData: PropTypes.array.isRequired,
  selectedSelfAccountCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default PeriodBookedMediaReportGraph
