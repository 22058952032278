import { useSelector } from 'react-redux'

import { activeStepSelector } from '../../../../../../modules/selectors/forms'

export const useProgressItems = () => {
  const activeStep = useSelector(activeStepSelector)

  const progressItems = [
    {
      show: true,
      label: 'Enter account details'
    },
    {
      show: true,
      label: 'Define ad accounts'
    },
    {
      show: true,
      label: 'Specify margins'
    }
  ]
    .filter(item => item.show)
    .map((item, index) => {
      return {
        ...item,
        isActive: activeStep === index,
        isSuccess: activeStep > index
      }
    })

  return progressItems
}
