import { TABLE_COL_CLASSNAME } from '../../../../../../constants/pdf'
import { SKIP_CELL_FOR_COPY } from '../../../../../../components/Table/constants'

import { DATA_COST, DATA_DATES, DATA_QUANTITY, DATA_RATE } from '../ProductsTable/constants'

export const formatTableContentToSheet = tableRows => {
  // Map over rows to extract text content, separating columns by tab
  return tableRows
    .map((row, index) => {
      // find all cols, ignore cols with data-skip-copy attribute
      const cells = Array.from(
        row.querySelectorAll(`[class^="${TABLE_COL_CLASSNAME}-"]:not([${SKIP_CELL_FOR_COPY}="true"])`)
      )
      const isHeader = index === 0

      return (
        cells
          .map(cell => {
            if (isHeader) {
              // don't format header cells
              return cell.innerText
            } else {
              // format data cells
              return formatCellsData(cell)
            }
          })
          // Join cells by tab
          .join('\t')
      )
    })
    .join('\n')
}

const formatCellsData = cell => {
  const dataCopy = cell.getAttribute('data-copy')

  if (dataCopy === DATA_QUANTITY) {
    // Format quantity as a number
    return cell.innerText.replace(/^x/, '')
  } else if (dataCopy === DATA_COST || dataCopy === DATA_RATE) {
    // Remove any currency symbol from cost and rate
    return cell.innerText.replace(/[^\d.,]/g, '').trim()
  } else if (dataCopy === DATA_DATES) {
    const dates = Array.from(cell.querySelectorAll('li'))
    // Add delimiter `/` for multiple date ranges:
    return dates.map(date => date.innerText).join(' / ')
  } else {
    // Replace newlines with spaces to avoid
    // cases when there is a list in cell and each item of this list become a new row
    return cell.innerText.replace(/\n/g, ' ')
  }
}
