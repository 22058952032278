import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../components/Button'
import RadioBlock from '../../../../../components/Form/RadioBlock'

import { ALL_CAMPAIGNS, SPECIFIC_CAMPAIGN, CAMPAIGN_OPTION } from '../fields'

import useStyles from '../../../../../styles/common/stepForms'

const MarginCreateRouteSelector = ({ manageStepFormRoutes, handleStepChange }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { selectedRoute, handleSelectRoute, routeWasSelected } = manageStepFormRoutes
  // chooseRoute just handle the UI and selectedRoute manage actual route
  const [chooseRoute, setChooseRoute] = useState(selectedRoute)

  const handleContinue = () => {
    if (selectedRoute === chooseRoute) {
      // route wasn't change
      handleStepChange()
    } else {
      // selected new route
      handleSelectRoute(chooseRoute)
    }
  }

  useEffect(() => {
    routeWasSelected && handleStepChange()
    // eslint-disable-next-line
  }, [])

  // The answers to this question will define which route the user takes (Route A, Route B or Route C)
  return (
    <div>
      <div className={classes.stepSubTitle}>{t('Margins')}</div>
      <div className={classes.stepTitle}>{t('Should the margins apply to all campaigns or just one?')}</div>
      <div className={classes.stepBody}>
        <RadioBlock
          setFieldValue={(fieldName, route) => setChooseRoute(route)}
          id="radio_all_campaigns"
          name={CAMPAIGN_OPTION}
          value={ALL_CAMPAIGNS}
          selectedValue={chooseRoute}
          label={t('All campaigns')}
        />
        <RadioBlock
          setFieldValue={(fieldName, route) => setChooseRoute(route)}
          id="radio_specific_campaign"
          name={CAMPAIGN_OPTION}
          value={SPECIFIC_CAMPAIGN}
          selectedValue={chooseRoute}
          label={t('I want to override the margin on one specific campaign')}
        />
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" className="dark" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </div>
  )
}

export default MarginCreateRouteSelector
