import { v4 as uuidv4 } from 'uuid'
import { t } from 'i18next'

import { PERIOD_ONE_MONTH, PRINT } from '../../../../../constants/mediaOrders'
import { PRICE_CURRENCY, PRICE_PAIRS, PRICE_VALUE } from '../../../../ReusableFormFields/PricePairFields/fields'
import { IMAGE_TYPE } from '../../../../../constants/product'
import { ORDER } from '../../../../../features/components/Form/SortableUploadImagesList/fields'
import {
  INVENTORY_AMOUNT,
  INVENTORY_PERIOD,
  LOCATION,
  MAX_PERIODS,
  MEDIA_SUB_CATEGORY,
  MEDIA_SUB_SUB_CATEGORY,
  MIN_PERIODS,
  TRIGGER_BOOKING_NOTIFICATION,
  VARIABLES
} from '../../fields'
import { initialImageFileValues } from '../../../../../constants/files'

// name
export const NAME = 'name'
export const DESCRIPTION = 'subtext'
// media category
export const MEDIA_CATEGORY = 'media_category'
// period
export const PERIOD_OPTION = 'period_option'
// discount pairs
export const DISCOUNTS = 'discounts'
export const DISCOUNTS_TABS = 'discounts_tabs'
export const DISCOUNTS_TAB_PRESET = 'discounts_tab_preset'
export const DISCOUNTS_TAB_CUSTOM = 'discounts_tab_custom'
export const DISCOUNT_PRESET = 'discount_preset'
export const CUSTOM_DISCOUNT_CHECKBOX = 'custom_discount_checkbox'
export const DISCOUNT_PAIRS = 'discount_pairs'
export const DISCOUNT_PERCENTAGE = 'discount_percentage'
export const DISCOUNT_QUANTITY = 'discount_quantity'
// deadline days
export const CREATIVE_DEADLINE_DAYS = 'creative_deadline_days'
// platforms
export const CHANNEL_OPTION = 'platforms_option'
export const CHANNEL_OPTION_ONSITE = 'platforms_option_onsite'
export const CHANNEL_OPTION_OFFSITE = 'platforms_option_offsite'
export const PLATFORMS = 'platforms'
// inventory
export const INVENTORY_LIST_DATA = 'inventory_list_data'
export const INVENTORY_START_DATE = 'inventory_start_date'
export const INVENTORY_QUANTITY = 'inventory_quantity'
export const BOOKED_QUANTITY = 'booked_quantity'

// images
export const IMAGES = 'images'
export const IMAGE = 'image'

// files requirements
export const FILES_REQUIREMENTS_OPTION = 'files_requirements_option'
export const FILES_REQUIREMENTS = 'files'
export const FILES_REQUIREMENTS_TYPE = 'file_type'
export const FILES_REQUIREMENTS_NAME = 'file_name'
export const FILES_REQUIREMENTS_DESCRIPTION = 'description'
export const FILES_REQUIREMENTS_SUPPORTED_FORMATS = 'supported_file_types'
export const FILES_REQUIREMENTS_REQUIRED = 'required_file'
export const FILES_REQUIREMENTS_MIN_FILE_SIZE = 'min_file_size'
export const FILES_REQUIREMENTS_MAX_FILE_SIZE = 'max_file_size'
export const FILES_REQUIREMENTS_WIDTH = 'width'
export const FILES_REQUIREMENTS_HEIGHT = 'height'
export const FILES_REQUIREMENTS_MIN_WIDTH = 'min_width'
export const FILES_REQUIREMENTS_MIN_HEIGHT = 'min_height'
export const FILES_REQUIREMENTS_MAX_WIDTH = 'max_width'
export const FILES_REQUIREMENTS_MAX_HEIGHT = 'max_height'
export const FILES_REQUIREMENTS_RATIO_FROM = 'ratio_from'
export const FILES_REQUIREMENTS_RATIO_TO = 'ratio_to'
export const FILES_REQUIREMENTS_RESOLUTION_FROM = 'resolution_from'
export const FILES_REQUIREMENTS_RESOLUTION_TO = 'resolution_to'
export const FILE_SPECIFICATIONS_PAIRS = 'file_specifications_pairs'
export const FILE_SPECIFICATION_TYPE = 'file_specification_type'
export const FILE_SPECIFICATION_VALUE = 'file_specification_value'

export const TAGS = 'tags'
export const DEFAULT_INVENTORY_DATE_START = 'default_inventory_date_start'
export const LOCATION_TAB = 'location_tab'
export const LOCATION_TAB_LOCATION = 'location_tab_location'
export const LOCATION_TAB_SUB_LOCATION = 'location_tab_sub_location'
export const SUB_LOCATION = 'store'
export const INTERNAL_ID = 'internal_id'

export const PRODUCT_STATUS = 'status'
export const PRODUCT_STATUS_ACTIVE = 'active'
export const PRODUCT_STATUS_PAUSED = 'paused'

export const locationTabOptions = [
  { label: t('Location'), value: LOCATION_TAB_LOCATION },
  { label: t('Sub-location'), value: LOCATION_TAB_SUB_LOCATION }
]

export const discountTabOptions = [
  { label: 'Presets', value: DISCOUNTS_TAB_PRESET },
  { label: 'Custom', value: DISCOUNTS_TAB_CUSTOM }
]

export const discountPairInitialValue = {
  [DISCOUNT_PERCENTAGE]: '',
  [DISCOUNT_QUANTITY]: ''
}

export const platformsOptions = [
  {
    value: CHANNEL_OPTION_ONSITE,
    label: 'Onsite'
  },
  {
    value: CHANNEL_OPTION_OFFSITE,
    label: 'Off-network'
  }
]

export const initialFormatsValue = []

export const initialFileSpecifications = {
  [FILES_REQUIREMENTS_MIN_FILE_SIZE]: '',
  [FILES_REQUIREMENTS_MAX_FILE_SIZE]: '',
  [FILES_REQUIREMENTS_WIDTH]: '',
  [FILES_REQUIREMENTS_HEIGHT]: '',
  [FILES_REQUIREMENTS_MIN_WIDTH]: '',
  [FILES_REQUIREMENTS_MIN_HEIGHT]: '',
  [FILES_REQUIREMENTS_MAX_WIDTH]: '',
  [FILES_REQUIREMENTS_MAX_HEIGHT]: '',
  [FILES_REQUIREMENTS_RATIO_FROM]: '',
  [FILES_REQUIREMENTS_RATIO_TO]: '',
  [FILES_REQUIREMENTS_RESOLUTION_FROM]: '',
  [FILES_REQUIREMENTS_RESOLUTION_TO]: ''
}

export const fileRequirementsInitialValue = {
  [FILES_REQUIREMENTS_TYPE]: IMAGE_TYPE,
  [FILES_REQUIREMENTS_NAME]: '',
  [FILES_REQUIREMENTS_DESCRIPTION]: '',
  [FILES_REQUIREMENTS_SUPPORTED_FORMATS]: initialFormatsValue,
  [FILES_REQUIREMENTS_REQUIRED]: false,
  [FILE_SPECIFICATIONS_PAIRS]: [],
  [LOCATION]: '',
  [TAGS]: [],
  [TRIGGER_BOOKING_NOTIFICATION]: false
}

export const initialFiles = []
export const getInitialValues = (controllerCurrency = '') => {
  return {
    [NAME]: '',
    [DESCRIPTION]: '',
    [MEDIA_CATEGORY]: PRINT,
    [MEDIA_SUB_CATEGORY]: '',
    [MEDIA_SUB_SUB_CATEGORY]: '',
    [PRICE_PAIRS]: [
      {
        [PRICE_CURRENCY]: controllerCurrency,
        [PRICE_VALUE]: '',
        id: uuidv4()
      }
    ],
    [FILE_SPECIFICATIONS_PAIRS]: [],
    [PERIOD_OPTION]: PERIOD_ONE_MONTH,
    [DISCOUNTS_TABS]: DISCOUNTS_TAB_PRESET,
    [CUSTOM_DISCOUNT_CHECKBOX]: false,
    [DISCOUNT_PAIRS]: [{ ...discountPairInitialValue, id: uuidv4() }],
    [CREATIVE_DEADLINE_DAYS]: '',
    [CHANNEL_OPTION]: CHANNEL_OPTION_ONSITE,
    [PLATFORMS]: [],
    [INVENTORY_AMOUNT]: '',
    [INVENTORY_PERIOD]: '',
    [MIN_PERIODS]: '',
    [MAX_PERIODS]: '',
    [FILES_REQUIREMENTS]: initialFiles,
    // order starts from 1
    [IMAGES]: [{ ...initialImageFileValues, [ORDER]: 1 }],
    [LOCATION]: '',
    [TAGS]: [],
    [VARIABLES]: [],
    [SUB_LOCATION]: '',
    [INTERNAL_ID]: ''
  }
}
