import React from 'react'
import { components } from 'react-select'

import { ReactComponent as RemoveIcon } from '../../../assets/icons/close-white.svg'

export default function SelectClearIndicator(props) {
  const { isIconSelect } = props.selectProps

  return (
    <components.ClearIndicator {...props} className={isIconSelect && 'iconDropdown'}>
      {<RemoveIcon />}
    </components.ClearIndicator>
  )
}
