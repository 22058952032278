import * as Yup from 'yup'

export const START_DATE = 'start_date'
export const END_DATE = 'end_date'

export const startEndDateRangeInitialValues = {
  [START_DATE]: '',
  [END_DATE]: ''
}

export const startDateValidation = Yup.string().required('Please select start date')
export const endDateValidation = Yup.string().required('Please select end date')
