import update from 'immutability-helper'
// import { deleteItemById } from '../../helpers/reducerHelpers'
import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_LOCATION_LISTS,
  GET_LOCATION_LISTS_SUCCESS,
  GET_LOCATION_LISTS_FAILURE,
  CLEAR_LOCATION_LISTS,
  GET_LOCATION_LIST,
  GET_LOCATION_LIST_SUCCESS,
  GET_LOCATION_LIST_FAILURE,
  CLEAR_LOCATION_LIST,
  CREATE_LOCATION_LIST,
  CREATE_LOCATION_LIST_SUCCESS,
  CREATE_LOCATION_LIST_FAILURE,
  CLEAR_CREATE_LOCATION_LIST,
  UPDATE_LOCATION_LIST,
  UPDATE_LOCATION_LIST_SUCCESS,
  UPDATE_LOCATION_LIST_FAILURE,
  CLEAR_UPDATE_LOCATION_LIST,
  DELETE_LOCATION_LIST,
  DELETE_LOCATION_LIST_SUCCESS,
  DELETE_LOCATION_LIST_FAILURE,
  VALIDATE_LOCATION_ADDRESSES,
  VALIDATE_LOCATION_ADDRESSES_SUCCESS,
  VALIDATE_LOCATION_ADDRESSES_FAILURE,
  CLEAR_VALIDATE_LOCATION_ADDRESSES
} from '../actions/location'
import { deleteItemById, updateItem } from '../../helpers/modules/reducerHelpers'
import { INITIAL_UPDATE_REDUCER } from '../../constants/reducer'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  locationLists: {
    results: [],
    isLoading: false,
    error: null,
    wasLoaded: false
  },
  locationList: {
    data: {},
    isLoading: false,
    error: null
  },
  createLocationList: {
    data: null,
    isLoading: false,
    error: null
  },
  updateLocationList: INITIAL_UPDATE_REDUCER,
  deleteLocationList: {
    isLoading: false,
    error: null
  },
  locationAddressesValidation: {
    addresses: [],
    isLoading: false,
    isValidationSuccess: false,
    error: null
  }
}

export default function reports(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    // get location lists
    case GET_LOCATION_LISTS:
      return update(state, {
        locationLists: {
          isLoading: { $set: true }
        }
      })
    case GET_LOCATION_LISTS_SUCCESS:
      return update(state, {
        locationLists: {
          isLoading: { $set: false },
          $merge: action.locationListsData,
          results: {
            // not replace, but add items to existing data
            $push: action.locationListsData.results
          },
          wasLoaded: { $set: true }
        }
      })
    case GET_LOCATION_LISTS_FAILURE:
      return update(state, {
        locationLists: {
          isLoading: { $set: false },
          error: { $set: action.error }
        }
      })
    case CLEAR_LOCATION_LISTS:
      return update(state, {
        locationLists: { $set: initialState.locationLists }
      })

    // get location list
    case GET_LOCATION_LIST:
      return update(state, {
        locationList: {
          isLoading: { $set: true }
        }
      })
    case GET_LOCATION_LIST_SUCCESS:
      return update(state, {
        locationList: {
          isLoading: { $set: false },
          data: { $set: action.locationListData }
        }
      })
    case GET_LOCATION_LIST_FAILURE:
      return update(state, {
        locationList: {
          isLoading: { $set: false },
          error: { $set: action.error }
        }
      })
    case CLEAR_LOCATION_LIST:
      return update(state, {
        locationList: { $set: initialState.locationList }
      })

    // create location list
    case CREATE_LOCATION_LIST:
      return update(state, {
        createLocationList: {
          isLoading: { $set: true }
        }
      })
    case CREATE_LOCATION_LIST_SUCCESS:
      return update(state, {
        // clear addresses validation on success create location list
        // we need to make if before setting createLocationList data to prevent bug with double request
        locationAddressesValidation: { $set: initialState.locationAddressesValidation },
        createLocationList: {
          data: { $set: action.locationListData },
          isLoading: { $set: false },
          wasCreated: { $set: true }
        },
        locationLists: {
          results: { $unshift: [action.locationListData] }
        }
      })
    case CREATE_LOCATION_LIST_FAILURE:
      return update(state, {
        createLocationList: {
          isLoading: { $set: false },
          error: { $set: action.error }
        }
      })
    case CLEAR_CREATE_LOCATION_LIST:
      return update(state, {
        createLocationList: {
          $set: initialState.createLocationList
        }
      })

    // update location list
    case UPDATE_LOCATION_LIST:
      return update(state, {
        updateLocationList: {
          $set: {
            isLoading: true,
            [action.id]: true
          }
        }
      })
    case UPDATE_LOCATION_LIST_SUCCESS: {
      return update(state, {
        locationLists: {
          results: {
            $apply: items => updateItem(items, action.locationListData)
          }
        },
        updateLocationList: {
          $set: { isLoading: false, id: action.locationListData.id, wasUpdated: true }
        }
      })
    }
    case UPDATE_LOCATION_LIST_FAILURE: {
      return update(state, {
        updateLocationList: {
          $set: { error: action.error }
        }
      })
    }
    case CLEAR_UPDATE_LOCATION_LIST:
      return update(state, {
        updateLocationList: {
          $set: initialState.updateLocationList
        }
      })

    // delete location list
    case DELETE_LOCATION_LIST:
      return update(state, {
        deleteLocationList: {
          isLoading: { $set: true }
        }
      })
    case DELETE_LOCATION_LIST_SUCCESS:
      return update(state, {
        locationLists: {
          results: {
            $set: deleteItemById(state.locationLists.results, action.id)
          }
        },
        deleteLocationList: {
          isLoading: { $set: false }
        }
      })
    case DELETE_LOCATION_LIST_FAILURE:
      return update(state, {
        deleteLocationList: {
          isLoading: { $set: false },
          error: { $set: action.error }
        }
      })

    // validate location addresses
    case VALIDATE_LOCATION_ADDRESSES:
      return update(state, {
        locationAddressesValidation: {
          isLoading: { $set: true }
        }
      })
    case VALIDATE_LOCATION_ADDRESSES_SUCCESS:
      return update(state, {
        locationAddressesValidation: {
          isLoading: { $set: false },
          addresses: { $set: action.locationAddressesData },
          isValidationSuccess: { $set: true }
        }
      })
    case VALIDATE_LOCATION_ADDRESSES_FAILURE:
      return update(state, {
        locationAddressesValidation: {
          isLoading: { $set: false },
          error: { $set: action.error },
          // also pass addresses if custom validation fails
          ...(action.locationAddressesData && { addresses: { $set: action.locationAddressesData } })
        }
      })
    case CLEAR_VALIDATE_LOCATION_ADDRESSES:
      return update(state, {
        locationAddressesValidation: { $set: initialState.locationAddressesValidation }
      })
    default:
      return state
  }
}
