import { createStyles } from '../../../../../../../../styles/helpers'

const useStyles = createStyles({
  productSectionHeader: {
    alignItems: 'center',
    paddingRight: '24px'
  },
  productQuantity: {
    minWidth: 70
  }
})

export default useStyles
