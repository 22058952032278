import React, { useCallback, useState } from 'react'
import classnames from 'classnames'
import { useKeenSlider } from 'keen-slider/react'

import Arrow from './Arrow'

import useStyles from './styles'

const ProductCardImages = ({ images = [], productName, onImageClick }) => {
  const classes = useStyles()

  const [loaded, setLoaded] = useState(false)

  const [currentSlide, setCurrentSlide] = React.useState(0)

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      initial: 0,
      slideChanged(s) {
        setCurrentSlide(s.track.details.rel)
      },
      created() {
        setLoaded(true)
      }
    },
    []
  )

  const sliderDotClickHandler = useCallback(
    (e, idx) => {
      e.stopPropagation()
      instanceRef.current?.moveToIdx(idx)
    },
    [instanceRef]
  )

  const onImageClickHandler = useCallback(
    (e, id) => {
      e.stopPropagation()
      onImageClick && onImageClick(id)
    },
    [onImageClick]
  )

  if (!images?.length) {
    return null
  }

  if (images.length === 1) {
    return (
      <img
        onClick={e => onImageClickHandler(e, images?.[0]?.id)}
        className={classes.thumbnail}
        src={images?.[0]?.image}
        alt={productName}
      />
    )
  }

  return (
    <div className={classes.imagesContainer}>
      <div ref={sliderRef} className={classnames('keen-slider', classes.slider)}>
        <div
          className={classnames(classes.leftEdge, classes.edge)}
          onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
        >
          <Arrow left classes={classes} disabled={currentSlide === 0} />
        </div>
        <div
          className={classnames(classes.rightEdge, classes.edge)}
          onClick={e => e.stopPropagation() || instanceRef.current?.next()}
        >
          <Arrow
            classes={classes}
            disabled={currentSlide === instanceRef.current?.track?.details?.slides?.length - 1}
          />
        </div>
        {images.map(imageItem => (
          <div key={imageItem.image} className={classnames('keen-slider__slide', classes.thumbnail)}>
            <img
              className={classes.thumbnail}
              src={imageItem?.image}
              alt={productName}
              onClick={e => onImageClickHandler(e, imageItem.id)}
            />
          </div>
        ))}
      </div>
      {loaded && instanceRef.current && (
        <div className={classes.dots}>
          {[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
            return (
              <span
                key={idx}
                onClick={event => sliderDotClickHandler(event, idx)}
                className={classnames(classes.dot, { active: currentSlide === idx })}
              ></span>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default ProductCardImages
