import { call, put, all, takeEvery } from 'redux-saga/effects'

import {
  GET_GOOGLE_GEOLOCATIONS,
  getGoogleGeolocationsSuccess,
  getGoogleGeolocationsFailure,
  GET_GOOGLE_LANGUAGES,
  getGoogleLanguagesSuccess,
  getGoogleLanguagesFailure,
  GET_GOOGLE_USERS_LIST,
  getGoogleUsersListSuccess,
  getGoogleUsersListFailure
} from '../actions/googleConstants'
import {
  getGoogleGeolocationsService,
  getGoogleLanguagesService,
  getGoogleUsersListService
} from '../services/googleConstants'

function* googleConstantsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_GOOGLE_USERS_LIST, getGoogleUsersListWorker),
    takeEvery(GET_GOOGLE_GEOLOCATIONS, getGoogleGeolocationsWorker),
    takeEvery(GET_GOOGLE_LANGUAGES, getGoogleLanguagesWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getGoogleUsersListWorker({ params }) {
  try {
    const response = yield call(getGoogleUsersListService, params)
    yield put(getGoogleUsersListSuccess(response))
  } catch (error) {
    yield put(getGoogleUsersListFailure(error))
  }
}

function* getGoogleGeolocationsWorker({ params }) {
  try {
    const response = yield call(getGoogleGeolocationsService, params)
    yield put(getGoogleGeolocationsSuccess(response))
  } catch (e) {
    yield put(getGoogleGeolocationsFailure(e))
  }
}

function* getGoogleLanguagesWorker({ params }) {
  try {
    const response = yield call(getGoogleLanguagesService, params)
    yield put(getGoogleLanguagesSuccess(response))
  } catch (e) {
    yield put(getGoogleLanguagesFailure(e))
  }
}

export default googleConstantsWatcher
