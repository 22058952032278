import React, { useCallback, useMemo } from 'react'
import { getIn } from 'formik'
import PropTypes from 'prop-types'

import Field from '../../../../../../../../../components/Form/Field'
import Table from '../../../../../../../../../components/Table'

import { formatCurrency } from '../../../../../../../../../helpers/numbers'

import { PRICE } from '../../fields'
import { PRODUCT_PERIODS_DATES } from '../../../../../../fields'

import { formatDateFullYear } from '../../../../../../../../../constants/dates'

const ProductPeriodsPriceTable = ({ formik, product, productPath, currencySymbol }) => {
  const { [PRODUCT_PERIODS_DATES]: pricePerPeriods, quantity } = product

  const { values } = formik

  const getPeriodPricePath = useCallback(
    index => {
      return `${productPath}.${PRODUCT_PERIODS_DATES}[${index}].${PRICE}`
    },
    [productPath]
  )

  const productPricesColumns = useMemo(() => {
    return [
      {
        header: 'Period Start',
        Cell: pricePerPeriod => {
          const { date_start } = pricePerPeriod

          return formatDateFullYear(new Date(date_start))
        }
      },
      {
        header: 'Unit Price',
        Cell: (_, index) => {
          const periodPath = getPeriodPricePath(index)

          return (
            <Field
              type="number"
              inputMode="decimal"
              formik={formik}
              name={periodPath}
              symbol={currencySymbol}
              placeholder="Unit Price"
              enableReinitialize
              autoComplete="off"
              fixedDecimalScale={true}
              decimalScale={2}
            />
          )
        }
      },
      {
        header: 'Quantity',
        style: { maxWidth: 70 },
        Cell: () => {
          return `x${quantity}`
        }
      },
      {
        header: 'Total',
        style: { maxWidth: 150, display: 'flex', justifyContent: 'flex-end' },
        Cell: (_, index) => {
          const periodPath = getPeriodPricePath(index)
          const unitPrice = getIn(values, periodPath)

          const total = unitPrice * quantity

          return formatCurrency(total, { min: 2, max: 2 }, { symbol: currencySymbol })
        }
      }
    ]
  }, [formik, currencySymbol, quantity, getPeriodPricePath, values])

  if (!pricePerPeriods) {
    return null
  }

  return <Table cols={productPricesColumns} data={pricePerPeriods} hideFooterRow />
}

ProductPeriodsPriceTable.propTypes = {
  formik: PropTypes.object,
  product: PropTypes.object,
  productPath: PropTypes.string,
  currencySymbol: PropTypes.string.isRequired
}

export default ProductPeriodsPriceTable
