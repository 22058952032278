import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'

import DeepCopyStep from './Steps/DeepCopyStep'
import Drawer from '../../../../components/Drawer'
import LineItemStartTimeStep from '../../../ReusableFormSteps/LineItemStartTimeStep'
import LineItemStopTimeStep from '../../../ReusableFormSteps/LineItemStopTimeStep'
import StatusStep from './Steps/StatusStep'
import LineItemDuplicationProgress from './Steps/LineItemDuplicationProgressStep'
import CampaignStep from './Steps/CampaignStep'
import StepForm from '../../../../features/components/Forms/StepForm'

import { usePurifiedFormik } from '../../../../hooks/formHooks/usePurifiedFormik'
import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'

import { clearAsyncSession } from '../../../../modules/actions/app'
import {
  duplicateLineItemErrorSelector,
  duplicateLineItemIsLoadingSelector
} from '../../../../modules/selectors/lineItems'
import { clearDuplicateLineItem } from '../../../../modules/actions/lineItems'

import { LINE_ITEM_FACEBOOK_DUPLICATE } from '../../../../constants/forms'
import { initialValues, validationSchema } from './fields'

function LineItemFacebookDuplicate() {
  const dispatch = useDispatch()

  const clearDuplicateCampaignHandler = useCallback(() => {
    dispatch(clearDuplicateLineItem())
    dispatch(clearAsyncSession())
  }, [dispatch])

  const { isFormOpen, handleFormClose, selectedEditItemId } = useManageFormsDrawer({
    onAfterFormClose: clearDuplicateCampaignHandler,
    formName: LINE_ITEM_FACEBOOK_DUPLICATE
  })

  const onSuccessSubmit = useCallback(() => {
    handleFormClose()
  }, [handleFormClose])

  const formik = useFormik({
    initialValues,
    validationSchema
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const steps = useMemo(
    () => [
      {
        component: <CampaignStep />,
        show: true
      },
      {
        component: <DeepCopyStep />,
        show: true
      },
      {
        component: <LineItemStartTimeStep stepTitle="When would you like the ad set to start?" />,
        show: true
      },
      {
        component: (
          <LineItemStopTimeStep
            stepTitle="When would you like the ad set to end?"
            indefiniteOptionDescription="The ad set will run indefinitely until paused"
          />
        ),
        show: true
      },
      {
        component: <StatusStep selectedEditItemId={selectedEditItemId} />,
        show: true
      },
      {
        component: <LineItemDuplicationProgress onSuccessSubmit={onSuccessSubmit} />,
        show: true,
        hideBackButton: true
      }
    ],
    [selectedEditItemId, onSuccessSubmit]
  )

  return (
    <Drawer isOpen={isFormOpen} onClose={handleFormClose}>
      <StepForm
        formName={LINE_ITEM_FACEBOOK_DUPLICATE}
        steps={steps}
        formik={purifiedFormik}
        submitOnLastStep={false}
        errorSelector={duplicateLineItemErrorSelector}
        isLoadingSelector={duplicateLineItemIsLoadingSelector}
      />
    </Drawer>
  )
}

export default LineItemFacebookDuplicate
