import * as Yup from 'yup'

import { getUrlDomain } from '../../helpers/url'

export const passwordValidation = Yup.string()
  .required('Please enter your password')
  // 1 upper, 1 lower, 1 number, 1 special character, min 8 characters
  // accepted characters are: = + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ `
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[=+\-^$*.?[\]{}()"!@#%&/\\,><':;|_~`])[A-Za-z\d=+\-^$*.?[\]{}()"!@#%&,><':;|_~`/\\]{8,}$/,
    'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character'
  )

export const urlValidation = Yup.string()
  .test('link', 'Please enter a valid URL, including the https://', link => getUrlDomain(link))
  .max(1024, 'There is a maximum limit of 1024 characters')
  .required('This field should not be empty')
