import React, { useCallback, useMemo, useState } from 'react'
import { Calendar } from 'react-date-range'
import { useTheme } from 'react-jss'
import PropTypes from 'prop-types'
import { isAfter } from 'date-fns'
import classnames from 'classnames'

import SelectDropdown from '../SelectDropdown'
import ErrorMessage from '../Form/ErrorMessage'

import useStartWeekDay from '../../features/hooks/useStartWeekDay'

import { formatDateFullYear, MINIMAL_PAST_DATE } from '../../constants/dates'

import useStyles from './styles'

const DatePicker = ({
  className,
  value,
  formattedLabel,
  title,
  changeHandler,
  minDate,
  maxDate,
  isPastAllowed = false,
  isSmall = true,
  disabled,
  disabledDates,
  error,
  placeholder,
  isPreOpen = false,
  onDropdownVisibilityChange
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const [isOpen, setIsOpen] = useState(isPreOpen)

  const weekStartsOn = useStartWeekDay()
  // If minDate not provided - set current date as minimal
  const minDateConverted = useMemo(() => (minDate ? new Date(minDate) : new Date()), [minDate])
  const valueDate = useMemo(() => (value ? new Date(value) : minDateConverted), [value, minDateConverted])
  const valueDateAfter = useMemo(() => isAfter(valueDate, minDateConverted), [valueDate, minDateConverted])
  // SelectDropdown component variables
  const selectLabel = useMemo(() => {
    if (formattedLabel) {
      return formattedLabel
    } else if (value) {
      return formatDateFullYear(valueDate)
    } else if (placeholder) {
      // Don't pass value to SelectDropdown if it's empty and we have placeholder
      return ''
      // If no value and no placeholder - show 'Select Date'
    } else {
      return 'Select Date'
    }
  }, [value, placeholder, valueDate, formattedLabel])
  // Calendar component variables
  const shownDate = useMemo(
    () => (valueDateAfter ? valueDate : minDateConverted),
    [valueDate, valueDateAfter, minDateConverted]
  )
  const minCalendarDate = useMemo(
    () => (isPastAllowed ? new Date(MINIMAL_PAST_DATE) : minDateConverted),
    [isPastAllowed, minDateConverted]
  )
  const maxCalendarDate = useMemo(() => maxDate && new Date(maxDate), [maxDate])
  const date = useMemo(() => (valueDateAfter ? valueDate : null), [valueDateAfter, valueDate])

  const handleDropdownVisibility = useCallback(
    show => {
      setIsOpen(show)
      onDropdownVisibilityChange && onDropdownVisibilityChange(show)
    },
    [setIsOpen, onDropdownVisibilityChange]
  )

  const handleChange = useCallback(
    date => {
      setIsOpen(false)

      // handling outside changes, like setFieldValue in formik
      changeHandler && changeHandler(date)
    },
    [changeHandler]
  )

  return (
    <div className={classnames('field', className)}>
      <SelectDropdown
        className={classes.datePickerSelect}
        value={selectLabel}
        title={title}
        isManuallyOpened={!disabled && isOpen}
        onVisibilityChange={handleDropdownVisibility}
        isDisabled={disabled}
        isSmall={isSmall}
        placeholder={placeholder}
      >
        <Calendar
          color={theme.brandPrimaryTextContrastColor}
          // start week day
          weekStartsOn={weekStartsOn}
          className={classes.calendar}
          // do not open date which is before minDate(avoid opening 2041 year)
          shownDate={shownDate}
          // if pastDate allowed - set minimal past date
          minDate={minCalendarDate}
          maxDate={maxCalendarDate}
          onChange={handleChange}
          disabledDates={disabledDates}
          // do not represent date which is before minDate
          date={date}
        />
      </SelectDropdown>
      {error && <ErrorMessage error={error} />}
    </div>
  )
}

export default DatePicker

DatePicker.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  formattedLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  title: PropTypes.string,
  changeHandler: PropTypes.func,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  isSmall: PropTypes.bool,
  error: PropTypes.string
}
