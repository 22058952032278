import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Portal } from 'react-portal'

import AdCreative from './AdCreative'
import Step from '../../../../../features/components/Forms/StepForm/Step'
import GooglePreviewsColumn from './GooglePreviewsColumn'
import ErrorMessage from '../../../../../components/Form/ErrorMessage'

import { ASSET_IMAGE_LIST } from '../../GoogleAssetGroup/GoogleAssetGroupMainForm/fields'
import { BUSINESS_NAME, CLICK_THROUGH_LINK, DESCRIPTIONS_LIST, HEADLINES_LIST } from '../../fields'
import { TEXT_ASSETS } from '../../../UploadGoogleAdCreative/duplicatedTextAssetsValiation'

import useStyles from './styles'

function AdCreativeDetailsStep({
  formik,
  adAccountId,
  showImagesSection,
  isAssetGroup,
  showPreview = true,
  isLastStep,
  handleStepChange,
  isActive: isStepActive,
  showNameField,
  showCustomClickThroughLink
}) {
  const classes = useStyles()

  const { values, errors } = formik

  const duplicatedTextAssetsError = errors[TEXT_ASSETS]

  const clickThroughLink = values[CLICK_THROUGH_LINK]
  const headlinesList = values[HEADLINES_LIST]
  const descriptionList = values[DESCRIPTIONS_LIST]
  const assetsList = values[ASSET_IMAGE_LIST]
  const businessName = values[BUSINESS_NAME]

  const adPreviewValues = useMemo(
    () => ({
      [CLICK_THROUGH_LINK]: clickThroughLink,
      [BUSINESS_NAME]: businessName,
      [HEADLINES_LIST]: headlinesList,
      [DESCRIPTIONS_LIST]: descriptionList,
      [ASSET_IMAGE_LIST]: assetsList
    }),
    [clickThroughLink, headlinesList, descriptionList, assetsList, businessName]
  )

  return (
    <Step
      stepTitle="Review and customise the ad creative"
      stepDescription="You can use from the AI suggested options or customise the ad with your own content"
      handleContinue={isLastStep ? null : handleStepChange}
    >
      {showPreview && isStepActive && (
        <Portal node={document && document.getElementById('formSideColumnPortal')}>
          <GooglePreviewsColumn values={adPreviewValues} showPortraitImage={isAssetGroup} />
        </Portal>
      )}
      <AdCreative
        isAssetGroup={isAssetGroup}
        formik={formik}
        adAccountId={adAccountId}
        showImagesSection={showImagesSection}
        showNameField={showNameField}
        showCustomClickThroughLink={showCustomClickThroughLink}
      />
      {duplicatedTextAssetsError && (
        <ErrorMessage className={classes.duplicatedTextAssetsError} error={duplicatedTextAssetsError} />
      )}
    </Step>
  )
}

AdCreativeDetailsStep.propTypes = {
  handleStepChange: PropTypes.func
}

export default AdCreativeDetailsStep
