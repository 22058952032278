import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../components/Form'
import CampaignTikTokCreateFormContent from './CampaignTikTokCreateFormContent'

import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'

import { clearCreateCampaign, createCampaign } from '../../../../../modules/actions/campaigns'
import {
  campaignCreateErrorSelector,
  campaignCreateIsLoadingSelector,
  campaignCreateWasCreatedSelector,
  createdCampaignIdSelector
} from '../../../../../modules/selectors/campaigns'
import { selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'

import { initialValues, transformValuesToBE, validationSchema } from './fields'
import { CAMPAIGN_TIKTOK_CREATE } from '../../../../../constants/forms'
import { ROUTE_PARAMS } from '../../../../../constants/routes'
import { TIKTOK_PLATFORM } from '../../../../../constants/selectLists/platformList'

const CampaignTikTokCreateForm = ({ onSuccessSubmit }) => {
  const dispatch = useDispatch()

  const createdCampaignId = useSelector(createdCampaignIdSelector)
  const campaignWasCreated = useSelector(campaignCreateWasCreatedSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const handleSuccessCreate = useCallback(() => {
    onSuccessSubmit({
      [ROUTE_PARAMS.adAccount]: selectedAdAccountId,
      [ROUTE_PARAMS.campaignId]: createdCampaignId
    })
  }, [onSuccessSubmit, selectedAdAccountId, createdCampaignId])

  const onSubmit = useCallback(
    values => {
      const campaignData = {
        campaignData: transformValuesToBE(values, selectedAdAccountId)
      }

      dispatch(createCampaign(campaignData, TIKTOK_PLATFORM))
    },
    [dispatch, selectedAdAccountId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const clearCreateCampaignHandler = useCallback(() => {
    dispatch(clearCreateCampaign())
  }, [dispatch])

  return (
    <Form
      formik={purifiedFormik}
      formName={CAMPAIGN_TIKTOK_CREATE}
      clearHandler={clearCreateCampaignHandler}
      successSubmit={campaignWasCreated}
      onSuccessSubmit={handleSuccessCreate}
      errorSelector={campaignCreateErrorSelector}
      isLoadingSelector={campaignCreateIsLoadingSelector}
    >
      <CampaignTikTokCreateFormContent formik={formik} />
    </Form>
  )
}

export default CampaignTikTokCreateForm
