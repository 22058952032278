import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Checkbox from '../../../../components/Form/Checkbox'
import ErrorMessage from '../../../../components/Form/ErrorMessage'

import useStyles from './styles'

const CheckboxBlock = ({
  checked,
  children,
  error,
  touched,
  className,
  checkBoxBlockBodyClassName,
  withBorder,
  ...props
}) => {
  const classes = useStyles({ withBorder, checked })

  return (
    <div className={classnames(classes.checkBoxBlockContainer, className)}>
      <Checkbox checked={checked} {...props} />
      {checked && children && (
        <div className={classnames(classes.checkBoxBlockBody, checkBoxBlockBodyClassName)}>
          {children} {error && touched && <ErrorMessage error={error} />}
        </div>
      )}
    </div>
  )
}

CheckboxBlock.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
  error: PropTypes.string,
  className: PropTypes.string,
  checkBoxBlockBodyClassName: PropTypes.string,
  // when we submit form, touched field can become array for some reason, which cause prop types error
  touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  isLarge: PropTypes.bool
}

export default CheckboxBlock
