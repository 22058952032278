import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import { activeStepSelector } from '../../../../../modules/selectors/forms'

export function useFormProgressItems() {
  const activeStep = useSelector(activeStepSelector)

  return useMemo(() => {
    return [
      {
        label: 'Select product',
        isActive: activeStep === 0,
        isSuccess: activeStep === 1
      },
      {
        label: 'Review ad creative',
        isActive: activeStep === 1,
        isSuccess: false
      }
    ]
  }, [activeStep])
}
