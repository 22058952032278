import { createStyles } from '../../../../../styles/helpers'

const useAudienceStyles = createStyles({
  colorsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(9, 1fr)',
    gridGap: 10,
    maxWidth: 260,
    margin: '20px auto 4px'
  },
  isActive: {
    outline: '3px solid black !important'
  },
  colorPicker: {
    width: 20,
    height: 20,
    cursor: 'pointer',

    '&:hover': {
      outline: '1px solid black'
    }
  },
  mediaLibrary: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 16
  }
})

export default useAudienceStyles
