import { createStyles } from '../../../../../styles/helpers'

import { carouselAdThumbnailHeight } from '../AdCardCarouselContent/AdCardCarouselItem/styles'

const useStyles = createStyles({
  carouselItemMedia: {
    height: carouselAdThumbnailHeight,
    minHeight: carouselAdThumbnailHeight,
    maxHeight: carouselAdThumbnailHeight,
    overflow: 'hidden',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%'
    },
    '& video': {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  carouselVideoPreview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: carouselAdThumbnailHeight
  },
  carouselItemImage: {
    objectFit: 'cover',
    height: '100%',
    width: '100%'
  }
})

export default useStyles
