import { useState, useEffect, useCallback } from 'react'
import { useDebounce } from './useDebounce'

// hook helps to make a search request with delay during typing
export function useDebouncedSearch(onSearchChange) {
  const [searchText, setSearchText] = useState('')

  // searched value with delay
  const debouncedSearchValue = useDebounce(searchText, 500)

  const handleSearchTextChange = useCallback(
    value => {
      setSearchText(value)
    },
    [setSearchText]
  )

  const clearSearchValue = useCallback(() => {
    setSearchText('')
  }, [setSearchText])

  useEffect(() => {
    // when debouncedSearchValue changes - trigger change event callback
    // avoid triggering for onSearchChange callback change - so it's excluded from array
    onSearchChange(debouncedSearchValue)
    // eslint-disable-next-line
  }, [debouncedSearchValue])

  return {
    searchText,
    clearSearchValue,
    handleSearchTextChange
  }
}
