import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { useFetchBrandRevenueReport } from '../../hooks'
import { formatSetupFilters } from '../../../helpers'

import { selfAccountCategoriesSelector } from '../../../../../../../../modules/selectors/selfAccounts'
import {
  controllerLandingPageSetupWasLoadedSelector,
  selectedControllerDataSelector
} from '../../../../../../../../modules/selectors/app'

export const BrandCategoryRevenueContext = React.createContext()

export function BrandCategoryRevenueDataProvider({ children, setupData }) {
  const selfAccountCategories = useSelector(selfAccountCategoriesSelector)
  const controllerLandingPageSetupWasLoaded = useSelector(controllerLandingPageSetupWasLoadedSelector)

  const { category: selectedControllerCategory } = useSelector(selectedControllerDataSelector)

  const initialBrandCategory = selectedControllerCategory?.id || selfAccountCategories?.[0]?.id

  const [brandCategoryFilter, setBrandCategoryFilter] = useState(initialBrandCategory)

  const formattedSetupTableFilters = useMemo(() => {
    const table = setupData?.table.filters

    return formatSetupFilters(table)
  }, [setupData])

  const filterRequestParams = useMemo(
    () => ({
      ...formattedSetupTableFilters,
      ...(brandCategoryFilter && { brand_category: brandCategoryFilter })
    }),
    [brandCategoryFilter, formattedSetupTableFilters]
  )

  const bookedRevenueFilterProps = useMemo(
    () => ({
      filterRequestParams,
      brandCategoryFilter,
      setBrandCategoryFilter
    }),
    [filterRequestParams, brandCategoryFilter, setBrandCategoryFilter]
  )

  const handleFetchBrandAnnualRevenueReport = useFetchBrandRevenueReport()

  useEffect(() => {
    if (controllerLandingPageSetupWasLoaded && brandCategoryFilter) {
      handleFetchBrandAnnualRevenueReport(filterRequestParams)
    }
  }, [
    filterRequestParams,
    handleFetchBrandAnnualRevenueReport,
    controllerLandingPageSetupWasLoaded,
    brandCategoryFilter
  ])

  useEffect(() => {
    // set initial brand category if it is not set
    if (!brandCategoryFilter && initialBrandCategory) {
      setBrandCategoryFilter(initialBrandCategory)
    }
  }, [brandCategoryFilter, initialBrandCategory])

  // make a type check for the props
  PropTypes.checkPropTypes(propTypes, bookedRevenueFilterProps, 'prop', 'useRequestHandler')

  return (
    <BrandCategoryRevenueContext.Provider value={bookedRevenueFilterProps}>
      {children}
    </BrandCategoryRevenueContext.Provider>
  )
}

export const filterRequestParamsTypes = PropTypes.shape({
  brand_category: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}).isRequired

const propTypes = {
  filterRequestParams: filterRequestParamsTypes,
  brandCategoryFilter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setBrandCategoryFilter: PropTypes.func.isRequired
}
