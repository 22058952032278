import { useEffect } from 'react'
import { useParams } from 'react-router'

import { getDefaultPageUrl, redirectTo } from '../../../helpers/url'
import { ROUTE_PARAMS } from '../../../constants/routes'

export function ValidateId({ children }) {
  const { [ROUTE_PARAMS.selfAccount]: selfAccountId } = useParams()

  const isValidId = /^\d+$/.test(selfAccountId)

  useEffect(() => {
    if (!isValidId) {
      redirectTo(getDefaultPageUrl())
    }
  }, [isValidId, selfAccountId])

  if (!isValidId) {
    // selfAccountId is not a number
    return null
  }

  return children
}
