import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import MediaPackageCreateForm from './MediaPackageCreateForm'

import { clearControllerMembers, getControllerMembers } from '../../../../modules/actions/controller'
import {
  controllerMembersErrorSelector,
  controllerMembersWasLoadedSelector
} from '../../../../modules/selectors/controller'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import { mediaPackageCategoriesWasLoadedSelector } from '../../../../modules/selectors/mediaPackages'

import { MEDIA_PACKAGE_CREATE } from '../../../../constants/forms'

function MediaPackageCreate() {
  const dispatch = useDispatch()

  const mediaCategoriesWasLoaded = useSelector(mediaPackageCategoriesWasLoadedSelector)
  const controllerMembersWasLoaded = useSelector(controllerMembersWasLoadedSelector)
  const controllerMembersError = useSelector(controllerMembersErrorSelector)
  const controllerId = useSelector(selectedControllerIdSelector)

  const controllerMembersDidLoading = controllerMembersWasLoaded || controllerMembersError

  useEffect(() => {
    dispatch(getControllerMembers({ controller: controllerId }))

    return () => {
      dispatch(clearControllerMembers())
    }
  }, [dispatch, controllerId])

  return (
    <FormDrawerWrapper
      formName={MEDIA_PACKAGE_CREATE}
      showOpenButton={false}
      isFormLoading={!mediaCategoriesWasLoaded || !controllerMembersDidLoading}
      hasDefaultDrawerContentSpacing={false}
      isWideDrawer
    >
      <MediaPackageCreateForm />
    </FormDrawerWrapper>
  )
}

export default MediaPackageCreate
