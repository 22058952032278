import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import DrawerHeadline from '../../../../../components/Drawer/DrawerHeadline'
import FieldsSection from '../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../features/components/Form/FieldsSection/FieldRow'
import Form from '../../../../../components/Form'
import Field from '../../../../../components/Form/Field'

import useLoadCatalogueProducts from '../../../../ReusableFormFields/ProductsAndPagesFields/hooks/useLoadCatalogueProducts'
import MultipleDv360MediaUpload from './MultipleDv360MediaUpload'
import { transformValuesToBE } from './formatters'

import {
  catalogueProductsSelector,
  catalogueProductsWasLoadedSelector,
  mediaOrderUploadCreativeDataSelector
} from '../../../../../modules/selectors/mediaOrders'
import {
  createAdsBatchErrorSelector,
  createAdsBatchIsLoadingSelector,
  createAdsBatchWasCreatedSelector
} from '../../../../../modules/selectors/ads'
import { clearCreateAdsBatch, createAdsBatch } from '../../../../../modules/actions/ads'
import { selectedDv360AdAccountIdSelector } from '../../../../../modules/selectors/app'

import { useShowProductOrPageSelectionStep } from '../../../../Facebook/AdForms/AdFacebookCreate/AdFacebookCreateForm/hooks/useShowProductOrPageSelectionStep'

import { DV_360_PLATFORM } from '../../../../../constants/selectLists/platformList'
import { CLICK_THROUGH_LINK, getInitialValues, MEDIA_ITEMS } from './fields'
import { dv360AdSizeValidationSchema, getValidationSchema } from './validation'

import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

const AdForm = ({ onSuccessSubmit }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const drawerFormClasses = useDrawerFormStyles()

  const { loadInitialProductsHandler } = useLoadCatalogueProducts(1000)

  const dv360AdAccountId = useSelector(selectedDv360AdAccountIdSelector)

  const catalogueProducts = useSelector(catalogueProductsSelector)
  const catalogueProductsWasLoaded = useSelector(catalogueProductsWasLoadedSelector)
  const createAdsBatchWasCreated = useSelector(createAdsBatchWasCreatedSelector)
  const { campaign, lineItem } = useSelector(mediaOrderUploadCreativeDataSelector)
  const showProductSelection = useShowProductOrPageSelectionStep()

  const campaignId = campaign.id
  const lineItemId = lineItem.id

  const formattedPageLinksList = useMemo(
    () =>
      catalogueProducts.map(({ link, name, id }) => ({
        value: id,
        label: name,
        description: link
      })),
    [catalogueProducts]
  )

  const onSubmit = useCallback(
    values => {
      const formattedData = transformValuesToBE({
        values,
        campaignId,
        lineItemId,
        account: dv360AdAccountId,
        productsList: catalogueProducts,
        showProductSelection
      })

      dispatch(
        createAdsBatch(
          {
            account: dv360AdAccountId,
            ...formattedData
          },
          DV_360_PLATFORM
        )
      )
    },
    [dispatch, campaignId, lineItemId, dv360AdAccountId, catalogueProducts, showProductSelection]
  )

  const initialValues = useMemo(() => getInitialValues({ showProductSelection }), [showProductSelection])
  const validationSchema = useMemo(() => getValidationSchema({ showProductSelection }), [showProductSelection])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const clearHandler = useCallback(() => {
    dispatch(clearCreateAdsBatch())
  }, [dispatch])

  useEffect(() => {
    if (!catalogueProductsWasLoaded && loadInitialProductsHandler) {
      loadInitialProductsHandler()
    }
  }, [catalogueProductsWasLoaded, loadInitialProductsHandler])

  return (
    <div className={drawerFormClasses.formContainer}>
      <DrawerHeadline
        activeStepNumber={3}
        customStepsLength={4}
        title="Upload one or more display creatives"
        description="Images should be transparent PNG files that will be displayed on a light grey background"
      />
      <Form
        formName="adFormContent"
        formik={formik}
        submitText="Save"
        successSubmit={createAdsBatchWasCreated}
        onSuccessSubmit={onSuccessSubmit}
        errorSelector={createAdsBatchErrorSelector}
        isLoadingSelector={createAdsBatchIsLoadingSelector}
        clearHandler={clearHandler}
      >
        <FieldsSection title="Creative assets">
          <FieldRow
            title="Image file"
            description={
              <>
                <p>
                  <b>{t('File Type')}: </b> .jpg, .jpeg, .gif, .png
                </p>
                <p>
                  <b>{t('Square and rectangle')}: </b> 250×250, 300×250, 320×320, 336×280
                </p>
                <p>
                  <b>{t('Skyscraper')}:</b> 120×600, 160×600, 300×600, 300×1050{' '}
                </p>
                <p>
                  <b>{t('Leaderboard')}: </b>468×60, 728×90, 800×250, 970×90, 970×250{' '}
                </p>
                <p>
                  <b>{t('Mobile')}: </b>300×50, 300×100, 320×50, 320×100, 320×480, 360×592, 360×640, 375×667
                </p>
              </>
            }
          >
            <MultipleDv360MediaUpload
              formik={formik}
              FileItemPath={MEDIA_ITEMS}
              fileValidationSchema={dv360AdSizeValidationSchema}
            />
          </FieldRow>
          <FieldRow title="Page link" description="Which category or product page the ad will click through to.">
            <Field
              formik={formik}
              placeholder="Page link"
              name={CLICK_THROUGH_LINK}
              // if custom_click_though_link field on selfAccount is true - we don't show dropdown, but just input
              options={showProductSelection ? formattedPageLinksList : null}
            />
          </FieldRow>
        </FieldsSection>
      </Form>
    </div>
  )
}

export default AdForm
