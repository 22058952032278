import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'formik'
import PropTypes from 'prop-types'

import FieldsSection from '../../../../../../../../../../features/components/Form/FieldsSection'
import VariableOptionsSelector from '../VariableOptionsSelector'

import { selectedControllerIdSelector } from '../../../../../../../../../../modules/selectors/app'
import {
  clearGetMediaProductVariables,
  clearGetMediaProductVariablesBulkList,
  getMediaProductVariables,
  getMediaProductVariablesBulkList
} from '../../../../../../../../../../modules/actions/mediaOrdersProductVariables'

import { VARIABLES } from '../../../../../../../../MediaProductForms/fields'
import { mediaProductVariablesBulkListWasLoadedSelector } from '../../../../../../../../../../modules/selectors/mediaOrdersProductVariables'

const ProductVariables = ({ formik, productItemPath, stepErrors, showError, variablesIds }) => {
  const dispatch = useDispatch()

  const { values } = formik

  const controllerId = useSelector(selectedControllerIdSelector)
  const mediaProductVariablesBulkListWasLoaded = useSelector(mediaProductVariablesBulkListWasLoadedSelector)

  const productVariables = getIn(values, `${productItemPath}.${VARIABLES}`)

  const currentProduct = getIn(values, productItemPath)

  useEffect(() => {
    dispatch(getMediaProductVariables({ controller: controllerId }))

    return () => {
      dispatch(clearGetMediaProductVariables())
    }
  }, [dispatch, controllerId])

  useEffect(() => {
    if (!mediaProductVariablesBulkListWasLoaded && variablesIds?.length) {
      dispatch(getMediaProductVariablesBulkList({ media_product_variable_ids: variablesIds.join(',') }))
    }
  }, [dispatch, variablesIds, mediaProductVariablesBulkListWasLoaded])

  useEffect(() => {
    return () => {
      if (mediaProductVariablesBulkListWasLoaded) {
        dispatch(clearGetMediaProductVariablesBulkList())
      }
    }
  }, [dispatch, mediaProductVariablesBulkListWasLoaded])

  return (
    <FieldsSection title="Product variables">
      {productVariables.map((variable, index) => {
        const selectedVariableId = getIn(currentProduct, `${VARIABLES}[${index}.id`)

        return (
          <VariableOptionsSelector
            key={selectedVariableId}
            formik={formik}
            stepErrors={stepErrors}
            productItemPath={productItemPath}
            index={index}
            selectedVariableId={selectedVariableId}
            showError={showError}
          />
        )
      })}
    </FieldsSection>
  )
}

ProductVariables.propTypes = {
  formik: PropTypes.object.isRequired,
  variablesIds: PropTypes.array.isRequired,
  productItemPath: PropTypes.string.isRequired,
  stepErrors: PropTypes.object.isRequired,
  showError: PropTypes.bool.isRequired
}

export default ProductVariables
