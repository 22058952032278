import { createStyles } from '../../../../../styles/helpers'

import { textEllipsis } from '../../../../../styles/mixins/text'
import { darkGreen } from '../../../../../styles/const/colors'

const useStyles = createStyles({
  suggestedChangesTable: {
    marginBottom: 40
  },
  campaignName: {
    ...textEllipsis()
  },
  budgetHeader: {
    textAlign: 'right'
  },
  newBudget: {
    color: darkGreen
  },
  applyChangesBtn: {
    width: 'unset',
    marginLeft: 'auto'
  }
})

export default useStyles
