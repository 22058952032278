import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import BrandPageEditForm from './BrandPageEditForm'

import useManageFormsDrawer from '../../../../../hooks/formHooks/useManageFormsDrawer'

import { categoryPagesIsLoadingSelector, categoryPagesSelector } from '../../../../../modules/selectors/mediaOrders'

import { CATEGORY_PAGE_EDIT } from '../../../../../constants/forms'

const BrandPageEdit = () => {
  const { t } = useTranslation()

  const categoryPages = useSelector(categoryPagesSelector)
  const categoryPagesIsLoading = useSelector(categoryPagesIsLoadingSelector)

  const { selectedEditItemId } = useManageFormsDrawer({
    formName: CATEGORY_PAGE_EDIT
  })

  const brandPage = categoryPages?.find(item => String(item.id) === String(selectedEditItemId))

  return (
    <FormDrawerWrapper
      isFormLoading={categoryPagesIsLoading}
      formName={CATEGORY_PAGE_EDIT}
      title={t('Edit Brand Page')}
      showOpenButton={false}
    >
      {<BrandPageEditForm brandPage={brandPage} />}
    </FormDrawerWrapper>
  )
}

export default BrandPageEdit
