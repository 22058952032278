export const getIsPartiallyCheckedState = (option, optionsState) => {
  const { options } = option

  let hasCheckedChildren = false

  for (let i = 0; i < options.length; i++) {
    const currentOptionData = options[i]
    const currentOptionState = optionsState[currentOptionData.id]

    if (currentOptionState?.isChecked) {
      hasCheckedChildren = true

      break
    }

    if (currentOptionData.options) {
      const hasCheckedSubOption = getIsPartiallyCheckedState(currentOptionData, optionsState)

      if (hasCheckedSubOption) {
        hasCheckedChildren = true

        break
      }
    }
  }

  return hasCheckedChildren
}
