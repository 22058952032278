import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import useComponentWillUnmount from '../../../../../../../hooks/useComponentWillUnmount'

import { setFormMinimize } from '../../../../../../../modules/actions/forms'
import { removeSelectedMediaPackage, setMediaOrderData } from '../../../../../../../modules/actions/mediaOrders'

import {
  ALLOWED_CATEGORY_PRODUCTS_SELECTION,
  ALLOWED_PRODUCT_SELECTION,
  DIGITAL_PRODUCTS,
  EDM_PRODUCTS,
  IN_STORE_PRODUCTS,
  MAGAZINE_PRODUCTS,
  OOH_PRODUCTS,
  PHYSICAL_SPACES_PRODUCTS,
  SELECTED_PACKAGES
} from '../fields'
import { DIGITAL, EDM, IN_STORE, OOH, PHYSICAL_SPACES, PRINT } from '../../../../../../../constants/mediaOrders'

export function useHandleCreateContractClose(values, successSubmit) {
  const dispatch = useDispatch()

  const [successContractSubmit, setSuccessContractSubmit] = useState(false)
  const [submittedValues, setSubmittedValues] = useState([])

  useEffect(() => {
    if (successSubmit) {
      setSuccessContractSubmit(true)
      setSubmittedValues(values)
    }
  }, [successSubmit, values])

  useComponentWillUnmount(() => {
    dispatch(setFormMinimize(false))

    // don't remove selected packages if form is closed or minimized
    // additionally save formik values to store for later use when form is opened again
    // form could be closed or minimized in 2 cases:
    // 1. when user closes the form by clicking on the close button
    // 2. when user creates the contract and the form get to be unmounted

    // use submitted values if contract was successfully submitted and regular values if just form closed
    const saveValues = successContractSubmit ? submittedValues : values

    const valuesWithoutNotSetupProducts = saveValues[SELECTED_PACKAGES].map(mediaPackage => {
      // reset all selected products which haven't been set up to the end
      const resetNotSetupSelectedProducts = selectedProducts => {
        // skip products with not finalized setup(isSetupAllowed = true)
        return selectedProducts.filter(product => !product.isSetupAllowed)
      }

      const magazineAddedToCartProducts = resetNotSetupSelectedProducts(mediaPackage[MAGAZINE_PRODUCTS])
      const edmAddedToCartProducts = resetNotSetupSelectedProducts(mediaPackage[EDM_PRODUCTS])
      const oohAddedToCartProducts = resetNotSetupSelectedProducts(mediaPackage[OOH_PRODUCTS])
      const inStoreAddedToCartProducts = resetNotSetupSelectedProducts(mediaPackage[IN_STORE_PRODUCTS])
      const physicalSpacesAddedToCartProducts = resetNotSetupSelectedProducts(mediaPackage[PHYSICAL_SPACES_PRODUCTS])
      const digitalAddedToCartProducts = resetNotSetupSelectedProducts(mediaPackage[DIGITAL_PRODUCTS])

      // check if there is at least one product selected
      const hasSelectedProducts =
        !!magazineAddedToCartProducts.length ||
        !!edmAddedToCartProducts.length ||
        !!oohAddedToCartProducts.length ||
        !!inStoreAddedToCartProducts.length ||
        !!physicalSpacesAddedToCartProducts.length ||
        !!digitalAddedToCartProducts.length

      if (hasSelectedProducts) {
        return {
          ...mediaPackage,
          [MAGAZINE_PRODUCTS]: magazineAddedToCartProducts,
          [EDM_PRODUCTS]: edmAddedToCartProducts,
          [OOH_PRODUCTS]: oohAddedToCartProducts,
          [IN_STORE_PRODUCTS]: inStoreAddedToCartProducts,
          [PHYSICAL_SPACES_PRODUCTS]: physicalSpacesAddedToCartProducts,
          [DIGITAL_PRODUCTS]: digitalAddedToCartProducts,
          [ALLOWED_CATEGORY_PRODUCTS_SELECTION]: {
            [PRINT]: false,
            [DIGITAL]: false,
            [EDM]: false,
            [OOH]: false,
            [IN_STORE]: false,
            [PHYSICAL_SPACES]: false
          },
          [ALLOWED_PRODUCT_SELECTION]: false
        }
      } else {
        // package should be removed if there was no product selected
        dispatch(removeSelectedMediaPackage(mediaPackage.id))
        return null
      }
    })

    // remove package if there was no product selected
    const selectedProductsValues = valuesWithoutNotSetupProducts.filter(packageData => {
      return !!packageData
    })
    dispatch(
      setMediaOrderData({
        ...saveValues,
        [SELECTED_PACKAGES]: selectedProductsValues
      })
    )
  })
}
