import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import AdsWrapper from '../AdsWrapper'
import GoogleAdCard from './GoogleAdCard'
import GoogleAdCardSkeleton from './GoogleAdCard/Skeleton'
import ItemsLoading from '../../../../../../components/Loaders/ItemsLoading'

import { GOOGLE_PROVIDER_PLATFORM } from '../../../../../../constants/selectLists/platformList'

import useStyles from './styles'

const GooglePerformingAds = ({
  dataSelector,
  loadingSelector,
  errorSelector,
  wasLoadedSelector,
  showOnlyHighestPerforming = true,
  initialLoadingSkeletonsNumber = 5,
  loadMoreHandler,
  getAdActionsDropdownOptions,
  updateAdStatusHandler,
  itemUpdatingId
}) => {
  const classes = useStyles(260)

  const { t } = useTranslation()

  const combinedAds = useSelector(dataSelector)

  const googleAds = useMemo(() => {
    return combinedAds.filter(ad => ad.provider === GOOGLE_PROVIDER_PLATFORM)
  }, [combinedAds])

  const sortedGoogleAds = useMemo(
    // We take the number of ads that fit in one line
    () => {
      return showOnlyHighestPerforming
        ? googleAds.sort((a, b) => b.ctr - a.ctr).slice(0, 5)
        : googleAds.sort((a, b) => b.ctr - a.ctr)
    },
    [googleAds, showOnlyHighestPerforming]
  )

  return (
    <section id="google-ad-cards-list">
      <ItemsLoading
        noDataWithoutIconAndDescription
        itemsLength={sortedGoogleAds.length}
        className={classes.cardsList}
        // noDataText needs to be empty string for not using default noDataText
        noDataText={''}
        contentWrapperText={t(showOnlyHighestPerforming ? 'Highest performing ads' : 'Ads')}
        isLoadingSelector={loadingSelector}
        wasLoadedSelector={wasLoadedSelector}
        errorSelector={errorSelector}
        initialLoadingSkeletonsNumber={initialLoadingSkeletonsNumber}
        SkeletonComponent={GoogleAdCardSkeleton}
        ContentWrapper={AdsWrapper}
        loadMore={loadMoreHandler}
      >
        {sortedGoogleAds.map(ad => {
          return ad.id === itemUpdatingId ? (
            <GoogleAdCardSkeleton key={ad.id} />
          ) : (
            <GoogleAdCard
              ad={ad}
              key={ad.id}
              updateAdStatusHandler={updateAdStatusHandler}
              getAdActionsDropdownOptions={getAdActionsDropdownOptions}
            />
          )
        })}
      </ItemsLoading>
    </section>
  )
}

export default GooglePerformingAds
