import { getFormData, madeRequest } from '../../helpers/api'

import { ENDPOINTS } from '../../constants/api'
import { replaceRouteParams } from '../../helpers/url'

// PLOP_APPEND_PATTERN_ANCHOR

export function getAmendmentService(id, params) {
  return madeRequest('GET', {
    params,
    url: `${ENDPOINTS.amendments}${id}/`
  })
}

export function getAmendmentsListService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.amendments
  })
}

export function createAmendmentService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.amendments
  })
}

export function signAmendmentContractService({ id, data, params, signType }) {
  return madeRequest('POST', {
    params,
    data: getFormData(data), // send signature File as formData
    url:
      signType === 'controller'
        ? replaceRouteParams(ENDPOINTS.signAmendmentController, { id })
        : replaceRouteParams(ENDPOINTS.signAmendmentUser, { id })
  })
}

export function deleteAmendmentContractSignatureService({ id, params, signType }) {
  return madeRequest('POST', {
    params,
    url:
      signType === 'controller'
        ? replaceRouteParams(ENDPOINTS.clearSignAmendmentController, { id })
        : replaceRouteParams(ENDPOINTS.clearSignAmendmentUser, { id })
  })
}

export function sendAmendmentContractService(id, data) {
  return madeRequest('POST', {
    data,
    url: replaceRouteParams(ENDPOINTS.amendmentSend, { id })
  })
}

export function submitAmendmentService(id, params) {
  return madeRequest('POST', {
    params,
    url: replaceRouteParams(ENDPOINTS.amendmentSubmit, { id })
  })
}
