import React from 'react'
import PropTypes from 'prop-types'

import FeesAndTotals from '../../../FeesAndTotals'
import SelectedPeriodsList from './SelectedPeriodsList'
import ProductVariablesList from '../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/OrderCartStep/PackageBreakdown/ProductBreakdown/ProductVariablesList'

import { VARIABLES } from '../../../../../../MediaProductForms/fields'
import { PRODUCT_PERIODS_DATES, QUANTITY } from '../../../../../fields'
import { CUSTOM_DISCOUNT, MODIFIED_PRODUCTS } from '../../../fields'

import { getModifiedProductsDiscount } from '../../../formatters'
import {
  calculateTotalOrderPrice,
  getDecimalDiscount
} from '../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/price'

import useStyles from './styles'

function ModifyProductsList({ formik, currency }) {
  const classes = useStyles()

  const modifiedProducts = formik.values[MODIFIED_PRODUCTS]
  const customDiscount = getDecimalDiscount(formik.values[CUSTOM_DISCOUNT])
  const productsDiscount = getModifiedProductsDiscount(modifiedProducts, customDiscount)
  const totalOrderPrice = calculateTotalOrderPrice(modifiedProducts, productsDiscount)

  return (
    <>
      {modifiedProducts.map((productValues, index) => {
        const productData = productValues.data
        const isProductGroup = productData?.group
        const productsQuantity = productValues[QUANTITY]

        return (
          <div className={classes.product} key={productData.id}>
            <div className={classes.productDetails}>
              <h4 className={classes.name}>{productData.name}</h4>
            </div>
            {isProductGroup && (
              <div className={classes.subProductsList}>
                {productData?.selectedSubProducts?.map(subProduct => subProduct.name).join(' | ')}
              </div>
            )}
            <ProductVariablesList variables={productValues[VARIABLES]} />
            <SelectedPeriodsList
              formik={formik}
              currencySymbol={currency.symbol}
              productPeriods={productValues[PRODUCT_PERIODS_DATES]}
              productsQuantity={productsQuantity}
              itemPath={`${MODIFIED_PRODUCTS}[${index}]`}
              showPeriodPrice
            />
          </div>
        )
      })}
      <FeesAndTotals
        totalOrderPrice={totalOrderPrice}
        selectedCurrency={currency}
        showFees={true}
        showPreSubTotalFees={true}
        showTotal={true}
      />
    </>
  )
}

ModifyProductsList.propTypes = {
  formik: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired
}

export default ModifyProductsList
