import { createStyles } from '../../styles/helpers'

import { transitionShort } from '../../styles/const/common'

const useStyles = createStyles(theme => ({
  section: {
    padding: '16px !important'
  },
  header: {
    position: 'relative',
    cursor: 'pointer'
  },
  toggleButtonWrapper: {
    display: 'flex',
    right: -16,
    top: -8,
    padding: 13,
    position: 'absolute',
    cursor: 'pointer'
  },
  toggleButton: {
    transform: ({ isOpened }) => (isOpened ? `rotate(0deg)` : `rotate(-90deg)`),
    border: 0,
    fontSize: 0,
    padding: 0,
    outline: 'none',
    backgroundColor: 'transparent',
    transition: transitionShort,
    '& svg': {
      cursor: 'pointer',
      color: theme.brandPrimary,
      width: 16,
      height: 'auto'
    }
  }
}))

export default useStyles
