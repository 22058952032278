import * as Yup from 'yup'

import { getBusinessNameData, getCTAData } from '../HeadlinesSection/helpers'
import { getKeyByValue } from '../../../../../../../helpers/common'

import { googleCallToActionEnums } from '../../../../../../../constants/ads'
import { BUSINESS_NAME } from '../../../../../../ReusableFormFields/LineItemForms/fields'
import { CALL_TO_ACTION } from '../../../../../../ReusableFormFields/CallToActionFields/fields'

export const NAME = 'name'

export const getInitialValues = assetGroup => {
  const businessNameData = getBusinessNameData(assetGroup.assets)

  const businessName = businessNameData?.asset_data.text_asset.text

  const ctaData = getCTAData(assetGroup.assets)

  const ctaEnumValue = ctaData?.asset_data?.call_to_action_asset?.call_to_action

  const cta = getKeyByValue(googleCallToActionEnums, ctaEnumValue)

  return {
    [NAME]: assetGroup[NAME] || '',
    [BUSINESS_NAME]: businessName || '',
    [CALL_TO_ACTION]: cta || ''
  }
}

export const validationSchema = Yup.object({
  [NAME]: Yup.string().max(400, 'Name should have maximum 400 characters').required('Name Required'),
  [BUSINESS_NAME]: Yup.string()
    .max(25, 'Business name should have maximum 25 characters')
    .required('Business Name Required')
})
