import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useLocation } from 'react-router'
import classnames from 'classnames'

import LoginMFAContent from './LoginMFAContent'
import Form from '../../../components/Form'
import Field from '../../../components/Form/Field'
import UnauthorizedPage from '../UnauthorizedPage'
import InternalLink from '../../../components/InternalLink'
import FieldLabelWrapper from '../../../features/components/Form/FieldLabelWrapper'

import { clearSubmitLogin, clearSubmitMFACode, submitLogin } from '../../../modules/actions/cognito'
import {
  cognitoLoadingSelector,
  isAuthorizedSelector,
  loginErrorSelector,
  loginMFAWasRequestedSelector
} from '../../../modules/selectors/cognito'

import { createJsonFromQueryString } from '../../../helpers/url'
import { showToasts } from '../../../helpers/toasts'
import { useAuthButtonProps } from '../useAuthButtonProps'
import { getAppDomainName } from '../../../features/helpers/getAppDomainName'

import { TOAST_TYPE } from '../../../constants/other'
import { ROUTES } from '../../../constants/routes'
import { EMAIL, PASSWORD, initialValues, validationSchema } from './fields'

import useAuthStyles from '../../../styles/common/authPages'
import useDrawerFormsStyles from '../../../styles/common/drawerForms'

function Login() {
  const authClasses = useAuthStyles()
  const drawerFormClasses = useDrawerFormsStyles()

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { search } = useLocation()

  const [showMFAForm, setShowMFAForm] = useState(false)

  const isAuthorized = useSelector(isAuthorizedSelector)
  const mfaWasRequested = useSelector(loginMFAWasRequestedSelector)

  const successSubmit = !!isAuthorized || mfaWasRequested

  const { error_code: errorCode } = createJsonFromQueryString(search)

  const buttonProps = useAuthButtonProps()
  const appName = getAppDomainName()

  const onSubmitLogin = useCallback(
    values => {
      dispatch(submitLogin(values))
    },
    [dispatch]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmitLogin
  })

  const onSuccessSubmit = useCallback(() => {
    if (mfaWasRequested) {
      setShowMFAForm(true)
    }
  }, [mfaWasRequested])

  const onRestartLogin = useCallback(() => {
    setShowMFAForm(false)
    dispatch(clearSubmitLogin())
    dispatch(clearSubmitMFACode())
  }, [dispatch])

  useEffect(() => {
    if (errorCode === 'invalid_token') {
      showToasts({
        type: TOAST_TYPE.info,
        message: t('You have already created a password, please try and log in.')
      })
    }
  }, [errorCode, t])

  return (
    <UnauthorizedPage helmetTitle="helmetTitle.LoginPage" className={authClasses.authPage}>
      <div className={authClasses.formContainer}>
        {showMFAForm ? (
          <LoginMFAContent onRestartLogin={onRestartLogin} />
        ) : (
          <Form
            formName="login"
            formik={formik}
            method="post"
            successSubmit={successSubmit}
            onSuccessSubmit={onSuccessSubmit}
            errorSelector={loginErrorSelector}
            isLoadingSelector={cognitoLoadingSelector}
            submitText={t('Log In')}
            buttonProps={buttonProps}
          >
            <div className={drawerFormClasses.section}>
              <h3 className={drawerFormClasses.sectionTitle}>👋 {t('Welcome back, let’s get you logged in.')}</h3>
              <p>{t('loginPage.loginDescription', { appName })}</p>
              <div className={authClasses.fieldsContainer}>
                <FieldLabelWrapper label={'Your work email'} labelFor={EMAIL}>
                  <Field formik={formik} id={EMAIL} name={EMAIL} placeholder={t('Your work email')} />
                </FieldLabelWrapper>
                <FieldLabelWrapper label={'Password'} labelFor={PASSWORD}>
                  <Field formik={formik} type="password" id={PASSWORD} name={PASSWORD} placeholder={t('Password')} />
                </FieldLabelWrapper>
              </div>
              <p className={authClasses.description}>
                {t(
                  'Passwords contain at least 8 characters, one uppercase, one lowercase, one number and one special character'
                )}
              </p>
            </div>
          </Form>
        )}
      </div>
      {!showMFAForm && (
        <h3 className={classnames(authClasses.authPageSubtitle, authClasses.authPageFooter)}>
          {t('Forgot your password?')} <InternalLink to={ROUTES.resetPassword}>{t('Reset your password')}</InternalLink>
        </h3>
      )}
    </UnauthorizedPage>
  )
}

export default Login
