import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SkeletonForm from '../../../components/Form/Skeleton'
import UploadAdCreativeSuccessModal from './UploadAdCreativeSuccessModal'
import UploadFacebookAdCreativeContent from './UploadFacebookAdCreativeContent'
import SocialAuthChecker from '../../../features/components/SocialAuthChecker'
import FormDrawerWrapper from '../../../features/components/FormDrawerWrapper'
import FacebookPagesMissingWarning from '../AdForms/AdFacebookCreate/FacebookPagesMissingWarning'

import useStepFormRoutes from '../../../hooks/formHooks/useStepFormRoutes'
import useManageFormsDrawer from '../../../hooks/formHooks/useManageFormsDrawer'
import { useGetAuthFailureText } from '../../Multiplatform/UploadAdCreative/hooks'
import useUploadAdCreativeConstants from '../../Multiplatform/MediaOrderForms/UploadAdCreative/useUploadAdCreativeConstants'
import useFormattedFacebookPages from '../AdForms/AdFacebookCreate/AdFacebookCreateForm/hooks/useFormattedFacebookPages'

import { clearMediaOrderUploadCreative } from '../../../modules/actions/mediaOrders'
import { clearAccessibleFacebookPages, getAccessibleFacebookPages } from '../../../modules/actions/socialAccounts'

import {
  accessibleFacebookPagesSelector,
  accessibleFacebookPagesWasLoadedSelector
} from '../../../modules/selectors/socialAccounts'

import { FACEBOOK_PLATFORM } from '../../../constants/selectLists/platformList'
import { PRODUCT_RETARGETING_ROUTE } from './fields'

import { UPLOAD_FACEBOOK_CREATIVE } from '../../../constants/forms'

export function UploadFacebookAdCreative() {
  const dispatch = useDispatch()
  const [createdCreativeData, setCreatedCreativeData] = useState({})

  const { isFormLoading, isInternalAdCreation } = useUploadAdCreativeConstants()

  const manageStepFormRoutes = useStepFormRoutes({ initialRoute: PRODUCT_RETARGETING_ROUTE })

  const facebookPages = useSelector(accessibleFacebookPagesSelector)
  const facebookPagesWasLoaded = useSelector(accessibleFacebookPagesWasLoadedSelector)

  const formattedFacebookPages = useFormattedFacebookPages(facebookPages)
  const hasFacebookPages = facebookPagesWasLoaded && !!formattedFacebookPages.length

  const { selectedRoute, handleFormClose, handleSelectRoute } = manageStepFormRoutes
  const { isFormOpen } = useManageFormsDrawer({ formName: UPLOAD_FACEBOOK_CREATIVE })

  const onSuccessAdFormSubmit = useCallback(
    creativeData => {
      setCreatedCreativeData({
        selectedRoute: selectedRoute,
        ...creativeData
      })
    },
    [selectedRoute, setCreatedCreativeData]
  )

  const onAfterFormCloseHandler = useCallback(() => {
    // reset routes state
    handleFormClose()

    dispatch(clearMediaOrderUploadCreative())
  }, [dispatch, handleFormClose])

  useEffect(() => {
    if (isFormOpen) {
      dispatch(getAccessibleFacebookPages())
    }
  }, [dispatch, isFormOpen])

  useEffect(() => {
    return () => {
      dispatch(clearAccessibleFacebookPages())
    }
  }, [dispatch])

  return (
    <>
      <FormDrawerWrapper
        formName={UPLOAD_FACEBOOK_CREATIVE}
        closeOnSubmit={false}
        showOpenButton={false}
        isFormLoading={isFormLoading || !facebookPagesWasLoaded}
        SkeletonFormComponent={<SkeletonForm stepsLength={3} />}
        onAfterFormClose={onAfterFormCloseHandler}
        hasDefaultDrawerContentSpacing={false}
        isWideDrawer
      >
        <SocialAuthChecker platform={FACEBOOK_PLATFORM} authFailureText={useGetAuthFailureText(FACEBOOK_PLATFORM)}>
          {hasFacebookPages ? (
            <UploadFacebookAdCreativeContent
              onSuccessSubmit={onSuccessAdFormSubmit}
              manageStepFormRoutes={manageStepFormRoutes}
            />
          ) : (
            <FacebookPagesMissingWarning hasDrawerPadding />
          )}
        </SocialAuthChecker>
      </FormDrawerWrapper>
      <UploadAdCreativeSuccessModal
        isInternalAdCreation={isInternalAdCreation}
        setCreatedCreativeData={setCreatedCreativeData}
        createdCreativeData={createdCreativeData}
        handleSelectRoute={handleSelectRoute}
      />
    </>
  )
}
