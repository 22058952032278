import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Button from '../../../../components/Button'
import Icon from '../../../../components/Icon'
import Loader from '../../../../components/Loaders/Loader'
import { ReactComponent as PdfFileIcon } from '../../../../assets/icons/pdf-file.svg'

import useStyles from './styles'

const DownloadPdfButton = ({ isLoading = false, isDisabled, onClick, className, text = 'Download PDF' }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Button
      iconHoverColor="white"
      className={classnames(classes.pdfGenerateBtn, className)}
      disabled={isDisabled}
      onClick={onClick}
    >
      {isLoading ? (
        <div className={classes.loadingButtonContent}>
          <Icon className={classes.icon}>
            <Loader />
          </Icon>
        </div>
      ) : (
        <div className={classes.buttonContent}>
          <Icon
            className={classnames(classes.icon, {
              [classes.iconDisabled]: isDisabled
            })}
          >
            <PdfFileIcon />
          </Icon>
          <span> {t(text)}</span>
        </div>
      )}
    </Button>
  )
}

DownloadPdfButton.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  text: PropTypes.string
}

export default DownloadPdfButton
