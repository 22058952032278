import React from 'react'
import classnames from 'classnames'

import Icon from '../../Icon'
import ProgressBar from '../../ProgressBar'
import { ReactComponent as ReportIcon } from '../../../assets/icons/upload-file-icon.svg'

import useStyles from './styles'

const DropFileUploaderProgress = ({ containerClassName, fileUploadProgress }) => {
  const classes = useStyles()

  return (
    <div className={classnames(classes.container, containerClassName)}>
      <div className={classnames(classes.baseStyle, classes.activeStyle)}>
        <div className={classes.dragInfo}>
          <Icon className={classes.icon}>
            <ReportIcon />
          </Icon>
          <ProgressBar className={classes.progressBar} value={fileUploadProgress || 0} />
        </div>
      </div>
    </div>
  )
}

export default DropFileUploaderProgress
