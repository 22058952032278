import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Chip from '../../../../../../../components/Chip'
import Tooltip from '../../../../../../../components/Tooltip'

import {
  BUYER_DISAPPROVED,
  DISAPPROVED,
  getApprovalFileStatus
} from '../../../../../../../features/components/UploadedFilesField/helpers'

import { CHIP_COLORS } from '../../../../../../../constants/other'

import useStyles from './styles'

const FileStatusCell = ({ uploadedFile }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const approvalStatus = uploadedFile?.approval_status
  const disapprovedReason = uploadedFile?.disapproved_reason
  const buyerDisapprovedReason = uploadedFile?.buyer_disapproved_reason

  if (!approvalStatus) {
    return <Chip text={t('Required')} color={CHIP_COLORS.grey} />
  }

  const { color, text } = getApprovalFileStatus(approvalStatus, CHIP_COLORS.yellow)

  if (approvalStatus === DISAPPROVED || approvalStatus === BUYER_DISAPPROVED) {
    // if status is disapproved or buyer disapproved, show tooltip with disapproval reason
    return (
      <Tooltip placement="top-start" triggerElement={<Chip text={text} color={color} />}>
        <div className={classes.disapprovalReasonTooltipContainer}>
          {approvalStatus === DISAPPROVED ? disapprovedReason : buyerDisapprovedReason}
        </div>
      </Tooltip>
    )
  } else {
    return <Chip text={text} color={color} />
  }
}

FileStatusCell.propTypes = {
  uploadedFile: PropTypes.shape({
    approval_status: PropTypes.string,
    disapproved_reason: PropTypes.string,
    buyer_disapproved_reason: PropTypes.string
  })
}

export default FileStatusCell
