import React from 'react'
import PropTypes from 'prop-types'

import { usePriceChangeActions } from './hooks'

import AdaptiveActions from '../../../../../../../features/components/AdaptiveActions'

const PriceChangePeriodsActions = ({ itemData }) => {
  const { dropdownOptions } = usePriceChangeActions(itemData)

  return <AdaptiveActions options={dropdownOptions} />
}

PriceChangePeriodsActions.propTypes = {
  itemData: PropTypes.object.isRequired
}

export default PriceChangePeriodsActions
