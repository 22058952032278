import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'formik'
import { useTranslation } from 'react-i18next'

import CarouselItemForm from './CarouselItemForm'
import ItemMediaFields from '../ItemMediaFields'
import ActionText from '../../../../components/ActionText'

import { setAdCarouselSelectedIndex } from '../../../../modules/actions/ads'
import { adCarouselSelectedIndexesSelector } from '../../../../modules/selectors/ads'

import {
  initialCarouselItem,
  IS_ITEM_FORM_OPENED,
  AD_LINK,
  AD_PREVIEW_CAROUSEL_INDEX_KEY
} from '../../../Facebook/AdForms/fields'
import { CALL_TO_ACTION } from '../../CallToActionFields/fields'
import { carouselMediaValidation } from '../../../Facebook/AdForms/AdFacebookCreate/AdFacebookCreateForm/validation/mediaValidation'

// To make it possible for component to work with nested objects
// in formik values, use the built-in formik function getIn
const CarouselMediaFields = ({
  formik,
  listItemsPath,
  onCarouselItemDelete,
  adAccountId,
  suggestions,
  campaignObjective
}) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { values, setFieldValue, setValues } = formik

  const carouselItems = getIn(values, listItemsPath)
  const selectedCarouselIndexes = useSelector(adCarouselSelectedIndexesSelector)

  const adPreviewCarouselIndexKey = values[AD_PREVIEW_CAROUSEL_INDEX_KEY]
  const selectedCarouselItemIndex = selectedCarouselIndexes[adPreviewCarouselIndexKey] || 0

  // Constant that represent path ot item in formik values
  const itemPath = `${listItemsPath}[${selectedCarouselItemIndex}]`

  const handleAddAnotherItem = useCallback(() => {
    if (carouselItems.length < 10) {
      // add new initial item to carousel items array
      // AD_LINK, CALL_TO_ACTION from previous item must be added to new item
      const newCarouselItem = {
        ...initialCarouselItem,
        [AD_LINK]: getIn(values, `${itemPath}.${AD_LINK}`),
        [CALL_TO_ACTION]: getIn(values, `${itemPath}.${CALL_TO_ACTION}`)
      }
      const updatedValues = {
        [listItemsPath]: [...carouselItems, newCarouselItem]
      }

      dispatch(setAdCarouselSelectedIndex(adPreviewCarouselIndexKey, carouselItems.length))
      setValues({ ...values, ...updatedValues })
    }
  }, [dispatch, listItemsPath, adPreviewCarouselIndexKey, carouselItems, itemPath, values, setValues])

  // Set selected index to the clicked item index, and focus the item
  const handleItemClick = useCallback(
    ({ index, isFormOpened }) => {
      dispatch(setAdCarouselSelectedIndex(adPreviewCarouselIndexKey, index))
      setFieldValue(IS_ITEM_FORM_OPENED, isFormOpened)
    },
    [dispatch, adPreviewCarouselIndexKey, setFieldValue]
  )

  const handleDeleteItem = useCallback(
    index => {
      // delete item from carousel items array
      const newCarouselItems = [...carouselItems]
      newCarouselItems.splice(index, 1)

      const updatedValues = {
        [listItemsPath]: newCarouselItems
      }

      // select previous item if deleted item wasn't first
      if (index !== 0) {
        dispatch(setAdCarouselSelectedIndex(adPreviewCarouselIndexKey, selectedCarouselItemIndex - 1))
      }
      setValues({ ...values, ...updatedValues })
      onCarouselItemDelete && onCarouselItemDelete(newCarouselItems)
    },
    [
      dispatch,
      listItemsPath,
      carouselItems,
      setValues,
      values,
      adPreviewCarouselIndexKey,
      selectedCarouselItemIndex,
      onCarouselItemDelete
    ]
  )

  return (
    <>
      {carouselItems.map((item, index) => (
        <CarouselItemForm
          key={index}
          formik={formik}
          item={item}
          index={index}
          listPath={listItemsPath}
          deleteItem={() => handleDeleteItem(index)}
          onItemClick={handleItemClick}
        >
          <ItemMediaFields
            formik={formik}
            itemPath={`${listItemsPath}[${index}]`}
            adAccountId={adAccountId}
            mediaValidationSchema={carouselMediaValidation}
            suggestions={suggestions}
            campaignObjective={campaignObjective}
          />
        </CarouselItemForm>
      ))}
      {carouselItems.length < 10 && (
        <ActionText onClick={handleAddAnotherItem} isDark>
          {t('+ Add Another Image or Video')}
        </ActionText>
      )}
    </>
  )
}

CarouselMediaFields.propTypes = {
  formik: PropTypes.object,
  listItemsPath: PropTypes.string.isRequired,
  onCarouselItemDelete: PropTypes.func,
  adAccountId: PropTypes.string,
  suggestions: PropTypes.object,
  campaignObjective: PropTypes.string.isRequired
}

export default CarouselMediaFields
