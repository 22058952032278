import { createStyles } from '../../../styles/helpers'

import { lightGrey2 } from '../../../styles/const/colors'

export default createStyles(theme => ({
  container: {
    marginTop: 16,
    background: 'white',
    border: {
      width: 1,
      style: 'solid',
      color: lightGrey2
    },
    borderRadius: theme.defaultBorderRadius
  }
}))
