import { createStyles } from '../../../../../../../styles/helpers'

const useStyles = createStyles({
  brandSection: {
    width: '100%'
  },
  brandSectionHeader: {
    padding: '8px 16px'
  }
})

export default useStyles
