import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { SHOW_COUNTRIES, SHOW_COUNTRY_CHOICE } from '../../forms/ReusableFormFields/GeolocationsFields/fields'
import { choicesCountriesWasLoadedSelector, currentUserCountrySelector } from '../../modules/selectors/choices'

export default function usePreselectUserCountry({ formik, formattedCountries = [] }) {
  const { values, setFieldValue } = formik

  const selectedCountries = values[SHOW_COUNTRIES]

  const countriesWasLoaded = useSelector(choicesCountriesWasLoadedSelector)
  const currentUserCountry = useSelector(currentUserCountrySelector)

  const setUserCountry = useCallback(() => {
    // when countries are filtered it could be the case when currentUserCountry is not in formattedCountries
    // in this case just do not preselect country
    const isUserCountryInList = formattedCountries.find(country => country.value === currentUserCountry?.id)

    if (isUserCountryInList) {
      setFieldValue(SHOW_COUNTRIES, [
        {
          value: currentUserCountry?.id,
          label: currentUserCountry?.name,
          code: currentUserCountry?.iso2,
          resourceName: currentUserCountry?.resource_name,
          tiktok_id: currentUserCountry?.['tiktok_id']
        }
      ])
      setFieldValue(SHOW_COUNTRY_CHOICE, currentUserCountry?.id)
    }
  }, [formattedCountries, currentUserCountry, setFieldValue])

  const [wasDefaultCountryPreselected, setWasDefaultCountryPreselected] = useState(false)

  const currentUserCountryIsAvailable = useMemo(
    () => formattedCountries.find(item => item.value === currentUserCountry?.id),
    [formattedCountries, currentUserCountry]
  )

  useEffect(() => {
    // Preselect the user's country if no countries were selected previously
    if (
      currentUserCountry &&
      currentUserCountryIsAvailable &&
      selectedCountries &&
      !selectedCountries.length &&
      !wasDefaultCountryPreselected &&
      countriesWasLoaded
    ) {
      setWasDefaultCountryPreselected(true)
      setUserCountry()
    }
  }, [
    setUserCountry,
    currentUserCountry,
    currentUserCountryIsAvailable,
    selectedCountries,
    setFieldValue,
    wasDefaultCountryPreselected,
    countriesWasLoaded
  ])
}
