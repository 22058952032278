import { createStyles } from '../../../../../../../styles/helpers'
import { footerMargin } from '../../../../../../../components/Form/styles'

export default createStyles({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    marginTop: footerMargin
  },
  buttonWrapper: {
    width: 'auto'
  }
})
