import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Field from '../../../../components/Form/Field'
import FieldRow from '../../../../features/components/Form/FieldsSection/FieldRow'

import useLoadInstagramAccounts from '../../../../hooks/instagramAccountsHooks/useLoadInstagramAccounts'
import useGetInstagramAccounts from '../../../../hooks/instagramAccountsHooks/useGetInstagramAccounts'
import { formatOptionsList } from '../../../../features/formatters'

import {
  accessibleFacebookPagesLoadingSelector,
  accessibleFacebookPagesSelector
} from '../../../../modules/selectors/socialAccounts'

import { FB_PAGE_CHOICE, INSTA_ACCOUNT_CHOICE } from '../../AdForms/fields'
import useFormattedFacebookPages from '../../AdForms/AdFacebookCreate/AdFacebookCreateForm/hooks/useFormattedFacebookPages'

const FacebookPageSelectionFields = ({ formik, adAccountId, placementPositions }) => {
  const { values, setFieldValue } = formik

  useLoadInstagramAccounts({
    facebookPageId: values[FB_PAGE_CHOICE],
    shouldLoadPageAccessToken: true,
    shouldLoadInstagramAccounts: true,
    adAccountId
  })
  const { selectedInstagramAccounts } = useGetInstagramAccounts(values[FB_PAGE_CHOICE])

  const {
    isLoading: instagramAccountsIsLoading,
    results: instagramAccounts,
    wasLoaded: instagramAccountsWasLoaded
  } = selectedInstagramAccounts

  const formattedInstagramAccounts = useMemo(() => {
    return formatOptionsList({
      list: instagramAccounts,
      valueName: 'id',
      labelName: 'username'
    })
  }, [instagramAccounts])

  const facebookPages = useSelector(accessibleFacebookPagesSelector)
  const facebookPagesLoading = useSelector(accessibleFacebookPagesLoadingSelector)

  const selectedInstagramAccount = values[INSTA_ACCOUNT_CHOICE]

  const formattedFacebookPages = useFormattedFacebookPages(facebookPages)

  // allow to select the Facebook page only if the are more then 2 pages
  // otherwise, the page should be preselected automatically to 1st
  const showFacebookDropdown = formattedFacebookPages?.length > 1

  // Dropdown with instagram accounts should appear only if:
  // 1) /pages/{id}/instagram_accounts/ results > 1
  // 2) placement_positions includes some item with 'instagram' string
  const showInstagramDropdown =
    instagramAccounts && instagramAccounts.length > 1 && placementPositions.some(item => item.includes('instagram'))

  useEffect(() => {
    const isExistingInstagramAccountValid = formattedInstagramAccounts.find(
      item => item.value === selectedInstagramAccount
    )

    if (formattedInstagramAccounts.length) {
      if (!selectedInstagramAccount || !isExistingInstagramAccountValid) {
        setFieldValue(INSTA_ACCOUNT_CHOICE, formattedInstagramAccounts[0].value)
      }
    } else if (instagramAccountsWasLoaded && selectedInstagramAccount) {
      setFieldValue(INSTA_ACCOUNT_CHOICE, '')
    }
  }, [formattedInstagramAccounts, selectedInstagramAccount, instagramAccountsWasLoaded, setFieldValue])

  return (
    <>
      {showFacebookDropdown && (
        <FieldRow title="Facebook Page">
          <Field
            placeholder="Select Facebook Page"
            formik={formik}
            name={FB_PAGE_CHOICE}
            options={formattedFacebookPages}
            isLoading={facebookPagesLoading}
            isSearchable={false}
          />
        </FieldRow>
      )}
      {showInstagramDropdown && (
        <FieldRow title={'Select Instagram Account'}>
          <Field
            placeholder="Instagram Account"
            formik={formik}
            name={INSTA_ACCOUNT_CHOICE}
            options={formattedInstagramAccounts}
            isLoading={instagramAccountsIsLoading}
            isSearchable={false}
          />
        </FieldRow>
      )}
    </>
  )
}

FacebookPageSelectionFields.propTypes = {
  adAccountId: PropTypes.string.isRequired,
  placementPositions: PropTypes.array.isRequired
}
export default FacebookPageSelectionFields
