import { concat } from '../../helpers/common'

const MODULE_NAME = 'GOOGLE_CONSTANTS/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_GOOGLE_USERS_LIST = concat(MODULE_NAME, 'GET_GOOGLE_USERS_LIST')
export const GET_GOOGLE_USERS_LIST_SUCCESS = concat(MODULE_NAME, 'GET_GOOGLE_USERS_LIST_SUCCESS')
export const GET_GOOGLE_USERS_LIST_FAILURE = concat(MODULE_NAME, 'GET_GOOGLE_USERS_LIST_FAILURE')
export const CLEAR_GET_GOOGLE_USERS_LIST = concat(MODULE_NAME, 'CLEAR_GET_GOOGLE_USERS_LIST')

export const GET_GOOGLE_GEOLOCATIONS = concat(MODULE_NAME, 'GET_GOOGLE_GEOLOCATIONS')
export const GET_GOOGLE_GEOLOCATIONS_SUCCESS = concat(MODULE_NAME, 'GET_GOOGLE_GEOLOCATIONS_SUCCESS')
export const GET_GOOGLE_GEOLOCATIONS_FAILURE = concat(MODULE_NAME, 'GET_GOOGLE_GEOLOCATIONS_FAILURE')
export const CLEAR_GOOGLE_GEOLOCATIONS = concat(MODULE_NAME, 'CLEAR_GOOGLE_GEOLOCATIONS')

export const GET_GOOGLE_LANGUAGES = concat(MODULE_NAME, 'GET_GOOGLE_LANGUAGES')
export const GET_GOOGLE_LANGUAGES_SUCCESS = concat(MODULE_NAME, 'GET_GOOGLE_LANGUAGES_SUCCESS')
export const GET_GOOGLE_LANGUAGES_FAILURE = concat(MODULE_NAME, 'GET_GOOGLE_LANGUAGES_FAILURE')
export const CLEAR_GOOGLE_LANGUAGES = concat(MODULE_NAME, 'CLEAR_GOOGLE_LANGUAGES')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// Get google users list
export function getGoogleUsersList(params) {
  return { type: GET_GOOGLE_USERS_LIST, params }
}

export function getGoogleUsersListSuccess(data) {
  return { type: GET_GOOGLE_USERS_LIST_SUCCESS, data }
}

export function getGoogleUsersListFailure(error) {
  return { type: GET_GOOGLE_USERS_LIST_FAILURE, error }
}

export function clearGetGoogleUsersList() {
  return { type: CLEAR_GET_GOOGLE_USERS_LIST }
}

// Get google geolocations
export function getGoogleGeolocations(params) {
  return { type: GET_GOOGLE_GEOLOCATIONS, params }
}

export function getGoogleGeolocationsSuccess(geolocationsData) {
  return { type: GET_GOOGLE_GEOLOCATIONS_SUCCESS, geolocationsData }
}

export function getGoogleGeolocationsFailure(error) {
  return { type: GET_GOOGLE_GEOLOCATIONS_FAILURE, error }
}

export function clearGoogleGeolocations() {
  return { type: CLEAR_GOOGLE_GEOLOCATIONS }
}

// Get google languages
export function getGoogleLanguages(params) {
  return { type: GET_GOOGLE_LANGUAGES, params }
}

export function getGoogleLanguagesSuccess(languagesData) {
  return { type: GET_GOOGLE_LANGUAGES_SUCCESS, languagesData }
}

export function getGoogleLanguagesFailure(error) {
  return { type: GET_GOOGLE_LANGUAGES_FAILURE, error }
}

export function clearGoogleLanguages() {
  return { type: CLEAR_GOOGLE_LANGUAGES }
}
