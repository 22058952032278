import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  fieldWrapper: {
    marginTop: ({ horizontalView }) => (horizontalView ? 0 : 16),
    width: '100%',
    display: ({ horizontalView }) => (horizontalView ? 'flex' : 'block'),
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  fieldLabel: {
    fontSize: 14,
    fontWeight: 600
  },
  fieldContent: {
    '& > *:first-child': {
      marginTop: 8
    }
  }
})

export default useStyles
