import React, { Suspense, useEffect, useState, lazy } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import calendarPlaceholder from '../../assets/calendarTimeline/plannerPlaceholder.png'
import { ReactComponent as CalendarIcon } from '../../assets/calendarTimeline/icons/calendar-colorful.svg'

import Page from '../index'
import AppLoader from '../../components/Loaders/AppLoader'
import CreateCalendarPlanner from './CreateCalendarPlanner'
import CalendarEditRow from '../../forms/Multiplatform/CalendarPlannerForms/Row/CalendarEditRow'
import CalendarCreateRow from '../../forms/Multiplatform/CalendarPlannerForms/Row/CalendarCreateRow'
import CalendarEditEvent from '../../forms/Multiplatform/CalendarPlannerForms/Event/CalendarEditEvent'
import InfoBlock from '../../features/components/InfoBlock'
import { MobileDown, MobileUp } from '../../components/hoc/ResponsiveRendering'

import { getTransformedData } from './helpers'

import { getCalendar, getCalendars } from '../../modules/actions/calendarPlanner'
import { selectedSelfAccountSelector } from '../../modules/selectors/app'
import {
  calendarDataSelector,
  calendarDataWasLoadedSelector,
  calendarsListErrorSelector,
  calendarsListSelector,
  calendarsListWasLoadedSelector
} from '../../modules/selectors/calendarPlanner'

import useStyles from './styles'

// lazy load Calendar component to reduce the initial bundle file size
const CalendarDesktop = lazy(() => import('./CalendarDesktop'))

function CalendarPlanner() {
  const classes = useStyles()

  const dispatch = useDispatch()
  const { t } = useTranslation()

  // const [calendar, setCalendar] = useState(null)
  const [selectedCalendar, setSelectedCalendar] = useState(undefined)
  const [selectedCalendarData, setSelectedCalendarData] = useState(undefined)

  const calendarData = useSelector(calendarDataSelector)
  const calendarDataWasLoaded = useSelector(calendarDataWasLoadedSelector)
  const calendarsList = useSelector(calendarsListSelector)
  const calendarsListWasLoaded = useSelector(calendarsListWasLoadedSelector)
  const calendarsListError = useSelector(calendarsListErrorSelector)
  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)

  const eventGroups = selectedCalendarData && selectedCalendarData.event_groups

  const hasCalendar = calendarsListWasLoaded && !!calendarsList.length

  const calendarIsLoading = !calendarsListWasLoaded || (hasCalendar && !calendarDataWasLoaded)

  useEffect(() => {
    // initial call - get calendar list
    dispatch(getCalendars({ account: selectedSelfAccount }))
  }, [dispatch, selectedSelfAccount])

  useEffect(() => {
    // after calendar selection make request for calendar data
    if (selectedCalendar) {
      dispatch(getCalendar(selectedCalendar.id))
    }
  }, [dispatch, selectedCalendar])

  useEffect(() => {
    if (hasCalendar) {
      // select first calendar by default
      setSelectedCalendar(calendarsList[0])
    }
  }, [hasCalendar, calendarsList])

  useEffect(() => {
    if (calendarDataWasLoaded && Object.keys(calendarData).length) {
      // transform, order and set calendarData which will be send to Calendar component
      const data = getTransformedData(calendarData)
      setSelectedCalendarData({
        ...data,
        // each event_group has it's order which should reflect the representation order
        // it's also possible to change order with dragging the row
        event_groups: data.event_groups.sort((a, b) => a.order - b.order)
      })
    }
  }, [calendarData, calendarDataWasLoaded])

  return (
    <Page helmetTitle="Calendar Planner">
      {calendarsListError ? (
        <InfoBlock title={t('Sorry, something went wrong')} centered greyDescription>
          <div>{t('Please try again later')}</div>
        </InfoBlock>
      ) : (
        <>
          <MobileUp>
            <Suspense fallback={<AppLoader isFixed />}>
              {(hasCalendar || calendarIsLoading) && (
                <CalendarDesktop calendar={selectedCalendarData} calendarIsLoading={calendarIsLoading} />
              )}
            </Suspense>
            {!hasCalendar && !calendarIsLoading && (
              <>
                <img src={calendarPlaceholder} alt="calendar placeholder" className={classes.placeholder} />
                <CreateCalendarPlanner />
              </>
            )}
            {hasCalendar && !calendarIsLoading && (
              <>
                <CalendarEditEvent eventGroups={eventGroups} />
                <CalendarEditRow eventGroups={eventGroups} />
                <CalendarCreateRow calendarId={selectedCalendar && selectedCalendar.id} eventGroups={eventGroups} />
              </>
            )}
          </MobileUp>
          <MobileDown>
            <InfoBlock Icon={CalendarIcon} title={t('The planner is best viewed on desktop')}>
              {t('Please use the desktop version to create and manage your planner')}
            </InfoBlock>
          </MobileDown>
        </>
      )}
    </Page>
  )
}

export default CalendarPlanner
