import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { activeStepSelector } from '../../../../../modules/selectors/forms'

export const useProgressItems = ({ showConversionGoalStep }) => {
  const activeStep = useSelector(activeStepSelector)

  return useMemo(
    () =>
      [
        {
          label: 'Enter ad set details',
          show: true
        },
        {
          label: 'Select target audience',
          show: showConversionGoalStep
        },
        {
          label: 'Select conversion goal',
          show: true
        },
        {
          label: 'Select Placements',
          show: true
        }
      ]
        .filter(item => item.show)
        .map((item, index) => {
          return {
            ...item,
            isActive: activeStep === index,
            isSuccess: activeStep > index
          }
        }),
    [activeStep, showConversionGoalStep]
  )
}
