import React from 'react'
import PropTypes from 'prop-types'

import { CLICK_THROUGH_LINK } from '../../../../fields'

const LinkSectionPreview = ({ data }) => {
  const link = data[CLICK_THROUGH_LINK][0]

  if (!link) {
    return null
  }

  return <p>{link}</p>
}

LinkSectionPreview.propTypes = {
  data: PropTypes.object
}

export default LinkSectionPreview
