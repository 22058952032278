import React, { useCallback, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { format } from 'date-fns'

import Can from '../../../features/components/Can'
import ListItemsFilters from '../../../features/components/ListItemsFilters'
import Breadcrumbs from '../../../features/components/Breadcrumbs'
import SummaryPagesAdsContent from '../../CampaignsSummary/SummaryPagesAdsContent'
import SummaryReport from '../../../features/components/SummaryReport'
import LineItemGoogleEdit from '../../../forms/Google/LineItemForms/LineItemGoogleEdit'
import LineItemFacebookEdit from '../../../forms/Facebook/LineItemForms/LineItemFacebookEdit'
import LineItemTikTokEdit from '../../../forms/Tiktok/LineItemForms/LineItemTikTokEdit'
import AdDuplicationModal from '../../../features/components/Modals/AdDuplicationModal'
import SuccessModal from '../../../features/components/Modals/SuccessModal'
import AdCreateFormComponent from './AdCreateFormComponent'

import useSearch from '../../../hooks/useSearch'
import usePermissions from '../../../hooks/usePermissions'

import {
  clearLineItemsSummaryReports,
  getLineItemsSummaryReports,
  setLineItemsSummaryDateRange,
  setLineItemsSummaryFilter,
  setLineItemsSummarySort
} from '../../../modules/actions/lineItemsSummary'
import { openForm } from '../../../modules/actions/forms'
import { copyAd } from '../../../modules/actions/ads'
import {
  lineItemsSummaryDateRangerSelector,
  lineItemsSummaryFilterSelector,
  lineItemsSummaryReportsLoadingSelector,
  lineItemsSummaryReportsSelector,
  lineItemsSummarySortSelector
} from '../../../modules/selectors/lineItemsSummary'
import { lineItemSelector, lineItemWasLoadedSelector } from '../../../modules/selectors/lineItems'
import { selectedPlatformSelector, selectedAdAccountIdSelector } from '../../../modules/selectors/app'
import { selectedAdForCopySelector } from '../../../modules/selectors/ads'

import {
  AD_FACEBOOK_CREATE,
  AD_GOOGLE_CREATE,
  AD_TIK_TOK_CREATE,
  LINE_ITEM_FACEBOOK_EDIT,
  LINE_ITEM_GOOGLE_ADS_EDIT,
  LINE_ITEM_TIKTOK_EDIT
} from '../../../constants/forms'
import { MODAL_TYPE } from '../../../constants/other'
import { FACEBOOK_PLATFORM, GOOGLE_PLATFORM, TIKTOK_PLATFORM } from '../../../constants/selectLists/platformList'
import { CAMPAIGN_FILTERS_DEFAULT } from '../../../constants/selectLists/listItemFiltersList'
import { ROUTES } from '../../../constants/routes'
import { DATES_FORMAT_BE } from '../../../constants/dates'
import { AD_PERMISSION, LINE_ITEM_PERMISSION } from '../../../constants/permissions'

const searchId = 'lineItemsSummaryPage'

const LineItemSummaryContent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { line_item_id: lineItemId, campaign_id: campaignId } = useParams()

  const searchTerm = useSearch(searchId)
  const lineItem = useSelector(lineItemSelector)
  const lineItemWasLoaded = useSelector(lineItemWasLoadedSelector)
  const selectedPlatform = useSelector(selectedPlatformSelector)
  const selectedAdForCopy = useSelector(selectedAdForCopySelector)
  const selectedAdAccount = useSelector(selectedAdAccountIdSelector)
  const dateRange = useSelector(lineItemsSummaryDateRangerSelector)
  const filter = useSelector(lineItemsSummaryFilterSelector)
  const sort = useSelector(lineItemsSummarySortSelector)

  const permissions = usePermissions()

  const { date_preset, startDate, endDate } = dateRange

  // currently reports are implemented only for these platforms from BE side
  const showSummaryReport = selectedPlatform === FACEBOOK_PLATFORM

  const summaryReportTitle = `Ad ${selectedPlatform === FACEBOOK_PLATFORM ? 'Set' : 'Group'} Report and Insights`

  const adsListNoDataText = useMemo(() => {
    const mainText = t('There are no ads to show. ')
    const subText =
      searchTerm || filter !== CAMPAIGN_FILTERS_DEFAULT
        ? t('Try clearing or modifying your search or filter.')
        : t("Click 'Create Ad' to set one up.")

    return (
      <>
        {mainText}
        <br />
        {subText}
      </>
    )
  }, [searchTerm, filter, t])

  const breadcrumbs = [
    {
      title: 'Campaigns list',
      url: ROUTES.campaigns
    },
    {
      title: 'Campaign',
      url: `${ROUTES.campaigns}/${campaignId}`
    },
    {
      title: 'Ad Set',
      url: ''
    }
  ]

  const lineItemCreatedMessage = (
    <>
      <p>{t('Your ad set was created successfully.')}</p>
      <p>
        {t(
          'The next step is to create an ad. We recommend creating around 3 significantly different ads (if possible) for best results.'
        )}
      </p>
    </>
  )

  const openAdFormHandler = useCallback(() => {
    let formName

    if (selectedPlatform === FACEBOOK_PLATFORM) {
      formName = AD_FACEBOOK_CREATE
    } else if (selectedPlatform === GOOGLE_PLATFORM) {
      formName = AD_GOOGLE_CREATE
    } else if (selectedPlatform === TIKTOK_PLATFORM) {
      formName = AD_TIK_TOK_CREATE
    }

    formName && dispatch(openForm({ formName }))
  }, [dispatch, selectedPlatform])

  const getReportsHandler = useCallback(() => {
    dispatch(
      getLineItemsSummaryReports({
        platform: selectedPlatform,
        external_id: selectedAdAccount,
        line_item_id: lineItemId,
        date_from: startDate && format(startDate, DATES_FORMAT_BE),
        date_to: endDate && format(endDate, DATES_FORMAT_BE),
        date_preset
      })
    )
  }, [selectedPlatform, selectedAdAccount, lineItemId, startDate, endDate, date_preset, dispatch])

  const pasteAdHandler = useCallback(() => {
    const data = {
      account: selectedAdAccount,
      adset_id: lineItemId
    }
    dispatch(copyAd(data, selectedAdForCopy.id))
  }, [dispatch, lineItemId, selectedAdForCopy, selectedAdAccount])

  const editFormName = useMemo(() => {
    switch (selectedPlatform) {
      case FACEBOOK_PLATFORM:
        return LINE_ITEM_FACEBOOK_EDIT
      case GOOGLE_PLATFORM:
        return LINE_ITEM_GOOGLE_ADS_EDIT
      case TIKTOK_PLATFORM:
        return LINE_ITEM_TIKTOK_EDIT
      default:
        return ''
    }
  }, [selectedPlatform])

  return (
    <>
      <ListItemsFilters
        breadcrumbsComponent={<Breadcrumbs routes={breadcrumbs} />}
        createFormComponent={
          <Can I="create" a={AD_PERMISSION}>
            <AdCreateFormComponent />
          </Can>
        }
        pageTitle={lineItemWasLoaded ? lineItem.name : <Skeleton width={280} />}
        setFilter={setLineItemsSummaryFilter}
        setDateRange={setLineItemsSummaryDateRange}
        setSort={setLineItemsSummarySort}
        searchId={searchId}
        filterSelector={lineItemsSummaryFilterSelector}
        dateRangeSelector={lineItemsSummaryDateRangerSelector}
        sortSelector={lineItemsSummarySortSelector}
        editButtonOptions={
          permissions.can('update', LINE_ITEM_PERMISSION) && { id: lineItemId, formName: editFormName }
        }
        pasteAdHandler={selectedAdForCopy && pasteAdHandler}
      />
      <SummaryPagesAdsContent
        searchTerm={searchTerm}
        dateRange={dateRange}
        filter={filter}
        sort={sort}
        noDataMessageText={adsListNoDataText}
        isLineItemSummaryPage
      />
      {showSummaryReport && (
        <SummaryReport
          pageTitle={summaryReportTitle}
          getReports={getReportsHandler}
          clearReportsAction={clearLineItemsSummaryReports}
          reportsSelector={lineItemsSummaryReportsSelector}
          reportsLoadingSelector={lineItemsSummaryReportsLoadingSelector}
        />
      )}
      {selectedPlatform === FACEBOOK_PLATFORM && (
        <Can I="update" a={LINE_ITEM_PERMISSION}>
          <LineItemFacebookEdit />
        </Can>
      )}
      {selectedPlatform === GOOGLE_PLATFORM && (
        <Can I="update" a={LINE_ITEM_PERMISSION}>
          <LineItemGoogleEdit />
        </Can>
      )}
      {selectedPlatform === TIKTOK_PLATFORM && (
        <Can I="update" a={LINE_ITEM_PERMISSION}>
          <LineItemTikTokEdit />
        </Can>
      )}
      <Can I="create" a={LINE_ITEM_PERMISSION}>
        <AdDuplicationModal />
      </Can>
      <Can I="create" a={LINE_ITEM_PERMISSION}>
        <SuccessModal
          successButtonText={t('Create Ad')}
          successButtonAction={openAdFormHandler}
          modalType={MODAL_TYPE.newLineItemCreated}
        >
          {lineItemCreatedMessage}
        </SuccessModal>
      </Can>
    </>
  )
}

export default LineItemSummaryContent
