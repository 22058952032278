import * as Yup from 'yup'

import {
  START_DATE,
  END_DATE,
  startDateValidation,
  endDateValidation,
  startEndDateRangeInitialValues
} from '../../../../../../../../ReusableFormFields/StartEndDateRangeFields/fields'
import { OPTION_NO, OPTION_YES } from '../../../../../../../../../constants/forms'

// budget
export const BUDGET_LIFETIME = 'budget_lifetime'
// budget percentage
export const BUDGET_PERCENTAGE_OPTION = 'budget_percentage_option'
export const BUDGET_PERCENTAGE = 'budget_percentage'

export const initialValues = {
  // budget
  [BUDGET_LIFETIME]: '',
  // budget percentage
  [BUDGET_PERCENTAGE_OPTION]: OPTION_NO,
  [BUDGET_PERCENTAGE]: '',
  // dates
  ...startEndDateRangeInitialValues
}

export const validationSchema = Yup.object({
  // budget
  [BUDGET_LIFETIME]: Yup.string().required('Budget Required'),
  // budget percentage
  [BUDGET_PERCENTAGE]: Yup.number().when(BUDGET_PERCENTAGE_OPTION, {
    is: OPTION_YES,
    then: () =>
      Yup.number()
        .required('Maximum percentage required')
        .min(0, 'Percentage must be at least 0%')
        .max(100, "Percentage can't exceed 100%")
  }),
  // dates
  [START_DATE]: startDateValidation,
  [END_DATE]: endDateValidation
})
