import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { MediaOrderFormContext } from '../../../../MediaOrderFormContext'

import { selectedMediaPackagesSelector } from '../../../../../../../modules/selectors/mediaOrders'

export function useGetSelectedCurrency() {
  const selectedMediaPackages = useSelector(selectedMediaPackagesSelector)

  const { contextSelfAccountData } = useContext(MediaOrderFormContext)
  const { currency: currencyCode, currency_symbol } = contextSelfAccountData

  // selected currency:
  return useMemo(() => {
    if (currencyCode && currency_symbol) {
      // when selfAccount currency exists use it
      return { code: currencyCode, symbol: currency_symbol }
    } else if (selectedMediaPackages[0]?.prices) {
      // if no selfAccount currency use the first prices currency
      const price = selectedMediaPackages[0]?.prices[0]
      return { code: price.currency, symbol: price.currency_symbol }
    }
  }, [currencyCode, currency_symbol, selectedMediaPackages])
}
