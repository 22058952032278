import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'

import Chip from '../../../../../../../../../../../components/Chip'
import ImagesModal from '../../../../../../../../../../../features/components/Modals/ImagesModal'

import { ALT_TEXT } from '../../../../../../../../../MediaProductLocationForms/fields'
import { getMediaProductLocationIsLoadingSelector } from '../../../../../../../../../../../modules/selectors/mediaOrdersProductLocations'

import useStyles from '../styles'

const LocationsImagesPreview = ({ selectedLocationImages, locationName }) => {
  const classes = useStyles()
  const isLocationLoading = useSelector(getMediaProductLocationIsLoadingSelector)

  const [showLocationImagesModal, setShowLocationImagesModal] = useState(false)

  const firstImageId = useMemo(() => {
    return selectedLocationImages?.[0]?.id
  }, [selectedLocationImages])

  const [selectedImageId, setSelectedImageId] = useState(firstImageId)

  const image = useMemo(
    () => selectedLocationImages?.find(image => image.id === selectedImageId) || {},
    [selectedLocationImages, selectedImageId]
  )

  const handleSelectImage = useCallback(
    imageId => {
      setSelectedImageId(imageId)
    },
    [setSelectedImageId]
  )

  const handleImageClick = useCallback(() => {
    setShowLocationImagesModal(true)
  }, [])

  const closeLocationImagesModal = useCallback(() => {
    setShowLocationImagesModal(false)
  }, [setShowLocationImagesModal])

  useEffect(() => {
    // If the selected location changes, select the first image
    if (firstImageId) {
      setSelectedImageId(firstImageId)
    }
  }, [firstImageId])

  if (isLocationLoading) {
    return (
      <div className={classes.skeletonWrapper}>
        <Skeleton width={400} height={300} />
      </div>
    )
  }

  if (!selectedLocationImages?.length) {
    return null
  }

  return (
    <>
      <ImagesModal
        title={locationName}
        images={selectedLocationImages}
        showModal={showLocationImagesModal}
        onModalClose={closeLocationImagesModal}
        selectedImageId={image?.id}
        handleSelectImage={handleSelectImage}
      />
      <div className={classes.imagesWrapper}>
        <div className={classes.imagesContainer}>
          {image.image && <img src={image.image} alt={image[ALT_TEXT]} onClick={handleImageClick} />}
        </div>
        <div className={classes.imagesButtons}>
          {selectedLocationImages.map((image, index) => {
            if (selectedLocationImages.length === 1 && !image[ALT_TEXT]) {
              // Don't show the button if there is only one image and it doesn't have alt text
              return null
            } else {
              return (
                <Chip
                  key={image.image}
                  text={image.title}
                  className={classnames(classes.chipButton, {
                    active: image.id === selectedImageId
                  })}
                  onClick={() => handleSelectImage(image.id)}
                />
              )
            }
          })}
        </div>
      </div>
    </>
  )
}

LocationsImagesPreview.propTypes = {
  locationName: PropTypes.string,
  selectedLocationImages: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      [ALT_TEXT]: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  )
}

export default LocationsImagesPreview
