import * as Yup from 'yup'

import { allCampaignsMarginValidationSchema } from '../../../../../../forms/ReusableFormFields/MarginFields/AllCampaignsMargin/validation'
import { marginFeeValidation } from '../../../../../../forms/ReusableFormFields/MarginFields/MarginFee/validation'

export const storiesItemShape = Yup.object({})

export const validationSchema = Yup.object({
  ...allCampaignsMarginValidationSchema,
  ...marginFeeValidation
})
