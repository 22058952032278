import { createStyles } from '../../../../styles/helpers'

import { black } from '../../../../styles/const/colors'

const useStyles = createStyles({
  warningMessage: {
    marginTop: 60
  },
  warningIcon: {
    textAlign: 'center'
  },
  description: {
    marginTop: 16,
    '& a': {
      color: 'inherit',

      '&:hover': {
        color: black
      }
    }
  }
})

export default useStyles
