export const getFileType = file => file && file.type.split('/')[0]

export const getFileFormatFromName = name => {
  const lastDotIndex = name.lastIndexOf('.')
  return name.slice(lastDotIndex + 1)
}

export const getFileFormat = file => {
  const formatFromName = getFileFormatFromName(file.name)
  // For some reason mov file has 'quicktime' in field type, because of that we handle mov files manually
  if (formatFromName === 'mov') {
    return 'mov'
  } else {
    return file.type.split('/')[1]
  }
}
