import { formatLocationTabsToBE } from '../../../MediaProductCreate/MediaProductCreateForm/formatters'
import { formatProductImagesToBE } from '../../sections/ImagesSection/formatters'

import { DESCRIPTION, INTERNAL_ID, NAME, TAGS } from '../../../MediaProductCreate/MediaProductCreateForm/fields'
import { SUB_PRODUCTS, VARIABLES } from '../../../fields'
import { formatCategoriesToBE } from '../../../formatters'

export const transformValuesToBE = ({ initialValues, values }) => {
  return {
    [NAME]: values[NAME],
    ...(values[DESCRIPTION] && { [DESCRIPTION]: values[DESCRIPTION] }),
    ...formatCategoriesToBE(values),
    ...formatProductImagesToBE(initialValues, values),
    ...(values[TAGS].length && { [TAGS]: values[TAGS].map(option => option.value) }),
    ...formatLocationTabsToBE(values),
    [VARIABLES]: values[VARIABLES].map(variable => variable.value),
    [INTERNAL_ID]: values[INTERNAL_ID],
    [SUB_PRODUCTS]: values[SUB_PRODUCTS].map(product => product.value)
  }
}
