import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles(theme => ({
  image: {
    height: 157,
    width: '100%',
    objectFit: 'cover'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'auto',
    marginBottom: 32
  }
}))

export default useStyles
