import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Button from '../../../../../../components/Button'
import InterestsFields from '../../../../../ReusableFormFields/InterestsFields'
import SelectMultiLevel from '../../../../../../components/SelectMultiLevel'

import { getTikTokInterests } from '../../../../../../modules/actions/tikTokConstants'
import {
  tikTokInterestsIsLoadingSelector,
  tikTokInterestsSelector
} from '../../../../../../modules/selectors/tikTokConstants'
import { selectedAdAccountIdSelector } from '../../../../../../modules/selectors/app'

import { INTERESTS, INTERESTS_OPTION } from '../../../../../ReusableFormFields/InterestsFields/fields'

import useStyles from '../../../../../../styles/common/stepForms'

const stepFields = [INTERESTS_OPTION, INTERESTS]

const InterestsStep = ({ formik, handleStepChange, adAccountId: providedAdAccountId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { setFieldValue, values } = formik

  const interests = useSelector(tikTokInterestsSelector)
  const interestsLoading = useSelector(tikTokInterestsIsLoadingSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const adAccountId = providedAdAccountId || selectedAdAccountId

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  useEffect(() => {
    dispatch(getTikTokInterests({ account: adAccountId, language: 'en', placement: ['PLACEMENT_TIKTOK'] }))
  }, [dispatch, adAccountId])

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create a TikTok ad group')}</div>
      <div className={classes.stepTitle}>{t('Do you want to target by people’s interests?')}</div>
      <div className={classes.stepBody}>
        <InterestsFields
          formik={formik}
          InterestsCustomField={
            <SelectMultiLevel
              placeholder={t('Add an Interest')}
              setFieldValue={setFieldValue}
              value={values[INTERESTS]}
              name={INTERESTS}
              options={interests}
              isLoading={interestsLoading}
            />
          }
        />
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" className="dark" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default InterestsStep
