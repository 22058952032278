import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ContentRow from '../../../features/components/ContentSection/ContentRow'
import ContentSection from '../../../features/components/ContentSection'
import MembersTable from './MembersTable'
import NewUserCreate from './NewUserCreate'
import DeactivateUserModal from './DeactivateUserModal'

import useModalManage from '../../../hooks/useModalManage'

import {
  clearInvitesList,
  clearMembersList,
  clearUpdateMember,
  deleteInvite,
  getInvitesList,
  getMembersList,
  resendInvite,
  updateSelfAccountMember,
  updateMemberStatus
} from '../../../modules/actions/selfAccountTeam'

import { selectedSelfAccountSelector, selectedSelfAccountDataSelector } from '../../../modules/selectors/app'
import { updatedMemberSelector } from '../../../modules/selectors/selfAccountTeam'

import { MEMBER_CHANGE_DATA, MEMBER_CHANGE_STATUS } from '../../../constants/forms'

import useSettingsClasses from '../styles'

function ManageSelfAccountTeam() {
  const settingsClasses = useSettingsClasses()

  const [memberId, setMemberId] = useState(null)

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const selfAccount = useSelector(selectedSelfAccountSelector)
  const selfAccountData = useSelector(selectedSelfAccountDataSelector)
  const member = useSelector(updatedMemberSelector)
  const { wasUpdated: memberWasUpdated, updatingType } = member

  const { isModalOpened, closeModalHandler, openModalHandler } = useModalManage({})

  const memberDataWasUpdated = memberWasUpdated && updatingType === MEMBER_CHANGE_DATA
  const memberStatusWasUpdated = memberWasUpdated && updatingType === MEMBER_CHANGE_STATUS

  const changeMemberStatusHandler = useCallback(
    ({ id, isActive }) => {
      if (isActive) {
        setMemberId(id)
        openModalHandler()
      } else {
        dispatch(updateMemberStatus(id, 'inactive'))
      }
    },
    [dispatch, openModalHandler]
  )

  const changeMemberRoleHandler = useCallback(
    ({ id, newRole }) => {
      dispatch(updateSelfAccountMember(id, { role: newRole }))
    },
    [dispatch]
  )

  const deactivateHandler = useCallback(() => {
    dispatch(updateMemberStatus(memberId, 'active'))
  }, [dispatch, memberId])

  const clearUpdateMemberHandler = useCallback(() => {
    dispatch(clearUpdateMember())
  }, [dispatch])

  const resendInviteHandler = useCallback(
    id => {
      dispatch(resendInvite(id))
    },
    [dispatch]
  )

  const deleteInviteHandler = useCallback(
    id => {
      dispatch(deleteInvite(id))
    },
    [dispatch]
  )

  useEffect(() => {
    // we don't need to use loadMore handlers for members and invites, just load all
    dispatch(getMembersList({ account: selfAccount, limit: 999 }))
    dispatch(getInvitesList({ account: selfAccount, limit: 999 }))

    return () => {
      dispatch(clearMembersList())
      dispatch(clearInvitesList())
    }
  }, [dispatch, selfAccount])

  useEffect(() => {
    if (memberStatusWasUpdated || memberDataWasUpdated) {
      clearUpdateMemberHandler()
    }
  }, [clearUpdateMemberHandler, memberStatusWasUpdated, memberDataWasUpdated])

  return (
    <>
      <h1 className={settingsClasses.title}>{t('selfAccountNameTeam', { selfAccountName: selfAccountData?.name })}</h1>
      <ContentSection title={t('Client team members')}>
        <ContentRow
          title={t('Users and invites')}
          description={t('Invite new user to this account')}
          leftColumnChildren={<NewUserCreate />}
        >
          <MembersTable
            onChangeMemberStatus={changeMemberStatusHandler}
            onChangeMemberRole={changeMemberRoleHandler}
            onResendInvite={resendInviteHandler}
            onDeleteInvite={deleteInviteHandler}
          />
        </ContentRow>
      </ContentSection>
      <DeactivateUserModal
        member={member}
        deactivateHandler={deactivateHandler}
        isModalOpened={isModalOpened}
        closeModalHandler={closeModalHandler}
        clearHandler={clearUpdateMemberHandler}
        memberStatusWasUpdated={memberStatusWasUpdated}
        accountName={`${selfAccountData?.name} account`}
      />
    </>
  )
}

export default ManageSelfAccountTeam
