import { createStyles } from '../../../../../styles/helpers'

export default createStyles({
  adStoriesCarousel: {
    position: 'absolute',
    overflow: 'hidden',
    maxHeight: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex'
  }
})
