import React from 'react'

import LoadingOptions from './LoadingOptions'

import SelectAllOptions from './SelectAllOptions'

const SelectMenuReusable = props => {
  const { isLoading, children, selectProps, options } = props

  const onSelectAll = selectProps?.onSelectAll
  const showSelectAll = Boolean(onSelectAll) && options?.length > 1 && !isLoading

  return (
    <>
      {showSelectAll && <SelectAllOptions selectProps={selectProps} options={options} />}
      {children}
      {isLoading && <LoadingOptions />}
    </>
  )
}

export default SelectMenuReusable
