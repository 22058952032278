import { calc } from '../../../../../../../helpers/numbers'

import { PRODUCT_PERIODS_DATES, QUANTITY } from '../../../../fields'

export const getProductDiscount = ({ discounts, activePresetDiscount, periodsAmount }) => {
  if (activePresetDiscount) {
    return activePresetDiscount.discount_percentage
  } else {
    const quantityDiscount = getQuantityDiscount({ discounts, periodsAmount })
    return quantityDiscount?.discount_percentage
  }
}
const getQuantityDiscount = ({ discounts, periodsAmount }) => {
  const hasDiscounts = !!discounts?.length
  if (hasDiscounts) {
    // there are some products with discounts so call the function only if there is discounts
    // find the discount that applies to the current amount of publications:
    return [...discounts]
      .sort((a, b) => {
        // bigger discount first:
        if (a.value < b.value) return 1
        if (a.value > b.value) return -1

        return 0
      })
      .find(discount => discount.value <= periodsAmount)
  } else {
    return null
  }
}
export const getProductPrice = (prices, currency) => prices?.find(price => price.currency === currency)

export const getDecimalDiscount = discount => (!!discount ? calc(discount).div(100).toNumber() : 0)
export const calculateDiscountedPrice = (price, quantity, discount = 1) => {
  // discount expected in decimal values i.e. 10% is 0.1
  const priceCoefficient = discount ? 1 - discount : 1
  return calc(price).mul(priceCoefficient).mul(quantity).toDP(2).toNumber()
}

export const calculateTotalOrderPrice = (products, productsDiscount) => {
  // sum all the prices of the products
  return products.reduce(
    (acc, productValues) => {
      const productData = productValues.data
      const publications = productValues[PRODUCT_PERIODS_DATES]
      const productQuantity = productValues[QUANTITY]

      // add each publication price to get total product price
      const productPrice = publications.reduce((acc, publication) => {
        // format to number to avoid string concatenation i.e '' 10 + '' + 10 = '1010'
        return acc + Number(publication.price)
      }, 0)
      const discount = productsDiscount.find(
        discountProduct => discountProduct.id === productData.id
      )?.discountPercentage

      acc.regularPrice += productPrice * productQuantity
      if (discount) {
        // if there is a discount, add it to the total
        acc.discountedPrice += calculateDiscountedPrice(productPrice, productQuantity, discount)
      } else {
        // if there is no discount, add the regular price to the total
        acc.discountedPrice += productPrice * productQuantity
      }

      return acc
    },
    { regularPrice: 0, discountedPrice: 0 }
  )
}
