import React, { useCallback } from 'react'
import { isPast } from 'date-fns'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import DatePicker from '../../../components/DatePicker'
import TimePicker from '../../../components/TimePicker'

import { START_DATE, START_TIME, STOP_DATE, STOP_TIME } from '../../Multiplatform/LineItemForms/fields'

import useStyles from './styles'
import useDrawerFormStyles from '../../../styles/common/drawerForms'

const StartEndTimeFields = ({
  formik,
  indefiniteText = 'This ad set has been set up to run indefinitely until paused.'
}) => {
  const { t } = useTranslation()

  const classes = useStyles()
  const drawerFormClasses = useDrawerFormStyles()

  const { setFieldValue, values, touched, errors } = formik

  const isStartDateInPast = isPast(new Date(values[START_DATE]))

  const handleStartDatePickerChange = useCallback(
    value => {
      setFieldValue(START_DATE, value)
    },
    [setFieldValue]
  )

  const handleStartTimePickerChange = useCallback(
    value => {
      setFieldValue(START_TIME, value || '00:00')
    },
    [setFieldValue]
  )

  const handleStopDatePickerChange = useCallback(
    value => {
      setFieldValue(STOP_DATE, value)
    },
    [setFieldValue]
  )

  const handleStopTimePickerChange = useCallback(
    value => {
      setFieldValue(STOP_TIME, value || '00:00')
    },
    [setFieldValue]
  )

  return (
    <>
      <h5 className={drawerFormClasses.sectionSubtitle}>{t('Start Date')}</h5>
      <div className={classes.dateFieldsWrapper}>
        <DatePicker
          title={t('Start Date')}
          value={values[START_DATE]}
          changeHandler={handleStartDatePickerChange}
          disabled={isStartDateInPast}
        />
        <TimePicker
          value={values[START_TIME]}
          changeHandler={handleStartTimePickerChange}
          disabled={isStartDateInPast}
        />
      </div>

      {/*END DATE*/}
      <h5 className={drawerFormClasses.sectionSubtitle}>{t('End Date')}</h5>
      {values[STOP_DATE] ? (
        <div className={classes.dateFieldsWrapper}>
          <DatePicker
            title={t('End Date')}
            value={values[STOP_DATE]}
            changeHandler={handleStopDatePickerChange}
            minDate={isStartDateInPast ? new Date() : values[START_DATE]}
            error={touched[STOP_DATE] && errors[STOP_DATE]}
          />
          <TimePicker value={values[STOP_TIME]} changeHandler={handleStopTimePickerChange} />
        </div>
      ) : (
        <p>{t(indefiniteText)}</p>
      )}
    </>
  )
}

StartEndTimeFields.propTypes = {
  formik: PropTypes.object.isRequired,
  indefiniteText: PropTypes.string
}

export default StartEndTimeFields
