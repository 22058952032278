import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import RadioBlock from '../../../components/Form/RadioBlock'

import Checkbox from '../../../components/Form/Checkbox'
import ErrorMessage from '../../../components/Form/ErrorMessage'

import {
  AGE_13_17,
  AGE_18_24,
  AGE_18_34,
  AGE_25_34,
  AGE_25_54,
  AGE_35_44,
  AGE_45_54,
  AGE_55_100,
  AGE_OPTION,
  ANYONE_OVER_18,
  CUSTOM_AGE_OPTION,
  CUSTOM_AGE_RANGES
} from './fields'

const AgeRangesFields = ({ formik }) => {
  const { t } = useTranslation()

  const { values, errors, touched, setFieldValue } = formik

  const checkCustomAgeRangeHandler = useCallback(
    e => {
      setFieldValue(CUSTOM_AGE_RANGES, {
        ...values[CUSTOM_AGE_RANGES],
        [e.target.name]: !values[CUSTOM_AGE_RANGES][e.target.name]
      })
    },
    [setFieldValue, values]
  )

  return (
    <>
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_age_anyone_over_18"
        name={AGE_OPTION}
        value={ANYONE_OVER_18}
        selectedValue={values[AGE_OPTION]}
        label={t('Anyone over 18')}
      />
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_age_18_34"
        name={AGE_OPTION}
        value={AGE_18_34}
        selectedValue={values[AGE_OPTION]}
        label={t('18-34')}
      />
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_age_25_54"
        name={AGE_OPTION}
        value={AGE_25_54}
        selectedValue={values[AGE_OPTION]}
        label={t('25-54')}
      />
      <RadioBlock
        setFieldValue={setFieldValue}
        id="radio_custom_age_range"
        name={AGE_OPTION}
        value={CUSTOM_AGE_OPTION}
        selectedValue={values[AGE_OPTION]}
        label={t('Custom age range')}
      >
        <Checkbox
          id="checkbox_age_13_17"
          title="13-17"
          isLarge
          name={AGE_13_17}
          checked={values[CUSTOM_AGE_RANGES][AGE_13_17]}
          onCheck={checkCustomAgeRangeHandler}
        />
        <Checkbox
          id="checkbox_age_18_24"
          title="18-24"
          isLarge
          name={AGE_18_24}
          checked={values[CUSTOM_AGE_RANGES][AGE_18_24]}
          onCheck={checkCustomAgeRangeHandler}
        />
        <Checkbox
          id="checkbox_age_25_34"
          title="25-34"
          isLarge
          name={AGE_25_34}
          checked={values[CUSTOM_AGE_RANGES][AGE_25_34]}
          onCheck={checkCustomAgeRangeHandler}
        />
        <Checkbox
          id="checkbox_age_35_44"
          title="35-44"
          isLarge
          name={AGE_35_44}
          checked={values[CUSTOM_AGE_RANGES][AGE_35_44]}
          onCheck={checkCustomAgeRangeHandler}
        />
        <Checkbox
          id="checkbox_age_45_54"
          title="45-54"
          isLarge
          name={AGE_45_54}
          checked={values[CUSTOM_AGE_RANGES][AGE_45_54]}
          onCheck={checkCustomAgeRangeHandler}
        />
        <Checkbox
          id="checkbox_age_55_100"
          title="55+"
          isLarge
          name={AGE_55_100}
          checked={values[CUSTOM_AGE_RANGES][AGE_55_100]}
          onCheck={checkCustomAgeRangeHandler}
        />
        {touched[CUSTOM_AGE_RANGES] && errors[CUSTOM_AGE_RANGES] && <ErrorMessage error={errors[CUSTOM_AGE_RANGES]} />}
      </RadioBlock>
    </>
  )
}

export default AgeRangesFields
