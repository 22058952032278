import * as Yup from 'yup'
import { locationListValidation } from '../../forms/ReusableFormFields/LineItemForms/fields'
import {
  SHOW_COUNTRIES,
  SHOW_OPTION,
  SHOW_OPTION_COUNTRIES,
  SHOW_OPTION_LOCATION_LIST,
  LOCATION_LIST,
  SHOW_REGIONS
} from '../../forms/ReusableFormFields/GeolocationsFields/fields'

export const showCountriesValidation = Yup.array().min(1, 'Please select at least one option')

export const regionsOverlapValidation = Yup.array()
  .min(1, 'Please select at least one option')
  .test(
    SHOW_REGIONS,
    'There is an overlap in your location targeting. You are trying to target a region and a city in that region. Remove one of these so that the locations to do not overlap.',
    value => {
      let hasOverlap

      value.forEach(({ region_id }) => {
        if (region_id) {
          hasOverlap = value.find(item => {
            return String(region_id) === String(item.value)
          })
        }
      })

      return !hasOverlap
    }
  )

export const geolocationsValidation = {
  [SHOW_COUNTRIES]: Yup.array().when(SHOW_OPTION, {
    is: SHOW_OPTION_COUNTRIES,
    then: () => showCountriesValidation
  }),
  [LOCATION_LIST]: Yup.string().when(SHOW_OPTION, {
    is: SHOW_OPTION_LOCATION_LIST,
    then: () => locationListValidation.required('Please select location list')
  })
}
