import { createStyles } from '../../../../styles/helpers'
import { siteLink } from '../../../../styles/common'

const useStyles = createStyles({
  link: {
    extend: siteLink
  }
})

export default useStyles
