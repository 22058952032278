import React from 'react'
import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

const SkeletonWelcomeSection = () => {
  const classes = useStyles()

  return (
    <div className={classes.section}>
      <div className={classes.textColumn}>
        <h1 className={classes.header}>
          <Skeleton height={40} />
        </h1>
        <p className={classes.description}>
          <Skeleton height={20} width={'90%'} />
        </p>
        <p className={classes.description}>
          <Skeleton height={20} width={'85%'} />
        </p>
        <p className={classes.description}>
          <Skeleton height={20} width={'60%'} />
        </p>
        <Skeleton height={32} width={160} style={{ marginTop: 32 }} />
      </div>
    </div>
  )
}

export default SkeletonWelcomeSection
