import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import FieldsSection from '../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../features/components/Form/FieldsSection/FieldRow'
import CheckboxGroup from '../../../../features/components/Form/CheckboxGroup'

import { campaignSelector } from '../../../../modules/selectors/campaigns'

import {
  getAudienceNetworkCheckboxOptions,
  getFeedsCheckboxOptions,
  getInStreamCheckboxOptions,
  getMessagesCheckboxOptions,
  getSearchCheckboxOptions,
  getStoriesAndReelsCheckboxOptions
} from '../../../ReusableFormFields/LineItemForms/facebookLineItemPlacementsHelpers'

import useStyles from './styles'

const PlacementsSection = ({ formik }) => {
  const classes = useStyles()

  const { setFieldValue, setValues, values } = formik

  const { objective: campaignObjective } = useSelector(campaignSelector)

  const feedsOptions = useMemo(() => getFeedsCheckboxOptions(campaignObjective), [campaignObjective])

  const checkboxGroupProps = {
    values: values,
    setFieldValue: setFieldValue,
    setValues: setValues,
    isLarge: false,
    showSelectAllButton: false,
    className: classes.placementCheckboxGroup
  }

  return (
    <FieldsSection title="Placements">
      <FieldRow title="Feeds" description="High visibility for your business with ads in feeds">
        <CheckboxGroup options={feedsOptions} {...checkboxGroupProps} />
      </FieldRow>

      <FieldRow
        title="Stories and Reels"
        description="Tell a rich, visual story with immersive, full-screen vertical ads"
      >
        <CheckboxGroup options={getStoriesAndReelsCheckboxOptions(campaignObjective)} {...checkboxGroupProps} />
      </FieldRow>

      <FieldRow
        title="In-stream ads for videos and reels"
        description="Reach people before, during or after they watch a video or reel"
      >
        <CheckboxGroup options={getInStreamCheckboxOptions(campaignObjective)} {...checkboxGroupProps} />
      </FieldRow>
      <FieldRow title="Search results" description="Get visibility for your business as people search">
        <CheckboxGroup options={getSearchCheckboxOptions(campaignObjective)} {...checkboxGroupProps} />
      </FieldRow>
      <FieldRow
        title="Messages"
        description={
          <p>
            Send offers or updates to <u>people</u> who are already connected to your business
          </p>
        }
      >
        <CheckboxGroup options={getMessagesCheckboxOptions(campaignObjective)} {...checkboxGroupProps} />
      </FieldRow>
      <FieldRow title="Apps and sites" description="Expand your reach with ads in external apps and websites">
        <CheckboxGroup options={getAudienceNetworkCheckboxOptions(campaignObjective)} {...checkboxGroupProps} />
      </FieldRow>
    </FieldsSection>
  )
}

export default PlacementsSection
