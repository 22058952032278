import React from 'react'
import { useSelector } from 'react-redux'

import FacebookAdCardList from '../../../../pages/CampaignsSummary/SummaryPagesAdsContent/FacebookAdCardList'

import {
  adsErrorSelector,
  adsLoadingSelector,
  adsWasLoadedSelector,
  getAdsSelector
} from '../../../../modules/selectors/ads'
import { selectedAdAccountDataSelector } from '../../../../modules/selectors/app'

const summaryPagesFacebookAdProps = {
  showPurchaseMetric: true
}

const SummaryPagesFacebookAdsList = ({ loadMoreHandler, handleDeleteRequest }) => {
  const adsList = useSelector(getAdsSelector)

  const { currency_symbol: currencySymbol } = useSelector(selectedAdAccountDataSelector)

  return (
    <FacebookAdCardList
      adsList={adsList}
      loadingSelector={adsLoadingSelector}
      errorSelector={adsErrorSelector}
      wasLoadedSelector={adsWasLoadedSelector}
      loadMoreHandler={loadMoreHandler}
      handleDeleteRequest={handleDeleteRequest}
      noDataText="There are no ads to show. "
      currencySymbol={currencySymbol}
      {...summaryPagesFacebookAdProps}
    />
  )
}

export default SummaryPagesFacebookAdsList
