import React from 'react'
import PropTypes from 'prop-types'

import ButtonClose from '../../features/components/Buttons/ButtonClose'

import { ReactComponent as SuccessIcon } from '../../assets/icons/checked-circle.svg'
import { ReactComponent as InfoIcon } from '../../assets/icons/info-icon.svg'
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg'

import { TOAST_TYPE } from '../../constants/other'

import useStyles from './styles'

const typeValues = {
  [TOAST_TYPE.success]: {
    title: 'SUCCESS',
    icon: <SuccessIcon />
  },
  [TOAST_TYPE.error]: {
    title: 'ERROR',
    icon: <WarningIcon />
  },
  [TOAST_TYPE.info]: {
    title: 'INFORMATION',
    icon: <InfoIcon />
  }
}

function ToastNotification({ title, appearance, titleIcon, message, closeToast }) {
  const classes = useStyles()

  return (
    <div className="toast-notify">
      <div className={classes.title}>
        <div className={classes.icon}>{titleIcon || (typeValues[appearance] && typeValues[appearance].icon)}</div>
        <div className={classes.titleText}>{title || (typeValues[appearance] && typeValues[appearance].title)}</div>
        <ButtonClose className={classes.closeBtn} onClick={closeToast} />
      </div>
      {message && <div className={classes.message}>{message}</div>}
    </div>
  )
}

export default ToastNotification

ToastNotification.propTypes = {
  title: PropTypes.string,
  titleIcon: PropTypes.object,
  appearance: PropTypes.string,
  message: PropTypes.string.isRequired,
  closeToast: PropTypes.func
}
