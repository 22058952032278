import { format } from 'date-fns'

import { DATES_FORMAT_FULL_YEAR } from '../../../../constants/dates'

export const formatDateRangeLabel = (datePeriod = {}) => {
  const { startDate, endDate } = datePeriod

  if (startDate && endDate) {
    //   Date Range: 01 Aug 2024 - 31 Aug 2024
    return `Date Range: ${format(startDate, DATES_FORMAT_FULL_YEAR)} - ${format(endDate, DATES_FORMAT_FULL_YEAR)}`
  } else if (startDate) {
    //   Date Range: From 01 Aug 2024
    return `Date Range: From ${format(startDate, DATES_FORMAT_FULL_YEAR)}`
  } else {
    return ''
  }
}
