import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CampaignsDateRange from '../../../pages/Campaigns/CampaignsDateRange'
import ButtonIcon from '../Buttons/ButtonIcon'
import { TabletUp, TabletDown } from '../../../components/hoc/ResponsiveRendering'

import FiltersSearch from '../Filters/FiltersSearch'
import Select from '../../../components/Select'

import {
  campaignFiltersList
  // campaignSortList
} from '../../../constants/selectLists/listItemFiltersList'

// import { ReactComponent as SortIcon } from '../../../assets/icons/sort-down.svg'
import { ReactComponent as PencilIcon } from '../../../assets/icons/pencil.svg'
import { ReactComponent as PasteIcon } from '../../../assets/icons/paste-icon.svg'

import { openForm } from '../../../modules/actions/forms'

import { selectedPlatformSelector } from '../../../modules/selectors/app'

import { FACEBOOK_PLATFORM } from '../../../constants/selectLists/platformList'

import useStyles from './styles'

function ListItemsFilters({
  breadcrumbsComponent,
  pageTitle,
  pasteAdHandler,
  createFormComponent,
  setFilter,
  // setSort,
  searchId,
  setDateRange,
  filterSelector,
  dateRangeSelector,
  // sortSelector,
  editButtonOptions
}) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const filter = useSelector(filterSelector)
  // const sort = useSelector(sortSelector)
  const selectedPlatform = useSelector(selectedPlatformSelector)
  //
  // const sortOptions =
  //   !sort.value || sort.value === '' ? campaignSortList : [{ value: '', label: 'Clear sort' }, ...campaignSortList]

  const handleFilterChange = useCallback(
    filter => {
      dispatch(setFilter(filter.value))
    },
    [dispatch, setFilter]
  )

  // const handleSortChange = useCallback(
  //   item => {
  //     dispatch(setSort(item))
  //   },
  //   [dispatch, setSort]
  // )

  const handleEditButtonClick = () => {
    if (editButtonOptions) {
      const { id, formName } = editButtonOptions
      dispatch(openForm({ id, formName }))
    }
  }

  const AdditionalActionButtons = (
    <>
      {editButtonOptions && (
        <ButtonIcon className={classes.actionBtn} Icon={PencilIcon} onClick={handleEditButtonClick} />
      )}
      {pasteAdHandler && selectedPlatform === FACEBOOK_PLATFORM && (
        <ButtonIcon className={classes.actionBtn} Icon={PasteIcon} onClick={pasteAdHandler} />
      )}
    </>
  )

  return (
    <>
      {breadcrumbsComponent && <div>{breadcrumbsComponent}</div>}
      <h1 className={classes.pageTitle}>{pageTitle}</h1>
      <div className={classes.filtersContainer}>
        <TabletUp>
          <div className={classes.desktopFiltersWrapper}>
            <div className={classes.createFormComponent}>{createFormComponent}</div>
            <div className={classes.filtersRow}>
              <FiltersSearch searchId={searchId} />
              <Select
                className={classes.select}
                placeholder="Filter"
                value={filter}
                options={campaignFiltersList}
                onChange={handleFilterChange}
                isSmall
              />
              <CampaignsDateRange selectDateRange={setDateRange} dateRangeSelector={dateRangeSelector} />
              {/*<Select*/}
              {/*  options={sortOptions}*/}
              {/*  isSearchable={false}*/}
              {/*  value={sort.value}*/}
              {/*  CustomDownIcon={<SortIcon />}*/}
              {/*  onChange={handleSortChange}*/}
              {/*  isSmall*/}
              {/*  isIconSelect*/}
              {/*/>*/}
              {AdditionalActionButtons}
            </div>
          </div>
        </TabletUp>

        <TabletDown>
          {createFormComponent}
          <div className={classes.filtersRow}>
            <div className={classes.mobileDateRangeWrapper}>
              <CampaignsDateRange selectDateRange={setDateRange} dateRangeSelector={dateRangeSelector} isMobile />
            </div>
            {AdditionalActionButtons}
          </div>
          {/*<Select*/}
          {/*  options={sortOptions}*/}
          {/*  isSearchable={false}*/}
          {/*  value={sort.value}*/}
          {/*  CustomDownIcon={<SortIcon />}*/}
          {/*  onChange={handleSortChange}*/}
          {/*  isSmall*/}
          {/*  isIconSelect*/}
          {/*/>*/}
        </TabletDown>
      </div>
    </>
  )
}

export default ListItemsFilters
