import { createStyles } from '../../../../../../../../styles/helpers'

const useStyles = createStyles({
  agesContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  yearField: {
    flex: 1
  },
  to: {
    width: 30,
    textAlign: 'center'
  }
})

export default useStyles
