import Qs from 'qs'
import { v4 as uuidv4 } from 'uuid'

import { checkAuthToken, getToken } from './getToken'
import { handleIncomingChunk } from './chunksBuffering'

export async function madeSocketRequest(method, { queryParams, params, data, url }, handlers) {
  const { handleStreamUpdate, onSocketOpen } = handlers
  const protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://'
  // proxy doesn't work with wss, so we need to use dev.shopometry.com
  const isProxy = window.location.hostname.includes('localhost') || window.location.hostname.includes('features')
  const host = isProxy ? 'dev.shopometry.com' : window.location.host
  const path = protocol + host + url

  const token = await getToken()
  // check token before request
  checkAuthToken(token)

  const requestParams = {
    ...queryParams,
    authorization: `Bearer ${token}`
  }
  const serializedParams = Qs.stringify(requestParams, { arrayFormat: 'repeat' })
  const requestUrl = path + '?' + serializedParams

  return apiStreamSocket({ method, url: requestUrl, params, data }, handleStreamUpdate, onSocketOpen)
}

export async function apiStreamSocket({ method, url, params, data }, onStreamMessage, onSocketOpen) {
  return new Promise((resolve, reject) => {
    // setting request timeout - reject if no response in 30 seconds
    let hasReturned = false
    setTimeout(function () {
      if (!hasReturned) {
        reject()
      }
    }, 30000)

    try {
      const socket = new WebSocket(url)

      let responseBuffer = ''
      const requestId = uuidv4()

      socket.addEventListener('open', function (event) {
        hasReturned = true
        onSocketOpen && onSocketOpen(socket)
        // send payload with request type once's socket connection is established
        socket.send(
          JSON.stringify({
            request_id: requestId,
            request_type: method,
            params,
            data
          })
        )
      })

      function handleStreamMessages(socket, resolve, reject) {
        socket.addEventListener('message', function (event) {
          const data = JSON.parse(event.data)

          // each websocket message contains a data to specific request_id
          // as there could be multiple websockets requests at the same time we need to filter by correct request_id
          if (requestId === data.request_id) {
            const chunk = data?.data?.chunk

            if (chunk) {
              const parsedData = handleIncomingChunk(chunk, data.request_id)
              parsedData && onStreamMessage && onStreamMessage(parsedData)
              responseBuffer += chunk
            }

            if (data?.status === 'completed') {
              handleServerResponse({
                socket,
                resolve,
                reject,
                responseBuffer,
                data: data?.data
              })
            }
          }
        })
      }
      handleStreamMessages(socket, resolve, reject)
      handleServerError(socket, reject)
    } catch (error) {
      hasReturned = true
      reject(error)
    }
  })
}

function handleServerResponse({ socket, resolve, reject, responseBuffer, data }) {
  socket.close()

  try {
    resolve(responseBuffer ? JSON.parse(responseBuffer) : data)
  } catch (error) {
    console.error('Error while parsing response buffer: ', error, responseBuffer)
    reject(error)
  }
}

function handleServerError(socket, reject) {
  socket.addEventListener('error', function (event) {
    console.error(`WebSocket error occurred: ${event.type}`)
    console.error(`WebSocket state: ${socket.readyState}`)
    console.error(`WebSocket URL: ${socket.url}`)
    console.error(`WebSocket error message: ${event.message}`)
    reject(event.message)
    socket.close()
  })
}
