import { v4 as uuidv4 } from 'uuid'

import { formatImageListToAssets, formatTextValuesToAssetsCreation } from '../formatters'

import { googleAssetFieldType, googleCallToActionEnums } from '../../../../../constants/ads'

import { CLICK_THROUGH_LINK, ASSET_TYPE, NAME, ASSET_GROUP_TYPE, VIDEO_ADS_URL } from '../../fields'
import { BUSINESS_NAME } from '../../../../ReusableFormFields/LineItemForms/fields'
import { CALL_TO_ACTION } from '../../../../ReusableFormFields/CallToActionFields/fields'
import { ASSET_IMAGE_LIST } from './fields'
import { landscapeRatio, portraitRatio } from '../../validations'
import { getAssetsByRatio } from '../../../UploadGoogleAdCreative/UploadGoogleAdCreativeForm/imageAssetsListValidationHelper'
import { PRODUCT_DATA } from '../../../../ReusableFormFields/ProductFields/fields'
import { BRANDS } from '../../../../../pages/Settings/AdvertiserAccounts/ClientAccountForms/ClientAccountWithMarginsCreate/ClientAccountWithMarginsCreateForm/fields'

export const transformValuesToAssetsCreation = ({ values, adAccountId, selectedSelfAccountData }) => {
  const assets = []

  // list of AssetFieldType for corresponding field_type
  // headlines HEADLINE 2
  // descriptions DESCRIPTION 3
  // long headline LONG_HEADLINE 17
  // business name BUSINESS_NAME 18
  // landscape image MARKETING_IMAGE 5
  // square image SQUARE_MARKETING_IMAGE 19
  // portrait image PORTRAIT_MARKETING_IMAGE 20
  // square logo LOGO 21
  // landscape logo LANDSCAPE_LOGO 22
  // youtube links YOUTUBE_VIDEO 7
  // call to action CALL_TO_ACTION_SELECTION 25

  const textAssets = formatTextValuesToAssetsCreation({
    values,
    adAccountId,
    allowIncludeYoutubeLink: values[VIDEO_ADS_URL][0].text
  })
  assets.push(...textAssets)

  assets.push({
    account: adAccountId,
    // if self account has brand names - user can select one of them,
    // otherwise we automatically use self account name as business name
    text_asset: { text: values[BUSINESS_NAME] || selectedSelfAccountData?.name },
    [ASSET_GROUP_TYPE]: googleAssetFieldType.BUSINESS_NAME
  })
  assets.push({
    account: adAccountId,
    call_to_action_asset: { call_to_action: googleCallToActionEnums[values[CALL_TO_ACTION]] },
    [ASSET_GROUP_TYPE]: googleAssetFieldType.CALL_TO_ACTION_SELECTION
  })

  if (values[BUSINESS_NAME]) {
    // when there is selected business name it probably was selected from self account brand names
    // brand name has 2 additional fields: square_logo and landscape_logo which should be used in assets creation

    const selectedBrandData = selectedSelfAccountData[BRANDS]?.find(
      ({ brand_name }) => brand_name === values[BUSINESS_NAME]
    )
    const squareLogoAssetData = selectedBrandData?.square_logo

    if (selectedBrandData && squareLogoAssetData) {
      assets.push({
        account: adAccountId,
        name: `${selectedBrandData.brand_name}-square-logo`,
        image_asset: {
          // S3 logo url
          data: squareLogoAssetData
        },
        [ASSET_GROUP_TYPE]: googleAssetFieldType.LOGO
      })
    }
  }

  return assets
}

export const transformValuesToBE = ({ values, assetsData, submittedAssetsList }) => {
  const assets = []

  assetsData.operations.forEach((asset, index) => {
    const assetTypeLabel = submittedAssetsList[index][ASSET_GROUP_TYPE]

    assets.push({
      asset: asset.resource_name,
      // asset type for AssetsGroup should be selected from submittedAssetsList as GOOGLE replace the name to it`s
      // own after assets creation, so population should be taken from the payload data(submittedAssetsList) not the
      // response data(assetsData)
      [ASSET_TYPE]: assetTypeLabel,
      // hardcode status for 2 - ENABLED(AssetLinkStatus)
      status: 2
    })
  })

  const imageAssets = values[ASSET_IMAGE_LIST]
  const filledImageAssets = imageAssets?.filter(({ file_url }) => file_url)

  const landscapeImageAssets = getAssetsByRatio(filledImageAssets, landscapeRatio)
  const squareImageAssets = getAssetsByRatio(filledImageAssets, 1)
  const portraitImageAssets = getAssetsByRatio(filledImageAssets, portraitRatio)

  assets.push(...formatImageListToAssets(landscapeImageAssets, googleAssetFieldType.MARKETING_IMAGE))
  assets.push(...formatImageListToAssets(squareImageAssets, googleAssetFieldType.SQUARE_MARKETING_IMAGE))
  assets.push(...formatImageListToAssets(portraitImageAssets, googleAssetFieldType.PORTRAIT_MARKETING_IMAGE))

  const selectedProduct = values[PRODUCT_DATA]

  return {
    // when create internal asset group, ad unique id to the name so it doesn't fail during approval process
    [NAME]: `${selectedProduct.name} - ${uuidv4().slice(0, 7)}`,
    // in our create form we have just 1 link, but link should be an array:
    [CLICK_THROUGH_LINK]: [values[CLICK_THROUGH_LINK]],
    assets
  }
}
