import { delay, call, put, all, takeEvery, select } from 'redux-saga/effects'
import { t } from 'i18next'
import * as Sentry from '@sentry/react'

import {
  GET_CURRENT_USER_PROFILE,
  getCurrentUserProfileSuccess,
  getCurrentUserProfileFailure,
  UPDATE_CURRENT_USER_PROFILE,
  updateCurrentUserProfileSuccess,
  updateCurrentUserProfileFailure,
  VERIFY_CURRENT_USER,
  verifyCurrentUserSuccess,
  verifyCurrentUserFailure,
  SET_MFA_TYPE,
  setMFATypeSuccess,
  setMFATypeFailure,
  GET_BANNERS,
  getBannersSuccess,
  getBannersFailure,
  getAsyncSessionSuccess,
  getAsyncSessionFailure,
  GET_ASYNC_SESSION,
  getAsyncSession,
  SUBMIT_SERVER_LOGOUT,
  submitServerLogoutSuccess,
  submitServerLogoutFailure,
  GET_USER_INVITES,
  getUserInvitesSuccess,
  getUserInvitesFailure,
  GET_CONTROLLER_LANDING_PAGE_SETUP,
  getControllerLandingPageSetupSuccess,
  getControllerLandingPageSetupFailure
} from '../actions/app'

import {
  getCurrentUserService,
  updateCurrentUserService,
  verifyCurrentUserService,
  setMFATypeService,
  bannersService,
  getAsyncSessionService,
  submitServerLogoutService,
  getUserInvitesService,
  getControllerLandingPageSetupService
} from '../services/app'
import { showToasts } from '../../helpers/toasts'
import { TOAST_TYPE } from '../../constants/other'
import { currentUserIdSelector } from '../selectors/app'
import { getErrorMessage } from '../../helpers/errors'
import { handleLogout } from '../../helpers/auth'

function* appWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(SUBMIT_SERVER_LOGOUT, submitServerLogoutWorker),
    takeEvery(GET_CURRENT_USER_PROFILE, getCurrentUserWorker),
    takeEvery(GET_USER_INVITES, getUserInvitesWorker),
    takeEvery(UPDATE_CURRENT_USER_PROFILE, updateCurrentUserWorker),
    takeEvery(VERIFY_CURRENT_USER, verifyCurrentUserWorker),
    takeEvery(SET_MFA_TYPE, setMFATypeWorker),
    takeEvery(GET_BANNERS, bannersWorker),
    takeEvery(GET_CONTROLLER_LANDING_PAGE_SETUP, getControllerLandingPageSetupWorker),
    takeEvery(GET_ASYNC_SESSION, getAsyncSessionWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getCurrentUserWorker() {
  try {
    const response = yield call(getCurrentUserService)
    const userId = response?.id
    Sentry.setUser({ id: userId })

    yield put(getCurrentUserProfileSuccess(response))
  } catch (e) {
    yield put(getCurrentUserProfileFailure(e))
    showToasts({
      type: TOAST_TYPE.error,
      message: 'Sorry, there has been an error loading the app. Please refresh the page.'
    })
    // logout user if getting user info failed, in other case user stuck
    handleLogout()
  }
}

function* getUserInvitesWorker({ params }) {
  try {
    const response = yield call(getUserInvitesService, params)
    yield put(getUserInvitesSuccess(response))
  } catch (error) {
    yield put(getUserInvitesFailure(error))
  }
}

function* submitServerLogoutWorker({ data }) {
  try {
    const response = yield call(submitServerLogoutService, data)
    yield put(submitServerLogoutSuccess(response))
  } catch (error) {
    yield put(submitServerLogoutFailure(error))
  }
}

function* updateCurrentUserWorker({ data }) {
  try {
    const { userId, userData, partialUpdate } = data
    const response = yield call(updateCurrentUserService, userId, userData)

    if (partialUpdate) {
      let responsePartial = {}
      Object.keys(userData).forEach(key => (responsePartial[key] = response[key]))
      yield put(updateCurrentUserProfileSuccess(responsePartial))
    } else {
      yield put(updateCurrentUserProfileSuccess(response))
    }
  } catch (e) {
    yield put(updateCurrentUserProfileFailure(e))
  }
}

function* verifyCurrentUserWorker({ data }) {
  try {
    const id = yield select(currentUserIdSelector)

    const response = yield call(verifyCurrentUserService, id, data)
    if (response?.data?.verified) {
      yield put(verifyCurrentUserSuccess(response))
    } else {
      // once verification failed, it doesn't response as 400, but 200 with error message, so we need to handle it
      // show error message from response
      showToasts({
        type: TOAST_TYPE.error,
        message: response?.data?.error
      })

      // dispatch failure action
      yield put(
        verifyCurrentUserFailure({
          ...response?.data,
          // code is added as key for parsing errors inside the ProgressButton
          code: 'verification_failed'
        })
      )
    }
  } catch (error) {
    yield put(verifyCurrentUserFailure(error))
  }
}

function* setMFATypeWorker({ data }) {
  try {
    yield call(setMFATypeService, data)

    // response doesn't return any data, so we just update user with new mfa type directly
    yield put(setMFATypeSuccess({ is_mfa_enabled: data.mfa }))
  } catch (error) {
    const errorMessage = error && getErrorMessage(error)

    showToasts({
      type: TOAST_TYPE.error,
      message: errorMessage || t('mfaSettingError', { mfa: data.mfa })
    })
    yield put(setMFATypeFailure(error))
  }
}

function* bannersWorker() {
  try {
    const bannersData = yield call(bannersService)
    yield put(getBannersSuccess(bannersData))
  } catch (e) {
    yield put(getBannersFailure(e))
  }
}

function* getControllerLandingPageSetupWorker({ params }) {
  try {
    const response = yield call(getControllerLandingPageSetupService, params)
    yield put(getControllerLandingPageSetupSuccess(response))
  } catch (error) {
    yield put(getControllerLandingPageSetupFailure(error))
  }
}

function* getAsyncSessionWorker({ sessionId, platform, params }) {
  try {
    const sessionData = yield call(getAsyncSessionService, sessionId, platform, params)

    if (sessionData.status === 'COMPLETED') {
      yield put(getAsyncSessionSuccess(sessionData))
    } else if (sessionData.status === 'FAILED') {
      const error = sessionData.result.error
      showToasts({
        type: TOAST_TYPE.error,
        message: error.error_user_msg
      })
      yield put(getAsyncSessionFailure(error, sessionData))
    } else {
      // SHOP-956 periodically check the endpoint until we get status "COMPLETED" or "FAILED"
      yield delay(3000)
      yield put(getAsyncSession(sessionId, platform, params))
    }
  } catch (e) {
    yield put(getAsyncSessionFailure(e))
  }
}

export default appWatcher
