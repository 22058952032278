import * as Yup from 'yup'

import { PRODUCT_DATA, PRODUCT_ID } from '../../../../../../ReusableFormFields/ProductFields/fields'

export const initialValues = {
  [PRODUCT_ID]: '',
  [PRODUCT_DATA]: {}
}

export const validationSchema = Yup.object({
  [PRODUCT_ID]: Yup.string().required('Please select product')
})

export const transformValuesToBE = (values, audienceName, adAccountId, firstPixelId) => ({
  account: adAccountId,
  name: audienceName,
  rule: {
    inclusions: {
      operator: 'or',
      rules: [
        {
          event_sources: [{ type: 'pixel', id: firstPixelId }],
          retention_seconds: 2592000,
          filter: {
            operator: 'and',
            filters: [{ field: 'url', value: values[PRODUCT_DATA]['product_item_number'], operator: 'i_contains' }]
          }
        }
      ]
    }
  }
})
