import React from 'react'
import { components } from 'react-select'
import { wrapMenuList } from 'react-select-async-paginate'

import SelectMenuListReusable from '../../Select/SelectMenuList/SelectMenuListReusable'

// Covering the case of the custom MenuList according to the library documentation:
// Usage of replacing components is similar with react-select, but there is one difference. If you redefine MenuList you should wrap it with wrapMenuList for workaround of some internal bugs of react-select.
const PaginatedMenuListWrapped = wrapMenuList(components.MenuList)
const PaginatedMenuList = props => {
  return (
    <PaginatedMenuListWrapped {...props}>
      <SelectMenuListReusable {...props} />
    </PaginatedMenuListWrapped>
  )
}

export default PaginatedMenuList
