import React from 'react'
import { components } from 'react-select'

import useStyles from './styles'

export default function HighlightedSelectSingleValue(props) {
  const classes = useStyles({
    bigIcon: props.selectProps.bigIcon,
    isLabelBrandPrimary: props.selectProps.isSelectedValueBrandPrimary
  })
  const Icon = props.data.icon

  return (
    <components.SingleValue {...props}>
      <div className={classes.value}>
        {Icon && (
          <div className={classes.iconContainer}>
            <Icon />
          </div>
        )}
        <div className={classes.info}>
          <div className={classes.label}>{props.data.label}</div>
        </div>
      </div>
    </components.SingleValue>
  )
}
