import React, { useCallback, useState } from 'react'
import { getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import FilesRequirementsSection from './FilesRequirementsSection'
import ActionText from '../../../../../components/ActionText'

import {
  fileRequirementsInitialValue,
  FILES_REQUIREMENTS
} from '../../MediaProductCreate/MediaProductCreateForm/fields'

const FilesRequirementsContent = ({ formik }) => {
  const { t } = useTranslation()
  // const [showErrors, setShowErrors] = useState(false)
  const [selected, setSelected] = useState(0)
  const { values, setFieldValue } = formik

  const carouselItems = getIn(values, FILES_REQUIREMENTS)

  const addNewFileButtonText = values[FILES_REQUIREMENTS].length ? '+ Add another file' : '+ Add a file'

  const handleAddAnotherItem = useCallback(() => {
    // add new initial file requirement to carousel items array
    const newFileRequirement = { ...fileRequirementsInitialValue, internalId: uuidv4() }
    const currentRequirements = values[FILES_REQUIREMENTS]

    // open new item in carousel
    setSelected(currentRequirements.length)
    setFieldValue(FILES_REQUIREMENTS, [...currentRequirements, newFileRequirement])

    // set a showErrors value to false for ensure next card requirements does not display validation errors
    // before clicking on continue button
    // setShowErrors(false)
  }, [setFieldValue, values])

  const handleDeleteItem = useCallback(
    index => {
      // delete item from carousel items array
      const newCarouselItems = [...carouselItems]
      newCarouselItems.splice(index, 1)

      // open previous item in carousel
      setSelected(newCarouselItems.length - 1)
      setFieldValue(FILES_REQUIREMENTS, newCarouselItems)
    },
    [carouselItems, setFieldValue]
  )

  return (
    <>
      {carouselItems.map((item, index) => {
        return (
          <FilesRequirementsSection
            key={item.internalId || item.id}
            formik={formik}
            itemPath={`${FILES_REQUIREMENTS}[${index}]`}
            selected={selected === index}
            onSelect={() => setSelected(index)}
            onClose={() => setSelected(null)}
            onDelete={() => handleDeleteItem(index)}
            index={index}
          />
        )
      })}
      <ActionText onClick={handleAddAnotherItem} isDark>
        {t(addNewFileButtonText)}
      </ActionText>
    </>
  )
}

export default FilesRequirementsContent
