import { concat } from '../../helpers/common'

const MODULE_NAME = 'MEDIA_ORDER_PRODUCTS/'

// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_PRODUCTS_TAGS = concat(MODULE_NAME, 'GET_PRODUCTS_TAGS')
export const GET_PRODUCTS_TAGS_SUCCESS = concat(MODULE_NAME, 'GET_PRODUCTS_TAGS_SUCCESS')
export const GET_PRODUCTS_TAGS_FAILURE = concat(MODULE_NAME, 'GET_PRODUCTS_TAGS_FAILURE')
export const CLEAR_PRODUCTS_TAGS = concat(MODULE_NAME, 'CLEAR_PRODUCTS_TAGS')

export const GET_PRODUCTS = concat(MODULE_NAME, 'GET_PRODUCTS')
export const GET_PRODUCTS_SUCCESS = concat(MODULE_NAME, 'GET_PRODUCTS_SUCCESS')
export const GET_PRODUCTS_FAILURE = concat(MODULE_NAME, 'GET_PRODUCTS_FAILURE')
export const CLEAR_PRODUCTS = concat(MODULE_NAME, 'CLEAR_PRODUCTS')

export const UPDATE_PRODUCT_GRAPH_DATA_IN_PRODUCTS_LIST = concat(
  MODULE_NAME,
  'UPDATE_PRODUCT_GRAPH_DATA_IN_PRODUCTS_LIST'
)

export const GET_PRODUCT = concat(MODULE_NAME, 'GET_PRODUCT')
export const GET_PRODUCT_SUCCESS = concat(MODULE_NAME, 'GET_PRODUCT_SUCCESS')
export const GET_PRODUCT_FAILURE = concat(MODULE_NAME, 'GET_PRODUCT_FAILURE')
export const CLEAR_PRODUCT = concat(MODULE_NAME, 'CLEAR_PRODUCT')

export const SET_GRAPH_DATES_PERIOD = concat(MODULE_NAME, 'SET_GRAPH_DATES_PERIOD')
export const CLEAR_GRAPH_DATES_PERIOD = concat(MODULE_NAME, 'CLEAR_GRAPH_DATES_PERIOD')

export const GET_PRODUCT_GRAPH = concat(MODULE_NAME, 'GET_PRODUCT_GRAPH')
export const GET_PRODUCT_GRAPH_SUCCESS = concat(MODULE_NAME, 'GET_PRODUCT_GRAPH_SUCCESS')
export const GET_PRODUCT_GRAPH_FAILURE = concat(MODULE_NAME, 'GET_PRODUCT_GRAPH_FAILURE')
export const CLEAR_GET_PRODUCT_GRAPH = concat(MODULE_NAME, 'CLEAR_GET_PRODUCT_GRAPH')

export const GET_PRODUCTS_GRAPH = concat(MODULE_NAME, 'GET_PRODUCTS_GRAPH')
export const GET_PRODUCTS_GRAPH_SUCCESS = concat(MODULE_NAME, 'GET_PRODUCTS_GRAPH_SUCCESS')
export const GET_PRODUCTS_GRAPH_FAILURE = concat(MODULE_NAME, 'GET_PRODUCTS_GRAPH_FAILURE')
export const CLEAR_PRODUCTS_GRAPH = concat(MODULE_NAME, 'CLEAR_PRODUCTS_GRAPH')

export const GET_UTILISATION_REPORT = concat(MODULE_NAME, 'GET_UTILISATION_REPORT')
export const GET_UTILISATION_REPORT_SUCCESS = concat(MODULE_NAME, 'GET_UTILISATION_REPORT_SUCCESS')
export const GET_UTILISATION_REPORT_FAILURE = concat(MODULE_NAME, 'GET_UTILISATION_REPORT_FAILURE')
export const CLEAR_GET_UTILISATION_REPORT = concat(MODULE_NAME, 'CLEAR_GET_UTILISATION_REPORT')

export const CREATE_PRODUCT = concat(MODULE_NAME, 'CREATE_PRODUCT')
export const CREATE_PRODUCT_SUCCESS = concat(MODULE_NAME, 'CREATE_PRODUCT_SUCCESS')
export const CREATE_PRODUCT_FAILURE = concat(MODULE_NAME, 'CREATE_PRODUCT_FAILURE')
export const CLEAR_CREATE_PRODUCT = concat(MODULE_NAME, 'CLEAR_CREATE_PRODUCT')

export const UPDATE_PRODUCT = concat(MODULE_NAME, 'UPDATE_PRODUCT')
export const UPDATE_PRODUCT_SUCCESS = concat(MODULE_NAME, 'UPDATE_PRODUCT_SUCCESS')
export const UPDATE_PRODUCT_FAILURE = concat(MODULE_NAME, 'UPDATE_PRODUCT_FAILURE')
export const CLEAR_UPDATE_PRODUCT = concat(MODULE_NAME, 'CLEAR_UPDATE_PRODUCT')

export const DELETE_PRODUCT = concat(MODULE_NAME, 'DELETE_PRODUCT')
export const DELETE_PRODUCT_SUCCESS = concat(MODULE_NAME, 'DELETE_PRODUCT_SUCCESS')
export const DELETE_PRODUCT_FAILURE = concat(MODULE_NAME, 'DELETE_PRODUCT_FAILURE')
export const CLEAR_DELETE_PRODUCT = concat(MODULE_NAME, 'CLEAR_DELETE_PRODUCT')

export const GET_INVENTORY = concat(MODULE_NAME, 'GET_INVENTORY')
export const GET_INVENTORY_SUCCESS = concat(MODULE_NAME, 'GET_INVENTORY_SUCCESS')
export const GET_INVENTORY_FAILURE = concat(MODULE_NAME, 'GET_INVENTORY_FAILURE')
export const CLEAR_INVENTORY = concat(MODULE_NAME, 'CLEAR_INVENTORY')

export const UPDATE_INVENTORY = concat(MODULE_NAME, 'UPDATE_INVENTORY')
export const UPDATE_INVENTORY_SUCCESS = concat(MODULE_NAME, 'UPDATE_INVENTORY_SUCCESS')
export const UPDATE_INVENTORY_FAILURE = concat(MODULE_NAME, 'UPDATE_INVENTORY_FAILURE')
export const CLEAR_UPDATE_INVENTORY = concat(MODULE_NAME, 'CLEAR_UPDATE_INVENTORY')

export const CREATE_INVENTORY = concat(MODULE_NAME, 'CREATE_INVENTORY')
export const CREATE_INVENTORY_SUCCESS = concat(MODULE_NAME, 'CREATE_INVENTORY_SUCCESS')
export const CREATE_INVENTORY_FAILURE = concat(MODULE_NAME, 'CREATE_INVENTORY_FAILURE')
export const CLEAR_CREATE_INVENTORY = concat(MODULE_NAME, 'CLEAR_CREATE_INVENTORY')

export const UPDATE_BATCH_INVENTORY = concat(MODULE_NAME, 'UPDATE_BATCH_INVENTORY')
export const UPDATE_BATCH_INVENTORY_SUCCESS = concat(MODULE_NAME, 'UPDATE_BATCH_INVENTORY_SUCCESS')
export const UPDATE_BATCH_INVENTORY_FAILURE = concat(MODULE_NAME, 'UPDATE_BATCH_INVENTORY_FAILURE')
export const CLEAR_UPDATE_BATCH_INVENTORY = concat(MODULE_NAME, 'CLEAR_UPDATE_BATCH_INVENTORY')

export const DELETE_INVENTORY = concat(MODULE_NAME, 'DELETE_INVENTORY')
export const DELETE_INVENTORY_SUCCESS = concat(MODULE_NAME, 'DELETE_INVENTORY_SUCCESS')
export const DELETE_INVENTORY_FAILURE = concat(MODULE_NAME, 'DELETE_INVENTORY_FAILURE')
export const CLEAR_DELETE_INVENTORY = concat(MODULE_NAME, 'CLEAR_DELETE_INVENTORY')

export const GET_INVENTORY_GROUP = concat(MODULE_NAME, 'GET_INVENTORY_GROUP')
export const GET_INVENTORY_GROUP_SUCCESS = concat(MODULE_NAME, 'GET_INVENTORY_GROUP_SUCCESS')
export const GET_INVENTORY_GROUP_FAILURE = concat(MODULE_NAME, 'GET_INVENTORY_GROUP_FAILURE')
export const CLEAR_GET_INVENTORY_GROUP = concat(MODULE_NAME, 'CLEAR_GET_INVENTORY_GROUP')

export const UPDATE_PRODUCTS_ORDER = concat(MODULE_NAME, 'UPDATE_PRODUCTS_ORDER')
export const UPDATE_PRODUCTS_ORDER_SUCCESS = concat(MODULE_NAME, 'UPDATE_PRODUCTS_ORDER_SUCCESS')
export const UPDATE_PRODUCTS_ORDER_FAILURE = concat(MODULE_NAME, 'UPDATE_PRODUCTS_ORDER_FAILURE')
export const CLEAR_UPDATE_PRODUCTS_ORDER = concat(MODULE_NAME, 'CLEAR_UPDATE_PRODUCTS_ORDER')

export const GET_INVENTORY_LAST_ITEM = concat(MODULE_NAME, 'GET_INVENTORY_LAST_ITEM')
export const GET_INVENTORY_LAST_ITEM_SUCCESS = concat(MODULE_NAME, 'GET_INVENTORY_LAST_ITEM_SUCCESS')
export const GET_INVENTORY_LAST_ITEM_FAILURE = concat(MODULE_NAME, 'GET_INVENTORY_LAST_ITEM_FAILURE')
export const CLEAR_GET_INVENTORY_LAST_ITEM = concat(MODULE_NAME, 'CLEAR_GET_INVENTORY_LAST_ITEM')

export const GET_INVENTORY_REPORT = concat(MODULE_NAME, 'GET_INVENTORY_REPORT')
export const GET_INVENTORY_REPORT_SUCCESS = concat(MODULE_NAME, 'GET_INVENTORY_REPORT_SUCCESS')
export const GET_INVENTORY_REPORT_FAILURE = concat(MODULE_NAME, 'GET_INVENTORY_REPORT_FAILURE')
export const CLEAR_GET_INVENTORY_REPORT = concat(MODULE_NAME, 'CLEAR_GET_INVENTORY_REPORT')

// get media products as csv
export const GET_MEDIA_PRODUCTS_AS_CSV = concat(MODULE_NAME, 'GET_MEDIA_PRODUCTS_AS_CSV')
export const GET_MEDIA_PRODUCTS_AS_CSV_SUCCESS = concat(MODULE_NAME, 'GET_MEDIA_PRODUCTS_AS_CSV_SUCCESS')
export const GET_MEDIA_PRODUCTS_AS_CSV_FAILURE = concat(MODULE_NAME, 'GET_MEDIA_PRODUCTS_AS_CSV_FAILURE')
export const CLEAR_GET_MEDIA_PRODUCTS_AS_CSV = concat(MODULE_NAME, 'CLEAR_GET_MEDIA_PRODUCTS_AS_CSV')

export const GET_MEDIA_SUB_CATEGORIES = concat(MODULE_NAME, 'GET_MEDIA_SUB_CATEGORIES')
export const GET_MEDIA_SUB_CATEGORIES_SUCCESS = concat(MODULE_NAME, 'GET_MEDIA_SUB_CATEGORIES_SUCCESS')
export const GET_MEDIA_SUB_CATEGORIES_FAILURE = concat(MODULE_NAME, 'GET_MEDIA_SUB_CATEGORIES_FAILURE')
export const CLEAR_GET_MEDIA_SUB_CATEGORIES = concat(MODULE_NAME, 'CLEAR_GET_MEDIA_SUB_CATEGORIES')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

export function getProductsTags(params) {
  return { type: GET_PRODUCTS_TAGS, params }
}

export function getProductsTagsSuccess(data) {
  return { type: GET_PRODUCTS_TAGS_SUCCESS, data }
}

export function getProductsTagsFailure(error) {
  return { type: GET_PRODUCTS_TAGS_FAILURE, error }
}

export function clearProductsTags() {
  return { type: CLEAR_PRODUCTS_TAGS }
}

// get products
export function getProducts(
  params,
  loadOptions = {
    shouldClearExistingState: false
  }
) {
  return { type: GET_PRODUCTS, params, loadOptions }
}

export function getProductsSuccess(productsData) {
  return { type: GET_PRODUCTS_SUCCESS, productsData }
}

export function getProductsFailure(error) {
  return { type: GET_PRODUCTS_FAILURE, error }
}

export function clearProducts() {
  return { type: CLEAR_PRODUCTS }
}

export function updateProductGraphDataInProductsList(productId, data) {
  return { type: UPDATE_PRODUCT_GRAPH_DATA_IN_PRODUCTS_LIST, productId, data }
}

// get product
export function getProduct(params) {
  return { type: GET_PRODUCT, params }
}

export function getProductSuccess(productData) {
  return { type: GET_PRODUCT_SUCCESS, productData }
}

export function getProductFailure(error) {
  return { type: GET_PRODUCT_FAILURE, error }
}

export function clearProduct() {
  return { type: CLEAR_PRODUCT }
}

// product graph filters
export function setGraphDatesPeriod(datesPeriod) {
  return { type: SET_GRAPH_DATES_PERIOD, datesPeriod }
}

export function clearGraphDatesPeriod() {
  return { type: CLEAR_GRAPH_DATES_PERIOD }
}

// Get product graph
export function getProductGraph(id, params) {
  return { type: GET_PRODUCT_GRAPH, id, params }
}

export function getProductGraphSuccess(data) {
  return { type: GET_PRODUCT_GRAPH_SUCCESS, data }
}

export function getProductGraphFailure(error) {
  return { type: GET_PRODUCT_GRAPH_FAILURE, error }
}

export function clearGetProductGraph() {
  return { type: CLEAR_GET_PRODUCT_GRAPH }
}

// get products graph
export function getProductsGraph(params) {
  return { type: GET_PRODUCTS_GRAPH, params }
}

export function getProductsGraphSuccess(productsGraphData) {
  return { type: GET_PRODUCTS_GRAPH_SUCCESS, productsGraphData }
}

export function getProductsGraphFailure(error) {
  return { type: GET_PRODUCTS_GRAPH_FAILURE, error }
}

export function clearProductsGraph() {
  return { type: CLEAR_PRODUCTS_GRAPH }
}

// Get utilisation report
export function getUtilisationReport(params) {
  return { type: GET_UTILISATION_REPORT, params }
}

export function getUtilisationReportSuccess(data) {
  return { type: GET_UTILISATION_REPORT_SUCCESS, data }
}

export function getUtilisationReportFailure(error) {
  return { type: GET_UTILISATION_REPORT_FAILURE, error }
}

export function clearGetUtilisationReport() {
  return { type: CLEAR_GET_UTILISATION_REPORT }
}

// create product
export function createProduct(productData) {
  return { type: CREATE_PRODUCT, productData }
}

export function createProductSuccess(productData) {
  return { type: CREATE_PRODUCT_SUCCESS, productData }
}

export function createProductFailure(error) {
  return { type: CREATE_PRODUCT_FAILURE, error }
}

export function clearCreateProduct() {
  return { type: CLEAR_CREATE_PRODUCT }
}

// Update product
export function updateProduct(id, data) {
  return { type: UPDATE_PRODUCT, id, data }
}

export function updateProductSuccess(data) {
  return { type: UPDATE_PRODUCT_SUCCESS, data }
}

export function updateProductFailure(error) {
  return { type: UPDATE_PRODUCT_FAILURE, error }
}

export function clearUpdateProduct() {
  return { type: CLEAR_UPDATE_PRODUCT }
}

// Delete product
export function deleteProduct(id) {
  return { type: DELETE_PRODUCT, id }
}

export function deleteProductSuccess(data) {
  return { type: DELETE_PRODUCT_SUCCESS, data }
}

export function deleteProductFailure(error) {
  return { type: DELETE_PRODUCT_FAILURE, error }
}

export function clearDeleteProduct() {
  return { type: CLEAR_DELETE_PRODUCT }
}

// get inventory
export function getInventory(
  params,
  loadOptions = {
    shouldClearExistingState: false
  }
) {
  return { type: GET_INVENTORY, params, loadOptions }
}

export function getInventorySuccess(inventoryData, dateRange) {
  return { type: GET_INVENTORY_SUCCESS, inventoryData, dateRange }
}

export function getInventoryFailure(error, dateRange) {
  return { type: GET_INVENTORY_FAILURE, error, dateRange }
}

export function clearInventory() {
  return { type: CLEAR_INVENTORY }
}

// inventory update
export function updateInventory(id, inventoryData) {
  return { type: UPDATE_INVENTORY, id, inventoryData }
}

export function updateInventorySuccess(inventoryData) {
  return { type: UPDATE_INVENTORY_SUCCESS, inventoryData }
}

export function updateInventoryFailure(error) {
  return { type: UPDATE_INVENTORY_FAILURE, error }
}

export function clearUpdateInventory() {
  return { type: CLEAR_UPDATE_INVENTORY }
}

export function createInventory(inventoryData) {
  return { type: CREATE_INVENTORY, inventoryData }
}

export function createInventorySuccess(inventoryData) {
  return { type: CREATE_INVENTORY_SUCCESS, inventoryData }
}

export function createInventoryFailure(error) {
  return { type: CREATE_INVENTORY_FAILURE, error }
}

export function clearCreateInventory() {
  return { type: CLEAR_CREATE_INVENTORY }
}

// Create batch inventory and after success update graph data for media product in the list
export function updateBatchInventory(data, productId) {
  return { type: UPDATE_BATCH_INVENTORY, data, productId }
}

export function updateBatchInventorySuccess(data) {
  return { type: UPDATE_BATCH_INVENTORY_SUCCESS, data }
}

export function updateBatchInventoryFailure(error) {
  return { type: UPDATE_BATCH_INVENTORY_FAILURE, error }
}

export function clearUpdateBatchInventory() {
  return { type: CLEAR_UPDATE_BATCH_INVENTORY }
}

// Delete inventory
export function deleteInventory(id) {
  return { type: DELETE_INVENTORY, id }
}

export function deleteInventorySuccess(id) {
  return { type: DELETE_INVENTORY_SUCCESS, id }
}

export function deleteInventoryFailure(error) {
  return { type: DELETE_INVENTORY_FAILURE, error }
}

export function clearDeleteInventory() {
  return { type: CLEAR_DELETE_INVENTORY }
}

// Get inventory group
export function getInventoryGroup(params) {
  return { type: GET_INVENTORY_GROUP, params }
}

export function getInventoryGroupSuccess(data) {
  return { type: GET_INVENTORY_GROUP_SUCCESS, data }
}

export function getInventoryGroupFailure(error) {
  return { type: GET_INVENTORY_GROUP_FAILURE, error }
}

export function clearGetInventoryGroup() {
  return { type: CLEAR_GET_INVENTORY_GROUP }
}

// Update products order
export function updateProductsOrder(data) {
  return { type: UPDATE_PRODUCTS_ORDER, data }
}

export function updateProductsOrderSuccess(data) {
  return { type: UPDATE_PRODUCTS_ORDER_SUCCESS, data }
}

export function updateProductsOrderFailure(error) {
  return { type: UPDATE_PRODUCTS_ORDER_FAILURE, error }
}

export function clearUpdateProductsOrder() {
  return { type: CLEAR_UPDATE_PRODUCTS_ORDER }
}

// Get inventory last item
export function getInventoryLastItem(params) {
  return { type: GET_INVENTORY_LAST_ITEM, params }
}

export function getInventoryLastItemSuccess(data) {
  return { type: GET_INVENTORY_LAST_ITEM_SUCCESS, data }
}

export function getInventoryLastItemFailure(error) {
  return { type: GET_INVENTORY_LAST_ITEM_FAILURE, error }
}

export function clearGetInventoryLastItem() {
  return { type: CLEAR_GET_INVENTORY_LAST_ITEM }
}

// Get inventory report
export function getInventoryReport({
  params,
  loadOptions = {
    shouldClearExistingState: false
  },
  period
}) {
  return { type: GET_INVENTORY_REPORT, params, loadOptions, period }
}

export function getInventoryReportSuccess(data, period) {
  return { type: GET_INVENTORY_REPORT_SUCCESS, data, period }
}

export function getInventoryReportFailure(error, period) {
  return { type: GET_INVENTORY_REPORT_FAILURE, error, period }
}

export function clearGetInventoryReport() {
  return { type: CLEAR_GET_INVENTORY_REPORT }
}

// get media products as csv
export function getMediaProductsAsCsv(params) {
  return { type: GET_MEDIA_PRODUCTS_AS_CSV, params }
}

export function getMediaProductsAsCsvSuccess(data) {
  return { type: GET_MEDIA_PRODUCTS_AS_CSV_SUCCESS, data }
}

export function getMediaProductsAsCsvFailure(error) {
  return { type: GET_MEDIA_PRODUCTS_AS_CSV_FAILURE, error }
}

export function clearGetMediaProductsAsCsv() {
  return { type: CLEAR_GET_MEDIA_PRODUCTS_AS_CSV }
}

// Get media sub categories
export function getMediaSubCategories(params) {
  return { type: GET_MEDIA_SUB_CATEGORIES, params }
}

export function getMediaSubCategoriesSuccess(data) {
  return { type: GET_MEDIA_SUB_CATEGORIES_SUCCESS, data }
}

export function getMediaSubCategoriesFailure(error) {
  return { type: GET_MEDIA_SUB_CATEGORIES_FAILURE, error }
}

export function clearGetMediaSubCategories() {
  return { type: CLEAR_GET_MEDIA_SUB_CATEGORIES }
}
