import { createStyles } from '../../../../styles/helpers'

import { cubicBezier } from '../../../../styles/const/animation'

const useStyles = createStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 32,
    background: 'white',
    borderRadius: theme.bigBorderRadius,
    boxShadow:
      '0px 216px 60px 0px rgba(200, 200, 200, 0.00), 0px 138px 55px 0px rgba(200, 200, 200, 0.01), 0px 78px 47px 0px rgba(200, 200, 200, 0.05), 0px 35px 35px 0px rgba(200, 200, 200, 0.09), 0px 9px 19px 0px rgba(200, 200, 200, 0.10)',
    transition: `transform 0.3s ${cubicBezier}`,
    cursor: ({ isDisabled } = {}) => (isDisabled ? 'mouse' : 'pointer'),
    '&:hover': {
      transform: ({ isDisabled } = {}) => (isDisabled ? 'scale(1)' : 'scale(1.05)')
    }
  }
}))

export default useStyles
