import { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import SkeletonAdMediaThumbnail from '../AdMediaThumbnail/Skeleton'
import FacebookVideoPreview from './FacebookVideoPreview'

import { singleAdThumbnailHeight } from '../AdCardSingleContent/styles'
import { FILE_NAME, FILE_URL, MEDIA_TYPE } from '../../../../../forms/ReusableFormFields/AdFileUpload/fields'
import { VIDEO_PICTURE } from '../../../../../forms/Facebook/AdForms/fields'

import useStyles from './styles'

const FacebookSingleMediaAdThumbnail = ({ viewAdOnFacebook, mediaFile, isInProgress = false }) => {
  const [isMediaLoaded, setIsMediaLoaded] = useState(false)

  const {
    [MEDIA_TYPE]: mediaType = 'image',
    [FILE_NAME]: fileName = '',
    [FILE_URL]: mediaUrl = '',
    [VIDEO_PICTURE]: videoPicture
  } = mediaFile

  const classes = useStyles({
    height: isMediaLoaded ? 'auto' : singleAdThumbnailHeight,
    mediaType
  })

  const showSkeleton = useMemo(
    () => (mediaType === 'image' && !mediaUrl) || (mediaType === 'video' && !videoPicture) || !mediaType,
    [mediaType, mediaUrl, videoPicture]
  )

  const handleMediaSuccessfullyLoaded = useCallback(() => {
    setIsMediaLoaded(true)
  }, [])

  // We need isMediaLoaded to define thumbnail height.
  // When user delete media clear isMediaLoaded
  useEffect(() => {
    if (!mediaUrl) {
      setIsMediaLoaded(false)
    }
  }, [mediaUrl])

  if (showSkeleton) {
    return (
      <SkeletonAdMediaThumbnail
        isInProgress={isInProgress}
        mediaPreviewHeight={singleAdThumbnailHeight}
        fileName={fileName}
        className={classes.adMedia}
      />
    )
  }

  return (
    <div className={classes.adMedia}>
      {mediaType === 'image' ? (
        <img onLoad={handleMediaSuccessfullyLoaded} src={mediaUrl} alt="Media preview" />
      ) : (
        <FacebookVideoPreview
          mediaFile={mediaFile}
          onLoad={handleMediaSuccessfullyLoaded}
          className={classes.videoPreviewContainer}
          viewAdOnFacebook={viewAdOnFacebook}
        />
      )}
    </div>
  )
}

FacebookSingleMediaAdThumbnail.propTypes = {
  viewAdOnFacebook: PropTypes.string,
  mediaFile: PropTypes.shape({
    [MEDIA_TYPE]: PropTypes.string,
    [FILE_NAME]: PropTypes.string,
    [FILE_URL]: PropTypes.string,
    [VIDEO_PICTURE]: PropTypes.string
  }),
  isInProgress: PropTypes.bool
}

export default FacebookSingleMediaAdThumbnail
