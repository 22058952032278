import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import useStyles from './styles'

// the Chip which depends on the theme color
function InfoChip({ text, className, color, isTransparent }) {
  const { t } = useTranslation()

  const classes = useStyles({ color, isTransparent })

  return (
    <div className={classnames(classes.infoChip, className)}>
      <span>{t(text)}</span>
    </div>
  )
}

InfoChip.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  isTransparent: PropTypes.bool
}

export default InfoChip
