import { addDays } from 'date-fns'

export function isTruthy(...params) {
  return params.every(param => !!param)
}

export function isFalsy(...params) {
  return params.every(param => !param)
}

export function isSomeTruthy(...params) {
  return params.some(param => !!param)
}

export function concat(...params) {
  return params.reduce((acc, cur) => {
    return acc + cur
  }, '')
}

export function takeTruth(...params) {
  return params.find(param => !!param)
}

export function join(separator, ...params) {
  return params.join(separator)
}

export function toString(param) {
  return param.toString()
}

export function reduce(array, cb, initialValue) {
  return array.reduce(cb, initialValue)
}

export function find(array, cb) {
  return array.find(cb)
}

export function replace(value, regexp, newValue) {
  return value.replace(regexp, newValue)
}

export function map(array, cb) {
  return array.map(cb)
}

export function filter(array, cb) {
  return array.filter(cb)
}

export function some(array, cb) {
  return array.some(cb)
}

export function toUpper(param) {
  return param.toUpperCase()
}

export function toLower(param) {
  return param.toString().toLowerCase()
}

export function assign(...objects) {
  return reduce(
    objects,
    (acc, cur) => {
      return { ...acc, ...cur }
    },
    {}
  )
}

export function keys(obj) {
  return Object.keys(obj)
}

export function values(obj) {
  return Object.values(obj)
}

export function pickByTruth(object) {
  return reduce(
    keys(object),
    (acc, cur) => {
      return object[cur] ? { ...acc, [cur]: object[cur] } : { ...acc }
    },
    {}
  )
}

export function formatStringWithUnderscore(string) {
  return string.split('_').join(' ')
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function capitalizeWordsExceptArticles(string) {
  return string
    .toLowerCase()
    .split(' ')
    .map((word, index) => {
      if ((word === 'a' || word === 'the' || word === 'an') && index !== 0) {
        return word
      } else {
        return capitalizeFirstLetter(word)
      }
    })
    .join(' ')
}

export function upperCaseSpecificWords(string, wordsArray) {
  // upper case words so user can always provide lowerCased if needed
  const upperCasedWordsArray = wordsArray.map(word => word.toUpperCase())

  return string
    .split(' ')
    .map(word => (upperCasedWordsArray.includes(word.toUpperCase()) ? word.toUpperCase() : word))
    .join(' ')
}

export function isEqual(value, other) {
  const type = Object.prototype.toString.call(value)
  if (type !== Object.prototype.toString.call(other)) return false
  if (['[object String]', '[object Number]', '[object Boolean]'].includes(type)) return value === other
  if (type === '[object Function]') return toString(value) === toString(other)
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false
  const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length
  const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length
  if (valueLen !== otherLen) return false

  const compare = function (item1, item2) {
    const itemType = Object.prototype.toString.call(item1)
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false
    } else {
      if (itemType !== Object.prototype.toString.call(item2)) return false
      if (itemType === '[object Function]') {
        if (toString(item1) !== toString(item2)) return false
      } else {
        if (item1 !== item2) return false
      }
    }
  }
  if (type === '[object Array]') {
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false
    }
  } else {
    for (const key in value) {
      // eslint-disable-next-line
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false
      }
    }
  }
  return true
}

export function getCookie(cname) {
  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return JSON.parse(c.substring(name.length, c.length))
    }
  }
  return ''
}

export function setCookie(name, value, expireDays) {
  let expires = ''
  if (expireDays) {
    const expirationDate = addDays(new Date(), expireDays)
    expires = '; expires=' + new Date(expirationDate).toUTCString()
  }
  document.cookie = name + '=' + (JSON.stringify(value) || '') + expires + '; path=/'
}

export function insertIf(condition, ...elements) {
  return condition ? elements : []
}

export function eraseCookie(name) {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
}

export function capitalizeWords(string = '', skipArticles = true) {
  return string
    .toLowerCase()
    .split(' ')
    .map((word, index) => {
      if (skipArticles && (word === 'a' || word === 'the' || word === 'an') && index !== 0) {
        return word
      } else {
        return capitalizeFirstLetter(word)
      }
    })
    .join(' ')
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value)
}

export function getSumOfNumbersArray(arr) {
  return reduce(arr, (a, b) => a + b, 0)
}

export const filterObjectEmptyValues = object => {
  return Object.keys(object).reduce((acc, key) => {
    // manual checks for undefined, null and empty string, skipping boolean values
    if (object[key] !== undefined && object[key] !== null && object[key] !== '') {
      acc[key] = object[key]
    }
    return acc
  }, {})
}
