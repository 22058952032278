import update from 'immutability-helper'

import { deleteItemById, updateItem } from '../../helpers/modules/reducerHelpers'

import {
  INITIAL_GET_REDUCER,
  INITIAL_GET_RESULTS_REDUCER,
  INITIAL_UPDATE_REDUCER,
  INITIAL_CREATE_REDUCER,
  INITIAL_DELETE_REDUCER,
  INITIAL_GET_RESULTS_PAGING_REDUCER
} from '../../constants/reducer'

import {
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  CLEAR_PRODUCTS,
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  CLEAR_PRODUCT,
  GET_PRODUCTS_GRAPH,
  GET_PRODUCTS_GRAPH_SUCCESS,
  GET_PRODUCTS_GRAPH_FAILURE,
  CLEAR_PRODUCTS_GRAPH,
  GET_INVENTORY,
  GET_INVENTORY_SUCCESS,
  GET_INVENTORY_FAILURE,
  CLEAR_INVENTORY,
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  CLEAR_CREATE_PRODUCT,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  CLEAR_UPDATE_PRODUCT,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  CLEAR_DELETE_PRODUCT,
  CREATE_INVENTORY,
  CREATE_INVENTORY_SUCCESS,
  CREATE_INVENTORY_FAILURE,
  CLEAR_CREATE_INVENTORY,
  UPDATE_INVENTORY,
  UPDATE_INVENTORY_SUCCESS,
  UPDATE_INVENTORY_FAILURE,
  CLEAR_UPDATE_INVENTORY,
  DELETE_INVENTORY,
  DELETE_INVENTORY_SUCCESS,
  DELETE_INVENTORY_FAILURE,
  CLEAR_DELETE_INVENTORY,
  UPDATE_BATCH_INVENTORY,
  UPDATE_BATCH_INVENTORY_SUCCESS,
  UPDATE_BATCH_INVENTORY_FAILURE,
  CLEAR_UPDATE_BATCH_INVENTORY,
  GET_INVENTORY_GROUP,
  GET_INVENTORY_GROUP_SUCCESS,
  GET_INVENTORY_GROUP_FAILURE,
  CLEAR_GET_INVENTORY_GROUP,
  GET_PRODUCTS_TAGS,
  GET_PRODUCTS_TAGS_SUCCESS,
  GET_PRODUCTS_TAGS_FAILURE,
  CLEAR_PRODUCTS_TAGS,
  UPDATE_PRODUCTS_ORDER,
  UPDATE_PRODUCTS_ORDER_SUCCESS,
  CLEAR_UPDATE_PRODUCTS_ORDER,
  UPDATE_PRODUCTS_ORDER_FAILURE,
  UPDATE_PRODUCT_GRAPH_DATA_IN_PRODUCTS_LIST,
  GET_PRODUCT_GRAPH,
  GET_PRODUCT_GRAPH_SUCCESS,
  GET_PRODUCT_GRAPH_FAILURE,
  CLEAR_GET_PRODUCT_GRAPH,
  SET_GRAPH_DATES_PERIOD,
  CLEAR_GRAPH_DATES_PERIOD,
  GET_INVENTORY_LAST_ITEM,
  GET_INVENTORY_LAST_ITEM_SUCCESS,
  GET_INVENTORY_LAST_ITEM_FAILURE,
  CLEAR_GET_INVENTORY_LAST_ITEM,
  GET_MEDIA_PRODUCTS_AS_CSV,
  GET_MEDIA_PRODUCTS_AS_CSV_SUCCESS,
  GET_MEDIA_PRODUCTS_AS_CSV_FAILURE,
  CLEAR_GET_MEDIA_PRODUCTS_AS_CSV,
  GET_INVENTORY_REPORT,
  GET_INVENTORY_REPORT_SUCCESS,
  GET_INVENTORY_REPORT_FAILURE,
  CLEAR_GET_INVENTORY_REPORT,
  GET_MEDIA_SUB_CATEGORIES,
  GET_MEDIA_SUB_CATEGORIES_SUCCESS,
  GET_MEDIA_SUB_CATEGORIES_FAILURE,
  CLEAR_GET_MEDIA_SUB_CATEGORIES,
  GET_UTILISATION_REPORT,
  GET_UTILISATION_REPORT_SUCCESS,
  GET_UTILISATION_REPORT_FAILURE,
  CLEAR_GET_UTILISATION_REPORT
} from '../actions/mediaOrdersProducts'
import { NEXT_6_MONTH } from '../../constants/selectLists/graphDatesList'

const initialInventoryReducer = {
  ...INITIAL_GET_RESULTS_PAGING_REDUCER,
  requestedDateRanges: [],
  fetchedDateRanges: [],
  lastFetchedPortion: INITIAL_GET_RESULTS_REDUCER
}

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  productsTags: INITIAL_GET_RESULTS_REDUCER,
  products: INITIAL_GET_RESULTS_PAGING_REDUCER,
  updateProductsOrder: INITIAL_UPDATE_REDUCER,
  product: INITIAL_GET_REDUCER,
  graphDatesPeriod: NEXT_6_MONTH,
  productGraph: INITIAL_GET_RESULTS_REDUCER,
  productsGraph: INITIAL_GET_RESULTS_REDUCER,
  utilisationReport: INITIAL_GET_RESULTS_REDUCER,
  inventory: initialInventoryReducer,
  inventoryGroup: INITIAL_GET_REDUCER,
  inventoryReport: {
    ...INITIAL_GET_RESULTS_REDUCER,
    loadingPeriods: []
  },
  createProduct: INITIAL_CREATE_REDUCER,
  updateProduct: INITIAL_UPDATE_REDUCER,
  deleteProduct: INITIAL_DELETE_REDUCER,
  createInventory: INITIAL_CREATE_REDUCER,
  updateInventory: INITIAL_UPDATE_REDUCER,
  catalogueProducts: INITIAL_GET_RESULTS_PAGING_REDUCER,
  updateBatchInventory: INITIAL_UPDATE_REDUCER,
  deleteInventory: INITIAL_DELETE_REDUCER,
  getInventoryLastItem: INITIAL_GET_REDUCER,
  mediaProductsAsCsv: INITIAL_GET_REDUCER,
  mediaSubCategories: INITIAL_GET_RESULTS_REDUCER
}

// recalculate product graph data item after changing inventory data
const updateGraphItem = (item, newGraphData) => {
  // find new data by date_start
  const newFullGraphData = newGraphData.find(monthData => monthData.date_start === item.date_start)
  // find new booked_percentage
  const booked_percentage =
    Number(((newFullGraphData?.booked_quantity / newFullGraphData?.quantity) * 100).toFixed(2)) || 0

  // update data if only booked_percentage aren't equal
  if (booked_percentage !== item.booked_percentage) {
    return { ...item, booked_percentage, unsold_value: newFullGraphData.unsold_value }
  }

  return item
}

export default function mediaOrdersProducts(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // Get products tags
    case GET_PRODUCTS_TAGS:
      return update(state, {
        productsTags: { $merge: { isLoading: true } }
      })
    case GET_PRODUCTS_TAGS_SUCCESS:
      return update(state, {
        productsTags: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data
          }
        }
      })
    case GET_PRODUCTS_TAGS_FAILURE:
      return update(state, {
        productsTags: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_PRODUCTS_TAGS:
      return update(state, {
        productsTags: { $set: initialState.productsTags }
      })

    case UPDATE_PRODUCT_GRAPH_DATA_IN_PRODUCTS_LIST:
      const foundProduct = state.products.results.find(product => product.id === action.productId)
      const newGraphData = foundProduct?.graph?.map(monthData => updateGraphItem(monthData, action.data))
      return update(state, {
        products: {
          results: {
            $apply: items => updateItem(items, { ...foundProduct, graph: newGraphData })
          }
        }
      })

    // get products
    case GET_PRODUCTS:
      return update(state, {
        products: {
          $merge: {
            ...(action.loadOptions.shouldClearExistingState && INITIAL_GET_RESULTS_PAGING_REDUCER),
            isLoading: true
          }
        }
      })
    case GET_PRODUCTS_SUCCESS:
      return update(state, {
        products: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: {
              next: action.productsData?.next
            }
          },
          results: {
            $push: action.productsData.results
          }
        }
      })
    case GET_PRODUCTS_FAILURE:
      return update(state, {
        products: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_PRODUCTS:
      return update(state, {
        products: { $set: initialState.products }
      })

    // get product
    case GET_PRODUCT:
      return update(state, {
        product: {
          $merge: { isLoading: true }
        }
      })
    case GET_PRODUCT_SUCCESS:
      return update(state, {
        product: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.productData
          }
        }
      })
    case GET_PRODUCT_FAILURE:
      return update(state, {
        product: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_PRODUCT:
      return update(state, {
        product: { $set: initialState.product }
      })

    // Product graph filter
    case SET_GRAPH_DATES_PERIOD:
      return update(state, {
        graphDatesPeriod: { $set: action.datesPeriod }
      })
    case CLEAR_GRAPH_DATES_PERIOD:
      return update(state, {
        graphDatesPeriod: { $set: initialState.graphDatesPeriod }
      })

    // Get product graph
    case GET_PRODUCT_GRAPH:
      return update(state, {
        productGraph: { $merge: { isLoading: true } }
      })
    case GET_PRODUCT_GRAPH_SUCCESS:
      return update(state, {
        productGraph: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            // the graph data is an array of objects
            results: action.data
          }
        }
      })
    case GET_PRODUCT_GRAPH_FAILURE:
      return update(state, {
        productGraph: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_PRODUCT_GRAPH:
      return update(state, {
        productGraph: { $set: initialState.productGraph }
      })

    // get products graph
    case GET_PRODUCTS_GRAPH:
      return update(state, {
        productsGraph: {
          $merge: { isLoading: true }
        }
      })
    case GET_PRODUCTS_GRAPH_SUCCESS:
      return update(state, {
        productsGraph: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            // the graph data is an array of objects
            results: action.productsGraphData
          }
        }
      })
    case GET_PRODUCTS_GRAPH_FAILURE:
      return update(state, {
        productsGraph: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_PRODUCTS_GRAPH:
      return update(state, {
        productsGraph: { $set: initialState.productsGraph }
      })

    // Get utilisation report
    case GET_UTILISATION_REPORT:
      return update(state, {
        utilisationReport: { $merge: { isLoading: true } }
      })
    case GET_UTILISATION_REPORT_SUCCESS:
      return update(state, {
        utilisationReport: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data
          }
        }
      })
    case GET_UTILISATION_REPORT_FAILURE:
      return update(state, {
        utilisationReport: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_UTILISATION_REPORT:
      return update(state, {
        utilisationReport: { $set: initialState.utilisationReport }
      })

    // create product
    case CREATE_PRODUCT:
      return update(state, {
        createProduct: {
          $merge: { isLoading: true }
        }
      })
    case CREATE_PRODUCT_SUCCESS:
      return update(state, {
        createProduct: {
          $merge: {
            isLoading: false,
            wasCreated: true
          }
        },
        products: {
          results: {
            $push: [action.productData]
          }
        }
      })
    case CREATE_PRODUCT_FAILURE:
      return update(state, {
        createProduct: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_PRODUCT:
      return update(state, {
        createProduct: { $set: initialState.createProduct }
      })

    // Update product
    case UPDATE_PRODUCT:
      return update(state, {
        updateProduct: {
          $merge: {
            id: action.id,
            isLoading: true
          }
        }
      })
    case UPDATE_PRODUCT_SUCCESS:
      return update(state, {
        updateProduct: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            data: action.data
          }
        },

        products: {
          results: {
            $apply: items => updateItem(items, action.data)
          }
        }
      })
    case UPDATE_PRODUCT_FAILURE:
      return update(state, {
        updateProduct: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_PRODUCT:
      return update(state, {
        updateProduct: { $set: initialState.updateProduct }
      })

    // Delete product
    case DELETE_PRODUCT:
      return update(state, {
        deleteProduct: {
          $merge: {
            isLoading: true,
            id: action.id
          }
        }
      })
    case DELETE_PRODUCT_SUCCESS:
      return update(state, {
        deleteProduct: {
          $merge: {
            isLoading: false,
            wasDeleted: true
          }
        },
        products: {
          results: {
            $apply: items => deleteItemById(items, action.data.id)
          }
        }
      })
    case DELETE_PRODUCT_FAILURE:
      return update(state, {
        deleteProduct: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DELETE_PRODUCT:
      return update(state, {
        deleteProduct: { $set: initialState.deleteProduct }
      })

    // get inventory
    case GET_INVENTORY:
      return update(state, {
        inventory: {
          $merge: {
            ...(action.loadOptions.shouldClearExistingState && initialInventoryReducer),
            isLoading: true
          },
          requestedDateRanges: {
            $push: [
              {
                date_from: action.params.date_from,
                date_to: action.params.date_to
              }
            ]
          },
          lastFetchedPortion: {
            $set: {
              isLoading: true,
              wasLoaded: false
            }
          }
        }
      })
    case GET_INVENTORY_SUCCESS:
      return update(state, {
        inventory: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: {
              next: action.inventoryData?.next
            }
          },
          results: {
            $push: action.inventoryData.results
          },
          fetchedDateRanges: {
            $push: [action.dateRange]
          },
          lastFetchedPortion: {
            $set: {
              isLoading: false,
              wasLoaded: true,
              results: action.inventoryData.results
            }
          }
        }
      })
    case GET_INVENTORY_FAILURE:
      return update(state, {
        inventory: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_INVENTORY:
      return update(state, {
        inventory: { $set: initialState.inventory }
      })

    // update inventory
    case UPDATE_INVENTORY:
      return update(state, {
        updateInventory: {
          $merge: { isLoading: true, id: action.id }
        }
      })
    case UPDATE_INVENTORY_SUCCESS:
      return update(state, {
        updateInventory: {
          $merge: {
            isLoading: false,
            wasUpdated: true
          }
        },
        inventory: {
          results: {
            $apply: items => updateItem(items, action.inventoryData)
          }
        }
      })
    case UPDATE_INVENTORY_FAILURE:
      return update(state, {
        updateInventory: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_INVENTORY:
      return update(state, {
        updateInventory: { $set: initialState.updateInventory }
      })

    // create inventory
    case CREATE_INVENTORY:
      return update(state, {
        createInventory: {
          $merge: { isLoading: true }
        }
      })
    case CREATE_INVENTORY_SUCCESS:
      return update(state, {
        createInventory: {
          $merge: {
            isLoading: false,
            wasCreated: true
          }
        },
        inventory: {
          results: {
            $push: [action.inventoryData]
          }
        }
      })
    case CREATE_INVENTORY_FAILURE:
      return update(state, {
        createInventory: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_INVENTORY:
      return update(state, {
        createInventory: { $set: initialState.createInventory }
      })

    // Create batch inventory
    case UPDATE_BATCH_INVENTORY:
      return update(state, {
        updateBatchInventory: { $merge: { isLoading: true } }
      })
    case UPDATE_BATCH_INVENTORY_SUCCESS:
      return update(state, {
        updateBatchInventory: {
          $merge: {
            isLoading: false,
            wasUpdated: true
          }
        },
        inventory: {
          results: {
            $set: action.data
          }
        }
      })
    case UPDATE_BATCH_INVENTORY_FAILURE:
      return update(state, {
        updateBatchInventory: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_BATCH_INVENTORY:
      return update(state, {
        updateBatchInventory: { $set: initialState.updateBatchInventory }
      })

    // Delete inventory
    case DELETE_INVENTORY:
      return update(state, {
        deleteInventory: { $merge: { isLoading: true, id: action.id } }
      })
    case DELETE_INVENTORY_SUCCESS:
      return update(state, {
        deleteInventory: {
          $merge: {
            isLoading: false,
            wasDeleted: true
          }
        },
        inventory: {
          results: {
            $apply: items => deleteItemById(items, action.id)
          }
        }
      })
    case DELETE_INVENTORY_FAILURE:
      return update(state, {
        deleteInventory: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DELETE_INVENTORY:
      return update(state, {
        deleteInventory: { $set: initialState.deleteInventory }
      })

    // Get inventory group
    case GET_INVENTORY_GROUP:
      return update(state, {
        inventoryGroup: { $merge: { isLoading: true } }
      })
    case GET_INVENTORY_GROUP_SUCCESS:
      return update(state, {
        inventoryGroup: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        }
      })
    case GET_INVENTORY_GROUP_FAILURE:
      return update(state, {
        inventoryGroup: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_INVENTORY_GROUP:
      return update(state, {
        inventoryGroup: { $set: initialState.inventoryGroup }
      })

    // Update products order
    case UPDATE_PRODUCTS_ORDER:
      return update(state, {
        updateProductsOrder: { $merge: { isLoading: true } },
        // we re-order data on FE, and just send actual BE re-ordering request on background
        products: {
          $merge: {
            results: action.data
          }
        }
      })
    case UPDATE_PRODUCTS_ORDER_SUCCESS:
      return update(state, {
        updateProductsOrder: {
          $merge: {
            isLoading: false
          }
        }
      })
    case UPDATE_PRODUCTS_ORDER_FAILURE:
      return update(state, {
        updateProductsOrder: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_PRODUCTS_ORDER:
      return update(state, {
        updateProductsOrder: { $set: initialState.updateProductsOrder }
      })

    // Get inventory last item
    case GET_INVENTORY_LAST_ITEM:
      return update(state, {
        getInventoryLastItem: { $merge: { isLoading: true } }
      })
    case GET_INVENTORY_LAST_ITEM_SUCCESS:
      return update(state, {
        getInventoryLastItem: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        }
      })
    case GET_INVENTORY_LAST_ITEM_FAILURE:
      return update(state, {
        getInventoryLastItem: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_INVENTORY_LAST_ITEM:
      return update(state, {
        getInventoryLastItem: { $set: initialState.getInventoryLastItem }
      })
    // Get inventory report
    case GET_INVENTORY_REPORT:
      return update(state, {
        inventoryReport: {
          $merge: {
            ...(action.loadOptions.shouldClearExistingState && INITIAL_GET_RESULTS_REDUCER),
            isLoading: true
          },
          ...(action.period && {
            loadingPeriods: {
              $push: [action.period]
            }
          })
        }
      })
    case GET_INVENTORY_REPORT_SUCCESS:
      return update(state, {
        inventoryReport: {
          $merge: {
            isLoading: false,
            wasLoaded: true
          },
          results: {
            $apply: oldData => {
              // Loop through the new array of data
              return action.data.reduce((updatedData, newItem) => {
                // Find the index of the new item in the old data
                const index = updatedData.findIndex(oldItem => oldItem.location_name === newItem.location_name)

                // If the new item exists in the old data, update it
                // Otherwise, add it to the old data
                return index >= 0
                  ? update(updatedData, {
                      [index]: {
                        $merge: {
                          ...newItem,
                          // push new booked media to the previous
                          booked_media: [...updatedData[index].booked_media, ...newItem.booked_media]
                        }
                      }
                    })
                  : [...updatedData, newItem]
              }, oldData)
            }
          },
          // remove loaded periods:
          ...(action.period && {
            loadingPeriods: {
              $apply: periods => periods.filter(period => period.startDate !== action.period.startDate)
            }
          })
        }
      })
    case GET_INVENTORY_REPORT_FAILURE:
      return update(state, {
        inventoryReport: {
          $merge: {
            isLoading: false,
            error: action.error
          },
          // remove period from loading:
          ...(action.period && {
            loadingPeriods: {
              $apply: periods => periods.filter(period => period.startDate !== action.period.startDate)
            }
          })
        }
      })
    case CLEAR_GET_INVENTORY_REPORT:
      return update(state, {
        inventoryReport: { $set: initialState.inventoryReport }
      })

    // get media products as csv
    case GET_MEDIA_PRODUCTS_AS_CSV:
      return update(state, {
        mediaProductsAsCsv: { $merge: { isLoading: true } }
      })
    case GET_MEDIA_PRODUCTS_AS_CSV_SUCCESS:
      return update(state, {
        mediaProductsAsCsv: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        }
      })
    case GET_MEDIA_PRODUCTS_AS_CSV_FAILURE:
      return update(state, {
        mediaProductsAsCsv: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_MEDIA_PRODUCTS_AS_CSV:
      return update(state, {
        mediaProductsAsCsv: { $set: initialState.mediaProductsAsCsv }
      })

    // Get media sub categories
    case GET_MEDIA_SUB_CATEGORIES:
      return update(state, {
        mediaSubCategories: { $merge: { isLoading: true } }
      })
    case GET_MEDIA_SUB_CATEGORIES_SUCCESS:
      return update(state, {
        mediaSubCategories: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data.results
          }
        }
      })
    case GET_MEDIA_SUB_CATEGORIES_FAILURE:
      return update(state, {
        mediaSubCategories: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_MEDIA_SUB_CATEGORIES:
      return update(state, {
        mediaSubCategories: { $set: initialState.mediaSubCategories }
      })

    default:
      return state
  }
}
