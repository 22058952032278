import { createStyles } from '../../styles/helpers'

import { container } from '../../styles/common'
import { grey } from '../../styles/const/colors'
import { tabletDown } from '../../styles/const/breakpoints'

const useStyles = createStyles({
  main: {
    padding: '40px 48px 64px',
    [`@media screen and (${tabletDown})`]: {
      padding: '24px 24px 64px'
    }
  },
  container: {
    extend: container
  },
  '@global': {
    'p, div': {
      '&.info': {
        color: grey
      }
    }
  }
})

export default useStyles
