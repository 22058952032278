// Generate list with numbers from 1 to 7
const generateSevenDaysList = () => {
  const daysList = []

  for (let i = 1; i < 8; i++) {
    daysList.push({
      value: i,
      label: `${i}`
    })
  }

  return daysList
}

export const sevenDaysList = generateSevenDaysList()
