import React from 'react'
import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

const LoadingOptions = () => {
  const classes = useStyles()

  return (
    <div className={classes.loadingContainer}>
      <Skeleton height={20} count={3} />
    </div>
  )
}

export default LoadingOptions
