import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR
export function getTikTokRegionsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.tikTokRegions,
    // todo remove when is_sandbox is cleared
    skipTikTokIsSandbox: true
  })
}

export async function getTikTokPixelsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.tikTokPixels
  })
}

export async function getTikTokIdentitiesService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.tikTokIdentities,
    // todo remove when is_sandbox is cleared
    skipTikTokIsSandbox: true
  })
}

export function createTikTokIdentityService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.tikTokIdentities,
    // todo remove when is_sandbox is cleared
    skipTikTokIsSandbox: true
  })
}

export function getTikTokInterestsService(params) {
  return madeRequest('GET', {
    url: ENDPOINTS.tikTokInterests,
    params,
    // todo remove when is_sandbox is cleared
    skipTikTokIsSandbox: true
  })
}

export function getTikTokActionCategoriesService(params) {
  return madeRequest('GET', {
    url: ENDPOINTS.tikTokActionCategories,
    params,
    // todo remove when is_sandbox is cleared
    skipTikTokIsSandbox: true
  })
}

export function getTikTokHashtagsService(params) {
  return madeRequest('GET', {
    url: ENDPOINTS.tikTokHashtags,
    params
  })
}

export function getTikTokCustomAudiencesService(params) {
  return madeRequest('GET', {
    url: ENDPOINTS.tikTokCustomAudiences,
    params
  })
}

export function getTikTokVideoService({ id, account }) {
  return madeRequest('GET', {
    params: { account },
    url: `${ENDPOINTS.tikTokVideos}${id}/`
  })
}

export function getTikTokVideoThumbnailsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.tikTokVideoThumbnails
  })
}
