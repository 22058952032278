import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getLocationListsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.locationLists
  })
}

export function getLocationListService(id) {
  return madeRequest('GET', {
    url: `${ENDPOINTS.locationLists}${id}/`
  })
}

export function createLocationListService(locationListData) {
  return madeRequest('POST', {
    data: locationListData,
    url: ENDPOINTS.locationLists
  })
}

export function updateLocationListService(locationListData, id) {
  return madeRequest('PATCH', {
    data: locationListData,
    url: `${ENDPOINTS.locationLists}${id}/`
  })
}

export function deleteLocationListService(id) {
  return madeRequest('DELETE', {
    params: { id },
    url: `${ENDPOINTS.locationLists}${id}/`
  })
}

export function validateLocationAddressesService(locationAddressesData) {
  return madeRequest('POST', {
    data: locationAddressesData,
    url: `${ENDPOINTS.locationAddresses}validate/`
  })
}
