import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'

import StepForm from '../../../../../../../../../features/components/Forms/StepForm'
import BudgetStep from './Steps/BudgetStep'
import BudgetPercentageStep from './Steps/BudgetPercentageStep'
import DatesStep from './Steps/DatesStep'

import { usePurifiedFormik } from '../../../../../../../../../hooks/formHooks/usePurifiedFormik'

import { createLineItem, clearCreateLineItem } from '../../../../../../../../../modules/actions/lineItems'
import { setMediaOrderUploadCreative } from '../../../../../../../../../modules/actions/mediaOrders'
import {
  lineItemCreatedDataSelector,
  lineItemCreateErrorSelector,
  lineItemCreateIsLoadingSelector,
  lineItemWasCreatedSelector
} from '../../../../../../../../../modules/selectors/lineItems'
import {
  mediaOrderSelector,
  mediaOrderUploadCreativeDataSelector
} from '../../../../../../../../../modules/selectors/mediaOrders'
import { choicesPixelsSelector, currentUserCountrySelector } from '../../../../../../../../../modules/selectors/choices'

import { initialValues, validationSchema } from './fields'
import { transformValuesToBE } from './formatters'
import { FACEBOOK_PLATFORM } from '../../../../../../../../../constants/selectLists/platformList'
import { LINE_ITEM_FORM } from '../../../index'

const LineItemFormContent = ({ adAccountId, onSuccessSubmit }) => {
  const dispatch = useDispatch()

  const lineItemFacebookCreatedData = useSelector(lineItemCreatedDataSelector)
  const lineItemWasCreated = useSelector(lineItemWasCreatedSelector)
  const mediaOrder = useSelector(mediaOrderSelector)
  const currentUserCountry = useSelector(currentUserCountrySelector)
  const pixels = useSelector(choicesPixelsSelector)
  const { campaign } = useSelector(mediaOrderUploadCreativeDataSelector)

  const firstPixelId = pixels[0]?.id

  const onSubmit = useCallback(
    values => {
      dispatch(
        createLineItem(
          transformValuesToBE({
            values,
            adAccountId,
            mediaOrder,
            campaign,
            country: currentUserCountry?.['iso2'],
            pixelId: firstPixelId
          }),
          FACEBOOK_PLATFORM
        )
      )
    },
    [dispatch, adAccountId, mediaOrder, campaign, currentUserCountry, firstPixelId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const steps = useMemo(
    () => [
      {
        component: <BudgetStep />,
        show: true
      },
      {
        component: <BudgetPercentageStep />,
        show: true
      },
      {
        component: <DatesStep />,
        show: true
      }
    ],
    []
  )

  const onSuccessLineItemCreateHandler = useCallback(() => {
    dispatch(setMediaOrderUploadCreative({ lineItem: lineItemFacebookCreatedData }))

    onSuccessSubmit()
  }, [dispatch, lineItemFacebookCreatedData, onSuccessSubmit])

  const clearCreateLineItemHandler = useCallback(() => {
    dispatch(clearCreateLineItem())
  }, [dispatch])

  return (
    <StepForm
      steps={steps}
      formik={purifiedFormik}
      formName={LINE_ITEM_FORM}
      submitText="Save and continue"
      onSuccessSubmit={onSuccessLineItemCreateHandler}
      clearSubmitHandler={clearCreateLineItemHandler}
      successSubmit={lineItemWasCreated}
      errorSelector={lineItemCreateErrorSelector}
      isLoadingSelector={lineItemCreateIsLoadingSelector}
    />
  )
}

LineItemFormContent.propTypes = {
  adAccountId: PropTypes.string.isRequired,
  onSuccessSubmit: PropTypes.func.isRequired
}

export default LineItemFormContent
