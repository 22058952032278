import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import DateRange from '../../../../../features/components/DateRange'

import useStyles from '../styles'

const InvoicingDateRangeFilter = ({ dateRangeFilter, setDateRangeFilter }) => {
  const classes = useStyles()

  const onDateRangeChange = useCallback(
    rangeValues => {
      setDateRangeFilter({
        startDate: rangeValues.startDate,
        endDate: rangeValues.endDate
      })
    },
    [setDateRangeFilter]
  )

  return (
    <DateRange
      className={classes.dateRange}
      placeholder="Dates"
      dateRangeData={dateRangeFilter}
      onDateRangeChange={onDateRangeChange}
      isSmall
    />
  )
}

InvoicingDateRangeFilter.propTypes = {
  dateRangeFilter: PropTypes.object.isRequired,
  setDateRangeFilter: PropTypes.func.isRequired
}

export default InvoicingDateRangeFilter
