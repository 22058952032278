import { differenceInDays, format } from 'date-fns'

import { CHIP_COLORS } from '../../../../constants/other'
import { DATES_FORMAT_MONTH_YEAR, DATES_FORMAT_SHORT } from '../../../../constants/dates'
import {
  PERIOD_DAY,
  PERIOD_ONE_MONTH,
  PERIOD_ONE_WEEK,
  PERIOD_TWO_MONTH,
  PERIOD_TWO_WEEKS
} from '../../../../constants/mediaOrders'

import {
  getEndMonthPeriodDate,
  getEndWeekPeriodDate
} from '../../../../forms/Multiplatform/MediaOrderForms/MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/periodHelpers'
import { getApprovalFileStatus } from '../../../../features/components/UploadedFilesField/helpers'

export const getMappedFiles = media => {
  // there are two arrays: media.file_requirements and media.uploaded_files
  // map all required files with uploaded to it files and add uploaded without required

  // return array of objects with required and uploaded files for these
  const files = media.file_requirements?.map(file => ({
    required: { ...file },
    // find uploaded file for this required file
    uploaded: media.uploaded_files.find(f => f.media_product_file === file.id)
  }))

  // add files that were uploaded but not required
  media.uploaded_files.forEach(file => {
    const wasAdded = files.find(f => f.uploaded?.id === file.id)
    if (!wasAdded) {
      files.push({
        required: null,
        uploaded: { ...file }
      })
    }
  })

  return files
}

export const areAllFilesUploaded = mediasList => {
  // Use .every to iterate over every media object in the list
  return mediasList.every(media => {
    // For each media, map the 'file_requirements' array to an array of ids
    let requiredFiles = media.file_requirements.map(file => file.id)

    // Check every required file id
    return requiredFiles.every(fileId =>
      // If some 'uploaded_files' has a 'media_product_file' that matches the current 'fileId', return true
      media.uploaded_files.find(file => file.media_product_file === fileId)
    )
  })
}

export const getFormattedMediaDate = (date, period) => {
  if (period === PERIOD_DAY) {
    return format(new Date(date), DATES_FORMAT_SHORT)
  } else if (period === PERIOD_ONE_WEEK) {
    return format(new Date(date), DATES_FORMAT_SHORT)
  } else if (period === PERIOD_TWO_WEEKS) {
    const endDate = getEndWeekPeriodDate(date, 2)
    return format(new Date(date), DATES_FORMAT_SHORT) + ' - ' + format(new Date(endDate), DATES_FORMAT_SHORT)
  } else if (period === PERIOD_ONE_MONTH) {
    return format(new Date(date), DATES_FORMAT_MONTH_YEAR)
  } else if (period === PERIOD_TWO_MONTH) {
    const endDate = getEndMonthPeriodDate(date, 1)

    return format(new Date(date), DATES_FORMAT_MONTH_YEAR) + ' - ' + format(new Date(endDate), DATES_FORMAT_MONTH_YEAR)
  }
}

const getRequiredFileStatus = (deadlineDate, isDeadlineInFuture) => {
  if (isDeadlineInFuture) {
    return {
      color: CHIP_COLORS.yellow,
      text: 'Due by ' + format(deadlineDate, DATES_FORMAT_SHORT)
    }
  } else {
    return {
      color: CHIP_COLORS.red,
      text: 'Overdue by ' + differenceInDays(new Date(), deadlineDate) + ' days'
    }
  }
}

export const getFileStatus = (uploadedFile, deadlineDate, isDeadlineInFuture) => {
  if (uploadedFile) {
    return getApprovalFileStatus(uploadedFile.approval_status)
  } else {
    return getRequiredFileStatus(deadlineDate, isDeadlineInFuture)
  }
}

export const getRowIdByMediaIdAndRequiredFileId = (mediaId, requirementFileId) => {
  return `${mediaId}-${requirementFileId}`
}
