import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles({
  tableTitle: {
    marginBottom: 14
  },
  invitesTableTitle: {
    margin: '14px 0'
  }
})

export default useStyles
