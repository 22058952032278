import React, { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import MultipleCheckboxesSelect from '../../../../components/MultipleCheckboxesSelect'

import { formatOptionsList } from '../../../formatters'

import { selectedControllerRelatedSelfAccountsSelector } from '../../../../modules/selectors/app'

import useStyles from '../../../../pages/Settings/BookedMediaAndFiles/BookedMediaAndFilesFilters/styles'

const MultipleControllerSelfAccountsFilter = ({ Context }) => {
  const classes = useStyles()

  const selectedControllerRelatedSelfAccounts = useSelector(selectedControllerRelatedSelfAccountsSelector)

  const { multipleAccountFilterOption, setMultipleAccountFilterOption } = useContext(Context)

  const formattedSelfAccounts = useMemo(() => {
    return formatOptionsList({
      list: selectedControllerRelatedSelfAccounts,
      valueName: 'id',
      labelName: 'name'
    })
  }, [selectedControllerRelatedSelfAccounts])

  const onMultipleAccountFilterChange = useCallback(
    data => {
      setMultipleAccountFilterOption(data)
    },
    [setMultipleAccountFilterOption]
  )

  return (
    <MultipleCheckboxesSelect
      options={formattedSelfAccounts}
      value={multipleAccountFilterOption}
      onChange={onMultipleAccountFilterChange}
      className={classes.select}
      placeholder="Accounts"
      multipleOptionsLabel="Accounts"
      isSearchable={true}
    />
  )
}

MultipleControllerSelfAccountsFilter.propTypes = {
  Context: PropTypes.object
}

export default MultipleControllerSelfAccountsFilter
