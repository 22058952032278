import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AddFacebookPageForm from './AddFacebookPageForm'
import FormDrawerWrapper from '../../../../../../features/components/FormDrawerWrapper'

import useManageFormsDrawer from '../../../../../../hooks/formHooks/useManageFormsDrawer'

import { facebookPagesWasLoadedSelector } from '../../../../../../modules/selectors/socialAccounts'
import { clearFacebookPages, getFacebookPages } from '../../../../../../modules/actions/socialAccounts'

import { ACCESSIBLE_FACEBOOK_PAGE_CREATE } from '../../../../../../constants/forms'

const AddFacebookPage = () => {
  const dispatch = useDispatch()

  const facebookPagesWasLoaded = useSelector(facebookPagesWasLoadedSelector)

  const { isFormOpen } = useManageFormsDrawer({
    formName: ACCESSIBLE_FACEBOOK_PAGE_CREATE
  })

  useEffect(() => {
    if (isFormOpen && !facebookPagesWasLoaded) {
      dispatch(getFacebookPages())
    }
  }, [dispatch, isFormOpen, facebookPagesWasLoaded])

  useEffect(
    () => () => {
      dispatch(clearFacebookPages())
    },
    [dispatch]
  )

  return (
    <FormDrawerWrapper
      formName={ACCESSIBLE_FACEBOOK_PAGE_CREATE}
      openButtonText="Add a Facebook Page"
      title="Add a Facebook page"
      isFormLoading={!facebookPagesWasLoaded}
    >
      <AddFacebookPageForm />
    </FormDrawerWrapper>
  )
}

export default AddFacebookPage
