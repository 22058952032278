import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ClientAccountEditForm from './ClientAccountEditForm'
import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'

import useManageFormsDrawer from '../../../../../hooks/formHooks/useManageFormsDrawer'
import useManageEditFormData from '../../../../../hooks/formHooks/useManageEditFormData'

import { clearGetSelfAccount, getSelfAccount } from '../../../../../modules/actions/selfAccounts'
import { selfAccountSelector, selfAccountWasLoadedSelector } from '../../../../../modules/selectors/selfAccounts'

import { CLIENT_ACCOUNT_EDIT } from '../../../../../constants/forms'

const ClientAccountEdit = () => {
  const dispatch = useDispatch()

  const selfAccount = useSelector(selfAccountSelector)
  const selfAccountWasLoaded = useSelector(selfAccountWasLoadedSelector)

  const { selectedEditItemId } = useManageFormsDrawer({
    formName: CLIENT_ACCOUNT_EDIT
  })

  const getDataHandler = useCallback(() => {
    dispatch(getSelfAccount({ id: selectedEditItemId }))
  }, [dispatch, selectedEditItemId])

  const clearDataHandler = useCallback(() => {
    dispatch(clearGetSelfAccount())
  }, [dispatch])

  useManageEditFormData({
    formName: CLIENT_ACCOUNT_EDIT,
    loadedDataId: selfAccount.id,
    getDataHandler: getDataHandler,
    clearDataHandler: clearDataHandler
  })

  return (
    <FormDrawerWrapper
      title="Edit client account"
      formName={CLIENT_ACCOUNT_EDIT}
      showOpenButton={false}
      // show loader until account is loaded
      isFormLoading={!selfAccountWasLoaded}
    >
      <ClientAccountEditForm editData={selfAccount} />
    </FormDrawerWrapper>
  )
}

export default ClientAccountEdit
