import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import InfoModal from '../../../features/components/Modals/InfoModal'
import Form from '../../../components/Form'
import Field from '../../../components/Form/Field'

import { ReactComponent as WarningIcon } from '../../../assets/icons/warning-icon.svg'

import { disapproveInternalAd, clearDisapproveInternalAd } from '../../../modules/actions/ads'
import {
  disapproveInternalAdErrorSelector,
  disapproveInternalAdIsLoadingSelector,
  disapproveInternalAdWasDisapprovedSelector
} from '../../../modules/selectors/ads'

import { DISAPPROVE_INTERNAL_AD } from '../../../constants/forms'
import { REASON, initialValues, validationSchema } from './fields'

import useStyles from '../../../styles/common/modalForms'

const DisapproveInternalAdModal = ({ isModalOpened, modalData = {}, onClose }) => {
  const classes = useStyles()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { provider } = modalData

  const adWasDisapproved = useSelector(disapproveInternalAdWasDisapprovedSelector)

  const onSubmit = useCallback(
    values => {
      dispatch(
        disapproveInternalAd(
          modalData.id,
          {
            reason: values[REASON]
          },
          provider
        )
      )
    },
    [dispatch, modalData.id, provider]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const clearDisapproveAdHandler = useCallback(() => {
    dispatch(clearDisapproveInternalAd())
  }, [dispatch])

  return (
    <InfoModal
      isOpen={isModalOpened}
      onClose={onClose}
      Icon={WarningIcon}
      contentClassName={classes.content}
      modalTitle="Disapprove ad"
      ModalMessage={
        <p>
          {t(
            'Please enter a descriptive reason why the ad is being disapproved. This feedback will be shared with the partner.'
          )}
        </p>
      }
    >
      <Form
        formik={formik}
        formName={DISAPPROVE_INTERNAL_AD}
        submitText={t('Disapprove')}
        className={classes.form}
        formFooterClassName={classes.formFooter}
        successSubmit={adWasDisapproved}
        errorSelector={disapproveInternalAdErrorSelector}
        isLoadingSelector={disapproveInternalAdIsLoadingSelector}
        clearHandler={clearDisapproveAdHandler}
        // close modal when ad is disapproved
        onSuccessSubmit={onClose}
      >
        <Field formik={formik} name={REASON} placeholder={t('Reason')} />
      </Form>
    </InfoModal>
  )
}

export default DisapproveInternalAdModal
