import { discountedStyle } from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/MediaOrderPrice/styles'

import { createStyles } from '../../../../../../../styles/helpers'

const useStyles = createStyles({
  feeItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '8px'
  },
  feeCostWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  discountedFeeCost: {
    ...discountedStyle
  },
  feeCost: {
    fontWeight: 600
  }
})

export default useStyles
