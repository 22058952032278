import { FACEBOOK_PLATFORM } from '../../../../constants/selectLists/platformList'
import { OPTION_YES } from '../../../../constants/forms'
import { marginsInitialValues } from '../../../../forms/ReusableFormFields/MarginFields/fields'

export const CAMPAIGN_OPTION = 'campaignOption'
export const ALL_CAMPAIGNS = 'allCampaigns'
export const SPECIFIC_CAMPAIGN = 'specificCampaign'

// SPECIFIC_CAMPAIGN route
export const SPECIFIC_MARGIN_OPTION = 'specificMarginOption'
export const SPECIFIC_CAMPAIGN_PLATFORM = 'specificCampaignPlatform'
export const SPECIFIC_CAMPAIGN_MARGIN = 'specificCampaignMargin'

export const initialValues = {
  // FORMAT
  [CAMPAIGN_OPTION]: ALL_CAMPAIGNS,
  [SPECIFIC_MARGIN_OPTION]: OPTION_YES,
  [SPECIFIC_CAMPAIGN_PLATFORM]: FACEBOOK_PLATFORM,
  [SPECIFIC_CAMPAIGN]: '',
  [SPECIFIC_CAMPAIGN_MARGIN]: '',
  ...marginsInitialValues
}
