import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import MultiFieldItem from '../../../../../../../../../../../../../features/components/Form/MultiFieldItem'

import { PRODUCT_GROUPED_PUBLICATIONS_DATES } from '../../../../../../../../../../fields'

// The grouped periods are used ONLY to display the selected date range in this component
// The selected periods are used to manage the selected dates in the form, send to BE and etc..
const SelectedPeriodsList = React.memo(
  ({ setFieldValue, productItemPath, productPublicationsDatesPath, selectedPeriods, selectedGroupedPeriods }) => {
    const onSelectedValueRemove = useCallback(
      removeValue => {
        // remove single period from array selectedPeriods:
        const newSelectedPeriods = selectedPeriods.filter(item => item.value !== removeValue)
        setFieldValue(productPublicationsDatesPath, newSelectedPeriods)
      },
      [selectedPeriods, setFieldValue, productPublicationsDatesPath]
    )

    const onSelectedGroupValueRemove = useCallback(
      ({ periodGroup, index }) => {
        // remove period group by index from array selectedGroupedPeriods:
        const newGroupsOfPeriods = [...selectedGroupedPeriods]
        newGroupsOfPeriods.splice(index, 1)
        setFieldValue(`${productItemPath}.${PRODUCT_GROUPED_PUBLICATIONS_DATES}`, newGroupsOfPeriods)

        const newSelectedPeriods = selectedPeriods.filter(item => {
          // find and remove all periods from selectedPeriods that are in removed group(several periods)
          return !periodGroup.find(period => period.value === item.value)
        })
        setFieldValue(productPublicationsDatesPath, newSelectedPeriods)
      },
      [selectedPeriods, selectedGroupedPeriods, setFieldValue, productItemPath, productPublicationsDatesPath]
    )

    return (
      <>
        {selectedGroupedPeriods.map((periodGroup, index) => {
          const firstPeriodLabel = periodGroup[0]?.label
          const lastPeriodLabel = periodGroup[periodGroup.length - 1]?.label
          const label = `${firstPeriodLabel} - ${lastPeriodLabel}`

          return (
            <MultiFieldItem
              value={{ periodGroup, index }}
              key={label}
              label={label}
              deleteHandler={onSelectedGroupValueRemove}
            />
          )
        })}
        {!selectedGroupedPeriods.length &&
          selectedPeriods.map(({ value, label }) => (
            <MultiFieldItem value={value} key={value} label={label} deleteHandler={onSelectedValueRemove} />
          ))}
      </>
    )
  }
)

SelectedPeriodsList.propTypes = {
  setFieldValue: PropTypes.func,
  selectedPeriods: PropTypes.array,
  selectedGroupedPeriods: PropTypes.array,
  productItemPath: PropTypes.string,
  productPublicationsDatesPath: PropTypes.string
}
export default SelectedPeriodsList
