import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import ProductForm from './ProductForm'
import AudienceLineItemForm from './LineItemForm'
import AdProductForm from '../../ReusableForms/AdProductForm'

import useContinuousForm from '../../../../../../hooks/formHooks/useContinuousForm'
import { useShowProductOrPageSelectionStep } from '../../../../AdForms/AdFacebookCreate/AdFacebookCreateForm/hooks/useShowProductOrPageSelectionStep'

import { AD_PRODUCT_FORM } from '../ProductRetargetingRoute'

export const PRODUCT_FORM = 'product_form'
export const LINE_ITEM_AUDIENCE_TARGETING_FORM = 'line_item_audience_targeting_form'

const AudienceTargetingRoute = ({
  adAccountId,
  isExistingCampaignFlow,
  onLineItemOptionChange,
  onSuccessAdFormSubmit,
  activatedInternalForm,
  setActivatedInternalForm
}) => {
  const { activatedForm, setActivatedForm } = useContinuousForm({
    initialForm: LINE_ITEM_AUDIENCE_TARGETING_FORM
  })

  const showProductSelectionStep = useShowProductOrPageSelectionStep()

  const handleSetActivatedForm = useCallback(
    formName => {
      setActivatedForm(formName)
      // set internal selected form for progress representation
      setActivatedInternalForm(formName)
    },
    [setActivatedForm, setActivatedInternalForm]
  )

  const lineItemSuccessSubmitHandler = useCallback(() => {
    const formName = showProductSelectionStep ? PRODUCT_FORM : AD_PRODUCT_FORM

    handleSetActivatedForm(formName)
  }, [handleSetActivatedForm, showProductSelectionStep])

  useEffect(() => {
    if (!activatedInternalForm) {
      handleSetActivatedForm(LINE_ITEM_AUDIENCE_TARGETING_FORM)
    }
  }, [activatedInternalForm, handleSetActivatedForm])

  switch (activatedForm) {
    case AD_PRODUCT_FORM:
      return (
        <AdProductForm
          onSuccessSubmit={onSuccessAdFormSubmit}
          activeStepNumber={showProductSelectionStep ? 4 : 3}
          customStepsLength={showProductSelectionStep ? 5 : 4}
          adAccountId={adAccountId}
          // When we show product selection step, we take name and link from product, otherwise user can enter it manually
          showNameField={!showProductSelectionStep}
          showCustomClickThroughLink={!showProductSelectionStep}
        />
      )
    case PRODUCT_FORM:
      return (
        <ProductForm
          onSuccessSubmit={() => {
            handleSetActivatedForm(AD_PRODUCT_FORM)
          }}
        />
      )
    case LINE_ITEM_AUDIENCE_TARGETING_FORM:
    default:
      return (
        <AudienceLineItemForm
          adAccountId={adAccountId}
          isExistingCampaignFlow={isExistingCampaignFlow}
          onLineItemOptionChange={onLineItemOptionChange}
          onSuccessSubmit={lineItemSuccessSubmitHandler}
        />
      )
  }
}

AudienceTargetingRoute.propTypes = {
  adAccountId: PropTypes.string.isRequired,
  onSuccessAdFormSubmit: PropTypes.func.isRequired,
  activatedInternalForm: PropTypes.string,
  setActivatedInternalForm: PropTypes.func.isRequired,
  isExistingCampaignFlow: PropTypes.bool,
  onLineItemOptionChange: PropTypes.func.isRequired
}

export default AudienceTargetingRoute
