import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import ProgressButton from '../../../../../components/Form/ProgressButton'

import { downloadFileByBrowser } from '../../../../../helpers/other'
import { formatProductsFiltersToRequestParams } from '../../../../hooks/useLoadFilteredAndPaginatedProducts/helpers'

import { clearGetMediaProductsAsCsv, getMediaProductsAsCsv } from '../../../../../modules/actions/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'
import {
  getMediaProductsAsCsvErrorSelector,
  getMediaProductsAsCsvIsLoadingSelector,
  getMediaProductsAsCsvSelector,
  getMediaProductsAsCsvWasLoadedSelector
} from '../../../../../modules/selectors/mediaOrdersProducts'

import useStyles from './styles'

const ExportProductsAsCsv = ({ filters }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const selfAccountControllerId = useSelector(selectedControllerIdSelector)
  const isLoading = useSelector(getMediaProductsAsCsvIsLoadingSelector)
  const error = useSelector(getMediaProductsAsCsvErrorSelector)
  const wasLoaded = useSelector(getMediaProductsAsCsvWasLoadedSelector)
  const csvData = useSelector(getMediaProductsAsCsvSelector)

  const csvUrl = csvData?.download_url

  const formattedMediaProductsFiltersParams = useMemo(() => {
    return formatProductsFiltersToRequestParams(filters)
  }, [filters])

  const handleOnClick = useCallback(() => {
    dispatch(
      getMediaProductsAsCsv({
        ...formattedMediaProductsFiltersParams,
        controller: selfAccountControllerId
      })
    )
  }, [dispatch, selfAccountControllerId, formattedMediaProductsFiltersParams])

  const handleDownloadCSVFile = useCallback(() => {
    downloadFileByBrowser({
      url: csvUrl,
      fileName: 'media_products',
      fileFormat: 'csv'
    })
  }, [csvUrl])

  const clearHandler = useCallback(() => {
    dispatch(clearGetMediaProductsAsCsv())
  }, [dispatch])

  useEffect(() => {
    // clear data on page close, to cancel download task in case it started
    return clearHandler
  }, [clearHandler])

  return (
    <ProgressButton
      onClick={handleOnClick}
      isFormLoading={isLoading}
      formError={error}
      successSubmit={wasLoaded}
      clearHandler={clearHandler}
      onSuccessSubmit={handleDownloadCSVFile}
      wrapperClassName={classes.exportButtonWrapper}
    >
      {t('Export as CSV')}
    </ProgressButton>
  )
}

export default ExportProductsAsCsv
