import React, { useCallback } from 'react'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import StartDateFields from '../../../../../../ReusableFormFields/StartTimeFields'

import { START_DATE } from '../../../../../../ReusableFormFields/StartTimeFields/fields'

const stepFields = [START_DATE]

const StartDateStep = ({ formik, handleStepChange }) => {
  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  return (
    <Step stepTitle="When would you like the ad group to start?" handleContinue={handleContinue}>
      <StartDateFields formik={formik} showTimePicker={false} />
    </Step>
  )
}

export default StartDateStep
