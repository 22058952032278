import React, { useCallback, useEffect } from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { setSearch, clearSearch } from '../../../../modules/actions/app'

import { ReactComponent as IconClose } from '../../../../assets/icons/close-grey.svg'

import { useDebouncedSearch } from '../../../../hooks/useDebouncedSearch'

import { selectedPlatformSelector } from '../../../../modules/selectors/app'

import useStyles from './styles'
import useInputFieldStyles from '../../../../components/Form/InputField/styles'

function FiltersSearch({ searchId, className }) {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const selectedPlatform = useSelector(selectedPlatformSelector)

  const handleSearchChange = useCallback(
    searchValue => {
      dispatch(setSearch(searchValue, searchId))
    },
    [dispatch, searchId]
  )

  const { searchText, clearSearchValue, handleSearchTextChange } = useDebouncedSearch(handleSearchChange)

  const classes = useStyles()
  const inputClass = useInputFieldStyles({ hasValue: !!searchText })

  const translatedPlaceholder = t('Search')

  const handleOnChange = useCallback(e => handleSearchTextChange(e.target.value), [handleSearchTextChange])

  const onClearSearch = useCallback(() => {
    dispatch(clearSearch())
    clearSearchValue()
  }, [dispatch, clearSearchValue])

  useEffect(
    () => () => {
      clearSearchValue()
    },
    [selectedPlatform, clearSearchValue]
  )

  useEffect(
    () => () => {
      dispatch(clearSearch())
    },
    [dispatch]
  )

  return (
    <div className={classnames(classes.filtersSearch, className)}>
      <input
        id="searchField"
        className={inputClass.input}
        type="text"
        name="search"
        placeholder={translatedPlaceholder}
        value={searchText}
        onChange={handleOnChange}
      />
      <div className={classnames(classes.clear, searchText.length > 0 && classes.clearActive)} onClick={onClearSearch}>
        <IconClose />
      </div>
    </div>
  )
}

export default FiltersSearch
