import React, { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import AllCampaignsMarginStep from '../../../../../../forms/ReusableFormSteps/AllCampaignsMarginStep'
import StepForm from '../../../../../../features/components/Forms/StepForm'
import MarginFeeStep from '../../../../../../forms/ReusableFormFields/MarginFields/MarginFee'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'

import { keys } from '../../../../../../helpers/common'

import { clearCreateMargins, createMargins } from '../../../../../../modules/actions/margins'
import { selectedSelfAccountSelector } from '../../../../../../modules/selectors/app'
import {
  createMarginsErrorSelector,
  createMarginsIsLoadingSelector,
  createMarginsWasCreatedSelector
} from '../../../../../../modules/selectors/margins'

import { initialValues } from '../../fields'
import { validationSchema } from './validation'
import { calculateMargins } from '../../../../../../forms/ReusableFormFields/MarginFields/formatters'

const MarginRoute = ({ formatOption, FormRouteSelector, eventSourceStep, ...formProps }) => {
  const dispatch = useDispatch()

  const selfAccount = useSelector(selectedSelfAccountSelector)
  const marginsWasCreated = useSelector(createMarginsWasCreatedSelector)

  const onSubmit = useCallback(
    values => {
      const calculatedMargins = calculateMargins(values)
      const margins = keys(calculatedMargins).map(platform => ({
        account: selfAccount,
        provider: platform,
        margin: calculatedMargins[platform]
      }))

      dispatch(createMargins(margins))
    },
    [dispatch, selfAccount]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)
  const steps = useMemo(
    () => [
      {
        component: FormRouteSelector,
        show: true
      },
      {
        component: <AllCampaignsMarginStep />,
        show: true
      },
      {
        component: <MarginFeeStep />,
        show: true
      }
    ],
    [FormRouteSelector]
  )

  const clearCreateMarginHandler = () => {
    dispatch(clearCreateMargins())
  }

  return (
    <StepForm
      steps={steps}
      formik={purifiedFormik}
      {...formProps}
      // this allow to clearCreateMargins on error
      clearSubmitHandler={clearCreateMarginHandler}
      successSubmit={marginsWasCreated}
      errorSelector={createMarginsErrorSelector}
      isLoadingSelector={createMarginsIsLoadingSelector}
    />
  )
}

export default MarginRoute
