import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../components/Button'
import Field from '../../../../../components/Form/Field'
import MultiSelectBox from '../../../../../features/components/Form/MultiSelectBox'

import { destinationsList } from '../../../../../constants/selectLists/audienceSelections'

import { DEPARTURE_DESTINATIONS, DESTINATIONS_OPERATOR, ARRIVAL_DESTINATIONS } from '../fields'

import useStyles from '../../../../../styles/common/stepForms'
import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

const FlightRoutesStep = ({ formik, handleStepChange }) => {
  const { t } = useTranslation()

  const classes = useStyles()
  const drawerFormClasses = useDrawerFormStyles()

  const { values, setFieldValue } = formik

  const handleContinue = () => {
    handleStepChange()
  }

  return (
    <>
      <div className={classes.stepSubTitle}>{t('Create an audience')}</div>
      <div className={classes.stepTitle}>{t('Select route(s) to target')}</div>
      <div className={classes.stepBody}>
        <section className={drawerFormClasses.section}>
          <h4 className={drawerFormClasses.sectionTitle}>{t('Departure destinations')}</h4>
          <MultiSelectBox
            placeholder="Departure Destination"
            setFieldValue={setFieldValue}
            value={values[DEPARTURE_DESTINATIONS]}
            name={DEPARTURE_DESTINATIONS}
            options={destinationsList}
          />
          <Field
            placeholder="And / or"
            formik={formik}
            name={DESTINATIONS_OPERATOR}
            options={[
              { value: 'or', label: 'or' },
              { value: 'and', label: 'and' }
            ]}
          />
        </section>
        <section className={drawerFormClasses.section}>
          <h4 className={drawerFormClasses.sectionTitle}>{t('Arrival destinations')}</h4>
          <MultiSelectBox
            placeholder="Arrival Destination"
            setFieldValue={setFieldValue}
            value={values[ARRIVAL_DESTINATIONS]}
            name={ARRIVAL_DESTINATIONS}
            options={destinationsList}
          />
        </section>
      </div>
      <div className={classes.stepFooter}>
        <Button type="button" className="dark" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default FlightRoutesStep
