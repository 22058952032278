import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import classnames from 'classnames'

import Table from '../../../../../components/Table'
import MediaPackageActions from './MediaPackageActions'
import AdditionalStatusCell from './AdditionalStatusCell'
import ThumbnailCell from '../../../../../components/Table/ReusableCells/Thumbnail'
import Toggle from '../../../../../components/Toggle'
import { ReactComponent as DragableDots } from '../../../../../assets/icons/draggable-dots.svg'

import { updateMediaPackage } from '../../../../../modules/actions/mediaPackages'
import {
  deleteMediaPackageIdSelector,
  updateMediaPackageIdSelector,
  updateMediaPackageWasUpdatedSelector
} from '../../../../../modules/selectors/mediaPackages'

import { formatDateShort } from '../../../../../constants/dates'
import { tableColumnsSize } from '../index'

import {
  PACKAGE_PENDING_APPROVAL,
  STATUS_ACTIVE,
  STATUS_PAUSED
} from '../../../../../forms/Multiplatform/MediaPackageForms/MediaPackageCreate/MediaPackageCreateForm/fields'

import { phonesDownSize } from '../../../../../styles/const/breakpoints'
import useStyles from './styles'
import useCommonStyles from '../../../../../styles/common/table'

const MediaPackagesTableData = ({ packages, onOrderChange }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const updateMediaPackageId = useSelector(updateMediaPackageIdSelector)
  const deleteMediaPackageId = useSelector(deleteMediaPackageIdSelector)

  const updateMediaPackageWasUpdated = useSelector(updateMediaPackageWasUpdatedSelector)

  // we use this state to not show skeleton when we update package status
  const [isStatusUpdateRunning, setIsStatusUpdateRunning] = useState(false)

  const updatePackageStatusHandler = useCallback(
    packageData => {
      const { id, status } = packageData

      const isActive = status === STATUS_ACTIVE

      setIsStatusUpdateRunning(true)
      dispatch(
        updateMediaPackage(id, {
          status: isActive ? STATUS_PAUSED : STATUS_ACTIVE
        })
      )
    },
    [dispatch, setIsStatusUpdateRunning]
  )

  const packagesColumns = useMemo(
    () => [
      {
        Cell: () => <DragableDots />,
        style: { maxWidth: tableColumnsSize.dragBtn }
      },
      {
        header: 'Active',
        Cell: packageData => {
          const isApproved = packageData.status !== PACKAGE_PENDING_APPROVAL

          if (isApproved) {
            return (
              <Toggle
                onToggle={() => updatePackageStatusHandler(packageData)}
                isInitiallyEnabled={packageData.status === STATUS_ACTIVE}
              />
            )
          } else {
            // don't show status toggle if package is not approved
            return null
          }
        },
        style: { maxWidth: tableColumnsSize.statusToggle },
        showOnMobile: false
      },
      {
        Cell: ({ image }) => image && <ThumbnailCell imageUrl={image} />,
        style: { maxWidth: tableColumnsSize.thumbnail }
      },
      {
        header: 'Package Name',
        Cell: ({ name, date_start, date_end }) => (
          <div className={classes.nameMobileFieldWrapper}>
            <div className={classnames(commonClasses.mainText, classes.nameField)}>{name}</div>
            {date_start && date_end && (
              <div className={commonClasses.infoText}>{`${formatDateShort(date_start)} - ${formatDateShort(
                date_end
              )}`}</div>
            )}
          </div>
        )
      },
      {
        header: 'Category',
        Cell: ({ category }) => category?.name,
        style: { maxWidth: tableColumnsSize.type },
        showOnMobile: false
      },
      {
        Cell: AdditionalStatusCell,
        style: { maxWidth: tableColumnsSize.status },
        showOnMobile: false
      },
      {
        Cell: data => <MediaPackageActions itemData={data} />,
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [commonClasses, updatePackageStatusHandler, isMobile, classes]
  )

  // We don't need to show skeleton when update status.
  useEffect(() => {
    if (isStatusUpdateRunning && updateMediaPackageWasUpdated) {
      setIsStatusUpdateRunning(false)
    }
  }, [isStatusUpdateRunning, updateMediaPackageWasUpdated])

  // We don't need to show skeleton when update status.
  const updateItemId = isStatusUpdateRunning ? null : updateMediaPackageId

  return (
    <Table
      data={packages}
      cols={packagesColumns}
      onRowDrag={onOrderChange}
      itemUpdatingId={updateItemId || deleteMediaPackageId}
      tableId="MediaPackagesTableData"
      hideFooterRow
    />
  )
}

MediaPackagesTableData.propTypes = {
  packages: PropTypes.array.isRequired
}

export default MediaPackagesTableData
