import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import DrawerHeadline from '../../../../../components/Drawer/DrawerHeadline'
import Form from '../../../../../components/Form'
import CampaignFormContent from './CampaignFormContent'
import RoutesMissingWarning from '../../../../Multiplatform/RoutesMissingWarning'

import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'
import useCampaignFormSuccessSubmit from '../../../../Multiplatform/MediaOrderForms/UploadAdCreative/useCampaignFormSuccessSubmit'
import { useMediaOrderRemainingBudgetCalculation } from '../../../../Multiplatform/MediaOrderForms/UploadAdCreative/useMediaOrderRemainingBudgetCalculation'
import { formatOptionsList } from '../../../../../features/formatters'
import useAccessibleCampaignsRoutes from '../../../../Multiplatform/RoutesMissingWarning/useAccessibleCampaignsRoutes'

import { setMediaOrderUploadCreative } from '../../../../../modules/actions/mediaOrders'
import { clearCampaigns, clearCreateCampaign, createCampaign } from '../../../../../modules/actions/campaigns'
import {
  campaignCreateErrorSelector,
  campaignCreateIsLoadingSelector
} from '../../../../../modules/selectors/campaigns'
import { selectedAdAccountsSelector, selectedControllerDataSelector } from '../../../../../modules/selectors/app'
import { mediaOrderSelector } from '../../../../../modules/selectors/mediaOrders'
import { combinedCampaignsSelector } from '../../../../../modules/selectors/combinedData'

import { DV_360_PLATFORM, DV_360_PROVIDER_PLATFORM } from '../../../../../constants/selectLists/platformList'
import {
  CAMPAIGN,
  CAMPAIGN_OPTION,
  CAMPAIGN_OPTION_EXISTING_CAMPAIGN
} from '../../../../Multiplatform/UploadAdCreative/fields'

import { getInitialValues, getValidationSchema, transformValuesToBE } from './fields'

import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

const CampaignForm = ({ onSuccessSubmit }) => {
  const drawerFormClasses = useDrawerFormStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [manualSuccessSubmit, setManualSuccessSubmit] = useState(false)

  const mediaOrder = useSelector(mediaOrderSelector)
  const combinedCampaigns = useSelector(combinedCampaignsSelector)
  const controller = useSelector(selectedControllerDataSelector)

  const { campaigns = [] } = combinedCampaigns

  const remainingBudget = useMediaOrderRemainingBudgetCalculation()

  const dv360Campaigns = useMemo(
    () => campaigns.filter(campaign => campaign.provider === DV_360_PROVIDER_PLATFORM),
    [campaigns]
  )

  const isCampaignsExist = !!dv360Campaigns.length

  const allowedRoute = useAccessibleCampaignsRoutes(DV_360_PLATFORM)
  const hasAccessibleRoutes = Object.values(allowedRoute).some(route => route)
  const hasRoutes = isCampaignsExist || hasAccessibleRoutes

  const formattedCampaigns = formatOptionsList({
    list: dv360Campaigns,
    valueName: 'id',
    labelName: 'name'
  })

  const { [DV_360_PLATFORM]: adAccountData } = useSelector(selectedAdAccountsSelector)

  const { currency_symbol: currencySymbol, external_id: dv360AdAccountId } = adAccountData

  const initialValues = useMemo(() => {
    return getInitialValues({ isCampaignsExist })
  }, [isCampaignsExist])

  const validationSchema = useMemo(() => {
    return getValidationSchema({ remainingBudget, currencySymbol })
  }, [remainingBudget, currencySymbol])

  const onSubmit = useCallback(
    values => {
      if (values[CAMPAIGN_OPTION] === CAMPAIGN_OPTION_EXISTING_CAMPAIGN) {
        const selectedCampaignFullData = formattedCampaigns.find(campaign => values[CAMPAIGN] === campaign.id)

        dispatch(setMediaOrderUploadCreative({ campaign: selectedCampaignFullData }))
        setManualSuccessSubmit(true)
      } else {
        const campaignData = transformValuesToBE({
          values,
          mediaOrder,
          account: dv360AdAccountId
        })

        dispatch(
          createCampaign({ campaignData }, DV_360_PLATFORM, {
            pushToCombinedCampaignsList: true
          })
        )
      }
    },
    [dispatch, dv360AdAccountId, formattedCampaigns, mediaOrder]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)
  const { [CAMPAIGN_OPTION]: campaignOption } = purifiedFormik.values

  const successSubmit = useCampaignFormSuccessSubmit({
    campaignOption,
    platform: DV_360_PLATFORM,
    adAccountId: dv360AdAccountId
  })

  const clearHandler = useCallback(() => {
    dispatch(clearCampaigns())
    dispatch(clearCreateCampaign())
  }, [dispatch])

  return hasRoutes ? (
    <div className={drawerFormClasses.formContainer}>
      <DrawerHeadline
        activeStepNumber={0}
        customStepsLength={4}
        title="Choose your programmatic campaign type"
        description={t('learnMoreAboutCampaigns', { controllerName: controller?.name })}
      />
      <Form
        formName="campaignFormContent"
        formik={purifiedFormik}
        submitText="Save and continue"
        successSubmit={successSubmit || manualSuccessSubmit}
        onSuccessSubmit={onSuccessSubmit}
        errorSelector={campaignCreateErrorSelector}
        isLoadingSelector={campaignCreateIsLoadingSelector}
        clearHandler={clearHandler}
      >
        <CampaignFormContent
          formik={purifiedFormik}
          remainingBudget={remainingBudget}
          formattedCampaigns={formattedCampaigns}
        />
      </Form>
    </div>
  ) : (
    <RoutesMissingWarning />
  )
}

export default CampaignForm
