import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import ProductsAndPagesFields from '../../../ReusableFormFields/ProductsAndPagesFields'

import {
  BRAND_CATEGORY_PAGE_DATA,
  BRAND_CATEGORY_PAGE_ID,
  initialBrandCategoryValues,
  initialProductValues,
  PRODUCT_OPTION,
  PRODUCT_OPTION_BRAND_CATEGORY_PAGES,
  PRODUCT_OPTION_PRODUCT_PAGES
} from '../../../ReusableFormFields/ProductsAndPagesFields/fields'
import { PRODUCT_DATA, PRODUCT_ID } from '../../../ReusableFormFields/ProductFields/fields'

const ProductOrPageSelector = ({ formik, onProductOrPageDataSelect }) => {
  const onBrandCategoryPageSelectHandler = useCallback(
    data => {
      const updatedValues = {
        ...initialProductValues,
        [PRODUCT_OPTION]: PRODUCT_OPTION_BRAND_CATEGORY_PAGES,
        [BRAND_CATEGORY_PAGE_ID]: data.id,
        [BRAND_CATEGORY_PAGE_DATA]: data
      }
      onProductOrPageDataSelect && onProductOrPageDataSelect(updatedValues, data)
    },
    [onProductOrPageDataSelect]
  )

  const onProductPageSelectHandler = useCallback(
    data => {
      const updatedValues = {
        ...initialBrandCategoryValues,
        [PRODUCT_OPTION]: PRODUCT_OPTION_PRODUCT_PAGES,
        [PRODUCT_ID]: data.id,
        [PRODUCT_DATA]: data
      }

      onProductOrPageDataSelect && onProductOrPageDataSelect(updatedValues, data)
    },
    [onProductOrPageDataSelect]
  )

  return (
    <ProductsAndPagesFields
      formik={formik}
      onBrandCategoryPageSelectHandler={onBrandCategoryPageSelectHandler}
      onProductPageSelectHandler={onProductPageSelectHandler}
    />
  )
}

ProductOrPageSelector.propTypes = {
  formik: PropTypes.object.isRequired,
  onProductOrPageDataSelect: PropTypes.func.isRequired
}

export default ProductOrPageSelector
