import React from 'react'
import PropTypes from 'prop-types'

import PageSection from '../../../../../../../features/components/PageSection'
import PageSectionHeader from '../../../../../../../features/components/PageSectionHeader'

import useStyles from './styles'

const GraphTableSection = ({ title, titleIsLoading, children }) => {
  const classes = useStyles()

  return (
    <>
      {title && <PageSectionHeader id="rights-report-header" title={title} titleIsLoading={titleIsLoading} />}
      <PageSection className={classes.graphTableSection}>{children}</PageSection>
    </>
  )
}

GraphTableSection.propTypes = {
  title: PropTypes.string,
  titleIsLoading: PropTypes.bool
}

export default GraphTableSection
