import * as Yup from 'yup'
import { spreadSheetInitialData } from '../../../../components/SpreadSheet'
import locationListTypes from '../../../../constants/selectLists/locationListTypes'
import { formatRawDataForSpreadSheet } from '../../../../features/formatters'

export const VALIDATION_ERROR_CODE = 'validation_error'

export const NAME = 'name'
export const LOCATION_LIST_TYPE = 'location_list_type'
export const COUNTRY = 'country'
export const ADDRESSES = 'addresses'

export const getInitialValues = (locationList = {}) => {
  const { name, location_list_type, country, addresses } = locationList

  return {
    [NAME]: name || '',
    [LOCATION_LIST_TYPE]: location_list_type || locationListTypes[0].value,
    [COUNTRY]: country || '',
    [ADDRESSES]: (addresses && formatRawDataForSpreadSheet(addresses, ['name', 'address'])) || spreadSheetInitialData
  }
}

export const validationSchema = Yup.object({
  [NAME]: Yup.string().required('Please enter name').max(255, 'Name should have maximum 255 characters'),
  [LOCATION_LIST_TYPE]: Yup.string().required('Please select type'),
  [COUNTRY]: Yup.string().required('Please select country'),
  [ADDRESSES]: Yup.array()
    .test({
      message: 'The number of addresses must not exceed 500',
      test: addresses =>
        addresses.filter(address => address[0] && address[0].value && address[1] && address[1].value).length <= 500
    })
    .test({
      message: 'Please enter at least one address with name',
      // ensure that we have at least one address entered
      // address can be undefined or object with value as empty string, so we need to check both cases
      test: addresses =>
        !!addresses.find(address => !!(address[0] && address[0].value) && !!(address[1] && address[1].value))
    })
    .test({
      message: 'Please ensure that all rows have name and address entered',
      // ensure that we don't have empty name, but existing address and vice versa
      test: addresses =>
        !addresses.find(
          address =>
            // has empty name, but entered address
            ((!address[0] || (address[0] && !address[0].value)) && address[1] && address[1].value) ||
            // has empty address, but entered name
            ((!address[1] || (address[1] && !address[1].value)) && address[0] && address[0].value)
        )
    })
})

export const transformAddressesValidationFieldsToBE = ({ country, addresses }) => ({
  country,
  // filter empty addresses and create array of values
  addresses: addresses
    .filter(address => !!(address[0] && address[0].value) && !!(address[1] && address[1].value))
    .map(address => address[1].value)
})

export const transformLocationListFieldsToBE = (values, validatedAddresses, selfAccount) => {
  const { addresses, ...purifiedValues } = values

  return {
    addresses: validatedAddresses.map(validatedAddress => {
      const foundedAddress = addresses.find(
        address => address[1] && address[1].value.toLowerCase() === validatedAddress.address.toLowerCase()
      )

      return {
        ...validatedAddress,
        name: foundedAddress && foundedAddress[0].value
      }
    }),
    account: selfAccount,
    ...purifiedValues
  }
}
