import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useClearMediaOrderForm } from '../hooks/useClearMediaOrderForm'

import ProgressButton from '../../../../../components/Form/ProgressButton'

import { clearGenerateMediaOrder, generateMediaOrder } from '../../../../../modules/actions/mediaOrders'
import {
  generateMediaOrderErrorSelector,
  generateMediaOrderIsLoadingSelector,
  mediaOrderWasGeneratedSelector
} from '../../../../../modules/selectors/mediaOrders'

import useStyles from './styles'

const GenerateMediaOrderButton = ({ contractId, tokenParams, disabled, onSuccessSubmit }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  const generateMediaOrderError = useSelector(generateMediaOrderErrorSelector)
  const generateMediaOrderIsLoading = useSelector(generateMediaOrderIsLoadingSelector)
  const mediaOrderWasCreated = useSelector(mediaOrderWasGeneratedSelector)

  const onSubmit = useCallback(() => {
    dispatch(generateMediaOrder(contractId, tokenParams))
  }, [dispatch, contractId, tokenParams])

  const clearCreateMediaOrderHandler = useCallback(() => {
    dispatch(clearGenerateMediaOrder())
  }, [dispatch])

  const handleSubmitFormClear = useClearMediaOrderForm()

  const handleSuccessCreateMediaOrder = useCallback(() => {
    handleSubmitFormClear()
    onSuccessSubmit && onSuccessSubmit()
  }, [handleSubmitFormClear, onSuccessSubmit])

  return (
    <ProgressButton
      onClick={onSubmit}
      disabled={disabled}
      successSubmit={mediaOrderWasCreated}
      isFormLoading={generateMediaOrderIsLoading}
      formError={generateMediaOrderError}
      clearHandler={clearCreateMediaOrderHandler}
      onSuccessSubmit={handleSuccessCreateMediaOrder}
      wrapperClassName={classes.confirmButtonWrapper}
    >
      {t('Confirm and book')}
    </ProgressButton>
  )
}

export default GenerateMediaOrderButton
