import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import AdPreview from '../../../../../../forms/Facebook/AdForms/AdFacebookEdit/AdPreview'

import useElementHeight from '../../../../../../hooks/useElementHeight'

import { transformValuesToFE } from '../../../../../../forms/Facebook/AdForms/AdFacebookEdit/helpers'

import { facebookImageUrlsSelector, facebookVideoUrlsSelector } from '../../../../../../modules/selectors/mediaFiles'

import useStyles from './styles'

function AdCardPreview({ ad, placementPositions, isAdApprovalsStyle }) {
  const videoUrls = useSelector(facebookVideoUrlsSelector)
  const imageUrls = useSelector(facebookImageUrlsSelector)

  const { ref, height } = useElementHeight(ad)

  const classes = useStyles({ previewHeight: height, isAdApprovalsStyle })

  const transformedOriginalValues = useMemo(
    () =>
      transformValuesToFE({
        ad,
        adWasLoaded: true,
        placementPositions,
        imageUrls,
        videoUrls
      }),
    [ad, placementPositions, imageUrls, videoUrls]
  )

  return (
    <div className={classes.adPreviewWrapper}>
      <AdPreview
        className={classes.adPreview}
        ref={ref}
        ad={ad}
        originalValues={transformedOriginalValues}
        placementPositions={placementPositions}
        hideControlsPanel
      />
    </div>
  )
}

export default AdCardPreview

AdCardPreview.propTypes = {
  ad: PropTypes.object.isRequired,
  placementPositions: PropTypes.array,
  isAdApprovalsStyle: PropTypes.bool
}
