import { createStyles } from '../../../styles/helpers'

import { transitionShort } from '../../../styles/const/common'
import { darkGrey } from '../../../styles/const/colors'
import { smallDesktopDown } from '../../../styles/const/breakpoints'
import { dropdownMenuHover } from '../../../styles/common/components'

export default createStyles(theme => ({
  dropdownWrapper: {
    pointerEvents: options => (options ? 'auto' : 'none')
  },
  actionsBtn: {
    border: 0,
    padding: '0 8px',
    fontSize: 0,
    outline: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    visibility: options => (options ? 'initial' : 'hidden'),
    '&:hover circle': {
      fill: theme.brandPrimary
    },
    '& circle': {
      transition: transitionShort
    }
  },
  actionsDropdown: {
    width: 214
  },
  actionsList: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    fontSize: 14,
    lineHeight: 1
  },
  actionsItem: {
    display: 'block',
    color: darkGrey,
    padding: '9px 16px',
    transition: transitionShort,
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      extend: dropdownMenuHover
    }
  },
  actionsLink: {
    textDecoration: 'none',
    color: darkGrey
  },

  [`@media screen and (${smallDesktopDown})`]: {
    actionsBtn: {
      order: 1
    }
  }
}))
