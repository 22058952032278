import { CHIP_COLORS } from '../../../constants/other'

export const PENDING_APPROVAL = 'pending_approval'
export const APPROVED = 'approved'
export const DISAPPROVED = 'disapproved'
export const PENDING_BUYER_APPROVAL = 'pending_buyer_approval'
export const BUYER_DISAPPROVED = 'buyer_disapproved'

export const getStatusForDisapprovalAction = (uploadedFile, isControllerBuyerApproval) => {
  const currentApprovalStatus = uploadedFile.approval_status

  let nextApprovalStatus = DISAPPROVED
  if (
    isControllerBuyerApproval &&
    (currentApprovalStatus === APPROVED || currentApprovalStatus === PENDING_BUYER_APPROVAL)
  ) {
    // if controller is "buyer_approval" and file is "approved" or "pending_buyer_approval"
    // then disapprove makes it "buyer_disapproved"
    nextApprovalStatus = BUYER_DISAPPROVED
  }

  return nextApprovalStatus
}

export const getApprovalActionStatus = (isControllerBuyerApproval, uploadedFile) => {
  return isControllerBuyerApproval ? getApprovalActionStatusForBuyerApproval(uploadedFile) : APPROVED
}

export const getApprovalActionStatusForBuyerApproval = uploadedFile => {
  const currentApprovalStatus = uploadedFile.approval_status

  return currentApprovalStatus === PENDING_APPROVAL || currentApprovalStatus === DISAPPROVED
    ? PENDING_BUYER_APPROVAL
    : APPROVED
}
export const getApprovalFileStatus = (status, defaultColor) => {
  switch (status) {
    case APPROVED: {
      return {
        color: CHIP_COLORS.green,
        text: 'Approved'
      }
    }
    case DISAPPROVED:
      return {
        color: CHIP_COLORS.red,
        text: 'Disapproved'
      }
    case PENDING_BUYER_APPROVAL:
      return {
        color: CHIP_COLORS.yellow,
        text: 'Pending Buyer Approval'
      }
    case BUYER_DISAPPROVED:
      return {
        color: CHIP_COLORS.red,
        text: 'Buyer Disapproved'
      }
    case PENDING_APPROVAL:
    default:
      return {
        color: defaultColor || CHIP_COLORS.grey,
        text: 'Pending Approval'
      }
  }
}
