import { useMediaQuery } from 'react-responsive'
import {
  smallDesktopUpSize,
  smallDesktopDownSize,
  tabletUpSize,
  tabletDownSize,
  phonesUpSize,
  phonesDownSize,
  smallPhonesDownSize,
  smallPhonesUpSize
} from '../../../styles/const/breakpoints'

export const DesktopUp = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: smallDesktopUpSize })
  return isDesktop ? children : null
}
export const DesktopDown = ({ children }) => {
  const isDesktop = useMediaQuery({ maxWidth: smallDesktopDownSize })
  return isDesktop ? children : null
}
export const TabletUp = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: tabletUpSize })
  return isTablet ? children : null
}
export const TabletDown = ({ children }) => {
  const isTablet = useMediaQuery({ maxWidth: tabletDownSize })
  return isTablet ? children : null
}
export const MobileUp = ({ children }) => {
  const isMobile = useMediaQuery({ minWidth: phonesUpSize })
  return isMobile ? children : null
}
export const MobileDown = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })
  return isMobile ? children : null
}
export const SmallMobileUp = ({ children }) => {
  const isMobile = useMediaQuery({ minWidth: smallPhonesUpSize })
  return isMobile ? children : null
}
export const SmallMobileDown = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: smallPhonesDownSize })
  return isMobile ? children : null
}
