import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Button from '../../../../components/Button'

import { openForm } from '../../../../modules/actions/forms'

import { MEDIA_CATEGORY_CREATE } from '../../../../constants/forms'

const CreateCategoryBtn = ({ className }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const openCreateCategoryFormHandler = useCallback(
    () => dispatch(openForm({ formName: MEDIA_CATEGORY_CREATE })),
    [dispatch]
  )

  return (
    <Button className={className} onClick={openCreateCategoryFormHandler} solid>
      {t('Add new category')}
    </Button>
  )
}

CreateCategoryBtn.propTypes = {
  className: PropTypes.string
}

export default CreateCategoryBtn
