import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'

import ButtonIcon from '../../Buttons/ButtonIcon'
import { ReactComponent as RefreshIcon } from '../../../../assets/icons/refresh-icon.svg'

import useStyles from './styles'

const RefreshButton = ({ refreshDataHandler, Context }) => {
  const classes = useStyles()

  const { filterRequestParams } = useContext(Context)

  const clickHandler = useCallback(() => {
    refreshDataHandler(filterRequestParams)
  }, [filterRequestParams, refreshDataHandler])

  return <ButtonIcon Icon={RefreshIcon} className={classes.refreshIcon} hasBorder={true} onClick={clickHandler} />
}

RefreshButton.propTypes = {
  refreshDataHandler: PropTypes.func,
  Context: PropTypes.object
}

export default RefreshButton
