import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TableWrapper from '../../UI/TableWrapper'
import InfoBlock from '../../../../../../../features/components/InfoBlock'
import TableDataLoader from '../../../../../../../components/Table/TableDataLoader'
import BrandCategoryRevenueTable from './BrandCategoryRevenueTable'
import { BrandCategoryRevenueContext } from './BrandCategoryRevenueContext'
import SelfAccountCategoryFilter from '../SelfAccountCategoryFilter'

import {
  brandRevenueReportSelector,
  brandRevenueReportWasLoadedSelector,
  getBrandRevenueReportErrorSelector,
  getBrandRevenueReportIsLoadingSelector
} from '../../../../../../../modules/selectors/mediaOrdersBookings'
import { clearGetBrandAnnualRevenueReport } from '../../../../../../../modules/actions/mediaOrdersBookings'
import { getSelfAccountCategoriesIsLoadingSelector } from '../../../../../../../modules/selectors/selfAccounts'

import useCommonTableStyles from '../../../../../../../styles/common/table'

export const tableColumnsSize = {
  year: 90
}

const BreakdownTable = () => {
  const { t } = useTranslation()
  const commonClasses = useCommonTableStyles()

  const brandRevenueReport = useSelector(brandRevenueReportSelector)

  const brandAnnualRevenueReportError = useSelector(getBrandRevenueReportErrorSelector)
  const getSelfAccountCategoriesIsLoading = useSelector(getSelfAccountCategoriesIsLoadingSelector)

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(clearGetBrandAnnualRevenueReport())
    }
  }, [dispatch])

  const skeletonProps = useMemo(() => {
    return {
      cols: [
        {
          header: 'Name'
        },
        {
          header: 'Year',
          style: { maxWidth: tableColumnsSize.year }
        },
        {
          header: 'Year',
          style: { maxWidth: tableColumnsSize.year }
        }
      ]
    }
  }, [])

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContentInTheMiddleOfTheTable}>
        <div>{t('No revenue')}</div>
        <br />
      </div>
    ),
    [commonClasses.noDataContentInTheMiddleOfTheTable, t]
  )

  if (brandAnnualRevenueReportError) {
    return (
      <InfoBlock title={t('Sorry, something went wrong')} centered greyDescription>
        <div>{t('Please try again later')}</div>
      </InfoBlock>
    )
  }

  return (
    <TableWrapper
      title={t('Brand Category Breakdown')}
      Filters={<SelfAccountCategoryFilter Context={BrandCategoryRevenueContext} />}
    >
      <TableDataLoader
        itemsLength={brandRevenueReport?.length}
        errorSelector={getBrandRevenueReportErrorSelector}
        wasLoadedSelector={brandRevenueReportWasLoadedSelector}
        isLoadingSelector={getBrandRevenueReportIsLoadingSelector}
        skeletonProps={skeletonProps}
        noDataContent={noDataContent}
        additionalDataIsLoading={getSelfAccountCategoriesIsLoading}
      >
        <BrandCategoryRevenueTable />
      </TableDataLoader>
    </TableWrapper>
  )
}

export default BreakdownTable
