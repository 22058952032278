import { updateFileUploadProgress } from '../actions/files'

import { madeRequest } from '../../helpers/api'
import { replaceRouteParams } from '../../helpers/url'
import { simulateFileUploadProgress } from '../../helpers/modules/reducerHelpers'

import { ENDPOINT_PLATFORM, ENDPOINTS } from '../../constants/api'

// PLOP_APPEND_PATTERN_ANCHOR

export async function createAssetService(assetData, platform) {
  let progressInterval = null

  return madeRequest('POST', {
    data: assetData,
    url: replaceRouteParams(ENDPOINTS.assets, {
      platform: ENDPOINT_PLATFORM[platform]
    }),
    // simulate file upload progress:
    onUploadProgress: () => (progressInterval = simulateFileUploadProgress(assetData.name, 500)),
    onDownloadProgress: () => {
      clearInterval(progressInterval)
      updateFileUploadProgress({ [assetData.name]: 99 })
    }
  })
}

export async function getAssetsService(params, platform) {
  return madeRequest('GET', {
    params,
    url: replaceRouteParams(ENDPOINTS.assets, { platform: ENDPOINT_PLATFORM[platform] })
  })
}

export async function createAssetsBatchService(assetsData, platform) {
  return madeRequest('POST', {
    data: assetsData,
    url: replaceRouteParams(ENDPOINTS.assetsBatch, {
      platform: ENDPOINT_PLATFORM[platform]
    })
  })
}

export async function createAssetGroupService(adData, platform) {
  return madeRequest('POST', {
    data: adData,
    url: replaceRouteParams(ENDPOINTS.assetGroups, {
      platform: ENDPOINT_PLATFORM[platform]
    })
  })
}

export async function getAssetGroupsService(params, platform) {
  return madeRequest('GET', {
    params,
    url: replaceRouteParams(ENDPOINTS.assetGroups, {
      platform: ENDPOINT_PLATFORM[platform]
    })
  })
}

export async function getAssetGroupService({ id, ...params }, platform) {
  return madeRequest('GET', {
    params,
    url: replaceRouteParams(ENDPOINTS.assetGroup, {
      id,
      platform: ENDPOINT_PLATFORM[platform]
    })
  })
}

export async function updateAssetGroupService(id, data, platform) {
  return madeRequest('PATCH', {
    data,
    url: replaceRouteParams(ENDPOINTS.assetGroup, {
      id,
      platform: ENDPOINT_PLATFORM[platform]
    })
  })
}

export function approveInternalAssetGroupService(id) {
  return madeRequest('POST', {
    url: `${ENDPOINTS.internalAssetGroups}${id}/approve/`
  })
}

export function disapproveInternalAssetGroupService(id, data) {
  return madeRequest('POST', {
    data,
    url: `${ENDPOINTS.internalAssetGroups}${id}/disapprove/`
  })
}

export function deleteInternalAssetGroupService(id) {
  return madeRequest('DELETE', {
    url: `${ENDPOINTS.internalAssetGroups}${id}/`
  })
}

export async function createAssetGroupSignalService(assetGroupSignalData, platform) {
  return madeRequest('POST', {
    data: assetGroupSignalData,
    url: replaceRouteParams(ENDPOINTS.assetGroupSignals, {
      platform: ENDPOINT_PLATFORM[platform]
    })
  })
}
