import { createStyles } from '../../../../styles/helpers'
import { phonesDown } from '../../../../styles/const/breakpoints'

const useStyles = createStyles({
  cardsList: {
    display: 'grid',
    width: '100%',
    gridGap: 16,
    gridTemplateColumns: 'repeat(auto-fill, [col-start] minmax(280px, 1fr) [col-end])',
    padding: '20px 16px 20px 18px',
    [`@media screen and (${phonesDown})`]: {
      gridTemplateColumns: 'repeat(auto-fill, [col-start] minmax(220px, 1fr) [col-end])'
    }
  }
})

export default useStyles
