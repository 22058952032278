import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import DrawerHeadline from '../../../../components/Drawer/DrawerHeadline'

import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning-icon.svg'

import useStyles from './styles'
import useStepFormStyles from '../../../../styles/common/stepForms'
import useDrawerFormStyles from '../../../../styles/common/drawerForms'

const WarningMessage = ({ title, subTitle, description, sectionClassName, descriptionClassName }) => {
  const classes = useStyles()
  const stepFormClasses = useStepFormStyles()
  const drawerFormClasses = useDrawerFormStyles()

  const { t } = useTranslation()

  return (
    <>
      {title && <DrawerHeadline title={title} subTitle={subTitle} />}
      <div className={stepFormClasses.stepBody}>
        <div className={classnames(drawerFormClasses.section, sectionClassName)}>
          <div className={classes.warningIcon}>
            <WarningIcon />
          </div>
          {description && (
            <p className={classnames(classes.description, descriptionClassName)}>
              {typeof description === 'string' ? t(description) : description}
            </p>
          )}
        </div>
      </div>
    </>
  )
}

WarningMessage.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  sectionClassName: PropTypes.string,
  descriptionClassName: PropTypes.string
}

export default WarningMessage
