import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import EditForm from '../../../../../../../features/components/Forms/EditForm'
import MultipleOptionsField from '../../../../../../ReusableFormFields/MultipleOptionsField'

import { useEditAssetGroupSubmit } from '../../hooks'

import { getDescriptionData } from '../HeadlinesSection/helpers'

import { selectedAdAccountIdSelector } from '../../../../../../../modules/selectors/app'
import { assetsCreateErrorSelector, assetsCreateLoadingSelector } from '../../../../../../../modules/selectors/assets'

import { getInitialValues, validationSchema } from './fields'
import { DESCRIPTIONS_LIST } from '../../../../fields'
import { googleAssetFieldType } from '../../../../../../../constants/ads'

const DescriptionsSectionForm = ({ editItemData, isInternalAssetGroup, ...formProps }) => {
  const { assets, id: assetGroupId, account } = editItemData

  const assetsCreateError = useSelector(assetsCreateErrorSelector)
  const assetsCreateLoading = useSelector(assetsCreateLoadingSelector)

  const selectedAdAccount = useSelector(selectedAdAccountIdSelector)
  const adAccount = account || selectedAdAccount

  const originalDescriptionAssets = useMemo(() => getDescriptionData(assets), [assets])

  const initialValues = useMemo(() => getInitialValues(originalDescriptionAssets), [originalDescriptionAssets])

  const purifiedFormik = useEditAssetGroupSubmit({
    originalSectionAssets: originalDescriptionAssets,
    fieldType: googleAssetFieldType.DESCRIPTION,
    sectionValueKey: DESCRIPTIONS_LIST,
    isInternalAssetGroup,
    assetGroupId,
    initialValues,
    validationSchema,
    adAccount
  })

  return (
    <EditForm
      formik={purifiedFormik}
      initialValues={initialValues}
      requestError={assetsCreateError}
      isLoading={assetsCreateLoading}
      {...formProps}
    >
      <MultipleOptionsField
        fieldPlaceholder="Description"
        listFieldName={DESCRIPTIONS_LIST}
        formik={purifiedFormik}
        minListQuantity={2}
      />
    </EditForm>
  )
}

export default DescriptionsSectionForm
