import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import Select from '../../../../components/Select'

import {
  selectedPlatformSelector,
  selectedAdAccountIdSelector,
  selectedSelfAccountSelector
} from '../../../../modules/selectors/app'

import { findNewLineTo } from './helpers'
import { checkIfParamsRelatedLink } from '../../../../helpers/url'
import history from '../../../../history'

import { ROUTE_PARAMS } from '../../../../constants/routes'

import useStyles from '../styles'

const MobileTabsSelect = ({ visibleContentTabs, subPagePath, defaultTab }) => {
  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)
  const selectedPlatform = useSelector(selectedPlatformSelector)
  const selectedAdAccount = useSelector(selectedAdAccountIdSelector)

  const classes = useStyles()

  const handleChange = useCallback(
    ({ path }) => {
      const isParamsRelatedLink = checkIfParamsRelatedLink(path)
      const populatedParams = {
        [ROUTE_PARAMS.selfAccount]: selectedSelfAccount,
        [ROUTE_PARAMS.platform]: selectedPlatform,
        [ROUTE_PARAMS.adAccount]: selectedAdAccount
      }

      const newLinkTo = findNewLineTo({
        populatedParams,
        isParamsRelatedLink,
        path
      })

      history.push(newLinkTo)
    },
    [selectedAdAccount, selectedPlatform, selectedSelfAccount]
  )

  const mobileTabsOptions = useMemo(() => {
    return visibleContentTabs.map(tab => {
      return {
        ...tab,
        label: tab.mobileTitle,
        value: tab.pathParam
      }
    })
  }, [visibleContentTabs])

  return (
    <Select
      className={classes.mobileTabsSelect}
      options={mobileTabsOptions}
      value={subPagePath || defaultTab.pathParam}
      onChange={handleChange}
      placeholder="Select tab"
    />
  )
}

export default MobileTabsSelect
