import React from 'react'
import Skeleton from 'react-loading-skeleton'

import SkeletonMediaPackageCard from './MediaPackageCard/Skeleton'

import useStyles from './styles'

const SkeletonMediaPackagesSection = () => {
  const classes = useStyles()

  return (
    <section className={classes.section}>
      <div className={classes.content}>
        <div className={classes.headerSection}>
          <h2 className={classes.header}>
            <Skeleton width={220} />
          </h2>
        </div>
        <div className={classes.headerDescription}>
          <Skeleton width={'90%'} height={25} />
        </div>
        <div className={classes.cardsContainer}>
          <SkeletonMediaPackageCard />
          <SkeletonMediaPackageCard />
          <SkeletonMediaPackageCard />
          <SkeletonMediaPackageCard />
        </div>
      </div>
    </section>
  )
}

export default SkeletonMediaPackagesSection
