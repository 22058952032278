import React, { useCallback } from 'react'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import ProductsAndPagesFields from '../../../../../../ReusableFormFields/ProductsAndPagesFields'

import { CLICK_THROUGH_LINK, NAME } from '../../../../fields'
import { PRODUCT_DATA, PRODUCT_ID } from '../../../../../../ReusableFormFields/ProductFields/fields'
import {
  BRAND_CATEGORY_PAGE_DATA,
  BRAND_CATEGORY_PAGE_ID,
  initialBrandCategoryValues,
  initialProductValues,
  PRODUCT_OPTION,
  PRODUCT_OPTION_BRAND_CATEGORY_PAGES,
  PRODUCT_OPTION_PRODUCT_PAGES
} from '../../../../../../ReusableFormFields/ProductsAndPagesFields/fields'

const stepFields = [PRODUCT_ID, BRAND_CATEGORY_PAGE_ID]

const ProductsStep = ({ formik, handleStepChange }) => {
  const { setValues, values } = formik

  const handleContinue = () => {
    handleStepChange(stepFields)
  }

  const onBrandCategoryPageSelectHandler = useCallback(
    data => {
      setValues({
        ...values,
        ...initialProductValues,
        [NAME]: data.name,
        [CLICK_THROUGH_LINK]: data.link,
        [PRODUCT_OPTION]: PRODUCT_OPTION_BRAND_CATEGORY_PAGES,
        [BRAND_CATEGORY_PAGE_ID]: data.id,
        [BRAND_CATEGORY_PAGE_DATA]: data
      })
    },
    [setValues, values]
  )

  const onProductPageSelectHandler = useCallback(
    data => {
      setValues({
        ...values,
        ...initialBrandCategoryValues,
        [CLICK_THROUGH_LINK]: data.link,
        [NAME]: data.name,
        [PRODUCT_OPTION]: PRODUCT_OPTION_PRODUCT_PAGES,
        [PRODUCT_ID]: data.id,
        [PRODUCT_DATA]: data
      })
    },
    [setValues, values]
  )

  return (
    <Step
      stepTitle="Select a product to promote"
      stepDescription="You can add more product ads to this campaign after successful creation"
      handleContinue={handleContinue}
    >
      <ProductsAndPagesFields
        formik={formik}
        onBrandCategoryPageSelectHandler={onBrandCategoryPageSelectHandler}
        onProductPageSelectHandler={onProductPageSelectHandler}
      />
    </Step>
  )
}

export default ProductsStep
