import { createStyles } from '../../../../../../styles/helpers'

const useStyles = createStyles({
  budgetOptionsTabs: {
    marginBottom: 8
  },
  roasField: {
    width: 120
  }
})

export default useStyles
