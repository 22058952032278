import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AddFacebookPage from './FacebookPagesForms/AddFacebookPage'
import ContentRow from '../../../../features/components/ContentSection/ContentRow'
import FacebookPagesTable from './FacebookPagesTable'
import SocialAuthChecker from '../../../../features/components/SocialAuthChecker'
import RequestPageAccess from './FacebookPagesForms/RequestPageAccess'

import { clearAccessibleFacebookPages, getAccessibleFacebookPages } from '../../../../modules/actions/socialAccounts'
import { selectedSelfAccountSelector } from '../../../../modules/selectors/app'

import { FACEBOOK_PLATFORM } from '../../../../constants/selectLists/platformList'

import useStyles from './styles'

const FacebookPages = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const selectedSelfAccountId = useSelector(selectedSelfAccountSelector)

  useEffect(() => {
    // trigger GET on self account change(keep this in the dependency array)
    dispatch(getAccessibleFacebookPages())
  }, [dispatch, selectedSelfAccountId])

  useEffect(() => {
    return () => {
      dispatch(clearAccessibleFacebookPages())
    }
  }, [dispatch])

  return (
    <ContentRow
      title="Facebook pages"
      description="Facebook ads are always linked to a page. Here you can add Facebook pages that users of this account can use to create ads."
      leftColumnChildren={
        <SocialAuthChecker
          platform={FACEBOOK_PLATFORM}
          authFailureText="Please authenticate with Facebook to add a page"
          contentAlign="left"
          hasPadding={false}
        >
          <div className={classes.formsButtons}>
            <AddFacebookPage />
            <RequestPageAccess />
          </div>
        </SocialAuthChecker>
      }
    >
      <FacebookPagesTable />
    </ContentRow>
  )
}

export default FacebookPages
