import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Can from '../../../../features/components/Can'
import GoogleAdsInternalPreviewList from './GoogleAdsInternalPreviewList'
import AdGoogleEdit from '../../../../forms/Google/AdForms/GoogleDisplayAd/AdGoogleEdit'
import AssetGroupGoogleEdit from '../../../../forms/Google/AdForms/GoogleAssetGroup/AssetGroupGoogleEdit'
import DisapproveAssetGroupModal from './DisapproveAssetGroupModal'
import DisapproveInternalAdModal from '../../DisapproveInternalAdModal'

import useModalManage from '../../../../hooks/useModalManage'

import { getAssetGroups, clearAssetGroups, clearApproveInternalAssetGroup } from '../../../../modules/actions/assets'
import { clearAds, clearApproveInternalAd } from '../../../../modules/actions/ads'
import {
  approveInternalAssetGroupErrorSelector,
  approveInternalAssetGroupWasApprovedSelector,
  assetGroupsSelector
} from '../../../../modules/selectors/assets'
import {
  approveInternalAdErrorSelector,
  approveInternalAdWasApprovedSelector,
  getAdsSelector
} from '../../../../modules/selectors/ads'
import { selectedControllerIdSelector, selectedAdAccountIdSelector } from '../../../../modules/selectors/app'

import { GOOGLE_PLATFORM } from '../../../../constants/selectLists/platformList'
import { INTERNAL_ADS_PERMISSION } from '../../../../constants/permissions'

const GoogleInternalAdsSection = () => {
  const dispatch = useDispatch()

  const assetGroups = useSelector(assetGroupsSelector)
  const ads = useSelector(getAdsSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const selectedSelfAccountControllerId = useSelector(selectedControllerIdSelector)
  const assetGroupWasApproved = useSelector(approveInternalAssetGroupWasApprovedSelector)
  const assetGroupApproveError = useSelector(approveInternalAssetGroupErrorSelector)
  const internalAdWasApproved = useSelector(approveInternalAdWasApprovedSelector)
  const internalAdApproveError = useSelector(approveInternalAdErrorSelector)

  // pass only internal asset groups to GoogleAssetGroupsPreviewList for approval
  const internalAssetGroups = assetGroups.filter(({ resource_name }) => !resource_name)
  const internalAds = ads.filter(({ approval_status }) => approval_status === 'pending_approval')

  const {
    isModalOpened: isDisapproveAssetGroupModalOpened,
    openModalHandler: disapproveAssetGroupOpenModalHandler,
    closeModalHandler: disapproveAssetGroupCloseModalHandler,
    modalData: disapproveAssetGroupModalData
  } = useModalManage({})

  const {
    isModalOpened: isDisapproveInternalAdModalOpened,
    openModalHandler: disapproveInternalAdOpenModalHandler,
    closeModalHandler: disapproveInternalAdCloseModalHandler,
    modalData: disapproveInternalAdModalData
  } = useModalManage({})

  useEffect(() => {
    if (assetGroupWasApproved || assetGroupApproveError) {
      dispatch(clearApproveInternalAssetGroup())
    }
  }, [dispatch, assetGroupWasApproved, assetGroupApproveError])

  useEffect(() => {
    if (internalAdWasApproved || internalAdApproveError) {
      dispatch(clearApproveInternalAd())
    }
  }, [dispatch, internalAdApproveError, internalAdWasApproved])

  useEffect(() => {
    dispatch(
      getAssetGroups({
        platform: GOOGLE_PLATFORM,
        controller_id: selectedSelfAccountControllerId,
        internal: true,
        detail: true,
        approval_status: 'pending_approval'
      })
    )
  }, [dispatch, selectedAdAccountId, selectedSelfAccountControllerId])

  useEffect(
    () => () => {
      dispatch(clearAssetGroups())
    },
    [dispatch]
  )

  useEffect(() => {
    return () => {
      dispatch(clearAds())
    }
  }, [dispatch])

  return (
    <>
      <GoogleAdsInternalPreviewList
        assetGroups={internalAssetGroups}
        ads={internalAds}
        noDataText="There are no ads waiting for approval."
        disapproveInternalAdHandler={disapproveInternalAdOpenModalHandler}
        disapproveInternalAssetGroupHandler={disapproveAssetGroupOpenModalHandler}
      />
      <Can I="update" a={INTERNAL_ADS_PERMISSION}>
        <AssetGroupGoogleEdit />
      </Can>
      <Can I="update" a={INTERNAL_ADS_PERMISSION}>
        <AdGoogleEdit />
      </Can>

      <Can I="manage" a={INTERNAL_ADS_PERMISSION}>
        <DisapproveAssetGroupModal
          isModalOpened={isDisapproveAssetGroupModalOpened}
          modalData={disapproveAssetGroupModalData}
          onClose={disapproveAssetGroupCloseModalHandler}
        />
      </Can>
      <Can I="manage" a={INTERNAL_ADS_PERMISSION}>
        <DisapproveInternalAdModal
          isModalOpened={isDisapproveInternalAdModalOpened}
          modalData={disapproveInternalAdModalData}
          onClose={disapproveInternalAdCloseModalHandler}
        />
      </Can>
    </>
  )
}

export default GoogleInternalAdsSection
