export const clearLSEPCharacters = (string = '') => {
  // We need to clear the value in some cases:
  // (e.g. user copy text with special character LSEP from text editor, and paste it to input)

  // - That character is U+2028 Line Separator, which is a kind of newline character.
  // It's not actually supposed to be displayed
  // Think of it as the Unicode equivalent of HTML’s <br>.
  // The problem is that neither the web server nor web browser are interpreting the LS as a newline.

  // - the LSEP character only shows up on Window machines where empty spaces may be present at the end of a line.
  return string.replaceAll(/\u2028/g, ' ')
}
