import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import DatePickerPeriodSelector from './EditPeriods/DatePickerPeriodSelector'
import CustomDatePickerPeriodSelector from './EditPeriods/CustomDatePeriodSelector'

import { SelectPeriodsContextProvider } from '../SelectPeriodsContext'

import { generateGroupLabel } from './helpers'
import { isValidPeriods } from '../helpers/periods'

import { selectedControllerDataSelector } from '../../../../../../../modules/selectors/app'

import { MEDIA_PRODUCTS, PRODUCT_GROUPED_PUBLICATIONS_DATES, PRODUCT_PERIODS_DATES, QUANTITY } from '../../../fields'

import useStyles from './styles'

const DatesCell = ({ formik, productValues, productIndex, isEditMode, checkInventory, isAmendment }) => {
  const classes = useStyles()

  const selectedController = useSelector(selectedControllerDataSelector)
  const supportCustomDates = selectedController?.custom_booking_dates

  const productData = productValues.data
  const isProductSelected = !!productData
  const productPath = `${MEDIA_PRODUCTS}[${productIndex}]`
  const periodsPath = `${productPath}.${PRODUCT_PERIODS_DATES}`
  const selectedQuantity = productValues[QUANTITY]
  const selectedPeriodsGroups = productValues[PRODUCT_GROUPED_PUBLICATIONS_DATES]

  const mediaOrderProductId = productData?.mediaOrderProductId

  if (!isProductSelected) {
    return null
  }

  return (
    <div className={classes.datesCol}>
      {isEditMode ? (
        <SelectPeriodsContextProvider
          productData={productValues.data}
          allowToFetchPreviousPeriods={isAmendment}
          // mediaOrderProductId used only for Amendments to fulfill the inevntory with booked products whihin the order
          mediaOrderProductId={isAmendment ? mediaOrderProductId : null}
        >
          {supportCustomDates ? (
            <CustomDatePickerPeriodSelector
              formik={formik}
              selectedPeriods={productValues[PRODUCT_PERIODS_DATES]}
              productItemPath={productPath}
              productPublicationsDatesPath={periodsPath}
              checkInventory={checkInventory}
            />
          ) : (
            <DatePickerPeriodSelector
              formik={formik}
              selectedPeriods={productValues[PRODUCT_PERIODS_DATES]}
              productItemPath={productPath}
              productPublicationsDatesPath={periodsPath}
              checkInventory={checkInventory}
            />
          )}
        </SelectPeriodsContextProvider>
      ) : selectedPeriodsGroups?.length ? (
        <ul className={classes.selectedDatesList}>
          {selectedPeriodsGroups.map(group => {
            const isAvailable = checkInventory ? isValidPeriods(group, selectedQuantity) : true

            const label = generateGroupLabel(group)

            // the li element is used for selector of copy functionality in formatTableContentToSheet helper
            return (
              <li className={classnames({ [classes.isNotAvailable]: !isAvailable })} key={label}>
                {label}
              </li>
            )
          })}
        </ul>
      ) : (
        '-'
      )}
    </div>
  )
}

DatesCell.propTypes = {
  formik: PropTypes.object,
  productValues: PropTypes.object,
  productIndex: PropTypes.number,
  isEditMode: PropTypes.bool,
  checkInventory: PropTypes.bool,
  isAmendment: PropTypes.bool
}

export default DatesCell
