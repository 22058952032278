import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo, useState } from 'react'

import useDoubleRequestSubmit from '../../../../../hooks/formHooks/useDoubleRequestSubmit'

import { transformValuesToBE } from './formatters'

import { clearCreateAd, createAd } from '../../../../../modules/actions/ads'
import { clearCreateAssetsBatch, createAssetsBatch } from '../../../../../modules/actions/assets'
import {
  assetsCreatedDataSelector,
  assetsCreateErrorSelector,
  assetsWasCreatedSelector
} from '../../../../../modules/selectors/assets'
import { adCreateErrorSelector, adWasCreatedSelector } from '../../../../../modules/selectors/ads'
import {
  selectedControllerIdSelector,
  selectedAdAccountIdSelector,
  selectedSelfAccountDataSelector
} from '../../../../../modules/selectors/app'

import { STATUS } from '../../fields'
import { GOOGLE_PLATFORM } from '../../../../../constants/selectLists/platformList'
import { BUSINESS_NAME } from '../../../../ReusableFormFields/LineItemForms/fields'
import { ASSET_GROUP_TYPE } from '../../fields'
import { googleAssetFieldType } from '../../../../../constants/ads'
import { BRANDS } from '../../../../../pages/Settings/AdvertiserAccounts/ClientAccountForms/ClientAccountWithMarginsCreate/ClientAccountWithMarginsCreateForm/fields'

// DisplayAdGoogleCreateForm form submission consists of 2 different requests:
// createAssetsBatch and then create ad with previously created assets,
// once first successfully submitted the 2nd is triggerred
export default function useGoogleDisplayAdCreate({
  isInternalAdCreation,
  providedAdAccountId,
  lineItemId,
  campaignId
}) {
  const dispatch = useDispatch()
  const [submittedAssetsList, setSubmittedAssetsList] = useState([])

  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const selectedSelfAccountData = useSelector(selectedSelfAccountDataSelector)
  const selectedSelfAccountControllerId = useSelector(selectedControllerIdSelector)
  const { id: selectedSelfAccountId } = selectedSelfAccountData

  const adAccountId = providedAdAccountId || selectedAdAccountId

  const createAssetsHandler = useCallback(
    values => {
      const assetsData = []
      const selectedBrandData = selectedSelfAccountData[BRANDS]?.find(
        ({ brand_name }) => brand_name === values[BUSINESS_NAME]
      )
      const squareLogoAssetData = selectedBrandData?.square_logo
      const squareLandScapeAssetData = selectedBrandData?.landscape_logo

      if (selectedBrandData && squareLogoAssetData && squareLandScapeAssetData) {
        assetsData.push(
          {
            account: adAccountId,
            name: `${selectedBrandData.brand_name}-square-logo`,
            image_asset: {
              // S3 logo url
              data: squareLogoAssetData
            },
            [ASSET_GROUP_TYPE]: googleAssetFieldType.LOGO
          },
          {
            account: adAccountId,
            name: `${selectedBrandData.brand_name}-landscape-logo`,
            image_asset: {
              // S3 logo url
              data: squareLandScapeAssetData
            },
            [ASSET_GROUP_TYPE]: googleAssetFieldType.LANDSCAPE_LOGO
          }
        )
      }

      setSubmittedAssetsList(assetsData)

      dispatch(
        createAssetsBatch(
          {
            account: adAccountId,
            operations: assetsData
          },
          GOOGLE_PLATFORM
        )
      )
    },
    [dispatch, adAccountId, selectedSelfAccountData]
  )

  const createDisplayAdHandler = useCallback(
    (values, createdAssetsData) => {
      const transformedValues = transformValuesToBE({
        values,
        selectedSelfAccountData,
        createdAssetsData,
        submittedAssetsList
      })
      const adData = {
        ad: transformedValues,
        account: adAccountId,
        ad_group_id: lineItemId,
        campaign_id: campaignId,
        status: values[STATUS],
        ...(isInternalAdCreation && {
          quarantine: true,
          controller_id: selectedSelfAccountControllerId,
          account_id: selectedSelfAccountId
        })
      }

      dispatch(createAd(adData, GOOGLE_PLATFORM))
    },
    [
      dispatch,
      adAccountId,
      lineItemId,
      campaignId,
      submittedAssetsList,
      isInternalAdCreation,
      selectedSelfAccountControllerId,
      selectedSelfAccountId,
      selectedSelfAccountData
    ]
  )

  const clearSubmitHandler = useCallback(() => {
    dispatch(clearCreateAssetsBatch())
    dispatch(clearCreateAd())
  }, [dispatch])

  const createAssetRequest = useMemo(
    () => ({
      requestHandler: createAssetsHandler,
      errorSelector: assetsCreateErrorSelector,
      successSelector: assetsWasCreatedSelector,
      dataSelector: assetsCreatedDataSelector
    }),
    [createAssetsHandler]
  )

  const createDisplayAdRequest = useMemo(
    () => ({
      requestHandler: createDisplayAdHandler,
      errorSelector: adCreateErrorSelector,
      successSelector: adWasCreatedSelector
    }),
    [createDisplayAdHandler]
  )

  const requests = useMemo(
    () => [createAssetRequest, createDisplayAdRequest],
    [createAssetRequest, createDisplayAdRequest]
  )

  return useDoubleRequestSubmit({ requests, clearSubmitHandler })
}
