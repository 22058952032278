import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles({
  mainHeading: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  quotationsActions: {
    display: 'flex',
    columnGap: 16
  },
  proposalsActions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '40px',
    alignItems: 'center'
  }
})

export default useStyles
