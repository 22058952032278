import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'

import DownloadPdfButton from './DownloadPdfButton'

import generatePdfReport from './generatePdfReport'
import { cleanUpTemporaryPDFElements } from '../../../helpers/pdf'

import { setPdfGeneration } from '../../../modules/actions/app'

import { pdfGenerationSelector } from '../../../modules/selectors/app'

const PdfGeneration = ({ dateRangeLabel, title, getReportPDFSections, fileName }) => {
  const dispatch = useDispatch()

  const isPdfGenerating = useSelector(pdfGenerationSelector)

  const theme = useTheme()
  const brandColor = theme.brandPrimary
  const isRtl = theme.textDirection === 'rtl'

  const handlePdfReportGeneration = async () => {
    dispatch(setPdfGeneration(true))

    const contentSections = await getReportPDFSections(brandColor, isRtl)

    generatePdfReport({ dateRangeLabel, title, contentSections, fileName })
      .then(() => {
        dispatch(setPdfGeneration(false))
      })
      .catch(() => {
        cleanUpTemporaryPDFElements()
        dispatch(setPdfGeneration(false))
      })
  }

  return <DownloadPdfButton onClick={handlePdfReportGeneration} isLoading={isPdfGenerating} />
}

PdfGeneration.propTypes = {
  dateRangeLabel: PropTypes.string,
  title: PropTypes.string,
  getReportPDFSections: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired
}

export default PdfGeneration
