import update from 'immutability-helper'
import { INITIAL_CREATE_REDUCER, INITIAL_UPDATE_REDUCER, INITIAL_GET_RESULTS_REDUCER } from '../../constants/reducer'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  CREATE_DOOHLY_LINE_ITEM,
  CREATE_DOOHLY_LINE_ITEM_SUCCESS,
  CREATE_DOOHLY_LINE_ITEM_FAILURE,
  CLEAR_CREATE_DOOHLY_LINE_ITEM,
  CREATE_DOOHLY_CAMPAIGN,
  CREATE_DOOHLY_CAMPAIGN_SUCCESS,
  CREATE_DOOHLY_CAMPAIGN_FAILURE,
  CLEAR_CREATE_DOOHLY_CAMPAIGN,
  GET_DOOHLY_API_KEY,
  GET_DOOHLY_API_KEY_SUCCESS,
  GET_DOOHLY_API_KEY_FAILURE,
  CLEAR_GET_DOOHLY_API_KEY,
  UPDATE_DOOHLY_API_KEY,
  UPDATE_DOOHLY_API_KEY_SUCCESS,
  UPDATE_DOOHLY_API_KEY_FAILURE,
  CLEAR_UPDATE_DOOHLY_API_KEY,
  CREATE_DOOHLY_API_KEY,
  CREATE_DOOHLY_API_KEY_SUCCESS,
  CREATE_DOOHLY_API_KEY_FAILURE,
  CLEAR_CREATE_DOOHLY_API_KEY,
  GET_DOOHLY_CAMPAIGNS,
  GET_DOOHLY_CAMPAIGNS_SUCCESS,
  GET_DOOHLY_CAMPAIGNS_FAILURE,
  CLEAR_GET_DOOHLY_CAMPAIGNS
} from '../actions/doohly'
import { updateItem } from '../../helpers/modules/reducerHelpers'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  createDoohlyLineItem: INITIAL_CREATE_REDUCER,
  createDoohlyCampaign: INITIAL_CREATE_REDUCER,
  doohlyData: INITIAL_GET_RESULTS_REDUCER,
  updateDoohlyApiKey: INITIAL_UPDATE_REDUCER,
  createDoohlyApiKey: INITIAL_CREATE_REDUCER,
  doohlyCampaigns: INITIAL_GET_RESULTS_REDUCER
}

export default function doohly(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // Create doohly line item
    case CREATE_DOOHLY_LINE_ITEM:
      return update(state, {
        createDoohlyLineItem: { $merge: { isLoading: true } }
      })
    case CREATE_DOOHLY_LINE_ITEM_SUCCESS:
      return update(state, {
        createDoohlyLineItem: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        }
      })
    case CREATE_DOOHLY_LINE_ITEM_FAILURE:
      return update(state, {
        createDoohlyLineItem: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_DOOHLY_LINE_ITEM:
      return update(state, {
        createDoohlyLineItem: { $set: initialState.createDoohlyLineItem }
      })

    // Get doohly api key
    case GET_DOOHLY_API_KEY:
      return update(state, {
        doohlyData: { $merge: { isLoading: true } }
      })
    case GET_DOOHLY_API_KEY_SUCCESS:
      return update(state, {
        doohlyData: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data.results
          }
        }
      })
    case GET_DOOHLY_API_KEY_FAILURE:
      return update(state, {
        doohlyData: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_DOOHLY_API_KEY:
      return update(state, {
        doohlyData: { $set: initialState.doohlyData }
      })

    // Create doohly api key
    case CREATE_DOOHLY_API_KEY:
      return update(state, {
        createDoohlyApiKey: { $merge: { isLoading: true } }
      })
    case CREATE_DOOHLY_API_KEY_SUCCESS:
      return update(state, {
        createDoohlyApiKey: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },
        doohlyData: {
          results: {
            $push: [action.data]
          }
        }
      })
    case CREATE_DOOHLY_API_KEY_FAILURE:
      return update(state, {
        createDoohlyApiKey: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_DOOHLY_API_KEY:
      return update(state, {
        createDoohlyApiKey: { $set: initialState.createDoohlyApiKey }
      })

    // Update doohly api key
    case UPDATE_DOOHLY_API_KEY:
      return update(state, {
        updateDoohlyApiKey: { $merge: { isLoading: true } }
      })
    case UPDATE_DOOHLY_API_KEY_SUCCESS:
      return update(state, {
        updateDoohlyApiKey: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            data: action.data
          }
        },
        doohlyData: {
          results: {
            $apply: items => updateItem(items, action.data)
          }
        }
      })
    case UPDATE_DOOHLY_API_KEY_FAILURE:
      return update(state, {
        updateDoohlyApiKey: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_DOOHLY_API_KEY:
      return update(state, {
        updateDoohlyApiKey: { $set: initialState.updateDoohlyApiKey }
      })

    // Get doohly campaigns
    case GET_DOOHLY_CAMPAIGNS:
      return update(state, {
        doohlyCampaigns: { $merge: { isLoading: true } }
      })
    case GET_DOOHLY_CAMPAIGNS_SUCCESS:
      return update(state, {
        doohlyCampaigns: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data
          }
        }
      })
    case GET_DOOHLY_CAMPAIGNS_FAILURE:
      return update(state, {
        doohlyCampaigns: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_DOOHLY_CAMPAIGNS:
      return update(state, {
        doohlyCampaigns: { $set: initialState.doohlyCampaigns }
      })

    // Create doohly campaign
    case CREATE_DOOHLY_CAMPAIGN:
      return update(state, {
        createDoohlyCampaign: { $merge: { isLoading: true } }
      })
    case CREATE_DOOHLY_CAMPAIGN_SUCCESS:
      return update(state, {
        createDoohlyCampaign: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },
        doohlyCampaigns: {
          results: {
            $push: [action.data]
          }
        }
      })
    case CREATE_DOOHLY_CAMPAIGN_FAILURE:
      return update(state, {
        createDoohlyCampaign: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_DOOHLY_CAMPAIGN:
      return update(state, {
        createDoohlyCampaign: { $set: initialState.createDoohlyCampaign }
      })

    default:
      return state
  }
}
