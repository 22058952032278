import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Drawer from '../../../../../components/Drawer'
import GoogleAssetsSliderPreview from '../../GoogleAssetsSliderPreview'
import EditSectionsList from '../../../../../features/components/Forms/EditForm/EditSectionsList'
import NameSectionPreview from './Sections/NameSection/NameSectionPreview'
import HeadlinesSectionForm from './Sections/HeadlinesSection/HeadlinesSectionForm'
import HeadlinesSectionPreview from './Sections/HeadlinesSection/HeadlinesSectionPreview'
import LinkSectionForm from './Sections/LinkSection/LinkSectionForm'
import LinkSectionPreview from './Sections/LinkSection/LinkSectionPreview'
import DescriptionsSectionForm from './Sections/DescriptionsSection/DescriptionsSectionForm'
import DescriptionsSectionPreview from './Sections/DescriptionsSection/DescriptionsSectionPreview'
import LongHeadlinesSectionPreview from './Sections/LongHeadlinesSection/LongHeadlinesSectionPreview'
import LongHeadlinesSectionForm from './Sections/LongHeadlinesSection/LongHeadlinesSectionForm'
import LandscapeImageSectionForm from './Sections/LandscapeImageSection/LandscapeImageSectionForm'
import LandscapeImageSectionPreview from './Sections/LandscapeImageSection/LandscapeImagePreview'
import SquareImageSectionPreview from './Sections/SquareImageSection/SquareImagePreview'
import SquareImageSectionForm from './Sections/SquareImageSection/SquareImageSectionForm'
import PortraitImageSectionForm from './Sections/PortraitImageSection/PortraitImageSectionForm'
import PortraitImageSectionPreview from './Sections/PortraitImageSection/PortraitImageSectionPreview'
import SquareLogoSectionPreview from './Sections/SquareLogoSection/SquareLogoPreview'
import SquareLogoSectionForm from './Sections/SquareLogoSection/SquareLogoSectionForm'
import LandscapeLogoSectionForm from './Sections/LandscapeLogoSection/LandscapeLogoSectionForm'
import LandscapeLogoSectionPreview from './Sections/LandscapeLogoSection/LandscapeLogoPreview'
import VideoUrlsSectionForm from './Sections/VideoUrlsSection/VideoUrlsSectionForm'
import VideoUrlsSectionPreview from './Sections/VideoUrlsSection/VideoUrlsSectionPreview'
import NameSectionForm from './Sections/NameSection/NameSectionForm'

import useManageFormsDrawer from '../../../../../hooks/formHooks/useManageFormsDrawer'
import useManageEditFormData from '../../../../../hooks/formHooks/useManageEditFormData'

import { getAssetGroupTransformedValues } from './helpers'
import { createJsonFromQueryString } from '../../../../../helpers/url'

import { clearAssetGroup, clearUpdateAssetGroup, getAssetGroup } from '../../../../../modules/actions/assets'
import {
  assetGroupIsLoadingSelector,
  assetGroupSelector,
  assetGroupsSelector,
  assetGroupWasLoadedSelector,
  assetsCreateErrorSelector,
  assetsCreateLoadingSelector,
  updateAssetGroupErrorSelector,
  updateAssetGroupIsLoadingSelector,
  updateAssetGroupWaUpdatedSelector
} from '../../../../../modules/selectors/assets'
import { selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'

import { ASSET_GROUP_GOOGLE_ADS_EDIT } from '../../../../../constants/forms'
import {
  NAME,
  HEADLINES_LIST,
  CLICK_THROUGH_LINK,
  DESCRIPTIONS_LIST,
  LONG_HEADLINES_LIST,
  LANDSCAPE_IMAGE,
  SQUARE_IMAGE,
  PORTRAIT_IMAGE,
  SQUARE_LOGO,
  LANDSCAPE_LOGO,
  VIDEO_ADS_URL
} from '../../fields'
import { GOOGLE_PLATFORM } from '../../../../../constants/selectLists/platformList'

// here defined initial open states for AssetGroupGoogleEdit sections
const sectionsInitialOpenStates = {
  [NAME]: false,
  [CLICK_THROUGH_LINK]: false,
  [HEADLINES_LIST]: false,
  [DESCRIPTIONS_LIST]: false,
  [LONG_HEADLINES_LIST]: false,
  [LANDSCAPE_IMAGE]: false,
  [SQUARE_IMAGE]: false,
  [PORTRAIT_IMAGE]: false,
  [SQUARE_LOGO]: false,
  [LANDSCAPE_LOGO]: false,
  [VIDEO_ADS_URL]: false
}

const AssetGroupGoogleEdit = () => {
  const dispatch = useDispatch()

  const assetGroup = useSelector(assetGroupSelector)
  const assetGroupIsLoading = useSelector(assetGroupIsLoadingSelector)
  const assetGroupWasLoaded = useSelector(assetGroupWasLoadedSelector)
  const assetGroupWasUpdated = useSelector(updateAssetGroupWaUpdatedSelector)

  const assetsCreateIsLoading = useSelector(assetsCreateLoadingSelector)
  const assetsCreateError = useSelector(assetsCreateErrorSelector)

  const assetGroups = useSelector(assetGroupsSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const { isFormOpen, handleFormClose, selectedEditItemId } = useManageFormsDrawer({
    formName: ASSET_GROUP_GOOGLE_ADS_EDIT
  })

  // if ad is internal, it appears in query params when we open edit form
  const { internal: internalQueryParam } = createJsonFromQueryString(window.location.search)

  const isInternalAssetGroup = internalQueryParam === 'true'

  const adAccount = useMemo(() => {
    const currentAssetGroup = assetGroups.find(({ id }) => id === selectedEditItemId)
    const currentAssetGroupAdAccountId = currentAssetGroup?.account

    return currentAssetGroupAdAccountId || selectedAdAccountId
  }, [selectedEditItemId, selectedAdAccountId, assetGroups])

  const getAssetGroupDataHandler = useCallback(
    selectedEditItemId => {
      dispatch(
        getAssetGroup({
          platform: GOOGLE_PLATFORM,
          account: adAccount,
          id: selectedEditItemId,
          ...(isInternalAssetGroup && { internal: true })
        })
      )
    },
    [dispatch, isInternalAssetGroup, adAccount]
  )

  const clearAssetGroupHandler = useCallback(() => {
    dispatch(clearAssetGroup())
  }, [dispatch])

  useManageEditFormData({
    formName: ASSET_GROUP_GOOGLE_ADS_EDIT,
    loadedDataId: assetGroup.id,
    getDataHandler: getAssetGroupDataHandler,
    clearDataHandler: clearAssetGroupHandler
  })

  const isInitialDataLoading = assetGroupIsLoading || !assetGroupWasLoaded

  const clearUpdateAssetGroupHandler = useCallback(() => {
    dispatch(clearUpdateAssetGroup())
  }, [dispatch])

  const formProps = useMemo(() => {
    return {
      successSubmit: assetGroupWasUpdated,
      clearEditItem: clearUpdateAssetGroupHandler,
      errorSelector: updateAssetGroupErrorSelector,
      isLoadingSelector: updateAssetGroupIsLoadingSelector,
      isLoading: assetsCreateIsLoading,
      requestError: assetsCreateError,
      isInternalAssetGroup
    }
  }, [
    assetGroupWasUpdated,
    clearUpdateAssetGroupHandler,
    assetsCreateIsLoading,
    assetsCreateError,
    isInternalAssetGroup
  ])

  const AdGroupEditSections = useMemo(
    () => [
      {
        sectionName: NAME,
        title: 'Name',
        PreviewComponent: <NameSectionPreview data={assetGroup} />,
        FormComponent: <NameSectionForm />
      },
      {
        sectionName: CLICK_THROUGH_LINK,
        title: 'Link',
        PreviewComponent: <LinkSectionPreview data={assetGroup} />,
        FormComponent: <LinkSectionForm />
      },
      {
        sectionName: HEADLINES_LIST,
        title: 'Headlines',
        PreviewComponent: <HeadlinesSectionPreview data={assetGroup} />,
        FormComponent: <HeadlinesSectionForm />
      },
      {
        sectionName: DESCRIPTIONS_LIST,
        title: 'Descriptions',
        PreviewComponent: <DescriptionsSectionPreview data={assetGroup} />,
        FormComponent: <DescriptionsSectionForm />
      },
      {
        sectionName: LONG_HEADLINES_LIST,
        title: 'Long headlines',
        PreviewComponent: <LongHeadlinesSectionPreview data={assetGroup} />,
        FormComponent: <LongHeadlinesSectionForm />
      },
      {
        sectionName: LANDSCAPE_IMAGE,
        title: 'Landscape images',
        PreviewComponent: <LandscapeImageSectionPreview data={assetGroup} />,
        FormComponent: <LandscapeImageSectionForm />
      },
      {
        sectionName: SQUARE_IMAGE,
        title: 'Square images',
        PreviewComponent: <SquareImageSectionPreview data={assetGroup} />,
        FormComponent: <SquareImageSectionForm />
      },
      {
        sectionName: PORTRAIT_IMAGE,
        title: 'Portrait images',
        PreviewComponent: <PortraitImageSectionPreview data={assetGroup} />,
        FormComponent: <PortraitImageSectionForm />
      },
      {
        sectionName: SQUARE_LOGO,
        title: 'Square logos',
        PreviewComponent: <SquareLogoSectionPreview data={assetGroup} />,
        FormComponent: <SquareLogoSectionForm />
      },
      {
        sectionName: LANDSCAPE_LOGO,
        title: 'Landscape logos',
        PreviewComponent: <LandscapeLogoSectionPreview data={assetGroup} />,
        FormComponent: <LandscapeLogoSectionForm />
      },
      {
        sectionName: VIDEO_ADS_URL,
        title: 'Video urls',
        PreviewComponent: <VideoUrlsSectionPreview data={assetGroup} />,
        FormComponent: <VideoUrlsSectionForm />
      }
    ],
    [assetGroup]
  )

  const transformedAssetGroupValues = useMemo(() => getAssetGroupTransformedValues(assetGroup), [assetGroup])

  return (
    <Drawer
      title="Update the settings of your performance max ad"
      subTitle="Edit a Google performance max ad"
      isOpen={isFormOpen}
      onClose={handleFormClose}
    >
      <>
        <GoogleAssetsSliderPreview values={transformedAssetGroupValues} showSkeleton={!assetGroupWasLoaded} />
        <EditSectionsList
          editSections={AdGroupEditSections}
          isEditDataLoading={isInitialDataLoading}
          editItemData={assetGroup}
          sectionsInitialOpenStates={sectionsInitialOpenStates}
          formProps={formProps}
        />
      </>
    </Drawer>
  )
}

export default AssetGroupGoogleEdit
