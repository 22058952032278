import React from 'react'
import InfoModalNew from '../../InfoModalNew'

import useStyles from './styles'

const DeleteModalWrapper = ({ modalTitle, ModalMessage, isOpen, onClose, children }) => {
  const classes = useStyles()

  return (
    <InfoModalNew modalTitle={modalTitle} ModalMessage={ModalMessage} isOpen={isOpen} onClose={onClose}>
      <div className={classes.buttonsWrapper}>{children && children}</div>
    </InfoModalNew>
  )
}

export default DeleteModalWrapper
