import { createStyles } from '../../../../../styles/helpers'

import { bottomDrawerPadding } from '../../../../../styles/common/drawerForms'

const useStyles = createStyles({
  editSections: {
    marginTop: 24,
    // fix for avoid cutting of the last section when the drawer is smaller then height of all sections
    paddingBottom: bottomDrawerPadding
  }
})

export default useStyles
