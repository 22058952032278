import * as Yup from 'yup'

export const VERIFICATION_REQUIRED_CHECK = 'verification_required_check'
export const MFA_TYPE = 'mfa_type'

// internal mfa types
export const DISABLED_MFA = 'disabled'
export const SMS_MFA = 'sms'
export const TOTP_MFA = 'totp'

export const getInitialValues = userCurrentMFAType => {
  const isMFAInitiallyEnabled = userCurrentMFAType !== DISABLED_MFA
  // default mfa for user will be TOTP
  const initialMFAType = isMFAInitiallyEnabled ? userCurrentMFAType : TOTP_MFA

  return {
    [VERIFICATION_REQUIRED_CHECK]: isMFAInitiallyEnabled,
    [MFA_TYPE]: initialMFAType
  }
}

export const getValidationSchema = isUserLevelMFARequired => {
  return Yup.object({
    [VERIFICATION_REQUIRED_CHECK]: Yup.boolean().test({
      message: "Two-factor authentication is required for your account and can't be turned off.",
      test: value => {
        if (isUserLevelMFARequired) {
          return value
        } else {
          return true
        }
      }
    })
  })
}
