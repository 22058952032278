import { CHIP_COLORS } from '../../../../../constants/other'
import {
  STATUS_PAID,
  STATUS_INVOICED,
  STATUS_AUTHORISED,
  STATUS_SUBMITTED,
  STATUS_UNKNOWN,
  STATUS_PENDING,
  STATUS_DRAFT
} from '../../InvoicesFilters/InvoicingStatusFilter/options'

export const getChipColorByStatus = status => {
  switch (status) {
    case STATUS_INVOICED:
    case STATUS_SUBMITTED:
      return CHIP_COLORS.yellow

    case STATUS_PAID:
    case STATUS_AUTHORISED:
      return CHIP_COLORS.green

    case STATUS_PENDING:
    case STATUS_UNKNOWN:
    case STATUS_DRAFT:
      return CHIP_COLORS.grey

    default:
      return CHIP_COLORS.red
  }
}
