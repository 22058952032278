import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import EditForm from '../../../features/components/Forms/EditForm'
import ScheduleSelector from '../../../components/ScheduleSelector'
import ErrorMessage from '../../../components/Form/ErrorMessage'

import { usePurifiedFormik } from '../../../hooks/formHooks/usePurifiedFormik'

import { SCHEDULE } from './fields'
import { scheduleTimesValidation } from '../../../features/validations/schedule'

const validationSchema = Yup.object({
  [SCHEDULE]: scheduleTimesValidation
})

const ScheduleSectionForm = ({ handleSubmit, schedule, ...formProps }) => {
  const initialValues = useMemo(() => ({ [SCHEDULE]: schedule || [] }), [schedule])

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const { values, touched, errors, setFieldValue } = purifiedFormik

  return (
    <EditForm formik={purifiedFormik} initialValues={initialValues} {...formProps}>
      <ScheduleSelector name={SCHEDULE} value={values[SCHEDULE]} setFieldValue={setFieldValue} />
      {errors[SCHEDULE] && touched[SCHEDULE] && <ErrorMessage error={errors[SCHEDULE]} />}
    </EditForm>
  )
}

export default ScheduleSectionForm
