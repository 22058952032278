import * as Yup from 'yup'
import { t } from 'i18next'

import {
  AD_LINK,
  FILE_URL,
  MEDIA_DURATION,
  MEDIA_FORMAT,
  MEDIA_HEIGHT,
  MEDIA_RATIO,
  MEDIA_SIZE,
  MEDIA_WIDTH
} from '../../../fields'
import { descriptionMaxLength, headlineMaxLength, primaryTextItemShape } from '../../../validation'
import { urlValidation } from '../../../../../../features/validations/other'

export const storiesItemShape = Yup.object({
  // FILE
  [FILE_URL]: Yup.string().required('Please select Image or Video file'),
  [AD_LINK]: urlValidation
})

export const primaryTextValidation = Yup.array().of(primaryTextItemShape)
export const headlineValidation = Yup.string()
  .max(headlineMaxLength, t('headlineMaxLengthErrorMessage', { maxLength: headlineMaxLength }))
  .required('This field should not be empty')

export const descriptionValidation = Yup.string().max(
  descriptionMaxLength,
  t('descriptionMaxLengthErrorMessage', { maxLength: descriptionMaxLength })
)

export const getFileDetailsError = errors => {
  return (
    errors[MEDIA_SIZE] ||
    errors[MEDIA_WIDTH] ||
    errors[MEDIA_HEIGHT] ||
    errors[MEDIA_FORMAT] ||
    errors[MEDIA_RATIO] ||
    errors[MEDIA_DURATION]
  )
}
