import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import FieldsSection from '../../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../../components/Form/Field'

import { formatOptionsList } from '../../../../../../../features/formatters'
import { getShowPromotedObjectFields, getShowTargetCostCapField } from '../../../../lineItemsHelpers'

import { clearChoicesPixels, getChoicesPixels } from '../../../../../../../modules/actions/choices'
import { campaignSelector } from '../../../../../../../modules/selectors/campaigns'
import { choicesPixelsSelector, choicesPixelsWasLoadedSelector } from '../../../../../../../modules/selectors/choices'
import { selectedAdAccountIdSelector, selectedAdAccountDataSelector } from '../../../../../../../modules/selectors/app'

import { TARGET_COST_CAP } from '../../../../../../ReusableFormFields/LineItemForms/fields'
import { CUSTOM_EVENT_TYPE, PROMOTED_PIXEL } from '../../../../../../ReusableFormFields/PromotedObjectFields/fields'
import customEventTypes from '../../../../../../../constants/selectLists/customEventTypes'

const stepFields = [TARGET_COST_CAP]

const ConversionGoalStep = ({ formik, handleStepChange }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { bidding_type: campaignBiddingType, objective: campaignObjective } = useSelector(campaignSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const pixels = useSelector(choicesPixelsSelector)
  const pixelsWasLoaded = useSelector(choicesPixelsWasLoadedSelector)
  const { currency_symbol } = useSelector(selectedAdAccountDataSelector)

  const showPromotedObjectFields = getShowPromotedObjectFields(campaignObjective)
  const showTargetCostCapField = getShowTargetCostCapField({
    campaignObjective,
    campaignBiddingType,
    lineItemBiddingType: ''
  })

  const formattedPixels = useMemo(
    () =>
      formatOptionsList({
        list: pixels,
        labelName: 'name',
        valueName: 'id'
      }),
    [pixels]
  )

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  useEffect(() => {
    if (!pixelsWasLoaded) {
      dispatch(getChoicesPixels({ account: selectedAdAccountId }))
    }
  }, [dispatch, selectedAdAccountId, pixels, pixelsWasLoaded])
  useEffect(
    () => () => {
      dispatch(clearChoicesPixels())
    },
    [dispatch]
  )

  return (
    <Step
      stepTitle="Select your conversion goal"
      stepDescription="This is the conversion goal that the ad set will optimise towards"
      handleContinue={handleContinue}
    >
      {/*todo options formatting finish when have ability to get pixels stats not empty response*/}
      <FieldsSection title="Conversion Event">
        {showPromotedObjectFields && (
          <>
            <FieldRow
              title="Event source"
              description="Please select the event source that tracks your conversion event"
            >
              <Field
                placeholder={t('Event Source')}
                formik={formik}
                name={PROMOTED_PIXEL}
                options={formattedPixels}
                preselectFirstOptionValue
              />
            </FieldRow>
            <FieldRow
              title="Conversion event"
              description="Select the conversion event that this ad set should optimise towards"
            >
              <Field placeholder={t('Event')} formik={formik} name={CUSTOM_EVENT_TYPE} options={customEventTypes} />
            </FieldRow>
          </>
        )}
        {showTargetCostCapField && (
          <FieldRow
            title="Average cost goal"
            description="Set an average cost per conversion goal. Some conversions will cost less, some will cost more, but the average will be approximately this goal."
          >
            <Field
              type="number"
              formik={formik}
              name={TARGET_COST_CAP}
              placeholder="Average Cost Goal"
              symbol={currency_symbol}
              autoComplete="off"
            />
          </FieldRow>
        )}
      </FieldsSection>
    </Step>
  )
}

export default ConversionGoalStep
