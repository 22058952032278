import { MEDIA_ITEMS_OPTION } from './fields'
import { categoriesWithAllowedPreselectedLocationFilter, MEDIA_CATEGORIES } from '../../fields'

export const getShowLocationFilterInCreateForm = values => {
  return values[MEDIA_ITEMS_OPTION] === MEDIA_CATEGORIES && showLocationFilter(values)
}

export const showLocationFilter = values => {
  // If user one of the allowed categories, we show the location filter
  return categoriesWithAllowedPreselectedLocationFilter.some(category =>
    values[MEDIA_CATEGORIES]?.find(item => item.value === category)
  )
}
