import React from 'react'
import { useSelector } from 'react-redux'

import { lineItemCriterionsSelector } from '../../../../../../modules/selectors/lineItems'

import { GOOGLE_AD_GROUP_USER_LIST_CRITERION_TYPE } from '../../../../../../constants/lineItems'

function AudienceSectionPreview() {
  const lineItemCriterions = useSelector(lineItemCriterionsSelector)

  const audienceCriterions = lineItemCriterions.filter(
    criterion => criterion['type_'] === GOOGLE_AD_GROUP_USER_LIST_CRITERION_TYPE
  )

  if (!audienceCriterions.length) {
    return null
  }

  return (
    <p>
      {audienceCriterions.map((audience, index) => (
        <span key={index}>{audience['display_name']}</span>
      ))}
    </p>
  )
}

export default AudienceSectionPreview
