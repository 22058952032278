import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import PropTypes from 'prop-types'
import Form from '../../../../../../../components/Form'
import WarningMessage from '../../../../../../../features/components/Form/WarningMessage'
import ProductFields from '../../../../../../ReusableFormFields/ProductFields'
import SkeletonForm from '../../../../../../../components/Form/Skeleton'
import DrawerHeadline from '../../../../../../../components/Drawer/DrawerHeadline'

import { usePurifiedFormik } from '../../../../../../../hooks/formHooks/usePurifiedFormik'
import useAudiencesAndPixelsLoading from './useAudiencesAndPixelsLoading'
import useLoadCatalogueProducts from '../../../../../../ReusableFormFields/ProductsAndPagesFields/hooks/useLoadCatalogueProducts'

import { setMediaOrderUploadCreative } from '../../../../../../../modules/actions/mediaOrders'
import { clearCreateAudience, createAudience } from '../../../../../../../modules/actions/audiences'
import { choicesPixelsSelector } from '../../../../../../../modules/selectors/choices'
import {
  audiencesSelector,
  createAudienceDataSelector,
  createAudienceErrorSelector,
  createAudienceIsLoadingSelector,
  createAudienceWasCreatedSelector
} from '../../../../../../../modules/selectors/audiences'
import {
  catalogueProductsErrorSelector,
  catalogueProductsIsLoadingSelector,
  catalogueProductsNextSelector,
  catalogueProductsSelector,
  catalogueProductsWasLoadedSelector
} from '../../../../../../../modules/selectors/mediaOrders'

import { AUDIENCE_PRODUCT_RETARGETING_FORM } from '../index'
import { initialValues, validationSchema, transformValuesToBE } from './fields'
import { PRODUCT_DATA, PRODUCT_ID } from '../../../../../../ReusableFormFields/ProductFields/fields'
import { FACEBOOK_PLATFORM } from '../../../../../../../constants/selectLists/platformList'

import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

const AudienceForm = ({ adAccountId, onSuccessSubmit }) => {
  const drawerFormClasses = useDrawerFormStyles()

  const [successSubmit, setSuccessSubmit] = useState(false)

  const dispatch = useDispatch()

  // we load pixels and audiences when submit previous campaign creation form
  const pixels = useSelector(choicesPixelsSelector)
  const audiences = useSelector(audiencesSelector)
  const createdAudienceData = useSelector(createAudienceDataSelector)
  const audienceWasCreated = useSelector(createAudienceWasCreatedSelector)
  const catalogueProductsNext = useSelector(catalogueProductsNextSelector)

  const firstPixelId = pixels[0]?.id

  const showWarningMessage = !pixels.length

  const audienceFormAdditionalDataIsLoading = useAudiencesAndPixelsLoading(adAccountId)

  const onSubmit = values => {
    const audienceName = `Product ${values[PRODUCT_DATA]['product_item_number']}`

    const existedAudience = audiences.find(audience => audience.name === audienceName)

    // firstly check if audience already exists
    if (existedAudience) {
      dispatch(
        setMediaOrderUploadCreative({
          catalogueProduct: values[PRODUCT_DATA],
          pixelId: existedAudience['pixel_id'],
          audience: existedAudience
        })
      )

      setSuccessSubmit(true)
    } else {
      // otherwise create new normal audience
      const createAudienceData = transformValuesToBE(values, audienceName, adAccountId, firstPixelId)

      dispatch(createAudience(createAudienceData, FACEBOOK_PLATFORM))
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const { setValues, values } = purifiedFormik

  const { loadInitialProductsHandler, loadMoreProductsHandler } = useLoadCatalogueProducts()

  const onProductSelectHandler = useCallback(
    product => {
      setValues({
        [PRODUCT_ID]: product.id,
        [PRODUCT_DATA]: product
      })
    },
    [setValues]
  )

  const clearHandler = useCallback(() => {
    dispatch(clearCreateAudience())
  }, [dispatch])

  useEffect(() => {
    if (audienceWasCreated) {
      dispatch(
        setMediaOrderUploadCreative({
          catalogueProduct: values[PRODUCT_DATA],
          pixelId: firstPixelId,
          audience: createdAudienceData
        })
      )

      setSuccessSubmit(true)
    }
  }, [dispatch, audienceWasCreated, createdAudienceData, firstPixelId, values])

  if (audienceFormAdditionalDataIsLoading) {
    return <SkeletonForm stepsLength={4} />
  }

  if (showWarningMessage) {
    return (
      <WarningMessage
        title="No audience source has been found"
        subTitle="Launch an off-network campaign"
        description="No audience source has been found, please contact your account manager."
      />
    )
  }

  return (
    <div className={drawerFormClasses.formContainer}>
      <DrawerHeadline
        activeStepNumber={1}
        customStepsLength={4}
        description={'You can add more product ads to this campaign after successful creation'}
        title="Select a product to promote"
      />
      <Form
        formName={AUDIENCE_PRODUCT_RETARGETING_FORM}
        formik={purifiedFormik}
        submitText="Save and continue"
        successSubmit={successSubmit}
        onSuccessSubmit={onSuccessSubmit}
        errorSelector={createAudienceErrorSelector}
        isLoadingSelector={createAudienceIsLoadingSelector}
        clearHandler={clearHandler}
      >
        <ProductFields
          formik={formik}
          loadInitialProductsHandler={loadInitialProductsHandler}
          loadMoreProductsHandler={catalogueProductsNext ? loadMoreProductsHandler : null}
          onProductSelect={onProductSelectHandler}
          dataSelector={catalogueProductsSelector}
          errorSelector={catalogueProductsErrorSelector}
          wasLoadedSelector={catalogueProductsWasLoadedSelector}
          isLoadingSelector={catalogueProductsIsLoadingSelector}
        />
      </Form>
    </div>
  )
}

AudienceForm.propTypes = {
  adAccountId: PropTypes.string.isRequired,
  onSuccessSubmit: PropTypes.func.isRequired
}

export default AudienceForm
