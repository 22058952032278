import { createStyles } from '../../styles/helpers'

import { black, darkGrey, grey, lightGrey } from '../../styles/const/colors'
import { zIndexLoader } from '../../styles/const/common'
import { smallPhonesDown } from '../../styles/const/breakpoints'

export default createStyles(theme => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 13
  },
  titleText: {
    fontSize: 12,
    lineHeight: '14px',
    color: grey,
    textTransform: 'uppercase'
  },
  icon: {
    marginRight: 8
  },
  closeBtn: {
    marginLeft: 'auto',
    marginTop: -7
  },
  message: {
    color: darkGrey,
    fontSize: 14,
    overflowWrap: 'anywhere'
  },
  '@global': {
    '.Toastify': {
      position: 'fixed',
      zIndex: zIndexLoader
    },
    '.Toastify__toast': {
      background: 'white !important',
      color: black,
      padding: '0 !important',
      borerRadius: theme.defaultBorderRadius,
      border: {
        width: 1,
        style: 'solid',
        color: lightGrey
      },

      '&-container': {
        '.Toastify &': {
          width: '100%',
          maxWidth: 320,
          top: 60,
          right: 10
        }
      },

      '&-body': {
        margin: 0,
        padding: '10px 10px 20px',
        width: '100%'
      }
    },

    '.Toastify__progress-bar': {
      backgroundColor: [theme.brandPrimary, '!important']
    },
    [`@media screen and (${smallPhonesDown})`]: {
      '.Toastify__toast-container': {
        left: '50% !important',
        transform: 'translateX(-50%) !important'
      }
    }
  }
}))
