import { madeRequest } from '../../helpers/api'

import { replaceRouteParams } from '../../helpers/url'
import { ENDPOINT_PLATFORM, ENDPOINTS } from '../../constants/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function submitServerLogoutService(data) {
  return madeRequest('POST', {
    data,
    url: `${ENDPOINTS.token}blacklist/` // token_blacklist_create
  })
}

export function getCurrentUserService() {
  return madeRequest('GET', {
    url: ENDPOINTS.currentUserProfile
  })
}

export function getUserInvitesService({ id, ...params }) {
  return madeRequest('GET', {
    params,
    url: replaceRouteParams(ENDPOINTS.userInvites, {
      id
    })
  })
}

export function updateCurrentUserService(id, userData) {
  return madeRequest('PATCH', {
    data: userData,
    url: replaceRouteParams(ENDPOINTS.userSpecific, {
      id
    })
  })
}

export function verifyCurrentUserService(id, data) {
  return madeRequest('POST', {
    data,
    url: replaceRouteParams(`${ENDPOINTS.userSpecific}verify/`, {
      id
    })
  })
}

export function setMFATypeService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.setMFA
  })
}

export function bannersService() {
  return madeRequest('GET', {
    url: ENDPOINTS.banners
  })
}

export function getControllerLandingPageSetupService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.controllerLandingPageSetup
  })
}

export function getAsyncSessionService(id, platform, params) {
  return madeRequest('GET', {
    params,
    url: replaceRouteParams(ENDPOINTS.asyncSession, {
      platform: ENDPOINT_PLATFORM[platform],
      id
    })
  })
}
