import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import { compareFileRequirements } from './helpers'

import { bookedMediaSelector } from '../../../../../modules/selectors/mediaOrdersBookings'

export default function useAvailableProducts(selectedFileRequirements) {
  const bookedMedia = useSelector(bookedMediaSelector)

  const availableBookedMediaFiles = useMemo(() => {
    // filter out uploaded files from file requirements
    const filteredFileRequirements = bookedMedia.map(media => {
      // filter out different file requirements from selected
      const sameFileRequirementOnly = media.file_requirements.filter(fileRequirements =>
        compareFileRequirements(selectedFileRequirements, fileRequirements)
      )

      return {
        ...media,
        file_requirements: sameFileRequirementOnly
      }
    })

    return filteredFileRequirements.filter(media => {
      // filter out booked media which don't have files to upload
      return media.file_requirements.length > 0
    })
  }, [bookedMedia, selectedFileRequirements])

  const groupedBookedMedia = useMemo(() => {
    // group booked media by product
    return availableBookedMediaFiles.reduce((acc, media) => {
      if (!acc[media.product]) {
        acc[media.product] = {
          id: media.product,
          name: media.original_product_name,
          bookedMedia: []
        }
      }
      acc[media.product].bookedMedia.push(media)
      return acc
    }, {})
  }, [availableBookedMediaFiles])

  return useMemo(() => {
    return Object.values(groupedBookedMedia)
  }, [groupedBookedMedia])
}
