import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'
import { useTheme } from 'react-jss'
import rtl from 'rtl-css-js'

import ActionButton from '../../ActionButton'
import DescriptionText from '../../DescriptionText'
import HeadlineText from '../../HeadlineText'
import FacebookCarouselItemThumbnail from '../../FacebookCarouselItemThumbnail'

import {
  CAROUSEL_ITEMS,
  DESCRIPTIONS_LIST,
  FILE_HASH,
  FILE_ID,
  FILE_NAME,
  FILE_URL,
  HEADLINES_LIST,
  MEDIA_TYPE,
  VIDEO_PICTURE
} from '../../../../../../forms/Facebook/AdForms/fields'
import { CALL_TO_ACTION } from '../../../../../../forms/ReusableFormFields/CallToActionFields/fields'

import useStyles, { slide } from './styles'

function AdCardCarouselItem({
  viewAdOnFacebook,
  formikValues,
  selectedCarouselItemIndex,
  lastItemIndex,
  isActive,
  handleItemClick,
  index,
  showSkeletonPlaceholders
}) {
  const carouselItem = getIn(formikValues, `${CAROUSEL_ITEMS}[${index}]`)

  const actionValue = carouselItem[CALL_TO_ACTION]
  const descriptionText = carouselItem[DESCRIPTIONS_LIST]?.[0]?.text
  const headlineText = carouselItem[HEADLINES_LIST]?.[0]?.text

  const mediaFile = useMemo(
    () => ({
      [MEDIA_TYPE]: getIn(carouselItem, MEDIA_TYPE),
      [FILE_NAME]: getIn(carouselItem, FILE_NAME),
      [FILE_URL]: getIn(carouselItem, FILE_URL),
      [FILE_HASH]: getIn(carouselItem, FILE_HASH),
      [VIDEO_PICTURE]: getIn(carouselItem, VIDEO_PICTURE),
      [FILE_ID]: getIn(carouselItem, FILE_ID)
    }),
    [carouselItem]
  )

  const classes = useStyles({ selectedCarouselItemIndex, lastItemIndex })

  const theme = useTheme()

  const selectCarouselItem = () => {
    if (!handleItemClick) return
    handleItemClick({ index, isFormOpened: false })
  }

  const marginToActiveItem = useMemo(() => {
    // add margin only to first element
    if (index === 0) {
      return { marginLeft: slide({ selectedCarouselItemIndex, lastItemIndex }) }
    } else {
      return {}
    }
  }, [index, lastItemIndex, selectedCarouselItemIndex])

  return (
    <div
      className={classes.carouselItem}
      onClick={selectCarouselItem}
      style={theme.textDirection === 'ltr' ? marginToActiveItem : rtl(marginToActiveItem)}
    >
      <div className={classes.thumbnail}>
        <FacebookCarouselItemThumbnail viewAdOnFacebook={viewAdOnFacebook} mediaFile={mediaFile} isActive={isActive} />
      </div>
      <div className={classes.footer}>
        <div className={classes.info}>
          <HeadlineText
            headlineText={headlineText}
            className={classes.carouseHeadlineText}
            skeletonClassName={classes.skeletonCarouselHeadline}
            isCarousel
            showSkeletonPlaceholders={showSkeletonPlaceholders}
          />
          <DescriptionText
            descriptionText={descriptionText}
            className={classes.carouseDescriptionText}
            skeletonClassName={classes.skeletonCarouselDescription}
            showSkeletonPlaceholders={showSkeletonPlaceholders}
          />
        </div>
        <ActionButton actionValue={actionValue} isInProgress={isActive} className={classes.carouseActionButton} />
      </div>
    </div>
  )
}

AdCardCarouselItem.propTypes = {
  viewAdOnFacebook: PropTypes.string,
  formikValues: PropTypes.object,
  selectedCarouselItemIndex: PropTypes.number,
  lastItemIndex: PropTypes.number,
  isActive: PropTypes.bool,
  handleItemClick: PropTypes.func,
  index: PropTypes.number,
  showSkeletonPlaceholders: PropTypes.bool
}

export default AdCardCarouselItem
