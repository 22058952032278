import { createStyles } from '../../../styles/helpers'
import { red, lightRed, green, lightGreen, textGrey, darkGrey, bannerGrey } from '../../../styles/const/colors'
import { textEllipsis } from '../../../styles/mixins/text'

const useStyles = createStyles({
  countOfFiles: {
    cursor: 'pointer'
  },
  container: {
    display: 'flex',
    gap: 10,
    alignItems: 'center'
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '7px 9px',
    width: 226,
    overflow: 'hidden'
  },
  tooltipTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: textGrey,
    ...textEllipsis()
  },
  tooltipSubTitle: {
    fontSize: 14,
    marginTop: 3,
    color: darkGrey,
    textWrap: 'wrap'
  },
  status: {
    margin: '8px 0 10px',
    padding: '5px 10px',
    background: bannerGrey,
    color: textGrey,
    fontSize: 12,
    width: 'fit-content',
    borderRadius: 4
  },
  statusApproved: {
    background: lightGreen,
    color: green
  },
  statusDisapproved: {
    background: lightRed,
    color: red
  },
  approvedButton: {
    background: [green, '!important'],
    borderColor: [green, '!important'],
    color: 'white',
    marginBottom: 8
  },
  disapprovedButton: {
    background: [red, '!important'],
    borderColor: [red, '!important'],
    color: 'white',
    marginBottom: 8
  },
  disapprovedReason: {
    color: red,
    marginBottom: 8,
    textWrap: 'wrap'
  }
})

export default useStyles
