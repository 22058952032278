import { concat } from '../../helpers/common'

const MODULE_NAME = 'TIKTOK_CONSTANTS/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION
export const GET_TIK_TOK_VIDEO = concat(MODULE_NAME, 'GET_TIK_TOK_VIDEO')
export const GET_TIK_TOK_VIDEO_SUCCESS = concat(MODULE_NAME, 'GET_TIK_TOK_VIDEO_SUCCESS')
export const GET_TIK_TOK_VIDEO_FAILURE = concat(MODULE_NAME, 'GET_TIK_TOK_VIDEO_FAILURE')
export const CLEAR_GET_TIK_TOK_VIDEO = concat(MODULE_NAME, 'CLEAR_GET_TIK_TOK_VIDEO')

export const GET_TIK_TOK_REGIONS = concat(MODULE_NAME, 'GET_TIK_TOK_REGIONS')
export const GET_TIK_TOK_REGIONS_SUCCESS = concat(MODULE_NAME, 'GET_TIK_TOK_REGIONS_SUCCESS')
export const GET_TIK_TOK_REGIONS_FAILURE = concat(MODULE_NAME, 'GET_TIK_TOK_REGIONS_FAILURE')
export const CLEAR_TIK_TOK_REGIONS = concat(MODULE_NAME, 'CLEAR_TIK_TOK_REGIONS')

export const GET_TIK_TOK_PIXELS = concat(MODULE_NAME, 'GET_TIK_TOK_PIXELS')
export const GET_TIK_TOK_PIXELS_SUCCESS = concat(MODULE_NAME, 'GET_TIK_TOK_PIXELS_SUCCESS')
export const GET_TIK_TOK_PIXELS_FAILURE = concat(MODULE_NAME, 'GET_TIK_TOK_PIXELS_FAILURE')
export const CLEAR_TIK_TOK_PIXELS = concat(MODULE_NAME, 'CLEAR_TIK_TOK_PIXELS')

export const GET_TIK_TOK_IDENTITIES = concat(MODULE_NAME, 'GET_TIK_TOK_IDENTITIES')
export const GET_TIK_TOK_IDENTITIES_SUCCESS = concat(MODULE_NAME, 'GET_TIK_TOK_IDENTITIES_SUCCESS')
export const GET_TIK_TOK_IDENTITIES_FAILURE = concat(MODULE_NAME, 'GET_TIK_TOK_IDENTITIES_FAILURE')
export const CLEAR_TIK_TOK_IDENTITIES = concat(MODULE_NAME, 'CLEAR_TIK_TOK_IDENTITIES')

export const CREATE_TIK_TOK_IDENTITY = concat(MODULE_NAME, 'CREATE_TIK_TOK_IDENTITY')
export const CREATE_TIK_TOK_IDENTITY_SUCCESS = concat(MODULE_NAME, 'CREATE_TIK_TOK_IDENTITY_SUCCESS')
export const CREATE_TIK_TOK_IDENTITY_FAILURE = concat(MODULE_NAME, 'CREATE_TIK_TOK_IDENTITY_FAILURE')
export const CLEAR_CREATE_TIK_TOK_IDENTITY = concat(MODULE_NAME, 'CLEAR_CREATE_TIK_TOK_IDENTITY')

export const GET_TIKTOK_INTERESTS = concat(MODULE_NAME, 'GET_TIKTOK_INTERESTS')
export const GET_TIKTOK_INTERESTS_SUCCESS = concat(MODULE_NAME, 'GET_TIKTOK_INTERESTS_SUCCESS')
export const GET_TIKTOK_INTERESTS_FAILURE = concat(MODULE_NAME, 'GET_TIKTOK_INTERESTS_FAILURE')
export const CLEAR_TIKTOK_INTERESTS = concat(MODULE_NAME, 'CLEAR_TIKTOK_INTERESTS')

export const GET_TIKTOK_ACTION_CATEGORIES = concat(MODULE_NAME, 'GET_TIKTOK_ACTION_CATEGORIES')
export const GET_TIKTOK_ACTION_CATEGORIES_SUCCESS = concat(MODULE_NAME, 'GET_TIKTOK_ACTION_CATEGORIES_SUCCESS')
export const GET_TIKTOK_ACTION_CATEGORIES_FAILURE = concat(MODULE_NAME, 'GET_TIKTOK_ACTION_CATEGORIES_FAILURE')
export const CLEAR_TIKTOK_ACTION_CATEGORIES = concat(MODULE_NAME, 'CLEAR_TIKTOK_ACTION_CATEGORIES')

export const GET_TIKTOK_HASHTAGS = concat(MODULE_NAME, 'GET_TIKTOK_HASHTAGS')
export const GET_TIKTOK_HASHTAGS_SUCCESS = concat(MODULE_NAME, 'GET_TIKTOK_HASHTAGS_SUCCESS')
export const GET_TIKTOK_HASHTAGS_FAILURE = concat(MODULE_NAME, 'GET_TIKTOK_HASHTAGS_FAILURE')
export const CLEAR_TIKTOK_HASHTAGS = concat(MODULE_NAME, 'CLEAR_TIKTOK_HASHTAGS')

export const GET_TIKTOK_CUSTOM_AUDIENCES = concat(MODULE_NAME, 'GET_TIKTOK_CUSTOM_AUDIENCES')
export const GET_TIKTOK_CUSTOM_AUDIENCES_SUCCESS = concat(MODULE_NAME, 'GET_TIKTOK_CUSTOM_AUDIENCES_SUCCESS')
export const GET_TIKTOK_CUSTOM_AUDIENCES_FAILURE = concat(MODULE_NAME, 'GET_TIKTOK_HASHTAGS_FAILURE')
export const CLEAR_TIKTOK_CUSTOM_AUDIENCES = concat(MODULE_NAME, 'CLEAR_TIKTOK_CUSTOM_AUDIENCES')

export const GET_TIK_TOK_VIDEO_THUMBNAILS = concat(MODULE_NAME, 'GET_TIK_TOK_VIDEO_THUMBNAILS')
export const GET_TIK_TOK_VIDEO_THUMBNAILS_SUCCESS = concat(MODULE_NAME, 'GET_TIK_TOK_VIDEO_THUMBNAILS_SUCCESS')
export const GET_TIK_TOK_VIDEO_THUMBNAILS_FAILURE = concat(MODULE_NAME, 'GET_TIK_TOK_VIDEO_THUMBNAILS_FAILURE')
export const CLEAR_TIK_TOK_VIDEO_THUMBNAILS = concat(MODULE_NAME, 'CLEAR_TIK_TOK_VIDEO_THUMBNAILS')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR
// Get tik tok video
export function getTikTokVideo(params) {
  return { type: GET_TIK_TOK_VIDEO, params }
}

export function getTikTokVideoSuccess(data) {
  return { type: GET_TIK_TOK_VIDEO_SUCCESS, data }
}

export function getTikTokVideoFailure(error) {
  return { type: GET_TIK_TOK_VIDEO_FAILURE, error }
}

export function clearGetTikTokVideo() {
  return { type: CLEAR_GET_TIK_TOK_VIDEO }
}

// Get tik tok regions
export function getTikTokRegions(params) {
  return { type: GET_TIK_TOK_REGIONS, params }
}

export function getTikTokRegionsSuccess(data) {
  return { type: GET_TIK_TOK_REGIONS_SUCCESS, data }
}

export function getTikTokRegionsFailure(error) {
  return { type: GET_TIK_TOK_REGIONS_FAILURE, error }
}

export function clearTikTokRegions() {
  return { type: CLEAR_TIK_TOK_REGIONS }
}

// Get tik tok pixels
export function getTikTokPixels(params) {
  return { type: GET_TIK_TOK_PIXELS, params }
}

export function getTikTokPixelsSuccess(data) {
  return { type: GET_TIK_TOK_PIXELS_SUCCESS, data }
}

export function getTikTokPixelsFailure(error) {
  return { type: GET_TIK_TOK_PIXELS_FAILURE, error }
}

export function clearTikTokPixels() {
  return { type: CLEAR_TIK_TOK_PIXELS }
}

// Get tik tok identities
export function getTikTokIdentities(params) {
  return { type: GET_TIK_TOK_IDENTITIES, params }
}

export function getTikTokIdentitiesSuccess(data) {
  return { type: GET_TIK_TOK_IDENTITIES_SUCCESS, data }
}

export function getTikTokIdentitiesFailure(error) {
  return { type: GET_TIK_TOK_IDENTITIES_FAILURE, error }
}

export function clearTikTokIdentities() {
  return { type: CLEAR_TIK_TOK_IDENTITIES }
}

// Create tik tok identities
export function createTikTokIdentity(data) {
  return { type: CREATE_TIK_TOK_IDENTITY, data }
}

export function createTikTokIdentitySuccess(data) {
  return { type: CREATE_TIK_TOK_IDENTITY_SUCCESS, data }
}

export function createTikTokIdentityFailure(error) {
  return { type: CREATE_TIK_TOK_IDENTITY_FAILURE, error }
}

export function clearCreateTikTokIdentity() {
  return { type: CLEAR_CREATE_TIK_TOK_IDENTITY }
}

// get interests
export function getTikTokInterests(params) {
  return { type: GET_TIKTOK_INTERESTS, params }
}

export function getTikTokInterestsSuccess(interestsData) {
  return { type: GET_TIKTOK_INTERESTS_SUCCESS, interestsData }
}

export function getTikTokInterestsFailure(error) {
  return { type: GET_TIKTOK_INTERESTS_FAILURE, error }
}

export function clearTikTokInterests() {
  return { type: CLEAR_TIKTOK_INTERESTS }
}

// get actionCategories
export function getTikTokActionCategories(params) {
  return { type: GET_TIKTOK_ACTION_CATEGORIES, params }
}

export function getTikTokActionCategoriesSuccess(actionCategoriesData) {
  return { type: GET_TIKTOK_ACTION_CATEGORIES_SUCCESS, actionCategoriesData }
}

export function getTikTokActionCategoriesFailure(error) {
  return { type: GET_TIKTOK_ACTION_CATEGORIES_FAILURE, error }
}

export function clearTikTokActionCategories() {
  return { type: CLEAR_TIKTOK_ACTION_CATEGORIES }
}

// get hashtags
export function getTikTokHashtags(params) {
  return { type: GET_TIKTOK_HASHTAGS, params }
}

export function getTikTokHashtagsSuccess(hashtagsData) {
  return { type: GET_TIKTOK_HASHTAGS_SUCCESS, hashtagsData }
}

export function getTikTokHashtagsFailure(error) {
  return { type: GET_TIKTOK_HASHTAGS_FAILURE, error }
}

export function clearTikTokHashtags() {
  return { type: CLEAR_TIKTOK_HASHTAGS }
}

// get customAudiences
export function getTikTokCustomAudiences(params) {
  return { type: GET_TIKTOK_CUSTOM_AUDIENCES, params }
}

export function getTikTokCustomAudiencesSuccess(customAudiencesData) {
  return { type: GET_TIKTOK_CUSTOM_AUDIENCES_SUCCESS, customAudiencesData }
}

export function getTikTokCustomAudiencesFailure(error) {
  return { type: GET_TIKTOK_CUSTOM_AUDIENCES_FAILURE, error }
}

export function clearTikTokCustomAudiences() {
  return { type: CLEAR_TIKTOK_CUSTOM_AUDIENCES }
}

// Get tik tok video thumbnails
export function getTikTokVideoThumbnails(params) {
  return { type: GET_TIK_TOK_VIDEO_THUMBNAILS, params }
}

export function getTikTokVideoThumbnailsSuccess(data) {
  return { type: GET_TIK_TOK_VIDEO_THUMBNAILS_SUCCESS, data }
}

export function getTikTokVideoThumbnailsFailure(error) {
  return { type: GET_TIK_TOK_VIDEO_THUMBNAILS_FAILURE, error }
}

export function clearTikTokVideoThumbnails() {
  return { type: CLEAR_TIK_TOK_VIDEO_THUMBNAILS }
}
