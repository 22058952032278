import * as Yup from 'yup'
import { EVENT_GROUP_TYPE } from '../../../../constants/selectLists/calendarList'

export const NAME = 'name'
export const TYPE = 'type'

export const getInitialValues = (editedEventGroup = {}) => {
  return {
    [NAME]: editedEventGroup[NAME] || '',
    [TYPE]: editedEventGroup[TYPE] || EVENT_GROUP_TYPE.EVENT
  }
}

export const validationSchema = Yup.object({
  [NAME]: Yup.string().required('Please enter name').max(255, 'Name should have maximum 255 characters'),
  [TYPE]: Yup.string().required('Please select type')
})
