import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ContentRow from '../../../../features/components/ContentSection/ContentRow'
import TagsTable from './TagsTable'
import TagsCreate from '../../../../forms/Multiplatform/TagsForms/TagsCreate'
import TagsEdit from '../../../../forms/Multiplatform/TagsForms/TagsEdit'

import { getTagsList } from '../../../../modules/actions/tags'
import { tagsSelector } from '../../../../modules/selectors/tags'
import { selectedSelfAccountDataSelector } from '../../../../modules/selectors/app'

const Tags = () => {
  const dispatch = useDispatch()

  const tags = useSelector(tagsSelector)
  const selectedSelfAccountData = useSelector(selectedSelfAccountDataSelector)

  const controllerId = selectedSelfAccountData.controller

  // update if we need to save new order for tags
  // const handleDragEnd = useCallback(
  // ({ destination, source }) => {
  //   if (destination && destination.index !== source.index) {
  //     const reorderedCategories = reorderElement(orderedMediaCategories, source.index, destination.index)
  //
  //     // dispatch(updateMediaPackageCategoriesOrder(reorderedCategoriesPayload))
  //   }
  // },
  // [dispatch, orderedMediaCategories]
  // )

  useEffect(() => {
    dispatch(getTagsList({ controller: controllerId }))
  }, [dispatch, controllerId])

  return (
    <ContentRow
      title="Tags"
      description="Tags are used to categorize accounts. You can apply bulk actions to tagged accounts, for example limit Package visibility to all accounts with a specific tag"
      leftColumnChildren={<TagsCreate />}
    >
      <TagsTable tags={tags} handleDragEnd={() => {}} />

      <TagsEdit />
    </ContentRow>
  )
}

export default Tags
