import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Can from '../../../../features/components/Can'
import ExpiredQuotationWarning from './ExpiredQuotationWarning'
import ContractReview from '../../../../features/components/Contract/ContractReview'
import ContractMediaOrderGenerate from '../../../../forms/Multiplatform/MediaOrderForms/ContractMediaOrderGenerate'
import GenerateMediaOrderButton from '../../../../forms/Multiplatform/MediaOrderForms/ContractMediaOrderGenerate/GenerateMediaOrderButton'

import usePermissions from '../../../../hooks/usePermissions'
import { useRedirectToGeneratedMediaOrder } from '../../../../forms/Multiplatform/MediaOrderForms/ContractMediaOrderGenerate/hooks/useRedirectToGeneratedMediaOrder'

import { QUOTATION_PERMISSION, SIGNATORY_PERMISSION } from '../../../../constants/permissions'

import useStyles from './styles'

const QuotationReviewContent = ({ quotationContract = {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { quotation_status: quotationStatus } = quotationContract || {}

  const isQuotationExpired = quotationStatus === 'expired'
  const isWithoutRequirements = quotationContract?.without_requirements

  const permissions = usePermissions()
  const allowSignContract = !isWithoutRequirements && permissions.can('manage', SIGNATORY_PERMISSION)

  const redirectToGeneratedMediaOrder = useRedirectToGeneratedMediaOrder()

  if (isQuotationExpired) {
    return <ExpiredQuotationWarning />
  }

  return allowSignContract ? (
    <ContractMediaOrderGenerate contract={quotationContract} onSuccessSubmit={redirectToGeneratedMediaOrder} />
  ) : (
    <>
      <ContractReview contract={quotationContract} allowCreateQuotation={false} />
      {isWithoutRequirements && (
        <Can I="create" a={QUOTATION_PERMISSION}>
          <h4 className={classes.confirmTitle}>{t('Ready to confirm your booking and reserve the inventory?')}</h4>
          <div className={classes.buttonContainer}>
            <GenerateMediaOrderButton
              contractId={quotationContract?.id}
              onSuccessSubmit={redirectToGeneratedMediaOrder}
            />
          </div>
        </Can>
      )}
    </>
  )
}

QuotationReviewContent.propTypes = {
  quotationContract: PropTypes.object
}

export default QuotationReviewContent
