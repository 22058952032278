import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { populateItemsByIdsArray } from '../../helpers'
import { renderArrayObjectValueWithComas } from '../../../../../Facebook/LineItemForms/LineItemFacebookEdit/Sections/CustomAudiencesSection/helpers'

import { lineItemSelector } from '../../../../../../modules/selectors/lineItems'
import {
  tikTokActionCategoriesSelector,
  tikTokInterestsSelector
} from '../../../../../../modules/selectors/tikTokConstants'

import { CREATOR_RELATED, VIDEO_RELATED, HASHTAG_RELATED } from '../../../../../../constants/lineItems'

const AudienceSectionPreview = () => {
  const { interest_category: interestIds, action } = useSelector(lineItemSelector)
  const tikTokInterests = useSelector(tikTokInterestsSelector)
  const tikTokActionCategories = useSelector(tikTokActionCategoriesSelector)

  const videoCategoriesIds = action.find(({ action_scene: type }) => type === VIDEO_RELATED)?.action_categories
  const accountCategoriesIds = action.find(({ action_scene: type }) => type === CREATOR_RELATED)?.action_categories
  const hashtags = action.find(({ action_scene: type }) => type === HASHTAG_RELATED)?.action_categories

  const videoCategories = useMemo(() => {
    return tikTokActionCategories.filter(item => item.action_scene === VIDEO_RELATED)
  }, [tikTokActionCategories])

  const accountCategories = useMemo(() => {
    return tikTokActionCategories.filter(item => item.action_scene === CREATOR_RELATED)
  }, [tikTokActionCategories])

  const anyItemsExist =
    interestIds?.length || videoCategoriesIds?.length || accountCategoriesIds?.length || hashtags?.length

  const populatedInterests = useMemo(
    () =>
      populateItemsByIdsArray({
        idsArray: interestIds,
        itemsList: tikTokInterests,
        itemIdFieldName: 'id',
        itemFallbackNameString: 'Interest'
      }),
    [interestIds, tikTokInterests]
  )

  const populatedVideoCategories = useMemo(
    () =>
      populateItemsByIdsArray({
        idsArray: videoCategoriesIds,
        itemsList: videoCategories,
        itemIdFieldName: 'id',
        itemFallbackNameString: 'Video Category'
      }),
    [videoCategoriesIds, videoCategories]
  )

  const populatedAccountCategories = useMemo(
    () =>
      populateItemsByIdsArray({
        idsArray: accountCategoriesIds,
        itemsList: accountCategories,
        itemIdFieldName: 'id',
        itemFallbackNameString: 'Account Category'
      }),
    [accountCategoriesIds, accountCategories]
  )

  if (!anyItemsExist) {
    return null
  }

  return (
    <p>
      {!!populatedInterests?.length && (
        <span>Interests: {renderArrayObjectValueWithComas(populatedInterests, 'name')}</span>
      )}
      {!!populatedVideoCategories?.length && (
        <span>Video Categories: {renderArrayObjectValueWithComas(populatedVideoCategories, 'name')}</span>
      )}
      {!!populatedAccountCategories?.length && (
        <span>Account Categories: {renderArrayObjectValueWithComas(populatedAccountCategories, 'name')}</span>
      )}
      {!!hashtags?.length && <span>Hashtags: {renderArrayObjectValueWithComas(hashtags, 'name')}</span>}
    </p>
  )
}

export default AudienceSectionPreview
