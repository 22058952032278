import React, { useCallback, useEffect, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { useTranslation } from 'react-i18next'

import Dropdown from '../../Dropdown'
import EmojiPicker from '../../EmojiPicker'
import FieldPortalContent from '../Field/FieldPortalContent'

import { TabletUp } from '../../hoc/ResponsiveRendering'
import { ReactComponent as SmileIcon } from '../../../assets/icons/smile.svg'

import { useDebounce } from '../../../hooks/useDebounce'

import { isArabic } from '../../../helpers/language'
import { clearLSEPCharacters } from '../clearLSEPCharacters'

import useStyles from './styles'

function TextareaField({
  name,
  placeholder,
  formikValue,
  maxLength,
  symbol,
  hasEmojiPicker,
  showError,
  onValueChange,
  setFieldValue,
  setFieldTouched,
  // enableReinitialize,
  portalElement,
  textSuggestionsProps,
  ignoreBlur
}) {
  const { t } = useTranslation()

  const [isPickerOpen, setIsPickerOpen] = useState(false)

  // We need to clear the value in some cases:
  // (e.g. user copy text with special character LSEP from text editor, and paste it to input)
  const cleanFormikValue = clearLSEPCharacters(formikValue)

  const [currentValue, setCurrentValue] = useState(cleanFormikValue)
  const [textDirection, setTextDirection] = useState(isArabic(cleanFormikValue) ? 'rtl' : 'ltr')
  // const [valueReinitialized, setValueReinitialized] = useState(false)
  const hasValue = !!currentValue

  const classes = useStyles({ hasValue, showError, hasEmojiPicker })

  const openEmojiPicker = useCallback(() => {
    if (!isPickerOpen) {
      setIsPickerOpen(true)
    }
  }, [isPickerOpen])

  const handleFieldChange = useCallback(e => {
    const { value } = e.target
    setTextDirection(isArabic(value) ? 'rtl' : 'ltr')
    setCurrentValue(value)
  }, [])

  const handleBlur = useCallback(() => {
    if (!ignoreBlur) {
      // sometimes onBlur should be ignored, e.g. when we need to show error message only after submit
      setFieldTouched(name, true, false)
    }
  }, [setFieldTouched, name, ignoreBlur])

  const onEmojiChoose = useCallback(emoji => {
    setCurrentValue(oldValue => oldValue + emoji.native)
  }, [])

  const onSelectTextSuggestion = useCallback(
    text => {
      // mock structure needed in handleFieldChange, and also trigger blur action
      handleFieldChange({ target: { value: text } })

      // run handleBlur with timeout more than debouncedCurrentValue timeout,
      // help to fix error message flickering before value is set to formik
      setTimeout(() => {
        handleBlur()
      }, 150)
    },
    [handleFieldChange, handleBlur]
  )

  // To improve performance, we update formik only when the user has finished typing
  const debouncedCurrentValue = useDebounce(currentValue, 100)
  useEffect(() => {
    let cleanValue = clearLSEPCharacters(debouncedCurrentValue)
    if (cleanValue !== debouncedCurrentValue) {
      // Set local state value if some character was found
      // We need to clear the value in some cases:
      // (e.g. user copy text with special character LSEP from text editor, and paste it to input)
      setCurrentValue(cleanValue)
    }
    setFieldValue(name, cleanValue)
    onValueChange && onValueChange(cleanValue, name)
    // eslint-disable-next-line
  }, [debouncedCurrentValue, setFieldValue, name, onValueChange])

  useEffect(() => {
    // we need to enable init value reinitialization for cases like edit form, when value is not available right away
    if (cleanFormikValue !== currentValue) {
      setCurrentValue(cleanFormikValue)
    }
    // eslint-disable-next-line
  }, [cleanFormikValue])

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.textAreaWrapper}>
          <TextareaAutosize
            placeholder={t(placeholder)}
            value={currentValue}
            onBlur={handleBlur}
            onChange={handleFieldChange}
            className={classes.textarea}
            // allow to pass right to left direction for arabic text
            dir={textDirection}
            maxLength={maxLength}
          />

          {hasEmojiPicker ? (
            <TabletUp>
              {
                <span onClick={openEmojiPicker} className={classes.icon}>
                  <SmileIcon />
                </span>
              }
            </TabletUp>
          ) : (
            <span className={classes.icon}>{symbol}</span>
          )}
        </div>
        <Dropdown isOpen={isPickerOpen} closeOnInsideClick onOpenChange={setIsPickerOpen}>
          <>
            <br />
            <EmojiPicker style={{ width: '100%' }} onEmojiSelect={onEmojiChoose} />
          </>
        </Dropdown>
      </div>
      {portalElement && (
        <FieldPortalContent
          value={currentValue}
          maxLength={maxLength}
          portalElement={portalElement}
          textSuggestionsProps={textSuggestionsProps}
          onSelectTextSuggestion={onSelectTextSuggestion}
        />
      )}
    </>
  )
}

export default TextareaField
