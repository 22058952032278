import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { redirectTo } from '../../../../../helpers/url'

import { closeForm } from '../../../../../modules/actions/forms'
import { selectedSelfAccountSelector } from '../../../../../modules/selectors/app'
import { createMediaOrderDataSelector, generateMediaOrderSelector } from '../../../../../modules/selectors/mediaOrders'

import { ROUTE_PARAMS, ROUTES } from '../../../../../constants/routes'

export function useRedirectToGeneratedMediaOrder(contextualSelfAccountId) {
  const dispatch = useDispatch()

  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)
  // the media order could be generated from contract or created directly:
  const { id: generatedMediaOrderId } = useSelector(generateMediaOrderSelector)
  const { id: createdMediaOrderId } = useSelector(createMediaOrderDataSelector)

  const redirectToGeneratedMediaOrder = useCallback(() => {
    dispatch(closeForm())
    redirectTo(ROUTES.mediaOrderSummary, {
      // use the id of the method that was used - generated or created
      [ROUTE_PARAMS.mediaOrderId]: generatedMediaOrderId || createdMediaOrderId,
      [ROUTE_PARAMS.selfAccount]: contextualSelfAccountId || selectedSelfAccount
    })
  }, [dispatch, generatedMediaOrderId, createdMediaOrderId, selectedSelfAccount, contextualSelfAccountId])

  return redirectToGeneratedMediaOrder
}
