import { createStyles } from '../../../../../../styles/helpers'

import { darkBlue, darkGreen, darkGrey } from '../../../../../../styles/const/colors'

const useStyles = createStyles({
  wrapper: {
    textAlign: 'right',
    width: '100%'
  },
  oldBudget: {
    color: darkGrey,
    fontSize: 12,
    textDecoration: 'line-through'
  },
  newBudget: {
    fontSize: 14,
    fontWeight: 600,
    color: darkBlue,
    '&.increase': {
      color: darkGreen
    }
  }
})

export default useStyles
