import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import MediaOrderPrice from '../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/MediaOrderPrice'
import FeeItem from './FeeItem'
import TotalWithFees from './TotalWithFees'

import { contractEntitiesSelector } from '../../../../../../modules/selectors/mediaOrders'

import useStyles from './styles'

function FeesAndTotals({
  totalOrderPrice: totalOrderPriceWithoutPreSubTotalFees,
  showFees = true,
  showPreSubTotalFees = true,
  showTotal = true,
  selectedCurrency,
  isSmall
}) {
  const classes = useStyles()
  const { t } = useTranslation()

  const contractEntities = useSelector(contractEntitiesSelector)

  const fees = useMemo(() => {
    return contractEntities[0]?.fees || []
  }, [contractEntities])

  const preSubTotalFees = useMemo(() => {
    return contractEntities[0]?.pre_subtotal_fees || []
  }, [contractEntities])

  const totalOrderPriceWithPreSubTotalFees = useMemo(() => {
    const calculatedPreSubTotalFeesSum = preSubTotalFees.reduce((acc, fee) => {
      const feeCost = totalOrderPriceWithoutPreSubTotalFees.regularPrice * fee.percent
      return acc + feeCost
    }, 0)

    const calculatedDiscountedPreSubTotalFeesSum = preSubTotalFees.reduce((acc, fee) => {
      const feeCost = totalOrderPriceWithoutPreSubTotalFees.discountedPrice * fee.percent
      return acc + feeCost
    }, 0)

    return {
      regularPrice: totalOrderPriceWithoutPreSubTotalFees.regularPrice + calculatedPreSubTotalFeesSum,
      discountedPrice: totalOrderPriceWithoutPreSubTotalFees.discountedPrice + calculatedDiscountedPreSubTotalFeesSum
    }
  }, [
    totalOrderPriceWithoutPreSubTotalFees.regularPrice,
    totalOrderPriceWithoutPreSubTotalFees.discountedPrice,
    preSubTotalFees
  ])

  const totalOrderPrice = useMemo(() => {
    return showPreSubTotalFees ? totalOrderPriceWithPreSubTotalFees : totalOrderPriceWithoutPreSubTotalFees
  }, [showPreSubTotalFees, totalOrderPriceWithPreSubTotalFees, totalOrderPriceWithoutPreSubTotalFees])

  const showDiscountedPrice = totalOrderPrice.discountedPrice !== totalOrderPrice.regularPrice

  return (
    <>
      {showPreSubTotalFees && !!preSubTotalFees?.length && (
        <div>
          {preSubTotalFees.map(preSubTotalFee => {
            return (
              <FeeItem
                key={preSubTotalFee.title}
                fee={preSubTotalFee}
                totalOrderPrice={totalOrderPriceWithoutPreSubTotalFees}
                discountedPrice={showDiscountedPrice ? totalOrderPriceWithoutPreSubTotalFees.discountedPrice : null}
                currencySymbol={selectedCurrency.symbol}
              />
            )
          })}
        </div>
      )}

      <div className={classes.total}>
        <h4>{t('Subtotal:')}</h4>

        <MediaOrderPrice
          className={classnames({ [classes.smallPrice]: isSmall })}
          regularPrice={totalOrderPrice.regularPrice}
          discountedPrice={showDiscountedPrice ? totalOrderPrice.discountedPrice : null}
          currencySymbol={selectedCurrency.symbol}
          isBold
        />
      </div>
      {showFees && (
        <div>
          {fees.map(fee => {
            return (
              <FeeItem
                key={fee.title}
                fee={fee}
                totalOrderPrice={totalOrderPrice}
                discountedPrice={showDiscountedPrice ? totalOrderPrice.discountedPrice : null}
                currencySymbol={selectedCurrency.symbol}
              />
            )
          })}
        </div>
      )}
      {showTotal && (
        <div className={classes.total}>
          <h4>{t('Total:')}</h4>
          <TotalWithFees
            fees={fees}
            totalOrderPrice={totalOrderPrice}
            discountedPrice={showDiscountedPrice ? totalOrderPrice.discountedPrice : null}
            currencySymbol={selectedCurrency.symbol}
          />
        </div>
      )}
    </>
  )
}

export default FeesAndTotals
