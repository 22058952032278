import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Button from '../../../components/Button'
import InternalLink from '../../../components/InternalLink'

import useStyles from './style'

// that is analogue component to ActionsDropdown
const ActionsButtonsList = React.memo(({ itemId, options, callbackData, className }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classnames(className, classes.actionsList)} data-html2canvas-ignore>
      {options &&
        options.map(option =>
          option.link ? (
            <Fragment key={option.text}>
              {option.isLinkExternal ? (
                <a
                  href={option.link}
                  target="_blank"
                  rel="noreferrer nofollow"
                  aria-disabled={true}
                  className={classnames(classes.actionsLink, classes.actionsItem)}
                >
                  {t(option.text)}
                </a>
              ) : (
                <Button>
                  <InternalLink className={classnames(classes.actionsItem, classes.actionsLink)} to={option.link}>
                    {t(option.text)}
                  </InternalLink>
                </Button>
              )}
            </Fragment>
          ) : (
            <Button
              className={classes.actionsItem}
              key={option.text}
              onClick={e => option.onClickHandler(e, itemId, callbackData)}
            >
              {t(option.text)}
            </Button>
          )
        )}
    </div>
  )
})

ActionsButtonsList.propTypes = {
  itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array,
  callbackData: PropTypes.object,
  className: PropTypes.string
}

export default ActionsButtonsList
