import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { mediaOrderUploadCreativeDataSelector } from '../../../../../../modules/selectors/mediaOrders'

// there can be two cases of asset group create form:
// 1) simple usage on campaign summary page
// 2) usage inside multi-form on digital right summary page
// in the second case, we store newly created campaign inside separate redux store
export default function useCampaignDetails() {
  const { campaign: uploadCreativeCampaignData } = useSelector(mediaOrderUploadCreativeDataSelector)
  const { campaign_id: campaignId } = useParams()

  const { id: uploadAdCampaignId } = uploadCreativeCampaignData

  // if has data from right upload ad data selector, use its campaign data
  const isMediaUploadAd = !!uploadAdCampaignId

  const getRegularCampaignData = useCallback(() => {
    return {
      campaignId
    }
  }, [campaignId])

  const getUploadAdCampaignData = useCallback(() => {
    return {
      campaignId: uploadAdCampaignId
    }
  }, [uploadAdCampaignId])

  return useMemo(
    () => (isMediaUploadAd ? getUploadAdCampaignData() : getRegularCampaignData()),
    [isMediaUploadAd, getUploadAdCampaignData, getRegularCampaignData]
  )
}
