import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'

import StepForm from '../../../../../features/components/Forms/StepForm'
import NameStep from './Steps/NameStep'
import IdentityStep from './Steps/IdentityStep'
import ClickThroughLinkStep from './Steps/ClickThroughLinkStep'
import VideoStep from './Steps/VideoStep'
import AdTextStep from './Steps/AdTextStep'
import CallToActionStep from './Steps/CallToActionStep'

import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'
import useIsInternalAdCreation from '../../../../../features/hooks/useIsInternalAdCreation'

import { createAd, clearCreateAd } from '../../../../../modules/actions/ads'
import {
  adCreateLoadingSelector,
  adWasCreatedSelector,
  adCreateErrorSelector
} from '../../../../../modules/selectors/ads'
import { selectedControllerIdSelector, selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'
import { tikTokIdentitiesSelector } from '../../../../../modules/selectors/tikTokConstants'

import { validationSchema } from './validation'
import { initialValues } from './fields'
import { transformValuesToBE } from './formatters'
import { AD_TIK_TOK_CREATE } from '../../../../../constants/forms'
import { TIKTOK_PLATFORM } from '../../../../../constants/selectLists/platformList'

function AdTikTokCreateForm({ adAccountId, campaignId, lineItemId, onSuccessSubmit }) {
  const dispatch = useDispatch()

  const adWasCreated = useSelector(adWasCreatedSelector)
  const tikTokIdentities = useSelector(tikTokIdentitiesSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const selectedSelfAccountControllerId = useSelector(selectedControllerIdSelector)

  // ad account can be passed from outside (e.g. media-orders upload creative form)
  const providedAdAccountId = adAccountId || selectedAdAccountId

  const isInternalAdCreation = useIsInternalAdCreation()

  const clearCreateAdHandler = useCallback(() => {
    dispatch(clearCreateAd())
  }, [dispatch])

  const onSubmit = useCallback(
    values => {
      const formattedData = transformValuesToBE({
        values,
        account: providedAdAccountId,
        // if right upload campaign id exists, use it instead of campaign id from usual selector
        campaignId,
        lineItemId,
        isInternalAdCreation,
        controllerId: selectedSelfAccountControllerId,
        identitiesList: tikTokIdentities
      })

      dispatch(createAd(formattedData, TIKTOK_PLATFORM))
    },
    [
      dispatch,
      providedAdAccountId,
      campaignId,
      lineItemId,
      selectedSelfAccountControllerId,
      tikTokIdentities,
      isInternalAdCreation
    ]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const steps = useMemo(
    () => [
      {
        component: <NameStep />,
        show: true
      },
      {
        component: <IdentityStep adAccountId={providedAdAccountId} />,
        show: true
      },
      {
        component: <ClickThroughLinkStep />,
        show: true
      },
      {
        component: <VideoStep adAccountId={providedAdAccountId} />,
        show: true
      },
      {
        component: <AdTextStep />,
        show: true
      },
      {
        component: <CallToActionStep />,
        show: true
      }
    ],
    [providedAdAccountId]
  )

  return (
    <StepForm
      steps={steps}
      formik={purifiedFormik}
      formName={AD_TIK_TOK_CREATE}
      onSuccessSubmit={onSuccessSubmit}
      // processing
      isLoadingSelector={adCreateLoadingSelector}
      successSubmit={adWasCreated}
      errorSelector={adCreateErrorSelector}
      // after form submit
      clearSubmitHandler={clearCreateAdHandler}
    />
  )
}

AdTikTokCreateForm.propTypes = {
  adAccountId: PropTypes.string,
  campaignId: PropTypes.string,
  lineItemId: PropTypes.string,
  onSuccessSubmit: PropTypes.func
}

export default AdTikTokCreateForm
