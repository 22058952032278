import { createStyles } from '../../../styles/helpers'

export default createStyles({
  productsGrid: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15
  },
  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
})
