import { createStyles } from '../../../styles/helpers'

import { card } from '../../../styles/common'

export default createStyles(theme => ({
  cardListItem: {
    extend: card(theme),
    borderRadius: theme.bigBorderRadius,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  }
}))
