import React, { useCallback, useMemo } from 'react'
import { getIn } from 'formik'
import { v4 as uuidv4 } from 'uuid'

import SingleFileUpload from '../../../../../forms/ReusableFormFields/SingleFileUpload'
import FileDetailsPreview from '../../../../../components/FileDetailsPreview'
import DraggableItemWrapper from '../../../DragAndDropWrapper/DraggableItemWrapper'

import { IMAGES, ORDER } from '../fields'
import { FILE_NAME, FILE_URL, initialImageFileValues } from '../../../../../constants/files'

import useStyles from './styles'

const SortableUploadImagesListItem = ({ formik, index, CustomImagePreview, accept, isInDrawer }) => {
  const { values, setFieldValue, errors, touched } = formik

  const classes = useStyles()

  const imageError = getIn(errors, FILE_URL)
  const imageTouched = getIn(touched, FILE_URL)
  const imageMissingError = imageTouched && imageError

  const image = values[IMAGES][index]

  const fileFormat = useMemo(() => {
    if (image?.[FILE_NAME]) {
      const lastDotIndex = image[FILE_NAME].lastIndexOf('.')
      return image.name && image[FILE_NAME].slice(lastDotIndex + 1)
    }

    return ''
  }, [image])

  const handleMediaUploaded = useCallback(
    uploadedFile => {
      const newImages = [...values[IMAGES]]
      newImages[index] = {
        ...newImages[index],
        // id is needed for unique key in the list
        id: uuidv4(),
        [FILE_NAME]: uploadedFile.name,
        [FILE_URL]: uploadedFile.url
      }
      // add a new empty image after uploading
      newImages.push({
        ...initialImageFileValues,
        [ORDER]: index + 2
      })
      setFieldValue(IMAGES, newImages, true)
    },
    [setFieldValue, index, values]
  )

  const handleMediaFileRemove = useCallback(() => {
    if (values[IMAGES]?.length === 1) {
      setFieldValue(IMAGES, [{ ...initialImageFileValues, [ORDER]: 1 }])
    } else {
      const newImages = values[IMAGES].filter((item, currentIndex) => index !== currentIndex).map(
        (item, currentIndex) => ({ ...item, [ORDER]: currentIndex + 1 })
      )
      setFieldValue(IMAGES, newImages)
    }
  }, [setFieldValue, values, index])

  const ImagePreview = useMemo(
    () =>
      CustomImagePreview ? (
        <CustomImagePreview formik={formik} image={image} index={index} handleMediaFileRemove={handleMediaFileRemove} />
      ) : (
        <FileDetailsPreview
          isImagePreview
          className={classes.fileDetailsPreview}
          fileName={image[FILE_NAME]}
          previewUrl={image[FILE_URL]}
          allowDelete={!!image[FILE_URL]}
          filePreviewType={'image'}
          fileFormat={fileFormat}
          removeFile={handleMediaFileRemove}
        />
      ),
    [CustomImagePreview, fileFormat, handleMediaFileRemove, classes, image, formik, index]
  )

  if (!!image[FILE_URL]) {
    return (
      <DraggableItemWrapper index={index} id={image.id} isInDrawer={isInDrawer}>
        {ImagePreview}
      </DraggableItemWrapper>
    )
  }

  return (
    <SingleFileUpload
      containerClassName={classes.dropFileUploader}
      fileName={image[FILE_NAME]}
      fileURL={image[FILE_URL]}
      onFileUploaded={handleMediaUploaded}
      onFileRemove={handleMediaFileRemove}
      error={imageMissingError}
      accept={accept || 'image/png,image/jpg,image/jpeg'}
      maxSize={205000}
      buttonText="Upload Image"
    />
  )
}

export default SortableUploadImagesListItem
