import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useContext } from 'react'

import ProgressButton from '../../../../../../components/Form/ProgressButton'

import { downloadCSVFile } from '../../../../../../helpers/other'

import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'
import { clearGetMediaProductsAsCsv } from '../../../../../../modules/actions/mediaOrdersProducts'
import {
  getInstallationBookedMediaReportAsCsvErrorSelector,
  getInstallationBookedMediaReportAsCsvIsLoadingSelector,
  getInstallationBookedMediaReportAsCsvSelector,
  getInstallationBookedMediaReportAsCsvWasLoadedSelector
} from '../../../../../../modules/selectors/mediaOrdersBookings'
import {
  clearGetInstallationBookedMediaReportAsCsv,
  getInstallationBookedMediaReportAsCsv
} from '../../../../../../modules/actions/mediaOrdersBookings'

import { InstallationReportFiltersContext } from '../InstallationReportFilters/InstallationReportFiltersContext'

import useStyles from '../../../../../../features/components/mediaProductsComponents/MediaProductsFilters/ExportProductsAsCsv/styles'

const ExportInstallationReportAsCsv = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const selfAccountControllerId = useSelector(selectedControllerIdSelector)
  const isLoading = useSelector(getInstallationBookedMediaReportAsCsvIsLoadingSelector)
  const error = useSelector(getInstallationBookedMediaReportAsCsvErrorSelector)
  const wasLoaded = useSelector(getInstallationBookedMediaReportAsCsvWasLoadedSelector)
  const csvData = useSelector(getInstallationBookedMediaReportAsCsvSelector)

  const { filterRequestParams } = useContext(InstallationReportFiltersContext)

  const handleOnClick = useCallback(() => {
    dispatch(
      getInstallationBookedMediaReportAsCsv({
        controller: selfAccountControllerId,
        ...filterRequestParams
      })
    )
  }, [dispatch, selfAccountControllerId, filterRequestParams])

  const handleDownloadCSVFile = useCallback(() => {
    downloadCSVFile({ data: csvData, fileName: 'installationReport' })

    dispatch(clearGetInstallationBookedMediaReportAsCsv())
  }, [csvData, dispatch])

  const clearHandler = useCallback(() => {
    dispatch(clearGetMediaProductsAsCsv())
  }, [dispatch])

  return (
    <ProgressButton
      onClick={handleOnClick}
      isFormLoading={isLoading}
      formError={error}
      successSubmit={wasLoaded}
      clearHandler={clearHandler}
      onSuccessSubmit={handleDownloadCSVFile}
      wrapperClassName={classes.exportButtonWrapper}
    >
      {t('Export as CSV')}
    </ProgressButton>
  )
}

export default ExportInstallationReportAsCsv
