import { createStyles } from '../../../../../../../../styles/helpers'
import { mediumGrey } from '../../../../../../../../styles/const/colors'

const useStyles = createStyles({
  lineSeparation: {
    width: '100%',
    height: 1,
    background: mediumGrey,
    margin: '16px 0'
  }
})

export default useStyles
