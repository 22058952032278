import { call, put, all, takeEvery } from 'redux-saga/effects'

import { getUsersService } from '../services/users'
import { getUsersSuccess, getUsersFailure, GET_USERS } from '../actions/users'

function* usersWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_USERS, getUsersWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getUsersWorker({ params }) {
  try {
    const response = yield call(getUsersService, params)
    yield put(getUsersSuccess(response))
  } catch (e) {
    yield put(getUsersFailure(e))
  }
}

export default usersWatcher
