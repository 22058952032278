import Decimal from 'decimal.js'
const locale = 'en-US'

// set the same settings as in the backend Decimal module
Decimal.set({
  precision: 28,
  rounding: Decimal.ROUND_HALF_EVEN
})

export function formatCurrency(number, digits = {}, currency) {
  const formattedNumber = formatNumber(number, digits)
  const currencySymbol = currency?.symbol

  const missSymbol = currencySymbol?.length >= 2 && currencySymbol.match(/^[A-Za-z]+$/)
  if (missSymbol) {
    // Where a currency has 2 letters: it should be written 3.75 SR not SR3.75
    // but if has a symbol then it should be standard: A$3.75 not 3.75 A$
    // ^[A-Za-z]+$ matches any string that contains ONLY letters for example SR
    return `${formattedNumber} ${currencySymbol || ''}`
  } else {
    // STANDARD RULES
    // position - always in the beginning
    // symbol - always from endpoint(hardcoded)
    // symbolSpace - without space
    return `${currencySymbol || ''}${formattedNumber}`
  }
}

export function formatNumber(num, digits = {}) {
  // remainder value
  const { min, max } = digits
  return new Intl.NumberFormat(locale, {
    ...(min && { minimumFractionDigits: min }),
    ...((max || max === 0) && { maximumFractionDigits: max })
  }).format(Number(num))
}

export function splitFormattedNumber(num) {
  return num.toString().split('.')
}

export function calc(num) {
  // Decimal library is used to avoid cases of calculation like 0.07 * 100 = 7,000000001
  // || 0 to support cases when num is empty '' string - the reason why Number() is not used is because it converts
  // empty string to 0 we will have precision issues for example Number("272252.660005555234234") - > 272252.66000555526
  return new Decimal(num || 0)
}

export const formatNumberToShort = number => {
  if (number >= 1000000) {
    // Format and append 'm' if the number is in millions
    return `${calc(number).div(1000000).toFixed(1)}m`
  } else if (number >= 1000) {
    // Format and append 'k' if the number is in thousands
    return `${calc(number).div(1000).toFixed(1)}k`
  } else {
    // Return the number as is if it's less than 1000
    return number
  }
}
