import { getLocalStorage } from './storage'

import store from '../store'

import { getCurrentRoute, redirectTo, updateRouteParams } from './url'

import { setSelectedSelfAccount } from '../modules/actions/app'
import { clearMediaOrderData, clearSelectedMediaPackages } from '../modules/actions/mediaOrders'
import { getControllerDefaultLanguage, handleChangeLanguage } from './language'

import { ROUTE_PARAMS, ROUTE_SEGMENTS, ROUTES } from '../constants/routes'
import { clearSelectedAdForCopy } from '../modules/actions/ads'
import { clearActiveContract } from '../modules/actions/contracts'
import {
  currentUserControllersSelector,
  currentUserSelfAccountsSelector,
  selectedLanguageSelector,
  selectedSelfAccountSelector
} from '../modules/selectors/app'

export function getInitialSelfAccount(selfAccounts, selfAccountFromPath) {
  const isAccountValid = selectedAccount => {
    return !!selfAccounts.find(account => parseInt(account.id) === selectedAccount)
  }

  const isPathSelfAccountValid = isAccountValid(selfAccountFromPath)

  if (isPathSelfAccountValid) {
    // path with correct selfAccount
    return selfAccountFromPath
  } else {
    // path with not correct selfAccount parameter - redirect to correct
    const savedAccount = getLocalStorage('selectedSelfAccount')
    const isSavedSelfAccountValid = savedAccount && isAccountValid(parseInt(savedAccount))
    const validAccountId = isSavedSelfAccountValid ? savedAccount : selfAccounts[0] && selfAccounts[0].id

    // always update path with correct selfAccount
    // this will also redirect landing page from home to selfAccountHome i.e: https://dev.shopometry.com/ to https://dev.shopometry.com/168
    updateRouteParams({
      [ROUTE_PARAMS.selfAccount]: parseInt(validAccountId)
    })
    return parseInt(validAccountId)
  }
}

export function handleSelfAccountChange(newAccountId, shouldHandleRedirect) {
  const storeState = store.getState()
  const selectedLanguage = selectedLanguageSelector(storeState)
  const selfAccounts = currentUserSelfAccountsSelector(storeState)
  const selectedSelfAccountId = selectedSelfAccountSelector(storeState)
  const controllers = currentUserControllersSelector(storeState)

  const currentSelfAccountData = selfAccounts.find(account => parseInt(account.id) === parseInt(selectedSelfAccountId))
  const basicSelfAccountData = selfAccounts.find(account => parseInt(account.id) === parseInt(newAccountId))

  const currentControllerId = currentSelfAccountData?.controller
  const newControllerId = basicSelfAccountData?.controller
  const isNewController = currentControllerId !== newControllerId
  const newControllerData = controllers.find(controller => controller.id === newControllerId)

  const newSelectedLanguage = isNewController
    ? getControllerDefaultLanguage(newControllerData, selectedLanguage)
    : selectedLanguage

  if (newSelectedLanguage !== selectedLanguage) {
    handleChangeLanguage(newSelectedLanguage)
  }

  store.dispatch(setSelectedSelfAccount(newAccountId, newSelectedLanguage))
  // selected ad for copy should be reset on Platform/AdAccount change
  store.dispatch(clearSelectedAdForCopy())
  // clear media order data and selected mediaPackages on self account change to reset media order
  store.dispatch(clearMediaOrderData())
  store.dispatch(clearActiveContract())
  store.dispatch(clearSelectedMediaPackages())

  if (shouldHandleRedirect) {
    // when the handleSelfAccountChange is made during the redirectTo process, we manage redirection at that level
    // for the selfAccount change in the slefAccount select - base on the route which user currently is we need to
    // redirect it to correct page
    const currentRoute = getCurrentRoute()
    if (currentRoute.includes(ROUTE_SEGMENTS.mediaOrders)) {
      // if user is in some of the Media orders page, redirect to media orders list:
      redirectTo(ROUTES.mediaOrders, {
        [ROUTES.mediaOrders]: newAccountId
      })
    } else if (currentRoute.includes(ROUTE_SEGMENTS.adApprovals)) {
      // if user is in some of the Ad approvals page, redirect to ad approvals list:
      redirectTo(ROUTES.adApprovals, {
        [ROUTE_PARAMS.selfAccount]: newAccountId
      })
    } else if (currentRoute.includes(ROUTE_SEGMENTS.settings)) {
      // if user is in some of the settings page, redirect to main settings:
      redirectTo(ROUTES.settings, {
        [ROUTE_PARAMS.selfAccount]: newAccountId
      })
    } else {
      // always update path with correct selfAccount
      redirectTo(ROUTES.selfAccountHome, {
        [ROUTE_PARAMS.selfAccount]: newAccountId
      })
    }
  }
}
