import { mediaCategories, PHYSICAL_SPACES } from '../../../../../constants/mediaOrders'

export const getValidCurrencyPackages = ({ mediaPackages, selectedCurrencyCode }) => {
  return mediaPackages.filter(mediaPackage => {
    // skip media package if there is no price with the same currency as selected currency
    // otherwise it will fail to calculate final price/cost for media order
    const hasSelectedCurrency = mediaPackage.prices.some(price => price.currency === selectedCurrencyCode)
    return hasSelectedCurrency
  })
}

export const getSelectedCategoryPackages = ({ mediaPackages, selectedCategory }) => {
  return mediaPackages.filter(mediaPackage => {
    const mediaPackageCategories = mediaPackage['media_categories']
    return !selectedCategory || mediaPackageCategories?.includes(selectedCategory)
  })
}

export const getAvailablePackages = ({ mediaPackages, selectedCategory, selectedCurrencyCode }) => {
  return mediaPackages.filter(mediaPackage => {
    const mediaPackageCategories = mediaPackage['media_categories']
    const hasAppropriateCategory = !selectedCategory || mediaPackageCategories?.includes(selectedCategory)
    // skip media package if there is no price with the same currency as selected currency
    // otherwise it will fail to calculate final price/cost for media order
    const hasSelectedCurrency = mediaPackage.prices.some(price => price.currency === selectedCurrencyCode)
    return hasSelectedCurrency && hasAppropriateCategory
  })
}

const getLandingPageCategoriesOptions = () => {
  return mediaCategories.map(category =>
    // Rename Physical Spaces to Promotional Spaces for the landing page
    category.value === PHYSICAL_SPACES ? { ...category, label: 'Promotional Spaces' } : category
  )
}

export const getAvailableCategories = ({ mediaPackages, selectedCurrencyCode }) => {
  const landingPageCategoriesOptions = getLandingPageCategoriesOptions()

  const validCurrencyPackages = getValidCurrencyPackages({ mediaPackages, selectedCurrencyCode })

  const packagesCategories = []
  validCurrencyPackages.forEach(mediaPackage => {
    if (mediaPackage['media_categories']?.length) {
      mediaPackage['media_categories'].forEach(category => {
        if (!packagesCategories.includes(category)) {
          packagesCategories.push(category)
        }
      })
    }
  })

  return landingPageCategoriesOptions.filter(item => packagesCategories.includes(item.value))
}
