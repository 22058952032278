import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import UtilisationGraph from './UtilisationGraph'
import SkeletonUtilisationGraph from './UtilisationGraph/Skeleton'

import { useInventoryDatesSelector } from '../../../../../../../../features/components/InventoryGraph/useInventoryDatesSelector'
import { getUtilisationReportIsLoadingSelector } from '../../../../../../../../modules/selectors/mediaOrdersProducts'

import { mediaCategoriesLabels } from '../../../../../../../../constants/mediaOrders'
import useStyles from './styles'

const MediaProductsRow = ({ rawData, title }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const productsGraphIsLoading = useSelector(getUtilisationReportIsLoadingSelector)

  const { sortedInventoryLimitedByDateRange, formattedLabels } = useInventoryDatesSelector({
    rawData: rawData,
    dateLabelValueName: 'month'
  })

  if (productsGraphIsLoading) {
    // the loading is handled on this level to avoid re-rendering current component and useInventoryDatesSelector
    // hook which clears filter on unmount
    return <SkeletonUtilisationGraph />
  }

  return (
    <div className={classes.graphSection}>
      {/* media categories doesn't have formatted names, so for media_category we format it manually*/}
      <h4 className={classes.title}>{mediaCategoriesLabels[title] || t(title)}</h4>

      <UtilisationGraph
        sortedInventoryLimitedByDateRange={sortedInventoryLimitedByDateRange}
        formattedLabels={formattedLabels}
      />
    </div>
  )
}

MediaProductsRow.propTypes = {
  rawData: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  graphColor: PropTypes.string,
  maxYValue: PropTypes.number,
  showProductReportLink: PropTypes.bool
}

export default MediaProductsRow
