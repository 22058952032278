import { calc } from '../../../helpers/numbers'
import { calculateDiscountedPrice } from '../../../forms/Multiplatform/MediaOrderForms/MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/price'

import {
  DIGITAL_PRODUCTS,
  EDM_PRODUCTS,
  IN_STORE_PRODUCTS,
  MAGAZINE_PRODUCTS,
  OOH_PRODUCTS,
  PHYSICAL_SPACES_PRODUCTS
} from '../../../forms/Multiplatform/MediaOrderForms/MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/fields'
import { PRODUCT_PERIODS_DATES, QUANTITY } from '../../../forms/Multiplatform/MediaOrderForms/fields'

export const getAllContractProducts = values => {
  return [
    ...(values[MAGAZINE_PRODUCTS] || []),
    ...(values[DIGITAL_PRODUCTS] || []),
    ...(values[EDM_PRODUCTS] || []),
    ...(values[OOH_PRODUCTS] || []),
    ...(values[IN_STORE_PRODUCTS] || []),
    ...(values[PHYSICAL_SPACES_PRODUCTS] || [])
  ]
}

export const formatPeriods = (periods, discount) => {
  return periods.map(period => {
    // BE expects price as Number
    const newPrice = Number(period.price)

    return {
      ...period,
      price: newPrice,
      // apply the discount to all products periods prices if discount is present
      // we send the price per 1 period, BE will calculate the total price based on the quantity
      price_with_discount: !!discount ? calculateDiscountedPrice(newPrice, 1, Number(discount)) : newPrice
    }
  })
}

export const getProductsTotalPrice = products => {
  // sum all the prices of the products, considering the quantity
  return products.reduce((acc, product) => {
    const totalProductPrice = product[PRODUCT_PERIODS_DATES].reduce((productTotal, period) => {
      const periodPrice = calc(period.price).mul(product[QUANTITY]).toNumber()
      return calc(productTotal).add(periodPrice).toNumber()
    }, 0)

    return calc(acc).add(totalProductPrice).toNumber()
  }, 0)
}
