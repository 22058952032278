import React from 'react'
import { useTranslation } from 'react-i18next'

import RadioBlock from '../../../../components/Form/RadioBlock'
import MarginField from '../MarginField'

import { getAppDomainName } from '../../../../features/helpers/getAppDomainName'

import { DIFFERENT_MARGIN, GENERAL_MARGIN, MARGIN_OPTION, MARGINS, NO_MARGIN, SAME_MARGIN } from '../fields'

import {
  DV_360_PLATFORM,
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  TIKTOK_PLATFORM
} from '../../../../constants/selectLists/platformList'

import useStyles from '../styles'
import useCommonStyles from '../../../../styles/common/stepForms'

const AllCampaignsMargin = ({ formik, subTitle = 'MARGINS' }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const appName = getAppDomainName()

  const { values, setFieldValue } = formik

  return (
    <>
      <div className={commonClasses.stepSubTitle}>{t(subTitle)}</div>
      <div className={commonClasses.stepTitle}>{t('Please enter the margin % you want to add to the media cost')}</div>
      <div className={commonClasses.stepBody}>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_same_margin"
          name={MARGIN_OPTION}
          value={SAME_MARGIN}
          selectedValue={values[MARGIN_OPTION]}
          label={t('I want to apply the same margin to all ad platforms')}
        >
          <p>{t('Please enter the % amount that you want to make.')}</p>
          <p>{t('ManageMarginPage.marginText', { appName })}</p>
          <MarginField formik={formik} name={GENERAL_MARGIN} />
        </RadioBlock>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_different_margin"
          name={MARGIN_OPTION}
          value={DIFFERENT_MARGIN}
          selectedValue={values[MARGIN_OPTION]}
          label={t('I want to apply different margins per ad platform')}
        >
          <p>{t('Please enter the % amount that you want to make.')}</p>
          <p>{t('ManageMarginPage.marginText', { appName })}</p>
          <div className={classes.marginValue}>
            <div className={classes.platform}>Facebook</div>
            <MarginField formik={formik} name={`${MARGINS}.${FACEBOOK_PLATFORM}`} />
          </div>
          <div className={classes.marginValue}>
            <div className={classes.platform}>Google Ads</div>
            <MarginField formik={formik} name={`${MARGINS}.${GOOGLE_PLATFORM}`} />
          </div>
          <div className={classes.marginValue}>
            <div className={classes.platform}>DV360</div>
            <MarginField formik={formik} name={`${MARGINS}.${DV_360_PLATFORM}`} />
          </div>
          <div className={classes.marginValue}>
            <div className={classes.platform}>TikTok</div>
            <MarginField formik={formik} name={`${MARGINS}.${TIKTOK_PLATFORM}`} />
          </div>
        </RadioBlock>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_no_margin"
          name={MARGIN_OPTION}
          value={NO_MARGIN}
          selectedValue={values[MARGIN_OPTION]}
          label={t('I don’t want to apply any margin on this account')}
        />
      </div>
    </>
  )
}

export default AllCampaignsMargin
