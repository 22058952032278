import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import BookingsTable from './BookingsTable'

import BookingsFilters from './BookingsFilters'
import { BookingsDataProvider } from './BookingsFilters/BookingsFiltersContext'

import { getSelfAccounts } from '../../../modules/actions/selfAccounts'
import { selectedControllerIdSelector } from '../../../modules/selectors/app'

import useSettingsClasses from '../styles'

const Bookings = () => {
  const dispatch = useDispatch()
  const settingsClasses = useSettingsClasses()

  const controllerId = useSelector(selectedControllerIdSelector)

  const { t } = useTranslation()

  useEffect(() => {
    dispatch(
      getSelfAccounts({
        controllerId: controllerId
      })
    )
  }, [dispatch, controllerId])

  return (
    <>
      <h1 className={settingsClasses.title}>{t('Bookings')}</h1>
      <BookingsDataProvider>
        <BookingsFilters />
        <BookingsTable />
      </BookingsDataProvider>
    </>
  )
}

export default Bookings
