import * as Yup from 'yup'
// import { v4 as uuidv4 } from 'uuid'

import { createDV360LineItemPayload } from './lineItemHelpers'
// import { createNameFromCustomAudiences } from '../../../../Multiplatform/LineItemForms/formatters'

import {
  END_DATE,
  START_DATE,
  endDateValidation,
  startDateValidation,
  startEndDateRangeInitialValues
} from '../../../../ReusableFormFields/StartEndDateRangeFields/fields'

export const initialValues = {
  ...startEndDateRangeInitialValues
}

export const validationSchema = Yup.object({
  [START_DATE]: startDateValidation,
  [END_DATE]: endDateValidation
})

export const transformValuesToBE = (values, campaign) => {
  return createDV360LineItemPayload({
    // name: `${createNameFromCustomAudiences(audiences)} - ${uuidv4().slice(0, 7)}`,
    // todo SHOP-1539 Implement name generation from selected audiences
    name: 'Audience targeting',
    status: 'paused',
    startDate: values[START_DATE],
    endDate: values[END_DATE],
    campaign: campaign
  })
}
