import React from 'react'
import PropTypes from 'prop-types'

import { getAdLongHeadline } from '../../helpers'

const LongHeadlineSectionPreview = ({ ad }) => {
  const longHeadline = getAdLongHeadline(ad)

  if (!longHeadline) {
    return null
  }

  return <p>{longHeadline}</p>
}

LongHeadlineSectionPreview.propTypes = {
  ad: PropTypes.object
}

export default LongHeadlineSectionPreview
