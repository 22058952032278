import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getMarginsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.groupedMargins
  })
}

export function createMarginsService(data) {
  return madeRequest('POST', {
    data,
    url: `${ENDPOINTS.margins}batch/`
  })
}

export function createMarginOverrideService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.marginOverride
  })
}

export function deleteMarginOverrideService(id) {
  return madeRequest('DELETE', {
    url: `${ENDPOINTS.marginOverride}${id}/`
  })
}
