import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import PeriodItem from './PeriodItem'

import { isValidPeriods } from '../../../../helpers/periods'
import { generateGroupLabel } from '../../../helpers'
import calculateProductPrice from '../../../../helpers/calculateProductPrice'
import { calc } from '../../../../../../../../../../helpers/numbers'

import { CUSTOM_PRODUCT_PRICE, ORIGINAL_PRODUCT_PRICE } from '../../../../../../fields'
import { PRODUCT_GROUPED_PUBLICATIONS_DATES } from '../../../../../../../fields'

// The grouped periods are used ONLY to display the selected date range in this component
// The selected periods are used to manage the selected dates in the form, send to BE and etc..
const SelectedPeriodsList = React.memo(
  ({
    setFieldValue,
    productItemPath,
    productPublicationsDatesPath,
    selectedPeriods,
    selectedQuantity,
    selectedGroupedPeriods,
    checkInventory
  }) => {
    const onSelectedGroupValueRemove = useCallback(
      ({ periodGroup, index }) => {
        // remove period group by index from array selectedGroupedPeriods:
        const newGroupsOfPeriods = [...selectedGroupedPeriods]
        newGroupsOfPeriods.splice(index, 1)
        setFieldValue(`${productItemPath}.${PRODUCT_GROUPED_PUBLICATIONS_DATES}`, newGroupsOfPeriods)

        const newSelectedPeriods = selectedPeriods.filter(item => {
          // find and remove all periods from selectedPeriods that are in removed group(several periods)
          return !periodGroup.find(period => {
            if (period.inventory && item.inventory) {
              return period.inventory === item.inventory
            } else {
              // fallback to remove old periods which were added without inventory id
              return period.date_start === item.date_start
            }
          })
        })
        const calculatedPrice = calculateProductPrice(newSelectedPeriods, 1, 0)
        setFieldValue(`${productItemPath}.${ORIGINAL_PRODUCT_PRICE}`, calculatedPrice.totalPrice)
        // apply quantity to the price and reset custom price
        const customPrice = calc(calculatedPrice.totalPrice).mul(selectedQuantity).toDP(2).toNumber()
        setFieldValue(`${productItemPath}.${CUSTOM_PRODUCT_PRICE}`, customPrice)
        setFieldValue(productPublicationsDatesPath, newSelectedPeriods)
      },
      [
        selectedGroupedPeriods,
        setFieldValue,
        productItemPath,
        selectedPeriods,
        selectedQuantity,
        productPublicationsDatesPath
      ]
    )

    return selectedGroupedPeriods.map((periodGroup, index) => {
      const label = generateGroupLabel(periodGroup)
      const isAvailable = checkInventory ? isValidPeriods(periodGroup, selectedQuantity) : true

      return (
        <PeriodItem
          value={{ periodGroup, index }}
          isNotValid={!isAvailable}
          key={label}
          label={label}
          deleteHandler={onSelectedGroupValueRemove}
        />
      )
    })
  }
)

SelectedPeriodsList.propTypes = {
  setFieldValue: PropTypes.func,
  selectedQuantity: PropTypes.number.isRequired,
  selectedPeriods: PropTypes.array,
  selectedGroupedPeriods: PropTypes.array,
  productItemPath: PropTypes.string,
  productPublicationsDatesPath: PropTypes.string,
  checkInventory: PropTypes.bool
}
export default SelectedPeriodsList
