import React, { useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'

import CarouselMediaFields from '../../../../../../ReusableFormFields/MediaFields/CarouselMediaFields'

import { transformCarouselToSingle } from './helpers'

import { clearUpdateAd } from '../../../../../../../modules/actions/ads'

import { AdFacebookEditContext } from '../../../AdFacebookEditContextProvider'

import { CAROUSEL_ITEMS } from '../../../../fields'

const CarouselMediaForm = ({ formik }) => {
  const dispatch = useDispatch()

  const { campaignObjective } = useContext(AdFacebookEditContext)

  const { setValues } = formik

  const switchAdToSingle = useCallback(
    carouselItems => {
      // transform formik modifiedAdValues to single values.
      // for that process carousel fields values are transformed to single item object
      const singleAdValues = transformCarouselToSingle(carouselItems)

      // set single items to formik
      setValues(singleAdValues)
    },
    [setValues]
  )

  const handleCarouselItemDelete = useCallback(
    carouselItems => {
      // In Edit ad form - switch Ad format from Carousel to Single when only one carousel item is left
      if (carouselItems.length === 1) {
        switchAdToSingle(carouselItems)
      }
      dispatch(clearUpdateAd())
    },
    [dispatch, switchAdToSingle]
  )

  return (
    <CarouselMediaFields
      formik={formik}
      onCarouselItemDelete={handleCarouselItemDelete}
      listItemsPath={CAROUSEL_ITEMS}
      campaignObjective={campaignObjective}
    />
  )
}

export default CarouselMediaForm
