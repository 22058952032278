import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import Modal from '../../../../components/Modal'

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-grey.svg'

import useStyles from './styles'

const InfoModal = ({
  ModalMessage,
  isOpen,
  onClose,
  modalTitle,
  children,
  className,
  classNameBody,
  contentClassName
}) => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldFocusAfterRender={true}
      className={classnames(classes.infoModal, className)}
    >
      <div className={classes.header}>
        <h4 className={classes.title}>{t(modalTitle)}</h4>
        <span onClick={onClose} className={classes.closeIcon}>
          <CloseIcon />
        </span>
      </div>
      <div className={classnames(classes.body, classNameBody)}>
        <div className={classes.modalMessage}>{ModalMessage}</div>
        <div className={classnames(classes.contentContainer, contentClassName)}>{children}</div>
      </div>
    </Modal>
  )
}

InfoModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  ModalMessage: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  modalTitle: PropTypes.string,
  classNameBody: PropTypes.string,
  contentClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

export default InfoModal
