import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'

import StepForm from '../../../../../features/components/Forms/StepForm'
import AdCreativeDetailsStep from '../../ReusableSteps/AdCreativeDetailsStep'
import ProductsStep from './Steps/ProductsStep'

import useGoogleDisplayAdCreate from './useGoogleDisplayAdCreate'
import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'
import useIsInternalAdCreation from '../../../../../features/hooks/useIsInternalAdCreation'
import { useShowProductOrPageSelectionStep } from '../../../../Facebook/AdForms/AdFacebookCreate/AdFacebookCreateForm/hooks/useShowProductOrPageSelectionStep'

import { selectedAdAccountIdSelector } from '../../../../../modules/selectors/app'
import { lineItemsLoadingSelector } from '../../../../../modules/selectors/lineItems'

import { AD_GOOGLE_CREATE } from '../../../../../constants/forms'
import { initialValues } from './fields'
import { getValidationSchema } from './validations'

const DisplayAdGoogleCreateForm = ({
  onSuccessSubmit,
  lineItemId,
  campaignId,
  adAccountId: providedAdAccountId,
  formProps
}) => {
  const isLineItemLoading = useSelector(lineItemsLoadingSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)

  const isInternalAdCreation = useIsInternalAdCreation()
  const showProductSelectionStep = useShowProductOrPageSelectionStep()

  const { onSubmit, onClearSubmit, isLoadingSelector, errorSelector, successSubmit } = useGoogleDisplayAdCreate({
    isInternalAdCreation,
    providedAdAccountId,
    lineItemId,
    campaignId
  })

  const adAccountId = providedAdAccountId || selectedAdAccountId

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema({ showProductSelectionStep }),
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const steps = useMemo(
    () => [
      {
        component: <ProductsStep />,
        show: showProductSelectionStep
      },
      {
        component: (
          <AdCreativeDetailsStep
            adAccountId={adAccountId}
            showPreview
            showImagesSection
            showNameField={!showProductSelectionStep}
            showCustomClickThroughLink={!showProductSelectionStep}
          />
        ),
        show: true
      }
    ],
    [adAccountId, showProductSelectionStep]
  )

  return (
    <StepForm
      steps={steps}
      formik={purifiedFormik}
      formName={AD_GOOGLE_CREATE}
      // processing
      isLoadingSelector={isLoadingSelector}
      errorSelector={errorSelector}
      successSubmit={successSubmit}
      isInitialDataLoading={isLineItemLoading}
      // after form submit
      onSuccessSubmit={onSuccessSubmit}
      clearSubmitHandler={onClearSubmit}
      {...formProps}
    />
  )
}

DisplayAdGoogleCreateForm.propTypes = {
  onSuccessSubmit: PropTypes.func,
  lineItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  campaignId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  adAccountId: PropTypes.string
}
export default DisplayAdGoogleCreateForm
