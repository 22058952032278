import { createStyles } from '../../../../styles/helpers'

import { darkGrey } from '../../../../styles/const/colors'
import { input } from '../../../../styles/common/components'

const useStyles = createStyles(theme => ({
  multiField: {
    width: '100%',
    position: 'relative'
  },
  input: {
    extend: input(true, theme)
  },
  button: {
    position: 'absolute',
    top: 'calc(50% - 13px)',
    right: 12,
    padding: '3px 0',
    minWidth: 65,
    width: 65,
    fontSize: 16,
    borderColor: darkGrey
  }
}))

export default useStyles
