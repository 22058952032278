import { format } from 'date-fns'

import { DATES_FORMAT_FULL_YEAR } from '../../../../constants/dates'

export const formatCreatedDateRangeLabel = (datePeriod = {}) => {
  const { startDate, endDate } = datePeriod

  if (startDate && endDate) {
    //   Created: 01 Aug 2024 - 31 Aug 2024
    return `Created: ${format(startDate, DATES_FORMAT_FULL_YEAR)} - ${format(endDate, DATES_FORMAT_FULL_YEAR)}`
  } else if (startDate) {
    //   Created: From 01 Aug 2024
    return `Created: From ${format(startDate, DATES_FORMAT_FULL_YEAR)}`
  } else {
    return ''
  }
}

export const formatDateRangeLabel = (datePeriod = {}) => {
  const { startDate, endDate } = datePeriod

  if (startDate && endDate) {
    //   Created: 01 Aug 2024 - 31 Aug 2024
    return `Go Live: ${format(startDate, DATES_FORMAT_FULL_YEAR)} - ${format(endDate, DATES_FORMAT_FULL_YEAR)}`
  } else if (startDate) {
    //   Created: From 01 Aug 2024
    return `Go Live: From ${format(startDate, DATES_FORMAT_FULL_YEAR)}`
  } else {
    return ''
  }
}
