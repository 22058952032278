import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import TableDataLoader from '../../../../components/Table/TableDataLoader'
import BookingsTableData from './BookingsTableData'
import { BookingsFiltersContext } from '../BookingsFilters/BookingsFiltersContext'

import {
  mediaOrdersErrorSelector,
  mediaOrdersIsLoadingSelector,
  mediaOrdersSelector,
  mediaOrdersWasLoadedSelector
} from '../../../../modules/selectors/mediaOrders'

import useCommonStyles from '../../../../styles/common/listPage'

export const tableColumnsSize = {
  id: 60,
  name: 250,
  account: 200,
  campaignName: 240,
  created: 200,
  createdBy: 200,
  cost: 120,
  status: 150,
  actions: 30
}

export const tableColumnMinSize = {
  name: 170,
  account: 80,
  brand: 90,
  campaignName: 170,
  created: 120,
  createdBy: 120,
  status: 150
}

const BookingsTable = () => {
  const { t } = useTranslation()

  const commonClasses = useCommonStyles()

  const mediaOrders = useSelector(mediaOrdersSelector)
  const { loadMoreMediaOrdersHandler } = useContext(BookingsFiltersContext)

  const bookingsColumnsSchema = useMemo(
    () => [
      {
        header: 'ID',
        style: { maxWidth: tableColumnsSize.id }
      },
      {
        header: 'Name',
        style: { maxWidth: tableColumnsSize.name }
      },
      {
        header: 'Account',
        style: { maxWidth: tableColumnsSize.account }
      },
      {
        header: 'Campaign Name',
        style: { maxWidth: tableColumnsSize.campaignName }
      },
      {
        header: 'Created',
        style: { maxWidth: tableColumnsSize.created }
      },
      {
        header: 'Created By',
        style: { maxWidth: tableColumnsSize.createdBy }
      },
      {
        header: 'Cost',
        style: { maxWidth: tableColumnsSize.cost }
      },
      {
        header: 'Status',
        style: { maxWidth: tableColumnsSize.status }
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no bookings')}</div>
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <TableDataLoader
      itemsLength={mediaOrders.length}
      errorSelector={mediaOrdersErrorSelector}
      wasLoadedSelector={mediaOrdersWasLoadedSelector}
      isLoadingSelector={mediaOrdersIsLoadingSelector}
      skeletonProps={{ cols: bookingsColumnsSchema }}
      noDataContent={noDataContent}
      loadMore={loadMoreMediaOrdersHandler}
    >
      <BookingsTableData />
    </TableDataLoader>
  )
}

export default BookingsTable
