import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

// Create doohly line item
export const createDoohlyLineItemSelector = createSelector(
  ({ doohly }) => doohly.createDoohlyLineItem.results,
  results => results
)

export const createDoohlyLineItemIsLoadingSelector = createSelector(
  ({ doohly }) => doohly.createDoohlyLineItem.isLoading,
  isLoading => isLoading
)

export const createDoohlyLineItemErrorSelector = createSelector(
  ({ doohly }) => doohly.createDoohlyLineItem.error,
  error => error
)

export const doohlyLineItemWasCreatedSelector = createSelector(
  ({ doohly }) => doohly.createDoohlyLineItem.wasCreated,
  wasCreated => wasCreated
)

// Create doohly campaign
export const createDoohlyCampaignDataSelector = createSelector(
  ({ doohly }) => doohly.createDoohlyCampaign.data,
  data => data
)

export const createDoohlyCampaignIdSelector = createSelector(
  ({ doohly }) => doohly.createDoohlyCampaign.data.id,
  id => id
)

export const createDoohlyCampaignIsLoadingSelector = createSelector(
  ({ doohly }) => doohly.createDoohlyCampaign.isLoading,
  isLoading => isLoading
)

export const createDoohlyCampaignErrorSelector = createSelector(
  ({ doohly }) => doohly.createDoohlyCampaign.error,
  error => error
)

export const doohlyCampaignWasCreatedSelector = createSelector(
  ({ doohly }) => doohly.createDoohlyCampaign.wasCreated,
  wasCreated => wasCreated
)

// Get doohly api key
export const doohlyDataSelector = createSelector(
  ({ doohly }) => doohly.doohlyData.results,
  results => results
)

export const doohlyDataIsLoadingSelector = createSelector(
  ({ doohly }) => doohly.doohlyData.isLoading,
  isLoading => isLoading
)

export const doohlyDataErrorSelector = createSelector(
  ({ doohly }) => doohly.doohlyData.error,
  error => error
)

export const doohlyDataWasLoadedSelector = createSelector(
  ({ doohly }) => doohly.doohlyData.wasLoaded,
  wasLoaded => wasLoaded
)

// Update doohly api key
export const updateDoohlyApiKeySelector = createSelector(
  ({ doohly }) => doohly.updateDoohlyApiKey.data,
  data => data
)

export const updateDoohlyApiKeyIsLoadingSelector = createSelector(
  ({ doohly }) => doohly.updateDoohlyApiKey.isLoading,
  isLoading => isLoading
)

export const updateDoohlyApiKeyErrorSelector = createSelector(
  ({ doohly }) => doohly.updateDoohlyApiKey.error,
  error => error
)

export const updateDoohlyApiKeyWasUpdatedSelector = createSelector(
  ({ doohly }) => doohly.updateDoohlyApiKey.wasUpdated,
  wasUpdated => wasUpdated
)

// Create doohly api key
export const createDoohlyApiKeySelector = createSelector(
  ({ doohly }) => doohly.createDoohlyApiKey.data,
  data => data
)

export const createDoohlyApiKeyIsLoadingSelector = createSelector(
  ({ doohly }) => doohly.createDoohlyApiKey.isLoading,
  isLoading => isLoading
)

export const createDoohlyApiKeyErrorSelector = createSelector(
  ({ doohly }) => doohly.createDoohlyApiKey.error,
  error => error
)

export const createDoohlyApiKeyWasCreatedSelector = createSelector(
  ({ doohly }) => doohly.createDoohlyApiKey.wasCreated,
  wasCreated => wasCreated
)

// Get doohly campaigns
export const doohlyCampaignsSelector = createSelector(
  ({ doohly }) => doohly.doohlyCampaigns.results,
  results => results
)

export const doohlyCampaignsIsLoadingSelector = createSelector(
  ({ doohly }) => doohly.doohlyCampaigns.isLoading,
  isLoading => isLoading
)

export const doohlyCampaignsErrorSelector = createSelector(
  ({ doohly }) => doohly.doohlyCampaigns.error,
  error => error
)

export const doohlyCampaignsWasLoadedSelector = createSelector(
  ({ doohly }) => doohly.doohlyCampaigns.wasLoaded,
  wasLoaded => wasLoaded
)
