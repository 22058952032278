import { createStyles } from '../../../../../../../../../styles/helpers'
import { phonesDown } from '../../../../../../../../../styles/const/breakpoints'

const useStyles = createStyles({
  filtersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10,
    alignItems: 'flex-start',
    marginBottom: 10
  },
  rightSideFilters: {
    display: 'flex',
    gap: 10
  },
  select: {
    maxWidth: 120,
    minWidth: 120
  },
  [`@media screen and (${phonesDown})`]: {
    filtersContainer: {
      justifyContent: 'flex-start',
      flexDirection: 'column'
    }
  }
})

export default useStyles
