import { createStyles } from '../../../../../../../../../../../styles/helpers'

import { darkGrey } from '../../../../../../../../../../../styles/const/colors'
import { counterBorder, counterBtn } from '../../../../../../../../../../../components/Counter/styles'

export default createStyles({
  btnDelete: {
    extend: [counterBtn, counterBorder],
    fontSize: '12px',
    color: darkGrey
  }
})
