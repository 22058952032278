import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import useGetInstagramAccounts from '../../../../../../hooks/instagramAccountsHooks/useGetInstagramAccounts'

import { accessibleFacebookPagesSelector } from '../../../../../../modules/selectors/socialAccounts'

import { FB_PAGE_CHOICE, INSTA_ACCOUNT_CHOICE, NAME } from '../../../fields'

const NameAndPageSectionPreview = ({ adValues }) => {
  const { t } = useTranslation()

  const facebookPages = useSelector(accessibleFacebookPagesSelector)

  const pageId = adValues[FB_PAGE_CHOICE]
  const page = facebookPages.find(page => page.id === pageId)
  const pageName = page && page.name

  const { selectedInstagramAccounts } = useGetInstagramAccounts(adValues[FB_PAGE_CHOICE])

  const { results: instagramAccounts } = selectedInstagramAccounts

  const instagramAccountId = adValues[INSTA_ACCOUNT_CHOICE]
  const instagramAccount = instagramAccounts.find(account => account.id === instagramAccountId)
  const instagramAccountName = instagramAccount && instagramAccount.username

  return (
    <p>
      <span>
        <b>{t('Name:')}</b> {adValues[NAME]}
      </span>

      {pageName && (
        <span>
          <b>{t('Facebook Page:')}</b> {pageName}
        </span>
      )}

      {instagramAccountName && (
        <span>
          <b>{t('Instagram Account:')}</b> {instagramAccountName}
        </span>
      )}
    </p>
  )
}

export default NameAndPageSectionPreview
