import React, { useCallback } from 'react'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import StopDateFields from '../../../../../../ReusableFormFields/StopTimeFields'

import { STOP_DATE } from '../../../../../../ReusableFormFields/StopTimeFields/fields'

const stepFields = [STOP_DATE]

const EndDateStep = ({ formik, handleStepChange }) => {
  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  return (
    <Step stepTitle="When would you like the ad group to end?" handleContinue={handleContinue}>
      <StopDateFields
        formik={formik}
        showIndefiniteOption={false}
        showTimePicker={false}
        indefiniteOptionDescription="The ad group will run indefinitely until paused"
      />
    </Step>
  )
}

export default EndDateStep
