import { createStyles } from '../../styles/helpers'

import { input } from '../../styles/common/components'
import { greyOutline } from '../../styles/const/colors'

export default createStyles(theme => ({
  searchInputContainer: {
    position: 'relative'
  },
  searchInput: {
    extend: input(false, theme),
    paddingLeft: 30,
    paddingRight: 40,
    borderColor: greyOutline
  },
  searchIcon: {
    width: 14,
    position: 'absolute',
    left: 10,
    top: '50%',
    transform: 'translate(0, -50%)'
  },
  clearIconContainer: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    right: 4,
    width: '30px',
    height: '30px',
    transform: 'translate(0, -50%)',
    cursor: 'pointer',

    '& svg': {
      margin: 'auto',
      fontSize: '15px',

      '& path': {
        fill: greyOutline
      }
    }
  },
  clearIconContainerActive: {
    display: 'flex'
  }
}))
