import { useCallback, useMemo, useState } from 'react'

import { isEqual } from '../../helpers/common'

export function useEditSectionFormSubmit({ initialValues, handleSubmit }) {
  const [manualSuccessSubmit, setManualSuccessSubmit] = useState(false)

  const onSubmit = useCallback(
    values => {
      // if values are equal (not modified), imitate success submit
      if (isEqual(values, initialValues)) {
        setManualSuccessSubmit(true)
      } else {
        // setManualSuccessSubmit can be also triggered inside handleSubmit if needed
        handleSubmit && handleSubmit(values, setManualSuccessSubmit)
      }
    },
    [initialValues, handleSubmit]
  )

  return useMemo(
    () => ({
      onSubmit,
      manualSuccessSubmit,
      setManualSuccessSubmit
    }),
    [manualSuccessSubmit, onSubmit]
  )
}
