import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Table from '../../../../../components/Table'
import MemberPermissionsEdit from '../MemberPermissionsEdit'

import { useSelfAccountMemberActions } from './useSelfAccountMemberActions'
import { useMembersAndInvitesColumns } from '../../../../../features/hooks/useMembersAndInvitesColumns'

import {
  deletedInvitationSelector,
  updatedMemberSelector,
  updateMemberIsLoadingSelector,
  deletedInvitationIsLoadingSelector
} from '../../../../../modules/selectors/selfAccountTeam'

import useStyles from '../../../../../features/hooks/useMembersAndInvitesColumns/styles'

const MembersTableData = ({
  membersAndInvites,
  onChangeMemberStatus,
  onChangeMemberRole,
  onResendInvite,
  onDeleteInvite,
  membersLists
}) => {
  const classes = useStyles()

  const { id: memberId } = useSelector(updatedMemberSelector)
  const updatedMemberIsLoading = useSelector(updateMemberIsLoadingSelector)
  const { id: invitationId } = useSelector(deletedInvitationSelector)
  const deletedInvitationIsLoading = useSelector(deletedInvitationIsLoadingSelector)

  const getItemUpdatingId = useMemo(() => {
    if (updatedMemberIsLoading) {
      return memberId
    } else if (deletedInvitationIsLoading) {
      return invitationId
    }

    return undefined
  }, [memberId, updatedMemberIsLoading, invitationId, deletedInvitationIsLoading])

  const membersActions = useSelfAccountMemberActions({ membersLists })
  const membersAndInvitesColumns = useMembersAndInvitesColumns({
    membersAndInvites,
    onChangeMemberStatus,
    onChangeMemberRole,
    onDeleteInvite,
    onResendInvite,
    getAdditionalActions: membersActions.getAdditionalSelfAccountMemberActions
  })

  return (
    <>
      <Table
        hideFooterRow
        data={membersAndInvites}
        cols={membersAndInvitesColumns}
        itemUpdatingId={getItemUpdatingId}
        mobileColumnsWrapperClassName={classes.mobileHeaderStyle}
      />
      <MemberPermissionsEdit />
    </>
  )
}

MembersTableData.propTypes = {
  membersAndInvites: PropTypes.array.isRequired
}

export default MembersTableData
