import { createSelector } from 'reselect'
import { DIGITAL, OOH } from '../../constants/mediaOrders'

// PLOP_APPEND_PATTERN_ANCHOR

// Generate media order
export const generateMediaOrderSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.generateMediaOrder.data,
  data => data
)

export const generateMediaOrderIsLoadingSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.generateMediaOrder.isLoading,
  isLoading => isLoading
)

export const generateMediaOrderErrorSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.generateMediaOrder.error,
  error => error
)

export const mediaOrderWasGeneratedSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.generateMediaOrder.wasCreated,
  wasCreated => wasCreated
)

// selectedMediaPackage
export const selectedMediaPackagesSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.selectedMediaPackages,
  selectedMediaPackages => selectedMediaPackages
)

// get media orders
export const mediaOrdersIsLoadingSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrders.isLoading,
  isLoading => isLoading
)

export const mediaOrdersWasLoadedSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrders.wasLoaded,
  wasLoaded => wasLoaded
)

export const mediaOrdersNextSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrders.paging.next,
  results => results
)

export const mediaOrdersSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrders.results,
  results => results
)

export const mediaOrdersErrorSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrders.error,
  error => error
)

// get media order
export const mediaOrderIsLoadingSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrder.isLoading,
  isLoading => isLoading
)

export const mediaOrderWasLoadedSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrder.wasLoaded,
  wasLoaded => wasLoaded
)

export const mediaOrderSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrder.data,
  data => data
)

export const mediaOrderProductsSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrder.data.products,
  products => products
)

export const mediaOrderDigitalProductsSelector = createSelector(mediaOrderProductsSelector, products =>
  products.filter(product => product.media_category === DIGITAL)
)

export const mediaOrderOOHProductsSelector = createSelector(mediaOrderProductsSelector, products =>
  products.filter(product => product.media_category === OOH)
)

export const mediaOrderErrorSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrder.error,
  error => error
)

// Add media order file
export const addedMediaOrderFileSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.addMediaOrderFile.data,
  data => data
)

export const addMediaOrderFileIsLoadingSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.addMediaOrderFile.isLoading,
  isLoading => isLoading
)

export const addMediaOrderFileErrorSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.addMediaOrderFile.error,
  error => error
)

export const addMediaOrderFileWasCreatedSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.addMediaOrderFile.wasCreated,
  wasCreated => wasCreated
)

// Delete media order file
export const deleteMediaOrderFileSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.deleteMediaOrderFile,
  data => data
)

export const deleteMediaOrderFileIsLoadingSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.deleteMediaOrderFile.isLoading,
  isLoading => isLoading
)

export const deleteMediaOrderFileErrorSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.deleteMediaOrderFile.error,
  error => error
)

export const deleteMediaOrderFileWasLoadedSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.deleteMediaOrderFile.wasLoaded,
  wasLoaded => wasLoaded
)

// Get media order files
export const mediaOrderFilesSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrderFiles.results,
  results => results
)

export const getMediaOrderFilesIsLoadingSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrderFiles.isLoading,
  isLoading => isLoading
)

export const getMediaOrderFilesErrorSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrderFiles.error,
  error => error
)

export const getMediaOrderFilesWasLoadedSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrderFiles.wasLoaded,
  wasLoaded => wasLoaded
)

// create media order helper
export const productPeriodsUpdateSelector = createSelector(
  // uses to know if product periods setup is initial or update
  ({ mediaOrders }) => mediaOrders.productPeriodsUpdate,
  isPeriodsUpdate => isPeriodsUpdate
)

// create media order
export const createMediaOrderDataSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.createMediaOrder.data,
  data => data
)

export const createMediaOrderIsLoadingSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.createMediaOrder.isLoading,
  isLoading => isLoading
)

export const createMediaOrderErrorSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.createMediaOrder.error,
  error => error
)

export const mediaOrderWasCreatedSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.createMediaOrder.wasCreated,
  wasCreated => wasCreated
)

// update media order
export const updateMediaOrderIsLoadingSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.updateMediaOrder.isLoading,
  isLoading => isLoading
)

export const updateMediaOrderErrorSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.updateMediaOrder.error,
  error => error
)

export const updateMediaOrderWasUpdatedSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.updateMediaOrder.wasUpdated,
  wasUpdated => wasUpdated
)

// Cancel media order
export const cancelMediaOrderSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.cancelMediaOrder.results,
  results => results
)

export const cancelMediaOrderIsLoadingSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.cancelMediaOrder.isLoading,
  isLoading => isLoading
)

export const cancelMediaOrderErrorSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.cancelMediaOrder.error,
  error => error
)

export const cancelMediaOrderWasCreatedSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.cancelMediaOrder.wasCreated,
  wasCreated => wasCreated
)

// media order uploads
export const mediaOrderUploadCreativeDataSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrderUploadCreative,
  data => data
)

// Get catalogue products
export const catalogueProductsSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.catalogueProducts.results,
  results => results
)

export const catalogueProductsIsLoadingSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.catalogueProducts.isLoading,
  isLoading => isLoading
)

export const catalogueProductsErrorSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.catalogueProducts.error,
  error => error
)

export const catalogueProductsWasLoadedSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.catalogueProducts.wasLoaded,
  wasLoaded => wasLoaded
)

export const catalogueProductsNextSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.catalogueProducts.paging.next,
  next => next
)

// Get category pages
export const categoryPagesSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.categoryPages.results,
  results => results
)

export const categoryPagesIsLoadingSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.categoryPages.isLoading,
  isLoading => isLoading
)

export const categoryPagesErrorSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.categoryPages.error,
  error => error
)

export const categoryPagesWasLoadedSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.categoryPages.wasLoaded,
  wasLoaded => wasLoaded
)

export const categoryPagesNextSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.categoryPages.paging.next,
  next => next
)

// media order
export const mediaOrderDataSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrderData,
  mediaOrderData => mediaOrderData
)
// date range
export const mediaOrderCampaignsDateRangeSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrderCampaignsDateRange,
  mediaOrderCampaignsDateRange => mediaOrderCampaignsDateRange
)

// create category page
export const createCategoryPageIsLoadingSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.createCategoryPage.isLoading,
  isLoading => isLoading
)

export const createCategoryPageErrorSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.createCategoryPage.error,
  error => error
)

export const categoryPageWasCreatedSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.createCategoryPage.wasCreated,
  wasCreated => wasCreated
)

// update category page
export const updateCategoryPageIdSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.updateCategoryPage.id,
  id => id
)

export const updateCategoryPageIsLoadingSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.updateCategoryPage.isLoading,
  isLoading => isLoading
)

export const updateCategoryPageErrorSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.updateCategoryPage.error,
  error => error
)

export const updateCategoryPageWasUpdatedSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.updateCategoryPage.wasUpdated,
  wasUpdated => wasUpdated
)

// Get contract entities
export const contractEntitiesSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.getContractEntities.results,
  results => results
)

export const getContractEntitiesIsLoadingSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.getContractEntities.isLoading,
  isLoading => isLoading
)

export const getContractEntitiesErrorSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.getContractEntities.error,
  error => error
)

export const getContractEntitiesWasLoadedSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.getContractEntities.wasLoaded,
  wasLoaded => wasLoaded
)

// Get media order history
export const mediaOrderHistorySelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrderHistory.results,
  results => results
)

export const getMediaOrderHistoryIsLoadingSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrderHistory.isLoading,
  isLoading => isLoading
)

export const getMediaOrderHistoryErrorSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrderHistory.error,
  error => error
)

export const mediaOrderHistoryWasLoadedSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrderHistory.wasLoaded,
  wasLoaded => wasLoaded
)

export const mediaOrderHistoryNextSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrderHistory.paging.next,
  next => next
)

// Get media order as csv
export const mediaOrderAsCsvSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrderAsCsv.data,
  data => data
)

export const getMediaOrderAsCsvIsLoadingSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrderAsCsv.isLoading,
  isLoading => isLoading
)

export const getMediaOrderAsCsvErrorSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrderAsCsv.error,
  error => error
)

export const mediaOrderAsCsvWasLoadedSelector = createSelector(
  ({ mediaOrders }) => mediaOrders.mediaOrderAsCsv.wasLoaded,
  wasLoaded => wasLoaded
)
