import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import Form from '../../../../components/Form'
import ManageDoohlyAPIKeyFormContent from './ManageDoohlyAPIKeyFormContent'
import { TabletDown, TabletUp } from '../../../../components/hoc/ResponsiveRendering'

import {
  createDoohlyApiKey,
  updateDoohlyApiKey,
  clearCreateDoohlyApiKey,
  clearUpdateDoohlyApiKey
} from '../../../../modules/actions/doohly'

import {
  createDoohlyApiKeyWasCreatedSelector,
  createDoohlyApiKeyIsLoadingSelector,
  createDoohlyApiKeyErrorSelector,
  updateDoohlyApiKeyWasUpdatedSelector,
  updateDoohlyApiKeyErrorSelector,
  updateDoohlyApiKeyIsLoadingSelector,
  doohlyDataSelector
} from '../../../../modules/selectors/doohly'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'

import { MANAGE_DOOHLY_API_KEY } from '../../../../constants/forms'

import { DOOHLY_API_KEY, getInitialValues } from './fields'

import useStyles from '../../EditProfile/styles'

const ManageDoohlyAPIKeyForm = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const classes = useStyles()

  const selectedControllerId = useSelector(selectedControllerIdSelector)
  const doohlyData = useSelector(doohlyDataSelector)
  const apiKeyWasCreated = useSelector(createDoohlyApiKeyWasCreatedSelector)
  const apiKeyWasUpdated = useSelector(updateDoohlyApiKeyWasUpdatedSelector)

  const successSubmit = useMemo(() => {
    return apiKeyWasUpdated || apiKeyWasCreated
  }, [apiKeyWasUpdated, apiKeyWasCreated])

  const loadingSelector = useMemo(() => {
    if (doohlyData.length) {
      return updateDoohlyApiKeyIsLoadingSelector
    } else {
      return createDoohlyApiKeyIsLoadingSelector
    }
  }, [doohlyData])

  const errorSelector = useMemo(() => {
    if (doohlyData.length) {
      return updateDoohlyApiKeyErrorSelector
    } else {
      return createDoohlyApiKeyErrorSelector
    }
  }, [doohlyData])

  const clearUpdateControllerHandler = useCallback(() => {
    if (doohlyData.length) {
      dispatch(clearUpdateDoohlyApiKey())
    } else {
      dispatch(clearCreateDoohlyApiKey())
    }
  }, [dispatch, doohlyData])

  const onSubmit = useCallback(
    values => {
      if (doohlyData.length) {
        dispatch(
          updateDoohlyApiKey(doohlyData[0].id, {
            controller_id: selectedControllerId,
            integration: 'doohly',
            [DOOHLY_API_KEY]: values[DOOHLY_API_KEY]
          })
        )
      } else {
        dispatch(
          createDoohlyApiKey({
            controller_id: selectedControllerId,
            integration: 'doohly',
            [DOOHLY_API_KEY]: values[DOOHLY_API_KEY]
          })
        )
      }
    },
    [dispatch, selectedControllerId, doohlyData]
  )

  const initialValues = useMemo(() => {
    return getInitialValues(doohlyData)
  }, [doohlyData])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit
  })

  return (
    <>
      <TabletUp>
        <Form
          autoComplete="off"
          formName={MANAGE_DOOHLY_API_KEY}
          formik={formik}
          successSubmit={successSubmit}
          errorSelector={errorSelector}
          isLoadingSelector={loadingSelector}
          submitText={t('Save changes')}
          formFooterClassName={classes.formFooter}
          buttonProps={{
            wrapperClassName: classes.submitButtonWrapper
          }}
          clearHandler={clearUpdateControllerHandler}
        >
          <ManageDoohlyAPIKeyFormContent formik={formik} />
        </Form>
      </TabletUp>
      <TabletDown>
        <Form
          formName={MANAGE_DOOHLY_API_KEY}
          formik={formik}
          successSubmit={successSubmit}
          errorSelector={errorSelector}
          isLoadingSelector={loadingSelector}
          submitText={t('Save changes')}
          clearHandler={clearUpdateControllerHandler}
        >
          <ManageDoohlyAPIKeyFormContent formik={formik} />
        </Form>
      </TabletDown>
    </>
  )
}

export default ManageDoohlyAPIKeyForm
