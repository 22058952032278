import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles(theme => ({
  markedRow: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 4,
      height: '100%',
      backgroundColor: markerColor => markerColor || theme.brandPrimary
    }
  }
}))

export default useStyles
