import React, { Fragment } from 'react'

import PublicationDatesFields from './PublicationDatesFields'
import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import FieldsSection from '../../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'
import SelectOrDeselectAllButton from './SelectOrDeselectAllButton'

import useStyles from './styles'

function PeriodStep({ formik, availableProducts }) {
  const classes = useStyles()
  const { values, setFieldValue } = formik

  return (
    <Step
      stepTitle="Select which media products and periods to upload this file to"
      stepDescription="You can apply this file to one or more of your booked media periods at once"
    >
      {availableProducts.map(product => {
        // group booked media by files from the file_requirements array
        const groupedBookedMediaByFile = product.bookedMedia.reduce((acc, media) => {
          media.file_requirements.forEach(file => {
            if (!acc[file.id]) {
              acc[file.id] = {
                id: file.id,
                name: file.file_name,
                bookedMedia: []
              }
            }
            acc[file.id].bookedMedia.push(media)
          })
          return acc
        }, {})

        return (
          <FieldsSection
            title={product.name}
            key={product.id}
            rightColumn={
              <SelectOrDeselectAllButton formik={formik} groupedBookedMediaByFile={groupedBookedMediaByFile} />
            }
          >
            <FieldRow title="Publication dates" description="These are the go-live dates for the booked media">
              {Object.values(groupedBookedMediaByFile).map(file => {
                return (
                  <Fragment key={file.id}>
                    <h3 className={classes.fileName}>{file.name}</h3>
                    <PublicationDatesFields
                      values={values}
                      setFieldValue={setFieldValue}
                      fileId={file.id}
                      bookedMedias={file.bookedMedia}
                    />
                  </Fragment>
                )
              })}
            </FieldRow>
          </FieldsSection>
        )
      })}
    </Step>
  )
}

export default PeriodStep
