// BUDGET
export const BUDGET_OPTION = 'budget_option'
export const BUDGET_LIFETIME = 'budget_lifetime'
export const BUDGET_DAILY = 'budget_daily'
export const CAMPAIGN_BUDGET = 'campaign_budget'
// LANGUAGES
export const LANGUAGES = 'languages'
// START DATE
export const START_DATE = 'date_start'
export const START_TIME = 'start_time'
// STOP DATE
export const STOP_DATE = 'date_stop'
export const STOP_TIME = 'stop_time'
// AGE
export const AGE = 'age'
