import {
  FEE,
  FEE_OPTION,
  getMarginWithFee,
  MARGIN_OPTION,
  SAME_MARGIN,
  GENERAL_MARGIN,
  DIFFERENT_MARGIN,
  MARGINS,
  NO_MARGIN
} from './fields'

import { OPTION_YES } from '../../../constants/forms'

import { keys } from '../../../helpers/common'
import {
  DV_360_PLATFORM,
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  TIKTOK_PLATFORM
} from '../../../constants/selectLists/platformList'

const setEqualPlatformMargin = margin => ({
  // convert margins to decimal when dealing with BE i.e. (70% becomes 0.7)
  [FACEBOOK_PLATFORM]: margin * 0.01,
  [GOOGLE_PLATFORM]: margin * 0.01,
  [TIKTOK_PLATFORM]: margin * 0.01,
  [DV_360_PLATFORM]: margin * 0.01
})

// adjust
const getPlatformsMargins = values => {
  if (values[MARGIN_OPTION] === SAME_MARGIN) {
    // set GENERAL_MARGIN value to each platform margin
    return setEqualPlatformMargin(values[GENERAL_MARGIN])
  } else if (values[MARGIN_OPTION] === DIFFERENT_MARGIN) {
    // all margins are set, now it's needed only to be converted to BE
    // convert margins to decimal when dealing with BE i.e. (70% becomes 0.7)
    const platformsMargins = { ...values[MARGINS] }

    keys(platformsMargins).forEach(key => {
      const margin = platformsMargins[key]
      platformsMargins[key] = margin * 0.01
    })

    return platformsMargins
  } else if (values[MARGIN_OPTION] === NO_MARGIN) {
    // set 0 to each platform margin
    return setEqualPlatformMargin(0)
  } else {
    // all margins are set, now it's needed only to be converted to BE
    // convert margins to decimal when dealing with BE i.e. (70% becomes 0.7)
    const platformsMargins = { ...values[MARGINS] }

    keys(platformsMargins).forEach(key => {
      const margin = platformsMargins[key]
      platformsMargins[key] = margin * 0.01
    })

    return platformsMargins
  }
}

export const calculateMargins = values => {
  const platformsMargins = getPlatformsMargins(values)

  if (values[FEE_OPTION] === OPTION_YES) {
    // convert margins to decimal when dealing with BE i.e. (70% becomes 0.7)
    const fee = parseFloat(values[FEE]) * 0.01

    keys(platformsMargins).forEach(key => {
      platformsMargins[key] = getMarginWithFee(platformsMargins[key], fee)
    })

    return platformsMargins
  } else {
    return platformsMargins
  }
}
