import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Radio from '../Radio'

import { useTranslatedChildren } from '../../../hooks/useTranslatedChildren'

function RadioButton(props) {
  const { name = '', value = '', label = '', setFieldValue, selectedValue = '', ...restProps } = props

  const checked = selectedValue === value

  const translatedLabel = useTranslatedChildren(label)

  const handleOnChange = useCallback(() => {
    setFieldValue(name, value)
  }, [setFieldValue, name, value])

  return (
    <Radio
      name={name}
      value={value}
      checked={checked}
      onChange={handleOnChange}
      label={translatedLabel}
      {...restProps}
    />
  )
}

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  selectedValue: PropTypes.string,
  setFieldValue: PropTypes.func
}

export default RadioButton
