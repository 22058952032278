import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'

import CheckYourInbox from './CheckYourInbox'
import Form from '../../../components/Form'
import UnauthorizedPage from '../UnauthorizedPage'
import Field from '../../../components/Form/Field'
import InfoBlock from '../../../features/components/InfoBlock'

import { useAuthButtonProps } from '../useAuthButtonProps'

import { EMAIL, initialValues, validationSchema } from './fields'

import { resendRegister } from '../../../modules/actions/auth'
import { resendRegisterErrorSelector, resendRegisterLoadingSelector } from '../../../modules/selectors/auth'

import useAuthStyles from '../../../styles/common/authPages'

const ResendRegister = () => {
  const authClasses = useAuthStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [formSubmitted, setFormSubmitted] = useState(false)

  const buttonProps = useAuthButtonProps()

  const onSubmit = values => {
    dispatch(resendRegister(values))
    setFormSubmitted(true)
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const onResendEmail = () => {
    const { email } = formik.values
    dispatch(resendRegister({ email }, true))
  }

  return (
    <UnauthorizedPage helmetTitle="helmetTitle.ResendRegisterPage" className={authClasses.authPage}>
      {formSubmitted ? (
        <CheckYourInbox onResendEmail={onResendEmail} email={formik.values.email} />
      ) : (
        <>
          <InfoBlock title={t('Sorry, that link has expired')}>
            {t('Fear not, we can resend you a new one. Please enter the email address that you registered with:')}
          </InfoBlock>
          <div className={authClasses.formContainer}>
            <Form
              formName="resendRegister"
              formik={formik}
              method="post"
              successSubmit={formSubmitted}
              errorSelector={resendRegisterErrorSelector}
              isLoadingSelector={resendRegisterLoadingSelector}
              submitText={t('Send New Link')}
              buttonProps={buttonProps}
            >
              <Field formik={formik} id={EMAIL} name={EMAIL} placeholder="Registered Email" />
            </Form>
          </div>
        </>
      )}
    </UnauthorizedPage>
  )
}

export default ResendRegister
