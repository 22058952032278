import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useStyles from './styles'

function AdCardListItem({ children, className }) {
  const classes = useStyles()

  return <div className={classnames(classes.cardListItem, className)}>{children}</div>
}

AdCardListItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string
}

export default AdCardListItem
