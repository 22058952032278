import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { ReactComponent as OrderIcon } from '../../../../assets/icons/orders.svg'

import { formatDateFullYear } from '../../../../constants/dates'

import useStyles from './styles'
import useCommonStyles from '../../../../styles/common/table'

const MediaOrderNameCell = ({ mediaOrder = {}, onClick, showIcon = true, infoTextClassName }) => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const { name, start_date: startDate, end_date: endDate, id } = mediaOrder

  const handleClick = useCallback(() => {
    onClick && onClick(id)
  }, [onClick, id])

  return (
    <div onClick={handleClick} className={classes.nameField}>
      {showIcon && <OrderIcon className={classes.Icon} />}
      <div className={classes.texts}>
        <div className={commonClasses.mainText}>{name}</div>
        <div className={classnames(commonClasses.infoText, infoTextClassName)}>
          {startDate ? formatDateFullYear(startDate) : ''}
          {startDate && endDate ? ' - ' : null}
          {endDate ? formatDateFullYear(endDate) : ''}
        </div>
      </div>
    </div>
  )
}

MediaOrderNameCell.propTypes = {
  mediaOrder: PropTypes.shape({
    name: PropTypes.string.isRequired,
    start_date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    end_date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    id: PropTypes.number.isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  showIcon: PropTypes.bool,
  infoTextClassName: PropTypes.string
}

export default MediaOrderNameCell
