import React from 'react'
import { components } from 'react-select'

import { ReactComponent as CrossIcon } from '../../../assets/icons/close-grey.svg'
import { useStyles } from '../styles'

const SelectIndicatorsContainer = props => {
  const { showClearInputIcon, onClearInput } = props.selectProps

  const classes = useStyles()

  // added onTouchEnd for custom icon, because item is not clickable on mobile due to known library issue
  return (
    <div className={classes.indicatorsWrapper}>
      {showClearInputIcon && (
        <CrossIcon className={classes.clearInputIcon} onClick={onClearInput} onTouchEnd={onClearInput} />
      )}
      <components.IndicatorsContainer {...props} />
    </div>
  )
}

export default SelectIndicatorsContainer
