import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import Field from '../../../../../../../../../components/Form/Field'
import { MediaOrderFormContext } from '../../../../../../MediaOrderFormContext'

import { calculateDiscountPercentage } from '../../../DiscountCell/helpers'
import {
  calculateDiscountedPrice,
  getDecimalDiscount
} from '../../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/price'
import { formatCurrency } from '../../../../../../../../../helpers/numbers'

import { CUSTOM_ORDER_DISCOUNT, CUSTOM_ORDER_PRICE } from '../../../../../fields'

import useStyles from '../../styles'
import { discountFieldValueValidator } from '../../../../../../../../../helpers/fieldValidation'

const EditTotals = ({
  formik,
  originalTotalPrice,
  calculatedTotalCost,
  calculatedDiscount,
  wasCustomTotalPriceSet,
  wasDiscountSet
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { values, setFieldValue } = formik

  const { currency } = useContext(MediaOrderFormContext)
  const currencySymbol = currency?.symbol

  const discount = getDecimalDiscount(values[CUSTOM_ORDER_DISCOUNT])

  useEffect(() => {
    setFieldValue(CUSTOM_ORDER_PRICE, calculatedTotalCost)
    setFieldValue(CUSTOM_ORDER_DISCOUNT, calculatedDiscount)
  }, [calculatedDiscount, calculatedTotalCost, setFieldValue])

  return (
    <>
      <div className={classes.totalFieldContainer}>
        <h5 className={classes.totalTitle}>{t('Discount:')}</h5>{' '}
        {/* only one of the fields could be filled at the same time*/}
        {wasCustomTotalPriceSet ? (
          <div>
            {/* find the discount percentage amount between calculatedPrice and originalPrice:*/}
            {calculateDiscountPercentage(originalTotalPrice, values[CUSTOM_ORDER_PRICE])}%
          </div>
        ) : (
          <Field
            type="number"
            inputMode="decimal"
            name={CUSTOM_ORDER_DISCOUNT}
            formik={formik}
            placeholder="0"
            symbol="%"
            symbolPosition="end"
            // A checker function to validate the input value. If this function returns false, the onChange method will not get triggered and the input value will not change.
            isAllowed={discountFieldValueValidator}
            decimalScale={2}
            enableReinitialize={true}
            className={classes.discountField}
            inputClassName={classes.totalInput}
          />
        )}
      </div>
      <div className={classnames(classes.totalFieldContainer, classes.totalCostContainer)}>
        <h5 className={classes.totalTitle}>{t('Total Cost:')}</h5>{' '}
        {/*only one of the fields could be filled at the same time*/}
        {wasDiscountSet ? (
          <div>
            {/* calculate the price according to the discount
             originalTotalPrice is already calculated with correct quantity, so for calculation set quantity as 1 */}
            {formatCurrency(
              calculateDiscountedPrice(originalTotalPrice, 1, discount),
              {
                min: 2,
                max: 2
              },
              { symbol: currencySymbol }
            )}
          </div>
        ) : (
          <Field
            type="number"
            inputMode="decimal"
            name={CUSTOM_ORDER_PRICE}
            formik={formik}
            placeholder="0"
            autoComplete={'off'}
            symbol={currencySymbol}
            symbolPosition="end"
            decimalScale={2}
            enableReinitialize={true}
            className={classes.priceField}
            inputClassName={classes.totalInput}
          />
        )}
      </div>
    </>
  )
}

EditTotals.propTypes = {
  formik: PropTypes.object,
  originalTotalPrice: PropTypes.number,
  calculatedTotalCost: PropTypes.number,
  calculatedDiscount: PropTypes.number,
  wasCustomTotalPriceSet: PropTypes.bool,
  wasDiscountSet: PropTypes.bool
}

export default EditTotals
