import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Drawer from '../../../../components/Drawer'
import MediaOrderHistoryContent from './MediaOrderHistoryContent'

import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'

import { mediaOrderSelector } from '../../../../modules/selectors/mediaOrders'

import { MEDIA_ORDER_HISTORY } from '../../../../constants/forms'

const MediaOrderHistory = () => {
  const { t } = useTranslation()

  const { name: mediaOrderName } = useSelector(mediaOrderSelector)

  const { isFormOpen, handleFormClose } = useManageFormsDrawer({
    formName: MEDIA_ORDER_HISTORY
  })

  return (
    <Drawer title={t('changeHistoryFor', { mediaOrderName })} isOpen={isFormOpen} onClose={handleFormClose}>
      <MediaOrderHistoryContent />
    </Drawer>
  )
}

export default MediaOrderHistory
