import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { formatDateShort } from '../../../../../../constants/dates'

import { campaignSelector } from '../../../../../../modules/selectors/campaigns'

import { START_DATE, STOP_DATE } from '../../../CampaignGoogleCreate/CampaignGoogleCreateForm/fields'

const DatesSectionPreview = () => {
  const { t } = useTranslation()

  const { [START_DATE]: startDate, [STOP_DATE]: stopDate } = useSelector(campaignSelector)

  const formattedStartDate = useMemo(() => startDate && formatDateShort(startDate), [startDate])
  const formattedStopDate = useMemo(() => (stopDate ? formatDateShort(stopDate) : t('Ongoing')), [stopDate, t])

  return (
    <p>
      {formattedStartDate}
      {formattedStartDate && formattedStopDate ? ' - ' : null}
      {formattedStopDate}
    </p>
  )
}

export default DatesSectionPreview
