import React from 'react'
import { components } from 'react-select'

import MultiLevelSelectOption from './MultiLevelSelectOption'

import useStyles from './styles'

const MultiLevelSelectMenuList = props => {
  const classes = useStyles()

  const { options, selectProps, isLoading, children } = props

  const { optionsState, onOptionSelect, onOptionDeselect, onOptionExpand, isSearchApplied, searchOptions } = selectProps

  return (
    <components.MenuList {...props}>
      {options && options.length && !isLoading ? (
        <div className={classes.optionsWrapper}>
          {options.map(option => (
            <MultiLevelSelectOption
              key={option.id}
              option={option}
              optionsState={optionsState}
              isSearchApplied={isSearchApplied}
              searchOptions={searchOptions}
              onOptionSelect={onOptionSelect}
              onOptionDeselect={onOptionDeselect}
              onOptionExpand={onOptionExpand}
            />
          ))}
        </div>
      ) : (
        children
      )}
    </components.MenuList>
  )
}

export default MultiLevelSelectMenuList
