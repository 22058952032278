import React from 'react'
import PropTypes from 'prop-types'
import { Draggable } from 'react-beautiful-dnd'
import { useTheme } from 'react-jss'

const DraggableItemWrapper = React.memo(({ id, index, children, className, isInDrawer = false }) => {
  const theme = useTheme()

  return (
    <Draggable key={id} draggableId={`${id}`} index={index} cl>
      {(provided, snapshot) => {
        const style = {
          ...provided.draggableProps.style,
          border: snapshot.isDragging && `1px solid ${theme.brandPrimary}`,
          backgroundColor: snapshot.isDragging && 'white',
          // If DragAndDropWrapper is in drawer, we need to set left and top to 0 to prevent the bug with disappearing dragged item
          ...(isInDrawer && { left: '0 !important', top: '0 !important' })
        }

        return (
          <div
            className={className}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={style}
          >
            {children}
          </div>
        )
      }}
    </Draggable>
  )
})

DraggableItemWrapper.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  index: PropTypes.number,
  className: PropTypes.string
}

export default DraggableItemWrapper
