export const SHOW_OPTION = 'show_option'
export const SHOW_OPTION_COUNTRIES = 'show_option_countries'
export const SHOW_OPTION_REGIONS = 'show_option_regions'
export const SHOW_OPTION_LOCATION_LIST = 'show_option_location_list'
export const SHOW_OPTION_WORLDWIDE = 'show_option_worldwide'
export const SHOW_COUNTRIES = 'show_countries'
export const SHOW_COUNTRY_CHOICE = 'show_country_choice'
export const SHOW_REGIONS = 'show_regions'

export const LOCATIONS_SELECTED_TAB = 'locationsSelectedTab'
export const LOCATIONS_TAB = 'locationsTab'
export const LOCATIONS_LIST_TAB = 'locationsListTab'
export const TARGET_BY_REGION = 'targetByRegion'

export const locationsTabs = [
  {
    value: LOCATIONS_TAB,
    label: 'Locations'
  },
  {
    value: LOCATIONS_LIST_TAB,
    label: 'Location list'
  }
]

// GEO LOCATION
export const LOCATION_LIST = 'location_list'

export const initialGeolocationsValues = {
  [SHOW_OPTION]: SHOW_OPTION_COUNTRIES,
  [SHOW_COUNTRIES]: [],
  [SHOW_COUNTRY_CHOICE]: '',
  [SHOW_REGIONS]: [],
  [LOCATION_LIST]: ''
}
