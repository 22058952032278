import { call, put, all, takeEvery } from 'redux-saga/effects'

import {
  APPLY_MEDIA_ORDER_SUGGESTED_CHANGES,
  applyMediaOrderSuggestedChangesFailure,
  applyMediaOrderSuggestedChangesSuccess,
  GET_MEDIA_ORDER_SUGGESTED_CHANGES,
  GET_OPTIMISATION_HISTORY,
  getMediaOrderSuggestedChangesFailure,
  getMediaOrderSuggestedChangesSuccess,
  getOptimisationHistoryFailure,
  getOptimisationHistorySuccess
} from '../actions/mediaOrderOptimisations'
import {
  applyMediaOrderSuggestedChangesService,
  getMediaOrderSuggestedChangesService,
  getOptimisationHistoryService
} from '../services/mediaOrderOptimisations'

function* mediaOrderOptimisationsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(APPLY_MEDIA_ORDER_SUGGESTED_CHANGES, applyMediaOrderSuggestedChangesWorker),
    takeEvery(GET_MEDIA_ORDER_SUGGESTED_CHANGES, getMediaOrderSuggestedChangesWorker),
    takeEvery(GET_OPTIMISATION_HISTORY, getOptimisationHistoryWorker)
  ])
}

function* getMediaOrderSuggestedChangesWorker({ id }) {
  try {
    const response = yield call(getMediaOrderSuggestedChangesService, id)
    yield put(getMediaOrderSuggestedChangesSuccess(response))
  } catch (error) {
    yield put(getMediaOrderSuggestedChangesFailure(error))
  }
}

function* applyMediaOrderSuggestedChangesWorker({ id }) {
  try {
    const response = yield call(applyMediaOrderSuggestedChangesService, id)
    yield put(applyMediaOrderSuggestedChangesSuccess(response))
  } catch (error) {
    yield put(applyMediaOrderSuggestedChangesFailure(error))
  }
}

function* getOptimisationHistoryWorker({ params }) {
  try {
    const response = yield call(getOptimisationHistoryService, params)
    yield put(getOptimisationHistorySuccess(response))
  } catch (error) {
    yield put(getOptimisationHistoryFailure(error))
  }
}

export default mediaOrderOptimisationsWatcher
