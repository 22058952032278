export const formatProductsFiltersToRequestParams = filters => {
  const {
    selectedCategories,
    selectedSubCategories,
    selectedSubSubCategories,
    selectedLocations,
    selectedTag,
    searchTerm
  } = filters

  return {
    ...(searchTerm && { search: searchTerm }),
    ...(selectedTag && { tag: selectedTag }),
    ...(selectedLocations.length && { location: selectedLocations }),
    ...(selectedCategories.length && { media_category: selectedCategories }),
    ...(selectedSubCategories.length && { media_sub_category: selectedSubCategories }),
    ...(selectedSubSubCategories.length && { media_sub_sub_category: selectedSubSubCategories })
  }
}
