import { madeRequest } from '../../helpers/api'
import { replaceRouteParams } from '../../helpers/url'

import { ENDPOINT_PLATFORM, ENDPOINTS } from '../../constants/api'

// PLOP_APPEND_PATTERN_ANCHOR

export async function getAdsService(params, platform) {
  return madeRequest('GET', {
    params,
    url: replaceRouteParams(ENDPOINTS.ads, { platform: ENDPOINT_PLATFORM[platform] })
  })
}

export async function getAdService({ id, ...params }, platform) {
  return madeRequest('GET', {
    params,
    url: replaceRouteParams(ENDPOINTS.ad, {
      id,
      platform: ENDPOINT_PLATFORM[platform]
    })
  })
}

export async function createAdService(adData, platform, showErrorToast = true) {
  return madeRequest('POST', {
    data: adData,
    url: replaceRouteParams(ENDPOINTS.ads, {
      platform: ENDPOINT_PLATFORM[platform]
    }),
    showErrorToast
  })
}

export async function createAdsBatchService(adsData, platform) {
  return madeRequest('POST', {
    data: adsData,
    url: replaceRouteParams(`${ENDPOINTS.ads}batch_create/`, {
      platform: ENDPOINT_PLATFORM[platform]
    })
  })
}

export function updateAdService(adData, id, requestMethod = 'PATCH', platform) {
  return madeRequest(requestMethod, {
    data: adData,
    url: replaceRouteParams(ENDPOINTS.ad, {
      id,
      platform: ENDPOINT_PLATFORM[platform]
    })
  })
}

export function deleteAdService(id, params, platform) {
  return madeRequest('DELETE', {
    params,
    url: replaceRouteParams(ENDPOINTS.ad, {
      id,
      platform: ENDPOINT_PLATFORM[platform]
    })
  })
}

export function copyAdService(data, adId, platform) {
  return madeRequest('POST', {
    data,
    url: replaceRouteParams(ENDPOINTS.copyAd, {
      id: adId,
      platform: ENDPOINT_PLATFORM[platform]
    })
  })
}

export function approveInternalAdService(id) {
  return madeRequest('POST', {
    url: `${ENDPOINTS.internalAds}${id}/approve/`
  })
}

export function disapproveInternalAdService(id, data) {
  return madeRequest('POST', {
    data,
    url: `${ENDPOINTS.internalAds}${id}/disapprove/`
  })
}

export function deleteInternalAdService(id) {
  return madeRequest('DELETE', {
    url: `${ENDPOINTS.internalAds}${id}/`
  })
}

export function getInternalAdsItemsCountService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.internalAdsCount
  })
}
