import { createStyles } from '../../../styles/helpers'

import colors from '../colors'

export interface StyleClasses {
  subtitle: string
}

export default createStyles({
  subtitle: {
    fontSize: 20,
    fontWeight: 400,
    color: colors.black,
    textAlign: 'center',
    margin: 0,
    marginBottom: 4,
    '@media screen and (max-width: 699px)': {
      fontSize: 12
    },
    '@media screen and (min-width: 700px)': {
      fontSize: 18
    }
  }
})
