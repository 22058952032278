import React from 'react'
import { useSelector } from 'react-redux'

import FileDetailsPreview from '../../../../../../components/FileDetailsPreview'

import { adSelector } from '../../../../../../modules/selectors/ads'

import { VIDEO } from '../../../fields'

const VideoSectionPreview = () => {
  const ad = useSelector(adSelector)

  const { [VIDEO]: video = {} } = ad

  return <FileDetailsPreview fileName={video.file_name} fileUrl={video['poster_url']} filePreviewType={'image'} />
}

export default VideoSectionPreview
