import PropTypes from 'prop-types'
import { useCallback, useEffect } from 'react'

import AdProductForm from '../../ReusableForms/AdProductForm'
import ProximityLineItemForm from './ProximityLineItemForm'

import useContinuousForm from '../../../../../../hooks/formHooks/useContinuousForm'

import { AD_PRODUCT_FORM } from '../ProductRetargetingRoute'
import { LEARN_MORE } from '../../../../../ReusableFormFields/CallToActionFields/fields'

export const LINE_ITEM_PROXIMITY_FORM = 'line_item_proximity_form'

const ProximityCampaignRoute = ({
  adAccountId,
  isExistingCampaignFlow,
  onLineItemOptionChange,
  onSuccessAdFormSubmit,
  activatedInternalForm,
  setActivatedInternalForm
}) => {
  const { activatedForm, setActivatedForm } = useContinuousForm({
    initialForm: LINE_ITEM_PROXIMITY_FORM
  })

  const handleSetActivatedForm = useCallback(
    formName => {
      setActivatedForm(formName)
      // set internal selected form for progress representation
      setActivatedInternalForm(formName)
    },
    [setActivatedForm, setActivatedInternalForm]
  )

  const lineItemSuccessSubmitHandler = useCallback(() => {
    handleSetActivatedForm(AD_PRODUCT_FORM)
  }, [handleSetActivatedForm])

  useEffect(() => {
    if (!activatedInternalForm) {
      handleSetActivatedForm(LINE_ITEM_PROXIMITY_FORM)
    }
  }, [activatedInternalForm, handleSetActivatedForm])

  switch (activatedForm) {
    case AD_PRODUCT_FORM:
      return (
        <AdProductForm
          onSuccessSubmit={onSuccessAdFormSubmit}
          activeStepNumber={3}
          customStepsLength={4}
          adAccountId={adAccountId}
          showNameField={true}
          showCustomClickThroughLink={true}
          callToAction={LEARN_MORE}
        />
      )
    case LINE_ITEM_PROXIMITY_FORM:
    default:
      return (
        <ProximityLineItemForm
          adAccountId={adAccountId}
          isExistingCampaignFlow={isExistingCampaignFlow}
          onLineItemOptionChange={onLineItemOptionChange}
          onSuccessSubmit={lineItemSuccessSubmitHandler}
        />
      )
  }
}

ProximityCampaignRoute.propTypes = {
  adAccountId: PropTypes.string.isRequired,
  onSuccessAdFormSubmit: PropTypes.func.isRequired,
  activatedInternalForm: PropTypes.string,
  setActivatedInternalForm: PropTypes.func.isRequired,
  isExistingCampaignFlow: PropTypes.bool,
  onLineItemOptionChange: PropTypes.func.isRequired
}

export default ProximityCampaignRoute
