import { getNumberOfDays } from '../../helpers/date'

import {
  DV_360_PROVIDER_PLATFORM,
  FACEBOOK_PROVIDER_PLATFORM,
  GOOGLE_PROVIDER_PLATFORM,
  TIKTOK_PROVIDER_PLATFORM
} from '../../constants/selectLists/platformList'
import {
  BUDGET_DAILY,
  BUDGET_LIFETIME,
  BUDGET_LIFETIME_LINE_ITEM
} from '../../forms/Multiplatform/CampaignForms/fields'

export const getTotalBudgetInMediaOrder = combinedCampaigns => {
  const facebookCampaigns = combinedCampaigns.filter(campaign => campaign.provider === FACEBOOK_PROVIDER_PLATFORM)
  const googleCampaigns = combinedCampaigns.filter(campaign => campaign.provider === GOOGLE_PROVIDER_PLATFORM)

  const tikTokCampaigns = combinedCampaigns.filter(campaign => campaign.provider === TIKTOK_PROVIDER_PLATFORM)

  const dv360Campaigns = combinedCampaigns.filter(campaign => campaign.provider === DV_360_PROVIDER_PLATFORM)

  const totalFacebookCampaignsBudget = facebookCampaigns.reduce((partialSum, campaign) => {
    const budget = campaign[BUDGET_LIFETIME] || campaign[BUDGET_LIFETIME_LINE_ITEM] || 0
    return partialSum + Number(budget)
  }, 0)

  const totalGoogleCampaignsBudget = googleCampaigns
    .filter(item => item.status !== 'deleted')
    .reduce((partialSum, campaign) => {
      const dateStart = campaign.date_start
      const dateStop = campaign.date_stop
      // Newly created campaign has different budget field in redux store:
      // campaign.campaign_budget.amount_micros
      const budgetDaily = campaign[BUDGET_DAILY] || campaign?.campaign_budget?.amount_micros || 0

      const days = getNumberOfDays(dateStart, dateStop)

      let budgetLifetime = 0

      if (budgetDaily && days) {
        budgetLifetime = days * budgetDaily
      }

      return partialSum + Number(budgetLifetime)
    }, 0)

  const totalTikTokCampaignsBudget = tikTokCampaigns.reduce((partialSum, campaign) => {
    const budget = campaign[BUDGET_LIFETIME] || campaign[BUDGET_LIFETIME_LINE_ITEM] || 0
    return partialSum + Number(budget)
  }, 0)

  const totalDV360CampaignsBudget = dv360Campaigns.reduce((partialSum, campaign) => {
    const budget = campaign[BUDGET_LIFETIME] || 0
    return partialSum + Number(budget)
  }, 0)

  return (
    totalFacebookCampaignsBudget + totalGoogleCampaignsBudget + totalTikTokCampaignsBudget + totalDV360CampaignsBudget
  )
}
