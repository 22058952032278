import React, { useCallback, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ProgressButton from '../../../../../components/Form/ProgressButton'

import { downloadFileByBrowser } from '../../../../../helpers/other'

import {
  getMediaOrderAsCsvErrorSelector,
  getMediaOrderAsCsvIsLoadingSelector,
  mediaOrderAsCsvSelector,
  mediaOrderAsCsvWasLoadedSelector
} from '../../../../../modules/selectors/mediaOrders'
import { clearGetMediaOrderAsCsv, getMediaOrderAsCsv } from '../../../../../modules/actions/mediaOrders'

import { BookingsFiltersContext } from '../../../Bookings/BookingsFilters/BookingsFiltersContext'

import useStyles from './styles'

const MediaOrderDownloadCSV = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const isLoading = useSelector(getMediaOrderAsCsvIsLoadingSelector)
  const error = useSelector(getMediaOrderAsCsvErrorSelector)
  const wasLoaded = useSelector(mediaOrderAsCsvWasLoadedSelector)
  const csvData = useSelector(mediaOrderAsCsvSelector)

  const csvUrl = csvData?.download_url

  const { filterRequestParams } = useContext(BookingsFiltersContext)

  const clearHandler = useCallback(() => {
    dispatch(clearGetMediaOrderAsCsv())
  }, [dispatch])

  const handleOnClick = useCallback(() => {
    dispatch(getMediaOrderAsCsv(filterRequestParams))
  }, [dispatch, filterRequestParams])

  const handleDownloadCSVFile = useCallback(() => {
    downloadFileByBrowser({
      url: csvUrl,
      fileName: 'media_order',
      fileFormat: 'csv'
    })
  }, [csvUrl])

  useEffect(() => {
    // clear data on page close, to cancel download task in case it started
    return clearHandler
  }, [clearHandler])

  return (
    <ProgressButton
      onClick={handleOnClick}
      isFormLoading={isLoading}
      formError={error}
      successSubmit={wasLoaded}
      clearHandler={clearHandler}
      onSuccessSubmit={handleDownloadCSVFile}
      wrapperClassName={classes.exportButtonWrapper}
    >
      {t('Export as CSV')}
    </ProgressButton>
  )
}

export default MediaOrderDownloadCSV
