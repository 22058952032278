import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { lineItemSelector } from '../../../../../../modules/selectors/lineItems'

import { formatDateShort } from '../../../../../../constants/dates'

const DatesSectionPreview = () => {
  const { t } = useTranslation()

  const lineItem = useSelector(lineItemSelector)

  const { schedule_start_time: startDate, schedule_end_time: stopDate } = lineItem

  const formattedStartDate = useMemo(() => startDate && formatDateShort(startDate), [startDate])
  const formattedStopDate = useMemo(() => (stopDate ? formatDateShort(stopDate) : t('Ongoing')), [stopDate, t])

  return (
    <p>
      {formattedStartDate}
      {formattedStartDate && formattedStopDate ? ' - ' : null}
      {formattedStopDate}
    </p>
  )
}

export default DatesSectionPreview
