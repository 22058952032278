import { v4 as uuidv4 } from 'uuid'

import { IMAGES } from '../../../../../features/components/Form/SortableUploadImagesList/fields'
import {
  ADDRESS,
  ALT_TEXT,
  BOOKING_NOTIFICATION_RECIPIENTS,
  EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS,
  initialExternalRecipientValue,
  initialStoreValue,
  INTERNAL_ID,
  LAST_NAME,
  NAME,
  SEND_BOOKING_SUMMARY_NOTIFICATION,
  STORES,
  SUMMARY_DAYS_ADVANCE,
  SUMMARY_INTERVAL,
  summaryIntervals,
  WEEK_M,
  WEEK_S
} from '../../fields'
import { FILE_URL } from '../../../../ReusableFormFields/AdFileUpload/fields'

export const getInitialValues = ({ editProductLocationData }) => {
  const hasExternalNotifications = !!editProductLocationData[EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS]?.length

  return {
    [NAME]: editProductLocationData[NAME] || '',
    [ADDRESS]: editProductLocationData[ADDRESS] || '',
    [IMAGES]: editProductLocationData[IMAGES]?.length
      ? [
          ...editProductLocationData[IMAGES].map(image => ({
            ...image,
            [FILE_URL]: image.image
          })),
          // add empty image to list
          {
            id: uuidv4(),
            [ALT_TEXT]: '',
            [FILE_URL]: ''
          }
        ]
      : [{ id: uuidv4(), [ALT_TEXT]: '' }],
    [BOOKING_NOTIFICATION_RECIPIENTS]:
      editProductLocationData[BOOKING_NOTIFICATION_RECIPIENTS]?.map(item => ({
        label: `${item[LAST_NAME]} ${item[LAST_NAME]}`,
        value: item.id
      })) || [],
    [EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS]: hasExternalNotifications
      ? editProductLocationData[EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS]
      : [initialExternalRecipientValue],
    [SEND_BOOKING_SUMMARY_NOTIFICATION]: Boolean(editProductLocationData[SUMMARY_INTERVAL]) || false,
    [SUMMARY_INTERVAL]:
      editProductLocationData[SUMMARY_INTERVAL] === WEEK_S || editProductLocationData[SUMMARY_INTERVAL] === WEEK_M
        ? summaryIntervals.WEEK
        : editProductLocationData[SUMMARY_INTERVAL],
    [SUMMARY_DAYS_ADVANCE]: String(editProductLocationData[SUMMARY_DAYS_ADVANCE]) || '0',
    [STORES]: editProductLocationData[STORES]?.length ? editProductLocationData[STORES] : [initialStoreValue],
    [INTERNAL_ID]: editProductLocationData[INTERNAL_ID] || ''
  }
}
