import { createStyles } from '../../styles/helpers'

const useStyles = createStyles({
  socialAuthPage: {
    textAlign: 'center',
    paddingTop: 48,
    maxWidth: 800,

    '& a': {
      textDecoration: 'none'
    }
  },
  title: {
    fontSize: 24,
    marginTop: 35,
    marginBottom: 16
  }
})

export default useStyles
