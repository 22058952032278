import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import FilePreview from './FilePreview'
import ButtonClose from '../../features/components/Buttons/ButtonClose'
import ProgressBar from '../ProgressBar'

import useStyles from './styles'

function FileDetailsPreview({
  className,
  removeFile,
  fileName,
  filePreviewType,
  previewUrl,
  fileFormat,
  isFileLoading,
  allowDelete,
  fileUploadProgress,
  isImagePreview,
  onClick
}) {
  const classes = useStyles({ onClick })

  const handleRemoveFile = e => {
    e.stopPropagation() // avoid firing onClick event during delete
    removeFile && removeFile(e)
  }

  return (
    <div className={classnames(className, classes.fileDetails)} onClick={onClick}>
      <div className={classes.uploadedContainer}>
        <FilePreview
          fileName={fileName}
          filePreviewType={filePreviewType}
          previewUrl={previewUrl}
          fileFormat={fileFormat}
          isImagePreview={isImagePreview}
        />
        {allowDelete && <ButtonClose className={classes.deleteBtn} onClick={handleRemoveFile} />}
      </div>
      {isFileLoading && <ProgressBar className={classes.progressBar} value={fileUploadProgress || 0} />}
    </div>
  )
}

FileDetailsPreview.propTypes = {
  onClick: PropTypes.func,
  removeFile: PropTypes.func,
  fileName: PropTypes.string,
  filePreviewType: PropTypes.oneOf(['image', 'video']),
  previewUrl: PropTypes.string,
  fileFormat: PropTypes.string,
  isFileLoading: PropTypes.bool,
  allowDelete: PropTypes.bool,
  fileUploadProgress: PropTypes.number
}

export default FileDetailsPreview
