import update from 'immutability-helper'

import {
  INITIAL_CREATE_REDUCER,
  INITIAL_DELETE_REDUCER,
  INITIAL_GET_REDUCER,
  INITIAL_GET_RESULTS_REDUCER,
  INITIAL_UPDATE_REDUCER
} from '../../constants/reducer'
import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  CREATE_ASSET,
  CREATE_ASSET_SUCCESS,
  CREATE_ASSET_FAILURE,
  CLEAR_CREATE_ASSET,
  GET_ASSETS,
  GET_ASSETS_SUCCESS,
  GET_ASSETS_FAILURE,
  CLEAR_ASSETS,
  CREATE_ASSETS_BATCH,
  CREATE_ASSETS_BATCH_SUCCESS,
  CREATE_ASSETS_BATCH_FAILURE,
  CLEAR_CREATE_ASSETS_BATCH,
  CREATE_ASSET_GROUP,
  CREATE_ASSET_GROUP_SUCCESS,
  CREATE_ASSET_GROUP_FAILURE,
  CLEAR_CREATE_ASSET_GROUP,
  GET_ASSET_GROUPS,
  GET_ASSET_GROUPS_SUCCESS,
  GET_ASSET_GROUPS_FAILURE,
  CLEAR_ASSET_GROUPS,
  GET_ASSET_GROUP,
  GET_ASSET_GROUP_SUCCESS,
  GET_ASSET_GROUP_FAILURE,
  CLEAR_ASSET_GROUP,
  UPDATE_ASSET_GROUP,
  UPDATE_ASSET_GROUP_SUCCESS,
  UPDATE_ASSET_GROUP_FAILURE,
  CLEAR_UPDATE_ASSET_GROUP,
  APPROVE_INTERNAL_ASSET_GROUP,
  APPROVE_INTERNAL_ASSET_GROUP_SUCCESS,
  APPROVE_INTERNAL_ASSET_GROUP_FAILURE,
  CLEAR_APPROVE_INTERNAL_ASSET_GROUP,
  DISAPPROVE_INTERNAL_ASSET_GROUP,
  DISAPPROVE_INTERNAL_ASSET_GROUP_SUCCESS,
  DISAPPROVE_INTERNAL_ASSET_GROUP_FAILURE,
  CLEAR_DISAPPROVE_INTERNAL_ASSET_GROUP,
  DELETE_INTERNAL_ASSET_GROUP,
  DELETE_INTERNAL_ASSET_GROUP_SUCCESS,
  DELETE_INTERNAL_ASSET_GROUP_FAILURE,
  CLEAR_DELETE_INTERNAL_ASSET_GROUP,
  CREATE_ASSET_GROUP_SIGNAL,
  CREATE_ASSET_GROUP_SIGNAL_SUCCESS,
  CREATE_ASSET_GROUP_SIGNAL_FAILURE,
  CLEAR_CREATE_ASSET_GROUP_SIGNAL
} from '../actions/assets'

import { updateItem, deleteItemById } from '../../helpers/modules/reducerHelpers'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  assetGroups: {
    ...INITIAL_GET_RESULTS_REDUCER,
    next_page_token: ''
  },
  assetGroup: INITIAL_GET_REDUCER,
  assets: {
    ...INITIAL_GET_REDUCER,
    data: {
      results: [],
      next_page_token: ''
    }
  },
  createdAsset: INITIAL_CREATE_REDUCER,
  createdAssets: INITIAL_CREATE_REDUCER,
  createdAssetGroup: INITIAL_CREATE_REDUCER,
  updatedAssetGroup: INITIAL_UPDATE_REDUCER,
  approveInternalAssetGroup: INITIAL_UPDATE_REDUCER,
  disapproveInternalAssetGroup: INITIAL_UPDATE_REDUCER,
  deleteInternalAssetGroup: INITIAL_DELETE_REDUCER,
  createAssetGroupSignal: INITIAL_CREATE_REDUCER
}

export default function assets(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // create asset
    case CREATE_ASSET:
      return update(state, {
        createdAsset: {
          $merge: {
            isLoading: true
          }
        }
      })
    case CREATE_ASSET_SUCCESS:
      return update(state, {
        createdAsset: {
          $merge: {
            isLoading: false,
            wasCreated: true
          }
        }
      })
    case CREATE_ASSET_FAILURE:
      return update(state, {
        createdAsset: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_ASSET: {
      return update(state, {
        createdAsset: { $set: initialState.createdAsset }
      })
    }

    // get assets
    case GET_ASSETS:
      return update(state, {
        assets: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_ASSETS_SUCCESS:
      return update(state, {
        assets: {
          data: {
            results: {
              $push: action.assetsData.results
            },
            next_page_token: {
              $set: action.assetsData.next_page_token
            }
          },
          $merge: {
            isLoading: false,
            wasLoaded: true
          }
        }
      })
    case GET_ASSETS_FAILURE:
      return update(state, {
        assets: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_ASSETS:
      return update(state, {
        assets: { $set: initialState.assets }
      })

    // create multiple assets
    case CREATE_ASSETS_BATCH:
      return update(state, {
        createdAssets: {
          $merge: {
            isLoading: true
          }
        }
      })
    case CREATE_ASSETS_BATCH_SUCCESS:
      return update(state, {
        createdAssets: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.assetsData
          }
        }
      })
    case CREATE_ASSETS_BATCH_FAILURE:
      return update(state, {
        createdAssets: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_ASSETS_BATCH: {
      return update(state, {
        createdAssets: { $set: initialState.createdAssets }
      })
    }

    // create ad
    case CREATE_ASSET_GROUP:
      return update(state, {
        createdAssetGroup: {
          $merge: {
            isLoading: true
          }
        }
      })
    case CREATE_ASSET_GROUP_SUCCESS:
      return update(state, {
        createdAssetGroup: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.assetGroupData
          }
        },
        assetGroups: {
          results: {
            $unshift: [action.assetGroupData]
          }
        }
      })
    case CREATE_ASSET_GROUP_FAILURE:
      return update(state, {
        createdAssetGroup: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_ASSET_GROUP: {
      return update(state, {
        createdAssetGroup: { $set: initialState.createdAssetGroup }
      })
    }

    // get assetGroups
    case GET_ASSET_GROUPS:
      return update(state, {
        assetGroups: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_ASSET_GROUPS_SUCCESS:
      return update(state, {
        assetGroups: {
          $merge: {
            isLoading: false,
            wasLoaded: true
          },
          results: {
            $push: action.assetGroupsData.results
          },
          next_page_token: {
            $set: action.assetGroupsData.next_page_token
          }
        }
      })
    case GET_ASSET_GROUPS_FAILURE:
      return update(state, {
        assetGroups: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_ASSET_GROUPS:
      return update(state, {
        assetGroups: { $set: initialState.assetGroups }
      })

    // get assetGroup
    case GET_ASSET_GROUP:
      return update(state, {
        assetGroup: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_ASSET_GROUP_SUCCESS:
      return update(state, {
        assetGroup: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.assetGroupData
          }
        }
      })
    case GET_ASSET_GROUP_FAILURE:
      return update(state, {
        assetGroup: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_ASSET_GROUP:
      return update(state, {
        assetGroup: { $set: initialState.assetGroup }
      })

    // update asset group
    case UPDATE_ASSET_GROUP:
      return update(state, {
        updatedAssetGroup: { $merge: { isLoading: true, [action.id]: true, id: action.id } }
      })
    case UPDATE_ASSET_GROUP_SUCCESS:
      return update(state, {
        updatedAssetGroup: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            id: action.data.id
          }
        },
        assetGroups: {
          results: { $apply: items => updateItem(items, action.data) }
        },
        assetGroup: {
          data: {
            $merge: action.data
          }
        }
      })
    case UPDATE_ASSET_GROUP_FAILURE:
      return update(state, {
        updatedAssetGroup: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_ASSET_GROUP:
      return update(state, {
        updatedAssetGroup: { $set: initialState.updatedAssetGroup }
      })

    // Approve internal asset group
    case APPROVE_INTERNAL_ASSET_GROUP:
      return update(state, {
        approveInternalAssetGroup: {
          $merge: {
            isLoading: true,
            [action.id]: true,
            id: action.id
          }
        }
      })
    case APPROVE_INTERNAL_ASSET_GROUP_SUCCESS:
      return update(state, {
        approveInternalAssetGroup: {
          $merge: {
            isLoading: false,
            wasUpdated: true
          }
        },
        assetGroups: {
          results: { $apply: items => updateItem(items, action.data, action.id) }
        }
      })
    case APPROVE_INTERNAL_ASSET_GROUP_FAILURE:
      return update(state, {
        approveInternalAssetGroup: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_APPROVE_INTERNAL_ASSET_GROUP:
      return update(state, {
        approveInternalAssetGroup: { $set: initialState.approveInternalAssetGroup }
      })

    // Disapprove internal asset group
    case DISAPPROVE_INTERNAL_ASSET_GROUP:
      return update(state, {
        disapproveInternalAssetGroup: {
          $merge: {
            isLoading: true,
            [action.id]: true
          }
        }
      })
    case DISAPPROVE_INTERNAL_ASSET_GROUP_SUCCESS:
      return update(state, {
        disapproveInternalAssetGroup: {
          $merge: {
            isLoading: false,
            wasUpdated: true
          }
        },
        assetGroups: {
          results: {
            $apply: items => deleteItemById(items, action.id)
          }
        }
      })
    case DISAPPROVE_INTERNAL_ASSET_GROUP_FAILURE:
      return update(state, {
        disapproveInternalAssetGroup: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DISAPPROVE_INTERNAL_ASSET_GROUP:
      return update(state, {
        disapproveInternalAssetGroup: { $set: initialState.disapproveInternalAssetGroup }
      })

    // Delete internal asset group
    case DELETE_INTERNAL_ASSET_GROUP:
      return update(state, {
        deleteInternalAssetGroup: { $merge: { isLoading: true, [action.id]: true, id: action.id } }
      })
    case DELETE_INTERNAL_ASSET_GROUP_SUCCESS:
      return update(state, {
        deleteInternalAssetGroup: {
          $merge: {
            isLoading: false,
            wasDeleted: true
          }
        },
        assetGroups: {
          results: { $apply: items => deleteItemById(items, action.id) }
        }
      })
    case DELETE_INTERNAL_ASSET_GROUP_FAILURE:
      return update(state, {
        deleteInternalAssetGroup: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DELETE_INTERNAL_ASSET_GROUP:
      return update(state, {
        deleteInternalAssetGroup: { $set: initialState.deleteInternalAssetGroup }
      })

    // create asset group signal
    case CREATE_ASSET_GROUP_SIGNAL:
      return update(state, {
        createAssetGroupSignal: {
          $merge: {
            isLoading: true
          }
        }
      })
    case CREATE_ASSET_GROUP_SIGNAL_SUCCESS:
      return update(state, {
        createAssetGroupSignal: {
          $merge: {
            isLoading: false,
            wasCreated: true
          }
        }
      })
    case CREATE_ASSET_GROUP_SIGNAL_FAILURE:
      return update(state, {
        createAssetGroupSignal: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_ASSET_GROUP_SIGNAL: {
      return update(state, {
        createAssetGroupSignal: { $set: initialState.createAssetGroupSignal }
      })
    }
    default:
      return state
  }
}
