import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import Form from '../../../../../../components/Form'
import DrawerHeadline from '../../../../../../components/Drawer/DrawerHeadline'
import RadioBlock from '../../../../../../components/Form/RadioBlock'
import Field from '../../../../../../components/Form/Field'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'

import { selectedControllerDataSelector } from '../../../../../../modules/selectors/app'

import { OPTION_NO, OPTION_YES } from '../../../../../../constants/forms'
import { AUDIENCE, AUDIENCE_OPTION, initialValues, validationSchema } from './fields'

import useDrawerFormStyles from '../../../../../../styles/common/drawerForms'

const GoogleAudienceForm = ({
  formName,
  handleSubmit,
  errorSelector,
  isLoadingSelector,
  audienceWasCreated,
  onSuccessSubmit,
  clearHandler,
  audiencesIsLoading,
  audiencesList
}) => {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormStyles()

  const [successSubmit, setSuccessSubmit] = useState(false)

  const selectedController = useSelector(selectedControllerDataSelector)

  const selectedControllerName = selectedController?.name || ''

  const onSubmit = useCallback(
    values => {
      if (values[AUDIENCE_OPTION] === OPTION_NO) {
        // if no audiences option is selected > don't send request
        setSuccessSubmit(true)
      } else {
        handleSubmit(values)
      }
    },
    [handleSubmit]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const { setFieldValue, values } = purifiedFormik

  return (
    <div className={drawerFormClasses.formContainer}>
      <DrawerHeadline
        activeStepNumber={3}
        customStepsLength={4}
        title="Select an audiences"
        description={`Target ${selectedControllerName} shopper audiences on Google ads.`}
      />
      <Form
        formName={formName}
        formik={purifiedFormik}
        successSubmit={successSubmit || audienceWasCreated}
        onSuccessSubmit={onSuccessSubmit}
        clearHandler={clearHandler}
        errorSelector={errorSelector}
        isLoadingSelector={isLoadingSelector}
      >
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_audience_option_yes"
          name={AUDIENCE_OPTION}
          value={OPTION_YES}
          selectedValue={values[AUDIENCE_OPTION]}
          label={t('Select target audience(s)')}
        >
          <Field
            placeholder="Select an audience"
            options={audiencesList}
            name={AUDIENCE}
            formik={purifiedFormik}
            isLoading={audiencesIsLoading}
          />
        </RadioBlock>
        <RadioBlock
          setFieldValue={setFieldValue}
          id="radio_audience_option_no"
          name={AUDIENCE_OPTION}
          value={OPTION_NO}
          selectedValue={values[AUDIENCE_OPTION]}
          label={t('No audience targeting')}
        />
      </Form>
    </div>
  )
}

GoogleAudienceForm.propTypes = {
  formName: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorSelector: PropTypes.func.isRequired,
  isLoadingSelector: PropTypes.func.isRequired,
  audienceWasCreated: PropTypes.bool,
  audiencesIsLoading: PropTypes.bool,
  audiencesList: PropTypes.array.isRequired,
  onSuccessSubmit: PropTypes.func,
  clearHandler: PropTypes.func
}

export default GoogleAudienceForm
