import { useSelector } from 'react-redux'

import {
  getCurrentUserSelector,
  selectedAdAccountDataSelector,
  userSelfAccountTypeSelector
} from '../../modules/selectors/app'
import {
  DV_360_PLATFORM,
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  TIKTOK_PLATFORM
} from '../../constants/selectLists/platformList'

import { CONTROLLER_TYPE } from '../../constants/permissions'

// platform connection consists with 2 flows:
// 1) based on selectedAdAccount and taking auth connection from account auth_user
// 2) if there is no auth_user just check current user platform connections
// checkCurrentAccountAuth - check adAccount authUser always by default
export default function usePlatformConnected(platform, checkCurrentAccountAuth = true) {
  const user = useSelector(getCurrentUserSelector)

  const selectedAdAccount = useSelector(selectedAdAccountDataSelector)
  const userAccountType = useSelector(userSelfAccountTypeSelector)

  const hasAuthUser = !!selectedAdAccount.auth_user
  const notController = userAccountType !== CONTROLLER_TYPE

  // if auth user exists AND selectedSelfAccount is not under controller
  if (checkCurrentAccountAuth && hasAuthUser && notController) {
    // do not need to check if auth_user has authorised with the external service as if an auth_user exists it will have all auth
    // if there auth_user id exists treat all platforms as connected:
    return true
  } else {
    switch (platform) {
      case FACEBOOK_PLATFORM:
        return user.facebook_is_connected
      case GOOGLE_PLATFORM:
        return user.google_is_connected
      case DV_360_PLATFORM:
        return user.dv360_is_connected
      case TIKTOK_PLATFORM:
        return user.tiktok_is_connected
      default:
        return false
    }
  }
}
