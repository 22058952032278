import React from 'react'
import PropTypes from 'prop-types'

import { CalendarInventoryDataProvider, CalendarInventoryFiltersContext } from './CalendarInventoryFiltersContext'
import MediaProductFilter from '../../../../../../features/components/Filters/MediaProductFilter'
import BookedMediaLocationsFilter from '../../../../../../features/components/Filters/BookedMediaLocationsFilter'
import MultipleCategoriesFilters from '../../../../../../features/components/Filters/MultipleCategoriesFilters'

import useStyles from './styles'

const CalendarInventoryFilters = ({ onFiltersChange }) => {
  const classes = useStyles()

  return (
    <CalendarInventoryDataProvider onFiltersChange={onFiltersChange}>
      <div className={classes.filtersRow}>
        <div className={classes.rightFiltersRow}>
          <BookedMediaLocationsFilter Context={CalendarInventoryFiltersContext} />
          <MultipleCategoriesFilters Context={CalendarInventoryFiltersContext} />
          <MediaProductFilter Context={CalendarInventoryFiltersContext} />

          {/*this is used for portalled filters - don't remove until check id usage */}
          <div id="booked_media_calendar_view_portal" />
          {/*this is used for pdf generation */}
          <div id="pdf_generation_portal" />
        </div>
      </div>
    </CalendarInventoryDataProvider>
  )
}

CalendarInventoryFilters.propTypes = {
  onFiltersChange: PropTypes.func
}

export default CalendarInventoryFilters
