import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'formik'

import CollapsibleFormFields from '../../../../../../../features/components/Form/CollapsibleFormFields'
import Field from '../../../../../../../components/Form/Field'
import FacebookMediaUpload from '../../../../../../ReusableFormFields/FacebookMediaUpload'

import { choicesCTAByGoalSelector } from '../../../../../../../modules/selectors/choices'

import { keys } from '../../../../../../../helpers/common'
import { formatOptionsList } from '../../../../../../../features/formatters'
import { isStepFieldsValid } from '../../../../../../../features/components/Forms/StepForm/helpers'

import { AD_LINK, FILE_URL, IS_ITEM_FORM_OPENED, STORIES_ITEM_SELECTED_INDEX, STORIES_ITEMS } from '../../../../fields'
import { CALL_TO_ACTION } from '../../../../../../ReusableFormFields/CallToActionFields/fields'

import { storyMediaValidation } from '../../validation/mediaValidation'
import { AdFacebookCreateContext } from '../../../../../formsContexts/AdFacebookCreateFormContextProvider'

const StoriesItemForm = ({ formik, item, index, onItemDelete, onItemClick, showError, adAccountId }) => {
  const { t } = useTranslation()

  const { values, setFieldValue, errors, touched, setFieldTouched } = formik

  const itemPath = `${STORIES_ITEMS}[${index}]`

  const { campaignObjective } = useContext(AdFacebookCreateContext)

  const actionsList = useSelector(choicesCTAByGoalSelector(campaignObjective))

  const formattedActionsList = useMemo(() => {
    return formatOptionsList({
      list: actionsList,
      labelName: 'label',
      valueName: 'name'
    })
  }, [actionsList])

  const storiesItems = getIn(values, `${STORIES_ITEMS}`)
  const selectedItemIndex = getIn(values, `${STORIES_ITEM_SELECTED_INDEX}`)

  const itemErrors = getIn(errors, itemPath)
  const itemTouched = getIn(touched, itemPath)
  const isItemActive = selectedItemIndex === index

  const isRemovable = storiesItems.length > 1

  const isFocused = getIn(values, `${IS_ITEM_FORM_OPENED}`)
  const isOpened = isFocused && isItemActive

  const isValid = itemErrors && itemTouched && isStepFieldsValid(itemErrors, keys(itemTouched))

  const showFileUrlError = !isValid && itemTouched?.[FILE_URL]
  const mediaUrlError = showFileUrlError && itemErrors?.[FILE_URL]

  useEffect(() => {
    if (showError) {
      setFieldTouched(`${STORIES_ITEMS}[${index}].${FILE_URL}`, true)
      setFieldTouched(`${STORIES_ITEMS}[${index}].${AD_LINK}`, true)
    }
  }, [showError, setFieldTouched, index, values, item])

  const handleDeleteStoriesItem = useCallback(() => {
    onItemDelete(index)
  }, [index, onItemDelete])

  const closeFormHandler = useCallback(() => {
    setFieldValue(IS_ITEM_FORM_OPENED, false)
  }, [setFieldValue])

  const selectStoriesForm = useCallback(() => {
    onItemClick({ index, isFormOpened: true })
  }, [onItemClick, index])

  return (
    <CollapsibleFormFields
      index={index}
      isValid={isValid}
      isOpened={isOpened}
      isItemActive={isItemActive}
      isRemovable={isRemovable}
      selectForm={selectStoriesForm}
      closeForm={closeFormHandler}
      onDelete={handleDeleteStoriesItem}
    >
      <FacebookMediaUpload
        formik={formik}
        item={item}
        itemPath={itemPath}
        mediaValidationSchema={storyMediaValidation}
        mediaUrlError={mediaUrlError}
        adAccountId={adAccountId}
      />
      <Field
        placeholder={t('Call-to-action')}
        formik={formik}
        name={`${itemPath}.${CALL_TO_ACTION}`}
        options={formattedActionsList}
      />
      <Field formik={formik} name={`${itemPath}.${AD_LINK}`} placeholder={t('Click through link')} autoComplete="off" />
    </CollapsibleFormFields>
  )
}

export default StoriesItemForm
