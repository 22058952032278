import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { catalogueProductsSelector, categoryPagesSelector } from '../../../../../modules/selectors/mediaOrders'

export default function useProductsAndPages() {
  const categoryPages = useSelector(categoryPagesSelector)
  const catalogueProducts = useSelector(catalogueProductsSelector)

  return useMemo(() => {
    // join together products and pages
    const pages = catalogueProducts.concat(categoryPages)

    return pages.map(({ link, name }) => ({
      value: link,
      label: name,
      description: link
    }))
  }, [catalogueProducts, categoryPages])
}
