import { call, put, all, takeEvery } from 'redux-saga/effects'

import {
  CREATE_DV360_BULK_TARGETING,
  createDv360BulkTargetingFailure,
  createDv360BulkTargetingSuccess
} from '../actions/dv360Constants'
import { createDv360BulkTargetingService } from '../services/dv360Constants'

function* dv360ConstantsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(CREATE_DV360_BULK_TARGETING, createDv360BulkTargetingWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* createDv360BulkTargetingWorker({ params }) {
  try {
    const response = yield call(createDv360BulkTargetingService, params)
    yield put(createDv360BulkTargetingSuccess(response))
  } catch (error) {
    yield put(createDv360BulkTargetingFailure(error))
  }
}

export default dv360ConstantsWatcher
