import { createStyles } from '../../../../styles/helpers'

export const mediaPackageCardButtonBorderRadius = 21

const useStyles = createStyles({
  button: {
    fontSize: 12,
    fontWeight: 400,
    borderRadius: mediaPackageCardButtonBorderRadius,
    padding: '8px 16px',
    // reset default behaviour of Button component (width: 100% on mobile)
    minWidth: ['unset', '!important'],
    width: ['unset', '!important']
  }
})

export default useStyles
