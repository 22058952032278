import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Page from '../index'
import ApprovalsTabs from './ApprovalsTabs'
import TabsHeader from '../../features/components/ContentTabs/TabsHeader'

import { clearAds, getInternalAdsItemsCount, clearInternalAdsItemsCount } from '../../modules/actions/ads'
import { selectedControllerIdSelector } from '../../modules/selectors/app'

import useContentTabsHeaderStyles from '../../features/components/ContentTabs/TabsHeader/contentTabsHeaderStyles'

const AdApprovalsPage = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const contentTabsHeaderClasses = useContentTabsHeaderStyles()

  const selectedSelfAccountControllerId = useSelector(selectedControllerIdSelector)

  useEffect(() => {
    dispatch(
      getInternalAdsItemsCount({
        controller_id: selectedSelfAccountControllerId
      })
    )
  }, [dispatch, selectedSelfAccountControllerId])

  useEffect(
    () => () => {
      dispatch(clearAds())
    },
    [dispatch]
  )

  useEffect(() => {
    return () => {
      dispatch(clearInternalAdsItemsCount())
    }
  }, [dispatch])

  return (
    <Page
      helmetTitle="helmetTitle.ApprovalsPage"
      mainClassName={contentTabsHeaderClasses.pageMain}
      className={contentTabsHeaderClasses.pageContainer}
    >
      <TabsHeader>
        <h1>{t('Ad Approvals')}</h1>
      </TabsHeader>
      <ApprovalsTabs />
    </Page>
  )
}

export default AdApprovalsPage
