import update from 'immutability-helper'
import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_CALENDARS,
  GET_CALENDARS_SUCCESS,
  GET_CALENDARS_FAILURE,
  CREATE_CALENDAR,
  CREATE_CALENDAR_SUCCESS,
  CREATE_CALENDAR_FAILURE,
  GET_CALENDAR,
  GET_CALENDAR_SUCCESS,
  GET_CALENDAR_FAILURE,
  GET_EVENT,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
  CREATE_EVENT,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAILURE,
  CREATE_EVENT_GROUP,
  UPDATE_EVENT,
  UPDATE_EVENT_SUCCESS,
  UPDATE_NEW_EVENTS_GROUP_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
  UPDATE_EVENT_PARTIAL,
  UPDATE_EVENT_PARTIAL_SUCCESS,
  UPDATE_EVENT_PARTIAL_FAILURE,
  DELETE_EVENT,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,
  CREATE_EVENT_GROUP_SUCCESS,
  CREATE_EVENT_GROUP_FAILURE,
  UPDATE_EVENT_GROUP,
  UPDATE_EVENT_GROUP_SUCCESS,
  UPDATE_CALENDAR_FAILURE,
  UPDATE_EVENT_GROUP_ORDER,
  UPDATE_EVENT_GROUP_ORDER_SUCCESS,
  UPDATE_EVENT_GROUP_ORDER_FAILURE,
  DELETE_EVENT_GROUP,
  DELETE_EVENT_GROUP_SUCCESS,
  DELETE_EVENT_GROUP_FAILURE,
  CLEAR_DELETE_EVENT_GROUP,
  CLEAR_CREATE_EVENT_GROUP,
  CLEAR_CREATE_EVENT,
  CLEAR_UPDATE_EVENT,
  CLEAR_UPDATE_EVENT_GROUP,
  CLEAR_EVENT_DATA
} from '../actions/calendarPlanner'
import { deleteItemById, updateItem } from '../../helpers/modules/reducerHelpers'
import { INITIAL_CREATE_REDUCER, INITIAL_GET_REDUCER, INITIAL_GET_RESULTS_REDUCER } from '../../constants/reducer'
import { ROW } from '../../forms/Multiplatform/CalendarPlannerForms/Event/CalendarEventForm/fields'

const getUpdatedEventGroupIndex = (calendarData, eventData) => {
  // when Event is created/updated - first need to find to which event_group it relates
  const updatedEventGroupId = eventData[ROW]
  // updatedEventGroupIndex
  return calendarData.data.event_groups.findIndex(eventGroup => eventGroup.id === updatedEventGroupId)
}

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  calendarCreate: {
    isLoading: false,
    data: null
  },
  calendarsList: INITIAL_GET_RESULTS_REDUCER,
  calendarData: INITIAL_GET_REDUCER,
  // handling GET data
  calendarEventData: {},
  // handling create/update
  calendarEvent: INITIAL_CREATE_REDUCER,
  calendarEventUpdate: {},
  deletedEvent: {},
  calendarRow: INITIAL_CREATE_REDUCER,
  updatedEventGroup: {},
  updateEventGroupOrder: {
    isLoading: false
  },
  deletedEventGroup: {}
}

export default function calendarPlanner(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS
    case GET_CALENDARS:
      return update(state, {
        calendarsList: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_CALENDARS_SUCCESS:
      return update(state, {
        calendarsList: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.calendarsList.results
          }
        }
      })
    case GET_CALENDARS_FAILURE:
      return update(state, {
        calendarsList: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CREATE_CALENDAR:
      return update(state, {
        calendarCreate: {
          $merge: { isLoading: true }
        }
      })
    case CREATE_CALENDAR_SUCCESS:
      return update(state, {
        calendarCreate: {
          $merge: {
            isLoading: false,
            data: action.calendarData
          }
        },
        calendarsList: {
          isLoaded: { $set: true },
          results: { $push: [action.calendarData] }
        }
      })
    case CREATE_CALENDAR_FAILURE:
      return update(state, {
        calendarCreate: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case GET_CALENDAR:
      return update(state, {
        calendarData: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_CALENDAR_SUCCESS:
      return update(state, {
        calendarData: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.calendarData
          }
        }
      })
    case GET_CALENDAR_FAILURE:
      return update(state, {
        calendarData: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CREATE_EVENT_GROUP:
      return update(state, {
        calendarRow: {
          $merge: { isLoading: true }
        }
      })
    case CREATE_EVENT_GROUP_SUCCESS:
      return update(state, {
        calendarRow: {
          $merge: {
            isLoading: false,
            id: action.newEventGroupData.id,
            wasCreated: true
          }
        },
        calendarData: {
          data: {
            event_groups: {
              $push: [action.newEventGroupData]
            }
          }
        }
      })
    case CREATE_EVENT_GROUP_FAILURE:
      return update(state, {
        calendarRow: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case GET_EVENT:
      return update(state, {
        calendarEventData: {
          $merge: {
            isLoading: true
          }
        }
      })
    case GET_EVENT_SUCCESS:
      return update(state, {
        calendarEventData: {
          $merge: {
            isLoading: false,
            data: action.eventData
          }
        }
      })
    case GET_EVENT_FAILURE:
      return update(state, {
        calendarEventData: {
          $merge: {
            isLoading: false
          }
        }
      })
    case CLEAR_EVENT_DATA:
      return update(state, {
        calendarEventData: { $set: initialState.calendarEventData }
      })
    case UPDATE_NEW_EVENTS_GROUP_EVENT_SUCCESS:
      return update(state, {
        calendarEventUpdate: {
          $merge: {
            isLoading: false,
            data: action.updatedEventData
          }
        },
        calendarData: {
          data: {
            event_groups: {
              // update only event_group for which the event was created
              [getUpdatedEventGroupIndex(state.calendarData, action.updatedEventData)]: {
                events: {
                  // push new event which was updated with new event group events
                  $push: [action.updatedEventData]
                }
              },
              [getUpdatedEventGroupIndex(state.calendarData, { [ROW]: action.oldEventGroupId })]: {
                events: {
                  // delete event for oldEventGroup which was updated with new event group events
                  $apply: items => deleteItemById(items, action.updatedEventData.id)
                }
              }
            }
          }
        }
      })

    case CREATE_EVENT:
      return update(state, {
        calendarEvent: {
          $merge: { isLoading: true }
        }
      })
    case CREATE_EVENT_FAILURE:
      return update(state, {
        calendarEvent: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CREATE_EVENT_SUCCESS:
      return update(state, {
        calendarEvent: {
          $merge: {
            isLoading: false,
            id: action.newEventData.id,
            wasCreated: true
          }
        },
        calendarData: {
          data: {
            event_groups: {
              // update only event_group for which the event was created
              [getUpdatedEventGroupIndex(state.calendarData, action.newEventData)]: {
                events: {
                  // push new event to "updated" event group events
                  $push: [action.newEventData]
                }
              }
            }
          }
        }
      })

    case CLEAR_CREATE_EVENT:
      return update(state, {
        calendarEvent: { $set: initialState.calendarEvent }
      })

    case UPDATE_EVENT:
    case UPDATE_EVENT_PARTIAL:
      return update(state, {
        calendarEventUpdate: {
          $merge: { isLoading: true }
        }
      })

    case UPDATE_EVENT_PARTIAL_SUCCESS:
    case UPDATE_EVENT_SUCCESS:
      return update(state, {
        calendarEventUpdate: {
          $merge: {
            isLoading: false,
            id: action.updatedEventData.id,
            wasUpdated: true
          }
        },
        calendarData: {
          data: {
            event_groups: {
              // update only event_group for which the event was created/updated
              [getUpdatedEventGroupIndex(state.calendarData, action.updatedEventData)]: {
                events: {
                  // update new event in "updated" event group events
                  $apply: items => updateItem(items, action.updatedEventData)
                }
              }
            }
          }
        }
      })

    case UPDATE_EVENT_FAILURE:
    case UPDATE_EVENT_PARTIAL_FAILURE:
      return update(state, {
        calendarEventUpdate: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_EVENT:
      return update(state, {
        calendarEventUpdate: { $set: initialState.calendarEventUpdate }
      })

    case DELETE_EVENT:
      return update(state, {
        deletedEvent: { $set: { isLoading: true } }
      })
    case DELETE_EVENT_SUCCESS:
      return update(state, {
        calendarData: {
          data: {
            event_groups: {
              // find event_group of deleted event
              [getUpdatedEventGroupIndex(state.calendarData, { [ROW]: action.eventGroupId })]: {
                events: {
                  // delete event
                  $apply: items => deleteItemById(items, action.eventId)
                }
              }
            }
          }
        },
        deletedEvent: { $set: { isLoading: false, id: action.id } }
      })
    case DELETE_EVENT_FAILURE:
      return update(state, {
        deletedEvent: { $set: { isLoading: false, error: action.error } }
      })
    case CLEAR_CREATE_EVENT_GROUP:
      return update(state, {
        calendarRow: { $set: initialState.calendarRow }
      })
    case UPDATE_EVENT_GROUP:
      return update(state, {
        updatedEventGroup: { $set: { isLoading: true } }
      })
    case UPDATE_EVENT_GROUP_SUCCESS:
      return update(state, {
        calendarData: {
          data: {
            event_groups: {
              $apply: items => updateItem(items, action.eventGroupData)
            }
          }
        },
        updatedEventGroup: { $set: { isLoading: false, wasUpdated: true } }
      })
    case CLEAR_UPDATE_EVENT_GROUP:
      return update(state, {
        updatedEventGroup: { $set: initialState.updatedEventGroup }
      })
    case UPDATE_CALENDAR_FAILURE:
      return update(state, {
        updatedEventGroup: { $set: { isLoading: false, error: action.error } }
      })
    case UPDATE_EVENT_GROUP_ORDER:
      return update(state, {
        updateEventGroupOrder: { $merge: { isLoading: true } }
      })
    case UPDATE_EVENT_GROUP_ORDER_SUCCESS:
      return update(state, {
        updateEventGroupOrder: { $merge: { isLoading: false } },
        calendarData: {
          data: {
            event_groups: {
              $set: action.eventGroupData
            }
          }
        }
      })
    case UPDATE_EVENT_GROUP_ORDER_FAILURE:
      return update(state, {
        updateEventGroupOrder: { $merge: { isLoading: false } }
      })
    case DELETE_EVENT_GROUP:
      return update(state, {
        deletedEventGroup: { $set: { isLoading: true } }
      })
    case DELETE_EVENT_GROUP_SUCCESS:
      return update(state, {
        calendarData: {
          data: {
            event_groups: {
              $apply: items => deleteItemById(items, action.id)
            }
          }
        },
        deletedEventGroup: { $set: { isLoading: false, id: action.id, wasDeleted: true } }
      })
    case DELETE_EVENT_GROUP_FAILURE:
      return update(state, {
        deletedEventGroup: { $set: { isLoading: false, error: action.error } }
      })
    case CLEAR_DELETE_EVENT_GROUP:
      return update(state, {
        deletedEventGroup: { $set: initialState.deletedEventGroup }
      })
    default:
      return state
  }
}
