import { createStyles } from '../../../../../styles/helpers'
import { textEllipsis } from '../../../../../styles/mixins/text'
import { ELEMENT_HEIGHT } from '../../CalendarRow/helpers'

export default createStyles(theme => ({
  tooltipTrigger: {
    display: 'block !important'
  },
  barPlaceholder: {
    height: ELEMENT_HEIGHT,
    lineHeight: '16px',
    boxSizing: 'border-box',
    borderRadius: theme.defaultBorderRadius,
    backgroundColor: theme.brandPrimary,
    ...textEllipsis(),
    color: 'white',
    padding: '4px',
    textAlign: 'center',
    fontSize: 12
  }
}))
