import React from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import { TabletDown, TabletUp } from '../../../../../components/hoc/ResponsiveRendering'
import CampaignsDateRange from '../../../../Campaigns/CampaignsDateRange'
import PdfGeneration from '../../../../../features/components/PdfGeneration'

import { getPDFSections } from './generatePdf'

import { setMediaOrderCampaignsDateRange } from '../../../../../modules/actions/mediaOrders'

import { mediaOrderCampaignsDateRangeSelector, mediaOrderSelector } from '../../../../../modules/selectors/mediaOrders'

import { phonesDownSize } from '../../../../../styles/const/breakpoints'
import useStyles from './styles'

const CampaignSection = () => {
  const classes = useStyles()
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const mediaOrder = useSelector(mediaOrderSelector)

  return (
    <div className={classes.container}>
      <div className={classes.campaignsDateRangeWrapper}>
        <TabletUp>
          <CampaignsDateRange
            dateRangeSelector={mediaOrderCampaignsDateRangeSelector}
            selectDateRange={setMediaOrderCampaignsDateRange}
          />
        </TabletUp>
        <TabletDown>
          <CampaignsDateRange
            dateRangeSelector={mediaOrderCampaignsDateRangeSelector}
            selectDateRange={setMediaOrderCampaignsDateRange}
            mobileMenuHeightLimit={220}
            isMobile
          />
        </TabletDown>
      </div>
      {!isMobile && <PdfGeneration fileName={mediaOrder?.name} getReportPDFSections={getPDFSections} />}
    </div>
  )
}

export default CampaignSection
