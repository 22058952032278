import { OPTION_YES } from '../../../../../../constants/forms'

export const NAME = 'name'
export const CPM_BID = 'cpm_bid_micros'
export const SET_CPM_BID = 'set_cpm_bid'

export const initialValues = {
  [NAME]: '',
  [CPM_BID]: '',
  [SET_CPM_BID]: OPTION_YES
}
