import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import StepForm from '../../../../../features/components/Forms/StepForm'
import ProductsSelectionStep from './Steps/ProductsSelectionStep'
import OrderCartStep from './Steps/OrderCartStep'

import { useProgressItems } from './useProgressItems'
import usePermissions from '../../../../../hooks/usePermissions'
import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'
import { useRequiredFields } from '../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/hooks/useRequiredFields'
import { useGetSelectedCurrency } from '../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/hooks/useGetSelectedCurrency'

import { createContract } from '../../../../../modules/actions/contracts'
import { clearGetContractEntities, getContractEntities } from '../../../../../modules/actions/mediaOrders'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'
import {
  createContractsErrorSelector,
  createContractsIsLoadingSelector
} from '../../../../../modules/selectors/contracts'
import { getContractEntitiesWasLoadedSelector } from '../../../../../modules/selectors/mediaOrders'

import { getValidationSchema } from './validation'
import { getFormattedContractData } from './formatters'

import { initialValues } from './fields'
import { MEDIA_ORDER_CREATE_SIMPLE } from '../../../../../constants/forms'
import { MODIFY_QUOTATION_PRICING } from '../../../../../constants/permissions'
import { MediaOrderFormContext } from '../../MediaOrderFormContext'

function MediaOrderCreateSimpleForm() {
  const dispatch = useDispatch()
  // we have 2 buttons which uses the same endpoint, so we need to know which one was clicked,
  // base on clicked pass the handlers
  const [isQuotationSubmission, setIsQuotationSubmission] = useState(false)

  const { contextSelfAccountData } = useContext(MediaOrderFormContext)

  const controllerId = useSelector(selectedControllerIdSelector)
  const selectedControllerId = useSelector(selectedControllerIdSelector)
  const contractEntitiesWasLoaded = useSelector(getContractEntitiesWasLoadedSelector)

  const selectedCurrency = useGetSelectedCurrency()
  const permissions = usePermissions()
  const hasEditPricingPermissions = permissions.can('manage', MODIFY_QUOTATION_PRICING)

  const formProgressItems = useProgressItems()

  const onSubmit = useCallback(
    values => {
      const { id: selectedSelfAccountId, representative: selfAccountRepresentative } = contextSelfAccountData

      const formattedContract = getFormattedContractData({
        hasEditPricingPermissions,
        values,
        selfAccountRepresentative,
        selectedSelfAccountId,
        controllerId,
        selectedCurrency
      })

      const contractData = {
        without_requirements: true, // allows to create media order through the contract endpoint, without terms in contract
        ...(hasEditPricingPermissions && { set_amendment_cost: true }), // allows to set modified pricing
        detail: formattedContract,
        account: selectedSelfAccountId,
        controller: controllerId,
        quotation: isQuotationSubmission
      }

      // the contract with without_requirements creates the media order without need of sign or approve
      // so even though the contract is creating, the media order is created behind the scenes.
      dispatch(createContract(contractData))
    },
    [contextSelfAccountData, hasEditPricingPermissions, controllerId, selectedCurrency, isQuotationSubmission, dispatch]
  )

  const { askBrandName, askCampaignName, isBrandRequired } = useRequiredFields(contextSelfAccountData)
  const validationSchema = useMemo(
    () =>
      getValidationSchema({
        askBrandName,
        askCampaignName,
        isBrandRequired
      }),
    [askBrandName, askCampaignName, isBrandRequired]
  )
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const steps = useMemo(
    () => [
      {
        component: <ProductsSelectionStep />,
        show: true
      },
      {
        component: (
          <OrderCartStep
            isQuotationSubmission={isQuotationSubmission}
            setIsQuotationSubmission={setIsQuotationSubmission}
            onSubmit={onSubmit}
          />
        ),
        show: true
      }
    ],
    [isQuotationSubmission, onSubmit]
  )

  useEffect(() => {
    dispatch(
      getContractEntities({
        controller: selectedControllerId
      })
    )
  }, [dispatch, selectedControllerId])

  useEffect(() => {
    return () => {
      dispatch(clearGetContractEntities())
    }
  }, [dispatch])

  return (
    <StepForm
      steps={steps}
      formik={purifiedFormik}
      formName={MEDIA_ORDER_CREATE_SIMPLE}
      formProgressItems={formProgressItems}
      submitOnLastStep={false} // we do not submit by default buttons, we have custom buttons on OrderCartStep
      // processing
      isLoadingSelector={createContractsIsLoadingSelector}
      errorSelector={createContractsErrorSelector}
      isInitialDataLoading={!contractEntitiesWasLoaded}
    />
  )
}

export default MediaOrderCreateSimpleForm
