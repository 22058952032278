import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles(theme => ({
  sortCell: {
    display: 'flex',
    alignItems: 'center'
  },
  sortIconWrapper: {
    display: 'flex',
    marginLeft: '2px'
  },
  sortIcon: {
    fontSize: '16px',
    color: theme.brandPrimary,
    '&:hover': {
      color: theme.brandPrimaryHover
    }
  },
  isAsc: {
    '& svg path:nth-child(1)': {
      fill: theme.brandPrimary
    }
  },
  isDesc: {
    '& svg path:nth-child(2)': {
      fill: theme.brandPrimary
    }
  }
}))

export default useStyles
