import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useStyles from './styles'

const TypeWriteAnimation = ({ className, text = '', delay = 40 }) => {
  const classes = useStyles()

  const [displayText, setDisplayText] = useState('')
  const [currentTextIndex, setCurrentTextIndex] = useState(0)

  const prevTextRef = useRef('')

  useEffect(() => {
    if (prevTextRef.current !== text) {
      setDisplayText(prevTextRef.current)
      setCurrentTextIndex(prevTextRef.current.length)
      prevTextRef.current = text
    }
  }, [text])

  useEffect(() => {
    const typewriter = setInterval(() => {
      if (currentTextIndex < text.length) {
        setDisplayText(prevDisplayText => prevDisplayText + text[currentTextIndex])
        setCurrentTextIndex(prevIndex => prevIndex + 1)
      }
    }, delay)

    return () => clearInterval(typewriter)
  }, [text, delay, currentTextIndex])

  return <span className={classnames(className, classes.typingText)}>{displayText}</span>
}

// PropTypes
TypeWriteAnimation.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  // the smaller is delay the faster is typing
  delay: PropTypes.number
}
export default TypeWriteAnimation
