import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import useDeleteAction from '../../../../features/hooks/useDeleteAction'
import useGetAdActionsDropdownOptions from './useGetAdActionsDropdownOptions'

import {
  clearApproveInternalAd,
  clearDeleteAd,
  clearDeleteInternalAd,
  clearUpdateAd,
  deleteAd,
  updateAd
} from '../../../../modules/actions/ads'
import { PROVIDER_TO_PLATFORMS } from '../../../../constants/selectLists/platformList'
import { listItemChangeStatus } from '../../../../constants/other'
import {
  adDeleteErrorSelector,
  adDeleteIsLoadingSelector,
  adDeleteItemSelector,
  adUpdateErrorSelector,
  adUpdateIsLoadingSelector,
  adUpdateSelector,
  adWasUpdatedSelector,
  approveInternalAdErrorSelector,
  approveInternalAdIsLoadingSelector,
  approveInternalAdSelector,
  approveInternalAdWasApprovedSelector,
  deleteInternalAdErrorSelector,
  deleteInternalAdIsLoadingSelector,
  deleteInternalAdSelector,
  deleteInternalAdWasDeletedSelector
} from '../../../../modules/selectors/ads'

const useAdActions = ({ selectedAdAccountId, selectedPlatform }) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const [isStatusUpdateRunning, setIsStatusUpdateRunning] = useState(false)

  const adUpdate = useSelector(adUpdateSelector)
  const adUpdateIsLoading = useSelector(adUpdateIsLoadingSelector)
  const adUpdateWasUpdated = useSelector(adWasUpdatedSelector)
  const adUpdateError = useSelector(adUpdateErrorSelector)
  const adDelete = useSelector(adDeleteItemSelector)
  const adDeleteIsLoading = useSelector(adDeleteIsLoadingSelector)
  const approveInternalAdData = useSelector(approveInternalAdSelector)
  const approveInternalAdIsLoading = useSelector(approveInternalAdIsLoadingSelector)
  const approveInternalAdWasApproved = useSelector(approveInternalAdWasApprovedSelector)
  const approveInternalAdError = useSelector(approveInternalAdErrorSelector)
  const deleteInternalAdData = useSelector(deleteInternalAdSelector)
  const deleteInternalAdIsLoading = useSelector(deleteInternalAdIsLoadingSelector)
  const deleteInternalAdError = useSelector(deleteInternalAdErrorSelector)
  const deleteInternalAdWasDeleted = useSelector(deleteInternalAdWasDeletedSelector)

  const itemUpdatingId = useMemo(() => {
    // avoid representation of skeleton row during status update (happens from toggle button)
    if (adUpdateIsLoading && !isStatusUpdateRunning) {
      return adUpdate.id
    } else if (adDeleteIsLoading) {
      return adDelete.id
    } else if (approveInternalAdIsLoading) {
      return approveInternalAdData.id
    } else if (deleteInternalAdIsLoading) {
      return deleteInternalAdData.id
    }

    return null
  }, [
    isStatusUpdateRunning,
    adUpdateIsLoading,
    adUpdate.id,
    adDeleteIsLoading,
    adDelete.id,
    approveInternalAdIsLoading,
    approveInternalAdData.id,
    deleteInternalAdIsLoading,
    deleteInternalAdData.id
  ])

  const deleteAdHandler = useCallback(
    params => {
      dispatch(deleteAd(params))
    },
    [dispatch]
  )

  const clearDeleteAdHandler = useCallback(() => {
    dispatch(clearDeleteAd())
  }, [dispatch])

  const deleteModalMessage = useMemo(
    () => (
      <>
        <p>
          {t(
            'If there has been recent delivery on this ad, we recommend you archive it. Otherwise, you can delete if it is not required anymore.'
          )}
        </p>
        <p>{t('This cannot be undone.')}</p>
      </>
    ),
    [t]
  )

  const {
    handleDelete,
    handleDeleteRequest,
    handleCloseDeleteModal,
    isDeleteModalOpened,
    deleteItemIsLoading,
    dataWaitingForDelete
  } = useDeleteAction({
    onDelete: deleteAdHandler,
    onClearDelete: clearDeleteAdHandler,
    deletedItemSelector: adDeleteItemSelector,
    deleteItemErrorSelector: adDeleteErrorSelector,
    deleteItemIsLoadingSelector: adDeleteIsLoadingSelector,
    deleteModalMessage: deleteModalMessage
  })

  const archiveProps = useMemo(
    () => ({
      updateItemAction: updateAd,
      updatedItemSelector: adUpdateSelector,
      updateItemErrorSelector: adUpdateErrorSelector,
      updateItemIsLoadingSelector: adUpdateIsLoadingSelector,
      clearUpdateAction: clearUpdateAd,
      dataWaitingForDelete
    }),
    [dataWaitingForDelete]
  )

  const getAdActionsDropdownOptions = useGetAdActionsDropdownOptions({ handleDeleteRequest })

  const updateAdStatusHandler = useCallback(
    ad => {
      const { ad_account_id: adAccountId, provider } = ad
      const currentAdPlatform = PROVIDER_TO_PLATFORMS[provider]
      const adAccount = adAccountId || selectedAdAccountId
      setIsStatusUpdateRunning(true)

      dispatch(
        updateAd(
          {
            account: adAccount,
            status: listItemChangeStatus[ad.status]?.changeTo
          },
          ad.id,
          'PATCH',
          currentAdPlatform
        )
      )
    },
    [dispatch, selectedAdAccountId]
  )

  useEffect(() => {
    if (isStatusUpdateRunning && (adUpdateWasUpdated || adUpdateError)) {
      dispatch(clearUpdateAd())

      setIsStatusUpdateRunning(false)
    }
  }, [dispatch, adUpdateError, adUpdateWasUpdated, isStatusUpdateRunning])

  useEffect(() => {
    if (approveInternalAdWasApproved || approveInternalAdError) {
      dispatch(clearApproveInternalAd())
    }
  }, [dispatch, approveInternalAdError, approveInternalAdWasApproved])

  useEffect(() => {
    if (deleteInternalAdWasDeleted || deleteInternalAdError) {
      dispatch(clearDeleteInternalAd())
    }
  }, [dispatch, deleteInternalAdError, deleteInternalAdWasDeleted])

  return {
    itemUpdatingId,
    getAdActionsDropdownOptions,
    handleDelete,
    updateAdStatusHandler,
    handleDeleteRequest,
    handleCloseDeleteModal,
    isDeleteModalOpened,
    deleteItemIsLoading,
    deleteModalMessage,
    archiveProps
  }
}

export default useAdActions
