import React from 'react'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import AgencyCreateForm from './AgencyCreateForm'

import { AGENCY_CREATE } from '../../../../constants/forms'

function AgencyCreate() {
  return (
    <FormDrawerWrapper formName={AGENCY_CREATE} title="Agency name" openButtonText="New Agency">
      <AgencyCreateForm />
    </FormDrawerWrapper>
  )
}

export default AgencyCreate
