import React from 'react'
import { useSelector } from 'react-redux'

import { NAME } from '../../fields'

import { adSelector } from '../../../../../../modules/selectors/ads'

const NameSectionPreview = () => {
  const ad = useSelector(adSelector)

  const { [NAME]: name } = ad

  return <p>{name}</p>
}

export default NameSectionPreview
