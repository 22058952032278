import { createStyles } from '../../../../../styles/helpers'

import { textEllipsis } from '../../../../../styles/mixins/text'
import { phonesDown } from '../../../../../styles/const/breakpoints'

// these styles are used across different components
export default createStyles({
  col: {
    display: 'flex',
    padding: '0px 12px',
    width: '100%',
    lineHeight: 'normal',
    ...textEllipsis(),

    '&> span': {
      // skeleton fix to stretch it for column width
      width: '100%'
    }
  },
  [`@media screen and (${phonesDown})`]: {
    col: {
      padding: 0
    }
  }
})
