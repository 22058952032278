import {
  AIRLINE_DURATION_OPTION,
  ARRIVAL_DESTINATIONS,
  BASED_ON_CHOICE_DURATION,
  BRAND_SHOPPERS,
  CATEGORY_SHOPPERS,
  CUSTOM_DURATION,
  DEPARTURE_DESTINATIONS,
  DESTINATIONS_OPERATOR,
  ECONOMY_PASSENGERS,
  LIFESTYLE_CATEGORIES,
  PASSENGER_STATUS_OPTION,
  PREMIUM_PASSENGERS,
  SHOPPER_TYPE_OPTION,
  THIRTY_DAYS_DURATION,
  YOUR_WEBSITE
} from './fields'

const getDurationInSeconds = (duration_option, origin_option, custom_days) => {
  switch (duration_option) {
    case THIRTY_DAYS_DURATION:
      return 2592000 // 30 days * 24 * 60 * 60
    case BASED_ON_CHOICE_DURATION:
      // 15552000 sec = 180 days
      // 31536000 sec = 365 days
      return origin_option === YOUR_WEBSITE ? 15552000 : 31536000
    case CUSTOM_DURATION:
      return custom_days * 24 * 60 * 60
    default:
      return 2592000 // 30 days * 24 * 60 * 60
  }
}

const getFilters = url_contains => {
  if (url_contains.length) {
    return url_contains.map(item => ({
      field: 'url',
      value: item,
      operator: 'i_contains'
    }))
  }

  return [
    {
      field: 'url',
      value: '',
      operator: 'i_contains'
    }
  ]
}

export const formatSupermarketAudienceToBE = (values, account, firstPixelId) => {
  const {
    [SHOPPER_TYPE_OPTION]: shopperType,
    [BRAND_SHOPPERS]: brandShoppers,
    [CATEGORY_SHOPPERS]: categoryShoppers,
    [LIFESTYLE_CATEGORIES]: lifestyleCategories
  } = values

  let formattedFilters
  let formattedName
  const formatShoppers = (name, shoppersList) => {
    formattedName = `${name} (${shoppersList
      .map(({ label }) => label)
      .sort()
      .join(', ')})`
    formattedFilters = shoppersList.map(({ value }) => ({ field: 'url', value, operator: 'i_contains' }))
  }

  switch (shopperType) {
    case BRAND_SHOPPERS:
      formatShoppers('Brand Shoppers', brandShoppers)
      break
    case CATEGORY_SHOPPERS:
      formatShoppers('Category Shoppers', categoryShoppers)
      break
    case LIFESTYLE_CATEGORIES:
      formatShoppers('Lifestyle Categoriess', lifestyleCategories)
      break
    default:
      return
  }

  return {
    account,
    name: formattedName,
    rule: {
      inclusions: {
        operator: 'or',
        rules: [
          {
            ...(firstPixelId && { event_sources: [{ type: 'pixel', id: firstPixelId }] }),
            retention_seconds: 15552000,
            filter: {
              operator: 'and',
              filters: formattedFilters
            }
          }
        ]
      }
    }
  }
}

export const formatYourWebsiteAudienceToBE = (values, account) => {
  const { name, pixel_choice, duration_option, origin_option, custom_days, url_contains } = values

  return {
    account: account,
    name: name,
    rule: {
      inclusions: {
        operator: 'or',
        rules: [
          {
            event_sources: [
              {
                type: 'pixel',
                id: pixel_choice
              }
            ],
            retention_seconds: getDurationInSeconds(duration_option, origin_option, custom_days),
            filter: {
              operator: 'and',
              filters: getFilters(url_contains)
            }
          }
        ]
      }
    }
  }
}

export const formatFacebookAudienceToBE = (values, account) => {
  const { name, duration_option, origin_option, custom_days, facebook_page_choice } = values

  return {
    account: account,
    name: name,
    rule: {
      inclusions: {
        operator: 'or',
        rules: [
          {
            event_sources: [
              {
                type: 'page',
                id: facebook_page_choice
              }
            ],
            retention_seconds: getDurationInSeconds(duration_option, origin_option, custom_days),
            filter: {
              operator: 'and',
              filters: [
                {
                  field: 'event',
                  value: 'page_engaged',
                  operator: '='
                }
              ]
            }
          }
        ]
      }
    }
  }
}

export const formatAirlineAudienceNameToBE = values => {
  const departureDestinations = values[DEPARTURE_DESTINATIONS].map(d => d.value).join(', ')
  const arrivalDestinations = values[ARRIVAL_DESTINATIONS].map(a => a.value).join(', ')

  const departureString = values[DEPARTURE_DESTINATIONS].length ? `dep. ${departureDestinations}` : ''
  const arrivalString = values[ARRIVAL_DESTINATIONS].length ? `arr. ${arrivalDestinations}` : ''

  const operatorString =
    values[DEPARTURE_DESTINATIONS].length && values[ARRIVAL_DESTINATIONS].length
      ? ` ${values[DESTINATIONS_OPERATOR]} `
      : ''

  const durationString =
    values[DEPARTURE_DESTINATIONS].length || values[ARRIVAL_DESTINATIONS].length
      ? ` | ${values[AIRLINE_DURATION_OPTION]}`
      : values[AIRLINE_DURATION_OPTION]

  const statusString =
    values[PASSENGER_STATUS_OPTION] === PREMIUM_PASSENGERS || values[PASSENGER_STATUS_OPTION] === ECONOMY_PASSENGERS
      ? ` | ${values[PASSENGER_STATUS_OPTION]}`
      : ''

  // Search or booking (dep. KUL, SIN and arr. BKK | past 3 months | premium)
  return `Search or booking (${departureString}${operatorString}${arrivalString}${durationString}${statusString})`
}

export const formatAirlineAudienceToBE = (values, account, firstPixelId) => {
  const formattedName = formatAirlineAudienceNameToBE(values)

  return {
    account: account,
    name: formattedName,
    rule: {
      inclusions: {
        operator: 'or',
        rules: [
          {
            event_sources: [{ type: 'pixel', id: firstPixelId }],
            retention_seconds: 2592000,
            filter: {
              operator: 'and',
              filters: [{ field: 'url', value: 'https://www.facebook.com/', operator: 'i_contains' }]
            }
          }
        ]
      }
    }
  }
}
