import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import EditForm from '../../../../../../../features/components/Forms/EditForm'
import Field from '../../../../../../../components/Form/Field'

import { usePurifiedFormik } from '../../../../../../../hooks/formHooks/usePurifiedFormik'
import { formatAssetsToAssetGroupUpdate } from '../../formatters'
import { getKeyByValue } from '../../../../../../../helpers/common'
import { getBusinessNameData, getCTAData } from '../HeadlinesSection/helpers'

import {
  clearCreateAssetsBatch,
  createAssetsBatch,
  updateAssetGroup
} from '../../../../../../../modules/actions/assets'
import { selectedAdAccountIdSelector } from '../../../../../../../modules/selectors/app'
import { assetsCreatedDataSelector, assetsWasCreatedSelector } from '../../../../../../../modules/selectors/assets'

import { googleAssetFieldType, googleCallToActionEnums } from '../../../../../../../constants/ads'
import { CALL_TO_ACTION } from '../../../../../../ReusableFormFields/CallToActionFields/fields'
import { googleAdsCallToActionList } from '../../../../../../../constants/selectLists/googleAdsCallToActionList'
import { getInitialValues, validationSchema } from './fields'
import { BUSINESS_NAME } from '../../../../../../ReusableFormFields/LineItemForms/fields'
import { ASSET_GROUP_TYPE, NAME, RESOURCE_NAME } from '../../../../fields'
import { GOOGLE_PLATFORM } from '../../../../../../../constants/selectLists/platformList'

const NameSectionForm = ({ editItemData, isInternalAssetGroup, ...formProps }) => {
  const dispatch = useDispatch()

  const { assets, id: assetGroupId, account } = editItemData

  const assetsWasCreated = useSelector(assetsWasCreatedSelector)
  const createdAssetsData = useSelector(assetsCreatedDataSelector)

  const selectedAdAccount = useSelector(selectedAdAccountIdSelector)
  const adAccount = account || selectedAdAccount

  const [deleteAssets, setDeleteAssets] = useState([])
  const [assetsReady, setAssetsReady] = useState(false)

  const [editedName, setEditedName] = useState(editItemData[NAME])

  const createNewAssets = useCallback(
    createAssets => {
      dispatch(
        createAssetsBatch(
          {
            account: adAccount,
            operations: createAssets
          },
          GOOGLE_PLATFORM
        )
      )
    },
    [dispatch, adAccount]
  )

  const handleSubmit = useCallback(
    values => {
      setEditedName(values[NAME])

      const originalBusinessNameAsset = getBusinessNameData(assets)
      const originalBusinessName = originalBusinessNameAsset?.asset_data.text_asset.text

      const originalCtaAsset = getCTAData(assets)
      const originalCtaEnumValue = originalCtaAsset?.asset_data?.call_to_action_asset?.call_to_action
      const originalCta = getKeyByValue(googleCallToActionEnums, originalCtaEnumValue)

      const selectedCta = values[CALL_TO_ACTION]
      const editedBusinessName = values[BUSINESS_NAME]

      let createAssets = []
      let deleteAssets = []

      if (originalBusinessName !== editedBusinessName) {
        createAssets.push({
          account: selectedAdAccount,
          text_asset: { text: editedBusinessName },
          [ASSET_GROUP_TYPE]: googleAssetFieldType.BUSINESS_NAME
        })

        deleteAssets.push({
          [RESOURCE_NAME]: originalBusinessNameAsset[RESOURCE_NAME],
          ...(isInternalAssetGroup && { internal_id: originalBusinessNameAsset?.id }),
          action: 'delete'
        })
      }

      if (originalCta !== selectedCta) {
        createAssets.push({
          account: selectedAdAccount,
          call_to_action_asset: { call_to_action: googleCallToActionEnums[selectedCta] },
          [ASSET_GROUP_TYPE]: googleAssetFieldType.CALL_TO_ACTION_SELECTION
        })

        deleteAssets.push({
          [RESOURCE_NAME]: originalCtaAsset[RESOURCE_NAME],
          ...(isInternalAssetGroup && { internal_id: originalCtaAsset.id }),
          action: 'delete'
        })
      }

      if (!!createAssets.length) {
        createNewAssets(createAssets)
      } else {
        // skip asset creation and run assetGroup update directly
        setAssetsReady(true)
      }

      setDeleteAssets(deleteAssets)
    },
    [assets, createNewAssets, selectedAdAccount, isInternalAssetGroup]
  )

  const initialValues = useMemo(() => getInitialValues(editItemData), [editItemData])

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema
  })

  const purifiedFormik = usePurifiedFormik(formik)

  useEffect(() => {
    if (assetsReady) {
      // When we create assets "/google/assets/batch/", in response we don't have "field_type",
      // so we use "type_" to distinguish them. businessName = 5, cta = 18
      const createdBusinessNameAssets = createdAssetsData.operations?.filter(({ type_ }) => type_ === 5)
      const createdCtaAssets = createdAssetsData.operations?.filter(({ type_ }) => type_ === 18)

      const formattedBusinessNameCreateAssets = formatAssetsToAssetGroupUpdate(
        createdBusinessNameAssets,
        googleAssetFieldType.BUSINESS_NAME,
        assetGroupId
      )

      const formattedCtaCreateAssets = formatAssetsToAssetGroupUpdate(
        createdCtaAssets,
        googleAssetFieldType.CALL_TO_ACTION_SELECTION,
        assetGroupId
      )

      dispatch(
        updateAssetGroup(
          {
            account: selectedAdAccount,
            // All formatted assets. We format create and delete assets separately
            assets: [...deleteAssets, ...formattedBusinessNameCreateAssets, ...formattedCtaCreateAssets],
            [NAME]: editedName,
            ...(isInternalAssetGroup && { internal: true })
          },
          assetGroupId
        )
      )

      setAssetsReady(false)
    }
  }, [
    dispatch,
    assetsReady,
    selectedAdAccount,
    deleteAssets,
    createdAssetsData,
    assetGroupId,
    editedName,
    isInternalAssetGroup
  ])

  useEffect(() => {
    if (assetsWasCreated) {
      setAssetsReady(true)
    }
  }, [assetsWasCreated])

  useEffect(() => {
    return () => {
      dispatch(clearCreateAssetsBatch())
    }
  }, [dispatch])

  return (
    <EditForm formik={purifiedFormik} initialValues={initialValues} {...formProps}>
      <Field formik={formik} name={NAME} placeholder="Name" autoComplete="off" />
      <Field formik={formik} name={BUSINESS_NAME} placeholder="Business name" autoComplete="off" />
      <Field placeholder="Call To Action" formik={formik} name={CALL_TO_ACTION} options={googleAdsCallToActionList} />
    </EditForm>
  )
}

export default NameSectionForm
