import { createStyles } from '../../../../../../styles/helpers'

import { card } from '../../../../../../styles/common'
import { googleBlue, lightGrey } from '../../../../../../styles/const/colors'

export default createStyles(theme => ({
  adPreview: {
    position: 'relative',
    extend: card(theme),
    borderRadius: 0,
    padding: 25,
    fontFamily: 'Arial',
    width: 400,

    '& img': {
      display: 'block',
      maxWidth: '25%'
    }
  },
  icon: {
    color: googleBlue,
    position: 'absolute',
    top: 2,
    right: 2
  },
  top: {
    display: 'flex',
    alignItems: 'center'
  },
  image: {
    marginRight: 20
  },
  headline: {
    fontSize: 22,
    fontWeight: 700
  },
  description: {
    margin: '30px 0'
  },
  businessName: {
    color: lightGrey,
    margin: '20px 0'
  },
  button: {
    color: '#fff !important',
    background: googleBlue,
    borderColor: `${googleBlue} !important`,
    boxShadow: '1px 7px 29px -10px rgba(0,0,0,0.63)',
    width: '100%',

    '&:hover': {
      background: `${googleBlue} !important`
    }
  }
}))
