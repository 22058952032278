import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Page from '../index'
import MediaOrdersTable from './MediaOrdersTable'

import { useLoadPaginatedMediaOrders } from './hooks'

import { mediaOrdersSelector } from '../../modules/selectors/mediaOrders'
import { selectedSelfAccountSelector } from '../../modules/selectors/app'

import { validMediaOrdersStatuses } from '../../constants/mediaOrders'

import useCommonStyles from '../../styles/common/listPage'

const MediaOrders = () => {
  const { t } = useTranslation()
  const commonClasses = useCommonStyles()

  const mediaOrders = useSelector(mediaOrdersSelector)
  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)

  const mediaOrdersParams = useMemo(
    () => ({
      status: validMediaOrdersStatuses.join(','),
      account: selectedSelfAccount
    }),
    [selectedSelfAccount]
  )

  const loadMoreMediaOrdersHandler = useLoadPaginatedMediaOrders(mediaOrdersParams)

  return (
    <Page helmetTitle="helmetTitle.MediaOrdersPage">
      <div className={commonClasses.heading}>
        <h3 className={commonClasses.title}>{t('Media bookings')}</h3>
      </div>
      <MediaOrdersTable mediaOrders={mediaOrders} loadMoreMediaOrdersHandler={loadMoreMediaOrdersHandler} />
    </Page>
  )
}

export default MediaOrders
