import React from 'react'
import ActionText from '../../ActionText'
import classnames from 'classnames'

import useStyles from './styles'

const MinimisedSectionHeader = ({ title, onDelete, deleteBtnText = 'Remove', className, titleClassName, children }) => {
  const classes = useStyles()

  const handleDelete = e => {
    e.stopPropagation()
    if (onDelete) {
      onDelete && onDelete()
    }
  }

  return (
    <div className={classnames(classes.header, className)}>
      <h3 className={titleClassName}>{title}</h3>
      {onDelete && (
        <ActionText className={classes.removeFileBtn} onClick={handleDelete}>
          {deleteBtnText}
        </ActionText>
      )}
      {children && children}
    </div>
  )
}

export default MinimisedSectionHeader
