import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles({
  spoilerTitle: {
    '& svg': {
      marginRight: 32
    }
  }
})

export default useStyles
