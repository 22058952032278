import { darkGrey, lightGrey2 } from './const/colors'

export const pageContainerMaxWidth = 1440
export const smallPageMaxWidth = 632

export const container = {
  width: '100%',
  maxWidth: pageContainerMaxWidth,
  margin: '0 auto'
}

export const cardBorderColor = lightGrey2
export const card = theme => ({
  background: 'white',
  border: {
    width: 1,
    style: 'solid',
    color: lightGrey2
  },
  borderRadius: theme.defaultBorderRadius
})

export const siteLink = {
  color: darkGrey,
  textDecoration: 'underline',
  '&:hover': {
    cursor: 'pointer'
  }
}

export const disabled = {
  opacity: 0.8,
  pointerEvents: 'none'
}

export const smallPageContainer = {
  maxWidth: smallPageMaxWidth,
  margin: '0 auto'
}

export const hideVisibleScrollbar = {
  // IE, Edge and Firefox
  '-ms-overflow-style': 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    // Chrome, Safari and Opera
    display: 'none'
  }
}
