import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { ReactComponent as AllVisitorsIcon } from '../../../assets/icons/web-all-visitors.svg'
import { ReactComponent as TopVisitorsIcon } from '../../../assets/icons/web-top.svg'
import { ReactComponent as FacebookIcon } from '../../../assets/icons/fb-icon.svg'
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning-icon.svg'
import AudienceBySuggestionCreate from '../../../forms/Multiplatform/AudienceForms/AudienceBySuggestionCreate'
import SuggestionCard from '../../../features/components/SuggestionCard'
import InfoModal from '../../../features/components/Modals/InfoModal'
import Button from '../../../components/Button'
import AudienceCreateButton from './AudienceCreateButton'

import { createdAudienceBySuggestionSelector } from '../../../modules/selectors/audiences'
import { accessibleFacebookPagesSelector } from '../../../modules/selectors/socialAccounts'
import { choicesPixelsSelector } from '../../../modules/selectors/choices'
import { clearCreateAudiencesBySuggestion, createAudienceBySuggestion } from '../../../modules/actions/audiences'
import { openForm } from '../../../modules/actions/forms'

import { transformValuesToBE } from '../../../forms/Multiplatform/AudienceForms/AudienceBySuggestionCreate/fields'
import useModalManage from '../../../hooks/useModalManage'
import { AUDIENCE_SUGGESTION_TYPE } from '../../../constants/forms'

import useCommonStyles from '../../../styles/common/listPage'
import useStyles from './styles'
import { selectedAdAccountIdSelector } from '../../../modules/selectors/app'

function AudienceSuggestions() {
  const dispatch = useDispatch()
  const commonClasses = useCommonStyles()
  const classes = useStyles()

  const { wasCreated: audienceCreatedBySuggestionWasCreated } = useSelector(createdAudienceBySuggestionSelector)
  const selectedAdAccountId = useSelector(selectedAdAccountIdSelector)
  const facebookPages = useSelector(accessibleFacebookPagesSelector)
  const pixels = useSelector(choicesPixelsSelector)

  const [suggestionType, setSuggestionType] = useState('')

  const { t } = useTranslation()

  const suggestions = useMemo(
    () => [
      {
        title: 'Website All Visitors',
        info: 'Anyone that has visited your website in the last 6 months',
        icon: <AllVisitorsIcon />,
        suggestionType: AUDIENCE_SUGGESTION_TYPE.WEBSITE_ALL_VISITORS
      },
      {
        title: 'Website Top 25%',
        info: 'The top 25% of visitors to your website based on time spent',
        icon: <TopVisitorsIcon />,
        suggestionType: AUDIENCE_SUGGESTION_TYPE.WEBSITE_TOP_25
      },
      {
        title: 'Facebook Page Engagers',
        info: 'Anyone that has engaged with your page or its posts in the last year',
        icon: <FacebookIcon />,
        suggestionType: AUDIENCE_SUGGESTION_TYPE.FACEBOOK_PAGE_ENGAGERS
      }
      // todo Instagram audience is hidden because we don’t have a list of all instagram accounts yet.
      // {
      //   title: 'Instagram Page Engagers',
      //   info: 'Anyone that has engaged with your page or its posts in the last year',
      //   icon: <InstagramIcon />
      // }
    ],
    []
  )

  const { isModalOpened, openModalHandler, closeModalHandler } = useModalManage({})

  const modalMessage = useMemo(() => {
    return suggestionType === 'facebookPageEngagers' ? (
      <p>{t('You don’t currently have access to any pages to create this audience')}</p>
    ) : (
      <p>{t('You don’t currently have any pixel installed.')}</p>
    )
  }, [t, suggestionType])

  const modalBody = useMemo(() => {
    return suggestionType === 'facebookPageEngagers' ? (
      <Button solid onClick={closeModalHandler}>
        {t('OK')}
      </Button>
    ) : (
      <a href="https://business.facebook.com/overview/" target="__blank">
        <Button solid>{t('Create a pixel on Facebook.')}</Button>
      </a>
    )
  }, [t, suggestionType, closeModalHandler])

  const createAudienceBySuggestionHandler = useCallback(
    suggestionType => {
      setSuggestionType(suggestionType)

      // Define options depending on suggestionType
      const options =
        suggestionType === 'facebookPageEngagers'
          ? facebookPages.filter(({ tasks }) => tasks.includes('advertise'))
          : pixels

      if (options.length > 1) {
        // Before opening the form, clear form to avoid showing error state in case some other suggestion failed before
        dispatch(clearCreateAudiencesBySuggestion())
        // If more than 1 pixel or facebook page, then open the form with Select the pixel or Select facebook page
        dispatch(openForm({ formName: suggestionType }))
      } else if (options.length === 1) {
        // If only 1 pixel or only 1 facebook page, then create the audience without showing a drawer
        const values = { option_choice: options[0].id }
        dispatch(
          createAudienceBySuggestion(
            transformValuesToBE({
              values,
              account: selectedAdAccountId,
              suggestionType
            })
          )
        )
      } else {
        // If no pixel of facebook page, then show a modal error
        openModalHandler()
      }
    },
    [dispatch, facebookPages, pixels, selectedAdAccountId, openModalHandler]
  )

  useEffect(() => {
    if (audienceCreatedBySuggestionWasCreated) {
      dispatch(clearCreateAudiencesBySuggestion())
    }
  }, [dispatch, audienceCreatedBySuggestionWasCreated])

  return (
    <>
      <div className={classnames(commonClasses.container, classes.suggestions)}>
        <div className={commonClasses.heading}>
          <h3 className={classes.moreAudienceTitle}>{t('Need more audiences? Use our suggestions below')}</h3>
        </div>
        {suggestions.map(item => (
          <SuggestionCard
            key={item.title}
            suggestion={item}
            onCreateAudienceBySuggestion={createAudienceBySuggestionHandler}
            suggestionTypeInProgress={suggestionType}
          >
            <AudienceCreateButton
              suggestion={item}
              onCreateAudienceBySuggestion={createAudienceBySuggestionHandler}
              suggestionTypeInProgress={suggestionType}
            />
          </SuggestionCard>
        ))}
      </div>
      <AudienceBySuggestionCreate suggestionType={suggestionType} formattedOptions={[]} />
      <InfoModal
        Icon={WarningIcon}
        isOpen={isModalOpened}
        onClose={closeModalHandler}
        modalTitle={suggestionType === 'facebookPageEngagers' ? 'No pages found!' : 'No pixel found!'}
        ModalMessage={modalMessage}
      >
        {modalBody}
      </InfoModal>
    </>
  )
}

export default AudienceSuggestions
