import React from 'react'
import PropTypes from 'prop-types'

import GoogleMediaFilesPreview from '../../../../GoogleMediaFilesPreview'

import { getAdSquareImageAssets } from '../../helpers'

const SquareImageSectionPreview = ({ ad }) => {
  const squareImageAssets = getAdSquareImageAssets(ad)

  return <GoogleMediaFilesPreview assets={squareImageAssets} assetDataName="asset" />
}

SquareImageSectionPreview.propTypes = {
  ad: PropTypes.object
}

export default SquareImageSectionPreview
