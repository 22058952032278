import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { formatCurrency } from '../../../../helpers/numbers'

const MediaOrderCostCell = ({ mediaOrder = {} }) => {
  const formattedCurrency = useMemo(() => {
    const { currency, cost_with_discount } = mediaOrder

    return formatCurrency(cost_with_discount, { min: 2, max: 2 }, currency)
  }, [mediaOrder])

  return <>{formattedCurrency}</>
}

MediaOrderCostCell.propTypes = {
  mediaOrder: PropTypes.object
}

export default MediaOrderCostCell
