import React from 'react'
import PropTypes from 'prop-types'

import SkeletonForm from '../../../../../../../components/Form/Skeleton'
import LineItemAdvancedFormContent from './LineItemAdvancedFormContent'

import useLoadLineItems from '../../useLoadLineItems'

const LineItemAdvancedForm = ({ adAccountId, isExistingCampaignFlow, ...props }) => {
  const { isLineItemsLoading } = useLoadLineItems({ adAccountId, isExistingCampaignFlow })

  if (isLineItemsLoading) {
    return <SkeletonForm stepsLength={7} />
  }

  return <LineItemAdvancedFormContent adAccountId={adAccountId} {...props} />
}

LineItemAdvancedForm.propTypes = {
  adAccountId: PropTypes.string.isRequired,
  isExistingCampaignFlow: PropTypes.bool.isRequired,
  onSuccessSubmit: PropTypes.func.isRequired
}

export default LineItemAdvancedForm
